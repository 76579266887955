<template>
    <div>
        <div v-if="!loading">
            <apexchart ref="lineColumnChart" type="line" :height="height" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-else>
            <CSpinner
                color="primary"
                style="width:4rem;height:4rem;"
            /><h1>CARGANDO...</h1>
        </div>
    </div>
</template>

<script>
    //computed
    function chartOptions(){
        let options = {
            chart: {
                width: "100%",
                height: this.height,
                type: 'line',
            },
            stroke: {
                width: [0, 4]
            },
            title: {
                text: undefined
            },
            tooltip: {
                enabled: true,
                y: [
                    {
                        formatter: function (val) {
                            return Hrs(val);
                        }
                    },
                    {
                        formatter: function (val) {
                            return val;
                        }
                    },
                ]
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1]
            },
            labels: this.labels,
            xaxis: this.xaxis,
            yaxis: this.yaxis
        }
        if(this.colors.length !== 0){
            options.colors = this.colors;
        }
        return options;
    }
    function Hrs(val){
        let Hrs = Math.floor(val/60);
        if(Hrs<10){
            Hrs = '0'+Hrs;
        }
        let Min = val - (Hrs*60)
        if(Min<10){
            Min = '0'+Min;
        }
        return Hrs+':'+Min;
    }
    export default {
        name:'lineColumn',
        data(){
            return {
                loading:true,
            }
        },
        beforeCreate(){
            this.loading = true;
        },
        mounted(){
            this.$nextTick(async () => {
                this.loading = false;
            });
        },
        update(){
            if(this.$refs.lineColumnChart !== undefined){
                this.$refs.lineColumnChart.refresh();
            }
        },
        props:{
            height:{
                type: String,
                required: true,
                default: '430',
            },
            colors: {
                type: Array,
                required: false,
                default: () => [],
            },
            series: {
                type: Array,
                required: true,
                default: () => [],
            },
            labels: {
                type: Array,
                required: true,
                default: () => [],
            },
            legend: {
                type: Object,
                required: false,
                default: () => {},
            },
            xaxis: {
                type: Object,
                required: false,
                default: {},
            },
            yaxis: {
                type: Array,
                required: false,
                default: () => {},
            }
        },
        methods:{
            Hrs,
        },
        computed:{
            chartOptions,
        }
    }
</script>


<style scoped>
    #chart {
        width:100%;
        max-width: 100%;
    }
</style>