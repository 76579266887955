<template>
  <div>
    <CModalExtended
      :title="tituloModal"
      size="lg"
      color="dark"
      class="modal-extended"
      :closeOnBackdrop="false"
      :show.sync="$store.state.marcamodelo.modalModelUpdated"
    >
            <CRow>
            <CCol sm="12" lg="12">
              <CCard class="card-simple">
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="5">
                <CRow>
                  <CCol sm="12" lg="12" class="text-center">
                    <picture-input
                      ref="imageInputModelo"
                      :imgSrc="imagen"
                      width="186"
                      height="186"
                      accept="image/jpeg,image/png,image"
                      size="10"
                      :custom-strings="imgInputTexts"
                      @change="handleFileUploadModelo"
                      v-if="$store.state.marcamodelo.modalModelUpdated"
                    >
                    </picture-input> 
                    <div v-if="$v.formModelo.RutaModelo.$error">
                      <div
                        class="text-invalid-feedback text-center"
                         v-if="!$v.formModelo.RutaModelo.isValidFile"
                      >
                        {{$t('label.badImage')}}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" lg="7">
                <CRow>
                  <CCol sm="12" lg="12" class="mt-4">
                    <CSelect
                          :label="$t('label.brand')"
                          addLabelClasses="required text-right"
                          :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                          :value="formModelo.IdMarca"
                          v-model.trim="$v.formModelo.IdMarca.$model"
                          :is-valid="hasError($v.formModelo.IdMarca)"
                          :invalid-feedback="errorMessage($v.formModelo.IdMarca)"
                          @change="changeIdMarca($event)"
                          :options="optionsListMarca"
                        >
                        </CSelect>
                  </CCol>
                  <CCol sm="12" lg="12">
                    <CInput
                          :label="$t('label.name')"
                          addLabelClasses="required text-right"
                          v-uppercase
                          :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                          :placeholder="$t('label.modelName')"
                          v-model="$v.formModelo.NbModelo.$model"
                          :is-valid="hasError($v.formModelo.NbModelo)"
                          :invalid-feedback="errorMessage($v.formModelo.NbModelo)"
                        >
                    </CInput>    
                  </CCol>
                  <CCol sm="12" lg="12">
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                        :is-valid="formModelo.FgActModelo"
                        v-if="
                          !idIndentification($store.state.marcamodelo.idModelo)
                        "
                        v-model="formModelo.FgActModelo"
                        :value.sync="formModelo.FgActModelo"
                        :options="selectOptions"
                      />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            </CCardBody>
        </CCard>
            </CCol>
           </CRow>
      <CElementCover :opacity="0.8" v-if="apiStateLoading">
        <label class="d-inline">{{$t('label.load')}}... </label>
        <CSpinner size="sm" />
      </CElementCover>
      <template #footer>
          <CButton
            id="botonmodel"
            color="add"
            class="d-flex align-items-center"
            @click="IsEdit ? statusConfirmation(originalActModelo, formModelo.FgActModelo?1:0, DataModelo) : DataModelo()"
            :disabled="apiStateLoading"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        <CButton
          color="wipe"
          @click="cerrarModal"
          class="d-flex align-items-center m-2"
          :disabled="apiStateLoading"
        >
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import ENUM from "@/_store/enum";
import { mapState } from "vuex";
import FORMARCAMODELO from "@/_validations/marcamodelo/MarcaModeloValidation";
import UpperCase from "@/_validations/uppercase-directive";
import GeneralMixin from "@/_mixins/general";
import ModalMixin from "@/_mixins/modal";
import { GenerateImgPlaceholder } from "@/_helpers/funciones";
import PictureInput from "@/components/picture-input";
import { maxLength } from 'vuelidate/lib/validators';

//data
function data() {
  return {
    formModelo: {
      IdMarca: "",
      NbModelo: "",
      RutaModelo: "",
      FgActModelo: true,
      Image_url: '',
    },
    imagen: "/img/uploadPhotoEs.svg",
    IsEdit: false,
    originalRutmodelo: null,
    
    originalActMarca: "",
    originalActModelo: "",
    loading: false
  };
}
//methods
function changeIdMarca(event) {
  this.formModelo.IdMarca = event.target.value;
}

function idIndentification(id) {
  return id === 0;
}

function registerDataModelo() {
  try{ 
    this.$v.formModelo.$touch();
    if (this.$v.formModelo.$pending || this.$v.formModelo.$error)
      throw this.$t('label.errorsPleaseCheck');
    if (this.$v.formModelo.$pending || this.$v.formModelo.$error) return;

    let IdModelo = this.idModelo;

    const ModelJson = this.IsEdit?{ 
      ModelId: IdModelo,
			BrandId: this.formModelo.IdMarca,
			ModelRoute: this.formModelo.RutaModelo,
      ModelName: this.formModelo.NbModelo,
      Status: this.formModelo.FgActModelo ? 1 : 0,
      UserId:JSON.parse(localStorage.getItem('user')).UserId   
    }:{ 
      BrandId: this.formModelo.IdMarca,
			ModelRoute: this.formModelo.RutaModelo,
      ModelName: this.formModelo.NbModelo,
      UserId:JSON.parse(localStorage.getItem('user')).UserId 
    }

    let metodo = this.IsEdit ? 'PUT':'POST';
    let ruta = this.IsEdit ? 'Model-update':'Model-insert';

      this.$http
        .ejecutar( metodo, ruta, ModelJson, { root: 'ModelJson' })
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            this.notifySuccess({text: response.data.data[0].Response});
            this.cerrarModal();
          } 
        }).catch((err) => {
            this.notifyError({text: err});
          })
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function DataModelo() {
  try{ 
    if(typeof this.formModelo.RutaModelo !== 'string' && this.formModelo.RutaModelo !== null && this.formModelo.RutaModelo !== undefined && this.formModelo.RutaModelo !== ''){
      let ruta = this.IsEdit ? 'Model-update':'Model-insert';
      this.$http.file(ruta, this.formModelo.RutaModelo, this.originalRutmodelo)
        .then((response) => {
          this.formModelo.RutaModelo = response.data.data.files[0].path;
          this.registerDataModelo();
        }).catch((err) => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
        });
    }else{
      this.registerDataModelo();
    }  
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function srcImage(icon) {
  if (icon === null || icon === "" || icon === undefined) {
    return this.srcError;
  } else {
    return icon;
  }
}
function setAltImg(event) {
  event.target.src = this.srcError;
}

async function updatedItemModeloTable(item) {
  this.$store.state.marcamodelo.idModelo = item.ModelId;
  await this.getInformationModelo();
}

async function getInformationModelo() {
  const id = this.idModelo;
  const icon = this.ModelRoute;
  if (icon != null) {
    this.imagen = `${this.$store.getters["connection/getBase"]}${icon.replace('http://pruebassegop.softicompany.com/', '', null, 'i')}`;
    this.formModelo.Image_url = this.imagen;
  }else{
    this.imagen = "/img/uploadPhotoEs.svg";
    this.formModelo.Image_url = '';
  }
  
  this.$store.state.marcamodelo.apiStateForm = ENUM.LOADING;
  this.$http
    .ejecutar("GET", "Model-by-id", { ModelId: id }, "")
    .then((response) => {
      
        if (response.status === 200) {
          const Information = response.data.data;
          if (Information.length !== 0) {
     
            this.$refs.imageInputModelo.file = null;

            this.formModelo.IdMarca = Information[0].BrandId;
            this.formModelo.NbModelo = Information[0].ModelName;
            this.formModelo.RutaModelo = Information[0].ModelRoute;
            this.originalRutmodelo = Information[0].ModelRoute;
            this.originalActModelo = Information[0].FgActModel;
            this.formModelo.FgActModelo = Information[0].FgActModel;
            this.$store.state.marcamodelo.apiStateForm = ENUM.INIT;
          }
        }   
    })
}
function cerrarModal() {
  /*if(this.apiStateLoading)
            return*/
  this.formModelo.IdMarca = "";
  this.formModelo.NbModelo = "";
  this.formModelo.RutaModelo = "";
  this.formModelo.FgActModelo = true;
  this.imagen = "/img/uploadPhotoEs.svg";
  this.originalRutmodelo = null;
  this.originalActModelo = "";
  this.$store.state.marcamodelo.idModelo = 0;
  this.IsEdit=false;
  this.$emit('set-modal-model-list'); 
}

function handleFileUploadModelo() {
  this.formModelo.RutaModelo = this.$refs.imageInputModelo.file;
  this.$v.formModelo.RutaModelo.$touch();
}

//computed

function optionsListMarca() {
  if (this.myDataMarcas.length === 0) {
    return [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
  } else {
    const chart = [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
    this.myDataMarcas.map(function(e) {
      if (e.FgActBrand === true) {
        chart.push({
          value: e.BrandId,
          label: e.BrandName,
        });
      }
    });
    return chart;
  }
}
function apiStateLoading() {
  return this.apiState === ENUM.LOADING;
}
function apiStateFormLoading() {
  let carga = false;
  if (this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT) {
    carga = !carga;
  }
  return carga;
}

function selectOptions(){
  return [
      {
        value: true,
        label: this.$t('label.ACTIVO'),
      },
      {
        value: false,
        label: this.$t('label.INACTIVO'),
      },
  ]
}

function tituloModal(){
  if(idIndentification(this.$store.state.marcamodelo.idModelo)){
    return /*this.$t('label.register')+' '+*/this.$t('label.nuevo')+' '+this.$t('label.model');
  } else {
    return this.$t('label.edit')+' '+this.$t('label.model')+': '+this.formModelo.NbModelo;
  } 
}
//watch
function modalModelUpdated(newQuestion, Oldquestion) {
  if (newQuestion === false) {

    this.formModelo.IdMarca = "";
    this.formModelo.NbModelo = "";
    this.formModelo.RutaModelo = "";
    this.formModelo.FgActModelo = true;
    this.imagen = "/img/uploadPhotoEs.svg";
    this.originalActModelo = "";
    this.originalRutmodelo = null;
    this.IsEdit = false;

    this.$nextTick(() => {
      this.$v.$reset();
    });
    this.$store.commit("marcamodelo/asignarid", 0);
  } else {
    if (this.idModelo !== 0) {
      this.$v.formModelo.$touch();
      this.IsEdit = true;
      this.getInformationModelo();
    }
  }
}
export default {
  name: "app-modal-marcamodelo",
  data,
  mixins: [
    GeneralMixin,
    ModalMixin
  ],
  components: {
    PictureInput,
  },
  validations() {
    return FORMARCAMODELO();
  },
  computed: {
    apiStateLoading,
    apiStateFormLoading,
    optionsListMarca,
    selectOptions,
    tituloModal,
    ...mapState({
      idModelo: (state) => state.marcamodelo.idModelo,
      ModelRoute: (state) => state.marcamodelo.ModelRoute,
      modalModelUpdated: (state) => state.marcamodelo.modalModelUpdated,
      myDataMarcas: (state) => state.marcamodelo.myDataMarcas,
      apiState: (state) => state.marcamodelo.apiState,
      apiStateForm: (state) => state.marcamodelo.apiStateForm,
    }),
  },
  directives: UpperCase,
  methods: {
    changeIdMarca,
    DataModelo,
    registerDataModelo,
    updatedItemModeloTable,
    idIndentification,
    srcImage,
    setAltImg,
    cerrarModal,
    getInformationModelo,
    handleFileUploadModelo,
  },
  watch: {
    modalModelUpdated,
    /*
            idState*/
  },
};
</script>

<style lang="scss">

.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>

