<template>
    <div class="mb-3">
        <CRow class="my-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
            </CCol>
        </CRow>

        <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedDocumentationContainer"
                    :fields="Documentationfields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    @filtered-items-change="getFilteredList"
                    sorter
                >
                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">{{ $t('label.'+item.Status) }}</CBadge>
                      </td>
                    </template>
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                                square
                                size="sm"
                                color="excel"
                                @click="downloadArchive(item)"
                                v-c-tooltip="{
                                    content: $t('label.download'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name="cil-cloud-download" />
                            </CButton>
                                
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
  </template>
  <script>
  
  import General from '@/_mixins/general';
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import YardManagement from '@/_mixins/yardManagement';
  import DataStorage from '@/_helpers/localStorage';

  //DATA-------------------------
   function data() {
        return {
            Items: [],
            ContainerItem:{},
            dataDocumentation: [],
            filteredList: [],
        };
    }
  //methods
    function getFilteredList(arr) {
        this.filteredList = arr;
    }

    function downloadArchive(item) {
        let route = item.DocumentRoute.toLowerCase();
        
        if(route.includes("public/"))
            return window.open( (`${this.$store.getters['connection/getBase']}${route.split("public/").pop()}`).toLowerCase(), '_blank');
        else
            return window.open( (`${this.$store.getters['connection/getBase']}${route}`).toLowerCase(), '_blank');
    }

    async function getYardVisitListDocuments() {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardCargoDocumentation-by-CargoId', { CargoId: this.dataContainer.CargoId })
        .then(response => {
            this.dataDocumentation = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
        this.$store.state.yardManagement.loading = false;
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.yardManagement.loading = true; 
        let currentDate = new Date();   
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];
        let Filter = [];

        Filter[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        Filter[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        this.Documentationfields.map(item=>{   
            if (item.key!='options') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })
        
        for (let i = 0; i < this.filteredList.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataDocumentation[i].IdX,
                Activity: (_lang=='en' ? this.dataDocumentation[i].ActivityEn : this.dataDocumentation[i].ActivityEs) ?? 'N/A',
                TransaLogin: this.dataDocumentation[i].TransaLogin ?? 'N/A',
                TransaRegDate: this.dataDocumentation[i].TransaRegDate ? DateFormater.formatDateTimeWithSlash(this.dataDocumentation[i].TransaRegDate) : 'N/A',
                Status: this.$t('label.'+this.dataDocumentation[i].Status),
            });   
        }

        if(this.filteredList.length !== 0) {
            await this.getReporExcel(rowDataFormated, this.$t('label.documentation'), valor, Filter, '', false, tableHeader);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.yardManagement.loading = false; 
    }

    function computedDocumentationContainer() {
        let _lang = this.$i18n.locale;
        return  this.dataDocumentation.map((item) => Object.assign({}, item, {
            RowNumber: Number.parseInt(item.IdX),
            Activity: _lang=='en' ? item.ActivityEn : item.ActivityEs ?? 'N/A',
            TransaLogin: item.TransaLogin?? 'N/A',
            TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
            DocumentDs: _lang=='en' ? item.DocumentDsEn : item.DocumentDsEs ?? 'N/A',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        }));
    }


    function Documentationfields(){
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'RowNumber', label: '#', _style: 'width:5%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.activity'), key: 'Activity', _classes: 'text-uppercase text-center', _style: 'width:30%; text-align:center;', sorter: true, filter: true},
            { label: this.$t('label.description'), key: 'DocumentDs', _classes: 'text-uppercase text-center', _style: 'width:30%; text-align:center;', sorter: true, filter: true},
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaRegDate', label: this.$t('label.date'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            
        ];
    }
  
    export default{
        name: 'documentation-list',
        data,
        mixins: [General, YardManagement],
        components: {
        },
        methods: {
            onBtnExport,
            getFilteredList,
            downloadArchive,
            getYardVisitListDocuments
        },
        computed: {
            Documentationfields,
            computedDocumentationContainer,
            ...mapState({
                dataContainer: state => state.yardManagement.dataContainer,
                ContainerTabIndex: state => state.yardManagement.ContainerTabIndex,
            })
        },
        watch: {
            ContainerTabIndex: function(newVal) {
                if(newVal && newVal==6){
                    this.getYardVisitListDocuments();
                }
            }
        },
    }
  </script>