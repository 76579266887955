<template>
    <div class="mx-3">
      <CModalExtended
        :title="titleModal"
        color="dark"
        :show.sync="showModal"
        @update:show="closeModal()"
        :closeOnBackdrop="false"
        class="modal-extended"
        size="lg"
      >
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedItems"
          :fields="fields"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="tableText.itemsPerPage"
          hover
          small
          sorter
          pagination
        >
        </dataTableExtended>
      </CCol>
        <template #footer>
          <CButton color="wipe" @click="closeModal">
            <CIcon name="x" />&nbsp; {{$t('button.exit')}}
          </CButton>
      </template>
      </CModalExtended>
    </div>
  </template>
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import { NumberFormater } from '@/_helpers/funciones';
  import mixinGeneral from '@/_mixins/general';
  import mixinServicio from '@/_mixins/servicio';
  import { mapState } from 'vuex';

  function data() {
    return {
      showModal: false,
      title: ''
    };
  }
  
function closeModal() {
  this.$emit('closeModal');
}

//computed
function fields(){
  return  [
    { key: 'Nro', label: '#', filter: false,_style: 'width:5%; text-align:center;'},
    { key: 'CraneAlias', label:this.$t('label.crane'), _style:'width:15%',_classes:"text-center"},
    { key: 'LocationDs', label:this.$t('label.localizacion'), _style:'width:15%',_classes:"text-center"},
    { key: 'PackagingName', label:this.$t('label.packaging'), _style:'width:15%',_classes:"text-center"},
    { key: 'OperationAcronym', label:this.$t('label.OperationType'), _style:'width:20%',_classes:"text-center"},
    { key: 'Quantity', label:this.$t('label.quantity'), _style:'width:15%',_classes:"text-center"},
  ];
}
function formatedItems() {
  return this.detailJson.map((item) => {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return {
      ...item,
      CraneAlias: item.CraneAlias ?? 'N/A',
      LocationDs: item.LocationDs ?? 'N/A',
      PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
      OperationAcronym: item.OperationAcronym ?? 'N/A',
      Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
    };
  });
}
function titleModal() {
  return `${this.$t('label.lapseDetail')}: ${this.detailItems.VisitLapseCode}`;
}

export default {
  name: 'modal-detail',
  data,
  props: { modal: Boolean, detailItems: Object, detailJson: Array },
  mixins: [mixinGeneral, mixinServicio],
  components: {},
  directives: UpperCase,
  watch: {
    modal: async function (val) {
      this.showModal = val;
    },
  },
  methods: {
    closeModal,    
  },
  computed: {
    fields,
    formatedItems,
    titleModal,
    ...mapState({
      Visit: state => state.visitas.VisitId,      
    }),
  },
};
  </script>
  <style lang="scss">
  .status-open {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #42aa91 !important;
    }
  
  }
  .status-close {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #FF8000 !important;
    }
    .form-control:focus {
      border-color: #FF8000 !important;
      -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
      box-shadow: 0 0 0 0.1rem #FF8000 !important;
    }
  }
  .status-inactive {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #e1373f !important;
    }
    .form-control:focus {
      border-color: #e1373f !important;
      -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
      box-shadow: 0 0 0 0.1rem #e1373f !important;
    }
  }
  .status-EndOperation {
    .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #1A3760 !important;
    }
    .form-control:focus {
      border-color: #1A3760 !important;
      -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
      box-shadow: 0 0 0 0.1rem #1A3760 !important;
    }
  }
  .block-gray{
    width: 100%;
    background: rgb(198, 198, 201);
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
    border-radius: 5px;
  }
  .block-blue{
    width: 100%;
    background: rgb(102, 155, 180);
    border: 1px solid rgb(25, 26, 27);
  }
  .block-blue .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }
  .block-green{
    width: 100%;
    background: rgb(66, 170, 145);
    border: 1px solid rgb(25, 26, 27)
  }
  .block-green .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }
  .block-orange{
    width: 100%;
    background: rgb(255, 128, 0);
    border: 1px solid rgb(25, 26, 27)
  }
  .block-orange .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }

  .block-grey{
    width: 100%;
    background: rgb(229, 229, 229);
    border: 1px solid rgb(25, 26, 27)
  }
  .block-grey .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }

  .block-yellow{
    width: 100%;
    background: rgb(255, 253, 176);
    border: 1px solid rgb(25, 26, 27)
  }
  .block-yellow .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }

  .block-green-two{
    width: 100%;
    background: rgb(106, 153, 70);
    border: 1px solid rgb(25, 26, 27)
  }
  .block-green-two .text-black{
    color: rgb(25, 26, 27);
    font-weight: 500 !important;
  }

  .circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid rgb(25, 26, 27)
  }
  </style>