<template>
    <div>
        <CCollapse :show="$store.state.grupomodulo.collapseModal">
            <CRow class="mb-3">
            <CCol sm="8" class="d-flex align-items-center justify-content-start ">
                <CButton
                    color="edit"
                    class="mr-2"
                    v-c-tooltip="{
                    content: $t('label.return'),
                    placement: 'top',
                    }"
                    size="sm"
                    @click="desactivar()" 
                >
                    <CIcon name="cil-chevron-left-alt" />
                </CButton>
                <h6 class="mt-2"> {{ $t('label.groupModuleList')+': '+nombrePadreCollapse }}</h6>
            </CCol>  
            <CCol sm="4" class="d-flex align-items-center justify-content-end ">
            </CCol>
            </CRow>
            <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                :loading="apiStateLoading"
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="dateFilteredItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                pagination
            >
                <template #loading>
                  <loading/>
                </template>
                <template #id="{ index}">
                    <td>
                    {{index+1}}
                    </td>
                </template>
                <template #status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item)">
                            {{item.textStatus}}
                        </CBadge>
                    </td>
                </template>
            </dataTableExtended>
            
        </CCollapse>
    </div>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
</style>
<script>
import ENUM from '@/_store/enum';
import { mapState,mapMutations } from 'vuex';
import { tableTextHelpers } from '@/_helpers/funciones';
import general from '@/_mixins/general';
//data
function data() {
    return {
        usersOpened: null,
        padre: '',                
    }
}

//methods
function desactivar(){
    this.$store.state.grupomodulo.collapseModal = false;
    const id = 0;
    this.$store.commit('grupomodulo/asignarid', {id} )
}
function typeItem(item){
  return item.status;
}
function getBadge (item) {
    if(item.status === true){
        return 'success'
    }else if(item.status === false){
        return 'danger';
    }
}
//computed
function computedItems () {
    return this.$store.getters["grupomodulo/myDataHijotable"];
}
function apiStateLoading() {
    let carga = false;
    if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
        carga = !carga;
    }
    return carga;
}
function dateFilteredItems(){
    if(this.collapseModal){
        return this.computedItems.filter(item => {
            return item
        });
    }else{
        return [];
    }
}
function fields(){
    return[
            { key: 'id', label: '#',filter :false},
            { key: 'moduloEs', label: this.$t('label.moduleGroup')+' '+('(ES)'),_classes:"text-center center-cell" },
            { key: 'moduloEn', label: this.$t('label.moduleGroup')+' '+('(EN)'),_classes:"text-center center-cell" },
            { key: 'nombrePadreEs', label: this.$t('label.father')+' '+('(ES)'),_classes:"text-center center-cell"},
            { key: 'nombrePadreEn', label: this.$t('label.father')+' '+('(EN)'),_classes:"text-center center-cell"},
            { key: 'orden', label: this.$t('label.order'),filter:true,_style:'width:5%;',_classes:"center-cell",_style:'width:8%;',_classes:"text-center center-cell" },
            { key: 'ultimo_usuario', label: this.$t('label.user'),_classes:"text-center center-cell" },
            { key: 'fecharegistrotransa', label:this.$t('label.date'),_classes:"center-cell",_style:'width:8%;',_classes:"text-center center-cell"},
            { key: 'status', label: this.$t('label.status'),_classes:"center-cell",_style:'width:7%;',_classes:"text-center center-cell" },
        ]
}

export default {
    props: ['nombrePadreCollapse'],
    name: 'app-grupo-updated-modulo',
    data,
    mixins:[general],
    computed: {
        computedItems,
        fields,
        apiStateLoading,
        dateFilteredItems,
        ...mapState({
            idValor: state => state.grupomodulo.id,
            apiState: state => state.grupomodulo.apiState,
            collapseModal: state => state.grupomodulo.collapseModal,
        })
    },  
    methods: {
        desactivar,
        getBadge,
        typeItem
    }
}
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}

</style>