import {
  required,
  requiredIf
} from 'vuelidate/lib/validators';

export default () => {
  return {
    bayTier: {
      VesselId: { required },
      BayCode: { required },
      FgUnderDeck: { },
      TierUnderDeckFrom: { required: requiredIf(function(tier) { return tier.FgUnderDeck }) },
      TierUnderDeckTo: { required: requiredIf(function(tier) { return tier.FgUnderDeck }) },
      RowStarboardUnderDeck: { required: requiredIf(function(tier) { return tier.FgUnderDeck }) },
      RowPortUnderDeck: { required: requiredIf(function(tier) { return tier.FgUnderDeck }) },
      FgCrossLineUnderDeck: {  },
      FgToDeck: {  },
      TierToDeckFrom: { required: requiredIf(function(tier) { return tier.FgToDeck }) },
      TierToDeckTo: { required: requiredIf(function(tier) { return tier.FgToDeck }) },
      RowStarboardToDeck: { required: requiredIf(function(tier) { return tier.FgToDeck }) },
      RowPortToDeck: { required: requiredIf(function(tier) { return tier.FgToDeck }) },
      FgCrossLineToDeck: {  },
    }
  }
}