<template>
    <div id="chart" :style="`min-height: ${MinHeight};`">
        <apexchart type="pie" :height="height" :width="width" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    //computed
    function chartOptions(){
        let options = {
            chart: {
                foreColor: this.foreColor,
                type: 'pie',
                animations: {
                    enabled: true,
                }
            },
            labels: this.labels,
            legend: {
                show: this.legend,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: this.positionLegend,
                horizontalAlign: "center",
                floating: false,
                fontSize: '11px',
            },
            noData: {
                text: this.$t('label.noDataAvailable').toUpperCase(),  
                align: 'center',  
                verticalAlign: 'middle',  
                offsetX: 0,  
                offsetY: 0,  
                style: {  
                    color: "#000000",
                    fontSize: '14px',
                    fontFamily: "Helvetica"
                }  
            },
            tooltip: { 
                y: {
                    formatter: function(value) {
                        return "";
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName
                        }
                    }
                }
            },
            theme: {
                palette: this.palleteValue
            }
        }
        if(this.colors.length !== 0){
            options.colors = this.colors;
        }
        return options;
    }
    export default {
        name:"simplePie",
        props:{
            width:{
                type: String,
                required: true,
                default: '430',
            },
            height:{
                type: String,
                required: false,
                default: '100%',
            },
            colors: {
                type: Array,
                required: false,
                default: () => [],
            },
            labels: {
                type: Array,
                required: true,
                default: () => [],
            },
            series: {
                type: Array,
                required: true,
                default: () => [],
            },
            foreColor: {
                type: String,
                required : false,
                default: "#FFFFFF"
            },
            palleteValue: {
                type: String,
                required : false,
                default: () => "palette1"
            },
            positionLegend:{
                type: String,
                required : false,
                default: () => "right"
            },
            legend: {
                type: Boolean,
                default: () => true
            },
            MinHeight: {
                type: String,
                default: () => '100%'
            }
        },
        computed:{
            chartOptions,
        }
    }   
</script>

<style scoped>
    #chart {
        width:100%;
        max-width: 100%;
    }
</style>