<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="close()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ title }}
        </h6>
      </CCol>
    </CRow>
    <CTabs variant="tabs" class="mb-3" :active-tab="tabIndex" @update:activeTab="tabIndex=$event">
      <CTab :title="$t('label.generalData')" :class="(this.$v.form.GeneralData.$anyError)? 'tab-error' : ''">
        <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
          <GeneralDataTab
            :form="form"
            :vForm="$v.form"
            :dateRange="dateRange"
            :RecognitionItem="RecognitionItem"
            :Tab="tabIndex===0 && Active"
            :isEdit="isEdit"
            :show="Active"
            :fgDisabled="fgDisabled"
          />
        </div>
      </CTab>
      <CTab v-show="!form.GeneralData.FgTotal" :title="`${$t('label.packaging')} / ${$t('label.implement')}`" :class="(this.$v.form.DetailJson.$anyError)? 'tab-error' : ''">
        <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
          <PackagingImplementTab
            :form="form"
            :vForm="$v.form"
            :RecognitionItem="RecognitionItem"
            :Tab="tabIndex===1 && Active && !form.GeneralData.FgTotal"
            :isEdit="isEdit"
            :show="Active && !form.GeneralData.FgTotal"
            :fgDisabled="fgDisabled"
          />
        </div>
      </CTab>
      <CTab :title="$t('label.documentation')">
        <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
          <DocumentationTab
            :form="form"
            :vForm="$v.form"
            :Tab="tabIndex===2 && Active"
            :isEdit="isEdit"
            :show="Active"
            :fgDisabled="fgDisabled"
          />
        </div>
      </CTab>
    </CTabs>
    <div class="d-flex align-items-center justify-content-end">
      <CButton
        v-if="!fgDisabled"
        square
        color="add"
        class="d-flex align-items-center mb-3"
        @click="isEdit ? statusConfirmation(RecognitionItem.FgActYardCargoEventDetail, form.GeneralData.Status, Submit) : Submit()"
        :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
      </CButton>
    </div>
  </div>
</template>
<script>
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import recognitionValidations from '@/_validations/bl-query/recognitionValidations';
import GeneralDataTab from './tab/generalData';
import PackagingImplementTab from './tab/packagingImplement';
import DocumentationTab from './tab/documentation';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment';

function data() {
  return {
    tabIndex: 0,
    fgDisabled: false,
    form: {
      GeneralData: {
        FgTotal: false,
        YardAreaId: '',
        TransactionBegin: '',
        TransactionFinish: '',
        EventReasonId: '',
        MachineId: '',
        Observation: '',
        Status: 1,
      },
      Packaging: {
        Id: '',
        FgVolumen: false,
        FgSerial: false,
        BlCargoMasterDetailId: '',
        BlCargoMasterDetailSerialId: '',
        WeightOrigin: 0,
        Quantity: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        Volumen: 0,
        QuantityOrigin: 0,
        VolumenOrigin: 0,
        ImplementJson: [],
      },
      DetailJson: [],
      Implement: {
        id: '',
        GpoImplementId: '',
        ImplementId: '',
        Quantity: 0,
        Observation: '',
      },
      Document: {
        DocumentDs: '',
        DocumentRoute: '',
      },
      CustomInspectionDocumentJson: [],
    }
  };
}

//methods
function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;

    this.$v.form.GeneralData.$touch();
    this.$v.form.DetailJson.$touch();

    let fromError = this.TransactionDateError(this.form.GeneralData.TransactionBegin, 'from');
    let toError = this.TransactionDateError(this.form.GeneralData.TransactionFinish, 'to');

    if (fromError || toError) {
      throw fromError ?? toError;
    }

    if (this.$v.form.GeneralData.$error || this.$v.form.DetailJson.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let cant = {Quantity: 0, Length: 0, Width: 0, Height: 0, Volumen: 0};
    if (!this.form.GeneralData.FgTotal) {
      this.form.DetailJson.map(e => {
        cant.Quantity += e.Quantity;
        cant.Length += e.Length;
        cant.Width += e.Width;
        cant.Height += e.Height;
        cant.Volumen += e.Volumen;
      })
    }

    let BlCargoJson = !this.isEdit ? {
      BlCargoId: this.dataBl.BlCargoId,
      YardId: this.yardData.YardId,
      EventReasonId: this.form.GeneralData.EventReasonId ?? '',
      YardAreaId: this.form.GeneralData.YardAreaId ?? '',
      MachineId: this.form.GeneralData.MachineId ?? '',
      EventServiceId: this.form.GeneralData.EventServiceId ?? '',
      Quantity: cant.Quantity,
      Length: cant.Length,
      Width: cant.Width,
      Height: cant.Height,
      Volumen: cant.Volumen,
      DetailJson: !this.form.GeneralData.FgTotal ? this.form.DetailJson.map(e => {
        return{
          BlCargoMasterDetailId: e.BlCargoMasterDetailId,
          Quantity: e.Quantity,
          Length: e.Length,
          Width: e.Width,
          Height: e.Height,
          Volumen: e.Volumen,
          ImplementJson: e?.ImplementJson?.map(el => {
            return {
              ImplementId: el.ImplementId,
              Quantity: el.Quantity,
              Status: el.Status,
            }
          }) ?? [],
          SerialJson: e?.SerialJson?.map(el => {
            return {
              BlCargoMasterDetailSerialId: el.BlCargoMasterDetailSerialId,
              Quantity: el.Quantity,
              Length: el.Length,
              Width: el.Width,
              Height: el.Height,
              Volumen: el.Volumen,
              ImplementJson: el?.ImplementJson?.map(ele => {
                return {
                  ImplementId: ele.ImplementId,
                  Quantity: ele.Quantity,
                  Status: ele.Status,
                }
              }) ?? [],
              Status: el.Status,
            }
          }),
          Status: e.Status,
        }
      }) : [],
      CustomInspectionDocumentJson: this.form.CustomInspectionDocumentJson.map(e => {
        return {
          DocumentRoute: e.DocumentRoute,
          DocumentDs: e.DocumentDs,
          Status: e.Status,
        }
      }),
      Observation: this.form.GeneralData.Observation ?? '',
      TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionBegin),
      TransactionFinish: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionFinish),
      Status: 1,
    } : {
      YardCargoEventDetailId: this.RecognitionItem?.YardCargoEventDetailId ?? '',
      BlCargoMasterDetailId: this.RecognitionItem?.BlCargoMasterDetailId ?? '',
      BlCargoMasterDetailSerialId: this.RecognitionItem?.BlCargoMasterDetailSerialId ?? '',
      BlCargoId: this.dataBl.BlCargoId,
      YardId: this.yardData.YardId,
      EventReasonId: this.form.GeneralData.EventReasonId ?? '',
      YardAreaId: this.form.GeneralData.YardAreaId ?? '',
      MachineId: this.form.GeneralData.MachineId ?? '',
      EventServiceId: this.form.GeneralData.EventServiceId ?? '',
      Quantity: cant.Quantity,
      Length: cant.Length,
      Width: cant.Width,
      Height: cant.Height,
      Volumen: cant.Volumen,
      CustomInspectionDocumentJson: this.form.CustomInspectionDocumentJson.map(e => {
        return {
          YardCargoEventDetailDocumentId: e.YardCargoEventDetailDocumentId ?? '',
          DocumentRoute: e.DocumentRoute,
          DocumentDs: e.DocumentDs,
          Status: e.Status,
        }
      }),
      ImplementJson: (this.RecognitionItem?.BlCargoMasterDetailId ? 
        (this.RecognitionItem?.BlCargoMasterDetailId ? 
          this.form.DetailJson?.[0].SerialJson?.[0]?.ImplementJson?.map(e => {
            return {
              YardCargoEventDetailId: e.YardCargoEventDetailId ?? '',
              ImplementId: e.ImplementId,
              Quantity: e.Quantity,
              Status: e.Status,
            }
          }) :
          this.form.DetailJson?.[0]?.ImplementJson?.map(e => {
            return {
              YardCargoEventDetailId: e.YardCargoEventDetailId ?? '',
              ImplementId: e.ImplementId,
              Quantity: e.Quantity,
              Status: e.Status,
            }
          })
        ) : []
      ),
      Observation: this.form.GeneralData.Observation ?? '',
      TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionBegin),
      TransactionFinish: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionFinish),
      Status: this.form.GeneralData.Status,
    }

    let method = this.isEdit ? 'PUT' : 'POST';
    let route = this.isEdit ? 'YardWCargoCustomInspection-update' : 'YardWCargoCustomInspection-insert';

    this.$http.ejecutar(method, route, BlCargoJson, { root: 'BlCargoJson' })
      .then(async(response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.close();
        await this.$emit("Update-list");
      }).catch((err) => {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        })
      }).finally(() => {
        this.isSubmit = false;
      });  
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

function getData(item) {
  this.form.GeneralData = {
    FgTotal: !item.BlCargoMasterDetailId && !item.BlCargoMasterDetailSerialId,
    YardAreaId: item.YardAreaId ?? '',
    TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
    TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
    EventReasonId: item.EventReasonId ?? '',
    MachineId: item.MachineId ?? '',
    Observation: item.Observation ?? '',
    Status: item.FgActYardCargoEventDetail ? 1 : 0,
  }
  this.form.DetailJson = [{ 
    ...item, 
    ImplementJson: !item.BlCargoMasterDetailSerialId ? (item.ImplementJson?.map((e, index) => {return {...e, IdX: index, Status: 1}}) ?? []) : [],
    SerialJson: item.BlCargoMasterDetailSerialId ? [{
      ...item, 
      IdX: 0, 
      ImplementJson: item?.ImplementJson?.map((e, index) => {return {...e, IdX: index, Status: 1}}) ?? [], 
      Status: 1
    }] : [],
    Status: 1,
  }]
  this.form.CustomInspectionDocumentJson = item?.CustomInspectionDocumentJson.map((e, index) => {return {...e, IdX: index, Status: 1}}) ?? [];

  if(!this.yardData?.YardId){
    this.fgDisabled = true; 
  }
  
  this.$v.form.GeneralData.$touch();
  setTimeout(() => {
    this.$v.form.Packaging.$reset();
  }, 30)
}

function TransactionDateError(date, type) {
  if (date) {
    if (DateFormater.formatDateTimeWithoutSlash(date) < this.dateRange.GeneralData.PreviousDate) {
      return this.$t('validation.minDateRecognition');
    }else if (!this.dateRange.GeneralData.FgDispatch && DateFormater.formatDateTimeWithoutSlash(date) > this.dateRange.GeneralData.LaterDate) {
      return this.$t('label.ErrorCurrentDatedPleaseCheck');
    }else if (this.dateRange.GeneralData.FgDispatch && DateFormater.formatDateTimeWithoutSlash(date) > this.dateRange.GeneralData.LaterDate) {
      return this.$t('label.ErrorDispatchDatePleaseCheck');
    }else if (type == 'from' && this.dateRange.GeneralData.TransactionFinish && DateFormater.formatDateTimeWithoutSlash(date) >= this.dateRange.GeneralData.TransactionFinish) {
      return this.$t('label.ErrorFromToPleaseCheck');
    }else if (type == 'to' && this.dateRange.GeneralData.TransactionBegin && DateFormater.formatDateTimeWithoutSlash(date) <= this.dateRange.GeneralData.TransactionBegin) {
      return this.$t('label.ErrorFromToPleaseCheck');
    }
  }else {
    return false
  }
}

function close() {
  this.$v.form.$reset();
  this.$emit("close");
}

//computed
function title() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.recognition')}`
  }else {
    return `${this.$t('label.nuevo')} ${this.$t('label.recognition')}`
  }
}

function dateRange() {
  return {
    GeneralData: {
      FgDispatch: this.dataBl?.DispatchDate ? true : false,
      PreviousDate: this.dataBl?.DocumentYardJson?.[0]?.TransactionDateCertification ?  moment(this.dataBl?.DocumentYardJson?.[0]?.TransactionDateCertification, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm") : undefined,
      LaterDate: this.dataBl?.DispatchDate ? moment(this.dataBl.DispatchDate, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm") : DateFormater.formatTimeZoneToDateTimewithDash(new Date()),
      TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionBegin),
      TransactionFinish: DateFormater.formatDateTimeWithoutSlash(this.form.GeneralData.TransactionFinish),
    }
  }
}

function TransactionBegin() {
  return this.form.GeneralData.TransactionBegin;
}

function TransactionFinish() {
  return this.form.GeneralData.TransactionFinish;
}

export default{
  name: 'BlRecognitionRegister',
  data,
  mixins: [General, ModalMixin],
  validations(){ return recognitionValidations(this.form, this.dateRange) },
  directives: UpperCase,
  components:{
    GeneralDataTab,
    PackagingImplementTab,
    DocumentationTab,
  },
  props: {
    Active: Boolean,
    isEdit: Boolean,
    RecognitionItem: Object,
  },
  methods: {
    Submit,
    getData,
    TransactionDateError,
    close,
  },
  computed: {
    title,
    dateRange,
    TransactionBegin,
    TransactionFinish,
    ...mapState({
      dataBl: state => state.yardManagement.dataBl,
      yardData: state => state.yardManagement.yardData,
    })
  },
  watch: {
    Active: function (NewVal) {
      this.tabIndex = 0;
      this.fgDisabled = false;
      if (NewVal && this.isEdit) {
        this.getData(this.RecognitionItem);
      }
    },
    TransactionBegin: function(NewVal) {
      try {
        let Error = this.TransactionDateError(NewVal, 'from')
        if (Error) {
          throw Error;
        }
      } catch (error) {
        this.notifyError({text: error});
      }
    },
    TransactionFinish: function(NewVal) {
      try {
        let Error = this.TransactionDateError(NewVal, 'to')
        if (Error) {
          throw Error;
        }
      } catch (error) {
        this.notifyError({text: error});
      }
    },
  }
}
</script>