import {
    onlyNumeroTresdigitos,
    onlyMayusText,
    requiredSelect,
    UtcZone
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal,
    minValue,
    maxValue,
    numeric } from "vuelidate/lib/validators";

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else{
            return (value != '');
        }
          
      };

export const FormPais = () => {
    return {
        formPais: {
            NbPais:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            CodIsoAlpha2:{
                required,
                minLength: minLength(2),
                maxLength: maxLength(2),
                onlyMayusText
            },
            CodIsoAlpha3:{
                required,
                minLength: minLength(3),
                maxLength: maxLength(3),
                onlyMayusText
            },
            Latitude:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(50),
                decimal
            },
            Longitude:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(50),
                decimal
            },
            Numeric:{
                required,
                onlyNumeroTresdigitos
            },
            UtcZone:{
                required,
                UtcZone
            },
            svgIcon:{
                isValidFile
            }
        }
    }
}

export const FormEstado = () => {
    return {
        formEstado: {
            NbEstado:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            IdPais:{
                requiredSelect,
                required
            },
        }
    }
}

export const FormMunicipio = () => {
    return {
        formMunicipio: {
            NbMunicipio:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            IdEstado:{
                requiredSelect,
                required
            },
        },
        paisSelected:{
            requiredSelect,
            required
        }
    }
}

export const FormParroquia = () => {
    return {
        formParroquia: {
            NbParroquia:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            IdMunicipio:{
                requiredSelect,
                required
            },
        },
        paisSelected:{
            requiredSelect,
            required
        },
        estadoSelected:{
            requiredSelect,
            required
        }
    }
}