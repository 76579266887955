<template>
  <div>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab">
        <CustomTab icon="cil-playlist-add" :title="$t('label.basicData')">
          <CRow class="justify-content-center mt-2">
            <CCol sm="12" lg="6">
              <CInput
                size="sm"
                v-uppercase
                :label="$t('label.CurrentSeal')"
                :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                v-model="$v.CurrentSeal.$model"
                :is-valid="hasError($v.CurrentSeal)"
                :invalid-feedback="errorMessage($v.CurrentSeal)"
                :placeholder="$t('label.CurrentSeal')"
                :disabled="true"
              />
              <CInput
                size="sm"
                v-uppercase
                :label="$t('label.NewSeal')"
                :horizontal="{label:'col-sm-12 col-lg-4 text-right require', input:'col-sm-12 col-lg-8'}"
                v-model="$v.NewSeal.$model"
                :is-valid="hasError($v.NewSeal)"
                :invalid-feedback="errorMessage($v.NewSeal)"
                :placeholder="$t('label.NewSeal')"
                :disabled="SealConditionIntact"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <CSelect
                size="sm"
                :label="$t('label.AssignedBy')"
                :horizontal="{label:'col-sm-12 col-lg-4 text-right require', input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.select')"
                v-model="$v.AssignedBy.$model"
                :options="AssignedByOptions"
                :is-valid="hasError($v.AssignedBy)"
                :invalid-feedback="errorMessage($v.AssignedBy)"
                :disabled="SealConditionIntact"
              />
              <CTextarea
                rows="2"
                :label="$t('label.observation')"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.observation')"
                v-uppercase
                size="sm"
                v-model="$v.Observation.$model"
                :is-valid="hasError($v.Observation)"
                :invalid-feedback="errorMessage($v.Observation)"
                :disabled="SealConditionIntact"
              />
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab icon="photo" :title="$t('label.picture')">
          <CRow class="justify-content-center mt-2">
            <CCol sm="4" lg="4" class="mb-3">
              <div class="preview">
                <picture-input
                  v-if="tabIndex==1"
                  ref="imageInput"
                  width="200"
                  height="200"
                  accept="image/jpeg,image/png"
                  size="10"
                  :custom-strings="imgInputTexts"
                  @change="handleFileUpload"
                  :imgSrc="Route"
                >
                </picture-input>
              </div>
            </CCol>
            <CCol sm="12" lg="auto" class="mb-3 row align-items-end justify-content-end">
              <CButton
                  color="add"
                  @click.stop="submitImage()"
                  :disabled="isSubmit"
                >
                  <CIcon name="checkAlt"/>
                </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                :items="formatedItems"
                :fields="fields"
                :items-per-page="5"
                :noItemsView="tableText.noItemsViewText"
                pagination
                column-filter
              >
                <template #Imagen="{item, index}">
                  <td class="center-cell text-uppercase">
                    <img
                      :src="srcImage(item.Imagen)"
                      style="height: 32px !important; width: 32px !important;"
                      @error="'/img/errorimage.jpg'"
                    />
                  </td>
                </template>
                <template #status="{item}">
                  <td class="center-cell">
                    <CBadge v-if="item.status" color="success">
                      {{item.Status}}
                    </CBadge>
                    <CBadge v-if="!item.status" color="danger">
                      {{item.Status}}
                    </CBadge>
                  </td>
                </template>
                <template #Options="{item,index}">
                  <td class="center-cell">
                    <CButton
                      color="wipe"
                      size="sm"
                      class="mr-1"
                      @click="DeleteImage(item)"
                      v-c-tooltip="{
                        content: $t('label.delete')+' '+$t('label.picture'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CustomTab>
      </CustomTabs>
      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click.stop="submit"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import ModalSealValidations from '@/_validations/yard-management/movements/modalSealValidations';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import PictureInput from '@/components/picture-input';
import { DateFormater } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';
import moment from 'moment';

function data() {
  return {
    showModal: false,
    tabIndex: 0,
    titleModal: '',
		CurrentSeal: '',
		NewSeal: '',
    AssignedBy: '',
    AssignedByList: [],
		Observation: '',
    Imagen: '',
    Route: '',
    RouteImage: [],
    isSubmit: false,
  };
}

function SealAssignmentList() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('SealAssignment-list')
    .then(response => {
      let List = response.data.data;
      this.AssignedByList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function submit() {
	try {
    this.isSubmit = false;
		this.$store.state.yardManagement.loading = true;

		this.$v.$touch();

    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let Seal = {};
    if (this.SealConditionIntact) {
      Seal = {
        index: this.SealJson ? this.SealJson.index : '',
        CurrentSeal: this.SealJson ? this.SealJson.CurrentSeal : '',
        Seal: this.SealJson ? this.SealJson.Seal : '',
        NewSeal: '',
        ConditionSeal: this.SealJson ? this.SealJson.ConditionSeal : '',
        SealAssignmentId: this.SealJson ? this.SealJson.SealAssignmentId : '',
        ObservationSeal: this.SealJson ? this.SealJson.ObservationSeal : '',
        SealDocumentJson: this.RouteImage,
      }
    } else {
      Seal = {
        index: this.SealJson ? this.SealJson.index : '',
        CurrentSeal: this.SealJson ? this.SealJson.CurrentSeal : '',
        Seal: this.NewSeal,
        NewSeal: this.NewSeal,
        ConditionSeal: this.SealJson ? this.SealJson.ConditionSeal : '',
        SealAssignmentId: this.AssignedBy,
        ObservationSeal: this.Observation,
        SealDocumentJson: this.RouteImage,
      }
    }
    this.closeModal(Seal);
    this.$store.state.yardManagement.loading = false;
		this.isSubmit = false;
	} catch (e) {
		this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function submitImage() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
  
    if (this.RouteImage.length == 4) {
      throw this.$t('label.maximunFourSeals');
    }

    this.$http.file('YardManagement-file', this.Imagen)
    .then((response) => {
      this.$refs.imageInput.removeImage();
      this.$refs.imageInput.value = null;
      this.$refs.imageInput.file = null;
      this.Imagen = '';
      this.RouteImage.push({
        SealRoute: response.data.data.files[0].path,
        Date: moment(new Date).format('DD/MM/YYYY HH:mm'),
      });
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
    }).catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }); 
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function DeleteImage(item) {
  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteFile')}`,
      }))
    .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      this.$http.delete('files', item.SealRoute.replace(/public+[/]/i, ''))
        .then(async(response) => {
          if (this.isEdit && item.FgActDocument && item.SealName) {
            let UpdateImages = this.RouteImage.filter(RouteImage => 
              RouteImage.FgActDocument && RouteImage.SealName && RouteImage.SealRoute != item.SealRoute
            )?.map(Document => {
              return{
                SealRoute: Document.SealRoute ?? '',
                FgActDocument: 1,
                Status: 1,
              }
            });
            this.$emit('UpdateRegister', UpdateImages, this.SealJson.index);
          }
          this.RouteImage = this.RouteImage.filter(RouteImage => RouteImage.SealRoute != item.SealRoute);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

function handleFileUpload() {
  this.Imagen = this.$refs.imageInput.file;
  if(this.$refs.imageInput.fileType == 'image/png'){
    document.getElementById('img-position').style.display = 'none';
  }
  else{
    document.getElementById('img-position').style.display = 'block';
  }
}

function handleTab(tab) {
  this.tabIndex = tab;
}

function getData(item) {
  this.CurrentSeal = item ? item?.CurrentSeal : '';
  this.NewSeal = item ? item?.NewSeal : '';
  this.AssignedBy = item ? item?.SealAssignmentId : '';
  this.Observation = item ? item?.ObservationSeal : '';
  this.RouteImage = item ? item?.SealDocumentJson : [];
  this.SealAssignmentList();
}

function closeModal(Seal) {
  if (!Seal) {
    Seal = {
      ...this.SealJson,
      SealDocumentJson: this.RouteImage,
    };
  }
  this.$emit('UpdateSeal', Seal);
  this.refreshComponent();
  this.$emit('CloseModal');
}

function srcImage(icon){
  if(icon){
    return icon;
  }else{
    return '/img/errorimage.jpg';
  }
}

function refreshComponent() {
	this.CurrentSeal = '';
	this.NewSeal = '';
	this.AssignedBy = '';
	this.AssignedByList = [];
	this.Observation = '';

  this.Imagen = '';
  this.RouteImage = [];

  this.titleModal= '';

  this.$v.$reset();
}

//computed
function AssignedByOptions() {
  if(this.AssignedByList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.AssignedByList.map(function(e){
      chart.push({
        value: e.SealAssignmentId,
        label: _lang=='en'? e.SealAssignmentNameEn : e.SealAssignmentNameEs,
      })
    })
    return chart;
  }
}

function formatedItems() {
  return this.RouteImage.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      SealRoute: item.SealRoute,
      Imagen: `${this.$store.getters['connection/getBase']}${item.SealRoute.replace(/public+[/]/i, '')}`,
      TransaLogin: item.TransaLogin ? item.TransaLogin : DataStorage.getUser().Login,
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : item.Date,
    }
  })
}

function fields(){ 
  return [
    { key: 'Nro', label: '#',_style: 'width:5%; text-align:center;', filter: false},
    { key: 'Imagen', label: this.$t('label.image'), _style: 'width: 50%;', sorter: false,  filter: false, _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 20%;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 20%;', _classes:"text-center"},
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 45px',  sorter: false,  filter: false, _classes:'text-center' }
  ]
}

function SealConditionIntact() {
  return process.env.VUE_APP_SEAL_CONDITION_INTACT == this.SealJson?.ConditionSeal?.toUpperCase();
}

export default {
  name: 'modal-seal',
  components: {
    CustomTabs,
    CustomTab,
    PictureInput,
  },
  data,
  props: {
    modal: Boolean,
    SealJson: Object,
    isEdit: Boolean,
  },
  mixins: [ModalMixin, GeneralMixin],
  directives: UpperCase,
  validations(){ 
    return ModalSealValidations(this.SealConditionIntact);
  },
  methods: {
    SealAssignmentList,
    submit,
    submitImage,
    DeleteImage,
    handleFileUpload,
    handleTab,
    getData,
    closeModal,
    srcImage,
    refreshComponent,
  },
  computed: {
    AssignedByOptions,
    formatedItems,
    fields,
    SealConditionIntact,
  },
  watch: {
    modal: async function (val) {
      this.showModal = val;
      if (val) {
        this.tabIndex = 0;
        let _lang = this.$i18n.locale;
        this.Route = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        this.titleModal = `${this.$t('label.Seal')}: ${this.SealJson?.Seal ? this.SealJson.Seal : ''}`;
        this.getData(this.SealJson);
      }
    },
  }
};
</script>