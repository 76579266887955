<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="img-vessel">
                <img :src="imageSrc(this.details.YardProfileRoute ?? srcError)"  height="100px" class="bg-wrap text-center"  />
              </div>
              <div class="title-vessel">
                <div class="vessel" >
                  <div class="d-flex align-items-center justify-content-center w-100" style="height: 20%;">
                    <strong> {{ titleDataSheet }} </strong>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ $t('label.acronym') }}: </strong
                          >{{ getDato(details.YardAlias) }}
                        </td>
                        <td class="limit-field-20">
                          <strong> AREA : </strong
                          >{{ getDato(details.SquareMeters) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.capStatic') }}: </strong>{{ getDato(details.StaticCapacity) }}
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <strong>{{ $t('label.height_') }}: </strong> {{ getDato(details.OperationalCapacity) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.nroAccess') }}: </strong
                          >{{ getDato(details.AccesNumber) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.yardType') }}: </strong
                          >{{ getDato( this.$i18n.locale=='en' ? details.TpYardNameEn : details.TpYardNameEs ) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('label.condition') }}: </strong
                          >{{ getDato( this.$i18n.locale=='en' ? details.YardStatusDsEn : details.YardStatusDsEs ) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.teus') }}: </strong>{{ getDato(details.Teus) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.yardGroup') }}: </strong>{{ getDato(details.YardGpoName)  }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="info-vessel">
                <div class="bay-plan">
                  <div class="bay-plan-card" 
                    :style="
                      colNum(false)>=1 && colNum(false) <= 5? 'width: 30%;': 
                      colNum(false)>=6 && colNum(false) <= 10? 'width: 60%;':
                      'width: 100%;'
                    ">
                      <CRow align-horizontal="center">
                        <CCol sm="8" v-if="positions">
                          <bay-grid
                            :col="colNum(false)"
                            :row="rowNum(false)"
                            :items="positions"
                            :maxCol="responsiveCol()"
                            showStatus
                            :statusCondition = true
                          />
                        </CCol>
                      </CRow>
                  </div>
                  <span class="teus-bayplan">{{ `${$t('label.block')} : ${getDato(details.yardBlock)}` }}</span>
                  <span class="baycode-bayplan">{{ `${$t('label.height_')} : ${getDato(details.yardHeight)}` }}</span>
                </div>

                <div class="legend">
                  <CCol sm="2"  v-for="leg in formatedLegend" :key="leg.YardSlotStatusId">
                  <CRow>
                    <CCol sm="2" class="item">
                      <div :class="leg.Class || null"></div>
                    </CCol>
                    <CCol sm="9" class="item-label">
                      {{ getDato( $i18n.locale=='en' ? leg.YardSlotStatusNameEn : leg.YardSlotStatusNameEs ) }}
                    </CCol>
                  </CRow>
                  </CCol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayGrid from '@/pages/yards/tabs/blocksBays/bay-plan-view/bay-grid';
import { BayMixin } from '@/_mixins/buque';
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    srcError: 'img/errorimage.jpg',
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function getDato(value) {
  if (!this.details) return "";

  return value ? value : "";
}

function formatNumber(number) {
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace('.', ',');

  return format.replace(/\d(?=(\d{3})+\,)/g, '$&.');
}

//Computeds:
function hasDetailsInfo() {
  return this.details ? true : false;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function imageSrc(Src) {
  if (Src === '') {
    return this.srcError;
  }
  else {
    return `${this.$store.getters["connection/getBase"]}${Src}`;
  }
}

function footerText() {
  return "";
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function titleDataSheet() {
  if (!this.details) return ` `;

  let name = this.details.YardName;

  return `${name}`;
}
function totalPages() {
  return 1;
}

function colNum(){
    let count = 0;

    this.positions?.map(item =>{
      if(count < item?.SlotJson?.length)
        count = item?.SlotJson?.length;
    });
    return count;
  }
  function rowNum(){
    
    return this.positions?.length > 0 ? this.positions?.length :  0;
  }
  function responsiveCol() {
    let colNum = this.colNum(false);
    return colNum;
  }
function formatedLegend(){
  return this.legend.filter((item) => item.Class != null);
}
export default {
  name: "yard-blocks-pdf",
  props: {
    details: {
      type: Object,
      default: null,
    },
    currentBay: {
      type: Object,
      default: null,
    },
    vesselRoute: {
      type: String,
      default: "",
    },
    bayCode: {
      type: String,
      default: "",
    },
    positions: {
      tyoe: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [BayMixin],
  components: {
    BayGrid
  },
  data,
  created,
  methods: {
    getDato,
    formatNumber,
    colNum,
    rowNum,
    responsiveCol,
    imageSrc
  },
  computed: {
    footerText,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    hasLogo,
    loginName,
    hasDetailsInfo,
    titleDataSheet,
    totalPages,
    formatedLegend,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
  },
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;
  color: #000000d7;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff50;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 0.8rem;
  padding: 0%;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.5rem;
}
.vessel{
  width: 100%;
  height: 100%;
}
.vessel > table {
  height: 80%;
}
.vessel > table td {
  border: 0.5px solid #0000008e;
}
.vessel > table td {
  padding: 0px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-30 {
  width: 30% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 80%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .info-vessel .bay-plan {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}
.teus-bayplan {
  position: absolute;
  left: 82%;
  bottom: 10%;
}
.baycode-bayplan {
  position: absolute;
  left: 82%;
  bottom: 4%;
}

.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
.info-content  .legend {
  width: 100%;
  padding: 0px 5px 10px 5px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  margin-top: 1%;
  gap: 5px;
}
.legend .area {				
  width:	100%;
}
 .legend .area {
  min-height: 10px;						
  width:	calc((100% / 12) - 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0%;
}
 .item {
  min-height: 60%;
  height: 25px;
  padding-top: 10%;
  /* filter: brightness(0%); */
  /* border-radius: 5px; */
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

 .item-label {
  font-size: 7px;
  height: 25px;
  padding-top: 5%;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding-left: 0px;

}
 .item svg{
  width: 50%;
}
 .item .icon {
  max-width: 30px;
  position: absolute;
  width: 28%;
  top: 14%;
}

.area-border {
  border: 1px solid #000;
}

/* Iconos */
.flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45,
  .footer-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube,
  .refrigerado-high-cube,
  .rt-refrigerado-high-cube  {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .disabled-position {
  background-image: url('/img/yards/blocks/DISABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reefer-plug-status {
  background-image: url('/img/yards/blocks/RIFEL-PLUG-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-available {
  background-image: url('/img/yards/blocks/HAZARD-AVAILABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-restriction {
  background-image: url('/img/yards/blocks/HAZARD-RESTRINGED-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.special-container {
  background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>