<template>
  <div class="matching-results">
    <table class="match-results" :style="{ transform: `scale(${getCurrentScale})` }">
      <thead>
        <tr>
          <th colspan="2" rowspan="2"></th>
          <th colspan="2" style="width: 100%">{{ colLabels }}</th>
        </tr>
        <tr>
          <th class="bg-matching-white p-0" style="width: 80%">
            <div
              class="columns-values"
              v-for="(colum, index) in formatColLabels"
              :key="index + 'collabel'"
            >
              <div
                v-for="(value, i) in colum.values"
                :key="i + 'collabelval'"
                class="cell-box"
                :style="{
                  width: `calc( calc(100% / ${colum.values.length}))`,
                  backgroundColor: value.Color ? `${value.Color}` : '#fff',
                  color: value.Color ? '#fff' : '#000',
                }"
              >
                {{ getOptionName(colum.type, value) }}
              </div>
            </div>
          </th>
          <th class="bg-matching-white" colspan="2">TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="bg-matching-grey p-0 text-rotate-labels">
            <span>{{ rowLabels }}</span>
          </td>
          <td
            class="p-0"
            style="height: 100%;"
          >
            <div class="rows-values" style="width: 100%;height: 100%;">
              <div
                class="row-columns"
                v-for="(fila, index) in formatRowLabels"
                :key="index + 'rowlabel'"
              >
                <div
                  v-for="(value, i) in fila.values"
                  :key="i + 'rowlabelval'"
                  class="cell-box row-values-padding"
                  :style="{
                    height: `calc( calc(100% / ${fila.values.length}))`,
                    backgroundColor: value.Color ? `${value.Color}` : '#fff',
                    color: value.Color ? '#fff' : '#000',
                  }"  
                >
                  {{ getOptionName(fila.type, value) }}
                </div>
              </div>
            </div>
          </td>
          <td class="p-0" style="height: 100%;">
            <div
              class="columns-values"
              :style="{ 
                  height: `calc( calc(100% / ${formatMatchingValues.length}))`,
                 }"
              v-for="(values, index) in formatMatchingValues"
              :key="index + 'col'"
            >
              <div
                class="cell-box"
                :style="{ 
                  width: `calc( calc(100% / ${getColNum}))`,
                 }"
                v-for="(value, i) in values"
                :key="i + 'colval'"
              >
                {{ value }}
              </div>
            </div>
          </td>
          <td colspan="2" class="p-0" style="height: 100%;">
            <div class="matching-totales-vertical" style="height: 100%;">
              <div
                class="cell-box"
                :style="{ 
                  height: `calc( calc(100% / ${rowTotales.length}))`,
                 }"
                v-for="(total, index) in rowTotales"
                :key="index + 'totalrow'"
              >
                {{ total }}
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="lastTable">
          <td colspan="2">TOTAL</td>
          <td class="p-0">
            <div class="columns-values">
              <div
                class="cell-box"
                :style="{ width: `calc( calc(100% / ${getColNum}))` }"
                v-for="(total, index) in colTotales"
                :key="index + 'totalcol'"
              >
                {{ total }}
              </div>
            </div>
          </td>
          <td>{{ getSumTotal }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";

//Data
function data() {
  return {};
}

//Methods
function getMatchingValues() {
  if (this.row.length == 0) return;
  if (this.row.length != 0 && this.col.length == 0) return;

  if (this.modulo == "stowage")
    this.$store.state.planificacionestiba.apiStateForm = 1;
  if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 1;

  let ruta = "VisitFilterResult";

  let fileId = this.modulo == "stowage" ? this.ediFileId : this.visitEdiFileId;

  let FilterJson = {
    FilterJson: [
      {
        ColRowJson: [
          {
            col: this.col.filter((item) => item.values.length != 0),
            row: this.row.filter((item) => item.values.length != 0),
          },
        ],
      },
      {
        EdiFileId: fileId,
      },
    ],
  };

  this.$http
    .postJSON(ruta, FilterJson)
    .then((response) => {
      this.matchingValues = response.data.data[0].ResultJson;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if (this.modulo == "stowage")
        this.$store.state.planificacionestiba.apiStateForm = 0;
      if (this.modulo == "visit") this.$store.state.visitas.apiStateForm = 0;
    });
}

// Computeds

function formatMatchingValues() {
  if (this.results.length == 0) return [];
  return this.results;
}
function rowLabels() {
  if (this.rows.length == 0) return "N/A";
  let labels = this.rows.map((item) => {
    return item.alias;
  });

  return labels.join();
}
function colLabels() {
  if (this.cols.length == 0) return "N/A";

  let labels = this.cols.map((item) => {
    return item.alias;
  });

  return labels.join();
}
function rowTotales() {
  let totales = [];

  for (let i = 0; i < this.results.length; i++) {
    let values = this.results[i];
    let count = 0;
    for (let j = 0; j < values.length; j++) {
      count += values[j];
    }
    totales.push(count);
  }

  return totales;
}
function colTotales() {
  let totales = [];
  let arr = this.modal ? [...this.formatMatchingValues] : [...this.colsTotales];
  arr.forEach(sub => {
      sub.forEach((num, index) => {
         if(totales[index]){
            totales[index] += num;
         }else{
            totales[index] = num;
         }
      });
   });

  return totales;
}
function getColNum() {
  if (this.results.length == 0) return 0;

  return this.results[0].length;
}
function getSumTotal() {
  let sum = 0;
  let arr = this.colTotales;
  for (let index = 0; index < arr.length; index++) {
    sum += arr[index];
  }

  return sum;
}
function getOptionName(type, option) {
  switch (type) {
    case "status":
      return option.Status;
    case "carrier":
      return option.ShippingLineCode;
    case "load":
      return option.LoadPortCode;
    case "discharge":
      return option.DischargePortCode;
    case "details":
      return option.TpCargoDetailCode;
    default:
      return "";
  }
}
function getColValues(col) {
  if (!col) return [];

  return col.values;
}

function formatColLabels() {
  let arr = this.cols.map((item) => Object.assign({}, item));
  let colNum = this.getColNum;

  let foundDetails = arr.findIndex((item) => item.type == "details");
  if(foundDetails != -1){
    let values = arr[foundDetails].values;
    if(values.length < colNum) {
      let qty = colNum / arr[foundDetails].values.length;
  
      for (let index = 0; index < qty - 1; index++) {
        arr[foundDetails].values = arr[foundDetails].values.concat(values);
      }
    }
  }
  
  let foundStatus = arr.findIndex((item) => item.type == "status");
  let carriers = arr.findIndex((item) => item.type == "carrier");
  if(carriers != -1 && foundStatus != -1 && arr[foundStatus].values.length == 2){
    let values = arr[carriers].values;
    let qty = 2;
    for (let index = 0; index < qty - 1; index++) {
      arr[carriers].values = arr[carriers].values.concat(values);
    }
  }
  
  return arr;
}
function formatRowLabels() {
  let arr = this.rows.map((item) => Object.assign({}, item));
  let rowNum = this.formatMatchingValues.length;

  let foundDetails = arr.findIndex((item) => item.type == "details");
  if(foundDetails != -1){
    let values = arr[foundDetails].values;
    if(values.length < rowNum) {
      let qty = rowNum / arr[foundDetails].values.length;
  
      for (let index = 0; index < qty - 1; index++) {
        arr[foundDetails].values = arr[foundDetails].values.concat(values);
      }
    }
  }
  
  let foundStatus = arr.findIndex((item) => item.type == "status");
  let carriers = arr.findIndex((item) => item.type == "carrier");
  if(carriers != -1 && foundStatus != -1 && arr[foundStatus].values.length == 2){
    let values = arr[carriers].values;
    let qty = 2;
    for (let index = 0; index < qty - 1; index++) {
      arr[carriers].values = arr[carriers].values.concat(values);
    }
  }
  
  return arr;
}

function getCurrentScale() {
  if (this.formatMatchingValues.length <= 8 || !this.scale) return 1;

  let total = this.formatMatchingValues.length, count = 0;
  while(total > 0){
    total -= 8;
    count++;
  }

  let scale = 1;
  let interval = 0.15;
  for (let index = 0; index < count; index++) {
    scale -= interval;
  }

  return scale;
}

export default {
  name: "matching-table",
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Array,
      default: () => [],
    },
    colsTotales: {
      type: Array,
      default: () => [],
    },
    scale: {
      type: Boolean,
      default: false,
    },
    lastTable: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data,
  methods: {
    getOptionName,
    getColValues,
  },
  computed: {
    formatMatchingValues,
    getColNum,
    rowTotales,
    colTotales,
    rowLabels,
    colLabels,
    getSumTotal,
    formatColLabels,
    formatRowLabels,
    getCurrentScale,
  },
  watch: {},
};
</script>

<style scoped>
/****** TABLA RESULTADOS ******/
.matching-results {
  width: 60%;
  min-height: 100%;
  /* min-width: 60%; */
  /* border: 1px solid #000; */
}
table.match-results {
  border-collapse: collapse;
  /* border-style: hidden; */
  width: 100%;
  /* height: 100%; */
  min-height: 1px;
  height: 1px;
  text-align: center;
}
table.match-results,
table.match-results th,
table.match-results td {
  border: 1px solid #000;
}

table.match-results th {
  background-color: #e0e0e0;
}
table.match-results th,
table.match-results td {
  padding: 4px 5px;
}
.columns-values {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.matching-totales-vertical ´ {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bg-matching-white {
  background-color: #fff !important;
  color: #000;
}
.bg-matching-grey {
  background-color: #e0e0e0;
  color: #000;
}
.cell-box {
  min-height: 20px;
  border: 1px solid #000;
  /* background-color: aqua; */
  padding: 10px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-rotate-labels {
  vertical-align: middle;
  text-align: center;
  width: 30px;
  min-width: 30px;
  font-size: 0.8rem;
}
.text-rotate-labels span {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  font-weight: bold;
}

.rows-values {
  display: flex;
  width: 100%;
}
.row-columns {
  min-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row-values-padding {
  padding: 10px 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>