<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfShipments') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>        
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    v-model="unitMeasureId"
                    :value.sync="unitMeasureId"
                    :options="filterUnitMeasureWeight"
                    required 
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-7 col-xl-7  text-right', input: 'col-sm-10 col-lg-5 col-xl-5 px-0'}"
                    :label="$t('label.loadPort')"
                    v-model="loadPortId"
                    :value.sync="loadPortId"
                    @change="filterLoadPort"
                    :options="portListOptions"
                    required  
                >
                </CSelect>
            </CCol>          
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>

            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            rowSelection="single"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
        >   
            <CRow>
                <CCol sm="12">
                    <CRow class="m-1" >
                        <CCol sm="11" lg="12" xl="12">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-11 col-lg-11 col-xl-11 text-right col-form-label">{{$t('label.selectAll')}}</label>
                                <div class="col-sm-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="changeAll"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">ISO CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.iso_code"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">GROUP TYPE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.group_type"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">WEIGTH</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.weigth"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEALS</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seals"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">STATUS</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.status"
                                    />
                                </div>
                            </div>
                        </CCol> 
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CARRIER CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.shippinglinecode"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CARRIER</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.carrier"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">IMDG CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.imdg_code"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">LOAD PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.pol"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DISCHARGE PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.pod"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DELIVERY PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.delivery_port"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">TRANSHIPMENT PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.transhipment_port"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">VGM CERTIFICATE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.vgm_certificate"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">VGM</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.vgm"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">BOOKING</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.booking"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DEPARTURE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.CodPosition"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">YARD</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Yard"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">PROCEDENCE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Procedence"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DIRECT DISCHARGE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.DirectDischarge"
                                    />
                                </div>
                            </div>
                        </CCol>
                                              
                    </CRow>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="modalColumn = false"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>


<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    
    //Data
    function data() {
        return {
            loadingOverlay: false,
            textSearch:'',
            gridOptions: null,
            rows: [],
            dataBuque:{
                CountryName:'',
                DatePlanning:'',
                VesselFlagRoute:'',
            },
            SizeChanged:50,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            changeAll:true,
            dataPort:[],
            unitMeasureOptions:[],
            loadPortId:null,
            unitMeasureId:null,
            columnsOption:{
                iso_code:true,
                group_type:true,
                pol:true,
                pod:true,
                weigth:true,
                shippinglinecode:true,
                status:true,
                carrier:true,
                imdg_code:true,
                transhipment_port:true,
                delivery_port:true,
                booking:true,
                vgm_certificate:true,
                vgm:true,
                CodPosition:true,
                Seals: true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            nulo:'', 
        }
    }

    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    async function onBtnPdf(){
        try{
            this.loadingOverlay = true; 
            if(this.rows.length !== 0){
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                let computedItems = [];
                let _this = this;
                this.rows.map((item)=>{
                    let weigth = _this.filtroOpciones(item.CargoJson[0].WeigthJson,_this.unitMeasureId);
                    let vgm = _this.filtroOpciones(item.CargoJson[0].VgmJson,_this.unitMeasureId);
                    computedItems.push({
                        IdX: item.IdX,
                        bay: item.BayCode ? item.BayCode : '',
                        StowagePlanningCargoId: item.StowagePlanningCargoId ? item.StowagePlanningCargoId : '',
                        siglas: item.ContainerCode ? item.ContainerCode : '',
                        sizeid: item.CargoJson[0].SizeId ? item.CargoJson[0].SizeId : null,
                        size: item.CargoJson[0].TpCargoDetailCode ? item.CargoJson[0].TpCargoDetailCode : '',
                        statusid: item.CargoJson[0].TpCargoStatusId ? item.CargoJson[0].TpCargoStatusId : null,
                        status: item.CargoJson[0].Status ? item.CargoJson[0].Status : '',
                        iso_code: item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '',
                        group_type: item.CargoJson[0].TpCargoName ? item.CargoJson[0].TpCargoName : '',
                        filtroOptions: _this.unitMeasureId ? _this.unitMeasureId : null,
                        seals: item.CargoJson[0].Seals ? item.CargoJson[0].Seals : '',
                        weigth: formatMilDecimal(Number.parseFloat(weigth).toFixed(2)),
                        shippinglinecode: item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '',
                        carrier: item.CargoJson[0].ShippingLineName ? item.CargoJson[0].ShippingLineName : '',
                        imdg_code: item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '',
                        polid: item.CargoJson[0].LoadPortId ? item.CargoJson[0].LoadPortId : null,
                        pol: item.CargoJson[0].LoadPort ? item.CargoJson[0].LoadPort : '',
                        podid: item.CargoJson[0].DischargePortId ? item.CargoJson[0].DischargePortId : null,
                        pod: item.CargoJson[0].DischargePort ? item.CargoJson[0].DischargePort : '',
                        transhipment_portid: item.CargoJson[0].TranshipmentPortId ? item.CargoJson[0].TranshipmentPortId : null,
                        delivery_port: item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '',
                        transhipment_port: item.CargoJson[0].TranshipmentPort ? item.CargoJson[0].TranshipmentPort : '',
                        booking_number: item.CargoJson[0].BookingNumber ? item.CargoJson[0].BookingNumber : '',
                        vgm_certificate: item.CargoJson[0].VgmCertificate ? item.CargoJson[0].VgmCertificate : '',
                        vgm: formatMilDecimal(Number.parseFloat(vgm).toFixed(2)),
                        CodPosition: item.CodPosition,
                        Procedence: item.Procedence,
                        YardNamePlanning: item.YardNamePlanning, 
                        FgDirect: item.FgDirect? 'YES' : 'NO'
                    })
                })
                await this.getPdf(computedItems, this.$t('label.listOfPlannedShipments'));
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }    
            this.loadingOverlay = false; 
        } catch (error) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }
    }
    async function onBtnExport(valor) {
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0){
            await this.getExcel(rowData,this.$t('label.listOfPlannedShipments'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
    }

    function filterLoadPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('POST', 'StowagePlanningCargoLoadPort-list', {
                LoadPortId: event.target.value,
                EdiFileId: this.EdiFileId,
                StowagePlanningId: (["", null, undefined].includes(this.StowagePlanning)) ? "": this.StowagePlanning 
            },{ root: "CargoListJson"}).then(response => {
                this.rows = response.data.data;
                
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$store.state.planificacionestiba.messageError = err;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
        }
    }
    function getLoadList(Id) {
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'StowagePlanningLoadPort-list', {
            StowagePlanningEdiId: Id,
            StowagePlanningId: (["", null, undefined].includes(this.StowagePlanning)) ? "": this.StowagePlanning , 
        }).then(responseLoadPort => {
            this.dataPort = responseLoadPort.data.data;
            if(this.dataPort.length !== 0){
                this.loadPortId = this.user.BranchJson[0].PortId;
                this.dataPort.value = this.loadPortId;
            }
            
            this.$http.ejecutar('POST', 'StowagePlanningCargoLoadPort-list', {
                LoadPortId: this.loadPortId,
                EdiFileId: Id,
                StowagePlanningId: (["", null, undefined].includes(this.StowagePlanning)) ? "": this.StowagePlanning 
            },{ root: "CargoListJson"}).then(response => {
                this.$http.ejecutar('GET', 'UnitMeasureWeigth-list', {
                }).then(responseWeigth => {
                    this.unitMeasureOptions = responseWeigth.data.data;

                    this.unitMeasureId = this.unitMeasureOptions[0].UnitMeasureId;

                    this.rows = response.data.data;
                    this.nulo = String(response.data.data[0].Json);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                }).catch( err => {
                    this.$store.state.planificacionestiba.messageError = err;
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
            }).catch( err => {
                this.$store.state.planificacionestiba.messageError = err;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
        }).catch( err => {
            this.$store.state.planificacionestiba.messageError = err;
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
        });
    }
    function filtroOpciones(data,value){
        if(value){
            let index = data.map(function(e) {
                return e.UnitMeasureId; 
            }).indexOf(value);
            if(index !== -1){
                return data[index].Value;
            }
            return 0;
        }else if(value === null && data.length !== 0){
            return data[0].Value;
        }
        return 0;
    }
    //computed
    function loadText(){
        if(this.loadPortId !== null){
            let loadtext = '';
            const Index = this.dataPort.map(function(e) {
                return e.PortId; 
            }).indexOf(this.loadPortId);
            
            if(Index > -1){
                loadtext = this.dataPort[Index].PortCode;
            }
            return loadtext;
        }else{  
            return '';
        }
    }
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                //pinned: 'left',
                minWidth: 70,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "bay",
                headerName: "BAY",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                width: 150,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "CodPosition",
                headerName: "DEPARTURE SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 150,
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "siglas",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'gb-cell',
                minWidth: 160,
                //pinned: 'left',
            },
            {
                field: "size",
                headerName: "SIZE (FT)",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
        ]
        if(this.columnsOption.iso_code){
            columnDefs.push({
                field: "iso_code",
                headerName: "ISO CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.Seals){
            columnDefs.push({
                field: "seals",
                headerName: "SEALS", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        }
        if(this.columnsOption.status){
            columnDefs.push({
                field: "status",
                headerName: "STATUS",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.shippinglinecode){
            columnDefs.push({
                field: "shippinglinecode",
                headerName: "CARRIER CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.carrier){
            columnDefs.push({
                field: "carrier",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.imdg_code){
            columnDefs.push({
                field: "imdg_code",
                headerName: "IMDG CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.pol){
            columnDefs.push({
                field: "pol",
                headerName: "LOAD PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.pod){
            columnDefs.push({
                field: "pod",
                headerName: "DISCHARGE PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.delivery_port){
            columnDefs.push({
                field: "delivery_port",
                headerName: "DELIVERY PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.transhipment_port){
            columnDefs.push({
                field: "transhipment_port",
                headerName: "TRANSHIPMENT PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.group_type){
            columnDefs.push({
                field: "group_type",
                headerName: "GROUP TYPE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.weigth){
            columnDefs.push({
                field: "weigth",
                headerName: "WEIGTH"+' ('+this.filterText+')',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
                filter: "agNumberColumnFilter",
            });
        }
        if(this.columnsOption.booking){
            columnDefs.push({
                field: "booking",
                headerName: "BOOKING", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }

        if(this.columnsOption.vgm_certificate){
            columnDefs.push({
                field: "vgm_certificate",
                headerName: "VGM CERTIFICATE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.vgm){
            columnDefs.push({
                field: "vgm",
                headerName: "VGM"+' ('+this.filterText+')', 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
                filter: "agNumberColumnFilter",
            });
        }  
        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        return columnDefs;
    }
    function formatedItems() {
        let computedItems = [];
        let _this = this;
        if(this.rows.length !== 0  && this.nulo!="null"){
            this.rows.map((item,index)=>{
                //Weigth: Weigth  Y VGM
                let weigth = _this.filtroOpciones(item.CargoJson[0].WeigthJson,_this.unitMeasureId);
                let vgm = _this.filtroOpciones(item.CargoJson[0].VgmJson,_this.unitMeasureId);

                computedItems.push({
                    id: index+1,
                    bay: item.BayCode ? item.BayCode : '',
                    StowagePlanningCargoId: item.StowagePlanningCargoId ? item.StowagePlanningCargoId : '',
                    siglas: item.ContainerCode ? item.ContainerCode : '',
                    sizeid: item.CargoJson[0].SizeId ? item.CargoJson[0].SizeId : null,
                    size: item.CargoJson[0].TpCargoDetailCode ? item.CargoJson[0].TpCargoDetailCode : '',
                    statusid: item.CargoJson[0].TpCargoStatusId ? item.CargoJson[0].TpCargoStatusId : null,
                    status: item.CargoJson[0].Status ? item.CargoJson[0].Status : '',
                    iso_code: item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '',
                    group_type: item.CargoJson[0].TpCargoName ? item.CargoJson[0].TpCargoName : '',
                    filtroOptions: _this.unitMeasureId ? _this.unitMeasureId : null,
                    seals: item.CargoJson[0].Seals ? item.CargoJson[0].Seals : '',
                    //weigth: item.CargoJson[0].Weigth ? formatMilDecimal(parseFloat(item.CargoJson[0].Weigth).toFixed(2)) : 0,
                    weigth: weigth,
                    shippinglinecode: item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '',
                    carrier: item.CargoJson[0].ShippingLineName ? item.CargoJson[0].ShippingLineName : '',
                    imdg_code: item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '',
                    polid: item.CargoJson[0].LoadPortId ? item.CargoJson[0].LoadPortId : null,
                    pol: item.CargoJson[0].LoadPort ? item.CargoJson[0].LoadPort : '',
                    podid: item.CargoJson[0].DischargePortId ? item.CargoJson[0].DischargePortId : null,
                    pod: item.CargoJson[0].DischargePort ? item.CargoJson[0].DischargePort : '',
                    transhipment_portid: item.CargoJson[0].TranshipmentPortId ? item.CargoJson[0].TranshipmentPortId : null,
                    delivery_port: item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '',
                    transhipment_port: item.CargoJson[0].TranshipmentPort ? item.CargoJson[0].TranshipmentPort : '',
                    booking_number: item.CargoJson[0].BookingNumber ? item.CargoJson[0].BookingNumber : '',
                    vgm_certificate: item.CargoJson[0].VgmCertificate ? item.CargoJson[0].VgmCertificate : '',
                    vgm: vgm,
                   // vgm: item.CargoJson[0].Vgm ? formatMilDecimal(parseFloat(item.CargoJson[0].Vgm).toFixed(2)) : 0,
                    CodPosition: item.CodPosition,
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }
    function filterText(){
        if(this.unitMeasureId === '' || this.unitMeasureId === null ){
            return 'TON';
        }else{
            let text = '';
            this.unitMeasureOptions.map(async (e) => {
                if(this.unitMeasureId === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function filterUnitMeasureWeight() {
        if (this.unitMeasureOptions.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.unitMeasureOptions.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function portListOptions() {
        if (this.dataPort.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.dataPort.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortCode,
                });
            });
            return chart;
        }
    }
    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    function dropItem(newQuestion,oldQuestion){
        if(newQuestion === 13){
            this.SizeChanged = 50;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            this.changeAll = true;
            this.unitMeasureId='';

            if(this.EdiFileId !== 0){
                this.getLoadList(this.EdiFileId);
                if(this.ItineraryId !== 0 || this.ItineraryId !== null){
                    const Index = this.myDataItinirary.map(function(e) {
                        return e.ItineraryId; 
                    }).indexOf(this.ItineraryId);
                    
                    if(Index > -1){
                        this.dataBuque.DatePlanning = this.myDataItinirary[Index].DatePlanning;
                        this.dataBuque.VesselRoute = `${this.$store.getters["connection/getBase"]}${this.myDataItinirary[Index].VesselRoute}`;
                    }
                }
            }
        }else if(oldQuestion === 13){
            this.SizeChanged = 50;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            this.unitMeasureId='';
        }
    }
    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }

    function changeAll(newQuestion){
        this.columnsOption.iso_code=newQuestion;
        this.columnsOption.group_type=newQuestion;
        this.columnsOption.pol=newQuestion;
        this.columnsOption.pod=newQuestion;
        this.columnsOption.weigth=newQuestion;
        this.columnsOption.shippinglinecode=newQuestion;
        this.columnsOption.status=newQuestion;

        this.columnsOption.carrier=newQuestion;
        this.columnsOption.imdg_code=newQuestion;
        this.columnsOption.transhipment_port=newQuestion;
        this.columnsOption.delivery_port=newQuestion;
        this.columnsOption.booking=newQuestion;
        this.columnsOption.vgm_certificate=newQuestion;

        this.columnsOption.vgm=newQuestion;
        this.columnsOption.CodPosition=newQuestion;
        this.columnsOption.Seals=newQuestion;
        this.columnsOption.Yard=newQuestion;
        this.columnsOption.Procedence=newQuestion;
        this.columnsOption.DirectDischarge=newQuestion;

    }
    export default {
        name: "load-list",
        data,
        beforeMount,
        methods:{
            getLoadList,
            onBtnPdf,
            onBtnExport,
            onGridReady,
            filtroOpciones,
            filterLoadPort,
        },
        mixins: [Reportes, AgGrid],
        computed:{
            loadText,
            columnDefs,
            formatedItems,
            filterUnitMeasureWeight,
            portListOptions,
            filterText,
            ...mapState({
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                user: state => state.auth.user,
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            })
        },
        watch:{
            changeAll,
            textSearch,
            dropItem,
            SizeChanged,
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>