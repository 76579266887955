<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ Recognition ? `${$t('label.nuevo')} ${$t('label.ContainerPositioning')}: ${PositionItem.ContainerCode}` : 
            isEdit ? `${$t('label.edit')} ${$t('label.ContainerPositioning')}  ${isOfBlProfile ? `: ${dataContainer.ContainerCode}` : ''}` 
            : `${$t('label.nuevo')} ${$t('label.ContainerPositioning')} ${isOfBlProfile ? `: ${dataContainer.ContainerCode}` : ''}`}} 
        </h6>
      </CCol>
    </CRow>
      <CRow class="justify-content-center mt-3 px-3">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.basicData')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6" v-if="!isEdit && !ValidateDispached && !Recognition">
                    <div class="form-group row">
                      <CCol sm="12" lg="4" class="px-0">
                          <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-0">{{$t('label.isItOutOfTime')}}</label>
                      </CCol>
                      <label class="col-sm-auto text-right pt-1">NO</label>
                      <div class="col-sm-auto d-flex align-items-center px-0">
                          <CSwitch
                              color="watch"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="misTime"
                              @change.native="ChangeTime()"
                              :disabled="IsDisabled"
                          />
                      </div>
                      <label class="col-sm-auto text-right pt-1">{{ `${this.$t('label.yes')}`}}</label>
                    </div>
                  </CCol>
                  <CCol sm="0" lg="6" v-if="!isEdit && !ValidateDispached && !Recognition">
                  </CCol>
                  <CCol sm="12" lg="6" v-if="!Recognition">
                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.previousPosition')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.PreviousPosition.$model"
                      :is-valid="hasError($v.PreviousPosition)"
                      :disabled="true"
                    >
                      <template #append>
                        <CButton
                          v-if="OversizedPreviousPosition.length!=0"
                          shape="square"
                          color="add"
                          square
                          size="sm"
                          style="padding: 0.15rem 0.4rem;"
                          @click="PreviousPositionCollapse=!PreviousPositionCollapse"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="cil-playlist-add"/>
                        </CButton>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.EventReasonId.$model"
                      :options="EventOptions"
                      :label="$t('label.motive')"
                      addLabelClasses="required text-right"
                      :horizontal="{
                        label: !Recognition ? 'col-sm-12 col-lg-2' : 'col-sm-12 col-lg-4', 
                        input: !Recognition ? 'col-sm-12 col-lg-10' : 'col-sm-12 col-lg-8'
                      }"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.EventReasonId)"
                      :invalid-feedback="errorMessage($v.EventReasonId)"
                      @change="getEventService"
                      :disabled="IsDisabled"
                    />
                  </CCol>
                  <CCollapse :show="PreviousPositionCollapse">
                    <CCard>
                      <CCardHeader class="text-center dark text-white">
                        <CRow>
                            <CCol sm="11" class="d-flex ">{{$t('label.OversizedMovement')}}</CCol>
                            <CCol sm="1" class="d-flex justify-content-end">
                              <button type="button" aria-label="Close" class="close" @click="PreviousPositionCollapse=false" :disabled="IsDisabled">×</button>
                            </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCardBody>
                        <CCol sm="12" class="row m-0">
                          <div v-for="(item) in OversizedPreviousPosition" class="col-sm-6 col-lg-4">
                            <b><label class="text-left mb-0">{{item.SlotCode}}</label></b><br>
                            <label  class="text-left mb-0 mr-2">({{ item[`YardSlotStatusName${($i18n.locale).charAt(0).toUpperCase() + ($i18n.locale).slice(1)}`] }})</label>
                          </div>
                        </CCol>
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                  <CCol sm="12" lg="6" v-if="!Recognition">
                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.CurrentPosition')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.CurrentPosition.$model"
                      :is-valid="hasError($v.CurrentPosition)"
                      :disabled="true"
                    >
                      <template #append>
                        <CButton
                          v-if="OversizedCurrentPosition.length!=0"
                          shape="square"
                          color="add"
                          square
                          size="sm"
                          style="padding: 0.15rem 0.4rem;"
                          @click="CurrentPositionCollapse=!CurrentPositionCollapse"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="cil-playlist-add"/>
                        </CButton>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.EventServiceId.$model"
                      :options="ServiceOptions"
                      :label="$t('label.service')"
                      :addLabelClasses="EventServiceList.length!=0 ? 'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.EventServiceId)"
                      :invalid-feedback="errorMessage($v.EventServiceId)"
                      :disabled="EventServiceList.length==0 || IsDisabled"
                    />
                  </CCol>
                  <CCollapse :show="CurrentPositionCollapse" class="col-sm-12">
                    <CCard>
                      <CCardHeader class="text-center dark text-white">
                        <CRow>
                            <CCol sm="11" class="d-flex ">{{$t('label.OversizedMovement')}}</CCol>
                            <CCol sm="1" class="d-flex justify-content-end">
                              <button type="button" aria-label="Close" class="close" @click="CurrentPositionCollapse=false" :disabled="IsDisabled">×</button>
                            </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCardBody>
                        <CCol sm="12" class="row m-0">
                          <div v-for="(item) in OversizedCurrentPosition" class="col-sm-6 col-lg-4">
                            <b><label class="text-left mb-0">{{item.SlotCode}}</label></b>
                            <label  class="text-left mb-0 mr-2">({{ item[`YardSlotStatusName${($i18n.locale).charAt(0).toUpperCase() + ($i18n.locale).slice(1)}`] }})</label>
                          </div>
                        </CCol>
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.MachineId.$model"
                      :options="MachineOptions"
                      :label="$t('label.machine')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.MachineId)"
                      :invalid-feedback="errorMessage($v.MachineId)"
                      :disabled="IsDisabled"
                    />

                    <CTextarea
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                      :disabled="IsDisabled"
                    />
                    
                    <div class="form-group form-row mb-3" v-if="!misTime">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.PositioningDate')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.TransactionDate.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                          :disabled="IsDisabled"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.TransactionDate)"
                              size="sm"        
                              :disabled="IsDisabled"              
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <div class="form-group form-row mb-3" v-if="misTime">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.from')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.fromDay.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                          :disabled="IsDisabled"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="fromDay"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.fromDay)"
                              size="sm"              
                              :disabled="IsDisabled"        
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <div class="form-group form-row mb-3" v-if="misTime">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.to')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.toDay.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                          :disabled="IsDisabled"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="toDay"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.toDay)"
                              size="sm" 
                              :disabled="IsDisabled"                     
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <CSelect
                      v-if="isEdit"
                      size="sm"
                      :options="statusOptions"
                      :value.sync="Status"
                      :label="$t('label.status')"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                      :disabled="IsDisabled"
                    />
                    <CRow v-if="(!Continue && misTime && !this.loadingEdit)">
                      <CCol sm="12" lg="12" class="d-flex justify-content-end">
                        <CButton
                          color="watch"
                          size="sm"
                          :disabled="isSubmit || IsDisabled"
                          @click="isContinue()"
                        >
                          <CIcon name="continue" />&nbsp; {{$t('label.Continue')}}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CRow v-show="(Continue && misTime) || !misTime">
                      <CCol sm="12" lg="6">
                        <CInput
                          size="sm"
                          v-uppercase
                          :label="$t('label.yard')"
                          addLabelClasses="text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 pl-2'}"
                          :is-valid="hasError($v.YardName)"
                          :placeholder="$t('label.select')"
                          v-model="$v.YardName.$model"
                          :disabled="true"
                        >
                        </CInput>
                      </CCol>

                      <CCol sm="12" lg="6">
                        <CSelect
                          size="sm"
                          :label="$t('label.type')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 pl-2'}"
                          :is-valid="hasError($v.BlockOrArea)"
                          :placeholder="$t('label.select')"
                          v-model="$v.BlockOrArea.$model"
                          :options="TypeOptions"
                          @change="getBlockOrArea"
                          :disabled="IsDisabled"
                          
                        />
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12" v-if="BlockOrArea===1">
                        <CSelect
                          size="sm"
                          :label="$t('label.Area')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                          :is-valid="hasError($v.AreaId)"
                          :placeholder="$t('label.select')"
                          v-model="$v.AreaId.$model"
                          :options="AreaOptions"
                          :disabled="IsDisabled"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="BlockOrArea===0">
                        <CSelect
                          size="sm"
                          :label="$t('label.block')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 pl-2'}"
                          :is-valid="hasError($v.Block)"
                          :placeholder="$t('label.select')"
                          v-model="$v.Block.$model"
                          :options="BlockOptions"
                          @change="getBay"
                          :disabled="BlockList.length==0 || IsDisabled"
                        />
                      </CCol>

                      <CCol sm="12" lg="6" v-if="BlockOrArea===0">
                        <CSelect
                          size="sm"
                          :label="$t('label.bay')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 pl-2'}"
                          :is-valid="hasError($v.Bay)"
                          :placeholder="$t('label.select')"
                          v-model="$v.Bay.$model"
                          :options="BayOptions"
                          @change="getLevel"
                          :disabled="BayList.length==0 || IsDisabled"
                        />
                      </CCol>

                      <CCol sm="12" lg="6" v-if="BlockOrArea===0">
                        <CSelect
                          size="sm"
                          :label="$t('label.level')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 pl-2'}"
                          :is-valid="hasError($v.Level)"
                          :placeholder="$t('label.select')"
                          v-model="$v.Level.$model"
                          :options="LevelOptions"
                          @change="getPosition"
                          :disabled="LevelList.length==0 || IsDisabled"
                        />
                      </CCol>

                      <CCol sm="12" lg="12" v-if="BlockOrArea===0">
                        <CSelect
                          size="sm"
                          :label="$t('label.positionContainer')"
                          addLabelClasses="required text-right px-0 text-size-11"
                          :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                          :is-valid="hasError($v.Position)"
                          :placeholder="$t('label.select')"
                          v-model="$v.Position.$model"
                          :options="PositionOptions"
                          @change="getAuxiliary"
                          :disabled="PositionList.length==0 || IsDisabled"
                        />
                      </CCol>

                      <CCol sm="12" lg="12" v-if="BlockOrArea===0 && this.ContainerSize">
                        <CSelect
                          size="sm"
                          :label="$t('label.Auxiliary')"
                          addLabelClasses="required text-right"
                          :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                          :is-valid="hasError($v.Auxiliary)"
                          :placeholder="$t('label.select')"
                          v-model="$v.Auxiliary.$model"
                          :options="AuxiliaryOptions"
                          :disabled="AuxiliaryList.length==0 || IsDisabled"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.inspectionSeals')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal1.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal1.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal1.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal1, 1)"
                          :disabled="IsDisabled"
                        />
                        <CButton
                          v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal2.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal2.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal2.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal2, 2)"
                          :disabled="IsDisabled"
                        />
                        <CButton
                          v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal3.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal3.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal3.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal3, 3)"
                          :disabled="IsDisabled"
                        />
                        <CButton
                          v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal4.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal4.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal4.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal4, 4)"
                          :disabled="IsDisabled"
                        />
                        <CButton
                          v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="IsDisabled"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" class="text-invalid-feedback text-center" 
                    v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                  >
                    <label>{{ $t('label.AtLeastSealRequired') }}</label>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="((misTime && Continue) || !misTime) && !IsDisabled">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="statusConfirmation(PositionItem.FgActYardCargoEventDetail ? 1 : 0, Status, Submit)"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        </CCol>
      </CRow>
      <ModalSeal
        :modal="ModalSealActive"
        :SealJson="SealJson"
        :isEdit="isEdit"
        @CloseModal="(ModalSealActive=false, SealJson={})"
        @UpdateSeal="UpdateSeal"
        @UpdateRegister="UpdateRegister"
      />
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import positioningRegisterValidations from '@/_validations/yard-management/container/positioningRegisterValidations';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import ModalSeal from '@/pages/yard-management/modal-seal';
import moment from 'moment';

function data() {
  return {
    IsDisabled: false,
    loadingEdit: false,
    Continue: false,
    misTime: false,
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    PreviousPositionCollapse: false,
    CurrentPositionCollapse: false,
    SealJson: {},
    EventReasonId: '',
    EventList: [],
    EventServiceId: '',
    EventServiceList: [],
    PreviousPosition: '',
    OversizedPreviousPosition: [],
    CurrentPosition: '',
    OversizedCurrentPosition: [],
    MachineId: '',
    MachineList: [],
    TransactionDate: '',
    fromDay: '',
    toDay: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    YardName: '',
    BlockOrArea: '',
    AreaId: '',
    AreaList: [],
    Block: '',
    BlockList: [],
    Bay: '',
    BayList: [],
    Level: '',
    LevelList: [],
    Position: '',
    PositionList: [],
    Auxiliary: '',
    AuxiliaryList: [],
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Status: 0,
    CargoJson: {},
  };
}

async function getYardAreaList() {
  await this.$http.get('YardAreaApplyContainer-list', { YardId: this.dataContainer?.YardId ?? this.PositionItem.YardId ?? '' })
    .then(response => {
      let List = response.data.data;
      this.AreaList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardAvailablePosition(loading) {
  this.$store.state.yardManagement.loading = true;
  let route = 'YardAvailablePositionYardCargoEvent-list';
  await this.$http.get(route, { YardId: this.dataContainer?.YardId ?? this.PositionItem.YardId ?? '', YardCargoId: this.dataContainer.YardCargoId })
    .then(response => {
      let List = response.data.data[0];
      this.BlockList = List&&List?.YardDetailJson[0]?.BlockJson?.length!=0 ? 
        List?.YardDetailJson[0]?.BlockJson : [];
      if (this.isEdit) {
        let BlockData = {};
        let BayData = {};
        let LevelData = {};
        let PositionData = {};
        let YardSlotId = this.PositionItem?.YardNewDetailPosJson[0]?.YardSlotId;
        BlockData = this.BlockList.find(item =>{ 
          BayData = item.BayJson.find(Bay =>{
            LevelData = Bay.HeightJson.find(Level =>{
              PositionData = Level.PositionJson.find(Position =>
                Position.YardSlotId==YardSlotId
              )
              return PositionData
            })
            return LevelData;
          })
          return BayData;
        });
        this.Block = BlockData?.YardBlockId ?? '';
        this.Bay = BayData?.BayCode ?? '';
        this.BayList = BlockData?.BayJson ?? [];
        this.Level = LevelData?.YardHeightId ?? '';
        this.LevelList = BayData?.HeightJson ?? [];
        this.Position = PositionData?.YardSlotId ?? '';
        this.PositionList = LevelData?.PositionJson ?? [];
        if (this.ContainerSize) {
          this.Auxiliary = this.PositionItem.YardNewDetailPosJson[1].YardSlotId;
          let LeftRightJson = PositionData?.LeftRightJson && PositionData.LeftRightJson.length != 0 ? 
            PositionData.LeftRightJson[0] : '';
          this.AuxiliaryList = LeftRightJson ? LeftRightJson.LeftJson.concat(LeftRightJson.RightJson) : [];

        }
      }
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(()=>{
      this.$store.state.yardManagement.loading = loading;
    });
}

async function getYardAvailablePositionMisTime(loading) {
  this.$store.state.yardManagement.loading = true;
  let route = 'YardAvailablePositionTransaction-list';
  let dateStart = this.fromDay ? DateFormater.formatDateTimeWithoutSlash(this.fromDay) : '';
  let dateEnd= this.toDay ? DateFormater.formatDateTimeWithoutSlash(this.toDay) : '';

  let PositionJson = JSON.stringify({ 
    PositionJson:[ 
        {
          YardCargoEventDetailId: this.isEdit ? this.PositionItem?.YardCargoEventDetailId : '',
          YardId: this.dataContainer?.YardId ?? this.PositionItem.YardId ?? '',
          DateStart : dateStart,
          DateEnd : dateEnd,
        }
      ] 
    });
  
  await this.$http.get(route, {PositionJson: PositionJson})
    .then(response => {
      let List = response.data.data[0];
      this.BlockList = List&&List?.YardDetailJson[0]?.BlockJson?.length!=0 ? 
        List?.YardDetailJson[0]?.BlockJson : [];
      if (this.isEdit && this.loadingEdit) {
        let BlockData = {};
        let BayData = {};
        let LevelData = {};
        let PositionData = {};
        let YardSlotId = this.PositionItem?.YardNewDetailPosJson[0]?.YardSlotId;
        BlockData = this.BlockList.find(item =>{ 
          
          BayData = item.BayJson.find(Bay =>{
            LevelData = Bay.HeightJson.find(Level =>{
              PositionData = Level.PositionJson.find(Position =>
                Position.YardSlotId==YardSlotId
              )
              return PositionData
            })
            return LevelData;
          })
          return BayData;
        });
        
        this.Block = BlockData?.YardBlockId ?? '';
        this.Bay = BayData?.BayCode ?? '';
        this.BayList = BlockData?.BayJson ?? [];
        this.Level = LevelData?.YardHeightId ?? '';
        this.LevelList = BayData?.HeightJson ?? [];
        this.Position = PositionData?.YardSlotId ?? '';
        this.PositionList = LevelData?.PositionJson ?? [];
        if (this.ContainerSize) {
          this.Auxiliary = this.PositionItem.YardNewDetailPosJson[1].YardSlotId;
          let LeftRightJson = PositionData?.LeftRightJson && PositionData.LeftRightJson.length != 0 ? 
            PositionData.LeftRightJson[0] : '';
          this.AuxiliaryList = LeftRightJson ? LeftRightJson.LeftJson.concat(LeftRightJson.RightJson) : [];

        }
      }
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(()=>{
      this.$store.state.yardManagement.loading = loading;
    });
}

async function getSealConditionList() {
  await this.$http.get('SealCondition-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardMachine() {
  let TpCargoDetailId = this.Recognition ? this.PositionItem.TpCargoDetailId : this.dataContainer.TpCargoDetailId;
  let YardId = this.Recognition ? this.PositionItem.YardId : this.dataContainer.YardId;
  await this.$http.get('YardMachine-by-YardId-TpCargoDetailId', { YardId: YardId, CompanyBranchId: this.CompanyBranchId, TpCargoDetailId: TpCargoDetailId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardEventReasonList() {
  let Parameter = {
    EventId: process.env.VUE_APP_EVENT_POSITION,
    CargoId: !this.Recognition ? this.dataContainer.CargoId : this.PositionItem.CargoId,
    YardId: this.dataContainer?.YardId ?? this.PositionItem.YardId ?? '',
  };
  await this.$http.get('YardEventReason-list', Parameter)
    .then(response => {
      let List = response.data.data;
      this.EventList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function isContinue() {
  try{
    this.$v.fromDay.$touch();
    this.$v.toDay.$touch()
    if (this.$v.fromDay.$error || this.$v.toDay.$error){
      if (!this.fromDay) 
        throw this.$t('label.TheDateFromIsRequired');

      if (!this.toDay) 
        throw this.$t('label.TheDateToIsRequired');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate) 
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate) 
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm") || DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
        throw this.$t('label.ErrorDispatchDatePleaseCheck');

      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay)) 
        throw this.$t('label.ErrorFromToPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
        throw this.$t('label.ErrorToFromPleaseCheck');
    }

    this.Continue = true;
    this.$v.$reset();
    this.getYardAvailablePositionMisTime(false);
  }
  catch(e){
    
    this.notifyError({text: e});
  }
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
        throw this.$t('label.ErrorDispatchDatePleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let CargoJson = {
      YardAreaId: this.AreaId,
      YardCargoId: this.Recognition ? this.PositionItem?.YardCargoId : this.dataContainer?.YardCargoId,
      YardBlockId: this.Block,
      YardSlotId: this.Position,
      YardSlotAuxId: this.Auxiliary,
      EventServiceId: this.EventServiceId,
      EventReasonId: this.EventReasonId,
      MachineId: this.MachineId,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
        this.Seal.Seal1.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
        this.Seal.Seal2.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
        this.Seal.Seal3.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
        this.Seal.Seal4.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      TransactionBegin: this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.fromDay) : undefined, 
      TransactionFinish: this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.toDay) : undefined,
      TransactionDate: !this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) : undefined,
      Observation: this.Observation,
    }

    if (this.isEdit) {
      CargoJson.YardCargoEventDetailId = this.PositionItem.YardCargoEventDetailId;
      CargoJson.VisitCargoInspectionId = this.PositionItem?.SealJson[0]?.VisitCargoInspectionId ?? '';
      CargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardCargoPositioning-update' : 'YardCargoPositioning';

    this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list", this.PositionItem);
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function UpdateRegister(images, index) {
  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images;
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images;
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images;
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images;
      break;
  }
 
  await this.$http.put('YardCargoDispatch-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await this.$emit("Update-list");
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}

function getEventService(event) {
  this.EventReasonId = event.target.value;
  this.EventServiceId = '';
  this.EventServiceList = [];
  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData.EventServiceJson : [];
  }
}

function getBlockOrArea(event) {
  this.BlockOrArea = event.target.value ? Number.parseFloat(event.target.value) : '';
  this.AreaId = '';
  this.Block = '';
  this.Bay = '';
  this.BayList = '';
  this.Level = '';
  this.LevelList = [];
  this.Position = '';
  this.PositionList = [];
  this.AuxiliaryList = [];
}

function getBay(event) {
  this.Block = event.target.value;
  this.Bay = '';
  this.BayList = [];
  this.Level = '';
  this.LevelList = [];
  this.Position = '';
  this.PositionList = [];
  this.Auxiliary = '';
  this.AuxiliaryList = [];
  if (this.Block) {
    let BlockData = this.BlockList.find(item => item.YardBlockId == this.Block);
    this.BayList = BlockData?.BayJson && BlockData.BayJson.length != 0 ? BlockData.BayJson : [];
  }
}

function getLevel(event) {
  this.Bay = event.target.value;
  this.Level = '';
  this.LevelList = [];
  this.Position = '';
  this.PositionList = [];
  this.Auxiliary = '';
  this.AuxiliaryList = [];
  if (this.Bay) {
    let BayData = this.BayList.find(item => item.BayCode == this.Bay);
    this.LevelList = BayData?.HeightJson && BayData.HeightJson.length != 0 ? BayData.HeightJson : [];
  }
}

function getPosition(event) {
  this.Level = event.target.value;
  this.Position = '';
  this.PositionList = [];
  this.Auxiliary = '';
  this.AuxiliaryList = [];
  if (this.Level) {
    let LevelData = this.LevelList.find(item => item.YardHeightId == this.Level);
    this.PositionList = LevelData?.PositionJson && LevelData.PositionJson.length != 0 ? LevelData.PositionJson : [];
  }
}

function getAuxiliary(event) {
  this.Position = event.target.value;
  this.Auxiliary = '';
  this.AuxiliaryList = [];
  if (this.ContainerSize) {
    let PositionData = this.PositionList.find(item => item.YardSlotId == this.Position);
    let LeftRightJson = PositionData?.LeftRightJson && PositionData.LeftRightJson.length != 0 ? 
      PositionData.LeftRightJson[0] : '';
    this.AuxiliaryList = LeftRightJson ? LeftRightJson.LeftJson.concat(LeftRightJson.RightJson) : [];
  }
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function validateDateInitRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) 
    return true;
  
  return false;
}

function validateDateFinalRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
    return true;
  
  return false;
}

function getPositionJson(PositionJson, Nominal) {
  if (PositionJson) {
    if (Nominal) {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS==PreviousDetail.YardSlotStatus?.toUpperCase())
    } else {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS!=PreviousDetail.YardSlotStatus?.toUpperCase())
    }
  }else{
    return [];
  }
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function ChangeTime() {

  if(!this.misTime){
    this.fromDay = '';
    this.toDay = '';
    this.Continue = false;
    this.getYardAvailablePosition(false);
    this.$v.$reset();
  }

  else{
    this.TransactionDate = ''
    this.$v.$reset();
  }

  this.BlockOrArea = '';
  this.AreaId = '';
  this.Block = '';
  this.BlockList = [];
  this.Bay = '';
  this.Level = '';
  this.LevelList = [];
  this.Position = '';
  this.PositionList = [];
  this.AuxiliaryList = [];
}

function getNewData() {
  if (this.Recognition) {
    //PRECINTO 1
    this.Seal.Seal1.CurrentSeal = this.PositionItem?.SealJson[0]?.Seal1 ?? '';
    this.Seal.Seal1.Seal = this.PositionItem?.SealJson[0]?.Seal1 ?? '';
    this.Seal.Seal1.ConditionSeal = this.PositionItem?.SealJson[0]?.Seal1 ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    //PRECINTO 2
    this.Seal.Seal2.CurrentSeal = this.PositionItem?.SealJson[0]?.Seal2 ?? '';
    this.Seal.Seal2.Seal = this.PositionItem?.SealJson[0]?.Seal2 ?? '';
    this.Seal.Seal2.ConditionSeal = this.PositionItem?.SealJson[0]?.Seal2 ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    //PRECINTO 3
    this.Seal.Seal3.CurrentSeal = this.PositionItem?.SealJson[0]?.Seal3 ?? '';
    this.Seal.Seal3.Seal = this.PositionItem?.SealJson[0]?.Seal3 ?? '';
    this.Seal.Seal3.ConditionSeal = this.PositionItem?.SealJson[0]?.Seal3 ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    //PRECINTO 4
    this.Seal.Seal4.CurrentSeal = this.PositionItem?.SealJson[0]?.Seal4 ?? '';
    this.Seal.Seal4.Seal = this.PositionItem?.SealJson[0]?.Seal4 ?? '';
    this.Seal.Seal4.ConditionSeal = this.PositionItem?.SealJson[0]?.Seal4 ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  } else {
    this.PreviousPosition = this.getPositionJson(this.dataContainer?.PreviousPositionJson[0]?.YardPreviousDetailPosJson, true)
      .map(PreviousDetail => {return PreviousDetail.SlotCode.trim()}).join(', ');
    this.OversizedPreviousPosition = this.getPositionJson(this.dataContainer?.PreviousPositionJson[0]?.YardPreviousDetailPosJson, false)
    this.CurrentPosition = this.getPositionJson(this.dataContainer?.CurrentPositionJson, true)
      .map(CurrentPosition => {return CurrentPosition.SlotCode.trim()}).join(', ');
    this.OversizedCurrentPosition = this.getPositionJson(this.dataContainer?.CurrentPositionJson, false);

    //PRECINTO 1
    this.Seal.Seal1.CurrentSeal = this.dataContainer?.Seal1 ?? '';
    this.Seal.Seal1.Seal = this.dataContainer?.Seal1 ?? '';
    this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal1.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 2
    this.Seal.Seal2.CurrentSeal = this.dataContainer?.Seal2 ?? '';
    this.Seal.Seal2.Seal = this.dataContainer?.Seal2 ?? '';
    this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal2.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 3
    this.Seal.Seal3.CurrentSeal = this.dataContainer?.Seal3 ?? '';
    this.Seal.Seal3.Seal = this.dataContainer?.Seal3 ?? '';
    this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal3.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 4
    this.Seal.Seal4.CurrentSeal = this.dataContainer?.Seal4 ?? '';
    this.Seal.Seal4.Seal = this.dataContainer?.Seal4 ?? '';
    this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal4.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];
  }
  this.$v.$reset();
}

async function getdata(val) {
  this.loadingEdit = true;

  if(val.TransactionFinish != null){
    
    this.Continue = true;
    this.misTime = true; 
    this.fromDay = DateFormater.formatDateTimeWithSlash(val.TransactionBegin);
    this.toDay = DateFormater.formatDateTimeWithSlash(val.TransactionFinish);
  } 
  else
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(val.TransactionDate);
  
  this.PreviousPosition = this.getPositionJson(val?.YardPreviousDetailPosJson, true)
    .map(PreviousDetail => {return PreviousDetail.SlotCode.trim()}).join(', ');
  this.OversizedPreviousPosition = this.getPositionJson(val?.YardPreviousDetailPosJson, false);
  this.CurrentPosition = this.getPositionJson(val.YardNewDetailPosJson, true)
    .map(NewDetailPos => {return NewDetailPos.SlotCode.trim()}).join(', ');
  this.OversizedCurrentPosition = this.getPositionJson(val.YardNewDetailPosJson, false);

  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.NewSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.NewSeal =val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.EventReasonId = val.EventReasonId ?? '';
  this.EventServiceId = val.EventServiceId ?? '';
  this.MachineId = val.MachineId ?? '';
  this.Observation = val.Observation ?? '';
  this.AreaId = val.YardAreaId ?? '';
  this.BlockOrArea = val.YardAreaId ? 1 : 0;
  this.Status = val.FgActYardCargoEventDetail ? 1 : 0;
  if (this.BlockOrArea != 1) {
    this.misTime ? await this.getYardAvailablePositionMisTime(true) : await this.getYardAvailablePosition(true);
  }
  this.CargoJson = {
    YardCargoEventDetailId: this.PositionItem.YardCargoEventDetailId,
    YardAreaId: this.AreaId,
    YardCargoId: this.Recognition ? this.PositionItem?.YardCargoId : this.dataContainer?.YardCargoId,
    YardBlockId: this.Block,
    YardSlotId: this.Position,
    YardSlotAuxId: this.Auxiliary,
    EventServiceId: this.EventServiceId,
    EventReasonId: this.EventReasonId,
    MachineId: this.MachineId,
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal.Seal1.SealConditionId,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.Seal.Seal1.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2.SealConditionId,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.Seal.Seal2.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3.SealConditionId,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.Seal.Seal3.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4.SealConditionId,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.Seal.Seal4.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    TransactionDate: val.TransactionDate,
    Observation: this.Observation,
    VisitCargoInspectionId: this.PositionItem?.SealJson[0]?.VisitCargoInspectionId ?? '',
    Status: this.Status,
  }

  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData?.EventServiceJson : [];
  }

  this.Validate = true;
  this.$v.$touch();
  this.loadingEdit = false;
}

function getDateRange() {
  this.previousDate = !this.Recognition ? DateFormater.formatDateRange(this.dataContainer.ReceptionDate) :
    DateFormater.formatDateRange(this.PositionItem.ReceptionDate);
  this.laterDate = new Date();
  this.ValidPreviousDate = !this.Recognition ?
    DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ReceptionDate)) :
    DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.PositionItem.ReceptionDate));
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function statusSelectColor() {
  return this.Status === 1;
}

function Reset() {
  this.misTime = false;
  this.fromDay = '';
  this.toDay = '';
  this.EventReasonId = '',
  this.EventList = [],
  this.EventServiceId = '',
  this.EventServiceList = [],
  this.PreviousPosition = '',
  this.OversizedPreviousPosition = [];
  this.CurrentPosition = '',
  this.OversizedCurrentPosition = [];
  this.TransactionDate = '',
  this.ValidPreviousDate = '',
  this.ValidLaterDate = '',
  this.previousDate = '',
  this.laterDate = '',
  this.Observation = '',
  this.BlockOrArea = '',
  this.YardName = '';
  this.MachineId = '';
  this.MachineList = [];
  this.AreaId = '';
  this.AreaList = [];
  this.Block = '';
  this.BlockList = [];
  this.Bay = '';
  this.BayList = [];
  this.Level = '';
  this.LevelList = [];
  this.Position = '';
  this.PositionList = [];
  this.Auxiliary = '';
  this.AuxiliaryList = [];
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.ConditionList = [];
  this.Status = 0;
  this.CargoJson = {};
  this.Validate = false;
  this.$v.$reset();
  this.$emit("Close");
}

//Computeds:
function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventReasonId,
        label: e[`EventReasonName${_lang}`],
      })
    })
    return chart;
  }
}

function ServiceOptions() {
  if(this.EventServiceList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventServiceList.map(function(e){
      chart.push({
        value: e.EventId,
        label: e[`EventName${_lang}`],
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: e[`SealConditionName${_lang}`],
      })
    })
    return chart;
  }
}

function TypeOptions() {
  return [
    {
      value: '',
      label: this.$t('label.select'),
    },
    {
      value: 0,
      label: this.$t('label.block'),
    },
    {
      value: 1, 
      label: this.$t('label.Area'),
    }
  ];
}

function AreaOptions() {
  if(this.AreaList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.AreaList.map(function(e){
      chart.push({
        value: e.YardAreaId,
        label: `${e[`TpYardAreaName${_lang}`]} (${e.YardAreaName})`,
      })
    })
    return chart;
  }
}

function BlockOptions() {
  if(this.BlockList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.BlockList.map(function(e){
      chart.push({
        value: e.YardBlockId,
        label: e.BlockAlias,
      })
    })
    return chart;
  }
}

function BayOptions() {
  if(this.BayList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.BayList.map(function(e){
      chart.push({
        value: e.BayCode,
        label: e.BayCode,
      })
    })
    return chart;
  }
}

function LevelOptions() {
  if(this.LevelList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.LevelList.map(function(e){
      chart.push({
        value: e.YardHeightId,
        label: e.HeightCode,
      })
    })
    return chart;
  }
}

function PositionOptions() {
  if(this.PositionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.PositionList.map(function(e){
      chart.push({
        value: e.YardSlotId,
        label: e.SlotCode,
      })
    })
    return chart;
  }
}

function AuxiliaryOptions() {
  if(this.AuxiliaryList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.AuxiliaryList.map(function(e){
      chart.push({
        value: e.YardSlotId,
        label: e.SlotCode,
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

function ValidateDispached() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED==this.YardCargoStatus?.toUpperCase();
}

function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

function ContainerSize() {
  return this.Recognition ? this.PositionItem?.Size != 20  : this.dataContainer?.Size != 20;
}

export default {
  name: 'positioning-register',
  components: {
    ModalSeal,
   },
  props: { 
    Active: Boolean, 
    Recognition: Boolean, 
    isEdit: Boolean, 
    PositionItem: Object,
    isOfBlProfile: {
        type: Boolean,
        required: false,
        default: () => false,
    },  
  },
  data,
  mixins: [
    ModalMixin,
  ],
  validations(){
    return positioningRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate,
      moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm") ,this.EventServiceList.length!=0, 
      this.BlockOrArea, this.ContainerSize, this.fromDay, this.toDay, this.misTime);
  },
  directives: UpperCase,
  methods: {
    getYardAreaList,
    getYardAvailablePosition,
    getSealConditionList,
    getYardMachine,
    getYardEventReasonList,
    Submit,
    UpdateRegister,
    getEventService,
    getBlockOrArea,
    getBay,
    getLevel,
    getPosition,
    getAuxiliary,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    getPositionJson,
    UpdateSeal,
    getNewData,
    getdata,
    getDateRange,
    statusSelectColor,
    Reset,
    ChangeTime,
    getYardAvailablePositionMisTime,
    isContinue
  },
  computed: {
    validateDateInitRange,
    validateDateFinalRange,
    MachineOptions,
    EventOptions,
    ServiceOptions,
    ConditionOptions,
    TypeOptions,
    AreaOptions,
    BlockOptions,
    BayOptions,
    LevelOptions,
    PositionOptions,
    AuxiliaryOptions,
    ValidateTpCargoStatus,
    ValidateDispached,
    ContainerSize,
    VerifySeal,
    ...mapState({
      YardId: state => state.yardManagement.yardData.YardId,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      dataContainer: state => state.yardManagement.dataContainer,
      YardCargoStatus: state => state.yardManagement.dataContainer.YardCargoStatus,
      dropContainerMovement: state => state.yardManagement.dropContainerMovement,
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        if(!(this.YardId) || ( this.isEdit && ((this.YardId && (this.YardId != this.PositionItem.YardId)) || (!this.PositionItem?.FgActYardCargoEventDetail))))
          this.IsDisabled = true; 
        else
          this.IsDisabled = false;

        this.$store.state.yardManagement.loading = true;
        this.YardName = this.dataContainer.YardName;
        await this.getDateRange();
        await this.getSealConditionList();
        await this.getYardMachine();
        await this.getYardEventReasonList();
        await this.getYardAreaList();
        if (this.isEdit) {
          await this.getdata(this.PositionItem);
        }else{
          if (this.ValidateDispached) {
            this.Continue = true;
            this.misTime = true;
            await this.getYardAvailablePositionMisTime(true)
          } else {
            await this.getYardAvailablePosition(true);
          }
          await this.getNewData();
        }
        
        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    fromDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit && this.PositionItem.TransactionFinish == null){
            this.BlockOrArea = '';
            this.Continue = false;
          }

          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    toDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit && this.PositionItem.TransactionFinish == null){
            this.BlockOrArea = '';
            this.Continue = false;
          }
          
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) {
            throw this.$t('label.ErrorToFromPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropContainerMovement: function (NewVal, OldVal) {
      if (OldVal == 3) {
        this.Reset();
      }
    }
  },
};
</script>
<style>
.text-size-11{
  font-size: 11px;
}
.card-border{
  padding: 20px;
  background-color: #fff;
  border-color: #c4c9d0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
</style>