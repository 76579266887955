<template>
    <div>
      <CRow class="mb-3">
        <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
            <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${$t('label.EventList')}`}}</h6>
        </CCol>
        <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
            <CButton
                color="watch"
                size="sm"  
                @click="onBtnExport(false)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; CSV
            </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="7">
          <div class="form-group form-row d-flex aling-items-center">
            <label class="col-form-label-sm col-sm-12 col-lg-1 text-right mt-1">{{`${$t('label.date')}`}}</label>
            <div class="input-group col-sm-12 col-lg-4 mb-2">
              <vue-datepicker 
                type="datetime"
                header
                :lang="this.$i18n.locale"
                :editable="false"
                :clearable="false"
                format="DD/MM/YYYY HH:mm"
                placeholder="DD/MM/YYYY HH:mm"
                time-title-format="DD/MM/YYYY HH:mm"
                v-model="searchFilterDate.fromDay"
                :disabled-date="validateDateRange"
                :append-to-body="false"
                value-type="format"
                :show-second="false"
                class="w-100"
              >
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
            <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
            <div class="input-group col-sm-5 col-lg-4 mb-2">
              <vue-datepicker 
                type="datetime"
                header
                :lang="this.$i18n.locale"
                :editable="true"
                :clearable="false"
                format="DD/MM/YYYY HH:mm"
                placeholder="DD/MM/YYYY HH:mm"
                time-title-format="DD/MM/YYYY HH:mm"
                v-model="searchFilterDate.toDay"
                :disabled-date="validateDateRange"
                :append-to-body="false"
                value-type="format"
                :show-second="false"
                class="w-100"
              >
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
            <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto">
              <CButton
                  color="watch"
                  square
                  size="sm"
                  v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                  class="mx-1"
                  @click="(filtre=true, getYardEventDetailList())"
              >
                <CIcon name="cil-search"/>
              </CButton>
              <CButton
                  color="danger"
                  square
                  size="sm"
                  v-c-tooltip="{ content: $t('label.clearFilters'), placement: 'top-end'}"
                  @click="(refreshComponent(), getYardEventDetailList())"
              >
                <CIcon name="cil-brush-alt"/>
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="3">
            <CInput
              :label="$t('label.filter')"
              :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
              :placeholder="$t('label.enterSearch')" 
              @keyup="onQuickFilterChanged($event.target.value)"         
            >
            </CInput>  
        </CCol>
        <CCol sm="12" lg="5"></CCol>
        <CCol sm="12" lg="4" class="justify-content-end">
            <CSelect
              :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
              :label="$t('label.recordsPerPage')"
              @change="onPageSizeChanged"
              :options="[50,100,500,1000]"
            >
            </CSelect>
        </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="11" lg="12" xl="12" class="mb-2">
          <div class="d-flex bd-highlight">
              <div class="bd-highlight" style="width:50px">
                  <CButton
                      block
                      style="height: 100vh;"
                      shape="square"
                      color="light"
                      @click="ModalColumn = true"
                  >
                      <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                          <CIcon name="cil-cog" class="correct-vertical"/>
                      </div>
                  </CButton>
              </div>
              <div class="flex-grow-1 bd-highlight">
                  <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :getRowStyle="getRowStyle"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
              </div>
          </div>
      </CCol>
    </CRow>
    <ModalRegister
      :modal.sync="ModalColumn"
      :ModalList="columnsOption"
    />
    </div>
  </template>
  <script>
  
  import GeneralMixin from '@/_mixins/general';
  import AgGrid from '@/_mixins/ag-grid';
  import { localeKey } from '@/_helpers/locale.grid';
  import UpperCase from '@/_validations/uppercase-directive';
  import { DateFormater, NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
  import ModalRegister from './modal-columns';
  import { mapState } from 'vuex';
  import YardManagement from '@/_mixins/yardManagement';
  import moment from 'moment';
  
  //DATA-------------------------
  function data() {
    return {
      gridOptions: null,
      defaultColDef: null,
      paginationPageSize: null,
      paginationNumberFormatter: null,
      gridApi: null,
      filtre: false,
      columnApi: null,
      previousDate: '',
      laterDate: '',
      ModalColumn:false,
      searchFilterDate:{
          fromDay:"",
          toDay:"",
      },
      formatedItems: [],
      columnsOption:{
        selectAll:true,
        seal:true,
        codeIso:true,
        status:true,
        shippingLine:true,
        event: true,
        startDate: true,
        endDate: true,
        totalTime: true,
        machine: true,
        previousPosicion: true,
        newPosition: true,
        device: true,
        user: true,
        registerDate: true
      },
    }
  }
  
  //beforeMount
  function beforeMount() {
      this.gridOptions = {};
      this.defaultColDef = {
          editable: false,
          resizable: true,
          sortable: true,
          minWidth: 150,
          flex: 1,
      };
      this.paginationPageSize = 50;
      this.paginationNumberFormatter = (params) => {
          return params.value.toLocaleString();
      };
  }
  
  //methods
  function onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
  }
  
  function onQuickFilterChanged(value) {
      this.gridApi.setQuickFilter(value);
  }
  
  function onPageSizeChanged(event) {
      this.gridApi.paginationSetPageSize(parseInt(event.target.value));
  }
  
  function getYardEventDetailList() {
      this.$store.state.yardManagement.loading = true;
  
      let dateStart = '';
      let dateEnd = '';
  
      dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
      dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);        
  
      let ReportJson = {
          "YardId": this.yardData?.YardId ?? '',//Id del Patio 
          "Visit": this.Visit.VisitId ?? '',//Id de la Visita seleccionada
          "DateStart": dateStart,//Fecha desde 
          "DateEnd": dateEnd,//Fecha hasta (Fecha Actual valor maximo)
      };
  
      this.$http.post('YardCargoEventDetailReport',ReportJson, { root: 'ReportJson'})
          .then(response => {
              this.VisitName = this.Visit;
              let List = response.data.data;
              this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
          })
          .catch( err => {
              this.$notify({
                  group: 'container',
                  title: '¡Error!',
                  text: err,
                  type: "error"
              });
          })
          .finally(() => {
              this.$store.state.yardManagement.loading = false;
          });
  }
  
  function getDateRange(){
      this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
      this.laterDate = new Date();
      this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
      this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
  }
  
  
  async function refreshComponent() {
      this.formatedItems = [];
      this.Visit="";
      this.VisitName = {};
      this.searchFilterDate = {
          fromDay: '',
          toDay: '',
      };
      this.DateRange = '';
      this.previousDate = '';
      this.laterDate = '';
      await this.getDateRange();
      this.columnsOption = {
        selectAll:true,
        seal:true,
        codeIso:true,
        status:true,
        shippingLine:true,
        event: true, 
        startDate: true,
        endDate: true,
        totalTime: true, 
        machine: true,
        previousPosicion: true,
        newPosition: true,
        device: true,
        user: true,
        registerDate: true
      }
  }
  
  //computeds
  function RowData(){
      if(this.formatedItems.length != 0){
          let _lang = this.$i18n.locale;
          return this.formatedItems.map(item => {
  
              return {
                  ...item,
                NewPosition: item.YardNewDetailPosJson.map(Position => Position.SlotCode.trim()).join(', '),
                previousPosition: item.YardPreviousDetailPosJson.map(Position => Position.SlotCode.trim()).join(', '),  
                DocumentCode: item.DocumentCode ?? ''            
              }
          });
      }else{
          return [];
      }
  }
  
  function localeText(){
      let AG_GRID_LOCALE = {};
      for (let index = 0; index < localeKey.length; index++) {
          const element = localeKey[index];
          AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
      }
      return AG_GRID_LOCALE;
  }
  
  function columnDefs(){
      let columnDefs = [
          {
              field: "Nro",
              headerName: '#',
              filter: 'agTextColumnFilter',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 100,
              cellClass: 'gb-cell center-cell',
              headerClass: 'center-cell-especial',
          },
          {
              field: "ContainerCode",
              headerName: `${this.$t('label.container')}/${this.$t('label.serial')}`,
              filter: 'agTextColumnFilter',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 220,
              cellClass: 'gb-cell center-cell',
              headerClass: 'center-cell-especial',
          },
          {
              field: "TpCargoDetailCode",
              headerName: this.$t('label.type'), 
              filter: 'agTextColumnFilter',
              cellClass: ' center-cell',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 180,
          },
          {
              field: "TpTransacEirAcronym" ,
              headerName: this.$t('label.transaction'), 
              filter: 'agTextColumnFilter',
              cellClass: 'center-cell',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 180,
          },
          {
              field: "VisitDs",
              headerName: this.$t('label.visit'), 
              filter: 'agTextColumnFilter',
              cellClass: ' center-cell',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 180,
          },
          {
              field: "Ata",
              headerName: 'ATA',
              cellClass: '',
              filter: 'agTextColumnFilter',
              cellRenderer: params => {
                  let variable = '';
                  
                  if(params.value !== null && params.value !== '' ) 
                      variable = DateFormater.formatDateTimeWithSlash(params.value);
                  
                  return variable;
              },
              cellClass: 'center-cell',
              lockPosition: true,
              spanHeaderHeight: true,
              minWidth: 180,
          },  
          {
                field: "DocumentCode",
                headerName: this.$t('label.Acta'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                lockPosition: true,
                minWidth: 300,
            },
        ]
        
        if(this.columnsOption.codeIso){
            columnDefs.push({
                field: "IsoCode",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                headerName: this.$t('label.isoCode'), 
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
  
        if(this.columnsOption.status){
            columnDefs.push({
                field: "TpCargoStatusName",
                headerName: this.$t('label.status'),  
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
  
        if(this.columnsOption.seal){
            columnDefs.push({
                field: "Seals",
                headerName: this.$t('label.Seals'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            });
        }

        if(this.columnsOption.shippingLine){
            columnDefs.push({
                field: "ShippingLineCode",
                headerName: this.$t('label.newShippingLine'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            });
        }

        if(this.columnsOption.event){
            columnDefs.push({
                field: this.$i18n.locale=='en' ? 'EventNameEn'  : 'EventNameEs' ,
                headerName: this.$t('label.event'),  
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }

        if(this.columnsOption.startDate){
            columnDefs.push({
                field: "TransactionBegin",
                headerName: this.$t('label.startDate'),    
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                      let variable = '';
                      
                      if(params.value !== null && params.value !== '' ) 
                          variable = DateFormater.formatDateTimeWithSlash(params.value);
                      
                      return variable;
                },
                filter: "agTextColumnFilter",
                minWidth: 200,
            });
        }


        if(this.columnsOption.endDate){
            columnDefs.push({
                field: "TransactionFinish",
                headerName: this.$t('label.endDate'),    
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                      let variable = '';
                      
                      if(params.value !== null && params.value !== '' ) 
                          variable = DateFormater.formatDateTimeWithSlash(params.value);
                      
                      return variable;
                },
                filter: "agTextColumnFilter",
                minWidth: 200,
            });
        }

        if(this.columnsOption.totalTime){
          columnDefs.push({
              field: "TotalTimeH",
              headerName: this.$t('label.totalTime'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
        }


        if(this.columnsOption.previousPosicion){
          columnDefs.push({
              field: "previousPosition",
              headerName: this.$t('label.previousPosition'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 320,
          });
        }


        if(this.columnsOption.newPosition){
          columnDefs.push({
              field: "NewPosition",
              headerName: this.$t('label.newPosition'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 320,
          });
        }
  
        if(this.columnsOption.device){
          columnDefs.push({
              field: "DeviceCod",
              headerName: this.$t('label.device'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
        }

        if(this.columnsOption.user){
          columnDefs.push({
              field: "TransaLogin",
              headerName: this.$t('label.user'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
        }

        if(this.columnsOption.registerDate){
            columnDefs.push({
                field: "TransaRegDate",
                headerName: this.$t('label.registerDate'),    
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                      let variable = '';
                      
                      if(params.value !== null && params.value !== '' ) 
                          variable = DateFormater.formatDateTimeWithSlash(params.value);
                      
                      return variable;
                },
                filter: "agTextColumnFilter",
                minWidth: 200,
            });
        }
  
        
      return columnDefs;
  }
  
  async function onBtnExport(valor){
      if(this.formatedItems.length !== 0) {
          this.$store.state.yardManagement.loading = true;
          let currentDate = new Date();
          let rowData = [];
          let Filter = []; 
          let rowDataFormated = [];
          
          Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
          Filter[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
          Filter[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
  
          this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
          rowDataFormated = rowData.map(item => {
              return {
                  ...item,
                  Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
                  TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : 'N/A',
                  TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : 'N/A',
                  TransaRegDate : item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
              }
          })
          await this.getReporExcel(rowDataFormated, `${this.$t('label.EventList')}`, valor, Filter);
          this.$store.state.yardManagement.loading = false;
      }else{
          this.$notify({  
              group: 'container',
              title: '¡Error!',
              text: this.$t('label.noRecordsAvailbleReport'),
              type: "error" 
          });
      }
  }
  
  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }
  
  function getRowClass(params) {
      return 'bg-gradient'
  }
  
  function getRowStyle(params) {
      return { background: params.data.TpTransacEirColor};
  }
  
  export default {
    name: 'dispatch-export-list',
    components: {
      ModalRegister
     },
    data,
    beforeMount,
    mixins: [GeneralMixin, YardManagement, AgGrid],
    methods: {
      onGridReady,
      onQuickFilterChanged,
      onPageSizeChanged,
      onBtnExport,
      validateDateRange,
      getRowClass,
      getRowStyle,
      getYardEventDetailList,
      getDateRange,
      refreshComponent
    },
    computed: {
      RowData,
      columnDefs,
      localeText,
      ...mapState({
          dropItemReport: state => state.yardManagement.dropItemReport,
          yardData: state => state.yardManagement.yardData,
          CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      })
    },
    directives: UpperCase,
    watch: {
      dropItemReport: async function (val) {
          if (val==8) {
              this.$store.state.yardManagement.loading = true;
              await this.refreshComponent();
              await this.getYardEventDetailList();
          }
      },
      Visit: function (val) {
          if(val?.Ata){
              let ata = DateFormater.formatDateTimeWithoutSlash(val.Ata);
              this.previousDate = DateFormater.formatDateRange(ata);
              this.searchFilterDate.fromDay =val.Ata;
          }
          else{
              this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
              this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(process.env.VUE_APP_START_DATE_IN_PATIO);
          }
      },
      showGrid: function (NewValue) {
          if (NewValue) {
              this.$store.state.yardManagement.loading = false
          } else {
              this.$store.state.yardManagement.loading = true;
          }
      }
    },
  };
  </script>
  <style>
    .Entry-state-yard-header{
        background: #1f3864 !important;
    }
    .Entry-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Status-state-yard-header{
        background: #ed7d31 !important;
    }
    .Status-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Dispatched-state-yard-header{
        background: #616161 !important;
    }
    .Dispatched-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Inventary-state-yard-header{
        background: #5797e6 !important;
    }
    .Inventary-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .center-cell {
        text-align: center;
        vertical-align: middle;
        user-select: initial;
    }
    .ag-header-group-cell-label, .ag-header-cell-label{
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
            text-align: center;
            vertical-align: middle;
            user-select: initial;
            -moz-user-select: text;
            -ms-user-select: text;
            -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
  </style>