<template>
    <section>
        <div class="cloudiness">
            <img src="/img/weather/cloudSmall.svg" alt="cloudiness"><span class="cloudiness__value">{{ cloudiness }}</span>&percnt;
        </div>
        <div class="wind-speed">
            <img src="/img/weather/wind.svg" alt="wind speed"><span class="wind__value">{{ windSpeed }}</span>m/s
        </div>
        <div class="humidity">
            <img src="/img/weather/humidity.svg" alt="humidity"><span class="humidity__value">{{ `${humidity}%` }}</span>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Measurements',

    props: {
        cloudiness: {
            type: Number,
            required: true
        },
        windSpeed: {
            type: Number,
            required: true
        },
        humidity: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
section {
    width: 100%;
    /* padding-top: 25px; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 9px;
    color: #fff;
}

.cloudiness img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.wind-speed img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.humidity img {
    width: 21px;
    height: 21px;
    vertical-align: middle;
}
.humidity span {
    vertical-align: middle;
}
</style>
