<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <modalPackagingList :modal="ListModal" @closeModal="ListModal = false" />
    <modalPackaging
      :modal="AddModal"
      @closeModal="AddModal = false"
      @child-refresh="packagingRefresh = true"
    />
    <CCol sm="12" class="text-right">
      <CButton square color="add" @click="AddModal = true " v-c-tooltip="{
      placement:'top-end',content:$t('label.packaging')}">
        <CIcon name="cil-playlist-add" />&nbsp; {{$t('label.nuevo')}}
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedPackagingList"
        :fields="fields"
        column-filter
        :items-per-page="5"
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        hover
        small
        sorter
        pagination
      >
        
        <template #Status="{item}">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{item}">
          <td class="text-center">
            
            <CButton
              color="watch"
              square
              class="mr-1"
              style="margin-right:2px;"
              size="sm"                  
              v-c-tooltip="{placement:'top-end',content: $t('label.toView') + ' ' + $t('label.groupAndType')}"
              @click="ListModal = item"
            >
              <CIcon name="eye" />
            </CButton>

            <CButton
              color="edit"
              square
              size="sm"
              v-c-tooltip="{placement:'top-end',content: $t('label.edit') +  ' ' + $t('label.packaging')}"
              @click="AddModal = item"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import modalPackaging from "./modal-packaging";
import modalPackagingList from "./modal-packaging-list";

// const tableTextHelpers = {
//   tableFilterText: {
//     label: 'FILTRAR:',
//     placeholder: 'EMBALAJE',
//   },
//   itemsPerPageText: {
//     label: 'EMBALAJES POR PAGINA:',
//   },
//   noItemsViewText: {
//     noResults: 'NO SE ENCONTRARON RESULTADOS',
//     noItems: 'NO HAY REGISTROS DISPONIBLES',
//   },
// };

//DATA
function data() {
  return {
    itemsEmba: [],
    Loading: false,
    LoadingEmba: false,
    AddModal: false,
    ListModal: false,
    packagingRefresh: false,
    
  };
}

//METHOD
/*
function EnviarDatos(item) {
  this.PackagingId = item.PackagingId;
  this.PackagingName = item.PackagingName;
  this.ListModal = true;
}
*/

function getPackagingList() {
  this.itemsEmba = [];
  this.docs = [];
  this.Loading = true;
  let listado = Array;
  let _lang = this.$i18n.locale;

  this.$http
    .get("Packaging-list", { filter: "ALL" })
    .then((response) => {
      listado = [...response.data.data];
      this.docs = listado.filter((ob) => ob.Status === "ACTIVO");
      this.itemsEmba = listado;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function computedPackagingList() {
  if(this.itemsEmba == []){
    return this.itemsEmba;
  }
  return this.itemsEmba.map((item) => {
    return {
      ...item,
      Nro: item.Nro,
      PackagingNameEs: item.PackagingNameEs,
      PackagingNameEn: item.PackagingNameEn,
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      PackagingId: item.PackagingId,
      Serialitation: item.FgApplySerialization ? this.$t('label.yes') : 'NO',
      Weight: item.Weight,
      Status: item.Status,
      FgActPackaging: item.FgActPackaging,
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
    };
  });
}

function fields(){
  return[
    { key: "Nro", label: "#", _classes: 'text-left', _style: 'width:3%; text-align:center', filter: false },
    { key: "PackagingNameEs", label: this.$t('label.packaging')+' '+('(ES)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "PackagingNameEn", label: this.$t('label.packaging')+' '+('(EN)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "Serialitation", label: this.$t('label.requireSerialization'),_classes: 'text-uppercase text-center', style: 'min-width:120px;'},
    { key: "Weight", label: this.$t('label.weight') +  ' (KG)',_classes: 'text-uppercase text-center', style: 'min-width:140px;'},
    { key: "Usuario", label:this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:15%'},
    { key: "Fecha", label: this.$t('label.date'),_classes:'text-center',_style:'text-align:center;width: 15%;' },
    { key: "Status", label: this.$t('label.status'),_classes:'text-center',_style:'text-align:center;width: 10%;' },
    { key: "Detalle", label: "", _style:'width:10%;min-width:90px;', _classes:'center-cell',filter: false,},
  ];
}



export default {
  name: "index",
  mixins: [General],
  data,
  props: {
    Tab: Number,
  },
  components: {
    modalPackaging,
    modalPackagingList,
  },
  // props: {
  //   fields: {
  //     type: Array,
  //     default() {
  //       return fields;
  //     },
  //   },
  // },
  methods: {
    getPackagingList,
  },
  computed:{
    fields,
    computedPackagingList
  },
  watch: {
    packagingRefresh: function() {
      if (this.packagingRefresh) {
        this.getPackagingList();
        this.packagingRefresh = false;
      }
    },
    Tab: function(value) {
      if (value == 0) {
        this.getPackagingList();
      }
    },
  }
};
</script>
<style scoped></style>
