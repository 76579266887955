<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
        <CCol>
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right required"
            size="sm"
            :label="$t('label.group')"
            :options="IncidenceListFormatted"
            v-model.trim="$v.IncidenceGroup.$model"
            :value.sync="IncidenceGroup"
            :is-valid="hasError($v.IncidenceGroup)"
            :invalid-feedback="errorMessage($v.IncidenceGroup)"
          />
        </CCol>
        
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+'(ES)'"
            :placeholder="$t('label.IncidenceName')+' '+'(ES)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.IncidenceNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.IncidenceNameEs)"
            :invalid-feedback="errorMessage($v.IncidenceNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+'(EN)'"
            :placeholder="$t('label.IncidenceName')+' '+'(EN)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.IncidenceNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.IncidenceNameEn)"
            :invalid-feedback="errorMessage($v.IncidenceNameEn)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            type="color"
            size="sm"
            :label="$t('label.color')"
            addLabelClasses="required text-right"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :invalid-feedback="errorMessage($v.Color)"
            :is-valid="hasError($v.Color)"
            v-model.trim="$v.Color.$model"
          />
        </CCol>
  <!--  <CCol sm="12" lg="12">
          <CSelect
            :options="OrderUpdateListFormatted"
            :value.sync="OrderUpdate"
            v-model="$v.OrderUpdate.$model"
            :label="$t('label.ModifyShipmentOrder')"
            :horizontal="{label:'col-sm-12 col-lg-9', input:'col-sm-12 col-lg-3'}"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.OrderUpdate)"
          /> 
        </CCol> -->
        <CCol sm="12" lg="12">
          <CRow>
            <CCol class="d-flex justify-content-end ml-3" sm="12" lg="3">
              <CSwitch
                color="watch"
                variant="3d"
                type="checkbox"
                :checked.sync="FgActOrderUpdate"
              />
            </CCol>
            <label class="col-form-label col-sm-12 col-lg-auto text-right pl-0 mb-2 pt-1">{{$t('label.ModifyShipmentOrder')}}</label>
          </CRow>
        </CCol>
        <CCol sm="12" lg="12">
          <CRow>
            <CCol class="d-flex justify-content-end ml-3" sm="12" lg="3">
              <CSwitch
                color="watch"
                variant="3d"
                type="checkbox"
                :checked.sync="FgActBinReturn"
              />
            </CCol>
            <label class="col-form-label col-sm-12 col-lg-auto text-right pl-0 mb-2 pt-1">{{$t('label.BinReturn')}}</label>
          </CRow>
        </CCol>
        
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            :options="statusOptions"
            size="sm"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
           @click.stop="isEdit ? statusConfirmation(IncidenceItem.FgActIncidence , Status, submitBody) : submitBody()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import incidenceMasterValidation from '@/_validations/incidence/incidenceMasterValidation';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    apiStateLoading: false,
    FgActOrderUpdate: false,
    FgActBinReturn: false,
    IncidenceId: '',
    IncidenceNameEs: '',
    IncidenceNameEn: '',
    IncidenceGroup: '',
    Color: '',
    IncidenceItems: [],
    //OrderUpdate: 0,
    Status: 0,
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.isSubmit = false;
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const IncidenceJson = this.isEdit ? {
      IncidenceId: this.IncidenceId,
      IncidenceGpoId: this.IncidenceGroup,
      IncidenceNameEn: this.IncidenceNameEn,
      IncidenceNameEs: this.IncidenceNameEs,
      Color: this.Color,
      FgActOrderUpdate: this.FgActOrderUpdate?1:0,
      FgActReturnImplement: this.FgActBinReturn?1:0,
      Status: this.Status,
    }:{
      IncidenceGpoId: this.IncidenceGroup,
      IncidenceNameEn: this.IncidenceNameEn,
      IncidenceNameEs: this.IncidenceNameEs,
      Color: this.Color,
      FgActOrderUpdate: this.FgActOrderUpdate?1:0,
      FgActReturnImplement: this.FgActBinReturn?1:0,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'Incidence-update':'Incidence-insert';
    this.$http
      .ejecutar( metodo, ruta, IncidenceJson, { root: 'IncidenceJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.apiStateLoading = false;
          this.isSubmit = false;
          this.closeModal();
          this.$emit('Update-list');
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function getIncidenceGpo() {
  this.$http.get("IncidenceGpo-list", {VisitId: this.Visit})
    .then(response => {       
      this.IncidenceItems = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}  

function titleModal() {
  if (this.isEdit) {
    let _lang = this.$i18n.locale;
    let title = _lang=='en' ? this.IncidenceItem.IncidenceNameEn : this.IncidenceItem.IncidenceNameEs;
    return this.$t('label.edit')+' '+this.$t('label.incidence')+': '+title;
  } else {
    return this.$t('label.nueva')+' '+this.$t('label.incidence');
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function getdata(val) {
  this.IncidenceGroup = val.IncidenceGpoId;
  this.IncidenceId = val.IncidenceId;
  this.IncidenceNameEs = val.IncidenceNameEs;
  this.IncidenceNameEn = val.IncidenceNameEn;
  this.Color = val.Color;
  this.Status = val.FgActIncidence?1:0;
  this.FgActOrderUpdate  = val.FgActOrderUpdate;
  this.FgActBinReturn = val.FgActReturnImplement;
  this.$v.$touch();
}

function closeModal() {
  this.IncidenceGroup = '';
  this.IncidenceNameEs = '';
  this.IncidenceNameEn = '';
  this.Color = '';
  this.IncidenceItems = [];
  this.FgActOrderUpdate = false;
  this.FgActBinReturn = false;
  this.Status = 0;
  this.$v.$reset();
  //this.$emit('Update-list');
}

/*function OrderUpdateListFormatted(){
  return [{
    value: '', 
    label: this.$t('label.select'),
  },{
    value: 1, 
    label: this.$t('label.yes'),
  },{
    value: 0, 
    label: 'NO',
  }];
}*/

function IncidenceListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.IncidenceItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.IncidenceItems.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: _lang=='en' ? e.IncidenceGpoNameEn : e.IncidenceGpoNameEs,
      })    
    })
    return chart;
  }
}
export default {
  name: 'modal-Incidence',
  props: { modal: Boolean, isEdit: Boolean, IncidenceItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: incidenceMasterValidation,
  directives: UpperCase,
  methods: {
    statusSelectColor,
    closeModal,
    getdata,
    getIncidenceGpo,
    submitBody,
  },
  computed: {
    IncidenceListFormatted,
    //OrderUpdateListFormatted,
    titleModal,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
         this.getIncidenceGpo();
      }
      if (this.isEdit==true) {
        this.getdata(this.IncidenceItem);
      }else{
        this.closeModal();
      }
    }
  },
 
};
</script>