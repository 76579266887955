<template>
  <div class="row mx-0 mt-3">
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right required"
        :label="$t('label.containerCode')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
        v-model="$v.GeneralInfo.ContainerCode.$model"
        :is-valid="hasError($v.GeneralInfo.ContainerCode)"
        :invalid-feedback="errorMessage($v.GeneralInfo.ContainerCode)"
        :maxlength="12"
      > 
      </CInput>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.status')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            :value.sync="StatusData.TpCargoStatusName ?? ''"
            size="sm"
            disabled
            class="col-sm-6 pl-0 pr-1 mb-0"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.TpCargoStatusId.$model"
            class="col-sm-6 px-0 mb-0"
            :options="StatusOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.TpCargoStatusId)"
            @change="GeneralInfo.TpCargoConditionId = ''"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.TpCargoStatusId.$error">
            {{ errorMessage($v.GeneralInfo.TpCargoStatusId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.condition')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="TpCargoConditionName"
            size="sm"
            disabled
            class="col-sm-6 pl-0 pr-1 mb-0"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.TpCargoConditionId.$model"
            class="col-sm-6 px-0 mb-0"
            :options="ConditionOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.TpCargoConditionId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.TpCargoConditionId.$error">
            {{ errorMessage($v.GeneralInfo.TpCargoConditionId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{$t('label.class')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            size="sm"
            disabled
            class="col-sm-6 pl-0 pr-1 mb-0"
            v-model="TpCargoClassCode"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.TpCargoClassId.$model"
            class="col-sm-6 px-0 mb-0"
            :options="ClassOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.TpCargoClassId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.TpCargoClassId.$error">
            {{ errorMessage($v.GeneralInfo.TpCargoClassId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{$t('label.shippingLineCode')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            :value.async="ShippingLineData?.ShippingLineCode ?? ''"
            size="sm"
            disabled
            class="col-sm-6 pl-0 pr-1 mb-0"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.ShippingLineId.$model"
            class="col-sm-6 px-0 mb-0"
            :options="ShippingLineCodeOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.ShippingLineId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.ShippingLineId.$error">
            {{ errorMessage($v.GeneralInfo.ShippingLineId) }}
          </div>
        </div>
      </div>

      <CInput
        v-uppercase
        size="sm"
        class="mt-0"
        addLabelClasses="text-right"
        :label="$t('label.newShippingLine')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
        :disabled="true"
        :value.async="ShippingLineData?.ShippingLineName ?? ''"
      >
      </CInput>

      <div class="form-group form-row mb-2">
        <label class="col-form-label-sm col-sm-12 col-lg-4 text-right">SHIPPER OWNED</label>
        <div class="d-flex justify-content-end col-sm-12 col-lg-auto">
          <CSwitch
            color="watch"
            variant="3d"
            type="checkbox"
            :checked.sync="GeneralInfo.FgShipperOwn"
          />
        </div>
      </div>

      <CInput
        v-uppercase
        size="sm"
        :addLabelClasses="GeneralInfo.FgShipperOwn ? 'text-right required' : 'text-right'"
        label="SHIPPER OWNED"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
        v-model="$v.GeneralInfo.ShipperOwn.$model"
        :is-valid="hasError($v.GeneralInfo.ShipperOwn)"
        :invalid-feedback="errorMessage($v.GeneralInfo.ShipperOwn)"
        :disabled="!GeneralInfo.FgShipperOwn"
      >
      </CInput>
      
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        label="BOOKING"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
        v-model="$v.GeneralInfo.BookingNro.$model"
        :is-valid="hasError($v.GeneralInfo.BookingNro)"
        :invalid-feedback="errorMessage($v.GeneralInfo.BookingNro)"
      >
      </CInput>
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right required">{{$t('label.portActivity')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="PortActivityName"
            size="sm"
            disabled
            class="col-sm-6 pl-0 pr-1 mb-0"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.PortActivityId.$model"
            class="col-sm-6 px-0 mb-0"
            :options="PortActivictyOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.PortActivityId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.PortActivityId.$error">
            {{ errorMessage($v.GeneralInfo.PortActivityId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label :class="`col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right ${GeneralInfo.PortActivityId ? 'required' : ''}`">{{$t('label.loadPort')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="LoadPortCode"
            size="sm" 
            disabled
            class="mb-0 pl-0 pr-1 col-sm-6"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.LoadPortId.$model"
            class="mb-0 px-0 col-sm-6"
            :options="PortOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.LoadPortId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.LoadPortId.$error">
            {{ errorMessage($v.GeneralInfo.LoadPortId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label :class="`col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right ${GeneralInfo.PortActivityId ? 'required' : ''}`">{{$t('label.dischargePort')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="DischargePortCode"
            size="sm"
            disabled
            class="mb-0 pl-0 col-sm-6 pr-1"
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.DischargePortId.$model"
            class="mb-0 px-0 col-sm-6"
            :options="PortOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.DischargePortId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.DischargePortId.$error">
            {{ errorMessage($v.GeneralInfo.DischargePortId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.DeliveryPort')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="DeliveryPortCode"
            size="sm"
            class="mb-0 pl-0 col-sm-6 pr-1"
            disabled
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.DeliveryPortId.$model"
            class="mb-0 px-0 col-sm-6"
            :options="PortOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.DeliveryPortId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.DeliveryPortId.$error">
            {{ errorMessage($v.GeneralInfo.DeliveryPortId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label style="font-size: 12px;" class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.CargoOriginPort')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            v-model="CargoOriginPortCode"
            size="sm"
            class="mb-0 pl-0 col-sm-6 pr-1"
            disabled
          />
          <CSelect
            size="sm"
            v-model="$v.GeneralInfo.CargoOriginPortId.$model"
            class="mb-0 px-0 col-sm-6"
            :options="PortOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.GeneralInfo.CargoOriginPortId)"
          >
          </CSelect>
          <div class="text-invalid-feedback" v-if="$v.GeneralInfo.CargoOriginPortId.$error">
            {{ errorMessage($v.GeneralInfo.CargoOriginPortId) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row mb-2">
        <label class="col-form-label-sm col-sm-12 col-lg-5 text-right">{{ $t('label.DirectDischarge') }}</label>
        <div class="d-flex justify-content-end col-sm-12 col-lg-auto">
          <CSwitch
            color="watch"
            variant="3d"
            type="checkbox"
            :checked.sync="GeneralInfo.FgDirectDischarge"
          />
        </div>
      </div>

      <CSelect
        v-uppercase
        size="sm"
        :addLabelClasses="`text-right ${!GeneralInfo?.FgDirectDischarge ? 'required' : ''}`"
        :label="$t('label.PlannedYard')"
        :placeholder="$t('label.select')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.GeneralInfo.YardId.$model"
        :options="YardOptions"
        :is-valid="hasError($v.GeneralInfo.YardId)"
        :invalid-feedback="errorMessage($v.GeneralInfo.YardId)"
      />

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right required"
        :label="$t('label.Procedence')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.GeneralInfo.Procedence.$model"
        :is-valid="hasError($v.GeneralInfo.Procedence)"
        :invalid-feedback="errorMessage($v.GeneralInfo.Procedence)"
      >
      </CInput>
      
      <CSelect
        size="sm"
        :options="statusOptions"
        :value="GeneralInfo.Status"
        :label="$t('label.status')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        addLabelClasses="required text-right"
        :is-valid="statusSelectColor"
        @change="GeneralInfo.Status=$event.target.value"
      />
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from "vuex";

function data() {
  return {

  };
}

//methods
function statusSelectColor() {
  return this.GeneralInfo.Status == 1;
}

//computed
function StatusOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.StatusList.map(item => {
    chart.push({
      value: item.TpCargoStatusId, 
      label: item.TpCargoStatusName,
    })    
  })
  return chart;
}

function ConditionOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.TpCargoConditionList.map(item => {
    chart.push({
      value: item.TpCargoConditionId,
      label: `${item.TpCargoConditionName}`,
    })    
  })
  return chart;
}

function ClassOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ClassList.map(item => {
    chart.push({
      value: item.TpCargoClassId, 
      label: `${item.TpCargoClassCode}`,
    })    
  })
  return chart;
}

function ShippingLineCodeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ShippingLineList.map(item => {
    chart.push({
      value: item.ShippingLineId, 
      label: item.ShippingLineName
    })    
  })
  return chart;
}

function PortActivictyOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PortActivityList.map(item => {
    chart.push({
      value: item.PortActivityId, 
      label: item['PortActivityName'+_lang],
    })    
  })
  return chart;
}

function PortOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PortList.map(item => {
    chart.push({
      value: item.PortId,
      label: item.PortName,
    })    
  })
  return chart;
}

function YardOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.YardList.map(item => {
    chart.push({
      value: item.YardId,
      label: item.YardName,
    })    
  })
  return chart;
}

function StatusData() {
  let Data = {}
  if (this.GeneralInfo.TpCargoStatusId) {
    Data = this.StatusList?.find((item) => this.GeneralInfo.TpCargoStatusId === item.TpCargoStatusId);
  }
  return Data ?? {};
}

function TpCargoConditionList() {
  return this.StatusData.TpCargoConditionList ?? [];
}

function TpCargoConditionName() {
  let Data = {};
  if (this.GeneralInfo.TpCargoConditionId) {
    Data = this.TpCargoConditionList?.find((item) => this.GeneralInfo.TpCargoConditionId === item.TpCargoConditionId);
  }
  return Data?.TpCargoConditionName ?? '';
}

function TpCargoClassCode() {
  let Data = {};
  if (this.GeneralInfo.TpCargoClassId) {
    Data = this.ClassList?.find((item) => this.GeneralInfo.TpCargoClassId === item.TpCargoClassId);
  }
  return Data?.TpCargoClassCode ?? '';
}

function ShippingLineData() {
  let Data = {};
  if (this.GeneralInfo.ShippingLineId) {
    Data = this.ShippingLineList?.find((item) => this.GeneralInfo.ShippingLineId === item.ShippingLineId);
  }
  return Data ?? {};
}

function PortActivityName() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let Data = {};
  if (this.GeneralInfo.PortActivityId && typeof this.GeneralInfo.PortActivityId == 'string') {
    Data = this.PortActivityList?.find((item) => this.GeneralInfo.PortActivityId === item.PortActivityId);
  }
  return Data ? Data['PortActivityName'+_lang] : '';
}

function LoadPortCode() {
  let Data = {};
  if (this.GeneralInfo.LoadPortId) {
    Data = this.ServicePortMasterList?.find((item) => this.GeneralInfo.LoadPortId === item.PortId);
  }
  return Data?.PortCode ?? '';
}

function DischargePortCode() {
  let Data = {};
  if (this.GeneralInfo.DischargePortId) {
    Data = this.ServicePortMasterList?.find((item) => this.GeneralInfo.DischargePortId === item.PortId);
  }
  return Data?.PortCode ?? '';
}

function DeliveryPortCode() {
  let Data = {};
  if (this.GeneralInfo.DeliveryPortId) {
    Data = this.PortList?.find((item) => this.GeneralInfo.DeliveryPortId === item.PortId);
  }
  return Data?.PortCode ?? '';
}

function CargoOriginPortCode() {
  let Data = {};
  if (this.GeneralInfo.CargoOriginPortId) {
    Data = this.PortList?.find((item) => this.GeneralInfo.CargoOriginPortId === item.PortId);
  }
  return Data?.PortCode ?? '';
}

export default {
  name: 'basic-data-tab',
  data,
  props: {
    GeneralInfo: {
      type: Object,
      required: true,
      default: () => {},
    },
    StatusList: {
      type: Array,
      default: () => [], 
    },
    ClassList: {
      type: Array,
      default: () => [], 
    },
    ShippingLineList: {
      type: Array,
      default: () => [], 
    },
    PortActivityList: {
      type: Array,
      default: () => [], 
    },
    ServicePortMasterList: {
      type: Array,
      default: () => [], 
    },
    PortList: {
      type: Array,
      default: () => [], 
    },
    YardList: {
      type: Array,
      default: () => [], 
    },
    Validate: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
    statusSelectColor,
  },
  computed:{
    StatusOptions,
    ConditionOptions,
    ClassOptions,
    ShippingLineCodeOptions,
    PortActivictyOptions,
    PortOptions,
    YardOptions,
    StatusData,
    TpCargoConditionList,
    TpCargoConditionName,
    TpCargoClassCode,
    ShippingLineData,
    PortActivityName,
    LoadPortCode,
    DischargePortCode,
    DeliveryPortCode,
    CargoOriginPortCode,
    ...mapState({
      CompanyBranchId: (state) => state.auth.branch.CompanyBranchId,
      StowagePlanningId: (state) => state.planificacionestiba.planificacionId
    }),
  },
  watch: {
    showModal: async function (Newval) {
      if (Newval) {
        this.$v.GeneralInfo.$touch();
      }else{
        this.$v.$reset();
      }
    },
    TpCargoConditionList: function (NewVal) {
      if (NewVal.length == 1) {
        this.GeneralInfo.TpCargoConditionId = this.StatusData.TpCargoConditionList[0].TpCargoConditionId ?? '';
      }
    }
  },
};
</script>