import {
    required,
    maxLength
} from 'vuelidate/lib/validators';

export default () => {
    return {
        TpDamageName: { required, maxLength: maxLength(30) },
        TpDamageNameEn: { required, maxLength: maxLength(30) },
        TpDamageAbbrev: { required, maxLength: maxLength(5) },
    }
}