<template>
    <div>
        <CModalExtended
            size="lg"
            color="dark"
            :title="tituloModal"
            :closeOnBackdrop="false"
            :show.sync="$store.state.tiempoperdido.modalTiempoPerdido"
        >
        <CRow>
            <CCol sm="12">
            <CCard class="card-simple">
            <CCardBody>    
                <CRow>
                    <CCol sm="12">
                        <div class="form-group form-row" rol="group">
                            <label class="required col-form-label col-sm-4 text-right" 
                                    for="labelPuerto">{{$t('label.lostTimeType')}}</label>
                            <div class="col-sm-8 input-group">
                                <select
                                    v-if="langOption"
                                    @blur="$v.formTiempoPerdido.IdTpTiempoPerdido.$touch()"
                                    v-model="$v.formTiempoPerdido.IdTpTiempoPerdido.$model" 
                                    :class="InvalidSelect(timeVal)" 
                                    id="inputGroupSelect01"
                                    :is-valid="$v.formTiempoPerdido.IdTpTiempoPerdido.$error"
                                >
                                    <option value="">{{$t('label.select')}}</option>
                                    <option  v-for="items in filteredTipeItems" :key="items.Nro" :value="items.TpDelayId">
                                        {{ items.TpDelayNameEn }}
                                    </option>
                                </select>
                                <!-- @blur="$v.formTiempoPerdido.IdTpTiempoPerdido.$touch()"-->
                                <select
                                    v-if="!langOption"
                                    @input="verifyTpTiempoPerdido($event.target.value)"
                                    v-model="$v.formTiempoPerdido.IdTpTiempoPerdido.$model" 
                                    :class="InvalidSelect(timeVal)" 
                                    id="inputGroupSelect01"
                                    :is-valid="$v.formTiempoPerdido.IdTpTiempoPerdido.$error"
                                >
                                    <option value="">{{$t('label.select')}}</option>
                                    <option  v-for="items in filteredTipeItems" :key="items.Nro" :value="items.TpDelayId">
                                        {{ items.TpDelayNameEs }}
                                    </option>
                                </select>
                                <div class="input-group-append" style="height:35px;">
                                    <button 
                                        @click="showCollapse = !showCollapse"
                                        class="btn btn-edit"
                                        type="button"
                                        v-c-tooltip="{
                                            content: $t('label.add')+$t('label.type'),
                                            placement: 'top-end'
                                        }"
                                    >
                                      
                                       
                                       <CIcon v-if="showCollapse" name="minus"/>
                                       <CIcon v-if="!showCollapse" name="cil-plus"/>
                                    </button>
                                </div>
                                
                                <div class="invalid-feedback col-sm-12 px-0" v-if="$v.formTiempoPerdido.IdTpTiempoPerdido.$error">
                                    <div class='text-danger' v-if="!$v.formTiempoPerdido.IdTpTiempoPerdido.required">{{$t('label.required')}}</div>
                                </div>
                            </div>
                        </div>
                        <CCollapse :show="showCollapse">
                            <appCollapseTpTiempoPerdido :LimpiarCollapse.sync="LimpiarCollapse" />
                        </CCollapse>
                    </CCol>
                    <CCol sm="12">
                        <CInput
                        :label="$t('label.name')+' (EN)'"
                        addLabelClasses="required text-right"
                        v-uppercase
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                        @blur="$v.formTiempoPerdido.DsTiempoPerdidoEn.$touch()"
                        :placeholder="$t('label.lostTimeName')+' (EN)'"
                        v-model="$v.formTiempoPerdido.DsTiempoPerdidoEn.$model"
                        :is-valid="hasError($v.formTiempoPerdido.DsTiempoPerdidoEn)"
                        :invalid-feedback="errorMessage($v.formTiempoPerdido.DsTiempoPerdidoEn)"
                        required 
                        maxlength="250"
                        >
                        </CInput>
                    </CCol>
                    <CCol sm="12">
                        <CInput
                            :label="$t('label.name')+' (ES)'"
                            addLabelClasses="required text-right"
                            v-uppercase
                            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                            @blur="$v.formTiempoPerdido.DsTiempoPerdidoEs.$touch()"
                            :placeholder="$t('label.lostTimeName')+' (ES)'"
                            v-model="$v.formTiempoPerdido.DsTiempoPerdidoEs.$model"
                            :is-valid="hasError($v.formTiempoPerdido.DsTiempoPerdidoEs)"
                            :invalid-feedback="errorMessage($v.formTiempoPerdido.DsTiempoPerdidoEs)"
                            required 
                            maxlength="250"
                            >
                        </CInput>
                    </CCol>
                    <CCol sm="12">
                        <CInput
                        :label="$t('label.TdrCode')"
                        addLabelClasses="required text-right"
                        v-uppercase
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                        @blur="$v.formTiempoPerdido.TdrCode.$touch()"
                        :placeholder="$t('label.TdrCode')"
                        v-model="$v.formTiempoPerdido.TdrCode.$model"
                        :is-valid="hasError($v.formTiempoPerdido.TdrCode)"
                        :invalid-feedback="errorMessage($v.formTiempoPerdido.TdrCode)"
                        required 
                        maxlength="4"  
                        >
                        
                        </CInput>
                    </CCol>
                    <CCol sm="12">
                        <CSelect
                            :label="$t('label.status')"
                            addLabelClasses="text-right"
                            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                            :is-valid="formTiempoPerdido.FgActTiempoPerdido"
                            v-if="!idIndentification"
                            v-model="formTiempoPerdido.FgActTiempoPerdido"
                            :value.sync="formTiempoPerdido.FgActTiempoPerdido"
                            :options="selectOptions"
                        />
                    </CCol>
                </CRow>
            
                <CElementCover 
                    :opacity="0.8"
                    v-if="loading"
                >
                    <label class="d-inline">{{$t('label.load')}}... </label>
                    <CSpinner size="sm" />
                </CElementCover>
            </CCardBody>
            </CCard>
            </CCol>
        </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading || loading"
                >
                    <CIcon name="checkAlt"/>&nbsp; {{$t('button.accept')}}
                    <!--:disabled="apiStateLoading"
                    <div v-if="apiStateLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                    -->
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import { FormTiempoPerdido } from '@/_validations/tiempoperdido/TiempoValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    import appCollapseTpTiempoPerdido from './app-collapse-tipotiempoperdido.vue';
    import ModalMixin from '@/_mixins/modal';
    //data
    function data() {
        return {
            formTiempoPerdido:{
                IdTiempoPerdido:0,
                DsTiempoPerdidoEn:'',
                DsTiempoPerdidoEs:'',
                TdrCode:'',
                FgActTiempoPerdido:true,
                IdTpTiempoPerdido:''
            },
            selectOptions: [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ],
            originalAct:'',
            tituloModal : this.$t('label.nuevo')+this.$t('label.lostTime'),
            loading:false,
            showCollapse: false,
            iconoCollapse: 'cil-plus',
            locale: this.$i18n.locale,
            timeVal: false,
            LimpiarCollapse: false,
        }
    }

    //mounted
    function mounted(){

    }

    //methods
    function cambio(e){
        this.formTiempoPerdido.FgActTiempoPerdido = !this.formTiempoPerdido.FgActTiempoPerdido;
    }
    function cerrarModal(){
        this.showCollapse= false;
        this.limpiarCollapse = false;
        if(this.apiStateLoading || this.loading)
            return
        this.$store.commit('tiempoperdido/mutationModal', false);
    }
    function registerData(){
        try {
            this.$v.formTiempoPerdido.$touch();
            if (this.$v.formTiempoPerdido.$pending || this.$v.formTiempoPerdido.$error)
            {
                this.timeVal=true;
                throw this.$t('label.errorsPleaseCheck');
            }
                
            if (this.$v.formTiempoPerdido.$pending || this.$v.formTiempoPerdido.$error) return;

            let { IdTiempoPerdido,IdTpTiempoPerdido,DsTiempoPerdidoEn, DsTiempoPerdidoEs,TdrCode,FgActTiempoPerdido } = this.formTiempoPerdido;
            const { dispatch } = this.$store;
            if(this.originalAct !== '' && IdTiempoPerdido !== 0){
                if(this.originalAct !== FgActTiempoPerdido){
                    let textoMsg='';
                    if(this.$i18n.locale==='en'){ textoMsg = DsTiempoPerdidoEn; }
                    if(this.$i18n.locale==='es'){ textoMsg = DsTiempoPerdidoEs; }
                    this.$swal.fire({
                        text: `${this.$t('label.changeStatusQuestion')+' '} ${textoMsg}?`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#42AA91',
                        cancelButtonColor: '#E1373F',
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.cancel')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            dispatch('tiempoperdido/postregisterTiempoPerdido', { IdTiempoPerdido,IdTpTiempoPerdido,DsTiempoPerdidoEn, DsTiempoPerdidoEs,TdrCode,FgActTiempoPerdido });
                        }else {
                        //	this.$swal(this.$t('label.operationCancelled'));
                        }
                    })
                }else{
                    dispatch('tiempoperdido/postregisterTiempoPerdido', { IdTiempoPerdido,IdTpTiempoPerdido,DsTiempoPerdidoEn, DsTiempoPerdidoEs,TdrCode,FgActTiempoPerdido });
             
                }
            }else{
                dispatch('tiempoperdido/postregisterTiempoPerdido', { IdTiempoPerdido,IdTpTiempoPerdido,DsTiempoPerdidoEn, DsTiempoPerdidoEs,TdrCode,FgActTiempoPerdido });
            }
        //
        } catch (e) {
        this.notifyError({ text: e });
        }
    }
    function updatedCollapseTipo(){
        this.$store.state.tiempoperdido.idTipoTpPerdido = 0;
        this.$store.state.tiempoperdido.collapseTipoTiempoPerdido = true;
    }
    function verifyTpTiempoPerdido(value){
        this.timeVal=true;
    }
    //computed
    function idIndentification(){
        return this.formTiempoPerdido.IdTiempoPerdido === 0;
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function InvalidSelect(timeVal){
        if(this.timeVal === true){ 
            return (this.formTiempoPerdido.IdTpTiempoPerdido == null || this.formTiempoPerdido.IdTpTiempoPerdido == "") 
            ? 'custom-select is-invalid':  'custom-select is-valid'
        }
        if(this.timeVal === false){ 
            return 'custom-select'
        }
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function filteredTipeItems () {
        if(this.$i18n.locale==='en'){ 
            const computed = this.$store.getters["tiempoperdido/myDataTpTiempoPerdidoTableEn"];
            return computed.filter(u => u.FgActTpDelay);
        }
        if(this.$i18n.locale==='es'){ 
            const computed = this.$store.getters["tiempoperdido/myDataTpTiempoPerdidoTableEs"];
            return computed.filter(u => u.FgActTpDelay);
        }
       
    }
    function langOption () {
        if(this.$i18n.locale==='en'){ 
            return true;
        }
        if(this.$i18n.locale==='es'){ 
            return false;
        }
       
    }

    //watch
    function modalTiempoPerdido(newQuestion,Oldquestion){
        this.showCollapse= false;
        if(newQuestion === false){
            this.formTiempoPerdido.IdTiempoPerdido=0;
            this.formTiempoPerdido.IdTpTiempoPerdido='';
            this.formTiempoPerdido.DsTiempoPerdidoEn='';
            this.formTiempoPerdido.DsTiempoPerdidoEs='';
            this.formTiempoPerdido.TdrCode='';
            this.formTiempoPerdido.FgActTiempoPerdido=true;
            this.tituloModal = this.$t('label.nuevo')+this.$t('label.lostTime');
            this.originalAct = '';
            this.$store.state.tiempoperdido.collapseTipoTiempoPerdido = false;
            this.timeVal=false;
            this.limpiarCollapse = false;
            this.$nextTick(() => { this.$v.$reset() });
            
            this.$store.commit('tiempoperdido/asignarid', 0);
        }else{
            this.$store.dispatch('tiempoperdido/getTpTiempoPerdidolist');
        }
    }
    function idState(newQuestion,Oldquestion){
        this.showCollapse= false;
        if(this.modalTiempoPerdido){
            if(newQuestion !== 0){
                this.$store.state.tiempoperdido.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'Delay-by-id',{ DelayId: newQuestion }, '' ).then(response => {
                    if(response.status === 200){
                        try {
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                if(this.$i18n.locale==='en'){ 
                                    this.tituloModal = this.$t('label.edit')+this.$t('label.lostTime')+": "+Information[0].DelayDsEn;
                                }
                                if(this.$i18n.locale==='es'){ 
                                    this.tituloModal = this.$t('label.edit')+this.$t('label.lostTime')+": "+Information[0].DelayDsEs;
                                }
                                this.formTiempoPerdido.IdTiempoPerdido = Information[0].DelayId;
                                this.formTiempoPerdido.DsTiempoPerdidoEn = Information[0].DelayDsEn;
                                this.formTiempoPerdido.DsTiempoPerdidoEs = Information[0].DelayDsEs;
                                this.formTiempoPerdido.TdrCode = Information[0].TdrCode;
                                this.formTiempoPerdido.IdTpTiempoPerdido = Information[0].TpDelayId;
                                this.originalAct = Information[0].FgActDelay;
                                this.formTiempoPerdido.FgActTiempoPerdido = Information[0].FgActDelay;
                                this.$store.state.tiempoperdido.apiStateForm = ENUM.INIT;
                                this.timeVal=true;
                                this.$v.formTiempoPerdido.$touch();
                            }else{
                                this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
                                this.$store.commit('tiempoperdido/mutationModal', false);
                            }
                        } catch (err) {
                            this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
                            this.$store.commit('tiempoperdido/messageMutation', err);
                            this.$store.commit('tiempoperdido/mutationModal', false);
                        }
                    }else{
                        this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
                        this.$store.commit('tiempoperdido/mutationModal', false);
                    }
                }).catch(err => {
                    this.$store.commit('tiempoperdido/messageMutation', error);
                    this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
                    this.$store.commit('tiempoperdido/mutationModal', false);
                });
            }
        }
    }

    export default {
        name: 'app-modal-tiempoPerdido',
        data,
        mixins:[
            ModalMixin
        ],
        mounted,
        components: {
            appCollapseTpTiempoPerdido
        },
        directives: UpperCase,
        validations(){ return FormTiempoPerdido() },
        computed: { 
            idIndentification,
            apiStateLoading,
            apiStateFormLoading,
            
            filteredTipeItems,
            //tituloModal,
            langOption,
            ...mapState({
                idState: state=> state.tiempoperdido.id,
                modalTiempoPerdido: state=> state.tiempoperdido.modalTiempoPerdido,
                apiState: state => state.tiempoperdido.apiState,
                apiStateForm: state => state.tiempoperdido.apiStateForm
            }),
        },
        methods:{
            cambio,
            updatedCollapseTipo,
            cerrarModal,
            registerData,
            InvalidSelect,
            verifyTpTiempoPerdido
        },
        watch:{
            modalTiempoPerdido,
            idState,
            showCollapse: function() {
                if (this.showCollapse){
                    this.updatedCollapseTipo();
                    this.LimpiarCollapse=false;
                }else{
                    this.LimpiarCollapse=true;
                }

            }
        }
    }
</script>

<style lang="scss">
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>