<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{`${$t('label.yardGeneralState')}`}}</h6>
      </CCol>
      <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
          <CButton 
              color="danger"
              class="mr-1"
              size="sm" 
              @click="onBtnPdf()"
              >
              <CIcon name="cil-file-pdf"/>&nbsp; PDF 
          </CButton>
          <CButton
              color="excel"
              class="mr-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
          <CButton
              color="watch"
              size="sm"  
              @click="onBtnExport(false)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; CSV
          </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="2" xl="3">
        <CSelect
          size="sm"
          :horizontal="{ label: 'col-sm-12 col-lg-4 col-xl-4 text-right', input: 'col-sm-12 col-lg-8 col-xl-8'}"
          :label="$t('label.yard')"
        ></CSelect>
      </CCol>
      <CCol sm="12" lg="9">
        <div class="form-group form-row mb-2">
          <label class="col-form-label-sm col-sm-12 col-lg-1 text-right">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-5 col-lg-3">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="TransactionDate"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left"
                  :value="TransactionDate"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="true"
                  @blur="true"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>

          <div class="input-group col-sm-5 col-lg-3">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="TransactionDate"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left"
                  :value="TransactionDate"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="true"
                  @blur="true"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start col-sm-2 col-lg-2">
            <CButton
                color="watch"
                square
                size="sm"
                class="mx-1"
                style="padding: 0.15rem 0.4rem;"
                v-c-tooltip="{
                    content: $t(''),
                    placement: 'top-end'
                }"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
                color="danger"
                square
                size="sm"
                style="padding: 0.15rem 0.4rem;"
                v-c-tooltip="{
                    content: $t(''),
                    placement: 'top-end'
                }"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3" xl="3">
          <CInput
            size="sm"
            :label="$t('label.filter')"
            :horizontal="{ label: 'col-sm-12 col-lg-4 col-xl-4 text-right', input: 'col-sm-12 col-lg-8 col-xl-8'}"
            :placeholder="$t('label.enterSearch')" 
            @keyup="onQuickFilterChanged($event.target.value)"         
          >
          </CInput>  
      </CCol>
      <CCol sm="12" lg="5"></CCol>
      <CCol sm="12" lg="4" xl="4" class="justify-content-end">
          <CSelect
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
            :label="$t('label.recordsPerPage')"
            @change="onPageSizeChanged"
            :options="[50,100,500,1000]"
          >
          </CSelect>
      </CCol>
  </CRow>
  <CRow>
    <CCol col="12" xl="12">
      <ag-grid-vue
          style="width: 100%; height: 100vh;"
          class="ag-theme-alpine"
          :gridOptions="gridOptions"
          :defaultColDef="defaultColDef"
          :localeText="localeText"
          :columnDefs="columnDefs"
          :rowData="RowData"
          :suppressRowClickSelection="true"
          :groupSelectsChildren="true"
          :enableRangeSelection="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :paginationNumberFormatter="paginationNumberFormatter"
          rowSelection="single"
          @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </CCol>
  </CRow>
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { localeKey } from '@/_helpers/locale.grid';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    gridOptions: null,
    defaultColDef: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    gridApi: null,
    columnApi: null,
    DetailJson: [],
    HeaderJson: [],
    ValidPreviousDate: new Date(),
    ValidLaterDate: new Date(),
    previousDate: new Date(),
    laterDate: new Date(),
    IssueDate: '', 
    FinishDate: '',
    TransactionDate: ''
  }
}

//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//methods
function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

//computed
function RowData(){
  return [
    {
      number:'1',
      cliente:'---',
      contenedor:'--j-',
      tipo:'-5--',
      codigo:'--k-',
      imt:'--',
      iir:'----',
      eirs:'--',
      rir:'++',
      emt:'++',
      obo:'/',
      omt:'7',
      oir:'8',
      status:'44',
      almacenaje:'88',
      DocumentCode: '',
      inYard:'44'
    },
    {
      number:'1',
      cliente:'---',
      contenedor:'--j-',
      tipo:'-5--',
      codigo:'--k-',
      imt:'--',
      iir:'----',
      eirs:'--',
      rir:'++',
      emt:'++',
      obo:'/',
      omt:'7',
      oir:'8',
      status:'44',
      almacenaje:'88',
      DocumentCode: '',
      inYard:'44'
    },
  ]
}

function localeText(){
    let AG_GRID_LOCALE = {};
    for (let index = 0; index < localeKey.length; index++) {
        const element = localeKey[index];
        AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
    }
    return AG_GRID_LOCALE;
}

function columnDefs(){
    let columnDefs = [
        {
            field: "number",
            headerName: '#',
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 100,
        },
        {
            field: "cliente",
            headerName: this.$t('label.client'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "contenedor",
            headerName: this.$t('label.container'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
        },
        {
            field: "tipo",
            headerName: this.$t('label.type'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 100,
        },
        {
            field: "codigo",
            headerName: this.$t('label.isoCode'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
        },
        {
            headerName: this.$t('label.receptionInYard'),
            headerClass: 'Entry-state-yard-header text-white center-cell',
            cellClass: 'center-cell',
            children: [
              {
                field: 'imt',
                headerName: 'I-MT',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'iir',
                headerName: 'I-IR',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'eirs',
                headerName: "EIR'S",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              }
            ],
        },
        {
            headerName: this.$t('CAMBIO DE ESTATUS'),
            headerClass: 'Status-state-yard-header text-white center-cell',
            cellClass: 'center-cell',
            children: [
            
              {
                field: 'rir',
                headerName: 'R-IR',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'emt',
                headerName: 'E-MT',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
            ],
        },
        {
            headerName: this.$t('label.dispatchs'),
            headerClass: 'Dispatched-state-yard-header text-white center-cell',
            cellClass: 'center-cell',
            children: [
            
              {
                field: 'obo',
                headerName: 'O-BO',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'omt',
                headerName: 'O-MT',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'oir',
                headerName: 'O-IR',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              },
              {
                field: 'eirs',
                headerName: "EIR'S",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 90
              }
            ],
        },
        {
            headerName: this.$t('label.inventory'),
            headerClass: 'Inventary-state-yard-header text-white center-cell',
            cellClass: 'center-cell',
            children: [
            
              {
                field: 'inYard',
                headerName: this.$t('label.daysInYard'), 
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 150
              },
              {
                field: 'almacenaje',
                headerName: 'DÍAS DE ALMACENAJE',
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 200
              },
              {
                field: 'status',
                headerName: this.$t('label.status'), 
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell',
                cellClass: 'center-cell',
                lockPosition: true,
                minWidth: 150
              }
            ],
        },
        {
            field: "observaciones",
            headerName: this.$t('label.MOVIMIENTO.observation'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            spanHeaderHeight: true,
            minWidth: 180
        },
    ]
    return columnDefs;
}

async function onBtnPdf(valor){
}

async function onBtnExport(valor){
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

export default {
  name: 'state-yard-export-list',
  components: {
   },
  data,
  beforeMount,
  mixins: [GeneralMixin],
  methods: {
    onGridReady,
    onQuickFilterChanged,
    onPageSizeChanged,
    onBtnPdf,
    onBtnExport,
    validateDateRange
  },
  computed: {
    RowData,
    columnDefs,
    localeText
  },
  directives: UpperCase,
  watch: {
    TransactionDate: async function(val){
      try{
        if(val != ''){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorAtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorEtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorAtdPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
              throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
          }
          if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
              throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
};
</script>
<style>
.Entry-state-yard-header{
    background: #1f3864 !important;
}
.Entry-state-yard-header .ag-header-group-text{
    width: 100%;
}
.Status-state-yard-header{
    background: #ed7d31 !important;
}
.Status-state-yard-header .ag-header-group-text{
    width: 100%;
}
.Dispatched-state-yard-header{
    background: #616161 !important;
}
.Dispatched-state-yard-header .ag-header-group-text{
    width: 100%;
}
.Inventary-state-yard-header{
    background: #5797e6 !important;
}
.Inventary-state-yard-header .ag-header-group-text{
    width: 100%;
}
.center-cell {
    text-align: center;
    vertical-align: middle;
    user-select: initial;
}
.ag-header-group-cell-label, .ag-header-cell-label{
    text-align: center;
    justify-content: center;
    align-items: center;
}

</style>