<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
            <modal-unit-measure :titleModalUnitMeasure="titleModalUnitMeasure"/>
            <modal-unit-measure-type :titleModalUnitMeasureType="titleModalUnitMeasureType"/>
            <CCard>
                <CCardHeader class="text-center bg-dark text-white">
                    <b>{{$t('label.measurementUnitsList')}}</b>
                </CCardHeader>
                <CCardBody>
                    <CRow class="mt-2">
                        <CCol sm="12" lg="12" xl="12">
                            <CustomTabs :activeTab="0">
                                <CustomTab :title="$t('label.measureUnit')" >
                                    <CRow>
                                        <CCol sm="12" class="d-flex justify-content-end">
                                            <!--<CButton
                                                @click="setRefresh"
                                                color="dark"
                                                style="margin-right: 10px;"
                                            >
                                                <CIcon name="cil-sync"/>&nbsp; Refrescar datos
                                            </CButton>-->
                                            &nbsp;&nbsp;
                                            <CButton
                                                color="add"
                                                @click="showModalCreateUnitMeasure()"
                                                v-c-tooltip="{placement:'top-end',content: $t('label.MeasurementUnit')}"
                                            >
                                                <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nueva')}}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow class="mt-2">    
                                        <CCol sm="12">
                                            <!--<CRow>
                                                <CCol sm="4">
                                                    <CSelect
                                                        label="Filtro"
                                                        :options="optionsListFiltro"
                                                        :value.sync="$store.state.unidadmedida.filtroUnidad"
                                                        @input="$store.commit('unidadmedida/mutationfiltroUnidad', $event.target.value)"
                                                    />
                                                </CCol>
                                            </CRow>-->
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                hover
                                                sorter
                                                small
                                                column-filter                                                                      
                                                :items="formatedItemsUnitMeasure"
                                                :fields="fieldsUnitMeasure"
                                                :noItemsView="tableText.noItemsViewText"
                                                :items-per-page="5"
                                                :loading="apiStateLoading"
                                                pagination
                                                size="sm"
                                                :table-filter="tableText.tableFilterText"
                                                :items-per-page-select="tableText.itemsPerPageText"
                                            >
                                                <template #loading>
                                                    <loading/>
                                                </template>
                                                <template #UnitMeasureId="{ index }">
                                                    <td class="text-center">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #Status="{item}">
                                                    <td class="text-center">
                                                        <CBadge v-if="item.status" color="success">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                        <CBadge v-if="!item.status" color="danger">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                    </td>
                                                </template>
                                                <template #acciones="{item, index}">
                                                    <td class="text-center">
                                                        <CButton
                                                            color="edit"
                                                            square
                                                            size="sm"
                                                            class="mr-1"
                                                            v-c-tooltip="{
                                                                content: $t('label.edit') + ' ' + $t('label.MeasurementUnit'),
                                                                placement: 'top-end'
                                                            }"
                                                            @click="showModalEditUnitMeasure(item, index)"
                                                        >
                                                            <CIcon name="pencil"/>
                                                        </CButton>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCol>
                                    </CRow>
                                </CustomTab>
                                <CustomTab :title="$t('label.measureUnitType')" >
                                    <CRow>
                                        <CCol sm="12" class="d-flex justify-content-end">
                                            <!--<CButton
                                                @click="setRefresh"
                                                color="dark"
                                                style="margin-right: 10px;"
                                            >
                                                <CIcon name="cil-sync"/>&nbsp; Refrescar datos
                                            </CButton>-->
                                            &nbsp;&nbsp;
                                            <CButton
                                                color="add"
                                                @click="showModalCreateUnitMeasureType()"
                                                v-c-tooltip="{placement:'top-end',content: $t('label.measureUnitType')}"
                                            >
                                                <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow class="mt-2">    
                                        <CCol sm="12">
                                            <!--<CRow>
                                                <CCol sm="4">
                                                    <CSelect
                                                        label="Filtro"
                                                        :options="optionsListFiltro"
                                                        :value.sync="$store.state.unidadmedida.filtroUnidad"
                                                        @input="$store.commit('unidadmedida/mutationfiltroUnidad', $event.target.value)"
                                                    />
                                                </CCol>
                                            </CRow>-->
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                hover
                                                sorter
                                                small
                                                column-filter                                                                      
                                                :items="formatedItemsUnitMeasureType"
                                                :fields="fieldsUnitMeasureType"
                                                :noItemsView="tableText.noItemsViewText"
                                                :items-per-page="5"
                                                :loading="apiStateLoading"
                                                pagination
                                                :table-filter="tableText.tableFilterText"
                                                :items-per-page-select="tableText.itemsPerPageText"
                                            >
                                                <template #loading>
                                                    <loading/>
                                                </template>
                                                <template #UnitMeasureId="{ index }">
                                                    <td class="text-center">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #Status="{item}">
                                                    <td class="text-center">
                                                        <CBadge v-if="item.status" color="success">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                        <CBadge v-if="!item.status" color="danger">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                    </td>
                                                </template>
                                                <template #acciones="{item, index}">
                                                    <td class="text-center">
                                                        <CButton
                                                            color="edit"
                                                            square
                                                            size="sm"
                                                            class="mr-1"
                                                            v-c-tooltip="{
                                                                content: $t('label.edit') + ' ' + $t('label.measureUnitType'),
                                                                placement: 'top-end'
                                                            }"
                                                            @click="showModalEditUnitMeasureType(item, index)"
                                                        >
                                                            <CIcon name="pencil"/>
                                                        </CButton>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCol>
                                    </CRow>
                                </CustomTab>
                            </CustomTabs>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
</style>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import modalUnitMeasure from './modal-unit-measure';
    import modalUnitMeasureType from './modal-unit-measure-type';

    import GeneralMixin from '@/_mixins/general';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    const dataPais = [];

    function fieldsUnitMeasure(){
        return [
            { key: 'UnitMeasureId', label: '#',_classes: 'text-center', _style: 'width:5%; text-align:center', filter: false  },
            { key: 'UnitMeasureName',label: this.$t('label.unitSize'), _classes: 'text-uppercase', _style:'width:20%' },
            { key: 'TpUnitMeasureName',label: this.$t('label.type'), _classes: 'text-uppercase', _style:'width:15%'  },
            { key: 'UnitMeasureAbbrev',label: this.$t('label.serialNumber'), _classes: 'text-uppercase', _style:'width:15%'  },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:13%'},
            { key: 'FormatedDate', label: this.$t('label.date'), _classes: 'text-center text-uppercase', _style:'width:12%'},
            { key: 'Status', label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:12%'},
            { key: 'acciones', label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false }
        ]
    }

    function fieldsUnitMeasureType(){
        return [
            { key: 'RowNumber', label: '#',_classes: 'text-center', _style: 'width:5%; text-align:center', filter: false },
            { key: 'TpUnitMeasureName',label: this.$t('label.measureUnitType'), _classes: 'text-uppercase', _style:'width:43%' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:15%'},
            { key: 'FormatedDate', label: this.$t('label.date'), _classes: 'text-center text-uppercase', _style:'width:15%'},
            { key: 'Status', label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:15%'},
            { key: 'acciones', label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false }
        ]
    }
    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',                      
            activePage: 1,
            optionsListFiltro:[{value:"ALL",label:"TODOS"},{value:"ACTIVO",label:"ACTIVOS"},{value:"INACTIVO",label:"INACTIVOS"}],
            titleModalUnitMeasure: '',
            titleModalUnitMeasureType: '',
        }
    }

    //mounted
    function mounted() {
        this.$store.state.unidadmedida.apiState = ENUM.LOADED;
        this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
        this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
        this.$store.dispatch('unidadmedida/getUnidadlist','ALL');
        this.$store.dispatch('unidadmedida/getTipoUnidadlist','ALL');
    }
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    //computed
    function computedItemsUnitMeasure () {
        return this.$store.getters["unidadmedida/myDatatable"];
    }
    function formatedItemsUnitMeasure() {
        return this.computedItemsUnitMeasure.filter(item => {
            return item
        })
    }
 
    function computedItemsUnitMeasureType () {
        return this.$store.getters["unidadmedida/myDatatableUnitMeasureType"];
    }
    function formatedItemsUnitMeasureType() {
        return this.computedItemsUnitMeasureType.filter(item => {
            return item
        })
    }

    //methods
    function showModalCreateUnitMeasure(){
        this.titleModalUnitMeasure = this.$t('label.nueva')+' '+this.$t('label.MeasurementUnit');
        this.$store.commit('unidadmedida/mutationModalUnitMeasure', true);

    }
    function showModalEditUnitMeasure(item,index){
        this.titleModalUnitMeasure = this.$t('label.edit') + ' ' + this.$t('label.MeasurementUnit')+ ': ' +item.UnitMeasureName;
        this.$store.commit('unidadmedida/mutationModalUnitMeasure', true);
        this.$store.commit('unidadmedida/setUnitMeasureId', item.UnitMeasureId);
    }
    function showModalCreateUnitMeasureType(){
        this.titleModalUnitMeasureType = this.$t('label.nuevo')+' '+this.$t('label.measureUnitType');
        this.$store.commit('unidadmedida/mutationModalUnitMeasureType', true);

    }
    function showModalEditUnitMeasureType(item,index){
        this.titleModalUnitMeasureType = this.$t('label.edit') + ' ' + this.$t('label.measureUnitType')+ ': ' +item.TpUnitMeasureName;
        this.$store.commit('unidadmedida/mutationModalUnitMeasureType', true);
        this.$store.commit('unidadmedida/setUnitMeasureTypeId', item.TpUnitMeasureId);
    }
    function setRefresh(){
        this.$store.dispatch('unidadmedida/getUnidadMedidalist');
    }

    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.unidadmedida.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.unidadmedida.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.unidadmedida.messageError = '';
                break;
            default:
                break;
        }
    }
    function filtroUnidad(newQuestion,OldQuestion){
        this.$store.dispatch('unidadmedida/getUnidadlist',newQuestion);
    }

    export default{
        name: 'app-unidadmedida',
        data,
        mixins: [GeneralMixin],
        components: {
            modalUnitMeasure, modalUnitMeasureType,CustomTabs, CustomTab
        },
        methods: {
            showModalCreateUnitMeasure,
            showModalEditUnitMeasure,
            showModalCreateUnitMeasureType,
            showModalEditUnitMeasureType,
            setRefresh,
        },
        mounted,
        computed: {
            computedItemsUnitMeasure,
            computedItemsUnitMeasureType,
            formatedItemsUnitMeasure,
            formatedItemsUnitMeasureType,
            fieldsUnitMeasure,
            fieldsUnitMeasureType,
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                idState: state=> state.unidadmedida.id,
                apiState: state => state.unidadmedida.apiState,
                apiStateForm: state => state.unidadmedida.apiStateForm,
                filtroUnidad: state => state.unidadmedida.filtroUnidad,
                messageError: state => state.unidadmedida.messageError,
            })
        },
        watch:{
            filtroUnidad,
            apiState,
            apiStateForm
        }
    }
</script>