<template>
    <div class="h100">
        <div v-if="false">
            <CRow class="d-flex h100">
                <CCol sm="12" class="d-flex justify-content-center align-items-center">
                    <CButton size="sm"
                    color="add" 
                    @click="reloadingRequest()">
                        reload
                    </CButton>
                </CCol>
            </CRow>
        </div>
        <slot></slot>
    </div>
</template>
<script>
//computed 
import loadingOverlay from './loadingOverlay.vue'
import {mapState} from 'vuex';
async function reloadingRequest() {
    let pendingRequests = this.$store.state.request.pendings;
    pendingRequests = await pendingRequests.map((element)=>{
        return     this.$http.get(element.ruta, element.parametro);
    })
    

    Promise.all(pendingRequests)
        .then((responses) => {
            this.$store.state.request.pendings = [];
            this.$store.state.request.responsePendings = responses;
        }).catch((err) => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        }).then(() => {
            //console.log("finalizo");
        });
}
export default {
    name: 'requestPending',
    components:{
        loadingOverlay
    },
    methods:{
        reloadingRequest,
    },
};
</script>
<style scoped>
.h100{
    height: 100%;
}
</style>