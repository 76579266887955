<template>
    <div>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <div class="m-3" >
        <CCardBody >
            <CRow class="mt-3">
              <!-- imagen -->
              <CCol sm="12" lg="4" v-show="$store.state.maquina.machineRoute == ''">
                  <div class="preview">
                      <picture-input
                          ref="imageInputNew"
                          :imgSrc="image"
                          height="186"
                          width="186"
                          accept="image/jpeg,image/png,image"
                          size="10"
                          :custom-strings="imgInputTexts"
                          @change="handleFileUploadNew"
                          v-if="$store.state.maquina.tabMachine==1"
                      >
                      </picture-input>
                  </div>
                  <div v-if="$v.formPhotoMachine.MachinePhotoRoute.$error">
                      <div class='text-danger' v-if="!$v.formPhotoMachine.MachinePhotoRoute.isValidFile">
                          {{$t('label.badImage')}}
                      </div>
                  </div>
              </CCol>
              <CCol sm="12" lg="4" v-show="$store.state.maquina.machineRoute != ''">
                  <div class="preview">
                      <picture-input
                          ref="imageInputEdit"
                          :imgSrc="formPhotoMachine.Image_url"
                          height="186"
                          width="186"
                          accept="image/jpeg,image/png,image"
                          size="10"
                          :custom-strings="imgInputTexts"
                          @change="handleFileUploadEdit"
                          v-if="$store.state.maquina.machineRoute != ''"
                      >
                      </picture-input>
                  </div>
                  <div v-if="$v.formPhotoMachine.MachinePhotoRoute.$error">
                      <div class='text-danger' v-if="!$v.formPhotoMachine.MachinePhotoRoute.isValidFile">
                          {{$t('label.badImage')}}
                      </div>
                  </div>
              </CCol>
              <CCol sm="12" lg="6">
                <CRow>
                  <CCol sm="12">
                      <CInput
                          :label="$t('label.machineName')"
                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                          add-label-classes=" text-right"  
                          v-uppercase                
                          v-model="$store.state.maquina.NewMachineName"
                          readonly
                          size="sm"
                          >
                      </CInput>
                  </CCol>
                  <CCol sm="12">
                      <CTextarea
                          :label="$t('label.description')"
                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                          add-label-classes="required text-right"
                          type="textarea"
                          :placeholder="$t('label.description')"
                          v-uppercase
                          v-model="$v.formPhotoMachine.MachinePhotoDs.$model"
                          :addInputClasses="{ 'is-invalid': $v.formPhotoMachine.MachinePhotoDs.$error }"
                          :invalid-feedback="errorMessage($v.formPhotoMachine.MachinePhotoDs)"
                          @blur="$v.formPhotoMachine.MachinePhotoDs.$touch()"
                          :plaintext="false"
                          :readonly="false"
                          :lazy="(true,400)"
                          size="sm"
                          maxlength="500"
                          :is-valid="hasError($v.formPhotoMachine.MachinePhotoDs)"

                      >
                      </CTextarea>
                  </CCol>
                  <CCol sm="12" v-if="isEdit">
                      <CSelect
                          :label="$t('label.status')"
                          :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                          add-label-classes="required text-right"
                          :is-valid="formPhotoMachine.FgActPhotoMachine"
                          v-model="formPhotoMachine.FgActPhotoMachine"
                          :value.sync="formPhotoMachine.FgActPhotoMachine"
                          :options="selectOptions"
                          size="sm"
                      />
                  </CCol>
                </CRow>
              </CCol>  
              <CCol sm="12" lg="auto" class="text-right">
                <CButton
                    color="add"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.add'),
                      placement: 'top-end'
                    }"
                    @click="sendDate()"  
                    >
                    <CIcon name="checkAlt" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.clearFilters'),
                      placement: 'top-end'
                    }"
                    @click="resetComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
              </CCol>
            </CRow> 
          </CCardBody>
        </div>
    </div>         
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { FormPhotoMachine } from '@/_validations/maquina/MaquinaValidation';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import { GenerateImgPlaceholder } from '@/_helpers/funciones';
    import PictureInput from '@/components/picture-input';

    import GeneralMixin from '@/_mixins/general';
    import { errorMessage } from '@/_helpers/funciones';

//Data
function data() {
  let _lang = this.$i18n.locale;
  return {
    isSubmit: false,   
    Loading: false,
    isEdit: false,
    image: _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
    formPhotoMachine:{
      IdMachinePhoto:0,
      MachineId:'',
      MachinePhotoDs:'',
      MachinePhotoRoute:'',
      Image_url:_lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
      FgActPhotoMachine:true,
    },
    formMachine:{            
      MachineId:'',
      MachineName:'',    
    },
    Image_original:null,
    srcError:'/img/errorimage.jpg',
    originalAct:true,
    selectOptions: [
      { 
        value: true, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: false, 
        label: this.$t('label.INACTIVO')
      }
    ],
  }
}

//Methods
function handleFileUploadNew(image) { 
  this.formPhotoMachine.MachinePhotoRoute = this.$refs.imageInputNew.file;
  this.$v.formPhotoMachine.MachinePhotoRoute.$touch();     
}
function handleFileUploadEdit(image) { 
  this.formPhotoMachine.MachinePhotoRoute = this.$refs.imageInputEdit.file;
  this.$v.formPhotoMachine.MachinePhotoRoute.$touch();     
}
function sendDate(){
  if(this.idState !== 0){
    try {
      this.$store.state.maquina.apiStateForm = ENUM.LOADING;
      let _lang = this.$i18n.locale;
      this.$v.formPhotoMachine.$touch();

      if (this.$v.formPhotoMachine.$pending || this.$v.formPhotoMachine.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }

      if (this.$v.formPhotoMachine.$pending || this.$v.formPhotoMachine.$error) return;

      this.formPhotoMachine.MachineId = this.idState;
      
      let { IdMachinePhoto,MachineId ,MachinePhotoDs,MachinePhotoRoute,FgActPhotoMachine } 
      = this.formPhotoMachine;
      
      const Image_original = this.Image_original;
      const { dispatch } = this.$store;
      if(this.originalAct !== '' && IdMachinePhoto !== 0){
        if(this.originalAct !== FgActPhotoMachine){
          this.$swal.fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+' '} ${MachinePhotoDs}?`,
          }))     
          .then((result) => {
            if (result.isConfirmed) {
              dispatch('maquina/sendDataDocMachine', { IdMachinePhoto,MachineId,MachinePhotoDs,MachinePhotoRoute,FgActPhotoMachine,Image_original });     
              this.resetComponent();
            }
        })
        }else{//
          dispatch('maquina/sendDataDocMachine', { IdMachinePhoto,MachineId,MachinePhotoDs,MachinePhotoRoute,FgActPhotoMachine,Image_original });
          this.resetComponent();
        }
      }else{
        dispatch('maquina/sendDataDocMachine', { IdMachinePhoto,MachineId,MachinePhotoDs,MachinePhotoRoute,FgActPhotoMachine,Image_original });
        this.resetComponent();
      }
      this.$store.state.maquina.apiStateForm = ENUM.INIT;
    } catch (error) {
      this.$store.commit('maquina/messageMutation', error);
      this.$store.state.maquina.apiStateForm = ENUM.ERROR;
    }  
  }else{
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.registerMachineFirst'),
      type: "error"
    });
  }


}
function machineRoute(newMachine) {
  this.resetImage();
  if(this.machineRoute != null && this.machineRoute != ''){

    this.formPhotoMachine.Image_url = this.machineRoute;
  }else{
    let _lang = this.$i18n.locale;
    this.formPhotoMachine.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
  }

  if(this.machineRoute != null && this.machineRoute != ''){
    this.formPhotoMachine.IdMachinePhoto=this.dataMachine[0].MachineDocId;                       
    this.formPhotoMachine.MachineId=this.dataMachine[0].MachineId;

    this.formPhotoMachine.MachinePhotoDs=this.dataMachine[0].MachineDocDesc;
    this.formPhotoMachine.FgActPhotoMachine=this.dataMachine[0].FgActMachineDoc;
    this.formPhotoMachine.MachinePhotoRoute = this.dataMachine[0].RouteMachineDoc;
    this.originalAct = this.dataMachine[0].FgActMachineDoc;
    this.isEdit = true;
    this.$v.formPhotoMachine.$touch();
  }
}
function CollapseMachine(newMachine) {
  if(newMachine == false){
    
    this.formPhotoMachine.IdMachinePhoto = 0;
    this.formPhotoMachine.MachinePhotoDs = '';
    this.formPhotoMachine.MachinePhotoRoute = '';

    this.formPhotoMachine.FgActPhotoMachine=true;
    this.originalAct = true;
    this.isEdit = false;
    this.Image_original = null;

    this.resetImage();

    this.$nextTick(() => { this.$v.formPhotoMachine.$reset() });
  }
}
function resetForm(){
  let _lang = this.$i18n.locale;
  this.formPhotoMachine.IdMachinePhoto = 0;
  this.formPhotoMachine.MachinePhotoDs = '';
  this.formPhotoMachine.MachinePhotoRoute = '';
  
  this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
  
  this.formPhotoMachine.FgActPhotoMachine=true;
  this.originalAct = true;
  this.isEdit = false;
  this.Image_original = null;

  this.resetImage();

  this.$nextTick(() => { this.$v.formPhotoMachine.$reset() });
}
function resetImage(){
  if(this.$refs.imageInputNew != undefined){
    this.$refs.imageInputNew.removeImage();
    this.$refs.imageInputNew.value=null;
    this.$refs.imageInputNew.file=null;
  }
  if(this.$refs.imageInputEdit != undefined){
    this.$refs.imageInputEdit.removeImage();
    this.$refs.imageInputEdit.value=null;
    this.$refs.imageInputEdit.file=null;
  }
}
function resetComponent(){
  this.resetForm();
  this.$store.state.maquina.machineRoute = '';
  this.$store.state.maquina.tabMachine==1;
}

export default {
  name: 'collapse-add-image-machine',
  mixins: [TipoGruaMixin, GeneralMixin],
  props: {
    dataMachine: null,
  },
  components: {
    PictureInput
  },
  data,
  validations(){ return FormPhotoMachine() },
  directives: UpperCase,
  methods: {
    handleFileUploadNew,
    handleFileUploadEdit,
    sendDate,
    resetForm,
    resetImage,
    resetComponent,
  },
  computed: {
    ...mapState({
      idState: state => state.maquina.idState,
      CollapseMachine: state=> state.maquina.CollapseMachine,
      apiState: state => state.maquina.apiState,
      apiStateForm: state => state.maquina.apiStateForm,
      tabMachine: state => state.maquina.tabMachine,
      formReset: state => state.maquina.formReset,
      branch: state => state.auth.branch,
      newMachineName: state => state.maquina.NewMachineName,
      newMachineId: state => state.maquina.NewMachineId,
      machineRoute: (state) => state.maquina.machineRoute,

    })
  },
  watch: {
    tabMachine: async function (newValue, OldValue) {   
      if(newValue == 1){
        if(this.idState === 0){
          this.formPhotoMachine.MachineId = '';
          this.formMachine.MachineId = '';
          this.formMachine.MachineName = '';
        }else{
          this.formMachine.MachineName= this.newMachineName;   
          this.formPhotoMachine.MachineId=this.newMachineId; 
        }
      }
    },
    CollapseMachine,
    machineRoute,
  },
}
</script>
<style scoped>
</style>