<template>
  <form>
    <loading-overlay
      :active="loadingOverlay"
      :is-full-page="true"
      loader="bars"
    />
    <CRow class="mb-3">
      <CCol sm="8" class="d-flex align-items-center">
        <CButton
            color="edit"
            class="mr-2"
            v-c-tooltip="{
              content: $t('label.return'),
              placement: 'top',
            }"
            size="sm"
            @click="close"
          >
            <CIcon name="cil-chevron-left-alt" />
          </CButton>
          <h6 class="mt-2"> {{ $t('label.nueva')+' '+$t('label.YardCertification') }}</h6>
      </CCol>  
    </CRow>
    <CRow>
      <CCol sm="12" lg="5">
        <div class="form-group form-row"  >
          <label class="text-right col-sm-12 col-lg-7 col-form-label-sm">{{ $t('label.TotalHoldCertification')+$t('label.metricTonnageAcronym') }}</label>
          <div class="input-group col-sm-12 col-lg-5 input-group-sm">
            <money
              v-uppercase
              size="sm"
              :disabled="true"
              v-model.trim="TotalHoldCertification"
              v-bind="measure" 
              class="form-control input-header text-white"
            >
            </money>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5">
        <div class="form-group form-row"  >
          <label class="col-sm-12 col-lg-5 text-right col-form-label-sm">{{$t('label.InspectionRange')}}</label>
          <div class="col-sm-12 col-lg-7 input-group input-group-sm">
            <label class="col-sm-12 col-lg-12 input-header rounded col-form-label-sm text-white">{{$t('label.from')+':'+' - '+$t('label.to')+': '}}</label>
          </div>
        </div>
      </CCol>  
    </CRow>
    <CustomTabs :active-tab="tab" @update:activeTab="handleTab">
      <CustomTab :title="$t('label.GeneralSummary')" icon="general" active>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedOptionsList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #options="{ item }">
              <td class="center-cell" v-if="item.FgActStowagePlanningLogWeigth">       
                <CButton
                  :disabled="!item.FgActStowagePlanningLogWeigth"
                  square
                  size="sm"
                  color="edit"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.FullWeight'),
                    placement: 'top-start'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CustomTab>
      <CustomTab :title="$t('label.DetailsOfBinsWeighted')" active>
        <CCol sm="12" class="mt-1">
          <CRow sm="12">
            <CCol sm="12" lg="auto">  <!-- v-model="FgGeneral" -->
              <div class="form-group form-row d-flex align-items-center justify-content-end">
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1"> {{$t('label.resumido')}}</label>
                <input type="radio" :value="0" v-model="BinsWeighted.Summary" @change="Tablefilter"/>
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1 ml-2"> {{$t('label.Detallado')}}</label>
                <input type="radio" :value="1" v-model="BinsWeighted.Summary" @change="Tablefilter"/>
              </div>
            </CCol>
            <CCol sm="12" lg="3" xl="3" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9 px-0'}"
                :label="$t('label.yard')"
                :options="YardListFormatted"
                v-model.trim="BinsWeighted.YardId"
                :value.sync="BinsWeighted.YardId"
                @change="Tablefilter"
              />
            </CCol>
            <CCol sm="12" lg="4" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-7'}"
                :label="$t('label.transport')"
                v-model.trim="BinsWeighted.Transportation"
                :value.sync="BinsWeighted.Transportation"
                :options="transportationList"
                @change="Tablefilter"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedOptionsList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
          </dataTableExtended>
        </CCol>
      </CustomTab>
      <CustomTab :title="$t('label.DetailsOfBinsWeightless')" active>
        <CCol sm="12" class="mt-1">
          <CRow sm="12">
             <CCol sm="12" lg="auto">  <!-- v-model="FgGeneral" -->
              <div class="form-group form-row d-flex align-items-center justify-content-end">
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1"> {{$t('label.resumido')}}</label>
                <input type="radio" :value="0" v-model="BinsWeightless.Summary" @change="Tablefilter"/>
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1 ml-2"> {{$t('label.Detallado')}}</label>
                <input type="radio" :value="1" v-model="BinsWeightless.Summary" @change="Tablefilter"/>
              </div>
            </CCol>
            <CCol sm="12" lg="3" xl="3" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                :label="$t('label.yard')"
                :options="YardListFormatted"
                v-model.trim="BinsWeightless.YardId"
                :value.sync="BinsWeightless.YardId"
                @change="Tablefilter"
              />
            </CCol>
            <CCol sm="12" lg="4" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-7'}"
                :label="$t('label.transport')"
                v-model.trim="BinsWeightless.Transportation"
                :value.sync="BinsWeightless.Transportation"
                :options="transportationList"
                @change="Tablefilter"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedOptionsList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
          </dataTableExtended>
        </CCol>
      </CustomTab>
      <CustomTab :title="$t('label.DetailsOfBinsLoaded')" active>
        <CCol sm="12" class="mt-1">
          <CRow sm="12">
            <CCol sm="12" lg="auto">  <!-- v-model="FgGeneral" -->
              <div class="form-group form-row d-flex align-items-center justify-content-end">
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1"> {{$t('label.resumido')}}</label>
                <input type="radio" :value="0" v-model="BinsLoaded.Summary" @change="Tablefilter"/>
                <label class="col-form-label-sm col-sm-auto pb-1 mt-1 ml-2"> {{$t('label.Detallado')}}</label>
                <input type="radio" :value="1" v-model="BinsLoaded.Summary" @change="Tablefilter"/>
              </div>
            </CCol>
            <CCol sm="12" lg="3" xl="3" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                :label="$t('label.yard')"
                :options="YardListFormatted"
                v-model.trim="BinsLoaded.YardId"
                :value.sync="BinsLoaded.YardId"
                @change="Tablefilter"
              />
            </CCol>
            <CCol sm="12" lg="4" class="mt-1">
              <CSelect
                size="sm"
                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-7'}"
                :label="$t('label.transport')"
                v-model.trim="BinsLoaded.Transportation"
                :value.sync="BinsLoaded.Transportation"
                :options="transportationList"
                @change="Tablefilter"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedOptionsList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
          </dataTableExtended>
        </CCol>
      </CustomTab>
    </CustomTabs>
  </form>  
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import { DateFormater, tableTextTranslatedHelpers, NumberFormater } from '@/_helpers/funciones';
  import { required, maxLength } from 'vuelidate/lib/validators';
  import { VMoney, Money } from "v-money";
  
  function data() {
    return {
      tab: 0,
      BinsWeighted:{
        Summary: 0,
        Transportation: '',
        YardId: '',
      },
      BinsWeightless:{
        Summary: 0,
        Transportation: '',
        YardId: '',
      },
      BinsLoaded:{
        Summary: 0,
        Transportation: '',
        YardId: '',
      },
      TransportationOptions: [],
      YardOptions: [],
      Options: [],
      TotalHoldCertification: 0,
      Observation: '',
      loadingOverlay: false,
      isSubmit: false,
      measure: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
        maxlength: 14,
      },
    };
  }

  function Tablefilter() {
    
  } 

  function submit() {
    try{
    /*
        this.loadingOverlay = true;
        this.$v.$touch();
        if (this.$v.$error) {
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsPleaseCheck');
        }
        let LogisticJson = {
            StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
            Observation: this.Observation,
        }
        this.$http
            .put('', LogisticJson, { root: 'LogisticJson' })
            .then((response) => {
                if (response && response.status === (200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.loadingOverlay = false;
                    this.isSubmit = false;
                    this.close();
                    this.$notify({
                        group: 'container',
                        title: '¡'+this.$t('label.success')+'!',
                        text: messageSuccess,
                        type: "success"
                    });
                    } 
                }).catch((e) => {
                    this.loadingOverlay = false;
                    this.isSubmit = false;
                    this.$notify({
                        group: "container",
                        title: "¡Error!",
                        text: e,
                        type: "error",
                    });
                });*/
    }catch (e) {
        this.isSubmit = false;
        this.loadingOverlay = false;
        this.$notify({
            group: "container",
            title: "¡Error!",
            text: e,
            type: "error",
        });
    }
    
  }

  function getYardList() {
    // this.loadingOverlay = true;
    this.$http.get("VisitYardWithTruckWeigth-list", { VisitId: this.Visit })
      .then(response => {       
        this.YardOptions = response.data.data;
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
       // this.loadingOverlay = false;
      });
  }

  function getTransportationList() {
    //this.loadingOverlay = true;
    this.$http.get("VisitCarrier-list", { VisitId: this.Visit })
      .then(response => {
        this.TransportationOptions = response.data.data;
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
      //this.loadingOverlay = false;
    });
  }

  function handleTab(Tab) {
    this.tab = Tab;
  }
  
  function close() {
    this.$emit('close-Collapse');
  }

  function Reset() {
    this.BinsWeighted.Summary = 0;
    this.BinsWeighted.Transportation = '';
    this.BinsWeightless.Summary = 0;
    this.BinsWeightless.Transportation = '';
    this.BinsLoaded.Summary = 0;
    this.BinsLoaded.Transportation = '';
    this.TransportationOptions = [];
    this.Options = [];
    this.TotalHoldCertification = 0;
    this.Observation = '';
  }

  function transportationList(){
    if(this.TransportationOptions.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.TransportationOptions.map(function(e){
        chart.push({
          value: e.StowagePlanningResourceId, 
          label: e.ClientName,
        })
      })
      return chart;
    }
  }

  function YardListFormatted(){
    if(this.YardOptions.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.YardOptions.map(function(e){
        chart.push({
          value: e.YardId, 
          label: e.YardName,
        })
      })
      return chart;
    }
  }
  
  function title(){
    return this.$t('label.delete')+' '+this.$t('label.FullWeight')+': ';
  }

  function tableText(){
    return tableTextTranslatedHelpers(this);
  }

  function computedOptionsList() {
    if(this.Options.length > 0){
        let _lang = this.$i18n.locale;
        switch(this.tab) {
          case(0):{
            return this.Options.map((item) => {
                return {
                    ...item,
                    _classes: '',
                    FormatedDate: item.TransactionDate ? DateFormater.formatToNormalDateTime(item.TransactionDate) : 'N/A',
                };
            });
          };
          case(1):{
            return this.Options.map((item) => {
                return {
                    ...item,
                    _classes: '',
                    FormatedDate: item.TransactionDate ? DateFormater.formatToNormalDateTime(item.TransactionDate) : 'N/A',
                };
            });
          };
          case(2):{
            return this.Options.map((item) => {
                return {
                    ...item,
                    _classes: '',
                    FormatedDate: item.TransactionDate ? DateFormater.formatToNormalDateTime(item.TransactionDate) : 'N/A',
                };
            });
          };
          case(3):{
            return this.Options.map((item) => {
                return {
                    ...item,
                    _classes: '',
                    FormatedDate: item.TransactionDate ? DateFormater.formatToNormalDateTime(item.TransactionDate) : 'N/A',
                };
            });
          };
        }
    }else{
        return this.Options = [];
    }
  }

  function fields(){
    switch(this.tab) {
      case(0):{
        return [
          { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
          { label: this.$t('label.gamelasQuantity'), key: '', _style: 'width:10%', _classes: 'text-uppercase', },
          { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.client'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.InternalBl'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.BinsWeighted'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.BinsWeightless'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.BinsLoaded'), key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.WeightCorrection')+' (TON)', key: '', _classes: 'text-uppercase', _style: 'width:10%'},
          { label: this.$t('label.WeighingScaleWeight')+' (TON)', key: '', _classes: 'text-uppercase', _style: 'width:12%'},
          { label: '', key: 'options', _style: 'min-width:45px;', sorter: false, filter: false },
        ];
      };
      case(1):{
        if(this.BinsWeighted.Summary == 0){
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.voyage'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.Tons'), key: '', _classes: 'text-uppercase', _style: ''},
          ];
        }else{
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.noOrder'), key: '', _style: 'min-width:150px', _classes: 'text-uppercase', },
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.InternalClient'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.orderDate'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.D_shipping'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.InternalBl'), key: '', _classes: 'text-uppercase', _style: 'min-width:117px'},
            { label: this.$t('label.crane'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.hold'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.truckScale'), key: '', _classes: 'text-uppercase', _style: 'min-width:145px'},
            { label: this.$t('label.netWeight')+' (KGM)', key: '', _classes: 'text-uppercase', _style: 'min-width:150px'},
            { label: this.$t('label.netWeight')+' (TON)', key: '', _classes: 'text-uppercase', _style: 'min-width:150px'},
          ];
        }
      };
      case(2):{
        if(this.BinsWeightless.Summary == 0){
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.voyage'), key: '', _classes: 'text-uppercase', _style: ''},
          ];
        }else{
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.noOrder'), key: '', _style: 'min-width:150px', _classes: 'text-uppercase', },
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.InternalClient'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.orderDate'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.D_shipping'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.InternalBl'), key: '', _classes: 'text-uppercase', _style: 'min-width:117px'},
            { label: this.$t('label.crane'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.hold'), key: '', _classes: 'text-uppercase', _style: ''},
          ];
        }
      };
      case(3):{
        if(this.BinsLoaded.Summary == 0){
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.voyage'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.Tons'), key: '', _classes: 'text-uppercase', _style: ''},
          ];
        }else{
          return [
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.noOrder'), key: '', _style: 'min-width:150px', _classes: 'text-uppercase', },
            { label: this.$t('label.Gamela'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: '', _style: '', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: '', _classes: 'text-uppercase', },
            { label: this.$t('label.InternalClient'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.orderDate'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.D_shipping'), key: '', _classes:'text-center', _style: 'min-width:120px'},
            { label: this.$t('label.yard'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.InternalBl'), key: '', _classes: 'text-uppercase', _style: 'min-width:117px'},
            { label: this.$t('label.crane'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.hold'), key: '', _classes: 'text-uppercase', _style: ''},
            { label: this.$t('label.truckScale'), key: '', _classes: 'text-uppercase', _style: 'min-width:145px'},
            { label: this.$t('label.netWeight')+' (KGM)', key: '', _classes: 'text-uppercase', _style: 'min-width:150px'},
            { label: this.$t('label.netWeight')+' (TON)', key: '', _classes: 'text-uppercase', _style: 'min-width:150px'},
          ];
        }
      };
    }
  }
  
  export default {
    name: 'collapse-yar-certification',
    props: { Collapse: Boolean, isEdit: Boolean, item: Object },
    data,
    mixins: [
      GeneralMixin, 
    ],
    components: {
      Money,
      CustomTabs,
      CustomTab,
    },
    validations: {},
    directives: UpperCase,
    methods: {
      Tablefilter,
      getTransportationList,
      getYardList,
      handleTab,
      submit,
      close,
      Reset,
    },
    computed: {
      transportationList,
      YardListFormatted,
      title,
      computedOptionsList,
      fields,
      tableText,
      ...mapState({
        Visit: state => state.visitas.VisitId,
      }),
    },
    watch: {
      Collapse: async function(val){
        if (val) {
          this.tab = 0;
          this.getTransportationList();
          this.getYardList();
        }else{
            this.Reset();
        }
      },
    },
    
  };
  </script>
  <style scoped>
    .input-header{
      background: #1A3760 !important;
    }
  </style>