import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../_store';
import DataStorage from '@/_helpers/localStorage';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

//SIGEOP
// Users
const UserChangePassword = () => import('@/pages/user/change-password');

//Login
const Login = () => import('@/pages/auth/login');
const Recovery = () => import('@/pages/auth/recovery');
const RoleSelection = () => import('@/pages/auth/role-selection');

//Reporte PDF
const PdfParametryPage = () => import('@/pages/yard-management/pdf/reporte');
const PdfPage = () => import('@/pages/planificacion-estiba/archivos/pdf/reporte');
const PdfBuque = () => import('@/pages/buque/pdf/reporte');
const PdfStowagePlan = () => import('@/pages/planificacion-estiba/manifiesto/pdf/reporte');
const PdfDraft = () => import('@/pages/visita/tabs/draft_inspection/inspections-registre/pdf/reporte');
const PdfSumary = () => import('@/pages/visita/tabs/reporte/listas/pdf/reporte');
const PdfManagement = () => import('@/pages/management/pdf/reporte');
const PdfErrorSummary = () => import('@/pages/visita/tabs/error-log/summary/pdf/reporte');
const pdfEnd_Operations = () => import('@/pages/visita/tabs/end_operations/pdf/reporte');
const PdfYard = () => import('@/pages/yard-setting/pdf/report');
const PdfYardBlocks = () => import('@/pages/yards/pdf/reporte');
const PdfEir = () => import('@/pages/yard-management/container/eir/pdf/reporte');
const PdfYardPlan = () => import('@/pages/yards/tabs/pdf/reporte');
const PdfYardCurrentSituation = () => import('@/pages/yard-management/yardCurrentSituation/pdf/report');

//404
const Page404 = () => import('@/views/pages/Page404')

Vue.use(Router)

export const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'change-password',
          meta: {
            label: 'Cambiar Contraseña'
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'ChangePassword',
              component: UserChangePassword,
              meta: { label: 'Datos del Usuario' }
            }
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/recovery',
      name: 'Recovery',
      component: Recovery
    },
    {
      path: '/validate',
      name: 'Validate',
      component: Recovery
    },
    {
      path: '/role',
      name: 'RoleSelection',
      component: RoleSelection
    },
    {
      path: '/reporte',
      name:  'pdfPage',
      props: true,
      component: PdfPage
    },
    {
      path: '/report-planimetry',
      name:  'pdfParametryPage',
      props: true,
      component: PdfParametryPage
    },
    {
      path: '/transversal-view',
      name:  'pdfTransversal',
      props: true,
      component: PdfBuque
    },
    {
      path: '/bay-plan',
      name:  'pdfBayPlan',
      props: true,
      component: PdfBuque
    },
    {
      path: '/stowage-plan',
      name:  'pdfStowagePlan',
      props: true,
      component: PdfStowagePlan
    },
    {
      path: '/draft-inspection',
      name:  'PdfDraft',
      props: true,
      component: PdfDraft
    },
    {
      path: '/sumary',
      name:  'pdfSumary',
      props: true,
      component: PdfSumary
    },
    {
      path: '/Management',
      name:  'PdfManagement',
      props: true,
      component: PdfManagement,
    },
    {
      path: '/PdfErrorSummary',
      name:  'PdfErrorSummary',
      props: true,
      component: PdfErrorSummary
    },
    {
      path: '/End_Operations',
      name:  'pdfEnd_Operations',
      props: true,
      component: pdfEnd_Operations
    },
    {
      path: '/report-yard',
      name:  'pdfYard',
      props: true,
      component: PdfYard
    },
    {
      path: '/report-yard-Blocks',
      name:  'pdfYardBlocks',
      props: true,
      component: PdfYardBlocks
    },
    {
      path: '/Report-Eir',
      name:  'PdfEir',
      props: true,
      component: PdfEir
    },
    {
      path: '/Report-Yard-Plan',
      name:  'PdfYardPlan',
      props: true,
      component: PdfYardPlan
    },
    {
      path: '/Report-Yard-Current-Situation',
      name:  'PdfYardCurrentSituation',
      props: true,
      component: PdfYardCurrentSituation
    },
    {
      path: '*',
      component: Page404
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/recovery', '/validate'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next({ name: 'Login' });
  } else {
    const role = DataStorage.getRole();
    const path = to.redirectedFrom || to.path;
    if(role && store.state.menu.routesInfo == null){
      store.dispatch('menu/getMenuInfo', role.RoleId)
      .then((response) => {
        next(path);
      }).catch((err) => {
        DataStorage.deleteUser();
        next({ name: 'Login' });
      });
    }else
      next();
  }
});

function cacheRoutes(){
  store.menu.routesByRole.forEach((ruta) => {
    router.addRoute('Home', ruta);
  });
}