<template>
  <CRow class="mt-3">
    <loading-overlay
      :active="isLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CCol sm="5" class="form-inline">
      <label class="mr-2">
        {{$t('label.movement')}}:
      </label>
      <v-select 
        :options="movimientosOptions"
        v-model="movimiento"
        :placeholder="$t('label.select')"
        class="w-75"
      />
    </CCol>
    <CCol sm="7" class="d-flex justify-content-end align-items-center">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: `${$t('label.transactionMov')}`, 
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nueva') }}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
     class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="py-2 center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.transactionMov')}`,
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>

    <add-transac-modal :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-transac-modal :modal.sync="modalEdit" :transac-data="transac" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import AddTransacModal from './add-transacciones-modal';
import EditTransacModal from './edit-transacciones-modal';




function data() {
  return {
    items: [],
    transac: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    movimientos: [],
    movimiento: null
  };
}

//Created
function created() {
  this.getTransacciones();
  this.getMovimientos();
}

//Methods:
function getTransacciones(MovStowageId) {
  this.isLoading = true;
  MovStowageId = MovStowageId ? MovStowageId : '';
  this.$http.get("TransacStowage-list", { MovStowageId: MovStowageId, filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function getMovimientos() {
  // this.isLoading = true;

  this.$http.get("MovStowage-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.movimientos = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    // this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item) {
  this.transac = Object.assign({}, item);

  this.modalEdit = true;
}

function handleSubmit(){
  this.getTransacciones();
}

//Computed:
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,  _style: 'width:1%; text-align:center;',
    },
    { key: 'MotionName', label:this.$t('label.movement'), _style:'width:20%;',_classes:"text-uppercase text-center"},
    { key: 'TransacStowageNameEs', label:this.$t('label.transaction')+' '+'(ES)', _style:'width:20%;', _classes:"text-uppercase text-center"},
    { key: 'TransacStowageName', label:this.$t('label.transaction')+' '+'(EN)', _style:'width:20%;', _classes:"text-uppercase text-center"},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"text-uppercase text center"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:"text-center"},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}
function formatedItems() {
  let _this = this.$i18n.locale;
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    MotionName: _this == 'en' ? item.MovStowageName : item.MovStowageNameEs,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function movimientosOptions() {
  let _this = this.$i18n.locale;
  return this.movimientos.map((item) => Object.assign({}, item, {
    value: item.MovStowageId,
    label: _this == 'en' ? item.MovStowageName : item.MovStowageNameEs,
  }));
}

export default {
  name: 'transacciones-index',
  mixins: [GeneralMixin],
  components: { AddTransacModal, EditTransacModal },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  methods: {
    getTransacciones,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    getMovimientos
  },
  computed: {
    fields,
    formatedItems,
    movimientosOptions
  },
  watch: {
    movimiento: function(newMov) {
      if(newMov){
        this.getTransacciones(newMov.MovStowageId);
        this.$i18n.locale;
      }else{
        this.getTransacciones();
      }
    },
    active: function(newVal) {
      if(newVal){
        this.getTransacciones();
        this.getMovimientos();
      }
    }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>