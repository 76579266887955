<template>
  <div class="row mx-0 mt-3">
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        label="BL NRO"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="Commodity.BlNro"
        :disabled="true"
      />

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        label="DUA"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.Commodity.Dua.$model"
        :is-valid="hasError($v.Commodity.Dua)"
        :invalid-feedback="errorMessage($v.Commodity.Dua)"
      />

      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right required"
        :label="$t('label.consignee')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.Commodity.ConsigneeId.$model"
        :is-valid="hasError($v.Commodity.ConsigneeId)"
        :invalid-feedback="errorMessage($v.Commodity.ConsigneeId)"
        :options="ConsigneeOptions"
      >
        <template #append>
          <CButton
            color="add"
            size="sm"
            class="rounded"
            v-c-tooltip="{
              content:$t('label.nuevo')+' '+$t('label.consignee'),
              placement: 'top-end'
            }"
            @click="ConsigneeModal=!ConsigneeModal"
            style="padding: 0.15rem 0.4rem;"
          >
            <CIcon name="cil-playlist-add"/>
          </CButton>
        </template>
      </CSelect>
    </CCol>

    <CCol sm="12" lg="6">
      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right required"
        :label="$t('label.heading')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.Commodity.HeadingId.$model"
        :is-valid="hasError($v.Commodity.HeadingId)"
        :invalid-feedback="errorMessage($v.Commodity.HeadingId)"
        :options="HeadingOptions"
        @change="Commodity.CommodityId = ''"
      />

      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right required"
        :label="$t('label.commodity')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.Commodity.CommodityId.$model"
        :is-valid="hasError($v.Commodity.CommodityId)"
        :invalid-feedback="errorMessage($v.Commodity.CommodityId)"
        :options="CommodityOptions"
      />

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.declaredConsignee')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.Commodity.DeclaredConsignee.$model"
        :is-valid="hasError($v.Commodity.DeclaredConsignee)"
        disabled
      />
    </CCol>
    <CCollapse :show="ConsigneeModal">
      <AddConsignee
        :Active="ConsigneeModal"
        @Update="$emit('Update', true)"
      />
    </CCollapse>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
import AddConsignee from './add-consignee';
  
function data() {
  return {
    ConsigneeModal: false,
  };
}

//computed
function ConsigneeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ConsigneeList.map(function(e){
    chart.push({
      value: e.ClientTpId,
      label: e.ClientName,
    })    
  })
  return chart;
}

function HeadingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.HeadingList.map(function(e){
    chart.push({
      value: e.HeadingId,
      label: e['HeadingName'+_lang],
    })    
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function CommodityList() {
  let Data = this.HeadingList.find(item => item.HeadingId == this.Commodity.HeadingId) ?? {}
  return Data?.CommodityJson ?? [];
}
  
export default {
  name: 'commodity-tab',
  components: {
    AddConsignee,
  },
  data,
  props: {
    Commodity: {
      type: Object,
      required: true,
      default: () => {},
    },
    ConsigneeList: {
      type: Array,
      default: () => [],
    },
    HeadingList: {
      type: Array,
      default: () => [],
    },
    Validate: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
  },
  computed:{
    HeadingOptions,
    CommodityOptions,
    ConsigneeOptions,
    CommodityList,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.Commodity.$touch();
      }else{
        this.ConsigneeModal = false; 
        this.$v.$reset();
      }
    },
  }
};
</script>