import { required, maxLength } from "vuelidate/lib/validators";
import {  especiales,onlyMayusText, } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        StandardDocumentName: { required,especiales , maxLength: maxLength(50) },
        StandardDocumentDescription: { required,especiales, maxLength: maxLength(500) },
        StandardOrganization: { required,especiales, maxLength: maxLength(50) },
    }
}

