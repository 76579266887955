<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
        <CCard>
            <CCardHeader class="text-center bg-dark text-white">
                <b>{{$t('label.containersList')}}</b>
            </CCardHeader>
            <CCardBody>
                <app-modal-grupos></app-modal-grupos>
                <modal-classification></modal-classification>
                <app-modal-codes></app-modal-codes>
                <app-modal-elementos></app-modal-elementos>
                <app-modal-components></app-modal-components>
                <app-modal-vista></app-modal-vista>
                <app-modal-size></app-modal-size>
                <app-modal-accesorios></app-modal-accesorios>
                <app-modal-status></app-modal-status>
                <app-modal-condition></app-modal-condition>
                <CustomTabs :active-tab="tabIndex" @update:activeTab="tabChanged">
                    <CustomTab :title="$t('label.groups')"  icon="group">
                        <app-grupos :loading="apiStateLoading"></app-grupos>
                    </CustomTab>
                    <CustomTab :title="$t('label.sizes')" icon="size" >
                        <app-size :loading="apiStateLoading"></app-size>
                    </CustomTab>
                    <CustomTab :title="$t('label.classifications')"  >
                           <template #title>
                                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Clasificaciones.svg" alt="Card image cap">
                                {{$t('label.classifications')}}                
                            </template>
                        <index-classifications :loading="apiStateLoading">
                        </index-classifications>
                    </CustomTab>
                    <CustomTab :title="$t('label.views')" icon="view">
                        <app-vistas  :loading="apiStateLoading"></app-vistas>
                    </CustomTab>
                    <CustomTab :title="$t('label.parts')" icon="element">
                        <appElements :loading="apiStateLoading"></appElements>
                    </CustomTab>
                    <CustomTab :title="$t('label.accesories')" icon="accesory" >
                        <appAccesorios :loading="apiStateLoading"></appAccesorios>
                    </CustomTab>
                    <CustomTab :title="$t('label.components')" icon="component">
                        <appComponents :loading="apiStateLoading"></appComponents>
                    </CustomTab>
                    <CustomTab :title="$t('label.status')" icon="status">
                        <appStatus :loading="apiStateLoading"></appStatus>
                    </CustomTab>
                    <CustomTab :title="$t('label.conditions')" icon="conditions">
                        <appCondition :loading="apiStateLoading"></appCondition>
                    </CustomTab>
                    <CustomTab :title="$t('label.isoCodes')" icon="isocodes">
                        <appIsoCodes :loading="apiStateLoading"></appIsoCodes>
                    </CustomTab>
                    <CustomTab :title="$t('label.classes')" icon="model">
                        <appClasses :loading="apiStateLoading"></appClasses>
                    </CustomTab>
                </CustomTabs>
            </CCardBody>
        </CCard>
        </CCol>
    </CRow>
</template>
<style scoped>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .pcss3t > ul > li {
        height: auto;
        padding-bottom: 15px;
    }
</style>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';

    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';

    import appElements from './index-elements';
    import appCondition from './index-conditions';
    import appClasses from './index-classes';
    import appStatus from './index-status';
    import appComponents from './index-components';
    import appAccesorios from './index-accesories';
    import AppGrupos from './index-groups.vue';
    import AppSize from './index-sizes.vue';
    import indexClassifications from './index-classifications.vue';
    import AppVistas from './index-views.vue';
    import AppIsoCodes from './index-isocodes';
    import AppModalCodes from './modals/modal-codes';
    import AppModalGrupos from './modals/modal-group';
    import modalClassification from './modals/modal-classification';
    import AppModalVista from './modals/modal-view';
    import AppModalSize from './modals/modal-size';
    import AppModalAccesorios from './modals/modal-accesory';
    import AppModalStatus from './modals/modal-status';
    import AppModalCondition from './modals/modal-condition';
    import AppModalElementos from './modals/modal-element.vue';
    import AppModalComponents from './modals/modal-component.vue';
    //data
    function data() {
        return {
           activeTab: 0,
        }
    }
    //methods
    function tabChecked(val) {
        return this.tabIndex === val;
    }
    function tabChanged(val){
        this.$store.state.contenedores.tabIndex = val;
    }
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING /*|| this.apiState === ENUM.INIT*/){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    //created
    function created() {
        this.$store.state.contenedores.tabIndex = 0;
        this.$store.dispatch('contenedores/getGrupolist');
    }
    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.contenedores.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.contenedores.messageError = '';
            
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' :  this.messageError)
                });
                this.$store.state.contenedores.messageError = '';
                
                break;
            default:
                break;
        }
    }

    //Fields
    export default {
        name: 'app-contenedores',
        data,
        created,
        components: {
            CustomTabs,
            CustomTab,
            AppModalCodes,
            appElements,
            appAccesorios,
            appComponents,
            appStatus,
            appCondition,
            appClasses,
            AppGrupos,
            AppSize,
            indexClassifications,
            AppVistas,
            AppIsoCodes,
            AppModalGrupos,
            modalClassification,
            AppModalVista,
            AppModalSize,
            AppModalAccesorios,
            AppModalStatus,
            AppModalElementos,
            AppModalCondition,
            AppModalComponents
        },
        methods: {
            tabChanged,
        },
        computed:{
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                messageError: state => state.contenedores.messageError,
                tabIndex: state => state.contenedores.tabIndex,
            })
        },
        watch:{
            apiState,
            apiStateForm
        }
    }
</script>