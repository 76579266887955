<template>
  <CCard>
    <CCardHeader>
      <small>{{$t('label.Confirm')+' '+$t('label.positions')}}</small>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <div class="status-wrapper">
            <label>Status Bays</label>
            <div class="position-relative">
              <div class="status-container">
                <div
                  v-for="item in status"
                  :key="item.BayStatusId"
                  class="status-item"
                  :class="isStatusSelected(item)"
                  @click.prevent="selecStatus(item)"
                >
                  <div class="status-box mr-3" :class="statusBoxColor(item)">
                    <div :class="statusIcon(item)"></div>
                  </div>
                  <span>{{ StatusName(item) }}</span>
                </div>
                <!-- CROSS LINE -->
                <div
                  class="status-item"
                  :class="isStatusSelected(crossLineItem)"
                  @click.prevent="selecStatus(crossLineItem)"
                >
                  <div class="status-box mr-3 item-crossline"></div>
                  <span>{{ $t('label.CrosssLine') }}</span>
                </div>
              </div>
              <CElementCover
                :opacity="1"
                v-show="isLoading"
              >
                <CSpinner size="sm" />
              </CElementCover>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="toDeckHasData">
                  <bay-grid
                    :col="colNum(false)"
                    :row="rowNum(false)"
                    :crossLine="isCrossLineTo"
                    :items="positionsToDeck"
                    showStatus
                    :currentStatus="bayStatus"
                    :maxCol="responsiveCol(isCrossLineUnder)"
                  />
                </CCol>
              </CRow>
              <hr class="dash">
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="underDeckHasData">
                  <bay-grid
                  :col="colNum(true)"
                  :row="rowNum(true)"
                  :crossLine="isCrossLineUnder"
                  :items="positionsUnderDeck"
                  showStatus
                  showNumbersBottom
                  :currentStatus="bayStatus"
                  :maxCol="responsiveCol(isCrossLineTo)"
                />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex';
import BayGrid from "./bay-grid";
import { BayMixin } from '@/_mixins/buque';

//Data
function data() {
  return {
    isLoading: false,
    bayStatus: {
      BayStatusId: '',
      BayStatusName: '',
      BayStatusNameEn: '',
      BayStatusNameEs: ''
    },
    status: [],
    statusChanged: [],
    toDeckPos: [],
    underDeckPos: [],
    crossLineItem: {
      BayStatusId: "8D57C4D1-5142-4227-8124-E1BFC5232D3F",
      BayStatusName: 'CROSS LINE',
    }
  }
}

//Created:
function created() {
  this.getBayStatus();
  this.toDeckPos = [...this.positionsToDeck];
  this.underDeckPos = [...this.positionsUnderDeck];
}

//Metodos: 
function getBayStatus() {
  this.isLoading = true;
  this.$http.ejecutar('GET', 'BayStatus-list ')
  .then(response => {
    this.status = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function selecStatus(item) {
  let _lang = this.$i18n.locale;
  let BayName = _lang=='en' ? this.bayStatus.BayStatusNameEn : this.bayStatus.BayStatusNameEs;
  let ItemName = _lang=='en' ? item.BayStatusNameEn : item.BayStatusNameEs;
  if(ItemName == BayName){
    this.bayStatus.BayStatusId = '';
    this.bayStatus.BayStatusNameEs = '';
    this.bayStatus.BayStatusNameEn = '';
  } else {
    this.bayStatus = {...item};
    this.bayStatus.BayStatusName = item.BayStatusNameEn;
  }
}
function isStatusSelected(item) {
  let _lang = this.$i18n.locale;
  let BayName = _lang=='en' ? this.bayStatus.BayStatusNameEn : this.bayStatus.BayStatusNameEs;
  let ItemName = _lang=='en' ? item.BayStatusNameEn : item.BayStatusNameEs;
  return {
    'selected': BayName == ItemName ? true : null
  }
}
function statusIcon(item) {
  let _lang = this.$i18n.locale;
  let Name = _lang=='en' ? item.BayStatusNameEn : item.BayStatusNameEs;
  switch (Name) {
    case  this.$t('label.ACTIVO'):
      return 'active-position';
    case this.$t('label.Disabled'):
      return 'disabled-position';
    case this.$t('label.Not_Exist'):
      return 'not-exist';
    case this.$t('label.Reefer_Plug'):
      return 'reefer-plug';
    case this.$t('label.Hazard'):
      return 'hazard-available';
    case this.$t('label.Hazard_Restriction'):
      return 'hazard-restriction';
    default:
      return 'default-position';
  }
}
function statusBoxColor(item) {
  let _lang = this.$i18n.locale;
  let Name = _lang=='en' ? item.BayStatusNameEn : item.BayStatusNameEs;
  switch (Name) {
    case this.$t('label.Disabled'):
      return 'status-box-disabled';
    default:
      return 'status-box-color';
  }
}

//Computeds: 
function StatusName(item) {
  let _lang = this.$i18n.locale;
  let Name = _lang=='en' ? item.BayStatusNameEn : item.BayStatusNameEs;
  return Name
}
export default {
  name: 'positions',
  mixins: [BayMixin],
  components: {
    BayGrid
  },
  data,
  created,
  methods: {
    StatusName,
    getBayStatus,
    selecStatus,
    isStatusSelected,
    statusIcon,
    statusBoxColor
  },
  computed: {
    ...mapState({
      currentBay: state => state.baygrid.bay,
      positionsToDeck: state => state.baygrid.positionsToDeck,
      positionsUnderDeck: state => state.baygrid.positionsUnderDeck
    })
  },
  watch: {
    positionsToDeck: function(newPos) {
      this.toDeckPos = [...newPos];
    },
    positionsUnderDeck: function(newPos) {
      this.underDeckPos = [...newPos];
    }
  }
}
</script>

<style scoped>
.status-wrapper{
  width: 100%;
  padding: 10px;
  border:2px solid #000;
  margin-top: 10px;
}
.status-wrapper>label{
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  position:absolute;
  top:-4px;
  left:30px;
  background-color:white;
}

.status-wrapper .status-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.status-wrapper .status-container .status-item {
  cursor: pointer;
  width: calc(25% - 10px);
  margin: 5px 5px 0;
  height: 50px;
  border: 1px solid #000;

  display: flex;
  justify-content: center;
  align-items: center;
}
.status-wrapper .status-container .status-item:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
}
.status-wrapper .status-container .status-item.selected {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
  background: #2eb85c;
  color: #fff;
}

.status-box {
  position: relative;
  width: 40px;
  height: 40px;

  border-radius: 2px;
  color: #fff !important;
}
.status-box-activo {
  background: transparent;
  color: #000;
}
.status-box-color {
  background:#eeeded;
}
.status-box-inactive {
  background: #eeeded;
}
.status-box-disabled {
  background: #9e9e9e;
}
.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
}

.hazard-available {
  background-image: url('/img/iconos/AvailableHazard.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-restriction {
  background-image: url('/img/iconos/HazardRestriction.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reefer-plug {
  background-image: url('/img/iconos/ReeferPlug.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.not-exist {
  background-image: url('/img/iconos/NotExist.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.disabled-position {
  background-image: url('/img/iconos/Disabled.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.default-position {
  background-image: url('/img/iconos/Default.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.active-position {
  background-image: url('/img/iconos/Activo.svg');
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.item-crossline {
  cursor: pointer;
  background: #eeeded;
  border: none;
  color: transparent;
}
</style>