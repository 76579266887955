<template>
    <div class="mb-3 px-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ReceptionCanceledContainers')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items="computedListContainer"
                        :fields="fields"
                        :items-per-page="5"
                        column-filter
                        pagination
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        sorter
                    >
                    
                        <template #options="{ item }">
                            <td class="text-center">
                                <CButton
                                    square
                                    size="sm"
                                    color="watch"
                                    @click="toggleAdd(item)"
                                    v-c-tooltip="{
                                        content: $t('label.reception'),
                                        placement: 'top-start'
                                    }"
                                >
                                    <CIcon name='cil-list' />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <EquipmentRegister
                :Active="showIndex==1"
                :CanceledContainer="true"
                :ContainerItem="ContainerItem"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import EquipmentRegister from "./equipment-register";
import { DateFormater } from '@/_helpers/funciones';
  
//data
function data() {
    return {
        Items: [],
        ContainerItem: {},
        dataContainer: [],
        showIndex: 0,
    };
}

//methods
async function YardVisitContainerCanceled(loading) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('YardVisitContainerCanceled-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            let List = response.data.data;
            this.dataContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = loading;
            if (this.FgPositioning) {
                this.$store.state.yardManagement.ContainerTabIndex = 2;
                this.$store.state.yardManagement.dropContainerMovement = 3;
                this.$store.state.yardManagement.yardCollapse = 2;
            }
        });
}

async function Update(loading) {
    await this.YardVisitContainerCanceled(loading);
}

function toggleAdd(item) {
    this.ContainerItem = item;
    this.showIndex = 1;
}

function Reset() {
    this.dataContainer = [];
    this.ContainerItem = {};
    this.showIndex = 0;
}
  
//computed
function computedListContainer() {
    let _lang = this.$i18n.locale;
    return this.dataContainer.map((item, index) => {
        let AtaAtd = item.Ata ?? item.Atd;
        return {
            ...item,
            Nro: index+1,
            ContainerCode: item.ContainerCode,
            CancelReason: _lang=='en' ? item.CancelReasonNameEn||'' : item.CancelReasonNameEs||'',
            VesselVoyage: `${item.VesselName} - ${item.VoyageArrival}`,
            AtaEta: `${AtaAtd ? DateFormater.formatDateTimeWithSlash(AtaAtd) : ''}`,
            CumputedDate: item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '',
        };
    })
}

function fields(){ 
    return [
        { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _style: 'text-align:center', _classes: 'text-center', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'min-width: 170px; text-align:center;', sorter: true, filter: true},
        { key: 'CancelReason', label: this.$t('label.motive'),  _classes: 'text-uppercase text-center', _style: 'min-width: 170px; text-align:center;', sorter: true, filter: true},
        { key: 'VesselVoyage', label: `${this.$t('label.visit')}`,  _classes: 'text-uppercase text-center', _style: 'min-width: 170px; text-align:center;', sorter: true, filter: true},
        { key: 'AtaEta', label: 'ATA / ATD',  _classes: 'text-uppercase text-center', _style: 'min-width: 130px;  text-align:center;', sorter: true, filter: true},
        { key: 'TpCargoDetailCode', label: this.$t('label.type'), _style:'min-width: 130px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _style:'min-width: 130px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'DeviceCode', label: this.$t('label.device'), _style:'min-width: 130px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'TransaLogin', label: this.$t('label.user'), _classes: 'text-center', _style: 'min-width: 170px;'},
        { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
}

export default{
    name: 'canceled-containers-index',
    data,
    mixins: [General],
    components: {
        EquipmentRegister,
    },
    methods: {
        toggleAdd,
        YardVisitContainerCanceled,
        Update,
        Reset,
    },
    computed: {
        fields,
        computedListContainer,
        ...mapState({
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            YardId: state => state.yardManagement.yardData.YardId,
            FgPositioning: state => state.yardManagement.FgPositioning,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
        })
    },
    watch:{
        dropItemMovementList: async function (val) {
            if (val==9) {
                await this.Reset();
                this.YardVisitContainerCanceled(false);
            }
        }
    }
}
</script>