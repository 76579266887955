export const situacionOperativaHistorico = {
    namespaced: true,
  	state: {
        tabIndex:0,
        tabsOperationes:0,
        carrusel: true,
        carruselTab:1,
        Loading: false,
        messageError:'',
        myDataVisit:{},
        FgGeneralCargo: false,
        FgContainerShip: false,
        FgBulk: false,
        transversalView: {
            toDeck: [],
            underDeck: [],
        },
        VisitId: 0,
        bulkCarrier: 0,
        itinerarySelected: {},
        collapseHistorico: false,
        informacionVisit:{
            VesselName:'',
        },
        modalInformation:{
            title:'',
            show:false,
            iconShow: false,
            nameIcon: "",
            itemsInformation:[]
        },
    },
    mutations: {
        asignarModalInformacion (state, { show,title,data,iconShow,nameIcon } ) {
            state.modalInformation = {
                title:title,
                show:show,
                iconShow: iconShow,
                nameIcon: nameIcon,
                itemsInformation:data
            }
		},
    },
}