import { required, maxLength, } from "vuelidate/lib/validators";
import { nameLength } from '@/_validations/validacionEspeciales';
import { NumberFormater } from '@/_helpers/funciones';

export default () => {
    return {
        PackagingAccesoryNameEs: { required,  nameLength, maxLength: maxLength(20) },
        PackagingAccesoryNameEn: { required, nameLength, maxLength: maxLength(20) },
        PackagingId: { required },
        Status: { },
    }
}