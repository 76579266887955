<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <transition name="fade">
      <div class="c-app pdf-page">
        <YardCurrentSituationPdf
          :yardInfo="pageInfo.yardInfo"
          :levels="pageInfo.levels"
          :areas="pageInfo.areas"
          :AreaContainers="pageInfo.AreaContainers"
          :legend="legend"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import YardCurrentSituationPdf from "./Yard-Current-Situation-pdf";
import debounce from 'lodash/debounce';

//Data
function data() {
  return {
    pageInfo: {
      yardInfo: {},
      levels: [],
      areas: [],
      AreaContainers: [],
    },
    legend: [],
    loading: false,
  };
}

//Created
function created() {
  this.loading = true;
  let info = JSON.parse(localStorage.getItem("pageInfo"));
  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = {...info};

  let Page = 0;
  this.pageInfo.levels = this.pageInfo.levels.map((level) => {
    let Containers = this.LevelContainer(level.value);
    Containers = Containers.sort((a, b) => {
      if (a.CargoJson[0].ReceptionDate > b.CargoJson[0].ReceptionDate) {
        return -1;
      }else if (a.CargoJson[0].ReceptionDate < b.CargoJson[0].ReceptionDate) {
        return 1;
      }else{
        return 0;
      }
    })
    if (Containers.length!=0) {
      Containers = this.ContainerList(Containers);
      Page += Containers.length;
    }
    Page += 1;
    return {
      ...level,
      ContainerList: Containers,
      Page: Page,
    }
  })
  this.pageInfo.AreaContainers = this.ContainerList(this.pageInfo.AreaContainers);
  
  this.getDataFromService();
    
  window.addEventListener("beforeprint", function(event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

//methods
function getDataFromService() {
  this.$http.ejecutar('GET', 'YardSlotConfigStatus-list')
  .then(response => {
    this.legend = response.data.data || [];
    this.loading = false;
  }).catch( err => {
    this.loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function LevelContainer(level) {
  let Containers = [];
  this.pageInfo.levels.find(item => item.value == level).BlockJson.forEach(block => {
    block?.PositionJsonAsc?.forEach(Position => {
      if (Position.CargoJson && Position.CargoJson.length!=0 &&
        !Containers.some(Container => Container.CargoJson[0].ContainerId == Position.CargoJson[0].ContainerId)
      ) {
        Containers.push(Position);
      }
    })
    block?.PositionJsonDesc?.forEach(Position => {
      if (Position.CargoJson && Position.CargoJson.length!=0 &&
        !Containers.some(Container => Container.CargoJson[0].ContainerId == Position.CargoJson[0].ContainerId)
      ) {
        Containers.push(Position);
      }
    })
  });
  return Containers;
}

function ContainerList(Containers) {
  let List = [];
  let SubList = [];
  let Nro = 1;
  if (Containers.length!=0) {
    Containers.forEach((item) => {
      if (!Number.isInteger(Nro/17)) {
        SubList.push({...item, Nro: Nro});
        Nro++;
      }else{
        List.push(SubList);
        SubList = [];
        SubList.push({...item, Nro: Nro});
        Nro++;
      }
    })
    List.push(SubList);
  }
  return List;
}

export default {
  name: "Report-Yard-Current-Situation-PDF",
  components: {
    YardCurrentSituationPdf,
  },
  data,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    })
  }, 700),
  created,
  methods: {
    getDataFromService,
    LevelContainer,
    ContainerList,
  },
  computed: {

  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>