<template>
  <form>
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="`${$t('NUEVO DESPACHO DEL CONTENEDOR')}: SUDU2245047`"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CustomTabs :active-tab="this.tabIndex" @update:activeTab="handleTab">
        <CustomTab 
          :title="$t('DESPACHO')"                                                
        >
          <DispatchForm></DispatchForm>
        </CustomTab>
        <CustomTab  
            :title="$t('label.logistics')"
        >
          <LogisticForm></LogisticForm>
        </CustomTab>
      </CustomTabs>

    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import DispatchForm from './dispatch/dispatch-form-index';
import LogisticForm from './logistic/logistic-form-index';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirName: '',
    apiStateLoading: false,
    FeaturesDescription:'',
    rubros: [],
    imdg: [],
    isLoadingMulti: false,
    tabIndex: 0,
  };
}

function handleTab(tab) {
  this.tabIndex = tab;
}

//Computeds:


export default {
  name: 'modal-register',
  components: {
    DispatchForm,
    LogisticForm,
    CustomTabs,
    CustomTab
   },
  props: { modal: Boolean, ContainerItem: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  methods: {
    handleTab
  },
  computed: {
  },
  watch: {
    modal: function(val){
      this.tabIndex = 0;
      this.modalActive = val;
      /*if (this.isEdit==true) {
        this.getdata(this.IncidenceGpoItem);
      }*/
    }
  },
 
};
</script>