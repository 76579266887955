
import {
    required,
    maxLength
} from 'vuelidate/lib/validators';
import { onlyRouteTablets,onlyText,onlyText2,onlyText3} from '@/_validations/validacionEspeciales';
export default () => {
    return {
        GpoCargoId: { required },
        ModuleName: { required, maxLength: maxLength(50),onlyText },
        ModuleIcon: { required,maxLength: maxLength(50),onlyText },
        ModuleNameDsEs: { required, maxLength: maxLength(50),onlyText2 },
        ModuleNameDsEn: { required, maxLength: maxLength(50),onlyText3 },
        ModuleRoute: { required, onlyRouteTablets },
        Navegation: { required,onlyText},
    }
}