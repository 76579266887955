<template>
    <main>
      <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.unNumberList')}} </b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end py-2">
              <CButton
                square
                color="add"
                class="d-flex align-items-center"
                @click="toggleCreate"
                v-c-tooltip="{
                  content: $t('label.UnNumber'),
                  placement: 'top-end'
                }"
              >
                <CIcon name="cil-playlist-add"/>&nbsp;
                {{$t('label.nuevo')}}
              </CButton>
            </CCol>
          </CRow>
          <dataTableExtended
            class="align-center-row-datatable"
            :items="formatedList"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          > 
            <template #Status="{ item }">
              <td class="cell-center text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #options="{ item, index }">
              <td>
                  <CButton
                    size="sm"
                    square
                    color="edit"
                    class="d-flex align-items-center"
                    @click="toggleEdit(item)"
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.UnNumber'),
                      placement: 'top-end'
                    }"
                  >
                    <CIcon name="pencil" />
                  </CButton>
              
              </td>
            </template>
          </dataTableExtended>
        </CCardBody>
      </CCard>
      <modalUnNumber
        :modal.sync="modalUnNumberCreate"
        :isEdit="isEdit"
        :title="titleModal"
        :unNumberItems="unNumberItems"
        @Update="Update"
        @close="closeModal"
      />
    </main>
  </template>
  <script>
  import modalUnNumber from './modal-unNumber';
  import { DateFormater } from '@/_helpers/funciones';
  import GeneralMixin from '@/_mixins/general';
  
  
  function data() {
    return {
      items: [],
      modalUnNumberCreate: false,
      unNumberItems: {},
      loading: false,
      isEdit: false,
      titleModal: ''
    };
  }
  
  //methods
  function mountedUnNumberList() {
    this.loading = true;
    this.$http
      .get('ImdgUnNumber-list')
      .then((response) => (this.items = response.data.data))
      .finally(() => {
        this.loading = false;
      });
  }
  
  function toggleCreate() {
    this.modalUnNumberCreate = true;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.UnNumber');
  }
  
  function toggleEdit(item) {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1)
    this.unNumberItems = item;
    this.isEdit = true;
    this.modalUnNumberCreate = true;
    let title = this.unNumberItems[`UnNumberDs${_lang}`];
    this.titleModal =  this.$t('label.edit')+' '+this.$t('label.UnNumber')+': '+title;
  }
  
  function Update() {
    this.modalUnNumberCreate = false;
    this.isEdit = false;
    this.mountedUnNumberList();
  }
  
  function closeModal(){
    this.unNumberItems = {};
    this.modalUnNumberCreate= false;
    this.isEdit= false;
    this.titleModal = '';
  }
  
  //computed
  function fields(){
    return [
       { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
       { key: 'UnNumber',label: this.$t('label.code'), _style: 'width:20%;',_classes: 'text-uppercase text-center'},
       { key: 'UnNumberDsEs',label: this.$t('label.UnNumber')+' '+'(ES)',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
       { key: 'UnNumberDsEn',label: this.$t('label.UnNumber')+' '+'(EN)',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
       { key: 'ImdgClassAbbrev',label: this.$t('label.imdgClass'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', },
       { key: 'ImdgCod',label: this.$t('label.imdgCode'),  _classes: 'text-uppercase text-center', _style: 'width:27%;', },
       { key: 'TransaLogin',label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:12%' },
       { key: 'FormatedDate',label: this.$t('label.date'),_classes:'text-center', _style:'width:12%' },
       { key: 'Status',label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:12%' },
       { key: 'options',label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
    ];
  }
  
  function formatedList() {
    return this.items.map((item) => {
      return {
        ...item,
        UnNumber: item.UnNumber ?? 'N/A',
        UnNumberDsEs: item.UnNumberDsEs ?? 'N/A',
        UnNumberDsEn: item.UnNumberDsEn ?? 'N/A',
        ImdgClassAbbrev: item.ImdgClassAbbrev ?? 'N/A',
        ImdgCod: item.ImdgCod ?? 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        TransaLogin: item.TransaLogin ?? 'N/A',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
      };
    });
  }
  
  export default {
    name: 'index',
    components: { modalUnNumber },
    data,
    mixins: [GeneralMixin],
    methods: {
      mountedUnNumberList,
      toggleCreate,
      toggleEdit,
      Update,
      closeModal
    },
    mounted: mountedUnNumberList,
    computed: {
      formatedList,
      fields,
    },
  };
  </script>