import {
    required,
    minValue,
  } from 'vuelidate/lib/validators';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
  
  export default (fromDay, toDay, laterDate, previousDate, itinerarySelected) => {
    return {
        fromDay: { required, TimeValidate (value){
            return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
              DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
          }, RangeValidate (value) {
            if (toDay != 'Invalid date') {
              return DateFormater.formatDateTimeWithoutSlash(value) < toDay
            }else{
              return true
            }  
          } 
        },
        toDay: { required, TimeValidate (value) {
            return DateFormater.formatDateTimeWithoutSlash(value) <= laterDate &&
            DateFormater.formatDateTimeWithoutSlash(value) >= previousDate
          }, RangeValidate (value) {
            if (fromDay != 'Invalid date') {
              return DateFormater.formatDateTimeWithoutSlash(value) > fromDay
            }else{
              return true
            }
          } 
        },
        StowageFactor: { required },
        /*Density: { required },*/
        StartFreshWater: {  },
        StartConstant: {  },
        StartDieselOil: {  },
        StartFuelOil: {  },
        StartBallast: {   },
        EndFreshWater: {  },
        EndConstant: {  },
        EndDieselOil: {  },
        EndFuelOil: {  },
        EndBallast: {  },
        StartDraftAft: { PopaValidate (value){
          value= NumberFormater.setNum(value);
          return value <= itinerarySelected.Popa
        }},
        StartDM: {  },
        StartDraftForward: { ProaValidate (value){
          value= NumberFormater.setNum(value);
          return value <= itinerarySelected.Proa
        }},
        EndDraftAft: { PopaValidate (value){
          value= NumberFormater.setNum(value);
          return value <= itinerarySelected.Popa
        }},
        EndDM: {  },
        EndDraftForward: { ProaValidate (value){
          value= NumberFormater.setNum(value);
          return value <= itinerarySelected.Proa
        }},
        Status: {  },
    }
  }