<template>
    <CRow class="mt-2">
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
        <CCol sm="12" class="text-right">
            <CButton square 
                color="add"
                v-c-tooltip= "{placement:'top-center',content:$t('label.heading')}"

                @click="ShowHeadingModal=true">
                <CIcon name="cil-playlist-add"/>&nbsp;
                {{$t('label.nuevo')}}
            </CButton>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="ComputedHeading"
                :fields="Fields"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="tableText.itemsPerPage"
                hover
                small
                sorter
                pagination
            >
                <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                </template>
                <template #Detalle="{item}">
                    <td class="text-center">
                        <CButton color="edit" 
                            square 
                            size="sm" 
                            v-c-tooltip="{placement:'top-end',content:$t('label.edit') + ' ' + $t('label.heading') }" @click="ShowHeadingModal=item" >
                        <CIcon name="pencil" />
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
        <HeadingModal 
            :modal="ShowHeadingModal" 
            @cerrarModal="ShowHeadingModal=false" 
            @child-refresh="refreshHeading=true" 
        />
    </CRow>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import HeadingModal from './heading-modal';

function data() {
return {
  HeadingList: [],
  Loading: false,
  HeadingList: [],
  ShowHeadingModal: false,
  refreshHeading: false,
};
}
function handleTab(tab) {
    this.Tab = tab;
}

function getHeadingList() {
  this.Loading = true;
  this.$http
  .get('Heading-list',{ filter: 'ALL' })
    .then((response) => (
        this.HeadingList = response.data.data
    ))
    .finally(() => {
        this.Loading = false;
    });  
}

function ComputedHeading() {
    return this.HeadingList.map((item) => {
        return {
            ...item,
            Nro: item.Nro,
            HeadingId: item.HeadingId, 
            HeadingNameEs: item.HeadingNameEs ?? 'N/A', 
            HeadingNameEn: item.HeadingNameEn ?? 'N/A',
            Usuario: item.TransaLogin ? item.TransaLogin:'N/A',
            Fecha: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            Status: item.Status,
            FgActHeading: item.FgActHeading,
            _classes: item.Status === "INACTIVO" ? 'table-danger': ''
        };
    });
}
function Fields(){
return [
    { key: 'Nro', label: '#',_style: 'width:3%; text-align:center', filter: false },
    { key: 'HeadingNameEs', label:`${this.$t('label.heading')} (ES)`, _classes: 'text-uppercase text-center', _style:'width:25%' },
    { key: 'HeadingNameEn', label:`${this.$t('label.heading')} (EN)`, _classes: 'text-uppercase text-center', _style:'width:25%' },
    { key: "Usuario", label: this.$t('label.user'),_classes: 'text-uppercase text-center', _style:'width:10%' },
    { key: "Fecha", label: this.$t('label.date'), _classes:'text-center', _style:'width:10%' },
    { key: "Status", label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:10%' },
    { key: "Detalle", label: "",_style: 'width:5%; min-width:45px;', sorter: false, filter: false },
];
}


export default {
name: 'index',
components: { 
  HeadingModal,
  CustomTabs, 
  CustomTab,
},
data,
props:{Tab: Number},
mixins: [GeneralMixin],
methods: {
  getHeadingList,
  handleTab,
},
computed: {
  ComputedHeading,
  Fields,
},
watch:{
    refreshHeading: function () {
        if (this.refreshHeading) {
            this.getHeadingList();
            this.refreshHeading = false;
        }
    },
    Tab:function (val){
        if(val == 0){
        this.getHeadingList();
        }
    }
},
mounted: getHeadingList,
};
</script>
