<template>
<CModalExtended
  :title="$t('label.nuevo')+' '+$t('label.workShift')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow>
              <CCol sm="11" lg="7"> 
                <CInput
                  v-model.trim="$v.turno.TurnNameEs.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  :invalid-feedback="errorMessage($v.turno.TurnNameEs)"
                  :is-valid="hasError($v.turno.TurnNameEs)"
                  :label="$t('label.name')+' (ES)'"
                  addLabelClasses="required text-right"
                  :placeholder="$t('label.shiftName')+' (ES)'"
                  maxlength="50"
                >
                </CInput>
              </CCol>
              <CCol sm="11" lg="5">
                <vue-datepicker
                  valueType="format"
                  type="time"
                  :format="'HH:mm'"
                  v-model="turno.StartHour"
                  :clearable="false"
                  v-model.trim="$v.turno.StartHour.$model"
                  :append-to-body="false"
                  class="w-100"
                  id=" widthHour"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      class="w-100 float-left"
                      v-uppercase
                      v-model="turno.StartHour"
                      placeholder="HH:MM"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.turno.StartHour)"
                      v-model.trim="$v.turno.StartHour.$model"
                      @keypress="formatHourMMHH($event)"
                      :label="$t('label.from')"
                      :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
                    >
                      <template #append-content>
                        <CIcon name="cil-clock" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="11" lg="7"> 
                <CInput
                  v-model.trim="$v.turno.TurnNameEn.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  :invalid-feedback="errorMessage($v.turno.TurnNameEn)"
                  :is-valid="hasError($v.turno.TurnNameEn)"
                  :label="$t('label.name')+' (EN)'"
                  addLabelClasses="required text-right"
                  :placeholder="$t('label.shiftName')+' (EN)'"
                  maxlength="50"
                >
                </CInput>
              </CCol>
              <CCol sm="11" lg="5">
                <vue-datepicker
                  valueType="format"
                  type="time"
                  :format="'HH:mm'"
                  v-model="turno.EndHour"
                  :clearable="false"
                  v-model.trim="$v.turno.EndHour.$model"
                  :append-to-body="false"
                  class="w-100"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      class="w-100 float-left"
                      v-uppercase
                      v-model="turno.EndHour"
                      placeholder="HH:MM"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.turno.EndHour)"
                      v-model.trim="$v.turno.EndHour.$model"
                      @keypress="formatHourMMHH($event)"
                      :label="$t('label.to')"
                      :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
                    >
                      <template #append-content>
                        <CIcon name="cil-clock" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <CButton
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="submit"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TurnoFormValidations from '@/_validations/estiba/TurnoFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { TurnoMixin } from '@/_mixins/estiba';
import  ModalMixin  from '@/_mixins/modal';
import Servicio from '@/_mixins/servicio';
//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    turno: {
      TurnNameEs: '',
      TurnNameEn: '',
      StartHour: '',
      EndHour: ''
    },
    horaStartVal: false, 
    horaEndVal: false, 
  }
}

//Methods
function submit() {
  try {
    this.isSubmit = true;
    this.$v.turno.$touch();
    this.horaStartVal = true;
    this.horaEndVal = true;
    if (this.$v.turno.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TurnJson = {
      TurnName: this.turno.TurnNameEs,
      TurnNameEn: this.turno.TurnNameEn,
      StartHour: this.turno.StartHour,
      EndHour: this.turno.EndHour
    };

    this.$http.post("Turn-insert", TurnJson, { root: 'TurnJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
    } catch (e) {
    this.notifyError({ text: e });
  }
}

function resetForm() {
  this.turno.TurnNameEn = '';
  this.turno.TurnNameEs = '';
  this.turno.StartHour = '';
  this.turno.EndHour = '';
  this.horaStartVal = false;
  this.horaEndVal = false;
  this.$v.$reset();
}

function isHoraStartValido(horaStartVal) {
  if(this.horaStartVal === true){ 
    return (this.turno.StartHour == null || this.turno.StartHour == "") ? 'no-valido' : 'valido';
  }
}
function isHoraEndValido(horaEndVal) {
  if(this.horaEndVal === true){ 
    return (this.turno.EndHour == null || this.turno.EndHour == "") ? 'no-valido' : 'valido';
  }
}
//Computeds:
export default {
  name: 'modal-create-work-shift',
  mixins: [
    TurnoMixin,
    ModalMixin,
    Servicio
  ],
  props: {
    modal: Boolean
  },
  data,
  validations: TurnoFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    isHoraStartValido,
    isHoraEndValido
  }
}
</script>
<style lang="scss">
.invalid {
  display: block;
}
.time-invalid {
  border-color: #e55353 !important;
}
.time-valid {
  border-color: #2eb85c !important;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
#widthHour{
  .mx-datepicker {
    width: 120px;
  }
}
</style>