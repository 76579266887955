<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="pa-3"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CRow>
        <CCol sm="12" lg="7" class="mr-0">
          <CInput
            :label="$t('label.name')"
            addLabelClasses="text-right required"
            :placeholder="$t('label.stateName')"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            @keypress="f_letras($event)"
            v-uppercase
            v-model.trim="$v.TpCargoName.$model"
            :is-valid="hasError($v.TpCargoName)"
            :invalid-feedback="errorMessage($v.TpCargoName)"
          />
          <CSelect
            :label="$t('label.MeasurementUnit')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.select')"
            v-uppercase
            @change="selectedUnitMeasure($event)"
            :value="TpUnitMeasureId"
            :options="computedUnitMeasures"        
            v-model.trim="$v.TpUnitMeasureId.$model"
            :is-valid="hasError($v.TpUnitMeasureId)"
            :invalid-feedback="errorMessage($v.TpUnitMeasureId)"
          />
          <CTextarea
            :label="$t('label.description')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.stateDescription')"
            v-uppercase
            :is-valid="hasError($v.TpCargoDs)"
            :invalid-feedback="errorMessage($v.TpCargoDs)"
            v-model.trim="$v.TpCargoDs.$model"
          />
        </CCol>
        <CCol sm="12" lg="5">
          <CInput
            :label="$t('label.acronym')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.StateAcronyms')"
            v-uppercase
            @keypress="f_letras($event)"
            v-model.trim="$v.TpCargoCode.$model"
            :is-valid="hasError($v.TpCargoCode)"
            :invalid-feedback="errorMessage($v.TpCargoCode)"
          />
          <CSelect
            :label="$t('label.packaging')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :placeholder="$t('label.select')"
            v-uppercase
            :options="optionsPackaging"
            :value="FgPackaging"
            :is-valid="hasError($v.FgPackaging)"
            :invalid-feedback="errorMessage($v.FgPackaging)"
            @change="selectedPackagin($event)"
            v-model.trim="$v.FgPackaging.$model"
          />
          <CSelect v-if="editModal"
            :label="$t('label.status')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-uppercase
            :options="statusOptions"
            :value.sync="StatusMode"
            v-model.trim="$v.StatusMode.$model"
            :is-valid="statusSelectColor"
            :invalid-feedback="errorMessage($v.StatusMode)"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!editModal"
          outline
          color="add"
          @click="saveNewEstado"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="editModal"
          outline
          color="add"
          @click="submitUpdatedEstadoGrupoCarga"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton color="wipe" @click="closeModal(false)" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import { required, maxLength, helpers, alpha } from 'vuelidate/lib/validators';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import ImdgMixin from '@/_mixins/imdg';
import ModalMixin from '@/_mixins/modal';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    unitsMeasure: [],
    TpCargoName: '',
    TpCargoCode: '',
    TpCargoDs: '',
    TpUnitMeasureId: '',
    
    FgPackaging: 1,
    StatusMode: 0,
    Status: '',
    TpCargoId: '',
    isSubmit: false,
  };
}

async function mountedTpUnitMeasure() {
  this.loadingOverlay = true;
  await this.$http
    .get('TpUnitMeasure-list?Filter=ACTIVO')
    .then((response) => {
      this.loadingOverlay = false;
      this.unitsMeasure = response.data.data;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

function computedUnitMeasures() {
  if (this.unitsMeasure.length > 0) {
    return this.unitsMeasure.map((item) => {
      return {
        label: item.TpUnitMeasureName,
        value: item.TpUnitMeasureId,
      };
    });
  }
}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.showModal = false;
  this.TpCargoName = '';
  this.TpCargoCode = '';
  this.TpCargoDs = '';
  this.TpUnitMeasureId = '';
  this.FgPackaging = 1;
  this.TpCargoId = '';
  this.StatusMode = 0;
  this.isSubmit = false;
  this.$emit('updated-modal', event);
}

function selectedUnitMeasure(event) {
  this.TpUnitMeasureId = event.target.value;
}

function selectedPackagin(event) {
  this.FgPackaging = event.target.value;
}

function getUnitsMeasuresId(TpCargoUnitMeasureJson) {
  let measureId = '';
  if (TpCargoUnitMeasureJson !== null && TpCargoUnitMeasureJson.length > 0) {
    TpCargoUnitMeasureJson.map((item, index) => {
      if (item.Status === 'ACTIVO') {
        measureId = item.TpUnitMeasureId;
      }
    });
  }
  if (measureId === '' && TpCargoUnitMeasureJson !== null) {
    measureId = TpCargoUnitMeasureJson[0].TpUnitMeasureId;
  }

  return measureId;
}

async function saveNewEstado() {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpCargoJson = {
      TpCargoName: this.TpCargoName,
      TpCargoDs: this.TpCargoDs,
      TpCargoCode: this.TpCargoCode,
      FgPackaging: this.FgPackaging,
      TpUnitMeasureJson: [
        {
          TpUnitMeasureId: this.TpUnitMeasureId,
        },
      ],
    };

    await this.$http
      .post('BulkCargo-insert', TpCargoJson, { root: 'TpCargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.closeModal(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.notifyError({ text: e });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
}

async function updatedEstadoCargaGranel() {
  this.loadingOverlay = true;
  this.isSubmit = true;

  const TpCargoJson = {
    TpCargoId: this.TpCargoId,
    TpCargoName: this.TpCargoName,
    TpCargoDs: this.TpCargoDs,
    TpCargoCode: this.TpCargoCode,
    FgPackaging: this.FgPackaging,
    Status: this.StatusMode,
    TpUnitMeasureJson: [
      {
        TpUnitMeasureId: this.TpUnitMeasureId,
      },
    ],
  };

  await this.$http
    .put('BulkCargo-update', TpCargoJson, { root: 'TpCargoJson' })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('refresh-data-table');
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.closeModal(false);
        this.notifySuccess({ text: messageSuccess });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.notifyError({ text: e });
    });
}

async function submitUpdatedEstadoGrupoCarga() {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    this.checkSTatusEstado();
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
}

async function checkSTatusEstado() {
  this.loadingOverlay = false;
  this.isSubmit = false;
  try {
    if (this.StatusMode == 0) {
      this.$swal
        .fire(
            this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+' '} ${this.TpCargoName}?`,
        })
          )
        .then((result) => {
          if (result.isConfirmed) {
            this.updatedEstadoCargaGranel();
          }
        });
    } else {
      this.updatedEstadoCargaGranel();
    }
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function optionsPackaging(){
  return [
      { label: this.$t('label.Applies'), value: 1 },
      { label: 'N/A', value: 0 },
  ];
}

function statusSelectColor() {
    return this.StatusMode == 1;
}

export default {
  name: 'modalEstado',
  data,
  props: {
    modal: null,
    title: String,
    editModal: Boolean,
    estadoCgaGranelSelected: Object,
  },
  mixins: [
    ImdgMixin, 
    GeneralMixin,
    ModalMixin,
  ],
  directives: UpperCase,
  validations: {
    TpCargoName: { required, maxLength: maxLength(250) },
    TpCargoCode: { required, maxLength: maxLength(5) },
    TpCargoDs: { required, maxLength: maxLength(250) },
    TpUnitMeasureId: { required },
    FgPackaging: { required },
    StatusMode: { required },
  },
  methods: {
    statusSelectColor,
    closeModal,
    mountedTpUnitMeasure,
    selectedUnitMeasure,
    selectedPackagin,
    saveNewEstado,
    getUnitsMeasuresId,
    checkSTatusEstado,
    updatedEstadoCargaGranel,
    submitUpdatedEstadoGrupoCarga,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
      if (val === true) {
        this.mountedTpUnitMeasure();
      }
    },
    estadoCgaGranelSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.TpCargoId = val.TpCargoId;
        this.TpCargoName = val.TpCargoName;
        this.TpCargoCode = val.TpCargoCode;
        this.TpCargoDs = val.TpCargoDs;
        this.FgPackaging = val.FgPackaging ? 1 : 0;
        this.Status = val.Status;
        this.StatusMode = val.Status === 'ACTIVO' ? 1 : 0;
        this.TpUnitMeasureId = this.getUnitsMeasuresId(
          val.TpCargoUnitMeasureJson
        );
        this.$v.$touch();


      }

    },
  },
  computed: {
    computedUnitMeasures,
    optionsPackaging,
  },
};
</script>
<style lang="scss">
.text-area-descripcion-state {
  textarea {
    resize: none !important;
  }
}
</style>
