import axios from 'axios';
import { AuthHeader } from './service';
import DataStorage from '@/_helpers/localStorage';


const config = {
  baseURL: `${process.env.VUE_APP_API_BASE}`,
  timeout: 60000,
};

const headersRequest = () =>{
  let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
  return {
    headers:{
      "Language":`${lang}`,
      "Access-Control-Allow-Origin": "*"
    }
  };
}
function getCurrentBaseURL() {
  return {
    baseURL: `${DataStorage.getConnection()}apptest/`
  };
}

const api = axios.create(config);

const Errores = (err) => {
  let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
  let connectionErrorMsg = lang == 'ES' ? "ERROR DE CONEXION" : "CONNECTION ERROR";
  if (err.response) {
    throw new Error(err.response.data.data[0].Response);
  } else if(err.code){
    throw new Error(`${connectionErrorMsg}`);
  } else if (err.request) {
    throw new Error(`${connectionErrorMsg}`);
  } else {
    throw new Error(err);
  }
};

class AuthService {
  async companyList() {
    try {
      return await api.get('company', { ...getCurrentBaseURL() });
    } catch(err) {
      Errores(err);
    }
  }

  async login(user) {
    try {
      return await api.post('login', {
        Login: user.Login,
        Password: user.Password,
        CompanyId: user.CompanyId
      }, { 
        ...headersRequest(),
        ...getCurrentBaseURL()
      }).then((response) => {
        if(Array.isArray(response.data.data)){
          if (response.data.data[0].token && response.data.data[0].FgFirstTime == 0) {
            DataStorage.setUser(response.data.data[0]);

            return {
              ...response.data.data[0].Json.UserJson[0],
              FgFirstTime: response.data.data[0].FgFirstTime,
              token: response.data.data[0].token,
              refreshToken: response.data.data[0].refreshToken,
              msg: response.data.data[0].Response
            };
          }else {
            return {
              FgFirstTime: response.data.data[0].FgFirstTime,
              msg: response.data.data[0].Response
            }
          }
        }else{
          return {
            token: response.data.data.token,
            FgFirstTime: response.data.data.FgFirstTime?1:0,
            msg: response.data.data.Response
          }
        }
      });
    } catch (err) {
      Errores(err);
    }
  }
  logout() {
    DataStorage.deleteUser();
  }

  async verifyUser(user) {
    try {
      return await api.get(`verifyUser?CiLogin=${user.CiLogin}&Email=${user.Email}`, { 
        ...headersRequest(),
        ...getCurrentBaseURL()
      })
      .then((response) => {
        return {
          msg: response.data.data[0].Response,
          code: response.data.data[0].Code,
          token: response.data.data[0].token
        }
      });
    } catch (err) {
      Errores(err);
    }
  }
  async verifyCode(user) {
    try {
      return await api.get(`verifyCode?token=${user.token}&Code=${user.code}`, { 
        ...headersRequest(),
        ...getCurrentBaseURL()
      })
      .then((response) => {
        return {
          msg: response.data.data[0].Response
        }
      });
    } catch (err) {
      Errores(err);
    }
  }
  async changePassword(user) {
    try {
      return await api.put('changePassword', {
        token: user.token, //Token del verify user.
        Password: user.Password,
      }, { 
        ...getCurrentBaseURL()
      }).then((response) => {
        return {
          msg: response.data.data[0].Response
        }
      });
    } catch (err) {
      Errores(err);
    }
  }
  async changePasswordUser(user) {
    try {
      return await api.put('user/changePassword', {
        Password: user.password,
      },{
        headers: { ...headersRequest().headers, ...AuthHeader()},
        ...getCurrentBaseURL()
      }).then((response) => {
        return {
          msg: response.data.data[0].Response
        }
      });
    } catch (err) {
      Errores(err);
    }
  }
  async checkRepository(url) {
    try {
      return await api.get(`apptest/init`, {
        baseURL: url,
      })
      .then((response) => {
        return response;
      });
    } catch (err) {
      Errores(err);
    }
  }
}

export default new AuthService();