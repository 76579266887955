<template>
  <div>
    <CNavbar
      expandable="lg"
      light class="menu-interno"
      :class="removeMargin"
      color="secondary"
    >
      <CToggler in-navbar @click="collapsed = !collapsed" />
      <CCollapse :show="collapsed" navbar>
        <CNavbarNav>
          <CButton color="dark" size="sm" class="ml-2 my-2 my-sm-0 d-flex align-items-center" variant="outline"
            :class="{ 'btn-information active': activeItem(30) ? true : null }"
            @click="clickItem(30)"
          >
           <CIcon name="cil-info-circle"/>
           <span class="mx-2">
             {{ $t('label.information') }}
           </span>
          </CButton>
        </CNavbarNav>
        <CNavbarNav>
          <CDropdown
            :togglerText="$t('label.navReports')"
            class="mx-2"
          >
            <!--CDropdownItem>{{$t('label.lists')}}</CDropdownItem-->    
              
              <CDropdownItem :active="activeItem(1)" @click="clickItem(1)">{{$t('label.listOfGeneralContainers')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(6)" @click="clickItem(6)">{{$t('label.listOfImdgContainers')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(8)" @click="clickItem(8)">{{$t('label.listOfReeferContainers')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(9)" @click="clickItem(9)">{{$t('label.listOfOversizedContainers')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(12)" @click="clickItem(12)">{{$t('label.listOfMovementsRestow')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(13)" @click="clickItem(13)">{{$t('label.listOfShipments')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(22)" @click="clickItem(22)">{{$t('label.listOfCanceled')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(23)" @click="clickItem(23)">{{$t('label.listOfDischarges')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(11)" @click="clickItem(11)">{{$t('label.vgmDetail')}}</CDropdownItem>
            <!--CDropdownItem>{{$t('label.sumaries')}}</CDropdownItem-->    
              <CDropdownDivider></CDropdownDivider>
              <CDropdownItem :active="activeItem(2)" @click="clickItem(2)">{{$t('label.summaryOfContainersByTypeStatus')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(3)" @click="clickItem(3)">{{$t('label.summaryOfContainersByType')}}</CDropdownItem>
              <CDropdownItem :active="activeItem(4)" @click="clickItem(4)">{{$t('label.summaryOfContainersByPortLine')}}</CDropdownItem>
              <!--<CDropdownItem :active="activeItem(19)" @click="clickItem(19)">PORT SUMMARY</CDropdownItem>
              <CDropdownDivider></CDropdownDivider>-->    
              <CDropdownItem :active="activeItem(7)" @click="clickItem(7)">{{$t('label.summaryOfImdgContainers')}}</CDropdownItem>
              <CDropdownDivider></CDropdownDivider>
              <CDropdownItem :active="activeItem(25)" @click="clickItem(25)">{{$t('label.CustomListsFormat')}}</CDropdownItem>
            <!--<CDropdownItem :active="activeItem(10)" @click="clickItem(10)">BREAK BULK</CDropdownItem>-->
         




          </CDropdown>
          <!--<CDropdown
            :togglerText="$t('label.navConfig')"
            class="mr-2"
            addMenuClasses="nav-internal-dropdown"
            addTogglerClasses="nav-internal-item"
          >
            <CDropdownItem >ITEM 1</CDropdownItem>
          </CDropdown>-->
          <CDropdown
            :togglerText="$t('label.navViews')"
            class="mr-2"
            addMenuClasses="nav-internal-dropdown"
            addTogglerClasses="nav-internal-item"
          >
            <CDropdownItem :active="activeItem(14)" @click="clickItem(14)">BAY VIEW</CDropdownItem>
            <CDropdownItem :active="activeItem(15)" @click="clickItem(15)">FULL CARGO PLAN</CDropdownItem>
            <!-- <CDropdownItem :active="activeItem(16)" @click="clickItem(16)">BAYS PRINTOUT</CDropdownItem> -->
            <CDropdownItem :active="activeItem(21)" @click="clickItem(21)">{{$t('label.projection')+ ' ' + $t('label.view')}}</CDropdownItem>
            <CDropdownItem
              :active="activeItem(20)"
              v-if="isLoad"
              @click="clickItem(20)"
            >
              LOAD VIEW
            </CDropdownItem>
          </CDropdown>
          <!--<CDropdown
            :togglerText="$t('label.navPersonalReport')"
            class="mr-2"
            addMenuClasses="nav-internal-dropdown"
            addTogglerClasses="nav-internal-item"
          >
            <CDropdownItem :active="activeItem(17)" @click="clickItem(17)">ITEM 1</CDropdownItem>
            <CDropdownItem :active="activeItem(18)" @click="clickItem(18)">ITEM 2</CDropdownItem>
          </CDropdown>-->
          <CDropdown
            togglerText="BAPLIE"
            class="mr-2"
            addMenuClasses="nav-internal-dropdown"
            addTogglerClasses="nav-internal-item"
             v-if="generar"
          >
            <CDropdownItem :active="activeItem(24)" @click="clickItem(24)">{{ $t('label.generate') }} BAPLIE</CDropdownItem>
          </CDropdown>
        </CNavbarNav>
        <CNavbarNav class="ml-auto">
          <CButton
            color="watch"
            size="sm"
            class="my-2 my-sm-0 ml-2 d-flex align-items-center"
            shape="pill"
            @click.stop="closeDetails"
          >
            <CIcon name="returnArrowBold"/>
            <span class="mx-2">{{$t('label.back')}}</span>
          </CButton>
          <CButton
            color="wait"
            size="sm"
            class="my-2 my-sm-0 ml-2"
            @click="modalAlerta=1"
          >
            <CIcon name="alert" />
            <span class="mx-2">{{$t('label.alerts')}}</span>
          </CButton>
          <CButton
            color="wipe"
            size="sm"
            class="my-2 my-sm-0 mx-2"
            @click="modalAlerta=2"
          >
            <CIcon name="error"/>
            <span class="mx-2">{{$t('label.errors')}}</span>
          </CButton>
          <!--
          <CButton
            color="success"
            size="sm"
            class="my-2 my-sm-0 ml-2 d-flex align-items-center"
            shape="pill"
            @click.stop="closeDetails"
          >
            <span class="mx-2">{{$t('button.exit')}}</span>
            <CIcon name="cil-arrow-thick-from-left" class="mr-2" />
          </CButton>
          -->
          <img 
            style="cursor: pointer;"
            class="mr-2"
            size="sm" width="30px" height="30px" 
            src="/img/iconos/GIF_AYUDA.gif"
            @click="ModalHelp=true"
          >
        </CNavbarNav>
      </CCollapse>
    </CNavbar>
    <!--
    <CRow>
      <CCol sm="12" class="pr-4">
        
      </CCol>
    </CRow>
    -->
    <br v-if="!activeItem(14) && !activeItem(15) && !activeItem(20)">
     <CCollapse :show="activeItem(1)">
      <containerlist ></containerlist>
     </CCollapse>
     <CCollapse :show="activeItem(2)">
      <containerTypeStatusSummary :value="newUnit" @child-refresh="newUnit=false" ></containerTypeStatusSummary>
     </CCollapse>
     <CCollapse :show="activeItem(3)">
      <containerByTypeSummary :value="newUnit" @child-refresh="newUnit=false" ></containerByTypeSummary>
     </CCollapse>
     <CCollapse :show="activeItem(4)">
      <containerByPortLineSummary :value="newUnit" @child-refresh="newUnit=false" ></containerByPortLineSummary>
     </CCollapse>
     <CCollapse :show="activeItem(19)">
      <portSummary :value="newUnit" @child-refresh="newUnit=false" ></portSummary>
     </CCollapse>
     <CCollapse :show="activeItem(6)">
      <imdgList :value="newUnit" @child-refresh="newUnit=false" ></imdgList>
     </CCollapse>
     <CCollapse :show="activeItem(7)">
      <imdgContainerSummary :value="newUnit" @child-refresh="newUnit=false" ></imdgContainerSummary>
     </CCollapse>
     <CCollapse :show="activeItem(8)">
      <reefer-list :value="newUnit" @child-refresh="newUnit=false" ></reefer-list>
     </CCollapse>
     <CCollapse :show="activeItem(9)">
      <oversizedList :value="newUnit" @child-refresh="newUnit=false" ></oversizedList>
     </CCollapse>
     <CCollapse :show="activeItem(11)">
      <vgm-detail :value="newUnit" @child-refresh="newUnit=false" ></vgm-detail>
     </CCollapse>
     <CCollapse :show="activeItem(12)">
      <restowlist />
     </CCollapse>
     <CCollapse :show="activeItem(13)">
      <loadlist />
     </CCollapse>
     <CCollapse :show="activeItem(14)">
      <bay-view />
    </CCollapse>
    <CCollapse :show="activeItem(15)">
      <full-cargo-view />
    </CCollapse>
    <CCollapse :show="activeItem(20)">
      <load-View />
    </CCollapse>
    <CCollapse :show="activeItem(21)">
      <projection-view :value="newUnit" @child-refresh="newUnit=false" />
    </CCollapse>
    <CCollapse :show="activeItem(22)">
      <canceled-list :value="newUnit" @child-refresh="newUnit=false" />
    </CCollapse>
    <CCollapse :show="activeItem(23)">
      <discharge-list :value="newUnit" @child-refresh="newUnit=false" />
    </CCollapse>
    <CCollapse :show="activeItem(24)">
      <listado-baplie :value="newUnit" @child-refresh="newUnit=false" />
    </CCollapse>
    <CCollapse :show="activeItem(25)">
      <custom-lists-format :value="newUnit" @child-refresh="newUnit=false" />
    </CCollapse>
    <CCollapse :show="activeItem(30)">
      <edi-info-view />
    </CCollapse>
    <CCollapse :show="activeItem(31)">
      <errores-view :value="newUnit" @child-refresh="newUnit=false" @ret="setRetorno" />
    </CCollapse>
    <alerta-modal :modal="modalAlerta" @cerrarModal="modalAlerta=false" @errorSelect="errorSelect" />
    <ModalHelpBaplie
      :modal.sync="ModalHelp"
      @Close="ModalHelp=false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ModalHelpBaplie from './modal-help-baplie';
import containerlist from './container-list';
import containerTypeStatusSummary from './container-type-status-summary';
import containerByTypeSummary from './container-by-type-summary';
import containerByPortLineSummary from './container-by-port-line-summary';
import portSummary from './port-summary';
import imdgContainerSummary from './imdg-container-summary';
import imdgList from './imdg-list';
import oversizedList from './oversized-list';
import FullCargoView from './full-cargo-view';
import EdiInfoView from './info-view';
import BayView from './bay-view';
import ReeferList from './reefer-list.vue';
import LoadView from '../load/load-view';
import VgmDetail from './vgm-detail.vue';
import restowlist from './restow-list';
import loadlist from './load-list';
import AlertaModal from './alerta-modal';
import ErroresView from './errores-view';
import ProjectionView from './projection-view';
import CanceledList from './canceled-list';
import DischargeList from './discharge-list';
import ListadoBaplie from './listado-baplie';
import CustomListsFormat from './custom-list-format';
//Data
function data() {
  return {
    ModalHelp: false,
    modalAlerta: false,
    newUnit: false,
    collapsed: false,
    generar: false,
  };
}

//Metodos:
function closeDetails() {
  if (!this.apiStateLoading) {
    this.$store.commit("planificacionestiba/menuVisibility", false);
  }
}
function activeItem(item) {
  return this.dropItem === item;
}
function clickItem(item){
  this.newUnit = item;
  this.$store.state.planificacionestiba.dropItem  = item;
}
function errorSelect(item){
  this.newUnit = item;
  this.$store.state.planificacionestiba.dropItem  = item.valor;
}
function removeMargin() {
  return {
    'no-margin': this.activeItem(15) || this.activeItem(14) ? true : null
  }
}

function isLoad() {
  return this.ediFileInfo && (this.ediFileInfo.TpEdiTransacId == '0BDB045B-F7CF-4782-8ACF-AC04A1B342CF' || this.ediFileInfo.TpEdiId == '74423312-1450-4B16-B837-D5620CDCDD1D');
}

function setRetorno(value) {
  this.clickItem(30);
  this.modalAlerta=value;
}

export default {
  name: "details-navbar",
  data,
  methods: {
    closeDetails,
    activeItem,
    clickItem,
    errorSelect,
    setRetorno
  },
  computed:{
    removeMargin,
    isLoad,
    ...mapState({
      isHidden: state => state.planificacionestiba.isHidden,
      dropItem: state => state.planificacionestiba.dropItem,
      ediFileInfo: state => state.planificacionestiba.EdiFileInfo
    })
  },
  watch: {
    ediFileInfo: function () {
      this.generar = false;
      if (this.ediFileInfo) {
        if ((this.ediFileInfo.TpEdiTransacId=="0BDB045B-F7CF-4782-8ACF-AC04A1B342CF") || (this.ediFileInfo.TpEdiId=="74423312-1450-4B16-B837-D5620CDCDD1D")) {
          this.generar = true;
        }
      }
    }
  },
  components:{
    ModalHelpBaplie,
    containerlist,
    containerTypeStatusSummary,
    containerByTypeSummary,
    portSummary,
    containerByPortLineSummary,
    imdgList,
    imdgContainerSummary,
    oversizedList,
    FullCargoView,
    EdiInfoView,
    BayView,
    ReeferList,
    LoadView,
    VgmDetail,
    restowlist,
    loadlist,
    AlertaModal,
    ErroresView,
    ProjectionView,
    CanceledList,
    DischargeList,
    ListadoBaplie,
    CustomListsFormat
  },
};
</script>

<style scoped>
.menu-interno.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  border-top: none;
  box-shadow: 0 6px 6px -6px #000;
}
.menu-interno.no-margin {
  box-shadow: none;
  margin-bottom: 0;
}
/* .nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
} */
.nav-internal-dropdown,
.nav-internal-dropdown.dropdown-menu.show {
  background-color: #f5f7fa !important;
}
.menu-interno.dropdown-menu {
  background-color: #f5f7fa !important;
}
.dropdown-item {
  color: #fff !important;
}
.dropdown-item.active {
  background-color: #ebedef;
  color: #000 !important;
}
.btn-information.active {
  color: #fff;
  background-color: #636f83;
  border-color: #636f83;
}
</style>