<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
              content: $t('label.return'),
              placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{  this.isEdit ? `${$t('label.edit')} ${$t('label.damage')}` : `${$t('label.nuevo')} ${$t('label.damage')}` }} 
        </h6>
      </CCol>
    </CRow>
    <CRow class="justify-content-center px-3">
      <CCol sm="12" lg="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.basicData')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    :label="$t('label.location')"
                    :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.TpCargoViewId.$model"
                    :is-valid="hasError($v.TpCargoViewId)"
                    :invalid-feedback="errorMessage($v.TpCargoViewId)"
                    :options="LocationOptions"
                  />
                  <CSelect
                    size="sm"
                    :label="$t('label.type')"
                    :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.TpDamageId.$model"
                    :is-valid="hasError($v.TpDamageId)"
                    :invalid-feedback="errorMessage($v.TpDamageId)"
                    :options="TypeOptions"
                  />
                  <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.observation')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <CInput
                    size="sm"
                    v-uppercase
                    v-model="$v.Responsible.$model"
                    class="mb-3"
                    :label="$t('label.responsible')"
                    :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.responsible')"
                    :is-valid="hasError($v.Responsible)"
                    :invalid-feedback="errorMessage($v.Responsible)"
                  >
                  </CInput>
                  <div class="form-group form-row m-0">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Measures')} CMS`}}</label>
                    <div class="input-group col-sm-12 col-lg-8">
                      <CInput
                        type="number"
                        size="sm"
                        v-uppercase
                        v-model="$v.Width.$model"
                        class="col-sm-6 pr-1 pl-0"
                        :placeholder="$t('label.width')"
                        :is-valid="hasError($v.Width)"
                        :invalid-feedback="errorMessage($v.Width)"
                      />
                      <CInput
                        type="number"
                        size="sm"
                        v-uppercase
                        v-model="$v.Height.$model"
                        class="col-sm-6 px-0 mb-0"
                        :placeholder="$t('label.height')"
                        :is-valid="hasError($v.Height)"
                        :invalid-feedback="errorMessage($v.Height)"
                      />
                    </div>
                  </div>
                  <CSelect
                    v-if="isEdit"
                    size="sm"
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                  />
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.documentation')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <vue-dropzone 
                  ref="myVueDropzone" 
                  id="dropzone" 
                  :options="dropzoneOptions" 
                  :useCustomSlot="true"
                  v-on:vdropzone-error="DropzoneError"
                  class="col-lg-12"
                > 
                  <div>
                    <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                    <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                  </div>
                </vue-dropzone>
                <CCol sm="12" class="d-flex align-items-center justify-content-end my-2 px-0">
                  <CButton 
                    color="add" 
                    square 
                    size="sm"
                    :disabled="isSubmit"
                    @click="SubmitDocument"
                  >
                    <CIcon name="checkAlt"/>
                  </CButton>
                </CCol>
                <CCol sm="12" class="px-0">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    :items="formatedItems"
                    :fields="fields"
                    :items-per-page="5"
                    :noItemsView="tableText.noItemsViewText"
                    pagination
                    column-filter
                  >
                    <template #Imagen="{item, index}">
                      <td class="center-cell text-uppercase">
                        <img
                          :src="srcImage(item.Imagen)"
                          style="height: 32px !important; width: 32px !important;"
                          @error="'/img/errorimage.jpg'"
                        />
                      </td>
                    </template>
                    <template #Options="{item}">
                      <td class="center-cell" v-if="viewDownload(item)">
                        <CButton
                              square
                              size="sm"
                              color="excel"
                              @click="downloadArchive(item)"
                              v-c-tooltip="{
                                  content: $t('label.download'),
                                  placement: 'top-start'
                              }"
                          >
                              <CIcon name="cil-cloud-download" />
                          </CButton>
                      </td>
                      <td class="center-cell">
                        <CButton
                          color="wipe"
                          size="sm"
                          class="mr-1"
                          @click="DeleteImage(item)"
                          v-c-tooltip="{
                            content: $t('label.delete')+' '+$t('label.picture'),
                            placement: 'top-end'
                          }"
                        >
                          <CIcon name="cil-trash"/>
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import DamageRegisterValidations from '@/_validations/yard-management/container/damageRegisterValidations';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DataStorage from '@/_helpers/localStorage';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    isSubmit: false,
    TpCargoViewId: '',
    LocationList: [],
    TpDamageId: '',
    TypeList: [],
    Responsible: '',
    Width: '',
    Height: '',
    Observation: '',
    Status: 0,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 4,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.jpg, .png, .pdf',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${user.token}`
      }
    },
    FilesJson: [],
  }
}

//methods
function downloadArchive(item) {
    let route = item.FileValue.toLowerCase();
    
    if(route.includes("public/"))
        return window.open( (`${this.$store.getters['connection/getBase']}${route.split("public/").pop()}`).toLowerCase(), '_blank');
    else
        return window.open( (`${this.$store.getters['connection/getBase']}${route}`).toLowerCase(), '_blank');
}

async function getService() {
    let requests =  [
        this.$http.ejecutar("GET", "VisitTpDamageL1Yard-list"),
        this.$http.ejecutar("GET", "VisitTpCargoView-list", {TpCargoId: this.dataContainer.TpCargoId}),
    ];

  await Promise.all(requests)
    .then((responses) => {
      this.TypeList = responses[0].data.data ?? [];
      this.LocationList = responses[1].data.data ?? [];
    })
    .catch((err) => {
      this.notifyError({text: err});
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let CargoJson = this.isEdit ? 
    {
      VisitCargoInspDamageId: this.DamageItem.VisitCargoInspDamageId,
      YardCargoId: this.dataContainer.YardCargoId,
      VisitCargoId: this.dataContainer.VisitCargoId,
      TpCargoViewId: this.TpCargoViewId,
      TpDamageId: this.TpDamageId,
      Responsible: this.Responsible,
      Width: this.Width,
      Height: this.Height,
      Observation: this.Observation,
      Status: this.Status,
      FilesJson: this.FilesJson.map(item => {
        return {
          VisitCargoInspectionPhoto: item.VisitCargoInspectionPhoto ?? '',
          FileValue: item.FileValue,
          Status: 1,
        }
      }),
    }:{
      YardCargoId: this.dataContainer.YardCargoId,
      VisitCargoId: this.dataContainer.VisitCargoId,
      TpCargoViewId: this.TpCargoViewId,
      TpDamageId: this.TpDamageId,
      Responsible: this.Responsible,
      Width: this.Width,
      Height: this.Height,
      Observation: this.Observation,
      FilesJson: this.FilesJson,
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardCargoInspDamage-update' : 'YardCargoInspDamage-insert';

    this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list");
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function SubmitDocument() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
  
    let files = this.$refs.myVueDropzone.getQueuedFiles();
    if (files.length === 0) {
      throw this.$t('label.documentQuestion');
    }

    if (this.FilesJson.length == 4 || this.FilesJson.length+files.length > 4) {
      throw `${this.$t('label.MaxImage')}: 4`;
    }

    this.$http.file('YardManagement-file', files)
    .then((response) => {
      this.$refs.myVueDropzone.removeAllFiles();
      response.data.data.files.map(item => {
        this.FilesJson.push({ 
          FileValue: item.path,
          Date: DateFormater.formatTimeZoneToDateTime(new Date),
        });
      })
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
    }).catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }); 
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function DeleteImage(item) {
  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteFile')}`,
      }))
    .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      this.$http.delete('files', item.FileValue.replace(/public+[/]/i, ''))
        .then(async(response) => {
          if (this.isEdit && item.VisitCargoInspectionPhoto) {
            let UpdateImages = this.FilesJson.filter(File => File.VisitCargoInspectionPhoto);
            await this.EditSubmit(UpdateImages, item);
          }
          this.FilesJson = this.FilesJson.filter(File => File.FileValue != item.FileValue);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

 async function EditSubmit(UpdateImages, item) {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;

    let CargoJson = {
      VisitCargoInspDamageId: this.DamageItem.VisitCargoInspDamageId,
      YardCargoId: this.dataContainer.YardCargoId,
      VisitCargoId: this.dataContainer.VisitCargoId,
      TpCargoViewId: this.DamageItem.TpCargoViewId,
      TpDamageId: this.DamageItem.TpDamageId,
      Responsible: this.DamageItem.Responsible,
      Width: this.DamageItem.Width,
      Height: this.DamageItem.Height,
      Observation: this.DamageItem.Observation,
      Status: 1,
      FilesJson: UpdateImages.map(Update => {
        return {
          VisitCargoInspectionPhoto: item.VisitCargoInspectionPhoto ?? '',
          FileValue: item.FileValue,
          Status: Update.VisitCargoInspectionPhoto==item.VisitCargoInspectionPhoto ? 0 : 1,
        }
      }),
    }

    let Method = 'PUT';
    let Route = 'YardCargoInspDamage-update';

    await this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then(async (response) => {
        await this.$emit("Update-list");
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function getData(item) {
  this.TpCargoViewId = item.TpCargoViewId;
  this.TpDamageId = item.TpDamageId;
  this.Responsible = item.Responsible;
  this.Width = item.Width;
  this.Height = item.Height;
  this.Observation = item.Observation;
  this.FilesJson = item.InspectionDamagePhotographyJson;
  this.Status = item.FgActVisitCargoInspDamage ? 1 : 0;
  this.$v.$touch();
  this.$store.state.yardManagement.loading = false;
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function statusSelectColor() {
  return this.Status === 1;
}


function srcImage(icon){
  let extension = icon.split(".").pop();
  if(icon && (extension == 'jpg' || extension == 'png')){
    return icon;
  }else if (icon && ( extension == 'pdf')){
    return '/img/documentImage.png';
  }else{
    return '/img/errorimage.jpg';
  }
}

function viewDownload(item){
  let extension = item.FileValue.split(".").pop();
  if (item && ( extension == 'pdf'))
    return true;
}

function Reset() {
  this.TpCargoViewId = '';
  this.TpDamageId = '';
  this.Responsible = '';
  this.Width = '';
  this.Height = '';
  this.Observation = '';
  this.FilesJson = [];
  this.$v.$reset();
  this.$emit("Close");
}

//computed
function LocationOptions() {
  if(this.LocationList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.LocationList.map(function(e){
      chart.push({
        value: e.TpCargoViewId,
        label: e[`TpCargoViewName${_lang}`],
      })
    })
    return chart;
  }
}

function TypeOptions() {
  if(this.TypeList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.TypeList.map(function(e){
      chart.push({
        value: e.TpDamageId,
        label: `${e.TpDamageAbbrev}: ${e[`TpDamageName${_lang}`]}`,
      })
    })
    return chart;
  }
}

function fields(){ 
  return [
    { key: 'Nro', label: '#',_style: 'width:5%; text-align:center;', filter: false},
    { key: 'Imagen', label: this.$t('label.image'), _style: 'width: 50%;', sorter: false,  filter: false, _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 20%;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 20%;', _classes:"text-center"},
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 45px',  sorter: false,  filter: false, _classes:'text-center' }
  ]
}

function formatedItems() {
  return this.FilesJson.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      Imagen: item.FileValue ? `${this.$store.getters['connection/getBase']}${item.FileValue.replace(/public+[/]/i, '')}` : '',
      TransaLogin: item.TransaLogin ?? DataStorage.getUser().Login,
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : item.Date,
    }
  })
}

export default {
  name: 'damage-register',
  props: { Active: Boolean, DamageItem: Object, isEdit: Boolean },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data,
  mixins: [ModalMixin, GeneralMixin],
  directives: UpperCase,
  validations: DamageRegisterValidations,
  methods: {
    getService,
    Submit,
    SubmitDocument,
    DeleteImage,
    EditSubmit,
    getData,
    DropzoneError,
    statusSelectColor,
    srcImage,
    viewDownload,
    Reset,
    downloadArchive
  },
  computed: {
    LocationOptions,
    TypeOptions,
    fields,
    formatedItems,
    ...mapState({
        dropItemInspection: state => state.yardManagement.dropItemInspection,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    Active: async function (NewVal) {
      if (NewVal) {
        this.$store.state.yardManagement.loading = true;
        await this.getService();
        if (this.isEdit) {
          this.getData(this.DamageItem);
        } else {
          this.$store.state.yardManagement.loading = false;
        }
      }
    },
    dropItemInspection: function (NewVal, OldVal) {
      if(OldVal == 1){
        this.Reset();
      }
    },
  },
};
</script>