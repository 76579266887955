<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <modal-imdg-code
      :modal.sync="modalCodeActive"
      :imdgCodeData="imdgCodeSelected"
      :titleModalImdgCode="titleModalImdgCode"
      :modalEdit="modalEditImdgCode"
      @updated-imdg-code-list="getImdgListByClass"
      @close-modal-imdg-code="closeModalImdgCode"
    />

    <modal-imdg-class
      :modal.sync="modalClassActive"
      :imdgClassData="imdgClassSelected"
      :titleModalImdgClass="titleModalImdgClass"
      :modalEdit="modalEditImdgClass"
      @updated-imdg-class-list="getImdgClassList"
      @close-modal-imdg-class="closeModalImdgClass"
    />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.imdgList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow class="mt-2">
          <CCol sm="12" lg="12" xl="12">
              <CustomTabs :activeTab="0">
                  <CustomTab :title="$t('label.imdgClass')" >
                    <template #title>
                      <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Imdg-Class.svg" alt="Card image cap">
                      {{$t('label.imdgClass')}}                
                    </template>
                      <CRow>
                        <CCol sm="12" class="d-flex justify-content-end py-2">
                          <CButton
                            color="add"
                            class="d-flex align-items-center"
                            @click="showModalCreateImdgClass"
                            v-c-tooltip="{
                              content: $t('label.imdgClass'),
                              placement: 'top-end',
                            }"
                          >
                            <CIcon name="cil-playlist-add" />{{$t('label.nueva')}}
                          </CButton>
                        </CCol>
                      </CRow>
                      <CRow class="mt-2">
                        <CCol sm="12">
                          <dataTableExtended
                            class="align-center-row-datatable"
                            size="sm"
                            :fields="imdgClassFields"
                            :items="computedImdgClassList"
                            :items-per-page="5"
                            column-filter
                            pagination
                            :table-filter="tableText.tableFilterText"
                            :items-per-page-select="tableText.itemsPerPageText"
                            :noItemsView="tableText.noItemsViewText"
                            :loading="loadingTable"
                            sorter
                          >
                            <template #loading >
                              <loading/>
                            </template>
                            <template #Status="{ item }">
                                <td class="text-center">
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                                </td>
                            </template>
                            <template #options="{ item }">
                              <td class="text-center">
                                <CButton
                                    square
                                    size="sm"
                                    color="edit"
                                    class="align-items-center"
                                    v-c-tooltip="{placement:'top-end',content:$t('label.edit') + ' ' + $t('label.imdgClass')}"
                                    @click="showModalEditImdgClass(item)"
                                >
                                    <CIcon name="pencil" />
                                </CButton>
                              </td>
                            </template>
                          </dataTableExtended>
                        </CCol>
                      </CRow>
                  </CustomTab>
                  <CustomTab :title="$t('label.imdg')" >
                      <template #title>
                          <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Imdg.svg" alt="Card image cap">
                          {{$t('label.imdg')}}                
                      </template>  
                      <CRow>
                        <CCol sm="12" class="d-flex justify-content-end py-2">
                          <CButton
                            color="add"
                            class="d-flex align-items-center"
                            @click="showModalCreateImdgCode"
                            v-c-tooltip="{
                              content: $t('label.imdgCode'),
                              placement: 'top-end',
                            }"
                          >
                            <CIcon name="cil-playlist-add" />{{$t('label.nuevo')}}
                          </CButton>
                        </CCol>
                      </CRow>  
                      <CRow class="mt-2">
                          <CCol sm="12" >
                            <dataTableExtended
                              class="align-center-row-datatable"
                              size="sm"
                              :fields="imdgFields"
                              :items="computedImdgList"
                              :items-per-page="5"
                              column-filter
                              pagination
                              :table-filter="tableText.tableFilterText"
                              :items-per-page-select="tableText.itemsPerPageText"
                              :noItemsView="tableText.noItemsViewText"
                              :loading="loadingTable"
                              sorter
                            >
                              <template #loading >
                                <loading/>
                              </template>
                              <template #ImdgRoute="{item}">
                                <td class="text-center">
                                  <c-img-extended
                                    :src="item.ImgPreview"
                                    :key="item.Nro"
                                    :error-options="{ width: 40, height: 40, fontSize: 8 }"
                                    thumbnail
                                    :width="imageWidth"
                                    :height="imageHeight"
                                  />
                                </td>
                              </template>
                              <template #Status="{ item }">
                                  <td class="text-center">
                                  <CBadge :color="getBadge(item.Status)">
                                      {{ $t('label.'+item.Status) }}
                                  </CBadge>
                                  </td>
                              </template>
                              <template #options="{ item }">
                                <td class="text-center">
                                  <CButton
                                      size="sm"
                                      color="edit"
                                      class="align-items-center"
                                      v-c-tooltip="{
                                          placement:'top-end',
                                          content:  $t('label.edit')+' '+$t('label.imdgCode')
                                      }"
                                      @click="showModalEditImdgCode(item)"
                                  >
                                      <CIcon name="pencil" />
                                  </CButton>
                                </td>
                              </template>
                            </dataTableExtended>
                          </CCol>         
                      </CRow>
                  </CustomTab>
              </CustomTabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import modalImdgCode from './modal-imdg-code.vue';
import modalImdgClass from './modal-imdg-class.vue';

import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import CImgExtended from '@/components/CImgExtended';

function data() {
  return {
    modalAdd: '',
    imdgClassSelected: {},
    imdgCodeSelected: {},
    titleModalImdgClass: '',
    titleModalImdgCode: '',
    loadingTable: false,
    Loading: false,
    isLoading: false,
    isEditImdg: false,
    isEditImdgClass: false,
    loadingImdgTable:false,
    imdgItems:[],
    selectImdgClass:[],
    imdgClassItems:[],
    imageWidth: 45,
    imageHeight: 45,
    modalCodeActive: false,
    modalClassActive: false,
    modalEditImdgClass: false,
    modalEditImdgCode: false,
  };
}

async function getImdgClassList() {
    this.Loading = true;
    await this.$http
      .get('ImdgClass-list')
      .then((response) => (this.imdgClassItems = response.data.data))
    this.Loading = false;
}

async function getImdgClassById(ImdgClassId) {
    return await  this.$http.get(`ImdgClass-by-id?ImdgClassId=${ImdgClassId}`);
}

async function getSelectImdgClasses() {
      await this.$http
    .get('ImdgClass-list?Filter=ACTIVO')
    .then((response) => {
        this.selectImdgClass = response.data.data;
    });
}

async function getImdgListByClass(imdgClassId = "") {
    this.Loading = true;
    this.loadingImdgTable = true;
    await this.$http.get(`Imdg-list?ImdgClassId=${imdgClassId}`).then((response) => {
        this.imdgItems = response.data.data;
        this.loadingImdgTable = false;
    });
    this.Loading = false;
}

function changeSelectImdgValue(event) {
    this.formImdg.ImdgClassId = event.target.value;
    this.getImdgListByClass(this.formImdg.ImdgClassId);
    //this.verifyImdgClass(this.formImdg.ImdgClassId);
}

function imdgFields(){
    return [
        { label: '#', key: 'Nro', _style: 'width:3%; text-align:left', filter: false },
        { label: this.$t('label.imdgClass'), key: 'ImdgClassName', _classes: 'text-uppercase text-center', _style:'width:20%' },
        { label: `${this.$t('label.imdgDivision')} (ES)`, key: 'ImdgNameEs', _classes: 'text-uppercase text-center', _style:'width:25%' },
        { label: `${this.$t('label.imdgDivision')} (EN)`, key: 'ImdgNameEn', _classes: 'text-uppercase text-center', _style:'width:25%' },
        { label: this.$t('label.code'), key: 'ImdgCod', _classes: 'text-uppercase text-center',_style:'width:8%;'},
        { label: this.$t('label.image'), key: 'ImdgRoute', sorter: false, filter: false, _style:'width:5%; min-width:50px' },
        { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase text-center', _style:'width:10%' },
        { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:8%' },
        { label: this.$t('label.status'), key: 'Status', _classes: 'text-center', _style:'width:8%' },
        { label: '', key: 'options', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
    ];
}

function imdgClassFields(){
    return[
        { label: '#', key: 'Nro', _style: 'width:5%; text-align:left', filter: false },
        { label: `${this.$t('label.imdgClass')} (ES)` , key: 'ImdgClassNameEs', _classes: 'text-uppercase text-center', _style:'width:30%' },
        { label: `${this.$t('label.imdgClass')} (EN)`, key: 'ImdgClassNameEn', _classes: 'text-uppercase text-center', _style:'width:30%' },
        { label: this.$t('label.classAcronym'), key: 'ImdgClassAbbrev', _classes: 'text-uppercase text-center', _style:'width:10%' },
        { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase text-center', _style:'width:13%' },
        { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:13%' },
        { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-uppercase', _style:'width:12%' },
        { label: '', key: 'options', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
    ];
}


function computedSelectFormatted() {
    let list = [];
    if(this.selectImdgClass.length > 0) {
        list = this.selectImdgClass.sort( 
            (a, b) => a.ImdgClassAbbrev - b.ImdgClassAbbrev
        );
    }
    return list.map((item) => {
        return {
            label: item.ImdgClassAbbrev + ' - ' + item.ImdgClassName,
            value: item.ImdgClassId,
        };
    });
}
//lista de codigos
function computedImdgList() {
  const VUE_APP_PATH = `${this.$store.getters["connection/getBase"]}`;
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if (this.imdgItems.length > 0) {
        return this.imdgItems.map((item) => {
            return {
                ...item,
                ImdgClassName: item[`ImdgClassName${_lang}`] ??  'N/A',
                ImdgNameEs: item.ImdgNameEs ?? 'N/A',
                ImdgNameEn: item.ImdgNameEn ?? 'N/A',
                FormatedDate: item.TransaRegDate
                ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
                : 'N/A',
                _classes: item.Status !== 'ACTIVO' ? 'table-danger' : '',
                ImgPreview: item.ImdgRoute !== ('' || null) ? VUE_APP_PATH + item.ImdgRoute : '',
            };
        });
    }
}
//lista de clases de imdg
function computedImdgClassList() {
    if(this.imdgClassItems.length > 0){
      let list = [];
      
      list = this.imdgClassItems.sort((val) => {
          return val.Status === 'ACTIVO' ? -1 : 1;
      });

      return list.map((item) => {
      return {
          ...item,
          ImdgClassNameEs: item.ImdgClassNameEs ?? 'N/A',
          ImdgClassNameEn: item.ImdgClassNameEn ?? 'N/A',
          FormatedDate: item.TransaRegDate
              ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
              : 'N/A',
          _classes: item.Status !== 'ACTIVO' ? 'table-danger' : '',
          };
      })
    }
}

async function mountedImdgList() {
  this.Loading = true;
  await this.getImdgClassList();
  await this.getSelectImdgClasses();
  await this.getImdgListByClass();
  this.Loading = false;
}

function showModalCreateImdgClass() {
  this.titleModalImdgClass = this.$t('label.nueva')+' '+this.$t('label.imdgClass');
  this.modalClassActive = true;
  this.modalEditImdgClass = false;
}

function showModalEditImdgClass(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1)
  let title = item[`ImdgClassName${_lang}`];

  this.imdgClassSelected = item;
  this.titleModalImdgClass = this.$t('label.edit')+' '+this.$t('label.imdgClass')+': '+title;
  this.modalClassActive = true;
  this.modalEditImdgClass = true;
}


function closeModalImdgClass() {
  this.imdgClassSelected = {};
  this.modalEditImdgClass = false;
  this.modalClassActive = false;
  this.isEditImdgClass = false;
  this.titleModalImdgClass = '';
}
// function closeModalImdgClass(event) {
//   this.modalClassActive = event;
//   if (event === false) {
//     this.imdgClassSelected = {};
//     this.modalEditImdgClass = false;
//     this.modalClassActive = false;
//     this.getImdgClassList();
//   }
// }

function showModalCreateImdgCode() {
  this.titleModalImdgCode = this.$t('label.nuevo')+' '+this.$t('label.imdgCode');
  this.modalEditImdgCode = false;
  this.modalCodeActive = true; ;
}

function showModalEditImdgCode(item) {
  this.imdgCodeSelected = item;
  this.titleModalImdgCode = this.$t('label.edit')+' '+this.$t('label.imdgCode')+': '+this.imdgCodeSelected.ImdgCod;
  this.modalEditImdgCode = true;
  this.modalCodeActive = true;  
}

function closeModalImdgCode() {
    this.imdgCodeSelected = {};
    this.modalEditImdgCode = false;
    this.isEditImdg = false,
    this.modalCodeActive = false;
    this.titleModalImdgCode = '';
}



export default {
  name: 'imdg-index',
  data,
  components: { CImgExtended, CustomTabs, CustomTab, modalImdgCode, modalImdgClass},
  mixins: [GeneralMixin],
  methods: {
    closeModalImdgCode,
    showModalEditImdgCode,
    showModalCreateImdgCode,
    closeModalImdgClass,
    showModalEditImdgClass,
    showModalCreateImdgClass,
    getSelectImdgClasses,
    getImdgListByClass,
    getImdgClassList,
    getImdgClassById,
    changeSelectImdgValue,
  },
  mounted: mountedImdgList,

  computed: {
    computedImdgList,
    computedSelectFormatted,
    imdgFields,
    imdgClassFields,
    computedImdgClassList,  
  },
};
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
.table-imd-index {
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>
