<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaCliente"
    >
      
 <CRow>
         <CCol sm="12" lg="6" class="pl-0 "> 
                      <CRow sm="12" lg="12"  style="margin-left: 13px;">
                        <!-- <CCol sm="5" class="text-right mt-1 pr-1">
                          <label class="text-danger">* </label><label >{{  $t('label.rifNumber') }}</label>
                        </CCol> -->
                        <CCol sm="12" lg="7" class="pl-0 pr-1" ><!-- rif -->
                          <CSelect
                            v-uppercase
                            v-model="LetraRif"
                            :value.sync="LetraRif"
                            :options="LetraOptions"
                            add-label-classes="required text-right"
                            :horizontal="{label:'col-sm-10 col-lg-8', input:'col-sm-12 col-lg-4'}"
                            :label="$t('label.rifNumber')"
                            size="sm"
                          />
                        </CCol>
                        <CCol sm="12" lg="5" class="pl-1"> <!-- rif -->
                          <CInput
                            v-uppercase
                            v-model.trim="$v.ClientRif.$model"
                            ref="ClientRif"
                            maxlength="9" 
                            minlength="9"
                            :horizontal="{input:'col-sm-12 col-lg-11'}"
                            :is-valid="hasError($v.ClientRif)"
                            :invalid-feedback="errorMessage($v.ClientRif)"
                            @blur="$v.$touch()"
                            size="sm"
                            :placeholder="$t('label.rifNumber')"
                          />
                        </CCol>
                    </CRow>
                    <CCol sm="12" lg="12">
                      <CInput
                        :label="$t('label.name')"
                        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        v-uppercase
                        add-label-classes="required text-right"
                        :invalid-feedback="errorMessage($v.ClientName)"
                        maxlength="250"
                        size="sm"
                        v-model.trim="$v.ClientName.$model"
                        :is-valid="hasError($v.ClientName)"
                        :placeholder="$t('label.clientName')"                
                      />
                    </CCol>
                    <CCol sm="12" lg="12">
                      <CInput
                        :label="$t('label.alias')"
                        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        v-uppercase
                        add-label-classes="text-right"
                        :invalid-feedback="errorMessage($v.ClientAlias)"
                        maxlength="8"
                        minlength="3"
                        size="sm"
                        v-model.trim="$v.ClientAlias.$model"
                        :is-valid="hasError($v.ClientAlias)"
                        :placeholder="$t('label.Alias')"                
                      />
                    </CCol>
                    <CCol sm="12" lg="12">
                      <CInput
                        :label="$t('label.representative')"
                        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        v-uppercase
                        add-label-classes="required text-right"
                        :invalid-feedback="errorMessage($v.Representative)"
                        maxlength="50"
                        size="sm"
                        v-model.trim="$v.Representative.$model"
                        :is-valid="hasError($v.Representative)"
                        :placeholder="$t('label.representativeName')"                
                      />
                    </CCol>
                    <CCol sm="12" lg="12">
                        <CSelect
                          :label="$t('label.condition')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          v-uppercase
                          size="sm"
                          addLabelClasses="required text-right"
                          :options="FgGovernmentOptions"
                          v-model.trim="$v.FgGovernment.$model"
                          :value.sync="FgGovernment"
                          :is-valid="hasError($v.FgGovernment)"
                          :invalid-feedback="errorMessage($v.FgGovernment)"
                        />
                      </CCol> 
                    <CCol sm="12" lg="12">
                      <CTextarea
                      :label="$t('label.address')"
                      :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                      v-uppercase
                      add-label-classes="required text-right"
                      :invalid-feedback="errorMessage($v.ClientAddress)"
                      rows="2"
                      v-model.trim="$v.ClientAddress.$model"
                      :is-valid="hasError($v.ClientAddress)"
                      :placeholder="$t('label.clientAddress')"  
                      maxlength="500"  
                      size="sm"            
                    />
                    </CCol>
          </CCol>

          <CCol sm="12" lg="6" class="pl-0 pr-3" >
                      <CCol sm="12" lg="12">
                        <CInput
                          :label="$t('label.mainPhone')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          v-uppercase
                          add-label-classes="required text-right"
                          ref="PrimaryPhone"
                          maxlength="15"
                          minlength="11"
                          size="sm"
                          v-model.trim="$v.PrimaryPhone.$model"
                          :is-valid="hasError($v.PrimaryPhone)"
                          :invalid-feedback="errorMessage($v.PrimaryPhone)"
                          :placeholder="$t('label.mainPhone')"   
                        />
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CInput
                        :label="$t('label.secondaryPhone')"
                        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        v-uppercase 
                        add-label-classes="text-right"
                        maxlength="15"
                        minlength="11"
                        size="sm"
                        v-model.trim="$v.SecondaryPhone.$model"
                        :is-valid="hasError($v.SecondaryPhone)"
                        :invalid-feedback="errorMessage($v.SecondaryPhone)"
                        :placeholder="$t('label.secondaryPhone')"               
                        />
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CInput
                          :label="$t('label.mainEmail')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          v-uppercase
                          add-label-classes="required text-right"
                          v-model="PrimaryEmail"
                          maxlength="100"
                          size="sm"
                          v-model.trim="$v.PrimaryEmail.$model"
                          :is-valid="hasError($v.PrimaryEmail)"
                          :invalid-feedback="errorMessage($v.PrimaryEmail)"
                          :placeholder="$t('label.mainEmail')"                
                        />     
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CInput
                          :label="$t('label.secondaryEmail')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          v-uppercase
                          add-label-classes=" text-right"
                          v-model="SecondaryEmail"
                          maxlength="100"
                          size="sm"
                          :invalid-feedback="errorMessage($v.SecondaryEmail)"
                          v-model.trim="$v.SecondaryEmail.$model"
                          :is-valid="hasError($v.SecondaryEmail)"
                          :placeholder="$t('label.secondaryEmail')"                
                        />
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CInput
                          type="color"
                          size="sm"
                          :label="$t('label.color')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          add-label-classes="text-right"
                          :is-valid="hasError($v.Color)"
                          v-model.trim="$v.Color.$model"
                        />
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CSelect v-if="actualizar"
                          :label="$t('label.status')"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          v-uppercase
                          size="sm"
                          addLabelClasses="text-right"
                          v-model="Status"
                          :value.sync="Status"
                          :is-valid="statusSelectColor"
                          :options="selectOptions"
                        />
                      </CCol>    
          </CCol>
      </CRow>
      <template #footer>
        <CButton color="add" :disabled="isSubmit" class="d-flex align-items-center" @click="evaluaStatus" >
          <CIcon name="checkAlt"/>&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" :disabled="isSubmit" class="d-flex align-items-center" @click="ActualizaCliente=false">
          <CIcon name="x"/>&nbsp; <span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import ClienteValidations from '@/_validations/cliente/clienteValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';

//DATA
function data () {
  return {
    //MODELO
    LetraRif: 'J',
    ClientRif: '',
    ClientName: '',
    ClientAddress: '',
    PrimaryPhone: '',
    SecondaryPhone: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    Representative: '',
    FgGovernment: '',
    ClientAlias: '',
    FgActClient: '1',
    Status: 1,
    originStatus: 1,
    UserId: '',
    Color: '',
    //VARIABLES
    ActualizaCliente: false,
    Loading: false,    
    LetraOptions: ['J','G','P','V'],
    tituloModal: '',
    actualizar: false,
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
    isSubmit: false, 
  }
}

//METHOD
function limpiarDatos() {
  this.ClientRif = '';
  this.Representative = '';
  this.FgGovernment = '';
  this.ClientAddress = '';
  this.ClientName = '';
  this.ClientAlias = '';
  this.Color = '';
  this.Status= 1;
  this.$nextTick(() => { this.$v.$reset() })
}

function limpiarContacto() {
  this.PrimaryEmail = '';
  this.SecondaryEmail = '';
  this.PrimaryPhone = '';
  this.SecondaryPhone = '';
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status!==this.originStatus) {
    
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar () {
 // if (this.$v.$invalid) return false;
 try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
  let res = [];
  let rif = this.LetraRif+this.ClientRif;
  let metodo = '';
  let ruta = '';
  let ClientJson = [];
  
  //ASIGNACION DE VARIABLES PARA GUARDAR
  if (this.actualizar) {
    ClientJson = {
      ClientId: this.ClientId,
      ClientRif: rif,
      ClientName: this.ClientName,
      ClientAddress: this.ClientAddress,
      PrimaryEmail: this.PrimaryEmail,
      SecondaryEmail: this.SecondaryEmail,
      PrimaryPhone: this.PrimaryPhone,
      SecondaryPhone: this.SecondaryPhone,
      Representative: this.Representative,
      FgGovernment: this.FgGovernment,
      ClientAlias: this.ClientAlias,
      Status: this.Status,
      UserId: this.user.UserId,
      Color: this.Color,
    };
    metodo = 'PUT';
    ruta = "Client-update";
  } else {
    ClientJson = {
      ClientRif: rif,
      ClientName: this.ClientName,
      ClientAddress: this.ClientAddress,
      PrimaryEmail: this.PrimaryEmail,
      SecondaryEmail: this.SecondaryEmail,
      PrimaryPhone: this.PrimaryPhone,
      SecondaryPhone: this.SecondaryPhone,
      Representative: this.Representative,
      FgGovernment: this.FgGovernment,
      ClientAlias: this.ClientAlias,
      UserId: this.user.UserId,
      Color: this.Color,
    };
    metodo = 'POST';
    ruta = "Client-insert";
  }

  this.$http.ejecutar(metodo, ruta, ClientJson, { root: 'ClientJson' })
  .then(response => {
    res = [...response.data.data];
    this.limpiarDatos();
    this.limpiarContacto();
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.Loading= false;
    this.$emit('child-refresh', true);
    this.ActualizaCliente = false;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

//COMPUTED
function isDisabled() {
  return this.$v.$invalid
}

function statusSelectColor(){
  if(this.Status){
    return this.Status === 1;
  } else {
    return false;
  }
}


function FgGovernmentOptions() {
  return [
    { value:'', label: this.$t('label.select')},
    { value: 0, label: this.$t('label.private')},
    { value: 1, label: this.$t('label.government')},
  ]
}

export default {
  name: 'cliente-modal',
  mixins: [ModalMixin],
  components:{ 
  },
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: ClienteValidations,
  watch: {
    modal: function () {
      if (this.modal) {
        this.ActualizaCliente = true;
        if (this.modal==true) {
          this.limpiarDatos();
          this.limpiarContacto();
          this.actualizar = false;
          this.tituloModal = this.$t('label.nuevo')+''+this.$t('label.client');
          this.originStatus = 1;
          this.Status = 1;
        } else {
          let rif             = this.modal.ClientRif;
          this.actualizar     = true;
          this.tituloModal    = this.$t('label.edit')+''+this.$t('label.client')+': '+this.modal.Cliente;
          this.ClientName     = this.modal.Cliente;
          this.ClientId       = this.modal.ClientId;
          this.ClientAddress  = this.modal.ClientAddress;
          this.PrimaryEmail   = this.modal.PrimaryEmail;
          this.SecondaryEmail = this.modal.SecondaryEmail;
          this.PrimaryPhone   = this.modal.PrimaryPhone;
          this.SecondaryPhone = this.modal.SecondaryPhone;
          this.Representative = this.modal.Representative;
          this.FgGovernment   = this.modal.FgGovernment ? 1 : 0,
          this.ClientAlias    = this.modal.ClientAlias;
          this.Status         = this.modal.FgActClient ? 1 : 0;
          this.ClientRif      = rif.substr(1, (rif.length - 1));
          this.LetraRif       = rif.substr(0,1);
          this.Color          = this.modal.Color;
          this.selectOptions[this.Status];
          this.originStatus = this.Status;
          this.$v.$touch();
        }  
        this.$emit('cerrarModal');
      }
      if (this.modal==false) 
        this.$v.$touch();    
    }
  },
  methods: {
    evaluaStatus,
    limpiarDatos,
    limpiarContacto,
    guardar, 
  },
  computed: {    
    isDisabled,
    ...mapState({
      user: state => state.auth.user,
      branch: (state) => state.auth.branch,
    }),  
    statusSelectColor,
    FgGovernmentOptions
  }
}
</script>
<style lang="scss">
.modal-content-client {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }

.text-rif{
  font-size: 400px !important;
  background-color: purple !important;
}
}
</style>