<template>
    <CRow style="margin-top:20px;">
        <CCol sm="12">
            <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
            <CTabs style="width:100%;" variant="tabs" :active-tab="activePage">
                <CTab :title="$t('label.LOAD')" v-if="!visualizar">
                    <CRow>
                        <CCol sm="12" class="form-inline font-weight-bold mt-2 justify-content-center">
                            <span class="mr-2">{{ $t('label.planificacionEstiba') }}: </span>
                            <span class="mr-2">{{ $t('label.loadPort') }}: </span>
                            <CSelect
                                :options="loadPortOptions"
                                v-model.trim="LoadPortId"
                                :value.sync="LoadPortId"
                                @change="getAllLoad"
                            />
                        </CCol>
                    </CRow>
                    <CCard class="mt-2">
                        <CCardHeader class="text-center">
                            <h5>{{ $t('label.exe') }}</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow class="mb-2">
                                <CCol sm="12" lg="6" class="border-right">
                                    <CRow>
                                        <CCol sm="6" v-for="item in summaryLoad" :key='item.CraneName' class="mt-1">
                                            <CCard class="border shadow h-100" :style="{ borderLeft: '.25rem solid ' + colores[Math.floor(Math.random() * colores.length)] + ' !important' }">
                                                <CCardBody class="text-center">
                                                    <h4>
                                                        <CIcon name="crane" />
                                                        {{ item.CraneName }}
                                                    </h4>
                                                    {{ $t('label.workHour') }}
                                                    <h5 class="my-2">
                                                        <b>{{ item.TotalHoursCrane }}</b>
                                                    </h5>
                                                    <hr />
                                                    <h5 class="my-2">
                                                        <b>{{ item.EstimatedMovsCrane }}</b>
                                                    </h5>
                                                    {{ $t('label.movements') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol sm="12" lg="6">
                                    <CRow class="mt-1">
                                        <CCol sm="12" class="text-center py-4">
                                            <CImg src="/img/iconos/Tab-Vista-Transversal.svg" class="halto" />
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    {{ $t('label.estimated') }}
                                                    <h1 class="my-2">
                                                        <b>{{ EstimatedWorkHoursLoad }}</b>
                                                    </h1>
                                                    {{ $t('label.workHour') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    {{ $t('label.average') }}
                                                    <h1 class="my-2">
                                                        <b>{{ AverageMovsPerHourLoad }}</b>
                                                    </h1>
                                                    {{ $t('label.movement') }} / {{ $t('label.hour') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    TOTAL
                                                    <h1 class="my-2 text-primary">
                                                        <b>{{ TotalMovsLoad }}</b>
                                                    </h1>
                                                    {{ $t('label.movement') }} / {{ $t('label.vessel') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard class="mt-2 pb-4">
                        <CCardHeader class="text-center">
                            <h5>{{ $t('label.equipmentDetail') }}</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.type') }}</h4>
                                            <CRow class="mb-3" v-for="item in loadType" :key='item.DescriptionType'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.DescriptionType.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.DescriptionType }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.originPort') }}</h4>
                                            <CRow class="mb-3" v-for="item in loadPort" :key='item.PortCode'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.PortCode.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.PortCode }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.line') }}</h4>
                                            <CRow class="mb-3" v-for="item in loadLine" :key='item.ShippingLineCode'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.ShippingLineCode.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.ShippingLineCode }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.status') }}</h4>
                                            <CRow class="mb-3" v-for="item in loadStatus" :key='item.StatusName'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.StatusName.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.StatusName }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" class="text-right mt-2">
                                    <CButton
                                        color="primary"
                                        @click="loadSummary"
                                    >
                                        <CIcon name="cil-file-excel" />
                                        {{ $t('label.view')+' '+$t('label.details') }}
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CTab>
                <CTab :title="$t('label.DISCHARGE')" v-if="visualizar">
                    <CRow>
                        <CCol sm="12" class="form-inline font-weight-bold mt-2 justify-content-center">
                            <span class="mr-2">{{ $t('label.planificacionEstiba') }}: </span>
                            <span class="mr-2">{{ $t('label.dischargePort') }}: </span>
                            <CSelect
                                :options="dischargePortOptions"
                                v-model.trim="DischargePortId"
                                :value.sync="DischargePortId"
                                @change="getAllDischarge"
                            />
                        </CCol>
                    </CRow>
                    <CCard class="mt-2">
                        <CCardHeader class="text-center">
                            <h5>{{ $t('label.exe') }}</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow class="mb-2">
                                <CCol sm="12" lg="6" class="border-right">
                                    <CRow>
                                        <CCol sm="6" v-for="item in summaryDischarge" :key='item.CraneName' class="mt-1">
                                            <CCard class="border shadow h-100" :style="{ borderLeft: '.25rem solid ' + colores[Math.floor(Math.random() * colores.length)] + ' !important' }">
                                                <CCardBody class="text-center">
                                                    <h4>
                                                        <CIcon name="crane" />
                                                        {{ item.CraneName }}
                                                    </h4>
                                                    {{ $t('label.workHour') }}
                                                    <h5 class="my-2">
                                                        <b>{{ item.TotalHoursCrane }}</b>
                                                    </h5>
                                                    <hr />
                                                    <h5 class="my-2">
                                                        <b>{{ item.EstimatedMovsCrane }}</b>
                                                    </h5>
                                                    {{ $t('label.movements') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol sm="12" lg="6">
                                    <CRow class="mt-1">
                                        <CCol sm="12" class="text-center py-4">
                                            <CImg src="/img/iconos/Tab-Vista-Transversal.svg" class="halto" />
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    {{ $t('label.estimated') }}
                                                    <h1 class="my-2">
                                                        <b>{{ EstimatedWorkHoursDischarge }}</b>
                                                    </h1>
                                                    {{ $t('label.workHour') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    {{ $t('label.average') }}
                                                    <h1 class="my-2">
                                                        <b>{{ AverageMovsPerHourDischarge }}</b>
                                                    </h1>
                                                    {{ $t('label.movement') }} / {{ $t('label.hour') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    TOTAL
                                                    <h1 class="my-2 text-primary">
                                                        <b>{{ TotalMovsDischarge }}</b>
                                                    </h1>
                                                    {{ $t('label.movement') }} / {{ $t('label.vessel') }}
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard class="mt-2 pb-4">
                        <CCardHeader class="text-center">
                            <h5>{{ $t('label.equipmentDetail') }}</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.type') }}</h4>
                                            <CRow class="mb-3" v-for="item in dischargeType" :key='item.DescriptionType'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.DescriptionType.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.DescriptionType }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.destinationPort') }}</h4>
                                            <CRow class="mb-3" v-for="item in dischargePort" :key='item.PortCode'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.PortCode.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.PortCode }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.line') }}</h4>
                                            <CRow class="mb-3" v-for="item in dischargeLine" :key='item.ShippingLineCode'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.ShippingLineCode.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.ShippingLineCode }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="3" class="mt-1">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">{{ $t('label.status') }}</h4>
                                            <CRow class="mb-3" v-for="item in dischargeStatus" :key='item.StatusName'>
                                                <CCol sm="12">
                                                    <CRow :style="{ fontWeight: (item.StatusName.indexOf('TOTAL') < 0) ? 'normal' : 'bold' }">
                                                        <CCol sm="9">{{ item.StatusName }}</CCol>
                                                        <CCol sm="6" lg="3" class="mt-1">{{ item.Cant }}</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" class="text-right mt-2">
                                    <CButton
                                        color="primary"
                                        @click="dischargeSummary"
                                    >
                                        <CIcon name="cil-file-excel" />
                                        {{ $t('label.view')+' '+$t('label.details') }}
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';

    //data
    function data() {
        return {
            isLoading:false,
            colores:['grey','DarkCyan','DarkBlue','DarkOrange','DarkGreen','brown', 'DarkMagenta'],
            summaryLoad:[],
            summaryDischarge:[],
            loadItems:[],
            dischargeItems:[],
            loadPortOptions:[],
            loadType:[],
            loadPort:[],
            loadLine:[],
            loadStatus:[],
            dischargePortOptions:[],
            dischargeType:[],
            dischargePort:[],
            dischargeLine:[],
            dischargeStatus:[],
            activePage: 0,
            TotalMovsLoad: 0,
            EstimatedWorkHoursLoad: 0,
            AverageMovsPerHourLoad: 0,
            TotalMovsDischarge: 0,
            EstimatedWorkHoursDischarge: 0,
            AverageMovsPerHourDischarge: 0,
            LoadPortId: '',
            DischargePortId: '',
            loadingOverlay: false,
            visualizar: false,
            formatedItems: [],
            chartOptions: {
                chart: {
                    height: 200,
                },
                title: {
                    text: ''
                },
                legend: { 
                    enabled:false 
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: [{
                data: [1,2,3] // sample data
                }]
            }
        }
    }

    //methods
    async function totalInfoLoad () {
        this.loadingOverlay = true;
        let listado = Array;
        await this.$http.get("StowagePlanningProjectionSummary", { StowagePlanningId: this.StowagePlanning, EdiFileId: this.EdiFileId, PortId: this.LoadPortId })
        .then(response => {
            listado = [...response.data.data];

            if (listado[0].CraneJson.CraneJson.length > 0) {
                this.summaryLoad = listado[0].CraneJson.CraneJson;
            }

            this.TotalMovsLoad = listado[0].TotalMovs;
            this.EstimatedWorkHoursLoad = listado[0].EstimatedWorkHours;
            this.AverageMovsPerHourLoad = listado[0].AverageMovsPerHour;
            
            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function totalInfoDischarge () {
        this.loadingOverlay = true;
        let listado = Array;
        await this.$http.get("StowagePlanningProjectionSummary", { StowagePlanningId: this.StowagePlanning, EdiFileId: this.EdiFileId, PortId: this.DischargePortId })
        .then(response => {
            listado = [...response.data.data];

            if (listado[0].CraneJson.CraneJson.length > 0) {
                this.summaryDischarge = listado[0].CraneJson.CraneJson;
            }

            this.TotalMovsDischarge = listado[0].TotalMovs;
            this.EstimatedWorkHoursDischarge = listado[0].EstimatedWorkHours;
            this.AverageMovsPerHourDischarge = listado[0].AverageMovsPerHour;
            
            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function LoadPortList () {
        this.loadingOverlay = true;
        this.loadPortOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("StowagePlanningLoadPort-list", { StowagePlanningId: this.StowagePlanning, StowagePlanningEdiId: this.EdiFileId, PortId: '' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                this.loadPortOptions.push({
                    value: listado[i].PortId,
                    label: listado[i].PortCode,
                })
            }    
            this.LoadPortId = listado[0].PortId;     
            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function getInfoLoad () {
        this.loadItems = [];
        let listado = Array;

        if (this.LoadPortId !== '') {
            this.loadingOverlay = true;
            await this.$http.get("StowagePlanningProjectionLoad-list", { StowagePlanningId: this.StowagePlanning, EdiFileId: this.EdiFileId, PortId: this.LoadPortId })
            .then(response => {
                listado = [...response.data.data];

                if (listado[0].SizeTypeJson.SizeTypeJson.length > 0) {
                    this.loadType = listado[0].SizeTypeJson.SizeTypeJson;
                }

                if (listado[0].PortJson.PortJson.length > 0) {
                    this.loadPort = listado[0].PortJson.PortJson;
                }

                if (listado[0].ShippingLineJson.ShippingLineJson.length > 0) {
                    this.loadLine = listado[0].ShippingLineJson.ShippingLineJson;
                }

                if (listado[0].StatusJson.StatusJson.length > 0) {
                    this.loadStatus = listado[0].StatusJson.StatusJson;
                }

                this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
                });
                this.loadingOverlay = false;
            });
        }
    }

    async function DischargePortList () {
        this.loadingOverlay = true;
        this.dischargePortOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("StowagePlanningDischargePort-list", { StowagePlanningId: this.StowagePlanning, StowagePlanningEdiId: this.EdiFileId, PortId: '' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                this.dischargePortOptions.push({
                    value: listado[i].PortId,
                    label: listado[i].PortCode,
                })
            }    
            this.DischargePortId = listado[0].PortId;     
            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function getInfoDischarge () {
        let listado = Array;

        if (this.DischargePortId !== '') {
            this.loadingOverlay = true;
            await this.$http.get("StowagePlanningProjectionDischarge-list", { StowagePlanningId: this.StowagePlanning, EdiFileId: this.EdiFileId, PortId: this.DischargePortId })
            .then(response => {
                listado = [...response.data.data];

                if (listado[0].SizeTypeJson.SizeTypeJson.length > 0) {
                    this.dischargeType = listado[0].SizeTypeJson.SizeTypeJson;
                }

                if (listado[0].PortJson.PortJson.length > 0) {
                    this.dischargePort = listado[0].PortJson.PortJson;
                }

                if (listado[0].ShippingLineJson.ShippingLineJson.length > 0) {
                    this.dischargeLine = listado[0].ShippingLineJson.ShippingLineJson;
                }

                if (listado[0].StatusJson.StatusJson.length > 0) {
                    this.dischargeStatus = listado[0].StatusJson.StatusJson;
                }

                this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
                });

                this.loadingOverlay = false;
            });
        }
    }

    async function getAllLoad() {
        await this.totalInfoLoad();
        await this.getInfoLoad();
    }

    async function getAllDischarge() {
        await this.totalInfoDischarge();
        await this.getInfoDischarge();
    }

    async function onBtnExport(valor) {
        if(this.formatedItems.length !== 0){
            //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
            this.loadingOverlay = true;
            let rowData = this.formatedItems;
            await this.getExcel(rowData,'PROJECTION DETAIL',valor);
            this.loadingOverlay = false;
        }    
    }

    function loadSummary() {
        let listado = Array;
        this.formatedItems = [];
        let i = 1;

        if (this.LoadPortId !== '') {
            this.loadingOverlay = true;
            let CargoSumaryJson = {
                StowagePlanningId: this.StowagePlanning,
                EdiFileId: this.EdiFileId,
                LoadPortId: this.LoadPortId
            };

            this.$http.post("StowagePlanningCargoProjectionListByLoadPort", CargoSumaryJson, { root: 'CargoSumaryJson' })
            .then(response => {
                listado = [...response.data.data];

                this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                    '#': i++,
                    'BAYSLOT': listado.BaySlot,
                    'CONTAINER CODE': listado.ContainerCode,
                    'LOAD PORT': listado.LoadPort,
                    //GpoCargoName: listado.GpoCargoName,
                    'HAZARD CLASS': listado.ImdgCode,
                    'CONTAINER TYPE': listado.IsoCode,
                    'DISCHARGUE PORT': listado.DischargePort,
                    'CARRIER CODE': listado.ShippingLineCode,
                    'CARRIER': listado.ShippingLineName,
                    'SIZE FT': listado.Size,
                    'DESCRIPTION': listado.BbUnits,
                    'STATUS': listado.TpCargoStatusName,
                    'TRANSHIPMENT PORT': listado.TranshipmentPort,
                    'WEIGTH': listado.Weigth,
                }));
                
                this.onBtnExport(true);

                this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
                });

                this.loadingOverlay = false;
            });
        }
    }

    function dischargeSummary() {
        let listado = Array;
        let i = 1;

        if (this.DischargePortId !== '') {
            this.loadingOverlay = true;
            let CargoSumaryJson = {
                StowagePlanningId: this.StowagePlanning,
                EdiFileId: this.EdiFileId,
                DischargePortId: this.DischargePortId
            };

            this.$http.post("StowagePlanningCargoProjectionListByDischargePort", CargoSumaryJson, { root: 'CargoSumaryJson' })
            .then(response => {
                listado = [...response.data.data];
                this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                    '#': i++,
                    'BAYSLOT': listado.BaySlot,
                    'CONTAINER CODE': listado.ContainerCode,
                    'LOAD PORT': listado.LoadPort,
                    //GpoCargoName: listado.GpoCargoName,
                    'HAZARD CLASS': listado.ImdgCode,
                    'CONTAINER TYPE': listado.IsoCode,
                    'DISCHARGUE PORT': listado.DischargePort,
                    'CARRIER CODE': listado.ShippingLineCode,
                    'CARRIER': listado.ShippingLineName,
                    'SIZE FT': listado.Size,
                    'DESCRIPTION': listado.BbUnits,
                    'STATUS': listado.TpCargoStatusName,
                    'TRANSHIPMENT PORT': listado.TranshipmentPort,
                    'WEIGTH': listado.Weigth,
                }));
                this.onBtnExport(true);

                this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
                });

                this.loadingOverlay = false;
            });
        }
    }

    export default {
        name: 'projection-view',
        data,
        props: ['value'],
        mixins: [Reportes],
        methods: {
            totalInfoLoad,
            LoadPortList,
            getInfoLoad,
            DischargePortList,
            getInfoDischarge,
            totalInfoDischarge,
            getAllLoad,
            getAllDischarge,
            loadSummary,
            dischargeSummary,
            onBtnExport
        },
        computed: {
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                ediFileInfo: state => state.planificacionestiba.EdiFileInfo
            })
        },
        watch:{
            value: async function () {
                if (this.value==21) {
                    await this.LoadPortList();
                    await this.getAllLoad();
                    await this.DischargePortList();
                    await this.getAllDischarge();
                    this.$emit('child-refresh',true);
                }
            },
            ediFileInfo: function () {
                this.visualizar = false;
                if (this.ediFileInfo) {
                    if (this.ediFileInfo.TpEdiTransacId=="86648E9F-D6C9-4924-940A-B99AFB241468") {
                        this.visualizar = true;
                    }
                }
            }
        }
    } 
</script>

<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }

    .halto {
        width: 120px;
    }
</style>