import {
    required,
    maxLength
} from 'vuelidate/lib/validators';

export default () => {
    return {
        filtroMaquina: { required },
        filtroUbicacion: { required },
        Status: { required},
    }
}