<template>
	<div class="p-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" lg="6">
        <CRow>
          <CCol sm="4" lg="4" class="mb-2">
            <b>{{ $t('label.vessel') }}</b>
            <CCol sm="12" lg="12">
              <p class="mb-0 font-weight-400">{{ $t('label.planned') }}: {{ Vessel?.Planned }}</p>
              <p class="mb-0 font-weight-400">{{ $t('label.InDock') }}: {{ Vessel?.InBerth }}</p>
              <p class="mb-0 font-weight-400">{{ $t('label.InOperations') }}: {{ Vessel?.InOperation }}</p>
            </CCol>
          </CCol>
          <CCol sm="8" lg="8">
            <b>{{ $t('label.berth') }}</b>
            <CCol sm="12" lg="12">
              <p class="mb-0 font-weight-400">{{ $t('label.operational') }}: {{ Berth?.Opperative }}</p>
              <p class="mb-0 font-weight-400">{{ $t('label.occupied') }}: {{ Berth?.Occupied }}</p>
              <p class="mb-0 font-weight-400">{{ $t('label.available') }}: {{ Berth?.Available }}</p>
            </CCol>
          </CCol>
          
          <CCol sm="12" lg="12" class="mb-2">
            <b>{{ $t('label.VesselsAtBerth') }}</b>
            <CRow class="m-0">
              <CCol sm="6" lg="4" style="font-size: 11px;" class="mb-2" v-for="VesselDocked in VesselsDocked" :key="VesselDocked.VesselId">
                <label class="font-weight-500 text-underline mb-0">{{ VesselDocked.VesselName }}</label>
                <div style="font-size: 9px;">
                  <p class="mb-0">{{ $t('label.type') }}: {{ VesselDocked.TpVesselName }}</p> 
                  <p class="mb-0">ATA: {{ getDate(VesselDocked.Ata) }}</p>
                  <p class="mb-0">I OP: {{ getDate(VesselDocked.BeginOperationsDate) }}</p>
                  <p class="mb-0">H/OP: {{ VesselDocked.HoursOperation }}</p>
                </div>
              </CCol>
            </CRow>
          </CCol>

          <CCol sm="12" lg="12" class="mb-3">
            <CRow class="m-0 p-2 block-Teus">
              <CCol sm="auto">
                <b>{{ $t('label.yard')+'S' }}</b>
              </CCol>
              <CCol sm="auto" class="px-2">
                <p class="font-weight-500 mb-0">TOTAL TEUS: {{ YardTeus?.TotalTeus??'' }}</p>
              </CCol>
              <CCol sm="auto" class="px-2">
                <p class="font-weight-500 mb-0">{{ $t('label.occupiedTeus') }}: {{ YardTeus?.OccupiedTeus??'' }}</p>
              </CCol>
              <CCol sm="auto" class="px-2">
                <p class="font-weight-500 mb-0">{{ $t('label.availableTeus') }}: {{ YardTeus?.AvailableTeus??'' }}</p>
              </CCol>
            </CRow>
          </CCol>

          <CCol sm="12" lg="12">
            <CRow>
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.ContainerEquipment')+'S' }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ $i18n.locale=='en' ? Container.ContainerDsEn||'' : Container.ContainerDsEs||'' }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ Container.Quantity20ft }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ Container.Quantity40ft }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.type') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.TpCargoCode }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.Quantity20ft }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.Quantity40ft }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>

              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.newShippingLine') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.ShippingLineCode }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.Quantity20ft }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.Quantity40ft }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
              
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.yard') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="Yard in YardBySize">
                        <label class="mb-1">{{ Yard.YardAlias }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="Yard in YardBySize">
                        <label class="mb-1">{{ Yard.Quantity20ft }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="Yard in YardBySize">
                        <label class="mb-1">{{ Yard.Quantity40ft }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCol>

      <CCol sm="12" lg="6">
        <CRow>
          <CCol sm="12" lg="6">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.YardTeus') }}</b>
                <div style="height: 167px">
                  <SimpleDonutOrPie
                    :Series="[TeusInYard?.OccupiedTeus??0, TeusInYard?.AvailableTeus??0]"
                    :Categories="[$t('label.Ocupados'), $t('label.Unoccupied')]"
                    :Colors="['#41aaf7f7', '#8fcbf7f7']"
                    :isDonut="false"
                    Width="100%"
                    Height="200px"
                  />
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="6">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.ContainersInYard') }}</b>
                <div style="height: 167px">
                  <SimpleDonutOrPie
                    :Series="SeriesContainers"
                    :Categories="LabelsContainers"
                    :Colors="ColorsContainers"
                    :isDonut="true"
                    Width="100%"
                    Height="200px"
                  />
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" class="mt-2">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.newShippingLine') }}</b>
                <div>
                  <SimpleBar
                    :Series="SeriesShippingLine"
                    :Categories="LabelsShippingLine"
                    :Colors="ColorsShippingLine"
                    Width="100%"
                    Height="250px"
                  />
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import SimpleDonutOrPie from './simple-donut-or-pie';
import SimpleBar from './simple-bar';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    Loading: false,
    Vessel: {},
    Berth: {},
    VesselsDocked: [],
    YardTeus: {},
    ContMovsBySize: [],
    TpCargoBySize: [],
    TpCargoBySizeJson: [],
    ShippingLineBySize: [],
    YardBySize: [],
    TeusInYard: {},
    ContInYard: {},
    ContByShippingLine: [],
  }
}

//methods
function PortStatistics() {
  this.Loading = true;
  this.$http.get('PortStatistics-by-CompanyBranchId', { CompanyBranchId: this.CompanyBranchId})
    .then(response => {
      let PortStatistics = response?.data?.data[0] ?? {};
      this.Vessel = PortStatistics?.VesselsJson&&PortStatistics?.VesselsJson[0] ? PortStatistics.VesselsJson[0] : {};
      this.Berth = PortStatistics?.BerthsJson&&PortStatistics?.BerthsJson[0] ? PortStatistics?.BerthsJson[0] : {};
      this.VesselsDocked = PortStatistics?.VesselsDockedJson ?? [];
      this.YardTeus = PortStatistics?.YardTeusJson&&PortStatistics?.YardTeusJson[0] ? PortStatistics?.YardTeusJson[0] : {};
      this.ContMovsBySize = PortStatistics?.ContMovsBySizeJson ?? [];
      this.TpCargoBySize = PortStatistics?.TpCargoBySizeJson ?? [];
      this.ShippingLineBySize = PortStatistics?.ShippingLineBySizeJson ?? [];
      this.YardBySize = PortStatistics?.YardBySizeJson ?? [];
      this.TeusInYard = PortStatistics?.TeusInYardJson&&PortStatistics?.TeusInYardJson[0] ? PortStatistics?.TeusInYardJson[0] : {};
      this.ContInYard = PortStatistics?.ContInYardJson&&PortStatistics?.ContInYardJson[0] ? PortStatistics?.ContInYardJson[0] : {};
      this.ContByShippingLine = PortStatistics?.ContByShippingLineJson ?? [];
    })
    .catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function getDate(Date) {
  if (Date) {
    return DateFormater.formatDateTimeWithSlash(Date);
  } else {
    return 'N/A';
  }
}

//computed
function SeriesContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.Quantity);
}

function LabelsContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.Size);
}

function ColorsContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.SizeColor);
}

function SeriesShippingLine() {
  let data = [
    {
      name: '20´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 20)?.Quantity ?? 0;
      })
    },
    {
      name: '40´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 40)?.Quantity ?? 0;
      })
    },
    {
      name: '45´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 45)?.Quantity ?? 0;
      })
    },
  ];
  return data;
}

function LabelsShippingLine() {
  return this.ContByShippingLine.map(item => item.ShippingLineCode);
}

function ColorsShippingLine() {
  let Colors = [];
  this.ContByShippingLine[0]?.SizeJson?.forEach(item => {
    if(item.Size == 20){
      Colors[0] = item?.SizeColor ?? '';
    }else if(item.Size == 40){
      Colors[1] = item?.SizeColor ?? '';
    }else {
      Colors[2] = item?.SizeColor ?? '';
    }
  })
  return Colors;
}

export default {
  name:'port-statistict',
  data,
  components:{
    SimpleDonutOrPie,
    SimpleBar,
  },
  methods:{
    PortStatistics,
    getDate,
  },
  computed:{
    SeriesContainers,
    LabelsContainers,
    ColorsContainers,
    SeriesShippingLine,
    LabelsShippingLine,
    ColorsShippingLine,
    ...mapState({
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    })
  },
  mounted: PortStatistics,
}
</script>
<style lang="scss">
/* apexcharts -- quitar la opcion de descargar del componente */
.apexcharts-toolbar{
  display: none !important;
}
</style>
<style scoped>
.font-weight-500{
  font-weight: 500;
}

.font-weight-400{
  font-weight: 400;
}
.text-underline{
  text-decoration: underline;
}
.block-Teus{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e5e5e5;
  border-radius: 5px;
}
.title-row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-20{
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-yard{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
.block-title{
  background: rgb(189, 215, 238);
  padding: 0.5rem;
}
.block-20{
  text-align: center;
  border-right: 1px solid rgb(68, 114, 196);
}
.block-statistics{
  background: #1A3760;
  padding: 0.15rem;
  border-radius: 5px;
}
.block-white{
  background: rgb(255, 255, 255, 0.2);
  border-radius: 5px;
}
.table-port div:last-child {
  border-right: none;
  font-weight: bolder;
}
</style>