<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedDocumentationContainer"
                :fields="Documentationfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
                <template #Status="{item}">
                    <td class="text-center">
                    <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                    </td>
                </template>
            
                <template #options="{ item }">
                    <td class="text-center">
                        <CButton
                            square
                            size="sm"
                            color="excel"
                            class="d-flex align-items-center"
                            @click="downloadArchive(item)"
                            v-c-tooltip="{
                                content: $t('label.download'),
                                placement: 'top-start'
                            }"
                        >
                            <CIcon name="cil-cloud-download" />
                        </CButton>
                            
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';
    import DataStorage from '@/_helpers/localStorage';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataDocumentation: [],
        };
    }

    function downloadArchive(item) {
        let route = item.DocumentRoute.toLowerCase();
  
        if(route.includes("public/"))
            return window.open( (`${this.$store.getters['connection/getBase']}${route.split("public/").pop()}`).toLowerCase(), '_blank');
        else
            return window.open( (`${this.$store.getters['connection/getBase']}${route}`).toLowerCase(), '_blank');
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.Documentationfields.map(item=>{   
            if (item.key!='options') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })
        
        for (let i = 0; i < this.dataDocumentation.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataDocumentation[i].IdX,
                Activity: (_lang=='en' ? this.dataDocumentation[i].ActivityEn : this.dataDocumentation[i].ActivityEs) ?? 'N/A',
                TransaLogin: this.dataDocumentation[i].TransaLogin ?? 'N/A',
                TransaRegDate: this.dataDocumentation[i].TransaRegDate ? DateFormater.formatDateTimeWithSlash(this.dataDocumentation[i].TransaRegDate) : 'N/A',
                Status: this.dataDocumentation[i].Status ?? 'N/A',
            });   
        }

        if(this.nulo!="null" && this.dataDocumentation.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.documentation'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    function computedDocumentationContainer() {
        let _lang = this.$i18n.locale;
        return  this.dataDocumentation.map((item) => Object.assign({}, item, {
            RowNumber: item.IdX ?? 'N/A',
            Activity: _lang=='en' ? item.ActivityEn : item.ActivityEs ?? 'N/A',
            TransaLogin: item.TransaLogin?? 'N/A',
            TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
            Status: item.Status ?? 'N/A',
        }));
    }


    function Documentationfields(){
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'RowNumber', label: '#', _style: 'width:20%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.documentation'), key: 'Activity', _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaRegDate', label: this.$t('label.date'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            
        ];
    }
    

    export default{
        name: 'documentation-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
        },
        methods: {
            onBtnExport,
            downloadArchive
        },
        computed: {
            Documentationfields,
            computedDocumentationContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.DocumentationJson){
                    this.dataDocumentation = this.$store.state.containerSearch.dataContainer?.DocumentationJson;
                }
                else
                    this.dataDocumentation = [];
            },
        }
    }
</script>
<style lang="scss">
    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>