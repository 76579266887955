<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      class="modal-content-driver"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CRow class="mt-2">
        <CCol sm="12" class="mt-3">
          <CSelect
            :label="$t('label.transport')"
            :horizontal="{
              label: 'col-sm-12 col-lg-3',
              input: 'col-sm-12 col-lg-9',
            }"
            add-label-classes="text-right"
            :options="companyOptions"
            :value.sync="ClientTpId"
            :is-valid="hasError($v.ClientTpId)"
            v-model.trim="$v.ClientTpId.$model"
            :invalid-feedback="errorMessage($v.ClientTpId)"
          ></CSelect>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12" class="mt-3">
          <CInput
            addLabelClasses="required text-right"
            :placeholder="$t('label.IdNumber')"
            :horizontal="{
              label: 'col-sm-12 col-lg-3',
              input: 'col-sm-12 col-lg-9',
            }"
            :label="$t('label.IdNumber')"
            @keypress="ejecutarEnter"
            maxlength="10"
            v-model.trim="$v.DriverCi.$model"
            :invalid-feedback="errorMessage($v.DriverCi)"
            :is-valid="hasError($v.DriverCi)"
          >
            <template #prepend>
              <CSelect
                :value.sync="numberIdLetter"
                :options="ciTypeOptions"
                class="mr-2 mb-0"
                :is-valid="hasError($v.DriverCi)"
              />
            </template>
            <template #append>
              <CButton
                  color="watch" size="sm"
                  :disabled="DriverCi == '' && numberIdLetter == ''"
                  v-c-tooltip="{content: $t('label.search'),placement: 'top'}"
                  @click="buscarId"
                >
                <CIcon name="cil-search" />
              </CButton>
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow v-if="collapse">
        <CCol sm="12" class="medio mb-3">
          <CCollapse :show="collapse">
            <CCard>
              <CCardHeader>
                <b class="w-75">{{$t('label.select')}}</b>
                <CButton color="dark" 
                  class="float-right" 
                  size="sm" 
                  @click="collapse=false" >
                  X
                </CButton>
              </CCardHeader>
              <CCardBody>
                <dataTableExtended
    class="align-center-row-datatable"
                  :items="items2"
                  :fields="fields2"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                  
                >
                  <template #Seleccione="{item}">
                    <td class="text-center">
                      <CButton color="add" size="sm" v-c-tooltip="{content: $t('label.select')}" @click="seleccionarDatos(item)" >
                        <CIcon name="checkAlt" />
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCardBody>
            </CCard>
          </CCollapse>            
        </CCol> 
      </CRow>
      <CRow>
        <CCol sm="12" class="mt-3">
          <CInput
            :label="$t('label.name')"
            :horizontal="{
              label: 'col-sm-12 col-lg-3',
              input: 'col-sm-12 col-lg-9',
            }"
            add-label-classes="required text-right"
            v-uppercase
            :invalid-feedback="errorMessage($v.DriverName)"
            :is-valid="hasError($v.DriverName)"
            v-model.trim="$v.DriverName.$model"
            :value.sync="newDriverName"
            :placeholder="$t('label.driverName')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" class="mt-3">
          <CInput
            :label="$t('label.driverLicense')"
            :horizontal="{
              label: 'col-sm-12 col-lg-3',
              input: 'col-sm-12 col-lg-9',
            }"
            add-label-classes="text-right"
            v-uppercase
            @keypress="onlyNumbersStrict($event)"
            :is-valid="hasError($v.DriverLicense)"
            v-model.trim="$v.DriverLicense.$model"
            :invalid-feedback="errorMessage($v.DriverLicense)"
            :placeholder="$t('label.driverLicense')"
          />
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol sm="12" >
          <div role="group" class="form-group form-row">
            <label
              class="
                text-right
                col-form-label col-sm-3 col-form-label-md
              "
            >
              {{ $t('label.licenseExpirationDate') }}
            </label>
            <div class="col-sm-9 input-group-md d-flex align-items-center">
              <vue-datepicker
                valueType="format"
                :open="openDate"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                class="vue-datepicker-drive:focus" 
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                :clearable="false"
                :append-to-body="false"
                :lang="this.$i18n.locale"  
                format="DD/MM/YYYY"
                v-model.trim="$v.LicenseExpirationDate.$model"
                :disabled-date="(date) => rangeDays(date,LicenseExpirationDate)"                                
              ><!-- @keypress="formatCharDate($event)" -->
                <template #input>
                  <CInput
                    add-label-classes="text-right"
                    v-uppercase
                    :is-valid="hasError($v.LicenseExpirationDate)"
                    :invalid-feedback="errorMessage($v.LicenseExpirationDate)"
                    v-model.trim="$v.LicenseExpirationDate.$model"                
                    @keypress="formatCharDate($event)"
                    class="mb-0" 
                    placeholder="DD/MM/YYYY"                 
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="editModal">
        <CCol sm="12" class="mt-3">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{
              label: 'col-sm-12 col-lg-3',
              input: 'col-sm-12 col-lg-9',
            }"
            add-label-classes="text-right"
            v-uppercase
            :options="optionsStatus"
            :invalid-feedback="errorMessage($v.Status)"
            :is-valid="statusSelectColor()"
            v-model.trim="$v.Status.$model"
            :value.sync="Status"        
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit,id+' '+newDriverCi+' - '+newDriverName):submit()" 
                  
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
import { DateFormater } from '@/_helpers/funciones';
import { choferes } from '@/_validations/planificacion-estiba/logistica-empresa';
import { mapState } from 'vuex';
import moment from 'moment';
function data() {
  return {
    originStatus: '',
    Status: 1, 
    numberIdLetter: 'V',
    newDriverName: '',
    newDriverCi: '',
    modalActive: false,
    loadingOverlay: false,
    loadingTable: false,
    ClientTpId: null,
    companyOptions: [],
    items2:[],
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    DriverName: '',
    DriverCi: '',
    DriverLicense: '',
    LicenseExpirationDate: '',
    openDate: false,
    DriverId: '',  
    isSubmit: false,  
    collapse:false,
    StowagePlanningDriverId:'',
  };
}

async function listarCompany () {
  this.loadingOverlay = true;
  this.companyOptions = [];

  let listado = Array;
  await this.$http.get("StowagePlanningCarrier-list", { StowagePlanningId:this.StowagePlanningId, Filter: 'ACTIVO' })
  .then(response => {
      listado = [...response.data.data];
      this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
          value: listado.StowagePlanningResourceId,
          label: listado.ClientName,
      }));

      this.companyOptions.unshift({
          value: "",
          label: this.$t('label.select')
      });
  }).catch( err => {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
      });
  })
  .finally(() => {
      this.loadingOverlay = false;
  });
}

function ejecutarEnter($event) {
  this.evaluador = true;
  let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
  
  if (keyCode == 13) {
      this.buscarId();
  } else {
    this.collapse = false;
  }
}

function buttonEnable() {
  if (
    this.DriverName === '' ||
    this.DriverCi === 0 ||
    this.DriverCi.length < 6 ||
    this.DriverCi.length > 8
  ) {
    return true;
  } else {
    return false;
  }
}

function id() {
  return this.$t('label.IdNumber');
}

function selectedStatus(event) {
  this.Status = event.target.value;
}

//### Function para cerrar el modal ###

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

async function submit(){
  //this.Driver= this.$t('label.IdNumber')+' '+this.DriverCi+' - '+this.DriverName;
  try {
    //this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      //this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let licenseExpirationDate = "";
    if(this.LicenseExpirationDate !== "" && this.LicenseExpirationDate !== null){
      let isValid = moment(this.LicenseExpirationDate, "DD/MM/YYYY", true).isValid();
      if(isValid){
        licenseExpirationDate = moment(this.LicenseExpirationDate,"DD/MM/YYYY").format('YYYY-MM-DD');
        let ActivityDate = moment(this.LicenseExpirationDate,"DD/MM/YYYY").toDate();
        let dateActual = new Date();
        let maxDate = moment(moment(dateActual).add(10,'y')).toDate();
        if(ActivityDate > maxDate){
          throw (this.$t('label.dateInvalidHigherIn')+moment(maxDate).format('DD/MM/YYYY')+this.$t('label.In')+this.$t('label.licenseExpirationDate'));
        }
        let minDate = moment(moment(dateActual).subtract(10,'y')).toDate();
        if(ActivityDate < minDate){
          throw (this.$t('label.dateInvalidLessIn')+moment(minDate).format('DD/MM/YYYY')+this.$t('label.In')+this.$t('label.licenseExpirationDate'));
        }
      }else{
        throw this.$t('label.dateInvalidIn')+this.$t('label.driverLicense');
      }
    }

    const DriverJson = {
      StowagePlanningDriverId:this.StowagePlanningDriverId,
      StowagePlanningResourceId: this.ClientTpId,
      StowagePlanningId: this.StowagePlanningId,
      DriverCi: (this.numberIdLetter+this.DriverCi),
      DriverName: this.DriverName,
      DriverLicense: this.DriverLicense,
      LicenseExpirationDate: licenseExpirationDate,
      Status: 1
    };
    
    if(this.editModal){
      DriverJson.Status= this.Status;
      DriverJson.DriverId= this.DriverId;
    };

    let method = this.editModal ? 'PUT':'POST';
    let ruta = this.editModal ? 'StowagePlanningDriver-update':'StowagePlanningDriver-insert';

    await this.$http
      .ejecutar (method, ruta, DriverJson, { root: 'StowagePlanningDriverJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          //this.loadingOverlay = false;
          this.isSubmit = false;
          this.toggle(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        //this.loadingOverlay = false;
        this.notifyError({ text: e });
      }).then(() => {
      this.isSubmit = false;
    });
      
  } catch (e) {
    //this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
} 

function ciTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
    {
      value: "J",
      label: "J",
    },
  ];
}

async function buscarId(){
  if (!this.collapse) {
    if(this.numberIdLetter == '' || this.DriverCi == '') return;
    let listado = Array;
    this.items2 = [];
    this.collapse = false;
    this.loadingOverlay = true;
    let CiDriver = this.numberIdLetter+this.DriverCi;
    await this.$http.get("StowagePlanning-by-CiDriver", { StowagePlanningId:this.StowagePlanningId,StowagePlanningResourceId:this.ClientTpId,CiDriver:CiDriver })
    .then(response => {
      listado = [...response.data.data];
      if (listado.length == 1) {
         this.DriverId = listado[0].DriverId;
        let cedula = listado[0].DriverCi.trim().match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");
        let arrId = cedula.split(' ');
        this.numberIdLetter = arrId.length == 2 ? arrId[0] : 'V';
        this.DriverCi = arrId.length == 2 ? arrId[1] : arrId[0];
        this.DriverName = listado[0].DriverName;
        this.DriverLicense = listado[0].DriverLicense;
        this.Status = listado[0].FgActStoPlaDri ? 1 : 0;
        //this.ClientTpId = listado[0].StowagePlanningResourceId;
      } else if (listado.length > 1) {
        this.items2 = listado.map(listado => Object.assign({}, this.items2, {
          Nro: listado.Nro,
          DriverId: listado.DriverId,
          DriverCi: listado.DriverCi.trim(),
          DriverName: listado.DriverName,
          DriverLicense: listado.DriverLicense,
          Status: listado.FgActStoPlaDri,
          StowagePlanningResourceId: listado.StowagePlanningResourceId,
        }));
        this.collapse = true;
      } else {
        this.$notify({
          group: 'container',
          title: '¡Mensaje!',
          text: this.$t('label.noResultsFound'),
          type: "error"
        });  
      }
      this.loadingOverlay = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
        this.loadingOverlay = false;
    });
  }
}
function seleccionarDatos(item) {
  let cedula = item.DriverCi.trim().match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");
  let arrId = cedula.split(' ');
  this.numberIdLetter = arrId.length == 2 ? arrId[0] : 'V';
  this.DriverId = item.DriverId;
  this.DriverCi = arrId.length == 2 ? arrId[1] : arrId[0];
  this.DriverName = item.DriverName;
  this.DriverLicense = item.DriverLicense;
  this.Status = item.FgActStoPlaDri ? 1 : 0;
  this.ClientTpId = item.StowagePlanningResourceId;
  this.collapse = false;
  //this.$nextTick(() => { this.$v.$touch() });
}
function fields2(){
  return [
    { key: 'Nro', label: '#', _style: 'width:1%;', filter: false, _classes: 'text-center',sorter: true },
    { key: 'DriverCi', label:this.$t('label.IdNumber'), filter: true,sorter: true },
    { key: 'DriverName', label: this.$t('label.name'), filter: true,sorter: true },
    { key: 'DriverLicense', label:this.$t('label.driverLicense'), filter: true,sorter: true },
    { key: 'Seleccione', label: '', _style: 'width:5%;', filter: false,sorter: false },
  ];
}

function rangeDays(date){
  let todayMax = moment(moment(new Date()).add(10,'y')).toDate();
  let todayMin = moment(moment(new Date()).subtract(10,'y')).toDate();

  return !(date >= todayMin && date <= todayMax);
}

function resetForm(event) {
  this.DriverId = '';
  this.DriverCi = '';
  this.DriverName = '';
  this.LicenseExpirationDate = '';
  this.Status = 1;
  this.DriverLicense = '';
  this.openDate = false;
  this.collapse = false;
  this.$v.$reset();
  this.$emit('updated-modal', event);
}
export default {
  name: 'modalChofer',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    diverSelected: Object,
    ClientTpIdSelected: String,
  },
  mixins: [GeneralMixin, ChoferVehiculo, ModalMixin, ],
  directives: UpperCase,
  mounted(){
    this.toggle(false);
  },
  methods: {
    functionDate,
    selectedStatus,
    resetForm,
    submit,
    buscarId,
    listarCompany,
    ejecutarEnter,
    seleccionarDatos,
    rangeDays
  },
  computed: {
    buttonEnable,
    id,
    fields2,
    ciTypeOptions,
    ...mapState({
      StowagePlanningId: state => state.planificacionestiba.planificacionId,
      TpVesselId: state => state.planificacionestiba.TpVesselId,
      user: state => state.auth.user,
    })
  },
  validations: choferes,
   watch: {
    modal: function(val) {
      this.modalActive = val;
      if(val){
        this.listarCompany();
      }
      if(this.editModal)
        this.$v.$touch();
    },
    ClientTpIdSelected: function(val){
      if(!this.editModal){
        this.ClientTpId = val;
      }
    },
    diverSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.DriverId = val.DriverId;
        let cedula = val.DriverCi.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");
        let arrId = cedula.split(' ');
        this.numberIdLetter = arrId.length == 2 ? arrId[0] : 'V';
        this.DriverCi = arrId.length == 2 ? arrId[1] : arrId[0];
        this.DriverName = val.DriverName;
        this.DriverLicense = val.DriverLicense;
        this.LicenseExpirationDate = val.LicenseExpirationDate ? val.LicenseExpirationDate : "";
        this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.newDriverName= val.DriverName;
        this.newDriverCi= val.DriverCi;
        this.ClientTpId = val.StowagePlanningResourceId;
        this.StowagePlanningDriverId = val.StowagePlanningDriverId;
      }
    },
  },
};
</script>
<style lang="scss">
.modal-content-driver {
  .modal-content {
    width: 90% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}

.mx-datepicker{
    width: 100%;
    
  }
.vue-datepicker-drive {
  
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  } 
}
</style>
