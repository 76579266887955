
<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      class="modal-content-tp-vehiculo"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow  class="mt-2">
        <CCol sm="12">
          <CRow>
            <CCol sm="11">
              <CInput
                :label="$t('label.name')+' (EN)'"
                :horizontal="{label: 'col-sm-3', input:'col-sm-9'}"
                addLabelClasses="required text-right"
                v-uppercase
                @blur="$v.ResponsibleNameEn.$touch()"
                :placeholder="$t('label.name')+' (EN)'"
                v-model="$v.ResponsibleNameEn.$model"
                :is-valid="hasError($v.ResponsibleNameEn)"
                :invalid-feedback="errorMessage($v.ResponsibleNameEn)"
                required 
                maxlength="250"
              >
              </CInput>
            </CCol>
            <CCol sm="1">
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="11">
              <CInput
                  :label="$t('label.name')+' (ES)'"
                  :horizontal="{label: 'col-sm-3', input:'col-sm-9'}"
                  addLabelClasses="required text-right"
                  v-uppercase
                  @blur="$v.ResponsibleNameEs.$touch()"
                  :placeholder="$t('label.name')+' (ES)'"
                  v-model="$v.ResponsibleNameEs.$model"
                  :is-valid="hasError($v.ResponsibleNameEs)"
                  :invalid-feedback="errorMessage($v.ResponsibleNameEs)"
                  required 
                  maxlength="250"
                  >
              </CInput>
            </CCol>
            <CCol sm="1">
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow v-if="editModal">
        <CCol sm="11">
          <CSelect
            :label="this.$t('label.status')"
            addLabelClasses="required text-right"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
            :is-valid="statusSelectColor"
            
            v-model="Status"
            :value.sync="Status"
            :options="selectOptions"
          />
        </CCol>
        <CCol sm="1">
        </CCol>
      </CRow>

      <template #footer>

        <CButton
          v-if="$i18n.locale=='es'"
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit, ResponsibleNameEs):submit()"

        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="$i18n.locale=='en'"
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit, ResponsibleNameEn):submit()"

        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>

      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';
import DelayResponsibleValidations from '@/_validations/tiempoperdido/delayResponsibleValidations';
function data() {
  return {
    modalActive: false,
    loadingOverlay: false,
    loadingTable: false,
    Status: 1,
    originStatus: '',
    isSubmit: false,  

    ResponsibleNameEn: '',
    ResponsibleNameEs: '',
    ResponsibleId: '',
  };
}

//methods
async function submit(){
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false; 
     this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    
    const DelayResponsibleJson = {
      ResponsibleNameEn: this.ResponsibleNameEn,
      ResponsibleNameEs: this.ResponsibleNameEs,
};
    
    if(this.editModal){
      DelayResponsibleJson.Status= this.Status;
      DelayResponsibleJson.ResponsibleId= this.ResponsibleId;
    };

    let method = this.editModal? 'PUT':'POST';
    let ruta = this.editModal? 'DelayResponsible-update':'DelayResponsible-insert';

    await this.$http
      .ejecutar (method, ruta, DelayResponsibleJson, { root: 'DelayResponsibleJson' })

      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.toggle(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.notifyError({ text: e });
      }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.notifyError({ text: e });

  }
}

function refreshComponent() {
    this.loadingOverlay = false;
    this.loadingTable = false;
    this.Status = 1;
    this.originStatus = 1;
    this.isSubmit = false; 

    this.ResponsibleNameEn = "";
    this.ResponsibleNameEs = "";
    this.ResponsibleId = "";
    this.$v.$reset(); 
}

//computed
  function selectOptions(){
    return [
      { 
          value: 1, 
          label: this.$t('label.ACTIVO')
      },
      { 
          value: 0, 
          label: this.$t('label.INACTIVO')
      }
    ];
  }

export default {
  name: 'add-responsible',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    responsibleItem: Object,
  },
  mixins: [GeneralMixin, ModalMixin],
  directives: UpperCase,
  validations: DelayResponsibleValidations,
  watch: {
    modal: function(val) {
      this.modalActive = val;
      this.refreshComponent();
      if(this.editModal)
        this.$v.$touch();
    },
    responsibleItem: function(val) {
      if (Object.keys(val).length !== 0) {
        this.ResponsibleNameEn = val.ResponsibleNameEn;
        this.ResponsibleNameEs = val.ResponsibleNameEs;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.ResponsibleId = val.ResponsibleId;
        this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
      }
    },
  },
  methods: {
    submit,
    refreshComponent,
  },

  computed: {
    selectOptions,
  },
};
</script>
<style lang="scss">
.modal-content-tp-vehiculo {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>
