import { required, minValue, maxValue, minLength, maxLength } from 'vuelidate/lib/validators';
import { DateFormater } from "@/_helpers/funciones";
import { onlyAlphanumeric5,SoloContenedores,onlyAlphanumeric} from '@/_validations/validacionEspeciales'

async function containerCodeValidation(text) {
    try {
        let chars = text.split('');
        /*
        if( chars.length != (11)){
            throw ('lengthCodeInvalid')
        }
        */
  
        const special = text.substr(0,3) == "HLCU";
  
        let charValues = await chars.map((element, index)=>{
           
            let value;
           
            
            if(index< 4){
                value = charValue(element, special);
            } else {
                value = parseInt(element);
            } 
  
            if( index == (chars.length-1))
                return value;
            
            value = parseInt(value * Math.pow(2, index));
  
            return value;
        });
  
        let plusValues = 0;
  
        await charValues.forEach((element, index) => {
            if (index<(charValues.length-1))
                plusValues = parseInt(plusValues+element);
        });
        
        let resultDevided = Math.floor(plusValues/11) ;
        let resultTimes = parseInt(resultDevided*11)
        let lastChar = parseInt(plusValues - resultTimes);
  
        
  
        if(lastChar == 10){
            lastChar = 0;
        }
  
        if (charValues[charValues.length-1] != lastChar){
            throw (`checkDigitInvalid`);
        } 
            
        return true;
    } catch (e){
        return false;
    }
  }
  export default (laterDate, previousDate, VerifySeal,VerifyDirect, ValidateHoldDeck, ValidateHold, VerifyMovStowageId,MovStowageRestowId) => {

    let validYard = !VerifyDirect  && !VerifyMovStowageId ? { required } : {};
    let ValidateHoldHatch = ValidateHoldDeck ? { required } : {};
    let ValidateHoldId = ValidateHold || ValidateHoldDeck ? { required } : {};
    let MovStowageRestow = !VerifyMovStowageId ? { required } : {};
    let MovStowageRestowIdValid = MovStowageRestowId ? { required } : {};




    return {
        container: {
            TransactionDate: { required, TimeValidate(value){
                   
                return DateFormater.formatDateTimeWithoutSlash(value) > previousDate &&
                    DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
        
                }
            },
            ShippingLineName: {},
            Weigth: {},
            DischargePort: {},
            MovStowageName: {},
            Consignee: {},
            IsoCode: {},
            TpCargoSize: {},
            TbTpCargoStatus: {},
            LoadPort: {},
            BlNro: {},
            CargoStatus: {},
            VisitCraneId: { required },
            VesselHoldId: ValidateHoldId,
            VesselHoldHatchCId: ValidateHoldHatch,
            MovStowageId: { required },
            TransacStowageId: MovStowageRestowIdValid,
            MovStowageReasonId: MovStowageRestowIdValid,
            VesselUbicationId: { required },
            Seal1: { 
                required(){ return !VerifySeal },
                maxLength: maxLength(20)
              },
              Seal2: { 
                required(){ return !VerifySeal },
                maxLength: maxLength(20)
              },
              Seal3: { 
                required(){ return !VerifySeal },
                maxLength: maxLength(20)
              },
              Seal4: { 
                required(){ return !VerifySeal },
                maxLength: maxLength(20)
              },
            FgDirect: MovStowageRestow,
            YardId: validYard,
            Observation: { onlyAlphanumeric5 }
        },

        ContainerCode: { 
            required,
            onlyAlphanumeric,
            SoloContenedores,
            checkDigit: containerCodeValidation,
            minLength: minLength(1),
            maxLength: maxLength(11)  
        },
    }
  }