<template>
  <CModalExtended
    :title="`${$t('label.SearchResult')}: ${$store.state.yardManagement.WarehouseBlSearch ?? ''}`"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    @update:show="$emit('close')"
  >
    <CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedBlList"
          :fields="Blfields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
        
          <template #Ata="{ item }">
            <td class="text-center">
              {{ formatDateTimeWithSlash(item.Ata) }}
            </td>
          </template>
          <template #QuantityReceived="{ item }">
            <td class="text-center">
              {{ formatNumber(item.QuantityReceived, 2) }}
            </td>
          </template>
          <template #Weigth="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Weigth, 2) }}
            </td>
          </template>
          <template #Volumen="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Volumen, 2) }}
            </td>
          </template>
          <template #FirstEventDate="{ item }">
            <td class="text-center">
              {{ formatDateTimeWithSlash(item.FirstEventDate) }}
            </td>
          </template>
          <template #LastEventDate="{ item }">
            <td class="text-center">
              {{ formatDateTimeWithSlash(item.LastEventDate) }}
            </td>
          </template>
          <template #YardCargoBlStatus="{ item }">
            <td class="text-center">
              <b>{{ item.YardCargoBlStatus }}</b>
            </td>
          </template>
          <template #BlStatus="{ item }">
            <td class="text-center">
              <b>{{ item.BlStatus }}</b>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="$emit('close')"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";

//Data
function data() {
  return {
    modalActive: false,
  }
}

//Methods
function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function formatNumber(number, decimal) {
  return  NumberFormater.formatNumber(number, decimal);
}

//Computed
function computedBlList() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.BlJson?.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardAreaName: item?.YardAreaName ?? '',
      TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
      PortActivityName: item[`PortActivityName${_lang}`] ?? 'N/A',
      VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
      FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
      LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
      YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`] ?? 'N/A',
      BlStatus: item[`BlStatus${_lang}`] ?? 'N/A',
      TpBlName: item.TpBlName ?? '',
      DocumentCode: item.DocumentCode ?? '',
      ClientName: item.ClientName ?? '',
      DaysInYard: item[`DaysInYard${_lang}`] ?? '',
      MasterBlNro: item.MasterBlNro ?? '',
      _classes: 'color-gradient',
      _style: `background:${item.YardCargoBlStatusColor}`
    };
  })
}

function Blfields() {
  return [
    { key: 'Nro', label: '#', _style: 'min-width:45px', _classes: 'text-center', filter: false },
    { key: 'YardAreaName', label: this.$t('label.module'),  _style:'min-width:180px;', _classes:"text-center" },
    { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:"text-center" },
    { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px;', _classes:"text-center" },
    { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:140px;' },
    { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:200px', _classes:'text-uppercase text-center' },
    { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'PortActivityName',label: this.$t('label.activity'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;' },
    { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;' },
    { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px;' },
    { key: 'Weigth', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.received')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:190px;' },
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³) ${this.$t('label.received')}`, _style:'min-width: 190px;', _classes: 'text-center' },
    { key: 'DaysInYard', label: `${this.$t('label.DaysInOperation')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:160px;' },
    { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },  
    { key: 'BlStatus', label: this.$t('label.statusCertificate'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },
  ];
}

export default {
  name: 'modal-blSearch',
  props: {
    modal: Boolean,
    BlJson: {
      type: Array,
      default: () => [],
    }
  },
  data,
  mixins: [GeneralMixin],
  directives: UpperCase,
  methods: {
    formatDateTimeWithSlash,
    formatNumber,
  },
  computed: {
    computedBlList,
    Blfields,
  },
  watch: {
    modal: function(NewVal) {
      this.modalActive = NewVal;
    },
  }
}
</script>
