<template>
  <div>
    <div v-show="showIndex==0">
      <CRow>
        <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-star mb-3t">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.positioningList')}`}} </h6>
        </CCol>
        <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
      </CRow>
      <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
        <CTab :title="$t('label.packaging')">
          <CRow class="mt-3 m-0">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <h6 class="text-warning mr-3 mt-2">
                <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentPosition')}}&nbsp;
              </h6> 
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center ml-auto"
                v-c-tooltip="{
                  content: $t('label.containerPositioning'),
                  placement: 'top-end'
                }"
                v-if="YardId && this.dataContainers?.length == 0 && verifyContainers"
                @click="showIndex=1"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol> 
          </CRow>
          <CRow class="m-0">
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                pagination
                @filtered-items-change="(List)=> this.FilterList = [...List]"
              >
                <template #alertGif="{ item }">
                  <td class="center-cell">
                    <div class="animation-item ml-2" style="display: inline-block;" v-if="item.ComputedFinishDate=='' && item.Status == 'ACTIVO'">
                      <h3 class="text-danger">
                          <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                      </h3>
                    </div>
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #button="{item}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editContainerPositioning'),
                        placement: 'top-start'
                      }"
                      @click="toggleAdd(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      v-if="item.Seal1 || item.Seal2 || item.Seal3 || item.Seal4"
                      style="background-color: #d6d6d7;"
                      square
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.Seals'),
                        placement: 'top-start'
                      }"
                      @click="viewDetail(item)"
                    >
                      <CIcon name="bottomSeals"/>
                    </CButton>
                  </td>
                </template>
                <template #details="{item}">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <dataTableExtended
                        class="align-center-row-datatable"
                        small
                        :items="formatedSeal(item.SealJson)"
                        :fields="level2Fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="tableText.itemsPerPage"
                      >
                      </dataTableExtended>
                    </CCardBody>
                  </CCollapse>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.Containers')">
          <CRow class="mt-3">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <h6 class="text-warning mr-3 mt-2">
                <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentPosition')}}&nbsp;
              </h6> 
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center ml-auto"
                v-c-tooltip="{
                  content: $t('label.containerPositioning'),
                  placement: 'top-end'
                }"
                @click="toggleContainerAdd({})"
                v-if="YardId && this.dataContainers?.length>0 && verifyContainers"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="ConatinerItems"
                :fields="Containerfields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                pagination
                @filtered-items-change="(List)=> this.FilterList = [...List]"
              >
                <template #alertGif="{ item }">
                  <td class="center-cell">
                    <div class="animation-item ml-2" style="display: inline-block;" v-if="item.ComputedFinishDate=='' && item.Status == 'ACTIVO'">
                      <h3 class="text-danger">
                        <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                      </h3>
                    </div>
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #button="{item}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editContainerPositioning'),
                        placement: 'top-start'
                      }"
                      @click="toggleContainerAdd(item, true)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      style="background-color: #d6d6d7;"
                      square
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.Seals'),
                        placement: 'top-start'
                      }"
                      @click="viewDetail(item)"
                    >
                      <CIcon name="bottomSeals"/>
                    </CButton>
                  </td>
                </template>
                <template #details="{item}">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <dataTableExtended
                        class="align-center-row-datatable"
                        small
                        :items="formatedSeal(item.SealJson)"
                        :fields="level2Fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="tableText.itemsPerPage"
                      >
                        <template #button="{item}">
                          <td class="text-center">
                            <CButton 
                              v-if="item.SealDocumentJson.length!=0&&item.Seal"
                              color="watch"
                              square
                              size="sm"
                              class="mr-1"
                              @click="ModalImagen(item)"
                            >
                              <CIcon name="eye"/>
                            </CButton>
                          </td>
                        </template>
                      </dataTableExtended>
                    </CCardBody>
                  </CCollapse>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
    </div>
    <div class="my-3" v-show="showIndex==3">
        <CRow class="mb-3">
            <CCol sm="12" lg="6" class="d-flex align-items-start justify-content-start">
                <CButton
                    color="edit"
                    class="mr-2"
                    v-c-tooltip="{
                        content: $t('label.return'),
                        placement: 'top',
                    }"
                    size="sm"
                    @click="()=> {showIndex=0}"
                >
                    <CIcon name="cil-chevron-left-alt" />
                    
                </CButton>
                <CRow class="align-items-center justify-content-start w-100 m-0">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ContainersInInventory')}`}} </h6>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedContainerInventoryItems"
                    :fields="fieldsContainerInventory"
                    :noItemsView="tableText.noItemsViewText"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                >
                    <template #number="{ index }">
                    <td class="text-center">
                        {{index+1}}
                    </td>
                    </template>
                    <template #Status="{item}">
                        <td class='text-center'>
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #options="{item, index}">
                        <td class="text-center">
                            <CButton
                                color="watch"
                                square
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.dispatchContainer'),
                                    placement: 'top-start'
                                }"
                                @click="toggleContainer(item)"
                            >
                                <CIcon name='cil-list' />
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
    <div v-show="showIndex==1">
      <PositioningRegister
        :Active="showIndex==1"
        :isEdit="isEdit"
        :PositionItem="PositionItem"
        @Close="(showIndex=0, PositionItem={}, isEdit=false)"
        @Update-list="UpdateData"
      />
    </div>
    <div v-show="showIndex==2">
      <ContainerPositioningRegister
        :Active="showIndex==2"
        :isEdit="isEdit"
        :isOfBlProfile="true"
        :PositionItem="PositionItem"
        @Close="(showIndex=0, PositionItem={}, isEdit=false)"
        @Update-list="UpdateData"
      />
    </div>
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="labelModalImages"
      :SealImages="SealImages"
      @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import YardManagement from '@/_mixins/yardManagement';
import CustomTab from '@/components/tabs/CustomTab';
import PositioningRegister from './positioning-register';
import ContainerPositioningRegister from '@/pages/yard-management/container/movements/container-positioning/positioning-register';
import ModalSealImages from '@/pages/yard-management/container/modal-seal-images';

//DATA-------------------------
function data() {
  return {
    verifyContainers: false,
    collapseDuration: 0,
    showIndex: 0,
    tabIndex: 0,
    isEdit: false,
    ReportJson: [],
    ModalSeal: false,
    labelModalImages: '',
    SealImages: [],
    PositionItem: {},
    PackagingJson: [],
    ContainerJson: [],
    dataContainers: [],
    FilterList: [],
  }
}

async function getYardBlCargo() {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('BlCargo-by-Id', {BlCargoId: this.BlCargoId})
        .then(response => {
        let res = response.data.data;
        if (res && res?.length>0) {
            this.$store.state.yardManagement.dataBl = res[0];
        }
        })
        .catch( err => {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
        })
    this.$store.state.yardManagement.loading = false;
}

async function getYardEventPosition() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardEventPositionContainerPackaging-by-BlCargoId', { BlCargoId: this.BlCargoId??'', YardId: this.YardId??'' })
    .then(async(response) => {
      let List = response.data.data?.[0];
      this.PackagingJson = List?.PackagingJson ?? [];
      this.ContainerJson = List?.ContainerJson ?? [];

      if(this.YardId){
          await this.getInventoryInYard();
      }

      if(List?.ReportJson)
        this.ReportJson = List.ReportJson

      this.$store.state.yardManagement.loading = false;
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getInventoryInYard() {
  await this.$http.get('YardCargoInventoryInYard-by-BlCargoId', { YardId: this.YardId, BlCargoId: this.BlCargoId})
    .then((response) => {
        this.dataContainers =  response.data.data ?? [];
        this.verifyContainers = true;
    })
    .catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
    })
}

async function getBlCargoListYardId() {
  await this.$http.get('BlCargoResum-list-by-YardId', { BlCargoJson: {YardId: this.YardId} })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardCargo(item, isEdit = false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: !isEdit ? this.YardId : item.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.isEdit = isEdit;
        this.PositionItem = item;
        this.$store.state.yardManagement.dataContainer = List[0];
        this.showIndex = 2;
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
        this.$store.state.yardManagement.loading = false;
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function handleTab(tab) {
  this.tabIndex = tab;
}

async function UpdateData() {
  this.$store.state.yardManagement.loading = true;
  await this.getYardBlCargo();
  await this.getBlCargoListYardId();
  await this.getYardEventPosition();
}

function viewDetail(item){
  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
  });
}

function toggleAdd(item) {
  this.PositionItem = item;
  this.isEdit = true;
  this.showIndex = 1;
}

function toggleContainerAdd(item, isEdit = false) {

  if(!isEdit)
    this.showIndex = 3;
  else
    this.getYardCargo(item, isEdit);

}

function toggleContainer(item) {
  this.getYardCargo(item, false);
}

function ModalImagen(item) {
  this.labelModalImages = item.Seal;
  this.SealImages = item.SealDocumentJson;
  this.ModalSeal = true;
}

//----- Excel section -------

async function onBtnExport(valor){
    let dataList = [];
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let moreOpcions = {
        backgroundColorInTitle: '#4e5a70',
        colorLetterInTitle: '#ffffff',
    }

    this.ReportJson.map((e)=>{
      if(this.FilterList.some((el) => el.YardCargoEventDetailId == e.YardCargoEventDetailId)){
        dataList.push({...e, Nro: dataList.length + 1})
      }
    })

    let filters = [
        this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
        this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
    ]

    dataList = dataList.map((e)=>{  
        let NewDetailPos = e?.YardNewDetailPosJson ?  e.YardNewDetailPosJson.filter(NewDetailPos => process.env.VUE_APP_YARD_SLOT_STATUS==NewDetailPos.YardSlotStatus?.toUpperCase()).map(NewDetailPos =>NewDetailPos.SlotCode.trim()).join(', ') : '';
        
        e.DetailJson?.map((d)=>{
                d.SerialJson?.map((s, i)=>{
                    d.SerialJson[i].Seal = `S1:${s.Seal1 ? s.Seal1 : 'N/A'}, S2:${e.Seal2 ? s.Seal2 : 'N/A'}, S3:${e.Seal3 ? s.Seal3 : 'N/A'}, S4:${e.Seal4 ? s.Seal4 : 'N/A'}`;
                })
            })

        return {...e,
            TransactionBegin: e.TransactionBegin ? DateFormater.formatDateTimeWithSlash(e.TransactionBegin) : '',
            TransactionFinish: e.TransactionFinish ? DateFormater.formatDateTimeWithSlash(e.TransactionFinish) : '',
            EventReasonName: e[`EventReasonName${_lang}`] ?? '',
            NewDetailPos: NewDetailPos,
            TransaRegDate: e.TransaRegDate ? DateFormater.formatDateTimeWithSlash(e.TransaRegDate) : '',
            Vehicle: e.LicensePlate ? `${e.LicensePlate} - ${e['TpVehicleName'+_lang]}` :  '',
            Driver: e.DriverCi ? `${e.DriverCi} - ${e.DriverName}` :  '',
            DocumentCode: e.DocumentYardJson?.[0]?.DocumentCode ?? '',
            Observation: e.Observation ?? '',
            Seal: `S1:${e.Seal1 ? e.Seal1 : 'N/A'}, S2:${e.Seal2 ? e.Seal2 : 'N/A'}, S3:${e.Seal3 ? e.Seal3 : 'N/A'}, S4:${e.Seal4 ? e.Seal4 : 'N/A'}`,
            Status: this.$t(`label.${e.Status}`),
        }
    });
    
    await this.excel([this.excelHeader()], [dataList], this.$t('label.positioningList'), this.BranchName, `${this.$t('label.positioningList')}`, [filters], [], false, this.PersonalfilterCell, this.GroupTitle, moreOpcions);
    this.$store.state.yardManagement.loading = false;
}

function PersonalfilterCell (worksheet, cellMerge, cellStyles, CellNumber) {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let cell = {};

    //Data of Rows
    let custom = [
      [
        `${this.$t('label.bl')}:`, `${this.dataBl.BlNro??''}`,
        `${this.$t('label.Master')}:`, `${this.dataBl?.MasterBlNro??''}`,
        `${this.$t('label.consignee')}:`, `${this.dataBl?.ConsigneeName??''}`,
        `${this.$t('label.status')}:`, `${this.dataBl[`YardCargoBlStatus${_lang}`]??''}`, '', 
        `${this.$t('label.plannedQuantity')}:`, this.dataBl.QuantityPlanning,
        `${this.$t('label.plannedWeight')}:`, this.dataBl.WeigthPlanning,
        `${this.$t('label.plannedVolumen')}:`, this.dataBl.VolumenPlanning,
        `${this.$t('label.DescriptionOfGoods')}:`, '', `${removeTags(this.dataBl.DescriptionOfGoods??'')}`,
      ],
      [
        `${this.$t('label.type')}:`, `${this.dataBl.TpBlName??''}`,
        `${this.$t('label.PackagingOrigin')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`PackagingName${_lang}`]??''}`,
        `${this.$t('label.CustomBroker')}:`,  `${this.dataBl.CustomBrokerName??''}`,
        `${this.$t('label.Acta')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.DocumentCode??''}`,'', 
        `${this.$t('label.ReceivedQuantity')}:`, this.dataBl.QuantityReceived,
        `${this.$t('label.receivedWeight')}:`, this.dataBl.WeigthReception,
        `${this.$t('label.receivedVolumen')}:`, this.dataBl.VolumenReception
      ],
      [
        `${this.$t('label.status')}:`, `${this.dataBl[`BlStatus${_lang}`]??''}`,
        `${this.$t('label.SerialOrigin')}:`, `${this.dataBl?.BlMasterJson?.[0]?.Serial??''}`,
        `${this.$t('label.Ship')}:`,  `${this.dataBl?.VesselName??''}`,
        `${this.$t('label.statusCertificate')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.[`YardDocumentStatus${_lang}`]??''}`, '', 
        `${this.$t('label.CertifiedQuantity')}:`, this.dataBl.QuantityCertified,
        `${this.$t('label.CertifiedWeight')}:`, this.dataBl.WeigthCertified,
        `${this.$t('label.CertifiedVolumen')}:`, this.dataBl.VolumenCertified
      ],
      [
        `${this.$t('label.portActivity')}:`, `${this.dataBl[`PortActivityName${_lang}`]??''}`,
        `${this.$t('label.type')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`TpCargoName${_lang}`]??''}`,
        `${this.$t('label.visit')}:`,  `${this.dataBl.Voyage} - ${this.dataBl.Arrival ? DateFormater.formatDateTimeWithSlash(this.dataBl.Arrival) : ''}`, 
        `${this.$t('label.firstEvent')}:`, `${this.dataBl[`FirstEventName${_lang}`]??''}`, '', 
        `${this.$t('label.dispachedQuantity')}:`, this.dataBl.QuantityDispatched,
        `${this.$t('label.dispachedWeight')}:`, this.dataBl.WeigthDispatch,
        `${this.$t('label.dispachedVolumen')}:`, this.dataBl.VolumenDispatch
      ],
      [
        `${this.$t('label.group')}:`, `${this.dataBl[`TpCargoName${_lang}`]??''}`,
        `${this.$t('label.receptionDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.ReceptionDate) : ''}`,
        `${'POL'}:`,  `${this.dataBl?.LoadPortName??''}`,
        `${this.$t('label.dateFierstEvent')}:`, `${this.dataBl?.FirstEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.FirstEventDate) : ''}`, ''
      ],
      [
        `${this.$t('label.commodity')}:`, `${this.dataBl[`CommodityName${_lang}`]??''}`,
        `${this.$t('label.dispatchDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.DispatchDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.DispatchDate) : ''}`,
        `${'POD'}:`,  `${this.dataBl?.DischargePortName??''}`,
        `${this.$t('label.LastEvent')}:`, `${this.dataBl[`LastEventName${_lang}`]??''}`, ''
      ],
      [
        `${this.$t('label.Procedence')}:`, `${this.dataBl?.Procedence??''}`,
        `${this.$t('label.status')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`YardCargoBlMasterDetailSerialStatusDs${_lang}`]??''}`,
        `${this.$t('label.dua')}:`,  `${this.dataBl?.Dua??''}`,
        `${this.$t('label.DateLastEvent')}:`, `${this.dataBl?.LastEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.LastEventDate) : ''}`, ''
      ],
      [
        '', '',
        `${this.$t('label.Day')}:`,   `${this.dataBl?.BlMasterJson?.[0]?.DaysInYard??''}`,
        `${this.$t('label.BolipuertoCertificateEntry')}:`,  `${this.dataBl?.ReceptionBpAct??''}`, ''
      ]
    ]

    //Row Injection
    for (let i = 0; i <= 7; i++) {
        worksheet.addRow(custom[i])
    }

    //Merge of cells
    //worksheet.mergeCells(cellMerge(1, 14, 2, 14));
    //worksheet.mergeCells(cellMerge(3, 7, 3, 14));
    //worksheet.mergeCells(cellMerge(6, 7, 6, 14));
    worksheet.mergeCells(cellMerge(9, 7, 9, 14));
    //worksheet.mergeCells(cellMerge(10, 14, 11, 14));
   // worksheet.mergeCells(cellMerge(12, 7, 12, 14));
   // worksheet.mergeCells(cellMerge(13, 11, 14, 14));
    //worksheet.mergeCells(cellMerge(15, 7, 15, 14));
    //worksheet.mergeCells(cellMerge(16, 11, 17, 14));
    //worksheet.mergeCells(cellMerge(18, 7, 18, 14));
    //worksheet.mergeCells(cellMerge(19, 11, 20, 14));
    //worksheet.mergeCells(cellMerge(21, 7, 21, 14));
    worksheet.mergeCells(cellMerge(16, 7, 17, 14));
    worksheet.mergeCells(cellMerge(18, 7, 20, 14));

    //Styles of Cells
    //COL A
    cellStyles(worksheet, 1, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 13, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL D
    cellStyles(worksheet, 3, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 13, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 3, 14, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL G
    cellStyles(worksheet, 5, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 13, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 5, 14, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL J
    cellStyles(worksheet, 7, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 13, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL M
    cellStyles(worksheet, 10, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(11, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 10, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(11, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 10, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(11, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 10, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(11, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

    //COL P
    cellStyles(worksheet, 12, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(13, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 12, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(13, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 12, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(13, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 12, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(13, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    
    //COL S
    cellStyles(worksheet, 14, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(15, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 14, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(15, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 14, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(15, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 14, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(15, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

    //COL V
    cellStyles(worksheet, 16, 7, true, 10, 26, "",  "000000", 11, false,);

    //COL w
    cellStyles(worksheet, 18, 7, true, 10, 26, "",  "000000", 11, false, false, false);

    //CONTROL ROWS
    let fieldLast = 9;
    worksheet.addRow([]);
    
    return fieldLast
}

function GroupTitle (worksheet, fieldLast, cellMerge, cellStyles) {
    let custom = ['', '', '', '', '', '', '', '', '',  this.$t('label.serials'),  '', '', '', '', '', '', '', '', '',         '', '', '', '', '', '']
    worksheet.addRow(custom)
    worksheet.mergeCells(cellMerge(10, fieldLast,11, fieldLast));
    cellStyles(worksheet, 10, fieldLast, true, 10, 26, "1f3864", "ffffff", 12, true);

    let field = 1
    return field;
}

function excelHeader() {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

    return [   
        { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
        { text: this.$t('label.startDate'), value: "TransactionBegin", aux: "", json: "" },
        { text: this.$t('label.endDate'), value: "TransactionFinish", aux: "", json: "" },
        { text: this.$t('label.ubication'), value: "YardName", aux: "", json: "" },
        { text: this.$t('label.module'), value: "YardAreaName", aux: "", json: "", json: "" },
        { text: this.$t('label.positionContainer'), value: "NewDetailPos", aux: "", json: "", json: "" },
        { text: this.$t('label.machine'), value: "MachineCode", aux: "", json: "" },    
        { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "" },
        { text: this.$t('label.packaging'), value: `PackagingName${_lang}`, aux: "", json: "DetailJson" },
        { text: this.$t('label.serial'), value: "Serial", aux: "", json: "DetailJson", json2: "SerialJson"},
        { text: this.$t('label.Seal'), value: "Seal", aux: "", json: "", json2: "" },
        { text: this.$t('label.largo'), value: "Length", aux: "", json: "" },
        { text: this.$t('label.width'), value: "Width", aux: "", json: "" },
        { text: this.$t('label.height'), value: "Height", aux: "", json: "" },
        { text: this.$t('label.observation'), value: "Observation", json: "", json2: "" },
        { text: this.$t('label.motive'), value: "EventReasonName", aux: "", json: ""},
        { text: this.$t('label.device'), value: "DeviceCod", aux: "", json: ""},
        { text: this.$t('label.user'), value: "TransaLogin", aux: "", json: ""},
        { text: this.$t('label.registerDate'), value: "TransaRegDate", aux: "", json: ""},
        { text: this.$t('label.status'), value: "Status", aux: "", json: ""},
    ]
}

//---------------------------

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.PackagingJson.map((item, index) => {

    return {
      ...item,
      Nro: index+1,
      YardAreaName: item.YardAreaName ? item.YardAreaName : '',
      PackagingName: _lang=='en' ? item.PackagingNameEn : item.PackagingNameEs,
      Serial: item.Serial? item.Serial : '',
      YardNewDetailPos: item?.YardNewDetailPosJson?.map(NewDetailPos => {return NewDetailPos?.YardAreaName?.trim()})?.join(', ') ?? '',
      YardPreviousDetailPos: item?.YardPreviousDetailPosJson?.map(PreviousDetail => {return PreviousDetail?.YardAreaName?.trim()})?.join(', ') ?? '',
      ComputedQuantity: NumberFormater.formatNumber(item?.Quantity, 2),
      EventReasonName: _lang=='en' ? item.EventReasonNameEn : item.EventReasonNameEs,
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
      DaysInYard: _lang=='en' ? item.DaysInYardEn || '' : item.DaysInYardEs || '',
      MachineCode: item.MachineCode ? item.MachineCode : '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  })
}

function formatedSeal(item) {
  let seals = item?.[0] ?? []
   return [{
        ...seals,
        Seal1: seals?.Seal1Json?.[0]?.Seal ? seals?.Seal1Json?.[0]?.Seal: '',
        Seal2: seals?.Seal2Json?.[0]?.Seal ? seals?.Seal2Json?.[0]?.Seal: '',
        Seal3: seals?.Seal3Json?.[0]?.Seal ? seals?.Seal3Json?.[0]?.Seal: '',
        Seal4: seals?.Seal4Json?.[0]?.Seal ? seals?.Seal4Json?.[0]?.Seal: '',
  }];
}

function fields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width:90px; width: 1%; text-align:center;', 
      sorter: false, 
      filter: false
    },
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#',_style: 'width: 5%', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'YardName',label: this.$t('label.ubication'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'YardPreviousDetailPos', label: this.$t('label.previousModule'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardNewDetailPos', label: this.$t('label.assignedModule'),  _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
    { key: 'PackagingName',label: this.$t('label.packaging'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'Serial',label: this.$t('label.serial'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedInitDate', label: this.$t('label.startDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'ComputedFinishDate', label: this.$t('label.endDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'DaysInYard', label: this.$t('label.daysInYard'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px;', _classes:"text-center" }, 
  ];
}

function level2Fields(){
  return [
    { key: 'Seal1',label: `${this.$t('label.Seal')} 1`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal2',label: `${this.$t('label.Seal')} 2`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal3',label: `${this.$t('label.Seal')} 3`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal4',label: `${this.$t('label.Seal')} 4`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
  ];
}

function Containerfields(){
  return [
  {
      key: 'button', 
      label: '', 
      _style: 'min-width:90px; width: 1%; text-align:center;', 
      sorter: false, 
      filter: false
    },
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#',_style: 'width: 5%', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'Serial', label: this.$t('label.container'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'TpCargoDetailCode', label: this.$t('label.type'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'YardAreaName', label: this.$t('label.Area'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardPreviousDetailPos', label: this.$t('label.previousPosition'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardNewDetailPos', label: this.$t('label.assignedPosition'),  _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedInitDate', label: this.$t('label.startDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'ComputedFinishDate', label: this.$t('label.endDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'DaysInYard', label: this.$t('label.daysInYard'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px;', _classes:"text-center" }
  ];
}

function ConatinerItems() {
  let _lang = this.$i18n.locale;
  return this.ContainerJson.map((item, index) => {
    let NewDetailPos = item?.YardNewDetailPosJson ? 
      item.YardNewDetailPosJson
        .filter(NewDetailPos => process.env.VUE_APP_YARD_SLOT_STATUS==NewDetailPos.YardSlotStatus?.toUpperCase())
        .map(NewDetailPos =>NewDetailPos.SlotCode.trim()).join(', ') : '';
    let PreviousDetailPos = item?.YardPreviousDetailPosJson ? 
      item.YardPreviousDetailPosJson
        .filter(PreviousDetailPos => process.env.VUE_APP_YARD_SLOT_STATUS==PreviousDetailPos.YardSlotStatus?.toUpperCase())
        .map(PreviousDetailPos=> PreviousDetailPos.SlotCode.trim()).join(', ') : '';
    return {
      ...item,
      Nro: index+1,
      YardAreaName: item.YardAreaName ? item.YardAreaName : '',
      YardNewDetailPos: NewDetailPos,
      YardPreviousDetailPos: PreviousDetailPos,
      EventReasonName: _lang=='en' ? item.EventReasonNameEn : item.EventReasonNameEs,
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
      DaysInYard: _lang=='en' ? item.DaysInYardEn || '' : item.DaysInYardEs || '',
      MachineCode: item.MachineCode ? item.MachineCode : '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  })
}

function formatedContainerInventoryItems() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

    return this.dataContainers.map((item, index) => {

        let BlNro = '';
        let TpBlName = '';
        let DocumentCode = '';

        item?.BlJson.map((e, i)=>{
            BlNro = `${BlNro}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e?.BlNro??''}`
            TpBlName = `${TpBlName}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e?.TpBlName??''}`
            DocumentCode = `${DocumentCode}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e?.DocumentCode??''}`
        })

        return {
            ...item,
            Nro: index+1,
            VesselVoyage: item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : '',
            DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
            BlNro: BlNro,
            TpBlName: TpBlName,
            DocumentCode: DocumentCode,
            ReceptionDate: DateFormater.formatDateTimeWithSlash(item.ReceptionDate),
            TransaRegDate: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
            AtaEta:  DateFormater.formatDateTimeWithSlash(item.Ata ?? item.Eta),
            _classes: 'color-gradient',
            _style: 'background:'+item.TpTransacEirColor,
        };
    })
}

function fieldsContainerInventory(){ 
      return [
          { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
          { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
          { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
          { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:160px; text-align:center;', _classes:" text-center" },
          { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;' },
          { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
          { key: 'VesselVoyage', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`, _classes: 'text-center', _style: 'min-width: 235px;'},
          { key: 'AtaEta', label: 'ATA / ETA', _classes: 'text-center', _style: 'min-width: 160px;'},
          { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
          { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
          { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _classes: 'text-center'},
          { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
          { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 135px;'},
          { key: 'ReceptionDate', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
          { key: 'DaysInYard', label: this.$t('label.daysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
          { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
          { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
          { key: 'TransaRegDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
      ];
  }

export default {
  name: 'bl-positioning-index',
  components: {
    CustomTabs,
    CustomTab,
    PositioningRegister,
    ContainerPositioningRegister,
    ModalSealImages,
   },
  data,
  mixins: [GeneralMixin, YardManagement],
  methods: {
    getYardEventPosition,
    getBlCargoListYardId,
    getYardCargo,
    handleTab,
    UpdateData,
    viewDetail,
    toggleAdd,
    toggleContainer,
    toggleContainerAdd,
    ModalImagen,
    getYardBlCargo,
    formatedSeal,
    getInventoryInYard,
    onBtnExport,
    PersonalfilterCell,
    GroupTitle,
    excelHeader
  },
  computed: {
    formatedContainerInventoryItems,
    fieldsContainerInventory,
    VisitOptions,
    fields,
    level2Fields,
    Containerfields,
    formatedItems,
    ConatinerItems,
    ...mapState({
      dataBl: state => state.yardManagement.dataBl,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      BlCargoId: state => state.yardManagement.dataBl.BlCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      GeneralList: state => state.yardManagement.GeneralList,
      dropBlMovement: state => state.yardManagement.dropBlMovement,
      dataContainer: state => state.containerSearch.dataContainer,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
  mounted(){
    if (this.FgPositioning) {
      this.verifyContainers = false;
      this.tabIndex = 0;
      this.PackagingJson = [];
      this.ContainerJson = [];
      this.getYardEventPosition();
    }
  },
  watch: {
    dropBlMovement: function (val) {
      if (val==2) {
        this.verifyContainers = false;
        this.tabIndex = 0;
        this.PackagingJson = [];
        this.ContainerJson = [];
        this.dataContainers = [];
        this.getYardEventPosition();
      }
    }
  }
};
</script>