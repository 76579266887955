
<template>
    <CRow class="mt-2">
        <CCol sm="12" >
            <CRow>
                <CCol sm="4" >
                    <CSelect
                        :label="$t('label.group')"
                        :horizontal="{label:'col-sm-11 col-lg-3 col-xl-2', input:'col-sm-11 col-lg-9 col-xl-9'}"
                        :options="optionListCargo"
                        :disabled="loading"
                        :value.sync="$store.state.contenedores.filtroCargo"
                        @input="changeCargo($event.target.value)"
                    />
                </CCol>
                <CCol sm="4" >
                    <CSelect
                        :label="$t('agridTable.size')"
                        :horizontal="{label:'col-sm-11 col-lg-3 col-xl-3', input:'col-sm-11 col-lg-9 col-xl-9'}"
                        :options="optionListSize"
                        :disabled="loading"
                        :value.sync="$store.state.contenedores.filtroSize"
                        @input="changeSize($event.target.value)"
                    />
                </CCol>
                <CCol sm="4"  class="d-flex align-items-center justify-content-end">
                    <CButton
                        color="add"
                        @click="activarModal()"
                        v-c-tooltip="{
                            content: $t('label.classification'),
                            placement: 'top-end'
                        }"
                    >
                        <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nueva')}}
                    </CButton>
                    <CButton
                        color="excel"
                        style="margin-left:5px;"
                        @click="generarXLS(true)"
                        v-c-tooltip="{
                            content: $t('label.exportToExcel'),
                            placement: 'top-end'
                        }"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                </CCol>
            </CRow>
            <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                pagination
                :loading="loading"
            >
                <template #loading>
                    <loading/>
                </template>
                <template #TpCargoDetailId="{ index }">
                    <td class="text-center">
                        {{index+1}}
                    </td>
                </template>
                <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.status)">
                            {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                </template>
                <template #acciones="{item, index}">
                    <td class="text-center">
                        <CButton
                            square
                            color="watch"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.codesPerDocuments'),
                                placement: 'top-end'
                            }"
                            @click="viewCode(item)"
                        >
                            <CIcon name="eye"/>
                        </CButton>
                        <CButton
                            color="edit"
                            square
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.edit')+' '+$t('label.classification'),
                                placement: 'top-end'
                            }"
                            @click="updateModal(item, index)"
                        >
                            <CIcon name="pencil"/>
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
</style>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';
    function fields(){
        return [
            { key: 'TpCargoDetailId',label: '#', _classes: 'text-center', _style: 'width:4%; text-align:center', filter: false },
            { key: 'TpCargoDetailCode',label: this.$t('label.type'), _classes: 'text-uppercase', _style:'width:12%' },
            { key: 'TpCargoDetailName',label: this.$t('label.description'), _classes: 'text-uppercase', _style:'width:35%' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:12%' },
            { key: 'FormatedDate', label: this.$t('label.date'), _classes: 'text-center', _style:'width:12%' },
            { key: 'Status', label: this.$t('label.status'),  _classes: 'text-center', _style:'width:12%' },
            { key: 'acciones', label: '', _style: 'width:8%; min-width:90px;', sorter: false, filter: false }
        ];
    }

    //data
    function data() {
        return {
            activePage: 1,
        }
    }

    //methods
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.group')] = item.TpCargoName;
                objeto[this.$t('label.initials')] = item.TpCargoCode;
                objeto[this.$t('label.size')] = item.TpCargoDetailCode;
                objeto[this.$t('label.description')] = item.TpCargoDetailName;
                objeto[this.$t('label.special')] = item.FgSpecial ? this.$t('label.apply') : 'N/A';
                objeto[this.$t('label.Applyreefer')] = item.FgApplyRefrigeration ? this.$t('label.apply') : 'N/A';
                objeto[this.$t('label.oversize')] = item.FgApplyOversize ? this.$t('label.apply') : 'N/A';
                objeto[this.$t('label.Seal')] = item.FgApplySeal ? this.$t('label.apply') : 'N/A';
                objeto[this.$t('label.highOutside')] = item.OutsideHeigth + ' MTRS';
                objeto[this.$t('label.lengthOutside')] = item.OutsideLength + ' MTRS';
                objeto[this.$t('label.widthOutside')] = item.OutsideWidth + ' MTRS';
                objeto[this.$t('label.volumeOutside')] = item.OutsideVolumen + ' M³';
                objeto[this.$t('label.highInside')] = item.InsideHeigth + ' MTRS';
                objeto[this.$t('label.lengthInside')] = item.InsideLength + ' MTRS';
                objeto[this.$t('label.widthInside')] = item.InsideWidth + ' MTRS';
                objeto[this.$t('label.volumeInside')] = item.InsideVolumen + ' M³';
                objeto[this.$t('label.emptyWeith')] = item.WeigthEmpty + ' KGM';
                objeto[this.$t('label.maximumLoad')] = item.MaxLoad + ' KGM';
                objeto[this.$t('label.maximunGrossWeight')] = item.MaxGrossWeigth + ' KGM';
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'ClasificacionesDeContenedores' : 'ContainerClassification';

            await this.getExcelArray(itemsArray, this.$t('label.containerLists.clasificationsList'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }
    function activarModal(){
        this.$store.state.contenedores.modalDetalle = true;
    }
    function viewCode(item){
        this.$store.state.contenedores.MetadataJson = item.MetadataJson;
        this.$store.state.contenedores.modalCode = true;
        this.$store.state.contenedores.modalName = item.TpCargoDetailName;
    }
    function updateModal(item){
        this.$store.state.contenedores.modalDetalle = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoDetailId);
    }
    function changeCargo(value){
        this.$store.state.contenedores.filtroCargo = value;
        const idCargo = value;
        this.$store.dispatch('contenedores/getDetaillist', { idCargo } );
    }
    function changeSize(value){
        this.$store.state.contenedores.filtroSize= value;
    }

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataDetailtable"];
    }

    function formatedItems() {
        const idSize = this.filtroSize;
        return this.computedItems.filter(item => {
            if(idSize !== ''){
                return item.SizeId === idSize;
            }else{
                return item;
            }
        })
    }

    function optionListCargo(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListSize(){
        if(this.myDataSize.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataSize.map(function(e){
                if(e.FgActSize){
                    chart.push({
                        value: e.SizeId, 
                        label: e.Size,
                    })
                }
            })
            return chart;
        }
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 2){
            const idCargo = this.filtroCargo;
            this.$store.dispatch('contenedores/getDetaillist',{ idCargo });
        }
    }
    function modalDetalle(newValue){
        if(newValue === false){
            const idCargo = this.filtroCargo;
            this.$store.dispatch('contenedores/getDetaillist', { idCargo } );
        }
    }

    export default {
        name: 'index-classifications',
        data,
        mixins:[
            GeneralMixin,
            Reportes
        ],
        props: ['loading'],    
        methods: {
            activarModal,
            updateModal,
            viewCode,
            changeSize,
            changeCargo,
            generarXLS
        },
        computed: {
            computedItems,
            formatedItems,
            optionListCargo,
            optionListSize,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                myDataSize: state => state.contenedores.myDataSize,
                filtroSize: state => state.contenedores.filtroSize,
                filtroCargo: state => state.contenedores.filtroCargo,
                modalDetalle: state=> state.contenedores.modalDetalle,
            })
        },
        watch:{
            tabIndex,
            modalDetalle,
        }
    }   
</script>