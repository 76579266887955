<template>
  <div>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateTpVehiculo"
          v-c-tooltip="{
                  content: $t('label.vehicleClasification'),
                  placement: 'top-end'
                }"
        >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>

        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedClassVehicle"
      :table-filter="tableText.tableFilterText"
      column-filter
      :items-per-page-select="tableText.itemsPerPageText"
      :items-per-page="tableText.itemsPerPage"
      :noItemsView="tableText.noItemsViewText"
      pagination
      :loading="loadingTable"
      hover 
      sorter  
       
    >
      <template #loading>
        <loading/>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.vehicleClasification'),
                placement: 'top-end'
              }"
            @click="toggleShowModalEditCLassVehicle(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>

    <modalClassVehiculo
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="mountedClassVehicle"
      :classVehicleSelected="classVehicleSelected"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import modalClassVehiculo from './modal-class-vehiculo';


function data() {
  return {
    itemsClassVehiculo: [],
    itemsClassVehiculoTemporal: [],
    loadingTable: false,
    loadingOverlay: false,
    showModal: false,
    editModal: false,
    titleModal: '',
    classVehicleSelected: {},
  };
}

function fields() {
    return [    
      { label: '#', key: 'Nro', filter: false, _style: 'width:1%; text-align:center;', _classes:'text-center center-cell' },
      { label: this.$t('label.tpVehicle'), key: 'TpVehicleName',_classes:'text-uppercas text-center center-cell'  },
      { label: this.$t('label.vehicleClasification'), key: 'VehicleClassifName', _classes:'text-uppercase text-center center-cell'},
      { label: this.$t('label.user'), key: 'TransaLogin',_style: 'width:10%',  _classes:'text-uppercase text-center center-cell'  },
      { label: this.$t('label.date'), key: 'FormatedDate', _style: 'width:1%;', _classes: 'text-center center-cell' },
      { label: this.$t('label.status'), key: 'Status', _style: 'width:1%; ', _classes:'text-center center-cell'  },
      { key: 'options', label: '', sorter: false, filter: false, _style: 'width:1%;', _classes:'text-center center-cell'  },
    ]
  }


//### ACtualiza el estado una vez cerrado el modal
function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.classVehicleSelected = {};
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateTpVehiculo() {
  this.showModal = true;
  this.titleModal = this.$t('label.nueva')+' '+this.$t('label.vehicleClasific');
}

async function mountedClassVehicle() {
  this.loadingOverlay = true;
  this.loadingTable = true;

  await this.$http
    .get('VehicleClassification-list?TpVehicleId=')
    .then((response) => {
      if (response.data.data.data !== null) {
        this.itemsClassVehiculo = response.data.data;
        this.itemsClassVehiculoTemporal = response.data.data;
      }
      this.totalPages = response.data.data.TotalRecords;
      this.loadingOverlay = false;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedClassVehicle() {
  if (this.itemsClassVehiculo.length > 0) {
    return this.itemsClassVehiculo.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

function toggleShowModalEditCLassVehicle(item) {
  this.showModal = true;
  this.classVehicleSelected = item;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.vehicleClasific')+': '+this.classVehicleSelected.VehicleClassifName;
  this.editModal = true;
}

export default {
  name: 'classVehiculo',
  data,
  mixins: [GeneralMixin, ModalMixin],
  components: {
    modalClassVehiculo,
  },
  methods: {
    toggleShowModalCreateTpVehiculo,
    updateCLoseModal,
    mountedClassVehicle,
    toggleShowModalEditCLassVehicle,
  },
  mounted: mountedClassVehicle,
  computed: {
    computedClassVehicle,
    fields,
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
