<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${$t('label.positioningList')}`}}</h6>
      </CCol>
      <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
        <CButton
          color="excel"
          class="mr-1" 
          size="sm"
          @click="onBtnExport(true)"
        >
          <CIcon name="cil-file-excel"/>&nbsp; XSLX
        </CButton>
        <CButton
          color="watch"
          size="sm"  
          @click="onBtnExport(false)"
        >
          <CIcon name="cil-file-excel"/>&nbsp; CSV
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3">
        <div class="form-group form-row" rol="group">
            <label class="col-form-label col-sm-12 col-lg-2 text-right" style="font-size:13px">{{$t('label.visit')}}</label>
            <div class="col-sm-12 col-lg-10 input-group">
                <v-select
                  class="select-adjust"
                  :placeholder="$t('label.select')"
                  v-model="Visit"
                  :options="visitFormatted"
                  :reduce="option => option.Json" 
                  :getOptionLabel="option => option.title"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="{ Json, title }">
                    {{ title }}<br />
                    <cite v-if="title!=$t('label.select')">
                      {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.Ata }}
                    </cite>
                  </template>
                </v-select>
            </div>
        </div>
      </CCol>
      <CCol sm="12" lg="2">
        <CInput
          :label="`${$t('label.bl')}`"
          addLabelClasses="text-right"
          :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
          v-uppercase
          v-model="Bl"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <div class="form-group form-row d-flex aling-items-center">
          <label class="col-form-label-sm col-sm-12 col-lg-1 text-right mt-1">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="searchFilterDate.fromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-5 col-lg-4 mb-2">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="searchFilterDate.toDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto">
            <CButton
              color="watch"
              square
              size="sm"
              v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
              class="mx-1"
              @click="(filtre=true, getYardVisitListPositions())"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              v-c-tooltip="{ content: $t('label.clearFilters'), placement: 'top-end'}"
              @click="(refreshComponent(), getYardVisitListPositions())"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3">
        <CInput
          :label="$t('label.filter')"
          :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
          :placeholder="$t('label.enterSearch')" 
          @keyup="onQuickFilterChanged($event.target.value)"         
        >
        </CInput>  
      </CCol>
      <CCol sm="12" lg="5"></CCol>
      <CCol sm="12" lg="4" class="justify-content-end">
        <CSelect
          :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
          :label="$t('label.recordsPerPage')"
          @change="onPageSizeChanged"
          :options="[50,100,500,1000]"
        >
        </CSelect>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="11" lg="12" xl="12" class="mb-2">
        <div class="d-flex bd-highlight">
          <div class="flex-grow-1 bd-highlight">
            <ag-grid-vue
              v-if="showGrid"
              style="width: 100%; height: 100vh;"
              class="ag-theme-alpine"
              :getRowClass="getRowClass"
              :getRowStyle="getRowStyle"
              :gridOptions="gridOptions"
              :defaultColDef="defaultColDef"
              :localeText="localeText"
              :columnDefs="columnDefs"
              :rowData="ComputedList"
              :suppressRowClickSelection="true"
              :groupSelectsChildren="true"
              :enableRangeSelection="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :paginationNumberFormatter="paginationNumberFormatter"
              @grid-ready="onGridReady"
          >
          </ag-grid-vue>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import AgGrid from '@/_mixins/ag-grid';
import YardManagement from '@/_mixins/yardManagement';
import { localeKey } from '@/_helpers/locale.grid';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment';

//DATA-------------------------
function data() {
  return {
    gridOptions: null,
    defaultColDef: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    gridApi: null,
    filtre: false,
    columnApi: null,
    previousDate: '',
    laterDate: '',
    Visit: '',
    Bl: '',
    VisitName: {},
    VisitList: [],
    searchFilterDate:{
      fromDay:"",
      toDay:"",
    },
    formatedItems: [],
  }
}

//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//methods
function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

async function onBtnExport(valor){
  this.$store.state.yardManagement.loading = true;
  let filters = [
    this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay,
    this.$t('label.visit')+': '+(this.VisitName?.VisitId ? this.VisitName?.VesselName+' - '+this.VisitName?.VoyageArrival+ ' - ATA: '+this.VisitName?.Ata : 'N/A'),
    `${this.$t('label.yard')}: ${this.yardData.YardName}`,
    this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
    this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
  ]
  await this.excel([this.excelHeader()], [this.RowData], this.$t('label.positioningList'), this.BranchName, `${this.$t('label.positioningList')}`, [filters], [this.$t('label.inventoryList')], false, false, this.GroupTitle);
  this.$store.state.yardManagement.loading = false;
}

function GroupTitle (worksheet, fieldLast, cellMerge, cellStyles) {
  let custom = ['', '', '', '', '', '', '', '', '', '', '', '','', this.$t('label.packaging'), '', '', this.$t('label.serial'), '', '', '', '', '', '', '', '', '', '']
  worksheet.addRow(custom)
  worksheet.mergeCells(cellMerge(14, fieldLast, 16, fieldLast));
  cellStyles(worksheet, 14, fieldLast, true, 10, 26, "ed7d31", "ffffff", 12, true);
  worksheet.mergeCells(cellMerge(17, fieldLast, 19, fieldLast));
  cellStyles(worksheet, 17, fieldLast, true, 10, 26, "1f3864", "ffffff", 12, true);

  let field = 1
  return field;
}

function getYardVisitList() {
    this.$http.get('YardVisitList-by-Yard', { YardId: this.yardData?.YardId,  CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            this.VisitList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

function getYardVisitListPositions() {
    this.$store.state.yardManagement.loading = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);        

    let ReportJson = {
        ReportJson:{
            "VisitId": this.Visit.VisitId ?? '',
            "DateStart": dateStart,
            "DateEnd": dateEnd,
            "ItineraryId": "",
            "BlCargoId": "",
            SearchKey: this.Bl,
        }
    };

    this.$http.get('YardWBlCargoPositionReport',ReportJson)
        .then(response => {
            this.VisitName = this.Visit;
            let List = response.data.data;
            this.formatedItems = Object.keys(List?.[0] ?? {}).length > 1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}

function getDateRange(){
    this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
    this.laterDate = new Date();
    this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
    this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function excelHeader() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [   
    { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
    { text: this.$t('label.vessel'), value: "VesselName", aux: "", json: "" },
    { text: this.$t('label.voyage'), value: "Voyage", aux: "", json: "" },
    { text: `${'ATA'} / ${'ETA'}`, value: "AtaEta", aux: "", json: "" },
    { text: this.$t('label.bl'), value: "BlNro", aux: "", json: "" },
    { text: this.$t('label.blType'), value: "TpBlName", aux: "", json: "" },
    { text: this.$t('label.Master'), value: "MasterBlNro", aux: "", json: "" },
    { text: this.$t('label.cargoGroup'), value: `GpoCargoName${_lang}`, aux: "", json: "" },
    { text: this.$t('label.portActivity'), value: `PortActivityName${_lang}`, aux: "", json: "" },
    { text: this.$t('label.CertificateEntry'), value: "DocumentCode", aux: "", json: "" },
    { text: this.$t('label.client'), value: "ClientName", aux: "", json: "" },
    { text: this.$t('label.customBroker'), value: "CustomBrokerName", aux: "", json: "" },
    { text: this.$t('label.DescriptionOfGoods'), value: "DescriptionOfGoods", aux: "", json: "" },
    { text: this.$t('label.packaging'), value: `PackagingName${_lang}`, aux: "", json: "" },
    { text: this.$t('label.imdg'), value: "ImdgCodDetail", aux: "", json: "" },
    { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "" },
    { text: this.$t('label.serial'), value: "Serial", aux: "", json: "", json2: "" },
    { text: this.$t('label.type'), value: "TpCargoDetailCode", aux: "", json: "", json2: "" },
    { text: this.$t('label.Seal'), value: "Seal", aux: "", json: "", json2: "" },
    { text: this.$t('label.largo'), value: "Length", aux: "", json: "" },
    { text: this.$t('label.width'), value: "Width", aux: "", json: "" },
    { text: this.$t('label.height'), value: "Height", aux: "", json: "" },
    { text: this.$t('label.startDate'), value: "TransactionBegin", aux: "", json: "" },
    { text: this.$t('label.finalDate'), value: "TransactionFinish", aux: "", json: "" },
    { text: this.$t('label.yard'), value: "YardName", aux: "", json: "" },
    { text: this.$t('label.module'), value: "YardAreaName", aux: "", json: "" },
    { text: this.$t('label.machine'), value: "MachineCode", aux: "", json: "" },
    { text: this.$t('label.device'), value: "DeviceCod", aux: "", json: "" },
    { text: this.$t('label.user'), value: "TransaLogin", aux: "", json: "" },
    { text: this.$t('label.registerDate'), value: "TransaRegDate", aux: "", json: "" },
    { text: this.$t('label.status'), value: `Status`, aux: "", json: "" },
  ]
}

async function refreshComponent() {
  this.formatedItems = [];
  this.Visit="";
  this.Bl = "";
  this.VisitName = {};
  this.searchFilterDate = {
    fromDay: '',
    toDay: '',
  };
  this.DateRange = '';
  this.previousDate = '';
  this.laterDate = '';
  await this.getDateRange();
}

//computeds
function RowData(){
  if(this.formatedItems.length != 0){
    return this.formatedItems.map(item => {
      return {
        ...item,
        Nro: Number(item.IdX),
        AtaEta: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : (item.Eta ? DateFormater.formatDateTimeWithSlash(item.Eta) : ''),
        TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
        TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
        TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
        MasterBlNro: item.MasterBlNro ?? '',
        CustomBrokerName: item.CustomBrokerName ?? '',
        PortActivityName: item.PortActivityName ?? '',
        DocumentCode: item?.DocumentCode,
        Seal: `S1:${item.Seal1 ? item.Seal1 : 'N/A'}, S2:${item.Seal2 ? item.Seal2 : 'N/A'}, S3:${item.Seal3 ? item.Seal3 : 'N/A'}, S4:${item.Seal4 ? item.Seal4 : 'N/A'}`,
        DescriptionOfGoods: item.DescriptionOfGoods ? removeTags(item.DescriptionOfGoods) : ''
      }
    });
  }else{
    return [];
  }
}

function ComputedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let list = [];
  this.formatedItems?.map(e => {
    list.push({
      ...e, 
      Nro: list.length+1,
      AtaEta: e.Ata ? e.Ata : (e.Eta ? e.Eta : ''),
      DocumentCode: e.DocumentCode??'',
      Seal: `S1:${e.Seal1 ? e.Seal1 : 'N/A'}, S2:${e.Seal2 ? e.Seal2 : 'N/A'}, S3:${e.Seal3 ? e.Seal3 : 'N/A'}, S4:${e.Seal4 ? e.Seal4 : 'N/A'}`,
      DescriptionOfGoods: e.DescriptionOfGoods ? removeTags(e.DescriptionOfGoods) : '',
    })
  })
  return list
}

function visitFormatted(){
    if(this.VisitList.length === 0){
        return [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            },
        }];
    }else{
        let _lang = this.$i18n.locale;
        let chart = [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            }
        }];

        this.VisitList.map(function(e){
            chart.push({
                title: e.VesselName,
                value: e.Visit,
                Json: {
                    ...e,
                    VoyageArrival: e.VoyageArrival,
                    Ata: DateFormater.formatDateTimeWithSlash(e.Ata)
                }
            })    
        })
        return chart;
    };
}

function localeText(){
    let AG_GRID_LOCALE = {};
    for (let index = 0; index < localeKey.length; index++) {
        const element = localeKey[index];
        AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
    }
    return AG_GRID_LOCALE;
}

function columnDefs(){
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let columnDefs = [];
  columnDefs.push({
    headerName: '',  
    cellClass: 'center-cell-especial',
    lockPosition: true,
    children: [
      {
        field: "Nro",
        headerName: '#',
        filter: 'agTextColumnFilter',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 100,
        cellClass: 'gb-cell center-cell',
        headerClass: 'center-cell-especial',
      },
      {
        field: "VesselName",
        headerName: this.$t('label.vessel'),
        filter: 'agTextColumnFilter',
        cellClass: 'gb-cell center-cell',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
      },
      {
        field: "Voyage",
        headerName: this.$t('label.voyage'),
        filter: 'agTextColumnFilter',
        cellClass: 'gb-cell center-cell',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
      },
      {
        field: "AtaEta",
        headerName:  `${'ATA'} / ${'ETA'}`,
        cellClass: '',
        filter: 'agTextColumnFilter',
        cellClass: 'gb-cell center-cell',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
        cellRenderer: params => {
          return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : '';
        },
      },
      {
        field: "BlNro",
        headerName: this.$t('label.bl'),
        filter: 'agTextColumnFilter',
        cellClass: 'gb-cell center-cell',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
      },
      {
        field: "TpBlName",
        headerName: this.$t('label.blType'),
        filter: 'agTextColumnFilter',
        cellClass: 'gb-cell center-cell',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
      },
      {
        field: "MasterBlNro",
        headerName: this.$t('label.Master'),
        filter: 'agTextColumnFilter',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
        cellClass: 'gb-cell center-cell',
        headerClass: 'center-cell-especial',
      },
      {
        field: `GpoCargoName${_lang}`,
        headerName: this.$t('label.cargoGroup'),
        filter: 'agTextColumnFilter',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 180,
        cellClass: 'gb-cell center-cell',
        headerClass: 'center-cell-especial',
      },
      {
        field: `PortActivityName${_lang}`,
        headerName: this.$t('label.portActivity'),
        filter: 'agTextColumnFilter',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 220,
        cellClass: 'gb-cell center-cell',
        headerClass: 'center-cell-especial',
      },
      {
        field: "DocumentCode",
        headerName: this.$t('label.CertificateEntry'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 250,
      },
      {
        field: "ClientName",
        headerName: this.$t('label.client'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 220,
      },
      {
        field: "CustomBrokerName",
        headerName: this.$t('label.customBroker'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 220,
      },
      
      {
        field: "DescriptionOfGoods",
        headerName: this.$t('label.inventoryContains'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 260,
      }
    ]
  });

  columnDefs.push({
    headerName: this.$t('label.packaging'),  
    cellClass: 'center-cell-especial',
    headerClass: 'bg-color2 text-white center-cell',
    filter: "agTextColumnFilter",
    lockPosition: true,
    minWidth: 220,
    children: [
      {
        field: `PackagingName${_lang}` ,
        headerName: this.$t('label.packaging'),  
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 220,
      },
      {
        field: "ImdgCodDetail",
        headerName: this.$t('label.imdg'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 160,
      },
      {
        field: "Quantity",
        headerName: this.$t('label.quantity'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 160,
      }
    ]
  });

  columnDefs.push({
    headerName: this.$t('label.serial'),  
    cellClass: 'center-cell-especial',
    headerClass: 'bg-color1 text-white center-cell',
    filter: "agTextColumnFilter",
    lockPosition: true,
    minWidth: 220,
    children: [
      {
        field: "Serial",
        headerName: this.$t('label.serial'),
        filter: 'agTextColumnFilter',
        headerClass: 'center-cell',
        cellClass: 'center-cell',
        lockPosition: true,
        minWidth: 180,
      },
      {
        field: "TpCargoDetailCode",
        headerName: this.$t('label.type'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell',
        cellClass: 'center-cell',
        lockPosition: true,
        minWidth: 140,
      },
      {
        field: "Seal",
        headerName: this.$t('label.Seal'),
        filter: 'agTextColumnFilter',
        headerClass: 'center-cell',
        cellClass: 'center-cell',
        lockPosition: true,
        minWidth: 250,
      }
    ]
  });

  columnDefs.push({
    headerName: '',  
    cellClass: 'center-cell-especial',
    lockPosition: true,
    children: [
      {
        field: "Length",
        headerName: this.$t('label.largo'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 160,
      },
      {
        field: "Width",
        headerName: this.$t('label.width'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 160,
      },
      {
        field: "Height",
        headerName: this.$t('label.height'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 160,
      },
      {
        field: 'TransactionBegin',
        headerName: this.$t('label.startDate'),  
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 180,
        cellRenderer: params => {
          return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : 'N/A';
        },
      },
      {
        field: 'TransactionFinish',
        headerName: this.$t('label.finalDate'),  
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 180,
        cellRenderer: params => {
          return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : 'N/A';
        },
      },
      {
        field: 'YardName',
        headerName: this.$t('label.yard'),  
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 200,
      },
      {
        field: 'YardAreaName',
        headerName: this.$t('label.module'),  
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 200,
      },
      {
        field: 'MachineCode',
        headerName: this.$t('label.machine'),  
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 200,
      },
      {
        field: 'DeviceCod',
        headerName: this.$t('label.device'),
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 200,
      },
      {
        field: 'TransaLogin',
        headerName: this.$t('label.user'),
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 200,
      },
      {
        field: 'TransaRegDate',
        headerName: this.$t('label.registerDate'),
        cellClass: 'center-cell-especial',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 180,
        cellRenderer: params => {
          return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : 'N/A';
        },
      },
      {
        field: `YardCargoBlStatus${_lang}`,
        headerName: this.$t('label.blStatus'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial text-bold',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 220,
      },
      {
        field: `Status`,
        headerName: this.$t('label.status'), 
        headerClass: 'center-cell-especial',
        cellClass: 'center-cell-especial text-bold',
        filter: "agTextColumnFilter",
        lockPosition: true,
        minWidth: 260,
      }
    ]
  });
    
  return columnDefs;
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getRowClass(params) {
  return 'bg-gradient'
}

function getRowStyle(params) {
  return { background: params.data.YardCargoBlStatusColor};
}

export default {
  name: 'inventary-list',
  components: {

  },
  data,
  beforeMount,
  mixins: [GeneralMixin, YardManagement, AgGrid],
  methods: {
    onGridReady,
    onQuickFilterChanged,
    onPageSizeChanged,
    onBtnExport,
    GroupTitle,
    validateDateRange,
    getRowClass,
    getRowStyle,
    getYardVisitListPositions,
    getYardVisitList,
    getDateRange,
    excelHeader,
    refreshComponent
  },
  computed: {
    RowData,
    ComputedList,
    columnDefs,
    localeText,
    visitFormatted,
    ...mapState({
        dropItemReport: state => state.yardManagement.dropItemReportWarehouse,
        yardData: state => state.yardManagement.yardData,
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        BranchName: state => state.auth.branch.BranchName,
        user: state => state.auth.user,
    })
  },
  directives: UpperCase,
  watch: {
    dropItemReport: async function (val) {
        if (val==4) {
            this.$store.state.yardManagement.loading = true;
            await this.refreshComponent();
            await this.getYardVisitList();
            await this.getYardVisitListPositions();
        }
    },
    Visit: function (val) {
        if(val?.Ata){
            let ata = DateFormater.formatDateTimeWithoutSlash(val.Ata);
            this.previousDate = DateFormater.formatDateRange(ata);
            this.searchFilterDate.fromDay =val.Ata;
        }
        else{
            this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
            this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(process.env.VUE_APP_START_DATE_IN_PATIO);
        }
    },
    showGrid: function (NewValue) {
        if (NewValue) {
            this.$store.state.yardManagement.loading = false
        } else {
            this.$store.state.yardManagement.loading = true;
        }
    }
  },
};
</script>