<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t('label.deviceList') }}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
             <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                v-c-tooltip="{placement:'top-end',content:$t('label.device')}"
                class="d-flex align-items-center"
                @click="toggleModal"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
     class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #FormatedDate="{item}">
                  <td class="center-cell text-center align-middle">
                    {{ item.FormatedDate }}
                  </td>
                </template>
                <template #Status="{item}">
                  <td class="center-cell text-center">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class=" center-cell">
                    <CButtonGroup>
                      <CButton
                        shape="square"
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.device'),
                          placement: 'top-end'
                        }"
                        @click="toggleModal(true, item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
    <device-modal :modal.sync="modal" :device-data="device" :edit="modalEdit" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import DeviceModal from './device-modal';

function data() {
  return {
    items: [],
    device: null,
    activePage: 1,
    isLoading: false,
    modal: false,
    modalEdit: false,
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getDevices();
}

//*****Funciones de methods:
function getDevices() {
  this.isLoading = true;

  this.$http.get('Device-list')
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function toggleModal(edit = false, item = null) {
  if(edit && item) {
    this.modalEdit = edit;
    this.device = Object.assign({}, item);
  }
  this.modal = true;
}
function toggleEdit(item, index) {
  this.device = Object.assign({}, item);

  this.modalEdit = true;
}
function handleSubmit(){
  this.getDevices();
}

//*****Funcones de computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    DeviceName: item.DeviceName ? item.DeviceName : '',
    DeviceCode: item.Cod ? item.Cod : '',
    DeviceBrand: item.BrandName ? item.BrandName : '',
    DeviceCondition: item.DeviceStatusDs ? item.DeviceStatusDs : '',
    Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': '',
    _cellClasses: this.cellTableClasses,
  }));
}
function cellTableClasses() {
  return {
    RowNumber: 'center-cell text-left align-middle',
    DeviceName: 'center-cell text-left align-middle',
    MacAddress: 'center-cell text-left align-middle',
    DeviceCode: 'center-cell text-left align-middle',
    DeviceBrand: 'center-cell text-left align-middle',
    DeviceCondition: 'center-cell text-left align-middle',
    Ultimo: 'center-cell text-left align-middle',
    FormatedDate: 'center-cell text-left align-middle',
  }
}
function  fields(){
  
  return [
  { 
    key: 'RowNumber', 
    label: '#',
    _classes:'center-cell text-center',
    _style: 'width:1%; text-align:center;',
    filter:false
  },
  { 
    key: 'DeviceName',
    label:this.$t('label.device'),
    _classes:'center-cell text-center',
    _style: 'width:20%;',
  },
  { 
    key: 'UniqueDeviceId',
    label:this.$t('label.UniqueId'),
    _classes:'center-cell text-center',
    _style: 'width:10%;',
  },
  { key: 'DeviceCode', label: this.$t('label.code'),_classes:'center-cell text-center',_style: 'width:10%;',},
  { key: 'DeviceBrand', label: this.$t('label.brand'), _classes:'center-cell text-center',_style: 'width:10%;',},
  { key: 'DeviceCondition', label: this.$t('label.condition'), _classes:'center-cell text-center',_style: 'width:10%;',},
  { key: 'Ultimo', label: this.$t('label.user'), _classes:'center-cell text-center',_style: 'width:10%;',},
  { key: 'FormatedDate', label: this.$t('label.date'),_classes:'center-cell text-center',_style: 'width:10%;',},
  { key: 'Status', label: this.$t('label.status'), _classes:'center-cell text-center',_style: 'width:10%;',},
  { 
    key: 'Details', 
    label: '', 
    _style: 'min-width:50px', 
    sorter: false, 
    filter: false,
  }
];
}

export default {
  name: 'dispositivos',
  mixins: [GeneralMixin],
  components: { DeviceModal },
  data,
  created,
  methods: {
    getDevices,
    toggleModal,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields,
    cellTableClasses,
  },
  watch: {
    modal: function(val) {
      if(!val){
        this.modalEdit = false;
        this.device = null;
      }
    },
  }
}
</script>

<style scoped>
.label-actividad{
  width: 4em;
  height: 4em;
}
.center-cell {
  text-align: center !important;
  vertical-align: middle !important;
}
</style>