<template>
  <div>
    <div v-show="showIndex===0">
      <CRow class="mb-3">
        <CCol sm="12" lg="6" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.RecognitionList')}`}} </h6>
        </CCol>
        <CCol sm="12" lg="6" class="d-flex align-items-center justify-content-end">
          <CButton
            v-if="fgCertificate && YardId"
            color="add"
            v-c-tooltip="{
              content: $t('label.recognition'),
              placement: 'top-end'
            }"
            @click="showIndex = 1"
          >
            <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
          </CButton>
        </CCol>
      </CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedList"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Quantity="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Quantity, 2) }}
            </td>
          </template>
          <template #Volumen="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Volumen, 2) }}
            </td>
          </template>
          <template #TransactionBegin="{ item }">
            <td class="text-center">
              {{ formatDateTimeWithSlash(item?.TransactionBegin) }}
            </td>
          </template>
          <template #TransactionFinish="{ item }">
            <td class="text-center">
              {{ formatDateTimeWithSlash(item?.TransactionFinish) }}
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton 
                square
                size="sm"
                color="edit"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.recognition')}`,
                  placement: 'top-start'
                }"
                @click="editRecognition(item)"
              >
                <CIcon name='pencil' />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </div>
    <div v-show="showIndex===1">
      <RecognitionRegister
        :Active="showIndex===1"
        :isEdit="isEdit"
        :RecognitionItem="RecognitionItem"
        @close="showIndex=0, RecognitionItem = {}, isEdit=false"
        @Update-list="YardWCargoCustomInspection"
      />
    </div>
  </div>
</template>
<script>

import General from '@/_mixins/general';
import RecognitionRegister from './register/index';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

//data
function data() {
  return {
    showIndex: 0,
    isEdit: false,
    RecognitionItem: {},
    RecognitionList: [],
  };
}

//methods
async function YardWCargoCustomInspection() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardWCargoCustomInspection-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
    .then((response) => {
      this.RecognitionList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
      });
    })
    .finally(async() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function editRecognition(item) {
  this.RecognitionItem = item;
  this.isEdit = true;
  this.showIndex = 1;
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.RecognitionList?.map((e) => {
    return {
      ...e,
      PackagingName: e[`PackagingName${_lang}`] ?? '',
      YardName: e?.YardName ?? 'N/A',
      EventReasonName: e?.[`EventReasonName${_lang}`] ?? '',
      MachineName: e?.MachineName ?? '',
      _classes: !e.FgActYardCargoEventDetail ? 'table-danger' : '',
    };
  });
}

function fields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px', sorter: false, filter: false },
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width: 45px;', filter: false},
    { label: this.$t('label.yard'), key: 'YardName', _style: 'min-width: 200px;', _classes: 'text-uppercase text-center'},
    { label: this.$t('label.packaging'), key: 'PackagingName', _style: 'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { label: this.$t('label.quantity'), key: 'Quantity',  _classes: 'text-uppercase text-center', _style: 'min-width: 140px', },
    { label: this.$t('label.volume'), key: 'Volumen', _classes: 'text-center text-uppercase', _style:'min-width: 180px' },
    { label: this.$t('label.motive'), key: 'EventReasonName', _classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.machine'), key: 'MachineName', _classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.dateFrom'), key: 'TransactionBegin', _classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.dateTo'), key: 'TransactionFinish', _classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.time'), key: 'TotalTimeH', _classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.status'), key: 'Status', _classes:'text-center', _style:'min-width: 140px' },
  ];
}

export default{
  name: 'BlRecognitionIndex',
  data,
  mixins: [General],
  components:{
    RecognitionRegister,
  },
  props: {
    fgCertificate: Boolean,
  },
  methods: {
    YardWCargoCustomInspection,
    formatNumber,
    formatDateTimeWithSlash,
    editRecognition
  },
  computed: {
    computedList,
    fields,
    ...mapState({
      YardId: state => state.yardManagement.yardData.YardId,
      dataBl: state => state.yardManagement.dataBl,
      dropBlMovement: state => state.yardManagement.dropBlMovement,
    })
  },
  watch: {
    dropBlMovement: function (val) {
      if (val==6) {
        this.showIndex = 0;
        this.RecognitionList = [];
        this.RecognitionItem = {};
        this.YardWCargoCustomInspection();
      }
    },
  }
}
</script>