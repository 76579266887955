<template>
  <CCol sm="12">
    <CTabs
      style="width: 100%"
      variant="tabs"
      :active-tab="tabBlIndex"
      @update:activeTab="handleTab"
    >
      <CTab :title="$t('label.electronicData')" v-show="FgGeneralCargo">
        <ElectronicData 
          v-if="FgGeneralCargo" 
          :active="tabIndex == 1 && tabBlIndex==0 && FgGeneralCargo"
        />
      </CTab>
      <CTab title="BL">
        <BlIndex :active="tabIndex == 1 && tabBlIndex == 1 && bulkCarrier == 2"/>
      </CTab>
      <CTab :title="$t('label.stowagePlan')">
        <StowagePlanIndex v-if="FgGeneralCargo" :active="tabIndex == 1 && tabBlIndex == 2 && bulkCarrier == 2"/>
        <stowage-plan v-else :active="tabIndex == 1 && tabBlIndex == 2 && bulkCarrier == 2"/>
      </CTab>
    </CTabs>
  </CCol>
</template>

<script>
import { mapState } from "vuex";
import General from "@/_mixins/general";
import StowagePlanIndex from "./manifiesto/stowage-plan/stowage-plan-index";
import StowagePlan from "./manifiesto/stowage-plan/stowage-plan";
import BlIndex from "./manifiesto/bl-index";
import ElectronicData from "./manifiesto/electronic-data/electronic-data-index";

//data
function data() {
  return {
    tabBlIndex: 0,
  };
}

//methods
function handleTab(tab) {
  this.tabBlIndex = tab;
}

export default {
  name: "app-manifiesto-carga",
  mixins: [General],
  data,
  components: {
    StowagePlanIndex,
    StowagePlan,
    BlIndex,
    ElectronicData,
  },
  methods: {
    handleTab,
  },
  computed: {
    ...mapState({
      tabIndex: (state) => state.planificacionestiba.tabIndex,
      bulkCarrier: state => state.planificacionestiba.bulkCarrier,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    tabIndex: function (Newval, OldVal) {
      if(Newval==1&&!this.FgGeneralCargo) {
        this.tabBlIndex = 1;
      }else if(Newval==1&&this.FgGeneralCargo){
        this.tabBlIndex = 0;
      }
      if(OldVal==1 && this.FgGeneralCargo) {
        this.tabBlIndex = 1;
      }else if(OldVal==1 && this.bulkCarrier == 2 && !this.FgGeneralCargo){
        this.tabBlIndex = 0;
      }
    },
  }
};
</script>