<template>
  <div style="min-height: 880px;">
    <CRow class="m-0">
      <h5 class="col-sm-12 text-center text-bold px-0">{{ $t('label.blType') }}</h5>
      <CCol sm="12" lg="6" class="px-1 mb-2">
        <label class="text-bold">{{ $t('label.Master') }}</label>
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center" style="height: 395px;">
              <PieDonut
                Width="100%"
                Height="100%"
                Type="pie"
                :Series="seriesPieMaster"
                :Label="labelMaster"
                :Colors="colorsMaster"
              />
            </div>
            <div class="d-flex align-items-center px-2">
              <ChartBar
                distributed
                horizontal
                scrolly
                maxHeight="403px"
                Width="100%"
                :Height="BarSizeMaster"
                :Series="seriesMaster"
                :Categories="labelMaster"
                :Colors="colorsMaster"
                :dataLabels="dataLabelsBar"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="6" class="px-1 mb-2">
        <label class="text-bold">{{ $t('label.house') }}</label>
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center" style="height: 395px;">
              <PieDonut
                Width="100%"
                Height="100%"
                :Series="seriesPieHouse"
                :Label="labelHouse"
                :Colors="colorsHouse"
              />
            </div>
            <div class="d-flex align-items-center px-2">
              <ChartBar
                distributed
                horizontal
                scrolly
                maxHeight="403px"
                Width="100%"
                :Height="BarSizeHouse"
                :Series="seriesHouse"
                :Categories="labelHouse"
                :Colors="colorsHouse"
                :dataLabels="dataLabelsBar"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import ChartBar from '../chart/bar'
import PieDonut from '../chart/pieDonut'
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    CurrentStatistic: [],
  }
}

//methods
async function getYardWCargoStatisticsByTpBl() {
  this.$store.state.yardManagement.loading = true;
  this.CurrentStatistic = [];
  let ReportJson = {
    YardId: this.yardData.YardId,
  }

  await this.$http.post('YardWCargoStatistics-by-TpBl', ReportJson, { root: 'ReportJson' })
    .then(response => {
      this.CurrentStatistic = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    })
}

function validateDateRange(date) {
  return date < this.form.PreviousDate || date > this.form.LaterDate;
}

function statisticLegend(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item[`TpCargoName${_lang}`]
}

async function Clear() {
  await this.$emit('getDateRange');
  await this.getYardWCargoStatisticsByTpBl();
}

//computed
function master() {
  return this.CurrentStatistic.find(e => e.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER)
}

function house() {
  return this.CurrentStatistic.find(e => e.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE)
}

function seriesMaster() {
  let data = [];
  let ConsigneeJson = this.master?.ConsigneeJson ?? [];
  data.push({ data: ConsigneeJson.map(item => item.Quantity) });
  return data;
}

function seriesPieMaster() {
  let ConsigneeJson = this.master?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.Percentage);
}

function seriesHouse() {
  let data = [];
  let ConsigneeJson = this.house?.ConsigneeJson ?? [];
  data.push({ data: ConsigneeJson.map(item => item.Quantity) });
  return data;
}

function seriesPieHouse() {
  let ConsigneeJson = this.house?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.Percentage);
}

function labelMaster() {
  let ConsigneeJson = this.master?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.ClientName);
}

function labelHouse() {
  let ConsigneeJson = this.house?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.ClientName);
}

function colorsMaster() {
  let ConsigneeJson = this.master?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.Color);
}

function colorsHouse() {
  let ConsigneeJson = this.house?.ConsigneeJson ?? [];
  return ConsigneeJson.map(item => item.Color);
}

function dataLabelsBar() {
  return {
    formatter: function (val, opts) {
      return NumberFormater.formatNumber(val, 2);
    },
  }
}

function BarSizeMaster() {
  let size = this.master?.ConsigneeJson?.length;
  return size > 8 ? `${(size*403)/8}px` : '100%'
}

function BarSizeHouse() {
  let size = this.house?.ConsigneeJson?.length;
  return size > 8 ? `${(size*403)/8}px` : '100%'
}

export default {
  name: 'warehouse-yard-situation-loadType',
  data,
  components: {
    ChartBar,
    PieDonut,
  },
  mixins: [GeneralMixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    active: Boolean,
  },
  methods: {
    getYardWCargoStatisticsByTpBl,
    validateDateRange,
    statisticLegend,
    Clear,
  },
  computed: {
    master,
    house,
    seriesMaster,
    seriesPieMaster,
    seriesHouse,
    seriesPieHouse,
    labelMaster,
    labelHouse,
    colorsMaster,
    colorsHouse,
    dataLabelsBar,
    BarSizeMaster,
    BarSizeHouse,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    active: async function(newVal) {
      if(newVal) {
        await this.getYardWCargoStatisticsByTpBl();
      }
    },
  }
};
</script>
<style scoped>
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 0rem !important;
  border-radius: 5px;
}
.box-empty{
  width: 100%;
  height: 755px;
  padding-top: 1rem !important;
}
</style>