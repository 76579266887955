<template>
  <div>
    <loading-overlay
      :closeOnBackdrop="false"
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      size="lg"
      color="dark"
      :title=title
      :show.sync="modalActive"
      @update:show="$emit('close');"
    >
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="itemsDocs"
          :fields="fieldsDoc"
          column-filter
          :items-per-page="5"
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          hover
          small
          sorter
          pagination
        >
          <template #DocsRoute="{item}">
            <td class="py-2">
              <CButton
                color="excel"
                class="btn btn-sm btn-watch mr-1"
                v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
                target="_blank"
                :href="item.DocsRoute"
              >
                <CIcon name="cil-cloud-download" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
      <template #footer>
        <CButton color="wipe" @click="$emit('close');">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";

function fieldsDoc(){
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false
    },
    { key: "StandardDocumentDocRoute", label: this.$t('label.document'),_classes: 'text-center center-cell' },
    { key: "Usuario", label: this.$t('label.user'),_classes: 'text-center center-cell' },
    { key: "Fecha", label: this.$t('label.date'),_classes: 'text-center center-cell' },
    {
      key: "DocsRoute",
      label: "",
      sorter: false,
      filter: false,
      _style: "width:1%;",
    },
  ];
}

//METHOD
function listarDocs(id) {
  this.itemsDocs = [];
  this.Loading = true;
  let listado = [];
  let listado2 = [];
  this.$http
    .get("StandardDocument-by-id", { StandardDocumentId: id })
    .then((response) => {
      listado2 = [...response.data.data];
      listado = listado2[0].StandardDocumentDocJson;
      let Nro = 1;
      this.itemsDocs = listado.map((listado) =>
        Object.assign({}, this.itemsDocs, {
          Nro: Nro++,
          StandardDocumentDocRoute: listado2[0].StandardDocumentName,
          DocsRoute: listado.StandardDocumentDocRoute
            ? `${
                this.$store.getters["connection/getBase"]
              }${listado.StandardDocumentDocRoute.replace("Public/", "", "i")}`
            : "N/A",
          Usuario: listado2[0].TransaLogin ? listado2[0].TransaLogin : "N/A",
          Fecha: listado2[0].TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado2[0].TransaRegDate)
            : "N/A",
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

//DATA
function data() {
  return {
    itemsDocs: [],
    modalActive: false,
    Loading: false,
  };
}

export default {
  name: "list-modal",
  mixins: [General],
  data,
  props: { modal: Boolean,  listItem: Object, title: String,},
  methods: {
    listarDocs,
  },
  computed:{
    fieldsDoc,
  },
  watch: {
    modal: function() {
      if (this.modal) {
        this.modalActive = true;
        this.listarDocs(this.listItem.StandardDocumentId);
      }else{
        this.modalActive = false;
      }
    },
  },
};
</script>
<style scoped></style>
