import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { textareaSpecials } from '../funciones';
const isValidFile = (value) => {
  if(!value)
    return false;

  if(value.size && Math.round((value.size / 1024)) > 4096){
    return false;
  }

  if(value.name){
    let file = value.name.substring(value.name.lastIndexOf('.')+1, value.name.length) || "";
    return ['pdf','doc','docx','xlsx', 'jpg', 'png'].includes(file.toLowerCase());
  }else
    return (value != '');
};

export default () => {
  return {
    documento: {
      VesselId: { required },
      VesselDocDesc: { required, textareaSpecials, maxLength: maxLength(200) },
      RouteDocDesc: { }
    },
    fileDoc: { required, isValidFile }
  }
}