<template>
    <div>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.CurrentSituationTransportation") }}</span>
                </div>
                <div style="width: 100%;padding:10px;" class="d-flex text-especial">
                    <!--<div class="d-flex">
                        <div class="flex-fill" style="margin-left:5px;margin-right: 5px;border-radius: 5px;text-align: center;background: #edebeb;padding: 15px;">
                            <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                                <h5 style="margin-bottom:0px">{{ $t('label.client') }}: {{ clientData }}</h5>
                            </div>
                        </div>
                    </div>-->
                    <div class="flex-fill" style="margin-top:20px;background: #E8F0F4;padding: 5px;padding-top: 10px;">
                        <div style="border-bottom:4px solid #6D89AF;margin-left:50px;margin-right:50px;text-align: center;margin-bottom: 10px;"><h6>{{ $t('label.shipmentOrders') }}</h6></div>
                        <div class="d-flex">
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#1E3A61;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.transport') }}</th>
                                                <th>{{ $t('label.Weighing') }}</th>
                                                <th>{{ $t('label.orders') }}</th>
                                                <th>{{ $t('label.voyage') }}</th>
                                                <th>{{ $t('label.Returned') }}</th>
                                                <th>{{ $t('label.InCircuit') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.CarrierClientName }}</td>
                                                <td style="height: 45.5px;">{{ item.pesajeTex }}</td>
                                                <td style="height: 45.5px;">{{ item.CantDispatched }}</td>
                                                <td style="height: 45.5px;">{{ item.CantEmbarked }}</td>
                                                <td style="height: 45.5px;">{{ item.CantReturned }}</td>
                                                <td style="height: 45.5px;">{{ item.CantInCircuit }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#6D89AF;color:#FFF;text-align: center;height: 45.5px;align-items: center;">
                                            <tr>
                                                <th>{{ $t('label.Annulled') }}</th>
                                                <th>{{ $t('label.incidences') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center;">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.CantAnnulled }}</td>
                                                <td style="height: 45.5px;">{{ item.CantIncidence }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill" style="margin-top:20px;background: #E8F0F4;padding: 5px;padding-top: 10px;">
                        <div style="border-bottom:4px solid #E58C5E;margin-left:50px;margin-right:50px;text-align: center;margin-bottom: 10px;"><h6>{{ $t('label.time') }}</h6></div>
                        <div class="d-flex">
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#E58C5E;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.HorasTrabajadas') }}</th>
                                                <th>{{ $t('label.HorasRetrasos') }}</th>
                                                <th>{{ $t('label.HorasEfectivas') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.WorkedHours }}</td>
                                                <td style="height: 45.5px;">{{ item.DelayHours }}</td>
                                                <td style="height: 45.5px;">{{ item.EffectiveHours }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#Ec631B;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.Heavy')+' TON' }}</th>
                                                <th>{{ $t('label.GamelaxHora') }}</th>
                                                <th>TON {{ $t('label.PerHour') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ NumberFormat(item.WeighedTon, 2) }}</td>
                                                <td style="height: 45.5px;">{{ item.CantImplementXHour }}</td>
                                                <td style="height: 45.5px;">{{ NumberFormat(item.TonXHour, 2) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { NumberFormater } from '@/_helpers/funciones';
    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }
    //computed
    function carrierArray(){
        let data = [];
        this.CarrierJson.map(async(item) => {
            let pesajeTex = item.FgRequiredTruckWeighing ? this.$t('label.yes') : 'NO';
            data.push({
                ...item,
                pesajeTex: pesajeTex,
            })
        })
        return data;
    }
    export default {
        props:{
            CarrierJson:{
                type:Array,
                default: () => [],
            }
        },
        methods:{
            NumberFormat,
        },
        computed:{
            carrierArray,
        }
    }
</script>

<style lang="scss">
    .text-especial{
        font-size:0.65rem;
        .table th, .table td {
            vertical-align: middle;
        }
    }
</style>