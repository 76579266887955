<template>
    <div class="mb-3">
        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.DispachesMassiveToVessel')}`}} </h6>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="6">
                <div class="form-group form-row mb-2" rol="group">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 required text-right">{{$t('label.visit')}}</label>
                    <div class="col-sm-12 col-lg-8 input-group">
                        <v-select
                            id="v-select-small"
                            class="select-adjust"
                            :placeholder="$t('label.select')"
                            :class="$v.Visit.$dirty ? $v.Visit.$error ? 'select-client--error' : 'select-client--correct' : ''"
                            v-model="$v.Visit.$model"
                            :options="VisitOptions"
                            :getOptionLabel="option => option.label"
                            :reduce="option => option.Json" 
                            style="width: 100%; height: 29px;"
                        >
                            <template #no-options="{ }">
                                {{$t('label.noResultsFound')}}
                            </template>
                            <template #option="{ Json, label, value }">
                                {{ label }}<br />
                                <cite v-if="value">
                                    {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.CumputedAta }} 
                                </cite>
                            </template>
                        </v-select>
                        <div v-if="$v.Visit.$error" sm="12" class="text-invalid-feedback mb-0">
                            {{ errorMessage($v.Visit) }}
                        </div>
                    </div>
                </div>
            </CCol>
            <CCol sm="12" lg="6">
                <CSelect
                    size="sm"
                    v-model="$v.MachineId.$model"
                    :options="MachineOptions"
                    :label="$t('label.machine')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.MachineId)"
                    :invalid-feedback="errorMessage($v.MachineId)"
                />
            </CCol>
            <CCol sm="12" lg="6">
                <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.dispatchDate')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                    <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.TransactionDate.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                    >
                        <template #input>
                        <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="TransactionDate"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.TransactionDate)"
                            size="sm"                      
                        >
                            <template #append-content>
                            <CIcon name="cil-calendar" />
                            </template>
                        </CInput>
                        </template>
                        <template #icon-calendar>
                        <div style="display: none"></div>
                        </template>
                    </vue-datepicker>
                    </div>
                </div>
            </CCol>
            <CCol sm="12" lg="6">
                <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.observation')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #Checked-filter>
                        <td style="border-top:none" class="text-center">
                            <CInputCheckbox
                                custom
                                :label="$t('label.all')"
                                class="checkbook-filter"
                                :checked="selectedAll"
                                @update:checked="SelectAllContainer($event)"
                            />
                        </td>
                    </template>
                    <template #Checked="{ item }">
                        <td class="text-center">
                            <CInputCheckbox
                                custom
                                :checked="item.Checked"
                                class="checkbook-filter ml-1"
                                @update:checked="addRemoveContainer(item)"
                            />
                        </td>
                    </template>
                    <template #Observation="{ item }">
                        <td class="text-center">
                            <CButton
                                v-if="item.Checked"
                                square
                                size="sm"
                                color="watch"
                                class="d-flex align-items-center"
                                @click="AddObservation(item)"
                                v-c-tooltip="{
                                    content: $t('label.observation'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name='cil-comment-bubble'/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            <CCol v-if="$v.CheckedContainer.$error" sm="12" class="text-center text-invalid-feedback mb-2 mt-0">
                {{ $t('label.AtLeastContainerRequired') }}
            </CCol>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="Submit()"
                    :disabled="isSubmit"
                >
                    <CIcon name="checkAlt"/>{{ $t('button.accept')}}
                </CButton>
            </CCol>
        </CRow>
        <ModalObservationContainer
            :modal="ModalObservation"
            :SelectedContainer="SelectedContainer"
            @Close="ModalObservation=false, SelectedContainer={}"
            @Update="UpdateList"
        />
        <ModalDispatchedList
            :modal="ModalDispatched"
            :DispatchList="DispatchList"
            @Close="Update"
        />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import DispachesMassiveValidations from '@/_validations/yard-management/movements/dispachesMassiveValidations';
import ModalObservationContainer from './modal-observation-container';
import ModalDispatchedList from './modal-dispatched-list';
import moment from 'moment';

//data
function data() {
    return {
        selectedAll: false,
        ModalDispatched: false,
        ModalObservation: false,
        isSubmit: false,
        Visit: '',
        VisitList: [],
        MachineId: '',
        MachineList: [],
        TransactionDate: '',
        ValidPreviousDate: '',
        ValidLaterDate: '',
        previousDate: '',
        laterDate: '',
        Observation: '',
        CheckedContainer: [],
        dataContainer: [],
        SelectedContainer: {},
        DispatchList: {},
    };
}

//methods
async function getYardVisit() {
    await this.$http.get('YardVisit-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            this.VisitList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'yard',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

async function getMachineYard() {
    await this.$http.get('MachineYard-list', {YardId: this.YardId, CompanyBranchId: this.CompanyBranchId})
        .then(response => {
        let List = response.data.data;
        this.MachineList = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
        })
}

async function getYardCargoInventary() {
    this.$store.state.yardManagement.loading = true;
    let ReportJson = {
        YardId: this.YardId, 
        VisitId: this.Visit.VisitId,
        DateStart: this.ValidPreviousDate,
        DateEnd: this.ValidLaterDate,
    }
    await this.$http.post('YardCargoInventary-by-VisitId', ReportJson, { root: 'ReportJson' })
        .then(response => {
            let List = response.data.data ?? [];
            this.dataContainer = List[0]&&Object.entries(List[0]).length>1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}

async function getYardManagementCurrent() {
  await this.$http.get('YardManagementCurrent-by-Id', { YardCurrentId: this.YardCurrentId })
    .then(response => {
      if(response.data.data){
            let yardInfo = this.$store.state.yardManagement.yardData;
            yardInfo = {
                ...yardInfo,
                YardName : response.data.data[0].YardName,
                YardAlias : response.data.data[0].YardAlias,
                SquareMeters : response.data.data[0].SquareMeters,
                TpYardNameEn : response.data.data[0].TpYardNameEn,
                TpYardNameEs : response.data.data[0].TpYardNameEs,
                TeusCapacity : response.data.data[0].TeusCapacity,
                TeusOccupied : response.data.data[0].TeusOccupied,
                TeusAvailable: response.data.data[0].TeusAvailable,
                YardGpoName : response.data.data[0].YardGpoName,
                TodayReception : response.data.data[0].TodayReception,
                TodayDispatch : response.data.data[0].TodayDispatch
            }
            this.$store.state.yardManagement.yardData = yardInfo;
        }   
    }).catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
    })
}

async function getYardCargoReception() {
    await this.$http.get('YardCargoReception-by-YardId', { YardId: this.YardId })
        .then(response => {
            let List = response.data.data;
            this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
  }

function Submit() {
    try {
        this.$store.state.yardManagement.loading = true;
        this.isSubmit = true;
        this.$v.$touch();
        if (this.$v.$error){
            if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) <= this.ValidPreviousDate) {
                throw this.$t('label.ErrorAtaDatePleaseCheck');
            }
            if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) >= this.ValidLaterDate && this.Visit?.Atd) {
                throw this.$t('label.ErrorAtdDatePleaseCheck');
            }
            if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate) {
                throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
            if (this.$v.CheckedContainer.$error) {
                throw this.$t('label.AtLeastContainerRequired');
            }
            throw this.$t('label.errorsPleaseCheck');
        }
  
        let CargoJson = {
            YardId: this.YardId,
            MachineId: this.MachineId,
            TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
            Observation: this.Observation,
            YardCargoJson: this.CheckedContainer.map(item =>{
                return {
                    YardCargoId: item.YardCargoId,
                    Observation: item.Observation,
                }
            })
        }
  
        this.$http.post('YardCargoMultipleDispatch', CargoJson, { root: 'CargoJson' })
            .then(async (response) => {
                if (response?.data?.data?.DispatchJson && response.data.data.DispatchJson.length != 0) {
                    await this.getYardManagementCurrent();
                    await this.getYardCargoReception();
                }
                await this.Reset();
                this.DispatchList = response.data.data;
                this.ModalDispatched = true;
                this.isSubmit = false;
                this.$store.state.yardManagement.loading = false;
            }).catch((err) => {
                this.isSubmit = false;
                this.$store.state.yardManagement.loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    } catch (error) {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: error,
            type: "error"
        });
    }
}

function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
}

function SelectAllContainer(event) {
    this.selectedAll = event
    if (event) {
        this.CheckedContainer = this.dataContainer;
    }else{
        this.CheckedContainer = [];
    }
}

function addRemoveContainer(item) {
    let Container = this.CheckedContainer.some((e)=>e.YardCargoId==item.YardCargoId)
    if(!Container){
        //ADD
        this.CheckedContainer.push(item);
        //Select All
        if (this.CheckedContainer.length == this.dataContainer.length) {
            this.selectedAll = true;
        }
    }else{
        //DELETE
        this.CheckedContainer = this.CheckedContainer.filter((e) =>e.YardCargoId!=item.YardCargoId);
        this.selectedAll = false;
    }
}

function AddObservation(item) {
    this.SelectedContainer = item;
    this.ModalObservation = true;
}

function UpdateList(Observation, YardCargoId) {
    this.CheckedContainer = this.CheckedContainer.map(item => {
        if (item.YardCargoId == YardCargoId) {
            item.Observation = Observation;
        }
        return item;
    });
    this.dataContainer = this.dataContainer.map(item => {
        if (item.YardCargoId == YardCargoId) {
            item.Observation = Observation;
        }
        return item;
    });
}

async function Update() {
    this.$store.state.yardManagement.loading = true;
    this.ModalDispatched = false;
    this.DispatchList = {};
    await this.getYardVisit();
    await this.getMachineYard();
    this.$store.state.yardManagement.loading = false;
}

function Reset() {
    this.ModalDispatched=false;
    this.ModalObservation = false;
    this.selectedAll = false;
    this.Visit = '';
    this.VisitList = [];
    this.MachineId = '';
    this.MachineList = [];
    this.TransactionDate = '';
    this.ValidPreviousDate = '';
    this.ValidLaterDate = '';
    this.previousDate = '';
    this.laterDate = '';
    this.Observation = '';
    this.CheckedContainer = [];
    this.dataContainer = [];
    this.DispatchList = {};
    this.$v.$reset();
}
  
//computed
function VisitOptions() {
    if(this.VisitList.length === 0){
        return [{
            label: this.$t('label.select'),
            value: '',
            Json: {},
        }];
    }else{
        let chart = [{
            label: this.$t('label.select'),
            value: '',
            Json: {}
        }];
        this.VisitList.map(function(e){
            chart.push({
                label: e.VesselName,
                value: e.VisitId,
                Json: {
                    ...e,
                    VoyageArrival: e.VoyageArrival,
                    CumputedAta: DateFormater.formatDateTimeWithSlash(e.Ata),
                }
            })    
        })
        return chart;
    };
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function computedListContainer() {
    let _lang = this.$i18n.locale;
    return this.dataContainer.map((item) => {
        let Checked = this.CheckedContainer.some((e)=>e.YardCargoId==item.YardCargoId)
        return {
            ...item,
            Nro: Number.parseInt(item.Nro),
            BlockCode: item?.CurrentPositionJson[0] ? item?.CurrentPositionJson[0].BlockCode : '',
            BayCode: item?.CurrentPositionJson[0] ? item?.CurrentPositionJson.map(Pos => {
                return Pos.BayCode.trim();
            }).join(', ') : '',
            SlotCode: item?.CurrentPositionJson[0] ? item?.CurrentPositionJson.map(Pos => {
                return Pos.SlotCode.trim();
            }).join(', ') : '',
            Area: item?.AreaJson[0] ? item?.AreaJson.map(Area => {
                return Area.YardAreaName.trim();
            }).join(', ') : '',
            ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
            CumputedAta: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : '',
            DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
            CumputedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
            Checked: Checked,
            Observation: item.Observation ?? '',
            _classes: 'color-gradient',
            _style: 'background:'+item.TpTransacEirColor,
        };
    })
}

function fields(){ 
    return [
        { key: 'Checked', label: '', _style: 'min-width:45px;', sorter: false, },
        { key: 'Observation', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _classes: 'text-center',  _style: 'min-width:45px;', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
        { key: 'BlockCode', label: this.$t('label.block'), _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'BayCode', label: 'BAY', _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'SlotCode', label: this.$t('label.CurrentPosition'), _classes: 'text-center', _style:'min-width:180px'},
        { key: 'Area', label: 'AREA', _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'VisitDs', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`, _classes: 'text-center', _style: 'min-width: 235px;'},
        { key: 'CumputedAta', label: 'ATA', _classes: 'text-center', _style: 'min-width: 160px;'},
        { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
        { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
        { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _classes: 'text-center'},
        { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
        { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 135px;'},
        { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
        { key: 'DaysInYard', label: this.$t('label.daysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
        { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
        { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
        { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
}

export default{
    name: 'equipment',
    data,
    mixins: [General],
    components: {
        ModalObservationContainer,
        ModalDispatchedList,
    },
    validations(){
        return DispachesMassiveValidations(this.ValidPreviousDate, this.ValidLaterDate, this.Visit?.Atd);
    },
    directives: UpperCase,
    methods: {
        getYardVisit,
        getMachineYard,
        getYardCargoInventary,
        getYardManagementCurrent,
        getYardCargoReception,
        Submit,
        validateDateRange,
        SelectAllContainer,
        addRemoveContainer,
        AddObservation,
        UpdateList,
        Update,
        Reset,
    },
    computed: {
        VisitOptions,
        MachineOptions,
        fields,
        computedListContainer,
        ...mapState({
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            YardId: state => state.yardManagement.yardData.YardId,
            YardCurrentId: state => state.yardManagement.yardData.YardCurrentId,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
        })
    },
    watch:{
        dropItemMovementList: async function (NewVal) {
            if (NewVal==10) {
                this.$store.state.yardManagement.loading = true;
                await this.Reset();
                await this.getYardVisit();
                await this.getMachineYard();
                this.$store.state.yardManagement.loading = false;
            }
        },
        Visit: async function (NewValue) {;
            if(NewValue?.VisitId){
                this.$store.state.yardManagement.loading = true;
                this.laterDate = NewValue?.Atd ? DateFormater.formatDateRange(NewValue?.Atd) : new Date();
                this.previousDate = DateFormater.formatDateRange(NewValue.Ata);
                this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(NewValue.CumputedAta);
                this.ValidLaterDate = NewValue?.Atd ? moment(NewValue?.Atd).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm');
                this.TransactionDate = NewValue?.Atd ? moment(NewValue.Atd).add(1, 'm').format('DD/MM/YYYY HH:mm') :  moment(new Date()).format('DD/MM/YYYY HH:mm');
                await this.getYardCargoInventary();
            }else{
                this.dataContainer = [];
                this.ValidPreviousDate = '';
                this.ValidLaterDate = '';
                this.TransactionDate = '';
                this.laterDate = '';
                this.previousDate = '';
            }
        },
        TransactionDate: async function(val){
            try{
                if(val){
                    if (DateFormater.formatDateTimeWithoutSlash(val) <= this.ValidPreviousDate) {
                        throw this.$t('label.ErrorAtaDatePleaseCheck');
                    }
                    if (DateFormater.formatDateTimeWithoutSlash(val) >= this.ValidLaterDate && this.Visit?.Atd) {
                        throw this.$t('label.ErrorAtdDatePleaseCheck');
                    }
                    if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
                        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                    }
                }
            }catch(error){
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: error,
                    type: "error"
                });
            }
        },
    }
}
</script>