import ENUM from './enum';
import service from '@/_services/service';
import {DateFormater} from '@/_helpers/funciones';

export const unidadmedida = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        id:0,
        unitMeasureTypeId:0,
        myData:[],
        messageError:'',
        myDataTpUnidad:[],
        filtroUnidad:'ALL',
        modalUnidad:false,
        modalUnitMeasure:false,
        modalUnitMeasureType:false,
        collapseTipoUnidad:false,
        collapseTypeUnitMeasure:false,
        collapseTableTipoUnidad:false,
    },
    getters: {
        myDatatable: state => {
            let chart = [];
            if(state.myData.length !== 0){
                state.myData.sort(function (a, b) {
                    if (a.FgActUnitMeasure < b.FgActUnitMeasure) return 1;
                    else if (a.FgActUnitMeasure > b.FgActUnitMeasure) return -1;
                    else {
                        if (a.UnitMeasureName > b.UnitMeasureName) {
                            return 1;
                        }
                        if (a.UnitMeasureName < b.UnitMeasureName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myData.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActUnitMeasure,
                    _classes: ( item.FgActUnitMeasure ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDatatableUnitMeasureType: state => {
            let chart = [];
            if(state.myDataTpUnidad.length !== 0){
                state.myDataTpUnidad.sort(function (a, b) {
                    if (a.FgActTpUnitMeasure < b.FgActTpUnitMeasure) return 1;
                    else if (a.FgActTpUnitMeasure > b.FgActTpUnitMeasure) return -1;
                    else {
                        if (a.TpUnitMeasureName > b.TpUnitMeasureName) {
                            return 1;
                        }
                        if (a.TpUnitMeasureName < b.TpUnitMeasureName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataTpUnidad.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpUnitMeasure,
                    _classes: ( item.FgActTpUnitMeasure ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
    },
    actions: {
        getUnidadlist({commit},filtro){
            commit('getUnidadlisttRequest');
            service.ejecutar('GET', 'UnitMeasure-list', { filtro: (filtro === undefined || filtro === null) ? 'ALL': filtro }, '')
            .then(
                Unidades => commit('getUnidadlisttSuccess', { Unidades }),
                error => {
                    commit('messageMutation', error)
                    commit('getUnidadlisttFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterUnidad ({ commit,dispatch }, { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida }) {
            commit('postregisterUnidadRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'UnitMeasure-insert';
                if(IdUnidadMedida === 0){
                    valores = {
                        TpUnitMeasureId:IdTpUnidadMedida, 
                        UnitMeasureName:NbUnidadMedida,
                        UnitMeasureAbbrev:SiglasUnidadMedida, 
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'UnitMeasure-update';
                    valores = {
                        UnitMeasureId:IdUnidadMedida,
                        TpUnitMeasureId:IdTpUnidadMedida, 
                        UnitMeasureName:NbUnidadMedida,
                        UnitMeasureAbbrev:SiglasUnidadMedida, 
                        Status: FgActUnidadMedida ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }

                service.ejecutar(method, link, valores, { root: 'UnitMeasureJson' })
                .then(
                    dataUnidades => {
                        if(dataUnidades.data.status === 200){
                            const information = dataUnidades.data.data;
                            if(information[0].id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('postregisterUnidadFailure', information[0].Response)
                            }else{
                                commit('messageMutation', information[0].Response)
                                dispatch('unidadmedida/getUnidadlist', 'ALL', { root: true });
                                commit('postregisterUnidadSuccess', { information })

                            }
                        }else{
                            commit('postregisterUnidadFailure', dataUnidades.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('postregisterUnidadFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterUnidadFailure', error)
            }
        },
        getTipoUnidadlist({commit}){
            commit('getTipoUnidadlisttRequest');
            service.ejecutar('GET', 'TpUnitMeasure-list', { filtro: 'ALL' }, '')
            .then(
                TipoUnidades => commit('getTipoUnidadlisttSuccess', { TipoUnidades }),
                error => {
                    commit('messageMutation', error)
                    commit('getTipoUnidadlisttFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterTipoUnidad ({ commit,dispatch }, { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad }) {
            commit('postregisterTipoUnidadRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'TpUnitMeasure-insert';
                if(IdTpUnidadMedida === 0){
                    valores = {
                        TpUnitMeasureName:NbTpUnidadMedida,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'TpUnitMeasure-update';
                    valores = {
                        TpUnitMeasureId:IdTpUnidadMedida,
                        TpUnitMeasureName:NbTpUnidadMedida,
                        Status: FgActTpUnidad ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpUnitMeasureJson' })
                .then(
                    dataTipoUnidades => {
                        if(dataTipoUnidades.data.status === 200){
                            const information = dataTipoUnidades.data.data;
                            if(information[0].id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('postregisterTipoUnidadFailure', information[0].Response)
                            }else{
                                commit('messageMutation', information[0].Response)
                                commit('postregisterTipoUnidadSuccess', { information })
                                service.ejecutar('GET', 'TpUnitMeasure-list', { filtro: 'ALL' }, '')
                                .then(
                                    TipoUnidades => commit('getTipoUnidadlisttSuccess', { TipoUnidades }),
                                    error => {
                                        commit('messageMutation', error)
                                        commit('getTipoUnidadlisttFailure', error)
                                        //dispatch('alert/error', error, { root: true });
                                    }
                                );

                            }
                        }else{
                            commit('postregisterTipoUnidadFailure', dataTipoUnidades.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('postregisterTipoUnidadFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterTipoUnidadFailure', error)
            }
        }
    },
    mutations: {
        setUnitMeasureId (state, id ) {
            state.id = id;
        },
        messageMutation (state, value ) {
            state.messageError = value;
		},
        setUnitMeasureTypeId (state, id ) {
            state.unitMeasureTypeId = id;
        },
        mutationModal (state,  action ) {
            state.modalUnidad = action;
        },
        mutationModalUnitMeasure (state,  action ) {
            state.modalUnitMeasure = action;
        },
        mutationModalUnitMeasureType (state,  action ) {
            state.modalUnitMeasureType = action;
        },
        mutationfiltroUnidad (state,  value ) {
            state.filtroUnidad = value;
        },

        //Listar unidades
        getUnidadlisttRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getUnidadlisttSuccess(state, { Unidades }) {
            try{
                if(Unidades.status === 200){
                    state.myData = Unidades.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Unidades);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getUnidadlisttFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar unidad de medida
        postregisterUnidadRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterUnidadSuccess (state, { information }) {
            try{
               // state.modalUnidad = false;
                state.modalUnitMeasure = false;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterUnidadFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },

        //Listar tipos unidades
        getTipoUnidadlisttRequest(state) {
            state.apiStateForm = ENUM.LOADING; 
        },
        getTipoUnidadlisttSuccess(state, { TipoUnidades }) {
            try{
                if(TipoUnidades.status === 200){
                    state.myDataTpUnidad = TipoUnidades.data.data;
                    state.apiStateForm = ENUM.INIT;
                }else{
                    console.log(TipoUnidades);
                    state.apiStateForm = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiStateForm = ENUM.ERROR; 
            }
        },
        getTipoUnidadlisttFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR; 
        },

        //Registrar o actualizar tipo de unidad
        postregisterTipoUnidadRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterTipoUnidadSuccess (state, { information }) {
            try{
                state.modalUnitMeasureType = false;
                state.unitMeasureTypeId = 0;
                state.unitMeasureTypeId = information[0].TpUnitMeasureId;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiState = ENUM.ERROR;
                state.apiStateForm = ENUM.ERROR;
            }
        },
       


        postregisterTipoUnidadFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
    }
}