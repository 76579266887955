
<template>
  <form @submit.prevent="submitBody">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title=title
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="resetForm"
    >
        <CRow sm="12" class="justify-content-center mt-3">
          <!-- firt column -->
          <CCol sm="12" lg="6" >
            <!-- tipo -->
            <CInput
              horizontal
              size="sm"
              :label="$t('label.type')"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              addLabelClasses="text-right"
              v-model="$v.TpEirName.$model"
              :is-valid="hasError($v.TpEirName)"
              :invalid-feedback="errorMessage($v.TpEirName)" 
              class="remove-space"
              disabled
            />
            <!-- fecha -->
            <div class="form-group form-row mb-3 px-0">
              <label class="col-form-label-sm col-sm-12 col-lg-4 text-right mb-0">{{`${$t('label.date')}`}}</label>
              <div class="input-group col-sm-12 col-lg-7 align-items-center mb-0">
                <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model="$v.FormatedDate.$model"
                  :disabled-date="validateDateRange"
                  :append-to-body="false"
                  value-type="format"
                  :show-second="false"
                  class="w-100"
                >
                  <template #input>
                    <CInput
                      v-uppercase
                      class="w-100 float-left mb-0"
                      placeholder="DD/MM/YYYY HH:mm"
                      size="sm"
                      v-model="$v.FormatedDate.$model"
                      :is-valid="hasError($v.FormatedDate)"           
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
            <!--devolución -->
            <CInput
              v-uppercase
              horizontal
              size="sm"
              :label="$t('label.emptyReturn')"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              addLabelClasses="text-right"
              v-model="$v.Devolution.$model"
              :is-valid="hasError($v.Devolution)"
              :invalid-feedback="errorMessage($v.Devolution)" 
              class="remove-space"
            />
            <!-- cliente -->
             <div class="form-group form-row" rol="group">
                <label class="col-form-label-sm col-sm-12 col-lg-4 text-right mb-0 required" for="pais" >
                  {{$t('label.client')}}
                </label>
                <div class="col-sm-12 col-lg-7">
                  <v-select 
                    id="v-select-small"
                    :options="clientOptions"
                    :placeholder="$t('label.select')"      
                    v-model="$v.ClientId.$model"
                    :reduce="option => option.value"
                    :getOptionLabel="option => option.label"               
                    :class="!$v.ClientId.$dirty ? '' : ($v.ClientId.$error  ? 'select-client--error' : 'select-client--correct')"
                    style="min-height: 10px !important; font-size: 13px;"
                  />
                  <div v-if="$v.ClientId.$error" class="col-sm-12 col-lg-6  text-invalid-feedback text-left pl-0" >
                    {{ errorMessage($v.ClientId) }}
                  </div>
              </div>
            </div>
            <!-- chequeador -->
            <div class="form-group form-row" rol="group">
              <label v-if="FgOperator" class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">
                {{$t('label.checker')}}
              </label>
              <label v-if="!FgOperator" class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">
                {{$t('label.other')+' '+$t('label.checker')}}
              </label>
              <div class="col-sm-12 col-lg-1 mb-2">
                <div class="d-flex justify-content-end">
                  <CSwitch
                    color="watch"
                    variant="3d"
                    type="checkbox"
                    :checked.sync="FgOperator"
                    @update:checked="OperatorName=''"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-lg-7" v-if="FgOperator">
                <v-select 
                  style="min-height: 10px !important; font-size: 13px;"
                  id="v-select-small"
                  :placeholder="`${$t('label.select')}`"
                  :options="checkerOptions"
                  v-model="$v.OperatorName.$model"
                  :reduce="option => option.label"
                  :getOptionLabel="option => option.label"               
                  :class="!$v.OperatorName.$dirty ? '' : $v.OperatorName.$error? 'select-client--error' : 'select-client--correct'"
                  class="select-operator-name"
                />
              </div>
              <div class="col-sm-12 col-lg-7" v-if="!FgOperator">
                <CInput
                  :placeholder="$t('label.name')"                                         
                  v-uppercase
                  v-model="$v.OperatorName.$model"
                  :is-valid="hasError($v.OperatorName)"
                  :invalid-feedback="errorMessage($v.OperatorName)" 
                  class="mb-0"
                  size="sm"
                />
              </div>
            </div>
            <CSelect
              size="sm"
              v-uppercase
              :label="$t('label.condition')"
              :addLabelClasses="'required text-right'"
              :horizontal="{label:'col-sm-12 col-lg-4' , input:'col-sm-12 col-lg-7'}"
              v-model="$v.EirContainerCondictionId.$model"
              :options="ContainerCondictionOptions"
              :is-valid="hasError($v.EirContainerCondictionId)"
              :invalid-feedback="errorMessage($v.EirContainerCondictionId)" 
            />
            <!-- observacion -->
            <CTextarea
              :label="$t('label.observation')"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              maxlength="250"
              size="sm"
              v-uppercase
              v-model="$v.Observation.$model"
              :is-valid="hasError($v.Observation)"
              :invalid-feedback="errorMessage($v.Observation)"
              addLabelClasses="text-right"
              class="remove-space"
              rows="2"
              :disabled="true"
            />
          </CCol>

            <!-- second column -->
            <CCol sm="12" lg="6">
                <!-- transaccion -->
                <CInput
                  horizontal
                  size="sm"
                  :label="$t('label.transaction')"
                  :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                  addLabelClasses="text-right"
                  v-model="$v.TpTransacEirName.$model"
                  :is-valid="hasError($v.TpTransacEirName)"
                  :invalid-feedback="errorMessage($v.TpTransacEirName)"
                  class="remove-space"
                  disabled
                  >
                </CInput>
                <!-- transportista -->
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right required" for="pais" >
                    {{$t('label.transport')}}
                  </label>
                    <div class="col-sm-12 col-lg-7">
                      <v-select 
                        id="v-select-small"
                        :options="TransportOptions"
                        :placeholder="$t('label.select')"      
                        v-model="$v.CarrierId.$model"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"               
                        :class="!$v.CarrierId.$dirty ? '' : ($v.CarrierId.$error  ? 'select-client--error' : 'select-client--correct')" 
                        style="min-height: 10px !important; font-size: 13px;"
                      />
                      <div v-if="$v.CarrierId.$error" class="col-sm-12 col-lg-6  text-invalid-feedback text-left pl-0" >
                        {{ errorMessage($v.CarrierId) }}
                      </div>
                    </div>
                </div>
                 <!-- vehiculo -->
                 <CInput
                    size="sm"
                    v-uppercase
                    v-model="$v.SearchPlate.$model"
                    :label="$t('label.plate')"
                    :addLabelClasses="'text-right'"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                    :placeholder="$t('label.plate')"
                    :is-valid="hasError($v.VehicleId)"
                    :invalid-feedback="errorMessage($v.VehicleId)"
                  >
                    <template #append>
                      <div class="d-flex align-items-start">
                        <CButton
                          color="watch"
                          square
                          size="sm"
                          class="mr-1"
                          style="padding: 0.15rem 0.4rem;"
                          @click="getVehicleByLicensePlate"
                        >
                          <CIcon name="cil-search"/>
                        </CButton>
                        <CButton
                          v-if="Object.keys(objectVehicle).length>1"
                          color="edit"
                          square
                          size="sm"
                          style="padding: 0.15rem 0.4rem;"
                          @click="(isEditVehicle=true, CollapseVehicle=true)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                      </div>
                    </template>
                  </CInput>
                <CInput
                  size="sm"
                  v-uppercase
                  :label="$t('label.vehicle')"
                  :addLabelClasses="'text-right required'"
                  :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                  :placeholder="$t('label.vehicle')"
                  v-model="VehicleName"
                  :is-valid="hasError($v.VehicleId)"
                  :invalid-feedback="errorMessage($v.VehicleId)"
                  :disabled="true"
                >
                </CInput>
                 <!-- conductor -->
                 <CInput
                    size="sm"
                    v-uppercase
                    v-model="$v.SearchDriver.$model"
                    label="CI"
                    :addLabelClasses="'text-right'"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                    placeholder="CI"
                    :is-valid="hasError($v.DriverId)"
                    :invalid-feedback="errorMessage($v.DriverId)"
                  >
                    <template #prepend>
                      <CSelect
                        size="sm"
                        :value.sync="numberIdLetter"
                        :options="CiTypeOptions"
                        class="mr-2 mb-1"
                        :is-valid="hasError($v.DriverId)"
                      />
                    </template>
                    <template #append>
                      <div class="d-flex align-items-start">
                        <CButton
                          color="watch"
                          square
                          size="sm"
                          class="mr-1"
                          style="padding: 0.15rem 0.4rem;"
                          @click="getDriverByCi"
                        >
                          <CIcon name="cil-search"/>
                        </CButton>
                        <CButton
                          v-if="Object.keys(diverSelected).length>1"
                          color="edit"
                          square
                          size="sm"
                          style="padding: 0.15rem 0.4rem;"
                          @click="(isEditDriver=true, CollapseDriver=true)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                      </div>
                    </template>
                  </CInput>
                  <CInput
                    size="sm"
                    v-uppercase
                    :label="$t('label.driver')"
                    :addLabelClasses="'text-right required'"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                    :placeholder="$t('label.driver')"
                    class="remove-space"
                    v-model="DriverName"
                    :is-valid="hasError($v.DriverId)"
                    :invalid-feedback="errorMessage($v.DriverId)"
                    :disabled="true"
                  />
            </CCol>
        </CRow>

        <CCol sm="12" lg="12">
          <CCollapse :show="CollapseVehicle">
            <CollapseVehicle
              :modal="CollapseVehicle"
              :objectVehicle="objectVehicle"
              :CallMaster="true"
              :editModal="isEditVehicle"
              :title="TitleVehicle"
              @UpdatedVehicle="getVehicle"
              @updated-modal="(CollapseVehicle=false, isEditVehicle=false)"
            />
          </CCollapse>
        </CCol>

        <CCol sm="12" lg="12">
          <CCollapse :show="CollapseDriver">
            <CollapseDriver  
              :modal="CollapseDriver"
              :diverSelected="diverSelected"
              :CallMaster="true"
              :editModal="isEditDriver"
              :title="TitleDriver"
              @UpdatedDriver="getDriver"
              @updated-modal="(CollapseDriver=false, isEditDriver=false)"
              @cerrarModal="CollapseDriver=false"
            />
          </CCollapse>
        </CCol>

        <CCol sm="12">
          <CCollapse :show="VehicleOrDriverList.length!=0">
            <CollapseVehicleOrDriver
              :items="VehicleOrDriverList"
              :Type="TypeList"
              @SelectedInfo="SelectedInfo"
              @CloseModal="(VehicleOrDriverList=[], TypeList=0)"
            />
          </CCollapse>
        </CCol>  
  
      <CustomTabs :active-tab="this.tabIndex" @update:activeTab="handleTab" class="text-table mt-2">
        <CustomTab>
          <template #title>
            <span>{{$t('label.Seals')}}</span>
          </template>
          <div>
            <div>
              <div class="align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
                <CRow class="align-items-center justify-content-left">
                  <CCol sm="6" class="align-items-center justify-content-center">
                    <CRow>
                      <CCol sm="12" xl="12">
                        <b>{{`${$t('label.inspections')} / ${$t('label.Seals')}`}}</b>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
              <div>
                <CRow>
                    <CCol col="12" xl="12">
                        <dataTableExtended
                            class="align-center-row-datatable"
                            hover
                            sorter
                            small
                            details
                            column-filter
                            :items="formatedItemsSeals"
                            :fields="fieldsSeals"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                            pagination
                        >
                        </dataTableExtended>
                    </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.damageOption')}}</span>
          </template>
          <div>
            <div class="align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
              <CRow class="align-items-center justify-content-left">
                <CCol sm="6" class="align-items-center justify-content-center">
                  <CRow>
                    <CCol sm="12" xl="12">
                      <b>{{`${$t('label.inspections')} / ${$t('label.damageOption')}`}}</b>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <div>
              <CRow>
                  <CCol col="12" xl="12">
                      <dataTableExtended
                          class="align-center-row-datatable"
                          hover
                          sorter
                          small
                          details
                          column-filter
                          :items="formatedItemsDamageInspection"
                          :fields="fieldsDamageInspection"
                          :noItemsView="tableText.noItemsViewText"
                          :items-per-page="tableText.itemsPerPage"
                          pagination
                      >
                      <template #checked-header>
                          <td style="border-top:none">
                            <CInputCheckbox
                              custom
                              class="checkbook-filter mr-2"
                              :checked="AllDamage"
                              @update:checked="selectedAll($event,'Damage')"
                            />
                          </td>
                        </template>
                      <template #checked="{item}">
                          <td class="text-center">
                            <CInputCheckbox
                              custom
                              class="checkbook-filter ml-1"
                              :checked="item.checked"
                              @update:checked="addRemoveId(item,'Damage')"
                            />
                          </td>
                        </template>
                      </dataTableExtended>
                  </CCol>
              </CRow>
            </div>
          </div>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.observation')}}</span>
          </template>
          <CRow>
            <CCol sm="12" lg="12">
                <CCol sm="12" class="mb-2">
                    <vue-editor
                      size="sm"
                      :horizontal="{label:'col-sm-12 col-lg-12', input:'col-sm-12 col-lg-12'}"
                      v-model="ObservationUser"
                      :class="computedSelectObservation ? 'select-description--correct' : (descriptionValid ? 'select-description--correct' : '') "
                    >
                    </vue-editor>
                </CCol>
              </CCol>
            </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.specials')}}</span>
          </template>
          <dataTableExtended
            class="align-center-row-datatable"
            :items="formatedItemsSpecials"
            :fields="fieldsSpecials"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          > 
          <template #checked-header>
            <td style="border-top:none">
              <CInputCheckbox
                custom
                class="checkbook-filter mr-2"
                :checked="AllAccessories"
                @update:checked="selectedAll($event,'Accessories')"
              />
            </td>
          </template>
          <template #checked="{item}">
            <td class="text-center">
              <CInputCheckbox
                custom
                class="checkbook-filter ml-1"
                :checked="item.checked"
                @update:checked="addRemoveId(item,'Accessories')"
              />
            </td>
          </template>
          <template #select="{item}">
            <td class="text-center">
              <CSelect
                  size="sm"
                  class="mb-0"
                  v-uppercase
                  :addLabelClasses="'required text-right'"
                  :horizontal="{input:'col-sm-12'}"
                  :value="item.FgOperative" 
                  :options="FgOperativeOptions"
                  :is-valid="!validateAccessory(item.TpCargoAccesoryId) ? null : (item.FgOperative!='' ? true : false)"
                  @change="addRemoveCondition(item, $event.target.value)"
              />
            </td>
          </template>
          <template #input="{item}">
            <td class="text-center">
              <CInput
                  size="sm"
                  class="mb-0"
                  v-uppercase
                  :addLabelClasses="'required text-right'"
                  :horizontal="{input:'col-sm-12'}"
                  :value.sync="item.Observation"
                  :is-valid="!validateAccessory(item.TpCargoAccesoryId) ? null : true"
                  @change="addRemoveObservation(item, $event)"
              />
            </td>
          </template>
        </dataTableExtended>

        </CustomTab>
      </CustomTabs>

      <template #footer>
        <CButton
          color="watch"
          shape="square"
          class="d-flex align-items-center mr-2"
          @click="printPdf()"
        >
          <CIcon name="cil-info"/><span class="ml-1">{{$t('button.preview')}}</span>
        </CButton>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="submitBody()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>

    </CModalExtended>
  </form>



</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { VueEditor } from "vue2-editor";
import { mapState } from 'vuex';
import eirValidations from '@/_validations/yard-management/container/eirValidations';
import SealsForm from '../inspection/seals/seal-index';
import DamageForm from '../inspection/damages/damage-index';
import { DateFormater} from '@/_helpers/funciones';
import CollapseDriver from './collapse-driver'
import CollapseVehicle from './collapse-vehicle'
import CollapseVehicleOrDriver from './collapse-vehicle-or-driver-list.vue'

function data() {
  return {
    modalActive: false,
    tabIndex: 0,
    consolidatorCheck: false,
    FgOperator: false,
    clientItems: [],
    openDate: false,
    previousDate: '',
    laterDate: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    FormatedDate: '',
    TpTransacEirName: '',
    Devolution: '',
    TpEirName: '',
    InfoYardItems: {},
    items: [],
    TransactionList: [],
    YardStatusSuggestTransactionId: '',
    TpCargoDetailCode: '',
    CarrierName: '',
    LicensePlate: '',
    CarrierId: '',
    OperatorName: '',
    DriverName: '',
    SearchDriver: '',
    ClientId: '',
    Observation: '',
    TransportList: [],
    CheckerList: [],
    Loading: false,
    SealList:  [],
    DamageList: [],
    AccesoriesList: [],
    DescriptionType: '',
    numberIdLetter: 'V',
    DriverId: '',
    diverSelected: {},
    isEditDriver: false,
    isEditVehicle: false,
    ModalNewVehicle: false,
    SearchPlate: '',
    VehicleId: '',
    VehicleName: '',
    ClientName: '',
    TransportName: '',
    objectVehicle: {},
    VehicleOrDriverList: [],
    TypeList: 0,
    AccessoryObservation: '',
    EirContainerCondictionId: '',
    ContainerCondictionList: [],
    CollapseDriver: false,
    CollapseVehicle: false,
    AllSeals: false,
    AllDamage: false,
    AllAccessories: false,
    arrIdsDamage: [],
    arrIdsAccessories: [],
    ObservationUser: '',
    itemsEirReport: {},
    descriptionValid: false,
  };
}
//methods

function handleTab(tab) {
  this.tabIndex = tab;
}

 function submitBody() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.descriptionValid = true;

    this.$v.$touch();
    
    if ((this.$v.$error)||this.arrIdsAccessories.some(item => item.FgOperative=='')) {
      this.Loading = false;
      this.isSubmit = false;
      if (DateFormater.formatDateTimeWithoutSlash(this.FormatedDate) < this.ValidPreviousDate && this.ValidateEventReception) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.FormatedDate) < this.ValidPreviousDate && !this.ValidateEventReception) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfDispatch');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.FormatedDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }
    
    let EirJson  = {
      CompanyBranchId: this.getBranchId,
      YardCargoId: this.InfoYardItems?.YardCargoId,
      TpEirId: this.InfoYardItems?.TpEirId,
      TpEirTransacId: this.InfoYardItems?.TpEirTransacId,
      VisitInspectionId: this.InfoYardItems?.SealJson[0]?.VisitCargoInspectionId,
      ClientId: this.ClientId,
      Devolution: this.Devolution != '' ?  this.Devolution : '',
      Observation: this.ObservationUser,
      CarrierId: this.CarrierId,
      DriverId: this.DriverId,
      DriverName: this.DriverName,
      VehicleId: this.VehicleId,
      LicensePlate: this.SearchPlate,
      OperatorName: this.OperatorName,
      AccesoryInspJson: this.arrIdsAccessories,
      DamageInspJson: this.arrIdsDamage,
      EirContainerCondictionId: this.EirContainerCondictionId,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.FormatedDate),
    }
    
    let metodo = 'POST';
    let ruta = 'YardCargoEir-insert';

    this.$http.ejecutar(metodo, ruta, EirJson, { root: 'EirJson' })
      .then(async response => {
        this.$emit('submited');
        this.modalActive = false;
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
        await this.getPrintPdf(response.data.data[0], this.FormatedDate);
        this.$emit('Update-list');

        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

async function getData(val) { 
  let _lang = this.$i18n.locale;
  this.TpCargoDetailCode = val?.TpCargoDetailCode;
  this.CarrierId = val?.CarrierId;
  this.CarrierName =  val?.CarrierName;
  this.OperatorName =  val?.CheckerName ;
  this.VehicleId =  val?.VehicleId;
  this.VehicleName = val?.LicensePlate;
  this.LicensePlate = val?.LicensePlate;
  this.DriverId =  val?.DriverId;
  this.DriverName =  val?.DriverName;
  this.VehicleId = val?.VehicleId;
  this.ClientId = val?.ClientId;
  this.ObservationUser = val?.Observation;
  this.Devolution = val?.Devolution;
  this.FormatedDate =  process.env.VUE_APP_EVENT_RECEPTION == val?.EventId ? 
        DateFormater.formatDateTimeWithSlash(val?.ReceptionDate) : 
        DateFormater.formatDateTimeWithSlash(val?.DispatchDate);
  this.TpTransacEirName = _lang == 'en' ? val?.TpTransacEirNameEn : val?.TpTransacEirNameEs;
  this.TpEirName =  _lang == 'en' ? val?.TpEirNameEn : val?.TpEirNameEs;
  this.DescriptionType = val?.DescriptionType;
  this.descriptionValid = false;
  this.FgOperator =  val?.CheckerName ? this.FgOperator = true : this.FgOperator = false;
  this.EirContainerCondictionId = val.EirContainerCondictionId ?? '';
  this.SealList = val?.SealJson && val?.SealJson.length != 0 ? val?.SealJson : [];
  this.DamageList = val?.DamageInspectionJson && val?.DamageInspectionJson.length != 0 ? val?.DamageInspectionJson : [];
  this.AccesoriesList = val?.AccesoriesJson && val?.AccesoriesJson.length != 0 ? val?.AccesoriesJson : [];

  await this.getDateRange();
  val.DriverCi && val.DriverCi !='' && await this.getDriverByCi();
  val.LicensePlate && val.LicensePlate !='' && await this.getVehicleByLicensePlate();

}

function validateAccessory(id) {
  return this.arrIdsAccessories.some(element => element.TpCargoAccesoryId == id);
}

function resetForm() {
  this.TpCargoDetailCode = '';
  this.TpCargoDetailCode = '';
  this.CarrierId = '';
  this.CarrierName = '';
  this.OperatorName = '';
  this.VehicleId = '';
  this.VehicleName = '';
  this.LicensePlate = '';
  this.DriverName = '';
  this.ClientId = '';
  this.SearchDriver = '';
  this.Observation = '';
  this.ObservationUser = '';
  this.FormatedDate = '';
  this.TpTransacEirName = '';
  this.TpEirName = '';
  this.Devolution = '';
  this.eirForm = false;
  this.SealList=  [];
  this.DamageList= [];
  this.AccesoriesList = [];
  this.TransportList = [];
  this.CheckerList = [];
  this.DescriptionType= '';
  this.modalActive = false;
  this.CollapseDriver = false;
  this.CollapseVehicle = false;
  this.SearchPlate = '';
  this.AllSeals = false;
  this.AllDamage = false;
  this.AllAccessories = false;
  this.arrIdsDamage = [];
  this.arrIdsAccessories = [];
  this.descriptionValid = false;
  this.tabIndex = 0;
  this.itemsEirReport = {};
  this.ClientName = '';
  this.TransportName = '';
  this.FgOperator = false;
  this.AccessoryObservation = '';
  this.numberIdLetter = '';
  this.DriverId = '';
  this.diverSelected = {};
  this.VehicleId = '';
  this.VehicleName = '';
  this.objectVehicle = {};
  this.VehicleOrDriverList = [];
  this.previousDate = '';
  this.laterDate = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.EirContainerCondictionId = '';
  this.ContainerCondictionList = [];
  this.isEditDriver = false;
  this.isEditVehicle = false;

  this.$emit('close');
  this.$v.$reset();
}

function statusSelectColor() {
  return this.form.dataBasic.Status === 1;
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function printPdf() {
  let pageInfo = {
    FormatedDate: this.FormatedDate,
    Devolution: this.Devolution,
    VehicleName: this.VehicleName,
    ClientName: this.ClientName,
    TransportName: this.TransportName,
    DriverName: this.DriverName,
    OperatorName: this.OperatorName,
    ObservationUser: this.ObservationUser,
    FgEirContainerCondiction: this.EirContainerCondictionId.toUpperCase() == process.env.VUE_APP_EIR_CONTAINER_CONDICTION_OPERATIVE_ID ? true : false,
    arrIdsDamage: this.arrIdsDamage.map((item, index) => {
      return {
        ...item,
        Nro: index+1,
      }
    }),
    arrIdsAccessories: this.arrIdsAccessories.map((item, index) => {
      return {
        ...item,
        Nro: index+1,
      }
    }),
    infoContainer: this.InfoYardItems,
    Preview: true,
  };
  
  localStorage.setItem('pageInfo', JSON.stringify(pageInfo));
  let routeData = this.$router.resolve({name: 'PdfEir'});
  window.open(routeData.href, '_blank');

}


async function getPrintPdf(item, FormatedDate) {

  await this.$http.get('YardCargoEir-by-Id?',{ YardCargoEirId: item.YardCargoEirId})
  .then(response => {
    let res = response.data.data && response.data.data.length != 0 ? response.data.data[0] : {};
    let pageInfo = {
      FormatedDate: FormatedDate,
      Devolution: res.Devolution,
      VehicleName: res.LicensePlate,
      ClientName: res.ClientName,
      TransportName: res.CarrierName,
      DriverName: res.DriverName,
      OperatorName: res.OperatorName,
      ObservationUser: res.Observation,
      FgEirContainerCondiction: res.EirContainerCondictionId == process.env.VUE_APP_EIR_CONTAINER_CONDICTION_OPERATIVE_ID ? true : false,
      arrIdsDamage: res.DamageInspJson,
      arrIdsAccessories: res.AccesoryInspJson,
      infoContainer: res,
      Preview: false,
    };
  
    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));
    let routeData = this.$router.resolve({name: 'PdfEir'});
    window.open(routeData.href, '_blank');

  })        

}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getDateRange(){
  this.previousDate = this.ValidateEventReception ? 
    DateFormater.formatDateRange(this.InfoYardItems?.ReceptionDate) : 
    DateFormater.formatDateRange(this.InfoYardItems?.DispatchDate);
  this.ValidPreviousDate = DateFormater.formatTimeZoneToDateTimewithDash(this.previousDate);
  this.laterDate = new Date();
  this.ValidLaterDate = DateFormater.formatTimeZoneToDateTimewithDash(this.laterDate);
}

//datepicker
function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}
async function getDriverByCi() {
  if (this.SearchDriver.length <= 10) {
    this.Loading = true;
    await this.$http.get('Driver-by-Ci', { DriverCi: (this.numberIdLetter+this.SearchDriver) })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if (List.length==1) {
            this.numberIdLetter = List[0]?.DriverCi?.charAt(0) ?? '';
            this.SearchDriver = List[0].DriverCi?.slice(1) ?? '';
            this.DriverId = List[0].DriverId;
            this.DriverName = List[0].DriverName;
            this.diverSelected = List[0];
            this.CollapseDriver = false;
          }else{
            this.TypeList = 2;
            this.DriverId = '';
            this.DriverName = '';
            this.diverSelected = {};
            this.VehicleOrDriverList = List;
          }
        }else{
          this.DriverId = '';
          this.DriverName = '';
          this.diverSelected = {DriverCi: (this.numberIdLetter+this.SearchDriver)};
          this.CollapseDriver = true;
        }
      })
      .catch( err => {
        this.DriverId = '';
        this.DriverName = '';
        this.diverSelected = {};
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(()=> {
        this.Loading = false;
      });
  }
}

async function getVehicleByLicensePlate() {
  if (this.SearchPlate&&this.SearchPlate.length <= 10) {
    this.Loading = true;
    await this.$http.get('Vehicle-by-LicensePlate', { LicensePlate: this.SearchPlate })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if (List.length==1) {
            let Vehicle = List[0];
            let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
            this.SearchPlate = Vehicle.LicensePlate;
            this.VehicleId = Vehicle.VehicleId;
            this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`;
            this.objectVehicle = List[0];
            this.CollapseVehicle = false;
          }else{
            this.TypeList = 1;
            this.VehicleId = '';
            this.VehicleName = '';
            this.objectVehicle = {};
            this.VehicleOrDriverList = List;
          }
        }else{
          this.VehicleId = '';
          this.VehicleName = '';
          this.objectVehicle = { LicensePlate: this.SearchPlate };
          this.CollapseVehicle = true;
        }
      })
      .catch( err => {
        this.VehicleId = '';
        this.VehicleName = '';
        this.objectVehicle = {};
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(()=> {
       this.Loading = false;
      });
  }else{
    this.TypeList = 1;
    this.VehicleId = '';
    this.VehicleName = '';
    this.objectVehicle = {};
    this.VehicleOrDriverList = [];
  }
}

function getDriver(Driver) {
  this.numberIdLetter = Driver.DriverCi?.charAt(0) ?? '';
  this.SearchDriver = Driver?.DriverCi?.slice(1) ?? '';
  this.DriverId = Driver.DriverId;
  this.DriverName = Driver.DriverName;
  this.diverSelected = Driver;
}

function getVehicle(Vehicle) {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
  this.SearchPlate = Vehicle.LicensePlate;
  this.VehicleId = Vehicle.VehicleId;
  this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`; 
  this.objectVehicle = Vehicle;
}


function selectedAll(event,type) {
  if (type == 'Damage') {
    this.arrIdsDamage = [];
    if (event) {
      this.AllDamage = true;
      //SELECCIONA TODOS LOS ITEM
      this.DamageList.map((item) => {
        this.arrIdsDamage.push({
          VisitCargoInspDamageId: item.VisitCargoInspDamageId,
          TpDamageNameEn: item.TpDamageNameEn,
          TpDamageNameEs: item.TpDamageNameEs,
          TpCargoViewNameEn: item.TpCargoViewNameEn,
          TpCargoViewNameEs: item.TpCargoViewNameEn,
          Width: item.Width,
          Height: item.Height,
          Observation: item.Observation,
        });  
      });

    }else{
      this.AllDamage = false;
    }
  }

  if (type == 'Accessories') {
    this.arrIdsAccessories = [];
    if (event) {
      this.AllAccessories = true;
      //SELECCIONA TODOS LOS ITEM
      this.AccesoriesList.map((item) => {
        this.arrIdsAccessories.push({
          TpCargoAccesoryId: item.TpCargoAccesoryId,
          TpCargoAccesoryNameEn: item.TpCargoAccesoryNameEn,
          TpCargoAccesoryNameEs: item.TpCargoAccesoryNameEs,
          FgOperative: item.FgOperative, 
          Observation: '',
        });  
      });

    }else{
      this.AllAccessories = false;
    }
  }

 
}

function addRemoveId(item,type) {

  if (type == 'Damage') {
    let bool = this.arrIdsDamage.filter((e) => e.VisitCargoInspDamageId == item.VisitCargoInspDamageId)

    if( bool.length == 0 ){
      //ADD
      this.arrIdsDamage.push({
        VisitCargoInspDamageId: item.VisitCargoInspDamageId,
        TpDamageNameEn: item.TpDamageNameEn,
        TpDamageNameEs: item.TpDamageNameEs,
        TpCargoViewNameEn: item.TpCargoViewNameEn,
        TpCargoViewNameEs: item.TpCargoViewNameEn,
        Width: item.Width,
        Height: item.Height,
        Observation: item.Observation,
      });
    }
    else{
      //DELETE
      this.arrIdsDamage = this.arrIdsDamage.filter((e) => e.VisitCargoInspDamageId !== item.VisitCargoInspDamageId);
    }
    if (this.arrIdsDamage.length == this.DamageList.length) {
      this.AllDamage = true;
    }else{
      this.AllDamage = false;
    }
  }

  if (type == 'Accessories') {

    let bool = this.arrIdsAccessories.filter((e) => e.TpCargoAccesoryId == item.TpCargoAccesoryId)

    if( bool.length == 0 ){
      //ADD
      this.arrIdsAccessories.push({
        TpCargoAccesoryId: item.TpCargoAccesoryId, 
        TpCargoAccesoryNameEn: item.TpCargoAccesoryNameEn,
        TpCargoAccesoryNameEs: item.TpCargoAccesoryNameEs,
        FgOperative: '', 
        Observation: ''
      });  
    }
    else{
      //DELETE
      this.arrIdsAccessories = this.arrIdsAccessories.filter((e) => e.TpCargoAccesoryId !== item.TpCargoAccesoryId);
    }
    if (this.arrIdsAccessories.length == this.AccesoriesList.length) {
      this.AllAccessories = true;
    }else{
      this.AllAccessories = false;
    }
  }

}

function addRemoveCondition(item, FgOperative) {
  let Index = this.arrIdsAccessories.findIndex(element => element.TpCargoAccesoryId == item.TpCargoAccesoryId);
  if (FgOperative!='') {
    if (Index != -1) {
      this.arrIdsAccessories[Index].FgOperative = FgOperative;
    }else{
      this.arrIdsAccessories.push({
        TpCargoAccesoryId: item.TpCargoAccesoryId, 
        TpCargoAccesoryNameEn: item.TpCargoAccesoryNameEn,
        TpCargoAccesoryNameEs: item.TpCargoAccesoryNameEs,
        FgOperative: FgOperative, 
        Observation: ''
      });
    }
  }else if (Index != -1) {
    this.arrIdsAccessories[Index].FgOperative = FgOperative;
  }
}

function addRemoveObservation(item, Observation) {
  let Index = this.arrIdsAccessories.findIndex(element => element.TpCargoAccesoryId == item.TpCargoAccesoryId);
  if (Observation) {
    if (Index != -1) {
      this.arrIdsAccessories[Index].Observation = Observation;
    }else{
      this.arrIdsAccessories.push({
        TpCargoAccesoryId: item.TpCargoAccesoryId, 
        TpCargoAccesoryNameEn: item.TpCargoAccesoryNameEn,
        TpCargoAccesoryNameEs: item.TpCargoAccesoryNameEs,
        FgOperative: '', 
        Observation: Observation
      });
    }
  }else if (Index != -1) {
    this.arrIdsAccessories[Index].Observation = Observation;
  }
}

async function getListApis() {
  this.Loading = true;
  let YardCargoId = this.ContainerItem?.YardCargoId ?? this.dataContainer?.YardCargoId ?? '';
  let YardId = this.ContainerItem?.YardId ?? this.dataContainer?.YardId ?? '';
  let requests =  [
    this.$http.ejecutar("GET", "InfoYardCargo-by-id", {YardCargoId: YardCargoId}),
    this.$http.ejecutar("GET", "YardClientEir-list"),
    this.$http.ejecutar("GET", "ClientCarrier-list-by-CompanyBranchId", { TpClientId: process.env.VUE_APP_TP_CLIENT_LAND_TRANSPORT,  CompanyBranchId: this.branch.CompanyBranchId, Filter: 'ACTIVO' }),
    this.$http.ejecutar("GET", "YardChecker-list", { YardId: YardId}),
    this.$http.ejecutar("GET", "EirContainerCondition-list"),
  ];
  if (this.InfoYardItems?.YardCargoEirId) {
    requests.push(this.$http.get('YardCargoEir-by-Id?',{ YardCargoEirId: this.InfoYardItems.YardCargoEirId}));
  }
  await Promise.all(requests)
    .then(async(responses) => {
      this.InfoYardItems = responses[0].data.data&&responses[0].data.data[0] ? responses[0].data.data[0] : {};
      this.clientItems = responses[1].data.data ? responses[1].data.data : [];
      this.TransportList = responses[2].data.data ? responses[2].data.data : [];
      this.CheckerList = responses[3].data.data ? responses[3].data.data : [];
      this.ContainerCondictionList = responses[4].data.data ? responses[4].data.data : [];
      if (this.InfoYardItems?.YardCargoEirId) {
        this.itemsEirReport =  responses[5].data.data ? responses[5].data.data : [];
      }else{
        this.itemsEirReport = [];
      }
      await this.getData(this.InfoYardItems);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    })
}

function SelectedInfo(info, Type) {
  if (Type == 1) {
    let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
    this.SearchPlate = info.LicensePlate;
    this.VehicleId = info.VehicleId;
    this.VehicleName = `${info['TpVehicleName'+_lang] ?? 'N/A'} - ${info['VehicleClassifName'+_lang] ?? 'N/A'}`; 
    this.objectVehicle = info;
  }else{
    this.numberIdLetter = info.DriverCi?.charAt(0) ?? '';
    this.SearchDriver = info?.DriverCi?.slice(1) ?? '';
    this.DriverId = info.DriverId;
    this.DriverName = info.DriverName;
    this.diverSelected = info;
  }
}
//Computeds
function clientOptions () {
  if(this.clientItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.clientItems.map(function(e){
      chart.push({
        value: e.ClientId, 
        label: e.ClientName,
      }) 
    })
    return chart;
  }
}

  
function formatedItemsSpecials() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.AccesoriesList.map((item, index) => {
    let Accessory = this.arrIdsAccessories.find((e) => e.TpCargoAccesoryId == item.TpCargoAccesoryId);
    return {
      ...item,
      Nro: index+1,
      TpCargoAccesoryName: item[`TpCargoAccesoryName${_lang}`] ?? '',
      checked: Accessory ? true : false,
      FgOperative: Accessory?.FgOperative ?? '',
      Observation: Accessory?.Observation ?? '',
    };
  })
}

function fieldsSpecials() {
  return [
    { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
    { key: "TpCargoAccesoryName", label: this.$t("label.accesory"),_styles: "width: 25%;",_classes:'text-center',},
    { key: 'select',label: this.$t("label.condition"),sorter: false, filter: false,_style:'width:25%;', _classes:'text-center'},
    { key: 'input',label: this.$t("label.observation"),sorter: false, filter: false,_style:'width:50%;', _classes:'text-center'},
  ];
}
  
function TransactionOptions() {
  if(this.TransactionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.TransactionList.map(function(e){
      chart.push({
        value: e.YardStatusSuggestTransactionId,
        label: _lang=='en'? e.TpTransacEirNameEn||'' : e.TpTransacEirNameEs||'',
      })
    })
    return chart;
  }
}
  
function TransportOptions() {
  if(this.TransportList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.TransportList.map(function(e){
      chart.push({
        value: e.ClientTpId,
        label: e.ClientAlias,
      })
    })
    return chart;
  }
}

function checkerOptions() {
  if(this.CheckerList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.CheckerList.map(function(e){
      chart.push({
        value: e.OperatorName,
        label: e.OperatorName,
      })
    })
    return chart;
  }
}

function ContainerCondictionOptions() {
  if(this.ContainerCondictionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale?.charAt(0)?.toUpperCase() + this.$i18n.locale?.slice(1) ?? '';
    this.ContainerCondictionList.map(function(e){
      chart.push({
        value: e.EirContainerCondictionId,
        label: e['ContainerConditionName'+_lang] ?? '',
      })
    })
    return chart;
  }
}

function fieldsSeals(){
  return [
    { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 30%;', _classes: 'text-center',},
    { key: 'SealAssignmentName',label: this.$t('label.AssignedBy'), _style:'width: 30%;', _classes: 'text-center',},
    { key: 'SealConditionName',label: this.$t('label.condition'), _style:'width: 30%;', _classes: 'text-center',},
  ];
}

function formatedItemsSeals() {
  if(this.SealList[0]){
    let SealArray = this.SealList[0]?.Seal1Json.concat(this.SealList[0]?.Seal2Json, this.SealList[0]?.Seal3Json, this.SealList[0]?.Seal4Json);
    return SealArray.map((item) => {
      let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      return {
        ...item,
        SealAssignmentName: item[`SealAssignmentName${_lang}`] ?? 'N/A',
        SealConditionName: item[`SealConditionName${_lang}`] ?? 'N/A',
      }
    });
  }else{
    return [];
  }
}

function fieldsDamageInspection(){
  return [
    { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
    { key: 'TpDamageName', label:this.$t('label.damageType'), _style: 'width:15%;', _classes: 'text-center'},
    { key: 'TpCargoViewName', label:this.$t('label.localizacion'),_style: 'width:15%;', _classes: 'text-center' },
    { key: 'Height', label:this.$t('label.height')+'(CM)',_style: 'width:15%;', _classes: 'text-center' },
    { key: 'Width', label:this.$t('label.width')+'(CM)',_style: 'width:15%;', _classes: 'text-center' },
    { key: 'Dimension', label:this.$t('label.dimensions'),_style: 'width:15%;', _classes: 'text-center' },
    { key: 'Responsible', label:this.$t('label.responsible'), _style: 'width:15%;', _classes: 'text-center'},
  ];
}

function formatedItemsDamageInspection() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.DamageList.map((item, index) => {
    let checkeds = this.arrIdsDamage.filter((e) => e.VisitCargoInspDamageId == item.VisitCargoInspDamageId);
    return {
      ...item,
      Nro: index+1,
      TpDamageName: item[`TpDamageName${_lang}`] ?? '',
      TpCargoViewName: item[`TpCargoViewName${_lang}`] ?? '',
      Dimension: item.Width&&item.Height ? `${item.Width} x ${item.Height}` : '',
      TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaLogin: item.TransaLogin ? item.TransaLogin : '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): '',
      checked: (checkeds.length > 0),
    };
  })
}

function CiTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
    {
      value: "J",
      label: "J",
    },
  ];
}

function FgOperativeOptions() {
  return [
    { value:'', label: this.$t('label.select')},
    { value: 0, label: this.$t('label.Inoperative')},
    { value: 1, label: this.$t('label.operative')},
  ]
}

function computedSelectObservation() {
  if (this.ObservationUser === null||this.ObservationUser === '') {
    return false;
  } else {
    return true;
  }
}

function ValidateEventReception() {
  return process.env.VUE_APP_EVENT_RECEPTION == this.InfoYardItems?.EventId
}

function TitleVehicle() {
  if (this.isEditVehicle) {
    return `${this.$t('label.edit')} ${this.$t('label.vehicle')}: ${this.objectVehicle.LicensePlate}`
  } else {
    return `${this.$t('label.nuevo')} ${this.$t('label.vehicle')}`
  }
}

function TitleDriver() {
  if (this.isEditDriver) {
    return `${this.$t('label.edit')} ${this.$t('label.driver')}: ${this.diverSelected.DriverCi}`
  } else {
    return `${this.$t('label.nuevo')} ${this.$t('label.driver')}`
  }
}

export default {
  name: 'modal-bl-container',
  components: {
    CustomTabs,
    CustomTab,
    SealsForm,
    DamageForm,
    VueEditor,
    CollapseDriver,
    CollapseVehicle,
    CollapseVehicleOrDriver,
   },
  props: { 
    modal: Boolean, 
    ContainerItem: Object, 
    title: String,  
  },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){ return eirValidations(this.ValidPreviousDate, this.ValidLaterDate)},
  directives: UpperCase,
  methods: {
    submitBody,
    handleTab,
    getData,
    validateAccessory,
    resetForm,
    statusSelectColor,
    printPdf,
    getPrintPdf,
    validateDateRange,
    getDateRange,
    functionDate,
    getDriverByCi,
    getVehicleByLicensePlate,
    getDriver,
    getVehicle,
    selectedAll,
    addRemoveId,
    addRemoveCondition,
    addRemoveObservation,
    getListApis,
    SelectedInfo,
  },
  computed: {
    clientOptions,
    getBranchId,
    fieldsSpecials,
    TransactionOptions,
    TransportOptions, 
    checkerOptions,
    ContainerCondictionOptions,
    fieldsSeals,
    formatedItemsSeals,
    fieldsDamageInspection,
    formatedItemsDamageInspection,
    CiTypeOptions,
    formatedItemsSpecials,
    FgOperativeOptions,
    computedSelectObservation,
    ValidateEventReception,
    TitleVehicle,
    TitleDriver,
    ...mapState({
      branch: (state) => state.auth.branch,
      data: (state) => state.auth,
      loading: state => state.yardManagement.loading,
      dataContainer: state => state.yardManagement.dataContainer,
    })

  },
  watch: {
    modal: async function (val) {
      this.modalActive = val;
      if(val){
        await this.getListApis();
      }
    },
    CarrierId: function (Newval) {
      if (Newval) {
        let data = this.TransportList.find((item) => item.ClientTpId == this.CarrierId);
        this.TransportName = data?.ClientAlias ?? '';
      }else{
        this.TransportName = '';
      }
    },
    ClientId: function (Newval) {
      if (Newval) {
        let data = this.clientItems.find((item) => item.ClientId == this.ClientId);
        this.ClientName = data?.ClientName ?? '';
      }else{
        this.ClientName = '';
      }
    },
    FormatedDate: function (Newval) {
      try{
        if(Newval){
          if (DateFormater.formatDateTimeWithoutSlash(Newval) < this.ValidPreviousDate && this.ValidateEventReception) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(Newval) < this.ValidPreviousDate && !this.ValidateEventReception) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfDispatch');
          }
          if (DateFormater.formatDateTimeWithoutSlash(Newval) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(Error){
        this.notifyError({text: Error});
      }
    }
  },
 
};
</script>
<style lang="scss">

.select-description--correct {
    .ql-container{
        border-color: #2eb85c !important;
    }
    .ql-toolbar{
        border-color: #2eb85c !important;
    }
}
.select-description--error {
    .ql-container {
        border-color: #e55353 !important;
    }
    .ql-toolbar{
        border-color: #e55353 !important;
    }
}
.text-editor{
   font-size: 11.2px !important;
}

.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}

.tab-error .custom-tab-link {
  background: #e1373F;
  border-color: #e1373F !important;
}

.buttonClassConsignee{
  height: 90% !important;
  position: relative;
}

.buttonClassDriver{
  height: 88% !important;
  position: relative;
}


</style>