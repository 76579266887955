<template>
  <div style="width: 100%">
    <div class="d-flex align-content-center position-relative">
      <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
        <a
          href="#" 
          @click.prevent="disminuirCarrusel"
          class="arrow left"
        ></a>
      </div>
      <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
        <div
          class="card-header"
          :style="
            'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
          "
        >
          <span>{{ `BL'S` }}</span>
        </div>
        <div class="card-body">
          <CRow class="m-0">
            <CCol sm="12" :lg="BlJson[IndexChart-1] ? 6 : 12" v-if="BlJson[IndexChart-2]" class="px-1 m-0">
              <div class="card-chart row m-0" style="height: 100%;">
                <div class="card-bl">
                  <div>
                    <div class="mb-2">
                      <div class="text-underline">NRO BL:</div>
                      <div>{{ BlJson[IndexChart-2].NroBl }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.commodity') }}:</div>
                      <div>{{ CommodityName(BlJson[IndexChart-2]) }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.client') }}:</div>
                      <div>{{ BlJson[IndexChart-2].ClientName }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.portActivity') }}:</div>
                      <div>{{ PortActivityName(BlJson[IndexChart-2]) }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.weight') }}:</div>
                      <div>{{ FormatNumber(BlJson[IndexChart-2].Weight, 2) }} KGM</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.volume') }}:</div>
                      <div>{{ FormatNumber(BlJson[IndexChart-2].Volumen, 2) }} M³</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.DescriptionOfGoods') }}:</div>
                      <div>{{ DescriptionOfGoods(BlJson[IndexChart-2].DescriptionOfGoods) }}</div>
                    </div>
                  </div>
                  <div style="height: 60px;">
                    <CIcon name="general_Cargo" style="height: 100%; width: 100%;"/>
                  </div>
                </div>
                <div class="chart-width">
                  <div class="chart-height">
                    <BarVerticalApexChart
                      Width="100%"
                      Height="150"
                      :Series="SeriesBl(BlJson[IndexChart-2])"
                      :Categories="LabelBl(BlJson[IndexChart-2])"
                      :Tooltip="Tooltip"
                      :DataLabels="DataLabels"
                      :PlotOptions="PlotOptions"
                      :Yaxis="Yaxis"
                      :Colors="['#1A3760', '#EC631B']"
                      :Xaxis="false"
                      :Legend="Legend"
                      :Max="MaxNumber(BlJson[IndexChart-2])"
                    />
                  </div>
                  <div class="pt-1 px-3 pb-3 block-height">
                    <div class="block-white">
                      <div style="width: 100%;">
                        <BarVerticalApexChart
                          v-for="Packaging in BlJson[IndexChart-2]?.PackagingJson"
                          :key="Packaging.VisitBillOfLadingId"
                          Width="100%"
                          :Height="'110'"
                          :Series="SeriesPackaging(Packaging)"
                          :Categories="LabelPackaging(Packaging)"
                          :Tooltip="Tooltip"
                          :DataLabels="DataLabelsPackaging"
                          :PlotOptions="PlotOptions"
                          :Yaxis="YaxisPackaging"
                          :Colors="[Packaging.Color, Packaging.Color+'4d']"
                          :Xaxis="false"
                          :Legend="Legend"
                          :Max="MaxNumber(Packaging)"
                        />
                      </div>
                    </div>
                  </div>
                </div>             
              </div>
            </CCol>
            <CCol sm="12" lg="6" v-if="BlJson[IndexChart-1]" class="px-1 m-0">
              <div class="card-chart row m-0" style="height: 100%;">
                <div class="card-bl">
                  <div>
                    <div class="mb-2">
                      <div class="text-underline">NRO BL:</div>
                      <div>{{ BlJson[IndexChart-1].NroBl }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.commodity') }}:</div>
                      <div>{{ CommodityName(BlJson[IndexChart-1]) }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.client') }}:</div>
                      <div>{{ BlJson[IndexChart-1].ClientName }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.portActivity') }}:</div>
                      <div>{{ PortActivityName(BlJson[IndexChart-1]) }}</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.weight') }}:</div>
                      <div>{{ FormatNumber(BlJson[IndexChart-1].Weight, 2) }} KGM</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.volume') }}:</div>
                      <div>{{ FormatNumber(BlJson[IndexChart-1].Volumen, 2) }} M³</div>
                    </div>
                    <div class="mb-2">
                      <div class="text-underline">{{ $t('label.DescriptionOfGoods') }}:</div>
                      <div>{{ DescriptionOfGoods(BlJson[IndexChart-1].DescriptionOfGoods) }}</div>
                    </div>
                  </div>
                  <div style="height: 60px;">
                    <CIcon name="general_Cargo" style="height: 100%; width: 100%;"/>
                  </div>
                </div>
                <div class="chart-width">
                  <div class="chart-height">
                    <BarVerticalApexChart
                      Width="100%"
                      Height="150"
                      :Series="SeriesBl(BlJson[IndexChart-1])"
                      :Categories="LabelBl(BlJson[IndexChart-1])"
                      :Tooltip="Tooltip"
                      :DataLabels="DataLabels"
                      :PlotOptions="PlotOptions"
                      :Yaxis="Yaxis"
                      :Colors="['#1A3760', '#EC631B']"
                      :Xaxis="false"
                      :Legend="Legend"
                      :Max="MaxNumber(BlJson[IndexChart-1])"
                    />
                  </div>
                  <div class="pt-1 px-3 pb-3 block-height">
                    <div class="block-white">
                      <div style="width: 100%;">
                        <BarVerticalApexChart
                          v-for="Packaging in BlJson[IndexChart-1]?.PackagingJson"
                          :key="Packaging.VisitBillOfLadingId"
                          Width="100%"
                          :Height="'110'"
                          :Series="SeriesPackaging(Packaging)"
                          :Categories="LabelPackaging(Packaging)"
                          :Tooltip="Tooltip"
                          :DataLabels="DataLabelsPackaging"
                          :PlotOptions="PlotOptions"
                          :Yaxis="YaxisPackaging"
                          :Colors="[Packaging.Color, Packaging.Color+'4d']"
                          :Xaxis="false"
                          :Legend="Legend"
                          :Max="MaxNumber(Packaging)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
      <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
        <a
          href="#" 
          @click.prevent="aumentarCarrusel"
          class="arrow right"
        ></a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { trimRemoveSpecialCharacters, removeTags, NumberFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import BarVerticalApexChart from "../../components/barVerticalApexChart";
import { Icon } from 'leaflet';

//Data
function data() {
  return {
    interval: null,
    Tooltip: {
      y: {
        formatter: (Value) => {
          if (Value) {
            return formatMilDecimal(Value);
          }else{
            return 0
          }
        }
      }
    },
    DataLabels: {
      enabled: true,
      offsetX: 35,
      style: {
        fontSize: '12px',
        colors: ['#000000']
      },
      formatter: (Value) => {
        if (Value) {
          return formatMilDecimal(Value);
        }else{
          return 0
        }
      }
    },
    DataLabelsPackaging: {
      enabled: true,
      offsetX: 35,
      style: {
        fontSize: '12px',
        colors: ['#000000']
      },
      formatter: (Value) => {
        if (Value) {
          return formatMilDecimal(Value);
        }else{
          return 0
        }
      }
    },
    Yaxis: {
      show: false,
      showAlways: false,
      axisBorder: {
        show: false,
        color: '#fff',
      },
      axisTicks: {
        show: false,
        color: '#fff',
      },
      labels: {
        show: false,
        minWidth: 0,
        maxWidth: 0,
      },
      crosshairs: {
        show: false,
      }
    },
    YaxisPackaging:{
      show: true,
      showAlways: false,
      axisBorder: {
        show: false,
        color: '#fff',
      },
      axisTicks: {
        show: false,
        color: '#fff',
      },
      labels: {
        show: true,
        minWidth: 120,
        maxWidth: 120,
      },
      crosshairs: {
        show: false,
      }
    },
    PlotOptions: {
      dataLabels: {
        position: 'top',
      },
    },
    Legend: {
      show: true,
    }
  }
}

// Methods
function CommodityName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['CommodityName'+_lang] ?? '';
}

function PortActivityName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['PortActivityName'+_lang] ?? '';
}

function FormatNumber(Number, Decimal) {
  return NumberFormater.formatNumber(Number, Decimal);
}

function DescriptionOfGoods(DescriptionOfGoods) {
  if (DescriptionOfGoods) {
    return DescriptionOfGoods.length > 20 ? trimRemoveSpecialCharacters(DescriptionOfGoods, 23) : removeTags(DescriptionOfGoods);
  }else{
    return ''
  }
}

function SeriesBl(item) {
  let Data = [
    {
      name: this.$t('label.planned'),
      data: [item.QuantityPlanning ?? 0],
    },
    {
      name: this.$t('label.executed'),
      data: [item.QuantityExecuted ?? 0],
    },
  ];
  return Data;
}

function LabelBl(item) {
  return [item.NroBl ?? ''];
}

function SeriesPackaging(item) {
  let Data = [
    {
      name: this.$t('label.planned'),
      data:  [item.QuantityPlanning ?? 0],
    },
    {
      name: this.$t('label.executed'),
      data: [item.QuantityExecuted ?? 0],
    },
  ];
  return Data;
}

function LabelPackaging(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [item['PackagingName'+_lang] ?? ''];
}

function MaxNumber(item) {
  return item.QuantityPlanning > item.QuantityExecuted ? item.QuantityPlanning : item.QuantityExecuted;
}

function disminuirCarrusel(){
  if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
  }
}

function aumentarCarrusel(){
  if(this.$store.state.situacionOperativaActual.carruselTab !== (this.BlJson.length != 0 ? Math.ceil(this.BlJson.length/2) : 1)){
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
  }
}

//computed
function BlJson() {
  return this.OperSituationToday?.BlJson ?? [];
}

function IndexChart() {
  return this.carruselTab*2;
}

function minCarrusel(){
  return this.$store.state.situacionOperativaActual.carruselTab > 1;
}
function maxCarrusel(){
  return this.$store.state.situacionOperativaActual.carruselTab < (this.BlJson.length != 0 ? Math.ceil(this.BlJson.length/2) : 1);
}


export default {
  name: "bls",
  props: {
    OperSituationToday: {
      type: Object,
      default: function () {
        return {};
      },
    }
  },
  components: {
    BarVerticalApexChart,
    Icon
},
  data,
  methods: {
    CommodityName,
    PortActivityName,
    FormatNumber,
    DescriptionOfGoods,
    SeriesBl,
    LabelBl,
    SeriesPackaging,
    LabelPackaging,
    MaxNumber,
    disminuirCarrusel,
    aumentarCarrusel,
    _setInterval: function() {
      this.interval = setInterval(() => {
        if(this.$store.state.situacionOperativaActual.carruselTab !== 4){
          this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }else{
          this.$store.state.situacionOperativaActual.carruselTab = 1;
        }   
      }, 60000);
    }
  },
  computed: {
    BlJson,
    IndexChart,
    minCarrusel,
    maxCarrusel,
    ...mapState({
      carruselTab : state=> state.situacionOperativaActual.carruselTab,
      tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
      carrusel: state => state.situacionOperativaActual.carrusel,
      FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
      FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
    })
  },
};
</script>
<style scoped>
.card-chart {
  border-radius: 5px;
  background: #e8f0fa;
}
.chart-width {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.chart-height {
  width: 100%;
  min-height: 125px !important;
}
.card-bl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  min-width: 30%;
  min-height: 100%;
  border-radius: 5px 0 0 5px;
  background-image: url('/situacionOperativa/chatarra/fondo2.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  color: #ffffff;
  padding: 5px;
}
.text-underline{
  text-decoration: underline;
}
.block-white {
  background: #5a5a5a4d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.block-height {
  width: 100%;
  flex: auto;
  min-height: 173px !important;
}
</style>