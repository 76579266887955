<template>
  <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
    <div
      class="card-header"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ `${$t("label.SituationPackaging")} - ${$t('label.LOAD')}` }}</span>
    </div>
    <div class="card-body">
      <CRow class="m-0">
        <div class="block-main">
          <div class="block-table">
            <CRow class="m-0">

              <div class="block-discharge" >
                <div class="p-1 title-height">
                  <div class="py-1 title-Block text-center">{{ $t('label.LOAD') }}</div>
                </div>
              </div>
            </CRow>
            <CRow class="m-0">
              <div class="p-1 td-Packaging">
                <div class="py-1 title-packaging text-center">{{ $t('label.packaging') }}</div>
              </div>
							<div class="p-1 td-load">
								<div class="py-1 title-planned text-center">{{ $t('label.planned') }}</div>
							</div>
							<div class="p-1 td-load">
								<div class="py-1 title-executed text-center">{{ $t('label.executed') }}</div>
							</div>
							<div class="p-1 td-load">
								<div class="py-1 title-remaing text-center">{{ $t('label.Remaing') }}</div>
							</div>
            </CRow>
            <CRow v-for="Packaging in LoadJson" class="m-0">
              <div class="p-1 td-Packaging d-flex align-items-center">
                <div class="py-1 text-center w-100" :class="!Packaging.PackagingId ? 'font-weight-bold': ''">
                  {{ getPackagingName(Packaging) }}
                </div>
              </div>
              <div class="p-1 d-flex align-items-center td-load">
                <div class="py-1 text-center w-100" :class="!Packaging.PackagingId ? 'font-weight-bold total': ''">
                  {{ FormatNumber(Packaging.QuantityPlanningLoad) }}
                </div>
              </div>
              <div class="p-1 d-flex align-items-center td-load">
                <div class="py-1 text-center w-100" :class="!Packaging.PackagingId ? 'font-weight-bold total': ''">
                  {{ FormatNumber(Packaging.QuantityExecutedLoad) }}
                </div>
              </div>
              <div class="p-1 d-flex align-items-center td-load">
                <div class="py-1 text-center w-100" :class="!Packaging.PackagingId ? 'font-weight-bold total': ''">
                  {{ FormatNumber(Packaging.QuantityRemainingLoad) }}
                </div>
              </div>
            </CRow>
          </div>
        </div>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//Data
function data() {
  return {
  }
}

// Methods
function getPackagingName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['PackagingName'+_lang] ?? '';
}

function FormatNumber(Number) {
  if (Number) {
    return formatMilDecimal(Number);
  } else {
    return 0;
  }
}

export default {
  name: "situation-per-packaging-load",
  props: {
    LoadJson: {
      type: Array,
      default: () => [],
    }
  },
  components: {

  },
  data,
  methods: {
    getPackagingName,
    FormatNumber,
  },
  computed: {
    ...mapState({
      FgContainerShip: state => state.situacionOperativaHistorico.FgContainerShip,
      FgGeneralCargo: state => state.situacionOperativaHistorico.FgGeneralCargo,
    })
  },
};
</script>
<style scoped>
.block-main{
  width: 100%;
  display: flex;
  overflow-x: auto;
}
.block-discharge{
  width: 100%;
  height: 100%;
}
.title-height{
  width: 100%;
  height: 38px !important;
}
.title-Block{
  font-size: larger;
  font-weight: bold;
}
.title-packaging{
 background: #d0cece;
 font-weight: 500;
}
.title-planned{
  background: #70ad47;
  font-weight: 500;
  color: #ffffff;
}
.title-executed {
  background: #2f5597;
  font-weight: 500;
  color: #ffffff;
}
.title-remaing {
  background: #ffc000;
  font-weight: 500;
  color: #ffffff;
}
.block-table {
  width: 100%;
  min-width: 1147px !important;
  background: #f2f2f2 !important; 
  z-index: 2;
}
.w-100 {
  width: 100%;
}
.td-Packaging {
  width: 25%;
}
.td-load {
  width: 25%;
}
.border-left {
  border-left: 1px #9ea1a5 solid !important;
}
.font-weight-bold {
  font-weight: bold;
}
.total {
  background: #d0cece;
}
</style>