import { required, maxLength } from "vuelidate/lib/validators";
import { onlyMayusText, credentials2 } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        VisitResourceId: { required },
        StowageRoleId: { required },
        NumberId: { required/*, credentials2*/ },
        Name: { required, onlyMayusText, maxLength: maxLength(250) },
        LastName: { required, onlyMayusText, maxLength: maxLength(250) },
    }
}

