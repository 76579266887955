<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasVesselInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf" v-for="(page, index) in Condition">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="vessel mb-3">
                  <table style="border: none;">
                    <tbody>
                      <tr>
                        <td :class="`${FgGeneralCargo ? 'limit-field-33' : 'limit-field-25'}`">
                          <strong>LOA (MTR):</strong>
                          {{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td :class="`${FgGeneralCargo ? 'limit-field-33' : 'limit-field-25'}`">
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td :class="`${FgGeneralCargo ? 'limit-field-33' : 'limit-field-25'}`">
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                        <td v-if="!FgGeneralCargo" class="limit-field-25">
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                      </tr>
                      <tr v-if="!FgGeneralCargo">
                        <td class="limit-field-25">
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                        <td class="limit-field-25">
                          <strong>POL: </strong
                          >{{ getDato(vessel[0].PortOriginCode) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>POD: </strong
                          >{{ getDato(vessel[0].PortDestinationCode) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.StowageFactor") }}: </strong
                          >{{ formatNumber(getDato(vessel[0].StowageFactor), 4) }}
                        </td>  
                      </tr>
                      <tr v-else>
                        <td>
                          <strong>{{ $t("label.vesselType") }}: </strong
                          >{{ getDato(vessel[0].TpVesselName) }}
                        </td> 
                        <td>
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td>
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center" v-if="FgGeneralCargo">
                  <h4>{{ $t('label.stowagePlan') }}</h4>
                </div>
              </div>
              
              <div class="transversal-view">
                <transversal-view :billOfLading="positions" pdf manifestPdf :VesselType="TpVesselId"/>
              </div>
              <div class="holds-info">
                <table class="holds-table">
                  <thead>
                    <tr>
                      <th colspan="1" :style="`width: ${ValidateGeneralCargoVessel ? '33%' : '25%'}`"></th>
                      <th :colspan="ValidateGeneralCargoVessel ? 1 : 2" :style="`width: ${ValidateGeneralCargoVessel ? '33%' : '50%'}`" class="header-bg text-center">
                        {{ $t('label.PermissibleLoanOn') }}
                      </th>
                      <th colspan="1" :style="`width: ${ValidateGeneralCargoVessel ? '33%' : '25%'}`" class="header-bg text-center">
                        {{ $t('label.CapacityEstimated') }}
                      </th>
                    </tr>
                    <tr class="text-table-right">
                      <th class="text-table-left">{{ValidateGeneralCargoVessel ? $t('label.location') : $t('label.hold')}}</th>
                      <th>{{`${ValidateGeneralCargoVessel ? $t('label.volume') : $t('label.bale')} M³`}}</th>
                      <th v-if="!ValidateGeneralCargoVessel">{{$t('label.bale')+' '+$t("label.metricTonnageAcronym")}}</th>
                      <th>{{$t('label.Loaded')+' '+$t("label.metricTonnageAcronym")}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="hold in holds" :key="hold.VesselHoldId">
                      <td class="text-table-left">{{ hold.VesselHoldName }}</td>
                      <td>{{ ValidateGeneralCargoVessel ? formatNumber(hold.Volumen, 2) : formatNumber(hold.BaleM3, 2) }}</td>
                      <td v-if="!ValidateGeneralCargoVessel">{{ formatNumber(hold.BaleTon, 2) }}</td>
                      <td>{{ formatNumber(hold.OccupiedTon, 2) }}</td>
                    </tr>
                    <tr v-if="ValidateGeneralCargoVessel" v-for="HatchCover in DeckJson.HatchCoverJson">
                      <td class="text-table-left">{{ HatchCover.HatchCoverName }}</td>
                      <td>{{ formatNumber(0, 2) }}</td>
                      <td>{{ formatNumber(HatchCover.Total, 2) }}</td>
                    </tr>
                    <tr v-if="ValidateGeneralCargoVessel && DeckJson?.BlDeckJson && DeckJson?.BlDeckJson.length != 0">
                      <td class="text-table-left">{{ $t('label.deck') }}</td>
                      <td>{{ formatNumber(0, 2) }}</td>
                      <td>{{ formatNumber(DectTotal, 2) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-table-left">TOTAL</td>
                      <td>{{ ValidateGeneralCargoVessel ? getHoldsTotal.Volumen : getHoldsTotal.BaleM3 }}</td>
                      <td v-if="!ValidateGeneralCargoVessel">{{ getHoldsTotal.BaleTon }}</td>
                      <td>{{ ValidateGeneralCargoVessel ? formatNumber(setNum(getHoldsTotal.OccupiedTon)+HatchCoverTotal+DectTotal, 2) : getHoldsTotal.OccupiedTon }}</td>
                    </tr>
                  </tfoot>
                </table>

                <table class="departure-table">
                  <thead>
                    <tr>
                      <th colspan="2" class="header-bg text-center">
                        {{$t('label.departure')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CARGO</td>
                      <td>{{ ValidateGeneralCargoVessel ? formatNumber(departure[0]?.CargoTon+HatchCoverTotal+DectTotal, 2) : formatNumber(departure[0]?.CargoTon, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.ballast')}}</td>
                      <td>{{ formatNumber(departure[0]?.Ballast, 2) }}</td>
                    </tr>
                    <tr>
                      <td>FO</td>
                      <td>{{ formatNumber(departure[0]?.FuelOil, 2) }}</td>
                    </tr>
                    <tr>
                      <td>DO</td>
                      <td>{{ formatNumber(departure[0]?.DieselOil, 2) }}</td>
                    </tr>
                    <tr>
                      <td>FW</td>
                      <td>{{ formatNumber(departure[0]?.FreshWater, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.constant')}}</td>
                      <td>{{ formatNumber(departure[0]?.Constant, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.Deadweight')}}</td>
                      <td>{{ formatNumber(departure[0]?.DeadWedight, 2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="holds-info" v-if="!FgGeneralCargo">
                <table class="holds-table bl-table">
                  <thead>
                    <tr>
                      <th colspan="3" class="header-bg text-center">{{ $t("label.commodity") }}</th>
                      <th class="header-bg bl-same-width text-center">{{ $t("label.hold") }}</th>
                      <th class="header-bg bl-same-width text-center">{{ $t("label.quantity") + " (TON)" }}</th>
                      <th class="header-bg bl-same-width text-center">{{ $t("label.EstimatedBin") }}</th>
                      <th class="header-bg bl-same-width text-center">{{ $t("label.color") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(mercancia, Mercanciaindex) in commodity[index].index"
                      :key="`${mercancia.CommodityId}`"
                      class="text-table-left hold-commodity"
                    >
                      <td class="limit-field-30">
                        <strong>{{ $t("label.description") }}: </strong
                        >{{ mercancia.CommodityName }}
                      </td>
                      <td>
                        <strong
                          >{{ $t("label.quantity") + " (TON)" }}: </strong
                        >{{ formatNumber(mercancia.QuantityTon, 2) }}
                      </td>
                      <td><strong>{{'BL NRO:'}}</strong> #{{ mercancia.NroBl }}</td>
                      <td colspan="4" class="p-0">
                        <table class="bl-info" style="width: 100%">
                          <tbody v-if="(mercancia.CommodityHold != '' && mercancia.CommodityHold != null)">
                            <tr
                              v-for="blHold in mercancia.CommodityHold"
                              :key="`${blHold.StowagePlanningBlHoldId}`"
                            >
                              <td style="width: 25%">{{ blHold.VesselHoldName }}</td>
                              <td style="width: 25%">{{formatNumber(blHold.WeightAsigned, 2)}}</td>
                              <td style="width: 25%">{{formatNumber(blHold.EstimedBin, 2)}}</td>
                              <td style="width: 25%;">
                                <div class="d-flex justify-content-center">
                                  <div class="bl-color" :style="{ backgroundColor: blHold.Color}"></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td style="width: 25%"></td>
                              <td style="width: 25%"></td>
                              <td style="width: 25%"></td>
                              <td style="width: 25%"></td>
                            </tr>
                          </tbody>
                          <tfoot v-if="(mercancia.CommodityHold != '' && mercancia.CommodityHold != null)">
                            <tr>
                              <td class="text-table-left">TOTAL</td>
                              <td>{{ getBlHoldTotal(Mercanciaindex, page-1).WeightAsigned }}</td>
                              <td>{{ getBlHoldTotal(Mercanciaindex, page-1).EstimedBin }}</td>
                              <td>{{ }}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>

      <section class="pdf" v-if="FgGeneralCargo" v-for="(page, index) in BlJson.length">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="table-bl">
              <div class="info-vessel mb-3">
                <div class="vessel">
                  <table style="border: none;">
                    <tbody>
                      <tr>
                        <td>
                          <strong>LOA (MTR):</strong>
                          {{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td>
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td>
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t("label.vesselType") }}: </strong
                          >{{ getDato(vessel[0].TpVesselName) }}
                        </td> 
                        <td>
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td>
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-center">
                <h4>{{ $t('label.PlannedBlList') }}</h4>
              </div>
              <div class="holds-info">
                <table class="bl-table text-center">
                  <thead>
                    <tr>
                      <th style="width: 15.5%;" class="header-bg text-center">{{ $t("label.client") }}</th>
                      <th style="width: 12.5%;" class="header-bg text-center">{{ $t("label.commodity") }}</th>
                      <th style="width: 22.5%;" class="header-bg text-center">{{ $t("label.packaging") }}</th>
                      <th style="width: 12.5%;" class="header-bg text-center">BL NRO</th>
                      <th style="width: 12.5%;" class="header-bg text-center">DR</th>
                      <th style="width: 7.5%;" class="header-bg text-center">{{ $t("label.quantity") }}</th>
                      <th style="width: 8.5%;" class="header-bg text-center">{{ $t("label.weight") }} (TON)</th>
                      <th style="width: 8.5%;" class="header-bg text-center">{{ $t("label.volume") }} (M³)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(BL, Blindex) in BlJson[index]"
                      :key="`${BL.CommodityId+Blindex}`"
                    >
                      <td>
                        {{ getDataBl(BL.ClientName, 1) }}
                      </td>
                      <td>
                        {{ getDataBl(BL.CommodityName, 2)}}
                      </td>
                      <td class="packaging-table p-0">
                        <table style="width: 100%;" v-if="BL.ExternalPackagingJson&&BL.ExternalPackagingJson.length!=0">
                          <tr>
                            <th style="width: 70%;" class="header-bg text-center">{{ $t("label.type") }}</th>
                            <th style="width: 30%;" class="header-bg text-center">{{ $t("label.quantity") }}</th>
                          </tr>
                          <tr v-for="Packaging in BL.ExternalPackagingJson">
                            <td>{{ getPackaging(Packaging) }}</td>
                            <td>{{ formatNumber(Packaging.Quantity, 2) }}</td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        {{ BL.NroBl }}
                      </td>
                      <td>
                        {{ }}
                      </td>
                      <td>
                        {{ formatNumber(BL.Quantity, 2) }}
                      </td>
                      <td>
                        {{ formatNumber(BL.QuantityTon, 2) }}
                      </td>
                      <td>
                        {{ formatNumber(BL.Volumen, 2) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="BlJson.length == (index+1)">
                    <tr>
                      <td colspan="4"></td>
                      <td class="text-table-left">TOTAL</td>
                      <td>{{ formatNumber(PlannedBLTotal.Quantity, 2) }}</td>
                      <td>{{ formatNumber(PlannedBLTotal.QuantityTon, 2) }}</td>
                      <td>{{ formatNumber(PlannedBLTotal.Volumen, 2) }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page+1) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>

      <section class="pdf" v-if="FgGeneralCargo && commodity[0].index.length" v-for="(page, index) in commodity.length">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="table-bl">
              <div class="info-vessel mb-3">
                <div class="vessel">
                  <table style="border: none;">
                    <tbody>
                      <tr>
                        <td>
                          <strong>LOA (MTR):</strong>
                          {{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td>
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td>
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t("label.vesselType") }}: </strong
                          >{{ getDato(vessel[0].TpVesselName) }}
                        </td> 
                        <td>
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td>
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="text-center">
                <h4>{{ $t('label.DistributionInHold') }}</h4>
              </div>

              <div class="holds-info">
                <table class="bl-table text-center">
                  <thead>
                    <tr>
                      <th colspan="12" class="header-bg text-center">{{ $t("label.tonAssigned") }}</th>
                    </tr>
                    <tr>
                      <th style="width: 20%;" class="header-bg text-center">{{ $t("label.client") }}</th>
                      <th style="width: 20%;" class="header-bg text-center">{{ $t("label.commodity") }}</th>
                      <th style="width: 20%;" class="header-bg text-center">BL NRO</th>
                      <th style="width: 10%;" class="header-bg text-center">{{ $t("label.hold") }}</th>
                      <th style="width: 10%;" class="header-bg text-center">{{ $t("label.quantity") }}</th>
                      <th style="width: 10%;" class="header-bg text-center">{{ $t("label.weight") }} (TON)</th>
                      <th style="width: 10%;" class="header-bg text-center">{{ $t("label.color") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(BL, Blindex) in commodity[index].index"
                      :key="`${Blindex+BL.CommodityId}`"
                    >
                      <td>
                        {{ BL.ClientName ?? '' }}
                      </td>
                      <td>
                        {{ BL.CommodityName ?? '' }}
                      </td>
                      <td>
                        {{ BL.NroBl ?? '' }}
                      </td>
                      <td colspan="4" class="p-0">
                        <table class="bl-info" style="width: 100%">
                          <tbody v-if="(BL.CommodityHold && BL.CommodityHold.length != 0)">
                            <tr
                              v-for="(blHold, Holdindex) in BL.CommodityHold"
                              :key="blHold.StowagePlanningBlHoldId+Holdindex"
                            >
                              <td style="width: 25%">{{ blHold.VesselHoldName }}</td>
                              <td style="width: 25%">{{formatNumber(blHold.QuantityAsigned, 2)}}</td>
                              <td style="width: 25%">{{formatNumber(blHold.WeightAsigned, 2)}}</td>
                              <td style="width: 25%">
                                <div class="d-flex justify-content-center">
                                  <div class="bl-color" :style="{ backgroundColor: blHold.Color}"></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td style="width: 25%; height: 15.5px;"></td>
                              <td style="width: 25%; height: 15.5px;"></td>
                              <td style="width: 25%; height: 15.5px;"></td>
                              <td style="width: 25%; height: 15.5px;"></td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td class="text-table-left">TOTAL</td>
                              <td>{{ getBlHoldTotal(Blindex, page-1).QuantityAsigned }}</td>
                              <td>{{ getBlHoldTotal(Blindex, page-1).WeightAsigned }}</td>
                              <td>{{ }}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page+BlJson.length+1) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>

      <section class="pdf" v-if="FgGeneralCargo" v-for="(page, index) in HatchCoverJson.length">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="table-bl">
              <div class="info-vessel mb-3">
                <div class="vessel">
                  <table style="border: none;">
                    <tbody>
                      <tr>
                        <td>
                          <strong>LOA (MTR):</strong>
                          {{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td>
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td>
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t("label.vesselType") }}: </strong
                          >{{ getDato(vessel[0].TpVesselName) }}
                        </td> 
                        <td>
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td>
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-center">
                <h4>{{ $t('label.DistributionInDeckHold') }}</h4>
              </div>
              <div class="Deck-info">
                <div v-for="(HatchCover, indexHatchCover) in HatchCoverJson[index]" class="row m-0 align-items-center flex-column" :style="`${HatchCoverJson[index].length == 1 ? 'width: 100%;' : 'width: 50%;'}`">
                  <div v-if="indexHatchCover==0 && page==1" class="Hatch-cover-bow mb-3">
                    <img
                      :src="'/img/HATCH.png'"
                      width="100%"
                      height="100%"
                      @error="'/img/errorimage.jpg'"
                    />
                    <div class="HatchCover-center"><b>{{ HatchCover.HatchCoverName }} / {{ HatchCover.VesselHoldName }}</b></div>
                  </div>
                  
                  <div v-else class="Hatch-cover mb-3">
                    <div class="HatchCover-center"><b>{{ HatchCover.HatchCoverName }} / {{ HatchCover.VesselHoldName }}</b></div>
                  </div>
                  <div class="px-1" style="width: 100%; height: min-content;" v-if="HatchCover.BlDeckHatchCoverJson.length != 0">
                    <table class="bl-table text-center">
                      <thead>
                        <tr>
                          <th style="width: 25%;" class="header-bg">BL NRO</th>
                          <th style="width: 25%;" class="header-bg">{{ $t("label.packaging") }}</th>
                          <th style="width: 15%;" class="header-bg">{{ $t("label.hatchCover") }}</th>
                          <th style="width: 15%;" class="header-bg">{{ $t("label.quantity") }}</th>
                          <th style="width: 20%;" class="header-bg">{{ $t("label.weight") }} (TON)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="BlDeckHatchCover in HatchCover.BlDeckHatchCoverJson">
                          <td v-if="!BlDeckHatchCover?.FgTotal">{{ BlDeckHatchCover.NroBl }}</td>
                          <td v-if="!BlDeckHatchCover?.FgTotal">{{ getPackaging(BlDeckHatchCover) }}</td>
                          <td v-if="!BlDeckHatchCover?.FgTotal">{{ HatchCover.HatchCoverName }}</td>
                          <td v-if="BlDeckHatchCover?.FgTotal" colspan="2"></td>
                          <td v-if="BlDeckHatchCover?.FgTotal"><strong>TOTAL</strong></td>
                          <td :style="BlDeckHatchCover?.FgTotal ? 'font-weight: bold' : ''">{{ formatNumber(BlDeckHatchCover.QuantityAsigned, 2) }}</td>
                          <td :style="BlDeckHatchCover?.FgTotal ? 'font-weight: bold' : ''">{{ formatNumber(BlDeckHatchCover.WeightAsigned, 2) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page+commodity.length+BlJson.length+1) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>

      <section class="pdf" v-if="FgGeneralCargo" v-for="(page, index) in BlDeckJson.length">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="table-bl">
              <div class="info-vessel mb-3">
                <div class="vessel">
                  <table style="border: none;">
                    <tbody>
                      <tr>
                        <td>
                          <strong>LOA (MTR):</strong>
                          {{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td>
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td>
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t("label.vesselType") }}: </strong
                          >{{ getDato(vessel[0].TpVesselName) }}
                        </td> 
                        <td>
                          <strong>{{ $t("label.gross")+' (TON):' }} </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td>
                          <strong>{{$t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{formatNumber(getDato(vessel[0].FuelOilCapacity), 2)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-center">
                <h4>{{ $t('label.DistributionInDeck') }}</h4>
              </div>
              <div class="transversal-view mb-3">
                <img src="/img/BUQUES/general_cargo_deck.png" width="100%" height="100%"/>
              </div>
              <div style="width: 100%;">
                <table class="bl-table text-center">
                  <thead>
                    <tr>
                      <th style="width: 25%;" class="header-bg">BL NRO</th>
                      <th style="width: 25%;" class="header-bg">{{ $t("label.packaging") }}</th>
                      <th style="width: 15%;" class="header-bg">{{ $t("label.location") }}</th>
                      <th style="width: 15%;" class="header-bg">{{ $t("label.quantity") }}</th>
                      <th style="width: 20%;" class="header-bg">{{ $t("label.weight") }} (TON)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="BlDeck in BlDeckJson[index]">
                      <td v-if="!BlDeck?.FgTotal">{{ BlDeck?.NroBl }}</td>
                      <td v-if="!BlDeck?.FgTotal">{{ getPackaging(BlDeck) }}</td>
                      <td v-if="!BlDeck?.FgTotal">{{ getVesselUbicationName(BlDeck) }}</td>
                      <td v-if="BlDeck?.FgTotal" colspan="2"></td>
                      <td v-if="BlDeck?.FgTotal"><strong>TOTAL</strong></td>
                      <td :style="BlDeck?.FgTotal ? 'font-weight: bold' : ''">{{ formatNumber(BlDeck.QuantityAsigned, 2) }}</td>
                      <td :style="BlDeck?.FgTotal ? 'font-weight: bold' : ''">{{ formatNumber(BlDeck.WeightAsigned, 2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page+commodity.length+BlJson.length+HatchCoverJson.length+1) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransversalView from "@/pages/buque/bays/transversal-view";
import { DateFormater, NumberFormater, trimRemoveSpecialCharacters } from "@/_helpers/funciones";
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function getDato(value) {
  if (this.vessel.length == 0) return "";

  return value ? value : "";
}
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function getPackaging(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['PackagingName'+_lang];
}

function getDataBl(Data, Type) {
  if (Data && Data.trim().length > 42 && Type==1) {
    return trimRemoveSpecialCharacters(Data.trim(), 40);
  }else if(Data && Data.trim().length > 34 && Type==2){
    return trimRemoveSpecialCharacters(Data.trim(), 32);
  }else if(Data){
    return Data;
  }else{
    return '';
  }
}

function getVesselUbicationName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['VesselUbicationName'+_lang];
}

function setNum(Number) {
  return NumberFormater.setNum(Number);
}

//Computeds:
function hasVesselInfo() {
  return this.vessel.length == 1;
}

function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}

function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}

function footerText() {
  return this.$t("label.stowagePlan");
}

function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}

function titleFromItinerary() {
  if (this.vessel.length == 0) return "";
  let vesselName = this.vessel[0].VesselName,
    imo = this.vessel[0].Imo,
    voyage = this.vessel[0].Voyage,
    eta = this.vessel[0].Eta
      ? DateFormater.formatDateTimeWithSlash(this.vessel[0].Eta)
      : "N/A",
    etd = this.vessel[0].Etd
      ? DateFormater.formatDateTimeWithSlash(this.vessel[0].Etd)
      : "N/A";

  return `${vesselName} ${imo} / V:${voyage} - ETA:${eta} ETD:${etd}`;
}

function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

function totalPages() {
  return this.FgGeneralCargo ? 
    this.BlJson.length+this.commodity.length+this.HatchCoverJson.length+this.BlDeckJson.length+1 
    : this.Condition;
}

function getHoldsTotal() {
  let totals = {
    bale: 0,
    baleTon: 0,
    occupiedTon: 0,
    Volumen: 0,
  };
  if (this.holds.length == 0) return totals;

  this.holds.forEach(item => {
    totals.bale += item?.BaleM3 ?? 0;
    totals.baleTon += item?.BaleTon ?? 0;
    totals.occupiedTon += item?.OccupiedTon ?? 0;
    totals.Volumen += item?.Volumen ?? 0;
  })
  return {
    BaleM3: this.formatNumber(totals.bale, 2),
    BaleTon: this.formatNumber(totals.baleTon, 2),
    OccupiedTon: this.formatNumber(totals.occupiedTon, 2),
    Volumen: this.formatNumber(totals.Volumen, 2),
  };
}

function getBlHoldTotal(Position, page) {
  let totals = {
    WeightAsigned: 0,
    EstimedBin: 0,
    QuantityAsigned: 0,
  };

  if (this.commodity[page]?.index[Position]?.CommodityHold?.length == 0) return totals;

  for (let i = 0; i < this.commodity[page].index[Position].CommodityHold.length; i++) {
    totals.WeightAsigned += this.commodity[page].index[Position].CommodityHold[i].WeightAsigned;
    if (this.FgGeneralCargo) {
      totals.QuantityAsigned += this.commodity[page].index[Position].CommodityHold[i].QuantityAsigned;
    }else{
      totals.EstimedBin += this.commodity[page].index[Position].CommodityHold[i].EstimedBin;
    }
  }

  return {
    WeightAsigned: this.formatNumber(totals.WeightAsigned, 2),
    EstimedBin: this.formatNumber(totals.EstimedBin, 2),
    QuantityAsigned: this.formatNumber(totals.QuantityAsigned, 2),
  };
}

function ValidateGeneralCargoVessel() {
  return this.TpVesselId.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

function PlannedBLTotal() {
  let Total = {
    Quantity: 0,
    QuantityTon: 0,
    Volumen: 0,
  }
  if (this.BlJson.length != 0) {
    this.BlJson.forEach(BlList => {
      BlList.forEach(item => {
        Total.Quantity += item.Quantity;
        Total.QuantityTon += item.QuantityTon;
        Total.Volumen += item.Volumen;
      })
    })
  }
  return Total;
}

function HatchCoverTotal() {
  let Total = 0;
  this.DeckJson?.HatchCoverJson?.forEach(item => {
    Total += item.Total ?? 0
  })
  return Total;
}

function DectTotal() {
  let Total = 0;
  this.DeckJson?.BlDeckJson?.forEach(item => {
    Total += item.WeightAsigned ?? 0
  })
  return Total;
}

export default {
  name: "stowage-plan-view-pdf",
  props: {
    Condition: {
      type: Number,
      default: 1,
    },
    vessel: {
      type: Array,
      default: () => [],
    },
    TpVesselId: {
      type: String,
      default: () => '',
    },
    FgGeneralCargo: {
      type: Boolean,
      default: () => false,
    },
    commodity: {
      type: Array,
      default: () => [],
    },
    BlJson: {
      type: Array,
      default: () => [],
    },
    positions: {
      type: Array,
      default: () => [],
    },
    holds: {
      type: Array,
      default: () => [],
    },
    departure: {
      type: Array,
      default: () => [],
    },
    DeckJson: {
      type: Object,
      default: () => {},
    },
    BlDeckJson: {
      type: Array,
      default: () => [],
    },
    HatchCoverJson: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [],
  components: {
    TransversalView,
  },
  data,
  created,
  methods: {
    getDato,
    formatNumber,
    getBlHoldTotal,
    getPackaging,
    getDataBl,
    getVesselUbicationName,
    setNum,
  },
  computed: {
    footerText,
    currentDateTime,
    titleFromItinerary,
    hasLogo,
    hasVesselImg,
    loginName,
    hasVesselInfo,
    totalPages,
    getHoldsTotal,
    ValidateGeneralCargoVessel,
    PlannedBLTotal,
    HatchCoverTotal,
    DectTotal,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>

<style scoped src="../../archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.pdf .body .general-info .table-bl {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .table-bl .info-vessel,
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-bl .info-vessel .vessel,
.info-content .info-vessel .vessel {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.table-bl .info-vessel .vessel > table,
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px !important;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
}

.limit-field-20 {
  width: 20% !important;
}
.limit-field-25 {
  width: 25% !important;
}
.limit-field-30 {
  width: 32% !important;
}
.limit-field-33 {
  width: 33% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.transversal-view {
  /* border: 1px solid #000; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 10px; */
  overflow: hidden;
}
.Deck-info {
  width: 100%;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row-reverse;
}
.Hatch-cover-bow {
  width: 50%;
  height: 174px;
  position: relative;
}
.Hatch-cover {
  width: 65%;
  height: 174px;
  border: 2px solid;
  position: relative;
}

.HatchCover-center {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
}

/* *************************************************** */
/* Tablas de Info */
/* *************************************************** */
.holds-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.holds-table {
  width: 65%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.holds-table,
table.holds-table td,
table.holds-table th {
  border: 1px solid #000;
}
table.holds-table td,
table.holds-table th {
  padding: 0 5px;
}
table.holds-table td {
  text-align: right;
}
table.holds-table tfoot {
  font-weight: bold;
}

.text-table-left {
  text-align: left !important;
}
.text-table-right {
  text-align: right !important;
}
.header-bg {
  background-color: #e6e6e6;
}

.departure-table {
  width: 20%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.departure-table,
table.departure-table td,
table.departure-table th {
  border: 1px solid #000;
}
table.departure-table td,
table.departure-table th {
  padding: 0 5px;
}
table.departure-table td:nth-child(2) {
  text-align: right;
}
table.departure-table td:nth-child(1),
table.departure-table td:nth-child(2) {
  width: 50%;
}

.bl-table {
  width: 100% !important;
  font-size: 10px !important;
}
table.bl-table,
table.bl-table td,
table.bl-table th {
  border: 1px solid #000;
}
table.bl-table td,
table.bl-table th {
  padding: 0 5px;
}
table.bl-table tfoot {
  font-weight: bold;
}

.packaging-table th:nth-child(1), .packaging-table td:nth-child(1) {
  border-left: none;
  border-top: none;
}
.packaging-table th:nth-child(2), .packaging-table td:nth-child(2) {
  border-right: none;
  border-top: none;
}
.packaging-table tr:last-child td {
  border-bottom: none !important;
}
.hold-commodity td:nth-child(-n+4) {
  vertical-align: top;
  text-align: left;
}
.bl-info {
  min-height: 1px;
  height: 100%;
  font-size: 10px !important;
  border-collapse: collapse;
}
table.bl-info td {
  border: 1px solid #000;
  text-align: center !important;
  vertical-align: middle !important;
}
table.bl-info td:first-child {
  border-left: none !important;
}
table.bl-info td:last-child {
  border-right: none !important;
}
table.bl-info tr:first-child td {
  border-top: none;
}
table.bl-info tfoot tr:last-child td {
  border-bottom: none;
}
.bl-info .bl-color {
  min-width: 12px;
  min-height: 12px;
  border-radius: 100%;
}
.bl-same-width {
  width: 12%;
}
</style>