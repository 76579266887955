<template><!-- resto -->
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CCard class="card-cliente">
        <CCardHeader class="bg-dark text-white">
          <CRow>
            <CCol sm="10" class="d-flex mt-1">{{collapseTitle}}</CCol>
            <CCol sm="2">
              <CButton color="edit" size="sm" class="float-right" @click="tablaCollapse = !tablaCollapse" v-c-tooltip="{content: $t('label.dataTable')}">
                {{ cambiaTexto }}
                <CIcon :name="cambiaIcono"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
        <CRow>       
          <CCol sm="10">
            <CRow>
              <CCol sm="5" class="text-right mt-1 pr-1">
                <label><b class="text-danger">* </b>{{  $t('label.rifNumber') }}</label>
              </CCol>
              <CCol sm="2" class="px-1"><!-- rif -->
                <CSelect
                  v-uppercase
                  v-model="LetraRif"
                  :value.sync="LetraRif"
                  :options="LetraOptions"
                  :is-valid="hasError($v.LetraRif)"
                  :invalid-feedback="errorMessage($v.LetraRif)"
                  size="sm"
                />
              </CCol>
              <CCol sm="5"> <!-- rif -->
                <CInput
                  v-uppercase
                  v-model.trim="$v.ClientRif.$model"
                  ref="ClientRif"
                  maxlength="9"
                  minlength="9"
                  :is-valid="hasError($v.ClientRif)"
                  :invalid-feedback="errorMessage($v.ClientRif)"
                  @blur="$v.$touch()"
                  :placeholder="$t('label.rifNumber')"
                  size="sm"
                />
              </CCol>
              
              <CCol sm="12"><!-- nombre -->
                <CInput
                  :label="$t('label.name')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.ClientName)"
                  maxlength="250"
                  v-model.trim="$v.ClientName.$model"
                  :is-valid="hasError($v.ClientName)"
                  :placeholder="$t('label.consigneeName')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12"><!-- alias -->
                <CInput
                  :label="$t('label.alias')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.ClientAlias)"
                  maxlength="250"
                  v-model.trim="$v.ClientAlias.$model"
                  :is-valid="hasError($v.ClientAlias)"
                  :placeholder="$t('label.Alias')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12"><!-- representante -->
                <CInput
                  :label="$t('label.representative')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.Representative)"
                  maxlength="50"
                  v-model.trim="$v.Representative.$model"
                  :is-valid="hasError($v.Representative)"
                  :placeholder="$t('label.consigneeRepresentativeName')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12">
                <CSelect
                  :label="$t('label.condition')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  v-uppercase
                  addLabelClasses="required text-right"
                  :options="FgGovernmentOptions"
                  v-model.trim="$v.FgGovernment.$model"
                  :value.sync="FgGovernment"
                  :is-valid="hasError($v.FgGovernment)"
                  :invalid-feedback="errorMessage($v.FgGovernment)"
                  size="sm"
                  />
              </CCol>
              <CCol sm="12"><!-- direccion -->
                <CTextarea
                  :label="$t('label.address')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.ClientAddress)"
                  rows="2"
                  v-model.trim="$v.ClientAddress.$model"
                  :is-valid="hasError($v.ClientAddress)"
                  :placeholder="$t('label.consigneeAddress')"  
                  maxlength="500" 
                  size="sm"             
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  :label="$t('label.mainPhone')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  ref="PrimaryPhone"
                  maxlength="15"
                  minlength="11"
                  v-model.trim="$v.PrimaryPhone.$model"
                  :is-valid="hasError($v.PrimaryPhone)"
                  :invalid-feedback="errorMessage($v.PrimaryPhone)"
                  :placeholder="$t('label.mainPhone')"
                  size="sm"               
                />
              </CCol>
              <CCol sm="12"> <!-- tlf  2 -->
                <CInput
                  :label="$t('label.secondaryPhone')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="text-right"
                  maxlength="15"
                  minlength="11"
                  v-model.trim="$v.SecondaryPhone.$model"
                  :is-valid="hasError($v.SecondaryPhone)"
                  :invalid-feedback="errorMessage($v.SecondaryPhone)"
                  :placeholder="$t('label.secondaryPhone')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12"><!-- email1 -->
                <CInput
                  :label="$t('label.mainEmail')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  maxlength="100"
                  v-model.trim="$v.PrimaryEmail.$model"
                  :is-valid="hasError($v.PrimaryEmail)"
                  :invalid-feedback="errorMessage($v.PrimaryEmail)"
                  :placeholder="$t('label.mainEmail')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12"><!-- email2 -->
                <CInput
                  :label="$t('label.secondaryEmail')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes=" text-right"
                  maxlength="100"
                  :invalid-feedback="errorMessage($v.SecondaryEmail)"
                  v-model.trim="$v.SecondaryEmail.$model"
                  :is-valid="hasError($v.SecondaryEmail)"
                  :placeholder="$t('label.secondaryEmail')"
                  size="sm"                
                />
              </CCol>
              <CCol sm="12" lg="12">
                  <CInput
                    type="color"
                    :label="$t('label.color')"
                    :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                    add-label-classes="text-right"
                    :is-valid="hasError($v.Color)"
                    v-model.trim="$v.Color.$model"
                    size="sm"
                  />
              </CCol>
              <CCol sm="12" v-if="isEdit" ><!-- status -->
                <CSelect
                  :label="$t('label.status')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  addLabelClasses="text-right"
                  v-model="Status"
                  :value.sync="Status"
                  :is-valid="statusSelectColor"
                  :options="selectOptions"
                  size="sm"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="2">
            <CRow>
              <CCol sm="12">
                <CButton size="sm" color="add"  class="mr-1"  v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" @click="evaluaStatus" :disabled="isSubmit">
                  <CIcon name="checkAlt" />
                </CButton>
  
                <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" @click="Reset" :disabled="isSubmit">
                  <CIcon name="cil-brush-alt"/>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mt-3 justify-content-center">
          <CCol sm="11">
              <CCollapse :show="tablaCollapse" class="mt-2">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items="ConsigneeList"
                  :fields="fieldsCliente"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                    
                >
                  <template #Status="{item}">
                    <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                  <td class="text-center">
                    <CButton color="edit" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.client')}" @click="editDatos(item)" >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
                </dataTableExtended>
              </CCollapse>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
  </div>
</template>
  
<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import ClienteValidations from '@/_validations/cliente/clienteValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { mapState } from 'vuex';


//DATA
function data () {
  return {
    //MODELO
    LetraRif: 'J',
    ClientRif: '',
    ClientName: '',
    ClientAlias: '',
    ClientAddress: '',
    PrimaryPhone: '',
    SecondaryPhone: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    Representative: '',
    FgActClient: '1',
    FgGovernment: '',
    Color: '',
    Status: 1,
    UserId: '',
    //VARIABLES
    ConsigneeList: [],
    // fieldsCliente,
    tablaCollapse: false,      
    Loading: false,    
    LetraOptions: ['J','G','P','V'],
    TitleModal: '',
    isEdit: false,
    editCliente: false,
    statusOrigin: '',
    isSubmit: false, 
  }
}

//METHOD
function Reset() {
  this.isEdit = false;
  this.TitleModal= '';  
  this.ClientRif = '',
  this.ClientName = '',
  this.ClientAlias = '',
  this.ClientAddress = '',
  this.PrimaryEmail = '',
  this.SecondaryEmail = '',
  this.PrimaryPhone = '',
  this.SecondaryPhone = '',
  this.Representative = '',
  this.Color = '',
  this.FgGovernment = '',
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status!==this.statusOrigin && this.isEdit) {
    this.$swal.fire(
      alertPropertiesHelpers(this,{
        text: `${this.$t('label.changeStatusQuestion')}?`,
      })
      ).then((result) => {
      if (result.isConfirmed) {
        this.saveData();
      }
    });  
  } else {
    this.saveData();
  } 
}

function saveData () {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
  
    let res = [];
    let rif = this.LetraRif+this.ClientRif;
    let metodo = '';
    let ruta = '';
    let ClientJson = [];

    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.isEdit) {
      ClientJson = {
        ClientId: this.ClientId,
        ClientRif: rif,
        ClientName: this.ClientName,
        ClientAlias: this.ClientAlias,
        ClientAddress: this.ClientAddress,
        PrimaryEmail: this.PrimaryEmail,
        SecondaryEmail: this.SecondaryEmail,
        PrimaryPhone: this.PrimaryPhone,
        SecondaryPhone: this.SecondaryPhone,
        Representative: this.Representative,
        Status: this.Status,
        UserId: this.user.UserId,
        Color: this.Color,
        FgGovernment: this.FgGovernment,
      };
      metodo = 'PUT';
      ruta = "ClientConsigneYard-update";
    } else {
      ClientJson = {
        ClientRif: rif,
        ClientName: this.ClientName,
        ClientAlias: this.ClientAlias,
        ClientAddress: this.ClientAddress,
        PrimaryEmail: this.PrimaryEmail,
        SecondaryEmail: this.SecondaryEmail,
        PrimaryPhone: this.PrimaryPhone,
        SecondaryPhone: this.SecondaryPhone,
        Representative: this.Representative,
        Color: this.Color,
        FgGovernment: this.FgGovernment,
      };
      metodo = 'POST';
      ruta = "ClientConsigneYard-insert";
    }

    this.$http.ejecutar(metodo, ruta, ClientJson, { root: 'ClientJson' })
    .then(async response => {
      res = [...response.data.data];
      this.Reset();
      await this.getConsignee();
      this.$emit('Update');
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
        this.isSubmit = false;
        this.Loading= false;
      });
  } catch (e) {
    this.isSubmit = false;
    this.Loading= false;
    //this.notifyError({ text: e });
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

async function getConsignee () {
  this.Loading = true;
  this.ConsigneeList = [];
  
  let listado = [];
  await this.$http.get("Client-list", { filter: 'ALL' })
  .then(response => {
    let arreglo = [...response.data.data];
    let arreglo2 = [];
    
    for (let i=0; i<arreglo.length; i++){
      arreglo2 = arreglo[i].ClientTpJson;
      if (arreglo2!=null) {
        for (let y=0; y<arreglo2.length; y++){
          if (arreglo2[y].TpClientId==='64E31AF2-4A02-EE11-A88B-000D3A0DEDC7'){
            listado.push(arreglo[i]);
          }        
        } 
      }      
    }
    this.ConsigneeList = listado.map(item => {
      return {
        ...item,
        Nro: item.Nro,
        Cliente: item.ClientName ?? '',
        Usuario: item.TransaLogin ? item.TransaLogin:'N/A',
        Fecha: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
        Status: item.Status,
        ClientId: item.ClientId ?? '',
        ClientRif: item.ClientRif ?? '',
        ClientAddress: item.ClientAddress ?? '',
        PrimaryEmail: item.PrimaryEmail ?? '',
        SecondaryEmail: item.SecondaryEmail ?? '',
        PrimaryPhone: item.PrimaryPhone ?? '',
        SecondaryPhone: item.SecondaryPhone ?? '',
        Representative: item.Representative ?? '',
        FgActClient: item.FgActClient ? true : false,
        FgGovernment: item.FgGovernment ? true : false,
        _classes: item.Status === "INACTIVO" ? 'table-danger': '',
      }
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading = false;
  });
}

function editDatos(item) {
  let rif = item.ClientRif ?? '';
  this.isEdit = true;
  this.ClientName = item.Cliente ?? '';
  this.TitleModal=this.ClientName;
  this.ClientAlias = item.ClientAlias ?? '';
  this.ClientId = item.ClientId ?? '';
  this.ClientAddress = item.ClientAddress ?? '';
  this.PrimaryEmail = item.PrimaryEmail ?? '';
  this.SecondaryEmail = item.SecondaryEmail ?? '';
  this.PrimaryPhone = item.PrimaryPhone ?? '';
  this.SecondaryPhone = item.SecondaryPhone ?? '';
  this.Representative = item.Representative ?? '';
  this.Color = item.Color ?? '';
  this.FgGovernment = item.FgGovernment ? 1 : 0;
  this.Status = item.FgActClient ? 1 : 0;
  this.ClientRif = rif.substr(1, (rif.length - 1));
  this.LetraRif  = rif.substr(0,1);
  this.statusOrigin= item.FgActClient ? 1 : 0;
  this.$v.$touch();
}

//COMPUTED

function selectOptions(){
  return [
    { 
      value: 1, 
      label: this.$t('label.ACTIVO')
    },
    { 
      value: 0, 
      label: this.$t('label.INACTIVO')
    }
  ];
}

function fieldsCliente() {
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:3%;'
    },
    { key: 'Cliente', label:this.$t('label.consignee'), _classes: 'text-uppercase text-center',  _style: 'width:32%;' },
    { key: 'ClientRif', label:this.$t('label.rifNumber'), _classes: 'text-uppercase text-center', _style: 'width:12%;' },
    { key: 'Usuario', label:this.$t('label.user'), _classes: 'text-uppercase text-center', _style: 'width:12%;' },
    { key: 'Fecha', label:this.$t('label.date'), _classes: 'text-center', _style: 'width:12%;' },
    { key: 'Status', label:this.$t('label.status'), _classes: 'text-center', _style: 'width:12%;' },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _classes: 'text-center text-center',
      _style: 'min-width:45px; width:1%;'
    }
  ];
}

function cambiaIcono() {
  return this.tablaCollapse ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}
function collapseTitle(){
  return this.isEdit ?  `${this.$t('label.edit')} ${this.$t('label.consignee')}: ${this.TitleModal}` : `${this.$t('label.nuevo')} ${this.$t('label.consignee')}`;
}

function FgGovernmentOptions() {
  return [
    { value:'', label: this.$t('label.select')},
    { value: 0, label: this.$t('label.private')},
    { value: 1, label: this.$t('label.government')},
  ]
}
    
export default {
  name: 'add-consignee',
  mixins: [General],
  components:{},
  data,
  props: {
    Active:Boolean,
  },
  directives: UpperCase,
  validations: ClienteValidations,
  methods: {
    evaluaStatus,
    Reset,
    saveData,
    editDatos,
    getConsignee,
  },
  computed: {
    cambiaIcono,
    cambiaTexto,
    selectOptions,
    fieldsCliente,
    collapseTitle,
    FgGovernmentOptions,
    ...mapState({
      user: state => state.auth.user,
    })
  },
  watch: {
    Active: function (NewVal) {
      if (NewVal) {
        this.getConsignee();
      } else {
        this.Reset();
      }
    }
  },
}
</script>