<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.driversAndVehicleList')}}</b>
    </CCardHeader>
    <CCardBody>
      <CustomTabs :active-tab="0">
        <CustomTab icon="drivers" :title="$t('label.drivers')" class="pb-3">
          <choferes />
        </CustomTab>
        <CustomTab icon="vehicle" :title="$t('label.vehicle')" class="pb-3">
          <vehiculo />
        </CustomTab>
        <CustomTab icon="vehicleTp" :title="$t('label.vehicleType')" class="pb-3">
          <tpVehiculo />
        </CustomTab>
        <CustomTab icon="vehicleClass" :title="$t('label.vehicleClasific')" class="pb-3">
          <classVehiculo />
        </CustomTab>
      </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import choferes from './chofer/index-chofer';
import vehiculo from './vehiculo/index-vehiculo';
import tpVehiculo from './tpVehiculo/index-tp-vehiculo';
import classVehiculo from './classVehiculo/index-class-vehiculo';

export default {
  name: 'choferesVehiculo',
  components: {
    CustomTabs,
    CustomTab,
    choferes,
    vehiculo,
    tpVehiculo,
    classVehiculo,
  },
};
</script>
<style lang=""></style>
