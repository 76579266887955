<template>
  <div class="mt-3">
    <CRow v-if="!fgInfo" class="justify-content-center m-0" >
      <CCol sm="12" lg="4" class="px-2">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.hazardClass')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right"
          class="remove-space"
          :options="ImdgClassCodeOptions"
          v-model.trim="$v.form.imdg.ImdgClassId.$model"
          :value.sync="form.imdg.ImdgClassId"
          :is-valid="hasError($v.form.imdg.ImdgClassId)"
          :invalid-feedback="errorMessage($v.form.imdg.ImdgClassId)"
          @change="listImdgCode($event)"
        />
      </CCol>
      <CCol sm="12" lg="4" class="px-2">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.imdgCode')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right"
          class="remove-space"
          :disabled="changeClassImdg"
          :options="ImdgCodeOptions"
          v-model.trim="$v.form.imdg.ImdgId.$model"
          :value.sync="form.imdg.ImdgId"
          :is-valid="hasError($v.form.imdg.ImdgId)"
          :invalid-feedback="errorMessage($v.form.imdg.ImdgId)"
          @change="onChangeImdgId($event.target.value)"
        />
      </CCol>

      <CCol sm="12" lg="4" class="px-2">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.PackagingGroup')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="text-right"
          class="remove-space"
          :options="PackagingGroupOptions"
          v-model.trim="$v.form.imdg.PackagingGroupId.$model"
          :value.sync="form.imdg.PackagingGroupId"
          :is-valid="hasError($v.form.imdg.PackagingGroupId)"
          :invalid-feedback="errorMessage($v.form.imdg.PackagingGroupId)"
        />
      </CCol>
      <CCol sm="12" lg="4" class="px-2">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.UnNumber')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="text-right"
          class="remove-space"
          :disabled="UnNumberJson.length == 0"
          :options="UnNumberOptions"
          v-model.trim="$v.form.imdg.UnNumberId.$model"
          :value.sync="form.imdg.UnNumberId"
          :is-valid="hasError($v.form.imdg.UnNumberId)"
          :invalid-feedback="errorMessage($v.form.imdg.UnNumberId)"
        />
      </CCol>

      <CCol sm="12" lg="4" class="px-2">
        <CTextarea
          :label="$t('label.description')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          maxlength="250"
          size="sm"
          v-uppercase
          v-model.trim="$v.form.imdg.DescriptionOfGoodsDGS.$model"
          addLabelClasses="text-right"
          class="remove-space"
          rows="2"
          :is-valid="hasError($v.form.imdg.DescriptionOfGoodsDGS)"
          :invalid-feedback="errorMessage($v.form.imdg.DescriptionOfGoodsDGS)"
        />
      </CCol>
      <CCol sm="12" lg="4" class="px-2">
        <CCol class="col-form-label col-sm-12 col-lg-5 col-form-label-sm text-right pl-2" style="text-align: left;">
          <CButton
              color="add"
              size="sm"
              class="mr-1"
              v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
              :disabled="isSubmitImdg"
              @click="submitBodyImdg()"
              >
              <CIcon name="checkAlt"/>
          </CButton>
          <CButton
              color="wipe"
              class="justify-content-end"
              size="sm"
              v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
              @click="clearFilters()"
              >
              <CIcon name="cil-brush-alt" />
          </CButton>
        </CCol>
      </CCol>
    </CRow>
    <CCol sm="12" class="px-2">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListImdg"
        :fields="imdgfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #options="{ item }">
          <td class="text-center">
              <CButton
                  color="edit"
                  square
                  size="sm"
                  class="mr-2"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.imdg'),
                    placement: 'top-start'
                    }"
                  @click="editImdg(item)"
                >
                <CIcon name="pencil" />
              </CButton>
              <CButton
                class="btn btn-sm btn-wipe"
                v-c-tooltip="{
                  content: $t('label.delete')+$t('label.imdg'),
                  placement: 'top-start',
                }"
                  @click="deleteImdg(item)"
              >
                <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import ModalBlFormValidations from '@/_validations/yard-management/movements/modalBlFormValidations';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";


function data() {
  return {
    hazardClassItems: [],
    PackagingGroupJson: [],
    imdgCodeItems: [],
    UnNumberJson: [],
    isSubmitImdg: false,
    isSubmitImdgImdg: false
  };
}

//-----------------------   Method    ------------------------
function clearFilters() {
  this.form.imdg.Id = 0,
  this.form.imdg.BlCargoMasterImdgId = '';
  this.form.imdg.ImdgClassId = '',
  this.form.imdg.ImdgId = '',
  this.form.imdg.PackagingGroupId = '',
  this.form.imdg.UnNumberId = '',
  this.form.imdg.DescriptionOfGoodsDGS = '',
  this.form.imdg.ImdgId = '';
  this.imdgCodeItems = [];
  this.UnNumberJson = [];

  this.$v.$reset();
}

function deleteImdg(item) {
  this.$emit('deleteImdg', item);
}

async function editImdg(item) {
  this.form.imdg.Id = item.IdX;
  this.form.imdg.BlCargoMasterImdgId = item.BlCargoMasterImdgId;
  this.form.imdg.ImdgClassId = item.ImdgClassId ?? '';
  await this.listImdgCode();
  this.form.imdg.ImdgId = item.ImdgId ?? '';
  await this.onChangeImdgId(this.form.imdg.ImdgId);
  this.form.imdg.UnNumberId = item.UnNumberId ?? '';
  this.form.imdg.PackagingGroupId = item.PackagingGroupId ?? '';
  this.form.imdg.DescriptionOfGoodsDGS = item.DescriptionOfGoodsDGS ?? '';

  this.$v.form.imdg.$touch();
}

function getBadge(status) {
  switch (status) {
  case 'ACTIVO':
    return 'success'
  case 'INACTIVO':
    return 'danger'
  }
}

async function resetForm() {
  //imdg
  this.form.imdg.Id = 0;
  this.form.imdg.BlCargoMasterImdgId = '';
  this.form.imdg.ImdgId = '';
  this.form.imdg.ImdgClassId = '';
  this.form.imdg.PackagingGroupId = '';
  this.form.imdg.UnNumberId = '';
  this.form.imdg.DescriptionOfGoodsDGS = '';

  //data array
  this.imdgCodeItems = [];
  this.PackagingGroupJson = [];
  this.UnNumberJson = [];
  this.hazardClassItems = [];

}

async function submitBodyImdg(){
  try {
    this.isSubmitImdgImdg = true
    this.$v.form.imdg.$touch();

    if (this.$v.form.imdg.$error) {
      this.isSubmitImdgImdg = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.fgPackagingTab && this.form.imdg.Id == 0 && (this.imdgItems.filter(e => e.Status == 1).length != 0)) {
      throw this.$t('validation.OnlyOneImdgPerPackage');
    }

    if (this.imdgItems.some(e => e.Status == 1 && (this.form.imdg.Id == 0 ? true : e.IdX != this.form.imdg.Id) && e.ImdgClassId == this.form.imdg.ImdgClassId && e.ImdgId == this.form.imdg.ImdgId && e.PackagingGroupId == this.form.imdg.PackagingGroupId && e.UnNumberId == this.form.imdg.UnNumberId)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let hazardClassData =  this.hazardClassItems.find((val) => val.ImdgClassId == this.form.imdg.ImdgClassId);
    let imdgCodeData =  this.imdgCodeItems.find( (val) =>  val.ImdgId == this.form.imdg.ImdgId);
    let UnNumberData =  this.UnNumberJson.find( (val) =>  val.UnNumberId == this.form.imdg.UnNumberId);
    let PackagingGroupData =  this.PackagingGroupJson.find( (val) =>  val.PackagingGroupId == this.form.imdg.PackagingGroupId);
    
    if (this.form.imdg.Id == 0) {
      await this.$emit('imdgUpdateItems', 
        {
          IdX: this.imdgItems.length > 0 ? this.imdgItems[this.imdgItems.length - 1].IdX + 1 : 1,
          BlCargoMasterImdgId: this.form.imdg.BlCargoMasterImdgId, 
          ImdgCode: imdgCodeData?.ImdgCod ?? '',
          ImdgClassCode: hazardClassData?.ImdgClassAbbrev ?? 'N/A',
          ImdgClassId:  this.form.imdg.ImdgClassId ?? '',
          ImdgClassName: (Object.keys(hazardClassData).length != 0)  ? hazardClassData[`ImdgClassName${_lang}`]: '',
          ImdgId: this.form.imdg.ImdgId ?? '',
          ImdgName: (Object.keys(imdgCodeData).length != 0)  ? imdgCodeData[`ImdgName${_lang}`]: '',
          UnNumberId: this.form.imdg.UnNumberId ?? '',
          UnNumber: UnNumberData && (Object.keys(UnNumberData).length != 0) ? UnNumberData.UnNumber: 'N/A',
          PackagingGroupId: this.form.imdg.PackagingGroupId ?? '',
          PackagingGroupCode: PackagingGroupData && (Object.keys(PackagingGroupData).length != 0) ? PackagingGroupData.PackagingGroupCode: 'N/A',
          DescriptionOfGoodsDGS: this.form.imdg.DescriptionOfGoodsDGS ?? '',
          Status: 1,
        })
    }
    
    else{
      await this.$emit('imdgUpdateItems', 
        {
          IdX: this.form.imdg.Id,
          BlCargoMasterImdgId : this.form.imdg.BlCargoMasterImdgId, 
          ImdgCode : imdgCodeData?.ImdgCod ?? '',
          ImdgClassCode : hazardClassData?.ImdgClassAbbrev ?? 'N/A',
          ImdgClassId : this.form.imdg.ImdgClassId ?? '',
          ImdgClassName : (Object.keys(hazardClassData).length != 0)  ? hazardClassData[`ImdgClassName${_lang}`]: '',
          ImdgId : this.form.imdg.ImdgId ?? '',
          ImdgName : (Object.keys(imdgCodeData).length != 0)  ? imdgCodeData[`ImdgName${_lang}`]: '',
          UnNumberId : this.form.imdg.UnNumberId ?? '',
          UnNumber : UnNumberData && (Object.keys(UnNumberData).length != 0) ? UnNumberData.UnNumber: 'N/A',
          PackagingGroupId : this.form.imdg.PackagingGroupId ?? '',
          PackagingGroupCode : PackagingGroupData && (Object.keys(PackagingGroupData).length != 0) ? PackagingGroupData.PackagingGroupCode: 'N/A',
          DescriptionOfGoodsDGS : this.form.imdg.DescriptionOfGoodsDGS ?? '',
          Status : 1
        })
    }

    
    this.isSubmitImdgImdg = false;
    this.clearFilters();
     
    } catch (error) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
}

function listImdgCode(event){
  this.form.imdg.ImdgId = '';
  this.form.imdg.UnNumberId = '';
  this.imdgCodeItems = [];
  this.UnNumberJson = [];
  event ? this.form.imdg.ImdgClassId = event.target.value : ''; 

  let hazardClassData = this.hazardClassItems.find((val) => val.ImdgClassId == this.form.imdg.ImdgClassId);
  if (hazardClassData && Object.keys(hazardClassData).length != 0 ) {
    this.imdgCodeItems = hazardClassData?.ImdgJson ? hazardClassData?.ImdgJson : [];
  }
}

function onChangeImdgId(id){
  this.form.imdg.ImdgId = id ?? '';
  this.form.imdg.UnNumberId = '';
  this.UnNumberJson = [];
  let imdgCodeData = this.imdgCodeItems.find((val) => val.ImdgId == this.form.imdg.ImdgId);
  if (imdgCodeData) {
    this.UnNumberJson = imdgCodeData?.UnNumberJson ?? [];
  }
}

function getIMDGList() {
  this.$store.state.yardManagement.loading = true;
  this.$http
  .get('BlImdgGeneral-info')
  .then(response => {
    let listado = response.data.data;
    if (listado && listado.length != 0){
      let items = listado;
      //LISTAR CLASE DE PELIGRO
      let array = items.find((val) => val.BlCargoId == this.BlCargoId);
      this.hazardClassItems = array.ImdgJson;
      //LISTAR GRUPO DE EMBALAJE
      let array2 = items.find((val) => val.BlCargoId == this.BlCargoId);
      this.PackagingGroupJson = array2.PackagingGroupJson;
    }
  })        
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

//-----------------------   Computed   ------------------------

function imdgfields(){
  let head = [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'ImdgClassAbbrev', label: this.$t('label.hazardClass'),  _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'PackagingGroupCode', label: this.$t('label.PackagingGroup'), _style:'width:18%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'DescriptionOfGoodsDGS', label: this.$t('label.description'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
  ]
  if (!this.fgInfo) {
    head.unshift({ key: 'options', label: '', _style: 'width:1%; min-width:90px; text-align:center;', sorter: false, filter: false });
  }
  return head;
}

function ImdgClassCodeOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.hazardClassItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.hazardClassItems.map(function(e){
      chart.push({
        value: e.ImdgClassId, 
        label: e[`ImdgClassName${_lang}`] ?  e.ImdgClassAbbrev + ' - ' + e[`ImdgClassName${_lang}`] : 'N/A'
      })    
    })
    return chart;
  }
}

function changeClassImdg() {
  return  this.form.imdg.ImdgClassId && this.imdgCodeItems.length != 0 ? false : true;
}

function ImdgCodeOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.imdgCodeItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.imdgCodeItems.map(function(e){
      chart.push({
        value: e.ImdgId, 
        label: e.ImdgCod
      })    
    })
    return chart;
  }
}

function PackagingGroupOptions() {
  if(this.PackagingGroupJson.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.PackagingGroupJson.map(function(e){
      chart.push({
        value: e.PackagingGroupId, 
        label: e.PackagingGroupCode,
      })    
    })
    return chart;
  }
}

function UnNumberOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.UnNumberJson.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.UnNumberJson.map(function(e){
      chart.push({
        value: e.UnNumberId, 
        label: e.UnNumber
      })    
    })
    return chart;
  }
}

function computedListImdg() {
  return  this.imdgItems.filter(item => item.Status == 1).map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      ImdgClassAbbrev: item.ImdgClassCode ?? 'N/A',
      ImdgCod: item.ImdgCode ?? 'N/A',
      PackagingGroupCode: item.PackagingGroupCode ?? 'N/A',
      UnNumber: item.UnNumber ?? 'N/A',
      DescriptionOfGoodsDGS: item.DescriptionOfGoodsDGS ?? 'N/A',
    }
  });
}

export default {
  name: 'imdg-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    imdgItems: Array,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
    fgInfo: Boolean,
    fgPackagingTab: Boolean,
    Clean: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return ModalBlFormValidations(this.form.customBroke.consolidatorCheck,this.validCargoMaster);
  },
  methods: {
    listImdgCode,
    onChangeImdgId,
    submitBodyImdg,
    clearFilters,
    editImdg,
    deleteImdg,
    getBadge,
    getIMDGList,
    resetForm
  },
  computed:{
    imdgfields,
    ImdgClassCodeOptions,
    changeClassImdg,
    ImdgCodeOptions,
    PackagingGroupOptions,
    UnNumberOptions,
    computedListImdg,
    ...mapState({
      BlCargoMasterId: state => state.yardManagement.BlCargoMasterId,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      loading: state => state.yardManagement.loading,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
        this.$v.$reset();
      }
    },
    Tab: async function (Newval) {
      if (Newval && !this.fgInfo) 
        await this.getIMDGList();
    },
    Clean: function (Newval) {
      if (Newval) {
        this.clearFilters();
      }
    },
  }
};
</script>