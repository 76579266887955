import {
  required,
  maxLength,
  helpers
} from 'vuelidate/lib/validators';

import { onlyText, metrics } from '../funciones';

const isValidFile = (value) => {
  if(!value)
    return false;
  
  if(value.type)
    return value.type.startsWith('image');
  else
      return (value != '');
};

//const fileNotReq = (value) => !helpers.req(value) || isValidFile(value);

export default () => {
  return {
    tipoGrua: {
      TpCraneName: { required, onlyText, maxLength: maxLength(150) },
      MaxHeigth: { metrics, required, maxLength: maxLength(20) },
      MaxWeigth: { metrics, required, maxLength: maxLength(20) },
      TpCraneRoute: { isValidFile }
    }
  }
}