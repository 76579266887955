<template>
  <div style="height: 100%;">
    <div
      class="cardHeaderPersonalizado"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ $t("label.transversalView") }}</span>
    </div>
    <div class="d-flex align-items-center" style="height: 90%; overflow-x: scroll; padding:0 20px;">
      <div style="width: 100%; display: flex; justify-content: center; min-width: 819.7px;">
        <div class="vista-transversal" v-if="FgContainerShip">
          <img src="/img/BUQUES/SVG/BaseShip.svg" alt="" />
          <div class="top-half">
            <transversal-row
              :items="toDeck"
              showCode
              operativa
              onlyNumbers
              :tooltip="false"
            />
            <transversal-row
              :items="toDeck"
              operativa
              class="vessel-todeck"
              :tooltip="false"
            />
          </div>
          <div class="bottom-half">
            <transversal-row
              :items="toUnderDeck"
              operativa
              class="vessel-underdeck"
              alignTop
              :tooltip="false"
            />
          </div>
        </div>
        <div class="vista-transversal allow-overflow" v-if="FgBulk || FgGeneralCargo">
          <transversal-view-small
            :vesselType="vesselType"
            :TpVesselId="TpVesselId"
            :positionJson="positionJson"
            fullscreen
            legend
            :operationalSituation="operationalSituation"
          />
        </div>
      </div>
      <div class="foot-note" v-if="FgBulk || FgGeneralCargo">
        <strong style="font-size:13px;font-weight: bold;">
          <span>{{ $t('label.ValuesExpressedInMt') }}</span>
        </strong>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TransversalRow from "@/pages/planificacion-estiba/archivos/pdf/transversal-row";
import TransversalViewSmall from "@/pages/situacion-puerto/transversal-view-small";


//computed

export default {
  name: "transversal-view",
  props: {
    vesselType: {
      type: String,
      default: "",
    },
    TpVesselId: {
      type: String,
      default: "",
    },
    toDeck: {
      type: Array,
      // required: true,
      default: () => [],
    },
    toUnderDeck: {
      type: Array,
      // required: true,
      default: () => [],
    },
    positionJson: {
      type: Array,
      // required: true,
      default: () => [],
    },
    operationalSituation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TransversalRow,
    TransversalViewSmall,
  },
  computed: {
    ...mapState({
      FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
      FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
      FgBulk: state => state.situacionOperativaActual.FgBulk,
    }),
  },
};
</script>


<style scoped>

.vista-transversal {
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  min-width: 819.7px;
  max-width: 819.7px;
  /* border: 1px solid #000; */
}
.allow-overflow {
  overflow: visible;
}
.vista-transversal img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vista-transversal .top-half {
  position: absolute;
  top: 0;
  bottom: 32%;
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: flex-end; */
}
.code-operativa {
  background-color: transparent;
  font-size: 0.6rem !important;
}
.vista-transversal .bottom-half {
  position: absolute;
  top: 67%;
  bottom: 4%;
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
}
.foot-note {
  position: absolute;
  bottom: 0;
  bottom: 10%;
  left: 2%;
}
</style>