<template>
  <div>
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
        </CCol>
        <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
      </CRow>
      <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
        <CTab :title="$t('label.packaging')" class="pt-1">
          <CRow class="mb-3 mt-1">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end" v-if="fgDesconsolidate  && YardId && dataContainers.length == 0">
              <CRow>
                <CCol sm="12" xl="12" class="align-items-center">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.deconsolidated'),
                      placement: 'top-end'
                    }"
                    @click="toggleAdd({})"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                @filtered-items-change="(List)=> this.FilterList = [...List]"
                pagination
              >
                <template #number="{ index }">
                  <td class="text-center">
                    {{index+1}}
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #buttonOptions="{item, index}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editDeconsolidation'),
                        placement: 'top-start'
                      }"
                      @click="toggleAdd(item, true)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.Containers')" class="pt-1">
          <CRow class="mb-3 mt-1">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <CRow>
                <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate && ContainerformatedItems.length == 0 && YardId && dataContainers.length>0">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.deconsolidated'),
                      placement: 'top-end'
                    }"
                    @click="showIndex = 3"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="ContainerformatedItems"
                    :fields="Containerfields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    @filtered-items-change="(List)=> this.FilterList = [...List]"
                    pagination
                >
                    <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                      <td class='text-center'>
                          <CBadge :color="getBadge(item.Status)">
                              {{ $t('label.'+item.Status) }}
                          </CBadge>
                      </td>
                    </template>
                    <template #buttonOptions="{item, index}">
                        <td class="text-center">
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                content: $t('label.editDeconsolidation'),
                                placement: 'top-start'
                              }"
                              v-if="YardId"
                              @click="toggleContainerAdd(item, true)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <CButton
                              style="background-color: #d6d6d7;"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.Seals'),
                                placement: 'top-start'
                              }"
                              @click="viewDetail(item, index)"
                          >
                              <CIcon name="bottomSeals"/>
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSeal(item.SealJson[0])"
                            :fields="level2Fields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                        
                            <template #button="{item}">
                                <td class="text-center">
                                    <CButton
                                    v-if="item.SealDocumentJson.length!=0&&item.Seal"
                                        color="watch"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="ModalImagen(item)"
                                    >
                                      <CIcon name="pencil" />
                                    </CButton>
                                </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
    </div>
    <div v-show="showIndex==1">
      <DesconsolidatedRegister
        :Active="ActiveRegister && showIndex==1"
        :HouseData="HouseData"
        :isEdit="isEdit"
        :ContainerItem="ContainerItem"
        @Close="showIndex=0, ActiveRegister=false, isEdit=false"
        @Update-list="Update"
      />
    </div>
    <div v-show="showIndex==2">
      <ContainerDesconsolidatedRegister
        :Active="ActiveRegister && showIndex==2"
        :HouseData="HouseData"
        :isEdit="isEdit"
        :ContainerItem="ContainerItem"
        @Close="showIndex=0, ActiveRegister=false, isEdit=false"
        @Update-list="Update"
      />
    </div>
    <div v-show="showIndex==3" class="my-3">
      <CRow class="mb-3">
          <CCol sm="12" lg="6" class="d-flex align-items-start justify-content-start">
              <CButton
                  color="edit"
                  class="mr-2"
                  v-c-tooltip="{
                      content: $t('label.return'),
                      placement: 'top',
                  }"
                  size="sm"
                  @click="()=> {showIndex=0}"
              >
                  <CIcon name="cil-chevron-left-alt" />
                  
              </CButton>
              <CRow class="align-items-center justify-content-start w-100 m-0">
                  <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ContainersInInventory')}`}} </h6>
              </CRow>
          </CCol>
      </CRow>
      <CRow>
          <CCol col="12" xl="12">
              <dataTableExtended
                  class="align-center-row-datatable"
                  hover
                  sorter
                  small
                  details
                  column-filter
                  :items="formatedContainerInventoryItems"
                  :fields="fieldsContainerInventory"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  pagination
              >
                  <template #number="{ index }">
                  <td class="text-center">
                      {{index+1}}
                  </td>
                  </template>
                  <template #Status="{item}">
                      <td class='text-center'>
                          <CBadge :color="getBadge(item.Status)">
                              {{ $t('label.'+item.Status) }}
                          </CBadge>
                      </td>
                  </template>
                  <template #options="{item, index}">
                      <td class="text-center">
                          <CButton
                              color="watch"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                  content: $t('label.deconsolidated'),
                                  placement: 'top-start'
                              }"
                              @click="toggleContainerAdd(item)"
                          >
                              <CIcon name='cil-list' />
                          </CButton>
                      </td>
                  </template>
              </dataTableExtended>
          </CCol>
      </CRow>
  </div>
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="labelModalImages"
      :SealImages="SealImages"
      @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import DesconsolidatedRegister from './desconsolidated-register';
import ContainerDesconsolidatedRegister from '@/pages/yard-management/container/events/desconsolidated/desconsolidated-register';
import ModalSealImages from '@/pages/yard-management/container/modal-seal-images';
import YardManagement from '@/_mixins/yardManagement';
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import { mapState } from 'vuex';

//DATA-------------------------
function data() {
  return {
    tabIndex: 0,
    FilterList: [],
    ModalSeal: false,
    labelModalImages: '',
    SealImages: [],
    dataDesconsolited: [],
    PackagingJson: [],
    ContainerJson: [],
    collapseDuration: 0,
    dataContainers: [],
    _toggled: false,
    showIndex: 0,
    ActiveRegister: false,
    isEdit: false,
    ContainerItem:{},
    HouseData: {},
    ReportJson: [],
  }
}

async function getBlHouseData() {
  await this.$http.get('BlHouseData-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
    .then(response => {
      this.HouseData = response.data.data[0] ?? {}; 
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getDesconsolitedList() {

  await this.$http.get('YardEventDeconsolidation-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId})
    .then(response => {
      let List = response.data.data?.[0];
      this.PackagingJson = List?.PackagingJson ?? [];
      this.ContainerJson = List?.ContainerJson ?? [];
      this.ReportJson = List?.ReportJson ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getInventoryInYard() {
        await this.$http.get('YardCargoInventoryInYard-by-BlCargoId', { YardId: this.YardId, BlCargoId: this.dataBl.BlCargoId})
      .then((response) => {
          this.dataContainers =  response.data.data ?? [];
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
}

async function getYardCargo(item, Edit = false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.ContainerItem = item;
        this.isEdit = Edit;
        this.$store.state.yardManagement.dataContainer = List[0];
        this.ActiveRegister = true;
        this.showIndex = 2;
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
        this.$store.state.yardManagement.loading = false;
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    
}

async function getYardBlCargo() {
    await this.$http.get('BlCargo-by-Id', {BlCargoId: this.dataBl.BlCargoId})
        .then(response => {
        let res = response.data.data;
          if (res && res?.length>0) {
              this.$store.state.yardManagement.dataBl = res[0];
          }
        })
        .catch( err => {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
        })
}

function toggleAdd(item, Edit = false) {
    this.ContainerItem = item;
    this.ActiveRegister = true;
    this.showIndex = 1;
    this.isEdit = Edit;
}

async function toggleContainerAdd(item, Edit = false) {
  this.YardId && await this.getYardCargo(item, Edit);
}

function ModalImagen(item) {
  this.labelModalImages = item.Seal;
  this.SealImages = item.SealDocumentJson;
  this.ModalSeal = true;
}

async function Update(IdDesconsolidateToPendingBl) {
  this.$store.state.yardManagement.loading = true;
  await this.getYardBlCargo();
  //await this.getBlHouseData();
  await this.getDesconsolitedList();
  this.$store.state.yardManagement.loading = false;

  if(this.$store.state.yardManagement.IdDesconsolidateToPendingBl != '' || IdDesconsolidateToPendingBl){
    this.$store.state.yardManagement.IdDesconsolidateToPendingBl = IdDesconsolidateToPendingBl
    this.$store.state.yardManagement.dropBlMovement  = 5;
  }
}

function viewDetail(item){
  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function NumberFormat(number) {
  return NumberFormater.setNum(number);
}

function handleTab(tab) {
  this.tabIndex = tab;
}

function getBadge(status) {
    switch (status) {
        case 'INACTIVO':
          return 'danger'
        case 'INACTIVE':
          return 'danger'
        default:
          return 'success'
    }
}

//----- Excel section -------
async function onBtnExport(valor){
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let dataList = [];
    let moreOpcions = {
        backgroundColorInTitle: '#4e5a70',
        colorLetterInTitle: '#ffffff',
    }

    this.ReportJson.map((e)=>{
      if(this.FilterList.some((el) => el.YardCargoEventDetailId == e.YardCargoEventDetailId)){
        dataList.push({...e, Nro: dataList.length + 1})
      }
    })

    let filters = [
        this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
        this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
    ]

    dataList = dataList.map((e)=>{  

            e.BlHouseDetailJson?.map((s,int)=>{
              e.BlHouseDetailJson[int].PackagingName = s[`PackagingName${_lang}`] ?? 'N/A',
              
              s.BlHouseSerialJson?.map((a, i)=>{
                  s.BlHouseSerialJson[i].Seal = `S1:${a.Seal1 ? a.Seal1 : 'N/A'}, S2:${e.Seal2 ? a.Seal2 : 'N/A'}, S3:${e.Seal3 ? a.Seal3 : 'N/A'}, S4:${e.Seal4 ? a.Seal4 : 'N/A'}`;
              })
            })


        return {...e,
            ComputedInitDate: e.TransactionBegin ? DateFormater.formatDateTimeWithSlash(e.TransactionBegin) : '',
            ComputedFinishDate: e.TransactionFinish ? DateFormater.formatDateTimeWithSlash(e.TransactionFinish) : '',
            TransactionDate: e.TransactionDate ? DateFormater.formatDateTimeWithSlash(e.TransactionDate) : '',
            TransaRegDate: e.TransaRegDate ? DateFormater.formatDateTimeWithSlash(e.TransaRegDate) : '',
            PackagingParentName: e[`PackagingParentName${_lang}`] ?? 'N/A',
            EventReasonName: e[`EventReasonName${_lang}`] ?? 'N/A',
            EventServiceName: e[`EventServiceName${_lang}`] ?? 'N/A',
            ReceptionDate: e.ReceptionDate ? DateFormater.formatDateTimeWithSlash(e.ReceptionDate) : '',
            Observation: e.Observation  ?? '',
            Vehicle: e.LicensePlate ? `${e.LicensePlate} - ${e['TpVehicleName'+_lang]}` :  '',
            Driver: e.DriverCi ? `${e.DriverCi} - ${e.DriverName}` :  '',
            DocumentCode: e.DocumentCode ?? '',
            Status: this.$t(`label.${e.Status}`),
        }
    });
    
    await this.excel([this.excelHeader()], [dataList], this.$t('label.deconsolidatedList'), this.BranchName, `${this.$t('label.deconsolidatedList')}`, [filters], [], false, this.PersonalfilterCell, this.GroupTitle, moreOpcions);
    this.$store.state.yardManagement.loading = false;
}

function PersonalfilterCell (worksheet, cellMerge, cellStyles, CellNumber) {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let cell = {};

    //Data of Rows
    let custom = [
        [
        `${this.$t('label.bl')}:`, `${this.dataBl.BlNro??''}`, '',
        `${this.$t('label.Master')}:`, `${this.dataBl?.MasterBlNro??''}`,'',
        `${this.$t('label.consignee')}:`, `${this.dataBl?.ConsigneeName??''}`, '', 
        `${this.$t('label.status')}:`, `${this.dataBl[`YardCargoBlStatus${_lang}`]??''}`, '', 
        `${this.$t('label.plannedQuantity')}:`, this.dataBl.QuantityPlanning, '', 
        `${this.$t('label.plannedWeight')}:`, this.dataBl.WeigthPlanning, '',
        `${this.$t('label.plannedVolumen')}:`, this.dataBl.VolumenPlanning, '',
        '', '','', '', '', '', '', '', '', `${this.$t('label.DescriptionOfGoods')}:`,`${removeTags(this.dataBl.DescriptionOfGoods??'')}`,
        ],
        [
        `${this.$t('label.type')}:`, `${this.dataBl.TpBlName??''}`, '',
        `${this.$t('label.PackagingOrigin')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`PackagingName${_lang}`]??''}`,'',
        `${this.$t('label.CustomBroker')}:`,  `${this.dataBl.CustomBrokerName??''}`, '', 
        `${this.$t('label.Acta')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.DocumentCode??''}`, '', 
        `${this.$t('label.ReceivedQuantity')}:`, this.dataBl.QuantityReceived, '', 
        `${this.$t('label.receivedWeight')}:`, this.dataBl.WeigthReception, '',
        `${this.$t('label.receivedVolumen')}:`, this.dataBl.VolumenReception
        ],
        [
        `${this.$t('label.status')}:`, `${this.dataBl[`BlStatus${_lang}`]??''}`, '',
        `${this.$t('label.SerialOrigin')}:`, `${this.dataBl?.BlMasterJson?.[0]?.Serial??''}`,'',
        `${this.$t('label.Ship')}:`,  `${this.dataBl?.VesselName??''}`, '', 
        `${this.$t('label.statusCertificate')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.[`YardDocumentStatus${_lang}`]??''}`, '', 
        `${this.$t('label.CertifiedQuantity')}:`, this.dataBl.QuantityCertified, '', 
        `${this.$t('label.CertifiedWeight')}:`, this.dataBl.WeigthCertified, '',
        `${this.$t('label.CertifiedVolumen')}:`, this.dataBl.VolumenCertified
        ],
        [
        `${this.$t('label.portActivity')}:`, `${this.dataBl[`PortActivityName${_lang}`]??''}`, '',
        `${this.$t('label.type')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`TpCargoName${_lang}`]??''}`,'',
        `${this.$t('label.visit')}:`,  `${this.dataBl.Voyage} - ${this.dataBl.Arrival ? DateFormater.formatDateTimeWithSlash(this.dataBl.Arrival) : ''}`, '', 
        `${this.$t('label.firstEvent')}:`, `${this.dataBl[`FirstEventName${_lang}`]??''}`, '', 
        `${this.$t('label.dispachedQuantity')}:`, this.dataBl.QuantityDispatched, '', 
        `${this.$t('label.dispachedWeight')}:`, this.dataBl.WeigthDispatch, '',
        `${this.$t('label.dispachedVolumen')}:`, this.dataBl.VolumenDispatch
        ],
        [
        `${this.$t('label.group')}:`, `${this.dataBl[`TpCargoName${_lang}`]??''}`, '',
        `${this.$t('label.receptionDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.ReceptionDate) : ''}`,'',
        `${'POL'}:`,  `${this.dataBl.LoadPortName??''}`, '', 
        `${this.$t('label.dateFierstEvent')}:`, `${this.dataBl.FirstEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.FirstEventDate) : ''}`, ''
        ],
        [
        `${this.$t('label.commodity')}:`, `${this.dataBl[`CommodityName${_lang}`]??''}`, '',
        `${this.$t('label.dispatchDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.DispatchDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.DispatchDate) : ''}`,'',
        `${'POD'}:`,  `${this.dataBl.DischargePortName??''}`, '', 
        `${this.$t('label.LastEvent')}:`, `${this.dataBl[`LastEventName${_lang}`]??''}`
        ],
        [
        `${this.$t('label.Procedence')}:`, `${this.dataBl.Procedence??''}`, '',
        `${this.$t('label.status')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`YardCargoBlMasterDetailSerialStatusDs${_lang}`]??''}`,'',
        `${this.$t('label.dua')}:`,  `${this.dataBl.Dua??''}`, '', 
        `${this.$t('label.DateLastEvent')}:`, `${this.dataBl.LastEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.LastEventDate) : ''}`
        ],
        [
        '', '', '',
        `${this.$t('label.Day')}:`,   `${this.dataBl?.BlMasterJson?.[0]?.DaysInYard??''}`,'',
        `${this.$t('label.BolipuertoCertificateEntry')}:`,  `${this.dataBl.ReceptionBpAct??''}`
        ]
    ]

    //Row Injection
    for (let i = 0; i <= 7; i++) {
        worksheet.addRow(custom[i])
    }

    //Merge of cells
    worksheet.mergeCells(cellMerge(1, 14, 2, 14));
    worksheet.mergeCells(cellMerge(3, 7, 3, 14));
    worksheet.mergeCells(cellMerge(6, 7, 6, 14));
    worksheet.mergeCells(cellMerge(9, 7, 9, 14));
    worksheet.mergeCells(cellMerge(10, 14, 11, 14));
    worksheet.mergeCells(cellMerge(12, 7, 12, 14));
    worksheet.mergeCells(cellMerge(13, 11, 14, 14));
    worksheet.mergeCells(cellMerge(15, 7, 15, 14));
    worksheet.mergeCells(cellMerge(16, 11, 17, 14));
    worksheet.mergeCells(cellMerge(18, 7, 18, 14));
    worksheet.mergeCells(cellMerge(19, 11, 20, 14));
    worksheet.mergeCells(cellMerge(21, 7, 30, 14));
    worksheet.mergeCells(cellMerge(31, 7, 31, 14));
    worksheet.mergeCells(cellMerge(32, 7, 33, 14));

    //Styles of Cells
    //COL A
    cellStyles(worksheet, 1, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 1, 13, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL D
    cellStyles(worksheet, 4, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 13, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 4, 14, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL G
    cellStyles(worksheet, 7, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 13, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 7, 14, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL J
    cellStyles(worksheet, 10, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 11, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 12, true, 10, 26, "",  "000000", 11, false, 'right');
    cellStyles(worksheet, 10, 13, true, 10, 26, "",  "000000", 11, false, 'right');

    //COL M
    cellStyles(worksheet, 13, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(14, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 13, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(14, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 13, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(14, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 13, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(14, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

    //COL P
    cellStyles(worksheet, 16, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(17, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 16, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(17, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 16, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(17, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 16, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(17, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    
    //COL S
    cellStyles(worksheet, 19, 7, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(20, 7))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 19, 8, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(20, 8))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 19, 9, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(20, 9))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    cellStyles(worksheet, 19, 10, true, 10, 26, "",  "000000", 11, false, 'right');
    cell = worksheet.getCell(CellNumber(20, 10))
    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
    cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };


    cellStyles(worksheet, 31, 7, true, 10, 26, "",  "000000", 11, false,);

    cellStyles(worksheet, 33, 7, true, 10, 26, "",  "000000", 11, false, false, false);

    //CONTROL ROWS
    let fieldLast = 9;
    worksheet.addRow([]);
    
    return fieldLast
}

function GroupTitle (worksheet, fieldLast, cellMerge, cellStyles) {
    let custom = ['', '', '', '', '', '', '', '', '',  '', '', '', '', this.$t('label.packagings'), '', '', '', this.$t('label.serials'), '', '', '', '', '', '', '']
    let custom2 = ['', '', '', '', '', '', '', '', '',   this.$t('label.houseRecepted'), '', '', '', '', '', '', '', '', '',         '', '', '', '', '', '']
    
    worksheet.addRow(custom)
    worksheet.mergeCells(cellMerge(14, fieldLast, 17, fieldLast));
    cellStyles(worksheet, 14, fieldLast, true, 10, 26, "ed7d31", "ffffff", 12, true);
    worksheet.mergeCells(cellMerge(18, fieldLast, 26, fieldLast));
    cellStyles(worksheet, 18, fieldLast, true, 10, 26, "1f3864", "ffffff", 12, true);

    worksheet.addRow(custom2)
    worksheet.mergeCells(cellMerge(10, fieldLast + 1, 29, fieldLast + 1));
    cellStyles(worksheet, 10, fieldLast + 1, true, 10, 26, "26782c", "ffffff", 12, true);

    let field = 2
    return field;
}

function excelHeader() {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

    return [   
        { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
        { text: this.$t('label.startDate'), value: "ComputedInitDate", aux: "", json: "" },
        { text: this.$t('label.endDate'), value: "ComputedFinishDate", aux: "", json: "" },
        { text: this.$t('label.originSerial'), value: "SerialParent", aux: "", json: "" },
        { text: this.$t('label.originPackaging'), value: "PackagingParentName", aux: "", json: "" },
        { text: this.$t('label.motive'), value: "EventReasonName", aux: "", json: "" },
        { text: this.$t('label.service'), value: "EventServiceName", aux: "", json: "" },
        { text: this.$t('label.machine'), value: "MachineCode", aux: "", json: "" },
        { text: this.$t('label.observation'), value: "Observation", aux: "", json: "" },
        
        //HOUSE
        { text: this.$t('label.bl'), value: "BlNro", aux: "", json: "" },
        { text: this.$t('label.receptionDate'), value: "ReceptionDate", aux: "", json: "" },
        { text: this.$t('label.ubication'), value: "BlHouseYardName", aux: "", json: "" },
        { text: this.$t('label.transaction'), value: "TpTransacEirAcronym", aux: "", json: "" },    
        //EMBALAJES
        { text: this.$t('label.packaging'), value: `PackagingName${_lang}`, aux: "", json: "BlHouseDetailJson"},
        { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "BlHouseDetailJson" },
        { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "BlHouseDetailJson"},
        { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "BlHouseDetailJson"},
        //SERIAL
        { text: this.$t('label.serial'), value: "Serial", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: this.$t('label.imdg'), value: "ImdgCod", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson"},
        { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: `${this.$t('label.length')}`, value: "Length", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: `${this.$t('label.width')}`, value: "Width", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: this.$t('label.Seal'), value: "Seal", aux: "", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        { text: this.$t('label.DescriptionOfGoods'), value: "DescriptionOfGoods", json: "BlHouseDetailJson", json2: "BlHouseSerialJson" },
        
        { text: this.$t('label.transport'), value: "CarrierName", aux: "", json: ""},
        { text: this.$t('label.vehicle'), value: "Vehicle", aux: "", json: ""},
        { text: this.$t('label.driver'), value: "Driver", aux: "", json: ""},
        { text: this.$t('label.device'), value: "DeviceCod", aux: "", json: ""},
        { text: this.$t('label.user'), value: "TransaLogin", aux: "", json: ""},
        { text: this.$t('label.registerDate'), value: "TransaRegDate", aux: "", json: ""},
        { text: this.$t('label.status'), value: "Status", aux: "", json: ""},
    ]
}

    //---------------------------

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.PackagingJson.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardName: item.YardName? item.YardName??'' : this.yardData.YardName ?? '',
      TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
      MachineCode: item.MachineCode ?? '',
      DeviceName: item.DeviceName ?? '',
      TransaLogin: item.TransaLogin ?? '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
      _classes: (item.Status != 'ACTIVO' && item.Status != 'ACTIVE') ? 'table-danger' : '',
    };
  })
}

function fields(){
  return [
      {
        key: 'buttonOptions', 
        label: '', 
        _style: 'min-width: 45px; width: 1%; text-align:center; ', 
        sorter: false, 
        filter: false
      },
      { key: 'number', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
      { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
      { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
      { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 160px; text-align:center;', _classes:"text-center" },
      { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
      
  ];
}

function formatedSeal(SealJson) {
      if (SealJson) {
          let _lang = this.$i18n.locale;
          let SealArray = SealJson?.Seal1Json?.concat(SealJson.Seal2Json, SealJson.Seal3Json, SealJson.Seal4Json) ?? [];
          return SealArray.map((item) => {
          return {
              ...item,
              Seal: item.Seal ? item.Seal : '',
              SealConditionName: _lang=='en' ? item.SealConditionNameEn || '' : item.SealConditionNameEs || '',
              SealB: item.SealB ? item.SealB : '',
              SealAssignmentName: _lang=='en' ? item.SealAssignmentNameEn || '' : item.SealAssignmentNameEs || '',
              ObservationSeal: item.ObservationSeal ? item.ObservationSeal : '',
          };
          })
      }
}


function ContainerformatedItems() {
    let _lang = this.$i18n.locale;
    return this.ContainerJson.map((item, index) => {
      return {
            ...item,
            Nro: index+1,
            YardName: item.YardName? item.YardName : '',
            TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
            TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
            MachineCode: item.MachineCode ?? '',
            DeviceName: item.DeviceName ?? '',
            TransaLogin: item.TransaLogin ?? '',
            ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
            ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
            EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
            EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
            _classes: (item.Status != 'ACTIVO' && item.Status != 'ACTIVE') ? 'table-danger' : '',
        };
    })
}

function formatedContainerInventoryItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

  return this.dataContainers.map((item, index) => {

      let BlNro = '';
      let TpBlName = '';
      let DocumentCode = '';

      item.BlJson.map((e, i)=>{
          BlNro = `${BlNro}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.BlNro??''}`
          TpBlName = `${TpBlName}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.TpBlName??''}`
          DocumentCode = `${DocumentCode}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.DocumentCode??''}`
      })

      return {
          ...item,
          Nro: index+1,
          VesselVoyage: item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : '',
          DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
          BlNro: BlNro,
          TpBlName: TpBlName,
          DocumentCode: DocumentCode,
          ReceptionDate: DateFormater.formatDateTimeWithSlash(item.ReceptionDate),
          TransaRegDate: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
          AtaEta:  DateFormater.formatDateTimeWithSlash(item.Ata ?? item.Eta),
          _classes: 'color-gradient',
          _style: 'background:'+item.TpTransacEirColor,
      };
  })
}


function Containerfields(){
  return [
      {
        key: 'buttonOptions', 
        label: '', 
        _style: 'min-width: 90px; width: 1%; text-align:center; ', 
        sorter: false, 
        filter: false
      },
      { key: 'number', label: '#',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
      { key: 'Serial', label: this.$t('label.container'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: 'TpCargoDetailCode', label: this.$t('label.type'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
      { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
      { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
      
  ];
}

function level2Fields(){
  return [
      {
        key: 'button', 
        label: '', 
        _style: 'min-width: 45px; width: 1%; text-align:center; background-color: #828c9c;', 
        sorter: false, 
        filter: false
      },
      { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealConditionName', label: this.$t('label.Mode'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealB', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealAssignmentName', label: this.$t('label.assignation'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
      { key: 'ObservationSeal', label: this.$t('label.observation'), _style:'width: 35%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
  ];
}

function fieldsContainerInventory(){ 
  return [
      { key: 'options', label: '', _style: 'min-width:45px; width: 1%', sorter: false, filter: false },
      { key: 'Nro', label: '#', _style: 'min-width: 45px; text-align:center;', _classes: 'text-center', filter: false },
      { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
      { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:160px; text-align:center;', _classes:" text-center" },
      { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;' },
      { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'VesselVoyage', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`, _classes: 'text-center', _style: 'min-width: 235px;'},
      { key: 'AtaEta', label: 'ATA / ETA', _classes: 'text-center', _style: 'min-width: 160px;'},
      { key: 'TpCargoDetailCode', label: this.$t('label.type'), _style: 'min-width: 160px;', _classes: 'text-center'},
      { key: 'TpCargoStatusName', label: this.$t('label.status'), _style: 'min-width: 140px;', _classes: 'text-center'},
      { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _style: 'min-width: 140px;', _classes: 'text-center'},
      { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
      { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 140px;'},
      { key: 'ReceptionDate', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'DaysInYard', label: this.$t('label.daysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
      { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
      { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
      { key: 'TransaRegDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
  ];
}

function fgDesconsolidate() {
  return (
    this.dataBl.YardCargoBlStatus != process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED && 
    process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE == this.dataBl?.BlStatus?.toUpperCase() &&
    ((this.dataBl?.HouseJson?.length > 0) && this.dataBl.FgApplyDeconsolidation))
}

export default {
  name: 'desconsolidated',
  components: {
    CustomTabs,
    CustomTab,
    DesconsolidatedRegister,
    ContainerDesconsolidatedRegister,
    ModalSealImages,
   },
  data,
  mixins: [GeneralMixin, YardManagement],
  methods: {
    onBtnExport,
    PersonalfilterCell,
    GroupTitle,
    excelHeader,
    getYardCargo,
    viewDetail,
    toggleAdd,
    toggleContainerAdd,
    Update,
    getDesconsolitedList,
    getInventoryInYard,
    getBlHouseData,
    NumberFormat,
    handleTab,
    formatedSeal,
    getBadge,
    getYardBlCargo,
    ModalImagen
  },
  computed: {
    formatedContainerInventoryItems,
    fieldsContainerInventory,
    VisitOptions,
    level2Fields,
    fields,
    fgDesconsolidate,
    formatedItems,
    ContainerformatedItems,
    Containerfields,
    ...mapState({
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        YardCargoId: state => state.yardManagement.dataBl.YardCargoId,
        yardData: state => state.yardManagement.yardData,
        YardId: state => state.yardManagement.yardData.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        dropBlMovement: state => state.yardManagement.dropBlMovement,
        dataBl: state => state.yardManagement.dataBl,
        user: state => state.auth.user,
    })
  },
  watch: {
    dropBlMovement: async function (val) {
      if(val == 4){
        this.$store.state.yardManagement.loading = true;
        this.ActiveRegister=false;
        this.isEdit=false;
        this.showIndex = 0;
        this.tabIndex = 0;
        await this.getBlHouseData();
        await this.getDesconsolitedList();
        this.YardId && await this.getInventoryInYard();

        this.$store.state.yardManagement.loading = false;
      }
    },
  },
};
</script>