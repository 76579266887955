import {
    required,
    minLength,
    maxLength,
    alpha,
    email,
    helpers
  } from 'vuelidate/lib/validators';
  import { credentials, onlyText, phoneTest, alphaNumSpecials } from '../funciones';
  
  const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
  const emailToLower = (value) => email(value.toLowerCase());
  
  export default () => {
    return {
      user: {
        TpUserId: { required },
        Login: { required, alpha },
        Password: { required, alphaNumSpecials, minLength: minLength(6), maxLength: maxLength(20) },
        UserCi: { required, minLength: minLength(7), maxLength: maxLength(10), credentials },
        UserName: { required, onlyText },
        UserLastName: { required, onlyText },
        PrimaryPhone: { required, mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
        SecondaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
        PrimaryEmail: { required, email: emailToLower, maxLength: maxLength(100) },
        SecondaryEmail: { email: emailToLower, maxLength: maxLength(100) },
      },
      sucursal: {  },
      patio: {  },
      roles: {  }
    }
  }
  
  export const UserEdit = () => {
    return {
      user: {
        TpUserId: { required },
        Login: { required, alpha },
        Password: { required, alphaNumSpecials, minLength: minLength(6), maxLength: maxLength(20) },
        UserCi: { required, minLength: minLength(8), maxLength: maxLength(10), credentials },
        UserName: { required, onlyText },
        UserLastName: { required, onlyText },
        PrimaryPhone: { required, phoneTest, minLength: minLength(11), maxLength: maxLength(15) },
        SecondaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
        PrimaryEmail: { required, email: emailToLower, maxLength: maxLength(100) },
        SecondaryEmail: { email: emailToLower, maxLength: maxLength(100) },
      },
      sucursal: {  },
      patio: {  },
      roles: { }
    }
  }
