

import { required} from "vuelidate/lib/validators";
import {onlyCodeVersion,onlyApkFileName} from '@/_validations/validacionEspeciales';
import { DateFormater } from "@/_helpers/funciones";
import { } from '@/_validations/validacionEspeciales';
   
    export default (AvailableTo,AvailableFrom,RequiredTo) => {
        return {
            AvailableTo: { required },
            AvailableFrom: { AvailableTo (value){
                return DateFormater.formatDateTimeWithoutSlash(value) > DateFormater.formatDateTimeWithoutSlash(AvailableTo);
              }, RequiredTo (value){
                  if (RequiredTo) {
                    return RequiredTo && DateFormater.formatDateTimeWithoutSlash(value) > DateFormater.formatDateTimeWithoutSlash(RequiredTo);
                  }else{
                    return true;
                  }
              } },
              RequiredTo: { AvailableTo (value){
                return DateFormater.formatDateTimeWithoutSlash(value) > DateFormater.formatDateTimeWithoutSlash(AvailableTo)
              }, AvailableFrom (value){
                if (AvailableFrom) {
                  return AvailableFrom && DateFormater.formatDateTimeWithoutSlash(AvailableFrom) > DateFormater.formatDateTimeWithoutSlash(value);
                }else{
                  return true;
                }
            } },
            CodVersion: { required,onlyCodeVersion },
            SigetMobileFile:{ required,onlyApkFileName},
            TpModuleId: { required},
            FeaturesDescription: { required},
            Status: { required},
        }
      }
