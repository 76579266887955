<template>
  <div>
    <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars" />
    <div>
      <div class="visitas-body">
        <div class="wrapper d-flex align-items-stretch">
          <nav id="sidebar-visit">
            <div class="img bg-wrap text-center py-4"
              :style="'background-image: url(' + imageSrc(this.yardSelected?.YardProfileRoute ?? srcError) + ')'">
            </div>

            <div class="p-3">
              <ul class="list-unstyled components mb-5">
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="10">
                        <h5 class="text-white mt-0" style="font-size: 18px;">
                          {{ GetData(yardSelected?.YardName) }}
                        </h5>
                      </CCol>
                    </CRow>
                  </div>
                </li>

                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px">
                        {{$t('label.acronym')}}
                      </CCol>
                      <CCol sm="6"   style="font-size: 12px;" class="pl-0">
                        {{ GetData(yardSelected?.YardAlias) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px">
                        AREA
                      </CCol>
                      <CCol sm="6"  style="font-size: 12px;" class="pl-0">
                        {{ GetData(yardSelected?.SquareMeters) }}&nbsp;M2    
                      </CCol>
                    </CRow>
                  </div>
                </li>

                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px">
                        {{$t('label.capStatic')}}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                        {{ GetData(yardSelected?.StaticCapacity) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu ">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center"  style="font-size: 12px">
                        {{$t('label.height_')}}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                        {{ GetData(yardSelected?.OperationalCapacity) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>

                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center"  style="font-size: 12px">
                        {{ $t('label.nroAccess') }}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                         {{ GetData(yardSelected?.AccesNumber) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu ">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                        {{ $t('label.yardType') }}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                        {{ this.$i18n.locale=='en' ? this.yardSelected.TpYardNameEn : this.yardSelected.TpYardNameEs }}
                      </CCol>
                    </CRow>
                  </div>
                </li>

                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                        {{ $t('label.condition') }}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                        {{ this.$i18n.locale=='en' ? GetData(yardSelected?.YardStatusDsEn) : GetData(yardSelected?.YardStatusDsEs)}}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center"  style="font-size: 12px">
                        {{ $t('label.teus') }}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                         {{ GetData(yardSelected?.Teus) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
                <li>
                  <div class="list-menu">
                    <CRow>
                      <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                        {{ $t('label.yardGroup') }}
                      </CCol>
                      <CCol sm="6" style="font-size: 12px;" class="pl-0">
                        {{ GetData(yardSelected?.YardGpoName) }}
                      </CCol>
                    </CRow>
                  </div>
                </li>
              </ul>
            </div>
          </nav>


          <!-- Page Content  -->
          <div id="content">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <div class="container-fluid">

                <button type="button" id="sidebarCollapseVisita" class="btn bg-none text-withe"
                  @click="checkSideBarToggle()">
                  <CIcon class="text-white" :name="checkIconToggle" />
                  <span class="sr-only">{{ checkTextToggle }}</span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                    <li v-on:click="closeCollapse()"
                      class="nav-item d-flex align-items-center justify-content-end text-white">
                      <CIcon name="x" />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div class="container-fluid contenido-especial">
              <CRow>
                <CCol sm="12">
                  <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab">
                    <CustomTab :disabled="apiStateLoading"  :title="$t('label.subclassification')">
                      <SubClassTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading" :title="$t('label.cargoType')">
                      <CargoTypeTab  />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading || !GetData(yardSelected?.FgContainer)" :title="$t('label.blocksBays')" :style="isCoverWarehouse ? 'display: none;' : ''">
                      <BlocksBaysTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading" :title="$t('label.access')">
                      <AccessTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading" :title="'AREAS'">
                      <AreasTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading" :title="$t('label.machine')+'S'">
                      <MachinesTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading || !GetData(yardSelected?.FgContainer)" :title="$t('label.yardPlan')" :style="isCoverWarehouse ? 'display: none;' : ''">
                      <YardPlanTab />
                    </CustomTab>
                    <CustomTab :disabled="apiStateLoading || (!GetData(yardSelected?.FgContainer) && !isCoverWarehouse)" :title="$t('label.views')">
                      <ViewsTab />
                    </CustomTab>
                  </CustomTabs>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import ENUM from '@/_store/enum';
import { mapState } from 'vuex';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { DateFormater } from '@/_helpers/funciones';

import SubClassTab from './tabs/subclassification/index';
import CargoTypeTab from './tabs/cargoType/index';
import BlocksBaysTab from './tabs/blocksBays/index';
import AccessTab from './tabs/access/index';
import AreasTab from './tabs/areas/index';
import MachinesTab from './tabs/machines/index';
import ViewsTab from './tabs/views/index';
import YardPlanTab from './tabs/yardPlan/yard-plan-index';



//data
function data() {
  return {
    srcError: 'img/errorimage.jpg',
    yardInfo: {
      YardName: '',
      YardAlias: '',
      Area: '',
      StaticCapacity: '',
      OperativeCapacity: '',
      NroAccess: '',
      YardType: '',
    },
    collapsed: false,
    loadingOverlay: false,
    isSaved: true
  }
}
//mounted
function mounted() {
  document.getElementById("sidebarCollapseVisita").onclick = function () {
    document.getElementById("sidebar-visit").classList.toggle('active');
  }

  this.yardInfo = {...this.yardInfo, ... this.yardInfo};
}

//Methods
function formaterNormalDate(date) {
  return date ? DateFormater.formatOnlyDateWithSlash(date) : 'N/A';
}
function handleTab(tab) {
  this.$store.state.yard.tabIndex = tab;

}
function imageSrc(Src) {
  if (Src === '') {
    return this.srcError;
  }
  else {
    if(Src?.startsWith("http"))
      return Src;
    else 
      return `${this.$store.getters["connection/getBase"]}${Src}`;
  }
}

function setAltImg(event){
  event.target.src = this.srcError;
}
function closeCollapse() {
  this.$store.state.yard.tabIndex = 0;
  this.$store.state.yard.collapse = false;
  this.$store.state.yard.yardSelected = null;
  // this.$store.state.yard.collapseSidebar = false;
}

function GetData(Data) {
  return Data ? Data : '';
}


function checkSideBarToggle() {
  this.collapsed = !this.collapsed;
  // this.$store.state.yard.collapseSidebar = !this.$store.state.yard.collapseSidebar;
}

//computed
function apiStateLoading() {
  let carga = false;
  if (this.apiState === ENUM.LOADING) {
    carga = !carga;
  }
  return carga;
}

function checkIconToggle() {
  if (this.collapsed) {
    return "cil-chevron-right-alt";
  } else {
    return "cil-chevron-left-alt";
  }
}

function checkTextToggle() {
  if (this.collapsed) {
    return this.$t("label.close");
  } else {
    return this.$t("label.watch");
  }
}

function isCoverWarehouse() {
  return this.yardSelected.YardTpId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE;
}

export default {
  name: 'menu-yard',
  mixins: [],
  data,
  props: { yardId: {type: String,}, yardItem: {type: Object,}},
  mounted: mounted,
  methods: {
    formaterNormalDate,
    handleTab,
    imageSrc,
    setAltImg,
    closeCollapse,
    GetData,
    checkSideBarToggle,
  },
  components: {
    CustomTabs,
    CustomTab,
    SubClassTab,
    CargoTypeTab,
    BlocksBaysTab,
    AccessTab,
    AreasTab,
    MachinesTab,
    ViewsTab,
    YardPlanTab
  },
  computed: {
    apiStateLoading,
    checkTextToggle,
    checkIconToggle,
    isCoverWarehouse,
    ...mapState({
      branch: state => state.auth.branch,
      collapse: state => state.yard.collapse,
      tabIndex: state => state.yard.tabIndex,
      yardSelected: state => state.yard.yardSelected,
    })
  },
  beforeDestroy() {
    this.closeCollapse();
  },
  watch: {

  }
}
</script>

<style lang="scss" >
@import './css/style.scss';

.dropdowntab>.custom-tab-link {
  padding: 0px;
}

.custom-nav li>.custom-tab-link {
  .dropdown {
    width: 100%;
    padding: 0 20px;

    .dropdown-toggle {
      color: white;
      opacity: 1;
      padding-left: 0;
      padding-top: 0;

    }

    .dropdown-toggle::after {
      margin-left: 0.255em;
      margin-right: 1.0em;
    }
  }
}

.custom-nav li.active>.custom-tab-link {
  .dropdown {
    width: 100%;
    padding: 0 20px;

    .dropdown-toggle {
      color: #000;
      opacity: 1;
    }

    .dropdown-toggle::after {
      margin-left: 0.255em;
      margin-right: 1.0em;
    }
  }
}

.iconTab {
  max-width: 100%;
  height: auto;
  border-radius: 0.25rem !important;
  margin-left: 1.5rem !important;
  margin-top: -0.4rem !important;
}

.separate-list-item {
  border-bottom: 1px solid #fff !important;
}

</style>