<template>
  <div style="width: 100%" class="mt-2">
    <CRow>
      <CCol sm="12">
        <div class="card-planning">
          <div
            v-for="(vessel, index) in planningData"
            :key="index+'vessel'"
            class="item-wrapper"
          >
            <div
              class="item-header"
              :class="vessel.class"
            >
              <div class="d-flex justify-content-center align-content-center">
                  <div class="" style="margin-right:5px;">
                      <img v-if="!vessel.iconShow" style="color:#FFF" class="img-fluid rounded" height="20px" width="20px" :src="vessel.ImgIcon" alt="Card image cap">
                      <CIcon v-else style="color:#FFF" :name="vessel.nameIcon"/>
                  </div>
                  <div class="" style="color:#FFF">{{vessel.label}}</div>
              </div>
            </div>
            <div class="item-content">
              <div 
                class="item-value item-vessel-value"
                :class="{'item-vessel-value-center': true}"
                v-for="(item, i) in vessel.values"
                :key="i+'values'"
              >
                  {{item}}
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';

//data
function data() {
  return {};
}
//methods

//computed
function planningData() {
  return [
    {
      label: this.$t('label.vessel'),
      class: 'bg-vessel',
      iconShow: false,
      ImgIcon : '/situacionOperativa/buque.svg',
      values: this.getVessels,
    },
    {
      label: 'ETA/ETD',
      class: 'bg-eta',
      iconShow: true,
      nameIcon : 'cil-calendar',
      values: this.getEtas,
    },
    {
      label: 'ATA/ATD',
      class: 'bg-ata',
      iconShow: true,
      nameIcon : 'cil-calendar',
      values: this.getAtas,
    },
    {
      label: this.$t('label.stevedore'),
      class: 'bg-estibador',
      iconShow: false,
      ImgIcon : '/situacionOperativa/estibador.svg',
      values: this.getEstibadores,
    },
    {
      label: this.$t('label.status'),
      class: 'bg-status',
      iconShow: false,
      ImgIcon : '/situacionOperativa/estatus.svg',
      values: this.getStatus,
    },
    {
      label: this.$t('label.berth'),
      class: 'bg-berth',
      iconShow: false,
      ImgIcon : '/situacionOperativa/muelle.svg',
      values: this.getBerth,
    },
    {
      label: this.$t('label.details'),
      class: 'bg-details',
      iconShow: true,
      nameIcon : 'cil-document',
      values: this.getDetails,
    },
  ];
}

function getVessels() {
  return this.myDataVessel.map((item) => {
    return item.VesselName;
  });
}
function getEtas() {
  return this.myDataVessel.map((item) => {
    let Eta = item.Eta ? DateFormater.formatDateTimeWithSlash(item.Eta) : 'N/A';
    let Etd = item.Etd ? DateFormater.formatDateTimeWithSlash(item.Etd) : 'N/A';
    return Eta + ' ' + Etd;
  });
}
function getAtas() {
  return this.myDataVessel.map((item) => {
    let Ata = item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A';
    let Atd = item.Atd ? DateFormater.formatDateTimeWithSlash(item.Atd) : 'N/A';
    if(Ata === 'N/A' && Atd === 'N/A'){
      return 'N/A'
    }
    return Ata + ' ' + Atd;
  });
}
function getEstibadores() {
  return this.myDataVessel.map((item) => {
    return item.PortOperatorName;
  });
}
function getDetails() {
  return this.myDataVessel.map((item) => {
    return this.$i18n.locale == "es" ? item.DetailEs : item.DetailEn;
  });
}
function getStatus() {
  return this.myDataVessel.map((item) => {
    return this.$i18n.locale == "es" ? item.StatusEs : item.StatusEn;
  });
}
function getBerth() {
  return this.myDataVessel.map((item) => {
    return item.BerthName ? item.BerthName : 'N/A';
  });
}
function myDataVessel() {
  return this.info;
}

export default {
  name: "PlanningList",
  props: {
    info: {
      type: Array,
      default: () => [],
    },
  },
  data,
  components: {},
  methods: {},
  mounted() {},
  computed: {
    planningData,
    getVessels,
    getEtas,
    getAtas,
    getEstibadores,
    getDetails,
    getStatus,
    getBerth,
    myDataVessel,
  },
  watch: {
    
  },
};
</script>

<style scoped>
.card-planning {
  display: flex;
  width: 100%;
}
.item-wrapper {
  margin: 0 5px;
  width: calc(calc(100% / 7) - 10px);
  min-height: 750px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.item-header {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  background-color: #78909C;
  border-radius: 3px;
  padding: 5px 0;
}
.item-content {
  margin-top: 10px;
  width: 100%;
  height: 100%;

  border: 2px solid #c2c2c2;
  border-radius: 3px;

  overflow-y: auto;
  scrollbar-width: thin;
}
.item-value {
  width: 100%;
  height: calc(calc(100% / 5));

  padding: 0px 5px;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #c2c2c2;
  border-top: none;
  border-left: none;
  border-right: none;
}
.item-vessel-value {
  text-align: left;
  justify-content: flex-start;
}
.item-vessel-value-center {
  text-align: center;
  justify-content: center;
}

.bg-vessel {
  background-color: #3b70c0;
}
.bg-eta {
  background-color: #ec631b;
}
.bg-ata {
  background-color: #1e3a61;
}
.bg-estibador {
  background-color: #42aa91;
}
.bg-detalle {
  background-color: #838c9d;
}
.bg-status {
  background-color: #7092BE;
}
.bg-berth {
  background-color: #d8cb1d;
}
</style>