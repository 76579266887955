import {
  required,
} from 'vuelidate/lib/validators';

const alphaNumCustom = (value) => /^[\w\u00f1\u00d1 \-]+$/g.test(value);

export default () => {
  return {
    modalityCircuitInfo: {
      ModalityCircuitId: {},
      CircuitId: { required },
      Status: {  },
    },
  }
}