<template>
  <div class="pa-2 mt-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        square
        color="add"
        class="d-flex align-items-center"
        @click="toggleShowModalCreateState"
      >
        <CIcon name="cil-playlist-add" />
        <span class="ml-1">{{$t('label.packagingRelationship')}}</span>
      </CButton>
    </CCol>
    <CCol sm="12" lg="4" class="justify-content-start">
      <CSelect
        :label="$t('label.cargoState')"
        addLabelClasses="text-right"
        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-7'}"
        :options="computedBulkCargoSelect"
        @change="stateSelectedFilter($event)"
        :value="stateSelected"
      />
    </CCol>
    <CCol>
      <dataTableExtended
        class="align-center-row-datatable"
        size="sm"
        :fields="fields"
        :items="computedBulkCargoPackaging"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <!--
        <template #over-table>
          <div class="row justify-content-start" style="margin-top: -2.8rem">
            <div
              class="col-auto ml-2 mt-2"
              style="margin-left: 16rem !important"
            >
              {{$t('label.cargoState')}}:
            </div>
            <div class="col-3">
              <CSelect
                :options="computedBulkCargoSelect"
                @change="stateSelectedFilter($event)"
                :value="stateSelected"
              />
            </div>
          </div>
        </template> -->
        <template #Status="{ item }">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #options="{ item }">
          <td class="center-cell">
            <CButton
              color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="toggleShowModalEditBulkCargo(item.TpCargoId)"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  
    <modalEmbalaje
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="mountedBulkCargoPackaging"
      :idBulkCargoSelected="idBulkCargoSelected"
    />
  </div>
</template>
<script>
import modalEmbalaje from './modal-embalaje';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import { TableIndexMixin } from '@/_mixins/carga-granel';


function data() {
  return {
    itemsBulkCargoPackaging: [],
    showModal: false,
    titleModal: '',
    editModal: false,
    idBulkCargoSelected: '',
    itemsBulkCargoSelect: [],
    temporalItemBulkCargoPackagin: [],
    stateSelected: 'N/A',
    Loading: false
  };
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter:false,
      _style: 'width:3%; text-align:center',
    },
    { key: 'TpCargoName', label: this.$t('label.cargoState'),  _style:'width:30%;',  _classes:"text-center"},
    {  key: 'PackagingName', label: this.$t('label.packaging'), _style:'width:30%;', _classes:"text-center" },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"text-center" },
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;',_classes:"text-center" },
    {  key: 'options',
      label: '', 
      _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false,
    },
  ];
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedBulkCargoPackaging() {
  //this.loadingOverlay = true;
  this.idBulkCargoSelected = '';
  this.stateSelected = 'N/A';
  this.Loading = true;

  await this.$http
    .get('BulkCargoPackaging-list?TpCargoId=')
    .then((response) => {
      this.itemsBulkCargoPackaging = response.data.data;
      this.temporalItemBulkCargoPackagin = this.itemsBulkCargoPackaging;
    })
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.notifyError({text: e});
    });
  await this.mountedBulkCargo();
}

//### Funcion computed para formatear los datos de la tabla ###
function computedBulkCargoPackaging() {
  if (this.itemsBulkCargoPackaging.length > 0) {
    return this.itemsBulkCargoPackaging.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateState() {
  this.showModal = true;
  this.titleModal = this.$t('label.bulkCargoRelationship');
}

//### ACtualiza el estado una vez cerrado el modal
function updateShowModalCreate(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.idBulkCargoSelected = '';
}

function toggleShowModalEditBulkCargo(id) {
  this.idBulkCargoSelected = id;
  this.showModal = true;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.bulkCargoRelationship');
  this.editModal = true;
}

function stateSelectedFilter(event) {
  this.stateSelected = event.target.value;
  this.itemsBulkCargoPackaging = this.temporalItemBulkCargoPackagin;
  if (event.target.value === 'N/A') {
    this.itemsBulkCargoPackaging = this.temporalItemBulkCargoPackagin;
  } else {
    this.itemsBulkCargoPackaging = this.itemsBulkCargoPackaging.filter(
      (elelment) => elelment.TpCargoId === event.target.value
    );
  }
}

function handleTab(tab) {
    this.Tab = tab;
}

export default {
  name: 'embalajeCgaGranel',
  data,
  props:{Tab: Number},
  mixins: [
    GeneralMixin,
    TableIndexMixin
  ],
  components: {
    modalEmbalaje
  },
  methods: {
    toggleShowModalCreateState,
    updateShowModalCreate,
    mountedBulkCargoPackaging,
    toggleShowModalEditBulkCargo,
    stateSelectedFilter,
    handleTab
  },
  computed: {
    computedBulkCargoPackaging,
    fields,
  },
  watch:{
    Tab: async function (val){
      if(val == 1){
        await this.mountedBulkCargoPackaging();
      }
    }
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
