<template>
  <CRow>
    <CCol sm="12" lg="12">
      <CCol sm="12" class="mb-2">
        <vue-editor
          size="sm"
          :horizontal="{label:'col-sm-12 col-lg-12', input:'col-sm-12 col-lg-12'}"
          v-model="vForm.DescriptionOfGoods.DescriptionOfGoods.$model"
          :is-valid="hasError(vForm.DescriptionOfGoods.DescriptionOfGoods)"
          :invalid-feedback="errorMessage(vForm.DescriptionOfGoods.DescriptionOfGoods)"
          required
          :class="!vForm.DescriptionOfGoods.DescriptionOfGoods.$dirty ? '' : (vForm.DescriptionOfGoods.DescriptionOfGoods.$error ? 'select-description--error' : 'select-description--correct')"
          :disabled="fgInfo"
        >
        </vue-editor>
        <CCol v-if="vForm.DescriptionOfGoods.DescriptionOfGoods.$error" sm="12" class="pl-0 ml-0 mr-0">
          <div class="col-sm-12 text-danger mt-1 pr-0 pl-0 mr-0 ml-0 text-left text-editor" v-if="!vForm.DescriptionOfGoods.DescriptionOfGoods.required">
            {{$t('label.required')}}
          </div>
        </Ccol>
      </CCol>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { VueEditor } from "vue2-editor";

//-----------------------   Method    ------------------------
async function resetForm() {
  //DescriptionOfGoods
  this.form.DescriptionOfGoods.DescriptionOfGoods = '';
}


//-----------------------   Computed   ------------------------

export default {
  name: 'notifyParty-tab',

  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    isEdit: Boolean,
    fgInfo: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
  },
  components: {
    VueEditor
  },
  mixins: [
    ModalMixin, 
    GeneralMixin,
  ],
  methods: {
    resetForm
  },
  computed: {

  },
  directives: UpperCase,
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
  }
};
</script>