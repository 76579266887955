<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="xl"
      class="pa-3"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow>
        <CCol sm='6' class="mt-3 pr-5">
          <CRow>
            <CCol sm="12">
              <CInput
                :label="`${$t('label.name')} (ES)`"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                :placeholder="`${$t('label.groupName')} (ES)`"
                @keypress="f_letras($event)"
                v-uppercase
                size="sm"
                :is-valid="hasError($v.TpCargoNameEs)"
                :invalid-feedback="errorMessage($v.TpCargoNameEs)"
                v-model.trim="$v.TpCargoNameEs.$model"
              />
            </CCol>
            <CCol sm="12">
              <CInput
                :label="`${$t('label.name')} (EN)`"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                :placeholder="`${$t('label.groupName')} (EN)`"
                @keypress="f_letras($event)"
                v-uppercase
                size="sm"
                :is-valid="hasError($v.TpCargoNameEn)"
                :invalid-feedback="errorMessage($v.TpCargoNameEn)"
                v-model.trim="$v.TpCargoNameEn.$model"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                :label="$t('label.classification')"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                v-uppercase
                size="sm"
                :options="optionsApplies"
                :value="FgClassification"
                @change="selectedCLasification($event)"
                :is-valid="hasError($v.FgClassification)"
                :invalid-feedback="errorMessage($v.FgClassification)"
                v-model.trim="$v.FgClassification.$model"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                :label="$t('label.packaging')"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                v-uppercase
                size="sm"
                :options="optionsApplies"
                :value="FgPackaging"
                @change="selectedEmbalaje($event)"
                :is-valid="hasError($v.FgPackaging)"
                :invalid-feedback="errorMessage($v.FgPackaging)"
                v-model.trim="$v.FgPackaging.$model"
              />
            </CCol>
            
            <CCol sm="12">
              <CInput
                label="CODE"
                addLabelClasses="text-right required"
                :placeholder="$t('label.code')"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                @keypress="f_letras($event)"
                v-uppercase
                size="sm"
                :is-valid="hasError($v.TpCargoCode)"
                :invalid-feedback="errorMessage($v.TpCargoCode)"
                v-model.trim="$v.TpCargoCode.$model"
                maxLength="5"
              />
            </CCol>
            <CCol sm="12">
              <CTextarea
                rows="4"
                :label="$t('label.description')"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                :placeholder="$t('label.groupDescription')"
                v-uppercase
                size="sm"
                :is-valid="hasError($v.TpCargoDs)"
                :invalid-feedback="errorMessage($v.TpCargoDs)"
                v-model.trim="$v.TpCargoDs.$model"
              />
            </CCol>
            <CCol sm="12" v-if="editModal">
              <CSelect
                :label="$t('label.status')"
                addLabelClasses="text-right required"
                :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                v-uppercase
                size="sm"
                :options="statusOptions"
                :invalid-feedback="errorMessage($v.Status)"
                :value="Status"
                @change="selectedStatus($event)"
                :is-valid="hasError($v.Status)"
                v-model.trim="$v.Status.$model"
              />
            </CCol>
          </CRow>
        </CCol> 
      <CCol sm="6" class="pr-5">
        <dataTableExtended v-if="showModal"
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="computedUnitMeasureList"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #checked="{ item }">
            <td class="center-cell">
              <CInputCheckbox
                custom
                class="checkbook-carga-general"
                :checked="item.initialActive"
                @update:checked="updatedJsonMeasure($event, item)"
              />
            </td>
          </template>
          <template #checked-filter>
            <td class="center-cell" style="border-top:none">
              <CInputCheckbox
                :label="$t('label.all')"
                class="checkbook-carga-general"
                custom
                @update:checked="selectAllUnitsMeasures($event)"
              />
            </td>
          </template>
        </dataTableExtended>
          <p v-if="TpUnitMeasureJson.length === 0" class="mt-2 text-danger required">
            {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')+' 1 '+$t('label.unitType')}}
          </p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!editModal"
          outline
          color="add"
          @click="submit"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton 
          v-if="editModal" 
          outline 
          color="add" 
          @click="statusConfirmation(GpoCargaSelected.FgActTpCargo, Status, submit)"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import { formatedDateItem } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import grupoValidations from '@/_validations/carga-general/grupoValidations';
import ImdgMixin from '@/_mixins/imdg';
import CargaGranel from '@/_mixins/carga-granel'


function data() {
  return {
    isEdit: false,
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    unitMeasureItems: [],
    itemsgrupoCargo: [],
    itemMeasureChecked: [],
    itemMeasureCheckedUpdated: [],
    itemsGrupoPackagingUpdated: [],
    itemsDrupaGeneralUpdated: [],
    TpUnitMeasureJson: [],
    TpCargoId: '',
    selectAll: false,
    resetSelectAll: false,
    Status: 1,
    TpCargoNameEs: '',
    TpCargoNameEn: '',
    TpCargoDs: '',
    FgPackaging: 1,
    FgClassification: 1,
    TpCargoCode: '',
    TpCargoId: '',
    isSubmit: false,
  };
}

function fields() {
  return [
    { label: '#', key: 'Nro', sorter: false, filter:false,  _style: 'width:3%; text-align:center', },
    {
      label: this.$t('label.unitTypes'),
      key: 'TpUnitMeasureName', _style:'width:50%;'
    },
    {
      label: '',
      key: 'checked',
      sorter: false,
      filter: false,
      _style:'width:10%;'
    },
  ];
}



function selectedEmbalaje(event) {
  this.FgPackaging = event.target.value;
}

function selectedCLasification(event) {
  this.FgClassification = event.target.value;
}

async function mountedTpUnitMeasure() {
  this.loadingTable = true;
  await this.$http
    .get('UnitMeasureDetail-List?Filter=ACTIVO')
    .then((response) => {
      if (response.data.data.length > 0 && !this.editModal) {
        this.unitMeasureItems = response.data.data;
        const tpUnitTemporal = {
          TpUnitMeasureId: response.data.data[0].TpUnitMeasureId,
        };
        this.TpUnitMeasureJson.push(tpUnitTemporal);
      }
      if (response.data.data.length > 0 && this.editModal) {
        if (
          this.GpoCargaSelected &&
          this.GpoCargaSelected.TpCargoUnitMeasureJson !== null
        ) {
          
          let temporalUnitMeasureItems = [];
          response.data.data.map((item) => {
            let temporalTpUnitMeasureobject = this.GpoCargaSelected.TpCargoUnitMeasureJson.filter(
              (element) => element.TpUnitMeasureId === item.TpUnitMeasureId
            );
            if (
              temporalTpUnitMeasureobject.length > 0 &&
              temporalTpUnitMeasureobject[0].Status === 'ACTIVO'
            ) {
              const temporalItem = {
                ...item,
                initialActive: true,
              };
              const tpUnitTemporal = {
                TpUnitMeasureId: item.TpUnitMeasureId,
              };
              this.TpUnitMeasureJson.push(tpUnitTemporal);
              temporalUnitMeasureItems.push(temporalItem);
            } else {
              const temporalItem = {
                ...item,
                initialActive: false,
              };
              temporalUnitMeasureItems.push(temporalItem);
            }
          });
          this.unitMeasureItems = temporalUnitMeasureItems;
        } else {
          this.unitMeasureItems = response.data.data;
        }
      }
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.notifyError({text: e})
    });
}

//### Function para iniciar la data de la tabla ###
function computedUnitMeasureList() {
  if (this.unitMeasureItems.length > 0) {
    return this.unitMeasureItems.map((item, index) => {
      if (!this.selectAll && !this.resetSelectAll) {
        const UnitMeasure = this.TpUnitMeasureJson.filter(
          (element) => item.TpUnitMeasureId === element.TpUnitMeasureId
        );
        return formatedDateItem(item, UnitMeasure.length > 0);
      }
      if (this.selectAll && !this.resetSelectAll) {
        const temporalUnitMeasure = {
          TpUnitMeasureId: item.TpUnitMeasureId,
        };
        this.TpUnitMeasureJson.push(temporalUnitMeasure);
        return formatedDateItem(item, true);
      }
      if (!this.selectAll && this.resetSelectAll) {
        return formatedDateItem(item, false);
      }
    });
  }
}

function selectedStatus(event){
  this.Status = event.target.value
}

// ### Actualiza los datos de los TpUnitMeasureJson ###
async function updatedJsonMeasure(event, item) {
  this.selectAll = false;
  this.resetSelectAll = false;
  const tpUnitTemporal = {
    TpUnitMeasureId: item.TpUnitMeasureId,
  };
  if (event === true) {
    this.TpUnitMeasureJson.push(tpUnitTemporal);
  } 
  if(event === false) {
    const i =  await this.arrayObjectIndexOf(this.TpUnitMeasureJson, item.TpUnitMeasureId, 'TpUnitMeasureId' );
    if (i !== -1) {
      this.TpUnitMeasureJson.splice(i, 1);
    }
  }
}

function selectAllUnitsMeasures(event) {
  this.TpUnitMeasureJson = [];
  if (event === true) {
    this.selectAll = true;
    this.resetSelectAll = false;
  } else {
    this.selectAll = false;
    this.resetSelectAll = true;
  }
}

function resetForm(){
  this.isEdit = false;
  this.TpCargoId='';
  this.TpCargoNameEs='';
  this.TpCargoNameEn='';
  this.TpCargoDs='';
  this.TpCargoCode='';
  this.FgPackaging=1;
  this.FgClassification=1;
  this.TpUnitMeasureJson=[];
  this.Status=1;
  this.$v.$reset();

}

async function submit() {
  try {

    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(this.editModal === true && this.TpUnitMeasureJson.length === 0 && this.Status === 1){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpCargoJson = this.editModal? {
      TpCargoId: this.TpCargoId,
      TpCargoNameEs: this.TpCargoNameEs,
      TpCargoNameEn: this.TpCargoNameEn,
      TpCargoDs: this.TpCargoDs,
      TpCargoCode: this.TpCargoCode,
      FgPackaging: this.FgPackaging,
      FgClassification: this.FgClassification,
      TpUnitMeasureJson: this.TpUnitMeasureJson,
      Status: this.Status
    }:{
      TpCargoNameEs: this.TpCargoNameEs,
      TpCargoNameEn: this.TpCargoNameEn,
      TpCargoDs: this.TpCargoDs,
      TpCargoCode: this.TpCargoCode,
      FgPackaging: this.FgPackaging,
      FgClassification: this.FgClassification,
      TpUnitMeasureJson: this.TpUnitMeasureJson,
    };
    let method = this.editModal? 'PUT': 'POST';
    let ruta = this.editModal? 'TpGeneralCargo-update':'TpGeneralCargo-insert';
    await this.$http
      .ejecutar(method,ruta,TpCargoJson, { root: 'TpCargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.closeModal(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.notifyError({ text: e });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
}

function closeModal(event) {
  this.showModal = false;
  this.TpUnitMeasureJson = [];
  this.resetForm();
  this.$emit('updated-modal', event);
}

//computed 
function optionsApplies(){
  return [
      { label: 'N/A', value: 0 },
      { label: this.$t('label.applies'), value: 1 },
  ];
}

export default {
  name: 'modalGrupo',
  data,
  props: {
    modal: null,
    GpoCargaSelected: Object,
    title: String,
    editModal: Boolean,
  },
  mixins: [
    GeneralMixin, 
    ImdgMixin, 
    CargaGranel,
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    closeModal,
    submit,
    mountedTpUnitMeasure,
    selectedEmbalaje,
    selectedCLasification,
    selectAllUnitsMeasures,
    updatedJsonMeasure,
    resetForm,
    selectedStatus,
  },
  validations: grupoValidations,
  computed: {
    computedUnitMeasureList,
    fields,
    optionsApplies,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
      if(val === true){
        this.mountedTpUnitMeasure();
      }
    },
    GpoCargaSelected: function(val){
      if (Object.keys(val).length !== 0) {
        this.TpCargoId = val.TpCargoId
        this.TpCargoDs = val.TpCargoDs;
        this.TpCargoCode = val.TpCargoCode;
        this.TpCargoNameEs = val.TpCargoNameEs;
        this.TpCargoNameEn = val.TpCargoNameEn;
        this.FgClassification = val.FgClassification ?  1 : 0;
        this.FgPackaging = val.FgPackaging ?  1 : 0;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.$v.$touch();
        }
    },

  },
  
};
</script>
<style lang="scss">
.text-area-descripcion-grupo {
  textarea {
    resize: none !important;
  }
}
.checkbook-carga-general {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2eb85c;
    background-color: #2eb85c;
  }
}

.center-cell {
  text-align: center;
}
 
</style>
