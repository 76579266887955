<template>
  <div>
    <div v-show="showIndex==0">
      <div class="align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
        <CRow class="align-items-center justify-content-center">
          <CCol sm="6" class="align-items-center justify-content-center">
            <CRow>
              <CCol sm="12" xl="12">
                <b>{{`${$t('label.inspections')} / ${$t('label.damageOption')}`}}</b>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="6">
            <CRow>
              <CCol sm="12" xl="12" class="align-items-center">
                <CButton
                  shape="square"
                  color="add"
                  class="d-flex align-items-center ml-auto"
                  v-c-tooltip="{
                    content: $t('label.damage'),
                    placement: 'top-end'
                  }"
                  @click="showIndex=1"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                >

          

                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                      </td>
                    </template>
                    <template #button="{item}">
                        <td class="text-center">
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                content: `${$t('label.edit')} ${$t('label.damage')}`,
                                placement: 'top-start'
                              }"
                              @click="toggleAdd(item)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <CButton
                              v-if="viewBottom(item)"
                              color="watch"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: `${$t('label.damage')}`,
                                placement: 'top-start'
                              }"
                              @click="ViewImages(item)"
                            >
                              <CIcon name="eye"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <DamageRegister
          :Active="showIndex==1"
          :isEdit="isEdit"
          :DamageItem="DamageItem"
          @Close="(showIndex=0, DamageItem={}, isEdit=false)"
          @Update-list="UpdateData"
      />
    </div>
    <ModalImagesList
      :modal="ModalImages"
      :Images="ImagesList"
      :titleModal="ImagenModalTitle"
      @Close="ModalImages=false,ImagesList=[],ImagenModalTitle=''"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import DamageRegister from './damage-register';
import ModalImagesList from './modal-images-list';

//DATA-------------------------
function data() {
  return {
    showIndex: 0,
    DamageItem:{},
    DamageList :[],
    isEdit: false,
    ModalImages: false,
    ImagesList: [],
    ImagenModalTitle: '',
  }
}

//methods
async function UpdateData() {
  await this.getYardCargoInspDamage();
}

function ViewImages(item) {

  try {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ImagenModalTitle = item[`TpInspectionName${_lang}`]+': '+item[`TpCargoViewName${_lang}`] ?? '';

    item.InspectionDamagePhotographyJson.map((e)=>{
      let extension = e.FileValue.split(".").pop();

      if(e.FileValue && (extension == 'jpg' || extension == 'png'))
        this.ImagesList.push(e)
    })

    if(this.ImagesList.length>0)
      this.ModalImages = true;
    else
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noImages'),
        type: "error"
      });

  } catch (error) {}
}

async function getYardCargoInspDamage() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargoInspDamage-by-CargoId', { CargoId: this.dataContainer.CargoId})
    .then(response => {
      this.DamageList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
        this.$store.state.yardManagement.loading = false;
    });
}

function toggleAdd(item) {
  this.DamageItem = item;
  this.isEdit = true;
  this.showIndex = 1;
}

//computed
function fields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width: 90px; width: 1%;', 
      sorter: false, 
      filter: false
    },
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'min-width:45px; width:1%;'  },
    { key: 'TpDamageName', label:this.$t('label.damageType'), _style: 'min-width:180px;', _classes: 'text-center'},
    { key: 'TpCargoViewName', label:this.$t('label.localizacion'),_style: 'min-width:180px;', _classes: 'text-center' },
    { key: 'Height', label:this.$t('label.height')+'(CM)',_style: 'min-width:180px;', _classes: 'text-center' },
    { key: 'Width', label:this.$t('label.width')+'(CM)',_style: 'min-width:180px;', _classes: 'text-center' },
    { key: 'Dimension', label:this.$t('label.dimensions'),_style: 'min-width:180px;', _classes: 'text-center' },
    { key: 'Responsible', label:this.$t('label.responsible'), _style: 'min-width:180px;', _classes: 'text-center'},
    { key: 'DeviceCode',label: this.$t('label.device'), _style:'min-width:180px;', _classes: 'text-center',},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width:180px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedTransactionDate', label: this.$t('label.date'),  _style:'min-width: 140px;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width:140px', _classes:"text-center" },
  ];
}

function formatedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.DamageList.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      TpDamageName: item[`TpDamageName${_lang}`] ?? '',
      TpCargoViewName: item[`TpCargoViewName${_lang}`] ?? '',
      Dimension: item.Width&&item.Height ? `${item.Width} x ${item.Height}` : '',
      TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      ComputedTransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaLogin: item.TransaLogin ? item.TransaLogin : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): '',
    };
  })
}

function viewBottom(item) {
  let comprobation = false;
  item.InspectionDamagePhotographyJson.map((e)=>{
      let extension = e.FileValue.split(".").pop();

      if(e.FileValue && (extension == 'jpg' || extension == 'png'))
        comprobation = true;
  })

  return comprobation;
}

export default {
  name: 'damage-index',
  components: {
    DamageRegister,
    ModalImagesList
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    toggleAdd,
    getYardCargoInspDamage,
    UpdateData,
    ViewImages,
    viewBottom
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
        dropItemInspection: state => state.yardManagement.dropItemInspection,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    dropItemInspection: function (val) {
      if(val == 1){
        this.showIndex = 0;
        this.getYardCargoInspDamage();
      }
    },
  },
};
</script>