import {
  required,
  maxLength,
} from 'vuelidate/lib/validators';

const nameLength = (value) => {
  let Array = value.split(' ');
  for (let index = 0; index < Array.length; index++) {
    if(Array[index].length > 20){
      return false
    };
  }
  return true;
};

const alphaNum = (value) => /^[a-zA-Z0-9 \u00f1\u00d1]+$/g.test(value);

  export const FormIncidence = (FgActOrderUpdate) => {
    let orderUpdate = false;
    if(FgActOrderUpdate) { 
      orderUpdate = { } 
    } else {
      orderUpdate = { required }
    }
  return {
    FormIncidence: {
      incidence: {
        IncidenceId: { required },
        checkerId: { }, 
        duration: {  },
        hourStart: { required },
        dateStart: { required },
        hourEnd: orderUpdate,
        dateEnd: orderUpdate,
        observation: { required, maxLength: maxLength(150), nameLength, alphaNum},
        },
    
      order: {
        StowagePlanningResourceId: { required },
        StowagePlanningDriverId: { },
        StowagePlanningVehicleId: { required },
        YardId: { required },
        StowagePlanningImplementId: { required }, 
        blId: { }, 
        YardClientTpId: { required },

        transportName: { },
        driverName: { },
        vehicleName: { },
        yardName: { },
        StowagePlanningImplementVehicleId: { },
      },
    }, 
  }
}