<template>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.EventList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow class="mt-2">
          <CCol sm="12" lg="12" xl="12">
            <CustomTabs :activeTab="Tab" @update:activeTab="handleTab">
              <CustomTab :title="$t('label.classification')">
                <Classification :Tab="Tab"/>
              </CustomTab>
              <CustomTab :title="$t('label.Event')">
                <Event :Tab="Tab"/>
              </CustomTab>
              <CustomTab :title="$t('label.cargoType')">
                <CargoType :Tab="Tab"/>
              </CustomTab>
              <CustomTab :title="$t('label.EventsPerYard')">
                <EventsPerYard :Tab="Tab"/>
              </CustomTab>
              <CustomTab :title="$t('label.eventForReason')">
                <EventReason :Tab="Tab"/>
              </CustomTab>
            </CustomTabs>  
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import Classification from './classification/classification-index'
import Event from './event/event-index'
import CargoType from './cargo-type/cargo-type-index';
import EventsPerYard from './events-per-yard/events-per-yard-index';
import EventReason from './event-reason/event-reason-index';

function data() {
  return {
    Tab: 0,
  };
}
  
//methods
function handleTab(tab) {
  this.Tab = tab;
}
  
export default {
  name: 'event-master-index',
  data,
  components: { 
    CustomTabs, 
    CustomTab,
		Classification,
		Event,
    CargoType,
    EventsPerYard,
    EventReason,
  },
  methods: {
    handleTab,
  },
};
</script>