import {
    required,
    maxLength,
    alpha,
  } from 'vuelidate/lib/validators';
  
  import { onlyText } from '../funciones';
  
  export default () => {
    return {
      bascula: {
        nameScale: { required, maxLength: maxLength(50) },
        maxWeight: { required },
        UnitMeasureId: { required },
      }
    }
  }