import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { NumberFormater, DateFormater } from "@/_helpers/funciones";
import { minValue, maxValue } from "vuelidate/lib/validators";

export default (
    maquinaryIsRequired, 
    Form, 
    Serialization, 
    SerialitationVolumen = 0, 
    SerialitationWeigth = 0,
    PackagingItem,
    ContainerItem,
    PackagingJsonList,
    isEditPackaging = false,
    isReceptionEdit = false,
    isSerialEdit = false,
    isDispatch = false,
    fgDispatch = false,
) => {

    //Totals per Item
    //let totalQuantity = PackagingJsonList?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0) ?? 0;
    //let totalWeight = PackagingJsonList?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0) ?? 0;
    //let TotalVolume = NumberFormater.formatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);

    //Max in Quantity
    //let maxTotalPackagingQuantity = ((NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity)<0) ? ((NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity)*-1) : (NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity);
    let maxPackagingQuantity = 0;

    if(!isReceptionEdit)
        maxPackagingQuantity = Form.Packaging.maxPackagingQuantity //!isEditPackaging ? maxTotalPackagingQuantity : maxTotalPackagingQuantity + NumberFormater.setNum(Form?.Packaging?.PackagingItem.Quantity);
    else
        maxPackagingQuantity = Form.Packaging.maxPackagingQuantity;

    //ESTE VALOR HA SIDO COMENTADO PARA DEJAR LIBRE EL VOLUMEN Y HASTA CONOCER CORRECTAMENTE SI SE VALIDARA EL VOLUMEN CON LIMITACIONES POR BL O NO 
    let maxVolumen = false;// (Form?.Packaging?.maxPackagingVolumen && Form.Packaging.maxPackagingVolumen > 0) ? Form.Packaging.maxPackagingVolumen : false;
    // let Length =  0;
    // let Width = 0; 
    // let Height =  0;

    //Max in Weight
    //let maxTotalPackagingWeight = (((totalWeight ?? 0)  - Form?.Packaging?.maxPackagingWeight)<0) ? (((totalWeight ?? 0) - Form?.Packaging?.maxPackagingWeight)*-1) : ((totalWeight ?? 0) - Form?.Packaging?.maxPackagingWeight);
    let maxWeight = maxWeight = (Form?.Packaging?.maxPackagingWeight && Form.Packaging.maxPackagingWeight > 0) ? (Form?.Packaging?.maxPackagingWeight) : false;
    let maxWeightSerial = 0;
    if (fgDispatch) {
        maxWeightSerial = PackagingItem.maxPackagingWeight && (PackagingItem.maxPackagingWeight > 0) ? PackagingItem.maxPackagingWeight : false;
    }
    
    //Max per cant in serialitation
    //let maxPackaginVolumen = (PackagingItem?.maxPackagingVolumen && PackagingItem?.maxPackagingVolumen > 0) ? PackagingItem?.maxPackagingVolumen : (PackagingItem?.maxPackagingVolumen == 0) ? ContainerItem.Volumen : 0;
    let maxPackaginWeight = (PackagingItem?.maxPackagingWeight && PackagingItem?.maxPackagingWeight > 0) ? PackagingItem.maxPackagingWeight : (PackagingItem?.maxPackagingWeight > 0) ? ContainerItem.Weight : 0;

    //Max per cant of packagin in serialitation
    // let PackagingVolumen = PackagingItem?.Volumen ?? 0;
    // let PackagingWeigth = PackagingItem?.Weigth ?? 0;
    let IsNewSerial = Serialization.IsNewSerial ?? false;
    let serialitation = IsNewSerial ?  true : {required};

    //Total of Value of Serialitation
    let totalSerialWeight = PackagingItem?.SerialJson ? (PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0) : 0;
    let TotalSerialVolume = PackagingItem?.SerialJson ? (PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0) : 0;

    let TransactionDate =  { required, RangeDate(value){ return(DateFormater.formatDateTimeWithoutSlash(value) >= Form.BasicData.ValidPreviousDate)  && DateFormater.formatDateTimeWithoutSlash(value) <= DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(Form.BasicData.laterDate))} };
    return {
        Form:{
            //Form Basic
            BasicData: {
                Observation: { onlyAlphanumeric5 },
                MachineId: maquinaryIsRequired ? {required} : {},
                TransactionDate: TransactionDate,
                DocumentComment: {},
                TpEirId: {},
                TpEirTransacId: {},
                Status: {},
                Destiny: {}
            },

            //Form Document
            Documents: {
                DocumentDs: {required},
            },

            //Form For Packaging
            Packaging: {
                YardAreaId: { },
                PackagingId: { required },
                BlCargoMasterDetailId: { },
                VisitBillOfLadingPackagingId: { },
                maxPackagingQuantity: { },
                maxPackagingWeight: { },
                maxPackagingVolumen: { },
                VisitBillOfLadingId: { },
                VolumeCheck: { },
                Quantity: { 
                    minValue: minValue(!fgDispatch ? ((PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).length ?? 0) : 0),
                    maxValue: maxValue(maxPackagingQuantity??0),
                    NotNegative(Value) { 
                        return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true 
                    },
                    required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
                },
                Volumen: {
                    //IncorrectVolumenValue(Value) { return (NumberFormater.setNum(SerialitationVolumen) > 0 && NumberFormater.setNum(SerialitationVolumen) != Value) ? false : true },
                    minValue: minValue(!fgDispatch ? (TotalSerialVolume) : 0),
                    maxValue: maxVolumen ? maxValue(maxVolumen) : true,
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Width > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Length: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Width > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Width: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Height: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Width > 0) && Value == 0 ? false : true }, 
                },
                Weigth: { 
                    minValue: minValue(!fgDispatch ? (maxWeight == 0 ? 1 : totalSerialWeight??0) : 1),
                    maxValue: (maxWeight || maxWeight == 0) ? maxValue(maxWeight) : true,
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //IncorrectVolumenWeightValue(Value) { return (NumberFormater.setNum(SerialitationWeigth) > 0 && NumberFormater.setNum(SerialitationWeigth) != Value) ? false : true },
                },
                //Seals 
                Seal: {
                    Seal1: {
                        Seal: { }
                    },
                    Seal2: {
                        Seal: { },
                    },
                    Seal3: {
                        Seal: { }
                    },
                    Seal4: {
                        Seal: { },
                    },
                },

                Serialization: {
                    Id: {},
                    Serial: { required },
                    PackagingId: {},
                    Serialized: serialitation,
                    DescriptionOfGoods: {},
                    VolumeCheck: {},
                    IsNewSerial: {},
                    Weight: {
                        maxValue: !fgDispatch ? ((maxWeight || maxWeight == 0) ? maxValue(maxWeight) : true) : ((maxWeightSerial || maxWeightSerial == 0) ? maxValue(maxWeightSerial) : true),
                        maxLengthDeclared(Value) { return ((( ((SerialitationWeigth) - (isSerialEdit ? Serialization?.SerialItem?.Weigth ?? 0 : 0)) + NumberFormater.setNum(Value))) > maxPackaginWeight)? false : true },
                        //maxLengthDeclared(Value) { return ((( NumberFormater.setNum(SerialitationWeigth) + NumberFormater.setNum(Value)) > maxPackaginWeight) || ( NumberFormater.setNum(SerialitationWeigth) + NumberFormater.setNum(Value)) > PackagingWeigth)? false : true },
                        NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                        required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
                    },
                    Volumen: {
                        NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                        //maxLengthDeclared(Value) { return (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > maxPackaginVolumen) ? false : true },
                        //maxLengthDeclared(Value) { return (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > maxPackaginVolumen) || (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > PackagingVolumen) ? false : true },
                    },
                    Length: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }},
                    Width: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }},
                    Height: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }},
                },
                SerialImdg: {
                    ImdgClass: { required },
                    ImdgId: { required },
                    PackagingGroup: {},
                    UnNumber: {},
                    DescriptionGoods: { maxLength: maxLength(250), onlyAlphanumeric5 },
                },
            },

            //Form Logistic
            Logistic: {
                CarrierId: isDispatch ? { required } : {},
                numberIdLetter: isDispatch ? { required } : {},
                SearchDriver: isDispatch ? { required } : {},
                DriverId: isDispatch ? { required } : {},
                SearchPlate: isDispatch ? { required } : {},
                VehicleId: isDispatch ? { required } : {},
            },

            //Form Modal packaging
            ModalPackaging: {
                SelectedPackagings: { required },
            },

            //Form Details
            DetailJson: {required(value){ return value.length > 0 }}
        },
        
    }
}