<template>
    <div style="width: 100%">
        <CRow class="cardResumen">
            <CCol md="12" lg="12">
                <div
                    class="cardHeaderResumenPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #203864 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <span style="margin-left:5px;"> {{ $t('label.summary_of_operations') }} MPH </span>
                </div>
                <CRow style="background: #fff;padding-top:10px;">
                    <CCol lg="12">
                        <div class="container">
                            <div class="d-flex justify-content-center">
                                <div class="p-2 flex-fill">
                                    <table class="table table-sm table-secondary" style="margin-bottom:0px;">
                                        <thead>
                                            <tr class="table-active">
                                                <th scope="col">{{ $t('label.Day') }}</th>
                                                <th scope="col" class="text-right" style="width: 25%;">{{ $t('label.TonxHour') }}</th>
                                                <th scope="col" class="text-right" style="width: 19%;">MPH</th>
                                                <th scope="col" class="text-right" style="width: 24%;">{{ $t('label.delays') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index) in OperationSumary" :key="index">
                                                <td>{{ item.OperationCode }} - {{ formatOperationDate(item.OperationDate) }}</td>
                                                <td class="text-right">{{ item.TonXHour ? valuemoney(item.TonXHour) : '0,00' }}</td>
                                                <td class="text-right">{{ item.MovsXHour ? valuemoney(item.MovsXHour) : '0,00' }}</td>
                                                <td class="text-right">{{ item.DelayHoursDs }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-left">TOTAL</th>
                                                <th class="text-right">{{ valuemoney(totalLapse.TonXHourTotal) }}</th>
                                                <th class="text-right">{{ valuemoney(totalLapse.MphHotal) }}</th>
                                                <th class="text-right">{{ totalLapse.DelayTotal }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="p-2 flex-fill d-flex justify-content-center">
                                    <multipleLineAndArea
                                        :titleXavis="$t('label.Day')+' '+$t('label.OfOperation')"
                                        :titleYavis="'MPH'"
                                        :seriesGrafica="seriesGrafica" 
                                        :categoriesGrafica="categoriesGrafica"
                                    ></multipleLineAndArea>
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import moment from 'moment';
    import { DateFormater } from '@/_helpers/funciones';
    import multipleLineAndArea from '@/pages/situacion-operativa-actual/components/multipleLineAndArea';
    //methods
    function valuemoney(value){
        if(value !== null){
            let newValue = parseFloat(value).toFixed(2);
            var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
            return new Intl.NumberFormat("de-DE",options).format(newValue);
        }
        return "0,00";
    }
    function formatOperationDate(date){
        return DateFormater.formatDateWithSlash(date);
    }
    //computed
    function totalLapse(){
        let object = {
            TonXHourTotal : 0,
            MphHotal: 0,
            DelayTotal: '00:00',
        }
        this.OperationSumary.map((item) => {
            object.TonXHourTotal +=  item.TonXHour ? item.TonXHour : 0;
            object.MphHotal +=  item.MovsXHour ? item.MovsXHour : 0;

            object.DelayTotal = moment(object.DelayTotal, "hh:mm")
                .add(item.DelayMinutes, 'minutes')
                .format('hh:mm');;
        })
        return object;
    }
    function seriesGrafica(){
        let array = [{
            name: 'MPH '+this.$t('label.Ship')+'-'+this.$t('label.voyage'),
            type: 'area',
            data: []
        }, {
            name: 'TERMINAL',
            type: 'line',
            data: []
        }, {
            name: 'MPH '+this.$t('label.PerDay'),
            type: 'line',
            data: []
        }]
        this.OperationSumary.map((item) => {
            array[0].data.push(item.VisitMphBulkCarrier ? item.VisitMphBulkCarrier : 0);
            array[1].data.push(item.TerminalMphBulkCarrier ? item.TerminalMphBulkCarrier : 0);
            array[2].data.push(item.MovsXHour ? item.MovsXHour : 0);
        })
        return array;
    }
    function categoriesGrafica(){
        let array = [];
        this.OperationSumary.map((item) => {
            array.push(item.OperationCode);
        });
        return array;
    }
    export default {
        name:'ResumenOperacionMph',
        props:{
            OperationSumary:{
                type: Array,
                default: () => [],
            },
        },
        methods:{
            valuemoney,
            formatOperationDate,
        },
        components:{
            multipleLineAndArea,
        },
        computed:{
            seriesGrafica,
            categoriesGrafica,
            totalLapse,
        },
    }
</script>