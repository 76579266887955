<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
    >
    <CRow>
        <CCol sm="12">
          <CInput
            v-model.trim="$v.rol.StowageRoleName.$model"
            v-uppercase
            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
            :invalid-feedback="errorMessage($v.rol.StowageRoleName)"
            :is-valid="hasError($v.rol.StowageRoleName)"
            size="sm"
            :label="$t('label.name')"
            :placeholder="$t('label.role')+' '+$t('label.name')"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12"> 
          <CSelect
            :label="$t('label.applySigetMobile')"
            :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
            add-label-classes="text-right"
            v-uppercase
            :options="selectOptionsFgApplyTablet"
            :value.sync="rol.FgApplyTablet"
            v-model.trim="rol.FgApplyTablet" 
            :is-valid="hasError($v.rol.FgApplyTablet)"
            :invalid-feedback="errorMessage($v.rol.FgApplyTablet)"             
          >
          </CSelect>
        </CCol>
        <CCol sm="12" v-if="actualizar" >
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-4',input:'col-sm-8'}"
            addLabelClasses="text-right required"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add" @click="submit()" >
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton outline color="wipe" @click="showModal=false">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import SingleRoleFormValidation from '@/_validations/estiba/SingleRoleFormValidation';
import General from '@/_mixins/general';
import { hasError } from '@/_helpers/funciones';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    isSubmit: false,
    showModal: false,
    Loading: false,
    Status: 1,
    rol: {
      StowageRoleId: '',
      StowageRoleName: '',
      FgApplyTablet: 1,
      Status: 1
    },
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: this.$t('label.name'),
    tablaCollapse: false,
    items: [],
    actualizar: false,
    stowageRoleName: '',
    tituloModal: '',
    selectOptionsFgApplyTablet: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],
  }
}

//Method
function submit() {
    try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.rol.Status = this.Status;
    let metodo = this.actualizar ? 'PUT':'POST';
    let ruta = this.actualizar ? 'StowageRole-update' : 'StowageRole-insert'; 
    let StowageRoleJson = this.actualizar
      ? {
          StowageRoleId: this.rol.StowageRoleId,
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
          Status: this.rol.Status
        }
      : { 
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
        };

    this.$http.ejecutar(metodo, ruta, StowageRoleJson, { root: 'StowageRoleJson' })
    .then(response => {
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
     //this.resetForm();
     this.limpiarDatos();
     this.$emit('child-refresh', true);
     this.showModal = false;
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function setForm(newRole) {
  this.rol.StowageRoleId = newRole.StowageRoleId;
  this.rol.StowageRoleName = newRole.StowageRoleName;
  this.rol.FgApplyTablet = newRole.FgApplyTablet ? 1 : 0;
  this.rol.Status = newRole.FgActStowageRole ? 1 : 0;
}

function Editar(item) {
  this.rol.StowageRoleId   = item.StowageRoleId;
  this.rol.StowageRoleName = item.StowageRoleName;
  this.rol.FgApplyTablet = item.FgApplyTablet ? 1:0;
  //this.titulo = this.$t('label.edit')+' '+this.$t('label.role')+': '+item.StowageRoleName;
  this.stowageRoleName = item.StowageRoleName;
  this.actualizar = true;
  this.tituloModal = this.$t('label.edit')+' '+this.$t('label.stowageRole')+': '+item.StowageRoleName;
  this.$v.$touch();
}

function limpiarDatos() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.Status = 1;
  this.rol.FgApplyTablet = 0;
  this.stowageRoleName= '',
  this.actualizar= false,
 // this.$v.$reset();
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')} ${this.RoleName}?`,
      })).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });  
  } else this.submit();
}

//Computed
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}

function selectOptions(){
  return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ];
}

export default {
  name:  'role-modal',
  props: {
    modal: null
  },
  data,
  mixins: [General, ModalMixin],
  validations: SingleRoleFormValidation,
  directives: UpperCase,
  methods: {
    submit,
    hasError,
    setForm,
    evaluaStatus,
    Editar,
    limpiarDatos,
  },
  computed: {
    isSubmitValid,
    selectOptions
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.limpiarDatos();
        if (this.modal==true) {
          this.tituloModal = this.$t('label.nuevo')+this.$t('label.role');
        } else {
          this.Editar(this.modal);
        }
        this.$emit('cerrarModal');
      }
      this.showModal = true;
    },
  }
}
</script>

<style lang="scss">
  .card-body .card-header {
    background-color: #5D6164 !important;
    color: #fff;
  }
  .center-cell {
  text-align: center;
  }
  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>