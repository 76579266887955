<template>
  <div>
    <CModalExtended :closeOnBackdrop="false" :show.sync="modalActive">
      <template slot="header">
        <div class="header-alert">
          <div
            class="swal2-icon swal2-warning swal2-icon-show"
            style="display: flex"
          >
            <div class="swal2-icon-content">!</div>
          </div>
          <div class="flex-grow-1 msg">
            {{ `${$t("label.the")} ${$t("label.file")}` }}
            <span class="msg-name">{{ fileName }}</span>
            {{ `${$t("label.successUpload")}` }}
          </div>
        </div>
      </template>
      <div style="color: #000">
        <div class="main-alert">
          <div class="correct">
            {{ getCorrectCount }} {{ $t("label.rightRows") }}
          </div>
          <div class="errors" v-if="getErrorsCount != 0">
            {{ getErrorsCount }} {{ $t("label.wrongRows") }}
          </div>
          <div class="footer-errors" v-if="getErrorsCount != 0">
            {{ `(${$t("label.errorsStaff")})` }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <CButton
          shape="square"
          color="success"
          class="mr-2"
          @click="onBtnExportErrorsMigracion(getItems)"
          v-if="getErrorsCount != 0"
        >
          <CIcon name="cil-file-excel" />&nbsp; XLSX
        </CButton>
        <CButton shape="square" color="wipe" @click="toggle(false)">
          <CIcon name="x" />&nbsp; {{ $t("button.exit") }}
        </CButton>
      </div>
    </CModalExtended>
  </div>
</template>
<script>
import ENUM from '@/_store/enum';
import ModalMixin from "@/_mixins/modal";
import Reportes from '@/_mixins/reportes';
import StaffMixin from '@/_mixins/staff';
import Excel from "exceljs/dist/es5/exceljs.browser";
import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import { mapState } from 'vuex';
//DATA
function data() {
  return {
    modalActive: false,
  };
}

// METHODS
function toggle(newVal) {
  this.modalActive = newVal;
}

async function onBtnExportErrorsMigracion(items, headerInfo = []) {
  if (items.length !== 0) {
    let rowData = [];
    let title = this.fileName;
    for (let i = 0; i < items.length; i++) {
      if (Array.isArray(items[i].JsonError)) {
        if(items[i].JsonError.length > 0){
          if(this.type === 1){
            rowData.push({
              "#": i+1,
              "DriverCi": items[i].DriverCi,
              "DriverName": items[i].DriverName,
              "LicenseNumber": items[i].LicenseNumber,
              "LicenseExpirationDate": items[i].LicenseExpirationDate,
              Errors: items[i].JsonError.map((error) => {
                return `${this.$t("label.column")} ${error.ColumnName}: ${error.ColumnValue} ${this.$i18n.locale == 'es' ? error.ErrorMessageEs : error.ErrorMessageEn}`;
              }).join(",")
            });
          }else{
            rowData.push({
              "#": i+1,
              "LicensePlate" : items[i].LicensePlate,
              "Brand": items[i].BrandName,
              "Color" : items[i].Color,
              "Year" : items[i].Year,
              "Clasification" : items[i].VehicleClassifName,
              Errors: items[i].JsonError.map((error) => {
                return `${this.$t("label.column")} ${error.ColumnName}: ${error.ColumnValue} ${this.$i18n.locale == 'es' ? error.ErrorMessageEs : error.ErrorMessageEn}`;
              }).join(",")
            });
          }
          
        }
      }
    }
    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
    await this.getExcelArray2(
      rowData,
      title,
      true
    );
    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
  }
}

async function getExcelArray2(rowData, titulo, excel, companyName, vesselInfo = true, nombreLibro) {
  const workbook = new Excel.Workbook();
  let nombreOpcional = nombreLibro ? nombreLibro : titulo;
  const worksheet = workbook.addWorksheet(nombreOpcional);
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

  let y = 0;
  let letra = 12;
  let columnsDefs = [];
  let keyColumns = [];
  let bandera = true;

  columnsDefs = Object.keys(rowData[0]);
  keyColumns = columnsDefs;
  y = columnsDefs.length;
  bandera = false;

  if (excel) {
    if(vesselInfo){
      if(companyName === undefined){
        const Index = this.myDataItinirary.map(function(e) {
          return e.ItineraryId; 
        }).indexOf(this.ItineraryId);
          
        if(Index > -1){      
          this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
          this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
        } else {
          let itinerarySelected = this.itinerarySelected;
          this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
          this.dataBuque.CountryName = this.itinerarySelected.CountryName;
          this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        }
      }
    }
    if (y < 10) letra = (y < 2 ? 12 : 8);
    worksheet.mergeCells('A1:B2');
    const response1 = await this.$http.getFile('base64', {
      path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
      base64: myBase64Image,
      extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:B3');
    worksheet.getCell("A1").border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }
    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;
    if(vesselInfo){
      if(companyName){
        worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
      }else if(companyName === undefined){ 
        worksheet.mergeCells("C1:" + this.numeroLetra(y) + "1");
        worksheet.mergeCells("C2:" + this.numeroLetra(y) + "2");
      }
    }else{
      if(this.numeroLetra(y) === "B"){
        worksheet.mergeCells("C1:F2");
      }else{  
        worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
      }
    }
    const customCellCompanyBranch = worksheet.getCell("C1");
    const row1 = worksheet.getRow(1);
    if(vesselInfo){
      row1.height = companyName ? 60 : 30;
    }else{
      row1.height = 60;
    }
    customCellCompanyBranch.font = {
      name: "Calibri",
      family: 4,
      size:  12,
      underline: false,
      bold: true
    };
    customCellCompanyBranch.note = {
      margins: {
        insetmode: 'auto',
        inset: [10, 10, 5, 5]
      }
    }
    customCellCompanyBranch.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: 'f2f2f2'
      }
    }
    customCellCompanyBranch.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }
    customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
    customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

    if(vesselInfo){
      if(companyName){
      }else if(companyName === undefined){     
        const row2 = worksheet.getRow(2);    
        row2.height = 30;
        const customCell = worksheet.getCell("C2");
        customCell.font = {
          name: "Calibri",
          family: 4,
          size: 12,
          underline: false,
          bold: true
        };
        customCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f2f2f2'
          }
        }
        customCell.border = {
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        }
        customCell.value = this.type === 1 ? this.$t('label.listOfDriversTransports') : this.$t('label.listOfVehiclesTransports');
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      }
    }
    //titulo
    worksheet.getRow(3).height = 30;
    worksheet.mergeCells("A3:" + ( this.numeroLetra(y) === "B" ? "F" : this.numeroLetra(y) ) +"3");
    const customCell2 = worksheet.getCell("A3");
    customCell2.font = {
      name: "Calibri",
      family: 4,
      size: 12,
      underline: false,
      bold: true
    };
    customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell2.value = this.$t('label.reportMigrationMassive');
    customCell2.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }

    //COLUMNA 2
    let division = Math.floor((y === 2 ? 4 : y ) / 3);
    let rango1 = this.numeroLetra(division);
    let rango11 = this.numeroLetra(division+1);
    let rango2 = this.numeroLetra(division*2);
    let rango21 = this.numeroLetra((division * 2) +1);
    let rango3 = this.numeroLetra((y === 2 ? 4 : y ));
    
    const hoy = new Date();

    if(vesselInfo){
      worksheet.mergeCells("A4:" + rango3 + "4");
    }

    const customCell6 = vesselInfo ? (companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4")) : worksheet.getCell("A4");
    customCell6.height = 50;
    customCell6.font = {
        name: "Calibri",
        family: 4,
        size: 12,
        underline: false,
        bold: true
    };

    customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
    customCell6.value = this.$t('label.file')+": "+(this.description ? this.description : this.fileName);
    customCell6.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }
  }

  var headerRow = worksheet.addRow();
  headerRow.font = {
    name: "Calibri",
    family: 4,
    size: 11,
    underline: false,
    bold: true
  };

  headerRow.height = 30;

  let i = 0;
  columnsDefs.map(function (data) {
    let valor = bandera ? data.headerName.length : data.length;
    let long = 10;
    if ((valor >= 5) && (valor <= 12)) {
        long = 20;
    } else if ((valor >= 11) && (valor <= 18)) {
        long = 25;
    } else if ((valor >= 19) && (valor <= 26)) {
        long = 30;
    } else if (valor >= 27) {
        long = 40;
    }

    //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

    worksheet.getColumn(i + 1).width = long;
    //if (excel) {
    let cell = headerRow.getCell(i + 1);
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    
    cell.value = bandera ? data.headerName : data;
    cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
    //}        
    i++;
  });
  
  rowData.map(async function (data) {
    if (data.Cont20FtDeck=="DECK") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      //Grupo 1
      worksheet.mergeCells("C7:F7");
      let cellA4 = worksheet.getCell("D7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = { 
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }            
      cellA4.value = data.Cont20FtDeck;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 2
      worksheet.mergeCells("G7:J7");
      cellA4 = worksheet.getCell("H7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont20FtHold;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 3
      worksheet.mergeCells("K7:N7");
      cellA4 = worksheet.getCell("L7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont20FtTotal;

      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
    } else if (data.Cont20Full == "20F") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      worksheet.mergeCells("C6:C7");
      //Grupo 1
      worksheet.mergeCells("D7:E7");
      let cellA4 = worksheet.getCell("D7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.Cont20Full;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 2
      worksheet.mergeCells("F7:G7");
      cellA4 = worksheet.getCell("F7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.Cont20Empty;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 3
      worksheet.mergeCells("H7:I7");
      cellA4 = worksheet.getCell("H7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont40Full;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 4
      worksheet.mergeCells("J7:K7");
      cellA4 = worksheet.getCell("J7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.Cont40Empty;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 5
      worksheet.mergeCells("L7:M7");
      cellA4 = worksheet.getCell("L7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont45Full;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 6
      worksheet.mergeCells("N7:O7");
      cellA4 = worksheet.getCell("N7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '5d6164'
        }
      }
      cellA4.value = data.Cont45Empty;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
    } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
      //Grupo 1
      worksheet.mergeCells("N7:P7");
      let cellA4 = worksheet.getCell("N7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.CtaFunctionCode;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 2
      worksheet.mergeCells("Q7:R7");
      cellA4 = worksheet.getCell("R7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '1a3760'
        }
      }
      cellA4.value = data.ComAddressIdentifier;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 3
      worksheet.mergeCells("S7:V7");
      cellA4 = worksheet.getCell("S7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ec631b'
        }
      }
      cellA4.value = data.HanDescriptionCode;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 4
      worksheet.mergeCells("W7:Y7");
      cellA4 = worksheet.getCell("W7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '5d6164'
        }
      }
      cellA4.value = data.TsrServicePriorityCode;
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
    } else if (data.section=="totalLoad") {
      //Grupo 0
      worksheet.mergeCells("A6:A7");
      worksheet.mergeCells("B6:B7");
      //Grupo 1
      worksheet.mergeCells("C7:E7");
      let cellA4 = worksheet.getCell("C7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = { 
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '1a3760'
        },
      }            
      cellA4.value = "DECK";
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
      //Grupo 2
      worksheet.mergeCells("F7:H7");
      cellA4 = worksheet.getCell("F7");
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true,
        color: { argb: 'ffffff' },
      };
      cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'ec631b'
        }
      }
      cellA4.value = "HOLD";
      cellA4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      }
    }else {
      let dataRow = worksheet.addRow();
      let indice = 0;
      
      keyColumns.map(function (data2) {
        let cell = dataRow.getCell(indice + 1);
        cell.value = data[data2];
        //if (cell.value==)
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        indice++;
      })
    }
  });

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/", "");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
  //return false;
  if (excel) {
    workbook.xlsx.writeBuffer()
      .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
            group: 'container',
            text: this.$t('label.reportSuccessfully'),
            type: "success"
        });
      }).catch(err => {
        console.log(err);
    })
  } else {
    workbook.csv.writeBuffer()
      .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
        this.$notify({
          group: 'container',
          text: this.$t('label.reportSuccessfully'),
          type: "success"
        });
    })
  }
}

//COMPUTED
function getItems(){
  if (this.staffList.length == 0) return [];
  if(this.type === 1){
    return this.staffList[0].StowagePlanningDriverJson;
  }
  return this.staffList[0].StowagePlanningVehicleJson;
}
function getErrorsCount() {
  if (this.staffList.length == 0) return 0;
  return this.staffList[0].Error;
}
function getCorrectCount() {
  if (this.staffList.length == 0) return 0;
  return this.staffList[0].Correct;
}

export default {
  name: "alert-modal",
  mixins: [ModalMixin, Reportes, StaffMixin],
  data,
  props: {
    modal: Boolean,
    staffList: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "",
    },
    description: {
      required: false,
      type: String,
      default: "",
    },
    type:{
      required: true,
      type: Number,
      default: 1,
    }
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
  methods: {
    toggle,
    getExcelArray2,
    onBtnExportErrorsMigracion
  },
  computed: {
    getErrorsCount,
    getCorrectCount,
    getItems,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
    })
  },
};
</script>
<style scoped>
.main-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.msg {
  font-weight: bold;
  text-align: center;
}
.msg-name {
  font-weight: normal;
}
.correct {
  color: #1aff1a;
  font-weight: bold;
  margin-bottom: 10px;
}
.errors {
  color: #ff0101;
  font-weight: bold;
  text-align: center;
}
.footer-errors {
  margin-top: 10px;
  font-weight: bold;
}

.header-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>