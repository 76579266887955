<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="xl"
      class="modal-extended"
      :show.sync="modalActive"
      @update:show="closeModal(false, false)"
      :closeOnBackdrop="false"
    > 
      <CRow class="d-flex justify-content-center">
        <CCol v-if="NoOrder != ''" sm="12" lg="11" class="d-flex justify-content-end mb-2">
           <CCol sm="auto" class="text-OrderStatusDs text-color-negrita text-white rounded" :style="StatusColor">
            {{OrderCode+': '+OrderStatusDs}}
          </CCol>
        </CCol>
        <CCol sm="12" lg="6">
          <CCol sm="12" class="px-0 ">
            <div class="form-group form-row mb-2">
              <label class="col-form-label-sm col-sm-12 col-lg-5 col-xl-5 text-right required">{{$t('label.Gamela')}}</label>
              <div class="input-group col-sm-12 col-lg-7">
                <CSelect
                  class="mb-0"
                  :disabled="Collapse||isEdit"
                  :options="ScrapContainerFormatted"
                  v-model.trim="$v.scrapContainerId.$model"
                  :value.sync="scrapContainerId"
                  size="sm"
                  :is-valid="hasError($v.scrapContainerId)"
                  @change="filterBin"
                  style="width: 100% !important"
                >
                  <template #append>
                    <div class="d-flex align-items-start">
                      <CButton
                        v-if="!Collapse"
                        :disabled="TareOptions.length <= 1"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="(TareOptions.length > 1 && ReverseFormat(TaraweightKGM) == 0 && valid) ? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.TareWeightlist'),
                          placement: 'top-end'
                        }"
                        @click="ShowCollapse(true)"
                      >
                        <CIcon :class="TareOptions.length > 1 && ReverseFormat(TaraweightKGM) == 0 && valid ?'icon-toast' : ''" name="cil-playlist-add"/>
                      </CButton>
                      <CButton
                        v-else
                        :disabled="TareOptions.length <= 1"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="TareOptions.length > 1 && ReverseFormat(TaraweightKGM) == 0 && valid ? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.TareWeightlist'),
                          placement: 'top-end'
                        }"
                        @click="ShowCollapse(false)"
                      >
                        <CIcon :class="TareOptions.length > 1 && ReverseFormat(TaraweightKGM) == 0 && valid ?'icon-toast' : ''" name="cil-playlist-add"/>
                      </CButton>
                    </div>
                  </template>
                </CSelect>
                <div v-if="TareOptions.length > 1 && ReverseFormat(TaraweightKGM) == 0 && valid">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.TareWeightAssociateBinMustSelected')}}
                  </div>
                </div>
                <div v-if="(scrapContainerId == '' && valid) || (inValidBin && scrapContainerId == '')">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.required')}}
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CCol>   
        <CCol sm="12" lg="6">
          <CCol sm="12" class="px-0 ">
            <div class="form-group form-row mb-2">
              <label class="col-form-label-sm col-sm-12 col-lg-5 col-xl-5 text-right required">{{$t('label.noOrder')}}</label>
              <div class="input-group col-sm-12 col-lg-6">
                <CInput
                  size="sm"
                  class="mb-0"
                  v-uppercase
                  :disabled="true"
                  v-model.trim="$v.OrderCode.$model"
                  :is-valid="hasError($v.NoOrder)"
                  style="width: 100% !important"
                >
                  <template #append>
                    <div class="d-flex align-items-start">
                      <CButton
                        v-if="!Collapse" 
                        :disabled="NoOrderOptions.length <= 1 || isEdit"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="NoOrderOptions.length > 1 && !hasError($v.NoOrder) && valid? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.OrderNolist'),
                          placement: 'top-end'
                        }"
                        @click="ShowOrdenCollapse(true)"
                      >
                        <CIcon :class="(NoOrderOptions.length > 1 && valid && OrderCode == '')?'icon-toast':''" name="cil-playlist-add"/>
                      </CButton>
                      <CButton
                        v-else
                        :disabled="NoOrderOptions.length <= 1 || isEdit"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="NoOrderOptions.length > 1 && (!hasError($v.NoOrder) && valid)? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.OrderNolist'),
                          placement: 'top-end'
                        }"
                        @click="ShowOrdenCollapse(false)"
                      >
                        <CIcon :class="(NoOrderOptions.length > 1 && valid && OrderCode == '')?'icon-toast':''"  name="cil-playlist-add"/>
                      </CButton>
                    </div>
                  </template>
                </CInput>
                <div v-if="(NoOrderOptions.length > 1 && valid && OrderCode == '')">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.OrderNoAssociateBinMustSelected')}}
                  </div>
                </div>
                <div v-if="(scrapContainerId == '' && valid && NoOrderOptions.length == 0)">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.required')}}
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CCol>
        <CCol sm="12">
          <CCollapse :show="Collapse">
            <CollapseTareOrOrder
              :showCollapse="Collapse"
              :Option="TareOrOrdenOptions"
              :isTare="isTare"
              :BinAlias="BinAlias"
              :StowagePlanningLogTareWeigthId="StowagePlanningLogTareWeigthId"
              :NoOrder="NoOrder"
              @set-Tare-weight="ShowCollapse"
              @set-NoOrder="ShowOrdenCollapse"
            />
          </CCollapse>
        </CCol>
        
        <CCol sm="12" lg="6" class="mr-0" v-if="!Collapse">
          <CSelect
              
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-5 col-xl-5 text-right', input: 'col-sm-12 col-lg-7 col-xl-7'}"
            addLabelClasses="text-right required"
            :label="$t('label.truckScale')"
            :options="truckScaleListFormatted"
            v-model.trim="$v.truckScale.$model"
            :is-valid="hasError($v.truckScale)"
            :invalid-feedback="errorMessage($v.truckScale)"
            :value.sync="truckScale"
            @change="filtertruckScale"
          />
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.Taraweight')+'(KGM)' }}</label>
            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
              <money
                v-uppercase
                size="sm"
                :disabled="true"
                v-model.trim="TaraweightKGM"
                v-bind="measure" 
                :class="ValidateTaraFormControl(TaraweightKGM, 0)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(TaraweightKGM) > MaxWeight) && (truckScale != '') && (NoOrder != '')">
                {{ $t('label.maxValueIs')+': '+NumberFormat(MaxWeight, 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((TaraweightKGM == 0 || TaraweightKGM == '0,0000') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.Taraweight')+$t('label.metricTonnageAcronym') }}</label>
            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
              <money
                size="sm"
                v-uppercase
                :disabled="true"
                v-model.trim="TaraweightTON"
                v-bind="measure" 
                :class="ValidateTaraFormControl(TaraweightTON, 1)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(TaraweightTON) > (MaxWeight/1000)) && (truckScale != '') && (NoOrder != '')">
                {{ $t('label.maxValueIs')+': '+NumberFormat((MaxWeight/1000), 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((TaraweightTON == 0 || TaraweightTON == '0,0000') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>  
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.FullWeight')+'(KGM)' }}</label>
            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
              <money
                v-uppercase
                size="sm"
                :disabled="EnableFullWeight"
                v-model.trim="FullweightKGM"
                v-bind="measure" 
                :class="ValidateFullFormControl(FullweightKGM, 0)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(FullweightKGM) < ReverseFormat(TaraweightKGM) && (this.truckScale != '') && (this.NoOrder != ''))">
                {{ $t('label.FullLessTare') }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(FullweightKGM) > MaxWeight && (this.truckScale != '') && (this.NoOrder != ''))">
                {{ $t('label.maxValueIs')+': '+NumberFormat(MaxWeight, 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" 
                v-if="(FullweightKGM == 0 || FullweightKGM == '0,0000') && valid==true && (!(ReverseFormat(FullweightKGM) < ReverseFormat(TaraweightKGM))||this.truckScale == ''||this.NoOrder == '')">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.FullWeight')+$t('label.metricTonnageAcronym') }}</label>
            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
              <money
                v-uppercase
                size="sm"
                :disabled="true"
                v-model.trim="FullweightTON"
                v-bind="measure" 
                :class="ValidateFullFormControl(FullweightTON, 1)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(FullweightTON) < ReverseFormat(TaraweightTON) && (this.truckScale != '') && (this.NoOrder != ''))">
                {{ $t('label.FullLessTare') }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(FullweightTON) >= (MaxWeight/1000)) && (this.truckScale != '') && (this.NoOrder != '')">
                {{ $t('label.maxValueIs')+': '+NumberFormat((MaxWeight/1000), 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" 
                v-if="((FullweightTON == 0 || FullweightTON == '0,0000') && valid==true) && (!(ReverseFormat(FullweightKGM) < ReverseFormat(TaraweightKGM))||this.truckScale == ''||this.NoOrder == '')">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 mt-2 px-0 required col-form-label-sm">{{ $t('label.netWeight')+'(KGM)' }}</label>
            <div class="input-group col-sm-12 col-lg-7 input-group-lg">
              <div :class="ValidateNetFormControl(NetKGM, 0)">
                <h2 class="NetWeight mb-0 mt-1" style="display: inline;">{{NumberFormat((NetKGM), 4)}}</h2>
              </div> 
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(NetKGM) >= (MaxWeight)) && (truckScale != '')">
                {{ $t('label.maxValueIs')+': '+NumberFormat((MaxWeight), 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(NetKGM == 0 && valid==true)">
                {{$t('label.required')}}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(NetKGM < 0)">
                {{$t('label.ValueNotNegative')}}
              </CCol>
            </div>
          </div>
          <div class="form-group form-row"  >
            <label class="text-right col-sm-12 col-lg-5 mt-2 px-0 required col-form-label-sm">{{ $t('label.netWeight')+$t('label.metricTonnageAcronym') }}</label>
            <div class="input-group col-sm-12 col-lg-7">
              <div :class="ValidateNetFormControl(NetTON, 1)">
                <h2 class="NetWeight mb-0 mt-1" style="display: inline;">{{NumberFormat((NetTON), 4)}}</h2>
              </div>                                                                                                     
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(NetTON) >= (MaxWeight/1000)) && (truckScale != '')">
                {{ $t('label.maxValueIs')+': '+NumberFormat((MaxWeight/1000), 4) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(NetTON == 0 && valid==true)">
                {{$t('label.required')}}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(NetTON < 0)">
                {{$t('label.ValueNotNegative')}}
              </CCol>
            </div>
          </div>  
        </CCol>
        
        <CCol sm="12" lg="6" v-if="!Collapse">
          
          <CCol sm="12" class="px-0 "  >
            <div class="form-group form-row mb-0">
              <label class="col-form-label-sm col-sm-12 col-lg-5 col-xl-5 text-right required">{{$t('label.FECHA')}}</label>
              <div class="input-group col-sm-12 col-lg-6">
                <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model="TransactionDate"
                  :disabled-date="validateDateRange"
                  :append-to-body="false"
                  value-type="format"
                  :show-second="false"
                >
                  <template #input>
                    <CInput
                      v-uppercase
                      class="w-100 float-left"
                      :value="TransactionDate"
                      placeholder="DD/MM/YYYY HH:mm"
                      :is-valid="hasError($v.TransactionDate)"
                      @blur="$v.TransactionDate.$touch()"
                      size="sm"                      
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CCol sm="12" class="px-0 "  >
            <div class="form-group form-row mb-0">
              <label class="col-form-label-sm col-sm-12 col-lg-5 col-xl-5 text-right required">{{$t('label.movementType')}}</label>
              <CCol sm="12" lg="6">
                <CCol sm="12" class="rounded" style="background: #1A3760; height: 26px;">
                  <label class="text-color-negrita text-white mb-0" style="font-size: 1.1rem;">{{MovementType}}</label>
                </CCol>
              </CCol>
            </div>
          </CCol>
          <CInput  
            addLabelClasses="text-right"
            :horizontal="{ label: 'col-sm-12 col-lg-5 col-xl-5 text-right', input: 'col-sm-12 col-lg-6 col-xl-6'}"
            :label="$t('label.transport')"
            v-model.trim="$v.Transportation.$model"
            size="sm"
            :value.sync="Transportation"
            :is-valid="hasError($v.Transportation)"
            :disabled="true"
          />
          <CInput  
            :label="$t('label.vehicle')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-uppercase
            v-model.trim="$v.vehicle.$model"
            size="sm"
            :value.sync="vehicle"
            :is-valid="hasError($v.vehicle)"
            :disabled="true"
          />
          <CInput  
            :label="$t('label.yard')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-uppercase
            size="sm"
            v-model.trim="$v.Yard.$model"
            :value.sync="Yard"
            :is-valid="hasError($v.Yard)"
            :disabled="true"
          />
          <CInput  
            :label="$t('label.clientYard')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-uppercase
            size="sm"
            v-model.trim="$v.client.$model"
            :value.sync="client"
            :is-valid="hasError($v.client)"
            :disabled="true"
          />  
          <CTextarea  
            :label="$t('label.observation')"
            addLabelClasses="text-right"
            size="sm"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            :placeholder="$t('label.Description')"
            v-uppercase
            :is-valid="hasError($v.Observation)"
            :invalid-feedback="errorMessage($v.Observation)"
            v-model.trim="$v.Observation.$model"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          type="submit"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal(false, false)"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import CollapseTareOrOrder from './collapse-tare-or-orden';
import { VMoney, Money } from "v-money";
import { mapState } from 'vuex';
import fullWeighingValidation from '@/_validations/visitas/shipment_orders/Weighing/fullWeighingValidation';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

function data() {
  return {
    inValidBin: false,
    ValidPreviousDate: new Date(),
    ValidLaterDate: new Date(),
    previousDate: new Date(),
    laterDate: new Date(),
    isTare: false,
    StowagePlanningLogWeigthId: '',
    StowagePlanningLogTareWeigthId: '',
    NoOrder: '',
    NoOrderOptions: [],
    Transportation: '',
    vehicle: '',
    client: '',
    Yard: '',
    Observation: '',
    scrapContainerId: '',
    BinAlias: '',
    scrapContainerOptions: [],
    TareOptions: [],
    truckScale: '',
    truckScaleOptions: [],
    MovementType: '',
    MovementOrderId: '',
    MovementOrder: [],
    OrderCode: '',
    OrderStatusDs: '',
    ColorStatus: '',
    TransactionDate: '',
    TaraweightKGM: 0,
    TaraweightTON: 0,
    FullweightKGM: 0,
    FullweightTON: 0,
    NetKGM: 0,
    NetTON: 0,
    MaxWeight: 0,
    TareOrOrdenOptions: [],
    IssueDate: '', 
    FinishDate: '',

    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 4,
      masked: false,
      maxlength: 16,
    },
    Collapse: false,
    isSubmit: false,
    modalActive: false,
    valid: false,
    apiStateLoading: false,
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.apiStateLoading = true;
    let Max = this.ReverseFormat(this.TaraweightKGM) > this.ReverseFormat(this.MaxWeight) ? true : false;
    let minTare = this.ReverseFormat(this.TaraweightKGM) <= 0 ? true : false;
    let negativeNet =this.ReverseFormat(this.NetKGM) <= 0 ? true : false;
    this.valid = true;
    if (this.$v.$error || minTare || negativeNet || Max) {
      this.apiStateLoading = false;
      this.isSubmit = false;
      if((this.TareOptions.length > 1 && minTare)||(this.NoOrderOptions.length > 1 && this.$v.OrderCode.$error)){
        if(this.TareOptions.length > 1 && this.ReverseFormat(this.TaraweightKGM) == 0){
          throw this.$t('label.TareWeightAssociateBinMustSelected');
        }
        if(this.NoOrderOptions.length > 1 && this.OrderCode == ''){
          throw this.$t('label.OrderNoAssociateBinMustSelected');
        }
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
        throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
        throw this.$t('label.ErrorEtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
        throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.IssueDate) {
          throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.FinishDate) {
          throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }
    let date = DateFormater.formatDateTimeWithoutSlash(this.TransactionDate);
    let LogisticJson = this.isEdit ? {
      FgCurrentOrder: 1,
      StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
      StowagePlanningLogTareWeigthId:  this.StowagePlanningLogTareWeigthId,
      OrderId: this.NoOrder,
      StowagePlanningImplementVehicleId: this.scrapContainerId,
      MovementOrderId: this.MovementOrderId,
      TruckWeghingScaleId: this.truckScale,
      Weight: this.ReverseFormat(this.FullweightKGM),
      Observation: this.Observation,
      TransactionDate: date,
      Status: 1,
    }:{
      StowagePlanningLogTareWeigthId: this.StowagePlanningLogTareWeigthId,
      OrderId: this.NoOrder,
      StowagePlanningImplementVehicleId: this.scrapContainerId,
      MovementOrderId: this.MovementOrderId,
      TruckWeghingScaleId: this.truckScale,
      Weight: this.ReverseFormat(this.FullweightKGM),
      Observation: this.Observation,
      TransactionDate: date,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'StowagePlanningLogisticWeigthOrder-update':'StowagePlanningLogisticWeigthOrder-insert';
    this.$http
      .ejecutar( metodo, ruta, LogisticJson, { root: 'LogisticJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.apiStateLoading = false;
          this.closeModal(true, true);
          this.notifySuccess({text: messageSuccess});
          this.isSubmit = false;
        } 
      }).catch((e) => {
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function closeModal(filtre, refresh) {
  this.MovementOrder = [];
  this.inValidBin = false;
  this.StowagePlanningLogTareWeigthId = '';
  this.isTare = false;
  this.Collapse = false;
  this.TareOrOrdenOptions = [];
  this.TareOptions = [];
  this.StowagePlanningLogWeigthId = '';
  this.TransactionDate = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.valid = false;
  this.NoOrder= '';
  this.NoOrderOptions= [];
  this.Transportation= '';
  this.vehicle= '';
  this.client= '';
  this.Yard= '';
  this.Observation= '';
  this.scrapContainerId= '';
  this.BinAlias= '';
  this.scrapContainerOptions= [];
  this.truckScale= '';
  this.truckScaleOptions= [];
  this.MovementType= '';
  this.MovementOrderId='';
  this.TaraweightKGM= 0;
  this.TaraweightTON= 0;
  this.FullweightKGM= 0;
  this.FullweightTON= 0;
  this.NetKGM= 0;
  this.NetTON= 0;
  this.MaxWeight= 0;
  this.OrderCode = '';
  this.OrderStatusDs = '';
  this.ColorStatus = '';
  this.IssueDate = '';
  this.FinishDate = '';
  this.$emit('set-Weighing-list', filtre, refresh);
}

function NumberFormat(number, decimal) {
  if(decimal == 0){
    let format = new Intl.NumberFormat('de-DE').format(number)
    return format;
  }
  return NumberFormater.formatNumber(number, decimal);
}

function ReverseFormat(number) {
  return NumberFormater.setNum(number);
}

async function getList() {
  this.apiStateLoading = true;
  this.getDateRange();
  let peticiones =  
    [
      //this.$http.ejecutar("GET", "ShipmentOrderActive-list", {VisitId: this.Visit}),
      this.$http.ejecutar("GET", "VisitImplementVehicleWithOrder-list", {VisitId: this.Visit, 
        OrderId: this.isEdit ? this.FullItem.OrderId : ''}),
      this.$http.ejecutar("GET", "TruckWeighingScale-list", { CompanyBranchId: this.branch.CompanyBranchId }),
      this.$http.ejecutar("GET", "ShipmentFullMovementOrder-list", {VisitId: this.Visit}),
    ];

  Promise.all(peticiones)
    .then((responses) => {
      //this.NoOrderOptions = responses[0].data.data;
      this.scrapContainerOptions = responses[0].data.data;
      this.truckScaleOptions = responses[1].data.data;
      this.MovementOrder = responses[2].data.data;
    })
    .catch((err) => {
      this.apiStateLoading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      })
    })
    .then(() => {
      if (this.isEdit) {
        this.getdata(this.FullItem);
      }else{
        this.$v.$reset();
        this.apiStateLoading = false;
      }
    });
}

function ValidateTaraFormControl(value, WeightType) {
  value = this.ReverseFormat(value);
  let Max = WeightType == 0 ? this.MaxWeight : (this.MaxWeight/1000);

  if ((value == 0 && this.valid==true) || (value > Max && (this.truckScale != '') && (this.NoOrder != '')) || (value <= 0 && (this.NoOrder != '') && this.valid==true) ) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function ValidateFullFormControl(value, WeightType) { 
  value = this.ReverseFormat(value);
  let Max = WeightType == 0 ? this.MaxWeight : (this.MaxWeight/1000);
  let Tara = WeightType == 0 ? this.ReverseFormat(this.TaraweightKGM) : this.ReverseFormat(this.TaraweightTON);

  if (((value == 0 || value == '0,0000') && this.valid==true) || (value > Max && (this.truckScale != '')) || (value < Tara && (this.truckScale != '') && (this.NoOrder != ''))  ) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function ValidateNetFormControl(value, WeightType) { 
  value = this.ReverseFormat(value);
  let Max = WeightType == 0 ? this.MaxWeight : (this.MaxWeight/1000);

  if (((value == 0 || value == '0,0000') && this.valid==true) || (value > Max && (this.truckScale != '')) || (value < 0 && (this.truckScale != '') && (this.NoOrder != ''))  ) {
    return 'input-NetWeight-isInvalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'input-NetWeight';
    }else{
      return 'input-NetWeight-isValid';
    }
  }
}

function ShowCollapse(collapse, event, Tare, TareId) {
  if(collapse){
    this.TareOrOrdenOptions = this.TareOptions;
    this.isTare=true;
    this.Collapse=true;
  }else{
    if(this.isTare){
      this.Collapse=false;
      if(Tare != '' && Tare != null && TareId != '' && TareId != null && event){
        this.StowagePlanningLogTareWeigthId=TareId;
        this.TaraweightKGM=Tare;
      }
      if(event == false){
        this.StowagePlanningLogTareWeigthId='';
        this.TaraweightKGM=0;
      }
    }else{
      this.isTare=true;
      this.TareOrOrdenOptions = this.TareOptions;
    } 
  }
}

function ShowOrdenCollapse(collapse,event,Orden, Code,Status,Color,IssueDate,FinishDate,StowagePlanningLogFullWeigthId,StowagePlanningLogTareWeigthId,TareWeight) {
  if (!this.isEdit) {
    if(collapse){
      this.TareOrOrdenOptions=this.NoOrderOptions;
      this.isTare=false;
      this.Collapse=true;
    }else{
      if(!this.isTare){
        this.Collapse=false;
        if(Orden && Code && Status && Color && event){
          this.StowagePlanningLogTareWeigthId = StowagePlanningLogTareWeigthId;
          this.TaraweightKGM = TareWeight;
          this.NoOrder=Orden;
          this.OrderCode=Code;
          this.OrderStatusDs=Status;
          this.ColorStatus=Color;
          this.IssueDate = DateFormater.formatDateTimeWithoutSlash(IssueDate);
          this.FinishDate = FinishDate!='N/A'? DateFormater.formatDateTimeWithoutSlash(FinishDate) : this.ValidLaterDate;
          this.setMovementOrder(StowagePlanningLogFullWeigthId);
        }
        if(event == false){
          this.NoOrder='';
          this.OrderCode='';
          this.OrderStatusDs='';
          this.ColorStatus='';
          this.MovementType = '';
          this.MovementOrderId = '';
          this.IssueDate = this.ValidPreviousDate;
          this.FinishDate = this.ValidLaterDate;
        }
      }else{
        this.isTare=false;
        this.TareOrOrdenOptions=this.NoOrderOptions;
      }
    }
  }
}

function filterBin(e) {
  this.scrapContainerId=e.target.value;
  this.StowagePlanningLogTareWeigthId='';
  this.TaraweightKGM=0;
  this.inValidBin = true;
  this.getBinrelationship();
}

function filtertruckScale(e) {
  this.truckScale=e.target.value;
  this.getTruckScale();
}

function StatusColor() {
  return { background: this.ColorStatus };
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getDateRange(){
  let currentDate = new Date();
  if(this.itinerarySelected.Eta){
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
  }

  if (this.itinerarySelected.Eta>this.itinerarySelected.Ata) {
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
  }

  if(this.itinerarySelected.Atd!=undefined){  
    this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
  }

  if(this.itinerarySelected.Atd==undefined){
    this.laterDate = currentDate;
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(currentDate);
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash((currentDate));
  }

  this.IssueDate = this.ValidPreviousDate;
  this.FinishDate = this.ValidLaterDate;
}

function getdata(val) {
  this.$v.$touch();
  this.valid = true;
  this.StowagePlanningLogWeigthId = val.StowagePlanningLogWeigthId;
  this.StowagePlanningLogTareWeigthId = val.StowagePlanningLogTareWeigthId;
  this.TaraweightKGM = val.TareKgs;
  this.FullweightKGM = val.FullKgs;
  this.Observation = val.Observation;
  this.scrapContainerId = val.StowagePlanningImplementVehicleId;
  this.truckScale = val.TruckWeghingScaleId;
  this.MovementType = val.MovementOrderName;
  this.MovementOrderId = val.MovementOrderId;
  this.TransactionDate = val.FormatedDate;
  this.NoOrder = val.OrderId;
  this.getTruckScale();
  this.getBinrelationship();
}

function getBinrelationship() {
  if(this.scrapContainerId != ''){
    let Gamela = this.scrapContainerOptions.find(item => item.StowagePlanningImplementVehicleId==this.scrapContainerId);
    if (Gamela) {
      this.BinAlias = Gamela.ImplementAlias;
      this.Transportation = Gamela.CarrierAlias ? Gamela.CarrierAlias : '';
      this.vehicle = Gamela.LicensePlate ? Gamela.LicensePlate : '';
      this.Yard = Gamela.YardName ? Gamela.YardName : '';
      this.client = Gamela.YardClientName ? Gamela.YardClientName : '';
      this.TareOptions = Gamela.TareWeightJson ? Gamela.TareWeightJson : [];
      this.getNoOrder();
    } else {
      this.apiStateLoading = false;
    }
  }else{
    this.TareOptions = [];
    this.NoOrderOptions = [];
    this.BinAlias = '';
    this.scrapContainerId = '';
    this.Transportation = '';
    this.vehicle = '';
    this.Yard = '';
    this.client = '';
    this.NoOrder = '';
    this.OrderCode = '';
    this.OrderStatusDs = '';
    this.ColorStatus = '';
    this.IssueDate = this.ValidPreviousDate;
    this.FinishDate = this.ValidLaterDate;
  }
  
}

function getTruckScale() {
  if(this.truckScale != ''){
    this.truckScaleOptions.map((item) => {
      if (item.TruckWeghingScaleId == this.truckScale) {
        this.MaxWeight = item.MaxWeightKgm;
      }
    });
  }else{
    this.MaxWeight = 0;
  }
}

function getNoOrder() {
  this.apiStateLoading = true;
  this.$http
    .get('ShipmentOrderActive-list', { VisitId: this.Visit, StowagePlanningImplementVehicleId: this.scrapContainerId })
    .then((response) => {
      this.NoOrderOptions= response.data.data;
      this.setNoOrder(this.NoOrderOptions);
      this.apiStateLoading = false;       
    }).catch((err) => {
      this.apiStateLoading = false;
      this.notifyError({text: err});
    })

  /*this.NoOrderOptions.map((item) => {
    if (item.OrderId == this.NoOrder) {
      this.OrderCode = item.OrderCode;
      this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
        this.ColorStatus = item.ColorStatus;
        this.scrapContainerId = item.StowagePlanningImplementVehicleId;
        this.TaraweightKGM = item.TareWeight ? item.TareWeight : 0;
        if(this.scrapContainerId != null && this.scrapContainerId != ''){
          this.getBinrelationship();
        }else{
          this.scrapContainerId = '';
          this.TaraweightKGM = '';
          this.Transportation = '';
          this.vehicle = '';
          this.Yard = '';
          this.client = '';
        }
      }
    });*/
}

function setNoOrder(val){
  let _lang = this.$i18n.locale;
  if(!this.isEdit){
    if(val.length == 1){
      this.StowagePlanningLogTareWeigthId = val[0].StowagePlanningLogTareWeigthId;
      this.TaraweightKGM = val[0].TareWeight ? val[0].TareWeight : 0;
      this.NoOrder = val[0].OrderId;
      this.OrderCode = val[0].OrderCode;
      this.OrderStatusDs = _lang=='en' ? val[0].OrderStatusDsEn : val[0].OrderStatusDsEs;
      this.ColorStatus = val[0].ColorStatus;
      this.IssueDate = val[0].TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(val[0].TransactionDate)) : '';
      this.FinishDate = val[0].FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(val[0].FinishDate)) : this.ValidLaterDate;
      this.setMovementOrder(val[0].StowagePlanningLogFullWeigthId);
    }else{
      val.map((item) => {
        if (item.OrderStatusDsEn == 'OPEN') {
          this.StowagePlanningLogTareWeigthId = item.StowagePlanningLogTareWeigthId;
          this.TaraweightKGM = item.TareWeight ? item.TareWeight : 0;
          this.NoOrder = item.OrderId;
          this.OrderCode = item.OrderCode;
          this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
          this.ColorStatus = item.ColorStatus;
          this.IssueDate = item.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.TransactionDate)) : '';
          this.FinishDate = item.FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.FinishDate)) : this.ValidLaterDate;
          this.setMovementOrder(item.StowagePlanningLogFullWeigthId);
        }
      });
    }
  }else{
    val.map((item) => {
      if (item.OrderId == this.NoOrder) {
        this.OrderCode = item.OrderCode;
        this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
        this.ColorStatus = item.ColorStatus;
        this.IssueDate = item.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.TransactionDate)) : '';
        this.FinishDate = item.FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.FinishDate)) : this.ValidLaterDate;
        this.setMovementOrder(item.StowagePlanningLogFullWeigthId);
      }
    });
  }
}

function setMovementOrder(id){
  if (!this.isEdit) {
    let _lang = this.$i18n.locale;
    if(id != '' && id != null){
      this.MovementType = _lang == 'en' ? this.MovementOrder[1].MovementOrderNameEn : this.MovementOrder[1].MovementOrderNameEs;
      this.MovementOrderId = this.MovementOrder[1].MovementOrderId;
    }else{
      this.MovementType = _lang == 'en' ? this.MovementOrder[0].MovementOrderNameEn : this.MovementOrder[0].MovementOrderNameEs;
      this.MovementOrderId = this.MovementOrder[0].MovementOrderId;
    }  
  }   
}

function truckScaleListFormatted(){
  if(this.truckScaleOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.truckScaleOptions.map(function(e){
      chart.push({
        value: e.TruckWeghingScaleId, 
        label: e.TruckWeghingScaleName,
      })    
    })
    return chart;
  }
}

/*function NoOrderListFormatted(){
    if(this.NoOrderOptions.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
        }];
    }else{
      var chart = [{
          value: '', 
          label: this.$t('label.select'),
      }]
      this.NoOrderOptions.map(function(e){
        chart.push({
          value: e.OrderId, 
          label: e.OrderCode,
        })
      })
      return chart;
    }
}*/

function ScrapContainerFormatted(){
  if(this.scrapContainerOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.scrapContainerOptions.map(function(e){
      chart.push({
        value: e.StowagePlanningImplementVehicleId, 
        label: e.ImplementAlias,
      })    
    })
    return chart;
  }
}

function EnableFullWeight(){
  if (this.NoOrder != '' && this.truckScale != '' && this.ReverseFormat(this.TaraweightKGM) != 0) {
    return false;
  } else {
    this.FullweightKGM = 0;
    return true;
  }
}

function titleModal(){
  if(this.isEdit){
    return this.$t('label.edit')+' '+this.$t('label.FullWeight')+': '+this.FullItem.OrderCode;
  }else{
    return this.$t('label.nuevo')+' '+this.$t('label.FullWeight');
  }
}

export default {
  name: 'modal-full-weight',
  props: { modal: Boolean, isEdit: Boolean, FullItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations(){
    return fullWeighingValidation(this.ValidPreviousDate, this.ValidLaterDate, this.IssueDate, this.FinishDate);
  },
  directives: UpperCase,
  components: {
    Money,
    CollapseTareOrOrder,
  },
  computed: {
    titleModal,
    EnableFullWeight,
    StatusColor,
    truckScaleListFormatted,
    //NoOrderListFormatted,
    ScrapContainerFormatted,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      branch: state => state.auth.branch,
    }),
  },
  methods: {
    setNoOrder,
    setMovementOrder,
    getDateRange,
    validateDateRange,
    getBinrelationship,
    getTruckScale,
    getNoOrder,
    ValidateTaraFormControl,
    ValidateFullFormControl,
    ValidateNetFormControl,
    filtertruckScale,
    filterBin,
    ShowCollapse,
    ShowOrdenCollapse,
    NumberFormat,
    ReverseFormat,
    getList,
    getdata,
    closeModal,
    submitBody,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if(this.modalActive){
        this.getList();
      }
    },
    TransactionDate: async function(val){
      try{
        if(val != ''){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
              throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
          }
          if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
              throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    TaraweightKGM: async function(val){
      if (!isNaN(val)) {
        let Ton = (val/1000);
        if(!Number.isInteger(Ton) && Ton >= 0.0001){
          let New = `${Ton}`;
          New = New.split('.');
          Ton = New[0]+'.'+New[1].substring(4, -1);
          Ton = Number.parseFloat(Ton);
        }
        if(Ton >= 0.0001){
          this.TaraweightTON = Ton;
        }else{
          this.TaraweightTON = 0;
        }
        if (this.NoOrder != '' && this.truckScale != '') {
          let Full = this.ReverseFormat(this.FullweightKGM);
          let Tare = this.ReverseFormat(this.TaraweightKGM);
          this.NetKGM =(Full - Tare);
        }
      }
    },
    FullweightKGM: async function(val){
      if (!isNaN(val)) {
        if (this.NoOrder != '' && this.truckScale != '') {
          let Ton = (val/1000);
          if(!Number.isInteger(Ton) && Ton >= 0.0001){
            let New = `${Ton}`;
            New = New.split('.');
            Ton = New[0]+'.'+New[1].substring(4, -1);
            Ton = Number.parseFloat(Ton);
          }
          if(Ton >= 0.0001){
            this.FullweightTON = Ton;
          }else{
            this.FullweightTON = 0;
          }
          let Full = this.ReverseFormat(this.FullweightKGM);
          let Tare = this.ReverseFormat(this.TaraweightKGM);
          this.NetKGM =(Full - Tare);
        } else {
          this.FullweightTON = 0;
          this.NetKGM = 0;
        }
      }
    },
    NetKGM: async function(val){
      if (!isNaN(val)) {
        let Ton = (val/1000);
        if(!Number.isInteger(Ton) && (Ton >= 0.0001 || Ton <= (-0.0001))){
          let New = `${Ton}`;
          New = New.split('.');
          Ton = New[0]+'.'+New[1].substring(4, -1);
          Ton = Number.parseFloat(Ton);
        }
        if(Ton >= 0.0001 || Ton <= (-0.0001)){
          this.NetTON = Ton;
        }else{
          this.NetTON = 0;
        }
      }
    },
  },
 
};
</script>
<style>
.icon-toast{
  -webkit-animation: blink-1 2s infinite both !important;
	animation: blink-1 2s infinite both !important;
}
.NetWeight{
  color: #ffffff !important;
  font-size: 30px;
}
.input-NetWeight {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #1A3760 !important;
  background: #1A3760 !important;
}
.input-NetWeight-isInvalid {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e55353 !important;
  background: #1A3760 !important;
}
.input-NetWeight-isValid {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #2eb85c !important;
  background: #1A3760 !important;
}

</style>