import {
    onlyMayusTextEspecial,
    onlyMayusText,
    onlyAlphanumeric,
    requiredSelect,
    inputColor,
    UtcZone
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal,
    minValue,
    maxValue,
    numeric 
} from "vuelidate/lib/validators";

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

   

    export const FormGpoCarga = () => {
        return {
           
                GpoCargoName:{
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                    onlyMayusTextEspecial,
                }
            
        }
    }

   