<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mx-0 mb-2">
      <CCol sm="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ `${$t('label.BlContainerList')}:`}} 
          <strong class="ml-1">
            {{ `${Bl.BlNro ?? ''}`}} 
          </strong>
        </h6>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol col="12">
        <div class="d-flex align-items-center justify-content-end">
          <CButton
            color="excel"
            size="sm"
            @click="onBtnExport(true)"
          >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
        </div>
      </CCol>
      <CCol col="12" lg="12">
        <dataTableExtended
          class="align-center-row-datatable"
          sorter
          column-filter
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          @filtered-items-change="filteredList=$event"
          pagination
        >
          <template #Option="{item}">
            <td class="text-center">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.container')}`,
                  placement: 'top-start',
                }"
                @click="(StowagePlanningCargoId=item.StowagePlanningCargoId,ActivateContainerModal=true)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ContainerModal
      :modal.sync="ActivateContainerModal"
      :StowagePlanningCargoId="StowagePlanningCargoId"
			@Close="(ActivateContainerModal=false, StowagePlanningCargoId='')"
      @Update="UpdateList"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { NumberFormater, trimRemoveSpecialCharacters, removeTags } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import Reportes from '@/_mixins/reportes';
import ContainerModal from "./container-modal/container-modal-index"

//DATA-------------------------
function data() {
  return {
    Loading: false,
    ActivateContainerModal: false,
    StowagePlanningCargoId: '',
    filteredList: [],
  }
}

async function onBtnExport(Excel) {
  this.Loading = true;
  let title = this.$t('label.ContainerList');
  let ContainerList = [];
  let TableHeader = {
    label: [],
    key: [],
  }
  if (this.filteredList && this.filteredList.length != 0) {
    await this.fields.forEach((item, index) => {
      if (!index == 0) {
        TableHeader.label.push(item.label);
        TableHeader.key.push(item.key);
      }
    });
    ContainerList = this.filteredList.map(item => {
      return {
        ...item,
        Weight: item.Weight ? NumberFormater.setNum(item.Weight) : 0,
        Volumen: item.Volumen ? NumberFormater.setNum(item.Volumen) : 0,
        Quantity: item.Volumen ? NumberFormater.setNum(item.Quantity) : 0,
      }
    });
    await this.getExcelArray(ContainerList, title, Excel, undefined, true, undefined, TableHeader)
      .catch((err) => {
        this.Loading = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }else{
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:  this.$t('label.noRecordsAvailbleReport'),
      type: "error",
    });
  }
  this.Loading = false;
}

async function UpdateList() {
  this.Loading = true;
  await this.$http.get("StowagePlanningGeneralCargoBillOfLading-by-id", { StowagePlanningBillOfLadingId: this.Bl?.StowagePlanningBillOfLadingId ?? ''})
    .then((response) => {
      let Bl = response.data.data && response.data.data[0] ? response.data.data[0] : {};
      if (Object.keys(Bl).length != 0) {
        let List = this.BlList;
        List[this.Index] = Bl;
        this.$emit('Update', Bl, List);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function Reset() {
  this.StowagePlanningCargoId = '';
  this.filteredList = [];
  this.$emit('Close');
}

//computed
function formatedItems() {
  let CargoJson = this.Bl?.CargoJson ?? [];
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return CargoJson.map((item) => {
    return {
      ...item,
      Nro: Number.parseInt(item.Nro),
      ContainerCode: item.ContainerCode ?? '',
      BlNro: item.BlNo ?? '',
      TpCargoCode: item.TpCargoCode ?? '',
      Consignee: item.Consignee ?? '',
      IsoCode: item.IsoCode ?? '',
      Status: item.Status ?? '',
      Seals: item.Seals ?? '',
      ShippingLineCode: item.ShippingLineCode ?? '',
      PortActivityName: item['PortActivityName'+_lang] ?? '',
      Condition: item.Condition ?? '',
      ImdgCode: item.ImdgCode ?? '',
      LoadPort: item.LoadPort ?? '',
      DischargePort: item.DischargePort ?? '',
      Weight: NumberFormater.formatNumber(item.Weigth, 2),
      DescriptionOfGoods: item.DescriptionOfGoods ? (item.DescriptionOfGoods.length > 50 ? trimRemoveSpecialCharacters(item.DescriptionOfGoods, 50) : removeTags(item.DescriptionOfGoods) ) : '',
    };
  })
}

function fields(){
  return [
    { key: "Option", label: "",  _style: "min-width:45px; width:1%;", sorter: false, filter: false },
    { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
    { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"text-center", _style:'min-width:205px;'},
    { key: 'BlNro', label: 'BL NRO',_classes:"text-center", _style:'min-width:180px;'},
    { key: 'TpCargoCode', label: this.$t('label.type'),_classes:'text-center', _style:'min-width:140px;'},
    { key: 'Consignee', label: this.$t('label.consignee'),_classes:"text-center", _style:'min-width:200px;'},
    { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:'text-center', _style:'min-width:140px;'},
    { key: 'Status', label: this.$t('label.status'),_classes:'text-center', _style:'min-width:140px;'},
    { key: 'Seals', label: this.$t('label.Seal')+'S',_classes:'text-center', _style:'min-width:200px;'},
    { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'),_classes:'text-center', _style:'min-width:140px;'},
    { key: 'PortActivityName', label: this.$t('label.portActivity'),_classes:'text-center', _style:'min-width:190px;'},
    { key: 'Condition', label: this.$t('label.condition'),_classes:'text-center', _style:'min-width:140px;'},
    { key: 'ImdgCode', label: 'IMDG',_classes:'text-center', _style:'min-width:140px;'},
    { key: 'LoadPort', label: 'POL',_classes:'text-center', _style:'min-width:150px;'},
    { key: 'DischargePort', label: 'POD',_classes:'text-center', _style:'min-width:150px;'},
    { key: 'Weight', label: this.$t('label.weight')+' (KGM)',_classes:'text-center', _style:'min-width:140px;'},
    { key: 'DescriptionOfGoods', label: this.$t('label.DescriptionOfGoods'),_classes:'text-center', _style:'min-width:250px;'},
  ]
 }

export default {
  name: 'bl-container-list',
  data,
  components: {
    ContainerModal,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    Bl: {
      type: Object,
      default: () => {},
    },
    Index: {
      type: Number,
      default: () => 0,
    },
    BlList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [GeneralMixin, Reportes],
  methods: {
    onBtnExport,
    UpdateList,
    Reset,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      
    })
  },
  watch: {
    active: function (NewVal) {
      if (!NewVal) {
        this.Reset();
      }
    }
  }
};
</script>