import AuthService from '../_services/auth-service';
import DataStorage from '@/_helpers/localStorage';

const user = DataStorage.getUser();
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const config = DataStorage.getConfiguration();
const initialConfig = config
  ? { role: config.role, branch: config.branch }
  : { role: null, branch: null };

export const auth = {
  namespaced: true,
  state: {
    ...initialState,
    ...initialConfig
  },
  getters: {
    getSucursales: state => {
      return state.user.BranchJson.map((branch) => Object.assign({}, {
        CompanyBranchId: branch.CompanyBranchId,
        BranchName: branch.BranchName,
        RoleId: branch.RoleId,
        PortCode: branch.PortCode,
        PortId: branch.PortId,
        PortName: branch.PortName,
        YardJson: branch.YardJson,
      }));
    },
    getRoles: state => {
      let roles = state.user.RoleJson;

      return roles.map((rol) => Object.assign({}, {
        RoleId: rol.RoleId,
        RoleName: rol.RoleName,
        FgGeneral: rol.FgGeneral,
        Dash: rol.Dash,
        RoleConfigId: rol.RoleConfigJson[0]?.RoleConfigId,
        CompanyBranchId: rol.RoleConfigJson[0]?.CompanyBranchId,
        YardId: rol.RoleConfigJson[0]?.YardId
      }));
    },
    getValidRoles: state => {
      return state.user.RoleJson.filter((rol) => rol.FgMenu);
    }
  },
  actions: {
    login({ commit }, user) {
      if( user.Login && user.Login != '' && user.Password && user.Password != '' && user.CompanyId && user.CompanyId != '' && user.Connection && user.Connection != '')
        { 
          return AuthService.login(user).then(
            user => {
              if(user.FgFirstTime == 0)
                commit('loginSuccess', user);
                
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          );
        }
      else{
        if( !user.Login && user.Login == '' ){
          commit('loginFailure');
          return Promise.reject("EL CAMPO NOMBRE DE USUARIO ES REQUERIDO");
        } else if( !user.Password && user.Password == '' ){
          commit('loginFailure');
          return Promise.reject("EL CAMPO CONTRASEÑA ES REQUERIDO");
        }else if( !user.Connection && user.Connection == ''){
          commit('loginFailure');
          return Promise.reject("EL CAMPO   ORIGEN DE DATOS ES REQUERIDO");
        }else if( !user.CompanyId && user.CompanyId == ''){
          commit('loginFailure');
          return Promise.reject("EL CAMPO EMPRESA ES REQUERIDO");  
        }
      }
    },
    logout({ commit,  dispatch  }) {
      AuthService.logout();
      commit('logout');
    },
    verifyUser({ commit }, user) {
      return AuthService.verifyUser(user).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    verifyCode({ commit }, user) {
      return AuthService.verifyCode(user).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    changePassword({ commit }, user) {
      return AuthService.changePassword(user).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    setRole({ commit, getters }, RoleId) {
      let role = getters.getValidRoles.find((rol) => rol.RoleId == RoleId);
      DataStorage.changeRole(role);
      commit('roleSelected', role);
    },
    setBranch({ commit }, branch) {
      DataStorage.changeBranch(branch);
      commit('branchSelected', branch);
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.role = null;
      state.branch = null;
    },
    roleSelected(state, Role) {
      state.role = Role;
    },
    branchSelected(state, branch) {
      state.branch = branch;
    }
  }
};
