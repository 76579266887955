<template>
     <div>
        <CModalExtended
            color="dark"
            size="xl"
            :closeOnBackdrop="false"
            :show.sync="$store.state.contenedores.modalDetalle"
            :title="tituloModal"
            :close-on-backdrop="false"
            class=""
        >
            <CRow>
                <CCol sm="11" lg="12" >
                    <CustomTabs :activeTab="activeTab"  @update:activeTab="handleTab">
                        <CustomTab :title="$t('label.container')" >

                            <CRow class="mt-2">
                                <CCol sm="11" lg="5" xl="5">
                                    <CRow>
                                        <CCol sm="11" lg="12"  class="pr-0" >
                                            <CSelect
                                                :label="$t('label.group')"
                                                addLabelClasses="text-right required"
                                                :value="formDetalle.TpCargoId"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.TpCargoId.$error }"
                                                @blur="$v.formDetalle.TpCargoId.$touch()"
                                                @change="formDetalle.TpCargoId = $event.target.value"
                                                :options="optionListCargo"
                                                :is-valid="hasError($v.formDetalle.TpCargoId)"
                                                :invalid-feedback="errorMessage($v.formDetalle.TpCargoId)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12"  class="pr-0">
                                            <CInput
                                                :label="$t('label.type')"
                                                v-uppercase
                                                :placeholder="$t('label.type')"
                                                v-model="$v.formDetalle.TpCargoDetailCode.$model"
                                                @blur="$v.formDetalle.TpCargoDetailCode.$touch()"
                                                addLabelClasses="text-right required"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.TpCargoDetailCode.$error }"
                                                required 
                                                :is-valid="hasError($v.formDetalle.TpCargoDetailCode)"
                                                :invalid-feedback="errorMessage($v.formDetalle.TpCargoDetailCode)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                                >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="11" lg="12" class="pr-0">
                                            <CSelect
                                                :label="$t('label.size')"
                                                :value="formDetalle.SizeId"
                                                addLabelClasses="text-right required"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.SizeId.$error }"
                                                @blur="$v.formDetalle.SizeId.$touch()"
                                                @change="formDetalle.SizeId = $event.target.value"
                                                :options="optionListSize"
                                                :is-valid="hasError($v.formDetalle.SizeId)"
                                                :invalid-feedback="errorMessage($v.formDetalle.SizeId)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12" class="pr-0">
                                            <CInput
                                                :label="$t('label.color')"
                                                addLabelClasses="text-right required"
                                                v-uppercase
                                                type="color"
                                                v-model="$v.formDetalle.defaultColor.$model"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.defaultColor.$error }"
                                                required
                                                :is-valid="hasError($v.formDetalle.defaultColor)"
                                                :invalid-feedback="errorMessage($v.formDetalle.defaultColor)" 
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                                >
                                                <!--template #invalid-feedback>
                                                    <div v-if="$v.formDetalle.defaultColor.$error">
                                                        <div class='text-danger' v-if="!$v.formDetalle.defaultColor.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-else-if="!$v.formDetalle.defaultColor.inputColor">{{$t('label.istNotColour')}}</div>
                                                    </div>
                                                </template-->
                                            </CInput>
                                        </CCol>
                                        
                                        <label class="text-right required col-sm-11 col-lg-4 col-xl-4 pr-0" for="" >{{$t('label.description')}}</label>

                                        <CCol sm="11" lg="8" xl="8" class="pl-2 pr-0">
                                            <CTextarea
                                                type="textarea"
                                                :placeholder="$t('label.forAllTypeLoadsInGeneral')"
                                                v-uppercase
                                                v-model="$v.formDetalle.TpCargoDetailName.$model"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.TpCargoDetailName.$error }"
                                                @blur="$v.formDetalle.TpCargoDetailName.$touch()"
                                                :plaintext="false"
                                                :readonly="false"
                                                :lazy="(true,400)"
                                                :is-valid="hasError($v.formDetalle.TpCargoDetailName)"
                                                :invalid-feedback="errorMessage($v.formDetalle.TpCargoDetailName)"
                                            >
                                            </CTextarea>
                                        </CCol>
                                        <CCol sm="11" lg="12" xl="12" class="pr-0">
                                            <CSelect
                                                :label="$t('label.special')"
                                                addLabelClasses="text-right required"
                                                v-model="formDetalle.FgSpecial"
                                                :value.sync="formDetalle.FgSpecial"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.FgSpecial.$error }"
                                                @blur="$v.formDetalle.FgSpecial.$touch()"
                                                :options="selectOptionsEspecial"
                                                :is-valid="hasError($v.formDetalle.FgSpecial)"
                                                :invalid-feedback="errorMessage($v.formDetalle.FgSpecial)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12" xl="12" class="pr-0">
                                            <CSelect
                                                :label="$t('label.Applyreefer')"
                                                addLabelClasses="text-right required"
                                                v-model="formDetalle.FgApplyRefrigeration"
                                                :value.sync="formDetalle.FgApplyRefrigeration"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.FgApplyRefrigeration.$error }"
                                                @blur="$v.formDetalle.FgApplyRefrigeration.$touch()"
                                                :options="selectOptionsEspecial"
                                                :is-valid="hasError($v.formDetalle.FgApplyRefrigeration)"
                                                :invalid-feedback="errorMessage($v.formDetalle.FgApplyRefrigeration)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12" xl="12" class="pr-0">
                                            <CSelect
                                                :label="$t('label.oversize')"
                                                addLabelClasses="text-right required"
                                                v-model="formDetalle.FgApplyOversize"
                                                :value.sync="formDetalle.FgApplyOversize"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.FgApplyOversize.$error }"
                                                @blur="$v.formDetalle.FgApplyOversize.$touch()"
                                                :options="selectOptionsEspecial"
                                                :is-valid="hasError($v.formDetalle.FgApplyOversize)"
                                                :invalid-feedback="errorMessage($v.formDetalle.FgApplyOversize)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12" xl="12" class="pr-0">
                                            <CSelect
                                                :label="$t('label.Seal')"
                                                addLabelClasses="text-right required"
                                                v-model="formDetalle.FgApplySeal"
                                                :value.sync="formDetalle.FgApplySeal"
                                                :addInputClasses="{ 'is-invalid': $v.formDetalle.FgApplySeal.$error }"
                                                @blur="$v.formDetalle.FgApplySeal.$touch()"
                                                :options="selectOptionsEspecial"
                                                :is-valid="hasError($v.formDetalle.FgApplySeal)"
                                                :invalid-feedback="errorMessage($v.formDetalle.FgApplySeal)"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            >
                                            </CSelect>
                                        </CCol>
                                        <CCol sm="11" lg="12" xl="12" class="pr-0" v-if="!idIndentification">
                                            <CSelect
                                                :label="$t('label.status')"
                                                addLabelClasses="text-right required"
                                                :is-valid="formDetalle.FgActTpCargoDetail"
                                                v-model="formDetalle.FgActTpCargoDetail"
                                                :value.sync="formDetalle.FgActTpCargoDetail"
                                                :options="selectOptions"
                                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                            />
                                        </CCol>
                                        
                                    </CRow>         
                                </CCol>        
                                <CCol sm="11" lg="7" xl="7">
                                    <CRow>    
                                        <CCol sm="12">
                                            <CTabs variant="tabs" :active-tab="0">
                                                <CTab :title="$t('label.Outside')" >
                                                    <CCard bodyWrapper class="pb-0">
                                                        <CRow>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput 
                                                                    :label="$t('label.height')"
                                                                    type="text"
                                                                    append="m"
                                                                    addLabelClasses="text-right required"
                                                                    placeholder="000,00"
                                                                    v-model="$v.formDetalle.OutsideHeigth.$model"
                                                                    @keyup="$v.formDetalle.OutsideHeigth.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.OutsideHeigth.$error }"
                                                                    required 
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.OutsideHeigth)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.OutsideHeigth)"
                                                                >   
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.OutsideHeigth.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.OutsideHeigth.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideHeigth.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.OutsideHeigth.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideHeigth.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.OutsideHeigth.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :label="$t('label.width')"
                                                                    addLabelClasses="text-right required"
                                                                    append="m"
                                                                    placeholder="000,00"
                                                                    v-model="$v.formDetalle.OutsideWidth.$model"
                                                                    @keyup="$v.formDetalle.OutsideWidth.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.OutsideWidth.$error }"
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    required 
                                                                    :is-valid="hasError($v.formDetalle.OutsideWidth)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.OutsideWidth)"
                                                                >
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.OutsideWidth.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.OutsideWidth.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideWidth.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.OutsideWidth.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideWidth.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.OutsideWidth.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :label="$t('label.length')"
                                                                    addLabelClasses="text-right required"
                                                                    append="m"
                                                                    placeholder="000,00"
                                                                    v-model="$v.formDetalle.OutsideLength.$model"
                                                                    @keyup="$v.formDetalle.OutsideLength.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.OutsideLength.$error }"
                                                                    required 
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.OutsideLength)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.OutsideLength)"
                                                                >
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.OutsideLength.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.OutsideLength.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideLength.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.OutsideLength.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.OutsideLength.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.OutsideLength.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :disabled="true"
                                                                    :label="$t('label.volume')"
                                                                    addLabelClasses="text-right required"
                                                                    append="m³"
                                                                    placeholder="000,00"
                                                                    :value.sync="totalOutside"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.OutsideVolumen.$error }"
                                                                    required
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.OutsideVolumen)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.OutsideVolumen)"
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                    </CCard>
                                                </CTab>
                                                <CTab :title="$t('label.Inside')">
                                                    <CCard bodyWrapper class="pb-0">
                                                        <CRow>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :label="$t('label.height')"
                                                                    placeholder="000,00"
                                                                    append="m"
                                                                    addLabelClasses="text-right required"
                                                                    v-model="$v.formDetalle.InsideHeigth.$model"
                                                                    @keyup="$v.formDetalle.InsideHeigth.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.InsideHeigth.$error }"
                                                                    required 
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.InsideHeigth)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.InsideHeigth)"
                                                                >
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.InsideHeigth.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.InsideHeigth.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideHeigth.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.InsideHeigth.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideHeigth.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.InsideHeigth.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :label="$t('label.width')"
                                                                    placeholder="000,00"
                                                                    append="m"
                                                                    addLabelClasses="text-right required"
                                                                    v-model="$v.formDetalle.InsideWidth.$model"
                                                                    @keyup="$v.formDetalle.InsideWidth.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.InsideWidth.$error }"
                                                                    required 
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.InsideWidth)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.InsideWidth)"
                                                                >
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.InsideWidth.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.InsideWidth.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideWidth.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.InsideWidth.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideWidth.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.InsideWidth.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :label="$t('label.length')"
                                                                    placeholder="000,00"
                                                                    append="m"
                                                                    addLabelClasses="text-right required"
                                                                    v-model="$v.formDetalle.InsideLength.$model"
                                                                    @keyup="$v.formDetalle.InsideLength.$touch()"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.InsideLength.$error }"
                                                                    required 
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.InsideLength)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.InsideLength)"
                                                                
                                                                >
                                                                    <!--template #invalid-feedback>
                                                                        <div v-if="$v.formDetalle.InsideLength.$error">
                                                                            <div class='text-danger' v-if="!$v.formDetalle.InsideLength.required">{{$t('label.required')}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideLength.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.InsideLength.$params.minValue.min}}</div>
                                                                            <div class='text-danger' v-else-if="!$v.formDetalle.InsideLength.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.InsideLength.$params.maxValue.max}}</div>
                                                                        </div>
                                                                    </template-->
                                                                </NumberInput>
                                                            </CCol>
                                                            <CCol sm="11" lg="6" xl="6" class="px-0">
                                                                <NumberInput
                                                                    :disabled="true"
                                                                    :label="$t('label.volume')"
                                                                    append="m³"
                                                                    placeholder="000,00"
                                                                    addLabelClasses="text-right required"
                                                                    :value.sync="totalInside"
                                                                    :addInputClasses="{ 'is-invalid': $v.formDetalle.InsideVolumen.$error }"
                                                                    required
                                                                    :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                                    :is-valid="hasError($v.formDetalle.InsideVolumen)"
                                                                    :invalid-feedback="errorMessage($v.formDetalle.InsideVolumen)"
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                    </CCard>
                                                </CTab>
                                            </CTabs>
                                        </CCol>
                                        <CCol sm="12">
                                            <CCard bodyWrapper>
                                                <CRow>
                                                    <CCol sm="11" lg="10" xl="10">
                                                        <NumberInput
                                                            :label="$t('label.weightEmpty')"
                                                            :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                                                            append="KG"
                                                            placeholder="000,00"
                                                            v-model="$v.formDetalle.WeigthEmpty.$model"
                                                            @keyup="$v.formDetalle.WeigthEmpty.$touch()"
                                                            addLabelClasses="required text-right"
                                                            :addInputClasses="{ 'is-invalid': $v.formDetalle.WeigthEmpty.$error }"
                                                            required 
                                                            :is-valid="hasError($v.formDetalle.WeigthEmpty)"
                                                            :invalid-feedback="errorMessage($v.formDetalle.WeigthEmpty)"
                                                        >
                                                            <!--template #invalid-feedback>
                                                                <div v-if="$v.formDetalle.WeigthEmpty.$error">
                                                                    <div class='text-danger' v-if="!$v.formDetalle.WeigthEmpty.required">{{$t('label.required')}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.WeigthEmpty.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.WeigthEmpty.$params.minValue.min}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.WeigthEmpty.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.WeigthEmpty.$params.maxValue.max}}</div>
                                                                </div>
                                                            </template-->
                                                        </NumberInput>
                                                    </CCol>
                                                    <CCol sm="11" lg="10" xl="10">
                                                        <NumberInput
                                                            :label="$t('label.maxLoad')"
                                                            :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                                                            append="KG"
                                                            placeholder="000,00"
                                                            v-model="$v.formDetalle.MaxLoad.$model"
                                                            @keyup="$v.formDetalle.MaxLoad.$touch()"
                                                            addLabelClasses="required text-right"
                                                            :addInputClasses="{ 'is-invalid': $v.formDetalle.MaxLoad.$error }"
                                                            required 
                                                            :is-valid="hasError($v.formDetalle.MaxLoad)"
                                                            :invalid-feedback="errorMessage($v.formDetalle.MaxLoad)"
                                                        >
                                                            <!--template #invalid-feedback>
                                                                <div v-if="$v.formDetalle.MaxLoad.$error">
                                                                    <div class='text-danger' v-if="!$v.formDetalle.MaxLoad.required">{{$t('label.required')}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.MaxLoad.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.MaxLoad.$params.minValue.min}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.MaxLoad.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.MaxLoad.$params.maxValue.max}}</div>
                                                                </div>
                                                            </template-->
                                                        </NumberInput>
                                                    </CCol>
                                                    <CCol sm="11" lg="10" xl="10">
                                                        <NumberInput
                                                            :label="$t('label.maxGrossWeight')"
                                                            disabled
                                                            :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                                                            append="KG"
                                                            placeholder="000,00"
                                                            addLabelClasses="required text-right"
                                                            :value.sync="totalMaxGross"
                                                            @keyup="$v.formDetalle.MaxGrossWeigth.$touch()"
                                                            :addInputClasses="{ 'is-invalid': $v.formDetalle.MaxGrossWeigth.$error }"
                                                            required 
                                                            :is-valid="hasError($v.formDetalle.MaxGrossWeigth)"
                                                            :invalid-feedback="errorMessage($v.formDetalle.MaxGrossWeigth)"
                                                        >
                                                            <!--template #invalid-feedback>
                                                                <div v-if="$v.formDetalle.MaxGrossWeigth.$error">
                                                                    <div class='text-danger' v-if="!$v.formDetalle.MaxGrossWeigth.required">{{$t('label.required')}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.MaxGrossWeigth.minValue">{{$t('label.minValueIs')}} {{$v.formDetalle.MaxGrossWeigth.$params.minValue.min}}</div>
                                                                    <div class='text-danger' v-else-if="!$v.formDetalle.MaxGrossWeigth.maxValue">{{$t('label.maxValueIs')}} {{$v.formDetalle.MaxGrossWeigth.$params.maxValue.max}}</div>
                                                                </div>
                                                            </template-->
                                                        </NumberInput>
                                                    </CCol>
                                                </CRow>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>               
                        </CustomTab>
                        <CustomTab :title="$t('label.isoCode')" >
                            <CRow class="mt-2 justify-content-center">               
                                <CCol sm="11" lg="10" xl="10">
                                    <CCard>
                                        <CCardHeader>
                                            <CSelect
                                                :label="$t('label.isoStandar')"
                                                addLabelClasses="text-right required"
                                                :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-3 col-xl-3'}"
                                                :value="formStandartd.StandardDocumentId"
                                                :addInputClasses="{ 'is-invalid': $v.formStandartd.StandardDocumentId.$error }"
                                                @blur="$v.formStandartd.StandardDocumentId.$touch()"
                                                @change="formStandartd.StandardDocumentId = $event.target.value"
                                                :options="optionList"
                                                :is-valid="hasError($v.formStandartd.StandardDocumentId)"
                                                :invalid-feedback="errorMessage($v.formStandartd.StandardDocumentId)"
                                            >
                                            </CSelect>
                                        </CCardHeader>
                                        <CCardBody>
                                            <CCol sm="11" lg="12" xl="12">
                                                <CRow class="justify-content-center">
                                                    <CCol sm="11" lg="5" xl="5" class="px-0">
                                                        <CInput
                                                            :label="$t('label.isoCode')"
                                                            addLabelClasses="text-right required"
                                                            :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                            v-uppercase
                                                            :placeholder="$t('label.enter')+' '+$t('label.isoCode')"
                                                            v-model="$v.formStandartd.MetadataDesc.$model"
                                                            :addInputClasses="{ 'is-invalid': $v.formStandartd.MetadataDesc.$error }"
                                                            required 
                                                            :invalid-feedback="errorMessage($v.formStandartd.MetadataDesc)"
                                                            :is-valid="hasError($v.formStandartd.MetadataDesc)"
                                                        >
                                                        </CInput>
                                                    </CCol>
                                                    <CCol sm="11" lg="5" xl="5" class="px-0" v-if="idIdentificacionStandartd">
                                                        <CSelect
                                                            :label="$t('label.status')"
                                                            :horizontal="{label:'col-sm-11 col-lg-5 col-xl-5', input:'col-sm-11 col-lg-7 col-xl-7'}"
                                                            addLabelClasses="text-right required"
                                                            :is-valid="formStandartd.FgActMetadata"
                                                            v-model="formStandartd.FgActMetadata"
                                                            :value.sync="formStandartd.FgActMetadata"
                                                            :options="selectOptions"
                                                        />
                                                    </CCol>
                                                    <CCol sm="11" lg="2" xl="2" class="text-center">
                                                        <CButton
                                                            square
                                                            color="add"
                                                            size="sm"
                                                            v-c-tooltip="{
                                                                content: $t('label.add'),
                                                                placement: 'top-end'
                                                            }"
                                                            @click="updatedTable()"
                                                            >
                                                                <CIcon name="checkAlt"/>
                                                        </CButton>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                :items="formatedItems"
                                                :fields="fields"
                                                :table-filter="tableText.tableFilterText"
                                                :items-per-page-select="tableText.itemsPerPageText"
                                                :noItemsView="tableText.noItemsViewText"
                                                :items-per-page="5"
                                                pagination
                                                column-filter
                                                hover
                                            >
                                                <template #MetadataId="{ index}">
                                                    <td class="text-center">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #Status="{item}">
                                                    <td class="text-center">
                                                        <CBadge v-if="item.status" color="success">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                        <CBadge v-if="!item.status" color="danger">
                                                            {{ $t('label.'+item.Status) }}
                                                        </CBadge>
                                                    </td>
                                                </template>
                                                <template #acciones="{item,index}">
                                                    <td class="text-center">
                                                        <CButton
                                                            color="edit"
                                                            square
                                                            size="sm"
                                                            class="mr-1"
                                                            @click="editTable(item,index)"
                                                            v-c-tooltip="{
                                                                content: $t('label.edit'),
                                                                placement: 'top-end'
                                                            }"
                                                        >
                                                            <CIcon name="pencil"/>
                                                        </CButton>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CustomTab>
                    </CustomTabs>
    
             
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading">
                        <span  class="spinner-border spinner-border-sm" role="Accesory" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>
<style>
    .input-group-append{
        height:35px;
    }
</style>
<script>
    import ENUM from '@/_store/enum';
    import { FormDetalle } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import { GenerateImgPlaceholder, errorMessage, alertPropertiesHelpers } from '@/_helpers/funciones';
    import NumberInput from '@/components/numberinput';
    import InputColor from '@/components/InputColor';
    import {DateFormater,validURL} from '@/_helpers/funciones';

    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    function fields(){
        return [
            { key: 'MetadataId', label: '#',_classes: 'text-center', _style: 'width:3%; text-align:center', filter: false },
            { key: 'MetadataDesc',label: this.$t('label.name'), _classes: 'text-uppercase', _style:'width:30%'  },
            { key: 'StandardDocumentName',label: this.$t('label.Estandar'), _classes: 'text-uppercase', _style:'width:30%'  },
            { key: 'FormatedDate',label: this.$t('label.date'), _classes:'text-center', _style:'width:15%' },
            { key: 'Status',label: this.$t('label.status'), _classes: 'text-center', _style:'width:15%' },
            { key: 'acciones', label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false }
        ];
    }
    //data
    function data() {
        return {
            formDetalle:{
                TpCargoDetailId:0,
                TpCargoId:'',
                SizeId:'',
                TpCargoDetailName:'',
                TpCargoDetailCode:'',
                OutsideLength:0,
                OutsideWidth:0,
                OutsideHeigth:0,
                OutsideVolumen:0,
                InsideLength:0,
                InsideWidth:0,
                InsideHeigth:0,
                InsideVolumen:0,
                WeigthEmpty:0,
                MaxLoad:0,
                defaultColor: '#FFFFFF',
                DocumentJson:[],
                MaxGrossWeigth:0,
                FgSpecial:false,
                FgApplyRefrigeration:false,
                FgApplyOversize:false,
                FgApplySeal:false,
                FgActTpCargoDetail:true
            },
            myDataCombo:[],
            formStandartd:{
                position:null,
                StandardDocumentId:'',
                MetadataDesc:'',
                FgActMetadata:true,
            },
            changeData:true,
            originalAct: '',
            tituloModal: this.$t('label.nueva')+' '+this.$t('label.classification'),
            activeTab: 0,
            activePage: 1,
        }
    }

    //methods
    function cerrarModal(){
        if(this.apiStateFormLoading)
            return
        this.$store.state.contenedores.modalDetalle = false;
    }
    function registerData(){
        try { 
            this.$v.formDetalle.$touch();
            if (this.$v.formDetalle.$pending || this.$v.formDetalle.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formDetalle.$pending || this.$v.formDetalle.$error) return;

            let { TpCargoDetailId,TpCargoId,SizeId,TpCargoDetailName,TpCargoDetailCode,OutsideLength,OutsideWidth,
                OutsideHeigth,OutsideVolumen,InsideLength,InsideWidth,InsideHeigth,InsideVolumen,WeigthEmpty,MaxLoad,
                DocumentJson,MaxGrossWeigth,defaultColor,FgSpecial,FgActTpCargoDetail, FgApplyRefrigeration, FgApplyOversize, FgApplySeal } = this.formDetalle;
            const { dispatch } = this.$store;
            if(this.originalAct !== '' && TpCargoDetailId !== 0){
                if(this.originalAct !== FgActTpCargoDetail){
                    this.$swal.fire(
                        alertPropertiesHelpers(this,{
                            text: `${this.$t('label.changeStatusQuestion')} ${TpCargoDetailName}?`
                        })
                    ).then((result) => {
                        if (result.isConfirmed) {
                            dispatch('contenedores/dataDetails', { TpCargoDetailId,TpCargoId,SizeId,TpCargoDetailName,TpCargoDetailCode,OutsideLength,OutsideWidth,
                                                                    OutsideHeigth,OutsideVolumen,InsideLength,InsideWidth,InsideHeigth,InsideVolumen,WeigthEmpty,MaxLoad,
                                                                    DocumentJson,MaxGrossWeigth,color:defaultColor,FgSpecial,FgActTpCargoDetail, FgApplyRefrigeration, FgApplyOversize, FgApplySeal });
                    }else {
                        //	this.$swal(this.$t('label.operationCancelled'));
                        }
                    })
                }else{
                    dispatch('contenedores/dataDetails', { TpCargoDetailId,TpCargoId,SizeId,TpCargoDetailName,TpCargoDetailCode,OutsideLength,OutsideWidth,
                                                            OutsideHeigth,OutsideVolumen,InsideLength,InsideWidth,InsideHeigth,InsideVolumen,WeigthEmpty,MaxLoad,
                                                            DocumentJson,MaxGrossWeigth,color:defaultColor,FgSpecial,FgActTpCargoDetail, FgApplyRefrigeration, FgApplyOversize, FgApplySeal  });
                }
            }else{
                dispatch('contenedores/dataDetails', { TpCargoDetailId,TpCargoId,SizeId,TpCargoDetailName,TpCargoDetailCode,OutsideLength,OutsideWidth,
                                                        OutsideHeigth,OutsideVolumen,InsideLength,InsideWidth,InsideHeigth,InsideVolumen,WeigthEmpty,MaxLoad,
                                                        DocumentJson,MaxGrossWeigth,color:defaultColor,FgSpecial,FgActTpCargoDetail, FgApplyRefrigeration, FgApplyOversize, FgApplySeal  });
                }
        } catch (e) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    function handleFileUpload(files, e) {
        this.formDetalle.Image_url = '';
        this.formDetalle.Route = this.$refs.imageInput.file;
    }
    function updatedTable(){
        try { 

            this.$v.formStandartd.$touch();
            if (this.$v.formStandartd.$pending || this.$v.formStandartd.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formStandartd.$pending || this.$v.formStandartd.$error) return;


            const idStandarDocument = this.formStandartd.StandardDocumentId;

            let positionCombo = this.myDataCombo.map(function(e) {
                return e.StandardDocumentId; 
            }).indexOf(idStandarDocument);

            if(this.formStandartd.position === null){
                this.formDetalle.DocumentJson.push({
                    MetadataId: '',
                    StandardDocumentId: this.myDataCombo[positionCombo].StandardDocumentId,
                    StandardDocumentName: this.myDataCombo[positionCombo].StandardDocumentName,
                    StandardDocumentDescription: this.myDataCombo[positionCombo].StandardDocumentDescription,
                    StandardOrganization: this.myDataCombo[positionCombo].StandardOrganization,
                    MetadataDesc: this.formStandartd.MetadataDesc,
                    FgActMetadata: true,
                    Status: "ACTIVO",
                });
            }else{
                const positionOriginal = this.formStandartd.position;
                this.formDetalle.DocumentJson[positionOriginal].StandardDocumentId = this.myDataCombo[positionCombo].StandardDocumentId;
                this.formDetalle.DocumentJson[positionOriginal].StandardDocumentName = this.myDataCombo[positionCombo].StandardDocumentName;
                this.formDetalle.DocumentJson[positionOriginal].StandardDocumentDescription = this.myDataCombo[positionCombo].StandardDocumentDescription;
                this.formDetalle.DocumentJson[positionOriginal].StandardOrganization = this.myDataCombo[positionCombo].StandardOrganization;
                this.formDetalle.DocumentJson[positionOriginal].MetadataDesc = this.formStandartd.MetadataDesc;
                this.formDetalle.DocumentJson[positionOriginal].FgActMetadata = this.formStandartd.FgActMetadata;
                this.formDetalle.DocumentJson[positionOriginal].Status = this.formStandartd.FgActMetadata ? "ACTIVO" : "INACTIVO";
            }
            this.formStandartd.position = null;
            this.formStandartd.StandardDocumentId = '';
            this.formStandartd.MetadataDesc = '';
            this.formStandartd.FgActMetadata = true;
            this.$nextTick(() => { this.$v.formStandartd.$reset() })
        } catch (e) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    function editTable(item,index){
       this.formStandartd.position = index;
       this.formStandartd.MetadataDesc = item.MetadataDesc;
       this.formStandartd.StandardDocumentId = item.StandardDocumentId;
       this.formStandartd.FgActMetadata = item.FgActMetadata;
    }
    function numberFormat(e){
        return 1;
    }

    function handleTab(tab) {
        this.activeTab = tab;
    }
    
    function isActiveTab(current) {
        return this.activeTab == current;
    }
    //computed
    function idIndentification(){
        return this.formDetalle.TpCargoDetailId === 0;
    }
    function ChangeCargoId(){
        return this.formDetalle.TpCargoId;
    }
    function idIdentificacionStandartd(){
        return this.formStandartd.position !== null;
    }
    function totalOutside(){
        const { OutsideLength,OutsideWidth,OutsideHeigth } = this.formDetalle;
        let total = 0;
        if(parseFloat(OutsideLength) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(OutsideLength);
        }
        if(parseFloat(OutsideWidth) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(OutsideWidth);
        }
        if(parseFloat(OutsideHeigth) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(OutsideHeigth);
        }
        this.formDetalle.OutsideVolumen = total;
        return total;
    }
    function totalInside(){
        const { InsideLength,InsideWidth,InsideHeigth } = this.formDetalle;
        let total = 0;
        if(parseFloat(InsideLength) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(InsideLength);
        }
        if(parseFloat(InsideWidth) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(InsideWidth);
        }
        if(parseFloat(InsideHeigth) > 0){
            if(total === 0)
                total = 1;
            total = total * parseFloat(InsideHeigth);
        }
        this.formDetalle.InsideVolumen = total;
        return total;
    }
    function totalMaxGross(){
        let { WeigthEmpty,MaxLoad } = this.formDetalle;
        let total = 0;
        if(parseFloat(WeigthEmpty) > 0){
            total = total + parseFloat(WeigthEmpty);
        }
        if(parseFloat(MaxLoad) > 0){
            total = total + parseFloat(MaxLoad);
        }
        this.formDetalle.MaxGrossWeigth = total;
        return total;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionList(){
        if(this.myDataCombo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.toSelect'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.toSelect'),
            }]
            this.myDataCombo.map(function(e){
                if(e.FgActStandardDocument){
                    chart.push({
                        value: e.StandardDocumentId, 
                        label: e.StandardDocumentName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListCargo(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListSize(){
        if(this.myDataSize.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataSize.map(function(e){
                if(e.FgActSize){
                    chart.push({
                        value: e.SizeId, 
                        label: e.Size,
                    })
                }
            })
            return chart;
        }
    }
    function formatedItems() {
        let computedItems = this.formDetalle.DocumentJson;
       
        if(computedItems.length !== 0){
            computedItems = computedItems.map((item) => Object.assign({}, item, {
                FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                status: item.FgActMetadata,
                _classes: ( item.FgActMetadata ) ? '' : 'table-danger'
            }));
        }
        return computedItems.filter(item => {
            return item
        })
    }

    function selectOptions(){
        return [
            { 
                value: 1, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: 0, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    function selectOptionsEspecial() {
        return [
            { 
                value: true, 
                label: this.$t('label.apply')
            },
            { 
                value: false, 
                label: 'N/A'
            }
        ];
    }

    //watch

    function modalDetalle(newQuestion){
        this.activeTab = 0;
        if(newQuestion === false){
            this.formDetalle.TpCargoDetailId=0;
            this.formDetalle.TpCargoId='';
            this.formDetalle.SizeId='';
            this.formDetalle.TpCargoDetailName='';
            this.formDetalle.TpCargoDetailCode='';
            this.formDetalle.OutsideLength=0;
            this.formDetalle.OutsideWidth=0;
            this.formDetalle.OutsideHeigth=0;
            this.formDetalle.OutsideVolumen=0;
            this.formDetalle.InsideLength=0;
            this.formDetalle.InsideWidth=0;
            this.formDetalle.InsideHeigth=0;
            this.formDetalle.InsideVolumen=0;
            this.formDetalle.WeigthEmpty=0;
            this.formDetalle.MaxLoad=0;
            this.formDetalle.DocumentJson=[];
            this.formDetalle.MaxGrossWeigth=0;
            this.formDetalle.FgSpecial=true;
            this.formDetalle.FgApplyRefrigeration=false;
            this.formDetalle.FgApplyOversize=false;
            this.formDetalle.FgApplySeal=false;
            this.formDetalle.defaultColor = '#FFFFFF';

            this.formStandartd.MetadataDesc = '';
            this.formStandartd.StandardDocumentId='';
            this.formStandartd.position = null;

            this.changeData = true;
            this.originalAct = '';
            this.formDetalle.FgActTpCargoDetail = true;
            
            this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.classification');
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);

           
        }else{
            this.tituloModal =this.$t('label.nueva')+' '+this.$t('label.classification');
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            try {
                this.$http.ejecutar('GET', 'StandardDocument-list', { Filter: 'ALL' }, '')
                .then(
                    combo => {
                        if(combo.status === 200){
                            if(this.idState === 0){
                                if(this.filtroSize !== '')
                                    this.formDetalle.SizeId=this.filtroSize;
                                if(this.filtroCargo !== ''){
                                    this.formDetalle.TpCargoId=this.filtroCargo;

                                    const Index = this.myDataGrupo.map(function(e) {
                                        return e.TpCargoId; 
                                    }).indexOf(this.filtroCargo);

                                    if(Index !== -1){
                                        this.formDetalle.TpCargoDetailCode = this.myDataGrupo[Index].TpCargoCode;
                                    }
                                }
                                    
                                this.myDataCombo = combo.data.data;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }else{
                                const id = this.idState;
                                this.$http.ejecutar('GET', 'TpCargoDetail-by-id',{ TpCargoDetailId: id }, '' ).then(response => {
                                    if(response.status === 200){
                                        const Information = response.data.data;
                                        if(Information.length !== 0){
                                            this.changeData = false;
                                            this.tituloModal = this.$t('label.edit')+' '+this.$t('label.classification')+': '+Information[0].TpCargoDetailName;
                                            this.formDetalle.TpCargoDetailId=Information[0].TpCargoDetailId;
                                            this.formDetalle.TpCargoId=Information[0].TpCargoId;
                                            this.formDetalle.SizeId=Information[0].SizeId;
                                            this.formDetalle.TpCargoDetailName=Information[0].TpCargoDetailName;
                                            this.formDetalle.TpCargoDetailCode=Information[0].TpCargoDetailCode;
                                            this.formDetalle.OutsideLength=Information[0].OutsideLength;
                                            this.formDetalle.OutsideWidth=Information[0].OutsideWidth;
                                            this.formDetalle.OutsideHeigth=Information[0].OutsideHeigth;
                                            this.formDetalle.OutsideVolumen=Information[0].OutsideVolumen;
                                            this.formDetalle.InsideLength=Information[0].InsideLength;
                                            this.formDetalle.InsideWidth=Information[0].InsideWidth;
                                            this.formDetalle.InsideHeigth=Information[0].InsideHeigth;
                                            this.formDetalle.InsideVolumen=Information[0].InsideVolumen;
                                            this.formDetalle.WeigthEmpty=Information[0].WeigthEmpty;
                                            this.formDetalle.MaxLoad=Information[0].MaxLoad;
                                            this.formDetalle.DocumentJson=Information[0].MetadataJson;
                                            this.formDetalle.MaxGrossWeigth=Information[0].MaxGrossWeigth;
                                            this.formDetalle.FgSpecial=Information[0].FgSpecial;
                                            this.formDetalle.FgApplyRefrigeration=Information[0].FgApplyRefrigeration;
                                            this.formDetalle.FgApplyOversize=Information[0].FgApplyOversize;
                                            this.formDetalle.FgApplySeal=Information[0].FgApplySeal;
                                            if(Information[0].Color === null || Information[0].Color === undefined){
                                                this.formDetalle.defaultColor="#FFFFFF";
                                            }else
                                                this.formDetalle.defaultColor=Information[0].Color;
                                            
                                            this.originalAct = Information[0].FgActTpCargoDetail;
                                            this.formDetalle.FgActTpCargoDetail = Information[0].FgActTpCargoDetail;
                                            this.myDataCombo = combo.data.data;
                                            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                                        }
                                        this.$v.formDetalle.$touch();
                                    }else{
                                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                        this.$store.state.contenedores.modalDetalle = false;
                                    }
                                }).catch(err => {
                                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                    this.$store.state.contenedores.modalDetalle = false;
                                    this.$store.commit('contenedores/messageMutation', err);
                                });
                            }
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalDetalle = false;
                        }   
                    },
                    error => {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalDetalle = false;
                        this.$store.commit('contenedores/messageMutation', error);
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                this.$store.state.contenedores.modalDetalle = false;
                this.$store.commit('contenedores/messageMutation', err);
            }
            if(newQuestion === true &&  this.formDetalle.TpCargoDetailId != '')
            this.$v.formDetalle.$touch();

        }
        if(newQuestion === true &&  this.formDetalle.TpCargoDetailId != '')
            this.$v.formDetalle.$touch();
    }

    export default {
        name: 'modal-classification',
        data,
        validations(){ return FormDetalle() },
        methods:{
            registerData,
            cerrarModal,
            updatedTable,
            editTable,
            numberFormat,
            handleFileUpload, 
            errorMessage,
            isActiveTab,
            handleTab,
        },
        mixins: [TipoGruaMixin],
        components: {
            NumberInput,
            InputColor,
            CustomTabs, 
            CustomTab,
        },
        directives: UpperCase,
        computed:{
            idIndentification,
            ChangeCargoId,
            idIdentificacionStandartd,
            optionList,
            formatedItems,
            optionListCargo,
            totalOutside,
            totalInside,
            totalMaxGross,
            optionListSize,
            apiStateFormLoading,
            fields,
            selectOptions,
            selectOptionsEspecial,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                myDataSize: state => state.contenedores.myDataSize,
                modalDetalle: state=> state.contenedores.modalDetalle,
                filtroSize: state => state.contenedores.filtroSize,
                filtroCargo: state => state.contenedores.filtroCargo,
            })
        },
        watch:{
            ChangeCargoId(newQuestion){
                if(this.changeData){
                    if(newQuestion !== ''){

                        const CargoId = this.formDetalle.TpCargoId;
                        const Index = this.myDataGrupo.map(function(e) {
                            return e.TpCargoId; 
                        }).indexOf(CargoId);

                        if(Index !== -1){

                            this.formDetalle.TpCargoDetailCode = this.myDataGrupo[Index].TpCargoCode;
                            this.formDetalle.FgSpecial= this.myDataGrupo[Index].FgAccesory;
                        }
                    }
                }else{
                    this.changeData = true;
                }
                
            },
            modalDetalle,
           
        }
    }
</script>