import {
  required,
  maxLength,
} from 'vuelidate/lib/validators';

const nameLength = (value) => {
  let Array = value.split(' ');
  for (let index = 0; index < Array.length; index++) {
    if(Array[index].length > 20){
      return false
    };
  }
  return true;
};

//export default () => {
  export const order = (modalityVessel, anulled, fgNull) => {
    let modality = {};
    if(modalityVessel == '3C7C3A87-557A-453E-B98C-52488633086C'){ modality = {  } }

  if(anulled && !fgNull) {
    return {
      order: {
        transportName: { },
        driverName: { },
        vehicleName: { },
        yardName: { },
        implementId: { required }, 
        blId: { required }, 
        checkerId: { }, 
        hour: { required },
        date: { required },
        observation: { maxLength: maxLength(150), nameLength },
        observationAnulation: { maxLength: maxLength(150), nameLength, required },
      },
    }
  } else {
    return {
      order: {
        transportName: { },
        driverName: { },
        vehicleName: { },
        yardName: { },
        implementId: { required }, 
        blId: { required }, 
        checkerId: { }, 
        hour: { required },
        date: { required },
        observation: { maxLength: maxLength(150), nameLength },
        observationAnulation: { },
      },
    }
  }
}