import { required, maxLength } from 'vuelidate/lib/validators';

export default () => {
  return {
    ServiceId: { required },
    ItineraryStatus: { required },
    ServiceModel: { required },
    ArrivalTime: { required },
    TimeSail: { required },
    StartDate: { required },
    EndDate: { required },
    ServiceVesselId: { required },
    VesselModel: { required },
    Color: { required },
    ItineraryObservation: { maxLength: maxLength(250) },
    ServicePortId: { required },
    ShippingAgencyId: { required },
    Voyage: { required },
    OrigitPortId: { required },
  };
};
