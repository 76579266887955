<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Status="{item}">
        <td class='text-center'>
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
     </template>
      <template #TransactionDate="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item.TransactionDate) }}
        </td>
      </template>
      <template #TransactionBegin="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item.TransactionBegin) }}
        </td>
      </template>
      <template #TransactionFinish="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item.TransactionFinish) }}
        </td>
      </template>
      <template #TransaRegDate="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item.TransaRegDate) }}
        </td>
      </template>
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    TransactionList: [],
  };
}

//methods
async function getBlCargoTransactionList() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('BlCargoTransaction-list-by-BlCargoId', { BlCargoId: this.Bl?.BlCargoId })
    .then((response) => {
      this.TransactionList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.TransactionList?.map((item) => {
    return {
      ...item,
      EventDs: item[`EventDs${_lang}`] ?? '',
      TpTransacEirName: item[`TpTransacEirName${_lang}`] ?? '',
      YardName: item.YardName ?? '',
      MachineCode: item.MachineCode ?? '',
      DeviceCode: item.DeviceCode ?? '',
      TotalTimeH: item.TotalTimeH ?? '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width:45px;', filter: false},
    { label: this.$t('label.event'), key: 'EventDs', _classes: 'text-uppercase text-center' , _style:'min-width: 180px;'},
    { label: this.$t('label.transaction'), key: 'TpTransacEirName', _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
    { label: this.$t('label.yard'), key: 'YardName',_classes: 'text-uppercase text-center',  _style: 'min-width: 180px;'},
    { label: this.$t('label.machine'), key: 'MachineCode',  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;', },
    { label: this.$t('label.transactionDate'), key: 'TransactionDate',  _classes: 'text-uppercase text-center',  _style:'min-width: 180px;'},
    { label: this.$t('label.dateFrom'), key: 'TransactionBegin', _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
    { label: this.$t('label.dateTo'), key: 'TransactionFinish', _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
    { label: this.$t('label.ActivityTime'), key: 'TotalTimeH', _classes: 'text-uppercase text-center',  _style: 'min-width: 180px;' },
    { label: this.$t('label.device'), key: 'DeviceCode', _classes: 'text-uppercase text-center',  _style: 'min-width: 180px;' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'TransaRegDate', label: this.$t('label.date'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px;', _classes:"text-center" },
  ];
}

export default{
  name: 'CertificateEntry',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
    tab: Boolean,
  },
  methods: {
    getBlCargoTransactionList,
    formatNumber,
    formatDateTimeWithSlash,
  },
  computed: {
    computedList,
    fields,
  },
  watch: {
    tab: function (NewVal) {
      this.TransactionList = [];
      if (NewVal) {
        this.getBlCargoTransactionList();
      }
    },
  }
}
</script>