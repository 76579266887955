import { 
  imgInputTextsHelpers, 
  errorMessageHelpers 
} from '@/_helpers/funciones';

//Data:
function data() {
  return {}
}

//Meodos:
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function errorMessage(field){
  return errorMessageHelpers(this, field);
}
function touchRequiredValues() {
  this.$v.buque.VesselName.$touch();
  this.$v.buque.Imo.$touch();
  this.$v.buque.Mmsi.$touch();
  this.$v.buque.Loa.$touch();
  this.$v.buque.Gross.$touch();
  this.$v.buque.DraftBow.$touch();
  this.$v.buque.DraftStern.$touch();
  this.$v.buque.VesselCrane.$touch();
  this.$v.buque.VesselRoute.$touch();
  this.$v.buque.VesselRoute.$touch();
  this.$v.buque.CallSign.$touch();
  this.$v.pais.$touch();
  this.$v.buque.Lwl.$touch();
  this.$v.buque.SummerDwt.$touch();
  this.$v.buque.FuelOilCapacity.$touch();
  this.$v.buque.Teus.$touch();
}

//Computeds
function isSubmitValid(){
  if(this.isContainerShip)
    return this.$v.$invalid || this.isSubmit || this.$v.buque.Teus.$error || this.buque.Teus == ''; 
  else
    return this.$v.$invalid || this.isSubmit;
} 

function imgInputTexts(){
  return imgInputTextsHelpers(this);
}

export default {
  data,
  methods: {
    hasError,
    errorMessage,
    touchRequiredValues
  },
  computed: {
    isSubmitValid,
    imgInputTexts,
  }
}

export const DocMixin = {
  methods: {
    hasError
  }
}

/****** Bay ******/
function colNum(flag){
  if(flag && this.currentBay){
    return Number.parseInt(this.currentBay.RowStarboardUnderDeck) + Number.parseInt(this.currentBay.RowPortUnderDeck);
  } else if(this.currentBay){
    return Number.parseInt(this.currentBay.RowStarboardToDeck) + Number.parseInt(this.currentBay.RowPortToDeck);
  } else
    return 6;
}
function rowNum(flag){
  let count = 0;

  if(flag && this.currentBay){
    for (let index = Number.parseInt(this.currentBay.TierUnderDeckFrom); index <= Number.parseInt(this.currentBay.TierUnderDeckTo); index++) {
      if(index%2 == 0)
        count++;
    }
  } else if(this.currentBay){
    for (let index = Number.parseInt(this.currentBay.TierToDeckFrom); index <= Number.parseInt(this.currentBay.TierToDeckTo); index++) {
      if(index%2 == 0)
        count++;
    }
  } else
    return 6;

  return count;
}
function responsiveCol(crossLine) {
  let colToDeck = this.colNum(false);
  let colUnderDeck = this.colNum(true);
  let colNum = colToDeck >= colUnderDeck ? colToDeck : colUnderDeck;
  ;
  return crossLine ? colNum + 1 : colNum;
}

function toDeckHasData() {
  return this.toDeckPos.length != 0;
}
function underDeckHasData() {
  return this.underDeckPos.length != 0;
}
function isCrossLineUnder() {
  if(this.currentBay){
    return this.currentBay.FgCrossLineUnderDeck || this.currentBay.FgCrossLineUnderDeck == 1
    ? true : false;
  } else
    return false;
}
function isCrossLineTo() {
  if(this.currentBay) {
    return this.currentBay.FgCrossLineToDeck || this.currentBay.FgCrossLineToDeck == 1
    ? true : false;
  } else
    return false;
}
export const BayMixin = {
  methods: {
    colNum,
    rowNum,
    responsiveCol
  },
  computed: {
    toDeckHasData,
    underDeckHasData,
    isCrossLineTo,
    isCrossLineUnder
  }
}
