<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <FeriadoModal :modal="diaModal" @cerrarModal="diaModal=false" @child-refresh="refrescarTabla=true" />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.calendary')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="2">
            <CSelect
              v-model="periodo"
              :value.sync="periodo"
              :options="[2021,2022,2023]"
              @change="listarFechas"
            />
          </CCol>
          <CCol sm="3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><CIcon name="cil-search" /></span>
              </div>
              <input class="form-control" :placeholder="$t('label.enterSearch')" v-model="busqueda" >
            </div>
          </CCol>
          <CCol sm="4">
            <v-select 
              :options="tipoOptions" 
              v-model="TipoFeriado"
              :placeholder="$t('label.select')+' '+$t('label.holidayType')"
              :invalid-feedback="$t('label.required')"
              >
                <template slot="option" slot-scope="option" >
                    <span :style="option.color">
                      &nbsp;
                    </span>              
                    {{ option.label }}
                </template>
            </v-select>
          </CCol>
          <CCol sm="3" class="text-right mb-2">
            <CButton color="add" 
              v-c-tooltip="{placement:'top-end', content:$t('label.holiday')}" @click="abrirFeriado(1)" >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nuevo')}}
            </CButton>
          </CCol>
          <CCol sm="12" class="halto">
            <full-calendar :config.sync="configCallendar" :events="events" :header="header" :key="callendarKey" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { FullCalendar } from 'vue-full-calendar';
import "fullcalendar/dist/fullcalendar.css";
import FeriadoModal from './feriado-modal';
import { DateFormater } from '@/_helpers/funciones';
import General from '@/_mixins/general';
import _ from 'lodash';

//DATA
function data () {
  return {
    Loading: false,
    diaModal: false,
    refrescarTabla: false,
    TpHolidayId: '',
    TipoFeriado: '',
    events: [],
    tipoOptions: [],
    busqueda: '',
    fecha: new Date(),
    periodo: new Date().getFullYear(),
    header: {
      left: "prev,next today",
      center: "title",
      right: "month,agendaWeek,agendaDay,listYear"
    },
    callendarKey: 0
  }
}

function abrirFeriado(event) {
  if (event==1) {
    this.diaModal = Object.assign({ periodo: this.periodo });
  } else {
    this.diaModal = Object.assign({ periodo: this.periodo, item: event });
  }  
}

function configCallendar() {
  let sendCAllendarConfig = {
      locale: this.$i18n.locale,
      defaultView: 'month',
      eventClick: this.abrirFeriado,
      editable:false,
      validRange: rangoFecha(this.periodo)
  };
  
  return sendCAllendarConfig;
}

function rangoFecha(periodo) {
  var anioAtras = new Date(periodo,0,1);
  var anioSiguiente = new Date(periodo,11,31);
  
  return {
    start: anioAtras,
    end: anioSiguiente
  };
}

function listarTipoFeriado() {
  this.tipoOptions = [];
  let listado = Array;
  this.Loading = true;
  this.$http.get("TpHoliday-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.tipoOptions = listado.map(listado => Object.assign({}, this.tipoOptions, {
      value: listado.TpHolidayId,
      label: listado.TpHolidayName,
      color: 'padding: 1px 10px; border-radius: 15px; margin-right: 5px; background-color:'+listado.Color
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.Loading= false;
  });
}

function listarFechas() {
  let listado = Array;
  this.Loading = true;
  this.$http.get("HolidayCalendar-by-year", { Year: this.periodo })
  .then(response => {
    listado = [...response.data.data];
    listado = listado.filter(ob => ob.Status==="ACTIVO");
    if (this.busqueda!=="") listado = listado.filter(ob => ob.HolidayDs.includes(this.busqueda.toUpperCase()));
    if (this.TpHolidayId!=="") listado = listado.filter(ob => ob.TpHolidayId==this.TpHolidayId);
    
    this.events = [];
    this.events = listado.map((ob) => Object.assign({}, ob, {
      id: ob.HolidayCalendarId,
      start: DateFormater.formatDateWithoutSlash(ob.DateHoliday),
      end: DateFormater.formatDateWithoutSlash(ob.DateHoliday),
      title: ob.HolidayDs,
      allDay: true,
      color: ob.Color,
      textColor: '#FFF',
      editable: false
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.Loading= false;
  });
}
export default {
  name: 'index',
  mixins: [General],
  data,
  components: {
    FullCalendar,
    FeriadoModal
  },
  methods: {
    listarFechas,
    abrirFeriado,
    listarTipoFeriado,
    rangoFecha
  },
  watch: {
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.listarFechas();
        this.refrescarTabla=false;
      }
    },
    TipoFeriado: function () {
      if (this.TipoFeriado) {
        this.TpHolidayId = this.TipoFeriado.value;
      } else {
        this.TpHolidayId = '';
      }
      this.listarFechas();
    },
    busqueda: _.debounce(function(newVal){
      this.listarFechas();
    }, 700),
    periodo: function () {
      this.callendarKey++;
    },
     configCallendar: function(nuevo) {  
      this.callendarKey+= 1;
      //this.mountedItinerarioList();
    },
  },
  computed: {
    configCallendar
  },
  mounted() {
    this.listarFechas();
    this.listarTipoFeriado();
  },
}
</script>
<style scoped>
  .halto {
    overflow-y: auto;
  }

  .fc-content, .fc-list-item {
    cursor: pointer !important;
  }

  td {
    height: 50px !important;
  }
</style>