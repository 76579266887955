<template>
  <div sm="12">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow>
          <modalInsumo :modal="showModal" @cerrarModal="showModal=false" @child-refresh="refrescarTabla=true" />
          <CCol sm="12" class="text-right mb-2 mt-2">
            <CButton
              square
              color="add"
              v-c-tooltip="{
                content: $t('label.supply'),
                placement: 'top-end'
              }"
              @click="modalSupplie(true)"
            >
              <CIcon name="cil-playlist-add" /><span class="ml-1"
                >{{$t('label.nuevo')}}</span
              >
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
          class="align-center-row-datatable"
              size="lg"
              :fields="fields"
              :items="items"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              hover
              sorter
            >
              <template #Detalle="{ item }">
                <td class="text-center">
                    <CButton
                      size="sm"
                      color="edit"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.edit')+$t('label.supply'),
                        placement: 'top',
                      }"
                      @click="modalSupplie(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      size="sm"
                      color="wipe"
                      class="mx-1"
                      v-c-tooltip="{
                        content: $t('label.delete')+$t('label.supplie'),
                        placement: 'top-end',
                      }"
                      @click="ConfirmarEliminado(item)"
                    >
                      <CIcon name="cil-trash" />
                    </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import GeneralMixin from '@/_mixins/general';
import modalInsumo from './modal-insumo';
import { mapState } from 'vuex';

function data() {
  return {
    items: [],
    loadingOverlay: false,
    showModal: false,
    refrescarTabla: false,
    supplieItem: '',
    StowagePlanningId: ''
  };
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#', filter: false,
      _style: 'width:3%; text-align:center;',
      _classes: 'text-center',
    },          
    { key: 'ClientName', label:this.$t('label.supplier'), _style: 'width:10%;', _classes: 'text-center', },
    { key: 'GpoSupplyName', label:this.$t('label.group'), _style: 'width:10%;', _classes: 'text-center', },
    { key: 'SupplyName', label:this.$t('label.Supplies'), _style: 'width:10%;', _classes: 'text-center', },
    { key: 'UnitMeasureName', label:this.$t('label.MeasurementUnit'), _style: 'width:15%;', _classes: 'text-center', },
    { key: 'TotalFormat', label:'TOTAL', _style: 'width:15%;', _classes: 'text-center',},
    { key: 'Fecha', label:this.$t('label.date'), _classes: 'text-center', _style: 'width: 10%;' },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'min-width:90px; width:1%; text-align:center;'
    }
  ];
}

function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this, {
        text:  `${this.$t('label.changeStatusQuestion')} ${item.Nro}?`,
      })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let StowagePlanningSupplyJson = [];

      StowagePlanningSupplyJson = {
        StowagePlanningId: this.StowagePlanningId,
        SupplyId: item.SupplyId,
        UnitMeasureId: item.UnitMeasureId,
        Status: 0
      };

      this.$http.put("StowagePlanningSupplyAll-update", StowagePlanningSupplyJson, { root: 'StowagePlanningSupplyJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.StowagePlanningList();
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      });
    }
  });  
}

function modalSupplie(item) {
  if (item == true) {
    item = Object.assign({StowagePlanningId: this.StowagePlanningId, item: true });
  } else {
    item = Object.assign({StowagePlanningId: this.StowagePlanningId, item });
  }
  this.supplieItem = item;
  this.showModal = item;
}

async function StowagePlanningList () {
  this.loadingOverlay = true;
  this.items = [];
  
  let listado = Array;
  await this.$http.get("StowagePlanningSupply-list", { StowagePlanningId: this.StowagePlanningId })
  .then(response => {
    listado = [...response.data.data];
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: listado.Nro,
      StowagePlanningResourceId: listado.StowagePlanningResourceId,
      SupplyName: listado.SupplyName ? listado.SupplyName : '',
      GpoSupplyName: listado.GpoSupplyName ? listado.GpoSupplyName : '', 
      UnitMeasureName: listado.UnitMeasureName ? listado.UnitMeasureName : '', 
      ClientName: listado.ClientName ? listado.ClientName : '',
      TOTAL: listado.TOTAL ? listado.TOTAL : '',
      TotalFormat: listado.TOTAL ? formatMilDecimal(listado.TOTAL.toFixed(2)) : '',
      Usuario: listado.TransaLogin ? listado.TransaLogin : 'N/A',
      Fecha: listado.DatePlanning ? DateFormater.formatOnlyDateWithSlash(listado.DatePlanning) : 'N/A',
      Status: listado.Status ? listado.Status : '',
      GpoSupplyId: listado.GpoSupplyId ? listado.GpoSupplyId : '',
      UnitMeasureId: listado.UnitMeasureId ? listado.UnitMeasureId : '',
      SupplyId: listado.SupplyId ? listado.SupplyId : '',
      ClientTpId: listado.ClientTpId ? listado.ClientTpId : '',
      UnitMeasureAbbrev: listado.UnitMeasureAbbrev ? listado.UnitMeasureAbbrev : '',
      TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
      Status: listado.Status ? listado.Status : '',
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

export default {
  name: 'index-insumo',
  data,
  mixins: [GeneralMixin],
  components: {
    modalInsumo,
  },
  methods: {
    modalSupplie,
    StowagePlanningList,
    ConfirmarEliminado
  },  
  watch: {
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.StowagePlanningList();
        this.refrescarTabla = false;
      }
    },
    tabIndex: function (newValue) {
      if ((newValue==5) && (!["", null, undefined].includes(this.StowagePlanning))) {
        this.StowagePlanningId = this.StowagePlanning;
        this.StowagePlanningList();
      }
    },
  },
  computed: {
    fields,
    ...mapState({
        StowagePlanning: state => state.planificacionestiba.planificacionId,
        tabIndex: state => state.planificacionestiba.tabIndex,
        bulkCarrier: state => state.planificacionestiba.bulkCarrier
    })
  },
};
</script>
<style lang="scss" scope>
.center-cell {
  text-align: center;
}
 
</style>
