import { required, minLength } from "vuelidate/lib/validators";
import { onlyNumber, mayoraCero } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        StowagePlanningId: { required },
        StowageRoleId: { required },
        PayRollId: { required },
        StowageUbicationId: { required },
        Quantity: { required, onlyNumber, mayoraCero },
        ClientTpId: { required },
    }
}

