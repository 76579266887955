import { required, maxLength } from "vuelidate/lib/validators";
import { especiales, onlyMayusText } from '@/_validations/validacionEspeciales';

export default (select) => {
    return {
        RoleNameEs: { required, onlyMayusText, maxLength: maxLength(50) },
        RoleNameEn: { required, onlyMayusText, maxLength: maxLength(50) },
        patios: select ? { required } : false,
        sucursales: false,
        Dash: { required, especiales, maxLength: maxLength(500) },
        FgGeneral: { required }
    }
}

