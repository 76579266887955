<template>
    <div>
        <apexchart type="bar" height="380px" width="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    function series(){
        return [{
            data: this.seriesGrafica
        }]
    }
    function chartOptions(){
        return {
            chart: {
                type: 'bar',
                height: '380px',
            },
            xaxis: {
                type: 'category',
                labels: {
                    formatter: function(val) {
                        return val
                    }
                },
                group: {
                    style: {
                        fontSize: this.textSize,
                        fontWeight: 700
                    },
                    groups: this.groupsGrafica
                }
            },
            tooltip: {
                x: {
                    formatter: function(val) {
                        return val
                    }  
                },
                y: {
                    formatter: function(val) {
                        return val;
                    },
                    title: {
                        formatter: (seriesName, { series, seriesIndex, dataPointIndex, w }) => {
                            return this.seriesGrafica[dataPointIndex].name;
                        },
                    },
                },
            },
        }
    }
    export default {
        name:'ColumnwithGroupLabel',
        props: {
            textSize: {
                type: String,
                required: false,
                default: '13px',
            },
            seriesGrafica: {
                type: Array,
                required: true,
                default: () => [],
            },
            groupsGrafica: {
                type: Array,
                required: true,
                default: () => [],
            }
        },
        computed:{
            series,
            chartOptions
        },
    }
</script>