<template>
    <div>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="$store.state.unidadmedida.modalUnidad"
            :title="tituloModal"
        >
            <CRow>
                <CCol sm="12">
                    <div class="form-group form-row" rol="group">
                        <label class="required col-form-label col-sm-3 text-right" 
                                for="labelPuerto">{{$t('label.measurementUnitType')}}</label>
                        <div class="col-sm-9 input-group">
                            <select                                                           
                                @blur="$v.formUnidadMedida.IdTpUnidadMedida.$touch()"
                                :is-valid="$v.formUnidadMedida.IdTpUnidadMedida.$error"
                                v-model="$v.formUnidadMedida.IdTpUnidadMedida.$model" 
                                :class="InvalidSelect" id="inputGroupSelect01"
                            >
                                <option value="">{{$t('label.toSelect')}}</option>
                                <option v-for="items in filteredTipeItems" :key="items.Nro" :value="items.TpUnitMeasureId">{{ items.TpUnitMeasureName }}</option>
                            </select>
                           <div class="input-group-append" style="height:35px;">
                                <button 
                                    @click="updatedCollapseTipo()" 
                                    class="btn btn-add" 
                                    
                                    type="button"
                                    v-c-tooltip="{
                                        content: $t('label.add')+$t('label.type'),
                                        placement: 'top-end'
                                    }"
                                >
                                    +
                                </button>
                            </div>
                            <div class="invalid-feedback col-sm-12" v-if="$v.formUnidadMedida.IdTpUnidadMedida.$error">
                                <div class='text-danger' v-if="!$v.formUnidadMedida.IdTpUnidadMedida.requiredSelect">Campo requerido</div>
                            </div>
                        </div>
                        
                    </div>
                    <!--appCollapseTipoUnidadMedida /-->
                </CCol>
                <CCol sm="12">
                    <CInput
                    :label="$t('label.unitSize')"
                    addLabelClasses="required text-right"
                    v-uppercase
                     :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                    :placeholder="$t('label.registerName')"
                    @blur="$v.formUnidadMedida.NbUnidadMedida.$touch()"
                    v-model="$v.formUnidadMedida.NbUnidadMedida.$model"
                    :addInputClasses="{ 'is-invalid': $v.formUnidadMedida.NbUnidadMedida.$error }"
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formUnidadMedida.NbUnidadMedida.$error">
                                <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.required">Campo requerido</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.onlyMayusText">Solo letras mayúsculas</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.minLength">Campo requiere minimo {{$v.formUnidadMedida.NbUnidadMedida.$params.minLength.min}} caracteres</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.NbUnidadMedida.maxLength">Campo requiere maximo {{$v.formUnidadMedida.NbUnidadMedida.$params.maxLength.max}} caracteres</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CInput
                    :label="$t('label.serialNumber')"
                    addLabelClasses="required text-right"
                     :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                    v-uppercase
                    :placeholder="$t('label.EnterMeasurementUnitAcronym')"
                    @blur="$v.formUnidadMedida.SiglasUnidadMedida.$touch()"
                    v-model="$v.formUnidadMedida.SiglasUnidadMedida.$model"
                    :addInputClasses="{ 'is-invalid': $v.formUnidadMedida.SiglasUnidadMedida.$error }"
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formUnidadMedida.SiglasUnidadMedida.$error">
                                <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.required">Campo requerido</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.siglasAlphanumeric">Solo letras mayúsculas y números</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.minLength">Campo requiere minimo {{$v.formUnidadMedida.SiglasUnidadMedida.$params.minLength.min}} caracteres</div>
                                <div class='text-danger' v-if="!$v.formUnidadMedida.SiglasUnidadMedida.maxLength">Campo requiere maximo {{$v.formUnidadMedida.SiglasUnidadMedida.$params.maxLength.max}} caracteres</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                         :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"                
                        :is-valid="formUnidadMedida.FgActUnidadMedida"
                        v-if="!idIndentification"
                        v-model="formUnidadMedida.FgActUnidadMedida"
                        :value.sync="formUnidadMedida.FgActUnidadMedida"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <CElementCover 
                 :opacity="0.8"
                v-if="loading"
            >
                <label class="d-inline">{{$t('label.load')}}... </label>
                <CSpinner size="sm" />
            </CElementCover>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >                 
                    <div v-if="!apiStateLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    
                    :disabled="apiStateLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                     {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import { FormUnidadMedida } from '@/_validations/unidadmedida/UnidadValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    //import appCollapseTipoUnidadMedida from './app-collapse-tipounidad.vue';
    import general from '@/_mixins/general';
    //data
    function data() {
        return {
            formUnidadMedida:{
                IdUnidadMedida:0,
                NbUnidadMedida:'',
                SiglasUnidadMedida:'',
                FgActUnidadMedida:true,
                IdTpUnidadMedida:''
            },
            originalAct:'',
            tituloModal : this.$t('label.meaurenmentUnitRegistration'),            
            loading:false
        }
    }

    //methods
    function cambio(e){
        this.formUnidadMedida.FgActUnidadMedida = !this.FgActUnidadMedida.FgActUnidadMedida;
    }
    function cerrarModal(){
        if(this.apiStateLoading || this.loading)
            return
        this.$store.commit('unidadmedida/mutationModal', false);
    }
    function registerData(){
        this.$v.formUnidadMedida.$touch();
        if (this.$v.formUnidadMedida.$pending || this.$v.formUnidadMedida.$error) return;

        let { IdUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida,IdTpUnidadMedida } = this.formUnidadMedida;
        const { dispatch } = this.$store;
        //var source = 'https://www.google.es/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png';
        
        if(this.originalAct !== '' && IdUnidadMedida !== 0){
            if(this.originalAct !== FgActUnidadMedida){              
                    this.$swal
                        .fire(this.alertProperties({
                            text: `${this.$t('label.changeStatusQuestion')+' '} ${this.NbUnidadMedida}?`,
                        }))     
                    .then((result) => {
                    if (result.isConfirmed) {
                        dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
                    }else {
						// this.$swal('Operación cancelada');
					}
                })
            }else{
                dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
            }
        }else{
            dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
        }

        
    }
    function updatedCollapseTipo(){
        this.$store.state.unidadmedida.unitMeasureTypeId = 0;
        this.$store.state.unidadmedida.collapseTipoUnidad = true;
    }

    //computed
    function InvalidSelect(){
        return this.$v.formUnidadMedida.IdTpUnidadMedida.$error ? 'custom-select is-invalid':  'custom-select';
    }
    function idIndentification(){
        return this.formUnidadMedida.IdUnidadMedida === 0;
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function computedItems () {
        return this.$store.getters["unidadmedida/myDatatableUnitMeasureType"];
    }
    function filteredTipeItems () {
        return this.computedItems.filter(u => u.FgActTpUnitMeasure);
    }

    function selectOptions() {
    return[
      {
        value: true,
        label: this.$t('label.ACTIVO'),
      },
      {
        value: false,
        label: this.$t('label.INACTIVO'),
      },
    ]
    }

    //watch
    function modalUnidad(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.formUnidadMedida.IdUnidadMedida=0;
            this.formUnidadMedida.NbUnidadMedida='';
            this.formUnidadMedida.SiglasUnidadMedida='';
            this.formUnidadMedida.FgActUnidadMedida=true;
            this.formUnidadMedida.IdTpUnidadMedida='';
            this.$store.state.unidadmedida.collapseTipoUnidad = false;
            this.originalAct = '';
            this.tituloModal = this.$t('label.meaurenmentUnitRegistration');
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('unidadmedida/asignarid', 0);
        }else{
            this.$store.dispatch('unidadmedida/getTipoUnidadlist');
        }
    }
    function idState(newQuestion,Oldquestion){
        if(this.modalUnidad){
            if(newQuestion !== 0){
                this.$store.state.unidadmedida.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'UnitMeasure-by-id',{ UnitMeasureId: newQuestion }, '' ).then(response => {
                    if(response.status === 200){
                        try {
                            const UnidadInformation = response.data.data;
                            if(UnidadInformation.length !== 0){
                                this.tituloModal = this.$t('label.edit') + ' ' + this.$t('label.MeasurementUnit')+ ' ' +UnidadInformation[0].UnitMeasureName;
                                this.formUnidadMedida.IdUnidadMedida = UnidadInformation[0].UnitMeasureId;
                                this.formUnidadMedida.NbUnidadMedida = UnidadInformation[0].UnitMeasureName;
                                this.formUnidadMedida.SiglasUnidadMedida = UnidadInformation[0].UnitMeasureAbbrev;
                                this.formUnidadMedida.IdTpUnidadMedida = UnidadInformation[0].TpUnitMeasureId;
                                const Index = this.computedItems.map(function(e) {
                                    if(e.FgActTpUnitMeasure === true){
                                        return e.TpUnitMeasureId; 
                                    }
                                }).indexOf(UnidadInformation[0].TpUnitMeasureId);
                                if(Index === -1){
                                    this.formUnidadMedida.IdTpUnidadMedida = "";
                                }
                                this.originalAct = UnidadInformation[0].FgActUnitMeasure;
                                this.formUnidadMedida.FgActUnidadMedida = UnidadInformation[0].FgActUnitMeasure;
                                this.$v.formUnidadMedida.$touch();
                                this.$store.state.unidadmedida.apiStateForm = ENUM.INIT;
                            }else{
                                this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                this.$store.commit('unidadmedida/mutationModal', false);
                            }
                        } catch (error) {
                            this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                            this.$store.commit('unidadmedida/messageMutation', error);
                            this.$store.commit('unidadmedida/mutationModal', false);
                        }
                    }else{
                        this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                        this.$store.commit('unidadmedida/mutationModal', false);
                    }
                }).catch(err => {
                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                    this.$store.commit('unidadmedida/messageMutation', err);
                    this.$store.commit('unidadmedida/mutationModal', false);
                });
            }
        }
    }

    export default {
        name: 'app-modal-unidadmedida',
        data,
        mixins:[general],
        components: {
           // appCollapseTipoUnidadMedida
        },
        directives: UpperCase,
        validations(){ return FormUnidadMedida() },
        computed: { 
            idIndentification,
            apiStateLoading,
            apiStateFormLoading,
            computedItems,
            InvalidSelect,
            filteredTipeItems,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.unidadmedida.id,
                modalUnidad: state=> state.unidadmedida.modalUnidad,
                apiState: state => state.unidadmedida.apiState,
                apiStateForm: state => state.unidadmedida.apiStateForm
            })
        },
        methods:{
            cambio,
            updatedCollapseTipo,
            cerrarModal,
            registerData
        },
        watch:{
            modalUnidad,
            idState
        }
    }
</script>