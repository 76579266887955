<template>
    <CModalExtended
    :title="titulo"
    color="dark"
    size="xl"
    :show.sync="modalActive"
    :close-on-backdrop="false"
    >
    <loading-overlay :active="isLoading" loader="bars" />
        <CRow>
            <CCol sm="6">
                <CRow class="mb-3">
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label>{{ $t('label.delayTdrCode') }} </label>
                    </CCol>
                    <CCol sm="7">
                        <v-select 
                            :options="delayOptions"
                            :label="langOption ? 'DelayDsEn' : 'DelayDsEs'"
                            :placeholder="$t('label.select')"
                            v-model="delay"
                            :class="computedSelectDelay ? 'computed-select--correct' : (verifyDelay ? 'computed-select--correct' : '') "
                        />
                        <!--v-select 
                            v-if="!langOption"
                            :options="delayOptions"
                            label="DelayDsEs"
                            :placeholder="$t('label.select')"
                            v-model="delay"
                        /-->
                    </CCol>
                </CRow>
                <CRow class="mb-3">
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label> {{ $t('label.responsible') }} </label>
                    </CCol>
                    <CCol sm="7">
                        <v-select 
                            :options="responsibleOptions"
                            :label="langOption ? 'ResponsibleNameEn' : 'ResponsibleNameEs'"
                            :placeholder="$t('label.select')"
                            v-model="responsible"
                            :class="computedSelectResponsible ? 'computed-select--correct' : (verifyResponsible ? 'computed-select--correct' : '') "
                        />
                        <!--v-select 
                            v-if="!langOption"
                            :options="responsibleOptions"
                            label="ResponsibleNameEs"
                            :placeholder="$t('label.select')"
                            v-model="responsible"
                        /-->
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label><b class="text-danger"> * </b> {{ $t('label.name')+' (EN)' }} </label>
                    </CCol>
                    <CCol sm="7">
                        <CInput
                        addLabelClasses="required text-right"
                        v-uppercase
                        @blur="$v.DelayCustomNameEn.$touch()"
                        :placeholder="$t('label.name')+' (EN)'"
                        v-model="$v.DelayCustomNameEn.$model"
                        :is-valid="hasError($v.DelayCustomNameEn)"
                        :invalid-feedback="errorMessage($v.DelayCustomNameEn)"
                        required 
                        maxlength="250"
                        >
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label><b class="text-danger"> * </b> {{ $t('label.name')+' (ES)' }} </label>
                    </CCol>
                    <CCol sm="7">
                        <CInput
                            addLabelClasses="required text-right"
                            v-uppercase
                            @blur="$v.DelayCustomNameEs.$touch()"
                            :placeholder="$t('label.name')+' (ES)'"
                            v-model="$v.DelayCustomNameEs.$model"
                            :is-valid="hasError($v.DelayCustomNameEs)"
                            :invalid-feedback="errorMessage($v.DelayCustomNameEs)"
                            required 
                            maxlength="250"
                            >
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label><b class="text-danger"> * </b> {{ $t('label.code') }} </label>
                    </CCol>
                    <CCol sm="7">
                        <CInput
                            v-uppercase
                            @blur="$v.CustomCode.$touch()"
                            :placeholder="$t('label.code')"
                            v-model="$v.CustomCode.$model"
                            :is-valid="hasError($v.CustomCode)"
                            :invalid-feedback="errorMessage($v.CustomCode)" 
                            maxlength="3"
                        >
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="5" class="text-right mt-2 pr-1">
                        <label><b class="text-danger"> * </b> {{ $t('label.color') }} </label>
                    </CCol> 
                    <CCol sm="7">
                        <CInput
                        type="color"
                        @blur="$v.Color.$touch()"
                        :invalid-feedback="errorMessage($v.Color)"
                        :is-valid="hasError($v.Color)"
                        v-model.trim="$v.Color.$model"
                        required 
                        />
                    </CCol>
                </CRow>
                <CRow >
                    <CCol  sm="5" class="text-right mt-2 pr-1">
                        <label>{{ $t('label.status') }} </label>
                    </CCol>
                    <CCol sm="7"> 
                        <CSelect
                        :value.sync="Status"
                        :is-valid="statusSelectColor"
                        :options="statusOptions"
                        />              
                    </CCol>
                </CRow> 
            </CCol>
            
            
            
            <CCol sm="3">
                <CRow class="mb-3 justify-content-center">
                    <CCol sm="11">
                        <CCard>
                            <CCardHeader>
                                <label><b class="text-danger">*</b>{{ $t('label.apply') }}</label>
                            </CCardHeader>
                            <CCardBody>
                                <CRow class="justify-content-center">
                                    <CCol sm="12" class="px-4">
                                        <!-- :disabled="twinsBox||hatch||other" v-on:click="verifyConfig($event.target.value)"-->
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="vessel_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="1" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="((twinsBox||hatch||other||hold)&&!FgMph)||machinery"
                                            >
                                            <label for="vessel_edit" class="custom-control-label"> {{ $t('label.vessel') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4 ">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="crane_edit" type="checkbox" class="custom-control-input"
                                                v-model="applys" value="2" 
                                                v-on:click="verifyConfig($event.target.value)"
                                               :disabled="((twinsBox||hatch||other||hold)&&!FgMph)||machinery"
                                            >
                                            <label for="crane_edit" class="custom-control-label"> {{ $t('label.crane') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="twinsBox_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="3" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="(vessel||crane||hatch||other||hold||machinery)&&!twinsBox"
                                            >
                                            <label for="twinsBox_edit" class="custom-control-label"> {{ $t('label.twinsBox') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="hatch_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="4" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="(vessel||crane||twinsBox||other||onlyHold||machinery)&&!hatch"
                                                >
                                            <label for="hatch_edit" class="custom-control-label"> {{ $t('label.hatch') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4 ">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="hold_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="5" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="(vessel||crane||twinsBox||hatch||machinery||other)&&!onlyHold"
                                            >
                                            <label for="hold_edit" class="custom-control-label"> {{ $t('label.hold') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4 ">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="machinery_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="7" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="(vessel||crane||twinsBox||other||onlyHold||hatch)&&(machinery==false)"
                                            >
                                            <label for="machinery_edit" class="custom-control-label"> {{ $t('label.machinery') }} </label>
                                        
                                        </div>
                                    </CCol>
                                    <CCol sm="12" class="px-4 ">
                                        <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                                            <input 
                                                id="other_edit" type="checkbox" class="custom-control-input" 
                                                v-model="applys" value="6" 
                                                v-on:click="verifyConfig($event.target.value)"
                                                :disabled="(vessel||crane||twinsBox||hatch||hold||machinery)&&!other"
                                            >
                                            <label for="other_edit" class="custom-control-label"> {{ $t('label.others') }} </label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <p v-if="applys.length<1" class="mt-2 text-danger text-center">
                                            * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.option')}}
                                        </p>
                                    </CCol>
                                </CRow>    
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="3">
                <CRow class="mb-3 justify-content-center">
                    <CCol sm="11">
                        <CCard>
                            <CCardHeader>
                                <label><b class="text-danger">*</b>{{ $t('label.configurations') }}</label>
                            </CCardHeader>
                            <CCardBody>
                                <CRow class="justify-content-center">
                                    <CCol sm="12" class="px-3">
                                        <div class="col-sm-12">
                                            <input type="radio" name="config_vessel" value="0" 
                                                v-model="FgVesselOpt"
                                                :disabled="(applyValidVC!==1)&&(applyValidVC!==3)&&(applyValidVC!==5)"

                                            />
                                            <label class="mx-2">&nbsp;{{ $t('label.date') }}</label>
                                        </div>

                                         <div class="col-sm-12">
                                            <input type="radio" name="config_vessel" value="1" 
                                                v-model="FgVesselOpt" 
                                                :disabled="(applyValidVC!==1)&&(applyValidVC!==4)&&(applyValidVC!==5)&&(applyValidVC!==7)"
                                            />
                                            <label class="mx-2">&nbsp;{{ $t('label.dateRange') }}</label>
                                        </div>    

                                        <div role="group" class="ml-3 pt-2 checkbook-filter text-left custom-control custom-checkbox">
                                            <input id="FgApplyQuantity" type="checkbox" class="custom-control-input" 
                                                value="1" v-model="FgApplyQuantity"
                                                disabled :checked="!twinsBox"
                                            >
                                            <label for="FgApplyQuantity" class="custom-control-label"> {{ $t('label.quantity') }} </label>
                                        </div>

                                        <div role="group" class="ml-3 pt-2 checkbook-filter text-left custom-control custom-checkbox">
                                            <input id="FgApplyObservation" type="checkbox" class="custom-control-input" 
                                                value="2" v-model="FgApplyObservation"
                                                disabled :checked="!other"
                                            >
                                            <label for="FgApplyObservation" class="custom-control-label"> {{ $t('label.observation') }} </label>
                                        </div>
                                        <div role="group" class="ml-3 pt-2 checkbook-filter text-left custom-control custom-checkbox">
                                            <input id="FgMph_edit" type="checkbox" class="custom-control-input"
                                                v-model="FgMph"
                                                v-on:change="MphAffectations()"
                                                :disabled="!vessel&&!crane&&!twinsBox&&!hatch&&!hold&&!onlyHold&&!machinery&&!other"
                                            >
                                            <label for="FgMph_edit" class="custom-control-label">{{ $t('label.MphAffectation') }}</label>
                                        </div>
                                        <div role="group" class="ml-3 pt-2 checkbook-filter text-left custom-control custom-checkbox">
                                            <input id="FgCraneInoperative_edit" type="checkbox" class="custom-control-input"
                                                v-model="FgCraneInoperative"
                                                :disabled="!crane&&!machinery"
                                            >
                                            <label for="FgCraneInoperative_edit" class="custom-control-label">{{ $t('label.InoperativeCrane') }}</label>
                                        </div>
                                    </CCol>
                                </CRow>  
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>

        <template #footer>
            <CButton
                color="add"
                :disabled="isSubmit"
                class="d-flex align-items-center mr-2"
                @click.stop="statusConfirmation(oldStatus, Status, submit, DelayCustomName)"

                >
                <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
            </CButton>
            <CButton
                color="wipe"
                :disabled="isSubmit"
                class="d-flex align-items-center"
                @click.stop="toggle(false)"
                >
                <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
            </CButton>
        </template>
    </CModalExtended>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import DelayCustomCodeValidations from '@/_validations/tiempoperdido/delayCustomCodeValidations';
    import ModalMixin from '@/_mixins/modal';

    //Data
    function data() {
        return {
            applys:[],
            vessel: false, 
            crane: false, 
            twinsBox: false,
            hatch: false,
            hold: false,
            other: false,
            onlyHold: false,
            machinery: false,
            FgMph: false,
            FgCraneInoperative: false,

            FgSimultaneousOperation: false,
            FgApplyIncidenceDate: '',
            FgApplyQuantity: '',
            FgApplyDateRange: '',
            FgApplyObservation:'',
            FgVesselOpt: '',
            applyValidVC: '',

            delayId:'',
            delay:'',
            responsible:'',
            DelayCustomNameEn:'',
            DelayCustomNameEs:'',
            DelayCustomName:'',
            CustomCode:'',
            Status: '',
            Color: '',
            oldStatus: '',
            DelayCustomId: '',
            titulo: '',
            delays: [],
            responsibles: [],
            modalActive: this.modal,
            isSubmit: false,    
            editMode: false,
            isLoading: false,
            verifyDelay: false,
            verifyResponsible: false,
        }
    }
    
    //methods
    function submit() {
        try {  
        this.isLoading = true;
        this.isSubmit = true;
        let ValidateMph = !this.FgMph ? false : !this.crane&&!this.vessel ? true : false;
        this.$v.$touch();
        if (this.$v.$error||ValidateMph) {
            this.verifyDelay = true;
            this.verifyResponsible = true;
            this.isSubmit = false;
            this.isLoading = false;
            if (ValidateMph) {
                throw this.$t('label.MustIndicateVesselOrGraneToMphConfig');
            }
            throw this.$t('label.errorsPleaseCheck');
        }
        if (this.applys.length<1) {
            this.isSubmit = false;
            this.isLoading = false;
            throw this.$t('label.errorsPleaseCheck');     
        }

        if (this.FgVesselOpt == 0){
            this.FgApplyIncidenceDate=1;
            this.FgApplyDateRange=0;
        }
        if(this.FgVesselOpt == 1){
            this.FgApplyIncidenceDate=0;
            this.FgApplyDateRange=1;
        }
        
        let metodo = 'PUT';
        let ruta = 'DelayCustom-update'; 
        let DelayCustomJson =
             {
                DelayCustomId: this.DelayCustomId, 
                DelayId: (this.delay != null && this.delay != undefined && this.delay != '') ? this.delay.DelayId : null, 
                ResponsibleId: (this.responsible != null && this.responsible != undefined && this.responsible != '') ? this.responsible.ResponsibleId : null,
                DelayCustomNameEn: this.DelayCustomNameEn,                        
                DelayCustomNameEs: this.DelayCustomNameEs,                     
                CustomCode: this.CustomCode,
                Color: this.Color,                      
                Status: this.Status, 
                
                FgCrane: this.crane, 
                FgHatch: this.hatch,
                FgHold: this.hold,
                FgOther: this.other,
                FgTwinBox: this.twinsBox,
                FgVessel: this.vessel,
                FgMachine: this.machinery,

                FgApplyDateRange: this.FgApplyDateRange,
                FgApplyIncidenceDate: this.FgApplyIncidenceDate,
                FgApplyQuantity: this.FgApplyQuantity?1:0,
                FgObservation: this.FgApplyObservation?1:0,
                FgMph: this.FgMph?1:0,
                FgCraneInoperative: this.FgCraneInoperative?1:0,
            };

        this.$http.ejecutar(metodo, ruta, DelayCustomJson, { root: 'DelayCustomJson' })
        .then(response => {
            this.$emit('submited');
            this.modalActive = false;
            this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
            });
            this.resetForm();
        }).catch(err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        }).then(() => {
            this.isSubmit = false;
            this.isLoading = false;
        });
        } catch (e) {
        this.$notify({  
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error" });
        }
    }

    function MphAffectations() {
        if (this.crane&&this.vessel&&this.FgMph) {
            this.crane = false;
            this.FgCraneInoperative = false;
            this.applys=[1];
        }
        if (!this.FgMph&&this.applys.some(item => item!='1'&&item!='2')&&!this.applys.some(item => item=='7')) {
            this.vessel = false;
            this.crane = false;
            this.FgCraneInoperative = false;
            this.applys=this.applys.filter(item => item!=1&&item!=2);
        }
    }

    function toggle(newVal) {
        if(newVal){
        }else{
            this.resetForm();
        }
        this.modalActive = newVal;
    }

    function resetForm() {
        this.Color = '';
        this.DelayCustomId= '';
        this.delay = '';
        this.DelayCustomNameEn = '';
        this.DelayCustomNameEs = '';
        this.CustomCode = '';
        this.Status = 1;
        this.oldStatus = 1;
        this.editMode = false;
        this.verifyDelay = false;
        this.verifyResponsible = false;
        this.isSubmit = false;
        this.isLoading = false;
        this.responsible = '';
        this.applys = [];
        this.clearApplyConfig();
        this.$v.$reset();
    }

    function clearApplyConfig(){
        this.vessel= false; 
        this.crane= false;
        this.twinsBox= false;
        this.hatch= false;
        this.hold= false;
        this.other= false;
        this.onlyHold= false;
        this.machinery=false;
        this.FgMph = false;
        this.FgCraneInoperative = false;

        this.FgSimultaneousOperation = false;
        this.FgApplyIncidenceDate = '';
        this.FgApplyQuantity = '';
        this.FgApplyDateRange = '';
        this.FgApplyObservation = '';
        this.FgVesselOpt = '';
        this.applyValidVC= '';
    }

    function applyConfig(){
        this.FgVesselOpt = 1; //valor del radio button - 1 incidencia 2 rango de fecha
        this.FgApplyObservation=true; //selecciona el check observacion
    }

    function verifyConfig(value){   
        //buque 
        if(value==1 && this.vessel===false){
            if (this.FgMph) {
                this.crane=false;
                this.FgCraneInoperative = false;
                this.applys = this.applys.filter(item => item != 2);
                return this.vessel=true;
            }
            if ((this.twinsBox||this.hatch||this.other||this.hold||this.machinery)==false) {
                if(this.crane===false){
                    this.clearApplyConfig();
                    this.applyValidVC = 1; //habilita el radio button 
                    this.applyConfig(); 
                }
                if(this.crane===true){
                    this.clearApplyConfig();
                    this.crane=true;
                    this.applyValidVC = 1; //habilita el radio button
                    this.applyConfig(); 
                }
                return this.vessel=true;
            }
        }
        if(value==1 && this.vessel===true && (this.twinsBox||this.hatch||this.other||this.hold||this.machinery)==false){ 
            if(this.crane===false){
                this.clearApplyConfig(value);   
            }
            if(this.crane===true){
                this.clearApplyConfig(value);
                this.crane=true;
                this.applyValidVC = 1; //habilita el radio button
                this.applyConfig();
            }    
            return this.vessel=false;
        } 

        //grua 
        if(value==2 && this.crane===false){
            if (this.FgMph) {
                this.vessel=false;
                this.applys = this.applys.filter(item => item != 1);
                return this.crane=true
            }
            if ((this.twinsBox||this.hatch||this.other||this.hold||this.machinery)==false) {
                if(this.vessel===false){
                    this.clearApplyConfig(value); 
                    this.applyValidVC = 1; //habilita el radio button
                    this.applyConfig();  
                }
                if(this.vessel===true){
                    this.clearApplyConfig(value);
                    this.vessel=true;
                    this.applyValidVC = 1; //habilita el radio button
                    this.applyConfig();
                } 
                return this.crane=true;
            }
        }
        if(value==2 && this.crane===true && (this.twinsBox||this.hatch||this.other||this.hold||this.machinery)==false){ 
            if(this.vessel===false){
                this.clearApplyConfig(value);   
            }
            if(this.vessel===true){
                this.clearApplyConfig(value);
                this.vessel=true;
                this.applyValidVC = 1; //habilita el radio button
                this.applyConfig();
            } 
            return this.crane=false;
        }

        //twinsBox
        if(value==3 && this.twinsBox===false){
            this.clearApplyConfig(value);
            this.applyValidVC = 3; //habilita el rango de fechas
            this.FgApplyQuantity=true; // selecciona el check cantidad
            this.applyConfig();
            return this.twinsBox=true;
        }
        if(value==3 && this.twinsBox===true){
            this.applys = [];
            this.clearApplyConfig(value);
            return this.twinsBox=false;
        }

        //hatch
        if(value==4 && this.hatch===false){
            this.clearApplyConfig(value);
            this.applyValidVC = 4; //habilita el rango de fechas
            this.applys.push(5); //agregar el valor tapa a la lista
            this.applyConfig();
            this.hold=true;
            return this.hatch=true;
        }
        if(value==4 && this.hatch===true){
            this.clearApplyConfig(value);
            this.applys = [];
            return this.hatch=false;
        }

        //hold 
        if(value==5 && this.hold===false){
            this.clearApplyConfig(value);
            this.applyValidVC = 5; //habilita el rango de fechas
            this.applyConfig();
            this.onlyHold = true;
            return this.hold=true;
        }
        if(value==5 && this.hold===true){
            this.applys = [];
            this.clearApplyConfig(value);
            this.onlyHold = false;
            return this.hold=false;
        }

        //others
        if(value==6 && this.other===false){
            this.clearApplyConfig(value);
            this.applyValidVC = 6; //habilita el rango de fechas
            this.applyConfig();
            return this.other=true;
        }
        if(value==6 && this.other===true){
            this.applys = [];
            this.clearApplyConfig(value);
            return this.other=false;
        }
        //machinery
        if(value==7 && this.machinery===false){
            this.clearApplyConfig(value);
            this.applyValidVC = 7; //habilita el rango de fechas
            this.applys.push(5); //agregar el valor hold a la lista
            this.applys.push(2); //agregar el valor crane a la lista
            this.hold=true;
            this.crane=true;
            this.applyConfig();
            return this.machinery=true;
        }
        if(value==7 && this.machinery===true){
            this.clearApplyConfig(value);
            this.applys = [];
            return this.machinery=false;
        }
    }

    function setCustomCodeData(newCode) {
        this.isLoading = true;
        this.isSubmit = true;
        this.DelayCustomId = newCode.DelayCustomId;

        this.$http.get('DelayCustom-by-id', { DelayCustomId: this.DelayCustomId })
        .then(response => {

            let listado = response.data.data;
            let _this = this.$i18n.locale;

            this.delays = response.data.data[0].DelaysJson;
            this.responsibles = response.data.data[0].ResponsibleJson;

            this.DelayCustomNameEn = response.data.data[0].DelayCustomJson[0].DelayCustomNameEn;
            this.DelayCustomNameEs = response.data.data[0].DelayCustomJson[0].DelayCustomNameEs;
            this.CustomCode = response.data.data[0].DelayCustomJson[0].CustomCode;
            this.Color = response.data.data[0].DelayCustomJson[0].Color;
            this.Status = response.data.data[0].DelayCustomJson[0].FgActDelayCustom ? 1 : 0;
            this.oldStatus = response.data.data[0].DelayCustomJson[0].FgActDelayCustom ? 1 : 0;

            this.vessel= response.data.data[0].DelayCustomJson[0].FgVessel; 
            this.crane= response.data.data[0].DelayCustomJson[0].FgCrane; 
            this.twinsBox= response.data.data[0].DelayCustomJson[0].FgTwinBox; 
            this.hatch= response.data.data[0].DelayCustomJson[0].FgHatch; 
            this.hold= response.data.data[0].DelayCustomJson[0].FgHold;
            this.machinery= response.data.data[0].DelayCustomJson[0].FgMachine;
            this.other= response.data.data[0].DelayCustomJson[0].FgOther; 

            this.FgApplyQuantity= response.data.data[0].DelayCustomJson[0].FgApplyQuantity;
            this.FgApplyObservation= response.data.data[0].DelayCustomJson[0].FgObservation;
            this.FgMph= response.data.data[0].DelayCustomJson[0].FgMph;
            this.FgCraneInoperative= response.data.data[0].DelayCustomJson[0].FgCraneInoperative;

            this.FgApplyIncidenceDate= response.data.data[0].DelayCustomJson[0].FgApplyIncidenceDate ? 1 : 0;
            this.FgApplyDateRange= response.data.data[0].DelayCustomJson[0].FgApplyDateRange ? 1 : 0;

            this.FgVesselOpt = this.FgApplyDateRange;

            if(this.vessel===true){
                this.applys.push(1);
                this.applyValidVC = 1;
            }
            if(this.crane===true){
                this.applys.push(2);
                this.applyValidVC = 1;
            }
            if(this.twinsBox===true){
                this.applys.push(3);
                this.applyValidVC = 3;
            }
            
            if(this.hatch===true && this.hold===true){
                    this.applys.push(4);
                    this.applys.push(5);
                    this.onlyHold = false;
                    this.applyValidVC = 4;
            }
            else{
                if(this.hatch===true){
                    this.applys.push(4);
                    this.onlyHold = false;
                    this.applyValidVC = 4;
                }
                if(this.hold===true){
                    this.applys.push(5);
                    this.applyValidVC = 5; 
                    this.onlyHold = true; 
                }
            }

            if(this.machinery==true){
                this.applys.push(7);
                this.applyValidVC = 7;
            }

            if(this.other===true){
                this.applys.push(6);
                this.applyValidVC = 6;
            }

            if(response.data.data[0].DelayCustomJson[0].ResponsibleId !== null){
                this.responsible = Object.assign({}, { 
                    value: response.data.data[0].DelayCustomJson[0].ResponsibleId,
                    label: _this=='en' ? response.data.data[0].DelayCustomJson[0].ResponsibleNameEn : response.data.data[0].DelayCustomJson[0].ResponsibleNameEs,
                    ResponsibleId: response.data.data[0].DelayCustomJson[0].ResponsibleId,
                    ResponsibleNameEn: response.data.data[0].DelayCustomJson[0].ResponsibleNameEn, 
                    ResponsibleNameEs: response.data.data[0].DelayCustomJson[0].ResponsibleNameEs, 
                });
            }
            if(response.data.data[0].DelayCustomJson[0].DelayId !== null){
                this.delay = Object.assign({}, { 
                    value: response.data.data[0].DelayCustomJson[0].DelayId,
                    label: _this=='en' ? response.data.data[0].DelayCustomJson[0].DelayDsEn : response.data.data[0].DelayCustomJson[0].DelayDsEs,
                    DelayId: response.data.data[0].DelayCustomJson[0].DelayId,
                    DelayDsEn: response.data.data[0].DelayCustomJson[0].DelayDsEn,
                    DelayDsEs: response.data.data[0].DelayCustomJson[0].DelayDsEs,
                });
            }
           
            let name = _this=='en' ? response.data.data[0].DelayCustomJson[0].DelayCustomNameEn : response.data.data[0].DelayCustomJson[0].DelayCustomNameEs;
            this.titulo = `${this.$t('label.edit')} ${this.$t('label.delayCustomCode')}: ${name} `;
            this.DelayCustomName = response.data.data[0].DelayCustomJson[0].DelayCustomNameEn;

            this.isLoading = false;
            this.isSubmit = false;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.isLoading = false;
            this.isSubmit = false;
        })
        .finally(() => {
            this.isLoading = false;
            this.isSubmit = false;
        });
        this.verifyDelay = false;
        this.verifyResponsible = false;
        this.$v.$touch();
    }

    //computed
    function statusSelectColor(){
        if(this.Status){
            return this.Status === 1;
        } else {
            return false;
        }
    }

    function delayOptions() {
        let _this = this.$i18n.locale;
        return this.delays.map((item) => Object.assign({}, item, {
            value: item.DelayId,
            label: _this=='en' ? item.DelayDsEn : item.DelayDsEs,
        }));
    }

    function responsibleOptions() {
        let _this = this.$i18n.locale;
        return this.responsibles.map((item) => Object.assign({}, item, {
            value: item.ResponsibleId,
            label: _this=='en' ? item.ResponsibleNameEn : item.ResponsibleNameEs,
        }));
    }
    
    function langOption () {
        if(this.$i18n.locale==='en'){ 
            return true;
        }
        if(this.$i18n.locale==='es'){ 
            return false;
        } 
    }

    function computedSelectDelay() {
        if (this.delay === '' || this.delay === null) {
        return false;
        } else {
        return true;
        }
    }

    function computedSelectResponsible() {
        if (this.responsible === '' || this.responsible === null) {
        return false;
        } else {
        return true;
        }
    }

    export default {
        name: 'edit-custom-code',
        data,
        validations: DelayCustomCodeValidations,
        directives: UpperCase,
        mixins: [ModalMixin],
        props: {
            modal: Boolean,
            codeData: {
                type: Object,
                default: () => { }
            }
        },
        methods: {
            MphAffectations,
            submit,
            toggle,
            resetForm,
            setCustomCodeData, 
            verifyConfig,   
            clearApplyConfig,
            applyConfig,
        },
        computed: {
            delayOptions,
            responsibleOptions,
            statusSelectColor,
            langOption,
            computedSelectDelay,
            computedSelectResponsible,
        },
        watch: {
            codeData: function(newCode) {
                this.setCustomCodeData(newCode);
            }
        }
    }
</script>
<style lang="scss">
    .computed-select--correct {
        .vs__dropdown-toggle {
            border-color: #2eb85c !important;
        }
    }
</style>