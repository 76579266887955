var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-star mb-3t",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.positioningList')}`)+" ")],1)]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1)],1),_c('CTabs',{attrs:{"variant":"tabs","active-tab":_vm.tabIndex},on:{"update:activeTab":_vm.handleTab}},[_c('CTab',{attrs:{"title":_vm.$t('label.packaging')}},[_c('CRow',{staticClass:"mt-3 m-0"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"12"}},[_c('h6',{staticClass:"text-warning mr-3 mt-2"},[_c('CIcon',{staticClass:"mb-2 mr-1",attrs:{"name":"alertas","color":"wipe","size":"lg"}}),_vm._v(_vm._s(_vm.$t('label.currentPosition'))+"  ")],1),(_vm.YardId && this.dataContainers?.length == 0 && _vm.verifyContainers)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.containerPositioning'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.containerPositioning'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){_vm.showIndex=1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1):_vm._e()],1)],1),_c('CRow',{staticClass:"m-0"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"pagination":""},on:{"filtered-items-change":(List)=> this.FilterList = [...List]},scopedSlots:_vm._u([{key:"alertGif",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(item.ComputedFinishDate=='' && item.Status == 'ACTIVO')?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.editContainerPositioning'),
                      placement: 'top-start'
                    }),expression:"{\n                      content: $t('label.editContainerPositioning'),\n                      placement: 'top-start'\n                    }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleAdd(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(item.Seal1 || item.Seal2 || item.Seal3 || item.Seal4)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.Seals'),
                      placement: 'top-start'
                    }),expression:"{\n                      content: $t('label.Seals'),\n                      placement: 'top-start'\n                    }"}],staticStyle:{"background-color":"#d6d6d7"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('CIcon',{attrs:{"name":"bottomSeals"}})],1):_vm._e()],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"small":"","items":_vm.formatedSeal(item.SealJson),"fields":_vm.level2Fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage}})],1)],1)]}}])})],1)],1)],1),_c('CTab',{attrs:{"title":_vm.$t('label.Containers')}},[_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"12"}},[_c('h6',{staticClass:"text-warning mr-3 mt-2"},[_c('CIcon',{staticClass:"mb-2 mr-1",attrs:{"name":"alertas","color":"wipe","size":"lg"}}),_vm._v(_vm._s(_vm.$t('label.currentPosition'))+"  ")],1),(_vm.YardId && this.dataContainers?.length>0 && _vm.verifyContainers)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.containerPositioning'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.containerPositioning'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.toggleContainerAdd({})}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.ConatinerItems,"fields":_vm.Containerfields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"pagination":""},on:{"filtered-items-change":(List)=> this.FilterList = [...List]},scopedSlots:_vm._u([{key:"alertGif",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(item.ComputedFinishDate=='' && item.Status == 'ACTIVO')?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.editContainerPositioning'),
                      placement: 'top-start'
                    }),expression:"{\n                      content: $t('label.editContainerPositioning'),\n                      placement: 'top-start'\n                    }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleContainerAdd(item, true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.Seals'),
                      placement: 'top-start'
                    }),expression:"{\n                      content: $t('label.Seals'),\n                      placement: 'top-start'\n                    }"}],staticStyle:{"background-color":"#d6d6d7"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('CIcon',{attrs:{"name":"bottomSeals"}})],1)],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"small":"","items":_vm.formatedSeal(item.SealJson),"fields":_vm.level2Fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.SealDocumentJson.length!=0&&item.Seal)?_c('CButton',{staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.ModalImagen(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==3),expression:"showIndex==3"}],staticClass:"my-3"},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-start justify-content-start",attrs:{"sm":"12","lg":"6"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.return'),
                      placement: 'top',
                  }),expression:"{\n                      content: $t('label.return'),\n                      placement: 'top',\n                  }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":()=> {_vm.showIndex=0}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('CRow',{staticClass:"align-items-center justify-content-start w-100 m-0"},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.ContainersInInventory')}`)+" ")],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedContainerInventoryItems,"fields":_vm.fieldsContainerInventory,"noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"number",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                  content: _vm.$t('label.dispatchContainer'),
                                  placement: 'top-start'
                              }),expression:"{\n                                  content: $t('label.dispatchContainer'),\n                                  placement: 'top-start'\n                              }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleContainer(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1)],1)]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('PositioningRegister',{attrs:{"Active":_vm.showIndex==1,"isEdit":_vm.isEdit,"PositionItem":_vm.PositionItem},on:{"Close":function($event){(_vm.showIndex=0, _vm.PositionItem={}, _vm.isEdit=false)},"Update-list":_vm.UpdateData}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==2),expression:"showIndex==2"}]},[_c('ContainerPositioningRegister',{attrs:{"Active":_vm.showIndex==2,"isEdit":_vm.isEdit,"isOfBlProfile":true,"PositionItem":_vm.PositionItem},on:{"Close":function($event){(_vm.showIndex=0, _vm.PositionItem={}, _vm.isEdit=false)},"Update-list":_vm.UpdateData}})],1),_c('ModalSealImages',{attrs:{"modal":_vm.ModalSeal,"labelModal":_vm.labelModalImages,"SealImages":_vm.SealImages},on:{"Close":function($event){(_vm.ModalSeal=false, _vm.labelModalImages='', _vm.SealImages=[])}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }