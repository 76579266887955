<template>
  <li 
    v-if="distributed.header"
    @click="navClick"
    :class="{ active: isActive }"
  >
    <a
      href="#"
      @click.prevent="navLinkClick"
      :class="navLinkClasses"
      :disabled="disabled"
    >
      <div class="center-all">
        <slot name="title">
          <CIcon v-if="iconSrc==''" :name="iconTitle"/>
          <CIcon v-else :src="iconSrc"/>
          <span class="ml-1">{{title}}</span>
        </slot>
      </div>
    </a>
  </li>
  <transition
    v-else
    :name="tabs.scale ? 'scale' : ''"
  >
    <div
      v-show="isActive"
      :class="paneClasses"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomTab',
  inject: ['distributed', 'tabs', 'initialTab'],
  beforeMount () {
    this.index = Array.from(this.$parent.$children).indexOf(this)
    if (this.active && this.distributed.header && this.initialTab === undefined) {
      this.distributed.changeTabTo(this.index)
    }
  },
  data () {
    return {
      index: null
    }
  },
  props: {
    title: String,
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconSrc: {
      type: String,
      required: false,
      default: '',
    },
    disabled: Boolean,
    valueItemTab: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    iconTitle(){
      if(this.icon === '' || this.icon === null || this.icon === undefined){
        return 'cil-globe-alt';
      }
      else{
        return this.icon;
      }
    },
    navLinkClasses () {
      return {
        'custom-tab-link': true,
        'disable-link': this.disabled
      }
    },
    paneClasses () {
      return [
        this.distributed.addClasses, 
        'tab-pane',
        { 'active': this.isActive }
      ]
    },
    isActive () {
      if(this.valueItemTab != '')
        return this.tabs.activeTab === Number(this.valueItemTab)
      else
        return this.tabs.activeTab === this.index
    }
  },
  methods: {
    navClick () {
      if (!this.isActive && !this.disabled) {
        if(this.valueItemTab != '')
          this.distributed.changeTabTo(Number(this.valueItemTab))
        else
          this.distributed.changeTabTo(this.index)
      }
    },
    navLinkClick() {
      return;
    }
  }
}
</script>

<style scoped>
.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-all img{
  height: 1rem;
  width: 1rem;
  filter: invert(100%);
}
.active .center-all img{
  filter: brightness(0);
}
a.disable-link:hover {
  cursor: not-allowed;
  opacity: 0.8;
}

.scale-enter-active {
  animation: scale-in .5s;
}
.scale-leave-active {
  animation: scale-out .5s;
}
@keyframes scale-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}
</style>