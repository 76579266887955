
<script>
import { Chart, registerables } from 'chart.js';
import { HierarchicalScale } from 'chartjs-plugin-hierarchical';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(HierarchicalScale);
Chart.register(ChartDataLabels);
export default {
    name: '',
    type: '',
    props: {
        width:{
            type: String,
            required: true,
            default: '430',
        },
        bandera:{
            type: Boolean,
            required: true,
            default: false
        },
        height:{
            type: String,
            required: false,
            default: '100%',
        },
        labels: {
            type: Array,
            required: true,
            default: () => [],
        },
        yaxis: {
            type: Object,
            required: true,
            default: () => {},
        },
        series: {
            type: Array,
            required: true,
            default: [],
        },
        positionLegend:{
            type: String,
            required : false,
            default: () => "bottom"
        },
        typeGraffic:{
            type: String,
            required : false,
            default: () => "bar"
        },
        legendBandera:{
            type: Boolean,
            required : false,
            default: () => false
        },
        fontSize:{
            type: String,
            required : false,
            default: () => "11px"
        },
        displayDataLabels:{
            type: Boolean,
            required : false,
            default: () => true
        }
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    data () {
        return {
            chart: undefined
        }
    },
    watch: {
        chartConfig () {
            if(this.bandera){
                this.updateChart()
            }
        },
        bandera(newValue,OldValue){
            if(newValue){
                this.renderChart()
            }else{
                this.destroyChart()
            }
        }
    },
    beforeDestroy () {
        this.destroyChart()
    },
    computed: {
        safeId () {
            // as long as this._uid() works there is no need to generate the key
            const key = () => Math.random().toString(36).replace('0.', '')
            return '__safe_id__chart_donus' + (this._uid || key())
        },
        computedHeight() {
            return this.height ? this.height : '300'
        },
        computedtype () {
            return this.typeGraffic
        },
        computedDatasets () {
            return this.series;
        },
        computedLabels () {
            if (this.labels && typeof this.labels !== 'string') {
                return this.labels
            } else if (!this.datasets || !this.datasets[0] || !this.datasets[0].data) {
                return []
            }
            const emptyLabels = Array(this.datasets[0].data.length).fill('')
            if (this.labels === 'indexes') {
                return emptyLabels.map((u, i) => i + 1)
            } else if (this.labels === 'months') {
                return emptyLabels.map((u, i) => this.$options.months[i % 12])
            }
            return emptyLabels
        },
        computedData () {
            return {
                datasets: this.computedDatasets,
                labels: this.computedLabels
            }
        },
        computedOptions () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: this.yaxis
                },
                plugins: {
                    legend: {
                        display: this.legendBandera,
                        position: this.positionLegend,
                        align: 'start',
                        fullSize: true,
                        maxWidth: 850,
                        labels: {
                            generateLabels: function(chart) {
                                var data = chart.data;
                                if (data.datasets.length) {
                                    return data.datasets.map(function(item,index) {
                                        var value = 0;
                                        item.data.map(function(item2){
                                            value = value + item2;
                                        })
                                        var fill = item.backgroundColor;
                                        if(item.delayhours !== null || item.delayhours !== undefined){
                                            return {
                                                text: item.label + " : " + item.delayhours,
                                                fillStyle: fill,
                                                index: index
                                            }
                                        }
                                        return {
                                            text: item.label + " : " + parseFloat(value).toFixed(2),
                                            fillStyle: fill,
                                            index: index
                                        }
                                    })
                                } else {
                                    return [];
                                }
                            },
                            font: {
                                size: this.fontSize
                            }
                        }
                    },
                    datalabels: {
                        color: '#fff',
                        display: this.displayDataLabels,
                        formatter: function(value, ctx) {
                            if(value === 0 || value === "0" || value === ""){
                                return ""
                            }else if(value === "0.5" || value === 0.5){
                                return 0;
                            }else{
                                return value;
                            }
                        }
                    },
                }
            }
        },
        chartConfig () {
            return {
                type: this.computedtype,
                data: this.computedData,
                options: this.computedOptions,
            }
        }
    },
    methods: {
        renderChart () {
            this.destroyChart()
            this.chart = new Chart(
                this.$refs.canvas.getContext('2d'),
                this.chartConfig
            )
        },
        updateChart () {
            if (this.chart) {
                Object.assign(this.chart, this.chartConfig)
                this.chart.update()
            }
        },
        destroyChart () {
            if (this.chart) {
                this.chart.destroy()
            }
        }
    },
    render(h) {
        return h(
            'div',
            [
                h(
                    'canvas', {
                        attrs: {
                            id: this.safeId,
                        },
                        style: {
                            height: this.height
                        },
                        ref: 'canvas',
                    }
                )
            ]
        )
    }
}
</script>