<template>
  <div>
		<loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
      	<CButton
          color="add"
          @click="(isEdit=false, ModalActive=true)"
          v-c-tooltip="{
            content: $t('label.Event'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;{{$t('label.nuevo')}}
        </CButton>
      </CCol>
			<CCol sm="12" lg="4">
				<CSelect
            :options="EventGpoOptions"
            v-model="EventGpoId"
            :label="$t('label.classification')"
            :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right"
            @change="getEventList()"
          />
			</CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedList"
          :fields="fields"
          column-filter
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          pagination
          sorter
          :tableFilter="tableText.tableFilterText"
          :noItemsView="tableText.noItemsViewText"
        >
          <template #Status="{ item }">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton
                size="sm"
                color="edit"
                @click="EditEvent(item)"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.Event')}`,
                  placement: 'top-end'
                }"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
		<ModalEvent
			:modal="ModalActive"
			:isEdit="isEdit"
			:EventItem="EventItem"
			@Close-Modal="(ModalActive=false, EventItem={})"
			@List-Update="getEventList()"
		/>
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalEvent from "./modal-event";

function data() {
  return {
		EventGpoId: '',
		EventGpoList: [],
    EventList: [],
		EventItem: {},
		ModalActive: false,
		isEdit: false,
    loading: false,
  };
}

//methods
function getEventGpoList() {
  this.loading = true;
  this.$http.get('EventGpo-list')
    .then((response) => {
      let List = response.data.data
      if (List&&List.length!=0) {
        this.EventGpoList = List;
      }
      this.getEventList();
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}

function getEventList() {
  this.loading = true;
  this.$http.get('Event-list', { EventGpoId: this.EventGpoId })
    .then((response) => {
      let List = response.data.data
      if (List&&List.length!=0) {
        this.EventList = List;
      }else{
        this.EventList = [];
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}

function EditEvent(item) {
	this.isEdit = true;
	this.EventItem = item;
	this.ModalActive = true;
}

//computed
function EventGpoOptions(){
  if(this.EventGpoList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.EventGpoList.map(function(e){
      chart.push({
        value: e.EventGpoId, 
        label: _lang=='en' ? e.EventGpoNameEn : e.EventGpoNameEs,
      })
    })
    return chart;
  }
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.classification'), key: 'EventGpoName', _classes: 'text-center', _style: 'width:12%;',},
		{ label: `${this.$t('label.Event')} (ES)`, key: 'EventNameEn', _classes: 'text-center', _style: 'width:26%;',},
    { label: `${this.$t('label.Event')} (EN)`, key: 'EventNameEs', _classes: 'text-center', _style: 'width:26%;',},
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:10%' },
    { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:10%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-center', _style:'width:10%' },
    { label: '', key: 'options', _style: 'min-width:45px;', sorter: false, filter: false },
  ];
}

function computedList() {
  let _lang = this.$i18n.locale
  return this.EventList.map((item) => {
    return {
      ...item,
      EventGpoName: _lang=='en' ? item.EventGpoNameEn : item.EventGpoNameEs,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

export default {
  name: 'event-index',
	props: {
		Tab: {
			type: Number,
			default: 0,
		}
	},
  components: { 
		ModalEvent,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
		EditEvent,
    getEventList,
    getEventGpoList,
  },
  computed: {
		EventGpoOptions,
    fields,
    computedList,
  },
	watch: {
		Tab: function (val) {
			if (val==1) {
        this.getEventGpoList();
			}
		}
	}
};
</script>