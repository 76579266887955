<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.userList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center"
                @click="toggleAdd"
                v-c-tooltip="{
                  content: $t('label.user'),
                  placement: 'top-end'
                }"
               
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
     class="align-center-row-datatable"
                hover
                sorter
                small
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td>
                    {{ item.RowNumber }}
                  </td>
                </template>

                <template #StatusText="{item}">
                  <td class="text-center">
                    <CBadge :color="getBadge(item.StatusText)">
                      {{item.StatusText}}
                    </CBadge>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="py-2">
                      <CButton
                        color="edit"
                        square
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.user'),
                          placement: 'top-end'
                        }"
                        @click="toggleEdit(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                      <CButton
                        color="watch"
                        square
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.toView')+' '+'ROLES',
                          placement: 'top-end'
                        }"
                        @click="toggleRoles(item, index)"
                      >
                        <CIcon name="eye" />
                      </CButton>
                      <CButton
                        color="dark"
                        shape="square"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.transactions')+' '+$t('label.user'),
                          placement: 'top-end'
                        }"
                        @click="toggleTransac(item)"
                      >
                        <CIcon name="cil-chevron-circle-up-alt" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <add-modal :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-modal :modal.sync="modalEdit" :user-data="user" @submited="handleSubmit"/>
    <roles-modal :modal.sync="modalRoles" :user-data="rolesUser"/>
    <transacciones-modal 
      :modal.sync="modalTransacciones" 
      :transaccionesUser="transaccionesUser" 
      :transaccionesUserId="transaccionesUserId"/>
  </CRow>
</template>

<script>
import GeneralMixin from '@/_mixins/general';

import addModal from './add-modal';
import EditModal from './edit-modal';
import RolesModal from './roles-modal';
import TransaccionesModal from './transacciones-modal';
import { DateFormater } from '@/_helpers/funciones';


//Funcion data() del ciclo.
function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%;',
      filter: false,
      _classes:"center-cell text-center"
    },
    { key: 'Login', label:'LOGIN', _style: "width: 15%;",_classes:"center-cell text-center"},
    { key: 'UserCi', label: this.$t('label.userId'), _style: "width: 15%;",_classes:"center-cell text-center"},
    { key: 'FullName', label: this.$t('label.lastName')+', '+this.$t('label.name'), _style: "width: 30%;",_classes:"center-cell text-center" },
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: "width: 20%;",_classes:"center-cell text-center" },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: "width: 5%;", _classes:"center-cell text-center" },
    { key: 'StatusText', label: this.$t('label.status'), _style: "width: 5%;", _classes:"center-cell text-center" },
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:1%; min-width: 135px;', 
      sorter: false, 
      filter: false,
      _classes:"center-cell text-center"
    }
  ];
}
function data() {
  return {
    items: [],
    user: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    modalRoles: false,
    modalTransacciones: false,
    rolesUser: {},
    transaccionesUser: {},
    selected: {},
    transaccionesUserId:'',
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getUsers();
}

//*****Funcines de watch:
function handlerRoute() {
  return {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    };
}

//*****Funciones de methods:
function getUsers() {
  this.isLoading = true;

  this.$http.ejecutar('GET', 'User-list', { Filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function pageChange(val) {
  this.$router.push({ query: { page: val }});
}
function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item, index) {
  this.user = Object.assign({}, item);
  this.modalEdit = true;
}
function toggleRoles(item, index) {
  this.rolesUser = Object.assign({}, item);

  this.modalRoles = true;
}
function toggleTransac(item) {
  this.transaccionesUser = item;
  this.transaccionesUserId = item.UserId;
  this.modalTransacciones = true;
}
function handleSubmit(){
  this.getUsers();
}

//*****Funcones de computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    FullName: `${item.UserName}, ${item.UserLastName}`,
    UltimoUsuario: item.TransaLogin,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    StatusText: item.Status,
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'users',
  mixins: [GeneralMixin],
  components: { addModal,EditModal, RolesModal, TransaccionesModal },
  data,
  created,
  watch: {
    $route: handlerRoute
  },
  methods: {
    getUsers,
    pageChange,
    toggleAdd,
    toggleEdit,
    toggleRoles,
    toggleTransac,
    //closetransModal,
    handleSubmit
  },
  computed: {
    fields,
    formatedItems
  }
}
</script>
