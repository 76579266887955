<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="titulo"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <transition name="slide-fade" mode="out-in">
        <CRow v-if="!showCollapseVesselTtype">
          <CCol sm="12">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol sm="2" class="text-right mt-2 pr-1">
                    <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.role') }} </label>
                  </CCol>
                  <CCol sm="6" class="pr-0">
                    <v-select 
                      :options="rolesOptions"
                      label="StowageRoleName"
                      :placeholder="$t('label.select')"
                      class="select-adjust"
                      v-model="role"
                      :disabled="isRolesEmpty"
                    />
                  </CCol>
                  <CCol sm="4" class="px-0"> 
                    <CButton
                      @click="showCollapseRole = !showCollapseRole"
                      size="sm"
                      :color="checkColorCollapse"
                      v-c-tooltip="{
                        content: $t('label.add')+$t('label.role'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon :name="checkIconCollapse"/>
                    </CButton>
                    <CButton
                      color="add"
                      size="sm"
                      class="ml-4"
                      @click.prevent="newVesselType"
                      v-c-tooltip="{
                        content: $t('label.vesselType'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-playlist-add"/><span class="ml-1">
                        {{$t('label.nuevo') }}
                      </span>
                    </CButton>
                  </CCol>
                  <CCol sm="2" class="text-right mt-2 pr-1">
                    <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.status') }} </label>
                  </CCol>
                  <CCol sm="6" class="pr-0">
                    <CSelect
                      :value.sync="roleEstibaBuque.Status"
                      :is-valid="statusSelectColor"
                      size="sm"
                      addLabelClasses="text-right"
                      :options="statusOptions"
                      class="mt-1"
                    />
                  </CCol>
                  <CCol sm="4">
                  </CCol>
                  <CCol sm="12">
                    <CCollapse :show="showCollapseRole">
                      <role-collapse
                        :Edit="roleEdit"
                        :stowage-role="role"
                        @close="handleClose"
                        :LimpiarCollapse.sync="LimpiarCollapse"
                      />
                    </CCollapse>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              size="sm"
              :fields="fields"
              :items="tpBuquesOptions"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              :loading="isLoading"
               
            >
              <template #TpVesselName="{ item }">
                <td class="center-cell text-left">
                  {{ item.TpVesselName }}
                </td>
              </template>
              <template #checked="{ item }">
                <td class="center-cell">
                  <CInputCheckbox
                    custom
                    class="checkbook-filter"
                    :checked="item.initialActive"
                    @update:checked="handleTpBuque($event, item)"
                  />
                </td>
              </template>
              <template #checked-filter>
                <CInputCheckbox
                  :label="$t('label.all')"
                  class="checkbook-filter"
                  custom
                  @update:checked="selectAllTpVessel($event)"
                  :checked="selectAll"
                />
              </template>
              <template #Details="{item}">
                <td class="center-cell  py-2">
                  <CButton
                    color="edit"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.edit')+$t('label.vesselType'),
                      placement: 'top-end'
                    }"
                    @click.stop="editTpBuque(item)"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                </td>
              </template>
            </dataTableExtended>
            <!--p v-if="vesselTypeCheckedList.length == 0" class="mt-2 text-danger">
              * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.vesselType')}}
            </p-->
          </CCol>
        </CRow>
        <tp-buque-form
          v-else
          :tp-vessel-id="tpBuqueId"
          @submited="handleTpBuqueSubmit"
          @cancel="handleCancel"
        />
      </transition>     
    </form>
    <template #footer>
      <CButton
        v-if="!showCollapseVesselTtype"
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="statusConfirmation(roleEstibaData.FgActStowageRoleVessel, roleEstibaBuque.Status, submit)"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      <!--
        <div v-if="!isSubmit">
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </div>
        <div v-if="isSubmit">
          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </div>-->
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import { RoleFormEditValidations } from '@/_validations/estiba/RoleFormValidations';
import { tableTextTranslatedHelpers } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import { RoleMixin, RoleSingleCollapseMixin } from '@/_mixins/estiba';
import ModalMixin from '@/_mixins/modal';

import TpBuqueForm from '@/pages/tipo-buque/tipo-buque-form';
import RoleCollapse from '@/pages/estiba/roles/role-collapse';



//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoading: false,
    isLoadingRoles: false,
    role: null, 
    itemsVesselTypeList: [], 
    rolesList: [], 
    vesselTypeCheckedList: [], 
    vesselTypeCheckedUpdatedList: [], 
    vesselTypeStowageRoleCheckedUpdatedList: [], 
    isEdit: false,
    tpBuqueId: '',
    titulo: '',
    showCollapseVesselTtype: false, 
                                    
    showCollapseRole: false, 
    selectAll: false, 
    resetSelectAll: false, 
    Status: null, 
    LimpiarCollapse: false, 
    roleVal: false, 

    roleEstibaBuque: {  
      StowageRoleVesselId: '',
      StowageRoleId: '',
      TpVesselId: '',
      Status: 1,
    },

  }
}

//Methods
function submit() {
  //this.isSubmit = true;
  try {
    this.isSubmit = true;
    this.isLoadingGeneral = true;
   // this.roleVal = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let StowageRoleVesselJson = this.vesselTypeCheckedUpdatedList.map((item) => Object.assign({},{
      StowageRoleId: this.role.StowageRoleId,
      TpVesselId: item.TpVesselId,
      Status: item.Status,
      StowageRoleVesselId: item.StowageRoleVesselId
    }));

    this.$http.put("StowageRoleVessel-update", StowageRoleVesselJson, { root: 'StowageRoleVesselJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {

      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.error')+'!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
    });
  } catch (e) {

    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function toggle(newVal) {
  if(newVal)
    this.loadData(true);
  else
    {
    this.resetForm();
    this.$emit('submited');
    }

  this.modalActive = newVal;
}
function resetForm() {
  this.role = null;
  this.rolesList = [];
  this.vesselTypeCheckedList = null;
  this.itemsVesselTypeList = [];
  this.titulo = '';
  this.showCollapseRole = false;
  this.roleEdit = false;
  this.tpBuqueId = '';
  this.showCollapseVesselTtype = false;
  this.roleEstibaBuque.StowageRoleVesselId = '';
  this.roleEstibaBuque.StowageRoleId = '';
  this.roleEstibaBuque.TpVesselId = '';
  this.roleEstibaBuque.Status = 1;
  this.limpiarCollapse = false;
  this.vesselTypeCheckedUpdatedList= [];
  this.vesselTypeStowageRoleCheckedUpdatedList= [];
  this.isEdit= false;
  this.selectAll= false; 
  this.resetSelectAll= false; 
  this.Status= null;
  this.roleVal= false;
  this.$v.$reset();
}
function getTpBuques() {
  this.isLoading = true;

  this.$http.get("TpVessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.itemsVesselTypeList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.error')+'!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function selectAllTpVessel(event) {
 this.vesselTypeCheckedList = [];
  this.vesselTypeCheckedListUpdated = [];
  if (event === true) {
    this.selectAll = true;
    this.resetSelectAll = false;
  } else {
    this.selectAll = false;
    this.resetSelectAll = true;
  }
}
async function handleTpBuque(event, item) {
  const vesselTypeStowageRole =  this.vesselTypeStowageRoleCheckedUpdatedList.filter((element) => element.TpVesselId === item.TpVesselId);

  this.selectAll = false;
  this.resetSelectAll = false;
  const vesselTypeCheckedTemporal = {
    StowageRoleId: this.role.StowageRoleId,
    TpVesselId: item.TpVesselId,
  };
  const vesselTypeCheckedTemporalUpdated = {
    StowageRoleId: this.role.StowageRoleId,
    TpVesselId: item.TpVesselId,
    Status: this.Status,
    StowageRoleVesselId: vesselTypeStowageRole.length > 0 ? vesselTypeStowageRole[0].StowageRoleVesselId : ' '
  };

  if (event === true) {
    this.vesselTypeCheckedList.push(vesselTypeCheckedTemporal);
    if(this.isEdit){
      const j = await this.arrayObjectIndexOf(this.vesselTypeCheckedUpdatedList, item.TpVesselId, 'TpVesselId' )
      if (j !== -1) {
        this.vesselTypeCheckedUpdatedList[j].Status = 1;
      }else {
        this.vesselTypeCheckedUpdatedList.push(vesselTypeCheckedTemporalUpdated);
      }
    }
  } if (event === false) {

    const i = await this.vesselTypeCheckedList.findIndex((element) => element.TpVesselId === item.TpVesselId);
    const j = await this.vesselTypeCheckedUpdatedList.findIndex((element) => element.TpVesselId === item.TpVesselId);

    if (i !== -1) {
      this.vesselTypeCheckedList.splice(i, 1);
    }

    if(this.isEdit){
      if (j !== -1) {       
        this.vesselTypeCheckedUpdatedList[j].Status = 0;
      }
    }
  }
}
function setRoleEstibaData(newRole) {
  this.roleEstibaBuque.StowageRoleVesselId = newRole.StowageRoleVesselId;
  this.roleEstibaBuque.StowageRoleId = newRole.StowageRoleId;
  this.roleEstibaBuque.TpVesselId = newRole.TpVesselId;
  this.titulo = this.$t('label.edit')+' '+this.$t('label.stowageRole')+': '+newRole.StowageRoleName;

  this.roleEstibaBuque.Status = newRole.FgActStowageRoleVessel ? 1 : 0;
 // this.titulo = `ROL ESTIBA `;
  this.role = Object.assign({}, { //id de la linea
    value: newRole.StowageRoleId,
    label: newRole.StowageRoleName,
    StowageRoleId: newRole.StowageRoleId,
    StowageRoleName: newRole.StowageRoleName,
  });
  this.$v.$touch();
}

function newVesselType() {
  this.tpBuqueId = '';
  this.showCollapseVesselTtype = true;
}
function editTpBuque(item) {
  this.tpBuqueId = item.TpVesselId;
  this.showCollapseVesselTtype = true;
}
function handleTpBuqueSubmit() {
  this.showCollapseVesselTtype = false;
  this.getTpBuques();
}
function handleCancel() {
  this.showCollapseVesselTtype = false;
  this.tpBuqueId = '';
}
function getRoles() {
  this.isLoadingRoles = true;

  this.$http.get("StowageRole-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.rolesList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingRoles = false;
  });
}

//Computeds:
function tpBuquesOptions() {
    if (this.itemsVesselTypeList.length > 0) {
    return this.itemsVesselTypeList.map((item) => {
      if (!this.selectAll && !this.resetSelectAll) {
        const itemVesselType = this.vesselTypeCheckedList.filter(
          (element) => item.TpVesselId === element.TpVesselId
        );
        return this.formatedDateItem(item, itemVesselType.length > 0);
      }
      if (this.selectAll && !this.resetSelectAll) {
        const temporalItem = {
          StowageRoleId: this.role.StowageRoleId,
          TpVesselId: item.TpVesselId,
        };
        const VesselTypeStowageRole =  this.vesselTypeStowageRoleCheckedUpdatedList.filter((element) => element.TpVesselId === item.TpVesselId);
        const temporalItemUpdated = {
          StowageRoleId: this.role.StowageRoleId,
          TpVesselId: item.TpVesselId,
          Status: this.Status,
          StowageRoleVesselId: VesselTypeStowageRole.length > 0 ? VesselTypeStowageRole.StowageRoleVesselId : ''
        }
        this.vesselTypeCheckedList.push(temporalItem);
        this.vesselTypeCheckedUpdatedList.push(temporalItemUpdated)

        return this.formatedDateItem(item, true);
      }
      if (!this.selectAll && this.resetSelectAll) {
        return this.formatedDateItem(item, false);
      }
    });
  }
}
function rolesOptions() {
  return this.rolesList.map((item) => Object.assign({}, item, {
    value: item.StowageRoleId,
    label: item.StowageRoleName
  }));
}

function statusSelectColor(){
  return this.roleEstibaBuque.Status === 1;
}

function fields() { 
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false, _style: 'width:1%; text-align:center;'
    },
    { key: 'TpVesselName', label:this.$t('label.vesselType'), _style: 'width:60%', _classes:"text-left" },
    { 
      key: 'checked', 
      label: this.$t('agridTable.applyFilter'), 
      _style: 'width:30%', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    },
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}

function tableText(){
  return tableTextTranslatedHelpers(this);
}

function checkIconCollapse() {
  return this.showCollapseRole ? 'cil-minus' : 'cil-plus';
}

function checkColorCollapse(){
  return this.showCollapseRole ? "wipe" : "add" ;
}



//### Funcion para mostrar los tipos de buques seleccionados por rol
async function getStowageRoleVesselList(itemRoleVessel) {
  this.loadingOverlay = true;
  this.vesselTypeCheckedList = [];
  this.vesselTypeCheckedUpdatedList = [];
  await this.$http
    .get(`StowageRoleVessel-list?StowageRoleId=${itemRoleVessel.StowageRoleId}&TpVesselId=`)
    .then((response) => {
      if (response.data.data.length > 0) {
        this.vesselTypeStowageRoleCheckedUpdatedList = response.data.data;
        response.data.data.map((item) => {
          if (item.Status === 'ACTIVO') {
            this.Status = 1
            const temporalItem = {
              StowageRoleId: this.role.StowageRoleId,
              TpVesselId: item.TpVesselId,
            };
            const temporalItemUpdated = {
              StowageRoleId: this.role.StowageRoleId,
              TpVesselId: item.TpVesselId,
              Status: this.Status,
              StowageRoleVesselId: item.StowageRoleVesselId
            };

            this.vesselTypeCheckedList.push(temporalItem);
            this.vesselTypeCheckedUpdatedList.push(temporalItemUpdated)

          }else{
            if(this.Status === null){
              this.Status = 0
            }
            if(item.StowageRoleVesselId !== null && item.StowageRoleVesselId !== ''){
              const temporalItemUpdated = {
                StowageRoleId: this.role.StowageRoleId,
                TpVesselId: item.TpVesselId,
                Status: 0,
                StowageRoleVesselId: item.StowageRoleVesselId
              };
              this.vesselTypeCheckedUpdatedList.push(temporalItemUpdated)
            }
          }
        });
      }
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}



export default {
  name: 'modal-edit-role-stowage',
  mixins: [
    RoleMixin,
    RoleSingleCollapseMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean,
    roleEstibaData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    TpBuqueForm,
    RoleCollapse,
  },
  data,
  validations: RoleFormEditValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getTpBuques,
    handleTpBuque,
    setRoleEstibaData,
    newVesselType,
    handleTpBuqueSubmit,
    handleCancel,
    editTpBuque,
    getRoles,
    selectAllTpVessel,
    getStowageRoleVesselList,
  },
  computed: {
    tpBuquesOptions,
    rolesOptions,
    statusSelectColor,
    fields,
    tableText,
    checkIconCollapse,
    checkColorCollapse
  },
  watch: {
    roleEstibaData: function(newRole) {
      this.isEdit = true;
      this.setRoleEstibaData(newRole);
      this.getStowageRoleVesselList(newRole);
    },
    showCollapseRole: function() {
      if (this.showCollapseRole){
        this.LimpiarCollapse=false;
      }else{
        this.LimpiarCollapse=true;
        this.getRoles();
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.center-cell-embalaje {
  text-align: center;
}
.invalido {
  display: block;
}
/*
td {
  vertical-align: middle !important;
}
*/
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.center-cell {
text-align: center;
}
 
</style>