import {
  required,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators';

const alphaNumCustom = (value) => /^[\w\u00f1\u00d1 \-]+$/g.test(value);

export default () => {
  return {
    modalityInfo: {
      ModalityId: {},
      ModalityNameEs: { maxLength: maxLength(100), minLength: minLength(3), required, alphaNumCustom },
      ModalityNameEn: { maxLength: maxLength(100), minLength: minLength(3), required, alphaNumCustom },
      ConditionPlannigId: { required },
      Status: {},
    },
  }
}