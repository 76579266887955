
<template>
    <CCollapse :show="collapseTypeUnitMeasure">
        <CCard>
            <CCardHeader class="text-center bg-dark text-white">
                <CRow>
                    <CCol sm="10" class="d-flex ">{{$t('label.nuevo')+$t('label.measurementUnitType')}}</CCol>
                    <CCol sm="2" class="d-flex justify-content-end">
                        <button type="button" aria-label="Close" class="close" @click="closeCollapse()">×</button>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody>
                <CCol sm="12">
                    <CRow>
                        <CCol sm="11" lg="9" xl="10">
                            <CInput
                            :label="$t('label.name')"
                            addLabelClasses="required text-right"
                            :horizontal="{label:'col-sm-9 col-lg-3', input:'col-sm-11 col-lg-9'}"                            
                            v-uppercase
                            :placeholder="$t('label.measurementUnitTypeName')"
                            v-model="$v.formTpUnidadMedida.NbTpUnidadMedida.$model"
                            :is-valid="hasError($v.formTpUnidadMedida.NbTpUnidadMedida)" 
                            :invalid-feedback="errorMessage($v.formTpUnidadMedida.NbTpUnidadMedida)"
                            required 
                            >
                            </CInput>
                        </CCol>
                        <CCol sm="11" lg="3" xl="2">
                                <CButton
                                    square
                                    color="add"
                                    size="sm"
                                    v-c-tooltip="{
                                        content: $t('label.add'),
                                        placement: 'top-end'
                                    }"
                                    @click="updatedItem()"
                                    >
                                        <CIcon name="checkAlt"/>
                                </CButton>
                        </CCol>
                    </CRow>
                </CCol>
                <!--CCollapse :show="collapseTableTipoUnidad" class="mt-2">
                </CCollapse-->                
            </CCardBody>
        </CCard>
    </CCollapse>
</template>

<script>
    import ENUM from '@/_store/enum';
    import GeneralMixin from '@/_mixins/general';
    import modalMixin from '@/_mixins/modal';
    import { mapState,mapMutations } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { FormTpUnidadMedida } from '@/_validations/unidadmedida/UnidadValidations';
    
    //data
    function data() {
        return {            
            formTpUnidadMedida: {
                IdTpUnidadMedida:0,
                NbTpUnidadMedida:'',
                FgActTpUnidad:true,
            },            
        }
    }

    //methods
    function closeCollapse(){
        this.$store.state.unidadmedida.collapseTypeUnitMeasure = false;
        //this.$store.state.unidadmedida.collapseTableTipoUnidad = false;
    }

    function updatedItem(){
        try { 
            this.$v.formTpUnidadMedida.$touch();
            if (this.$v.formTpUnidadMedida.$pending || this.$v.formTpUnidadMedida.$error)
            throw this.$t('label.errorsPleaseCheck');
            
            if (this.$v.formTpUnidadMedida.$pending || this.$v.formTpUnidadMedida.$error) return;

            let { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad } = this.formTpUnidadMedida;
            const { dispatch } = this.$store;

            dispatch('unidadmedida/postregisterTipoUnidad', { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad });
            this.refreshComponent();
        } catch (e) {
            this.notifyError({ text: e });
        }
    }

    function refreshComponent(){
        this.formTpUnidadMedida.IdTpUnidadMedida=0;
        this.formTpUnidadMedida.NbTpUnidadMedida='';
        this.formTpUnidadMedida.FgActTpUnidad=true;
        this.$store.state.unidadmedida.collapseTableTipoUnidad = false;
        this.$nextTick(() => { this.$v.$reset() })
        this.$store.commit('unidadmedida/setUnitMeasureTypeId', 0);
    }
    //computed

    //watch
    function collapseTypeUnitMeasure(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.formTpUnidadMedida.IdTpUnidadMedida=0;
            this.formTpUnidadMedida.NbTpUnidadMedida='';
            this.formTpUnidadMedida.FgActTpUnidad=true;
            this.$store.state.unidadmedida.collapseTableTipoUnidad = false;
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('unidadmedida/setUnitMeasureTypeId', 0);
        }
    }
/*    function busqueda(newQuestion,Oldquestion){
        if(this.collapseTypeUnitMeasure){
            if(newQuestion !== 0){
                this.$store.state.unidadmedida.apiStateForm = ENUM.LOADING;
                try {
                    this.$http.ejecutar('GET', 'TpUnitMeasure-by-id',{ TpUnitMeasureId: newQuestion }, '' ).then(response => {
                        try {
                            if(response.status === 200){
                                const UnidadInformation = response.data.data;
                                if(UnidadInformation.length !== 0){
                                    this.formTpUnidadMedida.IdTpUnidadMedida = UnidadInformation[0].TpUnitMeasureId;
                                    this.formTpUnidadMedida.NbTpUnidadMedida = UnidadInformation[0].TpUnitMeasureName;
                                    this.formTpUnidadMedida.FgActTpUnidad = UnidadInformation[0].FgActTpUnitMeasure;
                                    this.$store.state.unidadmedida.apiStateForm = ENUM.INIT;
                                }else{
                                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('unidadmedida/mutationModal', false);
                                }
                            }else{
                                this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                this.$store.commit('unidadmedida/mutationModal', false);
                            }
                        } catch (err) {
                            this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                            this.$store.commit('unidadmedida/messageMutation', err);
                            this.$store.commit('unidadmedida/mutationModal', false);
                        }
                    }).catch(err => {
                        this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                        this.$store.commit('unidadmedida/messageMutation', err);
                        this.$store.commit('unidadmedida/mutationModal', false);
                    });
                } catch (error) {
                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                    this.$store.commit('unidadmedida/messageMutation', error);
                    this.$store.commit('unidadmedida/mutationModal', false);
                }
            }
        }
    }
*/
    export default {
        name:'collapse-type-unit-measure',
        data,
        mixins: [GeneralMixin, modalMixin],
        directives: UpperCase,
        validations(){ return FormTpUnidadMedida() },
        computed: {
            ...mapState({
               // idValor: state => state.unidadmedida.unitMeasureTypeId,
                collapseTypeUnitMeasure: state => state.unidadmedida.collapseTypeUnitMeasure,
                collapseTableTipoUnidad: state => state.unidadmedida.collapseTableTipoUnidad,
            })
        },
        methods:{
            closeCollapse,
            updatedItem,
            refreshComponent,
        },
        watch:{
            idValor: {
               deep:true,
               // handler:busqueda
            },
            collapseTypeUnitMeasure
        }
    }
</script>