import { imgInputTextsHelpers } from '@/_helpers/funciones';

//Data:
function data() {
  return {}
}

//Meodos:
function hasError(field){
  return field.$dirty ? !field.$error : null;
}

//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function imgInputTexts(){
  return imgInputTextsHelpers(this);
}

export default {
  data,
  methods: {
    hasError,
  },
  computed: {
    isSubmitValid,
    imgInputTexts,
  }
}