<template>
    <CRow style="margin-top:20px;">
        <CCol sm="12">
            <CRow>
                <CCol sm="12">
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.generalData')" class="form-especial">
                            <CCard bodyWrapper >
                                <CRow class="mb-3">
                                    <CCol sm="12" lg="11">
                                        <CRow class="m-0">
                                            <CCol sm="12" lg="6" class="px-0 mx-0 mb-3">
                                                <CSelect
                                                    :label="$t('label.operationType')"
                                                    addLabelClasses="required text-right"
                                                    :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                    v-model="$v.formPlanning.OperationId.$model"
                                                    :value.sync="formPlanning.OperationId"
                                                    :options="optionListTpOperation"
                                                    :is-valid="hasError($v.formPlanning.OperationId)"
                                                    size="sm"
                                                >
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formPlanning.OperationId.$error">
                                                            <div class='text-invalid-feedback' v-if="!$v.formPlanning.OperationId.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                </CSelect>
                                                <!--<loading v-show="loading" element="select" />
                                                
                                                <CElementCover
                                                    :opacity="1"
                                                    v-show="loading"
                                                >
                                                    <label class="d-inline">{{$t('label.loading')}}... </label>
                                                    <CSpinner />
                                                </CElementCover>
                                                -->
                                            </CCol>
                                            <CCol sm="12" lg="6" class="px-0 mx-0 mb-3">
                                                <CSelect
                                                    :label="$t('label.type')+' '+$t('label.of')+' '+$t('label.activity')"
                                                    addLabelClasses="required text-right"
                                                    :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                    v-model="$v.formPlanning.ConditionPlanningId.$model"
                                                    :options="optionListTpCondition"
                                                    :is-valid="hasError($v.formPlanning.ConditionPlanningId)"
                                                    size="sm"
                                                >
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formPlanning.ConditionPlanningId.$error">
                                                            <div class='text-invalid-feedback' v-if="!$v.formPlanning.ConditionPlanningId.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                </CSelect>
                                                <!--<loading v-show="loading" element="select" />
                                                
                                                <CElementCover
                                                    :opacity="1"
                                                    v-show="loading"
                                                >
                                                    <label class="d-inline">{{$t('label.loading')}}... </label>
                                                    <CSpinner />
                                                </CElementCover>
                                                -->
                                            </CCol>
                                            <CCol v-if="FgGeneralCargo||bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <CSelect
                                                    :label="$t('label.vesselType')"
                                                    addLabelClasses="required text-right"
                                                    :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                    v-model="$v.formPlanning.TpVesselId.$model"
                                                    :value.sync="formPlanning.TpVesselId"
                                                    :options="OptionsTpVessel"
                                                    :is-valid="hasError($v.formPlanning.TpVesselId)"
                                                    :invalid-feedback="errorMessage($v.formPlanning.TpVesselId)"
                                                    size="sm"
                                                    @change="VesselType($event.target.value)"
                                                >
                                                </CSelect>
                                            </CCol>
                                            <CCol v-if="ValidateBulk" sm="12" lg="6" class="px-0 mb-3">
                                                <CSelect
                                                    :label="$t('label.ModalityOfOperations')"
                                                    addLabelClasses="required text-right"
                                                    :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                    v-model="$v.formPlanning.ModalityId.$model"
                                                    :value.sync="formPlanning.ModalityId"
                                                    :options="optionListModality"
                                                    :is-valid="hasError($v.formPlanning.ModalityId)"
                                                    size="sm"
                                                >
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formPlanning.ModalityId.$error">
                                                            <div class='text-invalid-feedback' v-if="!$v.formPlanning.ModalityId.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                </CSelect>
                                                <!--<loading v-show="loading" element="select" />-->
                                            </CCol>
                                            <CCol v-if="ValidateBulk" sm="12" lg="6" class="px-0 mb-3">
                                                <CSelect
                                                    :label="$t('label.TruckWeighingsModality')"
                                                    addLabelClasses="required text-right"
                                                    :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                    v-model="$v.formPlanning.TruckWeighingModalityId.$model"
                                                    :value.sync="formPlanning.TruckWeighingModalityId"
                                                    :options="OptionTruckWeighingModality"
                                                    :is-valid="hasError($v.formPlanning.TruckWeighingModalityId)"
                                                    :invalid-feedback="errorMessage($v.formPlanning.TruckWeighingModalityId)"
                                                    size="sm"
                                                >      
                                                </CSelect>
                                            </CCol>
                                            <CCol v-if="ValidateBulk" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                    <label class="required text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{ $t('label.StowageFactor') }}</label>
                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            v-model.trim="$v.formPlanning.StowageFactor.$model"
                                                            @blur="$v.formPlanning.StowageFactor.$touch()"
                                                            v-bind="measureStowageFactor"
                                                            :class="computedClassStowageFactor ? 'form-control is-valid' : (isValidStowageFactor ? 'form-control is-invalid' : 'form-control') "
                                                        >
                                                        </money>
                                                    </div>
                                                    <div class=" col-sm-11 col-lg-5" v-if="(formPlanning.StowageFactor <= 0 || formPlanning.StowageFactor > 1) && isValidStowageFactor"></div>
                                                    <div class="col-sm-11 col-lg-7 text-invalid-feedback" 
                                                        v-if="(formPlanning.StowageFactor <= 0 || formPlanning.StowageFactor > 1) && isValidStowageFactor"
                                                    >
                                                        {{$t('validation.minValueIs')+' 0 '+$t('label.and')+' '+$t('validation.maxValueIs')+' 1'}}
                                                    </div>
                                                </div>
                                            </CCol>
                                            <!-- -->
                                            <CCol v-if="this.bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-5 mb-0 col-form-label-sm">{{ $t('label.ballast')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            v-model.trim="$v.formPlanning.Ballast.$model"
                                                            @blur="$v.formPlanning.Ballast.$touch()"
                                                            v-bind="measure"
                                                            :class="$v.formPlanning.Ballast.$dirty ? 'form-control is-valid' : 'form-control'"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol v-if="this.bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-5 mb-0 col-form-label-sm">{{ 'FO - '+$t('label.fuelOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            :label="'FO - '+$t('label.fuelOil')+' '+$t('label.metricTonnageAcronym')"
                                                            v-model.trim="$v.formPlanning.FuelOil.$model"
                                                            @blur="$v.formPlanning.FuelOil.$touch()"
                                                            v-bind="measure"
                                                            :class="$v.formPlanning.FuelOil.$dirty ? 'form-control is-valid' : 'form-control'"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>    
                                            <CCol v-if="this.bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-5 mb-0 col-form-label-sm">{{ 'DO - '+$t('label.dieselOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            :label="'DO - '+$t('label.dieselOil')+' '+$t('label.metricTonnageAcronym')"
                                                            v-model.trim="$v.formPlanning.DieselOil.$model"
                                                            @blur="$v.formPlanning.DieselOil.$touch()"
                                                            v-bind="measure"
                                                            :class="$v.formPlanning.DieselOil.$dirty ? 'form-control is-valid' : 'form-control'"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>       
                                            <CCol v-if="this.bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-5 mb-0 col-form-label-sm">{{ 'FW - '+$t('label.freshWater')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            v-model.trim="$v.formPlanning.FreshWater.$model"
                                                            @blur="$v.formPlanning.FreshWater.$touch()"
                                                            v-bind="measure"
                                                            :class="$v.formPlanning.FreshWater.$dirty ? 'form-control is-valid' : 'form-control'"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol v-if="this.bulkCarrier == 2" sm="12" lg="6" class="px-0 mb-3">
                                                <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 mb-0 col-form-label-sm">{{ $t('label.constant')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                        <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                        <money
                                                            :label="$t('label.constant')"
                                                            v-model.trim="$v.formPlanning.Constant.$model"
                                                            @blur="$v.formPlanning.Constant.$touch()"
                                                            v-bind="measure"
                                                            :class="$v.formPlanning.Constant.$dirty ? 'form-control is-valid' : 'form-control'"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>           
                                        </CRow>
                                    </CCol>
                                    <CCol sm="12" lg="1" class="d-flex align-items-baseline justify-content-end">
                                        <CButton 
                                            color="add"
                                            size="sm" 
                                            class="mr-1"
                                            v-c-tooltip="{
                                                content: $t('label.add'),
                                                placement: 'top-end'
                                            }"
                                            :disabled="isSubmit"
                                            @click="sendDatePlanning()"  
                                        >
                                            <CIcon name="checkAlt"/>
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCard>
                        </CTab>
                    </CTabs>
                </CCol>
                
                <CCol sm="12">
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.vesselDocumentation')" class="form-especial">
                            <CCard bodyWrapper>
                               <CRow class="mb-3">
                                    <CCol sm="12" class="mb-3">
                                        <vue-dropzone 
                                            :aria-disabled="idIndentification" 
                                            ref="myVueDropzone" 
                                            id="dropzone" 
                                            :options="dropzoneOptions" 
                                            :useCustomSlot="true"
                                            v-on:vdropzone-file-added="sendingEvent"
                                            v-on:vdropzone-files-added="sendingEvent"
                                            v-on:vdropzone-error="DropzoneError"
                                        > 
                                            <div class="dropzone-custom-content">
                                                <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                                                <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                                            </div>
                                        </vue-dropzone>
                                    </CCol>
                                    <CCol sm="11">
                                        <CInput
                                            :label="$t('label.description')"
                                            horizontal
                                            addLabelClasses="required text-right"
                                            :placeholder="$t('label.documentDescription')"
                                            :disabled="idIndentification"
                                            v-uppercase
                                            v-model="$v.formRoutePlanificacion.PlanificacionDs.$model"
                                            :addInputClasses="{ 'is-invalid': $v.formRoutePlanificacion.PlanificacionDs.$error }"
                                            @blur="$v.formRoutePlanificacion.PlanificacionDs.$touch()"
                                            :is-valid="hasError($v.formRoutePlanificacion.PlanificacionDs)"
                                        >
                                            <template #invalid-feedback>
                                                <div v-if="$v.formRoutePlanificacion.PlanificacionDs.$error">
                                                    <div class='text-invalid-feedback' v-if="!$v.formRoutePlanificacion.PlanificacionDs.required">{{$t('label.required')}}</div>
                                                    <div class='text-invalid-feedback' v-if="!$v.formRoutePlanificacion.PlanificacionDs.minLength">{{$t('label.required')}} {{$t('label.min')}} {{$v.formRoutePlanificacion.PlanificacionDs.$params.minLength.min}} {{$t('label.chars')}}</div>
                                                    <div class='text-invalid-feedback' v-if="!$v.formRoutePlanificacion.PlanificacionDs.maxLength">{{$t('label.required')}} {{$t('label.max')}} {{$v.formRoutePlanificacion.PlanificacionDs.$params.maxLength.min}} {{$t('label.chars')}}</div>
                                                </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="1"  class="d-flex align-items-start justify-content-end">
                                        <CButton 
                                            color="add"
                                            size="sm"
                                            v-c-tooltip="{
                                                content: $t('label.add'),
                                                placement: 'top-end'
                                            }"
                                            @click="sendDateRoute()"  
                                            :disabled="idIndentification||isSubmit"
                                        >
                                            <CIcon name="checkAlt"/>
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCard>
                        </CTab>
                    </CTabs>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items="formatedItems"
                        :fields="fields"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        sorter
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="5"
                        pagination
                        column-filter
                        hover
                         
                    >
                        <template #StowagePlanningDocId="{ index}">
                            <td class="text-center">
                                {{index+1}}
                            </td>
                        </template>
                        <template #Status="{item}">
                            <td class="text-center">
                                <div>
                                    <CBadge :color="getBadge(item.status)">
                                        {{ $t('label.'+item.Status) }}
                                    </CBadge>
                                </div>
                            </td>
                        </template>
                        <template #acciones="{item}">
                            <td class="center-cell text-center">
                                    <CButton
                                        color="excel"
                                        size="sm" 
                                        class="mr-1"
                                        v-c-tooltip="{
                                            content: $t('label.download')+' '+$t('label.document'),
                                            placement: 'top-end'
                                        }"
                                        
                                        target="_blank"
                                        :href="routeDoc(item)"
                                    >
                                        <CIcon name="cil-cloud-download"/>
                                    </CButton>
                                    <CButton
                                        color="wipe"
                                        size="sm"
                                        class="mr-1"
                                        v-c-tooltip="{
                                            content: $t('label.removeDocument'),
                                            placement: 'top-end'
                                        }"
                                        v-if="item.FgActPlanningDoc"
                                        @click="eliminarArchivo(item)"
                                    >
                                        <CIcon name="cil-trash" />
                                    </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { tableTextHelpers } from '@/_helpers/funciones';
    import { FormAllPlanificacion } from '@/_validations/estiba/PlanificacionEstibaFormValidations';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import { VMoney, Money } from "v-money";
    import GeneralMixin from '@/_mixins/general';

    let user = JSON.parse(localStorage.getItem('user'));

    function fields(){
        return [
            { key: 'StowagePlanningDocId', label: '#', filter:false, _style: 'width:1%; text-align:center;', },
            { key: 'PlanningDocDs',label: this.$t('label.document'), _style:'width:50%' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:12%;', _classes: 'text-uppercase' },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:13%;', _classes:"center-cell text-center"},
            { key: 'Status', label: this.$t('label.status'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center" },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:100px', 
                sorter: false, 
                filter: false
            },
        ];
    }
    //data
    function data() {
        return {
            isSubmit: false,
            formPlanning:{
                OperationId:'',
                ConditionPlanningId:'',
                ModalityId:'',
                TruckWeighingModalityId: '',
                FreshWater: 0,
                Constant: 0,
                DieselOil: 0,
                FuelOil: 0,
                Ballast: 0,
                StowageFactor: 0,
                TpVesselId: '',
            },
            formRoutePlanificacion:{
                PlanificacionDs:'',
                FgActPlanningDoc:true,
            },
            originalAct:'',
            selectOptionsOperativo: [
                { 
                    value: '', 
                    label: this.$t('label.select')
                },
                { 
                    value: 1, 
                    label: 'Opcion 1'
                }
            ],
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_BASE}files/`,
                autoProcessQueue: false,
                maxFiles: 1,
                addRemoveLinks: true,
                dictRemoveFile: `${this.$t('label.delete')}`,
                dictCancelUpload: `${this.$t('label.cancelUpload')}`,
                maxfilesexceeded (files) {
                    this.removeAllFiles();
                    this.addFile(files);
                },
                acceptedFiles: '.pdf, .doc, .docx, .xlsx',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`
                }
            },
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
            },
            measureStowageFactor: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 4,
                masked: false,
            },
            isValidStowageFactor: false,
        }
    }
    //methods
    async function VesselType(Id) {
        this.formPlanning.TpVesselId = Id ?? '';
        this.formPlanning.TruckWeighingModalityId = '';
        this.formPlanning.StowageFactor = '';
        this.formPlanning.ModalityId = '';
        this.$store.state.planificacionestiba.myDataWeighingModality = [];
        if(this.formPlanning.TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            let TpVesselId = this.formPlanning.TpVesselId;
            await this.$store.dispatch('planificacionestiba/getTruckWeighingModality', {TpVesselId: TpVesselId});
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
        }
    }

    function routeDoc(item){
        return `${this.$store.getters["connection/getBase"]}${item.RoutePlanningDoc}`;
    }
    function sendDatePlanning(){
        try{
            this.isSubmit = true;
            this.$v.formPlanning.$touch();
            this.isValidStowageFactor = true;
            if (this.$v.formPlanning.$pending || this.$v.formPlanning.$error){  
                this.isSubmit = false;             
                throw this.$t('label.errorsPleaseCheck');
            }

            if ((this.formPlanning.StowageFactor <= 0 || this.formPlanning.StowageFactor > 1) && this.bulkCarrier == 2 && !this.ValidateGeneralCargo){
                this.isValidStowageFactor = true;
                this.isSubmit = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.errorsPleaseCheck'),
                    type: 'error',
                });
            }

            let { ConditionPlanningId, OperationId, ModalityId, TruckWeighingModalityId, FreshWater, Constant, DieselOil, FuelOil, Ballast, StowageFactor, TpVesselId} = this.formPlanning;
            if (this.ValidateGeneralCargo) {
                TruckWeighingModalityId = '';
                StowageFactor = '';
            }
            const { dispatch } = this.$store;
            dispatch('planificacionestiba/sendDatePlanning', { ItineraryId:this.ItineraryId,StowagePlanningId: this.planificacionId,ConditionPlanningId,OperationId, ModalityId, TruckWeighingModalityId, FreshWater, Constant, DieselOil, FuelOil, Ballast, StowageFactor, TpVesselId });
            this.isSubmit = false;
        } catch (e) {
            this.isSubmit = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: 'error',
            });
        }
    
    }
    function sendingEvent(file){
        if(["", null, undefined].includes(this.planificacionId)){
            this.$refs.myVueDropzone.removeFile(file);
        }
    }
    function byIdPlanning(id){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'StowagePlanning-by-id',{ StowagePlanningId: id }, '' ).then(async response => {
            if(response.status === 200){
                try {
                    const Information = response.data.data;
                    if(Information.length !== 0){
                        this.formPlanning.OperationId = Information[0]?.OperationId ?? '';
                        this.formPlanning.ConditionPlanningId = Information[0]?.ConditionPlanningId ?? '';
                        this.formPlanning.ModalityId = Information[0]?.ModalityId ?? '';
                        this.formPlanning.TruckWeighingModalityId = Information[0]?.TruckWeighingModalityId ?? '';
                        this.formPlanning.FreshWater = Information[0]?.FreshWater ? parseFloat(Information[0].FreshWater).toFixed(2) : 0;
                        this.formPlanning.Constant =  Information[0]?.Constant ? parseFloat(Information[0].Constant).toFixed(2) : 0;
                        this.formPlanning.DieselOil = Information[0]?.DieselOil ? parseFloat(Information[0].DieselOil).toFixed(2) : 0;
                        this.formPlanning.FuelOil = Information[0]?.FuelOil ? parseFloat(Information[0].FuelOil).toFixed(2) : 0;
                        this.formPlanning.Ballast = Information[0]?.Ballast ? parseFloat(Information[0].Ballast).toFixed(2) : 0;
                        this.formPlanning.StowageFactor = Information[0]?.StowageFactor ? parseFloat(Information[0].StowageFactor).toFixed(4) : 0;
                        if ((this.formPlanning.StowageFactor <= 0 || this.formPlanning.StowageFactor > 1) && this.bulkCarrier == 2 ){
                            this.isValidStowageFactor = true;
                        }
                        this.formPlanning.TpVesselId = Information[0].TpVesselId ?? '';
                        this.$store.state.planificacionestiba.StowageFactor = Information[0].StowageFactor;
                        this.$store.state.planificacionestiba.TpVesselId = Information[0].TpVesselId;
                        this.$store.state.planificacionestiba.TpVesselMasterId = Information[0].TpVesselMasterId;
                        this.$store.state.planificacionestiba.ConditionPlanningId = Information[0].ConditionPlanningId;
                        this.$store.state.planificacionestiba.ModalityId = Information[0].ModalityId;
                        this.$store.state.planificacionestiba.FgGeneralCargo = Information[0].TpVesselId == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
                        await this.$store.dispatch('planificacionestiba/getPlanificacionlist',{StowagePlanningId:id, TpVesselId:Information[0].TpVesselId});
                        this.$v.formPlanning.$touch();
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }else{
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        this.$store.state.planificacionestiba.collapsePlanificacion = !this.$store.state.planificacionestiba.collapsePlanificacion;
                    }
                } catch (err) {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    this.$store.commit('planificacionestiba/messageMutation', err);
                    this.$store.state.planificacionestiba.collapsePlanificacion = !this.$store.state.planificacionestiba.collapsePlanificacion;
                }
            }else{
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.state.planificacionestiba.collapsePlanificacion = !this.$store.state.planificacionestiba.collapsePlanificacion;
            }
        }).catch(err => {
            this.$store.commit('planificacionestiba/messageMutation', err);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.state.planificacionestiba.collapsePlanificacion = !this.$store.state.planificacionestiba.collapsePlanificacion;
        });
    }
    function eliminarArchivo(item){
        let { StowagePlanningId,StowagePlanningDocId,PlanningDocDs,FgActPlanningDoc,RoutePlanningDoc } = item;
        const { dispatch } = this.$store;

        this.$swal.fire({
            text: `${this.$t('label.deleteQuestion')+' '} ${PlanningDocDs}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#42AA91',
            cancelButtonColor: '#E1373F',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                FgActPlanningDoc = !FgActPlanningDoc;
                dispatch('planificacionestiba/sendDateDocPlanning', { StowagePlanningId,StowagePlanningDocId,PlanningDocDs,RoutePlanningDoc,FgActPlanningDoc });
            }else {
              //  this.$swal(this.$t('label.operationCancelled'));
            }
        })
    }
    async function sendDateRoute(){
        this.isSubmit = true;
        const files = this.$refs.myVueDropzone.getQueuedFiles();
        if(files.length === 0 || ["", null, undefined].includes(this.planificacionId)){
            this.$v.formRoutePlanificacion.$touch();
        }
        if(files.length === 0 || ["", null, undefined].includes(this.planificacionId)){
            this.$notify({
                group: 'container',
                type: 'error',
                ignoreDuplicates:false,
                title: 'ERROR',
                text: `${this.$t('label.documentQuestion')}`,
            });
            this.isSubmit = false;
            return;
            
        }

        this.$v.formRoutePlanificacion.$touch();
        if (this.$v.formRoutePlanificacion.$pending || this.$v.formRoutePlanificacion.$error) return;

        if(this.idIndentification) {
            this.isSubmit = false;
            return;
        }
            

        let { PlanificacionDs,FgActPlanningDoc } = this.formRoutePlanificacion;
        let StowagePlanningId = this.planificacionId;

        this.$swal.fire({
            text: `${this.$t('label.addFileQuestion')}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#42AA91',
            cancelButtonColor: '#E1373F',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                //this.$refs.myVueDropzone.processQueue();
                const { dispatch } = this.$store;
                dispatch('planificacionestiba/sendDateDocPlanning', { StowagePlanningId,
                    StowagePlanningDocId:0,
                    PlanningDocDs:PlanificacionDs,
                    RoutePlanningDoc:'',
                    FgActPlanningDoc,
                    filesDoc:files[0]
                });
                this.isSubmit = false;
            }else {
                this.isSubmit = false;
              //  this.$swal('Operación cancelada');
            }
        })
    }
    function deleteDate(type){
        if(type === 0){
            this.formPlanning.OperationId = '';
            this.formPlanning.ConditionPlanningId = '';
            this.formPlanning.ModalityId = '';
            this.formPlanning.TruckWeighingModalityId = '';
            this.formPlanning.FreshWater = 0;
            this.formPlanning.Constant = 0;
            this.formPlanning.DieselOil = 0;
            this.formPlanning.fuelOil = 0;
            this.formPlanning.Ballast = 0;
            this.formPlanning.StowageFactor = 0;
            this.formPlanning.TpVesselId = '';
            this.isValidStowageFactor = false,

            this.$nextTick(() => { this.$v.formPlanning.$reset() })
        }
        else if(type === 1){
            this.formRoutePlanificacion.PlanificacionDs = '';
            this.$refs.myVueDropzone.removeAllFiles();
            this.$nextTick(() => { this.$v.formRoutePlanificacion.$reset() })
        }
        
    }

    function DropzoneError(file) {
        this.$refs.myVueDropzone.removeFile(file);
    }

    function computedClassStowageFactor() {
        if (this.formPlanning.StowageFactor <= 0 || this.formPlanning.StowageFactor > 1) {
            return false;
        } else {
            return true;
        }
    }
    //computed
    function optionListTpCondition(){
        if(this.myDataTpCondition.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataTpCondition.map(function(e){
                if(e.FgActConditionPlanning){
                    chart.push({
                        value: e.ConditionPlanningId, 
                        label: e.ConditionPlanningName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListModality(){
        let chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        this.myDataModality.map(function(e){
            if(e.FgActModality){
                chart.push({
                    value: e.ModalityId, 
                    label: e['ModalityName'+_lang],
                })
            }
        })
        return chart;
    }
    function OptionTruckWeighingModality(){
        if(this.myDataWeighingModality.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]

            let _lang = this.$i18n.locale;
            this.myDataWeighingModality.map(function(e){
                chart.push({
                    value: e.TruckWeighingModalityId, 
                    label: _lang=='en' ?  e.TruckWeighingModalityNameEn||'' : e.TruckWeighingModalityNameEs||'',
                })
            })
            return chart;
        }
    }
    function OptionsTpVessel(){
        if(this.myDataTpVessel.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataTpVessel.map(function(e){
                chart.push({
                    value: e.TpVesselId, 
                    label: e.TpVesselName,
                })
            })
            return chart;
        }
    }
    function optionListTpOperation(){
        if(this.myDataTpOperation.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataTpOperation.map(function(e){
                if(e.FgActOperation){
                    chart.push({
                        value: e.OperationId, 
                        label: e.OperationName,
                    })
                }
            })
            return chart;
        }
    }
    function idIndentification(){
        return ["", null, undefined].includes(this.planificacionId);
    }
    function computedItems () {
        return this.$store.getters["planificacionestiba/myDataPlanningDoctable"];
    }
    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    //watch
    function planificacionId(newValue,OldValue){
        this.deleteDate(0);
        if(this.collapsePlanificacion){
            this.isValidStowageFactor = true;
            
            if(OldValue === '' && newValue !== '' && newValue !== null){
                this.byIdPlanning(newValue);
            }else{
                this.$store.dispatch('planificacionestiba/getPlanificacionlist',{StowagePlanningId: newValue, TpVesselId: this.TpVesselId});
            }
        }
    }
    function formReset(newValue){
        this.deleteDate(newValue);
    }
    function ValidateGeneralCargo() {
        return this.formPlanning.TpVesselId == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
    }

    function ValidateBulk() {
        return this.formPlanning.TpVesselId == process.env.VUE_APP_BULK_VESSEL_ID;
    }
    export default {
        name:'app-general',
        data,
        props: ['loading'],
        mixins: [TipoGruaMixin, GeneralMixin],
        directives: UpperCase,
        validations(){ return FormAllPlanificacion(this.bulkCarrier, this.formPlanning.TpVesselId) },
        components: {
            vueDropzone: vue2Dropzone,
            Money,
        },
        methods:{
            VesselType,
            sendDatePlanning,
            sendDateRoute,
            byIdPlanning,
            deleteDate,
            DropzoneError,
            eliminarArchivo,
            sendingEvent,
            routeDoc,
        },
        computed: {
            idIndentification,
            computedItems,
            formatedItems,
            optionListTpCondition,
            optionListModality,
            OptionTruckWeighingModality,
            OptionsTpVessel,
            optionListTpOperation,
            fields,
            computedClassStowageFactor,
            ValidateGeneralCargo,
            ValidateBulk,
            ...mapState({
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataTpCondition: state => state.planificacionestiba.myDataTpCondition,
                myDataModality: state => state.planificacionestiba.myDataModality,
                myDataWeighingModality: state => state.planificacionestiba.myDataWeighingModality,
                myDataTpOperation: state => state.planificacionestiba.myDataTpOperation,
                myDataTpVessel: state => state.planificacionestiba.myDataTpVessel,
                collapsePlanificacion: state => state.planificacionestiba.collapsePlanificacion,
                formReset: state => state.planificacionestiba.formReset,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVesselId: state => state.planificacionestiba.TpVesselId,
                bulkCarrier: state => state.planificacionestiba.bulkCarrier,
                FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
            })
        },
        watch: {
            tabIndex: async function (val) {
                if(val == 0 && !["", null, undefined].includes(this.planificacionId)){
                    this.byIdPlanning(this.planificacionId);
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.formRoutePlanificacion.PlanificacionDs = '';
                    this.$v.formRoutePlanificacion.PlanificacionDs.$reset();
                }
            },
            planificacionId,
            formReset,
            collapsePlanificacion: async function (NewVal) {
                if (NewVal&&!this.planificacionId) {
                    await this.deleteDate(0);
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.formRoutePlanificacion.PlanificacionDs = '';
                    this.$v.formRoutePlanificacion.PlanificacionDs.$reset();
                    this.$v.$reset();
                }
            }
        }
    }
</script>
<style lang="scss">
    .center-cell {
    text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
    .text-invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #e55353;
    }
</style>