<template>
    <CModalExtended 
        :title="titleModal" 
        color="dark" 
        size="lg" 
        :close-on-backdrop="false" 
        :show.sync="modalActive"
        @update:show="resetForm"
        >
      <CRow>
        <CCol sm="12" v-if="modalActive">
            <video muted loop="true" controls class="mt-2 mb-2">
              <source 
                :src="getVideo" 
                type="video/mp4">
            </video>
        </CCol>
      </CRow>
      <template #footer>
        <CButton shape="square" color="wipe" class="d-flex align-items-center"  @click="resetForm">
          <CIcon name="x" />
          <span class="ml-1">{{ $t('button.exit') }}</span>
        </CButton>
      </template>
    </CModalExtended>
  </template>
  
  <script>
  import ModalMixin from '@/_mixins/modal';
  
  function data() {
  return {
    modalActive: false,
    Loading: false,
  };
  }
 
  function getVideo() {
    if (this.Type == 1) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_VESSEL_INFO_VIDEO_ROUTE}`;
    }
    else if(this.Type == 2) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_HOLDS_VIDEO_ROUTE}`;
    }
    else if(this.Type == 3) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_CRANES_VIDEO_ROUTE}`;
    }
    else if(this.Type == 4) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_CRANES_HOLDS_VIDEO_ROUTE}`;
    }
    else if(this.Type == 5) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_BAY_ROUTE}`;
    }
    else if(this.Type == 6) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_CRANE_BAY_ROUTE}`;
    }
    else if(this.Type == 7) {
        return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_TRANSVERSAL_VIEW_ROUTE}`;
    }  
}

  function resetForm() {
    this.$emit('close'); 
  }

  function titleModal() {
    return this.$t('label.help')+': '+`${this.title}`;
  }
  
  export default {
  name: 'modal-help-views',
  props: { modal: Boolean,title: String, Type: Number},
  data,
  mixins: [
    ModalMixin,
  ],
  methods: {
    resetForm
  },
  computed: {
    getVideo,
    titleModal
  },
  watch: {
    modal: function (val) {
      this.modalActive = val;
    },
  },
  
  };
  </script>
  <style scoped>
  video { 
    width: 100%;
  }
  </style>
  