<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{`${$t('label.newEvent')} / ${$t('TRASEGADO')}`}} 
        </h6>
      </CCol>
    </CRow>
      <CRow class="justify-content-center mt-3 px-3">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.basicData')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    
                    <div class="form-group form-row mb-0">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 col-xl-4 text-right">{{`${$t('label.destinationContainer')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="col-sm-12 col-lg-12 px-0"
                          v-uppercase
                          :disabled="false"
                        >
                          <template #append>
                            <div class="d-flex align-items-start">
                              <CButton
                                  color="watch"
                                  square
                                  size="sm"
                                  class="mx-1"
                                  style="height:calc(1.5em + 0.5rem + 2px)"
                          
                              >
                                <div class="form-row justify-content-center align-items-center yard">
                                  <CDropdown
                                      :togglerText="$t('')"
                                      class="text-white"
                                  >
                                    <CRow class="justify-content-center">
                                      <CCol sm="3" class="justify-content-center" style="border-right: 1px solid #d3d3d3;">
                                        <CIcon name="eye"/>
                                      </CCol>
                                      <CCol sm="7" class="px-0">
                                        <CDropdownItem class="px-0 ml-1" >MOVIMIENTO</CDropdownItem>
                                      </CCol>
                                    </CRow>

                                    <CRow class="justify-content-center">
                                      <CCol sm="3" class="justify-content-center" style="border-right: 1px solid #d3d3d3;">
                                        <CIcon name="eye"/>
                                      </CCol>
                                      <CCol sm="7" class="px-0" style="background-color:#ededed;">
                                        <CDropdownItem class="px-0 ml-1" >UBICACIÓN</CDropdownItem>
                                      </CCol>
                                    </CRow>

                                    <CRow class="justify-content-center">
                                      <CCol sm="3" class="justify-content-center" style="border-right: 1px solid #d3d3d3;">
                                        <CIcon name="eye"/>
                                      </CCol>
                                      <CCol sm="7" class="px-0">
                                        <CDropdownItem class="px-0 ml-1" >ESTATUS</CDropdownItem>
                                      </CCol>
                                    </CRow>

                                    <CRow class="justify-content-center">
                                      <CCol sm="3" class="justify-content-center" style="border-right: 1px solid #d3d3d3;">
                                        <CIcon name="eye"/>
                                      </CCol>
                                      <CCol sm="7" class="px-0" style="background-color:#ededed;">
                                        <CDropdownItem class="px-0 ml-1" >AGENTE ADUANAL</CDropdownItem>
                                      </CCol>
                                    </CRow>
                                  </CDropdown>
                            
                                </div>
                              </CButton>
                            </div>
                          </template>
                        </CInput>
                      </div>
                    </div>
                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.ubication')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.ubication')"
                    >
                    </CInput>

                    <CSelect
                      size="sm"
                      :label="$t('label.machine')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                    />

                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.yard')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      :is-valid="hasError(true)"
                      :placeholder="$t('label.yard')"
                      :disabled="true"
                    >
                    </CInput>
                  </CCol>
                  
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row mb-2">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 col-xl-4 text-right">{{`${$t('label.receptionDate')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="TransactionDate"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              class="w-100 float-left"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="true"
                              @blur="true"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <div class="form-group form-row mb-2">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 col-xl-4 text-right">{{`${$t('label.from')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="TransactionDate"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="true"
                              @blur="true"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <div class="form-group form-row mb-2">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 col-xl-4 text-right">{{`${$t('label.to')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="TransactionDate"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="true"
                              @blur="true"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>

                    <CTextarea
                      size="sm"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.description')"
                      addLabelClasses="text-right text-label-blue"
                      :placeholder="$t('label.description')"
                      rows="4"
                    />
                  </CCol>  
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.inspectionSeals')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal1.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal1.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal1.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal1, 1)"
                        />
                        <CButton
                          v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal2.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal2.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal2.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal2, 2)"
                        />
                        <CButton
                          v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal3.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal3.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal3.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal3, 3)"
                        />
                        <CButton
                          v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal4.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal4.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal4.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal4, 4)"
                        />
                        <CButton
                          v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" class="text-invalid-feedback text-center" 
                    v-if="Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                  >
                    <label>{{ $t('label.AtLeastSealRequired') }}</label>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        </CCol>
      </CRow>
      <ModalSeal
        :modal="ModalSealActive"
        :SealJson="SealJson"
        @CloseModal="(ModalSealActive=false, SealJson={})"
        @UpdateSeal="UpdateSeal"
      />
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import positioningRegisterValidations from '@/_validations/yard-management/container/positioningRegisterValidations';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import  ModalSeal  from './modal-seal';

function data() {
  return {
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    SealJson: {},
    title: '',
    VisitId: '',
    VesselName: '',
    VisitList: [],
    YardStatusSuggestTransactionId: '',
    TransactionList: [],
    YardName: '',
    YardId: '',
    block: '',
    bay: '',
    stack: '',
    level: '',
    CurrentPosition: '',
    machine: '',
    previousPosition: '',
    CurrentPosition: '',
    MachineId: '',
    MachineList: [],
    EirNumber: '',
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Observation: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    TransactionDate: '',
  };
}

async function YardContainerSearch() {
  await this.$http.get('YardContainerSearch-by-VisitCargoId', { VisitCargoId: this.ContainerItem.VisitCargoId })
    .then(response => {
      let List = response.data.data;
      this.VisitList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function SealConditionList() {
  await this.$http.get('SealCondition-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function YardMachine() {
  await this.$http.get('YardMachine-by-TpCargoDetailId', { CompanyBranchId: this.CompanyBranchId, TpCargoDetailId: this.ContainerItem.TpCargoDetailId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let CargoJson = {
      VisitCargoId: this.VisitCargoId,
      YardId: this.YardId,
      YardStatusSuggestTransactionId: this.YardStatusSuggestTransactionId,
      MachineId: this.MachineId,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.ConditionWithoutSeal(this.Seal.Seal1.SealAssignmentId) ? '' : this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.SealAssignmentId) ? [] : this.Seal.Seal1.SealDocumentJson,
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.ConditionWithoutSeal(this.Seal.Seal2.SealAssignmentId) ? '' : this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.SealAssignmentId) ? [] : this.Seal.Seal2.SealDocumentJson,
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.ConditionWithoutSeal(this.Seal.Seal3.SealAssignmentId) ? '' : this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.SealAssignmentId) ? [] : this.Seal.Seal3.SealDocumentJson,
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.ConditionWithoutSeal(this.Seal.Seal4.SealAssignmentId) ? '' : this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.SealAssignmentId) ? [] : this.Seal.Seal4.SealDocumentJson,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
      Observation: this.Observation.trim(),
      EirNumber: this.EirNumber,
    }

    this.$http.post( 'YardCargoReception', CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list");
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function getdata(val) {
  this.VisitCargoId = val.VisitCargoId;
  this.YardName = this.yardData.YardName;
  this.YardId = this.yardData.YardId;
  if (this.VisitList.length!=0 && this.ValidateYardCargoStatus) {
    this.previousDate = DateFormater.formatDateRange(this.VisitList[0].TransactionDate);
    this.laterDate = new Date();
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.VisitList[0].TransactionDate));
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
    this.VesselName = this.VisitList[0].VesselName ? this.VisitList[0].VesselName : '';
    this.VisitId = this.VisitList[0].VisitId ? this.VisitList[0].VisitId : '';
    this.TransactionList = this.VisitList[0].TransactionsSuggestJson&&this.VisitList[0].TransactionsSuggestJson.length!=0 ?
      this.VisitList[0].TransactionsSuggestJson : [];
    this.Seal.Seal1.CurrentSeal = this.VisitList[0].Seal1 ? this.VisitList[0].Seal1 : '';
    this.Seal.Seal1.Seal = this.VisitList[0].Seal1 ? this.VisitList[0].Seal1 : '';
    this.Seal.Seal2.CurrentSeal = this.VisitList[0].Seal2 ? this.VisitList[0].Seal2 : '';
    this.Seal.Seal2.Seal = this.VisitList[0].Seal2 ? this.VisitList[0].Seal2 : '';
    this.Seal.Seal3.CurrentSeal = this.VisitList[0].Seal3 ? this.VisitList[0].Seal3 : '';
    this.Seal.Seal3.Seal = this.VisitList[0].Seal3 ? this.VisitList[0].Seal3 : '';
    this.Seal.Seal4.CurrentSeal = this.VisitList[0].Seal4 ? this.VisitList[0].Seal4 : '';
    this.Seal.Seal4.Seal = this.VisitList[0].Seal4 ? this.VisitList[0].Seal4 : '';
  }
  this.$v.$reset();
}

function Reset() {
  this.title = '';
  this.VisitId = '';
  this.VisitList = [],
  this.YardStatusSuggestTransactionId = '';
  this.TransactionList = [];
  this.YardName = '';
  this.YardId = '';
  this.MachineId = '';
  this.MachineList = [];
  this.EirNumber = '';
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.Observation = '',
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.TransactionDate = '';
  this.Validate = false;
  this.$v.$reset();
  this.$emit("Close");
}

//Computeds:
function VisitOptions() {
  if(this.VisitList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
      Json: {
        VoyageArrival: '',
        Eta: '',
        VisitStatus: '',
      }
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
      Json: {
        VoyageArrival: '',
        Eta: '',
        VisitStatus: '',
      }
    }]
    let _lang = this.$i18n.locale;
    this.VisitList.map(function(e){
      chart.push({
        value: e.VisitId,
        label: e.VesselName,
        Json: {
          VoyageArrival: e.VoyageArrival,
          Ata: DateFormater.formatDateTimeWithSlash(e.Ata),
          VisitStatus: _lang=='en'?e.VisitCargoStatusEn:e.VisitCargoStatusEs,
        }
      })
    })
    return chart;
  }
}

function TransactionOptions() {
  if(this.TransactionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.TransactionList.map(function(e){
      chart.push({
        value: e.YardStatusSuggestTransactionId,
        label: _lang=='en'? e.TpTransacEirNameEn : e.TpTransacEirNameEs,
      })
    })
    return chart;
  }
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = this.$i18n.locale;
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: _lang=='en' ? e.SealConditionNameEn : e.SealConditionNameEs,
      })
    })
    return chart;
  }
}

function ValidateYardCargoStatus() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_SHIP_SIDE == this.VisitList[0]?.YardCargoStatus?.toUpperCase();
}

function ValidateTpCargoStatus() {
  if (this.VisitId) {
    let Visit = this.VisitList.find(item => item.VisitId == this.VisitId);
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == Visit?.MainJson[0]?.TpCargoStatusId?.toUpperCase();
  }else{
    return false;
  }
}
function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      Seal1: false,
      ConditionSeal1: false,
      Seal2: false,
      ConditionSeal2: false,
      Seal3: false,
      ConditionSeal3: false,
      Seal4: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        Seal1: true,
        ConditionSeal1: true,
        Seal2: true,
        ConditionSeal2: true,
        Seal3: true,
        ConditionSeal3: true,
        Seal4: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      Seal1: this.Seal.Seal1.Seal ? true : false,
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&!this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? true : false,
      Seal2: this.Seal.Seal2.Seal ? true : false,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&!this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? true : false,
      Seal3: this.Seal.Seal3.Seal ? true : false,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&!this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? true : false,
      Seal4: this.Seal.Seal4.Seal ? true : false,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&!this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? true : false,
    }
  }
}

export default {
  name: 'equipment-register',
  components: {
    ModalSeal,
   },
  props: { Active: Boolean, ContainerItem: Object, Index: Number },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){
    return positioningRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate);
  },
  directives: UpperCase,
  methods: {
    YardContainerSearch,
    SealConditionList,
    YardMachine,
    Submit,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    UpdateSeal,
    getdata,
    Reset,
  },
  computed: {
    VisitOptions,
    TransactionOptions,
    MachineOptions,
    ConditionOptions,
    ValidateYardCargoStatus,
    ValidateTpCargoStatus,
    VerifySeal,
    ...mapState({
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      yardData: state => state.yardManagement.yardData,
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        this.$store.state.yardManagement.loading = true;
        await this.YardContainerSearch();
        await this.SealConditionList();
        await this.YardMachine();
        await this.getdata(this.ContainerItem);
        this.$store.state.yardManagement.loading = false;
      }
    },
    VisitId: async function (val) {
      if (!this.ValidateYardCargoStatus) {
        if (val) {
          this.$store.state.yardManagement.loading = true;
          let Visit = await this.VisitList.find(item => item.VisitId == val);
          this.TransactionList = Visit.TransactionsSuggestJson;
          this.previousDate = DateFormater.formatDateRange(Visit.TransactionDate);
          this.laterDate = new Date();
          this.ValidPreviousDate = Visit.TransactionDate;
          this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
          this.Seal = {
            Seal1: {
              index: 1,
              CurrentSeal: Visit.Seal1 ? Visit.Seal1 : '',
              Seal: Visit.Seal1 ? Visit.Seal1 : '',
              NewSeal: '',
              ConditionSeal: '',
              SealAssignmentId: '',
              ObservationSeal: '',
              SealDocumentJson: [],
            },
            Seal2: {
              index: 2,
              CurrentSeal: Visit.Seal2 ? Visit.Seal2 : '',
              Seal: Visit.Seal2 ? Visit.Seal2 : '',
              NewSeal: '',
              ConditionSeal: '',
              SealAssignmentId: '',
              ObservationSeal: '',
              SealDocumentJson: [],
            },
            Seal3: {
              index: 3,
              CurrentSeal: Visit.Seal3 ? Visit.Seal3 : '',
              Seal: Visit.Seal3 ? Visit.Seal3 : '',
              NewSeal: '',
              ConditionSeal: '',
              SealAssignmentId: '',
              ObservationSeal: '',
              SealDocumentJson: [],
            },
            Seal4: {
              index: 4,
              CurrentSeal: Visit.Seal3 ? Visit.Seal3 : '',
              Seal: Visit.Seal3 ? Visit.Seal3 : '',
              NewSeal: '',
              ConditionSeal: '',
              SealAssignmentId: '',
              ObservationSeal: '',
              SealDocumentJson: [],
            },
          };
          
          this.Seal.Seal1.CurrentSeal = Visit.Seal1 ? Visit.Seal1 : '';
          this.Seal.Seal1.Seal = Visit.Seal1 ? Visit.Seal1 : '';
          this.Seal.Seal2.CurrentSeal = Visit.Seal2 ? Visit.Seal2 : '';
          this.Seal.Seal2.Seal = Visit.Seal2 ? Visit.Seal2 : '';
          this.Seal.Seal3.CurrentSeal = Visit.Seal3 ? Visit.Seal3 : '';
          this.Seal.Seal3.Seal = Visit.Seal3 ? Visit.Seal3 : '';
          this.Seal.Seal4.CurrentSeal = Visit.Seal4 ? Visit.Seal4 : '';
          this.Seal.Seal4.Seal = Visit.Seal4 ? Visit.Seal4 : '';
          this.$store.state.yardManagement.loading = false;
        }else{
          this.TransactionList = [];
        }
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
 
};
</script>
<style scope>
  .yard .dropdown-toggle {
    height: calc(1em + 2px);
    padding: 0px 5px 0px 5px !important;
    margin: 0px!important;
  }

  .card-border{
    padding: 20px;
    background-color: #fff;
    border-color: #c4c9d0;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .yard .dropdown-menu{
    background-color: #ffffffd1;
    border-color: #0627584a;
    color: #000000;
  }


  .yard .dropdown-item{
    color: #000000;
  }

  .yard .dropdown-item:hover, .dropdown-item:focus{
    border-radius: 5px;
    background-color:#999999;
  }

</style>