<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="title-report text-center">
                  <strong>{{ $t("label.ErrorLog") }}</strong>
                </div>
                <div class="vessel mt-2">
                    <div class="LogError">
                      <SimplePie
                        v-if="SummaryArray.length!=0"
                        :Series="SummaryArray"
                        :Colors="ColorsArray"
                        :Categories="ErrorType"
                        :Tooltip="Tooltip"
                        :Animations="false"
                      />
                    </div>
                    <div class="TableSummary">
                      <CCard style="border-color: #88a6d1 !important;">
                        <CCardHeader style="background: #1e3a61 !important;" class="text-center text-white">
                          <CIcon name="table"/><b>{{$t('label.TabletUsageSummary')}}</b>
                        </CCardHeader>
                        <CCardBody style="background: #e6e6e6 !important;" class="p-2">
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.UsersWithPermission')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantUsersWithPermission)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.UsersTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantUsersTransactions)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.TabletEnabled')}}:{{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantDevicesEnabled)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.TableTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantDevicesTransactions)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.NumberOfErrors')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantErrors)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.NumberOfTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantTransactions)}}
                            </b>
                          </CCol>
                          <CCol sm="12">
                            <b>
                              {{this.$t('label.NumberOfWarningMessages')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantWarningMessages)}}
                            </b>
                          </CCol>
                        </CCardBody>
                      </CCard>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+1 }} {{ $t("label.of") }} {{ 1 }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater } from "@/_helpers/funciones";
import DataStorage from "@/_helpers/localStorage";
import SimplePie from "../simplePie";
//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function ValidateValue(Val) {
    if (Val) {
        return Val;
    } else {
        return 0;
    }
}
//Computeds:
function SummaryArray(){
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(item.CantErrors);
    });
    return data;
}
function ColorsArray(){
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(item.ErrorTpColor);
    });
    return data;
}
function ErrorType(){
    let _lang = this.$i18n.locale;
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(_lang=='en'?item.ErrorTpNameEn:item.ErrorTpNameEs);
    });
    return data;
}
function Tooltip() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleCompany() {
 let CompanyName = this.user.CompanyName;
 let CompanyRif = this.user.CompanyRif;
 let BranchName = this.user.BranchJson[0].BranchName;

  return `${CompanyName} ${CompanyRif} - ${BranchName}`;
}
function titleVessel() {
 let VesselName = this.itinerarySelected.VesselName;
 let Imo = this.itinerarySelected.Imo;
 let CountryName = this.itinerarySelected.CountryName;
 let berth = this.$t('label.berth');
 let BerthName = this.itinerarySelected.BerthName;

  return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
}
function titleFromItinerary() {
  let previousDate;
  let laterDate;
  let fecha = "";
  let voyage = this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

  if(this.itinerarySelected.Ata!=undefined){ 
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = "ATA:"+fecha;
  }

  if(this.itinerarySelected.Ata==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = "ETA:"+fecha;
  }

  if(this.itinerarySelected.Atd!=undefined){  
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = "ATD:"+fecha;
  }

  if(this.itinerarySelected.Atd==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
    laterDate = "ETD:"+fecha;
  }

  return `${previousDate}, ${laterDate} - ${voyage}`;
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

export default {
  name: "Error-Summary-Pdf",
  props: {
    ErrorLogDeviceSummaryJson: Array,
    ErrorLogDistributionJson: Array,
    itinerarySelected: Object,
  },
  components: {
    SimplePie,
  },
  data,
  created,
  methods: {
    ValidateValue,
  },
  computed: {
    Tooltip,
    ErrorType,
    ColorsArray,
    SummaryArray,
    currentDateTime,
    titleCompany,
    titleVessel,
    titleFromItinerary,
    hasLogo,
    loginName,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}
.title-report{
    width: 100%;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  /* height: 75%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  /* margin-top: 10px; */
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}
.LogError{
  width: 63%;
}
.TableSummary {
  width: 37%;
}
</style>