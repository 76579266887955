<template>
  <div>
    <a href="#" class="bank-collapse" @click.prevent="collapse = !collapse">
      <span>{{getTypeName}}</span>
      <CIcon
        name="cil-chevron-bottom"
        :class="{
          'arrow-icon': true,
          'rotate': collapse
        }"
      />
    </a>
    <CCollapse :show="collapse">
      <CCard body-wrapper>
        <CRow v-if="type == 'account'">
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.holder')"
              :horizontal="{
                label: 'col-sm-12 col-lg-4',
                input: 'col-sm-12 col-lg-8',
              }"
              v-uppercase
              :label="$t('label.holder')"
              maxlength="50"
              size="sm"
              v-model.trim="$v.bankInfo.AccountHolder.$model"
              :invalid-feedback="errorMessage($v.bankInfo.AccountHolder)"
              :is-valid="hasError($v.bankInfo.AccountHolder)"
            />
          </CCol>
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.IdNumber')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              :label="$t('label.IdNumber')"
              maxlength="10"
              size="sm"
              v-model.trim="$v.bankInfo.BankInfoNumberId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.BankInfoNumberId)"
              :is-valid="hasError($v.bankInfo.BankInfoNumberId)"
            >
              <template #prepend>
                <CSelect
                  :value.sync="numberIdLetter"
                  :options="ciTypeOptions"
                  class="mr-2 mb-0"
                  size="sm"
                />
              </template>
            </CInput>
          </CCol>
          <CCol sm="4">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              :addLabelClasses="getLabelClasses"
              :label="$t('label.bank')"
              :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9' }"
              :options="banks"
              :disabled="isEmpty(banks)"
              :value.sync="$v.bankInfo.BankId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.BankId)"
              :is-valid="hasError($v.bankInfo.BankId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="4">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              :addLabelClasses="getLabelClasses"
              :label="$t('label.accountType')"
              :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8' }"
              :options="tpAccounts"
              :disabled="isEmpty(tpAccounts)"
              :value.sync="$v.bankInfo.TpAccountId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.TpAccountId)"
              :is-valid="hasError($v.bankInfo.TpAccountId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="4">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              :addLabelClasses="getLabelClasses"
              :label="$t('label.currency')"
              :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9' }"
              :options="currencies"
              :disabled="isEmpty(currencies)"
              :value.sync="$v.bankInfo.CurrencyId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.CurrencyId)"
              :is-valid="hasError($v.bankInfo.CurrencyId)"
            >
            </CSelect>
          </CCol>          
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.accountNumber')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              v-uppercase
              :label="$t('label.accountNumber')"
              maxlength="50"
              size="sm"
              v-model.trim="$v.bankInfo.AccountNumber.$model"
              :invalid-feedback="errorMessage($v.bankInfo.AccountNumber)"
              :is-valid="hasError($v.bankInfo.AccountNumber)"
            />
          </CCol>
          <CCol sm="4">
            <div v-if="edit">
              <CSelect
                :value.sync="bankInfo.Status"
                :is-valid="statusSelectColor"
                :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8' }"
                size="sm"
                :label="$t('label.status')"
                :options="statusOptions"
                addLabelClasses="text-right"
              />
            </div>
          </CCol>
          <CCol sm="8" class="d-flex align-items-center justify-content-end">
            <CButton v-if="!edit" size="sm" color="add" shape="square" class="mr-2" @click.prevent="submit"
              v-c-tooltip="{
                content: $t('label.add'),
                placement: 'top-end'
              }"
            >
              <CIcon name="checkAlt" />
            </CButton>
            <CButton v-if="edit" size="sm" color="add" shape="square" class="mr-2" @click.prevent="confirmation"
              v-c-tooltip="{
                content: $t('label.add'),
                placement: 'top-end'
              }"
            >
              <CIcon name="checkAlt" />
            </CButton>
            <CButton size="sm" color="wipe" shape="square" @click.prevent="clearInputs"
              v-c-tooltip="{
                content: $t('label.clearFilters'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </CCol>
        </CRow>
        <CRow v-if="type == 'P2P'">
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.phone')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              v-uppercase
              :label="$t('label.phone')"
              maxlength="15"
              size="sm"
              v-model.trim="$v.bankInfo.P2PPhone.$model"
              :invalid-feedback="errorMessage($v.bankInfo.P2PPhone)"
              :is-valid="hasError($v.bankInfo.P2PPhone)"
            />
          </CCol>
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.IdNumber')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              :label="$t('label.IdNumber')"
              maxlength="10"
              size="sm"
              v-model.trim="$v.bankInfo.BankInfoNumberId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.BankInfoNumberId)"
              :is-valid="hasError($v.bankInfo.BankInfoNumberId)"
            >
              <template #prepend>
                <CSelect
                  :value.sync="numberIdLetter"
                  :options="ciTypeOptions"
                  class="mr-2 mb-0"
                  size="sm"
                />
              </template>
            </CInput>
          </CCol>
          <CCol sm="4">
            <CInput
              :addLabelClasses="getLabelClasses"
              :placeholder="$t('label.alias')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              v-uppercase
              :label="$t('label.alias')"
              maxlength="50"
              size="sm"
              v-model.trim="$v.bankInfo.AccountHolder.$model"
              :invalid-feedback="errorMessage($v.bankInfo.AccountHolder)"
              :is-valid="hasError($v.bankInfo.AccountHolder)"
            />
          </CCol>
          <CCol sm="4">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              :addLabelClasses="getLabelClasses"
              :label="$t('label.bank')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              :options="banks"
              :disabled="isEmpty(banks)"
              :value.sync="$v.bankInfo.BankId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.BankId)"
              :is-valid="hasError($v.bankInfo.BankId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="4">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              :addLabelClasses="getLabelClasses"
              :label="$t('label.currency')"
              :horizontal="{
                label: 'col-sm-12 col-lg-3',
                input: 'col-sm-12 col-lg-9',
              }"
              :options="currencies"
              :disabled="isEmpty(currencies)"
              :value.sync="$v.bankInfo.CurrencyId.$model"
              :invalid-feedback="errorMessage($v.bankInfo.CurrencyId)"
              :is-valid="hasError($v.bankInfo.CurrencyId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="4">
            <div v-if="edit">
              <CSelect
                :value.sync="bankInfo.Status"
                :is-valid="statusSelectColor"
                :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9' }"
                size="sm"
                :label="$t('label.status')"
                :options="statusOptions"
                addLabelClasses="text-right"
              />
            </div>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end">
            <CButton v-if="!edit" size="sm" color="add" shape="square" class="mr-2" @click.prevent="submit"
              v-c-tooltip="{
                content: $t('label.add'),
                placement: 'top-end'
              }"
            >
              <CIcon name="checkAlt" />
            </CButton>
            <CButton v-if="edit" size="sm" color="add" shape="square" class="mr-2" @click.prevent="confirmation"
              v-c-tooltip="{
                content: $t('label.add'),
                placement: 'top-end'
              }"
            >
              <CIcon name="checkAlt" />
            </CButton>
            <CButton size="sm" color="wipe" shape="square" @click.prevent="clearInputs"
              v-c-tooltip="{
                content: $t('label.clearFilters'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </CCol>
        </CRow>
      </CCard>
    </CCollapse>
  </div>
</template>
<script>
import {BankAccountValidations, BankP2PValidations} from "@/_validations/staff/BankConfigValidations";
import UpperCase from "@/_validations/uppercase-directive";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";

//DATA
function data() {
  return {
    collapse: false,
    P2PIdTp: '0BDDE2E6-BA6A-44A8-8CB1-9C10C2784FAA',
    accountTp: '8E681E34-5032-488C-A0F6-A13F1FEFE994',
    bankInfo: {
      CompanyStaffBankInfoId: "",
      CurrencyId: "",
      TpAccountId: "",
      BankId: "",
      TpPaymentId: "",
      AccountNumber: "",
      AccountHolder: "",
      BankInfoNumberId: "",
      P2PPhone: "",
      Status: 1
    },
    numberIdLetter: 'V',
    oldStatus: '',
  };
}

// METHODS
function isEmpty(arr) {
  return arr.length == 0;
}
function clearInputs() {
  let arrKeys = Object.keys(this.bankInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.bankInfo[arrKeys[i]] = 1;
        break;
      default:
        this.bankInfo[arrKeys[i]] = '';
    }
  }
  this.numberIdLetter = 'V';
  this.oldStatus = 1;
  this.$v.$reset();
  this.$emit('clear', this.type);
}
function submit() {
  try {
    if(this.needValidation)
      this.$v.bankInfo.$touch();

    if (this.$v.bankInfo.$error || !this.needValidation) {
      throw this.$t("label.errorsPleaseCheck");
    }
    
    if(!this.edit) {
      this.$emit("submit", {
        ...this.bankInfo,
        BankInfoNumberId: `${this.numberIdLetter}${this.bankInfo.BankInfoNumberId}`,
        TpPaymentId: this.type == 'account' ? this.accountTp : this.P2PIdTp,
      });
    } else {
      this.$emit("update", {
        payment: {
          ...this.bankInfo,
          BankInfoNumberId: `${this.numberIdLetter}${this.bankInfo.BankInfoNumberId}`,
        },
        type: this.type,
      });
    }
    this.clearInputs();
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function setForm(config) {
  if(!config) return;

  this.collapse = true;
  let arrKeys = Object.keys(this.bankInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "BankInfoNumberId":
        let arrId = config[arrKeys[i]].split(/[VEP]/);
        this.numberIdLetter = arrId.length == 2 ? arrId[0] : 'V';
        this.bankInfo[arrKeys[i]] = arrId.length == 2 ? arrId[1] : arrId[0];
        break;
      case "Status":
        this.bankInfo.Status = config[arrKeys[i]] == 'ACTIVO' ? 1 : 0;
        this.oldStatus = config[arrKeys[i]] == 'ACTIVO'? 1 : 0;
        break;
      default:
        this.bankInfo[arrKeys[i]] = config[arrKeys[i]] ? config[arrKeys[i]] : "";
    }
  }
  this.$v.$touch();
}
function confirmation() {
  try {
    this.$v.bankInfo.$touch();
    if (this.$v.bankInfo.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.statusConfirmation(this.oldStatus, this.bankInfo.Status, this.submit, `${this.bankInfo.AccountHolder}`);
  } catch(e) {
    this.notifyError({ text: e });
  }
}

//COMPUTED
function getTypeName() {
  if(this.type == 'account')  return this.$t('label.bankAccount');
  if(this.type == 'P2P')  return this.$t('label.P2P');

  return '';
}
function ciTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
  ];
}
function needValidation() {
  if(this.type == 'account')
    return (
      this.bankInfo.BankInfoNumberId != '' ||
      this.bankInfo.AccountNumber != '' ||
      this.bankInfo.AccountHolder != '' ||
      this.bankInfo.CurrencyId != '' ||
      this.bankInfo.BankId != '' ||
      this.bankInfo.TpAccountId != ''
    );

  if(this.type == 'P2P')
    return (
      this.bankInfo.BankInfoNumberId != ''||
      this.bankInfo.P2PPhone != ''||
      this.bankInfo.AccountHolder != ''||
      this.bankInfo.CurrencyId != '' ||
      this.bankInfo.BankId != ''
    );

  return false;
}
function getLabelClasses() {
  if(this.needValidation)
    return 'required text-right';
  else
    return 'text-right';
}
function statusSelectColor(){
  return this.bankInfo.Status === 1;
}

export default {
  name: "bank-collapse",
  mixins: [ModalMixin],
  data,
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    banks: {
      type: Array,
      default: () => []
    },
    tpAccounts: {
      type: Array,
      default: () => []
    },
    currencies: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: null,
    }
  },
  validations() {
    if(this.type == 'account')
      return BankAccountValidations(this.needValidation);
    else
      return BankP2PValidations(this.needValidation);
  },
  directives: UpperCase,
  watch: {
    needValidation: function (val) {
      this.$emit("validation", val);
      if(!val) this.$v.$reset();
    },
    edit: function (val) {
      if(val)
        this.setForm(this.config);
    },
  },
  methods: {
    isEmpty,
    clearInputs,
    submit,
    setForm,
    confirmation,
  },
  computed: {
    getTypeName,
    ciTypeOptions,
    needValidation,
    getLabelClasses,
    statusSelectColor
  },
};
</script>
<style scoped>
.bank-collapse {
  background-color: #e0e0e0;
  padding: 5px 0;
  text-decoration: none;
  color: #000;

  width: 230px;
  position: relative;
  display: flex;
  justify-content: center;
}

.arrow-icon {
  position: absolute;
  right: 4%;
  top: 32%;
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(1800deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
