<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasVesselInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="vessel">
                  <table>
                    <tbody>
                      <tr>
                        <td class="limit-field-25">
                          <div>
                            <strong>{{ $t("label.DFrom") }}: </strong
                            >{{ item.DateFrom }}
                          </div>
                          <div>
                            <strong>{{ $t("label.DTo") }}: </strong
                            >{{ item.DateTo }}
                          </div>
                        </td>
                        <td class="limit-field-25">
                          <strong>LOA (MTR):</strong
                          >{{ formatNumber(getDato(vessel[0].Loa), 2) }}
                        </td>
                        <td class="limit-field-25">
                          <strong
                            >{{ $t("label.bowDraft") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].DraftBow), 2) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.LWL") + " (MTR)" }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Lwl), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.gross")+' '+$t("label.metricTonnageAcronym") }}: </strong
                          >{{ formatNumber(getDato(vessel[0].Gross), 2) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>POL: </strong
                          >{{ getDato(vessel[0].PortOriginName) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>POD: </strong
                          >{{ getDato(vessel[0].PortDestinationName) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.fuelOilCapacity") + " (M³)"}}: </strong
                          >{{ formatNumber(getDato(vessel[0].FuelOilCapacity), 2) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.commodity")}}: </strong
                          >{{ getDato(commodity[0].CommodityName) }}
                        </td>
                        <!--<td class="limit-field-25">
                          <strong> {{ $t("label.density") }}: </strong
                          >{{ formatNumber(getDato(commodity[0].Density), 2) }}
                        </td>-->
                        <td class="limit-field-25">
                          <strong>{{$t("label.quantity")}}: </strong
                          >{{ formatNumber(getDato(commodity[0].Weight), 2) }}
                        </td>
                        <td class="limit-field-25">
                          <strong>{{ $t("label.StowageFactor") }}: </strong
                          >{{ formatNumber(item.StowageFactor, 4) }}
                        </td>
                      </tr>
                      <!-- <tr
                        v-for="(mercancia, index) in commodity"
                        :key="`${mercancia.CommodityId}-${index}`"
                      >
                        <td class="limit-field-40">
                          <strong>{{ $t("label.commodity") }}: </strong
                          >{{ mercancia.CommodityName }}
                        </td>
                        <td class="limit-field-20">
                          <strong
                            >{{ $t("label.quantity") + " (TON)" }}: </strong
                          >{{ formatNumber(mercancia.QuantityTon) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t("label.density") }}: </strong
                          >{{ `${mercancia.Density}`.replace(".", ",") }}
                        </td>
                        <td class="limit-field-20">#{{ mercancia.NroBl }}</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="transversal-view">
                <transversal-view :billOfLading="positions" pdf manifestPdf :VesselType="itinerarySelected.TpVesselMasterId"/>
              </div>
              <div class="holds-info">
                <table class="holds-table">
                  <thead>
                    <tr>
                      <th colspan="1" ></th>
                      <th colspan="2" class="header-bg text-center">
                        {{ $t('label.PermissibleLoanOn') }}
                      </th>
                      <th colspan="1" class="header-bg text-center">
                        {{ $t('label.CertifiedCapacity') }}
                      </th>
                    </tr>
                    <tr class="text-table-right">
                      <th class="text-table-left" style="width: 25%">{{$t('label.hold')}}</th>
                      <th style="width: 25%">{{$t('label.bale')+' M³'}}</th>
                      <th style="width: 25%">{{$t('label.bale')+' '+$t("label.metricTonnageAcronym")}}</th>
                      <th style="width: 25%">{{$t('label.Loaded')+' '+$t("label.metricTonnageAcronym")}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(hold) in positionHolds" :key="hold.VesselHoldId">
                      <td class="text-table-left">{{ hold.VesselHoldName }}</td>
                      <td>{{ formatNumber(hold.Bale, 2) }}</td>
                      <td>{{ formatNumber(hold.BaleTon, 2) }}</td>
                      <td>{{ formatNumber(hold.OccupiedTon, 2) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-table-left">TOTAL</td>
                      <td>{{ getHoldsTotal.Bale }}</td>
                      <td>{{ getHoldsTotal.BaleTon }}</td>
                      <td>{{ getHoldsTotal.OccupiedTon }}</td>
                    </tr>
                  </tfoot>
                </table>

                <table class="departure-table">
                  <thead>
                    <tr>
                      <th colspan="2" class="header-bg text-center">
                        {{$t('label.departure')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CARGO</td>
                      <td>{{ (item.Cargo) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.ballast')}}</td>
                      <td>{{ formatNumber(item.Ballast[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.fuelOil')}}</td>
                      <td>{{ formatNumber(item.FuelOil[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.dieselOil')}}</td>
                      <td>{{ formatNumber(item.DieselOil[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.freshWater')}}</td>
                      <td>{{ formatNumber(item.FreshWater[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.constant')}}</td>
                      <td>{{ formatNumber(item.Constant[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.Deadweight')}}</td>
                      <td>{{ formatNumber(item.DeadWeight, 2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="holds-info">
                <table class="DraftDepth-table">
                  <thead>
                    <tr>
                      <th colspan="3" class="header-bg text-center">
                        {{$t('label.departure')+' - '+$t('label.draft')}}
                      </th>
                    </tr>
                    <tr class="text-table-right">
                      <th></th>
                      <th>I</th>
                      <th>F</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>A:</strong></td>
                      <td>{{ formatNumber(item.DraftAft[0].I, 2) }}</td>
                      <td>{{ formatNumber(item.DraftAft[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td><strong>M:</strong></td>
                      <td>{{ formatNumber(item.DraftMidShip[0].I, 2) }}</td>
                      <td>{{ formatNumber(item.DraftMidShip[0].F, 2) }}</td>
                    </tr>
                    <tr>
                      <td><strong>F:</strong></td>
                      <td>{{ formatNumber(item.DraftForward[0].I, 2) }}</td>
                      <td>{{ formatNumber(item.DraftForward[0].F, 2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransversalView from "@/pages/buque/bays/transversal-view";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function getDato(value) {
  if (this.vessel.length == 0) return "";

  return value ? value : "";
}
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

//Computeds:
function hasVesselInfo() {
  return this.vessel.length == 1;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function footerText() {
  return this.$t("label.draftInspections");
}
function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromItinerary() {
  if (this.vessel.length == 0) return "";
  let previousDate;
  let laterDate;
  let vesselName = this.vessel[0].VesselName;
  let voyage = this.vessel[0].Voyage;
  let imo = this.vessel[0].Imo;
  let fecha = "";

  if(this.itinerarySelected.Ata!=undefined){ 
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = "ATA:"+fecha;
  }

  if(this.itinerarySelected.Ata==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = "ETA:"+fecha;
  }

  if(this.itinerarySelected.Atd!=undefined){  
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = "ATD:"+fecha;
  }

  if(this.itinerarySelected.Atd==undefined){
    this.vessel[0].Etd=DateFormater.formatDateTimeWithSlash(this.vessel[0].Etd);
    laterDate = "ETD:"+this.vessel[0].Etd;
  }

  return `${vesselName} ${imo} / V:${voyage} - ${previousDate} ${laterDate}`;
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function totalPages() {
  return 1;
}

function getHoldsTotal() {
 let totals = {
    bale: 0,
    baleTon: 0,
    occupiedTon: 0,
  };
  if (this.positionHolds.length == 0) return totals;

  for (let index = 0; index < this.positionHolds.length; index++) {
    totals.bale += this.positionHolds[index].Bale;
    totals.baleTon += this.positionHolds[index].BaleTon;
    totals.occupiedTon += this.positionHolds[index].OccupiedTon;
  }

  return {
    Bale: this.formatNumber(totals.bale, 2),
    BaleTon: this.formatNumber(totals.baleTon, 2),
    OccupiedTon: this.formatNumber(totals.occupiedTon, 2),
  };
}

export default {
  name: "draft-inspection-view-pdf",
  props: {
    vessel: {
      type: Array,
      default: () => [],
    },
    commodity: {
      type: Array,
      default: () => [],
    },
    positions: {
      type: Array,
      default: () => [],
    },
    positionHolds: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: null,
    },
     itinerarySelected: {
      type: Object,
      default: null,
    },
  },
  mixins: [],
  components: {
    TransversalView,
  },
  data,
  created,
  methods: {
    getDato,
    formatNumber,
  },
  computed: {
    footerText,
    currentDateTime,
    titleFromItinerary,
    hasLogo,
    hasVesselImg,
    loginName,
    hasVesselInfo,
    totalPages,
    getHoldsTotal,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  /* height: 75%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  /* margin-top: 10px; */
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  /* font-size: 12px !important; */
  font-size: 10px !important;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-25 {
  width: 25% !important;
}
.limit-field-30 {
  width: 32% !important;
}
.limit-field-40 {
  width: 40% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .transversal-view {
  /* border: 1px solid #000; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 10px; */
  overflow: hidden;
}

/* *************************************************** */
/* Tablas de Info */
/* *************************************************** */
.holds-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.holds-table {
  width: 65%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.holds-table,
table.holds-table td,
table.holds-table th {
  border: 1px solid #000;
}
table.holds-table td,
table.holds-table th {
  padding: 0 5px;
}
table.holds-table td {
  text-align: right;
}
table.holds-table tfoot {
  font-weight: bold;
}

.text-table-left {
  text-align: left !important;
}
.text-table-right {
  text-align: right !important;
}
.header-bg {
  background-color: #bfbfbf;
}

.departure-table {
  width: 20%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.departure-table,
table.departure-table td,
table.departure-table th {
  border: 1px solid #000;
}
table.departure-table td,
table.departure-table th {
  padding: 0 5px;
}
table.departure-table td:nth-child(2) {
  text-align: right;
}
table.departure-table td:nth-child(1),
table.departure-table td:nth-child(2) {
  width: 50%;
}

.DraftDepth-table {
  width: 20%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.DraftDepth-table,
table.DraftDepth-table td,
table.DraftDepth-table th {
  border: 1px solid #000;
}
table.DraftDepth-table td,
table.DraftDepth-table th {
  padding: 0 5px;
}
table.DraftDepth-table td {
  text-align: right;
}
table.DraftDepth-table tfoot {
  font-weight: bold;
}

 .bl-table {
    width: 100% !important;
    font-size: 10px !important;
  }
  .hold-commodity td:nth-child(-n+4) {
    vertical-align: top;
    text-align: left;
  }
  .bl-info {
    min-height: 1px;
    height: 100%;
    font-size: 10px !important;
    border-collapse: collapse;
  }
  table.bl-info td {
    border: 1px solid #000;
    text-align: center !important;
    vertical-align: middle !important;
  }
  table.bl-info td:first-child {
    border-left: none !important;
  }
  table.bl-info td:last-child {
    border-right: none !important;
  }
  table.bl-info tr:first-child td {
    border-top: none;
  }
  table.bl-info tr:last-child td {
    border-bottom: none;
  }
  .bl-info .bl-color {
    width: 100%;
    min-height: 12px;
  }
  .bl-same-width {
    width: 12%;
  }
</style>