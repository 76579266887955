<template>
  <div class="container-fluid top-menos">
    <CModalExtended
      :title="$t('label.positioningList')"
      color="dark"
      size="xl"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <div class="col-sm-12 px-0">
        <CCard v-if="PositionsList?.PositioningJson&&PositionsList?.PositioningJson.length!=0">
          <CCardHeader class="bg-dark text-white">
            <CRow>
              <CCol sm="11" class="mt-1">
                {{$t('label.positionedContainer')}}
              </CCol>
              <CCol sm="1" class="px-0">
                <CButton color="edit" size="sm" class="float-right" @click="PositionTable = !PositionTable" v-c-tooltip="{content: $t('label.dataTable')}" >
                  {{ PositionTable ? this.$t("label.close") : this.$t("label.toView") }}
                  <CIcon :name="PositionTable ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt'"/>
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CCollapse :show="PositionTable">
              <CCol sm="12" class="px-0">
                <dataTableExtended
                  class="align-center-row-datatable"
                  size="lg"
                  :fields="fields(true)"
                  :items="computedList(PositionsList?.PositioningJson, false)"
                  :items-per-page="5"
                  column-filter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  sorter
                >
                </dataTableExtended>
              </CCol>
            </CCollapse>
          </CCardBody>
        </CCard>
        <CCard v-if="PositionsList?.NotValidPositionsJson&&PositionsList?.NotValidPositionsJson.length!=0">
          <CCardHeader class="bg-dark text-white">
            <CRow>
              <CCol sm="11" class="mt-1">
                {{$t('label.notValidMovemenet')}}
              </CCol>
              <CCol sm="1" class="px-0">
                <CButton color="edit" size="sm" class="float-right" @click="RepositionedTable = !RepositionedTable" v-c-tooltip="{content: $t('label.dataTable')}" >
                  {{ RepositionedTable ? this.$t("label.close") : this.$t("label.toView") }}
                  <CIcon :name="RepositionedTable ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt'"/>
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CCollapse :show="RepositionedTable">
              <CCol sm="12" class="px-0">
                <dataTableExtended
                  class="align-center-row-datatable"
                  size="lg"
                  :fields="fields(false)"
                  :items="computedList(PositionsList?.NotValidPositionsJson, true)"
                  :items-per-page="5"
                  column-filter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  sorter
                >
                </dataTableExtended>
              </CCol>
            </CCollapse>
          </CCardBody>
        </CCard>
      </div>
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    modalActive: false,
    PositionTable: false,
    RepositionedTable: false,
  };
}

//methods
function computedList(List, PositionsJson) {
  if (List) {
    let _lang = this.$i18n.locale;
    return List.map((item, index) => {
      let Position = '';

      item.PositionJson?.map((item)=>{
          Position = `${Position}${item?.SlotCode}`
      })

      return {
        ...item,
        Nro: index+1,
        ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
        DaysInYard: item.DaysInYardEn ? _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEn||'' : '',
        ShippingLineCode: item.ShippingLineCode ?? '',
        Error: item?.ResponseJson ? item.ResponseJson.map(error => {
          return _lang=='en' ? error.ErrorMessageEn||'' : error.ErrorMessageEs||'';
        }).join(', ') : '',
        PreviousPos: Position ?? '',
        Area: item?.YardAreaName ?? '',
        _classes: PositionsJson ? 'Alert-Row' : item.StatusItem == 200 ? 'Correct-Row' : 'Error-Row',
      };
    });
  }
}

function fields(PositionsList){
  let fields = [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width:45px; text-align:center', filter: false},
    { label: this.$t('label.container'), key: 'ContainerCode', _classes: 'text-center', _style: 'min-width:200px;',},
    { label: this.$t('label.previousPosition'), key: 'PreviousPos', _classes: 'text-center', _style:'min-width:180px' },
    { label: 'AREA', key: 'Area', _classes: 'text-center', _style:'min-width:100px' },
    { label: this.$t('label.type'), key: 'TpCargoDetailCode', _classes: 'text-center', _style:'min-width:80px' },
    { label: this.$t('label.newShippingLine'), key: 'ShippingLineCode', _classes: 'text-center', _style:'min-width:150px' },
    { label: this.$t('label.ReceptionDate'), key: 'ComputedReception', _classes: 'text-center', _style:'min-width:155px' },
    { label: this.$t('label.daysInYard'), key: 'DaysInYard', _classes: 'text-center', _style:'min-width:130px' },
  ];
  if (PositionsList) {
    fields.push({ label: 'ERROR', key: 'Error', _classes: 'text-center', _style:'min-width:180px' })
  }
  return fields;
}

function closeModal() {
  this.$emit('Close');
}

export default {
  name: 'modal-positions-list',
  props: { modal: Boolean, PositionsList: Object },
  data,
  mixins: [
    GeneralMixin, 
  ],
  directives: UpperCase,
  methods: {
    computedList,
    fields,
    closeModal,
  },
  watch: {
    modal: function (NewVal) {
      this.PositionTable = false;
      this.RepositionedTable = false;
      this.modalActive = NewVal;
    }
  },
  
};
</script>