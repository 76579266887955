import { required,  maxLength, } from "vuelidate/lib/validators";

export default () => {
  return {
    Crane: { 
      CraneAlias: { required, maxLength: maxLength(10) },
      FgOperational: { required },
      Status: { required },
    },
  }
}