
<template>
    <div>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars"/>
        <div>
            <CCollapse :show="!$store.state.planificacionestiba.collapsePlanificacion">
                <appItinerario :loading="apiStateLoading"></appItinerario>
            </CCollapse>
            <CCollapse :show="$store.state.planificacionestiba.collapsePlanificacion">
                <div class="planificacion-body">
                    <div class="wrapper d-flex align-items-stretch">
                        <nav id="sidebar-planificacion-estiba">
                            
                            <div class="img bg-wrap text-center py-4" :style="'background-image: url('+imageSrc(dataBuque.VesselRoute)+')'">
                            
                            </div>
                            
                            <!--
                            <div class="img bg-wrap text-center py-4" style="background-image: url('https://conceptodefinicion.de/wp-content/uploads/2020/11/mar.jpg');">
                                <div class="user-logo">
                                    <div class="img" :style="'background-image: url('+imageSrc(dataBuque.VesselRoute)+')'"></div>
                                </div>
                            </div>
                            -->
                            <div class="p-3">
                                <ul class="list-unstyled components mb-5">
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="10">
                                                    <h5 class="text-white mt-0">
                                                        {{dataBuque.VesselName}}                                                    
                                                    </h5>
                                                    <h6 class="text-white mt-0">
                                                        {{ dataBuque.TpVesselName }}                                                    
                                                    </h6>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                   {{$t('label.flag')}}
                                                <!--/CCol>
                                                <CCol sm="3" class="d-flex align-items-center"-->
                                                    <img
                                                        :src="imageSrc(dataBuque.VesselFlagRoute)"
                                                        alt="France"
                                                        width="40px"
                                                        height="50px"
                                                        class="img-thumbnail ml-1"
                                                        
                                                    />
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{dataBuque.CountryName}}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    MMSI
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Mmsi }}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    IMO
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Imo }}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                        <li v-if="bulkCarrier == 1">
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    TEUS
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{dataBuque.VesselTeus}}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    LOA
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{dataBuque.Loa}}&nbsp;MTS
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{$t('label.stern')}}
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Popa }}&nbsp;MTS
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{$t('label.bow')}}
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Proa }}&nbsp;MTS
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{$t('label.gross')}}
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Gross }}&nbsp;TON
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    ETA&nbsp;<CIcon name="cil-arrow-right"/>
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Eta }}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-menu">
                                            <CRow>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    ETD&nbsp;<CIcon name="cil-arrow-left"/>
                                                </CCol>
                                                <CCol sm="6" class="d-flex align-items-center">
                                                    {{ dataBuque.Etd }}
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                            
                        <!-- Page Content  -->
                        
                        <div id="content">
                            <nav
                                class="navbar navbar-expand-lg navbar-light bg-light"
                                :class="{'no-margin': isHidden ? true : null}"
                            >
                                <div class="container-fluid">

                                    <button type="button" id="sidebarCollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
                                        <CIcon  class="text-white" :name="checkIconToggle"/>
                                        <span class="sr-only">{{checkTextToggle}}</span>
                                    </button>
                                    <!--
                                    <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <CIcon name="cil-chevron-circle-left"/>
                                    </button>
                                    -->
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                                            <li class="nav-item active d-flex align-items-center justify-content-end" style="margin-right: 10px;">
                                                <a>{{$t('label.service')}}</a>
                                            </li>
                                            <li class="nav-item d-flex justify-content-center align-items-center" style="margin-right: 10px;">
                                                <input disabled type="text" class="form-control" v-model="dataBuque.ServiceCode" placeholder="">
                                            </li>
                                            <li class="nav-item d-flex align-items-center justify-content-end" style="margin-right: 10px;">
                                                <a>{{$t('label.itinerary')}}</a>
                                            </li>
                                            <li class="nav-item d-flex justify-content-center align-items-center w-50" style="margin-right: 10px;">
                                                <input disabled type="text" class="form-control" v-model="dataBuque.DatePlanning" placeholder="" style="min-width: 350;">
                                            </li>
                                            <li v-on:click="closeCollapse()" class="nav-item d-flex align-items-center justify-content-end text-white">
                                                <CIcon name="x"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                            <!-- Navbar Interno -->
                            <CCollapse :show="isHidden">
                                <nav-details />
                            </CCollapse>
                            <!-- Contenido Interno -->
                            <div class="container-fluid contenido-especial">
                                <CCollapse :show="!isHidden">
                                    <CRow>
                                        <CCol sm="12">
                                            <CustomTabs :active-tab="$store.state.planificacionestiba.tabIndex" @update:activeTab="handleTab">
                                                <CustomTab 
                                                    :disabled="apiStateLoading" 
                                                    title="GENERAL" 
                                                    icon="general" 
                                                    valueItemTab="0"                                                   
                                                >
                                                    <appGeneral :loading="apiStateLoading"></appGeneral>
                                                </CustomTab>
                                                <CustomTab  
                                                    v-if="bulkCarrier == 1 && collapsePlanificacion" 
                                                    :style="verificarTabs" 
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    icon="electronicData" 
                                                    :title="$t('label.electronicData')"
                                                    valueItemTab="1"
                                                >
                                                    <archivos-electronicos :loading="apiStateLoading"/>
                                                </CustomTab>
                                                <CustomTab 
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    :title="$t('label.billOfLading')"
                                                    v-if="(bulkCarrier == 2 || FgGeneralCargo) && collapsePlanificacion"
                                                    :style="verificarTabs"
                                                    icon="billOfLading" 
                                                    valueItemTab="1"
                                                >
                                                    <appManifiesto :loading="apiStateLoading"></appManifiesto>
                                                </CustomTab>
                                                <CustomTab  
                                                    v-if="collapsePlanificacion" 
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    icon="crane" 
                                                    :title="$t('label.crane')+'s'"
                                                    valueItemTab="2"
                                                >
                                                    <appGruas :loading="apiStateLoading"></appGruas>
                                                </CustomTab>
                                                <CustomTab 
                                                    v-if="collapsePlanificacion" 
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    icon="machinery" 
                                                    :title="$t('label.machineries')"
                                                    valueItemTab="3"
                                                >
                                                    <appMaquinas :loading="apiStateLoading"></appMaquinas>
                                                </CustomTab>
                                                <CustomTab  
                                                    v-if="bulkCarrier == 2 && collapsePlanificacion" 
                                                    :style="verificarTabs"
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    icon="logistic"
                                                    :title="$t('label.logistics')"
                                                     valueItemTab="8"
                                                >
                                                    <appLogistica :loading="apiStateLoading"></appLogistica>
                                                </CustomTab>
                                                <CustomTab
                                                    v-if="collapsePlanificacion" 
                                                    :style="verificarTabs"
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification || disabledTabStateIdTpVesselId" 
                                                    icon="resources" 
                                                    :title="$t('label.resource')+'S'"
                                                    valueItemTab="4"
                                                    >
                                                    <appRecurso />
                                                </CustomTab>
                                                <CustomTab 
                                                    v-if="collapsePlanificacion" 
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    icon="supplie" 
                                                    :title="$t('label.supplies')"
                                                    valueItemTab="5"
                                                    >
                                                    <appInsumo />
                                                </CustomTab>
                                                <!--<CustomTab :disabled="apiStateLoading || disabledTabStateIdPlanification" icon="cil-browser" :title="$t('label.projection')">
                                                    <appProyeccion :loading="apiStateLoading"></appProyeccion>
                                                </CustomTab>-->
                                                <CustomTab 
                                                    v-if="collapsePlanificacion" 
                                                    :style="verificarTabs"
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    :title="$t('label.devices')"
                                                    valueItemTab="6"
                                                >
                                                    <template #title>
                                                        <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Device.svg" alt="Card image cap">
                                                        {{$t('label.devices')}}           
                                                    </template>
                                                    
                                                    <asignar-dispositivos :loading="apiStateLoading"/>
                                                </CustomTab>
                                                <CustomTab  
                                                    v-if="bulkCarrier == 1 && collapsePlanificacion" 
                                                    :style="verificarTabs"
                                                    :disabled="apiStateLoading || disabledTabStateIdPlanification" 
                                                    :title="$t('label.filesTransferred')"
                                                    valueItemTab="7"
                                                >
                                                    <template #title>
                                                        <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Transferencia.svg" alt="Card image cap">
                                                        {{$t('label.filesTransferred')}}                
                                                    </template>
                                                    <transferencia-archivos :loading="apiStateLoading"/>
                                                </CustomTab>
                                            </CustomTabs >
                                        </CCol>
                                    </CRow>
                                </CCollapse> 
                            </div>
                        </div>
                    </div>
                    <br><br>
                </div>
            </CCollapse>
        </div>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import { DateFormater } from '@/_helpers/funciones';
    
    import appItinerario from './app-itinerario';
    import appGeneral from './app-general';
    import appGruas from './app-gruas';
    import appMaquinas from './app-maquinas';
    import appInsumo from './insumo/index-insumo';
    import appRecurso from './recurso/tabs-recurso';
    import appProyeccion from './app-proyeccion';
    import archivosElectronicos from './archivos/form';
    import appLogistica from './logistica/index';
    import navDetails from './archivos/detalles/navbar';
    import asignarDispositivos from './dispositivos/asignar-dispositivo';
    import transferenciaArchivos from './transferencia/transferencia-archivos';
    import appManifiesto from './app-manifiesto-carga';
    //mounted
    function mounted(){
        document.getElementById("sidebarCollapseSidebar").onclick = function() {
            document.getElementById("sidebar-planificacion-estiba").classList.toggle('active');
        }
    }
    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            dataBuque: {
                CountryName:'',
                VesselTeus:'',
                VesselName:'',
                VesselFlagRoute:'',
                VesselRoute:'',
                ServiceCode:'',
                DatePlanning: '',
                TpVesselName:'',
                TpVesselId: '',
                Gross:'',
                Loa:'',
                Teus:'',
                Popa:'',
                Proa:'',
                Ata: "",
                Atd: "",
                Eta:'',
                Etd:'',
                Imo:'',
                Mmsi:'',
            },
            activePage: "Canada",
            collapsed: false
        }
    }
    //created
    function created() {
        this.$store.dispatch('planificacionestiba/getItininerarylist',{CompanyBranchId:this.branch.CompanyBranchId});
   }
    //Methods
    function handleTab(tab) {
        this.$store.state.planificacionestiba.tabIndex = tab;
    }

    function imageSrc(Src){
        if(Src === ''){
            return this.srcError;
        }
        else{
            return `${this.$store.getters["connection/getBase"]}${Src}`;
        }
    }
    function closeCollapse(){
        if(!this.apiStateLoading){
            this.$store.state.planificacionestiba.planificacionId="";
            this.$store.state.planificacionestiba.ItineraryId=0;
            this.$store.state.planificacionestiba.ModalityId = '';
            this.$store.state.planificacionestiba.TpVesselId = '';
            this.$store.state.planificacionestiba.TpVesselMasterId = '';
            this.$store.state.planificacionestiba.ConditionPlanningId = '';
            this.$store.state.planificacionestiba.tabIndex=0;
            this.$store.state.planificacionestiba.collapsePlanificacion = false;
            this.$store.state.planificacionestiba.newUnit = 0;
            this.$store.state.planificacionestiba.myDataPlanningDoc = [];
            this.$store.state.planificacionestiba.myDataTpOperation = [];
            this.$store.state.planificacionestiba.myDataTpCondition = [];
            this.$store.state.planificacionestiba.myDataTpVessel = [];
            this.$store.state.planificacionestiba.myDataModality = [];
            this.$store.state.planificacionestiba.myDataWeighingModality = [];
            this.$store.commit("planificacionestiba/menuVisibility", {check:false,type:3});
            this.$store.dispatch('planificacionestiba/getItininerarylist',{CompanyBranchId:this.branch.CompanyBranchId});
        }
    }
    function checkSideBarToggle(){
        this.collapsed = !this.collapsed;
    }
    //computed
    function verificarTabs() {
        return this.ConditionPlanningId=='52FE865B-6267-4EF4-833D-4C7B20CD2F35' ? 'display: none;' : 'text-transform: uppercase;';
    }
    function disabledTabStateIdPlanification() {
        return ["", null, undefined].includes(this.planificacionId);
    }
    function disabledTabStateIdTpVesselId(){
        return this.TpVesselId === null || this.TpVesselId === '' || this.TpVesselId === 0;
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    
    function checkIconToggle() {
        if (this.collapsed) {
            return "cil-chevron-right-alt";
        } else {
            return "cil-chevron-left-alt";
        }
    }
    
    function checkTextToggle() {
        if (this.collapsed) {
            return this.$t("label.close");
        } else {
            return this.$t("label.watch");
        }
    }
    

    //watch
    function collapsePlanificacion(newQuestion){
        if(newQuestion){
            if(this.ItineraryId !== 0 || this.ItineraryId !== null){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);

                if(Index > -1){
                    this.dataBuque.DatePlanning = this.myDataItinirary[Index].DatePlanning;
                    this.dataBuque.TpVesselName = this.myDataItinirary[Index].TpVesselName;
                    this.dataBuque.TpVesselId = this.myDataItinirary[Index].TpVesselId;
                    this.dataBuque.Ata =  this.myDataItinirary[Index].Ata ? DateFormater.formatOnlyDateWithSlash(this.myDataItinirary[Index].Ata) : 'N/A' ;
                    this.dataBuque.Atd = this.myDataItinirary[Index].Atd ? DateFormater.formatOnlyDateWithSlash(this.myDataItinirary[Index].Atd) : 'N/A' ;
                    this.dataBuque.Eta =  this.myDataItinirary[Index].Eta ? DateFormater.formatOnlyDateWithSlash(this.myDataItinirary[Index].Eta) : 'N/A' ;
                    this.dataBuque.Etd = this.myDataItinirary[Index].Etd ? DateFormater.formatOnlyDateWithSlash(this.myDataItinirary[Index].Etd) : 'N/A' ;
                    this.dataBuque.VesselName = this.myDataItinirary[Index].VesselName;
                    this.dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;
                    this.dataBuque.VesselRoute = `${this.myDataItinirary[Index].VesselRoute}`;
                    this.dataBuque.ServiceCode = this.myDataItinirary[Index].ServiceCode;
                    this.dataBuque.Imo = this.myDataItinirary[Index].Imo;
                    this.dataBuque.Mmsi = this.myDataItinirary[Index].Mmsi;
                    this.dataBuque.Proa = this.myDataItinirary[Index].Proa;
                    this.dataBuque.Popa = this.myDataItinirary[Index].Popa;
                    this.dataBuque.Teus = this.myDataItinirary[Index].Teus;
                    this.dataBuque.Loa = this.myDataItinirary[Index].Loa;
                    this.dataBuque.VesselTeus = this.myDataItinirary[Index].VesselTeus;
                    this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    this.dataBuque.Gross = this.myDataItinirary[Index].Gross;

                    if (this.dataBuque.TpVesselId == '2A719797-70DB-437B-B55D-58F4177CB87B'){
                        this.$store.state.planificacionestiba.bulkCarrier = 1;
                        this.$store.state.planificacionestiba.FgGeneralCargo = false;
                    }else if (this.dataBuque.TpVesselId == process.env.VUE_APP_BULK_VESSEL_ID){
                        this.$store.state.planificacionestiba.bulkCarrier = 2;
                        this.$store.state.planificacionestiba.FgGeneralCargo = false;
                    }else if (this.dataBuque.TpVesselId == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID){
                        this.$store.state.planificacionestiba.bulkCarrier = 2;
                        this.$store.state.planificacionestiba.FgGeneralCargo = true;
                    }
                    if (!this.planificacionId) {
                        this.$store.dispatch('planificacionestiba/getPlanificacionlist',{StowagePlanningId: this.planificacionId, TpVesselId: this.dataBuque.TpVesselId});
                    }
                    
                }
            }
        }
    }
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.planificacionestiba.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.planificacionestiba.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.planificacionestiba.messageError = '';
                break;
            default:
                break;
        }
    }
    export default {    
        name:'app-planificacion-estiba',
        mounted:mounted,
        created,
        methods:{
            handleTab,
            imageSrc,
            closeCollapse,
            checkSideBarToggle,
        },
        data,
        computed:{
            verificarTabs,
            disabledTabStateIdTpVesselId,
            disabledTabStateIdPlanification,
            apiStateLoading,
            apiStateFormLoading,
            checkTextToggle,
            checkIconToggle,
            ...mapState({
                ConditionPlanningId: state => state.planificacionestiba.ConditionPlanningId,
                messageError : state=> state.planificacionestiba.messageError,
                apiState: state => state.planificacionestiba.apiState,
                apiStateForm: state => state.planificacionestiba.apiStateForm,
                branch: state => state.auth.branch,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                planificacionId: state => state.planificacionestiba.planificacionId,
                collapsePlanificacion: state => state.planificacionestiba.collapsePlanificacion,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                isHidden: state => state.planificacionestiba.isHidden,
                TpVesselId: state => state.planificacionestiba.TpVesselId,
                bulkCarrier: state => state.planificacionestiba.bulkCarrier,
                FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
            })

        },
        components: {
            CustomTabs,
            CustomTab,
            appItinerario,
            appLogistica,
            appGeneral,
            appGruas,
            appMaquinas,
            appInsumo,
            appRecurso,
            archivosElectronicos,
            navDetails,
            appProyeccion,
            asignarDispositivos,
            transferenciaArchivos,
            appManifiesto,
        },
        beforeDestroy(){
            this.closeCollapse();
        },
        watch:{
            apiState,
            apiStateForm,
            collapsePlanificacion,
        }
    }
</script>

<style lang="scss">
    @import 'css/style.scss';
</style>

