<template>
  <CRow class="mt-3">
    <CCol sm="12" class="d-flex justify-content-end align-items-center">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.workShift'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="py-2 center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.workShift'),
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>

    <modal-create-work-shift :modal.sync="modalAdd" @submited="handleSubmit"/>
    <modal-edit-work-shift :modal.sync="modalEdit" :turn-data="turno" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalCreateWorkShift from './modal-create-work-shift';
import ModalEditWorkShift from './modal-edit-work-shift';

function data() {
  return {
    items: [],
    turno: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
  };
}

//Created
function created() {
  this.getTurns();
}

//Methods:
function getTurns() {
  this.isLoading = true;
 
  this.$http.get("Turn-list", { filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item) {
  this.turno = Object.assign({}, item);

  this.modalEdit = true;
}

function handleSubmit(){
  this.getTurns();
}

//Computed:
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,  _style: 'width:1%; text-align:center;',
    },
    { key: 'TurnName', label:this.$t('label.workShift'), _style:'width:25%;', _classes:"text-uppercase"},
    { key: 'StartHour', label: this.$t('label.startHour'), _style:'width:15%; ', _classes:"text-uppercase"},
    { key: 'EndHour', label: this.$t('label.endHour'), _style:'width:15%;', _classes:"text-uppercase"},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"text-uppercase"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%; text-align:center;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%; text-align:center;',  _classes:"text-center"},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}
function formatedItems() {
  let _this = this.$i18n.locale;  
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    TurnName: _this=='en' ? item.TurnNameEn : item.TurnName,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'index-work-shift',
  mixins: [GeneralMixin],
  components: { 
    ModalCreateWorkShift,
    ModalEditWorkShift
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    tap: {
      type: Number,
      default: 0,
    },
  },
  data,
  created,
  methods: {
    getTurns,
    toggleAdd,
    toggleEdit,
    handleSubmit,
  },
  computed: {
    fields,
    formatedItems,
  },
  watch: {
    active: function(newVal) {
      if(newVal){
        this.getTurns();
      }
    },
    tap: function(newVal) {
      if(newVal == 0){
        this.getTurns();
      }
    },
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>