import {
  required,
  helpers,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

import { onlyText, phoneTest } from '../funciones';

const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const textNotReq = (value) => !helpers.req(value) || onlyText(value);
const onlyNumbers = (value) => /^[0-9]+$/.test(value);
const numberNotReq = (value) => !helpers.req(value) || onlyNumbers(value);

export const BankAccountValidations = (need) => {
  if(need) {
    return {
      bankInfo: {
        CurrencyId: { required },
        TpAccountId: { required },
        BankId: { required },
        AccountNumber: { required, onlyNumbers, maxLength: maxLength(20) },
        AccountHolder: { required, onlyText },
        BankInfoNumberId: { minLength: minLength(8), maxLength: maxLength(10), required, onlyNumbers },
      },
    }
  } else {
    return {
      bankInfo: {
        CurrencyId: { },
        TpAccountId: { },
        BankId: { },
        AccountNumber: { },
        AccountHolder: { },
        BankInfoNumberId: { },
      },
    }
  }
}

export const BankP2PValidations = (need) => {
  if(need){
    return {
      bankInfo: {
        CurrencyId: { required },
        BankId: { required },
        AccountHolder: { required, onlyText },
        BankInfoNumberId: { minLength: minLength(8), maxLength: maxLength(10), required, onlyNumbers },
        P2PPhone: { required, mobileNotReq, minLength: minLength(11), maxLength: maxLength(15)},
      },
    }
  } else {
    return {
      bankInfo: {
        CurrencyId: { },
        BankId: { },
        AccountHolder: { },
        BankInfoNumberId: { },
        P2PPhone: { },
      },
    }
  }
}