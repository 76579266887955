<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
      details
    >
      <template #Serial="{ item }">
        <td class="text-center">
          {{ item.Serial ?? 'N/A' }}
        </td>
      </template>
      <template #Quantity="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Quantity, 2) }}
        </td>
      </template>
      <template #QuantityReceived="{ item }">
        <td class="text-center">
          {{ formatNumber(item?.QuantityReceived, 2) }}
        </td>
      </template>
      <template #QuantityDispatched="{ item }">
        <td class="text-center">
          {{ formatNumber(item?.QuantityDispatched, 2) }}
        </td>
      </template>
      <template #Weigth="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Weigth, 2) }}
        </td>
      </template>
      <template #WeigthReception="{ item }">
        <td class="text-center">
          {{ formatNumber(item?.WeigthReception, 2) }}
        </td>
      </template>
      <template #WeigthDispatch="{ item }">
        <td class="text-center">
          {{ formatNumber(item?.WeigthDispatch, 2) }}
        </td>
      </template>
      <template #Volumen="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Volumen, 2) }}
        </td>
      </template>
      <template #VolumenReception="{ item }">
        <td class="text-center">
          {{ formatNumber(item.VolumenReception, 2) }}
        </td>
      </template>
      <template #VolumenDispatch="{ item }">
        <td class="text-center">
          {{ formatNumber(item.VolumenDispatch, 2) }}
        </td>
      </template>
      <template #SquareMeters="{ item }">
        <td class="text-center">
          {{ formatNumber(item.SquareMeters, 2) }}
        </td>
      </template>
      <template #options="{ item }">
        <td class="text-center">
          <CButton 
            v-if="item?.SerialHistJson && (item.SerialHistJson.length != 0)"
            square
            size="sm"
            color="watch"
            v-c-tooltip="{
              content: `${$t('label.see')} ${$t('label.serial')}`,
              placement: 'top-start'
            }"
            @click="ViewSerialJson(item)"
          >
            <CIcon name="eye" />
          </CButton>
        </td>
      </template>
      <template #details="{item}">
        <CCollapse v-if="item?.SerialHistJson && item.SerialHistJson.length != 0" :show="Boolean(item?.FgCollapse)" class="p-2">
          <dataTableExtended
            class="align-center-row-datatable"
            column-filter
            :items="computedSerialList(item.SerialHistJson)"
            :fields="fieldSerial(item.PackagingId)"
            :table-filter="tableText.tableFilterText"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="tableText.itemsPerPage"
            pagination
          >
            <template #Serial="{ item }">
              <td class="text-center">
                {{ `${item?.Serial ?? 'N/A'} ${item.TpCargoDetailCode ? `(${item.TpCargoDetailCode})` : ''}`.trim() }}
              </td>
            </template>
            <template #TpCargoStatusName="{ item }">
              <td class="text-center">
                <b>{{ item.TpCargoStatusName }}</b>
              </td>
            </template>
            <template #Quantity="{ item }">
              <td class="text-center">
                {{ formatNumber(item?.Quantity, 2) }}
              </td>
            </template>
            <template #Weigth="{ item }">
              <td class="text-center">
                {{ formatNumber(item?.Weigth, 2) }}
              </td>
            </template>
            <template #Volumen="{ item }">
              <td class="text-center">
                {{ formatNumber(item?.Volumen, 2) }}
              </td>
            </template>
            <template #options="{ item }">
              <td class="text-center">
                <CButton 
                  v-if="ValidateContainer(item.PackagingId)"
                  square
                  size="sm"
                  color="watch"
                  v-c-tooltip="{
                    content: $t('label.ViewContainer'),
                    placement: 'top-start'
                  }"
                  @click="ViewContainer(item)"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCollapse>
      </template>
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    
  };
}

//methods
function getYardCargoById(Container) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardCargo-by-Id", { YardCargoId: Container.YardCargoId, YardId: Container.YardId })
  .then(response => {
    let Container = response?.data?.data?.[0] ?? undefined;
    this.$store.state.yardManagement.ContainerTabIndex = 0;
    if (Container) {
      this.$store.state.yardManagement.dataContainer = Container;
      this.$store.state.yardManagement.FgContainerNavigation = true;
      this.$store.state.yardManagement.BlCollapse = 1;
      this.$store.state.yardManagement.yardCollapse = 2;
    }
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function ViewSerialJson(item) {
  this.$store.state.yardManagement.dataBl.DetailJson[item.Nro-1].FgCollapse = !item?.FgCollapse;
  this.$store.state.yardManagement.dataBl.DetailJson = [...this.Bl.DetailJson];
}

function computedSerialList(SerialHistJson = []) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return SerialHistJson?.map((item) => {
    return {
      ...item,
      Nro: item.IdX,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      YardCargoBlMasterDetailSerialStatusDs: item['YardCargoBlMasterDetailSerialStatusDs'+_lang] ?? '',
      YardName: item.YardName ?? '',
      ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
      ComputedDispatch: item.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : 'N/A',
      _style: `background: ${item.YardCargoBlMasterDetailSerialStatusColor}`,
      _classes: 'color-gradient',
    };
  });
}

function fieldSerial(PackagingId){
  let fgContainer = PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
  let header = [
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px; text-align:center;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width:45px;', _classes: 'text-center', filter: false },
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 220px;'},
  ]
  if (fgContainer) {
    header.push({ key: 'TpCargoStatusName', label: this.$t('label.status'),  _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'})
  }
  header.push(
    { key: 'YardCargoBlMasterDetailSerialStatusDs', label: fgContainer ? this.$t('label.ContainerStatus') : this.$t('label.status'),  _classes: 'text-uppercase text-center text-bold', _style: 'min-width: 180px;'},
    { key: 'YardName', label: this.$t('label.yard'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
    { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 160px;', _classes:"center-cell text-center"},
    { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
    { key: 'ComputedDispatch', label: this.$t('label.dispatchDate'), _classes: 'text-center', _style: 'min-width: 175px;'},
  )
  return header;
}

function ValidateContainer(id) {
  return id == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
}

function ViewContainer(Container) {
  if (Container.YardCargoId && Container.YardId) {
    this.getYardCargoById(Container);
  } else {

  }
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.Bl?.DetailJson?.map((item) => {
    return {
      ...item,
      Nro: item.IdX,
      FgCollapse: item?.FgCollapse ? true : false,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      Serial: item?.Serial ? item.Serial : 'N/A',
    };
  });
}

function fields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px; text-align:center;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width:45px;', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;' },
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;' },
    { key: 'Quantity', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
    { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'QuantityDispatched', label: this.$t('label.dispachedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'Weigth', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.planned')}`, _style:'min-width: 150px;', _classes: 'text-uppercase text-center'},
    { key: 'WeigthReception', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.received')}`, _style:'min-width: 150px;', _classes: 'text-uppercase text-center'},
    { key: 'WeigthDispatch', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.dispached')}`, _style:'min-width: 150px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³) ${this.$t('label.planned')}`, _style:'min-width: 180px;', _classes:"center-cell text-center" },
    { key: 'VolumenReception', label: `${this.$t('label.volume')} (M³) ${this.$t('label.received')}`, _style:'min-width: 180px;', _classes:"center-cell text-center" },
    { key: 'VolumenDispatch', label: `${this.$t('label.volume')} (M³) ${this.$t('label.dispached')}`, _style:'min-width: 180px;', _classes:"center-cell text-center" },
    { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style:'min-width: 150px;' },
  ];
}

export default{
  name: 'Packaging',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    getYardCargoById,
    formatNumber,
    ViewSerialJson,
    computedSerialList,
    fieldSerial,
    ValidateContainer,
    ViewContainer,
  },
  computed: {
    computedList,
    fields,
  },
  watch: {
    Bl: function (NewVal) {
      if (NewVal && Object.keys(NewVal).length != 0) {
        this.$store.state.yardManagement.dataBl?.DetailJson?.map(e => {
          e.FgCollapse = true;
        })
      }
    }
  }
}
</script>