<template>
  <CCol sm="12" class="mt-2">
    <CTabs
      style="width: 100%"
      variant="tabs"
      :active-tab="TabIndex"
      @update:activeTab="handleTab"
    >
      <CTab :title="$t('label.deck')">
        <Deck :active="active && TabIndex == 0 && FgGeneralCargo"/>
      </CTab>
      <CTab :title="$t('label.hold')">
        <StowagePlan :active="active && TabIndex == 1 && FgGeneralCargo"/>
      </CTab>
    </CTabs>
  </CCol>
</template>

<script>
import { mapState } from "vuex";
import General from "@/_mixins/general";
import Deck from "./deck";
import StowagePlan from "./stowage-plan";

//data
function data() {
  return {
    TabIndex: 0,
  };
}

//methods
function handleTab(tab) {
  this.TabIndex = tab;
}

export default {
  name: "app-manifiesto-carga",
  mixins: [General],
  data,
  props: {
    active: Boolean,
  },
  components: {
    Deck,
    StowagePlan,
  },
  methods: {
    handleTab,
  },
  computed: {
    ...mapState({
      bulkCarrier: state => state.planificacionestiba.bulkCarrier,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    active: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
      }
    },
  }
};
</script>