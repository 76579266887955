<template>
    <div class="container-fluid ">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mt-3">
            <CCol sm="12" class="d-flex align-items-center justify-content-end ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm" 
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow class="mt-2">
            <CCol sm="12" lg="4">
                <div class="form-group form-row" rol="group">
                    <label class="col-form-label col-sm-12 col-lg-2 text-right">{{$t('label.visit')}}</label>
                    <div class="col-sm-12 col-lg-9 input-group">
                        <v-select
                            class="select-adjust"
                            :placeholder="$t('label.select')"
                            v-model="VisitId"
                            :options="VisitOptions"
                            :reduce="option => option.value" 
                            :getOptionLabel="option => option.title"
                        >
                            <template #no-options="{ }">
                                {{$t('label.noResultsFound')}}
                            </template>
                            <template #option="{ Json, title }">
                                {{ title }}<br />
                                <cite v-if="title!=$t('label.select')">
                                    {{ Json.VoyageArrival }} - {{ 'ETA: '+Json.Eta }} - {{ Json.VisitStatus }} 
                                </cite>
                            </template>
                        </v-select>
                    </div>
                </div>
            </CCol>
            <CCol sm="12" lg="5">
                <div class="form-group form-row" rol="group">
                    <label class="col-form-label col-sm-12 col-lg-2 text-right">{{$t('label.client')}}</label>
                    <div class="col-sm-12 col-lg-10 input-group">
                        <v-select
                            class="select-adjust"
                            :placeholder="$t('label.select')"
                            v-model="ClientId"
                            :options="ClientOptions"
                            :reduce="option => option.value" 
                            :getOptionLabel="option => option.title"
                        >
                            <template #no-options="{ }">
                                {{$t('label.noResultsFound')}}
                            </template>
                        </v-select>
                    </div>
                </div>
            </CCol>

            <CCol sm="12" lg="3">
                <div class="form-group form-row" rol="group">
                    <label class="col-form-label col-sm-12 col-lg-3 text-right">{{$t('label.Gamela')}}</label>
                    <div class="col-sm-12 col-lg-8 input-group">
                        <v-select
                            class="select-adjust"
                            :placeholder="$t('label.select')"
                            v-model="BinId"
                            :options="gamelasOptions"
                            :reduce="option => option.value" 
                            :getOptionLabel="option => option.title"
                        >
                            <template #no-options="{ }">
                                {{$t('label.noResultsFound')}}
                            </template>
                        </v-select>
                    </div>
                </div>
            </CCol>
        </CRow>     
        <CRow>
            
            <CCol sm="12" lg="3" xl="3" class="center-field ">
                <CInput
                    :label="$t('label.search')"
                    :horizontal="{ label: 'col-sm-12 col-lg-auto text-right', input: 'input-adjust'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="search"
                >
                </CInput>
            </CCol>
            <div style="text-align: right;" class="col-sm-12 col-lg-auto center-field pl-0">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                    @click="(filtre=true, getBinShipmentOrderList())"
                >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
                    @click="CleanInformation"
                >
                    <CIcon name="cil-brush-alt" />
                </CButton>
            </div>
            <CCol sm="12" lg="4" xl="4" class="center-field ">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class=" justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="getBinShipmentOrderList"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="12" xl="12" class="mb-2">
                <div class="d-flex bd-highlight">
                    
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="RowData"
                            :suppressRowClickSelection="true"
                            :suppressPaginationPanel="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                        <div class="d-flex justify-content-end" style="border: 1px solid rgb(186, 191, 199, 1)">
                            <div class="m-3">{{`${StarRow} ${$t('agridTable.to')} ${EndRow} ${$t('label.of')} ${TotalRow}`}} <span :style="PointerBack" @click="BackPage"><CIcon name="cil-chevron-left-alt"/></span> {{`${$t('label.page')} ${Page} ${$t('label.of')} ${TotalPage}`}} <span :style="PointerNext" @click="NextPage"><CIcon name="cil-chevron-right-alt"/></span></div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
        <ModalOrder
            :modal.sync="ModalOrder"
            :Order="Orderitem"
            @set-list="CloseModal"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import GeneralReport from '@/_mixins/generalReport';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import ModalOrder from './modal-order';

    //Data
    function data() {
        return {
            ModalOrder: false,
            VisitId: '',
            VisitList: [],
            StarRow: 1,
            EndRow: 0,
            TotalRow: 0,
            Page: 1,
            StarRow: 0,
            TotalPage: 0,
            formatedItems: [],
            itemReport: [],
            Orderitem: {},
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            nulo:'', 
            search: '',
            pageSize: '',
            filtre: false,
            ClientList: [],
            ClientId: '',
            BinId: '',
            gamelasOptions: []
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'seeOrders'){
                    this.OrderList(event.data);
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'seeOrders'){
                   tippy('#myGenerateOrderButton', {
                        content: this.$t('label.seeOrders'),
                    })
                } 
            },   
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
        this.VisitId = '';
        this.search = '';
        this.gamelasOptions = [];
    }
    //methods
    function CleanInformation() {
        this.StarRow = 1;
        this.EndRow = 0;
        this.TotalRow = 0;
        this.Page = 1;
        this.StarRow = 0;
        this.TotalPage = 0;
        this.search = '';
        this.pageSize = 50;
        this.filtre=false;
        this.formatedItems = [];
        if (this.VisitId || this.BinId) {
            this.getBinShipmentOrderList();
        }
    }

    function Reset() {
        this.filtre=false; 
        this.search=''; 
        this.VisitId = ''; 
        this.ClientId = '';
        this.BinId = '';
        this.formatedItems = [];
        this.StarRow = 1;
        this.EndRow = 0;
        this.TotalRow = 0;
        this.Page = 1;
        this.StarRow = 0;
        this.TotalPage = 0;
        this.search = '';
        this.pageSize = 50;
    }

    function NextPage() {
        if (this.Page < this.TotalPage) {
            this.Page = this.Page + 1;
            this.getBinShipmentOrderList();
        }
    }

    function BackPage() {
        if (this.Page > 1) {
            this.Page = this.Page - 1;
            this.getBinShipmentOrderList();
        }
    }

    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function CloseModal() {
        this.Orderitem = {};
        this.ModalOrder = false;
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.pageSize>this.TotalRow) {
                this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            } else {
                await this.getBinShipmentOrderReport(this.filtre);
                rowData = this.itemReport;
            }
            await this.getPdf(rowData,this.$t('label.shipmentOrders'), 1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let currentDate = new Date();
        let rowData = [];
        let Filter = [];
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.pageSize>this.TotalRow) {
                this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            } else {
                await this.getBinShipmentOrderReport(this.filtre);
                rowData = this.itemReport;
            }
            let DataVisit = this.VisitList.find(item => item.VisitId==this.VisitId);
            Filter[0] = this.$t('label.visit')+": "+(DataVisit?DataVisit.VesselName:'N/A');
            Filter[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
            Filter[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            await this.getReporExcel(rowData, this.$t('label.shipmentOrders'), valor, Filter, 1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }


    async function getBinShipmentOrderList() {
        if((this.VisitId==='' || this.VisitId===null) && (this.BinId==='' || this.BinId===null) ){
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.aSelectedVisitIsRequired'),
                type: "error"
            });

            return false;
        }
        
        this.loadingOverlay = true;
        this.pageSize = this.pageSize == '' ? 50 : this.pageSize;
        if (this.TotalRow!=0 && this.pageSize>this.TotalRow) {
            this.Page = 1;
        }else if (this.TotalRow!=0 && this.Page>Math.ceil(this.TotalRow/this.pageSize)) {
            this.Page = Math.ceil(this.TotalRow/this.pageSize);
        }
        this.formatedItems = [];
        let DateFrom = '2022-01-01 00:00'
        let DateTo = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(new Date()));
        let ImplementOrderJson = {
            ImplementOrderJson:[ 
                {
                    CompanyBranchId: this.branch.CompanyBranchId,
                    DateFrom: DateFrom,
                    DateTo: DateTo,
                    VisitId: this.VisitId,
                    SearchKey: this.filtre ? this.search : '',
                    ImplementId: this.BinId
                }
            ] 
        }
        await this.$http.get('ImplementShipmentOrder-list', {PageNumber: this.Page, TotalRecords: this.pageSize, fgList: 1,ImplementOrderJson: ImplementOrderJson})
        .then(response => {
            let listado = response.data.data;
            if(listado!=null&&listado.data.length>0){
                this.TotalRow = listado.TotalRecords;
                this.TotalPage = listado.TotalPages;
                this.StarRow = (1 + (this.Page-1)*this.pageSize);
                this.EndRow = (this.Page*this.pageSize);
                this.formatedItems = listado.data.map(listado => Object.assign({}, this.formatedItems, {
                    VisitId: listado.VisitId ? listado.VisitId : '',
                    Nro: listado.Nro ? listado.Nro : '',
                    VesselName: listado.VesselName ? listado.VesselName : '',
                    VoyageArrival: listado.VoyageArrival ? listado.VoyageArrival : '',
                    Ata_Atd: listado.Atd ? DateFormater.formatDateTimeWithSlash(listado.Ata)+'-'+DateFormater.formatDateTimeWithSlash(listado.Atd) 
                        : DateFormater.formatDateTimeWithSlash(listado.Ata)+'-'+this.$t('label.InOperations'),
                    ImplementId: listado.ImplementId ? listado.ImplementId : '',
                    ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                    ClientId: listado.ClientId ? listado.ClientId : '',
                    ClientName: listado.ClientName ? listado.ClientName : '',
                    OpenOrder: listado.OpenOrder ? listado.OpenOrder : 0,
                    CloseOrder: listado.CloseOrder ? listado.CloseOrder : 0,
                    InactiveOrder: listado.InactiveOrder ? listado.InactiveOrder : 0,
                }));
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getClientConsigneImplementList() {
        
        this.loadingOverlay = true;

        await this.$http.get('ClientConsigneImplement-list', {CompanyBranchId: this.branch.CompanyBranchId})
        .then(response => {
            let listado = response.data.data;
            if(listado!=null && listado.length>0){
                this.ClientList = listado;
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getBinShipmentOrderReport(filtre) {
        this.itemReport = [];
        let DateFrom = '2022-01-01 00:00'
        let DateTo = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(new Date()));
        let ImplementOrderJson = JSON.stringify({ 
            ImplementOrderJson:[ 
                {
                    CompanyBranchId: this.branch.CompanyBranchId,
                    DateFrom: DateFrom,
                    DateTo: DateTo,
                    VisitId: this.VisitId,
                    SearchKey: filtre ? this.search : '',
                    ImplementId: this.BinId
                }
            ] 
        });
        await this.$http.get('ImplementShipmentOrder-list', {ImplementOrderJson: ImplementOrderJson})
        .then(response => {
            let listado = response.data.data;
            if(listado.length>0){
                this.itemReport = listado.map(listado => Object.assign({}, this.itemReport, {
                    VisitId: listado.VisitId ? listado.VisitId : '',
                    Nro: listado.Nro ? listado.Nro : '',
                    VesselName: listado.VesselName ? listado.VesselName : '',
                    VoyageArrival: listado.VoyageArrival ? listado.VoyageArrival : '',
                    Ata_Atd: listado.Atd ? DateFormater.formatDateTimeWithSlash(listado.Ata)+'-'+DateFormater.formatDateTimeWithSlash(listado.Atd) 
                        : DateFormater.formatDateTimeWithSlash(listado.Ata)+'-'+this.$t('label.InOperations'),
                    ImplementId: listado.ImplementId ? listado.ImplementId : '',
                    ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                    ClientId: listado.ClientId ? listado.ClientId : '',
                    ClientName: listado.ClientName ? listado.ClientName : '',
                    OpenOrder: listado.OpenOrder ? listado.OpenOrder : 0,
                    CloseOrder: listado.CloseOrder ? listado.CloseOrder : 0,
                    InactiveOrder: listado.InactiveOrder ? listado.InactiveOrder : 0,
                }));
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    function getVisitList() {
        this.loadingOverlay = true;
        this.$http.get('VisitBulkByCompanyBranchId-list', {CompanyBranchId: this.branch.CompanyBranchId})
        .then(response => {
            let listado = response.data.data;
            if(listado&&listado?.length>0){
                this.VisitList = listado;
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        this.loadingOverlay = false;
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-info text-white font-weight-bold'
        }
    }

    function OrderList(item){
        this.Orderitem = item;
        this.ModalOrder = true;
    }

    //computed
    function VisitOptions() {
        if(this.VisitList.length === 0){
            return [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    VoyageArrival: '',
                    Eta: '',
                    VisitStatus: '',
                },
            }];
        }else{
            let _lang = this.$i18n.locale;
            let chart = [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    VoyageArrival: '',
                    Eta: '',
                    VisitStatus: '',
                }
            }];
            this.VisitList.map(function(e){
                chart.push({
                    title: e.VesselName,
                    value: e.VisitId,
                    Json: {
                        VoyageArrival: e.VoyageArrival,
                        Eta: DateFormater.formatDateTimeWithSlash(e.Eta),
                        VisitStatus: _lang=='en'?e.VisitStatusEn:e.VisitStatusEs,
                    }
                })    
            })
            return chart;
        };
    }

    function ClientOptions() {
        if(this.VisitList.length === 0){
            return [{
                title: this.$t('label.select'),
                value: '',
            }];
        }else{
            let chart = [{
                title: this.$t('label.select'),
                value: '',
            }];
            this.ClientList.map(function(e){
                chart.push({
                    title: e.ClientName,
                    value: e.ClientId,
                })    
            })
            return chart;
        };
    }

    function PointerNext(){
        if (this.Page < this.TotalPage) {
            return {cursor: 'pointer'};
        }else{
            return {};
        }
    }

    function PointerBack(){
        if (this.Page > 1) {
            return {cursor: 'pointer'};
        }else{
            return {};
        }
    }

    function RowData(){
        if(this.formatedItems.length != 0){
            return this.formatedItems;
        }else{
            return [];
        }
    }
    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'seeOrders',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeOrders",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="myGenerateOrderButton" class="btn btn-watch mr-1 btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.08 20.51" role="img" class="c-icon">
                                        <path class="cls-1" d="M15,0A16.17,16.17,0,0,0,0,10.25a16.15,16.15,0,0,0,30.08,0A16.16,16.16,0,0,0,15,0Zm0,17.09a6.84,6.84,0,1,1,6.84-6.84A6.84,6.84,0,0,1,15,17.09ZM15,6.15a4.11,4.11,0,1,0,4.1,4.1A4.1,4.1,0,0,0,15,6.15Z"></path>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                field: "Nro",
                headerName: "#",
                headerClass: 'center-cell-especial',
                lockPosition: true,
                minWidth: 70,
                suppressMovable: true,
                cellClass: 'center-cell-especial gb-cell', 
            },
            {
                field: "ImplementAlias",
                headerName: this.$t('label.Gamela'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                minWidth: 130,
                suppressMovable: true,
                lockPosition: true,
            },
            {
                field: "VesselName",
                headerName: this.$t('label.vessel'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "VoyageArrival",
                headerName: this.$t('label.voyage'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 130,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "Ata_Atd",
                headerName: "ATA/ATD",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 250,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "ClientName",
                headerName: this.$t('label.client'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "OpenOrder",
                headerName: this.$t('label.OpenOrders'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 150,
                cellClass: 'center-cell-especial',
            },
            {
                field: "CloseOrder",
                headerName: this.$t('label.ClosedOrders'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
            },
             {
                field: "InactiveOrder",
                headerName: this.$t('label.OrdersInactive'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
        ]

        return columnDefs;
    }

    export default {
        name: "bin-ordenes-ingreso-index",
        data,
        beforeMount,
        components: {
            ModalOrder,
        },
        mixins: [GeneralReport,AgGrid],
        props: { tap: Number },
        methods:{
            CloseModal,
            OrderList,
            NextPage,
            BackPage,
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getBinShipmentOrderList,
            getBinShipmentOrderReport,
            getVisitList,
            getRowClass,
            getClientConsigneImplementList,
            CleanInformation,
            Reset,
        },
        computed:{
            VisitOptions,
            ClientOptions,
            PointerNext,
            PointerBack,
            RowData,
            columnDefs,
            ...mapState({
                branch: state => state.auth.branch,
            }),
        },
        watch:{
            tap: async function (newValue) {
                if (newValue==0) {
                    await this.getVisitList();
                    await this.getClientConsigneImplementList();
                }else{
                    this.Reset();
                }
            },
            VisitId: function (val) {
                if (val!='' && val!=null) {
                    this.getBinShipmentOrderList();
                }else if (this.BinId) {
                    this.getBinShipmentOrderList();
                }else{
                    this.formatedItems = [];
                }

                /*if(val==='' || val===null){
                    this.formatedItems = [];

                    this.TotalRow = 0;
                    this.TotalPage = 1;
                    this.StarRow = 0;
                    this.EndRow = 0;

                    this.pageSize = this.pageSize == '' ? 50 : this.pageSize;

                    if (this.TotalRow!=0 && this.pageSize>this.TotalRow) {
                        this.Page = 1;
                    }else if (this.TotalRow!=0 && this.Page>Math.ceil(this.TotalRow/this.pageSize)) {
                        this.Page = Math.ceil(this.TotalRow/this.pageSize);
                    }
                    
                }
                */
                    
            },
            ClientId: function (val) {
                let client = this.ClientList.find(item => val === item.ClientId)
                this.BinId = '';

                if(client != null && client != undefined){
                    let bins = [];

                     client.ImplementJson.map(function(e){
                        bins.push({
                            title: e.ImplementAlias,
                            value: e.ImplementId,
                        })    
                    })

                    this.gamelasOptions = bins;
                }  
                else
                    this.gamelasOptions = [];

            },
            BinId: function (newValue) {
                if (newValue) {
                    this.getBinShipmentOrderList();
                }else if (this.VisitId) {
                    this.getBinShipmentOrderList();
                }else{
                    this.formatedItems = [];
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style>
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
</style>