<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="title"
      :closeOnBackdrop="false"
      color="dark"
      size="xl"
      :show.sync="modalActive"
    >
      <CTabs justified :active-tab.sync="activeTab" :fade="false">
        <CTab :title="$t('label.basicData')">
          <CRow class="mt-2">
            <CCol sm="6">
              <CInput
                addLabelClasses="required text-right"
                :placeholder="$t('label.name')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-9',
                }"
                v-uppercase
                :label="$t('label.name')"
                maxlength="50"
                size="sm"
                v-model.trim="$v.staffInfo.Name.$model"
                :invalid-feedback="errorMessage($v.staffInfo.Name)"
                :is-valid="hasError($v.staffInfo.Name)"
              />
            </CCol>
            <CCol sm="6">
              <div role="group" class="form-group form-row">
                <label
                  class="
                    text-right
                    col-form-label col-sm-12 col-lg-5 col-form-label-sm
                  "
                >
                  {{ $t("label.birthday") }}
                </label>
                <div class="col-sm-12 col-lg-7 input-group-sm">
                  <vue-datepicker
                    valueType="format"
                    :open="openDate"
                    @update:open="functionDate($event)"
                    @clickoutside="openDate = false"
                    class="vue-datepicker-drive:focus"
                    @select="openDate = false"
                    @pick="openDate = false"
                    @close="openDate = false"
                    :clearable="false"
                    :editable="false"
                    :append-to-body="false"
                    :lang="this.$i18n.locale"
                    format="DD/MM/YYYY"
                    :disabled-date="validationBirthDate"
                    v-model.trim="$v.staffInfo.BirthDate.$model"
                  >
                    <template #input>
                      <CInput
                        v-uppercase
                        :is-valid="hasError($v.staffInfo.BirthDate)"
                        :invalid-feedback="errorMessage($v.staffInfo.BirthDate)"
                        v-model.trim="$v.staffInfo.BirthDate.$model"
                        @keypress="rightDate($event)"
                        class="mb-0"
                        size="sm"
                        placeholder="DD/MM/YYYY"
                      >
                        <template #append-content>
                          <CIcon name="cil-calendar" />
                        </template>
                      </CInput>
                    </template>
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
              </div>
            </CCol>
            <CCol sm="6">
              <CInput
                addLabelClasses="required text-right"
                :placeholder="$t('label.lastName')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-9',
                }"
                v-uppercase
                :label="$t('label.lastName')"
                maxlength="50"
                size="sm"
                v-model.trim="$v.staffInfo.LastName.$model"
                :invalid-feedback="errorMessage($v.staffInfo.LastName)"
                :is-valid="hasError($v.staffInfo.LastName)"
              />
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="$t('label.country')"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :options="countryOptions"
                :disabled="isEmpty(countries)"
                :value.sync="$v.staffInfo.CountryId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.CountryId)"
                :is-valid="hasError($v.staffInfo.CountryId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="6">
              <CInput
                addLabelClasses="required text-right"
                :placeholder="$t('label.IdNumber')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-9',
                }"
                :label="$t('label.IdNumber')"
                maxlength="10"
                size="sm"
                v-model.trim="$v.staffInfo.NumberId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.NumberId)"
                :is-valid="hasError($v.staffInfo.NumberId)"
              >
                <template #prepend>
                  <CSelect
                    :value.sync="numberIdLetter"
                    :options="ciTypeOptions"
                    class="mr-2 mb-0"
                    size="sm"
                  />
                </template>
              </CInput>
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="$t('label.state')"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :options="statesOptions"
                :disabled="isEmpty(states)"
                :value.sync="$v.staffInfo.StateId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.StateId)"
                :is-valid="hasError($v.staffInfo.StateId)"
              >
              </CSelect>
              <loading v-show="isLoadingStates" element="select" />
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.role')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                :options="roleOptions"
                :disabled="isEmpty(roles)"
                :value.sync="$v.staffInfo.StowageRoleId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.StowageRoleId)"
                :is-valid="hasError($v.staffInfo.StowageRoleId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="$t('label.municipality')"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :options="municipalityOptions"
                :disabled="isEmpty(municipalities)"
                :value.sync="$v.staffInfo.MunicipalityId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.MunicipalityId)"
                :is-valid="hasError($v.staffInfo.MunicipalityId)"
              >
              </CSelect>
              <loading v-show="isLoadingMunicipality" element="select" />
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.company')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                :options="companyOptions"
                :disabled="isEmpty(companies)"
                :value.sync="$v.staffInfo.ClientTpId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.ClientTpId)"
                :is-valid="hasError($v.staffInfo.ClientTpId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="6">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="$t('label.parish')"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :options="parishOptions"
                :disabled="isEmpty(parishes)"
                :value.sync="$v.staffInfo.ParishId.$model"
                :invalid-feedback="errorMessage($v.staffInfo.ParishId)"
                :is-valid="hasError($v.staffInfo.ParishId)"
              >
              </CSelect>
              <loading v-show="isLoadingParish" element="select" />
            </CCol>
            <CCol sm="6">
              <CTextarea
                size="sm"
                addLabelClasses="text-right "
                v-uppercase
                :label="$t('label.address')"
                :placeholder="$t('label.address')"
                :horizontal="{label: 'col-sm-12 col-lg-3',input: 'col-sm-12 col-lg-9',}"
                rows="3"
                maxlength="150"
                v-model.trim="$v.staffInfo.Address.$model"
                :invalid-feedback="errorMessage($v.staffInfo.Address)"
                :is-valid="hasError($v.staffInfo.Address)"
              />
            </CCol>
            <CCol sm="6" v-if="edit">
              <div>
                <CSelect
                  :value.sync="staffInfo.Status"
                  :is-valid="statusSelectColor()"
                  :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7' }"
                  size="sm"
                  :label="$t('label.status')"
                  :options="statusOptions"
                  addLabelClasses="text-right"
                />
              </div>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.contact')">
          <CRow class="mt-2">
            <CCol sm="6">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.mainPhone')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.mainPhone')"
                maxlength="15"
                size="sm"
                v-model.trim="$v.staffInfo.PrimaryPhone.$model"
                :invalid-feedback="errorMessage($v.staffInfo.PrimaryPhone)"
                :is-valid="hasError($v.staffInfo.PrimaryPhone)"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.secondaryPhone')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.secondaryPhone')"
                maxlength="15"
                size="sm"
                v-model.trim="$v.staffInfo.SecondaryPhone.$model"
                :invalid-feedback="errorMessage($v.staffInfo.SecondaryPhone)"
                :is-valid="hasError($v.staffInfo.SecondaryPhone)"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.mainEmail')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.mainEmail')"
                maxlength="100"
                size="sm"
                v-model.trim="$v.staffInfo.PrimaryEmail.$model"
                :invalid-feedback="errorMessage($v.staffInfo.PrimaryEmail)"
                :is-valid="hasError($v.staffInfo.PrimaryEmail)"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.secondaryEmail')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.secondaryEmail')"
                maxlength="100"
                size="sm"
                v-model.trim="$v.staffInfo.SecondaryEmail.$model"
                :invalid-feedback="errorMessage($v.staffInfo.SecondaryEmail)"
                :is-valid="hasError($v.staffInfo.SecondaryEmail)"
              />
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.bankDetails')">
          <CRow class="mt-3" v-if="modal">
            <CCol sm="12">
              <BanksCollapse 
                type="account"
                :banks="getBanks"
                :tpAccounts="getTpAccounts"
                :currencies="getCurrencies"
                :config="paymentToEdit"
                :edit="editAccount"
                @submit="submitPayment"
                @update="updatePayment"
                @clear="clearBank"
                @validation="validationAccount"
              />
            </CCol>
            <CCol sm="12" class="mt-2">
              <BanksCollapse
                type="P2P"
                :banks="getBanks"
                :currencies="getCurrencies"
                :config="paymentToEdit"
                :edit="editP2P"
                @clear="clearBank"
                @submit="submitPayment"
                @update="updatePayment"
                @validation="validationP2P"
              />
            </CCol>
            <CCol sm="12" class="mt-2">
              <dataTableExtended
                class="align-center-row-datatable"
                :items="formatedPayments"
                :fields="fieldsPayments"
                :loading="isLoadingBanks"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="tableText.itemsPerPage"
                hover
                small
                sorter
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #Status="{ item }">
                  <td class="text-center align-middle">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t("label." + item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>
                <template #Detalle="{item}">
                  <td class="text-center align-middle">
                    <CButton
                      shape="square"
                      color="edit"
                      size="sm"
                      v-c-tooltip="$t('label.edit')"
                      @click.prevent="editPayment(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton v-if="!edit" color="add" shape="square" :disabled="isSubmit" @click="submit">
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton v-if="edit" color="add" shape="square" :disabled="isSubmit" @click="confirmation">
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton color="wipe" shape="square" :disabled="isSubmit" @click="toggle(false)">
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import StaffValidations from "@/_validations/staff/StaffFormValidations";
import UpperCase from "@/_validations/uppercase-directive";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import mixinServicio from '@/_mixins/servicio';
import General from "@/_mixins/general";
import BanksCollapse from "./banks-collapse";
import { DateFormater } from "@/_helpers/funciones";
import { birthDateValidation } from "@/_validations/funciones";
import StaffMixin from '@/_mixins/staff';

//DATA
function data() {
  return {
    modalActive: false,
    staffInfo: {
      CompanyStaffId: "",
      ClientTpId: "",
      NumberId: "", //Cedula
      Name: "",
      LastName: "",
      StowageRoleId: "",
      BirthDate: "",
      CountryId: "",
      StateId: "",
      MunicipalityId: "",
      ParishId: "",
      Address: "",
      PrimaryPhone: "",
      SecondaryPhone: "",
      PrimaryEmail: "",
      SecondaryEmail: "",
      Status: 1,
      CompanyStaffBankInfoJson: [],
    },
    oldStatus: 1,
    numberIdLetter: "V",
    loading: false,
    staffBanks: [],
    countries: [],
    states: [],
    municipalities: [],
    parishes: [],
    companies: [],
    roles: [],
    bankConfig: null,
    currencies: [],
    currency: "",
    TpClientId: "7c1f74c3-3b2b-445c-84bf-004a5394983a",
    isLoadingStates: false,
    isLoadingMunicipality: false,
    isLoadingParish: false,
    openDate: false,
    isLoadingBanks: false,
    activeTab: 0,
    hasAccount: false,
    hasP2P: false,
    editAccount: false,
    editP2P: false,
    paymentToEdit: null,
    P2PIdTp: '0BDDE2E6-BA6A-44A8-8CB1-9C10C2784FAA',
    accountTp: '8E681E34-5032-488C-A0F6-A13F1FEFE994',
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else this.loadData();

  this.activeTab = 0;
  this.modalActive = newVal;
}
function resetForm() {
  this.staffInfo = Object.assign(
    {},
    {
      CompanyStaffId: "",
      ClientTpId: "",
      NumberId: "", //Cedula
      Name: "",
      LastName: "",
      StowageRoleId: "",
      BirthDate: "",
      CountryId: "",
      StateId: "",
      MunicipalityId: "",
      ParishId: "",
      Address: "",
      PrimaryPhone: "",
      SecondaryPhone: "",
      PrimaryEmail: "",
      SecondaryEmail: "",
      Status: 1,
      CompanyStaffBankInfoJson: [],
    }
  );
  this.numberIdLetter = "V";
  this.staffBanks = [];
  this.countries = [];
  this.states = [];
  this.municipalities = [];
  this.municipality = "";
  this.parishes = [];
  this.companies = [];
  this.roles = [];
  this.currencies = [];
  this.bankConfig = null;
  this.activeTab = 0;
  this.paymentToEdit = null;
  this.editP2P = false;
  this.editAccount = false;
  this.$v.$reset();
}
function setForm(staff) {
  let arrKeys = Object.keys(staff);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "BirthDate":
        this.staffInfo[arrKeys[i]] = staff[arrKeys[i]] ? this.formatDate(staff[arrKeys[i]]) : '';
        break;
      case "CompanyStaffBankInfoJson":
        this.staffInfo[arrKeys[i]] = staff[arrKeys[i]] ? staff[arrKeys[i]] : [];
        break;
      case "NumberId":
        let arrId = staff[arrKeys[i]].split(/[VEP]/);
        this.numberIdLetter = arrId.length == 2 ? arrId[0] : 'V';
        this.staffInfo[arrKeys[i]] = arrId.length == 2 ? arrId[1] : arrId[0];
        break;
      case "Status":
        this.staffInfo[arrKeys[i]] = staff[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = staff[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.staffInfo[arrKeys[i]] = staff[arrKeys[i]] ? staff[arrKeys[i]] : "";
    }
    // this.$v.staffInfo[arrKeys[i]].$touch();
  }
  this.$v.$touch();
}
function loadData() {
  this.loading = true;

  let peticiones = !this.edit
    ? [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "StowageRole-list", { Filter: "ACTIVO" }),
        this.$http.ejecutar("GET", "CountryByCompanyBranchId", {
          CompanyBranchId: this.getBranchId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "Currency-list", { Filter: "ACTIVO" }),
        this.$http.ejecutar("GET", "ConfigBank-list"),
      ]
    : [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "StowageRole-list", { Filter: "ACTIVO" }),
        this.$http.ejecutar("GET", "CountryByCompanyBranchId", {
          CompanyBranchId: this.getBranchId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "Currency-list", { Filter: "ACTIVO" }),
        this.$http.ejecutar("GET", "ConfigBank-list"),
        this.$http.ejecutar("GET", "CompanyGangs-by-id", {
          CompanyStaffId: this.companyStaffId,
        }),
      ];

  Promise.all(peticiones)
    .then((responses) => {
      this.companies = responses[0].data.data;
      this.roles = responses[1].data.data;
      this.countries = responses[2].data.data;
      this.currencies = responses[3].data.data;
      this.bankConfig = responses[4].data.data[0];

      if (this.edit) {
        this.setForm(responses[5].data.data[0]);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      console.log(err);
    })
    .then(() => {
      this.loading = false;
    });
}

function formatedData() {
  if (!this.edit) {
    return {
      ...this.staffInfo,
      BirthDate: this.staffInfo.BirthDate ? this.formatDate(this.staffInfo.BirthDate, true) : '',
      NumberId: `${this.numberIdLetter}${this.staffInfo.NumberId}`,
      CompanyStaffBankInfoJson: this.staffInfo.CompanyStaffBankInfoJson.map((item) => Object.assign({},{
        CompanyStaffBankInfoId: item.CompanyStaffBankInfoId,
        CurrencyId: item.CurrencyId,
        TpAccountId: item.TpAccountId,
        BankId: item.BankId,
        TpPaymentId: item.TpPaymentId,
        AccountNumber: item.AccountNumber,
        AccountHolder: item.AccountHolder,
        BankInfoNumberId: item.BankInfoNumberId,
        P2PPhone: item.P2PPhone,
        Status: typeof item.Status != 'number' 
        ? item.Status  == 'ACTIVO' ? 1 : 0
        : item.Status,
      })),
    };
  } else {
    return {
      ...this.staffInfo,
      BirthDate: this.staffInfo.BirthDate ? this.formatDate(this.staffInfo.BirthDate, true) : '',
      NumberId: `${this.numberIdLetter}${this.staffInfo.NumberId}`,
      CompanyStaffBankInfoJson: this.staffInfo.CompanyStaffBankInfoJson.map((item) => Object.assign({},{
        CompanyStaffBankInfoId: item.CompanyStaffBankInfoId,
        CurrencyId: item.CurrencyId,
        TpAccountId: item.TpAccountId,
        BankId: item.BankId,
        TpPaymentId: item.TpPaymentId,
        AccountNumber: item.AccountNumber,
        AccountHolder: item.AccountHolder,
        BankInfoNumberId: item.BankInfoNumberId,
        P2PPhone: item.P2PPhone,
        Status: typeof item.Status != 'number' 
        ? item.Status  == 'ACTIVO' ? 1 : 0
        : item.Status,
      })),
    };
  }
}
function submit() {
  try {
    this.$v.staffInfo.$touch();
    if (this.$v.staffInfo.$error || this.hasAccount || this.hasP2P) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let CompanyStaffJson = this.formatedData();

    this.$http
      .ejecutar("POST", "CompanyGangs-insert", CompanyStaffJson, {
        root: "CompanyStaffJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getStates(id) {
  this.isLoadingStates = true;

  this.$http
    .get("State-list", { CountryId: id, Filter: "ACTIVO" })
    .then((response) => {
      this.states = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingStates = false;
    });
}
function getMunicipality(id) {
  this.isLoadingMunicipality = true;

  this.$http
    .get("Municipality-list", { StateId: id, Filter: "ACTIVO" })
    .then((response) => {
      this.municipalities = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingMunicipality = false;
    });
}
function getParish(id) {
  this.isLoadingParish = true;

  this.$http
    .get("Parish-list", { MunicipalityId: id, Filter: "ACTIVO" })
    .then((response) => {
      this.parishes = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingParish = false;
    });
}
function isEmpty(arr) {
  return arr.length == 0;
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}
function formatCharDate(e) {
  var regex = new RegExp("^[0-9-/]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}
function validationBirthDate(date) {
  return birthDateValidation(date);
}

function confirmation() {
  try {
    this.$v.staffInfo.$touch();
    if (this.$v.staffInfo.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.statusConfirmation(this.oldStatus, this.staffInfo.Status, this.submit, `${this.staffInfo.Name} ${this.staffInfo.LastName}`);
  } catch(e) {
    this.notifyError({ text: e });
  }
}

function submitPayment(payment) {
 this.staffInfo.CompanyStaffBankInfoJson.push(payment);
}
function updatePayment(response) {
  let info = {...response.payment};

  let index = this.staffInfo.CompanyStaffBankInfoJson.findIndex((payment) => payment.CompanyStaffBankInfoId == info.CompanyStaffBankInfoId);

  if(index == -1) return;

  let toReplace = {...this.staffInfo.CompanyStaffBankInfoJson[index], ...info};

  this.staffInfo.CompanyStaffBankInfoJson.splice(index, 1, toReplace);

  if(response.type == 'account')
    this.editAccount = false;
  if(response.type == 'P2P')
    this.editP2P = false;
  this.paymentToEdit = null;
}
function validationP2P(validation) {
  this.hasAccount = validation;
}
function validationAccount(validation) {
  this.hasP2P = validation;
}
function editPayment(item) {
  this.paymentToEdit = {...item};
  if(item.TpPaymentId == this.accountTp)
    this.editAccount = true;
  else 
    this.editP2P = true;
}
function clearBank(type) {
  if(type == 'account') this.editAccount = false;
  if(type == 'P2P') this.editP2P = false;
}

//COMPUTED
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
function countryId() {
  return this.staffInfo.CountryId;
}
function stateId() {
  return this.staffInfo.StateId;
}
function municipalityId() {
  return this.staffInfo.MunicipalityId;
}
function countryOptions() {
  return this.countries.map((country) =>
    Object.assign({}, country, {
      label: country.CountryName,
      value: country.CountryId,
    })
  );
}
function statesOptions() {
  return this.states.map((state) =>
    Object.assign({}, state, {
      label: state.StateName,
      value: state.StateId,
    })
  );
}
function municipalityOptions() {
  return this.municipalities.map((municipality) =>
    Object.assign({}, municipality, {
      label: municipality.MunicipalityName,
      value: municipality.MunicipalityId,
    })
  );
}
function parishOptions() {
  return this.parishes.map((parish) =>
    Object.assign({}, parish, {
      label: parish.ParishName,
      value: parish.ParishId,
    })
  );
}
function roleOptions() {
  return this.roles.map((rol) =>
    Object.assign({}, rol, {
      label: rol.StowageRoleName,
      value: rol.StowageRoleId,
    })
  );
}
function companyOptions() {
  return this.companies.map((company) =>
    Object.assign({}, company, {
      label: company.ClientName,
      value: company.ClientTpId,
    })
  );
}
function ciTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
  ];
}
function statusSelectColor(){
  return this.staffInfo.Status === 1;
}

function fieldsPayments() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes:"text-center", filter: false},
    { key: "PaymentName", label: this.$t('label.type'), _classes:"text-center" },
    { key: "AccountHolder", label: this.$t('label.holder'), _classes:"text-center" },
    { key: "BankInfoNumberId", label: this.$t('label.IdNumber'), _classes:"text-center" },
    { key: "CurrencyName", label: this.$t('label.currency'), _classes:"text-center"},
    { key: "BankName", label: this.$t('label.bank'), _classes:"text-center"},
    { key: "Phone", label: this.$t('label.phone'), _classes:"text-center"},
    { key: "AccountNumber", label: this.$t('label.accountNumber'), _classes:"text-center"},
    { key: "User", label: this.$t('label.user'), _classes:"text-center"},
    { key: "Date", label: this.$t('label.date'), _classes:"text-center"},
    { key: "Status", label: this.$t('label.status'), _classes:"text-center"},
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _style:"width: 45px;"
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: 'align-middle',
    PaymentName: 'align-middle',
    AccountHolder: 'align-middle',
    BankInfoNumberId: 'align-middle',
    CurrencyName: 'align-middle',
    BankName: 'align-middle',
    Phone: 'align-middle',
    AccountNumber: 'align-middle',
    User: 'align-middle',
    Date: 'align-middle',
    Status: 'align-middle',
  }
}
function formatedPayments() {
  let index = 1;
  return this.staffInfo.CompanyStaffBankInfoJson.map((payment) => {
    let bankName = '', paymentName = '', currencyName = '';
    let foundBank = this.getBanks.find((item) => item.BankId == payment.BankId);
    if(foundBank)
      bankName = foundBank.BankName;
    let foundPayment = this.getPaymentTypes.find((item) => item.TpPaymentId == payment.TpPaymentId);
    if(foundPayment)
      paymentName = foundPayment.PaymentName;
    let foundCurrency = this.currencies.find((item) => item.CurrencyId == payment.CurrencyId);
    if(foundCurrency)
      currencyName = foundCurrency.CurrencyName;

    return Object.assign({}, payment, {
      Nro: index++,
      PaymentName: paymentName,
      AccountHolder: payment.AccountHolder ? payment.AccountHolder : 'N/A',
      BankInfoNumberId: payment.BankInfoNumberId ? payment.BankInfoNumberId : 'N/A',
      CurrencyName: currencyName,
      BankName: bankName,
      Phone: payment.P2PPhone ? payment.P2PPhone : 'N/A',
      AccountNumber: payment.AccountNumber ? payment.AccountNumber : 'N/A',
      User: payment.TransaLogin ? payment.TransaLogin : this.user.Login,
      Date: payment.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(payment.TransaRegDate)
        : DateFormater.formatOnlyDateWithSlash(new Date()),
      Status: typeof payment.Status == 'number' 
        ? payment.Status  == 1 ? 'ACTIVO':'INACTIVO'
        : payment.Status,
      _classes: typeof payment.Status == 'number' 
        ? payment.Status  == 1 ? '':"table-danger"
        : payment.Status == 'ACTIVO' ? '' :"table-danger",
      _cellClasses: this.cellTableClasses,
    });
  });
}

function getBanks() {
  if(!this.bankConfig) return [];

  return this.bankConfig.BankJson.map((bank) =>
    Object.assign({}, bank, {
      label: bank.BankName,
      value: bank.BankId,
    })
  );
}
function getTpAccounts() {
  if(!this.bankConfig) return [];

  return this.bankConfig.TpAccountJson.map((type) =>
    Object.assign({}, type, {
      label: type.TpAccountName,
      value: type.TpAccountId,
    })
  );
}
function getCurrencies() {
  return this.currencies.map((currency) =>
    Object.assign({}, currency, {
      label: currency.CurrencyName,
      value: currency.CurrencyId,
    })
  );
}
function getPaymentTypes() {
  if(!this.bankConfig) return [];

  return [...this.bankConfig.TpPaymentJson];
}

export default {
  name: "staff-modal",
  mixins: [ModalMixin, General, mixinServicio, StaffMixin],
  data,
  components: {
    BanksCollapse,
  },
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    companyStaffId: {
      type: String,
      default: "",
    },
    title:{
      type: String,
      default: "",
    }
  },
  validations: StaffValidations,
  directives: UpperCase,
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    countryId: function (newVal) {
      if (newVal) this.getStates(newVal);
    },
    stateId: function (newVal) {
      if (newVal) this.getMunicipality(newVal);
    },
    municipalityId: function (newVal) {
      if (newVal) this.getParish(newVal);
    },
  },
  methods: {
    loadData,
    toggle,
    resetForm,
    setForm,
    isEmpty,
    formatedData,
    submit,
    getStates,
    getMunicipality,
    getParish,
    functionDate,
    formatCharDate,
    confirmation,
    statusSelectColor,
    submitPayment,
    updatePayment,
    validationP2P,
    validationAccount,
    editPayment,
    clearBank,
    validationBirthDate,
  },
  computed: {
    getBranchId,
    countryId,
    stateId,
    municipalityId,
    countryOptions,
    statesOptions,
    municipalityOptions,
    parishOptions,
    roleOptions,
    companyOptions,
    ciTypeOptions,
    fieldsPayments,
    getBanks,
    getTpAccounts,
    getCurrencies,
    getPaymentTypes,
    cellTableClasses,
    formatedPayments,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: state => state.auth.user,
    }),
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}
</style>