<template>
  <div class="yard-body">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar-Detail">
          <div class="list-menu">
            <CRow>
                <CCol sm="12">
                  <img :src="imageSrc(yardData.YardProfileRoute)"  width="50px" height="50px" class="img bg-wrap text-center"  />
                </CCol>
            </CRow>
          </div>
          <div class="p-3">
            <ul class="list-unstyled components mb-5">
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="10">
                      <h5 class="text-white mt-0">
                        <b>{{yardData.YardName}}</b>                                                   
                      </h5>
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.serialNumber')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.YardAlias}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('agridTable.area')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ NumberFormated(yardData.SquareMeters) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.yardType')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.$i18n.locale=='en' ? yardData.TpYardNameEn : yardData.TpYardNameEs }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.group')}}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.YardGpoName}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li v-if="isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.UdInWarehouse')}}
                      <CIcon name="Quantity" class="ml-1"/>
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ NumberFormated(yardData.QuantityInYard, 2) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li v-if="isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ $t('label.TonInWarehouse') }}
                      <CIcon name="Weight" class="ml-1"/>
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ NumberFormated(yardData.WeigthInYard, 2) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li v-if="isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.VolInWarehouse')}}
                      <CIcon name="boxFill" class="ml-1"/>
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ NumberFormated(yardData.VolumenInYard, 2) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li v-if="!isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.teus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.TeusCapacity}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li v-if="!isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.occupiedTeus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.TeusOccupied}}
                    </CCol>
                  </CRow>
                </div>
              </li> 
              <li v-if="!isCoverWareHouse">
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.availableTeus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.TeusAvailable}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ `${$t('label.todayEntry')} ${isCoverWareHouse ? `(UD.)` : '' }` }}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.TodayReception}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{ `${$t('label.todayExit')} ${isCoverWareHouse ? `(UD.)` : '' }` }}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{yardData.TodayDispatch}}
                    </CCol>
                  </CRow>
                </div>
              </li>
            </ul>
          </div>
      </nav>
            
      <!-- Page Content  -->
        
      <div id="content">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light"
          :class="{'no-margin': null}"
        >
          <div class="container-fluid">
            <button type="button"  id="sidebarCollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
              <CIcon  :name="checkIconToggle"/>
              <span class="sr-only">{{checkTextToggle}}</span>
            </button>
            <div class="collapse navbar-collapse" v-if="RoleFgGeneral">
              <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                <li v-on:click="closeCollapse()" class="nav-item d-flex align-items-center justify-content-end">
                  <CIcon name="x"/>
                </li>
              </ul>
            </div>
          </div>
        </nav>
          <!-- Contenido Interno -->
        <div class="container-fluid contenido-especial">
            <CRow>
              <CCol sm="12">
                <CustomTabs :active-tab="$store.state.yardManagement.tabIndex" @update:activeTab="handleTab">
                  
                  <CustomTab 
                    icon="situationYardIcon" 
                    :title="$t('label.YardSituation')"
                    valueItemTab="0"                                                   
                  >
                    <YardSituation v-if="!isCoverWareHouse"></YardSituation>
                    <CoverWarehouseYardSituation v-else/>
                  </CustomTab>

                  <CustomTab 
                    icon="situationYardIcon" 
                    :title="$t('label.currentSituationYard')"
                    valueItemTab="1"                                                   
                  >
                    <YardCurrentSituation v-if="!isCoverWareHouse"></YardCurrentSituation>
                    <YardCurrentCoverWarehouse v-else/>
                  </CustomTab>

                  <!-- Yard Menu -->
                  <CustomTab  
                      icon="planimetry" 
                      :title="$t('label.planimetry')"
                      valueItemTab="2"
                      v-if="!isCoverWareHouse"
                  >
                    <Planimetry></Planimetry>
                  </CustomTab>

                  <CustomTab v-if="!isCoverWareHouse" valueItemTab="3">
                    <template #title class="contenido-especial">
                      <CIcon :name="'movementYardDetaild'"/>
                      <CDropdown
                        :togglerText="$t('label.movements')"
                        class="pl-0"
                        :flip="true"
                      >
                        <CDropdownItem :active="activeItemMovements(5)" @click="clickItemMovements(5)">{{ `${$t('label.ContainersInInventory')}`}}</CDropdownItem>
                        <CDropdownItem>
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.reception')}}</a>
                                <ul v-if="!YardEmpty">
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(1)" >{{$t('label.visit')}} </a></li>
                                  <!--<li><a href="javascript:void(0)" @click="clickItemMovements(2)">{{$t('label.masive')}}</a></li>-->
                                </ul>
                                <ul v-else>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(8)" >{{$t('label.ExternYard')}} </a></li>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(9)" >{{$t('label.CanceledContainers')}} </a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                        <CDropdownItem >
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.containerPositioning')}}</a>
                                <ul>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(11)">{{$t('label.masive')}}</a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                        <CDropdownItem v-if="!YardEmpty" :active="activeItemMovements(7)" @click="clickItemMovements(7)">{{ `${$t('label.recognition')}`}}</CDropdownItem>
                        <CDropdownItem v-if="!YardEmpty" :active="activeItemMovements(6)" @click="clickItemMovements(6)">{{ `${$t('label.dispatch')}`}}</CDropdownItem>
                        <CDropdownItem v-else>
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.dispatch')}}</a>
                                <ul>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(6)" >{{$t('label.executed')}} </a></li>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(10)">{{$t('label.masive')}}</a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                      </CDropdown>
                    </template>
                    <MovementList></MovementList>    
                  </CustomTab>
          
                  <CustomTab v-if="!isCoverWareHouse" valueItemTab="4">
                    <template #title>
                      <CIcon :name="'reportYardDetaild'"/>
                      <CDropdown
                          :togglerText="$t('label.navReports')"
                          class="pl-0"
                          :flip="false"
                      >
                        <CDropdownItem :active="activeItemReport(1)" @click="clickItemReport(1)">{{ `${$t('label.listReception')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemReport(2)" @click="clickItemReport(2)">{{ `${$t('label.dispatchList')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemReport(3)" @click="clickItemReport(3)">{{ `${$t('label.inventoryList')}`}}</CDropdownItem> 
                        <CDropdownItem v-if="YardEmpty" :active="activeItemReport(6)" @click="clickItemReport(6)">{{ `${$t('label.listOfCanceled')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemReport(8)" @click="clickItemReport(8)">{{ `${$t('label.EventList')}`}}</CDropdownItem> 
                        <CDropdownItem :active="activeItemReport(9)" @click="clickItemReport(9)">{{ `${$t('label.InspectionsList')}`}}</CDropdownItem> 
                        <!--
                        <CDropdownItem :active="activeItemReport(4)" @click="clickItemReport(4)">{{ `${$t('label.dispatchList')}(EXPORT)`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemReport(5)" @click="clickItemReport(5)">{{ `${$t('label.listOfShipments')}(EXPORT)`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemReport(7)" @click="clickItemReport(7)">{{ `${$t('label.yardGeneralState')}`}}</CDropdownItem>
                        -->
                      </CDropdown>
                    </template>
                    <Reports></Reports>
                  </CustomTab>

                  <!-- Warehouse Menu -->
                  <CustomTab v-if="isCoverWareHouse" valueItemTab="2">
                    <template #title class="contenido-especial">
                      <CIcon :name="'movementYardDetaild'"/>
                      <CDropdown
                        :togglerText="$t('label.movements')"
                        class="pl-0"
                        :flip="true"
                      >
                        <CDropdownItem :active="activeItemMovements(1)"  @click="clickItemMovements(1)">{{ `${$t('label.blsInventory')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemMovements(4)"  @click="clickItemMovements(4)">{{ `${$t('label.ContainersInInventory')}`}}</CDropdownItem>
                        <CDropdownItem>
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.reception')}}</a>
                                <ul>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(2)" >{{$t('label.visit')}} </a></li>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(3)" >{{$t('label.internalTransfer')}} </a></li>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(5)" >{{$t('label.blsHousePendingReceive')}} </a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                        <CDropdownItem :active="activeItemMovements(6)" @click="clickItemMovements(6)">{{ `${$t('label.dispatch')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemMovements(7)" @click="clickItemMovements(7)">{{ `${$t('label.deconsolidated')}`}}</CDropdownItem>
                        <!--
                        <CDropdownItem>
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.events')}}</a>
                                <ul>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(7)" >{{$t('label.deconsolidated')}} </a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                        <CDropdownItem>
                          <nav id="menu_gral">
                            <ul>
                              <li class="dropdown_gral">
                                <a href="javascript:void(0)">{{$t('label.inspections')}}</a>
                                <ul>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(8)" >{{$t('label.dimensions')}} </a></li>
                                  <li><a href="javascript:void(0)" @click="clickItemMovements(9)" >{{$t('label.accesories')}} </a></li>
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </CDropdownItem>
                        -->
                      </CDropdown>
                    </template>
                    <WareHouseMovementList></WareHouseMovementList>    
                  </CustomTab>

                  <CustomTab v-if="isCoverWareHouse" valueItemTab="3">
                    <template #title>
                      <CIcon :name="'reportYardDetaild'"/>
                      <CDropdown
                          :togglerText="$t('label.navReports')"
                          class="pl-0"
                          :flip="false"
                      >
                        <CDropdownItem :active="activeItemWarehouseReport(1)" @click="clickItemWarehouseReport(1)">{{ `${$t('label.inventoryList')}`}}</CDropdownItem> 
                        <CDropdownItem :active="activeItemWarehouseReport(2)" @click="clickItemWarehouseReport(2)">{{ `${$t('label.listReception')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(4)" @click="clickItemWarehouseReport(4)">{{ `${$t('label.positioningList')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(3)" @click="clickItemWarehouseReport(3)">{{ `${$t('label.dispatchList')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(5)" @click="clickItemWarehouseReport(5)">{{ `${$t('label.deconsolidatedList')}`}}</CDropdownItem>
                        <!--
                        <CDropdownItem :active="activeItemWarehouseReport(4)" @click="clickItemWarehouseReport(4)">{{ `${$t('label.inventoryList')}`}}</CDropdownItem> 
                        <CDropdownItem v-if="YardEmpty" :active="activeItemWarehouseReport(5)" @click="clickItemWarehouseReport(5)">{{ `${$t('label.listOfCanceled')}`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(6)" @click="clickItemWarehouseReport(6)">{{ `${$t('label.EventList')}`}}</CDropdownItem> 
                        <CDropdownItem :active="activeItemWarehouseReport(7)" @click="clickItemWarehouseReport(7)">{{ `${$t('label.InspectionsList')}`}}</CDropdownItem> 
                        
                        <CDropdownItem :active="activeItemWarehouseReport(8)" @click="clickItemWarehouseReport(8)">{{ `${$t('label.dispatchList')}(EXPORT)`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(9)" @click="clickItemWarehouseReport(9)">{{ `${$t('label.listOfShipments')}(EXPORT)`}}</CDropdownItem>
                        <CDropdownItem :active="activeItemWarehouseReport(10)" @click="clickItemWarehouseReport(10)">{{ `${$t('label.yardGeneralState')}`}}</CDropdownItem>
                        -->
                      </CDropdown>
                    </template>
                    <ReportsWareHouse></ReportsWareHouse>
                  </CustomTab>

                </CustomTabs>
              </CCol>
            </CRow>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import ListYard from './list-yard-index';
  import YardSituation from './yard-situation/yard-situation-index';
  import CoverWarehouseYardSituation from './warehouse/yard-situation/index';
  import YardCurrentSituation from './yardCurrentSituation/yardCurrentSituation-index';
  import YardCurrentCoverWarehouse from './yardCurrentSituation/coverWarehouse-index';
  import Planimetry from './planimetry/planimetry-index';
  import Reports from './reports/reports-index';
  import ReportsWareHouse from './warehouse/reports/reports-index';
  import MovementList from './movements-list/movements-list-index';
  import WareHouseMovementList from './warehouse/ware-house-movements-list-index';
  import Inventary from './inventary/inventary-index';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import { NumberFormater} from '@/_helpers/funciones';

  //data
  function data() {
    return {
      collapsed: false,
      dataContainer: {}
    }
  }

  //mounted
  function mounted(){
    document.getElementById("sidebarCollapseSidebar").onclick = function() {
      document.getElementById("sidebar-Detail").classList.toggle('active');
    }
  }

  //methods
  function handleTab(tab) {
    this.$store.state.yardManagement.tabIndex = tab;
    this.$store.state.yardManagement.GeneralList = 0;
    this.$store.state.yardManagement.dropItemMovementList = 0;
    this.$store.state.yardManagement.dropItemReport = 0;
    this.$store.state.yardManagement.dropItemInventary = 0;

  }

  function closeCollapse(){
    this.$store.state.yardManagement.yardCollapse = 0;
    this.$store.state.yardManagement.tabinfoIndex = 0;
    this.$store.state.yardManagement.ContainerTabIndex = 0;
    this.$store.state.yardManagement.tabMovementIndex = 0;
    this.$store.state.yardManagement.dropContainerMovement = 0;
    this.$store.state.yardManagement.dropBlMovement = 0;
    this.$store.state.yardManagement.dropItemEvent = 0;
    this.$store.state.yardManagement.dropItemReport = 0;
    this.$store.state.yardManagement.dropItemReportWarehouse = 0;
    this.$store.state.yardManagement.dropItemMovementList = 0;
    this.$store.state.yardManagement.dropItemMovementReception = 0;
    this.$store.state.yardManagement.dropItemMovementDispatch = 0;
    this.$store.state.yardManagement.dropItemInventary = 0;
    this.$store.state.yardManagement.dropItemInspection = 0;
    this.$store.state.yardManagement.tabIndex = 0;
    this.$store.state.yardManagement.isSelect = false;
    this.$store.state.yardManagement.GeneralList = 0;
    this.$store.state.yardManagement.loading = false;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.yardManagement.yardData = {};
    this.$store.state.yardManagement.ListReceivedContainers = [];
    this.$store.state.yardManagement.ListDispatchedContainers = [];
    this.$store.state.yardManagement.ListRecognitionContainers = [];
    this.$store.state.yardManagement.containerSelected = '';
    this.$store.state.yardManagement.blSelected = '';
    this.$store.state.yardManagement.globalSelectContainer = [];
    this.$store.state.yardManagement.globalSelectBl = [];
    this.$store.state.yardManagement.ContainerStatusInfo = {};
    this.$store.state.yardManagement.dataBl = {};
    this.$store.state.yardsetting.search = '';
    this.$store.state.yardManagement.WarehouseBlSearch = '';
    this.$store.state.yardManagement.ListDispatchedBl = [];
  }

  function checkSideBarToggle(){
    this.collapsed = !this.collapsed;
  }

  function imageSrc(Src){
      if(Src === ''|| !Src){
          return this.srcError;
      }
      else{
          return `${this.$store.getters["connection/getBase"]}${Src}`;
      }
  }

  function containerSearchActivity() {
    this.$store.state.yardManagement.yardCollapse = 2;
  }

  function clickItemReport(item){
    this.$store.state.yardManagement.dropItemReport = item;
  }

  function clickItemWarehouseReport(item){
    this.$store.state.yardManagement.dropItemReportWarehouse  = item;
  }

  function clickItemMovements(item){
    this.$store.state.yardManagement.dropItemMovementList  = item;
  }

  function clickItemInventary(item){
    this.$store.state.yardManagement.dropItemInventary  = item;
  }

  function NumberFormated(value){
    return value ? NumberFormater.formatNumber(value, 2) : '0,00';
  }

  //computed
  function VisitOptions(){
      return [
        { label: 'DECK TO', value: '1', option: 'DECK TO' },
        { label: 'UNDER DECK', value: '2', option: 'UNDER DECK'  },
        { label: 'ALL', value: '3', option: 'ALL'  },
      ];
  }


  function checkIconToggle() {
      if (this.collapsed) {
          return "cil-chevron-right-alt";
      } else {
          return "cil-chevron-left-alt";
      }
  }
    
  function checkTextToggle() {
      if (this.collapsed) {
          return this.$t("label.close");
      } else {
          return this.$t("label.watch");
      }
  }

  function activeItemReport(item) {
    return this.dropItemReport === item;
  }

  function activeItemWarehouseReport(item) {
    return this.dropItemReportWarehouse === item;
  }

  function activeItemMovements(item) {
    return this.dropItemMovementList === item;
  }

  function activeItemInventary(item) {
    return this.dropItemInventary === item;
  }

  function YardEmpty() {
    return process.env.VUE_APP_YARD_ID_EMPTY == this.yardData?.YardId?.toUpperCase();
  }

  function isCoverWareHouse() {
    return this.yardData?.TpYardId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE
  }

  async function getBlCargoListYardId() {
    await this.$http.get('BlCargoResum-list-by-YardId', { BlCargoJson: {YardId: this.yardData.YardId} })
      .then(response => {
        let List = response.data.data;
        this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function initWareHouse() {
    if(this.isCoverWareHouse){
      this.getBlCargoListYardId();
    }
  }

  export default{
      name: 'yard-detail',
      data,
      mixins: [GeneralMixin],
      components: {
        ListYard,
        YardSituation,
        YardCurrentCoverWarehouse,
        YardCurrentSituation,
        Planimetry,
        CustomTabs,
        CustomTab,
        Reports,
        ReportsWareHouse,
        MovementList,
        WareHouseMovementList,
        Inventary,
        CoverWarehouseYardSituation,
      },
      mixins: [GeneralMixin],
      beforeDestroy(){
        this.closeCollapse();
      },
      methods: {
        closeCollapse,
        handleTab,
        checkSideBarToggle,
        containerSearchActivity,
        activeItemReport,
        activeItemWarehouseReport,
        clickItemReport,
        clickItemWarehouseReport,
        clickItemMovements,
        activeItemMovements,
        activeItemInventary,
        clickItemInventary,
        imageSrc,
        NumberFormated,
        initWareHouse,
        getBlCargoListYardId
      },
      mounted:mounted,
      computed:{
        checkTextToggle,
        checkIconToggle,
        VisitOptions,
        YardEmpty,
        isCoverWareHouse,
        ...mapState({
            yardData: state => state.yardManagement.yardData,
            dropItemReport: state => state.yardManagement.dropItemReport,
            dropItemReportWarehouse: state => state.yardManagement.dropItemReportWarehouse,
            currentPrincipalTab: state => state.yardManagement.yardCollapse,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
            dropItemInventary: state => state.yardManagement.dropItemInventary,
            RoleFgGeneral: state => state?.auth?.role?.FgGeneral,
        })
      },
      watch: {
        currentPrincipalTab: async function(newVal, oldVal) {
          if(newVal == 1 && oldVal == 0) {
            this.initWareHouse();
          }
        }
      }
  }
</script>
<style lang="scss">
    @import 'css/style.scss';

    .navWidth{
      width: 15%;
    }
</style>