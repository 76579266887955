<template>
    <div>
        <CModalExtended
            size="sm"
            color="warning"
            :closeOnBackdrop="false"
            :show.sync="$store.state.planificacionestiba.modalAlertList"
        >
            <template slot="header" >
                <div class="d-flex align-content-center">
                    <div class="" style="margin-right:10px;">
                        <img class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" alt="Card image cap">
                    </div>
                    <div class="flex-grow-1" style="color:#FFF"><b style="font-weight: bold;">{{$t('label.Warning')}}:</b> {{ $store.state.planificacionestiba.alertInformation.messajeTitle }}</div>
                </div>
            </template>
            <div style="background:#FFF;" class="m-2">
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="dateFilteredItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    pagination
                     
                >
                </dataTableExtended>
            </div>
            <div slot="footer">
                <CButton
                    color="add"
                    @click="onBtnExport()"
                    class="m-2"
                    :disabled="apiStateLoading"
                >   
                    
                    <CIcon name="cil-file-excel"/>&nbsp; EXCEL
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>    
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    import { DateFormater } from '@/_helpers/funciones';

    import Reportes from '@/_mixins/reportes';
    //methods
    function cerrarModal(){
        this.$store.state.planificacionestiba.modalAlertList = false;
        this.$store.state.planificacionestiba.alertInformation = {
            messajeTitle:'',
            ErrorJson:[]
        }
    }
    async function onBtnExport(){
        try{
            if(this.dateFilteredItems.length !== 0){
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                await this.getExcelArray(this.dateFilteredItems,this.$t('label.errorList'),true);
/*
                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet("ListaErrores");

                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);

                let dataBuque = {
                    DatePlanning:'',
                    CountryName:'',
                    VesselFlagRoute:'',
                }

                if(Index > -1){
                    dataBuque.DatePlanning = this.myDataItinirary[Index].DatePlanning;
                    dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;

                    worksheet.mergeCells('A1:A1');
                    const response1 = await this.$http.getFile('base64', {
                        path:this.user.LogoRoute.replace('Public/','', null, 'i')
                    });
                    const divisiones1 = this.user.LogoRoute.split(".");
                    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
                    const imageId = workbook.addImage({
                      base64: myBase64Image,
                      extension: divisiones1[1],
                    });
                    worksheet.addImage(imageId, 'A1:A1');

                    const rowIndex1 = worksheet.getRow(1);
                    rowIndex1.height = 100;

                    worksheet.mergeCells("B1:F1");

                    const customCell = worksheet.getCell("B1");
                    const row = worksheet.getRow(1);
                    row.height = 60;
                    customCell.font = {
                        name: "Calibri",
                        family: 4,
                        size: 12,
                        underline: false,
                        bold: true
                    };
                    customCell.note = {
                        margins : {
                            insetmode: 'auto',
                            inset: [10, 10, 5, 5]
                        }
                    }
                    customCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{
                            argb:'f2f2f2' 
                        }
                    }
                    customCell.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell.value = dataBuque.DatePlanning;
                }

                //COLUMNA 2
                worksheet.mergeCells("A2:B2");
                const customCell2 = worksheet.getCell("A2");
                customCell2.height = 50;
                customCell2.font = {
                    name: "Calibri",
                    family: 4,
                    size: 18,
                    underline: false,
                    bold: true
                };
                customCell2.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell2.value = "LISTA DE ERRORES";
                customCell2.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                }

                //columna 3
                worksheet.mergeCells("C2:C2");
                const customCell3 = worksheet.getCell("C2");
                customCell3.height = 50;
                customCell3.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell3.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                const hoy = new Date();
                customCell3.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell3.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); //"IMPRESIÓN:  "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                customCell3.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                }

                //columna 4
                worksheet.mergeCells("D2:F2");
                const customCell4 = worksheet.getCell("D2");
                customCell4.height = 50;
                customCell4.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell4.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
                customCell4.value = "LOGIN DE USUARIO: "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                customCell4.border = {
                    top: {style:'thin'},
                    right: {style:'thin'},
                    bottom: {style:'thin'},
                }

                var headerRow = worksheet.addRow();
                headerRow.font =  {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };
                headerRow.height = 30;

                let keyColumns = [];
                let tamano = this.fields.length;
                this.fields.map(async(e,index) =>{
                    if(index < tamano){
                        keyColumns.push(e.key);
                    }
                });

                let i = 0;
                this.fields.map(async (data,index) => {
                    if(index < tamano){
                        let valor = data.label.length;
                        let long = 10;
                        if(data.key !== "ErrorMessage" && data.key !== "ColumnName" && data.key !== "ColumnValue"){
                            if ((valor>5) && (valor<12)) {
                                long = 25;
                            } else if ((valor>11) && (valor<18)) {
                                long = 30;
                            } else if ((valor > 19) && (valor < 26)) {
                                long = 35;
                            } else if (valor>27) {
                                long = 45;
                            }
                        }else if(data.key == "ColumnValue"){
                            long = 25;
                        }else if(data.key == "ColumnName"){
                            long = 35;
                        }else{
                            long = 65;
                        }

                        worksheet.getColumn(i + 1).width = long;
                        let cell = headerRow.getCell(i + 1);

                        
                        cell.border = {
                            top: {style:'thin'},
                            bottom: {style:'thin'},
                        }
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'cccccc'
                            }
                        }
                        
                        cell.value = data.label;
                        cell.alignment = { vertical: 'middle', horizontal: 'center' }
                        i++;
                    }
                });

                this.dateFilteredItems.map(async (data) => {
                    let dataRow = worksheet.addRow();
                    let indice = 0;
                    keyColumns.map(async (data2, index2) => {
                        if(index2 < tamano){
                            let cell = dataRow.getCell(indice + 1);
                            if(typeof data[data2] === 'boolean'){
                                cell.value = data[data2] ? 'YES' : 'NO';
                            }else{
                                cell.value = data[data2];
                            }
                            cell.alignment = { vertical: 'middle', horizontal: 'center' }
                            indice++;
                        }
                    })
                })

                // save under export.xlsx
                workbook.xlsx.writeBuffer()
                .then(buffer => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    let hoy = new Date();
                    let time = hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
                    let today = DateFormater.formatOnlyDateWithSlash(new Date());
                    today = today.replaceAll("/", "");
                    let titulo = (`ListaErrores${today}${time}.xlsx`).replaceAll(':','');
                    saveAs(new Blob([buffer]), titulo)
                    this.$notify({
                        group: 'container',
                        text: 'Formato Generado!',
                        type: "success"
                    });
                })
                .catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    console.log('Error writing excel export', err)
                }) */
            
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }
        } catch (error) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }
    }
    //computed
    function fields(){
        return [
            { key: 'Row', label: this.$t('label.row'),_classes:"center-cell", _style:'width:5%; text-align:center;' },
            { key: 'ErrorMessage', label: this.$t('label.Description'),_classes:"text-left", _style:'width:50%;'},
            { key: 'ColumnName', label: this.$t('label.attribute'),_classes:"text-left", _style:'width:23%;'},
            { key: 'ColumnValue', label: this.$t('label.value'),_classes:"text-left", _style:'width:22%;'},
        ]
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function dateFilteredItems(){
        if(this.modalAlertList){
            let local = this.$i18n.locale ;
            let itemx = this.alertInformation.ErrorJson.map((item) => Object.assign({}, item, {
                messageDescription : local == 'es'? item.ErrorMessage : item.ErrorMessage
            }));
            return itemx.filter(async (item) => {
                return item
            });
        }else{
            return [];
        }
    }
    export default {
        name:'modal-error-list',
        mixins: [Reportes],
        methods:{
            cerrarModal,
            onBtnExport
        },
        computed:{
            fields,
            tableTextHelpers,
            apiStateLoading,
            dateFilteredItems,
            ...mapState({
                user: state => state.auth.user,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                modalAlertList: state => state.planificacionestiba.modalAlertList,
                alertInformation: state => state.planificacionestiba.alertInformation,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        }
    }
</script>