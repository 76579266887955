<template>
<div>
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
  <SubclassificationModal
    :modal.sync="modal"
    :subClassId="subClassId"
    :subClassItems="subClassItems"
    :edit="edit"
    :title="titleModal"
    @submited="handleSubmit"
    @close="closeModal"
  />
  <CRow>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.subclassification'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedItems"
        :fields="fields"
        column-filter
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items-per-page="tableText.itemsPerPage"
        hover
        small
        sorter
        pagination
      >
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{$t('label.'+item.Status)}}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center">
            <CButton
              color="edit"
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.subclassification'),
                placement: 'top-end'
              }"
              @click="toggleEdit(item)"
            >
            <CIcon name="pencil" />
          </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</div>
</template>

<script>
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import General from "@/_mixins/general";
import { mapState } from "vuex";
import SubclassificationModal from "./subclassification-modal";

//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    subClassId: '',
    subClassItems: {},
    edit: false,
    titleModal: ''
  };
}

//METHOD
function getListSudClassification() {
  this.Loading = true;
  this.$http
    .get('YardSubClasification-list-by-YardId',{ YardId: this.getYardId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nueva')+' '+this.$t('label.subclassification');
}
function toggleEdit(item) {
  this.subClassId = item.YardSubClasificationId;
  this.subClassItems = item;
  this.edit = true;
  this.modal = true;
  let title = this.subClassItems.YardSubClasificationName;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.subclassification')+': '+title
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.subClassItems = {};
  this.titleModal = '';
}
function resetDataTab(){
  this.items = [];
}
function handleSubmit() {
  this.getListSudClassification();
}

// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "YardSubClasificationName", label: this.$t("label.subclassification"),_style: 'width:30%;',_classes:'text-center',},
    { key: "SquareMeters", label: 'AREA (M2)',_style: 'width:30%;',_classes:'text-center'},
    { key: "Usuario", label: this.$t("label.user"),_style: 'width:10%;',_classes:'text-center' },
    { key: "FormatedDate", label: this.$t("label.date"),_style: 'width:10%;', _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_style: 'width:10%;', _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width: 45px;",
    },
  ];
}
function formatedItems() {
  return this.items.map((items) =>
    Object.assign({}, items, {  
      Nro: items.Nro,
      YardSubClasificationName: items.YardSubClasificationName,
      SquareMeters: items.SquareMeters ? formatMilDecimal(items.SquareMeters?.toFixed(2)) : 'N/A',
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      Status: items.Status,
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}
function getYardId() {
    return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
export default {
  name: "index",
  mixins: [General],
  props: {Tab: Number},
  data,
  components: {
    SubclassificationModal,
  },
  mounted() {
    this.getListSudClassification();
  },
  methods: {
    getListSudClassification,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    closeModal,
    resetDataTab,
  },
  computed: {
    fields,
    formatedItems,
    getYardId,
    ...mapState({
      activeTab: state => state.yard.tabIndex,
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 0) {
        this.getListSudClassification();
        this.resetDataTab();
      }
    },
  },
};
</script>
<style scoped>
</style>