<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      class="modal-extended"
      :show.sync="modalActive"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CRow>
                    <CCol sm="9">
                      <CSelect
                        size="sm"
                        addLabelClasses="required text-right"
                        :label="$t('label.circuit')"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-5',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        :options="circuitOptions"
                        :value.sync="$v.modalityCircuitInfo.CircuitId.$model"
                        :invalid-feedback="errorMessage($v.modalityCircuitInfo.CircuitId)"
                        :is-valid="hasError($v.modalityCircuitInfo.CircuitId)"
                        @change="CircuitName"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="2" >
                          <div class="d-flex align-items-start">
                              <CButton
                                color="add"
                                size="sm"
                                class="d-flex align-items-center ml-2"
                                v-c-tooltip="{
                                  content: $t('label.add')+$t('label.modality')+$t(' ')+$t('label.circuit'),
                                  placement: 'top-end'
                                }"
                                :disabled="isSubmit"
                                @click="addCircuit"
                              >
                                <CIcon name="cil-plus"/>
                             
                              </CButton>
                          </div>
                    </CCol>

                   <CCol sm="12" class="mt-2" >
                      <dataTableExtended
                      class="align-center-row-datatable"
                        size="sm"
                        :fields="fields"
                        :items="circuitosOptions"
                        :items-per-page="5"
                        column-filter
                        pagination
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        :loading="isLoading"
                        
                      >
                        <template #Nro="{item}">
                          <td class="center-cell">
                              {{ item.Nro }}
                          </td>
                        </template>
                        <template #Details="{item}">
                          <td class="center-cell">
                              <CButton
                                color="plan"
                                size="sm"
                                v-c-tooltip="{
                                  content: $t('label.Down'),
                                  placement: 'top-end'
                                }"
                                @click.stop="bajar(item)"
                              >
                                 <CIcon name="cil-arrow-circle-bottom"/>
                              </CButton>
                              <CButton
                                color="watch"
                                size="sm"
                                class="ml-1"
                                v-c-tooltip="{
                                  content: $t('label.Up'),
                                  placement: 'top-end'
                                }"
                                @click.stop="subir(item)"
                              >
                                <CIcon name="cil-arrow-circle-top"/>
                              </CButton>
                               <CButton
                                color="wipe"
                                size="sm"
                                class="ml-1"
                                v-c-tooltip="{
                                  content: $t('label.delete')+$t('label.circuit')+' '+$t('label.modality'),
                                  placement: 'top-end'
                                }"
                                @click.stop="deleteR(item)"
                              >
                                <CIcon name="cil-trash" />
                              </CButton>
                          </td>
                        </template>
                      </dataTableExtended>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
         
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div >
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          
        </CButton>
       
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalityCircuitValidation from "@/_validations/metalscrap/ModalityCircuitFormValidations.js";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import MetalScrap from "@/_mixins/metalscrap";
import mixinServicio from "@/_mixins/servicio";

function fields() {

  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center'
    },
    { key: 'CircuitName', label:this.$t('label.circuit'),_style:'width:80%;', _classes:"text-left", sorter: false, filter: false},
    { 
      key:    'Details', 
      label:  this.$t('label.order'), 
      _style: 'min-width:135px; width:15%;', 
      sorter: false, 
      filter: false,
      _classes: 'text-center'
    }
  ];
}

//DATA
function data() {
  return {
    isLoading: false,
    modalActive: false,
    modalityCircuitInfo: {
      ModalityCircuitId: "",
      CircuitId: '',
      ModalityId: '',
      CircuitName: '',
      Order: '',
    },
 
    loading: false,
    circuitData: [],
    circuitos: [],
    editCircuit: false, 
  };
}

// METHODS
function toggle(newVal) {
 
  this.resetForm();
  if (!newVal){
    this.resetForm();
    this.modalActive=false;
  }
  if (newVal){
    this.circuitList();  
    this.modalActive = newVal;
    let _lang = this.$i18n.locale;

 

    if(this.ModalityCircuit.ModalityCircuitJson !== null){
      this.editCircuit = true;

     
      for(let i=0; i < this.ModalityCircuit.ModalityCircuitJson.length ; i++){
        if(this.ModalityCircuit.ModalityCircuitJson[i].FgActModalityCircuit){
          this.circuitos.push({
            ModalityCircuitId: this.ModalityCircuit.ModalityCircuitJson[i].ModalityCircuitId,
            Order: i+1,
            CircuitId: this.ModalityCircuit.ModalityCircuitJson[i].CircuitId,
            CircuitName: _lang=='en' ? this.ModalityCircuit.ModalityCircuitJson[i].CircuitNameEn : this.ModalityCircuit.ModalityCircuitJson[i].CircuitNameEs,
            ModalityId: this.ModalityCircuit.ModalityCircuitJson[i].ModalityId,
            Status: this.ModalityCircuit.ModalityCircuitJson[i].FgActModalityCircuit ? 1 : 0,        
          });
        }
      }

    }
  }
}

function CircuitName(e) {
  let _lang=this.$i18n.locale;
  this.modalityCircuitInfo.CircuitId = e.target.value;

  const findCircuit = this.circuitData.find( item => item.CircuitId === e.target.value );
  if (findCircuit != undefined)
    this.modalityCircuitInfo.CircuitName = _lang=='en' ?  findCircuit.CircuitNameEn : findCircuit.CircuitNameEs;
 /* for (let index = 0; index < this.circuitData.length; index++) {
    if (this.modalityCircuitInfo.CircuitId == this.circuitData[index].CircuitId) {
      let Name = _lang=='en' ?  this.circuitData[index].CircuitNameEn : this.circuitData[index].CircuitNameEs
      this.modalityCircuitInfo.CircuitName = Name;   
    }
  } */
}
function resetForm() {
  this.circuitos=[];
  this.modalityCircuitInfo.ModalityId = '';
  this.modalityCircuitInfo.CircuitId = '';
  this.modalityCircuitInfo.CircuitName = '';
  this.modalityCircuitInfo.Status = 1;
  this.oldStatus = 1;
  this.editCircuit = false;
  this.$v.$reset();
}

function circuitList() {


    this.loading = true;
    this.$http.get("Circuit-list",
     { CompanyBranchId: this.branch.CompanyBranchId,
       Filter: "ACTIVO",
     })
    .then(response => {
      this.circuitData = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loading = false;
    }).then(() => {
      this.loading = false;
    });
  
}

function submit() {

 try {
   let route = this.editCircuit
      ? "ModalityCircuit-update"
      : "ModalityCircuit-insert";

    let arrayCircuit = [];
      if(this.editCircuit){
        for(let i=0; i < this.circuitos.length ; i++){
      
          arrayCircuit.push({
            ModalityCircuitId: this.circuitos[i].ModalityCircuitId,
            CircuitId: this.circuitos[i].CircuitId,
            ModalityId: this.circuitos[i].ModalityId,
            Order: this.circuitos[i].Order,
            Status: this.circuitos[i].Status,
            UserId: this.user.UserId,
          });  

         
        }
      }else{
        for(let i=0; i < this.circuitos.length ; i++){
          arrayCircuit.push({
            CircuitId: this.circuitos[i].CircuitId,
            ModalityId: this.circuitos[i].ModalityId,
            Order: this.circuitos[i].Order,
            UserId: this.user.UserId,
          });
        }
      }

       

    let ModalityCircuitJson = arrayCircuit;
    let metodo = this.editCircuit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, ModalityCircuitJson, { root: "ModalityCircuitJson" })
      .then((response) => {
        const messageSuccess = response.data.data[0].Response;
        this.$emit("submited");
        this.modalActive = false;
        this.notifySuccess({text: messageSuccess})
        this.resetForm();
        this.isSubmit = false;
      })
      .catch((err) => {
        this.isSubmit = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
    this.isSubmit = false;
  }

}

function addCircuit() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    
    const findCircuit = this.circuitos.find( item => item.CircuitId === this.modalityCircuitInfo.CircuitId );
    if (findCircuit == undefined){
      let Order = this.circuitos.length + 1;

      if(this.editCircuit){
        this.circuitos.push({
          ModalityCircuitId: '', 
          Order: Order,
          CircuitId: this.modalityCircuitInfo.CircuitId,
          CircuitName: this.modalityCircuitInfo.CircuitName,
          ModalityId: this.ModalityCircuit.ModalityId,
          Status: 1,
        });
      }else{


        this.circuitos.push({
          Order: Order,
          CircuitId: this.modalityCircuitInfo.CircuitId,
          CircuitName: this.modalityCircuitInfo.CircuitName,
          ModalityId: this.ModalityCircuit.ModalityId,
          Status: 1,
        });

      }
      this.modalityCircuitInfo.CircuitId = '';
    }else{
      this.notifyError({ 
        text: this.$t('label.registeredPreviouslyCircuit')
      });
    }
      
    this.$v.$reset();
    this.isSubmit = false;

  } catch (e) {
    this.notifyError({ text: e });
  }
}

function subir(item) {

  let index = this.circuitos.findIndex((circuito) => circuito.Order == item.Order );

  if(index>0){
    let poriginal = index; 

    let newposition = poriginal-1;

    let anterior = this.circuitos[index-1];

    this.circuitos.splice(newposition, 1, item);
    this.circuitos.splice(poriginal, 1, anterior);

    this.circuitos[newposition].Order = newposition+1;

    this.circuitos[poriginal].Order = poriginal+1;
   
  }
}

function bajar(item) {

  let index = this.circuitos.findIndex((circuito) => circuito.Order == item.Order );

  if ((index+1)<this.circuitos.length) {
    let poriginal = index; 
  
    let newposition = poriginal+1;
   
    let next = this.circuitos[index+1];

    this.circuitos.splice(newposition, 1, item);
   
    this.circuitos.splice(poriginal, 1, next);

    this.circuitos[newposition].Order = newposition+1;

    this.circuitos[poriginal].Order = poriginal+1;

  }
}

function deleteR(item) {
      if(this.editCircuit){

                   let index = this.circuitos.findIndex((circuito) => circuito.Order == item.Order);

                    if(this.circuitos[index].ModalityCircuitId == ""){

                        
                        this.circuitos.splice(index, 1);

                    } else {

                        this.circuitos[index].Status = 0;
                     
                    }
      } else {


                
                  let index = this.circuitos.findIndex((circuito) => circuito.Order == item.Order);
                  this.circuitos.splice(index, 1);

      }
}

//Computeds:
function circuitosOptions() {
  let index = 1;
  return this.circuitos.map((item)=> {
    return {
      ...item,
      Nro: index++,
    };
  }).filter((circuit) => circuit.Status == 1);
}


function circuitOptions(){
   let _lang=this.$i18n.locale;
  if(this.circuitData.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.circuitData.map(function(e){
      chart.push({
          value: e.CircuitId, 
          label: _lang=='en' ?  e.CircuitNameEn : e.CircuitNameEs,
      })     
    })
    return chart;
  }
}

function tituloModal() {
   let _lang=this.$i18n.locale;

   let ModalityName = _lang=='en' ?  this.ModalityCircuit.ModalityNameEn : this.ModalityCircuit.ModalityNameEs;
  
   return this.$t("label.ModalityAssociation")+': '+ModalityName+' - '+this.$t("label.circuits"); 
}


export default {
  name: "modality-circuit-modal",
  mixins: [ModalMixin, General, mixinServicio, MetalScrap],
  data,
  components: {},
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: true,
    },
    ModalityCircuit: null,
  },
  validations: ModalityCircuitValidation,
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  methods: {
    deleteR,
    CircuitName,
    toggle,
    resetForm,
    submit,
    circuitList,
    addCircuit,
    bajar,
    subir
  },
  computed: {
    circuitosOptions,
    tituloModal,
    circuitOptions,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
    fields,

  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  cursor: pointer;
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>