<template>
  <div style="margin-top: 30px">
    <ManifiestoCargaModal
      :modal.sync="modal"
      :billOfLoadingItem="billOfLoadingItem"
      :edit="edit"
      @submited="handleSubmit"
    />
    <ModalReport
      :modal.sync="ModalReport"
      :billOfLoadingId="billOfLoadingId"
      @submited="handleSubmit"
    />
    <GeneralCargoModal
      :modal.sync="ActivateGeneralCargoModal"
      :GeneralCargoData="GeneralCargoData"
      :isEdit="edit"
			@Close="(ActivateGeneralCargoModal=false, edit=false, GeneralCargoData={})"
      @submited="handleSubmit"
    />
    <CCollapse :show="!ShowBlContainerList">
      <CCol sm="12">
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end">
            <CButton
              shape="square"
              color="add"
              v-c-tooltip="{ placement: 'top', content: 'BL' }"
              class="d-flex align-items-center"
              @click="toggleModal(false, null)"
            >
              <CIcon name="cil-playlist-add" /><span class="ml-1">{{
                $t("label.nuevo")
              }}</span>
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              hover
              sorter
              column-filter
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items="formatedItems"
              :fields="fields"
              :loading="loadingTable"
              :noItemsView="tableText.noItemsViewText"
              :items-per-page="5"
              :active-page="activePage"
              pagination
            >
              <template #Status="{ item }">
                <td class="text-center align-middle">
                  <div>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t("label." + item.Status) }}
                    </CBadge>
                  </div>
                </td>
              </template>

              <template #Details="{ item, index }">
                <td class="center-cell">
                  <CButton
                    square
                    size="sm"
                    class="mr-1"
                    color="edit"
                    v-c-tooltip="{
                      content: $t('label.edit') + ' ' + 'BL',
                      placement: IsGeneralCargoVessel || IsContainerShipVessel ? 'top-start' : 'top-end',
                    }"
                    @click="toggleModal(true, item)"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                  <CButton
                    v-if="(IsGeneralCargoVessel || IsContainerShipVessel) && item.CargoJson && item.CargoJson.length != 0"
                    color="watch"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.ContainerList'),
                      placement: 'top-start',
                    }"
                    @click="(ShowBlContainerList=true, itemBl=item, Index=index)"
                  >
                    <CIcon name="containerSizeIcon" />
                  </CButton>
                  <CButton
                    v-if="!(IsGeneralCargoVessel || IsContainerShipVessel)"
                    square
                    color="add"
                    size="sm"
                    v-c-tooltip="{ 
                      placement: 'top-end', 
                      content: $t('label.uploadCommodityDocuments'), 
                    }"
                    @click="toggleReport(item)"
                  >
                    <CIcon name="cil-cloud-upload" />
                  </CButton>
                  
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </CCol>
    </CCollapse>
    <CCollapse :show="ShowBlContainerList">
      <BlContainerList
        :active="ShowBlContainerList"
        :Bl="itemBl"
        :Index="Index"
        :BlList="items"
        @Close="(ShowBlContainerList=false, itemBl={}, Index=0)"
        @Update="Update"
      />
    </CCollapse>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import ManifiestoCargaModal from "./manifiesto-modal";
import ModalReport from "./modal-report";
import GeneralCargoModal from "./general-cargo-modal/general-cargo-modal-index";
import BlContainerList from "./bl-container-list"

function fields() {
  if (this.IsGeneralCargoVessel || this.IsContainerShipVessel) {
    return [
      {
        key: "Details",
        label: "",
        sorter: false,
        filter: false,
        _style: "min-width:90px; width:1%;",
      },
      { key: "Nro", label: "#", _classes: "text-center", filter: false,},
      { key: "BL", label: 'BL',  _classes: 'text-center',  _style: "min-width: 130px;"},
      { key: "ClientName", label: this.$t("label.client"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Activity", label: this.$t("label.activity"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Commodity", label: this.$t("label.commodity"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "ComputedWeight", label: this.$t("label.weight")+' (KGM)',  _classes: 'text-center', _style: "min-width: 130px"},
      { key: "VolumenM3", label: this.$t("label.volume")+' (M³)',  _classes: 'text-center', _style: "min-width: 135px"},
      { key: "ComputedQuantityPlanning", label: this.$t("label.plannedQuantity"),  _classes: 'text-center', _style: "min-width: 200px"},
      { key: "ComputedQuantity", label: this.$t("label.CertifiedQuantity"),  _classes: 'text-center', _style: "min-width: 195px"},
      { key: "Usuario", label: this.$t("label.user"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "min-width: 130px"},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "min-width: 130px"},
  ];
  } else {
    return [
      { key: "Nro", label: "#", _style: "width:2%;", _classes: "text-center", filter: false,},
      { key: "BL", label: 'BL', _style: "width:8%;", _classes: "text-center"},
      { key: "ClientName", label: this.$t("label.client"), _style: "width:13%;", _classes: "text-center"},
      { key: "Activity", label: this.$t("label.activity"), _style: "width:8%;", _classes: "text-center"},
      { key: "Cargo", label: this.$t("label.commodity"), _style: "width:20%;", _classes: "text-center"},
      { key: "ComputedWeight", label: this.$t("label.quantity")+' (TON)', _style: "width:15%;", _classes: "text-center"},
      { key: "Usuario", label: this.$t("label.user"), _style: "width:8%;", _classes: "text-center"},
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "width:10%;"},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "width:10%;"},
      { key: "Details", label: "", sorter: false, filter: false, _style: 'min-width:90px;', _classes:'text-center',},
    ];
  }
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    BL: "align-middle",
    ClientName: "align-middle",
    Activity: "align-middle",
    Cargo: "align-middle",
    ComputedWeight: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//data
function data() {
  return {
    Index: 0,
    ShowBlContainerList: false,
    ActivateGeneralCargoModal: false,
    isLoading: false,
    billOfLoadingId: '',
    items: [],
    // loading: false,
    modal: false,
    ModalReport: false,
    edit: false,
    billOfLoadingItem: {},
    GeneralCargoData: {},
    itemBl: {},
    activePage: 1,
    loadingTable: false,
  };
}

//methods
function getBillList() {
  this.loadingTable = true;
  this.$http
    .get("VisitBillOfLading-list", { VisitId: this.Visit })
    .then((response) => {
      this.items = response.data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingTable = false;
    });
}

function toggleModal(edit, item) {
  if (this.IsGeneralCargoVessel || this.IsContainerShipVessel) {
    this.edit = edit ? true : false;
    this.GeneralCargoData = item;
    this.ActivateGeneralCargoModal = true;
  } else {
    this.edit = edit ? true : false;
    this.billOfLoadingId = item ? item.StowagePlanningBillOfLadingId : '';
    this.modal = true;
  }
}

function toggleReport(item) {
  this.billOfLoadingId=item.VisitBillOfLadingId;
  this.ModalReport=true;
}

async function handleSubmit(){
  this.ModalReport = false;
  this.getBillList();
  this.modal = false;
  this.edit = false;
  this.billOfLoadingItem = {};
}

function Update(Bl, List) {
  this.itemBl = Bl ?? {};
  this.items = List ? [...List] : [];
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1);
  return this.items.map((bill) => {
    return {
      ...bill,
      Nro: Number.parseInt(bill.Nro),
      BL: bill.NroBl,
      ClientName: bill.ClientName,
      Activity: bill['PortActivityName'+this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1)],
      Cargo: bill.CommodityName,
      Commodity: bill['CommodityName'+_lang] ?? '',
      ComputedWeight: NumberFormater.formatNumber(bill.Weight, 2),
      VolumenM3: NumberFormater.formatNumber(bill.Volumen, 2),
      ComputedQuantityPlanning: NumberFormater.formatNumber(bill.QuantityPlanning, 2),
      ComputedQuantity: NumberFormater.formatNumber(bill.Quantity, 2),
      Usuario: bill.TransaLogin ? bill.TransaLogin : "N/A",
      Fecha: bill.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(bill.TransaRegDate)
        : "N/A",
      Status: bill.Status,
      _classes: bill.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    }
  });
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function IsGeneralCargoVessel() {
  return this.TpVesselId.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

function IsContainerShipVessel() {
  return this.TpVesselId.toUpperCase() == process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}

export default {
  name: "app-manifiesto-carga",
  mixins: [General],
  data,
  props: { currentModuleId: Boolean },
  components: {
    ManifiestoCargaModal,
    ModalReport,
    GeneralCargoModal,
    BlContainerList,
  },
  methods: {
    toggleModal,
    toggleReport,
    getBillList,
    handleSubmit,
    Update,
  },
  computed: {
    fields,
    cellTableClasses,
    formatedItems,
    getBranchId,
    IsGeneralCargoVessel,
    IsContainerShipVessel,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
      Visit: state => state.visitas.VisitId,
      TpVesselId: state => state.visitas.itinerarySelected.TpVesselId,
    }),
  },
  watch: {
    currentModuleId: async function (newValue) {
      if ((newValue) && (this.Visit!='')) {
        this.getBillList();
      }else{
        this.items = [];
        this.ShowBlContainerList = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-min-size {
  min-width: 130px;
  text-align: center;
}
.table-index {
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>