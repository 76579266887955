<template>
  <CRow>
    <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars" />
    <CCol sm="12">
      <CCollapse :show="!collapse && !configCollapse">
        <CCard>
          <CCardHeader class="text-center bg-dark text-white">
            <b>{{ $t('label.yardsList') }}</b>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12" xl="12">
                <dataTableExtended class="align-center-row-datatable" hover sorter small column-filter
                  :table-filter="tableText.tableFilterText" :items-per-page-select="tableText.itemsPerPageText"
                  :items="formatedItems" :fields="fields" :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage" pagination :loading="loading">
                  <template #loading>
                    <loading />
                  </template>
                  <template #acciones="{ item }">
                    <td class="text-center center-cell">
                      <CButton
                        color="watch"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.toView')+' '+$t('label.yardSetting'),
                          placement: 'top-end'
                        }"
                        @click="viewYardConfig(item)"
                      >
                        <CIcon name="eye" />
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCollapse>

      <CCollapse :show="configCollapse && !collapse">
        <CCard>
          <CCardHeader class="text-center bg-dark text-white">
            <b>{{ $t('label.selectedYardSettingList') }}: {{ getCurrentYardName }}</b>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" class="d-flex">
                <CButton
                  color="edit"
                  class="to-start"
                  v-c-tooltip="{
                    content: $t('label.return'),
                    placement: 'top',
                  }"
                  size="sm"
                  @click="returnYardList()"
                >
                  <CIcon name="cil-chevron-left-alt" />
                </CButton>
                <CButton
                  color="add"
                  class="d-flex align-items-center to-end"
                  v-c-tooltip="{
                    content: $t('label.yardSetting'),
                    placement: 'top-end'
                  }"
                  @click="newYardConfig()"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
              </CCol>
              <CCol col="12" xl="12">
                <dataTableExtended class="align-center-row-datatable" hover sorter small column-filter
                  :table-filter="tableText.tableFilterText" :items-per-page-select="tableText.itemsPerPageText"
                  :items="formatedConfigItems" :fields="fieldsConfig" :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage" pagination :loading="loading">
                  <template #loading>
                    <loading />
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell text-center">
                      <div>
                        <CBadge :color="getBadge(item.Status)">
                          {{$t('label.'+item.Status)}}
                        </CBadge>
                      </div>
                    </td>
                  </template>
                  <template #acciones="{ item }">
                    <td class="text-center center-cell actions-cell">
                      <CButton
                        color="edit"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.yardSetting'),
                          placement: 'top-end'
                        }"
                        @click="editConfig(item)"
                        :disabled="loading"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                      <CButton
                        color="watch"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.toView') + ' ' +$t('label.observation'),
                          placement: 'top-end'
                        }"
                        @click="getObservation(item)"
                      >
                        <CIcon name="eye" />
                      </CButton>
                      <CButton
                        color="print"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.print'),
                          placement: 'top-end'
                        }"
                        @click="printPdf(item)"
                        :disabled="loading"
                      >
                        <CIcon name="cil-print" />
                      </CButton>
                      <CButton
                        color="wipe"
                        size="sm"
                        class="ml-1"
                        v-c-tooltip="{
                          content: `${$t('label.Romeve')}`,
                          placement: 'top-start',
                        }"
                        :disabled="loading"
                        @click.stop="deleteConfirmation(item)"
                        v-if="item.YardConfigStatusId == 'CE1C43C0-5EDD-ED11-A88A-000D3A0DEDC7'"
                      >
                        <CIcon name="cil-trash" v-if="!loading"/>
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCollapse>

      <CCollapse :show="collapse && !configCollapse">
        <div v-if="collapse">
          <Menu :edit.sync="edit" @action="handleAction"/>
        </div>
      </CCollapse>

      <ObservationModal
        :modal.sync="observationModal"
        :observation="observation"
      />
    </CCol>
  </CRow>
</template>
<script>
import ENUM from '@/_store/enum';
import { mapState } from 'vuex';
import Menu from './menu-yard';
import { DateFormater, tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ObservationModal from './yards/observation-modal';

function fields() {
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:center;', filter: false },
    { key: 'Alfa', label: this.$t('label.group'), _style: 'width:10%;', _classes:"center-cell text-center" },
    { key: 'YardName', label: this.$t('label.yard'), _style: 'width:15%;', _classes:"center-cell text-center" },
    { key: 'YardAlias', label: this.$t('label.initials'), _style: 'width:10%;', _classes:"center-cell text-center" },
    { key: 'YardType', label: this.$t('label.yardType'), _style: 'width:15%;', _classes:"center-cell text-center" },
    { key: 'Teus', label: 'TEUS', _style: 'width:10%;', _classes:"center-cell text-center" },
    { key: 'AreaQuantity', label: this.$t('label.cantAreas'), _style: 'width:25%;', _classes:"center-cell text-center" },
    { key: 'BlockQuantity', label: this.$t('label.cantBlocks'), _style: 'width:25%;', _classes:"center-cell text-center" },
    {
      key: 'acciones',
      label: '',
      _style: 'min-width:90px; text-align:center;',
      sorter: false,
      filter: false
    },
  ];
}
function fieldsConfig() {
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:center;', filter: false },
    { key: 'Name', label: this.$t('label.name'),_classes:"center-cell text-center"},
    { key: 'Aproval', label: this.$t('label.approvedBy'),_classes:"center-cell text-center"},
    { key: 'Teus', label: this.$t('label.teus'),_style: 'width:10%;', _classes:"center-cell text-center"},
    { key: 'Ultimo', label: this.$t('label.user'),_style: 'width:10%;',_classes:"center-cell text-center"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;', _classes:"center-cell text-center"},
    { key: 'Estado', label: this.$t('label.status'), _style:'width:10%; text-align:center;', _classes:"center-cell text-center"},
    {
      key: 'acciones',
      label: '',
      _style: 'min-width:180px; text-align:center;',
      sorter: false,
      filter: false
    },
  ];
}

//created
function created() {
  this.getYards();
}

//data
function data() {
  return {
    loading: false,
    items: [],
    modal: false,
    edit: false,
    yardId: null,
    itemsConfig: [],
    observationModal: false,
    observation: '',
  }
}

//Method
function tableText() {
  return tableTextTranslatedHelpers(this);
}

function getYards() {
  this.$store.state.yardsetting.apiState = ENUM.LOADING;
  this.$http
    .get("YardContainer-list", {
      CompanyBranchId: this.branch ? this.branch.CompanyBranchId : '',
    })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).then(() => {
      this.$store.state.yardsetting.apiState = ENUM.INIT;
    });
}
function getConfigYard() {
  this.$store.state.yardsetting.apiState = ENUM.LOADING;
  this.$http
    .get("YardConfig-list", {
      YardId: this.yard ? this.yard.YardId : '',
    })
    .then((response) => {
      this.itemsConfig = response.data.data;
      this.$store.state.yardsetting.configCollapse = true;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      this.$store.state.yardsetting.collapse = true;
      this.$store.state.yardsetting.yardSelected = null;
    }).then(() => {
      this.$store.state.yardsetting.apiState = ENUM.INIT;
    });
}
function viewYardConfig(item){
  this.$store.state.yardsetting.yardSelected = item;
  this.getConfigYard();
}
function newYardConfig(){
  this.$store.state.yardsetting.collapse = true;
  this.$store.state.yardsetting.configCollapse = false;
}
function returnYardList(){
  this.$store.state.yardsetting.configCollapse = false;
  this.$store.state.yardsetting.yardSelected = null;
}
function editConfig(config) {
  this.$store.state.yardsetting.yardConfigSelected = {...config};
  this.edit = true;
  this.$store.state.yardsetting.collapse = true;
  this.$store.state.yardsetting.configCollapse = false;
}
function handleAction(action) {
  if(action == 'submit') {
    this.$store.state.yardsetting.collapse = false;
    this.$store.state.yardsetting.configCollapse = true;
    this.getConfigYard();
  }
  if(action == 'edit') {
    this.$store.state.yardsetting.collapse = false;
    this.$store.state.yardsetting.configCollapse = true;
    this.edit = false;
    this.$store.state.yardsetting.yardConfigSelected = null;
    this.getConfigYard();
  }
  
  return;
}
function getObservation(item){
    if(item.TransaUserApprovalId)
      this.observation = item.ObservationApproval;
    else if(item.TransaUserReverseId)
      this.observation = item.ObservationReverse;
    else
      this.observation = '';

    this.observationModal = true;
}
function printPdf(item) {
  let pageInfo = {
    layoutList: [
      ...item.YardConfigDetailJson[0].AreaJson.filter((item) => item.YardConfigDetailId != null),
      ...item.YardConfigDetailJson[0].BlockJson.filter((item) => item.YardConfigDetailId != null)
    ],
    yardInfo: this.yard,
    areas: [],
    teus: item.Teus
  };

  ldb.set('pageInfoYard', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfYard'});
  window.open(routeData.href, '_blank');
}
function handleDelete(item) {
  this.loading = true;

  let areasBlocks = [...item.YardConfigDetailJson[0].AreaJson, ...item.YardConfigDetailJson[0].BlockJson];
  areasBlocks = areasBlocks.filter((ab) => ab.YardConfigDetailId != null);

  let route = "YardConfig-insert";
  let YardConfigJson = {
    YardConfigId: item.YardConfigId, 
    YardId: item.YardId,
    ConfigDetailJson: areasBlocks.map((cfg) =>
      Object.assign({}, {
        YardConfigDetailId: cfg.YardConfigDetailId ? cfg.YardConfigDetailId : '',
        YardAreaId: cfg.YardAreaId ? cfg.YardAreaId : '', //Id del area del patio 
        YardBlockId: cfg.YardBlockId ? cfg.YardBlockId : '', //Id del  bloque
        x: cfg.x, //Posicion elemento en el eje x
        y: cfg.y, //Posicion elemento en el eje y
        h: cfg.h, //Posicion elemento altura
        w: cfg.w, //Posicion elemento ancho
        FgStatic: 0,
        Status: cfg.Status ? cfg.Status : 1,
      })
    ),
    FgBlockOrder:  item.FgBlockOrder,
    FgBayOrder: item.FgBayOrder,
    Status: 0
  };
  let metodo = "POST";

  this.$http
    .ejecutar(metodo, route, YardConfigJson, {
      root: "YardConfigJson",
    })
    .then((response) => {
      this.$notify({
        group: "container",
        title: "¡" + this.$t("label.success") + "!",
        text: response.data.data[0].Response,
        type: "success",
      });
      this.getConfigYard();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function deleteConfirmation(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.disableConfig')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.handleDelete(item);
    }
  });
}

//computed
function apiStateLoading() {
  let carga = false;
  if (this.apiState === ENUM.LOADING) {
    carga = !carga;
  }
  return carga;
}
function formatedItems() {
  let index = 1;
  return this.items.map((items) =>
    Object.assign({}, items, {
      Nro: index++,
      Alfa: items.YardGpoName ? items.YardGpoName : " ",
      YardType: this.$i18n.locale == 'es' ? items.TpYardNameEs : items.TpYardNameEn,
      Area: '',
    })
  );
}
function formatedConfigItems() {
  return this.itemsConfig.map((item) =>
    Object.assign({}, item, {
      Name: item.YardConfigName ? item.YardConfigName : " ",
      Aproval: item.TransaLoginApproval ? item.TransaLoginApproval : "",
      Estado: this.$i18n.locale == 'es' ? item.YardConfigStatusDsEs : item.YardConfigStatusDsEn,
      Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
      _classes: item.Status != 'ACTIVO'? 'table-danger': ''
    })
  );
}
function getCurrentYardName() {
  return this.yard ? this.yard.YardName : '';
}

//watch

export default {
  name: 'yards-setting',
  mixins:[GeneralMixin],
  components: {
    Menu,
    ObservationModal,
  },
  data,
  created,
  methods: {
    getYards,
    viewYardConfig,
    getConfigYard,
    newYardConfig,
    returnYardList,
    editConfig,
    handleAction,
    getObservation,
    printPdf,
    handleDelete,
    deleteConfirmation,
  },
  computed: {
    apiStateLoading,
    formatedItems,
    tableText,
    fields,
    fieldsConfig,
    formatedConfigItems,
    getCurrentYardName,
    ...mapState({
      apiState: state => state.yardsetting.apiState,
      collapse: state => state.yardsetting.collapse,
      configCollapse: state => state.yardsetting.configCollapse,
      branch: state => state.auth.branch,
      yard: state => state.yardsetting.yardSelected,
    })
  },
  watch: {
    collapse: function (newVal) {
      if(!newVal)
        this.edit = false;
    },
    configCollapse: function (newVal) {
      //if(newVal)
        //this.getConfigYard();
        
    }
  },
  beforeDestroy() {
    this.$store.state.yardsetting.collapse = false;
    this.$store.state.yardsetting.configCollapse = false;
    this.$store.state.yardsetting.yardConfigSelected = null;
    this.$store.state.yardsetting.yardSelected = null;
    this.$store.state.yardsetting.tabIndex = 0;
    this.$store.state.yardsetting.dropItem = 0;
    this.$store.state.yardsetting.printing = false;
    this.$store.state.yardsetting.dragging = false;
    this.$store.state.yardsetting.search = '';
    
  },
}
</script>

<style scoped>
.center-cell {
  vertical-align: middle;
}

.to-start {
  margin-right: auto;
}
.to-end {
  margin-left: auto;
}

.actions-cell {
  width: 10%;
  min-width: 155px;
}

</style>