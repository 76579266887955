<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <loading-overlay
        :active="loadingPdf"
        :is-full-page="true"
        loader="bars"
      />
      <draft-inspection-pdf
        :vessel="vessel"
        :commodity="commodity"
        :positions="positions"
        :positionHolds="positionHolds"
        :item="pageInfo.item"
        :itinerarySelected="pageInfo.itinerarySelected"
      />
    </div>
  </transition>
</template>

<script>
import DraftInspectionPdf from "./draft-inspection-pdf";
import debounce from "lodash/debounce";

//Data
function data() {
  return {
    pageInfo: {
      VisitDraftId: "",
    },
    vessel: [],
    commodity: [],
    positions: [],
    positionHolds: [],

    departure: [],
    loadingPdf: false,
  };
}

//Created
function created() {
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = { ...this.pageInfo, ...info };
  this.getDataFromService(this.pageInfo.VisitDraftId);

  localStorage.removeItem("pageInfo");

  window.addEventListener("beforeprint", function(event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

//Methods:
function getDataFromService(id) {
  this.loading = true;

  this.$http
    .get('VisitDraftVesselHold-list', { VisitDraftId: id } )
    .then(res => {
      this.vessel = res?.data?.data[0]?.VesselInfoJson[0]?.VesselDataJson ?? [];
      this.commodity = res?.data?.data[0]?.VesselInfoJson[0]?.CommodityJson ?? [];
      this.positions = res?.data?.data[0]?.VesselInfoJson[0]?.VesselPositionsJson ?? [];
      this.positions.map((item) => {
        if (item.FgHold) {
          this.positionHolds.push( item.PositionJson[0] );
        }
      });
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}

//Computed:

export default {
  name: "draft-inspection-PDF-Report",
  components: {
    DraftInspectionPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
  methods: {
    getDataFromService,
  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
