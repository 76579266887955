 <template>
    <form @submit.prevent="submitBody">
      <loading-overlay
        :active="loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="title"
        color="dark"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
        @update:show="$emit('close');"
      >
        <CRow>
            <CCol sm="12" lg="12">
            <CInput
              v-uppercase
              size="sm"
              :label="$t('label.code')"
              :placeholder="$t('label.code')"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              v-model="$v.UnNumber.$model"
              addLabelClasses="required text-right"
              :is-valid="hasError($v.UnNumber)"
              :invalid-feedback="errorMessage($v.UnNumber)"
            />
          </CCol>
          <CCol sm="12" lg="12">
            <CInput
              v-uppercase
              size="sm"
              :label="$t('label.name')+' '+'(ES)'"
              :placeholder="$t('label.UnNumber')+' '+'(ES)'"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              v-model="$v.UnNumberDsEs.$model"
              addLabelClasses="required text-right"
              :is-valid="hasError($v.UnNumberDsEs)"
              :invalid-feedback="errorMessage($v.UnNumberDsEs)"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="12">
            <CInput
              v-uppercase
              size="sm"
              :label="$t('label.name')+' '+'(EN)'"
              :placeholder="$t('label.UnNumber')+' '+'(EN)'"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              v-model="$v.UnNumberDsEn.$model"
              addLabelClasses="required text-right"
              :is-valid="hasError($v.UnNumberDsEn)"
              :invalid-feedback="errorMessage($v.UnNumberDsEn)"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="12">
            <CSelect
              size="sm"
              :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
              addLabelClasses="text-right required"
              :label="$t('label.imdgClass')"
              :options="ImdgClassComputed"
              v-model.trim="$v.ImdgClassId.$model"
              :value.sync="ImdgClassId"
              :is-valid="hasError($v.ImdgClassId)"
              :invalid-feedback="errorMessage($v.ImdgClassId)"
              @change="listCode($event)"
          />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="12">
            <CSelect
              size="sm"
              :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
              addLabelClasses="text-right required"
              :label="$t('label.imdgCode')"
              :options="ImdgCodeComputed"
              v-model.trim="$v.ImdgId.$model"
              :value.sync="ImdgId"
              :is-valid="hasError($v.ImdgId)"
              :invalid-feedback="errorMessage($v.ImdgId)"
          />
          </CCol>
        </CRow>
        <CRow>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            size="sm"
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>
        <template #footer>
            <CButton
                square
                color="add"
                class="d-flex align-items-center"
                @click.stop="isEdit ? statusConfirmation(unNumberItems.FgActUnNumber , Status, submitBody) : submitBody()"
                :disabled="isSubmit"
            >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
            <CButton
                square
                color="wipe"
                class="d-flex align-items-center"
                @click="$emit('close');"
            >
            <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
            </CButton>
      </template>
      </CModalExtended>
    </form>
  </template>
  <script>
  import ModalMixin from '@/_mixins/modal';
  import UnNumberFormValidations from '@/_validations/unNumber/unNumber';
  import UpperCase from '@/_validations/uppercase-directive';
  
  function data() {
    return {
      isSubmit: false,
      modalActive: false,
      UnNumberId: '',
      UnNumber: '',
      UnNumberDsEs: '',
      UnNumberDsEn: '',
      ImdgId: '',
      ImdgClassId: '',
      Status: 0,
      ImdgClassItems: [],
      ImdgCodeItems: [],
      loading: false,
    };
  }
  
  function submitBody() {
    try {
      this.isSubmit = true;
      this.$v.$touch();
      this.loading = true;
      if (this.$v.$error) {
        this.isSubmit = false;
        this.loading = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      let UnNumberJson = this.isEdit ? {
        UnNumberId:this.UnNumberId,
        ImdgId: this.ImdgId,
        ImdgClassId: this.ImdgClassId,
        UnNumber: this.UnNumber,
        UnNumberDsEs: this.UnNumberDsEs,
        UnNumberDsEn: this.UnNumberDsEn,
        Status: this.Status,
      }:{
        ImdgId: this.ImdgId,
        ImdgClassId: this.ImdgClassId,
        UnNumber: this.UnNumber,
        UnNumberDsEs: this.UnNumberDsEs,
        UnNumberDsEn: this.UnNumberDsEn,
      }
      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'ImdgUnNumber-update':'ImdgUnNumber-insert';
      this.$http
        .ejecutar(metodo, ruta, UnNumberJson,{ root: 'UnNumberJson'},)
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            const data = response.data.data[0];
            this.resetForm();
            this.$emit('close');
            this.$emit('Update');
            this.loading = false;
            this.isSubmit = false;
            this.notifySuccess({text: messageSuccess})
          } 
        }).catch((e) => {
          this.loading = false;
          this.notifyError({text: e});
        });
    } catch (e) {
      this.loading = false;
      this.notifyError({text: e});
    }
  }
  
  function getData(val) {
    this.UnNumberId= val.UnNumberId;
    this.ImdgId = val.ImdgId;
    this.ImdgClassId = val.ImdgClassId;
    this.UnNumber = val.UnNumber;
    this.UnNumberDsEs = val.UnNumberDsEs != 'N/A' ? val.UnNumberDsEs : '';
    this.UnNumberDsEn = val.UnNumberDsEn;
    this.Status = val.FgActUnNumber?1:0;

    this.$v.$touch();
  }

  function resetForm() {
    this.ImdgId = '',
    this.ImdgClassId = '',
    this.UnNumber = '',
    this.UnNumberDsEs = '',
    this.UnNumberDsEn = '',
    this.ImdgClassItems = [],
    this.ImdgCodeItems = []
    
    this.$v.$reset();
  }
  
  function statusSelectColor() {
    return this.Status === 1;
  }

  function getImdgClassList() {
    this.loading = true;
    this.$http
    .get('ImdgClass-list',{Filter: 'ACTIVO'})
    .then((response) => {
      if (response.data.data.length != 0) {
        this.ImdgClassItems = response.data.data
        if (this.isEdit) {
          this.getImdgCodeList();
        }
      }
    })
    .finally(() => {
    this.loading = false;
    });
  }

  function listCode(event) {
    this.ImdgId = '';
    this.ImdgCodeItems = [];
    this.ImdgClassId = event.target.value;
    if (this.ImdgClassId) {
        this.getImdgCodeList();
    } else {
        this.ImdgId = '';
        this.ImdgCodeItems = [];
    }   
  }
  
  function getImdgCodeList() {
    this.loading = true;
    this.$http
    .get('Imdg-list',{ImdgClassId: this.ImdgClassId})
    .then((response) => {
      if (response.data.data.length != 0) {
        this.ImdgCodeItems = response.data.data
      }
    })
    .finally(() => {
    this.loading = false;
    });
  }

  //computed
  function ImdgClassComputed(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if(this.ImdgClassItems.length === 0){
        return [{
        value: '', 
        label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
        value: '', 
        label: this.$t('label.select'),
        }]
        this.ImdgClassItems.map(function(e){
        chart.push({
            label: e[`ImdgClassName${_lang}`] ? e.ImdgClassAbbrev + ' - ' +  e[`ImdgClassName${_lang}`] : 'N/A',
            value: e.ImdgClassId,
        })    
        })
        return chart;
    }
    }

    function ImdgCodeComputed(){
    if(this.ImdgCodeItems.length === 0){
        return [{
        value: '', 
        label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
        value: '', 
        label: this.$t('label.select'),
        }]
        this.ImdgCodeItems.map(function(e){
        chart.push({
            label: e.ImdgCod,
            value: e.ImdgId,
        })    
        })
        return chart;
    }
    }

  export default {
    name: 'modal-unNumber',
    props: { modal: Boolean, unNumberItems: Object, isEdit: Boolean, title: String},
    data,
    mixins: [
      ModalMixin,
    ],
    validations: UnNumberFormValidations,
    directives: UpperCase,
    methods: {
      submitBody,
      getData,
      resetForm,
      statusSelectColor,
      getImdgClassList,
      listCode,
      getImdgCodeList
    },
    computed: {
      ImdgClassComputed,
      ImdgCodeComputed
    },
    watch: {
      modal: function(val){
        this.modalActive = val;
        if (val) {this.getImdgClassList();}
        if (this.isEdit==true) {
          this.getData(this.unNumberItems);
        }else{
          this.resetForm();
        };
      }
    },
   
  };
  </script>