<template>
  <div class="col-sm-12"  style="height: 100%;">
    <apexchart :width="Width" :height="Height" type="pie" :options="chartOptions" :series="Series"></apexchart>
  </div>
</template>
<script>
  function chartOptions(){
  return {
    chart: {
      type: 'pie',
      animations: {
        enabled: true,
      }
    },
    labels: this.Categories,
    colors: this.Colors.length!=0?this.Colors:undefined,
    stroke: {
      width: 0,
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      labels: {
        colors: '#60656e',
      },
    },
    tooltip: {
      enabled: true,
    },
    dataLabels: {
      enabled: true,
      background: {
        foreColor: "#ffffff",
      },
      dropShadow: {
          enabled: false,
      },
    },
  }    
}

export default {
  name:'simple-pie',
  props: {
    Series: {
      type: Array,
      require: true,
      default: () => [],
    },
    Categories:{
      type: Array,
      require: true,
      default: () => [],
    },
    Colors:{
      type: Array,
      require: false,
      default: () => [],
    },
    Width:{
      type: String,
      require: true,
      default: 'auto',
    },
    Height:{
      type: String,
      require: true,
      default: 'auto',
    },
  },
  methods:{
  },
  computed:{
    chartOptions
  },
}
</script>