<template>
    <div class="container-fluid top-menos">
      <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
      <modalDetentionCrane 
        :modal="showModalDetentionCrane" 
        :editModal="editModal"
        @closeModal="showModalDetentionCrane=false" 
        @child-refresh="datatableRefresh=true" 
      />
      <CRow>

      </CRow>
      <CRow>
        <CCol sm="12" lg="5" class="mt-4">
          <CSelect
            :label="$t('label.crane')"
            :horizontal="{ label: 'col-sm-12 col-lg-2 text-right', input: 'col-sm-12 col-lg-8'}"
            :options="craneListFormatted"
            v-model="VisitCraneId"
            @change="getDetentionCraneList()"
          />
        </CCol>
        <CCol sm="12" lg="7" class="d-flex align-items-center justify-content-end mt-4">
          <h6 class="text-warning mr-3 mt-2">
            <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentTime')}}&nbsp;
          </h6>
          <CButton
            square
            color="add"
            v-c-tooltip="{
              content: $t('label.detentionCrane'),
              placement: 'top-end'
            }"
            @click="showModalDetentionCrane=true; editModal=false"
          >
            <CIcon name="cil-playlist-add" /><span class="ml-1"
              >{{$t('label.nueva')}}</span
            >
          </CButton>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedItems"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #alertGifItem="{ item }">
              <td class="center-cell">
                <div class="animation-item ml-2" style="display: inline-block;" v-if="!item.TransactionFinish">
                  <h3 class="text-danger">
                    <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                  </h3>
                </div>
              </td>
            </template>
            <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
            </template>
            <template #options="{ item }">
              <td class="center-cell">       
                <CButton
                  v-if="item.FgActCraneEventGpo"
                  square
                  size="sm"
                  color="edit"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.detentionCrane'),
                    placement: 'top-end'
                  }"
                  @click="showModalDetentionCrane=item; editModal=true"
                >
                  <CIcon name="pencil" />
                </CButton>  
                <CButton
                  v-if="item.FgActCraneEventGpo"
                  color="wipe"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.delete')+' '+$t('label.detentionCrane'),
                    placement: 'top-end'
                  }"
                  @click="DeleteDetentionCrane(item)"
                >
                  <CIcon name="cil-trash" />  
                </CButton>          
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    </div>
  </template>
  <script>
  import { DateFormater } from '@/_helpers/funciones';
  import General from '@/_mixins/general';
  import { mapState } from 'vuex';
  import modalDetentionCrane from '@/pages/visita/tabs/tiempos/modal-detention-crane';
  
  function data() {
    return {
      items: [],
      dataCrane: [],
      showModalDetentionCrane: '',
      VisitCraneId: '', 
      loading: false, 
      datatableRefresh: false,
      editModal: false,
    };
  }
  
function fields(){
  return [
    { key: 'alertGifItem', label: '', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.crane'), key: 'CraneJson', _classes: 'text-center', _style: 'width:10%; text-align:center',},
    { label: this.$t('label.startDate'), key: 'FormaterTransactionBegin', _classes: 'text-center', _style:'width:11%' },
    { label: this.$t('label.finalDate'), key: 'FormaterTransactionFinish', _classes: 'text-center', _style:'width:11%' },
    { label: this.$t('label.time'), key: 'TotalTimeH', _classes: 'text-center', _style:'width:8%' },
    { label: this.$t('label.observation'), key: 'Observation', _classes: 'text-center', _style:'width:10%' },
    { label: this.$t('label.device'), key: 'DeviceCode', _classes: 'text-center', _style:'width:10%' },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:10%' },
    { label: this.$t('label.date'), key: 'TransaRegDate',_classes:'text-center', _style:'width:10%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center', _style:'width:8%' },
    { label: '', key: 'options', _style: 'min-width:90px; width:1%;', _classes:'text-center', sorter: false, filter: false },
  ];
}
  
function computedItems() {
  if (this.items.length==0) {
    return [];
  } else {
    return this.items.map((item) => {
      let CraneJson = item.VisitCraneDetailJson.map((element) => {
        return element.CraneAlias;
      })
      return {
        ...item,
        CraneJson: CraneJson ? CraneJson.join('-') : '',
        FormaterTransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
        FormaterTransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
        Observation: item.Observation ? item.Observation : '',
        TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

function craneListFormatted(){
var chart = [{
  value: '', 
  label: this.$t('label.select'),
}]
this.dataCrane.map(function(e){
  chart.push({
    value: e.VisitCraneId,
    label: e.CraneAlias,
  }) 
})
return chart;
}

function getDetentionCraneList() {
    this.loading = true;
    this.items = [];
    this.$http
        .get('VisitCraneDetention-list?VisitId='+this.Visit+'&VisitCraneId='+this.VisitCraneId)
        .then(res => {
            let listado = res.data.data;
            if (listado&&listado.length > 0) {
              this.items =  listado;
            }
        }).finally(() => {
        this.loading = false;
        });
}

function DeleteDetentionCrane(item) {
  this.$swal.fire({
    text: this.$t('label.changeStatusQuestion')+'?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: 'CONFIRMAR',
    cancelButtonText: 'CANCELAR'
  })
    .then((result) => {
      if (result.isConfirmed) {
        this.loadingOverlay = true;
        let VisitCraneJson = {
          VisitId: this.Visit,
          VisitCraneEventGpoId: item.VisitCraneEventGpoId,
          TransactionBegin: item.TransactionBegin ? item.TransactionBegin : '',
          TransactionFinish: item.TransactionFinish ? item.TransactionFinish : '',
          Observation: item.Observation,
          Status: 0,
          VisitCraneDetailJson: item.VisitCraneDetailJson,
        }
        this.$http.put('VisitCraneDetention-update', VisitCraneJson,{ root: 'VisitCraneJson' })
          .then(response => {
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
            this.refreshComponent()
          }).catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
            });
          }).finally(() => {
            this.loadingOverlay = false;
          });
      }
    })
}
  
async function refreshComponent() {
    this.items = [];
    this.dataCrane = [];
    this.VisitCraneId= '';
    this.showModalDetentionCrane= "";
    this.datatableRefresh = false;
    this.editModal = false;
    this.getDetentionCraneList();
    this.craneList();
}

function craneList() {
    this.loadingOverlay = true;
    this.$http.get("VisitOperativeCrane-list?VisitId="+this.Visit)
    .then(response => {
      this.dataCrane = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
    }).then(() => {
      this.loadingOverlay = false;
    });
  }
  
  export default {
    name: 'index-detention-crane',
    data,
    mixins: [General],
    components: {
        modalDetentionCrane,
    },
    methods: {
      DeleteDetentionCrane,
      getDetentionCraneList,
      refreshComponent,
      craneList,
    },  
    computed: {
        craneListFormatted,
        computedItems,
        fields,
        ...mapState({
            Visit: state => state.visitas.VisitId,
            itinerarySelected: state => state.visitas.itinerarySelected,
            dropItemTime: state => state.visitas.dropItemTime,
        })
    },
    watch: {
        dropItemTime:async function(newValue,OldValue){
          if (newValue == 3){
            if(this.$store.state.visitas.VisitId != ''){
              await  this.refreshComponent();
            }         
          }
        },
        datatableRefresh: function () {
            if (this.datatableRefresh) {                  
                this.getDetentionCraneList();
                this.datatableRefresh = false;
                this.editModal = false;
                this.showModalDetentionCrane= "";
            }
        },
    },
  };
  </script>

<style lang="scss">
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .not-margin-slot{
        .form-group {
            margin-bottom: 0px;
        }
    }
    .center-cell {
        text-align: center;
    }
 
    .animation-item{
        -webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
    }
    /**
    * ----------------------------------------
    * animation pulse
    * ----------------------------------------
    */
    @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
    @keyframes pulse{
        0%,
        100% {
            transform:scale(1.1)
        }
        50% {
            transform:scale(.8)
        }
    }
</style>