<template>
  <form @submit.stop.prevent="submit">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="getTitulo"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+('(ES)')"
            :placeholder="$t('label.eirTypeName')+' '+('(ES)')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            v-model="$v.TpEirNameEs.$model"
            :is-valid="hasError($v.TpEirNameEs)"
            :invalid-feedback="errorMessage($v.TpEirNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+('(EN)')"
            :placeholder="$t('label.eirTypeName')+' '+('(EN)')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            v-model="$v.TpEirNameEn.$model"
            :is-valid="hasError($v.TpEirNameEn)"
            :invalid-feedback="errorMessage($v.TpEirNameEn)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="12">
          <CSelect
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="statusConfirmation(TpEirObj.FgActTpEir, Status, submit )"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
          :disabled="isSubmit"
        >
          <CIcon name="x" />
          <span class="ml-1">
            {{$t('button.cancel')}}
          </span>
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirNameEs: '',
    TpEirNameEn: '',
    Status: 0,
    TpEirId: '',
    apiStateLoading: false,
    TpEirObj: {},
    TpEirItems: [],
    titulo: '',
  };
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  else
    this.$v.$touch();
  this.modalActive = newVal;
}

async function submit() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpEirJson = {
      TpEirId: this.TpEirId,
      TpEirNameEs: this.TpEirNameEs,
      TpEirNameEn: this.TpEirNameEn,
      Status: this.Status
    };

    await this.$http
      .put('TpEir-update', TpEirJson, { root: 'TpEirJson' })
      .then((response) => {
        if (response && response.status === 200) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpEirJson = data;
          this.resetForm();
          this.$emit('set-modal-edit-type-eir-list', TpEirJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function resetForm() {
  this.TpEirNameEs = '';
  this.TpEirNameEn = '';
  this.TpEirId = '';
  this.Status = 0;
  this.$v.$reset();
}

function mountInputsValues(item) {
  this.TpEirObj = item;
  this.TpEirNameEs = this.TpEirObj.TpEirNameEs;
  this.TpEirNameEn = this.TpEirObj.TpEirNameEn;
  this.TpEirId = this.TpEirObj.TpEirId;
  this.Status = this.TpEirObj.FgActTpEir?1:0;

  let _lang = this.$i18n.locale;
  let title = _lang=='en' ?  this.TpEirNameEn : this.TpEirNameEs;
  this.titulo = `${this.$t('label.edit')} ${this.$t('label.eirType')}: ${title}`;
}

function getTitulo() {
  return this.titulo;
}

export default {
  name: 'modal-edit-type-eir',
  props: { modal: Boolean, typeEirSelect: Object },
  data,
  mixins: [
    GeneralMixin, 
    ModalMixin
  ],
  directives: UpperCase,
  validations: {
    TpEirNameEs: { required, maxLength: maxLength(30) },
    TpEirNameEn: { required, maxLength: maxLength(30) },
    Status: { required },
  },
  methods: {
    toggle,
    resetForm,
    statusSelectColor,
    mountInputsValues,
    submit,
  },
  computed:{
    getTitulo
  },
  watch: {
    typeEirSelect: function(val) {
      this.mountInputsValues(val);
    },
  },
  
};
</script>
