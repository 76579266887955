<template>
  <div style="min-height: 880px;">
    <CRow>
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.FromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.ToDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
            <CButton
              color="watch"
              square
              size="sm"
              class="mx-1"
              @click="getYardWCargoStatisticsByEvent(carousel)"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              @click="Clear"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <h5 class="col-sm-12 text-center text-bold px-0">{{ $t('label.event') }}</h5>
      <CCol sm="12" class="px-1 mb-2">
        <label class="text-bold">{{ $t('label.quantity') }}</label>
        <div class="block-gray text-center">
          <CRow v-if="CurrentStatistic.length != 0" class="align-items-center m-0">
            <div class="col-sm-12 col-lg-6 px-1" style="height: 400px;">
              <PieDonut
                Width="100%"
                Height="100%"
                Type="pie"
                :Series="seriesPieQuantity"
                :Label="labelEvent"
                :Colors="colorsMovement"
              />
            </div>
            <div class="col-sm-12 col-lg-6 px-0 pt-3 px-2">
              <ChartBar
                distributed
                maxHeight="739px"
                Width="100%"
                Height="100%"
                :Series="seriesQuantity"
                :Categories="labelEvent"
                :Colors="colorsMovement"
                :dataLabels="dataLabelsBar"
                :xaxis="true"
                :yaxis="false"
                :grid="false"
              />
            </div>
          </CRow>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import ChartBar from '../chart/bar'
import PieDonut from '../chart/pieDonut'
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    CurrentStatistic: [],
  }
}

//methods
async function getYardWCargoStatisticsByEvent() {
  this.$store.state.yardManagement.loading = true;
  this.CurrentStatistic = [];
  let ReportJson = {
    YardId: this.yardData.YardId,
    DateStart: this.form.FromDay ? DateFormater.formatDateTimeWithoutSlash(this.form.FromDay) : '',
    DateEnd: this.form.ToDay ? DateFormater.formatDateTimeWithoutSlash(this.form.ToDay) : '',
  }

  await this.$http.post('YardWCargoStatistics-by-Event', ReportJson, { root: 'ReportJson' })
    .then(response => {
      this.CurrentStatistic = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    })
}

function validateDateRange(date) {
  return date < this.form.PreviousDate || date > this.form.LaterDate;
}

function statisticLegend(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item[`TpCargoName${_lang}`]
}

async function Clear() {
  await this.$emit('getDateRange');
  await this.getYardWCargoStatisticsByEvent();
}

//computed
function seriesPieQuantity() {
  return this.CurrentStatistic.map(item => item.Percentage);
}

function seriesQuantity() {
  let data = [];
  data.push({ data: this.CurrentStatistic.map(item => item.QuantityEvent) });
  return data;
}

function labelEvent() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.CurrentStatistic.map(item => item[`EventName${_lang}`]);
}

function colorsMovement() {
  return this.CurrentStatistic.map(item => item.Color);
}

function dataLabelsBar() {
  return {
    formatter: function (val, opts) {
      return NumberFormater.formatNumber(val, 2);
    },
  }
}

export default {
  name: 'warehouse-yard-situation-event',
  data,
  components: {
    ChartBar,
    PieDonut,
  },
  mixins: [GeneralMixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    active: Boolean,
  },
  methods: {
    getYardWCargoStatisticsByEvent,
    validateDateRange,
    statisticLegend,
    Clear,
  },
  computed: {
    seriesPieQuantity,
    seriesQuantity,
    labelEvent,
    colorsMovement,
    dataLabelsBar,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    active: async function(newVal) {
      if(newVal) {
        await this.getYardWCargoStatisticsByEvent();
      }
    },
  }
};
</script>
<style scoped>
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 0rem !important;
  border-radius: 5px;
}
.box-empty{
  width: 100%;
  height: 755px;
  padding-top: 1rem !important;
}
</style>