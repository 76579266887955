<template>
<CModalExtended
  :title="$t('label.nueva')+' '+$t('label.Scale')"
  color="dark"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  
    <CRow>
      <CCol sm="12" >
        <CInput
          :placeholder="branch.BranchName"                                        
          :horizontal="{label:'col-sm-12 col-lg-3 ', input:'col-sm-12 col-lg-8 '}"
          v-uppercase
          :label="$t('label.branch')"
          addLabelClasses=" text-right"
          maxlength="50"
          disabled
          size="sm"
        >
        </CInput>
      </CCol>
      <CCol sm="12" >
        <CInput
          :placeholder="$t('label.nameScale')"                                         
          :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8 '}"
          v-uppercase
          :label="$t('label.name')"
          addLabelClasses="required text-right"
          maxlength="50"
          v-model.trim="$v.bascula.nameScale.$model"
          :is-valid="hasError($v.bascula.nameScale)" 
          :invalid-feedback="errorMessage($v.bascula.nameScale)"
          size="sm"
        >
        </CInput>
      </CCol>
      <CCol sm="12" >
        <div class="form-group form-row mb-0">
            <label class="required text-right col-sm-11 col-lg-3 mb-0 col-form-label-sm">{{ $t('label.maxWeight') }}</label>
            <div class="input-group col-sm-11 col-lg-4 input-group-sm">
              <money
                  v-model.trim="$v.bascula.maxWeight.$model"
                  @blur="$v.bascula.maxWeight.$touch()"
                  v-bind="measure"
                  :class="computedInputSelect ? 'form-control is-valid' : (verifyWeigth ? 'form-control is-invalid' : 'form-control') "
              >             
              </money>
            </div>
            <div class="input-group col-sm-11 col-lg-4 input-group">
              <CSelect
                size="sm"
                class="w-100 mb-0"
                :options="unitMeasureOptions"
                v-model.trim="bascula.UnitMeasureId"
                :value.sync="bascula.UnitMeasureId"
                :is-valid="hasError($v.bascula.UnitMeasureId)" 
                :invalid-feedback="errorMessage($v.bascula.UnitMeasureId)"
                @blur="$v.bascula.UnitMeasureId.$touch()"
              />
              
            </div>
            <div class=" col-sm-11 col-lg-3" v-if="bascula.maxWeight <= 0 && verifyWeigth"></div>
            <div class="col-sm-11 col-lg-4 text-invalid-feedback" v-if="bascula.maxWeight <= 0 && verifyWeigth">
                {{$t('validation.required')}}
            </div>
            
          </div>
      </CCol>
    </CRow>

    <template #footer>
      <CButton
        color="add"
        class="m-2"
        :disabled="isSubmit"
        @click="submit"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </CButton>
      <CButton
        color="wipe"
        class="m-2"
        :disabled="isSubmit"
        @click="closeModal"
      >
        <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
  </CModalExtended> 
</template>

 

<script>
import scaleValidations from '@/_validations/bascula/scaleValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import { mapState } from "vuex";
import { VMoney, Money } from "v-money";

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    loadingOverlay: false,
    verifyWeigth: false,
    sucursal:'',
    bascula: {
      nameScale: '',
      maxWeight: '',
      UnitMeasureId: '',
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    unitMeasureOptions: [],
  }
}


function submit() {
  try {
    this.isSubmit = true;
    this.loadingOverlay = true;
    this.$v.bascula.$touch();
      if (this.$v.bascula.$error) {
        this.verifyWeigth = true;
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
    let TruckWeighingScaleJson = {
        CompanyBranchId: this.branch.CompanyBranchId,
        TruckWeghingScaleName: this.bascula.nameScale,
        UnitMeasureWeightId: this.bascula.UnitMeasureId,
        MaxWeight: this.bascula.maxWeight,
    };

    this.$http.post('TruckWeighingScale-insert', TruckWeighingScaleJson, { root: 'TruckWeighingScaleJson'} )
    .then(response => {
      this.closeModal();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.loadingOverlay = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }

}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  this.modalActive = newVal;
  this.getUnitMeasureList();

}

function resetForm() {
  this.bascula.nameScale = '';
  this.bascula.maxWeight = '';
  this.bascula.UnitMeasureId = '';
  this.verifyWeigth = false;
  this.$v.$reset();
}

 //lista um
  async function getUnitMeasureList() {
    this.loadingOverlay = true;
    this.unitMeasureOptions = [];
    let listado = Array;

    await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
    .then(response => {
      listado = [...response.data.data];

      this.unitMeasureOptions = listado.map(listado => Object.assign({}, this.unitMeasureOptions, {
        value: listado.UnitMeasureId,
        label: listado.UnitMeasureAbbrev,
      }));

      if(this.unitMeasureOptions.length > 0)
        this.bascula.UnitMeasureId = this.unitMeasureOptions[0].value;  
      
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

  function closeModal() {
    this.resetForm();
    this.$emit('submited');
    this.modalActive = false;
  }

//computadas
function computedInputSelect() {
  if (this.bascula.maxWeight <= 0) {
    return false;
  } else {
    return true;
  }
}

export default {
  name: 'modal-create-bascula',
  mixins: [ModalMixin],
  validations: scaleValidations,
  props: {
    modal: Boolean
  },
  components: {
    Money,
  },
  data,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getUnitMeasureList,
    closeModal,
  },
  computed: { 
    computedInputSelect,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
   },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>