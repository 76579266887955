<template>
    <div>
       <CModalExtended
           color="dark"
           size="lg"
           :closeOnBackdrop="false"
           :title="tituloModal"
           :show.sync="$store.state.ubicacion.modalPais"
       >
           <CRow>
               <CCol sm="12 mb-3" lg="4">
                   <!--<CInputFile
                       @change="handleFileUpload"
                       style="display:none"
                       id="fileregisterimage"
                       ref="fileregisterimage"
                       drop-placeholder="Drop file here..."
                       data-validation="mime size" 
                       data-validation-allowing="jpg, png" 
                       data-validation-max-size="2M"
                   />
                   <label id="c-image" for="fileregisterimage">
                       <img
                           :src="srcImage(formPais.Image_url)"
                           class="img-thumbnail mb-2"
                           @error="setAltImg"
                       />
                       <div class="text">
                           <h1><CIcon name="cil-eye"/></h1>
                       </div>
                   </label>-->
                   <div class="preview">    
                       <picture-input
                           ref="imageInput"
                           :imgSrc="formPais.Image_url"
                           width="200"
                           height="200"
                           accept="image/jpeg,image/png,image/svg+xml"
                           size="10"
                           :custom-strings="imgInputTexts"
                           @change="handleFileUpload"
                           v-if="$store.state.ubicacion.modalPais"
                       >
                       </picture-input>
                   </div>
                   <div v-if="$v.formPais.svgIcon.$error">
                       <div class='text-danger text-center' v-if="!$v.formPais.svgIcon.isValidFile">{{$t('label.badImage')}}</div>
                   </div>
                   
               </CCol>
               <CCol sm="12" lg="8" >
                       <!-- <label class="required mr-2 mt-2" for="nombrePais">{{$t('label.name')}}</label>
                       <autocomplete
                           style="width: 270px;"
                           @input="cambioNombre($event)"
                           :value.sync="formPais.NbPais"
                           :items="optionsListPaises"
                           :placeHolder="$t('label.countryName')"
                           :invalid-feedback="errorMessage($v.formPais.NbPais)"
                           :is-valid="hasError($v.formPais.NbPais)"
                       ></autocomplete>  -->
                       <CCol sm="12">
                           <div class="form-group form-row">
                               <label class="col-form-label-sm text-right col-sm-12 col-lg-5 mb-0 required">{{$t('label.name')}}</label>
                               <div class="col-sm-12 col-lg-7 ">
                                   <v-select
                                       :placeholder="$t('label.select')"
                                       :options="optionsListPaises"
                                       style="width: 100%;"
                                       :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                       v-model.trim="NbPais"
                                       :value.sync="formPais.NbPais"
                                       :class="computedSelectCountry ? 'select-country--correct' : (verifyCountry ? 'select-country--error' : '') "
                                       id="v-select-small"
                                       size="sm"
                                   />  
                                   <div v-if="$v.formPais.NbPais.$error">
                                       <div class='text-danger text' v-if="!$v.formPais.NbPais.required">
                                           {{$t('label.required')}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </CCol>
         
                   <CCol sm="12" class="mt-2">
                       <CInput
                           :label="$t('label.alpha2Code')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           :placeholder="$t('label.alpha2Code')"
                           v-uppercase
                           v-model="$v.formPais.CodIsoAlpha2.$model"
                           :invalid-feedback="errorMessage($v.formPais.CodIsoAlpha2)"
                           :is-valid="hasError($v.formPais.CodIsoAlpha2)"
                           size="sm"
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CInput
                           :label="$t('label.alpha3Code')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           :placeholder="$t('label.alpha3Code')"
                           v-model="$v.formPais.CodIsoAlpha3.$model"
                           :invalid-feedback="errorMessage($v.formPais.CodIsoAlpha3)"
                           :is-valid="hasError($v.formPais.CodIsoAlpha3)"
                           size="sm"
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CInput
                           :label="$t('label.latitude')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           v-uppercase
                           :placeholder="$t('label.latitude')"
                           v-model="$v.formPais.Latitude.$model"
                           :invalid-feedback="errorMessage($v.formPais.Latitude)"
                           :is-valid="hasError($v.formPais.Latitude)" 
                           size="sm"
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CInput
                           :label="$t('label.longitude')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           :placeholder="$t('label.longitude')"
                           v-uppercase
                           v-model="$v.formPais.Longitude.$model"
                           :invalid-feedback="errorMessage($v.formPais.Longitude)"
                           :is-valid="hasError($v.formPais.Longitude)" 
                           size="sm"                           
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CInput
                           :label="$t('label.number')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           placeholder="000"
                           v-model="$v.formPais.Numeric.$model"
                           :invalid-feedback="errorMessage($v.formPais.Numeric)"
                           :is-valid="hasError($v.formPais.Numeric)"
                           size="sm"
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CInput
                           label="UTC ZONE"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           placeholder="+00:00"
                           v-model="$v.formPais.UtcZone.$model"
                           :is-valid="hasError($v.formPais.UtcZone)"
                           :invalid-feedback="errorMessage($v.formPais.UtcZone)"  
                           size="sm"                      
                       >
                       </CInput>
                   </CCol>
                   <CCol sm="12">
                       <CSelect
                           :label="$t('label.status')"
                           addLabelClasses="required text-right"
                           :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                           :is-valid="formPais.FgActPais"
                           v-if="!idIndentification"
                           v-model="formPais.FgActPais"
                           :value.sync="formPais.FgActPais"
                           :options="selectOptions"
                       />
                   </CCol>
               </CCol>
           </CRow>
           <div slot="footer">
               <CButton
                   color="add"
                   class="m-2"
                   @click="registerData"
                   outline 
                   :disabled="isSubmit"
               >
                   <CIcon name="checkAlt"/>&nbsp;{{$t('button.accept')}}
               </CButton>
               <CButton
                   color="wipe"
                   @click="cerrarModal"
                   class="m-2"
                   :disabled="apiStateLoading || loading "
               >
               <CIcon name="x"/>&nbsp;
                   {{$t('button.cancel')}}
               </CButton>
           </div>
       </CModalExtended>
   </div>
</template>
<script>
   import ENUM from '@/_store/enum';
   import { mapState,mapMutations } from 'vuex';
   import { FormPais } from '@/_validations/ubicacion/UbicacionValidation';
   import General from "@/_mixins/general";
   import UpperCase  from '@/_validations/uppercase-directive';
   import TipoGruaMixin from '@/_mixins/tipo-grua';
   import { GenerateImgPlaceholder } from '@/_helpers/funciones';
   import PictureInput from '@/components/picture-input';
   import autocomplete from '@/components/autocomplete';
   import ModalMixin from "@/_mixins/modal";
import { off } from 'process';

   function data() {
       return {
           srcError:'/img/errorimage.jpg',
           NbPais: '',
           formPais:{
               idPais:0,
               NbPais:'',
               CodIsoAlpha2:'',
               CodIsoAlpha3:'',
               Latitude:'',
               Longitude:'',
               Numeric:0,
               UtcZone:'',
               svgIcon:'',
               Image_url:'',
               FgActPais:true,
               Image_url2:'',
               Image_url :'/img/uploadPhotoEs.svg'
           },
           Image_original:null,
           originalAct:'',            
           selectPaisOriginal:false,
           paisSelected:'',
           loading:false,
           dataListPaises:[],
           optionsListPaises:[],
           verifyCountry: false,
           tituloModal:'',
           isSubmit:false
       }
   }

   //methods
   function handleFileUpload(files, e) {
     //  this.formPais.Image_url = '';
       this.formPais.svgIcon = this.$refs.imageInput.file;
       this.$v.formPais.svgIcon.$touch();        
   }
   function cambio(e){
       this.formPais.FgActPais = !this.formPais.FgActPais;
   }
   function cerrarModal(){
       if(this.apiStateLoading || this.loading)
           return
       this.$store.commit('ubicacion/mutationModalPais', false);
   }
   function toKebabCase (str) {
     return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
   }
   function getInformationPais() {
       this.loading = true;
       this.$http.getInformationPaislist().then(response => {
           this.loading = false;
           if(response.length > 0){
               this.dataListPaises = response;
               const chart = [];
               this.dataListPaises.map(function(e){
                   chart.push({
                       value: e.cca3, 
                       label: e.translations.spa.common,
                   })
               })
               this.optionsListPaises = chart;
           }
       }).catch(err => {
           this.loading = false;
       });
   }
   function registerData(){
       try {
           this.isSubmit=true;
           this.verifyCountry = true;
           this.$v.$touch();
           if (this.$v.$error){
               throw this.$t('label.errorsPleaseCheck');
           }
           let { idPais,NbPais,CodIsoAlpha2,CodIsoAlpha3,Numeric,UtcZone,svgIcon,FgActPais,Latitude,Longitude } = this.formPais;
           const { dispatch } = this.$store;
           const Image_original = this.Image_original;
           if(this.originalAct !== '' && idPais !== 0){
               if(this.originalAct !== FgActPais){                
                   this.$swal
                       .fire(this.alertProperties({
                           text: `${this.$t('label.changeStatusQuestion')+' '} ${this.NbPais}?`,
                       }))
                       .then((result) => {
                       if (result.isConfirmed) {
                           dispatch('ubicacion/postregisterPais', { idPais,NbPais,CodIsoAlpha2,CodIsoAlpha3,Numeric,UtcZone,svgIcon,FgActPais,Latitude,Longitude,Image_original });
                       }else {
                           // this.$swal('Operación cancelada');
                       }
                   })
               }else{
                   dispatch('ubicacion/postregisterPais', { idPais,NbPais,CodIsoAlpha2,CodIsoAlpha3,Numeric,UtcZone,svgIcon,FgActPais,Latitude,Longitude,Image_original });
                   this.isSubmit=false;
               }
           }else{
               dispatch('ubicacion/postregisterPais', { idPais,NbPais,CodIsoAlpha2,CodIsoAlpha3,Numeric,UtcZone,svgIcon,FgActPais,Latitude,Longitude,Image_original });
               this.isSubmit=false;
           }
          
       } catch (err) {
           this.isSubmit=false;
           this.$notify({
               group: 'container',
               title: '¡Error!',
               text: err,
               type: "error"
           });
       }
}

   //mounted
   function mounted() {
       this.getInformationPais();
   }
   //computed
   function idIndentification(){
       return this.formPais.idPais === 0;
   }

   function apiStateLoading() {
       return this.apiState === ENUM.LOADING;
   }
   function apiStateFormLoading() {
       let carga = false;
       if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
           carga = !carga;
       }
       return carga;
   }
   async function cambioNombre(e){
       this.$v.formPais.NbPais.$touch();
       if(typeof e === 'object'){
           this.$v.formPais.NbPais.$model = e.label;
           this.paisSelected = e.value;
       }else{
           this.$v.formPais.NbPais.$model = e;
       }
   }
   function selectOptions(){
       return [
               { 
                   value: true, 
                   label: this.$t('label.ACTIVO')
               },
               { 
                   value: false, 
                   label: this.$t('label.INACTIVO')
               }
           ]
   }
   function computedSelectCountry() {
       if (this.formPais.NbPais === null||this.formPais.NbPais === '') {
       return false;
       } else {
       return true;
       }
   }
   //watch
   async function paisSelected(newQuestion,Oldquestion){
       if(!this.selectPaisOriginal){
           if(newQuestion !== ""){
               const x = this.dataListPaises.map(function(e) {
                   return e.cca3; 
               }).indexOf(newQuestion);
               if(x !== -1){
                   this.formPais.CodIsoAlpha2 = this.dataListPaises[x].cca2;
                   
                   this.formPais.Image_url = this.dataListPaises[x].flags.svg; 

                   const response = await fetch(this.dataListPaises[x].flags.svg);
                   const blob = await response.blob();
                   const file = new File([blob], this.dataListPaises[x].flags.svg, {type: blob.type});
                   this.formPais.svgIcon = file;
                   //this.formPais.Image_url2 = URL.createObjectURL(file); 

                   this.formPais.CodIsoAlpha3 = this.dataListPaises[x].cca3;
                   this.formPais.Numeric = this.dataListPaises[x].ccn3;
                   const latlng = this.dataListPaises[x].latlng;
                   this.formPais.Latitude = latlng[0];
                   this.formPais.Longitude = latlng[1];
                   
                   let text = this.dataListPaises[x].timezones[0];
                   const regex = /UTC/gi;
                   this.formPais.UtcZone = text.replace(regex,"");
               }
           }
       }
       this.selectPaisOriginal = false;
   }

function modalPais(newQuestion,Oldquestion){
       if(newQuestion === false){
           this.NbPais = '';
           this.tituloModal='';
           this.formPais.idPais=0;
           this.formPais.NbPais='';
           this.formPais.CodIsoAlpha2='';
           this.formPais.CodIsoAlpha3='';
           this.formPais.Numeric=0;
           this.formPais.UtcZone='';
           this.formPais.svgIcon='';
          // this.formPais.Image_url ='';
           this.formPais.Image_url ='/img/uploadPhotoEs.svg';
           this.formPais.Latitude='';
           this.formPais.Longitude='';
           this.formPais.FgActPais=true;
           this.paisSelected = '';
           this.Image_original = null;
           this.originalAct = '';
           this.selectPaisOriginal = false;
           this.verifyCountry = false;
           this.isSubmit=false;
           this.$nextTick(() => { this.$v.$reset() })
           this.$store.commit('ubicacion/asignarid', 0);
       }else{
           if(this.idState === 0){
               this.tituloModal = this.$t('label.nuevo')+this.$t('label.country');
           }
       }
   }
   function validURL(str) {
       var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
       '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
       '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
       '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
       return !!pattern.test(str);
   }
   function idState(newQuestion,Oldquestion){
       if(this.modalPais){
           if(newQuestion !== 0){
               this.$store.state.ubicacion.apiStateForm = ENUM.LOADING;
               if(this.iconRoute != null && this.iconRoute != ''){
                   this.formPais.Image_url = this.iconRoute;
               }else{
                   let _lang = this.$i18n.locale;
                   this.formPais.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
               }

               this.$http.ejecutar('GET', 'Country-by-id',{ CountryId: newQuestion }, '' ).then(response => {
                   if(response.status === 200){
                       const paisInformation = response.data.data;
                       if(paisInformation.length !== 0){
                           this.NbPais = paisInformation[0].CountryName;
                           this.tituloModal = this.$t('label.edit')+this.$t('label.country')+": "+paisInformation[0].CountryName;
                           this.formPais.idPais = paisInformation[0].CountryId;
                           this.formPais.NbPais = paisInformation[0].CountryName;
                           this.formPais.CodIsoAlpha2 = paisInformation[0].IsoCodeAlpha2;
                           this.formPais.CodIsoAlpha3 = paisInformation[0].IsoCodeAlpha3;
                           this.formPais.Numeric = paisInformation[0].Numeric;
                           this.formPais.UtcZone = paisInformation[0].UtcZone;
                           this.formPais.svgIcon=paisInformation[0].Icon;
                           this.formPais.Latitude=paisInformation[0].Latitude;
                           this.formPais.Longitude=paisInformation[0].Longitude;
                           const icon = paisInformation[0].Icon;
                           this.Image_original = icon;
                           this.verifyCountry = true;

                           /*if(this.validURL(icon)){
                               this.formPais.Image_url = icon;
                           }else{
                               this.formPais.Image_url = icon?`${this.$store.getters["connection/getBase"]}${icon.replace('public/', '', 'i')}`:GenerateImgPlaceholder();
                           }

                           */
                           this.selectPaisOriginal = true;
                           this.paisSelected = paisInformation[0].IsoCodeAlpha3;
                           this.originalAct = paisInformation[0].FgActCountry;
                           this.formPais.FgActPais = paisInformation[0].FgActCountry;
                           this.$store.state.ubicacion.apiStateForm = ENUM.INIT;
                           this.$v.$touch();
                       }else{
                           this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                       }
                   }else{
                       this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                       this.$store.commit('ubicacion/messageMutation', response);
                       this.$store.commit('ubicacion/mutationModalPais', false);
                   }
               }).catch(err => {
                   this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                   this.$store.commit('ubicacion/messageMutation', err);
                   this.$store.commit('ubicacion/mutationModalPais', false);
               });
           }

       }
   }

   export default {
       name: 'app-modal-pais',
       data,
       mounted,
       mixins: [TipoGruaMixin,General,ModalMixin],
       validations(){ return FormPais() },
       computed: { 
           idIndentification,
           selectOptions,
           apiStateLoading,
           apiStateFormLoading,
           computedSelectCountry,
           ...mapState({
               idState: state=> state.ubicacion.id,
               modalPais: state=> state.ubicacion.modalPais,
               apiState: state => state.ubicacion.apiState,
               apiStateForm: state => state.ubicacion.apiStateForm,
               iconRoute: state => state.ubicacion.iconRoute,
           })
       },
       components: {
           PictureInput,
           autocomplete
       },
       directives: UpperCase,
       methods:{
           validURL,
           cambioNombre,
           toKebabCase,
           cerrarModal,
           registerData,
           handleFileUpload,
           getInformationPais,
           cambio
       },
       watch:{
           modalPais,
           idState,
           paisSelected,
           NbPais: function (val) {
               this.$v.$touch();
               if ((val!='') && (val!=undefined) && (val!=null)) {
                   this.cambioNombre(val);    
               }else if(((val=='') && (val==undefined) && (val!=null)) && (this.idState!=0)){
                   this.verifyCountry = true;
               }
           }
       }
   }
</script>

<style lang="scss">

.select-country--correct {
   .vs__dropdown-toggle {
       border-color: #2eb85c !important;
   }
}
.select-country--error {
   .vs__dropdown-toggle {
       border-color: #e55353 !important;
   }
}
.text{
  font-size: 11.2px !important;
}
</style>

