<template>
    <div style="width: 100%">
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1">
                <div v-if="carruselTab === 1"  class="p-arrows-carousel" >
                    <div v-if="banderaCranes">
                        <CranesMovements
                            :CranesAverageCompletedMovs="CranesAverageCompletedMovs"
                        />
                    </div>
                </div>
                <CCollapse :show="carruselTab === 2"  class="p-arrows-carousel" >
                    <div class="card" style="width: 100%;border-color: #4d5a70 !important;" v-if="seriesMultipleTimeLine.length !== 0">
                        <div
                            class="card-header"
                            :style="
                                'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                            "
                        >
                            <span>{{ $t('label.CranesTimeLine') }}</span>
                        </div>
                        <div class="card-body">
                            <multipleSeriesTimeLine 
                                height="400px"
                                :series="seriesMultipleTimeLine"
                                :colors="colorsSeriesTimeLine"
                            ></multipleSeriesTimeLine>
                        </div>
                    </div>
                </CCollapse>
                <CCollapse :show="carruselTab === 3"  class="p-arrows-carousel" v-if="FgBulk || FgGeneralCargo">
                    <SituacionActualGrua
                        :Vessel="Vessel"
                        :VesselSituation="VesselSituation"
                        :CraneHold="CraneHold"
                    ></SituacionActualGrua>
                </CCollapse>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapState } from 'vuex';
    import multipleSeriesTimeLine from "../../components/multipleSeriesTimeLine";
    import VistaTransversal from "../../components/vista-transversal";
    import CranesMovements from "./CranesMovements";
    import SituacionActualGrua from "./SituacionActualGrua";
    //data
    function data() {
        return {
            interval: null,
            CranesAverageCompletedMovs: [],
            CranesTime: [],
            CraneHold: [],
            VesselSituation: {},
        }
    }
    // method
    function getTabCrane() {
        this.$store.state.situacionOperativaActual.Loading = true;
        this.$http.ejecutar('GET', 'VisitOperationalSituationTabCrane', {VisitId: this.Vessel.VisitId})
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    this.CranesAverageCompletedMovs = List[0]?.ServicedVesselHistJson[0]?.CranesAverageCompletedMovs ?
                        List[0].ServicedVesselHistJson[0].CranesAverageCompletedMovs : [];
                    this.CranesTime = List[0]?.ServicedVesselHistJson[0]?.CranesTimeJson ? 
                        List[0].ServicedVesselHistJson[0].CranesTimeJson : [];
                    this.VesselSituation = List[0]?.VesselSituationJson[0] ? List[0]?.VesselSituationJson[0] : {};
                    this.CraneHold = List[0]?.HoldSituationJson[0]?.CraneHoldJson ? List[0].HoldSituationJson[0].CraneHoldJson : [];
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        let maxOperation = this.FgContainerShip ? 2 : 3;
        if(this.$store.state.situacionOperativaActual.carruselTab !== maxOperation){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }
    }
    //computed
    function banderaCranes(){
        if(this.FgContainerShip){
            return this.$store.state.situacionOperativaActual.tabsOperationes === 3 && this.$store.state.situacionOperativaActual.carruselTab === 1; 
        }
        return this.$store.state.situacionOperativaActual.tabsOperationes === 4 && this.$store.state.situacionOperativaActual.carruselTab === 1; 
    }
    function minCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab > 1
    }
    function maxCarrusel(){
        let maxOperation = this.FgContainerShip ? 2 : 3;
        return this.$store.state.situacionOperativaActual.carruselTab < maxOperation;
    }
    function seriesMultipleTimeLine(){
        let series = [];
        let theValueIs = this.$t('label.lang');
        let DataCranes = this.CranesTime;
        DataCranes.map(async(item) => {
            let disimis = item.DismissedJson[0];
            let labelDisimissed = theValueIs == "ESPAÑOL" ? disimis.Label[0].Es : disimis.Label[0].En;
            let dataDissmissed = []
            disimis.data.map(async(itemDismissed) => {
                for (let index = 0; index < itemDismissed.y.length; index++) {
                    let dateIni = moment(itemDismissed.y[index].TransactionBegin,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    let dateFnl = moment(itemDismissed.y[index].TransactionFinish,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    dataDissmissed.push({
                        x: itemDismissed.x,
                        y: [dateIni,dateFnl]
                    })
                }
            })
            series.push({
                name: labelDisimissed,
                data: dataDissmissed,
            })

            let working = item.WorkingJson[0];
            let labelWorking = theValueIs == "ESPAÑOL" ? working.Label[0].Es : working.Label[0].En;
            let dataWorking = []
            working.data.map(async(itemWorking) => {
                for (let index = 0; index < itemWorking.y.length; index++) {
                    let dateIni = moment(itemWorking.y[index].TransactionBegin,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    let dateFnl = moment(itemWorking.y[index].TransactionFinish,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    dataWorking.push({
                        x: itemWorking.x,
                        y: [dateIni,dateFnl]
                    })
                }
            })
            series.push({
                name: labelWorking,
                data: dataWorking,
            })

            let stopped = item.StoppedTimeJson[0];
            let labelStopped = theValueIs == "ESPAÑOL" ? stopped.Label[0].Es : stopped.Label[0].En;
            let dataStopped = []
            stopped.data.map(async(itemStopped) => {
                for (let index = 0; index < itemStopped.y.length; index++) {
                    let dateIni = moment(itemStopped.y[index].TransactionBegin,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    let dateFnl = moment(itemStopped.y[index].TransactionFinish,"DD/MM/YYYY HH:mm").subtract(4,'h').valueOf();
                    dataStopped.push({
                        x: itemStopped.x,
                        y: [dateIni,dateFnl]
                    })
                }
            });
            
            series.push({
                name: labelStopped,
                data: dataStopped,
            })
        });
        return series;
    }
    function colorsSeriesTimeLine(){
        return ['#E5363F','#26E7A6','#FDBF01']
    }
    function getToDeck() {
        return this.$store.state.situacionOperativaActual.transversalView.toDeck;
    }
    function getUnderDeck() {
        return this.$store.state.situacionOperativaActual.transversalView.underDeck;
    }
    export default {
        name:'HistoryVesselsServed',
        data,
        props: {
            Vessel: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            ServicedVesselHist: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            VesselSituationJson: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            HoldSituationJson: {
                type: Object,
                default: function () {
                    return {};
                },
            },
        },
        components:{
            multipleSeriesTimeLine,
            VistaTransversal,
            CranesMovements,
            SituacionActualGrua
        },
        methods:{
            getTabCrane,
            disminuirCarrusel,
            aumentarCarrusel,
            _setInterval: function() {
                this.interval = setInterval(() => {
                    let maxOperation = this.FgContainerShip ? 2 : 3;
                    if(this.$store.state.situacionOperativaActual.carruselTab < maxOperation){
                        this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaActual.carruselTab = 1;
                    }   
                }, 60000);
            }
        },
        beforeDestroy(){
            clearInterval(this.interval);
            this.interval = null;
        },
        computed:{
            banderaCranes,
            seriesMultipleTimeLine,
            colorsSeriesTimeLine,
            getToDeck,
            getUnderDeck,
            minCarrusel,
            maxCarrusel,
            ...mapState({
                FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
                FgBulk: state => state.situacionOperativaActual.FgBulk,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                carrusel: state => state.situacionOperativaActual.carrusel,
                tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
            })
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                let tabsOperation = this.FgContainerShip ? 3 : 4;
                if(OldValue === tabsOperation){
                    this.CranesAverageCompletedMovs = [];
                    this.CranesTime = [];
                    this.CraneHold = [];
                    this.VesselSituation = {};
                    this.$store.state.situacionOperativaActual.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === tabsOperation){
                    if (this.FgBulk) {
                        this.getTabCrane();
                    } else {
                        this.CranesAverageCompletedMovs = this.ServicedVesselHist?.CranesAverageCompletedMovs ? 
                            this.ServicedVesselHist.CranesAverageCompletedMovs : [];
                        this.CranesTime = this.ServicedVesselHist?.CranesTimeJson ? this.ServicedVesselHist.CranesTimeJson : [];
                        if (this.FgGeneralCargo) {
                            this.VesselSituation = this.VesselSituationJson ?? {};
                            this.CraneHold = this.HoldSituationJson?.CraneHoldJson ?? [];
                        }
                    }
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue,OldValue){
                let tabsOperation = this.FgContainerShip ? 3 : 4;
                if(this.tabsOperationes === tabsOperation){
                    if(newValue){
                        this.$nextTick(async () => {
                            this._setInterval();
                        });
                    }else{
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                }
            }
        }
    }
</script>