function rightDate(e) {
  var regex = new RegExp(
    '([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'
  );
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function decimalNumber(e) {
  var regex = new RegExp('^[0-9]+([.][0-9]+)?$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function formatHourMMHH(e) {
  var regex = new RegExp('^(0[1-9]|1d|2[0-3]):([0-5]d)$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function rondedHour(value) {
  if (parseInt(value) < 10) {
    return `0${value}`;
  } else {
    return value;
  }
}

function getHourDate(date) {
  if (date && date !== '') {
    let newDate = new Date(date);

    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();

    return `${this.rondedHour(hour)}:${this.rondedHour(minutes)}`;
  } else {
    return '';
  }
}

export default {
  methods: {
    rightDate,
    decimalNumber,
    formatHourMMHH,
    rondedHour,
    getHourDate,
  },
};
