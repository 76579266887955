<template>
<CModalExtended
  :title="$t('label.nuevo')+' '+$t('label.weighingReport')"
  color="dark"
  :close-on-backdrop="false"
  :show.sync="showModal"
>
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  
    <CRow>
      <label class="col-sm-12 col-lg-4 text-right mt-2">{{$t('label.from')}}</label>
      <CCol sm="11" lg="6" class="center-field">
        <vue-datepicker 
          type="datetime"
          header
          :lang="this.$i18n.locale"
          :editable="false"
          :clearable="false"
          format="DD/MM/YYYY HH:mm"
          placeholder="DD/MM/YYYY HH:mm"
          time-title-format="DD/MM/YYYY HH:mm"
          v-model.trim="$v.binWeighing.searchFilterDate.fromDay.$model"
          :disabled-date="validateDateRange"
          :append-to-body="false"
          value-type="format"
          :show-second="false"
        >
        <template #input>
          <CInput
            v-uppercase
            :invalid-feedback="$t('label.required')"
            :is-valid="hasError($v.binWeighing.searchFilterDate.fromDay)"
            @keypress="rightDate($event)"
            type="datetime"
            header
            :editable="false"
            placeholder="DD/MM/YYYY HH:mm"
            v-model.trim="$v.binWeighing.searchFilterDate.fromDay.$model"
            @blur="$v.binWeighing.searchFilterDate.fromDay.$touch()"
            :disabled-date="validateDateRange"
            :show-second="false"
            >
            <template #append-content>
              <CIcon name="cil-calendar" />
            </template>
          </CInput>
        </template>
          <template #icon-calendar>
            <div style="display: none"></div>
          </template>
        </vue-datepicker>
      </CCol>
      <label class="col-sm-12 col-lg-4 text-right mt-2">{{$t('label.to')}}</label>
      <CCol sm="11" lg="6" class="center-field">
        <vue-datepicker 
          type="datetime"
          header
          :lang="this.$i18n.locale"
          :editable="false"
          :clearable="false"
          format="DD/MM/YYYY HH:mm"
          placeholder="DD/MM/YYYY HH:mm"
          time-title-format="DD/MM/YYYY HH:mm"
          v-model.trim="$v.binWeighing.searchFilterDate.toDay.$model"
          :disabled-date="validateDateRange"
          :append-to-body="false"
          value-type="format"
          :show-second="false"
        >
          <template #input>
            <CInput
              v-uppercase
              :invalid-feedback="$t('label.required')"
              :is-valid="hasError($v.binWeighing.searchFilterDate.toDay)"
              @keypress="rightDate($event)"
              type="datetime"
              header
              :editable="false"
              placeholder="DD/MM/YYYY HH:mm"
              v-model.trim="$v.binWeighing.searchFilterDate.toDay.$model"
              @blur="$v.binWeighing.searchFilterDate.toDay.$touch()"
              :disabled-date="validateDateRange"
              :show-second="false"
              >
              <template #append-content>
                <CIcon name="cil-calendar" />
              </template>
            </CInput>
          </template>
          <template #icon-calendar>
            <div style="display: none"></div>
          </template>
        </vue-datepicker>
      </CCol>
      <label class="col-sm-12 col-lg-4 text-right mt-2">{{$t('label.type')}}</label>
      <CCol sm="11" lg="6" class="center-field">
        <CSelect
            v-model="binWeighing.typeReport"
            :value.sync="binWeighing.typeReport"
            :options="selectOptions"
            :invalid-feedback="errorMessage($v.binWeighing.typeReport)"
            :is-valid="hasError($v.binWeighing.typeReport)"
            @blur="$v.binWeighing.typeReport.$touch()"
        />
      </CCol>
        
    </CRow>

    <template #footer>
      <CButton
        color="add"
        class="m-2"
        :disabled="isSubmit"
        @click="submit"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </CButton>
      <CButton
        color="wipe"
        class="m-2"
        :disabled="isSubmit"
        @click="closeModal"
      >
        <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
  </CModalExtended> 
</template>

<script>
  import modalBinWeighingSummaryValidations from '@/_validations/visitas/modalBinWeigingSummaryValidations';
  import UpperCase  from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import mixinServicio from '@/_mixins/servicio';
  import BinWeighing from '@/_mixins/mixinBinWeighing';
  import BinWeighingDetailed from '@/_mixins/mixinBinWeighingDetailed';

  import { mapState } from "vuex";
  import { DateFormater } from '@/_helpers/funciones';

  //Data
  function data() {
    return {
      showModal: false,
      isSubmit: false,
      loadingOverlay: false,
      binWeighing: { 
        searchFilterDate:{
          fromDay:"",
          toDay:"",
        },
        typeReport: '',
      },
      previousDate: new Date(),
      laterDate: new Date(),
      
    }
  }

  function submit() {
    try {
      this.isSubmit = true;
      this.loadingOverlay = true;
      this.$v.binWeighing.$touch();
        if (this.$v.binWeighing.$error) {
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsPleaseCheck');
        }

          let dateStart = '';
          let dateEnd = '';

          this.getDateRange();

          dateStart= DateFormater.formatDateTimeWithoutSlash(this.binWeighing.searchFilterDate.fromDay);
          dateEnd= DateFormater.formatDateTimeWithoutSlash(this.binWeighing.searchFilterDate.toDay); 

          if (this.$store.state.visitas.VisitId != ''){
              this.items= [];
            
              let ReportJson = {
                  VisitId : this.VisitId,
                  DateStart : dateStart,
                  DateEnd : dateEnd,
              };

              this.$http.ejecutar('GET', 'VisitWeighingDetailsReport',{ ReportJson: { ReportJson } }, '' )
              .then(response => {
                  this.items = response.data.data;

                  if(this.items.length > 0 ){ 
                    if(this.binWeighing.typeReport == 1){//general
                      this.getWeighingGeneralReport(this.items, this.binWeighing.searchFilterDate.fromDay, this.binWeighing.searchFilterDate.toDay);
                    }
                    if(this.binWeighing.typeReport == 0){//detailed
                      this.getWeighingDetailReport(this.items, this.binWeighing.searchFilterDate.fromDay, this.binWeighing.searchFilterDate.toDay);
                    }
                      this.closeModal();
                  }else{ 
                      this.$store.state.visitas.apiStateForm = ENUM.INIT;
                      this.$notify({
                          group: 'container',
                          title: '¡Error!',
                          text: this.$t('label.noRecordsAvailble'),
                          type: 'error',
                      });
                  }

              }).catch( err => {
                  this.$notify({
                      group: 'container',
                      title: '¡Error!',
                      text: err,
                      type: "error"
                  });
              }).then(() => {
              });
              

          }


    
        
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }

  }

  function refreshComponent() {
    this.binWeighing.searchFilterDate.fromDay="";
    this.binWeighing.searchFilterDate.toDay="";
    this.binWeighing.typeReport="";
    this.isSubmit = false;
    this.loadingOverlay = false;
    this.titleModal = '', 
    this.$v.$reset();
  }

  function closeModal() {
    this.showModal = false;
    this.refreshComponent();
    this.$emit('closeModal');
    this.$emit('child-refresh', true);
  }

      //rango de fechas
  function getDateRange(){
    let currentDate = new Date();
    
    if(this.itinerarySelected.Ata!=undefined && this.binWeighing.searchFilterDate.fromDay == ''){ 
      this.binWeighing.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined && this.binWeighing.searchFilterDate.fromDay == ''){
      this.binWeighing.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined && this.binWeighing.searchFilterDate.toDay == ''){  
      this.binWeighing.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined && this.binWeighing.searchFilterDate.toDay == ''){
      this.binWeighing.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
      this.laterDate = currentDate;
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function selectOptions(){
    return [
        { 
          value: '', 
          label: this.$t('label.select')
        },
        { 
          value: 0, 
          label: this.$t('label.detailed')
        },
        { 
          value: 1, 
          label: this.$t('label.general')
        }
      ];
  }
  export default {
    name: 'modal-bin-weighing-summary',
    mixins: [ModalMixin, mixinServicio, BinWeighing, BinWeighingDetailed],
    validations: modalBinWeighingSummaryValidations,
    props: {
      modal: null,
    },
    components: {
    },
    data,
    directives: UpperCase,
    methods: {
      submit,
      refreshComponent,
      closeModal,
      getDateRange,
      validateDateRange,
    },
    computed: { 
      selectOptions,
      ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,   
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch, 
        messageError : state=> state.visitas.messageError,
        apiState: state => state.visitas.apiState,
        apiStateForm: state => state.visitas.apiStateForm,
        VisitId: state => state.visitas.VisitId,
        collapseVisits: state => state.visitas.collapseVisits,
        bulkCarrier: state => state.visitas.bulkCarrier
      }),
    },
    watch: {
      modal: async function() {
        if(this.modal){
          this.showModal = true; 
          if (this.modal==true) {
            this.getDateRange();
          }
          this.$emit('closeModal');
        }
      },
    }
  }
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>