<template>
    <div>
        <CTabs variant="tabs" :active-tab="indexInformation" @update:activeTab="handleTab">
            <CTab :title="$t('label.timeLine')" >
                <CCard bodyWrapper>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <CSelect
                                            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                            :options="optionsListType"
                                            :value.sync="selectedType"
                                        >
                                        </CSelect>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="row">
                                            <div class="col-md-6">
                                            </div>
                                            <div class="col-md-4">
                                                <CSelect
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    :label="$t('label.list_terminal.n_toque')"
                                                    :options="optionsListToquehAll"
                                                    :value.sync="selectedToque"
                                                >
                                                </CSelect>
                                            </div>
                                            <div class="col-md-1">
                                                <CButton
                                                    color="edit"
                                                    square
                                                    size="sm"
                                                    @click="orientation = !orientation"
                                                    v-c-tooltip="{
                                                        content: $t('label.rotate'),
                                                        placement: 'top-end'
                                                    }"
                                                >
                                                    <CIcon name="rotateView"/>
                                                </CButton>
                                            </div>
                                            <div class="col-md-1">
                                                <CButton
                                                    color="edit"
                                                    square
                                                    size="sm"
                                                    @click="capture()"
                                                >
                                                    <CIcon name="cil-document"/>
                                                </CButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div ref="imagesave" >
                                    <CCollapse :show="orientation">
                                        <div class="main-timeline-vertical">
                                            <section id="cd-timeline" class="cd-container">
                                                <div class="cd-timeline-block" v-for="(item, index) in ListActivityOriginal" :key="index">
                                                    <div class="cd-timeline-img cd-movie">
                                                    </div>
                                                    <div class="cd-timeline-content">
                                                        <h2>{{item.date}}</h2>
                                                        <p>{{titleItemsActivity(item)}}</p>
                                                        <span class="cd-date">{{ item.TotalTime }}</span>
                                                    </div> <!-- cd-timeline-content -->
                                                </div>
                                            </section>
                                        </div>
                                    </CCollapse>
                                    <CCollapse :show="!orientation">
                                        <div class="main-timeline-horizontal" >
                                            <div class="bar"></div>
                                            <div class="timeline">
                                                <div class="entry" v-for="(item, index) in ListActivityOriginal" :key="index">
                                                    <h1>{{item.date}}</h1>
                                                    <p class="cd-date">{{ item.TotalTime }}</p>
                                                    {{titleItemsActivity(item)}}
                                                </div>
                                            </div>
                                        </div>
                                    </CCollapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCard>
            </CTab>
            <CTab :title="$t('label.vesselDocumentation')">
                <appForm :loading="loading" :indexInformation="indexInformation" />
            </CTab>
        </CTabs>
    </div>
                
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import moment from 'moment';
    import DataStorage from '@/_helpers/localStorage';
    import appForm from './form';
    import html2canvas from 'html2canvas';
    import { DateFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            orientation:true,
            listLineInformation:[],
            indexInformation:0,
            selectedType:1,
            selectedToque:"",
        }
    }
    //methods
    async function capture(){
        /*html2canvas($('#imagesave'), {
            onrendered: function (canvas) {
                var a = document.createElement('a');
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'somefilename.jpg';
                a.click();
            }
        });*/
        const el = this.$refs.imagesave;
        // add option type to get the image version
        // if not provided the promise will return 
        // the canvas.
        const options = {
            type: 'dataURL'
        }
        let output = await this.$html2canvas(el, options);
        var a = document.createElement('a');
        a.href = output.replace("image/jpeg", "image/octet-stream");
        a.download = 'somefilename.jpg';
        a.click();
    }
    async function mountedLineTimeList() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'VisitTerminalTimeLine-by-VisitId', { VisitId: this.VisitId }, '').then(responseTimeLine => {
            if(responseTimeLine.status === 200){
                this.listLineInformation = responseTimeLine.data.data;
                if(this.listLineInformation.length > 0){
                    this.selectedToque = this.listLineInformation[0].Called;
                }
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    function titleItemsActivity(item){
        let local = DataStorage.getLocale();
        return local == 'es'? item.Label.Es : item.Label.En;
    }
    function handleTab(tab) {
        this.indexInformation = tab;
    }
    //computed
    function ListActivityOriginal(){
        if(this.selectedType === 1){
            return this.ListTerminalActivity;
        }else if(this.selectedType === 2){
            return this.ListVoyageActivity;
        }else if(this.selectedType === 3){
            return this.ListScheduleActivity;
        }else if(this.selectedType === 4){
            return this.ListCraneActivity;
        }else{
            return [];
        }
    }

    function ListTerminalActivity(){
        let listActivity = [];
        if(this.listLineInformation.length !== 0){
            this.listLineInformation.map(async (e) => {
                if(this.selectedToque === e.Called){
                    e.VisitTerminalJson.map(async (x) => {
                        x.TerminalActivityJson.map(async (c) => {
                            listActivity.push({
                                VisitActivityVId: c.VisitActivityVId,
                                date : DateFormater.formatDateWithSlash(c.ActivityDate)+' '+DateFormater.formatOnlyTime(c.ActivityTime),//c.ActivityTime,
                                Label: { En: c.VisitActivityNameEn, Es: c.VisitActivityNameEs},
                                TotalTime: c.TotalTime,
                            })
                        }) 
                    })
                }
            })
        }
        return listActivity;
    }
    function ListVoyageActivity(){
        let listActivity = [];
        if(this.listLineInformation.length !== 0){
            this.listLineInformation.map(async (e) => {
                if(this.selectedToque === e.Called){
                    e.VisitTerminalJson.map(async (x) => {
                        x.VoyageActivityJson.map(async (c) => {
                            listActivity.push({
                                VisitActivityVId: c.VisitActivityVId,
                                date : DateFormater.formatDateWithSlash(c.ActivityDate)+' '+DateFormater.formatOnlyTime(c.ActivityTime),//c.ActivityTime,
                                Label: { En: c.VisitActivityNameEn, Es: c.VisitActivityNameEs},
                                TotalTime: c.TotalTime,
                            })
                        })
                    })
                }
            })
        }
        return listActivity;
    }
    function ListCraneActivity(){
        let listActivity = [];
        if(this.listLineInformation.length !== 0){
            this.listLineInformation.map(async (e) => {
                if(this.selectedToque === e.Called){
                    e.VisitTerminalJson.map(async (x) => {
                        x.VisitCraneJson.map(async (c) => {
                            listActivity.push({
                                VisitActivityVId: c.VisitActivityVId,
                                date : DateFormater.formatDateWithSlash(c.ActivityDate)+' '+DateFormater.formatOnlyTime(c.ActivityTime),//c.ActivityTime,
                                Label: { En: c.VisitActivityNameEn, Es: c.VisitActivityNameEs},
                                TotalTime: c.TotalTime,
                            })
                        })
                    })
                }
            })
        }
        return listActivity;
    }
    function ListScheduleActivity(){
        let listActivity = [];
        if(this.listLineInformation.length !== 0){
            this.listLineInformation.map(async (e) => {
                if(this.selectedToque === e.Called){
                    e.VisitTerminalJson.map(async (x) => {
                        let arreglo3 = x.SheduleArrivalActivityJson.concat(x.SheduleDepartureActivityJson);
                        arreglo3.map(async (c) => {
                            listActivity.push({
                                VisitActivityVId: c.VisitActivityVId,
                                date : DateFormater.formatDateWithSlash(c.ActivityDate)+' '+DateFormater.formatOnlyTime(c.ActivityTime),//c.ActivityTime,
                                Label: { En: c.VisitActivityNameEn, Es: c.VisitActivityNameEs},
                                TotalTime: c.TotalTime,
                            })
                        })
                    })
                }
            })
        }
        return listActivity;
    }
    function optionsListToquehAll() {
        if (this.listLineInformation.length == 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            let chart = [];
            this.listLineInformation.map(function(e) {
                chart.push({
                    value: e.Called,
                    label: e.Called,
                });
            });
            return chart;
        }
    }
    function optionsListType(){
        return [
            {
                label:this.$t('label.menu_diary.TERMINAL_DETAILS'),
                value:1
            },
            {
                label:this.$t('label.voyage'),
                value:2
            },
            {
                label:this.$t('label.menu_diary.AGENDA_DETAILS'),
                value:3
            },
            {
                label:this.$t('label.menu_diary.CRANES'),
                value:4
            }
        ]
    }
    export default {
        name: 'general_info',
        data,
        props: ['loading', 'currentModuleId'],
        mounted()  {
            this.$nextTick(async () => {
                if(this.VisitId !== ''){
                    await this.mountedLineTimeList();
                }
            });
        },
        methods:{
            handleTab,
            mountedLineTimeList,
            titleItemsActivity,
            capture
        },
        computed:{
            ListActivityOriginal,
            optionsListType,
            ListTerminalActivity,
            ListVoyageActivity,
            ListScheduleActivity,
            ListCraneActivity,
            optionsListToquehAll,
            ...mapState({
                branch: (state) => state.auth.branch,
                user: state => state.auth.user,
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                collapseVisits: state => state.visitas.collapseVisits,
                VisitId: state => state.visitas.VisitId,
            })
        },
        components:{
            appForm
        },
        watch:{
            currentModuleId:async function(newValue){
                if(newValue){
                    if(this.VisitId !== ''){
                        await this.mountedLineTimeList();
                    }
                }
            },
        }
    }
</script>


<style lang="scss" >
    @import 'css/line_time.scss';
</style>