<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            class="modal-extended"
            :show.sync="$store.state.contenedores.modalAccesorios"
            :title="tituloModal"
            :close-on-backdrop="false"

        >
            <CRow>
                 <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.group')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        :value="formAccesory.TpCargoId"
                        :addInputClasses="{ 'is-invalid': $v.formAccesory.TpCargoId.$error }"
                        @blur="$v.formAccesory.TpCargoId.$touch()"
                        @change="formAccesory.TpCargoId = $event.target.value"
                        :options="optionList"
                        :invalid-feedback="errorMessage($v.formAccesory.TpCargoId)"
                        :is-valid="hasError($v.formAccesory.TpCargoId)"
                    >
                    </CSelect>
                </CCol>
                 <CCol sm="12" lg="12" >
                    <CInput
                        :label="`${$t('label.name')} (ES)`"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="`${$t('label.accesoryName')} (ES)`"
                        v-model="$v.formAccesory.TpCargoAccesoryNameEs.$model"
                        @blur="$v.formAccesory.TpCargoAccesoryNameEs.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formAccesory.TpCargoAccesoryNameEs.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formAccesory.TpCargoAccesoryNameEs)"
                        :is-valid="hasError($v.formAccesory.TpCargoAccesoryNameEs)"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="12" >
                    <CInput
                        :label="`${$t('label.name')} (EN)`"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="`${$t('label.accesoryName')} (EN)`"
                        v-model="$v.formAccesory.TpCargoAccesoryNameEn.$model"
                        @blur="$v.formAccesory.TpCargoAccesoryNameEn.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formAccesory.TpCargoAccesoryNameEn.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formAccesory.TpCargoAccesoryNameEn)"
                        :is-valid="hasError($v.formAccesory.TpCargoAccesoryNameEn)"
                    >
                    </CInput>
                </CCol>
              <CCol sm="12" lg="12" >
                    <CSelect
                        :options="statusOptions"
                        :value.sync="formAccesory.FgActCargoAccesory"
                        :label="$t('label.status')"
                        v-if="!idIndentification"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        addLabelClasses="required text-right"
                        :is-valid="statusSelectColor"
                    />
                </CCol>  
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="Accesory" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormAccesory } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';

    //data
    function data() {
        return {
            formAccesory:{
                TpCargoId:'',
                TpCargoAccesoryId:0,
                TpCargoAccesoryNameEs:'',
                TpCargoAccesoryNameEn:'',
                FgActCargoAccesory:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nuevo')+' '+ this.$t('label.accesory'),
            
            loading:false,
        }
    }
    //methods
    function cambio(e){
        this.formAccesory.FgActCargoAccesory = !this.formAccesory.FgActCargoAccesory;
    }
    function cerrarModal(){
        if(this.apiStateFormLoading || this.loading)
            return
        this.$store.state.contenedores.modalAccesorios = false;
    }
    function registerData(){
        try {
               this.$v.formAccesory.$touch();
             if (this.$v.formAccesory.$pending || this.$v.formAccesory.$error)  {
                throw this.$t('label.errorsPleaseCheck');
             } 
                //if (this.$v.formAccesory.$pending || this.$v.formAccesory.$error) return;

                    let { TpCargoAccesoryId, TpCargoId,TpCargoAccesoryNameEs, TpCargoAccesoryNameEn, FgActCargoAccesory } = this.formAccesory;
                    const { dispatch } = this.$store;
                    if(this.originalAct !== '' && TpCargoAccesoryId !== 0){
                        if(this.originalAct !== FgActCargoAccesory){
                            this.$swal.fire(
                                alertPropertiesHelpers(this, {
                                    text: `${this.$t('label.changeStatusQuestion')} ?`,
                                })
                            ).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch('contenedores/dataAccesory', { TpCargoAccesoryId, TpCargoId,TpCargoAccesoryNameEs, TpCargoAccesoryNameEn, FgActCargoAccesory,FgComponent:0 });
                                }else {
                                    //this.$swal(this.$t('label.operationCancelled'));
                                }
                            })
                        }else{
                            dispatch('contenedores/dataAccesory', { TpCargoAccesoryId, TpCargoId,TpCargoAccesoryNameEs, TpCargoAccesoryNameEn, FgActCargoAccesory,FgComponent:0 });
                        }
                    }else{
                        dispatch('contenedores/dataAccesory', { TpCargoAccesoryId, TpCargoId,TpCargoAccesoryNameEs, TpCargoAccesoryNameEn, FgActCargoAccesory,FgComponent:0 });
                    }
        
        } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }

    }
    function statusSelectColor() {
        return this.formAccesory.FgActCargoAccesory === 1;
     }
    //computed
    function idIndentification(){
        return this.formAccesory.TpCargoAccesoryId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionList(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo && e.FgAccesory){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }

    function selectOptions() {
        return  [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalAccesorios(newQuestion){
        if(newQuestion === false){
            this.formAccesory.TpCargoAccesoryId=0;
            this.formAccesory.TpCargoAccesoryNameEs='';
            this.formAccesory.TpCargoAccesoryNameEn='';
            this.formAccesory.TpCargoId='';
            this.originalAct = '';
            this.tituloModal = this.$t('label.nuevo')+' '+ this.$t('label.accesory');
            this.formAccesory.FgActCargoAccesory=true;
            this.$store.state.contenedores.filtroCargo = '';
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            this.tituloModal = this.$t('label.nuevo')+' '+ this.$t('label.accesory');
            if(this.idState !== 0){
                const id = this.idState;
                this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'TpCargoAccesory-by-id',{ TpCargoAccesoryId: id }, '' ).then(response => {
                    try {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
                                let title = Information[0][`TpCargoAccesoryName${_lang}`];
                                this.tituloModal = this.$t('label.edit')+' '+this.$t('label.accesory')+': '+title;
                                this.formAccesory.TpCargoAccesoryId= Information[0].TpCargoAccesoryId;
                                this.formAccesory.TpCargoId= Information[0].TpCargoId;
                                this.formAccesory.TpCargoAccesoryNameEs= Information[0].TpCargoAccesoryNameEs;
                                this.formAccesory.TpCargoAccesoryNameEn= Information[0].TpCargoAccesoryNameEn;
                                this.originalAct = Information[0].FgActCargoAccesory;
                                this.formAccesory.FgActCargoAccesory= Information[0].FgActCargoAccesory?1:0;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }
                            this.$v.$touch();
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalVista = false;
                        }
                    } catch (error) {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalVista = false;
                        this.$store.commit('contenedores/messageMutation', err);
                    }
                }).catch(err => {
                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                    this.$store.state.contenedores.modalVista = false;
                    this.$store.commit('contenedores/messageMutation', err);
                });
            }else{
                if(this.filtroCargo !== ''){
                    this.formVista.TpCargoId= this.filtroCargo;
                }
            }
        }
    }

    export default {
        name: 'modal-accesory',
        data,
        validations(){ return FormAccesory() },
        methods:{
            registerData,
            statusSelectColor,
            cambio,
            cerrarModal,
            //errorMessage,
        },
        mixins: [GeneralMixin , ModalMixin],
        directives: UpperCase,
        computed:{
            idIndentification,
            optionList,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalAccesorios: state=> state.contenedores.modalAccesorios,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                filtroCargo: state => state.contenedores.filtroCargo,
            })
        },
        watch:{
            modalAccesorios
        }
    }
</script>
