import { localeKey } from '@/_helpers/locale.grid';

//Data:
function data() {
  return {
    showGrid: true,
	}
}

//Computeds
function CurrentLocale() {
	return this.$i18n.locale;
}

function localeText(){
	let AG_GRID_LOCALE = {};
	for (let index = 0; index < localeKey.length; index++) {
			const element = localeKey[index];
			AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
	}
	return AG_GRID_LOCALE;
}

export default {
  data,
  computed: {
    CurrentLocale,
		localeText,
  },
	watch: {
		CurrentLocale: function (newValue, oldValue) {
			if (newValue != oldValue) {
					this.showGrid = false;
					setTimeout(() => {
							this.showGrid = true;
					}, 10);
			}
		}
	}
}