import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric4 } from '@/_validations/validacionEspeciales'

const nameLength = (value) => {
  if (typeof value === 'string') {
    let Array = value.split(' ');
    return !Array.some(item => item.length > 20);
  } else {
    return true;
  }
}

export default () => {
  return {
    EventGpoNameEs: { required, maxLength: maxLength(60), nameLength, onlyAlphanumeric4 },
    EventGpoNameEn: { required, maxLength: maxLength(60), nameLength, onlyAlphanumeric4 },
    Status: { required },
  }
}