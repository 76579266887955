<template>
  <form>
    <loading-overlay
      :active="loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+'(ES)'"
            :placeholder="$t('label.name')+' '+'(ES)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.SealAssignmentNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.SealAssignmentNameEs)"
            :invalid-feedback="errorMessage($v.SealAssignmentNameEs)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+'(EN)'"
            :placeholder="$t('label.name')+' '+'(EN)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.SealAssignmentNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.SealAssignmentNameEn)"
            :invalid-feedback="errorMessage($v.SealAssignmentNameEn)"
          />
        </CCol>
      </CRow>   
      <CRow>
      <CCol sm="12" lg="12" v-if="isEdit">
        <CSelect
          size="sm"
          :options="statusOptions"
          :value.sync="Status"
          :label="$t('label.status')"
          :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
          addLabelClasses="required text-right"
          :is-valid="statusSelectColor"
        />
      </CCol>
    </CRow>
      <template #footer>
          <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click.stop="isEdit ? statusConfirmation(sealAssignment.FgActUnNumber , Status, submitBody) : submitBody()"
              :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
              square
              color="wipe"
              class="d-flex align-items-center"
              @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import sealAssignmentValidation from '@/_validations/sealAssignment/sealAssignmentValidation';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    SealAssignmentNameEs: '',
    SealAssignmentNameEn: '',
    SealAssignmentId : '',
    Status: 0,
    loading: false,
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.loading = true;
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let UnNumberJson = this.isEdit ? {
      SealAssignmentId: this.SealAssignmentId,
      SealAssignmentNameEs : this.SealAssignmentNameEs,
      SealAssignmentNameEn : this.SealAssignmentNameEn,
      Status: this.Status
    }:{
      SealAssignmentNameEs : this.SealAssignmentNameEs,
      SealAssignmentNameEn : this.SealAssignmentNameEn
    }
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'SealAssignment-update' : 'SealAssignment-insert';
    this.$http.ejecutar(metodo, ruta, UnNumberJson,{ root: 'SealAssignmentJson'},)
      .then((response) => {
          let messageSuccess = response.data.data[0].Response;
          this.resetForm();
          this.$emit('close');
          this.$emit('Update');
          this.notifySuccess({text: messageSuccess})
      }).catch((e) => {
        this.notifyError({text: e});
      }).finally(() => {
        this.isSubmit = false;
        this.loading = false;
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading = false;
    this.notifyError({text: e});
  }
}

function getData(val) {
  this.SealAssignmentNameEs = val.SealAssignmentNameEs != 'N/A' ? val.SealAssignmentNameEs : '';
  this.SealAssignmentNameEn = val.SealAssignmentNameEn != 'N/A' ? val.SealAssignmentNameEn : '';
  this.SealAssignmentId = val.SealAssignmentId;
  this.Status = val.Status === "ACTIVO" ?1:0;

  this.$v.$touch();
}

function resetForm() {
  this.SealAssignmentNameEs = '',
  this.SealAssignmentNameEn = '',
  this.$v.$reset();
}

function statusSelectColor() {
  return this.Status === 1;
}

//computed
function title() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1)
  return !this.isEdit ? `${this.$t('label.nueva')} ${this.$t('label.SealAssignment')}` : 
    `${this.$t('label.edit')} ${this.$t('label.SealAssignment')}:`;
}

export default {
  name: 'modal-sealAssignment',
  props: { modal: Boolean, sealAssignment: Object, isEdit: Boolean},
  data,
  mixins: [
    ModalMixin,
  ],
  validations: sealAssignmentValidation,
  directives: UpperCase,
  methods: {
    submitBody,
    getData,
    resetForm,
    statusSelectColor,
  },
  computed: {
    title,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
        if (this.isEdit) {
          this.getData(this.sealAssignment);
        }
      }else{
        this.resetForm();
      };
    }
  },
};
</script>