<template>
  <div class="mt-2">
    <CCol sm="12">
      <CCarousel 
        ref="myCarousel"
        animate
      >
        <CCarouselItem>
          <LoadType
            :form="form"
            :active="carousel == 0 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <BlType
            :form="form"
            :active="carousel == 1 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <Movement
            :form="form"
            :active="carousel == 2 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <Event
            :form="form"
            :active="carousel == 3 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <Consignee
            :form="form"
            :active="carousel == 4 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <CustomBroker
            :form="form"
            :active="carousel == 5 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <CCarouselItem>
          <Consolidator
            :form="form"
            :active="carousel == 6 && this.yardCollapse == 1"
            @getDateRange="getDateRange"
          />
        </CCarouselItem>
        <a class="carousel-control-prev" style="width: 25px !important; cursor: pointer;" @click="controlPrev"><span class="carousel-control-prev-icon"></span></a>
        <a class="carousel-control-next" style="width: 25px !important; cursor: pointer;" @click="controlNext"><span class="carousel-control-next-icon"></span></a>
      </CCarousel>
    </CCol>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadType from './loadType/index';
import BlType from './blType/index';
import Movement from './movement/index';
import Event from './event/index';
import Consignee from "./consignee/index";
import CustomBroker from "./customBroker/index";
import Consolidator from "./consolidator/index";
import { DateFormater } from '@/_helpers/funciones';
import moment from 'moment';

//DATA-------------------------
function data() {
  return {
    form: {
      FromDay: '',
      ToDay: '',
      PreviousDate: '',
      LaterDate: '',
    },
    carousel: 0,
  }
}

//methods
function getDateRange(){
  this.form.PreviousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
  this.form.LaterDate = new Date();
  this.form.FromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
  this.form.ToDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function controlPrev() {
  if (this.carousel > 0) {
    this.carousel -= 1; 
  }else{
    this.carousel = 6; 
  }
  this.$refs.myCarousel.setItem(this.carousel, 'prev');
}

function controlNext() {
  if (this.carousel < 6) {
    this.carousel += 1; 
  }else{
    this.carousel = 0; 
  }
  this.$refs.myCarousel.setItem(this.carousel, 'next');
}

//computed

export default {
  name: 'warehouse-yard-situation-index',
  components: {
    LoadType,
    BlType,
    Movement,
    Event,
    Consignee,
    CustomBroker,
    Consolidator,
  },
  data,
  mounted() {
    this.carousel = 0;
    this.$refs.myCarousel.setItem(this.carousel);
    this.getDateRange();
    this.$store.state.yardManagement.isSelectYard = false;
  },
  methods: {
    getDateRange,
    controlPrev,
    controlNext,
  },
  computed: {
    ...mapState({
      currentTab: state => state.yardManagement.tabIndex,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    currentTab: async function(newVal) {
      if(newVal == 0 && this.yardCollapse == 1) {
        this.carousel = 0;
        this.$refs.myCarousel.setItem(this.carousel);
        this.getDateRange();
        this.$store.state.yardManagement.isSelectYard = false;
      }
    },
    yardCollapse: function (newVal) {
      if(newVal == 1 && this.currentTab == 0) {
        this.carousel = 0;
        this.$refs.myCarousel.setItem(this.carousel);
        this.getDateRange();
        this.$store.state.yardManagement.isSelectYard = false;
      }
    }
  }
};
</script>