<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <modalAccesories
      :modal="AddModal"
      :PackagingList = "packagingList"
      @closeModal="AddModal = false"
      @child-refresh="packagingRefresh = true"
    />
    <CRow class="mt-2">
      <CCol sm="6">
          <CSelect
              :label="$t('label.packaging')"
              :horizontal="{label:'col-sm-11 col-lg-3 col-xl-2', input:'col-sm-11 col-lg-5 col-xl-5'}"
              :options="optionListPackagings"
              :disabled="Loading"
              :value.sync="packagings"
              @input="(event)=>changePackaging(event)"
          />
      </CCol>
    </CRow>
    <CCol sm="12" class="text-right">
      <CButton square color="add" @click="AddModal = true " v-c-tooltip="{
      placement:'top-end',content:$t('label.accesories')}">
        <CIcon name="cil-playlist-add" />&nbsp; {{$t('label.nuevo')}}
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedPackagingList"
        :fields="fields"
        column-filter
        :items-per-page="5"
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        hover
        small
        sorter
        pagination
      >
        
        <template #Status="{item}">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{item}">
          <td class="text-center">

            <CButton
              color="edit"
              square
              size="sm"
              v-c-tooltip="{placement:'top-end',content: $t('label.edit') +  ' ' + $t('label.accesory')}"
              @click="AddModal = item"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import modalAccesories from "./modal-accesories";

// const tableTextHelpers = {
//   tableFilterText: {
//     label: 'FILTRAR:',
//     placeholder: 'EMBALAJE',
//   },
//   itemsPerPageText: {
//     label: 'EMBALAJES POR PAGINA:',
//   },
//   noItemsViewText: {
//     noResults: 'NO SE ENCONTRARON RESULTADOS',
//     noItems: 'NO HAY REGISTROS DISPONIBLES',
//   },
// };

//DATA
function data() {
  return {
    itemsEmba: [],
    packagingList: [],
    Loading: false,
    PackagingId: '',
    LoadingEmba: false,
    AddModal: false,
    ListModal: false,
    packagingRefresh: false,
    packagings: '',
    
  };
}

//METHOD
/*
function EnviarDatos(item) {
  this.PackagingId = item.PackagingId;
  this.PackagingName = item.PackagingName;
  this.ListModal = true;
}
*/

async function changePackaging(value){
  
  let packaging = this.packagingList.find((item) => item.PackagingId == value);

  if(packaging || value == ''){
    this.PackagingId = (value == '') ? value : packaging.PackagingId;
    await this.getAccesoryList(true);
  }
}

function getPackagingList() {
  this.$http
    .get("Packaging-list", { filter: "ALL" })
    .then((response) => {
      this.packagingList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
    });
}

function getAccesoryList(loading =  false) {
  if(loading)
    this.Loading = true;

  this.itemsEmba = [];
  this.docs = [];
  let listado = Array;
  let _lang = this.$i18n.locale;

  this.$http
    .get("PackagingAccesory-by-PackagingId", {PackagingId: this.PackagingId??'' })
    .then((response) => {
      listado = [...response.data.data];
      this.docs = listado.filter((ob) => ob.Status === "ACTIVO");
      this.itemsEmba = listado;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
    });

    if(loading)
      this.Loading = false;
}

function computedPackagingList() {
  if(this.itemsEmba == []){
    return this.itemsEmba;
  }
  return this.itemsEmba.map((item, index) => {
    return {
      ...item,
      Nro: index + 1,
      PackagingNameEs: item.PackagingNameEs,
      PackagingNameEn: item.PackagingNameEn,
      PackagingAccesoryNameEs: item.PackagingAccesoryNameEs,
      PackagingAccesoryNameEn: item.PackagingAccesoryNameEn,
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      PackagingId: item.PackagingId,
      Weight: item.Weight,
      Status: item.Status,
      FgActPackaging: item.FgActPackaging,
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
    };
  });
}

function fields(){
  return[
    { key: "Nro", label: "#", _classes: 'text-left', _style: 'width:3%; text-align:center', filter: false },
    { key: "PackagingNameEs", label: this.$t('label.packaging')+' '+('(ES)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "PackagingNameEn", label: this.$t('label.packaging')+' '+('(EN)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "PackagingAccesoryNameEs", label: this.$t('label.accesory')+' '+('(ES)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "PackagingAccesoryNameEn", label: this.$t('label.accesory')+' '+('(EN)'),_classes: 'text-uppercase text-center', style: 'min-width:150px;'},
    { key: "Usuario", label:this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:15%'},
    { key: "Fecha", label: this.$t('label.date'),_classes:'text-center',_style:'text-align:center;width: 15%;' },
    { key: "Status", label: this.$t('label.status'),_classes:'text-center',_style:'text-align:center;width: 10%;' },
    { key: "Detalle", label: "", _style:'width:10%;min-width:90px;', _classes:'center-cell',filter: false,},
  ];
}


function optionListPackagings(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if(this.packagingList.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]
        this.packagingList.map(function(e){
          chart.push({
              value: e.PackagingId, 
              label: e[`PackagingName${_lang}`] ?? 'N/A',
          })
        })
        return chart;
    }
}

export default {
  name: "index",
  mixins: [General],
  data,
  props: {
    Tab: Number,
  },
  components: {
    modalAccesories,
  },
  // props: {
  //   fields: {
  //     type: Array,
  //     default() {
  //       return fields;
  //     },
  //   },
  // },
  methods: {
    getAccesoryList,
    getPackagingList,
    changePackaging,
  },
  computed:{
    fields,
    computedPackagingList,
    optionListPackagings
  },
  watch: {
    packagingRefresh: async function() {
      if (this.packagingRefresh) {
        this.PackagingId = '';
        this.Loading = true;
        await this.getPackagingList();
        await this.getAccesoryList(true);
        this.packagingRefresh = false;
      }
    },
    Tab: async function(value) {
      if (value == 1) {
        this.PackagingId = '';
        this.Loading = true;
        await this.getPackagingList();
        await this.getAccesoryList(true);
      }
    },
  },
};
</script>
<style scoped></style>
