
<template>
  <div>
    <div class="gantt_control" >
      <CButton 
        style="background: #636f83!important; border-color: #636f83!important; color: #ffff;"
        color="watch"
        class="mr-1"
        size="sm" 
        onclick="gantt.ext.zoom.zoomIn()"
      >
        <CIcon name="cil-zoom-out"/>
      </CButton>
      <CButton 
        style="background: #636f83!important; border-color: #636f83!important; color: #ffff;"
        color="watch"
        size="sm"
        onclick="gantt.ext.zoom.zoomOut()"
      >
        <CIcon name="cil-zoom-in"/>
      </CButton>
		</div>
    <div style="height: calc(100vh - 52px); width: 100%;" ref="ganttContainer"></div>
  </div>
</template>
<script>
  import { gantt } from 'dhtmlx-gantt';
  import GeneralMixin from '@/_mixins/general';
  import moment from 'moment';

  function data() {
    return {
      Month: {
        january: this.$t('label.january'),
        february: this.$t('label.february'),
        march: this.$t('label.march'),
        april: this.$t('label.april'),
        may: this.$t('label.may'),
        june: this.$t('label.june'),
        july: this.$t('label.july'),
        august: this.$t('label.august'),
        september: this.$t('label.september'),
        octuber: this.$t('label.octuber'),
        november: this.$t('label.november'),
        december: this.$t('label.december')
      }
    };
  }

  export default {
    data,
    props: {
      Color: {
        type: Object,
      },
      tasks: {
        type: Object,
        default () {
          return {data: [], links: []}
        }
      },
    },
    mixins: [GeneralMixin],
    methods: {
      
    },
    watch:{
      tasks: async function(val) {
        gantt.clearAll();
        gantt.config.show_unscheduled = true;
        gantt.config.autosize = "y";
        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.config.open_tree_initially = true;
        gantt.config.readonly = true;
        gantt.config.columns = [
          {name: "text", label: this.$t('label.activities'), tree: true, width: 250},
          {name: "Color", label: "", width: 30, align: "center"},
          {name: "start_date", label: this.$t('label.from'), width:82, align: "center"},
          {name: "end_date", label: this.$t('label.to'), width:82, align: "center"},
          {name: "Hrs", label: this.$t('label.duration')+'(HRS)', width: 80, align: "center"},
          {name: "HrsAcumulated", label: this.$t('label.Accumulated'), width: 80, align: "center"},
          {name: "Completed", label: this.$t('label.Completed'), width: 70, align: "center"},
          {name: "HrsInactive", label: this.$t('label.Inactivity'), width: 70, align: "center"},
        ];
        gantt.serverList("Color", this.Color.data);
        gantt.plugins({ 
          tooltip: true,
        });

        let hourToStr = gantt.date.date_to_str("%H:%i");
        let hourRangeFormat = function(step){
          return function(date){
            let intervalEnd = new Date(gantt.date.add(date, step, "hour") - 1)
            return hourToStr(date) + " - " + hourToStr(intervalEnd);
          };
        };
        let Month = this.Month;
        let Maxlength = val.data.length-1;
        let zoomConfig = {
          levels: [
            {
              name:"month",
              min_column_width:50,
              scales:[
                {unit: "month", step: 1, format: function (date) {
                  let month = gantt.date.date_to_str('%F')(date);
                  month = month.toLowerCase();
                  let Year = gantt.date.date_to_str('%Y')(date);
                  return Month[month]+'-'+Year;
                }},
                {unit: "day", step: 1, format: "%j"}
              ]
            },
            {
              name:"day",
              min_column_width:80,
              scales:[
                { unit: "day", step: 1, format: function (date) {
                  return moment(date).format('DD/MM/YYYY');;
                }},
                { unit: "hour", format: hourRangeFormat(4), step: 4}
              ]
            }
          ],
          startDate: val.data.length!=0 && val.data[0].start_date ? val.data[0].start_date : '',
          endDate: val.data.length!=0 && val.data[Maxlength].end_date ? val.data[Maxlength].end_date : '',
          useKey: "ctrlKey",
          trigger: "wheel",
          element: function(){
            return gantt.$root.querySelector(".gantt_task");
          }
        }
        gantt.ext.zoom.init(zoomConfig);

        gantt.config.start_date = val.data.length!=0 && val.data[0].start_date ? val.data[0].start_date : '';
        gantt.config.end_date = val.data.length!=0 && val.data[Maxlength].end_date ? val.data[Maxlength].end_date : '';

        gantt.config.layout = {
          css: "ganttContainer",
          cols: [
            {
              width:450,
              min_width: 400,
              rows:[
                {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer"},
                {view: "scrollbar", id: "gridScroll", group:"horizontal"}    
                ]
            },
            {resizer: true, width: 1},
            {
              rows:[
                {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
                {view: "scrollbar", id: "scrollHor", group:"horizontal"}      
              ]
            },
            {view: "scrollbar", id: "scrollVer"}
          ]
        };
        gantt.templates.grid_header_class = function(columnName, column){
          return "size-06";
        };
        gantt.templates.grid_row_class =
          gantt.templates.task_row_class =
            gantt.templates.task_class = function (start, end, task) {
              var css = [];
              if(task.id){
                css.push("gantt_resource_" + task.id);
              }

              return css.join(" ");
            };
        gantt.attachEvent("onParse", function(){
          var styleId = "dynamicGanttStyles";
          var element = document.getElementById(styleId);
          if(!element){
            element = document.createElement("style");
            element.id = styleId;
            document.querySelector("head").appendChild(element);
          }
          var html = [];
          var resources = gantt.serverList("Color");

          resources.forEach(function(r){
            html.push(".gantt_row.gantt_resource_" + r.id + " .gantt_cell:nth-child(2) .gantt_tree_content{" +
              "background-color:"+r.progressColor+"; " +
              "}");
            html.push(".gantt_row.gantt_resource_" + r.id + " .gantt_cell .gantt_tree_content{" +
              "white-space: nowrap; min-width: 0; font-size: 0.6rem;" +
              "}");
          });
          element.innerHTML = html.join("");
        });
        
        gantt.templates.grid_folder  = function() {
          return "";
        };
        gantt.templates.grid_file = function() {
          return "";
        };
        gantt.templates.task_text=function(start, end, task){
          return "";
        };
        gantt.templates.grid_indent=function(task){
          return "<div style='width:10px; float:left; height:100%'></div>"
        };
        gantt.templates.date_grid = function(date, task, column){
          if(task.unscheduled){
            return '';
          }else if (task.Delay) {
            return '-'
          }else if (task.FgDateRange && column == 'end_date') {
              return '-'
          }else if (task.DateFinish && column == 'end_date') {
            return moment(task.DateFinish,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
          }else{
            return gantt.date.date_to_str('%d/%m/%Y %H:%i')(date);
          }

        }

        let Activities = this.$t('label.activities');
        let From = this.$t('label.from');
        let To = this.$t('label.to');
        let Duration = this.$t('label.duration')+'(HRS)';
        let Completed = this.$t('label.Completed');
        let Occurrence = this.$t('label.Occurrence');
        let Accumulated = this.$t('label.Accumulated');
        let Inactivity = this.$t('label.Inactivity');
        gantt.templates.tooltip_text = function(start,end,task){
          let Start = gantt.date.date_to_str('%d/%m/%Y %H:%i')(start);
          if (task.unscheduled) {
            return "<b>"+Activities+":</b> "+task.text+"<br/>"+
              "<b>"+Duration+":</b> "+task.Hrs+"<br/>";
          } else if (task.Delay) {
            return "<b>"+Activities+":</b> "+task.text+"<br/>"+
              "<b>"+Duration+":</b> "+task.Hrs+"<br/>"+
              "<b>"+task.text+":</b> "+task.progress*100+'%'+"<br/>";
          }else if(task.FgDateRange){
            return "<b>"+Activities+":</b> "+task.text+"<br/>"+
              "<b>"+Occurrence+":</b> "+Start+"<br/>"+
              "<b>"+Duration+":</b> "+task.Hrs+"<br/>"+
              "<b>"+Accumulated+":</b> "+task.HrsAcumulated+"<br/>"+
              "<b>"+Inactivity+":</b> "+task.HrsInactive+"<br/>";
          }else if (task.Completed=='-') {
            let End = moment(task.DateFinish,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
            return "<b>"+Activities+":</b> "+task.text+"<br/>"+
              "<b>"+From+":</b> "+Start+"<br/>"+
              "<b>"+To+":</b> "+End+"<br/>"+
              "<b>"+Duration+":</b> "+task.Hrs+"<br/>"+
              "<b>"+Accumulated+":</b> "+task.HrsAcumulated+"<br/>"+
              "<b>"+Inactivity+":</b> "+task.HrsInactive+"<br/>";
          }else {
            let End = moment(task.DateFinish,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
            return "<b>"+Activities+":</b> "+task.text+"<br/>"+
              "<b>"+From+":</b> "+Start+"<br/>"+
              "<b>"+To+":</b> "+End+"<br/>"+
              "<b>"+Duration+":</b> "+task.Hrs+"<br/>"+
              "<b>"+Accumulated+":</b> "+task.HrsAcumulated+"<br/>"+
              "<b>"+Completed+":</b> "+task.Completed+"<br/>"+
              "<b>"+Inactivity+":</b> "+task.HrsInactive+"<br/>";
          }
        };
        
        gantt.init(this.$refs.ganttContainer);
        gantt.parse(this.$props.tasks);
      }
    },
  }
</script>
<style id='styles'>
	body,
	html {
		width: 100%;
		height: 100%;
		margin: unset;
	}

	.gantt_bar_task {
		background: orange;
	}

	.gantt_task_progress {
		background-color: rgba(33, 33, 33, 0.17);
	}
</style>
<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
    .gantt_task_line {
        border-radius: 14px !important;
    }
    .gantt_cell:nth-child(2) .gantt_tree_content{
			border-radius: 50%;
			width: 100%;
			height: 80%;
			margin: 5% 0;
			line-height: 230%;
		}
    .gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover,
      .gantt_grid_data .gantt_row.gantt_selected,
      .gantt_grid_data .gantt_row.odd.gantt_selected,
      .gantt_task_row.gantt_selected{
          background-color: #bdbdbd;
      }
    .size-06{
      font-size: 0.6rem;
    }
</style>