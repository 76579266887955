<template>
    <CRow>
        <CCol col="12" xl="12">
        <CCard>
            <CCardHeader>
                Ubicación
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol sm="12" class="d-flex justify-content-end">
                        <CButtonGroup>
                            <CButton
                                color="dark"
                            >
                                <CIcon name="cil-sync"/>&nbsp; Registrar pais
                            </CButton>
                            <CButton
                                color="dark"
                            >
                                <CIcon name="cil-sync"/>&nbsp; Registrar estado
                            </CButton>
                            <CButton
                                color="dark"
                            >
                                <CIcon name="cil-playlist-add"/>&nbsp; Registrar provincia
                            </CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        </CCol>
    </CRow>
</template>

<script>
    //data
    function data() {
        return {}
    }

    export default {
        name: 'app-ubicacion',
        data,
    }
</script>