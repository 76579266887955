<template>
    <form @submit.prevent="submitBody">
    <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title=title
        color="dark"
        size="lg"
        :close-on-backdrop="false"
        :show.sync="modalActive"
        class="modal-content-clasificacion-maquina"
        @update:show="$emit('close');"
        >
          <CRow class="mt-2">
            <CCol sm="12">
              <CInput
                  :label="$t('label.name')+' '+('(ES)')"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                  add-label-classes="required text-right"
                  v-uppercase
                  v-model.trim="$v.formMachineClassification.TpClassificationMachineNameEs.$model"               
                  :is-valid="hasError($v.formMachineClassification.TpClassificationMachineNameEs)"
                  :invalid-feedback="errorMessage($v.formMachineClassification.TpClassificationMachineNameEs)"
                  :placeholder="$t('label.classificationMachine')+' '+('(ES)')"
                  maxlength="250"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                  :label="$t('label.name')+' '+('(EN)')"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                  add-label-classes="required text-right"
                  v-uppercase
                  v-model.trim="$v.formMachineClassification.TpClassificationMachineNameEn.$model"               
                  :is-valid="hasError($v.formMachineClassification.TpClassificationMachineNameEn)"
                  :invalid-feedback="errorMessage($v.formMachineClassification.TpClassificationMachineNameEn)"
                  :placeholder="$t('label.classificationMachine')+' '+('(EN)')"
                  maxlength="250"
              >
              </CInput>
            </CCol>
            <CCol  sm="12">
              <CTextarea               
                :label="$t('label.description')"
                :placeholder="$t('label.description')"
                :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                add-label-classes="text-right"
                row="4"
                v-uppercase
                v-model="$v.formMachineClassification.TpClassificationMachineDs.$model"  
                :is-valid="hasError($v.formMachineClassification.TpClassificationMachineDs)"
                :invalid-feedback="errorMessage($v.formMachineClassification.TpClassificationMachineDs)" 
              />
            </CCol>
            <CCol  sm="12" v-if="isEdit">
                <CSelect
                 :options="statusOptions"
                 :value.sync="formMachineClassification.Status"
                 :label="$t('label.status')"
                 :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                 addLabelClasses="required text-right"
                 :is-valid="statusSelectColor"
                />
          </CCol>
          </CRow> 
           <template #footer>
            <CButton
                outline
                color="add"
                @click.stop="isEdit ? statusConfirmation(MachineClassificationItems.FgActClassificationMachine , formMachineClassification.Status, submitBody) : submitBody()"
                :disabled="isSubmit"
            >
                <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
            </CButton>
            <CButton
                color="wipe"
                class="d-flex align-items-center"
                @click="$emit('close');"
            >
                <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
            </CButton>
        </template>   
      </CModalExtended>  
    </form>       
</template>
  
      
  <script>
    import { FormMachineClassification } from '@/_validations/maquina/MaquinaValidation';
    import UpperCase from '@/_validations/uppercase-directive';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { mapState } from 'vuex';
  
  //Data
function data() {
    return {
        modalActive: false,
        isSubmit: false, 
        Loading: false,
        formMachineClassification: {
            TpClassificationMachineId:'',
            TpClassificationMachineNameEs: '',
            TpClassificationMachineNameEn: '',
            TpClassificationMachineDs:'',
            Status:'',
        },
    }
}
  
async function submitBody(){
    try {
      this.isSudmit = true;
      this.Loading = true;
      this.$v.formMachineClassification.$touch();
      if (this.$v.formMachineClassification.$error) {
        this.isSudmit = false;
        this.Loading=false;
        throw this.$t('label.errorsPleaseCheck');
      }
      const TpClassificationMachineJson = this.isEdit ?  {
        TpClassificationMachineId:this.formMachineClassification.TpClassificationMachineId,
        TpClassificationMachineNameEs: this.formMachineClassification.TpClassificationMachineNameEs,
        TpClassificationMachineNameEn: this.formMachineClassification.TpClassificationMachineNameEn,
        TpClassificationMachineDs: this.formMachineClassification.TpClassificationMachineDs,
        Status: this.formMachineClassification.Status,
      } : {
        TpClassificationMachineNameEs: this.formMachineClassification.TpClassificationMachineNameEs,
        TpClassificationMachineNameEn: this.formMachineClassification.TpClassificationMachineNameEn,
        TpClassificationMachineDs: this.formMachineClassification.TpClassificationMachineDs,
      }
      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'TpClassificationMachine-update':'TpClassificationMachine-insert';

      await this.$http.ejecutar(metodo, ruta, TpClassificationMachineJson, { root: 'TpClassificationMachineJson' })
        .then(response => {
          if (response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.resetForm();
              this.$emit('Update-list');
              this.$emit('close');
              this.notifySuccess({ text: messageSuccess });
              this.isSubmit = false;
              this.Loading=false;
            }     
        }).catch(err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
          this.isSubmit = false;
          this.Loading=false;
        }).then(() => {
          this.isSubmit = false;
          this.Loading=false;
        });
    } catch (e) {
        this.notifyError({ text: e });
        this.isSubmit = false;
        this.Loading=false;
    }
}
function toggle(newVal) {
    if(!newVal){
        this.resetForm();
        this.modalActive = newVal;
    }
}
function getdata(val) {
    this.formMachineClassification.TpClassificationMachineId = val.TpClassificationMachineId
    this.formMachineClassification.TpClassificationMachineNameEs = val.TpClassificationMachineNameEs;
    this.formMachineClassification.TpClassificationMachineNameEn = val.TpClassificationMachineNameEn;
    this.formMachineClassification.TpClassificationMachineDs = val.TpClassificationMachineDs;
    this.formMachineClassification.Status = val.FgActClassificationMachine?1:0;
    this.$v.$touch();
}
function resetForm() {
    this.formMachineClassification.TpClassificationMachineId='',
    this.formMachineClassification.TpClassificationMachineNameEs='',
    this.formMachineClassification.TpClassificationMachineNameEn='',
    this.formMachineClassification.TpClassificationMachineDs='',
    this.formMachineClassification.Status=0,
    this.isSubmit=false
    this.$v.$reset();
}
function statusSelectColor() {
    return this.formMachineClassification.Status === 1;
  }
export default {
    name: 'modal-clasificacion-maquina',
    mixins: [GeneralMixin, ModalMixin],
    props: {modal: Boolean, title: String, isEdit: Boolean, MachineClassificationItems: Object},
    data,
    validations: FormMachineClassification,
    directives: UpperCase,
    methods: {
    submitBody,
      toggle,
      getdata,
      resetForm,
      statusSelectColor
      
    },
    computed: {},
    watch: {
        modal: function(val){
        this.modalActive = val;
            if (this.isEdit==true) {
                this.getdata(this.MachineClassificationItems);
            }else{
                this.resetForm();
            };
        }
    }
}
</script>
<style lang="scss">
  .modal-content-clasificacion-maquina {
    .modal-content {
      width: 80% !important;
      margin: auto !important;
      .modal-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }
    }
  }
</style>
  