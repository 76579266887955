<template>
  <div>
    <CCollapse :show="activeMovementItem(1)">
      <BlsInWarehouse/> 
    </CCollapse>
    <CCollapse :show="activeMovementItem(2)">
      <PendingsBls/> 
    </CCollapse>
    <CCollapse :show="activeMovementItem(3)">
      <InternalTransfer/> 
    </CCollapse>
    <CCollapse :show="activeMovementItem(4)">
      <ContainerInInventory/>
    </CCollapse>
    <CCollapse :show="activeMovementItem(5)">
      <PendingsHouse/> 
    </CCollapse>
    <CCollapse :show="activeMovementItem(6)">  
      <BlDispatched/> 
    </CCollapse>
    <CCollapse :show="activeMovementItem(7)">  
      <BlDeconsolidated/> 
    </CCollapse>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BlsInWarehouse from './movements-list/blsInWarehouse';
  import PendingsBls from './movements-list/reception/visit/pendingsBls';
  import InternalTransfer from './movements-list/reception/internal-transfer/index';
  import PendingsHouse from './movements-list/reception/pendingHouse/housesList';
  import ContainerInInventory from './movements-list/containerInInventory';
  import BlDispatched from './movements-list/dispatch/index';
  import BlDeconsolidated from './movements-list/deconsolidated/index';

  //methods
  function activeMovementItem(item) {
      return this.dropItemMovementList === item;
  }

  export default {
    name:"movements-list-index",
    methods:{
      activeMovementItem,
    },
    components:{
      PendingsBls,
      BlsInWarehouse,
      InternalTransfer,
      ContainerInInventory,
      PendingsHouse,
      BlDispatched,
      BlDeconsolidated,
    },
    computed:{
      ...mapState({
        dropItemMovementList: state => state.yardManagement.dropItemMovementList,
      })
    },
    watch:{
      //tabIndex
    }
  }
</script>