<template>
  <div>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleCreate"
          v-c-tooltip="{
            content: $t('label.IncidenceGroup'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;
            {{$t('label.nuevo')}}
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedIncidenceList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :loading="loading"
      sorter
    >
      <template #loading>  
        <loading/>
      </template>  
      <template #Status="{ item }">
        <td class="cell-center text-center">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
      <template #options="{ item }">
        <td>
          <CButton
            size="sm"
            square
            color="edit"
            class="d-flex align-items-center"
            @click="toggleEdit(item)"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.IncidenceGroup'),
              placement: 'top-end'
            }"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>  
    <modal-group-incidence
      :modal.sync="modalActive"
      :isEdit="isEdit"
      :IncidenceGpoItem="IncidenceGpoItem"
      @Update-list="Update"
      @close="closeModal"
    />
  </div>
</template>
<script>
import ModalGroupIncidence from './modal-group-incidence';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function data() {
  return {
    items: [],
    modalActive: false,
    isEdit: false,
    IncidenceGpoItem: {},
    loading: false,
  };
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-left', _style: 'width:5%; text-align:center', filter: false},
     { label: this.$t('label.incidence')+' '+'(ES)', key: 'IncidenceGpoNameEs',_style: 'width:27%;',_classes:'text-center'},
     { label: this.$t('label.incidence')+' '+'(EN)', key: 'IncidenceGpoNameEn',_style: 'width:27%;',_classes:'text-center'},
     { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', _style:'width:12%',_classes:'text-center' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:15%',_classes:'text-center' },
     { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-uppercase', _style:'width:12%',_classes:'text-center' },
     { label: '', key: 'options', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
  ];
}

function toggleEdit(item) {
  this.IncidenceGpoItem = item;
  this.isEdit = true;
  this.modalActive = true;
}
function closeModal(){
  this.modalActive= false;
  this.isEdit= false;
}
function computedIncidenceList() {
  if(this.items == []){
    return this.items;
  }
  return this.items.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function Update() {
  this.modalActive = false;
  this.mountedIncidenceList();
}

function mountedIncidenceList() {
  this.loading = true;
  this.$http
    .get('IncidenceGpo-list')
    .then(response => {
      let listado = response.data.data;
      let nulo = String(listado[0].Json);
      if (nulo=="null" || listado ==''){
        return false;
      }
      this.items = response.data.data;
    }) 
    .finally(() => {
      this.loading = false;
    });
}

function toggleCreate() {
  this.modalActive = true;
}

export default {
  name: 'group-incidence-index',
  components: { ModalGroupIncidence },
  data,
  props:{Tab: Number},
  mixins: [GeneralMixin],
  methods: {
    Update,
    mountedIncidenceList,
    toggleCreate,
    toggleEdit,
    closeModal
  },
  computed: {
    computedIncidenceList,
    fields,
  },
  mounted: mountedIncidenceList,
  watch:{
    Tab:function (val){
      if(val == 0){
        this.mountedIncidenceList();
      }
    }
  }
};
</script>