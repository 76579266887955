<template>
    <div>
      <loading-overlay
        :active="isLoading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="title"
        color="dark"
        size="lg"
        @update:show="closeModal(false)"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
      <CRow>
        <CCol sm="12">
            <CSelect
                :label="$t('label.vesselType')"
                 addLabelClasses="required text-right"
                :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
                :options="TpVesselListFormatted"
                v-model.trim="$v.TpVesselId.$model"
                :is-valid="hasError($v.TpVesselId)"
                :invalid-feedback="errorMessage($v.TpVesselId)"
            />
        </CCol>
        <CCol sm="12">
            <div class="form-group form-row">
                <label class="col-form-label text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.DelayCustom')}}</label>
                <div class="col-sm-12 col-lg-7">
                    <v-select
                        :options="DelayCustomListFormatted"
                        :reduce="option => option.value"
						:getOptionLabel="option => option.label"
                        v-model.trim="DelayCustomId"
                        :value="DelayCustomId"
                        :placeholder="$t('label.select')"
                        :class="DelayCustomId ? 'select-driver--correct' : (verifyDelayCustom ? 'select-driver--error' : '') "
                    />
                    <div v-if="verifyDelayCustom&&(this.DelayCustomId === '' || this.DelayCustomId === null)">
                        <label class="text-invalid-feedback">{{$t('validation.required')}}</label>
                    </div>
                </div>
            </div>
        </CCol>
        <CCol v-if="IsEdit" sm="12">
            <CSelect
                :options="statusOptions"
                v-model.trim="$v.Status.$model"
                :label="$t('label.status')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                addLabelClasses="required text-right"
                :is-valid="statusSelectColor"
            />
        </CCol>
      </CRow>
        <template #footer>
            <CButton
                square
                color="add"
                :disabled="isSubmit"
                @click.stop="IsEdit ? statusConfirmation(true , Status, submitBody) : submitBody()"
            >
                <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
            <CButton color="wipe" @click="closeModal(false)">
                <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
            </CButton>
        </template>
      </CModalExtended>
    </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import TimePerTypeVesselValidations from '@/_validations/tiempoperdido/TimePerTypeVesselValidations';
import UpperCase from '@/_validations/uppercase-directive';
  
function data() {
    return {
        DelayTpVesselId: '',
        TpVesselId: '',
        TpVesselOption: [],
        DelayCustomId: '',
        DelayCustomOption: [],
        Status: 1,
        verifyDelayCustom: false,
        IsEdit: false,
        isSubmit: false,
        modalActive: false,
        isLoading: false,
    };
}

function submitBody() {
    try{
        this.isSubmit = true;
        this.isLoading = true;
        this.verifyDelayCustom = true;
        this.$v.$touch();
        if (this.$v.$error) {
            this.isSubmit = false;
            this.isLoading = false;
            throw this.$t('label.errorsPleaseCheck');
        }
        let DelayCustomTpVesselJson = !this.IsEdit?[{
            TpVesselId: this.TpVesselId,
            DelayCustomId: this.DelayCustomId,
        }]:[{
            DelayTpVesselId: this.DelayTpVesselId,
            TpVesselId: this.TpVesselId,
            DelayCustomId: this.DelayCustomId,
            Status: this.Status,
        }]
        let metodo = this.IsEdit ? 'PUT':'POST';
        let ruta = this.IsEdit ? 'DelayCustomTpVessel-update':'DelayCustomTpVessel-insert';
        this.$http
        .ejecutar( metodo, ruta, DelayCustomTpVesselJson, { root: 'DelayCustomTpVesselJson' })
        .then((response) => {
            if (response && response.status === (200 || 201)) {
                const messageSuccess = response.data.data[0].Response;
                this.isLoading = false;
                this.closeModal();
                this.notifySuccess({text: messageSuccess});
                this.isSubmit = false;
            } 
        }).catch((e) => {
            this.isSubmit = false;
            this.isLoading = false;
            this.notifyError({text: e});
        });
    }catch(e){
        this.isSubmit = false;
        this.isLoading = false;
        this.notifyError({text: e});
    }
}
  
function closeModal(refresh) {
    this.TpVesselId = '';
    this.TpVesselOption = [];
    this.DelayCustomId = '';
    this.DelayCustomOption = [];
    this.Status = 1;
    this.verifyDelayCustom = false;
    this.$v.$reset();
    this.$emit('close-Modal', refresh);
}

function getList() {
	this.isLoading = true;
	let peticiones =  
    [
      this.$http.ejecutar("GET", 'DelayCustom-list?DelayId='),
      this.$http.ejecutar("GET", 'TpVessel-list'),
    ];
	Promise.all(peticiones)
	.then((responses) => {
		this.DelayCustomOption = responses[0].data.data;
		this.TpVesselOption = responses[1].data.data;
	}).catch((err) => {
		this.$notify({
			group: "container",
			title: "¡Error!",
			text: err,
			type: "error",
		});
	}).then(() => {
		if (this.IsEdit) {
			this.mountValues(this.TpVesselItem);
		}else{
			this.$v.$reset();
			this.isLoading = false;
		}
	});
}
  
function mountValues(val) {
    this.DelayTpVesselId = val.DelayTpVesselId;
    this.TpVesselId = val.TpVesselId;
    this.DelayCustomId = val.DelayCustomId;
    this.Status = val.Status=='ACTIVO'?1:0;
    this.verifyDelayCustom = true;
    this.$v.$touch();
	this.isLoading = false;
}

function statusSelectColor() {
    return this.Status === 1;
}

function TpVesselListFormatted(){
    if(this.TpVesselOption.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]
        this.TpVesselOption.map(function(e){
            chart.push({
                value: e.TpVesselId, 
                label: e.TpVesselName,
            })
        })
        return chart;
    }
}

function DelayCustomListFormatted(){
    if(this.DelayCustomOption.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]
        let _lang = this.$i18n.locale;
        this.DelayCustomOption.map(function(e){
            chart.push({
                value: e.DelayCustomId, 
                label: _lang=='en'? e.DelayCustomNameEn : e.DelayCustomNameEs,
            })
        })
        return chart;
    }
}  

function title() {
    if (this.IsEdit) {
        let _lang = this.$i18n.locale;
        let title = _lang=='en'? this.TpVesselItem.DelayCustomNameEn : this.TpVesselItem.DelayCustomNameEs
        return this.$t('label.edit')+' '+this.$t('label.TimePerTypeVessel')+': '+title;
    } else {
        return this.$t('label.nuevo')+' '+this.$t('label.TimePerTypeVessel');
    }
} 
  
export default {
    name: 'modal-hold-information',
    props: { modal: Boolean, isEdit: Boolean, TpVesselItem: Object },
    data,
    mixins: [
      GeneralMixin,
      ModalMixin, 
    ],
    validations: TimePerTypeVesselValidations,
    directives: UpperCase,
    methods: {
        submitBody,
        statusSelectColor,
        closeModal,
		getList,
        mountValues,
    },
    computed: {
        title,
        TpVesselListFormatted,
        DelayCustomListFormatted,
    },
    watch: {
        modal: async function(val) {
          this.modalActive = val;
          this.IsEdit = this.isEdit;
          if (val) {
			this.getList();
          }
        },
    }, 
};
</script>
<style lang="scss">
.select-driver--correct {
    .vs__dropdown-toggle {
        border-color: #2eb85c !important;
    }
}
.select-driver--error {
    .vs__dropdown-toggle {
        border-color: #e55353 !important;
    }
}
</style>