<template>
    <div>
        <div class="table-responsive col-sm-12 col-lg-12">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th rowspan="3" style="width: 10%;"><label class="mb-4">{{ $t('label.size') }}</label></th>
                        <th colspan="6" style="background: #ededed;">TOTAL</th>
                    </tr>
                    <tr class="text-white">
                        <th colspan="2" style="width: 25%; background: #1f3864;">{{ $t('label.DISCHARGE') }}</th>
                        <th colspan="2" style="width: 25%; background: #ed7d31;">{{ $t('label.shipment') }}</th>
                        <th colspan="2" style="width: 40%; background: #1da9a2;">{{ $t('label.mobilized') }}</th>
                    </tr>
                    <tr>
                        <th>{{ $t('label.full') }}</th>
                        <th>{{ $t('label.empty') }}</th>
                        <th>{{ $t('label.full') }}</th>
                        <th>{{ $t('label.empty') }}</th>
                        <th>{{ $t('label.ship_ship') }}</th>
                        <th>{{ $t('label.ship_land_ship') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in Size" :key="item.IdX">
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.SizeDs }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.DischargeJson[0].CantFull }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.DischargeJson[0].CantEmpty }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.LoadJson[0].CantFull }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.LoadJson[0].CantEmpty }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.RestowJson[0].CantShipShip }}
                        </td>
                        <td :style="item.SizeDs=='TOTAL'?'font-weight: bold;':''">
                            {{ item.RestowJson[0].CantShipLandShip }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered text-center">
                <thead>
                    <tr class="text-white">
                        <th style="color: #3c4b64; width: 14%;" rowspan="2"><label class="mb-3">{{ $t('label.movement') }}</label></th>
                        <th colspan="4" style="width: 17.2%;background: #3492b0;">{{ $t('label.Estandar') }}</th>
                        <th colspan="4" style="width: 17.2%;background: #da8368;">HIGH CUBE</th>
                        <th colspan="4" style="width: 17.2%;background: #18cdd6;">{{ $t('label.Reefers') }}</th>
                        <th colspan="4" style="width: 17.2%;background: #ffc000;">{{ $t('label.special') }}</th>
                        <th colspan="4" style="width: 17.2%;background: #92cddc;">{{ $t('label.Tank') }}</th>
                    </tr>
                    <tr>
                        <th>20 FT</th>
                        <th>40 FT</th>
                        <th>45 FT</th>
                        <th>TOTAL</th>
                        <th>20 FT</th>
                        <th>40 FT</th>
                        <th>45 FT</th>
                        <th>TOTAL</th>
                        <th>20 FT</th>
                        <th>40 FT</th>
                        <th>45 FT</th>
                        <th>TOTAL</th>
                        <th>20 FT</th>
                        <th>40 FT</th>
                        <th>45 FT</th>
                        <th>TOTAL</th>
                        <th>20 FT</th>
                        <th>40 FT</th>
                        <th>45 FT</th>
                        <th>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in Type">
                        <td class="text-left">{{ $i18n.locale=='en'?item.MovementDsEn:item.MovementDsEs }}</td>
                        <td>{{ item.StandardJson[0].Cant20?item.StandardJson[0].Cant20:0 }}</td>
                        <td>{{ item.StandardJson[0].Cant40?item.StandardJson[0].Cant40:0 }}</td>
                        <td>{{ item.StandardJson[0].Cant45?item.StandardJson[0].Cant45:0 }}</td>
                        <td><strong>{{ item.StandardJson[0].CantTotal?item.StandardJson[0].CantTotal:0 }}</strong></td>
                        <td>{{ item.HighCubeJson[0].Cant20?item.HighCubeJson[0].Cant20:0 }}</td>
                        <td>{{ item.HighCubeJson[0].Cant40?item.HighCubeJson[0].Cant40:0 }}</td>
                        <td>{{ item.HighCubeJson[0].Cant45?item.HighCubeJson[0].Cant45:0 }}</td>
                        <td><strong>{{ item.HighCubeJson[0].CantTotal?item.HighCubeJson[0].CantTotal:0 }}</strong></td>
                        <td>{{ item.ReeferJson[0].Cant20?item.ReeferJson[0].Cant20:0 }}</td>
                        <td>{{ item.ReeferJson[0].Cant40?item.ReeferJson[0].Cant40:0 }}</td>
                        <td>{{ item.ReeferJson[0].Cant45?item.ReeferJson[0].Cant45:0 }}</td>
                        <td><strong>{{ item.ReeferJson[0].CantTotal?item.ReeferJson[0].CantTotal:0 }}</strong></td>
                        <td>{{ item.SpecialJson[0].Cant20?item.SpecialJson[0].Cant20:0 }}</td>
                        <td>{{ item.SpecialJson[0].Cant40?item.SpecialJson[0].Cant40:0 }}</td>
                        <td>{{ item.SpecialJson[0].Cant45?item.SpecialJson[0].Cant45:0 }}</td>
                        <td><strong>{{ item.SpecialJson[0].CantTotal?item.SpecialJson[0].CantTotal:0 }}</strong></td>
                        <td>{{ item.TankJson[0].Cant20?item.TankJson[0].Cant20:0 }}</td>
                        <td>{{ item.TankJson[0].Cant40?item.TankJson[0].Cant40:0 }}</td>
                        <td>{{ item.TankJson[0].Cant45?item.TankJson[0].Cant45:0 }}</td>
                        <td><strong>{{ item.TankJson[0].CantTotal?item.TankJson[0].CantTotal:0 }}</strong></td>
                    </tr>
                    <tr>
                        <td class="text-left"><strong>TOTAL</strong></td>
                        <td><strong>{{ TotalType.StandardCant20 }}</strong></td>
                        <td><strong>{{ TotalType.StandardCant40 }}</strong></td>
                        <td><strong>{{ TotalType.StandardCant45 }}</strong></td>
                        <td><strong>{{ TotalType.StandardCantTotal }}</strong></td>
                        <td><strong>{{ TotalType.HighCubeCant20 }}</strong></td>
                        <td><strong>{{ TotalType.HighCubeCant40 }}</strong></td>
                        <td><strong>{{ TotalType.HighCubeCant45 }}</strong></td>
                        <td><strong>{{ TotalType.HighCubeCantTotal }}</strong></td>
                        <td><strong>{{ TotalType.ReeferCant20 }}</strong></td>
                        <td><strong>{{ TotalType.ReeferCant40 }}</strong></td>
                        <td><strong>{{ TotalType.ReeferCant45 }}</strong></td>
                        <td><strong>{{ TotalType.ReeferCantTotal }}</strong></td>
                        <td><strong>{{ TotalType.SpecialCant20 }}</strong></td>
                        <td><strong>{{ TotalType.SpecialCant40 }}</strong></td>
                        <td><strong>{{ TotalType.SpecialCant45 }}</strong></td>
                        <td><strong>{{ TotalType.SpecialCantTotal }}</strong></td>
                        <td><strong>{{ TotalType.TankCant20 }}</strong></td>
                        <td><strong>{{ TotalType.TankCant40 }}</strong></td>
                        <td><strong>{{ TotalType.TankCant45 }}</strong></td>
                        <td><strong>{{ TotalType.TankCantTotal }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <CRow>
            <div class="col-sm-12 col-lg-6">
                <CRow>
                    <div class="col-sm-12 col-lg-7">
                        <simpleDonut
                            :dataLabels="false"
                            :Series="SeriesArray(Type[0])" 
                            :Colors="ColorArray" 
                            :Categories="LabelsArray"
                            :Tooltip="Tooltip"
                        />
                    </div>
                    <div class="col-sm-12 col-lg-5 d-flex justify-content-center">
                        <div class="col-sm-5 col-lg-12">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td class="text-center"><strong>{{ $t('label.DISCHARGE') }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #3492b0;"></span>
                                            </div>
                                        </td>
                                        <td>{{$t('label.Estandar')}}: {{ PercentageDischarge(Type[0]?.StandardJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #da8368;"></span>
                                            </div>
                                        </td>
                                        <td>HIGH CUBE: {{ PercentageDischarge(Type[0]?.HighCubeJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #18cdd6;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.Reefers') }}: {{ PercentageDischarge(Type[0]?.ReeferJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #ffc000;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.special') }}: {{ PercentageDischarge(Type[0]?.SpecialJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #92cddc;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.Tank') }}: {{ PercentageDischarge(Type[0]?.TankJson[0]) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CRow>
            </div>
            <div class="col-sm-12 col-lg-6">
                <CRow>
                    <div class="col-sm-12 col-lg-7">
                        <simpleDonut
                            :dataLabels="false"
                            :Series="SeriesArray(Type[1])" 
                            :Colors="ColorArray" 
                            :Categories="LabelsArray"
                            :Tooltip="Tooltip"
                        />
                    </div>
                    <div class="col-sm-12 col-lg-5 d-flex justify-content-center">
                        <div class="col-sm-5 col-lg-12">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td class="text-center"><strong>{{ $t('label.LOAD') }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #3492b0;"></span>
                                            </div>
                                        </td>
                                        <td>{{$t('label.Estandar')}}: {{ PercentageLoad(Type[1]?.StandardJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #da8368;"></span>
                                            </div>
                                        </td>
                                        <td>HIGH CUBE: {{ PercentageLoad(Type[1]?.HighCubeJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #18cdd6;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.Reefers') }}: {{ PercentageLoad(Type[1]?.ReeferJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #ffc000;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.special') }}: {{ PercentageLoad(Type[1]?.SpecialJson[0]) }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="circle mr-1" style="background: #92cddc;"></span>
                                            </div>
                                        </td>
                                        <td>{{ $t('label.Tank') }}: {{ PercentageLoad(Type[1]?.TankJson[0]) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CRow>
            </div>
        </CRow>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import simpleDonut from '@/pages/situacion-operativa-actual/components/simpleDonut';

function data() {
    return {
        Size: [],
        Type: [],
    }
}
//methods
function SeriesArray(type){
    let data = [];
    if (type) {
        type?.StandardJson[0].CantTotal!=0?data.push(type.StandardJson[0].CantTotal): '';
        type?.HighCubeJson[0].CantTotal!=0?data.push(type.HighCubeJson[0].CantTotal): '';
        type?.ReeferJson[0].CantTotal!=0?data.push(type.ReeferJson[0].CantTotal): '';
        type?.SpecialJson[0].CantTotal!=0?data.push(type.SpecialJson[0].CantTotal): '';
        type?.TankJson[0].CantTotal!=0?data.push(type.TankJson[0].CantTotal): '';
    }
    return data;
}

function PercentageDischarge(type) {
    if (type) {
        let Total = this.Type[0].HighCubeJson[0].CantTotal+this.Type[0].ReeferJson[0].CantTotal+
            this.Type[0].SpecialJson[0].CantTotal+this.Type[0].StandardJson[0].CantTotal+this.Type[0].TankJson[0].CantTotal;
        return ((type.CantTotal/Total)*100).toFixed(2)+' %';
    }else{
        return 0+' %';
    }
}

function PercentageLoad(type) {
    if (type) {
        let Total = this.Type[1].HighCubeJson[0].CantTotal+this.Type[1].ReeferJson[0].CantTotal+
            this.Type[1].SpecialJson[0].CantTotal+this.Type[1].StandardJson[0].CantTotal+this.Type[1].TankJson[0].CantTotal;
        return ((type.CantTotal/Total)*100).toFixed(2)+' %';
        
    }else{
        return 0+' %';
    }
}
//computed
function TotalType() {
    let Total = {
        StandardCant20: 0,
        StandardCant40: 0,
        StandardCant45: 0,
        StandardCantTotal: 0,
        HighCubeCant20: 0,
        HighCubeCant40: 0,
        HighCubeCant45: 0,
        HighCubeCantTotal: 0,
        ReeferCant20: 0,
        ReeferCant40: 0,
        ReeferCant45: 0,
        ReeferCantTotal: 0,
        SpecialCant20: 0,
        SpecialCant40: 0,
        SpecialCant45: 0,
        SpecialCantTotal: 0,
        TankCant20: 0,
        TankCant40: 0,
        TankCant45: 0,
        TankCantTotal: 0,
    }
    this.Type.map(item => {
        Total.StandardCant20 += item.StandardJson[0].Cant20;
        Total.StandardCant40 += item.StandardJson[0].Cant40;
        Total.StandardCant45 += item.StandardJson[0].Cant45,
        Total.StandardCantTotal += item.StandardJson[0].CantTotal;
        Total.HighCubeCant20 += item.HighCubeJson[0].Cant20;
        Total.HighCubeCant40 += item.HighCubeJson[0].Cant40;
        Total.HighCubeCant45 += item.HighCubeJson[0].Cant45;
        Total.HighCubeCantTotal += item.HighCubeJson[0].CantTotal;
        Total.ReeferCant20 += item.ReeferJson[0].Cant20;
        Total.ReeferCant40 += item.ReeferJson[0].Cant40;
        Total.ReeferCant45 += item.ReeferJson[0].Cant45;
        Total.ReeferCantTotal += item.ReeferJson[0].CantTotal;
        Total.SpecialCant20 += item.SpecialJson[0].Cant20;
        Total.SpecialCant40 += item.SpecialJson[0].Cant40;
        Total.SpecialCant45 += item.SpecialJson[0].Cant45;
        Total.SpecialCantTotal += item.SpecialJson[0].CantTotal;
        Total.TankCant20 += item.TankJson[0].Cant20;
        Total.TankCant40 += item.TankJson[0].Cant40;
        Total.TankCant45 += item.TankJson[0].Cant45;
        Total.TankCantTotal += item.TankJson[0].CantTotal;
    })
    return Total;
}

function ColorArray(){
    let data = ['#3492b0', '#da8368', '#18cdd6', '#ffc000', '#92cddc'];
    return data;   
}

function LabelsArray(){
    let data = [this.$t('label.Estandar'), 'HIGH CUBE', this.$t('label.Reefers'), this.$t('label.special'),
        this.$t('label.Tank')];
    return data;
}

function Tooltip() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
}

export default {
    name:"time-in-port",
    data,
    mixins: [],
    props:{
        SizeJson:{
            type: Array,
            default: function () {
                return [] 
            },
        },
        TypeJson:{
            type: Array,
            default: function () {
                return [] 
            },
        }
    },
    methods:{
        PercentageLoad,
        PercentageDischarge,
        SeriesArray,
    },
    components:{
        simpleDonut,
    },
    computed:{
        TotalType,
        LabelsArray,
        ColorArray,
        Tooltip,
        ...mapState({
            VisitId: state => state.Management.VisitId,
            CarruselTab: state => state.Management.CarruselTab,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==4) {
                this.Size = this.SizeJson&&this.SizeJson.length!=0?this.SizeJson:[];
                this.Type = this.TypeJson&&this.TypeJson.length!=0?this.TypeJson:[];
            }
            if (oldVal==4) {
                this.Size = [];
                this.Type = [];
            }
        }
    }
}
</script>
<style scoped>

.circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.lapse-table{
width: 100%;
border-collapse: collapse;
}
table.lapse-table tr:last-child td {
    border-bottom: none;
}
table.lapse-table tr:first-child th {
    border-top: none !important;
}
table.lapse-table th{
border-left: none !important;
border-bottom: none;
}
table.lapse-table th:last-child {
border-right: none !important;
}
table.lapse-table td:first-child {
border-left: none !important;
border-right: none !important;
}
table.lapse-table td:last-child {
border-right: none !important;
}
</style>