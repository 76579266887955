<template>
  <CRow>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #Seals="{ item }">
          <td class="center-item d-flex flex-column">
            <span v-for="seal in item.Seals">{{ seal }}</span>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>

<script>
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';

function fields() { 
  return [
    // {
    //   key: "Details",
    //   label: "",
    //   sorter: false,
    //   filter: false,
    //   _styles: "width: 1%;",
    // },
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center'
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: this.$t('label.status'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Mov",
      label: this.$t('label.transaction'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Reason",
      label: this.$t('label.motive'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Fecha",
      label: this.$t("label.date"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Usuario",
      label: this.$t("label.user"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Device",
      label: this.$t("label.device"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
}


function data() {
  return {
    items: [],
    activePage: 1,
    isLoading: false,
  };
}

//Methods:
function formatSeals(seal1, seal2, seal3, seal4) {
  let arr = [];

  if(seal1) arr.push(seal1);
  if(seal2) arr.push(seal2);
  if(seal3) arr.push(seal3);
  if(seal4) arr.push(seal4);

  return arr;
}

//Computed:
function formatedItems() {
  let index = 1;
  return this.items.map((item) =>
    Object.assign({}, item, {
      // _classes: this.tableVisitClasses(item),
      Nro: index++,
      Imagen: `${this.$store.getters["connection/getBase"]}${
        item.Route ? item.Route.replace("Public/", "", null, "i") : ""
      }`,
      ContainerCode: item.ContainerCode ? item.ContainerCode : "",
      SizeCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : "",
      IsoCode: item.IsoCode ? item.IsoCode : "",
      Status: item.TpCargoStatusName ? item.TpCargoStatusName : "",
      Carrier: item.ShippingLineCode ? item.ShippingLineCode : "",
      ImdgCode: item.ImdgCode ? item.ImdgCode : "",
      LoadPort: item.LoadPort ? item.LoadPort : "",
      DischargePort: item.DischargePort ? item.DischargePort : "",
      Seals: this.formatSeals(item.Seal1, item.Seal2, item.Seal3, item.Seal4),
      Weight: item.WeigthJson
        ? `${NumberFormater.formatNumber(item.WeigthJson[1].Value, 2)}`
        : "",
      Height: item.HeightJson
        ? `${NumberFormater.formatNumber(item.HeightJson[0].Value, 2)}`
        : "",
      Length: item.LenghtJson
        ? `${NumberFormater.formatNumber(item.LenghtJson[0].Value, 2)}`
        : "",
      Mov: this.$i18n.locale == 'en'
        ? item.MovStowageNameEn ? item.MovStowageNameEn : ''
        : item.MovStowageNameEs ? item.MovStowageNameEs : '',
      Reason: this.$i18n.locale == 'en'
        ? item.MovStowageReasonNameEn ? item.MovStowageReasonNameEn : ''
        : item.MovStowageReasonNameEs ? item.MovStowageReasonNameEs : '',
      Device: item.DeviceCode ? item.DeviceCode : "",
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.ConfirmationDate
        ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate)
        : "N/A",
    })
  );
}

export default {
  name: 'reverse-list',
  mixins: [GeneralMixin],
  components: {
  },
  props: {
    plano: {
      type: Object,
      default: () => {}
    },
  },
  data,
  methods: {
    formatSeals,
  },
  computed: {
    fields,
    formatedItems
  },
  watch: {
    plano: {
      handler(val){
        this.items = this.plano.ReverseListJson[0].CargoJson || [];
      },
      deep: true
   },
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>