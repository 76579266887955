<template>
  <div class="mb-3">
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="activarModal()"
          v-c-tooltip="{
            content: $t('label.brand'),
            placement: 'top-end',
          }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.nueva')}}
          </span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
        class="align-center-row-datatable"
        size="lg"
        sorter

        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        pagination
        :loading="loading"                            
    >
        <template #loading>
            <loading/>
        </template>
        <template #Status="{item}">
            <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
            </td>
        </template>
        <template #acciones="{item, index}">
            <td class="center-cell">
                <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.brand'),
                    placement: 'top-end'
                    }"
                    @click="editModal(item, index)"
                >
                    <CIcon name="pencil"/>
                </CButton>
            </td>
        </template>
    </dataTableExtended>

    <modal-brand
         @set-modal-brand-list="setBrandList"
    />

  </div>
</template>
<script>
    import { DateFormater, alertPropertiesHelpers, tableTextTranslatedHelpers } from '@/_helpers/funciones';
    import General from '@/_mixins/general';
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import ModalBrand from './modal-brand.vue';
    //data
    function data() {
        return {
            Items: [],
            loading: false,
        };
    }


    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }

    function formatedItems() {
        return this.Items.map((item) => {
            return {
            ...item,
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
            Machine: item.FgMachine ? this.$t('label.machinery') : this.$t('label.devices'),
            FormatedDate: item.TransaRegDate
                ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
                : 'N/A',
            };
        });
    }

    function fields(){
        return [
            { key: 'Nro', label: '#', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false },
            { key: 'BrandName',label: this.$t('label.brand'), _classes:"text-left", _style:'width:35%' },
            { key: "Machine", label: this.$t('label.applyTo'), _style: "width:20%", _classes:"text-left" },
            { key: 'TransaLogin', label: this.$t('label.user'), _classes: 'text-uppercase', _style:'width:15%'},
            { key: 'TransaRegDate', label: this.$t('label.date'), _classes:'text-center', _style:'width:15%'},
            { key: 'Status', label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:15%;'},
            { key: 'acciones', label: '', _style: 'width:50px; min-width:45px;', sorter: false, filter: false}
        ]
    }

    function tableText(){
        return tableTextTranslatedHelpers(this);
    }

    //methods
    function BrandList() {
        this.loading = true;
        let i = 0;
        this.$http
        .get('Brand-list',  { Filter: 'ALL'} , '')
        .then(res => {
          this.Items = res.data.data;
          if (this.Items.length > 0) {
            this.Items.map((item) => {
              this.Items[i].TransaRegDate = DateFormater.formatDateTimeWithSlash(item.TransaRegDate);
              i++;
            });
          }
        }).finally(() => {
            this.loading = false,
            this.$store.commit('marcamodelo/mutationModalBrand', false);
        })
    }

    async function setBrandList() {
        this.BrandList();
    }

    function editModal(item,index){
        this.$store.state.marcamodelo.BrandRoute = item.BrandRoute;
        this.$store.state.marcamodelo.idMarca = item.BrandId;
        this.$store.commit('marcamodelo/mutationModalBrand', true);
    }

    function activarModal(){
        this.$store.commit('marcamodelo/mutationModalBrand', true);
    }
    
    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
                break;
            default:
                break;
        }
    }

    export default{
        name: 'brand-list',
        data,
        mixins: [General],
        components: {
            ModalBrand,
            
        },
        methods: {
            BrandList,
            setBrandList,
            editModal,
            activarModal,
        },

        computed: {
            formatedItems,
            apiStateLoading,
            apiStateFormLoading,
            fields,
            tableText,
            ...mapState({
                tabIndex: state => state.marcamodelo.tabIndex,
                apiState: state => state.marcamodelo.apiState,
                messageError: state => state.marcamodelo.messageError,
                apiStateForm: state => state.marcamodelo.apiStateForm,
            })
        },
        mounted: setBrandList,
        watch:{
            tabIndex:async function (val) {
                
                if (val==0) {
                    this.setBrandList();
                }
            },
            apiState,
            apiStateForm
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>