<template>
  <div>
    <CRow class="my-3" v-if="!isWasRequested">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="backToList()"
        >
          <CIcon name="cil-chevron-left-alt"/>
        </CButton>
        <CRow class="align-items-center justify-content-start w-100 mt-2 m-0">
            <h6>
                {{`${ this.isEdit ? $t('label.editDeconsolidationEvent') : $t('label.newDeconsolidationEvent')}: ${this.dataContainer.ContainerCode}`}} 
            </h6>
        </CRow>
      </CCol>
    </CRow>
    <CRow class="justify-content-center mt-3 px-3">
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.basicData')">
            <CCard class="card-border">
              <CRow class="mb-4">
                <CCol sm="12" lg="12">
                  <CRow class="justify-content-center" style="font-size: 13px;">
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.declaredHouse')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHouseCount, 2)}`}}</b>
                    </CCol>
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.totalHouseReceived')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHouseQuantityReceived, 2)}`}}</b>
                    </CCol>
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.totalPlannedHouse')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHousePlannedQuantityReceived, 2)}`}}</b>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.EventReasonId.$model"
                    :options="EventOptions"
                    :label="$t('label.motive')"
                    addLabelClasses="required text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4', 
                      input: 'col-sm-12 col-lg-8' 
                    }"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventReasonId)"
                    :invalid-feedback="errorMessage($v.EventReasonId)"
                    @change="getEventService"
                    :disabled="isWasRequested || isDisabled"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.EventServiceId.$model"
                    :options="ServiceOptions"
                    :label="$t('label.service')"
                    :addLabelClasses="EventServiceList.length!=0 ? 'required text-right' : 'text-right'"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventServiceId)"
                    :invalid-feedback="errorMessage($v.EventServiceId)"
                    :disabled="EventServiceList.length==0 || isWasRequested || isDisabled" 
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.from')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.fromDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="fromDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.fromDay)"
                            size="sm"          
                            :disabled="isWasRequested || isDisabled"            
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.MachineId.$model"
                    :options="MachineOptions"
                    :label="$t('label.machine')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.MachineId)"
                    :invalid-feedback="errorMessage($v.MachineId)"
                    :disabled="isWasRequested || isDisabled"
                  />
                </CCol>
                <CCol sm="12" lg="6" v-if="false">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.PositioningDate')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.TransactionDate.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="TransactionDate"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.TransactionDate)"
                            size="sm"   
                            :disabled="isDisabled"                   
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.to')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.toDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="toDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.toDay)"
                            size="sm"     
                            :disabled="true"                 
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    v-if="isEdit"
                    size="sm"
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                    :disabled="isWasRequested || isDisabled"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                    :disabled="isWasRequested || isDisabled"
                  />
                </CCol>
                <CCol sm="12" lg="6"></CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.inspectionSeals')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal1.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal1.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal1.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal1, 1)"
                        :disabled="isWasRequested || isDisabled"
                      />
                      <CButton
                        v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal2.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal2.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal2.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal2, 2)"
                        :disabled="isWasRequested || isDisabled"
                      />
                      <CButton
                        v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal3.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal3.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal3.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal3, 3)"
                        :disabled="isWasRequested || isDisabled"
                      />
                      <CButton
                        v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal4.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal4.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal4.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal4, 4)"
                        :disabled="isWasRequested || isDisabled"
                      />
                      <CButton
                        v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="isWasRequested || isDisabled"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" class="text-invalid-feedback text-center" 
                  v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                >
                  <label>{{ $t('label.AtLeastSealRequired') }}</label>
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
        <CRow>
          <CCol sm="12" lg="12" class="d-flex justify-content-end"  v-if="!isWasRequested && !isDisabled">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="statusConfirmation(ContainerItem.FgActYardCargoEventDetail ? 1 : 0, Status, Submit)"
              :disabled="isSubmit"
            >
              <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="isWasRequested">
              <CButton
                  square
                  color="watch"
                  class="d-flex align-items-center"
                  @click="backToList()"
              >
                  <CIcon name='cil-list'/>{{ $t('label.blsHousePendingReceive')}}
              </CButton>

              <CButton
                  square
                  color="watch"
                  class="d-flex align-items-center ml-2"
                  @click="NextReception()"
              >
                  <CIcon name='cil-list'/>{{ $t('label.reception')}}
              </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <ModalSeal
      :modal="ModalSealActive"
      :SealJson="SealJson"
      :isEdit="isEdit"
      @CloseModal="(ModalSealActive=false, SealJson={})"
      @UpdateSeal="UpdateSeal"
      @UpdateRegister="UpdateRegister"
    />
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import desconsolitedRegisterValidations from '@/_validations/yard-management/events/desconsolitedRegisterValidations';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import ModalSeal from '@/pages/yard-management/modal-seal';
import moment from 'moment';

function data() {
  return {
    IdDesconsolidateToPendingBl: '',
    isDisabled: false,
    isWasRequested: false,
    loadingEdit: false,
    Continue: false,
    misTime: false,
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    SealJson: {},
    EventReasonId: '',
    EventList: [],
    EventServiceId: '',
    EventServiceList: [],
    MachineId: '',
    MachineList: [],
    TransactionDate: '',
    fromDay: '',
    toDay: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    YardName: '',
    BlockOrArea: '',
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Status: 0,
    CargoJson: {}
  };
}

async function getSealConditionList() {
  await this.$http.get('SealConditionDeconsolidation-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardMachine() {
  let TpCargoDetailId = this.dataContainer.TpCargoDetailId;
  await this.$http.get('YardMachine-by-YardId-TpCargoDetailId', { YardId: this.dataContainer.YardId, CompanyBranchId: this.CompanyBranchId, TpCargoDetailId: TpCargoDetailId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardEventReasonList() {
  let Parameter = {
    EventId: process.env.VUE_APP_EVENT_DESCONSOLIDATION,
    CargoId: this.dataContainer.CargoId,
    YardId: this.dataContainer?.YardId ?? this.ContainerItem.YardId,
  };
  await this.$http.get('YardEventReason-list', Parameter)
    .then(response => {
      let List = response.data.data;
      this.EventList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function isContinue() {
  try{
    this.$v.fromDay.$touch();
    this.$v.toDay.$touch()
    if (this.$v.fromDay.$error || this.$v.toDay.$error){
      if (!this.fromDay) 
        throw this.$t('label.TheDateFromIsRequired');

      if (!this.toDay) 
        throw this.$t('label.TheDateToIsRequired');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate) 
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate) 
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm") || DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
        throw this.$t('label.ErrorDispatchDatePleaseCheck');

      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay)) 
        throw this.$t('label.ErrorFromToPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
        throw this.$t('label.ErrorToFromPleaseCheck');
    }

    this.Continue = true;
    this.$v.$reset();
  }
  catch(e){
    
    this.notifyError({text: e});
  }
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
        throw this.$t('label.ErrorDispatchDatePleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
        throw this.$t('label.ErrorFromToPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let BlCargoJson = {
      YardCargoId: this.dataContainer?.YardCargoId,
      EventServiceId: this.EventServiceId,
      EventReasonId: this.EventReasonId,
      MachineId: this.MachineId,
      Observation: this.Observation,
      TransactionBegin: this.fromDay ? DateFormater.formatDateTimeWithoutSlash(this.fromDay) : '', 
      TransactionFinish: this.toDay ? DateFormater.formatDateTimeWithoutSlash(this.toDay) : '',
     // TransactionDate: !this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) : undefined,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
        this.Seal.Seal1.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
        this.Seal.Seal2.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
        this.Seal.Seal3.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
        this.Seal.Seal4.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
    }

    if (this.isEdit) {
      BlCargoJson.YardCargoEventDetailId = this.ContainerItem.YardCargoEventDetailId;
      BlCargoJson.VisitCargoInspectionId = this.ContainerItem?.SealJson[0]?.VisitCargoInspectionId ?? '';
      BlCargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardWCargoDeconsolidation-update' : 'YardWCargoDeconsolidation';

    this.$http.ejecutar(Method, Route, BlCargoJson, { root: 'BlCargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        //this.$emit("Update-list", this.ContainerItem);
        this.isSubmit = false;
        this.$emit("Reception", response.data.data[0]);
        this.Reset();
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function UpdateRegister(images, index) {
  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images;
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images;
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images;
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images;
      break;
  }
 
  await this.$http.put('YardCargoDispatch-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await this.$emit("Update-list");
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}

function getEventService(event) {
  this.EventReasonId = event.target.value;
  this.EventServiceId = '';
  this.EventServiceList = [];
  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData.EventServiceJson : [];
  }
}

function FormatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_WITHDRAWAL == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function validateDateInitRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) 
    return true;
  
  return false;
}

function validateDateFinalRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
    return true;
  
  return false;
}

function getPositionJson(PositionJson, Nominal) {
  if (PositionJson) {
    if (Nominal) {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS==PreviousDetail.YardSlotStatus?.toUpperCase())
    } else {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS!=PreviousDetail.YardSlotStatus?.toUpperCase())
    }
  }else{
    return [];
  }
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function ChangeTime() {

  if(!this.misTime){
    this.fromDay = '';
    this.toDay = '';
    this.Continue = false;
    this.$v.$reset();
  }

  else{
    this.TransactionDate = ''
    this.$v.$reset();
  }
}

function getNewData() {
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.Seal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_WITHDRAWAL : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ? this.Seal.Seal1.SealDocumentJson.concat(this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson) : [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.Seal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_WITHDRAWAL : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ? this.Seal.Seal2.SealDocumentJson.concat(this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson) : [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.Seal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_WITHDRAWAL : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ? this.Seal.Seal3.SealDocumentJson.concat(this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson) : [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.Seal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_WITHDRAWAL : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ? this.Seal.Seal4.SealDocumentJson.concat(this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson) : [];
  this.$v.$reset();
}

async function backToList() {
  this.$store.state.yardManagement.loading = true;
  this.IdDesconsolidateToPendingBl = '';
  this.$emit("Update-list", false);
  this.Reset();
}

async function NextReception() {
  this.$store.state.yardManagement.IdDesconsolidateToPendingBl = this.IdDesconsolidateToPendingBl;
  this.$emit("ReceptionDesconsolidation");
  this.Reset();
}

async function getdata(val) {
  this.loadingEdit = true;

  this.Continue = true;
  this.misTime = true; 
  this.TransactionDate = val.TransactionDate;
  this.fromDay = DateFormater.formatDateTimeWithSlash(val.TransactionBegin);
  this.toDay = val.TransactionFinish ? DateFormater.formatDateTimeWithSlash(val.TransactionFinish) : '';

  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.NewSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.NewSeal =val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.EventReasonId = val.EventReasonId ?? '';
  this.EventServiceId = val.EventServiceId ?? '';
  this.MachineId = val.MachineId ?? '';
  this.Observation = val.Observation ?? '';
  this.Status = val.FgActYardCargoEventDetail ? 1 : 0;
  this.CargoJson = {
    YardCargoEventDetailId: this.ContainerItem.YardCargoEventDetailId,
    YardCargoId: this.dataContainer?.YardCargoId,
    EventServiceId: this.EventServiceId,
    EventReasonId: this.EventReasonId,
    MachineId: this.MachineId,
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal.Seal1.SealConditionId,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.Seal.Seal1.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2.SealConditionId,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.Seal.Seal2.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3.SealConditionId,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.Seal.Seal3.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4.SealConditionId,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.Seal.Seal4.SealDocumentJson.map(Document => {
      return{
        SealRoute: Document.SealRoute ?? '',
        FgActDocument: 1,
        Status: 1,
      } 
    }),
    TransactionDate: val.TransactionDate,
    Observation: this.Observation,
    VisitCargoInspectionId: this.ContainerItem?.SealJson[0]?.VisitCargoInspectionId ?? '',
    Status: this.Status,
  }

  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData?.EventServiceJson : [];
  }

  this.Validate = true;
  this.$v.$touch();
  this.loadingEdit = false;
}

function getDateRange() {
  this.previousDate = DateFormater.formatDateRange(this.dataContainer?.ReceptionDate ?? process.env.VUE_APP_START_DATE_IN_PATIO) 
  this.laterDate = new Date();
  this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer?.ReceptionDate ?? process.env.VUE_APP_START_DATE_IN_PATIO)) 
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function statusSelectColor() {
  return this.Status === 1;
}

function Reset(isClose = true) {
  if(isClose)
    this.isWasRequested = false;

  this.isDisabled = false;
  this.misTime = false;
  this.fromDay = '';
  this.toDay = '';
  this.EventReasonId = '',
  this.EventList = [],
  this.EventServiceId = '',
  this.EventServiceList = [],
  this.TransactionDate = '',
  this.ValidPreviousDate = '',
  this.ValidLaterDate = '',
  this.previousDate = '',
  this.laterDate = '',
  this.Observation = '',
  this.YardName = '';
  this.MachineId = '';
  this.MachineList = [];
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.ConditionList = [];
  this.Status = 0;
  this.CargoJson = {};
  this.Validate = false;
  this.$v.$reset();
  isClose && this.$emit("Close");
}

//Computeds:
function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventReasonId,
        label: e[`EventReasonName${_lang}`],
      })
    })
    return chart;
  }
}

function ServiceOptions() {
  if(this.EventServiceList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventServiceList.map(function(e){
      chart.push({
        value: e.EventId,
        label: e[`EventName${_lang}`],
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: e[`SealConditionName${_lang}`],
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

function ValidateDispached() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED==this.YardCargoStatus?.toUpperCase();
}

function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

function ContainerSize() {
  return this.dataContainer?.Size != 20;
}

export default {
  name: 'positioning-register',
  components: {
    ModalSeal,
   },
  props: { Active: Boolean, isEdit: Boolean, ContainerItem: Object, HouseData: Object },
  data,
  mixins: [
    ModalMixin
  ],
  validations(){
    return desconsolitedRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate,
      moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm") ,this.EventServiceList.length!=0, 
      this.fromDay, this.toDay, this.misTime);
  },
  directives: UpperCase,
  methods: {
    getSealConditionList,
    getYardMachine,
    getYardEventReasonList,
    Submit,
    UpdateRegister,
    getEventService,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    getPositionJson,
    UpdateSeal,
    getNewData,
    getdata,
    getDateRange,
    statusSelectColor,
    Reset,
    ChangeTime,
    backToList,
    NextReception,
    isContinue,
    FormatNumber
  },
  computed: {
    validateDateInitRange,
    validateDateFinalRange,
    MachineOptions,
    EventOptions,
    ServiceOptions,
    ConditionOptions,
    ValidateTpCargoStatus,
    ValidateDispached,
    ContainerSize,
    VerifySeal,
    ...mapState({
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      dataContainer: state => state.yardManagement.dataContainer,
      YardCargoStatus: state => state.yardManagement.dataContainer.YardCargoStatus,
      dropItemEvent: state => state.yardManagement.dropItemEvent,
      yardData: state => state.yardManagement.yardData,
    })
  },
  watch: {
    Active: async function(val){
      this.IdDesconsolidateToPendingBl = '';

      if (val) {
        this.isWasRequested = false;
        this.Reset(false);

        if(!(this.yardData?.YardId) || (this.isEdit && !this.ContainerItem.FgActYardCargoEventDetail))
          this.isDisabled = true; 

        this.$store.state.yardManagement.loading = true;
        this.YardName = this.dataContainer.YardName;
        await this.getDateRange();
        await this.getSealConditionList();
        await this.getYardMachine();
        await this.getYardEventReasonList();
        if (this.isEdit) {
          await this.getdata(this.ContainerItem);
        }else{
         
          if (this.ValidateDispached) {
            this.Continue = true;
            this.misTime = true;
          }
          
          await this.getNewData();
        }
        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    fromDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    toDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }
          
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) {
            throw this.$t('label.ErrorToFromPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropItemEvent: function (NewVal, OldVal) {
      if (OldVal == 1) {
        this.Reset(false);
      }
    }
  },
};
</script>
<style>
.text-size-11{
  font-size: 11px;
}
.card-border{
  padding: 20px;
  background-color: #fff;
  border-color: #c4c9d0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
</style>