import {
    required,
    maxLength
} from 'vuelidate/lib/validators';

export default () => {
    return {
        Yard: { required },
        Machinery: { required },
        Status: { required },
    }
}