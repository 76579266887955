import ENUM from './enum';
import service from '@/_services/service';
import {DateFormater} from '@/_helpers/funciones';

export const tiempoperdido = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        id:0,
        idTipoTpPerdido:0,
        filtroTiempo:'ALL',
        messageError:'',
        myData:[],
        myDataTpTiempoPerdido:[],
        modalTiempoPerdido:false,
        collapseTipoTiempoPerdido:false,
        collapseTableTipoTiempoPerdido:false,
    },
    getters: {
        myDatatable: state => {
            let chart = [];
            if(state.myData.length !== 0){
                state.myData.sort(function (a, b) {
                    if (a.FgActDelay < b.FgActDelay) return 1;
                    else if (a.FgActDelay > b.FgActDelay) return -1;
                    else {
                        if (a.DelayDsEn > b.DelayDsEn) {
                            return 1;
                        }
                        if (a.DelayDsEn < b.DelayDsEn) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myData.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActDelay,
                    _classes: ( item.FgActDelay ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataTpTiempoPerdidoTableEn: state => {
            let chart = [];
            
            if(state.myDataTpTiempoPerdido.length !== 0){
                state.myDataTpTiempoPerdido.sort(function (a, b) {
                    if (a.FgActTpDelay < b.FgActTpDelay) return 1;
                    else if (a.FgActTpDelay > b.FgActTpDelay) return -1;
                    else {
                        if (a.TpDelayNameEn > b.TpDelayNameEn) {
                            return 1;
                        }
                        if (a.TpDelayNameEn < b.TpDelayNameEn) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataTpTiempoPerdido.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpDelay,
                    _classes: ( item.FgActTpDelay ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataTpTiempoPerdidoTableEs: state => {
            let chart = [];
            
            if(state.myDataTpTiempoPerdido.length !== 0){
                state.myDataTpTiempoPerdido.sort(function (a, b) {
                    if (a.FgActTpDelay < b.FgActTpDelay) return 1;
                    else if (a.FgActTpDelay > b.FgActTpDelay) return -1;
                    else {
                        if (a.TpDelayNameEs > b.TpDelayNameEs) {
                            return 1;
                        }
                        if (a.TpDelayNameEs < b.TpDelayNameEs) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataTpTiempoPerdido.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpDelay,
                    _classes: ( item.FgActTpDelay ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
    },
    actions: {
        getTiempoPerdidolist({commit},filtro,TpDelayId){
            commit('getUnidadlisttRequest');

            service.ejecutar('GET', 'Delay-list', { filtro: (filtro === undefined || filtro === null) ? 'ALL': filtro,TpDelayId: (TpDelayId === undefined || TpDelayId === null) ? '': TpDelayId}, '')
            .then(
                Tiempos => commit('getUnidadlisttSuccess', { Tiempos }),
                error => {
                    commit('messageMutation', error)
                    commit('getUnidadlisttFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterTiempoPerdido ({ commit,dispatch }, { IdTiempoPerdido,IdTpTiempoPerdido,DsTiempoPerdidoEn, DsTiempoPerdidoEs,TdrCode,FgActTiempoPerdido }) {
            commit('postregisterTiempoRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'Delay-insert';
                if(IdTiempoPerdido === 0){
                    valores = {
                        TpDelayId:IdTpTiempoPerdido,
                        DelayDsEn:DsTiempoPerdidoEn,
                        DelayDsEs:DsTiempoPerdidoEs,
                        TdrCode:TdrCode,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'Delay-update';
                    valores = {
                        DelayId:IdTiempoPerdido,
                        TpDelayId: IdTpTiempoPerdido,
                        DelayDsEn: DsTiempoPerdidoEn,
                        DelayDsEs: DsTiempoPerdidoEs,
                        TdrCode:TdrCode,
                        Status: FgActTiempoPerdido ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }

                service.ejecutar(method, link, valores, { root: 'DelayJson' })
                .then(
                    dataTiempo => {
                        if(dataTiempo.data.status === 200){
                            const information = dataTiempo.data.data;
                            if(information.length !== 0){
                                if(information[0].id === ""){
                                    commit('messageMutation', information[0].Response)
                                    commit('postregisterTiempoFailure', information[0].Response)
                                }else{
                                    commit('postregisterTiempoSuccess', { information })
                                    dispatch('tiempoperdido/getTiempoPerdidolist', 'ALL', { root: true });
                                }
                            }else{
                                commit('messageMutation', error)
                                commit('postregisterTiempoFailure', information);
                            }
                        }else{
                            commit('postregisterTiempoFailure', dataTiempo.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('postregisterTiempoFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterTiempoFailure', error)
            }
        },
        getTpTiempoPerdidolist({commit}){
            commit('getTipoTiempolistRequest');
            service.ejecutar('GET', 'TpDelay-list', { Filter: 'ALL' }, '')
            .then(
                TipoTiempo => commit('getTipoTiempolistSuccess', { TipoTiempo }),
                error => {
                    commit('messageMutation', error)
                    commit('getTipoTiempolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterTpTiempoPerdido ({ commit,dispatch }, { IdTpTiempoPerdido,NbTpTiempoPerdidoEn,NbTpTiempoPerdidoEs,FgActTpTiempoPerdido }) {
            commit('postregisterTpTiempoPerdidoRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'TpDelay-insert';
                if(IdTpTiempoPerdido === 0){
                    valores = {
                        TpDelayNameEn:NbTpTiempoPerdidoEn,
                        TpDelayNameEs:NbTpTiempoPerdidoEs,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'TpDelay-update';
                    valores = {
                        TpDelayId:IdTpTiempoPerdido,
                        TpDelayNameEn:NbTpTiempoPerdidoEn,
                        TpDelayNameEs:NbTpTiempoPerdidoEs,
                        Status: FgActTpTiempoPerdido ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                try{ 
                    service.ejecutar(method, link, valores, { root: 'TpDelayJson' })
                        .then(
                            dataTipoTiempo => {
                                if(dataTipoTiempo.data.status === 200){
                                    const information = dataTipoTiempo.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterTpTiempoPerdidoFailure', information[0].Response)
                                    }else{
                                        commit('postregisterTpTiempoPerdidoSuccess', { information });
                                        dispatch('tiempoperdido/getTpTiempoPerdidolist', '', { root: true });
                                    }
                                }else{
                                    commit('postregisterTpTiempoPerdidoFailure', dataTipoTiempo.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('postregisterTpTiempoPerdidoFailure', error)
                                //dispatch('alert/error', error, { root: true });
                            }
                        );
                        } catch (error) {
                            commit('messageMutation', error)
                            commit('postregisterTpTiempoPerdidoFailure',error);
                        }
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('postregisterTpTiempoPerdidoFailure', error)
                    }
        }
    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
        },
        mutationModal (state,  action ) {
            state.modalTiempoPerdido = action;
        },
        mutationfiltroTiempo (state,  value ) {
            state.filtroTiempo = value;
        },
        messageMutation (state, value ) {
            state.messageError = value;
		},
        //Listar unidades
        getUnidadlisttRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getUnidadlisttSuccess(state, { Tiempos }) {
            try{
                if(Tiempos.status === 200){
                    state.myData = Tiempos.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Tiempos);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getUnidadlisttFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar un tiempo perdido
        postregisterTiempoRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterTiempoSuccess (state, { information }) {
            try{
                state.modalTiempoPerdido = false;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterTiempoFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },

        //Listar tipos unidades
        getTipoTiempolistRequest(state) {
            state.apiStateForm = ENUM.LOADING; 
        },
        getTipoTiempolistSuccess(state, { TipoTiempo }) {
            try{
                if(TipoTiempo.status === 200){
                    state.myDataTpTiempoPerdido = TipoTiempo.data.data;
                    state.apiStateForm = ENUM.INIT;
                }else{
                    console.log(TipoTiempo);
                    state.apiStateForm = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiStateForm = ENUM.ERROR; 
            }
        },
        getTipoTiempolistFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR; 
        },

        //Registrar o actualizar tipo de unidad
        postregisterTpTiempoPerdidoRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterTpTiempoPerdidoSuccess (state, { information }) {
            try{
                state.idTipoTpPerdido = 0;
               // state.idTipoTpPerdido = information[0].TpDelayId;
                state.messageError = information[0].Response;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterTpTiempoPerdidoFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
    }
}