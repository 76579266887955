<template>
  <div class="container-fluid top-menos">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CRow> 
      <CCol sm="12" class="text-right mt-4">
        <CButton
          square
          color="add"
          @click="generar=true"
          v-c-tooltip="{
            content: $t('label.DraftInspection'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1"
            >{{$t('label.nueva')}}</span
          >
        </CButton>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="computedListDraft"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
          <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
          </template>
          <template #options="{ item }">
            <td class="center-cell">       
                <CButton
                    square
                    size="sm"
                    color="edit"
                    class="mr-1"
                    @click="toggleEdit(item)"
                    v-c-tooltip="{
                        content: $t('label.edit')+' '+$t('label.DraftInspection'),
                        placement: 'top-end'
                    }"
                >
                    <CIcon name="pencil" />
                </CButton>      
                <CButton
                    square
                    size="sm"
                    color="watch"
                    class="mr-1"
                    @click="toggleHold(item)"
                    v-c-tooltip="{
                        content: $t('label.HoldList'),
                        placement: 'top-end'
                    }"
                >
                  <CIcon name="hold" />
                </CButton>     
                <CButton
                    square
                    color="danger"
                    size="sm"
                    class="mr-1"
                    @click.prevent="printPdf(item)"
                    v-c-tooltip="{
                        content: $t('label.generateReport'),
                        placement: 'top-end'
                    }"
                >
                    <CIcon name="cil-file-pdf"/>
                </CButton>
                       
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <modal-hold-information
      @closeModal="CloseHoldModal()"
      :HoldItems="HoldItems"
    />
    <draft-inspection
      :generar.sync="generar"
      :EditDraftItems="EditDraftItems"
      :isEdit="isEdit"
      @close-Modal="CloseModal"
    />  
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import General from '@/_mixins/general';
import DraftInspection from './draft-inspection';
import ModalHoldInformation from './modal-hold-information';
import { mapState } from 'vuex';

function data() {
  return {
    DraftItems: [],
    HoldItems: {},
    EditDraftItems: {},
    isEdit: false,
    nulo: '',
    loading: false,
    generar: false,

  };
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.DFrom'), key: 'DateFrom', _classes: 'text-center', _style: 'width:13%;',},
    { label: this.$t('label.DTo'), key: 'DateTo', _classes: 'text-center', _style:'width:13%' },
    { label: this.$t('label.TotalCertification')+this.$t('label.metricTonnageAcronym'), key: 'Cargo', _classes: 'text-center', _style:'width:25%' },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:10%' },
    { label: this.$t('label.date'), key: 'TransaRegDate',_classes:'text-center', _style:'width:11%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center', _style:'width:10%' },
    { label: '', key: 'options', _style: 'min-width:135px; width:1%;', _classes:'text-center', sorter: false, filter: false },
  ];
}

function tableText(){
    return tableTextTranslatedHelpers(this);
}

function toggleEdit(item) {
  this.EditDraftItems = item;
  this.isEdit = !this.isEdit;
  this.generar = !this.generar;
}

function toggleHold(item) {
  this.HoldItems = item;
}

function computedListDraft() {
  
  if (this.nulo=="null" || this.DraftItems ==[]) return this.DraftItems = [];
  if (this.DraftItems.length > 0) {
    return this.DraftItems.map((item) => {
      return {
        ...item,
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
      };
    });
  }
}

function CloseModal(Reset){
  this.generar = !this.generar;
  this.isEdit = false;
  this.EditDraftItems = {};
  if (Reset) {
    this.getDraftList();
  }
}

function CloseHoldModal(){
  this.HoldItems = {};
}

function getDraftList() {
  this.loading = true;
  this.DraftItems =[];
  let i = 0;
  let id = this.Visit;
    const VisitDraftJson = [{
      VisitId: id
    }]
      this.$http
        .post('VisitDraft-list', VisitDraftJson, { root: 'VisitDraftJson' })
        .then(res => {
          this.DraftItems = res.data.data;
          this.nulo = String(res.data.data[0].Json);
          if (this.DraftItems.length > 0 && this.nulo != "null") {
            this.DraftItems.map((item) => {
              this.DraftItems[i].DateFrom = DateFormater.formatDateTimeWithSlash(item.DateFrom);
              this.DraftItems[i].DateTo = DateFormater.formatDateTimeWithSlash(item.DateTo);
              this.DraftItems[i].TransaRegDate = DateFormater.formatDateTimeWithSlash(item.TransaRegDate);
              this.DraftItems[i].Cargo = formatNumber(item.Cargo);
              i++;
            });
          }
        }).finally(() => {
          this.loading = false;
        });
}

function formatNumber(number) {
  if (number == "") return "0,00";
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}

function printPdf(item) {

    let pageInfo = {
      VisitDraftId: item.VisitDraftId,
      item: item,
      itinerarySelected: this.itinerarySelected,
    };

    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

    let routeData = this.$router.resolve({name: 'PdfDraft'});
    window.open(routeData.href, '_blank');
}


export default {
  name: 'list-draft',
  data,
  mixins: [General],
  components: {
    DraftInspection,
    ModalHoldInformation,
  },
  methods: {
    formatNumber,
    printPdf,
    toggleEdit,
    CloseHoldModal,
    toggleHold,
    getDraftList,
    CloseModal,
  },  
  computed: {
    computedListDraft,
    fields,
    tableText,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        dropItemDratf: state => state.visitas.dropItemDratf,
    })
  },
  watch: {
    dropItemDratf: function (newValue) {
      if ((newValue==1) && (this.Visit!='')) {
        this.getDraftList();
      }
    },
  },
};
</script>
<style scope>
  .oscuro {
    color: #000;
  }

  .top-menos {
    margin-top: -20px;
  }

  .bg-watch {
    background-color: #1E3A61;
    color: #fff;
  }

  .badge-danger {
        background-color: transparent;
        color: #e55353;
    }
</style>