<template>
  <div>
    <loading-overlay
      :active="loadingOverlay"
      :is-full-page="true"
      loader="bars"
    />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.cargoGroupList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end py-2">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="toggleShowModalCreateGpo"
               v-c-tooltip="{
                content: $t('label.cargoGroup'),
                placement: 'top-end',
              }"
            >
              <CIcon name="cil-playlist-add" />{{$t('label.nuevo')}}
            </CButton>
          </CCol>
        </CRow>

        <!-- #### Table ####   -->
          <dataTableExtended
          class="align-center-row-datatable"
          :fields="fields"
          :items="computedGrupoCargaList"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
          sorter
        >
          <template #loading>
            <loading/>
          </template>
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="center-cell">
             
                <CButton
                  square
                  color="watch"
                  class=" mr-1"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.listMeasurementUnits'),
                    placement: 'top-end',
                  }"
                  @click="showModalUnits(item.GpoCargoId)"
                >
                  <CIcon name="eye" />
                </CButton>
                <CButton
                  square
                  color="edit"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.cargoGroup'),
                    placement: 'top-end',
                  }"
                  @click="toggleShowModalEditGpo(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
   
            </td>
          </template>
         </dataTableExtended>
      </CCardBody>
    </CCard>

    <modalGpoCarga
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      :isEdit="isEdit"
      @refresh-data-table="mountedGrupoCarga"
      :GpoCargaSelected="gpoCargaSelected"
    />

    <modalUnitMeasure
      :modal.sync="showModalUnidades"
      @updated-modal-unidades="updateShowModalUnitsMeasure"
      :gpoCargaId="selectedGpoId"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalGpoCarga from './modal-gpo-carga.vue';
import modalUnitMeasure from './modal-unidades-medidas.vue';


//### Valores de la data inicial ###
function data() {
  return {
    grupoItems: [],
    loadingOverlay: false,
    loadingTable: false,
    showModal: false,
    showModalUnidades: false,
    isEdit: false,
    selectedGpoId: '',
    gpoCargaSelected: {},
  };
}

function fields(){
  return [
      { 
        key: 'Nro', filter: false,
        label: '#',
        _style: 'width:4%; text-align:center',
      },
      { key: 'GpoCargoName', label: this.$t('label.cargoGroup'), _style:'width:42%;', _classes: 'text-uppercase' },
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:18%;', _classes: 'text-uppercase' },
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:18%;', _classes:"center-cell text-center"},
      { key: 'Status', label: this.$t('label.status'),  _style:'width:18%; text-align:center;', _classes:"center-cell text-center" },
      {  key: 'options',
        label: '', 
        _style: 'width:11%; min-width: 90px', 
        sorter: false, 
        filter: false,
      },
    ];
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedGrupoCarga() {
  this.loadingTable = true;

  this.$http
    .get('GpoCargo-list')
    .then((response) => (this.grupoItems = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function updateShowModalCreate(event) {
  this.showModal = false;
  this.isEdit = false;
  this.gpoCargaSelected = {};
}

function updateShowModalUnitsMeasure(event) {
  this.showModalUnidades = false;
}

function showModalUnits(gpoId) {
  this.selectedGpoId = gpoId;
  this.showModalUnidades = true;
}

function toggleShowModalCreateGpo() {
  this.showModal = true;
}

function toggleShowModalEditGpo(item) {
  this.showModal = true;
  this.gpoCargaSelected = item;
  this.isEdit = true;
}

//### Funcion computed para formatear los datos de la tabla ###
function computedGrupoCargaList() {
  if (this.grupoItems.length > 0) {
    return this.grupoItems.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}
export default {
  name: 'grupoCarga',
  data,
  components: {
    modalGpoCarga,
    modalUnitMeasure,
  },
  mixins: [GeneralMixin],
  methods: {
    toggleShowModalCreateGpo,
    updateShowModalCreate,
    updateShowModalUnitsMeasure,
    showModalUnits,
    mountedGrupoCarga,
    toggleShowModalEditGpo,
  },
  mounted: mountedGrupoCarga,
  computed: {
    computedGrupoCargaList,
    fields,
  },
};
</script>
<style lang="scss"></style>
