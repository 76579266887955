<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="pa-3"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CCol sm="12" class="d-flex justify-content-end">
        <CButton 
          square 
          color="add" 
          size="sm" 
          @click="showCollpaseCreateEmbalaje"
            v-c-tooltip="{
              content:  $t('label.packaging'),
              placement: 'top-end',
            }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
      <CCol sm="12">
        <CRow class="d-flex justify-content-center">
          <CCol sm="12" lg="7">
            <CSelect
              :label="$t('label.cargoState')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              :options="computedBulkCargoSelect"
              :value.sync="TpCargoId"
              @change="changeSelectedBulkCargoSelect($event)"
              :is-valid="hasError($v.TpCargoId)"
              :invalid-feedback="errorMessage($v.TpCargoId)"

              v-model.trim="$v.TpCargoId.$model"
            />
          </CCol>

          <CCol sm="12" lg="7" v-if="editModal">
            <CSelect
              :label="$t('label.status')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              v-uppercase
              :options="statusOptions"
              :invalid-feedback="errorMessage($v.Status)"
              :value.sync="Status"
              @change="selectedStatus($event)"
              :is-valid="statusSelectColor"
              v-model.trim="$v.Status.$model"
            />
          </CCol>
            <CCol sm="12" lg="9">
              <collpaseEmbalaje 
                :collapse.sync="collapse"
                :packagingEdit="packagingEdit"
                @close-collapse="closeCollapse" 
                @refresh-packaging="mountedPackagingList"
              />
            </CCol>
        </CRow>
      </CCol>
      
      <CCol v-if="!collapse">
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="computedPackagingList"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
          
        >
          <template #loading>
            <loading/>
          </template>
          <template #checked="{ item }">
            <td class="center-cell">
              <CInputCheckbox
                custom
                class="checkbook-filter"
                :checked="item.initialActive"
                @update:checked="updatedItemPackagingChecked($event, item)"
              />
            </td>
          </template>
          <template #checked-filter>
            <td class="center-cell" style="border-top:none">
              <CInputCheckbox
                :label="$t('label.all')"
                class="checkbook-filter"
                custom
                @update:checked="selectAllChecked($event)"
                :checked="selectAll"
              />
            </td>
          </template>
          <template #options="{ item }">
            <td class="center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.packaging'),
                  placement: 'top-end',
                }"
                @click="showCollpaseEditEmbalaje(item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
        <p v-if="!isEdit && itemPackagingChecked.length === 0" class="mt-2 text-danger required">
          {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')+' 1 '+$t('label.type')+' '+$t('label.of')+' '+$t('label.packaging')}}
        </p>
      </CCol>
      
      <template #footer>
        <CButton  outline color="add" @click.stop="isEdit?statusConfirmation(true,Status,submit):submit()" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import { required, maxLength, helpers, alpha } from 'vuelidate/lib/validators';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import ImdgMixin from '@/_mixins/imdg';
import CargaGranel from '@/_mixins/carga-granel';
import {ModalCargaGranelMixin} from '@/_mixins/carga-granel';
import collpaseEmbalaje from '@/pages/embalaje/embalaje-collapse.vue'

function data() {
  return {
    isSubmit: false,
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    itemsPackagingList: [],
    itemsBulkCargo: [],
    itemPackagingChecked: [],
    itemPackagingCheckedUpdated: [],
    itemsBuilkCargosPackagingUpdated: [],
    
    isEdit: false,
    TpCargoId: '',
    selectAll: false,
    resetSelectAll: false,
    Status: null,
    collapse: false,
    packagingEdit: {},
    packagingSelected: '',
  };
}
function fields() {
  return [
      { label: '#', key: 'Nro', filter:false, _style: 'width:3%; text-align:center', },
      { key: 'PackagingName', label: this.$t('label.packaging'),  _style:'width:40%;' },
      {
        label: '',
        key: 'checked',
        sorter: false, filter: false,
        _classes:"center-cell",
         _style: 'width: 15%;text-align:center;'
      },
      { key: 'options', label: '', sorter: false, filter: false, _style: 'min-width: 50px; width:5%',  },
    ];
}

function showCollpaseCreateEmbalaje(){
  this.collapse = !this.collapse
}

function showCollpaseEditEmbalaje(item){
  if(item.PackagingId !== this.packagingSelected){
    this.collapse = true
    this.packagingEdit = item;
    this.packagingSelected = item.PackagingId
  }else {
    this.collapse = false;
    this.packagingSelected = '';
  }
}

function closeCollapse(){
  this.collapse = !this.collapse;
}

//### Funcion mounted para cargar los datos de la tabla y el select para bulkCargos y checked packaging ###
async function mountedPackagingList() {
  this.selectAll = false;
  this.resetSelectAll = false;
  this.collapse = false;
  this.packagingSelected = '';
  await this.mountedBulkCargo();
  if(this.TpCargoId != ''){
    await this.getBulkCargoPackagingByTpCargo(this.TpCargoId);
  }
  else{
    this.resetSelectAll = true;
  }
  
  //this.loadingOverlay = true;

  this.loadingTable = true;
  await this.$http
    .get('Packaging-list?Filter=ACTIVO')
    .then((response) => (this.itemsPackagingList = response.data.data))
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.notifyError({ text: e });
    });
}

function selectedStatus(event) {
  this.Status = event.target.value;
  if(this.isEdit){
    this.itemPackagingCheckedUpdated.map((element) => {
      element.Status = this.Status;
    })
  }
}

//### Funcion computed para formatear los datos de la tabla ###
function computedPackagingList() {
  if (this.itemsPackagingList.length > 0) {
    return this.itemsPackagingList.map((item) => {
      if (!this.selectAll && !this.resetSelectAll) {
        const itemPackagin = this.itemPackagingChecked.filter(
          (element) => item.PackagingId === element.PackagingId
        );
        return this.formatedDateItem(item, itemPackagin.length > 0);
      }
      if (this.selectAll && !this.resetSelectAll) {
        const temporalItem = {
          TpCargoId: this.TpCargoId,
          PackagingId: item.PackagingId,
        };
        const BuilkCargoPackage =  this.itemsBuilkCargosPackagingUpdated.filter((element) => element.PackagingId === item.PackagingId);
        const temporalItemUpdated = {
          TpCargoId: this.TpCargoId,
          PackagingId: item.PackagingId,
          Status: this.Status,
          TpCargoPackagingId: BuilkCargoPackage.length > 0 ? BuilkCargoPackage.TpCargoPackagingId : ''
        }
        this.itemPackagingChecked.push(temporalItem);
        this.itemPackagingCheckedUpdated.push(temporalItemUpdated)
        return this.formatedDateItem(item, true);
      }
      if (!this.selectAll && this.resetSelectAll) {
        return this.formatedDateItem(item, false);
      }
    });
  }
}

//#### Funcion para seleccionar todos los embalajes
function selectAllChecked(event) {
  this.itemPackagingChecked = [];
  this.itemPackagingCheckedUpdated = [];
  if (event === true) {
    this.selectAll = true;
    this.resetSelectAll = false;
  } else {
    this.selectAll = false;
    this.resetSelectAll = true;
  }
}

//Funcion para bulkCargos del select
async function mountedBulkCargo() {
  this.loadingOverlay = true;
  await this.$http
    .get('BulkCargo-list?Filter=ACTIVO')
    .then((response) => {
      this.itemsBulkCargo = response.data.data;
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

//### Funcion para mostrar los embalajes seleccionados por bulkCargo
async function getBulkCargoPackagingByTpCargo(id) {
  this.loadingOverlay = true;
  this.itemPackagingChecked = [];
  this.itemPackagingCheckedUpdated = [];
  await this.$http
    .get(`BulkCargoPackaging-list?TpCargoId=${id}`)
    .then((response) => {
      if (response.data.data.length > 0) {
        this.itemsBuilkCargosPackagingUpdated = response.data.data;
        response.data.data.map((item) => {
          if (item.Status === 'ACTIVO') {
            this.Status = 1
            const temporalItem = {
              TpCargoId: this.TpCargoId,
              PackagingId: item.PackagingId,
            };
            const temporalItemUpdated = {
              TpCargoId: this.TpCargoId,
              PackagingId: item.PackagingId,
              Status: this.Status,
              TpCargoPackagingId: item.TpCargoPackagingId
            };
            this.itemPackagingChecked.push(temporalItem);
            this.itemPackagingCheckedUpdated.push(temporalItemUpdated)
          }else{
            if(this.Status === null){
              this.Status = 0
            }
            if(item.TpCargoPackagingId !== null && item.TpCargoPackagingId !== ''){
              const temporalItemUpdated = {
                TpCargoId: this.TpCargoId,
                PackagingId: item.PackagingId,
                Status: 0,
                TpCargoPackagingId: item.TpCargoPackagingId
              };
              this.itemPackagingCheckedUpdated.push(temporalItemUpdated)
            }
          }
        });
      }
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

async function submit() {
  try{
    this.isSubmit = true;
    this.$v.$touch();
    this.loadingOverlay = true;
    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(!this.isEdit && this.itemPackagingChecked.length === 0){
      this.loadingOverlay = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(this.isEdit === true && this.itemPackagingCheckedUpdated.length === 0){
      this.loadingOverlay = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpCargoPackagingJson = this.isEdit? this.itemPackagingCheckedUpdated:this.itemPackagingChecked

    let metodo = this.isEdit? 'PUT':'POST';
    let ruta = this.isEdit? 'BulkCargoPackaging-update':'BulkCargoPackaging-insert';
    await this.$http
      .ejecutar( metodo, ruta, TpCargoPackagingJson, { root: 'TpCargoPackagingJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.isSubmit = false;
          this.loadingOverlay = false;
          this.closeModal(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        this.isSubmit = false;
        this.loadingOverlay = false;
        this.notifyError({ text: e });
      });
  }catch (e){
    this.isSubmit = false;
    this.loadingOverlay = false;
    this.notifyError({ text: e });
  }
}


//### Cambia el valor actual del BulkCargo
async function changeSelectedBulkCargoSelect(event) {
  this.selectAll = false;
  this.resetSelectAll = false;
  this.TpCargoId = event.target.value;
  if(this.TpCargoId != ''){
    await this.getBulkCargoPackagingByTpCargo(this.TpCargoId);
  }
  else{
    this.resetSelectAll = true;
  }
}


//### funcion para el valor de los checked en los packagin seleccionados
async function updatedItemPackagingChecked(event, item) {
  const BuilkCargoPackage =  this.itemsBuilkCargosPackagingUpdated.filter((element) => element.PackagingId === item.PackagingId);

  this.selectAll = false;
  this.resetSelectAll = false;
  const PackagingCheckedTemporal = {
    TpCargoId: this.TpCargoId,
    PackagingId: item.PackagingId,
  };
  const PackagingCheckedTemporalUpdated = {
    TpCargoId: this.TpCargoId,
    PackagingId: item.PackagingId,
    Status: this.Status,
    TpCargoPackagingId: BuilkCargoPackage.length > 0 ? BuilkCargoPackage[0].TpCargoPackagingId : ' '
  };
  if (event === true) {
    this.itemPackagingChecked.push(PackagingCheckedTemporal);
    if(this.isEdit){
      const j = await this.arrayObjectIndexOf(this.itemPackagingCheckedUpdated, item.PackagingId, 'PackagingId' )
      if (j !== -1) {
        this.itemPackagingCheckedUpdated[j].Status = 1;
      }else {
        this.itemPackagingCheckedUpdated.push(PackagingCheckedTemporalUpdated);
      }
    }
  } if (event === false) {
    const i = await this.arrayObjectIndexOf(this.itemPackagingChecked, item.PackagingId, 'PackagingId' )
    const j = await this.arrayObjectIndexOf(this.itemPackagingCheckedUpdated, item.PackagingId, 'PackagingId' )
    if (i !== -1) {
      this.itemPackagingChecked.splice(i, 1);
    }
    if(this.isEdit){
      if (j !== -1) {
        this.itemPackagingCheckedUpdated[j].Status = 0;
      }
    }
      
  }
}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.resetForm();
  this.$emit('updated-modal', event);
}
function resetForm(){
  this.collapse = false,
  this.selectAll = false,
  this.resetSelectAll = false,
  this.isEdit = false;
  this.TpCargoId = '';
  this.Status = 0;
  this.$v.$reset();
}

function statusSelectColor() {
    return this.Status == 1;
}

export default {
  name: 'modalEmbalaje',
  data,
  props: {
    editModal: Boolean,
    title: String,
    modal: null,
    idBulkCargoSelected: String,
  },
  mixins: [
    ImdgMixin,
    GeneralMixin, 
    CargaGranel,
    ModalCargaGranelMixin,
    ModalMixin,
  ],
  directives: UpperCase,
  validations: {
    TpCargoId: { required },
    Status: {required},
  },
  methods: {
    statusSelectColor,
    closeModal, //cierra modal
    mountedBulkCargo, // carga el select de los bulkcargo
    getBulkCargoPackagingByTpCargo, 
    changeSelectedBulkCargoSelect,
    mountedPackagingList,
    updatedItemPackagingChecked,
    selectAllChecked,
    selectedStatus,
    showCollpaseCreateEmbalaje,
    showCollpaseEditEmbalaje,
    closeCollapse,
    submit,
    resetForm,
  },
  computed: {
    computedPackagingList,
    fields,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
      if(val === true){
        this.mountedPackagingList();
      } else {
        this.resetForm();
      }
    },
    idBulkCargoSelected: function(val){
      this.isEdit = true;
      this.TpCargoId = val
    },
  },
  components: {
    collpaseEmbalaje
  }
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
