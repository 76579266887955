import {
    required,
} from 'vuelidate/lib/validators';

export default () => {
    return {
      actividadPortuaria: {
        PortActivityId: { required},
        tpOperationId: { required},
      }
    }
  }