 <template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-square-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
      </symbol>
      <symbol id="exclamation-square-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
      </symbol>
    </svg>
    <CModalExtended
      :title= titulo
      color="dark"
      size="xl"
      :close-on-backdrop="false"
      @update:show="toggle(false)"
      :show.sync="modalActive"
    >
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
      <CRow>
        <div class="col-sm-12 col-lg-11">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="6" class="center-field">
              <div class="form-group form-row" rol="group">
                <label class="col-form-label-sm text-right required col-sm-12 col-lg-4 mb-0 requiered">
                  {{$t('label.dateFrom')}}
                </label>
                <div class="col-sm-12 col-lg-8 input-group">
                  <vue-datepicker
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="FormDelay.dateBegin"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                    @input="startChronometrer()"
                  >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          addInputClasses="pr-0"
                          class="w-100 float-left mb-0"
                          v-uppercase
                          placeholder="DD/MM/YYYY HH:mm"
                          :is-valid="hasError($v.FormDelay.dateBegin)"
                          v-model.trim="$v.FormDelay.dateBegin.$model"
                          @keypress="rightDate($event)"
                          @blur="$v.FormDelay.dateBegin.$touch()"
                          @input="startChronometrer()"
                          size="sm" 
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6" class="center-field">
              <div class="form-group form-row" rol="group">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0">
                  {{$t('label.dateTo')}}
                </label>
                <div class="col-sm-12 col-lg-8 input-group">
                  <vue-datepicker
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="FormDelay.dateFinish"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                    @input="startChronometrer()"
                  >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          addInputClasses="pr-0"
                          class="w-100 float-left mb-0"
                          v-uppercase
                          placeholder="DD/MM/YYYY HH:mm"
                          :is-valid="hasError($v.FormDelay.dateFinish)"
                          v-model.trim="$v.FormDelay.dateFinish.$model"
                          @keypress="rightDate($event)"
                          @blur="$v.FormDelay.dateFinish.$touch()"
                          @input="startChronometrer()"
                          size="sm" 
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
              </div>
            </CCol>
          </CRow> 
        </div>
      </CRow>
      <CRow>
        <div class="col-sm-12 col-lg-11">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-sm-12 col-lg-4 mb-0 col-form-label-sm text-right">
                  {{ $t('label.MultipleTime') }}
                </label>
                <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                  <CSwitch
                    color="watch"
                    variant="3d"
                    type="checkbox"
                    :disabled="editModal"
                    :checked.sync="fgMultipleTime"
                    @update:checked="(delayCustom = '')"
                  />
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="required col-sm-12 col-lg-4 mb-0 col-form-label-sm text-right">
                  {{ $t('label.timeCode') }}
                </label>
                <div class="input-group col-sm-12 col-lg-8">
                  <v-select 
                    id="v-select-small"
                    style="width: 100%;"
                    :options="delayCustomOptions"
                    label="label"
                    :placeholder="$t('label.select')"
                    v-model="delayCustom"
                    :value.sync="delayCustom"
                    :reduce="option => option.value" 
                    :class="computedSelectDelay ? 'select-delay--correct' : (verifySelect ? 'select-delay--error' : '') "
                  />
                  <div class="col-sm-12 col-lg-10 text-invalid-feedback px-0" v-if=" (delayCustom == null || delayCustom == '') && verifySelect">
                    {{$t('label.required')}}
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
        <div class="col-sm-12 col-lg-auto text-right pl-0 mb-3">
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
            @click="clearFilters()"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
        <CCol sm="12" lg="11" class="text-right mb-4" v-if="!Continue">
          <CButton
            color="watch"
            size="sm"
            class="mr-1"
            :disabled="isSubmit"
            @click="craneList()"
          >
            <CIcon name="continue" />&nbsp; {{$t('label.Continue')}}
          </CButton>
        </CCol>
      </CRow>
      <CCollapse :show="Continue" :duration="collapseDuration">
        <CRow>
          <div class="col-sm-12 col-lg-11">
            <CRow>
              <CCol sm="12" lg="6" class="center-field" v-if="!fgMultipleTime">
                <CSelect
                  :label="$t('label.crane')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right required', input: 'col-sm-12 col-lg-8'}"
                  :options="craneOptions"
                  v-model.trim="$v.FormDelay.VisitCraneId.$model"
                  :value.sync="FormDelay.VisitCraneId"
                  :is-valid="hasError($v.FormDelay.VisitCraneId)"
                  :invalid-feedback="errorMessage($v.FormDelay.VisitCraneId)"                         
                  size="sm"
                  @change="ListHold($event)"
                />
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="fgHold&&!fgMultipleTime">
                <CSelect
                  :label="$t('label.hold')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right required', input: 'col-sm-12 col-lg-8'}"
                  :options="holdOptions"
                  v-model.trim="FormDelay.vesselHoldId"
                  :is-valid="hasError($v.FormDelay.vesselHoldId)"
                  :invalid-feedback="errorMessage($v.FormDelay.vesselHoldId)"                         
                  @blur="$v.FormDelay.vesselHoldId.$touch()"  
                  @change="changeHatch($event)"  
                  size="sm"
                />
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="fgHatch&&!fgMultipleTime">
                <CSelect
                  :label="$t('label.hatch')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right required', input: 'col-sm-12 col-lg-8'}"
                  :options="hatchOptions"
                  v-model.trim="FormDelay.vesselHoldHatchCId"
                  :value.sync="FormDelay.vesselHoldHatchCId"
                  :is-valid="hasError($v.FormDelay.vesselHoldHatchCId)"
                  :invalid-feedback="errorMessage($v.FormDelay.vesselHoldHatchCId)"                         
                  @blur="$v.FormDelay.vesselHoldHatchCId.$touch()"  
                  size="sm"
                  :disabled="FormDelay.vesselHoldId == ''"   
                />
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="fgMachine">
                <CSelect
                  :label="$t('label.machine')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right required', input: 'col-sm-12 col-lg-8'}"
                  :options="machineOptions"
                  v-model.trim="FormDelay.machineId"
                  :value.sync="FormDelay.machineId"
                  :is-valid="hasError($v.FormDelay.machineId)"
                  :invalid-feedback="errorMessage($v.FormDelay.machineId)"                    
                  @blur="$v.FormDelay.machineId.$touch()"   
                  size="sm" 
                />
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="fgTwinBox || fgQuantity">
                <CInput
                  :label="$t('label.twinsBox')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right required', input: 'col-sm-12 col-lg-8'}"
                  v-model="$v.FormDelay.quantity.$model"
                  @blur="$v.FormDelay.quantity.$touch()"
                  :invalid-feedback="errorMessage($v.FormDelay.quantity)"
                  :is-valid="hasError($v.FormDelay.quantity)" 
                  ref="weightInput"
                  v-money="measure"
                  maxlength="3"
                  size="sm"   
                >
                </CInput>
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="editModal">
                <CSelect
                  :label="$t('label.status')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-8'}"
                  addLabelClasses="text-right required"
                  v-model="FormDelay.status"
                  :value.sync="FormDelay.status"
                  :is-valid="statusSelectColor"
                  :options="selectOptions"
                  size="sm"   
                />
              </CCol>
              <CCol sm="12" lg="6" class="center-field" v-if="FgObservation">
                <CTextarea
                  :label="$t('label.observation')"
                  :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-8'}"
                  addLabelClasses="text-right required"
                  v-uppercase
                  v-model="$v.FormDelay.observation.$model"
                  :is-valid="hasError($v.FormDelay.observation)"
                  :invalid-feedback="errorMessage($v.FormDelay.observation)"                         
                  @blur="$v.FormDelay.observation.$touch()"  
                  :plaintext="false"
                  :readonly="false"
                  :lazy="(true,500)"
                  size="sm"   
                >
                </CTextarea>
              </CCol>
            </CRow>  
          </div>
        </CRow>
        <CRow>
          <div class="col-sm-12 col-lg-11">
            <CRow class="justify-content-center">
              <CCol sm="12" lg="6" v-if="fgMultipleTime">
                <CRow class="justify-content-end">
                  <CCol sm="12" lg="10">
                    <dataTableExtended
                      class="align-center-row-datatable"
                      size="lg"
                      :fields="fields"
                      :items="computedCraneList"
                      :items-per-page="10"
                      pagination
                      :noItemsView="tableText.noItemsViewText"
                    >
                      <template #Checked="{ item, index }">
                        <td>
                          <CInputCheckbox
                            custom
                            :checked="item.Checked"
                            class="checkbook-filter ml-1"
                            @update:checked="SelectedCrane(item, index, $event)"
                          />
                        </td>
                      </template>
                      <template #show_details="{item, index}">
                        <td class="centen-cell">
                          <CButton
                            color="watch"
                            square
                            v-c-tooltip="{
                            content: $t('label.toView')+' '+$t('label.hold'),
                              placement: 'top-end',
                            }"
                            size="sm"
                            @click="HoldList(item, index, $event)" 
                            v-if="item.HoldJson.length > 0"
                          >
                            <CIcon name="eye" />
                          </CButton>
                        </td>
                      </template>
                      <template #details="{item}">
                        <CCollapse
                          :show="item._toggled"
                          :duration="collapseDuration"
                        >
                          <div class="py-2 px-3">
                            <!-- Datatable sudmodule -->
                            <dataTableExtended
                              class="align-center-row-datatable"
                              :items="computedHoldList(item.HoldJson, item.VisitCraneId)"
                              :fields="fields2"
                              :items-per-page="5"
                              :noItemsView="tableText.noItemsViewText"                                    
                            >
                              <template #Checked="{item}">
                                <td>
                                  <CInputCheckbox
                                    custom
                                    :checked="item.Checked"
                                    class="checkbook-filter ml-1"
                                    @update:checked="addRemoveHold(item, item.VisitCraneId)"
                                  />
                                </td>
                              </template>
                            </dataTableExtended>
                          </div>
                        </CCollapse>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="10" lg="6" style="align-items: center;display: flex;justify-content: center;">
                <div class="center-field text-center">
                  <div>
                    <CRow>
                      <h3 class="timer-delay">{{dataChronometer(hour)}}:{{dataChronometer(min)}}:{{dataChronometer(sec)}}</h3>
                      <div class="ml-4 mt-3">
                        <CButton
                          outline
                          :color="checkColorChronometer"
                          @click="startChronometerClick()"
                          class="d-flex align-items-center"
                        >
                          <CIcon name="on" />&nbsp; {{timer !== null ? "OFF" :"ON" }}
                        </CButton>
                      </div>
                    </CRow>
                    <CCol sm="12" lg="12">
                      <p v-if="(FormDelay.dateFinish == '') && (FormDelay.dateBegin != '')" 
                        class="mt-2 text-danger text-center">
                        * {{$t('label.currentTime')}}
                      </p>
                    </CCol>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </CRow>
      </CCollapse>
      <template #footer>
        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="changeStatus()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template> 
    </CModalExtended>       
  </div>       
</template>
<script>
  import UpperCase from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import mixinServicio from '@/_mixins/servicio';
  import { FormDelay } from '@/_validations/visitas/delayValidations';
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import { VMoney, Money } from "v-money";
  import moment from 'moment';

//Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false, 
      loadingOverlay: false,
      openDateStart: false,
      openDateEnd: false,
      fgHatch: false, 
      fgMachine: false, 
      fgHold: false, 
      fgTwinBox: false, 
      fgQuantity: false, 
      fgCrane: false,
      FgObservation: false,
      isClickedButton: false,
      fgMultipleTime: false,
      StartService: false,
      verifySelect: false,
      Continue: false,

      dataCrane: [],
      dataMachine: [],
      dataDelayCustom: [],
      dataHatch: [],
      dataHold: [],
      CraneEventDelay: [],

      previousDate: new Date(), 
      laterDate: new Date(),    

      ValidPreviousDate: '',
      ValidLaterDate: '',
      delayCustom:'',
      FormDelay: {
        visit: '',
        VisitCraneEventGpoId: '',
        visitCraneDetailId: '',
        delayCustomId: '',
        VisitCraneId: '',
        machineId: '',
        dateBegin: '',
        dateFinish: '',
        vesselHoldId: '',
        vesselHoldHatchCId: '',      
        observation: '',
        quantity: '',
        status: 1,
        FgActStatus: 1,
      },
      measure: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
        maxlength: 3,
      },

      sec: 0,
      min: 0,
      hour: 0,
      timer: null,
      intervalList: [],
      collapseDuration: 300,

      fgOpen : true,
    }
  }

//methods
  async function HoldList(item, index, event) {
    if (event) {
      this.dataCrane[index].toggled = true;
    }else{
      this.dataCrane[index].toggled = false;
    };
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }

  function craneList() {
    try {
      this.isSubmit = true;
      this.loadingOverlay = true;
      this.$v.FormDelay.dateBegin.$touch();
      this.$v.FormDelay.dateFinish.$touch();
      this.$v.delayCustom.$touch();
      this.verifySelect = true;
      if (this.$v.FormDelay.dateBegin.$error||this.$v.FormDelay.dateFinish.$error||this.$v.delayCustom.$error) {
        let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.FormDelay.dateBegin != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        if(this.FormDelay.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }  
        }
        if(this.FormDelay.dateBegin != '' && this.FormDelay.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
        if (this.FormDelay.dateBegin == '') {
          throw this.$t('label.TheDateFromIsRequired');
        }
        throw this.$t('label.TimeIsRequired');
      }
      let VisitCraneJson = !this.editModal ? {
        VisitId: this.visit,
        DelayCustomId: this.FormDelay.delayCustomId,
        TransactionBegin: this.FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) : '',
        TransactionFinish: this.FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish) : '',
      }:{
        VisitId: this.visit,
        DelayCustomId: this.FormDelay.delayCustomId,
        TransactionBegin: this.FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) : '',
        TransactionFinish: this.FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish) : '',
        VisitCraneEventGpoId: this.FormDelay.VisitCraneEventGpoId,
        VisitCraneDetailId: this.FormDelay.VisitCraneEventGpoId ? '' : this.FormDelay.visitCraneDetailId,
      }
      this.$http.post("VisitCraneDelayApply-list", VisitCraneJson, { root: 'VisitCraneJson' })
      .then(response => {
        let CraneList = response.data.data
        if (Object.entries(CraneList[0]) ? Object.entries(CraneList[0])?.length > 1 : false) {
          this.dataCrane = CraneList;
          if (this.editModal) {
            if (this.fgMultipleTime) {
              this.FormDelay.VisitCraneId = [];
              if (this.fgHold) {
                this.FormDelay.vesselHoldId = [];
                this.CraneEventDelay.map(CraneEvent =>{
                  CraneList.map(Crane =>{
                    let Hold = Crane?.HoldJson.find(Hold =>Hold.VesselHoldId==CraneEvent.VesselHoldId&&
                      Crane.VisitCraneId==CraneEvent.VisitCraneId)
                    if (Hold) {
                        this.addRemoveHold(Hold, Crane.VisitCraneId);
                    }
                  })
                })
              }else{
                CraneList.map(item => {
                  if (item.FgActive) {
                    this.FormDelay.VisitCraneId.push(item);
                  }
                })
              }
            } else {
              let CraneData = CraneList.find(item => item.FgActive == true);
              this.FormDelay.VisitCraneId = CraneData?.VisitCraneId ? CraneData.VisitCraneId : '';
            }
          }else{
            this.FormDelay.VisitCraneId = [];
          }
        }else{
          this.dataCrane = [];
        }
        this.collapseDuration = 300;
        this.Continue = true;
      }).catch( err => {
        this.notifyError({text: err});
        this.loadingOverlay = false;
      }).then(() => {
        this.isSubmit = false;
        this.loadingOverlay = false;
      });
    } catch (error) {
      this.isSubmit = false;
      this.loadingOverlay = false;
      this.notifyError({text: error});
    }
  }

  async function delayCustomList() {
    this.loadingOverlay = true;
    this.$http.get("VisitCodeCustom-list", { TpVesselId: this.itinerarySelected.TpVesselId})
    .then(response => {
      this.dataDelayCustom = response.data.data;
      if(this.editModal){
        this.collapseDuration = 0;
        this.Continue = true;
        this.setData(this.itemDelay);
      }else{
        this.loadingOverlay = false;
      }
    }).catch( err => {
      this.notifyError({text: err});
      this.loadingOverlay = false;
    })
  }

  function changeHatch(event){
    this.FormDelay.vesselHoldId = event.target.value;
    this.FormDelay.vesselHoldHatchCId = '';
    if(event.target.value !== ''&&this.fgHatch){
      let DataHold = this.dataHold.find(item => item.VesselHoldId == this.FormDelay.vesselHoldId)
      this.dataHatch = DataHold ? DataHold.HoldHatchCoverJson : [];
    }else{
      this.dataHatch = [];
    }
  }

  function functionDateStart(event) {
    if (event) {
      this.openDateStart = event;
    }
  }

  function functionDateEnd(event) {
    if (event) {
      this.openDateEnd = event;
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function getDateRange(){
    let currentDate = new Date();
    if(this.itinerarySelected.Ata!=undefined){ 
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    }

    if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
    }

    if(this.itinerarySelected.Atd!=undefined){  
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
    }

    if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
    }
  }  

  function toggle(newVal) {
    if(!newVal){
      this.playChronometer(false);
      this.playingChronometer(false);
      this.resetForm();
    }

    this.modalActive = newVal;
  }

  function resetForm() {
    this.dataCrane = [];
    this.dataMachine = [];
    this.dataDelayCustom = [];
    this.CraneEventDelay = [];
    this.fgHatch = false;
    this.fgMachine = false;
    this.fgHold = false;
    this.fgTwinBox = false;
    this.verifySelect = false;
    this.fgOpen = true;
    this.fgCrane = false;
    this.FgObservation = false;
    this.fgQuantity = false;
    this.openDateStart = false;
    this.openDateEnd = false;
    this.isClickedButton = false;
    this.fgMultipleTime = false;
    this.StartService = false;
    this.Continue = false;
    this.FormDelay.visitCraneDetailId = '';
    this.delayCustom = '';
    this.ValidPreviousDate = '';
    this.ValidLaterDate = '';
    this.FormDelay.visit = '';
    this.FormDelay.VisitCraneEventGpoId = '';
    this.FormDelay.VisitCraneId = '';
    this.FormDelay.machineId = '';
    this.FormDelay.vesselHoldHatchCId = '';
    this.FormDelay.vesselHoldId = '';
    this.FormDelay.delayCustomId = '';
    this.FormDelay.observation = '';
    this.FormDelay.dateFinish = '';
    this.FormDelay.dateBegin = '';
    this.FormDelay.quantity = 0;
    this.FormDelay.status = 1;
    this.FormDelay.FgActStatus = 1;
    this.sec = 0;
    this.min = 0;
    this.hour = 0;
    this.timer = null; 
    this.intervalList = [];
    this.$v.$reset();
  }

  async function submit(){
    try {
      this.isSubmit = true;
      this.loadingOverlay = true;
      this.verifySelect = true;
      let HoldPerCrane = this.fgMultipleTime&&this.fgHold ? await this.ValidateHold(this.FormDelay) : false;
      this.$v.$touch();
      if (this.$v.$error||HoldPerCrane) {
        let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
        }
        if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
          || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.FormDelay.dateBegin != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        if(this.FormDelay.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }  
        }
        if(this.FormDelay.dateBegin != '' && this.FormDelay.dateFinish != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
        if (HoldPerCrane) {
          throw this.$t('label.YouMustHaveAtLeastOneHoldPerCrane');
        }
        throw this.$t('label.errorsPleaseCheck');
      }

      let metodo = !this.editModal ? 'POST' : this.fgMultipleTime ? 'PUT' : 'POST';
      let ruta = !this.fgMultipleTime ? 'VisitDelay-insert' :
        this.editModal ? 'VisitDelayMultiple-update' : 'VisitDelayMultiple-insert';
      this.FormDelay.visit = this.visit;

      let VisitDelayJson = !this.fgMultipleTime ? await SimpleFormat(this.editModal, this.FormDelay) : 
        this.fgHold ? await MulipleHoldFormat(this.editModal, this.FormDelay, this.CraneEventDelay) : await MulipleFormat(this.editModal, this.FormDelay, this.CraneEventDelay);

      this.$http.ejecutar(metodo, ruta, VisitDelayJson, { root: 'VisitDelayJson' })
        .then(response => {
          if (this.fgMultipleTime) {
            if (this.editModal) {
              if (response.data.data[0].StatusItem==200 || response.data.data[0].StatusItem==null) {
                this.NotifyMultipleCrane(response.data.data[0]);
              } else {
                let _lang = this.$i18n.locale;
                let ErrorJson = response.data.data[0].ResponseJson.map(item =>{
                  return _lang=='en' ? item.ErrorMessageEn : item.ErrorMessageEs;
                });
                this.notifyError({text: ErrorJson.join(',')});
                this.isSubmit = false;
                this.loadingOverlay = false;
              }
            } else{
              this.NotifySimpleCrane(response.data.data);
            }
          }else{
            this.notifySuccess({text: response.data.data[0].Response})
            this.$emit('submited');
            this.toggle(false);
            this.isSubmit = false;
            this.loadingOverlay = false;
          }
        }).catch(err => {
          this.notifyError({text: err });
          this.isSubmit = false;
          this.loadingOverlay = false;
        }) 
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.notifyError({ text: e });
    }
  }

  function SimpleFormat(editModal, FormDelay) {
    return !editModal? {
      VisitCraneId: FormDelay.VisitCraneId,
      VisitId: FormDelay.visit,
      DelayCustomId: FormDelay.delayCustomId,
      VesselHoldId: FormDelay.vesselHoldId,
      MachineId: FormDelay.machineId,
      VesselHoldHatchCId: FormDelay.vesselHoldHatchCId,
      TransactionDate: '',
      TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
      TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
      Quantity: FormDelay.quantity,
      Observation: FormDelay.observation,
    }:{
      VisitCraneDetailId: FormDelay.visitCraneDetailId,
      VisitCraneId: FormDelay.VisitCraneId,
      VisitId: FormDelay.visit,
      DelayCustomId: FormDelay.delayCustomId,
      VesselHoldId: FormDelay.vesselHoldId,
      MachineId: FormDelay.machineId,
      VesselHoldHatchCId: FormDelay.vesselHoldHatchCId,
      TransactionDate: '',
      TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
      TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
      Quantity: FormDelay.quantity,
      Observation: FormDelay.observation,
      Status: FormDelay.status==1 ? true : false,
    }
  }

  function MulipleFormat(editModal, FormDelay, CraneEventDelay) {
    let TotalGrane = [];
    let CraneJson = [];
    if (editModal) {
      TotalGrane = [...CraneEventDelay, ...FormDelay.VisitCraneId];
      TotalGrane.map(item =>{
        if (!CraneJson.some(Crane => Crane.VisitCraneId == item.VisitCraneId )) {
          CraneJson.push(item);
        }
      })
    }
    return !editModal? FormDelay.VisitCraneId.map(item =>{
      return {
        VisitCraneId: item.VisitCraneId,
        VisitId: FormDelay.visit,
        DelayCustomId: FormDelay.delayCustomId,
        VesselHoldId: FormDelay.vesselHoldId,
        MachineId: FormDelay.machineId,
        TransactionDate: '',
        TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
        TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
        Quantity: FormDelay.quantity,
        Observation: FormDelay.observation,
      }
    }):{
      VisitId: FormDelay.visit,
      VisitCraneEventGpoId: FormDelay.VisitCraneEventGpoId,
      TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
      TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
      Observation: FormDelay.observation,
      DelayCustomId: FormDelay.delayCustomId,
      VesselHoldId: FormDelay.vesselHoldId,
      MachineId: FormDelay.machineId,
      TransactionDate: '',
      Quantity: FormDelay.quantity,
      Status: FormDelay.status==1 ? true : false,
      VisitCraneDetailJson: CraneJson.map(item =>{
        let ValidatePreviousCrane = CraneEventDelay.find(VisitCraneDetailJson => 
          VisitCraneDetailJson.VisitCraneId == item.VisitCraneId
        )
        let ValidateCurrentCrane = FormDelay.VisitCraneId.some(VesselCrane =>
          VesselCrane.VisitCraneId == item.VisitCraneId
        )
        return {
          VisitCraneDetailId: ValidatePreviousCrane ? ValidatePreviousCrane.VisitCraneDetailId : '',
          VisitCraneId: item.VisitCraneId,
          Status: ValidateCurrentCrane ? 1 : 0,
        }
      }),
    }
  }

  function MulipleHoldFormat(editModal, FormDelay, CraneEventDelay) {
    let TotalHold = [];
    let HoldJson = [];
    if (editModal) {
      TotalHold = [...CraneEventDelay, ...FormDelay.vesselHoldId];
      TotalHold.map(item =>{
        if (!HoldJson.some(Hold => Hold.VesselHoldId==item.VesselHoldId&&Hold.VisitCraneId==item.VisitCraneId)) {
          HoldJson.push(item);
        }
      })
    }
    return !editModal ? FormDelay.vesselHoldId.map(item =>{
      return {
        VisitCraneId: item.VisitCraneId,
        VisitId: FormDelay.visit,
        DelayCustomId: FormDelay.delayCustomId,
        VesselHoldId: item.VesselHoldId,
        MachineId: FormDelay.machineId,
        TransactionDate: '',
        TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
        TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
        Quantity: FormDelay.quantity,
        Observation: FormDelay.observation,
      }
    }) : {
      VisitId: FormDelay.visit,
      VisitCraneEventGpoId: FormDelay.VisitCraneEventGpoId,
      TransactionBegin: FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateBegin) : '',
      TransactionFinish: FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(FormDelay.dateFinish) : '',
      Observation: FormDelay.observation,
      DelayCustomId: FormDelay.delayCustomId,
      MachineId: FormDelay.machineId,
      TransactionDate: '',
      Quantity: FormDelay.quantity,
      Status: FormDelay.status==1 ? true : false,
      VisitCraneDetailJson: HoldJson.map(item =>{
        let ValidatePreviousHold = CraneEventDelay.find(CraneEventDelay => 
          CraneEventDelay.VesselHoldId==item.VesselHoldId&&CraneEventDelay.VisitCraneId==item.VisitCraneId
        )
        let ValidateCurrentHold = FormDelay.vesselHoldId.some(VesselHold =>
          VesselHold.VisitCraneId==item.VisitCraneId&&VesselHold.VisitCraneId==item.VisitCraneId
        )
        return {
          VisitCraneDetailId: ValidatePreviousHold ? ValidatePreviousHold.VisitCraneDetailId : '',
          VisitCraneId: item.VisitCraneId,
          VesselHoldId: item.VesselHoldId,
          Status: ValidateCurrentHold ? 1 : 0,
        }
      }),
    }
  }

  function NotifyMultipleCrane(response) {
    if (response.FgActCraneEventGpo) {
      let CraneError = response.VisitCraneDetailJson.some(item => item.StatusItem != 200);
      let CraneResponse = response.VisitCraneDetailJson.map((item) => {
        let CraneHoldName = this.fgHold ? item.CraneAlias+'-'+item.VesselHoldName : '';
        if (this.FormDelay.VisitCraneId.some(Crane => Crane.VisitCraneId == item.VisitCraneId)) {
          if (item.StatusItem == 200) {
            return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #2eb85c!important;" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg>
              <b>${CraneHoldName ? CraneHoldName  : item.CraneAlias}</b> ${CraneError ? '' : this.$t('label.RecordUpdatedSuccessFully')}</div>`;
          }else{
            let _lang = this.$i18n.locale;
            let ErrorJson = item.ResponseJson.map(ResponseJson =>{
              return _lang=='en' ? ResponseJson.ErrorMessageEn : ResponseJson.ErrorMessageEs;
            });
            return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #e55353!important;" class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg>
              <b>${CraneHoldName ? CraneHoldName  : item.CraneAlias}</b> ${ErrorJson.join(',')}</div>`;
          }
        }
      })
      this.$swal.fire({
        title: this.$t('label.lostTime'),
        html: CraneResponse.join(''),
        icon: 'warning',
        padding: '10px',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
        confirmButtonColor: '#42AA91',
        confirmButtonText: 'CONFIRMAR',
      }).then((result) => {
        if (result.isConfirmed) {
          if (CraneError) {
            this.isSubmit = false;
            this.loadingOverlay = false;
          } else {
            this.$emit('submited');
            this.toggle(false);
            this.isSubmit = false;
            this.loadingOverlay = false;
          }
        }
      })
    } else {
      this.notifySuccess({text: response.Response})
      this.$emit('submited');
      this.toggle(false);
      this.isSubmit = false;
      this.loadingOverlay = false;
    }
  }

  function NotifySimpleCrane(response) {
    let CraneError = response.some(item => item.StatusItem != 200);
    let CraneResponse = response.map((item) => {
      let CraneHoldName = this.fgHold ? item.CraneAlias+'-'+item.VesselHoldName : '';
      if (item.StatusItem == 200) {
        return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #2eb85c!important;" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg>
          <b>${CraneHoldName ? CraneHoldName  : item.CraneAlias}</b> ${this.$t('label.RecordUpdatedSuccessFully')}</div>`;
      }else{
        let _lang = this.$i18n.locale;
        let ErrorJson = item.ResponseJson.map(ResponseJson =>{
          return _lang=='en' ? ResponseJson.ErrorMessageEn : ResponseJson.ErrorMessageEs;
        });
        return `<div style="font-size: 14px;" class="m-1 text-left"><svg style="color: #e55353!important;" class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg>
          <b>${CraneHoldName ? CraneHoldName  : item.CraneAlias}</b> ${ErrorJson.join(',')}</div>`;
      }
    })
    this.$swal.fire({
      title: this.$t('label.lostTime'),
      html: CraneResponse.join(''),
      icon: 'warning',
      padding: '10px',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      confirmButtonColor: '#42AA91',
      confirmButtonText: 'CONFIRMAR',
    }).then((result) => {
      if (result.isConfirmed) {
        if (CraneError) {
          this.isSubmit = false;
          this.loadingOverlay = false;
        } else {
          this.$emit('submited');
          this.toggle(false);
          this.isSubmit = false;
          this.loadingOverlay = false;
        }
      }
    })
  }

  function ValidateHold(FormDelay) {
    let validation = false;
    FormDelay.VisitCraneId.map(item =>{
      if (!FormDelay.vesselHoldId.some(VesselHold =>VesselHold.VisitCraneId==item.VisitCraneId)) {
        return validation = true;
      }
    })
    return validation;
  }

  function inputsActive(){
    const delayCustomData = this.dataDelayCustom.find( item => item.DelayCustomId === this.FormDelay.delayCustomId );
    if(delayCustomData != undefined){  
      this.fgHatch = delayCustomData.FgHatch;
      this.fgMachine = delayCustomData.FgMachine;
      this.fgHold = delayCustomData.FgHold;
      this.fgTwinBox = delayCustomData.FgTwinBox;
      this.fgCrane = delayCustomData.FgCrane;
      this.FgObservation = delayCustomData.FgObservation;
      this.fgQuantity = delayCustomData.FgApplyQuantity;
      let requests = [];
      if(this.fgMachine){
        this.loadingOverlay = true;
        this.dataMachine = [];
        requests[0]=(this.$http.ejecutar('GET','VisitMachine-list', { VisitId: this.visit , Filter: 'ACTIVO' }))
        this.$v.FormDelay.machineId.$reset();
      }else{
        this.FormDelay.machineId = "";
      }
      if(this.fgHatch){
        this.FormDelay.vesselHoldHatchCId = '';
        this.dataHatch = [];
        this.$v.FormDelay.vesselHoldHatchCId.$reset();
        if (this.StartService) {
          this.FormDelay.vesselHoldHatchCId = this.itemDelay.VesselHoldHatchCId;
        }
      }else{
        this.FormDelay.vesselHoldHatchCId = '';
      }
      if(this.fgHold){
        this.loadingOverlay = true;
        this.$v.FormDelay.vesselHoldId.$reset();
        this.dataHold = [];
        !this.StartService ? this.fgMultipleTime ? this.FormDelay.vesselHoldId = [] : this.FormDelay.vesselHoldId = '' : '';
        if (this.StartService&&!this.fgMultipleTime) {
          this.FormDelay.vesselHoldId = this.itemDelay.VesselHoldId;
        }
      }else{
        this.fgMultipleTime ? this.FormDelay.vesselHoldId = [] : this.FormDelay.vesselHoldId = '';
      }
      if(this.fgTwinBox){
        this.$v.FormDelay.quantity.$reset();
      }else{
        this.FormDelay.quantity = 0;
      }

      if (this.StartService) {
        let VisitCraneJson = !this.editModal ? {
          VisitId: this.visit,
          TransactionBegin: this.FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) : '',
          TransactionFinish: this.FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish) : '',
        }:{
          VisitId: this.visit,
          DelayCustomId: this.FormDelay.delayCustomId,
          TransactionBegin: this.FormDelay.dateBegin ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) : '',
          TransactionFinish: this.FormDelay.dateFinish ? DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish) : '',
          VisitCraneEventGpoId: this.FormDelay.VisitCraneEventGpoId,
          VisitCraneDetailId: this.FormDelay.VisitCraneEventGpoId ? '' : this.FormDelay.visitCraneDetailId,
        }
        this.fgHatch ? requests[1]=(this.$http.ejecutar("GET", "VisitVesselHoldHatchCover-list?VesselHoldId="+this.FormDelay.vesselHoldId)) : '';
        requests[2]=(this.$http.ejecutar("POST", "VisitCraneDelayApply-list", VisitCraneJson, { root: 'VisitCraneJson' }));
      }

      if (this.StartService||this.fgHold||this.fgMachine) {
        Promise.all(requests)
          .then((request) => {
            this.dataMachine = request[0]?.data?.data ? request[0].data.data : [];
            if (this.StartService) {
              this.dataHatch = request[1]?.data?.data ? request[1].data.data : [];
              let VisitCraneDelayApply = request[2]?.data?.data;
              this.dataCrane=Object.entries(VisitCraneDelayApply[0]).length>1?VisitCraneDelayApply:[];
              if (this.fgMultipleTime&&this.fgHold) {
                this.dataCrane.map((item, index) => {
                  if (this.FormDelay.VisitCraneId.some(Crane => Crane.VisitCraneId == item.VisitCraneId)) {
                    this.dataCrane[index].toggled = true;
                  }
                })
              }else if (this.fgHold) {
                let DataCrane = this.dataCrane.find(item => item.VisitCraneId == this.FormDelay.VisitCraneId )
                this.dataHold = DataCrane ? DataCrane.HoldJson : [];
              }
              this.StartService = false;
              this.$v.$touch();
            }
            this.loadingOverlay = false;
          })
          .catch((err) => {
            this.loadingOverlay = false;
            this.StartService = false;
            this.notifyError({text: err });
          })
      }else{
        this.loadingOverlay = false;
      }
    }else{
      this.fgHatch = false;
      this.fgMachine = false;
      this.fgHold = false;
      this.fgTwinBox = false;
      this.fgCrane = false;
      this.FgObservation = false;
      this.fgQuantity = false;
      this.loadingOverlay = false;
      this.StartService = false;
      this.FormDelay.machineId = "";
      this.fgMultipleTime ? this.FormDelay.vesselHoldId = [] : this.FormDelay.vesselHoldId = '';
      this.FormDelay.vesselHoldHatchCId = '';
      this.FormDelay.quantity = 0;
    } 
  }

  function VisitCraneEventGpo() {
    this.loadingOverlay = true;
    this.collapseDuration = 0;
    this.Continue = true;
    let requests = [
      this.$http.ejecutar("GET","VisitCraneEventDelay-by-VisitCraneEventGpoId?VisitCraneEventGpoId="+this.itemDelay.VisitCraneEventGpoId),
      this.$http.ejecutar("GET", "VisitCodeCustom-list", { TpVesselId: this.itinerarySelected.TpVesselId})
    ]
    Promise.all(requests)
    .then(response => {
      let VisitCraneEventDelay = response[0].data.data;
      this.dataDelayCustom = response[1].data.data;
      if (Object.entries(VisitCraneEventDelay[0]).length>1) {
        this.StartService = true;
        if (VisitCraneEventDelay[0].FgHold) {
          this.FormDelay.VisitCraneId = [];
          this.FormDelay.vesselHoldId = [];
          VisitCraneEventDelay[0].VisitCraneDetailJson.map(item => {
            this.addRemoveHold(item, item.VisitCraneId);
          })
        } else {
          this.FormDelay.VisitCraneId = VisitCraneEventDelay[0].VisitCraneDetailJson;
        }
        this.CraneEventDelay = VisitCraneEventDelay[0].VisitCraneDetailJson;
        this.FormDelay.machineId = this.CraneEventDelay[0]?.MachineId ? this.CraneEventDelay[0].MachineId : '';
        this.fgMultipleTime = true;
        this.FormDelay.visitCraneDetailId = '';
        this.FormDelay.dateBegin = VisitCraneEventDelay[0].TransactionBegin ? DateFormater.formatDateTimeWithSlash(VisitCraneEventDelay[0].TransactionBegin) : '';
        this.FormDelay.dateFinish = VisitCraneEventDelay[0].TransactionFinishAux ?  DateFormater.formatDateTimeWithSlash(VisitCraneEventDelay[0].TransactionFinishAux) : '';
        this.FormDelay.VisitCraneEventGpoId = VisitCraneEventDelay[0].VisitCraneEventGpoId ? VisitCraneEventDelay[0].VisitCraneEventGpoId : '';
        this.FormDelay.observation = VisitCraneEventDelay[0].Observation;
        this.FormDelay.status = VisitCraneEventDelay[0].FgActCraneEventGpo ? 1 : 0;
        this.FormDelay.FgActStatus = VisitCraneEventDelay[0].FgActCraneEventGpo ? 1 : 0;
        this.delayCustom = VisitCraneEventDelay[0].DelayCustomId;
        this.verifySelect = true;
        this.startChronometrer();
        this.startChronometerClick();
        this.$v.$touch();
      }else{
        this.loadingOverlay = false;
      }
    }).catch( err => {
      this.notifyError({text: err});
      this.loadingOverlay = false;
    })
  }

  async function setData(newDelay){
    this.StartService = true;
    if(newDelay.MachineId){
      this.FormDelay.machineId = newDelay.MachineId;
    }
    this.fgMultipleTime = newDelay.VisitCraneEventGpoId ? true : false;
    this.FormDelay.visitCraneDetailId = this.fgMultipleTime ? '' : newDelay.VisitCraneDetailId;
    //data delay
    this.FormDelay.dateBegin = newDelay.TransactionBegin ? newDelay.TransactionBegin : '';
    this.fgOpen = newDelay.FgOpen;
    if(!this.fgOpen){
      this.FormDelay.dateFinish = newDelay.TransactionFinish ? newDelay.TransactionFinish : '';
    }
    this.FormDelay.observation = newDelay.Observation;
    this.FormDelay.quantity = newDelay.Quantity;
    this.FormDelay.status = newDelay.FgActivo ? 1 : 0;
    this.FormDelay.FgActStatus = newDelay.FgActivo ? 1 : 0;
    await this.startChronometrer();
    this.FormDelay.VisitCraneId = newDelay.VisitCraneId;
    this.delayCustom = newDelay.DelayCustomId;
    this.verifySelect = true;
    this.$v.$touch();
  }

  function ListHold(event) {
    this.FormDelay.VisitCraneId = event.target.value;
    if (this.fgHold) {
      this.FormDelay.vesselHoldId = '';
      this.FormDelay.vesselHoldHatchCId = '';
      this.dataHatch = [];
      let DataCrane = this.dataCrane.find(item => item.VisitCraneId == this.FormDelay.VisitCraneId )
      this.dataHold = DataCrane ? DataCrane.HoldJson : [];
    }
  }

  function statusSelectColor(){
    return this.FormDelay.status === 1;
  }

  function changeStatus() {
    if(this.FormDelay.FgActStatus !== this.FormDelay.status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }

  function dataChronometer(number){
    return number.toString().padStart(2,0)
  }

  function playChronometer(value){
    if(value){
      if(this.FormDelay.dateBegin == ''){
        let dateNow = new Date();
        let currentDate = DateFormater.formatTimeZoneToDateTime(dateNow);

        this.sec = 0;
        this.min = 0;
        this.hour = 0;

        this.FormDelay.dateBegin = currentDate;

        this.$v.FormDelay.dateBegin.$touch();
      }
      if(this.timer === null){
        this.playingChronometer(true);
        this.timer = setInterval(()=> this.playingChronometer(true), 1000);
      }
      else{
          if(this.isClickedButton){
            clearInterval(this.timer);
            this.timer = null;
            this.pauseChronometer();
          }
          
        }
    } 
    else {
      this.playingChronometer(false);

      clearInterval(this.timer);
      this.timer = null;
      this.pauseChronometer(); 
    }

  }

  async function playingChronometer(valor){
    if(valor){
      this.sec++
      if(this.sec >= 59){
        this.sec = 0;
        this.min++;
      }
      if(this.min >= 59){
        this.min = 0;
        this.hour++;
      }

    }
  }
  
  function pauseChronometer(){
    this.intervalList.push(`${this.dataChronometer(this.hour)}:${this.dataChronometer(this.min)}:${this.dataChronometer(this.sec)}`);
    let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.FormDelay.dateBegin);
    let StartDate = DateFormater.formatDateTimeWithSlashToOnlyDate(this.FormDelay.dateBegin);
    var var1 = (this.hour*3600+this.min*60)*1000; 
    var arrDateStart = StartDate.split("/");
    var fecha1 = new Date(arrDateStart[1]+'/'+arrDateStart[0]+'/'+arrDateStart[2]+' '+timeBegin);
    fecha1 = fecha1.getTime();
    var newDateObj = new Date(fecha1 + var1);

    this.FormDelay.dateFinish = "";

    this.FormDelay.dateFinish = DateFormater.formatTimeZoneToDateTime(newDateObj);

    this.$v.FormDelay.dateFinish.$touch();
  }

  function clearChronometer(){
    if(this.timer !== null){
      clearInterval(this.timer);
      this.timer = null;
    }

    this.sec = 0;
    this.min = 0;
    this.hour = 0;
    this.clearIntervalList();
  }

  function clearIntervalList(){
    this.intervalList = []
  }

  async function startChronometrer(){
    try {
      this.dataCrane = [];
      if (!this.StartService) {
        this.Continue = false;
        if (this.fgMultipleTime) {
          this.FormDelay.VisitCraneId = [];
          this.FormDelay.vesselHoldId = []
        } else {
          this.FormDelay.VisitCraneId = '';
          this.FormDelay.vesselHoldId = '';
          this.FormDelay.vesselHoldHatchCId = '';
        }
      }
      let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
      if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
        || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
          throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
        || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
          throw this.$t('label.ErrorEtaPleaseCheck');
      }
      if ((DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
        || this.FormDelay.dateFinish && DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if(this.FormDelay.dateBegin != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin)>currentDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
      if(this.FormDelay.dateFinish != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)>currentDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }  
      }
      if(this.FormDelay.dateBegin != '' && this.FormDelay.dateFinish != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateBegin) >= DateFormater.formatDateTimeWithoutSlash(this.FormDelay.dateFinish)) {
          throw this.$t('label.ErrorFromToPleaseCheck');
        }
      }
    } catch (error) {
      this.notifyError({text: error });
      this.clearChronometer();
    }

    if(this.FormDelay.dateBegin !== '' && this.FormDelay.dateFinish != ''){
      if( moment(this.FormDelay.dateBegin,'DD/MM/YYYY HH:mm').isBefore(moment(this.FormDelay.dateFinish,'DD/MM/YYYY HH:mm') )){
        let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.FormDelay.dateBegin);
        let timeFinish = DateFormater.formatDateTimeWithSlashToOnlyTime(this.FormDelay.dateFinish);
        let totalDate = DateFormater.datesDifferenceInHours(DateFormater.formatDateTimeWithSlashToOnlyDate(this.FormDelay.dateBegin), 
          DateFormater.formatDateTimeWithSlashToOnlyDate(this.FormDelay.dateFinish), timeBegin, timeFinish);
        totalDate = totalDate.split(":");
        this.sec = 0;
        this.min = totalDate[1];
        this.hour = totalDate[0];

        if(this.timer != null){
          this.isClickedButton = false;
          this.playChronometer(false); 
        }else{
          clearInterval(this.timer);
          this.timer = null;
          this.pauseChronometer();
        }
      } 
    }else{
      if(this.FormDelay.dateFinish == ''){
        let dateNow = new Date();
        let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
        let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
        let timeBegin = DateFormater.formatDateTimeWithSlashToOnlyTime(this.FormDelay.dateBegin);
        let endTime2 = (this.itinerarySelected.Atd==undefined  && this.itinerarySelected.Atd==null) ?
          currentTime :
          DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
                
        let endDate2 = (this.itinerarySelected.Atd==undefined  && this.itinerarySelected.Atd==null) ?
          currentDate :
          DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));

        let totalDate = DateFormater.datesDifferenceInHours(DateFormater.formatDateTimeWithSlashToOnlyDate(this.FormDelay.dateBegin), 
          endDate2, timeBegin, endTime2);

        totalDate = totalDate.split(":");
        this.sec = 0;
        this.min = totalDate[1];
        this.hour = totalDate[0];
        this.isClickedButton = false;
        this.playChronometer(true);
      }else{
        this.clearChronometer();
      }
    }
  }

  function clearFilters() {
    this.Continue=false;
    this.FormDelay.dateBegin =''; 
    this.FormDelay.dateFinish =''; 
    this.clearChronometer();
    this.delayCustom = '';
    this.FormDelay.observation = ''; 
  }

  function startChronometerClick(){
    this.playChronometer(true); 
    this.isClickedButton = true;
  }

  function SelectedCrane(item, index, event) {
    this.addRemoveCrane(item);
    if (this.fgHold) {
      this.dataCrane[index].toggled = event;
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    }
  }

  function addRemoveCrane(item) {
    let Grane = this.FormDelay.VisitCraneId.find((e) => e.VisitCraneId == item.VisitCraneId)
    if(!Grane){
      //ADD
      this.FormDelay.VisitCraneId.push(item);
    }
    else{
      //DELETE
      this.FormDelay.VisitCraneId = this.FormDelay.VisitCraneId.filter((e) => e.VisitCraneId !== item.VisitCraneId);
      if (this.fgHold) {
        //SI SE DESTILDA UN PADRE SE DEBE ELIMINAR SUS HIJOS
        this.FormDelay.vesselHoldId = this.FormDelay.vesselHoldId.filter((e) =>(e.VisitCraneId!=item.VisitCraneId));
      }
    }
  }

  function addRemoveHold(item, Crane) {
    let Hold = this.FormDelay.vesselHoldId.find((e)=>e.VesselHoldId==item.VesselHoldId&&e.VisitCraneId==Crane)
    if(!Hold){
      //ADD
      this.FormDelay.vesselHoldId.push({...item, VisitCraneId: Crane});
      //SI SE TILDA UN HIJO SE DEBE MARCAR EL PADRE
      if (!(this.FormDelay.VisitCraneId.find((e) => e.VisitCraneId == Crane))) {
        this.FormDelay.VisitCraneId.push({VisitCraneId: Crane});
      }
    }
    else{
      //DELETE
      this.FormDelay.vesselHoldId = this.FormDelay.vesselHoldId.filter((e) =>!(e.VesselHoldId==item.VesselHoldId&&e.VisitCraneId==Crane));
    }
  }

  function computedHoldList(item, Crane) {
    return item.map((VesselHold) => {
      let checked = this.FormDelay.vesselHoldId.some(HoldChecked => HoldChecked.VesselHoldId == VesselHold.VesselHoldId&&HoldChecked.VisitCraneId==Crane);
      return {
        ...VesselHold,
        Checked: checked,
        VisitCraneId: Crane,
      };
    });
  }

  //computed
  function machineOptions(){
    if(this.dataMachine.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.dataMachine.map(function(e){
        chart.push({
            value: e.MachineId, 
            label: e.MachineName,
        })     
      })
      return chart;
    }
  }

  function delayCustomOptions(){
    if(this.dataDelayCustom.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      let _this = this.$i18n.locale;
      if (!this.fgMultipleTime) {
        this.dataDelayCustom.map(function(item){
          if (!item.FgVessel) {
            chart.push({
              value: item.DelayCustomId,
              label: _this=='en' ? item.DelayCustomNameEn : item.DelayCustomNameEs,
            })
          }
        })
      } else {
        this.dataDelayCustom.map(function(item){
          if (item.FgVessel) {
            chart.push({
              value: item.DelayCustomId,
              label: _this=='en' ? item.DelayCustomNameEn : item.DelayCustomNameEs,
            })
          }
        })
      }
      return chart;
    }
  }

  function craneOptions(){
    if(this.dataCrane.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.dataCrane.map(function(e){
        chart.push({
            value: e.VisitCraneId, 
            label: e.CraneAlias,
        })     
      })
      return chart;
    }
  }

  function hatchOptions(){
    if(this.dataHatch.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.dataHatch.map(function(e){
        chart.push({
            value: e.VesselHoldHatchCId, 
            label: e.HatchCoverName,
        })     
      })
      return chart;
    }
  }

  function holdOptions(){
    if(this.dataHold.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.dataHold.map(function(e){
        chart.push({
          value: e.VesselHoldId, 
          label: e.VesselHoldName,
        })     
      })
      return chart;
    }
  }

  function computedSelectDelay() {
    if (this.delayCustom === '' || this.delayCustom === null) {
      return false;
    } else {
      return true;
    }
  }

  function selectOptions(){
    return [
        { 
          value: 1, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: 0, 
          label: this.$t('label.INACTIVO')
        }
      ];
  }

  function checkColorChronometer(){
    return this.timer == null ? "add" : "wipe" ;
  }

  function fields () {
    return[
      { key: 'Checked',label: '',sorter: false, filter: false,_style:'width:3%;',},
      { key: 'CraneAlias',label: this.$t('label.crane'), _style:'width:94%;text-align:center;', _classes:'text-center' },
      { key: 'show_details',label: '',sorter: false, filter: false,_style:'min-width: 45px; max-width: 45px;',}
    ]
  }

  function fields2 () {
    return[
      { key: 'Checked',label: '',sorter: false, filter: false,_style:'width:3%;',},
      { key: 'VesselHoldName',label: this.$t('label.hold'), _style:'width:97%;text-align:center;', _classes:'text-center' },
    ]
  }

  function computedCraneList() {
    return this.dataCrane.map((item) => {
      let checked = this.FormDelay.VisitCraneId.some(Crane => Crane.VisitCraneId == item.VisitCraneId);
      return {
        ...item,
        Checked: checked,
        _toggled: item?.toggled ? true : false,
        _classes: 'table-secondary',
      };
    });
  }
  
  export default {
    name: 'modal-delay',
    mixins: [ GeneralMixin, mixinServicio, ModalMixin ],
    components: {
      Money,
    },
    directives: {
      money: VMoney,
    },
    props: {
      modal: Boolean,
      editModal: Boolean,
      titulo: String,
      itemDelay: {
        type: Object,
        default: () => {}
      } 
    },
    data,
    validations(){ 
      return FormDelay( this.fgHatch, this.fgHold, this.fgTwinBox, this.fgMachine, this.FgObservation, this.FormDelay.dateBegin, 
        this.FormDelay.dateFinish, this.ValidLaterDate, this.ValidPreviousDate, this.itinerarySelected)
    },
    directives: UpperCase,
    methods: {
      clearFilters,
      SelectedCrane,
      computedHoldList,
      VisitCraneEventGpo,
      ValidateHold,
      addRemoveHold,
      addRemoveCrane,
      HoldList,
      NotifySimpleCrane,
      NotifyMultipleCrane,
      MulipleHoldFormat,
      MulipleFormat,
      SimpleFormat,
      craneList,
      delayCustomList,
      setData,
      functionDateStart,
      functionDateEnd,
      validateDateRange,
      toggle,
      resetForm,
      submit,
      changeHatch,
      ListHold,
      getDateRange,
      inputsActive,
      statusSelectColor,
      changeStatus,
      dataChronometer, 
      playChronometer, 
      playingChronometer, 
      pauseChronometer, 
      clearChronometer, 
      clearIntervalList,
      startChronometrer,
      startChronometerClick,
    },
    computed: {
      fields,
      computedCraneList,
      fields2,
      machineOptions,
      delayCustomOptions,
      craneOptions,
      hatchOptions,
      holdOptions,
      computedSelectDelay,
      selectOptions,
      checkColorChronometer,
      ...mapState({
        visit: state => state.visitas.VisitId,
        itineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
      }),
    },
    watch: {
      modal: function(){
        if(this.modal){
          this.getDateRange();
          if (this.editModal&&this.itemDelay?.VisitCraneEventGpoId) {
            this.VisitCraneEventGpo();
          } else {
            this.delayCustomList();
          }
        }
      },
      delayCustom: async function(val){
        if (!this.StartService) {
          this.Continue = false;
          if (this.fgMultipleTime) {
            this.FormDelay.VisitCraneId = [];
          } else {
            this.FormDelay.VisitCraneId = '';
          }
        }
        this.dataCrane = [];
        if(val !== undefined && val !== null && val !== ''){
          this.FormDelay.delayCustomId = val 
          this.inputsActive();
        }else{
          this.FormDelay.delayCustomId = '';
          this.inputsActive();
        }
        
      }, 
      modalActive: function(val) {
        this.$emit('update:modal', val);
      },
    }
  }
</script>

<style lang="scss">
.select-delay--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-delay--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}
.timer-delay{
  color: rgb(19, 16, 16);
  font-size: 45px;
  margin-left: 0.5rem !important;

}
</style>