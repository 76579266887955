<template>
  <CCard>
    <CCardHeader>
      <small>{{$t('label.TierRows')}}</small>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="4" md="4" lg="3">
          <CInput
            v-model.trim="bayCode"
            :horizontal="{ label: 'col-sm-7', input: 'col-sm-5'}"
            disabled
            size="sm"
            :label="$t('label.baySelected')"
            addLabelClasses="text-right"
          >
          </CInput>
        </CCol>
        <CCol sm="5">
          <div class="position-relative">
            <div class="form-group form-row" rol="group">
              <label class="text-right col-form-label col-sm-4 col-form-label-sm" for="tpBuque">{{$t('label.hold')}}</label>
              <div class="col-sm-8 input-group">
                <v-select 
                  :options="holdOptions"
                  label="VesselHoldName"
                  :placeholder="$t('label.select')+' '+$t('label.hold')"
                  class="select-adjust"
                  v-model="vesselHold"
                  :disabled="isHoldEmpty"
                />
              </div>
            </div>
            <CElementCover
              :opacity="0.8"
              v-show="isLoadingHold"
            >
              <label class="d-inline">{{$t('label.load')}}...</label>
              <CSpinner size="sm" />
            </CElementCover>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CCard>
            <CCardBody>
              <CInputCheckbox
                class="mt-1"
                custom
                :checked.sync="bayTier.FgUnderDeck"
                :label="$t('label.Underdeck')"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.TierUnderDeckFrom"
            :options="underDeckSelect"
            :disabled="!isUnderDeckActive"
            size="sm"
            :label="$t('label.from')"
            placeholder="Select"
            :addLabelClasses="underDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.TierUnderDeckTo"
            :options="underDeckToOptions"
            :disabled="isUnderDeckToEmpty"
            size="sm"
            :label="$t('label.to')"
            placeholder="Seleccionar"
            :addLabelClasses="underDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.RowStarboardUnderDeck"
            :options="rowsSelect"
            :disabled="!isUnderDeckActive"
            size="sm"
            :label="$t('label.RowsToStartboard')"
            placeholder="Seleccionar"
            :addLabelClasses="underDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CCard>
            <CCardBody>
              <CInputCheckbox
                class="mt-1"
                custom
                :checked.sync="bayTier.FgCrossLineUnderDeck"
                :disabled="!isUnderDeckActive"
                :label="$t('label.CrosssLine')"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.RowPortUnderDeck"
            :options="rowsSelect"
            :disabled="!isUnderDeckActive"
            size="sm"
            :label="$t('label.RowsToPort')"
            placeholder="Seleccionar"
            :addLabelClasses="underDeckClasses"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="2">
          <CCard>
            <CCardBody>
              <CInputCheckbox
                class="mt-1"
                custom
                :checked.sync="bayTier.FgToDeck"
                :label="$t('label.onDeck2')"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.TierToDeckFrom"
            :options="toDeckSelect"
            :disabled="!isToDeckActive"
            size="sm"
            :label="$t('label.from')"
            placeholder="Seleccionar"
            :addLabelClasses="toDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.TierToDeckTo"
            :options="toDeckToOptions"
            :disabled="isToDeckToEmpty"
            size="sm"
            :label="$t('label.to')"
            placeholder="Seleccionar"
            :addLabelClasses="toDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.RowStarboardToDeck"
            :options="rowsSelect"
            :disabled="!isToDeckActive"
            size="sm"
            :label="$t('label.RowsToStartboard')"
            placeholder="Seleccionar"
            :addLabelClasses="toDeckClasses"
          />
        </CCol>
        <CCol sm="2">
          <CCard>
            <CCardBody>
              <CInputCheckbox
                class="mt-1"
                custom
                :checked.sync="bayTier.FgCrossLineToDeck"
                :disabled="!isToDeckActive"
                :label="$t('label.CrosssLine')"
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="2">
          <CSelect
            :value.sync="bayTier.RowPortToDeck"
            :options="rowsSelect"
            :disabled="!isToDeckActive"
            size="sm"
            :label="$t('label.RowsToPort')"
            placeholder="Seleccionar"
            :addLabelClasses="toDeckClasses"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" class="d-flex justify-content-end align-items-center">
          <CButton
            v-if="!isEdit"
            shape="square"
            variant="outline"
            color="dark"
            size="sm"
            :disabled="isSubmitInvalid"
            class="d-flex align-items-center"
            @click.prevent="submit"
          >
            <div v-if="!isSubmit">
              <CIcon name="cil-plus"/><span class="ml-1">{{$t('label.generate')+' BAY'}}</span>
            </div>
            <div v-if="isSubmit">
              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ml-1">{{$t('label.generate')+' BAY'}}</span>
            </div>
          </CButton>
          <CButton
            v-else
            shape="square"
            variant="outline"
            color="dark"
            size="sm"
            :disabled="isSubmitInvalid"
            class="d-flex align-items-center"
            @click.prevent="editConfirmation(true)"
          >
            <div v-if="!isSubmit">
              <CIcon name="cil-plus"/><span class="ml-1">{{$t('label.generate')+' BAY'}}</span>
            </div>
            <div v-if="isSubmit">
              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ml-1">{{$t('label.generate')+' BAY'}}</span>
            </div>
          </CButton>
          <CButton
            v-if="isEdit"
            shape="square"
            variant="outline"
            color="dark"
            size="sm"
            :disabled="isInactiveInvalid"
            class="d-flex align-items-center ml-2"
            @click.prevent="editConfirmation(false)"
          >
            <div v-if="!isSubmitInactive">
              <CIcon name="cil-x-circle"/><span class="ml-1">{{$t('label.Inactivate')+' BAY'}}</span>
            </div>
            <div v-if="isSubmitInactive">
              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ml-1">{{$t('label.Inactivate')+' BAY'}}</span>
            </div>
          </CButton>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="toDeckHasData">
                  <bay-grid
                    :col="colNum(false)"
                    :row="rowNum(false)"
                    :crossLine="isCrossLineTo"
                    :items="positionsToDeck"
                    :maxCol="responsiveCol(isCrossLineUnder)"
                  />
                </CCol>
              </CRow>
              <hr class="dash">
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="underDeckHasData">
                  <bay-grid
                    :col="colNum(true)"
                    :row="rowNum(true)"
                    :crossLine="isCrossLineUnder"
                    :items="positionsUnderDeck"
                    showNumbersBottom
                    :maxCol="responsiveCol(isCrossLineTo)"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>  
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex';
import BayGrid from "./bay-grid";
import BuqueBayFormValidations from '@/_validations/buque/BuqueBayFormValidations';
import { BayMixin } from '@/_mixins/buque';

//Data
function data() {
  return {
    isSubmit: false,
    isSubmitInactive: false,
    isLoadingHold: false,
    bayTier: {
      VesselId: '',
      VesselBayId: '',
      BayCode: '',
      FgUnderDeck: false, // 1 o 0
      TierUnderDeckFrom: '',
      TierUnderDeckTo: '',
      RowStarboardUnderDeck: '',
      RowPortUnderDeck: '',
      FgCrossLineUnderDeck: false, // 1 o 0,
      FgToDeck: false, // 1 o 0
      TierToDeckFrom: '',
      TierToDeckTo: '',
      RowStarboardToDeck: '',
      RowPortToDeck: '',
      FgCrossLineToDeck: false, // 1 o 0,
      Status: true
    },
    vesselHold: null,
    toDeckSelect: [],
    underDeckSelect: [],
    rowsSelect: [],
    toDekcSelectTo: [],
    underDeckSelectTo: [],
    holds: []
  }
}

//Created:
function created() {
  this.toDeckSelect = this.generateTierSelect(78, 120);
  this.underDeckSelect = this.generateTierSelect(2, 20);
  this.rowsSelect = this.generateTierSelect(1, 12, true);
  this.toDekcSelectTo = this.generateTierSelect(80, 120);
  this.underDeckSelectTo = this.generateTierSelect(1, 20);

  if(this.isEdit && this.currentBay)
    this.setData(this.currentBay);
}

//Mounted: 
function mounted() {
  if(this.VesselId){
    this.bayTier.VesselId = this.VesselId;
    this.getHolds();
  }
}

//Metodos: 
function generateTierSelect(init, end, normal = false) {
  let tiers = [];

  if(!normal) {
    for (let index = init; index <= end; index++) {
      if(index%2 == 0){
        tiers.push({
          label: index < 10 ? `0${index}`:`${index}`,
          value: index < 10 ? `0${index}`:`${index}`
        });
      }
    }
  } else {
    for (let index = init; index <= end; index++) {
      tiers.push({
        label: index < 10 ? `0${index}`:`${index}`,
        value: index
      });
    }
  }

  return tiers;
}
function setData(newBay) {
  this.bayTier.VesselId = newBay.VesselId ? newBay.VesselId : this.VesselId;
  this.bayTier.VesselHoldId = newBay.VesselHoldId ? newBay.VesselHoldId : '';
  this.bayTier.VesselBayId = newBay.VesselBayId;
  this.bayTier.BayCode = newBay.BayCode;
  this.bayTier.FgUnderDeck = newBay.FgUnderDeck || newBay.FgUnderDeck == 1 ? true : false;
  this.bayTier.TierUnderDeckFrom = newBay.TierUnderDeckFrom;
  this.bayTier.TierUnderDeckTo = newBay.TierUnderDeckTo;
  this.bayTier.RowStarboardUnderDeck = newBay.RowStarboardUnderDeck;
  this.bayTier.RowPortUnderDeck = newBay.RowPortUnderDeck;
  this.bayTier.FgCrossLineUnderDeck = newBay.FgCrossLineUnderDeck || newBay.FgCrossLineUnderDeck == 1 ? true : false;
  this.bayTier.FgToDeck = newBay.FgToDeck || newBay.FgToDeck == 1 ? true : false;
  this.bayTier.TierToDeckFrom = newBay.TierToDeckFrom;
  this.bayTier.TierToDeckTo = newBay.TierToDeckTo;
  this.bayTier.RowStarboardToDeck = newBay.RowStarboardToDeck;
  this.bayTier.RowPortToDeck = newBay.RowPortToDeck;
  this.bayTier.FgCrossLineToDeck = newBay.FgCrossLineToDeck || newBay.FgCrossLineToDeck == 1 ? true : false;
  this.bayTier.Status = newBay.FgActVesselBay;

  if(newBay.VesselHoldId){
    this.vesselHold = {
      VesselHoldId: newBay.VesselHoldId,
      VesselHoldName: newBay.VesselHoldName,
      label: newBay.VesselHoldName,
      value: newBay.VesselHoldId
    }
  } else
    this.vesselHold = null;
}
function resetform() {
  this.bayTier.VesselBayId = '';
  this.bayTier.VesselHoldId = '';
  this.bayTier.BayCode = '';
  this.bayTier.FgUnderDeck = false; // 1 o 0
  this.bayTier.TierUnderDeckFrom = '';
  this.bayTier.TierUnderDeckTo = '';
  this.bayTier.RowStarboardUnderDeck = '';
  this.bayTier.RowPortUnderDeck = '';
  this.bayTier.FgCrossLineUnderDeck = false; // 1 o 0;
  this.bayTier.FgToDeck = false; // 1 o 0
  this.bayTier.TierToDeckFrom = '';
  this.bayTier.TierToDeckTo = '';
  this.bayTier.RowStarboardToDeck = '';
  this.bayTier.RowPortToDeck = '';
  this.bayTier.FgCrossLineToDeck = false; // 1 o 0,
  this.bayTier.Status = true;

  this.vesselHold = null;
}
function submit() {
  this.isSubmit = true;

  let VesselBayJson = this.isEdit
    ? {
        ...this.formatedData(),
        VesselId: this.VesselId,
        VesselBayId: this.bayTier.VesselBayId,
        Status: this.bayTier.Status ? 1 : 0
      }
    : { ...this.formatedData() };

  this.$store.dispatch('baygrid/generateBay', VesselBayJson)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.Response,
      type: "success"
    });
    this.$emit('generated', true);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function formatedData() {
  return {
    VesselId: this.VesselId,
    VesselHoldId: this.vesselHold ? this.vesselHold.value : '',
    BayCode: this.bayTier.BayCode ? this.bayTier.BayCode : this.defaultCode,
    FgUnderDeck: this.bayTier.FgUnderDeck ? 1 : 0,
    TierUnderDeckFrom: this.bayTier.TierUnderDeckFrom,
    TierUnderDeckTo: this.bayTier.TierUnderDeckTo,
    RowStarboardUnderDeck: this.bayTier.RowStarboardUnderDeck,
    RowPortUnderDeck: this.bayTier.RowPortUnderDeck,
    FgCrossLineUnderDeck: this.bayTier.FgCrossLineUnderDeck ? 1 : 0,
    FgToDeck: this.bayTier.FgToDeck ? 1 : 0,
    TierToDeckFrom: this.bayTier.TierToDeckFrom,
    TierToDeckTo: this.bayTier.TierToDeckTo,
    RowStarboardToDeck: this.bayTier.RowStarboardToDeck,
    RowPortToDeck: this.bayTier.RowPortToDeck,
    FgCrossLineToDeck: this.bayTier.FgCrossLineToDeck ? 1 : 0,
  };
}
function getHolds() {
  this.isLoadingHold = true;
  this.$http.ejecutar('GET', 'VesselHold-by-vessel', { VesselId: this.VesselId, Filter: 'ACTIVO' })
  .then(response => {
    this.holds = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingHold = false;
  });
}
function editConfirmation(mod){
  let texto = mod
    ? '¿Esta seguro de generar un bay nuevo?'
    : '¿Esta seguro de inactivar el bay?';

  this.$swal.fire({
    text: `${texto}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'CONFIRMAR',
    cancelButtonText: 'CANCELAR'
  }).then((result) => {
    if (result.isConfirmed) {
      if(mod)
        this.submit();
      else
        this.inactivarBay();
    }
  });
}
function inactivarBay() {
  this.isSubmitInactive = true;

  let VesselBayJson = {
    ...this.formatedData(),
    VesselId: this.VesselId,
    VesselBayId: this.bayTier.VesselBayId,
    Status: 0
  };

  this.$store.dispatch('baygrid/inactivar', VesselBayJson)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.Response,
      type: "success"
    });
    this.$emit('generated', false);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmitInactive = false;
  });
}

//Computeds: 
function isToDeckToEmpty() {
  return this.bayTier.TierToDeckFrom == '' || !this.bayTier.FgToDeck;
}
function isToDeckActive() {
  return this.bayTier.FgToDeck;
}
function isUnderDeckToEmpty() {
  return this.bayTier.TierUnderDeckFrom == '' || !this.bayTier.FgUnderDeck;
}
function isUnderDeckActive() {
  return this.bayTier.FgUnderDeck;
}
function toDeckHasData() {
  return this.positionsToDeck.length != 0;
}
function underDeckHasData() {
  return this.positionsUnderDeck.length != 0;
}
function underDeckToOptions() {
  return this.underDeckSelectTo.filter((item) => {
    return Number.parseInt(item.value) > Number.parseInt(this.bayTier.TierUnderDeckFrom);
  });
}
function toDeckToOptions() {
  return this.toDekcSelectTo.filter((item) => {
    return Number.parseInt(item.value) > Number.parseInt(this.bayTier.TierToDeckFrom);
  });
}
function toDeckClasses() {
  return {
    'required': this.bayTier.FgToDeck ? true : null
  }
}
function underDeckClasses() {
  return {
    'required': this.bayTier.FgUnderDeck ? true : null
  }
}
function isHoldEmpty() {
  return this.holds.length == 0;
}
function holdOptions() {
  return this.holds.map((hold) => Object.assign({}, hold, {
    label: hold.VesselHoldName,
    value: hold.VesselHoldId
  }));
}

function isSubmitInvalid(){
  if(this.bayTier.FgUnderDeck  && this.bayTier.FgToDeck) {
    return this.isSubmit || this.bayTier.TierUnderDeckFrom == '' || this.bayTier.TierUnderDeckTo == '' || this.bayTier.TierToDeckFrom == '' || this.bayTier.TierToDeckTo == '' || this.bayTier.RowStarboardUnderDeck == '' || this.bayTier.RowStarboardToDeck == '' || this.bayTier.RowPortUnderDeck == '' || this.bayTier.RowPortToDeck == '';
  } else if(this.bayTier.FgToDeck) {
    return this.isSubmit || this.bayTier.TierToDeckFrom == '' || this.bayTier.TierToDeckTo == '' || this.bayTier.RowStarboardToDeck == '' || this.bayTier.RowPortToDeck == '';
  } else if(this.bayTier.FgUnderDeck) {
    return this.isSubmit || this.bayTier.TierUnderDeckFrom == '' || this.bayTier.TierUnderDeckTo == '' || this.bayTier.RowStarboardUnderDeck == '' || this.bayTier.RowPortUnderDeck == '';
  } else
    return true;
}
function isInactiveInvalid(){
  return this.isSubmitInactive;
}
function bayCode() {
  return this.bayTier.BayCode != '' ? this.bayTier.BayCode : this.defaultCode;
}

export default {
  name: 'tier-rows',
  mixins: [BayMixin],
  props: {
    defaultCode: {
      type: String,
      default: '00'
    }
  },
  components: {
    BayGrid
  },
  data,
  validations: BuqueBayFormValidations,
  created,
  mounted,
  methods: {
    generateTierSelect,
    setData,
    resetform,
    getHolds,
    submit,
    editConfirmation,
    formatedData,
    inactivarBay
  },
  computed: {
    isToDeckToEmpty,
    isUnderDeckToEmpty,
    toDeckHasData,
    underDeckHasData,
    underDeckToOptions,
    toDeckToOptions,
    isToDeckActive,
    isUnderDeckActive,
    isHoldEmpty,
    holdOptions,
    toDeckClasses,
    underDeckClasses,
    isSubmitInvalid,
    isInactiveInvalid,
    bayCode,
    ...mapState({
      isEdit: state => state.baygrid.isEdit,
      VesselId: state => state.baygrid.VesselId,
      currentBay: state => state.baygrid.bay,
      positionsToDeck: state => state.baygrid.positionsToDeck,
      positionsUnderDeck: state => state.baygrid.positionsUnderDeck
    })
  },
  watch: {
    currentBay: function(newBay) {
      if(newBay)
        this.setData(newBay);
      else
        this.resetform();
    },
    isToDeckActive: function(newVal) {
      if(!newVal){
        this.bayTier.TierToDeckFrom = '';
        this.bayTier.TierToDeckTo = '';
        this.bayTier.RowStarboardToDeck = '';
        this.bayTier.RowPortToDeck = '';
        this.bayTier.FgCrossLineToDeck = false;
      }
    },
    isUnderDeckActive: function(newVal) {
      if(!newVal){
        this.bayTier.TierUnderDeckFrom = '';
        this.bayTier.TierUnderDeckTo = '';
        this.bayTier.RowStarboardUnderDeck = '';
        this.bayTier.RowPortUnderDeck = '';
        this.bayTier.FgCrossLineUnderDeck = false;
      }
    },
    VesselId: function(newId) {
      if(newId)
        this.getHolds();
    }
  }
}
</script>

<style scoped>
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}
.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
</style>