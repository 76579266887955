<template>
    <div :style="!operationalSituation ? 'width: 100%' : ''">
      <apexchart :width="Width" :height="Height" :type="isDonut?'donut':'pie'" :options="chartOptions" :series="Series"></apexchart>
    </div>
 </template>
 <script>
function chartOptions(){
    return {
        chart: {
            type: this.isDonut?'donut':'pie',
            animations: {
                enabled: this.Animations,
            }
        },
        colors: this.Colors.length != 0 ? this.Colors : undefined,
        labels: this.Categories,
        legend: {
            show: false,
        },
        tooltip: this.Tooltip,
        plotOptions: {
            pie: {
                expandOnClick: true,
                donut: {
                    size: '55%'
                },
                donut: {
                    labels: {
                        show: this.ShowTotal,
                        name: {
                            fontSize: "9px",
                            offsetY: -5,
                        },
                        value: {
                            offsetY: -5,
                            fontSize: "9px",
                            ...this.FormatterValue,
                        },
                        total: {
                            show: this.ShowTotal,
                            fontSize: "9px",
                            color: '#373d3f',
                            ...this.FormatterTotal,
                        }
                    }
                }
            },
        },
        dataLabels: {
            enabled: this.dataLabels,
            dropShadow: {
                enabled: false,
            },
        },
        noData: {
            text: this.$t('label.noDataAvailable').toUpperCase(),
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#f77f7f",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        },
    }    
}

function Hrs(val){
    let Hrs = Math.floor(val/60);
    if(Hrs<10){
        Hrs = '0'+Hrs;
    }
    let Min = val - (Hrs*60)
    if(Min<10){
        Min = '0'+Min;
    }
        return Hrs+':'+Min;
    }

export default {
    name:'simpleDonut',
    props: {
        Series: {
            type: Array,
            required: true,
            default: () => [],
        },
        Categories:{
            type: Array,
            required: true,
            default: () => [],
        },
        Colors:{
            type: Array,
            required: false,
            default: () => [],
        },
        dataLabels:{
            type: Boolean,
            required: false,
            default: true,
        },
        Tooltip:{
            type: Object ,
            required: false,
            default: function () {
                return {
                    y: {
                        formatter: function (val) {
                            return Hrs(val)+' HRS';
                        }
                    }
                } 
            },
        },
        isDonut:{
            type: Boolean,
            required: false,
            default: true,
        },
        Width:{
            type: String,
            required: false,
            default: '100%',
        },
        Height:{
            type: String,
            required: false,
            default: '280px',
        },
        Animations:{
            type: Boolean,
            required: false,
            default: true,
        },
        ShowTotal: {
            type: Boolean,
            default: false,
        },
        FormatterValue: {
            type: Object,
            default: () => {
                return { 
                    formatter: (Value) => {
                        return Value;
                    }
                }
            },
        },
        FormatterTotal: {
            type: Object,
            default: () => {
                return { 
                    formatter: (W) => {
                        return W.globals.seriesTotals.reduce((a, b) => a + b, 0);
                    }
                }
            },
        },
        operationalSituation: {
            type: Boolean,
            default: false,
        }
    },
    methods:{
        Hrs,
    },
    computed:{
        chartOptions
    },
}
</script>