import {
    nullable,
    onlyMayusText,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    integer
} from "vuelidate/lib/validators";

    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else
          return (value != '');
      };
/*
    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else
            return (value != '');
      };
*/
    function verifyNumClaseImdg(ImdgClassAbbrev) {
        return ImdgClassAbbrev == 0 ||
          (ImdgClassAbbrev.indexOf(0, -1) == 1 && ImdgClassAbbrev.indexOf(0, -1) == 0)
          ? false
          : true;
    }

    function validateIfExistsInList(ImdgClassAbbrev) {
        let bool = true;        
        this?.imdgClassItems.map((item) => {
          if (
            item.ImdgClassAbbrev === ImdgClassAbbrev &&
            item.ImdgClassId !== this.formImdgClass.ImdgClassId
          ) {
            bool = false;
          }
        });
        return bool;
    }
    
    function associateImgClassByCode(ImdgCod, imdgCodeId, ImdgClassId) {
        if(ImdgClassId !== null || ImdgClassId !== ''){
            let ImdgCodClass = ImdgCod.split(".")[0];
                return ImdgCodClass === imdgCodeId.imdgCodeId ? true : false;
        }
    }

    export default (vue) => {
        
        return {
            formImdg: {
                ImdgClassId:{
                    required,
                },
                ImdgNameEs:{
                    required,
                   // onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                },
                ImdgNameEn:{
                    required,
                   // onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                },
                ImdgCod:{
                    required,
                    maxLength: maxLength(10),
                    minLength: minLength(3),
                    invalidCodeImdg: associateImgClassByCode
                },
                ImdgRoute: { 
                    required,
                },
                imdgCodeId:{
                    required,
                },
                Status: { 
                    //required
                },
            },
            formImdgClass: {
                ImdgClassNameEs: { 
                    required, 
                    maxLength: maxLength(100) 
                },
                ImdgClassNameEn: { 
                    required, 
                    maxLength: maxLength(100) 
                },
                ImdgClassAbbrev: { 
                    required, 
                    unique: validateIfExistsInList, 
                    verifyNumClaseImdg,
                    integer,
                    maxLength: maxLength(5),
                },
                Status: { 
                    //required
                },
                FgActRequieredSub: { 
                    required
                },

                

            }
        }
    }