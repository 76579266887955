<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CCol sm="12" class="d-flex justify-content-end px-0 mb-2 mt-2">
            <CButton 
                color="danger"
                class="mr-1"
                size="sm" 
                @click="onBtnPdf()"
            >
                <CIcon name="cil-file-pdf"/>&nbsp; PDF 
            </CButton>
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
        <CRow>
            <CCol sm="12" lg="3">
                <CSelect
                    :label="$t('label.user')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :options="UserListFormatted"
                    v-model.trim="UserId"
                    :value.sync="UserId"
                />
            </CCol>
            <label class="col-sm-12 col-lg-1 text-right pr-1 mt-2">{{$t('label.device')}}</label>
            <CCol sm="12" lg="2" xl="2" class="px-1">
                 <CSelect
                    :options="DeviceListFormatted"
                    v-model.trim="DeviceId"
                    :value.sync="DeviceId"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 text-right pr-1 mt-2">{{$t('label.version')}}</label>
            <CCol sm="12" lg="2" xl="2" class="px-1">
                 <CSelect
                    :options="VersionListFormatted"
                    v-model.trim="VersionId"
                    :value.sync="VersionId"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="3">
                <div class="form-group form-row mb-2">
                    <label class="col-sm-12 col-lg-3 text-right mt-2 pr-1">{{$t('label.date')}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                        <vue-datepicker 
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="searchFilterDate.fromDay"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                        >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker>
                    </div>
                </div>
            </CCol>
            <label class="col-sm-12 col-lg-1 text-center mt-2">-</label>
            <CCol sm="12" lg="2" class="px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-12 col-lg-auto px-1" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                    @click="getDetailsList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refresh()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-11 col-lg-3 text-right', input: 'col-sm-11 col-lg-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="12" lg="4" xl="4">
    
            </CCol>
            <CCol sm="1" lg="1" xl="1">
            </CCol>
            <CCol sm="12" lg="4">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 text-right', input: 'col-sm-12 col-lg-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
        <ModalError
            :modal="Modalerror"
            :itemError="itemError"
            @close-modal="(Modalerror=false, itemError='')"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    import ModalError from './modal-error';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
            },
            itemError: '',
            DeviceId: '',
            HoldName: '', 
            DeviceOptions: [],
            VersionId: '',
            transportName: '',
            VersionOptions: [],
            UserId: '',
            CraneName: '',
            UserOptions: [],
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            nulo:'', 
            pageSize:'',
            search: '',
            Modalerror: false,
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'seeError'){
                    this.ShowError(event.data);
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'seeError'){
                   tippy('#myGenerateOrderButton', {
                        content: this.$t('label.openErrors'),
                    })
                } 
            },   
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function ShowError(item) {
        this.itemError=item.ErrorDs;
        this.Modalerror=true;
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX: rowData[i].IdX,
                FormatedDate: rowData[i].FormatedDate,
                ErrorTpName: rowData[i].ErrorTpName,
                Version: rowData[i].Version,
                UserNameErrorDetail: rowData[i].UserNameErrorDetail,
                DeviceName: rowData[i].DeviceName,
                TransactionDate: rowData[i].TransactionDate,
                UserNameErrorTransfer: rowData[i].UserNameErrorTransfer,
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.ErrorLog'), false, {from: 1, to: 1});
            }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(val) {
        let currentDate = new Date();
        this.loadingOverlay = true;
        let rowData = [];
        let Filter = []; 
        let UserData = this.UserOptions.find( item => item.StowageRoleId === this.UserId );
        let DeviceData = this.DeviceOptions.find( item => item.StowageRoleId === this.DeviceId );
        let VersionData = this.VersionOptions.find( item => item.Version === this.VersionId );
        Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
        Filter[1] = this.$t('label.user')+': '+(UserData?UserData.Name:'N/A');
        Filter[2] = this.$t('label.device')+': '+(DeviceData?DeviceData.DeviceName:'N/A'); 
        Filter[3] = this.$t('label.version')+': '+(VersionData?VersionData.Version:'N/A');
        Filter[4] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        Filter[5] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        if(this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowData, this.$t('label.ErrorLog'), val, Filter, 1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refresh() {
        this.loadingOverlay = true;
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.getDateRange();

        this.DeviceId= '';
        this.HoldName= '';

        this.UserId= '';
        this.CraneName= '';

        this.VersionId= '';
        this.transportName= '';
		this.getDetailsList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    function getDetailsList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let List = [];
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

        let ErrorLogJson = [{
            VisitId: this.Visit,
            UserTransacId :this.UserId,
            DeviceId: this.DeviceId,
            Version: this.VersionId,
            DateStart: dateStart,
            DateEnd: dateEnd,
        }];

        this.$http.post("VisitErrorLogSigetMobile-Report", ErrorLogJson, { root: 'ErrorLogJson' })
        .then(response => {
            List = response.data.data;
            if (Object.entries(List).length != 0) {
                this.formatedItems = List.ErrorLogDetailJson ? List.ErrorLogDetailJson : [];
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
    
//computed
    function RowData(){
        if(this.formatedItems.length != 0){
            let _lang = this.$i18n.locale;
            return this.formatedItems.map((item) => {
                return {
                    ...item,
                    FormatedDate: item.TransaRegDate?DateFormater.formatDateTimeWithSlash(item.TransaRegDate):'',
                    ErrorTpName: _lang=='en'? item.ErrorTpNameEn : item.ErrorTpNameEs,
                    TransactionDate: item.TransactionDate?DateFormater.formatDateTimeWithSlash(item.TransactionDate):'',
                };
            });
        }else{
            return [];
        }
    }

    function VersionListFormatted(){
        if(this.VersionOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.VersionOptions.map(function(e){
                chart.push({
                    value: e.Version, 
                    label: e.Version,
                })
            })
            return chart;
        }
    }

    function UserListFormatted(){
        if(this.UserOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.UserOptions.map(function(e){
                chart.push({
                    value: e.StowageRoleId, 
                    label: e.Name,
                })
            })
            return chart;
        }
    }

    function DeviceListFormatted(){
        if(this.DeviceOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.DeviceOptions.map(function(e){
                chart.push({
                    value: e.DeviceId, 
                    label: e.DeviceName,
                })
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'seeError',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeError",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="myGenerateOrderButton" class="btn btn-watch mr-1 btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.08 20.51" role="img" class="c-icon">
                                        <path class="cls-1" d="M15,0A16.17,16.17,0,0,0,0,10.25a16.15,16.15,0,0,0,30.08,0A16.16,16.16,0,0,0,15,0Zm0,17.09a6.84,6.84,0,1,1,6.84-6.84A6.84,6.84,0,0,1,15,17.09ZM15,6.15a4.11,4.11,0,1,0,4.1,4.1A4.1,4.1,0,0,0,15,6.15Z"></path>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1',
            },
            {
                field: "IdX",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                width: 70,
                minWidth: 70,
                cellClass: 'center-cell-especial',
            },
            {
                field: "FormatedDate",
                headerName: this.$t('label.date'),
                filter: 'agTextColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                width: 180,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ErrorTpName",
                headerName: this.$t('label.type'),
                filter: 'agTextColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                width: 190,
                minWidth: 190,
                //pinned: 'left',
                cellClass: 'center-cell-especial',
            },
            {
                field: "Version",
                headerName: this.$t('label.version'),
                filter: 'agTextColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "UserNameErrorDetail",
                headerName: this.$t('label.user'),
                filter: 'agTextColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                width: 180,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "DeviceName",
                headerName: this.$t('label.device'),
                filter: 'agTextColumnFilter',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.TransferDate'),
                filter: 'agTextColumnFilter',
                // sortable: true,
                lockPosition: true,
                width: 220,
                minWidth: 220,
                cellClass: 'center-cell-especial',
            },
            {
                field: "UserNameErrorTransfer",
                headerName: this.$t('label.TransferUser'),
                filter: 'agTextColumnFilter',
                // sortable: true,
                lockPosition: true,
                width: 230,
                minWidth: 230,
                cellClass: 'center-cell-especial',
            },
        ]
        return columnDefs;
    }
    export default {
        name: "deteils-index",
        data,
        beforeMount,
        props: {
            Itinerary: {
                type: Array,
                default: [],
            },
            Filter: {
                type: Array,
                default: [],
            },
            ErrorLogDetailJson: {
                type: Array,
                default: [],
            }
        },
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            ShowError,
            onGridReady,
            onBtnExport,
            getDetailsList,
            onQuickFilterChanged,
            onPageSizeChanged,
            onBtnPdf,
            validateDateRange,
            refresh,
            getDateRange,
        },
        computed:{
            RowData,
            VersionListFormatted,
            UserListFormatted,
            DeviceListFormatted,
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
            }),
        },
        components:{
            ModalError,
        },
        watch:{
            Itinerary: function (val) {
                if (val.length!=0) {
                    this.searchFilterDate.fromDay = val[0].fromDay;
                    this.previousDate = val[0].previousDate;
                    this.searchFilterDate.toDay = val[0].toDay;
                    this.laterDate =  val[0].laterDate;
                }
            },
            Filter: function (val) {
                if (val.length!=0) {
                    this.DeviceOptions = val[0].DeviceJson;
					this.VersionOptions = val[0].VersionJson;
					this.UserOptions = val[0].UserDeviceJson;
                }
            },
            ErrorLogDetailJson: function (val) {
                if (val.length!=0) {
                    this.formatedItems = val;
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style>
    .center-cell-especial {
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .ag-header-cell-label {
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .mx-datepicker {
        position: relative;
        display: inline-block;
        width: 100%;
    }
</style>