<template>
    <div class="bay-container">
      <div
        class="col-item item-number-rows"
        :class="titleColClasses"
        :style="itemStyles"
      >
        <img :src="getCharacterSvg(bayCode)" alt=""
          class="item-number-icon"
          :style="{ width: `${numberSize+ 50}%`, height: `${numberSize}%` }"
        >
      </div>
      <!-- Numbers Top -->
      <div v-if="showNumbers && !showNumbersBottom" class="row-item">
        <div
          class="col-item item-number-corner"
          :style="itemStyles"
          :class="{ 'cursor-container-selected': cargoView && container && !container.BaySlot && !visit? true : null}"
        ></div>
          <div
          v-for="number in colNumbers"
          :key="number.SlotCode"
          class="col-item item-number-cols"
          :class="numbersColClasses"
          :style="itemStyles"
        >
       
          <img :src="getCharacterSvg(number.StackCode)" alt=""
            class="item-number-icon"
            :style="{ width: `${numberSize + 50}%`, height: `${numberSize}%` }"
          >
        </div> 
        <div
          class="col-item item-number-rows item-not-exist"
          :class="{ 'cursor-container-selected': cargoView && container && !container.BaySlot && !visit? true : null}"
          :style="itemStyles"
        ></div>
      </div>
      
      <!-- Bay Square -->
      <div class="row-item" v-for="(rows, index) in layoutItems" :key="index">

        <!-- Numbers Col Left -->
        <div
          class="col-item item-number-rows"
          :class="numbersRowClasses"
          :style="itemStyles"
          v-if="showNumbers"
        >
          <img :src="getCharacterSvgValidate(rows.HeightCode)" alt=""
            class="item-number-icon"
            :style="{ width: `${numberSize+ 50}%`, height: `${numberSize}%` }"
          >
        </div>
  
        <!-- Squares -->
        <div
          v-for="col in rows.PositionJson"
          :key="col.YardSlotId"
          class="col-item"
          :class="itemClasses(col)"
          :style="containerItemStyle(col)"
          @click.prevent="selectPosition(col)"
          @mouseenter="loadEnter(col)"
          @mouseleave="loadLeave(col)"
        >
          <!-- Tooltip -->
          <span
            class="sigeop-tooltip"
            v-if="tooltip && col.YardSlotStatusId != 'A782166F-3BFF-4E95-8C68-982815042857'"
          >
            {{ tooltipCode(col) }}
          </span>
  
          <!-- Colores segun las Preferencias seleccionadas -->
          <div
            class="bg-position"
            :style="{ backgroundColor: colorPreference(col) }"
            v-if="allColorPreferences.length != 0 && col.CargoJson"
          ></div>

          <!-- Contenedor buscado. -->
          <div
            class="bg-position border-search"
            v-if="hasSearchCode(col)"
          ></div>
  
          <!-- Color de valido/invalido Load View && NOT EXIST -->
          <div
            class="bg-position-select"
            :class="isAllowed(col)"
            v-if="cargoView && col.YardSlotStatusId != 'A782166F-3BFF-4E95-8C68-982815042857'"
          ></div>
          <!-- Unavailable Positions -->
          <div
            class="bg-unavailable-positions"
            :class="isAllowed(col)"
            v-if="visit && freePositions && col.YardSlotStatusId != 'A782166F-3BFF-4E95-8C68-982815042857'"
          ></div>
  
          <!-- Color dependiendo del Puerto -->
          <div class="bg-position" :class="bgByPort(col)"></div>
  
          <!-- Letras/Numeros segun las Preferencias seleccionadas -->
          <img
            :src="printCode(col)"
            alt=""
            class="center-icon"
            v-if="viewElement != '' && col?.CargoJson"
          >
  
          <!-- Iconos segun las Preferencias seleccionadas -->
          <div :class="iconPreference(col)" v-if="!imgPreference(col)"></div>
          <img src="/img/iconos/Standard.svg" class="st-estandar" v-if="imgPreference(col)">
  
          <!-- Iconos en Bay del Buque -->
          <div :class="statusIcon(col)" v-if="showStatus"></div>
  
          <!-- Datos de Bay View -->
          <div class="occupied-oversize-smaller" v-if="validBayView('occupiedOversize', col)"></div>
          <img :src="printBayData('occupiedBy40', col)" alt="" class="center-icon" v-if="validBayView('occupiedBy40', col)">
          <img :src="printBayData('load', col)" alt="" class="load-port" v-if="validBayView('load', col)">
          <img :src="printBayData('discharge', col)" alt="" class="discharge-port" v-if="validBayView('discharge', col)">
          <img :src="printBayData('containerCode', col)" alt="" class="container-code" v-if="validBayView('containerCode', col)">
          <img :src="printBayData('carrier', col)" alt="" class="carrier" v-if="validBayView('carrier', col)">
          <img :src="printBayData('weight', col)" alt="" class="weight" v-if="validBayView('weight', col)">
          <img :src="printBayData('type', col)" alt="" class="type" v-if="validBayView('type', col)">
          <img :src="printBayData('slot', col)" alt="" class="slot" v-if="validBayView('slot', col)">
          <img :src="printBayData('temperature', col)" alt="" class="temperature" v-if="validBayView('temperature', col)">
          <img :src="printBayData('hazard', col)" alt="" class="hazard-code" v-if="validBayView('hazard', col)">
          <div class="oversize-top bg-oversize oversize-transparency" v-if="validBayView('DimTop', col)"></div>
          <img :src="printBayData('DimTop', col)" alt="" class="dim-top" v-if="validBayView('DimTop', col)">
          <div class="oversize-right bg-oversize oversize-transparency" v-if="validBayView('DimRight', col)"></div>
          <img :src="printBayData('DimRight', col)" alt="" class="dim-right" v-if="validBayView('DimRight', col)">
          <div class="oversize-left bg-oversize oversize-transparency" v-if="validBayView('DimLeft', col)"></div>
          <img :src="printBayData('DimLeft', col)" alt="" class="dim-left" v-if="validBayView('DimLeft', col)">
          <div class="oversize-front bg-oversize oversize-transparency" v-if="validBayView('DimFront', col)"></div>
          <div class="oversize-back bg-oversize oversize-transparency" v-if="validBayView('DimBack', col)"></div>
          
          <!-- Iconos obligatorios -->
          <img src="/img/iconos/OccupiedOversize.svg" class="occupied-oversize" v-if="validMandatory(2, col)">
          <img src="/img/iconos/AvailableHazard.svg" class="hazard-available-plan" v-if="validMandatory(3, col)">
          <img src="/img/iconos/HazardRestriction.svg" class="hazard-restriction-plan" v-if="validMandatory(4, col)">
          <img src="/img/iconos/ReeferPlug.svg" class="reefer-plug" v-if="validMandatory(5, col)">
          <img src="/img/iconos/Plus45_1.svg" class="occupied-by-45" v-if="validMandatory(6, col)">
          <img src="/img/iconos/OccupiedBy40.svg" class="occupied-by-40" v-if="validMandatory(7, col)">
          <img src="/img/iconos/OccupiedOversize.svg" class="occupied-oversize" v-if="validMandatory(8, col)">
          <div class="restow" v-if="validMandatory(9, col)"></div>
          <img src="/img/iconos/Empty.svg" class="empty" v-if="validMandatory(11, col)">
          <div class="high-cube" v-if="validMandatory(12, col)"></div>
          <div class="reefer" v-if="validMandatory(13, col)"></div>
          <div class="oversize-top" v-if="validMandatory(14, col)"></div>
          <div class="oversize-right" v-if="validMandatory(15, col)"></div>
          <div class="oversize-left" v-if="validMandatory(16, col)"></div>
          <div class="oversize-front" v-if="validMandatory(17, col)"></div>
          <div class="oversize-back" v-if="validMandatory(18, col)"></div>
          <div class="imdg" v-if="validMandatory(19, col)"></div>
          <div class="high-cube-45" v-if="validMandatory(24, col)"></div>

          <!-- En Load View -->
          <img src="/img/iconos/Standard.svg" class="st-estandar" v-if="validMandatory(20, col)">
          <div class="open-top" v-if="validMandatory(21, col)"></div>
          <div class="flat-rack" v-if="validMandatory(22, col)"></div>
          <div class="tanque" v-if="validMandatory(23, col)"></div>

       
          <!-- Letras de los Restow -->
          <div class="bg-restows" v-if="hasRestow(col)"></div>
          <img
            :src="getCharacterSvg('A')"
            alt="A"
            class="restow-letter"
            v-if="hasRestow(col, 'A')"
          >
          <img
            :src="getCharacterSvg('D')"
            alt="D"
            class="restow-letter"
            v-if="hasRestow(col, 'D')"
          >
       
          
          <!-- Free Positions -->
          <div class="bg-free-position-selected" v-if="hasFreePosition(col)"></div>
          <!-- Alertas y Errores -->
          <div class="bg-semi-transparente" v-if="hasWarnings(col) || hasErrors(col)"></div>
          <img
            alt="A"
            srcset=""
            class="alerts-icon"
            src="/img/iconos/alert.svg"
            v-if="hasWarnings(col)"
            @click.stop="handleAlert(1, col)"
          >
          <img
            alt="E"
            srcset=""
            class="errors-icon"
            src="/img/iconos/error.svg"
            v-if="hasErrors(col)"
            @click.stop="handleAlert(2, col)"
          >
        </div>
  
        <!-- Invisible Col Right -->
        <div
          class="col-item item-number-rows item-not-exist"
          :class="{ 'cursor-container-selected': cargoView && container && !container.BaySlot && !visit? true : null}"
          :style="itemStyles"
          v-if="showNumbers"
        >
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { GenerateSvgPlaceholder, slugify } from '@/_helpers/funciones';

  //Data
  function data() {
    return {
      layout: [],
      currentPosition: null,
    };
  }
  
  //Created
  function created() {
    this.generateLayout();
  }
  
  //Metodos:
  function generateLayout() {
    this.layout = this.items;
  }
  
  function selectPosition(item) {
    if(this.disabledSelect || this.hasWarnings(item) || this.hasErrors(item) || this.restow)
      return;

    if(item?.CargoJson?.length>0)
      this.$emit('update', item);
  
    if(this.cargoView && item?.CargoJson && this.removeContainer && this.filterPort.PortId == item?.CargoJson[0].LoadPortId && !this.restow) {
      //Para remover un contenedor.
      this.$emit('remove-load', item);
    }
    if(this.cargoView && !this.removeContainer && !item?.CargoJson && this.canLoad(item) && !this.restow) {
      // Para actualizar la posicion/posiciones
      this.$emit('load-container', {
        posicion: item,
        contenedor: this.container,
      });
    }
  
    if( this.showStatus && this.currentStatus.YardSlotStatusName != "" && this.currentStatus.YardSlotStatusId != "") {
        
      this.$store.commit("yard/changePositionStatus", {
          original: item,
          nuevo: this.currentStatus,
        });
    }
  }

  function itemClasses(item) {
    return {
      "item-not-exist":
        item.YardSlotStatusNameEn == "NOT EXIST" && (this.showStatus || this.showStatusView || this.cargoView)
          ? true : null,
      "item-inactive":
        item.YardSlotStatusNameEn == "INACTIVE" && this.showStatus ? true : null,
      "item-disabled":
        item.YardSlotStatusNameEn == "DISABLED" && this.showStatus ? true : null,
      "item-occupied":
        ((item.YardSlotStatusNameEn == "OCCUPIED" || item.YardSlotStatusNameEn == "OCCUPIED OVERSIZE") && item.beforeOversize === undefined && item.CargoJson?.length>0) && this.showStatus ? true : null,
        // No dependen del showStatus
      "col-item-responsive": this.responsive ? true : null,
      "bg-gris": !this.obligatory && !this.bayView && !this.cargoView
        ? null 
        : this.filterPort.FilterType === '' || this.filterPort.PortId === '' || this.filterPort.PortCode === ''
          ? null 
          : item?.CargoJson && item?.CargoJson[0][this.filterPort.FilterType] != this.filterPort.PortId,
      "cursor-load": this.cargoView && this.container && !this.container.BaySlot && !this.visit ? true : null,
      "cursor-container-selected": this.cargoView && this.container && !this.container.BaySlot && item.YardSlotStatusId == 'A782166F-3BFF-4E95-8C68-982815042857' && !this.visit ? true : null,
      "position-asign": this.cargoView && this.container 
        ? this.container?.Size == '20'
          ? item.VesselBayPosId == this.container.VesselBayPosId
          : this.container.BaySlot == item.CodPositionLabel
        : null,
      "no-pointer": !this.cargoView
        ? null 
        : this.filterPort.FilterType === '' || this.filterPort.PortId === '' || this.filterPort.PortCode === ''
          ? null 
          : item?.CargoJson
            ? item?.CargoJson[0][this.filterPort.FilterType] != this.filterPort.PortId && this.removeContainer
            : this.removeContainer,
      "remove-position": this.isHover(item) && this.removeContainer ,
      "restow-border": this.hasRestow(item) ? true : null,
      "visit-free-border": this.visit && this.hasFreePosition(item) ? true : null,
    };
  }

  function statusIcon(item) {
    switch (item.YardSlotStatusNameEn) {
      case 'ACTIVO':
        return '';
      case 'DISABLED':
        return 'disabled-position';
      case 'NOT EXIST':
        return '';
      case 'REEFER PLUG':
        return 'reefer-plug-status';
      case 'AVAILABLE HAZARD':
        return 'hazard-available';
      case 'HAZARD RESTRICTION':
        return 'hazard-restriction';
      case 'SPECIAL CONTAINER':
        return 'special-container';
      case 'OCCUPIED':
        return '';
      default:
        return '';
    }
  }
  function validMandatory(condition, item) {

    if(this.bayView)
      return false;
    
    switch (condition) {
      case 1:
        if(this.filterPort.FilterType == '' || this.filterPort.PortId === '' || this.filterPort.PortCode === '')
          return false;
  
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0][this.filterPort.FilterType] === this.filterPort.PortId;
      case 2:
        return item?.YardSlotStatusId === '679D3BBC-214C-4DC7-9151-639F86570DC1';
      case 3:
        return item?.YardSlotStatusId === '4A1F6282-9299-4060-88D8-F4C1FF333CF1';
      case 4:
        return item?.YardSlotStatusId === 'A38CAAF3-8337-4D98-9826-3ADEBBCE0D69';
      case 5:
        return item?.YardSlotStatusId === '2B7CB51D-4A12-43F5-B0C2-47F9EE885057' && this.viewElement == '';
      case 6:
        return item?.YardSlotStatusId === '25885CF3-1524-4869-A496-1F0A3E6E61EF';
      case 7:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].SizeId == "857C1454-2883-4C41-8AF6-66D278DC4435";
      case 8:
        return item?.YardSlotStatusId === 'BE21EA3C-5D4E-45C4-A035-913442CFD734';
      case 9:
        return item?.YardSlotStatusId === '611ACE23-016F-46B7-ACA2-E23C27F253AB';
      case 10: return false;
      case 11:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].TpCargoStatusId === 'F26262C7-2080-4581-8E8F-F3723C66DB96';
      case 12:
        return item?.CargoJson && item?.CargoJson?.length != 0 && (item?.CargoJson[0].TpCargoId === '6C6CE673-3797-4D11-839B-06C228D51CEF' || item.CargoJson[0].TpCargoId === '40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5') && item.CargoJson[0].SizeId != "29ADF700-DA69-47B2-95CB-2C98A9D95486";
      case 13:
        return (item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].TpCargoId === '40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5') || (this.cargoView &&  item.YardSlotStatusId === '3CC91CC6-DDF9-4BE0-AEDA-A3BE65548BDE');
      case 14:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].DimTop;
      case 15:
        return this.byBay ? !item.beforeOversize && item?.DimRight : (item?.CargoJson && item?.CargoJson?.length != 0 && item.CargoJson[0].DimRight);
      case 16:
        return this.byBay ? item.beforeOversize && item?.DimLeft : (item?.CargoJson && item?.CargoJson?.length != 0 && item.CargoJson[0].DimLeft);
      case 17:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].DimFront;
      case 18:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].DimBack;
      case 19:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].ImdgClassId;
      case 20:
        return this.cargoView && item?.CargoJson?.length != 0 && item?.CargoJson && item?.CargoJson[0].TpCargoId == "1C76345A-8DCC-4CDC-B4F0-1FE85A73916C"; 
      case 21:
        return this.cargoView && item?.CargoJson?.length != 0 && item?.CargoJson && item?.CargoJson[0].TpCargoId == "53F364E6-E4BA-4356-B1CE-B7BBF87B612D";
      case 22:
        return this.cargoView && item?.CargoJson?.length != 0 && item?.CargoJson && item?.CargoJson[0].TpCargoId == "9E152C00-1F6A-47AD-85F0-5D9EC5EDE5AB";
      case 23:
        return this.cargoView && item?.CargoJson?.length != 0 && item?.CargoJson && item?.CargoJson[0].TpCargoId == "3D8A2E52-A132-4379-A769-7950F0B9A967";
      case 24:
        return item?.CargoJson && item?.CargoJson?.length != 0 && item?.CargoJson[0].SizeId == "29ADF700-DA69-47B2-95CB-2C98A9D95486";
      default:
        return false;
    }
  }
  function getCharacterSvg(character) {
    return GenerateSvgPlaceholder({
      width: 40,
      height: 40,
      bgColor: 'transparent',
      textColor: '#3c4b64',
      fontSize: 30,
      fontFamilty: 'Roboto',
      text: `${character}`
    });
  }
  function getCharacterSvgValidate(value) {

    return GenerateSvgPlaceholder({
      width: 40,
      height: 40,
      bgColor: 'transparent',
      textColor: '#3c4b64',
      fontSize: 30,
      fontFamilty: 'Roboto',
      text: `${value}`
    });
  }

  function validBayView(condition, item) {
    if(!this.bayView)
      return false;

    if(item?.CargoJson && item?.CargoJson?.length>0 || item.beforeOversize === true || item.beforeOversize === false )
    switch (condition) {
      case 'occupiedBy40':
        return item.YardSlotStatusId === '36321FE6-11B8-4BDD-80D9-784796CA3A9C' || item.YardSlotStatusId === '25885CF3-1524-4869-A496-1F0A3E6E61EF';
      case 'occupiedOversize':
        return item.YardSlotStatusId === '679D3BBC-214C-4DC7-9151-639F86570DC1' || item.YardSlotStatusId === 'BE21EA3C-5D4E-45C4-A035-913442CFD734';
      case 'load':
        return item?.CargoJson && item.CargoJson[0]?.LoadPortCode;
      case 'discharge':
        return item?.CargoJson && item?.CargoJson[0]?.DischargePortCode;
      case 'containerCode':
        return item?.CargoJson && item?.CargoJson[0]?.ContainerCode;
      case 'carrier':
        return item?.CargoJson && item?.CargoJson[0]?.ShippingLineCode;
      case 'weight':
        return item?.CargoJson && item?.CargoJson[0]?.Weigth;
      case 'type':
        return item?.CargoJson && item?.CargoJson[0]?.TpCargoDetailCode && item?.CargoJson[0]?.Size;
      case 'slot':
        return item?.SlotCode && item.SlotCode != '' && item.YardSlotStatusId != "A782166F-3BFF-4E95-8C68-982815042857"  && item.YardSlotStatus != "A889E5F6-57BA-4FBA-B7D3-2AD8BD8A0D17";
      case 'temperature':
        return item?.CargoJson && item?.CargoJson[0]?.ReeferSetting;
      case 'hazard':
        return item?.CargoJson && item?.CargoJson[0]?.Class;
      case 'DimTop':
        return item?.CargoJson && item?.CargoJson[0]?.DimTop;
      case 'DimRight':
        return this.byBay ? !item.beforeOversize && item.DimRight : item?.CargoJson && item?.CargoJson[0]?.DimRight;
      case 'DimLeft':
        return this.byBay ? item.beforeOversize && item.DimLeft : item?.CargoJson && item?.CargoJson[0]?.DimLeft;
      case 'DimFront':
        return item?.CargoJson && item?.CargoJson[0]?.DimFront;
      case 'DimBack':
        return item?.CargoJson && item?.CargoJson[0]?.DimBack;
      default:
        return false;
    }
  }
  function printBayData(condition, item) {
    if(!this.bayView)
      return false;

    
  
    switch (condition) {
      case 'occupiedBy40':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            width: 80,
            height: 40,
            bgColor: 'transparent',
            textColor: '#000',
            text: '++++'
          }) : '';
      case 'load':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4,
            width: 30,
            height: 14,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: item?.CargoJson[0]?.LoadPortCode,
            textAnchor: 'start'
          }) : '';
      case 'discharge':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4,
            width: 30,
            height: 14,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: item?.CargoJson[0].DischargePortCode,
            textAnchor: 'end'
          }) : '';
      case 'containerCode':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 6,
            width: 120,
            height: 18,
            fontSize: 18,
            bgColor: 'transparent',
            textColor: '#000',
            text: item?.CargoJson[0].ContainerCode,
            textAnchor: 'start'
          }) : '';
      case 'carrier':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4,
            width: 30,
            height: 14,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: item?.CargoJson[0].ShippingLineCode,
            textAnchor: 'start'
          }) : '';
      case 'weight':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4,
            width: 46,
            height: 14,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: item?.CargoJson[0].Weigth,
            textAnchor: 'end'
          }) : '';
      case 'type':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4,
            width: 30,
            height: 14,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: `${item?.CargoJson[0].TpCargoDetailCode}`,
            textAnchor: 'end'
          }) : '';
      case 'slot':
        let slotCode = item?.CargoJson
          && item.SlotCode;
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 9,
            width: 120,
            height: 25,
            fontSize: 24,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: slotCode,
            textAnchor: 'end'
          }) : '';
      case 'temperature':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 10,
            width: 220,
            height: 42,
            fontSize: 30,
            bgColor: 'transparent',
            textColor: '#000',
            fontWeight: 'normal',
            text: `${item?.CargoJson[0].ReeferSetting}`,
            textAnchor: 'start'
          }) : '';
      case 'hazard':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 4.5,
            width: 14,
            height: 12,
            fontSize: 12,
            bgColor: 'transparent',
            textColor: 'red',
            fontWeight: 'normal',
            text: item?.CargoJson[0].Class,
          }) : '';
      case 'DimTop':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 8,
            width: 80,
            height: 40,
            fontSize: 20,
            bgColor: 'transparent',
            textColor: '#000',
            text: `${item?.CargoJson[0].DimTop}${item?.CargoJson[0].UnitMeasureDimAbbrev}`
          }) : '';
      case 'DimRight':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 8,
            width: 80,
            height: 40,
            fontSize: 20,
            bgColor: 'transparent',
            textColor: '#000',
            text: `${ this.byBay ? item.DimRight : item?.CargoJson[0].DimRight}${this.byBay ? item.UnitMeasureDimAbbrev : item.CargoJson[0].UnitMeasureDimAbbrev}`
          }) : '';
      case 'DimLeft':
        return this.validBayView(condition, item)
          ? GenerateSvgPlaceholder({
            dy: 8,
            width: 80,
            height: 40,
            fontSize: 20,
            bgColor: 'transparent',
            textColor: '#000',
            text: `${this.byBay ? item.DimLeft : item?.CargoJson[0].DimLeft }${ this.byBay ? item.UnitMeasureDimAbbrev  : item.CargoJson[0].UnitMeasureDimAbbrev}`
          }) : '';
      default:
        return '';
    }
  }
  
  function colorPreference(item) {
    let color = 'transparent';
    let arr = this.allColorPreferences;
    if(arr.length == 0 || item?.CargoJson == null)
      return color;
  
    for (let index = 0; index < arr.length; index++) {
      let element = arr[index];
      if(item?.CargoJson?.length>0 && element[element.PreferenceElement] == item?.CargoJson[0][element.PreferenceElement] ) {
        color = element.Color;
        break
      } 
    }
  
    return color;
  }
  function tooltipCode(item) {
    return  item.SlotCode;
  }
  
  function printCode(item) {
    let text = item?.CargoJson[0][this.viewElement] ? item?.CargoJson[0][this.viewElement] : '';
    return GenerateSvgPlaceholder({
      width: 40,
      height: 40,
      bgColor: 'transparent',
      textColor: '#3c4b64',
      fontSize: 30,
      fontWeight: '400',
      // fontFamilty: 'Roboto',
      text: `${text}`
    });
  }
  function iconPreference(item) {
    let name = null;
    let arr = this.preferences;

  
    if(arr.length == 0 || item?.CargoJson == null)
      return name;
  
    for (let index = 0; index < arr.length; index++) {
      let element = arr[index];
      
      if(item?.CargoJson?.length>0 && element[element.PreferenceElement] == item?.CargoJson[0][element.PreferenceElement] ) {
        name = slugify(element.Name);
        break;
      } 
    }
  
    return name;
  }
  function imgPreference(item) {
    let imgs = ['st-estandar'];
    let arr = this.preferences;


    if(arr.length == 0 || item?.CargoJson == null)
      return false;
  
    for (let index = 0; index < arr.length; index++) {
      let element = arr[index];
      
      if(item?.CargoJson?.length>0 &&  element[element.PreferenceElement] == item?.CargoJson[0][element.PreferenceElement] && imgs.includes(slugify(element.Name))) {
        return true;
      } 
    }
  
    return false;
  }
  function bgByPort(item) {
    return { "bg-gris": !this.obligatory && !this.bayView && !this.cargoView
      ? null 
      : this.filterPort.FilterType === '' || this.filterPort.PortId === '' || this.filterPort.PortCode === ''
        ? null 
        : item?.CargoJson && item?.CargoJson[0][this.filterPort.FilterType] != this.filterPort.PortId,
    }
  }
  
  function loadEnter(item) {
    this.currentPosition = {...item};
  }
  function loadLeave() {
    this.currentPosition = null;
  }
  
  const validPositions = [
      'F24C3746-87C0-4CE9-B5E1-7B6AB848F4EA', //Free
      '4A1F6282-9299-4060-88D8-F4C1FF333CF1', // Hazard Available
      '3CC91CC6-DDF9-4BE0-AEDA-A3BE65548BDE' // Reefer Plug
  ];
  function canLoad(item) {
    if(!this.container) return false;
  
    //Si esta permitido cargar
    if(validPositions.includes(item.YardSlotStatusId)) return true;
  
    //Hazard Restriction:
    if(item.YardSlotStatusId === 'A38CAAF3-8337-4D98-9826-3ADEBBCE0D69' && !this.container.ImdgCode) return true;
    if(item.YardSlotStatusId === 'A38CAAF3-8337-4D98-9826-3ADEBBCE0D69' && this.container.ImdgCode) return false;
  
    if(item.YardSlotStatusId == '679D3BBC-214C-4DC7-9151-639F86570DC1' || item.YardSlotStatusId == 'BE21EA3C-5D4E-45C4-A035-913442CFD734' || item.YardSlotStatusId == '36321FE6-11B8-4BDD-80D9-784796CA3A9C') return false;
  
    return false;
  }
  function isAllowed(item) {
    if(this.baySlot && item.StowagePlanningCargoId == this.baySlot) {
      return 'not-allowed';
    }
  
    if(this.removeContainer && item?.CargoJson && this.filterPort.PortId == item?.CargoJson[0].LoadPortId) {
      return 'not-allowed';
    }
  
    if(!this.container) return '';
  
    //Si esta permitido cargar.
    if(validPositions.includes(item.YardSlotStatusId)) return '';
  
    //Hazard Restriction:
    if(item.YardSlotStatusId === 'A38CAAF3-8337-4D98-9826-3ADEBBCE0D69' && !this.container.ImdgCode) return '';
    if(item.YardSlotStatusId === 'A38CAAF3-8337-4D98-9826-3ADEBBCE0D69' && this.container.ImdgCode) return 'invalid';
  
    if((item.YardSlotStatusId == '679D3BBC-214C-4DC7-9151-639F86570DC1' || item.YardSlotStatusId == 'BE21EA3C-5D4E-45C4-A035-913442CFD734' || item.YardSlotStatusId == '36321FE6-11B8-4BDD-80D9-784796CA3A9C') ) return 'invalid';
    else return 'not-allowed'
  }
  function isHover(item) {
    if(!this.cargoView)
      return null;
      
    if(this.currentPosition && this.currentPosition.SlotCode == item.SlotCode)
      return true;
      
    return null;
  }
  function handleAlert(type, item) {
    this.$emit('handle-alert', {
      position: item,
      type: type == 1 ? 'warning' : 'error',
    });
  }
  function hasWarnings(item) {
    if(!this.showAlerts) return false;
    if(!this.visit) {
      if(!item?.CargoJson || this.alerts.length == 0) return false;
    
      let found = this.alerts.findIndex((alert) => alert.VesselBayPosId == item.VesselBayPosId && alert.StowagePlanningCargoId == item.StowagePlanningCargoId);
    
      return found != -1 ?  true : false;
    } else {
      if(!item?.CargoJson || this.freePositions) return false;
  
      return item?.CargoJson[0].AlertJson.length > 0 ?  true : false;
    }
  }
  function hasErrors(item) {
    if(!this.showErrors) return false;
    if(!this.visit) {
      if(!item?.CargoJson || this.errors.length == 0) return false;
      
      let found = this.errors.findIndex((error) => error.VesselBayPosId == item.VesselBayPosId && error.StowagePlanningCargoId == item.StowagePlanningCargoId);
    
      return found != -1 ?  true : false;
    } else {
      if(!item?.CargoJson || this.freePositions) return false;
  
      return item?.CargoJson[0].ErrorJson.length > 0 ?  true : false;
    }
  }
  function hasRestow(item, type = '') {
  
    if(type == ''){
      return item.CodPositionLabel == this.restowPositions.arrival || item.CodPositionLabel == this.restowPositions.departure;
    }
  
    if(type == 'A'){
      return item.CodPositionLabel == this.restowPositions.arrival;
    }
    if(type == 'D'){
      return item.CodPositionLabel == this.restowPositions.departure;
    }
  }

  function hasFreePosition(item) {
    if(this.visitList.length == 0)
      return false;
  
    let found = this.visitList.findIndex((bay) => bay.VesselBayPosId == item.VesselBayPosId);
  
    return (found != -1);
  }

  function containerItemStyle(item) {
    if(!this.visit){
      let estilo = {
        backgroundColor: !this.statusCondition ? '#9e9e9e':''
      };
      return {...estilo, ...this.itemStyles};
    }
      
  
    if(this.visitPort.FilterType === '' || this.visitPort.PortId === '' || this.visitPort.PortCode === '')
      return this.itemStyles;

    else {
      if(item?.CargoJson && item?.CargoJson[0][this.visitPort.FilterType] == this.visitPort.PortId) {
        let estilo = {
          border: `3px solid ${this.visitPort.Color} !important`,
        };
      
        return {...estilo, ...this.itemStyles};
      }else{
        return this.itemStyles;
      }
       
    }
  }
  
  //Computed
  function layoutItems() {
    return this.layout;
  }
  function colNumbers() {
    let arr = [];

    let length = 0;
    let posi = 0;
    
    this.layout?.map((item, index) =>{
      if(length < item?.PositionJson?.length){
        length = item?.PositionJson?.length;
        posi = index;
      }
    });

    for (let index = 0; index < this.layout[posi]?.PositionJson?.length; index++) {
      arr.push({
        SlotCode: this.layout[posi]?.PositionJson[index].SlotCode,
        StackCode: this.layout[posi]?.PositionJson[index].StackCode
,
      });
    }

    return arr || [];
  }

  function itemStyles() {
    if(this.responsive && this.maxCol > this.col) {
      let quantity = this.showNumbers ? (this.maxCol + 2) : this.maxCol;
      return { 
        width: `calc( calc(100% / ${quantity}) - ${this.margin*2}px )`,
        maxWidth: this.maxSize > 50 ? `${this.maxSize}px` : '50px',
        margin: `${this.margin}px`,
      };
    }
  
    if(this.responsive){
      let quantity = this.showNumbers ? (this.col + 2) : this.col;
      quantity = quantity;
      return {
        width: `calc( calc(100% / ${quantity}) - ${this.margin*2}px )`,
        maxWidth: this.maxSize > 50 ? `${this.maxSize}px` : this.bayFilter ? '90px' : '50px',
        margin: `${this.margin}px`,
      };
    }

    return {
      width: `${this.itemWidth}px`, height: `${this.itemHeight}px`,
      maxWidth: this.maxSize > 50 ? `${this.maxSize}px` : '50px',
      margin: `${this.margin}px`,
    };
  }
  function numbersColClasses() {
    return {
      "item-number-cols-dashed": this.showDashedNumbers && !this.bayFilter ? true : null,
      "col-item-responsive": this.responsive ? true : null,
      "cursor-container-selected": null,
    };
  }
  function titleColClasses() {
    return {
      "col-item-responsive": this.responsive ? true : null,
      "cursor-container-selected": null
    };
  }

  function numbersRowClasses() {
    return {
      "item-number-rows-dashed": this.showDashedNumbers && !this.bayFilter ? true : null,
      "cursor-container-selected": null,
    };
  }
  function allColorPreferences() {
    return this.preferences.filter((item) => item.FgColor == true);
  }

  function hasSearchCode(item) {
    return item?.CargoJson && item?.CargoJson?.length > 0 ?
      this.searchCode == item?.CargoJson[0].ContainerCode ? true : false
        : false;
  }
  
  export default {
    name: "bay-grid",
    props: {
      modal: Boolean,
      //Generar Matriz
      col: {
        type: Number,
        default: 12,
        require: true,
      },
      row: {
        type: Number,
        default: 12,
        require: true,
      },
      items: {
        type: Array,
        default: [],
      },
      itemWidth: {
        type: Number,
        default: 40,
      },
      itemHeight: {
        type: Number,
        default: 40,
      },
      maxSize: {
        type: Number,
        default: 50
      },
      margin: {
        type: Number,
        default: 2.5
      },
      disabledSelect: {
        type: Boolean,
        default: false,
      },
      //Para el Status de cada item.
      showStatusView: {
        type: Boolean,
        default: false
      },
      showStatus: {
        type: Boolean,
        default: false,
      },
      currentStatus: {
        type: Object,
        default: () => {
          return { YardSlotStatusId: "", YardSlotStatusName: "" };
        },
      },
      tooltip: {
        type: Boolean,
        default: true,
      },
      //Iconos de las vista
      obligatory: {
        type: Boolean,
        default: false
      },
      bayView: {
        type: Boolean,
        default: false
      },
      restowPositions: {
        type: Object,
        default: () => {
          return {
            arrival: '',
            departure: '',
          }
        }
      },
      removeContainer: {
        type: Boolean,
        default: false,
      },
      // Filtros
      filterPort: {
        type: Object,
        default: () => {
          return {
            FilterType: '',
            PortId: '',
            PortCode: ''
          }
        },
      },
      matching: {
        type: Array,
        default: () => []
      },
      preferences: {
        type: Array,
        default: () => []
      },
      viewElement: {
        type: String,
        default: ''
      },
      //Para activar responsive.
      responsive: {
        type: Boolean,
        default: true,
      },
      maxCol: {
        type: Number,
        default: 0
      },
      //Para mostrar los numeros de la fila y columna
      showNumbers: {
        type: Boolean,
        default: true,
      },
      showNumbersBottom: {
        type: Boolean,
        default: false,
      },
      showDashedNumbers: {
        type: Boolean,
        default: true,
      },
      numberSize: {
        type: Number,
        default: 60,
      },
      // Carga
      cargoView: {
        type: Boolean,
        default: false
      },
      container: {
        type: Object,
        default: null
      },
      baySlot: {
        type: String,
        default: '',
      },
      // Errores y Alertas - Load View
      showErrors: {
        type: Boolean,
        default: false
      },
      showAlerts: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Array,
        default: () => []
      },
      alerts: {
        type: Array,
        default: () => []
      },
      // Ajuste de Plano
      visit: {
        type: Boolean,
        default: false
      },
      visitType: {
        type: String,
        default: ''
      },
      selectedPosition: {
        type: Object,
        default: null
      },
      visitList: {
        type: Array,
        default: () => []
      },
      listType: {
        type: Number,
        default: 0
      },
      statusCondition: {
        type: Boolean,
        default: false
      },
      bayCode: {
        type: String,
        default: ''
      },
      searchCode: {
        type: String,
        default: ''
      },
      bayFilter: {
        type: Boolean,
        default: false
      },
      byBay: {
        type: Boolean,
        default: false
      },
      visitPort: {
        type: Object,
        default: () => {
          return {
            FilterType: '',
            PortId: '',
            PortCode: '',
            Color: '',
          }
        }
      },
      freePositions: {
        type: Boolean,
        default: false
      },
    },
    components: {
    },
    data,
    created,
    methods: {
      generateLayout,
      selectPosition,
      itemClasses,
      statusIcon,
      validMandatory,
      getCharacterSvg,
      validBayView,
      printBayData,
      colorPreference,
      tooltipCode,
      printCode,
      iconPreference,
      bgByPort,
      loadEnter,
      loadLeave,
      canLoad,
      isAllowed,
      isHover,
      hasErrors,
      hasWarnings,
      handleAlert,
      imgPreference,
      hasRestow,
      hasFreePosition,
      containerItemStyle,
      getCharacterSvgValidate,
      hasSearchCode
    },
    computed: {
      layoutItems,
      colNumbers,
      itemStyles,
      numbersColClasses,
      titleColClasses,
      numbersRowClasses,
      allColorPreferences,
    },
    watch: {
      items: function (newVal) {
        if (Array.isArray(newVal) && newVal.length != 0) {
          this.generateLayout();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .bay-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row-item {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
  }
  
  .col-item {
    position: relative;
    line-height: 1;
    max-width: 40px;
    background-color: #fff;
  
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    margin: 2.5px;
    border: 1px solid #000;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .col-item-responsive::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  
  .item-number-icon { /* Center and .svg */
    position: absolute;
    width: 70%;
    height: 70%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .item-number-cols {
    margin: 0 2.5px;
    border: none;
    cursor: default;
  }
  .item-number-rows {
    margin: 2.5px 0;
    border: none;
    cursor: default;
  }
  .item-number-corner {
    margin: 0;
    border: none;
    cursor: default;
  }
  .item-number-cols-dashed {
    border-left: 1px dashed #322f32;
    border-right: 1px dashed #322f32;
  }
  .item-number-rows-dashed {
    border-top: 1px dashed #322f32;
    border-bottom: 1px dashed #322f32;
  }
  
  /* Iconos en Bay Buque */
  .item-not-exist {
    background: transparent;
    border: none;
    color: transparent;
  }
  .item-disabled {
    background: #9e9e9e;
    border-color: #9e9e9e;
    color: #fff;
  }
  .item-occupied {
    background: #ec631b;
    border-color: #9e9e9e;
    color: #fff;
  }
  .item-plug {
    background: lightcoral;
    border-color: lightcoral;
    color: #fff;
  }
  .item-inactive {
    background: #eeeded;
    border-color: #eeeded;
  }
  
  /* Iconos del contenedor */
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .flat-rack,
  .ft-flat-rack {
    width: 30%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 30%;
    height: 30%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 20%;
    height: 20%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 0 2px 2px;
  }
  .oversize-top {
    width: 100%;
    height: 50%;
    border: 1px solid #555;
    background: #555;
    -moz-border-radius: 100px 100px 0 0;
    -webkit-border-radius: 100px 100px 0 0;
    border-radius: 100px 100px 0 0;
    position: absolute;
    top: -50%;
    z-index: 1;
  }
  .oversize-right {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 0 100px 100px 0;
    position: absolute;
    right: 50%;
    z-index: 1;
  }
  .oversize-left {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 100px 0 0 100px;
    position: absolute;
    left: 50%;
    z-index: 1;
  }
  .oversize-back {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
  }
  .oversize-front {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    top: 0;
  }
  
  .empty {
    /* background-image: url('/img/iconos/Empty.svg'); */
    position: absolute;
    width: 25%;
    height: 25%;
    top: 4%;
    left: 75%;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar {
    background-image: url('/img/iconos/Standard.svg'); 
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    left: 0;
    /* margin-top: 2px; */
  }
  .hazard-available {
    background-image: url('/img/iconos/AvailableHazard.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .hazard-restriction {
    background-image: url('/img/iconos/HazardRestriction.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .hazard-available-plan {
    /* background-image: url('/img/iconos/AvailableHazard.svg'); */
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .hazard-restriction-plan {
    /* background-image: url('/img/iconos/HazardRestriction.svg'); */
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .reefer-plug {
    /* background-image: url('/img/iconos/ReeferPlug.svg'); */
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .reefer-plug-status {
    background-image: url('/img/iconos/ReeferPlug.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-40 {
    /* background-image: url('/img/iconos/OccupiedBy40.svg'); */
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-45 {
    /* background-image: url('/img/iconos/Plus45_1.svg'); */
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-oversize {
    /* background-image: url('/img/iconos/OccupiedOversize.svg'); */
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .restow {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    top: 0;
    /* margin: 0 0 2px 2px; */
  }
  .bg-gris {
    background-color: #aaaaaa !important;
  }
  
  /* Clases para cuando se esta eliminando un contenedor del bay */
  .no-pointer {
    cursor: default;
  }
  .remove-position {
    border: 3px solid #111635;
  }
  
  /* Para showStatus */
  .disabled-position {
    background-image: url('/img/iconos/Disabled.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .special-container {
    background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  /* Positioning */
  .corner-top-icon { /* Right Top corner .svg */
    position: absolute;
    width: 30%;
    height: 30%;
    top: 0;
    right: 0;
  }
  .center-icon { /* Center a.svg */
  
    position: absolute;
    width: 45%;
    height: 45%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .bg-position {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .5s ease-in-out;
  }
  
  /* Load View */
  .bg-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
  }
  .bg-position-select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .1.7s ease-in-out;
  }
  .bg-position-select.not-allowed {
    background-color: #ec631d;
  }
  .bg-position-select.allowed {
    background-color: #00e676;
  }
  .bg-position-select.invalid {
    background-image: url('/img/iconos/ban-solid.svg');
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    -moz-transform: translate(-50%, -50%) rotate(90deg);
    -ms-transform: translate(-50%, -50%) rotate(90deg);
    -o-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .bg-position-select.asignada,
  .position-asign {
    border: 3px solid #3f51b5;
  }
  .cursor-load {
    cursor: url('/img/iconos/cursorLoad.png') 14 0, pointer;
  }
  .cursor-container-selected {
    cursor: url('/img/iconos/cursorContainer.png'), auto;
  }
  
  .tilt-animation {
    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  
    -webkit-animation-name:parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }
  @-moz-keyframes parpadeo{  
    0% { opacity: 1.0; }
    50% { opacity: 0.3; }
    100% { opacity: 1.0; }
  }
  @-webkit-keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.3; }
     100% { opacity: 1.0; }
  }
  @keyframes parpadeo {  
    0% { opacity: 1.0; }
     50% { opacity: 0.3; }
    100% { opacity: 1.0; }
  }
  
  /* Clases para Bay View */
  .load-port {
    position: absolute;
    width: 38%;
    top: 0;
    left: 0;
  }
  .discharge-port {
    position: absolute;
    width: 38%;
    top: 0;
    right: 0;
  }
  .container-code {
    position: absolute;
    width: 100%;
    top: 18%;
    left: 0;
  }
  .carrier {
    position: absolute;
    width: 40%;
    top: 32%;
    left: 0;
  }
  .weight {
    position: absolute;
    width: 60%;
    top: 32%;
    right: 0;
  }
  .type {
    position: absolute;
    width: 40%;
    top: 50%;
    right: 0;
  }
  .slot {
    position: absolute;
    width: 80%;
    bottom: 0;
    right: 0;
  }
  .temperature {
    position: absolute;
    width: 100%;
    top: 68%;
    left: 0;
  }
  .hazard-code {
    position: absolute;
    width: 20%;
    bottom: 0;
    left: 0;
  }
  .dim-top {
    position: absolute;
    width: 50%;
    height: 50%;
    top: -25%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .dim-right {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    right: 20%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .dim-left {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 80%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .bg-oversize {
    background: #f5f5f5;
  }
  .occupied-oversize-smaller {
    background-image: url('/img/iconos/OccupiedOversize.svg');
    position: absolute;
    width: 30%;
    height: 30%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .oversize-transparency {
    opacity: 0.5;
  }
  
  /* Tooltip */
  .sigeop-tooltip {
    visibility: hidden;
    /* font-size: small; */
    text-align: center;
    color: #fff;
    background-color: #000015;
    border-radius: .25rem;
  
    padding: .25rem .5rem;
    margin-bottom: 7px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 100;
  
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .sigeop-tooltip::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: calc(50% - 5px);
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
  .col-item:hover .sigeop-tooltip {
    visibility: visible;
  }
  .tilt-animation span.sigeop-tooltip {
    opacity: 1;
  }
  
  /* Para las alertas y errores */
  .alerts-icon,
  .errors-icon {
    width: 40%;
    cursor: pointer;
    z-index: 2;
  }
  .bg-semi-transparente {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    z-index: 1;
  }
  
  /* Restow */
  .bg-restows {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 1);
    z-index: 1;
  }
  .restow-letter,
  .visit-letter {
    width: 70%;
    z-index: 2;
  }
  .restow-border {
    border: 3px solid #111635;
  }
  
  /* Idk xD */
  .rombito {
    position: absolute;
    width: 15%;
    height: 15%;
    top: 0;
    right: 0;
    margin: 4px;
    border: 1px solid #555;
    transform: rotate(45deg);
  }
  .bg-position-selected {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #c3d4ec;
    z-index: 1;
  }
  .visit-border {
    border: 3px solid #132379;
  }
  
  .bg-free-position-selected {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(240, 28, 39, 0.5);
    z-index: 1;
  }
  .visit-free-border {
    border: 3px solid #e1373f;
  }
  .visit-previous-border {
    /* border: 3px solid #111635; */
    border: 3px solid greenyellow;
  }
  .bg-unavailable-positions.not-allowed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #e7945e;
  }
  .border-search {
    /* border: 1px solid #ec631b; */
    background: repeating-linear-gradient( -45deg, #000, #000 2.5px, transparent 2.5px, transparent 5px );
  }
  </style>