<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />

    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CRow class="justify-content-center">
        <CCol sm="12">
          <div class="preview">    
            <picture-input
              v-if="showImage"
              ref="imageInput"
              width="400"
              height="400"
              accept="image/jpeg,image/png"
              size="40"
              :custom-strings="imgInputTexts"
              @change="handleFileUpload"
              :imgSrc="Route"
            >
            </picture-input>
          </div>
        </CCol>
        <div class="col-sm-8" v-if="$v.imageDamage.RouteImage.$error">
          <div class='text-invalid-feedback' v-if="!$v.imageDamage.RouteImage.isValidFile">{{$t('label.badImage')}}</div>
        </div>
      </CRow>

      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click.stop="submitImage"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';
import PictureInput from '@/components/picture-input';


function data() {
  return {
    Route: '/img/uploadPhotoEs.svg',
    showModal: false,
    showImage: false,
    titleModal: '',
    loadingOverlay: false,
    
    imageDamage: {
      VisitCargoInspDamageId: '',
      RouteImage: '',
    },

    items: [],
    VisitCargoInspDamageId: '',
    image: '',
    isSubmit: false,
  };
}

function closeModal() {
  this.$emit('child-refresh', true);
  this.refreshComponent();
  this.showModal=false;
}

function handleFileUpload(image) {
  this.imageDamage.RouteImage = this.$refs.imageInput.file;
  this.image = image
}

function submitImage() {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.imageDamage.$touch();

    if (this.$v.imageDamage.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('file1', this.imageDamage.RouteImage);
    this.$http.file('VisitInspection-file', this.imageDamage.RouteImage)
    .then((response) => {
      this.imageDamage.RouteImage = response.data.data.files[0].path;
      this.submit();
      this.loadingOverlay = false;
      this.isSubmit = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }); 
  } catch (e) {
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }
}

function submit() {
    this.loadingOverlay = true;
      this.isSubmit = true;

    let FilesJson = {
      VisitCargoInspDamageId: this.imageDamage.VisitCargoInspDamageId,
      FileValue: this.imageDamage.RouteImage,
    };

    this.$http.post('VisitCargoInspDamageFile-insert', FilesJson, { root: 'FilesJson' })
    .then(response => {
      if (response.status === (200 || 201)) {
        this.closeModal();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
      }
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }).then(() => {
      this.loadingOverlay = false;
      this.isSubmit = false;
    });
}
function refreshComponent() {
  this.imageDamage.VisitCargoInspDamageId = '';
  this.imageDamage.RouteImage = '';
  this.image = "";
  this.items = '';
  this.titleModal= '';
  this.loadingOverlay= false;
  this.isSubmit = false;
  this.items= [];
  this.VisitCargoInspDamageId = '';
  if (this.$refs.imageInput) {
    this.$refs.imageInput.removeImage();
    this.$refs.imageInput.value = null;
    this.$refs.imageInput.file = null;
  }
  this.$v.imageDamage.$reset(); 
}

export default {
  name: 'modal-add-damage-images',
  components: {
    PictureInput,
  },
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  validations:inspectionValidation,
  watch: {
    modal: async function () {
      if (this.modal) {
        let _this = this.$i18n.locale;
        this.Route = _this=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        this.showImage = true; 
        this.imageDamage.VisitCargoInspDamageId = this.modal.VisitCargoInspDamageId; 
        this.titleModal = this.modal.TpCargoViewName+' - '+this.modal.TpDamageAbbrev+': '+this.modal.TpDamageName;
        this.showModal = true; 
        this.VisitCargoInspDamageId = this.modal.VisitCargoInspDamageId;
        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    handleFileUpload,
    submit,
    submitImage,
    refreshComponent,
  },
  computed: {
   
  },
};
</script>
<style lang="scss" scope>

</style>
