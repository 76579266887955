<template>
  <main>
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <form
      class="company-form"
      enctype="multipart/form-data"
      @submit.prevent="submitBody"
      method="post"
    >
      <CModalExtended
        :title="$t('label.nuevo')+$t('label.module')"
        color="dark"
        :show.sync="modalActive"
        size="xl"
        :closeOnBackdrop="false"
        >
        <CCardBody>
          <CRow>
            <CCol sm="6">
              <CInput
                :label="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' I)'"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right"
                v-model="GpoModuleDsNivel1"
                v-uppercase
                class="ml-1"
                disabled
                size="sm"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                :label="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' II)'"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right"
                v-model="GpoModuleDsNivel2"
                v-uppercase
                disabled
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CInput
                :label="$t('label.moduleType')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right"
                v-model="TpModuleName"
                v-uppercase
                disabled
                size="sm"
              >
              <template #append >
                <CButton
                  shape="square"
                  color="primary"
                  class="ml-2 rounded buttonClassModule"
                  size="sm"
                  v-c-tooltip="{placement:'top-end',content:$t('label.nuevo') + $t('label.module') }"
                  @click="collapse = !collapse"
                >
                  <CIcon :name="cambiaIcono" />
                </CButton>
              </template>
            </CInput>
            <CElementCover :opacity="0.8" v-show="loadingTM">
                <label class="d-inline">{{$t('label.load')}}... </label>
                <CSpinner size="sm" />
              </CElementCover>
            </CCol>
          </CRow>
          <addTpModuleCollapse
            :collapse="collapse"
            @close-collapse="updateCollapse"
            @update-data-modulo="insertTpModulo"
          />
          <!-- ### Process Business ###    -->
          <CRow>
            <CCol sm="12">
              <CSelect
                :label="$t('label.businessProcess')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.businessProcess')"
                :options="processBusiness"
                :invalid-feedback="$t('label.required')"
                :is-valid="hasError($v.ProcessId)"
                @change="insertPB($event)"
                :value="ProcessId"
                size="sm"
              />
            </CCol>
          </CRow>
          <!-- ### MASTER DIRECTORY ###    -->
          <CRow>
            <CCol sm="12">
              <CSelect
                :label="$t('label.masterDirectory')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.masterDirectory')"
                :options="masterDirectory"
                :invalid-feedback="$t('label.required')"
                :is-valid="hasError($v.DirectoryId)"
                @change="insertMD($event)"
                :value="DirectoryId"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                :label="$t('label.name')+' '+('(ES)')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.menuIdentifier')+' '+('(ES)')"
                v-model.trim="$v.ModuleNameEs.$model"
                v-uppercase
                class= "ml-1"
                :is-valid="hasError($v.ModuleNameEs)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                size="sm"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                :label="$t('label.name')+' '+('(EN)')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.menuIdentifier')+' '+('(EN)')"
                v-model.trim="$v.ModuleNameEn.$model"
                v-uppercase
                :is-valid="hasError($v.ModuleNameEn)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                size="sm"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                :label="$t('label.order')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.order')"
                v-model.trim="$v.Order.$model"
                :is-valid="hasError($v.Order)"
                @keypress="f_num($event)"
                class="ml-1"
                :invalid-feedback="$t('label.required')+$t('label.onlyNumber')"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CTextarea
                :label="$t('label.description')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right required"
                v-uppercase
                :placeholder="$t('label.interfaceAction')"
                v-model.trim="$v.ModuleDs.$model"
                :is-valid="hasError($v.ModuleDs)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInputFile
                :label="$t('label.proceduralManual')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.proceduralManual')"
                @change="addfilePathMP"
                class="ml-1"
                ref="MPPath"
                id="ref-input-proceess-add"
                size="sm"
              />
            </CCol>
            <CCol sm="6">
              <CInputFile
                :label="$t('label.userManual')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.userManual')"
                @change="addfilePathMU"
                ref="MUPath"
                id="ref-input-manual-add"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mt-2">
              <CInput
                :label="$t('label.location')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.exampleLocation')"
                v-model="$v.ModuleRoute.$model"
                :is-valid="hasError($v.ModuleRoute)"
                class="ml-1"
                :invalid-feedback="$t('label.required')"
                size="sm"
              />
            </CCol>
            <CCol sm="6" class="mt-2">
              <CInput
                :label="$t('label.accessRoute')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right required"
                :placeholder="$t('label.exampleRoute')"
                v-model="$v.pathAuxiliar.$model"
                :is-valid="hasError($v.pathAuxiliar)"
                :invalid-feedback="$t('label.required')"
                size="sm"
              />
            </CCol>
          </CRow>
        </CCardBody>
        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            type="submit"
          >
            <CIcon name="checkAlt" /><span class="ml-1">{{$t('button.accept')}}</span>
          </CButton>
          <CButton
            square
            color="wipe"
            class="d-flex align-items-center"
            @click="toggle(false)"
          >
            <CIcon name="x" /><span class="ml-1"
              >{{$t('button.cancel')}}</span
            >
          </CButton>
        </template>
      </CModalExtended>
    </form>
  </main>
</template>
<script>
import ModuleMixin from '@/_mixins/module';
import UpperCase from '@/_validations/uppercase-directive';
import { onlyMayusTextEspecial } from '@/_validations/validacionEspeciales';
import addTpModuleCollapse from './add-modulo-collapse';
import GeneralMixin from '@/_mixins/general';
import {
  required,
  maxLength,
  helpers,
  alphaNum,
} from 'vuelidate/lib/validators';

function toggle(newVal) {
  this.modalActive = newVal;
  this.resetBody();
}

function data() {
  return {
    routeDirectoryName: '',
    modalActive: this.modal,
    selectNivelIData: [],
    selectNivelIIData: [],
    selectTpModuloData: [],
    selectProcessBusiness: [],
    selectMasterDirectory: [],
    responsePostFile: {},
    TpModuleId: '',
    GpoModuleId: '',
    ModuleNameEs: '',
    ModuleNameEn: '',
    ModuleDs: '',
    ProcessId: '',
    DirectoryId: '',
    ProcessRoute: '',
    ManualRoute: '',
    ModuleRoute: '',
    Order: '',
    loadingTM: false,
    loadingLV2: false,
    apiStateLoading: false,
    listLv3Actually: this.listlv3,
    pathAuxiliar: '',
    collapse: false,
  };
}

function updateCollapse() {
  this.collapse = false;
}

function addfilePathMP(files) {
  const file = files[0];
  this.ProcessRoute = file; // Aqui iria el path para guardar la ruta del archivo.
}

function addfilePathMU(files) {
  const file = files[0];
  this.ManualRoute = file; // Aqui iria el path para guardar la ruta del archivo.
}

async function submitBody() {
  try {
  this.collapse = false;
  this.isSubmit = true;
  this.$v.$touch();
  this.apiStateLoading = true;

  if (this.$v.$error) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    throw this.$t('label.errorsPleaseCheck');
  }

  let processRoute = '';
  let manualRoute = '';

  if (this.ProcessRoute !== '' || this.ManualRoute !== '') {
    let formData = new FormData();
    formData.append('file1', this.ProcessRoute);
    formData.append('file2', this.ManualRoute);

    await this.$http
      .post(this.routeDirectoryName, formData)
      .then((response) => {
        if (response.status === (200 || 201)) {
          processRoute = response.data.data.files[0].path;
          manualRoute = response.data.data.files[1].path;
        }
      })
      .catch((e) => {
        this.apiStateLoading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  }

  let ModuleJson = {
    TpModuleId: this.TpModuleFilter,
    GpoModuleId: this.GpoModuleIdNivel2,
    DirectoryId: this.DirectoryId,
    ProcessId: this.ProcessId,
    ModuleNameEs: this.ModuleNameEs,
    ModuleNameEn: this.ModuleNameEn,
    ModuleDs: this.ModuleDs,
    ProcessRoute: processRoute,
    ManualRoute: manualRoute,
    ModuleRoute: this.ModuleRoute,
    Order: this.Order,
    Path: this.pathAuxiliar,
  };

  this.$http
    .post('Module-insert', ModuleJson, { root: 'ModuleJson' })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        const dataJson = response.data.data[0];
        const moduleObjectNew = dataJson;
        if (moduleObjectNew) {
          this.listlv3.push(moduleObjectNew);
          this.toggle(false);
          this.resetBody();
          this.resetSelects();
          this.$emit('update-data-modules');
          this.$notify({
            group: 'container',
            title: '¡Solicitud Exitosa!',
            text: messageSuccess,
            type: 'success',
          });
          this.apiStateLoading = false;
        } else {
          this.apiStateLoading = false;
          this.$notify({
            group: 'container',
            title: '¡Aviso!',
            text: messageSuccess,
            type: 'warn',
          });
        }
      } else {
        this.apiStateLoading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: response.data.data[0].Response,
          type: 'error',
        });
      }
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
  } catch (err) {
            this.isSubmit=false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }

}

function resetBody() {
  this.TpModuleId = '';
  this.GpoModuleId = '';
  this.ModuleNameEs = '';
  this.ModuleNameEn = '';
  this.ModuleDs = '';
  this.ProcessRoute = '';
  this.ManualRoute = '';
  this.$refs.MPPath.value = '';
  this.$refs.MUPath.value = '';
  //this.$refs.MPPath = null;
  //this.$refs.MUPath = null;
  this.ModuleRoute = '';
  this.Order = '';
  this.pathAuxiliar = '';
  this.ProcessId = '';
  this.DirectoryId = '';
  document.getElementById('ref-input-proceess-add').value = '';
  document.getElementById('ref-input-manual-add').value = '';
}

function resetSelects() {
  this.selectNivelIData = [];
  //this.selectNivelI = null;
  this.selectNivelIIData = [];
  this.GpoModuleId = null;
  //this.selectTpModuloData = [];
  this.TpModuleId = null;
  this.loadingLV2 = false;
  this.loadingTM = false;
  //this.insertTpModulo();
}

function selectTpModulo() {
  //let _lang = this.$i18n.locale;
  return this.selectTpModuloData.map((tpmodule) =>
    Object.assign({}, this.selectTpModuloData, {
      value: tpmodule.TpModuleId,
      label: tpmodule.TpModuleName,
      //label: _lang=='es' ? tpmodule.TpModuleName : tpmodule.GpoModuleName,
    })
  );
}

async function insertTpModulo() {
  this.apiStateLoading = true;
  await this.$http
    .get('TpModule-list?Filter=ACTIVO')
    .then((response) => {
      this.selectTpModuloData = response.data.data;
      this.apiStateLoading = false;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
  await this.insertProcessBusiness();
  await this.insertMasterDirectory();
}

function insertTp(event) {
  return (this.TpModuleId = event.target.value);
}

function processBusiness() {
  return this.selectProcessBusiness.map((processBusiness) =>
    Object.assign({}, this.selectProcessBusiness, {
      value: processBusiness.ProcessId,
      label: processBusiness.ProcessName,
    })
  );
}

function insertProcessBusiness() {
  this.$http
    .get('ProcessBusiness-list?Filter=ACTIVO')
    .then((response) => {
      this.selectProcessBusiness = response.data.data;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function insertPB() {
  return (this.ProcessId = event.target.value);
}

function insertMD() {
  this.selecDirectory(event.target.value);
  return (this.DirectoryId = event.target.value);
}

function insertMasterDirectory() {
  this.$http
    .get('MasterDirectory-list')
    .then((response) => {
      this.selectMasterDirectory = response.data.data;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function masterDirectory() {
  return this.selectMasterDirectory.map((masterDirectory) =>
    Object.assign({}, this.selectMasterDirectory, {
      value: masterDirectory.DirectoryId,
      label: masterDirectory.RouteDirectory,
    })
  );
}

async function selecDirectory(item) {
  const selectDirectory = await this.selectMasterDirectory.find(
    (element) => element.DirectoryId == item
  );

  this.routeDirectoryName = selectDirectory.RouteDirectory;
}

function computedOrder(val) {
  if (this.listlv3.length > 0) {
    this.listLv3Actually = val;
    this.Order = this.listLv3Actually.length + 1;
  } else {
    this.Order = 1;
  }
}

function validateOrderingExists(order, vm) {
  if (vm.listLv3Actually && vm.listLv3Actually.length > 0) {
    if (order != 0) {
      return !vm.listLv3Actually.filter((val) => val.Order == order).length;
    }
  }
  if (order != 0) {
    return 1;
  } else {
    return 0;
  }
}
function cambiaIcono() {
  return this.iconoCollapse = (this.collapse) ? 'minus' : 'cil-plus';
}
export default {
  name: 'add-module-modal',
  mixins: [ModuleMixin,GeneralMixin],
  props: {
    modal: Boolean,
    listNivelI: Array,
    listlv3: Array,
    GpoModuleIdNivel1: String,
    GpoModuleDsNivel1: String,
    GpoModuleDsNivel2: String,
    GpoModuleIdNivel2: String,
    TpModuleName: String,
    TpModuleFilter: String

  },
  data,
  directives: UpperCase,
  methods: {
    toggle,
    insertTp,
    insertTpModulo,
    submitBody,
    addfilePathMP,
    addfilePathMU,
    resetBody,
    resetSelects,
    computedOrder,
    insertProcessBusiness,
    insertMasterDirectory,
    selecDirectory,
    insertPB,
    insertMD,
    updateCollapse,
  },
  validations: {
    ModuleNameEs: { required, maxLength: maxLength(30), onlyMayusTextEspecial },
    ModuleNameEn: { required, maxLength: maxLength(30), onlyMayusTextEspecial },
    ModuleDs: { required, maxLength: maxLength(100), onlyMayusTextEspecial },
    Order: { required, validateOrderingExists, maxLength: maxLength(2) },
    //TpModuleId: { required },
    ModuleRoute: { required },
    //ProcessRoute: { required },
    //ManualRoute: { required },
    ProcessId: { required },
    DirectoryId: { required },
    pathAuxiliar: { required },
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
      if (val === true) {
        this.insertTpModulo();
      }
      this.$v.$reset();
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    listlv3: function(val) {
      this.computedOrder(val);
    },
  },
  computed: {
    selectTpModulo,
    processBusiness,
    cambiaIcono,
    masterDirectory,
  },
  //mounted: insertTpModulo,
  components: {
    addTpModuleCollapse,
  },
};
</script>
<style lang="scss">
.buttonClassModule{
  height: 90% !important;
  position: relative;
}
</style>
