<template>
    <div>
      <CRow>
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
          <CButton
            v-if="(!isWasRequested && !isEdit) || isEdit"
            color="edit"
            class="mr-2"
            v-c-tooltip="{
              content: $t('label.return'),
              placement: 'top',
            }"
            size="sm"
            @click="Return()"
          >
            <CIcon name="cil-chevron-left-alt" />
          </CButton>
          <CRow class="align-items-center justify-content-start w-100 mt-2 m-0">
            <h6 class="mt-2" v-if="isOfContainer">
              {{  `${ this.isEdit ? $t('label.editContainerReception') : $t('label.newContainerReception')}: ${this.dataGeneralContainer?.ContainerCode}`}} 
            </h6>
            <h6 class="ml-1" v-else>
              {{`${ this.isEdit ? $t('label.editBlReception') : $t('label.newBlReception')}: ${this.dataBl.BlNro}`}} 
            </h6>
          </CRow>
        </CCol>
      </CRow>
        <CRow class="justify-content-center mt-3">
          <CCol sm="12">
            <CTabs variant="tabs" :active-tab="0">
              <CTab :title="$t('label.basicData')">
                <CCard class="card-border">
                  <CRow class="justify-content-center px-3">
                    <CCol sm="12" lg="6">
                      <CInput
                        size="sm"
                        v-uppercase
                        class="mb-3"
                        :label="$t('label.visitItineraty')"
                        :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                        v-model="VesselName"
                        :placeholder="$t('label.visitItineraty')"
                        :disabled="true"
                      />
                      <CInput
                        size="sm"
                        v-uppercase
                        class="mb-3"
                        :label="$t('label.bl')"
                        :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                        v-model="blNumber"
                        :placeholder="$t('label.bl')"
                        :disabled="true"
                      />
                      <CSelect
                        size="sm"
                        :label="$t('label.transaction')"
                        :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.select')"
                        v-model="$v.YardStatusSuggestTransactionId.$model"
                        :is-valid="hasError($v.YardStatusSuggestTransactionId)"
                        :invalid-feedback="errorMessage($v.YardStatusSuggestTransactionId)"
                        :options="TransactionOptions"
                        :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                      />
                      <div class="form-group form-row mb-3">
                        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.receptionDate')}`}}</label>
                        <div class="input-group col-sm-12 col-lg-8 mb-0">
                          <vue-datepicker 
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="$v.TransactionDate.$model"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                            class="w-100"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          >
                            <template #input>
                              <CInput
                                v-uppercase
                                class="w-100 float-left mb-0"
                                :value="TransactionDate"
                                placeholder="DD/MM/YYYY HH:mm"
                                :is-valid="hasError($v.TransactionDate)"
                                size="sm"      
                                :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"                
                              >
                                <template #append-content>
                                  <CIcon name="cil-calendar" />
                                </template>
                              </CInput>
                            </template>
                            <template #icon-calendar>
                              <div style="display: none"></div>
                            </template>
                          </vue-datepicker>
                        </div>
                      </div>
                      <CTextarea
                        size="sm"
                        rows="2"
                        :label="$t('label.observation')"
                        addLabelClasses="text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.observation')"
                        v-model="$v.Observation.$model"
                        v-uppercase
                        :is-valid="hasError($v.Observation)"
                        :invalid-feedback="errorMessage($v.Observation)"
                        :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                      />
                    </CCol>
                    <CCol sm="12" lg="6">
                      <CInput
                        size="sm"
                        v-uppercase
                        v-model="YardName"
                        class="mb-3"
                        :label="$t('label.yard')"
                        :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.yard')"
                        :disabled="true"
                      >
                      </CInput>
                      <CInput
                        size="sm"
                        v-uppercase
                        v-model="YardNameOrigin"
                        class="mb-3"
                        :label="$t('label.OriginYard')"
                        :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.OriginYard')"
                        :disabled="true"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        v-model="$v.MachineId.$model"
                        :options="MachineOptions"
                        :label="$t('label.machine')"
                        :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.MachineId)"
                        :invalid-feedback="errorMessage($v.MachineId)"
                        :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                      />
                      <CInput
                        size="sm"
                        v-uppercase
                        v-model="$v.EirNumber.$model"
                        :label="$t('label.EirNumber')"
                        :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.EirNumber')"
                        :is-valid="hasError($v.EirNumber)"
                        :invalid-feedback="errorMessage($v.EirNumber)"
                        :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                      >
                      </CInput>
                      <CSelect
                        v-if="isEdit"
                        size="sm"
                        v-model="$v.Status.$model"
                        :options="statusOptions"
                        :label="$t('label.status')"
                        :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Status)"
                        :invalid-feedback="errorMessage($v.Status)"
                        :disabled="fgUnconsolidated || (isDisabled) || (ValidateDispached)"
                      />
                    </CCol>  
                  </CRow>
                </CCard>
              </CTab>
            </CTabs>
          </CCol>
          <CCol sm="12">
            <CTabs variant="tabs" :active-tab="0">
              <CTab :title="$t('label.inspectionSeals')">
                <CCard class="card-border">
                  <CRow class="justify-content-center px-3">
                    <CCol sm="12" lg="6">
                      <div class="form-group form-row">
                        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                        <div class="input-group col-sm-12 col-lg-8">
                          <CInput
                            size="sm"
                            class="select-adjust pr-1"
                            v-uppercase
                            :disabled="true"
                            v-model="$v.Seal.Seal1.Seal.$model"
                            :is-valid="hasError($v.Seal.Seal1.Seal)"
                            style="width: 20%;"
                          >
                          </CInput>
                          <CSelect
                            size="sm"
                            class="col-sm-5 px-0 mb-0"
                            v-model="$v.Seal.Seal1.ConditionSeal.$model"
                            :options="ConditionOptions"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                            @change="SelectCondition(Seal.Seal1, 1)"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          />
                          <CButton
                            v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                            square
                            size="sm"
                            class="ml-1"
                            @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                            style="padding: 0.15rem 0.4rem;"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          >
                            <CIcon name="dobleChevron"/>
                          </CButton>
                        </div>
                      </div>
  
                      <div class="form-group form-row">
                        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                        <div class="input-group col-sm-12 col-lg-8">
                          <CInput
                            size="sm"
                            class="select-adjust pr-1"
                            v-uppercase
                            :disabled="true"
                            v-model="$v.Seal.Seal2.Seal.$model"
                            :is-valid="hasError($v.Seal.Seal2.Seal)"
                            style="width: 20%;"
                          >
                          </CInput>
                          <CSelect
                            size="sm"
                            class="col-sm-5 px-0 mb-0"
                            v-model="$v.Seal.Seal2.ConditionSeal.$model"
                            :options="ConditionOptions"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                            @change="SelectCondition(Seal.Seal2, 2)"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          />
                          <CButton
                            v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                            square
                            size="sm"
                            class="ml-1"
                            @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                            style="padding: 0.15rem 0.4rem;"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          >
                            <CIcon name="dobleChevron"/>
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" lg="6">
                      <div class="form-group form-row">
                        <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                        <div class="input-group col-sm-12 col-lg-8">
                          <CInput
                            size="sm"
                            class="select-adjust pr-1"
                            v-uppercase
                            :disabled="true"
                            v-model="$v.Seal.Seal3.Seal.$model"
                            :is-valid="hasError($v.Seal.Seal3.Seal)"
                            style="width: 20%;"
                          >
                          </CInput>
                          <CSelect
                            size="sm"
                            class="col-sm-5 px-0 mb-0"
                            v-model="$v.Seal.Seal3.ConditionSeal.$model"
                            :options="ConditionOptions"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                            @change="SelectCondition(Seal.Seal3, 3)"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          />
                          <CButton
                            v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                            square
                            size="sm"
                            class="ml-1"
                            @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                            style="padding: 0.15rem 0.4rem;"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          >
                            <CIcon name="dobleChevron"/>
                          </CButton>
                        </div>
                      </div>
                      <div class="form-group form-row">
                        <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                        <div class="input-group col-sm-12 col-lg-8">
                          <CInput
                            size="sm"
                            class="select-adjust pr-1"
                            v-uppercase
                            :disabled="true"
                            v-model="$v.Seal.Seal4.Seal.$model"
                            :is-valid="hasError($v.Seal.Seal4.Seal)"
                            style="width: 20%;"
                          >
                          </CInput>
                          <CSelect
                            size="sm"
                            class="col-sm-5 px-0 mb-0"
                            v-model="$v.Seal.Seal4.ConditionSeal.$model"
                            :options="ConditionOptions"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                            @change="SelectCondition(Seal.Seal4, 4)"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          />
                          <CButton
                            v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                            square
                            size="sm"
                            class="ml-1"
                            @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                            style="padding: 0.15rem 0.4rem;"
                            :disabled="(dataGeneralContainer?.FgCertified??false) || fgUnconsolidated || isDisabled"
                          >
                            <CIcon name="dobleChevron"/>
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" class="text-invalid-feedback text-center" 
                      v-if="Validate && !ValidateTpCargoStatus && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                    >
                      <label>{{ $t('label.AtLeastSealRequired') }}</label>
                    </CCol>
                  </CRow>
                </CCard>
              </CTab>
            </CTabs>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!isWasRequested && isEdit && !fgUnconsolidated && !isDisabled && !ValidateDispached">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="statusConfirmation(1, Status, Submit)"
              :disabled="isSubmit"
            >
              <CIcon name="checkAlt"/>{{ $t('button.accept')}}
            </CButton>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-else>
            <CButton
              square
              color="watch"
              class="d-flex align-items-center"
              @click="backToList()"
              v-if="!(dataGeneralContainer?.FgCertified) && !fgUnconsolidated && !isDisabled"
            >
              <CIcon name='cil-list'/>{{ $t('label.listReception')}}
            </CButton>

            <CButton
              square
              color="watch"
              class="d-flex align-items-center ml-2"
              @click="position()"
              v-if="!(dataGeneralContainer?.FgCertified) && !fgUnconsolidated && !isDisabled"
            >
              <CIcon name='cil-list'/>{{ $t('label.positioningList')}}
            </CButton>
          </CCol>
        </CRow>
        <ModalSeal
          :modal="ModalSealActive"
          :SealJson="SealJson"
          @CloseModal="(ModalSealActive=false, SealJson={})"
          @UpdateSeal="UpdateSeal"
        />
    </div>
  </template>
  <script>
  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import EquipmentRegisterValidations from '@/_validations/yard-management/movements/equipmentRegisterValidations';
  import { DateFormater } from '@/_helpers/funciones';
  import { mapState } from 'vuex';
  import  ModalSeal from '@/pages/yard-management/modal-seal';
  import moment from 'moment';
  
  function data() {
    return {
      FgDeparture: false,
      isWasRequested: false,
      isSubmit: false,
      containerInfo: {},
      isDisabled: false,
      ModalSealActive: false,
      Validate: false,
      SealJson: {},
      VesselName: '',
      blNumber: '',
      YardStatusSuggestTransactionId: '',
      TransactionList: [],
      YardNameOrigin: '',
      YardName: '',
      YardId: '',
      MachineId: '',
      MachineList: [],
      EirNumber: '',
      dataContainer: '',
      Status: 1,
      Seal: {
        Seal1: {
          index: 1,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal2: {
          index: 2,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal3: {
          index: 3,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal4: {
          index: 4,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
      },
      ConditionList: [],
      Observation: '',
      ValidPreviousDate: '',
      ValidLaterDate: '',
      previousDate: '',
      laterDate: '',
      TransactionDate: '',
    };
  }

	async function getYardStatusSuggestTransaction() {
    let parameter = {
      YardCargoId: this.ContainerItem.YardCargoId,
      CargoId: this.isOfContainer? this.dataGeneralContainer.CargoId : this.ContainerItem.CargoId,
      VisitCargoId:  this.isOfContainer? this.dataGeneralContainer.VisitCargoId : '',
      FgInsert: 0,
      EventId: this.ContainerItem.EventId,
    }

    await this.$http.get(`YardStatusSuggestTransaction-by-YardCargoId`, parameter)
      .then(response => {
        let List = response.data.data;
        this.TransactionList = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
	}

  
  async function SealConditionList() {
    await this.$http.get('SealCondition-list')
      .then(response => {
        let List = response.data.data;
        this.ConditionList = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }
  
  async function YardMachine() {
    await this.$http.get('YardMachine-by-YardId-TpCargoDetailId', { YardId: this.$store.state.yardManagement.yardCollapse == 4 ?  this.ContainerItem.YardId ?? this.yardData?.YardId  : this.ContainerItem.YardId, TpCargoDetailId: this.ContainerItem.TpCargoDetailId ?? this.dataGeneralContainer.TpCargoDetailId, CompanyBranchId: this.CompanyBranchId })
      .then(response => {
        let List = response.data.data;
        this.MachineList = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  async function getYardManagementCurrent() {
    await this.$http.get('YardManagementCurrent-by-Id', { YardCurrentId: this.YardCurrentId })
      .then(response => {
        if(response.data.data){
            let yardInfo = this.$store.state.yardManagement.yardData;
            yardInfo = {
              ...yardInfo,
              YardName : response.data.data?.[0].YardName,
              YardAlias : response.data.data?.[0].YardAlias,
              SquareMeters : response.data.data?.[0].SquareMeters,
              TpYardNameEn : response.data.data?.[0].TpYardNameEn,
              TpYardNameEs : response.data.data?.[0].TpYardNameEs,
              TeusCapacity : response.data.data?.[0].TeusCapacity,
              TeusOccupied : response.data.data?.[0].TeusOccupied,
              TeusAvailable: response.data.data?.[0].TeusAvailable,
              YardGpoName : response.data.data?.[0].YardGpoName,
              TodayReception : response.data.data?.[0].TodayReception,
              TodayDispatch : response.data.data?.[0].TodayDispatch
            }
            this.$store.state.yardManagement.yardData = yardInfo;
        }   
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }
  
  function Submit() {
    try {
      this.$store.state.yardManagement.loading = true;
      this.isSubmit = true;
      this.Validate = true;
      this.$v.$touch();
      if (this.$v.$error){
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate && this.ContainerItem.DispatchDate) {
          throw this.$t('label.TheDateCannotBeLessThanTheDateOfDispatch');
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
        throw this.$t('label.errorsPleaseCheck');
      }

  
      let CargoJson = {
        YardCargoId: this.ContainerItem.YardCargoId,
        CargoId: this.isOfContainer? this.dataGeneralContainer.CargoId : this.ContainerItem.CargoId,
        YardId: this.YardId,
        UserId: this.ContainerItem.TransaUserId,
        YardStatusSuggestTransactionId: this.YardStatusSuggestTransactionId,
        MachineId: this.MachineId,
        Seal1: this.Seal.Seal1.Seal,
        Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
        Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
        ObservationSeal1: this.Seal.Seal1.ObservationSeal,
        Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : this.Seal.Seal1.SealDocumentJson,
        Seal2: this.Seal.Seal2.Seal,
        Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
        Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
        ObservationSeal2: this.Seal.Seal2.ObservationSeal,
        Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : this.Seal.Seal2.SealDocumentJson,
        Seal3: this.Seal.Seal3.Seal,
        Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
        Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
        ObservationSeal3: this.Seal.Seal3.ObservationSeal,
        Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : this.Seal.Seal3.SealDocumentJson,
        Seal4: this.Seal.Seal4.Seal,
        Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
        Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
        ObservationSeal4: this.Seal.Seal4.ObservationSeal,
        Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : this.Seal.Seal4.SealDocumentJson,
        TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
        Observation: this.Observation.trim(),
        EirNumber: this.EirNumber,
        YardCargoOriginId: this.ContainerItem?.YarCargoOriginId ?? '',
        VisitCargoId: this.isOfContainer? this.dataGeneralContainer.VisitCargoId ?? '' : this.ContainerItem?.VisitCargoId ?? '',
        CargoOriginId: this.isOfContainer? this.dataGeneralContainer.CargoOrigId ?? '' : this.ContainerItem?.CargoOrigId ?? '',
        Status: this.Status
      }

      let Route = this.isEdit ? 'YardCargoReceptionInternalTransfer-update' : 'YardCargoReceptionInternalTransfer'

      this.$http.put(Route, CargoJson, { root: 'CargoJson' })
        .then(async (response) => {
          this.notifySuccess({text: response.data.data?.[0].Response});
          //await this.getYardCargo(response?.data?.data?.[0]?.YardCargoId);
          await this.getYardManagementCurrent();
          this.isSubmit = false;
          this.isWasRequested = true;
          this.$store.state.yardManagement.loading = false;

          if(this.isEdit){
            this.backToList();
          }
        }).catch((err) => {
          this.isSubmit = false;
          this.$store.state.yardManagement.loading = false;
          this.notifyError({text: err});
        })
    } catch (error) {
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: error});
    }
  }
  
  function SelectCondition(Seal, index) {
    let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
    if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
      WithoutSeal) {
      let CurrentSeal = {
        index: index,
        CurrentSeal: Seal.CurrentSeal,
        Seal: WithoutSeal ? '' : Seal.CurrentSeal,
        NewSeal: '',
        ConditionSeal: Seal.ConditionSeal,
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: Seal.SealDocumentJson,
      }
      this.UpdateSeal(CurrentSeal);
    }
  }
  
  function ConditionWithoutSeal(ConditionSeal) {
    if (typeof ConditionSeal == 'string') {
      return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
    }else{
      return false;
    }
  }
  
  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }
  
  function UpdateSeal(Update) {
    switch (Update.index) {
      case 1:
        this.Seal.Seal1 = Update;
        break;
      case 2:
        this.Seal.Seal2 = Update;
        break;
      case 3:
        this.Seal.Seal3 = Update;
        break;
      case 4:
        this.Seal.Seal4 = Update;
        break;
      default:
        break;
    }
  }
  
  function getdata(item) {
    this.YardName = this.yardData.YardName;
    this.YardId = this.yardData?.YardId ?? this.ContainerItem.YardId ;

    if(this.isEdit){
      let transaction = this.TransactionList.find((e)=>e.TpEirTransacId == this.ContainerItem.TpEirTransacId)
      this.MachineId = this.ContainerItem.MachineId;
      this.EirNumber = this.ContainerItem.EirNumber;
      this.Observation = this.ContainerItem.Observation;
      this.YardStatusSuggestTransactionId = transaction?.YardStatusSuggestTransactionId ?? '';
      this.TransactionDate = this.ContainerItem.TransactionDate;
      this.laterDate = new Date();
      this.previousDate = item.DispatchDate ? moment(item.DispatchDate).format('YYYY-MM-DD HH:mm') : DateFormater.formatDateRange(this.ContainerItem.Ata??this.ContainerItem.ReceptionDate??new Date());
    }

    this.VesselName = item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : 'N/A';
    this.YardNameOrigin = item.YardName ?? '';
    this.blNumber = this.isOfContainer ? this.BlJson?.BlNro : this.dataBl.BlNro ;
    
    this.ValidPreviousDate = item.DispatchDate ? moment(item.DispatchDate).format('YYYY-MM-DD HH:mm') : this.ContainerItem.Ata??this.ContainerItem.ReceptionDate??new Date();
    this.ValidLaterDate = moment(this.laterDate).format('YYYY-MM-DD HH:mm');

    //SEALS 
    this.Seal.Seal1.CurrentSeal = item?.SealJson?.[0]?.Seal1Json?.[0]?.Seal ?? '';
    this.Seal.Seal1.Seal = item?.SealJson?.[0]?.Seal1Json?.[0]?.Seal ?? '';
    this.Seal.Seal1.SealDocumentJson = item?.SealJson?.[0]?.Seal1Json?.[0]?.SealDocumentJson ?? [];
    this.Seal.Seal1.NewSeal = item.SealJson?.[0]?.Seal1Json?.[0]?.Seal ?? '';
    this.Seal.Seal1.ObservationSeal = item?.SealJson?.[0]?.Seal1Json?.[0]?.ObservationSeal ?? '';
    this.Seal.Seal1.ConditionSeal = item?.SealJson?.[0]?.Seal1Json?.[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal1.SealAssignmentId = item?.SealJson?.[0]?.Seal1Json?.[0]?.SealAssignmentId ?? '';
    
    this.Seal.Seal2.CurrentSeal = item?.SealJson?.[0]?.Seal2Json?.[0]?.Seal ?? '';
    this.Seal.Seal2.Seal = item?.SealJson?.[0]?.Seal2Json?.[0]?.Seal ?? '';
    this.Seal.Seal2.SealDocumentJson = item?.SealJson?.[0]?.Seal2Json?.[0]?.SealDocumentJson ?? [];
    this.Seal.Seal2.NewSeal = item?.SealJson?.[0]?.Seal2Json?.[0]?.Seal ?? '';
    this.Seal.Seal2.ObservationSeal = item?.SealJson?.[0]?.Seal2Json?.[0]?.ObservationSeal ?? '';
    this.Seal.Seal2.ConditionSeal = item?.SealJson?.[0]?.Seal2Json?.[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal2.SealAssignmentId = item?.SealJson?.[0]?.Seal2Json?.[0]?.SealAssignmentId ?? '';

    this.Seal.Seal3.CurrentSeal = item?.SealJson?.[0]?.Seal3Json?.[0]?.Seal ?? '';
    this.Seal.Seal3.Seal = item?.SealJson?.[0]?.Seal3Json?.[0]?.Seal ?? '';
    this.Seal.Seal3.SealDocumentJson = item?.SealJson?.[0]?.Seal3Json?.[0]?.SealDocumentJson ?? [];
    this.Seal.Seal3.NewSeal = item?.SealJson?.[0]?.Seal3Json?.[0]?.Seal ?? '';
    this.Seal.Seal3.ObservationSeal = item?.SealJson?.[0]?.Seal3Json?.[0]?.ObservationSeal ?? '';
    this.Seal.Seal3.ConditionSeal = item?.SealJson?.[0]?.Seal3Json?.[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal3.SealAssignmentId = item?.SealJson?.[0]?.Seal3Json?.[0]?.SealAssignmentId ?? '';

    this.Seal.Seal4.CurrentSeal = item?.SealJson?.[0]?.Seal4Json?.[0]?.Seal ?? '';
    this.Seal.Seal4.Seal = item?.SealJson?.[0]?.Seal4Json?.[0]?.Seal ?? '';
    this.Seal.Seal4.SealDocumentJson = item?.SealJson?.[0]?.Seal4Json?.[0]?.SealDocumentJson ?? [];
    this.Seal.Seal4.NewSeal = item?.SealJson?.[0]?.Seal4Json?.[0]?.Seal ?? '';
    this.Seal.Seal4.ObservationSeal = item?.SealJson?.[0]?.Seal4Json?.[0]?.ObservationSeal ?? '';
    this.Seal.Seal4.ConditionSeal = item?.SealJson?.[0]?.Seal4Json?.[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal4.SealAssignmentId = item?.SealJson?.[0]?.Seal4Json?.[0]?.SealAssignmentId ?? '';
    this.$v.$reset();
  }

  function Return() {
    this.$emit("Return", this.ContainerItem);
    this.Reset();
  }
  
  function Reset() {
    this.isDisabled = false;
    this.VesselName = '';
    this.blNumber = '';
    this.YardStatusSuggestTransactionId = '';
    this.TransactionList = [];
    this.YardNameOrigin = '';
    this.YardName = '';
    this.YardId = '';
    this.Status = 1;
    this.MachineId = '';
    this.MachineList = [];
    this.EirNumber = '';
    this.Seal = {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL,
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    };
    this.ConditionList = [];
    this.Observation = '',
    this.ValidPreviousDate = '';
    this.ValidLaterDate = '';
    this.previousDate = '';
    this.laterDate = '';
    this.TransactionDate = '';
    this.containerInfo = {};
    this.Validate = false;
    this.isWasRequested = false;
    this.FgDeparture = false;
    this.$v.$reset();
    this.$emit("Close");
  }

  async function backToList() {
    this.$store.state.yardManagement.loading = true;
    this.Reset();
    await this.$emit("Update-list", false);
  }

  async function position() {
    this.$store.state.yardManagement.loading = true;
    this.$store.state.yardManagement.dataContainer = this.containerInfo;
    this.$store.state.yardManagement.FgPositioning = true;
    await this.$emit("Update-list", true);
    this.Reset();
  }
  
  
  //computed

  function BlJson() {
    return this.dataGeneralContainer?.BlJson&&this.dataGeneralContainer?.BlJson.length!=0 ? this.dataGeneralContainer?.BlJson?.[0] : {};
  }

  function TransactionOptions() {
    if(this.TransactionList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      let _lang = this.$i18n.locale;
      this.TransactionList.map(function(e){
        chart.push({
          value: e.YardStatusSuggestTransactionId,
          label: _lang=='en'? e.TpTransacEirNameEn : e.TpTransacEirNameEs,
        })
      })
      return chart;
    }
  }

  function statusOptions() {
      return [
          {
          value: 1,
          label: this.$t('label.ACTIVO')
          },
          {
          value: 0,
          label: this.$t('label.INACTIVO')
          }
      ]
  }
  
  function MachineOptions() {
    if(this.MachineList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.MachineList.map(function(e){
        chart.push({
          value: e.MachineId,
          label: e.MachineName,
        })
      })
      return chart;
    }
  }
  
  function ConditionOptions() {
    if(this.ConditionList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.condition'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.condition'),
      }]
      let _lang = this.$i18n.locale;
      this.ConditionList.map(function(e){
        chart.push({
          value: e.SealConditionId,
          label: _lang=='en' ? e.SealConditionNameEn : e.SealConditionNameEs,
        })
      })
      return chart;
    }
  }
  
  function ValidateTpCargoStatus() {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.ContainerItem?.TpCargoStatusId?.toUpperCase();
  }
  function VerifySeal() {
    if (this.ValidateTpCargoStatus) {
      return {
        ConditionSeal1: false,
        ConditionSeal2: false,
        ConditionSeal3: false,
        ConditionSeal4: false,
      }
    } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
        return {
          ConditionSeal1: true,
          ConditionSeal2: true,
          ConditionSeal3: true,
          ConditionSeal4: true,
        }
    } else {
      return {
        ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
        ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
        ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
        ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
      }
    }
  }

  function fgUnconsolidated() {
    return this.dataGeneralContainer?.BlJson?.[0]?.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED || this.dataGeneralContainer?.BlJson?.[0]?.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_PARTIAL_UNCONSOLIDATED
  }

  function ValidateDispached() {
    return process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED==this.YardCargoStatus?.toUpperCase();
  }
  
  export default {
    name: 'internal-transfer-register',
    components: {
      ModalSeal,
     },
    props: { 
      Active: Boolean, 
      FgInternalTransfer: Boolean, 
      ContainerItem: Object, 
      Index: Number, 
      isEdit: Boolean, 
      isOfContainer: Boolean,
      fgCertificate: Boolean,
    },
    data,
    mixins: [
      ModalMixin,
      GeneralMixin
    ],
    validations(){
      return EquipmentRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate, 
        this.ContainerItem.ItineraryId, this.FgDeparture);
    },
    directives: UpperCase,
    methods: {
			getYardStatusSuggestTransaction,
      SealConditionList,
      YardMachine,
      getYardManagementCurrent,
      Submit,
      SelectCondition,
      ConditionWithoutSeal,
      validateDateRange,
      UpdateSeal,
      getdata,
      Return,
      Reset,
      backToList,
      position
    },
    computed: {
      ValidateDispached, 
      fgUnconsolidated,
      statusOptions,
      TransactionOptions,
      MachineOptions,
      ConditionOptions,
      ValidateTpCargoStatus,
      BlJson,
      VerifySeal,
      ...mapState({
        YardCurrentId: state => state.yardManagement.yardData.YardCurrentId,
        yardData: state => state.yardManagement.yardData,
        CompanyBranchId: state => state.auth.branch.CompanyBranchId, 
        dataBl: state => state.yardManagement.dataBl,
        YardCargoStatus: state => state.yardManagement.dataContainer.YardCargoStatus,
        dataGeneralContainer: state => state.yardManagement.dataContainer,
      })
    },
    watch: {
      Active: async function (val) {
        if (val) {
          if(!(this.yardData?.YardId) || (this.yardData?.YardId && (this.yardData?.YardId != this.ContainerItem.YardId)) || this.fgCertificate)
            this.isDisabled = true; 

          this.$store.state.yardManagement.loading = true;
					await this.getYardStatusSuggestTransaction();
          await this.SealConditionList();
          await this.YardMachine();
          await this.getdata(this.ContainerItem);
          this.$store.state.yardManagement.loading = false;
        }else{
          this.Reset();
        }
      },
      TransactionDate: async function(val){
        try{
          if(val){
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.ContainerItem.DispatchDate) {
              throw this.$t('label.TheDateCannotBeLessThanTheDateOfDispatch');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
              throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
          }
        }catch(e){
          this.notifyError({text: e});
        }
      },
    },
   
  };
  </script>
  <style scoped>
  .card-border{
    padding: 20px;
    background-color: #fff;
    border-color: #c4c9d0;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  </style>