<template>
    <div class="mb-3">
        <div v-show="showIndex==0" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-start mt-2">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsHousePendingReceive')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mb-3">
                <CCol sm="12">
                    <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
                        <CTab :title="$t('label.packaging')" class="pt-1">
                            <CRow>
                                <CCol col="12" xl="12">
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="computedListContainer"
                                        :fields="fields"
                                        :items-per-page="5"
                                        column-filter
                                        pagination
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        :noItemsView="tableText.noItemsViewText"
                                        sorter
                                    >
                                        <template #YardCargoBlStatus="{ item }">
                                            <td class="cell-center text-center">
                                                <CBadge :color="getBadge(item.YardCargoBlStatus)">
                                                {{ item.YardCargoBlStatus }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #VisitBillOfLadingStatus="{ item }">
                                            <td class="cell-center text-center">
                                                <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                                                {{ item.VisitBillOfLadingStatus }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #options="{ item }">
                                            <td class="text-center">
                                                <CButton
                                                    square
                                                    size="sm"
                                                    color="watch"
                                                    class="d-flex align-items-center"
                                                    @click="toggleAdd(item)"
                                                    v-c-tooltip="{
                                                        content: $t('label.reception'),
                                                        placement: 'top-start'
                                                    }"
                                                    v-if="YardId"
                                                >
                                                    <CIcon name='cil-list' />
                                                </CButton>
                                            
                                            </td>
                                        </template>

                                        <template #Weight="{ item }">
                                            <td class="text-center">
                                                {{ NumberFormat(item.Weight, 2) }}
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CTab>
                        <CTab :title="$t('label.Containers')" class="pt-1">
                            <CRow>
                                <CCol col="12" xl="12">
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="ContainerformatedItems"
                                        :fields="Containerfields"
                                        :items-per-page="5"
                                        column-filter
                                        pagination
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        :noItemsView="tableText.noItemsViewText"
                                        sorter
                                    >
                                        <template #YardCargoBlStatus="{ item }">
                                            <td class="cell-center text-center">
                                                <CBadge :color="getBadge(item.YardCargoBlStatus)">
                                                {{ item.YardCargoBlStatus }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #VisitBillOfLadingStatus="{ item }">
                                            <td class="cell-center text-center">
                                                <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                                                {{ item.VisitBillOfLadingStatus }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #options="{ item }">
                                            <td class="text-center">
                                                <CButton
                                                    square
                                                    size="sm"
                                                    color="watch"
                                                    class="d-flex align-items-center"
                                                    @click="toggleContainerAdd(item)"
                                                    v-c-tooltip="{
                                                        content: $t('label.reception'),
                                                        placement: 'top-start'
                                                    }"
                                                >
                                                    <CIcon name='cil-list' />
                                                </CButton>
                                            
                                            </td>
                                        </template>

                                        <template #Weight="{ item }">
                                            <td class="text-center">
                                                {{ NumberFormat(item.Weight, 2) }}
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CTab>
                    </CTabs>
                </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                    <CButton
                        color="edit"
                        class="mr-2"
                        v-c-tooltip="{
                            content: $t('label.return'),
                            placement: 'top',
                        }"
                        size="sm"
                        @click="()=> {showIndex = 0}"
                    >
                        <CIcon name="cil-chevron-left-alt" />
                        
                    </CButton>
                    <CRow class="align-items-center justify-content-start w-100 m-0">
                        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
                    </CRow>
                </CCol>
            </CRow>
            <CRow class="my-3">
                <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
                    <CRow>
                    <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate">
                        <CButton
                        shape="square"
                        color="add"
                        class="d-flex align-items-center ml-auto"
                        v-c-tooltip="{
                            content: $t('label.deconsolidated'),
                            placement: 'top-end'
                        }"
                        @click="toggleAddDesconsolidation({})"
                        >
                        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                        </CButton>
                    </CCol>
                    </CRow>
                </CCol> 
            </CRow>
            <CRow class="mt-2">
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        details
                        column-filter
                        :items="formatedDesconsolidationPackagingItems"
                        :fields="fieldsDesconsolidationPackaging"
                        :noItemsView="tableText.noItemsViewText"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page="tableText.itemsPerPage"
                        :items-per-page-select="tableText.itemsPerPageText"
                        pagination
                    >
                        <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                        </template>
                        <template #Status="{item}">
                            <td class='text-center'>
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                        <template #buttonOptions="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.reception'),
                                        placement: 'top-start'
                                    }"
                                    @click="toggleDeconsolidation(item, true)"
                                >
                                    <CIcon name='cil-list' />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==5" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                    <CButton
                        color="edit"
                        class="mr-2"
                        v-c-tooltip="{
                            content: $t('label.return'),
                            placement: 'top',
                        }"
                        size="sm"
                        @click="()=> {showIndex = 0}"
                    >
                        <CIcon name="cil-chevron-left-alt" />
                        
                    </CButton>
                    <CRow class="align-items-center justify-content-start w-100 m-0">
                        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
                    </CRow>
                </CCol>
            </CRow>
            <CRow class="my-3">
                <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
                    <CRow>
                    <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate">
                        <CButton
                        shape="square"
                        color="add"
                        class="d-flex align-items-center ml-auto"
                        v-c-tooltip="{
                            content: $t('label.deconsolidated'),
                            placement: 'top-end'
                        }"
                        @click="toggleAddContainerDesconsolidation({})"
                        >
                        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                        </CButton>
                    </CCol>
                    </CRow>
                </CCol> 
            </CRow>
            <CRow class="mt-2">
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        details
                        column-filter
                        :items="formatedDesconsolidationContainerItems"
                        :fields="fieldsDesconsolidationContainer"
                        :noItemsView="tableText.noItemsViewText"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page="tableText.itemsPerPage"
                        :items-per-page-select="tableText.itemsPerPageText"
                        pagination
                    >
                        <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                        </template>
                        <template #Status="{item}">
                            <td class='text-center'>
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                        <template #buttonOptions="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.reception'),
                                        placement: 'top-start'
                                    }"
                                    @click="toggleContainerDeconsolidation(item)"
                                >
                                    <CIcon name='cil-list' />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==2">
            <BlRecepcion
                v-show="!isHouseOfContainer"
                :Active="showIndex==2 && !isHouseOfContainer"
                :ContainerItem="ContainerItem"
                :isEdit = "false"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="(loading, dataLocalBl)=>{Update(loading, dataLocalBl, false)}"
            />

            <BlContainerRecepcion
                v-show="isHouseOfContainer"
                :Active="showIndex==2 && isHouseOfContainer"
                :ContainerItem="ContainerItem"
                :isEdit = "false"
                @Close="showIndex=0, ContainerItem={}, isHouseOfContainer = false"
                @Update-list="(loading, dataLocalBl)=>{Update(loading, dataLocalBl, true)}"
            />
        </div>
        <div v-show="showIndex==4">
            <DesconsolidatedRegister
                :Active="ActiveRegister && (showIndex==4)"
                :HouseData="HouseData"
                :isEdit="false"
                :ContainerItem="ContainerItem"
                @Close="ActiveRegister=false"
                @Update-list="(event)=>{showIndex=0; ContainerItem ={};  UpdateDesconsolidation(event);}"
                @Reception="ReceptionDesconsolidation"
            />
        </div>
        <div v-show="showIndex==6">
            <DesconsolidatedContainerRegister
                :Active="ActiveRegister && (showIndex==6)"
                :HouseData="HouseData"
                :isEdit="false"
                :ContainerItem="ContainerItem"
                @Close="ActiveRegister=false"
                @Update-list="(event)=>{showIndex=0; ContainerItem ={};  UpdateDesconsolidation(event);}"
                @Reception="ReceptionContainerDesconsolidation"
            />
        </div>
        <ModalSealImages
            :modal="ModalSeal"
            :labelModal="labelModalImages"
            :SealImages="SealImages"
            @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
        />
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from './BlReception/reception';
    import BlContainerRecepcion from '@/pages/yard-management/container/events/pendingHouse/BlReception/reception';
    import DesconsolidatedRegister from '@/pages/bl-query/taps/movements/events/desconsolidated/desconsolidated-from-house-register';
    import DesconsolidatedContainerRegister from '@/pages/yard-management/container/events/desconsolidated/desconsolidated-from-house-register'
    import ModalSealImages from '@/pages/yard-management/container/modal-seal-images';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            tabIndex: 0,
            ActiveRegister: false,
            HouseData: {},
            ModalSeal: false,
            labelModalImages: '',
            collapseDuration: 0,
            dataContainers: [],
            IdDesconsolidateToPendingBl: false,
            SealImages: [],
            Items: [],
            ContainerItem:{},
            dataDesconsolited: [],
            PackagingDesconsolidationJson: [],
            ContainerDesconsolidationJson: [],
            dataBlHouseContainer: [],
            dataBlHouse: [],
            showIndex: 0,
            YardCargoEventDetailId: '',
            isHouseOfContainer: false
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    async function getDesconsolitedList() {
        await this.$http.get('YardEventDeconsolidation-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId})
            .then(async(response) => {
                let List = response.data.data?.[0];
                this.PackagingDesconsolidationJson = List?.PackagingJson ?? [];
                this.ContainerDesconsolidationJson = List?.ContainerJson ?? [];

                if(this.ContainerDesconsolidationJson.length>0){
                    this.getInventoryInYard();
                }
            })
            .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
    }

    async function getInventoryInYard() {
        await this.$http.get('YardCargoInventoryInYard-by-BlCargoId', { YardId: this.YardId ?? this.ContainerItem.YardId, BlCargoId: this.dataBl.BlCargoId})
        .then((response) => {
            this.dataContainers =  response.data.data ?? [];
            if(this.dataContainers.length>0){
                this.dataBlHouseContainer = [];
                this.dataContainers.map(async(e)=>{
                    await this.getBlContainerHousePendingsReception(e);
                })
                
            }
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        }


    async function getBlHouseData() {
        await this.$http.get('BlHouseData-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
            .then(response => {
            this.HouseData = response.data.data[0] ?? {}; 
            })
            .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
    }

    async function getBlHouseContainerData(item) {
        await this.$http.get('BlHouseData-by-CargoId', { CargoId: item.CargoId })
        .then(response => {
            this.HouseData = response.data.data[0] ?? []; 
        })
        .catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    async function getBlHousePendingsReception(loading, dataLocalBl) {
        await this.$http.get('YardWBlCargoHousePackagingNoCont-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouse = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.blSelected = '';
                    if(dataLocalBl)
                        this.$store.state.yardManagement.blSelected = dataLocalBl.BlCargoId;
                    else
                        this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    async function getBlContainerHousePendingsReception(item) {
        await this.$http.get('YardWBlCargoHousePackaging-by-YardCargoId', { YardCargoId: item.YardCargoId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouseContainer =  this.dataBlHouseContainer.concat(List&&List.length!=0 ? List : []);
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    }

    async function getYardBlCargo(dataLocalBl) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('BlCargo-by-Id', {BlCargoId: this.dataBl.BlCargoId})
            .then(response => {
            let res = response.data.data;
            if (res && res?.length>0) {
                this.$store.state.yardManagement.dataBl = res[0];
            }
            })
            .catch( err => {
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        this.$store.state.yardManagement.loading = false;
    }

    async function getYardCargo(item) {
         this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: this.YardId ?? this.ContainerItem.YardId })
            .then(response => {
            let List = response.data.data;
            if (List&&List.length!=0) {
                this.$store.state.yardManagement.dataContainer = List[0];
                this.showIndex = 2;
            }else{
                this.$store.state.yardManagement.dataContainer = {};
                this.$store.state.yardManagement.loading = false;
            }
            })
            .catch( err => {
            this.$store.state.yardManagement.dataContainer = {};
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
    }

    async function ReceptionDesconsolidation(item) {
        this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId: item.YardCargoEventDetailId, DeconsolidationDate: item.TransactionBegin };
        this.showIndex = 2;
        this.isHouseOfContainer = false;
    }

    async function ReceptionContainerDesconsolidation(item) {
        this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId: item.YardCargoEventDetailId, DeconsolidationDate: item.TransactionBegin, isProfileBl: true };
        this.showIndex = 2;
        this.isHouseOfContainer = true;
    }

    async function Update(loading, dataLocalBl, isContainer = false) {
        this.$store.state.yardManagement.loading = true;
        this.ContainerItem={};
        await this.getYardBlCargo();
        await this.getDesconsolitedList();
        !isContainer && await this.getBlHousePendingsReception(loading, dataLocalBl);

        if(!isContainer) {
            if(this.dataBlHouse.length == 0){
                this.showIndex = 0;
                this.tabIndex = 0;
            }
            else
                this.showIndex=0;
        }
        else{
            this.showIndex=0;
            this.tabIndex=1;
        }

        this.$store.state.yardManagement.loading = false; 
    }

    async function UpdateDesconsolidation() {
        this.$store.state.yardManagement.loading = true;
        await this.getBlHousePendingsReception(false);
        await this.getDesconsolitedList();
        this.showIndex = 0;
        this.ActiveRegister = false;
        this.$store.state.yardManagement.loading = false; 
    } 

    function toggleDeconsolidation(item) {
        this.isHouseOfContainer = false;
        this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId : item.YardCargoEventDetailId, DeconsolidationDate: item.TransactionBegin};
        this.showIndex = 2;
    }

    function toggleContainerDeconsolidation(item) {
        this.isHouseOfContainer = true;
        this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId : item.YardCargoEventDetailId, YardCargoId: item.YardCargoId, isProfileBl: true, DeconsolidationDate: item.TransactionBegin};
        this.getYardCargo(item)
    }

    async function toggleAdd(item) {

        if(this.IdDesconsolidateToPendingBl != ''){
            this.ContainerItem = {...item, YardCargoEventDetailId: this.IdDesconsolidateToPendingBl};
            this.showIndex = 2;
        }
        else{
            if(this.PackagingDesconsolidationJson.length == 0){
                await this.getBlHouseData();
                this.ContainerItem = {...item};
                this.showIndex = 4;
                this.ActiveRegister = true;
            }
            else if(this.PackagingDesconsolidationJson.length == 1){
                /*this.isHouseOfContainer = false;
                this.ContainerItem = {...item, YardCargoEventDetailId : this.PackagingDesconsolidationJson[0].YardCargoEventDetailId};
                this.showIndex = 2;*/

                this.ContainerItem = {...item};
                this.showIndex = 1;
            }
            else{
                this.ContainerItem = {...item};
                this.showIndex = 1;
            }
        }
    }

    async function toggleContainerAdd(item) {
        if(this.IdDesconsolidateToPendingBl != ''){
            this.isHouseOfContainer = true;
            this.ContainerItem = {...item, YardCargoEventDetailId: this.IdDesconsolidateToPendingBl, YardCargoId: item.YardCargoId, isProfileBl: true};
            this.showIndex = 2;
        }
        else{
            if(this.ContainerDesconsolidationJson.length == 0){
                await this.getBlHouseContainerData();
                this.ContainerItem = {...item, isProfileBl: true};
                this.showIndex = 6;
                this.ActiveRegister = true;
            }
            else if(this.ContainerDesconsolidationJson.length == 1){
                /*this.isHouseOfContainer = false;
                this.ContainerItem = {...item, YardCargoEventDetailId : this.ContainerDesconsolidationJson[0].YardCargoEventDetailId};
                this.showIndex = 2;*/

                this.ContainerItem = {...item};
                this.showIndex = 5;
            }
            else{
                this.ContainerItem = {...item};
                this.showIndex = 5;
            }
        }

       /* this.isHouseOfContainer = true;
        this.ContainerItem = {...item, YardCargoEventDetailId : this.YardCargoEventDetailId, YardCargoId: this.ContainerDesconsolidationJson[0].YardCargoId, isProfileBl: true};
        this.getYardCargo(this.ContainerDesconsolidationJson[0])*/
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
                return 'danger'
            case 'INACTIVE':
                return 'danger'
            default:
                return 'success'
        }
    }

    function ModalImagen(item) {
        this.labelModalImages = item.Seal;
        this.SealImages = item.SealDocumentJson;
        this.ModalSeal = true;
    }

    function viewDetail(item){
        this.closeShow(item, 1);
        item._toggled = !item._toggled;
        this.collapseDuration = 300;
        this.$nextTick(() => {
        this.collapseDuration = 0;
        this.loading = false;
        this.apiStateLoading = false;
        });
    }

    async function toggleAddDesconsolidation(item, Edit = false) {
        await this.getBlHouseData();
        this.showIndex = 4;
        this.ActiveRegister = true;
        this.isEdit = Edit;
    }

    async function toggleAddContainerDesconsolidation(item, Edit = false) {
        await this.getBlHouseData();
        this.showIndex = 6;
        this.ActiveRegister = true;
        this.isEdit = Edit;
    }
    
    //computed
    //PACKAGING
    function fieldsDesconsolidationPackaging(){
        return [
            {
                key: 'buttonOptions', 
                label: '', 
                _style: 'min-width: 45px; width: 1%; text-align:center; ', 
                sorter: false, 
                filter: false
            },
            { key: 'number', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
            { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
            { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 160px; text-align:center;', _classes:"text-center" },
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
            
        ];
    }

    function formatedDesconsolidationPackagingItems() {
        let _lang = this.$i18n.locale;
        return this.PackagingDesconsolidationJson.map((item, index) => {
            return {
                    ...item,
                    Nro: index+1,
                    YardName: item?.YardName? item?.YardName??'' : this.yardData.YardName ?? '',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                    TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                    MachineCode: item.MachineCode ?? '',
                    DeviceName: item.DeviceName ?? '',
                    TransaLogin: item.TransaLogin ?? '',
                    ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                    ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                    EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
                    EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || ''
                };
        })
    }

    function fieldsDesconsolidationContainer(){
        return [
            {
                key: 'buttonOptions', 
                label: '', 
                _style: 'min-width: 45px; width: 1%; text-align:center; ', 
                sorter: false, 
                filter: false
            },
            { key: 'number', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
            { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
            { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 160px; text-align:center;', _classes:"text-center" },
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
            
        ];
    }

    function formatedDesconsolidationContainerItems() {
        let _lang = this.$i18n.locale;
        return this.ContainerDesconsolidationJson.map((item, index) => {
            return {
                    ...item,
                    Nro: index+1,
                    YardName: item?.YardName? item?.YardName??'' : this.yardData.YardName ?? '',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                    TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                    MachineCode: item.MachineCode ?? '',
                    DeviceName: item.DeviceName ?? '',
                    TransaLogin: item.TransaLogin ?? '',
                    ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                    ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                    EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
                    EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || ''
                };
        })
    }

    //CONTAINER
    function ContainerformatedItems() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouseContainer.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpBlName: item.TpBlName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Planned: item.PlannedQuantityReceived ? NumberFormater.formatNumber(item.PlannedQuantityReceived, 2) : '0,00',
                Received: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                RemainReceived: item.QuantityRemainReceived ? NumberFormater.formatNumber(item.QuantityRemainReceived, 2) : '0,00',
                ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                Packaging: Detail,
                SerialParent: item.SerialParent ?? '',
                PackagingParentName: item[`PackagingParentName${_lang}`] ?? 'N/A',
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`,
            };
        })
    }

    function Containerfields(){
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;' },
            { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'SerialParent', label: this.$t('label.originSerial'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'PackagingParentName', label: this.$t('label.originPackaging'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'Packaging', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px; text-align:center;' },  
            { key: 'Planned', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
            { key: 'Received', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
            { key: 'RemainReceived', label: this.$t('label.CertifiedPendingReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'ComputedWeight', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.planned')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;' },
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³) ${this.$t('label.planned')}`, _style:'min-width: 180px;', _classes:"center-cell text-center"}     
        ];
    }

    //List of receptions packagings
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouse.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpBlName: item.TpBlName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Planned: item.PlannedQuantityReceived ? NumberFormater.formatNumber(item.PlannedQuantityReceived, 2) : '0,00',
                Received: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                RemainReceived: item.QuantityRemainReceived ? NumberFormater.formatNumber(item.QuantityRemainReceived, 2) : '0,00',
                Packaging: Detail,
                PackagingParentName: item[`PackagingParentName${_lang}`] ?? 'N/A',
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`,
                DeviceName: item.DeviceName ?? '',
                TransaLogin: item.TransaLogin ?? '',
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:45px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'PackagingParentName', label: this.$t('label.originPackaging'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'Packaging', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},  
            { key: 'Planned', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 140px;'},
            { key: 'Received', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 140px;'},
            { key: 'RemainReceived', label: this.$t('label.CertifiedPendingReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'ComputedWeight', label: `${this.$t('label.weight') } (KGM) ${this.$t('label.planned')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³) ${this.$t('label.planned')}`, _classes:"center-cell text-center",  _style:'min-width: 180px;', _classes:"center-cell text-center"}    
        ];
    }

    function fgDesconsolidate() {
        return (
            this.dataBl.YardCargoBlStatus != process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED && 
            process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE == this.dataBl?.BlStatus?.toUpperCase() &&
            ((this.dataBl?.HouseJson?.length > 0) && this.dataBl.FgApplyDeconsolidation))
    }

    export default{
        name: 'pendingsBls',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
            ModalSealImages,
            BlContainerRecepcion,
            DesconsolidatedRegister,
            DesconsolidatedContainerRegister
        },
        methods: {
            ReceptionDesconsolidation,
            ReceptionContainerDesconsolidation,
            toggleAdd,
            getBlHouseData,
            getBlHouseContainerData,
            toggleAddDesconsolidation,
            toggleAddContainerDesconsolidation,
            toggleContainerAdd,
            toggleDeconsolidation,
            toggleContainerDeconsolidation,
            getBlHousePendingsReception,
            getBlContainerHousePendingsReception,
            Update,
            UpdateDesconsolidation,
            getYardBlCargo,
            getYardCargo,
            NumberFormat,
            getBadge,
            getDesconsolitedList,
            getInventoryInYard,
            handleTab,
            ModalImagen,
            viewDetail,
        },
        computed: {
            fieldsDesconsolidationContainer,
            formatedDesconsolidationContainerItems,
            fgDesconsolidate,
            fields,
            fieldsDesconsolidationPackaging,
            formatedDesconsolidationPackagingItems,
            ContainerformatedItems,
            Containerfields,
            computedListContainer,
            ...mapState({
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                yardData: state => state.yardManagement.yardData,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropBlMovement: state => state.yardManagement.dropBlMovement,
                dataBl: state => state.yardManagement.dataBl,
                dataContainer: state => state.yardManagement.dataContainer,
            })
        },
        watch:{
            dropBlMovement: async function (val) {

                if (val==5) {
                    this.IdDesconsolidateToPendingBl = '';
                    this.$store.state.yardManagement.loading = true;
                    this.showIndex = 0;
                    this.tabIndex = 0;
                    this.isHouseOfContainer = false;
                    this.dataBlHouse = [];
                    this.dataBlHouseContainer = [];
                    this.PackagingDesconsolidationJson = [];
                    this.ContainerDesconsolidationJson = [];
                    await this.getDesconsolitedList();
                    await this.getBlHousePendingsReception(false);
                    this.$store.state.yardManagement.loading = false;
                }

                if(this.$store.state.yardManagement.IdDesconsolidateToPendingBl != ''){
                    this.IdDesconsolidateToPendingBl = this.$store.state.yardManagement.IdDesconsolidateToPendingBl;
                    this.$store.state.yardManagement.IdDesconsolidateToPendingBl = '';
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>