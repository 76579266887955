<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="xl"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="closeModal()"
    >
      <CRow>
        <CCol sm="12" lg="5">
          <CSelect
            :label="$t('label.classification')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.EventGpoId.$model"
            :options="ClassificationOptions"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventGpoId)"
            :invalid-feedback="errorMessage($v.EventGpoId)"
          />
          <CInput
            :label="$t('label.name')"
            :placeholder="`${$t('label.name')} (ES)`"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.EventNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventNameEs)"
            :invalid-feedback="errorMessage($v.EventNameEs)"
            v-uppercase
          />
          <CInput
            :label="$t('label.name')"
            :placeholder="`${$t('label.name')} (EN)`"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.EventNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventNameEn)"
            :invalid-feedback="errorMessage($v.EventNameEn)"
            v-uppercase
          />
          <CInput
            :label="$t('label.acronym')"
            :placeholder="$t('label.acronym')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.Acronym.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.Acronym)"
            :invalid-feedback="errorMessage($v.Acronym)"
            maxlength="10"
            v-uppercase
          />
          <CInput
            type="color"
            :label="$t('label.color')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.Color.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.Color)"
            :invalid-feedback="errorMessage($v.Color)"
          />
          <CSelect
            v-if="isEdit"
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
        <CCol sm="12" lg="4">
          <CCard>
            <CCardHeader>
              <label><b class="text-danger">*</b>{{ $t('label.apply') }}</label>
            </CCardHeader>
            <CCardBody>
              <CCol sm="12" class="px-4">
                <CInputCheckbox
                  custom
                  :label="$t('label.inspectionSeals')"
                  :checked="fgInspectionSeals"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 1)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.Implement')"
                  :checked="FgImplement"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 2)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.machine')"
                  :checked="FgMachine"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 3)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.EquipmentDestination')"
                  :checked="FgEquipmentDestination"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 4)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.DispachedLogistics')"
                  :checked="FgDispachedLogistics"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 5)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.Weighing')"
                  :checked="FgWeighing"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 6)"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.documentType')"
                  :checked="FgDocumentType"
                  class="checkbook-filter mb-2"
                  v-on:click="VerifyConfig($event.target.checked, 7)"
                />
                <p v-if="$v.Configurations.$error" class="mt-2 text-danger text-center">
                  * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.option')}}
                </p>
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="12" lg="3">
          <CCard>
            <CCardHeader>
              <label><b class="text-danger">*</b>{{ $t('label.configurations') }}</label>
            </CCardHeader>
            <CCardBody>
              <CCol sm="12" class="px-4">
                <div>
                  <input type="radio" value="0" 
                    v-model="DateOptions"
                    :disabled="FgConfigurations"
                  />
                  <label class="mx-2">&nbsp;{{ $t('label.date') }}</label>
                </div>
                <div>
                  <input type="radio" value="1" 
                    v-model="DateOptions" 
                    :disabled="FgConfigurations"
                  />
                  <label class="mx-2">&nbsp;{{ $t('label.dateRange') }}</label>
                </div>
                <CInputCheckbox
                  custom
                  :label="$t('label.quantity')"
                  :checked="FgQuantity"
                  class="checkbook-filter mb-2"
                  v-on:click="FgQuantity = $event.target.checked"
                  :disabled="FgDocumentType || FgConfigurations"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.observation')"
                  :checked="fgObservation"
                  class="checkbook-filter mb-2"
                  v-on:click="fgObservation = $event.target.checked"
                  :disabled="FgConfigurations"
                />
                <CInputCheckbox
                  custom
                  :label="$t('label.weight')"
                  :checked="FgWeight"
                  class="checkbook-filter mb-2"
                  v-on:click="FgWeight = $event.target.checked"
                  :disabled="FgWeighing || FgConfigurations"
                />
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="isEdit ? statusConfirmation(OriginStatus, Status, Submit) : Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import EventValidations from '@/_validations/event/eventValidations';
import UpperCase from "@/_validations/uppercase-directive";

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    loading: false,
    EventId: '',
    EventNameEs: '',
    EventNameEn: '',
    Acronym: '',
    Color: '',
    EventGpoId: '',
    EventGpoList: [],
    fgInspectionSeals: false,
    FgImplement: false,
    FgMachine: false,
    FgEquipmentDestination: false,
    FgDispachedLogistics: false,
    FgWeighing: false,
    FgDocumentType: false,
    FgQuantity: false,
    fgObservation: false,
    FgWeight: false,
    DateOptions: '',
    OriginStatus: 0,
    Status: 0,
  };
}

//methods
function getEventGpoList() {
  this.loading = true;
  this.$http.get('EventGpo-list')
    .then((response) => {
      let List = response.data.data
      if (List&&List.length!=0) {
        this.EventGpoList = List;
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}

function Submit() {
  try {
    this.isSubmit = true;
		this.loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let EventJson = this.isEdit ? {
      EventId: this.EventId,
      EventGpoId: this.EventGpoId,
      EventNameEn: this.EventNameEn,
      EventNameEs: this.EventNameEs,
      EventAlias: this.Acronym,
      EventDs: '',
      Color: this.Color,
      FgSealInspection: this.fgInspectionSeals ? 1 : 0,
      FgImplement: this.FgImplement ? 1 : 0,
      FgMachine: this.FgMachine ? 1 : 0,
      FgContainerDestiny: this.FgEquipmentDestination ? 1 : 0,
      FgLogisticDispatch: this.FgDispachedLogistics ? 1 : 0,
      FgTruckWeighingScale: this.FgWeighing ? 1 : 0,
      FgTpDocument: this.FgDocumentType ? 1 : 0,
      FgApplyDateRange: this.DateOptions,
      FgApplyQuantity: this.FgQuantity ? 1 : 0,
      FgObservation: this.fgObservation ? 1 : 0,
      FgWeight:this.FgWeight ? 1 : 0,
      Status: this.Status,
    }:{
      EventGpoId: this.EventGpoId,
      EventNameEn: this.EventNameEn,
      EventNameEs: this.EventNameEs,
      EventAlias: this.Acronym,
      EventDs: '',
      Color: this.Color,
      FgSealInspection: this.fgInspectionSeals ? 1 : 0,
      FgImplement: this.FgImplement ? 1 : 0,
      FgMachine: this.FgMachine ? 1 : 0,
      FgContainerDestiny: this.FgEquipmentDestination ? 1 : 0,
      FgLogisticDispatch: this.FgDispachedLogistics ? 1 : 0,
      FgTruckWeighingScale: this.FgWeighing ? 1 : 0,
      FgTpDocument: this.FgDocumentType ? 1 : 0,
      FgApplyDateRange: this.DateOptions,
      FgApplyQuantity: this.FgQuantity ? 1 : 0,
      FgObservation: this.fgObservation ? 1 : 0,
      FgWeight:this.FgWeight ? 1 : 0,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'Event-update': 'Event-insert';
    this.$http
      .ejecutar( metodo, ruta, EventJson, { root: 'EventJson' })
      .then((response) => {
				this.closeModal();
				this.$emit('List-Update');
        this.notifySuccess({text: response.data.data[0].Response})
        this.loading = false;
        this.isSubmit = false;
      }).catch((e) => {
        this.isSubmit = false;
        this.loading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading = false;
    this.notifyError({text: e});
  }
}

function VerifyConfig(event, Apply) {
  switch (Apply) {
    case 1:{
      this.fgInspectionSeals = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    case 2:{
      this.FgImplement = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    case 3:{
      this.FgMachine = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    } 
    case 4:{
      this.FgEquipmentDestination = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    case 5:{
      this.FgDispachedLogistics = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    case 6:{
      this.FgWeighing = event;
      this.FgWeight = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    case 7:{
      this.FgDocumentType = event;
      this.FgQuantity = event;
      this.DateOptions = this.DateOptions ? this.DateOptions : 0;
      break;
    }
    default:
      break;
  }
  if (this.FgConfigurations) {
    this.DateOptions = '';
    this.FgQuantity = false;
    this.fgObservation = false;
  }
}

function getdata(val) {
  this.EventId = val.EventId;
  this.EventNameEs = val.EventGpoNameEs;
  this.EventNameEn = val.EventGpoNameEn;
  this.Acronym = val.EventAlias;
  this.Color = val.Color;
  this.EventGpoId = val.EventGpoId;
  this.fgInspectionSeals = val.FgSealInspection;
  this.FgImplement = val.FgImplement;
  this.FgMachine = val.FgMachine;
  this.FgEquipmentDestination = val.FgContainerDestiny;
  this.FgDispachedLogistics = val.FgLogisticDispatch;
  this.FgWeighing = val.FgTruckWeighingScale;
  this.FgDocumentType = val.FgTpDocument;
  this.FgQuantity = val.FgApplyQuantity;
  this.fgObservation = val.FgObservation;
  this.FgWeight = val.FgWeight;
  this.DateOptions = val.FgApplyDateRange ? 1 : 0;
  this.Status = val.FgActEvent ? 1 : 0;
  this.OriginStatus = val.FgActEvent ? 1 : 0;
  this.$v.$touch();
}

function closeModal() {
  this.EventId = '';
  this.EventNameEs = '';
  this.EventNameEn = '';
  this.Acronym = '';
  this.Color = '';
  this.EventGpoId = '';
  this.EventGpoList = [];
  this.fgInspectionSeals = false;
  this.FgImplement = false;
  this.FgMachine = false;
  this.FgEquipmentDestination = false;
  this.FgDispachedLogistics = false;
  this.FgWeighing = false;
  this.FgDocumentType = false;
  this.FgQuantity = false;
  this.fgObservation = false;
  this.FgWeight = false;
  this.DateOptions = '';
  this.Status = 0;
  this.OriginStatus = 0;
  this.$v.$reset();
  this.$emit('Close-Modal');
}

function statusSelectColor() {
  return this.Status === 1;
}

//computed
function FgConfigurations() {
  return !this.fgInspectionSeals&&!this.FgImplement&&!this.FgMachine&&!this.FgEquipmentDestination&&
    !this.FgDispachedLogistics&&!this.FgWeighing&&!this.FgDocumentType
}
function ClassificationOptions(){
  if(this.EventGpoList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.EventGpoList.map(function(e){
      chart.push({
        value: e.EventGpoId, 
        label: _lang=='en' ? e.EventGpoNameEn : e.EventGpoNameEs,
      })    
    })
    return chart;
  }
}

function titleModal() {
  if (this.isEdit) {
    let _lang = this.$i18n.locale;
    let title =  _lang=='en' ? this.EventItem.EventNameEn : this.EventItem.EventNameEs;
    return `${this.$t('label.edit')} ${this.$t('label.Event')}: ${title ? title : ''}`;
  } else {
    return `${this.$t('label.nuevo')} ${this.$t('label.Event')}`;
  }
}

export default {
  name: 'modal-event',
  props: { modal: Boolean, isEdit: Boolean, EventItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  validations(){
    return EventValidations(this.FgConfigurations)
  },
  methods: {
    statusSelectColor,
    closeModal,
    getdata,
    Submit,
    VerifyConfig,
    getEventGpoList,
  },
  computed: {
    titleModal,
    ClassificationOptions,
    FgConfigurations,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
        this.getEventGpoList();
        if (this.isEdit) {
          this.getdata(this.EventItem);
        }
      }
    }
  },
 
};
</script>