<template>
  <div class="container-fluid top-menos">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="$t('label.billOfLadingDocument')"
      color="dark"
      size="lg"
      @update:show="closeModal()"
      :show.sync="ModalReport"
      :closeOnBackdrop="false"
    >
      <form class="company-form">
        <CRow>
          <CCol sm="12" class="mb-2">
            <vue-dropzone 
              ref="myVueDropzone" 
              id="dropzone" 
              :options="dropzoneOptions" 
              :useCustomSlot="true"
              v-on:vdropzone-files-added="sendingEvent"
              v-on:vdropzone-removed-file="deleteFile"
              v-on:vdropzone-error="DropzoneError"
            > 
            <div>
              <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
              <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
            </div>
            </vue-dropzone>
          </CCol>
          <CCol sm="12" lg="10">
            <CInput
              :label="$t('label.description')"
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
              v-model.trim="$v.DocumentName.$model"
              :invalid-feedback="errorMessage($v.DocumentName)"
              :is-valid="hasError($v.DocumentName)"
              addLabelClasses="text-right required"
            />
          </CCol>
          <CCol sm="12" lg="auto" class="mb-1" style="text-align: right;">
            <CButton 
              color="add" 
              square size="sm"
              class="mr-1" 
              :disabled="isSubmit"
              @click="submitDocumet()"
            >
                <CIcon name="checkAlt"/>
            </CButton>
            <CButton
              color="wipe"
              size="sm"
              v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
              @click="Refresh()"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="computedBillLadingDocument"
              :fields="fields"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            >
              <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
              </template>
              <template #Details="{item}">
                <td class="text-center">
                  <CButton 
                    class="mr-1"
                    color="excel" 
                    square 
                    size="sm" 
                    v-c-tooltip="{
                      content: $t('label.download'),
                      placement: 'top-end'
                    }"
                    target='_blank' 
                    :href="item.Ruta" 
                  >
                    <CIcon name="cil-cloud-download" />
                  </CButton>
                  <CButton v-if="item.FgActVisitBillOfLadingDoc"
                    color="wipe" 
                    square 
                    size="sm"
                    :disabled="isSubmit" 
                    v-c-tooltip="{
                      content: $t('label.delete'),
                      placement: 'top-end'
                    }"
                    @click="changeStatus(item)"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </form>
      
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from "@/_mixins/modal";
import BillLadingValidation from "@/_validations/visitas/bill_of_lading/bill_of_ladingValidate";
import { DateFormater, tableTextHelpers } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { file } from 'jszip';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    loading: false,
    isSubmit: false,
    VisitBillOfLadingDocId: '',
    DocumentName: '',
    RouteBillLading: '',
    Status: 0,
    BillLadingDocument: [],
    ModalReport: false,
    docFile: null,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
          this.removeAllFiles();
          this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    },
  };
}

function sendingEvent(files){
  if(files[0].name) this.docFile = files[0];
}

function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function fields(){
  return [
    { key: 'Nro', label: '#',  _classes: 'text-center', _style: 'width:3%;', filter:false,},
    { key: 'VisitBillOfLadingDocDs', label:this.$t('label.document'), _style:'width:45%;'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;'},
    { key: 'FormatedDate', label: this.$t('label.date'),  _classes: 'text-center', _style:'width:15%;'},
    { key: 'Status', label: this.$t('label.status'),  _classes: 'text-center', _style:'width:10%;'},
    { key: 'Details', label: '', _classes:'text-center', _style: 'min-width:90px', sorter: false, filter: false,}
  ];
}

function changeStatus(item) {
  this.isSubmit = true;
  this.$swal.fire(this.alertProperties({
    text: `${this.$t('label.deleteQuestion')+' '} ${item.VisitBillOfLadingDocDs}?`,
    })).then((result) => {
      if (result.isConfirmed) {
        this.loading= true;
        let VisitBillOfLadingDocJson = [{
          VisitBillOfLadingDocId: item.VisitBillOfLadingDocId,
          VisitBillOfLadingId: item.VisitBillOfLadingId,
          VisitBillOfLadingDocDs: item.VisitBillOfLadingDocDs,
          RouteVisitBillOfLadingDoc: item.RouteVisitBillOfLadingDoc,
          Status: 0,
        }];
        this.$http.ejecutar('PUT', 'VisitBillOfLadingDoc-update', VisitBillOfLadingDocJson, { root: 'VisitBillOfLadingDocJson' })
          .then(response => {
            this.notifySuccess({text: response.data.data[0].Response});
            this.mountValues();
            this.isSubmit = false;
            this.loading= false;
          }).catch((err) => {
            this.isSubmit = false;
            this.loading= false;
            this.notifyError({text: err});
          });      
      }else{
        this.isSubmit = false;
      }
    });
}

function submitDocumet() {
  try {
    this.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    let files = this.$refs.myVueDropzone.getQueuedFiles();
    if (files.length === 0) {
      this.loading = false;
      this.isSubmit = false;
      throw this.$t('label.documentQuestion');
    }
    if (this.$v.$error) {
      this.loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    this.$http.file('VisitBl-file', files[0])
      .then(response => {
        this.RouteBillLading = response.data.data.files[0].path;
        this.submit();
      }).catch((err) => {
        this.isSubmit = false;
        this.loading= false;
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading= false;
    this.notifyError({text: e});
  }
}

function submit() {
  try {
    let VisitBillOfLadingDocJson = [{
      VisitBillOfLadingId: this.billOfLoadingId,
      VisitBillOfLadingDocDs: this.DocumentName,
      RouteVisitBillOfLadingDoc: this.RouteBillLading,
    }];

    let metodo = 'POST';
    let ruta = 'VisitBillOfLadingDoc-insert';
      
    this.$http.ejecutar(metodo, ruta, VisitBillOfLadingDocJson, { root: 'VisitBillOfLadingDocJson' })
      .then(response => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Refresh();
        this.mountValues();
        this.isSubmit = false;
        this.loading= false;
      }).catch((err) => {
        this.isSubmit = false;
        this.loading= false;
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading= false;
    this.notifyError({text: e});
  }
}

function Refresh() {
  let files = this.$refs.myVueDropzone.getQueuedFiles();
  if(files.length != 0){
    this.$refs.myVueDropzone.removeFile(files[0]);
  }
  this.VisitBillOfLadingDocId = '';
  this.DocumentName = '';
  this.RouteBillLading = '';
  this.Status = 0;
  this.$v.$reset();
}

function closeModal() {
  this.$emit('submited');
}

function computedBillLadingDocument() {
  if (this.BillLadingDocument ==[]) return this.BillLadingDocument = [];
  if (this.BillLadingDocument.length > 0) {
    return this.BillLadingDocument.map((item) => {
      return {
        ...item,
        Ruta: item.RouteVisitBillOfLadingDoc ?`${this.$store.getters["connection/getBase"]}${item.RouteVisitBillOfLadingDoc.replace('Public/', '', null, 'i')}`:'',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
      };
    });
  }
}

function mountValues() {
  this.loading = true;
  this.$v.$reset();
  this.$http.ejecutar('GET', 'VisitBillOfLadingDoc-list', { VisitBillOfLadingId: this.billOfLoadingId })
  .then(response => {
    this.BillLadingDocument = response.data.data;
    this.loading = false;
  }).catch( err => {
    this.loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
}

export default {
  name: 'modal-report',
  props: { modal: Boolean, billOfLoadingId: String},
  data,
  mixins: [
    GeneralMixin,
    ModalMixin, 
  ],
  validations: BillLadingValidation,
  components: {
    vueDropzone: vue2Dropzone,
  },
  directives: UpperCase,
  methods: {
    Refresh,
    submitDocumet,
    submit,
    sendingEvent,
    closeModal,
    changeStatus,
    mountValues,
    deleteFile,
    DropzoneError
  },
  computed: {
    fields,
    computedBillLadingDocument,
  },
  watch: {
    modal: function(val){
      this.ModalReport = val;
      if (val) {
        this.mountValues();
      }else{
        this.Refresh();
      }
    },
  },
  
};
</script>
<style lang="scss">
  
</style>