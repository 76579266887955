<template>
  <div class="mb-3 mt-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="4" class="justify-content-start py-2">
          <CSelect
            :label="$t('label.group')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-auto', input:'col-sm-7'}"
            :options="computedTpGpoGeneral"
            @change="gpoSelectedFilter($event)"
            :value="valueFilterGpoSelected"
            size="sm"
          />
      </CCol>
      <CCol sm="8" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateState"
          v-c-tooltip="{
            content: $t('label.classification'),
            placement: 'top-end',
          }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedTpGeneralCLasification"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <!--
      <template #over-table>
        <div class="row justify-content-start" style="margin-top: -2.8rem">
          <div
            class="col-auto ml-2 mt-2 select-Status-embaleje"
            style="margin-left: 16rem !important"
          >
            {{$t('label.group')}} :
          </div>
          <div class="col-3">
            <CSelect
              :options="computedTpGpoGeneral"
              @change="gpoSelectedFilter($event)"
              :value="valueFilterGpoSelected"
            />
          </div>
        </div>
      </template> -->
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            square
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.classification'),
              placement: 'top-end',
            }"
            @click="toggleShowModalEditGpo(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>

    <modalClasification
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      @refresh-data-table="mountedTpGeneralClasification"
      :TpCargoSelected="TpCargoSelected"
      :title="titleModal"
      :editModal="editModal"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalClasification from './modal-clasificacion';

function data() {
  return {
    itemsclasification: [],
    itemsclasificationTemporal: [],
    itemsGposClasification: [],
    Loading: false,
    showModal: false,
    titleModal: '',
    editModal: false,
    TpCargoSelected: {},
    valueFilterGpoSelected: 'N/A',
    titleModal: '',
  };
}
function fields(){
  return [
    { label: '#', key: 'Nro', sorter: false, filter: false },
    {  key: 'TpCargoName', label: this.$t('label.group'), _style:'width:30%;', _classes:"center-cell text-center" },
    {
      label: this.$t('label.classification'),
      key: 'ClassificationName',
      _style:'width:30%;',
      _classes:"center-cell text-center"
    },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;',_classes:"center-cell text-center" },
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center", _classes:"center-cell text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center", _classes:"center-cell text-center" },
    {  key: 'options',
      label: '', 
      _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false,
    },
  ];
}

function toggleShowModalEditGpo(item) {
  this.showModal = true;
  this.TpCargoSelected = item;
  this.titleModal =  this.$t('label.edit')+' '+this.$t('label.generalCargoGroupClassification')+': '+this.TpCargoSelected.ClassificationName;
  this.editModal = true;
}

function updateShowModalCreate(event) {
  this.showModal = false;
  this.editModal = false;
  this.TpCargoSelected = {};
}

function toggleShowModalCreateState() {
  this.showModal = true;
  this.titleModal = this.$t('label.nueva')+' '+this.$t('label.generalCargoGroupClassification');
   this.editModal = false;
}

async function mountedTpGeneralClasification() {
  await this.mountSelectTpCargoGeneral();
  this.Loading = true;

  await this.$http
    .get('TpGeneralCargoClassification-list?TpCargoId=')
    .then((response) => {
      this.itemsclasification = response.data.data;
      this.itemsclasificationTemporal = response.data.data;
    })
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function mountSelectTpCargoGeneral() {
  this.Loading = true;

  await this.$http
    .get('TpGeneralCargo-list')
    .then((response) => {
      this.itemsGposClasification = response.data.data;
      const tpGrupopCargo = {
        TpCargoName: this.$t('label.all'),
        TpCargoId: 'N/A',
      };
      this.itemsGposClasification = [
        tpGrupopCargo,
        ...this.itemsGposClasification,
      ];
    })
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedTpGpoGeneral() {
  if (this.itemsGposClasification.length > 0) {
    return this.itemsGposClasification.map((item) => {
      if(item.TpCargoId == 'N/A')
        return {
          label: this.$t('label.all'),
          value: item.TpCargoId,
        };
      else
        return {
          label: item.TpCargoName,
          value: item.TpCargoId,
        };
    });
  }
}

function gpoSelectedFilter(event) {
  this.valueFilterGpoSelected = event.target.value;
  this.itemsclasification = this.itemsclasificationTemporal;
  if (event.target.value === 'N/A') {
    this.itemsclasification = this.itemsclasificationTemporal;
  } else {
    this.itemsclasification = this.itemsclasification.filter(
      (elelment) => elelment.TpCargoId === event.target.value
    );
  }
}

//### Funcion computed para formatear los datos de la tabla ###
function computedTpGeneralCLasification() {
  if (this.itemsclasification.length > 0) {
    return this.itemsclasification.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

export default {
  name: 'clasificacionCargaGeneral',
  data,
  props: {Tab: Number},
  mixins: [GeneralMixin],
  components: {
    modalClasification,
  },
  methods: {
    toggleShowModalCreateState,
    updateShowModalCreate,
    mountedTpGeneralClasification,
    toggleShowModalEditGpo,
    gpoSelectedFilter,
    mountSelectTpCargoGeneral,
  },
  computed: {
    computedTpGeneralCLasification,
    computedTpGpoGeneral,
    fields,
  },
  watch:{
    Tab: async function (val){
      if(val == 1){
        await this.mountedTpGeneralClasification();
      }
    }
  },
  
  
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
