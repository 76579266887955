<template>
    <div class="my-3">
        <div>
            <CRow>
                <CCol>
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.general')">
                            <CCard class="card-border">
                                <CRow class="justify-content-center px-3">
                                    <CCol sm="12" lg="6">
                                        <CSelect
                                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                            :label="$t('label.transaction')"
                                            :options="transacOptions"
                                            addLabelClasses="text-right"
                                            v-model.trim="VForm.BasicData.TpEirTransacId.$model"
                                            :is-valid="hasError(VForm.BasicData.TpEirTransacId)"
                                            :invalid-feedback="errorMessage(VForm.BasicData.TpEirTransacId)"
                                            size="sm"
                                            @blur="VForm.BasicData.TpEirTransacId.$touch()"
                                            @change="changeTransacEir"
                                            :disabled="true"
                                        />
                                    </CCol>
                                    <CCol sm="12" lg="6">
                                        <CSelect
                                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                                            :label="$t('label.machine')"
                                            :options="machineOptions"
                                            :addLabelClasses="(this.Form?.DetailJson?.some((e)=> e.YardAreaId && e.YardAreaId != '')) ? 'required text-right' : 'text-right'"
                                            v-model.trim="VForm.BasicData.MachineId.$model"
                                            :is-valid="hasError(VForm.BasicData.MachineId)"
                                            :invalid-feedback="errorMessage(VForm.BasicData.MachineId)"
                                            size="sm"
                                            @blur="VForm.BasicData.MachineId.$touch()"
                                            :disabled="isWasRequested"
                                        />
                                    </CCol>
                                    <CCol>
                                        <div class="form-group form-row mb-3">
                                            <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.receptionDate')}`}}</label>
                                            <div class="input-group col-sm-12 col-lg-8 mb-0">
                                                <vue-datepicker 
                                                    type="datetime"
                                                    header
                                                    :lang="this.$i18n.locale"
                                                    :editable="false"
                                                    :clearable="false"
                                                    format="DD/MM/YYYY HH:mm"
                                                    placeholder="DD/MM/YYYY HH:mm"
                                                    time-title-format="DD/MM/YYYY HH:mm"
                                                    v-model="VForm.BasicData.TransactionDate.$model"
                                                    :disabled-date="validateDateRange"
                                                    :append-to-body="false"
                                                    value-type="format"
                                                    :show-second="false"
                                                    class="w-100"
                                                    :disabled="isWasRequested"
                                                >
                                                    <template #input>
                                                        <CInput
                                                            v-uppercase
                                                            class="w-100 float-left mb-0"
                                                            :value="Form.BasicData.TransactionDate"
                                                            placeholder="DD/MM/YYYY HH:mm"
                                                            :is-valid="hasError(VForm.BasicData.TransactionDate)"
                                                            size="sm"         
                                                            :disabled="isWasRequested"             
                                                        >
                                                        <template #append-content>
                                                            <CIcon name="cil-calendar" />
                                                        </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="6">
                                        <CTextarea
                                            size="sm"
                                            rows="2"
                                            :label="$t('label.observation')"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                                            :placeholder="$t('label.observation')"
                                            v-model="VForm.BasicData.Observation.$model"
                                            v-uppercase
                                            :is-valid="hasError(VForm.BasicData.Observation)"
                                            :invalid-feedback="errorMessage(VForm.BasicData.Observation)"
                                            :disabled="isWasRequested"
                                        />
                                    </CCol>
                                    <CCol sm="12" lg="6"></CCol>
                                </CRow> 
                            </CCard>
                        </CTab>
                    </CTabs>
                    <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('label.documents')">
                        <CCard class="card-border">
                            <CRow class="justify-content-center px-3">
                                <CCol sm="12" lg="6">
                                    <CInput
                                        size="sm"
                                        v-uppercase
                                        v-model="CantDocuments"
                                        :label="$t('label.decription')"
                                        addLabelClasses="text-right"
                                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                        :placeholder="$t('label.documentDescription')"
                                        :disabled="true"
                                    >
                                    <template #append>
                                        <div class="d-flex align-items-start">
                                        <CButton
                                            color="add"
                                            square
                                            size="sm"
                                            style="padding: 0.15rem 0.4rem;"
                                            @click="OpenImageModal($t('label.documentation'), DocumentJson, 1)"
                                            :disabled="isWasRequested"
                                        >
                                            <CIcon name="cil-image"/>
                                        </CButton>
                                        </div>
                                    </template>
                                    </CInput>
                                </CCol>
                                <CCol sm="12" lg="6"></CCol>
                            </CRow>
                        </CCard>
                    </CTab>
                </CTabs>
                </CCol>
            </CRow>
        </div>
        <ModalImages
            :Form="Form"
            :VForm="VForm" 
            :modal="ModalImagesActive"
            :ImagesJson="ImagesJson"
            :DocumentType="DocumentType"
            :isEdit="isEdit"
            :titleModal="TitleModalImages"
            @CloseModal="(ModalImagesActive=false, ImagesJson=[], TitleModalImages='')"
            @UpdateImages="UpdateImages"
        />
    </div>
</template>
<script>
    import ModalMixin from '@/_mixins/modal';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import  ModalImages from '@/pages/yard-management/modal-documents-bl';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    
    //data
    function data() {
        return {
            //General
            previousDate:  DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO),
            laterDate: '',
            DocumentType: 0,
            TitleModalImages: '',
            ModalImagesActive: false,
            BlJson: {},
            DocumentJson: [],
            TicketOutDocumentJson: [],
            ValidPreviousDate: '',

            //List of selects
            MachineList: [],
            ImagenList: [],
            ImagesJson: [],
            TransactionList: []
            
        };
    }

    //-----------------------   Method    ------------------------

    function changeTransacEir(event){
        let data = this.TransactionList.find((e)=>e.TpEirTransacId == event?.target?.value) ?? []
        this.Form.BasicData.TpEirTransacId = data.TpEirTransacId ?? ''
    }

    function getDateRange(){
        this.Form.BasicData.laterDate = new Date();
        this.Form.BasicData.previousDate = DateFormater.formatDateRange(this.ContainerItem?.MinDischargeDate ? DateFormater.formatDateTimeWithoutSlash(this.ContainerItem?.MinDischargeDate) : process.env.VUE_APP_START_DATE_IN_PATIO);
        this.Form.BasicData.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.ContainerItem?.MinDischargeDate ? DateFormater.formatDateTimeWithoutSlash(this.ContainerItem?.MinDischargeDate) : process.env.VUE_APP_START_DATE_IN_PATIO));
    }

    async function MachineServiceList() {
        await this.$http.get('MachineYard-list', {YardId: this.YardId, CompanyBranchId : this.CompanyBranchId})
            .then(response => {
                let List = response.data.data;
                this.MachineList = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    }

    async function YardEirTransacList() {
        await this.$http.get('YardWTpEirTransac-list', {TpEirId: this.Form.BasicData.TpEirId})
            .then(response => {
                let List = response.data.data;
                this.TransactionList = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    }

    async function getInformationLadingPendingById() {
        this.$store.state.yardManagement.loading = true;
        await this.$http
            .get('YardWVisitBillOfLadingPending-by-Id',{VisitBillOfLadingId: this.ContainerItem.VisitBillOfLadingId})
                .then(async response => {
                    let data = response.data.data[0];
                    if(data){
                        this.Form.BasicData.QuantityStowage = data.QuantityStowage;
                        this.Form.BasicData.QuantityReceived = data.QuantityReceived;
                        this.Form.BasicData.QuantityRemainReceived = data.QuantityRemainReceived;
                    }
                    else{
                        this.Form.BasicData.QuantityStowage = 0;
                        this.Form.BasicData.QuantityReceived = 0;
                        this.Form.BasicData.QuantityRemainReceived = 0;
                    }
                    
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.$store.state.yardManagement.loading = false;
            });
    }


    async function getService() {
        if((this.MachineList.length == 0)  || (this.TransactionList.length == 0)){
            this.$store.state.yardManagement.loading = true;
            (this.MachineList.length == 0) && await this.MachineServiceList();
            (this.TransactionList.length == 0) &&  await this.YardEirTransacList();
            (this.Form.BasicData.QuantityStowage == 0 && this.Form.BasicData.QuantityReceived == 0 && this.Form.BasicData.QuantityRemainReceived == 0) &&  await this.getInformationLadingPendingById();
            this.$store.state.yardManagement.loading = false;
        }
    }

    function validateDateRange(date) {
        return date < this.Form.BasicData.previousDate || date > this.Form.BasicData.laterDate;
    }

    function OpenImageModal(title, ImagenList, type) {
        this.DocumentType = type;
        this.TitleModalImages = title;
        this.ImagesJson = ImagenList;
        this.ModalImagesActive = true;
    }

    function UpdateImages(Update) {
        switch (this.DocumentType) {
            case 1:{
                this.DocumentJson = Update;
                this.Form.BasicData.DocumentJson = Update;
                break;
            }
            case 2:{
                this.TicketOutDocumentJson = Update;
                break;
            }
            default:
                break;
        }
    }

    //-----------------------   Computed   ------------------------

    function machineOptions(){
        let chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]

        this.MachineList.map((e) => {
            chart.push({
                value: e.MachineId, 
                label: e.MachineName
            });
        })

        return chart;
    }

    function transacOptions(){
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]

        this.TransactionList.map((e) => {
            chart.push({
                value: e.TpEirTransacId, 
                label: e[`TpTransacEirName${_lang}`]
            });
        })

        return chart;
    }

    function TransactionDate(){
        return this.Form.BasicData.TransactionDate;
    }

    function CantDocuments(){
        if(this.Form.BasicData.DocumentJson.length>1)
            return `${this.Form.BasicData.DocumentJson.length } ${this.$t('label.documents')}`;
        else
            if(this.Form.BasicData.DocumentJson.length == 0)
                return `${this.$t('label.withoutDocuments')}`;
            else
                return `${this.Form.BasicData.DocumentJson.length } ${this.$t('label.uploadedDocuments')}`;
    }

    export default{
        name: 'bl-data-basic',
        data,
        mixins: [General, ModalMixin],
        directives: UpperCase,
        props: {
            Form: {
                type: Object,
                required: true,
                default: () => {},
            },
            ModuleForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            isEdit: Boolean,
            Tab: Boolean,
            Active: Boolean,
            isWasRequested:Boolean,
            VForm: Object,
            isSumited: Boolean,
        },
        components: {
            ModalImages
        },
        methods: {
            MachineServiceList,
            getService,
            validateDateRange,
            getDateRange,
            OpenImageModal,
            UpdateImages,
            YardEirTransacList,
            changeTransacEir,
            getInformationLadingPendingById
        },
        computed: {
            CantDocuments,
            machineOptions,
            TransactionDate,
            transacOptions,
            ...mapState({
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardId: state => state.yardManagement.yardData.YardId
            })
        },
        watch:{
            Tab: async function (Newval) {
                if (Newval) {
                    await this.getService();
                    this.getDateRange();
                }
            },
            isSumited: async function (Newval) {
                this.DocumentJson = [];
            },
            isWasRequested: async function (Newval) {
                if (Newval) {
                    this.BlJson = {};
                    this.DocumentJson = [];
                    this.Form.BasicData.laterDate = '';
                    this.DocumentType = 0;
                    this.TitleModalImages = '';
                    this.ModalImagesActive = false;
                    this.TicketOutDocumentJson = [];
                    this.MachineList = [];
                    this.ImagenList = [];
                    this.ImagesJson = [];
                }
            },
            Active: async function (Newval) {
                if (!Newval) {
                    this.BlJson = {};
                    this.DocumentJson = [];
                    this.Form.BasicData.laterDate = '';
                    this.DocumentType = 0;
                    this.TitleModalImages = '';
                    this.ModalImagesActive = false;
                    this.TicketOutDocumentJson = [];
                    this.MachineList = [];
                    this.ImagenList = [];
                    this.ImagesJson = [];
                }
            },
            TransactionDate: async function(val){
                try{
                    if(val){
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.Form.BasicData.ValidPreviousDate ) {
                            throw this.$t('label.TheDateCannotBeLessThanTheDateOfConfirmation');
                        }
                        if ((DateFormater.formatDateTimeWithoutSlash(val) > DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.Form.BasicData.laterDate)))  && this.Form.BasicData.laterDate) {
                            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                        }
                    }
                }catch(e){
                    this.notifyError({text: e});
                }
            }
        }
    }
</script>
<style lang="scss">
      .invalido {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: #e55353;
        }
</style>