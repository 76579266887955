<template>
<div>
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
  <MachineModal
    :modal.sync="modal"
    :machineId = "machineId"
    :machineItems="machineItems"
    :edit="edit"
    :title="titleModal"
    @submited="handleSubmit"
    @close="closeModal"
  />
  <CRow>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.machinery'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedItems"
        :fields="fields"
        column-filter
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items-per-page="tableText.itemsPerPage"
        hover
        small
        sorter
        pagination
      >
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{$t('label.'+item.Status)}}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center align-middle">
              <CButton
                color="edit"
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.machinery'),
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</div>
</template>

<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import { mapState } from "vuex";
import MachineModal from "./machines-modal";

//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    machineItems: {},
    edit: false,
    machineId:'',
    titleModal: ''

  };
}

//METHOD
function getListMachines() {
  this.Loading = true;
  this.$http
    .get('MachineYard-list',{YardId: this.getYardId, CompanyBranchId:this.getBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}


function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nueva')+' '+this.$t('label.machinery');
}
function toggleEdit(item) {
  this.machineId = item.MachineYardId;
  this.machineItems = item;
  this.edit = true;
  this.modal = true;
  let title = this.machineItems.MachineName;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.machinery')+': '+title
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.machineItems = {};
  this.titleModal = '';
}
function resetDataTab(){
  this.items = [];
}
function handleSubmit() {
  this.getListMachines();
}

// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "MachineName",label:this.$t("label.machinery"),_style: 'width:15%;',_classes:'text-center' },
    { key: "TpMachineName", label:this.$t("label.type"),_style: 'width:15%;',_classes:'text-center'},
    { key: "BrandName", label:this.$t("label.brand"),_style: 'width:15%;',_classes:'text-center' },
    { key: "ModelName", label:this.$t("label.model"),_style: 'width:15%;',_classes:'text-center' },
    { key: "Usuario", label: this.$t("label.user"),_style: 'width:10%;',_classes:'text-center' },
    { key: "FormatedDate", label: this.$t("label.date"),_style: 'width:10%;', _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_style: 'width:10%;', _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width: 45px;",
    },
  ];
}

function formatedItems() {
  return this.items.map((items) =>
    Object.assign({}, items, {
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      Fecha: items.TransaRegDate,
      Status: items.Status,
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
export default {
  name: "machines",
  mixins: [General],
  props: {},
  data,
  components: {
    MachineModal,
  },
  mounted() {
    //this.getListMachines();
  },
  methods: {
    getListMachines,
    toggleAdd,
    toggleEdit,
    closeModal,
    resetDataTab,
    handleSubmit,
  },
  computed: {
    fields,
    formatedItems,
    getYardId,
    getBranchId,
    ...mapState({
      activeTab: state => state.yard.tabIndex,
      branch: (state) => state.auth.branch,
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 5) {
        this.getListMachines();
        this.resetDataTab();
      }
    },
  },
};
</script>
<style scoped>
</style>