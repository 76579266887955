import {
    required,
    minValue,
    maxLength,
  } from 'vuelidate/lib/validators';
import { DateFormater } from "@/_helpers/funciones";

  export default (ValidPreviousDate, LaterDate, IssueDate, FinishDate) => {
    return {
      OrderCode: { required },
      TransactionDate: { required, TimeValidate (value){
        return (DateFormater.formatDateTimeWithoutSlash(value) >= ValidPreviousDate && 
          DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
          DateFormater.formatDateTimeWithoutSlash(value) >= IssueDate && 
          DateFormater.formatDateTimeWithoutSlash(value) <= FinishDate)
      } },
      NoOrder: { SelectOrder (value){
        return required
      }, required },
      Transportation: {  },
      vehicle: {  },
      client: {  },
      Yard: { },
      scrapContainerId: { required },
      truckScale: { required },
      MovementType: { required },
      TaraweightKGM: { required },
      TaraweightTON: { required },
      FullweightKGM: { required },
      FullweightTON: { required },
      NetKGM: { required },
      NetTON: { required },
      Observation: { maxLength: maxLength(250) },

    }
  }