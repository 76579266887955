<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.OrdersWeight') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.yard')"
                    :options="YardListFormatted"
                    v-model.trim="YardId"
                    :value.sync="YardId"
                />
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="center-field">
                 <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-4 col-xl-4 text-right', input: 'col-sm-12 col-lg-7 col-xl-7 px-0'}"
                    :label="$t('label.movement')"
                    :options="MovementFormatted"
                    v-model.trim="MovementId"
                    :value.sync="MovementId"
                />
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="center-field">
                <CSelect
                    :value.sync="Transportation"
                    v-model.trim="Transportation"
                    :horizontal="{ label: 'col-sm-12 col-lg-auto col-xl-auto text-right', input: 'col-sm-12 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.transport')"
                    :options="transportationListFormatted"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3">
                <div class="form-group form-row ">
                    <label class="col-sm-12 col-lg-3 col-xl-3 text-right mt-2">{{$t('label.date')}}</label>
                    <div class="input-group col-sm-12 col-lg-9 px-0">
                        <vue-datepicker 
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="searchFilterDate.fromDay"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                        >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker>
                    </div>
                </div>
            </CCol>
            
            <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2">-</label>

            <CCol sm="12" lg="4">
                <CRow>
                    <div class="input-group col-sm-12 col-lg-7 px-2 mb-3">
                        <vue-datepicker 
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="searchFilterDate.toDay"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                        >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker>
                    </div>
                    <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field" style="text-align: right;">
                        <CButton
                            color="watch"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                            @click="getOrderList()"
                            >
                            <CIcon name="cil-search" />
                        </CButton>
                        <CButton
                            color="wipe"
                            class="justify-content-end"
                            size="sm"
                            v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                            @click="refreshComponent()"
                            >
                            <CIcon name="cil-brush-alt" />
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
           
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowStyle="getRowStyle"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';


    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,

            MovementId: '',
            MovementName: '',
            MovementOptions: [],

            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
            },
            Transportation: '',
            TransportationName: '',
            TransportationOptions: [],
            YardId: '',
            YardOptions: [],
            YardName: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            nulo:'', 
            pageSize:'',
            search: '',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass() {
       return 'bg-gradient';
    }

    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }


    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Nro: rowData[i].Nro,
                OrderCode: rowData[i].OrderCode,
                OrderDate: rowData[i].OrderDate,
                CarrierClientName: rowData[i].CarrierClientName,
                CarrierAlias: rowData[i].CarrierAlias,
                YardName: rowData[i].YardName,
                YardClientName: rowData[i].YardClientName,
                LicensePlate: rowData[i].LicensePlate,
                ImplementAlias: rowData[i].ImplementAlias,
                MovementOrderName: rowData[i].MovementOrderName,
                TruckWeghingScaleName: rowData[i].TruckWeghingScaleName.trim(),
                TareWeight: formatMilDecimal(parseFloat(rowData[i].TareWeight).toFixed(2)),
                FullWeight: formatMilDecimal(parseFloat(rowData[i].FullWeight).toFixed(2)),
                NetWeight: formatMilDecimal(parseFloat(rowData[i].NetWeight).toFixed(2)),
                TransactionDate: rowData[i].TransactionDate,
                CraneName: rowData[i].CraneName,
                VesselHoldName: rowData[i].VesselHoldName,
                FinishDate: rowData[i].FinishDate,
                DeviceCode: rowData[i].DeviceCode,
                TransaLogin: rowData[i].TransaLogin,
                OrderStatusDs: rowData[i].OrderStatusDs,
                Status: rowData[i].Status,    
                ColorStatus: rowData[i].ColorStatus,
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.OrdersWeight'), '', {from:22,to:25});
            }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        let currentDate = new Date();
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        let valores = []; 
        
        valores[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
        valores[1] = this.$t('label.yard')+': '+this.YardName;
        valores[2] = this.$t('label.movement')+': '+this.MovementName;
        valores[3] = this.$t('label.transport')+': '+this.TransportationName;
        valores[4] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[5] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Nro: rowData[i].Nro,
                OrderCode: rowData[i].OrderCode,
                OrderDate: rowData[i].OrderDate,
                CarrierClientName: rowData[i].CarrierClientName,
                CarrierAlias: rowData[i].CarrierAlias,
                YardName: rowData[i].YardName,
                YardClientName: rowData[i].YardClientName,
                LicensePlate: rowData[i].LicensePlate,
                ImplementAlias: rowData[i].ImplementAlias,
                MovementOrderName: rowData[i].MovementOrderName,
                TruckWeghingScaleName: rowData[i].TruckWeghingScaleName.trim(),
                TareWeight: rowData[i].TareWeight,
                FullWeight: rowData[i].FullWeight,
                NetWeight: rowData[i].NetWeight,
                TransactionDate: rowData[i].TransactionDate,
                CraneName: rowData[i].CraneName,
                VesselHoldName: rowData[i].VesselHoldName,
                FinishDate: rowData[i].FinishDate,
                DeviceCode: rowData[i].DeviceCode,
                TransaLogin: rowData[i].TransaLogin,
                OrderStatusDs: rowData[i].OrderStatusDs,
                Status: rowData[i].Status,
                AnulationDate: rowData[i].AnulationDate,
                AnulationDeviceCode: rowData[i].AnulationDeviceCode,
                AnulationLogin: rowData[i].AnulationLogin,
                ObservationAnulation: rowData[i].ObservationAnulation,
                ColorStatus: rowData[i].ColorStatus,
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.OrdersWeight'),valor, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    async function getOrderList () {
        this.loadingOverlay = true;
        let _lang = this.$i18n.locale;
        this.formatedItems = [];
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);       

        let LogisticWeigthJson = [{
            VisitId: this.Visit,
            StowagePlanningResourceId: this.Transportation,
            MovementOrderId: this.MovementId,
            YardId :this.YardId,
            DateStart: dateStart,
            DateEnd: dateEnd,
        }];

        await this.$http.post("VisitFullLogisticWeigth-list", LogisticWeigthJson, { root: 'LogisticWeigthJson' })
        .then(response => {
            listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado ==''){
                return false;
            } 
            if(this.YardId === '' || this.YardId === null){
                this.YardName='N/A';  
            }else{
                this.YardName = listado[0].YardName;
            }
            if(this.Transportation === '' || this.Transportation === null){
                this.TransportationName = 'N/A';
            }else{
                this.TransportationName = listado[0].CarrierClientName;
            }
            let MovementData = this.MovementOptions.find( item => item.MovementOrderId === this.MovementId );
            if(MovementData !== '' && MovementData !== null && MovementData !== undefined){
                this.MovementName = _lang=='en' ? MovementData.MovementOrderNameEn : MovementData.MovementOrderNameEs;
            }else{
                this.MovementName = 'N/A';
            }
            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                Nro: listado.Nro ? listado.Nro : '',
                OrderCode: listado.OrderCode ? listado.OrderCode : '',
                OrderDate: listado.OrderDate ?  DateFormater.formatDateTimeWithSlash(listado.OrderDate) : '',
                CarrierClientName: listado.CarrierClientName ? listado.CarrierClientName : '',
                CarrierAlias: listado.CarrierAlias ? listado.CarrierAlias : '',
                YardName: listado.YardName ? listado.YardName : '',
                YardClientName: listado.YardClientName ? listado.YardClientName : '',
                LicensePlate: listado.LicensePlate ? listado.LicensePlate : '',
                ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                MovementOrderNameEn: listado.MovementOrderNameEn ? listado.MovementOrderNameEn : '',
                MovementOrderNameEs: listado.MovementOrderNameEs ? listado.MovementOrderNameEs : '',
                TruckWeghingScaleName: listado.TruckWeghingScaleName ? listado.TruckWeghingScaleName : '',
                TareWeight: listado.TareWeight ? listado.TareWeight : 0,
                FullWeight: listado.FullWeight ? listado.FullWeight  : 0,
                NetWeight: listado.NetWeight ? listado.NetWeight  : 0,
                TransactionDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : '',
                CraneName: listado.CraneAlias ? listado.CraneAlias : '',
                VesselHoldName: listado.VesselHoldName ? listado.VesselHoldName : '',
                FinishDate: listado.FinishDate ? DateFormater.formatDateTimeWithSlash(listado.FinishDate) : '',
                DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
                TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
                OrderStatusDsEn: listado.OrderStatusDsEn ? listado.OrderStatusDsEn : '',
                OrderStatusDsEs: listado.OrderStatusDsEs ? listado.OrderStatusDsEs : '',
                Status:listado.Status ? listado.Status : '',    
                ColorStatus: listado.ColorStatus ? listado.ColorStatus : '',
                AnulationDate: listado.AnulationDate ? DateFormater.formatDateTimeWithSlash(listado.AnulationDate) : '',
                AnulationDeviceCode: listado.AnulationDeviceCode ? listado.AnulationDeviceCode : '',
                AnulationLogin: listado.AnulationLogin ? listado.AnulationLogin : '',
                ObservationAnulation: listado.ObservationAnulation ? listado.ObservationAnulation : '',
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getMovementList() {
        await this.$http.get("ShipmentFullMovementOrder-list", { VisitId: this.Visit })
        .then(response => {       
            this.MovementOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getYardList() {
        await this.$http.get("VisitYardWithTruckWeigth-list", { VisitId: this.Visit })
        .then(response => {       
            this.YardOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getTransportationList() {
        await this.$http.get("VisitResourceCarrierWithTruckWeigth-list", { VisitId: this.Visit })
        .then(response => {
            this.TransportationOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.YardId= '';
        this.YardName= '';
        this.Transportation= '';
        this.TransportationName= '',
        this.MovementId='';
        this.MovementName='';
        this.pageSize="";
        this.getDateRange();
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getTransportationList();
        await this.getYardList();
        await this.getMovementList();
        await this.getOrderList();
    }


    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
//computed
    function RowData(){
        if(this.formatedItems.length != 0){
            let _lang = this.$i18n.locale;
            return this.formatedItems.map((item) => {
                return {
                    ...item,
                    OrderStatusDs: _lang=='en'? item.OrderStatusDsEn : item.OrderStatusDsEs,
                    MovementOrderName: _lang=='en' ? item.MovementOrderNameEn : item.MovementOrderNameEs,
                };
            });
        }else{
            return [];
        }
    }

    function transportationListFormatted(){
        if(this.TransportationOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.TransportationOptions.map(function(e){
                    chart.push({
                        value: e.StowagePlanningResourceId, 
                        label: e.ClientName,
                    })
            })
            return chart;
        }
    }

    function YardListFormatted(){
        if(this.YardOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.YardOptions.map(function(e){
                    chart.push({
                        value: e.YardId, 
                        label: e.YardName,
                    })
                
            })
            return chart;
        }
    }

    function MovementFormatted(){
        if(this.MovementOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let _lang = this.$i18n.locale;
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.MovementOptions.map(function(e){
                    chart.push({
                        value: e.MovementOrderId, 
                        label: _lang=='en'? e.MovementOrderNameEn : e.MovementOrderNameEs,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderCode",
                    headerName: this.$t('label.order'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 180,
                    minWidth: 180,
                   // pinned: 'left',
                    cellClass: 'gb-cell text-color-negrita',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderDate",
                    headerName: this.$t('label.orderDate'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 180,
                    minWidth: 180,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "CarrierClientName",
                    headerName: this.$t('label.transport'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "CarrierAlias",
                    headerName: this.$t('label.transport')+' - '+this.$t('label.alias'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 190,
                    minWidth: 190,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "YardName",
                    headerName: this.$t('label.yard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "YardClientName",
                    headerName: this.$t('label.clientYard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "LicensePlate",
                    headerName: this.$t('label.vehicle'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                    cellClass: 'center-cell-especial',
   
                },
                {
                    field: "ImplementAlias",
                    headerName: this.$t('label.Gamela'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',

                },
                {
                    field: "MovementOrderName",
                    headerName: this.$t('label.movement'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TruckWeghingScaleName",
                    headerName: this.$t('label.truckScale'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TareWeight",
                    headerName: this.$t('label.Taraweight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "FullWeight",
                    headerName: this.$t('label.FullWeight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "NetWeight",
                    headerName: this.$t('label.netWeight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "TransactionDate",
                    headerName: this.$t('label.DateWeighing'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "CraneName",
                    headerName: this.$t('label.crane'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: this.$t('label.hold'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "FinishDate",
                    headerName: this.$t('label.shippingDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 180,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransaLogin",
                    headerName: this.$t('label.user'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DeviceCode",
                    headerName: this.$t('label.device'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "OrderStatusDs",
                    headerName: this.$t('label.status'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial text-color-negrita',
                },
                {
                    field: "AnulationDate",
                    headerName: this.$t('label.AnulationDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationDeviceCode",
                    headerName: this.$t('label.AnulationDeviceCode'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 220,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationLogin",
                    headerName: this.$t('label.AnulationLogin'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ObservationAnulation",
                    headerName: this.$t('label.ObservationAnulation'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 230,
                    cellClass: 'center-cell-especial',
                },
            ]
            return columnDefs;
    }
    export default {
        name: "orders-by-scrap-container",
        data,
        beforeMount,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            getMovementList,
            onGridReady,
            onBtnExport,
            getOrderList,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowStyle,
            getRowClass,
            onBtnPdf,
            getYardList,
            validateDateRange,
            refreshComponent,
            getTransportationList,
            getDateRange,
        },
        computed:{
            RowData,
            transportationListFormatted,
            YardListFormatted,
            MovementFormatted,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==65 && this.Visit != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss" scoped>

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>