<template>
    <div>
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="titleModal"
        color="dark"
        size="lg"
        class="modal-extended"
        @update:show="closeModal()"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
        <CRow>
          <CCol sm="12">
            <CSelect
              :label="$t('label.Supplies')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              :is-valid="hasError($v.Supply)"
              :invalid-feedback="errorMessage($v.Supply)"
              :options="SupplyListFormatted"
              v-model.trim="$v.Supply.$model"
            />
            <CSelect
              :label="$t('label.MeasurementUnit')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              :is-valid="hasError($v.UnitMeasure)"
              :invalid-feedback="errorMessage($v.UnitMeasure)"
              :options="MeasureUnitListFormatted"
              v-model.trim="$v.UnitMeasure.$model"
            />
            <CSelect
              v-if="isEdit"
              :options="statusOptions"
              :value.sync="Status"
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              addLabelClasses="required text-right"
              :is-valid="statusSelectColor"
            />
          </CCol>
        </CRow>
        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="submit()"
            :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
          <CButton 
            color="wipe" 
            class="d-flex align-items-center"
            @click="closeModal()"
          >
            <CIcon name="x" /><span class="ml-1"
              >{{$t('button.cancel')}}</span
            >
          </CButton>
        </template>
      </CModalExtended>
    </div>
  </template>
  <script>
  import GeneralMixin from '@/_mixins/general';
  import ModalMixin from '@/_mixins/modal';
  import UnitMeasureAssociationValidation from '@/_validations/planificacion-estiba/UnitMeasureAssociationValidation';
  
  function data() {
    return {
      SupplyUnitMeasureId: '',
      Supply: '',
      OptionSupply: [],
      UnitMeasure: '',
      OptionMeasureUnit: [],
      Status: 0,
      isEdit: false,
      modalActive: false,
      loadingOverlay: false,
      isSubmit: false,
    };
  }

  function submit() {
    try{
      this.isSubmit = true;
      this.loadingOverlay = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      let SupplyUnitMeasureJson =  this.isEdit ? {
        SupplyUnitMeasureId: this.SupplyUnitMeasureId,
        SupplyId: this.Supply,
        UnitMeasureId: this.UnitMeasure,
        Status: this.Status,
      }:{
        SupplyId: this.Supply,
        UnitMeasureId: this.UnitMeasure,
      }
      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'SupplyUnitMeasure-update':'SupplyUnitMeasure-insert';
      this.$http
      .ejecutar( metodo, ruta, SupplyUnitMeasureJson, { root: 'SupplyUnitMeasureJson' })
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.closeModal();
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: messageSuccess,
              type: "success"
            });
          } 
        }).catch((e) => {
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: "container",
              title: "¡Error!",
              text: e,
              type: "error",
            });
          });
    }catch(e){
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: e,
        type: "error",
      });
    }
  }

  function statusSelectColor() {
    return this.Status === 1;
  }
  
  function closeModal() {
    this.isEdit = false;
    this.Supply = '';
    this.OptionSupply = [];
    this.UnitMeasure = '';
    this.OptionMeasureUnit = [];
    this.Status = 0;
    this.$emit('set-Measurement-Units-list');
  }

  function getSupplyList() {
    this.loadingOverlay = true;
    this.$http.get("Supply-list?GpoSupplyId=&Filter=ACTIVO")
      .then(response => {
        this.OptionSupply = response.data.data;
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function getMeasureUnitList() {
    this.$http.get("UnitMeasure-list?Filter=ACTIVO")
      .then(response => {
        this.OptionMeasureUnit = response.data.data;
        this.loadingOverlay = false;
      }).catch( err => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function getData(val){
    this.SupplyUnitMeasureId = val.SupplyUnitMeasureId;
    this.Supply = val.SupplyId;
    this.UnitMeasure = val.UnitMeasureId;
    this.Status = val.Status == "ACTIVO" ? 1 : 0;
    this.$v.$touch();
  }
  
  function titleModal(){
    if (this.isEdit) {
      return this.$t('label.edit')+' '+this.$t('label.UnitMeasurementAssociation')+': '+this.MeasurementUnits.SupplyName+
        ' - '+this.MeasurementUnits.UnitMeasureName;
    }else{
      return this.$t('label.UnitMeasurementAssociation');
    }
  }

  function SupplyListFormatted(){
    if(this.OptionSupply.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.OptionSupply.map(function(e){
        chart.push({
          value: e.SupplyId, 
          label: e.SupplyName,
        })
      })
      return chart;
    }
  }

  function MeasureUnitListFormatted(){
    if(this.OptionMeasureUnit.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.OptionMeasureUnit.map(function(e){
        chart.push({
          value: e.UnitMeasureId, 
          label: e.UnitMeasureName,
        })
      })
      return chart;
    }
  }
  
  export default {
    name: 'modal-measurement-units',
    props: { modal: Boolean, MeasurementUnits: Object, Edit: Boolean },
    data,
    mixins: [
      GeneralMixin,
      ModalMixin, 
    ],
    validations: UnitMeasureAssociationValidation,
    methods: {
      statusSelectColor,
      getMeasureUnitList,
      getSupplyList,
      getData,
      submit,
      closeModal,
    },
    computed: {
      SupplyListFormatted,
      MeasureUnitListFormatted,
      titleModal,
    },
    watch: {
      modal: async function(val){
        this.modalActive = val;
        if (val) {
          this.getSupplyList();
          this.getMeasureUnitList()
          this.$v.$reset();
          if (this.Edit == true) {
            this.isEdit = this.Edit;
            this.getData(this.MeasurementUnits);
          }
        }
      },
    },
    
  };
  </script>