import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import ENUM from '@/_store/enum';
import ReportesVisitas from '@/_mixins/reportesVisitas';

//Data:
function data() {
  return {
    cranes: [],
    listType: 0,
    subListConfirmed: 0,
    subListPending: 0,
    currentLists: [],
    craneId: '',
    vesselBayId: '',
    selectedPosition: null,
    visitList: [],
    freePositions: false,
    imgModal: false,
    modalContainer: null,
    confirmModal: false,
    freeModal: false,
    reversoModal: false,
    reversoSelected: null,
    loadBaysList: [],
    loadPosition: '',
    loadCodPosition: '',
    helpModal: false,
    // Reporte
    nulo: '',
    reporte: [],
  }
}

//Meodos:
function resetVisitData() {
  this.generalCol = 0;
  this.bayList = [];
  this.baySelected = null;
  this.container = null;
  this.viewType = "3";
  this.activeList = 0;
  this.activePage = 1;

  //Zoom
  this.zoom = 100;
  this.resetZoom();

  //Cargo
  this.idmgList = [];
  this.reeferList = [];
  this.overSizeList = [];
  this.hcList = [];
  this.cargoList = [];

  //Filtro de tabla
  this.portId = "";

  // Ajuste
  this.listType = 0;
  this.subListPending = 0;
  this.subListConfirmed = 0;
  this.currentLists = [];
  this.craneId = '';
  this.vesselBayId = '';
  this.selectedPosition = null;
  this.visitList = [];
  this.freePositions = false;

  this.imgModal = false;
  this.modalContainer = null;

  this.confirmModal = false;
  this.freeModal = false;
  this.reversoModal = false;
  this.reversoSelected = null;

  this.loadBaysList = [];
  this.loadPosition = '';
  this.loadCodPosition = '';

  this.helpModal = false;

  this.nulo = '';
  this.reporte = [];
}

function setVisitLists(){
  if(!this.visit) return;

  this.bayList = this.plano.bayList;
  this.generalCol = this.generalResponsiveCol(this.plano.bayList);
  this.cranes = this.plano.Cranes;
  switch (this.visit) {
    case 'discharge':
      this.currentLists = this.plano.DischageListJson;

      this.puertos = [{ PortCode: this.$t('label.select'), PortId: "123" }].concat(this.plano.DischargePorts);
      break;
    case 'load':
      this.currentLists = this.plano.LoadListJson;

      this.puertos = [{ PortCode: this.$t('label.select'), PortId: "123" }].concat(this.plano.LoadPorts);
      break;
    case 'restow':
      this.currentLists = this.plano.RestowListJson;

      this.puertos = [{ PortCode: this.$t('label.select'), PortId: "123" }].concat(this.plano.LoadPorts);
      break;
    default:
      this.resetVisitData();
      break;
  }
}
function changeVisitActiveList(num) {
  this.activePage = 1;
  // if(this.listType == 0)
    this.subListPending = num;
  // if(this.listType == 1)
    this.subListConfirmed = num;
}
function isVisitActiveList(num) {
  let active = this.listType == 0 ? this.subListPending : this.subListConfirmed;
  return active == num;
}
function handleVisitTab(tab) {
  this.listType = tab;
  //Reset
  this.container = null;
  this.baySelected = null;
  this.freePositions = false;
  this.selectedPosition = null;
  this.visitList = [];
}
function selectVisitContainer(item, index, columnName, event) {

  if( item.Arrival === "" && this.visit === 'discharge'){
     this.container = null;
      this.$swal.fire({
        icon: 'error',
        title: '',
        text: this.$t('label.equipmentDoesNotArrive'),
      });

    return false;
  }

  if(this.listType == 1 &&  item.Departure  === "" && this.visit === 'load'){

    this.container = null;
      this.$swal.fire({
        icon: 'error',
        title: '',
        text: this.$t('label.equipmentDoesNotHaveDeperturePosition'),
      });

    return false;
  }

  if(columnName == 'Details') return;
  if(this.freePositions)
    this.visitList = [];

  if (this.container) {
    if (item.VisitCargoId == this.container.VisitCargoId){
      this.container = null;
      if(this.visit == 'discharge') {
        this.baySelected = null;
      }
      if(this.visit == 'restow') {
        this.restowPositions = { arrival: "", departure: "" };
        this.bayRestowSelected = null;
      }
    } else {
      this.container = { ...item }
      if(this.visit == 'discharge' || (this.visit == 'load' && this.listType == 1)) {
        let found = null;
        for (let index = 0; index < this.bayList.length; index++) {
          let position  = this.bayList[index].PositionJson.find((bay) => (bay.VesselBayPosId == this.container.VesselBayPosId || bay.VesselBayPosIdPair == this.container.VesselBayPosId) || (bay.CodPosition == this.container.PreviousPosition || bay.CodPositionLabel == this.container.PreviousPosition)|| bay.CodPositionLabelPair == item.CodPosition);
          if(position) {
            found = {...this.bayList[index]};
            break;
          }
        }
        this.baySelected = found ? { ...found } : null;
      }
      if(this.visit == 'restow') {
        this.container = { ...item };
        this.searchRestowBays(item);
      }
    }
    if(this.visit != 'restow')
      this.resetZoom();
  } else {
    this.container = { ...item };
    if(this.visit == 'discharge' || (this.visit == 'load' && this.listType == 1)) { 
      let found = null;
      for (let index = 0; index < this.bayList.length; index++) {
        let position  = this.bayList[index].PositionJson.find((bay) => bay.VesselBayPosId == this.container.VesselBayPosId || bay.VesselBayPosIdPair == this.container.VesselBayPosId || (bay.CodPosition == this.container.PreviousPosition || bay.CodPositionLabel == this.container.PreviousPosition) || bay.CodPositionLabelPair == item.CodPosition);
        if(position) {
          found = {...this.bayList[index]};
          break;
        }
      }
      this.baySelected = found ? { ...found } : null;
    }
    if(this.visit == 'restow') {
      this.container = { ...item };
      this.searchRestowBays(item);
    }
    
    if(this.visit != 'restow')
      this.resetZoom();
  }
}
function tableVisitClasses(item) {
  return {
    clickable: true,
    "selected-visit":
      this.container &&
      item.VisitCargoId == this.container.VisitCargoId
        ? true
        : null,
  };
}
function formatedVisitList(list) {
  return list.map((item) =>
    Object.assign({}, item, {
      _classes: this.tableVisitClasses(item),
      Imagen: `${this.$store.getters["connection/getBase"]}${
        item.Route ? item.Route.replace("Public/", "", null, "i") : ""
      }`,
      ContainerCode: item.ContainerCode ? item.ContainerCode : "",
      Arrival: item.CodPosition ? item.CodPosition : "",
      Departure: item.CodPosition ? item.CodPosition : "",
      SizeCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : "",
      IsoCode: item.IsoCode ? item.IsoCode : "",
      Status: item.TpCargoStatusName ? item.TpCargoStatusName : "",
      Carrier: item.ShippingLineCode ? item.ShippingLineCode : "",
      ImdgCode: item.ImdgCode ? item.ImdgCode : "",
      LoadPort: item.LoadPort ? item.LoadPort : "",
      DischargePort: item.DischargePort ? item.DischargePort : "",
      Seals: this.formatSeals(item.Seal1, item.Seal2, item.Seal3, item.Seal4),
      Weight: item.WeigthJson
        ? `${NumberFormater.formatNumber(item.WeigthJson[1].Value, 2)}`
        : "",
      Height: item.HeightJson
        ? `${NumberFormater.formatNumber(item.HeightJson[0].Value, 2)}`
        : "",
      Length: item.LenghtJson
        ? `${NumberFormater.formatNumber(item.LenghtJson[0].Value, 2)}`
        : "",
      DimFront: item.DimFront
        ? `${item.DimFront} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimBack: item.DimBack
        ? `${item.DimBack} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimLeft: item.DimLeft
        ? `${item.DimLeft} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimRight: item.DimRight
        ? `${item.DimRight} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimTop: item.DimTop ? `${item.DimTop} ${item.UnitMeasureDimAbbrev}` : "",
      ReeferSetting: item.ReeferSetting
        ? `${item.ReeferSetting} ${item.UnitMeasureRfAbbrev}`
        : "",
      MinimumRangeRf: item.MinimumRangeRf
        ? `${item.MinimumRangeRf} ${item.UnitMeasureRfAbbrev}`
        : "",
      MaximumRangeRf: item.MaximumRangeRf
        ? `${item.MaximumRangeRf} ${item.UnitMeasureRfAbbrev}`
        : "",
      Crane: item.CraneAlias ? item.CraneAlias : "",
      Device: item.DeviceCode ? item.DeviceCode : "",
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate)
        : "N/A",
      Confirm: item.ConfirmationDate
        ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate)
        : "N/A",
    })
  );
}
function formatSeals(seal1, seal2, seal3, seal4) {
  let arr = [];
  if(seal1 && seal2){
    arr.push(seal1+' - '+seal2);
  } else if(seal1 && !seal2)
    arr.push(seal1);
  else if(!seal1 && seal2)
    arr.push(seal2);

  if(seal3 && seal4){
    arr.push(seal3+' - '+seal4);
  } else if(seal3 && !seal4)
    arr.push(seal3);
  else if(!seal3 && seal4)
    arr.push(seal4);

  // if(seal1) arr.push(seal1);
  // if(seal2) arr.push(seal2);
  // if(seal3) arr.push(seal3);
  // if(seal4) arr.push(seal4);

  return arr;
}
function selectPosition(item) {
  if(this.freePositions) {
    if(item.CargoJson) {
      if(item.CargoJson[0][this.filterByLoadPort.FilterType] != this.filterByLoadPort.PortId){
        let found = this.visitList.findIndex((container) => container.VesselBayPosId == item.VesselBayPosId);
        if(found != -1 && this.visitList.length != 0) {
          this.visitList.splice(found, 1);
        }else {
          this.visitList.push({...item});
        }
      } else {
        this.$swal.fire({
          icon: 'error',
          title: '',
          text: this.$t("label.invalidOptionPort"),
        });
      }
    } else {
      this.$swal.fire({
        icon: 'error',
        title: '',
        text: this.$t("label.invalidOptionPort"),
      });
    }
    return;
  }

  if(this.loadSelectBayAvailable) {
    if(!item.CargoJson || (item.CargoJson && this.visit == 'restow' && item.CargoJson.length>0 && (this.container.ContainerCode == item.CargoJson[0].ContainerCode))) {
      
      if(this.selectedPosition){
        if(this.selectedPosition.VesselBayPosId == item.VesselBayPosId){
          this.selectedPosition = null;
          this.loadPosition = '';
          this.loadCodPosition = '';
        } else {
          this.selectedPosition = {...item};
          this.loadPosition = item.VesselBayPosId;
          this.loadCodPosition = item.CodPositionLabel;
        }
      } else {
        this.selectedPosition = {...item};
        this.loadPosition = item.VesselBayPosId;
        this.loadCodPosition = item.CodPositionLabel;
      }
    } else {
      this.$swal.fire({
        icon: 'error',
        title: '',
        text: this.$t("label.invalidPosition"),
      });
    }
  }
}

function toggleFreePositions(){
  this.freePositions = !this.freePositions;
  this.selectedPosition = null;
  //this.container = {};
  if(!this.freePositions)
    this.visitList = [];
}

function toggleImg(item) {
  this.modalContainer = {...item};
  this.imgModal = true;
}
function toggleConfirm() {
  this.confirmModal = true;
}
function toggleFree() {
  this.freeModal = true;
}
function toggleCheck() {
  if(this.freePositions)
    this.toggleFree();
  else
    this.toggleConfirm();
}

function handleSubmitConfirm(item){

  this.clearAction();

  this.$emit("submit-action", { ...item, sumited : true });
}

function clearAction(){
  this.baySelected = null;
  this.container = null;
  this.freePositions = false;
  this.visitList = [];
  this.selectedPosition = null;
  this.loadBaysList = [];
  this.loadPosition = '';
  this.loadCodPosition = '';
  this.craneId = '';
  if(this.visit == 'restow') {
    this.bayRestowSelected = null;
    this.restowPositions = { arrival: "", departure: "" };
  }
}

function toggleReverse(item) {
  this.reversoSelected = {...item};
  this.reversoModal = true;
}
function refreshData(){
  this.clearAction();
  this.listType = 0;
  this.subListPending = 0;
  this.subListConfirmed = 0;
  this.portId = '123';
  this.$emit("submit-action", { type: 'refresh' });
}
function toggleContainerAlerts(item, type){
  this.alertPosition = { ...item };
  this.alertType = type == 1 ? 'alerta' : 'errores';
  this.alertModal = true;
}
function getBaysListByCraneSize() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;

  let ruta = this.visit == 'restow'
    ? 'VisitVesselBayPosRestow-by-VesselCraneId':'VisitVesselBayPos-by-VesselCraneId';
  let params = this.visit == 'restow'
    ? {
      VisitId: this.VisitId,
      VisitCraneId: this.craneId,
      ContainerCode: this.container.ContainerCode,
    } : {
      VisitId: this.VisitId,
      VisitCraneId: this.craneId,
      Size: this.container.Size,
    };
  this.$http
    .get(ruta, params)
    .then((response) => {
      this.loadBaysList = response.data.data || [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}

function hoverOverall(bay){

  if(this.freePositions) 
    return true;
  else
    if(this.loadSelectBayAvailable)
      return true;
    else
      return false;
}
function toggleHelp(){
  if(this.visit == 'restow' || this.visit == 'load')
    return false;
  
  this.helpModal = true;
}
async function onBtnExport(valor) {
  // this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  await this.getList();
  // let rowData = [];
  // this.formatedReport.forEach(node => rowData.push(node)); //Cambiar
  let label = this.visit == 'discharge'? this.$t('label.listOfDischarges'):this.$t('label.listOfShipments');
  let type = this.listType == 1 ? this.$t('label.confirmed') : this.$t('label.pendings');

  if(this.nulo!="null" && this.formatedReport.length !== 0) { 

    await this.getExcel(this.formatedReport,`${label}(${type})`, valor, this.$t('label.ALL'), DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata), DateFormater.formatDateTimeWithSlash(new Date()));
  }else{
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
  }
  this.$store.state.visitas.apiStateForm = ENUM.INIT;  
}
async function getList () {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  let dateStart = '';
  let dateEnd = '';

  dateStart= DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
  dateEnd= DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(new Date()));

  let CargoListJson = {
    UnitMeasureId: '52388680-623F-4E8A-8DB9-424605963856',
    VisitId: this.Visit,
    CompanyBranchId: this.branch.CompanyBranchId,
    VisitCraneId: '',
    DateStart:dateStart,
    DateEnd:dateEnd,
    UserPreference:this.listType == 0 ? 3:2,
  };

  if(this.visit == 'discharge')
    CargoListJson.DischargePortId = this.branch.PortId;
  if(this.visit == 'load')
    CargoListJson.LoadPortId = this.branch.PortId;

  let ruta = this.visit == 'discharge' ? 'VisitCargoDischargePort-list':'VisitCargoLoadPort-list';

  await this.$http.post(ruta, CargoListJson, { root: 'CargoListJson' })
  .then(response => {
    let listado = [...response.data.data];
    let i = 1;

    this.nulo = String(response.data.data[0].Json);
    this.reporte =  listado.map((item) => {
      return {
        ...item,
        IdX: i++,
      };
    });
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
  })
  .finally(() => {
    // this.$store.state.visitas.apiStateForm = ENUM.INIT;
  });
}
function formatedReport() {
  let computedItems = [];
  let _this = this;
  if(this.reporte.length !== 0 && this.nulo!="null"){
    this.reporte.map((item)=>{
      let object = {};

      object['#'] = item.IdX;
      object[_this.$t('CONTAINER')] =  item.ContainerCode ? item.ContainerCode : '';
      object[_this.$t('SIZE FT')] = item.CargoJson[0].TpCargoDetailCode ? item.CargoJson[0].TpCargoDetailCode : '';
      object[_this.$t('STATUS')] = item.CargoJson[0].StatusCargo ? item.CargoJson[0].StatusCargo : '';
      object[_this.$t('CRANE')] = item.CraneAlias ? item.CraneAlias : '';
      object[_this.$t('ISO CODE')] =  item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '';
      object[_this.$t('TYPE')] = item.CargoJson[0].TpCargoName ? item.CargoJson[0].TpCargoName : '';
      object[_this.$t('LOAD PORT')] = item.CargoJson[0].LoadPort ? item.CargoJson[0].LoadPort : '';
      object[_this.$t('WEIGHT(TON)')] = item.WeigthJson ? `${NumberFormater.formatNumber(item.WeigthJson[1].Value, 2)}`: 0;
      object[_this.$t('SHIPPING LINE CODE')] =  item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '';
      object[_this.$t('SHIPPING LINE')] = item.CargoJson[0].ShippingLineName ? item.CargoJson[0].ShippingLineName : '';
      object[_this.$t('IMDG CODE')] = item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '';
      object[_this.$t('BL')] = item.CargoJson[0].BlNo ? item.CargoJson[0].BlNo : '';
      object[_this.$t('POSITION')] = item.CargoJson[0].BaySlot ? item.CargoJson[0].BaySlot : '';
      object[_this.$t('GENERAL INFO')] = item.CargoJson[0].GeneralInfo ? item.CargoJson[0].GeneralInfo : '';
      object[_this.$t('VGM CERTIFICATE')] = item.CargoJson[0].VgmCertificate ? item.CargoJson[0].VgmCertificate : '';
      object[_this.$t('VGM')] = item.VgmJson ? `${NumberFormater.formatNumber(item.VgmJson[1].Value, 2)}`: 0;
      object[_this.$t('SEALS')] = item.CargoJson[0].Seals ? item.CargoJson[0].Seals : '';
      object[_this.$t('DELIVERY PORT')] = item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '';
      object[_this.$t('DISCHARGE PORT')] = item.CargoJson[0].DischargePort ? item.CargoJson[0].DischargePort : '';
      object[_this.$t('CONFIRMATED')] =  item.FgConfirmed === true ? _this.$t('label.yes') : 'NO';
      object[_this.$t('CONFIRMATION DATE')] =  item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '';
      object[_this.$t('DEVICE CODE')] = item.DeviceCode ? item.DeviceCode : '';
      object[_this.$t('USER')] = item.TransaLogin ? item.TransaLogin : '';

      computedItems.push(object)
    })
  }
  return computedItems;
}

//Computeds
function cranesOptions() {
  return this.cranes.map((port) =>
    Object.assign({}, port, {
      label: port.CraneAlias,
      value: port.VisitCraneId,
    })
  );
}
function isCranesEmpty() {
  return this.cranes.length == 0;
}
function baysOptions() {
  if(this.visit == 'discharge') {
   return this.bayList.map((bay) =>
      Object.assign({}, bay, {
        label: bay.BayCode,
        value: bay.VesselBayId,
      })
    );
  } else {
   let list = this.bayList.filter((el) => {
      return this.loadBaysList.some((f) => {
        return f.VesselBayId === el.VesselBayId;
      });
    });

    return list.map((bay) =>
      Object.assign({}, bay, {
        label: bay.BayCode,
        value: bay.VesselBayId,
      })
    );
  }
}
function filteredVisitList() {
  if(this.currentLists.length == 0) return [];

  let list = [];
  let active = this.listType == 0 ? this.subListPending : this.subListConfirmed;
  
  let type; 
  
  this.currentLists.map((e, index)=>{
    if(this.listType == 0 && !e.FgConfirmed)
      type = index;
    if(this.listType == 1 &&  e.FgConfirmed)
      type = index;
  })


  if (active == 0) list = this.currentLists[type].CargoJson;
  if (active == 1) list = this.currentLists[type].StandarJson;
  if (active == 2) list = this.currentLists[type].HighCubeJson;
  if (active == 3) list = this.currentLists[type].OversizeJson;
  if (active == 4) list = this.currentLists[type].ReeerJson;
  if (active == 5) list = this.currentLists[type].ImdgJson;

  return this.formatedVisitList(list);
}
function visitFields() {
  let fields = [
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Arrival",
      label: this.visit == 'load' && this.listType == 1? 'DEPARTURE POSITION':"ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:10%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:3%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:3%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:3%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:3%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsReefer = [
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Arrival",
      label: this.visit == 'load' && this.listType == 1? 'DEPARTURE POSITION':"ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:10%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTINGS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "MinimumRangeRf",
      label: "MINIMUM RANGE RF",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "MaximumRangeRf",
      label: "MAXIMUM RANGE RF",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsImdg = [
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Arrival",
      label: this.visit == 'load' && this.listType == 1? 'DEPARTURE POSITION':"ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:10%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsHc = [
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Arrival",
      label: this.visit == 'load' && this.listType == 1? 'DEPARTURE POSITION':"ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:10%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];

  let activa = this.listType == 0 ? this.subListPending : this.subListConfirmed;
  let arr = [];

  switch (activa) {
    case 0:
      arr = fieldsImdg;
      break;
    case 1:
      arr = fieldsImdg;
      break;
    case 2:
      arr = fieldsHc;
      break
    case 3:
      arr = fields;
      break;
    case 4:
      arr = fieldsReefer;
      break;
    case 5:
      arr = fieldsImdg;
      break;

    default:
      arr = fields;
      break;
  }

  if(this.visit == 'load' && this.listType == 0) {
    arr.splice(2, 1);
  }
  // if(this.visit == 'restow') {
  //   arr.splice(2, 1);
  // }

  if(this.listType == 1) {
    return arr.concat([
      {
        key: "Crane",
        label: this.$t("label.crane"),
        _classes: "center-item",
        _style: "width:1%; text-align: center;",
      },
      {
        key: "Device",
        label: this.$t("label.device"),
        _classes: "center-item",
        _style: "width:1%; text-align: center;",
      },
      {
        key: "Usuario",
        label: this.$t("label.user"),
        _classes: "center-item",
        _style: "width:1%; text-align: center;",
      },
      {
        key: "Confirm",
        label: this.$t("label.date"),
        _classes: "center-item",
        _style: "width:1%; text-align: center;",
      },
    ]);
  } else return arr;
}
function getSublistTab(){
  return this.listType == 0 ? this.subListPending : this.subListConfirmed;
}
function currentAction() {
  if(this.freePositions) {
    return 'LIBERAR';
  }
  if(this.container) {
    return 'CONFIRMAR';
  }

  return '';
}
function visitPort() {
  let port = null,
      type = '';
  
  port = this.puertos.find((item) => item.PortId === this.portId);

  if(!this.visit){
    type = (this.portId && this.portId != "123") ? "LoadPortId" : "";
  }
  else{
    switch (this.visit) {
      case 'discharge':
        type = 'DischargePortId';
        break;
      case 'load':
        type = 'LoadPortId';
        break;
      case 'restow':
        type = 'LoadPortId';
        break;
      default:
        type = '';
        break;
    }
  }

  return {
    FilterType: type,
    PortId: port ? port.PortId : "",
    PortCode: port ? port.PortCode : "",
    Color: port ? port.Color : "",
  };
}

function visitOverall() {

}
function loadSelectBayAvailable() {
  return (this.visit == 'load' && this.craneId || this.visit == 'restow') && this.container && this.listType == 0;
}
function currentCrane() {
  let found = this.cranes?.find((item) => item.VisitCraneId == this.craneId);
  
  return found ? found : null;
}
function currentContainer(){
  return this.container;
}

function actionAvailable(){
  if(this.baySelected == null) return true;

  if(this.visit == 'discharge') {
    if(this.listType == 0)
      return false;
    else
      return true;
  }

  if(this.visit == 'load') {
    if(this.freePositions && this.visitList.length != 0) {
      return false;
    } else if(!this.loadSelectBayAvailable || !this.selectedPosition)
      return true;
    else
      return false;
  }
}

function tabLabelPending() {
  if(this.visit == 'restow') return this.$t('label.listOfOnBoardContainers');
  else return this.$t('label.pendings');
}
function tabLabelCancel() {
  if(this.visit == 'restow') return this.$t('label.Restow');
  else return this.$t('label.confirmed');
}

export default {
  data,
  mixins: [ReportesVisitas],
  methods: {
    resetVisitData,
    setVisitLists,
    changeVisitActiveList,
    isVisitActiveList,
    handleVisitTab,
    selectVisitContainer,
    formatSeals,
    formatedVisitList,
    tableVisitClasses,
    selectPosition,
    toggleFreePositions,
    toggleImg,
    toggleConfirm,
    toggleFree,
    toggleCheck,
    handleSubmitConfirm,
    clearAction,
    toggleReverse,
    refreshData,
    toggleContainerAlerts,
    getBaysListByCraneSize,
    toggleHelp,
    onBtnExport,
    getList,
  },
  computed: {
    cranesOptions,
    isCranesEmpty,
    baysOptions,
    filteredVisitList,
    visitFields,
    getSublistTab,
    currentAction,
    visitPort,
    visitOverall,
    loadSelectBayAvailable,
    currentCrane,
    currentContainer,
    hoverOverall,
    actionAvailable,
    tabLabelPending,
    tabLabelCancel,
    formatedReport,
  },
  watch: {
    plano: {
      handler(val){
        this.setVisitLists();
      },
      deep: true
    },
    loadSelectBayAvailable: function (newValue) {
      if (newValue && this.visit != 'restow') {
        this.baySelected = null;
        this.getBaysListByCraneSize();
      } else {
        this.loadBaysList = [];
      }
    },
    craneId: function (newValue) {
      if (newValue && this.loadSelectBayAvailable && this.$store.state.visitas.apiStateForm == 0 ) {
        if( this.visit != 'restow' ) this.baySelected = null;
        this.getBaysListByCraneSize();
      }
    },
    currentContainer: function (newValue) {
      if (newValue && this.loadSelectBayAvailable && this.$store.state.visitas.apiStateForm == 0 && this.visit != 'restow') {
        this.baySelected = null;
        this.getBaysListByCraneSize();
      }
    },
    imgModal: function (newValue) {
      if (!newValue) {
        this.modalContainer = null;
      }
    },
    vesselBayId: function (newValue) {
      if (newValue) {
        let found  =  this.baysOptions.find((item) => item.VesselBayId == newValue);
        if(found) 
          this.baySelected = {...found};
      }
    },
  }
}