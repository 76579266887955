<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <EmpresaModal :modal="empreModal" @cerrarModal="empreModal=false" @child-refresh="refrescarEmpresa=true" />
    <SucursalModal :modal="sucModal" @cerrarModal="sucModal=false" @child-refresh="refrescarSucursal" />
    <PatioModal :modal="patModal" @cerrarModal="patModal=false" />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.companyBranchList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CustomTabs :active-tab="0" class="text-table">
          <CustomTab :title="$t('label.company')" class="text-table">
            <template #title>
                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Company.png" alt="Card image cap">
                {{$t('label.company')}}                
            </template>
            <CRow class="mt-2">
              <CCol sm="12" class="text-right">
                <CButton square color="add" @click="empreModal=true"
                v-c-tooltip="{
                          content:$t('label.company') ,
                          placement: 'top-end'
                        }"
                >
                  <CIcon name="cil-playlist-add"/>&nbsp;
                  {{$t('label.nueva')}}
                </CButton>
              </CCol>
              <CCol sm="12">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items="itemsEmpresa"
                  :fields="fieldsEmpresa"
                  :loading="LoadingEmpresa"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                >
                  <template #loading>
                      <loading/>
                  </template>
                  <template #LogoRoute="{ item }">
                    <td class="center-cell ">
                        <c-img-extended 
                          :src="item.LogoRoute"
                          :key="item.Nro"
                          :error-options="{ width: 200, height: 150, fontSize: 40 }"
                          block
                          thumbnail
                          class="img-logo"
                        />
                    </td>
                  </template>
                  <template #Status="{item}">
                    <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                    <td class="center-cell">
                      <CButton color="edit" square size="sm" v-c-tooltip="{placement:'top-end',
                      content: $t('label.edit') + ' ' + $t('label.company') }" @click="empreModal=item" >
                        <CIcon name="pencil" />
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
          </CustomTab>
          <CustomTab :title="$t('label.branch')">
            <template #title>
              <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Branch.svg" alt="Card image cap">
              {{$t('label.branch')}}                
            </template>
            <CRow class="mt-2">
              <CCol sm="7" class="d-flex justify-content-start">  
               <label class="mt-2 mr-2"><b class="text-danger">*</b> {{$t('label.company')}}</label>             
                <v-select                                  
                  addLabelClasses="text-right"                                   
                  :filter="fuseSearchEmpresa"
                  :options="empresaOptions"
                  v-model="CompanyId"
                  :placeholder="$t('label.select')"
                  class="w-75"
                />
              </CCol>
              <CCol sm="5" class="text-right">
                <CButton square color="add" @click="abrirSucursal(true)"
                v-c-tooltip="{placement:'top-left',content:$t('label.branch')}"
                >
                  <CIcon name="cil-playlist-add"/>&nbsp;
                  {{$t('label.nueva')}}
                </CButton>
              </CCol>
              <CCol sm="12">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items="itemsSucursal"
                  :fields="fieldsSucursal"
                  :loading="LoadingSucursal"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                >
                  <template #loading>
                      <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                    <td class="py-2">
                      <CButton color="edit" square size="sm" v-c-tooltip="{placement:'top-end',content: $t('label.edit') 
                      + ' ' + $t('label.branch') }" @click="abrirSucursal(item)" >
                        <CIcon name="pencil" />
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
          </CustomTab>
        </CustomTabs>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import CImgExtended from '@/components/CImgExtended';
import { DateFormater, GenerateImgPlaceholder } from '@/_helpers/funciones';
import EmpresaModal from './empresa-modal';
import SucursalModal from './sucursal-modal';
import PatioModal from './patio-modal';
import General from '@/_mixins/general';
import Fuse from "fuse.js";

function fieldsEmpresa (){
return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:50px;'
      ,filter:false,
      _classes:"text-center center-cell"      
    },
    { key: 'CompanyRif', label:'RIF',_classes:"text-center center-cell", _style: 'width:150px;'},
    { key: 'CompanyName', label:this.$t('label.company'), _style: 'width:230px;',_classes:"text-center center-cell" },
    { 
      key: 'LogoRoute',
      label: 'LOGO',
      _classes:"text-center center-cell",
      sorter: false,
      filter: false,
      _style: 'width:50px;',
      _classes:"text-center center-cell",
    },
    { key: 'Latitude', label:this.$t('label.latitude') ,_classes:"text-center center-cell", _style: 'width:150px;' },
    { key: 'Longitude', label:this.$t('label.longitude'),_classes:"text-center center-cell"},
    { key: 'Usuario', label:this.$t('label.user'), _classes:"text-center center-cell",_style: 'width:150px;' },
    { key: 'Fecha', label:this.$t('label.date'),_classes:"text-center center-cell",_style:'width:100px;' },
    { key: 'Status', label:this.$t('label.status'),_classes:"text-center center-cell",_style:'width:50px;' },
    { 
      key: 'Detalle',
      label: '',
      _style:'width:50px;',      
      _classes:'center-cell',
      sorter: false,
      filter: false,
      _classes:"text-center center-cell"     
    }
  ];
}
function fieldsSucursal (){
  return[
      { 
        key: 'Nro', 
        label: '#',
        _style: 'width:50px;',
        filter:false,
        _classes:"text-center center-cell"
      },
      { key: 'Sucursal', label:this.$t('label.branch'),_classes:"text-center center-cell"},
      { key: 'Latitude', label:this.$t('label.latitude'),_classes:"text-center center-cell"},
      { key: 'Longitude', label:this.$t('label.longitude'),_classes:"text-center center-cell" },
      { key: 'Usuario', label:this.$t('label.user'),_classes:"text-center center-cell" },
      { key: 'Fecha', label:this.$t('label.date'),_classes:"text-center center-cell",_style:"width:100px;" },
      { key: 'Status', label:this.$t('label.status'),_classes:"text-center center-cell",_style:"width:50px;" },
      { 
        key: 'Detalle',
        label: '',
        _style: 'width:50px;',
        _classes:'center-cell',
        sorter: false,
        filter: false,
        _classes:"text-center center-cell"
      }
    ];
}
//DATA
function data () {
  return {
    itemsEmpresa: [],
    itemsSucursal: [],
    empresas: [],
    CompanyId: '',
    empreModal: false,
    sucModal: false,
    patModal: false,
    LoadingSucursal: false,
    LoadingEmpresa: false,
    Loading: false,    
    refrescarEmpresa: false,
  }
}

function abrirSucursal(item) {
  this.sucModal = {Item: item, Empresas: this.empresas };
}

function filtroSucursal(listado) {
  this.itemsSucursal = listado.map(listado => Object.assign({}, this.itemsSucursal, {
    Nro: listado.Nro,
    CompanyBranchId: listado.CompanyBranchId,
    CompanyId: listado.CompanyId, 
    CompanyName: listado.CompanyName, 
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Status: listado.Status,
    CompanyRif: listado.CompanyRif,
    CountryId: listado.CountryId,
    CountryName: listado.CountryName,
    StateId: listado.StateId,
    StateName: listado.StateName,
    MunicipalityId: listado.MunicipalityId,
    MunicipalityName: listado.MunicipalityName,
    ParishId: listado.ParishId,
    ParishName: listado.ParishName,
    PortId: listado.PortId ? listado.PortId : '',
    PortName: listado.PortName ? listado.PortName : '',
    BranchLocation: listado.BranchLocation,
    Latitude: listado.Latitude,
    Longitude: listado.Longitude,
    Sucursal: listado.BranchName,
    FgActBranch: listado.FgActBranch,
    _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
  }));

  return this.itemsSucursal;
}

function filtroEmpresa(listado) {
  this.itemsEmpresa = listado.map(listado => Object.assign({}, this.itemsEmpresa, {
    Nro: listado.Nro,
    CompanyRif: listado.CompanyRif,
    CompanyName: listado.CompanyName, 
    LogoRoute: listado.LogoRoute ? `${this.$store.getters["connection/getBase"]}${listado.LogoRoute?listado.LogoRoute.replace('public/', '', 'i'):''}` : GenerateImgPlaceholder(),
    Logo: listado.LogoRoute,
    Latitude: listado.Latitude,
    Longitude: listado.Longitude,
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Status: listado.Status,
    CompanyId: listado.CompanyId,
    FgActEmpresa: listado.FgActEmpresa,
    CompanyObject: listado.CompanyObject,
    CompanyLocation: listado.CompanyLocation,
    CountryId: listado.CountryId,
    CountryName: listado.CountryName,
    StateId: listado.StateId,
    StateName: listado.StateName,
    MunicipalityId: listado.MunicipalityId,
    MunicipalityName: listado.MunicipalityName,
    ParishId: listado.ParishId,
    ParishName: listado.ParishName,
    FiscalAddress: listado.FiscalAddress,
    PrimaryPhone: listado.PrimaryPhone,
    SecondaryPhone: listado.SecondaryPhone,
    PrimaryEmail: listado.PrimaryEmail,
    SecondaryEmail: listado.SecondaryEmail,
    FgActCompany: listado.FgActCompany,
    _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
  }));

  return this.itemsEmpresa;
}

function listarSucursales (empresa) {
  this.itemsSucursal = [];
  this.LoadingSucursal = true;

  let listado = Array;
  this.$http.get("Branch-list-by-company", { CompanyId: empresa })
  .then(response => {
    listado = [...response.data.data];
    this.filtroSucursal(listado);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.LoadingSucursal = false;
  });
}

function listarEmpresas () {
  this.itemsEmpresa = [];
  this.Loading = true;

  let listado = Array;
  this.$http.get("Company-list", { filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data];
    this.filtroEmpresa(listado);
    this.empresas = listado.filter(ob => ob.Status==="ACTIVO" );
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading = false;
    this.LoadingEmpresa = false;
  });
}

function fuseSearchEmpresa(options, search) {
  const fuse = new Fuse(options, {
    keys: ["CompanyName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function empresaOptions() {
  return this.empresas.map((ob) => Object.assign({}, this.empresas, {
    label: ob.CompanyName,
    value: ob.CompanyId
  }));
}

function refrescarSucursal(newVal) {
  this.listarSucursales(newVal);
}

export default {
  name: 'index',
  mixins: [General],
  data,
  components: {
    EmpresaModal,
    SucursalModal,
    PatioModal,
    CustomTabs,
    CustomTab,
    CImgExtended
  },
  methods: {
    listarEmpresas,
    listarSucursales,
    filtroEmpresa,
    filtroSucursal,
    fuseSearchEmpresa,
    abrirSucursal,
    refrescarSucursal
  },
  watch: {
    CompanyId: function(item) {
      if(item){
        this.listarSucursales(item.value);
      }
    },
    refrescarEmpresa: function () {
      if (this.refrescarEmpresa) {
        this.listarEmpresas();
        this.refrescarEmpresa = false;
      }
    }
  },
  computed: {
    empresaOptions,
    fieldsSucursal,
    fieldsEmpresa,
  },
  mounted() {
    this.listarEmpresas();
  },
}
</script>

<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
 .img-logo {
    width: 50px;
  }
.center-cell {
  text-align: center;
}

.text-table .custom-tab-content {
    background: #fff;
    border: 0;
    outline: none;
    border-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

</style>