<template>
     <div>
        <CModalExtended
            color="dark"
            size="lg"
            :closeOnBackdrop="false"
            :show.sync="$store.state.contenedores.modalCode"
            :title="$t('label.codesDocuments')+': '+this.$store.state.contenedores.modalName"
            :close-on-backdrop="false"
        >
            <CRow class="m-2">
                 <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        column-filter
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :items="formatedItems"
                        :fields="fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="5"
                        :active-page="activePage"
                        pagination
                         
                    >
                        <template #MetadataId="{ index}">
                            <td class="text-center">
                                {{index+1}}
                            </td>
                        </template>
                        <template #Status="{item}">
                            <td class="text-center">
                                <CBadge v-if="item.status" color="success">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                                <CBadge v-if="!item.status" color="danger">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import { DateFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            activePage: 1,
            
           
        }
    }
    //methods
    function cerrarModal(){
        this.$store.state.contenedores.modalCode = false;
    }

    //computed
    function formatedItems() {
        let computedItems = this.MetadataJson;

        return computedItems.map((item) => Object.assign({}, item, {
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '',
            status: item.FgActMetadata,
            _classes: ( item.FgActMetadata ) ? '' : 'table-danger'
        }));

    }

    function fields() {
        return [
            { key: 'MetadataId', label: '#',filter: false, _style: 'width:3%; text-align:center;', },
            { key: 'MetadataDesc',label: this.$t('label.code'), _style:'width:20%;' },
            { key: 'StandardDocumentName',label: this.$t('label.standard'), _style:'width:30%;' },
            { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 15%;', _classes:'text-left'},
            { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
            { key: 'Status',label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center' },
            
        ];
    }
    //watch
    function modalCode(newQuestion){
        if(newQuestion === false){
            this.$store.state.contenedores.MetadataJson = [];
        }
    }
    export default {
        name: 'modal-codes',
        mixins: [GeneralMixin],
        data,
        methods:{
            cerrarModal
        },
        computed:{
            formatedItems,
            fields,
            ...mapState({
                modalCode: state => state.contenedores.modalCode,
                MetadataJson: state=> state.contenedores.MetadataJson,
            })
        },
        watch:{
            modalCode
            
        }
    }
</script>  