<template>
  <div class="row mx-0 mt-3">
    <CCol sm="12" lg="6">
      <CSelect
        size="sm"
        class="mb-3"
        :label="`${this.$t('label.measureUnit')} DM`"
        addLabelClasses="text-right required"
        :placeholder="$t('label.select')"
        :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
        :options="UnitMeasureDimOptions"
        v-model="$v.OversizedEquipment.UnitMeasureDim.$model"
        :is-valid="hasError($v.OversizedEquipment.UnitMeasureDim)"
        :invalid-feedback="errorMessage($v.OversizedEquipment.UnitMeasureDim)"
        @change="DimConversion($event.target.value)"
      />

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-6 mb-0 text-right required">{{`${$t('label.DimFront')}`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.OversizedEquipment.DimFront.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.OversizedEquipment.DimFront.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.OversizedEquipment.DimFront.$error">
            {{ errorMessage($v.OversizedEquipment.DimFront) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-6 mb-0 text-right required">{{`${$t('label.DimBack')}`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.OversizedEquipment.DimBack.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.OversizedEquipment.DimBack.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.OversizedEquipment.DimBack.$error">
            {{ errorMessage($v.OversizedEquipment.DimBack) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.DimLeft')}`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.OversizedEquipment.DimLeft.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.OversizedEquipment.DimLeft.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.OversizedEquipment.DimLeft.$error">
            {{ errorMessage($v.OversizedEquipment.DimLeft) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.DimRight')}`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.OversizedEquipment.DimRight.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.OversizedEquipment.DimRight.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.OversizedEquipment.DimRight.$error">
            {{ errorMessage($v.OversizedEquipment.DimRight) }}
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.DimTop')}`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.OversizedEquipment.DimTop.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.OversizedEquipment.DimTop.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.OversizedEquipment.DimTop.$error">
            {{ errorMessage($v.OversizedEquipment.DimTop) }}
          </div>
        </div>
      </div>
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
  
function data() {
  return {
    measure: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
    },
  };
}

//computed
function UnitMeasureDimOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureDimList.map((item) => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function DimConversion(Id) {
  this.OversizedEquipment.UnitMeasureDim = Id ?? '';
  if (this.OversizedEquipment.UnitMeasureDim) {
    if (this.OversizedEquipment.PreviousDimUnitMeasure) {
      
    }
    this.OversizedEquipment.PreviousDimUnitMeasure = this.OversizedEquipment.UnitMeasureDim;
  }else{
    this.OversizedEquipment.DimFront = 0;  
    this.OversizedEquipment.DimBack = 0 ;
    this.OversizedEquipment.DimLeft = 0 ;
    this.OversizedEquipment.DimRight = 0 ;
    this.OversizedEquipment.DimTop = 0 ;
    this.OversizedEquipment.PreviousDimUnitMeasure = '';
  }
}
  
export default {
  name: 'reefer-equipment-tab',
  components: {
    Money,
  },
  data,
  beforeDestroy() {
    this.OversizedEquipment.UnitMeasureDim = '';
    this.OversizedEquipment.PreviousDimUnitMeasure = '';
    this.OversizedEquipment.DimFront = 0;
    this.OversizedEquipment.DimBack = 0;
    this.OversizedEquipment.DimLeft = 0;
    this.OversizedEquipment.DimRight = 0;
    this.OversizedEquipment.DimTop = 0;
  },
  props: {
    OversizedEquipment: {
      type: Object,
      required: true,
      default: () => {},
    },
    UnitMeasureDimList: {
      type: Array,
      default: () => [],
    },
    Validate: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
    DimConversion,
  },
  computed:{
    UnitMeasureDimOptions,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.OversizedEquipment.$touch();
      }else{
        this.$v.$reset();
      }
    },
  }
};
</script>