<template>
  <div class="col-sm-12"  style="height: 100%;">
    <apexchart :width="Width" :height="390" type="radialBar" :options="chartOptions" :series="[90]"></apexchart>
  </div>
</template>
<script>
function chartOptions(){
  return {
    chart: {
              height: 350,
              type: 'radialBar',
              offsetY: -10
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                barLabels: {
                    enabled: true,
                    offsetX: 0,
                    offsetY: 0,
                    useSeriesColors: true,
                    fontFamily: undefined,
                    fontWeight: 600,
                    fontSize: '16px',
                    formatter: (val) => {
                      return val
                    },
                    onClick: undefined,
                },
                dataLabels: {
                  name: {
                    show: false,
                    fontSize: '16px',
                    color: undefined,
                    offsetY: 120
                  },
                  value: {
                    show: false,
                    offsetY: 76,
                    fontSize: '22px',
                    color: undefined,
                    formatter: function (val) {
                      return val + "%aa";
                    }
                  }
                },
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.15,
                  inverseColors: false,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 50, 65, 91]
              },
            },
            stroke: {
              dashArray: 4
            },
            labels: ['Median Ratio'],
            legend: {
                show: true
            }
          }
          
          
        }

export default {
  name:'pieDonut',
  props: {
    Series: {
      type: Array,
      require: true,
      default: () => [],
    },
    Label:{
      type: Array,
      require: true,
      default: () => [],
    },
    Colors:{
      type: Array,
      require: false,
      default: () => [],
    },
    Width:{
      type: String,
      require: true,
      default: 'auto',
    },
    Height:{
      type: String,
      require: true,
      default: 'auto',
    },
    dataLabelsBar: Object,
  },
  methods:{
  },
  computed:{
    chartOptions
  },
}
</script>