<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow>
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mb-5 mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.listOfImdgContainers')}}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>

        
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"      
                    v-model="search"     
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getImdgList"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
            size="lg"
        >   
            <CRow class="m-1">
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">SIZE FT</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.TpCargoDetailCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="5" lg="5" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DISCHARGE PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DischargePort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3" >
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">WEIGHT</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Weigth"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">IMDG CODE</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ImdgCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="5" lg="5" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8  col-lg-8 col-xl-8 text-right">EMERGENCY SCHEDULE ID</label>
                        <div class="col-sm-4  col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.EmergencySheduleNo"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">UN NUMBER</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnNumber"
                            />
                        </div>
                    </div>
                </CCol>                
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">CARRIER CODE</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLineCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="5" lg="5" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">CARRIER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLineName"
                            />
                        </div>
                    </div>
                </CCol>
                
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">IMDG PAGE NO</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ImgdPageNo"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">IsoCode</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.IsoCode"
                            />
                        </div>
                    </div>
                </CCol>
                
                <CCol sm="5" lg="5" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DELIVERY PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DeliveryPort"
                            />
                        </div>
                    </div>
                </CCol> 
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">FLASH POINT</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.FlashPoint"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">PACKAGING GROUP</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.PackagingGroupCode"
                            />
                        </div>
                    </div>
                </CCol>  
                <CCol sm="5" lg="5" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">LOAD PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.LoadPort"
                            />
                        </div>
                    </div>
                </CCol>     
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">STATUS</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Status"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">SEALS</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seals"
                            />
                        </div>
                    </div>
                </CCol> 
                <CCol sm="3" lg="3" xl="5">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">YARD</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Yard"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">PROCEDENCE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Procedence"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-6 col-xl-6 text-right">DIRECT DISCHARGE</label>
                        <div class="col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DirectDischarge"
                            />
                        </div>
                    </div>
                </CCol> 
            </CRow>
            <div slot="footer">
                <CButton outline color="wipe" @click="modalColumn=false">
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                Weigth:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
                UnNumber:true,
                ImgdPageNo:true,
                FlashPoint:true,
                PackagingGroupCode:true,
                EmergencySheduleNo:true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            nulo:'', 
            pageSize:'',
            search:'',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;        

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            rowData.map((item)=>{
                computedItems.push({
                    IdX: item.IdX,
                    BayCode: item.BayCode ? item.BayCode : '',
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    ContainerCode: item.ContainerCode,
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    Weigth: formatMilDecimal(Number.parseFloat(item.Weigth).toFixed(2)),
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ImgdPageNo: item.ImgdPageNo ? item.ImgdPageNo : '',
                    FlashPoint: item.FlashPoint ? item.FlashPoint : '',
                    PackagingGroupCode: item.PackagingGroupCode ? item.PackagingGroupCode : '',
                    EmergencySheduleNo: item.EmergencySheduleNo ? item.EmergencySheduleNo : '',
                    Seals: item.Seals ? item.Seals : '', 
                    IsoCode: item.IsoCode ? item.IsoCode : '', 
                    Status: item.Status ? item.Status : '', 
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '', 
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect
                })
            })
            await this.getPdf(computedItems,this.$t('label.listOfPlannedImdgContainers'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.listOfPlannedImdgContainers'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false; 
    }

    function buscarUnidad(e) {
        this.unitMeasureId=e.target.value;
        this.getImdgList();
    }

    async function getImdgList () {
        this.loadingOverlay = true;

        let CargoSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };

        await this.$http.post("StowagePlanningCargoImdg-list", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            let listado = [...response.data.data];
            let i = 1;
            this.nulo = String(response.data.data[0].Json);
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                    IdX: i++
                };
            });
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-edit text-white font-weight-bold'
        }
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                //lockVisible: true,
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                //pinned: 'left',
                //checkboxSelection: true, 
            },
            {
                field: "BayCode",
                headerName: "BAY",
                //lockVisible: true,
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 80,
                cellClass: 'gb-cell',
                //pinned: 'left',
                //checkboxSelection: true, 
            },
            {
                field: "BaySlot",
                headerName: "SLOT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 150,
                //pinned: 'left',
                cellClass: 'gb-cell',

            },
            {
                field: "ContainerCode",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 150,
                //pinned: 'left',
                cellClass: 'gb-cell',
            },
        ]

        if(this.columnsOption.TpCargoDetailCode){
            columnDefs.push({
                field: "TpCargoDetailCode",
                headerName: "SIZE (FT)", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
        if(this.columnsOption.IsoCode){
            columnDefs.push({
                field: "IsoCode",
                headerName: "ISO CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
        if(this.columnsOption.Seals){
            columnDefs.push({
                field: "Seals",
                headerName: "SEALS", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
        if(this.columnsOption.Status){
            columnDefs.push({
                field: "Status",
                headerName: "STATUS",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }
        if(this.columnsOption.ShippingLineCode){
            columnDefs.push({
                field: "ShippingLineCode",
                headerName: "CARRIER CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }

        if(this.columnsOption.ShippingLineName){
            columnDefs.push({
                field: "ShippingLineName",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            });
        }


        if(this.columnsOption.ImdgCode){
            columnDefs.push({
                field: "ImdgCode",
                headerName: "IMDG CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.LoadPort){
            columnDefs.push({
                field: "LoadPort",
                headerName: "LOAD PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DischargePort){
            columnDefs.push({
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DeliveryPort){
            columnDefs.push({
                field: "DeliveryPort",
                headerName: "DELIVERY PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }


        if(this.columnsOption.Weigth){
            columnDefs.push({
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && params.value !== 0){
                        variable = parseFloat(params.value).toFixed(2);
                    }
                    return new Intl.NumberFormat("de-DE").format(variable);
                },
                filter: "agNumberColumnFilter",
            });
        }

        if(this.columnsOption.UnNumber){
            columnDefs.push({
                field: "UnNumber",
                headerName: "UN NUMBER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ImgdPageNo){
            columnDefs.push({
                field: "ImgdPageNo",
                headerName: "IMDG PAGE NO",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.FlashPoint){
            columnDefs.push({
                field: "FlashPoint",
                headerName: "FLASH POINT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.PackagingGroupCode){
            columnDefs.push({
                field: "PackagingGroupCode",
                headerName: "PACKAGING GROUP",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.EmergencySheduleNo){
            columnDefs.push({
                field: "EmergencySheduleNo",
                headerName: "EMERGENCY SCHEDULE ID",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 220,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map((item)=>{
                computedItems.push({
                    IdX: item.IdX,
                    BayCode: item.BayCode ? item.BayCode : '',
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    ContainerCode: item.ContainerCode,
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    Weigth: item.Weigth,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ImgdPageNo: item.ImgdPageNo ? item.ImgdPageNo : '',
                    FlashPoint: item.FlashPoint ? item.FlashPoint : '',
                    PackagingGroupCode: item.PackagingGroupCode ? item.PackagingGroupCode : '',
                    EmergencySheduleNo: item.EmergencySheduleNo ? item.EmergencySheduleNo : '',
                    Seals: item.Seals ? item.Seals : '', 
                    IsoCode: item.IsoCode ? item.IsoCode : '', 
                    Status: item.Status ? item.Status : '', 
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '', 
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        
        return computedItems;
    }

    export default {
        name: "imdg-list",
        data,
        beforeMount,
        mounted,
        mixins: [Reportes, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getImdgList,
            getUnitMeasureWeigthList,
            buscarUnidad,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged
        },
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==6) {
                    this.pageSize='';
                    this.unitMeasureId='';
                    this.search=''; 
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getImdgList();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>