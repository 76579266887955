//Data:
function data() {
  return {
    baysList: [],
    loadingVesselPlan: false,
    filas: 2,
    columnas: 8,
  }
}

//Meodos:
function printVesselPlan() {
  if(!this.buque || !this.vesselType)
    return;

  this.loadingVesselPlan = true;
  this.$http.get('VesselBayPlaneContainer-by-VesselId', { VesselId: this.buque.VesselId })
  .then(response => {
    if(Array.isArray(response.data.data) && response.data.data.length > 1){
      this.baysList = response.data.data;

      let limitFilas =  this.filas > 2 ? 2 : this.filas;
      let pageInfo = {
        vesselType: this.vesselType,
        vesselId: this.buque.VesselId,
        vesselRoute: this.buque.VesselRoute,
        baysList: this.formatedBayList(this.baysList), //Funcion
        unformatedBaysList: this.baysList,
        columnas: this.columnas,
        generalCol: this.generalResponsiveCol(this.baysList), //Funcion
        numberPerPage: this.columnas * limitFilas,
        type: 'vessel-plan',
      };
    
      localStorage.setItem('pageInfo', JSON.stringify(pageInfo));
    
      let routeData = this.$router.resolve({name: 'pdfTransversal'});
      window.open(routeData.href, '_blank');

    }
  }).catch( err => {
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: err,
      type: "error",
    });
  }).then(() => {
    this.loadingVesselPlan = false;
  });

}

function generalResponsiveCol(bayList) {
  let quantity = 0;

  bayList.forEach((bay) => {
    let colToDeck = this.colNum(bay, "TO DECK"),
      colUnderDeck = this.colNum(bay, "UNDER DECK");

    let colNum =
      colToDeck >= colUnderDeck
        ? bay.FgCrossLineToDeck || bay.FgCrossLineToDeck === 1
          ? colToDeck + 1
          : colToDeck
        : bay.FgCrossLineUnderDeck || bay.FgCrossLineUnderDeck === 1
        ? colUnderDeck + 1
        : colUnderDeck;

    if (quantity < colNum) quantity = colNum;
  });

  return quantity;
}
function colNum(bay, type) {
  if (type === "UNDER DECK" && bay) {
    return (
      Number.parseInt(bay.RowStarboardUnderDeck) +
      Number.parseInt(bay.RowPortUnderDeck)
    );
  } else if (type === "TO DECK" && bay) {
    return (
      Number.parseInt(bay.RowStarboardToDeck) +
      Number.parseInt(bay.RowPortToDeck)
    );
  } else return 0;
}
function formatedBayList(bays) {
  let assigned = [];
  let formated = [];
  let newItems = bays;

  if(newItems.length != 0){
    for (let index = 0; index < newItems.length; index++) {
      const bay = newItems[index];
      let asignado = assigned.findIndex((item) => item.VesselBayId === bay.VesselBayId);
      if (asignado == -1) {
        let found = bay.VesselBayIdMirror
          ? newItems.find((item) => item.VesselBayId === bay.VesselBayIdMirror)
          : null;
        formated.push([bay, found]);
        assigned.push(bay);
        if (found) assigned.push(found);
      }
    }
  }

  return formated;
}

//Computeds


export default {
  data,
  methods: {
    printVesselPlan,
    generalResponsiveCol,
    colNum,
    formatedBayList,
  },
  computed: {

  }
}