<template>
  <div class="mb-4 mt-4">
    <loading-overlay :active="loadingVesselPlan" :is-full-page="true" loader="bars"/>
    <CRow align-horizontal="center">
      <CCol sm="7" class="pr-0"> 
        <CInput
          v-model.trim="buqueIMO"
          v-uppercase
          :horizontal="{ label: 'col-sm-1', input: 'col-sm-11'}"
          disabled
          size="sm"
          :label="$t('label.vessel')"
          addLabelClasses="text-right"
          maxlength="200"
          class="title-margin"
        >
        </CInput>
      </CCol>
      <CCol :sm="isContainerShip ? 3 : 1" class="d-flex align-items-start justify-content-start pl-0 buttons-max">
        <CButton
          v-if="isContainerShip"
          shape="square"
          color="excel"
          size="sm"
          class="ml-1"
          @click.prevent="printVesselPlan"
        >
          <div class="d-flex align-items-center">
            <CIcon name="cil-cloud-download"/>
            <span class="ml-1">{{$t('label.vesselPlan')}}</span>
          </div>
        </CButton>
        <CButton
          shape="square"
          color="excel"
          size="sm"
          class="ml-2"
          @click.prevent="printPdf"
        >
          <div class="d-flex align-items-center">
            <CIcon name="cil-cloud-download"/>
            <span class="ml-1">{{$t('label.download')}}</span>
          </div>
        </CButton>
      </CCol>
    </CRow>
    <!-- <CRow>
      <CCol sm="12" class="d-flex align-items-center justify-content-end">
        <CButton
          v-if="isContainerShip"
          shape="square"
          color="excel"
          size="sm"
          class="ml-2"
          @click.prevent="printVesselPlan"
        >
          <div class="d-flex align-items-center">
            <CIcon name="cil-cloud-download"/>
            <span class="ml-1">{{$t('label.vesselPlan')}}</span>
          </div>
        </CButton>
        <CButton
          shape="square"
          color="excel"
          size="sm"
          class="ml-2"
          @click.prevent="printPdf"
        >
          <div class="d-flex align-items-center">
            <CIcon name="cil-cloud-download"/>
            <span class="ml-1">{{$t('label.download')}}</span>
          </div>
        </CButton>
      </CCol>
    </CRow> -->
    <CRow v-if="active">
      <CCol sm="12" v-if="hasTtransversalView">
        <transversal-view :vessel-id="buque.VesselId" :VesselType="buque.TpVesselId"/>
      </CCol>
      <CCol sm="12" class="d-flex justify-content-center mb-4" v-else>
        <c-img-extended 
          :src="placeholder"
          block
          thumbnail
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import TransversalView from '@/pages/buque/bays/transversal-view';
import CImgExtended from '@/components/CImgExtended';
import { GenerateSvgPlaceholder } from '@/_helpers/funciones';
import VesselPlanMixin from '@/_mixins/vessel-plan';

//Created:
function created() { }

//Data:
function data() {
  return {
    vesselType: ''
  }
}

//Methods:
function printPdf() {
  if(!this.buque || !this.vesselType)
    return;

  let pageInfo = {
    vesselType: this.vesselType,
    vesselId: this.buque.VesselId,
    vesselRoute: this.buque.VesselRoute,
    type: 'transversal-view',
  };

  localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfTransversal'});
  window.open(routeData.href, '_blank');
}

//Computerd: 
function buqueIMO() {
  return this.buque && this.buque.VesselName && this.buque.Imo
    ?`${this.buque.VesselName} - ${this.buque.Imo}`
    :'';
}
function isContainerShip() {
  return this.vesselType == 'CONTAINER SHIP';
}
function isCargoVessel() {
  return ['BULK CARRIER', 'GENERAL CARGO', 'ROLL ON / ROLL OFF'].includes(this.vesselType);
}
function isBulkVessel() {
  return this.vesselType == 'BULK CARRIER';
}
function placeholder() {
  return GenerateSvgPlaceholder({
    width: 800,
    height: 250,
    text: `${this.$t('label.transversalView')} ${this.$t('label.notAvailable')}`
  });
}
function hasTtransversalView(){
  return [
    'BULK CARRIER',
    'CONTAINER SHIP',
    'CHEMICAL TANKERS',
    'CRUDE OIL CARRIERS',
    'GENERAL CARGO',
    'L.N.G. CARRIER',
  ].includes(this.vesselType);
}

export default {
  name: 'transversal-tab',
  mixins: [VesselPlanMixin],
  components: {
    TransversalView,
    CImgExtended
  },
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  directives: UpperCase,
  methods: {
    printPdf,
  },
  computed: {
    buqueIMO,
    isContainerShip,
    isCargoVessel,
    isBulkVessel,
    placeholder,
    hasTtransversalView,
  },
  watch: {
    active: function(newVal) {
      if(newVal){
        if( Object.keys(this.buque).length != 0 && this.buque.VesselId) {
          this.vesselType = this.buque.TpVesselName?this.buque.TpVesselName:'';
        }
      }
    }
  }
}
</script>

<style scoped>
.buttons-max {
  max-width: 25% !important;
}
.title-margin { 
  margin-top: 3px;
}
</style>