<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      @update:show="closeModal($event)"
      size="lg"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CRow class="justify-content-between">
        <CCol sm="10">
          <CSelect
            :label="$t('label.cargoGroup')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-6'}"
            :options="computedTpGpoGeneral"
            :value="TpCargoId"
            @change="gpoSelectedFilter($event)"
            :is-valid="hasError($v.TpCargoId)"
            :invalid-feedback="errorMessage($v.TpCargoId)"
            v-model.trim="$v.TpCargoId.$model"
          />
        </CCol>
        <CCol sm="2" class="justify-content-end">
          <CButton square 
            color="add" 
            @click="toggleCollapse"
            v-c-tooltip="{
              content: $t('label.packaging'),
              placement: 'top-end',
            }"
          >
            <CIcon name="cil-playlist-add" />
            <span class="ml-1">
              {{$t('label.nuevo')}}
            </span>
          </CButton>
        </CCol>

        <CCol sm="12">
          <collpaseEmbalaje 
          :collapse.sync="collapse"
          :packagingEdit="packagingEdit"
          @close-collapse="closeCollapse" 
          @refresh-packaging="getTpCargoGeneral"/>
        </CCol>
      </CRow>

      <dataTableExtended
     class="align-center-row-datatable"
        size="lg"
        :fields="fields"
        :items="computedTpGeneralPackaging"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingTable"
         
      >
        <template #checked="{ item }">
          <td class="center-cell">
            <CInputCheckbox
              custom
              class="checkbook-filter"
              :checked="item.checked"
              @update:checked="addRemoveId(item)"
            />
          </td>
        </template>
        <template #checked-filter>
          <td class="center-cell" style="border-top:none">
            <CInputCheckbox
              :label="$t('label.all')"
              class="checkbook-filter"
              custom
              @update:checked="SelectAllPackaging($event)"
              :checked="AllPackaging"
            />
          </td>
        </template>
        <template #options="{ item }">
          <td class="center-cell">
            <CButton
              square
              color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="showCollpaseEditEmbalaje(item)"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
        
      </dataTableExtended>
      <p v-if="!isEdit && arrIds.length === 0" class="mt-2 text-danger">
        {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')+' 1 '+$t('label.type')+' '+$t('label.of')+' '+$t('label.packaging')}}
      </p>
      <template #footer>
        <CButton
          outline
          color="add"
          @click.stop="isEdit?statusConfirmation(true, Status, submit) :submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';
import CargaGranel from '@/_mixins/carga-granel';
import embalajeValidations from '@/_validations/carga-general/embalajeValidations';
import UpperCase from '@/_validations/uppercase-directive';
import collpaseEmbalaje from '../../embalaje/embalaje-collapse.vue';

function data() {
  return {
    modalActive: false,
    Loading: false,
    loadingTable: false,
    itemGpoPackaging: [],
    itemPackagingChecked: [],
    itemPackagingCheckedUpdated: [],
    itemsBuilkCargosPackagingUpdated: [],
    TpCargoId: '',
    selectAll: false,
    resetSelectAll: false,
    Status: null,
    collapse: false,
    packagingSelected: '',
    packagingEdit:{},
    isEdit: false,
    itemsPackagingList: [],
    arrIds: [],
    arrIdsOrigin: [],
    AllPackaging: false,
  };
}
//methods
function SelectAllPackaging(event) {
  this.arrIds = [];
  if (event) {
    this.AllPackaging = true;
    //SELECCIONA TODOS LOS ITEM
    this.itemsPackagingList.map((item) => {
      this.arrIds.push({PackagingId: item.PackagingId, TpCargoPackagingId: item.TpCargoPackagingId ? item.TpCargoPackagingId : '' , Status: 1});  
    });

  }else{
    this.AllPackaging = false;
  }
}

function addRemoveId(item) {
  let bool = this.arrIds.filter((e) => e.PackagingId == item.PackagingId)

  if( bool.length == 0 ){
    //ADD
    this.arrIds.push({PackagingId: item.PackagingId, TpCargoPackagingId: '', Status: 1});
  }
  else{
    //DELETE
    this.arrIds = this.arrIds.filter((e) => e.PackagingId !== item.PackagingId);
  }

  if (this.arrIds.length == this.itemsPackagingList.length) {
    this.AllPackaging = true;
  }else{
    this.AllPackaging = false;
  }
}

function toggleCollapse(){
  this.collapse = true;
  this.isEdit = false;
}

function showCollpaseEditEmbalaje(item){
  if(item.PackagingId !== this.packagingSelected){
    this.collapse = true
    this.packagingEdit = item;
    this.packagingSelected = item.PackagingId
  }else {
    this.collapse = false;
    this.packagingSelected = '';
    this.packagingEdit = {};
  }
}

function closeCollapse(){
  this.collapse = false;
}

function gpoSelectedFilter(event) {
  this.TpCargoId = event.target.value;
  this.arrIds = [];
  this.arrIdsOrigin = [];
  this.getTpGeneralPackaging();
}

async function getTpCargoGeneral() {
  this.Loading = true;
  await this.$http
    .get('TpCargoGeneralApplyPackaging-list')
      .then(async response => {
      let list = response.data.data;
      if (list.length != 0) {
        this.itemGpoPackaging = list;
        this.TpCargoId = this.isEdit ? this.tpcargoSelcted : this.TpCargoId = list[0].TpCargoId;
      }
      await this.getTpGeneralPackaging()
      this.Loading = false;
    })
    .catch((e) => {
    this.Loading = false;
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
    });
  }) 
  }

  async function getTpGeneralPackaging() {
    if (this.TpCargoId != '') {
      this.Loading = true;
      await this.$http
      .get('TpGeneralCargoPackaging-list',{TpCargoId: this.TpCargoId})
      .then((response) => {
        let list = response.data.data;
        if (list && list.length != 0){
          this.itemsPackagingList = list;
          this.itemsPackagingList.map(item =>{
            if (item.TpCargoPackagingId) {
              this.arrIdsOrigin.push({PackagingId: item.PackagingId, TpCargoPackagingId: item.TpCargoPackagingId});
            }
          })
        }
        list.map((item) => {
          if ( item.TpCargoPackagingId !== null ) {
            this.arrIds.push({PackagingId: item.PackagingId, TpCargoPackagingId: item.TpCargoPackagingId});
          }
        });
        if (this.arrIds.length == this.itemsPackagingList.length) {
          this.AllPackaging = true;
        }else{
          this.AllPackaging = false;
        }
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      }); 
    }else{
      this.itemsPackagingList = [];
      this.arrIdsOrigin = [];
    }
  }

//### Function para cerrar el modal ###
function closeModal(event) {
  this.modalActive = false;
  this.collapse = false;
  this.resetForm();
  this.$emit('updated-modal', event);
}
function resetForm(){
    this.isEdit = false;
    this.TpCargoId = '';
    this.arrIds = [],
    this.arrIdsOrigin = [],
    this.AllPackaging = false,
    this.$v.$reset();
}

async function submit() {
    try {
      this.Loading = true;
      let Json = [];
      let Total_Id = [];

      if (this.arrIds.length === 0 && !this.isEdit) {
        this.Loading = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      Total_Id = [...this.arrIdsOrigin, ...this.arrIds];
      

      Total_Id.map(item =>{
        if (!Json.some(e => e.PackagingId == item.PackagingId )) {
          Json.push(item);
        }
      })

      let TpCargoPackagingJson = Json.map((item) => {
        let ValidateCurrentCargo = this.arrIds.some(e =>e.PackagingId == item.PackagingId)
          return {
            TpCargoPackagingId: item.TpCargoPackagingId ? item.TpCargoPackagingId : '',
            TpCargoId: this.TpCargoId,
            PackagingId: item.PackagingId,
            Status: ValidateCurrentCargo ? 1 : 0,
          };
      })

      await this.$http.post("TpGeneralCargoPackaging-insert", TpCargoPackagingJson, { root: 'TpCargoPackagingJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.$emit('refresh-data-table');
            this.Loading = false;
            this.closeModal(false);
            this.notifySuccess({ text: messageSuccess });
          }
        })
        .catch((e) => {
          this.Loading = false;
          this.notifyError({ text: e });
        });
    } catch (e) {
      this.Loading = false;
      this.notifyError({ text: e });
    }
  }

//computed
function computedTpGpoGeneral(){
    if(this.itemGpoPackaging.length === 0){
        return [{
        value: '', 
        label: this.$t('label.select'),
        }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.itemGpoPackaging.map((e)=>{
        chart.push({
            label: e.TpCargoName,
            value: e.TpCargoId,
        })    
      })
      return chart;
    }
  }

//### Funcion computed para formatear los datos de la tabla ###
function computedTpGeneralPackaging() {
    if (this.itemsPackagingList.length > 0) {
      return this.itemsPackagingList.map((item) => {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let checkeds = this.arrIds.filter((e) => e.PackagingId == item.PackagingId);
        return {
          ...item,
          TpCargoName: item['TpCargoName'+_lang] ?? 'N/A',
          PackagingNameEs: item.PackagingNameEs ?? 'N/A',
          PackagingNameEn: item.PackagingNameEn ?? 'N/A',
          FormatedDate: item.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
            : 'N/A',
          _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
          checked: (checkeds.length > 0),
        };
      });
    }
  }

function fields(){
  return [
      { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center', },
      { key: 'TpCargoName', label: this.$t('label.group'),  _style:'width:20%;', _classes:"center-cell text-center" },
      { key: 'PackagingNameEs', label: `${this.$t('label.packaging')} (ES)`, _style:'width:20%;',_classes:"center-cell text-center"},
      { key: 'PackagingNameEn', label: `${this.$t('label.packaging')} (EN)`, _style:'width:20%;',_classes:"center-cell text-center"},
      { key: 'checked', label: '', _classes: 'center-cell', _style: 'width: 15%;text-align:center;',sorter: false, filter: false},
      { key: 'options', label: '', sorter: false, filter: false, _style: 'min-width: 50px; width:5%',  },
    ];
}

function title(){
  return this.$t('label.associateGeneralGroupCargoPackaging')
}

export default {
  name: 'modalEmbalaje',
  data,
  mixins: [
    GeneralMixin, 
    CargaGranel,
    ModalMixin
  ],
  props: {
    modal: null,
    tpcargoSelcted: String,
  },
  validations: embalajeValidations,
  directives: UpperCase,
  components: {
    collpaseEmbalaje
  },
  methods: {
    closeModal,
    submit,
    resetForm,
    closeCollapse,
    gpoSelectedFilter,
    showCollpaseEditEmbalaje,
    toggleCollapse,
    SelectAllPackaging,
    addRemoveId,
    getTpCargoGeneral,
    getTpGeneralPackaging,
  },
  computed: {
    computedTpGpoGeneral,
    computedTpGeneralPackaging,
    fields,
    title,
  },
  watch: {
    modal: async function(val) {
      this.modalActive = val;
      if(val){
        this.$v.$touch();
        await this.getTpCargoGeneral();
      }
    },
    tpcargoSelcted: function(val){
      if (val) {
        this.isEdit = true;
        this.TpCargoId = val;
      }else{
        this.isEdit = false;
      }
    }
  },
  
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
