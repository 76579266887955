import {
  required,
  minLength,
} from 'vuelidate/lib/validators';

const verifyNumber = (value) => {
  return value === 0 ? false : true;
}

export default () => {
  return {
    movementInfo: {
      OrderDetailId: {},
      MovementOrderId: { required },
      TruckWeghingScaleId: { required },
      TransactionDate: { required },
      Weight: { required, verifyNumber },
      Status: {},
    },
  }
}