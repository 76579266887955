<template>
  <CCard>
    <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
    <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars" />
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.groupModuleList')}}</b>
    </CCardHeader>
    <CCardBody>
      <GrupoHijo :nombrePadreCollapse="nombrePadreCollapse" ></GrupoHijo>
      <CCollapse :show="!$store.state.grupomodulo.collapseModal"> 
        <GrupoModulo 
          :idPadre="idPadre" 
          :activarPadre="activarPadre" 
          @Update-list="Update" 
          :TpModuleFilterSelect="TpModuleFilter"
          :NavigationFilterSelect="NavigationFilter"
          :TpModuleObj="TpModuleObj"
          :navigationObj="navigationObj"
          :filterVessel="filterVessel"
          :VesselSelectData="VesselSelect"
          :VesselObj="VesselObj"
          :ModuleSelectData="GpoModuleL1Filter"
          :ModuleObj="ModuleObj"
        >
        </GrupoModulo>
        <GrupoModuloUpdate 
          :TpModuleFilterSelect="TpModuleFilter"
          :NavigationFilterSelect="NavigationFilter"
          :TpModuleObj="TpModuleObj"
          :navigationObj="navigationObj"
          :filterVessel="filterVessel"
          :VesselSelectData="VesselSelect"
          :VesselObj="VesselObj"
          :ModuleSelectData="GpoModuleL1Filter"
          :ModuleObj="ModuleObj"
          @Update-list="Update"
        >
        </GrupoModuloUpdate>
        <CRow>
          <CCol sm="12" lg="12"  class="d-flex align-items-center justify-content-end mb-3">
            <CButton
              @click="setDateModal"
              color="add"
              v-c-tooltip="{placement:'top-end',content:$t('label.moduleGroup')}"
            >
              <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="3">
            <CSelect
                :label="$t('label.subsystemType')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input: 'col-sm-12 col-lg-5'}"
                addLabelClasses="text-right"                
                :options="subsystemTypeListFormatted"
                :value="TpModuleFilter"
                @change="SubSystemList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3"  v-if="TpModuleFilter.toUpperCase() === this.sigetWebId">
            <CSelect                
                :label="$t('label.navigation')"
                :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-5'}"
                addLabelClasses="text-right"
                :options="navegationListFormatted"
                :value="NavigationFilter"
                @change="NavigationList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3"  v-if="NavigationFilter.toUpperCase() === this.visitaId">
            <CSelect                
                :label="$t('label.Ship')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-5'}"
                addLabelClasses="text-right"
                :options="vesselListFormatted"
                :value="VesselSelect"
                @change="VesselList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3" v-if="TpModuleFilter.toUpperCase() === this.sigetWebId">
              <CSelect                
                  :label="$t('label.module')"
                  :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-5'}"
                  addLabelClasses="text-right"
                  :options="opcionesPadre"
                  :value="GpoModuleL1Filter"
                  @change="ModuleList($event)"
              />
          </CCol>
        
          <CCol sm="12">
            <dataTableExtended
              hover
              sorter
              small
              column-filter
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items="dateFilteredItems"
              :fields="fields"
              :noItemsView="tableText.noItemsViewText"
              :items-per-page="5"
              pagination
              :active-page="activePage"
            >
              <template #id="{ index}">
                <td class="text-left">
                  {{index+1}}
                </td>
              </template>
              <template #nivel="{item}">
                <td class="center-cell text-center">
                  <CBadge :color="getBadge(item.nivel)">
                    {{item.nivel}}
                  </CBadge>
                </td>
              </template>
              <template #orden="{item,index}">
                <td class="center-cell">
                  {{item.orden}}&nbsp;&nbsp;
                  <CButtonGroup>
                    <CButton
                      class="btn btn-light"
                      square
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.Down'),
                        placement: 'top-end',
                      }"
                      @click="updateItem(item, index, 0)"
                    >
                      <CIcon name="cil-arrow-circle-bottom"/>
                    </CButton>
                    <CButton
                      color="dark"
                      square
                      size="sm"
                      @click="updateItem(item, index, 1)"
                      v-c-tooltip="{
                        content: $t('label.Up'),
                        placement: 'top-end',
                      }"
                    >
                      <CIcon name="cil-arrow-circle-top"/>
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
              <template #textStatus="{item}">
                <td class="text-center center-cell">
                  <CBadge v-if="item.status" color="success">
                    {{$t('label.ACTIVO')}}
                  </CBadge>
                  <CBadge v-if="!item.status" color="danger">
                    {{$t('label.INACTIVO')}}
                  </CBadge>
                </td>
              </template>
              <template #acciones="{item, index}">
                <td class="text-center">
                  <CButton
                    square
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit') + $t('label.moduleGroup'),
                      placement: 'top-end'
                    }"
                    @click="updateModal(item, index,1)"
                  >
                    <CIcon name="pencil"/>
                  </CButton>
                  <CButton
                    class="btn btn-light mr-1"
                    square
                    color="watch"
                    size="sm"
                    v-if="filteredNivel(item)"
                    v-c-tooltip="{content: $t('label.toView')+' '+ $t('label.dependencies'),
                      placement: 'top-end'}"
                    @click="updateCollapse(item, index)"
                  >
                    <CIcon name="eye"/>
                  </CButton>
                  <!-- <CButton
                    color="dark"
                    square
                    size="sm"
                    v-if="filteredNivel(item)"
                    @click="updateModal(item, index,2)"
                    v-c-tooltip="{content: $t('label.assign') + $t('label.level'),
                    placement: 'top-end'}"
                  >
                    <CIcon name="cil-chevron-circle-right-alt"/>
                  </CButton> -->
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </CCollapse>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapState,mapMutations } from 'vuex';
import GrupoModulo from './app-grupo-modulo';
import GrupoModuloUpdate from './app-grupo-updated-modulo';
import GrupoHijo from './app-grupo-modulo-hijos';
import ENUM from '@/_store/enum';
import general from '@/_mixins/general';
import { map } from 'leaflet';



//Fields
function fields(){
  return[
        { key: 'id', label: '#',filter :false,_style: 'width:3%',_classes:'text-left'},
        { key: 'moduloEs', label: this.$t('label.groupModuleList')+' '+('(ES)'), _style: 'width:35%',_classes:'text-center center-cell' },
        { key: 'moduloEn', label: this.$t('label.groupModuleList')+' '+('(EN)'), _style: 'width:35%',_classes:'text-center center-cell' },
        { key: 'nivel', label: this.$t('label.level'),_style: 'width:15%',_classes: "text-center center-cell"},
        { key: 'orden', label: this.$t('label.order'),filter:false,_style: 'width:20%',_classes: "text-center center-cell"},
        { key: 'ultimo_usuario', label: this.$t('label.user'),_style: 'width:15%',_classes: "text-center center-cell"},
        { key: 'fecharegistrotransa', label:this.$t('label.date'),_style:'width:20%',_classes:'text-center center-cell'},
        { key: 'textStatus', label: this.$t('label.status'),_style:'width:20%',_classes:'text-center center-cell'},
        {
          key: 'acciones', 
          label: '', 
          _style: 'min-width:90px',
          sorter: false, 
          filter: false,
        }, 
  ]
}

//Data
function data() {
  return {        
    startDate: 1325376000000,
    endDate: 1330560000000,
    action:false,
    toast:false,
    activar:false,
    activePage: 1,    
    filtroSelected: "0",
    filtroSelectedPadre: "0",
    TpModuleFilter:'',
    NavigationFilter:'',
    VesselSelect:'',
    GpoModuleL1Filter :'',
    filterVessel: [],
    sudSystemItems:[],
    navigationItems:[],
    vesselItems:[],
    GpoModuleL1Item :[],
    GpoModuleL2Item :[],
    items: [],
    sigetWebId:'886B95BE-452A-45E0-8C11-2C162BC31AFE', 
    visitaId:'5FFE2FBD-CD0C-4B78-96B7-0C1F0F8CB97C',
    nombrePadreCollapse:'',
    idPadre:0,
    activarPadre:false,
    TpModuleName:'',
    TpNavigationName:'',
    ModuleName:'',
    TpModuleObj: {},
    navigationObj:[],
    VesselObj: {},
    ModuleObj: {},
    isSubmit:true,
    Loading:false,
  }
}

//mounted
function mounted() {
    this.$events.$on('desactivar-modal-registered', eventData => this.onDesactivarModalRegistered(false));
}

//beforedestroy
function beforeDestroy(){
  this.$events.$off('desactivar-modal-registered')
  this.$events.off('desactivar-modal-registered')
  this.$events.remove('desactivar-modal-registered')
}

//Methods
function setDateModal(){
  let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
  
  this.$store.state.grupomodulo.registerModal = !this.$store.state.grupomodulo.registerModal;
  this.TpModuleObj = this.sudSystemItems.find(item => item.TpModuleId === this.TpModuleFilter);
  this.navigationObj = NavigationWeb.TpModuleNavegationJson;
  this.VesselObj = this.vesselItems.find(item => item.TpVesselId === this.VesselSelect);
  this.ModuleObj = this.GpoModuleL1Item.find(item => item.GpoModuleId === this.GpoModuleL1Filter);
  
}
function filteredNivel(item) {
  if(parseInt(item.nivel) === 1)
    return true
  else
    return false
}
function typeItem(item){
  return item.status;
}
function getBadge (nivel) {
  nivel = parseInt(nivel);
  switch (nivel) {
    case 1: return 'success'
    case 2: return 'danger'
    case 3: return 'info'
    default: 'success'
  }
}

function Update() {
  this.getSudSystemList();
  this.TpModuleFilter='';
  this.NavigationFilter='';
  this.VesselSelect='';
  this.GpoModuleL1Filter ='';
}
function updateModal(item,index,tipo){
  if(tipo === 1){
    let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
    this.$store.state.grupomodulo.updatedModal = true;
    this.$store.state.grupomodulo.id = item.id;
    this.TpModuleObj = this.sudSystemItems.find(item => item.TpModuleId === this.TpModuleFilter);
    this.VesselObj = this.vesselItems.find(item => item.TpVesselId === this.VesselSelect);
    this.navigationObj = NavigationWeb.TpModuleNavegationJson;
  }else{
    let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
    this.activarPadre = true;
    this.idPadre = item.id;
    this.TpModuleObj = this.sudSystemItems.find(item => item.TpModuleId === this.TpModuleFilter);
    this.navigationObj = NavigationWeb.TpModuleNavegationJson;
  }
}
function onDesactivarModal(){
  this.idValor = 0;
  this.activar = false;
}
function onDesactivarCollapse(){
  this.$store.state.grupomodulo.collapseModal = false;
}
function onDesactivarModalRegistered(){
  this.activarPadre = false;
}
function updateCollapse(item,index){
  const id = item.id;
  let _lang = this.$i18n.locale;
  let title =  _lang == 'en' ? item.moduloEn : item.moduloEs;
  this.nombrePadreCollapse = title;
  this.$store.dispatch('grupomodulo/getGrupoHijoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter, GpoModuleOrigId: id,});
  this.$store.state.grupomodulo.collapseModal = true;
}
function pageChange(val) {
  this.$router.push({ query: { page: val }});
}
function updateItem(item,index,tipo){
  const data = Object.assign({},item);
  if(data.status === false || data.status === '' || data.status === null || data.status === undefined){
    return
  }
  if(tipo === 0){
    if(data.nivel === 1){
      const itemsActivos = this.myData.filter(item => item.FgActGpoModule === true);
      const lengt = itemsActivos.length - 1;
      if(parseInt(itemsActivos[lengt].Order) === parseInt(data.orden)){
        return
      }else{
        const OrdenOriginal = data.orden;
        const OrdenModificada = OrdenOriginal + 1;

        const Index = this.myData.map(function(e) {
          return e.GpoModuleId; 
        }).indexOf(data.id);

        const GpoModuleId = data.id;
        const GpoModuleParentId = '';
        const Level = data.nivel;

        if(Index !== -1){
          this.$store.dispatch('grupomodulo/postGrupoModuloOrden', { Index,GpoModuleId,GpoModuleParentId,Level,OrdenModificada,OrdenOriginal });
        }
      }
    }else if(data.nivel === 2){
      const Index = this.myData.map(function(e) {
        return e.GpoModuleId; 
      }).indexOf(data.padre);
      if(Index !== -1){
        const itemsActivos = this.myData[Index].GpoModuleChildJson.filter(item => item.FgActGpoModule === true);
        const lengt = itemsActivos.length;
        
        if(lengt !== 0){
          if(parseInt(itemsActivos[lengt-1].Order) === parseInt(data.orden)){
            return
          }else{
            const OrdenOriginal = data.orden;
            const OrdenModificada = OrdenOriginal + 1;
            const GpoModuleId = data.id;
            const GpoModuleParentId = data.padre;
            const Level = data.nivel;

            this.$store.dispatch('grupomodulo/postGrupoModuloOrden', { Index,GpoModuleId,GpoModuleParentId,Level,OrdenModificada,OrdenOriginal });
          }
        }
      }
    }
  }else if(tipo === 1){
    if(data.orden === 1){
      return
    }
    if(data.nivel === 1){
      const OrdenOriginal = data.orden;
      const OrdenModificada = OrdenOriginal - 1;

      const Index = this.myData.map(function(e) {
        return e.GpoModuleId; 
      }).indexOf(data.id);

      const GpoModuleId = data.id;
      const GpoModuleParentId = '';
      const Level = data.nivel;

      if(Index !== -1){
        this.$store.dispatch('grupomodulo/postGrupoModuloOrden', { Index,GpoModuleId,GpoModuleParentId,Level,OrdenModificada,OrdenOriginal });
      }
    }else if(data.nivel === 2){
      const OrdenOriginal = data.orden;
      const OrdenModificada = OrdenOriginal - 1;

      const Index = this.myData.map(function(e) {
        return e.GpoModuleId; 
      }).indexOf(data.padre);

      const GpoModuleId = data.id;
      const GpoModuleParentId = data.padre;
      const Level = data.nivel;

      if(Index !== -1){
        this.$store.dispatch('grupomodulo/postGrupoModuloOrden', { Index,GpoModuleId,GpoModuleParentId,Level,OrdenModificada,OrdenOriginal });
      }
    }
  }
}


function getSudSystemList() {
  this.Loading = true;
    this.$http.get("TpModuleConfigWeb-list")
    .then(response => {       
      this.sudSystemItems = response.data.data;
      this.TpModuleFilter = this.sudSystemItems[0].TpModuleId;
      this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false;
    });
} 

function SubSystemList(event) {
  this.NavigationFilter='';
  this.navigationItems=[];
  this.GpoModuleL1Filter='';
  this.GpoModuleL1Item=[];
  this.VesselSelect='';
  this.filterVessel=[];
  this.TpModuleFilter = event.target.value; 
  if (this.TpModuleFilter!='') {
    if (this.TpModuleFilter.toUpperCase() === this.sigetWebId) {
      let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
      this.navigationItems = NavigationWeb.TpModuleNavegationJson;
      this.NavigationFilter = this.navigationItems[0].TpModuleNavegationId;
    }
    if (this.NavigationFilter!='') {
      this.getVesselList();
    }
  } 
  if (this.TpModuleFilter && this.TpModuleFilter != this.sigetWebId) {
    this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
  }else{
    this.$store.state.grupomodulo.myData = [];
  }
}

function NavigationList(event) {
  this.GpoModuleL1Filter='';
  this.VesselSelect='';
  this.NavigationFilter = event.target.value;
  if (this.NavigationFilter==this.visitaId && this.NavigationFilter!='') {
    this.getVesselList();
    //this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
  } else{
    this.GpoModuleL1Filter='';
    this.VesselSelect='';
    this.GpoModuleL1Item=[];
    this.filterVessel=[];
    this.$store.state.grupomodulo.myData = [];
  }
  if (this.NavigationFilter && this.NavigationFilter!=this.visitaId){
    this.GpoModuleL1List();
    this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
  }else{
    this.GpoModuleL1Filter='';
    this.VesselSelect='';
    this.GpoModuleL1Item=[];
    this.filterVessel=[];
    this.$store.state.grupomodulo.myData = [];
  }
}
function getVesselList() {
  this.Loading = true;
  this.$http.get("TpVessel-list")
    .then(response => {       
      this.vesselItems = response.data.data;
      //this.VesselSelect = this.vesselItems[0].TpVesselId;
      this.GpoModuleL2List();
      this. VesselList();
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false
    });
} 
function VesselList(event) {
  event ? this.VesselSelect = event.target.value: '';
  this.$store.state.grupomodulo.myData = [];
  if (this.TpModuleNavegationId!='') {
    if (this.VesselSelect!='') {
      this.filterVessel = this.GpoModuleL2Item.filter((item) => {return item.TpVesselId === (this.VesselSelect).trim()} );
      this.GpoModuleL1Item = this.filterVessel;
      //this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter}) 
    }else{
      this.$store.state.grupomodulo.myData = [];
      this.GpoModuleL1Item = [];
      this.GpoModuleL1Filter = '';
    }
  }
}
function GpoModuleL1List() {
  this.Loading = true;
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
    .then(response => {   
      this.GpoModuleL1Item = response.data.data;
      //this.NavigationFilter != this.visitaId ? (this.GpoModuleL1Filter = this.GpoModuleL1Item[0].GpoModuleId) : ( this.GpoModuleL1Filter = '' );
      if (this.NavigationFilter && this.NavigationFilter==this.visitaId) {
         this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
      }
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      
    });
}
function GpoModuleL2List() {
  this.Loading = true;
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
    .then(response => {   
      this.GpoModuleL2Item = response.data.data;
      // this.GpoModuleL1Filter = this.GpoModuleL2Item[0].GpoModuleId;
      //this.NavigationFilter != this.visitaId ? (this.GpoModuleL1Filter = this.GpoModuleL2Item[0].GpoModuleId) : ( this.GpoModuleL1Filter = '' );
      this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}
function ModuleList(event) {
  this.Loading = true;
  this.GpoModuleL1Filter = event.target.value;
  if (this.GpoModuleL1Filter!='') {
    this.$store.dispatch('grupomodulo/getGrupoModulolist',{TpModuleId: this.TpModuleFilter, TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
    this.Loading = false;
  }else{
    this.$store.state.grupomodulo.myData = [];
  }
}
//computed
function activatefiltro(){
  if((this.TpModuleFilter.toUpperCase() == this.sigetWebId.toUpperCase())){
    if ((this.NavigationFilter.toUpperCase() === this.visitaId.toUpperCase())) {
      return '2';
    }else {
      return '4';
    }
  }else{
    return '9';
  }
}

function apiStateLoading() {
  let carga = false;
  if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
    carga = !carga;
  }
  return carga;
}
function apiStateFormLoading() {
  let carga = false;
  if(this.apiStateForm === ENUM.LOADING){
    carga = !carga;
  }
  return carga;
}
function opcionesPadre(){
  let _lang = this.$i18n.locale;
  if(this.GpoModuleL1Item.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.GpoModuleL1Item.map(function(e){
      chart.push({
        value: e.GpoModuleId, 
        label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
      })    
    })
    return chart;
  }
}
function computedItems () {
  return this.$store.getters["grupomodulo/myDatatable"];
}
function dateFilteredItems() {
  const filters = this.filtroSelected;
  const filtersPadre = this.filtroSelectedPadre;
  return this.computedItems.filter(item => {
    if(filters === "0"){
      return item
    }else if(filters === "1"){
      return parseInt(item.nivel) === 1
    }else if(filters === "2"){
      if(filtersPadre === 0 || filtersPadre === "0"){
        return parseInt(item.nivel) === 2
      }else{
        return parseInt(item.nivel) === 2 && item.padre === filtersPadre
      }
    }
      
  })
}

function optionsList(){
  return[
          {value:"0",label:this.$t('label.all')},
          {value:"1",label:this.$t('label.level') + ' 1' },
          {value:"2",label:this.$t('label.level') + ' 2'}
          ]
}

function subsystemTypeListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.sudSystemItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.sudSystemItems.map(function(e){
      chart.push({
        value: e.TpModuleId, 
        label: _lang=='en' ? e.TpModuleNameEn : e.TpModuleNameEs,
      })    
    })
    return chart;
  }
} 

function navegationListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.navigationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.navigationItems.map(function(e){
      chart.push({
        value: e.TpModuleNavegationId, 
        label: _lang=='en' ? e.TpModuleNavegationEn : e.TpModuleNavegationEs,
      })    
    })
    return chart;
  }
}   
function vesselListFormatted(){
  if(this.vesselItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vesselItems.map(function(e){
      chart.push({
        value: e.TpVesselId, 
        label: e.TpVesselName,
      })    
    })
    return chart;
  }
}  

//watch
function apiState(newQuestion,OldQuestion){
  if(newQuestion === ENUM.ERROR){
    this.$notify({
      group: 'container',
      type: 'error',
      ignoreDuplicates:false,
      title: 'ERROR',
      text: (this.messageError === '' ? `${this.$t('label.unexpectedError')}` : this.messageError)
    });
    this.$store.state.grupomodulo.messageError = '';
  }
}

function apiStateForm(newQuestion,OldQuestion){
  switch (newQuestion) {
    case ENUM.ERROR:
      this.$notify({
        group: 'container',
        type: 'error',
        ignoreDuplicates:false,
        title: 'ERROR',
        text: (this.messageError === '' ? `${this.$t('label.unexpectedError')}` : this.messageError)
      });
      this.$store.state.grupomodulo.messageError = '';
      break;
    case ENUM.LOADED:
      this.$notify({
        group: 'container',
        type: 'success',
        ignoreDuplicates:false, 
        title: '¡Éxito!',
        text: (this.messageError === '' ? `${this.$t('label.completedSuccessfully')}` : this.messageError)
      });
      this.$store.state.grupomodulo.messageError = '';
      break;
    default:
      break;
  }
}
function handlerRoute() {
  return {
    immediate: true,
    handler (route) {
      if (route.query && route.query.page) {
        this.activePage = Number(route.query.page)
      }
    }
  };
}
export default {
  name: 'app-grupos-modulos',
  data,
  mixins:[general],
  mounted,
  beforeDestroy,
  computed: {
    opcionesPadre,
    activatefiltro,
    fields,
    optionsList,
    apiStateFormLoading,
    apiStateLoading,
    computedItems,
    dateFilteredItems,
    subsystemTypeListFormatted,
    navegationListFormatted,
    vesselListFormatted,
    ...mapState({
      apiState: state => state.grupomodulo.apiState,
      apiStateForm: state => state.grupomodulo.apiStateForm,
      messageError: state => state.grupomodulo.messageError,
      myData: state => state.grupomodulo.myData,
    })
  },
  components: {
    GrupoModulo,
    GrupoModuloUpdate,
    GrupoHijo
  },
  methods: {
    filteredNivel,
    setDateModal,
    typeItem,
    pageChange,
    getBadge,
    updateCollapse,
    updateModal,
    updateItem,
    onDesactivarModal,
    onDesactivarModalRegistered,
    onDesactivarCollapse,
    getSudSystemList,
    SubSystemList,
    NavigationList,
    getVesselList,
    VesselList,
    GpoModuleL1List,
    ModuleList,
    Update,
    GpoModuleL2List
  },
  watch:{
    $route: handlerRoute,
    apiState,
    apiStateForm
  },
  mounted:getSudSystemList,

}
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
</style>