<template>
    <div style="margin-top:10px;">
        <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.menu_diary.TERMINAL_DETAILS')">
                <CCard bodyWrapper>
                    <CCollapse :show="!collapseTerminal">
                        <CRow>
                            <CCol col="12" xl="12" class="d-flex align-items-center justify-content-end">
                                <CButton
                                    color="add"
                                    @click="nuevoToquen"
                                >
                                    <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                </CButton>
                            </CCol>
                            <CCol col="12" xl="12">
                                <dataTableExtended
                                    class="align-center-row-datatable"
                                    hover
                                    sorter
                                    small
                                    column-filter
                                    :loading="apiStateLoading"
                                    :table-filter="tableTextHelpers.tableFilterText"
                                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                    :items="formatedItems"
                                    :fields="fields"
                                    :noItemsView="tableTextHelpers.noItemsViewText"
                                    :items-per-page="10"
                                    pagination
                                >
                                    <template #acciones="{item, index}">
                                        <td>
                                            <CButtonGroup>
                                                <CButton
                                                    color="primary"
                                                    square
                                                    size="sm"
                                                    class="mr-1"
                                                    v-c-tooltip="{
                                                        content: $t('label.edit'),
                                                        placement: 'top-end'
                                                    }"
                                                    @click="updateItem(item, index)"
                                                >
                                                    <CIcon name="pencil"/>
                                                </CButton>
                                                <CButton
                                                    color="danger"
                                                    square
                                                    size="sm"
                                                    class="mr-1"
                                                    v-c-tooltip="{
                                                        content: $t('label.Romeve'),
                                                        placement: 'top-end'
                                                    }"
                                                >
                                                    <CIcon name="cil-trash"/>
                                                </CButton>
                                            </CButtonGroup>
                                        </td>
                                    </template>
                                </dataTableExtended>
                            </CCol>
                        </CRow>
                    </CCollapse>
                    <CCollapse :show="collapseTerminal">
                        <appRegisterTerminal @submited="handleSubmit" @asignarTerminal="asignarTerminal" 
                            :visitaTerminalId="visitaTerminalId" :itemSelected="itemSelected" 
                            :apiStateLoading="apiStateLoading"
                            :token="NextCalled" />
                    </CCollapse>
                </CCard>
            </CTab>
            <CTab :title="$t('label.recordInfo')" v-if="showTransactionHistory">
                <CCard bodyWrapper>
                    <CRow>
                        <CCol col="12" xl="12" class="d-flex align-items-center justify-content-end">
                            <CButton
                                color="excel" class="mr-1"
                                @click="onBtnExport(true)"
                                >
                                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                            </CButton>
                        </CCol>
                        <CCol col="12" xl="12">
                            <dataTableExtended
                                class="align-center-row-datatable"
                                hover
                                sorter
                                small
                                column-filter
                                :loading="apiStateLoading"
                                :table-filter="tableTextHelpers.tableFilterText"
                                :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                :items="formatedItemsSchedule"
                                :fields="fieldsRecord"
                                :noItemsView="tableTextHelpers.noItemsViewText"
                                :items-per-page="10"
                                pagination
                            >
                                <template #Label="{item}">
                                    <td class="text-left">
                                        {{titleItemsActivity(item)}}
                                    </td>
                                </template>
                            </dataTableExtended>
                        </CCol>
                    </CRow>
                </CCard>
            </CTab>
        </CTabs>

    </div>
            
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import appRegisterTerminal from './register-terminal';
    import { DateFormater, tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';
    import DataStorage from '@/_helpers/localStorage';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    import ReportesVisitas from '@/_mixins/reportesVisitas';

    //data
    function data() {
        return {
            NextCalled: 1,
            Called: 0,
            itemSelected:{},
            visitaTerminalId:0,
            listTerminal:[],
            ListVisitActivictyV:[],
            dataEs: [],
            dataEn: [],
            showTransactionHistory: false,
        }
    }
    //methods
    function nuevoToquen(){
        this.itemSelected = {};
        this.visitaTerminalId = 0;
        this.$store.state.visitas.collapseTerminal = true;
    }
    function updateItem(item,index){
        let arregloEs = [];
        let arregloEn = [];
        let listado = [];
        this.itemSelected = item;

        this.visitaTerminalId = item.VisitTerminalId;
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'VisitActivictyV-list-by-VisitTerminalId', { VisitTerminalId: this.visitaTerminalId }, '').then(responseVisitActivictyV => {
            if(responseVisitActivictyV.status === 200){
                this.ListVisitActivictyV = responseVisitActivictyV.data.data;
                listado = responseVisitActivictyV.data.data;
                
                let i=0;
                listado.forEach(function (item) {      
                    arregloEn.push({
                    '#': i+1,
                    ACTIVITY: item.VisitActivityNameEn ? item.VisitActivityNameEn : '',
                    TRANSACTION_DATE: item.ActivityDate ? DateFormater.formatDateTimeWithSlash(item.ActivityDate) : 'N/A',
                    USER: item.TransaLogin ? item.TransaLogin : '',
                    DATE: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A'
                    }); 
                    i++;
                })
                this.dataEn = arregloEn;
                
                let j=0;
                listado.forEach(function (item) { 
                    arregloEs.push({
                        '#': j+1,
                        ACTIVIDAD: item.VisitActivityNameEs ? item.VisitActivityNameEs : '',
                        FECHA_TRANSACCION: item.ActivityDate ? DateFormater.formatDateTimeWithSlash(item.ActivityDate) : 'N/A',
                        USUARIO: item.TransaLogin ? item.TransaLogin : '',
                        FECHA: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A'
                    }); 
                    j++;
                });
                this.dataEs = arregloEs; 
                this.Called = item.Called;
                this.showTransactionHistory = true;
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
                this.$store.state.visitas.collapseTerminal = true;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    function asignarTerminal(item){
        this.itemSelected.Called = item.Called;
        this.itemSelected.VisitId = item.VisitId;
        this.itemSelected.VisitTerminalId = item.VisitTerminalJson[0].VisitTerminalId;
        this.$store.state.visitas.VisitId = item.VisitId;
        this.visitaTerminalId = item.VisitTerminalJson[0].VisitTerminalId;
    }
    async function listTerminalAction(){
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.$http.ejecutar('GET', 'VisitTerminalList-by-ItineraryId', { ItineraryId: this.ItineraryId }, '').then(responseTerminal => {
            if(responseTerminal.status === 200){
                this.listTerminal = responseTerminal.data.data;
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    async function handleSubmit(){
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$store.state.visitas.collapseTerminal = false;
        await this.$http.ejecutar('GET', 'VisitNextCalled-by-ItineraryId', { ItineraryId: this.ItineraryId }, '').then(responseinformation => {
            if(responseinformation.status === 200){
                let data = responseinformation.data.data;
                this.NextCalled = data[0].NextCalled;
                this.listTerminalAction();
                this.showTransactionHistory = false;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    async function mountedToken(){
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$store.state.visitas.collapseTerminal = false;
        await this.$http.ejecutar('GET', 'VisitNextCalled-by-ItineraryId', { ItineraryId: this.ItineraryId }, '').then(responseinformation => {
            if(responseinformation.status === 200){
                let data = responseinformation.data.data;
                this.NextCalled = data[0].NextCalled;
                if(this.NextCalled == 1){
                    this.$store.state.visitas.collapseTerminal = true;
                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                }else{
                    this.listTerminalAction();
                }
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', err);
        });
    }
    function titleItemsActivity(item){
        let local = DataStorage.getLocale();
        return local == 'es'? item.Label.Es : item.Label.En;
    }
    async function onBtnExport(valor) { 
       // let rowData = this.formatedItemsSchedule;
        let _this = this.$i18n.locale;
        let rowData = _this=='en'? this.dataEn : this.dataEs;
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        try{
            if(rowData.length !== 0){
                await this.getExcelArray(rowData,this.$t('label.recordInfo'),valor, undefined, this.Called);
            }
        } catch (error) {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('visitas/messageMutation', error);
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }
    //computed
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function fields(){ 
        return [
            { key: 'Called', label:  this.$t('label.list_terminal.n_toque') ,_classes:"center-cell", _style:'width:1%;'},
            { key: 'BerthName', label: this.$t('label.berth'),_classes:"center-cell"},
            { key: 'VoyageArrival', label: this.$t('label.list_terminal.viaje_arribo'),_classes:"center-cell"},
            { key: 'VoyageDeparture', label: this.$t('label.list_terminal.viaje_zarpe'),_classes:"center-cell"},
            { key: 'Ata', label: 'ATA',_classes:"center-cell"},
            { key: 'Atd', label: 'ATD',_classes:"center-cell"},
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'FormatedDate', label: this.$t('label.FECHA'),_classes:"center-cell", _style:'min-width:150px;'},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:1%', 
                sorter: false, 
                filter: false
            }
        ]
    }
    function formatedItems() {
        return this.listTerminal.map((item,index) => Object.assign({}, item, {
            RowNumber: index+1,
            Called: item.Called ? item.Called : "",
            Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
            Atd: item.Atd ? DateFormater.formatDateTimeWithSlash(item.Atd) : 'N/A',
            UltimoUsuario: item.TransaLogin,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A'
        }));
    }
    function fieldsRecord(){ 
        return [
            { key: 'RowNumber', label: '#',filter :false, _style: "width:3%; text-align:center;", _classes: 'text-center',},
            { key: 'Label', label:  this.$t('label.activity') ,_classes:"text-uppercase ", _style:'width:37%'},
          //  { key: 'TpTransactionName', label: this.$t('label.transactions'),_classes:"center-cell"},
            { key: 'ActivityDate', label: this.$t('label.transactionDate'),_classes:"text-uppercase", _style:'width:20%'},
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell", _style:'width:20%'},
            { key: 'FormatedDate', label: this.$t('label.date'),_classes:"center-cell", _style:'width:20%'},
        ]
    }
    function formatedItemsSchedule() {
        return this.ListVisitActivictyV.map((item,index) => Object.assign({}, item, {
            RowNumber: index+1,
            Label: { En: item.VisitActivityNameEn, Es: item.VisitActivityNameEs},
           // TpTransactionName: item.TpTransactionName,
            UltimoUsuario: item.TransaLogin,
            ActivityDate: item.ActivityDate ? DateFormater.formatDateTimeWithSlash(item.ActivityDate) : 'N/A',
            FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A'
        }));
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }

    //watch
    async function dropItemAgenda(newValue){
        if(newValue === 1){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            this.$store.state.visitas.collapseTerminal = false;
            await this.$http.ejecutar('GET', 'VisitNextCalled-by-ItineraryId', { ItineraryId: this.ItineraryId }, '').then(responseinformation => {
                if(responseinformation.status === 200){
                    let data = responseinformation.data.data;
                    this.NextCalled = data[0].NextCalled;
                    if(this.NextCalled == 1){
                        this.$store.state.visitas.collapseTerminal = true;
                        this.$store.state.visitas.apiStateForm = ENUM.INIT;
                    }else{
                        this.listTerminalAction();
                    }
                }else{
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                this.$store.commit('visitas/messageMutation', err);
            });
        }
    }
    export default {
        name:'terminal-details',
        data,
        mixins: [ReportesVisitas],
        mounted(){
            this.$nextTick(async () => {
                if(this.tabIndex == 2){
                    if(this.dropItemAgenda == 1){
                        await this.mountedToken();
                    }
                }
            });
        },
        methods:{
            nuevoToquen,
            handleSubmit,
            updateItem,
            listTerminalAction,
            asignarTerminal,
            titleItemsActivity,
            mountedToken,
            onBtnExport
        },
        components:{
            appRegisterTerminal
        },
        computed:{
            fields,
            fieldsRecord,
            formatedItemsSchedule,
            tableTextHelpers,
            formatedItems,
            apiStateLoading,
            ...mapState({
                apiState: state => state.visitas.apiState,
                collapseTerminal: state => state.visitas.collapseTerminal,
                ItineraryId: state => state.visitas.ItineraryId,
                appRegisterTerminal: state => state.visitas.appRegisterTerminal,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                tabIndex: state => state.visitas.tabIndex,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
            })
        },
        watch:{
            dropItemAgenda
        }
    }
</script>
