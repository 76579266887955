<template>
  <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
    <div
      class="card-header"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ $t("label.cranesMovements") }}</span>
    </div>
    <div class="card-body">
      <CRow>
        <CCol sm="12" style="height:350px">
          <MultipleBar
            height="350px"
            :labels="getCategories"
            :series="getSeries"
            positionLegend="bottom"
            :getScales="getScales"
          ></MultipleBar>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import MultipleBar from "../../components/multipleBarNew";
import { mapState } from 'vuex';

//Data
function data() {
  return {
    colors: ['#1e3a61', '#2196f3', '#8fcaf9', '#ec631b'],
  }
}

// Methods


//computed
function bandera(){
  return this.$store.state.situacionOperativaActual.tabsOperationes === 3 && this.$store.state.situacionOperativaActual.carruselTab === 1; 
}
function getSeries(){
  return [
    {
      label: this.$t("label.DischargeMovements"),
      data: this.getDischarges,
      backgroundColor: '#1e3a61',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.LoadMovements"),
      data: this.getLoads,
      backgroundColor: '#2196f3',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.HatchMovements"),
      data: this.getHatch,
      backgroundColor: '#26E7A6',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.averageMovementsPerHrs"),
      data: this.getMovsXHrs,
      backgroundColor: '#ec631b',
      stack: 'Stack 1',
      yAxisID: 'y1',
    },
  ];
}
function getScales(){
  return {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      display: true,
      position: 'left',
      title: {
        display: true,
        text: this.$t("label.MovsNumbers") // array so it becomes multi lined
      },
    },
    y1: {
      display: true,
      stacked: true,
      position: 'right',
      ticks:{
        color: "#e96418",
      },
      grid: {
        color: "#e96418",
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      title: {
        display: true,
        color: '#e96418',
        text: this.$t("label.averageMovementsPerHrs") // array so it becomes multi lined
      },
    },
  }
}

function getCategories() {
  return this.CranesAverageCompletedMovs.map((item) => {
    return item.CraneAlias;
  });
}
function getLoads() {
  return this.CranesAverageCompletedMovs.map((item) => {
    if (this.FgContainerShip) {
      return item.Load;
    }else {
      return item.LoadOnHold;
    }
  });
}
function getDischarges() {
  return this.CranesAverageCompletedMovs.map((item) => {
    return item.Discharge;
  });
}
function getHatch() {
  return this.CranesAverageCompletedMovs.map((item) => {
    return item.HatchCover;
  });
}
function getMovsXHrs() {
  return this.CranesAverageCompletedMovs.map((item) => {
    return item.MovsXHours;
  });
}


export default {
  name: "CranesMovements",
  props: {
    CranesAverageCompletedMovs: {
      type:Array,
      default: () => [],
    },
  },
  components: {
    MultipleBar
  },
  data,
  methods: {
    
  },
  computed: {
    getCategories,
    getLoads,
    getDischarges,
    getHatch,
    getMovsXHrs,
    getSeries,
    getScales,
    bandera,
    ...mapState({
      FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
    })
  },
};
</script>

<style scoped>
.container-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 100%;
}
.legend-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.circle {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;
  margin-right: 10px;
}

.bg-circle-promedio {
  background-color: #ec631b;
}
.text-legend-promedio {
  color: #ec631b;
}
.bg-circle-descarga {
  background-color: #1e3a61;
}
.text-legend-descarga {
  color: #1e3a61;
}
.bg-circle-carga {
  background-color: #03a9f4;
}
.text-legend-carga {
  color: #03a9f4;
}
.bg-circle-tapa {
  background-color: #a0e0fd;
}
.text-legend-tapa {
  color: #a0e0fd;
}
</style>