<template>
  <div>
    <CModalExtended :closeOnBackdrop="false" :show.sync="modalActive">
      <template slot="header">
        <div class="header-alert">
          <div
            class="swal2-icon swal2-warning swal2-icon-show"
            style="display: flex"
          >
            <div class="swal2-icon-content">!</div>
          </div>
          <div class="flex-grow-1 msg">
            {{ `${$t("label.the")} ${$t("label.file")}` }}
            <span class="msg-name">{{ fileName }}</span>
            {{ `${$t("label.successUpload")}` }}
          </div>
        </div>
      </template>
      <div style="color: #000">
        <div class="main-alert">
          <div class="correct">
            {{ getCorrectCount }} {{ $t("label.rightRows") }}
          </div>
          <div class="errors" v-if="getErrorsCount != 0">
            {{ getErrorsCount }} {{ $t("label.wrongRows") }}
          </div>
          <div class="footer-errors" v-if="getErrorsCount != 0">
            {{ `(${$t("label.errorsStaff")})` }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <CButton
          shape="square"
          color="success"
          class="mr-2"
          @click="onBtnExportErrors(staffList)"
          v-if="getErrorsCount != 0"
        >
          <CIcon name="cil-file-excel" />&nbsp; XLSX
        </CButton>
        <CButton shape="square" color="wipe" @click="toggle(false)">
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </div>
    </CModalExtended>
  </div>
</template>
<script>
import ModalMixin from "@/_mixins/modal";
import Reportes from '@/_mixins/reportes';
import StaffMixin from '@/_mixins/staff';

//DATA
function data() {
  return {
    modalActive: false,
  };
}

// METHODS
function toggle(newVal) {
  this.modalActive = newVal;
}

//COMPUTED
function getErrorsCount() {
  if (this.staffList.length == 0) return 0;

  let count = 0;
  for (let index = 0; index < this.staffList.length; index++) {
    if (!Array.isArray(this.staffList[index].JsonError)) count++;
  }

  return count;
}
function getCorrectCount() {
  if (this.staffList.length == 0) return 0;

  let count = 0;
  for (let index = 0; index < this.staffList.length; index++) {
    if (Array.isArray(this.staffList[index].JsonError)) count++;
  }

  return count;
}

export default {
  name: "alert-modal",
  mixins: [ModalMixin, Reportes, StaffMixin],
  data,
  props: {
    modal: Boolean,
    staffList: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "",
    },
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
  methods: {
    toggle,
  },
  computed: {
    getErrorsCount,
    getCorrectCount,
  },
};
</script>
<style scoped>
.main-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.msg {
  font-weight: bold;
  text-align: center;
}
.msg-name {
  font-weight: normal;
}
.correct {
  color: #1aff1a;
  font-weight: bold;
  margin-bottom: 10px;
}
.errors {
  color: #ff0101;
  font-weight: bold;
  text-align: center;
}
.footer-errors {
  margin-top: 10px;
  font-weight: bold;
}

.header-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>