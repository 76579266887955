<template>
  <main>
    <Measurements
      :cloudiness="this.forecast.cloudiness"
      :windSpeed="this.forecast.windSpeed"
      :humidity="this.forecast.humidity"
    ></Measurements>

    <Temperature
      :value="this.forecast.temperatureValue"
      :high="this.forecast.temperatureHigh"
      :low="this.forecast.temperatureLow"
    ></Temperature>

    <Weather
      :location="this.forecast.location"
      :description="this.forecast.description"
      :icon="this.forecast.weatherIcon"
    ></Weather>
  </main>
</template>

<script>
import Weather from "./Weather.vue";
import Temperature from "./Temperature.vue";
import Measurements from "./Measurements.vue";

function created(){
  this.currentWeather();
}
function mounted() {
  this.$nextTick(async () => {
    this.initInterval();
  });
}
async function currentWeather() {
  let data = null;

  try {
    data = await this.getForecast({
      latitude: this.latitude,
      longitude: this.longitude,
    });
  } catch (e) {
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:this.$i18n.locale == 'es'?'Clima - Error de Conexion.':'Weather - Network Error.',
      type: "error",
    });
    data = this.getErrorData();
  }

  this.populate(data);
}
async function getForecast(coordinates) {
  let appId = "d3fd57469bff8ae278b4dfd066d50387";
  let endpoint = `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates.latitude}&lon=${coordinates.longitude}&appid=${appId}&units=metric&lang=es`;

  let response = await fetch(endpoint);

  return await response.json();
}
function getErrorData() {
  return {
    clouds: { all: 0 },
    wind: { speed: 0 },
    main: {
      humidity: 0,
      temp: 0,
      temp_max: 0,
      temp_min: 0,
    },
    weather: [
      {
        id: 0,
        description: `There's a problem at the weather forecast server ¯\\_(ツ)_/¯`,
      },
    ],
    name: null,
    sys: {
      country: null,
    },
  };
}
function populate(data) {
  this.forecast.cloudiness = data.clouds.all;
  this.forecast.windSpeed = data.wind.speed;
  this.forecast.humidity = data.main.humidity;
  this.forecast.temperatureValue = Math.round(data.main.temp);
  this.forecast.temperatureHigh = Math.round(data.main.temp_max);
  this.forecast.temperatureLow = Math.round(data.main.temp_min);
  this.forecast.location = this.formatLocation(data.name, data.sys.country);
  this.forecast.description = data.weather[0].description;
  this.forecast.weatherIcon = this.getWeatherIcon(data.weather[0].id);
}

function formatLocation(city, country) {
  if (city === null && country === null) {
    return "";
  }

  return `${city}, ${country}`;
}
function getWeatherIcon(id) {
  if (this.isThunderstorm(id)) {
    return "/img/weather/thunderstorm.svg";
  }

  if (this.isDrizzle(id) || this.isRain(id)) {
    return "/img/weather/rain.svg";
  }

  if (this.isSnow(id)) {
    return "/img/weather/snow.svg";
  }

  return "/img/weather/cloud.svg";
}
function isThunderstorm(id) {
  return id > 199 && id < 233;
}
function isDrizzle(id) {
  return id > 299 && id < 322;
}
function isRain(id) {
  return id > 499 && id < 532;
}
function isSnow(id) {
  return id > 599 && id < 623;
}
function initInterval(){
  this.intervalPlaying = setInterval(() => {
    this.currentWeather();
  }, 60000);
}

export default {
  name: "WeatherApp",
  props: {
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Weather,
    Temperature,
    Measurements,
  },
  created,
  mounted,
  data() {
    return {
      forecast: {
        cloudiness: 0,
        windSpeed: 0,
        humidity: 0,
        temperatureValue: 0,
        temperatureHigh: 0,
        temperatureLow: 0,
        location: ' ',
        description: 'No connection',
        weatherIcon: '/weather/cloud.svg',
        intervalPlaying: null,
      },
    };
  },
  methods: {
    currentWeather,
    getForecast,
    populate,
    getErrorData,
    isDrizzle,
    isRain,
    isSnow,
    isThunderstorm,
    getWeatherIcon,
    formatLocation,
    initInterval,
  },
  beforeDestroy(){
    clearInterval(this.intervalPlaying);
  },
};
</script>

<style scoped>
main {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
}
</style>
