<template>
  <div>
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      size="xl"
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close'),items=[];"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedTransactionClientList"
            :fields="fields"
            :loading="LoadingTabla"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
             
          >
          <template #loading>
              <loading/>
          </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" class="d-flex align-items-center" @click="$emit('close'),items=[];">
            <CIcon name="x"/>&nbsp;
            <span class="ml-1">{{$t('button.exit')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
//import RolService from '@/_services/rol-service';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

function data () {
  return {
    items: [],
    Loading: false,
    LoadingTabla:false,
    titleModal: '',
    modalActive: false,
    ClientId:''
  }
}

//methods
function getClientTransactionList() {
  this.Loading = true;
  this.$http
    .get('ClientTransaction-list', {ClientId: this.ClientItem.ClientId})
    .then((response) => {
      let list = response.data.data;
      if (list.length != 0) {
        this.items = list;
      }
      this.Loading = false;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
      this.Loading = false;
    })  
}


//computed
function fields(){
  return [
    { key: 'Nro', label: '#',filter: false,_style: 'width:1%; text-align:left;'},
    { key: 'TpClientName',label: this.$t('label.ClientType'), _style:'width:20px', _classes:'text-center center-cell' },
    { key: 'ModuleName',label: this.$t('label.ModuleName'), _style:'width:20px', _classes:'text-center center-cell' },
    { key: 'VesselName', label: this.$t('label.vesselName'), _style:'width:20%;', _classes:'text-center center-cell'},
    { key: 'Voyage', label: this.$t('label.voyage'), _style:'width:10%;', _classes:'text-center center-cell'},
    { key: 'FormatedDateArrival', label: this.$t('label.arrival'), _style: 'width: 10%;', _classes:'text-center center-cell'},
    { key: 'FormatedDateDeparture', label: this.$t('label.departure'), _style: 'width: 10%;', _classes:'text-center center-cell'},
  ]
}


function computedTransactionClientList() {
  let _lang = this.$i18n.locale;
   return this.items.map((item) => {
     return {
       ...item,
       TpClientName: _lang=='en' ? item.TpClientNameEn : item.TpClientNameEs,
       ModuleName: _lang=='en' ? item.ModuleNameEn : item.ModuleNameEs,
       FormatedDateArrival: item.Arrival ? DateFormater.formatOnlyDateWithSlash(item.Arrival)
         : 'N/A',
       FormatedDateDeparture: item.Departure
         ? DateFormater.formatOnlyDateWithSlash(item.Departure)
         : 'N/A',
     };
   });
 }



//FUNCIONES DE METHOD
export default {
  name: 'cliente-transacciones-modal',
  data,
  directives: UpperCase,
  props: { modal: Boolean, ClientItem: Object, title: String,},
  mixins: [General],

  methods: {
    getClientTransactionList
  },
  computed: {
    fields,  
    computedTransactionClientList 
  },
  watch: {
    modal: function(val){
        this.modalActive = val;
        if (val) {
          this.getClientTransactionList();
        }
      }
  }
}
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>