import {
  required,
  minLength
} from 'vuelidate/lib/validators';

export default () => {
  return {
    turn: { required },
    vesselType: { required }
  }
}

export const shiftVesselTypeFormEditValidations = () => {
  return {
    turn: { required },
    vesselType: { required }
  }
}