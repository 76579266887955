<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="closeModal()"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
            :label="$t('label.yard')"
            addLabelClasses="required text-right"
            :options="YardListFormatted"
            v-model.trim="$v.Yard.$model"
            :is-valid="hasError($v.Yard)"
            :invalid-feedback="errorMessage($v.Yard)"
            :value.sync="Yard"
          />
        </CCol>  
        <CCol sm="12" lg="12">
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
            :label="$t('label.machinery')"
            addLabelClasses="required text-right"
            :options="MachineryListFormatted"
            v-model.trim="$v.Machinery.$model"
            :is-valid="hasError($v.Machinery)"
            :invalid-feedback="errorMessage($v.Machinery)"
            :value.sync="Machinery"
          />
        </CCol>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-4', input:'col-sm-10 col-lg-7'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="isEdit ? statusConfirmation(OriginStatus, Status, submitBody) : submitBody()"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import machineryYardValidations from '@/_validations/machinery-in-yard/machineryYardValidations';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    apiStateLoading: false,
    Status: 0,
    OriginStatus: 0,
    Yard: '',
    YardName: '',
    YardOptions:[],
    Machinery: '',
    MachineryOptions:[],
    ItemId: '',
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.apiStateLoading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const MachineYardJson = this.isEdit ? {
      MachineYardId: this.ItemId,
      MachineId: this.Machinery,
      YardId: this.Yard,
      Status: this.Status,
    }:{
      MachineId: this.Machinery,
      YardId: this.Yard,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'MachineYard-update':'MachineYard-insert';
    this.$http
      .ejecutar(metodo, ruta, MachineYardJson, { root: 'MachineYardJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.apiStateLoading = false;
          this.closeModal();
          this.isSubmit = false;
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function titleModal() {
  if (this.isEdit) {
    return this.$t('label.edit')+' '+this.$t('label.machineryInYard')+': '+this.YardName;
  } else {
    return this.$t('label.nueva')+' '+this.$t('label.machineryInYard');
  }
}

function getYarList() {
  this.$http.get("Yard-list", {CompanyBranchId: this.branch.CompanyBranchId}, {Filter: 'ACTIVO'})
    .then(response => {       
      this.YardOptions = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
       //     this.apiStateLoading = false;
    });
}

function getMachineryList() {
  this.$http.get("Machine-by-CompanyBranchId", {CompanyBranchId: this.branch.CompanyBranchId})
    .then(response => {       
      this.MachineryOptions = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
       //     this.apiStateLoading = false;
    });
}

function statusSelectColor() {
  return this.Status === 1;
}

function getdata(id) {
  this.apiStateLoading = true;
  this.$http.get("MachineYard-by-id", {MachineYardId: id})
    .then(response => {       
      let item = response.data.data;
      this.ItemId = item[0].MachineYardId;
      this.YardName = item[0].YardName;
      this.Machinery = item[0].MachineId;
      this.Yard = item[0].YardId;
      this.Status = item[0].Status=='ACTIVO'?1:0;
      this.OriginStatus = item[0].Status=='ACTIVO'?1:0;
      this.$v.$touch();
      this.apiStateLoading = false;
    }).catch( err => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function closeModal() {
  this.Yard = '';
  this.Machinery = '';
  this.Status = 0;
  this.$v.$reset();
  this.$emit('Update-list');
}

function YardListFormatted(){
  if(this.YardOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.YardOptions.map(function(e){
      chart.push({
        value: e.YardId, 
        label: e.YardName,
      }) 
    })
    return chart;
  }
}

function MachineryListFormatted(){
  if(this.MachineryOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineryOptions.map(function(e){
      chart.push({
        value: e.MachineId, 
        label: e.MachineName,
      }) 
    })
    return chart;
  }
}

export default {
  name: 'modal-machinery-yard',
  props: { modal: Boolean, isEdit: Boolean, MachineYardId: String, branch: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: machineryYardValidations,
  directives: UpperCase,
  methods: {
    getYarList,
    getMachineryList,
    statusSelectColor,
    closeModal,
    getdata,
    submitBody,
  },
  computed: {
    titleModal,
    YardListFormatted,
    MachineryListFormatted,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      this.getMachineryList();
      this.getYarList();
      if (this.isEdit==true) {
        this.getdata(this.MachineYardId);
      }
    }
  },
 
};
</script>