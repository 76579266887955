<template>
    <div style="width: 100%">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-recursos1" fill="currentColor" viewBox="0 0 43.21 36.08">
                <g id="a"/>
                <g id="b">
                    <g id="c">
                        <path class="d" d="M40,24.41l-1.74,.02v-.85l3.33-2.89c.2-.17,.26-.45,.15-.68-.11-.23-.36-.37-.62-.33l-2.86,.47v-.52h-1.16v4.79l-4.71-.02v-.85l3.33-2.89c.2-.17,.25-.45,.14-.68-.11-.23-.36-.37-.62-.33l-2.86,.47v-.52h-1.16v4.79h-4.51v-.85l3.33-2.89c.2-.17,.26-.45,.15-.68-.11-.23-.36-.37-.62-.33l-2.86,.47v-.52h-1.16v4.79h-2.14v-2.93h.96v-1.8h-.97v-3.6h-.85v3.6h-.61v1.34h-.53v-3.26h-1.56v6.65h-.87l.44,3.09,1.41,1.89h18.72l3.66-4.99h-2.05m-1.86-3.26l-1.04,.9v-.73l1.04-.17Zm-5.87-.02l-1.04,.9v-.73l1.04-.17Zm-5.67,0l-1.04,.9v-.73l1.04-.17Z"/>
                        <path d="M.92,36.08v-4.82H40.18v4.82H.92Z"/>
                        <path d="M2.22,18.35h6.13v11.34h-1.14v-9.69h-1.31v9.69H2.22v-11.34Z"/>
                        <path d="M10.87,7.27h6.13V29.69h-1.14V10.53h-1.31V29.69h-3.69V7.27Z"/>
                        <polygon class="d" points=".04 16.35 0 14.35 4.31 14.27 6.73 1.86 13.39 0 13.93 1.93 8.46 3.45 5.96 16.24 .04 16.35"/>
                    </g>
                </g>
            </symbol>
        </svg>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-peso" fill="currentColor" viewBox="0 0 38.51 48.39">
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <path d="M38.44,42.53,34.37,17.44a5.06,5.06,0,0,0-5-4.25h-4a8,8,0,1,0-12.31,0h-4a5.06,5.06,0,0,0-5,4.25L.07,42.53a5.05,5.05,0,0,0,5,5.86H33.45A5.05,5.05,0,0,0,38.44,42.53ZM19.25,3.71A4.32,4.32,0,1,1,14.94,8,4.32,4.32,0,0,1,19.25,3.71Zm10,38-.39-7.85c0-.42,0-.87-.06-1.34l-.23.74L26,41.62H23.29l-2.44-8.23c0-.15-.08-.3-.13-.44,0,.37,0,.73,0,1.09l-.42,7.68H16.77l.86-14.2H13.75v14.2H10.17V27.52H6V24H21.61l2.56,8.34c.23.77.43,1.47.61,2.12.18-.65.39-1.35.63-2.12L28.08,24h3.77l1,17.72Z"/>
                    </g>
                </g>
            </symbol>
        </svg>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-recursos2" fill="currentColor" viewBox="0 0 43.21 36.08">
                <g id="a"/>
                <g id="b">
                    <g id="c">
                        <path class="d" d="M.76,36.26v-4.82H40.02v4.82H.76Z"/>
                        <path class="d" d="M2.05,18.71h6.13v11.16h-1.14v-9.54h-1.31v9.53H2.05v-11.16Z"/>
                        <path class="d" d="M10.71,7.41h6.13V29.87h-1.14V10.68h-1.31V29.87h-3.69V7.41Z"/>
                        <path class="d" d="M35.82,14.42v4.49h-14.72v-4.49h-2v14.9h.03v.43l18.66-.03v-.4h.03V14.42h-2Zm-14.72,6.49h14.72v6.81l-14.72,.03v-6.84Z"/>
                        <polygon class="d" points="32.17 14.42 28.44 17.45 24.71 14.42 27.44 14.42 27.44 5.51 29.44 5.51 29.44 14.42 32.17 14.42"/>
                        <polygon class="d" points=".04 16.35 0 14.35 4.31 14.27 6.73 1.86 13.39 0 13.93 1.93 8.46 3.45 5.96 16.24 .04 16.35"/>
                    </g>
                </g>
            </symbol>
        </svg>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-grua" fill="currentColor" viewBox="0 0 22.2 25.44">
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1">
                        <path class="cls-1" d="M21.28,0a.91.91,0,0,0-.9.85L7.81,2.39a2.32,2.32,0,0,0-4.48.84,2.32,2.32,0,0,0,1.25,2V12.1H2.15v6.64H0v6.7H10.62v-6.7h-2V12.1H6.72V5.27a2.29,2.29,0,0,0,1.2-1.6L20.44,1.28a.85.85,0,0,0,.65.51V5.08h.46V1.78A.9.9,0,0,0,22.2.92.92.92,0,0,0,21.28,0ZM6.14,18.86H4.59V14.64H6.14ZM5.65,4.41A1.2,1.2,0,1,1,6.84,3.22,1.19,1.19,0,0,1,5.65,4.41Z"/>
                    </g>
                </g>
            </symbol>
        </svg>
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1" style="height:100%">
                <CCollapse v-for="(item,index) in myDataClient" :show="carruselTab === index+1" :key="index">
                    <SituacionActualCliente :clientData="item.ClientName" :myDataCarrier="item.CarrierJson" :ClientCarrierTotal="item.ClientCarrierTotalJson[0]"></SituacionActualCliente>
                </CCollapse>
                <CCollapse :show="carruselTab === (myDataClient.length+1)"  class="p-arrows-carousel" >
                    <ClienteYard :ClientYardSummary="ClientYardSummary"></ClienteYard>
                </CCollapse>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import SituacionActualCliente from './SituacionActualCliente';
    import ClienteYard from './ClienteYard';
    //data
    function data() {
        return {
            interval: null,
            myDataClient: [],
            ClientYardSummary: [],
        }
    }
    //methods
    function getClientBl() {
        this.$store.state.situacionOperativaActual.Loading = true;
        this.$http.ejecutar('GET', 'VisitOperationalSituationTabClientBl', {VisitId: this.Vessel.VisitId})
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    this.myDataClient = List[0]?.HoldSituationJson[0]?.ClientJson?List[0].HoldSituationJson[0].ClientJson:[];
                    this.ClientYardSummary = List[0]?.ClientYardSummaryJson ? List[0]?.ClientYardSummaryJson : [];
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab < this.myDataClient.length+1){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }
    }
    //computed
    function bulkCarrier() {
        return this.Vessel?.TpVesselId.toUpperCase() === process.env.VUE_APP_BULK_VESSEL_ID ? 2 : 1;
    }
    function minCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab > 1
    }
    function maxCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab < this.myDataClient.length+1
    }
    export default {
        name:'indexClienteBl',
        data,
        props: {
            Vessel: {
                type: Object,
                default: function () {
                    return {};
                },
            }
        },
        methods:{
            getClientBl,
            disminuirCarrusel,
            aumentarCarrusel,
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if(this.$store.state.situacionOperativaActual.carruselTab < this.myDataClient.length+1){
                        this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaActual.carruselTab = 1;
                    } 
                }, 30000);
            }
        },
        components:{
            SituacionActualCliente,
            ClienteYard
        },
        computed:{
            bulkCarrier,
            minCarrusel,
            maxCarrusel,
            ...mapState({
                carrusel: state => state.situacionOperativaActual.carrusel,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
            }),
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                if(OldValue === 3&&this.bulkCarrier==2){
                    this.myDataClient = [];
                    this.ClientYardSummary = [];
                    this.$store.state.situacionOperativaActual.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === 3&&this.bulkCarrier==2){
                    this.getClientBl();
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue,OldValue){
                if(newValue){
                    if(this.tabsOperationes === 3&&this.bulkCarrier==2){
                        this.$nextTick(async () => {
                            if(this.interval !== null){
                                this._setInterval();
                            }
                        });
                    }
                }else{
                    clearInterval(this.interval);
                    this.interval = null;
                }
            }
        }
    }
</script>