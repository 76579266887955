<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-1 mb-2">
      <CCol sm="12" class="d-flex align-items-start justify-content-end">
        <CButton
          shape="square"
          color="add"
          @click="toggleModal"
          v-c-tooltip="{
            placement: 'top-end',
            content: $t('label.order'),
          }"
        >
          <CIcon name="cil-playlist-add" />{{ $t("label.nuevo") }}
        </CButton>
      </CCol>
    </CRow>
    <CRow class="mt-2">
      <CCol sm="2">
        <CSelect
          size="sm"
          :label="$t('label.client')"
          addLabelClasses="text-right"
          :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8' }"
          :placeholder="$t('label.select')"
          :options="clientOptions"
          :disabled="isEmpty(clientOptions)"
          :value.sync="client"
        >
        </CSelect>
      </CCol>
      <CCol sm="2">
        <CSelect
          size="sm"
          :label="$t('label.yard')"
          addLabelClasses="text-right"
          :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9' }"
          :placeholder="$t('label.select')"
          :options="yardOptions"
          :disabled="isEmpty(yardOptions)"
          :value.sync="yard"
        >
        </CSelect>
        <loading v-show="isLoadingYard" element="select" />
      </CCol>
      <CCol sm="2">
        <CInput
          size="sm"
          :label="$t('label.nroControl')"
          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7' }"
          addLabelClasses="text-right"
          :placeholder="$t('label.nroControl')"
          v-uppercase
          v-model.trim="nroControl"
        />
      </CCol>
      <CCol sm="4">
        <div role="group" class="form-group form-row">
          <label
            class="
              text-right
              col-form-label col-sm-12 col-lg-2 col-form-label-sm
            "
          >
            {{ $t("label.date") }}
          </label>
          <!-- :disabled-date="validateDateRange" -->
          <div class="col-sm-12 col-lg-4 input-group-sm">
            <vue-datepicker
              :lang="$i18n.locale"
              v-model="searchFilterDate.fromDay"
              type="date"
              format="DD/MM/YYYY"
              value-type="format"
              placeholder="DD/MM/YYYY"
              time-title-format="DD/MM/YYYY"
              :clearable="false"
              popup-class="vuedatapickerespecial"
              :editable="false"
              :append-to-body="false"
              :disabled-date="validateDateRange"
              class="col-sm-10 col-lg-12 col-xl-12 px-0 vue-datepicker-drive:focus"
            >
              <template #input>
                <CInput
                  v-uppercase
                  v-model.trim="searchFilterDate.fromDay"
                  @keypress="rightDate($event)"
                  class="mb-0"
                  size="sm"
                  placeholder="DD/MM/YYYY"
                >
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="text-center font-weight-bold px-1 col-sm-12 col-lg-1 input-group-sm">-</div>
          <div class="col-sm-12 col-lg-4 input-group-sm">
            <vue-datepicker
              :lang="$i18n.locale"
              v-model="searchFilterDate.toDay"
              type="date"
              format="DD/MM/YYYY"
              value-type="format"
              placeholder="DD/MM/YYYY"
              time-title-format="DD/MM/YYYY"
              :clearable="false"
              popup-class="vuedatapickerespecial"
              :editable="false"
              :append-to-body="false"
              :disabled-date="validationFilterDate"
              class="col-sm-10 col-lg-12 col-xl-12 px-0 vue-datepicker-drive:focus"
            >
              <template #input>
                <CInput
                  v-uppercase
                  v-model.trim="searchFilterDate.toDay"
                  @keypress="rightDate($event)"
                  class="mb-0"
                  size="sm"
                  placeholder="DD/MM/YYYY"
                >
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
        </div>
      </CCol>
      <CCol sm="2" class="d-flex align-items-start justify-content-end">
        <CButtonGroup>
          <CButton
            size="sm"
            class="mr-1"
            shape="square"
            color="excel"
            @click="getReportList"
            v-c-tooltip="{
              placement: 'top-end',
              content: `${$t('label.download')} XLSX`,
            }"
          >
            <CIcon name="cil-file-excel" />&nbsp;XLSX
          </CButton>
          <CButton
            size="sm"
            shape="square"
            color="watch"
            @click="getReportList(false)"
            v-c-tooltip="{
              placement: 'top-end',
              content: `${$t('label.download')} CSV`,
            }"
          >
            <CIcon name="cil-file-excel" />&nbsp;CSV
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>

    <CRow class="mb-1">
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fieldsReception"
          :items="formatedEntryList"
          sorter
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="tableText.itemsPerPage"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingList"
          @pagination-change="changePagination($event, getEntryList)"
          hover
          :paginator="{
            TotalPages: totalPages,
            TotalRecords: totalRecords,
            Page: page,
          }"
        >
          <template #over-table>
            <div class="row justify-content-start" style="margin-top: -2.8rem">
              <div class="col-auto px-2">
                <label class="p-1 mt-1"
                  >{{ $t("label.filter") }}<b>: </b></label
                >
              </div>
              <div class="col-auto px-0">
                <CInput
                  v-model="searchKey"
                  :placeholder="$t('label.enterSearch')"
                  v-uppercase
                  class="col-sm-12 px-0"
                />
              </div>
              <div class="col-auto px-2">
                <CButton
                  color="watch"
                  class="d-flex align-items-center"
                  v-c-tooltip="{
                    content: $t('label.search'),
                    placement: 'top',
                  }"
                  @click="searchAdvanceFilter(getEntryList)"
                >
                  <CIcon name="cil-search" />
                </CButton>
              </div>
              <div class="col-auto px-0">
                <CButton
                  color="wipe"
                  class="d-flex align-items-center"
                  v-c-tooltip="{
                    content: $t('label.reset') + $t('label.filter'),
                    placement: 'top',
                  }"
                  @click="clearFilters"
                >
                  <CIcon name="cil-brush-alt" />
                </CButton>
              </div>
            </div>
          </template>
          <template #loading>
            <loading />
          </template>
          <template #Status="{ item }">
            <td class="center-cell">
              <CBadge :color="getBadge(item.Status)">
                {{ $t("label." + item.Status) }}
              </CBadge>
            </td>
          </template>

          <template #Detalle="{ item }">
            <td class="text-center align-middle">
              <CButton
                shape="square"
                color="edit"
                size="sm"
                v-c-tooltip="$t('label.edit') + ' ' + $t('label.order')"
                @click="toggleModal(true, item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
          <!--paginacion-->
          <template #under-table>
            <div v-if="totalPages > 0" class="ml-3 mb-3">
              <CPagination
                :activePage.sync="page"
                :pages="totalPages"
                size="sm"
                @update:activePage="updatePage($event, getEntryList)"
              />
            </div>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <EntryOrderModal
      :modal.sync="modal"
      :entryOrderId="entryOrderId"
      :edit="edit"
      @submited="handleSubmit"
    />
  </div>
</template>

<script>
import { DateFormater } from "@/_helpers/funciones";
import UpperCase from "@/_validations/uppercase-directive";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import General from "@/_mixins/general";
import ModalMixin from '@/_mixins/modal';
import MetalScrap from "@/_mixins/metalscrap";
import mixinServicio from '@/_mixins/servicio';
import DatatablePagination from "@/_mixins/datatablePagination";
import EntryOrderModal from "./entry-order-modal";
import { dateValidationByYearQty } from "@/_validations/funciones";

import { mapState } from "vuex";

function fieldsReception() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      _classes: "text-center",
      filter: false,
    },
    { key: "NroBl", label: "BL" },
    { key: "Client", label: this.$t("label.client") },
    { key: "Yard", label: this.$t("label.yard") },
    { key: "IdDriver", label: this.$t("label.idDriver") },
    { key: "Driver", label: this.$t("label.driver") },
    { key: "Plate", label: this.$t("label.plate") },
    { key: "NroGuide", label: this.$t("label.nroGuide") },
    { key: "NroControl", label: this.$t("label.nroControl") },
    { key: "Weight", label: this.$t("label.netWeight") + "(KG)" },
    { key: "EntryDate", label: this.$t("label.entryDate") },
    { key: "OutDate", label: this.$t("label.outDate") },
    { key: "Usuario", label: this.$t("label.user") },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NroBl: "align-middle",
    Client: "align-middle",
    Yard: "align-middle",
    IdDriver: "align-middle",
    Driver: "align-middle",
    Plate: "align-middle",
    NroGuide: "align-middle",
    NroControl: "align-middle",
    Weight: "align-middle",
    EntryDate: "align-middle",
    OutDate: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    items: [],
    loadingList: false,
    loading: false,
    modal: false,
    edit: false,
    entryOrderId: "",
    clients: [],
    client: "",
    yards: [],
    yard: "",
    nroControl: "",
    isLoadingYard: false,
    disabledFilterByPreference: false,
    // Search Filters
    searchFilterDate: {
      fromDay: "",
      toDay: "",
    },
    openDateTo: false,
    openDateFrom: false,
    reportTitle: `${this.$t('label.metalScrapReception')}`,
  };
}

//METHOD
function loadData() {
  this.loading = true;
  this.loadingList = true;

  let entryOrderJson = JSON.stringify({ 
    EntryOrderJson: [
      { 
        SearchKey: this.searchKey,
        CompanyBranchId: this.getBranchId,
        YardId: this.yard,
        ClientTpId: this.client,
        ControlNumber: this.nroControl,
        DateFrom: this.searchFilterDate.fromDay,
        DateTo: this.searchAdvanceFilter.toDay,
      }
    ] 
  });

  let rutaEntryOrders = `EntryOrder-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&EntryOrderJson=${entryOrderJson}`;

  let peticiones = [
    this.$http.ejecutar("GET", "ConsigneBlYard-list", {
      CompanyBranchId: this.getBranchId,
      Filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "Yard-list-by-ConsigneId", {
      CompanyBranchId: this.getBranchId,
      ClientTpId: this.client,
    }),
    this.$http.ejecutar("GET", rutaEntryOrders),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.clients = responses[0].data.data;
      this.yards = responses[1].data.data;
      if (responses[2].data.data) {
        this.items = responses[2].data.data.data;
        this.totalPages = responses[2].data.data.TotalPages;
        this.totalRecords = responses[2].data.data.TotalRecords;
      } else {
        this.items=[];
        this.totalPages = 0;
        this.totalRecords = 0;
      }
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.loading = false;
      this.loadingList = false;
    });
}
function getEntryList() {
  this.loadingList = true;

  let entryOrderJson = JSON.stringify({ 
    EntryOrderJson: [
      { 
        SearchKey: this.searchKey,
        CompanyBranchId: this.getBranchId,
        YardId: this.yard,
        ClientTpId: this.client,
        ControlNumber: this.nroControl,
        DateFrom: this.formatDate(this.searchFilterDate.fromDay, true),
        DateTo: this.formatDate(this.searchFilterDate.toDay, true),
      }
    ] 
  });

  let ruta = `EntryOrder-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&EntryOrderJson=${entryOrderJson}`;

  this.$http.get(ruta)
    .then((response) => {
      if (response.data.data) {
        this.items = response.data.data.data;
        this.totalPages = response.data.data.TotalPages;
        this.totalRecords = response.data.data.TotalRecords;
        this.makeReportTitle();
      } else {
        this.items=[];
        this.totalPages = 0;
        this.totalRecords = 0;
      }
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.loadingList = false;
    });
}
function getYards(id) {
  this.isLoadingYard = true;

  this.$http
    .get("Yard-list-by-ConsigneId", { CompanyBranchId: this.getBranchId, ClientTpId: id })
    .then((response) => {
      this.yards = response.data.data;
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.isLoadingYard = false;
    });
}

function clearFilters() {
  this.nroControl = "";
  this.searchFilterDate.fromDay = '';
  this.searchFilterDate.toDay = ''
  this.yard = "";
  this.client = "";
  this.reportTitle = `${this.$t('label.metalScrapReception')}`;
  this.page = 1;
  this.searchKey= '',
  this.getEntryList();
}
function handleSubmit() {
  this.loadData(true);
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.entryOrderId = item.OrderId;
  }
  this.modal = true;
}
function functionDate(event, input) {
  if (event && input == 1) {
    this.openDateFrom = event;
  }

  if (event && input == 2) {
    this.openDateTo = event;
  }
}
function resetForm() {

}
function getReportList(excel = true) {
  this.loading = true;

  let entryOrderJson = JSON.stringify({ 
    EntryOrderJson: [
      { 
        SearchKey: this.searchKey,
        CompanyBranchId: this.getBranchId,
        YardId: this.yard,
        ClientTpId: this.client,
        ControlNumber: this.nroControl,
        DateFrom: this.formatDate(this.searchFilterDate.fromDay, true),
        DateTo: this.formatDate(this.searchFilterDate.toDay, true),
      }
    ] 
  });

  let ruta = `EntryOrder-list?PageNumber=${this.page}&TotalRecords=${this.totalRecords}&fgList=0&EntryOrderJson=${entryOrderJson}`;

  this.$http.get(ruta)
    .then((response) => {
      if (response.data.data) {
       this.onBtnExportAll(response.data.data.data, excel, this.reportTitle, [], true);
      }
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.loading = false;
    });
}
function makeReportTitle() {
  this.reportTitle = `${this.$t('label.metalScrapReception')}`;
      this.reportTitle += this.searchFilterDate.fromDay 
        ?` - ${this.$t('label.from')}: ${this.formatDate(this.searchFilterDate.fromDay, true)}` : '';
      this.reportTitle += this.searchFilterDate.toDay 
        ?` - ${this.$t('label.to')}: ${this.formatDate(this.searchFilterDate.toDay, true)}` : '';
}
function validationFilterDate(date) {
  return dateValidationByYearQty(date);
}
function validateDateRange(date) {
  if(this.searchFilterDate.toDay) {
    let arr = this.searchFilterDate.toDay.split('/'),
      day = Number.parseInt(arr[0])+1;

    day = day < 10 ? `0${day}` : day;

    let maxDate = new Date(`${arr[1]}/${day}/${arr[2]}`),
      year = maxDate.getFullYear() - 1,
      minDate = new Date(`${arr[1]}/${arr[0]}/${year}`);

    return (date >= maxDate || date <= minDate);
  } else {
    return dateValidationByYearQty(date);
  }
}
function testDateRange(date) {
  let arr = this.searchFilterDate.fromDay.split('/'),
    day = Number.parseInt(arr[0])+1;

  day = day < 10 ? `0${day}` : day;

  let maxDate = new Date(`${arr[1]}/${day}/${arr[2]}`),
    year = maxDate.getFullYear() - 1,
    minDate = new Date(`${arr[1]}/${arr[0]}/${year}`);

  return (date >= maxDate || date <= minDate);
}
// COMPUTED
function formatedEntryList() {
  return this.items.map((item) =>
    Object.assign({}, item, {
      Nro: item.Nro,
      Client: item.ClientName,
      Yard: item.YardName,
      IdDriver: item.DriverCi,
      Driver: item.DriverName,
      Plate: item.LicensePlate,
      NroGuide: item.GuideNumber,
      NroControl: item.ControlNumber,
      Weight: formatMilDecimal(item.NetWeight),
      EntryDate: item.EntryDate
        ? DateFormater.formatOnlyDateWithSlash(item.EntryDate)
        : "N/A",
      OutDate: item.OutDate
        ? DateFormater.formatOnlyDateWithSlash(item.OutDate)
        : "N/A",
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      Status: item.Status,
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    })
  );
}
function clientOptions() {
  return this.clients.map((client) =>
    Object.assign({}, client, {
      label: client.ClientName,
      value: client.ClientTpId,
    })
  );
}
function isEmpty(arr) {
  return arr.length == 0;
}
function yardOptions() {
  return this.yards.map((yard) =>
    Object.assign({}, yard, {
      label: yard.YardName,
      value: yard.YardId,
    })
  );
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
function currentToDate() {
  return this.searchFilterDate.toDay;
}

export default {
  name: "manual-load-tab",
  mixins: [General, MetalScrap, ModalMixin, mixinServicio, DatatablePagination],
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  directives: UpperCase,
  data,
  components: {
    EntryOrderModal,
  },
  methods: {
    loadData,
    handleSubmit,
    toggleModal,
    clearFilters,
    isEmpty,
    validateDateRange,
    getEntryList,
    getYards,
    functionDate,
    resetForm,
    getReportList,
    makeReportTitle,
    validationFilterDate,
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 0) {
        this.getEntryList();
      }
    },
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.entryOrderId = "";
      }
    },
    client: function (val) {
      this.getYards(val);
    },
    currentToDate: function (newVal, oldVal) {
      if(newVal != oldVal) {
        if(newVal && this.searchFilterDate.fromDay){

          let arrTo = newVal.split('/'),
            dayTo = Number.parseInt(arrTo[0])+1;
          dayTo = dayTo < 10 ? `0${dayTo}` : dayTo;
          
          let arrFrom = this.searchFilterDate.fromDay.split('/'),
            dayFrom = Number.parseInt(arrFrom[0])+1;
          dayFrom = dayFrom < 10 ? `0${dayFrom}` : dayFrom;
  
          let dateFrom = new Date(`${arrFrom[1]}/${dayFrom}/${arrFrom[2]}`),
              dateTo = new Date(`${arrTo[1]}/${dayTo}/${arrTo[2]}`);

          if(dateFrom > dateTo)
            this.searchFilterDate.fromDay = '';
        }
      }
    },
  },
  computed: {
    fieldsReception,
    formatedEntryList,
    clientOptions,
    cellTableClasses,
    yardOptions,
    getBranchId,
    currentToDate,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
  created() {},
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.upload-progress {
  width: 100%;
}
</style>