<template>
<CModalExtended
  :title="getTitulo"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow  class="mb-3">
              <CCol sm="4">
                <div class="preview">    
                  <picture-input
                    v-if="modalActive"
                    ref="imageInput"
                    :imgSrc="image"
                    width="200"
                    height="200"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                  >
                  </picture-input>
                </div>
                <div v-if="$v.tipoGrua.TpCraneRoute.$error">
                  <div class='text-invalid-feedback' v-if="!$v.tipoGrua.TpCraneRoute.isValidFile">{{$t('label.badImage')}}</div>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.TpCraneName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.tipoGrua.TpCraneName)"
                      :is-valid="hasError($v.tipoGrua.TpCraneName)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.name')"
                      :placeholder="$t('label.craneTypeName')"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.MaxHeigth.$model"
                      :invalid-feedback="errorMessage($v.tipoGrua.MaxHeigth)"
                      :is-valid="hasError($v.tipoGrua.MaxHeigth)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.maxHeigth')+'(MTR)'"
                      addLabelClasses="required text-right"
                      placeholder="0"
                      maxlength="20"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.MaxWeigth.$model"
                      :invalid-feedback="errorMessage($v.tipoGrua.MaxWeigth)"
                      :is-valid="hasError($v.tipoGrua.MaxWeigth)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.maxWeight')+'(TON)'"
                      addLabelClasses="required text-right"
                      placeholder="0"
                      maxlength="20"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CSelect
                      :value.sync="tipoGrua.Status"
                      :is-valid="statusSelectColor"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.status')"
                      :options="statusOptions"
                      addLabelClasses="text-right"
                      class="mt-1"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <!--
:disabled="isSubmitValid"

    -->
    <CButton
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="statusConfirmation(tipoGruaData.FgActTpCrane, tipoGrua.Status, submitFile, tipoGruaData.TpCraneName)"
    >
      <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      -->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TipoGruaValidations from '@/_validations/tipo-grua/TipoGruaFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import TipoGruaMixin from '@/_mixins/tipo-grua';
import ModalMixin from '@/_mixins/modal';
import { GenerateImgPlaceholder } from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    tipoGrua: {
      TpCraneId: '',
      TpCraneName: '',
      MaxHeigth: '',
      MaxWeigth: '',
      TpCraneRoute: '',
      Status: 0
    },
    titulo: '',
    image: ''
  }
}

//Methods
function submitFile() {
  if(this.tipoGruaData.TpCraneRoute == this.tipoGrua.TpCraneRoute)
    this.submit();
  else{
    try {
      this.isSubmit = true;
      this.$v.tipoGrua.$touch();
        if (this.$v.tipoGrua.$error) {
          this.isSubmit = false;
          throw this.$t('label.errorsPleaseCheck');
        }

        this.$http.file('TpCrane-update', this.tipoGrua.TpCraneRoute, this.tipoGruaData.TpCraneRoute)
        .then((response) => {
          this.tipoGrua.TpCraneRoute = response.data.data.files[0].path;
          this.submit();
        }).catch((err) => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
          this.isSubmit = false;
        });
    } catch (e) {
      this.notifyError({ text: e });
    }
  }
}
function submit() {
  try {
    this.isSubmit = true;
    this.$v.tipoGrua.$touch();
    if (this.$v.tipoGrua.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
  
    let TpCraneJson = {
      TpCraneId: this.tipoGrua.TpCraneId,
      TpCraneName: this.tipoGrua.TpCraneName,
      MaxHeigth: this.tipoGrua.MaxHeigth,
      MaxWeigth: this.tipoGrua.MaxWeigth,
      TpCraneRoute: this.tipoGrua.TpCraneRoute,
      Status: this.tipoGrua.Status
    };

    this.$http.put('TpCrane-update', TpCraneJson, { root: 'TpCraneJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.error')+'!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  else
    this.$v.$touch();
    
  this.modalActive = newVal;
}
function setTpGruaData(newTpGrua) {
  this.tipoGrua.TpCraneId = newTpGrua.TpCraneId;
  this.tipoGrua.TpCraneName = newTpGrua.TpCraneName;
  this.tipoGrua.MaxHeigth = newTpGrua.MaxHeigth;
  this.tipoGrua.MaxWeigth = newTpGrua.MaxWeigth;
  this.tipoGrua.TpCraneRoute = newTpGrua.TpCraneRoute;
  this.tipoGrua.Status = newTpGrua.FgActTpCrane?1:0;
  this.titulo = `${this.$t('label.edit')+' '+this.$t('label.craneType')}: ${newTpGrua.TpCraneName}`;

  this.image = newTpGrua.TpCraneRoute?`${this.$store.getters["connection/getBase"]}${newTpGrua.TpCraneRoute.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder();
}
function resetForm() {
  this.tipoGrua.TpCraneId = '';
  this.tipoGrua.TpCraneName = '';
  this.tipoGrua.MaxHeigth = '';
  this.tipoGrua.MaxWeigth= '';
  this.tipoGrua.TpCraneRoute = '';
  this.image = '';
  this.$v.$reset();
}
function handleFileUpload(img) {
  this.tipoGrua.TpCraneRoute = this.$refs.imageInput.file;
  this.image = '';
}

//Computeds:

function getTitulo() {
  return this.titulo;
}
function statusSelectColor(){
  if(this.tipoGrua.Status){
    return this.tipoGrua.Status === 1;
  } else {
    return false;
  }
}

export default {
  name: 'edit-tipo-grua-modal',
  mixins: [
    TipoGruaMixin,
    ModalMixin,
  ],
  props: {
    modal: Boolean,
    tipoGruaData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    PictureInput
  },
  data,
  validations: TipoGruaValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    setTpGruaData,
    submitFile
  },
  computed: {
    getTitulo,
    statusSelectColor
  },
  watch: {
    tipoGruaData: function(newTpGrua) {
      this.setTpGruaData(newTpGrua);
    }
  }
}
</script>

<style scoped>
.preview {
  height: 15em;
  color: white;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
      1px 1px 0 #000;
}
.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
    padding-left: 0.8rem !important;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>