<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal()"
      :closeOnBackdrop="false"
      size="lg"
    >
        <CRow>
          <CCol>
            <CRow class="my-2" v-if="!isEdit">
              <CCol sm="12" lg="5" class="mt-1 mx-1">
                <CInput
                  v-uppercase
                  :horizontal="{ label:'col-sm-12 col-lg-auto', input: 'col-sm-12 col-lg-8'}"
                  size="sm"
                  :placeholder="$t('label.EnterContainerCode')"
                  :label="$t('label.container')"
                  v-model="$v.ContainerCode.$model"
                  :is-valid="hasError($v.ContainerCode)"
                  :invalid-feedback="errorMessage($v.ContainerCode)"
                  addLabelClasses="text-right"
                  :maxlength="11"
                  @blur="$v.ContainerCode.$touch()"
                />
              </CCol>
              <CCol sm="12" lg="2" class="d-flex center align-items-start justify-content-start px-2 pt-1">
                <CButton
                  size="sm"
                  color="watch"
                  v-c-tooltip="{ content: $t('label.search'), placement: 'top-end'}"
                  class="mx-2"
                  style="padding: 0.15rem 0.4rem;"
                  @click="getVisitCargoContainer()"
                >
                <CIcon name="cil-search"/>
                </CButton>
                <CButton
                  color="wipe"
                  square
                  size="sm"
                  v-c-tooltip="{ content: $t('label.clearFilters'),placement: 'top-end'}"
                  style="padding: 0.15rem 0.4rem;"
                  @click="cleanDataContainer()"
                >
                  <CIcon name="cil-brush-alt"/>
                </CButton>
              </CCol>
            </CRow>
            <div>
              <!-- Container general data-->
              <CTabs variant="tabs" :active-tab="0" v-if="ValidateContainerData">
              <CTab :title="$t('label.generalData')">
                <CCard class="card-border">
                  <CRow sm="12" class="justify-content-center m-0 mt-3 px-2">
                    <!-- Firts Column -->
                    <CCol sm="12" lg="6" >
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.bl')"
                        :placeholder="$t('label.bl')"
                        v-model.trim="$v.container.BlNro.$model"
                        :value.sync="container.BlNro"
                        :is-valid="hasError($v.container.BlNro)"
                        :invalid-feedback="errorMessage($v.container.BlNro)"
                        size="sm"
                        @blur="$v.container.BlNro.$touch()"
                        disabled
                      />
                       <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.equipmentType')"
                        :placeholder="$t('label.equipmentType')"
                        v-model.trim="$v.container.TpCargoSize.$model"
                        :value.sync="container.TpCargoSize"
                        :is-valid="hasError($v.container.TpCargoSize)"
                        :invalid-feedback="errorMessage($v.container.TpCargoSize)"
                        size="sm"
                        @blur="$v.container.TpCargoSize.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.newShippingLine')"
                        :placeholder="$t('label.newShippingLine')"
                        v-model.trim="$v.container.ShippingLineName.$model"
                        :value.sync="container.ShippingLineName"
                        :is-valid="hasError($v.container.ShippingLineName)"
                        :invalid-feedback="errorMessage($v.container.ShippingLineName)"
                        size="sm"
                        @blur="$v.container.ShippingLineName.$touch()"
                        disabled
                      />
                      <div class="form-group form-row">
                        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                        <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                          <money
                            size="sm"
                            v-bind="measure" 
                            :class="!$v.container.Weigth.$dirty ? 'form-control' : ($v.container.Weigth.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                            v-model="container.Weigth"
                            addLabelClasses="required text-right"
                            maxlength= "13"
                            disabled
                          >
                          </money>
                          <div class="invalid-feedback" v-if="$v.container.Weigth.$error">
                            {{ errorMessage($v.container.Weigth) }}
                          </div>
                        </div>
                      </div>
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-11 col-lg-7'}"
                        label="POD"
                        placeholder="POD"
                        v-model.trim="$v.container.DischargePort.$model"
                        :value.sync="container.DischargePort"
                        :is-valid="hasError($v.container.DischargePort)"
                        :invalid-feedback="errorMessage($v.container.DischargePort)"
                        size="sm"
                        @blur="$v.container.DischargePort.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                        :label="$t('label.consignee')"
                        :placeholder="$t('label.consignee')"
                        v-model.trim="$v.container.Consignee.$model"
                        :value.sync="container.Consignee"
                        :is-valid="hasError($v.container.Consignee)"
                        :invalid-feedback="errorMessage($v.container.Consignee)"
                        size="sm"
                        @blur="$v.container.Consignee.$touch()"
                        disabled
                      />
                    </CCol>
                    <!-- Secund Column -->
                    <CCol sm="12" lg="6" >
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.isoCode')"
                        :placeholder="$t('label.isoCode')"
                        v-model.trim="$v.container.IsoCode.$model"
                        :value.sync="container.IsoCode"
                        :is-valid="hasError($v.container.IsoCode)"
                        :invalid-feedback="errorMessage($v.container.IsoCode)"
                        size="sm"
                        @blur="$v.container.IsoCode.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.status')"
                        :placeholder="$t('label.status')"
                        v-model.trim="$v.container.TbTpCargoStatus.$model"
                        :value.sync="container.TbTpCargoStatus"
                        :is-valid="hasError($v.container.TbTpCargoStatus)"
                        :invalid-feedback="errorMessage($v.container.TbTpCargoStatus)"
                        size="sm"
                        @blur="$v.container.TbTpCargoStatus.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        :label="$t('label.stowageMovement')"
                        :placeholder="$t('label.stowageMovement')"
                        v-model.trim="$v.container.MovStowageName.$model"
                        :value.sync="container.MovStowageName"
                        :is-valid="hasError($v.container.MovStowageName)"
                        :invalid-feedback="errorMessage($v.container.MovStowageName)"
                        size="sm"
                        @blur="$v.container.MovStowageName.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                        :label="$t('label.location')"
                        :placeholder="$t('label.location')"
                        v-model.trim="$v.container.CargoStatus.$model"
                        :value.sync="container.CargoStatus"
                        :is-valid="hasError($v.container.CargoStatus)"
                        :invalid-feedback="errorMessage($v.container.CargoStatus)"
                        size="sm"
                        @blur="$v.container.CargoStatus.$touch()"
                        disabled
                      />
                      <CInput
                        :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        label='POL'
                        placeholder='POL'
                        v-model.trim="$v.container.LoadPort.$model"
                        :value.sync="container.LoadPort"
                        :is-valid="hasError($v.container.LoadPort)"
                        :invalid-feedback="errorMessage($v.container.LoadPort)"
                        size="sm"
                        @blur="$v.container.LoadPort.$touch()"
                        disabled
                      />
                    </CCol>
                  </CRow>
                </CCard>
              </CTab>
            </CTabs>
             <!-- Container confirmation-->
            <CTabs variant="tabs" :active-tab="0" class="mt-2">
              <CTab :title="$t('label.dischargeConfirmation')">
                <CCard class="card-border">
                  <CRow sm="12" class="m-0 mt-3">
                      <CCol sm="12" lg="6">
                        <div class="form-group form-row" rol="group">
                          <label class="col-form-label-sm text-right required col-sm-12 col-lg-5 mb-0 requiered">
                            {{$t('label.date')}}
                          </label>
                          <div class="col-sm-12 col-lg-7 input-group">
                            <vue-datepicker
                              type="datetime"
                              header
                              :lang="this.$i18n.locale"
                              :editable="false"
                              :clearable="false"
                              format="DD/MM/YYYY HH:mm"
                              placeholder="DD/MM/YYYY HH:mm"
                              time-title-format="DD/MM/YYYY HH:mm"
                              v-model="container.TransactionDate"
                              :disabled-date="validateDateRange"
                              :append-to-body="false"
                              value-type="format"
                              :show-second="false"
                              @input="ValidateDate()"
                            >
                                <template #input>
                                  <CInput
                                    class="w-100 float-left mb-0"
                                    :value="container.TransactionDate"
                                    placeholder="DD/MM/YYYY HH:mm"
                                    :is-valid="hasError($v.container.TransactionDate)"
                                    @blur="$v.container.TransactionDate.$touch()"
                                    size="sm"
                                    @input="ValidateDate()"
                                    :disabled="ValidateContainerData ? false : true"
                                  >
                                    <template #append-content>
                                      <CIcon name="cil-calendar" />
                                    </template>
                                  </CInput>
                                </template>
                                <template #icon-calendar>
                                  <div style="display: none"></div>
                                </template>
                              </vue-datepicker>
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.movementType')"
                          :options="movementStowageOptions"
                          addLabelClasses="required text-right"
                          v-model.trim="$v.container.MovStowageId.$model"
                          :value.sync="container.MovStowageId"
                          :is-valid="hasError($v.container.MovStowageId)"
                          :invalid-feedback="errorMessage($v.container.MovStowageId)"
                          size="sm"
                          @blur="$v.container.MovStowageId.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="MovStowageRestowId">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.restowType')"
                          :options="restowOptions"
                          addLabelClasses="required text-right"
                          v-model.trim="$v.container.TransacStowageId.$model"
                          :value.sync="container.TransacStowageId"
                          :is-valid="hasError($v.container.TransacStowageId)"
                          :invalid-feedback="errorMessage($v.container.TransacStowageId)"
                          size="sm"
                          @blur="$v.container.TransacStowageId.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                          @change="filterRestow($event)"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="MovStowageRestowId">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.AUTHORIZED_BY')"
                          :options="MovStowageReasonOptions"
                          addLabelClasses="required text-right"
                          v-model.trim="$v.container.MovStowageReasonId.$model"
                          :value.sync="container.MovStowageReasonId"
                          :is-valid="hasError($v.container.MovStowageReasonId)"
                          :invalid-feedback="errorMessage($v.container.MovStowageReasonId)"
                          size="sm"
                          @blur="$v.container.MovStowageReasonId.$touch()"
                          :disabled="ValidateContainerData || !container.MovStowageId ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.localizacion')"
                          :options="ubicationOptions"
                          addLabelClasses="required text-right"
                          v-model.trim="$v.container.VesselUbicationId.$model"
                          :value.sync="container.VesselUbicationId"
                          :is-valid="hasError($v.container.VesselUbicationId)"
                          :invalid-feedback="errorMessage($v.container.VesselUbicationId)"
                          size="sm"
                          @blur="$v.container.VesselUbicationId.$touch()"
                          @change="filterVesselUbication($event)"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.crane')"
                          addLabelClasses="required text-right"
                          :options="craneOptions"
                          v-model.trim="$v.container.VisitCraneId.$model"
                          :value.sync="container.VisitCraneId"
                          :is-valid="hasError($v.container.VisitCraneId)"
                          :invalid-feedback="errorMessage($v.container.VisitCraneId)"
                          size="sm"
                          @blur="$v.container.VisitCraneId.$touch()"
                          @change="filterCrane($event)"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="ValidateHold || ValidateHoldDeck">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.hold')"
                          addLabelClasses="required text-right"
                          :options="holdOptions"
                          v-model.trim="$v.container.VesselHoldId.$model"
                          :value.sync="container.VesselHoldId"
                          :is-valid="hasError($v.container.VesselHoldId)"
                          :invalid-feedback="errorMessage($v.container.VesselHoldId)"
                          size="sm"
                          @blur="$v.container.VesselHoldId.$touch()"
                          @change="filterVesselHatch($event)"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="ValidateHoldDeck">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.hatchCover')"
                          addLabelClasses="required text-right"
                          :options="vesselHatchOptions"
                          v-model.trim="$v.container.VesselHoldHatchCId.$model"
                          :value.sync="container.VesselHoldHatchCId"
                          :is-valid="hasError($v.container.VesselHoldHatchCId)"
                          :invalid-feedback="errorMessage($v.container.VesselHoldHatchCId)"
                          size="sm"
                          @blur="$v.container.VesselHoldHatchCId.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="!MovStowageRestowId">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.DirectDischarge')"
                          addLabelClasses="required text-right"
                          :options="directDischargeOptions"
                          v-model.trim="$v.container.FgDirect.$model"
                          :value.sync="container.FgDirect"
                          :is-valid="hasError($v.container.FgDirect)"
                          :invalid-feedback="errorMessage($v.container.FgDirect)"
                          size="sm"
                          @blur="$v.container.FgDirect .$touch()"
                          :disabled="ValidateContainerData ? false : true"
                          @change="filterYard($event)"
                        />
                      </CCol>
                      <CCol sm="12" lg="6" v-if="!MovStowageRestowId">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.yard')"
                          :class="!VerifyDirect  ? 'condition-label text-right' : 'text-right' "
                          :options="yardOptions"
                          v-model.trim="$v.container.YardId.$model"
                          :value.sync="container.YardId"
                          :is-valid="hasError($v.container.YardId)"
                          :invalid-feedback="errorMessage($v.container.YardId)"
                          size="sm"
                          @blur="$v.container.YardId.$touch()"
                          :disabled="ValidateContainerData ? false : (container.FgDirect ? true : false)"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CTextarea
                          :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.observation')"
                          addLabelClasses="text-right"
                          v-uppercase
                          v-model.trim="$v.container.Observation.$model"
                          :value.sync="container.Observation"
                          :is-valid="hasError($v.container.Observation)"
                          :invalid-feedback="errorMessage($v.container.Observation)"
                          size="sm"
                          maxlength="150"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="12">
                        <CRow class="justify-content-center mt-3" >
                          <CCol sm="12" lg="auto">
                            <b>
                              <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.Seals')}}</label>
                            </b>
                          </CCol>
                          <CCol sm="12" lg="8"></CCol>
                      </CRow>
                      </CCol>
                       
                      <CCol sm="12" lg="6">
                        <CInput
                          :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                          :label="$t('label.Seal')+' 1'"
                          :placeholder="$t('label.Seal')+' 1'"
                          v-model.trim="$v.container.Seal1.$model"
                          :value.sync="container.Seal1"
                          :is-valid="hasError($v.container.Seal1)"
                          size="sm"
                          @blur="$v.container.Seal1.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CInput
                          :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                          :label="$t('label.Seal')+' 2'"
                          :placeholder="$t('label.Seal')+' 2'"
                          v-model.trim="$v.container.Seal2.$model"
                          :value.sync="container.Seal2"
                          :is-valid="hasError($v.container.Seal2)"
                          size="sm"
                          @blur="$v.container.Seal2.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CInput
                          :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                          :label="$t('label.Seal')+' 3'"
                          :placeholder="$t('label.Seal')+' 3'"
                          v-model.trim="$v.container.Seal3.$model"
                          :value.sync="container.Seal3"
                          :is-valid="hasError($v.container.Seal3)"
                          size="sm"
                          @blur="$v.container.Seal3.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <CInput
                          :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"                        
                          :label="$t('label.Seal')+' 4'"
                          :placeholder="$t('label.Seal')+' 4'"
                          v-model.trim="$v.container.Seal4.$model"
                          :value.sync="container.Seal4"
                          :is-valid="hasError($v.container.Seal4)"
                          size="sm"
                          @blur="$v.container.Seal4.$touch()"
                          :disabled="ValidateContainerData ? false : true"
                        />
                      </CCol>
                      <CCol sm="12" class="text-invalid-feedback text-center"
                        v-if="$v.container.Seal1.$error &&  !ValidateStatusEmpty && !container.Seal1 && !container.Seal2 && !container.Seal3 && !container.Seal4"
                      >
                        <label>{{ $t('label.AtLeastSealRequired') }}</label>
                      </CCol>
                  </CRow>
                </CCard>
              </CTab>
            </CTabs>

            </div>
          </CCol>
        </CRow>

      <template #footer>
        <CButton outline color="add" :disabled="isSubmit"
          @click.stop="submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal"  :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import containerStowageValidation from '@/_validations/visitas/containers/containerStowageValidations';
import { Money } from "v-money";

function data() {
  return {
    container: {
      ShippingLineName: '',
      Weigth: '',
      DischargePort: '',
      MovStowageName: '',
      Consignee: '',
      IsoCode: '',
      TpCargoSize: '',
      TbTpCargoStatus: '',
      LoadPort: '',
      CargoStatus: '',
      BlNro: '',
      TransactionDate: '',
      VisitCraneId: '',
      Seal1: '',
      Seal2: '',
      Seal3: '',
      Seal4: '',
      VesselHoldId: '',
      VesselHoldHatchCId: '',
      MovStowageId: '',
      TransacStowageId: '',
      MovStowageReasonId: '',
      VesselUbicationId: '',
      FgDirect: 0,
      YardId: '',
      Observation: '',
    },
 
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },

    PackagingId: '',
    Quantity:'',
    ContainerCode: '',
    previousDate: new Date(),
    laterDate: new Date(),

    ValidPreviousDate: '',
    ValidLaterDate: '',

    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    craneList: [],
    holdList: [],
    restowList: [],
    movStowageReasonList: [],
    VesselHatchList: [],
    yardList: [],
    packagingList: [],
    ubicationList: [],
    containerMovStowageList: [],
    ContainerData: {},
    VisitCargoId: '',
    ContainerList: [],
    VisitId: '',
    FgRadio: 0,
  };
}

  function closeModal() {
    this.refreshComponent();
    this.$emit('closeModal');
  }

  function refreshComponent() {
    //Container
    this.container.ShippingLineName = '';
    this.container.Weigth = '';
    this.container.DischargePort = '';
    this.container.MovStowageName = '';
    this.container.Consignee = '';
    this.container.IsoCode = '';
    this.container.TpCargoSize = '';
    this.container.TbTpCargoStatus = '';
    this.container.LoadPort = '';
    this.container.CargoStatus = '';
    this.container.BlNro = '';
    this.container.TransactionDate = '';
    this.container.VesselUbicationId = '';
    this.container.VisitCraneId = '';
    this.container.VesselHoldId = '';
    this.container.VesselHoldHatchCId = '';
    this.container.Seal1 = '';
    this.container.Seal2 = '';
    this.container.Seal3 = '';
    this.container.Seal4 = '';
    this.container.MovStowageId = '';
    this.container.TransacStowageId = '';
    this.container.MovStowageReasonId = '';
    this.container.FgDirect = 0;
    this.container.YardId = '';
    this.container.Observation = '';

    //Arrys
    this.craneList = [];
    this.holdList = [];
    this.restowList = [];
    this.movStowageReasonList = [];
    this.VesselHatchList = [];
    this.yardList = [];
    this.containerMovStowageList = [];
    this.ContainerData = {};
    this.VisitCargoId = '';
    this.ContainerCode = '';

    this.$v.$reset();
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
    
      this.$v.$touch();
     
      if (this.$v.container.$error )
      {
        this.loadingOverlay = false;
        this.isSubmit = false;

        if(DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined){
          throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if (DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.container.TransactionDate != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        
        throw this.$t('label.errorsPleaseCheck');
      }


        let CargoJson = {
          VisitCargoId: this.ContainerData.VisitCargoId,
          StowagePlanningCargoId: this.ContainerData.StowagePlanningCargoId,
          VesselUbicationId: this.container.VesselUbicationId,
          VesselHoldId: this.container.VesselHoldId,
          VesselHoldHatchCId: this.container.VesselHoldHatchCId,
          VisitId: this.Visit,
          VisitCraneId: this.container.VisitCraneId,
          MovStowageId: this.container.MovStowageId,
          ConfirmationDate: DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate),
          Seal1: this.container.Seal1,
          Seal2: this.container.Seal2,
          Seal3: this.container.Seal3,
          Seal4: this.container.Seal4,
          FgDirect: this.container.FgDirect,
          YardId: this.container.YardId,
          Observation: this.container.Observation
        }

        let CargoJsonRestow = {
          VisitCargoId: this.ContainerData.VisitCargoId,
          StowagePlanningCargoId: this.ContainerData.StowagePlanningCargoId,
          VesselUbicationId: this.container.VesselUbicationId,
          VesselHoldId: this.container.VesselHoldId,
          VesselHoldHatchCId: this.container.VesselHoldHatchCId,
          VisitId: this.Visit,
          VisitCraneId: this.container.VisitCraneId,
          MovStowageId: this.container.MovStowageId,
          MovStowageReasonId: this.container.MovStowageReasonId,
          ConfirmationDate: DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate),
          Seal1: this.container.Seal1,
          Seal2: this.container.Seal2,
          Seal3: this.container.Seal3,
          Seal4: this.container.Seal4,
          MovStowageReasonDs: this.container.Observation
        }
      
      let ruta =(this.MovStowageLoadId ? 'VisitGeneralCargoContainerLoadConfirmation' :  this.MovStowageDischargeId ? 'VisitGeneralCargoContainerDischargeConfirmation' : 'VisitGeneralCargoContainerRestowConfirmation' ) 
      let method = this.isEdit ? 'PUT':'POST';
      let json = this.MovStowageRestowId ? CargoJsonRestow : CargoJson;


      await this.$http
        .ejecutar (method, ruta, json, { root: 'CargoJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.$emit('child-refresh');
            this.closeModal();
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
            this.loadingOverlay = false;
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  async function getDateRange(){
    let currentDate = new Date();
    if(this.itinerarySelected.Ata!=undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    }

    if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
    }

    if(this.itinerarySelected.Atd!=undefined){
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
    }

    if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
      this.ValidLaterDate = DateFormater.formatTimeZoneToDateTimewithDash(this.laterDate);
    }
  }

  async function getCraneList() {
    this.loadingOverlay = true;
      return this.$http
        .get('VisitVesselCrane-list?VisitId='+this.Visit)
           .then(async response => {
            this.craneList = response.data.data ?? [];
            await this.getHoldList();
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }


  async function filterCrane(event) { 
    this.container.VisitCraneId = event.target.value;
    this.container.VesselHoldId = '';
    this.holdList = [];
    this.container.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
   
    await this.getHoldList();
  }

  async function getHoldList() { 
    if (this.container.VisitCraneId != '') {
      this.loadingOverlay = true;
      await this.$http.get( 'VisitVesselCraneHold-list', { VisitId: this.Visit, VisitCraneId: this.container.VisitCraneId })
      .then(response => {
        this.holdList = response.data.data ?? [];
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).finally(() => {
        this.loadingOverlay = false;
      });
    }
    else{
      this.container.VesselHoldId = '';
      this.holdList = [];
      this.container.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }  
  }

  function filterVesselHatch(event) {
    this.container.VesselHoldId = event.target.value;
    this.container.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    this.getVesselHatch(this.container.VesselHoldId);
  }

  function getVesselHatch(Id) {
    if(this.container.VesselHoldId){
      let data = this.holdList.find((val) => val.VesselHoldId == Id);
      this.VesselHatchList = data?.VesselHatchJson ?? [];
    }else{
      this.container.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }
  }

  async function getRestowList() { 
    this.loadingOverlay = true;
    await this.$http.get( 'VisitRestowTransacStowage-list')
    .then(response => {
      this.restowList = response.data.data ?? [];
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.loadingOverlay = false;
    });
  }

  function filterRestow(event) {
    this.container.TransacStowageId = event.target.value;
    this.container.MovStowageReasonId = '';
    this.movStowageReasonList = [];
    this.getMovStowageReasonList(this.container.TransacStowageId);
  }

  async function getMovStowageReasonList(Id) { 
    if (this.container.TransacStowageId != '') {
      this.loadingOverlay = true;
      await this.$http.get( 'MovStowageReason-by-transac-list',{TransacStowageId: Id})
      .then(response => {
        this.movStowageReasonList = response.data.data ?? [];
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).finally(() => {
        this.loadingOverlay = false;
      });
    }else{

    }
  }
  
  function getYards() {
    this.$http
      .get("YardContainer-list", {
        CompanyBranchId: this.branch ? this.branch.CompanyBranchId : '',
      })
      .then((response) => {
        this.yardList = response.data.data ?? [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }

  async function getMovementStowageList() {
      await this.$http
      .get(`VisitMovStowageGeneralCargo-list?VisitId=${this.Visit}&VisitCargoId=${this.VisitCargoId}`)
      .then(response => {
        let listado = response.data.data && response.data.data[0] ? response.data.data[0] : {};
        if (Object.keys(listado).length != 0){
          this.containerMovStowageList = listado?.ContainerMovStowageJsonList ?? [];
        }
      }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function getUbicationList() {
    return this.$http
      .get('VesselUbication-list')
        .then(response => {
          let listado = response.data.data;
          if (listado && listado.length != 0){
            this.ubicationList = listado;
          }
        })
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function filterVesselUbication(event) {
    this.container.VesselUbicationId = event.target.value;
    this.container.VisitCraneId = '';
    this.craneList = [];
    this.holdList = [];
    this.container.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    await this.getCraneList();
  }

  async function getApis(){
    await this.getCraneList();
    await this.getMovementStowageList();
    await this.getUbicationList();
    await this.getYards();
  }

  async function getVisitCargoContainer() {
    this.loadingOverlay = true;
      return this.$http
        .get('VisitCargoContainer-search',{VisitId: this.Visit, ContainerCode: this.ContainerCode})
          .then(async response => {
            let  Data = response.data.data && response.data.data[0] ? response.data.data[0] : {};
            if (this.ContainerCode&&Object.keys(Data).length == 0) {
               await this.cleanDataContainer();
               throw this.$t('label.containerNotFount');
            }
            if (this.ContainerCode&&Data.FgConfirmed) {
              await this.cleanDataContainer();
              throw this.$notify({
                  group: 'container',
                  title: this.$t('label.equipmentConfirmed'),
                  text: response.data.data[0].Response,
                  type: "warn"
              });
            }
            
            this.ContainerData = Data;
            let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            if (this.ContainerCode) {
               this.container.ShippingLineName = Data?.ShippingLineName ?? '';
               this.container.Weigth = Data?.Weigth ?? '';
               this.container.DischargePort = Data?.DischargePort ?? '';
               this.container.MovStowageName = Data?.[`MovStowageName${_lang}`] ?? '';
               this.container.MovStowageId = Data?.MovStowageId ?? '';
               this.container.Consignee = Data?.Consignee ?? '';
               this.container.IsoCode= Data?.IsoCode ?? '';
               this.container.TpCargoSize = Data?.TpCargoSize ?? '';
               this.container.TbTpCargoStatus = Data?.TbTpCargoStatus ?? '';
               this.container.LoadPort = Data?.LoadPort ?? '';
               this.container.CargoStatus = Data?.[`CargoStatus${_lang}`] ?? '';
               this.container.BlNro = Data?.BlNro ?? '';
               this.container.Seal1 = Data?.Seal1 ?? '';
               this.container.Seal2 = Data?.Seal2 ?? '';
               this.container.Seal3 = Data?.Seal3 ?? '';
               this.container.Seal4 = Data?.Seal4 ?? '';
               this.VisitCargoId = Data?.VisitCargoId ?? '';

               this.$v.container.ShippingLineName.$touch();
               this.$v.container.Weigth.$touch();
               this.$v.container.DischargePort.$touch();
               this.$v.container.MovStowageName.$touch();
               this.$v.container.MovStowageId.$touch();
               this.$v.container.Consignee.$touch();
               this.$v.container.IsoCode.$touch();
               this.$v.container.TpCargoSize.$touch();
               this.$v.container.TbTpCargoStatus.$touch();
               this.$v.container.LoadPort.$touch();
               this.$v.container.CargoStatus.$touch();
               this.$v.container.BlNro.$touch();
               this.$v.container.Seal1.$touch();
               this.$v.container.Seal2.$touch();
               this.$v.container.Seal3.$touch();
               this.$v.container.Seal4.$touch();
               await this.getMovementStowageList();
               if(this.MovStowageRestowId){
                await this.getRestowList();
               }
            }else{
              await this.cleanDataContainer();
            }
          })    
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }

  function cleanDataContainer(){
    this.ContainerCode = '';
    this.container.ShippingLineName = '';
    this.container.Weigth = '';
    this.container.DischargePort = '';
    this.container.MovStowageName =  '';
    this.container.MovStowageId = '';
    this.container.TransacStowageId = '';
    this.container.MovStowageReasonId = '';
    this.container.Consignee = '';
    this.container.IsoCode = '';
    this.container.TpCargoSize = '';
    this.container.TbTpCargoStatus = '';
    this.container.LoadPort = '';
    this.container.CargoStatus = '';
    this.container.BlNro = '';
    this.container.TransactionDate = '';
    this.container.VesselHoldId = '';
    this.container.VesselUbicationId = '';
    this.container.YardId = '';
    this.container.FgDirect = '';
    this.container.Seal1 = '';
    this.container.Seal2 = '';
    this.container.Seal3 = '';
    this.container.Seal4 = '';
    this.container.Observation = '';
    this.MovStowageId = '';
    this.Observation = '';
    this.VisitCargoId = '';
    this.ContainerData = {};
    this.holdList = [];
    this.VesselHatchList


    this.$v.$reset();
  }

  async function ValidateDate(){
    try { 
      if (DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
          throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if(this.container.TransactionDate != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.container.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
    }catch (error) {
      this.notifyError({text: error });
    }
  }

  function filterYard(event) {
    this.container.FgDirect = event.target.value;
  }

  //computed
  function craneOptions(){
    const chart = [
        {
            value: "",
            label: this.$t('label.select'),
        },
    ];
    this.craneList.map((item) => {
        chart.push({
            label: item.CraneAlias,
            value: item.VisitCraneId,
        });
    });
    return chart;
  }

  function holdOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.holdList.map((e) =>{
      chart.push({
        value: e.VesselHoldId,
        label: e.VesselHoldName,
      })
    })
    return chart;
  }

  function restowOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.restowList.map((e) =>{
      chart.push({
        value: e.TransacStowageId,
        label: e[`TransacStowageName${_lang}`]
      })
    })
    return chart;
  }

  function MovStowageReasonOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.movStowageReasonList.map((e) =>{
      chart.push({
        value: e.MovStowageReasonId,
        label: e[`MovStowageReasonName${_lang}`]
      })
    })
    return chart;
  }

  function vesselHatchOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.VesselHatchList.map((e) =>{
      chart.push({
        value: e.VesselHoldHatchCId,
        label: e.HatchCoverName,
      })
    })
    return chart;
  
  }

  function directDischargeOptions(){
    return [
        { 
            value: '', 
            label: this.$t('label.select')
        },
        { 
            value: true, 
            label: this.$t('label.yes')
        },
        { 
            value: false, 
            label: 'NO'
        }
    ];
  }

  function ubicationOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.ubicationList.map((e) => {
      chart.push({
        value: e.VesselUbicationId,
        label: e[`VesselUbicationName${_lang}`]
      });
    })
    return chart;
  }

  function movementStowageOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    const chart = [
        {
          value: "",
          label: this.$t('label.select'),
        },
    ];
      this.containerMovStowageList.map((item) => {
        chart.push({
            label: item[`MovStowageName${_lang}`],
            value: item.MovStowageId,
        });
      });
  
    return chart;
  }

  function yardOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.yardList.map((e) => {
      chart.push({
        value: e.YardId,
        label: e.YardName
      });
    })
    return chart;
  }

  function titleModal() {
    return  this.isEdit ? this.$t('label.edit')+' '+this.$t('label.dischargeConfirmation') :
            this.$t('label.nueva')+' '+this.$t('label.dischargeConfirmation');
  }

  function MovStowageLoadId() {
    if (typeof  this.ContainerData.MovStowageId == 'string') {
      return this.ContainerData.MovStowageId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_HOLD;
    }else{
      return false;
    }
  }

  function MovStowageDischargeId() {
    if (typeof  this.ContainerData.MovStowageId == 'string') {
      return this.ContainerData.MovStowageId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_MOVSTOWAGE_ID_DISCHARGE;
    }else{
      return false;
    }
  }

  function MovStowageRestowId() {
    if (typeof  this.ContainerData.MovStowageId == 'string') {
      return this.ContainerData.MovStowageId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_MOVSTOWAGE_ID_RESTOW;
    }else{
      return false;
    }
  }

  function ValidateStatusEmpty() {
    if (typeof this.ContainerData?.TpCargoStatusId == 'string') {
      return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.ContainerData?.TpCargoStatusId;
    }else{
      return false;
    }
  }

  function ValidateContainerData() {
    if (Object.keys(this.ContainerData).length == 0) {
      return false;
    }else{
      return true;
    }
  }
  

  function VerifySeal() {
    if (this.ValidateStatusEmpty) {
      return false;
    } else if (!this.container.Seal1 && !this.container.Seal2 && !this.container.Seal3 && !this.container.Seal4) {
        return true;
    } else {
      return false;
    }
  }

  function VerifyDirect() {
    if(this.container.FgDirect) {
      return true;
    } else {
      return false;
    }
  }

  function VerifyMovStowageId() {
    if(this.container.MovStowageId) {
      return false;
    } else {
      return true;
    }
  }

  function VerifyMovStowageIdRestow() {
    if (typeof  this.container.MovStowageId == 'string') {
      if (this.container.MovStowageId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_MOVSTOWAGE_ID_RESTOW) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  function ValidateHoldDeck() {
    if (typeof this.container.VesselUbicationId == 'string') {
      return ((this.container.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VESSEL_UBICATION_ID_DECK_HOLD));
    }else{
      return false;
    }
  }

  function ValidateHold() {
    if (typeof this.container.VesselUbicationId == 'string') {
      return ((this.container.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_HOLD));
    }else{
      return false;
    }
  }

  function ValidateDeck() {
    if (typeof this.container.VesselUbicationId == 'string') {
      return ((this.container.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_DECK));
    }else{
      return false;
    }
  }

export default {
  name: 'modal-stowage',
  data,
  props: { modal: Boolean, stowageItem: Object, isEdit: Boolean },
  components: { Money },
  mixins: [ModalMixin, mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){
    return containerStowageValidation(this.ValidLaterDate, this.ValidPreviousDate, this.VerifySeal, 
          this.VerifyDirect,this.ValidateHoldDeck,this.ValidateHold,this.VerifyMovStowageIdRestow, this.MovStowageRestowId);
  },

  watch: {
    modal: async function (val) {
      this.showModal = val;
      if(val){
        await this.getDateRange();
        await this.getApis(); 
      }
    },
  },
  methods: {
    closeModal,
    getCraneList,
    filterCrane,
    filterVesselHatch,
    filterVesselUbication,
    getVesselHatch,
    getHoldList,
    getYards,
    getRestowList,
    filterRestow,
    getMovStowageReasonList,
    getMovementStowageList,
    getUbicationList,
    getApis,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    getVisitCargoContainer,
    cleanDataContainer,
    ValidateDate,
    filterYard
  },
  computed: {
    craneOptions,
    holdOptions,
    restowOptions,
    MovStowageReasonOptions,
    vesselHatchOptions,
    directDischargeOptions,
    ubicationOptions,
    movementStowageOptions,
    yardOptions,
    titleModal,
    MovStowageLoadId,
    MovStowageDischargeId,
    MovStowageRestowId,
    ValidateStatusEmpty,
    ValidateContainerData,
    VerifySeal,
    VerifyDirect,
    VerifyMovStowageId,
    VerifyMovStowageIdRestow,
    ValidateHoldDeck,
    ValidateHold,
    ValidateDeck,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      branch: state => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss">
.status-open {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #42aa91 !important;
  }

}
.status-close {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #FF8000 !important;
  }
  .form-control:focus {
    border-color: #FF8000 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
    box-shadow: 0 0 0 0.1rem #FF8000 !important;
  }
}
.status-inactive {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #e1373f !important;
  }
  .form-control:focus {
    border-color: #e1373f !important;
    -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
    box-shadow: 0 0 0 0.1rem #e1373f !important;
  }
}
.status-EndOperation {
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #1A3760 !important;
  }
  .form-control:focus {
    border-color: #1A3760 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
    box-shadow: 0 0 0 0.1rem #1A3760 !important;
  }
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}
</style>
