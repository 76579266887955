import {
    required,
  } from 'vuelidate/lib/validators';
  
  
  export default () => {
    return {
      nroBl: { required },
      yard: { required },
    }
  }