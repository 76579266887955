var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex===0),expression:"showIndex===0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"6"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.RecognitionList')}`)+" ")],1)]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6"}},[(_vm.fgCertificate && _vm.YardId)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.recognition'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.recognition'),\n            placement: 'top-end'\n          }"}],attrs:{"color":"add"},on:{"click":function($event){_vm.showIndex = 1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1):_vm._e()],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Quantity",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Quantity, 2))+" ")])]}},{key:"Volumen",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Volumen, 2))+" ")])]}},{key:"TransactionBegin",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateTimeWithSlash(item?.TransactionBegin))+" ")])]}},{key:"TransactionFinish",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateTimeWithSlash(item?.TransactionFinish))+" ")])]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: `${_vm.$t('label.edit')} ${_vm.$t('label.recognition')}`,
                placement: 'top-start'
              }),expression:"{\n                content: `${$t('label.edit')} ${$t('label.recognition')}`,\n                placement: 'top-start'\n              }"}],attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.editRecognition(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex===1),expression:"showIndex===1"}]},[_c('RecognitionRegister',{attrs:{"Active":_vm.showIndex===1,"isEdit":_vm.isEdit,"RecognitionItem":_vm.RecognitionItem},on:{"close":function($event){_vm.showIndex=0, _vm.RecognitionItem = {}, _vm.isEdit=false},"Update-list":_vm.YardWCargoCustomInspection}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }