<template>
    <div class="m-2">
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
            <symbol id="exclamation-square-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </symbol>
            <symbol id="check-square-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
            </symbol>
        </svg>
        <modaBugList 
            :items="items"
        >
        </modaBugList>
        <CRow style="margin-bottom:10px;">
            <CCol sm="7">
                <div class="d-flex align-items-center">
                    <h5 class="mt-2">{{$t('label.auxiliary_file')}}:&nbsp;<CIcon style="width: 1rem;height: 1rem; margin-top: -0.2rem !important;" name="cil-exclamation-circle"/>&nbsp;{{ nombreTabla }}</h5>
                </div>
            </CCol>
            <CCol sm="5" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
                <CButton 
                    @click="desactivar()" 
                    color="wipe"
                    class="ml-2 "
                    :disabled="apiStateLoading"
                >
                    <CIcon name="returnSolid" />
                    <span class="mx-2">{{$t('button.return')}}</span>
                </CButton>
            </CCol>
        </CRow>
        <br />
        <CRow>
            <CCol sm="12">
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="dateFilteredItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    @row-clicked="itemChanged"
                    pagination
                >
                    <template #ErrorJson="{item}">
                        <td>
                            <div class="animation-item" v-if="item.FgError">
                                <h3 class="text-danger"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg></h3>
                            </div>
                            <div class="animation-item" v-else>
                                <h3 class="text-success"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg></h3>
                            </div>
                        </td>
                    </template>
                    <template #Weigth="{item}">
                        <td class="text-center">
                            {{ item.Weigth ? formatMilDecimal(item.Weigth?.toFixed(2)) : '0,00' }}
                        </td>
                    </template>
                    <template #Vgm="{item}">
                        <td class="text-center">
                            {{ item.Vgm ? formatMilDecimal(item.Vgm?.toFixed(2)) : '0,00' }}
                        </td>
                    </template>
                    <!--template slot="under-table">
                        <div v-if="dateFilteredItems.length !== 0">
                            <h6>{{$t('label.totalQuantity')+': '+dateFilteredItems.length}}</h6>
                        </div>
                    </template-->
                </dataTableExtended>
            </CCol>
            
        </CRow>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import modaBugList from './modal-bug-list';
    import Reportes from '@/_mixins/reportes';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';

    //data
    function data() {
        return {
            computedItems:[],
            usersOpened: null,
            items:{},
            itemsArreglo: [],

            padre: '',
        }
    }
    //methods
    function desactivar(){
        this.$store.commit("planificacionestiba/menuVisibility", {check:false,type:2});
    }
    function itemChanged(item, index, column, event){
        if(item.ErrorJson.length !== 0){
            this.items = item;
            this.$store.state.planificacionestiba.modalBugList = true;
        }
    }
    async function onBtnExport(valor){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        if(this.dateFilteredItems.length !== 0){
            let ExcelAuxiliaryFile = [];
            this.itemsArreglo.forEach(item => {
                let arreglo = {};
                arreglo['#']=item.Nro ? item.Nro : "";
                arreglo[this.$t('label.containerCode')]=item.ContainerCode ? item.ContainerCode : "";
                arreglo[this.$t('label.size')+' (FT)']=item.TpCargoCode ? item.TpCargoCode : 0;
                arreglo[this.$t('label.status')]=item.Status ? item.Status : "";
                arreglo[this.$t('label.bl')]= item.BlNo ? item.BlNo : "",
                arreglo[this.$t('label.isoCode')]=item.IsoCode ? item.IsoCode : "";
                arreglo[this.$t('label.Seal')+'S']=item.Seals ? item.Seals : "";
                arreglo[this.$t('label.carrier')]=item.ShippingLineCode ? item.ShippingLineCode : "";
                arreglo['IMDG']=item.ImdgCode ? item.ImdgCode : "";
                arreglo['POL']=item.LoadPort ? item.LoadPort : "";
                arreglo['POD']=item.DischargePort ? item.DischargePort : "";
                arreglo['PODEL']=item.DeliveryPort ? item.DeliveryPort : "";
                arreglo['POT']=item.TranshipmentPort ? item.TranshipmentPort : "";
                arreglo[this.$t('label.weight')+' (KGM)']=item.Weigth ? item.Weigth : 0;
                arreglo['VGM (KGM)']=item.Vgm ? item.Vgm : 0;
                arreglo[this.$t('label.certificateVGM')]=item.VgmCertificate ? item.VgmCertificate : "";
                if (this.EdiFileInfo?.TpEdiTransacId?.toUpperCase()==`${process.env.VUE_APP_CUSTOM_MANIFEST_ID}`) {
                    arreglo[this.$t('label.yard')]=item.YardName ? item.YardName : '';
                    arreglo[this.$t('label.Procedence')]=item.Procedence ? item.Procedence : '';
                }
                if (this.EdiFileInfo?.TpEdiTransacId?.toUpperCase()==`${process.env.VUE_APP_TP_ARCHIVE_YARD_EMPTY_ID}`) {
                    arreglo[this.$t('label.Procedence')]=item.Procedence ? item.Procedence : '';
                    arreglo[this.$t('label.reception')]=item.FgRecep == 1 ? this.$t('label.yes') : this.$t('label.no');
                }
                arreglo['BL NRO']=item.BlNo ? item.BlNo : '';
                arreglo[this.$t('label.DescriptionOfGoods')]=item.GeneralInfo ? item.GeneralInfo : '';
                arreglo[this.$t('label.consignee')]=item.Consignee ? item.Consignee : '';
                arreglo[this.$t('label.UMReefer')]=item.UnitMeasureReefer ? item.UnitMeasureReefer : '';
                arreglo[this.$t('label.temperature')]=item.ReeferSetting ? item.ReeferSetting : '';
                arreglo[this.$t('label.UMOversize')]=item.UnitMeasureOversize ? item.UnitMeasureOversize : '';
                arreglo[this.$t('label.DimFront')]=item.DimFront ? item.DimFront : '';
                arreglo[this.$t('label.DimBack')]=item.DimBack ? item.DimBack : '';
                arreglo[this.$t('label.DimLeft')]=item.DimLeft ? item.DimLeft : '';
                arreglo[this.$t('label.DimRight')]=item.DimRight ? item.DimRight : '';
                arreglo[this.$t('label.DimTop')]=item.DimTop ? item.DimTop : '';
                arreglo[this.$t('label.Heading')]=item.HeadingName ? item.HeadingName : '';
                arreglo['DUA']=item.Dua ? item.Dua : '';
                ExcelAuxiliaryFile.push(arreglo);
            });
            await this.getExcelArray(ExcelAuxiliaryFile,this.EdiFileInfo.EdiFileName,valor);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
        }else{
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
        }
    }
    //computed
    function fields(){
        let Fields = [
            { 
                key: 'ErrorJson',
                _style:'width:50px;text-align: center;',
                _classes:"center-cell",
                label: '', 
                sorter: false, 
                filter: false
            },
            { key: 'Nro', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:220px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell", _style:'min-width:130px;'},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell", _style:'min-width:130px;'},
            { key: 'BlNo', label: this.$t('label.bl'),_classes:"center-cell", _style:'min-width:130px;'},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell", _style:'min-width:130px;'},
            { key: 'Seals', label: this.$t('label.Seal')+'s',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell", _style:'min-width:140px;'},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell", _style:'min-width:130px;'},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:160px;text-align: left;'},
        ];
        if (this.EdiFileInfo?.TpEdiTransacId?.toUpperCase()===process.env.VUE_APP_CUSTOM_MANIFEST_ID) {
            Fields = [...Fields, 
                { key: 'YardName', label: this.$t('label.yard'),_classes:"center-cell", _style:'min-width:100px;'},
                { key: 'Procedence', label: this.$t('label.Procedence'),_classes:"center-cell", _style:'min-width:100px;'},
                { key: 'DirectDischarge', label: this.$t('label.DirectDischarge'),_classes:"center-cell", _style:'min-width:100px;'},
            ]
        };
        if (this.EdiFileInfo?.TpEdiTransacId?.toUpperCase()===process.env.VUE_APP_TP_ARCHIVE_YARD_EMPTY_ID) {
            Fields = [...Fields, 
                { key: 'Procedence', label: this.$t('label.Procedence'),_classes:"center-cell", _style:'min-width:100px;'},
                { key: 'Recep', label: this.$t('label.reception') ,_classes:"center-cell", _style:'min-width:100px;'},
            ]
        };
        return Fields;
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function nombreTabla(){
        if(this.auxList){
            return this.EdiFileInfo.EdiFileName;
        }else{
            return '';
        }
    }
    function dateFilteredItems(){
        if(this.auxList){
            return this.computedItems.map((item,index) => Object.assign({}, item, {
                RowNumber: Number.parseFloat(item.Nro),
                ContainerCode: item.ContainerCode ? item.ContainerCode : "",
                Size: item.Size ? item.Size : 0,
                Status: item.Status ? item.Status : "",
                BlNo: item.BlNo ? item.BlNo : "",
                Seals: item.Seals ? item.Seals : "",
                ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : "",
                IsoCode: item.IsoCode ? item.IsoCode : "",
                ImdgCode: item.ImdgCode ? item.ImdgCode : "",
                LoadPort: item.LoadPort ? item.LoadPort : "",
                DischargePort: item.DischargePort ? item.DischargePort : "",
                DeliveryPort: item.DeliveryPort ? item.DeliveryPort : "",
                TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : "",
                Weigth: item.Weigth ? item.Weigth : 0,
                Vgm: item.Vgm ? item.Vgm : 0,
                VgmCertificate: item.VgmCertificate ? item.VgmCertificate : "",
                YardName: item.YardName ? item.YardName : "",
                Procedence: item.Procedence ? item.Procedence : "",
                DirectDischarge: item.FgDirect === true ?  this.$t('label.yes') : this.$t('label.no'),
                Recep: item.FgRecep == 1 ? this.$t('label.yes') : this.$t('label.no'),
                _classes: this.EdiFileInfo?.TpEdiTransacId?.toUpperCase()==`${process.env.VUE_APP_TP_ARCHIVE_YARD_EMPTY_ID}` ? (item.FgRecep == 1 ? 'table-success' : '') : '',
            }));
        }else{
            return [];
        }
    }
    function auxList(newValue){ 
        if(newValue){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('GET', 'StowagePlanningCargoAuxiliarList-by-EdiAuxFileId', {
                EdiAuxFileId: this.EdiFileId
            }).then(responseAll => {
                if(responseAll.status === 200){
                    this.computedItems = responseAll.data.data;
                    let listado = responseAll.data.data || []; 
                    this.itemsArreglo = listado;
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                }else{  
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            });
        }else{
            this.computedItems = [];
        }
    }
    export default {
        name:'auxiliar-list',
        data,
        methods:{
            desactivar,
            itemChanged,
            onBtnExport,
            formatMilDecimal,
        },
        computed: {
            fields,
            tableTextHelpers,
            nombreTabla,
            apiStateLoading,
            dateFilteredItems,
            ...mapState({
                user: state => state.auth.user,
                auxList: (state) => state.planificacionestiba.auxList,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        },
        watch:{
            auxList
        },
        mixins: [Reportes],
        components:{
            modaBugList
        }
    }
</script>

<style lang="scss">
    .animation-item{
        -webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
    }
    /**
    * ----------------------------------------
    * animation pulse
    * ----------------------------------------
    */
    @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
    @keyframes pulse{
        0%,
        100% {
            transform:scale(1.1)
        }
        50% {
            transform:scale(.8)
        }
    }
</style>