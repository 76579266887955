
<template>
  <div class="mt-2">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <modalEmpleado :modal="showEmpleado" @cerrarModal="showEmpleado=false" @child-refresh="refrescarTabla=true" />
    <modalRecurso :modal="showModal" @cerrarModal="showModal=false" @child-refresh="refrescarSelect" />
    <CRow class="mt-3 mb-3">
      <CCol sm="6">
        <CRow>
          <CCol sm="11" class="px-0" style="margin-bottom:20px;">
            <CSelect
              :label="$t('label.role')"
              horizontal
              addLabelClasses="text-right required"
              :options="rolOptions"
              :is-valid="hasError($v.StowageRoleId)"
              v-model.trim="$v.StowageRoleId.$model"
              :value.sync="StowageRoleId"
              :invalid-feedback="$t('label.required')"
            />
          </CCol>
          <CCol sm="1" class="px-0">
            <CButton
              color="edit"
              class="float-left"
              @click="habilitaCollapse(0)" 
              size="sm"
              v-c-tooltip="{
                content: $t('label.nuevo')+$t('label.role'),
                placement: 'top-end'
              }"
              >
              <CIcon name="cil-playlist-add"/>
            </CButton>
          </CCol>

          <CCol sm="11" class="px-0" style="margin-bottom:20px;">
            <CSelect
              :label="$t('label.ubication')"
              horizontal
              addLabelClasses="text-right required"
              :options="ubicationOptions"
              :is-valid="hasError($v.StowageUbicationId)"
              v-model.trim="$v.StowageUbicationId.$model"
              :value.sync="StowageUbicationId"
              :invalid-feedback="$t('label.required')"
                />
          </CCol>
          <CCol sm="1" class="px-0">
            <CButton
              color="edit"
              size="sm"
              class="float-left"
              @click="habilitaCollapse(2)"
              v-c-tooltip="{
                content: $t('label.nueva')+$t('label.location'),
                placement: 'top-end'
              }"
              >
              <CIcon name="cil-playlist-add"/>
            </CButton>
          </CCol>
          <CCol v-if="mostrarEmpresaBoolean" sm="11" class="px-0" style="margin-bottom:10px;">
            <CSelect
              :label="$t('label.company')"
              horizontal
              addLabelClasses="text-right required"
              :options="companyOptions"
              v-model.trim="ClientTpId"
              :value.sync="ClientTpId"
            />
          </CCol>
          <CCol sm="1" class="px-0" v-if="mostrarEmpresaBoolean">
            <CButton
              color="edit"
              class="float-left"
              @click="habilitaCollapse(1)" 
              size="sm"
              v-c-tooltip="{
                content: $t('label.nueva')+$t('label.company'),
                placement: 'top-end'
              }"
            >
            <CIcon name="cil-playlist-add"/>
            </CButton>
          </CCol>
        </CRow> 
      </CCol>
      <CCol sm="4">
        <CRow>
          <CCol sm="12" style="margin-bottom:20px;">
          <CSelect
            :label="$t('label.payrollType')"
            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
            addLabelClasses="text-right required"
            :options="payrollOptions"
            :is-valid="hasError($v.PayRollId)"
            v-model.trim="$v.PayRollId.$model"
            :value.sync="PayRollId"
            :invalid-feedback="$t('label.required')"
          />
          </CCol>
          <CCol sm="12" style="margin-bottom:20px;">
            <CInput 
              :label="$t('label.quantity')"
              addLabelClasses="text-right required"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model.trim="$v.Quantity.$model"
              :value.sync="Quantity"
              :invalid-feedback="$t('label.required')+$t('label.onlyNumber')"
              :is-valid="hasError($v.Quantity)"
            />
          </CCol>
        </CRow> 
      </CCol>
      <CCol sm="2">
        <CButton size="sm" class="mr-1" color="wipe" @click="limpiarDatos"
          v-c-tooltip="{
              content:  $t('label.clearFields'),
              placement: 'top-end'
          }"
        >
          <CIcon name="cil-brush-alt"/>
        </CButton>
        <CButton v-if="!editar" size="sm" class="mr-1" color="add" :disabled="desactivado" @click="guardar" 
          v-c-tooltip="{
              content:  $t('label.grabar'),
              placement: 'top-end'
          }"          
        >
          <CIcon name="checkAlt" />
        </CButton>
        <CButton v-if="editar" size="sm" class="mr-1" color="add" :disabled="desactivado" @click="guardar" 
          v-c-tooltip="{
              content:  $t('label.grabar'),
              placement: 'top-end'
          }"          
        >
          <CIcon name="checkAlt" />
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="items"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
          hover
          sorter
        >
          <template #loading>
            <loading/>
          </template>
          <template #Quantity="{ item }">
            <td>
              {{ item.AssignedQuantity }} / {{ item.Quantity }}
            </td>
          </template>
          <template #Detalle="{ item }">
            <td class="text-center">
                <CButton color="watch" size="sm" v-c-tooltip="{content: $t('label.employeesRole'),placement: 'top'}"  @click="showEmpleado=item">
                  <CIcon name="user" />
                </CButton>
                <CButton color="edit" class="ml-1" size="sm" v-c-tooltip="{content: $t('label.edit'),placement: 'top'}" @click="editDatos(item)">
                  <CIcon name="pencil" />
                </CButton>
                <CButton color="wipe" class="ml-1" size="sm" v-c-tooltip="{content: $t('label.delete')}" @click="ConfirmarEliminado(item)" >
                  <CIcon name="cil-trash" />
                </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import UpperCase  from '@/_validations/uppercase-directive';
import RecursoValidations from '@/_validations/visitas/recursoValidations';
import modalEmpleado from './modal-empleado';
import modalRecurso from './modal-recurso';

function data() {
  return {
    items: [],
    loadingTable: false,
    showModal: false,
    showEmpleado: false,
    refrescarTabla: false,
    loadingOverlay: false,
    editar: false,
    supplieItem: '',
    companyOptions: [],
    proveedorOptions: [],
    measureUnitOptions: [],
    rolOptions: [],
    ubicationOptions: [],
    payrollOptions: [],
    UbicationJson: [],
    newUnit: false,
    tercerizada: false,
    //MODELO
    ClientTpId: '',
    VisitId: '',
    StowageRoleId: '',
    PayRollId: '',
    StowageUbicationId: '',
    Quantity: 0,
    VisitResourceId:'',
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 0,
      masked: false
    }
  };
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
    },
    { key: 'ClientName', label: this.$t('label.company'), _style: 'width:30%;',sorter: true },
    { key: 'StowageRoleName', label: this.$t('label.stowageRole'), _style: 'width:30%;',sorter: true },
    { key: 'PayRollName', label: this.$t('label.payrollType'),_style: 'width:25%;',sorter: true },
    { key: 'Quantity', label:this.$t('label.quantity'), _style: 'width:10%;', _classes: 'font-weight-bold',sorter: true },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'min-width: 150px; width:3%; text-align:center;'
    }
  ];
}

async function VisitList () {
  this.loadingTable = true;
  this.items = [];
  
  let listado = Array;
  await this.$http.get("VisitRole-list", { VisitId: this.VisitId })
  .then(response => {
    listado = [...response.data.data];

    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: listado.Nro,
      ClientTpId: listado.ClientTpId,
      ClientName: listado.ClientName ? listado.ClientName : "N/A",
      StowageRoleName: listado.StowageRoleName,
      PayRollName: listado.PayRollName,
      StowageUbicationName: listado.StowageUbicationName, 
      AssignedQuantity: listado.AssignedQuantity,
      Quantity: listado.Quantity,
      FgActVisitResource: listado.FgActVisitResource,
      PayRollId: listado.PayRollId,
      VisitId: listado.VisitId,
      StowageRoleId: listado.StowageRoleId,
      StowageUbicationId: listado.StowageUbicationId,
      VisitResourceId: listado.VisitResourceId,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingTable = false;
  });
}

function mostrarEmpresaBoolean(){
  
  if(this.PayRollId !== '' && this.PayRollId !== null && this.payrollOptions.length !== 0){
    const Index = this.payrollOptions.map(function(e) {
      return e.value; 
    }).indexOf(this.PayRollId);
    if(Index !== -1){
      return this.payrollOptions[Index].FgSupplier;
    }
    return false;
  }
  return false;
}

function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this, {
        text: `${this.$t('label.deleteQuestion')}: ${item.StowageRoleName} ${this.$t('label.and')+' '+this.$t('label.payrollType')}: ${item.PayRollName}?`,
      })
    ).then((result) => {
    if (result.isConfirmed) {
      let res = [];
      let VisitRoleJson = [];

      VisitRoleJson = {
        VisitId: item.VisitId,
        VisitResourceId: item.VisitResourceId,
        StowageRoleId: item.StowageRoleId,
        PayRollId: item.PayRollId,
        StowageUbicationId: item.StowageUbicationId,
        Status: 0
      };

      this.$http.put("VisitRoleAll-update", VisitRoleJson, { root: 'VisitRoleJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.VisitList();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      });
    }
  });  
}

function limpiarDatos() {
  this.VisitResourceId = '';
  this.StowageRoleId = '';
  this.ClientTpId = '';
  this.PayRollId = '';
  this.tercerizada = false;
  this.editar = false;
  this.StowageUbicationId = '';
  this.Quantity = 0;
  this.$nextTick(() => { this.$v.$reset() })
}

async function getUbicaciones() {
  this.loadingTable = true;
  let listado = [];
  await this.$http.get("StowageUbication-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = response.data.data;

    this.ubicationOptions = listado.map(listado => Object.assign({}, this.ubicationOptions, {
      value: listado.StowageUbicationId,
      label: listado.StowageUbicationName,
    }));

    this.ubicationOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

async function listarEmpresas () {
  this.loadingOverlay = true;
  this.companyOptions = [];

  let listado = Array;
  await this.$http.get("ClientActivityList-by-PayRollId", { PayRollId: this.PayRollId })
  .then(response => {
    listado = response.data.data;
    this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
      value: listado.ClientTpId,
      label: listado.ClientName,
    }));
    this.companyOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function listarPayroll () {
  this.loadingOverlay = true;
  this.payrollOptions = [];

  let listado = Array;
  await this.$http.get("PayRoll-list")
  .then(response => {
    listado = [...response.data.data].filter(ob => ob.Status==="ACTIVO");
    this.payrollOptions = listado.map(listado => Object.assign({}, this.payrollOptions, {
      value: listado.PayRollId,
      label: listado.PayRollName,
      FgSupplier: listado.FgSupplier
    }));

    this.payrollOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function listarRoles () {
  this.loadingOverlay = true;
  this.rolOptions = [];

  let listado = Array;
  await this.$http.get("StowageRoleVessel-list", { StowageRoleId:'', TpVesselId: '2a719797-70db-437b-b55d-58f4177cb87b', Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.rolOptions = listado.map(listado => Object.assign({}, this.rolOptions, {
      value: listado.StowageRoleId,
      label: listado.StowageRoleName,
    }));

    this.rolOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function guardar () {
  if (this.$v.$invalid) return false;
  let StowageUbicationJson = [];
  let VisitRoleJson = [];
  let ruta = '';
  let metodo = '';
  
  if ((this.tercerizada) && (this.ClientTpId=='')) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: 'Debe seleccionar una compañia',
      type: "error"
    });
    return false;
  }

  this.loadingOverlay = true;
  /*if (this.editar) {
    VisitRoleJson = {
      VisitId: this.VisitId,
      StowageRoleId: this.StowageRoleId,
      PayRollId: this.PayRollId,
      StowageUbicationId: this.StowageUbicationId,
      Quantity: this.Quantity,
      Status: 1
    };
    metodo = 'PUT';
    ruta = "VisitRole-update";
  } else {*/
    StowageUbicationJson.push({
      StowageUbicationId: this.StowageUbicationId,
      Quantity: this.Quantity,
    });

    VisitRoleJson = {
      VisitId: this.VisitId,
      VisitResourceId: this.VisitResourceId ? this.VisitResourceId : null,
      StowageRoleId: this.StowageRoleId,
      PayRollId: this.PayRollId,
      ClientTpId: this.ClientTpId,
      StowageUbicationJson: StowageUbicationJson
    };
    metodo = 'POST';
    ruta = "VisitRole-insert";
  //}

  this.$http.ejecutar(metodo, ruta, VisitRoleJson, { root: 'VisitRoleJson' })
  .then(response => {
    let res = [...response.data.data];
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.loadingOverlay= false;
    this.limpiarDatos();
    this.VisitList();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.loadingOverlay= false;
  });
}

function habilitaCollapse(valor) {
    this.newUnit = valor;
    this.showModal = valor;
}

function refrescarSelect(valor) {
  switch(valor) {
    case 0:{
      this.listarRoles();
      break;
    }case 1:
      if(this.mostrarEmpresaBoolean){
        this.listarEmpresas();
      }
      break;
    case 2:
      this.getUbicaciones();
      break;
    case 3:
      this.listarRoles();
      if(this.mostrarEmpresaBoolean){
        this.listarEmpresas();
      }
      this.getUbicaciones();
      break;
  }
}

function editDatos(item) {
  this.tercerizada = false;
  this.editar = true;
  this.StowageRoleId = item.StowageRoleId;
  this.VisitResourceId = item.VisitResourceId;
  this.VisitId = item.VisitId;
  this.PayRollId = item.PayRollId;
  this.Quantity = item.Quantity;
  this.StowageUbicationId = item.StowageUbicationId;
  
  this.ClientTpId = item.ClientTpId;
}

export default {
  name: 'index-recurso',
  data,
  props:['value'],
  mixins: [GeneralMixin],
  directives: UpperCase,
  validations: RecursoValidations,
  components: {
    modalEmpleado,
    modalRecurso
  },
  methods: {
    VisitList,
    ConfirmarEliminado,
    limpiarDatos,
    getUbicaciones,
    listarEmpresas,
    listarRoles,
    listarPayroll,
    guardar,
    habilitaCollapse,
    editDatos,
    refrescarSelect
  },  
  watch: {
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.VisitList();
        this.refrescarTabla = false;
      }
    },
    Visit: function () {
      if (this.Visit!="") {
        this.VisitId = this.Visit;
        this.VisitList();
      }
    },
    PayRollId: function (newValue){
      if(newValue != '' && this.mostrarEmpresaBoolean){
        this.listarEmpresas();
      }
    },
    value: function (newValue) {
      if ((newValue==1) && (this.Visit!="")) {
        this.VisitId = this.Visit;
        this.VisitList();
        this.getUbicaciones();
        if(this.mostrarEmpresaBoolean){
          this.listarEmpresas();
        }
        this.listarRoles();
        this.listarPayroll();
      }
    },
    dropItemReport: function (newValue,oldValue){
      if(oldValue==21){
        this.limpiarDatos();
      }
    }
  },
  computed: {
    fields,
    mostrarEmpresaBoolean,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,
    })
  }
};
</script>
<style scope>

</style>
