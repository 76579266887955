<template>
    <div class="col-sm-12 col-lg-12">
        <div>
            <DhtmlxGantt :tasks="GanttList" :Color="GanttList"></DhtmlxGantt>
        </div>
        <div v-if="NotProgrammed.length!=0">
            <table class="table table-bordered mt-2">
            <thead>
                <tr style="background: #ededed;">
                  <th class="text-center gb-color-cell">{{ $t('label.ActivitiesNotExecuted') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in NotProgrammed" :key="item.targetid">
                  <td>{{ $i18n.locale=='en' ? item.textEn : item.textEs }}</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { gantt } from 'dhtmlx-gantt';
import moment from 'moment';
import DhtmlxGantt from './DhtmlxGantt'
function data() {
    return {
        Gantt: [],
        NotProgrammed: [],
    }
}

//computed
function GanttList() {
  if (this.Gantt.length!=0) {
    let gattlist = this.Gantt;
    this.NotProgrammed = [];
    let data = [];
    let links = [];
    let tasks = {data: [], links: []};
    let _lang = this.$i18n.locale;
    gattlist.map((item) =>{
      if (item.TransactionBegin) {
        let text = _lang=='en' ? item.textEn : item.textEs;
        data.push({
          id: item.targetid,
          text: text,
          start_date: item.TransactionBegin ? moment(item.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
          end_date: item.TransactionBegin&&item.TransactionFinish&&item.TransactionFinishAux?moment(item.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
            : item.TransactionBegin ? (item.FgDateRange ? moment(item.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
            : item.TransactionFinishAux ? moment(item.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') 
            : moment(item.TransactionBegin,'YYYY-MM-DD HH:mm').add(3, 'h').format('YYYY-MM-DD HH:mm')) : '',
          DateFinish: item.TransactionFinish ? moment(item.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
          progress:  item.ProgressAux ? item.ProgressAux : item.progress!=null ? item.progress : 1,
          Completed: '-',
          Hrs: item.Hrs,
          HrsInactive: item.HrsInactive?item.HrsInactive : '00:00',
          HrsAcumulated: item.HrsAcumulated!='00:00'?item.HrsAcumulated:'00:00',
          color: '#e0e0e0',
          unscheduled: item.TransactionBegin ? false : true,
          progressColor: item.color,
          FgDateRange: item.FgDateRange ? false : true,
          row_height: 25,
        });
        if (item.ChildsJson && item.ChildsJson.length != 0) {
          item.ChildsJson.map((ChildsJson) =>{ 
            if (ChildsJson.TransactionBegin) {
              text = _lang=='en' ? ChildsJson.textEn : ChildsJson.textEs;
              data.push({
                id: ChildsJson.targetid,
                text: text,
                start_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                end_date: ChildsJson.TransactionBegin&&ChildsJson.TransactionFinish&&ChildsJson.TransactionFinishAux?moment(ChildsJson.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
                  : moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
                DateFinish: ChildsJson.TransactionFinish ? moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
                progress: ChildsJson.ProgressAux ? ChildsJson.ProgressAux : ChildsJson.Progress,
                Completed: ChildsJson.ProgressOperative*100+'%',
                Hrs: ChildsJson.Hrs,
                HrsInactive: ChildsJson.HrsInactive?ChildsJson.HrsInactive : '00:00',
                HrsAcumulated: ChildsJson.HrsAcumulated,
                color: '#e0e0e0',
                progressColor: ChildsJson.color, 
                unscheduled: ChildsJson.TransactionBegin ? false : true,
                row_height: 25,
                parent: item.targetid,
              });
              links.push({
                id: ChildsJson.targetid,
                source: item.targetid,
                target: ChildsJson.targetid,
                type: 1, 
              });
              if (ChildsJson.ChildsJson.length != 0) {
                ChildsJson.ChildsJson.map((Childs) =>{ 
                  text = _lang=='en' ? Childs.VisitActivityGpoNameEn : Childs.VisitActivityGpoNameEs;
                  data.push({
                    id: Childs.StowageEventId,
                    text: text,
                    start_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                    end_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                    progress: Childs.Porc,
                    Completed: '-',
                    HrsInactive: '-',
                    Hrs: Childs.TotalTime,
                    HrsAcumulated: '-',
                    color: '#e0e0e0',
                    progressColor: Childs.Color, 
                    unscheduled: false,
                    row_height: 25,
                    parent: ChildsJson.targetid,
                    Delay: true,
                  });
                  links.push({
                    id: Childs.StowageEventId,
                    source: ChildsJson.targetid,
                    target: Childs.StowageEventId,
                    type: 2, 
                  });
                });
              }
            } else {
              this.NotProgrammed.push({
                textEn: item.textEn+': '+ChildsJson.textEn,
                textEs: item.textEs+': '+ChildsJson.textEs,
                targetid: ChildsJson.targetid,
              });
            }
          });    
        } 
      } else {
        this.NotProgrammed.push(item);
      }   
    });
    tasks.data = data;
    tasks.links = links;
    return tasks;
  } else {
    return {data: [], links: []}
  }
}


export default {
  name:"time-in-port",
  data,
  mixins: [],
  props:{
    TimeLineJson: {
      type: Array,
      default: function () {
        return [] 
      },
    }
  },
  components:{
    DhtmlxGantt,
  },
  computed:{
    GanttList,
    ...mapState({
       VisitId: state => state.Management.VisitId,
      CarruselTab: state => state.Management.CarruselTab,
      itinerarySelected: state => state.Management.itinerarySelected,
    })
  },
  watch:{
    CarruselTab: function (val, OldVal) {
      if (val==1) {
        gantt.clearAll();
        this.Gantt = [];
        this.NotProgrammed = [];
        this.Gantt = this.TimeLineJson;
      };
      if (OldVal==1) {
        gantt.clearAll();
      };
    }
  },
}
</script>