<template>
    <div>
      <CRow>
        <CCol sm="12">
            <CRow>
                <CCol sm="12">
                  <CTabs :activeTab="activeTab" @update:activeTab="handleTab">
                    <CTab :title="$t('label.gpoSupply')">
                      <GpoSupply :active="isActiveTab(0)" class="ml-1 mr-1" />
                    </CTab>
                    <CTab :title="$t('label.supply')">
                      <Insumo :active="isActiveTab(1)" class="ml-1 mr-1" />
                    </CTab>
                    <CTab :title="$t('label.UnitMeasurementAssociation')">
                      <MeasurementUnits :active="isActiveTab(2)" class="ml-1 mr-1" />
                    </CTab>
                  </CTabs>
                </CCol>
            </CRow>
        </CCol>
      </CRow>         
    </div>
</template>
    
<script>
    import Insumo from './insumo';
    import GpoSupply from './gpo-supply-index';
    import MeasurementUnits from './measurement-units';
    
    //Data
    function data() {
      return {
        isSubmit: false,
        activeTab: 4,
      }
    }
    
    //Methods
    function handleTab(tab) {
      this.activeTab = tab;
    }
    function isActiveTab(current) {
      return this.activeTab == current;
    }
    
    export default {
      name: 'estiba',
      props: ['active'],
      components: {
        Insumo,
        MeasurementUnits,
        GpoSupply,
      },
      data,
      methods: {
       handleTab,
       isActiveTab
      },
      watch:{
        active: function (Val) {
          if (Val) {
            this.activeTab = 0;
          }else{
            this.activeTab = 4;
          }
        }
      }
    }
</script>