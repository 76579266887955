var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.fgDisabled)?_c('CCol',{staticClass:"my-2",attrs:{"sm":"12"}},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-error":_vm.DropzoneError}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}}),_vm._v(" ¡"+_vm._s(_vm.$t('label.dragAndDropToUploadContent'))+"!")],1),_c('div',{staticClass:"subtitle"},[_vm._v("..."+_vm._s(_vm.$t('label.orClickToSelectFileFromYourComputer'))+" ")])])])],1):_vm._e(),(!_vm.fgDisabled)?_c('CRow',{staticClass:"m-0 mt-4"},[_c('CCol',{attrs:{"sm":"12","lg":"10"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"remove-space",attrs:{"label":_vm.$t('label.description'),"horizontal":"","addLabelClasses":"required text-right","placeholder":_vm.$t('label.documentDescription'),"horizontal":{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'},"is-valid":_vm.hasError(_vm.vForm.Document.DocumentDs),"invalid-feedback":_vm.errorMessage(_vm.vForm.Document.DocumentDs)},model:{value:(_vm.vForm.Document.DocumentDs.$model),callback:function ($$v) {_vm.$set(_vm.vForm.Document.DocumentDs, "$model", $$v)},expression:"vForm.Document.DocumentDs.$model"}})],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"12","lg":"auto"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.add'),
            placement: 'top-end'
        }),expression:"{\n            content: $t('label.add'),\n            placement: 'top-end'\n        }"}],attrs:{"color":"add","size":"sm","disabled":_vm.isSubmitDocument},on:{"click":function($event){return _vm.submitDocument()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1)],1)],1):_vm._e(),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListDocument,"fields":_vm.documentfields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"DocsRoute",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({placement:'top-end',content:_vm.$t('label.download')+' '+_vm.$t('label.document')}),expression:"{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"}],staticClass:"btn btn-sm btn-watch mr-1",attrs:{"color":"excel","target":"_blank","href":item.DocsRoute}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),(!_vm.fgDisabled)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.delete')+_vm.$t('label.document'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.delete')+$t('label.document'),\n              placement: 'top-end',\n            }"}],staticClass:"btn btn-sm btn-wipe",on:{"click":function($event){return _vm.deleteDocument(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }