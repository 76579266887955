import {
  required,
  minLength,
  maxLength,
  helpers,
  email,
} from 'vuelidate/lib/validators';

import { textareaSpecials, phoneTest, onlyText } from '../funciones';
    
const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value/*.toLowerCase()*/);
const onlyNumbers = (value) => /^[0-9]+$/.test(value);
const textareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);
const correctDate = (value) => {
  if (value === '') {
    return true;
  } else {
    let i = value.indexOf('-');
    let j = value.indexOf('/');
    if (i !== -1) {
      let date = value.split('-' || '/');
      let fecha = new Date();
      let ano = fecha.getFullYear();
      ano = parseInt(ano) + 10;
      if (date.length === 3) {
        if (
          (date[0].length > 0 && date[0].length < 4) ||
          (date[2].length > 0 && date[2].length < 4)
        ) {
          return true;
        } else if (
          (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
          (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
        ) {
          return true;
        } else if (
          parseInt(date[1]).length > 0 &&
          parseInt(date[1]).length < 12
        ) {
          return true;
        } else if (
          (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
          (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else if (j !== -1) {
      let date = value.split('/');
      let fecha = new Date();
      let ano = fecha.getFullYear();
      ano = parseInt(ano) + 10;
      if (date.length === 3) {
        if (
          (date[0].length > 0 && date[0].length < 4) ||
          (date[2].length > 0 && date[2].length < 4)
        ) {
          return true;
        } else if (
          (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
          (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
        ) {
          return true;
        } else if (
          parseInt(date[1]).length > 0 &&
          parseInt(date[1]).length < 12
        ) {
          return true;
        } else if (
          (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
          (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
};

export default () => {
  return {
    staffInfo: {
      ClientTpId: { required },
      NumberId: { minLength: minLength(7), maxLength: maxLength(10), required, onlyNumbers },
      Name: { onlyText, required },
      LastName: { onlyText, required },
      StowageRoleId: { required },
      BirthDate: { correctDate },
      CountryId: { },
      StateId: { },
      MunicipalityId: { },
      ParishId: { },
      Address: { textareaNotReq, maxLength: maxLength(150)},
      PrimaryPhone: { mobileNotReq,  minLength: minLength(11), maxLength: maxLength(15) },
      SecondaryPhone: { mobileNotReq,  minLength: minLength(11), maxLength: maxLength(15) },
      PrimaryEmail: { email: emailToLower, maxLength: maxLength(100) },
      SecondaryEmail: { email: emailToLower, maxLength: maxLength(100) },
    },
  }
}