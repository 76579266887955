<template>
    <div>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.SituacionBodega") }} </span>
                </div>
                <div style="width: 100%;padding:10px;">
                    <div class="d-flex align-items-center justify-content-end mb-2">
                        <span class="circle mr-1" style="background: #1A3760 !important;;"></span>{{ $t('label.planned') }}
                        <span class="circle mx-1" style="background: #EC631B !important;"></span>{{ $t('label.done') }}
                    </div>
                    <div class="row row-cols-5">
                        <div v-for="(item, index) in operationHold" :class="item.class" :key="index" :style="item.bandera ? '' : 'margin-bottom:10px;'">
                            <div v-if="!item.bandera" style="font-size:0.65rem;">
                                <div class="p-2" style="background: #edebeb;padding:5px;border-radius: 5px 5px 0px 0px;text-align: center;">
                                    <b style="font-weight: bold;">{{ item.VesselHoldName }}</b>
                                </div>

                                <div class="p-2" style="padding-left:10px !important;padding-right:10px !important;background: #E8F0FA;border-radius: 0px 0px 5px 5px;text-align: center;">
                                    <div class="d-flex">
                                        <div class="flex-fill" style="text-align:left">
                                            {{ $t("label.StartLoard") }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ item.BeginShipmentNew }}</b>
                                        </div>
                                    </div>
                                    <div class="d-flex" :style="bandera ? 'margin-top:10px;height: 110px;padding-left:10px;padding-right: 10px;' : 'display:none;'" >
                                        <ColumnsDistruibuid
                                            :colors="['#013565','#EC631B']"
                                            :height="'110px'"
                                            :categories="item.categories"
                                            :seriesGrafica="item.series"
                                            :legendsTooltip="item.legend"
                                        ></ColumnsDistruibuid>
                                    </div>
                                    <div class="d-flex" style="margin-top:10px;text-align:left;">
                                        <div class="flex-fill">
                                            {{ $t("label.Remaing") }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ valuemoney(item.RemainingTon) }} TON</b>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="margin-top:10px;text-align:left;">
                                        <div class="flex-fill">
                                            {{ $t('label.TotalGamelas') }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ item.CantImplement }}</b>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="margin-top:10px;text-align:left;">
                                        <div class="flex-fill">
                                            {{ $t("label.GamelaxHora") }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ item.CantImplementXHour }}</b>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="margin-top:10px;text-align:left;">
                                        <div class="flex-fill">
                                            {{ $t('label.GamelaXdia') }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ item.CantImplementPerDay }}</b>
                                        </div>
                                    </div>
                                    <div class="w-100" style="background: #e58c5e;color:#FFF;padding:5px;text-align: center;margin-top:10px;">
                                        <div class="w-100">
                                            <span>{{ $t('label.today') }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <div class="flex-fill" style="text-align: left;">
                                                <b style="font-weight: bold;">{{ $t('label.Gamela') }}</b>
                                            </div>
                                            <div class="flex-fill" style="text-align: right;">
                                                <b style="font-weight: bold;">{{ item.CantImplementToday }}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-100" style="background: #B2CCEF;padding:5px;text-align: center;margin-top:10px;">
                                        <div class="w-100">
                                            <span>{{ $t("label.LastReading") }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <div class="flex-fill"  style="text-align: left;">
                                                <b style="font-weight: bold;">{{ item.LastReadDateCertificateNew }}</b>
                                            </div>
                                            <div class="flex-fill" style="text-align: right;">
                                                <b style="font-weight: bold;">{{ valuemoney(item.LastReadTonCertificate) }} TON</b>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex" style="margin-top:10px;">
                                        <div class="flex-fill" style="text-align:left">
                                            {{ $t("label.LastMovement") }}
                                        </div>
                                        <div class="flex-fill" style="text-align:right">
                                            <b style="font-weight: bold;">{{ item.EndShipmentNew }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { DateFormater } from '@/_helpers/funciones';
    import ColumnsDistruibuid from '@/pages/situacion-operativa-actual/components/ColumnsDistruibuid';
    //methods
    function valuemoney(value){
      if(value !== null){
        let modifiedValue = parseFloat(value).toFixed(2);
        var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
        return new Intl.NumberFormat("de-DE",options).format(modifiedValue);
      }
      return "0,00";
    }
    //computed
    function bandera(){
        return this.$store.state.situacionOperativaActual.tabsOperationes === 1 && this.$store.state.situacionOperativaActual.carruselTab === 2; 
    }
    function operationHold(){
        let data = [];
        this.HoldJson.map(async(item,index) => {
            let i = index+1;
            if((i % 6) === 0){
                data.push({
                    class: 'w-100',
                    bandera: true,
                })
            }
            let BeginShipmentNew = item.BeginShipmentDate ? DateFormater.formatDateTimeWithSlash(item.BeginShipmentDate) : 'N/A';
            let LastReadDateNew = item.LastReadDate ? DateFormater.formatDateTimeWithSlash(item.LastReadDate) : 'N/A';
            let EndShipmentNew = item.EndShipmentDate ? DateFormater.formatDateTimeWithSlash(item.EndShipmentDate) : 'N/A';
            let LastReadDateCertificateNew = item.LastReadCertificateDate ? DateFormater.formatDateTimeWithSlash(item.LastReadCertificateDate) : 'N/A';
            let PlannedTon = item.PlannedTon  === null ? 0 : item.PlannedTon;
            let EmbarkedTon = item.EmbarkedTon  === null ? 0 : item.EmbarkedTon;

            let categories = [
                this.valuemoney(PlannedTon),
                this.valuemoney(EmbarkedTon),
            ]
            let series = [
                PlannedTon,EmbarkedTon
            ]
            let legend = [
                this.$t('label.planned'),
                this.$t('label.Loaded'),
            ]
            data.push({
                ...item,
                LastReadDateCertificateNew: LastReadDateCertificateNew,
                BeginShipmentNew: BeginShipmentNew,
                LastReadDateNew: LastReadDateNew,
                EndShipmentNew: EndShipmentNew,
                categories: categories,
                legend: legend,
                series: series,
                bandera: false,
                class: 'col'
            })
        })
        return data;
    }
    export default {
        name:'HoldSituation',
        props: {
            HoldJson:{
                type:Array,
                default: () => [],
            }
        },
        methods:{
            valuemoney
        },
        components:{
            ColumnsDistruibuid
        },
        computed:{
            bandera,
            operationHold,
        }
    }  
</script>

<style lang="scss">
    .progress-bar-vertical {
        width: 40%;
        min-height: 100%;
        float: left;
        display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
        align-items: center;
        -webkit-align-items: flex-end; /* Safari 7.0+ */
    }

    .progress-bar-vertical .progress-bar {
        width: 100%;
        height: 0;
        -webkit-transition: height 0.6s ease;
        -o-transition: height 0.6s ease;
        transition: height 0.6s ease;
    }
</style>