import {
    onlyAlphanumeric,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal } from "vuelidate/lib/validators";

    const isValidFile = (value) => {
        if(value === '' || value === null || value === undefined){
            return true;
        }
        if(!value){
            return false;
        }
        if(value.type)
          return value.type.startsWith('image');
        else{
            return (value != '');
        }
    };

    

export default () => {
    return {
        formMuelle: {
            IdPuerto:{
                required,
            },
            NbMuelle:{
                required,
                onlyAlphanumeric,
                minLength: minLength(4),
                maxLength: maxLength(150),
            },
            CondicionMuelle:{
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            LineaAtraque:{
                required,
                decimal,
                minValue:(1),
                maxValue:(16),
            },
            Calado:{
                required,
                decimal,
                minValue:(1),
                maxValue:(16),
            },
            Vita:{
                decimal,
                minValue:(1),
                maxValue:(16),
            },
            FgOperativo:{
                required
            },
            FgActMuelle:{
                required
            },
            RutaMuelle: { isValidFile  },
        }
    }
}

