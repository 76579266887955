<template>
  <main>
    <CModalExtended
      :title="$t('label.associatedLine')"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="itenLines"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :loading="loadingTable"
            sorter
          >
            <template #loading>
              <loading/>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>

      <template #footer>
        <CButton shape="square" color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import servicio from '@/_validations/servicio/servicio';
import mixinServicio from '@/_mixins/servicio';
import CargaGranel from '@/_mixins/carga-granel';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    itenLines: [],    
  };
}

function fields(){
  return [
    { label: '#', key: 'Nro', filter:false, _style: 'width:1%;'},
    { label: this.$t('label.representativeLine'), key: 'ShippingLineName', _classes: 'text-uppercase w-50' },
    { label: this.$t('label.user'), key: 'TransaLogin',  _classes: 'text-uppercase' },
    { label: this.$t('label.date'), key: 'FormatedDate', _classes:'cell-center text-center'  },
    { label: this.$t('label.status'), key: 'Status', _classes:'cell-center text-center' },
  ];
}
function closeModal(event) {
  this.showModal = false;
  this.openDateSatrt = false;
  this.$emit('updated-modal-lineas', event);
}

async function mountedLineas(id) {
  this.loadingTable = true;
  await this.$http
    .get(`ServiceLine-by-service?ServiceId=${id}`)
    .then((response) => {
      this.loadingTable = false;
      this.itenLines = response.data.data.map((item) => {
          return {
            ...item,
            FormatedDate: item.TransaRegDate
              ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
              : 'N/A',
          };
        });
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

export default {
  name: 'modalLineas',
  data,
  props: {
    modal: null,
    serviceSelected: '',
  },
  mixins: [GeneralMixin, mixinServicio, CargaGranel, ChoferVehiculo],
  watch: {
    modal: async function(val) {
      this.showModal = val;
    },
    serviceSelected: async function(val) {
      if (val !== '') {
        await this.mountedLineas(val);
      }
    },
  },
  methods: {
    closeModal,
    mountedLineas,
  },
  computed: {
    fields
  },
};
</script>
<style lang="scss"></style>
