<template>
  <div>
    <loading-overlay :active="$store.state.yardManagement.loading" :is-ShippingLineCode-page="true" loader="bars" />
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 0 && RoleFgGeneral">
      <ListYard></ListYard>
    </CCollapse>
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 1">
      <YardDetail></YardDetail>
    </CCollapse>
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 2">
      <Container></Container>
    </CCollapse>
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 4">
      <BlInfo :isYard="true" :Bl="dataBl"></BlInfo>
    </CCollapse>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import ListYard from './list-yard-index';
  import YardDetail from './yard-detail';
  import BlInfo from "@/pages/bl-query/bl-info";
  import Container from './container/container-index';
  

  //DATA-------------------------
  function data() {
    return {
    }
  }

  export default {
    name: 'yard-management',
    components: {
      ListYard,
      YardDetail,
      BlInfo,
      Container
    },
    data,
    mixins: [GeneralMixin],
    methods: {
    },
    computed:{
      ...mapState({
        yardData: state => state.yardManagement.yardData,
        RoleFgGeneral: state => state?.auth?.role?.FgGeneral,
        dataBl: state => state.yardManagement.dataBl,
      })
    },

  };
</script>
<style>
  .mx-datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
  }
</style>