import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import { router } from './_helpers';
import { store } from './_store';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import Vuelidate from 'vuelidate';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueEvents from 'vue-events';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import "regenerator-runtime/runtime";
import Notifications from 'vue-notification';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import x5GMaps from 'x5-gmaps';
import VueLazyLoad from 'vue-lazyload';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import VueI18n from 'vue-i18n';
import money from 'v-money';
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import service from './_services/service';
import { en } from './_lang/en';
import { es } from './_lang/es';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from 'ag-grid-vue';
import loadingComponent from '@/components/loading';
import loadingOverlayComponent from '@/components/loadingOverlay';
import requestPending from '@/components/requestPending';
import VueApexCharts from 'vue-apexcharts'
import { request } from './_store/request';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import dataTableExtended from '@/components/dataTableExtended';
import VueHtml2Canvas from 'vue-html2canvas';
import CModalExtended from '@/components/CModalExtended';
Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages: {
    en, es
  }
})

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VueLazyLoad);
Vue.use(x5GMaps, { key: 'AIzaSyDbdJhe5O29oV9FpVCufkF3Np19uyzAXKs', libraries: ['places'] });
Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VueEvents);
Vue.use(VueHtml2Canvas);
Vue.use(money, {precision: 4});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect);
Vue.component('multiselect', Multiselect);
Vue.component('loading-overlay-original', Loading);
Vue.component('loading-overlay', loadingOverlayComponent);
Vue.component('requestPending', requestPending);
Vue.component('vue-datepicker', DatePicker);
Vue.component('ag-grid-vue', AgGridVue);
Vue.component('loading', loadingComponent);
Vue.component('dataTableExtended', dataTableExtended);
Vue.component('CModalExtended', CModalExtended);
Vue.use(VueFormWizard)
Vue.prototype.$log = console.log.bind(console)

Vue.prototype.$http = service;

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App, 
  }
})
