<template>
<div>
  <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
  <ErrorsModal :modal.sync="errosModal" :orderFileId="orderFileId" />
  <CRow>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedHistorical"
        :fields="fieldsHistorical"
        :loading="loadingHistorical"
        column-filter
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items-per-page="tableText.itemsPerPage"
        hover
        small
        sorter
        pagination
      >
        <template #loading>
          <loading />
        </template>
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{ item.Status }}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center align-middle">
            <CButtonGroup>
              <CButton
                shape="square"
                color="edit"
                class="mr-1"
                size="sm"
                v-c-tooltip="
                  `${$t('label.toView')} ${$t('label.errors')}`
                "
                @click="toggleErrors(item)"
              >
                <CIcon name="error" />
              </CButton>
              <CButton
                shape="square"
                color="excel"
                class="mr-1"
                size="sm"
                :disabled="!item.Route"
                :href="getFile(item.Route)"
                download
                v-c-tooltip="`${$t('label.download')} EXCEL`"
              >
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButton
                shape="square"
                class="mr-1"
                color="wipe"
                size="sm"
                :disabled="isDeactivate"
                v-c-tooltip="`${$t('label.delete')}`"
                @click="deleteConfirmation(item)"
              >
                <CIcon name="cil-trash" />
              </CButton>
            </CButtonGroup>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</div>
</template>

<script>
import { DateFormater, alertPropertiesHelpers } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import General from "@/_mixins/general";
import MetalScrap from '@/_mixins/metalscrap';
import { mapState } from "vuex";
import Reportes from "@/_mixins/reportes";

import ErrorsModal from "./errors-modal";

function fieldsHistorical() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      _classes: "text-center",
      filter: false,
    },
    { key: "FileName", label: this.$t("label.filename") },
    { key: "ClientName", label: this.$t("label.client") },
    { key: "Weight", label: this.$t("label.netWeight") },
    { key: "RightRows", label: this.$t("label.rightRows") },
    { key: "WrongRows", label: this.$t("label.wrongRows") },
    { key: "Usuario", label: this.$t("label.user") },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableHistoricalClasses() {
  return {
    Nro: "align-middle",
    FileName: "align-middle",
    ClientName: "align-middle",
    Weight: "align-middle",
    RightRows: "align-middle",
    WrongRows: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    items: [],
    loadingHistorical: false,
    isDeactivate: false,
    loading:  false,
    orderFile: [],
    fileName: "",
    errosModal: false,
    orderFileId: '',
  };
}

//METHOD
function getHistoricalList() {
  this.loadingHistorical = true;

  this.$http
    .get("OrderFile-List",{
      CompanyBranchId: this.getBranchId,
    })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingHistorical = false;
    });
}
function deleteConfirmation(item) {
  this.$swal
    .fire(
      alertPropertiesHelpers(this, {
        text: `${this.$t("label.deactivateFile")}`,
      })
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.deactivateFile(item);
      }
    });
}
function deactivateFile(item) {
  this.isDelete = true;

  let FileJson = {
    OrderFileId: item.OrderFileId,
  };

  this.$http
    .put("OrderFile-Deactivate", FileJson, {
      root: "FileJson",
    })
    .then((response) => {
      this.$notify({
        group: "container",
        title: "¡Exito!",
        text: response.data.data[0].Response,
        type: "success",
      });
      this.getHistoricalList();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isDelete = false;
    });
}

function toggleErrors(item) {
  this.orderFileId = item.OrderFileId;
  this.errosModal = true;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function getFile(ruta) {
  return `${this.$store.getters["connection/getBase"]}${ruta}`
}

function checkValue(value){
  return value ? value : 'N/A';
}

// COMPUTED
function formatedHistorical() {
  return this.items.map((historical) =>
    Object.assign({}, historical, {
      Nro: historical.Nro,
      FileName: this.checkValue(historical.FileName),
      ClientName: this.checkValue(historical.ClientName),
      Weight: this.checkValue(formatMilDecimal(historical.Weight)),
      RightRows: this.checkValue(historical.Correct),
      WrongRows: this.checkValue(historical.Error),
      Usuario: historical.TransaLogin ? historical.TransaLogin : "N/A",
      Fecha: historical.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(historical.TransaRegDate)
        : "N/A",
      Status: historical.Status,
      _classes: historical.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableHistoricalClasses,
    })
  );
}

export default {
  name: "historical-tab",
  mixins: [General, Reportes, MetalScrap],
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data,
  components: {
    ErrorsModal,
  },
  methods: {
    getHistoricalList,
    deleteConfirmation,
    deactivateFile,
    toggleErrors,
    getFile,
    checkValue,
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 2) {
        this.getHistoricalList();
      }
    },
  },
  computed: {
    fieldsHistorical,
    cellTableHistoricalClasses,
    formatedHistorical,
    getBranchId,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
  created() {},
  mounted() {
    this.getHistoricalList();
  },
};
</script>
<style scoped>
</style>