<template>
    <div class="table-responsive col-sm-12 col-lg-12">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <table class="table table-bordered text-center">
            <tbody>
                <tr>
                    <td>
                        <h2>{{ $t('label.OperationSituationOptions.Productivity') }}</h2>
                    </td>
                    <td class="text-left" style="background: #d9e2f3;">
                        <CCol>
                            <strong>{{ $t('label.MovementsPerHourNet') }}:
                                {{ HeaderJson[0]?.MovsPerHourNet?HeaderJson[0].MovsPerHourNet:0 }}
                            </strong>
                        </CCol>
                        <CCol>
                            <strong>{{ $t('label.MovementsPerHourGross') }}:
                                {{ HeaderJson[0]?.MovsPerHourGross?HeaderJson[0].MovsPerHourGross:0 }}
                            </strong>
                        </CCol>
                        <CCol>
                            <strong>{{ $t('label.MovementsPerHourTerminal') }}:
                                {{ HeaderJson[0]?.MovsPerHourTerminal?HeaderJson[0].MovsPerHourTerminal:0 }}
                            </strong>
                        </CCol>
                    </td>                                                                                             
                </tr>
                
            </tbody>
        </table>
        <CRow>
            <CCol sm="12" lg="3" xl="3">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-auto col-xl-auto text-right', input: 'col-sm-12 col-lg-8 col-xl-8'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"         
                >
                </CInput>  
            </CCol>
            <CCol sm="12" lg="5"></CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                >
                </CSelect>
            </CCol>
        </CRow>
        <ag-grid-vue
            v-if="showGrid"
            style="width: 100%; height: 100vh;"
            class="ag-theme-alpine"
            :gridOptions="gridOptions"
            :defaultColDef="defaultColDef"
            :localeText="localeText"
            :columnDefs="columnDefs"
            :rowData="RowData"
            :suppressRowClickSelection="true"
            :groupSelectsChildren="true"
            :enableRangeSelection="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :paginationNumberFormatter="paginationNumberFormatter"
            rowSelection="single"
            @grid-ready="onGridReady"
        >
        </ag-grid-vue>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import AgGrid from '@/_mixins/ag-grid';

function data() {
    return {
        gridOptions: null,
        defaultColDef: null,
        paginationPageSize: null,
        paginationNumberFormatter: null,
        gridApi: null,
        columnApi: null,

        DetailJson: [],
        HeaderJson: [],
        loadingOverlay: false,
    }
}
//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//methods
function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

//computed
function RowData(){
    if(this.DetailJson.length != 0){
        return this.DetailJson.map((item) => {
            item.MovementsJson.map(Movement =>{
                item['Movement'+Movement.Nro] = Movement.CantMovs;
            });
            item.DelaysJson.map(Delay =>{
                item['Delay'+Delay.Nro] = Delay.DelaysDs;
            });
            return item;
        });
    }else{
        return [];
    }
}

function QuantityCrane() {
    if (this.DetailJson.length!=0&&this.DetailJson[0].DelaysJson) {
        return this.DetailJson[0].DelaysJson.length!=0?this.DetailJson[0].DelaysJson.length:0;
    } else {
        return 0
    }
}

function columnDefs(){
    let columnDefs = [
        {
            field: "LapseDateHourRange",
            headerName: this.$t('label.lapse'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
        },
        {
            headerName: this.$t('label.movement'),
            headerClass: 'Movement-header text-white center-cell',
            cellClass: 'center-cell',
            children: this.DetailJson?.[0]?.MovementsJson?.map(item=>{
                return {
                    field: "Movement"+item.Nro,
                    headerName: item.CraneAlias,
                    filter: 'agNumberColumnFilter',
                    headerClass: 'center-cell',
                    cellClass: 'center-cell',
                    lockPosition: true,
                    minWidth: 90,
                    
                }
            }),
        },
        {
            headerName: this.$t('label.Timeslost'),
            headerClass: 'Delay-header text-white center-cell',
            cellClass: 'center-cell',
            children: this.DetailJson?.[0]?.DelaysJson?.map(item=>{
                return {
                    field: "Delay"+item.Nro,
                    headerName: item.CraneAlias,
                    filter: 'agNumberColumnFilter',
                    headerClass: 'center-cell',
                    cellClass: 'center-cell',
                    lockPosition: true,
                    minWidth: 90,
                    
                }
            }),
        },
        {
            field: "Percentage",
            headerName: this.$t('label.Percentage'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            spanHeaderHeight: true
        },
    ]
    return columnDefs;
}

export default {
    name:"time-in-port",
    data,
    beforeMount,
    mixins: [AgGrid],
    props: {
        ProdByDayLapseJson:{
            type: Object,
            default: function () {
                return {} 
            },
        },
    },
    methods:{
        onGridReady,
        onQuickFilterChanged,
        onPageSizeChanged,
    },
    components:{

    },
    computed:{
        RowData,
        columnDefs,
        QuantityCrane,
        ...mapState({
            VisitId: state => state.Management.VisitId,
            CarruselTab: state => state.Management.CarruselTab,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==3) {
                if (Object.entries(this.ProdByDayLapseJson).length !== 0) {
                    this.DetailJson = this.ProdByDayLapseJson.DetailJson;
                    this.HeaderJson = this.ProdByDayLapseJson.HeaderJson;
                }
                if (oldVal==3) {
                    this.DetailJson = [];
                    this.HeaderJson = [];
                }
            }
        },
        showGrid: function (val) {
            if (val) {
                this.loadingOverlay = false;
            } else {
                this.loadingOverlay = true;
            }
        }
    }
}
</script>
<style>
.Movement-header{
    background: #1f3864 !important;
}
.Movement-header .ag-header-group-text{
    width: 100%;
}
.Delay-header{
    background: #ed7d31 !important;
}
.Delay-header .ag-header-group-text{
    width: 100%;
}
.center-cell {
    text-align: center;
    vertical-align: middle;
    user-select: initial;
}
.ag-header-group-cell-label, .ag-header-cell-label{
    text-align: center;
    justify-content: center;
    align-items: center;
}
</style>