<template>
  <div>
    <loading-overlay
      :active="isLoadingData"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="modalTitle"
      color="dark"
      size="xl"
      :close-on-backdrop="false"
      :show.sync="modalActive"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <CRow>
          <CCol sm="12">
            <CCard style="border: none;">
              <CCardBody >
                <CRow class="mb-4">
                  <CCol sm="6"><!-- PRIMERA-->
                    <CRow>
                      <CCol sm="12"><!-- DIRECCION MAC-->
                        <CInput
                          v-model.trim="$v.device.UniqueDeviceId.$model"
                          v-uppercase
                          size="sm"
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :is-valid="hasError($v.device.UniqueDeviceId)"
                          :invalid-feedback="errorMessage($v.device.UniqueDeviceId)"
                          :label="$t('label.UniqueId')"
                          addLabelClasses="required text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="16"
                        >
                        </CInput>
                      </CCol>
                      <CCol sm="12"><!-- NIVEL DE PREFERENCIA-->
                        <div class="form-group form-row" rol="group">
                          <label
                            class="required text-right adjust-label col-form-label col-sm-3 col-form-label-sm"
                            for="type"
                          >
                            {{$t('label.levelPreference')}}
                          </label>
                          <div class="col-sm-9 input-group-sm adjust-input-device">
                            <v-select
                              :options="levelOptions"
                              label="LevelPreferenceName"
                              :placeholder="$t('label.toSelect')"
                              v-model.trim="level"
                              :value.sync="level"
                              :class="computedSelectLevelPreference ? 'select--Level--correct' : (verifySelect ? 'select--Level--error' : '') "
                              :disabled="isArrEmpty(levels)"
                              style="width: 100%;"
                              id="v-select-small"
                              size="sm"
                            />
                            <div v-if="$v.level.$error">
                              <div class='text-danger text' v-if="!$v.level.required">
                                {{$t('label.required')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="12"><!-- CONDICION -->
                        <div class="form-group form-row" rol="group">
                          <label
                            class="required text-right adjust-label col-form-label col-sm-3 col-form-label-sm"
                            for="type"
                          >
                            {{$t('label.condition')}}
                          </label>
                          <div class="col-sm-9 input-group-sm adjust-input-device">
                            <v-select
                              :options="conditionOptions"
                              label="ValueDs"
                              :placeholder="$t('label.toSelect')"
                              v-model.trim="condition"
                              :value.sync="condition"
                              :class="computedSelectCondition ? 'select--condition--correct' : (verifySelect ? 'select--condition--error' : '') "
                              :disabled="isArrEmpty(conditions)"
                              style="width: 100%;"
                              id="v-select-small"
                              size="sm"
                            />
                            <div v-if="$v.condition.$error">
                              <div class='text-danger text' v-if="!$v.condition.required">
                                {{$t('label.required')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>                     
                      <CCol sm="12"><!-- NOMBRE -->
                        <CInput
                          v-model.trim="$v.device.DeviceName.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :invalid-feedback="errorMessage($v.device.DeviceName)"
                          :is-valid="hasError($v.device.DeviceName)"
                          size="sm"
                          :label="$t('label.name')"
                          addLabelClasses="required text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="50"
                        />
                      </CCol>
                      <CCol sm="12"><!--MODELO-->
                        <CInput
                          v-model.trim="$v.device.ModelName.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :invalid-feedback="errorMessage($v.device.ModelName)"
                          :is-valid="hasError($v.device.ModelName)"
                          size="sm"
                          :label="$t('label.model')"
                          addLabelClasses="text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="20"
                        />
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol sm="6"><!-- SEGUNDA-->
                    <CRow>     
                      <CCol sm="12"><!-- CODE -->
                        <CInput
                          v-model.trim="$v.device.Cod.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                          :invalid-feedback="errorMessage($v.device.Cod)"
                          :is-valid="hasError($v.device.Cod)"
                          size="sm"
                          :label="$t('label.code')"
                          addLabelClasses="required text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="10"
                        />
                      </CCol>   
                      <CCol sm="12"><!-- TIPO -->
                        <div class="form-group form-row" rol="group">
                          <label
                            class="required text-right adjust-label col-form-label col-sm-3 col-form-label-sm"
                            for="type"
                          >
                            {{ $t('label.type') }}
                          </label>
                          <div class="col-sm-8 input-group-sm adjust-input-device">
                            <v-select
                              :options="typeOptions"
                              label="DeviceTpName"
                              :placeholder="$t('label.toSelect')"
                              v-model.trim="type"
                              :value.sync="type"
                              :class="computedSelectType ? 'select--type--correct' : (verifySelect ? 'select--type--error' : '') "
                              style="width: 100%;"
                              id="v-select-small"
                              size="sm"
                              :disabled="isArrEmpty(types)"
                            />
                            <div v-if="$v.type.$error">
                              <div class='text-danger text' v-if="!$v.type.required">
                                {{$t('label.required')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="12"><!-- MARCA -->
                        <div class="form-group form-row" rol="group">
                          <label
                            class="required text-right adjust-label col-form-label col-sm-3 col-form-label-sm"
                            for="type"
                          >
                            {{$t('label.brand')}}
                          </label>
                          <div class="col-sm-8 input-group-sm adjust-input-device">
                            <v-select
                              :options="brandOptions"
                              label="BrandName"
                              :placeholder="$t('label.toSelect')"
                              v-model.trim="brand"
                              :value.sync="brand"
                              :class="computedSelectBrand ? 'select--brand--correct' : (verifySelect ? 'select--brand--error' : '') "
                              style="width: 100%;"
                              id="v-select-small"
                              size="sm"
                              :disabled="isArrEmpty(brands)"
                            />
                            <div v-if="$v.brand.$error">
                              <div class='text-danger text' v-if="!$v.brand.required">
                                {{$t('label.required')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="12"><!-- SISTEMA OPERATIVO -->
                        <CInput
                          v-model.trim="$v.device.OperatingSystem.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :invalid-feedback="errorMessage($v.device.OperatingSystem)"
                          :is-valid="hasError($v.device.OperatingSystem)"
                          size="sm"
                          :label="$t('label.operatingSystem')"
                          addLabelClasses="text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="20"
                        />
                      </CCol>
                      <CCol sm="12"><!-- FABRICANTE -->
                        <CInput
                          v-model.trim="$v.device.Manufacturer.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :invalid-feedback="errorMessage($v.device.Manufacturer)"
                          :is-valid="hasError($v.device.Manufacturer)"
                          size="sm"
                          :label="$t('label.manufacturer')"
                          addLabelClasses="text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="50"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="6"><!-- tercera-->
                    <CRow>
                      
                      <CCol sm="7" class="pl-1">
                        <CInput
                          size="sm"
                          ref="storageInput"
                          :label="$t('label.storageCapacity')"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.StorageCapacity"
                          @focus="$v.device.StorageCapacity.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.StorageCapacity)"
                          :is-valid="hasError($v.device.StorageCapacity)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="storage"
                              :options="getMeasureOptions(memoryUnits)"
                              :addInputClasses="'not-border adjust-number-select'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>
                      <CCol sm="5" class="pl-0 pr-3">
                        <CInput
                          size="sm"
                          label="RAM"
                          ref="ramInput"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.MemoryInternal"
                          @focus="$v.device.MemoryInternal.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.MemoryInternal)"
                          :is-valid="hasError($v.device.MemoryInternal)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="ram"
                              :options="getMeasureOptions(memoryUnits)"
                              :addInputClasses="'not-border adjust-number-select'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>

                      <CCol sm="7" class="pl-1">
                        <CInput
                          size="sm"
                          ref="sizeInput"
                          :label="$t('label.screenSize')"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.ScreenSize"
                          @focus="$v.device.ScreenSize.$touch()"
                          maxlength="20"
                          :invalid-feedback="errorMessage($v.device.ScreenSize)"
                          :is-valid="hasError($v.device.ScreenSize)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="size"
                              :options="getMeasureOptions(sizeUnits)"
                              :addInputClasses="'not-border adjust-number-select pr-2'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>
                      <CCol sm="5" class="pl-0 pr-3">
                        <CInput
                          size="sm"
                          ref="weightInput"
                          :label="$t('label.weight')"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.Weigth"
                          @focus="$v.device.Weigth.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.Weigth)"
                          :is-valid="hasError($v.device.Weigth)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="peso"
                              :options="getMeasureOptions(weightUnits)"
                              :addInputClasses="'not-border adjust-number-select'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>


                      <CCol sm="7" class="pl-1">
                        <CInput
                          size="sm"
                          ref="largoInput"
                          :label="$t('label.largo')"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.Heigth"
                          @focus="$v.device.Heigth.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.Heigth)"
                          :is-valid="hasError($v.device.Heigth)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="largo"
                              :options="getMeasureOptions(sizeUnits)"
                              :addInputClasses="'not-border adjust-number-select pr-2'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>
                      <CCol sm="5" class="pl-0 pr-3">
                        <CInput
                          size="sm"
                          ref="anchoInput"
                          :label="$t('agridTable.width')"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.Width"
                          @focus="$v.device.Width.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.Width)"
                          :is-valid="hasError($v.device.Width)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="ancho"
                              :options="getMeasureOptions(sizeUnits)"
                              :addInputClasses="'not-border adjust-number-select pr-2'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>

                      <CCol sm="7" class="pl-1">
                        <CInput
                          size="sm"
                          :label="$t('label.resolution')"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-model.trim="device.ResolutionScreen"
                          @focus="$v.device.ResolutionScreen.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.ResolutionScreen)"
                          :is-valid="hasError($v.device.ResolutionScreen)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="resolucion"
                              :options="getMeasureOptions(resolutionUnits)"
                              :addInputClasses="'not-border adjust-number-select pl-select'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>
                      <CCol sm="5" class="pl-0 pr-3">
                        <CInput
                          size="sm"
                          ref="depthInput"
                          :label="$t('label.depth')"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                          placeholder="0"
                          addLabelClasses="text-right"
                          addInputClasses="not-padding-right"
                          v-money="measure"
                          v-model.trim="device.Depth"
                          @focus="$v.device.Depth.$touch()"
                          maxlength="9"
                          :invalid-feedback="errorMessage($v.device.Depth)"
                          :is-valid="hasError($v.device.Depth)"
                          required
                        >
                          <template #append>
                            <CSelect
                              size="sm"
                              :value.sync="espesor"
                              :options="getMeasureOptions(sizeUnits)"
                              :addInputClasses="'not-border adjust-number-select pr-2'"
                              class="mb-0"
                            />
                          </template>
                        ></CInput>
                      </CCol>


<!-- COLOR -->
                      <CCol sm="7" class="pl-1">
                        <CInput
                          :label="$t('label.color')"
                          addLabelClasses="text-right"
                          addWrapperClasses="adjust-input-device"
                          v-uppercase
                          type="color"
                          size="sm"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6' }"
                          v-model="$v.device.Color.$model"
                          :is-valid="hasError($v.device.Color)"
                          :invalid-feedback="errorMessage($v.device.Color)"
                          required
                        />
                      </CCol>
                      <CCol sm="5" class="pl-0 pr-3">
                        <!-- ESTATUS -->
                        <div v-if="edit">
                          <CSelect
                          :value.sync="statusDevice"
                          :is-valid="statusSelectColor"
                          :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                          size="sm"
                          :label="$t('label.status')"
                          :options="statusOptions"
                          addLabelClasses="text-right"
                          addWrapperClasses="adjust-input-device"
                        />
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>   
                  <CCol sm="6"><!-- cuarta-->
                    <CRow>
                      <CCol sm="12"><!-- IMEI -->
                        <CInput
                          v-model.trim="$v.device.Imei.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                          :invalid-feedback="errorMessage($v.device.Imei)"
                          :is-valid="hasError($v.device.Imei)"
                          size="sm"
                          :label="$t('label.codImei')"
                          addLabelClasses="text-right adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="15"
                        />
                      </CCol>
                      <CCol sm="12"><!-- Observaciones -->
                        <CTextarea
                          v-model="$v.device.Observation.$model"
                          v-uppercase
                          :horizontal="{ label: 'col-sm-4 text-right', input: 'col-sm-8' }"
                          :invalid-feedback="errorMessage($v.device.Observation)"
                          :is-valid="hasError($v.device.Observation)"
                          size="sm"
                          rows="4"
                          :label="$t('label.observation')"
                          addLabelClasses="adjust-label"
                          addWrapperClasses="adjust-input-device"
                          maxlength="240"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </form>
      <template #footer>
        <CButton
          v-if="!edit"
          shape="square"
          color="add"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          shape="square"
          color="add"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          shape="square"
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x" /><span class="ml-1">{{ $t("button.cancel") }}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DeviceValidations from "@/_validations/inventario/DeviceFormValidations";
import ModalMixin from "@/_mixins/modal";
import UpperCase from '@/_validations/uppercase-directive';
import { VMoney, Money } from "v-money";

const initCounter = 30;

//Data
function data() {
  return {
    modalActive: false,
    isSubmit: false,
    isLoadingData: false,
    verifySelect: false,
    device: {
      UniqueDeviceId: "",
      DeviceName: "",
      Cod: "",
      TpDeviceId: "",
      OperatingSystem: "",
      BrandId: "",
      ModelName: "",
      Manufacturer: "",
      MemoryInternal: "",
      StorageCapacity: "",
      LevelPreferenceId: "",
      Heigth: "",
      Width: "",
      Weigth: "",
      Depth: "",
      ScreenSize: "",
      ResolutionScreen: "",
      Imei: "",
      DeviceStatus: "",
      Color: "#000000",
      Observation: "",
    },
    statusDevice: 1,
    types: [],
    type: null,
    brands: [],
    brand: null,
    conditions: [],
    condition: null,
    levels: [],
    level: null,
    memoryUnits: [],
    resolutionUnits: [],
    sizeUnits: [],
    weightUnits: [],
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    // Unidades medidas
    ram: '525567B0-FCFA-EB11-A872-000D3A0DEDC7',
    storage: '525567B0-FCFA-EB11-A872-000D3A0DEDC7',
    size: '2658A273-FFFA-EB11-A872-000D3A0DEDC7',
    largo: 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B',
    ancho: 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B',
    espesor: 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B',
    resolucion: '93642CE0-FDFA-EB11-A872-000D3A0DEDC7',
    peso: 'AEE3354C-ADD5-427C-9E86-1C7826ADF31F',
  };
}

//Methods
function loadData() {
  this.isLoadingData = true;

  let peticiones = [
    this.$http.ejecutar("GET", "UnitMeasureByTpUnitMeasureId", {
      TpUnitMeasureId: "d69eae6e-fcfa-eb11-a872-000d3a0dedc7",
      filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "UnitMeasureByTpUnitMeasureId", {
      TpUnitMeasureId: "95570ab8-fdfa-eb11-a872-000d3a0dedc7",
      filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "UnitMeasureDeviceSize-list", {
      filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "UnitMeasureDeviceWeigh-list", {
      filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "TpDevice-list", {
      Filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "BrandDevice-list", {
      Filter: "ACTIVO",
    }),
    this.$http.ejecutar("GET", "DeviceStatus-list"),
    this.$http.ejecutar("GET", "DeviceLevelPreference-list"),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.memoryUnits = responses[0].data.data; // MEMORIA RAM, MEMORIA INTERNA
      this.resolutionUnits = responses[1].data.data; // RESOLUCION
      this.sizeUnits = responses[2].data.data; // LARGO, ANCHO, ESPESOR, TAMAÑO PANTALLA
      this.weightUnits = responses[3].data.data; // PESO
      this.types = responses[4].data.data;
      this.brands = responses[5].data.data;
      this.conditions = responses[6].data.data;
      this.levels = responses[7].data.data;
      if(this.edit) {
        this.type = this.types.find((item) => item.TpDeviceId == this.device.TpDeviceId);
        this.brand = this.brands.find((item) => item.BrandId == this.device.BrandId);
        this.condition = this.conditions.find((item) => item.ProcessStatusId == this.device.DeviceStatus);
        this.level = this.levels.find((item) => item.LevelPreferenceId == this.device.LevelPreferenceId);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingData = false;
    });
}
function submit() {
  try {
    this.verifySelect = true;
    this.$v.$touch();
    if (this.$v.device.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.isSubmit = true;
    let DeviceJson = this.formatedData();

    this.$http.ejecutar('POST', 'Device-insert', DeviceJson, { root: 'DeviceJson'} )
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch(e) {
    this.notifyError({ text: e });
  }
}
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else{
    this.loadData();
    if(newVal && this.edit){
      this.verifySelect=true;
      this.setData(this.deviceData);
    }
  }

  this.modalActive = newVal;
}
function resetForm() {
  this.device.UniqueDeviceId = "";
  this.device.DeviceName = "";
  this.device.Cod = "";
  this.device.TpDeviceId = "";
  this.device.OperatingSystem = "";
  this.device.BrandId = "";
  this.device.ModelName = "";
  this.device.Manufacturer = "";
  this.device.MemoryInternal = "";
  this.device.StorageCapacity = "";
  this.device.LevelPreferenceId = "";
  this.device.Heigth = "";
  this.device.Width = "";
  this.device.Weigth = "";
  this.device.Depth = "";
  this.device.ScreenSize = "";
  this.device.ResolutionScreen = "";
  this.device.Imei = "";
  this.device.DeviceStatus = "";
  this.device.Color = "#000000";
  this.device.Observation = "";
  this.statusDevice = 1;
  this.types = [];
  this.type = null;
  this.brands = [];
  this.brand = null;
  this.conditions = [];
  this.condition = null;
  this.levels = [];
  this.level = null;
  this.memoryUnits = [];
  this.resolutionUnits = [];
  this.sizeUnits = [];
  this.weightUnits = [];
  this.verifySelect=false;
  // Unidades medidas
  this.ram = '525567B0-FCFA-EB11-A872-000D3A0DEDC7';
  this.storage = '525567B0-FCFA-EB11-A872-000D3A0DEDC7';
  this.size = '2658A273-FFFA-EB11-A872-000D3A0DEDC7';
  this.largo = 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.ancho = 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.espesor = 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.resolucion = '93642CE0-FDFA-EB11-A872-000D3A0DEDC7';
  this.peso = 'AEE3354C-ADD5-427C-9E86-1C7826ADF31F';
  //Set medidas en inputs:
  let inputRam = this.$refs.ramInput.$el.querySelector("input");
  inputRam.value = "";
  let inputSize = this.$refs.sizeInput.$el.querySelector("input");
  inputSize.value = "";
  let inputStorage = this.$refs.storageInput.$el.querySelector("input");
  inputStorage.value = "";
  let inputLargo = this.$refs.largoInput.$el.querySelector("input");
  inputLargo.value = "";
  let inputAncho = this.$refs.anchoInput.$el.querySelector("input");
  inputAncho.value = "";
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = "";
  let inputDepth = this.$refs.depthInput.$el.querySelector("input");
  inputDepth.value = "";
  this.$v.$reset();
}

function isArrEmpty(arr) {
  return arr.length == 0;
}
function getMeasureOptions(arr) {
  return arr.map((item) =>
    Object.assign({}, item, {
      label: item.UnitMeasureAbbrev,
      value: item.UnitMeasureId,
    })
  );
}
function setData(deviceInfo) {
  if(!deviceInfo)
    return;

  this.device.UniqueDeviceId = deviceInfo.UniqueDeviceId ? deviceInfo.UniqueDeviceId : '';
  this.device.DeviceName = deviceInfo.DeviceName ? deviceInfo.DeviceName : '';
  this.device.Cod = deviceInfo.Cod ? deviceInfo.Cod : '';
  this.device.TpDeviceId = deviceInfo.TpDeviceId ? deviceInfo.TpDeviceId : '';
  this.device.OperatingSystem = deviceInfo.OperatingSystem ? deviceInfo.OperatingSystem : '';
  this.device.BrandId = deviceInfo.BrandId ? deviceInfo.BrandId : '';
  this.device.ModelName = deviceInfo.ModelName ? deviceInfo.ModelName : '';
  this.device.Manufacturer = deviceInfo.Manufacturer ? deviceInfo.Manufacturer : '';
  this.device.MemoryInternal = deviceInfo.MemoryInternal ? Number.parseFloat(deviceInfo.MemoryInternal).toFixed(2) : '';
  this.device.StorageCapacity = deviceInfo.StorageCapacity ? Number.parseFloat(deviceInfo.StorageCapacity).toFixed(2) : '';
  this.device.Heigth = deviceInfo.Heigth ? Number.parseFloat(deviceInfo.Heigth).toFixed(2) : '';
  this.device.Width = deviceInfo.Width ? Number.parseFloat(deviceInfo.Width).toFixed(2) : '';
  this.device.Weigth = deviceInfo.Weigth ? Number.parseFloat(deviceInfo.Weigth).toFixed(2) : '';
  this.device.Depth = deviceInfo.Depth ? Number.parseFloat(deviceInfo.Depth).toFixed(2) : '';
  this.device.ScreenSize =  deviceInfo.ScreenSize ? Number.parseFloat(deviceInfo.ScreenSize).toFixed(2) : '';
  this.device.ResolutionScreen = deviceInfo.ResolutionScreen ? deviceInfo.ResolutionScreen : '';
  this.device.Imei = deviceInfo.Imei ? deviceInfo.Imei : '';
  this.device.DeviceStatus = deviceInfo.DeviceStatus ? deviceInfo.DeviceStatus : '';
  this.device.Color = deviceInfo.Color ? deviceInfo.Color : '';
  this.device.Observation = deviceInfo.Observation ? deviceInfo.Observation : '';
  this.device.LevelPreferenceId = deviceInfo.LevelPreferenceId ? deviceInfo.LevelPreferenceId : '';
  this.statusDevice = deviceInfo.FgActDevice ? 1 : 0;

  // Unidades medidas
  this.ram = deviceInfo.UnitMeasureMemoryId ? deviceInfo.UnitMeasureMemoryId : '525567B0-FCFA-EB11-A872-000D3A0DEDC7';
  this.storage = deviceInfo.UnitMeasureStorageId ? deviceInfo.UnitMeasureStorageId : '525567B0-FCFA-EB11-A872-000D3A0DEDC7';
  this.size = deviceInfo.UnitMeasureScreenId ? deviceInfo.UnitMeasureScreenId : '2658A273-FFFA-EB11-A872-000D3A0DEDC7';
  this.largo = deviceInfo.UnitMeasureHeigthId ? deviceInfo.UnitMeasureHeigthId : 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.ancho = deviceInfo.UnitMeasureWidthId ? deviceInfo.UnitMeasureWidthId : 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.espesor = deviceInfo.UnitMeasureDepthId ? deviceInfo.UnitMeasureDepthId : 'C25EAD1E-74B9-4DBD-BF7F-4BD77AA2CD6B';
  this.resolucion = deviceInfo.UnitMeasureResolutionId ? deviceInfo.UnitMeasureResolutionId : '93642CE0-FDFA-EB11-A872-000D3A0DEDC7';
  this.peso = deviceInfo.UnitMeasureWeigthId ? deviceInfo.UnitMeasureWeigthId : 'AEE3354C-ADD5-427C-9E86-1C7826ADF31F';

  //Set medidas en inputs:
  let inputRam = this.$refs.ramInput.$el.querySelector("input");
  inputRam.value = this.device.MemoryInternal ? this.device.MemoryInternal : '';
  let inputSize = this.$refs.sizeInput.$el.querySelector("input");
  inputSize.value = this.device.ScreenSize ? this.device.ScreenSize : '';
  let inputStorage = this.$refs.storageInput.$el.querySelector("input");
  inputStorage.value = this.device.StorageCapacity ? this.device.StorageCapacity : '';
  let inputLargo = this.$refs.largoInput.$el.querySelector("input");
  inputLargo.value = this.device.Heigth ? this.device.Heigth : '';
  let inputAncho = this.$refs.anchoInput.$el.querySelector("input");
  inputAncho.value = this.device.Width ? this.device.Width : '';
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = this.device.Weigth ? this.device.Weigth : '';
  let inputDepth = this.$refs.depthInput.$el.querySelector("input");
  inputDepth.value = this.device.Depth ? this.device.Depth : '';

  this.$v.$touch();
}

function formatNumericValue(val) {
  return parseFloat(val.replace(/[.]/g, '')?.replace(',', '.')).toFixed(2);
}
function formatedData() {
  return {
    ...this.device,
    MemoryInternal: this.formatNumericValue(this.device.MemoryInternal),
    StorageCapacity: this.formatNumericValue(this.device.StorageCapacity),
    Heigth: this.formatNumericValue(this.device.Heigth),
    Width: this.formatNumericValue(this.device.Width),
    Weigth: this.formatNumericValue(this.device.Weigth),
    Depth: this.formatNumericValue(this.device.Depth),
    ScreenSize: this.formatNumericValue(this.device.ScreenSize),
    Status: this.statusDevice,
    UnitMeasureScreen: this.size,
    UnitMeasureResolution: this.resolucion,
    UnitMeasureMemory: this.ram,
    UnitMeasureStorage: this.storage,
    UnitMeasureHeigth: this.largo,
    UnitMeasureWeigth: this.peso,
    UnitMeasureWidth: this.ancho,
    UnitMeasureDepth: this.espesor,
  };
}
function confirmation() {
  try {
    this.verifySelect = true;
    this.$v.device.$touch();
    if (this.$v.device.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.statusConfirmation(this.deviceData.FgActDevice, this.statusDevice, this.submit);
  } catch(e) {
    this.notifyError({ text: e });
  }
}

// Computeds
function typeOptions() {
  return this.types.map((item) =>
    Object.assign({}, item, {
      label: item.DeviceTpName,
      value: item.TpDeviceId,
    })
  );
}
function isTypeValid() {
  return {
    "invalid-feedback": true,
    invalid: this.type
      ? null
      : this.$v.$anyDirty
      ? this.$v.type.$invalid
      : null,
  };
}
function brandOptions() {
  return this.brands.map((item) =>
    Object.assign({}, item, {
      label: item.BrandName,
      value: item.BrandId,
    })
  );
}
function isBrandValid() {
  return {
    "invalid-feedback": true,
    invalid: this.brand
      ? null
      : this.$v.$anyDirty
      ? this.$v.brand.$invalid
      : null,
  };
}
function conditionOptions() {
  return this.conditions.map((item) =>
    Object.assign({}, item, {
      label: item.ValueDs,
      value: item.ProcessStatusId,
    })
  );
}
function isConditionValid() {
  return {
    "invalid-feedback": true,
    invalid: this.condition
      ? null
      : this.$v.$anyDirty
      ? this.$v.condition.$invalid
      : null,
  };
}
function levelOptions() {
  return this.levels.map((item) =>
    Object.assign({}, item, {
      label: item.LevelPreferenceName,
      value: item.LevelPreferenceId,
    })
  );
}
function isLevelValid() {
  return {
    "invalid-feedback": true,
    invalid: this.level
      ? null
      : this.$v.$anyDirty
      ? this.$v.level.$invalid
      : null,
  };
}
function changeType() {
  return this.type ? this.type.TpDeviceId : null;
}
function changeBrand() {
  return this.brand ? this.brand.BrandId : null;
}
function changeCondition() {
  return this.condition ? this.condition.ProcessStatusId : null;
}
function changeLevel() {
  return this.level ? this.level.LevelPreferenceId : null;
}
function statusSelectColor(){
  return this.statusDevice === 1;
}
function modalTitle() {
  return !this.edit ? `${this.$t('label.nuevo')} ${this.$t('label.device')}`: `${this.$t('label.editDevice')}: ${this.device.DeviceName}`;
}

function computedSelectLevelPreference() {
  if (this.level === null||this.level === '') {
    return false;
  }else{
    return true;
  }
}

function computedSelectCondition() {
  if (this.condition === null||this.condition === '') {
    return false;
  }else{
    return true;
  }
}
function computedSelectType() {
  if (this.type === null||this.type === '') {
    return false;
  }else{
    return true;
  }
}
function computedSelectBrand() {
  if (this.brand === null||this.brand === '') {
    return false;
  }else{
    return true;
  }
}

export default {
  name: "device-modal",
  mixins: [ModalMixin],
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    deviceData: {
      type: Object,
      default: null,
    },
  },
  data,
  components: {

  },
  validations: DeviceValidations,
  directives: {
    money: VMoney,
  },
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    loadData,
    isArrEmpty,
    getMeasureOptions,
    formatNumericValue,
    formatedData,
    setData,
    confirmation,
  },
  computed: {
    typeOptions,
    brandOptions,
    conditionOptions,
    isTypeValid,
    isBrandValid,
    isConditionValid,
    changeType,
    changeBrand,
    changeCondition,
    statusSelectColor,
    modalTitle,
    levelOptions,
    isLevelValid,
    changeLevel,
    computedSelectLevelPreference,
    computedSelectCondition,
    computedSelectType,
    computedSelectBrand,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);  
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    changeType: function (val) {
      if (val) this.device.TpDeviceId = val;
      else this.device.TpDeviceId = "";
    },
    changeBrand: function (val) {
      if (val) this.device.BrandId = val;
      else this.device.BrandId = "";
    },
    changeCondition: function (val) {
      if (val) this.device.DeviceStatus = val;
      else this.device.DeviceStatus = "";
    },
    changeLevel: function (val) {
      if (val) this.device.LevelPreferenceId = val;
      else this.device.LevelPreferenceId = "";
    },
  },
};
</script>

<style scoped >
.not-padding-right {
  padding-right: 0 !important;
}
.not-border{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 0px;
}
.qr-code-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qr-code-bg {
  width: 200px;
  height: 200px;
  background-color: rgb(231, 231, 231);
}
 html:not([dir="rtl"]) .was-validated .form-control:valid,
 html:not([dir="rtl"]) .form-control.is-valid {
   padding-right: 0 !important;
 }

 .adjust-label,
 .adjust-label.col-form-label-sm {
   max-width: 145px !important;
   min-width: 145px !important;
 }
 .adjust-input-device {
   max-width: 72% !important;
   flex: 1 !important;
 }
 .adjust-left,
 .adjust-left.col-form-label-sm {
   max-width: 145px !important;
   min-width: 145px !important;
 }

 .limit-col-right {
   max-width: 32%;
   flex: 0 0 32%;
 }
 .limit-col-left {
   max-width: 18%;
 }
 .adjust-number {
   max-width: 62px !important;
 }
 .adjust-number-select {
   padding: 0;
 }
 
</style>

<style lang="scss">
.select--Level--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
  .vs__clear{
    border-color: #2eb85c !important;
  }
}
.select--Level--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
  .vs__actions{
    border-color: #e55353 !important;
  }
}
.text{
   font-size: 11.2px !important;
}

.select--condition--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
  .vs__actions{
    border-color: #2eb85c !important;
  }
}
.select--condition--error {
  .vs__dropdown-toggle {
      border-color: #e55353 !important;
  }
  .vs__actions{
    border-color: #e55353 !important;
  }
}

.select--type--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
  .vs__actions{
    border-color: #2eb85c !important;
  }
}
.select--type--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  } 
  .vs__actions{
    border-color: #e55353 !important;
  }
}

.select--brand--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
  .vs__actions{
    border-color: #2eb85c !important;
  }
}
.select--brand--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
  .vs__actions{
    border-color: #e55353 !important;
  }
}

.pl-select{
  padding-right: 12px !important;
}


</style>