import {
  required,
  helpers,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

import { textareaSpecials } from '../funciones';

const alphaNumCustom = (value) => !helpers.req(value) || /^[\w\u00f1\u00d1\-]+$/g.test(value);
const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);

export default () => {
  return {
    cancelInfo: {
      MovStowageReasonDs: { textareaSpecials },
      TransactionDate: { },
      MovStowageReasonId: { required },
    },
  }
}