<template>
  <div :class="wrapperClasses">
    <input 
        :class="isInValid ? 'form-control is-invalid' : 'form-control'"
        :placeholder="placeHolder"
        type="text"
        @input="onChange"
        v-model="search"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter.prevent="onEnter"
    />
    <ul
        id="autocomplete-results"
        v-show="isOpen"
        :class="resultWrapperClasses"
    >
        <li
            class="loading"
            v-if="isLoading"
        >
            Cargando resultados...
        </li>
        <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            class="autocomplete-result"
            :class="{ 'is-active': i === arrowCounter }"
        >
            {{ result.label }}
        </li>
    </ul>
  </div>
</template>

<style>

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 120px;
        overflow: auto;
        width: 100%;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: #4AAE9B;
        color: white;
    }

    .absolute {
        position: absolute;
        padding: 10px;
        background: #fff;
        z-index: 100;
    }
</style>

<script>
  export default {
    name: 'autocomplete',

    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false,
        },
        isInValid: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeHolder: {
            type: String,
            required: false,
            default: false,
        },
        absolute: {
            type: Boolean,
            required: false,
            default: false
        },
        value:{
            type: String,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isOpen: false,
            results: [],
            search: '',
            isLoading: false,
            arrowCounter: 0,
        };
    },
    computed: {
        wrapperClasses() {
            return {
                'autocomplete': true,
                'position-relative': this.absolute
            }
        },
        resultWrapperClasses() {
            return {
                'autocomplete-results': true,
                'absolute': this.absolute
            }
        }
    },
    methods: {
        onChange() {
            // Let's warn the parent that a change was made
            this.$emit('input', this.search);

            // Is the data given by an outside ajax request?
            if (this.isAsync) {
                this.isLoading = true;
            } 
            else {
                // Let's  our flat array
                this.filterResults();
                this.isOpen = true;
            }
        },

        filterResults() {
            // first uncapitalize all the things
            this.results = this.items.filter((item) => {
                return item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        setResult(result) {
            if(this.absolute) {
                this.$emit('selected', result);
                this.search = '';
            }else {
                this.search = result.label;
                this.$emit('input', result);
            }
            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter -1;
            }
        },
        onEnter() {
            if(this.absolute){
                this.$emit('selected', this.results[this.arrowCounter]);
                this.search = '';
            }else{
                this.$emit('input', this.results[this.arrowCounter]);
                this.search = this.results[this.arrowCounter].label;
            }

            this.isOpen = false;
            this.arrowCounter = -1;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            }
        },
        watch: {
            items: function (val, oldValue) {
                // actually compare them
                if (val.length !== oldValue.length) {
                    this.results = val;
                    this.isLoading = false;
                }
            },
            value:function (newValue){
                this.search = newValue;
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        }
    };
</script>