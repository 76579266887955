<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Quantity="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Quantity, 2) }}
        </td>
      </template>
      <template #FirstEventDate="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item?.FirstEventDate) }}
        </td>
      </template>
      <template #LastEventDate="{ item }">
        <td class="text-center">
          {{ formatDateTimeWithSlash(item?.LastEventDate) }}
        </td>
      </template>
      <template #options="{ item }">
        <td class="text-center">
          <CButton 
            square
            size="sm"
            color="watch"
            v-c-tooltip="{
              content: `${$t('label.see')} ${$t('label.yard')}`,
              placement: 'top-start'
            }"
            @click="SeeUbicationDetailJson(item)"
          >
            <CIcon name='eye' />
          </CButton>
        </td>
      </template>
      <template #details="{item}">
        <CCollapse :show="Boolean(item?.FgCollapse)" class="p-2">
          <CRow class="justify-content-start m-0">
            <CCol sm="12" lg="8" class="px-0">
              <dataTableExtended
                class="align-center-row-datatable"
                column-filter
                :items="computedUbicationDetailJson(item)"
                :fields="fieldUbicationDetail(item?.PackagingId)"
                :table-filter="tableText.tableFilterText"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
              >
                <template #Quantity="{ item }">
                  <td class="text-center">
                    {{ formatNumber(item?.Quantity, 2) }}
                  </td>
                </template>
                <template #Weigth="{ item }">
                  <td class="text-center">
                    {{ formatNumber(item?.Weigth, 2) }}
                  </td>
                </template>
                <template #Volumen="{ item }">
                  <td class="text-center">
                    {{ formatNumber(item?.Volumen, 2) }}
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCollapse>
      </template>
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function SeeUbicationDetailJson(item) {
  this.$store.state.yardManagement.dataBl.UbicationJson[item.Nro-1].FgCollapse = !item?.FgCollapse;
  this.$store.state.yardManagement.dataBl.UbicationJson = [...this.Bl.UbicationJson];
}

function computedUbicationDetailJson(item) {
  let list = item?.UbicationDetailJson ?? [];
  if (item?.PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER) {
    list = [
      ...list, 
      ...item?.UbicationSlotJson?.map(e => {
        return {
          ...e,
          YardAreaName: e.SlotCode
        }
      })
    ]
  }
  return list.map((e) => {
    return {
      ...e,
      Serial: e?.Serial ? e?.Serial : 'N/A',
    }
  })
}

function fieldUbicationDetail(PackagingId){
  if (PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER) {
    return [
      { key: 'YardAreaName', label: this.$t('label.location'), _classes: 'text-uppercase text-center', _style: 'width: 20%'},
      { key: 'Serial', label: this.$t('label.serial'), _classes: 'text-uppercase text-center', _style: 'width: 20%'},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-center', _style: 'width: 20%'},
      { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-center', _style: 'width: 20%'},
      { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _classes: 'text-center', _style: 'width: 20%'},
    ];
  }else {
    return [
      { key: 'YardAreaName', label: this.$t('label.module'), _classes: 'text-uppercase text-center', _style: 'width: 25%'},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-center', _style: 'width: 25%'},
      { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-center', _style: 'width: 25%'},
      { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _classes: 'text-center', _style: 'width: 25%'},
    ];
  }
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.Bl?.UbicationJson?.map((e) => {
    return {
      ...e,
      PackagingName: e[`PackagingName${_lang}`] ?? '',
      YardName: e?.YardName ?? 'N/A',
      DaysInYard: e[`DaysInYard${_lang}`] ?? '',
      FirstEventName: e?.[`FirstEventName${_lang}`] ?? '',
      LastEventName: e?.[`LastEventName${_lang}`] ?? '',
    };
  });
}

function fields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px; max-width:45px;', sorter: false, filter: false },
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width: 45px;', filter: false},
    { label: this.$t('label.yard'), key: 'YardName',_style: 'min-width: 200px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.packaging'), key: 'PackagingName',_style: 'min-width: 180px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.quantity'), key: 'Quantity',  _classes: 'text-uppercase text-center', _style: 'min-width: 140px', },
    { label: this.$t('label.DaysInOperation'), key: 'DaysInYard', _classes: 'text-center text-uppercase', _style:'min-width: 160px' },
    { label: this.$t('label.FirstEvent'), key: 'FirstEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.FirstEventDate'), key: 'FirstEventDate',_classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.LastEvent'), key: 'LastEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.LastEventDate'), key: 'LastEventDate',_classes:'text-center', _style:'min-width: 160px' },
  ];
}

export default{
  name: 'currentSituation',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    formatNumber,
    formatDateTimeWithSlash,
    SeeUbicationDetailJson,
    computedUbicationDetailJson,
    fieldUbicationDetail,
  },
  computed: {
    computedList,
    fields,
  },
  watch: {
    Bl: function (NewVal) {
      if (NewVal && Object.keys(NewVal).length != 0) {
        this.$store.state.yardManagement.dataBl?.UbicationJson?.map((e, index) => {
          e.Nro = index+1;
          e.FgCollapse = false;
        })
      }
    }
  }
}
</script>