<template>
    <CRow class="mt-2">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
      <CCol sm="12" lg="12" xl="12">
            <CRow>
                <CCol sm="12" class="d-flex justify-content-end py-2">
                  <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="toggleTranscCreate"
                    v-c-tooltip="{
                    content: $t('label.eirTransaction'),
                    placement: 'top-end'
                    }"
                  >
                  <CIcon name="cil-playlist-add"/>&nbsp;
                    {{$t('label.nueva')}}
                  </CButton>
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol sm="12">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListEir"
                    :fields="transcfields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                  >
                    <template #Status="{ item }">
                    <td class="text-center">
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                    </td>
                    </template>
                    <template #options="{ item }">
                      <td class="text-center">
                    
                          <CButton
                            square
                            size="sm"
                            color="edit"
                            class="d-flex align-items-center"
                            @click="toggleEirTranscEdit(item)"
                            v-c-tooltip="{
                              content: $t('label.edit')+' '+$t('label.eirTransaction'),
                              placement: 'top-end'
                            }"
                            >
                            <CIcon name="pencil" />
                          </CButton>
                     
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
      </CCol>
  <ModalCreateEirTransc
    :modal.sync="modalTranscCreate"
    @set-modal-eir-transc-list="setEirList"
  />
  <ModalEditEirTransc
    :modal.sync="modalEirTranscUpdate"
    @set-modal-edit-eir-transc-list="setEirList"
    :EirTranscSelect="EirTranscSelect"
  />
</CRow>
</template>
<script>
import ModalCreateEirTransc from './modal-create-eir-transc';
import ModalEditEirTransc from './modal-edit-eir-transc';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

function data() {
return {
  items: [],
  TpEirItems: [],
  modalTranscCreate: false,
  modalEirTranscUpdate: false,
  EirTranscSelect: {}, 
  loading: false,
};
}
function handleTab(tab) {
this.Tab = tab;
}


function toggleTranscCreate() {
  this.modalTranscCreate = !this.modalTranscCreate;
}

function toggleEirTranscEdit(item) {
  this.EirTranscSelect = Object.assign({}, item);
  this.modalEirTranscUpdate = !this.modalEirTranscUpdate;
}

async function setEirList() {
this.getEirList();
}

function getEirList() {
this.loading = true;
    this.$http
    .get('TpEirTransac-list?TpEirId=')
    .then((response) => (
        this.items = response.data.data
    ))
    .finally(() => {
        this.loading = false;
    });  
}

function computedListEir() {
  let _this = this.$i18n.locale;
  return this.items.map((item) => {
    return {
      ...item,
      EirTypeName: _this=='en' ? item.TpEirNameEn : item.TpEirNameEs,
      GpoCargoName: _this=='en' ? item.GpoCargoNameEn : item.GpoCargoNameEs,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}


function transcfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
    { key: 'EirTypeName',label: this.$t('label.eirType'), _classes: 'text-uppercase text-center', _style: 'width:27%;'},
    { key: 'TpTransacEirNameEs', label: this.$t('label.eirTransaction')+' '+'(ES)',  _classes: 'text-uppercase text-center', _style: 'width:20%;' },
    { key: 'TpTransacEirNameEn', label: this.$t('label.eirTransaction')+' '+'(EN)',  _classes: 'text-uppercase text-center', _style: 'width:20%;' },
    { key: 'GpoCargoNameEs', label: this.$t('label.cargoGroup')+' '+'(EN)',  _classes: 'text-uppercase text-center', _style: 'width:20%;' },
    { key: 'GpoCargoNameEn', label: this.$t('label.cargoGroup')+' '+'(EN)',  _classes: 'text-uppercase text-center', _style: 'width:20%;' },
    { key: 'Acronym', label: this.$t('label.acronym'),  _classes: 'text-uppercase text-center', _style: 'width:10%;' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-uppercase text-center' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" },
    { key: 'options', label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
  ];
}


export default {
name: 'eir-index',
components: { 
  ModalCreateEirTransc,
  ModalEditEirTransc,
  CustomTabs, 
  CustomTab,
},
data,
props:{Tab: Number},
mixins: [GeneralMixin],
methods: {
  toggleTranscCreate,
  toggleEirTranscEdit,
  setEirList,
  getEirList,
  handleTab,
},
computed: {
  computedListEir,
  transcfields
},
watch:{
  Tab:function (val){
    if(val == 1){
      this.getEirList();
    }
  }
},
mounted: getEirList,
};
</script>
<style lang="scss"></style>
