<template>
  <CRow>
    <CCol sm="3">
      <CButton
        v-if="current != 0"
        shape="square"
        variant="outline"
        color="dark"
        class="d-flex align-items-center"
        @click.stop="previousStep"
      >
        <CIcon name="cil-chevron-left"/>
        <span class="ml-1">{{ btnPreText }}</span>
      </CButton>
    </CCol>
    <CCol sm="6">
      <ul>
        <li
          v-for="step in generatedSteps"
          :key="step.id"
          :class="{'active': step.active? true : null}"
        ></li>
      </ul>
    </CCol>
    <CCol sm="3">
      <CButton
        v-if="current != 3"
        variant="outline"
        shape="square"
        color="dark"
        class="d-flex align-items-center float-right"
        :disabled="isDisabled"
        @click.stop="nextStep"
      >
        <div v-if="!isLoadingForm">
          <span class="mr-1">{{ btnNextText }}</span>
          <CIcon name="cil-chevron-right"/>
        </div>
        <div v-else>
          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1 mr-1">{{ btnNextText }}</span>
          <CIcon name="cil-chevron-right"/>
        </div>
      </CButton>
    </CCol>
  </CRow>
</template>

<script>
import { mapState } from 'vuex';

function created() {
  this.$store.commit('baygrid/setSteps', this.steps);
}

//Metodos: 
function nextStep() {
  this.$emit('step-action', this.currentStep);
}
function previousStep() {
  this.$store.commit('baygrid/previousStep');
}
//Computeds
function btnPreText() {
  if(this.current > this.preText.length || this.preText.length == 1)
    return this.preText[0] || 'ANTERIOR';
  else
    return this.preText[this.current-1] || 'ANTERIOR';
}
function btnNextText() {
  if(this.current > this.nextText.length || this.nextText.length == 1)
    return this.nextText[0] || 'SIGUIENTE';
  else
    return this.nextText[this.current] || 'SIGUIENTE';
}
function generatedSteps() {
  let arr = [];

  for (let index = 0; index < this.steps; index++) {  
    arr.push({
      id: index,
      label: index+1,
      active: this.current == index
    });
  }

  return arr;
}

function isDisabled() {
  return !this.isEdit || this.isLoadingForm;
}

export default {
  name: 'form-controls',
  props: {
    steps: {
      type: Number,
      default: 1
    },
    current: {
      type: Number,
      default: 0
    },
    preText: {
      type: Array,
      default: () => ['ANTERIOR']
    },
    nextText: {
      type: Array,
      default: () => ['SIGUIENTE']
    }
  },
  created,
  methods: {
    previousStep,
    nextStep
  },
  computed: {
    btnNextText,
    btnPreText,
    generatedSteps,
    isDisabled,
    ...mapState({
      currentStep: state => state.baygrid.current,
      maxSteps: state => state.baygrid.steps,
      isEdit: state => state.baygrid.isEdit,
      isLoadingForm: state => state.baygrid.isLoadingForm,
      selectedPositions: state => state.baygrid.selectedPositions
    })
  },
  watch: {
    steps: function (newVal) {
      if(newVal != 0)
        this.$store.commit('baygrid/setSteps', newVal);
    }
  }
}
</script>

<style scoped>
ul {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  margin: 14px 0 14px 0;
}

li {
  background: #3f51b5;
  color: white;
  content: ' ';
  display: flex;
  flex-grow: 1;
  height: .3em;
  line-height: 1em;
  margin: 0;
  position: relative;
  text-align: right;
  z-index: 10;
}

li::before {
  color: white;
  background: #3f51b5;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount); 
  height: 2em;
  left: -2em;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: -.85em;
  width: 2em;
}

li.active {
  background-color: lightblue;
}

li.active ~ li {
  background-color: lightblue;
}

li.active ~ li::before {
  background-color: lightblue;
}

li:last-child {
  /* flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1; */
  flex: 0 1 0;
}
</style>