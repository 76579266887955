import { required, maxLength } from "vuelidate/lib/validators";
import { onlyAlphanumeric } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        CompanyId: { required },
        BranchName: { required, onlyAlphanumeric, maxLength: maxLength(500) },
        Latitude: { required },
        Longitude: { required },
        CountryId: { required },
        StateId: { required },
        MunicipalityId: { required },
        ParishId: { required },
        PortId: { required }
    }
}

