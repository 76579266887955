<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaMercancia"
    >
      <CRow>
        <CCol sm="12" lg="4" class="text-center">
          <CRow>
            <div class="col-sm-12 d-flex justify-content-center align-items-center">
              <picture-input
                v-if="ActualizaMercancia"
                :imgSrc="image"
                ref="imageInput"
                width="200"
                height="200"
                accept="image/jpeg,image/png,image"
                size="10"
                :custom-strings="imgInputTexts"
                @change="handleFileUpload"
              >
              </picture-input> 
            </div>
            <CCol sm="11" lg="12" xl="12"  class="d-flex align-items-center">                           
              <div class="text-invalid-feedback text-center" v-if="$v.CommodityRoute.$error">
                  {{$t('label.badImage')}}
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="8">
              <CRow>
                <CCol sm="12">
                  <div class="form-group form-row">
                      <label for="rubro" class="text-right required form-control-label col-sm-12 col-lg-4 mt-2">{{$t('label.heading')}}</label>
                      <div class=" col-sm-12 col-lg-8">
                        <v-select
                          :options="HeadingOptions"
                          v-model="HeadingId"
                          :getOptionLabel="option => option.label"
                          :placeholder="$t('label.toSelect')"
                          :class="isRubroValid"
                        />
                    </div>
                  </div>
                </CCol>
                <CCol sm="12">          
                  <CInput
                    :label="`${$t('label.density')} (KG/M3)`"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    maxlength="11"
                    v-model.trim="$v.Density.$model"
                    :is-valid="hasError($v.Density)"
                    :invalid-feedback="errorMessage($v.Density)"
                    type="number"
                    step=".01"
                    min="0"
                    max="99999999.99"                
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    :label= "`${$t('label.commodity')} (ES)`"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                    :placeholder="`${$t('label.commodity')} (ES)`"
                    :is-valid="hasError($v.CommodityNameEs)"
                    :invalid-feedback="errorMessage($v.CommodityNameEs)"
                    maxlength="250"
                    v-model.trim="$v.CommodityNameEs.$model"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    :label= "`${$t('label.commodity')} (EN)`"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                    :placeholder="`${$t('label.commodity')} (EN)`"
                    :is-valid="hasError($v.CommodityNameEn)"
                    :invalid-feedback="errorMessage($v.CommodityNameEn)"
                    maxlength="250"
                    v-model.trim="$v.CommodityNameEn.$model"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    :label= "$t('label.hsCode')"
                    addLabelClasses="text-right"
                    v-uppercase
                    :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.hsCode')"
                    :is-valid="hasError($v.HsCode)"
                    :invalid-feedback="errorMessage($v.HsCode)"
                    maxlength="250"
                    v-model.trim="$v.HsCode.$model"
                  />
                </CCol>
                <CCol sm="12">
                  <CTextarea
                    :label="$t('label.description')"
                    addLabelClasses="text-right"
                    rows="5"
                    :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                    :invalid-feedback="errorMessage($v.CommodityDs)"
                    maxlength="250"
                    v-model.trim="$v.CommodityDs.$model"
                    :is-valid="hasError($v.CommodityDs)"
                  />
                </CCol>
                <CCol sm="12" v-if="actualizar" >
                  <CSelect
                    :label="$t('label.status')"
                    addLabelClasses="text-right"
                    v-model="Status"
                    :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                    :value.sync="Status"
                    :is-valid="Status"
                    :options="selectOptions"
                    required
                  />
                </CCol>
              </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline 
        color="add" 
        @click="changeStatus"  >
          <CIcon name="checkAlt" />&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton 
          color="wipe" 
          @click="ActualizaMercancia=false">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>
<script>
import MercanciaValidations from '@/_validations/rubro/mercanciaValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import Fuse from "fuse.js";
import { GenerateImgPlaceholder, alertPropertiesHelpers} from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';

//DATA
function data () {
  return {
    //MODELO
    CommodityId: '',
    HeadingId: '',
    CommodityNameEs: '',
    CommodityNameEn: '',
    CommodityDs: '',
    Density: '',
    CommodityRoute: '',
    CommodityRouteOrigin: '',
    HsCode: '',
    //VARIABLES
    ActualizaMercancia: false,
    image: '/img/uploadPhotoEs.svg',
    Loading: false,    
    tituloModal: '',
    rubros: [],
    actualizar: false,
    Status:true,
    FgActCommodity: true,
    HeadingList: []
  }
}
//METHOD
function refreshComponent() {
  this.CommodityId    ='';
  this.HeadingId      = '';
  this.HsCode  = '';
  this.CommodityNameEs  = '';
  this.CommodityNameEn  = '';
  this.CommodityDs    = '';
  this.Density        = '';
  this.CommodityRoute = '';
  this.CommodityRouteOrigin = '';
  this.Status=true;
  this.FgActCommodity= true;
  this.image = '/img/uploadPhotoEs.svg';
  this.$nextTick(() => { this.$v.$reset() })
}

function submitFile() {
  try {
    this.$v.$touch();
    if (this.$v.$invalid) {
        throw this.$t('label.errorsPleaseCheck');
    }

    if (this.$v.$invalid) return false;
      if(this.CommodityRoute === null || this.CommodityRoute === ""){
        this.submit();
      }else {

      if(this.CommodityRoute === this.CommodityRouteOrigin ){
        this.submit();
      }else{
        this.Loading = true;
        let formData = new FormData();
        formData.append('file1', this.CommodityRoute);
        this.$http.ejecutar('POST', 'Commodity-insert', formData)
        .then((response) => {
          this.CommodityRoute = response.data.data.files[0].path;
          this.submit();
        }).catch((err) => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
        })
        .then(() =>
          this.Loading = false
        );
      }
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }  
}

function changeStatus() {
  if(this.FgActCommodity !== '' && this.CommodityId !== 0){
    if(this.FgActCommodity !== this.Status){              
        this.$swal
          .fire(this.alertProperties({
              text: `${this.$t('label.changeStatusQuestion')+' '} ?`,
          }))     
        .then((result) => {
          if (result.isConfirmed) {
              this.submitFile();
          }
        })
    }else{
        this.submitFile();
    }
  }else{
      this.submitFile();
  }
}

function submit() {

    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let CommodityJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.CommodityId) {
      CommodityJson = {
        CommodityId: this.CommodityId,
        HeadingId: this.HeadingId.value,
        CommodityNameEs: this.CommodityNameEs,
        CommodityNameEn: this.CommodityNameEn,
        HsCode: this.HsCode,
        CommodityDs: this.CommodityDs,
        Density: this.Density,
        CommodityRoute: this.CommodityRoute,
        Status: this.Status,
      };
      metodo = 'PUT';
      ruta = "Commodity-update";
    } else {
      CommodityJson = {
        HeadingId: this.HeadingId.value,
        CommodityNameEs: this.CommodityNameEs,
        CommodityNameEn: this.CommodityNameEn,
        CommodityDs: this.CommodityDs,
        HsCode: this.HsCode,
        Density: this.Density,
        CommodityRoute: this.CommodityRoute,
      };
      metodo = 'POST';
      ruta = "Commodity-insert";
    }
    this.$http.ejecutar(metodo, ruta, CommodityJson, { root: 'CommodityJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.$emit('child-refresh', this.HeadingId.value);
      this.refreshComponent();
      this.ActualizaMercancia = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
    });
}

function handleFileUpload(image) {
  if (image) {
    this.CommodityRoute = this.$refs.imageInput.file;
  }
}

async function setCommodityForm() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let title = this.modal.Item['CommodityName'+_lang]
  this.actualizar         = true;
  this.tituloModal        = this.$t('label.edit')+' '+this.$t('label.commodity')+':'+title;
  this.CommodityId        = this.modal.Item.CommodityId;
  this.CommodityNameEs      = this.modal.Item.CommodityNameEs;
  this.CommodityNameEn      = this.modal.Item.CommodityNameEn;
  this.HsCode              = this.modal.Item.HsCode;
  this.HeadingId          = this.modal.Item.HeadingId;
  this.CommodityDs        = this.modal.Item.CommodityDs;
  this.Density            = this.modal.Item.Density;
  this.CommodityRoute     = this.modal.Item.CommodityRoute;
  this.CommodityRouteOrigin = this.modal.Item.CommodityRoute;
  this.HeadingId = {
    label: this.modal.Item.HeadingName,
    value: this.modal.Item.HeadingId
  };
  this.Status= this.modal.Item.FgActCommodity;
  this.FgActCommodity= this.modal.Item.FgActCommodity;
}

function getHeadingList() {
  this.Loading = true;
  this.$http
  .get('Heading-list',{ Filter: 'ACTIVO' })
    .then((response) => (
        this.HeadingList = response.data.data
    ))
    .finally(() => {
        this.Loading = false;
    });  
}
//COMPUTED
function HeadingOptions(){
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.HeadingList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.HeadingList.map((e)=>{
        if (e.FgActHeading) {
          chart.push({
            value: e.HeadingId,
            label: e['HeadingName'+_lang],
        })
      }    
    })
    return chart;
  }
}

function isRubroValid() {
  return (this.HeadingId == null || this.HeadingId == "") ? 'no-valido' : 'valido';
}

function selectOptions(){
  return [
    { 
      value: true, 
      label: this.$t('label.ACTIVO')
    },
    { 
      value: false, 
      label: this.$t('label.INACTIVO')
    }
  ];
}

export default {
  name: 'commodity-modal',
  mixins: [
    General,
    //ModalMixin,
  ],
  components:{ 
    PictureInput
  },
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: MercanciaValidations,
  methods: {
    refreshComponent,
    changeStatus,
    submit,
    submitFile,
    handleFileUpload,
    setCommodityForm,
    getHeadingList
  },
  computed: {
    HeadingOptions,
    isRubroValid,
    selectOptions,
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.getHeadingList();
        this.refreshComponent();
        this.ActualizaMercancia = true;
        
        if (this.modal.Item==true) {
          this.actualizar = false;
          this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.commodity');
          let _lang = this.$i18n.locale;
          this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        } else {
          if(this.modal.Item.CommodityRoute != null && this.modal.Item.CommodityRoute != ''){
              this.image = `${this.$store.getters["connection/getBase"]}${this.modal.Item.CommodityRoute.replace('Public/', '', 'i')}`;
          }else{
            let _lang = this.$i18n.locale;
            this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
          }
          await this.setCommodityForm();
          this.$v.$touch();
        }        
        this.$emit('cerrarModal');
      }
    }
  },
  
}
</script>
<style scoped>
.alto {
  height: 200px;
}
</style>
