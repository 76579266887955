<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ isEdit ? `${$t('label.edit')} ${$t('label.recognition')}` 
              : `${$t('label.nuevo')} ${$t('label.recognition')}`}}
        </h6>
      </CCol>
    </CRow>
      <CRow class="justify-content-center mt-3 px-3">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.basicData')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.yard')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :is-valid="hasError($v.YardName)"
                      :placeholder="$t('label.select')"
                      v-model="$v.YardName.$model"
                      :disabled="true"
                    >
                    </CInput>
                    <div class="form-group form-row mb-3">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right required">{{`${$t('label.dateFrom')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-9 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.DateFrom.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="DateFrom"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.DateFrom)"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>
                    <div class="form-group form-row mb-3">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right required">{{`${$t('label.dateTo')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-9 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.DateTo.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="DateTo"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.DateTo)"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>
                    <CTextarea
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.MachineId.$model"
                      :options="MachineOptions"
                      :label="$t('label.machine')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.MachineId)"
                      :invalid-feedback="errorMessage($v.MachineId)"
                    />
                    <CSelect
                      size="sm"
                      :label="$t('label.Area')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :is-valid="hasError($v.AreaId)"
                      :placeholder="$t('label.select')"
                      v-model="$v.AreaId.$model"
                      :options="AreaOptions"
                    />
                    <CSelect
                      size="sm"
                      v-model="$v.EventReasonId.$model"
                      :options="EventOptions"
                      :label="$t('label.motive')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.EventReasonId)"
                      :invalid-feedback="errorMessage($v.EventReasonId)"
                      @change="getEventService"
                    />
                    <CSelect
                      v-if="isEdit"
                      size="sm"
                      :options="statusOptions"
                      :value.sync="Status"
                      :label="$t('label.status')"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                    />
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.inspectionSeals')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal1.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal1.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal1.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal1, 1)"
                        />
                        <CButton
                          v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                      <div class="input-group col-sm-12 col-lg-9">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal2.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal2.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal2.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal2, 2)"
                        />
                        <CButton
                          v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal3.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal3.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal3.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal3, 3)"
                        />
                        <CButton
                          v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal4.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal4.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal4.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal4, 4)"
                        />
                        <CButton
                          v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" class="text-invalid-feedback text-center" 
                    v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                  >
                    <label>{{ $t('label.AtLeastSealRequired') }}</label>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.documentation')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <vue-dropzone 
                    ref="myVueDropzone" 
                    id="dropzone" 
                    :options="dropzoneOptions" 
                    :useCustomSlot="true"
                    v-on:vdropzone-error="DropzoneError"
                    class="col-lg-12"
                  > 
                    <div>
                      <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                      <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                    </div>
                  </vue-dropzone>
                  <CCol sm="12" class="d-flex align-items-center justify-content-end my-2 px-0">
                    <CButton 
                      color="add" 
                      square 
                      size="sm"
                      :disabled="isSubmit"
                      @click="SubmitDocument"
                    >
                      <CIcon name="checkAlt"/>
                    </CButton>
                  </CCol>
                  <CCol sm="12" class="px-0">
                    <dataTableExtended
                      class="align-center-row-datatable"
                      hover
                      sorter
                      :items="formatedItems"
                      :fields="fields"
                      :items-per-page="5"
                      :noItemsView="tableText?.noItemsViewText"
                      pagination
                      column-filter
                    >
                      <template #Imagen="{item, index}">
                        <td class="center-cell text-uppercase">
                          <img
                            :src="srcImage(item.Imagen)"
                            style="height: 32px !important; width: 32px !important;"
                            @error="'/img/errorimage.jpg'"
                          />
                        </td>
                      </template>
                      <template #status="{item}">
                        <td class="center-cell">
                          <CBadge v-if="item.status" color="success">
                            {{item.Status}}
                          </CBadge>
                          <CBadge v-if="!item.status" color="danger">
                            {{item.Status}}
                          </CBadge>
                        </td>
                      </template>
                      <template #Options="{item,index}">
                        <td class="center-cell">
                          <CButton
                            color="wipe"
                            size="sm"
                            class="mr-1"
                            @click="DeleteImage(item)"
                            v-c-tooltip="{
                              content: $t('label.delete')+' '+$t('label.picture'),
                              placement: 'top-end'
                            }"
                          >
                            <CIcon name="cil-trash"/>
                          </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        </CCol>
      </CRow>
      <ModalSeal
        :modal="ModalSealActive"
        :SealJson="SealJson"
        :isEdit="isEdit"
        @CloseModal="(ModalSealActive=false, SealJson={})"
        @UpdateSeal="UpdateSeal"
        @UpdateRegister="UpdateRegister"
      />
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import RecognitionRegisterValidations from '@/_validations/yard-management/container/recognitionRegisterValidations';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import ModalSeal from '../../../modal-seal';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DataStorage from '@/_helpers/localStorage';
import moment from 'moment';
import GeneralMixin from '@/_mixins/general';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    SealJson: {},
    EventReasonId: '',
    EventList: [],
    MachineId: '',
    MachineList: [],
    DateFrom: '',
    DateTo: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    YardName: '',
    AreaId: '',
    AreaList: [],
    Status: 0,
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    CustomInspectionDocumentJson: [],
    CargoJson: {},
    docFile: null,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx, .jpg, .png',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${user.token}`
      }
    },
  };
}

function getYardAreaCustomInspection() {
  this.$http.get('YardAreaCustomInspection-list', { YardId: this.dataContainer.YardId })
    .then(response => {
      let List = response.data.data;
      this.AreaList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getSealConditionList() {
  await this.$http.get('SealCondition-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardMachine() {
  await this.$http.get('MachineYard-list', { YardId: this.dataContainer.YardId, Filter: 'ACTIVO', CompanyBranchId: this.CompanyBranchId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardEventReasonList() {
  let Parameter = {
    EventId: process.env.VUE_APP_EVENT_RECOGNITION,
    CargoId: this.dataContainer.CargoId,
    YardId: this.dataContainer.YardId,
  };
  await this.$http.get('YardEventReason-list', Parameter)
    .then(response => {
      let List = response.data.data;
      this.EventList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if ((this.DateFrom&&DateFormater.formatDateTimeWithoutSlash(this.DateFrom)<this.ValidPreviousDate)
        || this.DateTo&&DateFormater.formatDateTimeWithoutSlash(this.DateTo)<this.ValidPreviousDate) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if ((this.DateFrom&&DateFormater.formatDateTimeWithoutSlash(this.DateFrom)>this.ValidLaterDate)
        || this.DateTo&&DateFormater.formatDateTimeWithoutSlash(this.DateTo)>this.ValidLaterDate) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (this.DateFrom && this.DateTo &&
        DateFormater.formatDateTimeWithoutSlash(this.DateFrom) >= DateFormater.formatDateTimeWithoutSlash(this.DateTo)) {
        throw this.$t('label.ErrorFromToPleaseCheck');
      }
      if (this.DateFrom && DateFormater.formatDateTimeWithoutSlash(this.DateFrom) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm") 
        || this.DateTo && DateFormater.formatDateTimeWithoutSlash(this.DateTo) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
        throw this.$t('label.ErrorDispatchDatePleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let CargoJson = {
      YardAreaId: this.AreaId,
      YardCargoId: this.dataContainer.YardCargoId,
      EventReasonId: this.EventReasonId,
      MachineId: this.MachineId,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] :
        this.Seal.Seal1.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
        this.Seal.Seal2.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
        this.Seal.Seal3.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
        this.Seal.Seal4.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.DateFrom),
      TransactionFinish: DateFormater.formatDateTimeWithoutSlash(this.DateTo),
      Observation: this.Observation,
      CustomInspectionDocumentJson: this.CustomInspectionDocumentJson,
    }

    if (this.isEdit) {
      CargoJson.YardCargoEventDetailId = this.RecognitionItem.YardCargoEventDetailId;
      CargoJson.VisitCargoInspectionId = this.RecognitionItem?.VisitCargoInspectionId ?? '';
      CargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardCargoCustomInspection-update' : 'YardCargoCustomInspection-insert';

    this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list");
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function UpdateRegister(images, index) {
  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images;
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images;
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images;
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images;
      break;
  }

  await this.$http.put('YardCargoCustomInspection-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await this.$emit("Update-list");
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}


function SubmitDocument() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
  
    let files = this.$refs.myVueDropzone.getQueuedFiles();
    if (files.length === 0) {
      throw this.$t('label.documentQuestion');
    }

    this.$http.file('YardManagement-file', files)
    .then((response) => {
      this.$refs.myVueDropzone.removeAllFiles();
      response.data.data.files.map(item => {
        this.CustomInspectionDocumentJson.push({ 
          DocumentRoute: item.path,
          Date: moment(new Date).format('DD/MM/YYYY HH:mm'),
        });
      })
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
    }).catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }); 
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function DeleteImage(item) {
  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteFile')}`,
      }))
    .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      let DocumentRoute = item.DocumentRoute ? item.DocumentRoute.replace(/public+[/]/i, '') : '';
      this.$http.delete('files', DocumentRoute)
        .then(async(response) => {
          if (this.isEdit && item.YardCargoEventDetailDocumentId) {
            let UpdateImages = this.CustomInspectionDocumentJson.filter(Document => Document.YardCargoEventDetailDocumentId);
            UpdateImages = UpdateImages.map(Update => {
              if (Update.YardCargoEventDetailDocumentId == item.YardCargoEventDetailDocumentId) {
                return {
                  YardCargoEventDetailDocumentId: item.YardCargoEventDetailDocumentId ?? '',
                  DocumentRoute: item.DocumentRoute,
                  Status: 0,
                }
              }else{
                return {
                  YardCargoEventDetailDocumentId: item.YardCargoEventDetailDocumentId ?? '',
                  DocumentRoute: item.DocumentRoute,
                  Status: 1,
                }
              }
            });
            await this.EditSubmit(UpdateImages);
          }
          this.CustomInspectionDocumentJson = this.CustomInspectionDocumentJson.filter(RouteImage => RouteImage.DocumentRoute != item.DocumentRoute);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

async function EditSubmit(UpdateImages) {
  try {
    this.$store.state.yardManagement.loading = true;

    this.CargoJson.CustomInspectionDocumentJson = UpdateImages;

    let Method = 'PUT';
    let Route = 'YardCargoCustomInspection-update';

    await this.$http.ejecutar(Method, Route, this.CargoJson, { root: 'CargoJson' })
      .then(async (response) => {
        await this.$emit("Update-list");
      }).catch((err) => {
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function getEventService(event) {
  this.EventReasonId = event.target.value;
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function statusSelectColor() {
  return this.Status === 1;
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function getNewData() {
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.Seal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.Seal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.Seal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.Seal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.$v.$reset();
}

function getdata(val) {
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.NewSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? '';
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.NewSeal =val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? '';
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? '';
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? '';
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.EventReasonId = val.EventReasonId ?? '';
  this.MachineId = val.MachineId ?? '';
  this.Observation = val.Observation ?? '';
  this.AreaId = val.YardAreaId ?? '';
  this.DateFrom = val.TransactionBegin ?? '';
  this.DateTo = val.TransactionFinish ?? '';
  this.Status = val.FgActYardCargoEventDetail ? 1 : 0;
  this.CustomInspectionDocumentJson = val.CustomInspectionDocumentJson ? 

    val.CustomInspectionDocumentJson.map(item => {
      return {
        YardCargoEventDetailDocumentId: item.YardCargoEventDetailDocumentId ?? '',
        DocumentRoute: item.DocumentRoute ,
        TransaLogin: item.TransaLogin,
        TransaRegDate: item.TransaRegDate,
        Status: 1,
      }
    })
  : [];


  this.CargoJson = {
    YardCargoEventDetailId: this.RecognitionItem.YardCargoEventDetailId,
    VisitCargoInspectionId: this.RecognitionItem?.VisitCargoInspectionId ?? '',
    YardAreaId: this.AreaId,
    YardCargoId: this.dataContainer.YardCargoId,
    EventReasonId: this.EventReasonId,
    MachineId: this.MachineId,
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
      this.Seal.Seal1.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
      this.Seal.Seal2.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
      this.Seal.Seal3.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
      this.Seal.Seal4.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.DateFrom),
    TransactionFinish: DateFormater.formatDateTimeWithoutSlash(this.DateTo),
    Observation: this.Observation,
    CustomInspectionDocumentJson: this.CustomInspectionDocumentJson,
    Status: this.Status,
  }

  this.Validate = true;
  this.$v.$touch();
}

function srcImage(icon){
  let extension = icon.split(".").pop();
  if(icon && (extension == 'jpg' || extension == 'png')){
    return icon;
  }else if (icon && ( extension == 'pdf' || extension == 'doc' || extension == 'docx' || extension == 'xlsx')){
    return '/img/documentImage.png';
  }else{
    return '/img/errorimage.jpg';
  }

}

function getDateRange() {
  this.previousDate = DateFormater.formatDateRange(this.dataContainer.ReceptionDate);
  this.laterDate = new Date();
  this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ReceptionDate));
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function Reset() {
  this.EventReasonId = '';
  this.EventList = [];
  this.DateFrom = '';
  this.DateTo = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.Observation = '';
  this.YardName = '';
  this.MachineId = '';
  this.MachineList = [];
  this.AreaId = '';
  this.AreaList = [];
  this.Status = 0;
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.ConditionList = [];
  this.CustomInspectionDocumentJson = [];
  this.CargoJson = {};
  this.$refs.myVueDropzone.removeAllFiles();
  this.Validate = false;
  this.$v.$reset();
  this.$emit("Close");
}

//Computeds:
function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventReasonId,
        label: e[`EventReasonName${_lang}`],
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: e[`SealConditionName${_lang}`],
      })
    })
    return chart;
  }
}

function AreaOptions() {
  if(this.AreaList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.AreaList.map(function(e){
      chart.push({
        value: e.YardAreaId,
        label: `${e[`TpYardAreaName${_lang}`]} (${e.YardAreaName})`,
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}
function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

function fields(){ 
  return [
    { key: 'Nro', label: '#',_style: 'width:5%; text-align:center;', filter: false},
    { key: 'Imagen', label: this.$t('label.image'), _style: 'width: 50%;', sorter: false,  filter: false, _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 20%;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 20%;', _classes:"text-center"},
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 45px',  sorter: false,  filter: false, _classes:'text-center' }
  ]
}

function formatedItems() {
  return this.CustomInspectionDocumentJson.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      Imagen: item.DocumentRoute ? `${this.$store.getters['connection/getBase']}${item.DocumentRoute.replace(/public+[/]/i, '')}` : '',
      TransaLogin: item.TransaLogin ?? DataStorage.getUser().Login,
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : item.Date,
    }
  })
}

export default {
  name: 'recognition-register',
  components: {
    ModalSeal,
    vueDropzone: vue2Dropzone,
   },
  props: { Active: Boolean, isEdit: Boolean, RecognitionItem: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){
    return RecognitionRegisterValidations(this.VerifySeal, this.DateFrom, this.DateTo,this.ValidPreviousDate, this.ValidLaterDate,
      moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm"));
  },
  directives: UpperCase,
  methods: {
    getYardAreaCustomInspection,
    getSealConditionList,
    getYardMachine,
    getYardEventReasonList,
    Submit,
    UpdateRegister,
    SubmitDocument,
    DeleteImage,
    EditSubmit,
    DropzoneError,
    getEventService,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    statusSelectColor,
    UpdateSeal,
    getNewData,
    getdata,
    srcImage,
    getDateRange,
    Reset,
  },
  computed: {
    MachineOptions,
    EventOptions,
    ConditionOptions,
    AreaOptions,
    ValidateTpCargoStatus,
    fields,
    formatedItems,
    VerifySeal,
    ...mapState({
      dataContainer: state => state.yardManagement.dataContainer,
      dropContainerMovement: state => state.yardManagement.dropContainerMovement,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        this.$store.state.yardManagement.loading = true;
        this.YardName = this.dataContainer.YardName;
        await this.getDateRange();
        await this.getSealConditionList();
        await this.getYardMachine();
        await this.getYardAreaCustomInspection();
        await this.getYardEventReasonList();
        if (this.isEdit) {
          await this.getdata(this.RecognitionItem);
        }else{
          await this.getNewData();
        }
        this.$store.state.yardManagement.loading = false;
      }
    },
    DateFrom: function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (this.DateTo && DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.DateTo)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    DateTo: function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (this.DateFrom && DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.DateFrom)) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropContainerMovement: function (NewVal, OldVal) {
      if (OldVal == 4) {
        this.Reset();
      }
    }
  },
};
</script>
<style scoped>
.card-border{
  padding: 20px;
  background-color: #fff;
  border-color: #c4c9d0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
</style>