 <template>
  <div>
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      :show.sync="modalActive"
      class="modal-content-condicion-maquina"
      >
        <CRow class="mt-2">
          <CCol sm="12"> 
            <CInput
              :label="$t('label.machineCondition')"
              :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              add-label-classes="required text-right"
              v-uppercase
              v-model.trim="$v.formMachineCondition.MachineConditionName.$model"
              :invalid-feedback="errorMessage($v.formMachineCondition.MachineConditionName)"
              :is-valid="hasError($v.formMachineCondition.MachineConditionName)"
              maxlength="250"
            >
            </CInput>
          </CCol>
    
          <CCol sm="12"> 
            <CSelect
                :label="$t('label.requiresSuplier')"
                :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                add-label-classes="text-right"
                v-uppercase
                v-model.trim="formMachineCondition.FgSuplier"            
                :options="selectOptionsFgSuplier"
                :value.sync="formMachineCondition.FgSuplier" 
                :is-valid="hasError($v.formMachineCondition.FgSuplier)"
                :invalid-feedback="errorMessage($v.formMachineCondition.FgSuplier)"               
            >
            </CSelect>
          </CCol>
          <CCol sm="12">
            <CSelect
              :value.sync="formMachineCondition.Status"
              :is-valid="statusSelectColor"
              :label="$t('label.status')"
              addLabelClasses="text-right"
              :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
              :options="selectOptions"
            />
        </CCol>
        </CRow> 
        <template #footer>
          <CButton
            outline
            color="add"
            :disabled="isSubmit"
            @click.stop="statusConfirmation(
              conditionData.FgActMachineCondition,
              formMachineCondition.Status,
              submit,
              conditionData.MachineConditionName)"
          >
            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
          </CButton>

          <CButton
            color="wipe"
            :disabled="isSubmit"
            class="d-flex align-items-center"
            @click.stop="toggle(false)"
          >
            <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
        </template> 
    </CModalExtended>       
  </div>       
</template>

    
<script>
  import { FormMachineCondition } from '@/_validations/maquina/MaquinaValidation';
  import UpperCase from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import { mapState } from 'vuex';

//Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false, 
      formMachineCondition: {
        MachineConditionName: '',
        MachineConditionId: '',
        FgSuplier: '',
        FormatedDate:'',
        Status: 1,
        FgActMachineCondition: '',
    },
    titulo: '',
    selectOptions: [
      { 
          value: 1, 
          label: this.$t('label.ACTIVO')
      },
      { 
          value: 0, 
          label: this.$t('label.INACTIVO')
      }
    ],
    selectOptionsFgSuplier: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],
  }
}

async function submit(){
  try { 
  this.$v.formMachineCondition.$touch();

  if (this.$v.formMachineCondition.$error) {
    throw this.$t('label.errorsPleaseCheck');
  }
  this.isSubmit = true;
  let MachineConditionJson = {
    MachineConditionId: this.formMachineCondition.MachineConditionId,
    MachineConditionName: this.formMachineCondition.MachineConditionName,
    FgSuplier: this.formMachineCondition.FgSuplier,
    Status: this.formMachineCondition.Status
  };

  await this.$http.put("MachineCondition-update", MachineConditionJson, { root: 'MachineConditionJson' })
    .then(response => {
      if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('submited');
          this.modalActive = false; 
          this.notifySuccess({ text: messageSuccess });
          this.resetForm();
        }     
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
    } catch (e) {
    this.notifyError({ text: e });
    }
    this.$store.dispatch('maquina/getAlllist',{CompanyBranchId:this.branch.CompanyBranchId});
  }

  function resetForm() {
    this.formMachineCondition.MachineConditionId = '';
    this.formMachineCondition.MachineConditionName = '';
    this.formMachineCondition.FgSuplier = false;
    this.formMachineCondition.Status = 1;
    this.$v.$reset();
  }

  function setConditionData(newCondition) {
    this.formMachineCondition.MachineConditionId = newCondition.MachineConditionId;
    this.formMachineCondition.MachineConditionName = newCondition.MachineConditionName;
    this.formMachineCondition.FgSuplier = newCondition.FgSuplier? 1 : 0;
    this.formMachineCondition.Status = newCondition.FgActMachineCondition ? 1 : 0; 
    this.titulo = `${this.$t('label.machineCondition')}: ${newCondition.MachineConditionName}`;
    
    this.$v.formMachineCondition.$touch();
  }

//Computeds:


function statusSelectColor(){
  if(this.formMachineCondition.Status){
    return this.formMachineCondition.Status === 1;
  } else {
    return false;
  }
}
function titleModal() {
  let title = this.formMachineCondition.MachineConditionName
  return this.$t('label.edit')+' '+this.$t('label.machineCondition')+': '+title;
}

export default {
  name: 'edit-condicion-maquina',
  mixins: [ ModalMixin ],
  props: {
    modal: Boolean,
    conditionData: {
      type: Object,
      default: () => { }
    }
  },
  data,
  validations: FormMachineCondition,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    setConditionData,
  },
  computed: {
    statusSelectColor,
    titleModal,
    ...mapState({
              branch: state => state.auth.branch
          }),
  },
  watch: {
    conditionData: function(newCondition) {
      this.setConditionData(newCondition);
    }
  }
}
</script>
<style lang="scss">
.modal-content-condicion-maquina {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>