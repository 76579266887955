<template>
    <div>
        <div class="align-items-center justify-content-end mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
          <CRow class="align-items-center justify-content-end">
            <CCol sm="6" xl="6" >
              <CRow>
                <CCol sm="12" xl="12" class="align-items-center">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.eir'),
                      placement: 'top-end'
                    }"
                    @click="toggleContainerAdd()"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <div>
          <CRow>
              <CCol col="12" xl="12">
                  <dataTableExtended
                      class="align-center-row-datatable"
                      hover
                      sorter
                      small
                      details
                      column-filter
                      :items="formatedItems"
                      :fields="fields"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="tableText.itemsPerPage"
                      pagination
                  >
                      <template #Nro="{ index }">
                          <td class="text-center">
                              {{index+1}}
                          </td>
                      </template>
                      <template #Status="{ item }">
                        <td class="cell-center text-center">
                          <CBadge :color="getBadge(item.Status)">
                            {{ $t('label.'+item.Status) }}
                          </CBadge>
                        </td>
                      </template>
                      <template #options="{item}">
                        <td class="text-center">
                          <CButton
                            color="print"
                            square
                            size="sm"
                            class="mr-2"
                            @click="printPdf(item)"
                            v-c-tooltip="{
                              content: $t('label.print'),
                              placement: 'top-start'
                            }"
                        >
                        <CIcon name="cil-print" />
                        </CButton>
                        <CButton
                            v-if="item.FgActYardCargoEir"
                            color="wipe"
                            size="sm"
                            @click="toggleDesactive(item)"
                            v-c-tooltip="{
                              content: `${$t('label.delete')}`,
                              placement: 'top-start',
                            }"
                          >
                            <CIcon name="cil-trash"/>
                          </CButton>
                        </td>
                      </template>
                  </dataTableExtended>
              </CCol>
          </CRow>
        </div>
      <ModalBlContainer
        :modal.sync="ModalBlContainer"
        :ContainerItem="ContainerItem"
        :items ="items"
        :title="titleModal"
        @close="closeModal"
        @Update-list="Update"
      />
      <ModalDesactiveEir
        :modal.sync="ModalDesactiveEir"
        :YardCargoEirId="YardCargoEirId"
        :title="titleModal"
        @Update-list="Update"
      />
    </div>
  </template>
  <script>
  
  import GeneralMixin from '@/_mixins/general';
  import ModalMixin from '@/_mixins/modal';
  import { mapState } from 'vuex';
  import ModalBlContainer from './modal-eir';
  import ModalDesactiveEir from './modal-desactive';
  import { DateFormater} from '@/_helpers/funciones';

  //DATA-------------------------
  function data() {
    return {
      ModalBlContainer: false,
      ModalDesactiveEir: false,
      ModalBlDocument: false,
      ContainerItem:{},
      titleModal: '',
      titleDocumentsModal: '',
      items: [],
      documentList: [],
      YardCargoEirId: '',
      itemsEirReport: {},
      FormatedDate: this.FormatedDate,
      Devolution: '',
      VehicleName: '',
      ClientName: '',
      TransportName: '',
      OperatorName: '',
      ObservationUser: ''
    }
  }

//methods

async function printPdf(item) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('YardCargoEir-by-Id?',{ YardCargoEirId: item.YardCargoEirId})
  .then(response => {
    let res = response.data.data && response.data.data.length != 0 ? response.data.data[0] : {};
    this.itemsEirReport = res;

    let date = DateFormater.formatDateTimeWithSlash(this.itemsEirReport.TransaRegDate);

    let pageInfo = {
      FormatedDate: date,
      Devolution: this.itemsEirReport.Devolution,
      VehicleName: this.itemsEirReport.LicensePlate,
      ClientName: this.itemsEirReport.ClientName,
      TransportName: this.itemsEirReport.CarrierName,
      OperatorName: this.itemsEirReport.OperatorName,
      ObservationUser: this.itemsEirReport.ObservationUser,
      arrIdsDamage: this.itemsEirReport.DamageInspJson,
      infoContainer: this.itemsEirReport
    };
  
    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));
    let routeData = this.$router.resolve({name: 'PdfEir'});
    window.open(routeData.href, '_blank');

  })        
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

// function printPdf(item) {
//   this.$store.state.yardManagement.loading = true;
//   let EirJson = {
//     YardCargoEirId: item.YardCargoEirId,
//   }

//   this.$http
//     .ejecutar("PUT", "YardCargoEirPrint", EirJson, {root: "EirJson",})
//     .then((response) => {
//       let res = response.data.data && response.data.data.length != 0 ? response.data.data[0] : {};
//       this.itemsEirReport = res;
      
//       let pageInfo = {
//         FormatedDate: this.itemsEirReport.TransactionDate,
//         Devolution: this.itemsEirReport.Devolution,
//         VehicleName: this.itemsEirReport.LicensePlate,
//         ClientName: this.itemsEirReport.ClientName,
//         TransportName: this.itemsEirReport.CarrierName,
//         OperatorName: this.itemsEirReport.OperatorName,
//         ObservationUser: this.itemsEirReport.ObservationUser,
//         infoContainer: this.itemsEirReport
//       };
      
//       localStorage.setItem('pageInfo', JSON.stringify(pageInfo));
//       let routeData = this.$router.resolve({name: 'PdfEir'});
//       window.open(routeData.href, '_blank');


//       this.$store.state.yardManagement.loading = false;
//       })
//     .catch((err) => {
//       this.$notify({
//         group: "container",
//         title: "¡Error!",
//         text: err,
//         type: "error",
//       });
//       this.$store.state.yardManagement.loading = false;
//   });
// }

function toggleDesactive(item){
  this.ModalDesactiveEir = true;
  this.YardCargoEirId = item.YardCargoEirId;
  this.titleModal = this.$t('button.annul')+' '+this.$t('label.eir');
}


async function getYardEirList() {
  this.$store.state.yardManagement.loading = true;
    this.$http.get('YardEirList-by-YardCargoId?',{ YardCargoId: this.YardCargoId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
          this.items = listado;
      }
    })        
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}


  async function getEiryardList() {
    //this.LoadingVisit =true;
    this.$http.get('YardEirList-by-YardCargoId', { CompanyBranchId: this.CompanyBranchId })
      .then(response => {
          this.VisitList = response.data.data ?? [];
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      }).finally(() => {
        //this.LoadingVisit = false;
      });
  }

  function toggleContainerAdd() {
    this.ModalBlContainer = true;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.eir');
  }
  
  async function Update() {
    this.$store.state.yardManagement.loading = true;
    await this.getYardEirList();
  }

  function closeModal(){
    this.ModalBlContainer = false;
    this.ModalBlDocument = false;
    this.documentList = [];
    this.ContainerItem = {};
    this.titleModal = '';
    this.titleDocumentsModal = '';
  }

  //computed
  function formatedItems() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return this.items.map((items) =>
      Object.assign({}, items, {  
        Nro: items.Nro,
        YardCargoStatusDs: items[`YardCargoStatusDs${_lang}`] ?? '',
        TpEirName: items[`TpEirName${_lang}`] ?? '',
        EventName: items[`EventName${_lang}`] ?? '',
        FormatedTransactionDate: items.TransactionDate ? DateFormater.formatDateTimeWithSlash(items.TransactionDate) : 'N/A',
        EmissionDateFormated: items.TransaRegDate ? DateFormater.formatDateTimeWithSlash(items.TransaRegDate) : 'N/A',
        TransaLogin: items.TransaLogin ?? "N/A",
        Status: items.Status,
        FormatedDate: items.TransaRegDate ? DateFormater.formatDateTimeWithSlash(items.TransaRegDate) : 'N/A',
        _classes: items.Status != 'ACTIVO' ? 'table-danger' : '',
      })
    );
  }
  
  function fields() {
    return [
      { key: 'options', label: '', _style: 'min-width:90px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: "YardCargoEirCode", label: this.$t("label.code"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "TpEirName", label: this.$t("label.eirType"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "EventName", label: this.$t("label.event"),_style: 'min-width: 150px;',_classes:'text-center',},
      { key: "TpTransacEirAcronym", label: this.$t("label.transaction"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "TpEirName", label: this.$t("label.movementType"),_style: 'min-width: 180px;',_classes:'text-center',},
      { key: "ClientName", label: this.$t("label.client"),_style: 'min-width: 180px;', _classes: "text-center" },
      { key: "CarrierName", label: this.$t("label.transport"),_style: 'min-width: 180px;', _classes: "text-center" },
      { key: 'LicensePlate', label: this.$t('label.vehicle'), _classes: 'text-center', _style: 'min-width: 130px;'},
      { key: "EmissionDateFormated", label: this.$t("label.emissionDate"),_style: 'min-width: 180px;', _classes: "text-center" },
      { key: "TransaLogin", label: this.$t("label.user"),_style: 'min-width: 130px;', _classes: "text-center" },
      { key: "Status", label: this.$t("label.status"),_style: 'min-width: 130px;', _classes: "text-center" },
      { key: "FormatedTransactionDate", label: this.$t("label.TransactionDate"), _style: "min-width: 180px",_classes: "text-center" },
    ];
  }
  
  export default {
    name: 'eir-index',
    components: {
      ModalBlContainer,
      ModalDesactiveEir
     },
    data,
    mixins: [GeneralMixin,ModalMixin],
    props: {containerItems: Object},
    methods: {
      printPdf,
      toggleDesactive,
      getYardEirList,
      getEiryardList,
      toggleContainerAdd,
      Update,
      closeModal
    },
    computed: {
      fields,
      formatedItems,
      ...mapState({
        CargoId: state => state.yardManagement.dataContainer.CargoId,
        YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
        YardId: state => state.yardManagement.yardData.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        yardCollapse: state => state.yardManagement.yardCollapse,
        isSelect: state => state.yardManagement.isSelect,
        tabIndex: state => state.yardManagement.ContainerTabIndex,
        dataContainer: state => state.yardManagement.dataContainer,
        ContainerTabIndex: state => state.yardManagement.ContainerTabIndex,
      })
    },
    watch: {
      ContainerTabIndex: async function(newVal) {
        if(newVal && newVal==7){
          await this.getYardEirList()
        }
      }
    }
  };
  </script>
  <style scoped>
  .Containers-header{
    background: #4e5a70;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .Containers-Search{
    background: #ffffff;
    border-radius: 10px;
    border:solid 0.5px;
  }
  .form-control-Search{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #fff;
    border-radius: 10px;
    outline: none;
  }
  .Containers-data{
    margin: 5px;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .Container-items{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .value-Table{
    background-color: white;
  }
  </style>