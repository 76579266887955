<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" /> 
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.vgmDetail') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <!--CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton-->
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>       
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"      
                    v-model="search"     
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getVgmDetailList"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <ag-grid-vue    
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnDefs: [
                {
                    field: "IdX",
                    headerName: "#",
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'text-center gb-cell',
                    //checkboxSelection: true, 
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'text-center gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "TpCargoDetailCode",
                    headerName: "SIZE (FT)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "IsoCode",
                    headerName: "ISO CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Seals",
                    headerName: "SEALS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Status",
                    headerName: "STATUS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ShippingLineCode",
                    headerName: "CARRIER CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ShippingLineName",
                    headerName: "CARRIER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ImdgCode",
                    headerName: "IMDG CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "LoadPort",
                    headerName: "LOAD PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DischargePort",
                    headerName: "DISCHARGE PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DeliveryPort",
                    headerName: "DELIVERY PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },

                {
                    field: "Weigth",
                    headerName: "GROSS \nWEIGHT (TON)",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 190,
                    cellClass: 'center-cell-especial',
                    filter: "agNumberColumnFilter",
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return formatMilDecimal(variable);
                    },
                },
                /*{
                    //field: "FgVgm",
                    headerName: "VGM",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'text-center',
                    //checkboxSelection: checkboxSelection
                    cellRenderer: params => {
                        if (params.data.FgVgm==undefined) {
                            return "";
                        } else {
                            return `<input type='checkbox' ${params.data.FgVgm ? 'checked' : ''} disabled />`;
                        }
                    }
                },*/
                {
                    field: "CtaFunctionCode",
                    headerName: "FUNCTION \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-watch';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "CtaIdentifier",
                    headerName: "IDENTIFIER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "CtaName",
                    headerName: "NAME",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ComAddressIdentifier",
                    headerName: "ADDRESS \nIDENTIFIER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 200,
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 2;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-watch';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "ComMeansTypeCode",
                    headerName: "MEANS \nTYPE \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "HanDescriptionCode",
                    headerName: "INSTRUCTION \nDESCRIPTION \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 270,
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 4;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-plan';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "HanIdentificationCode",
                    headerName: "CODE LIST \nIDENTIFICATION \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 270,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "HanResponsibleAgencyCode",
                    headerName: "CODE LIST \nRESPONSIBLE \nAGENCY CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "HanInstructionDescription",
                    headerName: "INSTRUCTION \nDESCRIPCION",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 250,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TsrServicePriorityCode",
                    headerName: "SERVICE \nPRIORITY \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 250,
                    colSpan: (params) => {
                        if (isHeader(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (isHeader(params)) {
                            return 'center-cell-especial bg-edit';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "TsrIdentificationCode",
                    headerName: "CODE LIST \nIDENTIFICATION \nCODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 280,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TsrResponsibleAgencyCode",
                    headerName: "CODE LIST \nRESPONSIBLE \nAGENCY CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "YardNamePlanning",
                    headerName: "YARD",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Procedence",
                    headerName: "PROCEDENCE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "FgDirect",
                    headerName: "DIRECT DISCHARGE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 300,
                    cellClass: 'center-cell-especial',
                },
            ],
            search: '',
            pageSize: '',
            nulo:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;        
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.section=="total") {
            return 'bg-secondary font-weight-bold text-'
        }
    }

    function checkboxSelection(params) {
        return params.node.data.FgVgm;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;   
            
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            
        });
        this.loadingOverlay = false;
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.listOfPlannedVgmDetail'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.listOfPlannedVgmDetail'),valor);
            
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }  
        this.loadingOverlay = false;    
    }

    function buscarUnidad(e) {
        this.unitMeasureId=e.target.value;
        this.getVgmDetailList();
    }

    async function getVgmDetailList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;

        let VgmSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };

        await this.$http.post("StowagePlanningVgmDetail-list", VgmSumaryJson, { root: 'VgmSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            if (this.nulo=="null") return false;

            this.formatedItems.push({
                section: 'total',
                CtaFunctionCode: 'CONTACT INFORMATION',
                ComAddressIdentifier: 'COMMUNICATION CONTACT',
                HanDescriptionCode: 'HANDLING INSTRUCTIONS',
                TsrServicePriorityCode: 'TRANSPORT SERVICE REQUIREMENTS',
            });

            for (let i=0; i < listado.length; i++) {
                if (listado[i].Weigth!==undefined) {
                    this.formatedItems.push({
                        
                        IdX: listado[i].Nro,
                        ContainerCode: listado[i].ContainerCode ? listado[i].ContainerCode : '',
                        LoadPort: listado[i].LoadPort ? listado[i].LoadPort : '',
                        DischargePort: listado[i].DischargePort ? listado[i].DischargePort  : '',
                        ShippingLineCode: listado[i].ShippingLineCode ? listado[i].ShippingLineCode : '',
                        ShippingLineName: listado[i].ShippingLineName ? listado[i].ShippingLineName : '',
                        Status: listado[i].Status ? listado[i].Status : '',
                        TpCargoDetailCode: listado[i].TpCargoDetailCode ? listado[i].TpCargoDetailCode : '',
                        ImdgCode: listado[i].ImdgCode ? listado[i].ImdgCode : '',
                        Seals: listado[i].Seals ? listado[i].Seals : '', 
                        IsoCode: listado[i].IsoCode ? listado[i].IsoCode : '', 
                        DeliveryPort: listado[i].DeliveryPort ? listado[i].DeliveryPort : '', 
                        Weigth: listado[i].Weigth,
                        //FgVgm: listado[i].FgVgm==1 ? true : false,
                        CtaFunctionCode: listado[i].CtaFunctionCode ? listado[i].CtaFunctionCode : '',
                        CtaIdentifier: listado[i].CtaIdentifier ? listado[i].CtaIdentifier : '',
                        CtaName: listado[i].CtaName ? listado[i].CtaName : '',
                        ComAddressIdentifier: listado[i].ComAddressIdentifier ? listado[i].ComAddressIdentifier : '',
                        ComMeansTypeCode: listado[i].ComMeansTypeCode ? listado[i].ComMeansTypeCode : '',
                        HanDescriptionCode: listado[i].HanDescriptionCode ? listado[i].HanDescriptionCode : '',
                        HanIdentificationCode: listado[i].HanIdentificationCode ? listado[i].HanIdentificationCode : '',
                        HanResponsibleAgencyCode: listado[i].HanResponsibleAgencyCode ? listado[i].HanResponsibleAgencyCode : '',
                        HanInstructionDescription: listado[i].HanInstructionDescription ? listado[i].HanInstructionDescription : '',
                        TsrServicePriorityCode: listado[i].TsrServicePriorityCode ? listado[i].TsrServicePriorityCode : '',
                        TsrIdentificationCode: listado[i].TsrIdentificationCode ? listado[i].TsrIdentificationCode : '',
                        TsrResponsibleAgencyCode: listado[i].TsrResponsibleAgencyCode ? listado[i].TsrResponsibleAgencyCode : '',
                        Procedence: listado[i].Procedence,
                        YardNamePlanning: listado[i].YardNamePlanning, 
                        FgDirect: listado[i].FgDirectFgDirect? this.$t('label.yes') : this.$t('label.no')

                    })
                }
            }
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }


    async function refreshComponent(){
        this.loadingOverlay = true;
                    this.pageSize='';
                    this.unitMeasureId= '';
                    this.search="";
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getVgmDetailList();
                    //this.listarPuertos();
                    this.$emit('child-refresh',true);
                    this.loadingOverlay = false;
    }
    function isHeader(params) {
        return params.data.section === 'total';
    }

    export default {
        name: "vgm-detail",
        data,
        beforeMount,
        mounted,
        mixins: [Reportes, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnExport,
            getVgmDetailList,
            getUnitMeasureWeigthList,
            buscarUnidad,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            onBtnPdf,
            refreshComponent,
        },
        computed:{
            isHeader,
            checkboxSelection,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem,                   
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                user: state => state.auth.user,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==11) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>