<template>
  <div class="d-flex no-block justify-content-center align-items-center" >
    <div class="back"></div> 
      <notifications group="container" position="top right" :duration="5000" :max="5" :ignoreDuplicates="true" :closeOnClick="true" class="mt-3 mr-3" />
    
      <CRow class="auth-wrapper d-flex justify-content-center align-self-stretch auth-box" >
        <div class="p-2 box">
          <div class="logo"></div>
          <CCardBody>
            <transition name="fade">
              <CForm>
                    <h2 class="text-center mb-2 pb-2 black-letter">GESTIÓN DE CONTRASEÑA</h2>
                     
                    <div class="form-group" role="group" style="background: #fff;">
                      <CRow v-if="!isFromEmail" class="justify-content-center">
                        <CCol sm="12">
                          <CustomTabs justified :active-tab="0" :fade="false" @update:activeTab="handleTab" class="m-3" >
                            <CustomTab title="USUARIO" icon="user" :disabled="isValidCode || isValidUser">
                              <CRow  class="justify-content-center">
                                <CCol sm="10">
                                  <CInput
                                    v-model.trim="$v.user.login.$model"
                                    v-uppercase
                                    label="LOGIN"
                                    placeholder="USUARIO"
                                    addLabelClasses="text-right required"
                                    :horizontal="{label: 'col-sm-3', input:'col-sm-9'}"
                                    invalid-feedback="Campo requerido: Texto."
                                    :is-valid="hasError($v.user.login)"
                                    maxlength="50"
                                    :disabled="isValidUser"
                                  />
                                </CCol>
                                <CCol sm="10">
                                  <CInput 
                                    label="EMAIL"   
                                    placeholder="MAIL@EXAMPLE.COM"
                                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                                    addLabelClasses="required text-right"
                                    v-uppercase
                                    required
                                    invalid-feedback="Campo requerido."
                                    :is-valid="hasError($v.user.email)"
                                    v-model.trim="$v.user.email.$model"
                                    type="email"
                                    maxlength="100"
                                    :disabled="isValidUser"
                                  >
                                    <template #append>
                                      <CButton
                                        color="watch"
                                        size="sm"
                                        v-c-tooltip="{content: $t('label.validateUser'),placement: 'top'}"
                                       
                                        @click="submitVerifyUser"
                                      >
                                        <div v-if="!isSubmitVerifyUser">
                                          <CIcon name="checkAlt"/> 
                                        </div>
                                        <div v-if="isSubmitVerifyUser">
                                          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>
                                      </CButton>
                                    </template>
                                  </CInput>
                                </CCol>
                              </CRow>
                              <CCollapse :show="isValidUser">
                                <CRow alignHorizontal="center" class="">
                                  <CCol sm="10">
                                    <CInput
                                      v-model.trim="$v.user.code.$model"
                                      v-uppercase
                                      label="CODIGO"
                                      addLabelClasses="text-right required"
                                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                                      invalid-feedback="Campo requerido."
                                      :is-valid="hasError($v.user.code)"
                                      maxlength="50"
                                      :disabled="isValidCode"
                                    >
                                      <template #append>
                                          <CButton
                                            color="watch"
                                            size="sm"
                                            v-c-tooltip="{content: $t('label.validateCode'),placement: 'top'}"
                                            
                                            @click="submitVerifyCode"
                                          >
                                            <div v-if="!isSubmitVerifyCode">
                                              <CIcon name="checkAlt"/> 
                                            </div>
                                            <div v-if="isSubmitVerifyCode">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </div>
                                          </CButton>
                                      </template>
                                    </CInput>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                              <CCollapse :show="isValidCode">
                                <CRow alignHorizontal="center">
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="password" class="required text-right col-form-label col-sm-3 col-form-label">
                                        PASSWORD
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.password.$model"
                                          id="password"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.password)"
                                          placeholder="PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Invalid length
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="confirmPassword" class="required text-right col-form-label col-sm-3 col-form-label">
                                        CONFIRMAR
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.confirmPassword.$model"
                                          id="confirmPassword"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.confirmPassword)"
                                          placeholder="CONFIRMAR PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Password do not match
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                            </CustomTab>
                            <CustomTab title="CEDULA" :disabled="isValidCode || isValidUser">
                              <CRow class="justify-content-center">
                                <CCol sm="10">
                                  <CInput
                                    label="CEDULA"
                                    v-model.trim="$v.cedula.$model"
                                    invalid-feedback="Campo requerido: Solo numeros."
                                    :is-valid="hasError($v.cedula)"
                                    placeholder="CEDULA"
                                    :horizontal="{label: 'col-sm-3', input:'col-sm-9'}"
                                    addLabelClasses="text-right required"
                                    maxlength="8"
                                    :disabled="isValidUser"
                                  >
                                    <template #prepend>
                                      <CSelect
                                        :value.sync="$v.ciType.$model"
                                        :options="ciTypeOptions"
                                        class="mr-2 mb-0"
                                        :is-valid="hasError($v.ciType)"
                                      />
                                    </template>
                                  </CInput>
                                </CCol> 
                                <CCol sm="10" >
                                  <CInput 
                                    label="EMAIL"   
                                    placeholder="MAIL@EXAMPLE.COM"
                                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                                    addLabelClasses="required text-right"
                                    v-uppercase
                                    required
                                    invalid-feedback="Campo requerido."
                                    :is-valid="hasError($v.user.email)"
                                    v-model.trim="$v.user.email.$model"
                                    type="email"
                                    maxlength="100"
                                    :disabled="isValidUser"
                                  >
                                    <template #append>
                                      <CButton
                                        color="watch"
                                        size="sm"
                                        v-c-tooltip="{content: $t('label.validateUser'),placement: 'top'}"
                                        :disabled="isVerifyUserButtonInvalid"
                                        @click="submitVerifyUser"
                                      >
                                        <div v-if="!isSubmitVerifyUser">
                                          <CIcon name="checkAlt"/> 
                                        </div>
                                        <div v-if="isSubmitVerifyUser">
                                          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>
                                      </CButton>
                                    </template>
                                  </CInput>
                                </CCol>
                              </CRow>
                              <CCollapse :show="isValidUser">
                                <CRow alignHorizontal="center" class="">
                                  <CCol sm="10">
                                    <CInput
                                      v-model.trim="$v.user.code.$model"
                                      v-uppercase
                                      label="CODIGO"
                                      addLabelClasses="text-right required"
                                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                                      invalid-feedback="Campo requerido."
                                      :is-valid="hasError($v.user.code)"
                                      maxlength="50"
                                      :disabled="isValidCode"
                                    >
                                      <template #append>
                                          <CButton
                                            color="watch"
                                            size="sm"
                                            v-c-tooltip="{content: $t('label.validateCode'),placement: 'top'}"
                                            :disabled="isValidateButtonInvalid"
                                            @click="submitVerifyCode"
                                          >
                                            <div v-if="!isSubmitVerifyCode">
                                              <CIcon name="checkAlt"/> 
                                            </div>
                                            <div v-if="isSubmitVerifyCode">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </div>
                                          </CButton>
                                      </template>
                                    </CInput>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                              <CCollapse :show="isValidCode">
                                <CRow alignHorizontal="center">
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="password" class="required text-right col-form-label col-sm-3 col-form-label">
                                        PASSWORD
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.password.$model"
                                          id="password"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.password)"
                                          placeholder="PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Invalid length
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="confirmPassword" class="required text-right col-form-label col-sm-3 col-form-label">
                                        CONFIRMAR
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.confirmPassword.$model"
                                          id="confirmPassword"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.confirmPassword)"
                                          placeholder="CONFIRMAR PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Password do not match
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                            </CustomTab>
                          </CustomTabs>
                        </CCol>          
                      </CRow>
                      <CRow v-if="isFromEmail">
                        <CCol sm="12">
                          <CustomTabs justified :active-tab="0" :fade="false" class="m-3">
                            <CustomTab title="VALIDAR USUARIO" icon="user">
                              <CCollapse :show="isValidUser && isFromEmail">
                                <CRow alignHorizontal="center" class="">
                                  <CCol sm="10">
                                    <CInput
                                      v-model.trim="$v.user.code.$model"
                                      v-uppercase
                                      label="CODIGO"
                                      addLabelClasses="text-right required"
                                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                                      invalid-feedback="Campo requerido."
                                      :is-valid="hasError($v.user.code)"
                                      maxlength="50"
                                      :disabled="isValidCode"
                                    >
                                      <template #append>
                                          <CButton
                                            color="watch"
                                            class="mt-0 pt-0"
                                            v-c-tooltip="{content: $t('label.validateCode'),placement: 'top'}"
                                            :disabled="isValidateButtonInvalid"
                                            @click="submitVerifyCode"
                                          >
                                            <div v-if="!isSubmitVerifyCode">
                                              <CIcon name="checkAlt"/> 
                                            </div>
                                            <div v-if="isSubmitVerifyCode">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </div>
                                          </CButton>
                                      </template>
                                    </CInput>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                              <CCollapse :show="isValidCode && isFromEmail">
                                <CRow alignHorizontal="center">
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="password" class="required text-right col-form-label col-sm-3 col-form-label">
                                        PASSWORD
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.password.$model"
                                          id="password"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.password)"
                                          placeholder="PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Invalid length
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="10">
                                    <div class="form-group form-row" role="group">
                                      <label for="confirmPassword" class="required text-right col-form-label col-sm-3 col-form-label">
                                        CONFIRMAR
                                      </label>
                                      <div class="col-sm-9 input-group">
                                        <input
                                          v-model="$v.user.confirmPassword.$model"
                                          id="confirmPassword"
                                          :type="inputType"
                                          class="form-control"
                                          :class="inputClasses($v.user.confirmPassword)"
                                          placeholder="CONFIRMAR PASSWORD"
                                        >
                                        <label
                                          for="password"
                                          class="btn input-group-text input-icon-append"
                                          @click.prevent="togglePassword"
                                        >
                                          <CIcon v-if="!showPassword" name="cil-eye"/>
                                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                                        </label>
                                        <div class="invalid-feedback">
                                          Password do not match
                                        </div>
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>
                              </CCollapse>
                            </CustomTab>
                          </CustomTabs>
                        </CCol>          
                      </CRow>
                      <CRow class="m-3">
                        <CCol sm="12" class="d-flex center align-items-center mb-3">
                          <CButton
                            color="add"
                            :disabled="isSubmitInvalid"
                            class="d-flex align-items-center mr-2"
                            @click.stop="submit"
                          >
                            <div v-if="!isSubmit">
                              <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                            </div>
                            <div v-if="isSubmit">
                              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span class="ml-1">{{$t('button.accept')}}</span>
                            </div>
                          </CButton>
                          <CButton
                            color="wipe"
                            :disabled="isSubmit"
                            class="d-flex align-items-center"
                            @click.stop="cancelForm"
                          >
                            <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                          </CButton>
                        </CCol>
                      </CRow>
                    </div>
                  </CForm>
            </transition>
          </CCardBody>
        </div>
      </CRow>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import RecoveryFormValidations from '@/_validations/auth/RecoveryFormValidations';
import GeneralMixin from '@/_mixins/general';
import AuthMixin from '@/_mixins/auth';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

//Data
function data() {
  return {
    isSubmitVerifyUser: false,
    isSubmitVerifyCode: false,
    isSubmit: false,
    user: {
      login: '',
      email: '',
      code: '',
      password: '',
      confirmPassword: ''
    },
    validateToken: '',
    isValidCode: false,
    isValidUser: false,
    cedula: '',
    ciType: 'V',
    activeTab: 0
  }
}

//Created
function created() {
  //console.log(this.$route.query);
  if(this.isFromEmail){
    this.isValidUser = true;
    this.validateToken = this.$route.query.token;
    this.user.login = this.$route.query.CiLogin;
  }
}

//Methods:
function submitVerifyUser() {
  this.isSubmitVerifyUser = true;
  let userData = {
    CiLogin: (this.activeTab==0)?this.user.login:`${this.ciType}${this.cedula}`,
    Email: this.user.email
  };
  this.$store.dispatch('auth/verifyUser', userData)
  .then((response) => {
    if (response.token==undefined) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: response.msg,
        type: "error"
      });
    } else {
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.msg,
        type: "success"
      });
      this.user.code = response.code;
      this.validateToken = response.token;
      this.isValidUser = true;
    }
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmitVerifyUser = false;
  });
}
function submitVerifyCode() {
  this.isSubmitVerifyCode = true;
  let userData = {
    token: this.validateToken,
    code: this.user.code
  };
  this.$store.dispatch('auth/verifyCode', userData)
  .then((response) => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.msg,
      type: "success"
    });
    this.isValidCode = true;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmitVerifyCode = false;
  });
}
function submit() {
  this.isSubmit = true;
  let userData = {
    token: this.validateToken,
    Password: this.user.password
  };
  this.$store.dispatch('auth/changePassword', userData)
  .then((response) => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.msg,
      type: "success"
    });
    this.$router.push('/login');
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.isSubmit = false;
  });
}
function cancelForm() {
  this.$store.dispatch('connection/delete');
  this.$router.push({ name: 'Login' });
}

function handleTab(tab) {
  this.activeTab = tab;
  if(tab == 0){
    this.cedula = '';
    this.ciType = 'V';
    this.$v.cedula.$reset();
    this.$v.user.login.$touch();
  }else {
    this.user.login = '';
    this.$v.user.login.$reset();
    this.$v.cedula.$touch();
  }
}

//Computed:
function isSubmitInvalid() {
  return this.$v.user.password.$invalid || this.$v.user.confirmPassword.$invalid || this.isSubmit;
}
function isFromEmail() {
  if(Object.entries(this.$route.query).length != 0){
    return this.$route.query.token && this.$route.query.CiLogin;
  }else
    return false;
}
function userName() {
  return this.isFromEmail
    ? this.$route.query.CiLogin?this.$route.query.CiLogin:''
    :'';
}
function isVerifyUserButtonInvalid(){
  if(this.activeTab == 0)
    return this.$v.user.login.$invalid || this.$v.user.email.$invalid || this.isSubmitVerifyUser || this.isValidUser;
  else
    return this.$v.cedula.$invalid || this.$v.user.email.$invalid || this.isSubmitVerifyUser || this.isValidUser;
}
function isValidateButtonInvalid(){
  return this.$v.user.code.$invalid || this.$v.validateToken.$invalid || this.isSubmitVerifyCode || this.isValidCode;
}
function ciTypeOptions() {
  return [
    {
      value: 'V',
      label: 'V'
    },
    {
      value: 'P',
      label: 'P'
    },
    {
      value: 'E',
      label: 'E'
    }
  ]
}

export default {
  name: 'Recovery',
  mixins: [GeneralMixin, AuthMixin],
  data,
    components: {
    CustomTabs,
    CustomTab
  },
  validations: RecoveryFormValidations,
  directives: UpperCase,
  created,
  methods: {
    submit,
    cancelForm,
    submitVerifyUser,
    submitVerifyCode,
    handleTab
  },
  computed: {
    isSubmitInvalid,
    isFromEmail,
    userName,
    isVerifyUserButtonInvalid,
    isValidateButtonInvalid,
    ciTypeOptions
  }
}
</script>

<style scoped>
.input-icon {
  padding-left: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prep {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prepend {
  position: absolute;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  z-index: 5;
}
.input-icon-append {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(1.2em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  z-index: 5;
  box-shadow: none;
}
.input-icon-append:hover {
  color: #000;
}

.es-valido {
  border-color: #2eb85c;
}
.es-invalido {
  border-color: #e55353;
}
.es-invalido ~ .invalid-feedback {
  display: block;
}
.form-control.es-valido:focus {
  border-color: #2eb85c;
  box-shadow: 0 0 0 .2rem rgba(46,184,92,.25);
}
.form-control.es-invalido:focus {
  border-color: #e55353;
  box-shadow: 0 0 0 .2rem rgba(229,83,83,.25);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}







.class-label {
  position: absolute;
  top: 7px;
  left: 33px;
  width: 100%;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
  z-index: 5;
}

.input-icon {
  padding-left: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prep {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prepend {
  position: absolute;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  z-index: 5;
}
.input-icon-append {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  z-index: 5;
  box-shadow: none;
}


.es-invalido .invalid-feedback {
  display: block;
}

.parrafo {
  font-size: medium;
}

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.box{
  width: 90%;
}

.auth-wrapper .auth-box.on-sidebar {
  
    top: 0;
    right: 0;
    height: 100%;
    min-height: 500px;
    margin: 0;
    position: absolute;
}

.auth-box {
    width: 650px;
    min-width: 500px ;
    background: #D2CFCA;
   z-index: 2;
   height: 100% !important;
   position:absolute;
   border-left: 8px #EC631B solid;
   border-right: 8px #EC631B solid;
}

.input-field {
  position: relative;
}


.es-valido .form-control,
.es-valido .form-control:focus{
  border-color: #2eb85c;
}

.es-invalido .form-control,
.es-invalido .form-control:focus{
  border-color: #e55353;
}

.input-field:hover .input-group-text{
  color: #2eb85c;
}
.input-field .form-control:focus,
.input-field:not(.es-invalido):hover .form-control{
  border-color: #2eb85c !important;
  box-shadow: 0 0 0 .2rem rgba(46,184,92,.25);
}
.es-invalido:hover .input-group-text {
  color: #e55353;
}

.es-invalido .form-control:focus,
.es-invalido:hover .form-control{
  border-color: #e55353;
  box-shadow: 0 0 0 .2rem rgba(229,83,83,.25);
}


input:focus~.class-label,
input:valid~.class-label  {
  font-size: 14px;
  top: -24px;
  left: 0;
  color: #3c4b64;
}

.black-letter{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
   font-weight: bold;
}

.black-letter-select{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  font-size: 14px !important;
}

h2{
  font-weight: bold;
  font-size: 28px;
}



.italic-letter{
  font-family: 'Barlow Condensed', sans-serif !important;
  font-weight:lighter;
  font-style: italic;
  font-size: 14px;
}






















.custom-tab-content {
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  box-shadow: 0 !important;

}

</style>