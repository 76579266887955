import { required } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5 } from '@/_validations/validacionEspeciales'
import { DateFormater } from "@/_helpers/funciones";

export default (PreviousDate, LaterDate) => {
    return {
        Block: { required },
        MachineId: { required },
        AreaId: {required},
        BayJson:{},
        TransactionDate: { required, RangeDate(value){
            if (PreviousDate && LaterDate) {
                return(DateFormater.formatDateTimeWithoutSlash(value) > PreviousDate && DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate)
            }else{
                return false;
            }
        } },
        Observation: { onlyAlphanumeric5 },
        CheckedContainer: { required },
    }
}