import ENUM from "./enum";
import service from "@/_services/service";
import { DateFormater, validURL } from "@/_helpers/funciones";
import { isNull } from "lodash";

export const yardsetting = {
  namespaced: true,
  state: {
    yardSelected: null,
    yardConfigSelected: null,
    apiState: ENUM.INIT,
    tabIndex: 0,
    collapse: false,
    configCollapse: false,
    isHidden: false,
    dropItem: 0,
    isSubmit: false,
    isSubmitEdit: false,
    printing: false,
    dragging: false,
    search: '',
  },
  getters: {

  },
  actions: {

  },
  mutations: {
    menuVisibility(state, { check, type }) {
      state.isHidden = check;
    },
  },
};