export const dataModule = [
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 1,
  },
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 0,
  },
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 0,
  },
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 0,
  },
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 1,
  },
  {
    idModulo: 1,
    idTpModulo: 1,
    IdGpoModulo: 1,
    NbModulo: "string",
    DsModulo: "string",
    RutaProceso: "string",
    RutaManual: "string",
    RouteModulo: "string",
    Orden: 0,
    FgActModulo: 0,
  },
];
