<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
    <modalAddIncidenceImages 
      :edit="false" 
      :modal="ShowModalAddImage" 
      @closeModal="ShowModalAddImage=false" 
      @child-refresh="refrescarTabla=true" 
    />
    <modalListIncidenceImages 
      :anulled="isAnulled"
      :modal="ShowModalImageList" 
      @closeModal="ShowModalImageList=false" 
      @child-refresh="refrescarTabla=true" 
    />
    <modalIncidence
      :anulled="isAnulled"
      :modal = "ShowModalIncidence" 
      :order-id = "$store.state.visitas.OrderId" 
      :circuit-id = "circuitId" 
      :order-date = "orderDate" 
      :orderData = "orderDataById[0]"
      @closeModal = "ShowModalIncidence=false" 
      @child-refresh = "refrescarTabla=true"     
    />

    <CCol sm="12">
      <CRow class="mb-3">
        <CCol sm="8" class="d-flex align-items-center ">
          <CButton
              color="edit"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="closeCollapse"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton>
            <h6 class="mt-2"> {{ $t('label.incidences')+' - '+$t('label.noOrder')+': '+orderNumber }}</h6>
        </CCol>  
        <CCol sm="4" class="d-flex align-items-center justify-content-end ">
          <h6 class="text-warning mr-3 mt-2">
              <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.incidenceOpen')}}&nbsp;
          </h6> 
          <CButton 
            v-if="!isAnulled"
            color="add"
            size="sm"
            v-c-tooltip="{
                content: $t('label.incidence'),
                placement: 'top',
              }"
            @click="ShowModalIncidence=true"
            >
            <CIcon name="cil-playlist-add"/>{{ $t('label.nueva')}}
          </CButton>
        </CCol>
      </CRow>
      
      <dataTableExtended
        class="align-center-row-datatable"
        :fields="fields"
        :items="itemsOrder"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingOverlay"
        hover
        sorter
      >
        <template #loading>
          <loading/>
        </template>
        <template #alertGif="{ item }">
          <td class="text-center">
            <div class="animation-item ml-2" style="display: inline-block;" v-if="item.TransactionBegin != '' && item.TransactionFinish == '' && item.FgActOrderCircuit && item.Status != 'INACTIVO'">
                <h3 class="text-danger">
                    <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                </h3>
            </div>
          </td>
        </template>
        <template #Nro="{ item }">
          <td class="text-center">
            {{ item.Nro }}
          </td>
        </template>

        <template #Detalle="{ item }">
          <td class="text-center" v-if="!item.FgEndOperation">
            <CButton
              size="sm"
              color="edit"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+$t('label.incidence'),
                placement: 'top-end',
              }"
              @click="ShowModalIncidence=item"
            >
              <CIcon name="pencil"/>
            </CButton>
            <CButton
              v-if="!isAnulled && item.Status == 'ACTIVO'"
              size="sm"
              color="add"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.add')+$t('label.image'),
                placement: 'top-end',
              }"
              @click="ShowModalAddImage=item"
            >
              <CIcon name="photo"/>
            </CButton>
            <CButton
              size="sm"
              color="watch"
              v-c-tooltip="{
                content: $t('label.see')+$t('label.image'),
                placement: 'top-end',
              }"
              v-if="item.DocumentJson.length > 0"
              @click="ShowModalImageList=item"
            >
              <CIcon name="eye"/>
            </CButton>
          </td>
          <td v-else></td>
        </template>
        <template #Status="{item}">
          <td class="text-center">
            <div>
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </div>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import modalAddIncidenceImages from './modal-add-incidence-images';
import modalListIncidenceImages from './modal-list-incidence-images';
import modalIncidence from './modal-incidence';

function data() {
  return {
    itemsOrder: [],
    loadingOverlay: false,
    ShowModalAddImage: false,
    ShowModalImageList: false,
    ShowModalIncidence: '',
    refrescarTabla: false,
    isAnulled: false,
    VisitId: '',
    orderNumber: '',
    circuitId: '',
    orderDate: '',
    nulo:'', 
    orderDataById: [],
  };
}

function fields(){
  return [
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:2%; text-align:center'  },
    { key: 'IncidenceName', label:this.$t('label.incidenceType'), _style: 'width:14%;', _classes: 'text-center'},
    { key: 'TransactionBegin', label:this.$t('label.from'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TransactionFinish', label:this.$t('label.to'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TransactionDate', label:this.$t('label.incidenceDate'), _style: 'width:11%;', _classes: 'text-center'},
    { key: 'TotalTime', label:this.$t('label.duration'),_style: 'width:8%;', _classes: 'text-center' },
    { key: 'DeviceCode', label:this.$t('label.device'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TransaLogin', label:this.$t('label.checker'), _style: 'width:10%;', _classes: 'text-center'},
    { key: 'TransaRegDate', label:this.$t('label.transactionDate'), _style: 'width:11%;', _classes: 'text-center'},
    { key: 'Status', label:this.$t('label.status'), _style: 'width:8%;', _classes: 'text-uppercase, text-align:center'},
    { key: 'Detalle', label: '',sorter: false,filter: false,_style: 'min-width:135px; width:15%;  '},
  ];
}

async function getIncidenceList(valor) {
  this.loadingOverlay = true;
  let _lang = this.$i18n.locale;  
  let listado = Array;

  await this.$http.get("VisitShipmentOrderIncidence-list", { OrderId: valor })
    .then(response => {
      listado = [...response.data.data];
      
      this.itemsOrder = listado.map(listado => Object.assign({}, this.itemsOrder, {
        Nro: Number(listado.Nro),
        IncidenceName: _lang=='en' ? listado.IncidenceNameEn : listado.IncidenceNameEs,
        TotalTime: listado.TotalTimeH ? listado.TotalTimeH : '',
        TransactionBegin: listado.TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado.TransactionBegin) : '',
        TransactionFinish: listado.TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado.TransactionFinish) : '',
        DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
        TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
        Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : '',
        TransaRegDate: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlash(listado.TransaRegDate) : '',
        TransactionDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : '',
        Status: listado.Status ? listado.Status : '',
        DocumentJson: listado.DocumentJson ? listado.DocumentJson : '',
        IncidenceId: listado.IncidenceId ? listado.IncidenceId : '',
        insertedid: listado.insertedid ? listado.insertedid : '',
        TransaUserId: listado.TransaUserId ? listado.TransaUserId : '',
        TpOrderId: listado.TpOrderId ? listado.TpOrderId : '',
        FgActOrderCircuit: listado.FgActOrderCircuit ? listado.FgActOrderCircuit : '',
        OrderId: listado.OrderId ? listado.OrderId : '',
        Observation: listado.Observation ? listado.Observation : '',
        DeviceId: listado.DeviceId ? listado.DeviceId : '',
        OrderCircuitId: listado.OrderCircuitId ? listado.OrderCircuitId : '',
        CircuitId: listado.CircuitId ? listado.CircuitId : '',
        OrderNumber: listado.OrderNumber ? listado.OrderNumber : '',
        FgActOrderCircuit: listado.FgActOrderCircuit,
        FgEndOperation: listado.FgEndOperation,
      }));
      /*if(this.itemsOrder.length > 0){
        this.itemsOrder[0].OrderCode;
      }*/
    }).catch( err => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
  this.loadingOverlay = false;
}

function closeCollapse(){
  this.$emit('closeCollapseIncidence', 0);
  this.$emit('child-refresh', true);
}

function getOrderDataById() {
  this.loadingOverlay = true;
  this.$http.ejecutar("GET", 'VisitShipmentOrder-by-id', { OrderId: this.$store.state.visitas.OrderId })
    .then( response => {
      let listado = [...response.data.data];
        this.nulo = String(listado[0].Json);

        if (this.nulo=="null" || listado =='') return false;
        
        this.orderDataById =  listado.map((item) => {
          return {
            ...item,
          };
        });
        this.orderNumber = this.orderDataById[0].OrderCode;
    })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
    });
  });
  this.loadingOverlay = false; 
}
export default {
  name: 'index-incidence',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    modalAddIncidenceImages,
    modalListIncidenceImages,
    modalIncidence,
  },
  methods: {
    getIncidenceList,
    closeCollapse,
    getOrderDataById,
  }, 
  props: {
    orderData: null,
    containerData: {
      type: String,
    },
  }, 
  watch: {
    orderData: function(newVal) {
      if(newVal){
        this.orderNumber = this.orderData.OrderCode;
        this.circuitId = this.orderData.CircuitId;
        this.$store.state.visitas.OrderId = this.orderData.OrderId;
        this.orderDate = this.orderData.OrderDate;
        this.isAnulled = (this.orderData.OrderStatus === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7') ? true : false;
        this.getIncidenceList(this.$store.state.visitas.OrderId);
        this.getOrderDataById();
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.orderDataById = [];
        this.refrescarTabla = false;
        this.getIncidenceList(this.$store.state.visitas.OrderId);
        this.getOrderDataById();
      }
    },
  },
  computed: {
    fields,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemOrder: state => state.visitas.dropItemOrder,
        OrderId: state => state.visitas.OrderId,
    })
  },
};
</script>
<style lang="scss">
    .animation-item{
        -webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
    }
    /**
    * ----------------------------------------
    * animation pulse
    * ----------------------------------------
    */
    @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
    @keyframes pulse{
        0%,
        100% {
            transform:scale(1.1)
        }
        50% {
            transform:scale(.8)
        }
    }
</style>
