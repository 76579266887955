<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
    <modalCircuit
      :anulled="isAnulled"
      :modal="ShowmodalCircuit" 
      :order = "order"
      :order-date = "orderDate" 
      :orderData = "orderData" 
      :circuitData= "circuitData"
      @closeModal="ShowmodalCircuit=false" 
      @child-refresh="refrescarTabla=true"  
    />

    <CCol sm="12">
      <CRow class="mb-3">
        <CCol sm="8" class="d-flex align-items-center">
          <CButton
              color="edit"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="closeCollapse"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton>
            <h6 class="mt-2"> {{ $t('label.circuits')+' - '+$t('label.noOrder')+': '+orderNumber }}</h6>
        </CCol>  
        <CCol sm="4" class="d-flex align-items-center justify-content-end ">
          <CButton 
            v-if="!isAnulled"
            color="add"
            size="sm"
            v-c-tooltip="{
                content: $t('label.circuit'),
                placement: 'top',
              }"
            @click="ShowmodalCircuit=true"
            >
            <CIcon name="cil-playlist-add"/>{{ $t('label.nuevo')}}
          </CButton>
        </CCol>
      </CRow>
      
      <dataTableExtended
        class="align-center-row-datatable"
        :fields="fields"
        :items="itemsCircuit"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingTable"
        hover
        sorter
      >
        <template #loading>
          <loading/>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center" >
            <div v-if="item.CircuitId !== 'C36C691E-60D0-44A1-BE92-8B4296E8A071'">
              <CButton
                size="sm"
                color="edit"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.circuit'),
                  placement: 'top-end',
                }"
                @click="ShowmodalCircuit=item"
                v-if="!item.FgWeighingScale"
              >
                <CIcon name="pencil"/>
              </CButton>
            </div>
          </td>
        </template>
        <template #Status="{item}">
          <td class="text-center">
            <div>
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </div>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import modalCircuit from './modal-circuit';

function data() {
  return {
    itemsCircuit: [],
    loadingOverlay: false,
    loadingTable: false,
    ShowmodalCircuit: false,
    refrescarTabla: false,
    isAnulled: false,
    VisitId: '',
    orderNumber: '',
    order: '',
    orderDate: '',
    orderData: '',
  };
}

function fields(){
  return [
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:2%; text-align:center'  },
    { key: 'CircuitName', label:this.$t('label.circuitName'), _style: 'width:14%;', _classes: 'text-center'},
    { key: 'TransactionBegin', label:this.$t('label.from'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TransactionFinish', label:this.$t('label.to'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TransactionDate', label:this.$t('label.transactionDate'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'Weigth', label:this.$t('label.weight')+' '+'KGM',_style: 'width:10%;', _classes: 'text-center' },
    { key: 'TotalTime', label:this.$t('label.duration'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'CraneAlias', label:this.$t('label.crane'),_style: 'width:10%;', _classes: 'text-center' },
    { key: 'VesselHoldName', label:this.$t('label.hold'), _style: 'width:12%;', _classes: 'text-center'},
    { key: 'DeviceCode', label:this.$t('label.device'), _style: 'width:12%;', _classes: 'text-center'},
    { key: 'TransaLogin', label:this.$t('label.user'), _style: 'width:12%;', _classes: 'text-center'},
    { key: 'Fecha', label:this.$t('label.registerDate'), _style: 'width:12%;', _classes: 'text-center'},
    { key: 'Status', label:this.$t('label.status'), _style: 'width:10%;', _classes: 'text-center; text-align:center'},
    { key: 'Detalle', label: '',sorter: false,filter: false,_style: 'min-width:45px; width:8%;  '},
  ];
}


async function getCircuitList(valor) {
  this.loadingOverlay = true;
  let _lang = this.$i18n.locale;  
  let listado = Array;
  await this.$http.get("VisitShipmentOrderCircuit-list", { OrderId: valor })
    .then(response => {
      listado = [...response.data.data];

      let i = 1;
      this.itemsCircuit = listado.map(listado => Object.assign({}, this.itemsCircuit, {
        Nro: i++,
        CircuitName: _lang=='en' ? listado.CircuitNameEn : listado.CircuitNameEs,
        TransactionBegin: listado.TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado.TransactionBegin) : '',
        TransactionFinish: listado.TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado.TransactionFinish) : '',
        TruckWeighingScaleId: listado.TruckWeighingScaleId ? listado.TruckWeighingScaleId : '',
        YardId: listado.YardId ? listado.YardId : '',
        Weigth: listado.Weigth ? NumberFormater.formatNumber(listado.Weigth, 2) : '0,00',
        TotalTime: listado.TotalTimeH ? listado.TotalTimeH : '',
        CraneAlias: listado.CraneAlias ? listado.CraneAlias : '',
        VesselHoldName: listado.VesselHoldName ? listado.VesselHoldName : '',
        DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
        TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
        Fecha: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlash(listado.TransaRegDate) : '',
        TransactionDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : '',
        Status: listado.Status ? listado.Status : '',
        OrderCircuitId: listado.OrderCircuitId ? listado.OrderCircuitId : '',
        CircuitId: listado.CircuitId ? listado.CircuitId : '',
        MachineId: listado.MachineId ? listado.MachineId : '',
        Observation: listado.Observation ? listado.Observation : '',
        VesselHoldId: listado.VesselHoldId ? listado.VesselHoldId : '',
        VisitCraneId: listado.VisitCraneId ? listado.VisitCraneId : '',
        FgActOrderCircuit: listado.FgActOrderCircuit ? true : false,
        FgWeighingScale: listado.FgWeighingScale ? true : false,
        YardClientTpId: listado.YardClientTpId ? listado.YardClientTpId : '',
 

      })); 
    }).catch( err => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    this.loadingOverlay = false;
}

function closeCollapse(){
  this.$emit('closeCollapseCircuit', 0);
  this.$emit('child-refresh', true);
}


export default {
  name: 'index-circuit',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    modalCircuit,
  },
  methods: {
    getCircuitList,
    closeCollapse,
  }, 
  props: {
    circuitData: null,
    containerData: {
      type: String,
    },
  }, 
  watch: {
    circuitData: function(newVal) {
      if(newVal){
        this.orderNumber = this.circuitData.OrderCode;
        this.order = this.circuitData.OrderId;
        this.orderDate = this.circuitData.OrderDate;
        this.orderData = this.circuitData;
        this.isAnulled = (this.circuitData.OrderStatus === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7') ? true : false;

        this.getCircuitList(this.circuitData.OrderId);
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.refrescarTabla = false;
        this.getCircuitList(this.circuitData.OrderId);
      }
    },
  },
  computed: {
    fields,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemOrder: state => state.visitas.dropItemOrder,
    })
  },
};
</script>
<style scope>
</style>
