<template>
  <CModalExtended
    :title="$t('label.MOVIMIENTO.observation')"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-content-mov"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    :value="observation"
                    v-uppercase
                    :horizontal="{ input: 'col-sm-12 col-lg-12'}"
                    :placeholder="$t('label.MOVIMIENTO.observation')"
                    addLabelClasses="text-right"
                    rows="4"
                    disabled
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>         
    </form>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  
  //Data
  function data() {
    return {
      modalActive: this.modal,
    }
  }
  
  //Methods
  function toggle(newVal) {
    this.modalActive = newVal;
  }
  
  //Computeds:
  
  export default {
    name: 'observation-modal',
    mixins: [
      ModalMixin
    ],
    props: {
      modal: Boolean,
      observation: {
        type: String,
        default: ''
      }
    },
    data,
    directives: UpperCase,
    methods: {
      toggle,
    },
    computed: {
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      }
    }
  }
  </script>
  <style lang="scss">
  .card-simple{
    border: 0px;
    margin-bottom: 0 !important;
  }
  .modal-content-mov {
    .modal-content {
      width: 70% !important;
      margin: auto !important;
      .modal-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }
    }
  }
  </style>