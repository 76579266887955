<template>
    <div id="chart">
        <apexchart type="line" height="380px" width="690px" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    function series(){
        return this.seriesGrafica
    }
    function chartOptions(){
        return {
            chart: {
                height: 350,
                type: 'line',
            },
            stroke: {
                width: [2, 5, 5],
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1,2]
            },
            colors:['#706DFB','#6DE0BA','#3E475B'],
            labels: this.categoriesGrafica,
            xaxis: {
                title: {
                    text: this.titleXavis,
                }
            },
            yaxis: [{
                title: {
                    text: this.titleYavis,
                },}
            ],
            fill: {
                opacity: [0.25, 1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            legend: {
                position: 'top',
            }
        }
          
    }
    export default {
        name:'ColumnwithGroupLabel',
        props: {
            textSize: {
                type: String,
                required: false,
                default: '13px',
            },
            seriesGrafica: {
                type: Array,
                required: true,
                default: () => [],
            },
            categoriesGrafica: {
                type: Array,
                required: true,
                default: () => [],
            },
            titleXavis: {
                type: String,
                required: false,
                default: undefined,
            },
            titleYavis: {
                type: String,
                required: false,
                default: undefined,
            }
        },
        computed:{
            series,
            chartOptions
        },
    }
</script>