import {
  required,
  helpers,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

import { textareaSpecials } from '../funciones';

const alphaNumCustom = (value) => !helpers.req(value) || /^[\w\u00f1\u00d1\-]+$/g.test(value);
const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);

export const UserErrorValidations = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: {  },
      VisitCraneId: {  },
      CancelTransacReasonId: { required },
      VesselBayPosId: { required },
      MovStowageReasonId: {  },
      MovStowageReasonDs: {  },
      Observation: { required, textareaSpecials }
    },
    bay: { required },
  }
}

export const CraneValidations = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: {  },
      VisitCraneId: { required },
      CancelTransacReasonId: { required },
      VesselBayPosId: { required },
      MovStowageReasonId: { required },
      MovStowageReasonDs: { },
      Observation: { required, textareaSpecials }
    },
    bay: { required },
  }
}

export const UserErrorLoad = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: { },
      VisitCraneId: { },
      CancelTransacReasonId: { required },
      VesselBayPosId: { },
      MovStowageReasonId: { },
      MovStowageReasonDs: { },
      Observation: { required, textareaSpecials }
    },
    bay: { },
  }
}

export const CraneLoad = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: { },
      VisitCraneId: { required },
      CancelTransacReasonId: { required },
      VesselBayPosId: { },
      MovStowageReasonId: { required },
      MovStowageReasonDs: { },
      Observation: { required, textareaSpecials }
    },
    bay: { },
  }
}

export const UserErrorRestow = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: { },
      VisitCraneId: { required },
      CancelTransacReasonId: { required },
      VesselBayPosId: { },
      MovStowageReasonId: { },
      MovStowageReasonDs: { },
      Observation: { required, textareaSpecials }
    },
    bay: { required },
  }
}

export const CraneRestow = () =>  {
  return {
    reversePosition: {
      VisitCraneDetailId: { },
      VisitCraneId: { required },
      CancelTransacReasonId: { required },
      VesselBayPosId: { required },
      MovStowageReasonId: { },
      MovStowageReasonDs: { },
      Observation: { required, textareaSpecials }
    },
    bay: { required },
  }
}