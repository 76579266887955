<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended :closeOnBackdrop="false" :show.sync="ModalActive" @update:show="$emit('Close')">
      <template slot="header">
        <div class="header-alert">
          <div
            class="swal2-icon swal2-warning swal2-icon-show"
            style="display: flex"
          >
            <div class="swal2-icon-content">!</div>
          </div>
          <div class="flex-grow-1 msg">
            {{ `${$t("label.the")} ${$t("label.file")}`  }}
            <span class="msg-name">{{ File?.FileName ?? '' }}</span>
            {{ `${$t("label.successUpload")}` }}
          </div>
        </div>
      </template>
      <div style="color: #000">
        <div class="main-alert">
          <div class="correct">
            {{ getCorrectCount }} {{ $t("label.rightRows") }}
          </div>
          <div class="errors" v-if="getErrorsCount != 0">
            {{ getErrorsCount }} {{ $t("label.wrongRows") }}
          </div>
          <div class="footer-errors" v-if="getErrorsCount != 0">
            {{ `(${$t("label.errorsStaff")})` }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <CButton
          shape="square"
          color="success"
          class="mr-2"
          @click="onBtnExport()"
          v-if="getErrorsCount != 0"
        >
          <CIcon name="cil-file-excel" />&nbsp; XLSX
        </CButton>
        <CButton shape="square" color="wipe" @click="$emit('Close')">
          <CIcon name="x" />&nbsp; {{ $t("button.exit") }}
        </CButton>
      </div>
    </CModalExtended>
  </div>
</template>
<script>
import Reportes from '@/_mixins/reportes';
import { mapState } from 'vuex';
//DATA
function data() {
  return {
    Loading: false,
    ModalActive: false,
  };
}

// METHODS
async function onBtnExport() {
  this.Loading = true;
  let title = this.File.FileName ?? '';
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let ErrorJson = [];
  let TableHeader = {
    label: [],
    key: [],
  }
  if (this.File.StowagePlanningBlJson && this.File.StowagePlanningBlJson.length != 0) {
    await this.Field.forEach(item =>{
      TableHeader.label.push(item.label);
      TableHeader.key.push(item.key);
    });
    if (this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CONTAINER_LIST) {
      ErrorJson = this.File?.StowagePlanningBlJson?.filter(item => item.CargoJson&&item.CargoJson[0].FgError)?.map((item, index) => {
        let CargoJson = item?.CargoJson&&item?.CargoJson[0] ? item?.CargoJson[0] : {};
        return {
          Nro: index+1,
          ContainerCode: CargoJson.ContainerCode ?? '',
          BlNro: CargoJson.BlNro ?? '',
          TpCargoCode: CargoJson.TpCargoCode ?? '',
          Consignee: CargoJson.Consignee ?? '',
          IsoCode: CargoJson.IsoCode ?? '',
          Status: CargoJson.TpCargoStatusName ?? '',
          Seals: `S1:${CargoJson.Seal1??'N/A'}, S2:${CargoJson.Seal2??'N/A'}, S3:${CargoJson.Seal3??'N/A'}, S4:${CargoJson.Seal4??'N/A'}`,
          ShippingLineCode: CargoJson.ShippingLineCode ?? '',
          PortActivityName: CargoJson.PortActivityName ?? '',
          Condition: CargoJson.Condition ?? '',
          ImdgCode: CargoJson.ImdgCode ?? '',
          LoadPort: CargoJson.LoadPort ?? '',
          DischargePort: CargoJson.DischargePort ?? '',
          Weigth: CargoJson.Weigth ?? 0,
          DescriptionOfGoods: CargoJson.DescriptionOfGoods ?? '',
          Errors: item.JsonError.map((Error) => {
            return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
          }).join(","),
        }
      });
    }else if(this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BILL_OF_LADING) {
      ErrorJson = this.File?.StowagePlanningBlJson?.filter(item => item.FgError)?.map((item, index) => {
        return {
          Nro: index+1,
          BlNro: item.BlNro ?? '',
          TpCargoCode: item.TpCargoCode ?? '',
          ClassificationName: item.Classification ?? '',
          Commodity: item.Commodity ?? '',
          GeneralInfo: item.GeneralInfo ?? '',
          ExternalPackaging: item.ExternalPackaging ?? '',
          InternalPackaging: item.InternalPackaging ?? '',
          Weight: item.Weight ?? '',
          Volumen: item.Volumen ?? '',
          Quantity: item.Quantity ?? '',
          ImdgCode: item.ImdgCode ?? '',
          ClientRif: item.ClientRif ?? '',
          ClientName: item.ClientName ?? '',
          LoadPort: item.LoadPort ?? '',
          DischargePort: item.DischargePort ?? '',
          Direct: item.FgDirect ? this.$t('label.yes') : this.$t('label.no'),
          Seller: item.Seller ?? '',
          NotifyTo: item.NotifyTo ?? '',
          Observation: item.Observation ?? '',
          Errors: item.JsonError.map((Error) => {
            return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
          }).join(","),
        }
      });
    }else{
      return [];
    }
    await this.getExcelArray(ErrorJson, title, true, undefined, true, undefined, TableHeader)
      .catch((err) => {
        this.Loading = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }else{
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:  this.$t('label.noDataAvailable').toUpperCase(),
      type: "error",
    });
  }
  this.Loading = false;
}

//COMPUTED
function getErrorsCount() {
  return this.File.Error;
}
function getCorrectCount() {
  return this.File.Correct;
}

function Field() {
  if (this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CONTAINER_LIST) {
    return [
      { key: 'Nro', label: '#'},
      { key: 'ContainerCode', label: this.$t('label.containerCode')},
      { key: 'BlNro', label: 'BL NRO'},
      { key: 'TpCargoCode', label: this.$t('label.type')},
      { key: 'Consignee', label: this.$t('label.consignee')},
      { key: 'IsoCode', label: this.$t('label.isoCode')},
      { key: 'Status', label: this.$t('label.status')},
      { key: 'Seals', label: this.$t('label.Seal')+'S'},
      { key: 'ShippingLineCode', label: this.$t('label.newShippingLine')},
      { key: 'PortActivityName', label: this.$t('label.portActivity')},
      { key: 'Condition', label: this.$t('label.condition')},
      { key: 'ImdgCode', label: 'IMDG'},
      { key: 'LoadPort', label: 'POL'},
      { key: 'DischargePort', label: 'POD'},
      { key: 'Weigth', label: this.$t('label.weight')+' (KGM)'},
      { key: 'DescriptionOfGoods', label: this.$t('label.DescriptionOfGoods')},
      { key: 'Errors', label: this.$t('label.errors')},
    ]
  } else if (this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BILL_OF_LADING) {
    return [
      { key: 'Nro', label: '#'},
      { key: 'BlNro', label: 'BL NRO'},
      { key: 'TpCargoCode', label: this.$t('label.cargoType')},
      { key: 'ClassificationName', label: this.$t('label.classification')},
      { key: 'Commodity', label: this.$t('label.commodity')},
      { key: 'GeneralInfo', label: this.$t('label.generalInfo')},
      { key: 'ExternalPackaging', label: this.$t('label.ExternalPackaging')},
      { key: 'InternalPackaging', label: `${this.$t('label.InternalPackaging')}`},
      { key: 'Weight', label: this.$t('label.weight')},
      { key: 'Volumen', label: this.$t('label.volume')},
      { key: 'Quantity', label: this.$t('label.quantity')},
      { key: 'ImdgCode', label: 'IMDG'},
      { key: 'ClientRif', label: this.$t('label.clientRif')},
      { key: 'ClientName', label: this.$t('label.client')},
      { key: 'LoadPort', label: 'POL'},
      { key: 'DischargePort', label: 'POD'},
      { key: 'Direct', label: this.$t('label.DirectDischarge')},
      { key: 'Seller', label: this.$t('label.seller')},
      { key: 'NotifyTo', label: this.$t('label.notifyParty')},
      { key: 'Observation', label: this.$t('label.observation')},
      { key: 'Errors', label: this.$t('label.errors')},
    ]
  }else{
    return [];
  }
}

export default {
  name: "alert-modal",
  mixins: [Reportes],
  data,
  props: {
    modal: Boolean,
    File: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onBtnExport,
  },
  computed: {
    getErrorsCount,
    getCorrectCount,
    Field,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
    })
  },
  watch: {
    modal: function (NewVal) {
      this.ModalActive = NewVal;
    },
  },
};
</script>
<style scoped>
.main-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.msg {
  font-weight: bold;
  text-align: center;
}
.msg-name {
  font-weight: normal;
}
.correct {
  color: #1aff1a;
  font-weight: bold;
  margin-bottom: 10px;
}
.errors {
  color: #ff0101;
  font-weight: bold;
  text-align: center;
}
.footer-errors {
  margin-top: 10px;
  font-weight: bold;
}

.header-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>