<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
    <modalListDamageImages :edit="false" :modal="ShowModalDamageImageList" @closeModal="ShowModalDamageImageList=false" @child-refresh="refrescarTabla=true" />
    
    <CCol sm="12">
      <CRow class="mb-3">
        <CCol sm="8" class="d-flex align-items-center justify-content-start ">
          <CButton
              color="edit"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="closeCollapse"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton>
            <h6 class="mt-2"> {{ $t('label.inspectionDamage')+': '+containerCode }}</h6>
        </CCol>  
        <CCol sm="4" class="d-flex align-items-center justify-content-end ">
        </CCol>
      </CRow>
      <dataTableExtended
        class="align-center-row-datatable"
        :fields="fieldsDamage"
        :items="itemsDamage"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingDamage"
        hover
        sorter
      >
        <template #loading>
          <loading/>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center">
            <CButton
              size="sm"
              color="watch"
              class="mx-1"
              v-c-tooltip="{
                content: $t('label.see')+$t('label.image'),
                placement: 'top-end',
              }"
              @click="ShowModalDamageImageList=item"
              v-if="item.FilesJson.length>0"
            >
              <CIcon name="eye"/>
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import modalListDamageImages from './modal-list-damage-images';

function data() {
  return {
    itemsDamage: [],
    loadingOverlay: false,
    loadingDamage: false,
    ShowModalDamageImageList: false,
    refrescarTabla: false,
    VisitId: '',
    visitCargoId: '',
    containerCode: '',
    showDamage: false,
    VisitCargoInspectionMasterId: '',
  };
}

function fieldsDamage(){
  return [
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:2%; text-align:center'  },
    { key: 'TipoDanio', label:this.$t('label.damageType'), _style: 'width:10%;', _classes: 'text-uppercase'},
    { key: 'TpCargoViewName', label:this.$t('label.localizacion'),_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'Height', label:this.$t('label.height')+'(CM)',_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'Width', label:this.$t('label.width')+'(CM)',_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'Dimension', label:this.$t('label.dimensions'),_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'InspectionMomentName', label:this.$t('label.inspectionMoment'), _style: 'width:11%;', _classes: 'text-uppercase'},
    { key: 'Responsible', label:this.$t('label.responsible'), _style: 'width:11%;', _classes: 'text-uppercase'},
    { key: 'FormatedDate', label:this.$t('label.date'), _style: 'width:10%;', _classes: 'text-uppercase'},
    { key: 'FormatedHour', label:this.$t('label.hour'), _style: 'width:8%;', _classes: 'text-uppercase'},
    { key: 'Detalle', label: '',sorter: false,filter: false,_style: 'min-width:45px; width:8%;  '},
  ];
}


async function getDamageByVisitCargoId(valor) {
  this.loadingDamage = true;
  let _this = this.$i18n.locale;  
  let listado = Array;
  await this.$http.get("VisitCargoInspDamage-by-VisitCargoId", { VisitCargoId: valor })
    .then(response => {
      listado = [...response.data.data];
      let i = 1;
      this.itemsDamage = listado.map(listado => Object.assign({}, this.itemsDamage, {
        Nro: i++,
        Height: listado.Height ? listado.Height : '',
        InspectionMomentId: listado.InspectionMomentId ? listado.InspectionMomentId : '',
        InspectionMomentName: _this=='en' ? listado.InspectionMomentNameEn : listado.InspectionMomentNameEs,
        Observation: listado.Observation ? listado.Observation: '',
        Responsible: listado.Responsible ? listado.Responsible : '',
        TpCargoViewId: listado.TpCargoViewId ? listado.TpCargoViewId : '',
        TpCargoViewName: listado.TpCargoViewName ? listado.TpCargoViewName : '',
        TpDamageAbbrev: listado.TpDamageAbbrev ? listado.TpDamageAbbrev : '',
        TpDamageId: listado.TpDamageId ? listado.TpDamageId : '',
        TpDamageName: listado.TpDamageName ? listado.TpDamageName : '',

        FormatedHour: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(listado.TransaRegDate)) : '',
        FormatedDate: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(listado.TransaRegDate)) : '',
        VisitCargoInspDamageId: listado.VisitCargoInspDamageId ? listado.VisitCargoInspDamageId : '',
        Width: listado.Width ? listado.Width : '',
        FilesJson: listado.FilesJson ? listado.FilesJson : '',

        TipoDanio: listado.TpDamageAbbrev+":"+listado.TpDamageName,
        TpCargoViewName: listado.TpCargoViewName,
        Dimension: ((listado.Width!=="") && (listado.Height!=="")) ? listado.Width+" x "+listado.Height : "",
        FgActVisitCargoInspDamage: listado.FgActVisitCargoInspDamage ? listado.FgActVisitCargoInspDamage : '',
        VisitCargoInspectionMasterId: listado.VisitCargoInspectionMasterId ? true : false,
      }));
      
      if(this.VisitCargoInspectionMasterId !== ''){
        this.showDamage = false;
      }
      else{
        this.showDamage = true;
      }

    }).catch( err => {
      this.loadingDamage = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })

    this.loadingDamage = false;
}

function closeCollapse(){
  this.$emit('closeCollapseDamage', false);
  this.$emit('child-refresh', true);
}


export default {
  name: 'index-damage',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    modalListDamageImages,
  },
  methods: {
    getDamageByVisitCargoId,
    closeCollapse,
  }, 
  props: {
    damageData: null,
    containerData: {
      type: String,
    },
  }, 
  watch: {
    damageData: function(newVal) {
      if(newVal){
        this.containerCode = newVal.ContainerCode;
        this.VisitCargoId = newVal.VisitCargoId;

        this.getDamageByVisitCargoId(newVal.VisitCargoId);
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.refrescarTabla = false;
        this.getDamageByVisitCargoId(this.VisitCargoId);

      }
    },
  },
  computed: {


    fieldsDamage,

    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemReport: state => state.visitas.dropItemReport,
    })
  },
};
</script>
