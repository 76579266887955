import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { DateFormater } from "@/_helpers/funciones";

export default (VerifySeal,  PreviousDate, LaterDate, ValidateVisitcargoId) => {
    let seal1Valid = VerifySeal.ConditionSeal1 ? required : true;
    let ConditionSeal1Valid = { required };
    let seal2Valid = VerifySeal.ConditionSeal2 ? required : true;
    let ConditionSeal2Valid = { required };
    let seal3Valid = VerifySeal.ConditionSeal3 ? required : true;
    let ConditionSeal3Valid = { required };
    let seal4Valid = VerifySeal.ConditionSeal4 ? required : true;
    let ConditionSeal4Valid = { required };
    let VisitCargoStatus = ValidateVisitcargoId ? { required } : {};
    return {
        YardStatusSuggestTransactionId: { required },
        MachineId: { required },
        TransportId: {},
        TransactionDate: { required, RangeDate(value){
            return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
                DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate)
        } },
        Destination: {},
        YardDestinyId: !ValidateVisitcargoId ? { required } : {},
        SearchDriver: { maxLength: maxLength(10) },
        DriverId: VisitCargoStatus,
        SearchPlate: { maxLength: maxLength(10) },
        VehicleId: VisitCargoStatus,
        Eir: {},
        TicketOut: {},
        Observation: { onlyAlphanumeric5 },
        Seal: {
            Seal1: {
                Seal: { seal1Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal1Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal2: {
                Seal: { seal2Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal2Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal3: {
                Seal: { seal3Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal3Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal4: {
                Seal: { seal4Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal4Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
        },
    }
}