<template>
    <CRow>
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
        <CCol sm="12">
            <CCollapse :show="!collapseHistorico">
                <CCard>
                    <CCardHeader class="text-center bg-dark text-white">
                        <b>{{$t('label.OperationSituationHistorical')}}</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <appCalendar/>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCollapse>
            <CCollapse :show="collapseHistorico">
                <div  v-if="collapseHistorico"  class="estandar-letra"><appGrafica v-if="collapseHistorico"/></div>
            </CCollapse>
        </CCol>
    </CRow>
</template>
<script>
    import { mapState } from 'vuex';
    import appCalendar from './calendar-visita';
    import appGrafica from './index-grafica';

    export default {
        name:'index-historico',
        components: {
            appCalendar,
            appGrafica
        },
        beforeDestroy(){
            this.$store.state.situacionOperativaHistorico.collapseHistorico = false;
            this.$store.state.situacionOperativaHistorico.tabsOperationes = 0;
            this.$store.state.situacionOperativaHistorico.carrusel = true;
            this.$store.state.situacionOperativaHistorico.carruselTab = 1;
        },
        computed:{
            ...mapState({
                collapseHistorico: state => state.situacionOperativaHistorico.collapseHistorico,
                Loading: state => state.situacionOperativaHistorico.Loading,
            })
        },
    }
</script>

<style lang="scss" scoped>
     @font-face {
        font-family: "Verdana";
        font-weight: 400;
        font-style: normal;
        font-display: auto;
        unicode-range: U+000-5FF;
        src: local("Verdana"), url(../../../public/fonts/verdana/verdana.ttf) format("truetype");
    }
    .estandar-letra{
        font-size: 0.75rem !important;
        font-weight: 100 !important;
        font-family: "Verdana", Helvetica, Arial;
        /*@font-face {
            font-family: "verdana";
            src: local("verdana"),
            url(./css/verdana/verdana-bold-italic.ttf) format("truetype");
        }*/
    }
</style>