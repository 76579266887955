<template>
  <div class="mb-3">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
      <symbol id="icon-recognition" fill="currentColor" viewBox="0 0 86.25 99.67">
        <g id="Capa_4" data-name="Capa 4">
            <path class="cls-1" d="M35.64,94.14a1.79,1.79,0,0,1-.87-.22C5.59,78.16.61,56.36.42,55.44V55.1L0,21.1A3.44,3.44,0,0,1,3.1,17.63C7.65,17,23.51,14.26,34.82.84a5.27,5.27,0,0,1,.49-.57A1.43,1.43,0,0,1,36.85.19a4.85,4.85,0,0,1,.56.55c.61.63,15.08,15.29,31,16.93a3.48,3.48,0,0,1,3.06,3.43v32a1.7,1.7,0,1,1-3.4,0v-32c-14.5-1.54-27.7-12.89-31.8-16.73C24.25,17.6,8.2,20.4,3.45,20.93l.33,34c.48,1.88,6,21.33,31.81,35.61l9.2-5.84a1.7,1.7,0,0,1,1.82,2.88h0l-10,6.33A1.74,1.74,0,0,1,35.64,94.14Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M66.41,91.25A16.75,16.75,0,1,1,83.16,74.5,16.75,16.75,0,0,1,66.41,91.25Zm0-30.1A13.35,13.35,0,1,0,79.76,74.5h0A13.37,13.37,0,0,0,66.41,61.15Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M84.61,99.67A1.7,1.7,0,0,1,83.25,99L75,87.93a1.7,1.7,0,1,1,2.48-2.32,1.57,1.57,0,0,1,.24.32l8.28,11a1.72,1.72,0,0,1-1.34,2.74Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M63.7,80.59a1.68,1.68,0,0,1-1.23-.53l-4.62-4.87a1.7,1.7,0,1,1,2.34-2.47l.12.13,3.38,3.55,8.42-9a1.7,1.7,0,0,1,2.49,2.32L65,80.05a1.71,1.71,0,0,1-1.24.54Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M36.16,16.75a1.7,1.7,0,0,1-1.7-1.7h0V3.17a1.7,1.7,0,0,1,3.4,0V15.05a1.7,1.7,0,0,1-1.7,1.7Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M36,72.93a15.72,15.72,0,0,1-15.69-15.7V46.93A3.93,3.93,0,0,1,24.19,43h23.5a4,4,0,0,1,3.94,3.93V57.21A15.71,15.71,0,0,1,36,72.93ZM24.17,46.39a.53.53,0,0,0-.53.53V57.21a12.3,12.3,0,0,0,24.59,0V46.93a.55.55,0,0,0-.54-.53Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M42.5,53.61H29.38a4.59,4.59,0,0,1-3.63-1.77l-3.94-5a1.7,1.7,0,0,1,2.68-2.1h0l3.94,5a1.19,1.19,0,0,0,1,.47H42.5a1.23,1.23,0,0,0,.88-.38l5-5.26a1.7,1.7,0,0,1,2.8,1.93,1.63,1.63,0,0,1-.36.38l-5,5.26A4.67,4.67,0,0,1,42.5,53.61Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M21.34,52.45a1.71,1.71,0,0,1-1.4-.74L12.57,41A3.71,3.71,0,0,1,12,38a3.82,3.82,0,0,1,1.78-2.44L34,23.93a3.73,3.73,0,0,1,3.84,0L57.41,35.82A3.77,3.77,0,0,1,58.67,41l-.18.27-6.26,8.56A1.7,1.7,0,1,1,49.36,48l.13-.18,6.26-8.56a.33.33,0,0,0,.06-.29.35.35,0,0,0-.17-.24L36.1,26.86a.37.37,0,0,0-.38,0L15.49,38.56a.36.36,0,0,0-.18.24.35.35,0,0,0,.06.28l7.37,10.71a1.7,1.7,0,0,1-.44,2.36A1.65,1.65,0,0,1,21.34,52.45Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M35.38,41.22l-6.57-5.16A1.71,1.71,0,1,1,31,33.38l4.41,3.55,4.36-3.49a1.7,1.7,0,1,1,2.12,2.66Z" transform="translate(-0.04 0)"/>
            <path class="cls-1" d="M35.65,93.31a1.7,1.7,0,0,1-1.7-1.7V75.93a1.7,1.7,0,1,1,3.4,0V91.61A1.7,1.7,0,0,1,35.65,93.31Z" transform="translate(-0.04 0)"/>
        </g>
      </symbol>
    </svg>

    
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>
    <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListReception"
        :fields="receptionfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
    >

        <template #Status="{ item }">
            <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                </CBadge>
            </td>
        </template>
    
        <template #options="{ item }">
            <td class="text-center">
            
                <CButton
                    square
                    size="sm"
                    style="background:#e0e0e0; border: 1px solid #ab0329;"
                    class="d-flex align-items-center "
                    @click="toggleContainerEdit(item)"
                    v-c-tooltip="{
                    content: $t('label.eirTransaction'),
                    placement: 'top-end'
                    }"
                >
                <CIcon name="recognition" />
                </CButton>
            
            </td>
        </template>
    </dataTableExtended>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataReception: [],
        };
    }

    async function onBtnPdf(){
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.receptionfields.map(item=>{   
            if (item.key!='options' && item.key!='pdf') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })

        for (let i = 0; i < this.dataReception.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataReception[i].IdX ?? 'N/A',
                YardName: this.dataReception[i].YardName ?? 'N/A',
                EirNumber: this.dataReception[i].EirNumber ?? 'N/A',
                nroEirSiget: this.dataReception[i].nroEirSiget ?? 'N/A',
                MachineName: this.dataReception[i].MachineName ?? 'N/A',
                DeviceCode: this.dataReception[i].DeviceCod ?? 'N/A',
                FormatedDate: this.dataReception[i].TransactionDate ? DateFormater.formatDateTimeWithSlash(this.dataReception[i].TransactionDate) : 'N/A',
                TransaLogin: this.dataReception[i].TransaLogin ?? 'N/A',
                Status: this.dataReception[i].Status ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataReception.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.reception'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    function computedListReception() {
        return  this.dataReception.map((item) => Object.assign({}, item, {
            RowNumber: item.IdX ?? 'N/A',
            YardName: item.YardName ?? 'N/A',
            nroEirSiget: item.nroEirSiget ?? 'N/A',
            EirNumber: item.EirNumber ?? 'N/A',
            MachineName: item.MachineName ?? 'N/A',
            DeviceCode: item.DeviceCod ?? 'N/A',
            FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
            TransaLogin: item.TransaLogin ?? 'N/A',
            Status: item.Status ?? 'N/A',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        }));
    }

    function Update() {
        this.ModalBlContainer = false;
    }

    function receptionfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.nroEirBolipuertos'), key: 'EirNumber', _classes: 'text-uppercase center-cell text-center', _style: 'min-width:180px;', sorter: true, filter: true},
            { key: 'nroEirSiget', label: this.$t('label.nroEirSiget'),  _classes: 'text-uppercase center-cell text-center', _style: 'min-width:130px;', sorter: true, filter: true},
            { key: 'YardName', label: this.$t('label.yard'), _style:'width:10%;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _style:'width:10%;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { key: 'MachineName', label: this.$t('label.machine'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'DeviceCode', label: this.$t('label.device'),  _style:'width:17%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'FormatedDate', label: this.$t('label.date'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            
        ];
    }

    
    function toggleContainerEdit(item) {
        this.ContainerItem = item;
        this.ModalBlContainer = !this.ModalBlContainer;
    }

    export default{
        name: 'index-reception',
        data,
        mixins: [General, ReportSearchContainer],
        methods: {
            Update,
            toggleContainerEdit,
            onBtnExport,
            onBtnPdf
        },
        computed: {
            receptionfields,
            computedListReception,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0].ReceptionJson){
                    this.dataReception = this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0].ReceptionJson;
                }
                else
                    this.dataReception = [];
            },
        }
    }
</script>
<style lang="scss">

</style>