<template>
  <CRow class="mt-2">
    <cancel-confirm-modal
      :movType="currentMovType"
      :cancelList="items"
      :modal.sync="cancelModal"
      @submited="handleSubmit"
    />
    <CCol sm="12">
      <CTabs :active-tab="activeTab" @update:activeTab="handleTab">
        <CTab :title="$t('label.pendings')">
          <CRow>
            <CCol sm="12" class="mb-2">
              <CRow class="mt-2">
                <CCol sm="4">
                  <CSelect
                    :label="$t('label.movementType')"
                    :placeholder="$t('label.select')"
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                    addLabelClasses="text-right"
                    :disabled="isEmpty(movTypes)"
                    :options="movTypeOptions"
                    :value.sync="movType"
                  />
                </CCol>
                <CCol sm="1">
                  <CButton
                    color="wipe"
                    shape="square"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.clearFields'),
                      placement: 'top-end',
                    }"
                    @click="clearFields"
                  >
                    <CIcon name="cil-brush-alt" />
                  </CButton>
                </CCol>
                <CCol sm="7" class="d-flex justify-content-end">
                  <CButton
                    color="add"
                    shape="square"
                    @click="toggleCancel"
                  >
                    <div>
                      <CIcon name="checkAlt" /><span class="ml-1">{{
                        $t("label.Confirm")
                      }}</span>
                    </div>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
            
            <CCol sm="12" class="mb-2">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedPending"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePagePending"
                pagination
                
              >
                <template #loading>
                  <loading/>
                </template>
                <template #Details="{ item }">
                  <td class="center-cell" style="width: 1%">
                    <CInputCheckbox
                      custom
                      class="checkbook-filter"
                      :checked="item.initialActive"
                      @update:checked="handleItem($event, item)"
                    />
                  </td>
                </template>
                <template #Details-filter>
                  <CInputCheckbox
                    :label="$t('label.all')"
                    class="checkbook-filter"
                    custom
                    @update:checked="selectAllItems($event)"
                    :checked="selectAll"
                  />
                </template>
                <template #Seals="{ item }">
                  <td class="center-item d-flex flex-column">
                    <span v-for="seal in item.Seals">{{ seal }}</span>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.canceled')">
          <dataTableExtended
            class="align-center-row-datatable"
            hover
            sorter
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items="formatedConfirm"
            :fields="fieldsConfirm"
            :loading="isLoading"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="5"
            :active-page="activePageConfirm"
            pagination
            
          >
            <template #loading>
              <loading/>
            </template>
            <template #Seals="{ item }">
              <td class="center-item d-flex flex-column">
                <span v-for="seal in item.Seals">{{ seal }}</span>
              </td>
            </template>
          </dataTableExtended>
        </CTab>
      </CTabs>
    </CCol>
  </CRow>
</template>

<script>
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CancelConfirmModal from './ajustes-plano/cancel-confirm-modal';
import CancelList from '@/_validations/ajustes-plano/CancelList';

function data() {
  return {
    itemsPending: [],
    itemsConfirm: [],
    activePageConfirm: 1,
    activePagePending: 1,
    isLoading: false,
    activeTab: 0,
    isSubmit: false,
    reasons: [],
    movTypes: [],
    movType: '',
    reasonsLoading: false,
    items: [],
    selectAll: false,
    cancelModal: false,
  };
}

function created() {
  this.getMovTypes();
}

//Methods:
function formatSeals(seal1, seal2, seal3, seal4) {
  let arr = [];

  if(seal1) arr.push(seal1);
  if(seal2) arr.push(seal2);
  if(seal3) arr.push(seal3);
  if(seal4) arr.push(seal4);

  return arr;
}
function handleTab(tab) {
  this.activeTab = tab;
}
function toggleCancel(){
  if(this.movType == '') {
    this.$swal.fire({
      icon: 'error',
      title: '',
      text: this.$t('label.mustSelectMovType'),
    });
  } else if(this.items.length == 0) {
    this.$swal.fire({
      icon: 'error',
      title: '',
      text: this.$t('label.mustSelectContainer'),
    });
  } else {
    this.cancelModal = true;
  }

}

function getMovTypes() {
  this.$http
    .get("VisitMovStowageSetting-list")
    .then((response) => {
      this.movTypes = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}
function isEmpty(arr) {
  return arr.length == 0;
}

function handleItem(event, item) {
  if (event === true) {
    let index = this.itemsPending.findIndex((type) => type.VisitCargoId == item.VisitCargoId);
    if(index != -1){
      this.items.push(item);
    }
  } else {
    let index = this.items.findIndex((type) => type.VisitCargoId == item.VisitCargoId);
    if(index != -1)
      this.items.splice(index, 1);
  }
}
function selectAllItems(event) {
  if(event) {
    this.itemsPending.forEach(element => {
      let index = this.items.findIndex((type) => type.VisitCargoId==element.VisitCargoId);
      if(index == -1)
        this.items.push(element);
    });
    this.selectAll = true;
  } else {
    this.items.splice(0, this.items.length);
    this.selectAll = false;
  }
}
function handleSubmit(item) {
  this.items = [];
  this.$emit("submit-action", item);
}
function clearFields() {
  this.movType = '';
}

//Computed:
function formatedPending() {
  let index = 1;

  let arr = this.itemsPending.map((item) => {

    let found = this.items.findIndex((type) => type.VisitCargoId == item.VisitCargoId);

    return Object.assign({}, item, {
        _classes: 'text-center align-middle',
        Nro: index++,
        Imagen: `${this.$store.getters["connection/getBase"]}${
          item.Route ? item.Route.replace("Public/", "", null, "i") : ""
        }`,
        ContainerCode: item.ContainerCode ? item.ContainerCode : "",
        ArrivalPosition: item.CodPosition ? item.CodPosition : "",
        SizeCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : "",
        IsoCode: item.IsoCode ? item.IsoCode : "",
        Status: item.TpCargoStatusName ? item.TpCargoStatusName : "",
        Carrier: item.ShippingLineCode ? item.ShippingLineCode : "",
        ImdgCode: item.ImdgCode ? item.ImdgCode : "",
        LoadPort: item.LoadPort ? item.LoadPort : "",
        DischargePort: item.DischargePort ? item.DischargePort : "",
        Seals: this.formatSeals(item.Seal1, item.Seal2, item.Seal3, item.Seal4),
        Weight: item.WeigthJson
          ? `${NumberFormater.formatNumber(item.WeigthJson[1].Value, 2)}`
          : "",
        Height: item.HeightJson
          ? `${NumberFormater.formatNumber(item.HeightJson[0].Value, 2)}`
          : "",
        Length: item.LenghtJson
          ? `${NumberFormater.formatNumber(item.LenghtJson[0].Value, 2)}`
          : "",
        Mov: this.$i18n.locale == 'en'
          ? item.MovStowageNameEn ? item.MovStowageNameEn : ''
          : item.MovStowageNameEs ? item.MovStowageNameEs : '',
        Reason: this.$i18n.locale == 'en'
          ? item.MovStowageReasonNameEn ? item.MovStowageReasonNameEn : ''
          : item.MovStowageReasonNameEs ? item.MovStowageReasonNameEs : '',
        Device: item.DeviceCode ? item.DeviceCode : "",
        Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
        Fecha: item.ConfirmationDate
          ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate)
          : "N/A",
        initialActive: found != -1 ? true : false,
      });
    }
  );

  if(this.movType){
    return arr.filter((item) => item.MovStowageId?.toUpperCase() == this.movType);
  } else
    return arr;
}
function formatedConfirm() {
  let index = 1;
  return this.itemsConfirm.map((item) =>
    Object.assign({}, item, {
      // _classes: this.tableVisitClasses(item),
      Nro: index++,
      Imagen: `${this.$store.getters["connection/getBase"]}${
        item.Route ? item.Route.replace("Public/", "", null, "i") : ""
      }`,
      ContainerCode: item.ContainerCode ? item.ContainerCode : "",
      ArrivalPosition: item.CodPosition ? item.CodPosition : "",
      SizeCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : "",
      IsoCode: item.IsoCode ? item.IsoCode : "",
      Status: item.TpCargoStatusName ? item.TpCargoStatusName : "",
      Carrier: item.ShippingLineCode ? item.ShippingLineCode : "",
      ImdgCode: item.ImdgCode ? item.ImdgCode : "",
      LoadPort: item.LoadPort ? item.LoadPort : "",
      DischargePort: item.DischargePort ? item.DischargePort : "",
      Seals: this.formatSeals(item.Seal1, item.Seal2, item.Seal3, item.Seal4),
      Weight: item.WeigthJson
        ? `${NumberFormater.formatNumber(item.WeigthJson[1].Value, 2)}`
        : "",
      Height: item.HeightJson
        ? `${NumberFormater.formatNumber(item.HeightJson[0].Value, 2)}`
        : "",
      Length: item.LenghtJson
        ? `${NumberFormater.formatNumber(item.LenghtJson[0].Value, 2)}`
        : "",
      Mov: this.$i18n.locale == 'en'
        ? item.MovStowageNameEn ? item.MovStowageNameEn : ''
        : item.MovStowageNameEs ? item.MovStowageNameEs : '',
      Reason: this.$i18n.locale == 'en'
        ? item.MovStowageReasonNameEn ? item.MovStowageReasonNameEn : ''
        : item.MovStowageReasonNameEs ? item.MovStowageReasonNameEs : '',
      Device: item.DeviceCode ? item.DeviceCode : "",
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.ConfirmationDate
        ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate)
        : "N/A",
    })
  );
}
function fields() { 
  return [
    {
      key: "Details",
      label: this.$t('agridTable.applyFilter'),
      sorter: false,
      filter: false,
      _styles: "width: 1%; text-align: center;",
    },
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:1%; text-align:center'
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ArrivalPosition",
      label: "ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: this.$t('label.status'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
}
function fieldsConfirm() { 
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center'
    },
    {
      key: "ContainerCode",
      label: "CONTAINER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ArrivalPosition",
      label: "ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "SizeCode",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Status",
      label: this.$t('label.status'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Carrier",
      label: "CARRIER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seals",
      label: "SEALS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT(TON)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH(CM)",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Reason",
      label: this.$t('label.motive'),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Device",
      label: this.$t("label.device"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Usuario",
      label: this.$t("label.user"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Fecha",
      label: this.$t("label.date"),
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
}
function movTypeOptions() {
  return this.movTypes.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.MovStowageNameEn : item.MovStowageNameEs,
      value: item.MovStowageParentId,
    })
  );
}
function currentMovType() {
  let found = this.movTypes.find((item) => item.MovStowageParentId == this.movType);
  
  return found ? found : null;
}

export default {
  name: 'cancel-list',
  mixins: [GeneralMixin],
  components: {
    CancelConfirmModal,
  },
  props: {
    plano: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  data,
  created,
  methods: {
    formatSeals,
    handleTab,
    toggleCancel,
    getMovTypes,
    isEmpty,
    handleItem,
    selectAllItems,
    handleSubmit,
    clearFields,
  },
  computed: {
    fields,
    formatedPending,
    formatedConfirm,
    fieldsConfirm,
    movTypeOptions,
    currentMovType,
  },
  watch: {
    plano: {
      handler(val){
        this.itemsPending = this.plano.CancelListJson[1].CargoJson;
        this.itemsConfirm = this.plano.CancelListJson[0].CargoJson;
      },
      deep: true
   },
   movType: function(newVal) {
    if(newVal){
      this.items = [];
    }
   }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>