<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.listBrand')}}</b>
    </CCardHeader>
    <CCardBody>
        <CustomTabs :active-tab="$store.state.marcamodelo.tabIndex" @update:activeTab="handleTab">
            <CustomTab :title="$t('label.brand')" icon="brand">
                <brand-List />
            </CustomTab>
            <CustomTab :title="$t('label.model')" icon="model">
                <model-list />
            </CustomTab>
        </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BrandList from './Marca/index-brand';
import ModelList from './Modelo/index-model';

function handleTab(tab) {
  this.$store.state.marcamodelo.tabIndex = tab;
}
export default {
  name: 'brand-model',
  methods:{
    handleTab,
  },
  components: {
    CustomTabs,
    CustomTab,
    BrandList,
    ModelList,
  },
};
</script>