<template>
<CModalExtended
  :title="$t('label.nueva')+' '+$t('label.newShippingLine')"
  color="dark"
  size="xl"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-linea"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="mb-0">
          <CCardBody>
            <CRow align-vertical="center" class="mt-1">
              <CCol sm="4">
                <div class="preview">    
                  <picture-input
                    v-if="modalActive"
                    ref="imageInput"
                    width="250"
                    height="200"
                    accept="image/jpeg,image/png,image"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                    :imgSrc="imageRoute"
                  >
                  </picture-input>
                </div>
                <div v-if="$v.linea.LineRoute.$error">
                  <div class='text-invalid-feedback' v-if="!$v.linea.LineRoute.isValidFile">{{$t('label.badImage')}}</div>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.ShippingLineName.$model"
                      :invalid-feedback="errorMessage($v.linea.ShippingLineName)"
                      v-uppercase                     
                      :is-valid="hasError($v.linea.ShippingLineName)"
                      size="sm"
                      :label="$t('label.name')"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      addLabelClasses="required text-right"
                      maxlength="50"
                      :placeholder="$t('label.newShippingLineName')"                   
                    />
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.linea.PrimaryPhone.$model"
                      :invalid-feedback="errorMessage($v.linea.PrimaryPhone)" 
                      v-uppercase 
                      :is-valid="hasError($v.linea.PrimaryPhone)"
                      size="sm"
                      type="tel"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.mainPhone')"
                      addLabelClasses="text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.mainPhone')" 
                    />
                  </CCol>
                  <CCol sm="12">                   
                    <CInput
                      v-model.trim="$v.linea.SecondaryPhone.$model"
                      :invalid-feedback="errorMessage($v.linea.SecondaryPhone)" 
                      v-uppercase 
                      :is-valid="hasError($v.linea.SecondaryPhone)"
                      size="sm"
                      type="tel"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.secondaryPhone')"
                      addLabelClasses="text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.secondaryPhone')" 
                    />
                  </CCol>
                  <CCol sm="12">                  
                    <CInput
                      v-model.trim="$v.linea.PrimaryEmail.$model"
                      :invalid-feedback="errorMessage($v.linea.PrimaryEmail)" 
                      v-uppercase
                      :is-valid="hasError($v.linea.PrimaryEmail)"
                      size="sm"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.mainEmail')"
                      addLabelClasses="text-right"
                      maxlength="100"
                      :placeholder="$t('label.mainEmail')" 
                    />
                  </CCol>
                  <CCol sm="12">
                    
                    <CInput
                      v-model.trim="$v.linea.SecondaryEmail.$model"
                      :invalid-feedback="errorMessage($v.linea.SecondaryEmail)" 
                      v-uppercase
                      :is-valid="hasError($v.linea.SecondaryEmail)"
                      size="sm"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.secondaryEmail')"
                      addLabelClasses="text-right"
                      maxlength="100"
                      :placeholder="$t('label.secondaryEmail')" 
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="$v.linea.Color.$model"
                      :invalid-feedback="errorMessage($v.linea.Color)" 
                      v-uppercase
                      :is-valid="hasError($v.linea.Color)" 
                      size="sm"  
                      type="color"                     
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.color')"
                      addLabelClasses="required text-right"    
                      required
                    />
                  </CCol>
                  <CCol sm="12">
                      <CInput
                        v-model.trim="$v.linea.ShippingLineAbbrev.$model"
                        :invalid-feedback="errorMessage($v.linea.ShippingLineAbbrev)" 
                        v-uppercase
                        :is-valid="hasError($v.linea.ShippingLineAbbrev)"
                        size="sm"
                        :label="$t('label.acronym')"
                        :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                        addLabelClasses="required text-right"
                        maxlength="8"
                        :placeholder="$t('label.acronym')" 
                      />
                    </CCol>
                </CRow>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CTextarea
                      v-model.trim="$v.linea.Address.$model"
                      :invalid-feedback="errorMessage($v.linea.Address)" 
                      v-uppercase
                      :is-valid="hasError($v.linea.Address)"
                      size="sm"
                      :label="$t('label.address')"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      addLabelClasses="required text-right"
                      maxlength="150"
                      :placeholder="$t('label.newShippingLineAddress')" 
                    />
                  </CCol> 
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>        
  </form>
  <template #footer>
    <CButton
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="submitFile"
    >
    <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    </CButton>
    <CButton
      color="wipe"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import LineaValidations from '@/_validations/linea-naviera/LineaNavieraValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import LineaNavieraMixin from '@/_mixins/linea-naviera';
import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    linea: {
      ShippingLineName: '',
      ShippingLineAbbrev: '',
      Address: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      PrimaryEmail: '',
      SecondaryEmail: '',
      LineRoute: '',
      Color: '#FFF',
      Image_url: '',
    },
    imageRoute: '/img/uploadPhotoEs.svg',
  }
}

//Methods
function submitFile() {
  try {
    this.isSubmit = true;
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    
    this.$http.file('shipping-agency-insert', this.linea.LineRoute)
    .then((response) => {
      this.linea.LineRoute = response.data.data.files[0].path;
      this.submit();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
  });

  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}
function submit() {
  try { 
    this.isSubmit = true;
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    

    this.$v.linea.$touch();
    if (this.$v.linea.$pending || this.$v.linea.$error) return;

    this.linea.PrimaryPhone.replace('-', '');
    this.linea.SecondaryPhone.replace('-', '');

    let LineJson = {
      ShippingLineName  : this.linea.ShippingLineName,
      ShippingLineAbbrev: this.linea.ShippingLineAbbrev, 
      Address           : this.linea.Address, 
      PrimaryPhone      : this.linea.PrimaryPhone, 
      SecondaryPhone    : this.linea.SecondaryPhone, 
      PrimaryEmail      : this.linea.PrimaryEmail, 
      SecondaryEmail    : this.linea.SecondaryEmail, 
      LineRoute         : this.linea.LineRoute,
      Color             : this.linea.Color
    };
    this.$http.post("shipping-agency-insert", LineJson, { root: 'LineJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.$store.commit('planificacionEstibaErrores/set', true);
      this.resetForm();
      
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error" });
  }
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  let _lang = this.$i18n.locale;
  this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';

  this.modalActive = newVal;
}
function resetForm() {
  this.linea.ShippingLineName = '';
  this.linea.ShippingLineAbbrev = '';
  this.linea.Address = '';
  this.linea.PrimaryPhone = '';
  this.linea.SecondaryPhone = '';
  this.linea.PrimaryEmail = '';
  this.linea.SecondaryEmail = '';
  this.linea.LineRoute = '';
  this.linea.Color = '#FFF';
  this.$v.$reset();
}

  function handleFileUpload(files, e) {
    this.linea.LineRoute = this.$refs.imageInput.file;
    this.$v.linea.LineRoute.$touch();     
  }
//Computeds:

export default {
  name: 'add-modal',
  mixins: [LineaNavieraMixin],
  props: {
    modal: Boolean
  },
  components: {
    PictureInput
  },
  data,
  validations: LineaValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    submitFile
  },
  computed: { },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>
<style lang="scss">
.preview {
  font-size: 0em;
}
.modal-content-linea {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
      padding-bottom: 0;
    }
  }
}
.card{
  border:0;
}
</style>