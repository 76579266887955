<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.CertificateEntry')}}</b>
      </CCardHeader>
      <div class="mt-3">
        <CCol sm="12" class="d-flex justify-content-end mb-3">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="toggleAdd"
            v-c-tooltip="{
              content: $t('label.CertificateEntry'),
              placement: 'top-end'
            }"
          >
            <CIcon name="cil-playlist-add"/>&nbsp;
            {{$t('label.nueva')}}
          </CButton>
            <CButton
              color="excel"
              class="mx-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
        </CCol>
        <CRow class="m-0">
          <CCol sm="12" lg="4">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label col-sm-12 col-lg-3 text-right">{{$t('label.vessel')}}</label>
              <div class="col-sm-12 col-lg-9">
                <v-select 
                  :placeholder="$t('label.select')"
                  :options="VesselOptions"
                  :reduce="option => option.value"
                  v-model="VesselId"
                  @input="onChangeVesselId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                </v-select>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="5">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label col-sm-12 col-lg-4 text-right">{{$t('label.visitItineraty')}}</label>
              <div class="col-sm-12 col-lg-8">
                <v-select 
                  :placeholder="$t('label.select')"
                  :options="VisitOrItineraryOptions"
                  :reduce="option => option.value"
                  v-model="VisitIdOrItineraryId"
                  :disabled="!VesselId"
                  @input="onInputVisitIdOrItineraryId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="item">
                    <p v-if="item.value" class="m-0">{{ `${item.VesselName} - ${item.Voyage}` }}</p>
                    <p v-else class="m-0">{{ item.label }}</p>
                    <cite v-if="item.value">{{ `${item.LabelArrival ?? ''} ${formatDateTimeWithSlash(item.Arrival)} - ${item.LabelDeparture ?? ''} ${formatDateTimeWithSlash(item.Departure)}` }} </cite>
                  </template>
                </v-select>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="3">
            <div v-if="VesselId" class="form-group form-row" rol="group">
              <label class="col-form-label col-sm-12 col-lg-3 text-right">{{$t('label.bl')}}</label>
              <div class="col-sm-12 col-lg-9">
                <v-select 
                  :placeholder="$t('label.select')"
                  :options="BlOption"
                  :reduce="option => option.value"
                  v-model.trim="BlCargoId"
                  :disabled="!VisitIdOrItineraryId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="{ Json, label, value }">
                    <div v-if="value">
                      <b>{{ label }}</b><cite>{{ `(${Json?.TpBlName})` }}</cite>
                    </div>
                    <div v-else>{{ label }}</div>
                  </template>
                </v-select>
              </div>
            </div>
            <CInput
              v-else
              v-uppercase
              :label="$t('label.bl')"
              :placeholder="$t('label.bl')"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
              v-model="BlNro"
              addLabelClasses="text-right"
            />
          </CCol>
          <CCol sm="12" lg="4">
            <CSelect
              v-uppercase
              add-label-classes="text-right"
              :label="$t('label.yard')"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}" 
              :options="YardOptions"
              v-model="UbicationId"
            />
          </CCol>
          <CCol sm="12" lg="7">
            <div class="form-group form-row d-flex aling-items-center mb-0">
                <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
                <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.fromDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                    >
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
                <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
                <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="true"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.toDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                    >
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </div>
                <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto">
                    <CButton
                      color="watch"
                      square
                      size="sm"
                      v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                      class="mx-1"
                      @click="getYardDocumentBlCargoList"
                    >
                        <CIcon name="cil-search"/>
                    </CButton>
                    <CButton
                      color="wipe"
                      square
                      size="sm"
                      v-c-tooltip="{ content: $t('label.clearFilters'), placement: 'top-end'}"
                      @click="RefreshFilter"
                    >
                        <CIcon name="cil-brush-alt"/>
                    </CButton>
                </div>
            </div>
          </CCol>
          
        </CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedItems"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
            @filtered-items-change="getFilteredList"
          >
            <template #TransactionDate="{ item }">
              <td class="cell-center text-center">
                {{ formatDateTimeWithSlash(item.TransactionDate) }}
              </td>
            </template>
            <template #Weigth="{ item }">
              <td class="cell-center text-center">
                {{ formatNumber(item.Weigth, 2) }}
              </td>
            </template>
            <template #Volumen="{ item }">
              <td class="cell-center text-center">
                {{ formatNumber(item.Volumen, 2) }}
              </td>
            </template>
            <template #Quantity="{ item }">
              <td class="cell-center text-center">
                {{ formatNumber(item.Quantity, 2) }}
              </td>
            </template>
            <template #TransactionDateCertification="{ item }">
              <td class="cell-center text-center">
                {{ formatDateTimeWithSlash(item.TransactionDateCertification) }}
              </td>
            </template>
            <template #YardDocumentStatus="{ item }">
              <td class="cell-center text-center">
                <b>{{ item.YardDocumentStatus }}</b>
              </td>
            </template>
            
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  size="sm"
                  square
                  color="edit"
                  class="mr-1"
                  @click="toggleEdit(item)"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.CertificateEntry'),
                    placement: 'top-start'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
                <CButton
                  size="sm"
                  square
                  color="danger"
                  @click="togglePdf(item)"
                  v-c-tooltip="{
                    content: 'PDF',
                    placement: 'top-start'
                  }"
                >
                  <CIcon name="cil-file-pdf" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </div>
    </CCard>
    <CertificateOfEntryModal
      :modal="modal"
      :isEdit="isEdit"
      :CertificateOfEntryItem="CertificateOfEntryItem"
      @close="modal=false, CertificateOfEntryItem={}, isEdit = false"
      @isEdit="isEdit = $event"
      @UpdateCertificateOfEntryItem="CertificateOfEntryItem = $event"
      @Update-list="getYardDocumentBlCargoList()"
    />
    <CertificateOfEntryModalPdf
      :modal="modalPdf"
      :CertificateOfEntryItem="CertificateOfEntryItem"
      @close="modalPdf=false, CertificateOfEntryItem={}"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import YardManagement from '@/_mixins/yardManagement';
import UpperCase from '@/_validations/uppercase-directive';
import CertificateOfEntryModal from './modal/index';
import CertificateOfEntryModalPdf from './modalPdf/index';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import moment from 'moment';

//data
function data() {
  return {
    modal: false,
    modalPdf: false,
    loading: false,
    isEdit: false,
    CertificateOfEntryList: [],
    CertificateOfEntryItem: {},
    UbicationId: '',
    UbicationList: [],
    VesselId: '',
    VesselList: [],
    VisitIdOrItineraryId: '',
    VisitOrItineraryList: [],
    BlCargoId: '',
    BlNro: '',
    BlList: [],
    searchFilterDate: {
      fromDay: '',
      toDay: '',
    },
    previousDate: '',
    laterDate: '',
  }
}

//methods
async function getYardDocumentBlCargoList() {
  this.loading = true;
  let Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.VisitIdOrItineraryId) ?? {};
  let YardDocumentJson = {
    YardDocumentJson: [{
      BlCargoId: this.BlCargoId,
      UbicationId: this.UbicationId,
      VisitId: Vessel?.VisitId ?? '',
      ItineraryId: Vessel?.ItineraryId ?? '',
      DateStart: DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay),
      DateEnd: DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay),
      SearchKey: this.BlNro,
      UserId: this.user.UserId,
      CompanyBranchId: this.branch.CompanyBranchId
    }]
  }
  await this.$http.get("YardDocumentBlCargo-list", { YardDocumentJson })
  .then(response => {
    this.CertificateOfEntryList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.loading = false;
  });
}

async function getVesselList() {
  await this.$http.get("Vessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.VesselList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
}

async function getYardDocumentUbicationList() {
  await this.$http.get("YardDocumentUbication-list-by-UserId", { UserId: this.user.UserId })
  .then(response => {
    this.UbicationList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
}

async function getVisitOrItineraryList() {
  this.loading = true;
  await this.$http.get("YardBlCargoVisitItinerary-by-CompanyBranchId", { VesselId: this.VesselId, CompanyBranchId: this.branch.CompanyBranchId })
  .then(response => {
    this.VisitOrItineraryList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.loading = false;
  });
}

async function getBlCargoList() {
  this.loading = true;
  let Vessel = {};
  Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.VisitIdOrItineraryId) ?? {};
  await this.$http.get("BlCargoAll-list", { BlCargoJson: { BlCargoJson: [{VisitId: Vessel?.VisitId ?? '', ItineraryId: Vessel?.ItineraryId ?? '', SearchKey: ''}] } })
  .then(response => {
    this.BlList = response?.data?.data ?? [];
  }).catch( err => {
    this.$notify({
    group: 'container',
    title: '¡Error!',
    text: err,
    type: "error"
    });
  })
  .finally(() => {
    this.loading = false;
  });
}

function getFilteredList(List) {
  this.FilterList = [...List];
}

function getDateRange(){
  this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
  this.laterDate = new Date();
  this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
  this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function onChangeVesselId() {
  this.VisitIdOrItineraryId = '';
  this.VisitOrItineraryList = [];
  this.BlCargoId = '';
  this.BlNro = '';
  this.BlList = [];
  if (this.VesselId) {
    this.getVisitOrItineraryList();
  }
}

function onInputVisitIdOrItineraryId() {
  this.BlCargoId = '';
  this.BlNro = '';
  this.BlList = [];
  if (this.VisitIdOrItineraryId) {
    this.getBlCargoList();
  }
}

function onInputBlCargoId(event) {
  this.$store.state.yardManagement.dataBl = this.BlList?.find(item => item.BlCargoId == event);
  this.$refs.BlSearch.search = this.dataBl?.BlNro ?? '';
  this.BlSearch = this.dataBl?.BlNro ?? '';
  this.$store.state.yardManagement.ContainerTabIndex = 0;
}

function toggleAdd() {
  this.isEdit = false;
  this.CertificateOfEntryItem = {};
  this.modal = true;
}

function toggleEdit(item) {
  this.isEdit = true;
  this.CertificateOfEntryItem = item;
  this.modal = true;
}

function togglePdf(item) {
  this.CertificateOfEntryItem = item;
  this.modalPdf = true;
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

async function RefreshFilter() {
  this.UbicationId = '';
  this.VesselId = '';
  this.VisitIdOrItineraryId = '';
  this.VisitOrItineraryList = [];
  this.BlCargoId = '';
  this.BlNro = '';
  this.BlList = [];
  this.searchFilterDate = {
    fromDay: '',
    toDay: '',
  };
  await this.getDateRange();
  await this.getYardDocumentBlCargoList();
}

function excelHeader() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [   
    { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
    { text: this.$t('label.CertificateNro'), value: "DocumentCode", aux: "", json: "" },
    { text: this.$t('label.CertificateDate'), value: "TransactionDate", aux: "", json: "", isDate: 2 },
    { text: this.$t('label.yard'), value: "UbicationName", aux: "", json: "" },
    { text: this.$t('label.visitItineraty'), value: "VesselName", aux: "", json: "" },
    { text: this.$t('label.bl'), value: "BlNro", aux: "", json: "" },
    { text: this.$t('label.blType'), value: "TpBlName", aux: "", json: "" },
    { text: this.$t('label.Master'), value: "MasterBlNro", aux: "", json: "" },
    { text: this.$t('label.group'), value: "TpCargoName", aux: "", json: "" },
    { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "" },
    { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "" },
    { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "" },
    { text: this.$t('label.client'), value: "ClientName", aux: "", json: "" },
    { text: this.$t('label.consolidator'), value: "ConsolidatorName", aux: "", json: "" },
    { text: this.$t('label.customBroker'), value: "CustomBrokerName", aux: "", json: "" },
    { text: this.$t('label.activity'), value: "PortActivityName", aux: "", json: "" },
    { text: this.$t('label.status'), value: "YardDocumentStatus", aux: "", json: "" },
    { text: this.$t('label.certificationDate'), value: "TransactionDateCertification", aux: "", json: "", isDate: 2 },
    { text: this.$t('label.UserCertifies'), value: "TransaLoginCertificate", aux: "", json: "" },
    { text: this.$t('label.registerDate'), value: "FormatedDate", aux: "", json: "" },
  ]
}

async function onBtnExport(valor){
  this.$store.state.yardManagement.loading = true;
  let dataList = [];
  dataList = dataList.concat(this.FilterList);

  let VisitName = this.VisitOrItineraryOptions.find((e)=>{ return (e.VisitId == this.VisitIdOrItineraryId) || (e.ItineraryId == this.VisitIdOrItineraryId)});
  let BlCargo = this.BlOption.find((e)=>{ return (e.value == this.BlCargoId)}) ?? false;
  
  let filters = [
    this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay,
    this.$t('label.vessel')+': '+ (VisitName?.VesselName ?  `${VisitName.VesselName} ${VisitName?.label ? `- ${VisitName?.label}` : ''}` : 'N/A'),
    `${this.$t('label.bl')}: ${BlCargo?.Json?.BlNro ?? 'N/A'}`,
    this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
    this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
  ]

  await this.excel([this.excelHeader()], [dataList], this.$t('label.CertificatesEntry'), this.BranchName, `${this.$t('label.CertificatesEntry')}`, [filters], [], false, false);
  this.$store.state.yardManagement.loading = false;
}

//Computed
function VesselOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VesselList.map(function(e){
    chart.push({
      ...e,
      value: e.VesselId,
      label: e.VesselName,
    })
  })
  return chart;
}

function VisitOrItineraryOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VisitOrItineraryList.map(function(e){
    chart.push({
      ...e,
      value: e.VisitId ?? e.ItineraryId, 
      label: `${e.Voyage} - ${e.LabelArrival ?? ''} ${formatDateTimeWithSlash(e.Arrival)}`,
    })
  })
  return chart;
}

function BlOption() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.BlList.map(e => {
    chart.push({
      value: e.BlCargoId,
      label: e.BlNro,
      Json: {...e }
    });
  });
  return chart;
}

function YardOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }] 
  this.UbicationList.map((e) => {
    chart.push({
      value: e.UbicationId,
      label: e.UbicationName,
    })
  })
  return chart;
}

function computedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.CertificateOfEntryList.map((item) => {
    return {
      ...item,
      ClientName: item.ClientName ?? '',
      PortActivityName: item[`PortActivityName${_lang}`] ?? '',
      TransaLoginCertificate: item.TransaLoginCertificate ?? 'N/A',
      YardDocumentStatus: item[`YardDocumentStatus${_lang}`] ?? '',
      MasterBlNro: item.MasterBlNro ?? '',
      CustomBrokerName: item.CustomBrokerName ?? '',
      ConsolidatorName: item.ConsolidatorName ?? '',
      TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate)
        : 'N/A',
      _style: `background: ${item?.YardDocumentStatusColor}`,
      _classes: 'color-gradient',
      
    };
  });
}

function fields(){
  return [
    { key: "options", label: "", _style:'max-width:90px; min-width:90px;', _classes:'center-cell',filter: false},
    { key: 'Nro', label: '#',_style: 'min-width: 60px;', _classes: 'text-center', filter: false },
    { key: 'DocumentCode',label: this.$t('label.CertificateNro'), _style:'min-width: 230px;', _classes: 'text-center'},
    { key: 'TransactionDate',label: this.$t('label.CertificateDate'), _style:'min-width: 180px;', _classes: 'text-center'},
    { key: 'UbicationName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-center'},
    { key: 'VesselName',label: this.$t('label.visitItineraty'), _style:'min-width: 200px;', _classes: 'text-center'},
    { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 180px;', _classes: 'text-center'},
    { key: 'TpBlName',label: this.$t('label.blType'), _style:'min-width: 160px;', _classes: 'text-center'},
    { key: 'MasterBlNro',label: this.$t('label.Master'), _style:'min-width: 180px;', _classes: 'text-center'},
    { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 160px;', _classes: 'text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 160px;', _classes: 'text-center' },
    { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width: 160px;', _classes: 'text-center' },
    { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:150px;', _classes: 'text-center'},
    { key: 'ConsolidatorName', label: this.$t('label.consolidator'), _style:'min-width:180px;', _classes: 'text-center'},
    { key: 'CustomBrokerName', label: this.$t('label.customBroker'), _style:'min-width:180px;', _classes: 'text-center'},
    { key: 'PortActivityName', label: this.$t('label.activity'), _style:'min-width:150px;', _classes: 'text-center'},
    { key: 'YardDocumentStatus', label: this.$t('label.status'),  _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'TransactionDateCertification',label: this.$t('label.certificationDate'), _style:'min-width: 200px;', _classes: 'text-center'},
    { key: 'TransaLoginCertificate', label: this.$t('label.UserCertifies'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'FormatedDate', label: this.$t('label.registerDate'), _style:'min-width: 180px;', _classes:"text-center"},
  ];
}

export default {
  name: 'index',
  components: {
    CertificateOfEntryModal,
    CertificateOfEntryModalPdf,
  },
  data,
  async mounted(){
    await this.getDateRange();
    await this.getVesselList();
    await this.getYardDocumentUbicationList();
    await this.getYardDocumentBlCargoList();
  },
  mixins: [GeneralMixin, YardManagement],
  directives: UpperCase,
  methods: {
    getYardDocumentBlCargoList,
    getVesselList,
    getYardDocumentUbicationList,
    getVisitOrItineraryList,
    getBlCargoList,
    getFilteredList,
    getDateRange,
    onChangeVesselId,
    onInputVisitIdOrItineraryId,
    onInputBlCargoId,
    toggleAdd,
    toggleEdit,
    togglePdf,
    formatDateTimeWithSlash,
    formatNumber,
    validateDateRange,
    RefreshFilter,
    onBtnExport,
    excelHeader
  },
  computed: {
    VesselOptions,
    VisitOrItineraryOptions,
    BlOption,
    YardOptions,
    computedItems,
    fields,
    ...mapState({
      branch: state => state.auth.branch,
      user: state => state.auth.user,
      yardData: state => state.yardManagement.yardData,
    })
  },
  watch: {
    
  },
};
</script>