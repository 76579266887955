<template>
  <div class="row mx-0 mt-3">
    <CCol sm="12" lg="6">
      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.hazardClass')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.DangerousGood.ImdgClassId.$model"
        :options="ImdgClassCodeOptions"
        :is-valid="hasError($v.DangerousGood.ImdgClassId)"
        :invalid-feedback="errorMessage($v.DangerousGood.ImdgClassId)"
        @change="DangerousGood.ImdgClassId='', DangerousGood.UnNumber=''"
      />

      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.imdgCode')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.DangerousGood.ImdgId.$model"
        :options="ImdgCodeOptions"
        :is-valid="hasError($v.DangerousGood.ImdgId)"
        :invalid-feedback="errorMessage($v.DangerousGood.ImdgId)"
        :disabled="!DangerousGood.ImdgClassId"
        @change="DangerousGood.UnNumber=''"
      />

      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.PackagingGroup')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        v-model="$v.DangerousGood.PackagingGroupId.$model"
        :options="PackagingGroupOptions"
        :is-valid="hasError($v.DangerousGood.PackagingGroupId)"
        :invalid-feedback="errorMessage($v.DangerousGood.PackagingGroupId)"
      />
    </CCol>

    <CCol sm="12" lg="6">
      <CSelect
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.UnNumber')"
        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
        v-model="$v.DangerousGood.UnNumber.$model"
        :options="UnNumberOptions"
        :is-valid="hasError($v.DangerousGood.UnNumber)"
        :invalid-feedback="errorMessage($v.DangerousGood.UnNumber)"
        :disabled="!DangerousGood.ImdgClassId"
      />

      <CTextarea
        size="sm"
        rows="2"
        :label="$t('label.DangerousGood')"
        addLabelClasses="text-right"
        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
        :placeholder="$t('label.Description')"
        v-model="$v.DangerousGood.DangerousGood.$model"
        v-uppercase
        :is-valid="hasError($v.DangerousGood.DangerousGood)"
        :invalid-feedback="errorMessage($v.DangerousGood.DangerousGood)"
      />
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
  
function data() {
  return {
  };
}

//computed
function ImdgClassCodeOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgClassCodeList.map(item => {
    chart.push({
      value: item.ImdgClassId,
      label: `${item.ImdgClassAbbrev} - ${item['ImdgClassName'+_lang] ?? ''}`,
    })    
  })
  return chart;
}

function ImdgCodeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgCodeList.map(item => {
    chart.push({
      value: item.ImdgId,
      label: `${item.ImdgCod}`,
    })    
  })
  return chart;
}

function UnNumberOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnNumberList.map(item => {
    chart.push({
      value: item.UnNumberId,
      label: item.UnNumber,
    })    
  })
  return chart;
}

function PackagingGroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingGroupList.map(item => {
    chart.push({
      value: item.PackagingGroupId,
      label: item.PackagingGroupCode,
    })    
  })
  return chart;
}

function ImdgClassCodeData() {
  return this.ImdgClassCodeList.find(item => item.ImdgClassId == this.DangerousGood.ImdgClassId) ?? {};
}

function ImdgCodeList() {
  return this.ImdgClassCodeData?.ImdgJson ?? [];
}

function UnNumberList() {
  if (this.DangerousGood.ImdgClassId) {
    if (this.DangerousGood.ImdgId) {
      let Data = this.ImdgCodeList.find(item => item.ImdgId == this.DangerousGood.ImdgId) ?? {};
      return Data?.UnNumberJson ?? [];
    } else {
      console.log(this.ImdgClassCodeData, 'this.ImdgClassCodeData');
      return this.ImdgClassCodeData?.UnNumberJson ?? [];
    }
  }else{
    return [];
  }
}
  
export default {
  name: 'dangerous-good-tab',
  components: {
  },
  data,
  props: {
    DangerousGood: {
      type: Object,
      required: true,
      default: () => {},
    },
    ImdgClassCodeList: {
      type: Array,
      default: () => [],
    },
    PackagingGroupList: {
      type: Array,
      default: () => [],
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations();
  },
  methods: {
  },
  computed:{
    ImdgCodeOptions,
    ImdgClassCodeOptions,
    UnNumberOptions,
    ImdgClassCodeData,
    PackagingGroupOptions,
    ImdgCodeList,
    UnNumberList,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.DangerousGood.$touch();
      }else{
        this.$v.$reset();
      }
    },
  }
};
</script>