<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="titulo"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-extended"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <CCol sm="12">
          <CRow class="justify-content-center">
            <CCol sm="11" lg="3" class="text-right mt-2 pr-1">
              <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.turn') }} </label>
            </CCol>
            <CCol sm="11" lg="6" class="pr-0">
              <v-select 
                :options="turnsOptions"
                label="TurnDs"
                :placeholder="$t('label.select')"
                class="select-adjust"
                v-model="turn"
                :disabled="isTurnsEmpty"
                :class="isTurnValido(turnVal)" 
              />
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
              <CCol sm="11" lg="3"  class="text-right mt-2 pr-1">
              </CCol>
              <CCol sm="11" lg="6" >
                <div :class="isTurnValid">
                        {{$t('label.required')}}
                      </div>
              </CCol>
            </CRow>
          <CRow class="justify-content-center">
            <CCol sm="11" lg="3" class="text-right mt-2 pr-1">
              <label><b class="text-danger"> * </b> {{ $t('label.vesselType') }} </label>
            </CCol>
            <CCol sm="11" lg="6" class="pr-0">
              <v-select 
                :options="vesselTypesOptions"
                label="TpVesselName"
                :placeholder="$t('label.select')"
                class="select-adjust"
                v-model="vesselType"
                :disabled="isVesselTypesEmpty"
                :class="isVesselTypeValido(vesselTypeVal)" 
              />
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
              <CCol sm="11" lg="3"  class="text-right mt-2 pr-1">
              </CCol>
              <CCol sm="11" lg="6" >
                <div :class="isVesselTypeValid">
                        {{$t('label.required')}}
                      </div>
              </CCol>
            </CRow>
          <CRow class="justify-content-center">
            <CCol sm="11" lg="3" class="text-right mt-2 pr-1">
              <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.status') }} </label>
            </CCol>
            <CCol sm="11" lg="6" class="pr-0">
              <CSelect
                :value.sync="shiftVesselType.Status"
                :is-valid="statusSelectColor"
                addLabelClasses="text-right"
                :options="statusOptions"
                class="mt-1"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </form>
    <template #footer>
      <CButton
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="statusConfirmation(shiftVesselTypeData.FgActTpVesselTurn, shiftVesselType.Status, submit)"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import { shiftVesselTypeFormEditValidations } from '@/_validations/estiba/ShiftVesselType';
import UpperCase  from '@/_validations/uppercase-directive';
import { ShiftVesselTypeMixin } from '@/_mixins/estiba';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoading: false,
    isLoadingTurns: false,
    turn: null, 
    turnsList: [],
    vesselType: null,
    vesselTypesList: [],
    isEdit: false,
    titulo: '',
                                    
    Status: null, 
    turnVal: false, 
    vesselTypeVal: false, 
    shiftVesselType: {  
      TpVesselTurnId: '',
      TurnId: '',
      TpVesselId: '',
      Status: 1,
    },

  }
}

//Methods
function submit() {
  try {
    this.isSubmit = true;
    this.isLoadingGeneral = true;
    this.turnVal = true;
    this.vesselTypeVal = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpVesselTurnJson = {
      TurnId: this.turn.TurnId,
      TpVesselId: this.vesselType.TpVesselId,
      Status: this.shiftVesselType.Status,
      TpVesselTurnId: this.shiftVesselType.TpVesselTurnId,
    }

    this.$http.put("TpVesselTurn-update", TpVesselTurnJson, { root: 'TpVesselTurnJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {

      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.error')+'!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
    });
  } catch (e) {

    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function toggle(newVal) {
  if(newVal)
    this.loadDataShiftVesselType(true);
  else
    {
    this.resetForm();
    this.$emit('submited');
    }

  this.modalActive = newVal;
}
function resetForm() {
  this.turn = null;
  this.turnsList = [];
  this.vesselType = null;
  this.vesselTypesList = [];
  this.titulo = '';

  this.shiftVesselType.TpVesselTurnId = '';
  this.shiftVesselType.TurnId = '';
  this.shiftVesselType.TpVesselId = '';
  this.shiftVesselType.Status = 1;

  this.isEdit= false;
  this.Status= null;
  this.turnVal= false;
  this.vesselTypeVal= false;
  this.$v.$reset();
}


function setShiftVesselTypeData(modalData) {
  let _this = this.$i18n.locale;
  let titleDs = _this == 'en' ? modalData.TurnNameEn : modalData.TurnNameEs;

  this.shiftVesselType.TpVesselTurnId = modalData.TpVesselTurnId;
  this.shiftVesselType.TurnId = modalData.TurnId;
  this.shiftVesselType.TpVesselId = modalData.TpVesselId;
  this.titulo = this.$t('label.editWorkShiftPerVesselType')+': '+titleDs;

  this.shiftVesselType.Status = modalData.FgActTpVesselTurn ? 1 : 0;

  this.turn = Object.assign({}, {
    value: modalData.TurnId,
    label: _this == 'en' ? modalData.TurnNameEn : modalData.TurnNameEs,
    TurnId: modalData.TurnId,
    TurnDs: _this == 'en' ? modalData.TurnNameEn : modalData.TurnNameEs,
  });

  this.vesselType = Object.assign({}, { 
    value: modalData.TpVesselId,
    label: modalData.TpVesselName,
    TpVesselId: modalData.TpVesselId,
  });

  this.vesselTypeVal = true;
  this.turnVal = true;
  this.$v.$touch();
}

function isTurnValido(turnVal) {
  if(this.turnVal === true){ 
    return (this.turn == null || this.turn == "") ? 'no-valido' : 'valido';
  }
}

function isVesselTypeValido(vesselTypeVal) {
  if(this.vesselTypeVal === true){ 
    return (this.vesselType == null || this.vesselType == "") ? 'no-valido' : 'valido';
  }
}

//Computeds:
function vesselTypesOptions() {
   return this.vesselTypesList.map((item) => Object.assign({}, item, {
    value: item.TpVesselId,
    TpVesselName: item.TpVesselName,
  }));
}

function turnsOptions() {
  let _this = this.$i18n.locale;

  return this.turnsList.map((item) => Object.assign({}, item, {
    value: item.TurnId,
    TurnDs: _this == 'en' ? item.TurnDsEn : item.TurnDs,
  }));
}

function statusSelectColor(){
  return this.shiftVesselType.Status === 1;
}

export default {
  name: 'modal-edit-shift-vessel-type',
  mixins: [
    ModalMixin,
    ShiftVesselTypeMixin
  ],
  props: {
    modal: Boolean,
    shiftVesselTypeData: {
      type: Object,
      default: () => { }
    },
  },
  components: {
  },
  data,
  validations: shiftVesselTypeFormEditValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    setShiftVesselTypeData,
    isTurnValido,
    isVesselTypeValido,
  },
  computed: {
    vesselTypesOptions,
    turnsOptions,
    statusSelectColor,
  },
  watch: {
    modal: function() {
      this.isEdit = true;
      this.setShiftVesselTypeData(this.shiftVesselTypeData);
    },
  }
}
</script>
<style lang="scss" scoped>
.invalido {
  display: block;
}

.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.center-cell {
text-align: center;
}
 
</style>