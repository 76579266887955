<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalOrder 
            :modal="showModalOrder" 
            @closeModal="showModalOrder=false" 
            @child-refresh="refrescarTabla=true" 
        />
       
        <CTabs variant="tabs"  @update:activeTab="handleTab" :active-tab="0">
            <CTab :title="$t('label.ordersList')"  :active="isActiveTab(0)">
                <div v-show="showCollapseIndex == 0" > 
                    <CRow class="my-3">
                        <CCol sm="11" lg="12" class="d-flex align-items-center justify-content-end">
                            <CButton
                                color="excel"
                                class="mr-1"
                                size="sm" 
                                @click="onBtnExport(true)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; XSLX
                            </CButton>
                            <CButton
                                color="watch"
                                size="sm" 
                                @click="onBtnExport(false)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; CSV
                            </CButton>
                            
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol sm="11" lg="3" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                                :label="$t('label.crane')"
                                :options="craneListFormatted"
                                v-model.trim="VisitCraneId"
                                :value.sync="VisitCraneId"
                                @change="filterCrane"
                            />        
                        </CCol>
                        <CCol sm="11" lg="3" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-10 col-lg-4 text-right', input: 'col-sm-10 col-lg-8 px-0'}"
                                :label="$t('label.hold')"
                                :options="holdListFormatted"
                                v-model.trim="vesselHoldId"
                                :value.sync="vesselHoldId"
                                @change="filterHold"
                            />        
                        </CCol>
                        <CCol sm="11" lg="4" class="center-field">
                            <CSelect
                                :value.sync="clientId"
                                v-model.trim="clientId"
                                :horizontal="{ label: 'col-sm-10 col-lg-auto text-right', input: 'col-sm-10 col-lg-8 px-0'}"
                                :label="$t('label.transport')"
                                :options="carrierListFormatted"
                                @change="filterCarrier"
                            />
                        </CCol>
                        <CCol sm="11" lg="2" class=" justify-content-end">
                            <CButton 
                                color="add"
                                size="sm"
                                v-c-tooltip="{
                                    content: $t('label.order'),
                                    placement: 'top',
                                }"
                                @click="showModalOrder=true"
                                >
                                <CIcon name="cil-playlist-add"/>{{ $t('label.nueva')}}
                            </CButton>
                            
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="11" lg="3" class="center-field">
                            <CSelect
                            :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                            :label="$t('label.status')"
                            :options="orderShipmentStatusFormatted"
                            v-model.trim="processStatusId"
                            :value.sync="processStatusId"
                            @change="filterStatus"
                        />        
                        </CCol>
                        <label class="col-sm-11 col-lg-1 text-right mt-2">{{$t('label.date')}}</label>
                        <CCol sm="11" lg="2" class="center-field px-0">
                            <vue-datepicker 
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="false"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="searchFilterDate.fromDay"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                            >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker>
                        </CCol>
                        <label class="col-sm-11 col-lg-auto text-right mt-2">-</label>
                        <CCol sm="11" lg="2" class="center-field px-0 mb-2">
                            <vue-datepicker 
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="false"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="searchFilterDate.toDay"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                            >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker>
                        </CCol>
                        <CCol class="col-sm-11 col-lg-auto center-field mr-0" style="text-align: right;">
                            <CButton
                                color="watch"
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                                @click="getOrderList()"
                                >
                                <CIcon name="cil-search" />
                            </CButton>
                            <CButton
                                color="wipe"
                                class="justify-content-end"
                                size="sm"
                                v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                                @click="refreshComponent()"
                                >
                                <CIcon name="cil-brush-alt" />
                            </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="11" lg="3" class="center-field">
                            <CInput
                                :label="$t('label.filter')"
                                :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                                :placeholder="$t('label.enterSearch')" 
                                @keyup="onQuickFilterChanged($event.target.value)" 
                                v-model="search"           
                            >
                            </CInput>  
                        </CCol>
                        <CCol sm="11" lg="5" class="center-field">
                        </CCol>
                        <CCol sm="11" lg="4" class="justify-content-end">
                            <CSelect
                                :horizontal="{ label: 'col-sm-10 col-lg-8 text-right', input: 'col-sm-10 col-lg-4 px-0'}"
                                :label="$t('label.recordsPerPage')"
                                @change="onPageSizeChanged"
                                :options="[50,100,500,1000]"
                                required 
                                v-model="pageSize"
                            >
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="11" lg="12" class=" mb-2">
                            <ag-grid-vue
                                v-if="showGrid"
                                style="width: 100%; height: 100vh;"
                                class="ag-theme-alpine"
                                :getRowClass="getRowClass"
                                :getRowStyle="getRowStyle"
                                :gridOptions="gridOptions"
                                :defaultColDef="defaultColDef"
                                :localeText="localeText"
                                :columnDefs="columnDefs"
                                :rowData="RowData"
                                :suppressRowClickSelection="true"
                                :groupSelectsChildren="true"
                                :enableRangeSelection="true"
                                :pagination="true"
                                :paginationPageSize="paginationPageSize"
                                :paginationNumberFormatter="paginationNumberFormatter"
                                rowSelection="single"
                                @grid-ready="onGridReady"
                                :tooltipShowDelay="tooltipShowDelay"
                                :tooltipHideDelay="tooltipHideDelay"
                            >
                            </ag-grid-vue>
                        </CCol>
                    </CRow>
                </div>
                <div v-show="showCollapseIndex == 1">
                    <collapseIncidenceList 
                        :order-data="orderData" 
                        @closeCollapseIncidence="showCollapseIndex=0" 
                        @child-refresh="refrescarTabla=true" 
                    />
                </div>
                <div v-show="showCollapseIndex == 2">
                    <collapseCircuitList 
                        :circuit-data="circuitData" 
                        @closeCollapseCircuit="showCollapseIndex=0" 
                        @child-refresh="refrescarTabla=true" 
                    />
                </div>
                <div v-show="showCollapseIndex == 3">
                    <collapseTimeLineList 
                        :circuit-data="timeLineData" 
                        @closeCollapseTimeLine="showCollapseIndex=0" 
                        @child-refresh="refrescarTabla=true" 
                    />
                </div>
            </CTab>   
        </CTabs>
        
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import modalOrder from '@/pages/visita/tabs/shipment_orders/order_list/modal-order';
    import collapseIncidenceList from '@/pages/visita/tabs/shipment_orders/order_list/incidence/index-incidence';
    import collapseCircuitList from '@/pages/visita/tabs/shipment_orders/order_list/circuit/index-circuit';
    import collapseTimeLineList from '@/pages/visita/tabs/shipment_orders/order_list/time_line/index-time-line';
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import moment from 'moment';

    //Data
    function data() {
        return {
            tooltipShowDelay: 0,
            tooltipHideDelay: 2000,
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,

            loadingOverlay:false,
            refrescarTabla: false,

            rows: [],
            carrierOptions: [],
            craneOptions: [],
            orderShipmentStatusOptions: [],
            holdOptions: [],

            showCollapseIndex: 0,
            activeTab: 0,

            clientId: "",
            nulo:'', 
            pageSize:'',
            search: '',
            showModalOrder: '',
            damageData: '',

            VisitCraneId: '',
            processStatusId: '',
            vesselHoldId: '',
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),

            orderData: '',
            circuitData: '',
            timeLineData: '',
        };
    }
    //beforeMount
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'generateOrder'){
                    if(event.value !== ""){
                        this.getOrderReport(event.data);
                    }
                }
                else if(event.colDef.colId == 'editOrder'){
                    this.showModalOrder = event.data;
                }
                else if(event.colDef.colId == 'seeCircuit'){
                    if(event.value !== ""){
                        this.showCollapseIndex = 2;
                        this.circuitData = event.data;
                    }
                }
                else if(event.colDef.colId == 'seeIncidence'){
                    if(event.value !== ""){
                        this.showCollapseIndex = 1;
                        this.orderData = event.data;
                    }
                }else if(event.colDef.colId == 'seeTimeLine'){
                    if(event.value !== ""){
                        this.showCollapseIndex = 3;
                        this.timeLineData = event.data;
                    }
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'generateOrder'){
                   tippy('#myGenerateOrderButton', {
                        content: this.$t('label.generateOrder'),
                    })
                } else if(event.colDef.colId == 'editOrder'){
                    tippy('#myEditOrderButton', {
                        content: this.$t('label.edit')+' '+this.$t('label.order'),
                    })
                }
                else if(event.colDef.colId == 'seeCircuit'){
                   tippy('#mySeeCircuitButton', {
                        content: this.$t('label.see')+' '+this.$t('label.circuit'),
                    })
                }else if(event.colDef.colId == 'seeIncidence'){
                    tippy('#mySeeIncidenceButton', {
                        content: this.$t('label.see')+' '+this.$t('label.incidences'),
                    })
                }else if(event.colDef.colId == 'seeTimeLine'){
                    tippy('#mySeeTimeLineButton', {
                        content: this.$t('label.see')+' '+this.$t('label.timeLine'),
                    })
                }
            },

        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function created() {
    }
    //methods
    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }

    function getRowClass() {
        return 'bg-gradient';
    }

    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.tooltipShowDelay = 0;
        this.tooltipHideDelay = 2000;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function getOrderList () {
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

        let ShipmentOrderJson = {
            VisitId: this.Visit,
            StowagePlannigResourceId: this.clientId,
            VisitCraneId: this.VisitCraneId,
            VesselHoldId: this.vesselHoldId,
            OrderStatusId: this.processStatusId,
            DateStart: dateStart,
            DateEnd: dateEnd,
        };

        await this.$http.post("VisitShipmentOrder-list", ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then(response => {
            let _lang = this.$i18n.locale;
            let computedItems = [];
            let listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado =='')
            { 
                this.rows = [];
                return false;
            }
            
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                };
            });
            
            let _this = this;
            if(this.rows.length !== 0 && this.nulo!="null"){

                this.rows.map(function(item){
                    var CircuitId = '';
                    var CircuitName = ''; 
                    var CircuitOrder = '';

                    if(item.CurrentCircuitJson.length > 0){
                        CircuitId= item.CurrentCircuitJson[0].CircuitId ? item.CurrentCircuitJson[0].CircuitId : '';
                        CircuitName= _lang=='en' ?  item.CurrentCircuitJson[0].CircuitNameEn : item.CurrentCircuitJson[0].CircuitNameEs;
                        CircuitOrder= item.CurrentCircuitJson[0].CircuitOrder ? item.CurrentCircuitJson[0].CircuitOrder : '';
                    }

                    computedItems.push({
                        Idx: item.Nro,
                        generateOrder: _this.$t('label.generateOrder'),
                        editOrder: _this.$t('label.edit')+' '+_this.$t('label.order'),
                        seeCircuit: _this.$t('label.see')+' '+_this.$t('label.circuit'),
                        seeIncidence: _this.$t('label.see')+' '+_this.$t('label.incidences'),
                        seeTimeLine: _this.$t('label.see')+' '+_this.$t('label.timeLine'),
                        OrderId: item.OrderId ? item.OrderId : '',

                        StowagePlanningVehicleId: item.StowagePlanningVehicleId ? item.StowagePlanningVehicleId : '',
                        VehicleId: item.VehicleId ? item.VehicleId : '',
                        LicensePlate: item.LicensePlate ? item.LicensePlate : '',
                        DriverName: item.DriverName ? item.DriverName : '',
                        StowagePlanningImplementVehicleId: item.StowagePlanningImplementVehicleId ? item.StowagePlanningImplementVehicleId : '',
                        StowagePlanningImplementId: item.StowagePlanningImplementId ? item.StowagePlanningImplementId : '',
                        ImplementId: item.ImplementId ? item.ImplementId : '',
                        ImplementAlias: item.ImplementAlias ? item.ImplementAlias : '',

                        NroBl: item.NroBl ? item.NroBl : '',

                        OrderCode: item.OrderCode ? item.OrderCode : '',

                        ClientId: item.ClientId ? item.ClientId : '',
                        ClientName: item.ClientName ? item.ClientName : '',
                        ClientRif: item.ClientRif ? item.ClientRif : '',
                        ClientTpId: item.ClientTpId ? item.ClientTpId : '',
                        YardId: item.YardId ? item.YardId : '',
                        YardName: item.YardName ? item.YardName : '',
                        YardClientName: item.YardClientName ? item.YardClientName : '',
                        YardClientTpId: item.YardClientTpId ? item.YardClientTpId : '',
                        
                        OrderDate: item.TransactionDate ? item.TransactionDate : '', 
                        TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '', //fecha emision
                        LastDate: item.LastDate ? DateFormater.formatDateTimeWithSlash(item.LastDate) : '', //ultima fecha
                        FinishDate: item.FinishDate ? DateFormater.formatDateTimeWithSlash(item.FinishDate) : '', //fecha embarqyue

                        VisitLapseId: item.VisitLapseId ? item.VisitLapseId : '', //corte
                        VisitLapseCode: item.VisitLapseCode ? item.VisitLapseCode : '', //corte

                        TotalTime: item.TotalTime ? item.TotalTime : '',

                        VisitCraneId: item.VisitCraneId ? item.VisitCraneId : '',
                        CraneAlias: item.CraneAlias ? item.CraneAlias : '',

                        VesselHoldId: item.VesselHoldId ? item.VesselHoldId : '',
                        VesselHoldName: item.VesselHoldName ? item.VesselHoldName : '',

                        DeviceId: item.DeviceId ? item.DeviceId : '',
                        DeviceCode: item.DeviceCode ? item.DeviceCode : '',

                        OrderStatus: item.OrderStatus ? item.OrderStatus : '',
                        OrderStatusDs: _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs,
                        
                        TransaLogin: item.TransaLogin ? item.TransaLogin : '',

                        CircuitId: CircuitId,
                        CircuitName: CircuitName,
                        CircuitOrder: CircuitOrder,

                        TransactionFinishTurnId: item.TransactionFinishTurnId ? item.TransactionFinishTurnId : '',
                        TransactionFinishTurnName: item.TransactionFinishTurnName ? item.TransactionFinishTurnName+' ('+item.TransactionFinishStartHour+'-'+item.TransactionFinishEndHour+')' : '',

                        ColorStatus: item.ColorStatus ? item.ColorStatus : '',

                        StowagePlanningBillOfLadingId: item.StowagePlanningBillOfLadingId ? item.StowagePlanningBillOfLadingId : '',
                        StowagePlanningResourceId: item.StowagePlanningResourceId ? item.StowagePlanningResourceId : '',
                        StowagePlanningDriverId: item.StowagePlanningDriverId ? item.StowagePlanningDriverId : '',
                        
                        TransaUserId: item.TransaUserId ? item.TransaUserId : '',

                        DriverId: item.DriverId ? item.DriverId : '',
                        DriverCi: item.DriverCi ? item.DriverCi : '',
                        DriverName: item.DriverName ? item.DriverName : '',
                        Observation: item.Observation ? item.Observation : '',
                        
                        NetWeight: item.NetWeight ? item.NetWeight : 0,
                        //NetWeightOrigin: item.NetWeight ? formatMilDecimal(parseFloat(item.NetWeight).toFixed(2)) : '0,00',
                        FullWeight: item.FullWeight ? item.FullWeight : 0,
                        //FullWeightOrigin: item.FullWeight? formatMilDecimal(parseFloat(item.FullWeight).toFixed(2)) : '0,00',
                        TareWeight: item.TareWeight ? item.TareWeight : 0,
                        //TareWeightOrigin: item.TareWeight ? formatMilDecimal(parseFloat(item.TareWeight).toFixed(2)) : '0,00',

                        AnulationDate: item.AnulationDate ? DateFormater.formatDateTimeWithSlash(item.AnulationDate) : '',
                        AnulationDeviceCode: item.AnulationDeviceCode ? item.AnulationDeviceCode : '',
                        AnulationLogin: item.AnulationLogin ? item.AnulationLogin : '',
                        ObservationAnulation: item.ObservationAnulation ? item.ObservationAnulation : '',
                    })
                })
            }
            
            this.rows = [];
            this.rows = computedItems; 
            
        }).catch( err => {
            
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function filterCarrier(e) {
        this.clientId=e.target.value;
    } 
    
    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.getHoldList(true);
    }

    async function filterStatus(e) {
        this.processStatusId=e.target.value;
    }

    async function filterHold(e) {
        this.vesselHoldId=e.target.value;
    }
    //transportes
    async function getCarrierList() {
        await this.$http
            .get('VisitCarrier-list?VisitId='+this.Visit)
            .then((response) => {
                this.carrierOptions = response.data.data;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    //gruas
    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    //bodegas
    async function getHoldList(Refresh) {
        this.loadingOverlay = true;
        let metodo = "VisitVesselHold-list";
        if (this.VisitCraneId !== '') {
            metodo = "VisitVesselCraneHold-list";
        }
        this.$http.get( metodo, { VisitId: this.Visit, VisitCraneId: this.VisitCraneId })
        .then(response => {
            this.holdOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            if (Refresh) {
                this.loadingOverlay = false;
            } 
        })
    }

    //status
    function getStatusList() {
        this.$http.get("OrderShipmentStatus-list")
        .then(response => {
            this.orderShipmentStatusOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }
    //rango de fechas
    function getDateRange(){
        if(this.itinerarySelected.Ata!=undefined){ 
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined){
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined){  
            if (this.itinerarySelected.Ata!=undefined) {
                this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            } else {
                this.searchFilterDate.fromDay = DateFormater.formatDateRange(this.itinerarySelected.Eta);
            }
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined){
            let currentDate = new Date();
            this.searchFilterDate.fromDay = `${moment(currentDate).format('DD/MM/YYYY')} 00:00`;
            this.searchFilterDate.toDay = DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    function handleTab(tab) {
        this.activeTab = tab;
    }

    function isActiveTab(current) {
        return this.activeTab == current;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.showModalOrder= "";
        this.damageData = "";
        this.showCollapseIndex = 0;
        this.search = "";
        this.tooltipShowDelay = 0,
        this.tooltipHideDelay = 2000,
        this.clientId = "",
        this.pageSize="";

        this.VisitCraneId= "";
        this.vesselHoldId = "";
        this.processStatusId = "";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";

        this.onQuickFilterChanged(this.search);
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getCarrierList();
        await this.getCraneList();
        await this.getStatusList();
        await this.getHoldList();
        await this.getDateRange();
        await this.getOrderList();
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

        //status
    async function getOrderReport(data) {
        this.loadingOverlay = true;
        let listado = [];
        let generalData = [];
        let incidenceData = [];
        let fullWeighingData = [];
        let emptyWeighingData = [];
        let shimpmentData = [];
        let _lang = this.$i18n.locale;
        let isAnnulled = (data.OrderStatus == "BED237C2-60CA-EC11-A87C-000D3A0DEDC7") ? true : false;

        await this.$http.ejecutar('GET', 'VisitShipmentOrderReport', { OrderId: data.OrderId }).then(response => {
            
            listado = response.data.data;

                if(listado[0].GeneralDataOrderJson.length > 0){
                    generalData.push({
                        NetWeight: listado[0].GeneralDataOrderJson[0].NetWeight ? formatMilDecimal(listado[0].GeneralDataOrderJson[0].NetWeight) : '0',
                        BlClientName: listado[0].GeneralDataOrderJson[0].BlClientName ? listado[0].GeneralDataOrderJson[0].BlClientName : '',
                        BlClientRif: listado[0].GeneralDataOrderJson[0].BlClientRif ? listado[0].GeneralDataOrderJson[0].BlClientRif : '',
                        ClientName: listado[0].GeneralDataOrderJson[0].ClientName ? listado[0].GeneralDataOrderJson[0].ClientName : '',
                        ClientRif: listado[0].GeneralDataOrderJson[0].ClientRif ? listado[0].GeneralDataOrderJson[0].ClientRif : '',
                        TransactionDate: listado[0].GeneralDataOrderJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].GeneralDataOrderJson[0].TransactionDate) : '',  //fecha emision
                        OrderCode: listado[0].GeneralDataOrderJson[0].OrderCode ? listado[0].GeneralDataOrderJson[0].OrderCode : '',
                        DriverCi: listado[0].GeneralDataOrderJson[0].DriverCi ? listado[0].GeneralDataOrderJson[0].DriverCi : '',
                        DriverName: listado[0].GeneralDataOrderJson[0].DriverName ? listado[0].GeneralDataOrderJson[0].DriverName : '',
                        LicensePlate: listado[0].GeneralDataOrderJson[0].LicensePlate ? listado[0].GeneralDataOrderJson[0].LicensePlate : '',
                        ImplementAlias: listado[0].GeneralDataOrderJson[0].ImplementAlias ? listado[0].GeneralDataOrderJson[0].ImplementAlias : '',
                        CommodityName: listado[0].GeneralDataOrderJson[0].CommodityName ? listado[0].GeneralDataOrderJson[0].CommodityName : '',
                        Observation: listado[0].GeneralDataOrderJson[0].Observation ? listado[0].GeneralDataOrderJson[0].Observation : '',
                        YardClientName: listado[0].GeneralDataOrderJson[0].YardClientName ? listado[0].GeneralDataOrderJson[0].YardClientName : '',
                        YardClientRif: listado[0].GeneralDataOrderJson[0].YardClientRif ? listado[0].GeneralDataOrderJson[0].YardClientRif : '',
                        YardName: listado[0].GeneralDataOrderJson[0].YardName ? listado[0].GeneralDataOrderJson[0].YardName : '',
                    })                
                };
                let i=1;
                if(listado[0].IncidenceInfoJson.length > 0){
                    for(let j=0; j < listado[0].IncidenceInfoJson.length; j++){
                        incidenceData.push({
                            Nro: i++,
                            IncidenceName: _lang =='en' ? listado[0].IncidenceInfoJson[j].IncidenceNameEn : listado[0].IncidenceInfoJson[j].IncidenceNameEs,
                            TransactionBegin: listado[0].IncidenceInfoJson[j].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[0].IncidenceInfoJson[j].TransactionBegin) : '',
                            TransactionFinish: listado[0].IncidenceInfoJson[j].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[0].IncidenceInfoJson[j].TransactionFinish) : '', 
                            TotalTimeH: listado[0].IncidenceInfoJson[j].TotalTimeH ? listado[0].IncidenceInfoJson[j].TotalTimeH : '',
                        })   
                    }             
                };

                if(listado[0].TareWeighingInfoJson.length > 0){
                    emptyWeighingData.push({
                        WeightKgs: listado[0].TareWeighingInfoJson[0].WeightKgs ? listado[0].TareWeighingInfoJson[0].WeightKgs+' KG' : '0 KG',
                        WeightTon: listado[0].TareWeighingInfoJson[0].WeightTon ? listado[0].TareWeighingInfoJson[0].WeightTon+' TON' : '0 TON',
                        TruckWeghingScaleName: listado[0].TareWeighingInfoJson[0].TruckWeghingScaleName ? listado[0].TareWeighingInfoJson[0].TruckWeghingScaleName : '',
                        TransactionDate: listado[0].TareWeighingInfoJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].TareWeighingInfoJson[0].TransactionDate) : '',
                    })                
                }else{
                    emptyWeighingData.push({
                        WeightKgs: '0 KG',
                        WeightTon: '0 TON',
                        TruckWeghingScaleName: '',
                        TransactionDate: '',
                    })
                };

                if(listado[0].FullWeighingInfoJson.length > 0){
                    fullWeighingData.push({
                        WeightKgs: listado[0].FullWeighingInfoJson[0].WeightKgs ? listado[0].FullWeighingInfoJson[0].WeightKgs+' KG' : '0 KG',
                        WeightTon: listado[0].FullWeighingInfoJson[0].WeightTon ? listado[0].FullWeighingInfoJson[0].WeightTon+' TON' : '0 TON',
                        TruckWeghingScaleName: listado[0].FullWeighingInfoJson[0].TruckWeghingScaleName ? listado[0].FullWeighingInfoJson[0].TruckWeghingScaleName : '',
                        TransactionDate: listado[0].FullWeighingInfoJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].FullWeighingInfoJson[0].TransactionDate) : '',
                    })                
                }else{
                    fullWeighingData.push({
                        WeightKgs: '0 KG',
                        WeightTon: '0 TON',
                        TruckWeghingScaleName: '',
                        TransactionDate: '',
                    })
                };

                if(listado[0].ShimpmentInfoJson.length > 0){
                    shimpmentData.push({
                        CraneAlias: listado[0].ShimpmentInfoJson[0].CraneAlias ? listado[0].ShimpmentInfoJson[0].CraneAlias : '',
                        VesselHoldName: listado[0].ShimpmentInfoJson[0].VesselHoldName ? listado[0].ShimpmentInfoJson[0].VesselHoldName : '',
                        TransaLoginName: listado[0].ShimpmentInfoJson[0].TransaLoginName ? listado[0].ShimpmentInfoJson[0].TransaLoginName : '',
                        TransaLogin: listado[0].ShimpmentInfoJson[0].TransaLogin ? listado[0].ShimpmentInfoJson[0].TransaLogin : '',
                        TransactionBegin: listado[0].ShimpmentInfoJson[0].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[0].ShimpmentInfoJson[0].TransactionBegin) : '',
                        TransactionFinish: listado[0].ShimpmentInfoJson[0].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[0].ShimpmentInfoJson[0].TransactionFinish) : '',
                    })                
                };
                
                this.orderMetalScrapPdf(generalData[0], incidenceData, fullWeighingData[0], emptyWeighingData[0], shimpmentData[0], this.$t('label.order'), isAnnulled);

            }).catch( err => {
                this.loadingOverlay = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).then(() => {});
            this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];
        let valores = []; 
        let currentDate = new Date();
        const transportData = this.carrierOptions.find( item => item.StowagePlanningResourceId === this.clientId );
        const craneData = this.craneOptions.find( item => item.VisitCraneId === this.VisitCraneId );
        const holdData = this.holdOptions.find( item => item.VesselHoldId === this.vesselHoldId );

        if(transportData !== '' && transportData !== null && transportData !== undefined){
            valores[1] = this.$t('label.transport')+': '+transportData.ClientName;
        }else{
            valores[1] = this.$t('label.transport')+': '+this.$t('label.all');
        }

        if(holdData !== '' && holdData !== null && holdData !== undefined){
            valores[3] = this.$t('label.hold')+': '+holdData.VesselHoldName;
        }else{
            valores[3] = this.$t('label.hold')+': '+this.$t('label.ALL');
        }

        if(craneData !== '' && craneData !== null && craneData !== undefined){
            valores[2] = this.$t('label.crane')+': '+craneData.CraneAlias;
        }else{
            valores[2] = this.$t('label.crane')+': '+this.$t('label.ALL');
        }
 
        valores[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
        valores[4] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[5] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push( node.data ));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Idx:rowData[i].Idx,
                LicensePlate:rowData[i].LicensePlate,
                DriverName:rowData[i].DriverName,
                ImplementAlias:rowData[i].ImplementAlias,
                NroBl:rowData[i].NroBl,
                OrderCode: rowData[i].OrderCode,
                ClientName:rowData[i].ClientName,
                ClientRif:rowData[i].ClientRif,
                YardName:rowData[i].YardName,
                YardClientName:rowData[i].YardClientName,
                OrderDate:rowData[i].OrderDate, 
                TransactionDate:rowData[i].TransactionDate, //fecha emision
                LastDate:rowData[i].LastDate, //ultima fecha
                FinishDate:rowData[i].FinishDate, //fecha embarqyue
                VisitLapseCode:rowData[i].VisitLapseCode, //corte
                TotalTime:rowData[i].TotalTime,
                CraneAlias:rowData[i].CraneAlias,
                VesselHoldName:rowData[i].VesselHoldName,
                DeviceCode:rowData[i].DeviceCode,
                OrderStatus:rowData[i].OrderStatus,
                OrderStatusDs:rowData[i].OrderStatusDs,
                TransaLogin:rowData[i].TransaLogin,
                CircuitName:rowData[i].CircuitName,
                CircuitOrder:rowData[i].CircuitOrder,
                TransactionFinishTurnId:rowData[i].TransactionFinishTurnId,
                TransactionFinishTurnName:rowData[i].TransactionFinishTurnName,
                ColorStatus: rowData[i].ColorStatus,
                DriverCi: rowData[i].DriverCi,
                DriverName: rowData[i].DriverName,
                Observation: rowData[i].Observation,
                NetWeight: rowData[i].NetWeight,
                FullWeight: rowData[i].FullWeight,
                TareWeight: rowData[i].TareWeight,
                AnulationDate: rowData[i].AnulationDate,
                AnulationDeviceCode: rowData[i].AnulationDeviceCode,
                AnulationLogin: rowData[i].AnulationLogin,
                ObservationAnulation: rowData[i].ObservationAnulation,
            });
        }

        if(this.nulo!="null" && this.RowData.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.ordersList'),valor, valores,5);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }


//computed
    //transporte
    function carrierListFormatted(){
        const chart = [
            {
                value: "",
                label: this.$t('label.select'),
            },
        ];
        this.carrierOptions.map((item) => {
            chart.push({
                label: item.ClientName,
                value: item.StowagePlanningResourceId,
            });   
        });
        return chart;    
    }

    //gruas
    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    //bodegas
    function holdListFormatted(){
        if(this.holdOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.holdOptions.map(function(e){
                
                    chart.push({
                        value: e.VesselHoldId, 
                        label: e.VesselHoldName,
                    })
                
            })
            return chart;
        }
    }
    //status
    function orderShipmentStatusFormatted(){
        if(this.orderShipmentStatusOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.orderShipmentStatusOptions.map(function(e){
                
                    chart.push({
                        value: e.ProcessStatusId, 
                        label: e.ValueDsEs,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'generateOrder',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "generateOrder",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="myGenerateOrderButton" class="btn mr-1 btn-danger btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon">
                                        <path fill="var(--ci-primary-color, currentColor)" d="M358.627,16H72V240H16V496H496V153.373ZM464,166.627V168H344V48h1.373ZM48,464V272H464V464Zm56-224V48H312V200H464v40Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M148.5,304H100V432h32V400h16.5A47.555,47.555,0,0,0,196,352.5v-1A47.555,47.555,0,0,0,148.5,304ZM164,352.5A15.517,15.517,0,0,1,148.5,368H132V336h16.5A15.517,15.517,0,0,1,164,351.5Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="340 432 372 432 372 392 404 392 404 360 372 360 372 336 420 336 420 304 340 304 340 432" class="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M259.833,304H212V432h47.833A56.23,56.23,0,0,0,316,375.833V360.167A56.23,56.23,0,0,0,259.833,304ZM284,375.833A24.194,24.194,0,0,1,259.833,400H244V336h15.833A24.194,24.194,0,0,1,284,360.167Z" class="ci-primary"></path>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                headerName: "",
                field: 'editOrder',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "editOrder",
                cellRenderer: params => {
                if(params.value !== ""){
                    return  `   
                        <Button id="myEditOrderButton" class="btn mr-1 btn-edit btn-sm">                   
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.93 22.93" role="img" class="c-icon" data-v-3be54f06="">
                                <g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1">
                                    <path id="_Color" data-name=" Color" d="M4.78,22.93H0V18.15L14.09,4.06l4.77,4.78ZM20.23,7.48h0L15.45,2.7,17.78.37a1.29,1.29,0,0,1,1.8,0l3,3a1.29,1.29,0,0,1,0,1.8L20.23,7.48Z">
                                    </path>
                                </g></g>
                            </svg>
                        </Button>
                    `
                }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                headerName: "",
                field: 'seeCircuit',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeCircuit",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="mySeeCircuitButton" class="btn mr-1 btn-watch btn-sm" >
                                                
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.03 26.4" role="img" class="c-icon">
                                        <g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1">
                                            <path class="cls-1" d="M22.85,14h-.52a.52.52,0,0,0-.52.51v.07a.52.52,0,0,0,.52.52h.52a3.78,3.78,0,0,1,1.68.4,3.86,3.86,0,0,1,1.18.93.53.53,0,0,0,.52.19l.07,0a.49.49,0,0,0,.37-.34.48.48,0,0,0-.08-.49A4.79,4.79,0,0,0,25,14.53,4.85,4.85,0,0,0,22.85,14Z"/>
                                            <path class="cls-1" d="M22.87,6.37a.56.56,0,0,0,.56-.57V3l3-1-3-1V.57A.56.56,0,0,0,22.87,0a.57.57,0,0,0-.57.57V5.8A.57.57,0,0,0,22.87,6.37Z"/>
                                            <path class="cls-1" d="M9.27,6.44A1.66,1.66,0,1,0,7.61,4.78,1.66,1.66,0,0,0,9.27,6.44ZM9.27,4a.83.83,0,1,1-.83.83A.84.84,0,0,1,9.27,4Z"/>
                                            <path class="cls-1" d="M3.32,9.59a1.66,1.66,0,1,0-1.66,1.66A1.66,1.66,0,0,0,3.32,9.59Zm-2.49,0a.83.83,0,1,1,.83.83A.83.83,0,0,1,.83,9.59Z"/>
                                            <path class="cls-1" d="M8.67,16.33A1.66,1.66,0,1,0,7,14.67,1.66,1.66,0,0,0,8.67,16.33Zm0-2.49a.83.83,0,1,1-.83.83A.83.83,0,0,1,8.67,13.84Z"/>
                                            <path class="cls-1" d="M19.4,13.06a1.66,1.66,0,1,0,1.66,1.66A1.66,1.66,0,0,0,19.4,13.06Zm0,2.49a.83.83,0,0,1-.83-.83.83.83,0,1,1,1.66,0A.83.83,0,0,1,19.4,15.55Z"/>
                                            <path class="cls-1" d="M26.38,17.82A1.66,1.66,0,1,0,28,19.48,1.66,1.66,0,0,0,26.38,17.82Zm0,2.49a.84.84,0,1,1,0-1.67.84.84,0,0,1,0,1.67Z"/>
                                            <path class="cls-1" d="M19,23a1.66,1.66,0,1,0,1.65,1.66A1.66,1.66,0,0,0,19,23Zm0,2.5a.85.85,0,0,1-.84-.84.84.84,0,1,1,.84.84Z"/>
                                            <path class="cls-1" d="M8.36,23.08A1.66,1.66,0,1,0,10,24.74,1.66,1.66,0,0,0,8.36,23.08Zm0,2.49a.83.83,0,0,1-.83-.83.83.83,0,1,1,1.66,0A.83.83,0,0,1,8.36,25.57Z"/>
                                            <path class="cls-1" d="M19.68,6.43A1.66,1.66,0,1,0,18,4.77,1.66,1.66,0,0,0,19.68,6.43Zm0-2.49a.83.83,0,0,1,.83.83.83.83,0,1,1-1.66,0A.83.83,0,0,1,19.68,3.94Z"/>
                                            <path class="cls-1" d="M25.85,24l.37-.37a.52.52,0,0,0,0-.73l0-.05a.5.5,0,0,0-.73,0l-.37.37a3.73,3.73,0,0,1-1.47.9,3.61,3.61,0,0,1-1.49.17.53.53,0,0,0-.5.24l0,.06a.54.54,0,0,0,0,.51.51.51,0,0,0,.41.29,5,5,0,0,0,2-.22A4.81,4.81,0,0,0,25.85,24Z"/>
                                            <path class="cls-1" d="M5.63,4.08h.53a.51.51,0,0,1,.51.52v.07a.51.51,0,0,1-.51.52H5.63A3.58,3.58,0,0,0,4,5.59a3.58,3.58,0,0,0-1.18.93.52.52,0,0,1-.52.18H2.19a.52.52,0,0,1-.38-.34.51.51,0,0,1,.09-.5A4.76,4.76,0,0,1,3.46,4.6,4.82,4.82,0,0,1,5.63,4.08Z"/>
                                            <path class="cls-1" d="M1.93,13.75l-.37-.37a.5.5,0,0,1,0-.73l.05-.05a.52.52,0,0,1,.73,0l.37.37a3.66,3.66,0,0,0,1.47.9A3.59,3.59,0,0,0,5.67,14a.52.52,0,0,1,.49.24l0,.06a.54.54,0,0,1,0,.51.52.52,0,0,1-.41.29,4.82,4.82,0,0,1-2-.22A4.74,4.74,0,0,1,1.93,13.75Z"/>
                                            <path d="M16.6,4H12.15a.51.51,0,0,0-.51.52v.07a.52.52,0,0,0,.51.52H16.6a.52.52,0,0,0,.52-.52V4.5A.52.52,0,0,0,16.6,4Z"/>
                                            <path d="M16.21,14.11H11.76a.52.52,0,0,0-.52.52v.07a.52.52,0,0,0,.52.52h4.45a.52.52,0,0,0,.52-.52v-.07A.52.52,0,0,0,16.21,14.11Z"/>
                                            <path d="M15.9,24.1H11.45a.52.52,0,0,0-.52.52v.07a.52.52,0,0,0,.52.52H15.9a.52.52,0,0,0,.52-.52v-.07A.52.52,0,0,0,15.9,24.1Z"/>
                                        </g></g>
                                    </svg>
                                
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                headerName: "",
                field: 'seeIncidence',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeIncidence",
                cellRenderer: params => {
                if(params.value !== ""){
                    return  `   
                        <Button id="mySeeIncidenceButton" class="btn mr-1 btn-plan btn-sm"
                        >                   
                            <svg data-v-791dce48="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" class="c-icon">
                                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                            </svg>
                        </Button>
                    `
                }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                headerName: "",
                field: 'seeTimeLine',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeTimeLine",
                cellRenderer: params => {
                if(params.value !== ""){
                    return  `   
                        <Button id="mySeeTimeLineButton" class="btn mr-1 btn-light-watch btn-sm"
                        >                   
                            <?xml version="1.0" standalone="no"?>
                            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
                            "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  role="img" class="c-icon" 
                            width="980.000000pt" height="736.000000pt" viewBox="0 0 980.000000 736.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,736.000000) scale(0.100000,-0.100000)"
                            fill="#ffffff" stroke="none">
                            <path d="M1340 7345 c-101 -16 -194 -49 -307 -110 -523 -278 -724 -894 -463
                            -1420 84 -169 204 -307 365 -415 75 -51 228 -130 251 -130 6 0 15 -10 19 -22
                            4 -13 10 -301 12 -640 l5 -618 -108 -1 c-355 -2 -904 -19 -937 -29 -59 -17
                            -142 -105 -161 -170 -20 -68 -20 -152 0 -220 22 -73 102 -153 171 -170 42 -10
                            836 -19 2587 -29 l139 -1 -7 -602 c-3 -332 -9 -619 -12 -639 -5 -32 -11 -38
                            -48 -49 -161 -48 -409 -242 -517 -405 -127 -192 -183 -376 -183 -600 1 -285
                            96 -529 286 -730 464 -491 1228 -449 1637 90 66 88 153 265 182 370 27 102 37
                            313 19 423 -35 223 -135 424 -293 589 -113 118 -223 189 -418 270 -23 10 -27
                            18 -32 80 -4 37 -7 323 -7 636 l0 567 1380 0 1380 0 -1 -577 c-1 -318 -4 -604
                            -8 -637 l-6 -58 -97 -43 c-291 -128 -501 -360 -597 -660 -43 -133 -54 -224
                            -48 -377 6 -165 29 -254 101 -403 292 -598 1009 -793 1566 -426 67 44 195 164
                            248 234 89 115 167 289 198 437 21 101 23 304 5 400 -54 290 -216 535 -462
                            699 -105 71 -166 103 -226 121 -34 10 -42 18 -47 43 -3 17 -9 304 -13 639 l-6
                            608 139 1 c1751 10 2545 19 2587 29 69 17 149 97 171 170 20 68 20 152 0 220
                            -19 65 -102 153 -161 170 -34 10 -571 27 -927 29 l-118 1 4 623 c3 342 8 629
                            12 638 4 8 31 23 60 32 60 19 198 103 291 177 124 99 251 260 311 396 175 397
                            84 868 -230 1189 -313 319 -795 403 -1211 211 -309 -143 -539 -455 -595 -808
                            -18 -110 -8 -321 19 -424 52 -202 148 -363 299 -506 107 -100 280 -211 373
                            -239 21 -7 41 -19 45 -28 4 -9 9 -296 12 -638 l4 -623 -1381 0 -1381 0 1 608
                            c1 334 4 620 8 637 5 24 15 32 54 46 27 10 81 36 121 59 281 161 468 397 548
                            690 30 114 33 352 5 475 -77 333 -279 596 -572 740 -432 213 -926 120 -1260
                            -237 -294 -315 -365 -762 -186 -1172 53 -121 187 -292 293 -373 97 -75 225
                            -150 297 -173 l71 -23 0 -638 0 -639 -1380 0 -1380 0 0 639 0 639 46 11 c103
                            26 269 128 383 236 173 163 285 371 321 598 18 112 8 340 -20 442 -103 380
                            -400 674 -777 771 -124 32 -316 40 -443 19z m326 -620 c106 -31 237 -162 286
                            -285 52 -132 30 -291 -57 -408 -146 -199 -399 -260 -586 -141 -58 37 -157 140
                            -192 199 -93 161 -63 347 81 501 134 143 289 188 468 134z m3333 10 c91 -22
                            154 -60 221 -132 181 -198 184 -431 7 -619 -69 -74 -121 -108 -208 -135 -298
                            -93 -625 198 -572 507 16 91 56 165 133 243 129 130 261 173 419 136z m3418
                            -10 c141 -42 286 -219 311 -380 38 -241 -176 -498 -428 -513 -140 -8 -238 36
                            -353 157 -180 191 -178 411 4 606 131 139 288 183 466 130z m-5105 -5206 c147
                            -31 282 -162 337 -326 82 -243 -105 -529 -376 -574 -85 -15 -155 -2 -251 46
                            -219 111 -324 345 -246 547 38 97 154 226 248 275 81 42 185 54 288 32z m3393
                            -4 c156 -41 301 -206 335 -382 53 -273 -240 -565 -516 -514 -232 42 -406 258
                            -392 484 7 107 48 189 141 282 132 132 267 173 432 130z"/>
                            </g>
                            </svg>
                        </Button>
                    `
                }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                field: "Idx",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 70,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "OrderCode",
                headerName: this.$t('label.noOrder'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial gb-cell text-color-negrita',
            },
            {
                field: "DriverName",
                headerName: this.$t('label.driver'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "LicensePlate",
                headerName: this.$t('label.vehicle'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "ImplementAlias",
                headerName: this.$t('label.Gamela'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "NroBl",
                headerName: "BL",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "ClientName",
                headerName: this.$t('label.transport'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardName",
                headerName: this.$t('label.yard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardClientName",
                headerName: this.$t('label.clientYard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TareWeight",
                headerName: this.$t('label.Taraweight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "FullWeight",
                headerName: this.$t('label.FullWeight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                   
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "NetWeight",
                headerName: this.$t('label.netWeight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.emissionDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "FinishDate",
                headerName: this.$t('label.shippingDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            { 
                field: "TransactionFinishTurnName",
                headerName: this.$t('label.turn'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            {
                field: "VisitLapseCode",
                headerName: this.$t('label.lapse'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "TotalTime",
                headerName: this.$t('label.totalTime'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "CraneAlias",
                headerName: this.$t('label.crane'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "VesselHoldName",
                headerName: this.$t('label.hold'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.device'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.checker'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 220,
            },
            {
                field: "LastDate",
                headerName: this.$t('label.lastDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "CircuitName",
                headerName: this.$t('label.currentCircuit'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            {
                field: "OrderStatusDs",
                headerName: this.$t('label.status'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial text-color-negrita',
                minWidth: 160,
            },
            {
                    field: "AnulationDate",
                    headerName: this.$t('label.AnulationDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationDeviceCode",
                    headerName: this.$t('label.AnulationDeviceCode'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 220,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationLogin",
                    headerName: this.$t('label.AnulationLogin'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ObservationAnulation",
                    headerName: this.$t('label.ObservationAnulation'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 230,
                    cellClass: 'center-cell-especial',
                },
        ]
        return columnDefs;
    }

    function RowData(){
        if(this.rows.length != 0){
            return this.rows.map((item) => {
                return {
                    ...item,
                };
            });
        }else{
            return [];
        }
    }
    export default {
        name: "index-order-list",
        data,
        beforeMount,
        created,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            getRowClass,
            getRowStyle,
            onGridReady,
            getOrderList,
            onQuickFilterChanged,
            onPageSizeChanged,
            refreshComponent,
            filterCarrier,
            filterCrane,
            filterHold,
            filterStatus,
            getCarrierList,
            getCraneList,
            getStatusList,
            getHoldList,
            getDateRange,
            handleTab,
            isActiveTab,
            validateDateRange,
            getOrderReport,
            onBtnExport,
        },
        computed:{
            carrierListFormatted,
            craneListFormatted,
            holdListFormatted,
            orderShipmentStatusFormatted,
            columnDefs,
            RowData,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemOrder: state => state.visitas.dropItemOrder,  
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
                OrderId: state => state.visitas.OrderId,
            }),
        },
        components:{
            modalOrder,
            collapseIncidenceList,
            collapseCircuitList,
            collapseTimeLineList,
            tippy,
        },
        watch:{
            dropItemOrder: async function (newValue) {
                if (newValue==1) {
                    await this.refreshComponent();
                }
            },
            active: function(newVal) {
                if(newVal)
                    this.activeTab = 0;
            },
            refrescarTabla: async function () {
                if (this.refrescarTabla) {
                    this.$store.state.visitas.OrderId = 0;
                    this.showCollapseIndex=0;
                    this.orderData = '';
                    this.circuitData = '';
                    this.timeLineData = '';
                    this.searchFilterDate.fromDay="";
                    this.searchFilterDate.toDay="";
                    await this.getDateRange();
                    await this.getOrderList();
                    this.refrescarTabla = false;
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss">
    .tippy-box[data-theme~='bluesimple'] {
        fill: #6ca3d7;
        color:#606a87;
    }
    .text-color-negrita {
        font-weight: bold;
    }

/**
 ****************************
 * Tooltip
 ****************************
*/
    .ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom) {
        -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
                box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    }

    .ag-theme-alpine .ag-tooltip {
        background-color: #000015;
        background-color: var(--ag-header-background-color, #000015);
        color: #fff;
        color: var(--ag-foreground-color, #fff);
        padding: 6px;
        border: solid 1px;
        border-color: #000015;
        border-color: var(--ag-border-color, #000015);
        border-radius: 3px;
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
        white-space: normal;
        text-align: center;
        border-radius: 0.25rem;
    }

    .ag-theme-alpine .ag-tooltip.ag-tooltip-hiding {
        opacity: 0;
    }

    .ag-theme-alpine .ag-tooltip-custom {
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
    }

    .ag-theme-alpine .ag-tooltip-custom.ag-tooltip-hiding {
        opacity: 0;
    }

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>