<template>
  <CCollapse :show="showCollapase">
    <loading-overlay
      :active="loadingOverlay"
      :is-full-page="true"
      loader="bars"
    />
    <CCard body-wrapper>
      <CCol sm="8">        
        <CInput
          :label="$t('label.moduleType')"      
          placeholder="ANDROID"
          :horizontal="{label:'col-sm-9 col-lg-3',input:'col-sm-11 col-lg-9'}"
          addLabelClasses="required text-right"
          v-model="TpModuleName"
          v-uppercase
          :is-valid="TpModuleName.length < 60 && TpModuleName !== ''"
        />
        <p
          v-if="TpModuleRequired || TpModuleName.length > 60"
          class="text-danger mt-2"
        >
          {{$t('label.required')}}
        </p>
      </CCol>
      <CCardFooter>
        <div class="row justify-content-end">
          <CCol sm="1">
            <CButton
              square
              color="add"
              class="d-flex align-items-start"
              @click="verifyField"
            >
              <CIcon name="checkAlt" /><span class="ml-1">{{$t('button.accept')}}</span>
            </CButton>
          </CCol>
          <CCol sm="2" class="mx-4">
            <CButton
              square
              color="wipe"
              class="d-flex align-items-start"
              @click="closeCollapse"
            >
              <CIcon name="x" /><span class="ml-1"
                >{{$t('button.cancel')}}</span
              >
            </CButton>
          </CCol>
        </div>
      </CCardFooter>
    </CCard>
  </CCollapse>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    showCollapase: false,
    TpModuleName: '',
    TpModuleRequired: false,
    loadingOverlay: false,
  };
}

function verifyField() {
  this.loadingOverlay = true;
  if (this.TpModuleName === '') {
    this.TpModuleRequired = true;
    this.loadingOverlay = false;
  } else {
    this.saveNewTpModule();
  }
}
async function saveNewTpModule() {
  this.TpModuleRequired = false;

  const TpModuleJson = [
    {
      TpModuleName: this.TpModuleName,
    },
  ];

  await this.$http
    .post('TpModule-insert', TpModuleJson, { root: 'TpModuleJson' })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('refresh-data-table');
        this.loadingOverlay = false;
        this.closeCollapse(false);
        this.$emit('update-data-modulo');
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function closeCollapse() {
  this.showCollapase = false;
  this.TpModuleName = '';
  this.TpModuleRequired = false;
  this.$emit('close-collapse');
}
export default {
  name: 'addTpModuleCollapse',
  data,
  props: {
    collapse: Boolean,
  },
  directives: UpperCase,
  methods: {
    saveNewTpModule,
    closeCollapse,
    verifyField,
  },
  watch: {
    collapse: function(val) {
      this.showCollapase = val;
      if (val === false) {
        this.TpModuleName = '';
        this.TpModuleRequired = false;
      }
    },
  },
};
</script>
<style lang=""></style>
