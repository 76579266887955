<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.summaryOfContainersByTypeStatus') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"             
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CSelect
                    :value.sync="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="getContainerTypeStatusSummary"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>

            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';


    //Data
    function data() {
        return {
            gridOptions: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            un: 'TON',
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
            preference:1,
            SizeChanged:50,
            preferenceOptions: [],
            nulo:'', 
            search: '',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            /*let select = this.$el.querySelector("#unidadMedida");
            select.length = 0;
            select.options.add(new Option("Seleccione", ""));
            for (let i = 0; i < listado.length; i++) {
                select.options.add(new Option(listado[i].UnitMeasureAbbrev, listado[i].UnitMeasureId));
            }

            //select.addEventListener('change', filterUnitMeasureWeight);
            select.addEventListener('change', function() { this.unitMeasureId = select.value; this.getContainerTypeStatusSummary(); })*/
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }   
            this.unitMeasureId = listado[0].UnitMeasureId;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            this.formatedItems.map(function(item){
                computedItems.push({
                    Description: item.Description,
                    Full: item.Full,
                    Empty: item.Empty,
                    Total: item.Total,
                    Weigth: formatMilDecimal(parseFloat(item.Weigth).toFixed(2)),
                    AvgWeigth: formatMilDecimal(parseFloat(item.AvgWeigth).toFixed(2)),
                })
            })
            //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
            await this.getPdf(computedItems,this.$t('label.summaryOfContainersByTypeStatus'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(valor) {
        //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            this.formatedItems.map(function(item){
                computedItems.push({
                    Description: item.Description,
                    Full: item.Full,
                    Empty: item.Empty,
                    Total: item.Total,
                    Weigth: Number.parseFloat(item.Weigth),
                    AvgWeigth: Number.parseFloat(item.AvgWeigth),
                })
            })
            await this.getExcel(computedItems,this.$t('label.summaryOfContainersByTypeStatus'),valor,'N/A','N/A','N/A');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
        this.un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        this.getContainerTypeStatusSummary();
    }

    async function getContainerTypeStatusSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;
        ////let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        
        let TypeSumaryJson = {
            VisitId: this.Visit,
            UnitMeasureId: this.unitMeasureId,
            CompanyBranchId: this.branch.CompanyBranchId,
            UserPreference: this.preference
        };

        await this.$http.post("VisitContainerTypeSummary", TypeSumaryJson, { root: 'TypeSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            for (i = 0; i < listado.length; i++) {
                /*if (control) {
                    this.formatedItems.push({
                        Description: "",
                        Full: "",
                        Empty: "",
                        Total: "",
                        Weigth: "",
                        AvgWeigth: ""
                    });
                    control = false;
                }
                
                if (listado[i].Description == "TOTAL") control=true;*/
                this.formatedItems.push({
                    Description: listado[i].Description,
                    Full: listado[i].Full,
                    Empty: listado[i].Empty,
                    Total: listado[i].Total,
                    Weigth: listado[i].Weigth,
                    AvgWeigth: listado[i].AvgWeigth
                });
                this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }

    function getRowClass(params) {
        if (params.node.data.Description=="TOTAL") {
            return 'bg-edit font-weight-bold  text-white'
        }
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.unitMeasureId= '';
        this.preference= 1;
        this.SizeChanged = 50;
        this.gridApi.paginationSetPageSize(parseInt(this.SizeChanged));
        await this.getPreferenceList();
        await this.getUnitMeasureWeigthList();
        await this.getContainerTypeStatusSummary();
        this.$emit('child-refresh',true);
    }

//computed
    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }
function columnDefs(){
        let columnDefs = [
            {
                field: "Description",
                headerName: "",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 120,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
                //pinned: 'left',
            },
            {
                field: "Full",
                headerName: "FULL",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
            },
            {
                field: "Empty",
                headerName: "EMPTY",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
            },
            {
                field: "Total",
                headerName: "TOTAL",
                headerClass: 'center-cell-especial gb-cell',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial gb-cell',
                filter: 'agNumberColumnFilter',
                minWidth: 170,
            },
            {
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
                /*headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        `<label id='unidadm' class='mt-2'>TON</label>`+
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        '  </div>' +                            
                        '</div>'
                }*/
            },
            {
                field: "AvgWeigth",
                headerName: "AVGWEIGHT",
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
        ]

      
        return columnDefs;
    }
    export default {
        name: "container-type-status-summary",
        data,
        beforeMount,
        mounted,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getContainerTypeStatusSummary,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            getRowClass,
            getPreferenceList,
            refreshComponent
        },
        computed:{
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
            }),
            preferenceListFormatted,
            columnDefs,
        },
        components:{
            
        },
        watch:{
            SizeChanged,
            dropItemReport: async function (newValue) {
                if (newValue==31) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>