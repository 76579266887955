<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-2">
      <CCol sm="12">
        <CRow>
          <label class="required text-right col-sm-4">{{$t('label.name')}}</label>
          <CCol sm="7">
            <CInput
              addLabelClasses="required text-right"
              :placeholder="$t('label.holidayTypeName')"
              v-uppercase
              :invalid-feedback="errorMessage($v.TpHolidayName)"
              :is-valid="hasError($v.TpHolidayName)"
              maxlength="250"
              v-model.trim="$v.TpHolidayName.$model"
            />
          </CCol>
          <CCol sm="1" class="d-flex align-items-start">
            <CButton 
              color="wipe" 
              square 
              size="sm"
              v-c-tooltip="{
                placement: 'top-end',
                content: this.$t('label.clearFields')
              }" 
              @click="limpiarDatos" 
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <CRow>
          <label class="required text-right col-sm-4">{{$t('label.colorIdentification')}}</label>
          <CCol sm="7">
            <CInput
              class="w-75 float-left"
              type="color" 
              v-model="SelectColor"
              @change="Color=$event.toUpperCase()"
            />
            <CInput
              addLabelClasses="required"
              :is-valid="hasError($v.Color)"
              :invalid-feedback="errorMessage($v.Color)"
              v-model="$v.Color.$model"
              @change="SelectColor=$event.toUpperCase()"
              v-uppercase
              class="w-25 float-right"
              maxlength="7"
            />
          </CCol>
          <CCol sm="1" lg="1" class="d-flex align-items-start">
            <CButton color="edit" square size="sm" class="float-right" 
              v-c-tooltip="this.$t('label.edit')+' '+this.$t('label.type')" 
              :disabled="isDisabled" 
              @click="evaluaStatus" 
            >
              <CIcon name="pencil"/>
            </CButton>
          </CCol>
        </CRow>        
      </CCol>
      
      <CCol sm="12"  v-if="actTp">
        <CRow>
          <label class="text-right col-sm-4">{{$t('label.status')}}</label>
          <CCol sm="7">
            <CSelect
              v-model="Status"
              :value.sync="Status"
              :is-valid="statusSelectColor"
              :options="selectOptions"
            />
          </CCol>
          
        </CRow>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="itemsTipo"
          :fields="fieldsTipo"
          :loading="LoadingTipo"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="10"
          hover
          small
          sorter
          pagination
        >
          <template #Color="{item}">
              <td class="text-center">
                <label :style="getColor(item.Color)">
                  {{item.Color}}
                </label>
              </td>
          </template>
          <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
          </template>
          <template #Detalle="{item}">
            <td class="py-2">
              <CButton color="edit" class="mr-1" square size="sm" v-c-tooltip="'Modificar'" @click="editar(item)" >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>  
</template>

<script>
import TpFeriadoValidations from '@/_validations/feriado/TpHolidayValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

function fieldsTipo(){
  return[
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%;',
      filter: false
    },
    { key: 'TpHolidayName', label:this.$t('label.holidayType'), _style: 'width:40%;' },
    { key: 'Color', label:this.$t('label.color'), _style: 'width:12%;', _classes: 'text-center' },
    { key: 'Usuario', label:this.$t('label.user'), _style: 'width:12%;' },
    { key: 'Fecha', label:this.$t('label.date'), _style: 'width:12%;', _classes: 'text-center'},
    { key: 'Status', label:this.$t('label.status'), _style: 'width:12%;', _classes: 'text-center' },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'width:5%;'
    }
  ];
}
//DATA
function data () {
  return {
    //MODELO
    TpHolidayId: '',
    TpHolidayName: '',
    Color: '#000',
    Status: 1,
    //VARIABLES
    Loading: false,    
    LoadingTipo: false,
    SelectColor: '#000',
    itemsTipo: [],
    actTp: false,
    StatusTp: false,
    actualizar: false,
    
  }
}

//METHOD
function limpiarDatos() {
  this.Status = 1;
  this.actualizar = false;
  this.actTp = false;
  this.Color = "#000";
  this.SelectColor = "#000";
  this.TpHolidayName = "";
  this.TpHolidayId = "";
  this.$nextTick(() => { this.$v.$reset() })
}

function editar(item) {
  this.TpHolidayId = item.TpHolidayId;
  this.TpHolidayName = item.TpHolidayName;
  this.Color = item.Color;
  this.SelectColor = item.Color;
  this.Status = item.Estatus;
  this.actualizar = true;
  this.actTp = true;
}

function getColor(color) {
  return 'color: #FFF; padding: 5px; border-radius: 4px; background-color:'+color;
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')} ${this.TpHolidayName}?`,
    })).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}
  
function guardar () {
  if (this.$v.$invalid) return false;
  this.Loading = true;
  let TpHolidayJson = [];
  let metodo = '';
  let ruta = '';
  let res = [];

  if (this.actualizar) {
    TpHolidayJson = {
      TpHolidayId: this.TpHolidayId,
      TpHolidayName: this.TpHolidayName,
      Color: this.Color,
      Status: this.Status,
    };
    metodo = 'PUT';
    ruta = "TpHoliday-update";
  } else {
    TpHolidayJson = {
      TpHolidayName: this.TpHolidayName,
      Color: this.Color,
    };
    metodo = 'POST';
    ruta = "TpHoliday-insert";
  }

  this.$http.ejecutar(metodo, ruta, TpHolidayJson, { root: 'TpHolidayJson' })
  .then(response => {
    res = [...response.data.data];
    this.limpiarDatos();
    this.listarTipoFeriado();
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.$emit('addTipo', TpHolidayJson);
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.Loading= false;
  });
}

function listarTipoFeriado() {
  this.itemsTipo = [];
  this.LoadingTipo = true;
  
  let listado = Array;
  this.$http.get("TpHoliday-list", { filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data];
    this.itemsTipo = listado.map(listado => Object.assign({}, this.itemsTipo, {
      Nro: listado.Nro,
      TpHolidayId: listado.TpHolidayId,
      TpHolidayName: listado.TpHolidayName,
      Color: listado.Color,
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      Estatus: listado.Status === "INACTIVO" ? 0: 1,
      FgActTpHoliday: listado.FgActTpHoliday,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.LoadingTipo = false;
  });
}

//COMPUTED
function isDisabled() {
  return this.$v.$invalid
}

function selectOptions() {
  return [
    { 
      value: 1, 
      label: this.$t('label.ACTIVO')
    },
    { 
      value: 0, 
      label: this.$t('label.INACTIVO')
    }
  ];
}

//MOUNTED
function mounted() {
  this.limpiarDatos();
  this.listarTipoFeriado();
}

export default {
  name: 'tipo-feriado-modal',
  mixins: [General],
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: TpFeriadoValidations,
  watch: {
    
  },
  methods: {
    limpiarDatos,
    evaluaStatus,
    guardar,
    listarTipoFeriado,
    editar,
    getColor
  },
  mounted,
  computed: {
    isDisabled,
    fieldsTipo,
    selectOptions,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style scoped>

</style>