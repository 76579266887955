<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :closeOnBackdrop="false"
      :title= this.titleModal
      color="dark"
      :show.sync="ListModal"
    >
      <CRow class="m-2">
        <!--CCol sm="6">
          <CInput :label="$t('label.packaging') + ' ' + $t('label.name') " 
          v-model="PackagingName"
          :horizontal="{label:'col-sm-9 col-lg-5', input:'col-sm-11 col-lg-7'}"
           disabled />
        </CCol-->
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="itemsPackaging"
            :fields="fieldsPackaging"
            column-filter
            :items-per-page="5"
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            hover
            small
            sorter
            pagination
          >
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #loading>
        <loading/>
      </template>
      <template #footer>
        <CButton color="wipe" @click="ListModal = false">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import { DateFormater, tableTextHelpers } from "@/_helpers/funciones";
import General from "@/_mixins/general";

function fieldsPackaging(){

  return[
  { key: "Nro", label: '',  _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false },
  { key: "Carga", label: this.$t('label.cargoGroup'),_classes: 'text-uppercase text-center', _style:'width:47%'},
  { key: "Tipo", label: this.$t('label.type') + ' ' + this.$t('label.LOAD'),_classes: 'text-uppercase text-center', _style:'width:47%'},
];
}
//METHODOS
function buscarTpcarga(PackagingId) {
  this.itemsPackaging = [];
  this.docs = [];
  this.Loading = true;
  let listado = Array;

  this.$http
    .get("TpCargoPackaging-by-id", { PackagingId: PackagingId, filter: "ALL" })
    .then((response) => {
      listado = [...response.data.data];
      this.docs = listado.filter((ob) => ob.Status === "ACTIVO");
      this.itemsPackaging = listado.map((listado) =>
        Object.assign({}, this.itemsPackaging, {
          Nro: listado.Nro,
          Carga: listado.GpoCargoName,
          Tipo: listado.TpCargoName,
          Fecha: listado.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate)
            : "N/A",
          PackagingId: listado.PackagingId,
          PackagingNameEs: listado.PackagingNameEs,
          PackagingNameEn: listado.PackagingNameEn,
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

//DATA
function data() {
  return {
    Loading: false,
    ListModal: false,
    itemsPackaging: [],
    PackagingNameEs: "",
    PackagingNameEn: "",
    titleModal: ''

  };
}

export default {
  name: "modal-packaging-list",
  mixins: [General],
  data,
  props: {
    modal: null
    
  },
  methods: {
    buscarTpcarga,
  },
  computed:{
    fieldsPackaging
  },
  watch: {
    modal: function() {
      if (this.modal) {
        let _lang = this.$i18n.locale;
        let title = _lang=='en' ? this.modal.PackagingNameEn : this.modal.PackagingNameEs;
        this.ListModal = true;
        this.buscarTpcarga(this.modal.PackagingId);
        this.PackagingNameEs = this.modal.PackagingNameEs;
        this.PackagingNameEn = this.modal.PackagingNameEn;
        this.titleModal = this.$t('label.loadGroupAssociatedTo')+': '+title
        this.$emit("closeModal");
      }
    },
  },
};
</script>
<style scoped></style>
