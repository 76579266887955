import {
  required,
} from 'vuelidate/lib/validators';
import { onlyText } from '../funciones';

export default () => {
  return {
    tpUser: {
      TpUserNameEs: { required, onlyText },
      TpUserNameEn: { required, onlyText }
    }
  }
}