<template>
    <div>
       <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
       <CModalExtended
           color="dark"
           :closeOnBackdrop="false"
           :show.sync="$store.state.ubicacion.modalMunicipio"
           :title="tituloModal"
       >
           <CRow>                                                       
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.country')"
                       addLabelClasses="required text-right"
                       :value="paisSelected"
                       v-model.trim="$v.paisSelected.$model"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :is-valid="hasError($v.paisSelected)"
                       :invalid-feedback="errorMessage($v.paisSelected)"
                       :options="optionsListPaises"
                   >
                   </CSelect>
               </CCol>
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.state')"
                       addLabelClasses="required text-right"
                       v-model.trim="$v.formMunicipio.IdEstado.$model"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :is-valid="hasError($v.formMunicipio.IdEstado)"
                       :invalid-feedback="errorMessage($v.formMunicipio.IdEstado)"
                       :options="optionsListEstado"
                   >
                   </CSelect>
               </CCol>       
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CInput
                       :label="$t('label.name')"
                       addLabelClasses="required text-right"
                       v-uppercase
                       :placeholder="$t('label.municipalityName')"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       v-model="$v.formMunicipio.NbMunicipio.$model"
                       :invalid-feedback="errorMessage($v.formMunicipio.NbMunicipio)"
                       :is-valid="hasError($v.formMunicipio.NbMunicipio)"
                   >
                   </CInput>
               </CCol>
           </CRow>                        
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.status')"
                       :is-valid="formMunicipio.FgActMunicipio"
                       v-if="!idIndentification"
                       addLabelClasses="text-right"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       v-model="formMunicipio.FgActMunicipio"
                       :value.sync="formMunicipio.FgActMunicipio"
                       :options="selectOptions"
                   />
               </CCol>
           </CRow>
           <CElementCover 
                :opacity="0.8"
               v-if="loading"
           >
               <label class="d-inline">{{$t('label.load')}}... </label>
               <CSpinner size="sm" />
           </CElementCover>
           <div slot="footer">
               <CButton
                   color="add"
                   class="m-2"
                   @click="registerData"
                   outline 
                   :disabled="isSubmit"
               >
                   <div>
                       <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                   </div>
               </CButton>
               <CButton
                   color="wipe"
                   @click="cerrarModal"
                   class="m-2"
                   :disabled="apiStateFormLoading || loading "
               >
               <CIcon name="x"/>&nbsp;
                   {{$t('button.cancel')}}
               </CButton>
               
           </div>
       </CModalExtended>
   </div>
</template>
<script>
   import ENUM from '@/_store/enum';
   import { FormMunicipio } from '@/_validations/ubicacion/UbicacionValidation';
   import { mapState,mapMutations } from 'vuex';
   import UpperCase  from '@/_validations/uppercase-directive';
   import General from "@/_mixins/general";
   import ModalMixin from "@/_mixins/modal";
   //data
   function data() {
       return {
           srcError:'/img/errorimage.jpg',
           formMunicipio:{
               IdMunicipio:0,
               NbMunicipio:'',
               IdEstado:'',
               FgActMunicipio:true,
           },            
           originalAct:'',
           originalEstado:'',
           estadoById:'',
           paisSelected:'',
           MydataEstado:[],
           loading:false,
           tituloModal:'',
           isSubmit:false,
           apiStateLoading:false,
       }
   }

   //methods
   function cambio(e){
       this.formMunicipio.FgActMunicipio = !this.formMunicipio.FgActMunicipio;
   }
   function validURL(str) {
       var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
       '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
       '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
       '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
       return !!pattern.test(str);
   }
   function cerrarModal(){
       if(this.apiStateFormLoading || this.loading)
           return
       
       this.$store.commit('ubicacion/mutationModalMunicipio', false);
   }
   function registerData(){
       try {
           this.isSubmit=true;
           this.apiStateLoading=true;
           this.$v.$touch();
           if (this.$v.$error){
               throw this.$t('label.errorsPleaseCheck');
           }
           let { IdMunicipio,NbMunicipio,IdEstado,FgActMunicipio } = this.formMunicipio;
           const IdPais = this.paisSelected;
           const { dispatch } = this.$store;
           if(this.originalAct !== '' && IdMunicipio !== 0){
               if(this.originalAct !== FgActMunicipio){                
                       this.$swal
                       .fire(this.alertProperties({
                           text: `${this.$t('label.changeStatusQuestion')+' '} ${this.NbMunicipio}?`,
                       }))
                       .then((result) => {
                       if (result.isConfirmed) {
                           dispatch('ubicacion/postregisterMunicipio', { IdPais,IdMunicipio,NbMunicipio,IdEstado,FgActMunicipio });
                       }else {
                           // this.$swal('Operación cancelada');
                       }
                   })
               }else{
                   dispatch('ubicacion/postregisterMunicipio', { IdPais,IdMunicipio,NbMunicipio,IdEstado,FgActMunicipio });
                   this.isSubmit=false;
                   this.apiStateLoading=false;
               }
           }else{
               dispatch('ubicacion/postregisterMunicipio', { IdPais,IdMunicipio,NbMunicipio,IdEstado,FgActMunicipio });
               this.isSubmit=false;
               this.apiStateLoading=false;
           }
       } catch (error) {
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.notifyError({text: error});
       }
   }
   function setAltImg(event){
       event.target.src = this.srcError;
   }

   //computed
   function idIndentification(){
       return this.formMunicipio.IdMunicipio === 0;
   }
   function IdPaisSelected(){
       return this.paisSelected !== "";
   }
   function apiStateFormLoading() {
       let carga = false;
       if(this.apiStateForm === ENUM.LOADING){
           carga = !carga;
       }
       return carga;
   }
   
   function SvgIcon(){
       if(this.paisSelected !== ''){
           if(this.myDataPais.length !== 0){
               const x  = this.myDataPais.map(function(e){
                   return e.CountryId;
               }).indexOf(this.paisSelected);
               if(x !== -1){
                   if(this.myDataPais[x].Icon === null || this.myDataPais[x].Icon === '' || this.myDataPais[x].Icon === undefined){
                       return this.srcError;
                   }   
                   return this.validURL(this.myDataPais[x].Icon) ? this.myDataPais[x].Icon : `${this.$store.getters["connection/getBase"]}${this.myDataPais[x].Icon.replace('public/','', null, 'i')}`;
               }else{
                   return this.srcError;
               }
           }
       }
       return this.srcError;
   }

   function optionsListPaises(){
       if(this.myDataPais.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.myDataPais.map(function(e){
               if(e.FgActCountry){
                   chart.push({
                       value: e.CountryId, 
                       label: e.CountryName,
                   })
               }
           })
           return chart;
       }
   }

   function optionsListEstado(){
       if(this.MydataEstado.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.MydataEstado.map(function(e){
               if(e.FgActState){
                   chart.push({
                       value: e.StateId, 
                       label: e.StateName,
                   })
               }
           })
           return chart;
       }
   }

   function selectOptions(){

       return [
               { 
                   value: true, 
                   label: this.$t('label.ACTIVO')
               },
               { 
                   value: false, 
                   label: this.$t('label.INACTIVO')
               }
           ]
   }

   //watch
   function modalMunicipio(newQuestion,Oldquestion){
       if(newQuestion === false){
           this.tituloModal = '';
           this.paisSelected = '';
           this.formMunicipio.IdMunicipio=0;
           this.formMunicipio.IdEstado='';
           this.formMunicipio.NbMunicipio='';
           this.formMunicipio.FgActMunicipio=true;
           this.originalAct = '';
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.$nextTick(() => { this.$v.$reset() })
           this.$store.commit('ubicacion/asignarid', 0);
       }else{
           if(this.idState === 0){
               this.paisSelected = this.filtroPais;
               this.originalEstado=this.filtroEstado;
               this.tituloModal = this.$t('label.nuevo')+this.$t('label.municipality');
           }
       }
   }
   function idState(newQuestion,Oldquestion){
       if(this.modalMunicipio){
           if(newQuestion !== 0){
               //this.$store.state.ubicacion.apiStateForm = ENUM.LOADING;
               this.apiStateLoading=true;
               this.$http.ejecutar('GET', 'Municipality-by-id',{ MunicipalityId: newQuestion }, '' ).then(response => {
                   if(response.status === 200){
                       const municipioInformation = response.data.data;
                       if(municipioInformation.length !== 0){
                           this.tituloModal = this.$t('label.edit')+this.$t('label.municipality')+": "+municipioInformation[0].MunicipalityName;
                           this.paisSelected = municipioInformation[0].CountryId;
                           this.formMunicipio.IdMunicipio = municipioInformation[0].MunicipalityId;
                           this.formMunicipio.NbMunicipio = municipioInformation[0].MunicipalityName;
                           this.originalAct = municipioInformation[0].FgActMunicipality;
                           this.formMunicipio.FgActMunicipio = municipioInformation[0].FgActMunicipality;
                           this.estadoById = municipioInformation[0].StateId;
                           //this.$store.state.ubicacion.apiStateForm = ENUM.INIT;
                           this.apiStateLoading=false;
                           this.$v.$touch();
                       }else{
                           this.apiStateLoading=false;
                           //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                           this.$store.commit('ubicacion/mutationModalMunicipio', false);
                       }
                   }else{
                        this.apiStateLoading=false;
                        //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                       this.$store.commit('ubicacion/mutationModalMunicipio', false);
                   }
               }).catch(err => {
                   //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                   this.apiStateLoading=false;
                   this.$store.commit('ubicacion/mutationModalMunicipio', false);
               });
           }
       }
   }
   function paisSelected(newQuestion,Oldquestion){
       if(newQuestion !== "" && typeof newQuestion == 'string' ){
           this.formMunicipio.IdEstado = this.originalEstado;
           this.originalEstado = '';
           this.MydataEstado = [];
           this.apiStateLoading=true;
           
           this.$http.ejecutar('GET', 'State-list',  { Filter: 'ALL', CountryId:newQuestion } , '')
           .then(
               Estados => {
                   try{
                       if(Estados.status === 200){
                           this.apiStateLoading=false;
                           this.MydataEstado = Estados.data.data;
                           if(this.idState !== 0){
                               this.formMunicipio.IdEstado = this.estadoById;
                           }
                           this.estadoById = this.estadoById !== '' ? '' : this.estadoById
                       }else{
                        this.apiStateLoading=false;
                       }
                   }catch(error){
                       this.apiStateLoading=false;
                       this.$store.commit('ubicacion/getMunicipiolistFailure', error)
                   }
               },
               error => {
                   this.apiStateLoading=false;
                   this.$store.commit('ubicacion/getMunicipiolistFailure', error)
               }
           );
       }else{
           this.formMunicipio.IdEstado = '';
           this.MydataEstado = [];
       }
   }

   export default {
       name: 'app-modal-municipio',
       data,
       mixins: [General, ModalMixin],
       validations(){ return FormMunicipio() },
       methods:{
           cambio,
           validURL,
           setAltImg,
           registerData,
           cerrarModal
       },
       directives: UpperCase,
       computed:{
           selectOptions,
           idIndentification,
           optionsListPaises,
           IdPaisSelected,
           SvgIcon,
           optionsListEstado,
           apiStateFormLoading,
           ...mapState({
               idState: state=> state.ubicacion.id,
               modalMunicipio: state=> state.ubicacion.modalMunicipio,
               apiState: state => state.ubicacion.apiState,
               apiStateForm: state => state.ubicacion.apiStateForm,
               myDataPais: state => state.ubicacion.myDataPais,
               filtroPais: state => state.ubicacion.filtroPais,
               filtroEstado: state => state.ubicacion.filtroEstado,
           })
       },
       watch:{
           idState,
           paisSelected,
           modalMunicipio
       }
   }
</script>