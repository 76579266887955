<template>
  <CModalExtended
    :title="currentTitle"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-content-mov"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12"> 
                  <CInput
                    v-model.trim="$v.newStatus.ConfigName.$model"
                    :invalid-feedback="errorMessage($v.newStatus.ConfigName)"
                    :is-valid="hasError($v.newStatus.ConfigName)"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                    :label="$t('label.name')"
                    :placeholder="$t('label.name')"
                    :addLabelClasses="`text-right ${reverse ? '' : 'required'}`"
                    :disabled="reverse"
                  >
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CTextarea
                    v-model.trim="$v.newStatus.ObservationReverse.$model"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                    :invalid-feedback="errorMessage($v.newStatus.ObservationReverse)"
                    :is-valid="hasError($v.newStatus.ObservationReverse)"
                    :label="$t('label.MOVIMIENTO.observation')"
                    :placeholder="$t('label.MOVIMIENTO.observation')"
                    addLabelClasses="text-right"
                    rows="4"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>         
    </form>
    <template #footer>
      <CButton
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="submit"
      >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import ChangeStatusValidation, { NameNotRequiredValidations } from '@/_validations/yard-setting/ChangeStatusValidation';
  import UpperCase  from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import { mapState } from 'vuex';
  
  //Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false,
      newStatus: {
        ConfigName: '',
        ObservationReverse: ''
      },
      loading: false
    }
  }
  
  //Methods
  function submit() {
    try {
    this.isSubmit = true;
    this.loading = true;
    this.$v.newStatus.$touch();
      if (this.$v.newStatus.$error) {
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
  
      let YardConfigJson = {
        YardConfigId: this.yardConfigSelected.YardConfigId,//Id de la configuracion del patio 
        YardConfigName: this.newStatus.ConfigName,//Nombre asignado a la configuracion
        YardConfigStatusId: this.reverse ? '35936FB6-F7ED-ED11-A88B-000D3A0DEDC7':'19902EC7-5EDD-ED11-A88A-000D3A0DEDC7',//Id del estatus asignada
        Status: this.yardConfigSelected.Status == 'ACTIVO'? 1 : 0//Estatus de dicha configuracion {1,0}
      };

      if(this.reverse){
        YardConfigJson.ObservationReverse = this.newStatus.ObservationReverse;
      }else {
        YardConfigJson.ObservationApproval = this.newStatus.ObservationReverse;
      }

      this.$http.put("YardConfigStatus-update", YardConfigJson, { root: 'YardConfigJson' })
      .then(async response => {
        if (response && response.status === 200) {
          const messageSuccess = response.data.data[0].Response;
          this.$store.state.yardsetting.yardConfigSelected = {
            ...this.$store.state.yardsetting.yardConfigSelected,
            ...response.data.data[0],
          }
          this.resetForm();
          this.modalActive = false;
          this.notifySuccess({text: messageSuccess})
          await setTimeout(() => {
						this.$emit('submited');
					}, 250);
        }
      }).catch(err => {
        this.notifyError({text: err});
      }).then(() => {
        this.isSubmit = false;
        this.loading = false;
      });
    } catch (e) {
      this.notifyError({ text: e });
      this.loading = false;
    }
  }
  
  function toggle(newVal) {
    if(!newVal)
      this.resetForm();
    else {
      this.newStatus.ObservationReverse = this.yardConfigSelected.ObservationApproval;
      this.newStatus.ConfigName = this.yardConfigSelected.YardConfigName;
      
    }
  
    this.modalActive = newVal;
  }
  function resetForm() {
    this.newStatus.ObservationReverse = '';
    this.$v.$reset();
  }
  
  //Computeds:
  function currentConfigName() {
    if(!this.yardConfigSelected) return '';

    return this.yardConfigSelected.YardConfigName;
  }
  function currentTitle() {
    return this.reverse ? this.$t('label.reverseSetting'):this.$t('label.approveSetting');
  }
  
  export default {
    name: 'change-status-modal',
    mixins: [
      ModalMixin
    ],
    props: {
      modal: Boolean,
      reverse: {
        type: Boolean,
        default: false,
      },
    },
    data,
    validations() {
      if(this.reverse)
        return NameNotRequiredValidations();
      else
        return ChangeStatusValidation();
    },
    directives: UpperCase,
    methods: {
      submit,
      toggle,
      resetForm
    },
    computed: {
      currentConfigName,
      currentTitle,
      ...mapState({
        yardConfigSelected: state => state.yardsetting.yardConfigSelected,
      })
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      }
    }
  }
  </script>
  <style lang="scss">
  .card-simple{
    border: 0px;
    margin-bottom: 0 !important;
  }
  .modal-content-mov {
    .modal-content {
      width: 70% !important;
      margin: auto !important;
      .modal-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }
    }
  }
  </style>