<template>
    <div>
        <CModalExtended
            size="sm"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('button.preview')"
            :show.sync="$store.state.planificacionestiba.modalPreliminarList"
        >
            <div class="mx-2" style="background:#FFF;">
                <CRow>
                    <CCol sm="2">
                    </CCol>
                    <CCol sm="8" >
                        <CInput
                            :label="$t('label.TITLE_PRELIMINAR_FILE')"
                            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                            addLabelClasses="required text-right"
                            v-uppercase
                            v-if="editDocument"
                            :placeholder="$t('label.name')"
                            v-model="$v.formPreliminar.Name.$model"
                            @blur="$v.formPreliminar.Name.$touch()"
                            required 
                            :is-valid="hasError($v.formPreliminar.Name)"
                            :invalid-feedback="errorMessage($v.formPreliminar.Name)"
                        >
                        </CInput>
                        <div v-else style="width:100%;text-align:center;">
                            <h4>{{editTextDocument}}</h4>
                        </div>
                    </CCol>
                    <CCol sm="2">
                    </CCol>
                    <CCol sm="12">
                        <dataTableExtended
     class="align-center-row-datatable"
                            hover
                            sorter
                            small
                            :loading="apiStateLoading"
                            column-filter
                            :table-filter="tableTextHelpers.tableFilterText"
                            :items-per-page-select="tableTextHelpers.itemsPerPageText"
                            :items="preliminarList"
                            :fields="fields"
                            :noItemsView="tableTextHelpers.noItemsViewText"
                            :items-per-page="5"
                            pagination
                        >
                            <template #id="{ index}">
                                <td class="center-cell text-center">
                                {{index+1}}
                                </td>
                            </template>

                            <!--template slot="under-table">
                                <div v-if="preliminarList.length !== 0">
                                    <h6>{{$t('label.totalQuantity')+': '+preliminarList.length}}</h6>
                                </div>
                            </template-->
                        </dataTableExtended>
                    </CCol>
                </CRow>
            </div>

            <div slot="footer">
                <CButton
                    color="add"
                    @click="onBtnExport()"
                    class="m-2"
                    :disabled="apiStateLoading"
                >   
                    
                    <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import DataStorage from '@/_helpers/localStorage';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { FormPreliminar } from '@/_validations/planificacion-estiba/containerdataValidations';
    import ModalMixin from '@/_mixins/modal';
    //data
    function data() {
        return {
            formPreliminar:{
                Name:'',
            }
        }
    }

    //methods
    function cerrarModal(){
        this.$store.state.planificacionestiba.modalPreliminarList = false;
    }
    function onBtnExport(){
        if(this.editDocument){
            this.$v.formPreliminar.$touch();
            if (this.$v.formPreliminar.$pending || this.$v.formPreliminar.$error) return;

            let { Name } = this.formPreliminar;
            const { dispatch } = this.$store;
            let PrelimCargoJson = [];

            this.preliminarList.map(async (item) => {
                PrelimCargoJson.push({
                    StowagePlanningCargoId: item.StowagePlanningCargoId,
                    FgAuxFile: item.FgAuxFile ? 1 : 0
                })
            })

            dispatch('planificacionestiba/sendCargoPrelim', { Name,PrelimCargoJson,editDocument:true,dataDocument:{},planificacionId:this.planificacionId });
        }else{
            const { dispatch } = this.$store;
            let PrelimCargoJson = [];

            this.preliminarList.map(async (item) => {
                PrelimCargoJson.push({
                    StowagePlanningCargoId: item.StowagePlanningCargoId,
                    FgAuxFile: item.FgAuxFile ? 1 : 0
                })
            })

            dispatch('planificacionestiba/sendCargoPrelim', { Name:'',PrelimCargoJson,editDocument:false,dataDocument:this.dataDocument,planificacionId:this.planificacionId });
        }
    }
    //computed
    function editTextDocument(){
        if(!this.editDocument){
            return this.dataDocument.EdiFileName
        }
        return '';
    }
    function fields(){
        return [
            { key: 'id', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal1', label: this.$t('label.Seal')+' 1',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal2', label: this.$t('label.Seal')+' 2',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal3', label: this.$t('label.Seal')+' 3',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal4', label: this.$t('label.Seal')+' 4',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Origin', label: this.$t('label.Origin'),_classes:"center-cell", _style:'min-width:120px;'},
        ]
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    export default {
        name:'preview-modal',
        props: {
            preliminarList:{
                type: Array,
                required: true,
                default: () => [],
            },
            editDocument:{
                type: Boolean,
                required: false,
                default: () => true,
            },
            dataDocument:{
                type: Object,
                required: false,
                default: () => {},
            }
        },
        data,
        mixins: [ModalMixin],
        validations(){ return FormPreliminar() },
        directives: UpperCase,
        methods:{
            cerrarModal,
            onBtnExport
        },
        computed:{
            fields,
            editTextDocument,
            tableTextHelpers,
            apiStateLoading,
            ...mapState({
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                modalPreliminarList: state => state.planificacionestiba.modalPreliminarList,
            })
        },
        watch:{
            modalPreliminarList(newValue){
                if(!newValue){
                    this.formPreliminar.Name='';
                    this.$v.$reset();
                }
            }
        }
    }   
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
</style>