<template>
    <main>
        <CModalExtended
            :title="title"
            color="dark"
            :show.sync="modalActive"
            :closeOnBackdrop="false"
            size="lg"
            class="modal-extended"
        >
            <loading-overlay
                :active="loadingOverlay"
                :is-full-page="true"
                loader="bars"
            />
            <CRow class="mt-2">
                <CCol sm="12" class="mb-3">
                    <CSelect
                        :label="$t('label.transport')"
                        :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        add-label-classes="required text-right"
                        :options="companyOptions"
                        v-model.trim="ClientTpId"
                        :value.sync="ClientTpId"
                        :invalid-feedback="errorMessage($v.ClientTpId)"
                        @change="TransportFiltre"
                        @blur="$v.ClientTpId.$touch()"
                        :is-valid="hasError($v.ClientTpId)"
                        size="sm"
                    >
                        <template #append>
                            <CButton
                                style="height: calc(1.5em + 0.5rem + 2px);"
                                class="rounded"
                                color="add"
                                size="sm"
                                @click="clieModal=!clieModal"
                                v-c-tooltip="{
                                    content:$t('label.NewTransportationCompany'),
                                    placement: 'top-end'
                                }"
                                >
                                    <CIcon name="cil-playlist-add"/>
                            </CButton>
                        </template>
                    </CSelect>
                </CCol>
                <CCol sm="12" class="mb-3 px-1" v-if="clieModal" style="margin-top:10px;">
                    <CCollapse :show="clieModal">
                        <ClienteCollapse
                            :modal="clieModal" 
                            @cerrarModal="clieModal=false" 
                            @child-refresh="refrescarCliente=true"
                        />
                    </CCollapse>
                </CCol>
                <!--CCol sm="12" class="mb-3">
                    <CSelect
                    :label="$t('label.ScaleWeightRequired')"
                    :horizontal="{label: 'col-sm-6', input:'col-sm-6'}"
                    add-label-classes="required text-right"
                    v-uppercase
                    :options="ScaleWeightOptions"
                    :value="ScaleWeight"
                    :addInputClasses="{ 'is-invalid': $v.ScaleWeight.$error }"
                    :invalid-feedback="errorMessage($v.ScaleWeight)"
                    :is-valid="hasError($v.ScaleWeight)"
                    v-model.trim="$v.ScaleWeight.$model"
                    />
                </CCol-->
                <CCol sm="12" class="mb-3">
                    <CInput  
                        add-label-classes="required text-right"
                        :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        v-uppercase
                        maxlength="8"
                        :label="$t('label.alias')"
                        :placeholder="$t('label.alias')"
                        :is-valid="hasError($v.ImplementAlias)"
                        v-model.trim="$v.ImplementAlias.$model"
                        :invalid-feedback="errorMessage($v.ImplementAlias)"
                        size="sm"
                    />
                </CCol>
                <!--CCol sm="12" class="mb-3" v-if="editModal">
                    <CSelect
                    :label="$t('label.StatusOperation')"
                    :horizontal="{label: 'col-sm-6', input:'col-sm-6'}"
                    add-label-classes="required text-right"
                    v-uppercase
                    :options="FgStopOperationOptions"
                    :value="FgStopOperation"
                    v-model.trim="FgStopOperation"
                    :is-valid="hasError($v.FgStopOperation)"
                    />
                </CCol-->
                <CCol class="d-flex justify-content-end" sm="12" lg="4" >
                    <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="ScaleWeight"
                    />
                </CCol>
                <label class="col-form-label-sm col-sm-12 col-lg-7 text-left mb-2 pt-1">{{$t('label.ScaleWeightRequired')}}</label>
                <CCol v-if="editModal" class="d-flex justify-content-end" sm="12" lg="4" >
                    <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="FgStopOperation"
                    />
                </CCol>
                <label v-if="editModal" class="col-form-label-sm col-sm-12 col-lg-7 text-left mb-2 pt-1">{{ FgStopOperation ? $t('label.StopOperations') : $t('label.StopOperations2')}}</label>
                <CCol sm="12" class="mb-3" v-if="editModal">
                    <CSelect
                        :label="$t('label.status')"
                        :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        add-label-classes="text-right"
                        v-uppercase
                        :options="optionsStatus"
                        :invalid-feedback="errorMessage($v.Status)"
                        :is-valid="statusSelectColor()"
                        :value.sync="Status"
                        v-model.trim="$v.Status.$model"
                        size="sm"
                    />
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    outline
                    color="add"
                    :disabled="isSubmit"
                    @click="editModal?statusConfirmation(originStatus, Status, submit,ImplementAlias):submit()"  
                >
                    <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    class="d-flex align-items-center"
                    :disabled="isSubmit"
                    @click.stop="toggle(false)"
                >
                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                </CButton>
            </template>
        </CModalExtended>
    </main>
</template>

<script>
    import UpperCase from '@/_validations/uppercase-directive';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
    import { DateFormater } from '@/_helpers/funciones';
    import { StowagePlanningImplement } from '@/_validations/planificacion-estiba/logistica-empresa';
    import { mapState } from 'vuex';
    import moment from 'moment';
    import ClienteCollapse from './transporte-modals.vue';
    //data
    function data(){
        return {
            clieModal:false,
            refrescarCliente:false,
            ClientTpId:null,
            ImplementAlias:'',
            UserId:null,
            originStatus: '',
            Status: 1,
            modalActive: false,
            loadingOverlay: false,
            FgStopOperation: false,
            optionsStatus: [
                { label: this.$t('label.ACTIVO'), value: 1 },
                { label: this.$t('label.INACTIVO'), value: 0 },
            ],
            isSubmit: false,  
            companyOptions:[],
            ScaleWeight : true,
            StowagePlanningResourceId:''
        }
    }
    //methods
    function TransportFiltre() {
        let transportData = this.companyOptions.find( item => item.value == this.ClientTpId );
        if (transportData !== '' && transportData !== null && transportData !== undefined && transportData.ClientAlias) {
            this.ImplementAlias = transportData.ClientAlias;
            this.$v.ImplementAlias.$touch();
        };
    }

    function resetForm(event){
        this.StowagePlanningResourceId = '';
        this.ScaleWeight = true;
        this.ClientTpId = '';
        this.ImplementAlias = '';
        this.FgStopOperation = false;
        this.$v.$reset();
        this.$emit('updated-modal', event);
    }
    async function listarCompany () {
        this.loadingOverlay = true;
        this.companyOptions = [];

        let listado = Array;
        await this.$http.get("Client-list-by-activity", { TpClientId:'2f8322be-ca77-ec11-a876-000d3a0dedc7', Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
                value: listado.ClientTpId,
                label: listado.ClientName,
                ClientAlias: listado.ClientAlias,
            }));

            this.companyOptions.unshift({
                value: "",
                label: this.$t('label.select')
            });
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
    async function submit(){
        try {
            this.$v.$touch();
            this.isSubmit = true;
            if (this.$v.$error) {
                //this.loadingOverlay = false;
                this.isSubmit = false;
                throw this.$t('label.errorsPleaseCheck');
            }
            let StowagePlanningCarrierJson = [];
            let ruta = '';
            let metodo = '';

            if (this.ClientTpId=='') {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: 'Debe seleccionar una compañia',
                    type: "error"
                });
                return false;
            }

            this.loadingOverlay = true;

            if (this.editModal) {
                StowagePlanningCarrierJson = {
                    StowagePlanningResourceId: this.StowagePlanningResourceId,
                    StowagePlanningId: this.StowagePlanningId,
                    ClientTpId: this.ClientTpId,
                    Alias: this.ImplementAlias,
                    FgActRequiredTruckWeighing: this.ScaleWeight ? 1 : 0,
                    FgStopOperation: this.FgStopOperation? 1 : 0,
                    UserId: this.user.UserId,
                    Status: this.Status
                };
                metodo = 'PUT';
                ruta = "StowagePlanningCarrier-update";
            }else{
                StowagePlanningCarrierJson = {
                    StowagePlanningId: this.StowagePlanningId,
                    ClientTpId: this.ClientTpId,
                    Alias: this.ImplementAlias,
                    FgActRequiredTruckWeighing: this.ScaleWeight == true ? 1 : 0,
                    UserId: this.user.UserId,
                    Status: 1
                };
                metodo = 'POST';
                ruta = "StowagePlanningCarrier-insert";
            }

            this.$http.ejecutar(metodo, ruta, StowagePlanningCarrierJson, { root: 'StowagePlanningCarrierJson' })
            .then(response => {
                const messageSuccess = response.data.data[0].Response;
                this.$emit('refresh-data-table');
                //this.loadingOverlay = false;
                this.isSubmit = false;
                this.toggle(false);
                this.notifySuccess({ text: messageSuccess });
            }).catch((err) => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
                this.isSubmit = false;
                this.loadingOverlay= false;
            });
        }catch (e) {
            this.isSubmit = false;
            this.notifyError({ text: e });
        }
    }
    //computed
    function ScaleWeightOptions() {
        return [
            { value: 'SI', label: this.$t('label.yes')},
            { value: false, label: false},
        ];
    }
    /*function FgStopOperationOptions() {
        return [
            { value: false, label: this.$t('label.StopOperations')},
            { value: 'SI', label: this.$t('label.StoppedOperation')},
        ];
    }*/
    export default {
        name:'modal-transporte',
        data,
        props: {
            title: String,
            modal: null,
            editModal: Boolean,
            transporteSelected: Object,
        },
        mixins: [GeneralMixin, ModalMixin, ],
        directives: UpperCase,
        mounted(){
            this.toggle(false);
        },
        methods:{
            TransportFiltre,
            submit,
            listarCompany,
            resetForm,
        },
        computed: {
            ScaleWeightOptions,
            //FgStopOperationOptions,
            ...mapState({
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                TpVesselId: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        components:{
            ClienteCollapse
        },
        validations: StowagePlanningImplement,
        watch: {
            refrescarCliente(val){
                if(val){
                    this.clieModal = false;
                    this.listarCompany();
                    this.refrescarCliente = false;
                }
            },
            modal: function(val) {
                this.modalActive = val;
                if(val){
                    this.listarCompany();
                }
                if(this.editModal){
                    this.$v.$touch();
                }
            },
            transporteSelected: function(val) {
                if (Object.keys(val).length !== 0) {
                    this.StowagePlanningResourceId = val.StowagePlanningResourceId;
                    this.ClientTpId = val.ClientTpId;
                    this.ImplementAlias = val.Alias;
                    this.FgStopOperation = val.FgStopOperation ? true : false;
                    this.ScaleWeight = val.FgActRequiredTruckWeighing ? true : false;
                    this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
                    this.Status = val.Status === 'ACTIVO' ? 1 : 0;
                }
            }
        }
    }
</script>

<style lang="scss">
    .modal-content-driver {
      .modal-content {
        width: 90% !important;
        margin: auto !important;
        .modal-body {
          padding-left: 1.7rem;
          padding-right: 1.7rem;
        }
      }
    }
    
    .mx-datepicker{
        width: 100%;
        
      }
    .vue-datepicker-drive {
      
      .mx-input {
        &:hover {
          border-color: #958bef;
        }
      }
      .mx-input {
        &:focus {
          color: #768192;
          background-color: #fff;
          outline: 0;
        }
      } 
    }
</style>