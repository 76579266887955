<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :show.sync="Visualizar"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12" class="text-center">
          <h4 class="text-danger" v-if="NoData">{{ SinRegistros }}</h4>
          <CNavbar  v-if="!NoData" expandable="md" color="dark">
            <CToggler in-navbar @click="collapsed = !collapsed"/>
            <CCollapse :show="collapsed" navbar>
              <CNavbarNav
                v-for="levelOne in levelOneOptions"
                :key="levelOne.LevelOneNameJson"
              >
                <CDropdown
                  :togglerText="levelOne.LevelOneNameJson"
                  color="dark"
                  class="zindex-nav"
                >
                  <CRow class="p-1" :class="childClasses(levelOne.LevelTwoJson)">
                    <CCol
                      :lg="rowQuantity(levelOne.LevelTwoJson)"
                      v-for="levelTwo in levelOne.LevelTwoJson"
                      :key="levelTwo.LevelTwoNameJson"
                    >
                      <CDropdownHeader>{{ levelTwo.LevelTwoNameJson }}</CDropdownHeader>
                      <CDropdownItem
                        v-for="levelThree in levelTwo.LevelTreeJson"
                        :key="levelThree.ModuleName"
                      >
                        {{ levelThree.ModuleName }}
                      </CDropdownItem>
                    </CCol>
                  </CRow>
                </CDropdown>
              </CNavbarNav>
            </CCollapse>
          </CNavbar>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" @click="Visualizar= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>

function data () {
  return {
    items: [],
    RoleId: '',
    Loading: false,
    Visualizar: false,
    tituloModal: null,
    collapsed: false,
    NoData: true,
    SinRegistros: '',
    rutasInfo: []
  }
}

function listarDatos(rol) {
  this.Loading = true;
  let listado = [];

  this.$http.get("Menu-by-role", { RoleId: rol })
    .then((response) => {
      listado = [...response.data.data];

      if (listado.length > 0) {
        this.rutasInfo = listado[0].LevelOneJson;
        
        this.NoData = false;
      } else {
        this.SinRegistros = this.$t('label.noData');
        this.NoData = true;
      }
    })
    .catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function childClasses(menus) {
  return {
    'whijo-dos': (Array.isArray(menus) && menus.length == 2)? true : false,
    'whijo-tres': (Array.isArray(menus) && menus.length == 3)? true : false
  }
}
function rowQuantity(menus) {
  if(!Array.isArray(menus))
    return 6;

  switch (menus.length) {
    case 1:
      return 12;
    case 2:
      return 6;
    default:
      return 4;
  }
}

//Computed:
function levelOneOptions() {
  return this.rutasInfo;
}

export default {
  name: 'visualizar-menu',
  data,
  props: {
    modal: null
  },
  methods: {
    listarDatos,
    childClasses,
    rowQuantity,
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.Visualizar = true;
        this.listarDatos(this.modal[0].value);
        this.tituloModal= this.$t('label.preview')+" MENU "+this.$t('label.role')+": "+this.modal[0].label;
        this.$emit('cerrarModal');
      }
    }
  },
  computed: {
    levelOneOptions
  }
}
</script>

<style scoped>
  .whijo {
    min-width: 30vw;
  }
  .whijo-dos {
    min-width: 60vw;
  }
  .whijo-tres {
    min-width: 80vw;
  }
  .zindex-nav {
    z-index: 1003;
  }

  .after-breadcrumb {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
  .remove-space {
    margin-bottom: 0;
  }
</style>
