<template>
  <div>
    <div v-show="showIndex==0">
      <div class="align-items-center justify-content-center mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
        <CRow class="align-items-center justify-content-center">
          <CCol sm="6" xl="6" class="align-items-center">
            <CRow>
              <CCol sm="12" xl="12">
                <b>{{`${$t('label.events')} / ${$t('VACIADO')}`}}</b>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="6" xl="6" >
            <CRow>
              <CCol sm="12" xl="12" class="align-items-center">
                <CButton
                  shape="square"
                  color="add"
                  class="d-flex align-items-center ml-auto"
                  v-c-tooltip="{
                    content: $t('VACIADO'),
                    placement: 'top-end'
                  }"
                  @click="toggleAdd({})"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                >
                    <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #status="{item}">
                      <td>
                        <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
                      </td>
                    </template>
                    <template #edit="{item, index}">
                        <td class="text-center">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                class="mr-1"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                        </td>
                    </template>
                    <template #seals="{item, index}">
                        <td class="text-center">
                            <CButton
                                square
                                style="background-color: #d6d6d7;"
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.Seals'),
                                    placement: 'top-end'
                                }"
                                @click="viewDetail(item, index)"
                            >
                              <CIcon name="bottomSeals"/>
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSecondLevelItems"
                            :fields="level2Fields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                            <template #view="{item, index}">
                                <td class="text-center">
                                    <CButton
                                        color="watch"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="viewDetail(item, index)"
                                    >
                                        <CIcon name="eye"/>
                                    </CButton>
                                </td>
                            </template>
                            <template #galery="{item, index}">
                                <td class="text-center">
                                    <CButton
                                        color="add"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="viewDetail(item, index)"
                                    >
                                        <CIcon name="cil-image"/>
                                    </CButton>
                                </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <EmptiedRegister
          :Active="ActiveRegister"
          :ContainerItem="ContainerItem"
          @Close="showIndex=0,ActiveRegister=false"
          @Update-list="Update"
      />
    </div>
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import EmptiedRegister from './emptied-register';

//DATA-------------------------
function data() {
  return {
    collapseDuration: 0,
    _toggled: false,
    showIndex: 0,
    ActiveRegister: false,
    ContainerItem:{},
    dataEmptied :
    [
      {
        number:'1',
        patio: 'sdfsd',
        device: '',
        maquinary: 'sdfds',
        maquinary: 'sdfds',
        user:'sdfsdf',
        dateRegister: 'asdff',
        estatus: 'true',
        _toggled: false,
      },
      {
        number:'1',
        patio: 'sdfsd',
        device: '',
        maquinary: 'sdfds',
        maquinary: 'sdfds',
        user:'sdfsdf',
        dateRegister: 'asdff',
        estatus: 'true',
        _toggled: false,
      },

    ],
    data2Reception :
    [
      {
        seals:'1',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      },
      {
        seals:'2',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      },
      {
        seals:'3',
        condition: 'sdfsd',
        after: 'sdfds',
        new: 'dsfdsf',
        _classes: 'value-Table' ,
      }
    ],
  }
}

function toggleAdd(item) {
    this.ContainerItem = item;
    this.ActiveRegister = true;
    this.showIndex = 1;
}

function formatedItems() {
  return this.dataEmptied.filter(item => {
      return item
  })
}

function formatedSecondLevelItems() {
  return this.data2Reception.filter(item => {
      return item
  })
}

function fields(){
        return [
            {
              key: 'edit', 
              label: '', 
              _style: 'width:50px; text-align:center;', 
              sorter: false, 
              filter: false
            },
            { 
              key: 'seals', 
              label: '', 
              _style: 'width:50px; text-align:center;', 
              sorter: false, 
              filter: false
            },
            { key: 'number', label: '#',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'patio',label: this.$t('label.yard'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
            { key: 'device', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
            { key: 'maquinary', label: this.$t('label.machine'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
            { key: 'maquinary', label: this.$t('label.eventDate'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
            { key: 'user', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'dateRegister', label: this.$t('label.registerDate'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
            { key: 'estatus', label: this.$t('label.status'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
            
        ];
    }

  function level2Fields(){
      return [
          {
            key: 'view', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { 
            key: 'galery', 
            label: '', 
            _style: 'min-width: 5%; text-align:center; background-color: #828c9c;', 
            sorter: false, 
            filter: false
          },
          { key: 'seals',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'condition', label: this.$t('label.Mode'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'after', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
          { key: 'new', label: this.$t('label.nuevo'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
      ];
  }

  function Update() {
  }

  function closeShow(item, level) {

  }

  function viewDetail(item){
    this.closeShow(item, 1);

    item._toggled = !item._toggled;
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }


//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}


export default {
  name: 'emptied',
  components: {
    CustomTabs,
    CustomTab,
    EmptiedRegister
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    viewDetail,
    closeShow,
    toggleAdd,
    Update,
  },
  computed: {
    VisitOptions,
    fields,
    level2Fields,
    formatedItems,
    formatedSecondLevelItems
  },
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}

.Container-items{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.value-Table{
  background-color: white;
}
</style>