<template>
    
    <CRow style="margin-top:20px;">
        <app-modal-gruas :grua="grua" :machineGrua="machineGrua" @set-list="reset"></app-modal-gruas>
        <loading-overlay
            :active="isLoading"
            :is-full-page="true"
            loader="bars"
        />
        <CCol sm="12">
            <CCollapse style="width:100%;" :show="true">
                <CTabs style="width:100%;" variant="tabs" :active-tab="tabGruaIndex" @update:activeTab="handleTab">
                    <CTab v-for="(tabs, index) in gruasTabs" :key="tabs.StowageUbicationId" :title="CraneTitle(index)">
                        <CCol sm="12" style="margin-top:20px;">
                            <CRow class="mb-3">
                                <CCol sm="6">
                                    <CRow>
                                        <CCol sm="12">
                                            <CSelect
                                                v-if="tabGruaIndex==0"
                                                :label="$t('label.crane')"
                                                horizontal
                                                :options="optionListGruas"
                                                :disabled="loading"
                                                v-model.trim="$v.CraneFiltre.$model"
                                                :value.sync="$store.state.planificacionestiba.filtroGrua"
                                                :is-valid="hasError($v.CraneFiltre)"
                                                :invalid-feedback="errorMessage($v.CraneFiltre)"
                                                addLabelClasses="text-right required"
                                            >
                                                <template #append>
                                                    <CButton
                                                        v-if="!ModalCraneMachine"
                                                        color="add"
                                                        size="sm"
                                                        class="rounded"
                                                        @click="activarCollapse()"
                                                        v-c-tooltip="{
                                                            content:$t('label.nueva')+' '+$t('label.crane'),
                                                            placement: 'top-end'
                                                        }"
                                                        >
                                                        <CIcon name="cil-playlist-add"/>
                                                    </CButton>
                                                    <CButton
                                                        v-else
                                                        color="watch"
                                                        size="sm"
                                                        class="rounded"
                                                        @click="activarCollapse()"
                                                        v-c-tooltip="{
                                                            content:$t('label.toView')+' '+$t('label.crane'),
                                                            placement: 'top-end'
                                                        }"
                                                        >
                                                        <CIcon name="eye"/>
                                                    </CButton>
                                                </template>
                                            </CSelect>
                                            <div v-else class="form-group form-row" rol="group">
                                                <label class="required col-form-label col-sm-12 col-lg-3 text-right">{{$t('label.crane')}}</label>
                                                <div class="col-sm-12 col-lg-9 input-group">
                                                    <v-select
                                                        :class="CraneFiltre ? 'select-client--correct Vselect-adjust' : (verifySelect ? 'select-client--error Vselect-adjust' : 'Vselect-adjust')"
                                                        v-model="$v.CraneFiltre.$model"
                                                        :placeholder="$t('label.select')"
                                                        :options="optionListGruas" 
                                                        :reduce="option => option.value" 
                                                        :getOptionLabel="option => option.label"
                                                    >
                                                        <template #no-options="{ }">
                                                            {{$t('label.noResultsFound')}}
                                                        </template>
                                                        <template #option="{ Json, label }">
                                                            {{ label }}
                                                            <br />
                                                            <cite>{{ Json.TpMachineName }} - {{ Json.MachineConditionName }} </cite>
                                                        </template>
                                                    </v-select>
                                                    <div class="input-group-append">
                                                        <div class="d-flex align-items-start">
                                                            <CButton
                                                                color="add"
                                                                size="sm"
                                                                class="rounded"
                                                                @click.stop="activarCollapse"
                                                                v-c-tooltip="{
                                                                    content: $t('label.add')+' '+$t('label.machine'),
                                                                    placement: 'top-end'
                                                                }"
                                                                v-if="!ModalCraneMachine"
                                                            >
                                                                <CIcon name="cil-playlist-add"/>
                                                            </CButton>
                                                            <CButton
                                                                v-else
                                                                color="watch"
                                                                size="sm"
                                                                class="rounded"
                                                                @click.stop="activarCollapse"
                                                                v-c-tooltip="{
                                                                    content:$t('label.toView')+' '+$t('label.crane'),
                                                                    placement: 'top-end'
                                                                }"
                                                            >
                                                                <CIcon name="eye"/>
                                                            </CButton>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 px-0" v-if="$v.CraneFiltre.$error || (CraneFiltre == '' && verifySelect == true)">
                                                        <div class="text-invalid-feedback">
                                                            {{$t('label.required')}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol sm="12" lg="auto" class="d-flex align-items-start justify-content-end">
                                    <CButton
                                        class="mr-1"
                                        color="add"
                                        size="sm"
                                        @click="asignarPlanificacion()"
                                        :disabled="isSubmit"
                                        v-c-tooltip="{
                                            content:$t('label.add'),
                                            placement: 'top-end'
                                        }"
                                    >
                                        <CIcon name="checkAlt" />
                                    </CButton>
                                    <CButton
                                        color="wipe"
                                        size="sm"
                                        v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" 
                                        @click="reset(true, false)"
                                    >
                                        <CIcon name="cil-brush-alt" />
                                </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol sm="12">
                            <dataTableExtended
     class="align-center-row-datatable"
                                :items="formatedItems"
                                :fields="fieldsGruas"
                                :table-filter="tableText.tableFilterText"
                                :items-per-page-select="tableText.itemsPerPageText"
                                sorter
                                :noItemsView="tableText.noItemsViewText"
                                :items-per-page="5"
                                pagination
                                column-filter
                                hover
                                 
                            >
                                <template #RowNumber="{ index}">
                                    <td class="text-center">
                                        {{index+1}}
                                    </td>
                                </template>
                                <template #TurnStatus="{item}">
                                    <td class="text-center">
                                        <CBadge :color="colorBadgePlanificacionCranne(item)">
                                            {{item.TurnStatus}}
                                        </CBadge>
                                    </td>
                                </template>
                                <template #acciones="{item,index}">
                                    <td class="text-center">
                                            <CButton
                                                color="watch"
                                                size="sm"
                                                class="mr-1"
                                                @click="editarPlanificacion(item, index)"
                                                v-c-tooltip="{
                                                    content: $t('label.toView')+' '+$t('label.PlannedHoursCrane'),
                                                    placement: 'top-end'
                                                }"
                                                :disabled="item.FgOperational === false"
                                            >
                                                <CIcon name="calendaryChecked"/>
                                            </CButton>
                                            <CButton
                                                color="edit"
                                                size="sm"
                                                class="mr-1"
                                                v-c-tooltip="{
                                                    content: $t('label.edit')+' '+$t('label.crane'),
                                                    placement: 'top-end'
                                                }"
                                                @click="editGruaCollapse(item)"
                                            >
                                                <CIcon name="pencil"/>
                                            </CButton>
                                            <CButton
                                                color="wipe"
                                                size="sm"
                                                class="mr-1"
                                                v-c-tooltip="{
                                                    content: $t('label.inactivateCranePlanification'),
                                                    placement: 'top-end'
                                                }"
                                                @click="eliminarPlanificacion(item, index)"
                                            >
                                                <CIcon name="cil-trash" />
                                            </CButton>
                                    </td>
                                </template>
                            </dataTableExtended>
                        </CCol>
                    </CTab>
                </CTabs>
            </CCollapse>
        </CCol>
        <appGruas v-if="tabGruaIndex === 0" @set-list="reset"></appGruas>
        <appMachinesGruas v-if="tabGruaIndex === 1" 
            @set-list="reset" 
            :Crane="true"
            :modalCraneMachine="modalCraneMachine"
        ></appMachinesGruas>
    </CRow>
</template>
<script>
    import { mapState } from 'vuex';
    import appGruas from './modals/app-gruas-crud';
    import appMachinesGruas from './modals/app-machinesgruas-crud';
    import AppModalGruas from './modals/app-modal-gruas';
    import ModalMixin from '@/_mixins/modal';
    import ENUM from '@/_store/enum';
    import CraneValidations from '@/_validations/planificacion-estiba/CraneValidations';
    import { tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            modalCraneMachine: false,
            isLoading:false,
            isSubmit: false,
            verifySelect: false,
            CraneFiltre: '',
            grua:{
                StowageUbicationId:'',
                CraneName:'',
                VesselCraneId: "",
                VesselNameCrane: "",
            },
            machineGrua:{
                StowageUbicationId:'',
                CraneName:'',
                VesselCraneId: "",
                VesselNameCrane: "",
            },
            gruasTabs:[],
            items:[],
            itemsMachine:[],
            itemsVesselSide: [],
            activePage: 1,
            tabGruaIndex: 0,
        }
    }

    //methods
    function reset(filtre, table) {
        this.modalCraneMachine = false;
        if (filtre) {
            this.$v.$reset();
            this.CraneFiltre = '';
            this.$store.state.planificacionestiba.filtroGrua='';
        }
        if (table) {
            if(this.tabGruaIndex === 0){
                this.$store.dispatch('planificacionestiba/getStowagePlanningCranelist',{StowagePlanningId:this.planificacionId});
                this.getGruas(this.planificacionId);
            }else{
                this.getMachines(this.planificacionId);
                this.getMachineCranelist();
            }
        }
    }

    async function getGruas(IdPlanificacion) {
        this.isLoading = true;
        await this.$http.ejecutar('GET', 'VesselCraneFree-list', { StowagePlanningId: IdPlanificacion })
        .then(response => {
            this.items = response.data.data;
            this.isLoading = false;
        }).catch( err => {
            this.isLoading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }
    async function getMachineCranelist(){
        this.isLoading = true;
        this.$http.get('StowagePlanningMachineCrane-list', {StowagePlanningId: this.planificacionId})
        .then(response => {
            this.itemsVesselSide = response.data.data;
            this.isLoading = false;
        }).catch( err => {
            this.isLoading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }
    async function getMachines(IdPlanificacion){
        this.isLoading = true;
        this.$http.ejecutar('GET', 'MachineCraneFree-list', { StowagePlanningId: IdPlanificacion })
        .then(response => {
            this.itemsMachine = response.data.data;
            this.isLoading = false;
        }).catch( err => {
            this.isLoading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }
    function fieldsGruas(){
        if(this.tabGruaIndex === 0){
            return [
                { key: 'RowNumber', label: '#',_style: 'width:1%; text-align:center;', filter:false },
                { key: 'CraneName',label: this.$t('label.crane'), _style:'width:40%', _classes:"center-cell" },
                { key: 'MaxHeigth',label: this.$t('label.maxRange')+'(MTS)', _style:'width:10%', _classes:"center-cell" },
                { key: 'MaxWeigth',label: this.$t('label.maxWeight')+'(TON)', _style:'width:10%', _classes:"center-cell" },
                { key: 'TotalHour',label: this.$t('label.EstimatedHours'), _style:'width:10%', _classes:"center-cell" },
                { key: 'TurnStatus', label: this.$t('label.status'), _style:'width:15%; text-align:center;' },
                /*{ key: 'UltimoUsuario', label: 'USUARIO', _style: 'width: 1%;'},
                { key: 'FormatedDate', label: 'FECHA', _style: 'width: 1%;'}
                { key: 'status', label: 'STATUS', _style:'width:1%; text-align:center;'},,*/
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'min-width: 135px; width: 1%', 
                    sorter: false, 
                    filter: false
                }
            ]
        }
        else if(this.tabGruaIndex === 1){
            return [
                { key: 'RowNumber', label: '#',_style: 'width:1%; text-align:center;', filter:false },
                { key: 'MachineName',label: this.$t('label.crane'), _style:'width:40%; text-align:center;', _classes:"center-cell" },
                { key: 'MaxRadio',label: this.$t('label.maxRange')+'(MTS)', _style:'width:10%', _classes:"center-cell" },
                { key: 'MaxCapacity',label: this.$t('label.maxWeight')+'(TON)', _style:'width:10%', _classes:"center-cell" },
                { key: 'TotalHour',label: this.$t('label.EstimatedHours'), _style:'width:10%', _classes:"center-cell" },
                { key: 'TurnStatus', label: this.$t('label.status'), _style:'width:15%; text-align:center;' },
                /*{ key: 'UltimoUsuario', label: 'USUARIO', _style: 'width: 1%;'},
                { key: 'FormatedDate', label: 'FECHA', _style: 'width: 1%;'}
                { key: 'status', label: 'STATUS', _style:'width:1%; text-align:center;'},,*/
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'min-width: 135px; width: 1%', 
                    sorter: false, 
                    filter: false
                }
            ]
        }
    }
    
    function asignarPlanificacion(){
        try{
            this.isLoading = true;
            this.isSubmit = true;
            this.verifySelect = true;
            this.$v.$touch();
            if (this.$v.$error) {
                throw this.$t('label.select')+' '+this.$t('label.crane');
            }
            let valores = {};
            let link = '';
            let json = '';
            let StowageUbicationId = this.gruasTabs[this.tabGruaIndex].StowageUbicationId;

            if(this.tabGruaIndex === 0){
                let CraneOnBoard  = this.items.find(item => item.VesselCraneId == this.CraneFiltre)
                if (!CraneOnBoard?.FgOperational) {
                    throw this.$t('label.TheCraneIsNotOperational');
                }

                link = "StowagePlanningCrane-insert";
                json = "StowagePlanningCraneJson";

                valores = {
                    StowagePlanningCraneId:"",
                    StowagePlanningId: this.planificacionId, 
                    VesselCraneId: this.$store.state.planificacionestiba.filtroGrua,
                    StowageUbicationId: StowageUbicationId, 
                    TurnId:"",
                    DateFrom:"",
                    DateTo:"",
                    TotalHour:"",
                    FgOperational:1,
                }
            }
            else if(this.tabGruaIndex === 1){
                link = "StowagePlanningMachineCrane-insert";
                json = "StowagePlanningMachineJson";

                valores = {
                    StowagePlanningCraneId: '',
                    StowagePlanningId: this.planificacionId, 
                    MachineId:this.CraneFiltre,
                }
            }
            this.$http.ejecutar('post', link, valores, { root: json })
                .then(response => {
                    if(response.data.status === 200){
                        const information = response.data.data;
                        if(information[0].id === ""){
                            this.$notify({
                                group: 'container',
                                type: 'error',
                                title: 'ERROR',
                                text: information[0].Response,
                            });
                        }else{
                            this.$notify({
                                group: 'container',
                                title: '¡'+this.$t('label.success')+'!',
                                text: information[0].Response,
                                type: "success"
                            });
                            this.verifySelect = false;
                            this.reset(true, true);
                            this.isSubmit = false; 
                        }
                    }
                }).catch( err => {
                    this.isSubmit = false;
                    this.isLoading = false;
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        title: 'ERROR',
                        text: err,
                    });
                });
        }catch(e){
            this.isSubmit = false;
            this.isLoading = false;
            this.$notify({
                group: 'container',
                type: 'error',
                ignoreDuplicates:false,
                title: 'ERROR',
                text: e,
            });
        }
    }
    function activarCollapse(){
        if(this.tabGruaIndex === 0){
            this.$store.state.planificacionestiba.collapseGruas = true;
            this.$store.state.planificacionestiba.idState = this.CraneFiltre?this.CraneFiltre:0;
        }else if(this.tabGruaIndex === 1){
            this.$store.state.planificacionestiba.idState = this.CraneFiltre?this.CraneFiltre:0;
            this.modalCraneMachine = true;
        }
        
    }
    function handleTab(tab) {
        this.tabGruaIndex = tab;
        this.$store.state.planificacionestiba.tabGruaIndex = tab;
    }
    function editarPlanificacion(item){
        if(this.tabGruaIndex === 0){
            this.grua = item;
            this.grua.StowageUbicationId = this.gruasTabs[this.tabGruaIndex].StowageUbicationId;
        }else if(this.tabGruaIndex === 1){
            this.machineGrua = item;
        }
        this.$store.state.planificacionestiba.modalGruas = true;
        /*this.$store.state.contenedores.modalAccesorios = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoAccesoryId);*/
    }
    function editGruaCollapse(item){
        if(this.tabGruaIndex === 0){
            this.$store.state.planificacionestiba.idState = item.VesselCraneId;
            this.$store.state.planificacionestiba.collapseGruas = true;
        }else if(this.tabGruaIndex === 1){
            this.$store.state.planificacionestiba.idState = item.MachineId;
            this.modalCraneMachine = true;
        }
    }
    function eliminarPlanificacion(item){
        let nombre = '';
        let valores = {};
        let link = '';
        let json = '';

        if(this.tabGruaIndex === 0){
            nombre = item.CraneName;
            link = "StowagePlanningCraneAll-update";
            json = "StowagePlanningCraneJson";

            valores = {
                StowagePlanningId: item.StowagePlanningId, 
                VesselCraneId: item.VesselCraneId, 
                Status:0
            }
        }
        else if(this.tabGruaIndex === 1){
            nombre = item.MachineName;
            link = "StowagePlanningMachineCraneAll-update";
            json = "StowagePlanningMachineJson";
            valores = {
                StowagePlanningId: item.StowagePlanningId, 
                MachineId: item.MachineId, 
                Status:0
            }
        }
        
        if(nombre !== ''){
            this.$swal.fire(
                alertPropertiesHelpers(this, {
                    text: `${this.$t('label.changeStatusQuestion')}?`,
                })
               ).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    this.$http.ejecutar('PUT', link, valores, { root: json })
                    .then(response => {
                        if(response.data.status === 200){
                            const information = response.data.data;
                            if(information[0].id === ""){
                                this.$notify({
                                    group: 'container',
                                    type: 'error',
                                    title: 'ERROR',
                                    text: information[0].Response,
                                });
                                this.isLoading = false;
                            }else{
                                this.$notify({
                                    group: 'container',
                                    title: '¡'+this.$t('label.success')+'!',
                                    text: information[0].Response,
                                    type: "success"
                                });
                                if(this.tabGruaIndex === 0){
                                    this.$store.dispatch('planificacionestiba/getStowagePlanningCranelist',{StowagePlanningId:this.planificacionId});
                                    this.getGruas(this.planificacionId);
                                }else if(this.tabGruaIndex === 1){
                                    this.getMachines(this.planificacionId);
                                    this.getMachineCranelist();
                                }
                            }   
                        }else{
                            this.isLoading = false;
                        }
                    }).catch( err => {
                        this.$notify({
                            group: 'container',
                            type: 'error',
                            title: 'ERROR',
                            text: err,
                        });
                        this.isLoading = false;
                    });
                }else {
                    //this.$swal(this.$t('label.operationCancelled'));
                }
            })
        }
        
    }
    function colorBadgePlanificacionCranne(item){
        if(this.tabGruaIndex === 0){
            if(item.FgOperational && item.TotalHour === '00:00'){
                return 'secondary';
            }else{
                return '';
            }
        }else if(this.tabGruaIndex === 1){
            if(item.TotalHour === '00:00'){
                return 'secondary';
            }else{
                return '';
            }
        }
        
    }
    //computed
    function ModalCraneMachine(){
        return this.CraneFiltre;
    }
    function computedItems () {
        if(this.tabGruaIndex === 0){
            return this.$store.getters["planificacionestiba/mytabDataGruastable"];
        }else if(this.tabGruaIndex === 1){
            return this.itemsVesselSide;
        }else{
            return [];
        }
    }
    function CraneTitle(index) {
        if (index === 0) {
            return this.$t('label.OnBoard');
        } else {
            return this.$t('label.VesselSide');
        }
    }
    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        });
    }
    function optionListGruas(){
        if(this.tabGruaIndex === 0){
            if(this.items.length === 0){
                return [{
                    value: '', 
                    label: this.$t('label.select'),
                }];
            }else{
                var chart = [{
                    value: '', 
                    label: this.$t('label.select'),
                }]
                this.items.map(function(e){
                    if(e.FgActVesselCrane){
                        chart.push({
                            value: e.VesselCraneId, 
                            label: e.CraneName,
                        })
                    }
                })
                return chart;
            }
        }else if(this.tabGruaIndex === 1){
            if(this.itemsMachine.length === 0){
                return [{
                    value: '', 
                    label: this.$t('label.select'),
                    Json: {
                        TpMachineName: '',
                        MachineConditionName: '',
                    },
                }];
            }else{
                var chart = [{
                    value: '', 
                    label: this.$t('label.select'),
                    Json: {
                        TpMachineName: '',
                        MachineConditionName: '',
                    },
                }]
                this.itemsMachine.map(function(e){
                    if(e.FgActMachine){
                        chart.push({
                            value: e.MachineId, 
                            label: e.MachineName,
                            Json: {
                                TpMachineName: e.TpMachineName,
                                MachineConditionName: e.MachineConditionName
                            },
                        })
                    }
                })
                return chart;
            }
        }
        
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 2){
            if(this.ItineraryId !== 0 || this.ItineraryId !== null){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                
                if(Index > -1){
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('GET', 'StowageUbication-list', {  })
                    .then(response => {
                        this.gruasTabs = response.data.data.filter((u,index) => {
                            return u.StowageUbicationName == 'A BORDO' || u.StowageUbicationName == 'COSTADO DE BUQUE'
                        });
                        this.$store.dispatch('planificacionestiba/getStowagePlanningCranelist',{StowagePlanningId:this.planificacionId});
                        this.getGruas(this.planificacionId);
                    }).catch( err => {
                        this.$store.commit('planificacionestiba/messageMutation', err);
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }).then(() => {
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    });
                    
                }
            }
        }else if(OldValue === 2){
            this.verifySelect = false;
            this.itemsVesselSide = [];
            this.tabGruaIndex = 0;
            this.$store.state.planificacionestiba.collapseGruas = false;
        }
    }

    function tableText(){
        return tableTextTranslatedHelpers(this);
    }
    export default {
        name: 'app-gruas',
        data,
        props: ['loading'],
        mixins: [ModalMixin],
        validations: CraneValidations,
        methods: {
            getMachineCranelist,
            CraneTitle,
            reset,
            editGruaCollapse,
            activarCollapse,
            asignarPlanificacion,
            editarPlanificacion,
            handleTab,
            getGruas,
            getMachines,
            colorBadgePlanificacionCranne,
            eliminarPlanificacion
        },
        computed: {
            ModalCraneMachine,
            optionListGruas,
            fieldsGruas,
            computedItems,
            formatedItems,
            tableText,
            ...mapState({
                filtroGrua: state => state.planificacionestiba.filtroGrua,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                myDataGruas: state => state.planificacionestiba.myDataGruas,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                bulkCarrier: state => state.planificacionestiba.bulkCarrier
            })
        },
        components: {
            appGruas,
            AppModalGruas,
            appMachinesGruas,
        },
        watch:{
            tabIndex,
            tabGruaIndex: function (val) {
                if (val == 1 && !["", null, undefined].includes(this.planificacionId)) {
                    this.reset(true, false);
                    this.CraneFiltre = '';
                    this.getMachines(this.planificacionId);
                    this.getMachineCranelist();
                }else if (val == 0 && !["", null, undefined].includes(this.planificacionId)) {
                    this.reset(true, false);
                    this.CraneFiltre = '';
                    this.$store.dispatch('planificacionestiba/getStowagePlanningCranelist',{StowagePlanningId:this.planificacionId});
                    this.getGruas(this.planificacionId);
                }
            },
        }
    } 
</script>

<style lang="scss">
 /*   .center-cell {
        text-align: center;
        vertical-align: middle;
    }

*/
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
                vertical-align: middle !important;
            }
        }
    }
</style>