import { required } from 'vuelidate/lib/validators';

export default () => {
  return {
    ServiceId: { required },
    Status: { required },
    PortId: { required },
    Sequence: { required },
    FrecuencyId: { required },
  };
};
