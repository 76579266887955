<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.seller')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerName.$model"
        :is-valid="hasError($v.Seller.SellerName)"
        :invalid-feedback="errorMessage($v.Seller.SellerName)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.address')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerAddress.$model"
        :is-valid="hasError($v.Seller.SellerAddress)"
        :invalid-feedback="errorMessage($v.Seller.SellerAddress)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.mainPhone')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerPrimaryPhone.$model"
        :is-valid="hasError($v.Seller.SellerPrimaryPhone)"
        :invalid-feedback="errorMessage($v.Seller.SellerPrimaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5" >
      <CInput
        size="sm"
        :label="$t('label.secondaryPhone')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerSecondaryPhone.$model"
        :is-valid="hasError($v.Seller.SellerSecondaryPhone)"
        :invalid-feedback="errorMessage($v.Seller.SellerSecondaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.mainEmail')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerPrimaryEmail.$model"
        :is-valid="hasError($v.Seller.SellerPrimaryEmail)"
        :invalid-feedback="errorMessage($v.Seller.SellerPrimaryEmail)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.secondaryEmail')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Seller.SellerSecondaryEmail.$model"
        :is-valid="hasError($v.Seller.SellerSecondaryEmail)"
        :invalid-feedback="errorMessage($v.Seller.SellerSecondaryEmail)"
      />
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {

  };
}

export default {
  name: 'seller-tab',
  components: {
  },
  data,
  props: {
    Seller: {
      type: Object,
      required: true,
      default: () => {},
    },
    isEdit: Boolean,
    showModal: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations();
  },
  methods: {

  },
  computed:{

  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.Seller.$touch();
      }else{
        this.$v.$reset();
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.Seller.$touch();
      }
    }
  }
};
</script>