<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      :show.sync="CreModal"
      class="modal-content-create"
    >
      <CRow class="mt-2">
        <CCol sm="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.name')"
            maxlength="50"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.StandardDocumentName.$model"
            :is-valid="hasError($v.StandardDocumentName)"
            :invalid-feedback="errorMessage($v.StandardDocumentName)"
          />
        </CCol>
        <CCol sm="12">
          <CTextarea
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.description')"
            maxlength="50"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            rows="5"
            v-model.trim="$v.StandardDocumentDescription.$model"
            :is-valid="hasError($v.StandardDocumentDescription)"
            :invalid-feedback="errorMessage($v.StandardDocumentDescription)"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.standardOrganization')"
            maxlength="400"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            rows="5"
            v-model.trim="$v.StandardOrganization.$model"
            :is-valid="hasError($v.StandardOrganization)"
            :invalid-feedback="errorMessage($v.StandardOrganization)"
          />
        </CCol>
        <CCol sm="6">
          <CSelect
            addLabelClasses="required text-right"
            :label="$t('label.newShippingLine')"
            :horizontal ="{label:'col-sm-6', input:'col-sm-6'}"
            v-model="aplica"
            :value.sync="aplica"
            :options="aplicaOptions"
          />
        </CCol>
        <CCol sm="5">
          <v-select 
            :options="shippingAgencyOptions"
            :placeholder="$t('label.select')"
            v-model="ShippingLineId"
            :value.sync="ShippingLineId"
            :disabled="!aplica"
            :class="computedSelectShippingLine 
              ? 'select-ShippingLine--correct' 
              : (verifyShippingLine ? 'select-ShippingLine--correct' : '') 
            " 
          />
        </CCol>
        <CCol sm="12" v-if="actualizar">
          <CSelect
            addLabelClasses="required text-right"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <br />
      <br />
      <template #footer>
        <CButton color="add" :disabled="isSudmit" @click="evaluaStatus">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="CreModal = false">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { DateFormater, tableTextHelpers } from "@/_helpers/funciones";
import UpperCase from "@/_validations/uppercase-directive";
import EstandarVal from "@/_validations/estandares/estandarVal";
import General from "@/_mixins/general";
import { mapState } from "vuex";

//COMPUTED
function isDisabled() {
  return this.$v.$invalid;
}

//METHOD
function eliminar(item) {
  this.$swal
    .fire(this.alertProperties({
      text: `¿Esta seguro de quitar el elemento ${item.Nro} de la lista ?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        this.itemsDocs.splice(item, 1);
      }
    });
}

function evaluaStatus() {
  if (this.Status === 0) {
    this.$swal
      .fire(this.alertProperties({
        text: `${this.$t('label.changeStatusQuestion')}?`,
      }))
      .then((result) => {
        if (result.isConfirmed) {
          this.guardar();
        }
      });
  } else this.guardar();
}

function listarDocs() {
  this.itemsDocs = [];
  this.Loading = true;
  let listado = [];
  let listado2 = [];
  this.$http
    .get("StandardDocument-by-id", {
      StandardDocumentId: this.StandardDocumentId,
    })
    .then((response) => {
      listado2 = [...response.data.data];
      listado = listado2[0].StandardDocumentDocJson;
      let Nro = 1;
      this.itemsDocs = listado.map((listado) =>
        Object.assign({}, this.itemsDocs, {
          Nro: Nro++,
          StandardDocumentDocRoute: listado.StandardDocumentDocRoute
            ? listado.StandardDocumentDocRoute.replace(
                "Public/StandarDocument/",
                "",
                "i"
              )
            : "N/A",
          DocsRoute: listado.StandardDocumentDocRoute
            ? `${
                this.$store.getters["connection/getBase"]
              }${listado.StandardDocumentDocRoute.replace("Public/", "", "i")}`
            : "N/A",
          Usuario: listado2[0].TransaLogin ? listado2[0].TransaLogin : "N/A",
          Fecha: listado2[0].TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado2[0].TransaRegDate)
            : "N/A",
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
}

function limpiarDatos() {
  this.StandardDocumentDescription = "";
  this.StandardDocumentName = "";
  this.StandardOrganization = "";
  this.StandardDocumentDocRoute = "";
  this.ShippingLineId = '';
  this.aplica = false;
  this.filelist = "";
  this.isSubmit = false;
  this.verifyShippingLine = false;
  this.$nextTick(() => {
    this.$v.$reset();
  });
}

function guardar() {
  try {
    this.isSubmit = true;
    this.verifyShippingLine = true;
    this.$v.$touch();
    this.Loading = true;

    if (this.$v.$error) {
      this.isSubmit = false;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let res = [];
    let metodo = "";
    let ruta = "";
    let StandardDocumentJson = [];
    if (!this.aplica) this.ShippingLineId = '';
    
    if ((this.aplica) && (this.ShippingLineId=='')) {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: "falta la linea naviera",
        type: "danger",
      });
      this.Loading = true;
      this.isSubmit = false;
      return false;
    }
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.actualizar) {
      StandardDocumentJson = {
        StandardDocumentId: this.StandardDocumentId,
        StandardDocumentName: this.StandardDocumentName,
        StandardDocumentDescription: this.StandardDocumentDescription,
        StandardOrganization: this.StandardOrganization,
        ShippingLineId: this.ShippingLineId.value,
        Status: this.Status,
      };
      metodo = "PUT";
      ruta = "StandardDocument-update";
    } else {
      StandardDocumentJson = {
        StandardDocumentName: this.StandardDocumentName,
        StandardDocumentDescription: this.StandardDocumentDescription,
        StandardOrganization: this.StandardOrganization,
        ShippingLineId: this.ShippingLineId.value,
      };
      metodo = "POST";
      ruta = "StandardDocument-insert";
    }
    
    //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
    this.$http
      .ejecutar(metodo, ruta, StandardDocumentJson, {
        root: "StandardDocumentJson",
      })
      .then((response) => {
        res = [...response.data.data];
        this.limpiarDatos();
        this.$notify({
          group: "container",
          title: "¡Exito!",
          text: res[0].Response,
          type: "success",
        });
        this.Loading = false;
        this.isSubmit = false;
        this.$emit("child-refresh", true);
        this.CreModal = false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.Loading = false;
        this.isSubmit = false;
      });
  } catch (err) {
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: err,
      type: "error",
    });
    this.Loading = false;
    this.isSubmit = false;
  }
  
}

function getShippingAgency() {
  this.Loading = true;
  let parametros = {
    Filter: 'ACTIVO'
  }
  this.$http.get('shipping-agency-list', parametros)
  .then(response => {
    this.items = response.data.data;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading = false;
  });
}

function shippingAgencyOptions() {
  return this.items.map((item) => Object.assign({}, item, {
    label: item.ShippingLineName,
    value: item.ShippingLineId
  }));
}

function computedSelectShippingLine() {
  if (this.ShippingLineId === '' || this.ShippingLineId === null) {
    return false;
  } else {
    return true;
  }
}


//DATA
function data() {
  return {
    // VARIABLES
    tituloModal: "",
    filelist: [],
    itemsDocs: [],
    items: [],
    actualizar: false,
    Status: 1,
    CreModal: false,
    Loading: false,
    aplica: false,
    StandardDocumentDocRoute: "",
    ShippingLineId: '',
    //MODELO
    StandardDocumentDescription: "",
    StandardDocumentName: "",
    StandardOrganization: "",
    StandardDocumentDocJson: "",
    isSudmit: false,
    verifyShippingLine: false
  };
}

function selectOptions() {
  return [
      {
        value: 1,
        label:`${this.$t('label.ACTIVO')}`
      },
      {
        value: 0,
        label:`${this.$t('label.INACTIVO')}`
      },
    ];
}

function aplicaOptions() {
  return [
      {
        value: false,
        label:`${this.$t('label.notApply')}`
      },
      {
        value: true,
        label:`${this.$t('label.apply')}`
      },
    ];
}

export default {
  name: "crear-modal",
  mixins: [General],
  data,
  props: {
    modal: null,
  },
  directives: UpperCase,
  validations: EstandarVal,
  watch: {
    //FUNCIONES DEL MODAL
    modal: async function() {
      if (this.modal) {
        this.limpiarDatos();
        this.CreModal = true;
        await this.getShippingAgency();
        if (this.modal == true) {
          this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.standardDocumentation');
          this.actualizar = false;
          this.itemsDocs = [];
        } else {
          this.actualizar = true;
          this.tituloModal = this.$t('label.edit')+' '+this.$t('label.standardDocumentation')+": " + this.modal.Name;
          this.StandardDocumentId = this.modal.StandardDocumentId;
          this.StandardDocumentName = this.modal.Name;
          this.StandardDocumentDescription = this.modal.StandardDocumentDescription;
          this.StandardOrganization = this.modal.StandardOrganization;
          this.verifyShippingLine = true;
          if (this.modal.ShippingLineId!=="") {
            this.ShippingLineId = {
              value: this.modal.ShippingLineId,
              label: this.modal.ShippingLineName
            }
          }
          if (this.ShippingLineId!="") this.aplica = true;
          this.Status = this.modal.Status == "ACTIVO" ? 1 : 0;
          this.$v.$touch();
          this.listarDocs();
        }
        this.$emit("cerrarModal");
      }
    },
  },
  methods: {
    limpiarDatos,
    guardar,
    listarDocs,
    getShippingAgency,
    evaluaStatus,
    eliminar,
    //TRATAR DE MEJORAR ESTOS METODOS DEL INPUT FILE
    /*
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.StandardDocumentDocRoute = this.filelist[0];
      this.submitFile();
      console.log(this.StandardDocumentDocRoute);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },*/
  },
  computed: {
    isDisabled,
    selectOptions,
    aplicaOptions,
    shippingAgencyOptions,
    computedSelectShippingLine
  },
};
</script>
<style scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}
</style>

<style lang="scss">
.modal-content-create {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}

.select-ShippingLine--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-ShippingLine--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}


</style>