<template>
    <CModalExtended
        :title="titleModal"
        color="dark"
        size="xl"
        :show.sync="modalActive"
        @update:show="closeModal(false, Refresh)"
        :closeOnBackdrop="false"
    >   
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CCol sm="12" class="d-flex justify-content-center">
            <CRow class="d-flex justify-content-center col-sm-12">
                <CCol sm="12" lg="12" v-if="isFull">
                    <CCollapse :show="Collapse">
                        <CollapseFullWeight
                            :CollapseFull="Collapse"
                            :FullItem="Item"
                            @set-Weighing-list="ListUpdate"
                        />
                    </CCollapse>
                </CCol>
                <CCol sm="12" lg="12" v-else>
                    <CCollapse :show="Collapse">
                        <CollapseTareWeight
                            :CollapseTare="Collapse"
                            :TareItem="Item"
                            @set-Weighing-list="ListUpdate"
                        />
                    </CCollapse>
                </CCol>
                <CCol sm="12" lg="9">
                    <CCollapse :show="CollapseDetele">
                        <CollapseDetelefull
                            :collapse="CollapseDetele"
                            :Item="Item"
                            :isFull="isFull"
                            @set-Weighing-list="ListUpdate"
                        />
                    </CCollapse>
                </CCol>
            </CRow>
        </CCol>
        <CCol sm="12" v-if="!CollapseDetele && !Collapse">
            <dataTableExtended
              class="align-center-row-datatable"
              :fields="fields"
              :items="computedOptionsList"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              hover
              small
              sorter
            >
                <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                            {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                </template>
                <template #options="{ item }">
                    <td class="center-cell" v-if="item.FgActStowagePlanningLogWeigth">       
                        <CButton
                            :disabled="!item.FgActStowagePlanningLogWeigth"
                            square
                            size="sm"
                            color="edit"
                            class="mr-1"
                            @click="toggleEdit(item)"
                            v-c-tooltip="{
                                content: isFull==true?$t('label.edit')+' '+$t('label.FullWeight'):$t('label.edit')+' '+$t('label.Taraweight'),
                                placement: 'top-start'
                            }"
                        >
                            <CIcon name="pencil" />
                        </CButton>
                        <CButton
                            :disabled="!item.FgActStowagePlanningLogWeigth"
                            square
                            size="sm"
                            color="wipe"
                            @click="DeleteWeight(item)"
                            v-c-tooltip="{
                                content: isFull==true?$t('label.delete')+' '+$t('label.FullWeight'):$t('label.delete')+' '+$t('label.Taraweight'),
                                placement: 'top-start'
                            }"
                        >
                            <CIcon name="cil-trash" />
                        </CButton>       
                    </td>
                    <td v-else></td>
                </template>
            </dataTableExtended>
        </CCol>
        <template #footer>
            <CButton color="wipe" @click="closeModal(false, Refresh)">
                <CIcon name="x" />&nbsp; {{$t('button.exit')}}
            </CButton>
      </template>
    </CModalExtended>
</template>
  
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater, tableTextTranslatedHelpers, NumberFormater } from '@/_helpers/funciones';
import CollapseFullWeight from './collapse-full-weight';
import CollapseDetelefull from './collapse-detele-weight';
import CollapseTareWeight from '@/pages/visita/tabs/shipment_orders/Weighing/collapse-tare-weight';

//Data
function data() {
    return {
        loadingOverlay: false,
        CollapseDetele: false,
        Refresh: false,
        Item: {},
        Collapse: false,
        Options: [],
        modalActive: false,
    }
}
    
//Method 
function closeModal(filtre, refresh) {
    this.Item = {};
    this.Collapse = false;
    this.CollapseDetele = false;
    this.$emit('set-Weighing-list', filtre, refresh);
}

function ListUpdate(refresh) {
    this.Collapse = false;
    this.Item = {};
    this.CollapseDetele = false;
    if (refresh) {
        this.loadingOverlay = true;
        if(this.isFull){
            this.$http.get("VisitFullWeigthOrder-by-OrderId", { OrderId: this.ListWeight.OrderId })
                .then(response => {
                    this.Refresh = true;
                    this.Options = response.data.data[0].FullListJson[0].HistoricDetailJson;
                }).catch( err => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                }).then(() => {
                    this.loadingOverlay = false;
                });
        }else{
            this.$http.get("VisitTareWeigth-by-StowagePlanningImplementVehicleId", { StowagePlanningImplementVehicleId: this.ListWeight.StowagePlanningImplementVehicleId })
                .then(response => {
                    this.Refresh = true;
                    this.Options = response.data.data[0].TareListJson[0].HistoricDetailJson;
                }).catch( err => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                }).then(() => {
                    this.loadingOverlay = false;
                });
        }
    }
    
}

function toggleEdit(item){
    this.Item = item;
    this.Collapse = true;
}

function DeleteWeight(item){
    this.Item = item;
    this.CollapseDetele = true;
}
     
//Computed
function fields(){
    if(this.isFull){
        return [
            { label: '', key: 'options', _style: 'min-width:90px;', _classes:'text-center', sorter: false, filter: false },
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.transport'), key: 'CarrierAlias', _classes: 'text-uppercase', },
            { label: this.$t('label.vehicle'), key: 'LicensePlate', _classes: 'text-uppercase', },
            { label: this.$t('label.clientYard'), key: 'YardClientName', _classes: 'text-uppercase', _style: 'min-width:140px'},
            { label: this.$t('label.yard'), key: 'YardName', _classes: 'text-uppercase', },
            { label: this.$t('label.Gamela'), key: 'ImplementAlias', _classes: 'text-uppercase', },
            { label: this.$t('label.truckScale'), key: 'TruckWeghingScaleName', _classes: 'text-uppercase', },
            { label: this.$t('label.movementType'), key: 'MovementOrderName', _classes: 'text-uppercase',},
            { label: this.$t('label.Taraweight')+'(KGM)', key: 'TareKgsForm', _classes: 'text-uppercase', },
            { label: this.$t('label.FullWeight')+'(KGM)', key: 'WeightKgsForm', _classes: 'text-uppercase', },
            { label: this.$t('label.netWeight')+'(KGM)', key: 'NetKgsForm', _classes: 'text-uppercase', },
            { label: this.$t('label.device'), key: 'DeviceCode', _classes: 'text-uppercase',},
            { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', },
            { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style: 'min-width:120px;'},
            { label: this.$t('label.status'), key: 'Status', _classes: 'text-center',},
        ];
    }else{
        return [
            { label: '', key: 'options', _style: 'min-width:90px;', sorter: false, filter: false },
            { label: '#', key: 'Nro', _classes: 'text-center', _style: 'text-align:center', filter: false},
            { label: this.$t('label.noOrder'), key: 'OrderCode', _style: 'min-width:150px', _classes: 'text-uppercase', },
            { label: this.$t('label.transport'), key: 'CarrierAlias', _classes: 'text-uppercase', },
            { label: this.$t('label.clientYard'), key: 'YardClientName', _classes: 'text-uppercase', _style: 'min-width:140px'},
            { label: this.$t('label.yard'), key: 'YardName', _classes: 'text-uppercase', },
            { label: this.$t('label.truckScale'), key: 'TruckWeghingScaleName', _classes: 'text-uppercase', },
            { label: this.$t('label.weight')+'(KGM)', key: 'TareKgsForm', _classes: 'text-uppercase', },
            { label: this.$t('label.device'), key: 'DeviceCode', _classes: 'text-uppercase',},
            { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', },
            { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style: 'min-width:120px;'},
            { label: this.$t('label.status'), key: 'Status', _classes: 'text-center',},
        ];
    }
    
}

function computedOptionsList() {
    if(this.Options.length > 0){
        let _lang = this.$i18n.locale;
        if (this.isFull) {
            return this.Options.map((item) => {
                item.YardClientName = item.YardClientName ? item.YardClientName : 'N/A';
                item.YardName = item.YardName ? item.YardName : 'N/A';
                item.OrderCode = item.OrderCode ? item.OrderCode : 'N/A';
                return {
                    ...item,
                    TareKgsForm: NumberFormater.formatNumber(item.TareKgs, 4),
                    NetKgsForm: NumberFormater.formatNumber(item.NetKgs, 4),
                    WeightKgsForm: NumberFormater.formatNumber(item.WeightKgs, 4),
                    MovementOrderName: _lang=='en' ? item.MovementOrderNameEn : item.MovementOrderNameEs,
                    _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
                    FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
                };
            });
        }else{
            return this.Options.map((item) => {
                item.YardClientName = item.YardClientName ? item.YardClientName : 'N/A';
                item.YardName = item.YardName ? item.YardName : 'N/A';
                item.OrderCode = item.OrderCode ? item.OrderCode : 'N/A';
                return {
                    ...item,
                    TareKgsForm: NumberFormater.formatNumber(item.TareKgs, 4),
                    TareTonForm: NumberFormater.formatNumber(item.TareTon, 4),
                    _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
                    MovementOrderName: _lang=='en' ? item.MovementOrderNameEn : item.MovementOrderNameEs,
                    FormatedDate: item.TransactionDate
                    ? DateFormater.formatDateTimeWithSlash(item.TransactionDate)
                    : 'N/A',
                };
            });
        }
    }else{
        return this.Options = [];
    }
}

function tableText(){
    return tableTextTranslatedHelpers(this);
}

function titleModal(){
    if(this.isFull){
        return this.$t('label.FullWeighingHistoricOrderNo')+' : '+this.ListWeight.OrderCode;
    }else{
        return this.$t('label.TareWeightBinHistoric')+': '+this.ListWeight.ImplementAlias+' - '+this.$t('label.vehicle')+': '+this.ListWeight.LicensePlate;
    }
}
    
export default {
    name:  'modal-list',
    mixins: [ GeneralMixin ],
    props: {modal: Boolean, ListWeight: Object, isFull:Boolean},
    data,
    components: {
        CollapseFullWeight,
        CollapseTareWeight,
        CollapseDetelefull,
    },
    directives: UpperCase,
    methods: {
        DeleteWeight,
        toggleEdit,
        ListUpdate,
        closeModal,
    },
    computed: {
        titleModal,
        computedOptionsList,
        fields,
        tableText,
    },
    watch: {
        modal: function(val){
            this.modalActive = val;
            if(this.modalActive){
                this.Refresh = false;
                this.Options = this.ListWeight.HistoricDetailJson;
            }else{
                this.Options = [];
            }
        },
    }
}
</script>