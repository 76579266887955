<template>
  <main>
    <form
      enctype="multipart/form-data"
      @submit.prevent="sumbittedBody"
      class="company-form"
    >
      <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="computedTitle"
        color="dark"
        :show.sync="modalActive"
        size="xl"
        :closeOnBackdrop="false"
      >
        <CCardBody>           
          <CRow>            
              <CCol sm="6">                
                <CSelect
                  :label="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' I)'"
                  :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                  :placeholder="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' I)'"
                  :options="formattedSelectLv1"
                  :value="valueSelectLv1"
                  @change="insertNivelII($event)"
                  class="ml-1"
                  addLabelClasses="text-right"
                  :is-valid="hasError($v.GpoModuleId)"
                  :invalid-feedback="$t('label.required')"
                  size="sm"
                />
              </CCol>
              <CCol sm="6">               
                <CSelect
                  :label="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' II)'"
                  :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                  :placeholder="$t('label.group')+' ('+$t('label.module')+ ' '+$t('label.level')+ ' II)'"
                  :options="formattedSelectLv2"
                  :value="valueSelectLv2"
                  addLabelClasses="text-right"
                  :is-valid="hasError($v.GpoModuleIdNivel2)"
                  :invalid-feedback="$t('label.required')"
                  @change="insertLv2($event)"
                  size="sm"
                />
              </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">               
              <CSelect
                :label="$t('label.moduleType')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.moduleType')"
                :options="formattedTpModule"
                :value="valueTpModule"
                addLabelClasses="text-right"
                :is-valid="hasError($v.TpModuleId)"
                :invalid-feedback="$t('label.required')"
                @change="insertTpModulo($event)"
                size="sm"
              >
                <template #append >                                                
                  <CButton
                    shape="square"
                    color="primary"
                    class="ml-2 rounded buttonClassModule"
                    v-c-tooltip="{placement:'top-end',content:$t('label.nuevo') + $t('label.module') }"                 
                    size="sm"               
                    @click="collapse = !collapse"
                  >
                    <CIcon :name="cambiaIcono" />                  
                  </CButton>                                                                                                                                                                                         
                </template>
              </CSelect>
              <CElementCover :opacity="0.8" v-show="loadingTM">
                <label class="d-inline">{{$t('label.load')}}... </label>
                <CSpinner size="sm" />
              </CElementCover>
            </CCol>              
          </CRow>

          <addTpModuleCollapse
            :collapse="collapse"
            @close-collapse="updateCollapse"
            @update-data-modulo="insertTpModuleData"
          />

          <!-- ### Process Business ###    -->
          <CRow>
            <CCol sm="12">              
              <CSelect
                :label="$t('label.businessProcess')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.businessProcess')"
                :options="processBusiness"
                :value="valueProcessBusinness"
                addLabelClasses="text-right"
                :invalid-feedback="$t('label.required')"
                :is-valid="hasError($v.ProcessId)"
                @change="insertPB($event)"
                size="sm"
              />
            </CCol>
          </CRow>
          <!-- ### MASTER DIRECTORY ###    -->
          <CRow>
            <CCol sm="12">                
              <CSelect
                :label="$t('label.masterDirectory')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.masterDirectory')"
                :options="masterDirectory"
                :invalid-feedback="$t('label.required')"
                addLabelClasses="text-right"
                :is-valid="hasError($v.DirectoryId)"
                @change="insertMD($event)"
                :value="valueMasterDirectory"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">                 
              <CInput
              :label="$t('label.name')+' '+('(ES)')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.menuIdentifier')"
                v-model.trim="$v.ModuleNameEs.$model"
                :is-valid="hasError($v.ModuleNameEs)"
                addLabelClasses="text-right"
                v-uppercase
                class="ml-1"
                :value="ModuleNameEs"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                size="sm"
              />
            </CCol>
            <CCol sm="6">                 
              <CInput
              :label="$t('label.name')+' '+('(EN)')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.menuIdentifier')"
                v-model.trim="$v.ModuleNameEn.$model"
                :is-valid="hasError($v.ModuleNameEn)"
                addLabelClasses="text-right"
                v-uppercase
                class="ml-1"
                :value="ModuleNameEn"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                size="sm"
              />
            </CCol>
            <CCol sm="6">                
              <CInput
                :label="$t('label.order')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.order')"
                v-model.trim="$v.Orden.$model"
                :is-valid="hasError($v.Orden)"
                @keypress="f_num($event)"
                addLabelClasses="text-right"
                class="ml-1"
                :value="Orden"
                :invalid-feedback="$t('label.required')+$t('label.onlyNumber')"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">                
              <CInput
                :label="$t('label.description')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.interfaceAction')"
                v-model.trim="$v.ModuleDs.$model"
                :is-valid="hasError($v.ModuleDs)"
                addLabelClasses="text-right"
                class="ml-1"  
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                :value="ModuleDs"
                size="sm"
              />
            </CCol>
            <CCol sm="6">                
              <CSelect
                :label="$t('label.status')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                :options="statusOptions"
                :value.sync="Status"
                addLabelClasses="text-right"
                :is-valid="statusSelectColor"
                size="sm"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInputFile
                :label="$t('label.proceduralManual')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.proceduralManual')"
                ref="MPPath"
                @change="addfilePathMP"
                addLabelClasses="text-right"
                class="ml-1"
                id="ref-input-proceess"
                size="sm"
              />
              <span style="margin-left:64px;">{{$t('label.toView')}}:</span>
              <CLink :href="ProcessRouteLink">{{ this.RPName }}</CLink>
            </CCol>
            <CCol sm="6">
              <CInputFile
                :label="$t('label.userManual')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.userManual')"
                ref="MUPath"
                @change="addfilePathMU"
                addLabelClasses="text-right"
                id="ref-input-manual"
                size="sm"
              />
              <span style="margin-left:64px;">{{$t('label.toView')}}:</span>
              <CLink :href="ManualRouteLink">{{ this.RMName }}</CLink>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mt-2">                
              <CInput
                :label="$t('label.location')"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.exampleLocation')"
                v-model="$v.ModuleRoute.$model"
                :is-valid="hasError($v.ModuleRoute)"
                addLabelClasses="text-right"
                class="ml-1"
                :invalid-feedback="$t('label.required')"
                size="sm"
              />
            </CCol>
            <CCol sm="6" class="mt-2">                
              <CInput
                :label="$t('label.accessRoute')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-5'}"
                :placeholder="$t('label.exampleRoute')"
                v-model="$v.pathAuxiliar.$model"
                :is-valid="hasError($v.pathAuxiliar)"
                addLabelClasses="text-right"
                :invalid-feedback="$t('label.required')"
                size="sm"
              />
            </CCol>
          </CRow>
        </CCardBody>

        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            type="submit"
          >
            <CIcon name="checkAlt" /><span class="ml-1">{{$t('button.accept')}}</span>
          </CButton>
          <CButton
            square
            color="wipe"
            class="d-flex align-items-center"
            @click="toggle(false)"
          >
            <CIcon name="x" /><span class="ml-1"
              >{{$t('button.cancel')}}</span
            >
          </CButton>
        </template>
      </CModalExtended>
    </form>
  </main>
</template>
<script>
import ModuleMixin from '@/_mixins/module';
import GeneralMixin from '@/_mixins/general';
import { onlyMayusTextEspecial } from '@/_validations/validacionEspeciales';
import UpperCase from '@/_validations/uppercase-directive';
import addTpModuleCollapse from './add-modulo-collapse';
import {
  required,
  maxLength
} from 'vuelidate/lib/validators';
import ModalMixin from '@/_mixins/modal';

function toggle(newVal) {
  this.modalActive = newVal;
  this.resetBody();
}

function data() {
  return {
    responseSendFile: {},
    ProcessRouteLink: '',
    ManualRouteLink: '',
    GpoModuleIdNivel2: '',
    modalActive: this.modal,
    GpoModuleId: '',
    TpModuleId: '',
    ModuleNameEs: '',
    ModuleNameEn: '',
    moduleSelectedId: '',
    ModuleDs: '',
    ProcessRoute: '',
    RPName: '',
    ManualRoute: '',
    ProcessId: '',
    DirectoryId: '',
    routeDirectoryName: '',
    selectProcessBusiness: [],
    selectMasterDirectory: [],
    listNivelI: [],
    RMName: '',
    ModuleRoute: '',
    Orden: '',
    Status: '',
    selectListLv1: [],
    selectListLv2: [],
    selectTpModuloData: [],
    nuevoManualProcedimiento: false,
    nuevoMnaualUsuario: false,
    newProcessRoute: '',
    newManualProccess: '',    
    loadingTM: true,
    listLv3Actually: [],
    Loading: false,
    pathAuxiliar: '',
    collapse: false,
    selectedModuleLevel2: '',
  };
}

//const API_URL_FILES = `${this.$store.getters["connection/getBase"]}`;

const defaultModuleData = {
  GpoModuleId: '',
  TpModuleId: '',
  ModuleNameEs: '',
  ModuleNameEn: '',
  ModuleDs: '',
  ProcessRoute: '',
  ManualRoute: '',
  ModuleRoute: '',
  Orden: '',
  Status: '',
};

function addfilePathMP(files) {
  this.nuevoManualProcedimiento = true;
  const file = files[0];
  this.ProcessRoute = file; // Aqui iria el path para guardar la ruta del archivo.
}

function addfilePathMU(files) {
  this.nuevoMnaualUsuario = true;
  const file = files[0];
  this.ManualRoute = file; // Aqui iria el path para guardar la ruta del archivo.
}

function computedTitle() {
  let _lang = this.$i18n.locale;
  let title = _lang == 'en' ? this.ModuleNameEn : this.ModuleNameEs;
  return this.$t('label.edit')+' '+this.$t('label.module')+': '+ title;
}

function insertTp(event) {
  return (this.TpModuleId = event.target.value);
}

function insertLv2(event) {
  let ModuleId = event.target.value;
  // this.$http
  //   .get(`Module-list?GpoModuleId=${ModuleId}`).then((response) => {
  //   this.listLv3Actually = response.data.data;
  //   this.computedOrden(this.listLv3Actually);
  // });
  return (this.GpoModuleId = ModuleId);
}

function insertTpModulo(event) {
  return (this.TpModuleId = event.target.value);
}

function formattedSelectLv1() {
  return this.listNivelI.map((level) =>
    Object.assign({}, this.selectListLv1, {
      value: level.GpoModuleId,
      label: level.GpoModuleName,
    })
  );
}

function formattedSelectLv2() {
  return this.selectListLv2.map((level) =>
    Object.assign({}, this.selectListLv2, {
      value: level.GpoModuleId,
      label: level.GpoModuleName,
    })
  );
}

function formattedTpModule() {
  return this.selectTpModuloData.map((item) =>
    Object.assign({}, this.selectTpModuloData, {
      value: item.TpModuleId,
      label: item.TpModuleName,
    })
  );
}

function valueSelectLv1() {
  if (
    Object.keys(this.moduleData).length !== 0 &&
    this.moduleData.GpoModuleParentId
  ) {
    const data = this.listNivelI.find(
      (val) => val.GpoModuleId === this.moduleData.GpoModuleParentId
    );

    if (data && data.GpoModuleChildJson && data.GpoModuleChildJson !== null) {
      this.selectListLv2 = data.GpoModuleChildJson;
    }

    if (data !== undefined) {
      return data.GpoModuleId;
    }
  }
}

function valueSelectLv2() {
  if (
    Object.keys(this.moduleData).length !== 0 &&
    this.moduleData.GpoModuleParentId
  ) {
    const level1 = this.listNivelI.find(
      (val) => val.GpoModuleId === this.moduleData.GpoModuleParentId
    );

    const dataJsonChild = level1 ? level1.GpoModuleChildJson : [];
    const data = dataJsonChild.find(
      (val) => val.GpoModuleId === this.moduleData.GpoModuleId
    );

    if (data != undefined) {
      this.GpoModuleIdNivel2 = data.GpoModuleId;
      return data.GpoModuleId;
    }
  }
}

function valueTpModule() {
  if (this.moduleData.TpModuleId) {
    return this.moduleData.TpModuleId;
  }
}

async function insertTpModuleData() {
  this.loadingTM = true;
  await this.$http
    .get('TpModule-list?Filter=ACTIVO')
    .then((response) => {
      this.selectTpModuloData = response.data.data;
      this.loadingTM = false;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
  await this.mountModules();
  await this.insertProcessBusiness();
  await this.insertMasterDirectory();
}

    
function mountModules() {
  this.Loading = true;
  this.$http
    .get('GpoModule-list', { TpModuleId:this.TpModuleFilterSelect,TpModuleNavegationId:this.NavigationFilterSelect, GpoModuleOrigId: ''})
    
    .then((response) => {
      const data = response.data;
      const moduleNivelI = data.data;

      this.listNivelI = moduleNivelI;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

async function insertNivelII(event) {
  try {
    let nivelI = this.listNivelI.find(
      (nivelI) => nivelI.GpoModuleId === event.target.value
    );
    this.selectListLv2 = nivelI.GpoModuleChildJson;
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

function inserDataModuleSelected(data) {
  this.GpoModuleId = data.GpoModuleId;
  this.ModuleDs = data.ModuleDs;
  this.TpModuleId = data.TpModuleId;
  this.ModuleNameEs = data.ModuleNameEs;
  this.ModuleNameEn = data.ModuleNameEn;
  this.moduleSelectedId = data.ModuleId;
  this.ProcessRoute = data.ProcessRoute;
  this.ProcessRouteLink =
    data.ProcessRoute !== null
      ? `${this.$store.getters["connection/getBase"]}` + data.ProcessRoute.replace('public', '')
      : '';
  this.RPName =
    data.ProcessRoute !== null && data.ProcessRoute.length > 15
      ? data.ProcessRoute.replace('public', '').slice(15)
      : data.ProcessRoute || '';
  this.ManualRoute = data.ManualRoute;
  this.ManualRouteLink =
    data.ManualRoute !== null
      ? `${this.$store.getters["connection/getBase"]}` + data.ManualRoute.replace('public', '')
      : '';
  this.RMName =
    data.ManualRoute !== null && data.ManualRoute.length > 14
      ? data.ManualRoute.slice(14)
      : data.ManualRoute || '';
  this.ModuleRoute = data.Path;
  this.Orden = data.Order;
  this.Status = data.Status === 'ACTIVO' ? 1 : 0;
  this.DirectoryId = data.DirectoryId;
  this.ProcessId = data.ProcessId;
  this.selecDirectory(data.DirectoryId);
  this.pathAuxiliar = data.ModuleRoute;

  this.$v.$touch();
}

async function sendDataFile() {
  if (this.nuevoManualProcedimiento && this.nuevoMnaualUsuario) {
    let formData = new FormData();
    formData.append('file1', this.ProcessRoute);
    formData.append('file2', this.ManualRoute);

    await this.$http
      .post(this.routeDirectoryName, formData)
      .then((response) => {
        this.responseSendFile = response;
        this.newProcessRoute = response.data.data.files[0].path;
        this.newManualProccess = response.data.data.files[1].path;
      })
      .catch((e) => {
        return (this.Loading = false);
      });
  }

  if (this.nuevoManualProcedimiento && !this.nuevoMnaualUsuario) {
    let formData = new FormData();
    formData.append('file1', this.ProcessRoute);
    await this.$http
      .post(this.routeDirectoryName, formData)
      .then((response) => {
        this.responseSendFile = response;
        this.newProcessRoute = response.data.data.files[0].path;
      })
      .catch((e) => {
        return (this.Loading = false);
      });
  }
  if (!this.nuevoManualProcedimiento && this.nuevoMnaualUsuario) {
    let formData = new FormData();
    formData.append('file1', this.ManualRoute);
    await this.$http
      .post(this.routeDirectoryName, formData)
      .then((response) => {
        this.responseSendFile = response;
        this.newManualProccess = response.data.data.files[0].path;
      })
      .catch((e) => {
        return (this.Loading = false);
      });
  }
}

function sumbittedBody() {
  this.collapse = false;
  if (this.Status == 0 || this.Status == 'INACTIVO') {
     this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')}`,
          }))
      .then((result) => {
        if (result.isConfirmed) {
          this.submitBody();
        }
      });
  } else {
    this.submitBody();
  }
}

function resetData() {
  this.moduleSelectedId = '';
  this.TpModuleId = '';
  this.GpoModuleId = '';
  this.DirectoryId = '';
  this.ProcessId = '';
  this.ModuleNameEs = '';
  this.ModuleNameEn = '';
  this.ModuleDs = '';
  this.nuevoManualProcedimiento = false;
  this.nuevoMnaualUsuario = false;
  this.newProcessRoute = '';
  this.ManualRoute = '';
  this.newManualProccess = '';
  this.ManualRoute = '';
  this.ModuleRoute = '';
  this.Statu = '';
  this.Orden = '';
}

async function submitBody() {
  this.$v.$touch();
  const message = 'Faltan campos obligatorios';
  this.Loading = true;

  if (this.$v.$invalid) {
    this.Loading = false;
    throw message;
  }

  if (this.ProcessRoute !== '' || this.ManualRoute) {
    await this.sendDataFile();
  }

  if (
    this.responseSendFile.status === (200 || 201) ||
    !this.nuevoMnaualUsuario ||
    !this.nuevoMnaualUsuario
  ) {
    let ModuleJson = {
      ModuleId: this.moduleSelectedId,
      TpModuleId: this.TpModuleId,
      GpoModuleId: this.GpoModuleId,
      DirectoryId: this.DirectoryId,
      ProcessId: this.ProcessId,
      ModuleNameEs: this.ModuleNameEs,
      ModuleNameEn: this.ModuleNameEn,
      ModuleDs: this.ModuleDs,
      ProcessRoute: this.nuevoManualProcedimiento
        ? this.newProcessRoute
        : this.ProcessRoute,
      ManualRoute: this.nuevoMnaualUsuario
        ? this.newManualProccess
        : this.ManualRoute,
      ModuleRoute: this.ModuleRoute,
      Status: this.Status,
      Order: this.Orden,
      Path: this.pathAuxiliar,
    };

    this.Loading = false;
    //this.resetData();
    this.toggle(false);

    this.$http
      .put('Module-update', ModuleJson, { root: 'ModuleJson' })
      .then((response) => {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('edit-module-selected', true);
        //this.$emit('edit-module-selected', moduleObjectNew);
        this.resetBody();
        this.toggle(false);
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e.response.data['error-codes'],
          type: 'error',
        });
      });
  } else {
    this.Loading = false;
  }
}

function resetBody() {
  this.TpModuleId = '';
  this.GpoModuleId = '';
  this.ModuleNameEs = '';
  this.ModuleNameEn = '';
  this.ModuleDs = '';
  this.ProcessRoute = '';
  this.ManualRoute = '';
  this.$refs.MPPath.value = '';
  this.$refs.MUPath.value = '';
  this.ModuleRoute = '';
  this.Orden = '';
  this.pathAuxiliar = '';
  this.ProcessId = '';
  this.DirectoryId = '';
  document.getElementById('ref-input-proceess').value = '';
  document.getElementById('ref-input-manual').value = '';
}

function computedOrden(val) {
  if (this.listlv3.length > 0) {
    this.listLv3Actually = val;
    this.Orden = this.listLv3Actually.length + 1;
  } else {
    this.Orden = 1;
  }
}

function validateOrderingExists(orden, vm) {
  if (vm.listLv3Actually.length > 0) {
    if (orden != 0) {
      return !vm.listLv3Actually.filter(
        (val) => val.Orden == orden && vm.GpoModuleId != val.GpoModuleId
      ).length;
    }
  }

  if (orden != 0) {
    return 1;
  } else {
    return 0;
  }
}

function insertLv2Data(data) {
  const level1 = this.listNivelI.find(
    (val) => val.GpoModuleId === data.GpoModuleIdPadre
  );
  this.selectListLv2 = level1.GpoModuleIdHijo;
}

function processBusiness() {
  return this.selectProcessBusiness.map((processBusiness) =>
    Object.assign({}, this.selectProcessBusiness, {
      value: processBusiness.ProcessId,
      label: processBusiness.ProcessName,
    })
  );
}

function valueProcessBusinness() {
  if (this.moduleData.ProcessId) {
    return this.moduleData.ProcessId;
  }
}

function valueMasterDirectory() {
  if (this.moduleData.DirectoryId) {
    return this.moduleData.DirectoryId;
  }
}

function insertProcessBusiness() {
  this.$http
    .get('ProcessBusiness-list?Filter=ACTIVO')
    .then((response) => {
      this.selectProcessBusiness = response.data.data;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function insertPB() {
  return (this.ProcessId = event.target.value);
}

function insertMD() {
  this.selecDirectory(event.target.value);
  return (this.DirectoryId = event.target.value);
}

function insertMasterDirectory() {
  this.$http
    .get('MasterDirectory-list')
    .then((response) => {
      this.selectMasterDirectory = response.data.data;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function updateCollapse() {
  this.collapse = false;
}

function masterDirectory() {
  return this.selectMasterDirectory.map((masterDirectory) =>
    Object.assign({}, this.selectMasterDirectory, {
      value: masterDirectory.DirectoryId,
      label: masterDirectory.RouteDirectory,
    })
  );
}

async function selecDirectory(item) {
  const selectDirectory = await this.selectMasterDirectory.find(
    (element) => element.DirectoryId === item
  );
  this.routeDirectoryName = selectDirectory
    ? selectDirectory.RouteDirectory
    : '';
}
function statusSelectColor() {
  return this.Status === 1;
}
//computed
function cambiaIcono() {
  return this.iconoCollapse = (this.collapse) ? 'minus' : 'cil-plus';
}                
export default {
  name: 'edit-module-modal',
  props: {
    modal: Boolean,
    listlv3: Array,
    moduleData: {
      type: Object,
      default: () => {
        return Object.assign({}, defaultModuleData);
      },
    },
    TpModuleFilterSelect: String,
    NavigationFilterSelect: String
  },
  data,
  mixins: [ModuleMixin, GeneralMixin,ModalMixin],
  directives: UpperCase,
  methods: {
    toggle,    
    inserDataModuleSelected,
    submitBody,
    resetBody,
    insertTp,
    insertLv2,
    insertNivelII,
    insertLv2Data,
    insertTpModulo,
    computedOrden,
    sumbittedBody,
    mountModules,
    insertProcessBusiness,
    insertMasterDirectory,
    selecDirectory,
    statusSelectColor,
    insertPB,
    insertMD,
    addfilePathMP,
    addfilePathMU,
    sendDataFile,
    resetData,
    updateCollapse,
    insertTpModuleData,
  },
  validations: {
    ModuleNameEs: { required, maxLength: maxLength(50), onlyMayusTextEspecial },
    ModuleNameEn: { required, maxLength: maxLength(50), onlyMayusTextEspecial },
    ModuleDs: { required, maxLength: maxLength(100), onlyMayusTextEspecial },
    Orden: { required, validateOrderingExists, maxLength: maxLength(2) },
    GpoModuleId: { required },
    TpModuleId: { required },
    ModuleRoute: { required },
    Status: { required },
    // ProcessRoute: { required },
    // ManualRoute: { required },
    ProcessId: { required },
    DirectoryId: { required },
    GpoModuleIdNivel2: { required },
    pathAuxiliar: { required },
  },
  watch: {
    modal: async function(val) {
      this.toggle(val);
      if (val === true) {
        await this.insertTpModuleData();
      }
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    moduleData: function(item) {
      if (Object.keys(item).length !== 0) {
        this.inserDataModuleSelected(item);
      }
    },
    listlv3: function(val) {
      this.computedOrden(val);
    },
  },
  computed: {
    formattedSelectLv1,
    formattedSelectLv2,
    valueSelectLv1,
    valueSelectLv2,
    valueTpModule,
    formattedTpModule,
    computedTitle,
    processBusiness,
    cambiaIcono,
    masterDirectory,
    valueProcessBusinness,
    valueMasterDirectory,
  },
  //created: insertTpModuleData,
  components: {
    addTpModuleCollapse,
  },
};
</script>
<style lang="scss">
.buttonClassModule{
  height: 90% !important;
  position: relative;
}
</style>
