import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { onlyAlphanumeric } from '@/_validations/validacionEspeciales';
export default () => {
  return {
    cancelacion: {
      movimientoId: { required },
      transactionId: { required },
      CancellationReasonEs: { required, onlyAlphanumeric, maxLength: maxLength(100) },
      CancellationReasonEn: { required, onlyAlphanumeric, maxLength: maxLength(100) },
    },
   
  }
}