
  
  export const request = {
    namespaced: true,
    state: {
      pendings:[],
      responsePendings:[],
    },
    mutations: {
     
    }
  }