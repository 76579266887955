import { required, minLength,maxLength } from "vuelidate/lib/validators";
import { onlyNumber, onlyText, nameLength, onlyAlphaguion, siglasAlphanumeric} from '@/_validations/validacionEspeciales';

export const StowagePlanningImplement = () => {
    return {
        ClientTpId: { required },
        ImplementAlias: { required,maxLength: maxLength(8) },
        UserId: {  },
        Status: {  },
        ScaleWeight: { },
        FgStopOperation: {  },
    }
}

export const StowagePlanningFile = () => {
    return  {
        ClientTpId: { required },
        formArchivos :{
            description: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(500)
            },
        }
    }
}

const correctDate = (value) => {
    if (value === '') {
        return true;
    } else {
        let i = value.indexOf('-');
        let j = value.indexOf('/');
        if (i !== -1) {
            let date = value.split('-' || '/');
            let fecha = new Date();
            let ano = fecha.getFullYear();
            ano = parseInt(ano) + 10;
            if (date.length === 3) {
                if (
                    (date[0].length > 0 && date[0].length < 4) ||
                    (date[2].length > 0 && date[2].length < 4)
                ) {
                    return true;
                } else if (
                    (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
                    (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
                ) {
                    return true;
                } else if (
                    parseInt(date[1]).length > 0 &&
                    parseInt(date[1]).length < 12
                ) {
                    return true;
                } else if (
                    (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
                    (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (j !== -1) {
            let date = value.split('/');
            let fecha = new Date();
            let ano = fecha.getFullYear();
            ano = parseInt(ano) + 10;
            if (date.length === 3) {
            if (
                (date[0].length > 0 && date[0].length < 4) ||
                (date[2].length > 0 && date[2].length < 4)
            ) {
                return true;
            } else if (
                (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
                (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
            ) {
                return true;
            } else if (
                parseInt(date[1]).length > 0 &&
                parseInt(date[1]).length < 12
            ) {
                return true;
            } else if (
                (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
                (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
            ) {
                return true;
            } else {
                return false;
            }
            }
        } else {
            return false;
        }
    }
};

export const choferes = () => {
    return {
        ClientTpId: { required },
        Status: { required },
        DriverName: { required, onlyAlphaguion, maxLength: maxLength(50), nameLength },
        DriverCi: { required, maxLength: maxLength(8), minLength: minLength(7), onlyNumber },
        LicenseExpirationDate: { correctDate },
        DriverLicense: { maxLength: maxLength(50) },
    };
};

const correctValue = (value) => {
    if (value === 'N/A') {
        return false;
    } else {
        return true;
    }
};

export const vehiculo = () => {
    return {
        ClientTpId: { required },
        Status: { required },
        VehicleClassifId: { },
        BrandId: { },
        ModelId: {  },
        Year: {  },
        Color: { onlyText },
        LicensePlate: { required,maxLength: maxLength(10),siglasAlphanumeric },
    }
}

export const asignacion = (FgYard) => {
    
    let Yard = false;
    if(FgYard)  { Yard = { required } }

    return {
        StowagePlanningImplementId: { required },
        StowagePlanningDriverId: {  },
        StowagePlanningVehicleId: { required },
        StowagePlanningResourceId: { required },
        YardId: {  },
        ClientName: Yard,
        ClientTpId: Yard,
        BillOfLadingYardId: Yard,
        Status: { required },
    }
}

export const gamela = () => {
    return {
        ClientTpId: { required },
        ImplementAlias: { required, siglasAlphanumeric },
        ImplementId: {  },
        Status: { required },
    }
}