<template>
    <div>
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CModal
        :title="titleModal"
        color="dark"
        size="lg"
        class="modal-extended"
        @update:show="closeModal()"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
        <CRow>
          <CCol sm="12">
            <CTextarea
              :label="$t('label.motive')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              :placeholder="$t('label.Description')"
              v-uppercase
              v-model.trim="Motive"
            />
          </CCol>
        </CRow>
        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="submit()"
            :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
          <CButton 
            color="wipe" 
            class="d-flex align-items-center"
            @click="closeModal()"
          >
            <CIcon name="x" /><span class="ml-1"
              >{{$t('button.cancel')}}</span
            >
          </CButton>
        </template>
      </CModal>
    </div>
</template>
<script>
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import { required, maxLength } from 'vuelidate/lib/validators';
  
  function data() {
    return {
      Motive: '',
      modalActive: false,
      loadingOverlay: false,
      isSubmit: false,
    };
  }

  function submit() {
    try{
      /*this.loadingOverlay = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      let LogisticJson = {
        StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
        Observation: this.Observation,
      }
      this.$http
        .put('StowagePlanningLogisticWeigthOrder-Deactivate', LogisticJson, { root: 'LogisticJson' })
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.closeModal();
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: messageSuccess,
              type: "success"
            });
          } 
        }).catch((e) => {
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: "container",
              title: "¡Error!",
              text: e,
              type: "error",
            });
          });*/
    }catch(e){
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: e,
        type: "error",
      });
    }
  }
  
  function closeModal() {
    this.Motive = '';
    this.$emit('close-Modal');
  }
  
  function titleModal(){
    return this.$t('label.reverse')+' '+this.$t('label.TransportCertification');
  }
  
  export default {
    name: 'modal-transport-certification-reverse',
    props: { modal: Boolean },
    data,
    mixins: [
      GeneralMixin, 
    ],
    validations: {},
    directives: UpperCase,
    methods: {
      submit,
      closeModal,
    },
    computed: {
      titleModal,
    },
    watch: {
      modal: async function(val){
        this.modalActive = val;
        if (val) {

        }
      },
    },
    
  };
</script>