
<script>
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(ChartDataLabels);
export default {
    name: '',
    type: '',
    props: {
        datasets: Array,
        labels: [Array, String],
        options: Object,
        plugins: Array,
        type: String,
        title: {
            type: String,
            required: false,
            default: 'Default tittle',
        },
        minX: {
            type: Number,
            required: false,
            default: 8,
        },
        height: {
            type: String,
            required: false,
            default: '400px',
        },
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    data () {
        return {
            chart: undefined
        }
    },
    watch: {
        chartConfig () {
            this.updateChart()
        }
    },
    mounted () {
        this.renderChart()
    },
    beforeDestroy () {
        this.destroyChart()
    },
    computed: {
        safeId () {
            // as long as this._uid() works there is no need to generate the key
            const key = () => Math.random().toString(36).replace('0.', '')
            return '__safe_id__' + (this._uid || key())
        },
        computedHeight() {
            return this.height ? this.height : '30vh'
        },
        computedtype () {
            return this.type ? this.type : 'bar'
        },
        computedDatasets () {
            return this.datasets
        },
        computedLabels () {
            if (this.labels && typeof this.labels !== 'string') {
                return this.labels
            } else if (!this.datasets || !this.datasets[0] || !this.datasets[0].data) {
                return []
            }
            const emptyLabels = Array(this.datasets[0].data.length).fill('')
            if (this.labels === 'indexes') {
                return emptyLabels.map((u, i) => i + 1)
            } else if (this.labels === 'months') {
                return emptyLabels.map((u, i) => this.$options.months[i % 12])
            }
            return emptyLabels
        },
        computedData () {
            return {
                datasets: this.computedDatasets,
                labels: this.computedLabels
            }
        },
        computedOptions () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: this.$t('label.Day')+' : '+ this.title
                    },
                    datalabels: {
                        color: '#fff',
                        display: true,
                        formatter: function(value, context) {
                            if(value === 0 || value === "0" || value === ""){
                                return ""
                            }else if(value === "0.5" || value === 0.5){
                                return 0;
                            }else{
                                return value;
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.x !== null) {
                                    if(context.parsed.x == "0.5" || context.parsed.x == 0.5){
                                        label += "0";
                                    }else{
                                        label += context.parsed.x;
                                    }
                                    
                                }
                                return label;
                            }
                        }
                    }
                },
                indexAxis: 'y',
                scales: {
                    x: {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: this.$t('label.TotalMovements')
                        },
                        suggestedMax: this.minX
                    },
                    y: {
                        stacked: true
                    }
                },
            }
        },
        chartConfig () {
            return {
                type: this.computedtype,
                data: this.computedData,
                options: this.computedOptions,
            }
        }
    },
    methods: {
        renderChart () {
            this.destroyChart()
            this.chart = new Chart(
                this.$refs.canvas.getContext('2d'),
                this.chartConfig
            );
        },
        updateChart () {
            Object.assign(this.chart, this.chartConfig)
            this.chart.update()
        },
        destroyChart () {
            if (this.chart) {
                this.chart.destroy()
            }
        }
    },
    render(h) {
        return h(
            'div',{
                style: {
                    height: this.height
                },
            },
            [
                h(
                    'canvas', {
                        attrs: {
                            id: this.safeId,
                        },
                        style: {
                            height: this.height
                        },
                        ref: 'canvas',
                    }
                )
            ]
        )
    }
}
</script>