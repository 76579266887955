import XLSX from 'xlsx';
import moment from 'moment';

const DateFormater = {
  /*************************************
    RECIBE FORMATO: YYYY-MM-DD HH:mm:ss
    RETORNA FORMATO: DD/MM/YYYY HH:mm
  **************************************/
  formatDateTimeWithSlash(dateTime) {
    return moment(dateTime,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
  },

  /**********************************
    RECIBE FORMATO: YYYY-MM-DD HH:mm
    RETORNA FORMATO: YYYY-MM-DD
  ***********************************/
  formatDateWithoutSlash(dateTime) {
    return moment(dateTime,'YYYY-MM-DD HH:mm').format("YYYY-MM-DD");
  },

  /*************************************
    RECIBE FORMATO: YYYY-MM-DD HH:mm
    RETORNA FORMATO: DD/MM/YYYY
  **************************************/
  formatOnlyDateWithSlash(dateTime) {
    return moment(dateTime,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY');
  },

  /*****************************
    RECIBE FORMATO: YYYY-MM-DD
    RETORNA FORMATO: DD/MM/YYYY
  ******************************/
  formatDateWithSlash(date) {
    return moment(date,'YYYY-MM-DD').format('DD/MM/YYYY');
  },

  /*************************************
    RECIBE FORMATO: DD/MM/YYYY HH:mm
    RETORNA FORMATO: YYYY-MM-DD HH:mm
  **************************************/
  formatDateTimeWithoutSlash(dateTime) {
    return moment(dateTime,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
  },

  /*************************************
    RECIBE FORMATO: DD/MM/YYYY HH:mm
    RETORNA FORMATO: DD/MM/YYYY
  **************************************/
  formatDateTimeWithSlashToOnlyDate(dateTime){
    return moment(dateTime,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
  },

  /*************************************
    RECIBE FORMATO: DD/MM/YYYY HH:mm
    RETORNA FORMATO: HH:mm
  **************************************/
  formatDateTimeWithSlashToOnlyTime(dateTime){
    return moment(dateTime,'DD/MM/YYYY HH:mm').format('HH:mm');
  },

  /***************************************************
    RECIBE FORMATO: Thu Dec 16 2021 05:11:42 GMT-1000
    RETORNA FORMATO: DD/MM/YYYY HH:mm
  ****************************************************/
  formatTimeZoneToDateTime(dateTime){
    return moment(dateTime).format('DD/MM/YYYY HH:mm');
  },

  /***************************************************
    RECIBE FORMATO: Thu Dec 16 2021 05:11:42 GMT-1000
    RETORNA FORMATO: YYYY-MM-DD HH:mm
  ****************************************************/
  formatTimeZoneToDateTimewithDash(dateTime){
    return moment(dateTime).format('YYYY-MM-DD HH:mm');
  },

  /******************************************
    RECIBE FECHAS FORMATO: DD/MM/YYYY
    RECIBE HORAS FORMATO: HH:mm
    RETORNA TOTAL DE HORAS EN FORMATO: HH:mm
  *******************************************/
  datesDifferenceInHours(dateStart, dateEnd, hourStart, hourEnd){
    let arrDateStart = dateStart.split("/");
    let arrDateEnd = dateEnd.split("/");

    let fecha1 = new Date(arrDateStart[1]+'/'+arrDateStart[0]+'/'+arrDateStart[2]+' '+hourStart);
    let fecha2 = new Date(arrDateEnd[1]+'/'+arrDateEnd[0]+'/'+arrDateEnd[2]+' '+hourEnd);

    let diferencia = fecha1.getTime() - fecha2.getTime();
    let timeTotal = Math.abs(diferencia/1000/3600);
    let horas = Math.trunc(timeTotal); 
    let minutos = Math.round((timeTotal - horas)*60);
    
    if(minutos < 10)
      minutos = '0'+minutos;

    if(horas < 10)
      horas = '0'+horas;

    return `${horas}:${minutos}`;
  },

  /***********************************************************************
    RECIBE FORMATO: YYYY-MM-DD HH:mm
    RETORNA FORMATO: Tue Sep 20 2022 06:02:00 GMT-0400 (hora de Venezuela)
  ************************************************************************/
  formatDateRange(dateTime) {
    const dateRangeValue = moment(moment(dateTime,'YYYY-MM-DD HH:mm').format('DD/MM/YYYY') ,'DD/MM/YYYY').toDate();
    return dateRangeValue;
  },

  /***********************************************************************
    RECIBE FORMATO: DD/MM/YYYY HH:mm
    RETORNA FORMATO: Tue Sep 20 2022 06:02:00 GMT-0400 (hora de Venezuela)
  ************************************************************************/
  formatDateRangeWithSlash(dateTime) {
    const dateRangeValue = moment(dateTime, 'DD/MM/YYYY HH:mm').toDate();
    return dateRangeValue;
  },
  /*****************************
    RECIBE FORMATO: HH:mm:ss
    RETORNA FORMATO: HH:mm
  ******************************/
    formatOnlyTime(time) {
      return moment(time,'HH:mm:ss').format('HH:mm');
    },
  /***********funciones originales start**********/
  
  toNormalDate(date, separator) {
    let timezone_offset_minutes = new Date(date).getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
    let horas = timezone_offset_minutes / 60;
    let nuevo = new Date(date);

    if (horas < 0) nuevo.setHours(nuevo.getHours() + (horas * -1));
    let newDay = new Date(nuevo),
      day = ('0' + newDay.getDate()).slice(-2),
      month = ('0' + (newDay.getMonth() + 1)).slice(-2),
      year = newDay.getFullYear();

    separator = separator ? separator : '/';

    return `${day}${separator}${month}${separator}${year}`;
  },

  /*situcion operativa*/
  toNormalDateTime(date, separator) {
    let timezone_offset_minutes = new Date(date).getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
    let horas = timezone_offset_minutes / 60;
    let nuevo = new Date(date);

    if (horas < 0) nuevo.setHours(nuevo.getHours() + (horas * -1));
    let newDay = new Date(nuevo),
      day = ('0' + newDay.getDate()).slice(-2),
      month = ('0' + (newDay.getMonth() + 1)).slice(-2),
      year = newDay.getFullYear(),
      hour = ("0" + (newDay.getHours())).slice(-2),
      min = ("0" + (newDay.getMinutes())).slice(-2);

    separator = separator ? separator : '/';

    return `${day}${separator}${month}${separator}${year} ${hour}:${min}`;
  },

/*situcion operativa*/
  retornarFecha(nuevo, separator) {
    let newDay = new Date(nuevo),
      day = ('0' + newDay.getDate()).slice(-2),
      month = ('0' + (newDay.getMonth() + 1)).slice(-2),
      year = newDay.getFullYear(),
      hour = ("0" + (newDay.getHours())).slice(-2),
      min = ("0" + (newDay.getMinutes())).slice(-2);

    separator = separator ? separator : '/';

    return `${day}${separator}${month}${separator}${year} ${hour}:${min}`;
  },
  /*situcion operativa*/
  //**** Para fecha en formato: 2021-11-16T10:54:00.770 A 16/11/2021 10:54
  formatToNormalDateTime(date, separator) {
    let arrDate = date.split(/[- :T]/);

    let day = arrDate[2],
      month = arrDate[1],
      year = arrDate[0],
      hour = arrDate[3],
      min = arrDate[4];
    separator = separator ? separator : '/';

    return `${day}${separator}${month}${separator}${year} ${hour}:${min}`;
  },
  /***********funciones originales end************/

  toLongDate(date) {
    let meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");

    return `${date.getDate()} de ${meses[date.getMonth()]} de ${date.getFullYear()}`;
  },
  
}

const NumberFormater ={
  //para cambiar el formato 100000.45 a 100.000,45 - decimal = cantidad de decimales
  formatNumber(number, decimal = 0) {
    if (!number){
      let Default = Number.parseFloat(0).toFixed(decimal)
      Default = Default.replace(".", ",");
      return Default.replace(/\d(?=(\d{3})+\,)/g, "$&.");
    }
    let format = Number.parseFloat(number).toFixed(decimal);
    format = format.replace(".", ",");

    return format.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$&.");
  },

  //para cambiar el formato 100.000,45 a 100000.45
  setNum(Num) {
    if(Num){
      if (!isNaN(Num)) {
        return Num;
      }else{
        return Number.parseFloat(Num.replace(/[.]/g, '')?.replace(',', '.'));
      }
    }else{
      return 0;
    }
  },
}

const GenerateExcel = (jsonArray = [], name) => {
  if (Array.isArray(jsonArray)) {
    let fileName = name ? name : '';
    let worksheet = XLSX.utils.json_to_sheet(jsonArray);
    let workbook = XLSX.utils.book_new();
    let today = DateFormater.formatOnlyDateWithSlash(new Date());
    let time = new Date().getTime();

    XLSX.utils.book_append_sheet(workbook, worksheet, `${fileName}`);
    XLSX.writeFile(workbook, `${fileName}_${today}_${time}.xlsx`);
  } else {
    console.log('Error: Solo se aceptan Arrays como parametro.');
  }
}

function GenerateImgPlaceholder(text = 'N/A', width = 200, height = 200, colorTxt = '#4f5d73', colorBg = '#ebedef') {
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  canvas.width = height >= 100 ? height : 100;
  canvas.height = width = 100 ? width : 100;

  context.fillStyle = colorBg;
  context.fill();

  context.font = (canvas.height / 4) + "px Arial";
  context.fillStyle = colorTxt;

  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL();
}

function GenerateSvgPlaceholder(options = {}) {
  let defaults = {
    width: 200,
    height: 150,
    bgColor: '#ebedef',
    textColor: '#4f5d73',
    fontFamily: 'Consolas',
    fontSize: 30,
    dy: 10.5,
    fontWeight: 'bold',
    text: 'NO IMG',
    textAnchor: 'middle',
  };
  options = {
    ...defaults,
    ...options
  };

  let posX = '50%';
  if (options.textAnchor != 'middle') {
    posX = options.textAnchor == 'start' ?
      '0' :
      '100%';
  }

  let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${options.width}" height="${options.height}" viewBox="0 0 ${options.width} ${options.height}">
  <rect fill="${options.bgColor}" width="${options.width}" height="${options.height}"/>
  <text fill="${options.textColor}" font-family="${options.fontFamily}" font-size="${options.fontSize}" dy="${options.dy}" font-weight="${options.fontWeight}" x="${posX}" y="50%" text-anchor="${options.textAnchor}">${options.text}</text>
</svg>`;

  const cleaned = svg
    .replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
    .replace(/\s\s+/g, ' ') // Condense multiple spaces
    .replace(/'/gim, '\\i'); // Normalize quotes

  const encoded = encodeURIComponent(cleaned)
    .replace(/\(/g, '%28') // Encode brackets
    .replace(/\)/g, '%29');

  return `data:image/svg+xml;charset=UTF-8,${encoded}`;
}

const tableTextHelpers = {
  tableFilterText: {
    label: 'Filtrar:',
    placeholder: 'INGRESE BUSQUEDA...'
  },
  itemsPerPageText: {
    label: 'Registros por Pagina:'
  },
  noItemsViewText: {
    noResults: 'No se han encontrado resultados',
    noItems: 'No hay registros disponibles'
  },
};

const tableTextTranslatedHelpers = (vue) => {
  if (vue.labelTableText) {
    return {
      tableFilterText: {
        label: vue.$t('label.filter'),
        placeholder: vue.$t('label.' + vue.labelTableText)
      },
      itemsPerPageText: {
        label: vue.$t('label.' + vue.labelTableText) + 's ' + vue.$t('label.perPage')
      },
      noItemsViewText: {
        noResults: vue.$t('label.noResultsFound'),
        noItems: vue.$t('label.noRecordsAvailble')
      },
      itemsPerPage: 5,
    };
  } else {
    return {
      tableFilterText: {
        label: vue.$t('label.filter'),
        placeholder: vue.$t('label.enterSearch')
      },
      itemsPerPageText: {
        label: vue.$t('label.recordsPerPage')
      },
      noItemsViewText: {
        noResults: vue.$t('label.noResultsFound'),
        noItems: vue.$t('label.noRecordsAvailble')
      },
      itemsPerPage: 5,
    };
  }
};

function tableText() {
  return tableTextTranslatedHelpers(this);
};


const imgInputMsgs = {
  upload: '<p>Tu dispositivo no soporta la subida de archivos.</p>', // HTML allowed
  drag: 'Click aqui para seleccionar una <br>IMAGEN', // HTML allowed
  tap: 'Toque para seleccionar una IMAGEN', // HTML allowed
  change: 'Editar Imagen', // Text only
  remove: 'Eliminar Imagen', // Text only
  select: 'Seleccionar Imagen', // Text only
  selected: '<p>Imagen seleccionada!</p>', // HTML allowed
  fileSize: 'El tamaño del archivo excede el limite permitido.', // Text only
  fileType: 'El tipo de archivo no esta permitido.', // Text only
  aspect: 'Landscape/Portrait', // Text only
  defaultImage: '/img/picture-component-img.jpeg'
};

function imgInputTextsHelpers(vue) {
  return {
    upload: '<p>' + vue.$t('label.deviceDoesNotSupportFileUploads') + '</p>', // HTML allowed
    drag: vue.$t('label.clickHereToSelectPicture'), // HTML allowed
    tap: vue.$t('label.touchToSelectPicture'), // HTML allowed
    change: vue.$t('label.edit') + ' ' + vue.$t('label.image'), // Text only
    remove: vue.$t('label.delete') + ' ' + vue.$t('label.image'), // Text only
    select: vue.$t('label.select') + ' ' + vue.$t('label.image'), // Text only
    selected: '<p>' + vue.$t('label.selectedImage') + '!</p>', // HTML allowed
    fileSize: vue.$t('label.fileSizeExceedsAllowedLimit'), // Text only
    fileType: vue.$t('label.fileTypeIsNotAllowed'), // Text only
    aspect: 'Landscape/Portrait', // Text only
    defaultImage: '/img/picture-component-img.jpeg'
  };
}

function imgInputTexts() {
  return imgInputTextsHelpers(this);
}

function hasError(field) {
  return field.$dirty ? !field.$error : null;
}

function errorMessageHelpers(vue, field) {
  let msg = "";
  if (field.hasOwnProperty('required') && field.required == false) {
    return vue.$t('validation.required');
  }
  if (field.hasOwnProperty('requiredSelect') && field.requiredSelect == false) {
    return vue.$t('validation.required');
  }
  Object.keys(field).forEach(e => {
    if (e.indexOf('$') == -1 && field[e] == false && e != 'required') {

      if (['minLength'].includes(e)) {
        msg = vue.$t('validation.min') + ' ' + field.$params.minLength.min + ' ' + vue.$t('validation.chars');

      } else if (['maxLength'].includes(e)) {
        msg = vue.$t('validation.max') + ' ' + field.$params.maxLength.max + ' ' + vue.$t('validation.chars');

      } else if (['onlyMayusText'].includes(e)) {
        msg = vue.$t('validation.alpha');

      }  else if (['onlyMayusTextEspecial'].includes(e)) {
        msg = vue.$t('validation.onlyMayusTextEspecial');

      } else if (['numeric'].includes(e)) {
        msg = vue.$t('validation.onlyNumber');

      } else if (['minValue'].includes(e)) {
        msg = vue.$t('validation.minValueIs') + ': ' + field.$params.minValue.min;

      } else if (['maxValue'].includes(e)) {
        msg = vue.$t('validation.maxValueIs') + ': ' + field.$params.maxValue.max;

      } else if(['minNumberFormater'].includes(e)) {
        msg = `${vue.$t('validation.minValueIs')}: ${NumberFormater.formatNumber(field.$params.minNumberFormater.min, 2)}`;

      } else if(['maxNumberFormater'].includes(e)) {
        msg = `${vue.$t('validation.maxValueIs')}: ${NumberFormater.formatNumber(field.$params.maxNumberFormater.max, 2)}`;

      } else if (['SoloDecimales'].includes(e)) {
        msg = vue.$t('validation.onlyNumber');

      } else if (['validationSeal'].includes(e)) {
        msg = vue.$t('validation.validationSeal');

      } else if (['nameLength'].includes(e)) {
        msg = vue.$t('validation.nameLength');

      } else if (['TimeValidate'].includes(e)) {
        msg = vue.$t('validation.RangeValidate');

      } else if (['RangeValidate'].includes(e)) {
        msg = vue.$t('validation.RangeValidate');
        
      } else if (['onlyAlphanumeric4'].includes(e)||['onlyAlphanumeric5'].includes(e)) {
        msg = vue.$t('validation.onlyAlphanumeric');

      } else if (['credentials'].includes(e)) {
       msg = vue.$t('validation.credentials2');
       
      }else if (['textNumSpecial'].includes(e)) {
        msg = vue.$t('validation.onlyAlphanumeric');

      }else if (['decimal'].includes(e)) {
        msg = vue.$t('validation.onlyDecimal');

      }else if (['onlyRoute'].includes(e)) {
        msg = vue.$t('validation.onlyRoute');

      }else if (['onlyIcon'].includes(e)) {
        msg = vue.$t('validation.onlyIcon');

      }
      else if (['onlyCodeVersion'].includes(e)) {
        msg = vue.$t('validation.onlyCodeVersion');

      }
      else if (['onlyRouteTablets'].includes(e)) {
        msg = vue.$t('validation.onlyRouteTablets');

      }
      else if (['onlyText2'].includes(e)) {
        msg = vue.$t('validation.onlyText2');

      }
      else if (['onlyApkFileName'].includes(e)) {
        msg = vue.$t('validation.onlyApkFileName');

      }
      else if (['TextareaNotReq'].includes(e)) {
        msg = vue.$t('validation.textareaNotReq');
      
      }
      else if (['onlyText3'].includes(e)) {
        msg = vue.$t('validation.onlyText');

      }
      else if (['resolution'].includes(e)) {
        msg = vue.$t('validation.resolutionFormat');

      }
      else if (['verifyNumClaseImdg'].includes(e)) {
        msg = vue.$t('validation.verifyNumClaseImdg');
        
      }
      else if (['onlyAlphanumericGuion'].includes(e)) {
        msg = vue.$t('validation.onlyAlphanumeric');

      }else if (['checkDigit'].includes(e)) {
        msg = vue.$t('label.badFormat');

      }else if (['NotSameAsPLAndPOD'].includes(e)) {
        msg = vue.$t('validation.NotSameAsPLAndPOD');

      }else if (['NotSameLoadPortAndDeliveryPort'].includes(e)) {
        msg = vue.$t('validation.NotSameLoadPortAndDeliveryPort');

      }else if (['onlyHsCode'].includes(e)) {
        msg = vue.$t('validation.onlyHsCode');

      }else if (['DocumentTypeActivity'].includes(e)) {
        msg = vue.$t('validation.DocumentTypeActivity');

      }else if (['PortCompanyBranch'].includes(e)) {
        msg = vue.$t('validation.POLOrPODEqualCompanyBranchPort');

      }
      else if (['QuantityData'].includes(e)) {
        msg = vue.$t('validation.quantityRemainErrorBl');

      }
      else if (['QuantityRemainder'].includes(e)) {
        msg = vue.$t('validation.quantityRemainError');

      }
      else if (['ConsigneeBl'].includes(e)) {
        msg = vue.$t('validation.ConsigneeBl');

      }
      else if (['NotNegative'].includes(e)) {
        msg = vue.$t('label.ValueNotNegative');
      }
      else if (['maxLengthDeclared'].includes(e)) {
        msg = vue.$t('label.maxLengthDeclared');
      }
      else if (['IncorrectVolumenValue'].includes(e)) {
        msg = vue.$t('label.IncorrectVolumenValue');
      }
      else if (['IncorrectVolumenWeightValue'].includes(e)) {
        msg = vue.$t('label.IncorrectVolumenWeightValue');
      }
      else if (['onlyAlphanumericGuion2'].includes(e)) {
        msg = vue.$t('validation.onlyAlphanumeric');
      }
      else {
        msg = vue.$t('validation.' + e);
      }
    }
  });
  return msg;
}
//nota: esta funcion debe ser incluida en los methods de la intancia de vue para evitar error
function errorMessage(field) {
  return errorMessageHelpers(this, field);
}

function validarExtension(datos) {
  let ruta = datos.name;

  if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(datos.name)) {
    return false;
  } else {
    return true;
  }
}

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function slugify(text) {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c =>
      b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

//funcion para formatear registro de check
function formatedDateItem(item, initialActiveValue) {
  return {
    ...item,
    FormatedDate: item.TransaRegDate ?
      DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) :
      'N/A',
    _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    initialActive: initialActiveValue,
  };
}

function alertPropertiesHelpers(vue, properties = {}) {
  let defaultProperties = {
    text: `${vue.$t('label.changeStatusQuestion')}?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: vue.$t('button.confirm'),
    cancelButtonText: vue.$t('button.cancel'),
  }
  return Object.assign(defaultProperties, properties);
}

function alertProperties(properties = {}) {
  return alertPropertiesHelpers(this, properties);
}

function hexToRgb(hex, opacity = 1) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: Number.parseInt(result[1], 16),
    g: Number.parseInt(result[2], 16),
    b: Number.parseInt(result[3], 16),
    a: Number.isNaN(opacity) ? 1 : opacity,
  } : {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  };
}

//funcion para remover etiquetas html
function removeTags(str) {
  if ((str===null) || (str===''))
    return false;
  else
    str = str.toString();
  return str.replace( /(<([^>]+)>)/ig, '');
}

//funcion para limitar strings
function trimRemoveSpecialCharacters(string, limit = 30) {
  return string ? (string.replace(/(<([^>]+)>)/gi, "").length > limit ? `${string.replace(/(<([^>]+)>)/gi, "").substr(0,limit)} ...`.toUpperCase() : string.replace(/(<([^>]+)>)/gi, "")) : '';
}

//PARIDAD ENTRE CADENAS

function levenshteinDistance (a, b)  {

  const matrix = [];

  // Inicializa la matriz de Levenshtein
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Llena la matriz de Levenshtein
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // Sustitución
                                Math.min(matrix[i][j - 1] + 1, // Inserción
                                        matrix[i - 1][j] + 1)); // Eliminación
      }
    }
  }

  return matrix[b.length][a.length];
}


function parity (text, searchWord) {
  if(text && searchWord){
    let distanceThreshold = 10;
    let distance = levenshteinDistance(text, searchWord);
    return distance <= distanceThreshold;
  }
  else
    return false
  
}

export {
  DateFormater,
  NumberFormater,
  tableTextHelpers,
  tableTextTranslatedHelpers,
  formatedDateItem,
  imgInputMsgs,
  imgInputTextsHelpers,
  errorMessageHelpers,
  errorMessage,
  GenerateExcel,
  validarExtension,
  GenerateImgPlaceholder,
  GenerateSvgPlaceholder,
  validURL,
  slugify,
  hasError,
  alertPropertiesHelpers,
  alertProperties,
  tableText,
  imgInputTexts,
  hexToRgb,
  removeTags,
  trimRemoveSpecialCharacters,
  parity
};