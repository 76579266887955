<template>
    <CModalExtended
      :title="title"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      :show.sync="modalActive"
      @update:show="$emit('close');"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
        <CRow class="justify-content-center">
          <CCol sm="12">
            <dataTableExtended
          class="align-center-row-datatable"
              size="sm"
              :fields="fields"
              :items="formatedItems"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              id="tableTrans"
                
            >
            </dataTableExtended>
          </CCol>
        </CRow>       
      </form>
      <template #footer>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
        >
          <CIcon name="x" /><span class="ml-1">{{$t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
    </template>
    
  <script>
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
 
    
    //Data
    function data() {
      return {
        modalActive: this.modal,
        isSubmit: false,
        items:[],
        Loading: false,
      }
    }

    //Computeds:
    
    function fields(){
      return  [
        { 
          key: 'Nro', 
          label: '#',
          filter: false,
          _style: 'width:5%; text-align:center;'
        },
        { key: 'YardName', label:this.$t('label.yard'), _style:'width:15%',_classes:"text-center"},
        { key: 'YardAlias', label:this.$t('label.acronym'), _style:'width:15%',_classes:"text-center"},
        { key: 'SquareMeters', label: 'AREA (M2)', _style: 'width:15%;',_classes:'text-center' },
        { key: 'Teus', label: 'TEUS', _style: 'width:15%;',_classes:'text-center' },
      ];
    }
   
  function formatedItems() {
    if(this.DetailJson == []){
      return this.DetailJson;
    }
    return this.DetailJson?.map((item) => {
      return {
        ...item,
        Nro: item.Nro,
        SquareMeters: item.SquareMeters ? formatMilDecimal(item.SquareMeters?.toFixed(2)) : 'N/A' ,
        UltimoUsuario: item.TransaLogin ? item.TransaLogin : "N/A",
        FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
        _classes: item.Status === "INACTIVO" ? "table-danger" : "",
      };
    });
  }

  function getBranchId() {
    return this.branch ? this.branch.CompanyBranchId : "";
  }
  export default {
    name: 'view-yards-modal',
    mixins: [
      GeneralMixin,
    ],
    props: {modal: Boolean, title: String, DetailJson: Array},
    data,
    methods: {
    },
    computed: {
      formatedItems,
      fields,
      getBranchId,
      ...mapState({
        branch: (state) => state.auth.branch,
      })
    },
    watch: {
      modal: function(val){
      this.modalActive = val;
    }
    }
  }
</script>
   