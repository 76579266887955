<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CTabs variant="tabs" :active-tab="Tab" @update:activeTab="handleTab">
            <CTab :title="$t('label.Taraweight')">
                <CCard bodyWrapper >
                    <CRow class="mb-3">
                        <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                            <CButton
                                color="excel"
                                class="mr-1" 
                                size="sm"
                                @click="onBtnExport(true)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; XSLX
                            </CButton>
                            <CButton
                                color="watch"
                                size="sm"  
                                @click="onBtnExport(false)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; CSV
                            </CButton>
                        </CCol>
                        <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
                            <CButton
                                square
                                color="add"
                                @click="modal=true"
                                v-c-tooltip="{
                                    content: $t('label.Taraweight'),
                                    placement: 'top-end'
                                }"
                                >
                                <CIcon name="cil-playlist-add" /><span class="ml-1"
                                    >{{$t('label.nuevo')}}</span
                                >
                            </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="4" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
                                :label="$t('label.user')"
                                v-model.trim="User"
                                :value.sync="User"
                                :options="UserListFormatted"
                            />
                        </CCol>
                        <CCol sm="12" lg="7" class="center-field">
                            <CRow>
                                <CCol sm="12" lg="5" class="center-field">
                                    <div class="form-group form-row">
                                        <label class="col-form-label col-sm-12 col-lg-3 text-right pt-2">{{$t('label.date')}}</label>
                                        <CCol sm="12" lg="9" class="center-field">
                                            <vue-datepicker 
                                                type="datetime"
                                                header
                                                :lang="this.$i18n.locale"
                                                :editable="false"
                                                :clearable="false"
                                                format="DD/MM/YYYY HH:mm"
                                                placeholder="DD/MM/YYYY HH:mm"
                                                time-title-format="DD/MM/YYYY HH:mm"
                                                v-model="searchFilterDate.fromDay"
                                                :disabled-date="validateDateRange"
                                                :append-to-body="false"
                                                value-type="format"
                                                :show-second="false"
                                            >
                                                <template #icon-calendar>
                                                    <div style="display: none"></div>
                                                </template>
                                            </vue-datepicker> 
                                        </CCol>
                                    </div>
                                </CCol>
                                <label class="col-form-label col-sm-12 col-lg-auto text-right pt-2"> - </label>
                                <CCol sm="12" lg="4" class="center-field">
                                    <vue-datepicker 
                                        type="datetime"
                                        header
                                        :lang="this.$i18n.locale"
                                        :editable="false"
                                        :clearable="false"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="DD/MM/YYYY HH:mm"
                                        time-title-format="DD/MM/YYYY HH:mm"
                                        v-model="searchFilterDate.toDay"
                                        :disabled-date="validateDateRange"
                                        :append-to-body="false"
                                        value-type="format"
                                        :show-second="false"
                                    >
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                    </vue-datepicker> 
                                </CCol>
                                <CCol style="text-align: right;" class="col-sm-12 col-lg-auto center-field">
                                    <CButton
                                        color="watch"
                                        size="sm"
                                        class="mr-1"
                                        v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                                        @click="getTruckWeighingList()"
                                    >
                                        <CIcon name="cil-search" />
                                    </CButton>
                                    <CButton
                                        color="wipe"
                                        class="justify-content-end"
                                        size="sm"
                                        v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                                        @click="refreshComponent(true, true)"
                                    >
                                        <CIcon name="cil-brush-alt" />
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="3" xl="3" class="center-field">
                            <CInput
                                :label="$t('label.filter')"
                                :horizontal="{ label: 'col-sm-12 col-lg-auto col-xl-auto text-right', input: 'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="$t('label.enterSearch')" 
                                @keyup="onQuickFilterChanged($event.target.value)" 
                                v-model="search"           
                            >
                            </CInput>  
                        </CCol>
                        <CCol sm="12" lg="4" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-8'}"
                                :label="$t('label.transport')"
                                v-model.trim="Transportation"
                                :value.sync="Transportation"
                                :options="transportationListFormatted"
                            />
                        </CCol>
                        <CCol sm="1"></CCol>
                        <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
                                :label="$t('label.recordsPerPage')"
                                @change="onPageSizeChanged"
                                :options="[50,100,500,1000]"
                                required 
                                v-model="pageSize"
                            >
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="12" xl="12" class="mb-2">
                            <ag-grid-vue
                                v-if="showGrid"
                                style="width: 100%; height: 100vh;"
                                class="ag-theme-alpine"
                                :getRowStyle="getRowStyle"
                                :gridOptions="gridOptions"
                                :defaultColDef="defaultColDef"
                                :localeText="localeText"
                                :columnDefs="columnDefs"
                                :rowData="RowData"
                                :suppressRowClickSelection="true"
                                :groupSelectsChildren="true"
                                :enableRangeSelection="true"
                                :pagination="true"
                                :paginationPageSize="paginationPageSize"
                                :paginationNumberFormatter="paginationNumberFormatter"
                                rowSelection="single"
                                @grid-ready="onGridReady"
                            >
                            </ag-grid-vue>
                        </CCol>
                    </CRow>
                </CCard>    
            </CTab>
            <CTab :title="$t('label.FullWeight')">
                <FullWeight
                    :Tab="Tab"
                 /> 
            </CTab>    
        </CTabs> 
        <ModalTareWeight
            :modal.sync="modal"
            :isEdit="isEdit"
            :TareItem="TareItem"
            @set-Weighing-list="refreshComponent"
        />
        <ModaList
            :modal.sync="ModalList"
            :ListWeight="WeighingList"
            :isFull="false"
            @set-Weighing-list="refreshComponent"
        />
        <ModalDeleteTare
            :modal.sync="ModalDeleteTare"
            :TareItem="TareItem"
            @set-Weighing-list="refreshComponent"
        />   
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ModalTareWeight from './modal-tare-weight';
    import ModalDeleteTare from './modal-delete-tare';
    import FullWeight from './full_weight/full-weight';
    import ModaList from './full_weight/modal-list';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import GeneralMixin from '@/_mixins/general';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import moment from 'moment';

    //Data
    function data() {
        return {
            gridOptions: null,
            defaultColDef: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            search: '',
            pageSize:'',
            gridApi: null,
            columnApi: null,

            Tab: 0,
            loadingOverlay: false,
            WeighingItems: [],
            ModalList: false,
            WeighingList: {},
            modal: false,
            ModalDeleteTare: false,
            isEdit: false,
            TareItem: {},
            TransportationOptions: [],
            Transportation: '',
            TransportationName: '',
            User: '',
            UserName: '',
            UserOptions: [],
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            nulo:'', 
        };
    }

    //methods
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'EditTare'){
                    this.toggleEdit(event.data);
                }
                else if(event.colDef.colId == 'HistoricDetail'){
                    this.toggleList(event.data);
                }
                else if(event.colDef.colId == 'DeleteTaraweight'){
                    this.DeleteTaraweight(event.data);
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'EditTare'){
                   tippy('#myEditTareButton', {
                        content: this.$t('label.edit')+' '+this.$t('label.Taraweight'),
                    })
                } else if(event.colDef.colId == 'HistoricDetail'){
                    tippy('#myHistoricDetailButton', {
                        content: this.$t('label.TareWeightBinHistoricVehicle'),
                    })
                }
                else if(event.colDef.colId == 'DeleteTaraweight'){
                   tippy('#myDeleteTaraweightButton', {
                        content: this.$t('label.delete')+' '+this.$t('label.Taraweight'),
                    })
                }
            },
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowStyle(params) {
        if (params.node.data.Status != 'ACTIVO' ) {
            return {'background-color': 'rgba(0,0,0,.07)'};
        }
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        let currentDate = new Date();
        this.loadingOverlay = true;
        let rowDataFormated = [];
        let Filter = []; 

        Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
        Filter[1] = this.$t('label.user')+': '+this.UserName;
        Filter[2] = this.$t('label.transport')+': '+this.TransportationName;
        Filter[3] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        Filter[4] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        for (let i = 0; i < this.WeighingItems.length; i++) {
            rowDataFormated.push({
                Nro: this.WeighingItems[i].Nro,
                OrderCode: this.WeighingItems[i].OrderCode,
                ClientName : this.WeighingItems[i].ClientName,
                CarrierAlias: this.WeighingItems[i].CarrierAlias,
                LicensePlate: this.WeighingItems[i].LicensePlate,
                YardClientName: this.WeighingItems[i].YardClientName,
                YardName: this.WeighingItems[i].YardName,
                ImplementAlias: this.WeighingItems[i].ImplementAlias,
                TruckWeghingScaleName: this.WeighingItems[i].TruckWeghingScaleName,
                MovementOrderName:  _lang=='en' ? this.WeighingItems[i].MovementOrderNameEn : this.WeighingItems[i].MovementOrderNameEs,
                TareKgs: this.WeighingItems[i].TareKgs,
                TareTon: this.WeighingItems[i].TareTon,
                DeviceCode: this.WeighingItems[i].DeviceCode,
                TransaLogin: this.WeighingItems[i].TransaLogin,
                FormatedDate: this.WeighingItems[i].FormatedDate,
                Status: this.$t('label.'+this.WeighingItems[i].status),
            });   
        }
        if(this.nulo!="null" && this.WeighingItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.Taraweight'),valor, Filter, 3);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    function DeleteTaraweight(item){
        if (item.FgActStowagePlanningLogWeigth) {
            this.ModalDeleteTare = true;
            this.TareItem = item;
        }
    }
    
    function handleTab(tab) {
        this.Tab = tab;
    }

    function toggleEdit(item) {
        if (item.FgActStowagePlanningLogWeigth){
            this.isEdit = true;
            this.TareItem = item;
            this.modal = true;
        }
    }
    
    function toggleList(item){
        if (item.HistoricDetailJson.length != 0) {
            this.WeighingList = item;
            this.ModalList = true;
        }
    }

    async function getTruckWeighingList () {
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);       

        let ShipmentOrderJson = [{
            VisitId: this.Visit,
            StowagePlanningResourceId: this.Transportation,
            UserFilterId: this.User,
            DateStart: dateStart,
            DateEnd: dateEnd,
        }];
        await this.$http.post("VisitTruckWeighingTare-list", ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then(response => {
            let listado = response.data.data;
            this.nulo = String(response.data.data[0].Json);
            if (this.nulo != "null" && listado[0].TareListJson.length > 0){
                let TransportationData = this.TransportationOptions.find( item => item.StowagePlanningResourceId === this.Transportation );
                let UserData = this.UserOptions.find( item => item.UserFilterId === this.User );
                if(TransportationData !== '' && TransportationData !== null && TransportationData !== undefined){
                    this.TransportationName = TransportationData.ClientName;
                }else{
                    this.TransportationName = 'N/A';
                }
                if(UserData !== '' && UserData !== null && UserData !== undefined){
                    this.UserName = UserData.TransaUserName;
                }else{
                    this.UserName = 'N/A';
                }
                this.WeighingItems = listado[0].TareListJson.map(listado => Object.assign({}, this.WeighingItems, {
                    Nro: listado.Nro ? listado.Nro : '',
                    StowagePlanningLogWeigthId: listado.StowagePlanningLogWeigthId ? listado.StowagePlanningLogWeigthId : '',
                    StowagePlanningImplementVehicleId: listado.StowagePlanningImplementVehicleId ? listado.StowagePlanningImplementVehicleId : '',
                    MovementOrderId: listado.MovementOrderId ? listado.MovementOrderId : '',
                    OrderId: listado.OrderId ? listado.OrderId : '',
                    OrderCode: listado.OrderCode ? listado.OrderCode : 'N/A',
                    ClientName: listado.ClientName ? listado.ClientName : '',
                    CarrierAlias: listado.CarrierAlias ? listado.CarrierAlias : '',
                    LicensePlate: listado.LicensePlate ? listado.LicensePlate : '',
                    YardClientName: listado.YardClientName ? listado.YardClientName : 'N/A',
                    YardName: listado.YardName ? listado.YardName : 'N/A',
                    ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                    TruckWeghingScaleId: listado.TruckWeghingScaleId ? listado.TruckWeghingScaleId : '',
                    TruckWeghingScaleName: listado.TruckWeghingScaleName ? listado.TruckWeghingScaleName : '',
                    MovementOrderNameEn: listado.MovementOrderNameEn ? listado.MovementOrderNameEn : '',
                    MovementOrderNameEs: listado.MovementOrderNameEs ? listado.MovementOrderNameEs : '',
                    TareKgs: listado.TareKgs ? listado.TareKgs : 0,
                    TareTon: listado.TareTon ? listado.TareTon : 0,
                    DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
                    TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
                    FormatedDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : 'N/A',
                    status: listado.Status ? listado.Status : '',
                    Observation: listado.Observation ? listado.Observation : '',
                    HistoricDetailJson: listado.HistoricDetailJson ? listado.HistoricDetailJson : [],
                    FgActStowagePlanningLogWeigth: listado.FgActStowagePlanningLogWeigth ? listado.FgActStowagePlanningLogWeigth : '',
                }));
            }else{
                this.WeighingItems = [];
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getTransportationList() { 
    await this.$http.get("VisitCarrier-list", { VisitId: this.Visit })
        .then(response => {
            this.TransportationOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getUserList() {
        await this.$http.get("VisitLogisticWeigthUserList", { VisitId: this.Visit })
           .then(response => {
               this.UserOptions = response.data.data;
           }).catch( err => {
               this.$notify({
                   group: 'container',
                   title: '¡Error!',
                   text: err,
                   type: "error"
               });
           })
       }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent(filtre, refresh) {
        this.modal = false;
        this.ModalDeleteTare = false;
        this.ModalList = false;
        this.isEdit = false;
        this.TareItem = {};
        this.WeighingList = {};
        if(filtre){
            this.Transportation= '';
            this.TransportationName= '';
            this.User='';
            this.UserName='';
        };
        if (refresh) {
            this.loadingOverlay = true;
            this.searchFilterDate.fromDay="";
            this.searchFilterDate.toDay="";
            this.onQuickFilterChanged(this.search);
            this.search = '';
            this.pageSize = '';
            this.gridApi.paginationSetPageSize(parseInt(50));
            this.getDateRange();
            await this.getTransportationList();
            await this.getUserList();
            await this.getTruckWeighingList();
        }
    }

    function getDateRange(){
        if(this.itinerarySelected.Eta){
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }
        
        if (this.itinerarySelected.Eta>this.itinerarySelected.Ata) {
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Atd!=undefined){
            if (this.itinerarySelected.Eta>this.itinerarySelected.Ata) {
                this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            }else{
                this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            }
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined){
            let currentDate = new Date();
            this.searchFilterDate.fromDay=`${moment(currentDate).format('DD/MM/YYYY')} 00:00`;
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

//computed
    function RowData(){
        if(this.WeighingItems.length != 0){
            let _lang = this.$i18n.locale;
            return this.WeighingItems.map((item) => {
                return {
                    ...item,
                    MovementOrderName: _lang=='en' ? item.MovementOrderNameEn : item.MovementOrderNameEs,
                    Status: this.$t('label.'+item.status),
                };
            });
        }else{
            return [];
        }
    }

    function transportationListFormatted(){
        if(this.TransportationOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.TransportationOptions.map(function(e){
                    chart.push({
                        value: e.StowagePlanningResourceId, 
                        label: e.ClientName,
                    })
            })
            return chart;
        }
    }

    function UserListFormatted(){
        if(this.UserOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.UserOptions.map(function(e){
                    chart.push({
                        value: e.UserFilterId, 
                        label: e.TransaUserName,
                    })
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    headerName: "",
                    field: 'FgActStowagePlanningLogWeigth',
                    width: 45,
                    minWidth: 45,
                    maxWidth: 45,
                    suppressMovable: true,
                    lockPosition: true,
                    colId: "EditTare",
                    cellRenderer: params => {
                        if(params.value !== ""){
                            return  `
                                <div align="center">
                                    <button id="myEditTareButton" class="btn mr-1 btn-edit btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.93 22.93" role="img" class="c-icon">
                                            <path id="_Color" data-name=" Color" d="M4.78,22.93H0V18.15L14.09,4.06l4.77,4.78ZM20.23,7.48h0L15.45,2.7,17.78.37a1.29,1.29,0,0,1,1.8,0l3,3a1.29,1.29,0,0,1,0,1.8L20.23,7.48Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            `
                        }
                    },
                    cellClass: 'center-cell-especial px-1',
                },
                {
                    headerName: "",
                    field: 'HistoricDetailJson',
                    width: 45,
                    minWidth: 45,
                    maxWidth: 45,
                    suppressMovable: true,
                    lockPosition: true,
                    colId: "HistoricDetail",
                    cellRenderer: params => {
                        if(params.value.length != 0){
                            return  `
                                <div align="center">
                                    <button id="myHistoricDetailButton" class="btn btn-watch mr-1 btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.08 20.51" role="img" class="c-icon">
                                            <path class="cls-1" d="M15,0A16.17,16.17,0,0,0,0,10.25a16.15,16.15,0,0,0,30.08,0A16.16,16.16,0,0,0,15,0Zm0,17.09a6.84,6.84,0,1,1,6.84-6.84A6.84,6.84,0,0,1,15,17.09ZM15,6.15a4.11,4.11,0,1,0,4.1,4.1A4.1,4.1,0,0,0,15,6.15Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            `
                        }
                    },
                    cellClass: 'center-cell-especial px-1',
                },
                {
                    headerName: "",
                    field: 'FgActStowagePlanningLogWeigth',
                    width: 45,
                    minWidth: 45,
                    maxWidth: 45,
                    suppressMovable: true,
                    lockPosition: true,
                    colId: "DeleteTaraweight",
                    cellRenderer: params => {
                        if(params.value !== ""){
                            return  `
                                <div align="center">
                                    <button id="myDeleteTaraweightButton" class="btn btn-wipe mr-1 btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon">
                                            <path fill="var(--ci-primary-color, currentColor)" d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z" class="ci-primary"></path>
                                            <rect width="32" height="200" x="168" y="216" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                                            <rect width="32" height="200" x="240" y="216" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                                            <rect width="32" height="200" x="312" y="216" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                                            <path fill="var(--ci-primary-color, currentColor)" d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z" class="ci-primary"></path>
                                        </svg>
                                    </button>
                                </div>
                            `
                        }
                    },
                    cellClass: 'center-cell-especial px-1',
                },
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                    //checkboxSelection: true, 
                    //pinned: 'left'
                },
                {
                    field: "OrderCode",
                    headerName: this.$t('label.order'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 150,
                    minWidth: 150,
                    cellClass: 'text-color-negrita ag-font-style',
                    //checkboxSelection: true, 
                    //pinned: 'left',
                },
                {
                    field: "ClientName",
                    headerName: this.$t('label.transport'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'ag-font-style',
                    //pinned: 'left',
                },
                {
                    field: "CarrierAlias",
                    headerName: this.$t('label.transport')+' - '+this.$t('label.alias'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 190,
                    minWidth: 190,
                    cellClass: 'ag-font-style',
                    //pinned: 'left',
                },
                {
                    field: "LicensePlate",
                    headerName: this.$t('label.vehicle'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                    cellClass: 'ag-font-style',
                   // pinned: 'left',
                },
                {
                    field: "YardClientName",
                    headerName: this.$t('label.clientYard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'ag-font-style',
                    //pinned: 'left'
                },
                {
                    field: "YardName",
                    headerName: this.$t('label.yard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                    
                },
                {
                    field: "ImplementAlias",
                    headerName: this.$t('label.Gamela'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                },
                {
                    field: "TruckWeghingScaleName",
                    headerName: this.$t('label.truckScale'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',

                },
                {
                    field: "MovementOrderName",
                    headerName: this.$t('label.movementType'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                },
                {
                    field: "TareKgs",
                    headerName: this.$t('label.weight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "TareTon",
                    headerName: this.$t('label.weight')+' '+this.$t('label.metricTonnageAcronym'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "DeviceCode",
                    headerName: this.$t('label.device'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                },
                {
                    field: "TransaLogin",
                    headerName: this.$t('label.user'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                    minWidth: 180,
                },
                {
                    field: "FormatedDate",
                    headerName: this.$t('label.date'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial ag-font-style',
                },
                {
                    field: "Status",
                    headerName: this.$t('label.status'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial text-color-negrita ag-font-style',
                },
            ]
            return columnDefs;
    }

    export default {
        name: "weighing-index",
        data,
        beforeMount,
        components: { ModalTareWeight, FullWeight, ModalDeleteTare, ModaList },
        props: ['value'],
        mixins: [GeneralMixin, ReportesVisitas, AgGrid],
        methods:{
            getRowStyle,
            onGridReady,
            onQuickFilterChanged,
            onPageSizeChanged,
            onBtnExport,
            DeleteTaraweight,
            toggleEdit,
            toggleList,
            handleTab,
            getTransportationList,
            getUserList,
            getTruckWeighingList,
            validateDateRange,
            refreshComponent,
            getDateRange,
        },
        computed:{
            columnDefs,
            RowData,
            transportationListFormatted,
            UserListFormatted,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemOrder: state => state.visitas.dropItemOrder,  
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
                StowagePlanningId: state => state.visitas.StowagePlanningId,
            }),
        },
        watch:{
            Tab: async function (newValue) {
                if (newValue==0 && this.Visit != '') {
                    this.refreshComponent(false, true);
                }
            },
            dropItemOrder: async function (newValue) {
                if (newValue==4 && this.Visit != '') {
                    this.Tab=0;
                    this.refreshComponent(true, true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style scoped>
    .ag-font-style {
        user-select: text;
    }
</style>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>