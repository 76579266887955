<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="xl"
      @update:show="closeModal()"
      :show.sync="modalActive"
    >
      <CTabs
        style="width: 100%"
        variant="tabs"
        :active-tab.sync="activeTab"
        :fade="false"
      >
        <CTab :title="$t('label.generalInfo')">
          <CRow class="mt-3">
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.activity')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="activityOptions"
                :disabled="isEmpty(activities)"
                :value.sync="$v.stowageBillOfLoading.PortActivityId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.PortActivityId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.PortActivityId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="getClientLabel"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="clientOptions"
                :disabled="isEmpty(clients)"
                :value.sync="$v.stowageBillOfLoading.ClientTpId.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.ClientTpId)"
                :is-valid="hasError($v.stowageBillOfLoading.ClientTpId)"
              >
                <template #append>
                  <CButton
                    shape="square"
                    color="add"
                    size="sm"
                    class="pt-0 rounded"
                    :disabled="stowageBillOfLoading.PortActivityId == ''"
                    v-c-tooltip="{
                      content: $t('label.nuevo') + getClientLabel,
                      placement: 'top-end',
                    }"
                    @click="getTpClientId"
                  >
                    <CIcon name="cil-playlist-add" />
                  </CButton>
                </template>
              </CSelect>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="getRifLabel"
                size="sm"
                v-model.trim="rifClient"
                disabled
              />
            </CCol>

            <CCol sm="12">
              <CCollapse :show="collapse">
                <CRow>
                  <CCol sm="12" v-if="collapse">
                    <AddCliente
                      metalScrap="ClientPortActivity-insert"
                      :tpClientId="currentTpClientId"
                      :title="getCollapseTitle"
                      @child-refresh="handleCollapse"
                      @update-list="handleRefresh"
                    />
                  </CCol>
                </CRow>
              </CCollapse>
            </CCol>

            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="required text-right"
                :placeholder="'BL'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="'BL'"
                maxlength="20"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.NroBl.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.NroBl)"
                :is-valid="hasError($v.stowageBillOfLoading.NroBl)"
              />
            </CCol>
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="'POL'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="loadPortOptions"
                :disabled="isEmpty(loadPorts)"
                :value.sync="$v.stowageBillOfLoading.LoadPortId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.LoadPortId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.LoadPortId)"
              >
              </CSelect>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="'POD'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="dischargePortOptions"
                :disabled="isEmpty(dischargePorts)"
                :value.sync="$v.stowageBillOfLoading.DischargePortId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.DischargePortId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.DischargePortId)"
              >
              </CSelect>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>

            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="'Nº BOOKING'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="'Nº BOOKING'"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.BookingNumber.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.BookingNumber)"
                :is-valid="hasError($v.stowageBillOfLoading.BookingNumber)"
              />
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.seller')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.seller')"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.Shipper.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Shipper)"
                :is-valid="hasError($v.stowageBillOfLoading.Shipper)"
              />
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.notifyParty')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.notifyParty')"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.NotifyParty.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.NotifyParty)"
                :is-valid="hasError($v.stowageBillOfLoading.NotifyParty)"
              />
            </CCol>

            <CCol sm="12" lg="8">
              <CTextarea
                size="sm"
                addWrapperClasses="input-textarea-manifiesto-general"
                addLabelClasses="text-right label-textarea-manifiesto-general"
                v-uppercase
                :label="$t('label.inventoryContains')"
                :placeholder="$t('label.inventoryContains')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                rows="2"
                maxlength="150"
                v-model.trim="$v.stowageBillOfLoading.GeneralInfo.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.GeneralInfo)"
                :is-valid="hasError($v.stowageBillOfLoading.GeneralInfo)"
              />
            </CCol>
            <CCol sm="12" lg="4" v-if="edit">
              <div>
                <CSelect
                  :value.sync="stowageBillOfLoading.Status"
                  :is-valid="statusSelectColor()"
                  :horizontal="{
                    label: 'col-sm-12 col-lg-4',
                    input: 'col-sm-12 col-lg-8',
                  }"
                  size="sm"
                  :label="$t('label.status')"
                  :options="statusOptions"
                  addLabelClasses="text-right"
                />
              </div>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.loadCondition')">
          <CRow class="mt-3">
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.cargoType')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="cargoTypesOptions"
                :disabled="isEmpty(cargoTypes)"
                :value.sync="$v.stowageBillOfLoading.TpCargoId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.TpCargoId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.TpCargoId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                addLabelClasses="text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.classification')"
                size="sm"
                v-model.trim="classification"
                disabled
              />
            </CCol>
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.commodity')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="commodityOptions"
                :disabled="isEmpty(commodities)"
                :value.sync="$v.stowageBillOfLoading.CommodityId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.CommodityId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.CommodityId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="'IMDG'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="imosOptions"
                :disabled="isEmpty(imos)"
                :value.sync="$v.stowageBillOfLoading.ImdgId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.ImdgId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.ImdgId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                size="sm"
                ref="weightInput"
                :label="$t('label.weight') + ' (TON)'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                placeholder="0"
                addLabelClasses="required text-right"
                v-model.lazy="stowageBillOfLoading.Weight"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Weight.$touch()"
                maxlength="9"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Weight)"
                :is-valid="hasError($v.stowageBillOfLoading.Weight)"
                required
              />
            </CCol>
            <!-- <CCol sm="4">
              <CInput
                addLabelClasses="required text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.density')+'(KG/M³)'"
                size="sm"
                v-model.trim="density"
                disabled
              /> -->
              <!-- <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-5 col-form-label-sm">{{
                  $t('label.density')+'(KG/M³)'
                }}</label>
                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                  <money
                    v-model="density"
                    v-bind="measureDensity"
                    class="form-control"
                    value="0"
                    disabled
                  >
                  </money>
                </div>
              </div> -->
            <!--</CCol>-->
            <CCol sm="12" lg="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.heading')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="rubrosOptions"
                :disabled="isEmpty(rubros)"
                :value.sync="$v.stowageBillOfLoading.HeadingId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.HeadingId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.HeadingId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="4">
              <div class="form-group form-row mb-2">
                <label class="col-form-label-sm col-sm-12 col-lg-4 text-right">{{$t('label.temperature')}}</label>
                <div class="input-group col-sm-12 col-lg-8">
                  <CCol sm="6" class="px-0">
                    <CSelect
                      :value.sync="$v.stowageBillOfLoading.UnitMeasureTemperatureId.$model"
                      :options="temperatureOptions"
                      :disabled="isEmpty(temperatures)"
                      size="sm"
                      style="width: 100% !important"
                    />
                  </CCol>
                  <CCol sm="6" class="px-0">
                    <CInput
                      :disabled="stowageBillOfLoading.UnitMeasureTemperatureId == ''"
                      ref="temperatureInput"
                      :placeholder="$t('label.temperature')"
                      size="sm"
                      v-model.lazy="stowageBillOfLoading.Temperature"
                      :invalid-feedback="errorMessage($v.stowageBillOfLoading.Temperature)"
                      :is-valid="hasError($v.stowageBillOfLoading.Temperature)"
                      v-money="measure"
                      maxlength="9"
                      style="width: 100% !important"
                    />
                  </CCol>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                ref="volumenInput"
                addLabelClasses="text-right"
                placeholder="0"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.volume')+'(M³/MOL)'"
                size="sm"
                v-model.lazy="stowageBillOfLoading.Volumen"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Volumen)"
                :is-valid="hasError($v.stowageBillOfLoading.Volumen)"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Volumen.$touch()"
                maxlength="9"
              />
            </CCol>
            <CCol sm="12" lg="4">
              <CInput
                ref="viscosityInput"
                addLabelClasses="text-right"
                placeholder="0"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.viscosity')"
                size="sm"
                v-model.lazy="stowageBillOfLoading.Viscosity"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Viscosity)"
                :is-valid="hasError($v.stowageBillOfLoading.Viscosity)"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Viscosity.$touch()"
                maxlength="9"
              />
            </CCol>
            <CCol sm="12" lg="8">
              <CTextarea
                size="sm"
                addWrapperClasses="input-textarea-manifiesto-condition"
                addLabelClasses="text-right label-textarea-manifiesto-condition"
                v-uppercase
                :label="$t('label.condition')"
                :placeholder="$t('label.condition')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-9',
                }"
                rows="2"
                maxlength="150"
                v-model.trim="$v.stowageBillOfLoading.Condiction.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Condiction)"
                :is-valid="hasError($v.stowageBillOfLoading.Condiction)"
              />
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="closeModal()"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ManifiestoCargaValidation from "@/_validations/planificacion-estiba/ManifiestoDeCargaValidations";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import UpperCase from '@/_validations/uppercase-directive';
import { VMoney, Money } from "v-money";
import AddCliente from "@/pages/cliente/add-cliente";

//DATA
function data() {
  return {
    modalActive: false,
    stowageBillOfLoading: {
      VisitBillOfLadingId: "",
      TpCargoId: "",
      HeadingId: "",
      CommodityId: "",
      ClientTpId: "",
      NroBl: "",
      Shipper: "",
      NotifyParty: "",
      LoadPortId: "",
      DischargePortId: "",
      ImdgId: "",
      PortActivityId: "",
      BookingNumber: "",
      UnitMeasureWeightBlId: "",
      Weight: 0,
      UnitMeasureVolumenBlId: "",
      Volumen: 0,
      UnitMeasureTemperatureId: "",
      Temperature: "",
      Viscosity: "",
      GeneralInfo: "",
      Condiction: "",
      Status: 1,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    measureDensity: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 4,
      masked: false,
    },
    modalTitle: "",
    collapse: false,
    oldStatus: 1,
    loading: false,
    imos: [],
    cargoTypes: [],
    activities: [],
    rubros: [],
    commodities: [],
    temperatures: [],
    //density: '',
    classification: '',
    rifClient: '',
    temperatureDefault: '',
    loadPorts: [],
    dischargePorts: [],
    clients: [],
    loadingClients: false,
    loadingPorts: false,
    currentTpClientId: '',
    loadingClientAndPort: false,
    activeTab: 0,
    currentEstimatedTotal: 0,
  };
}

// METHODS
function closeModal() {
  this.resetForm();
  this.$emit('submited');
}

function toggle(newVal) {
  if (!newVal){
    this.resetForm();
  } 
  else{
    this.loadData();
  } 
}
function resetForm() {
  let arrKeys = Object.keys(this.stowageBillOfLoading);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "HeadingId":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "Weight":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "UnitMeasureWeightBlId":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "Volumen":
        this.stowageBillOfLoading[arrKeys[i]] = '';
        break;
      case "Temperature":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "UnitMeasureTemperatureId":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "Viscosity":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "Status":
        this.stowageBillOfLoading[arrKeys[i]] = 1;
        this.oldStatus = 1;
        break;
      default:
        this.stowageBillOfLoading[arrKeys[i]] = "";
    }
  }
  this.oldStatus = 1;
  this.clients = [];
  this.commodities = [];
  this.rubros = [];
  this.cargoTypes = [];
  this.loadPorts = [];
  this.dischargePorts = [];
  this.activities = [];
  this.temperatures = [];

  this.modalTitle = "";
  this.rifClient = "";
  this.classification = "";
  //this.density = "";

  this.collapse = false;
  this.loadingClients = false;
  this.loadingPorts = false;
  this.currentTpClientId = '';
  this.loadingClientAndPort = false;
  this.activeTab = 0;
  this.currentEstimatedTotal = 0

  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = "";
  let inputVolumen = this.$refs.volumenInput.$el.querySelector("input");
  inputVolumen.value = "";
  let inputViscosity = this.$refs.viscosityInput.$el.querySelector("input");
  inputViscosity.value = "";
  let inputTemperature = this.$refs.temperatureInput.$el.querySelector("input");
  inputTemperature.value = "";
  this.$v.$reset();
}
function setForm(bill) {
  let arrKeys = Object.keys(this.stowageBillOfLoading);
  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Weight":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Volumen":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Viscosity":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Temperature":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? Number.parseFloat(bill[arrKeys[i]]).toFixed(2) : '';
        break;
      case "Status":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = bill[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]] : "";
    }
  }

  this.modalTitle = `${bill.NroBl}`;
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = this.stowageBillOfLoading.Weight ? this.stowageBillOfLoading.Weight : '';
  let inputVolumen = this.$refs.volumenInput.$el.querySelector("input");
  inputVolumen.value = this.stowageBillOfLoading.Volumen ? this.stowageBillOfLoading.Volumen : '';
  let inputViscosity = this.$refs.viscosityInput.$el.querySelector("input");
  inputViscosity.value = this.stowageBillOfLoading.Viscosity ? this.stowageBillOfLoading.Viscosity : '';
  let inputTemperature = this.$refs.temperatureInput.$el.querySelector("input");
  inputTemperature.value = this.stowageBillOfLoading.Temperature ? this.stowageBillOfLoading.Temperature : '';
  this.$v.$touch();
}
function loadData() {
  this.loading = true;
  let peticiones = 
    [
      this.$http.ejecutar("GET", "PortActivityList-by-VisitId", {
      VisitId: this.Visit,
      }),
      this.$http.ejecutar("GET", "Imdg-list", {
        ImdgClassId: '',
        Filter: 'ACTIVO',
      }),
      this.$http.ejecutar("GET", "BulkCargoConfig-list"),
      this.$http.ejecutar("GET", "UnitMeasureByTpUnitMeasureId", {
        TpUnitMeasureId: 'b6f6dcfd-7246-410c-a576-36462ccf5819',
        Filter: 'ACTIVO',
      }),
    ];

  Promise.all(peticiones)
    .then((responses) => {
      this.activities = responses[0].data.data;
      this.imos = responses[1].data.data;
      this.cargoTypes = responses[2].data.data[0].TpCargoJson;
      this.rubros = responses[2].data.data[0].CommodityJson;
      this.commodities = responses[2].data.data[0].CommodityJson;
      this.temperatures = responses[3].data.data;
      this.clients = [];
      this.loadPorts = [];
      this.dischargePorts = [];

      /*let found = this.rubros.find((item) => item.HeadingId == this.stowageBillOfLoading.HeadingId);
      if(found) {
        this.density = found.Density;
      }*/

      if (this.edit) {
        this.setForm(this.billOfLoadingItem);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function formatNumericValue(val) {
  return Number.parseFloat(val.replace(".", "").replace(",", "."));
}
function formatedData() {
  if (!this.edit) {
    let newBill = {
      ...this.stowageBillOfLoading,
      VisitId: this.Visit,
      Weight: this.formatNumericValue(this.stowageBillOfLoading.Weight),
      Volumen: this.formatNumericValue(this.stowageBillOfLoading.Volumen),
      Viscosity: this.formatNumericValue(this.stowageBillOfLoading.Viscosity),
      Temperature: this.formatNumericValue(this.stowageBillOfLoading.Temperature),
    };

    delete newBill.VisitBillOfLadingId;
    delete newBill.Status;

    return newBill;
  } else {
    return {
      ...this.stowageBillOfLoading,
      VisitId: this.Visit,
      Weight: this.formatNumericValue(this.stowageBillOfLoading.Weight),
      Volumen: this.formatNumericValue(this.stowageBillOfLoading.Volumen),
      Viscosity: this.formatNumericValue(this.stowageBillOfLoading.Viscosity),
      Temperature: this.formatNumericValue(this.stowageBillOfLoading.Temperature),
    };
  }
}
function submit() {
  try {
    this.$v.stowageBillOfLoading.$touch();
    if (this.$v.stowageBillOfLoading.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit ? "VisitBillOfLading-update" : "VisitBillOfLading-insert";
    let VisitBillOfLadingJson = this.formatedData;
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, VisitBillOfLadingJson, {
        root: "VisitBillOfLadingJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function isEmpty(arr) {
  return arr.length == 0;
}

function confirmation() {
  try {
    this.$v.stowageBillOfLoading.$touch();
    if (this.$v.stowageBillOfLoading.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.stowageBillOfLoading.Status,
      this.submit,
      `${this.modalTitle}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getClients() {
  this.loadingClients = true;
  this.$http
    .get("Client-list-by-PortActivityId", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.clients = response.data.data;

      if(this.edit) {
        let foundClient = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
        if(foundClient)
          this.rifClient = foundClient.ClientRif;
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingClients = false;
    });
}
function getPorts() {
  this.loadingPorts = true;
  this.$http
    .get("StowagePlanningPort-by-CompanyBranchId", {
      CompanyBranchId: this.getBranchId,
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.loadPorts = response.data.data[0].VisitJson[0].LoadPortJson;
      this.dischargePorts = response.data.data[0].VisitJson[0].DischargePortJson;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingPorts = false;
    });
}
function handleCollapse() {
  if (this.collapse) {
    this.getClients();
    this.collapse = false;
    this.currentTpClientId = '';
  } else {
    this.collapse = true;
  }
}
function handleRefresh() {
  this.getClients();
  this.currentTpClientId = '';
}

function getTpClientId() {
  this.loading = true;
  this.$http
    .get("TpClientPortActivity-list", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.currentTpClientId = response.data.data[0].TpClientId;
      this.handleCollapse();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function loadClientsAndPorts() {
  this.loadingClientAndPort = true;
  let peticiones = [
    this.$http.ejecutar("GET", "Client-list-by-PortActivityId", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    }),
    this.$http.ejecutar("GET", "StowagePlanningPort-by-CompanyBranchId", {
      CompanyBranchId: this.getBranchId,
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.clients = responses[0].data.data;

      this.loadPorts = responses[1].data.data[0].VisitJson[0].LoadPortJson;
      this.dischargePorts = responses[1].data.data[0].VisitJson[0].DischargePortJson;
      if(this.edit) {
        let foundClient = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
        if(foundClient)
          this.rifClient = foundClient.ClientRif;
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingClientAndPort = false;
    });
}

//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return `${this.$t("label.nuevo")} BL`;
  } else {
    return `${this.$t("label.edit")} BL: ${this.modalTitle}`;
  }
}
function activityOptions() {
  if(this.activities.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let _lang = this.$i18n.locale
    var chart = [{
        value: '', 
        label: this.$t('label.select'),
    }];
    this.activities.map(function(item){
      chart.push({
        value: item.PortActivityId,
        label: _lang=='en' ? item.PortActivityNameEn : item.PortActivityNameEs,
      })    
    })
    return chart;
  }
}
function cargoTypesOptions() {
  if(this.cargoTypes.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
        value: '', 
        label: this.$t('label.select'),
    }];
    this.cargoTypes.map(function(item){
      chart.push({
        value: item.TpCargoId,
        label: item.GpoCargoName,
      })    
    })
    return chart;
  }
}
function rubrosOptions() {
  if(this.rubros.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.rubros.map(function(item){
      chart.push({
        value: item.HeadingId,
        label: item.HeadingName,
      })    
    })
    return chart;
  }
}
function clientOptions() {
  if(this.clients.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.clients.map(function(item){
      chart.push({
        value: item.ClientTpId,
        label: item.ClientName,
      })    
    })
    return chart;
  }
}
function commodityOptions() {
  if(this.commodities.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.commodities.map(function(item){
      chart.push({
        value: item.CommodityId,
        label: item.CommodityName,
      })    
    })
    return chart;
  }
}
function temperatureOptions() {
  if(this.temperatures.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.temperatures.map(function(item){
      chart.push({
        value: item.UnitMeasureId,
        label: item.UnitMeasureAbbrev,
      })    
    })
    return chart;
  }
}
function loadPortOptions() {
  if(this.loadPorts.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.loadPorts.map(function(item){
      chart.push({
        value: item.PortId,
        label: `${item.PortName}, ${item.IsoCodeAlpha2}`,
      })    
    })
    return chart;
  }
}
function dischargePortOptions() {
  if(this.dischargePorts.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.dischargePorts.map(function(item){
      chart.push({
        value: item.PortId,
        label: `${item.PortName}, ${item.IsoCodeAlpha2}`,
      })    
    })
    return chart;
  }
}
function imosOptions() {
  if(this.imos.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }];
    this.imos.map(function(item){
      chart.push({
        value: item.ImdgId,
        label: item.ImdgCod,
      })    
    })
    return chart;
  }
}
function statusSelectColor() {
  return this.stowageBillOfLoading.Status === 1;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function isExporter() {
  return (this.stowageBillOfLoading.PortActivityId == '' || this.stowageBillOfLoading.PortActivityId == 'A104FA2A-53A6-4238-8344-C2FD20F04EDE')
}
function getClientLabel() {
  if(this.stowageBillOfLoading.PortActivityId != ''){
    if(this.isExporter){
      return this.$t('label.exporter');
    }else{
      return this.$t('label.importer');
    }
  }else{
    return this.$t('label.client');
  }
}
function getRifLabel() {
  if(this.isExporter)
    return this.$t('label.rifExporter');
  else
    return this.$t('label.rifImporter');
}
function currentActivity() {
  return this.stowageBillOfLoading.PortActivityId;
}
function currentClient() {
  return this.stowageBillOfLoading.ClientTpId;
}
function currentCargoType() {
  return this.stowageBillOfLoading.TpCargoId;
}
/*function currentRubro() {
  return this.stowageBillOfLoading.HeadingId;
}*/

function getCollapseTitle() {
  if(this.isExporter)
    return this.$t('label.nuevo')+' '+this.$t('label.clientExporter');
  else
    return this.$t('label.nuevo')+' '+this.$t('label.clientImporter');
}

export default {
  name: "manifiesto-carga-modal",
  mixins: [ModalMixin, General],
  data,
  components: {
    AddCliente,
    Money,
  },
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    billOfLoadingItem: {
      type: Object,
      default: {},
    },
    cargoType: {
      type: String,
      default: "",
    },
  },
  validations() {
    return ManifiestoCargaValidation(this.stowageBillOfLoading.LoadPortId, this.stowageBillOfLoading.DischargePortId, this.currentEstimatedTotal);
  },
  directives: {
    money: VMoney,
  },
  directives: UpperCase,
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.modalActive = val;
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentActivity: function(newVal, oldVal) {
      if(newVal != oldVal && this.modalActive){
        if(newVal != ''){
          this.loadClientsAndPorts();
          this.dischargePorts = [];
          this.loadPorts = [];
          this.clients = [];
          this.stowageBillOfLoading.ClientTpId = '';
          this.stowageBillOfLoading.LoadPortId = '';
          this.stowageBillOfLoading.DischargePortId = '';
          this.rifClient = '';
        }else{
          this.collapse = false;
          this.dischargePorts = [];
          this.loadPorts = [];
          this.clients = [];
          this.stowageBillOfLoading.ClientTpId = '';
          this.stowageBillOfLoading.LoadPortId = '';
          this.stowageBillOfLoading.DischargePortId = '';
          this.rifClient = '';
        }
      }
    },
    currentClient: function(newVal, oldVal) {
      if(newVal != oldVal){
        if(newVal != ''){
          let found = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
          if(found && this.modalActive){
            this.rifClient = found.ClientRif;
          }
        }else{
          this.rifClient = '';
        }
      }
    },
    currentCargoType: function(newVal, oldVal) {
      if(newVal != oldVal){
        if (this.stowageBillOfLoading.TpCargoId != '') {
          let found = this.cargoTypes.find((item) => item.TpCargoId == this.stowageBillOfLoading.TpCargoId);
          if(found && this.modalActive){
            this.classification = found.TpCargoName;
          }
        }else{
          this.classification = '';
        } 
      }
    },
    /*currentRubro: function(newVal, oldVal) {
      if(newVal != oldVal){
        let found = this.rubros.find((item) => item.HeadingId == this.stowageBillOfLoading.HeadingId);
        if(found && this.modalActive)
          this.density = found.Density;
      }
    },*/
  },
  methods: {
    closeModal,
    toggle,
    loadData,
    isEmpty,
    getPorts,
    getClients,
    handleCollapse,
    handleRefresh,
    resetForm,
    submit,
    confirmation,
    formatNumericValue,
    setForm,
    statusSelectColor,
    getTpClientId,
    loadClientsAndPorts,
  },
  computed: {
    tituloModal,
    getBranchId,
    rubrosOptions,
    clientOptions,
    activityOptions,
    loadPortOptions,
    dischargePortOptions,
    cargoTypesOptions,
    temperatureOptions,
    commodityOptions,
    imosOptions,
    isExporter,
    getClientLabel,
    getRifLabel,
    currentActivity,
    currentClient,
    currentCargoType,
    //currentRubro,
    formatedData,
    getCollapseTitle,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
      Visit: state => state.visitas.VisitId,
    }),
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>