import {
    required,
    maxLength,
    alpha
  } from 'vuelidate/lib/validators';
  
  export default () => {
    return {
        PortCode: { required, alpha, maxLength: maxLength(3) },
        ShippingLine:{ required },
    }
  }