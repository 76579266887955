import {
  required,
  maxLength,
} from 'vuelidate/lib/validators';

import { onlyAlphanumeric4 } from '../validacionEspeciales';
const Time = (value) => /^((?:2[0-3]|[01][0-9]):[0-5][0-9])$/.test(value);

export default () => {
  return {
    turno: {
      TurnNameEs: { required, onlyAlphanumeric4, maxLength: maxLength(50) },
      TurnNameEn: { required, onlyAlphanumeric4, maxLength: maxLength(50) },
      StartHour: { required, Time },
      EndHour: { required, Time }
    }
  }
}