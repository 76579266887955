<template>
    <div sm="12">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow>
            <CCol sm="12" class="text-right mb-2 mt-2">
                <CButton
                    square
                    color="add"
                    v-c-tooltip="{
                    content: $t('label.gpoSupply'),
                    placement: 'top-end'
                    }"
                    @click="ModalActive=true"
                >
                    <CIcon name="cil-playlist-add" /><span class="ml-1"
                    >{{$t('label.nuevo')}}</span
                    >
                </CButton>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="computedGpoSupply"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                    </template>
                    <template #Detalle="{ item }">
                    <td class="text-center">
                        <CButton
                        size="sm"
                        color="edit"
                        class="mr-1"
                        v-c-tooltip="{
                            content: $t('label.edit')+$t('label.gpoSupply'),
                            placement: 'top-end',
                        }"
                        @click="EditGpoSupply(item)"
                        >
                        <CIcon name="pencil" />
                        </CButton>
                    </td>
                    </template>
                </dataTableExtended>
            </CCol>
            <ModalGpoSupply
                :modal.sync="ModalActive"
                :isEdit="isEdit"
                :GpoSupplyItem="GpoSupplyItem"
                @closeModal="closeModal"
            />
        </CRow>
    </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalGpoSupply from './modal-gpo-supply';

function data() {
    return {
        GpoSupply: [],
        GpoSupplyItem: {},
        ModalActive: false,
        isEdit: false,
        loadingOverlay: false,
    };
}

function fields(){
    return [
        { 
            key: 'Nro', 
            label: '#', filter: false,
            _style: 'width:4%',
            _classes: 'text-center',
        },          
        { key: 'GpoSupplyName', label:this.$t('label.group'), _style:'width:45%;',_classes:'text-center' },
        { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;', _classes:'text-center'},
        { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%;', _classes:"text-center"},
        { key: 'Status', label: this.$t('label.status'), _style:'width:15%;', _classes:"text-center"},
        { 
            key: 'Detalle',
            label: '',
            sorter: false,
            filter: false,
            _style: 'min-width:45px; width:1%; text-align:center;'
        }
    ];
}

function EditGpoSupply(item) {
    this.isEdit = true;
    this.GpoSupplyItem = item;
    this.ModalActive = true;
}

function closeModal(Reset) {
    this.ModalActive = false;
    this.isEdit = false;
    if (Reset) {
      this.getGpoSupplyList();
    }
}

function getGpoSupplyList () {
  this.loadingOverlay = true;
  this.GpoSupply = [];
  this.$http.get("GpoSupply-list", { Filter: 'ALL' })
  .then(response => {
    let listado = response.data.data;
    if (listado?.length > 0) {
      this.GpoSupply = listado;
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function computedGpoSupply() {
  if (this.GpoSupply.length > 0) {
    return this.GpoSupply.map((item) => {
      return {
        ...item,
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
      };
    });
  }else{
    return [];
  }
}

export default {
  name: 'gpo-supply-index',
  data,
  mixins: [GeneralMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalGpoSupply,
  },
  methods: {
    closeModal,
    EditGpoSupply,
    getGpoSupplyList,
  },  
  watch: {
    active: function(newVal){
        if (newVal) {
          this.getGpoSupplyList();
        }
    }
  },
  computed: {
    computedGpoSupply,
    fields,
  },
};
</script>