<template>
    <div>
        <CModalExtended
            :title="titleModalImplement"
            size="lg"
            color="dark"
            @update:show="closeModal()"
            :closeOnBackdrop="false"
            :show.sync="$store.state.implemento.modalImplement"
        >
            <CRow align-vertical="center" class="mb-4">
                <CCol sm="12" lg="4" xl="4">
                    <div class="preview">    
                        <picture-input
                            ref="imageInputImplemento"
                            :imgSrc="formImplemento.Image_url"
                            width="200"
                            height="200"
                            accept="image/jpeg,image/png,image"
                            size="10"
                            :custom-strings="imgInputTexts"
                            @change="handleFileUploadImplemento"
                            v-if="$store.state.implemento.modalImplement"
                        >
                        </picture-input>
                    </div>
                    <div v-if="$v.formImplemento.RutaImplemento.$error">
                        <div class='text-invalid-feedback' v-if="!$v.formImplemento.RutaImplemento.isValidFile">{{t('label.badImage')}}</div>
                    </div>
                </CCol>
                <CCol sm="12" lg="8" xl="8">
                    <CRow>
                        <CCol sm="12" lg="12" xl="12">
                            <CSelect
                                :label="$t('label.implementsGroup')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :value.sync="formImplemento.IdGpoImplemento"
                                @blur="$v.formImplemento.IdGpoImplemento.$touch()"
                                @change="filterIdGpoImplemento"
                                :options="optionsListGpoImplemento"
                                :is-valid="hasError($v.formImplemento.IdGpoImplemento)"
                                :invalid-feedback="errorMessage($v.formImplemento.IdGpoImplemento)"
                            >      
                                <template #BrandRoute="{ index,item }">
                                    <td class="text-center">
                                        <img :src="srcImage(item.BrandRoute)" :alt="item.BrandId+index"    style="height: 32px !important; width: 32px !important;" class="img-thumbnail icon mb-2" @error="setAltImg" />
                                    </td>
                                </template>
                            </CSelect>
                        </CCol>
                        <CCol sm="12" lg="12" xl="12" v-if="Bin">
                            <CSelect
                                :label="$t('label.client')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :value.sync="formImplemento.BinClient"
                                @blur="$v.formImplemento.BinClient.$touch()"
                                :options="optionsClientList"
                                :is-valid="hasError($v.formImplemento.BinClient)"
                                :invalid-feedback="errorMessage($v.formImplemento.BinClient)"
                            >      
                            </CSelect>
                        </CCol>
                        <CCol sm="12" lg="12" xl="12">
                            <CInput
                                :label="`${$t('label.name')} (ES)`"
                                addLabelClasses="text-right required"
                                v-uppercase
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="Bin ? `${$t('label.BinName')} (ES)` : `${$t('label.implementName')} (ES)`"
                                @blur="$v.formImplemento.NbImplemento.$touch()"
                                v-model="$v.formImplemento.NbImplemento.$model"
                                required 
                                :is-valid="hasError($v.formImplemento.NbImplemento)"
                                :invalid-feedback="errorMessage($v.formImplemento.NbImplemento)"
                                >                                                    
                            </CInput>
                        </CCol>
                        <CCol sm="12" lg="12" xl="12">
                            <CInput
                                :label="`${$t('label.name')} (EN)`"
                                addLabelClasses="text-right required"
                                v-uppercase
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="Bin ?  `${$t('label.BinName')} (EN)`: `${$t('label.implementName')} (EN)`"
                                @blur="$v.formImplemento.ImplementNameEn.$touch()"
                                v-model="$v.formImplemento.ImplementNameEn.$model"
                                required 
                                :is-valid="hasError($v.formImplemento.ImplementNameEn)"
                                :invalid-feedback="errorMessage($v.formImplemento.ImplementNameEn)"
                                >                                                    
                            </CInput>
                        </CCol>
                        <CCol sm="12" lg="12" xl="12">
                            <CTextarea
                                :label="$t('label.description')"
                                :placeholder="$t('label.implementDescription')"
                                type="textarea"
                                addLabelClasses="text-right"
                                v-uppercase
                                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-8 col-xl-8'}"
                                v-model="$v.formImplemento.DsImplemento.$model"
                                @blur="$v.formImplemento.DsImplemento.$touch()"
                                :plaintext="false"
                                :readonly="false"
                                :lazy="(true,400)"
                                :is-valid="hasError($v.formImplemento.DsImplemento)"
                                :invalid-feedback="errorMessage($v.formImplemento.DsImplemento)"
                                >                                                  
                            </CTextarea >
                        </CCol>
                        <CCol sm="12" lg="12" xl="12">
                            <div class="form-group form-row">
                                <label class="col-sm-11 col-lg-4 col-xl-4 d-flex justify-content-end">{{$t('label.quantity')}}</label>
                                <div class="input-group 'col-sm-11 col-lg-8 col-xl-8 input-group-sm justify-content-start">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        type="checkbox"
                                        :checked.sync="$v.formImplemento.FgApplyQuantity.$model"
                                    />
                                </div>
                                
                            </div>
                        </CCol>
                        <CCol sm="12" lg="12" xl="12" v-if="this.idImplemento !== 0">
                            <CSelect
                                :label="$t('label.status')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :is-valid="formImplemento.FgActImplemento"
                                v-model="formImplemento.FgActImplemento"
                                :value.sync="formImplemento.FgActImplemento"
                                :options="selectOptions"
                            />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CElementCover 
                 :opacity="0.8"
                v-if="apiStateLoading"
            >
                <label class="d-inline">{{$t('label.load')}}... </label>
                <CSpinner size="sm" />
            </CElementCover>
            <template #footer >
                <CButton
                    id="botonmarca2"
                    color="add"
                    class="d-flex align-items-center"
                    :disabled="apiStateLoading"
                    @click="registerDataImplemento"
                >
                    <CIcon name="checkAlt" />{{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import  FORMIMPLEMENTO from '@/_validations/implemento/ImplementoValidation';
    import UpperCase  from '@/_validations/uppercase-directive';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import PictureInput from '@/components/picture-input';
    import general from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { GenerateImgPlaceholder } from '@/_helpers/funciones';


    //data
    function data() {
        let _lang = this.$i18n.locale;
        return {
            showPicture: 1,
            activeTab: 0,
            isEdit:0,
            formGpoImplemento:{
                NbGpoImplemento:'',
                FgActGpoImplemento:true,
            },
            formImplemento:{
                IdGpoImplemento:'',
                NbImplemento:'',
                ImplementNameEn: '',
                FgApplyQuantity: false,
                DsImplemento:'',
                RutaImplemento:'',
                BinClient: '',
                FgActImplemento:true,
                Image_url:_lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
            },
            Image_original:'',
            ClientList: [],
            selectedTab:"tab1",
            originalActGpoImplemento:'',
            originalActImplemento:'',
            editModal: false,
            loading:false,
            Bin: false,
        }
    }
    //methods
    function registerDataImplemento(){
        let _lang = this.$i18n.locale
        try { 
            this.$v.formImplemento.$touch();
            if (this.$v.formImplemento.$pending || this.$v.formImplemento.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formImplemento.$pending || this.$v.formImplemento.$error) return;

            if(this.originalActImplemento !== '' && this.idImplemento !== 0){
                if(this.originalActImplemento !== this.formImplemento.FgActImplemento){
                    this.$swal
                    .fire(this.alertProperties({
                        text: `${this.$t('label.changeStatusQuestion')+' '} ${_lang=='en' ? this.formImplemento.ImplementNameEn : this.formImplemento.NbImplemento}?`,
                    }))
                    .then((result) => {
                        if (result.isConfirmed) {
                           this.submit();
                        }
                    })
                }else{
                    this.submit();
                }
            }else{
                this.submit();

            }
        } catch (e) {
            this.notifyError({ text: e });
        }
    }
    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }

    function filterIdGpoImplemento(event) {
        this.formImplemento.IdGpoImplemento=event.target.value;
        this.Bin = false;
        if(event.target.value == '7E0E9C9A-A93E-40FF-90A3-9DE8637FDCE7'){
            this.formImplemento.BinClient= '';
            this.Bin = true;
        }else{
            this.formImplemento.BinClient= '';
        }
    } 

    function closeModal(){
        if(this.apiStateLoading)
            return
        this.$store.dispatch('implemento/getGpoImplementolist', { filtro : 'ALL', type : 1});
        this.$store.commit('implemento/mutationModalImplement', false);
        this.$nextTick(() => { this.$v.$reset() })

    }
    function validarExtension(datos) {
        let ruta = datos.name;

        if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(datos.name)) {
            return false;
        }else{
            return true;
        }
    }
    function handleFileUploadImplemento(files, e) {
       // this.formImplemento.Image_url = '';
        this.formImplemento.RutaImplemento = this.$refs.imageInputImplemento.file;
        this.$v.formImplemento.RutaImplemento.$touch();  
    }
    async function submit() {
        try{
            const ImplementJson = {
                GpoImplementId:this.formImplemento.IdGpoImplemento,
                ClientTpId:this.formImplemento.BinClient,
                ImplementName:this.formImplemento.NbImplemento,
                ImplementNameEn: this.formImplemento.ImplementNameEn,
                FgApplyQuantity:this.formImplemento.FgApplyQuantity,
                ImplementDs:this.formImplemento.DsImplemento,
                ImplementRoute:this.formImplemento.RutaImplemento,
                UserId:JSON.parse(localStorage.getItem('user')).UserId,
                CompanyBranchId: this.Bin==true ? this.branch.CompanyBranchId : '',
            }
            if(this.editModal){
                ImplementJson.Status= this.formImplemento.FgActImplemento ? 1 : 0;
                ImplementJson.ImplementId= this.idImplemento;
            }

            let method = this.editModal ? 'PUT':'POST';
            let link = this.editModal ? 'Implement-update':'Implement-insert';

            if(typeof this.formImplemento.RutaImplemento !== 'string' && this.formImplemento.RutaImplemento !== null && this.formImplemento.RutaImplemento !== undefined && this.formImplemento.RutaImplemento !== ''){
                const response = await this.$http.file(link,this.formImplemento.RutaImplemento,this.formImplemento.Image_original);
                const info = response.data.data;
                ImplementJson.ImplementRoute = info.files[0].path;
            }

            await this.$http.ejecutar(method, link, ImplementJson, { root: 'ImplementJson' })
            .then((response) => {
                if ((response.status = 200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.notifySuccess({text: messageSuccess})
                    this.closeModal();
                }
            }).catch((e) => {
                this.notifyError({text: e});
            });
        } catch (error) {
            this.notifyError({text: error});
        }
    }

    function getClientList() {
        this.$store.state.implemento.apiStateForm = ENUM.LOADING;
        this.$http.get('ClientImplement-list', {CompanyBranchId: this.branch.CompanyBranchId})
            .then(response => {
            let listado = response.data.data;
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado ==''){
                return false;
            }
            this.ClientList = response.data.data;
            this.$store.state.implemento.apiStateForm = ENUM.INIT;
        }) 

    }
    //computed
  
    function optionsListGpoImplemento(){
        if(this.myDataGpoImplemento.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let _lang = this.$i18n.locale;
            const chart = [{
                value: '', 
                label: this.$t('label.select'),
            }];
            this.myDataGpoImplemento.map(function(e){
                if(e.FgActGpoImplement === true){
                    chart.push({
                        value: e.GpoImplementId,
                        label: _lang=='en' ? e.GpoImplementNameEn : e.GpoImplementNameEs,
                    })
                }
            })
            return chart;
        }
    }

    function optionsClientList(){
        if(this.ClientList.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let chart = [{
                value: '', 
                label: this.$t('label.select'),
            }];
            this.ClientList.map(function(e){
                chart.push({
                    value: e.ClientTpId,
                    label: e.ClientName,
                })
            })
            return chart;
        }
    }

    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }

     function selectOptions() {
        return [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ];
    }

    //watch
    function modalImplement(newQuestion,Oldquestion){
        let _lang = this.$i18n.locale;

        this.Bin = false;
        if( newQuestion && this.idImplemento !== 0 ){
            if(this.$refs.imageInputImplemento){
                this.$refs.imageInputImplemento.value=null;
                this.$refs.imageInputImplemento.file=null;
            }

            const icon = this.implementRoute;
            if(icon != null && icon != ''){
                this.formImplemento.Image_url = `${this.$store.getters["connection/getBase"]}${icon.replace('Public/', '', null,'i')}`;
            }else{
                let _lang = this.$i18n.locale;
                this.formImplemento.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
            }
            this.formImplemento.IdGpoImplemento=this.implementData.GpoImplementId;
            this.formImplemento.BinClient=this.implementData.ClientTpId;
            this.formImplemento.NbImplemento=this.implementData.ImplementName;
            this.formImplemento.ImplementNameEn=this.implementData.ImplementNameEn;
            this.formImplemento.FgApplyQuantity=this.implementData.FgApplyQuantity;
            this.formImplemento.DsImplemento=this.implementData.ImplementDs;
            this.formImplemento.RutaImplemento=this.implementData.ImplementRoute;
            this.formImplemento.FgActImplemento=this.implementData.FgActImplement;

            if(this.implementData.GpoImplementId == '7E0E9C9A-A93E-40FF-90A3-9DE8637FDCE7'){
                this.Bin = true;
            }
            //this.formImplemento.Image_url= this.implementData.ImplementRoute?`${this.$store.getters["connection/getBase"]}${this.implementData.ImplementRoute.replace('public/', '', null ,'i')}`:GenerateImgPlaceholder();
            this.originalActImplemento = this.implementData.FgActImplement;
            this.Image_original= this.formImplemento.Image_url;
            this.editModal = true;

            this.$v.$touch();  
        }

        if( newQuestion && this.iImplemento === 0 ){
            this.formImplemento.IdGpoImplemento='';
            this.formImplemento.BinClient='';
            this.formImplemento.NbImplemento='';
            this.formImplemento.ImplementNameEn='';
            this.formImplemento.FgApplyQuantity=false;
            this.formImplemento.DsImplemento='';
            this.formImplemento.RutaImplemento='';
            this.formImplemento.FgActImplemento=true;
            this.editModal = false;
            this.formImplemento.Image_url= _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
            this.originalActImplemento = '';
            this.Image_original = ''; 
            this.$nextTick(() => { this.$v.$reset() })
        }

        if(newQuestion === false){

            this.formImplemento.IdGpoImplemento='';
            this.formImplemento.BinClient='';
            this.formImplemento.NbImplemento='';
            this.formImplemento.ImplementNameEn='';
            this.formImplemento.FgApplyQuantity='';
            this.formImplemento.DsImplemento='';
            this.formImplemento.RutaImplemento='';
            this.formImplemento.FgActImplemento=true;
            this.editModal = false;
            this.formImplemento.Image_url=_lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
            this.originalActImplemento = '';
            this.Image_original = '';
            this.$nextTick(() => { this.$v.$reset() })
        }else{
            this.getClientList();
        }
    }
    function formReset(newQuestion,Oldquestion){
        this.$store.state.implemento.formReset = 0;
    }
    export default {
        name: 'modal-implement',
        data,
        mixins: [TipoGruaMixin,general, ModalMixin],
        components: {
            PictureInput,
        },
        props: {
            implementData: {
                type: Object,
                default: () => { }
            },
            titleModalImplement:{ 
                type: String,
                default: false,
            }
        },
        validations(){ return FORMIMPLEMENTO(this.Bin) },
        computed: {
            apiStateLoading,
            apiStateFormLoading,
            optionsListGpoImplemento,
            optionsClientList,
            selectOptions,
            ...mapState({
                branch: state => state.auth.branch,
                idGpoImplemento: state=> state.implemento.idGpoImplemento,
                idImplemento: state=> state.implemento.idImplemento,
                modalImplement: state=> state.implemento.modalImplement,
                myDataGpoImplemento: state => state.implemento.myDataGpoImplemento,
                formReset: state=> state.implemento.formReset,
                apiState: state => state.implemento.apiState,
                apiStateForm: state => state.implemento.apiStateForm,
                implementRoute: (state) => state.implemento.implementRoute,
            })
        },
        directives: UpperCase,
        methods:{
            getClientList,
            filterIdGpoImplemento,
            registerDataImplemento,
            srcImage,
            setAltImg,
            closeModal,
            validarExtension,
            handleFileUploadImplemento,
            submit,
        },
        watch:{
            modalImplement,
            formReset,
            /*
            idState*/
        }
    }
</script>

<style lang="scss">
    

    #c-image{
        width:95%;
        max-width:600px;
        position:relative;
        overflow:hidden;
        text-align:center;
        cursor:pointer;
        display: flex;

        &:before, &:after{
          content:"";
          width: calc(100% - 30px);
          height:4px;
          background:#FFF;
          transition:all .3s;
        }

        &:before{ 
          position: absolute;
          top:335px; 
          left:20px;
          content:""; 
          width:0%; 
          height:3px; 
          background:#fff;
          transition:all .3s ease;
        } 

        &:after{
          left:100%;
          bottom:10px
        }

        img{
          height:150px; 
          width:100%;
        }

        .text{
          text-align:center;
          position:absolute;
          transition:all .3s;
          
          transform:scale(1.5);
          opacity:0;
          
          h1{
            background: #AAA3A2;
            border: 2px solid #BEB9B8;
            border-radius: 5px;
            color:#FFF;
            padding:10px 20px;
          }
        }
        &:hover .text{
          transform:scale(1);
          opacity:1;
        }
    }
</style>
