//Data:
function data() {
  return {

  }
}

//Meodos:
function generalResponsiveCol(bayList) {
  let quantity = 0;

  bayList.forEach((bay) => {
    let colToDeck = this.colNum(bay, "TO DECK"),
      colUnderDeck = this.colNum(bay, "UNDER DECK");

    let colNum =
      colToDeck >= colUnderDeck
        ? bay.FgCrossLineToDeck || bay.FgCrossLineToDeck === 1
          ? colToDeck + 1
          : colToDeck
        : bay.FgCrossLineUnderDeck || bay.FgCrossLineUnderDeck === 1
        ? colUnderDeck + 1
        : colUnderDeck;

    if (quantity < colNum) quantity = colNum;
  });

  return quantity;
}
function colNum(bay, type) {
  if (type === "UNDER DECK" && bay) {
    return (
      Number.parseInt(bay.RowStarboardUnderDeck) +
      Number.parseInt(bay.RowPortUnderDeck)
    );
  } else if (type === "TO DECK" && bay) {
    return (
      Number.parseInt(bay.RowStarboardToDeck) +
      Number.parseInt(bay.RowPortToDeck)
    );
  } else return 0;
}
function getPositionsToDeck(bay) {
  return bay.PositionJson
    ? bay.PositionJson.filter((item) => item.BayLocName === "TO DECK")
    : [];
}
function getPositionsUnderDeck(bay) {
  return bay.PositionJson
    ? bay.PositionJson.filter((item) => item.BayLocName === "UNDER DECK")
    : [];
}

//Computeds


export default {
  data,
  methods: {
    generalResponsiveCol,
    colNum,
    getPositionsToDeck,
    getPositionsUnderDeck,
  },
  computed: {}
}