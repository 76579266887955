<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="modal-content-classif"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow>
        <CCol sm="12">
          <CSelect
            :label="$t('label.group')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            :placeholder="$t('label.toSelect')"
            v-uppercase
            :options="computedTpGeneralCLasification"
            :value="TpCargoId"
            :is-valid="hasError($v.TpCargoId)"
            :invalid-feedback="errorMessage($v.TpCargoId)"
            v-model.trim="$v.TpCargoId.$model"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CInput
            :label="$t('label.classification')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            v-uppercase
            :is-valid="hasError($v.ClassificationName)"
            :invalid-feedback="errorMessage($v.ClassificationName)"
            v-model.trim="$v.ClassificationName.$model"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CSelect
            :label="$t('label.weightRange')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            :placeholder="$t('label.toSelect')"
            v-uppercase
            :options="rangoPeso"
            :value="FgApplyWeight"
            @change="selectWeight($event)"
            :is-valid="hasError($v.FgApplyWeight)"
            :invalid-feedback="errorMessage($v.FgApplyWeight)"
            v-model.trim="$v.FgApplyWeight.$model"
          />
        </CCol>
      </CRow>

      <CRow v-if="showFormToSelectWeight">
        <CCol sm="12">
          <CSelect
            :label="$t('label.weightLimit')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            :placeholder="$t('label.toSelect')"
            v-uppercase
            :options="rangoLimitWeight"
            :value="Operator"
            @change="selectLimitWeight($event)"
            :is-valid="hasError($v.Operator)"
            :invalid-feedback="errorMessage($v.Operator)"
            v-model.trim="$v.Operator.$model"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            :label="$t('label.weight')+' (KG)'"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            append="KG"
            @keypress="f_num($event)"
            :is-valid="hasError($v.Weight)"
            :invalid-feedback="errorMessage($v.Weight)"
            v-model.trim="$v.Weight.$model"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12" v-if="editModal">
          <CSelect
            :label="$t('label.status')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
            v-uppercase
            :options="statusOptions"
            :value="Status"
            :is-valid="hasError($v.Status)"
            :invalid-feedback="errorMessage($v.Status)"
            v-model.trim="$v.Status.$model"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          outline
          color="add"
          @click.stop="editModal?statusConfirmation(TpCargoSelected.FgActTpCargoClassif, Status,submit,ClassificationName):submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import clasificationValidations from '@/_validations/carga-general/clasificationValidations';
import GeneralMixin from '@/_mixins/general';
import ImdgMixin from '@/_mixins/imdg';
import ModalMixin from '@/_mixins/modal';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    itemsSelectedGpo: [],
    TpCargoId: '',
    selectAll: false,
    resetSelectAll: false,
    TpCargoId: '',
    ClassificationName: '',
    Status: 1,
    FgApplyWeight: 0,
    Operator: '<',
    Weight: 0,
    showFormToSelectWeight: false,
    TpCargoClassifId: '',
    isEdit: false,
    isSubmit: false,
  };
}

function rangoLimitWeight() {
  return [
    { label: this.$t('label.greaterOrEqualThan'), value: '>' },
    { label: this.$t('label.lessOrEqualThan'), value: '<' },
  ];
}

function rangoPeso() {
  return [
    { label: 'N/A', value: 0 },
    { label: this.$t('label.Applies'), value: 1 },
  ];
}

async function mountSelectGpoClasification() {
  this.loadingOverlay = true;

  await this.$http
    .get('TpGeneralCargo-list?Filter=ACTIVO')
    .then((response) => (this.itemsSelectedGpo = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedTpGeneralCLasification() {
  if (this.itemsSelectedGpo.length > 0) {
    return this.itemsSelectedGpo.map((item, index) => {
      if (index == 0 && !this.editModal) {
        this.TpCargoId = item.TpCargoId;
      }
      return {
        label: item.TpCargoName,
        value: item.TpCargoId,
      };
    });
  }
}

function selectLimitWeight(event) {
  this.Operator = event.target.value;
}

function closeModal(event) {
  this.showModal = false;
  this.resetForm();
  this.$emit('updated-modal', event);
}

function selectWeight(event) {
  if (event.target.value == 1) {
    this.showFormToSelectWeight = true;
  } else {
    this.showFormToSelectWeight = false;
  }
  this.FgApplyWeight = event.target.value;
}

async function submit() {
  try {

    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpCargoClassificationJson = {};
    TpCargoClassificationJson.TpCargoId = this.TpCargoId;
    TpCargoClassificationJson.ClassificationName = this.ClassificationName;
    TpCargoClassificationJson.FgApplyWeight = this.FgApplyWeight;
    if (this.FgApplyWeight == 1) {
      TpCargoClassificationJson.Operator= this.Operator;
      TpCargoClassificationJson.Weight= this.Weight;
    }
    if(this.editModal){
      TpCargoClassificationJson.TpCargoClassifId = this.TpCargoClassifId;
      TpCargoClassificationJson.Status = this.Status;
    }

    let method = this.editModal? 'PUT':'POST';
    let ruta = this.editModal? 'TpGeneralCargoClassification-update':'TpGeneralCargoClassification-insert';
    await this.$http
      .ejecutar(method, ruta, TpCargoClassificationJson, {
        root: 'TpCargoClassificationJson',
      })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.closeModal(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.notifyError({ text: e });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
}
function resetForm() {
  this.TpCargoId = '';
  this.ClassificationName = '';
  this.TpCargoClassifId = '';
  this.Status = 1;
  this.FgApplyWeight = 0;
  this.Operator = '<';
  this.Weight = 0;
  this.showFormToSelectWeight = false;
  this.isEdit = false;
  this.$v.$reset();
}

function setForm(val){
  if (Object.keys(val).length !== 0) {
    this.TpCargoId = val.TpCargoId;
    this.ClassificationName = val.ClassificationName;
    this.TpCargoClassifId = val.TpCargoClassifId;
    this.Status = val.Status === 'ACTIVO' ? 1 : 0;
    this.FgApplyWeight = val.FgApplyWeight ? 1 : 0;
    this.Operator = val.Operator !== null && val.Operator !== '' ? val.Operator : '<';
    this.Weight = val.Weight !== null && val.Weigh !== '' ? val.Weight : 0;
    this.showFormToSelectWeight = val.FgApplyWeight ? true : false;
    this.$v.$touch();
  }
}
/*
function title(){
  if(!this.isEdit)
    return this.$t('label.nueva')+' '+this.$t('label.generalCargoGroupClassification');
  else
    return this.$t('label.edit')+' '+this.$t('label.generalCargoGroupClassification');
}
*/

export default {
  name: 'modalClasification',
  data,
  props: {
    modal: Boolean,
    TpCargoSelected: Object,
    title: String,
    editModal: Boolean,
  },
  mixins: [
    GeneralMixin, 
    ImdgMixin,
    ModalMixin,
  ],
  methods: {
    submit,
    closeModal,
    selectWeight,
    selectLimitWeight,
    mountSelectGpoClasification,
    resetForm,
    setForm,
  },
  validations: clasificationValidations,
  computed: {
    computedTpGeneralCLasification,
    rangoLimitWeight,
    rangoPeso,
    //title
  },
  directives: UpperCase,
  watch: {
    modal: function(val) {
      this.showModal = val;
      if (val === true) {
        this.mountSelectGpoClasification();
      } else {
        this.resetForm();
      }
    },
    TpCargoSelected: function(val) {
      this.setForm(val);
    },
  },
};
</script>
<style lang="scss">
.modal-content-classif {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>
