<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <ModalDelay 
            :modal.sync="showModal" 
            :editModal="editModal" 
            :item-delay="itemDelay" 
            @submited="handleSubmit"
            :titulo="titulo" 
        />
        <CRow class="mt-3">
            <CCol sm="12" lg="4" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-auto text-right', input: 'col-sm-12 col-lg-7'}"
                    :label="$t('label.crane')"
                    :options="craneOptions"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="getDeviceDelay"
                />
            </CCol>
            <CCol sm="12" lg="8" class="d-flex align-items-center justify-content-end">
                <h6 class="text-warning mr-3 mt-2">
                    <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentTime')}}&nbsp;
                </h6> 

                <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center"
                    v-c-tooltip="{
                        content: $t('label.delay'),
                        placement: 'top-end'
                    }"
                    @click="newDelay()"
                    >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>       
                </CButton>
            </CCol> 
        </CRow>

        <CRow class="mt-3">
            <CCol sm="12" >
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="formatedItems"
                    :fields="fields"
                    :items-per-page="5"
                    hover
                    sorter
                    small
                    pagination
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"                   
                    :noItemsView="tableText.noItemsViewText"              
                    details     
                >
                    <template #alertGif="{ item }">
                        <td class="center-cell">
                            <div class="animation-item ml-2" style="display: inline-block;" v-if="item.FgOpen">
                                <h3 class="text-danger">
                                    <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                                </h3>
                            </div>
                        </td>
                    </template>
                    <template #RowNumber="{ item }">
                        <td class="center-cell">
                            {{ item.RowNumber }}
                        </td>
                    </template>
                
                    <template #show_details="{item}">
                        <td class="text-center">
                            <CButton
                                v-if="item.MovsJson?.length !== 0 || item.DelaysJson?.length !== 0"
                                color="watch"
                                v-c-tooltip="{
                                    content: $t('label.toView')+' '+$t('label.details'),
                                    placement: 'top-end',
                                }"
                                size="sm"
                                @click="toggleLevel(item)"
                                class="mb-1"
                            >
                                <CIcon name="eye" />
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                        <CCollapse
                            :show="Boolean(item._toggled)"
                            :duration="collapseDuration"
                        >
                            <div class="py-3 px-2">
                                <dataTableExtended
                                    class="align-center-row-datatable table-lv-2 "
                                    :items="formatedItemsDetails"
                                    :fields="fieldDetails"
                                    :items-per-page="5"
                                    sorter
                                    pagination
                                    :table-filter="tableText.tableFilterText"
                                    :items-per-page-select="tableText.itemsPerPageText"
                                    :noItemsView="tableText.noItemsViewText"                                  
                                >
                                    <template #alertGifItem="{ item }">
                                        <td class="center-cell">
                                            <div class="animation-item ml-2" style="display: inline-block;" v-if="item.FgOpen">
                                                <h3 class="text-danger">
                                                    <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                                                </h3>
                                            </div>
                                        </td>
                                    </template>
                                    <template #Id="{ item }">
                                        <td class="center-cell">
                                            {{ item.Id }}
                                        </td>
                                    </template>
                                    <template #TransactionFinish="{ item }">
                                        <td class="center-cell" v-if="!item.FgOpen">
                                            {{ item.TransactionFinish }}
                                        </td>
                                        <td class="center-cell" v-if="item.FgOpen">
                                        </td>
                                    </template>
                                    
                                    <template #TotalTime="{ item }">
                                        <td class="center-cell">
                                            {{ item.TotalTime }}
                                        </td>
                                    </template>
                                    <template #show_details="{item}">
                                        <td class="text-center">
                                            <CButton
                                                color="edit"
                                                v-c-tooltip="{
                                                    content: $t('label.edit')+' '+$t('label.delay'),
                                                    placement: 'top-end',
                                                }"
                                                size="sm"
                                                @click="editDelay(item)"
                                                class="mb-1"
                                            >
                                                <CIcon name="pencil" />
                                            </CButton>
                                        </td>
                                    </template>
                                </dataTableExtended>
                            </div>
                        </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>    
</template>

<script>
    import { mapState } from 'vuex';
    import ModalDelay from './modal-delay';
    import GeneralMixin from '@/_mixins/general';
    import { DateFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            items: [],
            itemDetails: [],
            editModal: false,
            showModal: false,
            loadingOverlay: false,
            collapseDuration: 0, //de detalles
            itemDelay: {},
            titulo: '',
            VisitCraneId: '',
            dataCrane: [],
            delayValue: false,
        }
    }
    //methods
    function newDelay(){
        this.titulo = this.$t('label.nuevo')+' '+this.$t('label.delay');
        this.editModal = false;
        this.showModal = true;
        this.itemDelay = {};
    }

    function editDelay(item) {
        let  _lang = this.$i18n.locale;
        this.titulo =  _lang=='en' ? item.DelayCustomNameEn : item.DelayCustomNameEs;
        this.titulo = this.$t('label.edit')+' '+this.$t('label.delay')+': '+this.titulo;
        this.itemDelay = Object.assign({}, item);
        this.editModal = true;
        this.showModal = true;
    }

    function handleSubmit(){
        this.VisitCraneId = '';
        this.getDeviceDelay();
    }

    function getDeviceDelay() {
        if (this.dataCrane.length > 0){ 
            this.loadingOverlay = true;
            this.$http.ejecutar('GET', 'VisitDeviceDelay-list', { VisitId: this.Visit, VisitCraneId: this.VisitCraneId })
                .then(responseDeviceDelay => {
                    let listado = responseDeviceDelay.data.data;
                    if(listado&&listado.length > 0){
                        this.items = listado;
                    }else{
                        this.items = [];
                    }
                }).catch( err => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                }).then(() => {
                    this.loadingOverlay = false;
                });
        }
        
    }

    async function closeShow(item) {
        let moduleOpen;
 
        moduleOpen = this.formatedItems.find((val) => val._toggled == true);

        if (moduleOpen != undefined && moduleOpen.RowNumber != item.RowNumber) {
            moduleOpen._toggled = !moduleOpen._toggled;
        }
    }

    async function toggleLevel(item) {
        await this.closeShow(item);
        const modulePadre = item; //this.items.find((val) => val.IdX === item.IdX);
        this.delayValue = modulePadre.DelaysJson.length>0 ? true : false;
        this.itemDetails = modulePadre.MovsJson.length>0 ? modulePadre.MovsJson : modulePadre.DelaysJson;
        item._toggled = !item._toggled;
        this.collapseDuration = 300;
        this.$nextTick(() => {
            this.collapseDuration = 0;
        });
    }

    async function loadData() {
        this.loadingOverlay = true;
        let peticiones = [
            this.$http.ejecutar('GET', 'VisitOperativeCrane-list', { VisitId: this.Visit }),
            this.$http.ejecutar('GET', 'VisitDeviceDelay-list', { VisitId: this.Visit, VisitCraneId: '' }),
        ];
        Promise.all(peticiones)
        .then((responses) => {
            let CraneList = responses[0].data.data;
            let DeviceDelayList = responses[1].data.data;
            this.dataCrane = CraneList && CraneList.length != 0  ? CraneList : [];
            this.items =  DeviceDelayList && DeviceDelayList.length != 0  ? DeviceDelayList : [];
        }).catch((err) => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            this.loadingOverlay = false;
        });
    }

    function refreshComponent() {
        this.titulo = '';
        this.VisitCraneId = '';
        this.items = [];
        this.itemDetails = [];
        this.itemDelay = {};
        this.dataCrane = [];
        this.editModal = false;
        this.showModal = false;
        this.loadData();
    }

    //computed
    function formatedItems() {
        if(this.items.length != 0){
            return this.items.map((item) => {
                return {
                    ...item,
                    RowNumber: item.IdX,
                    CustomCode: item.CustomCode ? item.CustomCode : '',
                    TdrCode: item.TdrCode ? item.TdrCode : '',
                    DelayCustomNameEn: item.DelayCustomNameEn ? item.DelayCustomNameEn : '',
                    DelayCustomNameEs: item.DelayCustomNameEs ? item.DelayCustomNameEs : '',
                    MovsJson: item.MovsJson !== null ? item.MovsJson : [],
                    DelaysJson: item.DelaysJson !== null ? item.DelaysJson : [],
                    TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                    TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                    TotalTime: item.TotalTime ? item.TotalTime :  '',
                    ResponsibleNameEn: item.ResponsibleNameEn ? item.ResponsibleNameEn : '',
                    ResponsibleNameEs: item.ResponsibleNameEs ? item.ResponsibleNameEs : '',
                    FgOpen: item.FgOpen ? true : false,
                    _classes: 'table-secondary',
                    _toggled: false,
                };
            });
        }else{
            return [];
        }
    }

    function formatedItemsDetails(){
        if(this.delayValue){ 
            return this.itemDetails.map((item) => Object.assign({}, item, {
                Id: item.IdX ? item.IdX : '',
                CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                DelayCustomNameEn: item.DelayCustomNameEn ? item.DelayCustomNameEn : '',
                DelayCustomNameEs: item.DelayCustomNameEs ? item.DelayCustomNameEs : '',
                TdrCode: item.TdrCode ? item.TdrCode : '',
                TotalTime: item.TotalTime ? item.TotalTime : '',
                TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                FgActivo: item.TransactionFinish ? true : false,
                FgOpen: item.FgOpen ? true : false,
            }));
        }
    }

    function fields () {
        let  _lang = this.$i18n.locale;
        return [
            { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
            { key: 'RowNumber', label: '#', filter: false,_style: 'width:2%; text-align:center;', },
            { key: 'CustomCode',label: this.$t('label.timeCode'), _style:'width:12%;', _classes:'text-center' },
            { key:  _lang=='en' ? 'DelayCustomNameEn' : 'DelayCustomNameEs', label:  _lang=='en' ? this.$t('label.name')+' (EN)' : this.$t('label.name')+' (ES)' , _style:'width:22%;', _classes:'text-center' },
            { key:  _lang=='en' ? 'ResponsibleNameEn' : 'ResponsibleNameEs', label:  _lang=='en' ? this.$t('label.responsible')+' (EN)' : this.$t('label.responsible')+' (ES)' , _style:'width:22%;', _classes:'text-center' },
            { key: 'TdrCode',label: this.$t('label.TdrCode'), _style:'width:12%;', _classes:'text-center' },
            { key: 'TotalTime',label: this.$t('label.TotalHours'), _style:'width:13%;', _classes:'text-center' },  
            { key: 'show_details', label: '', sorter: false, filter: false, _style: ' min-width:45px; width:5%;', _classes:'text-center' },
        ]
    }

    function fieldDetails () {
        let  _lang = this.$i18n.locale;
        if(this.delayValue === true){ 
            return [
                { key: 'alertGifItem', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
                { key: 'Id', label: '#', filter: false,_style: 'width:2%; text-align:center;', },
                { key: 'CraneAlias',label: this.$t('label.crane'), _style:'width:14%;', _classes:'text-center' },  
                { key:  _lang=='en' ? 'DelayCustomNameEn' : 'DelayCustomNameEs', label:  _lang=='en' ? this.$t('label.name')+' (EN)' : this.$t('label.name')+' (ES)' , _style:'width:30%;', _classes:'text-center' },
                { key: 'TransactionBegin',label: this.$t('label.startDate'), _style:'width:13%;', _classes:'text-center' }, 
                { key: 'TransactionFinish',label: this.$t('label.endDate'), _style:'width:13%;', _classes:'text-center' }, 
                { key: 'TotalTime',label: this.$t('label.TotalHours'), _style:'width:15%;', _classes:'text-center' },  
                { key: 'show_details', label: '', sorter: false, filter: false, _style: 'min-width:45px; width:5%;', _classes:'text-center' }
            ]
        }
    }

    function craneOptions(){
        if(this.dataCrane.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.dataCrane.map(function(e){
                chart.push({
                    value: e.VisitCraneId, 
                    label: e.CraneAlias,
                })
                
            })
            return chart;
        }
    }

    export default {
        name:'index',
        data,
        mixins: [GeneralMixin],
        methods:{
            refreshComponent,
            editDelay,
            newDelay,
            handleSubmit,
            getDeviceDelay,
            toggleLevel,
            closeShow,
            loadData,
        },
        computed:{
            formatedItems,
            fields,
            formatedItemsDetails,
            fieldDetails,
            craneOptions, 
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemTime: state => state.visitas.dropItemTime,
            })
        },
        components: {
            ModalDelay,
        },
        watch:{
            dropItemTime:async function(newValue,OldValue){
                if (newValue == 1){
                    if(this.$store.state.visitas.VisitId != ''){
                        this.refreshComponent();
                    }         
                }
            },
        }
    }
</script>

<style lang="scss">
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .not-margin-slot{
        .form-group {
            margin-bottom: 0px;
        }
    }
    .center-cell {
        text-align: center;
    }
 
    .animation-item{
        -webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
    }
    /**
    * ----------------------------------------
    * animation pulse
    * ----------------------------------------
    */
    @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
    @keyframes pulse{
        0%,
        100% {
            transform:scale(1.1)
        }
        50% {
            transform:scale(.8)
        }
    }
</style>