<template>
  <div>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      :closeOnBackdrop="false"
      :size="checkSizeModal"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CRow class="justify-content-center">
        <CCol :sm="checkColumn">
          <CRow>
            <CCol sm="12">
              <CInput
                :label="$t('label.code')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.ServiceCode)"
                addLabelClasses="required text-right"
                class="w-100 "
                v-uppercase
                :is-valid="hasError($v.ServiceCode)"
                v-model.trim="$v.ServiceCode.$model"
                maxlength="100"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                :label="$t('label.vesselType')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.TpVesselId)"
                addLabelClasses="required text-right"
                :options="computedTpVessel"
                :value="TpVesselId"
                class="w-100"
                v-uppercase
                @change="selectTpVessel($event)"
                :is-valid="hasError($v.TpVesselId)"
                v-model.trim="$v.TpVesselId.$model"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                v-if="!vesselContainerShip"
                :label="$t('label.shippingAgency')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.AgencyClientTpId)"
                addLabelClasses="required text-right"
                class="w-100"
                v-uppercase
                :options="computedShippingAgent"
                :value="AgencyClientTpId"
                :is-valid="hasError($v.AgencyClientTpId)"
                v-model.trim="$v.AgencyClientTpId.$model"
              >
              </CSelect>
            </CCol>
            <CCol sm="12">
              <CSelect
                v-if="vesselContainerShip"
                :label="$t('label.representative')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.LineRepresServiceId)"
                addLabelClasses="text-right"
                class="w-100"
                v-uppercase
                :options="computedShippingAgencyRepresentative"
                :value="LineRepresServiceId"
                @change="selectLineRepresentanteService($event)"
                :is-valid="hasError($v.LineRepresServiceId)"
                v-model.trim="$v.LineRepresServiceId.$model"
              >
                <template #append>
                  <div class="d-flex align-items-start">
                    <CButton
                      size="sm"
                      @click="nuevaLinea(false)"
                      color="add"
                      class="pb-2"
                      v-c-tooltip="{
                        content: $t('label.newShippingLine'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-playlist-add"/>
                    </CButton>
                  </div>
                </template>
              </CSelect>
            </CCol>
            <CCol sm="12" v-if="newLine">
              <AddLinea :value="ShippingLine" @child-refresh="nuevaLinea" />
            </CCol>
            <CCol sm="12" class="text-area-descripcion-servicio">
              <CTextarea
                :label="$t('label.description')"
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.ServiceDs)"
                addLabelClasses="required text-right"
                class="w-100"
                v-uppercase
                :is-valid="hasError($v.ServiceDs)"
                v-model.trim="$v.ServiceDs.$model"
              />
            </CCol>
            <CCol sm="3" class="text-right pr-2">
              <label class="mt-1"><b class="text-danger">*</b> {{$t('label.inicio')}}</label>
            </CCol>
            <CCol sm="9" class="pl-1 pr-4">
              <CRow>
                <CCol sm="5">
                  <vue-datepicker
                    valueType="format"
                    :open="openDateSatrt"
                    format="DD/MM/YYYY"
                    :editable="false"
                    @update:open="functionDateStart($event)"
                    @clickoutside="openDateSatrt = false"
                    @select="openDateSatrt = false"
                    @pick="openDateSatrt = false"
                    @close="openDateSatrt = false"
                    :clearable="false"
                    :lang="this.$i18n.locale"
                    :disabled-date="fechaNoValida"
                    v-model.trim="$v.StartDate.$model"
                    :append-to-body="false"
                    class="w-100"
                  >
                    <template #input>
                      <CInput
                        :invalid-feedback="errorMessage($v.StartDate)"
                        addLabelClasses="required text-right"
                        class="w-100 float-left"
                        v-uppercase
                        placeholder="dd/mm/aaaa"
                        v-model.trim="$v.StartDate.$model"
                        @keypress="rightDate($event)"
                        :is-valid="hasError($v.StartDate)"
                        addInputClasses="pr-0"
                      >
                        <template #append-content>
                          <CIcon name="cil-calendar" />
                        </template>
                      </CInput>
                    </template>
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </CCol>
                <CCol sm="2" class="text-right pr-0">
                <label class="mt-1"><b class="text-danger">*</b> {{$t('label.fin')}}</label>
                </CCol>
                <CCol sm="5">
                    <vue-datepicker
                      valueType="format"
                      format="DD/MM/YYYY"
                      :editable="false"
                      :open="openDateEnd"
                      @update:open="functionDateEnd($event)"
                      @clickoutside="openDateEnd = false"
                      class="w-100 vue-datepicker-drive:focus"
                      @select="openDateEnd = false"
                      @pick="openDateEnd = false"
                      @close="openDateEnd = false"
                      :clearable="false"
                      :disabled-date="fechaNoValida"
                      :lang="this.$i18n.locale"
                      v-model.trim="$v.EndDate.$model"
                      :append-to-body="false"
                    >
                      <template #input>
                        <CInput
                          :invalid-feedback="errorMessage($v.EndDate)"
                          addLabelClasses="required text-right"
                          class="w-100"
                          v-uppercase
                          placeholder="dd/mm/aaaa"
                          v-model.trim="$v.EndDate.$model"
                          @keypress="rightDate($event)"
                          :is-valid="hasError($v.EndDate)"
                          addInputClasses="pr-0"
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                </CCol>
              </CRow> 
            </CCol>
            <CCol sm="3" class="text-right pr-2" v-if="!editModal">
              <label v-if="!computedRequiredTeus" class="mt-1">{{$t('label.ton')}}</label>
              <label v-if="computedRequiredTeus" class="mt-1"> TEUS</label>
            </CCol>
            <CCol sm="9" class="pl-1 pr-4" v-if="!editModal">
              <CRow>
                <CCol sm="5"  v-if="!computedRequiredTeus">
                  <CInput
                    v-if="!computedRequiredTeus"
                    class="w-100"
                    v-uppercase
                    @keypress="decimalNumber($event)"
                    v-model="ServiceTonnage"
                    maxLength="6"
                    :is-valid="hasError($v.ServiceTonnage)"
                    :invalid-feedback="errorMessage($v.ServiceTonnage)"
                  />
                </CCol>
                <CCol sm="5" v-if="computedRequiredTeus">
                  <CInput
                    v-if="computedRequiredTeus"
                    class="w-100"
                    v-uppercase
                    @keypress="decimalNumber($event)"
                    v-model="ServiceTeus"
                    maxLength="6"
                    :is-valid="hasError($v.ServiceTeus)"
                    :invalid-feedback="errorMessage($v.ServiceTeus)"
                  />
                </CCol>
                <CCol sm="7">
                </CCol>
              </CRow>
            </CCol>

            <CCol sm="3" class="text-right pr-2" v-if="editModal">
              <label v-if="!computedRequiredTeus" class="mt-1">{{$t('label.ton')}}</label>
              <label v-if="computedRequiredTeus" class="mt-1"> TEUS</label>
            </CCol>
            <CCol sm="9" class="pl-1 pr-4" v-if="editModal">
              <CRow>
                <CCol sm="5"  v-if="!computedRequiredTeus">
                    <CInput
                      v-if="!computedRequiredTeus"
                      addLabelClasses="text-right"
                      class="w-100"
                      v-uppercase
                      @keypress="decimalNumber($event)"
                      v-model="ServiceTonnage"
                      :is-valid="hasError($v.ServiceTonnage)"
                      :invalid-feedback="errorMessage($v.ServiceTonnage)"
                    />
                </CCol>
                <CCol sm="5" v-if="computedRequiredTeus">
                  <CInput
                    v-if="computedRequiredTeus"
                    addLabelClasses="text-right"
                    class="w-100"
                    v-uppercase
                    @keypress="decimalNumber($event)"
                    v-model="ServiceTeus"
                    :is-valid="hasError($v.ServiceTeus)"
                    :invalid-feedback="errorMessage($v.ServiceTeus)"
                  />
                </CCol>
                <CCol sm="2" class="text-right pr-0">
                <label class="mt-1"><b class="text-danger">*</b> {{$t('label.status')}}</label>
                </CCol>
                <CCol sm="5" v-if="editModal">
                  <CSelect
                    addLabelClasses="required text-right"
                    class="w-100"
                    v-uppercase
                    :invalid-feedback="$t('label.required')"
                    :value="Status"
                    :options="computedStatusList"
                    @change="selectStatus($event)"
                    v-model.trim="$v.Status.$model"
                    :is-valid="hasError($v.Status)"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>

        <CCol sm="5" style="margin-top: -0.8rem !important;" v-if="vesselContainerShip">
          <CRow v-if="vesselContainerShip">
            <CCol sm="11" v-if="vesselContainerShip">
              <dataTableExtended
                v-if="vesselContainerShip"
                class="align-center-row-datatable"
                size="sm"
                :fields="fields"
                :items="computedCheckShippingAgency"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingTable"
                 
              >
                <template #loading>
                  <loading/>
                </template>
                <template #checked="{ item }">
                  <td class="center-cell ">
                    <CInputCheckbox
                      custom
                      class="checkbook-filter"
                      :checked="item.initialActive"
                      @update:checked="checkedAndUnchekedSelect($event, item)"
                    />
                  </td>
                </template>
                <template #checked-filter>
                  <td class="center-cell ">
                    <CInputCheckbox
                      :label="$t('label.all')"
                      class="checkbook-filter text-center"
                      custom
                      :checked="selectAll"
                      @update:checked="selectAllChecked($event)"
                    />
                  </td>
                </template>
                <template #options="{ item }">
                  <td class="center-cell">
                      <CButton
                        color="edit"
                        size="sm"
                        @click="nuevaLinea(item)"
                        v-c-tooltip="{
                          content: $t('label.edit')+$t('label.line'),
                          placement: 'top',
                        }"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCol>

      </CRow>

      <template #footer>
        <CButton v-if="!editModal" outline color="add" @click="saveService">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="editModal"
          outline
          color="add"
          @click="submitUpdateServicio"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import { servicio } from '@/_validations/servicio/servicio';
import mixinServicio from '@/_mixins/servicio';
import CargaGranel from '@/_mixins/carga-granel';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
import AddLinea from '@/pages/linea/add-linea';
import { mapState } from 'vuex';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    newLine: false,
    icono: 'arrowDown',
    ShippingLine: false,
    vesselContainerShip: false,
    statusSelectOptions: [],
    Status: 1,
    openDate: false,
    DriverId: '',
    
    itemsLinea: [],
    selectAll: false,
    resetSelectAll: false,
    ServiceCode: '',
    StartDate: '',
    EndDate: '',
    ServiceTonnage: 0,
    ServiceDs: '',
    ServiceTeus: 0,
    LineJson: [],
    TpVesselId: '',
    LineRepresServiceId: '',
    AgencyClientTpId: '',
    itemsTpVessel: [],
    itemsAgency: [],
    itemsAgencyRepresentative: [],
    itemsShippingAgent: [],
    openDateSatrt: false,
    openDateEnd: false,
    teusRequired: false,
    config: {},
    CompanyBranchId: '',
    temporalRepresentativAgency: '',
    requiredTeus: false,
    requiredTon: false,
    ServiceId: '',
    statusToVerify: '',
  };
}

function checkSizeModal() {
  return this.vesselContainerShip ? 'xl' : 'lg';
}

function checkColumn() {
  return this.vesselContainerShip ? '7' : '11';
}
function optionsStatus(){
  return [
    { label: this.$t('label.ACTIVO'), value: 1 },
    { label: this.$t('label.INACTIVO'), value: 0 },
  ];
}

function fields(){
  return [
    { label: '#', key: 'Nro', sorter: false, filter: false, _style: 'width:1%; text-align:center;', _classes: 'text-center', },
    { label: this.$t('label.shippingLineName'), key: 'ShippingLineName', _style: 'width:80%;',_classes: 'text-uppercase' },
    {
      label: this.$t('label.apply'),
      key: 'checked',
      sorter: false,
      filter: false,
      _classes:'cell-center text-center',
       _style: ' width:10%;'
    },
    { key: 'options', label: '', sorter: false, filter: false, _style: 'min-width: 50px; width:1%;' },
  ];
}


function closeModal(event) {
  this.showModal = false;
  this.openDateSatrt = false;
  this.$emit('updated-modal', event);
}

function functionDateStart(event) {
  if (event) {
    this.openDateSatrt = event;
  }
}

function functionDateEnd(event) {
  if (event) {
    this.openDateEnd = event;
  }
}

async function mountedTpVessel() {
  this.loadingOverlay = true;
  await this.$http
    .get('TpVessel-list?Filter=ACTIVO')
    .then((response) => {
      this.loadingOverlay = false;
      this.itemsTpVessel = response.data.data;
      if (!this.editModal) {
        this.TpVesselId = this.itemsTpVessel[0].TpVesselId;
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedTpVessel() {
  if (this.itemsTpVessel.length > 0) {
    return this.itemsTpVessel.map((item, index) => {
      return {
        label: item.TpVesselName,
        value: item.TpVesselId,
      };
    });
  }
}

async function mounteStatusList() {
  this.loadingOverlay = true;
  await this.$http
    .get('ServiceStatus-list')
    .then((response) => {
      this.loadingOverlay = false;
      this.statusSelectOptions = response.data.data;
      if (response.data.data.length > 0) {
        response.data.data.map((item) => {
          if (item.ValueDs === 'ACTIVO') {
            this.statusToVerify = item.ProcessStatusId;
          }
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedStatusList() {
  if (this.statusSelectOptions.length > 0) {
    return this.statusSelectOptions.map((item, index) => {
      return {
        label: item.ValueDs,
        value: item.ProcessStatusId,
      };
    });
  }
}

async function mountedShippingAgency() {
  this.loadingOverlay = true;
  this.loadingTable = true;
  this.itemsAgency = [];
  await this.$http
    .get('shipping-agency-list?Filter=ACTIVO')
    .then((response) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.itemsAgency = response.data.data;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedShippingAgency() {
  if (this.itemsAgency.length > 0) {
    return this.itemsAgency.map((item, index) => {
      return {
        label: item.ShippingLineName,
        value: item.ShippingLineId,
      };
    });
  }
}

async function mountedShippingAgencyRepresentative() {
  this.loadingOverlay = true;
 // this.loadingTable = true;
  this.itemsAgencyRepresentative = [];
  await this.$http
    .get('shipping-agency-list?Filter=ACTIVO')
    .then((response) => {
      this.loadingOverlay = false;
      //this.loadingTable = false;
      this.itemsAgencyRepresentative = response.data.data;
      if (!this.editModal) {
        /*this.LineRepresServiceId = this.itemsAgencyRepresentative[0].ShippingLineId;
        this.temporalRepresentativAgency = this.LineRepresServiceId;
        const lineAgency = {
          ShippingLineId: this.itemsAgencyRepresentative[0].ShippingLineId,
        };
        this.LineJson.push(lineAgency);*/
      } else {
        
        if (this.LineJson.length === 0 && this.vesselContainerShip) {
          const lineAgency = {
            ShippingLineId: this.LineRepresServiceId,
          };
          this.LineJson.push(lineAgency);
        }
      }
    })
    .catch((e) => {
     // this.loadingTable = false;
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function getShippingAgentList() {
  this.loadingOverlay = true;
  this.itemsShippingAgent = [];
  await this.$http
    .get('Client-list-by-activity', { TpClientId: '10fafb97-1575-4c71-8cdb-7f8f3c0c1245', Filter: 'ACTIVO' })
    .then((response) => {
      this.itemsShippingAgent = response.data.data;
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function ServiceLineById() {
  this.loadingOverlay = true;
  let listado = [];
  await this.$http
    .get("ServiceLine-by-service", { ServiceId: this.ServiceId })
    .then((response) => {
      this.loadingOverlay = false;
      listado = response.data.data;
      if(listado.length > 0){
        this.LineJson = this.serviceLineJson(response.data.data);
      }
      this.$v.$touch();
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedShippingAgent() {
  if (this.itemsShippingAgent.length > 0) {
    let listado = [];
    listado.push({
      label: this.$t('label.select'),
      value: "",
    });
    for (let i = 0; i < this.itemsShippingAgent.length; i++) {
      listado.push({
        label: this.itemsShippingAgent[i].ClientName,
        value: this.itemsShippingAgent[i].ClientTpId,
      });
    }

    return listado;    
  }
}

function computedShippingAgencyRepresentative() {
  if (this.itemsAgencyRepresentative.length > 0) {
    let listado = [];
    listado.push({
      label: this.$t('label.select'),
      value: "",
    });
    for (let i = 0; i < this.itemsAgencyRepresentative.length; i++) {
      listado.push({
        label: this.itemsAgencyRepresentative[i].ShippingLineName,
        value: this.itemsAgencyRepresentative[i].ShippingLineId,
      });
    }

    return listado;    
  }
}

function computedCheckShippingAgency() {
  if (this.itemsAgency.length > 0) {
    return this.itemsAgency.map((item, index) => {
      if (!this.editModal) {
        if (!this.selectAll && !this.resetSelectAll) {
          const selectLine = this.LineJson.filter(
            (element) => item.ShippingLineId === element.ShippingLineId
          );
          return {
            ...item,
            initialActive:
              selectLine.length > 0 ||
              this.LineRepresServiceId === item.ShippingLineId
                ? true
                : false,
          };
        }
        if (this.selectAll && !this.resetSelectAll) {
          const selectLine = {
            ShippingLineId: item.ShippingLineId,
          };
          this.LineJson.push(selectLine);
          return {
            ...item,
            initialActive: true,
          };
        }
        if (!this.selectAll && this.resetSelectAll) {
          return {
            ...item,
            initialActive: false,
          };
        }
      } else {
        if (!this.selectAll && !this.resetSelectAll) {
          const selectLine = this.LineJson.filter(
            (element) => item.ShippingLineId === element.ShippingLineId
          );
          return {
            ...item,
            initialActive:
              selectLine.length > 0 ||
              this.LineRepresServiceId === item.ShippingLineId
                ? true
                : false,
          };
        }
        if (this.selectAll && !this.resetSelectAll) {
          const selectLine = {
            ShippingLineId: item.ShippingLineId,
          };
          this.LineJson.push(selectLine);
          return {
            ...item,
            initialActive: true,
          };
        }
        if (!this.selectAll && this.resetSelectAll) {
          return {
            ...item,
            initialActive: false,
          };
        }
      }
    });
  }
}

async function selectLineRepresentanteService(event) {
  this.LineRepresServiceId = event.target.value;
  const selectLine = {
    ShippingLineId: this.temporalRepresentativAgency,
  };
  const i = await this.arrayObjectIndexOf(
    this.LineJson,
    this.temporalRepresentativAgency,
    'ShippingLineId'
  );
  if (i !== -1) {
    await this.LineJson.splice(i, 1);
  }

  const selectLineTemp = {
    ShippingLineId: this.LineRepresServiceId,
  };
  const j = await this.arrayObjectIndexOf(
    this.LineJson,
    this.LineRepresServiceId,
    'ShippingLineId'
  );
  if (j === -1) {
    this.LineJson.push(selectLineTemp);
  }
  this.temporalRepresentativAgency = this.LineRepresServiceId;
}

async function selectTpVessel(event) {
  this.TpVesselId = '';
  this.TpVesselId = event.target.value;
  const vesselType = this.itemsTpVessel.find( item => item.TpVesselId === event.target.value);
  this.vesselContainerShip = (this.TpVesselId === '2A719797-70DB-437B-B55D-58F4177CB87B') ? true : false;
}

function computedRequiredTeus() {
  if (this.TpVesselId !== '') {
    const vessel = this.itemsTpVessel.filter(
      (element) => element.TpVesselId === this.TpVesselId
    );
    if (vessel.length > 0 && vessel[0].TpVesselName === 'CONTAINER SHIP') {
      //this.teusRequired = true;
      return true;
    } else {
      return false;
    }
  }
}

function selectStatus(event) {
  this.Status = event.target.value;
  this.ServiceStatus = event.target.value;
}

//### funcion para el valor de los checked en los packagin seleccionados
async function checkedAndUnchekedSelect(event, item) {
  this.selectAll = false;
  this.resetSelectAll = false;
  const selectLine = {
    ShippingLineId: item.ShippingLineId,
  };
  if (event === true) {
    this.LineJson.push(selectLine);
  }
  if (event === false) {
    const i = await this.arrayObjectIndexOf(
      this.LineJson,
      item.ShippingLineId,
      'ShippingLineId'
    );
    if (i !== -1) {
      this.LineJson.splice(i, 1);
    }
  }
}

//#### Funcion para seleccionar todos los embalajes
function selectAllChecked(event) {
  this.LineJson = [];
  if (event === true) {
    this.selectAll = true;
    this.resetSelectAll = false;
  } else {
    this.selectAll = false;
    this.resetSelectAll = true;
  }
}

async function saveService() {
  try {
  //  this.requiredTeus = false;
  //  this.requiredTon = false;
    this.loadingOverlay = true;
    this.$v.$touch();
    
    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw this.$t('label.errorsPleaseCheck');
    }
/*
    if (this.teusRequired && this.ServiceTeus < 1) {
      this.requiredTeus = true;
      this.loadingOverlay = false;
      this.invalidUnits = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    if (!this.teusRequired && this.ServiceTonnage < 1) {
      this.requiredTon = true;
      this.loadingOverlay = false;
      this.invalidUnits = true;
      throw this.$t('label.errorsPleaseCheck');
    }
*/
    const ServiceJson = {
      CompanyBranchId: this.CompanyBranchId,
      TpVesselId: this.TpVesselId,
      AgencyClientTpId: this.AgencyClientTpId,
      LineRepresServiceId: this.LineRepresServiceId,
      ServiceCode: this.ServiceCode,
      StartDate: this.StartDate,
      EndDate: this.EndDate,
      ServiceDs: this.ServiceDs,
      ServiceTeus: this.ServiceTeus === '' ? 0 : this.ServiceTeus,
      ServiceTonnage: this.ServiceTonnage === '' ? 0 : this.ServiceTonnage,
      LineJson: this.LineJson,
    };

    await this.$http
      .post('Service-insert', ServiceJson, {
        root: 'ServiceJson',
      })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.$store.commit('servicio/listado', response.data.data[0].TransaRegDate);
          this.loadingOverlay = false;
          this.closeModal(false);
          this.resetBody();
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: messageSuccess,
            type: 'success',
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

function resetBody() {
  this.CompanyBranchId = '';
  this.TpVesselId = '';
  this.LineRepresServiceId = '';
  this.AgencyClientTpId = '';
  this.ServiceCode = '';
  this.StartDate = '';
  this.EndDate = '';
  this.Status = 1;
  this.ServiceDs = '';
  this.ServiceTeus = '';
  this.ServiceTonnage = '';
  this.ServiceId = '';
  this.LineJson = [];
  this.vesselContainerShip = false;
  this.$nextTick(() => { this.$v.$reset() })
}

async function submitUpdateServicio() {
  try {
  //  this.requiredTeus = false;
  //  this.requiredTon = false;

    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw this.$t('label.errorsPleaseCheck');
    }
/*
    if (this.teusRequired && this.ServiceTeus === '') {
      this.requiredTeus = true;
      this.loadingOverlay = false;
      this.invalidUnits = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    if (!this.teusRequired && this.ServiceTonnage === '') {
      this.requiredTon = true;
      this.loadingOverlay = false;
      this.invalidUnits = true;
      throw this.$t('label.errorsPleaseCheck');
    }
*/
    this.checkSTatusServicio();
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

async function checkSTatusServicio() {
  try {
    if (this.ServiceStatus !== this.statusToVerify) {
      this.$swal
        .fire({
         /* title: `¿Estás seguro de ${this.statusServicesValue(
            this.ServiceStatus
          )} a este Servicio?`,
*/
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.ServiceCode}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#42AA91',
          cancelButtonColor: '#E1373F',
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateServicio();
          }
        });
    } else {
      this.updateServicio();
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

async function updateServicio() {
  this.loadingOverlay = true;
  if(!this.vesselContainerShip)
    this.LineJson = [];

  const ServiceJson = {
    ServiceId: this.ServiceId,
    CompanyBranchId: this.CompanyBranchId,
    TpVesselId: this.TpVesselId,
    AgencyClientTpId: this.AgencyClientTpId,
    LineRepresServiceId: this.LineRepresServiceId,
    ServiceCode: this.ServiceCode,
    StartDate: this.StartDate,
    EndDate: this.EndDate,
    ServiceDs: this.ServiceDs,
    ServiceTeus: this.ServiceTeus === '' ? 0 : this.ServiceTeus,
    ServiceTonnage: this.ServiceTonnage === '' ? 0 : this.ServiceTonnage,
    ServiceStatus: this.ServiceStatus,
    LineJson: this.LineJson,
  };

  await this.$http
    .put('Service-update', ServiceJson, { root: 'ServiceJson' })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$store.commit('servicio/listado', response.data.data[0].TransaRegDate);
        this.$emit('refresh-data-table');
        this.loadingOverlay = false;
        this.resetBody();
        this.closeModal(false);
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function serviceLineJson(items) {
  if (items.length === 0) {
    return [];
  } else {
    return items.map((item) => {
      return {
        ShippingLineId: item.LineAuxId,
      };
    });
  }

}
function statusServicesValue(statusServices) {
  const status = this.statusSelectOptions.filter(
    (element) => element.ProcessStatusId === statusServices
  );
  if (status.length > 0 && status[0].ValueDs === 'INACTIVO') {
    return 'desactivar';
  } else if (status.length > 0 && status[0].ValueDs === 'CANCELADO') {
    return 'cancelar';
  } else {
    return 'finalizar';
  }
}

function nuevaLinea(item) {
  this.ShippingLine = item;
  if (this.newLine) {
    this.newLine = false;
    this.icono = 'arrowDown';
    this.mountedShippingAgency();
    this.mountedShippingAgencyRepresentative();
    this.getShippingAgentList();
  } else {
    this.newLine = true;
    this.icono = 'arrowUp';
  }  
}

function fechaNoValida(date) {
    var anioAtras = new Date();
    anioAtras.setFullYear(anioAtras.getFullYear() - 1);
    var anioSiguiente = new Date();
    anioSiguiente.setFullYear(anioSiguiente.getFullYear() + 2);

    return date < anioAtras || date > anioSiguiente;
}

export default {
  name: 'modal-servicio',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    serviceSelected: Object,
  },
  mixins: [GeneralMixin, mixinServicio, CargaGranel, ChoferVehiculo],
 // validations: servicio,
  validations(){ 
    return servicio(this.vesselContainerShip) 
  },
  directives: UpperCase,
  components: {
    AddLinea,
  },

  watch: {
    modal: async function(val) {
      this.newLine = false;
      this.showModal = val;
      if (val) {
        this.resetBody();
        await this.mountedTpVessel();
        await this.mountedShippingAgency();
        await this.mountedShippingAgencyRepresentative();
        await this.mounteStatusList();
        await this.getShippingAgentList();
        this.config = await localStorage.getItem('user');
        this.config = JSON.parse(this.config);
        if (Object.keys(this.config).length !== 0) {
          this.CompanyBranchId = this.config.BranchJson[0].CompanyBranchId;
        }
      }
    },
    serviceSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.ServiceId = val.ServiceId;
        this.CompanyBranchId = val.CompanyBranchId;
        this.TpVesselId = val.TpVesselId;
        this.LineRepresServiceId = val.LineRepresServiceId;
        this.AgencyClientTpId = val.AgencyClientTpId;
        this.ServiceCode = val.ServiceCode;
        this.StartDate = DateFormater.formatOnlyDateWithSlash(val.StartDate);
        this.EndDate = DateFormater.formatOnlyDateWithSlash(val.EndDate);
        this.ServiceDs = val.ServiceDs;
        this.ServiceTeus = val.ServiceTeus;
        this.ServiceTonnage = val.ServiceTonnage;
        this.Status = val.ServiceStatus;
        //this.LineJson = this.serviceLineJson(val.ServiceLineJson);
        //this.ServiceStatus = this.statusServicesValue(val.ServiceStatus);
        this.ServiceStatus = val.ServiceStatus;
        
        this.vesselContainerShip = (this.TpVesselId === '2A719797-70DB-437B-B55D-58F4177CB87B') ? true : false;
        this.ServiceLineById();
      }
      
    },
  },
  methods: {
    closeModal,
    functionDateStart,
    functionDateEnd,
    mountedTpVessel,
    mountedShippingAgency,
    selectLineRepresentanteService,
    selectTpVessel,
    selectStatus,
    checkedAndUnchekedSelect,
    selectAllChecked,
    mountedShippingAgencyRepresentative,
    getShippingAgentList,
    saveService,
    resetBody,
    submitUpdateServicio,
    checkSTatusServicio,
    updateServicio,
    serviceLineJson,
    mounteStatusList,
    statusServicesValue,
    nuevaLinea,
    fechaNoValida,
    ServiceLineById
  },
  computed: {
    checkColumn,
    computedTpVessel,
    checkSizeModal,
    computedShippingAgency,
    computedCheckShippingAgency,
    computedRequiredTeus,
    computedShippingAgencyRepresentative,
    computedShippingAgent,
    computedStatusList,
    fields,
    optionsStatus,
    ...mapState({
        user: state => state.auth.user,
    })
  },
};
</script>
<style lang="scss">
.text-area-descripcion-servicio {
  textarea {
    resize: none !important;
  }
}

.center-cell {
  text-align: center;
}

  .table-responsive {
    overflow-x: hidden;
  }

</style>
