<template>
  <div><!--  -->
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"     
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ModalActividad"
      :title="titleModal"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
  
      <CRow >
        <CCol sm="12">
          <div v-if="isActive">
            <CRow>
              <CCol sm="12" lg="3" class="text-right mt-2 pr-1">
                <label><b class="text-danger"> * </b> {{ $t('label.activity') }} </label>
              </CCol>
              <CCol sm="12" lg="7">
                <div class="form-group form-row">
                  <div class="input-group col-sm-12 col-lg-12 input-group">
                    <v-select 
                      :options="actividadesOptions"
                      :placeholder="$t('label.select')"
                      v-model.trim="$v.TpClientId.$model"
                      :reduce="option => option.value" 
                      :class="`${isValid($v.TpClientId)} select-adjust`"
                      v-uppercase
                    />
                    <div class="input-group-append">
                      <CButton color="add" size="sm" class="rounded" 
                        v-c-tooltip="{
                          content: $t('label.nueva')+''+$t('label.activity'),
                          placement: 'top-end'
                          }"
                        @click="ActCollapse = !ActCollapse">
                        <CIcon name="cil-playlist-add"/>
                      </CButton>
                    </div>
                  </div>
                  <div class="col-sm-12" v-if="$v.TpClientId.$error && !$v.TpClientId.required">
                    <div class="col-sm-12 p-0">
                      <div class='text-invalid-feedback'>{{ $t("label.required") }}</div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCol>
        <CCol sm="12" class="mb-3" v-if="FgActApplyCompanyBranch == true">
          <CRow>
            <CCol sm="12" lg="3" class="text-right mt-2 pr-1">
              <label><b class="text-danger"> * </b> {{$t('label.branch')}}</label>
            </CCol>
            <CCol sm="12" lg="7">
              <div class="form-group form-row">
                <div class="input-group col-sm-12 col-lg-12 input-group">
                  <v-select 
                    :options="sucursalOptions"
                    label="BranchName"
                    :placeholder="$t('label.select')"
                    v-model.trim="sucursal"
                    :value.sync="sucursal"
                    :class="`${isValid($v.sucursal)} select-adjust`"
                  />
                </div>
                <div class="col-sm-12" v-if="$v.sucursal.$error && !$v.sucursal.required">
                  <div class="col-sm-12 p-0" >
                    <div class='text-invalid-feedback'>{{ $t("label.required") }}</div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
       
        <CCol sm="12" class="mt-3">
          <ActividadCollapse :collapse="ActCollapse"  @child-refresh="actualizaActividad"/>
        </CCol>
        <CCol sm="12" v-if="!ActCollapse">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="LoadingTabla"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
          >
          <template #loading>
          <loading/>
          </template>
            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}" >
              <td class="center-cell" v-if="isActive">
                <CButton color="wipe" size="sm" class="mr-1"
                  v-c-tooltip="getTooltip(item.Status)"
                  @click="ConfirmarEliminado(item)"
                  :disabled="isSubmit" >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>

      <template #footer>
          <div v-if="isActive">
              <CButton color="add" :disabled="isSubmit" @click="guardar()" >
                <CIcon name="checkAlt"/>&nbsp; {{$t('button.accept')}}
              </CButton>
          </div>
          <div v-if="isActive">
              <CButton outline color="wipe" :disabled="isSubmit" @click="closeModal">
                <CIcon name="x"/>&nbsp; <span class="ml-1">{{$t('button.cancel')}}</span>
              </CButton>
          </div>
      
          <div v-if="!isActive">
              <CButton outline color="wipe" :disabled="isSubmit" @click="closeModal">
                <CIcon name="x"/>&nbsp; <span class="ml-1">{{$t('button.exit')}}</span>
              </CButton>
          </div>
      </template>
      
    </CModalExtended>
  </div>  
</template>

<script>
import ActividadClienteValidations from '@/_validations/cliente/actividadClienteValidations';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadCollapse  from './actividad-collapse';
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';

function data () {
  return {
    //MODELO
    UserId: '',
    sucursal: '',
    ClientId: [],
    TpClientId: '',
    //VARIABLES
    actividadesOptions: [],
    sucursales: [],
    items: [],
    clientes: [],
    FgActApplyCompanyBranch: false,
    Loading: false,
    LoadingTabla: false,
    titleModal: null,
    ModalActividad: false,
    ActCollapse: false,
    isSubmit: false, 
    isActive:false,
  }
}
//COMPUTED
function fields(){ 
  return[
  { 
    key: 'Nro', 
    label: '#',
    filter: false,_style: 'width:1%; text-align:center;',
    _classes: 'text-uppercase text-center center-cell'
  },
  { key: 'TpClientName',label: this.$t('label.activity'), _style:'width:40%', _classes: 'text-uppercase text-center center-cell' },
  { key: 'Usuario', label: this.$t('label.user'), _classes: 'text-uppercase text-center center-cell'},
  { key: 'Fecha', label: this.$t('label.date'), _classes:'text-center center-cell'},
  { key: 'Status', label: this.$t('label.status'), _classes:'text-center center-cell'},
  { 
    key: 'Detalle',
    label: '',
    sorter: false,
    filter: false,
    _style: 'width:1%;',
    _classes: 'text-center center-cell'
  }
]}
//FUNCIONES DE METHOD
function closeModal() {
  this.refreshComponent();
  this.ModalActividad= false;
  this.$emit('child-refresh', true); 
}
function actualizaActividad(valor) {
  this.listarActividades();
}
function ConfirmarEliminado(item) {
  this.$swal.fire({
    text: `${this.$t('label.changeStatusQuestion')}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel'),
  }).then((result) => {
    if (result.isConfirmed) {
      this.Loading = true;
      this.isSubmit = true;
      let res = [];
      let ClientTpJson = [];

      ClientTpJson = {
        ClientTpId: item.ClientTpId,
        ClientId: item.ClientId,
        TpClientId: item.TpClientId,
        //Status: 0,
        Status: item.Status === 'ACTIVO'? 0:1,
        UserId: this.user.UserId,
      };

      this.$http.put("ClientActivity-update", ClientTpJson, { root: 'ClientTpJson' })
      .then(async response => {
        res = [...response.data.data];
        await this.refreshComponent();
        this.buscarActividades(this.ClientId.ClientId);
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false; 
        this.isSubmit = false;   
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
        this.isSubmit = false;
      });
    }
  });  
}
function getTooltip(status) {
  switch (status) {
    case 'ACTIVO': 
      return {
        content: this.$t('label.inactivateActivity'), //'Inactivar actividad',
        placement: 'top-end'
      };
    case 'INACTIVO':
      return {
        content:  this.$t('label.activateActivity'), //'Activar actividad',
        placement: 'top-end'
      };
  }
}
function refreshComponent() {
  this.UserId = '';
  this.sucursal = '';
  this.sucursales = [];
  this.TpClientId = '';
  this.FgActApplyCompanyBranch = false;
  this.ActCollapse = false;
  this.isSubmit = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function buscarActividades(clie) {
    if (clie!="") {
      let listado = Array;
      this.LoadingTabla=true;
      this.items = [];
      let _lang = this.$i18n.locale;

      this.$http.get("Activity-list-by-client", { ClientId: clie })
      .then(response => {
        listado = [...response.data.data];
        let i=1;
        this.items = listado.map(listado => Object.assign({}, this.items, {
          Nro: i++,
          TpClientName: _lang=='en' ? listado.TpClientNameEn : listado.TpClientNameEs,
          Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
          Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
          Status: listado.Status,
          TpClientId: listado.TpClientId,
          ClientId: listado.ClientId,
          ClientTpId: listado.ClientTpId,
          _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
        }));
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.LoadingTabla= false;
      });
    }
}

function guardar() {
 //if (this.$v.$invalid) return false;

try {
  this.Loading = true;
  this.isSubmit = true;
  this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

  let res = [];
  let ClientTpJson = {
    TpClientId: this.TpClientId,
    ClientId: this.ClientId.ClientId,
    UserId: this.user.UserId,
    CompanyBranchId: this.sucursal.CompanyBranchId ?? '',
  };
  //ASIGNACION DE VARIABLES PARA GUARDAR
  

  this.$http.post("ClientActivity-insert", ClientTpJson, { root: 'ClientTpJson' })
  .then(response => {
    res = [...response.data.data];
    this.refreshComponent();
    this.buscarActividades(this.ClientId.ClientId);
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.Loading= false; 
    this.isSubmit = false;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
    this.isSubmit = false;
  });
  } catch (e) {
    this.notifyError({ text: e });
    }
}

function listarActividades () {
  this.Loading = true;
  this.actividadesOptions = [];
  let listado = [];
  let activity = false; 
  let _lang = this.$i18n.locale;

  this.$http.get("TpClient-list", { Filter: 'ACTIVO' })
  .then(async response => {
    listado = [...response.data.data];
    this.actividadesOptions = listado.map(ob => Object.assign({}, this.actividadesOptions, {
      value: ob.TpClientId,
      label: _lang=='en' ? ob.TpClientNameEn||'' : ob.TpClientNameEs||'',
      FgActApplyCompanyBranch: ob.FgActApplyCompanyBranch
    }));
    activity = listado.find(item =>  item.TpClientId == this.TpClientId );
    if (activity?.FgActApplyCompanyBranch === true) {
      this.FgActApplyCompanyBranch = true;
      await this.getSucursales();
    }else{
      this.FgActApplyCompanyBranch = false;
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).
  then(() => {
    this.Loading = false;
  });
}

async function getSucursales() {
  let id = this.user.CompanyId;
  await this.$http.ejecutar('GET', 'Branch-list-by-company', {CompanyId: id, Filter: 'ACTIVO'})
  .then(response => {
    this.sucursales = response.data.data??[];
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function isValid(Val) {
  if(Val?.$dirty){
    return Val?.$error ? 'select-client--error' : 'select-client--correct';
  }else{
    return '';
  }
}

function sucursalOptions() {
  return this.sucursales.map((sucursal) => Object.assign({}, sucursal, {
    value: sucursal.CompanyBranchId,
    label: sucursal.BranchName
  }));
}

export default {
  name: 'actividad-modal',
  mixins: [General, ModalMixin],
  components: {
    ActividadCollapse
  },
  data,
  directives: UpperCase,
  validations() {
    return ActividadClienteValidations(this.FgActApplyCompanyBranch)
  },
  props: {
    modal: null,
  },
  methods: {
    refreshComponent,
    guardar,
    buscarActividades,
    ConfirmarEliminado,
    listarActividades,
    getSucursales,
    actualizaActividad,
    isValid,
    getTooltip,
    closeModal,
  },
  watch: {
    modal: async function (NewVal) {
      if (NewVal) {
        this.isActive = this.modal.Item.FgActClient;

        let listado = [];
        let _lang = this.$i18n.locale;
        this.actividadesOptions = [];
        await this.refreshComponent();
        this.ModalActividad = true;
        this.titleModal    = this.$t('label.activities');
        listado = this.modal.Actividades;
        this.actividadesOptions = listado.map(ob => Object.assign({}, this.actividadesOptions, {
          value: ob.TpClientId,
          label: _lang=='en' ? ob.TpClientNameEn||'' : ob.TpClientNameEs||'',
          FgActApplyCompanyBranch: ob.FgActApplyCompanyBranch
        }));
        
        listado = [];
        listado = this.modal.Clientes.map(ob => Object.assign({}, listado, {
          ClientId: ob.ClientId,
          ClientName: (ob.ClientRif +" - "+ ob.ClientName)
        }));

        this.clientes = listado;
        if (this.modal.Item!==true) {
          this.ClientId = {
            ClientName: this.modal.Item.Cliente,
            ClientId: this.modal.Item.ClientId
          }
          this.titleModal = this.$t('label.clientActivities')+': '+this.modal.Item.Cliente;
        }   
        this.buscarActividades(this.ClientId.ClientId);
        this.$emit('cerrarModal');
      }
    },
    TpClientId: async function (NewVal) {
      if(NewVal){
        let activity = this.actividadesOptions.find(item => item.value == NewVal);
        if (activity?.FgActApplyCompanyBranch === true) {
          this.FgActApplyCompanyBranch = true;
          await this.getSucursales();
        }else{
          this.FgActApplyCompanyBranch = false;
        }
      }
    }
  },
  computed: {
    fields,
    sucursalOptions,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style lang="scss">
.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}

  .select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }
  
.center-cell {
  text-align: center;
}
 
</style>
