<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      :show.sync="AddModal"
    >
      <CRow class="mt-2">
        <CCol sm="12" lg="12" xl="12">
          <CSelect
            v-uppercase
            :label="$t('label.packaging')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            :options="PackagingOptions"
            addLabelClasses="required text-right"
            v-model="$v.PackagingId.$model"
            :is-valid="hasError($v.PackagingId)"
            :invalid-feedback="errorMessage($v.PackagingId)"
            :disabled="!StatusOrigin"
          />
        </CCol>
        <CCol sm="12" lg="12" xl="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.name')+' '+('(ES)')"
            :placeholder="$t('label.nameAccesory')+' '+('(ES)')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            :invalid-feedback="errorMessage($v.PackagingAccesoryNameEs)"
            maxlength="20"
            v-model="$v.PackagingAccesoryNameEs.$model"
            :is-valid="hasError($v.PackagingAccesoryNameEs)"
            :disabled="!StatusOrigin"
          />
        </CCol>
        <CCol sm="12" lg="12" xl="12">
          <CInput
            addLabelClasses="required text-right"
            v-uppercase
            :label="$t('label.name')+' '+('(EN)')"
            :placeholder="$t('label.nameAccesory')+' '+('(EN)')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            :invalid-feedback="errorMessage($v.PackagingAccesoryNameEn)"
            maxlength="20"
            v-model="$v.PackagingAccesoryNameEn.$model"
            :is-valid="hasError($v.PackagingAccesoryNameEn)"
            :disabled="!StatusOrigin"
          />
        </CCol>
        <CCol sm="12" lg="12" xl="12" v-if="actualizar">
          <CSelect
            :label="$t('label.status')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            v-model="Status"
            :value.sync="Status"
            :options="selectOptions"
            :disabled="!StatusOrigin"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="add" v-if="StatusOrigin" :disabled="isSubmit" @click="evaluaStatus"">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" :disabled="isSubmit" @click="AddModal = false">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import UpperCase from "@/_validations/uppercase-directive";
import AccesoriesVal from "@/_validations/embalaje/accesoriesVal";
import General from "@/_mixins/general";
import { VMoney, Money } from "v-money";

//METODOS
function resetComponent() {
  this.PackagingAccesoryNameEs = "";
  this.PackagingAccesoryNameEn = "";
  this.PackagingId = '';
  this.Status= true,
  this.StatusOrigin= true,
  this.$nextTick(() => {
    this.$v.$reset();
  });
}
function guardar() {
   try {
      this.Loading = true;
      this.isSubmit = true;
      this.$v.$touch();

      if (this.$v.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }

      let res = [];
      let metodo = "";
      let ruta = "";
      let PackagingAccesoryJson = [];
      //ASIGNACION DE VARIABLES PARA GUARDAR
      if (this.actualizar) {
        PackagingAccesoryJson = {
          PackagingId: this.PackagingId,
          PackagingAccesoryId: this.PackagingAccesoryId,
          PackagingAccesoryNameEs: this.PackagingAccesoryNameEs,
          PackagingAccesoryNameEn: this.PackagingAccesoryNameEn,
          Status: this.Status ? 1 : 0,
        };
        metodo = "PUT";
        ruta = "PackagingAccesory-update";
      } else {
        PackagingAccesoryJson = {
          PackagingAccesoryNameEs: this.PackagingAccesoryNameEs,
          PackagingAccesoryNameEn: this.PackagingAccesoryNameEn,
          PackagingId: this.PackagingId,
        };
        metodo = "POST";
        ruta = "PackagingAccesory-insert";
      }
      //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
      this.$http
        .ejecutar(metodo, ruta, PackagingAccesoryJson, {
          root: "PackagingAccesoryJson",
        })
        .then((response) => {
          res = [...response.data.data];
          this.resetComponent();
          this.$notify({
            group: "container",
            title: "¡Exito!",
            text: res[0].Response,
            type: "success",
          });
          this.Loading = false;
          this.isSubmit = false;
          this.$emit("child-refresh", true);
          this.AddModal = false;
        })
        .catch((err) => {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
          this.Loading = false;
          this.isSubmit = false;
        });
      } catch (e) {
        this.Loading = false;
        this.isSubmit = false;
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: e,
            type: "error",
          });
      }
}

function evaluaStatus() {
  if (this.Status !== this.StatusOrigin) {   
      this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+'?'}`,
        }))
      .then((result) => {
        if (result.isConfirmed) {
          this.guardar();
        }
      });
  } else this.guardar();
}
async function checkInputs(value) {
  if(value){
    this.$v.$touch();
}

}

//COMPUTED
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingList.map((e) => {
    chart.push({
      value: e.PackagingId,
      label: e['PackagingName'+_lang],
    })
  })
  return chart;
}

function isDisabled() {
  return this.$v.$invalid;
}
 function selectOptions() {
    return [
      { 
          value: true, 
          label: this.$t('label.ACTIVO')
      },
      { 
          value: false, 
          label: this.$t('label.INACTIVO')
      },
    ];
 }
//DATA
function data() {
  return {
    // VARIABLES
    AddModal: false,
    Loading: false,
    Status: true,
    StatusOrigin: true,
    actualizar: false,
    tituloModal: "",
    isSubmit: false,
    //MODELO
    PackagingAccesoryNameEs: "",
    PackagingAccesoryNameEn: "",
    PackagingId: '',
    PackagingAccesoryId: '',
  }
}
export default {
  name: "modal-packaging",
  mixins: [General],
  data,
  props: {
    modal: null,
    PackagingList: Array
  },
  components: {
    Money,
  },
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener('input', (e) => {
            e.target.value = e.target.value.toUpperCase()
            vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
        });
      }
    },
    money: VMoney
  },
  validations: AccesoriesVal,
  watch: {
    modal: async function () {
      if (this.modal) {
        
        this.resetComponent();
        this.AddModal = true;
        if (this.modal == true) {
          this.tituloModal = this.$t('label.nuevo') + this.$t('label.accesory');
          this.actualizar = false;
        } else {
          this.actualizar = true;
          let _lang = this.$i18n.locale;
          let title = _lang=='en' ? this.modal.PackagingAccesoryNameEn : this.modal.PackagingAccesoryNameEs;
          this.tituloModal = this.$t('label.edit') + this.$t('label.accesory') +': ' + title;
          this.PackagingId = this.modal.PackagingId;
          this.PackagingAccesoryId = this.modal.PackagingAccesoryId,
          this.PackagingAccesoryNameEs = this.modal.PackagingAccesoryNameEs;
          this.PackagingAccesoryNameEn = this.modal.PackagingAccesoryNameEn;
          this.Status = (this.modal.Status == ("INACTIVO" || "INACTIVE")) ? false : true;
          this.StatusOrigin = (this.modal.Status == ("INACTIVO" || "INACTIVE")) ? false : true;
          await this.checkInputs(this.actualizar);
          this.$v.$touch();

        }
        this.$emit("closeModal");
      }

    },
  },
  methods: {
    evaluaStatus,
    resetComponent,
    guardar,
    checkInputs,
  },
  computed: {
    isDisabled,
    selectOptions,
    PackagingOptions
    
  },
};
</script>
<style scoped></style>
