import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import ENUM from '@/_store/enum';

function data() {
  return {
    dataBuque:{
      CountryName:'',
      DatePlanning:'',
      VesselFlagRoute:'',
    },
    colors: [   'DDD9C4',
        'C5D9F1',
        '9F9FFF',
        'F2DCDB',
        'D8E4BC',
        'CCC0DA',
        'FABF8F',
        'FFFF99',
        'FFCCFF',
        '9ADEBC',
    ],
    colorActual: '',
  }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getWeighingGeneralReport(items, dateStart, dateEnd) {
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    

    if (items.length>0){  
        const _lang = this.$i18n.locale;  
        let bandera = false;

        /*********************DATA HOJA DE DATOS Y PESO TARA START******************************/
        let sheetWeightTareData = [];
        let sheetWeightTareDataClients = [];
        if (items[0].ExcelJson.length>0){ 
            items[0].ExcelJson.forEach(x => {
                for(let i=0; i<x.DataJson.length; i++ ){ 
                    sheetWeightTareData.push({
                        NRO: x.DataJson[i].IdX ? x.DataJson[i].IdX : '',
                        BIN: x.DataJson[i].ImplementAlias ? x.DataJson[i].ImplementAlias :'',
                        PLATE: x.DataJson[i].LicensePlate ? x.DataJson[i].LicensePlate : '',
                        TARE_WEIGTH: x.DataJson[i].Weight ? x.DataJson[i].Weight : 0,
                        TRANSPORT: x.CarrierClientName ? x.CarrierClientName : '',
                    });
                }

                let color = '';
                do{
                    color = this.getFontColor();
                }while(this.colorActual === color);

                this.colorActual = color;

                sheetWeightTareDataClients.push({
                    QUANTITY_ROWS: x.DataJson.length,
                    CLIENT_COLOR: color,
                });
                bandera = true;
            });
            this.colorActual = '';
        }
        /*********************DATA HOJA DE DATOS Y PESO TARA END*********************************/
                
        /*********************DATA HOJAS DINAMICAS DE PESAJE POR PATIOS START********************/
        let sheetsYardWeighingData = [];
        let sheetsYardWeighingDataYards = [];
        let sheetsYardQuantityItemsPerDispacher = [];
        if(items[1] !== undefined) {
            if (items[1].ExcelJson.length>0){ 
                let lengthYardWeighing=items[1].ExcelJson.length;
                let itemsYardWeighing=items[1].ExcelJson;
              
                for(let i=0; i<lengthYardWeighing; i++){// incremento por patio
                    let sheetYardWeighing= [];
                    let sheetYardWeighingDispacher = [];
                    let quantityItemsPerDispacher = [];
                    let k=1;
                    for(let j=0; j<itemsYardWeighing[i].DataJson.length; j++ ){ //incremento por dia-despachador
                        let color = '';
                        do{
                            color = this.getFontColor();
                        }while(this.colorActual === color);
        
                        this.colorActual = color;
                        
                        itemsYardWeighing[i].DataJson[j].DataJson.forEach(x => {
                            sheetYardWeighing.push({
                                NRO: k,
                                BIN: x.ImplementAlias ? x.ImplementAlias : '',
                                PLATE: x.LicensePlate ? x.LicensePlate : '',
                                TARE_WEIGHT: x.TareWeight ? x.TareWeight :  0, 
                                GROSS_WEIGHT: x.FullWeight ? x.FullWeight :  0,
                                NET_WEIGHT: x.NetWeight ? x.NetWeight :  0,
                                ORDER: x.OrderCode ? x.OrderCode : '',
                                DISPACHER: x.TransaUserName ? x.TransaUserName : '',
                                DATE: x.TransactionDate ? DateFormater.formatDateWithSlash(x.TransactionDate) : '',
                                HOUR: x.TransactionHour ? x.TransactionHour : '',
                                OBSERVATION: x.Observation ? x.Observation : '', 
                              //  TOTAL_GAMELAS: itemsYardWeighing[i].DataJson[j].TotalImplement ? itemsYardWeighing[i].DataJson[j].TotalImplement : 0,
                             //   TOTAL_TARE: itemsYardWeighing[i].DataJson[j].TotalTareWeight ? itemsYardWeighing[i].DataJson[j].TotalTareWeight :  0,
                              //  TOTAL_GROSS: itemsYardWeighing[i].DataJson[j].TotalFullWeight ? itemsYardWeighing[i].DataJson[j].TotalFullWeight :  0,
                              //  TOTAL_NETO: itemsYardWeighing[i].DataJson[j].TotalNetWeight ? itemsYardWeighing[i].DataJson[j].TotalNetWeight :  0,
                            });
                            k++;
                            bandera = true;                        
                            
                            sheetYardWeighingDispacher.push({
                                DISPACHER_DS: itemsYardWeighing[i].DataJson[j].DispatcherDateDs,
                                DISPACHER_COLOR: color,
                            });
                        }); 
                        quantityItemsPerDispacher.push({
                            QUANTITY_ROWS: itemsYardWeighing[i].DataJson[j].DataJson.length,
                        
                        });
                    }
    
                    sheetsYardQuantityItemsPerDispacher.push({
                        quantityItemsPerDispacher,
                        TOTAL_GAMELAS: itemsYardWeighing[i].TotalImplement ? itemsYardWeighing[i].TotalImplement : 0,
                        TOTAL_TARE: itemsYardWeighing[i].TotalTareWeight ? itemsYardWeighing[i].TotalTareWeight :  0,
                        TOTAL_GROSS: itemsYardWeighing[i].TotalFullWeight ? itemsYardWeighing[i].TotalFullWeight :  0,
                        TOTAL_NETO: itemsYardWeighing[i].TotalNetWeight ? itemsYardWeighing[i].TotalNetWeight :  0,
    
                        TOTAL_TARE_TON: itemsYardWeighing[i].TotalTareWeightTon ? itemsYardWeighing[i].TotalTareWeightTon :  0,
                        TOTAL_FULL_TON: itemsYardWeighing[i].TotalFullWeightTon ? itemsYardWeighing[i].TotalFullWeightTon :  0,
                        TOTAL_NETO_TON: itemsYardWeighing[i].TotalNetWeightTon ? itemsYardWeighing[i].TotalNetWeightTon :  0,
                    });
                    
    
                    sheetsYardWeighingData.push({
                        sheetYardWeighing, 
                    });
                    sheetsYardWeighingDataYards.push({
                        sheetYardWeighingDispacher,
                    });
                }
            }
        }
        /*********************DATA HOJAS DINAMICAS DE PESAJE END**********************/

        /***********************DATA HOJA DE ESTADISTICAS START**********************************/
        let sheetStatisticsData = [];
        let sheetStatisticsDataTotals = [];
        if(items[2] !== undefined) {
            if (items[2].ExcelJson.length>0){    
                items[2].ExcelJson.forEach(x => {
                    for(let i=0; i<x.DataJson.length; i++ ){ 
                        sheetStatisticsData.push({
                            NRO: x.DataJson[i].IdX ? x.DataJson[i].IdX : '',
                            BIN: x.DataJson[i].ImplementAlias ? x.DataJson[i].ImplementAlias :'',
                            PLATE: x.DataJson[i].LicensePlate ? x.DataJson[i].LicensePlate : '',
                            VOYAGE: x.DataJson[i].Voyages ? x.DataJson[i].Voyages : '',
                            WEIGTH: x.DataJson[i].Weight ? x.DataJson[i].Weight :  0,
                            WEIGTH_TONS: x.DataJson[i].WeightTon ? x.DataJson[i].WeightTon :  0,
                        });
                    }
                });
    
                if(items[2].ExcelJson.length > 0){
                    sheetStatisticsDataTotals.push({
                        TotalVoyages: items[2].ExcelJson[0].TotalVoyages ? items[2].ExcelJson[0].TotalVoyages :  0,
                        TotalWeight: items[2].ExcelJson[0].TotalWeight ? items[2].ExcelJson[0].TotalWeight :  0,
                        TotalWeightTon: items[2].ExcelJson[0].TotalWeightTon ? items[2].ExcelJson[0].TotalWeightTon :  0,
                        Average: items[2].ExcelJson[0].Average ? items[2].ExcelJson[0].Average :  0,
                    });
                }
                bandera = true;
            }
        }
        /**************************DATA HOJA DE ESTADISTICAS END*********************************/

        /***********************DATA HOJA DE ESTADISTICAS POR PATIOS START**********************************/
        let sheetStatisticsYardData = [];
        let sheetStatisticsYardDataTotals = [];
        let sheetsStatisticsYardQuantityItems = [];
        let k=1;
        if(items[3] !== undefined) {
            if (items[3].ExcelJson.length>0){    
                items[3].ExcelJson.forEach(x => {
                    for(let i=0; i<x.DataJson.length; i++ ){ 
                        sheetStatisticsYardData.push({
                            YardName: x.YardName ? x.YardName : '',
                            NRO: k,
                            BIN: x.DataJson[i].ImplementAlias ? x.DataJson[i].ImplementAlias :'',
                            PLATE: x.DataJson[i].LicensePlate ? x.DataJson[i].LicensePlate : '',
                            VOYAGE: x.DataJson[i].Voyages ? x.DataJson[i].Voyages : '',
                            WEIGTH: x.DataJson[i].Weight ? x.DataJson[i].Weight :  0,
                            WEIGTH_TONS: x.DataJson[i].WeightTon ? x.DataJson[i].WeightTon :  0,
                        });
                        k++;
                    }
                    let color = '';
                    do{
                        color = this.getFontColor();
                    }while(this.colorActual === color);
    
                    this.colorActual = color;
    
                    sheetsStatisticsYardQuantityItems.push({
                        YARD_COLOR: color,
                        QUANTITY_ROWS: x.DataJson.length,
                    });
                });
    
                if(items[3].ExcelJson.length > 0){
                    for(let i=0; i<items[3].ExcelJson.length; i++ ){ 
                        sheetStatisticsYardDataTotals.push({
                           // YardName: items[3].ExcelJson[0].YardName ? items[3].ExcelJson[0].YardName : '',
                            TotalVoyages: items[3].ExcelJson[i].TotalVoyages ? items[3].ExcelJson[i].TotalVoyages :  0,
                            TotalWeight: items[3].ExcelJson[i].TotalWeight ? items[3].ExcelJson[i].TotalWeight :  0,
                            TotalWeightTon: items[3].ExcelJson[i].TotalWeightTon ? items[3].ExcelJson[i].TotalWeightTon :  0,
                            Average: items[3].ExcelJson[i].Average ? items[3].ExcelJson[i].Average :  0,
                        });
                    }
                }
                bandera = true;
            }
        }
        /**************************DATA HOJA DE ESTADISTICAS DE PESAJE END*********************************/


        /**************************DATA HOJA DE GAMELAS START************************************/
        let sheetGamelasData = [];
        let sheetGamelasDataClients = [];
        if(items[4] !== undefined) {
            if (items[4].ExcelJson.length>0){
                items[4].ExcelJson.forEach(x => {
                    let sheetGamelasDataPerClient = [];
                    for(let i=0; i<x.DataJson.length; i++ ){ 
                        sheetGamelasDataPerClient.push({
                            NRO: x.DataJson[i].IdX ? x.DataJson[i].IdX : '',
                            BIN: x.DataJson[i].ImplementAlias ? x.DataJson[i].ImplementAlias :'',
                            PLATE: x.DataJson[i].LicensePlate ? x.DataJson[i].LicensePlate : '',
                            VOYAGES: x.DataJson[i].Voyages ? x.DataJson[i].Voyages : '',
                            WEIGTH: x.DataJson[i].Weight ? x.DataJson[i].Weight :  0,
                        });
                    }
    
                    sheetGamelasData.push({
                        sheetGamelasDataPerClient: sheetGamelasDataPerClient,
                    });
    
                    let color = '';
                    do{
                        color = this.getFontColor();
                    }while(this.colorActual === color);
    
                    this.colorActual = color;
    
                    sheetGamelasDataClients.push({
                        QUANTITY_ROWS: x.length,
                        CLIENT_COLOR: color,
                        CLIENT_NAME: x.CarrierClientName ? x.CarrierClientName : '',
                        TOTAL_WEIGTH: x.TotalWeight ? x.TotalWeight :  0,
                        TOTAL_VOYAGES: x.TotalVoyages ? x.TotalVoyages : '',
                    });
                    bandera = true;
                });
                this.colorActual = '';
            }
        }
        /*************************DATA HOJA DE GAMELAS END****************************************/

        /*************************DATA HOJAS DINAMICAS DE GAMELAS DEVUELTAS START*****************/
        let sheetsReturnedBinsData = [];
        let sheetsReturnedBinsDataYards = [];
        let sheetsReturnedBinsQuantityItemsPerDispacher = [];
        if(items[5] !== undefined) {
            if (items[5].ExcelJson.length>0){
                let lengthReturnedBins=items[5].ExcelJson.length; 
                let itemsReturnedBins=items[5].ExcelJson;
                for(let i=0; i<lengthReturnedBins; i++){// incremento por clientes
                    let sheetReturnedBins= [];
                    let sheetReturnedBinsDispacher = [];
                    let quantityItemsPerDispacher = [];
                    let k=1;
    
                    for(let j=0; j<itemsReturnedBins[i].DataJson.length; j++ ){ //incremento por dia-despachador
                        let color = '';
                        do{
                            color = this.getFontColor();
                        }while(this.colorActual === color);
        
                        this.colorActual = color;
                        
                        itemsReturnedBins[i].DataJson[j].DataJson.forEach(x => {
                            sheetReturnedBins.push({
                                NRO: k,
                                BIN: x.ImplementAlias ? x.ImplementAlias : '',
                                PLATE: x.LicensePlate ? x.LicensePlate : '',
                                TARE_WEIGHT: x.TareWeight ? x.TareWeight :  0, 
                                GROSS_WEIGHT: x.FullWeight ? x.FullWeight :  0,
                                NET_WEIGHT: x.NetWeight ? x.NetWeight :  0,
                                YARD_CLIENT: x.YardClientName ? x.YardClientName : '',
                                ORDER: x.OrderCode ? x.OrderCode : '',
                                DISPACHER: x.TransaUserName ? x.TransaUserName : '',
                                DATE: x.TransactionDate ? DateFormater.formatDateWithSlash(x.TransactionDate) : '',
                                HOUR: x.TransactionHour ? x.TransactionHour : '',
                                DEVICE: x.DeviceCode ? x.DeviceCode : '',
                                USER: x.TransaLogin ? x.TransaLogin : '',
                                DATE_REGISTER: x.TransactionDatetime ? DateFormater.formatDateTimeWithSlash(x.TransactionDatetime) : '',
                                OBSERVATION: x.Observation ? x.Observation : '', 
                                INCIDENCE: _lang=='en' ? x.IncidenceNameEn : x.IncidenceNameEs, 
                            });
                            k++;
                            bandera = true;                        
                            
                            sheetReturnedBinsDispacher.push({
                                DISPACHER_DS: itemsReturnedBins[i].DataJson[j].DispatcherDateDs,
                                DISPACHER_COLOR: color,
                            });
                        }); 
                        quantityItemsPerDispacher.push({
                            QUANTITY_ROWS: itemsReturnedBins[i].DataJson[j].DataJson.length,
                            
                        });
                    }
    
                    sheetsReturnedBinsQuantityItemsPerDispacher.push({
                        quantityItemsPerDispacher,
                        TOTAL_TARE: itemsReturnedBins[i].TareWeight ? itemsReturnedBins[i].TareWeight :  0,
                        TOTAL_GROSS: itemsReturnedBins[i].FullWeight ? itemsReturnedBins[i].FullWeight :  0,
                        TOTAL_NETO: itemsReturnedBins[i].NetWeight ? itemsReturnedBins[i].NetWeight :  0,
    
                        TOTAL_TARE_TON: itemsReturnedBins[i].TareWeightTon ? itemsReturnedBins[i].TareWeightTon :  0,
                        TOTAL_FULL_TON: itemsReturnedBins[i].FullWeightTon ? itemsReturnedBins[i].FullWeightTon :  0,
                        TOTAL_NETO_TON: itemsReturnedBins[i].NetWeightTon ? itemsReturnedBins[i].NetWeightTon :  0,
                    });
                    
    
                    sheetsReturnedBinsData.push({
                        sheetReturnedBins, 
                    });
                    sheetsReturnedBinsDataYards.push({
                        sheetReturnedBinsDispacher,
                    });
                    
                }
            }
        }
        /************************DATA HOJAS DINAMICAS DE GAMELAS DEVUELTAS END********************/

        let rowData = [{
            sheetWeightTareData: sheetWeightTareData,
            sheetWeightTareDataClients: sheetWeightTareDataClients,
            sheetsYardWeighingData: sheetsYardWeighingData,
            sheetsYardWeighingDataYards: sheetsYardWeighingDataYards,
            sheetsYardQuantityItemsPerDispacher: sheetsYardQuantityItemsPerDispacher,
            sheetStatisticsData: sheetStatisticsData,
            sheetStatisticsDataTotals: sheetStatisticsDataTotals,
            sheetStatisticsYardData: sheetStatisticsYardData,
            sheetStatisticsYardDataTotals: sheetStatisticsYardDataTotals,
            sheetsStatisticsYardQuantityItems: sheetsStatisticsYardQuantityItems,
            sheetGamelasData: sheetGamelasData,
            sheetGamelasDataClients: sheetGamelasDataClients,
            sheetsReturnedBinsData: sheetsReturnedBinsData,
            sheetsReturnedBinsDataYards: sheetsReturnedBinsDataYards,
            sheetsReturnedBinsQuantityItemsPerDispacher: sheetsReturnedBinsQuantityItemsPerDispacher,

        }];

        const workbook = new Excel.Workbook();
        const documento = this.$t('label.weighingReport');
        let response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });
    
        let divisiones1 = this.user.LogoRoute.split(".");
        let myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        let imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });

        let nameVessel = "";
        nameVessel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - Viaje: "+this.itinerarySelected.Voyage;
    
        let hoy = new Date();
        let letra = 12;
        /****************************************************************************
        ******************** HOJA DE DATOS Y PESO TARA START*************************
        *****************************************************************************/
        if (items[0].ExcelJson.length>0){       
            var titulo= items[0].ExcelTabName;
            const worksheet = workbook.addWorksheet(titulo);
           
            let y = 0;
            let columnsDefs = [];
            let keyColumns = [];
            bandera = true;
          
            keyColumns = Object.keys(rowData[0].sheetWeightTareData[0]);
            columnsDefs = [
                '#',
                this.$t('label.Gamela'),
                this.$t('label.plate'),
                this.$t('label.Taraweight'),
                this.$t('label.transport'),
            ];
            y = 15;
            bandera = false;
          
            if (y < 10) letra = 8;
            worksheet.getColumn(7).width = 25;
            worksheet.mergeCells('A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
          
            worksheet.addImage(imageId, 'A1:B2');
          
            let rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;
          
            worksheet.mergeCells("C1:G1");
            worksheet.mergeCells("C2:G2");
            const customCellCompanyBranch = worksheet.getCell("C1");

            let row = worksheet.getRow(1);
            row.height = 40;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            const row2 = worksheet.getRow(2);    
            row2.height = 30;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };      
          
            customCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                },
            }
          
            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
                 
            customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            customCell.value = nameVessel;
          
            /**************titulo hoja inicio***************/
            worksheet.mergeCells("A3:G3");
            var customCell6 = worksheet.getCell("A3");
            customCell6.height = 30;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell6.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
            }
            customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell6.value = items[0].ExcelTabName;
            /**************titulo hoja fin***************/

            /**************filtros inicio***************/ 
            worksheet.mergeCells("A4:D4");
            let customCellDate = worksheet.getCell("A4");
            customCellDate.height = 50;
            customCellDate.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellDate.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd;

            worksheet.mergeCells("E4:F4");
            let customCell2 = worksheet.getCell("E4");
            customCell2.height = 50;
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell2.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
           
            //worksheet.mergeCells("G4:H4");
            let customCell4 = worksheet.getCell("G4");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell4.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            /**************filtros fin*************************/
          
            /**************fila divisoria inicio***************/
            worksheet.mergeCells("A5:G5");
            let customCell5 = worksheet.getCell("A5");
            customCell5.height = 50;
            customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
          
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            /**************fila divisoria fin*****************/
            
            let i = 0;
            columnsDefs.map(function (data) {
              let valor = bandera ? data.headerName.length : data.length;

              let long = 10;
              if ((valor >= 5) && (valor <= 12)) {
                  long = 20;
              } else if ((valor >= 11) && (valor <= 18)) {
                  long = 25;
              } else if ((valor >= 19) && (valor <= 26)) {
                  long = 30;
              } else if (valor >= 27) {
                  long = 40;
              }
          
              worksheet.getColumn(i + 2).width = long;
              let cell = headerRow.getCell(i + 2);
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
              }
              cell.value = data.replace("_"," ");
              cell.alignment = { vertical: 'middle', horizontal: 'center' }
              i++;
            })
            worksheet.autoFilter = {
                from: 'A5',
                to: numeroLetra(y),
            } 
            

            rowData[0].sheetWeightTareData.map(async function (data) {
                let dataRow = worksheet.addRow();
                let indice = 0;
                bandera=false;
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 2);
                    cell.value = data[data2];
                    if (indice!=0 && !isNaN(data[data2])) {
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                    }   
                    
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                    indice++;
                })
            })

            let mergeWeightTareDataStart = 7;
            let mergeWeightTareDataEnd = 7;
            for(let i= 0; i<rowData[0].sheetWeightTareDataClients.length; i++ ){
                if(rowData[0].sheetWeightTareDataClients[i].QUANTITY_ROWS > 1){
                    mergeWeightTareDataEnd = mergeWeightTareDataEnd+
                    rowData[0].sheetWeightTareDataClients[i].QUANTITY_ROWS-1;

                    worksheet.mergeCells('F'+mergeWeightTareDataStart+':F'+mergeWeightTareDataEnd);
                    worksheet.getCell('F'+mergeWeightTareDataStart).font = { bold: true };     

                    worksheet.getCell('F'+mergeWeightTareDataStart).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: ''+rowData[0].sheetWeightTareDataClients[i].CLIENT_COLOR+'',
                        }
                    }
                    worksheet.getCell('F'+mergeWeightTareDataStart).font = { bold: true };   
                    mergeWeightTareDataStart = mergeWeightTareDataEnd+1;
                    mergeWeightTareDataEnd = mergeWeightTareDataEnd+1;

                }else{
                    worksheet.getCell('F'+mergeWeightTareDataStart).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: ''+rowData[0].sheetWeightTareDataClients[i].CLIENT_COLOR+'',
                        }
                    }
                    worksheet.getCell('F'+mergeWeightTareDataStart).font = { bold: true }; 
                    mergeWeightTareDataEnd = mergeWeightTareDataEnd+1;
                    mergeWeightTareDataStart = mergeWeightTareDataStart+1; 
                }
            }
        } 
        /****************************************************************************
        ******************** HOJA DE DATOS Y PESO TARA  END**************************
        *****************************************************************************/

        /****************************************************************************
        ***************HOJAS DINAMICAS DE PESAJE POR PATIOS START********************
        *****************************************************************************/
        if (items[1].ExcelJson.length>0){    
            let longitudYard=rowData[0].sheetsYardWeighingData.length;
            for(let k=0; k<longitudYard; k++){  
            
                if(items[1].ExcelJson[k].DataJson.length > 0) {
                    titulo= items[1].ExcelJson[k].YardName.replaceAll("/","-");;
                    var worksheetYardWeighing = workbook.addWorksheet(titulo);
                     
            
                    let y = 0;
                    let letra = 12;
                    var columnsDefsYardWeighing = [];
                    var keyColumnsYardWeighing = [];
                    bandera = true;
                
                    keyColumnsYardWeighing = Object.keys(rowData[0].sheetsYardWeighingData[k].sheetYardWeighing[0]);
                    
                    columnsDefsYardWeighing = [
                        '#',
                        this.$t('label.Gamela'),
                        this.$t('label.plate'),
                        this.$t('label.Taraweight'),
                        this.$t('label.gross'),
                        this.$t('label.netWeight'),
                        this.$t('label.order'),
                        this.$t('label.dispacher'),
                        this.$t('label.date'),
                        this.$t('label.hour'),
                        this.$t('label.observation'), 
                    ];  

                    bandera = false;
                    y = columnsDefsYardWeighing.length;
                    if (y < 10) letra = 8;
                    worksheetYardWeighing.mergeCells('A1:B2');
                    worksheetYardWeighing.getCell("A1").border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
            
                    worksheetYardWeighing.addImage(imageId, 'A1:B2');
            
                    let rowIndex1 = worksheetYardWeighing.getRow(1);
                    rowIndex1.height = 100;
            
                    worksheetYardWeighing.mergeCells("C1:" + numeroLetra(y) +"1");
                    const customCellCompanyBranch = worksheetYardWeighing.getCell("C1");

                    let row = worksheetYardWeighing.getRow(1);
                    row.height = 40;
                    customCellCompanyBranch.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellCompanyBranch.note = {
                        margins: {
                            insetmode: 'auto',
                            inset: [10, 10, 5, 5]
                        }
                    }
                    customCellCompanyBranch.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'f2f2f2'
                        }
                    }
                    customCellCompanyBranch.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
                    customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

                    let row3 = worksheetYardWeighing.getRow(2);   
                    
                    row3.height = 30;
                    worksheetYardWeighing.mergeCells("C2:" + numeroLetra(y) +"2");
                    const customCellTitulo = worksheetYardWeighing.getCell("C2");  
                    customCellTitulo.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };      
            
                    customCellTitulo.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'f2f2f2'
                        },
                    }
            
                    customCellTitulo.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                        
                    customCellTitulo.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                    customCellTitulo.value = nameVessel;

                    /**************titulo hoja inicio***************/
                    worksheetYardWeighing.mergeCells("A3:"+numeroLetra(y)+"3");
                    var customCell6 = worksheetYardWeighing.getCell("A3");
                    customCell6.height = 30;
                    customCell6.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell6.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                        bottom: { style: 'thin' },
                    }
                    customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell6.value = this.$t('label.binWeighingPerYard')+': '+titulo;
                    /**************titulo hoja fin***************/

                    /**************filtros inicio***************/
                    worksheetYardWeighing.mergeCells("A4:E4");
                    let customCellDate = worksheetYardWeighing.getCell("A4");
                    customCellDate.height = 50;
                    customCellDate.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellDate.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd;
                    //printed
                    worksheetYardWeighing.mergeCells("F4:H4");
                    let customCell2 = worksheetYardWeighing.getCell("F4");
                    customCell2.height = 50;
                    customCell2.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell2.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 

                    //user
                    worksheetYardWeighing.mergeCells("I4:K4");
                    let customCell4 = worksheetYardWeighing.getCell("I4");
                    customCell4.height = 50;
                    customCell4.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell4.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                    /**************filtros fin*************************/
            
                    /**************fila divisoria inicio***************/
                    worksheetYardWeighing.mergeCells("A5:"+numeroLetra(y)+"5");
                    let customCell5 = worksheetYardWeighing.getCell("A5");
                    customCell5.height = 50;
                    customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
                    /**************fila divisoria fin*****************/
            
                    var headerRow = worksheetYardWeighing.addRow();
                    headerRow.font = {
                        name: "Calibri",
                        family: 4,
                        size: 11,
                        underline: false,
                        bold: true
                    };
                    
                    let i = 0;
                    columnsDefsYardWeighing.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
            
                    worksheetYardWeighing.getColumn(i + 1).width = long;
                    let cell = headerRow.getCell(i + 1);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    cell.value = data.replace("_"," ");
                    cell.alignment = { vertical: 'middle', horizontal: 'center' }
                    i++;
                    })
                    worksheetYardWeighing.autoFilter = {
                    from: 'A6',
                    to: numeroLetra(y),
                    } 
            
                    let indicador =k;
                    let dispacher = 0;

                    rowData[0].sheetsYardWeighingData[indicador].sheetYardWeighing.map(async function (data) {

                    let dataRow = worksheetYardWeighing.addRow();
                    let indice = 0;
                    bandera=false;
                    
                    keyColumnsYardWeighing.map(function (data2) {      
                        let  cell = dataRow.getCell(indice + 1);
                        cell.value = data[data2];
                        if (indice!=0 && !isNaN(data[data2]) && 
                            (data2 == 'TARE_WEIGHT' || data2 == 'GROSS_WEIGHT' || data2 == 'NET_WEIGHT')) 
                        {
                            cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        }  
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: ''+rowData[0].sheetsYardWeighingDataYards[k].sheetYardWeighingDispacher[dispacher].DISPACHER_COLOR+'',
                            }
                        }
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            
                        indice++;
                    })
                    dispacher++;
                    })
                    
                    var mergeYardWeighingDataStart = 7;
                    var mergeYardWeighingDataEnd = 7;
                    for(let i= 0; i<rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher.length; i++ ){

                        if(rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher[i].QUANTITY_ROWS > 1){

                            mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+
                            rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher[i].QUANTITY_ROWS-1;

                            worksheetYardWeighing.mergeCells('I'+mergeYardWeighingDataStart+':I'+mergeYardWeighingDataEnd);
                            worksheetYardWeighing.getCell('I'+mergeYardWeighingDataStart).font = { bold: true };  
                            
                            mergeYardWeighingDataStart = mergeYardWeighingDataEnd+1;
                            mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+1;

                        }else{
                            worksheetYardWeighing.getCell('I'+mergeYardWeighingDataStart).font = { bold: true }; 
                            mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+1;
                            mergeYardWeighingDataStart = mergeYardWeighingDataStart+1; 
                        }
                    }

                    /******************************totales inicio***********************************************/
                    worksheetYardWeighing.getColumn(6).width = 27;
                    /***************** inicio total gamelas*******************/
                    var mergeYardWeighingDataEnd2 = mergeYardWeighingDataEnd;
                    mergeYardWeighingDataEnd2++;

                    worksheetYardWeighing.mergeCells("A"+mergeYardWeighingDataEnd+":B"+mergeYardWeighingDataEnd2);
                    var customCellTotalBins = worksheetYardWeighing.getCell("A"+mergeYardWeighingDataEnd);
                    customCellTotalBins.height = 50;
                    customCellTotalBins.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalBins.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalBins.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalBins.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalBins.value = this.$t('label.TotalGamelas');
                    
                    worksheetYardWeighing.mergeCells("C"+mergeYardWeighingDataEnd+":C"+mergeYardWeighingDataEnd2);
                    worksheetYardWeighing.getCell("C"+mergeYardWeighingDataEnd).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("C"+mergeYardWeighingDataEnd).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("C"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("C"+mergeYardWeighingDataEnd).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_GAMELAS;
                    /***************** fin total gamelas*******************/

                    /***************** inicio total tara kg*******************/
                    var customCellTotalTara = worksheetYardWeighing.getCell("D"+mergeYardWeighingDataEnd);
                    customCellTotalTara.height = 50;
                    customCellTotalTara.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalTara.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalTara.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalTara.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalTara.value = this.$t('label.tareTotal')+' (KG)';

                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_TARE;
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';
                    /***************** fin total tara kg*******************/

                    /***************** inicio total tara Tons*******************/
                    var customCellTotalTaraTons = worksheetYardWeighing.getCell("D"+mergeYardWeighingDataEnd2);
                    customCellTotalTaraTons.height = 50;
                    customCellTotalTaraTons.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalTaraTons.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalTaraTons.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalTaraTons.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalTaraTons.value = this.$t('label.tareTotal')+' (TON)';

                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd2).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd2).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_TARE_TON;
                    worksheetYardWeighing.getCell("E"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';

                    /***************** fin total tara tons*******************/

                    /***************** inicio total peso bruto *******************/
                    var customCellTotalGross = worksheetYardWeighing.getCell("F"+mergeYardWeighingDataEnd);
                    customCellTotalGross.height = 50;
                    customCellTotalGross.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalGross.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalGross.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalGross.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalGross.value = this.$t('label.grossTotal')+' (KG)';

                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_GROSS;
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';

                    /***************** fin total peso bruto **************************/
                    /***************** inicio total peso bruto tons*******************/

                    var customCellTotalGrossTons = worksheetYardWeighing.getCell("F"+mergeYardWeighingDataEnd2);
                    customCellTotalGrossTons.height = 50;
                    customCellTotalGrossTons.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalGrossTons.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalGrossTons.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalGrossTons.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalGrossTons.value = this.$t('label.grossTotal')+' (TON)';

                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd2).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd2).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_FULL_TON;
                    worksheetYardWeighing.getCell("G"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';

                   /***************** fin total peso bruto tons*******************/

                   /***************** fin total peso neto *******************/
                    var customCellTotalNeto = worksheetYardWeighing.getCell("H"+mergeYardWeighingDataEnd);
                    customCellTotalNeto.height = 50;
                    customCellTotalNeto.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellTotalNeto.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    customCellTotalNeto.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellTotalNeto.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellTotalNeto.value = this.$t('label.netoTotal')+' (KG)';

                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_NETO;
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';
                   
                   /***************** fin total peso neto *******************/
                   /***************** fin total peso neto tons*******************/
                   var customCellTotalNetoTons = worksheetYardWeighing.getCell("H"+mergeYardWeighingDataEnd2);
                   customCellTotalNetoTons.height = 50;
                   customCellTotalNetoTons.font = {
                       name: "Calibri",
                       family: 4,
                       size: letra,
                       underline: false,
                       bold: true
                   };
                   customCellTotalNetoTons.fill = {
                       type: 'pattern',
                       pattern: 'solid',
                       fgColor: {
                           argb: 'cccccc'
                       }
                   }
                   customCellTotalNetoTons.border = {
                       bottom: { style: 'thin' },
                       left: { style: 'thin' },
                       right: { style: 'thin' },
                   }
                   customCellTotalNetoTons.alignment = { vertical: 'middle', horizontal: 'center' }
                   customCellTotalNetoTons.value = this.$t('label.netoTotal')+' (TON)';

                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd2).font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd2).border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsYardQuantityItemsPerDispacher[indicador].TOTAL_NETO_TON;
                    worksheetYardWeighing.getCell("I"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';
                   
                    /***************** fin total peso neto tons*******************/
                
                   
                    /**************totales fin*************************/
                }
            }
        }
        /****************************************************************************
        ****************HOJAS DINAMICAS DE PESAJE POR PATIOS END*********************
        *****************************************************************************/

        /****************************************************************************
        ************************HOJA DE ESTADISTICAS START***************************
        *****************************************************************************/
        if (items[2].ExcelJson.length>0 && items[2].ExcelJson[0].DataJson.length > 0 ){
           titulo= items[2].ExcelTabName;
           const worksheetStatistics= workbook.addWorksheet(titulo);
           
            
           let y = 0;
           let letra = 12;
           let columnsDefs = [];
           let keyColumns = [];
           bandera = true;
         
           keyColumns = Object.keys(rowData[0].sheetStatisticsData[0]);
        
           columnsDefs = [
                '#',
                this.$t('label.Gamela'),
                this.$t('label.plate'),
                this.$t('label.voyage'),
                this.$t('label.weight')+' (KG)',
                this.$t('label.weight')+' (TON)',
           ];

           y = 14;
           bandera = false;
         
           if (y < 10) letra = 8;
           worksheetStatistics.mergeCells('A1:B2');
           worksheetStatistics.getCell("A1").border = {
               top: { style: 'thin' },
               bottom: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
         
           worksheetStatistics.addImage(imageId, 'A1:B2');
         
           let rowIndex1 = worksheetStatistics.getRow(1);
           rowIndex1.height = 40;
         
           worksheetStatistics.mergeCells("C1:"+numeroLetra(y)+"1");
           worksheetStatistics.mergeCells("C2:"+numeroLetra(y)+"2");
           const customCellCompanyBranchEndPage = worksheetStatistics.getCell("C1");

           let rowFinal = worksheetStatistics.getRow(1);
           rowFinal.height = 30;
           customCellCompanyBranchEndPage.font = {
               name: "Calibri",
               family: 4,
               size: letra,
               underline: false,
               bold: true
           };
          /* customCellCompanyBranchEndPage.note = {
               margins: {
                   insetmode: 'auto',
                   inset: [10, 10, 5, 5]
               }
           }*/
           customCellCompanyBranchEndPage.fill = {
               type: 'pattern',
               pattern: 'solid',
               fgColor: {
                   argb: 'f2f2f2'
               }
           }
           customCellCompanyBranchEndPage.border = {
               top: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
           customCellCompanyBranchEndPage.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
           customCellCompanyBranchEndPage.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
           let rowBuque = worksheetStatistics.getRow(2);
           rowBuque.height = 30;
           let customCellBuque = worksheetStatistics.getCell("C2");

           customCellBuque.font = {
               name: "Calibri",
               family: 4,
               size: letra,
               underline: false,
               bold: true
           };      
         
           customCellBuque.fill = {
               type: 'pattern',
               pattern: 'solid',
               fgColor: {
                   argb: 'f2f2f2'
               },
           }
         
           customCellBuque.border = {
               bottom: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
                
           customCellBuque.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
           customCellBuque.value = nameVessel;

            /**************titulo hoja inicio***************/
            worksheetStatistics.mergeCells("A3:"+numeroLetra(y)+"3");
            let customCell6 = worksheetStatistics.getCell("A3");
            customCell6.height = 30;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell6.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
            }
            customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell6.value = this.$t('label.statistics');
            /**************titulo hoja fin***************/
            /**************filtros inicio***************/
           worksheetStatistics.mergeCells("A4:F4");
            let customCellDate = worksheetStatistics.getCell("A4");
            customCellDate.height = 50;
            customCellDate.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellDate.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd;

           worksheetStatistics.mergeCells("G4:J4");
           let customCell2 = worksheetStatistics.getCell("G4");
           customCell2.height = 50;
           customCell2.font = {
               name: "Calibri",
               family: 4,
               size: letra,
               underline: false,
               bold: true
           };
           customCell2.border = {
               top: { style: 'thin' },
               bottom: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
           customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
           customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
         
           worksheetStatistics.mergeCells("K4:N4");
           let customCell4 = worksheetStatistics.getCell("K4");
           customCell4.height = 50;
           customCell4.font = {
               name: "Calibri",
               family: 4,
               size: letra,
               underline: false,
               bold: true
           };
           customCell4.border = {
               top: { style: 'thin' },
               bottom: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
           customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
           customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            /**************filtros fin*************************/
            
            /**************fila divisoria inicio***************/
            worksheetStatistics.mergeCells("A5:"+numeroLetra(y)+"5");
            let  customCell5 = worksheetStatistics.getCell("A5");
            customCell5.height = 50;
            customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
            /**************fila divisoria fin*****************/
         
           var headerRow = worksheetStatistics.addRow();
           headerRow.font = {
               name: "Calibri",
               family: 4,
               size: 11,
               underline: false,
               bold: true
           };
           
           let i = 0;
           columnsDefs.map(function (data) {
             let valor = bandera ? data.headerName.length : data.length;
             let long = 10;
             if ((valor >= 5) && (valor <= 12)) {
                 long = 20;
             } else if ((valor >= 11) && (valor <= 18)) {
                 long = 25;
             } else if ((valor >= 19) && (valor <= 26)) {
                 long = 30;
             } else if (valor >= 27) {
                 long = 40;
             }
         
             worksheetStatistics.getColumn(i + 1).width = long;
             let cell = headerRow.getCell(i + 1);
             cell.fill = {
               type: 'pattern',
               pattern: 'solid',
               fgColor: {
                   argb: 'cccccc'
               }
             }
             cell.border = {
               top: { style: 'thin' },
               bottom: { style: 'thin' },
               left: { style: 'thin' },
               right: { style: 'thin' },
           }
             cell.value = data.replace("_"," ");
             cell.alignment = { vertical: 'middle', horizontal: 'center' }
             i++;
           })
         
           rowData[0].sheetStatisticsData.map(async function (data) {
                let dataRow = worksheetStatistics.addRow();
                let indice = 0;
                bandera=false;
                keyColumns.map(function (data2) {
                let  cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    if (indice!=0 && !isNaN(data[data2]) && 
                    (data2 == 'TARE_WEIGHT' || data2 == 'WEIGTH' || data2 == 'WEIGTH_TONS')) 
                    {
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                    }  
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            
                    indice++;
             })
           })

            let statisticsBarLength = rowData[0].sheetStatisticsData.length + 7;
            worksheetStatistics.addConditionalFormatting({
                ref: "D7:D"+statisticsBarLength,
                rules: [
                    {
                        priority: 1,
                        type: "dataBar",
                        minLength: 10,
                        cfvo: [{type: "min"}, {type: "max"}],
                        color: {argb: "FFFF5050"}
                    }
                ],
            });
            /**************************TOTALS STATISTICS START***********************************/
            worksheetStatistics.mergeCells("G6:H6");
            let customCellVoyage = worksheetStatistics.getCell("G6");
            customCellVoyage.height = 30;
            customCellVoyage.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellVoyage.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellVoyage.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
            customCellVoyage.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellVoyage.value = this.$t('label.voyagesTotal');

            worksheetStatistics.mergeCells("G7:H7");
            let customCellVoyageValue = worksheetStatistics.getCell("G7");
            customCellVoyageValue.height = 30;
            customCellVoyageValue.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellVoyageValue.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellVoyageValue.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellVoyageValue.value = (items[2].ExcelJson.length > 0) ? rowData[0].sheetStatisticsDataTotals[0].TotalVoyages : 0;
            
            worksheetStatistics.mergeCells("I6:J6");
            let customCellWeight = worksheetStatistics.getCell("I6");
            customCellWeight.height = 30;
            customCellWeight.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellWeight.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellWeight.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
            customCellWeight.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellWeight.value = this.$t('label.weightTotal')+' (KG)';

            worksheetStatistics.mergeCells("I7:J7");
            let customCellWeightValue = worksheetStatistics.getCell("I7");
            customCellWeightValue.height = 30;
            customCellWeightValue.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellWeightValue.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellWeightValue.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellWeightValue.value = (items[2].ExcelJson.length > 0) ? rowData[0].sheetStatisticsDataTotals[0].TotalWeight : 0;
            customCellWeightValue.numFmt = '#,##0.00;[Red]\-#,##0.00';



            worksheetStatistics.mergeCells("K6:L6");
            let customCellWeightTon = worksheetStatistics.getCell("K6");
            customCellWeightTon.height = 30;
            customCellWeightTon.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellWeightTon.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellWeightTon.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
            customCellWeightTon.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellWeightTon.value = this.$t('label.weightTotal')+' (TON)';

            worksheetStatistics.mergeCells("K7:L7");
            let customCellWeightTonValue = worksheetStatistics.getCell("K7");
            customCellWeightTonValue.height = 30;
            customCellWeightTonValue.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellWeightTonValue.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellWeightTonValue.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellWeightTonValue.value = (items[2].ExcelJson.length > 0) ? rowData[0].sheetStatisticsDataTotals[0].TotalWeightTon : 0;
            customCellWeightTonValue.numFmt = '#,##0.00;[Red]\-#,##0.00';


            worksheetStatistics.mergeCells("M6:N6");
            let customCellAverage = worksheetStatistics.getCell("M6");
            customCellAverage.height = 30;
            customCellAverage.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellAverage.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellAverage.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
            customCellAverage.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellAverage.value = this.$t('label.average');

            worksheetStatistics.mergeCells("M7:N7");
            let customCellAverageValue = worksheetStatistics.getCell("M7");
            customCellAverageValue.height = 30;
            customCellAverageValue.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellAverageValue.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellAverageValue.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellAverageValue.value = (items[2].ExcelJson.length > 0) ? rowData[0].sheetStatisticsDataTotals[0].Average : 0;
            customCellAverageValue.numFmt = '#,##0.00;[Red]\-#,##0.00';

    
            /**************************TOTALS STATISTICS END***********************************/
        }
        /****************************************************************************
        ************************HOJA DE ESTADISTICAS END*****************************
        *****************************************************************************/

        /****************************************************************************
        ************************HOJA DE ESTADISTICAS POR PATIOS START****************
        *****************************************************************************/
        if (items[3].ExcelJson.length>0 && items[3].ExcelJson[0].DataJson.length > 0 ){
            titulo= items[3].ExcelTabName;
            const worksheetStatisticsYard= workbook.addWorksheet(titulo);
            
             
            let y = 0;
            let letra = 12;
            let columnsDefs = [];
            let keyColumns = [];
            bandera = true;
          
            keyColumns = Object.keys(rowData[0].sheetStatisticsYardData[0]);
         
            columnsDefs = [
                this.$t('label.YardYard'),
                 '#',
                 this.$t('label.Gamela'),
                 this.$t('label.plate'),
                 this.$t('label.voyage'),
                 this.$t('label.weight')+' (KG)',
                 this.$t('label.weight')+' (TON)',
            ];
 
            y = 15;
            bandera = false;
          
            if (y < 10) letra = 8;
            worksheetStatisticsYard.mergeCells('A1:B2');
            worksheetStatisticsYard.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
          
            worksheetStatisticsYard.addImage(imageId, 'A1:B2');
          
            let rowIndex1 = worksheetStatisticsYard.getRow(1);
            rowIndex1.height = 40;
          
            worksheetStatisticsYard.mergeCells("C1:"+numeroLetra(y)+"1");
            worksheetStatisticsYard.mergeCells("C2:"+numeroLetra(y)+"2");
            const customCellCompanyBranchEndPage = worksheetStatisticsYard.getCell("C1");
 
            let rowFinal = worksheetStatisticsYard.getRow(1);
            rowFinal.height = 30;
            customCellCompanyBranchEndPage.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
           /* customCellCompanyBranchEndPage.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }*/
            customCellCompanyBranchEndPage.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranchEndPage.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranchEndPage.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranchEndPage.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            let rowBuque = worksheetStatisticsYard.getRow(2);
            rowBuque.height = 30;
            let customCellBuque = worksheetStatisticsYard.getCell("C2");
 
            customCellBuque.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };      
          
            customCellBuque.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                },
            }
          
            customCellBuque.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
                 
            customCellBuque.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            customCellBuque.value = nameVessel;
 
             /**************titulo hoja inicio***************/
             worksheetStatisticsYard.mergeCells("A3:"+numeroLetra(y)+"3");
             let customCell6 = worksheetStatisticsYard.getCell("A3");
             customCell6.height = 30;
             customCell6.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCell6.border = {
                 top: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
                 bottom: { style: 'thin' },
             }
             customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
             customCell6.value = titulo;
             /**************titulo hoja fin***************/
             /**************filtros inicio***************/
            worksheetStatisticsYard.mergeCells("A4:F4");
             let customCellDate = worksheetStatisticsYard.getCell("A4");
             customCellDate.height = 50;
             customCellDate.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCellDate.border = {
                 bottom: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
             }
             customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
             customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd;
 
            worksheetStatisticsYard.mergeCells("G4:J4");
            let customCell2 = worksheetStatisticsYard.getCell("G4");
            customCell2.height = 50;
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
            
            worksheetStatisticsYard.mergeCells("K4:"+numeroLetra(y)+"4");
            let customCell4 = worksheetStatisticsYard.getCell("K4");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
             /**************filtros fin*************************/
             
             /**************fila divisoria inicio***************/
             worksheetStatisticsYard.mergeCells("A5:"+numeroLetra(y)+"5");
             let  customCell5 = worksheetStatisticsYard.getCell("A5");
             customCell5.height = 50;
             customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
             /**************fila divisoria fin*****************/
          
            var headerRow = worksheetStatisticsYard.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            
            let i = 0;
            columnsDefs.map(function (data) {
              let valor = bandera ? data.headerName.length : data.length;
              let long = 10;
              if ((valor >= 5) && (valor <= 12)) {
                  long = 20;
              } else if ((valor >= 11) && (valor <= 18)) {
                  long = 25;
              } else if ((valor >= 19) && (valor <= 26)) {
                  long = 30;
              } else if (valor >= 27) {
                  long = 40;
              }
          
              worksheetStatisticsYard.getColumn(i + 1).width = long;
              let cell = headerRow.getCell(i + 1);
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
              cell.value = data.replace("_"," ");
              cell.alignment = { vertical: 'middle', horizontal: 'center' }
              i++;
            })
          
            rowData[0].sheetStatisticsYardData.map(async function (data) {
              let dataRow = worksheetStatisticsYard.addRow();
              let indice = 0;
              bandera=false;
              keyColumns.map(function (data2) {
                let  cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    if (indice!=0 && !isNaN(data[data2]) && 
                    (data2 == 'TARE_WEIGHT' || data2 == 'WEIGTH' || data2 == 'WEIGTH_TONS')) 
                    {
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                    } 

                  cell.border = {
                      top: { style: 'thin' },
                      bottom: { style: 'thin' },
                      left: { style: 'thin' },
                      right: { style: 'thin' },
                  };
                  cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
          
                  indice++;
              })
            })

            var mergeStatisticsYardDataStart = 7;
            var mergeStatisticsYardDataEnd = 7;
            for(let i= 0; i<rowData[0].sheetsStatisticsYardQuantityItems.length; i++ ){

                if(rowData[0].sheetsStatisticsYardQuantityItems[i].QUANTITY_ROWS > 1){

                    mergeStatisticsYardDataEnd = mergeStatisticsYardDataEnd+
                    rowData[0].sheetsStatisticsYardQuantityItems[i].QUANTITY_ROWS-1;

                    worksheetStatisticsYard.mergeCells('A'+mergeStatisticsYardDataStart+':A'+mergeStatisticsYardDataEnd);
                    worksheetStatisticsYard.getCell('A'+mergeStatisticsYardDataStart).font = { bold: true };     
                    worksheetStatisticsYard.getCell('A'+mergeStatisticsYardDataStart).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: ''+rowData[0].sheetsStatisticsYardQuantityItems[i].YARD_COLOR+'',
                        }
                    }

                    mergeStatisticsYardDataStart = mergeStatisticsYardDataEnd+1;
                    mergeStatisticsYardDataEnd = mergeStatisticsYardDataEnd+1;

                }else{
                    worksheetStatisticsYard.getCell('A'+mergeStatisticsYardDataStart).font = { bold: true };
                    mergeStatisticsYardDataEnd = mergeStatisticsYardDataEnd+1;
                    mergeStatisticsYardDataStart = mergeStatisticsYardDataStart+1; 
                }
            }

            let statisticsBarLength = rowData[0].sheetStatisticsYardData.length + 7;
            worksheetStatisticsYard.addConditionalFormatting({
                ref: "E7:E"+statisticsBarLength,
                rules: [
                    {
                        priority: 1,
                        type: "dataBar",
                        minLength: 10,
                        cfvo: [{type: "min"}, {type: "max"}],
                        color: {argb: "FFFF5050"}
                    }
                ],
            });

             /**************************TOTALS STATISTICS START***********************************/
             worksheetStatisticsYard.mergeCells("H6:I6");
             let customCellVoyage = worksheetStatisticsYard.getCell("H6");
             customCellVoyage.height = 30;
             customCellVoyage.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCellVoyage.border = {
                 top: { style: 'thin' },
                 bottom: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
             }
             customCellVoyage.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: {
                     argb: 'cccccc'
                 },
             }
             customCellVoyage.alignment = { vertical: 'middle', horizontal: 'center' }
             customCellVoyage.value = this.$t('label.voyagesTotal');
 
            //  worksheetStatisticsYard.mergeCells("H7:I7");
            //  let customCellVoyageValue = worksheetStatisticsYard.getCell("H7");
            //  customCellVoyageValue.height = 30;
            //  customCellVoyageValue.font = {
            //      name: "Calibri",
            //      family: 4,
            //      size: letra,
            //      underline: false,
            //      bold: true
            //  };
            //  customCellVoyageValue.border = {
            //      top: { style: 'thin' },
            //      bottom: { style: 'thin' },
            //      left: { style: 'thin' },
            //      right: { style: 'thin' },
            //  }
            //  customCellVoyageValue.alignment = { vertical: 'middle', horizontal: 'center' }
            //  customCellVoyageValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[0].TotalVoyages : 0;
             
             worksheetStatisticsYard.mergeCells("J6:K6");
             let customCellWeight = worksheetStatisticsYard.getCell("J6");
             customCellWeight.height = 30;
             customCellWeight.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCellWeight.border = {
                 top: { style: 'thin' },
                 bottom: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
             }
             customCellWeight.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: {
                     argb: 'cccccc'
                 },
             }
             customCellWeight.alignment = { vertical: 'middle', horizontal: 'center' }
             customCellWeight.value = this.$t('label.weightTotal')+' (KG)';
 
            //  worksheetStatisticsYard.mergeCells("J7:K7");
            //  let customCellWeightValue = worksheetStatisticsYard.getCell("J7");
            //  customCellWeightValue.height = 30;
            //  customCellWeightValue.font = {
            //      name: "Calibri",
            //      family: 4,
            //      size: letra,
            //      underline: false,
            //      bold: true
            //  };
            //  customCellWeightValue.border = {
            //      top: { style: 'thin' },
            //      bottom: { style: 'thin' },
            //      left: { style: 'thin' },
            //      right: { style: 'thin' },
            //  }
            //  customCellWeightValue.alignment = { vertical: 'middle', horizontal: 'center' }
            //  customCellWeightValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[0].TotalWeight : 0;
            //  customCellWeightValue.numFmt = '#,##0.00;[Red]\-#,##0.00';
 
 
 
             worksheetStatisticsYard.mergeCells("L6:M6");
             let customCellWeightTon = worksheetStatisticsYard.getCell("L6");
             customCellWeightTon.height = 30;
             customCellWeightTon.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCellWeightTon.border = {
                 top: { style: 'thin' },
                 bottom: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
             }
             customCellWeightTon.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: {
                     argb: 'cccccc'
                 },
             }
             customCellWeightTon.alignment = { vertical: 'middle', horizontal: 'center' }
             customCellWeightTon.value = this.$t('label.weightTotal')+' (TON)';
 
            //  worksheetStatisticsYard.mergeCells("L7:M7");
            //  let customCellWeightTonValue = worksheetStatisticsYard.getCell("L7");
            //  customCellWeightTonValue.height = 30;
            //  customCellWeightTonValue.font = {
            //      name: "Calibri",
            //      family: 4,
            //      size: letra,
            //      underline: false,
            //      bold: true
            //  };
            //  customCellWeightTonValue.border = {
            //      top: { style: 'thin' },
            //      bottom: { style: 'thin' },
            //      left: { style: 'thin' },
            //      right: { style: 'thin' },
            //  }
            //  customCellWeightTonValue.alignment = { vertical: 'middle', horizontal: 'center' }
            //  customCellWeightTonValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[0].TotalWeightTon : 0;
            //  customCellWeightTonValue.numFmt = '#,##0.00;[Red]\-#,##0.00';
 
 
             worksheetStatisticsYard.mergeCells("N6:O6");
             let customCellAverage = worksheetStatisticsYard.getCell("N6");
             customCellAverage.height = 30;
             customCellAverage.font = {
                 name: "Calibri",
                 family: 4,
                 size: letra,
                 underline: false,
                 bold: true
             };
             customCellAverage.border = {
                 top: { style: 'thin' },
                 bottom: { style: 'thin' },
                 left: { style: 'thin' },
                 right: { style: 'thin' },
             }
             customCellAverage.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: {
                     argb: 'cccccc'
                 },
             }
             customCellAverage.alignment = { vertical: 'middle', horizontal: 'center' }
             customCellAverage.value = this.$t('label.average');
 
            //  worksheetStatisticsYard.mergeCells("N7:O7");
            //  let customCellAverageValue = worksheetStatisticsYard.getCell("N7");
            //  customCellAverageValue.height = 30;
            //  customCellAverageValue.font = {
            //      name: "Calibri",
            //      family: 4,
            //      size: letra,
            //      underline: false,
            //      bold: true
            //  };
            //  customCellAverageValue.border = {
            //      top: { style: 'thin' },
            //      bottom: { style: 'thin' },
            //      left: { style: 'thin' },
            //      right: { style: 'thin' },
            //  }
            //  customCellAverageValue.alignment = { vertical: 'middle', horizontal: 'center' }
            //  customCellAverageValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[0].Average : 0;
            //  customCellAverageValue.numFmt = '#,##0.00;[Red]\-#,##0.00';
     
             /**************************TOTALS STATISTICS END***********************************/

            var mergeStatisticsYardDataStartTotal = 7;
            var mergeStatisticsYardDataEndTotal = 7;
            for(let i= 0; i<rowData[0].sheetsStatisticsYardQuantityItems.length; i++ ){

                if(rowData[0].sheetsStatisticsYardQuantityItems[i].QUANTITY_ROWS > 1){

                    mergeStatisticsYardDataEndTotal = mergeStatisticsYardDataEndTotal+
                    rowData[0].sheetsStatisticsYardQuantityItems[i].QUANTITY_ROWS-1;

                    worksheetStatisticsYard.mergeCells("H"+mergeStatisticsYardDataStartTotal+":I"+mergeStatisticsYardDataStartTotal);
                    let customCellVoyageValue = worksheetStatisticsYard.getCell("H"+mergeStatisticsYardDataStartTotal);
                    customCellVoyageValue.height = 30;
                    customCellVoyageValue.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellVoyageValue.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellVoyageValue.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellVoyageValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[i].TotalVoyages : 0;

                    worksheetStatisticsYard.mergeCells("J"+mergeStatisticsYardDataStartTotal+":K"+mergeStatisticsYardDataStartTotal);
                    let customCellWeightValue = worksheetStatisticsYard.getCell("J"+mergeStatisticsYardDataStartTotal);
                    customCellWeightValue.height = 30;
                    customCellWeightValue.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellWeightValue.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellWeightValue.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellWeightValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[i].TotalWeight : 0;
                    customCellWeightValue.numFmt = '#,##0.00;[Red]\-#,##0.00';

                    worksheetStatisticsYard.mergeCells("L"+mergeStatisticsYardDataStartTotal+":M"+mergeStatisticsYardDataStartTotal);
                    let customCellWeightTonValue = worksheetStatisticsYard.getCell("L"+mergeStatisticsYardDataStartTotal);
                    customCellWeightTonValue.height = 30;
                    customCellWeightTonValue.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellWeightTonValue.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellWeightTonValue.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellWeightTonValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[i].TotalWeightTon : 0;
                    customCellWeightTonValue.numFmt = '#,##0.00;[Red]\-#,##0.00';

                    worksheetStatisticsYard.mergeCells("N"+mergeStatisticsYardDataStartTotal+":O"+mergeStatisticsYardDataStartTotal);
                    let customCellAverageValue = worksheetStatisticsYard.getCell("N"+mergeStatisticsYardDataStartTotal);
                    customCellAverageValue.height = 30;
                    customCellAverageValue.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCellAverageValue.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCellAverageValue.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCellAverageValue.value = (items[3].ExcelJson.length > 0) ? rowData[0].sheetStatisticsYardDataTotals[i].Average : 0;
                    customCellAverageValue.numFmt = '#,##0.00;[Red]\-#,##0.00';


                    mergeStatisticsYardDataStartTotal = mergeStatisticsYardDataEndTotal+1;
                    mergeStatisticsYardDataEndTotal = mergeStatisticsYardDataEndTotal+1;

                }else{
                    mergeStatisticsYardDataEndTotal = mergeStatisticsYardDataEndTotal+1;
                    mergeStatisticsYardDataStartTotal = mergeStatisticsYardDataStartTotal+1; 
                }
            }
         }
         /****************************************************************************
         ************************HOJA DE ESTADISTICAS POR PATIOS END******************
         *****************************************************************************/

        /****************************************************************************
        ************************HOJA DE GAMELAS START********************************
        *****************************************************************************/
        if (items[4].ExcelJson.length>0 && items[4].ExcelJson[0].DataJson.length > 0 ){
            titulo= items[4].ExcelTabName;
            const worksheetGamelas= workbook.addWorksheet(titulo);
             
            letra = 12;
            bandera = true;
            let y = 11;
            bandera = false;
          
            if (y < 10) letra = 8;
            worksheetGamelas.mergeCells('A1:B2');
            worksheetGamelas.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
          
            worksheetGamelas.addImage(imageId, 'A1:B2');
          
            let rowIndex1 = worksheetGamelas.getRow(1);
            rowIndex1.height = 40;
          
            worksheetGamelas.mergeCells("C1:K1");
            worksheetGamelas.mergeCells("C2:K2");
            const customCellCompanyBranchGamelas = worksheetGamelas.getCell("C1");
 
            worksheetGamelas.getRow(1).height = 30;
            customCellCompanyBranchGamelas.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranchGamelas.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranchGamelas.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranchGamelas.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranchGamelas.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranchGamelas.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            worksheetGamelas.getRow(2).height = 30;
            let customCellBuqueGamelas = worksheetGamelas.getCell("C2");
 
            customCellBuqueGamelas.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };      
          
            customCellBuqueGamelas.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                },
            }
          
            customCellBuqueGamelas.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
                 
            customCellBuqueGamelas.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
            customCellBuqueGamelas.value = nameVessel;
          
            /**************titulo hoja inicio***************/
            worksheetGamelas.mergeCells("A3:"+numeroLetra(y)+"3");
            var customCell6 = worksheetGamelas.getCell("A3");
            customCell6.height = 30;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell6.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
            }
            customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell6.value = this.$t('label.binWeighing');
            /**************titulo hoja fin***************/

            /**************filtros inicio***************/
            worksheetGamelas.mergeCells("A4:D4");
            let customCellDate = worksheetGamelas.getCell("A4");
            customCellDate.height = 50;
            customCellDate.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellDate.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
            customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd;
            //printed
            worksheetGamelas.mergeCells("E4:G4");
            let customCell2 = worksheetGamelas.getCell("E4");
            customCell2.height = 50;
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
          
            worksheetGamelas.mergeCells("H4:K4");
            let customCell4 = worksheetGamelas.getCell("H4");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            /**************filtros fin*************************/

            worksheetGamelas.mergeCells("A5:"+numeroLetra(y)+"5");
            let customCell5 = worksheetGamelas.getCell("A5");
            customCell5.height = 50;
            customCell5.alignment = { vertical: 'middle', horizontal: 'center' }


            /***for ini ***** */
            let longitudYard1=rowData[0].sheetGamelasData.length - 1;

            for(let l=0; l< longitudYard1; l++){
                /*************************NOMBRE CLIENTE START*******************************/
                var columnsDefsClient = [];
                var headerRowClient = worksheetGamelas.addRow();
                headerRowClient.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };
                columnsDefsClient = [
                    '#',
                    this.$t('label.Gamela'),
                    this.$t('label.plate'),
                    this.$t('label.voyage'),
                    this.$t('label.weight'),
            ];
                let client = 0;
                columnsDefsClient.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
                
                    worksheetGamelas.getColumn(client + 2).width = long;
                    let cell = headerRowClient.getCell(client + 2);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: rowData[0].sheetGamelasDataClients[l].CLIENT_COLOR,//'cccccc'
                        }
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    cell.value = rowData[0].sheetGamelasDataClients[l].CLIENT_NAME;
                    cell.alignment = { vertical: 'middle', horizontal: 'center' }
                    client++;
                })

                var celda1 =headerRowClient.getCell(2)._address;
                var celda2 =headerRowClient.getCell(client + 1)._address;
                worksheetGamelas.mergeCells(celda1+":"+celda2);
                /*************************NOMBRE CLIENTE END**********************************/

                /***********************DATOS GAMELAS POR CLIENTE START***********************/
                var columnsDefsGamelas = [];
                var keyColumnsGamelas = [];
                keyColumnsGamelas = Object.keys(rowData[0].sheetGamelasData[l].sheetGamelasDataPerClient[0]);

                columnsDefsGamelas = columnsDefsClient;

            var headerRow = worksheetGamelas.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            
            let j = 0;
                columnsDefsGamelas.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
                
                    worksheetGamelas.getColumn(j + 2).width = long;
                    let cell = headerRow.getCell(j + 2);
                    cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'cccccc'
                    }
                    }
                    cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                    cell.value = data.replace("_"," ");
                    cell.alignment = { vertical: 'middle', horizontal: 'center' }
                    j++;
                })
                
                rowData[0].sheetGamelasData[l].sheetGamelasDataPerClient.map(async function (data) {
                    let dataRow = worksheetGamelas.addRow();
                    let indice = 0;
                    bandera=false;
                    keyColumnsGamelas.map(function (data2) {
                    let  cell = dataRow.getCell(indice + 2);
                        cell.value = data[data2];
                        if (indice!=0 && !isNaN(data[data2]) && (data2 == 'WEIGTH')) {
                            cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        } 
                        
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                
                        indice++;
                    })
                })
            
            /***********************DATOS GAMELAS POR CLIENTE END*************************/
            /*************************TOTALES CLIENTE START*******************************/
            var columnsDefsTotals = [];
            var headerRowTotals = worksheetGamelas.addRow();
            headerRowTotals.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            columnsDefsTotals = columnsDefsClient;
            let totals = 0;
            columnsDefsTotals.map(function (data) {
                let valor = bandera ? data.headerName.length : data.length;
                let long = 10;
                if ((valor >= 5) && (valor <= 12)) {
                    long = 20;
                } else if ((valor >= 11) && (valor <= 18)) {
                    long = 25;
                } else if ((valor >= 19) && (valor <= 26)) {
                    long = 30;
                } else if (valor >= 27) {
                    long = 40;
                }

                worksheetGamelas.getColumn(totals + 2).width = long;
                let cell = headerRowTotals.getCell(totals + 2);
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                cell.alignment = { vertical: 'middle', horizontal: 'center' }
                totals++;
            })

            var celda1 =headerRowTotals.getCell(2)._address;
            var celda2 =headerRowTotals.getCell(4)._address;
            var celda3 =headerRowTotals.getCell(5)._address;
            var celda4 =headerRowTotals.getCell(6)._address;

            worksheetGamelas.mergeCells(celda1+":"+celda2);
            worksheetGamelas.getCell(celda1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc',
                }
            }
            worksheetGamelas.getCell(celda1).value = this.$t('label.totals');
            worksheetGamelas.getCell(celda3).value = rowData[0].sheetGamelasDataClients[l].TOTAL_VOYAGES;
            worksheetGamelas.getCell(celda4).value = rowData[0].sheetGamelasDataClients[l].TOTAL_WEIGTH;
            worksheetGamelas.getCell(celda4).numFmt = '#,##0.00;[Red]\-#,##0.00';


            /*************************TOTALES CLIENTE END**********************************/
            var headerRowClient1 = worksheetGamelas.addRow();
            headerRowClient1.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            }
            /***for end ***** */

            worksheetGamelas.mergeCells("H6:K6");
            worksheetGamelas.getCell("H6").value = this.$t('label.totals');
            worksheetGamelas.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetGamelas.getCell("H6").fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: rowData[0].sheetGamelasDataClients[longitudYard1].CLIENT_COLOR,
                }
            }
            worksheetGamelas.getCell("H6").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            worksheetGamelas.mergeCells("H7:I7");
            worksheetGamelas.getCell("H7").value = this.$t('label.voyage');
            worksheetGamelas.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetGamelas.getCell("H7").fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            worksheetGamelas.getCell("H7").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            worksheetGamelas.mergeCells("J7:K7");
            worksheetGamelas.getCell("J7").value = this.$t('label.weight');
            worksheetGamelas.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetGamelas.getCell("J7").fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            worksheetGamelas.getCell("J7").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            worksheetGamelas.mergeCells("H8:I8");
            worksheetGamelas.getCell("H8").value = rowData[0].sheetGamelasDataClients[longitudYard1].TOTAL_VOYAGES;
            worksheetGamelas.getCell("H8").alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetGamelas.getCell("H8").font = { underline: false, bold: true};
            worksheetGamelas.getCell("H8").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            worksheetGamelas.mergeCells("J8:K8");
            worksheetGamelas.getCell("J8").value = rowData[0].sheetGamelasDataClients[longitudYard1].TOTAL_WEIGTH;
            worksheetGamelas.getCell("J8").numFmt = '#,##0.00;[Red]\-#,##0.00';

            worksheetGamelas.getCell("J8").alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetGamelas.getCell("J8").font = { underline: false, bold: true};
            worksheetGamelas.getCell("J8").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }
        /****************************************************************************
        ************************HOJA DE GAMELAS END**********************************
        *****************************************************************************/

        /****************************************************************************
        ************************HOJAS DINAMICAS GAMELAS DEVUELTAS START**************
        *****************************************************************************/
        if(items[5] !== undefined) {
            if (items[5].ExcelJson.length>0){    
                let longitudGamelas=rowData[0].sheetsReturnedBinsData.length;
    
                for(let k=0; k<longitudGamelas; k++){  
                
                    if(items[5].ExcelJson[k].DataJson.length > 0) {
                        titulo= items[5].ExcelJson[k].ExcelTabName.replaceAll("/","-");;
                        var worksheetReturnedBins = workbook.addWorksheet(titulo);
                         
                
                        let y = 0;
                        let letra = 12;
                        var columnsDefsYardWeighing = [];
                        var keyColumnsYardWeighing = [];
                        bandera = true;
                    
                        keyColumnsYardWeighing = Object.keys(rowData[0].sheetsReturnedBinsData[k].sheetReturnedBins[0]);
                        
                        columnsDefsYardWeighing = [
                            '#',
                            this.$t('label.Gamela'),
                            this.$t('label.plate'),
                            this.$t('label.Taraweight'),
                            this.$t('label.gross'),
                            this.$t('label.netWeight'),
                            this.$t('label.clientYard'),
                            this.$t('label.order'),
                            this.$t('label.dispacher'),
                            this.$t('label.date'),
                            this.$t('label.hour'),
                            this.$t('label.device'),
                            this.$t('label.user'),
                            this.$t('label.registerDate'),
                            this.$t('label.observation'), 
                            this.$t('label.returnType'), 
                        ];  
    
                        bandera = false;
                        y = columnsDefsYardWeighing.length;
                        if (y < 10) letra = 8;
                        worksheetReturnedBins.mergeCells('A1:B2');
                        worksheetReturnedBins.getCell("A1").border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                
                        worksheetReturnedBins.addImage(imageId, 'A1:B2');
                
                        let rowIndex1 = worksheetReturnedBins.getRow(1);
                        rowIndex1.height = 100;
                
                        worksheetReturnedBins.mergeCells("C1:" + numeroLetra(y) +"1");
                        const customCellCompanyBranch = worksheetReturnedBins.getCell("C1");
    
                        let row = worksheetReturnedBins.getRow(1);
                        row.height = 40;
                        customCellCompanyBranch.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCellCompanyBranch.note = {
                            margins: {
                                insetmode: 'auto',
                                inset: [10, 10, 5, 5]
                            }
                        }
                        customCellCompanyBranch.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'f2f2f2'
                            }
                        }
                        customCellCompanyBranch.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
                        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    
                        let row3 = worksheetReturnedBins.getRow(2);   
                        
                        row3.height = 30;
                        worksheetReturnedBins.mergeCells("C2:" + numeroLetra(y) +"2");
                        const customCellTitulo = worksheetReturnedBins.getCell("C2");  
                        customCellTitulo.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };      
                
                        customCellTitulo.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'f2f2f2'
                            },
                        }
                
                        customCellTitulo.border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                            
                        customCellTitulo.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                        customCellTitulo.value = nameVessel;
    
                        /**************titulo hoja inicio***************/
                        worksheetReturnedBins.mergeCells("A3:"+numeroLetra(y)+"3");
                        var customCell6 = worksheetReturnedBins.getCell("A3");
                        customCell6.height = 30;
                        customCell6.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCell6.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                            bottom: { style: 'thin' },
                        }
                        customCell6.alignment = { vertical: 'middle', horizontal: 'center' }
                        customCell6.value = this.$t('label.weighingReturnedBin')+': '+items[5].ExcelJson[k].ClientName;;
                        /**************titulo hoja fin***************/
    
                        /**************filtros inicio***************/
                        //printed
                        worksheetReturnedBins.mergeCells("A4:G4");
                        let customCellDate = worksheetReturnedBins.getCell("A4");
                        customCellDate.height = 50;
                        customCellDate.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCellDate.border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        customCellDate.alignment = { vertical: 'middle', horizontal: 'center' }
                        customCellDate.value = this.$t('label.from')+': '+dateStart+' - '+this.$t('label.to')+': '+dateEnd; 
                        //printed
                        worksheetReturnedBins.mergeCells("H4:K4");
                        let customCell2 = worksheetReturnedBins.getCell("H4");
                        customCell2.height = 50;
                        customCell2.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCell2.border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        customCell2.alignment = { vertical: 'middle', horizontal: 'center' }
                        customCell2.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); 
    
                        //user
                        worksheetReturnedBins.mergeCells("L4:P4");
                        let customCell4 = worksheetReturnedBins.getCell("L4");
                        customCell4.height = 50;
                        customCell4.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCell4.border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        customCell4.alignment = { vertical: 'middle', horizontal: 'center' }
                        customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                        /**************filtros fin*************************/
                
                        /**************fila divisoria inicio***************/
                        worksheetReturnedBins.mergeCells("A5:"+numeroLetra(y)+"5");
                        let  customCell5 = worksheetReturnedBins.getCell("A5");
                        customCell5.height = 50;
                        customCell5.alignment = { vertical: 'middle', horizontal: 'center' }
                        /**************fila divisoria fin*****************/
                
                        var headerRow = worksheetReturnedBins.addRow();
                        headerRow.font = {
                            name: "Calibri",
                            family: 4,
                            size: 11,
                            underline: false,
                            bold: true
                        };
                        
                        let i = 0;
                        columnsDefsYardWeighing.map(function (data) {
                        let valor = bandera ? data.headerName.length : data.length;
                        let long = 10;
                        if ((valor >= 5) && (valor <= 12)) {
                            long = 20;
                        } else if ((valor >= 11) && (valor <= 18)) {
                            long = 25;
                        } else if ((valor >= 19) && (valor <= 26)) {
                            long = 30;
                        } else if (valor >= 27) {
                            long = 40;
                        }
                
                        worksheetReturnedBins.getColumn(i + 1).width = long;
                        let cell = headerRow.getCell(i + 1);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'cccccc'
                            }
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        cell.value = data.replace("_"," ");
                        cell.alignment = { vertical: 'middle', horizontal: 'center' }
                        i++;
                        })
                        worksheetReturnedBins.autoFilter = {
                        from: 'A6',
                        to: numeroLetra(y),
                        } 
                
                        let indicador =k;
                        let dispacher = 0;
    
                        rowData[0].sheetsReturnedBinsData[indicador].sheetReturnedBins.map(async function (data) {
    
                        let dataRow = worksheetReturnedBins.addRow();
                        let indice = 0;
                        bandera=false;
                        
                        keyColumnsYardWeighing.map(function (data2) {      
                            let  cell = dataRow.getCell(indice + 1);
                            cell.value = data[data2];
                            if (indice!=0 && !isNaN(data[data2])) {
                                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                            }   
                            cell.border = {
                                top: { style: 'thin' },
                                bottom: { style: 'thin' },
                                left: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: {
                                    argb: ''+rowData[0].sheetsReturnedBinsDataYards[k].sheetReturnedBinsDispacher[dispacher].DISPACHER_COLOR+'',
                                }
                            }
                            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center'  };    
                
                            indice++;
                        })
                        dispacher++;
                        })
                        
                        var mergeYardWeighingDataStart = 7;
                        var mergeYardWeighingDataEnd = 7;
                        for(let i= 0; i<rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher.length; i++ ){
    
                            if(rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher[i].QUANTITY_ROWS > 1){
    
                                mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+
                                rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].quantityItemsPerDispacher[i].QUANTITY_ROWS-1;
    
                                worksheetReturnedBins.mergeCells('J'+mergeYardWeighingDataStart+':J'+mergeYardWeighingDataEnd);
                                worksheetReturnedBins.getCell('J'+mergeYardWeighingDataStart).font = { bold: true };     
    
                                mergeYardWeighingDataStart = mergeYardWeighingDataEnd+1;
                                mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+1;
    
                            }else{
                                worksheetReturnedBins.getCell('J'+mergeYardWeighingDataStart).font = { bold: true };
                                mergeYardWeighingDataEnd = mergeYardWeighingDataEnd+1;
                                mergeYardWeighingDataStart = mergeYardWeighingDataStart+1; 
                            }
                        }
    
                         /******************************totales inicio***********************************************/
                         worksheetReturnedBins.getColumn(4).width = 27;
                         /***************** inicio peso tara kg*******************/
                         var mergeYardWeighingDataEnd2 = mergeYardWeighingDataEnd;
                         mergeYardWeighingDataEnd2++;
     
                         worksheetReturnedBins.mergeCells("A"+mergeYardWeighingDataEnd+":B"+mergeYardWeighingDataEnd);
                         var customCellTotalBins = worksheetReturnedBins.getCell("A"+mergeYardWeighingDataEnd);
                         customCellTotalBins.height = 50;
                         customCellTotalBins.font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         customCellTotalBins.border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         customCellTotalBins.fill = {
                             type: 'pattern',
                             pattern: 'solid',
                             fgColor: {
                                 argb: 'cccccc'
                             }
                         }
                         customCellTotalBins.alignment = { vertical: 'middle', horizontal: 'center' }
                         customCellTotalBins.value = this.$t('label.tareTotal')+' (KG)';
                         
                         worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd).font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd).border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                         worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_TARE;
                         worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';
    
                         /***************** fin peso tara kg*******************/
    
                        /***************** inicio peso tara TONS*******************/
                        worksheetReturnedBins.mergeCells("A"+mergeYardWeighingDataEnd2+":B"+mergeYardWeighingDataEnd2);
                        var customCellTotalBins = worksheetReturnedBins.getCell("A"+mergeYardWeighingDataEnd2);
    
                        customCellTotalBins.height = 50;
                        customCellTotalBins.font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        customCellTotalBins.border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        customCellTotalBins.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'cccccc'
                            }
                        }
                        customCellTotalBins.alignment = { vertical: 'middle', horizontal: 'center' }
                        customCellTotalBins.value = this.$t('label.tareTotal')+' (TON)';
                        
                        worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd2).font = {
                            name: "Calibri",
                            family: 4,
                            size: letra,
                            underline: false,
                            bold: true
                        };
                        worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd2).border = {
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        }
                        worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                        worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_TARE_TON;
                        worksheetReturnedBins.getCell("C"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';
                        
                        /***************** fin total peso tara tons*******************/
     
                         /***************** inicio total peso bruto*******************/
                         var customCellTotalTara = worksheetReturnedBins.getCell("D"+mergeYardWeighingDataEnd);
                         customCellTotalTara.height = 50;
                         customCellTotalTara.font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         customCellTotalTara.fill = {
                             type: 'pattern',
                             pattern: 'solid',
                             fgColor: {
                                 argb: 'cccccc'
                             }
                         }
                         customCellTotalTara.border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         customCellTotalTara.alignment = { vertical: 'middle', horizontal: 'center' }
                         customCellTotalTara.value = this.$t('label.grossTotal')+' (KG)';
     
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd).font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd).border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                        worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                        worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_GROSS;
                        worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';
    
                         /***************** fin peso bruto kg*******************/
     
                         /***************** inicio peso bruto Tons*******************/
                         var customCellTotalTaraTons = worksheetReturnedBins.getCell("D"+mergeYardWeighingDataEnd2);
                         customCellTotalTaraTons.height = 50;
                         customCellTotalTaraTons.font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         customCellTotalTaraTons.fill = {
                             type: 'pattern',
                             pattern: 'solid',
                             fgColor: {
                                 argb: 'cccccc'
                             }
                         }
                         customCellTotalTaraTons.border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         customCellTotalTaraTons.alignment = { vertical: 'middle', horizontal: 'center' }
                         customCellTotalTaraTons.value = this.$t('label.grossTotal')+' (TON)';
     
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd2).font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd2).border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_FULL_TON;
                         worksheetReturnedBins.getCell("E"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';
                         
                         /***************** fin peso bruto tons*******************/
     
                         /***************** inicio total peso neto *******************/
                         var customCellTotalGross = worksheetReturnedBins.getCell("F"+mergeYardWeighingDataEnd);
                         customCellTotalGross.height = 50;
                         customCellTotalGross.font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         customCellTotalGross.fill = {
                             type: 'pattern',
                             pattern: 'solid',
                             fgColor: {
                                 argb: 'cccccc'
                             }
                         }
                         customCellTotalGross.border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         customCellTotalGross.alignment = { vertical: 'middle', horizontal: 'center' }
                         customCellTotalGross.value = this.$t('label.netoTotal')+' (KG)';
     
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd).font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd).border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd).alignment = { vertical: 'middle', horizontal: 'center' }
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_NETO;
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd).numFmt = '#,##0.00;[Red]\-#,##0.00';
     
                         /***************** fin total peso NETO **************************/
                         /***************** inicio total peso NETO tons*******************/
     
                         var customCellTotalGrossTons = worksheetReturnedBins.getCell("F"+mergeYardWeighingDataEnd2);
                         customCellTotalGrossTons.height = 50;
                         customCellTotalGrossTons.font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         customCellTotalGrossTons.fill = {
                             type: 'pattern',
                             pattern: 'solid',
                             fgColor: {
                                 argb: 'cccccc'
                             }
                         }
                         customCellTotalGrossTons.border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         customCellTotalGrossTons.alignment = { vertical: 'middle', horizontal: 'center' }
                         customCellTotalGrossTons.value = this.$t('label.netoTotal')+' (TON)';
     
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd2).font = {
                             name: "Calibri",
                             family: 4,
                             size: letra,
                             underline: false,
                             bold: true
                         };
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd2).border = {
                             bottom: { style: 'thin' },
                             left: { style: 'thin' },
                             right: { style: 'thin' },
                         }
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd2).alignment = { vertical: 'middle', horizontal: 'center' }
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd2).value = rowData[0].sheetsReturnedBinsQuantityItemsPerDispacher[indicador].TOTAL_NETO_TON;
                         worksheetReturnedBins.getCell("G"+mergeYardWeighingDataEnd2).numFmt = '#,##0.00;[Red]\-#,##0.00';
                        
                         /***************** fin total peso bruto tons*******************/
                        
                         /**************totales fin*************************/
    
                    }
                }
            }
        }
        /****************************************************************************
        ************************HOJAS DINAMICAS GAMELAS DEVUELTAS END****************
        *****************************************************************************/

        let fileName = documento;
        let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
        today = today.replaceAll("/", "");
        let time = new Date();
        time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
        //return false;
        workbook.xlsx.writeBuffer()
        .then(buffer => {
            saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
            this.$notify({
                group: 'container',
                text: this.$t('label.reportSuccessfully'),
                type: "success"
            });
        }) 

    }else{
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailble'),
            type: 'error',
        });
        
    }
    this.$store.state.visitas.apiStateForm = ENUM.INIT;
}

    // funcion para generar colores aleatorios
    function getFontColor() {
        var position = '';
        const minPosition = 0;
        const maxPosition = 9;
        position = Math.floor((Math.random() * (maxPosition+1 -minPosition)) +minPosition);
        
        return this.colors[position];
    }

export default {
  data,
  methods: {
    getWeighingGeneralReport,
    numeroLetra,
    getFontColor,
  },
  computed:{
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,       
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
        bulkCarrier: state => state.visitas.bulkCarrier,
        apiState: state => state.visitas.apiState,
        apiStateForm: state => state.visitas.apiStateForm,
    }),
  },
}