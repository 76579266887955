<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>

    <CCol sm="12">
      <CRow class="mb-3">
        <CCol sm="7" class="d-flex align-items-center">
          <CButton
              color="edit"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="closeCollapse"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton> 
            <h6 class="mt-2"> {{ $t('label.orderListPerIncidenceType')+': ' }} </h6>&nbsp;
            <h6 class="mt-2" style="font-weight: bold;" > {{ incidence.ResponsibleName+' - '+incidenceName }} </h6>
            
        </CCol>  
        <CCol sm="5" class="d-flex align-items-center justify-content-end ">
          <h6 class="text-warning mr-3 mt-2">
              <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.incidenceOpen')}}&nbsp;
          </h6> 
          <CButton 
            color="danger"
            class="mr-1"
            size="sm" 
            @click="onBtnPdf()"
            >
            <CIcon name="cil-file-pdf"/>&nbsp; PDF 
          </CButton>
          <CButton
              color="excel"
              class="mr-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
        </CCol>
      </CRow>
      <div>
        <dataTableExtended
          class="align-center-row-datatable"
          :fields="fields"
          :items="itemsIncidence"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"
          hover
          sorter
        >
          <template #loading>
            <loading/>
          </template>
          <template #alertGif="{ item }">
            <td class="text-center">
              <div class="animation-item ml-2" style="display: inline-block;" v-if="item.FgOpenIncidence">
                  <h3 class="text-danger">
                      <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                  </h3>
              </div>
            </td>
          </template>
          <template #Nro="{ item }">
            <td class="text-center">
              {{ item.Nro }}
            </td>
          </template>
          <template #OrderStatusDs="{item}">
            <td class="text-center">
              <div>
                <CBadge     
                  :style="item.ColorStatus"
                >
                  {{ item.OrderStatusDs }}
                </CBadge> 
              </div>
            </td>
          </template>
        </dataTableExtended>
      </div>
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import collapseIncidenceType from './index-incidence-type';

function data() {
  return {
    itemsIncidence: [],
    loadingOverlay: false,
    loadingTable: false,
    ShowmodalCircuit: false,
    refrescarTabla: false,
    VisitId: '',
    incidenceName: '',
  };
}

//methods
async function setData (item) {
  
  this.itemsIncidence = [];
  let incidence = item.OrderJson;

  this.loadingOverlay = true;
  let _lang = this.$i18n.locale;
  this.incidenceName = _lang == 'en' ? item.IncidenceNameEn : item.IncidenceNameEs;

  for(let i = 0; i < item.OrderJson.length; i++){
    this.itemsIncidence.push({
      Nro: incidence[i].IdX,
      OrderCode: incidence[i].OrderCode ? incidence[i].OrderCode : '',
      OrderDate: incidence[i].OrderDate ? DateFormater.formatDateTimeWithSlash(incidence[i].OrderDate) : '',
      DateFrom: incidence[i].DateFrom ? DateFormater.formatDateTimeWithSlash(incidence[i].DateFrom) : '',
      DateTo: incidence[i].DateTo ? DateFormater.formatDateTimeWithSlash(incidence[i].DateTo) : '',
      OrderStatusDs: _lang == 'en' ? incidence[i].OrderStatusDsEn : incidence[i].OrderStatusDsEs,
      YardClientName: incidence[i].YardClientName ? incidence[i].YardClientName : '',
      LicensePlate: incidence[i].LicensePlate ? incidence[i].LicensePlate : '',
      ImplementAlias: incidence[i].ImplementAlias ? incidence[i].ImplementAlias : '',
      YardNroBl: incidence[i].YardNroBl ? incidence[i].YardNroBl : '',
      YardName: incidence[i].YardName ? incidence[i].YardName : 'N/A',
      TotalHrsDs: incidence[i].TotalHrsDs ? incidence[i].TotalHrsDs : '',
      DelayHrsDs: incidence[i].DelayHrsDs ? incidence[i].DelayHrsDs : '',
      NetHrsDs: incidence[i].NetHrsDs ? incidence[i].NetHrsDs : '',
      ColorStatus: incidence[i].ColorStatus ? 'color:'+incidence[i].ColorStatus : '',
      FgOpenIncidence: incidence[i].FgOpenIncidence ? true : false,

      _classes: incidence[i].OrderStatusDs ? 'table-danger': ''
    });
      
  }
    this.loadingOverlay = false;
}

function closeCollapse(){
  this.incidenceName = '';
  this.itemsIncidence = [];
  this.$emit('closeCollapseIncidence', false);
  this.$emit('child-refresh', true);
}

async function onBtnPdf(){
  this.loadingOverlay = true;
  let rowData = [];
  
  if(this.nulo!="null" && this.itemsIncidence.length !== 0) {  
    await this.itemsIncidence.map(async(item,index) => {
      let objeto = {};
      objeto['#'] = index+1;
      objeto[this.$t('label.noOrder')] = item.OrderCode;
      objeto[this.$t('label.orderDate')] = item.OrderDate;
      objeto[this.$t('label.from')] = item.DateFrom;
      objeto[this.$t('label.to')] = item.DateTo;
      objeto[this.$t('label.status')] = item.OrderStatusDs;
      objeto[this.$t('label.clientYard')] = item.YardClientName;
      objeto[this.$t('label.vehiclePlate')] = item.LicensePlate;
      objeto[this.$t('label.Gamela')] = item.ImplementAlias;
      objeto[this.$t('label.bl')] = item.YardNroBl;
      objeto[this.$t('label.yard')] = item.YardName;
      objeto[this.$t('label.time')] = item.TotalHrsDs;
      objeto[this.$t('label.lost')] = item.DelayHrsDs;
      objeto[this.$t('label.net')] = item.NetHrsDs;
      rowData.push(objeto);
    });

    await this.getPdfArray(rowData,this.$t('label.orderListPerIncidenceType')+' '+this.incidence.ResponsibleName+' - '+this.incidenceName);
  }else{
      this.$notify({  
          group: 'container',
          title: '¡Error!',
          text: this.$t('label.noRecordsAvailbleReport'),
          type: "error" 
      });
  }
  this.loadingOverlay = false;
}

async function onBtnExport(valor) {
  this.loadingOverlay = true;
  let rowData = [];
  if(this.nulo!="null" && this.itemsIncidence.length !== 0) {  
    await this.itemsIncidence.map(async(item,index) => {
      let objeto = {};
      objeto['#'] = index+1;
      objeto[this.$t('label.noOrder')] = item.OrderCode;
      objeto[this.$t('label.orderDate')] = item.OrderDate;
      objeto[this.$t('label.from')] = item.DateFrom;
      objeto[this.$t('label.to')] = item.DateTo;
      objeto[this.$t('label.status')] = item.OrderStatusDs;
      objeto[this.$t('label.clientYard')] = item.YardClientName;
      objeto[this.$t('label.vehiclePlate')] = item.LicensePlate;
      objeto[this.$t('label.Gamela')] = item.ImplementAlias;
      objeto[this.$t('label.bl')] = item.YardNroBl;
      objeto[this.$t('label.yard')] = item.YardName;
      objeto[this.$t('label.time')] = item.TotalHrsDs;
      objeto[this.$t('label.lost')] = item.DelayHrsDs;
      objeto[this.$t('label.net')] = item.NetHrsDs;
      rowData.push(objeto);
    });

    await this.getExcelArray(rowData, this.$t('label.orderListPerIncidenceType')+' '+this.incidence.ResponsibleName+' - '+this.incidenceName, valor);
  }else{
      this.$notify({  
          group: 'container',
          title: '¡Error!',
          text: this.$t('label.noRecordsAvailbleReport'),
          type: "error" 
      });
  }
  this.loadingOverlay = false; 
}

//computeds
function fields(){
  return [
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:2%; text-align:center'  },
    { key: 'OrderCode', label:this.$t('label.noOrder'), _style: 'width:11%;', _classes: 'text-uppercase'},
    { key: 'OrderDate', label:this.$t('label.orderDate'),_style: 'width:8%;', _classes: 'text-uppercase' },
    { key: 'DateFrom', label:this.$t('label.from'),_style: 'width:8%;', _classes: 'text-uppercase' },
    { key: 'DateTo', label:this.$t('label.to'),_style: 'width:8%;', _classes: 'text-uppercase' },
    { key: 'OrderStatusDs', label:this.$t('label.status'), _style: 'width:7%;', _classes: 'text-uppercase'},
    { key: 'YardClientName', label:this.$t('label.clientYard'),_style: 'width:9%;', _classes: 'text-uppercase' },
    { key: 'LicensePlate', label:this.$t('label.vehiclePlate'),_style: 'width:7%;', _classes: 'text-uppercase' },
    { key: 'ImplementAlias', label:this.$t('label.Gamela'),_style: 'width:7%;', _classes: 'text-uppercase' },
    { key: 'YardNroBl', label:this.$t('label.bl'), _style: 'width:5%;', _classes: 'text-uppercase'},
    { key: 'YardName', label:this.$t('label.yard'),_style: 'width:7%;', _classes: 'text-uppercase' },
    { key: 'TotalHrsDs', label:this.$t('label.time'),_style: 'width:7%;', _classes: 'text-uppercase' },
    { key: 'DelayHrsDs', label:this.$t('label.lost'),_style: 'width:7%;', _classes: 'text-uppercase' },
    { key: 'NetHrsDs', label:this.$t('label.net'),_style: 'width:7%;', _classes: 'text-uppercase' },
  ];
}

export default {
  name: 'index-incidence-type',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    collapseIncidenceType,
  },
  methods: {
    closeCollapse,
    setData,
    onBtnPdf,
    onBtnExport,
  }, 
  props: {
    incidence: null,
  }, 
  watch: {
    incidence: function(newVal) {
      if(newVal){
        this.setData(newVal);
      }
    },
  },
  computed: {
    fields,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemOrder: state => state.visitas.dropItemOrder,
    })
  },
};
</script>
<style lang="scss">
    .animation-item{
        -webkit-animation: pulse 2s linear infinite;
        animation: pulse 2s linear infinite;
    }
    /**
    * ----------------------------------------
    * animation pulse
    * ----------------------------------------
    */
    @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
    @keyframes pulse{
        0%,
        100% {
            transform:scale(1.1)
        }
        50% {
            transform:scale(.8)
        }
    }
</style>
