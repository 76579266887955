<template>
<CModalExtended
  :title="$t('label.nuevo')+$t('label.vesselType')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-buque"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow class="justify-content-center mt-1 mb-4">
              <CCol sm="4">
                <div class="preview">    
                  <picture-input
                    v-if="modalActive"
                    ref="imageInput"
                    width="200"
                    height="200"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                    :imgSrc="imageRoute"
                  >
                  </picture-input>
                  <div v-if="$v.tipoBuque.Route.$error">
                    <div class=" text-invalid-feedback mx-3" v-if="!$v.tipoBuque.Route.isValidFile">
                      {{$t('label.badImage')}}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoBuque.TpVesselName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.tipoBuque.TpVesselDs)"
                      :is-valid="hasError($v.tipoBuque.TpVesselName)"
                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                      size="sm"
                      :label="$t('label.name')"
                      :placeholder="$t('label.vesselTypeName')"
                      addLabelClasses="required text-right"
                      maxlength="100"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea
                      v-model.trim="$v.tipoBuque.TpVesselDs.$model"
                      :invalid-feedback="errorMessage($v.tipoBuque.TpVesselDs)"
                      :is-valid="hasError($v.tipoBuque.TpVesselDs)"
                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                      size="sm"
                      :label="$t('label.description')"
                      :placeholder="$t('label.vesselTypeDescription')"
                      addLabelClasses="required text-right"
                      maxlength="500"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <!-- :disabled="isSubmitValid" -->
    <CButton
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="submitFile"
    >
      <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
<!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      -->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TipoBuqueValidations from '@/_validations/tipo-buque/TipoBuqueFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import TipoBuqueMixin from '@/_mixins/tipo-buque';
import ModalMixin from '@/_mixins/modal';

import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    tipoBuque: {
      TpVesselName: '',
      TpVesselDs: '',
      Route: ''
    },
    image: '',
    imageRoute: '/img/uploadPhotoEs.svg',
  }
}

//Methods
function submitFile() {
  try {
  this.isSubmit = true;
  this.$v.tipoBuque.$touch();

  if (this.$v.tipoBuque.$error) {
    this.isSubmit = false;
    throw this.$t('label.errorsPleaseCheck');
  }
    let formData = new FormData();
    formData.append('file1', this.tipoBuque.Route);

    this.$http.file('TpVessel-insert', this.tipoBuque.Route)
    .then((response) => {
      this.tipoBuque.Route = response.data.data.files[0].path;
      this.submit();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function submit() {
  try {
    this.isSubmit = true;
    this.$v.tipoBuque.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpVesselJson = {
      TpVesselName: this.tipoBuque.TpVesselName,
      TpVesselDs: this.tipoBuque.TpVesselDs,
      Route: this.tipoBuque.Route
    };

    this.$http.post('TpVessel-insert', TpVesselJson, { root: 'TpVesselJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  
  this.modalActive = newVal;
}
function resetForm() {
  this.tipoBuque.TpVesselName = '';
  this.tipoBuque.TpVesselDs = '';
  this.tipoBuque.Route = '';
  this.image = '';
  this.$v.$reset();
}
function handleFileUpload(image) {
  this.tipoBuque.Route = this.$refs.imageInput.file;
  this.image = image
}

//Computeds:

export default {
  name: 'add-tipo-buque-modal',
  mixins: [
    TipoBuqueMixin,
    ModalMixin,
  ],
  props: {
    modal: Boolean
  },
  components: {
    PictureInput
  },
  data,
  validations: TipoBuqueValidations,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    handleFileUpload,
    submitFile
  },
  computed: {  },
  watch: {
    modal: async function () {
      if (this.modal) {
        let _lang = this.$i18n.locale;
        this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
      }
    },
   }
}
</script>

<style lang="scss" scoped>
.preview {
  height: 15em;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
.modal-content-buque {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
  }
}
</style>