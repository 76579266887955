
<template>
    <CRow class="mt-3">
        <CCol col="12" xl="12" >
            <CCol sm="12" >
                <CRow>
                    <CCol sm="4" >
                        <CSelect
                            :label="$t('label.group')"
                            :horizontal="{label:'col-sm-11 col-lg-3 col-xl-2', input:'col-sm-11 col-lg-9 col-xl-9'}"
                            :options="optionListCargo"
                            :disabled="loading"
                            :value.sync="$store.state.contenedores.filtroCargo"
                            @input="changeCargo($event.target.value)"
                        />
                    </CCol>
                    <CCol sm="4">
                        <CSelect
                            :label="$t('label.view')"
                           :horizontal="{label:'col-sm-11 col-lg-3 col-xl-2', input:'col-sm-11 col-lg-9 col-xl-9'}"
                            :options="optionListView"
                            :disabled="loading || $store.state.contenedores.filtroCargo =='' || $store.state.contenedores.filtroCargo =='null'"
                            :value.sync="$store.state.contenedores.filtroView"
                            @input="changeView($event.target.value)"

                        />
                    </CCol>
                    <CCol sm="4" class="d-flex align-items-center justify-content-end">
                        <CButton
                            color="add"
                            @click="activarModal()"
                             v-c-tooltip="{
                                content: $t('label.part'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}} 
                        </CButton>
                        <CButton
                            color="excel"
                            style="margin-left:5px;"
                            @click="generarXLS(true)"
                            v-c-tooltip="{
                                content: $t('label.exportToExcel'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XLSX
                        </CButton>
                    </CCol>
                </CRow>
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #TpCargoElementId="{ index }">
                        <td class="center-cell">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="center-cell">
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="center-cell">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                v-c-tooltip="{
                                    content: $t('label.edit')+' '+$t('label.part'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';

    function fields(){
        return [
            { key: 'TpCargoElementId',label: '#', filter:false, _style: 'width:4%; text-align:center', },
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'width:17%;' },
            { key: 'TpCargoViewName',label: this.$t('label.view'), _style:'width:17%;' },
            { key: 'TpCargoElementName',label: this.$t('label.part'), _style:'width:17%;' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:12%;', },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:12%; ', _classes: 'text-center'},
            { key: 'Status', label: this.$t('label.status'),  _style:'width:12%; text-align:center;',},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 50px; width:5%', 
                sorter: false, 
                filter: false
            },
        ];
    }

    //data
    function data() {
        return {
            activePage: 1,
        }
    }

    //methods
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.group')] = item.TpCargoName;
                objeto[this.$t('label.view')] = item.TpCargoViewName;
                objeto[this.$t('label.parts')] = item.TpCargoElementName;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'ElementosContenedores' : 'ContainerParts';

            await this.getExcelArray(itemsArray, this.$t('label.containerLists.elementsList'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }
    function activarModal(){
        this.$store.state.contenedores.modalElementos = true;
    }
    function updateModal(item){
        this.$store.state.contenedores.modalElementos = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoElementId);
    }
    function changeCargo(value){
        this.$store.state.contenedores.filtroCargo = value;
        this.$store.state.contenedores.filtroView = '';

        const idCargo = value;
        const idView = '';
        this.$store.dispatch('contenedores/getElementlist', { idCargo,idView } );
    }
    function changeView(value){
        this.$store.state.contenedores.filtroView = value;

        const idCargo = this.filtroCargo;
        const idView = value;
        this.$store.dispatch('contenedores/getElementlist', { idCargo,idView } );
    }

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataElementtable"];
    }

    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    function optionListCargo(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListView(){
        if(this.myDataView.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            if(this.filtroCargo === ''){
                this.myDataView.map(function(e){
                    if(e.FgActTpCargoView){
                        chart.push({
                            value: e.TpCargoViewId, 
                            label: e.TpCargoViewName,
                        })
                    }
                })
                return chart;
            }else{
                const idCargo = this.filtroCargo;
                this.myDataView.map(function(e){
                    if(e.FgActTpCargoView){
                        if(e.TpCargoId === idCargo){
                            chart.push({
                                value: e.TpCargoViewId, 
                                label: e.TpCargoViewName,
                            })
                        }
                    }
                })
                return chart;
            }
        }
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 4){
            const idCargo = this.filtroCargo;
            const idView = this.filtroView;
            this.$store.dispatch('contenedores/getElementlist',{ idCargo,idView });
        }
    }
    function modalElementos(newValue){
        if(newValue === false){
            const idCargo = this.filtroCargo;
            const idView = this.filtroView;
            this.$store.dispatch('contenedores/getElementlist', { idCargo,idView } );
        }
    }
    export default {
        name: 'index-elements',
        data,
        mixins:[
            GeneralMixin,
            Reportes
        ],
        props: ['loading'],    
        methods: {
            activarModal,
            updateModal,
            changeView,
            changeCargo,
            generarXLS
        },
        computed: {
            computedItems,
            formatedItems,
            optionListCargo,
            optionListView,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                myDataView: state => state.contenedores.myDataView,
                filtroView: state => state.contenedores.filtroView,
                filtroCargo: state => state.contenedores.filtroCargo,
                modalElementos: state=> state.contenedores.modalElementos,
            })
        },
        watch:{
            tabIndex,
            modalElementos,
        }
    }   
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>