<template>
  <div class="mt-3">
    <CRow class="justify-content-center m-0" >
      <CCol sm="12" lg="4" class="px-2">
        <CSelect
          size="sm"
          :label="$t('label.accesory')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right"
          :options="PackagingAccesoryOptions"
          v-model.trim="vForm.accesory.PackagingAccesoryId.$model"
          :value.sync="form.accesory.PackagingAccesoryId"
          :is-valid="hasError(vForm.accesory.PackagingAccesoryId)"
          :invalid-feedback="errorMessage(vForm.accesory.PackagingAccesoryId)"
        />
      </CCol>
      <CCol sm="12" lg="4" class="px-2">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.quantity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              size="sm"
              v-bind="QuantityMeasure" 
              :class="!vForm.accesory.Quantity.$dirty ? 'form-control' : (vForm.accesory.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.accesory.Quantity.$model"
              maxlength= "13"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.accesory.Quantity.$error&&!CleanInformation">
              {{ errorMessage(vForm.accesory.Quantity) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4" class="px-2">
        <CTextarea
          :label="$t('label.description')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          maxlength="250"
          size="sm"
          v-uppercase
          v-model.trim="vForm.accesory.Observation.$model"
          addLabelClasses="text-right"
          rows="2"
          :is-valid="hasError(vForm.accesory.Observation)"
          :invalid-feedback="errorMessage(vForm.accesory.Observation)"
        />
      </CCol>
      <CCol sm="12" class="px-2">
        <CCol class="col-form-label col-sm-12 col-lg-12 col-form-label-sm px-0 text-right" style="text-align: left;">
          <CButton
              color="add"
              size="sm"
              class="mr-1"
              v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
              :disabled="isSubmit"
              @click="submit()"
              >
              <CIcon name="checkAlt"/>
          </CButton>
          <CButton
              color="wipe"
              class="justify-content-end"
              size="sm"
              v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" 
              @click="clear()"
              >
              <CIcon name="cil-brush-alt" />
          </CButton>
        </CCol>
      </CCol>
    </CRow>
    <CCol sm="12" class="px-2">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedList"
        :fields="fields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #options="{ item }">
          <td class="text-center">
              <CButton
                  color="edit"
                  square
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.accesory'),
                    placement: 'top-start'
                    }"
                  @click="editAccesory(item)"
                >
                <CIcon name="pencil" />
              </CButton>
              <CButton
                class="btn btn-sm btn-wipe"
                v-c-tooltip="{
                  content: $t('label.delete')+$t('label.accesory'),
                  placement: 'top-start',
                }"
                  @click="deleteAccesory(item)"
              >
                <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";


function data() {
  return {
    isSubmit: false,
    CleanInformation: false,
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//-----------------------   Method    ------------------------
async function submit(){
  try {
    this.isSubmit = true
    this.vForm.accesory.$touch();

    if (this.vForm.accesory.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.form.accesory.Id == '' &&this.AccesoryJson.some(e => e.Status == 1 && e.PackagingAccesoryId == this.form.accesory.PackagingAccesoryId)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }
   
    if (this.form.accesory.Id == '') {
      let AccesoryData = this.AccesoryList.find((e) => e.PackagingAccesoryId == this.form.accesory.PackagingAccesoryId);
      await this.$emit('updateAccesory', {
        ...AccesoryData,
        IdX: this.AccesoryJson.length > 0 ? this.AccesoryJson[this.AccesoryJson.length - 1].IdX + 1 : 1,
        BlCargoMasterDetailSerialAccesoryId: '',
        FgChecked: true,
        Quantity: this.form.accesory.Quantity,
        Observation: this.form.accesory.Observation,
        Status: 1,
      })
    }else {
      await this.$emit('updateAccesory', {
        IdX: this.form.accesory.id,
        FgChecked: true,
        Quantity: this.form.accesory.Quantity,
        Observation: this.form.accesory.Observation,
        Status: 1,
      })
    }
    this.isSubmit = false;
    this.clear();
    } catch (error) {
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
}

async function editAccesory(item) {
  this.form.accesory = {
    Id: item.IdX,
    PackagingAccesoryId: item.PackagingAccesoryId,
    Quantity: item.Quantity,
    Observation: item.Observation,
  }
  this.vForm.accesory.$touch();
}

function deleteAccesory(item) {
  this.$emit('deleteAccesory', item);
}

function clear() {
  this.CleanInformation = true;
  this.form.accesory = {
    Id: '',
    PackagingAccesoryId: '',
    Quantity: '',
    Observation: '',
  };
  setTimeout(() => {
    this.vForm.accesory.$reset();
    this.CleanInformation = false;
  }, 10);
}

//-----------------------   Computed   ------------------------
function PackagingAccesoryOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.AccesoryList.map((e) => {
    chart.push({
      value: e.PackagingAccesoryId, 
      label: e[`PackagingAccesoryName${_lang}`],
    })    
  })
  return chart;
}

function fields(){
  return [
    { key: 'options', label: '', _style: 'width:1%; min-width:90px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'width:1%; min-width: 45px;', _classes: 'text-center', filter: false },
    { key: 'PackagingAccesoryName', label: this.$t('label.accesory'),  _classes: 'text-uppercase text-center', _style: 'width: 30%;' },
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width: 30%;' },
    { key: 'Observation', label: this.$t('label.observation'), _style:'width: 30%;', _classes: 'text-uppercase text-center' },
  ]
}

function computedList() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.AccesoryJson.filter(item => item.Status == 1).map((e, index) => {
    return {
      ...e,
      Nro: index+1,
      PackagingAccesoryName: e[`PackagingAccesoryName${_lang}`],
    }
  });
}

export default {
  name: 'Accesory-tab',
  components: {
    Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: {
      type: Object,
      required: true,
      default: () => {},
    },
    AccesoryList: {
      type: Array,
      default: () => [],
    },
    AccesoryJson: Array,
    Clean: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    submit,
    editAccesory,
    deleteAccesory,
    clear,
  },
  computed:{
    PackagingAccesoryOptions,
    fields,
    computedList,
    ...mapState({
      BlCargoMasterId: state => state.yardManagement.BlCargoMasterId,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      loading: state => state.yardManagement.loading,
    })
  },
  watch: {
    Clean: function (Newval) {
      if (Newval) {
        this.clear();
      }
    },
  }
};
</script>