<template>
  <CRow class="mx-0 justify-content-center mt-3">
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        addLabelClasses="required text-right"
        :label="$t('label.activity')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :options="ActivityOptions"
        v-model="$v.BasicData.Activity.$model"
        :is-valid="hasError($v.BasicData.Activity)"
        :invalid-feedback="errorMessage($v.BasicData.Activity)"
        @change="(BasicData.Pol='', BasicData.Pod='', getPorts(false))"
      />
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        addLabelClasses="required text-right"
        :label="'POL'"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :options="PolOptions"
        :disabled="!BasicData.Activity"
        v-model="$v.BasicData.Pol.$model"
        :invalid-feedback="errorMessage($v.BasicData.Pol)"
        :is-valid="hasError($v.BasicData.Pol)"
        @change="$emit('UpdateBasicData', 'Pol')"
      />
      <CInput
        v-uppercase
        size="sm"
        placeholder="Nº BOOKING"
        addLabelClasses="text-right"
        label="Nº BOOKING"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.BasicData.Booking.$model"
        :is-valid="hasError($v.BasicData.Booking)"
        :invalid-feedback="errorMessage($v.BasicData.Booking)"
      />
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        :addLabelClasses="this.BasicData.FgClassification ? 'text-right required' : 'text-right'"
        :label="$t('label.classification')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :options="ClassificationOptions"
        :disabled="!BasicData.Group"
        v-model="$v.BasicData.Classification.$model"
        :is-valid="hasError($v.BasicData.Classification)"
        :invalid-feedback="errorMessage($v.BasicData.Classification)"
        @change="$emit('UpdateBasicData', 'Classification')"
      />
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
        <div class="input-group col-sm-12 col-lg-8 input-group-sm">
          <money
            size="sm"
            v-bind="QuantityMeasure" 
            v-c-tooltip="{ placement: 'top', content: $t('label.SumOfWeightPerDetail') }"
            :class="!$v.BasicData.Weight.$dirty ? 'form-control' : ($v.BasicData.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            :value.async="Weight"
            addLabelClasses="required text-right"
            maxlength= "12"
            disabled
          >
          </money>
          <div class="invalid-feedback" v-if="$v.BasicData.Weight.$error">
            {{ errorMessage($v.BasicData.Weight) }}
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-4 col-form-label-sm mb-0">{{`${$t('label.temperature')}`}}</label>
        <div class="input-group col-sm-12 col-lg-8 input-group-sm">
          <money
            size="sm"
            v-bind="measure" 
            :class="!$v.BasicData.Temperature.$dirty ? 'form-control' : ($v.BasicData.Temperature.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            v-model="$v.BasicData.Temperature.$model"
            addLabelClasses="required text-right"
            maxlength= "12"
          >
          </money>
          <div class="invalid-feedback" v-if="$v.BasicData.Temperature.$error">
            {{ errorMessage($v.BasicData.Temperature) }}
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm mb-0">{{`${$t('label.quantity')}`}}</label>
        <div class="input-group col-sm-12 col-lg-8 input-group-sm">
          <money
            size="sm"
            v-bind="QuantityMeasure" 
            v-c-tooltip="{ placement: 'top', content: $t('label.SumOfQuantityPerDetail') }"
            :class="!$v.BasicData.Quantity.$dirty ? 'form-control' : ($v.BasicData.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            :value.async="Quantity"
            addLabelClasses="required text-right"
            maxlength= "12"
            disabled
          >
          </money>
          <div class="invalid-feedback" v-if="$v.BasicData.Quantity.$error">
            {{ errorMessage($v.BasicData.Quantity) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        v-uppercase
        size="sm"
        placeholder="BL"
        addLabelClasses="required text-right"
        label="BL"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.BasicData.Bl.$model"
        :is-valid="hasError($v.BasicData.Bl)"
        :invalid-feedback="errorMessage($v.BasicData.Bl)"
        @input="$emit('UpdateBasicData', 'Bl')"
      />
      <div class="form-group form-row">
        <label class="required text-right col-form-label-sm mb-0 col-sm-12 col-lg-4">{{'POD'}}</label>
        <div class="col-sm-12 col-lg-8">
          <CSelect
            v-uppercase
            size="sm"
            :disabled="!BasicData.Activity"
            :placeholder="$t('label.select')"
            :options="PodOptions"
            class="mb-0"
            v-model="$v.BasicData.Pod.$model"
            :is-valid="hasError($v.BasicData.Pod)"
            @change="$emit('UpdateBasicData', 'Pod')"
          />
          <div v-if="$v.BasicData.Pod.$error" class="text-invalid-feedback">
            {{errorMessage($v.BasicData.Pod)}}
          </div>
        </div>
      </div>
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        addLabelClasses="required text-right"
        :label="$t('label.group')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :options="GroupOptions"
        v-model="$v.BasicData.Group.$model"
        :is-valid="hasError($v.BasicData.Group)"
        :invalid-feedback="errorMessage($v.BasicData.Group)"
        @change="getClassificationAndCommodity"
      />
      <div class="form-group form-row">
        <label class="required text-right col-form-label-sm mb-0 col-sm-12 col-lg-4">{{$t('label.commodity')}}</label>
        <div class="col-sm-12 col-lg-8">
          <v-select
            id="v-select-small"
            :disabled="!BasicData.Group"
            :placeholder="$t('label.select')"
            :options="CommodityOptions"
            :reduce="option => option.value" 
            v-model="$v.BasicData.Commodity.$model"
            :class="$v.BasicData.Commodity.$dirty ? ($v.BasicData.Commodity.$error ? 'select-client--error' : 'select-client--correct') : ''"
            style="height: 30px"
          />
          <div v-if="$v.BasicData.Commodity.$error" class="text-invalid-feedback">
            {{errorMessage($v.BasicData.Commodity)}}
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-4 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
        <div class="input-group col-sm-12 col-lg-8 input-group-sm">
          <money
            size="sm"
            v-bind="measure" 
            v-c-tooltip="{ placement: 'top', content: $t('label.SumOfVolumePerDetail') }"
            :class="!$v.BasicData.Volumen.$dirty ? 'form-control' : ($v.BasicData.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            :value.async="Volumen"
            addLabelClasses="required text-right"
            maxlength= "12"
            disabled
          >
          </money>
          <div class="invalid-feedback" v-if="$v.BasicData.Volumen.$error">
            {{ errorMessage($v.BasicData.Volumen) }}
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="text-right col-sm-12 col-lg-4 col-form-label-sm mb-0">{{`${$t('label.viscosity')}`}}</label>
        <div class="input-group col-sm-12 col-lg-8 input-group-sm">
          <money
            size="sm"
            v-bind="measure" 
            :class="!$v.BasicData.Viscosity.$dirty ? 'form-control' : ($v.BasicData.Viscosity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
            v-model="$v.BasicData.Viscosity.$model"
            addLabelClasses="required text-right"
            maxlength= "12"
          >
          </money>
          <div class="invalid-feedback" v-if="$v.BasicData.Viscosity.$error">
            {{ errorMessage($v.BasicData.Viscosity) }}
          </div>
        </div>
      </div>
      <CSelect
        v-if="isEdit"
        size="sm"
        :options="statusOptions"
        :value="BasicData.Status"
        :label="$t('label.status')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        addLabelClasses="required text-right"
        :is-valid="statusSelectColor"
        @change="BasicData.Status=$event.target.value"
      />
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { VMoney, Money } from "v-money";
import { mapState } from "vuex";

function data() {
  return {
    ActivityList: [],
    PolList: [],
    PodList: [],
    GroupList: [],
    ClassificationList: [],
    CommodityList: [],
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

async function getService() {
  this.$emit('Loading', true)
  let requests = [
    this.$http.ejecutar("GET", "PortActivity-list-by-StowagePlanningId", { StowagePlanningId: this.StowagePlanningId }),
    this.$http.ejecutar("GET", "GeneralCargoConfig-list"),
  ];
  await Promise.all(requests)
     .then(async (responses) => {
      this.ActivityList = responses[0].data.data ?? [];
      let GeneralCargoList = responses[1].data.data&&responses[1].data.data[0] ? responses[1].data.data[0] : {};
      this.GroupList = GeneralCargoList.TpCargoJson;
      if (this.isEdit) {
        let GroupData = this.GroupList.find((val) => val.TpCargoId == this.BasicData.Group);
        this.BasicData.FgClassification = GroupData.FgClassification ?? false;
        this.ClassificationList = GroupData?.ClassificationJson ? GroupData?.ClassificationJson : [];
        await this.getCommodity(true);
        await this.getPorts(true);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false)
    });
}

async function getPorts(Loading) {
  this.$emit('UpdateBasicData', 'Activity');
  if (this.BasicData.Activity) {
    this.$emit('Loading', true)
    await this.$http.get("StowagePlanningPort-by-CompanyBranchId", {CompanyBranchId: this.CompanyBranchId, PortActivityId: this.BasicData.Activity})
      .then((response) => {
        let Service = response.data.data&&response.data.data[0] ? response.data.data[0] : {};
        this.PolList = Service?.VisitJson&&Service?.VisitJson[0] ? (Service?.VisitJson[0]?.LoadPortJson ?? []) : [];
        this.PodList = Service?.VisitJson&&Service?.VisitJson[0] ? (Service?.VisitJson[0]?.DischargePortJson ?? []) : [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).finally(() => {
        this.$emit('Loading', Loading)
      });
    }else{
      this.PolList = [];
      this.PodList = [];
      this.BasicData.Pol = '';
      this.BasicData.Pod = '';
    }
}

function getCommodity(Loading) {
  this.$emit('Loading', true);
  this.$http.get("Commodity-by-TpcargoId", { TpCargoId: this.BasicData.Group })
    .then((response) => {
      this.CommodityList = response?.data?.data ?? [];
      this.$emit('UpdateCommodityList', this.CommodityList);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).finally(() => {
      this.$emit('Loading', Loading);
    });
}

function getClassificationAndCommodity(event){
  this.$emit('UpdateBasicData', 'Group');
  this.BasicData.Group =  event?.target?.value ?? ''; 
  this.BasicData.Classification = '';
  this.ClassificationList = [];
  this.BasicData.HeadingId = '';
  this.BasicData.Commodity = '';
  this.CommodityList = [];
  this.$emit('UpdateCommodityList', this.CommodityList);
  if (this.BasicData.Group) {
    let GroupData = this.GroupList.find((val) => val.TpCargoId == this.BasicData.Group);
    this.BasicData.FgClassification = GroupData.FgClassification ?? false;
    this.ClassificationList = GroupData?.ClassificationJson ? GroupData?.ClassificationJson : [];
    this.getCommodity(false);
  }else{
    this.BasicData.FgClassification = false;
  }
}

function statusSelectColor() {
  return this.BasicData.Status == 1;
}

function Reset() {
  this.ActivityList = [];
  this.PolList = [];
  this.PodList = [];
  this.GroupList = [];
  this.ClassificationList = [];
  this.CommodityList = [];
  this.$emit('UpdateCommodityList', this.CommodityList);
}

//computed
function ActivityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ActivityList.map(function(e){
    chart.push({
      value: e.PortActivityId, 
      label: e['PortActivityName'+_lang],
    })    
  })
  return chart;
}

function PolOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PolList.map(function(e){
    chart.push({
      value: e.PortId, 
      label: `${e.PortName}, ${e.IsoCodeAlpha2}`,
    })    
  })
  return chart;
}

function PodOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PodList.map(function(e){
    chart.push({
      value: e.PortId, 
      label: `${e.PortName}, ${e.IsoCodeAlpha2}`,
    })    
  })
  return chart;
}

function GroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.GroupList.map(function(e){
    chart.push({
      value: e.TpCargoId, 
      label: e.TpCargoName
    })    
  })
  return chart;
}

function ClassificationOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ClassificationList.map(function(e){
    chart.push({
      value: e.TpCargoClassifId, 
      label: e['ClassificationName'+_lang],
    })    
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function Weight() {
  return this.BasicData?.Weight ?? 0;
}

function Quantity() {
  return this.BasicData?.Quantity ?? 0;
}

function Volumen() {
  return this.BasicData?.Volumen ?? 0;
}

function Commodity() {
  return this.BasicData.Commodity ?? '';
}

export default {
  name: 'basic-data-tab',
  components: {
    Money
  },
  data,
  props: {
    BasicData: {
      type: Object,
      required: true,
      default: () => {},
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations(this.BasicData.FgClassification, this.BasicData.HeadingId);
  },
  methods: {
    getService,
    getPorts,
    getCommodity,
    getClassificationAndCommodity,
    statusSelectColor,
    Reset,
  },
  computed:{
    ActivityOptions,
    PolOptions,
    PodOptions,
    GroupOptions,
    ClassificationOptions,
    CommodityOptions,
    Weight,
    Volumen,
    Quantity,
    Commodity,
    ...mapState({
      CompanyBranchId: (state) => state.auth.branch.CompanyBranchId,
      StowagePlanningId: (state) => state.planificacionestiba.planificacionId
    }),
  },
  watch: {
    showModal: async function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.BasicData.$touch();
      }else{
        await this.Reset();
        this.$v.$reset();
      }
    },
    Tab: async function (Newval) {
      if (Newval) {
        await this.getService();
      }
    },
    Commodity: function (NewVal) {
      if (this.showModal && this.Tab) {
        if (NewVal) {
          let CommodityData = this.CommodityList.find(item => item.CommodityId == NewVal);
          this.$v.BasicData.HeadingId.$model = CommodityData?.HeadingId ?? '';
        }else{
          this.$v.BasicData.HeadingId.$model = '';
        }
        this.$emit('UpdateBasicData', 'Commodity');
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.BasicData.$touch();
      }
    },
  },
};
</script>