import DataStorage from '@/_helpers/localStorage';

const apiUrl = DataStorage.getConnection();
const initialState = apiUrl
  ? { apiUrl: apiUrl }
  : { apiUrl: '' };

  // this.$store.getters["connection/getBase"]
  // this.$store.getters["connection/getApiBase"]

export const connection = {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    // http://dominio/
    getBase: state => {
      return state.apiUrl;
    },
    // http://dominio/apptest/
    getApiBase: state => {
      return `${state.apiUrl}apptest/`;
    },
    // http://dominio/apptest/services/
    getApiServices: state => {
      return `${state.apiUrl}apptest/services/`;
    },
    // http://dominio/apptest/files/
    getApiFiles: state => {
      return `${state.apiUrl}apptest/files/`;
    }
  },
  actions: {
    assign({ commit }, url) {
      DataStorage.setConnection(url);
      commit('apiUrlMutation', url);
    },
    delete({ commit }) {
      DataStorage.setConnection('');
      commit('apiUrlMutation', '');
    },
  },
  mutations: {
    apiUrlMutation(state, url) {
      state.apiUrl = url;
    }
  }
};
