<template>
  <CRow  class="mt-3">
    <CCol sm="12" class="d-flex justify-content-end align-items-center">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.WorkShiftPerVesselType'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="py-2 center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.WorkShiftPerVesselType'),
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>

    <modal-create-shift-vessel-type :modal.sync="modalAdd" @submited="handleSubmit"/>
    <modal-edit-shift-vessel-type :modal.sync="modalEdit" :shift-vessel-type-data="shiftVesselType" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalCreateShiftVesselType from './modal-create-shift-vessel-type';
import ModalEditShiftVesselType from './modal-edit-shift-vessel-type';


function data() {
  return {
    items: [],
    shiftVesselType: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false
  };
}

//Created
function created() {
  this.getWorkTurnByTypeOfVessel();
}

//Methods:
function getWorkTurnByTypeOfVessel() {
  this.isLoading = true;
  this.$http.get("TpVesselTurn-list?TpVesselId=")
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item) {
  this.shiftVesselType = Object.assign({}, item);

  this.modalEdit = true;
}

function handleSubmit(){
  this.getWorkTurnByTypeOfVessel();
}

//Computed:
function formatedItems() {
  let _this = this.$i18n.locale;

  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    TurnName: _this== 'en' ? item.TurnNameEn : item.TurnNameEs,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

function fields() { 
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,  _style: 'width:1%; text-align:center;',
    },
    { key: 'TpVesselName', label:this.$t('label.vesselType'), _style:'width:25%;',_classes:"text-uppercase" },
    { key: 'TurnName', label:this.$t('label.WorkShift'), _style:'width:20%;',_classes:"text-uppercase" },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;', _classes:"text-uppercase"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%; ', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes: "text-center"
    }
  ];
}

function tableText() {
 return tableTextTranslatedHelpers(this);
}

export default {
  name: 'index-shift-vessel-type',
  mixins: [GeneralMixin],
  components: { ModalCreateShiftVesselType, ModalEditShiftVesselType },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  methods: {
    getWorkTurnByTypeOfVessel,
    toggleAdd,
    toggleEdit,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields,
    tableText
  },
  watch: {
    active: function(newVal) {
      if(newVal){
        this.getWorkTurnByTypeOfVessel();
      }
    }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>