import { required, maxLength, email, minLength, helpers } from "vuelidate/lib/validators";
import {  onlyAlphanumeric, phoneTest, onlyNumber, especiales } from '@/_validations/validacionEspeciales';
import {  alphaNumSpecials2 } from '@/_validations/funciones.js';

const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value.toLowerCase());
const onlyNumbers = (value) => /^[0-9]+$/.test(value);
const notOnlyNumbers = (value) => !onlyNumbers(value);


export default () => {
    return {
        ClientRif: { required, onlyNumber, maxLength: maxLength(9), minLength: minLength(9) },
        ClientName: { required,alphaNumSpecials2, notOnlyNumbers, maxLength: maxLength(250) },
        ClientAddress: { required, alphaNumSpecials2, maxLength: maxLength(500) },
        PrimaryPhone: { required, mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) }, 
        PrimaryEmail: { required, emailToLower, maxLength: maxLength(100) },
        SecondaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) }, 
        SecondaryEmail: { emailToLower, maxLength: maxLength(100)  },
        Representative: { required, alphaNumSpecials2, notOnlyNumbers, maxLength: maxLength(250) },
        FgGovernment: { required },
        ClientAlias: { minLength: minLength(3), maxLength: maxLength(8) },
        FgActClient: { required },
        LetraRif: {required,},
        Color: {},
        FgGovernment: {required}
    }
}

