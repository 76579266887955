<template>
  <div>
    <CModalExtended
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
     
          <CRow class="justify-content-center mt-2">
            <CCol sm="12" class="mb-3">
              <div class="justify-content-center">
                <vue-dropzone 
                    ref="myVueDropzone" 
                    id="dropzone" 
                    :options="dropzoneOptions" 
                    :useCustomSlot="true"
                    v-on:vdropzone-error="DropzoneError"
                    class="col-lg-12"
                  > 
                    <div>
                      <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                      <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                    </div>
                  </vue-dropzone>
              </div>
            </CCol>
            <CCol sm="12" class="mb-3 row align-items-start justify-content-end">
              <CCol sm="12" lg="10">
                <CInput
                  :label="$t('label.description')"
                  horizontal
                  addLabelClasses="required text-right"
                  :placeholder="$t('label.documentDescription')"
                  v-uppercase
                  :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}" 
                  v-model="VForm.Documents.DocumentDs.$model"
                  :is-valid="hasError(VForm.Documents.DocumentDs)"
                  :invalid-feedback="errorMessage(VForm.Documents.DocumentDs)"
                  class="remove-space"
                >
                </CInput>
              </CCol>
              <CCol sm="12" lg="auto">
                <CButton 
                  color="add"
                  size="sm"
                  v-c-tooltip="{
                      content: $t('label.add'),
                      placement: 'top-end'
                  }"
                  :disabled="isSubmit"
                  @click="submitImage()" 
                >
                  <CIcon name="checkAlt"/>
                </CButton>
              </CCol>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                :items="formatedItems"
                :fields="fields"
                :items-per-page="5"
                :noItemsView="tableText.noItemsViewText"
                pagination
                column-filter
              >
                <template #Document="{item}">
                  <td class="center-cell text-uppercase">
                    <label class="col-sm-12 text-center px-0">{{item.DocumentDs}}</label>
                  </td>
                </template>
                <template #Options="{item}">
                  <td class="center-cell">
                    <CButton
                      class="btn btn-sm btn-watch mr-1"
                      color="excel"
                      v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
                      target="_blank"
                      :href="item.Document"
                    >
                      <CIcon name="cil-cloud-download" />
                    </CButton>
                    <CButton
                      color="wipe"
                      size="sm"
                      class="mr-1"
                      @click="DeleteImage(item)"
                      v-c-tooltip="{
                        content: $t('label.delete')+' '+$t('label.picture'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
      <template #footer>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ImagesListValidations  from '@/_validations/yard-management/container/ImagesListValidations';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import PictureInput from '@/components/picture-input';
import { DateFormater } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import moment from 'moment';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    showModal: false,
    Imagen: '',
    Route: '',
    RouteDocument: [],
    isSubmit: false,
    isDocumentOfService: false,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx, .jpg, .png',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${user.token}`
      }
    },
  };
}

function submitImage() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;

    let files = this.$refs.myVueDropzone.getQueuedFiles();
    this.VForm.Documents.DocumentDs.$touch();

    if (files.length === 0) {
      throw this.$t('label.documentQuestion');
    }
    
    if (this.VForm.Documents.DocumentDs.$error ) 
      throw this.$t('label.errorsPleaseCheck');

    this.$http.file('YardManagement-file', files)
    .then((response) => {

      this.$refs.myVueDropzone.removeAllFiles();
      response.data.data.files.map(item => {
        this.RouteDocument.push({ 
          DocumentRoute: item.path,
          DocumentDs: this.Form.Documents.DocumentDs,
          Date: moment(new Date).format('DD/MM/YYYY HH:mm'),
        });
      })
      this.isSubmit = false;
      this.Form.Documents.DocumentDs = '';
      this.$store.state.yardManagement.loading = false;
      this.VForm.Documents.$reset();

    }).catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }); 
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function DeleteImage(item) {
  this.$swal.fire(this.alertProperties({ text: `${this.$t('label.deleteFile')}`,})).then((result) => {
    
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;

      this.$http.delete('files', item.DocumentRoute.replace(/public+[/]/i, ''))
        .then(async(response) => {
          if (this.isEdit && item.YardCargoEventDetailDocumentId) {
            let UpdateImages = this.RouteDocument.filter(RouteDocument => RouteDocument.YardCargoEventDetailDocumentId  && RouteDocument.DocumentRoute != item.DocumentRoute);
            this.isDocumentOfService = true;
              
            this.ModuleForm.DocumentJson = this.ModuleForm.DocumentJson.map(Document => {
              return{
                YardCargoEventDetailDocumentId: Document.YardCargoEventDetailDocumentId ?? '',
                DocumentRoute: Document.DocumentRoute ?? '',
                DocumentDs: Document.DocumentDs,
                FgActYardCargoEventDetailDoc: ((Document.DocumentRoute == item.DocumentRoute) || Document.FgActYardCargoEventDetailDoc == 0) ? 0 : 1,
                Status: ((Document.DocumentRoute == item.DocumentRoute) || Document.Status == 0) ? 0 : 1,
              } 
            });

            this.$emit('UpdateSubmit', true);
            this.VForm.Documents.$reset();
          }
          
          this.RouteDocument = this.RouteDocument.filter(RouteDocument => RouteDocument.DocumentRoute != item.DocumentRoute);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          if(!this.isDocumentOfService)
            this.$store.state.yardManagement.loading = false;  
          else
            this.isDocumentOfService = false;
        });     
    }
  });
}

function closeModal() {
  this.$emit('UpdateImages', this.RouteDocument);
  this.refreshComponent();
  this.$emit('CloseModal');
}

function srcImage(icon){
  let extension = icon.split(".").pop();
  if(icon && (extension == 'jpg' || extension == 'png')){
    return icon;
  }else if (icon && ( extension == 'pdf' || extension == 'doc' || extension == 'docx' || extension == 'xlsx')){
    return '/img/documentImage.png';
  }else{
    return '/img/errorimage.jpg';
  }

}
function refreshComponent() {
  this.Imagen = '';
  this.RouteDocument = [];
  this.$v.$reset();
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

//computed
function formatedItems() {
  return this.RouteDocument.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      Document: item.DocumentRoute ? `${this.$store.getters['connection/getBase']}${item.DocumentRoute.replace(/public+[/]/i, '')}` : '',
      TransaLogin: item.TransaLogin ?? DataStorage.getUser().Login,
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : item.Date
    }
  })
}

function fields(){ 
  return [
    { key: 'Nro', label: '#',_style: 'width:5%; text-align:center;', filter: false},
    { key: 'Document', label: this.$t('label.document'), _style: 'width: 30%;', sorter: false,  filter: false, _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 20%;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 20%;', _classes:"text-center"},
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 90px',  sorter: false,  filter: false, _classes:'text-center' }
  ]
}


export default {
  name: 'modal-seal',
  components: {
    PictureInput,
    vueDropzone: vue2Dropzone,
  },
  data,
  props: {
    Form: {
        type: Object,
        required: true,
        default: () => {},
    },
    VForm: Object,
    modal: null,
    ImagesJson: Array,
    CargoJson: Object,
    DocumentType: Number,
    isEdit: Boolean,
    titleModal: String,
    ModuleForm: {
        type: Object,
        required: false,
        default: () => {},
    },
  },
  mixins: [GeneralMixin],
  directives: UpperCase,
  validations: ImagesListValidations,
  methods: {
    submitImage,
    DeleteImage,
    closeModal,
    srcImage,
    refreshComponent,
    DropzoneError,
  },
  computed: {
    formatedItems,
    fields,
  },
  watch: {
    modal: async function (val) {
      this.VForm.Documents.$reset();
      this.Form.Documents.DocumentDs = '';
      this.showModal = val;
      this.$refs.myVueDropzone.removeAllFiles();
      if (val) {
        let _lang = this.$i18n.locale;
        this.Route = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        this.RouteDocument = this.ImagesJson.length!=0 ? this.ImagesJson : [];
      }
    },
  }
};
</script>