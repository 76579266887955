<template>
    <div>
      <CCollapse :show="activeItem(1)">
          <Inventary/>
      </CCollapse>
      <CCollapse :show="activeItem(2)">
          <Recepcion/>
      </CCollapse>
      <CCollapse :show="activeItem(4)">
          <Position/>
      </CCollapse>
	  <CCollapse :show="activeItem(3)">
          <Dispatch/>
      </CCollapse>
      <CCollapse :show="activeItem(5)">
          <Desconsolidation/>
      </CCollapse>
      <!--
      <CCollapse :show="activeItem(4)">
          <DispatchExport/>
      </CCollapse>
      <CCollapse :show="activeItem(5)">
          <Embarked/>
      </CCollapse>
      <CCollapse :show="activeItem(6)">
          <Canceled/>
      </CCollapse>
      <CCollapse :show="activeItem(7)">
          <StateYard/>
      </CCollapse>
      <CCollapse :show="activeItem(8)">
          <Event/>
      </CCollapse>
      <CCollapse :show="activeItem(9)">
          <Inspection />
      </CCollapse>-->
    </div>
  </template>
  <script>
    import { mapState } from 'vuex';
    import Inventary from './inventary-list';
    import Recepcion from './recepcion-list';
    import Position from './position-list';
	import Dispatch from './dispatch-list';
    import Desconsolidation from './desconsolidation-list';
    
    //methods
    function activeItem(item) {
      return this.dropItemReport === item;
    }
  
    export default{
        name: 'reports-index',
        methods:{
              activeItem,
          },
          components:{
              Inventary,
              Recepcion,
              Position,
			  Dispatch,
              Desconsolidation
          },
          computed:{
              ...mapState({
                  dropItemReport: state => state.yardManagement.dropItemReportWarehouse,
              })
          },
          watch:{
              //tabIndex
          }
    }
  </script>