<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <loading-overlay
        :active="loadingPdf"
        :is-full-page="true"
        loader="bars"
      />
      <ManagementPdf
        :Color="tasks"
        :tasks="tasks"
        :NotProgrammed="NotProgrammed"
        :itinerarySelected="pageInfo.itinerarySelected"
        :BulkCarrier="pageInfo.BulkCarrier"
        :TotalPage="pageInfo.TotalPage"
        :VisitSummaryJson="VisitSummaryJson"
        :FgDate="pageInfo.FgDate"
        :ProdByDayLapseJson="ProdByDayLapseJson"
        :SizeJson="SizeJson"
        :TypeJson="TypeJson"
        :ShippingLineJson="ShippingLineJson"
        :MovsPlannedVsExecutedJson="MovsPlannedVsExecutedJson"
        :VisitCranesOpsJson="VisitCranesOpsJson"
      />
    </div>
  </transition>
</template>

<script>
import ManagementPdf from "./Management-pdf";
import debounce from "lodash/debounce";
import moment from 'moment';
//Data
function data() {
  return {
    pageInfo: {
      VisitId: '',
      itinerarySelected: [],
      BulkCarrier: 0,
      TotalPage: 0,
      FgDate: true,
    },
    Gantt: [],
    tasks: {},
    NotProgrammed: [],
    departure: [],

    VisitSummaryJson: {},
    ProdByDayLapseJson: {},
    SizeJson: [],
    TypeJson: [],
    ShippingLineJson: [],
    MovsPlannedVsExecutedJson: [],
    VisitCranesOpsJson: [],

    loadingPdf: false,
  };
}

//Created
async function created() {
  this.loadingPdf = true;
  let info = JSON.parse(localStorage.getItem("pageInfo"));
  if (!info) {
    window.close();
    return;
  }
  this.pageInfo = { ...this.pageInfo, ...info };
  await this.TimelineReport(this.pageInfo.VisitId, this.pageInfo.TotalPage, this.pageInfo.BulkCarrier);
  localStorage.removeItem("pageInfo");

  window.addEventListener("afterprint", function (event) {
    close();
  });
}

//Methods:
function TimelineReport(VisitId, page, BulkCarrier) {
  let Service = BulkCarrier==1?'VisitContainerShipFinalReportV2':'VisitMetalScrapFinalReportvV2';
  let peticiones =  
    [
      this.$http.ejecutar("GET", Service, {VisitId: VisitId}),
    ];
  Promise.all(peticiones)
    .then((responses) => {
      let list = responses[0].data.data;
      this.Gantt = list[0]?.TimeLineJson ? list[0].TimeLineJson : [];
      this.GanttList();
      this.VisitSummaryJson = list[0]?.VisitSummaryJson[0] ? list[0].VisitSummaryJson[0] : {};
      this.ProdByDayLapseJson = list[0]?.ProdByDayLapseJson[0] ? list[0].ProdByDayLapseJson[0] : {};
      this.ProdByDayLapseList(list[0]?.ProdByDayLapseJson[0]);
      this.SizeJson = list[0]?.SizeJson ? list[0].SizeJson : [];
      this.TypeJson = list[0]?.TypeJson ? list[0].TypeJson : [];
      this.ShippingLineJson = list[0]?.ShippingLineJson ? list[0].ShippingLineJson : [];
      this.MovsPlannedVsExecutedJson = list[0]?.MovsPlannedVsExecutedJson ? list[0].MovsPlannedVsExecutedJson : [];
      this.VisitCranesOpsJson = list[0]?.VisitCranesOpsJson ? list[0].VisitCranesOpsJson : [];
      if (this.NotProgrammed.length<=5) {
        this.pageInfo.TotalPage = page+1;
      }
      this.loadingPdf = false;
    }).catch((err) => {
      this.loadingPdf = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

//methods
function ProdByDayLapseList(val) {
  if (val && val.DetailJson.length>15) {
    this.ProdByDayLapseJson.index = [];
    let index = Math.ceil(val.DetailJson.length/15) == 0 ? 1 : Math.ceil(val.DetailJson.length/15);
    let length = 0;
    let DetailJson = [];
    for (let n = 0; n < index; n++) {
      for (let i = 0; i < 15 && val.DetailJson.length > length; i++){
        DetailJson.push(val.DetailJson[length]);
        if (i == 14 || (val.DetailJson.length-1) == length) {
          this.ProdByDayLapseJson.index.push({
            DetailJson: DetailJson,
          });
          DetailJson = [];
        };
        length++;
      };
    };
  }else if (val) {
    this.ProdByDayLapseJson.index = [];
    this.ProdByDayLapseJson.index.push({
      DetailJson: val.DetailJson,
    });
  }
}

function GanttList() {
  if (this.Gantt != []) {
    let gattlist = this.Gantt;
    this.NotProgrammed = [];
    let data = [];
    let links = [];
    let tasks = {data: [], links: []};
    let _lang = this.$i18n.locale;
    gattlist.map((item) =>{
      if (item.TransactionBegin) {
        let text = _lang=='en' ? item.textEn : item.textEs;
        data.push({
          id: item.targetid,
          text: text,
          start_date: item.TransactionBegin ? moment(item.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
          end_date: item.TransactionBegin&&item.TransactionFinish&&item.TransactionFinishAux?moment(item.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
            : item.TransactionBegin ? (item.FgDateRange ? moment(item.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
            : item.TransactionFinishAux ? moment(item.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') 
            : moment(item.TransactionBegin,'YYYY-MM-DD HH:mm').add(3, 'h').format('YYYY-MM-DD HH:mm')) : '',
          DateFinish: item.TransactionFinish ? moment(item.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
          progress:  item.ProgressAux ? item.ProgressAux : item.progress ? item.progress : 1,
          Completed: '-',
          Hrs: item.Hrs,
          HrsInactive: item.HrsInactive?item.HrsInactive : '00:00',
          HrsAcumulated: item.HrsAcumulated!='00:00'?item.HrsAcumulated:'00:00',
          color: '#e0e0e0',
          unscheduled: item.TransactionBegin ? false : true,
          progressColor: item.color,
          FgDateRange: item.FgDateRange ? false : true,
          row_height: 14,
        });
        if (item.ChildsJson && item.ChildsJson.length != 0) {
          item.ChildsJson.map((ChildsJson) =>{ 
            if (ChildsJson.TransactionBegin) {
              text = _lang=='en' ? ChildsJson.textEn : ChildsJson.textEs;
              data.push({
                id: ChildsJson.targetid,
                text: text,
                start_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                end_date: ChildsJson.TransactionBegin&&ChildsJson.TransactionFinish&&ChildsJson.TransactionFinishAux?moment(ChildsJson.TransactionFinishAux,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
                  : moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
                DateFinish: ChildsJson.TransactionFinish ? moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
                progress: ChildsJson.ProgressAux ? ChildsJson.ProgressAux : ChildsJson.Progress,
                Completed: ChildsJson.ProgressOperative*100+'%',
                Hrs: ChildsJson.Hrs,
                HrsInactive: ChildsJson.HrsInactive?ChildsJson.HrsInactive : '00:00',
                HrsAcumulated: ChildsJson.HrsAcumulated,
                color: '#e0e0e0',
                progressColor: ChildsJson.color, 
                unscheduled: ChildsJson.TransactionBegin ? false : true,
                row_height: 14,
                parent: item.targetid,
              });
              links.push({
                id: ChildsJson.targetid,
                source: item.targetid,
                target: ChildsJson.targetid,
                type: 1, 
              });
              if (ChildsJson.ChildsJson.length != 0) {
                ChildsJson.ChildsJson.map((Childs) =>{ 
                  text = _lang=='en' ? Childs.VisitActivityGpoNameEn : Childs.VisitActivityGpoNameEs;
                  data.push({
                    id: Childs.StowageEventId,
                    text: text,
                    start_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionBegin,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                    end_date: ChildsJson.TransactionBegin ? moment(ChildsJson.TransactionFinish,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'):'',
                    progress: Childs.Porc,
                    Completed: '-',
                    HrsInactive: '-',
                    Hrs: Childs.TotalTime,
                    HrsAcumulated: '-',
                    color: '#e0e0e0',
                    progressColor: Childs.Color, 
                    unscheduled: false,
                    row_height: 14,
                    parent: ChildsJson.targetid,
                    Delay: true,
                  });
                  links.push({
                    id: Childs.StowageEventId,
                    source: ChildsJson.targetid,
                    target: Childs.StowageEventId,
                    type: 2, 
                  });
                });
              }
            } else {
              this.NotProgrammed.push({
                textEn: item.textEn+': '+ChildsJson.textEn,
                textEs: item.textEs+': '+ChildsJson.textEs,
                targetid: ChildsJson.targetid,
              });
            }
          });    
        } 
      } else {
        this.NotProgrammed.push(item);
      }   
    });
    tasks.data = data;
    tasks.links = links;
    this.tasks = tasks;
  } else {
    this.tasks = {}
  }
}

export default {
  name: "PDF-Report",
  components: {
    ManagementPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
  methods: {
    GanttList,
    ProdByDayLapseList,
    TimelineReport,
  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
