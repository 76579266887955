<template>
<CModalExtended
  :title="$t('label.nuevo')+' '+$t('label.OperationType')"
  color="dark"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-extended"
  size="lg"
>
<loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12" lg="12" xl="12">
        <CCard class="card-simple">
          <CCardBody >
            <CRow  class="mb-4">
              
              <CCol sm="12" lg="12" xl="12">
                <CRow>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.tpOperationId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.tpOperationId)"
                      :is-valid="hasError($v.actividadPortuaria.tpOperationId)"
                      :label="$t('label.OperationType')"
                      addLabelClasses="required text-right"
                      :options="tpOperationFormatted"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.PortActivityId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityId)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityId)"
                      :label="$t('label.portActivity')"
                      addLabelClasses="required text-right"
                      :options="activityListFormatted"
                    />
                  </CCol>
                </CRow>
              </CCol>
             
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <CButton
      shape="square"
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="submit"
    >
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{ $t('button.accept') }}</span>
      </div>
    </CButton>
    <CButton
      shape="square"
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import tpOperationActividadValidations from '@/_validations/actividad-portuaria/tpOperationActividadFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadPortuariaMixin from '@/_mixins/actividad-portuaria';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoadingActivitys: false,
    isLoadingTpOperation: false,
    activityList: [], 
    tpOperationList: [], 
    actividadPortuaria: {
      PortActivityId: '',
      tpOperationId:'',
    },
    loadingOverlay: false,
  
  }
}

//Methods


function submit() {

  try {
    this.isSubmit = true;
    this.loadingOverlay = true;
    this.$v.actividadPortuaria.$touch();

    if (this.$v.actividadPortuaria.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
    }
  
    let PortActivityTpOperationJson = {

      TpOperationId: this.actividadPortuaria.tpOperationId,
      PortActivityId: this.actividadPortuaria.PortActivityId,
      
    };

    this.$http.post('PortActivityTpOperation-insert', PortActivityTpOperationJson, { root: 'PortActivityTpOperationJson'} )
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.loadingOverlay = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }

}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  this.modalActive = newVal;
}

function resetForm() {
  this.actividadPortuaria.PortActivityId = '';
  this.actividadPortuaria.tpOperationId = '';
  this.$v.$reset();
}


function getActivityPort() {
  this.isLoadingActivitys = true;

  this.$http.get("PortActivity-list", { Filter: 'ACTIVO' })
  .then(response => {
     this.activityList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingActivitys = false;
  });
}

function activityListFormatted(){

 let _lang = this.$i18n.locale;

  if(this.activityList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.activityList.map(function(e){
      chart.push({
        value: e.PortActivityId, 
        label: _lang=='en' ?  e.PortActivityNameEn : e.PortActivityNameEs,
      })    
    })
    return chart;
  }
}


function getTpOperation() {

  this.isLoadingTpOperation = true;

  this.$http.get("TpOperation-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.tpOperationList = response.data.data;
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpOperation = false;
  });
}

function tpOperationFormatted(){
  if(this.tpOperationList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpOperationList.map(function(e){
      chart.push({
        value: e.OperationId, 
        label: e.OperationName,
      })    
    })
    return chart;
  }
}

export default {
  name: 'modal-create-tpoperation-port-activity',
  mixins: [ActividadPortuariaMixin, ModalMixin],
  props: {
    modal: Boolean
  },
  components: {
  },
  data,
  validations: tpOperationActividadValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getActivityPort,
    getTpOperation,
  },
  computed: { 
        activityListFormatted,
        tpOperationFormatted,
   },
  watch: {
    modal: function(val) {
      this.toggle(val);
      this.getActivityPort();
      this.getTpOperation();    
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);

    }
  }
}
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>