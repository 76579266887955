<template>
<transition name="fade">
  <div class="d-flex no-block justify-content-center align-items-center">
    <div class="back"></div>
    <notifications group="container" position="top right" :duration="5000" :max="5" :ignoreDuplicates="true" :closeOnClick="true" class="mt-3 mr-3" />
    
    <CRow class="auth-wrapper d-flex justify-content-center align-self-stretch auth-box" >
      <div class="p-2 box">
        <div class="logo"></div>
        <CCardBody>
          <transition name="slide-fade" mode="out-in">
            <CForm>
              <h2 class="text-center mb-4 pb-4 black-letter">{{ $t('label.Welcome') }} {{ userFullName }}!</h2>
                
              <CRow alignHorizontal="center">
                <CCol sm="12">
                  <div class="form-group form-row" rol="group">
                    <label class="black-letter col-sm-12 mb-0">{{ $t('label.role') }}</label>
                    <div class="input-group-sm black-letter-select col-sm-12">
                      <v-select
                        :options="rolesOptions"
                        :placeholder="$t('label.select')"
                        :class="role ? 'select-client--correct':ValidateSelect?'select-client--error':''"
                        v-model="$v.role.$model"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow alignHorizontal="center">
                <CCol sm="12">
                  <div class="form-group form-row" rol="group">
                    <label class="black-letter col-sm-12 mb-0">{{$t('label.branch')}}</label>
                    <div class="input-group-sm black-letter-select col-sm-12">
                      <v-select 
                        :options="sucursalesOptions"
                        :placeholder="$t('label.select')"
                        :class="branch ? 'select-client--correct':ValidateSelect?'select-client--error':''"
                        v-model="$v.branch.$model"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"
                        :disabled="role ? false : true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow alignHorizontal="center">
                <CCol sm="12">
                  <div class="form-group form-row" rol="group">
                    <label class="black-letter col-sm-12 mb-0">{{$t('label.yard')}}</label>
                    <div class="input-group-sm black-letter-select col-sm-12">
                      <v-select 
                        :options="patiosOptions"
                        :placeholder="$t('label.select')"
                        :class="yard ? 'select-client--correct':ValidateSelect?FgGeneral:''"
                        v-model="$v.yard.$model"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"
                        :disabled="branch ? false : true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" class="d-flex justify-content-end align-items-center">
                  <CButton
                    color="add"
                    shape="square"
                    class="d-flex align-items-center mr-2"
                    @click.stop="submit"
                  >
                    <div v-if="!isSubmit">
                      <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                    </div>
                    <div v-if="isSubmit">
                      <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="ml-1">{{$t('button.accept')}}</span>
                    </div>
                  </CButton>
                  <CButton
                    shape="square"
                    color="wipe"
                    :disabled="isSubmit"
                    class="d-flex align-items-center"
                    @click.stop="cancelForm"
                  >
                    <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </transition>
        </CCardBody>
      </div>
    </CRow>
  </div>
</transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RoleSelectionValidations from '@/_validations/auth/roleSelectionValidations';

//Data
function data() {
  return {
    isSubmit: false,
    ValidateSelect: false,
    role: '',
    branch: '',
    BranchOptions: [],
    yard: '',
    patios: [],
  }
}

//Methods:
function submit() {
  try{
    this.isSubmit = true;
    this.ValidateSelect = true;
    this.$v.$touch();
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let roleId = this.role;
    this.$store.dispatch('menu/getMenuInfo', roleId)
    .then((response) => {
      this.$store.state.yardManagement.YardSelected = this.yard;
      let BranchData = this.BranchOptions.find(item => item.CompanyBranchId == this.branch);
      BranchData.YardId = this.yard
      this.$store.dispatch('auth/setBranch', BranchData);
      this.$router.push({ name: 'Dashboard' });
    })
    .catch((err) => {
      if (err=='menu') {
        err = this.$t('label.TheRoleDoesNotHaveMenuAssigned');
      }
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
    });
  }catch(err){
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }
}

function cancelForm() {
  this.$store.dispatch('auth/logout');
  this.$router.push({ name: 'Login' });
}

function setPatios(CompanyBranchId, RoleId) {
  let BranchData = this.BranchOptions.find((patio) => {
    return patio.CompanyBranchId == CompanyBranchId && patio.RoleId == RoleId;
  });
  this.patios = BranchData.YardJson&&BranchData.YardJson.length!=0 ? BranchData.YardJson : [];
}

function SetBranch(RolId) {
  this.BranchOptions = this.getSucursales.filter(item => item.RoleId == RolId )
}

//Computed:
function sucursalesOptions() {
  if(this.BranchOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.BranchOptions.map(function(e){
      chart.push({
        value: e.CompanyBranchId,
        label: e.BranchName,
      })
    })
    return chart;
  }
}
function patiosOptions() {
  if(this.patios.length === 0 || this.validateFgGeneral){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]

    this.patios.map(function(e){
      chart.push({
        value: e.YardId,
        label: e.YardName,
      })
    })
    return chart;
  }
}
function rolesOptions() {
  if(this.user){
    if(this.getRoles.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.getRoles.map(function(e){
        chart.push({
          value: e.RoleId,
          label: e.RoleName,
          FgGeneral: e.FgGeneral,
        })
      })
      return chart;
    }
  }
}

function userFullName() {
  if(this.user)
    return `${this.user.UserLastName} ${this.user.UserName}`;
  else
    return '';
}

function FgGeneral() {
  this.validateFgGeneral ? 'select-client--correct' : 'select-client--error';
}

function validateFgGeneral() {
  return this.getRoles.find(item => item.RoleId == this.role)?.FgGeneral ;
}

export default {
  name: 'RoleSelection',
  data,
  validations(){ 
    return RoleSelectionValidations(this.getRoles.find(item => item.RoleId == this.role));
  },
  methods: {
    submit,
    cancelForm,
    setPatios,
    SetBranch,
  },
  computed: {
    rolesOptions,
    patiosOptions,
    sucursalesOptions,
    userFullName,
    validateFgGeneral,
    FgGeneral,
    ...mapState({
        user: state => state.auth.user,
    }),
    ...mapGetters('auth',[
      'getSucursales',
      'getRoles'
    ])
  },
  watch: {
    branch: function(newSucursal){
      this.yard = '';
      if(newSucursal){
        this.setPatios(newSucursal, this.role);
      }else{
        this.patios = [];
      }
    },
    role: function (NewRol) {
      this.branch = '';
      if (NewRol) {
        this.SetBranch(NewRol);
      }else{
        this.BranchOptions = [];
      }
    }
  }
}
</script>

<style scoped>
.box {
  opacity: 1;
  width: 90%;
  height: 233px;
  margin-top: 4%;
}
.auth-wrapper .auth-box.on-sidebar {
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  position: absolute;
}
.auth-box {
  min-width: 500px ;
    background: #D2CFCA;
   z-index: 2;
   height: 100% !important;
   position:absolute;
   border-left: 8px #EC631B solid;
   border-right: 8px #EC631B solid;
}
.black-letter{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
}
.black-letter-select{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14px !important;
}
.background{
  background: #D2CFCA;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>