import {
    onlyAlphanumeric,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal } from "vuelidate/lib/validators";
    

export default () => {
    return {
        SealAssignmentNameEs:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
        SealAssignmentNameEn:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(250),
            }

    }
}

