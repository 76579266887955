import { 
  imgInputTextsHelpers,
  tableTextTranslatedHelpers,
  hasError
 } from '@/_helpers/funciones';

//Data:
function data() {
  return {}
}


//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function imgInputTexts(){
  return imgInputTextsHelpers(this);
}
function tableText(){
  return tableTextTranslatedHelpers(this);
}

export default {
  data,
  methods: {
    hasError,
  },
  computed: {
    isSubmitValid,
    imgInputTexts, 
    tableText,
  }
}