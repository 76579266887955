<template>
    <div>
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars"/>
        <CModalExtended
            color="dark"
            @update:show="closeModal(false, false)"
            :closeOnBackdrop="false"
            :title="title"
            :show.sync="ModalActive"
        >
            <CRow class="justify-content-center">
                <CCol sm="11">   
                    <CInput
                        v-model.trim="$v.Crane.CraneAlias.$model"
                        v-uppercase
                        :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
                        :invalid-feedback="errorMessage($v.Crane.CraneAlias)"
                        :is-valid="hasError($v.Crane.CraneAlias)"
                        :label="$t('label.alias')"
                        :placeholder="$t('label.alias')"
                        addLabelClasses="text-right required"
                    >
                    </CInput>
                    <CSelect
                        v-model="$v.Crane.FgOperational.$model"
                        :label="$t('label.operativa')+'?'"
                        :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
                        :is-valid="hasError($v.Crane.FgOperational)"
                        :options="operativaOptions"
                        addLabelClasses="text-right required"
                    />
                    <CSelect
                        :value.sync="Crane.Status"
                        :is-valid="statusSelectColor"
                        :label="$t('label.status')"
                        :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
                        :options="statusOptions"
                        addLabelClasses="text-right required"
                    />
                </CCol>
            </CRow> 
            <template #footer>
                <CButton
                    color="add"
                    class="m-2"
                    @click="statusConfirmation"
                    :disabled="isSubmit"
                >
                    <div>
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal(false, false)"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import AppCraneValidation from '@/_validations/visitas/schedule/crane/ModalEditDeckCraneValidation';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';

    //Data:
    function data() {
        return {
            ModalActive: false,
            isSubmit: false,
            isLoading: false,
            items: [],
            Buque: '',
            Crane: {
                VisitCraneId: '',
                VesselCraneId: '',
                VesselId: '',
                CraneAlias: '',
                FgOperational: 1,
                Status: 1
            },
            oldFgAct: 1,
        }
    }

    //Methods:
    function submit() {
        try {
            this.isLoading = true;
            this.isSubmit = true;
            this.$v.$touch();

            if (this.$v.$error) {
                throw this.$t('label.errorsPleaseCheck');
            };
            let VisitCraneJson = {
                VisitCraneId:  this.Crane.VisitCraneId,
                VisitId: this.Visit,
                VesselCraneId:  this.Crane.VesselCraneId,
                CraneAlias: this.Crane.CraneAlias,
                FgOperational: this.Crane.FgOperational,
                Status: this.Crane.Status,
            }

            this.$http.ejecutar('POST', 'VisitCrane-insert', VisitCraneJson, { root: 'VisitCraneJson' })
            .then(response => {
                this.isLoading = false;
                this.$notify({
                    group: 'container',
                    title: this.$t('label.success'),
                    text: response.data.data[0].Response,
                    type: "success"
                });
                this.resetInputs();
                this.closeModal(false, true);
                this.isSubmit = false;
            }).catch(err => {
                this.isLoading = false;
                this.isSubmit = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isLoading = false;
            this.isSubmit = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    function resetInputs() {
        this.Crane.VisitCraneId = '';
        this.Crane.VesselCraneId = '';
        this.Crane.VesselId = '';
        this.Crane.CraneAlias = '';
        this.Crane.FgOperational = 1;
        this.Crane.Status = 1;
        this.oldFgAct = 1;
        this.$v.$reset();
    }
    function handleEdit(item) {
        this.Crane.VisitCraneId = item.VisitCraneId;
        this.Crane.VesselCraneId = item.VesselCraneId;
        this.Crane.VesselId = item.VesselCraneId;
        this.Crane.CraneAlias = item.CraneAlias;
        this.Crane.FgOperational = item.FgOperational?1:0;
        this.Crane.Status = item.FgActVisitCrane?1:0;
        this.oldFgAct = item.FgActVisitCrane?1:0;
        this.$v.$touch();
    }
    function closeModal(filtre, Refresh){
        this.resetInputs();
        this.$emit('Close-Modal', filtre, Refresh);
    }
    function statusSelectColor(){
        return this.Crane.Status == 1;
    }
    function statusConfirmation(){
        if(this.Crane.Status === this.oldFgAct)
            this.submit();
        else{
            this.$swal.fire({
                text: `${this.$t('label.changeStatusQuestion')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#42AA91',
                cancelButtonColor: '#E1373F',
                confirmButtonText: this.$t('button.confirm'),
                cancelButtonText: this.$t('button.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                this.submit();
                }
            });
        }
    }
    
    //computed
    function statusOptions() {
        return [
            {
                value: 1,
                label: this.$t('label.ACTIVO')
            },
            {
                value: 0,
                label: this.$t('label.INACTIVO')
            }
        ]
    }
    function operativaOptions() {
        return [
            {
                value: 1,
                label: this.$t('label.yes')
            },
            {
                value: 0,
                label: 'NO'
            }
        ]
    }
    function submitText() {
        return this.$t('button.accept')
    }
    function isSubmitValid(){
        return this.$v.$invalid || this.isSubmit;
    }
    function title() {
        let title = this.CraneItems.CraneAlias ? this.CraneItems.CraneAlias : '';
        return this.$t('label.edit')+' '+this.$t('label.crane')+': '+title;
    }
    export default {
        name:'modal-edit-deck-Crane',
        mixins: [GeneralMixin],
        data,
        props: {modal: Boolean, CraneItems: Object},
        validations: AppCraneValidation,
        directives: UpperCase,
        methods: {
            closeModal,
            submit,
            resetInputs,
            handleEdit,
            statusSelectColor,
            statusConfirmation,
        },
        computed:{
            title,
            statusOptions,
            operativaOptions,
            submitText,
            isSubmitValid,
            ...mapState({
                Visit: state => state.visitas.VisitId,
            })
        },
        watch:{
            modal: function (val) {
                this.ModalActive = val;
                this.handleEdit(this.CraneItems)
            },
        }
    }
</script>

<style lang="scss">
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
                vertical-align: middle !important;
            }
        }
    }
</style>