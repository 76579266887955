<template>
  <div class="container-fluid" :class="{ 'p-0' : visit ? true : null }">
    <leyenda-modal
      loadView
      :visit="visit?true:false"
      :visitType="visit"
      :listType="listType"
      :bayId="currentBayId"
      :modal.sync="legendModal"
    />
    <container-data-modal
      :modal.sync="dataModal"
      :container="containerData"
      loadView
    />
    <bay-info-modal
      v-if="baySelected != null"
      :visit="visit?true:false"
      :vesselBayId="getVesselBayId"
      :bayCode="getBayCode"
      :modal.sync="infoModal"
    />
    <bay-slot-modal
      :stowagePlanningCargoId="stowagePlanningCargoId"
      :baySlot="baySlot"
      :modal.sync="baySlotModal"
    />
    <alerts-modal
      :visit="visit"
      :currentPosition="alertPosition"
      :alertType="alertType"
      :modal.sync="alertModal"
    />
    <img-modal
      :container="modalContainer"
      :listType="getSublistTab"
      :modal.sync="imgModal"
    />
    <confirm-modal
      :visitPosition="container"
      :visit="visit"
      :vesselBayPosId="loadPosition"
      :codPosition="loadCodPosition"
      :craneSelected="currentCrane"
      :modal.sync="confirmModal"
      @submited="handleSubmitConfirm"
    />
    <free-positions-modal
      :visitList="visitList"
      :visit="visit"
      :modal.sync="freeModal"
      @submited="handleSubmitConfirm"
    />
    <reverso-modal
      :visitPosition="reversoSelected"
      :cranes="cranes"
      :visit="visit"
      :modal.sync="reversoModal"
      @submited="handleSubmitConfirm"
    />
    <help-modal
      :visit="visit"
      :listType="listType"
      :modal.sync="helpModal"
    />
    <CRow class="mb-2">
      <CCol sm="12" class="load-view" :class="{'visit-limit': visit ? true : null}">
        <div class="cargo-container">
          <div class="cargo-row">
            <div class="cargo-col" :class="{'cargo-col-visit-left': visit && !isCollapsed ? true : null}">
              <!-- Cargo Nav -->
              <div class="cargo-nav" v-if="!visit">
                <p class="mx-2">BAY VIEW</p>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'INFORMACION',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="toggleInfo"
                  class="ml-1"
                >
                  <CIcon name="information" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'LEYENDA',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="toggleLegend"
                  class="ml-1"
                >
                  <CIcon name="cil-eye" />
                </CButton>
                <CButton
                  :class="{ 'alert-active': showAlerts ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'ALERTAS',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null || removeContainer || isActiveList(1)"
                  @click.stop="toggleAlerts(1)"
                  class="ml-1"
                >
                  <img
                    alt="A"
                    srcset=""
                    class="c-icon"
                    src="/img/iconos/alert.svg"
                  />
                </CButton>
                <CButton
                  :class="{ 'error-active': showErrors ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'ERRORES',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null || removeContainer || isActiveList(1)"
                  @click.stop="toggleAlerts(2)"
                  class="ml-1"
                >
                  <img
                    alt="E"
                    srcset=""
                    class="c-icon"
                    src="/img/iconos/error.svg"
                  />
                </CButton>
                <div class="selects-controls-view">
                  <div class="selects-container">
                    <span>LOAD PORT:</span>
                    <div class="position-relative">
                      <v-select
                        id="v-select-small"
                        size="sm"
                        class="no-margin-select ml-1 mr-2 my-1 select-adjust d-flex aling-items-center v-select-in-settings-plane"
                        v-model="portId"
                        :options="loadPortOptions"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"
                        :placeholder="$t('label.select')"
                        :disabled="isLoadPortEmpty || removeContainer"
                      >
                          <template #no-options="{ }">
                              {{$t('label.noResultsFound')}}
                          </template>
                          <template #option="{label, Json}">
                            <div class="row  d-flex aling-items-center">
                              <div style="width: 13px; height: 12px; border-radius: 20px;" :style="`background-color: ${Json?.Color}`"></div>
                              <label class="m-0 ml-1 p-0" style='color: black;'>
                                {{ label }}
                              </label>
                            </div>
                          </template>
                      </v-select>
                      <loading v-show="isLoadingLoadPorts" element="select" />
                      <!--
                        <CSelect
                          size="sm"
                          :placeholder="$t('label.port')"
                          class="no-margin-select ml-1 mr-2 my-1"
                          :options="loadPortOptions"
                          :disabled="isLoadPortEmpty || removeContainer"
                          :value.sync="portId"
                        />
                      -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="cargo-nav" v-if="!visit">
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  :disabled="isMinimumZoom || baySelected == null"
                  @click.stop="minusZoom"
                  class="ml-1"
                >
                  <CIcon name="cil-zoom-out" />
                </CButton>
                <a
                  href="#"
                  class="zoom-number"
                  :disabled="baySelected == null"
                  @click.prevent="resetZoom"
                >
                  {{ `${zoom}%` }}
                </a>
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  :disabled="baySelected == null"
                  @click.stop="plusZoom"
                  class="ml-1"
                >
                  <CIcon name="cil-zoom-in" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'SHOW ALL',
                    placement: 'top-end',
                  }"
                  class="ml-1"
                  :class="{ 'type-active': activeViewType(3) ? true : null }"
                  @click.stop="selectViewType(3)"
                >
                  <CIcon name="bay" />
                </CButton>
                <CButton
                  :class="{ 'type-active': activeViewType(2) ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'SHOW UNDER DECK',
                    placement: 'top-end',
                  }"
                  @click.stop="selectViewType(2)"
                  class="ml-1"
                >
                  <CIcon name="bayBottom" />
                </CButton>
                <CButton
                  :class="{ 'type-active': activeViewType(1) ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'SHOW DECK TO',
                    placement: 'top-end',
                  }"
                  @click.stop="selectViewType(1)"
                  class="ml-1"
                >
                  <CIcon name="bayTop" />
                </CButton>
                <CButton
                  :class="{ 'type-active': removeContainer ? true : null }"
                  color="secondary"
                  shape="square"
                  class="ml-1"
                  size="sm"
                  :disabled="baySelected == null || isActiveList(1)"
                  @click.stop="toggleRemove"
                >
                  <CIcon name="cil-trash" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  class="ml-1"
                  v-c-tooltip="{
                    content: $t('label.print'),
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="printPdf"
                >
                  <CIcon name="cil-print" />
                </CButton>
              </div>
              <div class="nav cargo-nav nav-height" v-if="visit">
                <!-- Selects -->
                <div class="selects-controls-view ml-1" v-if="(visit == 'load' || visit == 'restow') && listType == 0">
                  <div class="selects-container">
                    <span>{{$t('label.crane')}}</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.crane')"
                        class="no-margin-select ml-1 mr-2 my-1"
                        :options="cranesOptions"
                        :disabled="isCranesEmpty ||( baySelected == null && visit == 'restow' )"
                        :value.sync="craneId"
                        style="min-width: 90px;"
                      />
                    </div>
                  </div>
                </div>
                <div class="selects-controls-view ml-1">
                  <div class="selects-container">
                    <span>{{ 
                      visit == 'discharge' ? 'POD'
                      : visit == 'load' ? 'POL' : $t('label.port')
                    }}</span>
                    <div class="position-relative">
                      <v-select
                        id="v-select-small"
                        size="sm"
                        class="no-margin-select ml-1 mr-2 my-1 select-adjust d-flex aling-items-center v-select-in-settings-plane"
                        v-model="portId"
                        :options="loadPortOptions"
                        :reduce="option => option.value"
                        :getOptionLabel="option => option.label"
                        :placeholder="$t('label.select')"
                        :disabled="isLoadPortEmpty"
                      >
                          <template #no-options="{ }">
                              {{$t('label.noResultsFound')}}
                          </template>
                          <template #option="{label, Json}">
                            <div class="row  d-flex aling-items-center">
                              <div style="width: 13px; height: 12px; border-radius: 20px;" :style="`background-color: ${Json?.Color}`"></div>
                              <label class="m-0 ml-1 p-0" style='color: black;'>
                                {{ label }}
                              </label>
                            </div>
                          </template>
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="selects-controls-view ml-0">
                  <div class="selects-container">
                    <span>BAY</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="'BAY'"
                        class="no-margin-select ml-1 mr-2 my-1"
                        :options="baysOptions"
                        :disabled="!freePositions || baysOptions.length == 0"
                        :value.sync="vesselBayId"
                        style="min-width: 50px;"
                      />
                    </div>
                  </div>
                </div>
                <!-- Botones -->
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  :disabled="isMinimumZoom || baySelected == null"
                  @click.stop="minusZoom"
                >
                  <CIcon name="cil-zoom-out" />
                </CButton>
                <a
                  href="#"
                  class="zoom-number"
                  :disabled="baySelected == null"
                  @click.prevent="resetZoom"
                >
                  {{ `${zoom}%` }}
                </a>
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  :disabled="baySelected == null"
                  @click.stop="plusZoom"
                >
                  <CIcon name="cil-zoom-in" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.showAll'),
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  class="ml-1"
                  :class="{ 'type-active': activeViewType(3) ? true : null }"
                  @click.stop="selectViewType(3)"
                >
                  <CIcon name="bay" />
                </CButton>
                <CButton
                  :class="{ 'type-active': activeViewType(2) ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'SHOW UNDER DECK',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="selectViewType(2)"
                  class="ml-1"
                >
                  <CIcon name="bayBottom" />
                </CButton>
                <CButton
                  :class="{ 'type-active': activeViewType(1) ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'SHOW DECK TO',
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="selectViewType(1)"
                  class="ml-1"
                >
                  <CIcon name="bayTop" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  class="ml-1"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.information'),
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="toggleInfo"
                >
                  <CIcon name="information" />
                </CButton>
                <CButton
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.legend'),
                    placement: 'top-end',
                  }"
                  :disabled="baySelected == null"
                  @click.stop="toggleLegend"
                  class="ml-1"
                >
                  <CIcon name="cil-eye" />
                </CButton>
                <!-- Alertas -->
                <CButton
                  :class="{ 'alert-active': showAlerts ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  class="ml-1"
                  v-c-tooltip="{
                    content: $t('label.alerts'),
                    placement: 'top-end',
                  }"
                  :disabled="container == null || freePositions"
                  @click.stop="toggleAlerts(1)"
                >
                  <img
                    alt="A"
                    srcset=""
                    class="c-icon"
                    src="/img/iconos/alert.svg"
                  />
                </CButton>
                <CButton
                  :class="{ 'error-active': showErrors ? true : null }"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.errors'),
                    placement: 'top-end',
                  }"
                  :disabled="container == null || freePositions"
                  @click.stop="toggleAlerts(2)"
                  class="ml-1"
                >
                  <img
                    alt="E"
                    srcset=""
                    class="c-icon"
                    src="/img/iconos/error.svg"
                  />
                </CButton>
              </div>
              <!-- BayView -->
              <div
                class="zoom-container position-relative"
                :class="{
                  'grab-bing': !isOgZoom ? true : null,
                  'zoom-center':  null,
                  'cursor-container':
                    container && !container.BaySlot && !this.visit ? true : null,
                }"
                v-dragscroll="!isOgZoom"
              >
                <div
                  class="bay-view-container"
                  v-if="baySelected != null"
                  :style="{ width: `${zoom}%` }"
                >
                  <div class="bay-load-view">
                    <bay-container
                      cargoView
                      obligatory
                      smallHeader
                      showStatusView
                      :freePositions="freePositions"
                      :visit="visit != ''"
                      :visitType="visit"
                      :visitPosition="selectedPosition"
                      :visitList="visitList"
                      :listType="listType"
                      :restow="isActiveList(1) || visit == 'restow'"
                      :restowPositions="restowPositions"
                      :removeContainer="removeContainer"
                      :showAlerts="showAlerts"
                      :showErrors="showErrors"
                      :alerts="getWarnings"
                      :errors="getErrors"
                      :maxSize="zoom"
                      :numberSize="30"
                      :viewType="viewType"
                      :colSize="generalCol"
                      :container="container"
                      :bayInfo="baySelected"
                      :filterPort="filterByLoadPort"
                      :visitPort="visitPort"
                      :positionsToDeck="getPositionsToDeck(baySelected)"
                      :positionsUnderDeck="getPositionsUnderDeck(baySelected)"
                      @remove-load="removeLoad"
                      @load-container="loadContainer"
                      @handle-alert="toggleAlertsModal"
                      @selected-position="selectPosition"
                      :loadViewComponent = "true"
                    />
                    <!-- Load View -->
                    <bay-container
                      v-if="isActiveList(1) && bayRestowSelected"
                      cargoView
                      obligatory
                      smallHeader
                      showStatusView
                      :restow="isActiveList(1)"
                      :restowPositions="restowPositions"
                      :removeContainer="removeContainer"
                      :showAlerts="showAlerts"
                      :showErrors="showErrors"
                      :alerts="getWarnings"
                      :errors="getErrors"
                      :maxSize="zoom"
                      :numberSize="30"
                      :viewType="viewType"
                      :colSize="generalCol"
                      :container="container"
                      :bayInfo="bayRestowSelected"
                      :filterPort="filterByLoadPort"
                      :positionsToDeck="getPositionsToDeck(bayRestowSelected)"
                      :positionsUnderDeck="getPositionsUnderDeck(bayRestowSelected)"
                      @remove-load="removeLoad"
                      @load-container="loadContainer"
                      @handle-alert="toggleAlertsModal"
                      :loadViewComponent = "true"
                    />
                    <!-- Ajuste de plano -->
                    <bay-container
                      v-if="visit == 'restow' && bayRestowSelected"
                      :restow="visit == 'restow'"
                      cargoView
                      obligatory
                      smallHeader
                      showStatusView
                      :freePositions="freePositions"
                      :visit="visit != ''"
                      :visitType="visit"
                      :visitPosition="selectedPosition"
                      :visitList="visitList"
                      :listType="listType"
                      :restowPositions="restowPositions"
                      :removeContainer="removeContainer"
                      :showAlerts="showAlerts"
                      :showErrors="showErrors"
                      :alerts="getWarnings"
                      :errors="getErrors"
                      :maxSize="zoom"
                      :numberSize="30"
                      :viewType="viewType"
                      :colSize="generalCol"
                      :container="container"
                      :bayInfo="bayRestowSelected"
                      :filterPort="filterByLoadPort"
                      :visitPort="visitPort"
                      :positionsToDeck="getPositionsToDeck(bayRestowSelected)"
                      :positionsUnderDeck="getPositionsUnderDeck(bayRestowSelected)"
                      @remove-load="removeLoad"
                      @load-container="loadContainer"
                      @handle-alert="toggleAlertsModal"
                      @selected-position="selectPosition"
                      :loadViewComponent = "true"
                    />
                  </div>
                </div>
                <CElementCover v-show="isUpdatingCargo">
                  <CSpinner />
                </CElementCover>
              </div>
              <!-- End BayView -->
            </div>
            <!-- Cargo List -->
            <div class="cargo-col" :class="{'cargo-col-visit-right': visit && !isCollapsed ? true : null}">
              <div class="cargo-nav" v-if="!visit">
                <p class="mx-2" v-if="isActiveList(0)">PRELIMINAR LIST</p>
                <p class="mx-2" v-if="isActiveList(1)">RESTOW LIST</p>
                <p class="mx-2" v-if="isActiveList(2)">IMDG LIST</p>
                <p class="mx-2" v-if="isActiveList(3)">OVERSIZE LIST</p>
                <p class="mx-2" v-if="isActiveList(4)">REEFER LIST</p>
                <p class="mx-2" v-if="isActiveList(5)">HIGH CUBE LIST</p>
              </div>
              <div
                class="nav cargo-nav nav-height"
                :class="{ 'ajustar-header': visit ? true : null}"
                v-if="visit"
              >
                <!-- Confirmacion -->
                <CButton
                  color="add"
                  class="ml-1"
                  size="sm"
                  v-c-tooltip="{
                    content: freePositions?$t('label.freePositions'):$t('label.Confirm'),
                    placement: 'top-end',
                  }"
                  :disabled="actionAvailable || ( freePositions && visitList.length == 0)  || ( !freePositions && !this.container?.VisitCargoId) || ( visit == 'restow' && craneId == '') || (visit == 'restow' && loadPosition == '')"
                  @click="toggleCheck"
                >
                  <CIcon name="checkAlt" />
                </CButton>
                <CButton
                  v-if="visit != 'restow'"
                  color="wipe"
                  shape="square"
                  class="ml-1"
                  :class="{ 'free-positions-border': freePositions ? true : null }"
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.delete'),
                    placement: 'top-end',
                  }"
                  :disabled="(visit == 'discharge' && listType == 1) || (visit == 'load' && listType == 1)"
                  @click="toggleFreePositions"
                >
                  <CIcon name="cil-trash" />
                </CButton>

                <div class="selects-controls-view ml-1">
                  <div class="selects-container">
                    <span><strong>{{$t('label.currentAction')}}</strong></span>
                    <div class="position-relative">
                      <CInput
                        class="no-margin-select ml-1 mr-1 my-1"
                        size="sm"
                        :value="currentAction"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <CButton
                  color="wipe"
                  shape="square"
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: 'Limpiar',
                    placement: 'top-end',
                  }"
                  @click="clearAction"
                >
                  <CIcon name="cil-brush-alt" />
                </CButton>
                <CButton
                  color="light"                            
                  @click="refreshData()"
                  size="sm"
                  shape="pill"
                  style="margin-right:10px"
                  v-c-tooltip="{placement:'top-end',content:$t('label.reset')}"
                >
                  <CIcon name="cil-reload"/>
                </CButton>
                <CButton
                  v-if="visit != 'restow'"
                  color="excel"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: listType == 0 ? $t('label.pendings'):this.$t('label.confirmed'),
                    placement: 'top-end',
                  }"
                  @click="onBtnExport(true)"
                >
                  <CIcon name="cil-file-excel"/>
                </CButton>

                <CButton
                  class="guia-btn p-0"
                  color="secondary"
                  shape="square"
                  size="sm"
                  v-c-tooltip="{
                    content: 'GUIA',
                    placement: 'top-end',
                  }"
                  @click="toggleHelp()"
                >
                  <CIcon class="help-icon-size" src="/img/iconos/GIF_AYUDA.gif" />
                </CButton>
              </div>
              <!-- Container List -->
              <div
                class="container-list"
                v-if="!visit"
                :class="{ 
                  'no-limit-visit-list': isCollapsed ? true : null,
                }"
              >
                <!-- Restow -->
                <dataTableExtended
                  class="align-center-row-datatable list-table active"
                  v-if="isActiveList(1)"
                  hover
                  sorter
                  size="sm"
                  column-filter
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items="formatedList(restowList)"
                  :fields="fields"
                  :loading="isLoadingList"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="5"
                  :active-page="activePage"
                  pagination
                  @row-clicked="selectRestow"
                >
                  <template #loading>
                    <loading />
                  </template>
                  <template #Imagen="{ item }">
                    <td class="center-item">
                      <div>
                        <c-img-extended
                          :src="item.Imagen"
                          :key="item.StowagePlanningCargoId"
                          :error-options="{
                            width: 500,
                            height: 250,
                            text: '',
                            bgColor: 'lightgray',
                          }"
                          block
                          class="img-item"
                        />
                      </div>
                    </td>
                  </template>
                  <template #ContainerCode="{ item }">
                    <td class="center-item">
                      <div class="container-item-icons">
                        <span>{{ item.ContainerCode }}</span>
                        <CButton
                          color="secondary"
                          shape="square"
                          size="sm"
                          @click.stop="toggleDataModal(item)"
                        >
                          <CIcon
                            name="cil-search"
                            size="sm"
                            class="ml-1 table-icons"
                          />
                        </CButton>
                      </div>
                    </td>
                  </template>
                </dataTableExtended>
                <!-- Cargo -->
                <dataTableExtended
                  class="align-center-row-datatable list-table load-view-table active"
                  v-else
                  hover
                  sorter
                  size="sm"
                  column-filter
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items="filteredList"
                  :fields="fields"
                  :loading="isLoadingList"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="5"
                  :active-page="activePage"
                  pagination
                  @row-clicked="selectContainer"
                >
                  <template #loading>
                    <loading />
                  </template>
                  <template #Imagen="{ item }">
                    <td class="center-item">
                      <div>
                        <c-img-extended
                          :src="item.Imagen"
                          :key="item.StowagePlanningCargoId"
                          :error-options="{
                            width: 500,
                            height: 250,
                            text: '',
                            bgColor: 'lightgray',
                          }"
                          block
                          class="img-item"
                        />
                      </div>
                    </td>
                  </template>
                  <template #ContainerCode="{ item }">
                    <td class="center-item">
                      <div class="container-item-icons">
                        <span>{{ item.ContainerCode }}</span>
                        <CButton
                          color="secondary"
                          shape="square"
                          size="sm"
                          @click.stop="toggleDataModal(item)"
                        >
                          <CIcon
                            name="cil-search"
                            size="sm"
                            class="ml-1 table-icons"
                          />
                        </CButton>
                      </div>
                    </td>
                  </template>
                  <template #BaySlot="{ item }">
                    <td class="center-item">
                      <div class="container-item-icons">
                        <span>{{ item.BaySlot }}</span>
                        <CButton
                          color="secondary"
                          shape="square"
                          size="sm"
                          v-if="item.BaySlot != ''"
                          @click.stop="toggleBaySlotModal(item)"
                        >
                          <CIcon
                            name="cil-location-pin"
                            size="sm"
                            class="table-icons"
                          />
                        </CButton>
                      </div>
                    </td>
                  </template>
                </dataTableExtended>
                <CElementCover v-show="isLoadingListados">
                  <CSpinner />
                </CElementCover>
              </div>
              <div
                class="container-list"
                v-if="visit"
                :class="{ 
                  'no-limit-visit-list': isCollapsed ? true : null,
                }"
              >
                <!-- Cargo -->
                <CTabs variant="tabs" :active-tab="listType" @update:activeTab="handleVisitTab" class="nav-setting-plane">
                  <CTab :title="tabLabelPending" class="form-especial">
                    <dataTableExtended
                      class="align-center-row-datatable list-table active"
                      :class="{ 
                        'load-view-table': !visit ? true : null,
                      }"
                      hover
                      sorter
                      size="sm"
                      column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :items="filteredVisitList"
                      :fields="visitFields"
                      :loading="isLoadingList"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="5"
                      :active-page="activePage"
                      pagination
                      @row-clicked="selectVisitContainer"
                    >
                      <template #loading>
                        <loading />
                      </template>
                      <template #Details="{ item }">
                        <td class="center-item">
                          <CButtonGroup>
                            <CButton
                              color="edit"
                              square
                              class="mr-1"
                              disabled
                              size="sm"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <!-- <CButton
                              color="watch"
                              square
                              class="mr-1"
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.toView')+' '+$t('label.image'),
                                placement: 'top-end'
                              }"
                              @click="toggleImg(item)"
                            >
                              <CIcon name="eye" />
                            </CButton> -->
                            <CButton
                              color="secondary"
                              shape="square"
                              size="sm"
                              class="mr-1"
                              :disabled="item.AlertJson.length == 0"
                              v-c-tooltip="{
                                content: $t('label.alerts'),
                                placement: 'top-end',
                              }"
                              @click.stop="toggleContainerAlerts(item, 1)"
                            >
                              <img
                                alt="A"
                                srcset=""
                                class="c-icon"
                                src="/img/iconos/alert.svg"
                              />
                            </CButton>
                            <CButton
                              color="secondary"
                              shape="square"
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.errors'),
                                placement: 'top-end',
                              }"
                              :disabled="item.ErrorJson.length == 0"
                              @click.stop="toggleContainerAlerts(item, 2)"
                            >
                              <img
                                alt="E"
                                srcset=""
                                class="c-icon"
                                src="/img/iconos/error.svg"
                              />
                            </CButton>
                          </CButtonGroup>
                        </td>
                      </template>
                      <template #ContainerCode="{ item }">
                        <td class="center-item">
                          <div class="container-item-icons">
                            <span>{{ item.ContainerCode }}</span>
                          </div>
                        </td>
                      </template>
                      <template #Arrival="{ item }">
                        <td class="center-item">
                          <div class="container-item-icons">
                            <span>{{ item.Arrival }}</span>
                          </div>
                        </td>
                      </template>
                      <template #Seals="{ item }">
                        <td class="center-item d-flex flex-column">
                          <span v-for="seal in item.Seals">{{ seal }}</span>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CTab>
                  <CTab :title="tabLabelCancel" class="form-especial">
                    <dataTableExtended
                      class="align-center-row-datatable list-table active"
                      :class="{ 
                        'load-view-table': !visit ? true : null,
                      }"
                      hover
                      sorter
                      size="sm"
                      column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :items="filteredVisitList"
                      :fields="visitFields"
                      :loading="isLoadingList"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="5"
                      :active-page="activePage"
                      pagination
                      @row-clicked="selectVisitContainer"
                    >
                      <template #loading>
                        <loading />
                      </template>
                      <template #Details="{ item }">
                        <td class="center-item">
                          <CButtonGroup>
                            <CButton
                              color="edit"
                              class="mr-1"
                              disabled
                              size="sm"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <!-- <CButton
                              color="watch"
                              square
                              class="mr-1"
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.toView')+' '+$t('label.image'),
                                placement: 'top-end'
                              }"
                              @click="toggleImg(item)"
                            >
                              <CIcon name="eye" />
                            </CButton> -->
                            <CButton
                              color="plan"
                              class="mr-1"
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.reverse'),
                                placement: 'top-end'
                              }"
                              @click="toggleReverse(item)"
                            >
                              <CIcon name="cil-loop-circular" />
                            </CButton>
                            <CButton
                              color="secondary"
                              size="sm"
                              class="mr-1"
                              :disabled="item.AlertJson.length == 0"
                              v-c-tooltip="{
                                content: $t('label.alerts'),
                                placement: 'top-end',
                              }"
                              @click.stop="toggleContainerAlerts(item, 1)"
                            >
                              <img
                                alt="A"
                                srcset=""
                                class="c-icon"
                                src="/img/iconos/alert.svg"
                              />
                            </CButton>
                            <CButton
                              color="secondary"
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.errors'),
                                placement: 'top-end',
                              }"
                              :disabled="item.ErrorJson.length == 0"
                              @click.stop="toggleContainerAlerts(item, 2)"
                            >
                              <img
                                alt="E"
                                srcset=""
                                class="c-icon"
                                src="/img/iconos/error.svg"
                              />
                            </CButton>
                          </CButtonGroup>
                        </td>
                      </template>
                      <template #ContainerCode="{ item }">
                        <td class="center-item">
                          <div class="container-item-icons">
                            <span>{{ item.ContainerCode }}</span>
                          </div>
                        </td>
                      </template>
                      <template #Arrival="{ item }">
                        <td class="center-item">
                          <div class="container-item-icons">
                            <span>{{ item.Arrival }}</span>
                          </div>
                        </td>
                      </template>
                      <template #Seals="{ item }">
                        <td class="center-item">
                          <span v-for="seal in item.Seals">{{ seal }}</span>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CTab>
                </CTabs>
                <CElementCover v-show="isLoadingListados">
                  <CSpinner />
                </CElementCover>
              </div>
              <!-- End Container List -->
              <!-- Botones de "tabs" -->
              <div class="container-list-tabs" v-if="!visit">
                <a
                  href="#"
                  :class="{ active: isActiveList(0) ? true : null }"
                  @click.prevent="changeActiveList(0)"
                >
                  PRELIMINAR
                </a>
                <a
                  href="#"
                  :class="{ active: isActiveList(1) ? true : null }"
                  @click.prevent="changeActiveList(1)"
                >
                  RESTOW
                </a>
                <a
                  href="#"
                  :class="{ active: isActiveList(2) ? true : null }"
                  @click.prevent="changeActiveList(2)"
                >
                  IMDG
                </a>
                <a
                  href="#"
                  :class="{ active: isActiveList(3) ? true : null }"
                  @click.prevent="changeActiveList(3)"
                >
                  OVERSIZE
                </a>
                <a
                  href="#"
                  :class="{ active: isActiveList(4) ? true : null }"
                  @click.prevent="changeActiveList(4)"
                >
                  REEFER
                </a>
                <a
                  href="#"
                  :class="{ active: isActiveList(5) ? true : null }"
                  @click.prevent="changeActiveList(5)"
                >
                  HIGH CUBE
                </a>
              </div>
              <div class="container-list-tabs" v-if="visit && !(visit=='restow' && this.listType == 1)">
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(0) ? true : null }"
                  @click.prevent="changeVisitActiveList(0)"
                >
                  GENERAL
                </a>
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(1) ? true : null }"
                  @click.prevent="changeVisitActiveList(1)"
                >
                  STANDARD
                </a>
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(2) ? true : null }"
                  @click.prevent="changeVisitActiveList(2)"
                >
                  HIGH CUBE
                </a>
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(3) ? true : null }"
                  @click.prevent="changeVisitActiveList(3)"
                >
                  OVERSIZE
                </a>
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(4) ? true : null }"
                  @click.prevent="changeVisitActiveList(4)"
                >
                  REEFER
                </a>
                <a
                  href="#"
                  :class="{ active: isVisitActiveList(5) ? true : null }"
                  @click.prevent="changeVisitActiveList(5)"
                >
                  IMDG
                </a>
              </div>
              <!-- End botones de "tabs" -->
            </div>
          </div>
          <!-- Bay Overall -->
          <div class="cargo-row">
            <div class="cargo-col no-limit" :class="{'bay-overall-visit': visit && !isCollapsed?true:null}">
              <div class="cargo-nav">
                <p class="mx-2">BAY OVERALL</p>
              </div>
              <!-- BayList -->
              <div class="bay-load-container" :class="{ 'disable-overall': null}">
                <div
                  class="bay-load-col"
                  v-for="(bays, index) in formatedBayList"
                  :key="index"
                >
                  <div
                    v-for="(bay, index) in bays"
                    :key="index"
                    class="bay-load-item"
                    :class="{
                      'bay-load-item-hover': true ,
                      selected: isSelected(bay),
                    }"
                    v-if="(visit == 'restow' && craneId !='') || (visit == 'discharge') || (visit == 'load' && craneId !='' && container) || (visit == 'load' && listType == 1 && container) || (visit == '')"
                  >
                    <bay-container
                      v-if="bay != null"
                      cargoView
                      smallHeader
                      disabledSelect
                      :margin="-1"
                      :bayInfo="bay"
                      :showNumbers="false"
                      :colSize="generalCol"
                      :positionsToDeck="getPositionsToDeck(bay)"
                      :positionsUnderDeck="getPositionsUnderDeck(bay)"
                      :loadViewComponent = "true"
                    />
                    <div
                      v-if="bay != null"
                      class="select-control"
                      :class="{'disable-control': null}"
                      @click.stop="selectBay(bay)"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- End BayList -->
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  tableTextTranslatedHelpers,
  alertPropertiesHelpers,
  DateFormater,
} from "@/_helpers/funciones";
import { dragscroll } from "vue-dragscroll";
import Zoom from "@/_mixins/ZoomMixin";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import CImgExtended from "@/components/CImgExtended";
import LeyendaModal from "../detalles/leyenda-modal";
import ContainerDataModal from "../bays/container-data-modal";
import BayInfoModal from "./information-modal";
import BaySlotModal from "./bayslot-modal";
import AlertsModal from "./alerts-modal";
import BayMixin from "@/_mixins/bays";
import AjustePlanoMixin from "@/_mixins/ajuste-plano";
import ImgModal from "@/pages/visita/tabs/manual_lists/ajustes-plano/container-img-modal";
import ConfirmModal from "@/pages/visita/tabs/manual_lists/ajustes-plano/confirm-modal";
import FreePositionsModal from "@/pages/visita/tabs/manual_lists/ajustes-plano/free-positions-modal";
import ReversoModal from "@/pages/visita/tabs/manual_lists/ajustes-plano/reverso-modal";
import HelpModal from "@/pages/visita/tabs/manual_lists/ajustes-plano/help-modal";
import ldb from 'localdata';

// Data
function data() {
  return {
    bayList: [],
    baySelected: null,
    bayRestowSelected: null,
    restowPositions: { arrival: "", departure: "" },
    viewType: "3",
    removeContainer: false,
    // Para el listado
    generalCol: 0,
    // Para el zoom
    zoom: 100,
    // Buscador
    search: "",
    //Filtros Cargo List
    listados: {},
    isLoadingListados: false,
    idmgList: [],
    reeferList: [],
    overSizeList: [],
    hcList: [],
    restowList: [],
    //Cargo
    isLoadingList: false,
    cargoList: [],
    activePage: 1,
    activeList: 0,
    //ContainerSeleccionado
    container: null,
    containerData: null,
    isUpdatingCargo: false,
    // Botones sobre BayView
    legendModal: false,
    infoModal: false,
    //Botones del datatable
    dataModal: false,
    baySlotModal: false,
    baySlot: "",
    stowagePlanningCargoId: "",
    // Select puertos de carga
    isLoadingLoadPorts: false,
    puertos: [{ PortCode: this.$t('label.select'), PortId: "123" }],
    portId: "",
    // Alertas y Errores
    showAlerts: false,
    showErrors: false,
    alertas: [],
    alertModal: false,
    alertPosition: null,
    alertType: "",
  };
}

// methods:
function loadData() {
  this.$store.state.planificacionestiba.apiStateForm = 1;
  this.isLoadingLoadPorts = true;
  this.isLoadingList = true;

  let peticiones = [
    this.$http.ejecutar("GET", "StowagePlanningCargoListByEdiAuxId_T", {
      StowagePlanningEdiAuxId: this.ediFileId,
    }),
    this.$http.ejecutar("GET", "StowagePlanningVesselBayCargo-list", {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
    }),
    this.$http.ejecutar("GET", "StowagePlanningLoadPort-list", {
      StowagePlanningId: this.planificacionId,
      StowagePlanningEdiId: this.ediFileId,
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.listados = responses[0].data.data[0].CargoListJson.CargoListJson[0];
      this.cargoList = this.listados.CargoJson;
      this.idmgList = this.listados.ImdgJson;
      this.reeferList = this.listados.ReeferJson;
      this.overSizeList = this.listados.OversizedJson;
      this.hcList = this.listados.HighcubeJson;
      this.restowList = this.listados.RestowJson;

      this.bayList = responses[1].data.data;
      this.puertos = [...this.puertos, ...responses[2].data.data];
      this.generalCol = this.generalResponsiveCol(this.bayList);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.planificacionestiba.apiStateForm = 0;
      this.isLoadingLoadPorts = false;
      this.isLoadingList = false;
    });
}

function getCargoList() {
  this.isLoadingList = true;

  this.$http
    .ejecutar("GET", "StowagePlanningCargoListByEdiAuxId_T", {
      StowagePlanningEdiAuxId: this.ediFileId,
    })
    .then((response) => {
      this.listados = response.data.data[0].CargoListJson.CargoListJson[0];
      this.cargoList = this.listados.CargoJson;
      this.idmgList = this.listados.ImdgJson;
      this.reeferList = this.listados.ReeferJson;
      this.overSizeList = this.listados.OversizedJson;
      this.hcList = this.listados.HighcubeJson;
      this.restowList = this.listados.RestowJson;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingList = false;
    });
}
function getRestowList() {
  this.isLoadingRestowList = true;

  this.$http
    .ejecutar("GET", "StowagePlanningCargoTabRestow-list", {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
    })
    .then((response) => {
      this.restowList = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingRestowList = false;
    });
}

function selectBay(item) {
  //if(!this.freePositions && !this.loadSelectBayAvailable) return;
  
  if(this.visit == 'restow') {

    if(this.baySelected?.BayCode != item.BayCode){

      this.zoom = 70;
      this.bayRestowSelected = item ? { ...item } : null;
      //this.restowPositions.arrival = item.Arrival;
      //this.restowPositions.departure = item.Departure;
      
    }
    return;
  }

  this.resetZoom();
  this.baySelected = item ? { ...item } : null;

  if(this.isActiveList(1)) {
    this.container = null;
    this.bayRestowSelected = null;
    this.restowPositions = { arrival: "", departure: "" };
  }
  
}
function isSelected(item) {
  
  if(this.isActiveList(1)) {
    return (this.baySelected && item && this.baySelected.VesselBayId == item.VesselBayId) || (this.bayRestowSelected && item && this.bayRestowSelected.VesselBayId == item.VesselBayId)
  }

  if(this.bayRestowSelected )
    return this.bayRestowSelected && item && this.bayRestowSelected.VesselBayId == item.VesselBayId

  else
    return this.baySelected && item ? this.baySelected.VesselBayId == item.VesselBayId : false;
}
function selectViewType(num) {
  this.viewType = `${num}`;
}
function activeViewType(num) {
  return this.viewType == `${num}`;
}
function resetData() {
  this.generalCol = 0;
  this.bayList = [];
  this.baySelected = null;
  this.bayRestowSelected = null;
  this.restowPositions = { arrival: "", departure: "" };
  this.viewType = "3";
  this.activeList = 0;
  this.activePage = 1;
  this.removeContainer = false;
  //Zoom
  this.zoom = 100;
  this.resetZoom();
  //Cargo
  this.listados = {};
  this.idmgList = [];
  this.reeferList = [];
  this.overSizeList = [];
  this.hcList = [];
  this.restowList = [];
  this.cargoList = [];
  this.container = null;
  this.baySlot = "";
  this.stowagePlanningCargoId = "";
  this.showAlerts = false;
  this.showErrors = false;
  this.alertas = [];
  this.alertPosition = null;
  this.alertType = "";
  //Filtro de tabla
  (this.puertos = [{ PortCode: this.$t('label.select'), PortId: "123" }]),
    (this.portId = "");
}
function selectContainer(item, index, columnName, event) {
  if (this.container) {
    if (item.StowagePlanningCargoId == this.container.StowagePlanningCargoId)
      this.container = null;
    else this.container = { ...item };
  } else this.container = { ...item };
}
function removeLoad(load) {
  this.$swal
    .fire(
      alertPropertiesHelpers(this, {
        text: `¿${this.$t('label.removeContainerOne')} ${load.CargoJson[0].ContainerCode} ${this.$t('label.removeContainerTwo')} ${load.CodPositionLabel}?`,
      })
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.deactivateLoad(load);
      }
    });
}
function loadContainer(toUpdate) {
  this.isUpdatingCargo = true;

  let StowagePlanningCargoJson = {
    StowagePlanningCargoId: toUpdate.contenedor.StowagePlanningCargoId,
    VesselBayPosId: toUpdate.posicion.VesselBayPosId,
    EdiFileId: this.ediFileId,
  };

  this.$http
    .put("StowagePlanningCargoPosition-update", StowagePlanningCargoJson, {
      root: "StowagePlanningCargoJson",
    })
    .then((response) => {
      this.$notify({
        group: "container",
        title: "¡Exito!",
        text: response.data.data[0].Response,
        type: "success",
      });
      this.container = null;
      this.bayList = [];
      this.baySelected = null;
      this.puertos = [];
      this.portId = "123"
      this.loadData();
     //this.getCargoList();
     //this.updatePositions(response.data.data[0].BayJson);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isUpdatingCargo = false;
    });
}
function updatePositions(bays) {
  for (let i = 0; i < bays.length; i++) {
    let bayIndex = this.bayList.findIndex(
      (item) => item.VesselBayId == bays[i].VesselBayId
    );
    if (bayIndex == -1) {
      console.error("Error en el id de response del PUT - Bay inexistente.");
      break;
    }

    if (!bays[i].PositionJson) {
      console.warn("PositionJson null, verificar servicio.");
      return;
    }

    for (let j = 0; j < bays[i].PositionJson.length; j++) {
      let pos = this.bayList[bayIndex].PositionJson.findIndex(
        (item) => item.VesselBayPosId == bays[i].PositionJson[j].VesselBayPosId
      );
      if (pos == -1) {
        console.error(
          "Error en el id de response del PUT - Posicion inexistente."
        );
        return;
      }

      this.bayList[bayIndex].PositionJson.splice(
        pos,
        1,
        bays[i].PositionJson[j]
      );
    }
  }
}
function tableClasses(item) {
  let port = this.puertos.find((item) => item.PortId === this.portId);

  return {
    clickable: true,
    asignado: item.CodPosition && !item.ArrivalPosition && !this.visit ? true : null,
    "not-load-port":
      item.CodPosition &&
      this.portId != "123" &&
      port &&
      item.LoadPortId != port.PortId
        ? true
        : null,
    selected:
      this.container &&
      item.StowagePlanningCargoId == this.container.StowagePlanningCargoId
        ? true
        : null,
  };
}
function toggleLegend() {
  this.legendModal = true;
}
function toggleDataModal(item) {
  let datos = { ...item };
  datos.ContainerCode = item.ContainerCode;
  datos.CodPositionLabel = item.CodPosition;
  datos.WeigthReal = item.WeigthJson[0].Value;
  datos.UnitMeasureWeigthAbbrev = item.WeigthJson[0].UnitMeasureAbbrev;
  datos.LoadPortCode = item.LoadPort;
  datos.DischargePortCode = item.DischargePort;
  datos.Commodity = item.CommodityName;
  datos.TranshipmentPortCode = item.TranshipmentPort;
  datos.Lenght = item.LenghtJson[0].Value;
  datos.UnitMeasureLenghtAbbrev = item.LenghtJson[0].UnitMeasureAbbrev;
  datos.Height = item.HeightJson[0].Value;
  datos.UnitMeasureHeigth = item.HeightJson[0].UnitMeasureAbbrev;
  datos.Class = item.ImdgCode;
  datos.GpoCargoRoute = item.Route;

  let auxArray = [];
  auxArray.push(datos);
  datos.CargoJson = [...auxArray];

  this.containerData = { ...datos };
  this.dataModal = true;
}
function toggleInfo() {
  this.infoModal = true;
}
function toggleBaySlotModal(item) {
  this.stowagePlanningCargoId = item.StowagePlanningCargoId;
  this.baySlot = item.BaySlot;
  this.baySlotModal = true;
}
function toggleAlerts(op) {
  if (op == 1) this.showAlerts = !this.showAlerts;
  if (op == 2) this.showErrors = !this.showErrors;
}
function toggleAlertsModal(info) {
  this.alertPosition = { ...info.position };
  this.alertType = info.type;
  this.alertModal = true;
}
function changeActiveList(num) {
  this.activePage = 1;
  this.activeList = num;
  this.container = null;
  if (this.activeList == 1) {
    this.resetZoom();
  }
  if (this.activeList != 1) {
    this.bayRestowSelected = null;
    this.restowPositions = { arrival: "", departure: "" };
  }
}
function isActiveList(num) {
  return this.activeList == num;
}
function getAlerts(vesselBayId) {
  this.$http
    .ejecutar("GET", "StowagePlanningAlertError-by-VeselBayId", {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
      VesselBayId: vesselBayId,
    })
    .then((response) => {
      this.alertas = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}
function formatedList(list) {
  return list.map((item) =>
    Object.assign({}, item, {
      _classes: this.tableClasses(item),
      _cellClasses: this.cellTableClasses,
      Imagen: `${this.$store.getters["connection/getBase"]}${
        item.Route ? item.Route.replace("Public/", "", null, "i") : ""
      }`,
      BaySlot: item.CodPosition ? item.CodPosition : "",
      IsoCode: item.IsoCode ? item.IsoCode : "",
      TpCargoCode: item.TpCargoCode ? item.TpCargoCode : "",
      Size: item.Size ? item.Size : "",
      ImdgCode: item.ImdgCode ? item.ImdgCode : "",
      Weight: item.WeigthJson
        ? `${item.WeigthJson[0].Value} ${item.WeigthJson[0].UnitMeasureAbbrev}`
        : "",
      TpCargoStatusName: item.TpCargoStatusName ? item.TpCargoStatusName : "",
      ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : "",
      LoadPort: item.LoadPort ? item.LoadPort : "",
      DischargePort: item.DischargePort ? item.DischargePort : "",
      TransshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : "",
      BookingNumber: item.BookingNumber ? item.BookingNumber : "",
      Height: item.HeightJson
        ? `${item.HeightJson[0].Value} ${item.HeightJson[0].UnitMeasureAbbrev}`
        : "",
      Length: item.LenghtJson
        ? `${item.LenghtJson[0].Value} ${item.LenghtJson[0].UnitMeasureAbbrev}`
        : "",
      ReeferSetting: item.ReeferSetting
        ? `${item.ReeferSetting} ${item.UnitMeasureRfAbbrev}`
        : "",
      MinimumRangeRf: item.MinimumRangeRf
        ? `${item.MinimumRangeRf} ${item.UnitMeasureRfAbbrev}`
        : "",
      MaximumRangeRf: item.MaximumRangeRf
        ? `${item.MaximumRangeRf} ${item.UnitMeasureRfAbbrev}`
        : "",
      DimFront: item.DimFront
        ? `${item.DimFront} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimBack: item.DimBack
        ? `${item.DimBack} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimLeft: item.DimLeft
        ? `${item.DimLeft} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimRight: item.DimRight
        ? `${item.DimRight} ${item.UnitMeasureDimAbbrev}`
        : "",
      DimTop: item.DimTop ? `${item.DimTop} ${item.UnitMeasureDimAbbrev}` : "",
      Vgm: item.VgmCertificate
        ? `${item.VgmJson[0].Value} ${item.VgmJson[0].UnitMeasureAbbrev}`
        : "",
      VgmCertificate: item.VgmCertificate ? item.VgmCertificate : "",
      Seal1: item.Seal1 ? item.Seal1 : "",
      Seal2: item.Seal2 ? item.Seal2 : "",
      Seal3: item.Seal3 ? item.Seal3 : "",
      Seal4: item.Seal4 ? item.Seal4 : "",
      CommodityName: item.CommodityName ? item.CommodityName : "",
      Arrival: item.CodPosition ? item.CodPosition : "",
      Departure: item.NewPosition ? item.NewPosition : "",
      MovType: item.FgSS
        ? "SHIP - TO - SHIP"
        : item.FgSLS
        ? "SHIP - LAND - SHIP"
        : "",
    })
  );
}
function selectRestow(item, index, columnName, event) {
  if (this.activeList != 1) return;

  if (this.container) {
    if (item.StowagePlanningCargoId == this.container.StowagePlanningCargoId) {
      this.container = null;
      this.restowPositions = { arrival: "", departure: "" };
      this.bayRestowSelected = null;
    } else {
      this.container = { ...item };
      this.searchRestowBays(item);
    }
  } else {
    this.container = { ...item };
    this.searchRestowBays(item);
  }
}
function searchRestowBays(item) {
  this.restowPositions.arrival = item.Arrival;
  this.restowPositions.departure = item.Departure;

  let auxBay = null,
    auxBayRestow = null,
    foundBay = false,
    foundBayRestow = false;


  for (let index = 0; index < this.bayList.length; index++) {
    if (!foundBay) {
      
      let foundA = this.bayList[index].PositionJson.find(
        (item) =>{ return (item?.CargoJson?.length>0 && item?.CargoJson[0]?.Size == "40")? item.CodPositionLabel == this.restowPositions.arrival : item.CodPosition == this.restowPositions.arrival}
      );
      
      if (foundA) {
        auxBay = { ...this.bayList[index] };
        foundBay = true;
        if(this.visit)
          break;
      }
    }
    if (!foundBayRestow) {
      let foundB = this.bayList[index].PositionJson.find(
        (item) =>{ return (item?.CargoJson?.length>0 && item?.CargoJson[0]?.Size == "40")? item.CodPositionLabel == this.restowPositions.departure : item.CodPosition == this.restowPositions.departure}
      );

      if (foundB) {
        auxBayRestow = { ...this.bayList[index] };
        foundBayRestow = true;
      }
    }

    if (foundBay && foundBayRestow) break;
  }

  if(auxBay) this.baySelected = { ...auxBay };


  this.bayRestowSelected =
    foundBayRestow && auxBay?.VesselBayId != auxBayRestow?.VesselBayId && !this.visit
      ? { ...auxBayRestow }
      : null;
  if (this.bayRestowSelected)
    this.zoom = 70;
  else
    this.resetZoom();

    
}
function toggleRemove() {
  if (!this.removeContainer) {
    this.container = null;
    this.portId = this.branch.PortId;
  }
  if (this.removeContainer) this.portId = "123";

  this.removeContainer = !this.removeContainer;
}
function deactivateLoad(load) {
  this.isUpdatingCargo = true;

  let StowagePlanningCargoJson = {
    StowagePlanningCargoId: load.StowagePlanningCargoId,
    VesselBayPosId: load.VesselBayPosId,
  };

  this.$http
    .put("StowagePlanningCargoPositionDeactivate", StowagePlanningCargoJson, {
      root: "StowagePlanningCargoJson",
    })
    .then((response) => {
      this.$notify({
        group: "container",
        title: "¡Exito!",
        text: response.data.data[0].Response,
        type: "success",
      });

      this.getCargoList();
      this.updatePositions(response.data.data[0].BayJson);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isUpdatingCargo = false;
    });
}
function printPdf() {
  if(!this.baySelected)
    return;

  let pageInfo = {
    baysList: [this.baySelected],
    itineraryInfo: this.currentItinerary,
    generalCol: this.generalCol,
    filter: this.filterByLoadPort,
    type: 'bay-view',
  };

  ldb.set('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfPage'});
  window.open(routeData.href, '_blank');
}

// computeds:
function formatedBayList() {
  let assigned = [];
  let formated = [];
  let list = [];

  if(this.loadSelectBayAvailable){
    list = this.bayList.filter((el) => {
      return this.loadBaysList.some((f) => {
        return f.VesselBayId ===  ((this.container?.Size == "40")? el.VesselBayIdPair : el.VesselBayId);
      });
    });

  } else
    list = this.bayList;

  if (list.length != 0) {
    for (let index = 0; index < list.length; index++) {
      const bay = list[index];
      let asignado = assigned.findIndex(
        (item) => item.VesselBayId === bay.VesselBayId
      );
      if (asignado == -1) {
        let found = bay.VesselBayIdMirror
          ? this.bayList.find(
              (item) => item.VesselBayId === bay.VesselBayIdMirror
            )
          : null;
        formated.push([bay, found]);
        assigned.push(bay);
        if (found) assigned.push(found);
      }
    }
  }
  
  return formated;
}
function isBayListEmpty() {
  return this.bayList.length === 0;
}
function tableText() {
  return tableTextTranslatedHelpers(this);
}
function currentBayId() {
  return this.baySelected && this.baySelected.VesselBayId
    ? this.baySelected.VesselBayId
    : "";
}

/*
function loadPortOptions() {
  return this.puertos.map((port) =>
    Object.assign({}, port, {
      label: port.PortCode,
      value: port.PortId,
      Json: {...port}
    })
  );
}*/

function loadPortOptions() {
  let chart = []
  this.puertos.map((port)=>{
    chart.push({
      label: port.PortCode,
      value: port.PortId,
      Json: {...port}
    })
  })
  return chart;
  
}

function isLoadPortEmpty() {
  return this.puertos.length == 0;
}
function filterByLoadPort() {
  let port = null, type = '';

  if(!this.visit){
    port = this.puertos.find((item) => item.PortId === this.portId);
    type = (this.portId && this.portId != "123") ? "LoadPortId" : "";
  } else 
      if (this.branch) {
        port = { 
          PortId: this.branch.PortId,
          PortCode: this.branch.PortCode
        }
        switch (this.visit) {
          case 'discharge':
            type = 'DischargePortId';
            break;
          case 'load':
            type = 'LoadPortId';
            break;
          case 'restow':
            type = 'LoadPortId';
            break;
          default:
            type = '';
            break;
        }
      } 
      else 
       port = null;

  return {
    FilterType: type,
    PortId: port ? port.PortId : "",
    PortCode: port ? port.PortCode : "",
  };
}
function getVesselBayId() {
  return this.baySelected ? this.baySelected.VesselBayId : "";
}
function getBayCode() {
  return this.baySelected ? this.baySelected.BayCode : "";
}
function getWarnings() {
  return this.alertas.filter((item) => item.FgAlert == 1);
}
function getErrors() {
  return this.alertas.filter((item) => item.FgError == 1);
}
function fields() {
  let fields = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "BaySlot",
      label: "BAYSLOT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsRestow = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Arrival",
      label: "ARRIVAL POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Departure",
      label: "DEPARTURE POSITION",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "MovType",
      label: "MOV TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsImdg = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "BaySlot",
      label: "BAYSLOT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsOversize = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "BaySlot",
      label: "BAYSLOT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsReefer = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "BaySlot",
      label: "BAYSLOT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "MinimumRangeRf",
      label: "MINIMUM RANGE RF",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "MaximumRangeRf",
      label: "MAXIMUM RANGE RF",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];
  let fieldsHC = [
    {
      key: "Imagen",
      label: "",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
      sorter: false,
      filter: false,
    },
    {
      key: "ContainerCode",
      label: "CONTAINER CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "BaySlot",
      label: "BAYSLOT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Size",
      label: "SIZE FT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoCode",
      label: "GROUP TYPE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "IsoCode",
      label: "ISOCODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TpCargoStatusName",
      label: "STATUS",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ShippingLineCode",
      label: "CARRIER",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "LoadPort",
      label: "LOAD PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DischargePort",
      label: "DISCHARGE PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "TransshipmentPort",
      label: "TRANSSHIPMENT PORT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;"
    },
    {
      key: "Weight",
      label: "WEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Height",
      label: "HEIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Length",
      label: "LENGTH",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ImdgCode",
      label: "IMDG CODE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "ReeferSetting",
      label: "REEFER SETTING",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimFront",
      label: "DIM FRONT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimBack",
      label: "DIM BACK",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimLeft",
      label: "DIM LEFT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimRight",
      label: "DIM RIGHT",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "DimTop",
      label: "DIM TOP",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Vgm",
      label: "VGM",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "VgmCertificate",
      label: "VGM CERTIFICATE",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal1",
      label: "SEAL 1",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal2",
      label: "SEAL 2",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal3",
      label: "SEAL 3",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "Seal4",
      label: "SEAL 4",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
    {
      key: "CommodityName",
      label: "COMMODITY",
      _classes: "center-item",
      _style: "width:1%; text-align: center;",
    },
  ];

  switch (this.activeList) {
    case 0:
      return fields;
    case 1:
      return fieldsRestow;
    case 2:
      return fieldsImdg;
    case 3:
      return fieldsOversize;
    case 4:
      return fieldsReefer;
    case 5:
      return fieldsHC;

    default:
      return fields;
  }
}
function filteredList() {
  let list = [];
  if (this.activeList == 0) list = this.cargoList;
  if (this.activeList == 2) list = this.idmgList;
  if (this.activeList == 3) list = this.overSizeList;
  if (this.activeList == 4) list = this.reeferList;
  if (this.activeList == 5) list = this.hcList;

  if (this.baySelected && !this.baySelected.VesselBayIdPair) {
    return this.formatedList(list).filter((item) => item.Size == "20");
  }

  return this.formatedList(list);
}

function cellTableClasses() {
  return {
    BaySlot: 'bg-specific-cell',
  }
}
function currentItinerary() {
  if(this.ItineraryId !== 0 || this.ItineraryId !== null){
    const Index = this.myDataItinirary.map(function(e) {
        return e.ItineraryId; 
    }).indexOf(this.ItineraryId);
    
    if(Index > -1){
      return {
        DatePlanning: this.myDataItinirary[Index].DatePlanning,
        TpVesselName: this.myDataItinirary[Index].TpVesselName,
        Voyage: this.myDataItinirary[Index].Voyage,
        Ata:  this.myDataItinirary[Index].Ata ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Ata) : 'N/A' ,
        Atd: this.myDataItinirary[Index].Atd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Atd) : 'N/A' ,
        Eta:  this.myDataItinirary[Index].Eta ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta) : 'N/A' ,
        Etd: this.myDataItinirary[Index].Etd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd) : 'N/A' ,
        VesselId: this.myDataItinirary[Index].VesselId,
        VesselName: this.myDataItinirary[Index].VesselName,
        VesselFlagRoute: this.myDataItinirary[Index].VesselFlagRoute,
        VesselRoute: `${this.myDataItinirary[Index].VesselRoute}`,
        ServiceCode: this.myDataItinirary[Index].ServiceCode,
        Imo: this.myDataItinirary[Index].Imo,
        Mmsi: this.myDataItinirary[Index].Mmsi,
        Proa: this.myDataItinirary[Index].Proa,
        Popa: this.myDataItinirary[Index].Popa,
        Teus: this.myDataItinirary[Index].Teus,
        VesselTeus: this.myDataItinirary[Index].VesselTeus,
        CountryName: this.myDataItinirary[Index].CountryName,
      }
    } else return null;
  }
}
function isCollapsed() {
  return this.$store.state.visitas.collapseSidebar;
}


export default {
  name: "load-view",
  components: {
    BayContainer,
    CImgExtended,
    LeyendaModal,
    ContainerDataModal,
    BayInfoModal,
    BaySlotModal,
    AlertsModal,
    ImgModal,
    ConfirmModal,
    FreePositionsModal,
    ReversoModal,
    HelpModal,
  },
  props: {
    visit: {
      type: String,
      default: "",
    },
    plano: {
      type: Object,
      default: () => {}
    },
    tabChange: {
      type: Number,
      default: 0,
    }
  },
  mixins: [Zoom, BayMixin, AjustePlanoMixin],
  data,
  directives: {
    dragscroll,
  },
  methods: {
    loadData,
    resetData,
    selectBay,
    isSelected,
    selectViewType,
    activeViewType,
    getCargoList,
    selectContainer,
    removeLoad,
    loadContainer,
    tableClasses,
    toggleLegend,
    toggleDataModal,
    changeActiveList,
    isActiveList,
    toggleInfo,
    toggleBaySlotModal,
    toggleAlerts,
    getAlerts,
    toggleAlertsModal,
    updatePositions,
    formatedList,
    selectRestow,
    toggleRemove,
    deactivateLoad,
    searchRestowBays,
    printPdf,
  },
  computed: {
    fields,
    filteredList,
    formatedBayList,
    isBayListEmpty,
    tableText,
    currentBayId,
    loadPortOptions,
    isLoadPortEmpty,
    filterByLoadPort,
    getVesselBayId,
    getBayCode,
    getWarnings,
    getErrors,
    cellTableClasses,
    currentItinerary,
    isCollapsed,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      dropItem: (state) => state.planificacionestiba.dropItem,
      collapsePlanificacion: (state) =>
        state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      branch: (state) => state.auth.branch,
      ItineraryId: state => state.planificacionestiba.ItineraryId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
      VisitId: state => state.visitas.VisitId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch: {
    baySelected: function (newBay) {
      if (newBay && !this.visit) this.getAlerts(newBay.VesselBayId);
      else this.alertas = [];
    },
    dropItem: function (newItem) {
      if (newItem == 20 && !this.visit) {
        this.loadData();
      } else {
        this.resetData();
      }
    },
    collapsePlanificacion: function (newValue) {
      if (!newValue) {
        this.resetData();
        this.resetVisitData();
      }
    },
    isHidden: function (newValue) {
      if (!newValue) {
        this.resetData();
        this.resetVisitData();
      }
    },
    currentBayId:function(newValue) {
      this.vesselBayId = newValue ? newValue : '';
    },
    tabChange:function(newValue) {
      this.baySelected = null;
      this.container = null;
      this.craneId = '';
      this.freePositions = false;
      this.visitList = [];
      this.selectedPosition = null;
    },
  },
};
</script>

<style>

.nav-setting-plane .nav-tabs .nav-link.active {
  color: #ffffff!important;
  background-color: #768192;
  border-color: #c4c9d0 #c4c9d0 #ebedef;
}

.load-view .btn{
  border-radius: 3px;
}

.v-select-in-settings-plane .vs__dropdown-toggle{
  min-width: 110px;
  max-height: 25px;
}

.load-view .cargo-container {
  width: 100%;
}
.load-view .cargo-row {
  width: 100%;
  display: flex;
}
.load-view .cargo-col {
  width: calc(calc(100% / 2) + 2px);
  min-height: 525px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  transition: height 0.5s ease-in-out;

  /* No van */
  border: 1px solid #000;
  margin: -1px 0 0 -1px;
}
.load-view .cargo-col-visit-left {
  width: 60%;
    /* max-width: 690.467px; */
}
.load-view .cargo-col-visit-right {
  width: 39%;
}
.load-view .cargo-nav {
  width: 100%;
  min-height: 30px;
  max-height: 60px;
  background-color: #010830;
  color: #fff;

  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

@media (max-width: 1600px) {
  .load-view .nav-height {
    min-height: 80px!important;
  }
}
.load-view .cargo-nav .btn-sm {
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
}
.load-view .cargo-nav p {
  margin: 0;
}
.load-view .no-limit {
  width: calc(100% + 2px);
  min-height: 250px;
  max-height: 265px;
}

/* Bay View */
.load-view .bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.load-view .bay-load-view {
  position: relative;
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.load-view .bay-load-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  overflow-x: hidden;
}
.load-view .bay-load-col {
  position: relative;
  margin: 0 5px 5px 5px;
  width: calc(calc(100% / 5) - 10px);
  padding: 10px 0;

  display: flex;
  flex-direction: column;
}
.load-view .bay-load-item {
  position: relative;
  width: 100%;
  padding: 2px 0 5px;
  flex: 1;
}
.load-view .bay-load-item:first-child {
  margin-bottom: 15px;
}
.load-view .bay-load-item-hover:hover {
  background-color: #cbcbcb;
}
.load-view .bay-load-item-hover:hover .select-control {
  outline: 2px solid lawngreen;
}
.load-view .bay-load-item.selected {
  background-color: #cbcbcb;
  border: 2px solid lawngreen;
}
.load-view .bay-load-item .select-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Zooom */
.load-view .zoom-container {
  width: 100%;
  min-height: 525px;
  max-height: 800px;
  overflow: auto;
}
.load-view .zoom-center {
  display: flex;
  justify-content: center;
}
.load-view .sidebar-controls {
  display: flex;

  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}
.load-view .zoom-btn-icon {
  width: 0;
  height: 0;
}
.load-view .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 1rem !important;
  height: 0.8rem !important;
}
.load-view .zoom-number {
  padding: 3.5px;
}
.load-view .zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.load-view .grab-bing {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
}
.load-view .grab-bing:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}

.load-view .type-active,
.load-view .alert-active,
.load-view .error-active {
  color: #fff;
  border-color: #0d47a1;
  background-color: #0d47a1;
}

/* Selects styles */
.load-view .cargo-controls {
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0 5px;

  display: flex;
  align-items: center;
}
.load-view .cargo-controls span {
  font-size: small;
}
.load-view .no-margin-select {
  margin-bottom: 0;
  font-size: 0.6rem;
}
.load-view .selects-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-view .selects-controls {
  margin-left: auto;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.load-view .selects-controls-view {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.load-view .selects-controls span,
.load-view .selects-controls-view span {
  font-size: x-small;
}

/* Container list styles */
.load-view .container-list {
  position: relative;
  width: 100%;
  max-width: 650px;
  min-height: 525px;
  max-height: 770px;

  overflow: auto;
}
.load-view .list-table {
  font-size: x-small;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;
}
.load-view .list-table.active {
  opacity: 1;
}
.load-view .list-table tbody tr:hover {
  background-color: transparent;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  box-shadow: 20px 0px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 20px 0px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 20px 0px 15px rgba(0, 0, 0, 0.3);
}
.load-view .list-table tbody tr.asignado:hover {
  background-color: #f7c0a2;
}
.load-view .list-table tbody tr.not-load-port:hover {
  background-color: #aaaaaa;
}
.load-view .img-item {
  width: 10em;
  height: 6em;
}
.load-view .pagination {
  margin-bottom: 0 !important;
}
.load-view .clickable {
  cursor: pointer;
}
.load-view .center-item {
  text-align: center;
  vertical-align: middle !important;
}
.load-view .form-control {
  font-size: x-small !important;
}
.load-view .form-control-sm {
  font-size: xx-small !important;
}

.load-view .container-list-tabs {
  display: flex;
  margin-top: auto;
  overflow: hidden;
}
.load-view .container-list-tabs a {
  width: calc((100% / 6) - 0.5px);
  text-align: center;
  border: 1px solid #a6a6a6;
  background-color: #fff;
  color: #000;
  font-size: small;
  /* padding: 3px 14px; */
  padding: 3px 0;
  border-radius: 4px;
  margin: 1px 0;
}
.load-view .container-list-tabs a:hover {
  background-color: #ebebeb;
}
.load-view .container-list-tabs a.active {
  background-color: #bdbdbd;
}
.load-view .container-list-tabs a:first-child {
  margin-right: 1px;
}

.load-view .list-table .asignado {
  background-color: #f7c0a2;
}
.load-view .list-table .not-load-port {
  background-color: #aaaaaa;
}
.load-view .cursor-container {
  cursor: url("/img/iconos/cursorContainer.png"), auto;
}
.load-view .list-table .selected {
  border: 3px solid #3f51b5;
}
.load-view .list-table .selected-visit {
  border: 3px solid #3f51b5;
  background-color: #d7e2f3;
}

.load-view .container-item-icons {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.load-view .table-icons {
  cursor: pointer;
}
.load-view .list-table.load-view-table .btn-sm {
  background-color: transparent;
  padding: 0;
  padding-right: 0.3rem;
  border-radius: 0.2rem;
}

/* Iconos */
.load-view .restow-externo {
  transform: rotate(90deg);
}

.bg-specific-cell {
  background-color: gray !important;
}

.disable-overall {
  background-color: #bdbdbd;
}
.disable-control {
  cursor: default !important;
}
.ajustar-header {
  min-height: 31.5px !important;
}
.visit-limit {
  padding-right: 0;
}
.bay-overall-visit {
  width: 99% !important;
}
.no-limit-visit-list {
  max-width: 100% !important;
}
.guia-btn {
  margin-left: auto;
  background-color: #010830;
  border: 0;
}
.free-positions-border {
  border: 2px solid orange;
}
.help-icon-size {
  max-width: 30px;
  max-height: 30px;
}
</style>