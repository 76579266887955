<template>
<CModalExtended
  :title="getTitle"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-mov"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow>
              <CCol sm="12"> 
                <CInput
                  v-model.trim="$v.movimiento.MovStowageNameEs.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                  :invalid-feedback="errorMessage($v.movimiento.MovStowageNameEs)"
                  :is-valid="hasError($v.movimiento.MovStowageNameEs)"
                  :label="$t('label.name')+' '+('(ES)')"
                  addLabelClasses="required text-right"
                  :placeholder="$t('label.movementName')+' '+('(ES)')"
                  maxlength="100"
                >
                </CInput>
              </CCol>
              <CCol sm="12"> 
                <CInput
                  v-model.trim="$v.movimiento.MovStowageName.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                  :invalid-feedback="errorMessage($v.movimiento.MovStowageName)"
                  :is-valid="hasError($v.movimiento.MovStowageName)"
                  :label="$t('label.name')+' '+('(EN)')"
                  addLabelClasses="required text-right"
                  :placeholder="$t('label.movementName')+' '+('(EN)')"
                  maxlength="100"
                >
                </CInput>
              </CCol>
              <CCol sm="12">
                <CSelect
                  :value.sync="movimiento.Status"
                  :is-valid="statusSelectColor"
                  :label="$t('label.status')"
                  addLabelClasses="text-right"
                  :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                  :options="statusOptions"
                  class="mt-1"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer><!--:disabled="isSubmitValid"-->
    <CButton
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="statusConfirmation(movData.FgActMovStowage, movimiento.Status, submit)"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>

    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>-->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import MovFormValidations from '@/_validations/estiba/MovFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { MovimientoMixin } from '@/_mixins/estiba';
import ModalMixin  from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    movimiento: {
      MovStowageId: '',
      MovStowageNameEs: '',
      MovStowageName: '',
      Status: 1
    },
    titulo: ''
  }
}

//Methods
function submit() {
  try {
  this.isSubmit = true;
  this.$v.movimiento.$touch();
    if (this.$v.movimiento.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let MovStowageJson = {
      MovStowageId: this.movimiento.MovStowageId,
      MovStowageNameEs: this.movimiento.MovStowageNameEs,
      MovStowageName: this.movimiento.MovStowageName,
      Status: this.movimiento.Status
    };

    this.$http.put("MovStowage-update", MovStowageJson, { root: 'MovStowageJson' })
    .then(response => {
      if (response && response.status === 200) {
        const messageSuccess = response.data.data[0].Response;
        this.resetForm();
        this.$emit('submited');
        this.modalActive = false;
        this.notifySuccess({text: messageSuccess})
        this.isSubmit=false;
      }
    }).catch(err => {
      this.notifyError({text: err});
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function resetForm() {
  this.movimiento.MovStowageId = '';
  this.movimiento.MovStowageNameEs = '';
  this.movimiento.MovStowageName = '';
  this.movimiento.Status = 1;
  this.$v.$reset();
}
function setMovData(newMov) {
  let _lang = this.$i18n.locale;

  this.movimiento.MovStowageId = newMov.MovStowageId;
  this.movimiento.MovStowageNameEs = newMov.MovStowageNameEs;
  this.movimiento.MovStowageName = newMov.MovStowageName;
  this.movimiento.Status = newMov.FgActMovStowage ? 1 : 0;

  let name =_lang =='en' ? newMov.MovStowageName : newMov.MovStowageNameEs;
  this.titulo = this.$t('label.edit')+this.$t('label.stowageMovement')+`: ${name}`;
  this.$v.$touch();
}

//Computeds:
function statusSelectColor(){
  return this.movimiento.Status === 1;
}
function getTitle() {
  return this.titulo;
}
export default {
  name: 'edit-movimientos-modal',
  mixins: [
    MovimientoMixin,
    ModalMixin,
  ],
  props: {
    modal: Boolean,
    movData: {
      type: Object,
      default: () => { }
    }
  },
  data,
  validations: MovFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    setMovData,
  },
  computed: {
    statusSelectColor,
    getTitle
  },
  watch: {
    movData: function(newMov) {
      this.setMovData(newMov);
    }
  }
}
</script>
<style lang="scss">
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-mov {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>