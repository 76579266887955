<template>
  <div class="mb-3 mt-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CRow>
        <CCol sm="4" class="justify-content-start py-2">
          <CSelect
            :label="$t('label.group')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-auto', input:'col-sm-7'}"
            :options="computedTpGpoGeneral"
            @change="gpoSelectedFilter($event)"
            :value="TpCargoId"
            size="sm"
          />
      </CCol>
      <CCol sm="8" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateState"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.associatePackaging')}}
          </span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedTpGeneralPackaging"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
    >
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            square
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.packaging'),
              placement: 'top-end',
            }"
            @click="toggleShowModalEditBulkCargo(item.TpCargoId)"
          >
            <CIcon name="pencil"/>
          </CButton>
        </td>
      </template>
    </dataTableExtended>

    <modalEmbalaje
      :title="titleModal"
      :modal.sync="showModal"
      :editModal="editModal"
      @updated-modal="updateShowModalCreate"
      @refresh-data-table="getTpGeneralPackaging"
      :tpcargoSelcted="tpcargoSelcted"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalEmbalaje from './modal-embalaje';

function data() {
  return {
    itemsPackagingList: [],
    itemsGposPackaging: [],
    Loading: false,
    showModal: false,
    titleModal: '',
    editModal: false,
    tpcargoSelcted: '',
    TpCargoId: '',
  };
}

//methods
async function getTpCargoGeneral() {
    await this.$http
      .get('TpCargoGeneralApplyPackaging-list')
      .then(response => {
          let list = response.data.data;
          if (list.length != 0) {
              this.itemsGposPackaging = list;
          }
      })
      .catch((e) => {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
      });
    }) 
  }

  function gpoSelectedFilter(event) {
    this.TpCargoId = event.target.value;
    this.getTpGeneralPackaging()
  }
  
  async function getTpGeneralPackaging() {
    this.Loading = true;
    await this.$http
      .get('TpCargoGeneralPackagingList-by-TpCargoId',{TpCargoId: this.TpCargoId})
      .then((response) => {
        this.itemsPackagingList = response.data.data;
        this.Loading = false;
      })
      .finally(() => {
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  }
//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateState() {
  this.showModal = true;
  //this.titleModal = 'ASOCIAR EMBALAJE GRUPO CARGA GENERAL';
  this.titleModal = this.$t('label.associateGeneralGroupCargoPackaging');
}

function toggleShowModalEditBulkCargo(id) {
  this.tpcargoSelcted = id;
  this.showModal = true;
  //this.titleModal = 'EDITAR EMBALAJE GRUPO CARGA GENERAL';
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.associateGeneralGroupCargoPackaging');
  this.editModal = true;
}

//### ACtualiza el estado una vez cerrado el modal
function updateShowModalCreate(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.tpcargoSelcted = '';
}

//computed
function computedTpGpoGeneral(){
    if(this.itemsGposPackaging.length === 0){
        return [{
        value: '', 
        label: this.$t('label.all'),
        }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.all'),
      }]
      this.itemsGposPackaging.map((e)=>{
        chart.push({
            label: e.TpCargoName,
            value: e.TpCargoId,
        })    
      })
      return chart;
    }
  }

//### Funcion computed para formatear los datos de la tabla ###
function computedTpGeneralPackaging() {
  if (this.itemsPackagingList.length > 0) {
    return this.itemsPackagingList.map((item) => {
      let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      return {
        ...item,
        TpCargoName: item['TpCargoName'+_lang] ?? 'N/A',
        PackagingNameEs: item.PackagingNameEs ?? 'N/A',
        PackagingNameEn: item.PackagingNameEn ?? 'N/A',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

function fields(){
  return [
      { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center', },
      { key: 'TpCargoName', label: this.$t('label.group'),  _style:'width:20%;', _classes:"center-cell text-center" },
      { key: 'PackagingNameEs', label: `${this.$t('label.packaging')} (ES)`, _style:'width:20%;',_classes:"center-cell text-center"},
      { key: 'PackagingNameEn', label: `${this.$t('label.packaging')} (EN)`, _style:'width:20%;',_classes:"center-cell text-center"},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"center-cell text-center" },
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center"},
      { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" },
      {  key: 'options',
        label: '', 
      _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false,
      },
    ];
}


export default {
  name: 'embalajeCargaGeneral',
  data,
  props: {Tab: Number},
  computed: {
    computedTpGeneralPackaging,
    computedTpGpoGeneral,
    fields,
  },
  mixins: [GeneralMixin],
  methods: {
    getTpCargoGeneral,
    gpoSelectedFilter,
    getTpGeneralPackaging,
    updateShowModalCreate,
    toggleShowModalCreateState,
    toggleShowModalEditBulkCargo,
  },
  components: {
    modalEmbalaje,
  },
  watch:{
    Tab: async function (val){
      if(val == 2){
        this.Loading = true;
        await this.getTpCargoGeneral();
        await this.getTpGeneralPackaging();
      }
    }
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
