<template>
  <div>
		<loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
      	<CButton
          color="add"
          @click="(isEdit=false, ModalActive=true)"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;{{$t('label.AssociateCargoType')}}
        </CButton>
      </CCol>
			<CCol sm="12" lg="3">
				<CSelect
          :options="ComputedGroupOptions"
          v-model="GroupId"
          :label="$t('label.group')"
          :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-9'}"
          addLabelClasses="text-right"
          @change="GroupFilter"
        />
			</CCol>
			<CCol sm="12" lg="4">
        <CSelect
          :options="ComputedCargoTypeOptions"
          v-model="CargoTypeId"
          :label="$t('label.cargoType')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          @change="CargoTypeFilter"
        />
			</CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedList"
          :fields="fields"
          column-filter
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          pagination
          sorter
          :tableFilter="tableText.tableFilterText"
          :noItemsView="tableText.noItemsViewText"
        >
          <template #Status="{ item }">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton
                size="sm"
                color="edit"
                @click="EditCargoType(item)"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.AssociateCargoType')}`,
                  placement: 'top-end'
                }"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalCargoType
			:modal="ModalActive"
			:isEdit="isEdit"
			:CargoTypeItem="CargoTypeItem"
			@Close-Modal="(ModalActive=false, CargoTypeItem={})"
			@List-Update="(GroupId='', CargoTypeId='', ConsultServices())"
		/>
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalCargoType from './modal-cargo-type';

function data() {
  return {
		GroupId: '',
		GroupOptions: [],
    CargoTypeId: '',
		CargoTypeOptions: [],
    CargoTypeList: [],
		CargoTypeItem: {},
		ModalActive: false,
		isEdit: false,
    loading: false,
  };
}

//methods
function ConsultServices(GpoCargo, TpCargo) {
  this.loading = true;
  let requests = [];
  if (GpoCargo) {
    requests[0] = this.$http.ejecutar("GET", "GpoCargoEvent-list");
  }
  if (TpCargo) {
    requests[1] = this.$http.ejecutar("GET", "TpCargo-list-by-GpoCargoId", {GpoCargoId : this.GroupId});
  }
  requests[2] = this.$http.ejecutar("GET", "EventCargo-List", {GpoCargoId : this.GroupId, TpCargoId: this.CargoTypeId});
  Promise.all(requests)
    .then((responses) => {
      if (GpoCargo) {
        let GpoCargoEvent = responses[0].data.data;
        this.GroupOptions  = GpoCargoEvent&&GpoCargoEvent.length!=0 ? GpoCargoEvent : [];
      }
      if (TpCargo) {
        let TpCargoList = responses[1].data.data;
        this.CargoTypeOptions = TpCargoList&&TpCargoList.length!=0 ? TpCargoList : [];
      }
      let EventCargo = responses[2].data.data;
      this.CargoTypeList = EventCargo&&EventCargo.length!=0 ? EventCargo : [];
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function GroupFilter(event) {
  this.GroupId = event.target.value;
  this.ConsultServices(false, this.GroupId ? true : false);
}

function CargoTypeFilter(event) {
  this.CargoTypeId = event.target.value;
  this.ConsultServices();
}

function EditCargoType(item) {
	this.isEdit = true;
	this.CargoTypeItem = item;
	this.ModalActive = true;
}

//computed
function ComputedGroupOptions(){
  if(this.GroupOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.GroupOptions.map(function(e){
      chart.push({
        value: e.GpoCargoId, 
        label: e.GpoCargoName,
      })    
    })
    return chart;
  }
}

function ComputedCargoTypeOptions(){
  let _lang = this.$i18n.locale;
  if(this.CargoTypeOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.CargoTypeOptions.map(function(e){
      chart.push({
        value: e.TpCargoId, 
        label: _lang=='en' ? e.TpCargoNameEn : e.TpCargoNameEs,
      })    
    })
    return chart;
  }
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
     { label: this.$t('label.group'), key: 'GpoCargoName', _classes: 'text-center', _style: 'width:15%;',},
     { label: this.$t('label.cargoType'), key: 'TpCargoName', _classes: 'text-center', _style: 'width:15%;',},
		 { label: this.$t('label.Event'), key: 'EventName', _classes: 'text-center', _style: 'width:30%;',},
     { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:11%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:12%' },
     { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-center', _style:'width:12%' },
     { label: '', key: 'options', _style: 'min-width:45px;', sorter: false, filter: false },
  ];
}

function computedList() {
  let _lang = this.$i18n.locale;
  return this.CargoTypeList.map((item) => {
    return {
      ...item,
      TpCargoName: _lang=='en' ? item.TpCargoNameEn : item.TpCargoNameEs,
      EventName: _lang=='en' ? item.EventNameEn : item.EventNameEs,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

export default {
  name: 'cargo-type-index',
	props: {
		Tab: {
			type: Number,
			default: 0,
		}
	},
  components: { 
    ModalCargoType,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
		EditCargoType,
    ConsultServices,
    GroupFilter,
    CargoTypeFilter,
  },
  computed: {
		ComputedGroupOptions,
    ComputedCargoTypeOptions,
    fields,
    computedList,
  },
	watch: {
		Tab: function (val) {
			if (val==2) {
				this.ConsultServices(true)
			}
		}
	}
};
</script>