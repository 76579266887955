<template>
  <div class="mb-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateState"
          v-c-tooltip="{
            content: $t('label.group'),
            placement: 'top-end',
          }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedTpGeneralGrupo"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.group'),
              placement: 'top-end',
            }"
            @click="toggleShowModalEditGpo(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>
    <modalGrupo
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      @refresh-data-table="mountedTpGeneralGrupo"
      :GpoCargaSelected="gpoCargaSelected"
      :title="titleModal"
      :editModal="editModal"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalGrupo from './modal-grupo';

function data() {
  return {
    itemsGrupos: [],    
    Loading: false,
    showModal: false,
    editModal: false,
    gpoCargaSelected: {},
    titleModal:'',
  };
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%;',
      filter:false,
      _style: 'width:3%; text-align:center',
    },
    { key: 'TpCargoNameEs', label: `${this.$t('label.group')} (ES)`, _style:'width:20%;',_classes:"center-cell text-center" },
    { key: 'TpCargoNameEn', label: `${this.$t('label.group')} (EN)`, _style:'width:20%;',_classes:"center-cell text-center" },
    { key: 'TpCargoCode', label: this.$t('label.code'), _style:'width:20%;',_classes:"center-cell text-center" },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"center-cell text-center" },
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%; text-align:center;', _classes:"center-cell text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center"},
    { key: 'options',
      label: '', 
      _style: 'min-width: 50px; width:5%',
      sorter: false, 
      filter: false,
    },
  ];
}

async function mountedTpGeneralGrupo() {
  this.Loading = true;

  await this.$http
    .get('TpGeneralCargo-list')
    .then((response) => (this.itemsGrupos = response.data.data))
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedTpGeneralGrupo() {
  if (this.itemsGrupos.length > 0) {
    return this.itemsGrupos.map((item) => {
      return {
        ...item,
        TpCargoNameEs: item?.TpCargoNameEs ?? 'N/A',
        TpCargoNameEn: item?.TpCargoNameEn ?? 'N/A',
        TpCargoCode: item?.TpCargoCode ?? 'N/A',
        FormatedDate: item.TransaRegDate ?? 'N/A'
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
 }

function toggleShowModalCreateState() {
  this.showModal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.generalCargoGroup');
}

function updateShowModalCreate(event) {
  this.showModal = false;
  this.editModal = false;
  this.titleModal = "";
  this.gpoCargaSelected = {};
}

function toggleShowModalEditGpo(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.showModal = true;
  this.gpoCargaSelected = item;
  this.editModal = true;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.generalCargoGroup')+': '+this.gpoCargaSelected[`TpCargoName${_lang}`];
}

export default {
  name: 'grupoCargaGeneral',
  data,
  props: { Tab: Number},
  components: {
    modalGrupo,
  },
  mixins: [GeneralMixin],
  mounted: mountedTpGeneralGrupo,
  computed: {
    computedTpGeneralGrupo,
    fields,
  },
  methods: {
    toggleShowModalCreateState,
    updateShowModalCreate,
    mountedTpGeneralGrupo,
    toggleShowModalEditGpo,
  },
  watch:{
    Tab: async function (val){
      if(val == 0){
        await this.mountedTpGeneralGrupo();
      }
    }
  },
};
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
