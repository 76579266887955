<template>
  <CModalExtended
    :title="$t('label.filterBy')"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CRow class="options">
      <CCol
        :sm="{ size: items?.length < 3 ? '6' : '4' }"
        v-for="item in items"
        :key="item.YardViewPreferenceId"
      >
        <transition name="fade">
          <CCard v-if="loaded">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <h4><strong>
                    {{ currentLocale == 'es' ? item.YardViewPreferenceNameEs : item.YardViewPreferenceNameEn }}
                  </strong></h4>
                  <hr class="mt-1">
                </CCol>
                 <CCol
                  :key="item.YardViewPreferenceId"
                  sm="12"
                  class="checkbox-row"
                  :class="{
                    'checked-option': isSelectedAll(item.YardViewPreferenceId) ? true : null
                  }"
                >
                  <CInputCheckbox
                    custom
                    :checked="isSelectedAll(item.YardViewPreferenceId)"
                    :label="$t('label.selectAll')"
                    class="float-left"
                    @update:checked="selectAll($event, item)"
                  />
                </CCol>
                <CCol
                  v-for="option in optionsFormated(item.filters)"
                  :key="option.Name"
                  sm="12"
                  class="checkbox-row"
                  :class="{ 'checked-option': option.selected ? true : null }"
                >
                  <CInputCheckbox
                    custom
                    :checked="option.selected"
                    :label="`${option.Name}`"
                    class="float-left"
                    @update:checked="selectOption($event, option)"
                  />
                  <div class="float-right option-preview">
                    <div
                      class="option-color"
                      :style="{ backgroundColor: option.Color }"
                      v-if="option.FgColor"
                    ></div>
                    <div class="option-no-bg" v-if="validIcon(option.Name)"></div>
                    <div :class="slugName(option.Name)"></div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </transition>
      </CCol>
    </CRow>         
    <template #footer>
      <CButton
        shape="square"
        color="add"
        class="d-flex align-items-center"
        @click="submit"
      >
        <div>
          <CIcon name="checkAlt"/>
          <span class="ml-1">{{ $t('button.accept') }}</span>
        </div>
      </CButton>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import UpperCase  from '@/_validations/uppercase-directive';
  import { slugify } from '@/_helpers/funciones';
  
  //Data
  function data() {
    return {
      loaded: false,
      modalActive: this.modal,
      items: [],
      filters: [],
      selectedOptions: [],
      loading: false,
    }
  }
  
  //Methods
  function loadData() {
    this.loading = true;
  
    let peticiones = [];
  
    let parametros = {
      YardId: this.yardId,
    };
  
    this.items?.forEach(item => {
      if(item.ServiceUrl)
        peticiones.push(this.$http.ejecutar("GET", item.ServiceUrl, parametros))
    });
  
    Promise.all(peticiones)
    .then((responses) => {
      this.items?.forEach((item, index) => {
        if(item.ServiceUrl) {
          
          this.items[index].filters = responses[index].data.data?.map((opt) => {
            if(opt.Id){
              return Object.assign({}, opt, {
                PreferenceElement: this.items[index].YardViewPreferenceElement,
                [this.items[index].YardViewPreferenceElement]: opt.Id,
                FgColor: this.items[index].FgColor,
              });
            } else {
              return Object.assign({}, opt, {
                PreferenceElement: this.items[index].YardViewPreferenceElement,
                Element: this.items[index].Element,
                FgColor: this.items[index].FgColor,
              });
            }
          });
        }
      });
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.loading = false;
      this.loaded = true;
    });
  }
  
  function submit() {
    this.$emit('submit', this.selectedOptions);
    this.modalActive = false;
  }
  function toggle(newVal) {
    if(newVal){
      this.items = [...this.preferencias];
      this.selectedOptions = [...this.selecteds];
      this.loadData();
    }else{
      this.items = [];
      this.filters = [];
      this.selectedOptions = [];
      this.loaded = false;
      this.selectedAll = [];
    }
  
    this.modalActive = newVal;
  }
  function selectOption(event, option){
    if(event ==  true){
      this.selectedOptions.push(option);
    } else {
      let index = this.selectedOptions.findIndex((opt) => opt.Name === option.Name);
      this.selectedOptions.splice(index, 1);
    }
  }
  
  function optionsFormated(options) {
    return options?.map((item) => {
      let opt = this.selectedOptions.find((option) => option.Name === item.Name);
      
     return Object.assign({}, item, {
       selected: opt ? true : false,
     });
    }).filter((opt) => {
      return opt.Id != "6C6CE673-3797-4D11-839B-06C228D51CEF" && opt.Id != "40EC1BCA-4430-4CA0-8856-B0CD38AB7CA5";
    });
  }
  function slugName(name) {
    return slugify(name);
  }
  function validIcon(name) {
    let clase = slugify(name);
    return [
      'ft-flat-rack',
      'pl-plataforma',
      'ot-open-top',
      'tk-tanque',
      'st-estandar',
      'st-standard',
      'estandar',
      'hc-high-cube',
    ].includes(clase);
  }
  
  function selectAll(event, item) { //Revisar
    if(event) {
      let found = this.items.findIndex((pref) => pref.YardViewPreferenceId == item.YardViewPreferenceId);
  
      this.items[found].filters?.forEach(element => {
        let index = this.selectedOptions.findIndex((item) => item.Name === element.Name);
        if(index == -1)
          this.selectedOptions.push(element);
      });
    } else {
      let found = this.items.findIndex((pref) => pref.YardViewPreferenceId == item.YardViewPreferenceId);
  
      this.selectedOptions = this.selectedOptions.filter((item) => {
        return this.items[found].filters.findIndex((element) => element.Name === item.Name) == -1;
      });
    }
  }
  function isSelectedAll(id) {
    let index = this.items.findIndex((item) => item.YardViewPreferenceId == id);
    if(index == -1 || this.items[index].filters?.length == 0 || this.selectedOptions?.length == 0)
      return false;
  
    let count = 0;
    this.items[index].filters?.forEach((item) => {
      if(this.selectedOptions.findIndex((ele) => ele.Name == item.Name) != -1)
        count++;
    });
  
    return this.items[index].filters?.length == count;
  }

  function currentLocale() {
    return  this.$i18n.locale;
  }
  
  export default {
    name: 'preferences-modal',
    mixins: [],
    props: {
      modal: Boolean,
      preferencias: {
        type: Array,
        default: () => []
      },
      selecteds: {
        type: Array,
        default: () => []
      },
      yardId: {
        type: String,
        default: ''
      },
    },
    data,
    directives: UpperCase,
    methods: {
      toggle,
      loadData,
      selectOption,
      submit,
      optionsFormated,
      slugName,
      validIcon,
      selectAll,
      isSelectedAll
    },
    computed: {
      currentLocale,
      ...mapState({
        
      }),
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      }
    }
  }
  </script>
  
  <style scoped>
  .checkbox-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .checked-option {
    background-color: lightblue;
  }
  .options {
    min-height: 340px;
    max-height: 340px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  .option-preview {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .option-color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid #000;
  }
  .option-no-bg {
    width: 100%;
    height: 100%;
    background-color: lightgray;
    border: 1px solid #000;
  }
  
  /* Iconos */
  .flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar,
  .estandar,
  .st-standard {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  </style>