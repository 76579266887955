<template>
    <div class="mb-3">
        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.massivePositioning')}`}} </h6>
            </CCol>
        </CRow>
        <CRow class="mb-2">
            <CCol sm="12" lg="5">
                <CSelect
                    size="sm"
                    v-model="$v.Block.$model"
                    :options="BlockOptions"
                    @change="filterBlock($event.target.value)"
                    :label="$t('label.blocks')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.Block)"
                    :invalid-feedback="errorMessage($v.Block)"
                />
            </CCol>
            <CCol sm="12" lg="5">
                <div class="position-relative">
                    <div class="form-group form-row">
                        <label class="col-form-label col-sm-12 col-lg-3 col-form-label-sm text-right">
                            {{$t('label.bay')}} 
                        </label>
                        <div class="col-sm-12 col-lg-9 px-1">
                            <multiselect 
                            size="sm"
                            v-model.trim="$v.BayJson.$model"
                            :options="bayOptions"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :placeholder="$t('label.select')"
                            :label="'BlockAlias'"
                            track-by="IdX"
                            class="col-sm-12 p-0" 
                            :class="isValidmultiselect()"           
                            style="min-height: 33px;"
                            >
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                {{ values.length}} {{$t('label.bay')}}{{('(S)')}}
                                </span>
                            </template>
                            </multiselect> 
                        </div>
                    </div>
                </div>
            </CCol>
            <CCol sm="12" lg="2">
                <div class="d-flex align-items-start justify-content-start col-sm-12 col-lg-auto">
                    <CButton
                        color="watch"
                        square
                        size="sm"
                        class="mx-1"
                        @click="(getYardCargoInventaryBlockBay())" 
                    >
                    <CIcon name="cil-search"/>
                    </CButton>
                    <CButton
                        color="danger"
                        square
                        size="sm"
                        @click="(Reset())"
                    >
                    <CIcon name="cil-brush-alt"/>
                    </CButton>
                </div>
            </CCol>
        </CRow>
        <CTabs variant="tabs">
          <CTab :title="$t('label.information')">
            <CCard class="card-border">
              <form>
                <CRow class="px-5">
                    <CCol sm="12" lg="6">
                        <CSelect
                            size="sm"
                            v-model="$v.AreaId.$model"
                            :options="AuxiliarOptions"
                            :label="$t('label.auxiliaryArea')"
                            addLabelClasses="required text-right"
                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.AreaId)"
                            :invalid-feedback="errorMessage($v.AreaId)"
                        />
                    </CCol>
                    <CCol sm="12" lg="6">
                        <div class="form-group form-row">
                            <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.date')}`}}</label>
                            <div class="input-group col-sm-12 col-lg-8 mb-0">
                            <vue-datepicker 
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="false"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="$v.TransactionDate.$model"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                                class="w-100"
                            >
                                <template #input>
                                <CInput
                                    v-uppercase
                                    class="w-100 float-left mb-0"
                                    :value="TransactionDate"
                                    placeholder="DD/MM/YYYY HH:mm"
                                    :is-valid="hasError($v.TransactionDate)"
                                    size="sm"                      
                                >
                                    <template #append-content>
                                    <CIcon name="cil-calendar" />
                                    </template>
                                </CInput>
                                </template>
                                <template #icon-calendar>
                                <div style="display: none"></div>
                                </template>
                            </vue-datepicker>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm="12" lg="6">
                        <CSelect
                            size="sm"
                            v-model="$v.MachineId.$model"
                            :options="MachineOptions"
                            :label="$t('label.machine')"
                            addLabelClasses="required text-right"
                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                            :placeholder="$t('label.select')"
                            :is-valid="hasError($v.MachineId)"
                            :invalid-feedback="errorMessage($v.MachineId)"
                        />
                    </CCol>
                    <CCol sm="12" lg="6">
                        <CTextarea
                            size="sm"
                            rows="2"
                            :label="$t('label.observation')"
                            addLabelClasses="text-right"
                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                            :placeholder="$t('label.observation')"
                            v-model="$v.Observation.$model"
                            v-uppercase
                            :is-valid="hasError($v.Observation)"
                            :invalid-feedback="errorMessage($v.Observation)"
                        />
                    </CCol>
                </CRow>
              </form>
            </CCard>
          </CTab>
        </CTabs>
        <CRow>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #Checked-filter>
                        <td style="border-top:none" class="text-center">
                            <CInputCheckbox
                                custom
                                :label="$t('label.all')"
                                class="checkbook-filter"
                                :checked="selectedAll"
                                @update:checked="SelectAllContainer($event)"
                            />
                        </td>
                    </template>
                    <template #Checked="{ item }">
                        <td class="text-center">
                            <CInputCheckbox
                                custom
                                :checked="item.Checked"
                                class="checkbook-filter ml-1"
                                @update:checked="addRemoveContainer(item)"
                            />
                        </td>
                    </template>
                    <template #Observation="{ item }">
                        <td class="text-center">
                            <CButton
                                v-if="item.Checked"
                                square
                                size="sm"
                                color="watch"
                                class="d-flex align-items-center"
                                @click="AddObservation(item)"
                                v-c-tooltip="{
                                    content: $t('label.observation'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name='cil-comment-bubble'/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            <CCol v-if="$v.CheckedContainer.$error" sm="12" class="text-center text-invalid-feedback mb-2 mt-0">
                {{ $t('label.AtLeastContainerRequired') }}
            </CCol>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="Submit()"
                    :disabled="isSubmit"
                >
                    <CIcon name="checkAlt"/>{{ $t('button.accept')}}
                </CButton>
            </CCol>
        </CRow>
        <ModalObservationContainer
            :modal="ModalObservation"
            :SelectedContainer="SelectedContainer"
            @Close="ModalObservation=false, SelectedContainer={}"
            @Update="UpdateList"
        />
        <ModalPositionsList
            :modal="ModalPositions"
            :PositionsList="PositionsList"
            @Close="Update"
        />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import PositionsMassiveValidations from '@/_validations/yard-management/movements/positionsMassiveValidations';
import ModalObservationContainer from './modal-observation-container';
import ModalPositionsList from './modal-positions-list';
import moment from 'moment';

//data
function data() {
    return {
        selectedAll: false,
        ModalPositions: false,
        ModalObservation: false,
        isSubmit: false,
        Block: '',
        BlockList: [],
        AreaId: '',
        AreaList: [],
        BayJson: [],
        BayItems: [],
        MachineId: '',
        MachineList: [],
        TransactionDate: '',
        ValidPreviousDate: '',
        ValidLaterDate: '',
        previousDate: '',
        laterDate: '',
        Observation: '',
        CheckedContainer: [],
        dataContainer: [],
        SelectedContainer: {},
        PositionsList: {},
    };
}

//methods
async function getYardBlocks() {
    await this.$http.get('YardBlockListOcupped-by-YardId', { YardId: this.YardId })
        .then(response => {
            this.BlockList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'yard',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

async function getYardArea() {
    await this.$http.get('YardBlockAuxAreaList-by-YardId', { YardId: this.YardId })
        .then(response => {
            this.AreaList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'yard',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

async function getMachineYard() {
    await this.$http.get('MachineYard-list', {YardId: this.YardId, CompanyBranchId: this.CompanyBranchId})
        .then(response => {
        let List = response.data.data;
        this.MachineList = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
        })
}

async function getYardManagementCurrent() {
  await this.$http.get('YardManagementCurrent-by-Id', { YardCurrentId: this.YardCurrentId })
    .then(response => {
      if(response.data.data){
            let yardInfo = this.$store.state.yardManagement.yardData;
            yardInfo = {
                ...yardInfo,
                YardName : response.data.data[0].YardName,
                YardAlias : response.data.data[0].YardAlias,
                SquareMeters : response.data.data[0].SquareMeters,
                TpYardNameEn : response.data.data[0].TpYardNameEn,
                TpYardNameEs : response.data.data[0].TpYardNameEs,
                TeusCapacity : response.data.data[0].TeusCapacity,
                TeusOccupied : response.data.data[0].TeusOccupied,
                TeusAvailable: response.data.data[0].TeusAvailable,
                YardGpoName : response.data.data[0].YardGpoName,
                TodayReception : response.data.data[0].TodayReception,
                TodayDispatch : response.data.data[0].TodayDispatch
            }
            this.$store.state.yardManagement.yardData = yardInfo;
        }   
    }).catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
    })
}

async function getYardCargoReception() {
    await this.$http.get('YardCargoReception-by-YardId', { YardId: this.YardId })
        .then(response => {
            let List = response.data.data;
            this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
  }

function filterBlock(event) {
    this.Block = event 

    if(this.Block && this.Block!=''){
        let List = this.BlockList?.filter((e) => { return  this.Block === e.YardBlockId }) ;
        this.BayItems = List[0]?.BayJson ?? [];
        this.CheckedContainer = [];
        this.BayJson = [];
        this.dataContainer = [];
        this.$v.$reset();
    }
    else
        this.Reset();
    
}

function Submit() {
    try {
        this.$store.state.yardManagement.loading = true;
        this.isSubmit = true;
        this.$v.$touch();
        if (this.$v.$error){
            if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) <= this.ValidPreviousDate) {
                throw this.$t('label.ErrorAtaDatePleaseCheck');
            }
            if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate) {
                throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
            if (this.$v.CheckedContainer.$error) {
                throw this.$t('label.AtLeastContainerRequired');
            }
            throw this.$t('label.errorsPleaseCheck');
        }
        

  
        let CargoJson = {
            YardId: this.YardId,
            MachineId: this.MachineId,
            YardAreaId: this.AreaId,
            TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
            Observation: this.Observation,
            YardCargoJson: this.CheckedContainer.map(item =>{
                return {
                    YardCargoId: item?.YardCargoId,
                    Observation: item?.Observation ?? '',
                }
            })
        }

        this.$http.post('YardCargoMultiplePositioning', CargoJson, { root: 'CargoJson' })
            .then(async (response) => {
                if (response?.data?.data?.PositioningJson && response.data.data.PositioningJson.length != 0) {
                    await this.getYardManagementCurrent();
                    await this.getYardCargoReception();
                }

                await this.Reset();
                
                this.PositionsList = response.data.data;
                this.ModalPositions = true;
                this.$store.state.yardManagement.loading = false;
            }).catch((err) => {
                this.isSubmit = false;
                this.$store.state.yardManagement.loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    } catch (error) {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: error,
            type: "error"
        });
    }
}

function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
}

function SelectAllContainer(event) {
    this.selectedAll = event
    if (event) {
        let _lang = this.$i18n.locale;

        let data = [];

        Object.entries(this.dataContainer).forEach(([keyBlock, block]) => {
            Object.entries(block.StackJson).forEach(([keyStack, Stack]) => {
                Object.entries(Stack.HeightJson).forEach(([keyHeigth, heigth]) => {
                    let Checked = this.CheckedContainer.some((e)=> e.YardCargoId === heigth.YardCargoId ), Position = '';

                    heigth?.CurrentPositionJson?.map((item)=>{
                        Position = `${Position}${item?.SlotCode}`
                    })

                    data.push(

                        {
                            ...heigth,
                            Nro: Number.parseInt(block.Nro),
                            BlockCode: block?.BlockCode ?? '',
                            BayCode:  block?.BayCode ?? '',
                            SlotCode: Position != '' ? Position : '',
                            Area: heigth.YardAreaName ?? '',
                            TpCargoDetailCode: heigth.TpCargoDetailCode ?? '',
                            ComputedReception: heigth.ReceptionDate ? DateFormater.formatDateTimeWithSlash(heigth.ReceptionDate) : 'N/A',
                            CumputedAta: heigth.Ata ? DateFormater.formatDateTimeWithSlash(heigth.Ata) : '',
                            DaysInYard: _lang=='en' ? heigth.DaysInYardEn||'' : heigth.DaysInYardEs||'',
                            CumputedDate: heigth.TransaRegDate ? DateFormater.formatDateTimeWithSlash(heigth.TransaRegDate) : 'N/A',
                            Checked: Checked,
                            Weigth: heigth.Weigth ? NumberFormater.formatNumber(heigth.Weigth, 2) : '',
                            Observation: heigth.Observation ?? '',
                            _classes: 'color-gradient' ?? '',
                            _style: heigth.TpTransacEirColor ? 'background:'+heigth.TpTransacEirColor : ''
                        }
                    )
                });
            });
        });

        this.CheckedContainer = data;


    }else{
        this.CheckedContainer = [];
    }
}

function addRemoveContainer(item) {
    let data = [];

    let Container = this.CheckedContainer.some((e)=> e.YardCargoId === item.YardCargoId);

    this.dataContainer.map((block) => {

        block?.StackJson?.map((stack)=>{

            let back = false;

            if(!Container)
                for(let i = (stack.HeightJson.length-1); i>=0; i--){
                    if( stack.HeightJson[i].YardCargoId === item.YardCargoId || back ){
                        !this.CheckedContainer.some((e)=> e.YardCargoId === stack.HeightJson[i].YardCargoId) && data.push( stack.HeightJson[i]); 
                        back = true;
                    } 
                }
            else
                for(let i = 0; i<=stack.HeightJson.length-1; i++){
                    if( stack.HeightJson[i].YardCargoId === item.YardCargoId || back ){
                        this.CheckedContainer.some((e)=> e.YardCargoId === stack.HeightJson[i].YardCargoId) && data.push( stack.HeightJson[i]); 
                        back = true;
                    } 
                }
        })
    })
       
    if(!Container){
        
        this.CheckedContainer = this.CheckedContainer.concat(data);

        //Select All
        if (this.CheckedContainer.length == this.computedListContainer.length) {
            this.selectedAll = true;
        }
    }else{
        //DELETE
        data.map((item)=>{
            this.CheckedContainer = this.CheckedContainer.filter((e) => e?.YardCargoId != item.YardCargoId);
        })

        this.selectedAll = false;
    }
}

function AddObservation(item) {
    this.SelectedContainer = item;
    this.ModalObservation = true;
}

function UpdateList(Observation, YardCargoId) {
    this.CheckedContainer = this.CheckedContainer.map(item => {
        if (item.YardCargoId == YardCargoId) {
            item.Observation = Observation;
        }
        return item;
    });
    this.dataContainer = this.dataContainer.map(item => {
        if (item.YardCargoId == YardCargoId) {
            item.Observation = Observation;
        }
        return item;
    });
}

async function Update() {
    this.$store.state.yardManagement.loading = true;
    this.ModalPositions = false;
    this.PositionsList = {};
    await this.getYardBlocks();
    await this.getYardArea();
    await this.getMachineYard();
    this.$store.state.yardManagement.loading = false;
}

function Reset() {
    this.selectedAll = false;
    this.ModalPositions = false;
    this.ModalObservation = false;
    this.isSubmit = false;
    this.Block = '';
    this.AreaId = '';
    this.BayJson = [];
    this.BayItems = [];
    this.MachineId = '';
    this.TransactionDate = '';
    this.Observation = '';
    this.CheckedContainer = [];
    this.dataContainer = [];
    this.SelectedContainer = {};
    this.PositionsList = {};
    this.$v.$reset();
    this.CheckedContainer = [];
}

function isValidmultiselect() {
  if(this.rubroVal === true){ 
    return (this.BayJson == null || this.BayJson == "") ? 'valido' : 'valido';
  }
}

function getYardCargoInventaryBlockBay() {

    if(this.Block === '' || this.BayJson.length === 0){
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.youMustChooseABlockAndBay'),
            type: "error"
        });

        return false;
    }

    this.$store.state.yardManagement.loading = true;    

    let ReportJson = {
        "BayJson": this.BayJson.map((bay)=> {return {BayCode: bay.BlockAlias, YardBlockId: this.Block} }),
    };

    this.$http.post('YardCargoInventary-by-BlockBay',ReportJson, { root: 'ReportJson'})
        .then(response => {
            let List = response.data.data ?? [];
            this.dataContainer = List[0]&&Object.entries(List[0]).length>1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}

  
//computed
function BlockOptions() {
    if(this.BlockList.length === 0){
        return [{
            label: this.$t('label.select'),
            value: ''
        }];
    }else{
        let chart = [{
            label: this.$t('label.select'),
            value: ''
        }];
        this.BlockList.map(function(e){
            chart.push({
                label: e.BlockAlias,
                value: e.YardBlockId,
            })    
        })
        return chart;
    };
}

function bayOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.BayItems.map(function(e){
    chart.push({
      value: e.IdX, 
      label: e.BlockAlias
    })    
  })
  return this.BayItems;
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function AuxiliarOptions() {
  if(this.AreaList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.AreaList.map(function(e){
      chart.push({
        value: e.YardAreaId,
        label: e.YardAreaName,
      })
    })
    return chart;
  }
}

function computedListContainer() {
    let _lang = this.$i18n.locale,  data = [];
    let cont = 0

    Object.entries(this.dataContainer).forEach(([keyBlock, block]) => {
        Object.entries(block.StackJson).forEach(([keyStack, Stack]) => {
            Object.entries(Stack.HeightJson).forEach(([keyHeigth, heigth]) => {
                let Checked = this.CheckedContainer.some((e)=> e.YardCargoId === heigth.YardCargoId ), Position = '';

                heigth?.CurrentPositionJson?.map((item)=>{
                    Position = `${Position}${item?.SlotCode}`
                })

                cont++;

                data.push(

                    {
                        ...heigth,
                        Nro: cont,
                        BlockCode: block?.BlockCode ?? '',
                        BayCode:  block?.BayCode ?? '',
                        SlotCode: Position != '' ? Position : '',
                        Area: heigth.YardAreaName ?? '',
                        TpCargoDetailCode: heigth.TpCargoDetailCode ?? '',
                        ComputedReception: heigth.ReceptionDate ? DateFormater.formatDateTimeWithSlash(heigth.ReceptionDate) : 'N/A',
                        CumputedAta: heigth.Ata ? DateFormater.formatDateTimeWithSlash(heigth.Ata) : '',
                        DaysInYard: _lang=='en' ? heigth.DaysInYardEn||'' : heigth.DaysInYardEs||'',
                        CumputedDate: heigth.TransaRegDate ? DateFormater.formatDateTimeWithSlash(heigth.TransaRegDate) : 'N/A',
                        Checked: Checked,
                        Weigth: heigth.Weigth ? NumberFormater.formatNumber(heigth.Weigth, 2) : '',
                        Observation: heigth.Observation ?? '',
                        _classes: 'color-gradient' ?? '',
                        _style: heigth.TpTransacEirColor ? 'background:'+heigth.TpTransacEirColor : ''
                    }
                )
            });
        });
    });

    return data;
}

function fields(){ 
    return [
        { key: 'Checked', label: '', _style: 'min-width:45px;', sorter: false, },
        { key: 'Observation', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _classes: 'text-center',  _style: 'min-width:45px;', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
        { key: 'BlockCode', label: this.$t('label.block'), _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'BayCode', label: 'BAY', _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'HeightCode', label: this.$t('label.heights'), _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'SlotCode', label: this.$t('label.CurrentPosition'), _classes: 'text-center', _style:'min-width:180px'},
        { key: 'Area', label: 'AREA', _classes: 'text-center', _style: 'min-width:100px;'},
        { key: 'VisitDs', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`, _classes: 'text-center', _style: 'min-width: 235px;'},
        { key: 'CumputedAta', label: 'ATA', _classes: 'text-center', _style: 'min-width: 160px;'},
        { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
        { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
        { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _classes: 'text-center'},
        { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-center', _style: 'min-width: 160px;'},
        { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 135px;'},
        { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
        { key: 'DaysInYard', label: this.$t('label.daysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
        { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
        { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
        { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
}

export default{
    name: 'equipment',
    data,
    mixins: [General],
    components: {
        ModalObservationContainer,
        ModalPositionsList,
    },
    validations(){
        return PositionsMassiveValidations(this.ValidPreviousDate, this.ValidLaterDate);
    },
    directives: UpperCase,
    methods: {
        getYardBlocks,
        getYardArea,
        getMachineYard,
        getYardManagementCurrent,
        getYardCargoReception,
        Submit,
        validateDateRange,
        SelectAllContainer,
        addRemoveContainer,
        AddObservation,
        UpdateList,
        Update,
        Reset,
        isValidmultiselect,
        filterBlock,
        getYardCargoInventaryBlockBay,
    },
    computed: {
        BlockOptions,
        bayOptions,
        MachineOptions,
        AuxiliarOptions,
        fields,
        computedListContainer,
        ...mapState({
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            YardId: state => state.yardManagement.yardData.YardId,
            YardCurrentId: state => state.yardManagement.yardData.YardCurrentId,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
        })
    },
    watch:{
        dropItemMovementList: async function (NewVal) {
            if (NewVal==11) {
                this.$store.state.yardManagement.loading = true;
                await this.Reset();
                await this.getYardBlocks();
                await this.getYardArea();
                await this.getMachineYard();
                
                this.laterDate = new Date();
                this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
                this.ValidPreviousDate = process.env.VUE_APP_START_DATE_IN_PATIO;
                this.ValidLaterDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
                this.TransactionDate = moment(new Date()).format('DD/MM/YYYY HH:mm');

                this.$store.state.yardManagement.loading = false;
            }
        },
        TransactionDate: async function(val){
            try{
                if(val){
                    if (DateFormater.formatDateTimeWithoutSlash(val) <= this.ValidPreviousDate) {
                        throw this.$t('label.ErrorAtaDatePleaseCheck');
                    }
                    if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
                        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                    }
                }
            }catch(error){
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: error,
                    type: "error"
                });
            }
        },
    }
}
</script>