import {
    onlyMayusText,
    codeDelay,
    onlyAlphanumeric2
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    alphaNum,
    
} from "vuelidate/lib/validators";


export default () => {
    return {
        ResponsibleNameEn:{
            required,
            //minLength: minLength(4),
            maxLength: maxLength(250),
            onlyAlphanumeric2
        },
        ResponsibleNameEs:{
            required,
            //minLength: minLength(4),
            maxLength: maxLength(250),
            onlyAlphanumeric2
        },
    }
}