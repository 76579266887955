<template>
  <div>
    <CRow class="justify-content-center mt-3">
      <CCol sm="12" lg="5">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="10" class="px-1 ">
              <CInput
                size="sm"
                v-uppercase
                :label="`${$t('label.eir')}`"
                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                :placeholder="`${$t(`${$t('label.eir')}`)}`"
                addLabelClasses="text-right"
                v-model.trim="$v.TpEirName.$model"
                :is-valid="hasError($v.TpEirName)"
                :invalid-feedback="errorMessage($v.TpEirName)"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="watch"
                  square
                  size="sm"
                  class="mr-1"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="eye"/>
              </CButton>
            </CCol>

            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="add"
                  square
                  size="sm"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="cil-image"/>
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="5">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="4" class="px-1 align-self-center">
              <label class="col-form-label col-form-label-sm col-sm-12 col-sm-12 text-right px-0">{{$t('label.machine')}}</label>
            </CCol>
            <CCol sm="12" lg="8" class="px-1 align-self-center">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                :options="filter"
                style="width: 100%; font-size: 11px; margin-bottom: 0;"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow class="justify-content-center">
        <CCol sm="12" lg="5">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="10" class="px-1 ">
              <CInput
                size="sm"
                v-uppercase
                :label="`${$t('label.sidunea')}`"
                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                :placeholder="`${$t(`${$t('label.sidunea')}`)}`"
                addLabelClasses="text-right"
                v-model.trim="$v.TpEirName.$model"
                :is-valid="hasError($v.TpEirName)"
                :invalid-feedback="errorMessage($v.TpEirName)"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="watch"
                  square
                  size="sm"
                  class="mr-1"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="eye"/>
              </CButton>
            </CCol>

            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="add"
                  square
                  size="sm"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="cil-image"/>
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="5">
          <CInput
            size="sm"
            v-uppercase
            :label="`${$t('label.transport')}`"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :placeholder="`${$t('label.transport')}`"
            addLabelClasses="text-right"
            v-model.trim="$v.TpEirName.$model"
            :is-valid="hasError($v.TpEirName)"
            :invalid-feedback="errorMessage($v.TpEirName)"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow class="justify-content-center">
        <CCol sm="12" lg="5">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="10" class="px-1 ">
              <CInput
                size="sm"
                v-uppercase
                :label="`${$t('label.dua')}`"
                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                :placeholder="`${$t(`${$t('label.dua')}`)}`"
                addLabelClasses="text-right"
                v-model.trim="$v.TpEirName.$model"
                :is-valid="hasError($v.TpEirName)"
                :invalid-feedback="errorMessage($v.TpEirName)"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="watch"
                  square
                  size="sm"
                  class="mr-1"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="eye"/>
              </CButton>
            </CCol>

            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="add"
                  square
                  size="sm"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="cil-image"/>
              </CButton>
            </CCol>
          </CRow>
        </CCol>

        <CCol sm="12" lg="5">
          <CInput
            size="sm"
            v-uppercase
            :label="`${$t('label.driver')}`"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :placeholder="`${$t('label.driver')}`"
            addLabelClasses="text-right"
            v-model.trim="$v.TpEirName.$model"
            :is-valid="hasError($v.TpEirName)"
            :invalid-feedback="errorMessage($v.TpEirName)"
          >
          </CInput>
        </CCol>
      </CRow>

      <CRow class="justify-content-center">
        <CCol sm="12" lg="5">
          <CRow class="justify-content-center">
            <CCol sm="12" lg="10" class="px-1 ">
              <CInput
                size="sm"
                v-uppercase
                :label="`${$t('label.ticketOut')}`"
                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                :placeholder="`${$t('label.ticketOut')}`"
                addLabelClasses="text-right"
                v-model.trim="$v.TpEirName.$model"
                :is-valid="hasError($v.TpEirName)"
                :invalid-feedback="errorMessage($v.TpEirName)"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="watch"
                  square
                  size="sm"
                  class="mr-1"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="eye"/>
              </CButton>
            </CCol>

            <CCol sm="12" lg="1" class="px-0 py-0">
              <CButton
                  color="add"
                  square
                  size="sm"
                  style="padding: 0.15rem 0.4rem;"
                  v-c-tooltip="{
                      content: $t(''),
                      placement: 'top-end'
                  }"
              >
                <CIcon name="cil-image"/>
              </CButton>
            </CCol>
          </CRow>
        </CCol>

        <CCol sm="12" lg="5">
          <CInput
            size="sm"
            v-uppercase
            :label="`${$t('label.vehicle')}`"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :placeholder="`${$t('label.vehicle')} 3`"
            addLabelClasses="text-right"
            v-model.trim="$v.TpEirName.$model"
            :is-valid="hasError($v.TpEirName)"
            :invalid-feedback="errorMessage($v.TpEirName)"
          >
          </CInput>
        </CCol>
      </CRow>
  </div>
</template>
<script>

  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import { required, maxLength, helpers } from 'vuelidate/lib/validators';
  import UpperCase from '@/_validations/uppercase-directive';

  //DATA-------------------------
  function data() {
    return {
      isSubmit: false,
      TpEirName: '',
      apiStateLoading: false,
      FeaturesDescription:'',
      rubros: [],
      imdg: [],
      isLoadingMulti: false,
      tabIndex: 0,
    };
  }


//computed


export default {
  name: 'dispatch-form-index',
  components: {
   },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations: {
    TpEirName: { required, maxLength: maxLength(30) },
  },
  directives: UpperCase,
  methods: {
  },
  computed: {
  },
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}

.Container-items{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
</style>