<template>
    <div>
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars"/>
        <CCollapse :show="$store.state.maquina.CollapseCarga">  
            <CRow class="m-3">
                <CCol sm="12">  
                    <CustomTabs>   
                        <CustomTab :title="$t('label.containers')" :disabled="apiStateLoading">
                            <CRow class="mt-3 mb-3">
                                <CCol sm="5" class="justify-content-start">
                                    <CSelect
                                        :label="$t('label.typeMachine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"
                                        :options="optionListType"
                                        :disabled="apiStateLoading"
                                        :value.sync="selectType"
                                        @input="typeChange($event.target.value)"
                                    />
                                </CCol>
                                <CCol sm="5">
                                    <CSelect
                                        :label="$t('label.machine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"                        
                                        :options="optionListMachine"
                                        :disabled="apiStateLoading"
                                        :value.sync="MachineId"
                                        @input="machineChange($event.target.value)"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsTpCargo"
                                        :fields="fieldsTpCargo"
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        ref="table-tpcargo-modulo"
                                        sorter
                                        column-filter
                                        hover
                                         
                                    >
                                        <template #TpCargoId="{ index}">
                                            <td class="center-cell">
                                                {{index+1}}
                                            </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <!--<CInputRadio
                                                    name="padres"
                                                    color="success"
                                                    :checked.sync="item.status"
                                                    @change.native="changeTpCargo(item)"
                                                >
                                                </CInputRadio>-->
                                                <CSwitch
                                                    color="success"
                                                    variant="opposite"
                                                    :checked.sync="item.status"
                                                    @change.native="changeTpCargo(item)"
                                                ></CSwitch>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                                <CCol sm="4">
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsTpCargoDetail"
                                        :fields="fieldsTpDetailCargo"
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        ref="table-tpdetailscargo-modulo"
                                        sorter
                                        column-filter
                                        hover
                                         
                                    >
                                        <template #TpCargoDetailId="{ index}">
                                            <td class="center-cell">
                                                {{index+1}}
                                            </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CSwitch
                                                    color="success"
                                                    variant="opposite"
                                                    :checked.sync="item.status"
                                                    @change.native="changeTpDetailCargo(item)"
                                                ></CSwitch>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                                <CCol sm="4">
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsTpCargoDetailAsociacion"
                                        :fields="fieldsAsociados"
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        ref="table-tpdetailscargoasociacion-modulo"
                                        sorter
                                        column-filter
                                        hover
                                         
                                    >
                                        <template #TpCargoDetailId="{ index}">
                                            <td class="center-cell">
                                                {{index+1}}
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow><!--
                            <CRow>
                                <CCol sm="12" class="d-flex justify-content-end mb-3">
                                    <CButton
                                    color="add"
                                    class="m-2"
                                    @click="sendDataCargo()"
                                    :disabled="apiStateLoading"
                                    >
                                    <div v-if="apiStateLoading">
                                        <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                    </div>

                                    <div v-if="!apiStateLoading">
                                        <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                    </div>
                                    </CButton>
                                    <CButton
                                    color="wipe"
                                    @click="$store.commit('maquina/collapseCarga',false)" 
                                    class="m-2"
                                    :disabled="apiStateLoading"
                                    >
                                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                                    </CButton>
                                </CCol>
                            </CRow> -->
                        </CustomTab>
                        <CustomTab :title="$t('label.bulk')" :disabled="apiStateLoading">
                            <CRow class="mt-3 mb-3">
                                <CCol sm="5" class="justify-content-start">
                                    <CSelect
                                        :label="$t('label.typeMachine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"                       
                                        :options="optionListType"
                                        :disabled="apiStateLoading"
                                        :value.sync="selectType"
                                        @input="typeChange($event.target.value)"
                                    />
                                </CCol>
                                <CCol sm="5">
                                    <CSelect
                                        :label="$t('label.machine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"                        
                                        :options="optionListMachine"
                                        :disabled="apiStateLoading"
                                        :value.sync="MachineId"
                                        @input="machineChange($event.target.value)"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsTpBulkCargo"
                                        :fields="fieldsTpCargo"
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        ref="table-tpcargo-modulo"
                                        sorter
                                        column-filter
                                        hover
                                         
                                    >
                                        <template #TpCargoId="{ index}">
                                            <td class="center-cell">
                                                {{index+1}}
                                            </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CSwitch
                                                    color="success"
                                                    variant="opposite"
                                                    :checked.sync="item.status"
                                                ></CSwitch>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                            <!--<CRow>
                                <CCol sm="12" class="d-flex justify-content-end mb-3">
                                    <CButton
                                    color="add"
                                    class="m-2"
                                    @click="sendDataCargo()"
                                    :disabled="apiStateLoading"
                                    >
                                    <div v-if="apiStateLoading">
                                        <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                    </div>

                                    <div v-if="!apiStateLoading">
                                        <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                    </div>
                                    </CButton>
                                    <CButton
                                    color="wipe"
                                    @click="$store.commit('maquina/collapseCarga',false)" 
                                    class="m-2"
                                    :disabled="apiStateLoading"
                                    >
                                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                                    </CButton>
                                </CCol>
                            </CRow>-->
                        </CustomTab>
                        <CustomTab :title="$t('label.generalCargo')" :disabled="apiStateLoading">
                            <CRow class="mt-3 mb-3">
                                <CCol sm="5" class="justify-content-start">
                                    <CSelect
                                        :label="$t('label.typeMachine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"                        
                                        :options="optionListType"
                                        :disabled="apiStateLoading"
                                        :value.sync="selectType"
                                        @input="typeChange($event.target.value)"
                                    />
                                </CCol>
                                <CCol sm="5">
                                    <CSelect
                                        :label="$t('label.machine')"
                                        :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                                        add-label-classes="text-right"
                                        size="sm"                       
                                        :options="optionListMachine"
                                        :disabled="apiStateLoading"
                                        :value.sync="MachineId"
                                        @input="machineChange($event.target.value)"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsTpGeneralCargo"
                                        :fields="fieldsTpCargo"
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        ref="table-tpcargo-modulo"
                                        sorter
                                        column-filter
                                        hover
                                         
                                    >
                                        <template #TpCargoId="{ index}">
                                            <td class="center-cell">
                                                {{index+1}}
                                            </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CSwitch
                                                    color="success"
                                                    variant="opposite"
                                                    :checked.sync="item.status"
                                                ></CSwitch>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow><!--
                            <CRow>
                                <CCol sm="12" class="d-flex justify-content-end mb-3">
                                <CButton
                                color="add"
                                class="m-2"
                                @click="sendDataCargo()"
                                :disabled="apiStateLoading"
                                >
                                <div v-if="apiStateLoading">
                                    <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                </div>

                                <div v-if="!apiStateLoading">
                                    <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                                </div>
                                </CButton>
                                <CButton
                                color="wipe"
                                @click="$store.commit('maquina/collapseCarga',false)" 
                                class="m-2"
                                :disabled="apiStateLoading"
                                >
                                <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                                </CButton>
                            </CCol>
                            </CRow> -->
                        </CustomTab>
                    </CustomTabs>
                </CCol>
            </CRow>
            <CRow><!-- px-4 mt-3 mb-2-->
                <CCol sm="12" class="d-flex justify-content-end  footer-carga">
                    <CButton
                        color="add"
                        class="m-2"
                        @click="sendDataCargo()"
                        :disabled="apiStateLoading"
                    >
                        <div v-if="apiStateLoading">
                            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                        </div>

                        <div v-if="!apiStateLoading">
                            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                        </div>
                    </CButton>
                    <CButton
                        color="wipe"
                        @click="$store.commit('maquina/collapseCarga',false)" 
                        class="m-2"
                        :disabled="apiStateLoading"
                    >
                        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                    </CButton>
                </CCol>
            </CRow> 
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ENUM from '@/_store/enum';
    import GeneralMixin from '@/_mixins/general';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    function fieldsTpCargo() { 
        return [
            { key: 'TpCargoId', label: '#',_style: 'width:10%; text-align:center',filter: false},
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:100px;', _classes:'text-uppercase' },
            { key: 'TpCargoCode',label: this.$t('label.acronym'), _style:'width:20%;', _classes:'text-uppercase text-center'},
            { key: 'acciones', label: '', _style: 'width:10%', sorter: false, filter: false, _classes:'text-center' }
        ]
    }
    function fieldsTpDetailCargo(){ 
        return [
            { key: 'TpCargoDetailId', label: '#',filter: false,_style: 'width:1%; text-align:center;',},
            { key: 'TpCargoDetailName',label: this.$t('label.description'), _style:'min-width:100px;', _classes:'text-uppercase' },
            { key: 'acciones', label: '', _style: 'width:1%', sorter: false, filter: false, _classes:'text-center' }
        ]
    }
    function fieldsAsociados() {
        return [
            { key: 'TpCargoDetailId', label: '#',filter: false,_style: 'width:1%; text-align:center;', },
            { key: 'TpCargoDetailName',label: this.$t('label.associatedEquipment'), _style:'min-width:100px;',filter: false, _classes:'text-center' },
        ]
    }

    //data
    function data() {
        return {
            MachineOperations: [],
            MachineCargoJson:{
                MachineId:'',
                TpCargoJson:[],
                TpBulkCargo:[],
                TpGeneralCargo:[]
            },
            selectType:'',
            MachineId:'',
            isLoading: false
        }   
    }
    //method
    function sendDataCargo(){
        if(this.MachineCargoJson.MachineId === '' || this.MachineCargoJson.MachineId === null || this.MachineCargoJson.MachineId === undefined){
            this.$notify({
                group: 'container',
                type: 'error',
                ignoreDuplicates:false,
                title: 'ERROR',
                text: "Seleccione la maquina"
            });
        }else{
            const MachineCargoJson = {
                MachineId:this.MachineId,
                TpCargoJson:this.MachineCargoJson.TpCargoJson,
                TpBulkCargo:this.MachineCargoJson.TpBulkCargo,
                TpGeneralCargo:this.MachineCargoJson.TpGeneralCargo,
            }

            const { dispatch } = this.$store;
            dispatch('maquina/sendDataCargoMachine', { MachineCargoJson });
        }
    }
    function changeTpCargo(e){
        if(!e.status){
            e.TpCargoDetailJson.map(function(t){
                t.status = false;
            })
            /*this.MachineCargoJson.TpCargoJson.map(function(x){
                if(x.TpCargoId !== e.TpCargoId){
                    x.status = false;
                    x.TpCargoDetailJson.map(function(y){
                        y.status = false
                    })
                }
            })*/
        }/*else{
            this.MachineCargoJson.TpCargoJson.map(function(x){
                x.status = false;
                x.TpCargoDetailJson.map(function(y){
                    y.status = false
                })
            })
        }*/
    }
    function machineChange(e){
        this.MachineId = e;
        if(e !== ''){
            this.MachineCargoJson.MachineId = e;

            const Index = this.myDataMachine.map(function(e) {
                return e.MachineId; 
            }).indexOf(this.MachineCargoJson.MachineId);

            const TpBulkCargo = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpBulkCargo;
            const TpCargoJson = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpCargoJson;
            const TpGeneralCargo = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpGeneralCargo;
            const _this = this.MachineCargoJson;
            if(TpBulkCargo.length > 0){
                TpBulkCargo.map(function(e){
                    _this.TpBulkCargo.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                        }
                    })
                })
            }
            if(TpGeneralCargo.length > 0){
                TpGeneralCargo.map(function(e){
                    _this.TpGeneralCargo.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                        }
                    })
                })
            }
            if(TpCargoJson.length > 0){
                TpCargoJson.map(function(e){
                    _this.TpCargoJson.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                            e.TpCargoDetailJson.map(function(x){
                                i.TpCargoDetailJson.map(function(y){
                                    if(y.TpCargoDetailId === x.TpCargoDetailId){
                                        y.status = x.status
                                    }
                                })
                            })
                        }
                    })
                })
            }
            this.MachineCargoJson = _this;
        }
    }
    function getDataInformation(){
        if(this.$store.state.contenedores.filtroTipoMachine !== ''){
            this.selectType = this.$store.state.contenedores.filtroTipoMachine;
        }
        if(this.idState !== 0){
            const Index = this.myDataMachine.map(function(e) {
                return e.MachineId; 
            }).indexOf(this.idState);

            this.MachineCargoJson.MachineId = this.idState;
            this.MachineId = this.idState;
            this.selectType = this.myDataMachine[Index].TpMachineId;
            
            const TpBulkCargo = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpBulkCargo;
            const TpCargoJson = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpCargoJson;
            const TpGeneralCargo = this.myDataMachine[Index].MachineCargoJson.MachineCargoJson[0].TpGeneralCargo;
            const _this = this.MachineCargoJson;
            if(TpBulkCargo.length > 0){
                TpBulkCargo.map(function(e){
                    _this.TpBulkCargo.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                        }
                    })
                })
            }
            if(TpGeneralCargo.length > 0){
                TpGeneralCargo.map(function(e){
                    _this.TpGeneralCargo.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                        }
                    })
                })
            }
            if(TpCargoJson.length > 0){
                TpCargoJson.map(function(e){
                    _this.TpCargoJson.map(function(i){
                        if(i.TpCargoId === e.TpCargoId){
                            i.status = e.status
                            e.TpCargoDetailJson.map(function(x){
                                i.TpCargoDetailJson.map(function(y){
                                    if(y.TpCargoDetailId === x.TpCargoDetailId){
                                        y.status = x.status
                                    }
                                })
                            })
                        }
                    })
                })
            }
            this.MachineCargoJson = _this;
        }
    }
    function changeTpDetailCargo(e){
        //console.log(e);
    }
    function typeChange(value){
        this.selectType = value;
    }
    function getDataTpCargo(){
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        try {
            this.$http.ejecutar('GET', 'TpCargo-list', { Filter: 'ACTIVO' }, '').then(response => {
                if(response.status === 200){
                    const data = response.data.data;
                    if(data.length > 0){
                        let chartPadre = [];
                        data.map(function(e) {
                            if(e.FgActTpCargo){
                                let chart = [];
                                if(e.TpCargoDetailJson.length > 0){
                                    e.TpCargoDetailJson.map(function(t){
                                        chart.push({
                                            TpCargoId:e.TpCargoId,
                                            TpCargoDetailId:t.TpCargoDetailId,
                                            TpCargoDetailName:t.TpCargoDetailName,
                                            Size:t.Size,
                                            status:false
                                        })
                                    })
                                }
                                chartPadre.push({
                                    TpCargoId:e.TpCargoId,
                                    TpCargoName:e.TpCargoName,
                                    TpCargoCode:e.TpCargoCode,
                                    status:false,
                                    TpCargoDetailJson:chart
                                })
                            }
                        })
                        this.MachineCargoJson.TpCargoJson = chartPadre;
                    }
                    this.$http.ejecutar('GET', 'TpGeneralCargo-list', { Filter: 'ACTIVO' }, '').then(cargageneral => {
                        if(cargageneral.status === 200){
                            const dataGeneral = cargageneral.data.data;
                            if(dataGeneral.length > 0){
                                let chatGeneral = [];
                                dataGeneral.map(function(e){
                                    if(e.FgActTpCargo){
                                        chatGeneral.push({
                                            TpCargoId:e.TpCargoId,
                                            TpCargoName:e.TpCargoName,
                                            TpCargoCode:e.TpCargoCode,
                                            status:false
                                        })
                                    }
                                })
                                this.MachineCargoJson.TpGeneralCargo = chatGeneral;
                            }
                            this.$http.ejecutar('GET', 'BulkCargo-list', { Filter: 'ACTIVO' }, '').then(cargabulk => {
                                if(cargabulk.status === 200){
                                    const dataBulk = cargabulk.data.data;
                                    if(dataBulk.length > 0){
                                        let chatBulk = [];
                                        dataBulk.map(function(e){
                                            if(e.FgActTpCargo){
                                                chatBulk.push({
                                                    TpCargoId:e.TpCargoId,
                                                    TpCargoName:e.TpCargoName,
                                                    TpCargoCode:e.TpCargoCode,
                                                    status:false
                                                })
                                            }
                                        })
                                        this.MachineCargoJson.TpBulkCargo = chatBulk;
                                    }
                                    this.getDataInformation();
                                    this.$store.state.maquina.apiStateForm = ENUM.INIT;
                                }else{
                                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('maquina/collapseCarga',false);
                                }
                            }).catch(err => {
                                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                this.$store.commit('maquina/messageMutation', err);
                                this.$store.commit('maquina/collapseCarga',false);
                            });
                        }else{
                            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                            this.$store.commit('maquina/collapseCarga',false);
                        }
                    }).catch(err => {
                        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                        this.$store.commit('maquina/messageMutation', err);
                        this.$store.commit('maquina/collapseCarga',false);
                    });
                }else{
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                    this.$store.commit('maquina/collapseCarga',false);
                }
            }).catch(err => {
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
                this.$store.commit('maquina/collapseCarga',false);
            });
        } catch (error) {
            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            this.$store.commit('maquina/messageMutation', error);
            this.$store.commit('maquina/collapseCarga',false);
        }
    }

function getDataMachine(tpMachine,condition) {
  this.isLoading = true;
  let valores = {
    MachineJson:[
      {
      CompanyBranchId:this.branch.CompanyBranchId, 
      TpMachineId: '',
     // TpMachineId: tpMachine ? tpMachine.TpMachineId : '',
      MachineConditionId: '',
      }
    ]
    };

  this.$http.get("MachineOperation-list", { MachineJson: valores, Filter: 'ACTIVO' })
  .then(response => {
    this.MachineOperations = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.error')+'!',
      text: err,
      type: "error"
    });
  }).then(() => {
   this.isLoading = false;
  });
}

    //computed
    function optionListType(){
        if(this.myDataMachineTp.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            let _lag = this.$i18n.locale;
            this.myDataMachineTp.map(function(e){
                if(e.FgActTpMachine){
                    chart.push({
                        value: e.TpMachineId, 
                        label: _lag=='en' ? e.TpMachineNameEn : e.TpMachineNameEs,
                    })
                }
            })
            return chart;
        }
    }

    function optionListMachine(){
        //if(this.myDataMachine.length === 0 || this.selectType === ''){
        if(this.MachineOperations.length === 0 || this.selectType === ''){

            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            let selectType = this.selectType;
            this.MachineOperations.map(function(e){
                if(e.FgActMachine){
                    if(selectType === e.TpMachineId){
                        chart.push({
                            value: e.MachineId, 
                            label: e.MachineName,
                        })
                    }
                }
            })
            return chart;
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING /*|| this.apiState === ENUM.INIT*/){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function formatedItemsTpBulkCargo() {
        return this.MachineCargoJson.TpBulkCargo.filter(item => {
            return item
        })
    }
    function formatedItemsTpGeneralCargo() {
        return this.MachineCargoJson.TpGeneralCargo.filter(item => {
            return item
        })
    }
    function formatedItemsTpCargo() {
        return this.MachineCargoJson.TpCargoJson.filter(item => {
            return item
        })
    }
    function formatedItemsTpCargoDetail() {
        if(this.MachineCargoJson.TpCargoJson.length === 0){
            return []
        }
        const chart = [];
        this.MachineCargoJson.TpCargoJson.map(function(e){
            if(e.TpCargoDetailJson.length > 0){
                if(e.status){
                    e.TpCargoDetailJson.map(function(i){
                        chart.push(i)
                    })
                }
            }
        })
        return chart.filter(item => {
            return item
        })
    }
    function formatedItemsTpCargoDetailAsociacion() {
        if(this.MachineCargoJson.TpCargoJson.length === 0){
            return []
        }
        const chart = [];
        this.MachineCargoJson.TpCargoJson.map(function(e){
            if(e.TpCargoDetailJson.length > 0){
                if(e.status){
                    e.TpCargoDetailJson.map(function(i){
                        if(i.status){
                            chart.push(i)
                        }
                    })
                }
            }
        })
        return chart.filter(item => {
            return item
        })
    }
    //watch
    function CollapseCarga(newValue,OldValue){
        if(newValue === true){
            this.getDataTpCargo();
            this.getDataMachine();
        }else{
            this.MachineCargoJson.MachineId='';
            this.MachineCargoJson.TpCargoJson=[];
            this.MachineCargoJson.TpBulkCargo=[];
            this.MachineCargoJson.TpGeneralCargo=[];
            this.$store.state.maquina.idState = 0;
            this.getDataMachine();
        }
        
    }
    export default {
        name:'app-container-carga.vue',
        data,
        mixins: [GeneralMixin],
        methods:{
            typeChange,
            machineChange,
            sendDataCargo,
            changeTpCargo,
            changeTpDetailCargo,
            getDataTpCargo,
            getDataInformation,
            getDataMachine,
        },
        computed:{
            fieldsTpCargo,
            fieldsTpDetailCargo,
            fieldsAsociados,
            formatedItemsTpCargo,
            formatedItemsTpCargoDetail,
            formatedItemsTpCargoDetailAsociacion,
            formatedItemsTpBulkCargo,
            formatedItemsTpGeneralCargo,
            apiStateLoading,
            apiStateFormLoading,
            optionListType,
            optionListMachine,
       
            ...mapState({
                idState: state => state.maquina.idState,
                myDataMachine: state => state.maquina.myDataMachine,
                myDataMachineTp: state => state.maquina.myDataMachineTp,
                apiState: state => state.maquina.apiState,
                apiStateForm: state => state.maquina.apiStateForm,
                CollapseCarga: state=> state.maquina.CollapseCarga,
                formReset: state => state.maquina.formReset,
                branch: state => state.auth.branch

            })
        },
        watch:{
            CollapseCarga
        },
         components: {
            CustomTabs,
            CustomTab,
        }
    }
</script>

<style lang="scss">
.footer-carga{
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    padding-left: 1.5rem !important;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
}
.center-cell {
  text-align: center;
}
 
</style>