<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="pa-3"
      :closeOnBackdrop="false"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CCol sm="12" class="d-flex justify-content-end mb-3">
        <CButton v-if="!collapse"
          size="sm" 
          square 
          color="add" 
          @click="showCollpaseCreateCommodity"
          v-c-tooltip="{
            content:  $t('label.commodity'),
            placement: 'top-end',
          }"
          >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.nueva')}}
          </span>
        </CButton>
        <CButton v-else
          size="sm" 
          square 
          color="add" 
          @click="closeCollapse"
          v-c-tooltip="{
            content:  $t('label.commodity'),
            placement: 'top-end',
          }"
          >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.nueva')}}
          </span>
        </CButton>
      </CCol>
      <CCol sm="12">
        <CRow class="d-flex justify-content-center">
          <CCol sm="12" lg="7">
            <CSelect
              :label="$t('label.cargoState')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              :options="computedBulkCargoSelect"
              @change="changeSelectedBulkCargoSelect($event)"
              :value="TpCargoId"
              :invalid-feedback="errorMessage($v.TpCargoId)"
              :is-valid="hasError($v.TpCargoId)"
              v-model.trim="$v.TpCargoId.$model"
            />
          </CCol>
          <CCol sm="12" lg="7" v-if="isEdit">
            <CSelect
              :label="$t('label.status')"
              addLabelClasses="text-right"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              v-uppercase
              :options="statusOptions"
              :invalid-feedback="errorMessage($v.Status)"
              :is-valid="statusSelectColor"
              :value.sync="Status"
              v-model.trim="$v.Status.$model"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <mercanciaCollapse
          :modal="modalCollapse" 
          :collapse.sync="collapse" 
          @close-collapse="closeCollapse" 
          @refresh-Commodity="mountedCommodityList"
        />
      </CCol>
      <CCol sm="12" lg="5" v-if="!collapse">
        <CSelect
          :label="$t('label.heading')"
          addLabelClasses="text-right"
          :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-8'}"
          :options="computedHeadingList"
          @change="headingSelectedFilter($event)"
          :value="selectRubroValue"
          class="input-rubro"
        />
      </CCol>
      <CCol sm="12" v-if="!collapse">
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="computedCommodityList"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loadingTable"           
          sorter
        >
            
            <!--
            <template #over-table>
              <div class="row justify-content-start" style="margin-top: -2.8rem">
                <div class="col-auto  mt-2" style="margin-left: 14.8rem !important">
                  {{$t('label.heading')}} :
                </div>
                <div class="col-3" style="margin-left: -1rem !important">
                  <CSelect
                    :options="computedHeadingList"
                    @change="headingSelectedFilter($event)"
                    :value="selectRubroValue"
                  />
                </div>
              </div>
            </template> -->
          <template #loading>
            <loading/>
          </template>
          <template #checked="{ item }">
            <td class="center-cell">
              <CInputCheckbox
                custom
                class="checkbook-filter"
                :checked="item.initialActive"
                @update:checked="updatedItemCommodityChecked($event, item)"
              />
            </td>
          </template>
          <template #checked-filter>
            <td class="center-cell" style="border-top:none">
              <CInputCheckbox
                :label="$t('label.all')"
                class="checkbook-filter"
                custom
                :checked="selectAll"
                @update:checked="selectAllChecked($event)"
              />
            </td>
          </template>
          <template #options="{ item }">
            <td class="center-cell">
              <CButton
                square
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.commodity'),
                  placement: 'top-end',
                }"
                @click="showCollpaseEditCommodity(item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
        <p v-if="itemCommonityChecked.length === 0" class="mt-2 text-danger required">
        {{$t('label.must')+' '+$t('label.toSelect')+''+$t('label.atLeast')+' 1 '+$t('label.type')+' '+$t('label.of')+' '+$t('label.packaging')}} 
        </p>
      </CCol>
      <template #footer>
        <CButton v-if="!isEdit" outline color="add" @click="submit" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton v-if="isEdit" outline color="add" @click="statusConfirmation(true, Status, submit)" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import { required, maxLength, helpers, alpha } from 'vuelidate/lib/validators';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';
import ImdgMixin from '@/_mixins/imdg';
import CargaGranel from '@/_mixins/carga-granel';
import {ModalCargaGranelMixin} from '@/_mixins/carga-granel';
import mercanciaCollapse from '../../rubro/mercancia-collapse'

function data() {
  return {
    TpCargoId: '',
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    itemsCommonityList: [],
    itemsBulkCargo: [],
    temporalItemsCommonityList: [],
   
    selectAll: false,
    resetSelectAll: false,
    Status: null,
    TpCargoId: '',
    itemsHeadingList: [],
    itemCommonityChecked: [],
    itemCommonityCheckedUpdated: [],
    itemsBuilkCargosCommonityUpdated: [],
    selectAll: false,
    resetSelectAll: false,
    selectRubroValue: '',
    collapse: false,
    modalCollapse: null,
    commoditySelected: '',
    itemsRubro: [],
    rubros: [],
    isEdit: false,
    isSubmit: false,
  };
}

function fields(){
  return [
    { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center',},
    { label: `${this.$t('label.commodity')} (ES)`, key: 'CommodityNameEs', _style:'width:35%;', _classes: 'text-uppercase text-center' },
    { label: `${this.$t('label.commodity')} (EN)`, key: 'CommodityNameEn', _style:'width:35%;', _classes: 'text-uppercase text-center' },
    {
      label: '',
      key: 'checked',
      _classes: 'text-center',
      sorter: false,
      filter: false,
      _style:'width:10%;'
    },
    { key: 'options', label: '', _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false, },
  ];
}

function showCollpaseCreateCommodity() {
  this.collapse = true;
  this.modalCollapse = {Item: this.collapse, Rubros: this.rubros };
}

function showCollpaseEditCommodity(item){
    this.collapse = true;
    this.commoditySelected = item.CommodityId;
    this.modalCollapse = {Item: item, Rubros: this.rubros };
}

function closeCollapse(){
  this.collapse = false;
  this.modalCollapse = null;
}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.commoditySelected = '';
  this.collapse = false;
  this.modalCollapse = null;
  this.isSubmit = false;
  this.showModal = false;
  this.$emit('updated-modal', event);
}


//##### Funcion para montar toda la data necesiaria en el modal
async function mountedCommodityList() {
  this.loadingOverlay = true;
  this.collapse = false;
  this.modalCollapse = null;
  await this.mountedBulkCargo();
  if(this.TpCargoId != ''){
    await this.getBulkCargoCommodityByTpCargo(this.TpCargoId);
  }
  else{
    this.resetSelectAll = true;
  }
  //this.loadingTable = true;
  await this.$http
    .get('Commodity-list?HeadingId=&Filter=ACTIVO')
    .then((response) => {
      this.itemsCommonityList = response.data.data;
      this.temporalItemsCommonityList = response.data.data;
    })
    .finally(() => {
      //this.loadingTable = false;
    })
    .catch((e) => {
      //this.loadingTable = false;
      this.notifyError({ text: e });
    });
  await this.mountedHeadingList();
  await this.listarRubros();
  this.loadingOverlay = false;

}

//#### Monta la data para la tabla
function computedCommodityList() {
  if (this.itemsCommonityList.length > 0) {
    return this.itemsCommonityList.map((item) => {
        if (!this.selectAll && !this.resetSelectAll && (this.selectRubroValue === 'N/A' || this.selectRubroValue !== 'N/A')) {
            let itemComodity = this.itemCommonityChecked.filter(
            (element) => item.CommodityId === element.CommodityId
            );
            return this.formatedDateItem(item, itemComodity.length > 0);
        }
        if (this.selectAll && !this.resetSelectAll && this.selectRubroValue === 'N/A') {
        const temporalItem = {
            TpCargoId: this.TpCargoId,
            CommodityId: item.CommodityId,
        };
         const BuilkCargoCommonity =  this.itemsBuilkCargosCommonityUpdated.filter((element) => element.CommodityId === item.CommodityId);
         const temporalItemUpdated = {
           TpCargoId: this.TpCargoId,
           CommodityId: item.CommodityId,
           Status: this.Status,
           TpCargoCommodityId: BuilkCargoCommonity.length > 0 ? BuilkCargoCommonity.TpCargoCommodityId : ''
         }
        this.itemCommonityChecked.push(temporalItem);
        this.itemCommonityCheckedUpdated.push(temporalItemUpdated)
        return this.formatedDateItem(item,true);
      }
      if (this.selectAll && !this.resetSelectAll && this.selectRubroValue !== 'N/A') {
        const temporalItem = {
            TpCargoId: this.TpCargoId,
            CommodityId: item.CommodityId,
        };
        const BuilkCargoCommonity =  this.itemsBuilkCargosCommonityUpdated.filter((element) => element.CommodityId === item.CommodityId);
        const temporalItemUpdated = {
            TpCargoId: this.TpCargoId,
            CommodityId: item.CommodityId,
            Status: this.Status,
            TpCargoCommodityId: BuilkCargoCommonity.length > 0 ? BuilkCargoCommonity.TpCargoCommodityId : ''
        }
        if(!this.itemCommonityChecked.includes(temporalItem)){
            this.itemCommonityChecked.push(temporalItem);
        }
        if(!this.itemCommonityCheckedUpdated.includes(temporalItemUpdated)){
            this.itemCommonityCheckedUpdated.push(temporalItemUpdated);
        }
        return this.formatedDateItem(item, true);
    }
    if (!this.selectAll && this.resetSelectAll && this.selectRubroValue === 'N/A') {
        return this.formatedDateItem(item, false);
    }
    if (!this.selectAll && this.resetSelectAll && this.selectRubroValue !== 'N/A') {
        const temporalItem = {
            TpCargoId: this.TpCargoId,
            CommodityId: item.CommodityId,
        };
        const BuilkCargoCommonity =  this.itemsBuilkCargosCommonityUpdated.filter((element) => element.CommodityId === item.CommodityId);
        const temporalItemUpdated = {
            TpCargoId: this.TpCargoId,
            CommodityId: item.CommodityId,
            Status: this.Status,
            TpCargoCommodityId: BuilkCargoCommonity.length > 0 ? BuilkCargoCommonity.TpCargoCommodityId : ''
        }
        
        const i =  this.arrayObjectIndexOf(this.itemCommonityChecked, item.CommodityId, 'CommodityId' )
        const j =  this.arrayObjectIndexOf(this.itemCommonityCheckedUpdated, item.CommodityId, 'CommodityId' )
        if (i !== -1) {
            this.itemCommonityChecked.splice(i, 1);
        }
        if (j !== -1) {
            this.itemCommonityCheckedUpdated.splice(i, 1);
        }
        return this.formatedDateItem(item,false);
    }
    });
  }
}

//Funcion para bulkCargos del select
async function mountedBulkCargo() {
  //this.loadingOverlay = true;
  await this.$http
    .get('BulkCargo-list?Filter=ACTIVO')
    .then((response) => {
      this.itemsBulkCargo = response.data.data;
    })
    .finally(() => {
      //this.loadingOverlay = false;
    })
    .catch((e) => {
      //this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

//Funcion para bulkCargos del select
async function mountedHeadingList() {
  //this.loadingOverlay = true;
  this.$http
    .get('Heading-list?Filter=ACTIVO')
    .then((response) => {
      this.itemsHeadingList = response.data.data;
      const headingTemporal = {
        HeadingName: this.$t('label.all'),
        HeadingId: 'N/A',
      };
      this.selectRubroValue = 'N/A'
      this.itemsHeadingList = [headingTemporal, ...this.itemsHeadingList];
    })
    .finally(() => {
      //this.loadingOverlay = false;
    })
    .catch((e) => {
      //this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

//### Funcion computed para formatear los datos del select ###
function computedHeadingList() {
  if (this.itemsHeadingList.length > 0) {
    return this.itemsHeadingList.map((item) => {
      return {
        label: item.HeadingName,
        value: item.HeadingId,
      };
    });
  }
}

function headingSelectedFilter(event) {
    this.selectAll = false;
    this.resetSelectAll = false;
    this.selectRubroValue = event.target.value
  this.itemsCommonityList = this.temporalItemsCommonityList;
  if (event.target.value === 'N/A') {
    this.itemsCommonityList = this.temporalItemsCommonityList;
  } else {
    this.itemsCommonityList = this.itemsCommonityList.filter(
      (elelment) => elelment.HeadingId === event.target.value
    );
  }
}

async function getBulkCargoCommodityByTpCargo(id) {
  //this.loadingOverlay = true;
  this.itemCommonityChecked = [];
  this.itemCommonityCheckedUpdated = [];
  this.selectAll = false;
  this.resetSelectAll = false;
  await this.$http
    .get(`BulkCargoCommodity-list?TpCargoId=${id}`)
    .then((response) => {
      if (response.data.data.length > 0) {
        this.itemsBuilkCargosCommonityUpdated = response.data.data;
        response.data.data.map((item) => {
          if (item.Status === 'ACTIVO') {
            this.Status = 1;
            const temporalItem = {
              TpCargoId: this.TpCargoId,
              CommodityId: item.CommodityId,
            };
            const temporalItemUpdated = {
              TpCargoId: this.TpCargoId,
              CommodityId: item.CommodityId,
              Status: this.Status,
              TpCargoCommodityId: item.TpCargoCommodityId
            };
            this.itemCommonityChecked.push(temporalItem);
            this.itemCommonityCheckedUpdated.push(temporalItemUpdated);
          } else {
            if(this.Status === null){
              this.Status = 0;
            }
          }
        });
      }
    })
    .finally(() => {
      //this.loadingOverlay = false;
    })
    .catch((e) => {
     // this.loadingOverlay = false;
      this.notifyError({ text: e });
    });
}

async function changeSelectedBulkCargoSelect(event) {
  this.selectAll = false;
  this.resetSelectAll = false;
  this.TpCargoId = event.target.value;
  if(this.TpCargoId != ''){
    await this.getBulkCargoCommodityByTpCargo(this.TpCargoId);
  }
  else{
    this.resetSelectAll = true;
  }
}

async function updatedItemCommodityChecked(event, item) {
    const BuilkCargoCommonity =  this.itemsBuilkCargosCommonityUpdated.filter((element) => element.CommodityId === item.CommodityId);

    this.selectAll = false;
    this.resetSelectAll = false;
    const commonityheckedTemporal = {
        TpCargoId: this.TpCargoId,
        CommodityId: item.CommodityId,
    };
    const commonityheckedTemporalUpdated = {
        TpCargoId: this.TpCargoId,
        CommodityId: item.CommodityId,
        Status: this.Status,
        TpCargoCommodityId: BuilkCargoCommonity.length > 0 ? BuilkCargoCommonity[0].TpCargoCommodityId : ' '
    };
    if (event === true) {
        this.itemCommonityChecked.push(commonityheckedTemporal);
        
        if(this.isEdit){
          const j = await this.arrayObjectIndexOf(this.itemCommonityCheckedUpdated, item.CommodityId, 'CommodityId' )
          if (j !== -1) {
            this.itemCommonityCheckedUpdated[j].Status = 1;
          }else {
            this.itemCommonityCheckedUpdated.push(commonityheckedTemporalUpdated)
          }
        }
    } else {
      const i = await this.arrayObjectIndexOf(this.itemCommonityChecked, item.CommodityId, 'CommodityId' )
      const j = await this.arrayObjectIndexOf(this.itemCommonityCheckedUpdated, item.CommodityId, 'CommodityId' )
    
        if (i !== -1) {
            this.itemCommonityChecked.splice(i, 1);
        }
        if(this.isEdit){
          if (j !== -1) {
            this.itemCommonityCheckedUpdated[j].Status = 0;
          }
        }
    }
}

async function submit() {
  try {
    this.$v.$touch();
    this.loadingOverlay = true;
    this.isSubmit = true;

    if(!this.isEdit && this.itemCommonityChecked.length === 0){
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw  this.$t('label.errorsPleaseCheck');
    }
    if(this.isEdit === true && this.itemCommonityCheckedUpdated.length === 0){
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw  this.$t('label.errorsPleaseCheck');
    }

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpCargoCommodityJson = this.isEdit? this.itemCommonityCheckedUpdated:this.itemCommonityChecked
    const ruta = this.isEdit?  'BulkCargoCommodity-update':'BulkCargoCommodity-insert';
    const method = this.isEdit? 'PUT':'POST';
    await this.$http
      .ejecutar(method,ruta,TpCargoCommodityJson, { root: 'TpCargoCommodityJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.closeModal(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.notifyError({ text: e });
      });
  } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.notifyError({ text: e });
  }
}

//#### Funcion para seleccionar todos los embalajes
function selectAllChecked(event) {
    if(this.selectRubroValue === 'N/A'){
        this.itemCommonityChecked = [];
        this.itemCommonityCheckedUpdated = [];
    }

    if (event === true) {
        this.selectAll = true;
        this.resetSelectAll = false;
    } else {
        this.selectAll = false;
        this.resetSelectAll = true;
    }
}

async function updatedBulkCargoCommonity(){
    //this.loadingOverlay = true;
    const TpCargoCommodityJson = this.itemCommonityCheckedUpdated

    await this.$http
        .put('BulkCargoCommodity-update', TpCargoCommodityJson, { root: 'TpCargoCommodityJson' })
        .then((response) => {
            if (response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.$emit('refresh-data-table');
            //this.loadingOverlay = false;
            this.closeModal(false);
            this.notifySuccess({ text: messageSuccess });
            }
        })
        .catch((e) => {
            //this.loadingOverlay = false;
            this.notifyError({ text: e });
        });
}

function selectedStatus(event) {
    this.Status = event.target.value;
    if(this.isEdit){
      this.itemCommonityCheckedUpdated.map((element) => {
        element.Status = this.Status;
      })
    }
}

async function listarRubros () {
  this.itemsRubro = [];
  //this.loadingOverlay = true;

  let listado = Array;
  await this.$http.get("Heading-list", { filter: 'ALL' })
  .then(async response => {
    listado = [...response.data.data];
    await this.filtroRubro(listado);
    this.rubros = listado.filter(ob => ob.Status==="ACTIVO" );
    //this.loadingOverlay = false;
  }).catch( err => {
    //this.loadingOverlay = false;
    this.notifyError({ text: err });
  })
}

function filtroRubro(listado) {
  this.itemsRubro = listado.map(listado => Object.assign({}, this.itemsMercancia, {
    Nro: listado.Nro,
    HeadingId: listado.HeadingId, 
    HeadingName: listado.HeadingName, 
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Status: listado.Status,
    FgActHeading: listado.FgActHeading,
  }));

  return this.itemsRubro;
}

export default {
    name: 'modalMercancia',
    data,
    props: {
        modal: null,
        idBulkCargoSelected: String,
        title: String,
    },
    mixins: [
      ImdgMixin, 
      GeneralMixin, 
      ModalMixin,
      CargaGranel,
      ModalCargaGranelMixin
    ],
    directives: UpperCase,
    methods: {
        mountedHeadingList,
        mountedBulkCargo,
        closeModal,
        headingSelectedFilter,
        getBulkCargoCommodityByTpCargo,
        mountedCommodityList,
        changeSelectedBulkCargoSelect,
        updatedItemCommodityChecked,
        selectAllChecked,
        selectedStatus,
        showCollpaseCreateCommodity,
        showCollpaseEditCommodity,
        closeCollapse,
        listarRubros,
        filtroRubro,
        submit,
    },
    validations: {
        TpCargoId: { required },
        Status: {required},

    },
    computed: {
        computedCommodityList,
        computedHeadingList,
        fields,
    },
    watch: {
        modal: function(val) {
            this.showModal = val;
            if (val) {
              this.mountedCommodityList();
            } else {
              this.resetForm();
            }
        },
        idBulkCargoSelected: function(val){
          if(val !== '' && val !== null){
            this.isEdit = true;
            this.$v.$touch();
            this.TpCargoId = val
          }
        },
    },

    components: {
      mercanciaCollapse
    }
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
.input-rubro{
  padding-left: 0.5rem !important;
  padding-right: 0.8rem !important;
}
</style>
