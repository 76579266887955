<template>
  <div class="col-sm-12"  style="height: 100%;">
    <apexchart :width="Width" :height="Height" :type="Type" :options="chartOptions" :series="Series"></apexchart>
  </div>
</template>
<script>
  function chartOptions(){
  return {
    chart: {
      type: this.Type,
      animations: {
        enabled: true,
      }
    },
    labels: this.Label,
    colors: this.Colors.length!=0?this.Colors:undefined,
    stroke: {
      width: 0,
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false,
    },
    legend: this.legend ?? {
      show: true,
      height: 36,
      position: 'bottom',
      labels: {
        //colors: '#60656e',
      },
      formatter: function (val, opts) {
        return `${opts.w.config.series[opts.seriesIndex]}%`
      },
    },
    tooltip: this.tooltip ?? {
      enabled: true,
      y: {
        formatter: function (val, opts) {
          return `${val}%`
        }
      }
    },
    dataLabels: {
      enabled: false,
      // background: {
      //   foreColor: "#ffffff",
      // },
      dropShadow: {
          enabled: false,
      },
    },
  }    
}

export default {
  name:'pieDonut',
  props: {
    Type: {
      type: String,
      default: () => 'pie',
    },
    Series: {
      type: Array,
      require: true,
      default: () => [],
    },
    Label:{
      type: Array,
      require: true,
      default: () => [],
    },
    Colors:{
      type: Array,
      require: false,
      default: () => [],
    },
    Width:{
      type: String,
      require: true,
      default: 'auto',
    },
    Height:{
      type: String,
      require: true,
      default: 'auto',
    },
    legend: Object,
    tooltip: Object,
  },
  methods:{
  },
  computed:{
    chartOptions
  },
}
</script>