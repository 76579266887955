import {
  required,
  helpers,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

import { textareaSpecials, onlyText } from '../funciones';

const alphaNumCustom = (value) => /^[\w\u00f1\u00d1\-]+$/g.test(value);
const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);
const onlyNumbers = (value) => !helpers.req(value) || /^[0-9\-]+$/.test(value);

export default () => {
  return {
    orderInfo: {
      OrderId: {},
      BillOfLadingYardId: { required },
      YardId: { required },
      ClientTpId: { required },
      DriverId: {},
      DriverCi: { minLength: minLength(7), maxLength: maxLength(10), required, onlyNumbers },
      DriverName: { required, onlyText },
      VehicleId: {},
      LicensePlate: { required, alphaNumCustom },
      GuideNumber: { onlyNumbers },
      ControlNumber: { required, onlyNumbers },
      TransactionDate: { required },
      Observation: { TextareaNotReq },
      Status: {},
      OrderDetailJson: {},
    }
  }
}