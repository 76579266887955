<template>
    <div class="Containers-data">
        <CRow class="m-0"> 
            <CCol sm="12" lg="2" class="px-0" style=" display: flex; align-items: center; justify-content: center;">
                <img :src="imageSrc(this.ContainerItems?.TpCargoRoute ?? 'Container/17253579-c55c-43d3-b807-5bb88dcf287f.jpeg')"  width="90%" height="80%" >
            </CCol>
            <CCol sm="12" lg="10" class="px-1">
                <CRow>
                    <CCol sm="12" lg="12">
                        <div class="form-group form-row" rol="group">
                            <label class="col-form-label-sm col-sm-12 col-lg-2 text-right Label">{{$t('label.visitItineraty')}}</label>
                            <div class="col-sm-12 col-lg-10 col-xl-auto" style="min-width:50%;">
                                <v-select 
                                    id="v-select-small"
                                    :placeholder="$t('label.select')"
                                    :options="VisitOptions"
                                    style="font-size: 11px;"
                                    v-model="visitValue"
                                    :value.sync="visitValue"
                                    :disabled="this.containerOptions.length === 0"
                                    
                                >
                                    <template #no-options="{ }">
                                        {{$t('label.noResultsFound')}}
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </CCol>
                </CRow>

                <CRow class="Container-items">
                    <CCol sm="12" lg="4" xl="2" class="mb-0 p-0">
                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.SizeFt')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Size ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.status')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Status ?? 'N/A'}}</label> 
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.carrier')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.ShippingLineCode ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.isoCode')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">
                                    {{this.ContainerItems?.IsoCode ?? 'N/A'}}
                                </label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.type')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">
                                    {{(Object.keys(this.ContainerItems).length > 0)? 
                                        (this.$i18n.locale=='en'?  `${this.ContainerItems?.TpCargoNameEn} ${this.ContainerItems?.TpCargoCode}` : `${this.ContainerItems?.TpCargoNameEs} ${this.ContainerItems?.TpCargoCode}`)  
                                        : 
                                        'N/A'
                                    }}</label> 

                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.weight')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{`${methodFormated(this.ContainerItems?.Weigth)} ${this.ContainerItems?.UnitMeasureWeigthAbbrev ?? ''}`}}</label>
                            </div> 
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.confirmation')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">
                                    {{methodDate(this.ContainerItems?.ConfirmationDate) ?? 'N/A'}}
                                </label>
                            </div>
                        </div>
                    </CCol> 

                    <CCol sm="12" lg="4" xl="2" class="mb-0 p-0">
                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.vgm')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{`${methodFormated(this.ContainerItems?.Vgm)} ${this.ContainerItems?.UnitMeasureVgmAbbrev ?? ''}`}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.oversize')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.FgOverSize? $t('label.yes') : $t('label.no')}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.reefer')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.FgReefer? $t('label.yes') : $t('label.no')}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.imdg')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.FgImdg? this.ContainerItems?.ImdgClassCode ?? this.ContainerItems?.ImdgCode : $t('label.no')}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.damage')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{(this.ContainerItems?.FgDamaged? $t('label.yes'): $t('label.no'))}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Slot')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.BaySlot ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label style="font-size: 9px;" class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.DirectDischarge')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.FgDirect ? $t('label.yes'): $t('label.no')}}</label>
                            </div>
                        </div>
                    </CCol>

                    <CCol sm="12" lg="4" xl="2" class="mb-0 p-0 mb-5" style="border-right: 1px solid #cdcdcd;">
                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('POL')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.LoadPort ?? 'N/A'}}</label> 
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('POD')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.DischargePort ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.seals')+' 1:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Seal1 ?? 'N/A'}}</label> 
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.seals')+' 2:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Seal2 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.seals')+' 3:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Seal3 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.seals')+' 4:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.Seal4 ?? 'N/A'}}</label>
                            </div>
                        </div>
                    </CCol>


                    <CCol sm="12" lg="6" xl="3" class="mb-0 p-0">
                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.status')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-auto col-lg-auto text-center rounded-pill mb-0 Labels px-2" style="color: aliceblue; min-width: 20%;" :style="{backgroundColor: this.ContainerItems?.YardCargoStatusColor ?? '#C9C7CB'}"  >
                                    {{(Object.keys(this.ContainerItems).length > 0)? 
                                        this.ContainerItems?.YardCargoStatusDsEn ? 
                                            (this.$i18n.locale=='en'?  this.ContainerItems?.YardCargoStatusDsEn : this.ContainerItems?.YardCargoStatusDsEs) 

                                            :
                                            'N/A'
                                        : 
                                        'N/A'
                                    }}
                                </label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.yard')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.YardName ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.yardPosition')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{formatedPosition(this.ContainerItems)}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.daysInYard')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{
                                    (Object.keys(this.ContainerItems).length > 0)? 
                                        (this.$i18n.locale=='en'?  this.ContainerItems?.DaysInYardEn : this.ContainerItems?.DaysInYardEs) 
                                        : 
                                        'N/A'
                                    }}
                                </label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.receptionDate')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{methodDate(this.ContainerItems?.ReceptionDate)}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.dispatchDate')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{methodDate(this.ContainerItems?.DispatchDate)}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group" v-show="this.$store?.state?.containerSearch?.dataContainer?.BlJson && this.$store?.state?.containerSearch?.dataContainer?.BlJson?.length==1">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.customBroker')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.BlContainer[0]?.ClientNameCustomBroker ?? 'N/A'}}</label>  
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group" v-show="this.$store?.state?.containerSearch?.dataContainer?.BlJson && this.$store?.state?.containerSearch?.dataContainer?.BlJson?.length==1">
                            <b class="col-sm-6 col-lg-5 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.consignee')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-7 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.BlContainer[0]?.ClientNameConsignee ?? 'N/A'}}</label>
                            </div>
                        </div>
                    </CCol>

                    <CCol sm="12" lg="6" xl="3" class="mb-0 p-0">
                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.portActivity')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-auto mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-auto col-lg-auto text-center rounded-pill mb-0 Labels px-2"  style="color: aliceblue; min-width: 20%;" :style="{backgroundColor: this.ContainerItems?.PortActivityColor ?? '#C9C7CB'}">
                                    {{(Object.keys(this.ContainerItems).length > 0)? 
                                        this.ContainerItems?.PortActivityNameEn ?
                                            (this.$i18n.locale=='en'?  this.ContainerItems?.PortActivityNameEn : this.ContainerItems?.PortActivityNameEs) 
                                            :
                                            'N/A'
                                        : 
                                        'N/A'
                                    }}
                                </label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group" v-show="this.$store?.state?.containerSearch?.dataContainer?.BlJson && this.$store?.state?.containerSearch?.dataContainer?.BlJson?.length==1">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.bl')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.BlContainer[0]?.BlNro ?? 'N/A'}}</label> 
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group" v-show="this.$store?.state?.containerSearch?.dataContainer?.BlJson && this.$store?.state?.containerSearch?.dataContainer?.BlJson?.length==1">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Mode')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.TpCargoConditionCode ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Seal')+' 1:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.YardSeal1 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Seal')+' 2:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.YardSeal2 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Seal')+' 3:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.YardSeal3 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.Seal')+' 4:'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.YardSeal4 ?? 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group form-row mb-2" rol="group" v-show="this.$store?.state?.containerSearch?.dataContainer?.BlJson && this.$store?.state?.containerSearch?.dataContainer?.BlJson?.length==1">
                            <b class="col-sm-6 col-lg-6 p-0"><label class="col-form-label-sm col-sm-12 col-lg-12 text-right mb-0 p-0 Labels">{{$t('label.inventoryContains')+':'}}</label></b>
                            <div class="col-sm-6 col-lg-6 mb-0">
                                <label v-show="Object.keys(this.ContainerItems).length > 0 " class="col-form-label-sm col-sm-12 col-lg-12 text-left mb-0 p-0 Labels">{{this.ContainerItems?.DescriptionOfGoods ?? 'N/A' }}</label>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>
<script>

    import General from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            containerOptions:[],
            ContainerItems:{},
            BlContainer:{},
            visitValue:{},
        };
    }

    //methods
    function imageSrc(Src){
        if(Src === ''){
            return this.srcError;
        }
        else{
            return `${this.$store.getters["connection/getBase"]}${Src}`;
        }
    }

    //computeds
    function VisitOptions(){
        let _lang = this.$i18n.locale;

        if(this.containerOptions.length == 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var visit = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.containerOptions.map(function(e){
                visit.push({
                    ...e,
                    value: e.VisitId, 
                    label: `${e.VesselName ?? 'N/A'}, ${e.VoyageArrival ?? 'N/A'}, ATA ${methodDate(e.Ata ?? new Date)}  ${e.Atd ?('- ATD '+methodDate(e.Atd ??  new Date)) : ''} - ${(_lang =='en'?  e.HeaderJson[0]?.YardCargoStatusDsEn : e.HeaderJson[0]?.YardCargoStatusDsEs) ?? 'N/A'}`,
                })
                
            })
            return visit;
        }
    }


    function methodDate(date) {
        if(date)
            return  DateFormater.formatDateTimeWithSlash(date)
        else
            return  'N/A'
    }
    
    function methodFormated(item) {
        if(item)
            return  NumberFormater.formatNumber(item, 2) ?? 'N/A'
        else
            return  'N/A'
    }

    function formatedPosition(item) {
        let position = '';

        item?.PositionJson?.map((json)=>{
            position = `${position} ${json.SlotCode}`
        })
        
        if( item?.PositionJson?.length>0)
            return  position
        else
            return  'N/A'
    }

    export default{
        name: 'container-info',
        data,
        mixins: [General],
        components: {
        },
        methods: {
            imageSrc,
            methodDate,
            methodFormated,
            formatedPosition,
        },
        computed: {
            VisitOptions,
            ...mapState({
                options: state => state.containerSearch.containerOptions,
            })
        },
        watch:{
            options: function (newValue) {
                if (Object.keys(newValue).length > 0){
                    this.BlContainer = {};
                    this.ContainerItems = {};
                    this.visitValue = '';
                    this.containerOptions = this.$store.state.containerSearch.containerOptions;
                    
                    if(this.containerOptions.length>0) {
                        let _lang = this.$i18n.locale;
                        this.visitValue = {
                            ...this.containerOptions[0], 
                            label: `${this.containerOptions[0].VesselName ?? 'N/A'}, ${this.containerOptions[0].VoyageArrival ?? 'N/A'}, ATA ${methodDate(this.containerOptions[0].Ata ?? new Date)}  ${this.containerOptions[0].Atd ?('- ATD '+methodDate(this.containerOptions[0].Atd ??  new Date)) : ''} - ${(_lang =='en'?  this.containerOptions[0].HeaderJson[0]?.YardCargoStatusDsEn : this.containerOptions[0].HeaderJson[0]?.YardCargoStatusDsEs) ?? 'N/A'}`, 
                            value: this.containerOptions[0]?.VisitId
                        };
                        this.BlContainer = this.containerOptions[0]?.BlJson;
                        this.ContainerItems = this.containerOptions[0]?.HeaderJson[0];
                        this.$store.state.containerSearch.dataContainer = this.containerOptions[0];
                    }
                }
                else{
                    
                    this.visitValue = '';
                    this.ContainerItems = {};
                    this.BlContainer = {};
                    this.containerOptions = [];
                }  
            },
            visitValue: function (selectValue) {
                if(selectValue?.IdX && selectValue.HeaderJson && selectValue?.HeaderJson[0]?.IdX){   
                    this.BlContainer = selectValue?.BlJson;
                    this.ContainerItems = selectValue?.HeaderJson[0];
                    this.$store.state.containerSearch.dataContainer = selectValue;
                }
                else
                {
                    this.BlContainer = {}
                    this.ContainerItems = {};
                    this.$store.state.containerSearch.dataContainer = {};
                }
            },
        }
    }
</script>
<style lang="scss">
    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
    .Labels {
        font-size: 10px;
    }
</style>