import {
    onlyMayusTextEspecial,
    onlyMayusText,
    onlyAlphanumeric,
    requiredSelect,
    inputColor,
    UtcZone,
    onlyText,
    onlyAlphanumeric4,
    nameLength
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal,
    minValue,
    maxValue,
    numeric 
} from "vuelidate/lib/validators";

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else{
            if(validURL(value))
                return true;
            else
                return (value != '');
        }
    };
    

    export const FormCargo = () => {
        return {
            formCargo: {
                TpCargoDs:{
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                    onlyMayusTextEspecial,
                },
                TpCargoCode:{
                    required,
                    onlyMayusText,
                    minLength: minLength(1),
                    maxLength: maxLength(5)
                },
                TpCargoName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                Route: { isValidFile  },
                FgAccesory:{
                    required
                }
            },
            formStandartd:{
                StandardDocumentId:{
                    required
                },
                MetadataDesc:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(1),
                    maxLength: maxLength(250)
                }
            }
        }
    }

    export const FormDetalle = () => {
        return {
            formDetalle: {
                TpCargoId:{
                    required
                },
                SizeId:{
                    required
                },
                defaultColor:{
                    required,
                    inputColor
                },
                TpCargoDetailName:{
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                    onlyAlphanumeric,
                },
                TpCargoDetailCode:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(1),
                    maxLength: maxLength(5)
                },
                OutsideLength:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                OutsideWidth:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                OutsideHeigth:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                OutsideVolumen:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                InsideLength:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                InsideWidth:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                InsideHeigth:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                InsideVolumen:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                WeigthEmpty:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                MaxLoad:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                MaxGrossWeigth:{
                    required,
                    decimal,
                    minValue:(1),
                    maxValue:(16),
                },
                FgSpecial:{
                    required
                },
                FgApplyRefrigeration:{
                    required
                },
                FgApplyOversize:{
                    required
                },
                FgApplySeal:{
                    required
                }
            },
            formStandartd:{
                StandardDocumentId:{
                    required
                },
                MetadataDesc:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(1),
                    maxLength: maxLength(250)
                }
            }
        }
    }

    export const FormElement = () => {
        return {
            formElement: {
                TpCargoId:{
                    requiredSelect,
                    required
                },
                TpCargoViewId:{
                    requiredSelect,
                    required
                },
                TpCargoElementName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormComponent = () => {
        return {
            formComponent: {
                TpCargoId:{
                    requiredSelect,
                    required
                },
                TpCargoComponentName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormVista = () => {
        return {
            formVista: {
                TpCargoId:{
                    requiredSelect,
                    required
                },
                TpCargoViewName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormSize = () => {
        return {
            formSize: {
                UnitMeasureIdSize:{
                    requiredSelect,
                    required
                
                },
                TpCargoSizeName:{
                    required,
                    numeric,
                    minLength: minLength(1),
                    maxLength: maxLength(2)
                },
            }
        }
    }

    export const FormAccesory = () => {
        return {
            formAccesory: {
                TpCargoId:{
                    requiredSelect,
                    required
                },
                TpCargoAccesoryNameEs:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                TpCargoAccesoryNameEn:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormStatus = () => {
        return {
            formStatus: {
                TpCargoStatusName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormCondition = () => {
        return {
            formCondition: {
                TpCargoStatusId:{
                    requiredSelect,
                    required
                },
                TpCargoConditionName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                TpCargoConditionCode:{
                    required,
                    onlyMayusText,
                    minLength: minLength(1),
                    maxLength: maxLength(4)
                },
            }
        }
    }

    export const FormClass = () => {
        return {
            formClass: {
                TpCargoClassDsEs:{
                    required,
                    onlyText,
                    nameLength,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                TpCargoClassDsEn:{
                    required,
                    onlyText,
                    nameLength,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                TpCargoClassCode:{
                    required,
                    onlyText,
                    minLength: minLength(1),
                    maxLength: maxLength(1)
                },
            }
        }
    }