import { required, minValue, maxValue} from 'vuelidate/lib/validators';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import { onlyAlphanumeric5} from '@/_validations/validacionEspeciales'

export default (laterDate, previousDate, ValidateHoldDeck, ValidateHold, ValidateQuantity) => {

  let ValidateHoldHatch = ValidateHoldDeck ? { required } : {};
  let ValidateHoldId = ValidateHold || ValidateHoldDeck ? { required } : {};

  return {
    generalCargo: {
      TransactionDate: { required, TimeValidate(value){
        return DateFormater.formatDateTimeWithoutSlash(value) > previousDate &&
          DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
        }
      },
      VisitCraneId: { required },
      VesselUbicationId: { required },
      VesselHoldId: ValidateHoldId,
      VesselHoldHatchCId: ValidateHoldHatch,
      Observation: { onlyAlphanumeric5 },
      MovStowageId: {required },
      Status: { required }
    },

    PackagingId:  { required },
    VisitBillOfLadingPackagingId: { required },
    Quantity: { 
      required(value) {
        return NumberFormater.setNum(value) > 0;
      }, 
      QuantityRemainder (value){
        if (NumberFormater.setNum(value) > 0) {
          return  NumberFormater.setNum(value) <=  NumberFormater.setNum(ValidateQuantity);
        }else{
          return true;
        }
      } 
    },
    QuantityPlanning: {},
    QuantityStowageRate: {},
    ValidateQuantity: {}
  }
}