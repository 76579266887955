<template>
  <div>
    <CRow class="mt-2">
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.generalInfo')">
            <CCard class="card-border">
              <form>
                <CRow class="justify-content-center mt-3 px-2">
                  <CCol sm="12" lg="6">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right required"
                      :label="$t('label.containerCode')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="containerCode"
                      :is-valid="hasError($v.containerCode)"
                      :invalid-feedback="errorMessage($v.containerCode)"
                      :maxlength="$v.containerCode.$params.maxLength.max"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    > 
                    <!-- <template #invalid-feedback v-if="$v.containerCode.$error">
                      <div class='text-danger' style="font-size: 11.2px;"   v-if="$v.containerCode.SoloContenedores">{{$t('label.badFormat')}}</div>
                    </template> -->
                    </CInput>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.status')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="status"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.statusSelected.$model"
                          @change="filterStatus($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="StatusOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.statusSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.statusSelected.$error">
                          {{ errorMessage($v.statusSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.condition')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="condition"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.conditionSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterCondition($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.conditionSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.conditionSelected.$error">
                          {{ errorMessage($v.conditionSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.class')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          v-model="containerClass"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.containerClassSelected.$model"
                          :addInputClasses="'not-border'"
                          @change=" filterClass($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerClassOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.containerClassSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.containerClassSelected.$error">
                          {{ errorMessage($v.containerClassSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.shippingLineCode')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="shippingLineCode"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.shippingLineCodeSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterShippingLineCode($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerOptionShippingLineCode"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.shippingLineCodeSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.shippingLineCodeSelected.$error">
                          {{ errorMessage($v.shippingLineCodeSelected) }}
                        </div>
                      </div>
                    </div>


                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.portActivity')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="portActivity"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.portActivitySelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterPortActivity($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerOptionPortActivity"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.portActivitySelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.portActivitySelected.$error">
                          {{ errorMessage($v.portActivitySelected) }}
                        </div>
                      </div>
                    </div>


                    <div class="form-group form-row mb-2">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 text-right">SHIPPER OWNED</label>
                      <div class="d-flex justify-content-end col-sm-12 col-lg-auto ">
                        <CSwitch
                          color="watch"
                          variant="3d"
                          type="checkbox"
                          :checked.sync="FgActShipperOwn"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        />
                      </div>
                    </div>

                    <CInput
                      v-uppercase
                      size="sm"
                      class="mt-0"
                      :addLabelClasses="FgActShipperOwn ? 'text-right required' : 'text-right'"
                      label="SHIPPER OWNED"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.shipperOwn.$model"
                      :is-valid="hasError($v.shipperOwn)"
                      :invalid-feedback="errorMessage($v.shipperOwn)"
                      :disabled="!FgActShipperOwn || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                    >
                    </CInput>


                    <CSelect
                      v-if="!VisitCargoId"
                      v-uppercase
                      size="sm"
                      addLabelClasses="text-right"
                      label="IMDG"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.imdg.$model"
                      :is-valid="hasError($v.imdg)"
                      :invalid-feedback="errorMessage($v.imdg)"
                      :options="ImdgOptions"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    />

                  </CCol>

                  <CCol sm="12" lg="6">

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.loadPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="loadPort"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 pr-1 col-sm-6"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.loadPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterLoadPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerMasterPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.loadPortSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.loadPortSelected.$error">
                          {{ errorMessage($v.loadPortSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.dischargePort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="dischargePort"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-6 pr-1"
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.dischargePortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterDischargePort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerMasterPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.dischargePortSelected)"
                          :disabled="VisitCargoId || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.dischargePortSelected.$error">
                          {{ errorMessage($v.dischargePortSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.DeliveryPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="deliveryPort"
                          size="sm"
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          required 
                          disabled
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.deliveryPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterDeliveryPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.deliveryPortSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.deliveryPortSelected.$error">
                          {{ errorMessage($v.deliveryPortSelected) }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label style="font-size: 12px;" class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.CargoOriginPort')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="cargoOriginPort"
                          size="sm"
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          required 
                          disabled
                        />
                        <CSelect
                          size="sm"
                          v-model="$v.cargoOriginPortSelected.$model"
                          :addInputClasses="'not-border'"
                          @change="filterCargoOriginPort($event.target.value)"
                          class="mb-0 px-0 col-sm-6"
                          :options="ContainerPort"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.cargoOriginPortSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.cargoOriginPortSelected.$error">
                          {{ errorMessage($v.cargoOriginPortSelected) }}
                        </div>
                      </div>
                    </div>


                    <CInput
                      v-uppercase
                      size="sm"
                      class="mt-0"
                      addLabelClasses="text-right"
                      :label="$t('label.newShippingLine')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      :disabled="true"
                      v-model="shippingLineName"
                    >
                    </CInput>

                    <CInput
                      v-uppercase
                      size="sm"
                      class="mt-0"
                      addLabelClasses="text-right"
                      :label="$t('label.Procedence')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.procedence.$model"
                      :is-valid="hasError($v.procedence)"
                      :invalid-feedback="errorMessage($v.procedence)"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>



                    <CSelect
                      v-if="!VisitCargoId"
                      v-uppercase
                      size="sm"
                      class="mt-0"
                      addLabelClasses="text-right"
                      :label="$t('label.itinerary')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.itinerary.$model"
                      :is-valid="hasError($v.itinerary)"
                      :invalid-feedback="errorMessage($v.itinerary)"
                      :options="ItineraryOptions"
                      @change="YardContainerNewGeneralInfo"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CSelect>



                    <CInput
                      v-if="!VisitCargoId"
                      v-uppercase
                      size="sm"
                      class="mt-0"
                      addLabelClasses="text-right"
                      label="BOOKING"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right py-1', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.bookingNro.$model"
                      :is-valid="hasError($v.bookingNro)"
                      :invalid-feedback="errorMessage($v.bookingNro)"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>

                    <CSelect
                      v-if="!VisitCargoId"
                      v-uppercase
                      size="sm"
                      addLabelClasses="text-right"
                      :label="$t('label.client')"
                      :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                      v-model="$v.client.$model"
                      :is-valid="hasError($v.client)"
                      :invalid-feedback="errorMessage($v.client)"
                      :options="ClientOptions"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                      <template #append>
                        <CButton
                          color="add"
                          size="sm"
                          class="rounded"
                          v-c-tooltip="{
                              content:$t('label.nuevo')+' '+$t('label.client'),
                              placement: 'top-end'
                          }"
                          @click="ClientModal=true"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                          <CIcon name="cil-playlist-add"/>
                        </CButton>
                      </template>
                    </CSelect>

                  </CCol>
                </CRow>
              </form>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="`${$t('ISO')} - ${$t('label.Seals')}`">
            <CCard class="card-border">
              <form>
                <CRow class="justify-content-center mt-3">
                  <CCol sm="12" lg="5">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.containerType')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-model="typeContainer"
                          size="sm"
                          required 
                          disabled
                          class="mb-0 pl-0 col-sm-5 pr-1"
                        />
                        <v-select
                          class="mb-0 px-0 col-sm-7"
                          id="v-select-small"
                          :placeholder="$t('label.select')"
                          :options="TypeContainerOptions"
                          :reduce="option => option.value" 
                          v-model="$v.typeContainerSelected.$model"
                          :addInputClasses="'not-border'"
                          :is-valid="hasError($v.typeContainerSelected)"
                          style="height: 29px;"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                            <template #no-options="{}">
                                {{$t('label.noResultsFound')}}
                            </template>
                            <template slot="option" slot-scope="option" >
                              <span>
                                &nbsp;
                              </span>              
                              {{ option.label }}
                          </template>
                        </v-select>
                        <div class="text-invalid-feedback" v-if="$v.typeContainerSelected.$error">
                          {{ errorMessage($v.typeContainerSelected) }}
                        </div>
                      </div>
                    </div>

                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="$t('label.type')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.tpCargoName)"
                      :invalid-feedback="errorMessage($v.tpCargoName)"
                      :disabled="true"
                      v-model="$v.tpCargoName.$model"
                    >
                    </CInput>

                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="$t('label.typecargocode')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.typecargocode)"
                      :invalid-feedback="errorMessage($v.typecargocode)"
                      :disabled="true"
                      v-model="$v.typecargocode.$model"
                    >
                    </CInput>

                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="$t('label.SizeFt')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.sizeFt)"
                      :invalid-feedback="errorMessage($v.sizeFt)"
                      :disabled="true"
                      v-model="$v.sizeFt.$model"
                    >
                    </CInput>
                  </CCol>

                  <CCol sm="12" lg="5">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.largo')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          ref="lenghtInput"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 col-sm-6 pr-1"
                          :is-valid="hasError($v.length)"
                          v-model="$v.length.$model"
                          maxlength="10"
                          disabled
                        />
                        <CSelect
                          size="sm"
                          @change="filterLength($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureDimrLengthOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.lengthSelected)"
                          v-model="$v.lengthSelected.$model"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        ></CSelect>
                        <div class="text-invalid-feedback" v-if="$v.length.$error||$v.lengthSelected.$error">
                          <div v-if="$v.lengthSelected.$error">
                            {{ errorMessage($v.lengthSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.length.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.length) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.height')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          ref="heightInput"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          :is-valid="hasError($v.heigth)"
                          v-model="$v.heigth.$model"
                          maxlength="10"
                          disabled
                        />
                        <CSelect
                          size="sm"
                          @change="filterHeigth($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureDimrHeightOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.heigthSelected)"
                          v-model="$v.heigthSelected.$model"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        ></CSelect>
                        <div class="text-invalid-feedback" v-if="$v.heigth.$error||$v.heigthSelected.$error">
                          <div v-if="$v.heigthSelected.$error">
                            {{ errorMessage($v.heigthSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.heigth.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.heigth) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.weight')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          ref="weigthInput"
                          :is-valid="hasError($v.weight)"
                          v-model="$v.weight.$model"
                          maxlength="10"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          @change="filterWeight($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureWeigthOptions"
                          :placeholder="$t('label.select')"
                          v-model="$v.weightSelected.$model"
                          :is-valid="hasError($v.weightSelected)"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        />
                        <div class="text-invalid-feedback" v-if="$v.weight.$error||$v.weightSelected.$error">
                          <div v-if="$v.weightSelected.$error">
                            {{ errorMessage($v.weightSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.weight.limit ? `${$t('label.container_data.container_weight')} ${maxWeigthNumber}` : 
                              !$v.weight.maxValue ?  `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.weight) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.vgm')}}</label>
                      <div class="input-group col-sm-12 col-lg-7">
                        <CInput
                          :is-valid="hasError($v.vgm)"
                          v-model="$v.vgm.$model"
                          v-money="money"
                          size="sm"
                          required 
                          class="mb-0 pl-0 pr-1 col-sm-6"
                          ref="vgmInput"
                          maxlength="10"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          @change="filterVgm($event.target.value)"
                          :addInputClasses="'not-border'"
                          class="mb-0 px-0 col-sm-6"
                          :options="UnitMeasureVgmOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.vgmSelected)"
                          v-model="$v.vgmSelected.$model"
                          :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                        >
                        </CSelect>
                        <div class="text-invalid-feedback" v-if="$v.vgm.$error||$v.vgmSelected.$error">
                          <div v-if="$v.vgmSelected.$error">
                            {{ errorMessage($v.vgmSelected) }}
                          </div>
                          <div v-else>
                            {{ !$v.vgm.limit ? `${$t('label.container_data.container_weight')} ${maxVgmNumber}` : 
                              !$v.vgm.maxValue ?  `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.vgm) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCol>

                  <CCol sm="12" lg="5">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="`${$t('label.Seal')} 1`"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.seal1Container)"
                      v-model="$v.seal1Container.$model"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="5">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="`${$t('label.Seal')} 2`"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.seal2Container)"
                      v-model="$v.seal2Container.$model"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="5">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="`${$t('label.Seal')} 3`"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.seal3Container)"
                      v-model="$v.seal3Container.$model"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="5">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="`${$t('label.Seal')} 4`"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.seal4Container)"
                      v-model="$v.seal4Container.$model"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="3"
                     v-if="!ValidateStatusSelected && !seal1Container && !seal2Container && !seal3Container && !seal4Container"
                  >
                    
                  </CCol>
                  <CCol sm="5" class="text-invalid-feedback m-0"
                     v-if="!ValidateStatusSelected && !seal1Container && !seal2Container && !seal3Container && !seal4Container"
                  >
                    <label>{{ $t('label.AtLeastSealRequired') }}</label>
                  </CCol>
                  <CCol sm="12" lg="5">
                    <CInput
                      v-uppercase
                      size="sm"
                      class="mb-3"
                      addLabelClasses="text-right"
                      :label="$t('label.description')"
                      :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                      :is-valid="hasError($v.DescriptionType)"
                      :invalid-feedback="errorMessage($v.DescriptionType)"
                      v-model="$v.DescriptionType.$model"
                      :disabled="(dataContainer?.FgCertified??false) || fgUnconsolidated"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="5"></CCol>
                </CRow>
              </form>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" v-show="dataContainer.FgSpecial || dryReefer || FgApplyOversize">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.special')">
            <CCard class="card-border">
              <form>
                <CRow class="justify-content-center mt-3">
                  <CCol sm="12" lg="5" v-show="dryReefer">
                    <CCard>
                      <CCardHeader class="text-center bg-dark text-white" style="background-color: #9e9e9e;">
                        <b>{{ $t('label.ReeferInfo') }}</b> 
                      </CCardHeader>
                      <CCardBody style="background-color: #f5f5f5;">
                        <div class="form-group row mt-4 mb-3">
                          <div  class="col-sm-12 col-lg-6 text-right pr-1">{{ `${this.$t('label.reefer')}`}}</div>
                          <div class="col-sm-12 col-lg-6 d-flex align-items-center">
                            <CSwitch
                              color="watch"
                              size="sm"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="dryReefer"
                              :disabled="true"
                              @update:checked="dryReefer = $event"
                            />
                          </div>
                        </div>

                        <div class="form-group row mb-3" v-if="dryReefer">
                          <div  class="col-sm-12 col-lg-6 text-right pr-1">{{ `${this.$t('label.pluggedIn')}`}}</div>
                          <div class="col-sm-12 col-lg-6 d-flex align-items-center">
                            <CSwitch
                              color="watch"
                              size="sm"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="plugged"
                              @update:checked="plugged = $event"
                              :disabled="!dryReefer || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                            />
                          </div>
                        </div>

                        <CSelect
                          addLabelClasses="text-right text-label-black"
                          class="mb-3"
                          size="sm"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.connectedTo')}`" 
                          @change="filterTpPowerPlug($event.target.value)"
                          :options="TpPowerPlugOptions"
                          :is-valid="hasError($v.tpPowerPlugSelected)"
                          :invalid-feedback="errorMessage($v.tpPowerPlugSelected)"
                          v-model="$v.tpPowerPlugSelected.$model"
                          :disabled="( !plugged || !dryReefer) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        ></CSelect>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.temperature')"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.reeferSetting)"
                          :invalid-feedback="errorMessage($v.reeferSetting)"
                          v-model="$v.reeferSetting.$model"
                          type="number"
                          :disabled="( !dryReefer) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CSelect
                          addLabelClasses="text-right text-label-black"
                          size="sm"
                          class="mb-3"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.measureUnit')} RF`" 
                          @change="filterRf($event.target.value)"
                          :options="UnitMeasureUnitRfOptions"
                          :is-valid="hasError($v.measureUnitRfSelected)"
                          :invalid-feedback="errorMessage($v.measureUnitRfSelected)"
                          v-model="$v.measureUnitRfSelected.$model"
                          :disabled="( !dryReefer) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        ></CSelect>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="`${$t('label.MaximumRange')} RF`"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          ref="MaximumtInput"
                          :is-valid="hasError($v.maximunRangeRf)"
                          :invalid-feedback="errorMessage($v.maximunRangeRf)"
                          v-model="$v.maximunRangeRf.$model"
                          :disabled="( !dryReefer) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="`${$t('label.MinimumRange')} RF`"
                          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.minimunRangeRf)"
                          :invalid-feedback="errorMessage($v.minimunRangeRf)"
                          v-model="$v.minimunRangeRf.$model"
                          ref="MinimumInput"
                          :disabled="( !dryReefer) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>
                        
                      </CCardBody>
                    </CCard>
                  </CCol>

                  <CCol sm="12" lg="5" v-show="FgApplyOversize">
                    <CCard>
                      <CCardHeader class="text-center bg-dark text-white" style="background-color: #9e9e9e;">
                        <b>{{ $t('label.OversizeInfo') }}</b>
                      </CCardHeader>
                      <CCardBody style="background-color: #f5f5f5;">

                        <CSelect
                          size="sm"
                          class="mb-3"
                          :is-valid="hasError($v.measureUnitDinSelected)"
                          :invalid-feedback="errorMessage($v.measureUnitDinSelected)"
                          v-model="$v.measureUnitDinSelected.$model"
                          @change="filterMeasureDin($event.target.value)"
                          addLabelClasses="text-right"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :label="`${this.$t('label.measureUnit')} DM`"
                          :addInputClasses="'not-border'"
                          :options="UnitMeasureUnitDinOptions"
                          :placeholder="$t('label.select')"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        ></CSelect>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          :is-valid="hasError($v.DimFront)"
                          :invalid-feedback="errorMessage($v.DimFront)"
                          v-model="$v.DimFront.$model"
                          addLabelClasses="text-right"
                          :label="$t('label.DimFront')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          v-money="money"
                          ref="DimFrontInput"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimBack')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimBack)"
                          :invalid-feedback="errorMessage($v.DimBack)"
                          v-model="$v.DimBack.$model"
                          v-money="money"
                          ref="DimBackInput"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimLeft')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimLeft)"
                          :invalid-feedback="errorMessage($v.DimLeft)"
                          v-model="$v.DimLeft.$model"
                          v-money="money"
                          ref="DimLeftInput"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimRight')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimRight)"
                          :invalid-feedback="errorMessage($v.DimRight)"
                          v-model="$v.DimRight.$model"
                          v-money="money"
                          ref="DimRightInput"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>

                        <CInput
                          v-uppercase
                          size="sm"
                          class="mb-3"
                          addLabelClasses="text-right"
                          :label="$t('label.DimTop')"
                          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
                          :is-valid="hasError($v.DimTop)"
                          :invalid-feedback="errorMessage($v.DimTop)"
                          v-model="$v.DimTop.$model"
                          v-money="money"
                          ref="DimToptInput"
                          :disabled="( !FgApplyOversize ) || ((dataContainer?.FgCertified??false) || fgUnconsolidated)"
                        >
                        </CInput>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </form>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
    <CRow>
    <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!(dataContainer?.FgCertified) && !fgUnconsolidated">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>
    <ClientModal 
			:modal="ClientModal"
			@cerrarModal="ClientModal=false" 
			@UpdateClient="YardManagementGeneralClient(false)" 
		/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import ModalMixin from '@/_mixins/modal';
import containerdataValidations from '@/_validations/yard-management/container/containerdataValidations';
import { NumberFormater } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import { VMoney } from 'v-money';
import ClientModal from  './client-modal';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    Start: false,
    money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
    },
    isSubmit: false,
    yardCargoId:'',
    tabinfoIndex:0,
    //general info
    containerCode:'',
    sizeFt:'',
    sizeId:'',
    statusSelected:'',
    status:'',
    optionStatus:[],
    condition:'',
    conditionSelected:'',
    optionCondition:[],
    containerClass:'',
    containerClassSelected:'',
    optionContainerClass:[],
    portActivity:'',
    portActivitySelected:'',
    optionPortActivity:[],
    shippingLineCode:'',
    shippingLineCodeSelected:'',
    optionShippingLineCode:[],
    loadPort:'',
    loadPortSelected:'',
    dischargePort:'',
    dischargePortSelected:'',
    deliveryPort:'',
    deliveryPortSelected:'',
    cargoOriginPort:'',
    cargoOriginPortSelected:'',
    optionMasterPort:[],
    optionPort:[],
    shippingLineName:'',
    procedenceName: '',
    procedence: '',
    bookingNro: '',
    shipperOwn: '',
    FgActShipperOwn: false,
    client: '',
    itinerary: '',
    shippingLine: '',
    imdg: '',
    
    //ISO AND SEALS
    typeContainerSelected:'',
    typeContainer:'',
    typeContainerOption:[],
    tpCargoName:'',
    typecargocode:'',
    size:'',
    DescriptionType:'',
    seal1Container:'',
    seal2Container:'',
    seal3Container:'',
    seal4Container:'',
    unitMeasureLengthDim:[],
    length:0,
    lengthSelected:'',
    afterUnitLength:'',
    unitMeasureHeightDim:[],
    heigth:0,
    heigthSelected:'',
    afterUnitHeigth:'',
    UnitMeasureWeigth:[],
    weight:0,
    weightSelected:'',
    afterUnitWeight:'',
    UnitMeasureVgm:[],
    vgm:0,
    vgmSelected:'',
    afterUnitVgm:'',
    maxGrossWeigthJson:[],
    weigthEmptyJson:[],
    maxLoadJson:[],
    //SPECIAL
    tpPowerPlug:[],
    tpPowerPlugSelected:'',
    dryReefer:false,
    plugged:false,
    measureUnitRf:[],
    measureUnitRfSelected:'',
    afterUnitRf:'',
    reeferSetting:'',
    minimunRangeRf: 0,
    maximunRangeRf: 0,
    DimBack: 0,
    DimFront: 0,
    DimLeft: 0,
    DimRight: 0,
    DimTop: 0,
    measureUnitDin:[],
    measureUnitDinSelected:'',
    afterUnitDin:'',
    FgApplyOversize:false,
    FgActShipperOwn: false,
    ClientList: [],
    ItineraryList: [],
    ClientModal: false,
    ImdgList: [],
    
  };
}

function handleTab(tab) {
  this.tabinfoIndex = tab;

}

function isActiveTab(current) {
  return this.tabinfoIndex == current;
}

//methods
function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    
    if (this.$v.validationGroup.$error && !this.$v.containerCode.minLength && !this.$v.containerCode.onlyAlphanumeric){
      throw this.$t('label.errorsPleaseCheck');
    }
    
    let CargoJson = { 
      CargoId: this.dataContainer.CargoId, 
      ContainerCode: this.containerCode, 
      SizeId: this.sizeId, 
      TpCargoClassId:this.containerClassSelected, 
      TpCargoStatusId:this.statusSelected, 
      ShippingLineId: this.shippingLineCodeSelected, 
      LoadPortId: this.loadPortSelected, 
      DischargePortId: this.dischargePortSelected, 
      DeliveryPortId: this.deliveryPortSelected, 
      OriginalPortLoadingId: this.cargoOriginPortSelected, 
      MetadataId: this.typeContainerSelected, 
      DescriptionType: this.DescriptionType, 
      Weigth: Number.parseFloat(this.weight.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0, 
      UnitMeasureWeigth: this.weightSelected, 
      Vgm: Number.parseFloat(this.vgm.replace(/[.]/g, '')?.replace(',', '.')).toFixed(2) ?? 0, 
      UnitMeasureVgm: this.vgmSelected, 
      Seal1: this.seal1Container, 
      Seal2: this.seal2Container, 
      Seal3: this.seal3Container, 
      Seal4: this.seal4Container, 
      PortActivityId: this.portActivitySelected, 
      TpCargoConditionId: this.conditionSelected, 
      DryReefer: (this.dryReefer? 1 : 0), 
      UnitMeasureRf: this.measureUnitRfSelected, 
      Plugged: (this.plugged? 1 : 0), 
      TpPowerPlugId: this.tpPowerPlugSelected, 
      ReeferSetting: this.reeferSetting, 
      MinimumRangeRf: this.minimunRangeRf, 
      MaximumRangeRf: this.maximunRangeRf, 
      UnitMeasureDim: this.measureUnitDinSelected,
      Procedence: this.procedence,
      ImdgId: this.imdg,
      BookingNro: this.bookingNro,
      ClientTpId: this.client,
      ShipperOwn: this.shipperOwn,
      FgActShipperOwn: this.FgActShipperOwn?1:0,
      ItineraryId: this.itinerary,
      DimFront: Number.parseFloat(this.DimFront.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0, 
      DimBack:  Number.parseFloat(this.DimBack.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0, 
      DimLeft:  Number.parseFloat(this.DimLeft.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0, 
      DimRight: Number.parseFloat(this.DimRight.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0, 
      DimTop:   Number.parseFloat(this.DimTop.replace(/[.]/g, '').replace(',', '.')).toFixed(2)?? 0
    }

    this.$http.put('YardContainer-update', CargoJson, { root: 'CargoJson' })
      .then(async (response) => {
        await this.getYardCargo();
        this.notifySuccess({text: response.data.data[0].Response});
        this.$store.state.yardManagement.loading = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function getYardCargo() {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.yardCargoId, YardId: this.yardData.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
          let yardCargoReception = '', yardCargoDispatch = ''; 

          yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
          yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

          this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
        } 
        else{
          this.$store.state.yardManagement.dataContainer = List[0];
        }
        
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.CargoId ? List[0] : Select;
            });
        }
        this.isSubmit = false;
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getGeneralInfo() {
  this.$store.state.yardManagement.loading = true;

  await this.$http.get('YardContainerGeneralInfo-by-CargoId', { CargoId: this.dataContainer.CargoId})
      .then(response => {
        if( response.data.data){
          let res = response.data.data[0]
          this.optionStatus = res?.TpCargoStatusJson;
          this.optionContainerClass = res?.TpCargoClassJson;
          this.optionPortActivity = res?.TpPortActivityJson;
          this.optionShippingLineCode = res?.ServiceLineMasterJson;
          this.optionMasterPort = res?.ServicePortMasterJson;
          this.optionPort = res?.PortJson;
          //WEIGHT
          this.UnitMeasureWeigth = res?.UnitMeasureWeigthJson;
          //VGM
          this.UnitMeasureVgm = res?.UnitMeasureWeigthJson;
          //RF
          this.measureUnitRf =  res?.UnitMeasureTemperatureJson;

          if(this.dataContainer.DryReefer){
            this.afterUnitRf = this.measureUnitRf[0]?.UnitMeasureId;
            this.measureUnitRfSelected = this.measureUnitRf[0]?.UnitMeasureId;
          }
          
          //TP POWER PLUG
          this.tpPowerPlug = res?.TpPowerPlugJson;

          if(this.dataContainer.DryReefer)
            this.tpPowerPlugSelected = this.dataContainer.TpPowerPlugId ?? this.tpPowerPlug[0].TpPowerPlugId 

          //DINS
          this.measureUnitDin = res?.UnitMeasureDimJson;

          if( this.FgApplyOversize || validDim(this.DimBack) && validDim(this.DimLeft) && validDim(this.DimRight) && validDim(this.DimTop) && validDim(this.DimFront)){
            this.afterUnitDin = this.measureUnitDin[0]?.UnitMeasureId;
            this.measureUnitDinSelected = this.measureUnitDin[0]?.UnitMeasureId;
          }
          
          //STATUS CONTAINER
          let statusValue = this.optionStatus.filter(item =>{ return this.dataContainer.TpCargoStatusId === item.TpCargoStatusId });

          if(statusValue){
            this.optionCondition = statusValue[0]?.TpCargoConditionList;
          }

         }
      })
      .catch( err => {
        this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
      .finally(() => {
        this.$store.state.yardManagement.loading = false;
      });
}

async function getTpCargoIsoCode() {
    await this.$http.get('TpCargoDetail-by-codiso', { CodIso: ''})
      .then(response => {
        if( response.data.data){
          this.typeContainerOption = response.data.data;

          let typeContainer = this.typeContainerOption?.filter((e) => { return  this.dataContainer.MetadataId === e.MetadataId })

          if(typeContainer.length>0){
            this.typeContainerSelected = typeContainer[0].MetadataId;
            this.typeContainer = typeContainer[0].MetadataDesc;
            this.FgApplyOversize = typeContainer[0].FgApplyOversize;
          }

        }
      })
      .catch( err => {
        this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
}

async function getItyneraryList() {
  this.$store.state.yardManagement.loading = true;
    await this.$http.get('YardServiceItinerary-by-CompanyBranchId', { CompanyBranchId: this.branch.CompanyBranchId })
      .then(response => {
        if( response.data.data){
          this.ItineraryList = response.data.data;
        }
      })
      .catch( err => {
        this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      }).finally(() => {
			  this.$store.state.yardManagement.loading = false;
		  });
}


function filterStatus(event) {
  this.statusSelected = event;
  if(this.statusSelected && this.statusSelected!='' && this.optionStatus.length>0){
    let valueList = this.optionStatus?.filter((e) => { return  this.statusSelected === e.TpCargoStatusId });
    this.status = valueList[0]?.TpCargoStatusName;
    this.optionCondition = valueList[0]?.TpCargoConditionList ?? [];
    if (this.optionCondition.length == 1) {
      this.condition = this.optionCondition[0].TpCargoConditionName;
      this.conditionSelected = this.optionCondition[0].TpCargoConditionId;
    }else{
      this.condition = '';
      this.conditionSelected = '';
    }
  }
  else
  {
    this.status = '';
    this.optionCondition = [];
    this.condition = '';
    this.conditionSelected = '',
    this.$store.state.yardManagement.ContainerStatusInfo = {};
  }
}

function filterCondition(event) {
  this.conditionSelected = event;

  if(this.conditionSelected && this.conditionSelected!=''){
    let condition = this.optionCondition?.filter((e) => { return  this.conditionSelected === e.TpCargoConditionId }) ?? [];
    this.condition = condition[0]?.TpCargoConditionName;
  }
  
  else
  {
    this.conditionSelected = '';
    this.condition = '';
  }
}

function filterClass(event) {
  this.containerClassSelected = event;

  if(this.containerClassSelected && this.containerClassSelected!=''){
    let containerClass = this.optionContainerClass?.filter((e) => { return  this.containerClassSelected === e.TpCargoClassId }) ?? [];
    this.containerClass = containerClass[0]?.TpCargoClassCode;
   }
   else
  {
    this.containerClassSelected = '';
    this.containerClass = '';
  }
}

function filterShippingLineCode(event) {
  this.shippingLineCodeSelected = event

  if(this.shippingLineCodeSelected && this.shippingLineCodeSelected!=''){
    let shippingLineCode = this.optionShippingLineCode?.filter((e) => { return  this.shippingLineCodeSelected === e.ShippingLineId }) ?? [];
    this.shippingLineCode = shippingLineCode[0]?.ShippingLineCode;
    this.shippingLineName = shippingLineCode[0]?.ShippingLineName;

  }
  else
  {
    this.shippingLineCodeSelected = '';
    this.shippingLineCode = '';
    this.shippingLineName ='N/A'
  }
}

function filterPortActivity(event) {
  let _lang = this.$i18n.locale;
  this.portActivitySelected = event 

  if(this.portActivitySelected && this.portActivitySelected!=''){
    let portActivity = this.optionPortActivity?.filter((e) => { return  this.portActivitySelected === e.PortActivityId }) ?? [];
    this.portActivity = _lang=='en'? portActivity[0]?.PortActivityNameEn||'' : portActivity[0]?.PortActivityNameEs||'';
  }
  else
  {
    this.portActivitySelected = '';
    this.portActivity = '';
  }
}

function filterLoadPort(event) {
  this.loadPortSelected = event 

  if(this.loadPortSelected && this.loadPortSelected!=''){
    let loadPort = this.optionMasterPort?.filter((e) => { return  this.loadPortSelected === e.PortId }) ?? [];
    this.loadPort = loadPort[0]?.PortCode;
  }
  else
  {
    this.loadPortSelected = '';
    this.loadPort = '';
  }
}

function filterDischargePort(event) {
  this.dischargePortSelected = event 

  if(this.dischargePortSelected && this.dischargePortSelected!='' && !this.VisitCargoId){
    let dischargePort = this.optionMasterPort?.filter((e) => { return  this.dischargePortSelected === e.PortId }) ?? [];
    this.dischargePort = dischargePort[0]?.PortCode;
  }
  else
  {
    this.dischargePortSelected = '';
    this.dischargePort = '';
  }
}

function filterDeliveryPort(event) {
  this.deliveryPortSelected = event 

  if(this.deliveryPortSelected && this.deliveryPortSelected!=''){
    let deliveryPort = this.optionPort?.filter((e) => { return  this.deliveryPortSelected === e.PortId }) ?? [];
    this.deliveryPort = deliveryPort[0]?.PortCode;
  }
  else
  {
    this.deliveryPortSelected = '';
    this.deliveryPort = '';
  }
}

function filterCargoOriginPort(event) {
  this.cargoOriginPortSelected = event 

  if(this.cargoOriginPortSelected && this.cargoOriginPortSelected!=''){
    let cargoOriginPort = this.optionPort?.filter((e) => { return  this.cargoOriginPortSelected === e.PortId }) ?? [];
    this.cargoOriginPort = cargoOriginPort[0]?.PortCode;
  }
  else
  {
    this.cargoOriginPortSelected = '';
    this.cargoOriginPort = '';
  }
}

//ISO AND SEALS
function filterLength(event) {
  this.lengthSelected = event 
  let inputLenght = this.$refs?.lenghtInput?.$el?.querySelector("input");

  if(this.lengthSelected && this.lengthSelected!=''){
    let unitMeasure = this.unitMeasureLengthDim?.filter((e) => { return  this.lengthSelected === e.UnitMeasureId }) ;
    this.afterUnitLength !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitLength, this.length, inputLenght );
    this.length = inputLenght.value;
    this.afterUnitLength = this.lengthSelected;
  }
  else
  {
    this.length = 0.00;
    inputLenght.Value = 0.00;
    this.lengthSelected = '';
    this.afterUnitLength = this.lengthSelected;
  }
}

function filterHeigth(event) {
  this.heigthSelected = event 
  let inputHeight = this.$refs?.heightInput?.$el?.querySelector("input");

  if(this.heigthSelected && this.heigthSelected!=''){
    let unitMeasure = this.unitMeasureHeightDim?.filter((e) => { return  this.heigthSelected === e.UnitMeasureId });
    this.afterUnitHeigth !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitHeigth, this.heigth, inputHeight );
    this.heigth = inputHeight.value;
    this.afterUnitHeigth = this.heigthSelected;
  }
  else
  {
    this.heigth = 0.00;
    inputHeight.Value = 0.00;
    this.heigthSelected = '';
    this.afterUnitHeigth = this.heigthSelected;
  }
}

async function filterWeight(event) {
  this.weightSelected = event 
  let inputWeigth = this.$refs?.weigthInput?.$el?.querySelector("input");

  if(this.weightSelected && this.weightSelected!=''){
    let unitMeasure = this.UnitMeasureWeigth?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });
    this.afterUnitWeight !="" && await this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitWeight, this.weight, inputWeigth );
    this.weight = inputWeigth.value;
    this.afterUnitWeight = this.weightSelected;
  }
  else
  {
    this.weight = 0.00;
    inputWeigth.value = 0.00;
    this.weightSelected = '';
    this.afterUnitWeight = this.weightSelected;
  }
}

function filterVgm(event) {
  this.vgmSelected = event 
  let inputVgm = this.$refs?.vgmInput?.$el?.querySelector("input");

  if(this.vgmSelected && this.vgmSelected!=''){
    let unitMeasure = this.UnitMeasureVgm?.filter((e) => { return  this.vgmSelected === e.UnitMeasureId });
    this.afterUnitVgm !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitVgm, this.vgm, inputVgm );
    this.vgm = inputVgm.value;
    this.afterUnitVgm = this.vgmSelected;
  }
  else
  {
    this.vgm = 0;
    inputVgm.value = 0;
    this.vgmSelected = '';
    this.afterUnitVgm = this.vgmSelected;
  }
}

function filterMeasureDin(event) {
  this.measureUnitDinSelected = event;
  let inputDimFront = this.$refs?.DimFrontInput?.$el?.querySelector("input");
  let inputDimBack = this.$refs?.DimBackInput?.$el?.querySelector("input");
  let inputDimLeft = this.$refs?.DimLeftInput?.$el?.querySelector("input");
  let inputDimRight = this.$refs?.DimRightInput?.$el?.querySelector("input");
  let inputDimTop = this.$refs?.DimToptInput?.$el?.querySelector("input");
  

  if(this.measureUnitDinSelected && this.measureUnitDinSelected!=''){
    let unitMeasure = this.measureUnitDin?.filter((e) => { return  this.measureUnitDinSelected === e.UnitMeasureId });
    this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimFront, inputDimFront );
    this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimBack, inputDimBack );
    this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimLeft, inputDimLeft );
    this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimRight, inputDimRight );
    this.afterUnitDin !="" && this.Conversions( unitMeasure[0]?.UnitMeasureId, this.afterUnitDin, this.DimTop, inputDimTop );
    this.afterUnitDin = this.measureUnitDinSelected;
    this.DimFront = inputDimFront.value;
    this.DimBack = inputDimBack.value;
    this.DimLeft = inputDimLeft.value;
    this.DimRight = inputDimRight.value;
    this.DimTop = inputDimTop.value;
  }
  else
  {
    this.DimFront = 0 ;
    inputDimFront.value = 0;    
    this.DimBack = 0 ;
    inputDimBack.value = 0; 
    this.DimLeft = 0 ;
    inputDimLeft.value = 0; 
    this.DimRight = 0 ;
    inputDimRight.value = 0; 
    this.DimTop = 0 ;
    inputDimTop.value = 0; 
    this.measureUnitDinSelected = '';
    this.afterUnitDin = this.measureUnitDinSelected;
  }
}

async function filterRf(event) {
  this.measureUnitRfSelected = event 
  let MaximumtInput = this.$refs?.MaximumtInput?.$el?.querySelector("input");
  let MinimumInput = this.$refs?.MinimumInput?.$el?.querySelector("input");

  if(this.measureUnitRfSelected && this.measureUnitRfSelected!=''){
    let unitMeasure = this.measureUnitRf?.filter((e) => { return  this.measureUnitRfSelected === e.UnitMeasureId });
    this.afterUnitRf !="" && await this.conversionsRef( unitMeasure[0]?.UnitMeasureId, this.afterUnitRf );
    this.afterUnitRf = this.measureUnitRfSelected;
  }
  else
  {
    this.minimunRangeRf = 0;
    MinimumInput.value = 0;
    this.maximunRangeRf = 0;
    MaximumtInput.value = 0;
    this.measureUnitRfSelected = '';
    this.afterUnitRf = this.measureUnitRfSelected;
  }
}

function filterTpPowerPlug(event) {
  this.tpPowerPlugSelected = event 

  if(this.tpPowerPlugSelected && this.tpPowerPlugSelected!=''){
    let tpPowerPlug = this.tpPowerPlug?.filter((e) => { return  this.tpPowerPlugSelected === e.TpPowerPlugId });
  }
  else
  {
    this.tpPowerPlugSelected = '';
  }
}

function Reset() {
  this.isSubmit = false;
  this.tabinfoIndex = 0;
  //general info
  this.containerCode = '';
  this.sizeFt = '';
  this.sizeId = '';
  this.statusSelected = '';
  this.status = '';
  this.optionStatus = [];
  this.condition = '';
  this.conditionSelected = '';
  this.optionCondition = [];
  this.containerClass = '';
  this.containerClassSelected = '';
  this.optionContainerClass = [];
  this.portActivity = '';
  this.portActivitySelected = '';
  this.optionPortActivity = [];
  this.shippingLineCode = '';
  this.procedenceName = '';
  this.shippingLineCodeSelected = '';
  this.optionShippingLineCode = [];
  this.loadPort = '';
  this.loadPortSelected = '';
  this.dischargePort = '';
  this.dischargePortSelected = '';
  this.deliveryPort = '';
  this.deliveryPortSelected = '';
  this.cargoOriginPort = '';
  this.cargoOriginPortSelected = '';
  this.optionMasterPort = [];
  this.optionPort = [];
  this.shippingLineName = '';
  this.procedence = '',
  this.bookingNro = '',
  this.client = '',
  this.shipperOwn = '',
  this.FgActShipperOwn = false,
  this.client = '',
  this.itinerary = '',
  this.shippingLine = ''
  this.imdg = '',
  this.ClientModal = false,
  this.ItineraryList = [],
  this.ClientModal = false,
  this.ImdgList = [],
  //ISO AND SEALS
  this.typeContainerSelected = '';
  this.typeContainer = '';
  this.typeContainerOption = [];
  this.tpCargoName = '';
  this.typecargocode = '';
  this.size = '';
  this.DescriptionType = '';
  this.seal1Container = '';
  this.seal2Container = '';
  this.seal3Container = '';
  this.seal4Container = '';
  this.unitMeasureLengthDim = [];
  this.length = 0;
  this.lengthSelected = '';
  this.afterUnitLength = '';
  this.unitMeasureHeightDim = [];
  this.heigth = 0;
  this.heigthSelected = '';
  this.afterUnitHeigth = '';
  this.UnitMeasureWeigth = [];
  this.weight = 0;
  this.weightSelected = '';
  this.afterUnitWeight = '';
  this.UnitMeasureVgm = [];
  this.vgm = 0;
  this.vgmSelected = '';
  this.afterUnitVgm = '';
  this.maxGrossWeigthJson = [];
  this.weigthEmptyJson = [];
  this.maxLoadJson = [];
  //SPECIAL
  this.tpPowerPlug = [];
  this.tpPowerPlugSelected = '';
  this.dryReefer = false;
  this.plugged = false;
  this.measureUnitRf = [];
  this.measureUnitRfSelected = '';
  this.afterUnitRf = '';
  this.reeferSetting = '';
  this.minimunRangeRf = 0;
  this.maximunRangeRf = 0;
  this.DimBack = 0;
  this.DimFront = 0;
  this.DimLeft = 0;
  this.DimRight = 0;
  this.DimTop = 0;
  this.measureUnitDin = [];
  this.measureUnitDinSelected = '';
  this.afterUnitDin = '';
  this.$v.$reset();
}

async function YardManagementGeneralClient(loadingEnd) {
	this.$store.state.yardManagement.loading = true;
	await this.$http.ejecutar("GET", "YardManagementGeneralClient-list")
		.then(response => {
			this.ClientList = response.data.data ?? [];
		})
		.catch(async err => {
			this.ClientList = [];
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
		.finally(() => {
			this.$store.state.yardManagement.loading = loadingEnd;
		});
}

async function YardContainerNewGeneralInfo() {
	this.$store.state.yardManagement.loading = true;
	await this.$http.get('YardContainerNewGeneralInfo', {ItineraryId: this.itinerary})
		.then(async response => {
			let List = response?.data?.data[0] ?? {};
      this.optionStatus = List?.TpCargoStatusJson ?? [];
      this.optionContainerClass = List?.TpCargoClassJson ?? [];
      this.optionPortActivity = List?.TpPortActivityJson ?? [];
      this.optionShippingLineCode = List?.ServiceLineMasterJson ?? [];
      this.optionMasterPort = this.itinerary ? List?.ServicePortMasterJson||[] : List?.PortJson||[];
      this.optionPort = List?.PortJson ?? [];
      this.ImdgList = List?.ImdgJson ?? [];
      //WEIGHT
      this.UnitMeasureWeigth = List?.UnitMeasureWeigthJson ?? [];
      //VGM
      this.UnitMeasureVgm = List?.UnitMeasureWeigthJson ?? [];
      //RF
      this.measureUnitRf = List?.UnitMeasureTemperatureJson ?? [];
		})
		.catch(async err => {
      this.optionStatus = [];
      this.optionContainerClass = [];
      this.optionPortActivity = [];
      this.optionShippingLineCode = [];
      this.optionMasterPort = [];
      this.optionPort = [];
      this.ImdgList = [];
			this.$notify({
				group: 'container',
				title: '¡Error!',
				text: err,
				type: "error"
			});
		})
		.finally(() => {
			this.$store.state.yardManagement.loading = false;
		});
}

function getData() {
  this.yardCargoId = this.dataContainer.YardCargoId?? '';
  //GENERAL INFO CONTAINER
  this.containerCode = this.dataContainer.ContainerCode ?? '';
  this.sizeFt = this.dataContainer.Size ?? '';
  this.sizeId = this.dataContainer.SizeId ??  '';
  this.loadPort = this.dataContainer.LoadPort ?? '';
  this.dischargePort = this.dataContainer.DischargePort ?? '';
  this.containerClass = this.dataContainer.TpCargoClassCode ?? '';
  this.deliveryPort = this.dataContainer.DeliveryPort ?? '';
  this.status = this.dataContainer.TpCargoStatusName ?? '';
  this.statusSelected = this.dataContainer.TpCargoStatusId ?? ''; 
  this.conditionSelected = this.dataContainer.TpCargoConditionId ?? '';
  this.containerClassSelected = this.dataContainer.TpCargoClassId ?? '';
  this.shippingLineCodeSelected = this.dataContainer.ShippingLineId ?? '';
  this.portActivitySelected = this.dataContainer.PortActivictyId ?? '';
  this.loadPortSelected = this.dataContainer.LoadPortId ?? '';
  this.dischargePortSelected = this.dataContainer.DischargePortId ?? '';
  this.deliveryPortSelected = this.dataContainer.DeliveryPortId ?? '';
  this.cargoOriginPortSelected = this.dataContainer.OriginalPortLoadingId ?? '';
  this.cargoOriginPort = this.dataContainer.OriginalPortLoading ?? '';
  this.shippingLineCode = this.dataContainer.ShippingLineCode ?? '';
  this.shippingLineName = this.dataContainer.ShippingLineName ?? '';
  this.procedenceName = this.dataContainer.Procedence ?? '';
  this.condition = this.dataContainer.TpCargoConditionName ?? '';
  this.procedence = this.dataContainer.Procedence ?? '',
  this.bookingNro = this.dataContainer.BookingNro?? '',
  this.client = this.dataContainer.ClientTpId ?? '',
  this.shipperOwn = this.dataContainer.ShipperOwn ?? '',
  this.FgActShipperOwn = this.dataContainer.FgActShipperOwn ?? '',
  this.itinerary = this.dataContainer.ItineraryId ?? '',
  this.imdg = this.dataContainer.ImdgId ?? '',
  this.itinerary = this.dataContainer.ItineraryId ?? '',
  this.shippingLine = this.dataContainer.ShippingLineId ?? '',
  this.portActivity = (this?.$i18n?.locale=='en' ? this.dataContainer.PortActivityNameEn||'' : this.dataContainer.PortActivityNameEs||'');
  //STATUS CONTAINER
  let statusValue = this.optionStatus.filter(item =>{ return this.dataContainer.TpCargoStatusId === item.TpCargoStatusId });

  if(statusValue){
    this.optionCondition = statusValue[0]?.TpCargoConditionList;
  }
  //ISO AND SEALS
  this.typeContainer = this.dataContainer.IsoCode ?? '';
  this.tpCargoName = (this?.$i18n?.locale=='en' ? this.dataContainer.TpCargoNameEn : this.dataContainer.TpCargoNameEs) ?? '';
  this.typecargocode= this.dataContainer.TpCargoCode ?? '';
  this.size= this.dataContainer.Size ?? '';
  this.DescriptionType= this.dataContainer.DescriptionType ?? '';
  this.seal1Container= this.dataContainer.Seal1 ?? '';
  this.seal2Container= this.dataContainer.Seal2 ?? '';
  this.seal3Container= this.dataContainer.Seal3 ?? '';
  this.seal4Container= this.dataContainer.Seal4 ?? '';
  this.unitMeasureHeightDim = this.dataContainer.HeigthJson; 
  this.unitMeasureLengthDim = this.dataContainer.LenghtJson;
  this.maxGrossWeigthJson = this.dataContainer?.MaxGrossWeigthJson;
  this.weigthEmptyJson = this.dataContainer?.WeigthEmptyJson;
  this.maxLoadJson = this.dataContainer?.MaxLoadJson;

  //WEIGHT
  let MeasureWeigth =  this.UnitMeasureWeigth?.find((e) => { return  this.dataContainer.UnitMeasureWeigth === e.UnitMeasureId });
  this.weightSelected =  MeasureWeigth?.UnitMeasureId ?? '';
  this.afterUnitWeight = this.weightSelected
  //VGM
  let MeasureVgm = this.UnitMeasureVgm?.find((e) => { return  this.dataContainer.UnitMeasureVgm === e.UnitMeasureId });
  this.vgmSelected = MeasureVgm?.UnitMeasureId ?? '';
  this.afterUnitVgm = this.vgmSelected;

  //WEIGHT VALUE
  let inputWeigth = this.$refs?.weigthInput?.$el?.querySelector("input");
  inputWeigth.value = Number.parseFloat(this.dataContainer?.Weigth).toFixed(2) ?? 0.00;

  //VGM VALUE
  let inputVgm = this.$refs?.vgmInput?.$el?.querySelector("input");
  inputVgm.value = Number.parseFloat(this.dataContainer?.Vgm).toFixed(2) ?? 0.00;

  //LENGHT VALUE
  let unitMeasureLenght = this.dataContainer.LenghtJson?.filter((e) => { return  this.dataContainer.UnitMeasureAbbrevDim === e.UnitMeasureAbbrev });
  this.lengthSelected = unitMeasureLenght[0]?.UnitMeasureId ?? this.dataContainer.LenghtJson[0].UnitMeasureId;
  this.afterUnitLength = this.lengthSelected;
  let lenghtInput = this.$refs?.lenghtInput?.$el?.querySelector("input");
  lenghtInput.value = unitMeasureLenght[0]?.Value ? Number.parseFloat(unitMeasureLenght[0]?.Value).toFixed(2) : Number.parseFloat( this.dataContainer.LenghtJson[0].Value).toFixed(2);

  //HEIGHT VALUE
  let unitMeasureHeigth = this.dataContainer.HeigthJson?.filter((e) => { return  this.dataContainer.UnitMeasureAbbrevDim === e.UnitMeasureAbbrev });
  this.heigthSelected = unitMeasureHeigth[0]?.UnitMeasureId ?? this.dataContainer.HeigthJson[0].UnitMeasureId;
  this.afterUnitHeigth = this.heigthSelected;
  let heightInput = this.$refs?.heightInput?.$el?.querySelector("input");
  heightInput.value = unitMeasureHeigth[0]?.Value ? Number.parseFloat(unitMeasureHeigth[0]?.Value).toFixed(2) : Number.parseFloat( this.dataContainer.HeigthJson[0].Value).toFixed(2);

  //SPECIAL
  this.dryReefer = this.dataContainer.DryReefer ?? false;
  this.plugged = this.dataContainer.Plugged ?? false;
  this.reeferSetting = this.dataContainer.ReeferSetting ?? '';
  this.minimunRangeRf = this.dataContainer.MinimumRangeRf ?? 0;
  this.maximunRangeRf = this.dataContainer.MaximumRangeRf ?? 0;

  //DIM BACK VALUE
  let DimBackInput = this.$refs?.DimBackInput?.$el?.querySelector("input");
  DimBackInput.value = Number.parseFloat(this.dataContainer.DimBack).toFixed(2) ?? 0.00;

  //DIM FRONT VALUE
  let DimFrontInput = this.$refs?.DimFrontInput?.$el?.querySelector("input");
  DimFrontInput.value = Number.parseFloat(this.dataContainer.DimFront).toFixed(2) ?? 0.00;

  //DIM LEFT VALUE
  let DimLeftInput = this.$refs?.DimLeftInput?.$el?.querySelector("input");
  DimLeftInput.value = Number.parseFloat(this.dataContainer.DimLeft).toFixed(2) ?? 0.00;

  //DIM RIGHT VALUE
  let DimRightInput = this.$refs?.DimRightInput?.$el?.querySelector("input");
  DimRightInput.value = Number.parseFloat(this.dataContainer.DimRight).toFixed(2) ?? 0.00;

  //DIM TOP VALUE
  let DimToptInput = this.$refs?.DimToptInput?.$el?.querySelector("input");
  DimToptInput.value = Number.parseFloat(this.dataContainer.DimTop).toFixed(2) ?? 0.00;
  
  this.Start = false;
  this.$v.$touch();
}

//Computeds:
function StatusOptions() {
  if(this.optionStatus.length ==0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionStatus?.map(function(e){
      chart.push({
        value: e.TpCargoStatusId,
        label: e.TpCargoStatusName,
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.optionCondition?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionCondition?.map(function(e){
      chart.push({
        value: e.TpCargoConditionId,
        label: e.TpCargoConditionName,
      })
    })
    return chart;
  }
}

function ContainerClassOptions() {
  if(this.optionContainerClass?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionContainerClass?.map(function(e){
      chart.push({
        value: e.TpCargoClassId,
        label: e.TpCargoClassCode,
      })
    })
    return chart;
  }
}

function ContainerOptionPortActivity() {
  if(this.optionPortActivity?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let _lang = this.$i18n.locale;
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionPortActivity?.map(function(e){
      chart.push({
        value: e.PortActivityId,
        label: _lang=='en'? e.PortActivityNameEn||'' : e.PortActivityNameEs||'',
      })
    })
    return chart;
  }
}

function ContainerOptionShippingLineCode() {
  if(this.optionShippingLineCode?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionShippingLineCode?.map(function(e){
      chart.push({
        value: e.ShippingLineId,
        label: e.ShippingLineName
      })
    })
    return chart;
  }
}

function ContainerMasterPort() {
  if(this.optionMasterPort?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionMasterPort?.map(function(e){
      chart.push({
        value: e.PortId,
        label: e.PortCode
      })
    })
    return chart;
  }
}

function ContainerPort() {
  if(this.optionPort?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.optionPort?.map(function(e){
      chart.push({
        value: e.PortId,
        label: e.PortCode
      })
    })
    return chart;
  }
}

//ISO AND SEALS
function TypeContainerOptions() {
  if(this.typeContainerOption && (this.typeContainerOption?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.typeContainerOption?.map(function(e){
      chart.push({
        value: e.MetadataId,
        label: e.MetadataDesc,
      })
    })
    return chart;
  }
}

function UnitMeasureDimrLengthOptions() {
  if(this.unitMeasureLengthDim && (this.unitMeasureLengthDim?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.unitMeasureLengthDim?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}

function UnitMeasureDimrHeightOptions() {
  if(this.unitMeasureHeightDim && (this.unitMeasureHeightDim?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.unitMeasureHeightDim?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}
function UnitMeasureWeigthOptions() {
  if(this.UnitMeasureWeigth && (this.UnitMeasureWeigth?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.UnitMeasureWeigth?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}

function UnitMeasureVgmOptions() {
  if(this.UnitMeasureVgm && (this.UnitMeasureVgm?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.UnitMeasureVgm?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}

function UnitMeasureUnitRfOptions() {
  if(this.measureUnitRf && (this.measureUnitRf?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.measureUnitRf?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}

function UnitMeasureUnitDinOptions() {
  if(this.measureUnitDin && (this.measureUnitDin?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.measureUnitDin?.map(function(e){
      chart.push({
        value: e.UnitMeasureId,
        label: e.UnitMeasureAbbrev,
      })
    })
    return chart;
  }
}

function TpPowerPlugOptions() {
  if(this.tpPowerPlug && (this.tpPowerPlug?.length === 0)){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let _lang = this.$i18n.locale;
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpPowerPlug?.map(function(e){
      chart.push({
        value: e.TpPowerPlugId,
        label: _lang=='en'? e.TpPowerPlugNameEn : e.TpPowerPlugNameEs,
      })
    })
    return chart;
  }
}

async function Conversions(newValue, OldValue, Value, input, conversions = true){
  try{
      this.$store.state.yardManagement.loading = true;
      let conversion = conversions ? Number.parseFloat(Value?.replace(/[.]/g, '')?.replace(',', '.'))?.toFixed(2) : Value;

      await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: conversion }, '').then(response => {
          if(response.status === 200){
            let  result = conversions ? NumberFormater.formatNumber(response.data.data[0].Value , 2) : response.data.data[0].Value;
            input.value =  result ?? 0.00;
          }
      }).catch(err => {
        this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);
      })
  } catch (error) {
      this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', error);
  }
  this.$store.state.yardManagement.loading = false;
}

async function conversionsRef(newValue, OldValue){
  try{
      let MaximumtInput = this.$refs?.MaximumtInput?.$el?.querySelector("input");
      let MinimumInput = this.$refs?.MinimumInput?.$el?.querySelector("input");

      this.$store.state.yardManagement.loading = true;

      await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: Number.parseFloat(this.minimunRangeRf).toFixed(2) }, '').then(response => {
          if(response.status === 200){
            let  result = response.data.data[0].Value;
            MinimumInput.value =  result ?? 0.00;
            this.minimunRangeRf = result ?? 0.00;
          }
      }).catch(err => {this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);})
      

      await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId:newValue, UnitMeasureFromId:OldValue, Value: Number.parseFloat(this.maximunRangeRf).toFixed(2) }, '').then(response => {
          if(response.status === 200){
            let  result = response.data.data[0].Value;
            MaximumtInput.value =  result ?? 0.00;
            this.maximunRangeRf = result ?? 0.00;
          }
      }).catch(err => {this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', err);})
  } catch (error) {
      this.$store.commit('yard-management/container/general-info/UnitMeasureConversion', error);
  }
  this.$store.state.yardManagement.loading = false;
}

function VerifySeal() {
  if (this.ValidateStatusSelected) {
    return false;
  } else if (!this.seal1Container && !this.seal2Container && !this.seal3Container && !this.seal4Container) {
      return true;
  } else {
    return false;
  }
}

function limitWeight(){
    let value = 0.00;
    let unitMeasure = [];
    switch (this.status) {
      case 'EMPTY':
          unitMeasure = this.weigthEmptyJson?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });

          if(unitMeasure.length > 0){
              value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
          }else{
              value = 0.00;
          }
          break;
          
      case 'FULL':
          unitMeasure = this.maxGrossWeigthJson?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });

          if(unitMeasure.length > 0){
              value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
          }else{
              value = 0.00;
          }
          break;

      default:
          value = 0.00;
          break;
  }
    return value;
}

//ESTA CONDICION SOLO SE DA SI EL CONTENEDOR ESTA FULL
function limitVgm(){
    let value = 0.00;
    let unitMeasure = [];
    if(this.status === "FULL"){

      unitMeasure = this.maxLoadJson?.filter((e) => { return  this.vgmSelected === e.UnitMeasureId });
      this.maxLoadJson
      if(unitMeasure.length > 0){
          value = Number.parseFloat(unitMeasure[0].Value).toFixed(2);
      }else{
          value = 0.00;
      }
    }
    else 
      return null
    return value;
}

function maxWeigthNumber(){
    let unitMeasure = this.UnitMeasureWeigth?.filter((e) => { return  this.weightSelected === e.UnitMeasureId });
    return  `${this.limitWeight} ${unitMeasure[0]?.UnitMeasureAbbrev ?? UnitMeasureWeigth[0].UnitMeasureAbbrev}`
}

function maxVgmNumber(){
   let unitMeasure = this.UnitMeasureVgm?.filter((e) => { return  this.vgmSelected === e.UnitMeasureId });
   return  `${this.limitVgm} ${unitMeasure[0]?.UnitMeasureAbbrev ?? UnitMeasureVgm[0].UnitMeasureAbbrev}`
}

function validDim(value){
   return  Number.parseFloat(value).toFixed(2)>0
}

function ClientOptions() {
	if(this.ClientList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ClientList.map(function(e){
      chart.push({
        value: e.ClientTpId,
        label: e.ClientName,
      })
    })
    return chart;
  }
}

function ImdgOptions() {
	if(this.ImdgList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ImdgList.map(function(e){
      chart.push({
        value: e.ImdgId,
        label: `${e[`ImdgName${_lang}`]} (${e.ImdgCod})`,
      })
    })
    return chart;
  }
}

function ItineraryOptions() {
	if(this.ItineraryList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ItineraryList.map(function(e){
      chart.push({
        value: e.ItineraryId,
        label: `${e.VesselName} - ${e.Voyage} - ${DateFormater.formatDateTimeWithSlash(e.TransaRegDate)}`,
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

function ValidateStatusSelected() {
  if (typeof this.statusSelected == 'string') {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.statusSelected?.toUpperCase();
  }else{
    return true;
  }
}

function fgUnconsolidated() {
  return this.dataContainer?.BlJson?.[0]?.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED || this.dataContainer?.BlJson?.[0]?.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_PARTIAL_UNCONSOLIDATED
}

export default {
  name: 'general-info-index',
  data,
  mixins: [GeneralMixin, ReportesVisitas, ModalMixin],
  props: { },
  components: {ClientModal},
  directives: {
    money: VMoney
  },
  validations(){
    return containerdataValidations(
      this.limitWeight, 
      this.limitVgm, 
      !this.ValidateStatusSelected, 
      this.plugged, 
      this.dryReefer, 
      {DimBack : this.DimBack, DimLeft: this.DimLeft, DimRight: this.DimRight, DimTop: this.DimTop, DimFront: this.DimFront},
      this.dataContainer.VisitCargoId != null && this.dataContainer.VisitCargoId != undefined,
      this.FgApplyOversize, 
      this.FgActShipperOwn,
      this.VerifySeal,
      this.portActivitySelected,
    );
  },
  directives: UpperCase,
  methods: {
    Submit,
    validDim,
    Conversions,
    handleTab,
    isActiveTab,
    filterStatus,
    filterCondition,
    filterClass,
    filterShippingLineCode,
    filterPortActivity,
    filterLoadPort,
    filterDischargePort,
    filterDeliveryPort,
    filterCargoOriginPort,
    filterLength,
    filterHeigth,
    filterWeight,
    filterVgm,
    filterRf,
    filterTpPowerPlug,
    getGeneralInfo,
    getTpCargoIsoCode,
    getItyneraryList,
    getYardCargo,
    filterMeasureDin,
    Reset,
    YardManagementGeneralClient,
    YardContainerNewGeneralInfo,
    conversionsRef,
    getData,
  }, 
  computed: {
    fgUnconsolidated,
    StatusOptions,
    ConditionOptions,
    ContainerOptionPortActivity,
    ContainerOptionShippingLineCode,
    ContainerMasterPort,
    ContainerPort,
    ContainerClassOptions,
    TypeContainerOptions,
    UnitMeasureDimrLengthOptions,
    UnitMeasureDimrHeightOptions,
    UnitMeasureWeigthOptions,
    UnitMeasureVgmOptions,
    UnitMeasureUnitDinOptions,
    UnitMeasureUnitRfOptions,
    TpPowerPlugOptions,
    limitWeight,
    limitVgm,
    maxWeigthNumber,
    maxVgmNumber,
    ClientOptions,
    ImdgOptions,
    ItineraryOptions,
    ValidateTpCargoStatus,
    ValidateStatusSelected,
    VerifySeal,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      yardData: state => state.yardManagement.yardData,
      dataContainer: state => state.yardManagement.dataContainer,
      ContainerTabIndex: state => state.yardManagement.ContainerTabIndex,
      VisitCargoId: state => state.yardManagement.dataContainer.VisitCargoId,
      yardCollapse: state => state.yardManagement.yardCollapse,
      isSelect: state => state.yardManagement.isSelect,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
  mounted() {
    if (this.yardCollapse == 2 && this.ValidateTpCargoStatus && this.ContainerTabIndex == 0 && !this.FgPositioning) {
      this.$store.state.yardManagement.isSelect = false;
      this.$store.state.yardManagement.ContainerTabIndex = 1;
    }
  },
  watch: {
    ContainerTabIndex: async function(newVal) {
      if(newVal==1 && !this.isSelect){
        this.$store.state.yardManagement.loading = true;
        this.Start = true;
        await this.Reset();
        this.tabinfoIndex = 0;
        await this.getTpCargoIsoCode();
        if(!this.VisitCargoId){
          await this.getItyneraryList();
          await this.YardManagementGeneralClient(true); 
          this.itinerary = this.dataContainer.ItineraryId ?? '';
          await this.YardContainerNewGeneralInfo();
        }else{
          await this.getGeneralInfo();
        }
        this.getData();
      }
    },
    isSelect: async function (NewVal) {
      if (NewVal && this.ValidateTpCargoStatus && this.yardCollapse == 2) {
        this.Start = true;
        this.$store.state.yardManagement.loading = true;
        this.$store.state.yardManagement.ContainerTabIndex = 1;
        this.$store.state.yardManagement.dropContainerMovement = 0;
        this.$store.state.yardManagement.dropItemInspection = 0;
        await this.Reset();
        this.tabinfoIndex = 0;
        await this.getTpCargoIsoCode();
        if(!this.VisitCargoId){
          await this.getItyneraryList();
          await this.YardManagementGeneralClient(true); 
          this.itinerary = this.dataContainer.ItineraryId ?? '';
          await this.YardContainerNewGeneralInfo();
        }else{
          await this.getGeneralInfo();
        }
        await this.getData();
        this.$store.state.yardManagement.isSelect = false;
      }
    },
    plugged: function(newVal) {
      if(!newVal) this.tpPowerPlugSelected = "";
    },
    typeContainerSelected: function (val) {
      this.typeContainerSelected = val 

      if(this.typeContainerSelected && this.typeContainerSelected!=''&& !this.Start){
        let typeContainer = this.typeContainerOption?.filter((e) => { return  this.typeContainerSelected === e.MetadataId }) ?? [];
        this.typeContainer = typeContainer[0]?.MetadataDesc;
        this.typecargocode = typeContainer[0]?.TpCargoCode;
        this.tpCargoName = typeContainer[0]?.TpCargoName;
        this.size = typeContainer[0]?.Size;
        this.DescriptionType = typeContainer[0]?.DescriptionType; 
        this.dryReefer = typeContainer[0]?.FgApplyRefrigeration;
        this.maxGrossWeigthJson = typeContainer[0]?.MaxGrossWeigthJson;
        this.weigthEmptyJson = typeContainer[0]?.WeigthEmptyJson;
        this.maxLoadJson = typeContainer[0]?.MaxLoadJson;

        //CHANGE TYPE
        this.plugged = false;
        this.tpPowerPlugSelected = ""
        this.afterUnitRf = "";
        this.measureUnitRfSelected = "";
        this.measureUnitDinSelected = "";
        this.FgApplyOversize = typeContainer[0]?.FgApplyOversize;

        //DIM BACK VALUE
        let DimBackInput = this.$refs?.DimBackInput?.$el?.querySelector("input");
        DimBackInput.value = 0.00;

        //DIM FRONT VALUE
        let DimFrontInput = this.$refs?.DimFrontInput?.$el?.querySelector("input");
        DimFrontInput.value = 0.00;

        //DIM LEFT VALUE
        let DimLeftInput = this.$refs?.DimLeftInput?.$el?.querySelector("input");
        DimLeftInput.value = 0.00;

        //DIM RIGHT VALUE
        let DimRightInput = this.$refs?.DimRightInput?.$el?.querySelector("input");
        DimRightInput.value = 0.00;

        //DIM TOP VALUE
        let DimToptInput = this.$refs?.DimToptInput?.$el?.querySelector("input");
        DimToptInput.value = 0.00;

        //LENGHT VALUE
        let unitMeasureLenght =  typeContainer[0].LenghtJson?.filter((e) => { return   typeContainer[0].UnitMeasureAbbrevDim === e.UnitMeasureAbbrev });
        this.lengthSelected = unitMeasureLenght[0]?.UnitMeasureId ??  typeContainer[0].LenghtJson[0].UnitMeasureId;
        this.afterUnitLength = this.lengthSelected;
        let lenghtInput = this.$refs?.lenghtInput?.$el?.querySelector("input");
        lenghtInput.value = unitMeasureLenght[0]?.Value ? Number.parseFloat(unitMeasureLenght[0]?.Value).toFixed(2) : Number.parseFloat(  typeContainer[0].LenghtJson[0].Value).toFixed(2);

        //HEIGHT VALUE
        let unitMeasureHeigth =  typeContainer[0].HeigthJson?.filter((e) => { return   typeContainer[0].UnitMeasureAbbrevDim === e.UnitMeasureAbbrev });
        this.heigthSelected = unitMeasureHeigth[0]?.UnitMeasureId ??  typeContainer[0].HeigthJson[0].UnitMeasureId;
        this.afterUnitHeigth = this.heigthSelected;
        let heightInput = this.$refs?.heightInput?.$el?.querySelector("input");
        heightInput.value = unitMeasureHeigth[0]?.Value ? Number.parseFloat(unitMeasureHeigth[0]?.Value).toFixed(2) : Number.parseFloat( typeContainer[0].HeigthJson[0].Value).toFixed(2);
      }
      else if (!this.Start) {
        this.typeContainerSelected = '';
        this.typeContainer = '';
      }
    },
  },
};
</script>
<style scope>
  .tamanio_auto {
    width: 100%; 
    overflow-x: auto;
  }

  .tabla_auto {
    width: 140%; 
  }

  .azulado {
    background-color: #7abcff;
    color: #FFF;
  }
</style>
