<template>
	<div class="col-sm-12"  style="height: 100%;">
		<apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
	</div>
</template>
<script>
	function chartOptions(){
	return {
		chart: {
			type: 'bar',
			animations: {
				enabled: true,
			}
		},
		colors: this.Colors.length!=0?this.Colors:undefined,
		xaxis: {
			max: this.Max,
			categories: this.Categories,
			labels: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				barHeight: '80%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'top',
				},
			}
		},
		grid: {
			show: false,
		},
		legend: {
			show: false
		},
		tooltip: {
			enabled: false,
		},
		dataLabels: {
			enabled: true,
			offsetX: 40,
			style: {
				fontSize: '12px',
				colors: ["#60656e"]
			}
		},
		yaxis: {
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false,
			},
			labels: {
				show: true,
				style: {
					colors: '#60656e',
				}
			}
		},
	}    
}

export default {
	name:'container-type-bar',
	props: {
		Series: {
			type: Array,
			require: true,
			default: () => [],
		},
		Categories:{
			type: Array,
			require: true,
			default: () => [],
		},
		Colors:{
			type: Array,
			require: false,
			default: () => [],
		},
		Width:{
			type: String,
			require: true,
			default: 'auto',
		},
		Height:{
			type: String,
			require: true,
			default: 'auto',
		},
		Max: {
			type: Number,
			require: true,
			default: 0,
		}
	},
	methods:{
	},
	computed:{
		chartOptions
	},
}
</script>