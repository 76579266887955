<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CModalExtended
            :title="tituloModal"
            color="dark"
            :show.sync="showModal"
            :closeOnBackdrop="false"
            size="lg"
            class="modal-content-employee"
        >
            <CRow>
                <CCol sm="6" style="margin-bottom:20px;">
                    <CInput       
                      :label="$t('label.IdNumber')"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      addLabelClasses="required text-right"
                      v-uppercase
                      required
                      :placeholder="$t('label.IdNumber')"
                      maxlength="20"
                      :is-valid="hasError($v.NumberId)"
                      v-model.trim="$v.NumberId.$model"
                      :invalid-feedback="errorMessage($v.NumberId)"
                    >
                    </CInput>
                </CCol>
                <CCol sm="6">
                    <CSelect
                        :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                        :label="$t('label.role')"
                        disabled
                        addLabelClasses="required text-right"
                        :options="rolOptions"
                        :is-valid="hasError($v.StowageRoleId)"
                        v-model.trim="$v.StowageRoleId.$model"
                        :value.sync="StowageRoleId"
                        :invalid-feedback="errorMessage($v.StowageRoleId)"
                    />
                </CCol>
                <CCol sm="6" style="margin-bottom:20px;">
                    <CInput
                        addLabelClasses="required text-right"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                        v-uppercase
                        maxlength="250"
                        :label="$t('label.name')"
                        :placeholder="$t('label.name')"
                        :is-valid="hasError($v.Name)"
                        v-model.trim="$v.Name.$model"
                        :invalid-feedback="errorMessage($v.Name)"
                    />
                </CCol>
                <CCol sm="6">
                    <CInput  
                        addLabelClasses="required text-right"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                        v-uppercase
                        maxlength="20"
                        :label="$t('label.lastName')"
                        :placeholder="$t('label.lastName')"
                        :is-valid="hasError($v.LastName)"
                        v-model.trim="$v.LastName.$model"
                        :invalid-feedback="errorMessage($v.LastName)"
                    />
                </CCol>
                <CCol sm="6">
                    <CSelect
                        :label="$t('label.status')"
                        :is-valid="Status"
                        :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                        addLabelClasses='text-right'
                        v-model="Status"
                        :value.sync="Status"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="loadingOverlay"
                >
                    <div v-if="loadingOverlay">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!loadingOverlay">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <!--<CButton outline color="success" @click="guardar" >
                <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                </CButton>-->
                <CButton color="wipe" @click="cerrarModal">
                <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
     </div>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import RecursoDetalleValidations from '@/_validations/planificacion-estiba/recursoDetalleValidations';
    import General from '@/_mixins/general';
    import { mapState } from 'vuex';

    function data() {
        return {
            nameModal:'',
            showModal: false,
            loadingOverlay: false,
            rolOptions: [],
            //MODELO
            tituloModal:'',
            Name: '',
            LastName: '',
            StowageRoleId: '',
            NumberId: '',
            StowagePlanningResourceId: '',
            StowagePlanningRoleDetId: '',
            FgSupplier: false,
            Status:true,
            CompanyStaffId: '',
        }
    }
    function registerData(){
        if (this.$v.$invalid) return false;
        this.loadingOverlay = true;

        let StowagePlanningRoleDetailJson = {
            StowagePlanningRoleDetId: this.StowagePlanningRoleDetId,
            CompanyStaffId: this.CompanyStaffId,
            StowagePlanningResourceId: this.StowagePlanningResourceId,
            NumberId: this.NumberId,
            Name: this.Name,
            LastName: this.LastName,
            Status: this.Status
        };
        let metodo = 'PUT';
        let ruta = "StowagePlanningRoleDetail-update";

        this.$http.ejecutar(metodo, ruta, StowagePlanningRoleDetailJson, { root: 'StowagePlanningRoleDetailJson' })
        .then(response => {
            let res = [...response.data.data];
            this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.success')+'!',
                text: res[0].Response,
                type: "success"
            });
            this.loadingOverlay= false;
            this.limpiarDatos();
            this.cerrarModal();
        }).catch((err) => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            this.loadingOverlay= false;
        });
    }
    async function listarRoles () {
        this.loadingOverlay = true;
        this.rolOptions = [];

        let listado = Array;
        await this.$http.get("StowageRoleVessel-list", { StowageRoleId:'', TpVesselId: this.TpVessel, Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            this.rolOptions = listado.map(listado => Object.assign({}, this.rolOptions, {
                value: listado.StowageRoleId,
                label: listado.StowageRoleName,
            }));

            this.rolOptions.unshift({
                value: "",
                label: this.$t('label.select')
            });
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
    function selectOptions(){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ]
    }
    function limpiarDatos() {
        this.CompanyStaffId = '';
        this.StowagePlanningRoleDetId = '';
        this.Name = '';
        this.LastName = '';
        this.NumberId = '';
        this.$nextTick(() => { this.$v.$reset() })
    }
    function cerrarModal() {
        this.$emit('child-refresh', true);
        this.showModal=false;
    }
    export default {
        name:'modal-edit-empleado',
        data,
        props: {
            modal: null,
        },
        mixins: [General],
        directives: UpperCase,
        validations: RecursoDetalleValidations,
        methods:{
            registerData,
            cerrarModal,
            limpiarDatos,
            listarRoles,
        },
        computed:{
            selectOptions,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVessel: state => state.planificacionestiba.TpVesselId,
            })
        },
        watch: {
            modal: function () {
                if (this.modal) {
                    this.limpiarDatos();
                    this.tituloModal = this.$t('label.edit')+this.$t('label.employeeRole')+": "+ this.modal.Name;
                    this.CompanyStaffId = this.modal.CompanyStaffId;
                    this.StowagePlanningResourceId = this.modal.StowagePlanningResourceId;
                    this.StowagePlanningRoleDetId = this.modal.StowagePlanningRoleDetId;
                    this.StowageRoleId = this.modal.StowageRoleId;
                    this.Name = this.modal.Name;
                    this.LastName = this.modal.LastName;
                    this.NumberId = this.modal.NumberId;
                    this.Status = this.modal.status;
                    //this.listarDetalle();
                    this.showModal = true;
                    this.listarRoles();
                    this.$emit('cerrarModal');
                }
            }
        }
    }
</script>