
//Data:
function data() {
  return {
    currentPage: 1,
    numberPerPage: 6,
    items: [],
  }
}

//Meodos:
function nextPage() {
  this.currentPage += 1;
}
function previousPage() {
  this.currentPage -= 1;
}
function firstPage() {
  this.currentPage = 1;
}
function lastPage() {
  this.currentPage = this.numberOfPages;
}
function resetPagination() {
  this.currentPage = 1;
  this.numberPerPage = 6;
}

//Computeds
function numberOfPages() {
  return Math.ceil(this.items.length / this.numberPerPage);
}
function pageList() {
  var begin = ((this.currentPage - 1) * this.numberPerPage);
  var end = begin + this.numberPerPage;

  return this.items.slice(begin, end);
}
function leftBtnsDisabled() {
  return this.currentPage == 1 || this.items.length == 0;
}
function rightBtnsDisabled() {
  return this.currentPage == this.numberOfPages || this.items.length == 0;
}

export default {
  data,
  methods: {
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    resetPagination,
  },
  computed: {
    numberOfPages,
    pageList,
    leftBtnsDisabled,
    rightBtnsDisabled,
  }
}