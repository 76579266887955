import Vue from 'vue';
import Vuex from 'vuex';
import { inicio } from './inicio.module';
import { alert } from './alert.module';
import { grupomodulo } from './grupomodulo.module';
import { ubicacion } from './ubicacion.module';
import { muelle } from './muelle.module';
import { unidadmedida } from './unidadmedida.module';
import { tiempoperdido } from './tiempoperdido.module';
import { marcamodelo } from './marcamodelo.module';
import { implemento } from './implemento.module';
import { contenedores } from './contenedores.module';
import { maquina } from './maquina.module';
import { auth } from './auth.module';
import { menu } from './menu.module';
import { planificacionestiba } from './planificacionestiba.module';
import { planificacionbuque } from './planificacionbuque.module';
import { planificacionEstibaErrores } from './planificacionestiba-errores.module';
import { baygrid } from './bay-grid.module';
import { blocksBayGrid } from './blocks-bays-grid';
import { servicio } from './servicio.module';
import { visitas } from './visitas.module';
import { situacionOperativaActual } from './situacionoperativa-actual.module';
import { situacionOperativaHistorico } from './situacionoperativa-historico.module';
import { Management } from './Management.module';
import { connection } from './connections.module';
import { yardsetting } from './yard-setting.module';
import { request } from './request';
import { yardManagement } from './yard-management.module';
import { yard } from './yards.module';
import { containerSearch } from './containerSearch.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        inicio,
        alert,
        grupomodulo,
        ubicacion,
        maquina,
        muelle,
        tiempoperdido,
        unidadmedida,
        marcamodelo,
        implemento,
        contenedores,
        auth,
        planificacionbuque,
        planificacionestiba,
        menu,
        baygrid,
        blocksBayGrid,
        servicio,
        planificacionEstibaErrores,
        visitas,
        Management,
        situacionOperativaActual,
        situacionOperativaHistorico,
        request,
        connection,
        yardsetting,
        yardManagement,
        yard,
        containerSearch
    }
});
