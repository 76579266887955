<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CRow>
      <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="10"
            hover
            small
            sorter
            pagination
          >
            <template #Seleccione="{item}">
              <td>
                <CButton color="add" size="sm" v-c-tooltip="{content: $t('label.select')}" @click="seleccionarDatos(item)" >
                  <CIcon name="checkAlt" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import RecursoDetalleValidations from '@/_validations/visitas/recursoDetalleValidations';
import General from '@/_mixins/general';
import {alertPropertiesHelpers} from '@/_helpers/funciones';

function fields (){
  return [
    { key: 'Nro', label: '#', _style: 'width:1%;' },
    { key: 'NumberId', label:this.$t('label.IdNumber') },
    { key: 'Name', label: this.$t('label.name') },
    { key: 'LastName', label:this.$t('label.lastName') },
    { key: 'Detalle', label: '#', _style: 'width:15%;' },
  ];
}
async function listarDetalle () {
  this.items = [];
  this.loadingTable = true;
  let i = 1;

  let listado = Array;
  await this.$http.get("VisitRoleDetail-list", { VisitResourceId: this.VisitResourceId })
  .then(response => {
    listado = [...response.data.data].filter((ob) => ob.Status=='ACTIVO');
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: i++,
      NumberId: listado.NumberId,
      Name: listado.Name,
      LastName: listado.LastName,
      VisitRoleDetId: listado.VisitRoleDetId,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    items: [],
    items2: [],
    rolOptions: [],
    //MODELO
    Name: '',
    LastName: '',
    StowageRoleId: '',
    NumberId: '',
    VisitResourceId: '',
    VisitRoleDetId: '',
    collapse: false,
  };
}

export default {
  name: 'modal-empleado',
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  validations: RecursoDetalleValidations,
  computed: {
    fields,
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.limpiarDatos();
        this.StowageRoleId = this.modal.StowageRoleId;
        /*if (this.modal.RoleJson.length > 1) {
          let arreglo = [];
          if (this.modal.PayRollId=="8E4A0880-7D8B-45BE-849A-DCB72CC46A66") {
            arreglo = this.modal.RoleJson.filter(x => x.ClientId!=null);
          } else {
            arreglo = this.modal.RoleJson.filter(x => x.ClientId==null);
          }
          this.VisitResourceId = arreglo[0].VisitResourceId;
        } else {
          this.VisitResourceId = this.modal.RoleJson[0].VisitResourceId;
        }*/
        this.rolOptions.push({
          value: this.modal.StowageRoleId,
          label: this.modal.StowageRoleName
        })
        this.listarDetalle();
      }
    }
  },
  methods: {
    listarDetalle,
  },
};
</script>
<style scope>
  .largo {
    width: 112% !important;
  }  
  .w-85 {
    width: 85% !important;
  }
  .alto {
    max-height: 500px;
    overflow-y: auto;
  }
  .medio {
    max-height: 350px;
    overflow-y: auto;
  }
</style>
