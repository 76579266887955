<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal()"
      :closeOnBackdrop="false"
      size="lg"
    >
        <CRow>
          <CCol>
            <div>
              <!-- General cargo confirmation-->
              <CTabs variant="tabs" :active-tab="0" class="mt-2">
                <CTab :title="$t('label.loadConfirmation')">
                  <CCard class="card-border">
                    <CRow sm="12" class="m-0 mt-3">
                        <CCol sm="12" lg="6">
                          <div class="form-group form-row" rol="group">
                            <label class="col-form-label-sm text-right required col-sm-12 col-lg-5 mb-0 requiered">
                              {{$t('label.date')}}
                            </label>
                            <div class="col-sm-12 col-lg-7 input-group">
                              <vue-datepicker
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="false"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="generalCargo.TransactionDate"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                                @input="ValidateDate()"
                              >
                                  <template #input>
                                    <CInput
                                      class="w-100 float-left mb-0"
                                      :value="generalCargo.TransactionDate"
                                      placeholder="DD/MM/YYYY HH:mm"
                                      :is-valid="hasError($v.generalCargo.TransactionDate)"
                                      @blur="$v.generalCargo.TransactionDate.$touch()"
                                      size="sm"
                                      @input="ValidateDate()"
                                    >
                                      <template #append-content>
                                        <CIcon name="cil-calendar" />
                                      </template>
                                    </CInput>
                                  </template>
                                  <template #icon-calendar>
                                    <div style="display: none"></div>
                                  </template>
                                </vue-datepicker>
                            </div>
                          </div>
                        </CCol>
                        <CCol sm="12" lg="6">
                          <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.movementType')"
                            :options="movementStowageOptions"
                            addLabelClasses="required text-right"
                            v-model.trim="$v.generalCargo.MovStowageId.$model"
                            :value.sync="generalCargo.MovStowageId"
                            :is-valid="hasError($v.generalCargo.MovStowageId)"
                            :invalid-feedback="errorMessage($v.generalCargo.MovStowageId)"
                            size="sm"
                            @blur="$v.generalCargo.MovStowageId.$touch()"
                          />
                        </CCol>
                        <CCol sm="12" lg="6">
                          <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.location')"
                            :options="ubicationOptions"
                            addLabelClasses="required text-right"
                            v-model.trim="$v.generalCargo.VesselUbicationId.$model"
                            :value.sync="generalCargo.VesselUbicationId"
                            :is-valid="hasError($v.generalCargo.VesselUbicationId)"
                            :invalid-feedback="errorMessage($v.generalCargo.VesselUbicationId)"
                            size="sm"
                            @blur="$v.generalCargo.VesselUbicationId.$touch()"
                            @change="filterVesselUbication($event)"
                          />
                        </CCol>
                        <CCol sm="12" lg="6">
                          <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.crane')"
                            addLabelClasses="required text-right"
                            :options="craneOptions"
                            v-model.trim="$v.generalCargo.VisitCraneId.$model"
                            :value.sync="generalCargo.VisitCraneId"
                            :is-valid="hasError($v.generalCargo.VisitCraneId)"
                            :invalid-feedback="errorMessage($v.generalCargo.VisitCraneId)"
                            size="sm"
                            @blur="$v.generalCargo.VisitCraneId.$touch()"
                            @change="filterCrane($event)"
                          />
                        </CCol>
                        <CCol sm="12" lg="6" v-if="ValidateHold || ValidateHoldDeck">
                          <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.hold')"
                            addLabelClasses="required text-right"
                            :options="holdOptions"
                            v-model.trim="$v.generalCargo.VesselHoldId.$model"
                            :value.sync="generalCargo.VesselHoldId"
                            :is-valid="hasError($v.generalCargo.VesselHoldId)"
                            :invalid-feedback="errorMessage($v.generalCargo.VesselHoldId)"
                            size="sm"
                            @blur="$v.generalCargo.VesselHoldId.$touch()"
                            @change="filterVesselHatch($event)"
                          />
                        </CCol>
                        <CCol sm="12" lg="6" v-if="ValidateHoldDeck">
                          <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.hatchCover')"
                            addLabelClasses="required text-right"
                            :options="vesselHatchOptions"
                            v-model.trim="$v.generalCargo.VesselHoldHatchCId.$model"
                            :value.sync="generalCargo.VesselHoldHatchCId"
                            :is-valid="hasError($v.generalCargo.VesselHoldHatchCId)"
                            :invalid-feedback="errorMessage($v.generalCargo.VesselHoldHatchCId)"
                            size="sm"
                            @blur="$v.generalCargo.VesselHoldHatchCId.$touch()"
                          />
                        </CCol>
                        <CCol sm="12" lg="6">
                          <CTextarea
                            :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            :label="$t('label.observation')"
                            addLabelClasses="text-right"
                            v-uppercase
                            v-model.trim="$v.generalCargo.Observation.$model"
                            :value.sync="generalCargo.Observation"
                            :is-valid="hasError($v.generalCargo.Observation)"
                            :invalid-feedback="errorMessage($v.generalCargo.Observation)"
                            size="sm"
                            maxlength="150"
                          />
                        </CCol>
                        <CCol sm="12" lg="6" v-if="isEdit">
                          <CSelect
                            :label="$t('label.status')"
                            addLabelClasses="text-right required"
                            :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-7'}"
                            :value="generalCargo.Status"
                            :is-valid="statusSelectColor"
                            v-model.trim="generalCargo.Status"
                            :options="statusOptions"
                            size="sm"
                          />
                      </CCol>

                    </CRow>
                  </CCard>
                </CTab>
              </CTabs>
              <table class="text-center mb-3" style="width: 100%;">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <div class="row m-0 align-items-center justify-content-center">
                        <span class="circle planned-quantity mr-1"></span>
                        {{`${$t('label.planned')}`}}
                      </div>
                    </th>
                    <th>
                      <div class="row m-0 align-items-center justify-content-center">
                        <span class="circle identified-quantity mr-1"></span>
                        {{`${$t('label.Identified')}`}}
                      </div>
                    </th>
                    <th>
                      <div class="row m-0 align-items-center justify-content-center">
                        <span class="circle dispahed-quantity mr-1"></span>
                        {{`${$t('label.Loaded')}`}}
                      </div>
                    </th>
                    <th>
                      <div class="row m-0 align-items-center justify-content-center">
                        <span class="circle remainder-quantity mr-1"></span>
                        {{`${$t('label.remainder')}`}}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-right"><b>{{ $t('label.packaging') }}</b></td>
                    <td>{{ FormatNumber(QuantityPlanning, 2) }}</td>
                    <td>{{ FormatNumber(QuantityIdentified, 2) }}</td>
                    <td>{{ FormatNumber(QuantityStowage, 2) }}</td>
                    <td>{{ FormatNumber(Remain, 2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-right"><b>BL</b></td>
                    <td>{{ FormatNumber(QuantityPlanningBl, 2) }}</td>
                    <td>{{ FormatNumber(QuantityIdentifiedBl, 2) }}</td>
                    <td>{{ FormatNumber(QuantityStowageRateBl, 2) }}</td>
                    <td>{{ FormatNumber(RemainStowageBl, 2) }}</td>
                  </tr>
                </tbody>
              </table>

              <CRow class="mx-0">
                <CRow class="mx-0 col-sm-12 col-lg-10 px-0">
                  <CCol sm="12" lg="6">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                      :label="$t('label.packaging')"
                      :options="packagingOptions"
                      addLabelClasses="text-right required"
                      v-model.trim="$v.PackagingId.$model"
                      :value.sync="PackagingId"
                      :is-valid="hasError($v.PackagingId)"
                      :invalid-feedback="errorMessage($v.PackagingId)"
                      size="sm"
                      @change="filterDetail($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-uppercase
                      label="BL"
                      :options="BlOptions"
                      addLabelClasses="required text-right"
                      :placeholder="$t('label.select')"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      v-model="$v.VisitBillOfLadingPackagingId.$model"
                      :is-valid="hasError($v.VisitBillOfLadingPackagingId)"
                      :invalid-feedback="errorMessage($v.VisitBillOfLadingPackagingId)"
                      @change="filterBl($event)"
                      :disabled="PackagingId ? false : true"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                      <div class="form-group form-row">
                        <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{$t('label.quantity')}}</label>
                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                          <money
                            size="sm"
                            v-bind="measure" 
                            :class="!$v.Quantity.$dirty ? 'form-control' : ($v.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                            v-model="$v.Quantity.$model"
                            addLabelClasses="required text-right"
                            maxlength= "13"
                            :disabled ="PackagingId ? false : true"
                          >
                          </money>
                          <div class="invalid-feedback" v-if="$v.Quantity.$error">
                            {{ errorMessage($v.Quantity) }}
                          </div>
                        </div>
                      </div>  
                    </CCol>
                </CRow>        
                <CCol sm="12" lg="auto" style="text-align: left;">
                  <CButton
                    color="add"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
                    :disabled="isSubmit"
                    @click="SubmitPackaging"
                  >
                    <CIcon name="checkAlt"/>
                  </CButton>
                  <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
                    @click="clearFilters"
                  >
                    <CIcon name="cil-brush-alt" />
                  </CButton>
                </CCol>
                
              </CRow>

              <CCol sm="12" lg="12" class="justify-content-center">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items-per-page="5"
                  :items="ComputedPackaging"
                  :fields="Packagingfields"
                  column-filter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  sorter
                  >

                    <template #Status="{ item }">
                      <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                      </td>
                    </template>
                    <template #options="{ item }">
                      <td class="text-center">
                        <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-2"
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.imdg'),
                            placement: 'top-end'
                            }"
                          @click="EditPackaging(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          class="btn btn-sm btn-wipe"
                          v-c-tooltip="{
                            content: $t('label.delete')+$t('label.detail'),
                            placement: 'top-end',
                          }"
                          @click="DeletePackaging(item)"
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                  <p v-if="PackagingJsonList.length === 0" class="mt-2 text-danger required">
                    {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')+' 1 '+$t('label.detail')}}
                  </p>
              </CCol>
            </div>
          </CCol>
        </CRow>

      <template #footer>
        <CButton outline color="add" :disabled="isSubmit"
          @click.stop="isEdit ? statusConfirmation(stowageItem.FgActVisitCraneDetail,generalCargo.Status, submit) : submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal"  :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import stowageValidation from '@/_validations/visitas/general-cargo/shipment/stowageValidation';
import { Money } from "v-money";

function data() {
  return {
    VisitCraneDetailPackagingId: '',
    VisitCraneDetailId: '',
    generalCargo: {
      TransactionDate: '',
      VisitCraneId: '',
      VesselHoldId: '',
      VesselHoldHatchCId: '',
      Observation: '',
      MovStowageId: '',
      VesselUbicationId: '',
      Status: 0,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },

    PackagingId: '',
    VisitBillOfLadingPackagingId: '',
    Quantity: 0,
    QuantityPlanning: 0,
    QuantityStowage: 0,
    QuantityIdentified: 0,
    ValidateQuantity: 0,
    Remain: 0,
    QuantityPlanningBl: 0,
    QuantityIdentifiedBl: 0,
    QuantityStowageRateBl: 0,
    RemainStowageBl: 0,
    previousDate: new Date(),
    laterDate: new Date(),
    isEditPackaging: false,

    ValidPreviousDate: '',
    ValidLaterDate: '',

    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    craneList: [],
    holdList: [],
    VesselHatchList: [],
    packagingList: [],
    blList: [],
    ubicationList: [],
    generalCargoMovStowageList: [],
    PackagingJsonList: [],
    VisitCargoId: '',
    VisitId: '',
  };
}

  function closeModal() {
    this.refreshComponent();
    this.$emit('closeModal');
  }

  function refreshComponent() {
    //GeneralCargo
    this.generalCargo.TransactionDate = '';
    this.generalCargo.VesselUbicationId = '';
    this.generalCargo.VisitCraneId = '';
    this.generalCargo.VesselHoldId = '';
    this.generalCargo.VesselHoldHatchCId = '';
    this.generalCargo.Observation = '';
    this.generalCargo.MovStowageId = '';
    this.generalCargo.Status = 0;
    this.PackagingId = '';
    this.VisitBillOfLadingPackagingId = '';
    this.Quantity = 0;
    this.QuantityPlanning = 0;
    this.QuantityStowage = 0;
    this.QuantityIdentified = 0;
    this.ValidateQuantity = 0;
    this.Remain = 0;
    this.isEditPackaging = false;
    this.QuantityPlanningBl = 0;
    this.QuantityIdentifiedBl = 0;
    this.QuantityStowageRateBl = 0;
    this.RemainStowageBl = 0;

    //Arrys
    this.craneList = [];
    this.holdList = [];
    this.VesselHatchList = [];
    this.packagingList = [];
    this.blList = [];
    this.ubicationList = [];
    this.generalCargoMovStowageList = [];
    this.PackagingJsonList = [];
    this.VisitCargoId = '';
    this.VisitCraneDetailPackagingId = '';

    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.generalCargo.$touch();
      
      let ValidateList = this.PackagingJsonList.some(item => item.Status !== 0);

      if (!ValidateList || this.$v.generalCargo.$error  || this.PackagingJsonList.length === 0 ) 
      {
        this.loadingOverlay = false;
        this.isSubmit = false;

        if(DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined){
          throw this.$t('label.ErrorAtaPleaseCheck');
        }
        if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.generalCargo.TransactionDate != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        
        throw this.$t('label.errorsPleaseCheck');
      }

      let PackagingJson = [];
      this.PackagingJsonList.map((e) =>{
        PackagingJson.push({
            VisitCraneDetailPackagingId: e.VisitCraneDetailPackagingId ?? '',
            PackagingId: e.PackagingId ?? '',
            VisitBillOfLadingPackagingId: e.VisitBillOfLadingPackagingId ?? '',
            Quantity: e.Quantity ?? 0,
            Status: e.Status,
          });
        }
      )

      let QuantityAccumulator = PackagingJson.reduce((accumulator, currentValue) => accumulator += currentValue?.Quantity, 0);

      let Quantity = Number(QuantityAccumulator);
     
       let GeneralCargoJson = this.isEdit ? {
          VisitCraneDetailId: this.VisitCraneDetailId,
          VisitId: this.Visit,
          VesselHoldId: this.generalCargo.VesselHoldId,
          VesselUbicationId: this.generalCargo.VesselUbicationId,
          VesselHoldHatchCId: this.generalCargo.VesselHoldHatchCId,
          MovStowageId:this.generalCargo.MovStowageId,
          VisitCraneId: this.generalCargo.VisitCraneId,
          TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate),
          PackagingJson: PackagingJson,
          Observation: this.generalCargo.Observation,
          Quantity: Quantity,
          Status: this.generalCargo.Status
        } : {
          VisitId: this.Visit,
          VisitCraneId: this.generalCargo.VisitCraneId,
          TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate),
          VesselHoldId: this.generalCargo.VesselHoldId,
          VesselUbicationId: this.generalCargo.VesselUbicationId,
          VesselHoldHatchCId: this.generalCargo.VesselHoldHatchCId,
          PackagingJson: PackagingJson,
          Quantity: Quantity,
          Observation: this.generalCargo.Observation,
          MovStowageId:this.generalCargo.MovStowageId,
        }
      
      let ruta = this.isEdit ? 'VisitGeneralCargoLoadConfirmation-update' : 'VisitGeneralCargoLoadConfirmation';
      let method = this.isEdit ? 'PUT':'POST';
      
      await this.$http
        .ejecutar (method, ruta, GeneralCargoJson, { root: 'GeneralCargoJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.$emit('child-refresh');
            this.closeModal();
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
            this.loadingOverlay = false;
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  async function getDateRange(){
    let currentDate = new Date();
    if(this.itinerarySelected.Ata!=undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    }

    if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
    }

    if(this.itinerarySelected.Atd!=undefined){
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
    }

    if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
      this.ValidLaterDate = DateFormater.formatTimeZoneToDateTimewithDash(this.laterDate);
    }
  }

  async function getCraneList() {
    this.loadingOverlay = true;
      await this.$http
        .get('VisitVesselCrane-list?VisitId='+this.Visit)
           .then(async response => {
            this.craneList = response.data.data ?? [];
            await this.getHoldList();
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }


  async function filterCrane(event) { 
    this.generalCargo.VisitCraneId = event.target.value;
    this.generalCargo.VesselHoldId = '';
    this.holdList = [];
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
   
    await this.getHoldList();
  }

  async function getHoldList() { 
    if (this.generalCargo.VisitCraneId != '') {
      this.loadingOverlay = true;
      await this.$http.get( 'VisitVesselCraneHold-list', { VisitId: this.Visit, VisitCraneId: this.generalCargo.VisitCraneId })
      .then(response => {
        this.holdList = response.data.data ?? [];
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).finally(() => {
        this.loadingOverlay = false;
      });
    }
    else{
      this.generalCargo.VesselHoldId = '';
      this.holdList = [];
      this.generalCargo.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }  
  }

  function filterVesselHatch(event) {
    this.generalCargo.VesselHoldId = event.target.value;
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    this.getVesselHatch(this.generalCargo.VesselHoldId);
  }

  function getVesselHatch(Id) {
    if(this.generalCargo.VesselHoldId ){
      let data = this.holdList.find((val) => val.VesselHoldId == Id);
      this.VesselHatchList = data?.VesselHatchJson ?? [];
    }else{
      this.generalCargo.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }
  }

  async function getMovementStowageList() {
      await this.$http
      .get(`VisitMovStowageGeneralCargo-list?VisitId=${this.Visit}&VisitCargoId=${this.VisitCargoId}`)
      .then(response => {
        let listado = response.data.data && response.data.data[0] ? response.data.data[0] : {};
        if (Object.keys(listado).length != 0){
          this.generalCargoMovStowageList = listado?.GeneralCargoMovStowageJsonList ?? [];
        }
      }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function getPackagingList() {
    await this.$http
      .get('VisitGeneralCargoExportPackagingToShipmentResum-by-VisitId?VisitId='+this.Visit)
        .then(response => {
          this.packagingList = response.data.data ?? [];
        }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function getBlList () {
    this.loadingOverlay = true;
    let list = [];
    let VisitJson = [{
        VisitId: this.Visit,
        PackagingId: this.PackagingId
    }];
    await this.$http.post("VisitBillOfLadingExportPackaging-list", VisitJson, { root: 'VisitJson' })
    .then(response => {
        list = response.data.data ?? [];
        this.blList = Object.keys(list[0]).length>1 ? list : [];
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    })
    .finally(() => {
        this.loadingOverlay = false;
    });
  }

  async function getUbicationList() {
    await this.$http
      .get('VesselUbication-list')
        .then(response => {
          let listado = response.data.data;
          if (listado && listado.length != 0){
            this.ubicationList = listado;
          }
        })
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function filterVesselUbication(event) {
    this.generalCargo.VesselUbicationId = event.target.value;
    this.generalCargo.VisitCraneId = '';
    this.craneList = [];
    this.generalCargo.VesselHoldId = '';
    this.holdList = [];
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    await this.getCraneList();
  }

  async function filterDetail(event) {
    this.PackagingId = event.target.value;
    this.QuantityPlanning = 0;
    this.QuantityStowage = 0;
    this.ValidateQuantity = 0;
    this.VisitBillOfLadingPackagingId = 0;
    this.QuantityPlanningBl = 0;
    this.QuantityIdentifiedBl = 0;
    this.QuantityStowageRateBl = 0;
    this.RemainStowageBl = 0;
    this.Remain = 0;
    this.Quantity = 0;
    this.blList = [];
    this.isEditPackaging = false;

    await this.getQuantityData(this.PackagingId);
    await this.getBlList()
  }

  async function getQuantityData(Id) {
    if(this.PackagingId){
      let data = this.packagingList.find((val) => val.PackagingId == Id);
      this.QuantityPlanning = data?.QuantityPlanning ?? 0;
      this.QuantityIdentified = data?.QuantityIdentify ?? 0;
      this.QuantityStowage = data?.QuantityStowage ?? 0;
      this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowage).toFixed(2));
      this.Remain = data?.Remain ?? 0;
    }else{
      this.QuantityPlanning = 0;
      this.QuantityStowage = 0;
      this.QuantityIdentified = 0; 
      this.ValidateQuantity = 0;
      this.Remain = 0;

      this.QuantityPlanningBl = 0;
      this.QuantityIdentifiedBl = 0;
      this.QuantityStowageRateBl = 0;
      this.RemainStowageBl = 0;
      this.VisitBillOfLadingPackagingId = 0;
      this.blList = [];
    }
  }

  async function filterBl(event) {
    this.VisitBillOfLadingPackagingId = event.target.value;
    this.QuantityPlanningBl = 0;
    this.QuantityIdentifiedBl = 0;
    this.QuantityStowageRateBl = 0;
    this.RemainStowageBl = 0;
    this.Quantity = 0;
    this.isEditPackaging = false;

    await this.getQuantityDataBl(this.VisitBillOfLadingPackagingId);
  }

  async function getQuantityDataBl(Id) {
      let data = this.blList.find((val) => val.VisitBillOfLadingPackagingId == Id);
      this.QuantityPlanningBl = data?.QuantityPlanning ?? 0;
      this.QuantityIdentifiedBl = data?.QuantityIdentify ?? 0;
      this.QuantityStowageRateBl = data?.QuantityStowage ?? 0;
      this.RemainStowageBl = data?.RemainStowage ?? 0;
  }

  async function getListApis() {
    this.loadingOverlay = true;
    let peticiones =  
      [
        this.$http.ejecutar("GET", "VesselUbication-list"),
        this.$http.ejecutar("GET", 'VisitVesselCrane-list?VisitId='+this.Visit),
        this.$http.ejecutar("GET", `VisitMovStowageGeneralCargo-list?VisitId=${this.Visit}&VisitCargoId=${this.VisitCargoId}`),
        this.$http.ejecutar("GET", 'VisitGeneralCargoExportPackagingToShipmentResum-by-VisitId?VisitId='+this.Visit),
        this.$http.ejecutar("GET", "VisitVesselCraneHold-list", { VisitId: this.Visit, VisitCraneId: this.generalCargo.VisitCraneId }),
      ];
    await Promise.all(peticiones)
      .then((responses) => {

       
        this.ubicationList = responses[0].data.data ?? [];
        this.craneList = responses[1].data.data ?? [];
        let listado = responses[2].data.data && responses[2].data.data[0] ? responses[2].data.data[0] : {};
        if (Object.keys(listado).length != 0){
          this.generalCargoMovStowageList = listado?.GeneralCargoMovStowageJsonList ?? [];
        }
        this.packagingList = responses[3].data.data ?? [];
        if(this.isEdit){
          this.holdList = responses[4].data.data ?? []; 
        }else{
          this.holdList = [];
        }
        this.loadingOverlay = false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }

  function SubmitPackaging(){
    try {
      this.$v.PackagingId.$touch();
      this.$v.Quantity.$touch();

      if (this.$v.PackagingId.$error || this.$v.Quantity.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }
      if (this.PackagingJsonList.some(item => item.PackagingId == this.PackagingId && item.FgEdit
        && item.Status==1)) {
        throw this.$t('label.ItemPreviouslyRegistered');
      }
      if (this.Quantity > this.RemainStowageBl) {
        throw this.$t('label.quantityRemainErrorBl');
      }
     

      if (this.PackagingJsonList.some(item => item.PackagingId == this.PackagingId)) {
        this.PackagingJsonList = this.PackagingJsonList.map(Packaging => {
          if (Packaging.PackagingId == this.PackagingId) {
            return {
              ...Packaging,
              Quantity: this.Quantity ? Number.parseInt(this.Quantity) : 0,
              Status: 1,
            }
          } else {
            return Packaging;
          }
        })
      } else {
        let packagingData =  this.packagingList.find( (val) =>  val.PackagingId == this.PackagingId);
        let blData = this.blList.find( (val) =>  val.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId);
        this.PackagingJsonList.push({
          IdX: this.PackagingJsonList.length > 0 ? this.PackagingJsonList[this.PackagingJsonList.length - 1].IdX + 1 : 1,
          PackagingId: this.PackagingId ?? '',
          VisitBillOfLadingPackagingId: blData?.VisitBillOfLadingPackagingId ?? '',
          NroBl: blData?.NroBl ?? '',
          PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
          PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
          Quantity: this.Quantity ? Number.parseInt(this.Quantity) : 0,
          FgEdit: this.isEditPackaging,
          Status: 1,
        });
      }
    this.clearFilters();
    } catch (error) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
  }

  function clearFilters() {
    this.PackagingId = '';
    this.Quantity = 0;
    this.QuantityPlanning = 0;
    this.QuantityStowage = 0;
    this.Remain = 0;
    this.QuantityIdentified = 0;
    this.QuantityPlanningBl = 0;
    this.QuantityIdentifiedBl = 0;
    this.QuantityStowageRateBl = 0;
    this.RemainStowageBl = 0;
    this.VisitBillOfLadingPackagingId = 0;
    this.blList = [];
    this.isEditPackaging = false;

    this.$v.PackagingId.$reset();
    this.$v.VisitBillOfLadingPackagingId.$reset();
    setTimeout(() => {
      this.$v.Quantity.$reset();
    }, 10);
  }

  async function EditPackaging(item){
    this.isEditPackaging = true;
    this.PackagingId = item.PackagingId;
    await this.getQuantityData(item.PackagingId);
    await this.getBlList();
    await this.getQuantityDataBl(item.VisitBillOfLadingPackagingId);
    this.VisitBillOfLadingPackagingId = item.VisitBillOfLadingPackagingId;
    this.Quantity = item.Quantity;

    this.$v.PackagingId.$touch();
    this.$v.VisitBillOfLadingPackagingId.$touch();
    this.$v.Quantity.$touch();
  }

  function DeletePackaging(item) {
    this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.deleteQuestion')}?`,
        }))
      .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitCraneDetailPackagingId) {
          this.PackagingJsonList = this.PackagingJsonList.map(Packaging => {
            if (Packaging.VisitCraneDetailPackagingId == item.VisitCraneDetailPackagingId) {
              return {
                ...Packaging,
                Status: 0,
              }
            } else {
              return Packaging;
            }
          })
        }else{
          this.PackagingJsonList = this.PackagingJsonList.filter(e => e.IdX != item.IdX);
        }      
      }
    });
  }

  function statusSelectColor() {
    return this.generalCargo.Status === 1;
  }

  async function getData(val){
    this.VisitCraneDetailId = val.VisitCraneDetailId ?? '',
    this.VisitCraneDetailPackagingId = val.VisitCraneDetailPackagingId ?? '';
    this.generalCargo.TransactionDate = val.TransactionDate ?? '';
    this.generalCargo.VisitCraneId = val.VisitCraneId ?? '';
    this.generalCargo.VesselUbicationId = val.VesselUbicationId ?? '';
    this.generalCargo.VesselHoldId = val.VesselHoldId ?? '';
    this.generalCargo.VesselHoldHatchCId = val.VesselHoldHatchCId ?? '';
    this.generalCargo.MovStowageId = val.MovStowageId ?? '';
    this.generalCargo.Status = val.Status ? 1:0;
    this.PackagingJsonList = val.PackagingJson && val.PackagingJson.length!=0 ? val.PackagingJson : [];
    this.PackagingJsonList = this.PackagingJsonList.map((item, index) => {
      return {
        ...item,
        IdX: index+1,
        NroBl: item.NroBl,
        Status: 1,
      }
    })

    await this.getHoldList(this.generalCargo.VisitCraneId);

    this.$v.generalCargo.$touch();
  }

  async function ValidateDate(){
    try {
      if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
          throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if(this.generalCargo.TransactionDate != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
    }catch (error) {
      this.notifyError({text: error });
    }
  }

  function FormatNumber(Number) {
    return NumberFormater.formatNumber(Number, 2);
  }


  //computed
  function craneOptions(){
    const chart = [
        {
            value: "",
            label: this.$t('label.select'),
        },
    ];
    this.craneList.map((item) => {
        chart.push({
            label: item.CraneAlias,
            value: item.VisitCraneId,
        });
    });
    return chart;
  }

  function holdOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.holdList.map((e) =>{
      chart.push({
        value: e.VesselHoldId,
        label: e.VesselHoldName,
      })
    })
    return chart;
  }

  function vesselHatchOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.VesselHatchList.map((e) =>{
      chart.push({
        value: e.VesselHoldHatchCId,
        label: e.HatchCoverName,
      })
    })
    return chart;
  
  }

  function directDischargeOptions(){
    return [
        { 
            value: '', 
            label: this.$t('label.select')
        },
        { 
            value: true, 
            label: this.$t('label.yes')
        },
        { 
            value: false, 
            label: 'NO'
        }
    ];
  }

  function ubicationOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.ubicationList.map((e) => {
      chart.push({
        value: e.VesselUbicationId,
        label: e[`VesselUbicationName${_lang}`]
      });
    })
    return chart;
  }

  function movementStowageOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    const chart = [
        {
          value: "",
          label: this.$t('label.select'),
        },
    ];
      this.generalCargoMovStowageList.map((item) => {
        chart.push({
            label: item[`MovStowageName${_lang}`],
            value: item.MovStowageId,
        });
      });
    return chart;
  }

  function packagingOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.packagingList.map((e) => {
      chart.push({
        value: e.PackagingId,
        label: e[`PackagingName${_lang}`]
      });
    })
    return chart;
  }

  function BlOptions() {
    let chart = [{
      label: this.$t('label.select'), 
      value: '',
    }]
    this.blList.map(item => {
      chart.push({
        value: item.VisitBillOfLadingPackagingId, 
        label: item.NroBl,
      })
    })
    return chart;
  }

  
  function Packagingfields(){
    return [
      { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
      { key: 'NroBl', label: 'Bl',  _classes: 'text-uppercase text-center', _style: 'width:30%;'},
      { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:32%;'},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:15%;'},
      { key: 'options', label: '', _style: 'width:1%; min-width:90px; text-align:center;', sorter: false, filter: false },
    ];
  }

  function ComputedPackaging() {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let List = this.PackagingJsonList.filter(item => item.Status != 0);
    return List.map((item, index) => {
      return {
        ...item,
        Nro: index+1,
        NroBl: item.NroBl,
        PackagingName: item['PackagingName'+_lang] ?? 'N/A',
        Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
      }
    });
  }

  function titleModal() {
    return  this.isEdit ? this.$t('label.edit')+' '+this.$t('label.loadConfirmation') :
            this.$t('label.nueva')+' '+this.$t('label.loadConfirmation');
  }

  function ValidateHoldDeck() {
    if (typeof this.generalCargo.VesselUbicationId == 'string') {
      return this.generalCargo.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VESSEL_UBICATION_ID_DECK_HOLD;
    }else{
      return false;
    }
  }

  function ValidateHold() {
    if (typeof this.generalCargo.VesselUbicationId == 'string') {
      return this.generalCargo.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_HOLD;
    }else{
      return false;
    }
  }

  function ValidateDeck() {
    if (typeof this.generalCargo.VesselUbicationId == 'string') {
      return this.generalCargo.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_DECK;
    }else{
      return false;
    }
  }

  


export default {
  name: 'modal-stowage',
  data,
  props: { modal: Boolean, stowageItem: Object, isEdit: Boolean },
  components: { Money },
  mixins: [ModalMixin, mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){
    return stowageValidation(this.ValidLaterDate, this.ValidPreviousDate, this.ValidateHoldDeck,
    this.ValidateHold, this.RemainStowageBl);
  },

  watch: {
    modal: async function (val) {
      setTimeout(() => {
        this.$v.$reset();
      }, 10);
      this.showModal = val;
      if(val){
        await this.getDateRange();
        await this.getListApis();
        if (this.isEdit) {
           await this.getData(this.stowageItem);
        }
      
      }
    },
    Quantity: function (val) {
      try {
        //this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowage).toFixed(2));

        if (NumberFormater.setNum(val) > 0) {
          if (NumberFormater.setNum(val) > NumberFormater.setNum(this.RemainStowageBl)) {
            throw this.$t('label.quantityRemainErrorBl');
          }
        }
      } catch (error) {
          this.notifyError({text: error});
      }
    },
  },
  methods: {
    closeModal,
    getCraneList,
    filterCrane,
    filterVesselHatch,
    filterVesselUbication,
    filterDetail,
    getQuantityData,
    filterBl,
    getQuantityDataBl,
    getVesselHatch,
    getHoldList,
    getMovementStowageList,
    getPackagingList,
    getBlList,
    getUbicationList,
    getListApis,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    SubmitPackaging,
    clearFilters,
    EditPackaging,
    DeletePackaging,
    statusSelectColor,
    getData,
    ValidateDate,
    FormatNumber
  },
  computed: {
    craneOptions,
    holdOptions,
    vesselHatchOptions,
    directDischargeOptions,
    ubicationOptions,
    movementStowageOptions,
    packagingOptions,
    BlOptions,
    Packagingfields,
    ComputedPackaging,
    titleModal,
    ValidateHoldDeck,
    ValidateHold,
    ValidateDeck,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      branch: state => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss">
.status-open {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #42aa91 !important;
  }

}
.status-close {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #FF8000 !important;
  }
  .form-control:focus {
    border-color: #FF8000 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
    box-shadow: 0 0 0 0.1rem #FF8000 !important;
  }
}
.status-inactive {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #e1373f !important;
  }
  .form-control:focus {
    border-color: #e1373f !important;
    -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
    box-shadow: 0 0 0 0.1rem #e1373f !important;
  }
}
.status-EndOperation {
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #1A3760 !important;
  }
  .form-control:focus {
    border-color: #1A3760 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
    box-shadow: 0 0 0 0.1rem #1A3760 !important;
  }
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}
</style>
