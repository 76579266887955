<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="xl"
      :show.sync="modalActive"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="5">
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        addLabelClasses="required text-right"
                        :placeholder="$t('label.circuitNameLabelEs')"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-5',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        v-uppercase
                        :label="$t('label.circuitNameLabelEs')"
                        size="sm"
                        maxlength="100"
                        minlength="3"
                        v-model.trim="$v.circuitInfo.CircuitNameEs.$model"
                        :invalid-feedback="errorMessage($v.circuitInfo.CircuitNameEs)"
                        :is-valid="hasError($v.circuitInfo.CircuitNameEs)"
                      />
                    </CCol>
                    <CCol sm="12">
                      <CInput
                        addLabelClasses="required text-right"
                        :placeholder="$t('label.circuitNameLabelEn')"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-5',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        v-uppercase
                        :label="$t('label.circuitNameLabelEn')"
                        size="sm"
                        maxlength="100"
                        minlength="3"
                        v-model.trim="$v.circuitInfo.CircuitNameEn.$model"
                        :invalid-feedback="errorMessage($v.circuitInfo.CircuitNameEn)"
                        :is-valid="hasError($v.circuitInfo.CircuitNameEn)"
                      />
                    </CCol>
                    <CCol sm="12">
                       <div role="group" class="form-group form-row">
                        <label
                          class="
                            text-right
                            required
                            col-form-label col-sm-12 col-lg-5 col-form-label-sm
                          "
                        >
                          {{ $t('label.mandatory') }}
                        </label>
                        <div class="col-sm-12 col-lg-7 input-group-sm">
                          <CInputRadioGroup
                            class="mt-1"
                            size="sm"
                            :options="mandatoryOptions"
                            :checked.sync="mandatory"
                            inline
                          />
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.order')"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-5',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        :options="orderItems"
                        :value.sync="$v.circuitInfo.Order.$model"
                        :invalid-feedback="
                          errorMessage($v.circuitInfo.Order)
                        "
                        :is-valid="hasError($v.circuitInfo.Order)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12">
                      <CInput
                        v-model="$v.circuitInfo.Color.$model"
                        :invalid-feedback="errorMessage($v.circuitInfo.Color)" 
                        v-uppercase
                        :is-valid="hasError($v.circuitInfo.Color)" 
                        size="sm"  
                        type="color"                     
                        :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                        :label="$t('label.color')"
                        addLabelClasses="required text-right"    
                        required
                      />
                    </CCol>
                     <CCol sm="12" v-if="edit">
                      <div>
                        <CSelect
                          :value.sync="circuitInfo.Status"
                          :is-valid="statusSelectColor()"
                          :horizontal="{
                            label: 'col-sm-12 col-lg-5',
                            input: 'col-sm-12 col-lg-7',
                          }"
                          size="sm"
                          :label="$t('label.status')"
                          :options="statusOptions"
                          addLabelClasses="text-right"
                        />
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="7">
                  <CRow>
                    <CCol sm="6">
                       <CCard>
                        <CCardHeader color="dark" class="text-center">
                          {{ $t('label.require') }}
                        </CCardHeader>
                        <CCardBody>
                          <CRow>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgCrane"
                                :label="`${$t('label.crane')}`"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgMachine"
                                :label="`${$t('label.machineryInYard')}`"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgWeighingScale"
                                :label="`${$t('label.truckScale')}`"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgHold"
                                :label="`${$t('label.hold')}`"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgRadioActivityLevel"
                                :label="`${$t('label.radioactivityLevel')}`"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked.sync="circuitInfo.FgYard"
                                :label="`${$t('label.yard')}`"
                              />
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                    <CCol sm="6">
                      <CCard>
                        <CCardHeader color="dark" class="text-center">
                          {{ $t('label.date') }}
                        </CCardHeader>
                        <CCardBody>
                          <CRow>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked="circuitInfo.FgApplyDate"
                                :label="`${$t('label.date')}`"
                                @update:checked="selectDate($event, 1)"
                              />
                            </CCol>
                            <CCol sm="12">
                              <CInputCheckbox
                                custom
                                :checked="circuitInfo.FgApplyDateRange"
                                :label="`${$t('label.dateRange')}`"
                                @update:checked="selectDate($event, 2)"
                              />
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import CircuitValidation from "@/_validations/metalscrap/CircuitFormValidations.js";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import MetalScrap from "@/_mixins/metalscrap";
import mixinServicio from "@/_mixins/servicio";

//DATA
function data() {
  return {
    modalActive: false,
    circuitInfo: {
      CircuitId: "",
      CompanyBranchId: '',
      CircuitNameEs: '',
      CircuitNameEn: '',
      Order: "",
      Color: "",
      FgRequired: false,
      FgCrane: false,
      FgWeighingScale: false,
      FgMachine: false,
      FgYard: false,
      FgHold: false,
      FgRadioActivityLevel: false,
      FgApplyDate: false,
      FgApplyDateRange: false,
      Status: 1,
    },
    orderItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    oldStatus: 1,
    loading: false,
    mandatory: 'SI',
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else if(this.edit && this.circuitId) this.loadData();

  this.modalActive = newVal;
}
function resetForm() {
  let arrKeys = Object.keys(this.circuitInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.circuitInfo[arrKeys[i]] = 1;
        break;
      case "FgRequired":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgCrane":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgWeighingScale":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgMachine":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgYard":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgHold":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgRadioActivityLevel":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgApplyDate":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      case "FgApplyDateRange":
        this.circuitInfo[arrKeys[i]] = false;
        break;
      default:
        this.circuitInfo[arrKeys[i]] = "";
    }
  }
  this.mandatory = 'SI';
  this.oldStatus = 1;
  this.$v.$reset();
}
function setForm(circuit) {
  let arrKeys = Object.keys(this.circuitInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "FgRequired":
        this.circuitInfo[arrKeys[i]] = circuit[arrKeys[i]];
        this.mandatory = circuit[arrKeys[i]] ? 'SI' : 'NO';
        break;
      case "Status":
        this.circuitInfo[arrKeys[i]] =
          circuit[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = circuit[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.circuitInfo[arrKeys[i]] = circuit[arrKeys[i]];
    }
  }
  this.$v.$touch();
}
function loadData() {
  this.loading = true;

  let peticiones = [
    this.$http.ejecutar("GET", "Circuit-by-id", {
      CircuitId: this.circuitId,
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.setForm(responses[0].data.data[0]);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function formatedData() {
  if (!this.edit) {
    let newCircuit = {
      ...this.circuitInfo,
      FgRequired: this.mandatory == 'SI' ? true : false,
      CompanyBranchId: this.getBranchId,
    };

    delete newCircuit.CircuitId;
    delete newCircuit.Status;

    return newCircuit;
  } else {
    return {
      ...this.circuitInfo,
      FgRequired: this.mandatory == 'SI' ? true : false,
    };
  }
}
function submit() {
  try {
    this.$v.circuitInfo.$touch();
    if (this.$v.circuitInfo.$error || !this.isDateOptionSelected || !this.isAtLeastOneRequire) {
      if(!this.isDateOptionSelected)
        throw this.$t("label.selectDateOption");
      else if(!this.isAtLeastOneRequire)
        throw this.$t("label.selectRequireOption");
      else 
        throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit
      ? "Circuit-update"
      : "Circuit-insert";
    let CircuitJson = this.formatedData();
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, CircuitJson, {
        root: "CircuitJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function confirmation() {
  try {
    this.$v.circuitInfo.$touch();
    if (this.$v.circuitInfo.$error || !this.isDateOptionSelected || !this.isAtLeastOneRequire) {
      if(!this.isDateOptionSelected)
        throw this.$t("label.selectDateOption");
      else if(!this.isAtLeastOneRequire)
        throw this.$t("label.selectRequireOption");
      else 
        throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.circuitInfo.Status,
      this.submit,
      `${this.$i18n.locale == 'es' ? this.circuitInfo.CircuitNameEs : this.circuitInfo.CircuitNameEn}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function selectDate(event, option){
  if(event && option ==  1){
    this.circuitInfo.FgApplyDate = true;
    this.circuitInfo.FgApplyDateRange = false;
  }
  if(event && option ==  2){
    this.circuitInfo.FgApplyDate = false;
    this.circuitInfo.FgApplyDateRange = true;
  }
  if(!event) {
    this.circuitInfo.FgApplyDate = false;
    this.circuitInfo.FgApplyDateRange = false;
  }
}

//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return this.$t("label.nuevo") + " " + this.$t("label.circuit");
  } else {
    return `${this.$t("label.edit")} ${this.$t("label.circuit")}: ${this.$i18n.locale == 'es' ? this.circuitInfo.CircuitNameEs : this.circuitInfo.CircuitNameEn}`;
  }
}
function statusSelectColor() {
  return this.circuitInfo.Status === 1;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function mandatoryOptions() {
  return [
    { value: 'SI', label: this.$t('label.yes')},
    { value: 'NO', label: 'NO'},
  ];
}

function isDateOptionSelected() {
  return !this.circuitInfo.FgApplyDate && !this.circuitInfo.FgApplyDateRange ? false : true;
}

function isAtLeastOneRequire() {
  let arrKeys = Object.keys(this.circuitInfo);
  let selectedQty = 0;

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "FgCrane":
      case "FgWeighingScale":
      case "FgMachine":
      case "FgYard":
      case "FgHold":
      case "FgRadioActivityLevel":
        if(this.circuitInfo[arrKeys[i]])
          selectedQty += 1;
        break;
    }
  }

  return (selectedQty >= 1);
}

export default {
  name: "circuit-modal",
  mixins: [ModalMixin, General, mixinServicio, MetalScrap],
  data,
  components: {},
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    circuitId: {
      type: String,
      default: "",
    },
  },
  validations: CircuitValidation,
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  methods: {
    loadData,
    toggle,
    resetForm,
    setForm,
    submit,
    confirmation,
    statusSelectColor,
    formatedData,
    selectDate,
  },
  computed: {
    tituloModal,
    getBranchId,
    mandatoryOptions,
    isDateOptionSelected,
    isAtLeastOneRequire,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    mandatory: function (newVal) {
      this.circuitInfo.FgRequired = newVal == 'SI' ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>