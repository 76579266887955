<template>
  <main>
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.moduleList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
           <CCol sm="12" lg="3">
            <CSelect
                :label="$t('label.subsystemType')"
                :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right"                
                :options="subsystemTypeListFormatted"
                :value="TpModuleFilter"
                @change="SubSystemList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3" v-if="TpModuleFilter.toUpperCase() === this.sigetWebId">
            <CSelect                
                :label="$t('label.navigation')"
                :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-6'}"
                addLabelClasses="text-right"
                :options="navegationListFormatted"
                :value="NavigationFilter"
                @change="NavigationList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3"  v-if="NavigationFilter.toUpperCase() === this.visitaId.toUpperCase()">
            <CSelect                
              :label="$t('label.vesselName')"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
              addLabelClasses="text-right"
              :options="vesselListFormatted"
              :value="VesselSelect"
              @change="VesselList($event)"
            />
          </CCol>
          <CCol sm="12" lg="3" v-if="NavigationFilter.toUpperCase() === this.visitaId.toUpperCase()">
            <CSelect                
              :label="$t('label.module')"
              :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-6'}"
              addLabelClasses="text-right"
              :options="moduleL1Formated"
              :value="GpoModuleL1Filter"
              @change="ModuleList($event)"
          />
          </CCol>
        </CRow>
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formattedNivel1"
          :fields="fieldslv1and2"
          :items-per-page="5"
          sorter
          pagination
          details
          addTableClasses="table-lv-1"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="tableLoadingLv1"
        >
          <template #loading>
              <loading/>
          </template>
          <template #Orden="{item}">
            <td class="col-row-width">
              {{ item.Orden }}
            </td>
          </template>
          <template #Status="{ item }">
            <td class="center-cell">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Nivel="{ item }">
            <td class="col-row-width">
              {{ item.Nivel }}
            </td>
          </template>
          <template #show_details="{item}">
            <td class="center-cell">
              <CButton
                color="watch"
                square
                v-c-tooltip="{
                  content: $t('label.toView')+' '+$t('label.level'),
                  placement: 'top-end',
                }"
                size="sm"
                @click="toggleLevel1(item)"
              >
                <CIcon name="eye" />
              </CButton>
            </td>
          </template>
          <template #details="{item}">
            <CCollapse
              :show="Boolean(item._toggled)"
              :duration="collapseDuration"
            >
              <CCardBody>
                <dataTableExtended
                  class="align-center-row-datatable table-lv-2"
                  :items="formattedNivel2"
                  :fields="fieldslv1and2"
                  :items-per-page="5"
                  sorter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"                                    
                >
                  <template #loading>
                      <loading/>
                  </template>
                  <template #Orden="{item}">
                    <td class="col-row-width">
                      {{ item.Orden }}
                    </td>
                  </template>
                  <template #Nivel="{item}">
                    <td class="col-row-width">
                      {{ item.Nivel }}
                    </td>
                  </template>
                  <template #Status="{ item }">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #show_details="{item}">
                    <td class="center-cell">
                      <CButton
                        color="watch"
                        square
                        v-c-tooltip="{
                          content: $t('label.toView')+' '+$t('label.level'),
                          placement: 'top-end',
                        }"
                        size="sm"
                        @click="toggleLevel2(item)"
                      >
                        <CIcon name="eye" />
                      </CButton>
                    </td>
                  </template>
                  <template #details="{item}">
                    <CCollapse
                      :show="Boolean(item._toggled)"
                      :duration="collapseDuration"
                    >
                      <CCardBody>
                        <CRow>
                          <CCol sm="12" class="d-flex justify-content-end py-2">
                            <CButton
                              square
                              color="add"
                              class="d-flex align-items-center"
                              v-c-tooltip="{placement:'top-end',content:$t('label.module') }"
                              @click="toggleAdd"
                            >
                              <CIcon name="cil-playlist-add" /><span
                                class="ml-1"
                                >{{$t('label.nuevo')}}</span
                              >
                            </CButton>
                          </CCol>
                        </CRow>

                        <dataTableExtended
                          class="align-center-row-datatable table-lv-3"
                          :items="formattedNivel3"
                          :fields="fieldsLv3"
                          :items-per-page="5"
                          sorter
                          pagination
                          column-filter
                          :loading="loading"
                          :table-filter="tableText.tableFilterText"
                          :items-per-page-select="tableText.itemsPerPageText"
                          :noItemsView="tableText.noItemsViewText"   
                        >
                          <template #loading>
                              <loading/>
                          </template>
                          <template #Status="{ item }">
                            <td class="center-cell">
                              <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                              </CBadge>
                            </td>
                          </template>
                          <template #Nivel="{item}">
                            <td class="col-row-width">
                              {{ item.Nivel }}
                            </td>
                          </template>
                          <template #Orden="{item}">
                            <td class="center-cell">
                              <CRow>
                                <CCol>
                                  {{ item.Orden }}
                                  <CButtonGroup>
                                    <CButton
                                      color="dark"
                                      circle
                                      size="sm"
                                      variant="ghost"
                                      @click="changeOrderUp(item, 3)"
                                      v-if="showBtnUpLv3(item)"
                                    >
                                      <CIcon name="cil-chevron-circle-up" />
                                    </CButton>
                                    <CButton
                                      color="dark"
                                      size="sm"
                                      variant="ghost"
                                      @click="changeOrderDown(item, 3)"
                                      v-if="showBtnDownLv3(item)"
                                    >
                                      <CIcon name="cil-chevron-circle-down" />
                                    </CButton>
                                  </CButtonGroup>
                                </CCol>
                              </CRow>
                            </td>
                          </template>
                          <template #show_details="{item}">
                            <td class="center-cell">
                              <CButtonGroup>
                                <CButton
                                  color="edit"
                                  v-c-tooltip="{
                                    content: $t('label.edit')+$t('label.module'),
                                    placement: 'top-end',
                                  }"
                                  square
                                  size="sm"
                                  class="mr-1"
                                  @click="toggleEdit(item)"
                                >
                                  <CIcon name="pencil" />
                                </CButton>
                                <CButton
                                  color="watch"
                                  square
                                  v-c-tooltip="{
                                    content: $t('label.toView')+' '+$t('label.associatedRol'),
                                    placement: 'top-end',
                                  }"
                                  size="sm"
                                  class="mr-1"
                                  @click="toggleRoles(item)"
                                >
                                  <CIcon name="eye" />
                                </CButton>
                              </CButtonGroup>
                            </td>
                          </template>
                        </dataTableExtended>
                      </CCardBody>
                    </CCollapse>
                  </template>
                </dataTableExtended>
              </CCardBody>
            </CCollapse>
          </template>
        </dataTableExtended>
      </CCardBody>
    </CCard>
    <add-module-modal
      :modal.sync="moduleAdd"
      :listNivelI="itemsNivelI"
      :listlv3="itemsNivelIII"
      :GpoModuleIdNivel1="GpoModuleIdNivel1"
      :GpoModuleDsNivel1="GpoModuleDsNivel1"
      :GpoModuleIdNivel2="GpoModuleIdNivel2"
      :GpoModuleDsNivel2="GpoModuleDsNivel2"
      :TpModuleName="TpModuleName"
      :TpModuleFilter="TpModuleFilter"
      @update-data-modules="mountModules"
    />
    <list-roles-module-modal
      :modal.sync="moduleRoles"
      :idModule="moduleRoleIdSelect"
      :moduleNbSelect="moduleNbRoleSelect"
    />
    <edit-module-modal
      :modal.sync="moduleEdit"
      :moduleData="moduleSelect"
      @edit-module-selected="updateMountedData"
      :TpModuleName="TpModuleName"
      :TpModuleFilter="TpModuleFilter"
      :listlv3="itemsNivelIII"
      :TpModuleFilterSelect="TpModuleFilter"
      :NavigationFilterSelect="NavigationFilter"
      @update:modal="updateModal"
    />
  </main>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>
<script>
import EditModuleModal from './edit-module';
import AddModuleModal from './add-module';
import ListRolesModuleModal from './list-roles-module';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';

function data() {
  return {
    GpoModuleIdNivel1: '',
    GpoModuleDsNivel1: '',
    GpoModuleIdNivel2: '',
    GpoModuleDsNivel2: '',
    TpModuleName: '',
    TpModuleObj: {},
    itemsNivelI: [],
    itemsNivelII: [],
    itemsNivelIII: [],        
    collapseDuration: 0,
    moduleAdd: false,
    moduleEdit: false,
    moduleRoles: false,
    moduleSelect: {},
    loading: false,
    orderingListSelected: [],
    moduleRoleIdSelect: '',
    moduleNbRoleSelect: '',
    apiStateLoading: false,    
    tableLoadingLv1: false,
    TpModuleFilter:'',
    NavigationFilter:'',
    VesselSelect:'',
    GpoModuleL1Filter:'',
    sudSystemItems:[],
    navigationItems:[],
    vesselItems:[],
    GpoModuleL1Item:[],
    GpoModuleL2Item:[],
    filterVessel: [],
    sigetWebId:'886B95BE-452A-45E0-8C11-2C162BC31AFE',  
    visitaId:'5FFE2FBD-CD0C-4B78-96B7-0C1F0F8CB97C',
  };
}

function updateModal(val) {
  if (!val) {
    this.moduleSelect = {};
  }else{
    
  }
}

function toggleRoles(item) {
  this.moduleRoleIdSelect = item.ModuleId;
  this.moduleNbRoleSelect = item.TpModuleName;
  this.moduleRoles = !this.moduleRoles;
}

function showBtnUpLv1(item) {
  return this.formattedNivel1.length > 1 && item.Order > 1;
}

function showBtnUpLv2(item) {
  return this.formattedNivel2.length > 1 && item.Order > 1;
}

function showBtnUpLv3(item) {
  return this.formattedNivel3.length > 1 && item.Order > 1;
}

function showBtnDownLv1(item) {
  return (
    this.formattedNivel1.length > 1 && item.Order < this.formattedNivel1.length
  );
}

function showBtnDownLv2(item) {
  return (
    this.formattedNivel2.length > 1 && item.Orden < this.formattedNivel2.length
  );
}

function showBtnDownLv3(item) {
  return (
    this.formattedNivel3.length > 1 && item.Orden < this.formattedNivel3.length
  );
}

async function updatedModuleSelected(item) {
  let newItems = this.itemsNivelIII.map((val) => {
    if (val.ModuleId === item.ModuleId) {
      val = item;
    }
    return val;
  });

  let newList = newItems.sort((val) => {
    return val.Status === 'ACTIVO' ? -1 : 1;
  });

  let ultimateList = newList.map((item, index) => {
    return {
      ...item,
      Orden: index + 1,
    };
  });
  this.orderingListSelected = ultimateList;
  this.formatedOrderingList();
  //await ModuleService.postOrderModule(this.orderingListSelected);
}

function toggleAdd() {
  this.moduleAdd = !this.moduleAdd;
}

function resetTables() {
  //this.formattedNivel2 = [];
  //this.formattedNivel3 = [];
}

function formatDate(item) {
  return item ? DateFormater.formatOnlyDateWithSlash(item) : 'N/A';
}

function closeShow(item, level) {
  let moduleOpen;
  if (level == 1) {
    moduleOpen = this.formattedNivel1.find((val) => val._toggled == true);
  } else if (level == 2) {
    moduleOpen = this.formattedNivel2.find((val) => val._toggled == true);
  }
  if (moduleOpen != undefined && moduleOpen.GpoModuleId != item.GpoModuleId) {
    moduleOpen._toggled = !moduleOpen._toggled;
  }
}

async function toggleLevel1(item) {
  this.apiStateLoading = true;
  await this.resetTables();
  await this.closeShow(item, 1);

  this.GpoModuleIdNivel1 = item.GpoModuleId;
  this.GpoModuleDsNivel1 = item.GpoModuleDs;

  const modulePadre = this.itemsNivelI.find(
    (val) => val.GpoModuleId === item.GpoModuleId
  );

  this.itemsNivelII = modulePadre.GpoModuleChildJson;

  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

async function toggleLevel2(item) {
  let _lang = this.$i18n.locale;
  this.apiStateLoading = true;
  this.GpoModuleIdNivel2 = item.GpoModuleId;
  this.GpoModuleDsNivel2 = item.GpoModuleDs;
  this.TpModuleObj = this.sudSystemItems.find(item => item.TpModuleId === this.TpModuleFilter);
  this.TpModuleName = this.TpModuleName =  _lang=='en' ? this.TpModuleObj.TpModuleNameEn : this.TpModuleObj.TpModuleNameEs;
  await this.resetTables();
  await this.closeShow(item, 2);
  this.loading = true;
  const modulePadre = this.itemsNivelII.find(
    (val) => val.GpoModuleId === item.GpoModuleId
  );

  this.$http
    .get(`Module-list?GpoModuleId=${modulePadre.GpoModuleId}`)
    .then((response) => {
      const data = response.data.data;
      const moduleNivelIII = data;

      this.itemsNivelIII = moduleNivelIII;
      this.loading = false;
      this.apiStateLoading = false;
    })
    .catch((e) => {
      this.loading = false;
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });

  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
  });
}

async function toggleEdit(item) {
  await this.resetTables();
  this.moduleSelect = item;
  this.moduleEdit = !this.moduleEdit;
}

function mountModules() {
  this.tableLoadingLv1 = true;
  this.$http
    .get('GpoModule-list', { TpModuleId:this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter, GpoModuleOrigId: this.GpoModuleL1Filter})
    .then((response) => {  
      const data = response.data;
      const moduleNivelI = data.data;
      this.itemsNivelI = moduleNivelI;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })
    .finally(() => {
      this.tableLoadingLv1 = false;
    });
}

function formattedNivel1() {
  return this.itemsNivelI.map((item) =>
    Object.assign({}, item, {
      ...item,
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'table-dark',
      _toggled: false,
    })
  );
}

function formattedNivel2() {
  return this.itemsNivelII.map((item) =>
    Object.assign({}, item, {
      ...item,
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'table-primary',
      _toggled: false,
    })
  );
}

function formattedNivel3() {
  // let newList = this.itemsNivelIII.sort((a, b) => {
  //   if (parseInt(a.Order) < parseInt(b.Order)) {
  //     return -1;
  //   }
  //   if (parseInt(a.Order) > parseInt(b.Order)) {
  //     return 1;
  //   }
  // });
  // newList = newList.sort((val) => {
  //   return val.Status === 'ACTIVO' ? -1 : 1;
  // });
  return this.itemsNivelIII.map((item) => {
    return {
      ...item,
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'table-warning',
      _toggled: false,
    };
  });
}

function convertDate(date) {
  let newDay = new Date(date),
    day = ('0' + newDay.getDate()).slice(-2),
    month = ('0' + (newDay.getMonth() + 1)).slice(-2),
    year = newDay.getFullYear();

  return `${day}/${month}/${year}`;
}

function changeOrderDown(item, level) {
  if (item.Status == 'ACTIVO' || item.Status == '1') {
    switch (level) {
      case 3:
        this.changeDownLv3(item, this.formattedNivel3);
        break;
    }
  } else {
    this.$notify({
      group: 'container',
      title: '¡Aviso!',
      text: 'No es posible actualizar el orden de un modulo inactivo',
      type: 'error',
    });
  }
}

function changeDownLv3(item, listItems) {
  const limitItems = listItems.length;
  if (item.Orden < limitItems) {
    listItems.map((itemFilter) => {
      if (itemFilter.ModuleId != item.ModuleId) {
        let rest = parseInt(item.Orden) - parseInt(itemFilter.Orden);
        if (rest == -1) {
          itemFilter.Orden--;
          this.checkListOrderLv3(itemFilter);
        }
      }
    });
    item.Orden++;
    this.checkListOrderLv3(item);
  }
}

function changeUpLv3(item, listItems) {
  if (item.Orden > 0) {
    const limitItems = listItems.length;
    listItems.map((itemFilter) => {
      if (itemFilter.ModuleId != item.ModuleId) {
        if (itemFilter.Orden < limitItems) {
          let rest = parseInt(item.Orden) - parseInt(itemFilter.Orden);
          if (rest == 1) {
            let newOrder = itemFilter.Orden--;
            let orderByItem = item.Orden++;
            if (newOrder != 0 && orderByItem != 0) {
              if (newOrder != orderByItem) {
                item.Orden = newOrder;
                itemFilter.Orden = orderByItem;
              }
            }
          }
        }
      }
    });
  }
}

function checkListOrderLv3(itemSelected) {
  let listOrder = this.orderingListSelected;
  let verify = false;
  if (listOrder.length > 0) {
    listOrder.map((item) => {
      if (item.ModuleId == itemSelected.ModuleId) {
        verify = true;
        return itemSelected;
      }
    });
    if (verify == false) {
      listOrder.push(itemSelected);
    }
  } else {
    listOrder.push(itemSelected);
  }
}

function checkListOrderLv1andLv2(itemSelected) {
  let listOrder = this.orderingListSelected;
  let verify = false;
  if (listOrder.length > 0) {
    listOrder.map((item) => {
      if (item.GpoModuleId == itemSelected.GpoModuleId) {
        verify = true;
        return itemSelected;
      }
    });
    if (verify == false) {
      listOrder.push(itemSelected);
    }
  } else {
    listOrder.push(itemSelected);
  }
}

function formatedOrderingList() {
  let newList = this.orderingListSelected.map((item) => {
    return {
      ModuleId: item.GpoModuleId,
      Orden: item.Order,
      IdUsuario: item.TransaUserId,
    };
  });
  this.orderingListSelected = newList;
}

async function onSaveOrderChanges() {
  // if (this.orderingListSelected.length > 0) {
  //   this.apiStateLoading = true;
  //   this.formatedOrderingList();
  //   await ModuleService.postOrderModule(this.orderingListSelected)
  //     .then((response) => {
  //       this.$notify({
  //         group: 'container',
  //         title: '¡Solicitud Exitosa!',
  //         text: 'Cambios Guardados Exitosamente',
  //         type: 'success',
  //       });
  //       this.apiStateLoading = false;
  //     })
  //     .catch((e) => {
  //       this.$notify({
  //         group: 'container',
  //         title: '¡Error!',
  //         text: e,
  //         type: 'error',
  //       });
  //       this.apiStateLoading = false;
  //     });
  //   this.apiStateLoading = false;
  // } else {
  this.$notify({
    group: 'container',
    title: '¡Aviso!',
    text: 'No hay nada que actualizar!',
    type: 'warning',
  });
  // }
}

function changeDown(item, listItems) {
  const limitItems = listItems.length;
  if (item.Orden < limitItems) {
    listItems.map((itemFilter) => {
      if (itemFilter.GpoModuleId != item.GpoModuleId) {
        let rest = parseInt(item.Orden) - parseInt(itemFilter.Orden);
        if (rest == -1) {
          const newOrder = itemFilter.Orden--;
          if (newOrder != 0) {
            if (newOrder != item.Orden) {
              item.Orden = itemFilter.Orden;
              itemFilter.Orden = newOrder;
            }
          }
        }
      }
    });
  }
}

function changeOrderUp(item, level) {
  if (item.Status == 'ACTIVO' || item.Status == '1') {
    switch (level) {
      case 3:
        this.changeUpLv3(item, this.formattedNivel3);
        break;
    }
  } else {
    this.$notify({
      group: 'container',
      title: '¡Aviso!',
      text: 'No es posible actualizar el orden de un modulo inactivo',
      type: 'error',
    });
  }
}

async function updateMountedData() {
  await this.mountModules();
}


function getSudSystemList() {
    this.$http.get("TpModuleConfigWeb-list")
    .then(response => {       
      this.sudSystemItems = response.data.data;
      this.TpModuleFilter = this.sudSystemItems[0].TpModuleId;  
      this.mountModules();
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
 
} 

function SubSystemList(event) {
  this.cleanData();
  this.TpModuleFilter = event.target.value; 
  if (this.TpModuleFilter!='') {
    if (this.TpModuleFilter.toUpperCase() === this.sigetWebId) {
      let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
      this.navigationItems = NavigationWeb.TpModuleNavegationJson;
      this.NavigationFilter = this.navigationItems[0].TpModuleNavegationId;
      this.NavigationList();
    }
   this.mountModules();
  } else {
    this.cleanData();
  }
}
function cleanData(){
    this.itemsNivelI= [];
    this.navigationItems=[];
    this.vesselItems= [];
    this.NavigationFilter='';
    this.VesselSelect='';
    this.GpoModuleL1Item = [];
    this.GpoModuleL1Filter = '';
}
function NavigationList(event) {
  this.itemsNivelI= [];
  this.vesselItems= [];
  this.VesselSelect='';
  this.GpoModuleL1Item = [];
  this.GpoModuleL1Filter = '';
  event ? this.NavigationFilter = event.target.value : '';
  if (this.NavigationFilter!='') {
    if (this.NavigationFilter && this.NavigationFilter==this.visitaId.toUpperCase()) {
      this.mountModules();
      this.getVesselList();
    } else {
      this.mountModules();
    }
  } else {
    this.itemsNivelI= [];
    this.vesselItems= [];
    this.VesselSelect='';
    this.GpoModuleL1Item = [];
    this.GpoModuleL1Filter = '';
  }
}
function getVesselList() {
  this.Loading = true;
  this.$http.get("TpVessel-list")
    .then(response => {       
      this.vesselItems = response.data.data;
      this.GpoModuleL2Item;
      this.GpoModuleL2List();
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false
    });
} 
function VesselList(event) {
  this.itemsNivelI= [];
  this.GpoModuleL1Item = [];
  this.GpoModuleL1Filter = '';
  this.VesselSelect = event.target.value;
  if (this.TpModuleNavegationId!='') {
    if (this.VesselSelect!='') {
      this.filterVessel = this.GpoModuleL2Item.filter((item) => {return item.TpVesselId === (this.VesselSelect).trim()} );
      this.GpoModuleL1Item = this.filterVessel;
      //this.mountModules();
    }else{
      this.itemsNivelI= [];
      this.GpoModuleL1Item = [];
      this.GpoModuleL1Filter = '';
    }
  }
}

function GpoModuleL1List() {
  this.Loading = true;
  if (this.TpModuleNavegationId!='') {
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
    .then(response => {   
      this.GpoModuleL1Item = response.data.data;
      this.mountModules();
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      
    });
  }else{
    //this.GpoModuleL1Filter='';
    //this.GpoModuleL1Item=[];
  }
}
function ModuleList(event) {
  this.itemsNivelI= [];
  this.Loading = true;
  this.GpoModuleL1Filter = event.target.value;
  if (this.GpoModuleL1Filter!='') {
    this.mountModules();
    this.Loading = false;
  }else{
    this.itemsNivelI= [];
  }
}
function GpoModuleL2List() {
  this.Loading = true;
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,GpoModuleOrigId:this.GpoModuleL1Filter})
    .then(response => {   
      this.GpoModuleL2Item = response.data.data;
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}
//computed
function fieldslv1and2(){  
    return[
      { key: 'Order', label: this.$t('label.order'), _style: 'width:1%', _classes: 'text-uppercase center-cell text-center' },
      {
        key: 'GpoModuleNameEs',
        label: this.$t('label.moduleGroup')+' '+('(ES)'),        
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'GpoModuleNameEn',
        label: this.$t('label.moduleGroup')+' '+('(EN)'),        
        _classes: 'text-uppercase center-cell text-center',
      },
      { key: 'Level', label: this.$t('label.level'), _style: 'width:10%;',_classes:'center-cell text-center' },
      {
        key: 'TransaLogin',
        label: this.$t('label.user'),        
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'FormatedDate',
        label: this.$t('label.date'),
        _style: 'width:10%;',
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'Status',
        label: this.$t('label.status'),
        _style: 'width:1%;',
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'show_details',
        label: this.$t('label.viewHierarchy'),
        _style: 'width:15%;',
        _classes: 'text-uppercase center-cell text-center',
        sorter: false,
        filter: false,
      },
    ]
  }

  function fieldsLv3(){
  return[
      { key: 'Order', label: this.$t('label.order'), _style: 'width:1%;', filter:false, _classes: 'text-uppercase center-cell text-center' },
      {
        key: 'ModuleName',
        label: this.$t('label.moduleGroup'),
        _style: 'min-width:100px;',
        _classes: 'text-uppercase center-cell text-center',
      },
      { key: 'Level', label: this.$t('label.level'), _style: 'width:10%;',_classes:'center-cell' },
      {
        key: 'TransaLogin',
        label: this.$t('label.user'),
        _style: 'min-width:100px;',
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'FormatedDate',
        label: this.$t('label.date'),
        _style: 'width:10%;',
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'Status',
        label: this.$t('label.status'),
        _style: 'width:1%;',
        _classes: 'text-uppercase center-cell text-center',
      },
      {
        key: 'show_details',
        label: this.$t('label.viewHierarchy'),        
        _style: 'width:15%;',
        _classes:"center-cell",
        sorter: false,
        filter: false,
      },
    ]
  } 
  
function subsystemTypeListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.sudSystemItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.sudSystemItems.map(function(e){
      chart.push({
        value: e.TpModuleId, 
        label: _lang=='en' ? e.TpModuleNameEn : e.TpModuleNameEs,
      })    
    })
    return chart;
  }
} 

function navegationListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.navigationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.navigationItems.map(function(e){
      chart.push({
        value: e.TpModuleNavegationId, 
        label: _lang=='en' ? e.TpModuleNavegationEn : e.TpModuleNavegationEs,
      })    
    })
    return chart;
  }
}   
function vesselListFormatted(){
  if(this.vesselItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vesselItems.map(function(e){
      chart.push({
        value: e.TpVesselId, 
        label: e.TpVesselName,
      })    
    })
    return chart;
  }
}  
function moduleL1Formated(){
  let _lang = this.$i18n.locale;
  if(this.GpoModuleL1Item.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.GpoModuleL1Item.map(function(e){
      chart.push({
        value: e.GpoModuleId, 
        label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
      })    
    })
    return chart;
  }
}
export default {
  name: 'module-index',
  components: { AddModuleModal, EditModuleModal, ListRolesModuleModal },
  data,
  mixins: [GeneralMixin],
  methods: {
    toggleAdd,
    toggleEdit,
    convertDate,
    toggleLevel1,
    toggleLevel2,
    changeUpLv3,
    changeOrderUp,
    changeOrderDown,
    changeDown,
    changeDownLv3,
    showBtnUpLv1,
    showBtnUpLv2,
    showBtnUpLv3,
    showBtnDownLv1,
    showBtnDownLv2,
    showBtnDownLv3,
    resetTables,
    closeShow,
    toggleRoles,
    updatedModuleSelected,
    onSaveOrderChanges,
    checkListOrderLv1andLv2,
    checkListOrderLv3,
    formatedOrderingList,
    formatDate,
    mountModules,
    updateMountedData,
    updateModal,
    getSudSystemList,
    SubSystemList,
    cleanData,
    NavigationList,
    getVesselList,
    VesselList,
    GpoModuleL1List,
    ModuleList,
    GpoModuleL2List,
  },
  computed: {
    formattedNivel1,
    fieldslv1and2,
    fieldsLv3,    
    formattedNivel2,
    formattedNivel3,
    subsystemTypeListFormatted,
    navegationListFormatted,
    vesselListFormatted,
    moduleL1Formated
  },
  //created: mountModules,
  mounted:getSudSystemList,
};
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
</style>
