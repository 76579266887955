<template>
  <div>
    <div v-show="showIndex==0">
      <div>
        <CRow class="mb-3">
          <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
            <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.listReception')}`}} </h6>
          </CCol>
        </CRow>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                >
                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                        </CBadge>
                      </td>
                    </template>
                    <template #buttonOptions="{item, index}">
                      <td class="text-center">
                        <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.editReception'),
                            placement: 'top-start'
                          }"
                          @click="toggleAdd(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          style="background-color: #d6d6d7;"
                          square
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.Seals'),
                            placement: 'top-start'
                          }"
                          @click="viewDetail(item, index)"
                        >
                          <CIcon name="bottomSeals"/>
                        </CButton>
                        <CButton
                          size="sm"
                          square
                          color="danger"
                          @click="togglePdf(item)"
                          v-c-tooltip="{
                            content: $t('label.OrderOfEntry'),
                            placement: 'top-start'
                          }"
                        >
                          <CIcon name="cil-file-pdf" />
                        </CButton>
                      </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSeal(item.SealJson[0])"
                            :fields="level2Fields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                        
                            <template #button="{item}">
                              <td class="text-center">
                                <CButton
                                  v-if="item.SealDocumentJson.length!=0&&item.Seal"
                                  color="watch"
                                  square
                                  size="sm"
                                  class="mr-1"
                                  @click="ModalImagen(item)"
                                >
                                  <CIcon name="eye"/>
                                </CButton>
                              </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <ReceptionRegister
          v-show="!this.isInternalTranfer"
          :Active="showIndex==1 && !this.isInternalTranfer"
          :isEdit="isEdit"
          :ReceptionItem="ReceptionItem"
          @Close="(showIndex=0, ReceptionItem={}, isEdit=false)"
          @Update-list="UpdateData"
      />

      <BlInternalRecepcion
        v-show="this.isInternalTranfer"
        :Active="showIndex==1 && this.isInternalTranfer"
        :FgInternalTransfer="true"
        :isEdit="true"
				:ContainerItem="ReceptionItem"
        :isOfContainer="true"
				@Close="showIndex = 0, ReceptionItem={}, isInternalTranfer = false, isEdit=false"
				@Update-list="UpdateData"
      />
    </div>
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="labelModalImages"
      :SealImages="SealImages"
      @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import YardManagement from '@/_mixins/yardManagement';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import ReceptionRegister from './reception-register';
import ModalSealImages from '../../modal-seal-images';
import BlInternalRecepcion from '@/pages/yard-management/container/movements/reception/internal-transfer-register';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';


//DATA-------------------------
function data() {
  return {
    showIndex: 0,
    collapseDuration: 0,
    _toggled: false,
    ReceptionItem:{},
    dataReception :[],
    labelModalImages: '',
    SealImages: [],
    isEdit: false,
    ModalSeal: false,
    isInternalTranfer: false
  }
}

//methods
async function UpdateData() {
  this.$store.state.yardManagement.loading = true;
  await this.getYardCargo();
  if (process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED==this.dataContainer?.YardCargoStatus?.toUpperCase()||
    process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION==this.dataContainer?.YardCargoStatus?.toUpperCase()) {
    await this.getListReception();
  }else{
    this.$store.state.yardManagement.yardCollapse = 1;
    this.$store.state.yardManagement.FgContainerWarehouse = true;
    this.$store.state.yardManagement.loading = false;
  }
}

async function getListReception() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardEventReception-by-CargoId', { CargoId: this.dataContainer.CargoId})
    .then(response => {
      this.dataReception = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
        this.$store.state.yardManagement.loading = false;
    });
}

async function getYardCargo() {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        
        if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
          let yardCargoReception = '', yardCargoDispatch = ''; 

          yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
          yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

          this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
        } 
        else{
          this.$store.state.yardManagement.dataContainer = List[0];
        }

        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.dataContainer.CargoId ? List[0] : Select;
            });
        }
      }else{
        if (this.GeneralList==1){
          let filter = this.$store.state.yardManagement.ListReceivedContainers.filter(item => item.YardCargoId != this.YardCargoId);
          this.$store.state.yardManagement.ListReceivedContainers = filter;
        }else if(this.GeneralList==3){
          this.$store.state.yardManagement.ListRecognitionContainers = 
            this.$store.state.yardManagement.ListRecognitionContainers.filter(item => item.YardCargoId != this.YardCargoId);
        }else if(this.GeneralList==2){
          let filter = this.$store.state.yardManagement.ListDispatchedContainers.filter(item => item.YardCargoId != this.YardCargoId);
          this.$store.state.yardManagement.ListDispatchedContainers = filter;
        }
        this.$store.state.yardManagement.globalSelectContainer = 
          this.$store.state.yardManagement.globalSelectContainer.filter(item => item.YardCargoId != this.YardCargoId);
        this.$store.state.yardManagement.dataContainer = {};
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function togglePdf(item) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardDocumentRecoveryOrderReport", { YardDocumentId: item.YardDocumentId })
  .then(async(response) => {
    let pdf = response.data.data?.[0] ?? undefined;
    if (pdf) {
      await this.getPassPdf(pdf, this.$t('label.OrderOfEntry'), 2);
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
      this.$store.state.yardManagement.loading = false;
  });
}

function toggleAdd(item) {
  this.ReceptionItem = item;
  this.isEdit = true;

  if(this.ReceptionItem.TpEirTransacId == process.env.VUE_APP_REGISTER_RECEPTION_INTERNAL_TRANSFER)
    this.isInternalTranfer = true;

  this.showIndex = 1;
}

function ModalImagen(item) {
  this.labelModalImages = item.Seal;
  this.SealImages = item.SealDocumentJson;
  this.ModalSeal = true;
}

function formatedItems() {
  return this.dataReception.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardName: item.YardName ? item.YardName : '',
      MachineCode: item.MachineCode ? item.MachineCode : '',
      TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaLogin: item.TransaLogin ? item.TransaLogin : '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : ''
    };
  })
}

function formatedSeal(SealJson) {
  if (SealJson) {
    let _lang = this.$i18n.locale;
    let SealArray = SealJson.Seal1Json.concat(SealJson.Seal2Json, SealJson.Seal3Json, SealJson.Seal4Json);
    return SealArray.map((item) => {
      return {
        ...item,
        Seal: item.Seal ? item.Seal : '',
        SealConditionName: _lang=='en' ? item.SealConditionNameEn || '' : item.SealConditionNameEs || '',
        SealB: item.SealB ? item.SealB : '',
        SealAssignmentName: _lang=='en' ? item.SealAssignmentNameEn || '' : item.SealAssignmentNameEs || '',
        ObservationSeal: item.ObservationSeal ? item.ObservationSeal : '',
      };
    })
  }
}

function fields(){
    return [
        {
          key: 'buttonOptions', 
          label: '', 
          _style: 'min-width: 135px; width: 1%; text-align:center; ', 
          sorter: false, 
          filter: false
        },
        { key: 'Nro', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
        { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
        { key: 'TpTransacEirAcronym',label: this.$t('label.transaction'), _style:'min-width: 140px; text-align:center;', _classes: 'text-uppercase text-center'},
        { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 140px;', _classes: 'text-uppercase text-center'},
        { key: 'TransactionDate', label: this.$t('label.receptionDate'), _style:'min-width: 150px;', _classes: 'text-uppercase text-center' },
        { key: 'DocumentCode',label: this.$t('label.OrderOfEntry'), _style:'min-width: 190px;', _classes: 'text-uppercase text-center'},
        { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 180px;', _classes:"text-center"},
        { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 140px;', _classes:"text-center"},
        { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
        { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
    ];
  }

  function level2Fields(){
    return [
        {
          key: 'button', 
          label: '', 
          _style: 'min-width: 45px; width: 1%; text-align:center; background-color: #828c9c;', 
          sorter: false, 
          filter: false
        },
        { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealConditionName', label: this.$t('label.Mode'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealB', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealAssignmentName', label: this.$t('label.assignation'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
        { key: 'ObservationSeal', label: this.$t('label.observation'), _style:'width: 35%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
    ];
  }

  function closeShow(item, level) {

  }

  function viewDetail(item){
    this.closeShow(item, 1);

    item._toggled = !item._toggled;
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }


//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

export default {
  name: 'reception',
  components: {
    CustomTabs,
    CustomTab,
    ReceptionRegister,
    BlInternalRecepcion,
    ModalSealImages
   },
  data,
  mixins: [GeneralMixin, YardManagement],
  methods: {
    viewDetail,
    closeShow,
    toggleAdd,
    getListReception,
    getYardCargo,
    togglePdf,
    formatedSeal,
    UpdateData,
    ModalImagen
  },
  computed: {
    VisitOptions,
    fields,
    level2Fields,
    formatedItems,
    ...mapState({
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
        YardId: state => state.yardManagement.dataContainer.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        dropContainerMovement: state => state.yardManagement.dropContainerMovement,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    dropContainerMovement: function (val) {
      if(val == 1){
        this.showIndex = 0;
        this.getListReception();
      }
    },
  },
};
</script>