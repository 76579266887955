<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCol sm="12" class="mt-2">
                <CRow>                    
                    <!-- <CCol sm="5">
                        <CSelect
                            :label="$t('label.filtro')"
                            :horizontal="{label:'col-sm-9 col-lg-2',input:'col-sm-11 col-lg-5'}"
                            addLabelClasses="text-right"
                            :options="optionsListPaises"
                            @input="filtroSelected = $event.target.value"
                        />
                    </CCol> -->
                    <CCol sm="12" class="d-flex justify-content-end">
                        <div>
                            <CButton
                                color="add"                            
                                @click="activarModal()"
                                v-c-tooltip="{placement:'top-end',content:$t('label.country')}"
                            >
                                <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                            </CButton>
                        </div>
                    </CCol>                    
                </CRow>
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #IdPais="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Icon="{item}">
                        <td class="text-center">
                            <img
                                :src="srcImage(item.Icon)"
                                :alt="item.NbPais"
                                width="50px"
                                height="50px"
                                class="img-thumbnail mb-2"
                                @error="setAltImg"
                            />
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="text-center">
                            <CBadge v-if="item.status" color="success">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                            <CBadge v-if="!item.status" color="danger">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="center-cell">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.edit')+$t('label.country'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>   

import General from "@/_mixins/general";

    const dataPais = [];

function fields(){

    return[
            { key: 'IdPais', label: '#',filter :false, _style: 'width:1%;', },
            { key: 'CountryName',label: this.$t('label.country'),_classes:'center-cell text-center', _style:'width: 15%;' },
            { key: 'IsoCodeAlpha2',label: this.$t('label.alpha2Code'),_classes:'center-cell text-center', _style:' width: 10%;' },
            { key: 'IsoCodeAlpha3',label: this.$t('label.alpha3Code'),_classes:'center-cell text-center', _style:'width: 10%;' },
            { key: 'Numeric',label: this.$t('label.countryNumericCode'),_classes:'center-cell text-center', _style:'width:15%;' },
            { key: 'UtcZone',label: 'UTC ZONE',_classes:'center-cell text-center',_style:'width: 10%;' },
            { key: 'Icon',label: this.$t('label.flag'),_classes:'center-cell text-center',filter:false, _style:'width:10%;' },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:'center-cell text-center', _style:'width: 10%;' },
            { key: 'FormatedDate', label:this.$t('label.date'),_style:' width: 12%;',_classes:'center-cell text-center'},
            { key: 'Status',label: this.$t('label.status'),_classes:'center-cell text-center', _style:'width:10%;' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width:50px; width:1%', 
                sorter: false, 
                filter: false
            }
        ]
    }
    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',            
            activePage: 1,                        
            warningModal:false,
            filtroSelected:"ALL",
        }
    }

    //computed
    function computedItems () {
        return this.$store.getters["ubicacion/myDataPaistable"];
    }
    function formatedItems() {
        const filtro = this.filtroSelected;
        return this.computedItems.filter(item => {
            if(filtro === "ALL"){
                return item
            }else if(filtro === "ACTIVO"){
                return item.status === true
            }else if(filtro === "INACTIVO"){
                return item.status === false
            }
        })
    }
    
    function optionsListPaises(){
        return[
            {value:"ALL",label:this.$t('label.all')},{value:"ACTIVO",label:this.$t('label.ACTIVO')},{value:"INACTIVO",label:this.$t('label.INACTIVO')}]
    }
    //methods
    function pageChange(val) {
        this.$router.push({ query: { page: val }});
    }
    function activarModal(){
         this.$store.state.ubicacion.iconRoute = '';
        this.$store.commit('ubicacion/mutationModalPais', true);
    }
    function updateModal(item,index){
        this.$store.state.ubicacion.iconRoute = item.Icon;
        this.$store.commit('ubicacion/mutationModalPais', true);
        this.$store.commit('ubicacion/asignarid', item.CountryId);
    }
    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }
    function handlerRoute() {
        return {
            immediate: true,
            handler (route) {
                if (route.query && route.query.page) {
                this.activePage = Number(route.query.page)
                }
            }
        };
    }
    

    export default {
        name: 'app-pais',
        data,
        mixins: [General],
        props: ['loading','Tab'],
        watch: {
            $route: handlerRoute,

            Tab:function (val){
                if(val == 0){
                    //this.$store.dispatch('ubicacion/getPaislist');
                }
            },
        },
        methods: {
            pageChange,
            srcImage,
            setAltImg,
            activarModal,
            updateModal,
        },
        computed: {
            fields,
            optionsListPaises,
            computedItems,
            formatedItems
        }
    }
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>