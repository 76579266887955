<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.listOfOversizedContainers') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CSelect
                    :value.sync="preference"
                    v-model.trim="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="filterPreference"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    :disabled = "disabledFilterByPreference"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-auto center-field" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getOversizedList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>

        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"   
                    v-model="search"        
                >
                </CInput>  
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>    
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';


    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                STOWAGE:true,
                pod:true,
                weigth:true,
                slot_CONTAINER:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                Yard:true,
                Procedence:true,
                DirectDischarge:true
            },
            preference:1,
            preferenceOptions: [],
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(), 
            disabledFilterByPreference: false,
            nulo:'',
            search:'',
            labelUnitMeasure: 'TON',
            pageSize:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-info text-white font-weight-bold'
        }
         if (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES") {
            return 'bg-confirmed'
        }
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }   
            this.unitMeasureId = listado[0].UnitMeasureId;  
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX: rowData[i].IdX,
                BayCode: rowData[i].BayCode,
                BaySlot: rowData[i].BaySlot,
                ContainerCode: rowData[i].ContainerCode,
                TpCargoDetailCode: rowData[i].TpCargoDetailCode,
                IsoCode: rowData[i].IsoCode,
                Seals: rowData[i].Seals,
                Status: rowData[i].Status,
                ShippingLineCode: rowData[i].ShippingLineCode,
                ShippingLineName: rowData[i].ShippingLineName,
                ImdgCode: rowData[i].ImdgCode,
                LoadPort: rowData[i].LoadPort,
                DischargePort: rowData[i].DischargePort,
                DeliveryPort: rowData[i].DeliveryPort,
                CraneAlias: rowData[i].CraneAlias,
                DimTop: rowData[i].DimTop,
                DimLeft: rowData[i].DimLeft,
                DimRight: rowData[i].DimRight,
                DimBack: rowData[i].DimBack,
                DimFront: rowData[i].DimFront,
                Weigth: formatMilDecimal(parseFloat(rowData[i].Weigth).toFixed(2)),
                FgConfirmed: rowData[i].FgConfirmed,
                ConfirmationDate: rowData[i].ConfirmationDate,
                Procedence: rowData[i].Procedence,
                YardNamePlanning: rowData[i].YardNamePlanning, 
                FgDirect: rowData[i].FgDirect
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.listOfOversizedContainers'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.listOfOversizedContainers'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }
    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
    }

    async function getOversizedList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

        let CargoSumaryJson = {
            UnitMeasureId: this.unitMeasureId,
            VisitId: this.Visit,
            CompanyBranchId: this.branch.CompanyBranchId,
            VisitCraneId:this.VisitCraneId,
            DateStart:dateStart,
            DateEnd:dateEnd,
            UserPreference: this.preference
        };

        await this.$http.post("VisitCargoOogSummary", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            let i = 1;
            let _this = this;
            if (this.nulo=="null") return false;
            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                IdX: i++,
                BayCode: listado.BayCode ? listado.BayCode : '',
                BaySlot: listado.BaySlot ? listado.BaySlot : '',
                ContainerCode: listado.ContainerCode ? listado.ContainerCode : '',
                Size: listado.Size ? listado.Size : '',
                LoadPort: listado.LoadPort ? listado.LoadPort : '',
                DischargePort: listado.DischargePort ? listado.DischargePort  : '',
                DimTop: listado.DimTop ? listado.DimTop : '',
                DimLeft: listado.DimLeft ? listado.DimLeft : '',
                DimRight: listado.DimRight ? listado.DimRight : '',
                DimBack: listado.DimBack ? listado.DimBack : '',
                DimFront: listado.DimFront ? listado.DimFront : '',
                Weigth: listado.Weigth ? listado.Weigth: 0,
                ShippingLineCode: listado.ShippingLineCode ? listado.ShippingLineCode : '',
                ShippingLineName: listado.ShippingLineName ? listado.ShippingLineName : '',
                Status: listado.Status ? listado.Status : '',
                TpCargoDetailCode: listado.TpCargoDetailCode,
                ImdgCode: listado.ImdgCode ? listado.ImdgCode : '',
                Seals: listado.Seals ? listado.Seals : '', 
                IsoCode: listado.IsoCode ? listado.IsoCode : '', 
                DeliveryPort: listado.DeliveryPort ? listado.DeliveryPort : '', 
                CraneAlias: listado.CraneAlias ? listado.CraneAlias : '',
                FgConfirmed: listado.FgConfirmed === true ? _this.$t('label.yes') : 'NO',
                ConfirmationDate: listado.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(listado.ConfirmationDate) : '',
                Procedence: listado.Procedence,
                YardNamePlanning: listado.YardNamePlanning, 
                FgDirect: listado.FgDirect? 'YES' : 'NO'

            }));

            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
           
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
    
    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.pageSize='';
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.unitMeasureId= '';
        this.VisitCraneId= '';
        this.preference= 1;
        this.disabledFilterByPreference= false;
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.getDateRange();
        await this.getPreferenceList();
        await this.getCraneList();
        await this.getUnitMeasureWeigthList();
        await this.getOversizedList();
    }

    function filterPreference(e) {
        this.preference=e.target.value;
        if (this.preference == 3)
            this.disabledFilterByPreference= true;
        else
            this.disabledFilterByPreference= false;
    } 

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
//computed
    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
        {
            field: "IdX",
            headerName: "#",
            //lockVisible: true,
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 70,
            //pinned: 'left',
            cellClass: 'gb-cell',
            filter: "agTextColumnFilter",
            //checkboxSelection: true, 
        },
        {
            field: "BayCode",
            headerName: "BAY",
            //lockVisible: true,
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 160,
            //pinned: 'left',
            cellClass: 'gb-cell',
            //checkboxSelection: true, 
        },
        {
            field: "BaySlot",
            headerName: "SLOT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 150,
            //pinned: 'left',
            cellClass: 'gb-cell',
        },
        {
            field: "ContainerCode",
            headerName: "CONTAINER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 150,
            //pinned: 'left',
            cellClass: 'gb-cell',
        },
        {
            field: "TpCargoDetailCode",
            headerName: "SIZE (FT)",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "IsoCode",
            headerName: "ISO CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Seals",
            headerName: "SEALS",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Status",
            headerName: "STATUS",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ShippingLineCode",
            headerName: "CARRIER CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ShippingLineName",
            headerName: "CARRIER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ImdgCode",
            headerName: "IMDG CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "LoadPort",
            headerName: "LOAD PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "DischargePort",
            headerName: "DISCHARGE PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "DeliveryPort",
            headerName: "DELIVERY PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "CraneAlias",
            headerName: "CRANE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 150,
        },
        {
            field: "DimTop",
            headerName: "DIM TOP",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 140,
            cellClass: 'center-cell-especial',
            /*headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> OVER <br />HEIGHT' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +                            
                    '</div>'
            }*/
        },
        {
            field: "DimLeft",
            headerName: "DIM LEFT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 170,
            cellClass: 'center-cell-especial',
            /*headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> OVER <br />WIDTH <br />LEFT' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +                            
                    '</div>'
            }*/
        },
        {
            field: "DimRight",
            headerName: "DIM RIGHT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 180,
            cellClass: 'center-cell-especial',
            /*headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> OVER <br />WIDTH <br />RIGHT' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +                            
                    '</div>'
            }*/
        },
        {
            field: "DimBack",
            headerName: "DIM BACK",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 180,
            cellClass: 'center-cell-especial',
            /*headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> OVER <br />WIDTH <br />BACK' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +                            
                    '</div>'
            }*/
        },
        {
            field: "DimFront",
            headerName: "DIM FRONT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 180,
            cellClass: 'center-cell-especial',
            /*headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> OVER <br />WIDTH <br />FRONT' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +                            
                    '</div>'
            }*/
        },
        {
            field: "Weigth",
            headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            cellClass: 'center-cell-especial',
            cellRenderer: params => {
                let variable = 0.00;
                if(params.value !== null && params.value !== '' ){    
                    let newValue =  Number.parseFloat(params.value).toFixed(2);
                    variable = formatMilDecimal(newValue);
                }
                return variable;
            },
            filter: "agNumberColumnFilter",
        },
        {
            field: "FgConfirmed",
            headerName: "CONFIRMED",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ConfirmationDate",
            headerName: "CONFIRMATION",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            cellClass: 'center-cell-especial',
        },
        ]

        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        return columnDefs;
    }

    export default {
        name: "oversized-list",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnExport,
            onBtnPdf,
            getOversizedList,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            getCraneList,
            filterCrane,
            validateDateRange,
            refreshComponent,
            filterPreference,
            getPreferenceList,
            getDateRange,
        },
        computed:{
            preferenceListFormatted,
            craneListFormatted,
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==36) {
                    await  this.refreshComponent();
                    //this.listarPuertos();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }

    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>