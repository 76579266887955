import Fuse from "fuse.js";

//Data:
function data() {
  return {
    isLoadingGeneral: false,
    isLoadingEstado: false
  }
}

//Meodos:
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function getPaises() {
  this.isLoadingGeneral = true;

  this.$http.get('Country-list', { Filter: 'ACTIVO' })
  .then((response) => {
    this.paises = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}
function getEstados(idPais) {
  this.isLoadingEstado = true;

  this.$http.get('State-list', { CountryId: idPais, Filter: 'ACTIVO' })
  .then((response) => {
    this.estados = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingEstado = false;
  });
}
function fuseSearchPais(options, search) {
  const fuse = new Fuse(options, {
    keys: ["IsoCodeAlpha2", "IsoCodeAlpha3", "CountryName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
function fuseSearchEstado(options, search) {
  const fuse = new Fuse(options, {
    keys: ["StateName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
function touchRequiredValues() {
  this.$v.puerto.PortName.$touch();
  this.$v.puerto.PortCode.$touch();
  this.$v.pais.$touch();

  if(this.$v.puerto.Latitude.$model)
    this.$v.puerto.Latitude.$touch();

  if(this.$v.puerto.Longitude.$model)
    this.$v.puerto.Longitude.$touch();
}

//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function isEstadosEmpty(){
  return this.estados.length == 0;
}
function isPaisesEmpty(){
  return this.paises.length == 0;
}
function paisOptions() {
  return this.paises.map((pais) => Object.assign({}, pais, {
    label: pais.CountryName,
    value: pais.CountryId
  }));
}
function estadoOptions() {
  return this.estados.map((estado) => Object.assign({}, estado, {
    label: estado.StateName,
    value: estado.StateId
  }));
}
function isPaisValid() {
  return {
    'invalid-feedback': true,
    invalid: this.pais? null : this.$v.$anyDirty? this.$v.pais.$invalid : null
  };
}

export default {
  data,
  methods: {
    hasError,
    getPaises,
    getEstados,
    fuseSearchPais,
    fuseSearchEstado,
    touchRequiredValues
  },
  computed: {
    isSubmitValid,
    isEstadosEmpty,
    isPaisesEmpty,
    paisOptions,
    estadoOptions,
    isPaisValid
  }
}