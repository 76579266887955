<template>
  <div>
    <loading-overlay :active="isLoadingOverlay" :is-full-page="true" loader="bars" />             
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-extended"
    >
      <div>
        <div v-if="showCollapseInspection">
          <CRow class="justify-content-center">
            <CCol sm="9" lg="9">
              <CInput
                :label="$t('label.containerCode')"
                :placeholder="$t('label.containerCode')"
                :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                addLabelClasses="text-right required"
                v-model="$v.FormInspection.inspection.containerCode.$model"
                :value.sync="containerCode"
                :is-valid="hasError($v.FormInspection.inspection.containerCode)" 
                :invalid-feedback="errorMessage($v.FormInspection.inspection.containerCode)"
                maxLength = "11"
                @focus="$v.FormInspection.inspection.containerCode.$touch()"
                @blur="$v.FormInspection.inspection.containerCode.$touch()"
                v-uppercase
              >
                <template #append>
                  <CButton
                    color="watch"
                    size="sm"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.container'),placement: 'top'}"
                    @click="searchContainer(true)"
                  >
                    <CIcon name="cil-search" />
                  </CButton>
                </template>
              </CInput>
            </CCol>
            <CCol sm="2" lg="2" class="center-field mr-0">
              <CButton
                color="wipe"
                class="justify-content-end"
                size="sm"
                v-c-tooltip="{content: $t('label.clearFilter'), placement: 'top-end'}" 
                @click="refreshComponentInspection()"
                >
                <CIcon name="cil-brush-alt" />
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="11" lg="6" >
              <CRow>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.group')"
                    :placeholder="$t('label.group')"
                    :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.group"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.carrier')"
                    :placeholder="$t('label.newShippingLineName')"
                    :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.carrier"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.status')"
                    :placeholder="$t('label.status')"
                    :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.status"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    label="POL"
                    placeholder="POL"
                    :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.pol"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CSelect
                    :label="$t('label.inspectionType')"
                    :placeholder="$t('label.select')"
                    :horizontal="{label:'col-sm-11 col-lg-6', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right required"
                    v-model.trim="FormInspection.inspection.inspectionTypeId"
                    :value.sync="FormInspection.inspection.inspectionTypeId"
                    :is-valid="hasError($v.FormInspection.inspection.inspectionTypeId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.inspection.inspectionTypeId)"
                    :options="inspectionTypeOptions"
                    @blur="$v.FormInspection.inspection.inspectionTypeId.$touch()"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="11" lg="6" >
              <CRow>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.isoCode')"
                    :placeholder="$t('label.isoCode')"
                    :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.isoCode"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.size')+' (FT)'" 
                    :placeholder="$t('label.size')+' (FT)'"
                    :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.size"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    :label="$t('label.stowageMovement')"
                    :placeholder="$t('label.stowageMovement')"
                    :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="FormInspection.seals.movStowageName"
                  />
                </CCol>
                <CCol sm="11" lg="12" >
                  <CInput
                    label="POD"
                    placeholder="POD"
                    :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-6'}"
                    addLabelClasses="text-right "
                    disabled
                    v-model="container.pod"
                  />
                </CCol>
                
              </CRow>
            </CCol>
          </CRow>
        </div>
        <!-- daños -->
        <div v-if="showCollapseDamage">
          <CRow>
            <CCol sm="11" lg="7">
              <CSelect
                :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.damageType')"
                addLabelClasses="required text-right"
                v-model.trim="FormInspection.damage.damageTypeId"
                :value.sync="FormInspection.damage.damageTypeId"
                :is-valid="hasError($v.FormInspection.damage.damageTypeId)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.damageTypeId)"
                :options="damageTypeOptions"
                @blur="$v.FormInspection.damage.damageTypeId.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5">
              <CInput
                :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.height')+'(CM)'"
                addLabelClasses="text-right"
                type="number"
                min="0"
                v-model.trim="FormInspection.damage.height"
                :value.sync="FormInspection.damage.height"
                :is-valid="hasError($v.FormInspection.damage.height)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.height)"
                @blur="$v.FormInspection.damage.height.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="7" >
              <CSelect
                :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.localizacion')"
                addLabelClasses="required text-right"
                v-model.trim="FormInspection.damage.locationId"
                :value.sync="FormInspection.damage.locationId"
                :is-valid="hasError($v.FormInspection.damage.locationId)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.locationId)"
                :options="locationOptions"
                @blur="$v.FormInspection.damage.locationId.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5" >
              <CInput
                :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.width')+'(CM)'"
                addLabelClasses="text-right"
                type="number"
                min="0"
                v-model.trim="FormInspection.damage.width"
                :value.sync="FormInspection.damage.width"
                :is-valid="hasError($v.FormInspection.damage.width)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.width)"
                @blur="$v.FormInspection.damage.width.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="7">
              <CSelect
                :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.inspectionMoment')"
                addLabelClasses="required text-right"
                v-model.trim="FormInspection.damage.inspectionMomentId"
                :value.sync="FormInspection.damage.inspectionMomentId"
                :is-valid="hasError($v.FormInspection.damage.inspectionMomentId)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.inspectionMomentId)"
                :options="momentInspectionList"
                @blur="$v.FormInspection.damage.inspectionMomentId.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5">
              <div class="form-group form-row mb-0" rol="group">
                <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
                  {{$t('label.date')}}
                </label>
                <div class="col-sm-11 col-lg-7 input-group">
                  <vue-datepicker
                    valueType="format"
                    format="DD/MM/YYYY"
                    :open="openDate"
                    :lang="this.$i18n.locale"
                    @update:open="functionDate($event)"
                    @clickoutside="openDate = false"
                    @select="openDate = false"
                    @pick="openDate = false"
                    @close="openDate = false"
                    :clearable="false"
                    :disabled-date="validateDateRange"
                    v-model.trim="$v.FormInspection.damage.date.$model"
                    :append-to-body="false"
                    class="w-100"
                  >
                    <template #input>
                      <CInput
                        addInputClasses="pr-0"
                        class="w-100 float-left"
                        v-uppercase
                        v-model="FormInspection.damage.date"
                        placeholder="DD/MM/YYYY"
                        :invalid-feedback="$t('label.required')"
                        :is-valid="hasError($v.FormInspection.damage.date)"
                        v-model.trim="$v.FormInspection.damage.date.$model"
                        @keypress="rightDate($event)"
                      >
                        <template #append-content>
                          <CIcon name="cil-calendar" />
                        </template>
                      </CInput>
                    </template>
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
              </div>
            </CCol>
            <CCol sm="11" lg="7" >
              <CInput
                :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.responsible')"
                addLabelClasses="text-right"
                v-model.trim="FormInspection.damage.responsible"
                :value.sync="FormInspection.damage.responsible"
                :is-valid="hasError($v.FormInspection.damage.responsible)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.responsible)"
                @blur="$v.FormInspection.damage.responsible.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5">
              <div class="form-group form-row mb-0" rol="group">
                <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
                  {{$t('label.hour')}}
                </label>
                <div class="col-sm-11 col-lg-7 input-group">
                  <vue-datepicker
                    valueType="format"
                    type="time"
                    :format="'HH:mm'"
                    v-model="FormInspection.damage.hour"
                    :clearable="false"
                    v-model.trim="$v.FormInspection.damage.hour.$model"
                    :append-to-body="false"
                    class="w-100"
                  >
                    <template #input>
                      <CInput
                        class="w-100 float-left"
                        v-uppercase
                        v-model="FormInspection.damage.hour"
                        placeholder="HH:MM"
                        :invalid-feedback="$t('label.required')"
                        :is-valid="hasError($v.FormInspection.damage.hour)"
                        v-model.trim="$v.FormInspection.damage.hour.$model"
                        @keypress="formatHourMMHH($event)"
                      >
                        <template #append-content>
                          <CIcon name="cil-clock" />
                        </template>
                      </CInput>
                    </template>
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
              </div>
            </CCol>           
            <CCol sm="11" lg="7"  >
              <CTextarea
                :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                :label="$t('label.observation')"
                addLabelClasses="text-right"
                v-uppercase
                v-model.trim="FormInspection.damage.observation"
                :value.sync="FormInspection.damage.observation"
                :is-valid="hasError($v.FormInspection.damage.observation)" 
                :invalid-feedback="errorMessage($v.FormInspection.damage.observation)"
                @blur="$v.FormInspection.damage.observation.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5">
            </CCol> 
          </CRow>
        </div>
        <!-- precintos -->
        <div v-if="showCollapseSeals">
          <CRow class="mx-1 justify-content-center">
            <CCol sm="12" lg="6">
              <CSelect
                :label="$t('label.moment')+' '+$t('label.inspection')"
                addLabelClasses="text-right"
                :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                v-model="FormInspection.seals.inspectionMomentId"
                :value.sync="FormInspection.seals.inspectionMomentId"
                :options="momentInspectionList"
                :is-valid="hasError($v.FormInspection.seals.inspectionMomentId)" 
                :invalid-feedback="errorMessage($v.FormInspection.seals.inspectionMomentId)"
                @blur="$v.FormInspection.seals.inspectionMomentId.$touch()"
              />
            </CCol>
            <CCol sm="11" lg="5">
          <div class="form-group form-row mb-0" rol="group">
            <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
              {{$t('label.date')}}
            </label>
            <div class="col-sm-11 col-lg-8 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDate"
                :lang="this.$i18n.locale"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.FormInspection.seals.date.$model"
                :append-to-body="false"
                class="w-100"
              >
                <template #input>
                  <CInput
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    v-model="FormInspection.seals.date"
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.FormInspection.seals.date)"
                    v-model.trim="$v.FormInspection.seals.date.$model"
                    @keypress="rightDate($event)"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
            </CCol>
            <CCol sm="12" lg="6">
              <CInput
                :label="$t('label.stowageMovement')"
                addLabelClasses="text-right"
                :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                v-model="FormInspection.seals.movStowageName"
                readonly
              />
            </CCol>
            <CCol sm="11" lg="5">
              <div class="form-group form-row mb-0" rol="group">
                <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
                  {{$t('label.hour')}}
                </label>
                <div class="col-sm-11 col-lg-8 input-group">
                  <vue-datepicker
                    valueType="format"
                    type="time"
                    :format="'HH:mm'"
                    v-model="FormInspection.seals.hour"
                    :clearable="false"
                    v-model.trim="$v.FormInspection.seals.hour.$model"
                    :append-to-body="false"
                    class="w-100"
                  >
                    <template #input>
                      <CInput
                        class="w-100 float-left"
                        v-uppercase
                        v-model="FormInspection.seals.hour"
                        placeholder="HH:MM"
                        :invalid-feedback="$t('label.required')"
                        :is-valid="hasError($v.FormInspection.seals.hour)"
                        v-model.trim="$v.FormInspection.seals.hour.$model"
                        @keypress="formatHourMMHH($event)"
                      >
                        <template #append-content>
                          <CIcon name="cil-clock" />
                        </template>
                      </CInput>
                    </template>
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
              </div>
            </CCol>
        </CRow>
        <CRow class="mx-1">
          <CCol md="12" lg="4" class="bg-watch text-center" align-vertical="center">
            {{$t("label.previous")}}
          </CCol>
          <CCol md="12" lg="8" >
            <CRow>
              <CCol md="12" lg="12" class="bg-edit text-center">
                {{$t("label.actualConditions")}}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4" class="bg-watch text-center">
                {{$t("label.condition")}}
              </CCol>
              <CCol md="12" lg="4" class="bg-watch text-center">
                {{$t("label.inspection")}}
              </CCol>
              <CCol md="12" lg="4" class="bg-watch text-center">
                {{$t("label.observation")}}
              </CCol>
            </CRow>
          </CCol>       
        </CRow>
        <CRow class="mx-1">
          <CCol md="12" lg="4" class=" text-center pt-3">
            <CRow>  
              <CCol sm="11" lg="12" >
                <CInput
                  :placeholder="$t('label.Seal')+' 1'"                                         
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  v-uppercase
                  :label="$t('label.Seal')+' 1'"
                  v-model="FormInspection.seals.Seal1.seal"
                  :value="FormInspection.seals.Seal1.seal"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12" >
                <CInput
                  :placeholder="$t('label.Seal')+' 2'"                                         
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  v-uppercase
                  :label="$t('label.Seal')+' 2'"
                  v-model="FormInspection.seals.Seal2.seal"
                  :value="FormInspection.seals.Seal2.seal"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12" >
                <CInput
                  :placeholder="$t('label.Seal')+' 3'"                                         
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  v-uppercase
                  :label="$t('label.Seal')+' 3'"
                  v-model="FormInspection.seals.Seal3.seal"
                  :value="FormInspection.seals.Seal3.seal"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12" >
                <CInput
                  :placeholder="$t('label.Seal')+' 4'"                                         
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  v-uppercase
                  :label="$t('label.Seal')+' 4'"
                  v-model="FormInspection.seals.Seal4.seal"
                  :value="FormInspection.seals.Seal4.seal"
                  disabled
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="12" lg="8" >
            <CRow>
              <CCol md="12" lg="4" class="pt-3 px-1">
                <CRow class="border-left ">
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal1.seal !== null && FormInspection.seals.Seal1.seal !== '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal1.sealConditionId"
                      :value.sync="conditionSeal1"
                      :options="conditionList"
                      :is-valid="hasError($v.FormInspection.seals.Seal1.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal1.sealConditionId.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal1.seal === null || FormInspection.seals.Seal1.seal === '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal1.sealConditionId"
                      :value.sync="conditionSeal1"
                      :options="conditionListEmpty"
                      :is-valid="hasError($v.FormInspection.seals.Seal1.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal1.sealConditionId.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal2.seal !== null && FormInspection.seals.Seal2.seal !== '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal2.sealConditionId"
                      :value.sync="conditionSeal2"
                      :options="conditionList"
                      :is-valid="hasError($v.FormInspection.seals.Seal2.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal2.sealConditionId.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal2.seal === null || FormInspection.seals.Seal2.seal === '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal2.sealConditionId"
                      :value.sync="conditionSeal2"
                      :options="conditionListEmpty"
                      :is-valid="hasError($v.FormInspection.seals.Seal2.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal2.sealConditionId.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal3.seal !== null && FormInspection.seals.Seal3.seal !== '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal3.sealConditionId"
                      :value.sync="conditionSeal3"
                      :options="conditionList"
                      :is-valid="hasError($v.FormInspection.seals.Seal3.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal3.sealConditionId.$touch()"                     
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal3.seal === null || FormInspection.seals.Seal3.seal === '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal3.sealConditionId"
                      :value.sync="conditionSeal3"
                      :options="conditionListEmpty"
                      :is-valid="hasError($v.FormInspection.seals.Seal3.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal3.sealConditionId.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal4.seal !== null && FormInspection.seals.Seal4.seal !== '' ">
                    <CSelect
                      v-model="FormInspection.seals.Seal4.sealConditionId"
                      :value.sync="conditionSeal4"
                      :options="conditionList"
                      :is-valid="hasError($v.FormInspection.seals.Seal4.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal4.sealConditionId.$touch()"
                      
                    />
                  </CCol>
                  <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal4.seal === null || FormInspection.seals.Seal4.seal === '' " >
                    <CSelect
                      v-model="FormInspection.seals.Seal4.sealConditionId"
                      :value.sync="conditionSeal4"
                      :options="conditionListEmpty"
                      :is-valid="hasError($v.FormInspection.seals.Seal4.sealConditionId)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.sealConditionId)"
                      @blur="$v.FormInspection.seals.Seal4.sealConditionId.$touch()"
                      
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" lg="4" class="pt-3">
                <CRow >
                  
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal1.inspection"
                      :value="FormInspection.seals.Seal1.inspection"
                      :is-valid="hasError($v.FormInspection.seals.Seal1.inspection)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.inspection)"
                      @blur="$v.FormInspection.seals.Seal1.inspection.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal2.inspection"
                      :value="FormInspection.seals.Seal2.inspection"
                      :is-valid="hasError($v.FormInspection.seals.Seal2.inspection)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.inspection)"
                      @blur="$v.FormInspection.seals.Seal2.inspection.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal3.inspection"
                      :value="FormInspection.seals.Seal3.inspection"
                      :is-valid="hasError($v.FormInspection.seals.Seal3.inspection)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.inspection)"
                      @blur="$v.FormInspection.seals.Seal3.inspection.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal4.inspection"
                      :value="FormInspection.seals.Seal4.inspection"
                      :is-valid="hasError($v.FormInspection.seals.Seal4.inspection)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.inspection)"
                      @blur="$v.FormInspection.seals.Seal4.inspection.$touch()"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" lg="4"  class="pt-3">
                <CRow class=""> 
                  
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal1.observation"
                      :value="FormInspection.seals.Seal1.observation"
                      :is-valid="hasError($v.FormInspection.seals.Seal1.observation)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.observation)"
                      @blur="$v.FormInspection.seals.Seal1.observation.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal2.observation"
                      :value="FormInspection.seals.Seal2.observation"
                      :is-valid="hasError($v.FormInspection.seals.Seal2.observation)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.observation)"
                      @blur="$v.FormInspection.seals.Seal2.observation.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12" >
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal3.observation"
                      :value="FormInspection.seals.Seal3.observation"
                      :is-valid="hasError($v.FormInspection.seals.Seal3.observation)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.observation)"
                      @blur="$v.FormInspection.seals.Seal3.observation.$touch()"
                    />
                  </CCol>
                  <CCol sm="11" lg="12">
                    <CInput
                      v-uppercase
                      v-model="FormInspection.seals.Seal4.observation"
                      :value="FormInspection.seals.Seal4.observation"
                      :is-valid="hasError($v.FormInspection.seals.Seal4.observation)" 
                      :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.observation)"
                      @blur="$v.FormInspection.seals.Seal4.observation.$touch()"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        </div>
      </div>
      <template #footer>
        <CButton  color="watch" @click="checkCollapseBack" v-if="showCollapseDamage || showCollapseSeals" :disabled="isSubmit">
          <CIcon name="returnArrowBold" />&nbsp; {{$t('label.back')}}
        </CButton>
        <CButton color="add" @click="checkCollapseNext" v-if="showCollapseInspection" :disabled="isSubmit">
          <CIcon name="cil-arrow-right" />&nbsp; {{$t('label.next')}}
        </CButton>
        <CButton color="add" @click="submitDamage" v-if="showCollapseDamage" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="add" @click="submitSeals" v-if="showCollapseSeals" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>

</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import { FormInspection } from '@/_validations/inspection/inspectionSealsValidation';
import { mapState } from 'vuex';

function data() {
  return {
     //collapses
    showCollapseInspection: true,
    showCollapseDamage: false,
    showCollapseSeals: false,
    openDate: false,
    showModal: false,
    titleModal: '',
    isLoadingOverlay: false,
    actualizar: false,
    isSubmit: false,
    previousDate: new Date(),
    laterDate: new Date(),

    containerList: '',
    containerCode: '',

     container:{
      group: '',
      carrier: '',
      status: '',
      pol:'',
      inspectionTypeId: '',
      isoCode: '',
      size: '',
      pod: '',
      visitCargoId: '',
    },
    //modelo inspecciones
    FormInspection:{
        inspection:{
          containerCode: '',
          inspectionTypeId: '',
          visitCargoId: '',
        },

        //modelo daños
        damage: {
          damageTypeId: '',
          locationId: '',
          width: '',
          height: '',
          inspectionMomentId: '', 
          responsible: '',
          date: 'DD/MM/YYYY',
          hour: 'HH:MM',
          observation: '',
        },

        //modelo precintos
        seals: {
          date: 'DD/MM/YYYY',
          hour: 'HH:MM',
          inspectionMomentId: '',
          movStowageName: '',
          Status: 1,
        
          Seal1: {
            sealConditionId: '',
            seal: '',
            inspection: '',
            observation: '',
          },
          Seal2: {
            sealConditionId: '',
            seal: '',
            inspection: '',
            observation: '',
          },
          Seal3: {
            sealConditionId: '',
            seal: '',
            inspection: '',
            observation: '',
          },
          Seal4: {
            sealConditionId: '',
            seal: '',
            inspection: '',
            observation: '',
          },
        },
    },
    momentInspectionList: [],
    damageTypeOptions: [],
    locationOptions: [],
    conditionList: [],

    conditionList: [],

    verifySeal1: false,
    verifySeal2: false,
    verifySeal3: false,
    verifySeal4: false,

    conditionSeal1: '',
    conditionSeal2: '',
    conditionSeal3: '',
    conditionSeal4: '',
  };
}
//methods

function closeModal() {
  this.refreshComponentInspection();
  this.refreshComponentDamage();
  this.refreshComponentSeal();
  this.$emit('child-refresh', true);
  this.showModal=false;
  this.openDate = false;
}

function refreshComponentInspection() {
  this.FormInspection.inspection.containerCode = '';
  this.container.group = '';
  this.container.carrier = '';
  this.container.status = '';
  this.container.pol = ''; 
  this.FormInspection.inspection.inspectionTypeId = '';
  this.container.isoCode = '';
  this.container.size = '';
  this.container.pod = '';
  this.FormInspection.inspection.visitCargoId = '';
  this.showCollapseInspection = true;
  this.showCollapseDamage = false;
  this.showCollapseSeals = false;
  this.showTimePanel = false;
  this.containerCode = '';
  this.$v.FormInspection.inspection.$reset();
}

function checkCollapseNext(){
  try{
    this.$v.FormInspection.inspection.$touch();

  //  if (this.$v.FormInspection.inspection.$error) {
      if (this.FormInspection.inspection.visitCargoId === '') {
        throw this.$t('label.searchForContainerToContinue');
      }
      if (this.FormInspection.inspection.inspectionTypeId === '') {
        throw this.$t('label.errorsPleaseCheck');
      }


 //   }
    //daños
    if(this.FormInspection.inspection.inspectionTypeId && this.FormInspection.inspection.inspectionTypeId !== ''){
      this.titleModal = this.$t('label.newDamage');
      this.showCollapseInspection = false;
      this.showCollapseDamage = true;
      this.showCollapseSeals = false;
    }

    //precintos
    if(!this.FormInspection.inspection.inspectionTypeId && this.FormInspection.inspection.inspectionTypeId !== ''){
      this.titleModal = this.$t('label.inspectionSeals')+': '+this.FormInspection.inspection.containerCode;
      this.showCollapseInspection = false;
      this.showCollapseDamage = false;
      this.showCollapseSeals = true;
    }
  }catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}


function checkCollapseBack(){
    //daños
    if(this.FormInspection.inspection.inspectionTypeId){
      this.titleModal = this.$t('label.nueva')+this.$t('label.inspection');
      this.showCollapseInspection = true;
      this.showCollapseDamage = false;
      this.showCollapseSeals = false;
      this.$v.FormInspection.damage.$reset();
    }

    //precintos
    if(!this.FormInspection.inspection.inspectionTypeId){
      this.titleModal = this.$t('label.nueva')+this.$t('label.inspection');
      this.showCollapseInspection = true;
      this.showCollapseDamage = false;
      this.showCollapseSeals = false;
      this.$v.FormInspection.damage.$reset();
    }
}

function submitDamage(){
  try{
    this.isLoadingOverlay = true;
    this.isSubmit = true;
    this.$v.FormInspection.damage.$touch();

    if (this.$v.FormInspection.damage.$error) {
      this.isLoadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let dateNow = new Date();
    let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
    let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
    let startTime = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    let startDate = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

    if((this.FormInspection.damage.date === currentDate) && (this.FormInspection.damage.hour > currentTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsCurrentDateTimePleaseCheck');
    }

    if((this.FormInspection.damage.date === startDate) && (this.FormInspection.damage.hour < startTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsStartDateTimePleaseCheck');
    }

    const CargoJson = {
      VisitCargoId: this.FormInspection.inspection.visitCargoId, 
      TpCargoViewId: this.FormInspection.damage.locationId,
      TpDamageId: this.FormInspection.damage.damageTypeId,
      Width: this.FormInspection.damage.width,
      Height: this.FormInspection.damage.height,
      Observation: this.FormInspection.damage.observation, 
      Responsible: this.FormInspection.damage.responsible,  
      InspectionMomentId: this.FormInspection.damage.inspectionMomentId,  
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.FormInspection.damage.date+' '+this.FormInspection.damage.hour),
    };
    
    let method = 'POST';
    let ruta = 'VisitCargoInspDamage-insert';

    this.$http
      .ejecutar (method, ruta, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          this.closeModal();
          this.isLoadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
        this.isLoadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.isSubmit = false;
      this.isLoadingOverlay = false;

    });
  }catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

function refreshComponentDamage() {
  this.FormInspection.damage.damageTypeId = '';
  this.FormInspection.damage.locationId = '';
  this.FormInspection.damage.width = '';
  this.FormInspection.damage.height = '';
  this.FormInspection.damage.inspectionMomentId = '';
  this.FormInspection.damage.responsible = '';
  this.FormInspection.damage.date = '';
  this.FormInspection.damage.hour = '';
  this.FormInspection.damage.observation = '';

  this.$v.FormInspection.inspection.$reset();
  this.$v.FormInspection.damage.$reset();
  this.$v.FormInspection.seals.$reset();

  this.showCollapseInspection = true;
  this.showCollapseDamage = false;
  this.showCollapseSeals = false;

  this.showTimePanel = false;
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

function submitSeals(){
try {
    this.isLoadingOverlay = true;
    this.isSubmit = true;
    this.$v.FormInspection.seals.$touch();
    let verifySeals= false;
    if(
        (this.FormInspection.seals.Seal1.inspection==='' && this.FormInspection.seals.Seal1.sealConditionId==='') ||
        (this.FormInspection.seals.Seal2.inspection==='' && this.FormInspection.seals.Seal2.sealConditionId==='') ||
        (this.FormInspection.seals.Seal3.inspection==='' && this.FormInspection.seals.Seal3.sealConditionId==='') ||
        (this.FormInspection.seals.Seal4.inspection==='' && this.FormInspection.seals.Seal4.sealConditionId==='')
      )
      {
      this.verifySeal1 = true;  
      this.verifySeal2 = true;   
      this.verifySeal3 = true;  
      this.verifySeal4 = true; 
      verifySeals = true;

      if(this.FormInspection.seals.Seal1.inspection!=='' && this.FormInspection.seals.Seal1.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }
 
      if(this.FormInspection.seals.Seal2.inspection!=='' && this.FormInspection.seals.Seal2.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false; 
      }
       
      if(this.FormInspection.seals.Seal3.inspection!=='' && this.FormInspection.seals.Seal3.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }

      if(this.FormInspection.seals.Seal4.inspection!=='' && this.FormInspection.seals.Seal4.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }

      this.isLoadingOverlay = false;
      this.isSubmit = false;
      if (verifySeals) throw this.$t('label.requiresAtLeastOneSealInspection');
    }

    if (this.$v.FormInspection.seals.$error) {
      this.isLoadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let dateNow = new Date();
    let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
    let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
    let startTime = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    let startDate = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

    if((this.FormInspection.seals.date === currentDate) && (this.FormInspection.seals.hour > currentTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsCurrentDateTimePleaseCheck');
    }

    if((this.FormInspection.seals.date === startDate) && (this.FormInspection.seals.hour < startTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsStartDateTimePleaseCheck');
    }

    const CargoJson = {
      VisitCargoId: this.FormInspection.inspection.visitCargoId , 
      InspectionMomentId: this.FormInspection.seals.inspectionMomentId,

      Seal1: this.FormInspection.seals.Seal1.inspection,  
      Seal1ConditionId: this.FormInspection.seals.Seal1.sealConditionId,  
      ObservationSeal1: this.FormInspection.seals.Seal1.observation,  

      Seal2: this.FormInspection.seals.Seal2.inspection,  
      Seal2ConditionId: this.FormInspection.seals.Seal2.sealConditionId,  
      ObservationSeal2: this.FormInspection.seals.Seal2.observation,  

      Seal3: this.FormInspection.seals.Seal3.inspection,  
      Seal3ConditionId: this.FormInspection.seals.Seal3.sealConditionId,  
      ObservationSeal3: this.FormInspection.seals.Seal3.observation,  
      
      Seal4: this.FormInspection.seals.Seal4.inspection,  
      Seal4ConditionId: this.FormInspection.seals.Seal4.sealConditionId,  
      ObservationSeal4: this.FormInspection.seals.Seal4.observation,  
          
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.FormInspection.seals.date+' '+this.FormInspection.seals.hour),
    };

    let method = 'POST';
    let ruta = 'VisitCargoSealInspection-insert';

    this.$http
      .ejecutar (method, ruta, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
         //this.$emit('child-refresh');
          this.closeModal();
          this.isLoadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
        this.isLoadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.isSubmit = false;
      this.isLoadingOverlay = false;

    }); 
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

//metodos precintos
async function getSealConditionList() {
  this.isLoadingOverlay = true;
  this.conditionList = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("SealCondition-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.conditionList = listado.map(listado => Object.assign({}, this.conditionList, {
      value: listado.SealConditionId,
      label: _this=='en' ? listado.SealConditionNameEn : listado.SealConditionNameEs,
    }));

    this.conditionList.unshift({
      value: "",
      label: this.$t('label.select')
    });
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.isLoadingOverlay = false;
  });
} 

//lista de momento de la inspeccion
async function getMomentList() {
  this.isLoadingOverlay = true;
  this.momentInspectionList = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("InspectionMoment-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.momentInspectionList = listado.map(listado => Object.assign({}, this.momentInspectionList, {
      value: listado.InspectionMomentId,
      label: _this=='en' ? listado.InspectionMomentNameEn : listado.InspectionMomentNameEs,
    }));

    this.momentInspectionList.unshift({
      value: "",
      label: this.$t('label.select')
    });
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.isLoadingOverlay = false;
  });
}


//lista tipos de daños
async function getDamageTypeList() {
  this.isLoadingOverlay = true;
  this.damageTypeOptions = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("VisitTpDamageL1-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.damageTypeOptions = listado.map(listado => Object.assign({}, this.damageTypeOptions, {
      value: listado.TpDamageId,
      label: _this=='en' ? listado.TpDamageAbbrev+': '+listado.TpDamageNameEn : listado.TpDamageAbbrev+': '+listado.TpDamageNameEs,
    }));

    this.damageTypeOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
    

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.isLoadingOverlay = false;
  });
}

//lista de localizacion del daño - prametro 
async function getLocationList() {
  this.isLoadingOverlay = true;
  this.locationOptions = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  await this.$http.get("VisitTpCargoView-list", { TpCargoId: '6C6CE673-3797-4D11-839B-06C228D51CEF' })
  .then(response => {
    listado = [...response.data.data];
    this.locationOptions = listado.map(listado => Object.assign({}, this.locationOptions, {
      value: listado.TpCargoViewId,
      label: _this=='en' ? listado.TpCargoViewNameEn : listado.TpCargoViewNameEs,
    }));

    this.locationOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.isLoadingOverlay = false;
  });
}

//collapse tiempo/fecha
function toggleTimePanel() {
  this.showTimePanel = !this.showTimePanel;
}

function handleOpenChange() {
  this.showTimePanel = false;
}
//cierra el datepicker cuando selecciona los minutos
function   handleChange(value, type) {
  if (type === 'minute') {
    this.openDate = false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getDateRange(){
  let currentDate = new Date();

  if(this.itinerarySelected.Ata!=undefined){ 
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
  }

  if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
  }

  if(this.itinerarySelected.Atd!=undefined){  
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
  }

  if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
  }
}

//lista de conntenedores por ID
async function getContinerList() {
  this.isLoadingOverlay = true;
  this.containerList = [];
  let listado = Array;
  await this.$http.get("VisitCargoListForInspection-by-VisitId", { VisitId: this.Visit })
  .then(response => {
    listado = [...response.data.data];
    this.containerList = listado;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.isLoadingOverlay = false;
  });
}

function refreshComponentSeal() {
  this.FormInspection.seals.inspectionMomentId = '';
  this.FormInspection.seals.date = '';
  this.FormInspection.seals.hour = '';
  this.FormInspection.seals.movStowageName = '';
  this.FormInspection.seals.status = 1;
  this.FormInspection.seals.FgActStatus = 1;
  this.visitCargoInspectionId = '';

  this.FormInspection.seals.Seal1.inspection = '';
  this.FormInspection.seals.Seal2.inspection = '';
  this.FormInspection.seals.Seal3.inspection = '';
  this.FormInspection.seals.Seal4.inspection = '';

  this.FormInspection.seals.Seal1.sealConditionId = '';
  this.FormInspection.seals.Seal2.sealConditionId = '';
  this.FormInspection.seals.Seal3.sealConditionId = '';
  this.FormInspection.seals.Seal4.sealConditionId = '';

  this.FormInspection.seals.Seal1.seal = '';
  this.FormInspection.seals.Seal2.seal = '';
  this.FormInspection.seals.Seal3.seal = '';
  this.FormInspection.seals.Seal4.seal = '';

  this.FormInspection.seals.Seal1.observation = '';
  this.FormInspection.seals.Seal2.observation = '';
  this.FormInspection.seals.Seal3.observation = '';
  this.FormInspection.seals.Seal4.observation = '';

  this.showTimePanel = false;
  this.openDate = false;
  this.showCollapseInspection = true;
  this.showCollapseDamage = false;
  this.showCollapseSeals = false;

  this.verifySeal1 = false;
  this.verifySeal2 = false;
  this.verifySeal3 = false;
  this.verifySeal4 = false;

  this.$v.FormInspection.seals.$reset();
}


async function searchContainer(e) {
  await this.getContinerList();
  if (!this.$v.FormInspection.inspection.containerCode.$error) {
    const containerDataById = this.containerList.find( item => item.ContainerCode === this.containerCode );
    let _this = this.$i18n.locale;

    if(containerDataById !== '' && containerDataById !== null && containerDataById !== undefined){
      this.container.group = containerDataById.TpCargoNameEn;
      this.container.carrier = containerDataById.ShippingLineCode;
      this.container.status = containerDataById.TbTpCargoStatus;
      this.container.pol = containerDataById.LoadPort;
      this.container.isoCode = containerDataById.IsoCode;
      this.container.size = containerDataById.TpCargoSize;
      this.FormInspection.seals.movStowageName = _this=='en' ? containerDataById.MovStowageNameEn : containerDataById.MovStowageNameEs;
      this.container.pod = containerDataById.DischargePort;
      this.FormInspection.inspection.visitCargoId = containerDataById.VisitCargoId;
      this.FormInspection.seals.Seal1.seal = containerDataById.Seal1;
      this.FormInspection.seals.Seal2.seal = containerDataById.Seal2;
      this.FormInspection.seals.Seal3.seal = containerDataById.Seal3;
      this.FormInspection.seals.Seal4.seal = containerDataById.Seal4;
    }else{
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.verifyContainer'),
        type: "error"
      });
    }
  }
} 

function conditionListEmpty(){
  return [
    { 
      value: '', 
      label: this.$t('label.select')
    },
    { 
      value: '3AF77F9D-F00E-4390-A6C2-F11F8E8E6F6C', 
      label: this.$t('label.withoutSeal')
    },
  ]
} 

function inspectionTypeOptions(){
  return [
    { 
        value: true, 
        label: this.$t('label.damageOption')
    },
    { 
        value: false, 
        label: this.$t('label.Seal')+'S'
    }
  ]
}
export default {
  name: 'modal-inspection',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,
  components: {
  },
  //validations:inspectionValidation,
  validations(){ return FormInspection(this.verifySeal1, this.verifySeal2,this.verifySeal3,this.verifySeal4) },
        
  watch: {
    modal: async function () {
      if (this.modal) {
        if (this.modal==true) {
          this.actualizar  = false;
          this.titleModal = this.$t('label.nueva')+this.$t('label.inspection');
          this.refreshComponentInspection();
          this.showModal = true;  
          this.refreshComponentDamage();
          this.refreshComponentSeal();
          await this.getMomentList();
          await this.getDamageTypeList();
          await this.getLocationList();
          await this.getSealConditionList();   
          this.getDateRange(); 
        }
            
        this.$emit('closeModal');
      }
    }, 
    conditionSeal1: function () {
      if (this.conditionSeal1 !== "" && this.FormInspection.seals.Seal1.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal1.inspection = this.FormInspection.seals.Seal1.seal;
      }else{
        this.FormInspection.seals.Seal1.inspection = "";
      }
    },
    conditionSeal2: function () {
      if (this.conditionSeal2 !== "" && this.FormInspection.seals.Seal2.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal2.inspection = this.FormInspection.seals.Seal2.seal;
      }else{
        this.FormInspection.seals.Seal2.inspection = "";
      }
    },
    conditionSeal3: function () {
      if (this.conditionSeal3 !== "" && this.FormInspection.seals.Seal3.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal3.inspection = this.FormInspection.seals.Seal3.seal;
      }else{
        this.FormInspection.seals.Seal3.inspection = "";
      }
    },
    conditionSeal4: function () {
      if (this.conditionSeal4 !== "" && this.FormInspection.seals.Seal4.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal4.inspection = this.FormInspection.seals.Seal4.seal;
      }else{
        this.FormInspection.seals.Seal4.inspection = "";
      }
    },
  },
  methods: {
    closeModal,
    refreshComponentInspection,
    checkCollapseNext,
    checkCollapseBack,
    submitDamage,
    refreshComponentDamage,
    refreshComponentSeal,
    functionDate,
    submitSeals,
    getMomentList,
    getDamageTypeList,
    getLocationList,
    getSealConditionList,
    toggleTimePanel,
    handleOpenChange,
    handleChange,
    validateDateRange,
    getDateRange,
    getContinerList,
    searchContainer,
  },
  computed: {
    conditionListEmpty,
    inspectionTypeOptions,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,         
    }),
  },
};
</script>
<style scope>
  .w-80 {
    width: 80% !important;
  }
</style>
