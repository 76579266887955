<template>
  <div style="width: 100%">
    <div class="card" style="width: 100%; border-color: #4d5a70 !important">
      <div
        class="card-header"
        :style="
          'background:' +
          ' #4d5a70 ' +
          '!important;width:100%;color:#FFFFFF;font-weight:bold;'
        "
      >
        <span>{{$t('label.DISCHARGE')+', '+$t('label.LOAD')+' '+$t('label.and')+' '+$t('label.Restow')}}</span>
      </div>
      <div class="card-body">
        <CRow>
          <CCol sm="12">
            <div class="card-summary">
              <div class="container-summary">
                <div class="summary-total">
                  <div>TOTAL</div>
                  <div class="float-right">
                    {{`${totalValue} + ${totalRestow} ${$t('label.Restow')}`}}
                  </div>
                </div>
                <div class="summary-container-subtitle">
                  <div class="subtitle bg-summary-descarga">
                    <div class="mr-2">
                      <CIcon name="cil-arrow-bottom"/>
                    </div>
                    <div>{{$t('label.DISCHARGE')}}</div>
                    <div class="float-right">
                      {{totales.dischargeFull+totales.dischargeEmpty}}
                    </div>
                  </div>
                  <div class="subtitle bg-summary-embarque">
                    <div class="mr-2">
                      <CIcon name="cil-arrow-top"/>
                    </div>
                    <div>{{$t('label.LOAD')}}</div>
                    <div class="float-right">
                      {{totales.loadingFull+totales.loadingEmpty}}
                    </div>
                  </div>
                  <div class="subtitle bg-summary-restow">
                    <div class="mr-2">
                      <CIcon name="cil-transfer"/>
                    </div>
                    <div>{{$t('label.Restow')}}</div>
                    <div class="float-right">
                      {{totales.restowShipShip+totales.restowShipLandShip}}
                    </div>
                  </div>
                </div>
                <div class="summary-table-container position-relative">
                  <table class="summary-number-table" v-if="Object.keys(DischargeLoading).length !== 0">
                    <thead>
                      <tr>
                        <th style="border: none;">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="bg-number-20">20'</td>
                      </tr>
                      <tr>
                        <td class="bg-number-40">40'</td>
                      </tr>
                      <tr>
                        <td class="bg-number-45">45'</td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th class="text-color-full">{{$t('label.full')}}</th>
                        <th class="text-color-empty">{{$t('label.empty')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in getSummaryDischarge"
                        :key="index+'discharge'"
                      >
                        <td>{{item.Full}}</td>
                        <td>{{item.Empty}}</td>
                      </tr>
                      <tr class="bg-summary-totales">
                        <td>{{totales.dischargeFull}}</td>
                        <td>{{totales.dischargeEmpty}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th class="text-color-full">{{$t('label.full')}}</th>
                        <th class="text-color-empty">{{$t('label.empty')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in getSummaryLoading"
                        :key="index+'loading'"
                      >
                        <td>{{item.Full}}</td>
                        <td>{{item.Empty}}</td>
                      </tr>
                      <tr class="bg-summary-totales">
                        <td>{{totales.loadingFull}}</td>
                        <td>{{totales.loadingEmpty}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th class="text-color-shipship">{{$t('label.ship_ship')}}</th>
                        <th class="text-color-shiplandship">{{$t('label.ship_land_ship')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in getSummaryRestow"
                        :key="index+'loading'"
                      >
                        <td>{{item.ShipShip}}</td>
                        <td>{{item.ShipLandShip}}</td>
                      </tr>
                      <tr class="bg-summary-totales">
                        <td>{{totales.restowShipShip}}</td>
                        <td>{{totales.restowShipLandShip}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

//data
function data() {
  return {
    totales: {
      dischargeFull: 0,
      dischargeEmpty: 0,
      loadingFull: 0,
      loadingEmpty: 0,
      restowShipShip:0,
      restowShipLandShip:0,
    },
    totalValue: 0,
    totalRestow: 0,
  };
}
//methods

//computed
function bulkCarrier() {
  return this.Vessel.TpVesselId.toUpperCase() === process.env.VUE_APP_BULK_VESSEL_ID ? 2 : 1;
}
function getSummaryDischarge() {
    return this.DischargeLoading?.DischargeJson ? this.DischargeLoading.DischargeJson : [];
}
function getSummaryLoading() {
    return this.DischargeLoading?.LoadingJson ? this.DischargeLoading.LoadingJson : [];
}
function getSummaryRestow() {
    return this.DischargeLoading?.RestowJson ? this.DischargeLoading.RestowJson : [];
}

export default {
  name: "OperationSummary",
  data,
  components: {},
  methods: {},
  props: {
    Vessel: {
      type: Object,
      default: function () {
        return {};
      },
    },
    DischargeLoading:{
      type: Object,
      default: function () {
        return {};
      },
    }
  },
  computed: {
    bulkCarrier,
    getSummaryRestow,
    getSummaryDischarge,
    getSummaryLoading,
    ...mapState({
      tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
      tabsIndex: state=> state.situacionOperativaActual.tabIndex,
    })
  },
  watch: {
    tabsOperationes(newValue, oldValue){
      if(newValue === 0&&this.bulkCarrier==1){
        let disFull = 0, disEmpty = 0, loadFull = 0, loadEmpty = 0, restowShipShip = 0,restowShipLandShip=0;
        let discharge = this.getSummaryDischarge;
        let loading = this.getSummaryLoading;
        let restow = this.getSummaryRestow;
        for (let index = 0; index < discharge.length; index++) {
          disFull += discharge[index].Full;
          disEmpty += discharge[index].Empty;
        }
        for (let index = 0; index < loading.length; index++) {
          loadFull += loading[index].Full;
          loadEmpty += loading[index].Empty;
        }
        for (let index = 0; index < restow.length; index++) {
          restowShipShip += restow[index].ShipShip;
          restowShipLandShip += restow[index].ShipLandShip;
        }
        this.totales.dischargeFull = disFull;
        this.totales.dischargeEmpty = disEmpty;
        this.totales.loadingFull = loadFull;
        this.totales.loadingEmpty = loadEmpty;
        this.totales.restowShipShip = restowShipShip;
        this.totales.restowShipLandShip = restowShipLandShip;
        this.totalValue = disFull + disEmpty + loadFull + loadEmpty;
        this.totalRestow = restowShipShip + restowShipLandShip;
      }else if (oldValue === 0&&this.bulkCarrier==1) {
        this.totales.dischargeFull = 0;
        this.totales.dischargeFull = 0;
        this.totales.dischargeEmpty = 0;
        this.totales.loadingFull = 0;
        this.totales.loadingEmpty = 0;
        this.totales.restowShipShip = 0;
        this.totales.restowShipLandShip = 0;
        this.totalValue = 0;
        this.totalRestow = 0;
      }
    },
    DischargeLoading: function (val) {
      if (Object.keys(val).length !== 0) {
        let disFull = 0, disEmpty = 0, loadFull = 0, loadEmpty = 0, restowShipShip = 0,restowShipLandShip=0;
        let discharge = val?.DischargeJson ? val.DischargeJson : [];
        let loading = val?.LoadingJson ? val.LoadingJson : [];
        let restow = val?.RestowJson ? val.RestowJson : [];
        for (let index = 0; index < discharge.length; index++) {
          disFull += discharge[index].Full;
          disEmpty += discharge[index].Empty;
        }
        for (let index = 0; index < loading.length; index++) {
          loadFull += loading[index].Full;
          loadEmpty += loading[index].Empty;
        }
        for (let index = 0; index < restow.length; index++) {
          restowShipShip += restow[index].ShipShip;
          restowShipLandShip += restow[index].ShipLandShip;
        }
        this.totales.dischargeFull = disFull;
        this.totales.dischargeEmpty = disEmpty;
        this.totales.loadingFull = loadFull;
        this.totales.loadingEmpty = loadEmpty;
        this.totales.restowShipShip = restowShipShip;
        this.totales.restowShipLandShip = restowShipLandShip;
        this.totalValue = disFull + disEmpty + loadFull + loadEmpty;
        this.totalRestow = restowShipShip + restowShipLandShip;
      }else{
        this.totales.dischargeFull = 0;
        this.totales.dischargeFull = 0;
        this.totales.dischargeEmpty = 0;
        this.totales.loadingFull = 0;
        this.totales.loadingEmpty = 0;
        this.totales.restowShipShip = 0;
        this.totales.restowShipLandShip = 0;
        this.totalValue = 0;
        this.totalRestow = 0;
      }
    }
  },
};
</script>

<style scoped>
.card-summary {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.container-summary {
  width: 85%;
  margin-bottom: 5px;
}
.summary-total {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: #8f99a5;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
}
.float-right {
  margin-left: auto;
}

.summary-container-subtitle,
.summary-table-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.summary-container-subtitle {
  margin-bottom: 15px;
}
.subtitle {
  width: calc((100% / 3) - 20px);
  display: flex;
  padding: 10px;
  background-color: #8f99a5;
  color: #fff;
  border-radius: 4px;
}
.bg-summary-descarga {
  background-color: #EC631B;
}
.bg-summary-embarque {
  background-color: #1A3760;
}
.bg-summary-totales {
  background-color: #D6D6D6;
  color: #000 !important;
}
.bg-summary-restow{
  background-color: #6CB3AF;
  color: #FFF !important;
}
.text-color-full {
  color: #cd414a;
}
.text-color-empty {
  color: #60ae98;
}

.text-color-shipship {
  color: #0B748B;
}
.text-color-shiplandship {
  color: #A0C5A9;
}

.summary-table-container > table {
  border-collapse: collapse;
  /* border-style: hidden; */
  width: calc((100% / 3) - 20px);
  min-height: 1px;
  text-align: center;
}
.summary-table-container > table,
.summary-table-container > table th,
.summary-table-container > table td {
  border: 1px solid #000;
}
.summary-table-container > table th,
.summary-table-container > table td {
  padding: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
}

table.summary-number-table {
  position:absolute;
  left: -6%;
  width: 50px !important;
}
table.summary-number-table,
table.summary-number-table td,th {
  border: none;
  color: #fff;
}
.bg-number-20 {
  background-color: #00343F;
}
.bg-number-40 {
  background-color: #FFBF67;
}
.bg-number-45 {
  background-color: #D36F4D;
}
</style>