import { required,helpers,sameAs,not, minValue, maxLength, } from "vuelidate/lib/validators";
const onlyText = helpers.regex('alpha', /^[a-zA-ZñÑ ´]+$/);
export default () => {
    return {
        formTerminalVisity: {
            OperationId: { required },
            ConditionPlanningId: { required },
            ProtectionLevelId: { required },
            CaptainName: { required,onlyText },
            PortId: { required },
            PortOriginId: { required },
            PortDestinationId: { required },
            BerthId: { required },
            TerminalId: { required },
            SspYear: { },
            SspNumber: { 
                minValue: minValue(1),
                maxLength: maxLength(5),
            },
            SspPlanedTon: { },
        },
        formTripVisity: {
            shippingAgentId: { required },
            portOperatorId:{ required },
            voyageArrival:{ required },
            voyageDeparture:{ required },
        }
    }
}
