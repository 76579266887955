<template>
    <CRow>
        <CCol sm="12">
            <CModalExtended
                color="dark"
                :show.sync="$store.state.situacionOperativaHistorico.modalInformation.show"
            >
                <template slot="header" >
                    <div class="d-flex align-content-center">
                        <div class="" style="margin-right:10px;">
                            <img v-if="!$store.state.situacionOperativaHistorico.modalInformation.iconShow" class="img-fluid rounded" height="20px" width="20px" src="/img/gruasOperativa.svg" alt="Card image cap">
                            <CIcon v-else style="color:#FFF" :name="$store.state.situacionOperativaHistorico.modalInformation.nameIcon"/>
                        </div>
                        <div class="flex-grow-1" style="color:#FFF">{{ $store.state.situacionOperativaHistorico.modalInformation.title }}</div>
                    </div>
                </template>
                <CRow>                                                      
                    <CCol sm="6" style="margin-bottom:10px;" v-for="(item,index) in $store.state.situacionOperativaHistorico.modalInformation.itemsInformation" :key="index">
                        <div class="d-flex align-content-center">
                            <div class="circuloOperativo" :style="'background:'+item.color"></div>
                            <div class="">{{item.label}}</div>
                        </div>
                    </CCol>
                </CRow>
                <div slot="footer">
                    <CButton
                        color="wipe"
                        @click="cerrarModal"
                        size="sm"
                        class="m-2"
                    >
                        <CIcon name="x"/>&nbsp;
                        {{$t('button.exit')}}
                    </CButton>
                </div>
            </CModalExtended>
            <CCard>
                <CCardHeader class="text-center bg-dark text-white" style="padding: 0.1rem;">
                    <div class="d-flex align-items-center">
                        <div>
                            <CButton
                                color="light"                            
                                @click="toggleCarrusel()"
                                size="sm"
                                shape="pill"
                                style="margin-right:10px"
                            >
                                <CIcon name="cil-media-pause" v-if="carrusel" />
                                <CIcon name="cil-media-play" v-else />
                            </CButton>
                        </div>
                        <div v-if="!isHidden">
                            <CButton
                                color="light"                            
                                @click="ocultarMenu()"
                                size="sm"
                                shape="pill"
                                v-c-tooltip="{placement:'top-end',content:$t('label.Maximize')}"
                            >
                                <CIcon name="cil-fullscreen"/>
                            </CButton>
                        </div>
                        <div v-else>
                            <CButton
                                color="light"                            
                                @click="ocultarMenu()"
                                size="sm"
                                shape="pill"
                                v-c-tooltip="{placement:'top-end',content:$t('label.Minimize')}"
                            >
                                <CIcon name="cil-fullscreen-exit"/>
                            </CButton>
                        </div>
                        <div class="nav-item d-flex align-items-center justify-content-end text-white" style="margin-right:10px;margin-left:10px;">
                            <CButton
                                color="light"                            
                                @click="cerrarSituacion()"
                                size="sm"
                                shape="pill"
                                v-c-tooltip="{placement:'top-end',content:$t('label.exit')}"
                            >
                                <CIcon name="x" />
                            </CButton>
                        </div>
                        <div class="flex-grow-1" style="font-weight: bold;">
                            <b style="font-weight: bold;">{{$t('label.OperationSituationHistorical')}}</b>
                        </div>
                        <div>
                            <!--<CButton
                                color="light"                            
                                @click="resetData()"
                                size="sm"
                                shape="pill"
                                style="margin-right:10px"
                                v-c-tooltip="{placement:'top-end',content:$t('label.reset')}"
                            >
                                <CIcon name="cil-reload"/>
                            </CButton>-->
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody class="pt-1">
                    <div class="especial_menu position-relative">
                        <CTabs variant="tabs" :active-tab="$store.state.situacionOperativaHistorico.tabsOperationes" @update:activeTab="handleTabOperations">
                            <CTab :title="$t('label.summary_of_operations')">
                                <CCard bodyWrapper style="width: 100%;">
                                    <indexSummaryChatarra 
                                        v-if="FgBulk"
                                        :SummaryChatarraBackup="SummaryChatarraBackup"
                                        @backup="ServiceBackup"
                                    />
                                    <OperationSummary 
                                        v-else-if="FgContainerShip"
                                        :DischargeLoading="DischargeLoading"
                                    />
                                    <OperationsSummaryGeneralCargo 
                                        v-else-if="FgGeneralCargo"
                                        :DischargeLoading="DischargeLoading"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.OperationSituationOptions.OperationalSituation')" v-if="FgContainerShip || FgGeneralCargo" >
                                <CCard bodyWrapper style="width: 100%;">
                                    <OperationalSituation
                                        :VesselInfo="VesselInfo"
                                        :OperSituationToday="OperSituationToday"
                                    />
                                </CCard>
                            </CTab>
                            <CTab title="BL'S" v-if="FgGeneralCargo">
                                <CCard bodyWrapper style="width: 100%;">
                                    <Bls
                                        :VesselInfo="VesselInfo"
                                        :OperSituationToday="OperSituationToday"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.hold')+'S'" v-if="FgBulk" >
                                <CCard bodyWrapper style="width: 100%;">
                                    <indexBodega
                                        :SummaryChatarraBackup="SummaryChatarraBackup"
                                        @backup="ServiceBackup"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.OperationSituationOptions.InactivityTime')">
                                <CCard bodyWrapper style="width: 100%;">
                                    <InactivityTime 
                                        :IdleTime="IdleTime"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.client')+' BL'" v-if="FgBulk">
                                <CCard bodyWrapper style="width: 100%;">
                                    <indexClienteBl></indexClienteBl>
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.crane')+'S'">
                                <CCard bodyWrapper style="width: 100%;">
                                    <HistoryVesselsServed 
                                        :ServicedVesselHist="ServicedVesselHist"
                                        :VesselSituationJson="VesselSituationJson"
                                        :HoldSituationJson="HoldSituationJson"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.transport')+'S'" v-if="FgBulk" >
                                <CCard bodyWrapper style="width: 100%;">
                                    <indexTransporte></indexTransporte>
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.activities')">
                                <CCard bodyWrapper style="width: 100%;">
                                    <Productivity
                                        :ProductivityJson="ProductivityJson"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.OperationSituationOptions.ProductivityTime')"  >
                                <CCard bodyWrapper style="width: 100%;">
                                    <ProductivityForTime
                                        :ProductivityByTurnJson="ProductivityByTurnJson"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.Lines')" v-if="FgContainerShip || FgGeneralCargo" >
                                <CCard bodyWrapper style="width: 100%;">
                                    <Lines
                                        :LinesJson="LinesJson"
                                    />
                                </CCard>
                            </CTab>
                            <CTab :title="$t('label.resume')" v-if="FgBulk" >
                                <CCard bodyWrapper style="width: 100%;">
                                    <Resumen/>
                                </CCard>
                            </CTab>
                        </CTabs>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from 'vuex';
    import OperationSummary from './tabs/OperationSummary/Summary';
    import indexSummaryChatarra from './tabs/OperationSummaryChatarra/indexSummary';
    import OperationalSituation from './tabs/OperationalSituation/OperationalSituation';
    import InactivityTime from './tabs/InactivityTime/InactivityTime';
    import HistoryVesselsServed from './tabs/HistoryVesselsServed/HistoryVesselsServed';
    import Lines from './tabs/Lines/Lines';
    import Productivity from './tabs/Productivity/Productivity';
    import ProductivityForTime from './tabs/ProductivityForTime/ProductivityForTime';
    import indexBodega from './tabs/Bodega/indexBodega';
    import indexTransporte from './tabs/Transporte/indexTransporte';
    import indexClienteBl from './tabs/ClienteBL/indexClienteBl';
    import OperationsSummaryGeneralCargo from './tabs/OperationSummary/operations-summary-general-cargo';
    import Bls from './tabs/Bls/bls';
    import TransversalViewMixin from "@/_mixins/transversal-view";
    import Resumen from './tabs/Resumen/index';

    //data
    function data() {
        return {
            interval: null,
            resetInterval: null,
            vesselBoard: [],
            SummaryChatarraBackup: [],
            ProductivityByTurnJson: [],
            DischargeLoading: {},
            VesselInfo: {},
            OperSituationToday: {},
            IdleTime: {},
            ServicedVesselHist: {},
            ProductivityJson: {},
            LinesJson: {},
            VesselSituationJson: {},
            HoldSituationJson: {},
        }
    }
    //methods
    function cerrarSituacion(){
        this.$store.state.situacionOperativaHistorico.collapseHistorico = false;
        this.$store.state.situacionOperativaHistorico.tabsOperationes = 0;
        this.$store.state.situacionOperativaHistorico.carrusel = true;
        this.$store.state.situacionOperativaHistorico.carruselTab = 1;
    }
    function handleTabOperations(tab){
        this.$store.state.situacionOperativaHistorico.tabsOperationes = tab;
    }
    function toggleCarrusel() {
        this.$store.state.situacionOperativaHistorico.carrusel = !this.$store.state.situacionOperativaHistorico.carrusel;
    }
    function ocultarMenu(){
        this.$store.state.planificacionestiba.isHidden = !this.$store.state.planificacionestiba.isHidden;
    }
    function cerrarModal(){
        this.$store.state.situacionOperativaHistorico.modalInformation = {
            title:'',
            show:false,
            iconShow: false,
            nameIcon: "",
            itemsInformation:[]
        }
    }
    function ServiceBackup(event) {
        this.SummaryChatarraBackup = event;
    }
    //computed
    export default {
        name:"situacionOperativaHistorico",
        data,
        methods:{
            ServiceBackup,
            cerrarSituacion,
            ocultarMenu,
            toggleCarrusel,
            handleTabOperations,
            cerrarModal,
            resetData(){
                this.$nextTick(async () => {
                    this.$store.state.situacionOperativaHistorico.tabsOperationes = 0;
                    this.$store.dispatch('situacionOperativaHistorico/getOperationalSituationReportlist',{ VisitId: this.VisitId });
                })
            },
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if (this.carrusel) {
                        let max = this.FgContainerShip ? 6 : 8;
                        this.$store.state.situacionOperativaHistorico.carruselTab = 1;
                        if(this.$store.state.situacionOperativaHistorico.tabsOperationes < max){
                            this.$store.state.situacionOperativaHistorico.tabsOperationes = this.$store.state.situacionOperativaHistorico.tabsOperationes + 1;
                        }else{
                            this.$store.state.situacionOperativaHistorico.tabsOperationes = 0;
                        }
                    }
                }, 240000);
            },
        },
        components:{
            OperationSummary,
            OperationalSituation,
            InactivityTime,
            HistoryVesselsServed,
            Lines,
            Productivity,
            ProductivityForTime,
            indexSummaryChatarra,
            indexBodega,
            indexTransporte,
            indexClienteBl,
            Resumen,
            OperationsSummaryGeneralCargo,
            Bls,
        },
        mixins: [TransversalViewMixin],
        mounted(){
            this.$nextTick(async () => {
                if(this.collapseHistorico){
                    if(this.FgContainerShip){
                        this.$store.state.situacionOperativaHistorico.Loading = true;
                        let requests = [
                            this.$http.ejecutar('GET', 'VisitOperationalSituationContainerShip', {VisitId: this.VisitId}),
                            this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: this.VesselId}),
                        ]
                        Promise.all(requests)
                            .then((responses) => {
                                let List = responses[0].data.data;
                                this.DischargeLoading = List[0]?.ContainerResultJson[0]?.DischargeLoadingJson[0] ?
                                    List[0].ContainerResultJson[0].DischargeLoadingJson[0] : {};
                                this.VesselInfo = List[0]?.ContainerResultJson[0]?.VesselInfoJson[0] ? List[0].ContainerResultJson[0].VesselInfoJson[0] : {};
                                this.OperSituationToday = List[0]?.ContainerResultJson[0]?.OperSituationTodayJson[0] ?
                                    List[0].ContainerResultJson[0].OperSituationTodayJson[0] : {};
                                this.IdleTime = List[0]?.ContainerResultJson[0]?.IdleTimeJson[0] ? List[0].ContainerResultJson[0].IdleTimeJson[0] : {};
                                this.ServicedVesselHist = List[0]?.ContainerResultJson[0]?.ServicedVesselHistJson[0] ?
                                    List[0].ContainerResultJson[0].ServicedVesselHistJson[0] : {};
                                this.ProductivityJson = List[0]?.ContainerResultJson[0]?.ProductivityJson[0] ?
                                    List[0].ContainerResultJson[0].ProductivityJson[0] : {};
                                this.ProductivityByTurnJson = List[0]?.ContainerResultJson[0]?.ProductivityByTurnJson ?
                                    List[0].ContainerResultJson[0].ProductivityByTurnJson : [];
                                this.LinesJson = List[0]?.ContainerResultJson[0]?.LinesJson[0] ?
                                    List[0].ContainerResultJson[0].LinesJson[0] : {};
                                this.baysJson = responses[1].data.data || [];
                            })
                            .catch((err) => {
                                this.$notify({
                                    group: "container",
                                    title: "¡Error!",
                                    text: err,
                                    type: "error",
                                });
                            }).finally(()=>{
                                this.$store.state.situacionOperativaHistorico.Loading = false;
                            });
                    }else if(this.FgGeneralCargo){
                        this.$store.state.situacionOperativaHistorico.Loading = true;
                        let requests = [
                            this.$http.ejecutar('GET', 'VisitOperationalSituationGeneralCargo', {VisitId: this.VisitId}),
                        ]
                        Promise.all(requests)
                            .then((responses) => {
                                let Vessel = responses[0]?.data?.data[0] ?? {};
                                this.DischargeLoading = Vessel?.GeneralCargoResultJson[0]?.DischargeLoadingJson[0] ?
                                    Vessel.GeneralCargoResultJson[0].DischargeLoadingJson[0] : {};
                                this.VesselInfo = Vessel?.GeneralCargoResultJson[0]?.VesselInfoJson[0] ? 
                                    {
                                        ...Vessel.GeneralCargoResultJson[0].VesselInfoJson[0],
                                        TpVesselId: Vessel.TpVesselId,
                                        TpVesselMasterId: Vessel.TpVesselMasterId,
                                    } : {};
                                this.OperSituationToday = Vessel?.GeneralCargoResultJson[0]?.OperSituationTodayJson[0] ?
                                    Vessel.GeneralCargoResultJson[0].OperSituationTodayJson[0] : {};
                                this.IdleTime = Vessel?.GeneralCargoResultJson[0]?.IdleTimeJson[0] ? Vessel.GeneralCargoResultJson[0].IdleTimeJson[0] : {};
                                this.ServicedVesselHist = Vessel?.GeneralCargoResultJson[0]?.ServicedVesselHistJson[0] ?
                                    Vessel.GeneralCargoResultJson[0].ServicedVesselHistJson[0] : {};
                                this.ProductivityJson = Vessel?.GeneralCargoResultJson[0]?.ProductivityJson[0] ?
                                    Vessel.GeneralCargoResultJson[0].ProductivityJson[0] : {};
                                this.ProductivityByTurnJson = Vessel?.GeneralCargoResultJson[0]?.ProductivityByTurnJson ?
                                    Vessel.GeneralCargoResultJson[0].ProductivityByTurnJson : [];
                                this.VesselSituationJson = Vessel?.GeneralCargoResultJson[0]?.VesselSituationJson[0] ?? {};
                                this.HoldSituationJson = Vessel?.GeneralCargoResultJson[0]?.HoldSituationJson[0] ?? {};
                                this.LinesJson = Vessel?.GeneralCargoResultJson[0]?.LinesJson[0] ?
                                    Vessel.GeneralCargoResultJson[0].LinesJson[0] : {};
                            })
                            .catch((err) => {
                                this.$notify({
                                    group: "container",
                                    title: "¡Error!",
                                    text: err,
                                    type: "error",
                                });
                            }).finally(()=>{
                                this.$store.state.situacionOperativaHistorico.Loading = false;
                            });
                    }else{
                        this._setInterval();
                    }
                }
            });
        },
        beforeDestroy(){
            clearInterval(this.interval);
            clearInterval(this.resetInterval);
        },
        computed:{
            ...mapState({
                collapseHistorico : state=> state.situacionOperativaHistorico.collapseHistorico,
                VisitId: state => state.situacionOperativaHistorico.VisitId,
                carrusel: state => state.situacionOperativaHistorico.carrusel,
                isHidden: state => state.planificacionestiba.isHidden,
                FgGeneralCargo: state => state.situacionOperativaHistorico.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaHistorico.FgContainerShip,
                FgBulk: state => state.situacionOperativaHistorico.FgBulk,
                VesselId: state => state.situacionOperativaHistorico.itinerarySelected.VesselId,
            })
        },
        watch:{
            baysJson: {
                handler(val, oldVal) {
                    this.$store.state.situacionOperativaHistorico.transversalView.toDeck = [...this.formatedToDeck];
                    this.$store.state.situacionOperativaHistorico.transversalView.underDeck = [...this.formatedUnderDeck];
                },
                deep: true
            },
            carrusel(newValue,OldValue){
                if(newValue){
                    this.$nextTick(async () => {
                        this._setInterval();
                    });
                }else{
                    clearInterval(this.interval);
                }
            }
        }
    }
</script>

<style lang="scss" >
    @import 'css/style.scss';
</style>