import { required, maxLength, helpers } from "vuelidate/lib/validators";

/*const numberId = (value) => {
    if(value){
        return /(^[V,P,E]{1})(\d{6,8})$/.test(value) ||  /(^[0-9]{6,8})$/.test(value)
    }
    return true
};*/

const numberId = (value) => /(^[V,P,E]{1})(\d{6,8})$/.test(value);
const onlyMayusText = (value) => /^[A-ZÑ \s]+$/.test(value);

export default () => {
    return {
        StowagePlanningResourceId: { required },
        StowageRoleId: { required },
        NumberId: { required,numberId },
        Name: { required, onlyMayusText, maxLength: maxLength(250) },
        LastName: { required, onlyMayusText, maxLength: maxLength(250) },
    }
}

