<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" /> 
        <CRow>
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mb-3 mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.transactions') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="danger" 
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnPdf()"
                >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-auto center-field" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getTransactionsList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
        <CRow >    
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="5" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>  
        </CRow>
        <CRow class="mt-3">          
            <CCol sm="11" lg="12" xl="12" class="  mb-2">
                <div class="flex-grow-1 bd-highlight">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    //data
    function data() {
        return {
            SizeChanged:50,
            textSearch:'',
            gridOptions: null,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            VisitId: '',
            rows: [],
            loadingOverlay: false,
            previousDate: new Date(),
            laterDate: new Date(),
            nulo: '', 
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
        }
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.transactionReport'));
            
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.transactionReport'),valor, this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }

    async function getTransactionsList(){
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);
        
        let TransactionJson = {
            VisitId: this.Visit,
            DateStart:dateStart,
            DateEnd:dateEnd,
            VisitCraneId:this.VisitCraneId,
        }

        this.$http.ejecutar('POST', 'VisitContainerTransactionReport', TransactionJson, { root: 'TransactionJson' })
        .then(response => {
            this.rows = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            this.loadingOverlay = false;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
            .then(response => {
                this.craneOptions = response.data.data;
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.VisitCraneId= '';
        this.craneName= '';
        this.vesselCraneId= '';
        await this.getDateRange();
        await this.getCraneList();
        await this.getTransactionsList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                lockVisible: true,
                lockPosition: true,
                minWidth: 70,
                //pinned: 'left',
            },
            {
                field: "BaySlot",
                headerName: "SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 160,
                //pinned: 'left',
            },
            {
                field: "ContainerCode",
                headerName: "CONTAINER CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 200,
                //pinned: 'left',
            },
            {
                field: "Size",
                headerName: "SIZE FT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "ImdgCode",
                headerName: "IMDG CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "CraneAlias",
                headerName: "CRANE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "StowageEventNameEs",
                headerName: this.$t('label.transaction'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "DeviceName",
                headerName: this.$t('label.device'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.code'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TurnName",
                headerName: this.$t('label.WorkShift'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.transactionDate'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "CompanyName",
                headerName: this.$t('label.company'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.user'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TransactionRegDate",
                headerName: this.$t('label.registerDate'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "Status",
                headerName: this.$t('label.status'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
                cellStyle: {top: '28%'},
                cellRenderer: params => {
                    let textStatus = '';
                    if(params.value == 'ACTIVO' || params.value == 'ACTIVE')
                        textStatus = `<h6 style="color:#3c4b64;">&nbsp; ${params.value}</h6>`
                    if(params.value == 'INACTIVO' || params.value == 'INACTIVE')
                        textStatus = `<h6 style="color:#e55353;">&nbsp; ${params.value}</h6>`

                    return textStatus === '' ? '' : textStatus;
                }
            },
        ]
        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            if(this.VisitCraneId !== '' || this.VisitCraneId !== null)
                this.CraneAlias = this.rows[0].CraneAlias;
            this.rows.map(function(item, index){
                computedItems.push({
                    id: index+1,
                    CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                    StowageEventNameEs: item.StowageEventNameEs? item.StowageEventNameEs : '',
                    DeviceName: item.DeviceName? item.DeviceName : '',
                    DeviceCode: item.DeviceCode? item.DeviceCode : '',
                    ContainerCode: item.ContainerCode? item.ContainerCode : '',
                    TpCargoCode: item.TpCargoCode? item.TpCargoCode : '',
                    Size: item.Size ? item.Size+item.TpCargoCode : '',
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    TurnName: item.TurnName ? item.TurnName : '',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                    TransactionRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                    CompanyName: item.CompanyName ? item.CompanyName : '',
                    Status: item.Status ? item.Status : '',
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }

    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }
    export default {
        name:'index-transacciones',
        props:['loading','tabIndexGruas'],
        data,
        beforeMount,
        methods:{
            onBtnPdf,
            onBtnExport,
            onGridReady,
            getTransactionsList,
            validateDateRange,
            getCraneList,
            filterCrane,
            refreshComponent,
            getDateRange
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                user: (state) => state.auth.user,
                itinerarySelected: state => state.visitas.itinerarySelected,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                dropItemReport: state => state.visitas.dropItemReport,
            }),
            craneListFormatted,
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        watch:{
            textSearch,
            SizeChanged,    
            dropItemReport: function (newValue) {
                if ((newValue==6) && (this.Visit!='')) {
                    this.SizeChanged = 50;
                    this.VisitId = this.Visit;
                    this.refreshComponent();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        },
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>