<template>
  <div style="width: 100%">
    <delay-list-modal :modal.sync="infoModal" :delay="delayInfo" />
    <div class="d-flex align-content-center position-relative">
      <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
        <a href="#" @click.prevent="disminuirCarrusel" class="arrow left"></a>
      </div>
      <div class="flex-grow-1">
        <CCollapse :show="carruselTab === 1" class="p-arrows-carousel">
          <CRow style="width: 100%;">
            <!-- Left card -->
            <CCol :sm="`${getCraneMovsXHrs.length <= 3 ? 12 : 12}`" style="width: 100%;min-height: 100%;">
                <div class="card" style="width: 100%;min-height: 100%;background: rgb(226, 226, 226)">
                <div class="card-header" :style="
                  'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                ">
                  <span>{{ $t("label.InactivityTimeOptions.MovementsPerHour") }}</span>
                </div>
                <CRow>
                  <div class="card-body pb-0 pt-1" :style="{ width: `${getCraneMovsXHrs.length <= 3 ? '40%' : '100%'}`,
                    'padding-right': `${getCraneMovsXHrs.length <= 3 ? '0.25rem' : '1.25rem'}`}">
                    <div
                      class="delay-container"
                      style="width: 100%;"
                      :style="{
                        flexDirection: `${getCraneMovsXHrs.length <= 3 ? 'column' : 'row'}`
                      }"
                    >
                      <div
                        class="delay-times"
                        :style="{
                          width: `${getCraneMovsXHrs.length <= 3 ? '100%' : '70%'}`
                        }"
                      >
                        <div class="delay-wrapper position-relative" v-for="delay in getTpDelaysTotal"
                          :key="delay.TpDelayId"
                          :style="{
                            width: `calc( calc(100% / ${getCraneMovsXHrs.length <= 3 ? 2 : 4}) - 10px )`
                          }"
                        >
                          <div class="info-delay-icon" @click="openInfoModal(delay)">
                            <CIcon name="cil-info-circle" />
                          </div>
                          <h5 class="mb-0">{{ delay.label }}</h5>
                          <div class="delay-row">
                            <div class="icon-delay-box">
                              <c-img-extended :src="delay.Icon"
                              :error-options="{ width: 28, height: 19, text: 'N/A', fontSize: 10, dy: 3 }"
                              class="icon-delay" />
                            </div>
                            <div class="delay-days ml-1">
                              <div class="delay-days" style="box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2)">
                                <h6 class="mb-0">
                                  {{ `${delay.TpDelayDays} ${$t('label.Day')}${delay.TpDelayDays == 1 ? '' : 'S'}` }}
                                </h6>
                                <h6>{{ `${delay.TpDelayHours} HRS` }}</h6>
                              </div>
                              <h6>{{ `${delay.TpDelayTotalHours} HRS` }}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="Object.keys(TotalDelaysHours).length !== 0"
                        class="delay-totals p-1"
                        :style="{
                          width: `${getCraneMovsXHrs.length <= 3 ? '100%' : '30%'}`
                        }"
                      >
                        <div style="padding: 5px 15px;background-color: #ffffff;border-radius: 10px;">
                          <CRow>
                            <CCol sm="6">
                              <label class="text-center mb-0 col-sm-12 px-0" style="font-weight: bold; font-size: 0.95rem;">TOTAL {{ $t('label.delays') }}</label>
                              <div class="delay-day-time-totals">
                                <div style='box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2); width: 100%;'>
                                  <h6 class="text-center mb-0" style="font-weight: bold;">{{ getTotalDelaysHrs.days }} {{ $t('label.Day') }}</h6>
                                  <h6 class="text-center mb-0" style="font-weight: bold;">{{ getTotalDelaysHrs.hrs }} HRS</h6>
                                </div>
                                <h6 class="text-center mt-2" style="font-weight: bold;">{{ getTotalDelaysHrs.Totalhrs }} HRS</h6>
                              </div>
                            </CCol>
                            <CCol sm="6">
                              <label class="text-center mb-0 col-sm-12 px-0" style="font-weight: bold; font-size: 0.95rem;">TOTAL {{ $t('label.net') }}</label>
                              <div class="delay-day-time-totals">
                                <div style='box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2); width: 100%;'>
                                  <h6 class="text-center mb-0" style="font-weight: bold;">{{ getTotalDelaysHrs.Netdays }} {{ $t('label.Day') }}</h6>
                                  <h6 class="text-center mb-0" style="font-weight: bold;">{{ getTotalDelaysHrs.Nethrs }} HRS</h6>
                                </div>
                                <h6 class="text-center mt-2" style="font-weight: bold;">{{ getTotalDelaysHrs.TotalNethrs }} HRS</h6>
                              </div>
                            </CCol>
                          </CRow>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body pb-0 pt-1" :style="{ width: `${getCraneMovsXHrs.length <= 3 ? '60%' : '100%'}`, 
                    'padding-top': `${getCraneMovsXHrs.length <= 3 ? '1.25rem' : '0'}`,
                    'padding-left': `${getCraneMovsXHrs.length <= 3 ? '0' : '1.25rem'}`}">
                    <div style="width: 100%;">
                      <div class="crane-movs px-0 pt-0" :style="{ 'overflow-x': `${getCraneMovsXHrs.length > 5 ? 'scroll' : ''}`,
                        height: `${getCraneMovsXHrs.length <= 3 ? '381px' : 'auto'}`}">
                        <div class="crane" v-for="crane in getCraneMovsXHrs" :key="crane.VisitCraneId" :style="{
                          width: `calc( calc(100% / ${getCraneMovsXHrs.length <= 3 ? 3 : getCraneMovsXHrs.length}) - 10px )`
                        , 'font-size': `${crane.CraneAlias == 'TOTAL'? '0.7rem' : '0.85rem'}`}">
                          <div class="crane-title" :style="
                            'background:' + crane.Color + '!important;'
                          ">
                            {{ crane.CraneAlias }}
                          </div>
                          <div class="mb-3 ml-2 mt-3">
                            <p class="m-0">{{ $t('label.dateStartOperations') }}</p>
                            <p class="m-0" style="font-weight: bold;">{{ formatDate(crane.BeginOperationsDate) }}</p>
                          </div>
                          <div class="mb-3 ml-2">
                            <p class="m-0">{{ $t('label.dateEndOperations') }}</p>
                            <p class="m-0" style="font-weight: bold;">{{ formatDate(crane.EndOperationsDate) }}</p>
                          </div>
                          <div class="ml-2">
                            <p class="m-0">HRS {{ $t('label.crane') }}: {{ crane.TotalHours }}</p>
                            <p class="m-0">{{ $t('label.HoursDelays') }}: {{ crane.TotalDelays }}</p>
                            <p v-if="crane.CraneAlias == 'TOTAL'" class="m-0">{{ $t('label.NetDelayHours') }}: 
                              {{ crane.TotalNetDelays }}</p>
                            <p class="m-0">MOVS: {{ crane.TotalMovs }}</p>
                            <p class="m-0">{{ $t("label.NetHrs") }}: {{ crane.TotalNetHours }}</p>
                            <p v-if="crane.CraneAlias == 'TOTAL'" class="m-0">{{ $t('label.EffectiveHours') }}: 
                              {{ crane.TotalEffectiveHours }}</p>
                          </div>
                          <div class="movs-op">
                            <h5>MOVS X HRS: <strong
                                :style="'color:' + crane.Color + '!important;'">{{ crane.MovsXHours }}</strong></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </CCollapse>
        <CCollapse :show="carruselTab === 2" class="p-arrows-carousel">
          <div style="border-color: #4d5a70 !important;">
            <CRow class="cardPersonalizado">
              <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div class="cardHeaderPersonalizado" :style="
                  'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                ">
                  <span>
                    {{ $t("label.InactivityTimeOptions.InactivityTimePerCrane") }}
                  </span>
                </div>
                <div style="padding: 1.25rem;" v-if="sinInformacion(cranesDelaySeries)">
                  <stackedColumnsNew width="100%" height="60vh" :bandera="bandera" :series="cranesDelaySeries"
                    :legendBandera="true" positionLegend="right" :labels="MovsDelayLabels" :yaxis="cranesDelaysyaxis"
                    :displayDataLabels="false"></stackedColumnsNew>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="padding: 1.25rem;height:60vh;"
                  v-else>
                  <h6 style="color:#F67272;text-align:center;">{{ $t('label.noDataAvailable') }}</h6>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCollapse>
        <CCollapse :show="carruselTab === 3" class="p-arrows-carousel">
          <div style="border-color: #4d5a70 !important;">
            <CRow class="cardPersonalizado">
              <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div class="cardHeaderPersonalizado" :style="
                  'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                ">
                  <span>
                    {{ $t("label.InactivityTimeOptions.InactivityTimeTheVessel") }}
                  </span>
                </div>
                <div style="padding: 1.25rem;" v-if="sinInformacion(simpleDelaySeries)">
                  <SimpleBarVertical width="100%" height="60vh" positionLegend="right" :bandera="bandera2"
                    textAlignLegend="start" typeGraffic="bar" :labels="simpleDelayLabels"
                    :delayhours="simpleDelayHourNs" :series="simpleDelaySeries" :colors="simpleDelayColors">
                  </SimpleBarVertical>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="padding: 1.25rem;height:60vh;"
                  v-else>
                  <h6 style="color:#F67272;text-align:center;">{{ $t('label.noDataAvailable') }}</h6>
                </div>
                <div class="px-1">
                  <table class="col-sm-12">
                    <tr v-for="(Row) in simpleDelayLegend.length" :key="Row">
                      <td v-for="(legend, index) in simpleDelayLegend[Row-1].legend" :key="index" class="d-flex align.items-center">
                        <span :style="'background:'+legend.color+' !important'" class="circle mr-1"></span>{{legend.text+': '+legend.HourNs}}
                      </td>
                    </tr>
                  </table>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCollapse>
        <CCollapse :show="carruselTab === 4" class="p-arrows-carousel">
          <div class="card" style="width: 100%">
            <div class="card-header" :style="
              'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
            ">
              <span>{{ $t("label.InactivityTimeOptions.MovementsPerHoursVsNetHours") }}</span>
            </div>
            <div class="card-body">
              <CRow>
                <CCol v-if="banderaLines">
                  <lineColumn height="auto" :series="movsDelayMixedSeries" :labels="movsDelaysMixedLabels"
                    :xaxis="movsDelayMixedXaxis" :yaxis="movsDelayMixedYaxis"></lineColumn>
                </CCol>
                <CCol>
                  <div style="width: 100%">
                    <div class="crane-resume-movs">
                      <div class="crane-resume" v-for="crane in getCraneWorksHrs" :key="crane.VisitCraneId">
                        <div class="crane-resume-title" :style="{
                          backgroundColor: `${crane.Color ? crane.Color : '#1e3a61'}`
                        }">
                          {{ crane.CraneAlias }}
                        </div>
                        <div class="content-movs-resume">
                          <p class="m-0">TOTAL HRS: {{ crane.TotalHours }}</p>
                          <p class="m-0">TOTAL {{ $t('label.HoursDelays') }}: {{ crane.TotalDelays }}</p>
                          <p class="m-0">TOTAL {{ $t('label.Movements') }}: {{ crane.TotalMovs }}</p>
                          <p class="m-0">TOTAL {{ $t("label.NetHrs") }}: {{ crane.TotalNetHours }}</p>
                          <p class="m-0 mov-x-hours-label">
                            TOTAL {{ $t("label.InactivityTimeOptions.MovementsPerHour") }}:
                            <strong>{{ crane.MovsXHours }}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </div>
        </CCollapse>
      </div>
      <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
        <a href="#" @click.prevent="aumentarCarrusel" class="arrow right"></a>
      </div>
    </div>
  </div>
</template>

<script>
import simplePie from "../../components/simplePie";
import SimpleBarVertical from "../../components/SimpleBarVertical";
import stackedColumnsNew from "../../components/StackedColumnNew";
import lineColumn from "../../components/lineColumn";
import VistaTransversal from "../../components/vista-transversal";
import DelayListModal from "./delay-list-modal";
import { DateFormater } from '@/_helpers/funciones';
import CImgExtended from '@/components/CImgExtended';
import { mapState } from 'vuex';

//data
function data() {
  return {
    interval: null,
    infoModal: false,
    delayInfo: null,
    CranesDelays: [],
    DelaysTotals: [],
    CranesMovsXHours: [],
    TpDelaysTotalHours: [],
    WorkHoursByCrane: [],
    TotalDelaysHours: [],
  }
}
// method
function getTabIdleTime() {
  this.$store.state.situacionOperativaActual.Loading = true;
  this.$http.ejecutar('GET', 'VisitOperationalSituationTabIdleTime', {VisitId: this.Vessel.VisitId})
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.CranesDelays = List[0]?.IdleTimeJson[0]?.CranesDelaysJson ? List[0]?.IdleTimeJson[0]?.CranesDelaysJson : [];
        this.DelaysTotals = List[0]?.IdleTimeJson[0]?.DelaysTotalsJson ? List[0]?.IdleTimeJson[0]?.DelaysTotalsJson : [];
        this.CranesMovsXHours = List[0]?.IdleTimeJson[0]?.CranesMovsXHoursJson ? List[0]?.IdleTimeJson[0]?.CranesMovsXHoursJson : [];
        this.TpDelaysTotalHours = List[0]?.IdleTimeJson[0]?.TpDelaysTotalHoursJson ? List[0]?.IdleTimeJson[0]?.TpDelaysTotalHoursJson : [];
        this.WorkHoursByCrane = List[0]?.IdleTimeJson[0]?.WorkHoursByCraneJson ? List[0]?.IdleTimeJson[0]?.WorkHoursByCraneJson : [];
        this.TotalDelaysHours = List[0]?.IdleTimeJson[0]?.TotalDelaysHoursJson ? List[0]?.IdleTimeJson[0]?.TotalDelaysHoursJson : [];
      }
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(()=>{
      this.$store.state.situacionOperativaActual.Loading = false;
    });
}
function disminuirCarrusel() {
  if (this.$store.state.situacionOperativaActual.carruselTab !== 1) {
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
  }
}
function aumentarCarrusel() {
  if (this.$store.state.situacionOperativaActual.carruselTab !== 4) {
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
  }
}
function bgTpDelayById(delay) {
  switch (delay.TpDelayId) {
    case "EA0CA871-010A-492F-A7C8-3CD55889BB9D":
      return "bg-line";
    case "1756B577-74C7-44E5-9214-63BD54806923":
      return "bg-terminal";
    case "49DF9416-255B-48EE-924D-AB957300325C":
      return "bg-port";
    case "942B4BED-108B-4BE1-8351-D95304728D84":
      return "bg-others";
    default:
      return "";
  }
}
function formatDate(date) {
  if (date === null) {
    return 'N/A';
  }
  return DateFormater.formatDateTimeWithSlash(date);
}
function openInfoModal(delay) {
  this.delayInfo = { ...delay };
  this.infoModal = true;
}
function sinInformacion(array) {
  let banderita = false;
  for (let index = 0; index < array.length; index++) {
    if (array[index] !== 0) {
      banderita = true;
    }
  }
  return banderita;
}
//computed 
function bandera() {
  return this.carruselTab === 2 && (this.FgGeneralCargo ? this.tabsOperationes === 3 : this.tabsOperationes === 2);
}
function bandera2() {
  return this.carruselTab === 3 && (this.FgGeneralCargo ? this.tabsOperationes === 3 : this.tabsOperationes === 2);
}
function banderaLines() {
  return this.carruselTab === 4 && (this.FgGeneralCargo ? this.tabsOperationes === 3 : this.tabsOperationes === 2);
}
function minCarrusel() {
  return this.$store.state.situacionOperativaActual.carruselTab > 1
}
function maxCarrusel() {
  return this.$store.state.situacionOperativaActual.carruselTab < 4
}
function cranesDelaySeries() {
  let data = [];
  let DataCranesDelays = this.CranesDelays;
  let theValueIs = this.$t('label.lang');
  DataCranesDelays.map(async (item, index) => {
    let labels = theValueIs == "ESPAÑOL" ? item.Label.Es : item.Label.En;
    data.push({
      label: labels,
      delayhours: item.DelayHours,
      data: item.Series,
      backgroundColor: item.Color,
    });
  });
  return data;
}
function cranesDelayColors() {
  let data = [];
  let DataCranesDelays = this.CranesDelays;
  DataCranesDelays.map(async (item) => {
    data.push(item.Color);
  });
  return data;
}
function MovsDelayLabels() {
  let data = [];
  let DataMovsDelays = this.CranesMovsXHours;
  DataMovsDelays.map(async (item) => {
    if (item.VisitCraneId != null) {
      data.push(item.CraneAlias);
    }
  });
  return data;
}
function cranesDelaysLegend() {
  return {
    show: false,
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40
  };
}
function cranesDelaysyaxis() {
  return {
    stacked: true,
    ticks: {
      callback: function (value, index, values) {
        return value + "Hrs";
      }
    }
  }
}
function simpleDelaySeries() {
  let data = [];
  let DataTpCargo = this.DelaysTotals;
  DataTpCargo.map(async (item) => {
    let variable = item.DelayHours == null ? 0 : item.DelayHours;
    data.push(variable);
  });
  return data;
}
function simpleDelayColors() {
  let data = [];
  let DataTpCargo = this.DelaysTotals;
  DataTpCargo.map(async (item) => {
    data.push(item.Color);
  });
  return data;
}
function simpleDelayLabels() {
  let data = [];
  let DataTpCargo = this.DelaysTotals;
  let theValueIs = this.$t('label.lang');

  DataTpCargo.map(async (item) => {
    let labels = theValueIs == "ESPAÑOL" ? item.DelayDsEs : item.DelayDsEn;
    data.push(labels);
  });
  return data;
}
function simpleDelayLegend() {
  let data = [];
  let Legend = [];
  let DataTpCargo = this.DelaysTotals;
  let theValueIs = this.$t('label.lang');
  let i = 0;
  DataTpCargo.map(async (item) => {
    let labels = theValueIs == "ESPAÑOL" ? item.DelayDsEs : item.DelayDsEn;
    Legend.push({
      text:labels,
      color: this.simpleDelayColors[i],
      HourNs: this.simpleDelayHourNs[i]
    });
    if (Legend.length==4||i == DataTpCargo.length-1) {
      data.push({
        legend: Legend,
      })
      Legend = [];
    }
    i++
  });
  return data;
}
function simpleDelayHourNs() {
  let data = [];
  let DataTpCargo = this.DelaysTotals;
  DataTpCargo.map(async (item) => {
    if (item.DelayHourNs) {
      data.push(item.DelayHourNs);
    }
  });
  return data;
}
function movsDelayMixedSeries() {
  let data = [{
    name: this.$t('label.hour') + 'S',
    type: 'column',
    data: [],
  }, {
    name: this.$t('label.Movements'),
    type: 'line',
    data: []
  }];
  let DataMovsDelays = this.CranesMovsXHours;
  DataMovsDelays.map(async (item) => {
    data[0].data.push(item.TotalNetMinutes);
    data[1].data.push(item.TotalMovs);
  });
  return data;
}
function movsDelayMixedXaxis() {
  return {
    type: 'category'
  }
}
function movsDelaysMixedLabels() {
  let data = [];
  let DataMovsDelays = this.CranesMovsXHours;
  DataMovsDelays.map(async (item) => {
    data.push(item.CraneAlias);
  });
  return data;
}
function movsDelayMixedYaxis() {
  return [{
    title: {
      text: this.$t('label.hour') + 'S',
    },
    labels: {
      formatter: function(val) {
        let Hrs = Math.floor(val/60);
        if(Hrs<10){
            Hrs = '0'+Hrs;
        }
        return Hrs;
      },
    }
  }, {
    opposite: true,
    title: {
      text: this.$t('label.Movements'),
    }
  }]
}
function getTpDelaysTotal() {
  return this.TpDelaysTotalHours.map((item) => {
    return Object.assign({}, item, {
      label: this.$i18n.locale == "es" ? item.TpDelayNameEs : item.TpDelayNameEn,
      Icon: `${this.$store.getters["connection/getBase"]}${item.RouteIcon ? item.RouteIcon.replace('Public/', '', 'i') : null}`,
    });
  });
}
function getCraneMovsXHrs() {
  return this.CranesMovsXHours;
}
function getToDeck() {
  return this.$store.state.situacionOperativaActual.transversalView.toDeck;
}
function getUnderDeck() {
  return this.$store.state.situacionOperativaActual.transversalView.underDeck;
}
function getCraneTotalPerHour() {
  let hrs = 0, movs = 0, delays = 0, movsXHrs = 0;
  this.getCraneMovsXHrs.forEach(element => {
    hrs += element.TotalHoursN;
    movs += element.TotalMovs;
    delays += element.TotalDelaysN;
    movsXHrs += element.MovsXHours;
  });

  return {
    hrs: this.formatTimeHHmm(hrs),
    movs: movs,
    delays: this.formatTimeHHmm(delays),
    movsXHrs: movsXHrs,
  }
}
function getCraneMaxDate() {
  let max = new Date(Math.max(...this.getCraneMovsXHrs.map(e => new Date(e.EndOperationsDate))));
  return this.formatDate(max);
}
function getCraneMinDate() {
  let min = new Date(Math.min(...this.getCraneMovsXHrs.map(e => new Date(e.BeginOperationsDate))));
  return this.formatDate(min);
}
function formatTimeHHmm(value) {
  let formatedHrs = Number.parseFloat(Number.parseFloat(value).toFixed(2));
  let onlyHrs = Math.trunc(formatedHrs);
  let hrsDececiml = Number.parseFloat(Number.parseFloat(formatedHrs - onlyHrs).toFixed(2));
  let minutos = Math.trunc(hrsDececiml * 60);

  let hrs = onlyHrs < 10 ? `0${onlyHrs}` : `${onlyHrs}`,
    min = minutos < 10 ? `0${minutos}` : `${minutos}`;
  return `${hrs}:${min}`;
}
function getCraneWorksHrs() {
  return this.WorkHoursByCrane;
}
function getTotalDelaysHrs() {
  let data = this.TotalDelaysHours;

  return {
    days: data.length == 1 ? data[0].TotalDelaysDaysDs : '0',
    hrs:  data.length == 1 ? data[0].TotalDelaysHoursDs : '00:00',
    Totalhrs:  data.length == 1 ? data[0].TotalDelaysTotalHoursDs : '00:00',
    Netdays: data.length == 1 ? data[0].TotalNetDelaysDaysDs : '0',
    Nethrs:  data.length == 1 ? data[0].TotalNetDelaysHoursDs : '00:00',
    TotalNethrs:  data.length == 1 ? data[0].TotalNetDelaysTotalHoursDs : '00:00',
  }
}

export default {
  name: "InactivityTime",
  data,
  props: {
    Vessel: {
      type: Object,
      default: function () {
        return {};
      },
    },
    IdleTime: {
      type: Object,
      default: function () {
        return {};
      },
    }
  },
  components: {
    simplePie,
    stackedColumnsNew,
    VistaTransversal,
    lineColumn,
    CImgExtended,
    DelayListModal,
    SimpleBarVertical
  },
  methods: {
    getTabIdleTime,
    bgTpDelayById,
    formatDate,
    disminuirCarrusel,
    aumentarCarrusel,
    formatTimeHHmm,
    sinInformacion,
    openInfoModal,
    _setInterval: function () {
      this.interval = setInterval(() => {
        if (this.$store.state.situacionOperativaActual.carruselTab !== 4) {
          this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        } else {
          this.$store.state.situacionOperativaActual.carruselTab = 1;
        }
      }, 60000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  computed: {
    getTotalDelaysHrs,
    cranesDelaySeries,
    cranesDelayColors,
    MovsDelayLabels,
    cranesDelaysyaxis,
    cranesDelaysLegend,
    simpleDelaySeries,
    simpleDelayColors,
    simpleDelayLabels,
    simpleDelayLegend,
    simpleDelayHourNs,
    movsDelayMixedSeries,
    movsDelayMixedXaxis,
    movsDelayMixedYaxis,
    movsDelaysMixedLabels,
    getTpDelaysTotal,
    getCraneMovsXHrs,
    getToDeck,
    getUnderDeck,
    getCraneTotalPerHour,
    getCraneMaxDate,
    getCraneMinDate,
    minCarrusel,
    maxCarrusel,
    bandera,
    bandera2,
    banderaLines,
    getCraneWorksHrs,
    ...mapState({
      FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
      FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
      FgBulk: state => state.situacionOperativaActual.FgBulk,
      carruselTab: state => state.situacionOperativaActual.carruselTab,
      carrusel: state => state.situacionOperativaActual.carrusel,
      tabsOperationes: state => state.situacionOperativaActual.tabsOperationes,
    })
  },
  watch: {
    tabsOperationes(newValue, OldValue) {
      if (this.FgGeneralCargo ? OldValue === 3 : OldValue === 2) {
        this.CranesDelays = [];
        this.DelaysTotals = [];
        this.CranesMovsXHours = [];
        this.TpDelaysTotalHours = [];
        this.WorkHoursByCrane = [];
        this.TotalDelaysHours = [];
        this.$store.state.situacionOperativaActual.carruselTab = 1;
        clearInterval(this.interval);
        this.interval = null;
      } else if (this.FgGeneralCargo ? newValue === 3 : newValue === 2) {
        if (this.FgBulk) {
          this.getTabIdleTime();
        } else if (this.FgGeneralCargo || this.FgContainerShip) {
          this.CranesDelays = this.IdleTime?.CranesDelaysJson ? this.IdleTime.CranesDelaysJson : [];
          this.DelaysTotals = this.IdleTime?.DelaysTotalsJson ? this.IdleTime.DelaysTotalsJson : [];
          this.CranesMovsXHours = this.IdleTime?.CranesMovsXHoursJson ? this.IdleTime.CranesMovsXHoursJson : [];
          this.TpDelaysTotalHours = this.IdleTime?.TpDelaysTotalHoursJson ? this.IdleTime.TpDelaysTotalHoursJson : [];
          this.WorkHoursByCrane = this.IdleTime?.WorkHoursByCraneJson ? this.IdleTime.WorkHoursByCraneJson : [];
          this.TotalDelaysHours = this.IdleTime?.TotalDelaysHoursJson ? this.IdleTime.TotalDelaysHoursJson : [];
        }
        this.$nextTick(async () => {
          if (this.carrusel) {
            this._setInterval();
          }
        });
      }
    },
    carrusel(newValue, OldValue) {
      if (this.FgGeneralCargo ? this.tabsOperationes === 3 : this.tabsOperationes === 2) {
        if (newValue) {
          this.$nextTick(async () => {
            this._setInterval();
          });
        } else {
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }
  }
};
</script>

<style scoped>
  .circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
.info-delay-icon {
  position: absolute;
  top: 3%;
  right: 4%;
  cursor: pointer;
}
</style>