<template>
    <div class="mb-3">
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars"/>
        <CRow class="mt-2">
            <CCol sm="12" class="d-flex justify-content-end py-2">
                <CButton
                color="add"
                class="d-flex align-items-center" 
                v-c-tooltip="{
                    content: $t('label.classificationMachine'),
                    placement: 'top-end'
                }"
                @click="toggleAdd"
                >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
            </CCol>
        </CRow>  
        <CRow class="mb-3">
            <CCol sm="12" >
                <dataTableExtended
     class="align-center-row-datatable"
                    :items="formatedItems"
                    :fields="fields"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    sorter
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    column-filter
                    hover
                    size="lg" 
                >
                    <template #Nro="{ item }">
                        <td class="center-cell">
                            {{ item.Nro }}
                        </td>
                    </template>
                    <template #status="{item}">
                    <td class="center-cell">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                    </template>
                    <template #actions="{item}">
                        <td class="center-cell">
                                <CButton
                                    color="edit"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.classificationMachine'),   
                                        placement: 'top-end'
                                    }" 
                                    @click="toggleEdit(item)"  
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
        <ModalClassificationMachine  
            :modal.sync="modalClassification" 
            :title="titleModal"
            :MachineClassificationItems="MachineClassificationItems"
            :isEdit="isEdit"
            @Update-list="Update"
            @close="closeModal"
        />
      
    </div>   
</template>

<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import ModalClassificationMachine from './modal-clasificacion-maquina';
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';

//data
function data() {
    return {
        items: [],
        MachineClassificationItems: {},
        activePage: 1,
        isLoading: false,
        modalClassification: false,
        isEdit: false,
        titleModal:''
    };
}

//methods
 function getMachineClassification() {
    this.isLoading = true;
    this.$http.get('TpClassificationMachine-list', {filter: 'ALL'})
        .then((response) => {
            let list = response.data.data;
            if (list.length != 0) {
                this.items = list;
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
            });
        }).then(() => {
            this.isLoading = false;
        });
}

function toggleAdd() {
    this.modalClassification = true;
    this.titleModal = this.$t('label.nueva')+' '+this.$t('label.classificationMachine');
}
function toggleEdit(item) {
    this.MachineClassificationItems = (item); 
    this.modalClassification = true;
    this.isEdit = true;
    
    let _lang = this.$i18n.locale;
    let title = _lang=='en' ? this.MachineClassificationItems.TpClassificationMachineNameEn : this.MachineClassificationItems.TpClassificationMachineNameEs;
    this.titleModal =  this.$t('label.edit')+' '+this.$t('label.classificationMachine')+': '+title;
}
function Update() {
    this.modalClassification = false;
    this.isEdit = false;
    this.getMachineClassification();
}
function closeModal(){
  this.modalClassification= false;
  this.formMachineClassification = {};
  this.titleModal = '';
  this.isEdit= false;
}
//Computed:
function formatedItems() {
    return this.items.map((item) => Object.assign({}, item, {
        Nro: item.Nro,
        FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
        _classes: item.Status != 'ACTIVO'? 'table-danger': ''
    }));
}

function fields () {
    return [
        { key: 'Nro', label: '#', filter: false,_style: 'width:1%; text-align:center;', },
        { key: 'TpClassificationMachineNameEs',label: this.$t('label.machineClassification')+' '+('(ES)'), _style:'width:25%', _classes:'text-uppercase text-center center-cell' },
        { key: 'TpClassificationMachineNameEn',label: this.$t('label.machineClassification')+' '+('(EN)'), _style:'width:25%', _classes:'text-uppercase text-center center-cell' },
        { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 15%;', _classes:'text-uppercase text-center center-cell'},
        { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%; text-align:center;', _classes:'text-center center-cell'},
        { key: 'status', label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center center-cell'},
        { key: 'actions', label: '', sorter: false, filter: false, _style: 'min-width:45px;', _classes:'text-center center-cell' }
    ]
}
function tableText() {
    return tableTextTranslatedHelpers(this);
}
export default {
    name:'app-clasificacion-maquina',
    mixins: [GeneralMixin, ModalMixin], 
    components: {ModalClassificationMachine},
    props:{Tab: Number},
    data,   
    methods:{
        getMachineClassification,
        toggleAdd,
        toggleEdit,
        Update,
        closeModal,
    },
    computed: {
        fields,
        formatedItems,
        tableText
    },
    watch: {
        Tab:function (val){
            if(val === 1){
                this.getMachineClassification();
            }
        }
    }
} 
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
</style>