<template>
    <div class="col-sm-12">
      <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
    </div>
 </template>
 <script>
function chartOptions(){
	return {
		chart: {
			type: 'bar',
			animations: {
				enabled: true,
			}
		},
		colors: this.Colors,
		xaxis: {
			categories: this.Categories,
			labels: {
				style: {
					fontSize: '10px'
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
			}
			},
		plotOptions: {
			bar: {
			distributed: this.Distributed,
			}
		},
		legend: {
			show: this.Legend
		},
		tooltip: this.Tooltip,
		dataLabels: this.DataLabels,
		yaxis: {
			axisBorder: {
				show: this.Yaxis,
			},
			axisTicks: {
				show: this.Yaxis,
			},
			labels: {
				show: this.Yaxis,
				formatter: (Value) => { return Number.parseInt(Value) },
			}
		},
		noData: {
            text: this.$t('label.noDataAvailable').toUpperCase(),
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#f77f7f",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        },
	}
}

function Hrs(val){
let Hrs = Math.floor(val/60);
if(Hrs<10){
	Hrs = '0'+Hrs;
}
let Min = val - (Hrs*60)
if(Min<10){
	Min = '0'+Min;
}
return Hrs+':'+Min;
}

function Percent(val){
if(val==0){
	return 0
}
let percent = (val/this.Total) * 100;
return Math.round(percent * 1e2) / 1e2+'%';
}

export default {
	name:'SimpleBar',
	props: {
		Series: {
			type: Array,
			require: true,
			default: () => [],
		},
		Categories: {
			type: Array,
			require: true,
			default: () => [],
		},
		Colors: {
			type: Array,
			require: false,
			default: () => [],
		},
		Tooltip: {
			type: Object ,
			required: false,
			default: function () {
				return {
					y: {
						formatter: function (val) {
							return Hrs(val)+' HRS';
						}
					}
				} 
			},
		},
		DataLabels: {
			type: Object ,
			required: false,
			default: function () {
				return {
					enabled: true,
					formatter: function (val) {
						return Hrs(val);
					},
				}
			},
		},
		Legend: {
			type: Boolean,
			default: false,
		},
		Distributed: {
			type: Boolean,
			default: () => true,
		},
		Yaxis: {
			type: Boolean,
			default: () => false,
		},
		Width:{
			type: String,
			require: true,
			default: 'auto',
		},
		Height:{
			type: String,
			require: true,
			default: 'auto',
		},
	},
	methods:{
		Hrs,
		Percent,
	},
	computed:{
		chartOptions
	},
}
</script>