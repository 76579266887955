<template>
    <div class="mb-3">
        <div>
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ListOfDispatchedContainer')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
                <CCol sm="12" lg="4">
                    <div class="form-group form-row" rol="group">
                        <label class="col-form-label col-sm-12 col-lg-2 text-right">{{$t('label.visit')}}</label>
                        <div class="col-sm-12 col-lg-9 input-group">
                            <v-select
                                class="select-adjust"
                                :placeholder="$t('label.select')"
                                v-model="VisitId"
                                :options="VisitOptions"
                                :getOptionLabel="option => option.label"
                                :reduce="option => option.value" 
                            >
                                <template #no-options="{ }">
                                    {{$t('label.noResultsFound')}}
                                </template>
                                <template #option="{ Json, label, value }">
                                    {{ label }}<br />
                                    <cite v-if="value">
                                        {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.Ata }} 
                                    </cite>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </CCol>
                <CCol sm="12" lg="7">
                    <div class="form-group form-row d-flex aling-items-center mb-0">
                        <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
                        <div class="input-group col-sm-12 col-lg-4 mb-2">
                            <vue-datepicker 
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="false"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="searchFilterDate.fromDay"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                                class="w-100"
                            >
                                <template #icon-calendar>
                                    <div style="display: none"></div>
                                </template>
                            </vue-datepicker>
                        </div>
                        <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
                        <div class="input-group col-sm-12 col-lg-4 mb-2">
                            <vue-datepicker 
                                type="datetime"
                                header
                                :lang="this.$i18n.locale"
                                :editable="true"
                                :clearable="false"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="DD/MM/YYYY HH:mm"
                                time-title-format="DD/MM/YYYY HH:mm"
                                v-model="searchFilterDate.toDay"
                                :disabled-date="validateDateRange"
                                :append-to-body="false"
                                value-type="format"
                                :show-second="false"
                                class="w-100"
                            >
                                <template #icon-calendar>
                                    <div style="display: none"></div>
                                </template>
                            </vue-datepicker>
                        </div>
                        <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
                            <CButton
                                color="watch"
                                square
                                size="sm"
                                class="mx-1"
                                @click="getYardCargoDispatch()"
                            >
                                <CIcon name="cil-search"/>
                            </CButton>
                            <CButton
                                color="danger"
                                square
                                size="sm"
                                @click="Resest()"
                            >
                                <CIcon name="cil-brush-alt"/>
                            </CButton>
                        </div>
                    </div>
                </CCol>
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items="computedListContainer"
                        :fields="fields"
                        :items-per-page="5"
                        column-filter
                        pagination
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        sorter
                    >
                    
                        <template #options="{ item }">
                            <td class="text-center">
                                <CButton
                                    square
                                    size="sm"
                                    color="watch"
                                    class="d-flex align-items-center"
                                    @click="toggleContainer(item)"
                                    v-c-tooltip="{
                                        content: $t('label.ViewContainer'),
                                        placement: 'top-start'
                                    }"
                                >
                                        <CIcon name='cil-list' />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater } from "@/_helpers/funciones";
import moment from 'moment';
  
//data
function data() {
    return {
        VisitId: '',
        VisitList: [],
        searchFilterDate: {
            fromDay: '',
            toDay: '',
        },
        previousDate: '',
        laterDate: '',
    };
}

//methods
function getYardCargoDispatch() {
    this.$store.state.yardManagement.loading = true;
    let ReportJson = {
        YardId: this.YardId,
        VisitId: this.VisitId,
        DateStart: DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay),
        DateEnd: DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay),
    }
    this.$http.post('YardCargoDispatch-by-YardId', ReportJson, { root: 'ReportJson' })
        .then(response => {
            let List = response.data.data ?? [];
            this.$store.state.yardManagement.ListDispatchedContainers = List[0]&&Object.entries(List[0]).length>1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}

async function getYardVisit() {
    await this.$http.get('YardVisit-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            this.VisitList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'yard',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

async function getYardCargo(item) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: item.YardId })
    .then(response => {
      let res = response.data.data;
      if (res && res?.length>0) {
        this.$store.state.yardManagement.dataContainer = {...res[0], YardCargoId : item.YarCargoOriginId, YarCargoDispachtId: item.YardCargoId};
        this.$store.state.yardManagement.yardCollapse = 2;
      }
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.$store.state.yardManagement.loading = false;
    })
    
}

function toggleContainer(item) {
    this.getYardCargo(item);
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getDateRange(){
    this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
    this.laterDate = new Date();
    this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
    this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

async function Resest() {
  this.$store.state.yardManagement.loading = true;
  this.$store.state.yardManagement.ListDispatchedContainers = [];
  this.VisitId = '';
  this.VisitList = [];
  await this.getDateRange();
  await this.getYardVisit();
  await this.getYardCargoDispatch();
}
  
//computed
function VisitOptions() {
    if(this.VisitList.length === 0){
        return [{
            label: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
            },
        }];
    }else{
        let chart = [{
            label: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
            }
        }];
        this.VisitList.map(function(e){
            chart.push({
                label: e.VesselName,
                value: e.VisitId,
                Json: {
                    VoyageArrival: e.VoyageArrival,
                    Ata: DateFormater.formatDateTimeWithSlash(e.Ata),
                }
            })    
        })
        return chart;
    };
}

function computedListContainer() {
    let _lang = this.$i18n.locale;
    return this.ListDispatchedContainers.map((item, index) => {
        let BlNro = '';
        let TpBlName = '';

        item.BlJson.map((e, i)=>{
            BlNro = `${BlNro}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.BlNro??''}`
            TpBlName = `${TpBlName}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.TpBlName??''}`
        })

        return {
            ...item,
            Nro: index+1,
            VesselVoyage: item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : '',
            ComputedAta: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
            ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
            ComputedDispatch: item.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : 'N/A',
            DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
            AtaEta: item.Ata&&item.Atd ? `${DateFormater.formatDateTimeWithSlash(item.Ata)} - ${DateFormater.formatDateTimeWithSlash(item.Atd)}` : DateFormater.formatDateTimeWithSlash(item.Ata),
            CumputedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
            BlNro: BlNro,
            TpBlName: TpBlName,
            DocumentCode: item.DocumentCode ?? ''
        };
    })
}

function fields(){ 
    return [
        { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
        { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:160px; text-align:center;', _classes:" text-center" , sorter: true, filter: true},
        { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;', sorter: true, filter: true},
        { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
        { key: 'VesselVoyage', label: this.$t('label.visit'), _classes: 'text-center', _style: 'min-width: 200px;'},
        { key: 'ComputedAta', label: 'ATA', _classes: 'text-center', _style: 'min-width: 120px;'},
        { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
        { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
        { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _classes: 'text-center'},
        { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
        { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 130px;'},
        { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
        { key: 'ComputedDispatch', label: this.$t('label.dispatchDate'), _classes: 'text-center', _style: 'min-width: 175px;'},
        { key: 'DaysInYard', label: this.$t('label.daysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
        { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
        { key: 'TransaLogin', label: this.$t('label.DispatchUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
        { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
}
      
export default{
    name: 'dispatches-executed',
    data,
    mixins: [General],
    components: {

    },
    methods: {
        getYardCargoDispatch,
        getYardVisit,
        toggleContainer,
        validateDateRange,
        getDateRange,
        Resest,
        getYardCargo
    },
    computed: {
        VisitOptions,
        fields,
        computedListContainer,
        ...mapState({
            YardId: state => state.yardManagement.yardData.YardId,
            ListDispatchedContainers: state => state.yardManagement.ListDispatchedContainers,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        })
    },
    watch:{
        dropItemMovementList: function (val) {
            if (val==6) {
                this.$store.state.yardManagement.GeneralList = 2;
                this.Resest();
            }
        }
    }
}
</script>