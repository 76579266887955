<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      :show.sync="modalActive"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow class="mb-4">
                <CCol sm="6">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4',
                      input: 'col-sm-12 col-lg-8',
                    }"
                    v-uppercase
                    :label="$t('label.commodityType')"
                    size="sm"
                    v-model.trim="commodityInfo.type"
                    disabled
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.state')"
                    size="sm"
                    v-model.trim="commodityInfo.state"
                    disabled
                  />
                </CCol>

                <CCol sm="6">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.select')"
                    addLabelClasses="required text-right"
                    :label="$t('label.commodity')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4',
                      input: 'col-sm-12 col-lg-8',
                    }"
                    :options="commodityOptions"
                    :disabled="isEmpty(commodityItems)"
                    :value.sync="$v.inventoryInfo.CommodityId.$model"
                    :invalid-feedback="
                      errorMessage($v.inventoryInfo.CommodityId)
                    "
                    :is-valid="hasError($v.inventoryInfo.CommodityId)"
                  >
                  </CSelect>
                </CCol>
                <CCol sm="6">
                  <CInput
                    size="sm"
                    ref="weightInput"
                    :label="$t('label.totalWeight') + '(TON)'"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    placeholder="0"
                    addLabelClasses="required text-right"
                    v-model.trim="totalWeight"
                    required
                    disabled
                  />
                </CCol>

                <CCol sm="6">
                  <CInput
                    addLabelClasses="required text-right"
                    placeholder="BL"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4',
                      input: 'col-sm-12 col-lg-8',
                    }"
                    v-uppercase
                    label="BL"
                    maxlength="20"
                    size="sm"
                    v-model.trim="$v.inventoryInfo.NroBl.$model"
                    :invalid-feedback="errorMessage($v.inventoryInfo.NroBl)"
                    :is-valid="hasError($v.inventoryInfo.NroBl)"
                  />
                </CCol>
                <CCol sm="6">
                  <div role="group" class="form-group form-row">
                    <label
                      class="
                        text-right
                        col-form-label col-sm-12 col-lg-5 col-form-label-sm
                      "
                    >
                      {{ $t("label.certificationDate") }}
                    </label>
                    <div class="col-sm-12 col-lg-7 input-group-sm">
                      <vue-datepicker
                        valueType="format"
                        :open="openDate"
                        @update:open="functionDate($event)"
                        @clickoutside="openDate = false"
                        class="vue-datepicker-drive:focus"
                        @select="openDate = false"
                        @pick="openDate = false"
                        @close="openDate = false"
                        :clearable="false"
                        :editable="false"
                        :append-to-body="false"
                        :lang="this.$i18n.locale"
                        format="DD/MM/YYYY"
                        :disabled-date="validationDate"
                        v-model.trim="$v.inventoryInfo.CertificateDate.$model"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            :is-valid="hasError($v.inventoryInfo.CertificateDate)"
                            :invalid-feedback="errorMessage($v.inventoryInfo.CertificateDate)"
                            v-model.trim="$v.inventoryInfo.CertificateDate.$model"
                            @keypress="rightDate($event)"
                            class="mb-0"
                            size="sm"
                            placeholder="DD/MM/YYYY"
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>

                <CCol sm="6">
                  <CSelect
                    size="sm"
                    :placeholder="$t('label.select')"
                    addLabelClasses="required text-right"
                    :label="$t('label.client')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4',
                      input: 'col-sm-12 col-lg-8',
                    }"
                    :options="clientOptions"
                    :disabled="isEmpty(clients)"
                    :value.sync="$v.inventoryInfo.ClientTpId.$model"
                    :invalid-feedback="
                      errorMessage($v.inventoryInfo.ClientTpId)
                    "
                    :is-valid="hasError($v.inventoryInfo.ClientTpId)"
                  >
                   <template #append>
                      <CButton
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0"
                        v-c-tooltip="{
                          content: $t('label.nuevo')+$t('label.client'),
                          placement: 'top-end'
                        }"
                        @click="handleCollapse"
                      >
                        <CIcon name="cil-playlist-add" />
                      </CButton>
                    </template>
                  </CSelect>
                  <loading v-show="loadingClients" element="select" />
                </CCol>
                <CCol sm="6">
                  <CInput
                    addLabelClasses="text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    v-uppercase
                    :label="$t('label.clientRif')"
                    size="sm"
                    v-model.trim="clientInfo.rif"
                    disabled
                  />
                </CCol>

                <CCol sm="12">
                  <CTextarea
                    size="sm"
                    addLabelClasses="text-right label-textarea-inventory-metalscrap"
                    v-uppercase
                    :label="$t('label.inventoryContains')"
                    :placeholder="$t('label.inventoryContains')"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-2',
                      input: 'col-sm-12 col-lg-10',
                    }"
                    rows="2"
                    maxlength="150"
                    v-model.trim="$v.inventoryInfo.GeneralInfo.$model"
                    :invalid-feedback="
                      errorMessage($v.inventoryInfo.GeneralInfo)
                    "
                    :is-valid="hasError($v.inventoryInfo.GeneralInfo)"
                  />
                </CCol>

                <CCol sm="6" v-if="edit">
                  <div>
                    <CSelect
                      :value.sync="inventoryInfo.Status"
                      :is-valid="statusSelectColor()"
                      :horizontal="{
                        label: 'col-sm-12 col-lg-4',
                        input: 'col-sm-12 col-lg-8',
                      }"
                      size="sm"
                      :label="$t('label.status')"
                      :options="statusOptions"
                      addLabelClasses="text-right"
                    />
                  </div>
                </CCol>
              </CRow>

              <!-- Collapse Clientes -->
              <CRow>
                <CCol sm="12">
                  <CCollapse :show="collapse">
                    <CRow>
                      <CCol sm="12" v-if="collapse">
                        <AddCliente
                          metalScrap="ClientConsigne-insert"
                          @child-refresh="handleCollapse"
                          @update-list="handleRefresh"
                        />
                      </CCol>
                    </CRow>
                  </CCollapse>
                </CCol>
              </CRow>

              <!-- Yard -->
              <CRow>
                <CCol sm="12">
                  <YardInputs
                    :yards="yardOptions"
                    :item="yardToEdit"
                    :edit="editYard"
                    @clear="clearYard"
                    @submit="submitYard"
                    @update="updateYard"
                  />
                </CCol>
              </CRow>

              <!-- Yard-List -->
              <CRow>
                <CCol sm="12">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="formatedYards"
                    :fields="fieldsYards"
                    :loading="loading"
                    column-filter
                    :noItemsView="tableText.noItemsViewText"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="tableText.itemsPerPage"
                    hover
                    small
                    sorter
                    pagination
                  >
                    <template #loading>
                      <loading/>
                    </template>
                    <template #Status="{ item }">
                      <td class="text-center align-middle">
                        <div>
                          <CBadge :color="getBadge(item.Status)">
                            {{ $t("label." + item.Status) }}
                          </CBadge>
                        </div>
                      </td>
                    </template>
                    <template #Detalle="{item}">
                      <td class="text-center align-middle">
                        <CButtonGroup>
                          <CButton
                            shape="square"
                            color="edit"
                            size="sm"
                            v-c-tooltip="$t('label.edit')+' '+$t('label.yard')"
                            @click.prevent="editYardInfo(item)"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                        </CButtonGroup>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import InventoryValidation from "@/_validations/metalscrap/InventoryFormValidations.js";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import MetalScrap from '@/_mixins/metalscrap';
import mixinServicio from '@/_mixins/servicio';
import Fuse from "fuse.js";
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import { dateValidationByYearQty } from "@/_validations/funciones";

import AddCliente from '@/pages/cliente/add-cliente';
import YardInputs from './yard-inputs';

//DATA
function data() {
  return {
    modalActive: false,
    inventoryInfo: {
      BillOfLadingYardId: "",
      ClientTpId: "",
      NroBl: "",
      YardJson: [],
      TpCargoId: "",
      CommodityId: "",
      GeneralInfo: "",
      CertificateDate: "",
      Status: 1,
    },
    commodityInfo: {
      state: "",
      type: "",
      TpCargoId: "",
    },
    clientInfo: {
      rif: "",
    },
    clientId: '',
    clientClass: 'col mx-0 px-0',
    oldStatus: 1,
    loading: false,
    commodityItems: [],
    yards: [],
    clients: [],
    TpClientId: "b03f5d06-149b-ec11-a877-000d3a0dedc7",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    openDate: false,
    collapse: false,
    yardToEdit: null,
    editYard: false,
    loadingClients: false,
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else this.loadData();

  this.modalActive = newVal;
}
function resetForm() {
  let arrKeys = Object.keys(this.inventoryInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.inventoryInfo[arrKeys[i]] = 1;
        break;
      case 'YardJson':
        this.inventoryInfo[arrKeys[i]] = [];
        break;
      default:
        this.inventoryInfo[arrKeys[i]] = "";
    }
  }
  this.oldStatus = 1;
  this.clientInfo.rif = "";
  this.clientId = '';
  this.clientClass = 'col mx-0 px-0';
  this.commodityInfo.type = "";
  this.commodityInfo.state = "";
  this.commodityInfo.TpCargoId = "";
  this.commodityItems = [];
  this.yards = [];
  this.clients = [];
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = "";

  this.collapse = false;
  this.yardToEdit = null;
  this.editYard = false;
  this.loadingClients = false;
  this.$v.$reset();
}
function setForm(inventory) {
  let arrKeys = Object.keys(this.inventoryInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case 'YardJson':
        this.inventoryInfo[arrKeys[i]] = Array.isArray(inventory[arrKeys[i]]) ? [...inventory[arrKeys[i]]] : [];
        break;
      case "CertificateDate":
        this.inventoryInfo[arrKeys[i]] = inventory[arrKeys[i]]
          ? this.formatDate(inventory[arrKeys[i]])
          : '';
        break;
      case "Status":
        this.inventoryInfo[arrKeys[i]] =
          inventory[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = inventory[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.inventoryInfo[arrKeys[i]] = inventory[arrKeys[i]]
          ? inventory[arrKeys[i]]
          : "";
    }
  }
  this.clientInfo.rif = inventory.ClientRif ? inventory.ClientRif : "";
  this.$v.$touch();
}
function loadData() {
  this.loading = true;

  let peticiones = !this.edit
    ? [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "BulkCargoYard-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
      ]
    : [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "BulkCargoYard-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
        this.$http.ejecutar("GET", "BillOfLadingYard-by-id", {
          BillOfLadingYardId: this.inventoryId,
        }),
      ];

  Promise.all(peticiones)
    .then((responses) => {
      this.clients = responses[0].data.data;
      this.yards = responses[1].data.data;
      this.commodityItems = responses[2].data.data[0].CommodityJson;
      this.commodityInfo = {
        ...this.commodityInfo,
        TpCargoId: responses[2].data.data[0].TpCargoJson[0].TpCargoId,
        type: responses[2].data.data[0].TpCargoJson[0].GpoCargoName,
        state: responses[2].data.data[0].TpCargoJson[0].TpCargoName,
      }
      this.inventoryInfo.TpCargoId = responses[2].data.data[0].TpCargoJson[0].TpCargoId;

      if (this.edit) {
        this.setForm(responses[3].data.data[0]);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      console.log(err);
    })
    .then(() => {
      this.loading = false;
    });
}
function formatNumericValue(val) {
  return parseFloat(val.replace(".", "").replace(",", ".")).toFixed(2);
}
function formatedData() {
  if (!this.edit) {
    let newInventory = {
      ...this.inventoryInfo,
      CertificateDate: this.inventoryInfo.CertificateDate
        ? this.formatDate(this.inventoryInfo.CertificateDate, true)
        : "",
      YardJson: this.inventoryInfo.YardJson.map(
        (item) =>
          Object.assign(
            {},
            {
              YardId: item.YardId,
              Weight: item.Weight,
            }
          )
      ),
    };

    delete newInventory.BillOfLadingYardId;
    delete newInventory.Status;

    return newInventory;
  } else {
    return {
      ...this.inventoryInfo,
      CertificateDate: this.inventoryInfo.CertificateDate
        ? this.formatDate(this.inventoryInfo.CertificateDate, true)
        : "",
      YardJson: this.inventoryInfo.YardJson.map((item) => Object.assign({}, {
        BillOfLadingYardDetailId: item.BillOfLadingYardDetailId,
        YardId: item.YardId,
        Weight: item.Weight,
        Status: typeof item.Status != "number"
          ? item.Status == "ACTIVO" ? 1 : 0
          : item.Status,
      })),
    };
  }
}
function submit() {
  try {
    this.$v.inventoryInfo.$touch();
    if (this.$v.inventoryInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit
      ? "BillOfLadingYard-update"
      : "InventoryBillOfLadingYard-insert";
    let BillOfLadingYardJson = this.formatedData();
    let metodo = this.edit ? 'PUT' : 'POST';

    this.$http
      .ejecutar(metodo, route, BillOfLadingYardJson, {
        root: "BillOfLadingYardJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function isEmpty(arr) {
  return arr.length == 0;
}
function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}
function formatCharDate(e) {
  var regex = new RegExp("^[0-9-/]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function confirmation() {
  try {
    this.$v.inventoryInfo.$touch();
    if (this.$v.inventoryInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.inventoryInfo.Status,
      this.submit,
      `${this.inventoryInfo.NroBl}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getClients() {
  this.loadingClients = true;
  this.$http
    .get("Client-list-by-activity", {
      TpClientId: this.TpClientId,
      Filter: "ACTIVO",
    }).then((response) => {
      this.clients = response.data.data;
    }).catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingClients = false;
    });
}
function fuseSearchClient(options, search) {
  const fuse = new Fuse(options, {
    keys: ["ClientName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
function handleCollapse() {
  if (this.collapse) {
    this.getClients();
    this.collapse = false;
  } else {
    this.collapse = true;
  }  
}
function handleRefresh() {
  this.getClients();
}

function editYardInfo(item) {
  this.yardToEdit = {...item};
  this.editYard  = true;
}
function clearYard() {
  this.editYard = false;
}
function submitYard(yard) {
 this.inventoryInfo.YardJson.push(yard);
}
function updateYard(response) {
  let info = {...response};

  let index = this.inventoryInfo.YardJson.findIndex((yard) => yard.BillOfLadingYardDetailId == info.BillOfLadingYardDetailId);

  if(index == -1) {
    let flag = this.inventoryInfo.YardJson.findIndex(
      (yard) => yard.AuxId == info.AuxId
    );

    if (flag == -1) return;

    let toReplace = { ...this.inventoryInfo.YardJson[flag], ...info };
    this.inventoryInfo.YardJson.splice(flag, 1, toReplace);
  } else {
    let toReplace = {...this.inventoryInfo.YardJson[index], ...info};
  
    this.inventoryInfo.YardJson.splice(index, 1, toReplace);
  }


  this.editYard = false;
  this.yardToEdit = null;
}
function validationDate(date) {
  return dateValidationByYearQty(date);
}

//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return this.$t("label.nuevo") + " " + this.$t("label.inventory");
  } else {
    return `${this.$t("label.edit")} ${this.$t("label.inventory")}: ${this.inventoryInfo.NroBl}`;
  }
}
function yardOptions() {
  return this.yards.map((yard) =>
    Object.assign({}, yard, {
      label: yard.YardName,
      value: yard.YardId,
    })
  );
}
function clientOptions() {
  return this.clients.map((client) =>
    Object.assign({}, client, {
      label: client.ClientName,
      value: client.ClientTpId,
    })
  );
}
function commodityOptions() {
  return this.commodityItems.map((commodity) =>
    Object.assign({}, commodity, {
      label: commodity.CommodityName,
      value: commodity.CommodityId,
    })
  );
}
function statusSelectColor() {
  return this.inventoryInfo.Status === 1;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function fieldsYards() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes:"text-center ", filter: false},
    { key: "YardName", label: this.$t('label.yard'),},
    { key: "WeightTON", label: this.$t("label.weight")+'(TON)',},
    { key: "User", label: this.$t('label.user'),},
    { key: "Date", label: this.$t('label.date'), _classes:"text-center"},
    { key: "Status", label: this.$t('label.status'), _classes:"text-center"},
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles:"width: 1%;"
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: 'align-middle',
    YardName: 'align-middle',
    WeightTON: 'align-middle',
    User: 'align-middle',
    Date: 'align-middle',
    Status: 'align-middle',
  }
}
function formatedYards() {
  let index = 1;
  return this.inventoryInfo.YardJson.map((yard) => {
    let yardName = '';
    let foundYard = this.yards.find((item) => item.YardId == yard.YardId);
    if(foundYard)
      yardName = foundYard.YardName;

    return Object.assign({}, yard, {
      Nro: index++,
      YardName: yard.YardName ? yard.YardName : yardName,
      WeightTON: formatMilDecimal(parseFloat(yard.Weight).toFixed(2)),
      User: yard.TransaLogin ? yard.TransaLogin : this.user.Login,
      Date: yard.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(yard.TransaRegDate)
        : DateFormater.formatOnlyDateWithSlash(new Date()),
      Status: typeof yard.Status == 'number' 
        ? yard.Status  == 1 ? 'ACTIVO':'INACTIVO'
        : yard.Status,
      _classes: typeof yard.Status == 'number' 
        ? yard.Status  == 1 ? '':"table-danger"
        : yard.Status == 'ACTIVO' ? '' :"table-danger",
      _cellClasses: this.cellTableClasses,
    });
  });
}
function currentClient() {
  return this.inventoryInfo.ClientTpId;
}
function totalWeight() {
  let total = 0;
  for (let index = 0; index < this.inventoryInfo.YardJson.length; index++) {
    if(this.inventoryInfo.YardJson[index].Status == 'ACTIVO' || this.inventoryInfo.YardJson[index].Status == 1)
      total += this.inventoryInfo.YardJson[index].Weight;
  }
      
  return formatMilDecimal(parseFloat(total).toFixed(2));
}

export default {
  name: "inventory-modal",
  mixins: [ModalMixin, General, mixinServicio, MetalScrap],
  data,
  components: {
    AddCliente,
    YardInputs,
  },
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    inventoryId: {
      type: String,
      default: "",
    },
  },
  validations: InventoryValidation,
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentClient: function (val) {
      if(val) {
        let found = this.clients.find((item) => item.ClientTpId == val);
        this.clientInfo.rif = found ? found.ClientRif : '';
      }else {
        this.clientInfo.rif = '';
      }
    },
  },
  methods: {
    loadData,
    toggle,
    resetForm,
    setForm,
    isEmpty,
    formatedData,
    submit,
    functionDate,
    formatCharDate,
    confirmation,
    statusSelectColor,
    formatNumericValue,
    fuseSearchClient,
    getClients,
    handleCollapse,
    handleRefresh,
    clearYard,
    submitYard,
    updateYard,
    editYardInfo,
    validationDate,
  },
  computed: {
    tituloModal,
    yardOptions,
    clientOptions,
    commodityOptions,
    getBranchId,
    fieldsYards,
    cellTableClasses,
    formatedYards,
    currentClient,
    totalWeight,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>