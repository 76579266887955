<template>
  <div>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <CRow>
          <CCol sm="12" lg="12">
            <b>{{$t('label.serviceList')}}</b>
          </CCol>
          <div class="TogglerPdf d-flex justify-content-end position-dropdown">
            <img size="sm" width="30px" height="30px" 
                class="position-icon" 
                src="/img/iconos/GIF_AYUDA.gif"
                aria-expanded="false"
                style="cursor: pointer;"
                @click="EventImg()"
              >
              <CDropdown id="dropdown-help" addTogglerClasses="Toggler-Botton p-1"  :togglerText="''" 
                  style="width: 30px; height: 30px;">
                <CDropdownItem>
                  <a class="dropdown-item" @click="OpenModal(1,$t('label.service')+'S')">{{$t('label.service')+'S'}}</a>
                </CDropdownItem>
                <CDropdownItem>
                  <a class="dropdown-item" @click="OpenModal(2,$t('label.port')+'S')">{{$t('label.port')+'S'}}</a>
                </CDropdownItem>
                <CDropdownItem>
                  <a class="dropdown-item" @click="OpenModal(3,$t('label.vessel')+'S')">{{$t('label.vessel')+'S'}}</a>
                </CDropdownItem>
              </CDropdown>
          </div>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CustomTabs :active-tab="0" @update:activeTab="handleTab">
          <CustomTab icon="service" :title="$t('label.service')+'S'" class="pb-3">
            <indexServicio :tabValue="activeTab" />
          </CustomTab>
          <CustomTab icon="port" :title="$t('label.port')+'S'" class="pb-3">
            <indexPuerto :tabValue="activeTab"/>
          </CustomTab>
          <CustomTab icon="vessel" :title="$t('label.vessel')+'S'" class="pb-3">
            <indexBuque :tabValue="activeTab"/>
          </CustomTab>
        </CustomTabs>
      </CCardBody>
    </CCard>
    <ModalHelpViews
        :modal.sync="ShowModal"
        :Type="Type"
        :title="titleHelpModal"
        @close="Close"
      />
  </div>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import indexServicio from './servicio/index-servicio';
import indexPuerto from './puerto/index-puerto';
import indexBuque from './buque/index-buque';
import ModalHelpViews from './modal-help-views';

function data() {
  return {
    activeTab: 0, 
    ShowModal: false,
    Type: 0,
    titleHelpModal: ''
  };
}

function handleTab(tab) {
  this.activeTab = tab;
}

function OpenModal(type,title){
  this.ShowModal = true;
  this.Type= type;
  this.titleHelpModal = title;
}

function EventImg(){
  let Dropdown = document.getElementById("dropdown-help");
  Dropdown.classList.toggle("show");
  Dropdown.lastChild.classList.toggle("show");
  Dropdown.lastChild.classList.toggle("dropdown-center-help")
}

function Close(){
  this.ShowModal = false;
  this.Type = 0;
  this.titleHelpModal = ''
}

export default {
  name: 'servicio',
  data,
  components: {
    CustomTabs,
    CustomTab,
    indexServicio,
    indexPuerto,
    indexBuque,
    ModalHelpViews
  },
  methods: {
    handleTab,
    OpenModal,
    EventImg,
    Close
  },
};
</script>
<style>
.dropdown-center-help{
  position: absolute; 
  inset: 0px 0px auto auto; 
  margin: 0px; 
  transform: translate(0.4375px, 45px);
}
.position-dropdown{
  position: absolute;
  z-index: 1;
  width: 98%;
  top: 1%;
}
.position-icon{
  position: absolute;
  z-index: 1;
}
#position-dropdown .dropdown .btn {
  height: 30px;
  width: 30px;
}
#dropdown-help .dropdown-menu.show{
  top: -21%;
}
#dropdown-help .btn {
  height: 30px;
  width: 30px;
}

</style>
