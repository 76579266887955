<template>

  <form @submit.prevent="submitBody">
    <loading-overlay
        :active="apiStateLoading"
        :is-full-page="true"
        loader="bars"
      />
    <CModal
      :title=title
      color="dark"
      size="xl"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
      class="modal-content-siget-module"
      >
    
      <CRow>
        <CCol sm="12" lg="6">
          <CCol sm="12">
            <CInput
              :label="$t('label.cargoType')"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
              disabled
              addLabelClasses="text-right"
              v-model="GpoCargoName"  
              size="sm"               
            />
          </CCol>
          
          <CCol sm="12">
            <CInput   
              :label="$t('label.description')+' '+'(ES)'"
              :placeholder="$t('label.description')+' '+'(ES)'"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="required text-right"  
              v-model="$v.ModuleNameDsEs.$model"  
              :is-valid="hasError($v.ModuleNameDsEs)"
              :invalid-feedback="errorMessage($v.ModuleNameDsEs)"
              size="sm"
            />
          </CCol>
          <CCol  sm="12">
            <CInput
              class="text-table"
              :label="$t('label.route')"
              :placeholder="$t('label.route')"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="required text-right" 
              v-model="$v.ModuleRoute.$model"
              :is-valid="hasError($v.ModuleRoute)"
              :invalid-feedback="errorMessage($v.ModuleRoute)"
              size="sm"
            />
          </CCol>
          <CCol sm="12">
            <CInput
              class="text-table"
              :label="$t('label.icon')"
              :placeholder="$t('label.icon')"
              :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="required text-right"  
              v-model="$v.ModuleIcon.$model" 
              :is-valid="hasError($v.ModuleIcon)"
              :invalid-feedback="errorMessage($v.ModuleIcon)"     
              size="sm"          
              />
          </CCol>
        </CCol>
             
        <CCol sm="12" lg="6">
          <CCol sm="12">
            <CInput
              class="text-table"
              :label="$t('label.name')"
              :placeholder="$t('label.ModuleName')"
              :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
              addLabelClasses="required text-right"
              v-model="$v.ModuleName.$model"   
              :is-valid="hasError($v.ModuleName)"
              :invalid-feedback="errorMessage($v.ModuleName)"   
              size="sm"           
            />
          </CCol>
            <CCol  sm="12">
              <CInput               
                :label="$t('label.description')+' '+'(EN)'"
                :placeholder="$t('label.description')+' '+'(EN)'"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="required text-right" 
                v-model="$v.ModuleNameDsEn.$model"  
                :is-valid="hasError($v.ModuleNameDsEn)"
                :invalid-feedback="errorMessage($v.ModuleNameDsEn)" 
                size="sm"
              />
            </CCol>
            <CCol  sm="12" >
              <CInput   
                class="text-table"            
                :label="$t('label.navigation')"
                :placeholder="$t('label.navigation')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="required text-right"  
                v-model="$v.Navegation.$model" 
                :is-valid="hasError($v.Navegation)"
                :invalid-feedback="errorMessage($v.Navegation)"  
                size="sm"
              />
          </CCol>
          <CCol  sm="12" v-if="isEdit">
            <CSelect
              :options="statusOptions"
              :value.sync="Status"
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              addLabelClasses="required text-right"
              :is-valid="statusSelectColor"
              size="sm"
            />
          </CCol>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="isEdit ? statusConfirmation(fatherItem.FgActModuleDevice , Status, submitBody) : submitBody()"
          :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModal>
  </form>
  </template>
  
  <script>
  import ModalMixin from '@/_mixins/modal';
  import SigetMobileValidations from '@/_validations/siget-mobile/sigetMobileValidations.js';
  
  
  function data() {
    return {
      modalActive: false,
      GpoCargoId:'',
      ModuleDeviceId:'',
      ModuleDeviceChildId:'',
      GpoCargoName:'',
      ModuleName:'',
      ModuleIcon:'',
      ModuleNameDsEs:'',
      ModuleNameDsEn:'',
      ModuleRoute:'',
      Navegation:'',
      Status:0,
      apiStateLoading:false,
      isSubmit:false,
    };
  }
  
  
  function submitBody(){
    try {
      this.isSubmit = true;
      this.$v.$touch();
      this.apiStateLoading = true;
      if (this.$v.$error) {
        this.isSubmit = false;
        this.apiStateLoading = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      const ModuleDeviceJson = this.isEdit ? {
          ModuleDeviceId:this.ModuleDeviceId,
          GpoCargoId: this.GpoCargoId,
          ModuleDeviceChildId: this.ModuleDeviceChildId,
          ModuleName: this.ModuleName,
          ModuleNameDsEs: this.ModuleNameDsEs,
          ModuleNameDsEn: this.ModuleNameDsEn,
          ModuleIcon: this.ModuleIcon,
          ModuleRoute: this.ModuleRoute,
          Navegation: this.Navegation,
          Status: this.Status,
      }:{
          GpoCargoId: this.GpoCargoId,
          ModuleDeviceChildId: this.ModuleDeviceChildId,
          ModuleName: this.ModuleName,
          ModuleNameDsEs: this.ModuleNameDsEs,
          ModuleNameDsEn: this.ModuleNameDsEn,
          ModuleIcon: this.ModuleIcon,
          ModuleRoute: this.ModuleRoute,
          Navegation: this.Navegation,
      }
      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'ModuleDevice-update':'ModuleDevice-insert';
      this.$http
        .ejecutar(metodo, ruta, ModuleDeviceJson,{ root: 'ModuleDeviceJson'},)
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            const data = response.data.data[0];
            this.resetForm();
            this.$emit('Update-list');
            this.$emit('close');
            this.apiStateLoading = false;
            this.notifySuccess({text: messageSuccess})
            this.isSubmit = false;
          } 
        }).catch((e) => {
          this.isSubmit = false;
          this.apiStateLoading = false;
          this.notifyError({text: e});
        });
    } catch (e) {
      this.isSubmit = false;
      this.apiStateLoading = false;
      this.notifyError({text: e});
    }
  }
  
  
  //methods
  
  function statusSelectColor() {
    return this.Status === 1;
  }
  
  function getdata(val) {
    this.ModuleDeviceId=val.ModuleDeviceId;
    this.GpoCargoId = val.GpoCargoId;
    this.ModuleDeviceChildId = val.ModuleDeviceChildId;
    this.ModuleName = val.ModuleName;
    this.ModuleNameDsEs = val.ModuleNameDsEs;
    this.ModuleNameDsEn = val.ModuleNameDsEn;
    this.ModuleIcon = val.ModuleIcon;
    this.ModuleRoute = val.ModuleRoute;
    this.Navegation = val.Navegation;
    this.Status = val.FgActModuleDevice?1:0;
    this.$v.$touch();
  }
  
  function resetForm() {
    this.GpoCargoId = '';
    this.ModuleDeviceId='';
    this.ModuleDeviceChildId = '';
    this.GpoCargoName = '';
    this.ModuleName = '';
    this.ModuleNameDsEs = '';
    this.ModuleNameDsEn = '';
    this.ModuleIcon = '';
    this.ModuleRoute = '';
    this.Navegation = '';
    this.Status = 0;
  
    this.$v.$reset();
  }
  
  
  //computed
 

  
  export default {
    name: 'modal-siget-mobile',
    props: { modal: Boolean, GpoCargo: Object, fatherItem: Object, isEdit: Boolean, title: String,},
    data,
    validations: SigetMobileValidations,
    mixins: [
      ModalMixin,
    ],
    methods:{
      submitBody,
      statusSelectColor,
      getdata,
      resetForm,
    },
    watch: {
      modal: function(val){
        this.modalActive = val;
        if (this.isEdit==true) {
          this.getdata(this.fatherItem);
        }
        if (val) {
          this.GpoCargoName = this.GpoCargo.GpoCargoName;
          this.GpoCargoId = this.GpoCargo.GpoCargoId;
        }else{
          this.resetForm();
        };
      }
    },
  };
</script>
<style lang="scss">
.modal-content-siget-module {
  .modal-content {
    width: 95% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
.text-table .form-control {
  text-transform: none !important;
}
</style>
