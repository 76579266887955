<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <CCol sm="12" class="mb-2">
        <label class="col-sm-12 col-lg-auto px-0 m-0 text-right">
          <b>{{$t('label.bl')}}</b>
        </label>
      </CCol>
      <CRow class="m-0">
        <CCol sm="12" lg="4">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.totalWeight')} (KGM)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="TotalBl.Weight"
                maxlength= "13"
                disabled
              >
              </money>
              <div class="invalid-feedback" v-if="$v.BasicData.Weight.$error">
                {{ errorMessage($v.BasicData.Weight) }}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.TotalVolume')} (M³)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="TotalBl.Volumen"
                maxlength= "13"
                disabled
              >
              </money>
              <div class="invalid-feedback" v-if="$v.BasicData.Volumen.$error">
                {{ errorMessage($v.BasicData.Volumen) }}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.totalQuantity')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="TotalBl.Quantity"
                maxlength= "13"
                disabled
              >
              </money>
              <div class="invalid-feedback" v-if="$v.BasicData.Quantity.$error">
                {{ errorMessage($v.BasicData.Quantity) }}
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCol>

    <CCol sm="12">
      <CTabs :active-tab="TabIndex" @update:activeTab="TabIndex=$event" variant="tabs" class="m-0">
        <CTab :title="$t('label.generalInfo')" :class="$v.Packaging.PackagingId.$error || $v.Packaging.Quantity.$error || $v.Packaging.Weight.$error ? 'tab-error' : ''">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <CRow class="m-0">
              <CCol sm="12" lg="4">
                <CSelect
                  size="sm"
                  :label="$t('label.commodity')"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  :options="CommodityOptions"
                  addLabelClasses="text-right"
                  v-model="$v.Packaging.CommodityId.$model"
                  :is-valid="hasError($v.Packaging.CommodityId)"
                  :invalid-feedback="errorMessage($v.Packaging.CommodityId)"
                  @change="onChangeCommodityId"
                />
              </CCol>
              <CCol sm="12" lg="4">
                <CInput
                  v-uppercase
                  size="sm"
                  placeholder="RUBRO"
                  addLabelClasses="text-right"
                  label="RUBRO"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  v-model="$v.Packaging.HeadingName.$model"
                  :is-valid="hasError($v.Packaging.HeadingName)"
                  :invalid-feedback="errorMessage($v.Packaging.HeadingName)"
                  disabled
                />
              </CCol>
              <CCol sm="12" lg="4">
                <CInput
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.serial')"
                  addLabelClasses="text-right"
                  :label="$t('label.serial')"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  v-model="$v.Packaging.Serial.$model"
                  :is-valid="hasError($v.Packaging.Serial)"
                  :invalid-feedback="errorMessage($v.Packaging.Serial)"
                />
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!$v.Packaging.Quantity.$dirty ? 'form-control' : ($v.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="$v.Packaging.Quantity.$model"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="$v.Packaging.Quantity.$error&&!CleanInformation">
                      {{ errorMessage($v.Packaging.Quantity) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!$v.Packaging.Weight.$dirty ? 'form-control' : ($v.Packaging.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="$v.Packaging.Weight.$model"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="$v.Packaging.Weight.$error&&!CleanInformation">
                      {{ errorMessage($v.Packaging.Weight) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-4 text-right">
                    {{$t('label.ApplyPackaging')}}
                  </label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="FgPackaging"
                      @update:checked="$v.Packaging.PackagingId.$model = '', FgExternPackaging = false"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" v-if="FgPackaging">
                <CSelect
                  size="sm"
                  :label="$t('label.packaging')"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  :options="PackagingOptions"
                  addLabelClasses="required text-right"
                  v-model="$v.Packaging.PackagingId.$model"
                  :is-valid="hasError($v.Packaging.PackagingId)"
                />
                <div class="invalid-feedback" v-if="$v.Packaging.PackagingId.$error&&!CleanInformation">
                  {{ errorMessage($v.Packaging.PackagingId) }}
                </div>
              </CCol>
              <CCol sm="12" lg="4" v-if="FgPackaging">
                <div class="d-flex justify-content-end">
                  <div class="col-sm-auto col-lg-12 d-flex p-0">
                    <label class="col-form-label-sm col-sm-auto col-lg-4 text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.internal')}}
                    </label>
                    <div class="col-sm-auto d-flex justify-content-end" style="padding-right: 5px; padding-left: 5px;">
                      <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="FgExternPackaging"
                      />
                    </div>
                    <label class="col-form-label-sm col-sm-auto text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.external')}}
                    </label>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <CTextarea
                  :label="$t('label.description')"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  size="sm"
                  v-uppercase
                  v-model.trim="$v.Packaging.DescriptionOfGoods.$model"
                  addLabelClasses="text-right"
                  rows="2"
                  :is-valid="hasError($v.Packaging.DescriptionOfGoods)"
                  :invalid-feedback="errorMessage($v.Packaging.DescriptionOfGoods)"
                />
              </CCol>
            </CRow>
            
          </div>
        </CTab>
        <CTab :title="$t('label.dimensions')" :class="$v.Packaging.Length.$error || $v.Packaging.Width.$error || $v.Packaging.Height.$error ? 'tab-error' : ''">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <CRow class="m-0">
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!$v.Packaging.Length.$dirty ? 'form-control' : ($v.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="$v.Packaging.Length.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!$v.Packaging.Width.$dirty ? 'form-control' : ($v.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="$v.Packaging.Width.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!$v.Packaging.Height.$dirty ? 'form-control' : ($v.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="$v.Packaging.Height.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`M²`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!$v.Packaging.Length.$dirty || !$v.Packaging.Width.$dirty || !$v.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.SquareMeters"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.volume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!$v.Packaging.Length.$dirty || !$v.Packaging.Width.$dirty || !$v.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.Volumen"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
            </CRow>
            
          </div>
        </CTab>
        <CTab :title="$t('label.imdg')" :disabled="Packaging.Quantity == 0 || Packaging.Weight == 0">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <ImdgTab
              :ImdgList="ImdgList"
              :Tab="Tab"
              :fgPackagingTab="true"
              :Clean="CleanInformation"
              @Loading="$emit('Loading', $event)"
              @UpdateList="ImdgList=$event"
            />
          </div>
        </CTab>
      </CTabs>
      <CCol sm="12 px-0">
        <div class="col-sm-12 col-form-label-sm text-right px-0" style="text-align: left;">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="SubmitPackaging()"
          >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            @click="Clear(false)"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CCol>
        
    
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListPackaging"
        :fields="packagingfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              v-if="!ValidatePackagingContainer(item.PackagingId)"
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end'
              }"
              @click="EditPackaging(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              v-if="!ValidatePackagingContainer(item.PackagingId) && (item.Quantity == 0)"
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="text-invalid-feedback text-center" v-if="$v.PackagingList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import ImdgTab from './imdg-tab';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    CleanInformation: false,
    isSubmit: false,
    FgPackaging: false,
    FgExternPackaging: false,
    TabIndex: 0,
    Packaging: {
      Id: '',
      PackagingId: '',
      PackagingNameEn: '',
      PackagingNameEs: '',
      HeadingId: '',
      HeadingName: '',
      CommodityId: '',
      Serial: '',
      DescriptionOfGoods: '',
      Length: 0,
      Width: 0,
      Height: 0,
      SquareMeters: 0,
      Weight: 0, 
      Quantity: 0,
      CertifiedQuantity: 0,
      Volumen: 0,
    },
    PackagingSelectList: [],
    ImdgList: [],
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//methods
function getService() {
  //this.$emit('Loading', true)
  let peticiones = [
    this.$http.ejecutar("GET", "Packaging-list", { Filter: 'ACTIVO' }),
  ];
  Promise.all(peticiones)
    .then((responses) => {
      this.PackagingSelectList = responses[0].data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      //this.$emit('Loading', false)
    });
}

async function SubmitPackaging() {
  try {
    this.isSubmit = true;
    this.$v.Packaging.$touch();
    if (this.$v.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let ImdgActivesList = this.ImdgList.filter(e => e.Status == 1);

    if (
      this.PackagingList.some(e => {
        let ImdgActiveJson = e.ImdgJson.filter(el => el.Status == 1)
        return e.Status == 1 && (this.Packaging.Id=='' ? true : e.IdX != this.Packaging.Id) && e.PackagingId == this.Packaging.PackagingId && 
          (ImdgActiveJson.length == 0 ? 
            ImdgActivesList.length == 0 :
            ImdgActiveJson.some(el => ImdgActivesList.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)))
      })
    ) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }


    let PackagingData = this.PackagingSelectList.find(item => item.PackagingId == this.Packaging.PackagingId);
    if (this.Packaging.Id=='') {
      this.PackagingList.push({
        IdX: this.PackagingList.length > 0 ? this.PackagingList[this.PackagingList.length - 1].IdX + 1 : 1,
        PackagingId: this.FgPackaging ? this.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
        HeadingId: this.Packaging.HeadingId ?? '',
        HeadingName: this.Packaging.HeadingName ?? '',
        CommodityId: this.Packaging.CommodityId ?? '',
        Serial: this.Packaging.Serial ?? '',
        DescriptionOfGoods: this.Packaging.DescriptionOfGoods ?? '',  
        Length: NumberFormater.setNum(this.Packaging.Length),
        Width: NumberFormater.setNum(this.Packaging.Width),
        Height: NumberFormater.setNum(this.Packaging.Height),
        SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
        Weight: this.Packaging.Weight,
        QuantityPlanning: this.Packaging.Quantity,
        Volumen: this.Meters.Volumen,
        PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
        PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
        FgExternPackaging: this.FgExternPackaging,
        ImdgJson: this.ImdgList,
        Status: 1,
      });
    }else{
      this.$emit('UpdateList', this.PackagingList.map(item => {
        if (item.IdX == this.Packaging.Id) {
          return {
            ...item,
            IdX: item.IdX,
            VisitBillOfLadingPackagingId: item.VisitBillOfLadingPackagingId,
            HeadingId: this.Packaging.HeadingId ?? '',
            HeadingName: this.Packaging.HeadingName ?? '',
            CommodityId: this.Packaging.CommodityId ?? '',
            Serial: this.Packaging.Serial ?? '',
            DescriptionOfGoods: this.Packaging.DescriptionOfGoods ?? '',
            Length: NumberFormater.setNum(this.Packaging.Length),
            Width: NumberFormater.setNum(this.Packaging.Width),
            Height: NumberFormater.setNum(this.Packaging.Height),
            SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
            PackagingId: this.FgPackaging ? this.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
            Weight: NumberFormater.setNum(this.Packaging.Weight), 
            QuantityPlanning: this.Packaging.Quantity,
            Quantity: item.Quantity,
            Volumen: NumberFormater.setNum(this.Meters.Volumen),
            PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
            PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
            FgExternPackaging: this.FgExternPackaging,
            ImdgJson: this.ImdgList,
            Status: 1,
          }
        }else{
          return item;
        }
      }));
    }
    await this.Clear(false);
    this.isSubmit = false;
    this.$v.Packaging.$reset();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function EditPackaging(item) {
  this.Packaging = {
    Id: item.IdX,
    PackagingId: item.PackagingId ?? '',
    PackagingNameEn: item.PackagingNameEn,
    PackagingNameEs: item.PackagingNameEs,
    HeadingId: item.HeadingId ?? '',
    HeadingName: item.HeadingName ?? '',
    CommodityId: item.CommodityId ?? '',
    Serial: item.Serial ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: NumberFormater.setNum(item.Weight), 
    Quantity: item.QuantityPlanning,
    CertifiedQuantity: item.Quantity ?? 0,
  };
  this.ImdgList = item?.ImdgJson ?? [];

  this.FgExternPackaging = item.FgExternPackaging ? true : false;
  this.FgPackaging = item.PackagingId ? true : false;
  this.$v.Packaging.$touch();
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitBillOfLadingPackagingId) {
          this.$emit('UpdateList', this.PackagingList.map(Packaging => {
            if (Packaging.VisitBillOfLadingPackagingId == item.VisitBillOfLadingPackagingId) {
              return {
                ...Packaging,
                ImdgJson: Packaging?.ImdgJson?.map(e => {
                  return {
                    ...e,
                    Status: 0,
                  }
                }),
                Status: 0,
              };
            } else {
              return Packaging;
            }
          }));
        }else{
          this.$emit('UpdateList', this.PackagingList.filter(e => e.IdX != item.IdX));
        }
        this.$v.PackagingList.$touch();
      }
    });
}

function onChangeCommodityId(event) {
  this.Packaging.CommodityId = event.target.value;
  if (this.Packaging.CommodityId) {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let CommodityData = this.CommodityList.find(item => item.CommodityId == this.Packaging.CommodityId);
    this.$v.Packaging.HeadingName.$model = CommodityData?.[`HeadingName${_lang}`] ?? '';
    this.$v.Packaging.HeadingId.$model = CommodityData?.HeadingId ?? '';
  }else{
    this.$v.Packaging.HeadingId.$model = '';
  }
}

function ValidatePackagingContainer(PackagingId) {
  return PackagingId.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
}

function Clear(All) {
  this.CleanInformation = true;
  this.TabIndex = 0;
  this.Packaging = {
    Id: '',
    PackagingId: '',
    PackagingNameEn: '',
    PackagingNameEs: '',
    HeadingId: '',
    HeadingName: '',
    CommodityId: '',
    Serial: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    SquareMeters: 0,
    Weight: 0, 
    Quantity: 0,
    CertifiedQuantity: 0,
    Volumen: 0,
  };
  this.ImdgList = [];
  this.FgExternPackaging = false;
  this.FgPackaging = false;
  if (All) {
    this.PackagingSelectList = [];
  }
  setTimeout(() => {
    this.$v.$reset();
    this.CleanInformation = false;
  }, 1);
}

//computed
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingSelectList.filter(item => item.PackagingId != process.env.VUE_APP_PACKAGING_ID_CONTAINER).forEach((e) => {
    chart.push({
      value: e.PackagingId,
      label: e['PackagingName'+_lang],
    })
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function TotalBl() {
  let Total = {
    Weight: 0,
    Volumen: 0,
    Quantity: 0,
  };
  this.PackagingList.filter(item => item.Status == 1).forEach(item => {
    Total.Weight += item.Weight;
    Total.Volumen += item.Volumen;
    Total.Quantity += item.QuantityPlanning;
  })
  this.BasicData.Weight = Total.Weight;
  this.BasicData.Volumen = Total.Volumen;
  this.BasicData.Quantity = Total.Quantity;

  return Total;
}

function Meters() {
  return {
    SquareMeters: NumberFormater.setNum(this.Packaging.Length) * NumberFormater.setNum(this.Packaging.Width),
    Volumen: NumberFormater.setNum(this.Packaging.Length) * NumberFormater.setNum(this.Packaging.Width) * NumberFormater.setNum(this.Packaging.Height),
  }
}

function computedListPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.PackagingList.filter(item => item.Status != 0);
  return List.map((item, index) => {
    let imdgData = item?.ImdgJson?.[0] ?? {};
    return {
      ...item,
      Nro: index+1,
      PackagingName: item.PackagingNameEs || item.PackagingNameEn ? item['PackagingName'+_lang] ?? '' : this.$t('label.BreakBulk'),
      Serial: item.Serial ? item.Serial : 'N/A',
      ComputedQuantityPlanning: NumberFormater.formatNumber(item.QuantityPlanning, 2),
      Quantity: item.Quantity ?? 0,
      ComputedQuantity: NumberFormater.formatNumber(item.Quantity, 2),
      ComputedWeight: NumberFormater.formatNumber(item.Weight, 2),
      ComputedVolume: NumberFormater.formatNumber(item.Volumen, 2),
      SquareMeters: NumberFormater.formatNumber(item.SquareMeters, 2),
      ImdgCod: imdgData?.ImdgCode ?? 'N/A',
      PackagingGroupCod: imdgData?.PackagingGroupCode ?? 'N/A',
      UnNumber: imdgData?.UnNumber ?? 'N/A',
    }
  });
}

function packagingfields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width:45px', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;' },
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;' },
    { key: 'ComputedQuantityPlanning', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
    { key: 'ComputedQuantity', label: this.$t('label.CertifiedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
    { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 180px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center" },
    { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;' },
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
    { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
    { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'min-width: 180px;', _classes:"text-center" },
  ];
}

export default {
  name: 'packaging-tab',
  components: {
    ImdgTab,
    Money
  },
  data,
  props: {
    PackagingList: {
      type: Array,
      required: true,
      default: () => [],
    },
    BasicData: {
      type: Object,
      required: true,
      default: () => {},
    },
    CommodityList: {
      type: Array,
      default: () => [],
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations(null, null, null, this.FgPackaging, this.Packaging.CertifiedQuantity);
  },
  methods: {
    getService,
    SubmitPackaging,
    EditPackaging,
    DeletePackaging,
    onChangeCommodityId,
    ValidatePackagingContainer,
    Clear,
  },
  computed:{
    PackagingOptions,
    CommodityOptions,
    TotalBl,
    Meters,
    computedListPackaging,
    packagingfields,
  },
  watch: {
    showModal: function (Newval) {
      this.$v.Packaging.$reset();
      if (Newval && this.isEdit) {
        this.$v.PackagingList.$touch();
      }else{
        this.TabIndex = 0;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.$emit('Loading', true);
        this.getService();
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.PackagingList.$touch();
      }
    }
  }
};
</script>