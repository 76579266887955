<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
        <CCol sm="11" lg="12">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right"
              for="type"
            >
              {{ $t("label.role") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <v-select
                id="v-select-small"
                :placeholder="$t('label.select')"
                :options="computedRoles"
                v-model.trim="StowageRoleId"
                :value.sync="StowageRoleId"
                :disabled="isArrEmpty(rolesOptions)"
                @input="getResponsibles"
                :class="isValidSelectRol ? 'select--correct' : (verifyRol ? 'select--error' : '') "

              />
            </div>
            <div class="col-sm-12 col-lg-3 mt-0" v-if=" (StowageRoleId == null || StowageRoleId == '') && verifyRol"></div>
            <div class="col-sm-12 col-lg-8 text-invalid-feedback mt-0" v-if=" (StowageRoleId == null || StowageRoleId == '') && verifyRol">
              {{$t('label.required')}}
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="12">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right"
              for="type"
            >
              {{ $t("label.device") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <v-select
                id="v-select-small"
                :placeholder="$t('label.select')"
                :options="computedDevice"
                :is-valid="hasError($v.DeviceId)"
                v-model.trim="$v.DeviceId.$model"
                :value.sync="DeviceId"  
                :disabled="isArrEmpty(deviceOptions)"  
                :invalid-feedback="$t('label.required')"
                :class="isValidSelectDevice ? 'select--correct' : (verifyDevice ? 'select--error' : '') "

              />
            </div>
            <div class="col-sm-12 col-lg-3 mt-0" v-if=" (DeviceId == null || DeviceId == '') && verifyDevice"></div>
            <div class="col-sm-12 col-lg-8 text-invalid-feedback mt-0" v-if=" (DeviceId == null || DeviceId == '') && verifyDevice">
              {{$t('label.required')}}
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="12">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right "
              for="type"
            >
              {{ $t("label.responsible") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <v-select
                id="v-select-small"
                :placeholder="$t('label.select')"
                :options="computedResponsable"
                v-model.trim="StowagePlanningRoleDetId"
                :value.sync="StowagePlanningRoleDetId"  
                :disabled="isArrEmpty(responsableOptions)"  
                @input="getLocation"
                :invalid-feedback="$t('label.required')"
                :class="isValidSelectResponsible ? 'select--correct' : (verifyResponsible ? 'select--error' : '') "

              />
            </div>
            <div class="col-sm-12 col-lg-3 mt-0" v-if=" (StowagePlanningRoleDetId == null || StowagePlanningRoleDetId == '') && verifyResponsible"></div>
            <div class="col-sm-12 col-lg-8 text-invalid-feedback mt-0" v-if=" (StowagePlanningRoleDetId== null || StowagePlanningRoleDetId == '') && verifyResponsible">
              {{$t('label.required')}}
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="12">
          <CInput
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :label="$t('label.location')"
            addLabelClasses="text-right"
            v-model.trim="StowageUbicationName"
            :value.sync="StowageUbicationName"
            :is-valid="hasError($v.StowageUbicationName)" 
            :invalid-feedback="errorMessage($v.StowageUbicationName)"
            size="sm"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right required"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
            size="sm"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="add" :disabled="isSubmit" @click="changeStatus()" size="sm">
          <CIcon name="checkAlt"/>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton color="wipe" @click="closeModal" :disabled="isSubmit" size="sm">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import AsignarValidations from "@/_validations/planificacion-estiba/asignarDispositivoValidations";

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    isEdit: false,
    verifyRol: false,
    verifyDevice: false,
    verifyResponsible: false,

    rolesOptions: [],
    deviceOptions: [],
    responsableOptions: [],

    titleModal: '',
    DeviceId: '',
    StowageRoleId: '',
    StowagePlanningRoleDetId: '',
    StowageUbicationId: '',
    StowageUbicationName: '',
    rolName: '',
    Status: 1,
    FgActStowagePlanningUser: 1,
  };
}
  //methods
  function closeModal() {
    this.refreshComponent();
    this.$emit('child-refresh', true);
    this.showModal=false;
  }

  function isArrEmpty(arr) {
    return arr.length == 0;
  }

  function getRoles() {
  this.loadingOverlay = true;
  this.rolesOptions = [];

  let StowagePlanningId = this.planificacionId;
  this.$http
    .ejecutar("GET", "StowagePlanningRoleDevice-list", {
      StowagePlanningId: StowagePlanningId,
    })
    .then((response) => {
      this.rolesOptions = [...response.data.data];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

function getDevices() {
  this.loadingOverlay = true;

  let StowagePlanningId = this.planificacionId;
  this.$http
    .ejecutar("GET", "StowagePlanningDeviceList-by-StowagePlanningId", {
      StowagePlanningId: StowagePlanningId,
    })
    .then((response) => {
      this.deviceOptions = response.data.data || [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

function getResponsibles(role) {
  this.loadingOverlay = true;
  this.responsableOptions = [];

  if (role != null) {
    this.StowagePlanningRoleDetId = '';
    let StowagePlanningId = this.planificacionId;
    this.$http
      .ejecutar("GET", "StowagePlanningRoleDetail-by-StowageRoleId", {
        StowagePlanningId: StowagePlanningId,
        StowageRoleId: role.value,
      })
      .then((response) => {
        this.responsableOptions = response.data.data || [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.loadingOverlay = false;
      });
  } else {
    this.loadingOverlay = false;
  }
}

function getLocation(event) {
  if (event) {
    this.StowageUbicationName = event.StowageUbicationName;
    this.StowageUbicationId = event.StowageUbicationId;
  }  
}
  function refreshComponent() {
    this.Status = 1;
    this.FgActStowagePlanningUser = 1;
    this.isEdit = false;
    this.verifyRol = false;
    this.verifyDevice = false;
    this.verifyResponsible = false;
    this.isSubmit = false;
    this.isEdit = false;
    this.verifyRol = false;
    this.verifyDevice = false;
    this.verifyResponsible = false;

    this.titleModal = '';
    this.DeviceId = '';
    this.StowageRoleId = '';
    this.StowagePlanningRoleDetId = '';
    this.StowageUbicationId = '';
    this.StowageUbicationName = '';
    this.rolName = '';

this.$v.$reset();
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.$touch();

      if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.verifyRol = true;
        this.verifyDevice = true;
        this.verifyResponsible = true;
        throw this.$t('label.errorsPleaseCheck');
      }

      const DeviceUserJson = {
        StowagePlanningRoleDetId: this.StowagePlanningRoleDetId.value,
        DeviceId: this.DeviceId.value,
      };
      
      if(this.isEdit){
        DeviceUserJson.StowagePlanningUserId = this.StowagePlanningUserId;
        DeviceUserJson.Status = this.Status;
      };

      let method = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'DeviceStowagePlanningUser-update':'DeviceStowagePlanningUser-insert';

      await this.$http
        .ejecutar (method, ruta, DeviceUserJson, { root: 'DeviceUserJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.closeModal();
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  } 

  function statusSelectColor(){
    return this.Status === 1;
  }

  function changeStatus() {
    if(this.FgActStowagePlanningUser !== this.Status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.rolName}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }
  
  //computeds
  function computedRoles() {
    if (this.rolesOptions.length > 0) {
      return this.rolesOptions.map((item) => {
        return {
          value: item.StowageRoleId,
          label: item.StowageRoleName,
        };
      });
    }
  }

  function computedDevice() {
    if (this.deviceOptions.length > 0) {
      return this.deviceOptions.map((item) => {
        return {
          value: item.DeviceId,
          label: item.DeviceName,
        };
      });
    }
  }

  function computedResponsable() {
    if (this.responsableOptions.length > 0) {
      return this.responsableOptions.map((item) => {
        return {
          value: item.StowagePlanningRoleDetId,
          label: item.LastName+', '+item.Name+' ('+item.Login+')',
          StowageUbicationId: item.StowageUbicationId,
          StowageUbicationName: item.StowageUbicationName
        };
      });
    }
  }

  function isValidSelectRol() {
    if (this.StowageRoleId === '' || this.StowageRoleId === null) {
      return false;
    } else {
      return true;
    }
  }

  function isValidSelectDevice() {
    if (this.DeviceId === '' || this.DeviceId === null) {
      return false;
    } else {
      return true;
    }
  }

  function isValidSelectResponsible() {
    if (this.StowagePlanningRoleDetId === '' || this.StowagePlanningRoleDetId === null) {
      return false;
    } else {
      return true;
    }
  }
  function selectOptions(){
    return [
        { 
          value: 1, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: 0, 
          label: this.$t('label.INACTIVO')
        }
      ];
  }

export default {
  name: 'modal-device-assign',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral],
  directives: UpperCase,
  validations() {
    return AsignarValidations();
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true; 
        this.getRoles();
        this.getDevices();
        if (this.modal==true) {
          this.refreshComponent();
          this.isEdit  = false;
          this.titleModal = this.$t('label.nueva')+' '+this.$t('label.asignDevice');

        } else {
          this.isEdit  = true;
          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.asignDevice')+': '+this.modal.StowageRoleName;

          this.StowagePlanningUserId = this.modal.StowagePlanningUserId ? this.modal.StowagePlanningUserId : '';
          this.StowageRoleId = {
            value: this.modal.StowageRoleId,
            label: this.modal.StowageRoleName,
          }
          await this.getResponsibles(this.StowageRoleId);
          this.DeviceId = {
            value: this.modal.DeviceId,
            label: this.modal.DeviceName,
          };

          this.StowagePlanningRoleDetId = {
            value: this.modal.StowagePlanningRoleDetId,
            label: this.modal.RoleDetLastName+', '+this.modal.ResponsableName+' ('+this.modal.RoleDetLogin+')',
            StowageUbicationId: this.modal.StowageUbicationId,
            StowageUbicationName: this.modal.StowageUbicationName
          }
          this.StowageUbicationName = this.modal.StowageUbicationName
          
          this.Status = this.modal.FgActStowagePlanningUser ? 1 : 0;
          this.FgActStowagePlanningUser = this.modal.FgActStowagePlanningUser ? 1 : 0;
          this.rolName =  this.modal.StowageRoleName ? this.modal.StowageRoleName : '';
          this.$v.$touch();
          this.verifyRol = true;
          this.verifyDevice = true;
          this.verifyResponsible = true;

        }
        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    isArrEmpty,
    getResponsibles,
    getRoles,
    getDevices,
    getLocation,
    refreshComponent,
    submit,
    statusSelectColor,
    changeStatus,
  },
  computed: {
    computedRoles,
    computedResponsable,
    computedDevice,
    isValidSelectRol,
    isValidSelectDevice,
    isValidSelectResponsible,
    selectOptions,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      collapsePlanificacion: (state) => state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      tabIndex: (state) => state.planificacionestiba.tabIndex,
      user: (state) => state.auth.user,      
    }),
  },
};
</script>
<style lang="scss">
  .select--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }

</style>
