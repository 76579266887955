import { required } from 'vuelidate/lib/validators';

const verifyNumber = (value) => {
  return value === "0,00" || value === "-0,00" ? false : true;
}

export default (Validations) => {
  let ValidateQuantity = Validations?.FgGeneralCargo ? { 
    required(value){
      return  Number.parseFloat(value.replace(".", "").replace(",", ".")) > 0
    }, 
    maxValue(value){ 
      return  Number.parseFloat(value.replace(".", "").replace(",", ".")) <= (Validations?.PackagingQuantity)
    }, 
    ValidateTotalPackaging(value) {
      if (Validations?.FgTotalWeight) {
        return Number.parseFloat(value.replace(".", "").replace(",", ".")) == Number.parseFloat(Validations?.PackagingQuantity).toFixed(2);
      }else{
        return true;
      }
    }} : {};
  let ValidatePackaging = Validations?.FgGeneralCargo ? { required } : {};
  let ValidateWeight = Validations?.FgGeneralCargo ? { required, verifyNumber, notAvailableTotal (value) {
    return Number.parseFloat(value.replace(".", "").replace(",", ".")) <= Number.parseFloat(Validations?.totalAvailable).toFixed(2)
  }, notAvailableBl(value) {
    return Number.parseFloat(value.replace(".", "").replace(",", ".")) <= Number.parseFloat(Validations?.blAvailable).toFixed(2)
  }, ValidateTotalPackaging(value) {
    if (Validations?.FgTotalQuantity) {
      return Number.parseFloat(value.replace(".", "").replace(",", ".")) == Number.parseFloat(Validations?.PackagingWeight).toFixed(2);
    }else{
      return true;
    }
  }, notAvailableDetail(value) {
    return Number.parseFloat(value.replace(".", "").replace(",", ".")) <= Number.parseFloat(Validations?.PackagingWeight).toFixed(2)
  }} : { required, verifyNumber, notAvailableTotal (value) {
    return Number.parseFloat(value.replace(".", "").replace(",", ".")) <= Number.parseFloat(Validations?.totalAvailable).toFixed(2)
  }, notAvailableBl(value) {
    return Number.parseFloat(value.replace(".", "").replace(",", ".")) <= Number.parseFloat(Validations?.blAvailable).toFixed(2)
  }};
  return {
    planned: {
      StowagePlanningBlHoldId: {},
      StowagePlanningBillOfLadingId: { required }, 
      StowagePlanningBillOfLadingPackagingId: ValidatePackaging,
      VesselHoldId: {},
      UnitMeasureWeightBlHoldId: { },
      Color: { required },
      WeightAsigned: ValidateWeight,
      QuantityAsigned: ValidateQuantity,
      Status: {},
    },
  }
}