<template>
    <div>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.PlannedVsCurrentStatus") }}</span>
                </div>
                <div style="width: 100%;padding:10px;">
                    <div class="d-flex" style="margin-top:10px;">
                        <div class="p-2 flex-fill" style="background:#EDEBEB;margin-left:5px;margin-right: 5px;border-radius: 5px;width: 50%;">
                            <div class="w-100" style="text-align:left;">
                                <h6>{{ $t('label.ShippedEstimatedVsPlanned') }}</h6>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;font-weight: bold;">
                                <div class="col-1"></div>
                                <div class="col-2">
                                    <b>{{ $t('label.tonPlannif') }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ $t('label.Gamela')+'S' }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ $t('label.tonEstim') }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ $t('label.ToDischarge') }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ $t('label.Heavy') }}</b>
                                </div>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;" v-for="(item, index) in operationHold" :key="index">
                                <div class="col-1">
                                    {{ item.VesselHoldName }}
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(item.PlannedTon) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(item.CantImplement) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(item.TonPlanifEstimated) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(item.RemainingTonEstimated) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(item.WeighedTonEstimated) }}</b>
                                </div>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;background-color: #AFAFAF;font-weight: bold;color:#FFF;">
                                <div class="col-1" style="background: #EDEBEB"></div>
                                <div class="col-2">
                                    <b>{{ valuemoney(totalOperations.PlannedTonTotal) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(totalOperations.CantImplementTotal) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(totalOperations.TonPlanifEstimatedTotal) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(totalOperations.RemainingTonEstimatedTotal) }}</b>
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(totalOperations.WeighedTonEstimatedTotal) }}</b>
                                </div>
                            </div>
                            <div class="row" style="margin-left:1px;margin-right: 1px;margin-top:10px;">
                                <div v-for="(item, index) in graficaOperationHold" :class="item.class" :key="index" :style="item.bandera ? '' : 'margin-bottom:2px;padding-right: 2px;padding-left: 2px;'">
                                    <div v-if="!item.bandera">
                                        <h6>{{ item.VesselHoldName }}</h6>
                                        <ColumnsDistruibuid
                                            :colors="['#013565','#EC631B']"
                                            :height="'100px'"
                                            :categories="item.categoriesEstimated"
                                            :seriesGrafica="item.seriesEstimated"
                                            :legendsTooltip="item.legendEstimated"
                                        ></ColumnsDistruibuid>
                                    </div>
                                </div>
                                <div class="col-4 d-flex align-items-center px-0">
                                    <div style="background:#FEEB40;padding-top:0.2rem;padding-left:1rem;padding-right:1rem;border-radius: 5px;width:100%;">
                                        <div class="d-flex" style="width:100%">
                                            <div class="flex-fill" style="width: 80px;">
                                                <h6 style="margin-bottom: 0.2rem;"><b>TON</b></h6>
                                                <h6 style="margin-bottom: 0.2rem;"><b>{{ $t('label.PerGamela') }}</b></h6>
                                            </div>
                                            <div class="flex-fill d-flex align-items-center">
                                                <h2 style="margin-bottom: 0px;">{{ HoldSituationJson.TonPerImplement ? HoldSituationJson.TonPerImplement : 0 }}</h2>
                                                <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-peso"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center" style="margin-right:5px;margin-left:5px;">
                                <div class="flex-fill" >
                                    <strong style="font-size:13px;font-weight: bold;"><span>{{ $t('label.ValuesExpressedInMt') }}</span></strong>
                                </div>
                                <div class="flex-fill" style="text-align:right;font-weight: bold;">
                                    <b style="font-size:13px;"><span>TON X {{ $t('label.hour') }}: {{ valuemoney(HoldSituationJson.TonXHour) }}</span></b>
                                    <b style="font-size:13px;margin-left:10px;"><span>GM X {{ $t('label.hour') }}: {{ valuemoney(HoldSituationJson.CantImplementXHour) }}</span></b>
                                </div>
                            </div>
                        </div>
                        <div class="p-2 flex-fill" style="background:#FFECE7;margin-left:5px;margin-right: 5px;border-radius: 5px;width: 50%;">
                            <div class="w-100" style="text-align:left;">
                                <h6>{{ $t('label.PlannedVsCertificate') }}</h6>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;font-weight: bold;">
                                <div class="col-1"></div>
                                <div class="col-2">
                                    <b>{{ $t('label.tonPlannif') }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ $t('label.Gamela')+'S' }}</b>
                                </div>
                                <div class="col-3">
                                    <b>TON {{ $t('label.Certificate') }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ $t('label.ToDischarge') }}</b>
                                </div>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;" v-for="(item, index) in operationHold" :key="index">
                                <div class="col-1">
                                    {{ item.VesselHoldName }}
                                </div>
                                <div class="col-2">
                                    <b>{{ valuemoney(item.PlannedTon) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(item.CantImplementCertificate) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(item.CertificateTon) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(item.RemainingTonCertificate) }}</b>
                                </div>
                            </div>
                            <div class="row filas-especiales" style="text-align:center;margin-left:1px;margin-right: 1px;background: #EA641A;color:#FFF;font-weight: bold;">
                                <div class="col-1" style="background: #FFECE7"></div>
                                <div class="col-2">
                                    <b>{{ valuemoney(totalOperations.PlannedTonTotal) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(totalOperations.CantImplementCertificateTotal) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(totalOperations.CertificateTonTotal) }}</b>
                                </div>
                                <div class="col-3">
                                    <b>{{ valuemoney(totalOperations.RemainingTonCertificateTotal) }}</b>
                                </div>
                            </div>
                            <div class="row" style="margin-left:1px;margin-right: 1px;margin-top:5px;">
                                <div v-for="(item, index) in graficaOperationHold" :class="item.class" :key="index" :style="item.bandera ? '' : 'margin-bottom:2px;padding-right: 2px;padding-left: 2px;'">
                                    <div v-if="!item.bandera">
                                        <h6>{{ item.VesselHoldName }}</h6>
                                        <ColumnsDistruibuid
                                            :colors="['#013565','#EC631B']"
                                            :height="'100px'"
                                            :categories="item.categoriesCertificated"
                                            :seriesGrafica="item.seriesCertificated"
                                            :legendsTooltip="item.legendCertificated"
                                        ></ColumnsDistruibuid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ColumnsDistruibuid from '@/pages/situacion-operativa-actual/components/ColumnsDistruibuid';
    //methods
    function valuemoney(value){
      if(value !== null){
        let modifiedValue = parseFloat(value).toFixed(2);
        var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
        return new Intl.NumberFormat("de-DE", options).format(modifiedValue);
      }
      return "0,00";
    }
    //computed
    function graficaOperationHold(){
        let data = [];
        this.myDataHold.map(async(item,index) => {
            let PlannedTon = item.PlannedTon  === null ? 0 : item.PlannedTon;
            let EmbarkedTon = item.EmbarkedTon  === null ? 0 : item.EmbarkedTon;
            let CertificateTon = item.CertificateTon === null ? 0 : item.CertificateTon;
            let categoriesPlanned = [
                this.valuemoney(PlannedTon),
                this.valuemoney(EmbarkedTon),
            ]
            let seriesPlanned = [
                PlannedTon,EmbarkedTon
            ]
            let categoriesEstimated = [
                this.valuemoney(PlannedTon),
                this.valuemoney(EmbarkedTon),
            ]
            let seriesEstimated = [
                PlannedTon,EmbarkedTon
            ]
            let legendEstimated = [
                this.$t('label.planned'),
                this.$t('label.Loaded'),
            ]
            let categoriesCertificated = [
                this.valuemoney(PlannedTon),
                this.valuemoney(CertificateTon),
            ]
            let seriesCertificated = [
                PlannedTon,CertificateTon
            ]
            let legendCertificated = [
                this.$t('label.planned'),
                this.$t('label.Certificate'),
            ]
            data.push({
                ...item,
                categoriesPlanned,
                seriesPlanned,
                categoriesEstimated,
                seriesEstimated,
                legendEstimated,
                categoriesCertificated,
                seriesCertificated,
                legendCertificated,
                bandera: false,
                class: 'col-4'
            })

            let i = index+1;
            if((i % 3) === 0){
                data.push({
                    class: 'w-100',
                    bandera: true,
                })
            }
        })
        return data;
    }
    function operationHold(){
        let data = [];
        this.myDataHold.map(async(item,index) => {
            data.push({
                ...item,
            })
        })
        return data;
    }
    function totalOperations(){
        let PlannedTonTotal = 0;
        let PlannedCantImplementTotal = 0;
        let CapacXImplementTotal = 0;
        let RemainingTonEstimatedTotal = 0;
        let TonPlanifEstimatedTotal = 0;
        let RemainingTonTotal = 0;
        let EmbarkedTonTotal = 0;
        let WeighedTonEstimatedTotal = 0;
        let CantImplementTotal = 0;
        let CantImplementCertificateTotal = 0;
        let CertificateTonTotal = 0;
        let RemainingTonCertificateTotal = 0;
        this.myDataHold.map(async(item,index) => {
            PlannedTonTotal = PlannedTonTotal + item.PlannedTon;
            TonPlanifEstimatedTotal = TonPlanifEstimatedTotal + item.TonPlanifEstimated;
            RemainingTonEstimatedTotal = RemainingTonEstimatedTotal + item.RemainingTonEstimated;
            PlannedCantImplementTotal = PlannedCantImplementTotal + item.PlannedCantImplement;
            CapacXImplementTotal = CapacXImplementTotal + item.CapacXImplement;
            WeighedTonEstimatedTotal = WeighedTonEstimatedTotal + item.WeighedTonEstimated;
            RemainingTonTotal = RemainingTonTotal + item.RemainingTon;
            EmbarkedTonTotal = EmbarkedTonTotal + item.EmbarkedTon;
            CantImplementCertificateTotal = CantImplementCertificateTotal + item.CantImplementCertificate;
            CantImplementTotal = CantImplementTotal + item.CantImplement;
            CertificateTonTotal = CertificateTonTotal + item.CertificateTon;
            RemainingTonCertificateTotal = RemainingTonCertificateTotal + item.RemainingTonCertificate;
        });

        return {
            PlannedTonTotal: PlannedTonTotal,
            PlannedCantImplementTotal: PlannedCantImplementTotal,
            TonPlanifEstimatedTotal: TonPlanifEstimatedTotal,
            CapacXImplementTotal: CapacXImplementTotal,
            RemainingTonEstimatedTotal: RemainingTonEstimatedTotal,
            RemainingTonTotal: RemainingTonTotal,
            EmbarkedTonTotal: EmbarkedTonTotal,
            WeighedTonEstimatedTotal: WeighedTonEstimatedTotal,
            CantImplementTotal: CantImplementTotal,
            CantImplementCertificateTotal: CantImplementCertificateTotal,
            CertificateTonTotal: CertificateTonTotal,
            RemainingTonCertificateTotal: RemainingTonCertificateTotal
        }
    }
    function myDataHold() {
        return this.HoldSituationJson?.HoldJson ? this.HoldSituationJson?.HoldJson : [];
    }
    export default {
        name:'HoldSituationBl',
        props:{
            HoldSituationJson:{
                type:Object,
                default: function () {
                    return {};
                },
            },
        },
        components:{
            ColumnsDistruibuid
        },
        methods:{
            valuemoney
        },
        computed:{
            myDataHold,
            operationHold,
            totalOperations,
            graficaOperationHold,
        }
    }
</script>

<style lang="scss">
    .filas-especiales{
        .col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
</style>