<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" /> 
        <CRow>
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mb-3 mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.transactions') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow class="m-0">
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                />
            </CCol>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                    :label="$t('label.hold')"
                    :options="holdListFormatted"
                    v-model.trim="VesselHoldId"
                    :value.sync="VesselHoldId"
                />
            </CCol>
        </CRow>
        <CRow class="m-0">
            <CCol sm="12" lg="3">
              <div class="form-group form-row ">
                <label class="col-sm-12 col-lg-3 col-form-label text-right">{{$t('label.date')}}</label>
                <div class="input-group col-sm-12 col-lg-9">
                    <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="searchFilterDate.fromDay"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                    >
                    <template #icon-calendar>
                        <div style="display: none"></div>
                    </template>
                </vue-datepicker>
                </div>
              </div>
            </CCol>
            <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="12" lg="4">
              <CRow>
                <div class="input-group col-sm-12 col-lg-7 mb-3">
                    <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="searchFilterDate.toDay"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                    >
                    <template #icon-calendar>
                        <div style="display: none"></div>
                    </template>
                    </vue-datepicker>
                </div>
                <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field" style="text-align: right;">
                    <CButton
                        color="watch"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                        @click="getTransactionsList()"
                        >
                        <CIcon name="cil-search" />
                    </CButton>
                    <CButton
                        color="wipe"
                        class="justify-content-end"
                        size="sm"
                        v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                        @click="refreshComponent()"
                        >
                        <CIcon name="cil-brush-alt" />
                    </CButton>
                </CCol>
              </CRow>
            </CCol>
        </CRow>
        <CRow class="m-0">    
            <CCol sm="12" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="5" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>  
        </CRow>
        <CRow class="m-0">          
            <CCol sm="12" lg="12" xl="12" class="  mb-2">
                <div class="flex-grow-1 bd-highlight">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    //data
    function data() {
        return {
            SizeChanged:50,
            textSearch:'',
            gridOptions: null,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            VisitId: '',
            rows: [],
            loadingOverlay: false,
            previousDate: new Date(),
            laterDate: new Date(),
            nulo: '', 
            VisitCraneId: '',
            VesselHoldId: '',
            craneOptions: [],
            holdOptions: [],
            CraneAlias: '', 
        }
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    async function onBtnExport(valor) {
        //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.transactionReport'),valor, this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }

    async function getTransactionsList(){
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);
        
        let ReportJson = {
            VisitId: this.Visit,
            DateStart:dateStart,
            DateEnd:dateEnd,
            VisitCraneId:this.VisitCraneId,
            VesselHoldId: this.VesselHoldId
        }

        this.$http.ejecutar('POST', 'VisitGeneralCargoTransactionReport', ReportJson, { root: 'ReportJson' })
        .then(response => {
            this.rows = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            this.loadingOverlay = false;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
            .then(response => {
                this.craneOptions = response.data.data;
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }
    function getHoldList() {
        this.$http.get("VisitVesselHold-list", { VisitId: this.Visit })
        .then(response => {      
            let listado = response.data.data;
            if (listado.length > 0) {
                this.VesselHoldId = listado[0].VesselHoldId;      
                this.holdOptions = listado;
            }            
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }  

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.VisitCraneId= '';
        this.craneName= '';
        this.vesselCraneId= '';
        await this.getDateRange();
        await this.getCraneList();
        await this.getHoldList();
        await this.getTransactionsList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                lockVisible: true,
                lockPosition: true,
                minWidth: 70,
                //pinned: 'left',
            },
            {
                field: "BlNro",
                headerName: this.$t('label.bl'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "CraneAlias",
                headerName: this.$t('label.crane'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "VesselUbicationName",
                headerName: this.$t('label.localizacion'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "VesselHoldName",
                headerName: this.$t('label.hold'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "HatchCoverName",
                headerName: this.$t('label.hatchCover'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "PackagingName",
                headerName: this.$t('label.packaging'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "Quantity",
                headerName: this.$t('label.quantity'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            },
            {
                field: "StowageEventName",
                headerName: this.$t('label.transactionType'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.device'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.transactionDate'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.user'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "TransactionRegDate",
                headerName: this.$t('label.registerDate'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "Status",
                headerName: this.$t('label.status'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
                cellStyle: {top: '28%'},
                cellRenderer: params => {
                    let textStatus = '';
                    if(params.value == 'ACTIVO' || params.value == 'ACTIVE')
                        textStatus = `<h6 style="color:#3c4b64; font-size:75%"><b>&nbsp; ${params.value}</b></h6>`
                    if(params.value == 'INACTIVO' || params.value == 'INACTIVE')
                        textStatus = `<h6 style="color:#e55353; font-size:75%"><b>&nbsp; ${params.value}</b></h6>`

                    return textStatus === '' ? '' : textStatus;
                }
            },
        ]
        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            if(this.VisitCraneId !== '' || this.VisitCraneId !== null)
                this.CraneAlias = this.rows[0].CraneAlias;
                let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            this.rows.map(function(item, index){
                computedItems.push({
                    IdX: item.IdX ?? 'N/A',
                    BlNro: item.BlNro ?? 'N/A',
                    CraneAlias: item.CraneAlias ?? 'N/A',
                    VesselUbicationName: item[`VesselUbicationName${_lang}`] ?? 'N/A',
                    VesselHoldName: item.VesselHoldName ?? 'N/A',
                    HatchCoverName: item.HatchCoverName ?? 'N/A',
                    Quantity: item.Quantity ?? 0,
                    PackagingName: item.PackagingJson.length > 0 ? item.PackagingJson.map(Packaging => Packaging[`PackagingName${_lang}`].trim()).join(', '): 'N/A',
                    StowageEventName: item[`StowageEventName${_lang}`] ?? 'N/A',
                    DeviceCode: item.DeviceCode ?? 'N/A',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
                    TransactionRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
                    TransaLogin: item.TransaLogin ??  'N/A',
                    Status: item.Status ?? 'N/A',
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }
    function holdListFormatted(){
        let hold = this.holdOptions.map((item) => Object.assign({}, item, {
            value: item.VesselHoldId,
            label: item.VesselHoldName,
        }));

        hold.unshift({value:'',label: this.$t('label.select')})
        this.VesselHoldId = '';
        return hold;
    }

    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }

    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }
    export default {
        name:'index-transactions-general-cargo',
        props: {currentModuleId: Boolean, loading: Boolean},
        data,
        beforeMount,
        methods:{
            onBtnExport,
            onGridReady,
            getTransactionsList,
            validateDateRange,
            getCraneList,
            filterCrane,
            getHoldList,
            refreshComponent,
            getDateRange
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                user: (state) => state.auth.user,
                itinerarySelected: state => state.visitas.itinerarySelected,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
            }),
            craneListFormatted,
            holdListFormatted
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        watch:{
            textSearch,
            SizeChanged,    
            currentModuleId: async function (newValue) {
                if (newValue) {
                    this.SizeChanged = 50;
                    this.VisitId = this.Visit;
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        },
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>