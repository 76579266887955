<template>
    <div class="container-fluid">
        <CRow>
            <CCol sm="6">
                <CRow>
                    <CCol sm="6" class="d-flex align-items-center">
                        <div class="cajita-no">
                            {{$t('label.benningOperation')}}
                        </div>
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center">
                        <div class="cajita-inicio">
                            {{dateCommence}}
                        </div>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="6">
                <CRow>
                    <CCol sm="6" class="d-flex align-items-center">
                        <div class="cajita-no">
                            {{$t('label.endOperation')}}
                        </div>
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center">
                        <div class="cajita-fin">
                            {{dateFinnished}}
                        </div>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="12" style="margin-top:20px;" v-for="(item, index) in formatedItemsInformation" :key="index">
                <div class="d-flex justify-content-center" style="width: 100%;">
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding" style="height:100%;">
                                <div class="cajita-sin-relleno d-flex align-items-center justify-content-center" style="height:100%;">
                                    {{item.CraneName+' '+'('+item.CraneAlias+')'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde align-items-center justify-content-center" style="height:50px">
                                    Commenced op.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div :class="infoCommence(item)"  style="height:100%;">
                                    {{item.DateFrom}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde" style="height:50px">
                                    Last Move.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div class="cajita-sin-borde d-flex align-items-center justify-content-center"  style="height:100%;">
                                    {{item.DateTo}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde" style="height:50px">
                                    Completed op.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div :class="infoFinnished(item)"  style="height:100%;">
                                    {{item.DateFinnishedOperation}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde" style="height:50px">
                                    Operation time.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div class="cajita-sin-borde d-flex align-items-center justify-content-center"  style="height:100%;">
                                    {{item.TotalHourOperation}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde" style="height:50px">
                                    Hours Worked.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div class="cajita-sin-borde d-flex align-items-center justify-content-center"  style="height:100%;">
                                    {{item.TotalHourWorked}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column" style="height:100%;">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde" style="height:50px">
                                    Hours Stopped.
                                </div>
                            </div>
                            <div class="sin-padding" style="height:100%;">
                                <div class="cajita-sin-borde d-flex align-items-center justify-content-center"  style="height:100%;">
                                    {{item.TotalHourDetection}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="d-flex flex-column">
                            <div class="sin-padding">
                                <div class="cajita-sin-borde">
                                    {{item.Discharged}} discharged.
                                </div>
                            </div>
                            <div class="sin-padding">
                                <div class="cajita-sin-borde">
                                    {{item.Loaded}} Loaded
                                </div>
                            </div>
                            <div class="sin-padding">
                                <div class="cajita-sin-borde">
                                    {{item.ShiftedReloaded}} Shifted/Reloaded
                                </div>
                            </div>
                            <div class="sin-padding">
                                <div class="cajita-sin-borde">
                                    {{item.TotalMovs}} Total Mov's
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
        <br />
        <br />
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import DataStorage from '@/_helpers/localStorage';
    //data
    function data () {
        return {
            listInformation: [],
        }
    }
    //methods
    function infoCommence(item){
        return item.FgCommenced ? 'cajita-info d-flex align-items-center justify-content-center' : 'cajita-sin-borde d-flex align-items-center justify-content-center';
    }
    function infoFinnished(item){
        return item.FgFinnished ? 'cajita-danger d-flex align-items-center justify-content-center' : 'cajita-sin-borde d-flex align-items-center justify-content-center';
    }
    //computed
    function dateCommence(){
        let date = "N/A";
        this.formatedItemsInformation.map(async (item) => {
            if(item.FgCommenced){
                date = item.DateFrom;
            }
        })
        return date;
    }
    function dateFinnished(){
        let date = "N/A";
        this.formatedItemsInformation.map(async (item) => {
            if(item.FgFinnished){
                date = item.DateTo;
            }
        })
        return date;
    }
    function formatedItemsInformation() {
        return this.listInformation.map((item,index) => Object.assign({}, item, {
            RowNumber: index+1,
            DateFrom: item.DateFrom ? DateFormater.formatDateTimeWithSlash(item.DateFrom) : 'N/A',
            DateTo: item.DateTo ? DateFormater.formatDateTimeWithSlash(item.DateTo) : 'N/A'
        }));
    }
    export default {
        name:"tabs-general-info",
        data,
        props: {tabIndexGruas: Number},
        methods:{
            infoCommence,
            infoFinnished,
        },
        computed:{
            dateCommence,
            dateFinnished,
            formatedItemsInformation,
            ...mapState({
                VisitId: state => state.visitas.VisitId,
            })
        },
        watch:{
            tabIndexGruas: async function (val) {
                if (val==2 && this.VisitId != '') {
                    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                    await this.$http.ejecutar('GET', 'VisitCraneGeneralInfo-by-VisitId', { VisitId: this.VisitId }, '').then(responseinformation => {
                        if(responseinformation.status === 200){
                            this.listInformation = responseinformation.data.data;
                            this.$store.state.visitas.apiStateForm = ENUM.INIT;
                        }else{
                            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                        this.$store.commit('visitas/messageMutation', err);
                    });
                }
            }
        }
    }
</script>


<style lang="scss">
    .sin-padding{
        padding:0px;
    }
    .cajita-no{
        text-align: center;
        margin-top:10px;
        width:100%;
        padding: 8px 0;
        font-size:10px;
    }
    .cajita-inicio{
        margin-top:10px;
        background: #e8e9ed;
        border: 4px solid #a0afc8;
        border-radius: 10px;
        width:100%;
        padding: 8px 0;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
    .cajita-fin{
        margin-top:10px;
        background: #e8e9ed;
        border: 4px solid #e1a67e;
        border-radius: 10px;
        width:100%;
        padding: 8px 0;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
    .cajita-sin-relleno{
        background: #e8e9ed;
        border: 1px solid #c7c8c8;
        border-radius: 5px;
        width:100%;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
    .cajita-sin-borde{
        border: 1px solid #c7c8c8;
        border-radius: 5px;
        /*width:100%;*/
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
    .cajita-info{
        border: 1px solid #c7c8c8;
        border-radius: 5px;
        background: #727cb6;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
    .cajita-danger{
        border: 1px solid #c7c8c8;
        border-radius: 5px;
        background: #f2915d;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        font-size:10px;
        color:rgba(0, 0, 0, 0.898);
    }
</style>