import {
    onlyMayusText,
    siglasAlphanumeric,
    onlyAlphanumeric
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength, } from "vuelidate/lib/validators";


export const FormUnidadMedida = () => {
    return {
        formUnidadMedida: {
            IdUnidadMedida:{
                required,
            },
            NbUnidadMedida:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyAlphanumeric
            },
            SiglasUnidadMedida:{
                required,
                siglasAlphanumeric,
                minLength: minLength(2),
                maxLength: maxLength(5),
            },
            FgActUnidadMedida:{
                //required
            },
            IdTpUnidadMedida: { required  },
        }
    }
}

export const FormTpUnidadMedida = () => {
    return {
        formTpUnidadMedida: {
            IdTpUnidadMedida:{
                required,
            },
            NbTpUnidadMedida:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyAlphanumeric
            },
            FgActTpUnidad:{
                required
            }
        }
    }
}

