<template>
    <CRow>
        <CCol sm="12">
            <CRow>
                <CCol sm="12">
                    <CRow>
                        <CCol sm="10" md="10" lg="10">
                            <CRow>
                                <CCol sm="12">
                                    <CRow>
                                        <CCol>
                                            <CInput
                                                label="NOMBRE TIPO MAQUINA"
                                                addLabelClasses="required"
                                                horizontal
                                                v-uppercase
                                                @blur="$v.formTpMachine.TpMachineName.$touch()"
                                                placeholder="INGRESE EL NOMBRE"
                                                v-model="$v.formTpMachine.TpMachineName.$model"
                                                :addInputClasses="{ 'is-invalid': $v.formTpMachine.TpMachineName.$error }"
                                                required 
                                            >
                                                <template #invalid-feedback>
                                                    <div v-if="$v.formTpMachine.TpMachineName.$error">
                                                        <div class='text-danger' v-if="!$v.formTpMachine.TpMachineName.required">Campo requerido</div>
                                                        <div class='text-danger' v-if="!$v.formTpMachine.TpMachineName.minLength">Campo requiere minimo {{$v.formTpMachine.TpMachineName.$params.minLength.min}} caracteres</div>
                                                        <div class='text-danger' v-if="!$v.formTpMachine.TpMachineName.maxLength">Campo requiere maximo {{$v.formTpMachine.TpMachineName.$params.maxLength.max}} caracteres</div>
                                                    </div>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol v-if="!idIndentification">
                                            <CSelect
                                                label="STATUS"
                                                addLabelClasses="required"
                                                horizontal
                                                :is-valid="formTpMachine.FgActTpMachine"
                                                v-model="formTpMachine.FgActTpMachine"
                                                :value.sync="formTpMachine.FgActTpMachine"
                                                :options="selectOptions"
                                                
                                            />
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol sm="12">
                                    <CTextarea
                                        label="DESCRIPCIÓN"
                                        type="textarea"
                                        placeholder="PARA TODO TIPO DE CARGA EN GENERAL"
                                        v-uppercase
                                        v-model="$v.formTpMachine.TpMachineDs.$model"
                                        :addInputClasses="{ 'is-invalid': $v.formTpMachine.TpMachineDs.$error }"
                                        @blur="$v.formTpMachine.TpMachineDs.$touch()"
                                        :plaintext="false"
                                        :readonly="false"
                                        :lazy="(true,400)"
                                    >
                                        <template #invalid-feedback>
                                            <div v-if="$v.formTpMachine.TpMachineDs.$error">
                                                <div class='text-danger' v-if="!$v.formTpMachine.TpMachineDs.minLength">Campo requiere minimo {{$v.formTpMachine.TpMachineDs.$params.minLength.min}} caracteres</div>
                                                <div class='text-danger' v-if="!$v.formTpMachine.TpMachineDs.maxLength">Campo requiere maximo {{$v.formTpMachine.TpMachineDs.$params.maxLength.max}} caracteres</div>
                                            </div>
                                        </template>
                                    </CTextarea>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol sm="2" md="2" lg="2">
                            <CButtonGroup>
                                <CButton 
                                    color="success"
                                    v-c-tooltip="{
                                        content: 'Agregar',
                                        placement: 'top-end'
                                    }"
                                    @click="sendData"
                                    :disabled="loading || !idIndentification"
                                >
                                    <CIcon name="cil-plus"/>
                                </CButton>
                                <CButton 
                                    color="info"
                                    v-c-tooltip="{
                                        content: 'Modificar',
                                        placement: 'top-end'
                                    }"
                                    @click="sendData"
                                    :disabled="loading || idIndentification"
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                                <CButton 
                                    color="danger"
                                    v-c-tooltip="{
                                        content: 'Limpiar información',
                                        placement: 'top-end'
                                    }"
                                    :disabled="loading"
                                    @click="deleteDate()"   
                                >
                                    <CIcon name="cil-brush-alt"/>
                                </CButton>
                            </CButtonGroup>
                        </CCol>
                    </CRow>                               
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                    <dataTableExtended
     class="align-center-row-datatable"
                        :items="formatedItems"
                        :fields="fields"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        sorter
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="4"
                        pagination
                        column-filter
                        :loading="loading"
                        hover
                    >
                        <template #TpMachineId="{ index}">
                            <td>
                                {{index+1}}
                            </td>
                        </template>
                        <template #status="{item}">
                            <td class="text-center">
                                <CBadge v-if="item.status" color="success">
                                    {{item.Status}}
                                </CBadge>
                                <CBadge v-if="!item.status" color="danger">
                                    {{item.Status}}
                                </CBadge>
                            </td>
                        </template>
                        <template #acciones="{item,index}">
                            <td>
                                <CButtonGroup>
                                    <CButton
                                        color="primary"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="updatedTable(item, index)"
                                        v-c-tooltip="{
                                            content: 'Modificar condición maquina',
                                            placement: 'top-end'
                                        }"
                                        
                                    >
                                        <CIcon name="pencil"/>
                                    </CButton>
                                </CButtonGroup>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { FormTpMachine } from '@/_validations/maquina/MaquinaValidation';

    const tableTextHelpers = {
        tableFilterText: {
            label: 'FILTRAR:',
            placeholder: 'TIPO DE MAQUINA',
        },
        itemsPerPageText: {
            label: 'TIPOS DE MAQUINAS POR PAGINA:',
        },
        noItemsViewText: {
            noResults: 'NO SE ENCONTRARON RESULTADOS',
            noItems: 'NO HAY REGISTROS DISPONIBLES',
        },
    };
    
    const fields = [
        { key: 'TpMachineId', label: '#',_style: 'width:1%', },
        { key: 'TpMachineName',label: 'TIPO DE MAQUINA', _style:'min-width:100px' },
        { key: 'UltimoUsuario', label: 'USUARIO', _style: 'width: 1%;',_classes:"center-cell",},
        { key: 'FormatedDate', label: 'FECHA', _style: 'width: 1%;'},
        { key: 'status', label: 'STATUS', _style:'width:1%; text-align:center;'},
        { 
            key: 'acciones', 
            label: '', 
            _style: 'width:1%', 
            sorter: false, 
            filter: false
        }
    ]

    //data
    function data() {
        return {
            formTpMachine:{
                TpMachineId:0,
                TpMachineName:'',
                TpMachineDs:'',
                FgActTpMachine:true,
            },
            tableText: Object.assign({}, tableTextHelpers),
            fields,
            originalAct:'',
            selectOptions: [
                { 
                    value: true, 
                    label: 'ACTIVO'
                },
                { 
                    value: false, 
                    label: 'INACTIVO'
                }
            ],
        }
    }

    //methods
    function sendData(){
        this.$v.formTpMachine.$touch();
        if (this.$v.formTpMachine.$pending || this.$v.formTpMachine.$error) return;

        let { TpMachineId,TpMachineName,TpMachineDs,FgActTpMachine } = this.formTpMachine;
        const { dispatch } = this.$store;
        if(this.originalAct !== '' && TpMachineId !== 0){
            if(this.originalAct !== FgActTpMachine){
                this.$swal.fire({
                    title: `¿Esta seguro de realizar el cambio de status para el registro ${TpMachineName}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch('maquina/sendDataTpMachine', { TpMachineId,TpMachineName,TpMachineDs,FgActTpMachine });
                    }else {
						// this.$swal('Operación cancelada');
					}
                })
            }else{
                dispatch('maquina/sendDataTpMachine', { TpMachineId,TpMachineName,TpMachineDs,FgActTpMachine });
            }
        }else{
            dispatch('maquina/sendDataTpMachine', { TpMachineId,TpMachineName,TpMachineDs,FgActTpMachine });
        }
    }
    function deleteDate(){
        this.formTpMachine.TpMachineId = 0;
        this.formTpMachine.TpMachineName='';
        this.formTpMachine.TpMachineDs='';
        this.formTpMachine.FgActTpMachine=true;
        this.originalAct = '';
        this.$nextTick(() => { this.$v.$reset() })
    }
    function updatedTable(item){
        const id = item.TpMachineId;
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'TpMachine-by-id',{ TpMachineId: id }, '' ).then(response => {
            try {
                if(response.status === 200){
                    const Information = response.data.data;
                    if(Information.length !== 0){

                        this.formTpMachine.TpMachineId=Information[0].TpMachineId;
                        this.formTpMachine.TpMachineName=Information[0].TpMachineName;
                        this.formTpMachine.TpMachineDs=Information[0].TpMachineDs;
                        this.formTpMachine.FgActTpMachine=Information[0].FgActTpMachine;
                        this.originalAct = Information[0].FgActTpMachine;

                        this.$store.state.maquina.apiStateForm = ENUM.INIT;
                    }
                }else{
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                }
            } catch (error) {
                this.$store.commit('maquina/messageMutation', error);
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.commit('maquina/messageMutation', err);
            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
        });
    }
    //computed
    function idIndentification(){
        return this.formTpMachine.TpMachineId === 0;
    }
    function computedItems () {
        return this.$store.getters["maquina/myDataMachineTptable"];
    }
    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    //watch
    function tabIndex(newValue,oldValue){
        if(newValue === "tab2"){
            this.$store.dispatch('maquina/getTpMachinelist');
        }else if(oldValue === "tab2"){
            this.deleteDate();
        }
    }
    function formReset(newValue){
        if(newValue === 2){
            this.deleteDate();
            this.$store.state.maquina.formReset = 0;
        }
    }
    export default {
        name:'app-tipo-maquina',
        data,
        props: ['loading'],
        directives: UpperCase,
        validations(){ return FormTpMachine() },
        methods:{
            sendData,
            deleteDate,
            updatedTable,
        },
        computed: {
            idIndentification,
            computedItems,
            formatedItems,
            ...mapState({
                tabIndex: state => state.maquina.tabIndex,
                formReset: state => state.maquina.formReset,
            })
        },
        watch:{
            tabIndex,
            formReset
        }
    }
</script>