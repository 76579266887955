<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.bulkCargoList')}}</b>
    </CCardHeader>
    <CCardBody>
      <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
        <CustomTab :title="$t('label.cargoState')" icon="cargoState">
          <estadoCgaGranel 
            :Tab="Tab"
          />
        </CustomTab>
        <CustomTab :title="$t('label.packaging')" icon="packaging">
          <embalajeCgaGranel 
            :Tab="Tab"
          />
        </CustomTab>
        <CustomTab :title="$t('label.commodity')">
          <template #title>
            <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Commodity.svg" alt="Card image cap">
            {{$t('label.commodity')}}                
          </template>
          <mercanciaCgaGranel 
            :Tab="Tab"
          />
        </CustomTab>
      </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import mercanciaCgaGranel from './mercancia/index-mercancia';
import estadoCgaGranel from './estado/index-estado';
import embalajeCgaGranel from './embalaje/index-embalaje';

function data() {
  return {
    Tab: 0,
  };
}

function handleTab(tab) {
  this.Tab = tab;
}

export default {
  name: 'cargaGranel',
  data,
  components: {
    CustomTabs,
    CustomTab,
    mercanciaCgaGranel,
    estadoCgaGranel,
    embalajeCgaGranel,
  },
  methods:{
    handleTab,
  },
};

</script>
<style lang=""></style>
