<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CRow class="mt-3" style="margin-left: 0.15rem !important; margin-right: 0.15rem !important;">
        <CCol sm="12" class="px-0" >
          <CustomTabs class="text-table" :active-tab="$store.state.maquina.tabMachine" @update:activeTab="handleTab">
            <CustomTab icon="cil-playlist-add" :title="$t('label.MachineData')">
              <addMachine
                @closeCollapse= "closeCollapse=true"
                @child-refresh="ChildRefresh"
              />
            </CustomTab>
            <CustomTab icon="cil-satelite" :title="$t('label.registerPhotoMachine')">
              <appPhotoMachine
                @closeCollapse= "closeCollapse=true"
              />
            </CustomTab>

          </CustomTabs>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <div/>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import { mapState } from 'vuex';
import addMachine from './add-machine';
import appPhotoMachine from './add-photos-machine';

import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import ENUM from '@/_store/enum';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    isEdit: false,
    activeTab: 0, 
    titleModal: '',
    closeCollapse: false,
  };
}
 
  function handleTab(tab) {
    this.activeTab = tab;
    this.$store.state.maquina.tabMachine = this.activeTab;
  }

  function closeModal() {
    this.$store.state.maquina.CollapseMachine = false;  
    this.$emit('closeModal');
    this.showModal=false;
    this.$store.state.maquina.tabMachine = 0;
  }

  function getData(modal){   
      this.$store.state.maquina.apiStateForm = ENUM.LOADING;
      try {
          this.$http.ejecutar('GET', 'MachineStatus-list', { Filter: 'ALL' }, '').then(responseMachineStatus => {
              if(responseMachineStatus.status === 200){
                  this.$http.ejecutar('GET', 'MachineSuplier-list', { Filter: 'ACTIVO' }, '').then(responseMachineSuplier => {
                      if(responseMachineSuplier.status === 200){
                          this.$http.ejecutar('GET', 'BrandMachine-list', { Filter: 'ACTIVO' }, '').then(responseBrand => {
                              if(responseBrand.status === 200){
                                  this.$http.ejecutar('GET', 'UnitMeasureByTpUnitMeasureId', { TpUnitMeasureId:'ec73eb11-b431-4594-94c8-0f6183722b76',Filter: 'ACTIVO' }, '').then(response => {
                                      if(response.status === 200){
                                        this.$store.state.maquina.myDataBrand = responseBrand.data.data;
                                        this.$store.state.maquina.myDataUnityMesure = response.data.data;
                                        this.$store.state.maquina.myDataMachineSuplier = responseMachineSuplier.data.data;
                                        this.$store.state.maquina.myDataStatus = responseMachineStatus.data.data;
                                        if(this.$store.state.maquina.idState !== 0){
                                          this.getDataModel(modal);
                                        }else{
                                          this.$store.state.maquina.apiStateForm = ENUM.INIT;
                                        }
                                      }else{
                                        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                      }
                                  }).catch(err => {
                                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('maquina/messageMutation', err);
                                  });
                              }else{
                                  this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                              }
                          }).catch(err => {
                              this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                              this.$store.commit('maquina/messageMutation', err);
                          });
                      }else{
                          this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                      }
                  }).catch(err => {
                      this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                      this.$store.commit('maquina/messageMutation', err);
                  });
              }else{
                  this.$store.state.maquina.apiStateForm = ENUM.ERROR;
              }
          }).catch(err => {
              this.$store.state.maquina.apiStateForm = ENUM.ERROR;
              this.$store.commit('maquina/messageMutation', err);
          });            
      } catch (error) {
          this.$store.state.maquina.apiStateForm = ENUM.ERROR;
          this.$store.commit('maquina/messageMutation', error);
      }  
  } 

  function getDataModel(value){
    let modalId = value;
     // this.$store.state.maquina.apiStateForm = ENUM.LOADING;
      this.$http.ejecutar('GET', 'Model-list', { BrandId:modalId.BrandId,Filter: 'ACTIVO' }, '').then(response => {
          if(response.status === 200){
              this.$store.state.maquina.myDataModel = response.data.data;
           //   this.$store.state.maquina.apiStateForm = ENUM.INIT;
          }else{
            //  this.$store.state.maquina.apiStateForm = ENUM.ERROR;
          }
      }).catch(err => {
        //  this.$store.state.maquina.apiState = ENUM.ERROR;
          this.$store.commit('maquina/messageMutation', err);
        //  this.$store.state.maquina.apiStateForm = ENUM.INIT;
      });
  }

  function ChildRefresh() {
    this.closeCollapse = false;
    this.$store.state.maquina.CollapseMachine = false;
    this.$store.state.maquina.idState = 0;
    this.$store.state.maquina.NewMachineName = '';
    this.$store.state.maquina.NewMachineId = '';
    this.$store.state.maquina.machineRoute = '';
    this.$emit('child-refresh', true);
    this.showModal=false;
  }

export default {
  name: 'modal-machine',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral],
  directives: UpperCase,
  components: {
    addMachine,
    appPhotoMachine,
    CustomTabs,
    CustomTab,
},
watch: {
    modal: async function () {
      if (this.modal) {
        this.$store.state.maquina.tabMachine = 0;
        this.showModal = true;  
        this.$store.state.maquina.CollapseMachine = true;   
        this.$store.state.maquina.myDataPhotoMachine = [];

        if (this.modal==true) {
          this.$store.state.maquina.idState = 0;
          this.$store.state.maquina.NewMachineName = '';
          this.$store.state.maquina.NewMachineId = '';
          this.$store.state.maquina.machineRoute = '';
          this.getData(this.modal);  
          this.titleModal = this.$t('label.nueva')+' '+this.$t('label.machine');
        } else {
          this.$store.state.maquina.idState = this.modal.MachineId;
          this.$store.state.maquina.NewMachineName = this.modal.MachineName;
          this.$store.state.maquina.NewMachineId = this.modal.MachineId;
          this.$store.state.maquina.machineRoute = '';
          this.getData(this.modal);  
          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.machine')+': '+this.modal.MachineName;
        }
        this.$emit('closeModal');
      }
    },
    closeCollapse: function () {
      if (this.closeCollapse) {
        this.closeCollapse = false;
        this.$store.state.maquina.CollapseMachine = false;
        this.$store.state.maquina.idState = 0;
        this.$store.state.maquina.NewMachineName = '';
        this.$store.state.maquina.NewMachineId = '';
        this.$store.state.maquina.machineRoute = '';
        this.$emit('closeModal');
        this.showModal=false;
      }
    },
  },
  methods: {
    ChildRefresh,
    handleTab,
    closeModal,
    getData,
    getDataModel,
  },
  computed: {
    ...mapState({
      tabIndex: state => state.maquina.tabIndex,
      myDataMachineTp: state => state.maquina.myDataMachineTp,
      myDataMachineCondition: state => state.maquina.myDataMachineCondition,
      filtroConditionId: state => state.maquina.filtroConditionId,
      filtroMachineTp: state => state.maquina.filtroMachineTp,
      CollapseMachine: state=> state.maquina.CollapseMachine,
      CollapseCarga: state=> state.maquina.CollapseCarga,
      branch: state => state.auth.branch,
      idState: state => state.maquina.idState,
      tabMachine:state => state.maquina.tabMachine, 
    })
  },
};
</script>
<style lang="scss">
 
  .text-table .custom-tab-content {
    background: #fff;
    border: 0;
    outline: none;
    border-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

</style>
