<template>
  <div class="row mx-0 mt-3">
    <CCol sm="12" lg="6">
      <CSelect
        addLabelClasses="text-right required"
        size="sm"
        class="mb-3"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
        :label="`${this.$t('label.measureUnit')} RF`" 
        :options="UnitMeasureUnitRfOptions"
        v-model="$v.ReeferEquipment.UnitMeasureRf.$model"
        :is-valid="hasError($v.ReeferEquipment.UnitMeasureRf)"
        :invalid-feedback="errorMessage($v.ReeferEquipment.UnitMeasureRf)"
        @change="RfConversion($event.target.value)"
      />
    </CCol>
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        class="mb-3"
        addLabelClasses="text-right required"
        :label="$t('label.temperature')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-6'}"
        v-model="$v.ReeferEquipment.ReeferSetting.$model"
        :is-valid="hasError($v.ReeferEquipment.ReeferSetting)"
        :invalid-feedback="errorMessage($v.ReeferEquipment.ReeferSetting)"
        type="number"
      />
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right required">{{`${$t('label.MinimumRange')} RF`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.ReeferEquipment.MinimumRangeRf.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.ReeferEquipment.MinimumRangeRf.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.ReeferEquipment.MinimumRangeRf.$error">
            {{ errorMessage($v.ReeferEquipment.MinimumRangeRf) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.MaximumRange')} RF`}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-6">
          <money
            size="sm"
            v-bind="measure"
            :class="`${$v.ReeferEquipment.MaximumRangeRf.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.ReeferEquipment.MaximumRangeRf.$model"
            maxlength= "11"
          />
          <div class="text-invalid-feedback" v-if="$v.ReeferEquipment.MaximumRangeRf.$error">
            {{ errorMessage($v.ReeferEquipment.MaximumRangeRf) }}
          </div>
        </div>
      </div>
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
  
function data() {
  return {
    measure: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
    },
  };
}

//methods

//computed
function UnitMeasureUnitRfOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureTemperatureList.map(item =>{
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function RfConversion(Id) {
  this.ReeferEquipment.UnitMeasureRf = Id ?? '';
  if (this.ReeferEquipment.UnitMeasureRf) {
    if (this.ReeferEquipment.PreviousRfUnitMeasure) {
      
    }
    this.ReeferEquipment.PreviousRfUnitMeasure = this.ReeferEquipment.UnitMeasureRf;
  }else{
    this.ReeferEquipment.MinimumRangeRf = 0;
    this.ReeferEquipment.MaximumRangeRf = 0;
    this.ReeferEquipment.PreviousRfUnitMeasure = '';
  }
}
  
export default {
  name: 'reefer-equipment-tab',
  components: {
    Money,
  },
  data,
  beforeDestroy() {
    this.ReeferEquipment.ReeferSetting = '';
    this.ReeferEquipment.UnitMeasureRf = '';
    this.ReeferEquipment.PreviousRfUnitMeasure = '';
    this.ReeferEquipment.MinimumRangeRf = 0;
    this.ReeferEquipment.MaximumRangeRf = 0;
  },
  props: {
    ReeferEquipment: {
      type: Object,
      required: true,
      default: () => {},
    },
    UnitMeasureTemperatureList: {
      type: Array,
      default: () => {},
    },
    Validate: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
    RfConversion,
  },
  computed:{
    UnitMeasureUnitRfOptions,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.ReeferEquipment.$touch();
      }else{
        this.$v.$reset();
      }
    },
  }
};
</script>