<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.damageTypeRegistration')"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(ES)'"
            :placeholder="$t('label.damageTypeName')+' '+'(ES)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.TpDamageName.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.TpDamageName)"
            :invalid-feedback="errorMessage($v.TpDamageName)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(EN)'"
            :placeholder="$t('label.damageTypeName')+' '+'(EN)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.TpDamageNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.TpDamageNameEn)"
            :invalid-feedback="errorMessage($v.TpDamageNameEn)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.acronym')"
            :placeholder="$t('label.damageTypeAcronym')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.TpDamageAbbrev.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.TpDamageAbbrev)"
            :invalid-feedback="errorMessage($v.TpDamageAbbrev)"
            maxlength="5"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          type="submit"
          :disabled="isSubmit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import EirTipoDanoFormValidations from '@/_validations/eir/tpdano/EirTipoDanoFormValidations';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpDamageName: '',
    TpDamageNameEn: '',
    TpDamageAbbrev: '',
    apiStateLoading: false,
  };
}

function submitBody() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpDamageJson = {
      TpDamageName: this.TpDamageName,
      TpDamageNameEn: this.TpDamageNameEn,
      TpDamageAbbrev: this.TpDamageAbbrev,
    };

    this.$http
      .post('TpDamage-insert', TpDamageJson, { root: 'TpDamageJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpDanoJson = data;
          this.resetForm();
          this.$emit('set-type-damage-list', TpDanoJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function resetForm() {
  this.TpDamageName = '';
  this.TpDamageNameEn = '';
  this.TpDamageAbbrev = '';
  this.$v.$reset();
}

export default {
  name: 'modal-create-type-damage',
  props: { modal: Boolean },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: EirTipoDanoFormValidations,
  directives: UpperCase,
  methods: {
    submitBody,
    resetForm,
  },
  watch: {
    
  },
 
};
</script>
