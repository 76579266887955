<template>
    <div>
        <CCollapse :show="activeItem(21)">
            <appRecurso :loading="apiStateLoading"/>            
        </CCollapse>
        <CCollapse :show="activeItem(22)">
            <appInsumo :loading="apiStateLoading"/>            
        </CCollapse>
        <CCollapse :show="activeItem(37)">
            <Machinery/>            
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import appInsumo from './insumo/index-insumo';
    import appRecurso from './recurso/tabs-recurso';
    import Machinery from './machinery/machinery-index';
    //methods
    function activeItem(item) {
        return this.dropItemReport === item;
    }
    //computed
    function idIndentification(){
        return this.VisitId == '';
    }

    export default {
        name:"agenda",
        props:['apiStateLoading'],
        methods:{
            activeItem,
        },
        components:{
            appInsumo,
            appRecurso,
            Machinery,
        },
        computed:{
            idIndentification,
            ...mapState({
                VisitId: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,
            })
        },
    }
</script>