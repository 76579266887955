<template>
  <div class="mb-3">
    <div>
      <CRow class="mb-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12" lg="4">
          <div class="form-group form-row" rol="group">
            <label class="col-form-label col-sm-12 col-lg-2 text-right">{{$t('label.visit')}}</label>
            <div class="col-sm-12 col-lg-9 input-group">
                <v-select
                    class="select-adjust"
                    :placeholder="$t('label.select')"
                    v-model="VisitId"
                    :options="VisitOptions"
                    :getOptionLabel="option => option.label"
                    :reduce="option => option.value" 
                >
                    <template #no-options="{ }">
                        {{$t('label.noResultsFound')}}
                    </template>
                    <template #option="{ Json, label, value }">
                        {{ label }}<br />
                        <cite v-if="value">
                            {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.Ata }} 
                        </cite>
                    </template>
                </v-select>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="7">
          <div class="form-group form-row d-flex aling-items-center mb-0">
              <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
              <div class="input-group col-sm-12 col-lg-4 mb-2">
                  <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.fromDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                  >
                      <template #icon-calendar>
                          <div style="display: none"></div>
                      </template>
                  </vue-datepicker>
              </div>
              <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
              <div class="input-group col-sm-12 col-lg-4 mb-2">
                  <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="true"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.toDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                  >
                      <template #icon-calendar>
                          <div style="display: none"></div>
                      </template>
                  </vue-datepicker>
              </div>
              <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
                  <CButton
                      color="watch"
                      square
                      size="sm"
                      class="mx-1"
                      @click="getBlCargoDeconsolidation()"
                  >
                      <CIcon name="cil-search"/>
                  </CButton>
                  <CButton
                      color="danger"
                      square
                      size="sm"
                      @click="Resest()"
                  >
                      <CIcon name="cil-brush-alt"/>
                  </CButton>
              </div>
          </div>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedList"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #Ata="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.Ata) }}
              </td>
            </template>
            <template #Quantity="{ item }">
              <td class="text-center">
                {{ NumberFormat(item.Quantity, 2) }}
              </td>
            </template>
            <template #Weigth="{ item }">
              <td class="text-center">
                {{ NumberFormat(item.Weigth, 2) }}
              </td>
            </template>
            <template #Volumen="{ item }">
              <td class="text-center">
                {{ NumberFormat(item.Volumen, 2) }}
              </td>
            </template>
            <template #FirstEventDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.FirstEventDate) }}
              </td>
            </template>
            <template #LastEventDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.LastEventDate) }}
              </td>
            </template>
            <template #YardCargoBlStatus="{ item }">
              <td class="cell-center text-center">
                <CBadge>
                  {{ item.YardCargoBlStatus }}
                </CBadge>
              </td>
            </template>
            <template #BlStatus="{ item }">
              <td class="cell-center text-center">
                <CBadge>
                  {{ item.BlStatus }}
                </CBadge>
              </td>
            </template>
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  square
                  size="sm"
                  color="watch"
                  class="d-flex align-items-center"
                  @click="toggleAdd(item)"
                  v-c-tooltip="{
                    content: `${$t('label.see')} ${$t('label.bl')}`,
                    placement: 'top-start'
                  }"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import moment from 'moment';

//data
function data() {
return {
    VisitId: '',
    VisitList: [],
    searchFilterDate: {
        fromDay: '',
        toDay: '',
    },
    previousDate: '',
    laterDate: '',
};
}

//methods
function getBlCargoDeconsolidation() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('BlCargoDeconsolidation-list-by-YardId', { BlCargoJson: { YardId: this.YardId } })
      .then(response => {
          let List = response.data.data ?? [];
          this.$store.state.yardManagement.ListDeconsolidatedBl = List[0]&&Object.entries(List[0]).length>1 ? List : [];
      })
      .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
      }).finally(() => {
        this.$store.state.yardManagement.loading = false;
      });
}

async function getYardVisit() {
  await this.$http.get('YardVisitList-by-Yard', { YardId: this.YardId,  CompanyBranchId: this.CompanyBranchId })
    .then(response => {
      this.VisitList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'yard',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function toggleAdd(item) {
  this.$store.state.yardManagement.FgDispatch = true;
  this.$store.state.yardManagement.blSelected = item.BlCargoId;
}

function validateDateRange(date) {
return date < this.previousDate || date > this.laterDate;
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A'
}

function NumberFormat(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function getDateRange(){
  this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
  this.laterDate = new Date();
  this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
  this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

async function Resest() {
  this.$store.state.yardManagement.loading = true;
  this.$store.state.yardManagement.ListDeconsolidatedBl = [];
  this.VisitId = '';
  this.VisitList = [];
  await this.getDateRange();
  await this.getYardVisit();
  await this.getBlCargoDeconsolidation();
}

//computed
function VisitOptions() {
  if(this.VisitList.length === 0){
      return [{
          label: this.$t('label.select'),
          value: '',
          Json: {
              VoyageArrival: '',
              Eta: '',
          },
      }];
  }else{
      let chart = [{
          label: this.$t('label.select'),
          value: '',
          Json: {
              VoyageArrival: '',
              Eta: '',
          }
      }];
      this.VisitList.map(function(e){
          chart.push({
              label: e.VesselName,
              value: e.VisitId,
              Json: {
                  VoyageArrival: e.VoyageArrival,
                  Ata: DateFormater.formatDateTimeWithSlash(e.Ata),
              }
          })    
      })
      return chart;
  };
}

function computedList() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.ListDeconsolidatedBl.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
      PortActivityName: item[`PortActivityName${_lang}`] ?? 'N/A',
      VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
      FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
      LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
      YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`] ?? 'N/A',
      BlStatus: item[`BlStatus${_lang}`] ?? 'N/A',
      TpBlName: item.TpBlName ?? '',
      DocumentCode: item.DocumentCode ?? '',
      ClientName: item.ClientName ?? '',
      DaysInYard: item.DaysInYard ?? 0,
      MasterBlNro: item.MasterBlNro ?? '',
      _classes: 'color-gradient',
      _style: `background:${item.YardCargoBlStatusColor}`
    };
  })
}

function fields(){ 
  return [
    { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width:45px', _classes: 'text-center', filter: false },
    { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:" text-center" },
    { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px;', _classes:" text-center" },
    { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:140px;' },
    { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:200px', _classes:'text-uppercase text-center center-cell' },
    { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'PortActivityName',label: this.$t('label.activity'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;' },
    { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;' },
    { key: 'Quantity', label: this.$t('label.quantity'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;' },
    { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px;' },
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes: 'text-center' },
    { key: 'DaysInYard', label: `${this.$t('label.daysInYard')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:160px;' },
    { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },  
    { key: 'BlStatus', label: this.$t('label.statusCertificate'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },  
  ];
}
    
export default{
  name: 'warehouse-dispatch',
  data,
  mixins: [General],
  components: {

  },
  methods: {
      getBlCargoDeconsolidation,
      getYardVisit,
      toggleAdd,
      validateDateRange,
      formatDateTimeWithSlash,
      NumberFormat,
      getDateRange,
      Resest,
  },
  computed: {
      VisitOptions,
      fields,
      computedList,
      ...mapState({
          YardId: state => state.yardManagement.yardData.YardId,
          ListDeconsolidatedBl: state => state.yardManagement.ListDeconsolidatedBl,
          dropItemMovementList: state => state.yardManagement.dropItemMovementList,
          CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      })
  },
  watch:{
    dropItemMovementList: function (val) {
      if (val==7) {
        //this.$store.state.yardManagement.GeneralList = 2;
        this.Resest();
      }
    }
  }
}
</script>