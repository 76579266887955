<template>
    <div class="mb-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsHousePendingReceive')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #YardCargoBlStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.YardCargoBlStatus)">
                            {{ item.YardCargoBlStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #VisitBillOfLadingStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                            {{ item.VisitBillOfLadingStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                            square
                            size="sm"
                            color="watch"
                            class="d-flex align-items-center"
                            @click="toggleAdd(item)"
                            v-c-tooltip="{
                                content: $t('label.reception'),
                                placement: 'top-start'
                            }"
                            >
                            <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>

                    <template #Weight="{ item }">
                        <td class="text-center">
                            {{ NumberFormat(item.Weight, 2) }}
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <BlRecepcion
                :Active="showIndex==1"
                :ContainerItem="ContainerItem"
                :isEdit = "false"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
        <div v-show="showIndex==2">
            <DesconsolidatedRegister
                :Active="ActiveRegister"
                :HouseData="HouseData"
                :isEdit="false"
                :ContainerItem="ContainerItem"
                @Close="ActiveRegister=false"
                @Update-list="(event)=>{showIndex=0; ContainerItem ={};  Update(event);}"
                @Reception="BeforeReceptionDesconsolidation"
            />
        </div>
        <div v-show="showIndex==3" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                    <CButton
                        color="edit"
                        class="mr-2"
                        v-c-tooltip="{
                            content: $t('label.return'),
                            placement: 'top',
                        }"
                        size="sm"
                        @click="()=> {showIndex = 0}"
                    >
                        <CIcon name="cil-chevron-left-alt" />
                        
                    </CButton>
                    <CRow class="align-items-center justify-content-start w-100 m-0">
                        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
                    </CRow>
                </CCol>
            </CRow>
            <CRow class="my-3">
                <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
                    <CRow>
                    <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate">
                        <CButton
                        shape="square"
                        color="add"
                        class="d-flex align-items-center ml-auto"
                        v-c-tooltip="{
                            content: $t('label.deconsolidated'),
                            placement: 'top-end'
                        }"
                        @click="()=>{ this.showIndex = 2; this.ActiveRegister = true; }"
                        >
                        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                        </CButton>
                    </CCol>
                    </CRow>
                </CCol> 
            </CRow>
            <CRow class="mt-2">
                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        details
                        column-filter
                        :items="formatedDesconsolidationItems"
                        :fields="fieldsDesconsolidation"
                        :noItemsView="tableText.noItemsViewText"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page="tableText.itemsPerPage"
                        :items-per-page-select="tableText.itemsPerPageText"
                        pagination
                    >
                        <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                        </template>
                        <template #Status="{item}">
                            <td class='text-center'>
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                        <template #buttonOptions="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.reception'),
                                        placement: 'top-start'
                                    }"
                                    @click="ReceptionDesconsolidation(item)"
                                >
                                    <CIcon name='cil-list' />
                                </CButton>
                            </td>
                        </template>
                        <template #Weigth="{ item }">
                            <td class="text-center">
                                {{ formatNumber(item.Weigth, 2) }}
                            </td>
                        </template>
                        <template #Volumen="{ item }">
                            <td class="text-center">
                                {{ formatNumber(item.Volumen, 2) }}
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from './BlReception/reception';
    import DesconsolidatedRegister from '@/pages/yard-management/container/events/desconsolidated/desconsolidated-from-house-register'
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            ContainerItem:{},
            HouseData: {},
            desconsolidationList: [],
            dataBlHouseContainer: [],
            ActiveRegister: false,
            showIndex: 0,
            IdDesconsolidateToPendingBl: '',
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    async function getYardCargo() {
        await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
        .then(response => {
            let List = response.data.data;
            if (List&&List.length!=0) {
            if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
                let yardCargoReception = '', yardCargoDispatch = ''; 

                yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
                yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

                this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
            } 
            else{
                this.$store.state.yardManagement.dataContainer = List[0];
            }
            
            if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
                this.$store.state.yardManagement.globalSelectContainer = 
                this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
                    return Select.CargoId == this.CargoId ? List[0] : Select;
                });
            }
            }else{
            this.$store.state.yardManagement.dataContainer = {};
            this.$store.state.yardManagement.ListReceivedContainers = [];
            this.$store.state.yardManagement.ListDispatchedContainers = [];
            this.$store.state.yardManagement.ListRecognitionContainers = [];
            }
        })
        .catch( err => {
            this.$store.state.yardManagement.dataContainer = {};
            this.$store.state.yardManagement.ListReceivedContainers = [];
            this.$store.state.yardManagement.ListDispatchedContainers = [];
            this.$store.state.yardManagement.ListRecognitionContainers = [];
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    async function getBlHousePendingsReception(loading) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardWBlCargoHousePackaging-by-YardCargoId', { YardCargoId: this.dataContainer.YardCargoId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouseContainer = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.BlCollapse = 1;
                    this.$store.state.yardManagement.FgBlNavigation = true;
                    this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    async function getBlHouseData() {
        await this.$http.get('BlHouseData-by-CargoId', { CargoId: this.dataContainer.CargoId })
        .then(response => {
            this.HouseData = response.data.data[0] ?? []; 
        })
        .catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    async function Update(loading) {
        this.$store.state.yardManagement.loading = true;
        await this.getYardCargo();
        await this.getBlHousePendingsReception(loading);
    }

    async function UpdateDesconsolidated(loading) {
        await this.getBlHousePendingsReception(loading);
    }

    async function BeforeReceptionDesconsolidation(item) {
        this.$store.state.yardManagement.loading = true;
        await this.getYardCargo();
        this.ReceptionDesconsolidation(item)
    }

    async function ReceptionDesconsolidation(item) {
        this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId: item.YardCargoEventDetailId, DeconsolidationDate: item.TransactionBegin};
        this.showIndex = 1;
    }

    async function toggleAdd(item) {
        await this.getBlHouseData();
        this.ContainerItem = {...item, isProfileBl: false};

        
        if(this.IdDesconsolidateToPendingBl != ''){
            this.ContainerItem = {...this.ContainerItem, YardCargoEventDetailId: this.IdDesconsolidateToPendingBl};
            this.showIndex = 1;
        }
        else{
            if(item.DeconsolidationJson.length>0){
                if(item.DeconsolidationJson.length == 1){
                    /*this.desconsolidationList = item.DeconsolidationJson;
                    this.ReceptionDesconsolidation(item)*/
                    this.desconsolidationList = item.DeconsolidationJson;
                    this.showIndex = 3;
                }
                else{
                    this.desconsolidationList = item.DeconsolidationJson;
                    this.showIndex = 3;
                }   
            }
            else{
                this.showIndex = 2;
                this.ActiveRegister = true;
            }
        }
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
                return 'danger'
            case 'INACTIVE':
                return 'danger'
            default:
                return 'success'
        }
    }
    
    //computed
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouseContainer.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpBlName: item.TpBlName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Planned: item.PlannedQuantityReceived ? NumberFormater.formatNumber(item.PlannedQuantityReceived, 2) : '0,00',
                Received: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                RemainReceived: item.QuantityRemainReceived ? NumberFormater.formatNumber(item.QuantityRemainReceived, 2) : '0,00',
                Packaging: Detail,
                SerialParent: item.SerialParent ?? '',
                PackagingParentName: item[`PackagingParentName${_lang}`] ?? 'N/A',
                DaysInYard: item[`DaysInYard${_lang}`] ?? '',
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`,
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;' },
            { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'SerialParent', label: this.$t('label.originSerial'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'PackagingParentName', label: this.$t('label.originPackaging'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'Packaging', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px; text-align:center;' },  
            { key: 'Planned', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
            { key: 'Received', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'RemainReceived', label: this.$t('label.CertifiedPendingReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'ComputedWeight', label:  `${this.$t('label.weight') } (KGM) ${this.$t('label.planned')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;' },
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³) ${this.$t('label.planned')}`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
        ];
    }

    function fieldsDesconsolidation(){
        return [
            {
                key: 'buttonOptions', 
                label: '', 
                _style: 'min-width: 45px; width: 1%; text-align:center; ', 
                sorter: false, 
                filter: false
            },
            { key: 'number', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
            { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
            { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
            { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 160px; text-align:center;', _classes:"text-center" },
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
            
        ];
    }

    function formatedDesconsolidationItems() {
        let _lang = this.$i18n.locale;
        return this.desconsolidationList.map((item, index) => {
            return {
                    ...item,
                    Nro: index+1,
                    YardName: item?.YardName? item?.YardName??'' : this.yardData.YardName ?? '',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                    TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                    MachineCode: item.MachineCode ?? '',
                    DeviceName: item.DeviceName ?? '',
                    TransaLogin: item.TransaLogin ?? '',
                    ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
                    ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
                    EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
                    EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
                    
                };
        })
    }

    function fgDesconsolidate() {
        return ( 
            (process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED != this.dataContainer.BlJson?.[0]?.YardCargoBlStatus) && 
            (this.HouseData?.BlHouseCount && (NumberFormat(this.HouseData?.BlHouseCount??0)>0)) && 
            this.dataContainer?.FgCertified && this.dataContainer?.TpCargoStatusId == process.env.VUE_APP_TP_CARGO_STATUS_FULL 
        )
    }

    export default{
        name: 'pendingsBls',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
            DesconsolidatedRegister
        },
        methods: {
            toggleAdd,
            getYardCargo,
            getBlHousePendingsReception,
            Update,
            ReceptionDesconsolidation,
            BeforeReceptionDesconsolidation,
            UpdateDesconsolidated,
            NumberFormat,
            getBadge,
            getBlHouseData
        },
        computed: {
            fields,
            fgDesconsolidate,
            computedListContainer,
            ...mapState({
                fieldsDesconsolidation,
                formatedDesconsolidationItems,
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropItemEvent: state => state.yardManagement.dropItemEvent,
                dataBl: state => state.yardManagement.dataBl,
                dataContainer: state => state.yardManagement.dataContainer,
            })
        },
        watch:{
            dropItemEvent: function (val) {
                if (val==3) {
                    this.IdDesconsolidateToPendingBl = '';
                    this.showIndex = 0;
                    this.ActiveRegister=false;
                    this.desconsolidationList = [];
                    this.dataBlHouseContainer = [];
                    this.getBlHousePendingsReception(false);
                }

                if(this.$store.state.yardManagement.IdDesconsolidateToPendingBl != ''){
                    this.IdDesconsolidateToPendingBl = this.$store.state.yardManagement.IdDesconsolidateToPendingBl;
                    this.$store.state.yardManagement.IdDesconsolidateToPendingBl = '';
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>