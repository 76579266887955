<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfReeferContainers') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :value.sync="preference"
                    v-model.trim="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="filterPreference"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    :disabled = "disabledFilterByPreference"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getReeferList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            preference:1,
            preferenceOptions: [],
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            disabledFilterByPreference: false,
            nulo:'', 
            pageSize:'',
            search: '',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES") {
            return 'bg-confirmed'
        }
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }     
            this.unitMeasureId = listado[0].UnitMeasureId;                 
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.listOfReeferContainers'));
            }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.listOfReeferContainers'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
    }

    async function getReeferList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i = 1;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);       

        let CargoSumaryJson = {
            UnitMeasureId: this.unitMeasureId,
            VisitId: this.Visit,
            CompanyBranchId: this.branch.CompanyBranchId,
            VisitCraneId:this.VisitCraneId,
            DateStart:dateStart,
            DateEnd:dateEnd,
            UserPreference: this.preference
        };

        await this.$http.post("VisitCargoReefer-list", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            let _this = this;
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado =='') return false;
            if(this.VisitCraneId !== '' || this.VisitCraneId !== null)
                this.CraneAlias = listado[0].CraneAlias;

            let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            this.formatedItems = listado.map(listado => {
                return {
                    ...listado,
                    IdX: i++,
                    BayCode: listado.BayCode ? listado.BayCode : '',
                    BaySlot: listado.BaySlot ? listado.BaySlot : '',
                    VesselUbicationName: listado['VesselUbicationName'+_lang] ?? '',
                    VesselHoldName: listado.VesselHoldName ?? '',
                    HatchCoverName: listado.HatchCoverName ?? '',
                    ContainerCode: listado.ContainerCode ? listado.ContainerCode : '',
                    Size: listado.Size ? listado.Size : '',
                    LoadPort: listado.LoadPort ? listado.LoadPort : '',
                    DischargePort: listado.DischargePort ? listado.DischargePort  : '',
                    Weigth: listado.Weigth,
                    ShippingLineCode: listado.ShippingLineCode ? listado.ShippingLineCode : '',
                    ShippingLineName: listado.ShippingLineName ? listado.ShippingLineName : '',
                    Setting: listado.Setting ? listado.Setting : '',
                    Unit: listado.Unit ? listado.Unit : '',
                    MinimumRangeRf: listado.MinimumRangeRf ? listado.MinimumRangeRf : '',
                    MaximumRangeRf: listado.MaximumRangeRf ? listado.MaximumRangeRf : '',
                    PackGroup: listado.PackGroup ? listado.PackGroup : '',
                    Commodity: listado.Commodity ? listado.Commodity : '',
                    Status: listado.Status ? listado.Status : '',
                    TpCargoDetailCode: listado.TpCargoDetailCode,
                    ImdgCode: listado.ImdgCode ? listado.ImdgCode : '',
                    Seals: listado.Seals ? listado.Seals : '', 
                    IsoCode: listado.IsoCode ? listado.IsoCode : '', 
                    DeliveryPort: listado.DeliveryPort ? listado.DeliveryPort : '', 
                    CraneAlias: listado.CraneAlias ? listado.CraneAlias : '',
                    FgConfirmed: listado.FgConfirmed === true ? _this.$t('label.yes') : 'NO',
                    ConfirmationDate: listado.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(listado.ConfirmationDate) : '',
                    TpCargoDetailCode: listado.TpCargoDetailCode ? listado.TpCargoDetailCode : '',
                    Procedence: listado.Procedence,
                    YardNamePlanning: listado.YardNamePlanning, 
                    FgDirect: listado.FgDirect? 'YES' : 'NO'
                }
            });

            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
            
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {       
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.unitMeasureId= '';
        this.VisitCraneId= '';
        this.preference= 1;
        this.disabledFilterByPreference= false;
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getDateRange();
        await this.getPreferenceList();
        await this.getCraneList();
        await this.getUnitMeasureWeigthList();
        await this.getReeferList();
    }

    function filterPreference(e) {
        this.preference=e.target.value;
        if (this.preference == 3)
            this.disabledFilterByPreference= true;
        else
            this.disabledFilterByPreference= false;
    } 


    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
//computed
    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    field: "IdX",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                
            ]

            if (!this.FgGeneralCargo) {
                columnDefs.push({
                    field: "BayCode",
                    headerName: "BAY",
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 80,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "BaySlot",
                    headerName: "SLOT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                })
            } else {
                columnDefs.push({
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "VesselUbicationName",
                    headerName: "LOCATION",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: "HOLD",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "HatchCoverName",
                    headerName: "HATCH COVER",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                })
            }

            columnDefs.push(
            {
                field: "TpCargoDetailCode",
                headerName: "SIZE (FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "IsoCode",
                headerName: "ISO CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Seals",
                headerName: "SEALS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Status",
                headerName: "STATUS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineName",
                headerName: "CARRIER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ImdgCode",
                headerName: "IMDG CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "LoadPort",
                headerName: "LOAD PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "DeliveryPort",
                headerName: "DELIVERY PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "CraneAlias",
                headerName: "CRANE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 150,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                let variable = 0.00;
                if(params.value !== null && params.value !== '' && params.value !== 0){
                    variable = parseFloat(params.value).toFixed(2);
                }
                return new Intl.NumberFormat("de-DE").format(variable);
                },
                filter: "agNumberColumnFilter",
            },
            {
                field: "Setting",
                headerName: "REEFER SETTING",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "Unit",
                headerName: "UNIT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "MinimumRangeRf",
                headerName: "MINIMUM RANGE RF",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "MaximumRangeRf",
                headerName: "MAXIMUM RANGE RF",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "PackGroup",
                headerName: "PACKAGING GROUP",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Commodity",
                headerName: "COMMODITY",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                minWidth: 150,
                cellClass: 'center-cell-especial',
            },
            {
                field: "FgConfirmed",
                headerName: "CONFIRMED",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ConfirmationDate",
                headerName: "CONFIRMATION",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            })

            if(this.columnsOption.Yard){
                columnDefs.push({
                    field: "YardNamePlanning",
                    headerName: "YARD",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }

            if(this.columnsOption.Procedence){
                columnDefs.push({
                    field: "Procedence",
                    headerName: "PROCEDENCE",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }

            if(this.columnsOption.DirectDischarge){
                columnDefs.push({
                    field: "FgDirect",
                    headerName: "DIRECT DISCHARGE",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }
            return columnDefs;
    }
    export default {
        name: "reefer-list",
        data,
        beforeMount,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onGridReady,
            onBtnExport,
            getReeferList,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            onBtnPdf,
            getCraneList,
            filterCrane,
            validateDateRange,
            refreshComponent,
            getPreferenceList,
            filterPreference,
            getDateRange,
        },
        computed:{
            preferenceListFormatted,
            craneListFormatted,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==26) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>