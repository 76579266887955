<template>
  <form @submit.stop.prevent="submit">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="getTitulo"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
    <CRow>
      <CCol sm="12" lg="12">
        <CSelect
          :label="$t('label.eirType')"
          :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          @change="changeTpEirId($event)"
          :value="TpEirId"
          :options="computedTpEirList"
          v-model.trim="$v.TpEirId.$model"
          :is-valid="hasError($v.TpEirId)"
          :invalid-feedback="errorMessage($v.TpEirId)"
        />
      </CCol>
      <CCol sm="12" lg="12">
        <CSelect
          :label="$t('label.cargoGroup')"
          :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :value="GpoCargoId"
          :options="computedGpoCargoList"
          v-model.trim="$v.GpoCargoId.$model"
          :is-valid="hasError($v.GpoCargoId)"
          :invalid-feedback="errorMessage($v.GpoCargoId)"
        />
      </CCol>
      </CRow>
        <CRow>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.name')+' '+('(ES)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.eirTypeTransactionName')+' '+('(ES)')"
                v-model.trim="$v.TpTransacEirNameEs.$model"
                :is-valid="hasError($v.TpTransacEirNameEs)"
                :invalid-feedback="errorMessage($v.TpTransacEirNameEs)"
              />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.name')+' '+('(EN)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.eirTypeTransactionName')+' '+('(EN)')"
                v-model.trim="$v.TpTransacEirNameEn.$model"
                :is-valid="hasError($v.TpTransacEirNameEn)"
                :invalid-feedback="errorMessage($v.TpTransacEirNameEn)"
              />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.acronym')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.acronym')"
                v-model.trim="$v.Acronym.$model"
                :is-valid="hasError($v.Acronym)"
                :invalid-feedback="errorMessage($v.Acronym)"
              />
            </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="12">
            <CInput
              type="color"
              :label="$t('label.color')"
              addLabelClasses="required text-right"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
              :invalid-feedback="errorMessage($v.Color)"
              :is-valid="hasError($v.Color)"
              v-model.trim="$v.Color.$model"
            />
            </CCol>
        </CRow>
      <CRow>
        <CCol sm="11" lg="12">
          <CSelect
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="statusConfirmation(TpEirTranscObj.FgActTpTransacEir, Status, submit)"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
          :disabled="isSubmit"
        >
          <CIcon name="x" />
          <span class="ml-1">
            {{$t('button.cancel')}}
          </span>
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirId: '',
    GpoCargoId: '',
    TpTransacEirNameEs: '',
    TpTransacEirNameEn: '',
    Acronym: '',
    Color: '',
    Status: 0,
    TpEirTransacId: '',
    tpEirSelect: [],
    gpoCargoSelect: [],
    apiStateLoading: false,
    TpEirTranscObj: {},
    EirTranscItems: [],
    titulo: '',
  };
}

function changeTpEirId(event) {
  this.TpEirId = event.target.value;
}

function getTpEirList(loading = false) {
  this.apiStateLoading = true;
  this.$http
    .get('TpEir-list?Filter=ACTIVO')
    .then((res) => (this.tpEirSelect = res.data.data));
  this.apiStateLoading = loading; 
}

function getGpoCargoList(loading = false) {
  this.apiStateLoading = true;
  this.$http
    .get('GpoCargo-list?Filter=ACTIVO')
    .then((res) => (this.gpoCargoSelect = res.data.data));
  this.apiStateLoading = loading; 
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  else
    this.$v.$touch();
  this.modalActive = newVal;
}

async function submit() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpEirTransacJson = {
      TpEirId: this.TpEirId,
      GpoCargoId: this.GpoCargoId,
      TpEirTransacId: this.TpEirTransacId,
      TpTransacEirNameEs: this.TpTransacEirNameEs,
      TpTransacEirNameEn: this.TpTransacEirNameEn,
      Acronym: this.Acronym,
      Color: this.Color,
      Status: this.Status,
    };

    await this.$http
      .put('TpEirTransac-update', TpEirTransacJson, { root: 'TpEirTransacJson' })
      .then((response) => {
        if (response && response.status === 200) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpEirTransacJson = data;
          this.resetForm();
          this.$emit('set-modal-edit-eir-transc-list', TpEirTransacJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function resetForm() {
  this.TpTransacEirNameEs = '';
  this.TpTransacEirNameEn = '';
  this.Acronym = '',
  this.Color = '',
  this.TpEirId = '';
  this.GpoCargoId = '';
  this.TpEirTransacId = '';
  this.Status = 0;
  this.$v.$reset();
}

function mountInputsValues(item) {
  this.TpEirTranscObj = item;
  this.TpTransacEirNameEs = this.TpEirTranscObj.TpTransacEirNameEs;
  this.TpTransacEirNameEn = this.TpEirTranscObj.TpTransacEirNameEn;
  this.GpoCargoId = this.TpEirTranscObj.GpoCargoId;
  this.Acronym = this.TpEirTranscObj.Acronym;
  this.Color = this.TpEirTranscObj.Color;
  this.TpEirId = this.TpEirTranscObj.TpEirId;
  this.TpEirTransacId = this.TpEirTranscObj.TpEirTransacId;
  this.Status = this.TpEirTranscObj.FgActTpTransacEir?1:0;

  let _lang = this.$i18n.locale;
  let title = _lang=='en' ?  this.TpTransacEirNameEn : this.TpTransacEirNameEs;
  this.titulo = `${this.$t('label.edit')} ${this.$t('label.eirTransaction')}: ${title}`;
}

function computedTpEirList(){
  let _lang = this.$i18n.locale;
  if(this.tpEirSelect.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpEirSelect.map(function(e){
      chart.push({
        value: e.TpEirId, 
        label: _lang=='en' ? e.TpEirNameEn : e.TpEirNameEs,
      })    
    })
    return chart;
  }
}

function getTitulo() {
  return this.titulo;
}

function computedGpoCargoList(){
  let _lang = this.$i18n.locale;
  if(this.gpoCargoSelect.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.gpoCargoSelect.map(function(e){
      chart.push({
        value: e.GpoCargoId, 
        label: _lang=='en' ? e.GpoCargoNameEn : e.GpoCargoNameEs,
      })    
    })
    return chart;
  }
}

export default {
  name: 'modal-edit-eir-transc',
  props: { modal: Boolean, EirTranscSelect: Object },
  data,
  mixins: [
    GeneralMixin, 
    ModalMixin
  ],
  directives: UpperCase,
  validations: {
    TpTransacEirNameEs: { required, maxLength: maxLength(100) },
    TpTransacEirNameEn: { required, maxLength: maxLength(100) },
    Acronym: { required, maxLength: maxLength(10)},
    Color: { required},
    TpEirId:{required},
    Status: { required },
    GpoCargoId:{required}
  },
  methods: {
    changeTpEirId,
    toggle,
    resetForm,
    statusSelectColor,
    mountInputsValues,
    submit,
    getTpEirList,
    getGpoCargoList
  },
  computed: {
    computedTpEirList,
    computedGpoCargoList,
    getTitulo
  },
  mounted: getTpEirList,
  watch: {
    EirTranscSelect: function(val) {
      this.mountInputsValues(val);
    },
    modal: function(val){
      this.modalActive = val;
      if(val){
         this.getTpEirList(true);
         this.getGpoCargoList();
      }
    }
  }, 
};
</script>
