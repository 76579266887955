<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <modalSealsImages 
      :title="titleModal"
      style="z-index: 5; position: absolute;" 
      :modal="ShowModalSealsImages" 
      :inspectionImages="inspectionImages"
      :labelModal = "labelModal"
      @close="closeModal2"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      style="z-index: 4; position: relative;"
    >
      <CRow class="mx-1">
        <CCol sm="12" lg="5">
          <CSelect
            :label="$t('label.moment')+' '+$t('label.inspection')"
            addLabelClasses="text-right"
            :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            v-model="FormInspection.seals.inspectionMomentId"
            :value.sync="FormInspection.seals.inspectionMomentId"
            :options="momentInspectionList"
            :is-valid="hasError($v.FormInspection.seals.inspectionMomentId)" 
            :invalid-feedback="errorMessage($v.FormInspection.seals.inspectionMomentId)"
            @blur="$v.FormInspection.seals.inspectionMomentId.$touch()"
            :disabled="!edit"
          />
        </CCol>

        <CCol sm="11" lg="4">
          <div class="form-group form-row mb-0" rol="group">
            <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
              {{$t('label.date')}}
            </label>
            <div class="col-sm-11 col-lg-8 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDate"
                :lang="this.$i18n.locale"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.FormInspection.seals.date.$model"
                :append-to-body="false"
                class="w-100"
                :disabled="!edit"
              >
                <template #input>
                  <CInput
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    v-model="FormInspection.seals.date"
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.FormInspection.seals.date)"
                    v-model.trim="$v.FormInspection.seals.date.$model"
                    @keypress="rightDate($event)"
                    :disabled="!edit"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="11" lg="3">
          <div class="form-group form-row mb-0" rol="group">
            <label class="col-form-label required text-right col-sm-11 col-lg-4" for="hordadesde">
              {{$t('label.hour')}}
            </label>
            <div class="col-sm-11 col-lg-8 input-group">
              <vue-datepicker
                valueType="format"
                type="time"
                :format="'HH:mm'"
                v-model="FormInspection.seals.hour"
                :clearable="false"
                v-model.trim="$v.FormInspection.seals.hour.$model"
                :append-to-body="false"
                class="w-100"
                :disabled="!edit"
              >
                <template #input>
                  <CInput
                    class="w-100 float-left"
                    v-uppercase
                    v-model="FormInspection.seals.hour"
                    placeholder="HH:MM"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.FormInspection.seals.hour)"
                    v-model.trim="$v.FormInspection.seals.hour.$model"
                    @keypress="formatHourMMHH($event)"
                    :disabled="!edit"
                  >
                    <template #append-content>
                      <CIcon name="cil-clock" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="5">
          <CInput
            :label="$t('label.stowageMovement')"
            addLabelClasses="text-right"
            :horizontal ="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            v-model="FormInspection.seals.movStowageName"
            readonly
            :disabled="!edit"
          />
        </CCol>
        <CCol sm="12" lg="4" >
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right required"
            v-model="FormInspection.seals.status"
            :value.sync="FormInspection.seals.status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
            :disabled="!edit"
          />
        </CCol>
      </CRow>
      <CRow class="mx-1">
        <CCol md="12" lg="4" class="bg-watch text-center" align-vertical="center">
           {{$t("label.previous")}}
        </CCol>
        <CCol md="12" lg="8">
          <CRow>
            <CCol md="12" lg="12" class="bg-edit text-center">
              {{$t("label.actualConditions")}}
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" lg="4" class="bg-watch text-center">
              {{$t("label.condition")}}
            </CCol>
            <CCol md="12" lg="4" class="bg-watch text-center">
              {{$t("label.inspection")}}
            </CCol>
            <CCol md="12" lg="3" class="bg-watch text-center">
              {{$t("label.observation")}}
            </CCol>
            <CCol md="12" lg="1" class="bg-watch text-center">
            </CCol>
          </CRow>
        </CCol>       
      </CRow>
      <CRow class="mx-1">
        <CCol md="12" lg="4" class=" text-center pt-3">
          <CRow>  
            <CCol sm="11" lg="12" >
              <CInput
                :placeholder="$t('label.Seal')+' 1'"                                         
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-uppercase
                :label="$t('label.Seal')+' 1'"
                v-model="FormInspection.seals.Seal1.seal"
                :value="FormInspection.seals.Seal1.seal"
                disabled
              />
            </CCol>
            <CCol sm="11" lg="12">
              <CInput
                :placeholder="$t('label.Seal')+' 2'"                                         
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-uppercase
                :label="$t('label.Seal')+' 2'"
                v-model="FormInspection.seals.Seal2.seal"
                :value="FormInspection.seals.Seal2.seal"
                disabled
              />
            </CCol>
            <CCol sm="11" lg="12">
              <CInput
                :placeholder="$t('label.Seal')+' 3'"                                         
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-uppercase
                :label="$t('label.Seal')+' 3'"
                v-model="FormInspection.seals.Seal3.seal"
                :value="FormInspection.seals.Seal3.seal"
                disabled
              />
            </CCol>
            <CCol sm="11" lg="12">
              <CInput
                :placeholder="$t('label.Seal')+' 4'"                                         
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-uppercase
                :label="$t('label.Seal')+' 4'"
                v-model="FormInspection.seals.Seal4.seal"
                :value="FormInspection.seals.Seal4.seal"
                disabled
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol md="12" lg="8">
          <CRow>
            <CCol md="12" lg="4" class="pt-3">
              <CRow class="border-left ">
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal1.seal !== null && FormInspection.seals.Seal1.seal !== '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal1.sealConditionId"
                    :value.sync="conditionSeal1"
                    :options="conditionList"
                    :is-valid="hasError($v.FormInspection.seals.Seal1.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal1.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal1.seal === null || FormInspection.seals.Seal1.seal === '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal1.sealConditionId"
                    :value.sync="conditionSeal1"
                    :options="conditionListEmpty"
                    :is-valid="hasError($v.FormInspection.seals.Seal1.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal1.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal2.seal !== null && FormInspection.seals.Seal2.seal !== '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal2.sealConditionId"
                    :value.sync="conditionSeal2"
                    :options="conditionList"
                    :is-valid="hasError($v.FormInspection.seals.Seal2.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal2.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal2.seal === null || FormInspection.seals.Seal2.seal === '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal2.sealConditionId"
                    :value.sync="conditionSeal2"
                    :options="conditionListEmpty"
                    :is-valid="hasError($v.FormInspection.seals.Seal2.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal2.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal3.seal !== null && FormInspection.seals.Seal3.seal !== '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal3.sealConditionId"
                    :value.sync="conditionSeal3"
                    :options="conditionList"
                    :is-valid="hasError($v.FormInspection.seals.Seal3.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal3.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal3.seal === null || FormInspection.seals.Seal3.seal === '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal3.sealConditionId"
                    :value.sync="conditionSeal3"
                    :options="conditionListEmpty"
                    :is-valid="hasError($v.FormInspection.seals.Seal3.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal3.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal4.seal !== null && FormInspection.seals.Seal4.seal !== '' ">
                  <CSelect
                    v-model="FormInspection.seals.Seal4.sealConditionId"
                    :value.sync="conditionSeal4"
                    :options="conditionList"
                    :is-valid="hasError($v.FormInspection.seals.Seal4.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal4.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12" v-if="FormInspection.seals.Seal4.seal === null || FormInspection.seals.Seal4.seal === '' " >
                  <CSelect
                    v-model="FormInspection.seals.Seal4.sealConditionId"
                    :value.sync="conditionSeal4"
                    :options="conditionListEmpty"
                    :is-valid="hasError($v.FormInspection.seals.Seal4.sealConditionId)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.sealConditionId)"
                    @blur="$v.FormInspection.seals.Seal4.sealConditionId.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12" lg="4" class="pt-3">
              <CRow >
                
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal1.inspection"
                    :value="FormInspection.seals.Seal1.inspection"
                    :is-valid="hasError($v.FormInspection.seals.Seal1.inspection)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.inspection)"
                    @blur="$v.FormInspection.seals.Seal1.inspection.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal2.inspection"
                    :value="FormInspection.seals.Seal2.inspection"
                    :is-valid="hasError($v.FormInspection.seals.Seal2.inspection)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.inspection)"
                    @blur="$v.FormInspection.seals.Seal2.inspection.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal3.inspection"
                    :value="FormInspection.seals.Seal3.inspection"
                    :is-valid="hasError($v.FormInspection.seals.Seal3.inspection)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.inspection)"
                    @blur="$v.FormInspection.seals.Seal3.inspection.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal4.inspection"
                    :value="FormInspection.seals.Seal4.inspection"
                    :is-valid="hasError($v.FormInspection.seals.Seal4.inspection)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.inspection)"
                    @blur="$v.FormInspection.seals.Seal4.inspection.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12" lg="3" class="pt-3">
              <CRow class=""> 
                
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal1.observation"
                    :value="FormInspection.seals.Seal1.observation"
                    :is-valid="hasError($v.FormInspection.seals.Seal1.observation)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal1.observation)"
                    @blur="$v.FormInspection.seals.Seal1.observation.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal2.observation"
                    :value="FormInspection.seals.Seal2.observation"
                    :is-valid="hasError($v.FormInspection.seals.Seal2.observation)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal2.observation)"
                    @blur="$v.FormInspection.seals.Seal2.observation.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal3.observation"
                    :value="FormInspection.seals.Seal3.observation"
                    :is-valid="hasError($v.FormInspection.seals.Seal3.observation)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal3.observation)"
                    @blur="$v.FormInspection.seals.Seal3.observation.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
                <CCol sm="11" lg="12">
                  <CInput
                    v-uppercase
                    v-model="FormInspection.seals.Seal4.observation"
                    :value="FormInspection.seals.Seal4.observation"
                    :is-valid="hasError($v.FormInspection.seals.Seal4.observation)" 
                    :invalid-feedback="errorMessage($v.FormInspection.seals.Seal4.observation)"
                    @blur="$v.FormInspection.seals.Seal4.observation.$touch()"
                    :disabled="!edit"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="11" lg="1" class="pt-3">
              <CRow class=""> 
                <CCol sm="11" lg="12" class="mb-3" style="height: 35px;">
                  <CButton
                      color="watch"
                      square
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.image'),
                        placement: 'top-end',
                      }"
                      size="sm"
                      @click="ShowModalImages(FormInspection.seals.Seal1)" 
                      v-if="FormInspection.seals.Seal1.image.length > 0"
                    >
                      <CIcon name="eye" />
                    </CButton>
                </CCol>
                <CCol sm="11" lg="12" class="mb-3" style="height: 35px;">
                  <CButton
                      color="watch"
                      square
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.image'),
                        placement: 'top-end',
                      }"
                      size="sm"
                      @click="ShowModalImages(FormInspection.seals.Seal2)"  
                      v-if="FormInspection.seals.Seal2.image.length > 0"
                    >
                      <CIcon name="eye" />
                    </CButton>
                </CCol>
                <CCol sm="11" lg="12" class="mb-3" style="height: 35px;">
                  <CButton
                      color="watch"
                      square
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.image'),
                        placement: 'top-end',
                      }"
                      size="sm"
                      @click="ShowModalImages(FormInspection.seals.Seal3)"  
                      v-if="FormInspection.seals.Seal3.image.length > 0"
                    >
                      <CIcon name="eye" />
                    </CButton>
                </CCol>
                <CCol sm="11" lg="12" class="mb-3" style="height: 35px;">
                    <CButton
                      color="watch"
                      square
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.image'),
                        placement: 'top-end',
                      }"
                      size="sm"
                      @click="ShowModalImages(FormInspection.seals.Seal4)" 
                      v-if="FormInspection.seals.Seal4.image.length > 0" 
                    >
                      <CIcon name="eye" />
                    </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add"  @click="changeStatus()" v-if="edit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal" v-if="edit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
        <CButton color="wipe" @click="closeModal" v-if="!edit">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import modalSealsImages from './modal-seals-images';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import { FormInspection } from '@/_validations/inspection/inspectionSealsValidation';

function data() {
  return {
    showModal: false,
    titleModal: this.$t('label.inspectionSeals'),
    loadingOverlay: false,
    newUnit: false,
    momentInspectionList: [],
    conditionList: [],
    ShowModalSealsImages:false,
    inspectionImages: [],
    labelModal: '',

    //MODELO
    VisitId: '',
    
    FormInspection:{
    //modelo precintos
      seals: {
        date: 'DD/MM/YYYY',
        hour: 'HH:MM',
        inspectionMomentId: '',
        movStowageName: '',
        status: 1,
        FgActStatus: 1,
        
        Seal1: {
          sealConditionId: '',
          seal: '',
          inspection: '',
          observation: '',
          image:'',
        },
        Seal2: {
          sealConditionId: '',
          seal: '',
          inspection: '',
          observation: '',
          image:'',
        },
        Seal3: {
          sealConditionId: '',
          seal: '',
          inspection: '',
          observation: '',
          image:'',
        },
        Seal4: {
          sealConditionId: '',
          seal: '',
          inspection: '',
          observation: '',
          image:'',
        },
      },
    },

    verifySeal1: false,
    verifySeal2: false,
    verifySeal3: false,
    verifySeal4: false,

    conditionSeal1: '',
    conditionSeal2: '',
    conditionSeal3: '',
    conditionSeal4: '',

    isSubmit: false,
    showTimePanel: false,
    openDate: false,
    previousDate: new Date(),
    laterDate: new Date(),
    visitCargoInspectionId: '',
    withoutSealId: '3AF77F9D-F00E-4390-A6C2-F11F8E8E6F6C',
  };
}

function getMomentList() {
  this.loadingOverlay = true;
  this.momentInspectionList = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
  
    this.$http.get("InspectionMoment-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.momentInspectionList = listado.map(listado => Object.assign({}, this.momentInspectionList, {
      value: listado.InspectionMomentId,
      label: _this=='en' ? listado.InspectionMomentNameEn : listado.InspectionMomentNameEs,
    }));

    this.momentInspectionList.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function SealConditionList() {
  this.loadingOverlay = true;
  this.conditionList = [];
  let _this = this.$i18n.locale;
  
  let listado = Array;
    this.$http.get("SealCondition-list", { })
  .then(response => {
    listado = [...response.data.data];
    this.conditionList = listado.map(listado => Object.assign({}, this.conditionList, {
      value: listado.SealConditionId,
      label: _this=='en' ? listado.SealConditionNameEn : listado.SealConditionNameEs,
    }));

    this.conditionList.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function submit(){
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.FormInspection.seals.$touch();
    let verifySeals;

    if(
        (this.FormInspection.seals.Seal1.inspection==='' && this.FormInspection.seals.Seal1.sealConditionId==='') ||
        (this.FormInspection.seals.Seal2.inspection==='' && this.FormInspection.seals.Seal2.sealConditionId==='') ||
        (this.FormInspection.seals.Seal3.inspection==='' && this.FormInspection.seals.Seal3.sealConditionId==='') ||
        (this.FormInspection.seals.Seal4.inspection==='' && this.FormInspection.seals.Seal4.sealConditionId==='')
      )
      {
      this.verifySeal1 = true;  
      this.verifySeal2 = true;   
      this.verifySeal3 = true;  
      this.verifySeal4 = true; 
      verifySeals = true;

      if(this.FormInspection.seals.Seal1.inspection!=='' && this.FormInspection.seals.Seal1.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }
      
      if(this.FormInspection.seals.Seal2.inspection!=='' && this.FormInspection.seals.Seal2.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false; 
      }
       
      if(this.FormInspection.seals.Seal3.inspection!=='' && this.FormInspection.seals.Seal3.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }

      if(this.FormInspection.seals.Seal4.inspection!=='' && this.FormInspection.seals.Seal4.sealConditionId!==''){
        this.verifySeal1 = false; 
        this.verifySeal2 = false;   
        this.verifySeal3 = false;  
        this.verifySeal4 = false; 
        verifySeals = false;
      }

      this.loadingOverlay = false;
      this.isSubmit = false;
      if (verifySeals) throw this.$t('label.requiresAtLeastOneSealInspection');
    }

    if (this.$v.FormInspection.seals.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let dateNow = new Date();
    let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
    let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
    let startTime = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    let startDate = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

    if((this.FormInspection.seals.date === currentDate) && (this.FormInspection.seals.hour > currentTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsCurrentDateTimePleaseCheck');
    }

    if((this.FormInspection.seals.date === startDate) && (this.FormInspection.seals.hour < startTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsStartDateTimePleaseCheck');
    }

    const CargoJson = {
      VisitCargoInspectionId: this.visitCargoInspectionId ,
      InspectionMomentId: this.FormInspection.seals.inspectionMomentId,

      Seal1: this.FormInspection.seals.Seal1.inspection,  
      Seal1ConditionId: this.FormInspection.seals.Seal1.sealConditionId,  
      ObservationSeal1: this.FormInspection.seals.Seal1.observation,  

      Seal2: this.FormInspection.seals.Seal2.inspection,  
      Seal2ConditionId: this.FormInspection.seals.Seal2.sealConditionId,  
      ObservationSeal2: this.FormInspection.seals.Seal2.observation,  

      Seal3: this.FormInspection.seals.Seal3.inspection,  
      Seal3ConditionId: this.FormInspection.seals.Seal3.sealConditionId,  
      ObservationSeal3: this.FormInspection.seals.Seal3.observation,  
      
      Seal4: this.FormInspection.seals.Seal4.inspection,  
      Seal4ConditionId: this.FormInspection.seals.Seal4.sealConditionId,  
      ObservationSeal4: this.FormInspection.seals.Seal4.observation,  
          
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.FormInspection.seals.date+' '+this.FormInspection.seals.hour),
      Status: this.FormInspection.seals.status,
    };

    let method = 'PUT';
    let ruta = 'VisitCargoSealInspection-update';

      this.$http
      .ejecutar (method, ruta, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
         //this.$emit('child-refresh');
          this.closeModal();
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
} 

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getDateRange(){
  let currentDate = new Date();

  if(this.itinerarySelected.Ata!=undefined){ 
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
  }

  if(this.itinerarySelected.Ata==undefined){
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
  }

  if(this.itinerarySelected.Atd!=undefined){  
    this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
  }

  if(this.itinerarySelected.Atd==undefined){
    this.laterDate = currentDate;
  }
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal = false;
  this.openDate = false;
}

function closeModal2() {
  this.ShowModalSealsImages=false;
  this.inspectionImages=[];
  this.labelModal = '';
}


function refreshComponent() {
  this.FormInspection.seals.inspectionMomentId = '';
  this.FormInspection.seals.date = '';
  this.FormInspection.seals.hour = '';
  this.FormInspection.seals.movStowageName = '';
  this.FormInspection.seals.status = 1;
  this.FormInspection.seals.FgActStatus = 1;
  this.FormInspection.visitCargoInspectionId = '';

  this.FormInspection.seals.Seal1.inspection = '';
  this.FormInspection.seals.Seal2.inspection = '';
  this.FormInspection.seals.Seal3.inspection = '';
  this.FormInspection.seals.Seal4.inspection = '';

  this.FormInspection.seals.Seal1.sealConditionId = '';
  this.FormInspection.seals.Seal2.sealConditionId = '';
  this.FormInspection.seals.Seal3.sealConditionId = '';
  this.FormInspection.seals.Seal4.sealConditionId = '';

  this.FormInspection.seals.Seal1.seal = '';
  this.FormInspection.seals.Seal2.seal = '';
  this.FormInspection.seals.Seal3.seal = '';
  this.FormInspection.seals.Seal4.seal = '';

  this.FormInspection.seals.Seal1.observation = '';
  this.FormInspection.seals.Seal2.observation = '';
  this.FormInspection.seals.Seal3.observation = '';
  this.FormInspection.seals.Seal4.observation = '';

  this.showTimePanel = false;
  this.openDate = false;
  this.$v.FormInspection.seals.$reset();
}

function changeStatus() {
  if(this.FormInspection.seals.FgActStatus !== this.FormInspection.seals.status){              
    this.$swal
      .fire(this.alertProperties({
        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.titleModal}?`,
      }))     
      .then((result) => {
      if (result.isConfirmed) {
          this.submit();
      }
    })
  }else{
    this.submit();
  }
}
function statusSelectColor(){
  return this.FormInspection.seals.status === 1;
}

function setDataEditSeal(modal) {
  //this.loadingOverlay = true;
  this.visitCargoInspectionId = modal.VisitCargoInspectionId;
  this.FormInspection.seals.inspectionMomentId = modal.InspectionMomentId;
  this.FormInspection.seals.hour = modal.TransaDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(modal.TransaDate)) : '';
  this.FormInspection.seals.date = modal.TransaDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(modal.TransaDate)) : '';
  this.FormInspection.seals.movStowageName = modal.MovStowageName;
  this.FormInspection.seals.status = modal.FgActVisitCargoInspection ? 1:0;
  this.FormInspection.seals.FgActStatus = modal.FgActVisitCargoInspection ? 1:0;

  if(modal.SealInspection.length > 0){
    this.FormInspection.seals.Seal1.inspection = modal.SealInspection[0].Seal1[0].Seal;
    this.FormInspection.seals.Seal2.inspection = modal.SealInspection[0].Seal2[0].Seal;
    this.FormInspection.seals.Seal3.inspection = modal.SealInspection[0].Seal3[0].Seal;
    this.FormInspection.seals.Seal4.inspection = modal.SealInspection[0].Seal4[0].Seal;
    
    this.FormInspection.seals.Seal1.sealConditionId = modal.SealInspection[0].Seal1[0].SealConditionId ? modal.SealInspection[0].Seal1[0].SealConditionId : this.withoutSealId;
    this.FormInspection.seals.Seal2.sealConditionId = modal.SealInspection[0].Seal2[0].SealConditionId ? modal.SealInspection[0].Seal2[0].SealConditionId : this.withoutSealId;
    this.FormInspection.seals.Seal3.sealConditionId = modal.SealInspection[0].Seal3[0].SealConditionId ? modal.SealInspection[0].Seal3[0].SealConditionId : this.withoutSealId;
    this.FormInspection.seals.Seal4.sealConditionId = modal.SealInspection[0].Seal4[0].SealConditionId ? modal.SealInspection[0].Seal4[0].SealConditionId : this.withoutSealId;

    this.FormInspection.seals.Seal1.seal = modal.SealInspection[0].Seal1[0].PreviousSeal;
    this.FormInspection.seals.Seal2.seal = modal.SealInspection[0].Seal2[0].PreviousSeal;
    this.FormInspection.seals.Seal3.seal = modal.SealInspection[0].Seal3[0].PreviousSeal;
    this.FormInspection.seals.Seal4.seal = modal.SealInspection[0].Seal4[0].PreviousSeal;

    this.FormInspection.seals.Seal1.observation = modal.SealInspection[0].Seal1[0].ObservationSeal;
    this.FormInspection.seals.Seal2.observation = modal.SealInspection[0].Seal2[0].ObservationSeal;
    this.FormInspection.seals.Seal3.observation = modal.SealInspection[0].Seal3[0].ObservationSeal;
    this.FormInspection.seals.Seal4.observation = modal.SealInspection[0].Seal4[0].ObservationSeal;

    this.FormInspection.seals.Seal1.image = modal.SealInspection[0].FilesJson[0].Seal1DocumentJson;
    this.FormInspection.seals.Seal2.image = modal.SealInspection[0].FilesJson[0].Seal2DocumentJson;
    this.FormInspection.seals.Seal3.image = modal.SealInspection[0].FilesJson[0].Seal3DocumentJson;
    this.FormInspection.seals.Seal4.image = modal.SealInspection[0].FilesJson[0].Seal4DocumentJson;

    if (this.FormInspection.seals.Seal1.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
      this.FormInspection.seals.Seal1.inspection = this.FormInspection.seals.Seal1.seal;
    }
    if (this.FormInspection.seals.Seal2.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
      this.FormInspection.seals.Seal2.inspection = this.FormInspection.seals.Seal2.seal;
    }
    if (this.FormInspection.seals.Seal3.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
      this.FormInspection.seals.Seal3.inspection = this.FormInspection.seals.Seal3.seal;
    }
    if (this.FormInspection.seals.Seal4.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
      this.FormInspection.seals.Seal4.inspection = this.FormInspection.seals.Seal4.seal;
    }
  }
  this.$v.FormInspection.seals.$touch();
  this.getDateRange();
 // this.loadingOverlay = false;
}

function ShowModalImages(seal){
  this.ShowModalSealsImages =! this.ShowModalSealsImages; 
  this.inspectionImages = seal.image;
  this.labelModal = seal.inspection;
}


function selectOptions(){
  return [
    { 
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    { 
      value: 0, 
      label: this.$t('label.INACTIVO')
    }
  ];
}

function conditionListEmpty(){
  return [
    { 
      value: '', 
      label: this.$t('label.select')
    },
    { 
      value: '3AF77F9D-F00E-4390-A6C2-F11F8E8E6F6C', 
      label: this.$t('label.withoutSeal')
    },
  ]
} 

export default {
  name: 'modal-edit-seals',
  data,
  props: {
    modal: null,
    edit: Boolean,
  },
  mixins: [General, mixinServicio],
  components:{modalSealsImages},
  directives: UpperCase,

  validations(){ return FormInspection(this.verifySeal1, this.verifySeal2,this.verifySeal3,this.verifySeal4) },

  watch: {
    modal: async function () {
      if (this.modal) {
        this.titleModal = this.$t('label.inspectionSeals')+': '+this.modal.ContainerCode;
        this.showModal = true;   
        this.getMomentList();
        this.SealConditionList();        
        this.setDataEditSeal(this.modal);
        this.$emit('closeModal');
        }
      },
    //},
     conditionSeal1: function () {
      if (this.conditionSeal1 !== "" && this.FormInspection.seals.Seal1.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal1.inspection = this.FormInspection.seals.Seal1.seal;
      }else{
        this.FormInspection.seals.Seal1.inspection = "";
      }
    },
    conditionSeal2: function () {
      if (this.conditionSeal2 !== "" && this.FormInspection.seals.Seal2.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal2.inspection = this.FormInspection.seals.Seal2.seal;
      }else{
        this.FormInspection.seals.Seal2.inspection = "";
      }
    },
    conditionSeal3: function () {
      if (this.conditionSeal3 !== "" && this.FormInspection.seals.Seal3.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal3.inspection = this.FormInspection.seals.Seal3.seal;
      }else{
        this.FormInspection.seals.Seal3.inspection = "";
      }
    },
    conditionSeal4: function () {
      if (this.conditionSeal4 !== "" && this.FormInspection.seals.Seal4.sealConditionId == '29A34065-BD61-433C-83BF-653295882CB3') {
        this.FormInspection.seals.Seal4.inspection = this.FormInspection.seals.Seal4.seal;
      }else{
        this.FormInspection.seals.Seal4.inspection = "";
      }
    },
  },
  methods: {
    getMomentList,
    SealConditionList,
    validateDateRange,
    getDateRange,
    functionDate,
    submit,
    closeModal,
    closeModal2,
    refreshComponent,
    changeStatus,
    statusSelectColor,
    setDataEditSeal,
    ShowModalImages,
  },
  computed: {
    conditionListEmpty,
    selectOptions,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,    
    })
  },
};
</script>
<style scope>
.Z-index1{
    z-index: -100;
  }
</style>
