<template>
  <div class="container-fluid">
    <!--loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/-->
    <CTabs variant="tabs"  @update:activeTab="handleTab" :active-tab="0">
      <CTab :title="$t('label.incidences')"  :active="isActiveTab(0)">
        <div v-show="!showCollapseIncidenceType">
          <CRow class="my-3">
            <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start">
              <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.incidenceList') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end">
                
                <h6 class="text-warning mr-3 mt-2">
                    <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.incidenceOpen')}}&nbsp;
                 </h6> 
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
            </CCol>
          </CRow>
          <CRow class="mt-2">
            <CCol sm="12" >
              <dataTableExtended
                class="align-center-row-datatable"
                :fields="fields"
                :items="itemsIncidence"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingOverlay"
                hover
                sorter
                @filtered-items-change="getFilteredList"
              >
                <template #loading>
                  <loading/>
                </template>
                <template #alertGif="{ item }">
                  <td class="text-center">
                    <div class="animation-item ml-2" style="display: inline-block;" v-if="item.FgOpenIncidence">
                        <h3 class="text-danger">
                            <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                        </h3>
                    </div>
                  </td>
                </template>
                <template #Nro="{ item }">
                  <td class="text-center">
                    {{ item.Nro }}
                  </td>
                </template>
                <template #IncidenceName="{ item }">
                  <td class="text-center">
                    {{ item.IncidenceName }}
                  </td>
                </template>
                <template #Detalle="{ item }">
                  <td class="text-center">
                    <CButton
                      size="sm"
                      color="watch"
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.orders'),
                        placement: 'top-end',
                      }"
                      @click="setDataCollapse(item)"
                    >
                      <CIcon name="cil-list"/>
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </div>
        <div v-show="showCollapseIncidenceType">
          <collapseIncidenceType
            :incidence="incidenceData"
            @closeCollapseIncidence="showCollapseIncidenceType=false" 
            @child-refresh="refrescarTabla=true"  
          />
        </div>
      </CTab>   
    </CTabs>  
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import collapseIncidenceType from './index-incidence-type';

function data() {
  return {
    itemsIncidence: [],
    loadingOverlay: false,
    showCollapseIncidenceType: false,
    refrescarTabla: false,
    incidenceData: '',
    VisitId: '',
    activeTab: 0,
    filteredList: [],
  };
}

//methods
async function getIncidenceList () {
  this.loadingOverlay = true;
  
  await this.$http.ejecutar('GET', 'VisitIncidenceSummary', { VisitId: this.VisitId })
    .then(response => {
      let listado = [...response.data.data];
      
      let i = 1;

      this.itemsIncidence =  listado.map((item) => {
          
          return {
              ...item,
              Nro: i++,
          };
      });

  }).catch( err => {
      
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function refreshComponent() {
  this.getIncidenceList();
}

function setDataCollapse(item){
  if(item.OrderJson.length > 0){
    this.incidenceData = item;
    this.showCollapseIncidenceType = true;
  }else{
    this.showCollapseIncidenceType = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailble'),
      type: 'error',
    });
  }
}

function handleTab(tab) {
  this.activeTab = tab;
}

function isActiveTab(current) {
  return this.activeTab == current;
}

async function onBtnPdf(){
  this.loadingOverlay = true;
  let rowData = [];
  let _lang = this.$i18n.locale;
  
  if(this.filteredList.length !== 0) {  
    await this.filteredList.map(async(item,index) => {
      let objeto = {};
      objeto['#'] = index+1;
      objeto[this.$t('label.responsible')] = item.ResponsibleName;
      objeto[this.$t('label.incidenceType')] = _lang == 'en' ? item.IncidenceNameEn : item.IncidenceNameEs;
      objeto[this.$t('label.orderQuantity')] = `${item.CantOrder}`;
      objeto[this.$t('label.delayTime')] = item.DelayHrsDs;
      rowData.push(objeto);
    });

    await this.getPdfArray(rowData,this.$t('label.incidenceList'));
  }else{
      this.$notify({  
          group: 'container',
          title: '¡Error!',
          text: this.$t('label.noRecordsAvailbleReport'),
          type: "error" 
      });
  }
  this.loadingOverlay = false;
}

async function onBtnExport(valor) {
  this.loadingOverlay = true;
  let rowData = [];
  let _lang = this.$i18n.locale;
  if(this.filteredList.length !== 0) {  
    await this.filteredList.map(async(item,index) => {
      let objeto = {};
      objeto['#'] = index+1;
      objeto[this.$t('label.responsible')] = item.ResponsibleName;
      objeto[this.$t('label.incidenceType')] = _lang == 'en' ? item.IncidenceNameEn : item.IncidenceNameEs;
      objeto[this.$t('label.orderQuantity')] = `${item.CantOrder}`;
      objeto[this.$t('label.delayTime')] = item.DelayHrsDs;
      rowData.push(objeto);
    });

    await this.getExcelArray(rowData, this.$t('label.incidenceList'), valor);
  }else{
      this.$notify({  
          group: 'container',
          title: '¡Error!',
          text: this.$t('label.noRecordsAvailbleReport'),
          type: "error" 
      });
  }
  this.loadingOverlay = false; 
}
function getFilteredList(arr) {
    this.filteredList = arr;
}
//computeds
  function formatedItemsInspection() {
    let computedItems = [];
    
    if(this.itemsIncidence.length !== 0){
      let _lang = this.$i18n.locale;
      this.itemsIncidence.map(function(item){
        computedItems.push({
          Nro: item.Nro ? item.Nro : '',
          ResponsibleName: item.ResponsibleName ? (item.ResponsibleRif+' '+item.ResponsibleName) : '',
          ResponsibleRif: item.ResponsibleRif ? item.ResponsibleRif : '',
          IncidenceNameEn: _lang == 'en' ? item.IncidenceNameEn : '',
          IncidenceNameEs: _lang == 'en' ? item.IncidenceNameEs : '',
          CantOrder: item.CantOrder ? item.CantOrder : '',
          DelayHrsDs: item.DelayHrsDs ? item.DelayHrsDs : '',
          OrderJson: item.OrderJson ? item.OrderJson : '',
          IncidenceId: item.IncidenceId ? item.IncidenceId : '',
          FgOpenIncidence: item.FgOpenIncidence ? true : false,
        })
      }) 
    }
    return computedItems;
  }

function fields(){
      let _lang = this.$i18n.locale;

  return [
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:2%; text-align:center'  },
    { key: 'ResponsibleName', label:this.$t('label.responsible'), _style: 'width:27%;', _classes: 'text-uppercase'},
    { key: _lang == 'en' ? 'IncidenceNameEn' : 'IncidenceNameEs', label:this.$t('label.incidenceType'),_style: 'width:27%;', _classes: 'text-uppercase' },
    { key: 'CantOrder', label:this.$t('label.orderQuantity'),_style: 'width:19%;', _classes: 'text-uppercase' },
    { key: 'DelayHrsDs', label:this.$t('label.delayTime'),_style: 'width:19%;', _classes: 'text-uppercase' },
    
    { key: 'Detalle', label: '',sorter: false,filter: false,_style: 'min-width:45px; width:5%;  '},
  ];
}

export default {
  name: 'index-incidence',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    collapseIncidenceType,
  },
  methods: {
    refreshComponent,
    setDataCollapse,
    getIncidenceList,
    handleTab,
    isActiveTab,
    onBtnPdf,
    onBtnExport,
    getFilteredList,
  }, 
  props: {
  }, 
  watch: {
    dropItemOrder: async function (newValue) {
        if (newValue==3) {
          this.VisitId = this.$store.state.visitas.VisitId;
          if(this.VisitId != ''){
            this.showCollapseIncidenceType = false;
            await this.refreshComponent();
          }
        }
    },
    VisitId:async function(newValue,OldValue){
      if(newValue !== 0){
        this.VisitId = this.$store.state.visitas.VisitId;
        if(this.VisitId != ''){
          this.showCollapseIncidenceType = false;
          this.refreshComponent();
        }
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.showCollapseIncidenceType = false;
        this.incidenceData = '';
        this.getIncidenceList();
        this.refrescarTabla = false;
      }
    },
    active: function(newVal) {
      if(newVal)
        this.activeTab = 0;
    },
  },
  computed: {
    formatedItemsInspection,
    fields,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemOrder: state => state.visitas.dropItemOrder,
    })
  },
};
</script>
<style lang="scss">
  .animation-item{
      -webkit-animation: pulse 2s linear infinite;
      animation: pulse 2s linear infinite;
  }
  /**
  * ----------------------------------------
  * animation pulse
  * ----------------------------------------
  */
  @-webkit-keyframes pulse{0%,100%{transform:scale(1.1)}50%{transform:scale(.8)}}
  @keyframes pulse{
      0%,
      100% {
          transform:scale(1.1)
      }
      50% {
          transform:scale(.8)
      }
  }
</style>
