import {
    onlyMayusTextEspecial,
    onlyMayusText,
    onlyAlphanumeric,
    onlyAlphanumeric4,
    nameLength,
    requiredSelect,
    UtcZone
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal,
    minValue,
    maxValue,
    numeric,
    integer,
} from "vuelidate/lib/validators";

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else{
            if(validURL(value))
                return true;
            else
                return (value != '');
        }
    };

    export const FormMachine = (value) => {
        let ClientTpIdOptional = {};
       
        if(value){
            ClientTpIdOptional = {
                required
            }
        }

        return {
            formMachine: {
                TpMachineId:{
                    required
                },
                MachineConditionId:{
                    required
                },
                ClientTpId:ClientTpIdOptional,
                MarcaId:{
                    required
                },
                ModelId:{
                    required
                },
                MachineName:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                ProcessStatusId:{
                    required
                },
                Year:{
                    numeric,
                    integer,
                   /* minLength: minLength(4),
                    maxLength: maxLength(4)*/
                },
                Cod:{
                    required,
                    onlyAlphanumeric,
                    minLength: minLength(4),
                    maxLength: maxLength(50)
                },
                Serial:{
                    onlyAlphanumeric4,
                    minLength: minLength(4),
                    maxLength: maxLength(50)
                },
                MaxCapacity:{
                    decimal,
                    maxValue:maxValue(99999.99),
                },
                UnitMeasureIdSC:{
                    required
                },
                UnitMeasureIdSD:{
                    required
                },
                MaxRadio:{
                    decimal,
                    maxValue:maxValue(999.99),
                },
                SpeedCharge:{
                    decimal,
                    maxValue:maxValue(999.99),
                },
                /*time:{
                    required
                },*/
                SpeedDischarge:{
                    decimal,
                    maxValue:maxValue(999.99),
                },
                FgOperation:{
                    required
                }
            }
        }
    }

    export const FormPhotoMachine = () => {
        return {
            formPhotoMachine: {
                MachineId:{
                    required
                },
                MachinePhotoDs:{
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(500)
                },
                MachinePhotoRoute:{
                    isValidFile
                },
            }
        }
    }

    export const FormMachineCondition = () => {
        return {
            formMachineCondition: {
                MachineConditionName:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                FgSuplier:{
                    required,
                }
            }
        }
    }
    
    export const FormMachineClassification = () => {
        return {
            formMachineClassification: {
                TpClassificationMachineNameEs:{
                    required,
                    onlyMayusText,
                    nameLength,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                   
                },
                TpClassificationMachineNameEn:{
                    required,
                    onlyMayusText,
                    nameLength,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                TpClassificationMachineDs:{
                    onlyAlphanumeric,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
            }
        }
    }

    export const FormTpMachine = () => {
        return {
            formTpMachine: {
                TpClassificationMachineId:{
                    required
                },
                TpMachineNameEs:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                TpMachineNameEn:{
                    required,
                    onlyMayusText,
                    minLength: minLength(4),
                    maxLength: maxLength(250)
                },
                TpMachineDs:{
                    minLength: minLength(4),
                    maxLength: maxLength(500)
                }
            }
        }
    }