<template>
    <CCard>
        <CCardHeader class="text-center bg-dark text-white">
            <b>{{$t('label.VersionControlList')}}</b>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol sm="12" class="d-flex justify-content-end">
                    <div>
                        <CButton
                            color="add"                            
                            @click="toggleCreate()"
                            v-c-tooltip="{placement:'top-end',content:$t('label.version')}"
                        >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nueva')}}
                        </CButton>
                    </div>
                </CCol>                    
            </CRow>
            <CRow>
                <br>
                <CCol col="12" xl="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        column-filter
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :items="formatedItems"
                        :fields="fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="5"
                        pagination
                        :loading="loading"
                    >
                        <template #loading>
                            <loading/>
                        </template>
                        <template #Status="{ item }">
                            <td class="cell-center text-center">
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template> 
                        <template #options="{item}">
                            <td class="center-cell">
                                <CButton
                                    color="edit"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.version'),
                                        placement: 'top-end'
                                    }"
                                    @click="toggleEdit(item)"
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                                <CButton
                                    class="mr-1"
                                    color="add"
                                    size="sm"
                                    square
                                    v-c-tooltip="{
                                        content: $t('label.downloadApk'),
                                        placement: 'top-end'
                                    }"
                                     :href="item.FileRoute"
                                    >
                                    <CIcon name="cil-cloud-download"/>
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
            <ModalAPK
                :modal.sync="modalActive"
                :title="titleModal"
                :isEdit="isEdit"
                :ApkItem="ApkItem"
                @close="closeModal"
                @Update-list="Update"
            />
        </CCardBody>
    </CCard>
</template>

<script>
    import General from "@/_mixins/general";
    import ModalAPK from './modals/modal-apk';
    import { DateFormater } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';

    //data
    function data() {
        return {      
            titleModal: '',     
            listApk:[],
            isEdit: false,
            ApkItem: {},
            loading: false,
            modalActive:false,
        }
    }
    //methods
    function toggleCreate() {
         this.modalActive = !this.modalActive;
         this.titleModal = this.$t('label.nueva')+' '+this.$t('label.version');
    }

    function toggleEdit(item) {
        this.ApkItem = item;
        this.isEdit = !this.isEdit;
        this.modalActive = true;

        let title =  this.ApkItem.CodVersion;
        this.titleModal =  this.$t('label.edit')+' '+this.$t('label.version')+': '+title;
    }
    function Update() {
        this.modalActive = false;
        this.isEdit = false;
        this.ControlVersionList();
    }

    function closeModal(){
        this.modalActive= false;
        this.isEdit= false;
    }

    function ControlVersionList() {
        this.loading = true;
        this.$http
            .get('SigetMobileVersion-list')
            .then(response => {
            let listado = response.data.data;
            let nulo = String(listado?.[0]?.Json);
            if (nulo=="null" || listado =='' || !listado?.[0]?.Json){
                return false;
            }
            this.listApk = response.data.data;
            })        
            .finally(() => {
            this.loading = false;
            });
    }

    //computed
    function fields(){
        return[
             { key: 'Nro', label: '#',filter :false,_classes:'center-cell text-center',_style: 'width:3%;', },
             { key: 'TpModuleName',label: this.$t('label.typeApk'),_classes:'center-cell text-center', _style:' width: 25%;' },
             { key: 'CodVersion',label: this.$t('label.VersionCode'),_classes:'center-cell text-center', _style:'width: 25%;' },
             { key: 'TransaLogin', label: this.$t('label.user'),_classes:'center-cell text-center', _style:'width: 15%;' },
             { key: 'FormatedDate', label:this.$t('label.date'),_classes:'center-cell text-center',_style:' width: 15%;'},
             { key: 'Status',label: this.$t('label.status'),_classes:'center-cell text-center', _style:'width:10%;' },
             { key: 'options', label: '', _style: 'min-width:90px;', sorter: false, filter: false}
         ]
    }

    function formatedItems() {
        let _this = this.$i18n.locale;
        
        return this.listApk.map((item) => { 
            return {
            ...item,
            
            TpModuleName: _this=='en' ? item.TpModuleNameEn : item.TpModuleNameEs,
            FileRoute: item.SigetMobileRoute
            ? this.$store.getters['connection/getBase']  + item.SigetMobileRoute
            : "N/A",
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
            FormatedDate: item.TransaRegDate
                ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
                : 'N/A',
            };
        });
    }

    export default {
        name:'index',
        data,
        components: { ModalAPK},
        mixins: [General,GeneralMixin],
        methods:{
            toggleCreate,
            closeModal,
            toggleEdit,
            Update,
            ControlVersionList
        },
        computed:{
            formatedItems,
            fields,
        },
        mounted: ControlVersionList,
    }
</script>