import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'

export default (SealConditionIntact) => {
    let ValidateSeal = !SealConditionIntact ? { required } : false;
    return {
        CurrentSeal: { maxLength: maxLength(20), validationSeal },
        NewSeal: { ...ValidateSeal, maxLength: maxLength(20), validationSeal },
        AssignedBy: { },
        Observation: { maxLength: maxLength(20), onlyAlphanumeric5 },
        RouteImage: { },
    }
}