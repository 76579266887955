<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.summaryOfContainersByPortLine') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"  
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm" 
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>      
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"            
                >
                </CInput>  
            </CCol>
            
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CSelect
                    :value.sync="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    addLabelClasses="text-right"
                    @change="getContainerByPortLineSummary"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            items: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
            preference: 1,
            preferenceOptions: [],
            nulo:'',
            pageSize:'',
            search:'',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }     
            this.unitMeasureId = listado[0].UnitMeasureId;       
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.rows.length !== 0) {
            let computedItems = [];

            computedItems.push({
                IdX: '',
                DischargePort: '',
                ShippingLineCode: '',
                Cont20Full: '20F',
                Cont20FullWeight: '20F',
                Cont20Empty: '20E',
                Cont20EmptyWeight: '20E',
                Cont40Full: '40F',
                Cont40FullWeight: '40F',
                Cont40Empty: '40E',
                Cont40EmptyWeight: '40E',
                Cont45Full: '45F',
                Cont45FullWeight: '45F',
                Cont45Empty: '45E',
                Cont45EmptyWeight: '45E',
            });

            this.rows.map(function(item){
                computedItems.push({
                    IdX: item.IdX,
                    DischargePort: item.DischargePort,
                    ShippingLineCode: item.ShippingLineCode,
                    Cont20Full: item.Cont20Full,
                    Cont20FullWeight: formatMilDecimal(parseFloat(item.Cont20FullWeight).toFixed(2)),
                    Cont20Empty: item.Cont20Empty,
                    Cont20EmptyWeight: formatMilDecimal(parseFloat(item.Cont20EmptyWeight).toFixed(2)),
                    Cont40Full: item.Cont40Full,
                    Cont40FullWeight: formatMilDecimal(parseFloat(item.Cont40FullWeight).toFixed(2)),
                    Cont40Empty: item.Cont40Empty,
                    Cont40EmptyWeight: formatMilDecimal(parseFloat(item.Cont40EmptyWeight).toFixed(2)),
                    Cont45Full: item.Cont45Full,
                    Cont45FullWeight: formatMilDecimal(parseFloat(item.Cont45FullWeight).toFixed(2)),
                    Cont45Empty: item.Cont45Empty,
                    Cont45EmptyWeight: formatMilDecimal(parseFloat(item.Cont45EmptyWeight).toFixed(2)),
                })
            });

            //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
            await this.getPdf(computedItems,this.$t('label.summaryOfContainersByPortLine'),'containerByPortLineSummary');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(valor) {
        //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.rows.length !== 0) {
            await this.getExcel(rowData,this.$t('label.summaryOfContainersByPortLine'),valor, 'N/A','N/A','N/A');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;     
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
        this.getContainerByPortLineSummary();
    }

    async function portList () {
        this.loadingOverlay = true;
        this.portOptions = [];
        let listado = Array;
        let i = 0;

        let PortSumaryJson = {
            VisitId: this.Visit
        };

        await this.$http.post("VisitPortsSummary", PortSumaryJson, { root: 'PortSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.portOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.portOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }            

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function getContainerByPortLineSummary () {
        this.loadingOverlay = true;
        this.items = [];
        
        let CargoSumaryJson = {
            VisitId: this.Visit,
            UnitMeasureId: this.unitMeasureId,
            CompanyBranchId: this.branch.CompanyBranchId,
            UserPreference: this.preference
        };

        await this.$http.post("VisitLoadPortSummary-by-operator", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            let listado = [...response.data.data];
            let i = 1;

            this.nulo = String(response.data.data[0].Json);
            
            this.rows =  listado.map((item) => {
                
                return {
                    ...item,
                    IdX: i++,                
                };
            });

            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
                       
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }


    function formatedItems() {
        let computedItems = [];

        if(this.rows.length !== 0 && this.nulo!="null"){
            computedItems.push({
                section: 'total',
                Cont20Full: '20F',
                Cont20Empty: '20E',
                Cont40Full: '40F',
                Cont40Empty: '40E',
                Cont45Full: '45F',
                Cont45Empty: '45E',
            })
            
            this.rows.map(function(item){
                
                computedItems.push({
                    IdX: item.IdX,
                    DischargePort: item.DischargePort,
                    ShippingLineCode: item.ShippingLineCode,
                    Cont20Full: item.Cont20Full,
                    Cont20FullWeight: item.Cont20FullWeight,
                    Cont20Empty: item.Cont20Empty,
                    Cont20EmptyWeight: item.Cont20EmptyWeight,
                    Cont40Full: item.Cont40Full,
                    Cont40FullWeight: item.Cont40FullWeight,
                    Cont40Empty: item.Cont40Empty,
                    Cont40EmptyWeight: item.Cont40EmptyWeight,
                    Cont45Full: item.Cont45Full,
                    Cont45FullWeight: item.Cont45FullWeight,
                    Cont45Empty: item.Cont45Empty,
                    Cont45EmptyWeight: item.Cont45EmptyWeight,
                })
            })

        }
        return computedItems;
    }


    function getRowClass(params) {
        if (params.node.data.DischargePort=="TOTAL") {
            return 'bg-edit font-weight-bold text-white'
        }

        if (params.node.data.section=="total") {
            return 'bg-cell font-weight-bold'
        }
    }
    async function getPreferenceList() {
    await this.$http.get("UserPreference-list")
        .then(response => {
            
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.pageSize='';
        this.unitMeasureId='';
        this.search=''; 
        this.onQuickFilterChanged(this.search);
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.preference= 1;
        await this.getPreferenceList();
        await this.getUnitMeasureWeigthList();
        await this.getContainerByPortLineSummary();
        //this.portList();
        this.$emit('child-refresh',true);
    }

    //computed
    function isTotal(params) {
        return params.data.section === 'total';
    }

    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    function columnDefs(){
        let columnDefs = [
             {
                field: "IdX",
                headerName: "#",
                lockVisible: true,
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                //pinned: 'left',
                cellClass: 'gb-cell',
                //checkboxSelection: true,
                 
            },
            {
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                //pinned: 'left',
                cellClass: 'gb-cell',
            },
            {
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                //pinned: 'left',
                cellClass: 'gb-cell',
            },
            {
                field: "Cont20Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-watch';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont20FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
            {
                field: "Cont20Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-watch';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont20EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
            {
                field: "Cont40Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-plan';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont40FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
            {
                field: "Cont40Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-plan';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont40EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
            {
                field: "Cont45Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-edit';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont45FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
            {
                field: "Cont45Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && !isNaN(params.value)){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }else{
                        return params.value;
                    }
                },
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-edit';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont45EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                        return variable;
                    }
                },
            },
        ]

      
        return columnDefs;
    }
    export default {
        name: "container-by-port-line-summary",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getContainerByPortLineSummary,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            portList,
            getPreferenceList,
            refreshComponent,
        },
        computed:{
            isTotal,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,    
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
            }),
            preferenceListFormatted, 
            columnDefs,
            formatedItems,
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==33) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>