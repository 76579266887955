import { required} from 'vuelidate/lib/validators';
import { onlyAlphanumeric5 } from '@/_validations/validacionEspeciales'

  export default (ValidateHoldDeck, ValidateHold,ValidateUserError) => {
    let ValidateHoldHatch = ValidateHoldDeck ? { required } : {};
    let ValidateHoldId = ValidateHold || ValidateHoldDeck ? { required } : {};
    let ValidateUser = ValidateUserError ? {} : { required };

    return {
        CancelTransacReasonId: { required },
        MovStowageReasonId: ValidateUser,
        VesselUbicationId: { required },
        VisitCraneId: ValidateUser,
        VesselHoldId: ValidateHoldId,
        VesselHoldHatchCId: ValidateHoldHatch,
        Observation: { onlyAlphanumeric5 }
    }
    
  }