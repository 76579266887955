<template>
    <div style="width: 100%">
        <CRow class="cardResumen">
            <CCol md="12" lg="12">
                <div
                    class="cardHeaderResumenPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #203864 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <span style="margin-left:5px;"> {{ $t("label.dailyReport") }} </span>
                </div>
                <CRow style="background: #fff;padding-top:10px;">
                    <CCol lg="12">
                        <div class="container" v-if="Object.keys(operationColumnsDay).length !== 0">
                            <div class="row justify-content-md-center">
                                <div class="col-md-auto report-primary">
                                    <h6 style="margin-bottom: 0px;">TON</h6>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TonXHour) }}</strong> {{ $t('label.PerHours') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TonXImplement) }}</strong> {{ $t('label.PorGamela') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TonDay) }}</strong> {{ $t('label.Diurnal') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TonNight) }}</strong> {{ $t('label.Nocturnal') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TonAccumulated) }}</strong> {{ $t('label.Accumulated') }}</p>
                                    <p class="m-0">({{ operationAta }} - {{ realOperationDate(operationColumnsDay.OperationDate) }})</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.TonJson[0].TotalTonEmbarked) }}</strong> {{ $t('label.TotalLoaded') }}</p>
                                </div>
                                <div class="col-md-auto report-danger">
                                    <h6 style="margin-bottom: 0px;">{{ $t('label.Gamela')+'S' }}</h6>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.ImplementJson[0].ImplementXHour) }}</strong> {{ $t('label.PerHours') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.ImplementJson[0].ImplementDay) }}</strong> {{ $t('label.Diurnal') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.ImplementJson[0].ImplementNight) }}</strong> {{ $t('label.Nocturnal') }}</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.ImplementJson[0].ImplementAccumulated) }}</strong> {{ $t('label.Accumulated') }}</p>
                                    <p class="m-0">({{ operationAta }} - {{ realOperationDate(operationColumnsDay.OperationDate) }})</p>
                                    <p class="m-0"><strong>{{ valuemoney(operationColumnsDay.ImplementJson[0].TotalImplementEmbarked) }}</strong> {{ $t('label.TotalLoaded') }}</p>
                                </div>
                                <div class="col-md-auto report-success">
                                    <h6 style="margin-bottom: 0px;">{{ $t('label.delays') }}</h6>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].VesselDelayHoursDs  }}</strong> {{ $t('label.vessel') }}</p>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].PortDelayHoursDs  }}</strong> {{ $t('label.port') }}</p>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].TerminalDelayHoursDs }}</strong> {{ $t('label.operator') }}</p>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].OtherDelayHoursDs }}</strong> {{ $t('label.other') }}</p>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].TotalDayDelayHoursDs }}</strong> {{ $t('label.TotalDelaysPerDay') }}</p>
                                    <p class="m-0"><strong>{{ operationColumnsDay.DelayJson[0].TotalAccumulatedDelayHoursDs }}</strong> {{ $t('label.TotalDelaysAccumulated') }}</p>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-12" style="margin-top:10px;">
                                    <div class="d-flex">
                                        <div class="p-2 flex-fill" style="height: 400px;">
                                            <div class="title-etd">
                                                <h3>
                                                    ETZ: {{ formateRealTimeDate(operationColumnsDay.EstimationJson[0].EstimatedEtz) }} - {{ $t('label.tonRestante') }}: {{ valuemoney(operationColumnsDay.EstimationJson[0].EstimatedRemainingTon) }} - {{ $t('label.Gamela')+'S' }}: {{ valuemoney(operationColumnsDay.EstimationJson[0].EstimatedCantImplement) }}
                                                </h3>
                                            </div>

                                            <div class="w-100" style="margin-top:10px;">
                                                <div class="d-flex align-items-center">
                                                    <div class="p-2 flex-fill li-planificado d-flex justify-content-center"><h1><li>{{ $t('label.planned') }}</li></h1></div>
                                                    <div class="p-2 flex-fill li-realizado d-flex justify-content-center"><h1><li>{{ $t('label.done') }}</li></h1></div>
                                                </div>
                                            </div>
                                            <div class="w-100 d-flex align-items-center justify-content-center" style="margin-top:10px;">
                                                <TransversalView 
                                                    :vesselType="BulkVesselId"
                                                    :TpVesselId="BulkVesselId"
                                                    :positionJson="operationColumnsDay.PositionJson ? operationColumnsDay.PositionJson : []"
                                                    :fullscreen="true"
                                                />
                                            </div>
                                        </div>
                                        <div class="p-2 flex-fill">
                                            <div class="d-flex">
                                                <div class="p-2 d-flex align-content-center">
                                                    <div class="vertical-letra">
                                                        <h1>{{ $t('label.lapse')+'S' }}</h1>
                                                    </div>
                                                </div>
                                                <div class="p-2 flex-grow-1 collapse-lapses">
                                                    <div class="table-responsive">
                                                        <table class="table table-sm table-secondary" style="margin-bottom:0px;" v-for="(item,index) in operationColumnsDay.LapseJson" :key="index">
                                                            <thead>
                                                                <tr class="table-active">
                                                                    <th scope="col">{{ $t('label.lapse') }} {{ item.VisitLapseCode }}</th>
                                                                    <th scope="col">{{ $t('label.crane') }}</th>
                                                                    <th scope="col">{{ $t('label.hold') }}</th>
                                                                    <th scope="col">CANT</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item2) in item.CraneHoldJson" :key="item2.VisitLapseCode">
                                                                    <th>{{ item2.VisitLapseCode }}</th>
                                                                    <td>{{ item2.CraneAlias }}</td>
                                                                    <td>{{ item2.VesselHoldName }}</td>
                                                                    <td>{{ item2.Cant }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="3" class="text-right"><strong>{{ $t('label.TotalPerDay') }}</strong></td>
                                                                    <td>{{ totalDaysLapse(item.CraneHoldJson) }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCol>
            <CCol lg="12" style="padding-right: 1px;padding-left: 1px;">
                <div>
                    <div
                        class="cardHeaderResumenPersonalizado d-flex align-items-center"
                        :style="
                            'background:' + ' #203864 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;justify-content: center;'
                        "
                    >
                        
                        <span style="margin-left:5px;"> {{ $t('label.DayOfOperations') }} </span>
                    </div>
                    <div class="d-flex">
                        <div :class="'p-2 flex-fill opcion-deseleccionada'" v-on:click="seleccionarDia(1)">
                            <CIcon name="cil-chevron-double-left-alt"/>
                        </div>
                        <div :class="'p-2 flex-fill opcion-deseleccionada'" v-on:click="seleccionarDia(seleccionado != 1 ? seleccionado-1 : 1)">
                            <CIcon name="cil-chevron-left-alt"/>
                        </div>
                        <div v-if="(first!=1)" :disabled="true" :class="'p-2 flex-fill opcion-deseleccionada user-select-none'" >
                            ...
                        </div>
                        <div :class="'p-2 flex-fill '+(seleccionado === item ? 'opcion-seleccionada user-select-none' : 'opcion-deseleccionada user-select-none')" v-for="item in TotalDays(first, last)" :key="item" v-on:click="seleccionarDia(item)">
                            {{ item }}
                        </div>
                        <div v-if="(last<OperationDays.length)" :disabled="true" :class="'p-2 flex-fill opcion-deseleccionada user-select-none'" >
                            ...
                        </div>
                        <div :class="'p-2 flex-fill opcion-deseleccionada'" v-on:click="seleccionarDia(seleccionado != OperationDays.length ? seleccionado+1 : OperationDays.length)">
                            <CIcon name="cil-chevron-right-alt"/>
                        </div>
                        <div :class="'p-2 flex-fill opcion-deseleccionada'" v-on:click="seleccionarDia(OperationDays.length)">
                            <CIcon name="cil-chevron-double-right-alt"/>
                        </div>
                    </div>
                </div>
            </CCol>
            
        </CRow>
    </div>
</template>

<script>
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState  } from 'vuex';
    import TransversalView from '@/pages/situacion-puerto/transversal-view-small';
    import moment from 'moment';
    //data
    function data() {
        return {
            first: 1,
            last: 10,
            seleccionado: 1,
            tap: 0,
        }
    }
    //methods
    function TotalDays(first, last) {
        let Pagination = [];
        for (first, last; first <= this.OperationDays.length && first<=last; first++) {
            Pagination.push(this.OperationDays[first-1]?.IdX);
        }
        return Pagination;
    }
    function seleccionarDia(item){
        if(this.seleccionado !== item){
            this.seleccionado = item;
            let medium = this.first+Math.ceil((this.last-this.first)/2);
            if (this.OperationDays.length<10) {
                this.first = 1;
                this.last = 10;
            } else if (this.seleccionado > this.OperationDays.length-5) {
                this.first = this.OperationDays.length-9;
                this.last = this.OperationDays.length;
            }else if (this.seleccionado <= 5) {
                this.first = 1;
                this.last = 10;
            }else if (this.seleccionado > medium && this.OperationDays.length > 10) {
                this.first = this.seleccionado-4;
                this.last = this.seleccionado+5;
            }else{
                if (this.seleccionado < medium && this.OperationDays.length > 10) {
                    this.first = this.seleccionado-4;
                    this.last = this.seleccionado+5;
                }
            }
        }
    }
    function realOperationDate(item){
        return item ? moment(item,"YYYY-MM-DD").format('DD/MM/YYYY') : 'N/A';
    }
    function formateRealTimeDate(item){
        return item ? DateFormater.formatDateTimeWithSlash(item) : 'N/A';
    }
    function valuemoney(value){
        if(value !== null){
            let newValue = parseFloat(value).toFixed(2);
            var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
            return new Intl.NumberFormat("de-DE",options).format(newValue);
        }
        return "0,00";
    }
    function totalDaysLapse(array){
        if(array.length > 0){
            let element = 0;
            for (let index = 0; index < array.length; index++) {
                element += array[index].Cant;
            }
            return element;
        }
        return 0;
    }
    //computed
    function operationAta(){
        let DataAtaEtd = this.Vessel;
        if(Object.keys(DataAtaEtd).length !== 0){
            return DataAtaEtd.Ata ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Ata) : 'N/A';
        }
        return '';
    }
    
    function operationColumnsDay(){
        let OperationDays = this.OperationDays;
        if(OperationDays.length !== 0){
            let Object = {};
            const index = OperationDays.map(function(e) {
                return e?.IdX; 
            }).indexOf(this.seleccionado);
            if(index !== -1){
                Object = OperationDays[index];
            }
            return Object;
        }else{
            return {}
        }
    }

    function BulkVesselId() {
        return process.env.VUE_APP_BULK_VESSEL_ID;
    }

    export default {
        name:'ResumenDiario',
        data,
        props:{
            OperationDays:{
                type: Array,
                default: () => [],
            },
            Vessel:{
                type: Object,
                default: () => {},
            }
        },
        components: {
            TransversalView,
        },
        methods:{
            TotalDays,
            seleccionarDia,
            totalDaysLapse,
            valuemoney,
            realOperationDate,
            formateRealTimeDate
        },
        computed:{
            operationColumnsDay,
            operationAta,
            BulkVesselId,
            ...mapState({
                tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
                carrusel: state => state.situacionOperativaActual.carrusel,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
            }),
        },
        watch:{
            carruselTab(newValue,oldValue){
                if(this.tabsOperationes === 8){
                    if(oldValue == 1){
                        this.seleccionado = 1;
                        this.first = 1;
                        this.last = 10;
                        this.seleccionado = 1;
                        this.tap = 0;
                    }
                }
            }
        }
    }
</script>


<style scoped lang="scss">
    .report-primary{
        strong{
            font-weight: bold;
        }
        width:300px;
        border-left: 10px solid #26547C; 
    }
    .report-danger{
        strong{
            font-weight: bold;
        }
        width:300px;
        border-left: 10px solid #FF9900; 
    }
    .report-success{
        strong{
            font-weight: bold;
        }
        width:300px;
        border-left: 10px solid #A9D18E; 
    }

    .vertical-letra{
        h1 {
            writing-mode: vertical-rl;
            text-orientation: upright;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-content: center;
        }
        display: flex;
        justify-content: center;
        align-content: center;
        width: 40px;
    }
    .collapse-lapses{
        strong{
            font-weight: bold;
        }
        border-left: 10px solid #1A3760; 
    }

    .title-etd{
        h3{
            font-weight: bold;
            margin-bottom: 0px !important;
            font-size: 1.3rem;
        }
        text-align: center;
        padding: 2px;
        background: #FF9900;
    }
    .opcion-seleccionada{
        text-align: center;
        color: #fff;
        cursor:pointer;
    }
    .opcion-deseleccionada{
        text-align: center;
        background: #D8DBE0;
        cursor:pointer;
    }
    .opcion-deseleccionada:hover{
        background: #e4e7ed;
    }
    .user-select-none {
        user-select: none;
    }
</style>