function SoloNumeros(e) {
  var regex = new RegExp('^[0-9.,]+$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function onlyNumbersStrict(e) {
  var regex = new RegExp('^[0-9]+$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function formatCharDate(e) {
  var regex = new RegExp('^[0-9-/]+$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

function formatDateToInput(date, separator) {
  let newDay = new Date(date),
    day = ('0' + newDay.getDate()).slice(-2),
    month = ('0' + (newDay.getMonth() + 1)).slice(-2),
    year = newDay.getFullYear();

  separator = separator ? separator : '-';

  return `${day}${separator}${month}${separator}${year}`;
}

export default {
  methods: {
    SoloNumeros,
    onlyNumbersStrict,
    formatCharDate,
    formatDateToInput,
  },
  computed: {},
};
