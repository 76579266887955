

<template>
    <CRow class="mt-3">
        <CCol col="12" xl="12">
            <CRow>
                <CCol sm="12" class="d-flex align-items-center justify-content-end">
                    <CButton
                        color="excel"
                        style="margin-left:5px;"
                        @click="generarXLS(true)"
                        v-c-tooltip="{
                            content: $t('label.exportToExcel'),
                            placement: 'top-end'
                        }"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                </CCol>
            </CRow>
            <CCol sm="12">
                <dataTableExtended
                     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :items="formatedItems"
                    :fields="fields"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #TransaUserId="{ index }">
                        <td class="center-cell">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="center-cell">
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';

    function fields(){
        return [
            { key: 'TransaUserId',label: '#', filter:false, _style: 'width:3%; text-align:center', },
            { key: 'MetadataDesc',label: this.$t('label.isoCode'),_style:'width:25;' },
            { key: 'TpCargoDetailCode',label: this.$t('label.equivalent')+' '+this.user.CompanyName,_style:'width:25;' },
            { key: 'StandardDocumentName',label: this.$t('label.standard'), _style:'width:15%;' },
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;',  },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%;', _classes: 'text-center'},
            { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;',},
        ];
    }

    //data
    function data() {
        return {
            activePage: 1,
        }
    }

    //methods
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.isoCode')] = item.MetadataDesc;
                objeto[this.$t('label.equivalent')] = item.TpCargoDetailCode;
                objeto[this.$t('label.standard')] = item.StandardDocumentName;
                objeto[this.$t('label.user')] = item.TransaLogin;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'CodigosIsoDeContenedores' : 'ContainerIsoCode';

            await this.getExcelArray(itemsArray, this.$t('label.containerLists.isoCodesList'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataIsoCodetable"];
    }

    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }

    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 9){
            this.$store.dispatch('contenedores/getIsoCodelist');
        }
    }
    
    export default {
        name: 'index-isocodes',
        data,
        mixins:[
            GeneralMixin,
            Reportes
        ],
        props: ['loading'],    
        methods: {
            generarXLS
        },
        computed: {
            computedItems,
            formatedItems,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
                user: state => state.auth.user,
            })
        },
        watch:{
            tabIndex
        }
    }   
</script>

<style lang="scss">
</style>