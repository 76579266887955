import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

const alphaNumeric = (value) => /^[a-zA-Z0-9 \u00f1\u00d1]+$/g.test(value);

export default () => {
  return {
    insumo: {
      SupplyName: { required, alphaNumeric, maxLength: maxLength(100) },
      GpoSupplyId: { required },
    },
  }
}