<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="$t('label.positionContainerSlot')"
      color="dark"
      size="xl"
      :close-on-backdrop="false"
      :show.sync="modalActive"
    >

      <CRow>
        <CCol sm="3" ></CCol>
        <CCol sm="auto" class="px-1 align-self-center">
          <label class="col-form-label col-form-label-sm col-sm-12 col-sm-12 text-right px-0">{{$t('label.container')}}</label>
        </CCol>
        <CCol sm="5" class="px-1 input-group align-self-center">
          <v-select
            class="select-adjust"
            id="v-select-small"
            :placeholder="$t('label.select')"
            :options="ContainerOption"
            :reduce="option => option.value" 
            v-model="containerSelected"
          >
            <template #no-options="{}">
                {{$t('label.noResultsFound')}}
            </template>
            <template #option="{ Json, label, value }">
                {{ label }}
                <br v-if="value"/>
                <cite v-if="value&&Json?.VesselName">{{ Json?.VesselName }} - {{ Json?.VoyageArrival }} </cite>
            </template>
          </v-select>
        </CCol>
      </CRow>

      <div class="border-title align-items-stretch mx-1 my-3" style="background-color: white; color: #828c9c; height: auto; margin-top: 20%;">
        <CRow class="align-items-start">
          <CCol sm="12" xl="6">
            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.block')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(positionData?.BlockCode)}}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.bay')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(positionData?.BayCode)}}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.row')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(positionData?.StackCode)}}</label>
              </CCol>
            </CRow>
            
            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.heights')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(positionData?.HeightCode)}}</label>
              </CCol>
            </CRow>
            
            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.positionContainer')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(positionData?.SlotCode)}}</label>
              </CCol>
            </CRow>
          </CCol>

          <CCol sm="12" xl="6">
            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.bl')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(BlJson?.BlNro)}}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.SizeFt')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{`${GetData(dataContainer?.Size)}`}}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.daysInYard')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{ GetData($i18n.locale=='en' ? dataContainer?.DaysInYardEn : dataContainer?.DaysInYardEs) }}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.status')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetData(dataContainer?.TpCargoStatusName)}}</label>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6" lg="6" class="d-flex justify-content-end">
                <b><label class="text-right p-0">{{$t('label.receptionDate')}}</label></b>
              </CCol>
              <CCol sm="6" lg="6" class="pl-0 pb-0 mb-0">
                <label class="text-left">{{GetDate(dataContainer?.ReceptionDate)}}</label>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>

      <CRow class="justify-content-center mt-3 px-3" v-if="dataContainer.CargoId">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.basicData')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.EventReasonId.$model"
                      :options="EventOptions"
                      :label="$t('label.motive')"
                      addLabelClasses="required text-right"
                      :horizontal="{
                        label:  'col-sm-12 col-lg-3', 
                        input:  'col-sm-12 col-lg-9' 
                      }"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.EventReasonId)"
                      :invalid-feedback="errorMessage($v.EventReasonId)"
                      @change="getEventService"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.EventServiceId.$model"
                      :options="ServiceOptions"
                      :label="$t('label.service')"
                      :addLabelClasses="EventServiceList.length!=0 ? 'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.EventServiceId)"
                      :invalid-feedback="errorMessage($v.EventServiceId)"
                      :disabled="EventServiceList.length==0"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-model="$v.MachineId.$model"
                      :options="MachineOptions"
                      :label="$t('label.machine')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.MachineId)"
                      :invalid-feedback="errorMessage($v.MachineId)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row mb-3">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right required">{{`${$t('label.PositioningDate')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-9 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.TransactionDate.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.TransactionDate)"
                              size="sm"                      
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="6" v-if="this.ContainerSize">
                    <CSelect
                      size="sm"
                      :label="$t('label.Auxiliary')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :is-valid="hasError($v.Auxiliary)"
                      :placeholder="$t('label.select')"
                      v-model="$v.Auxiliary.$model"
                      :options="AuxiliaryOptions"
                      :disabled="AuxiliaryList.length==0"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CTextarea
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6" v-if="!this.ContainerSize">
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
          
      <template #footer>
        <CButton
          color="add"
          @click="Submit()"
          :disabled="isSubmit"
          v-if="dataContainer.CargoId"
        >
          <CIcon name="checkAlt"/>
          <span class="ml-1">{{ $t('button.accept') }}</span>
        </CButton>

        <CButton
          shape="square"
          color="wipe"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/>
          <span class="ml-1">{{ $t('button.exit') }}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
  
  <script>
  import { mapState } from "vuex";
  import { trimRemoveSpecialCharacters, DateFormater } from '@/_helpers/funciones';
  import positioningRegisterValidations from '@/_validations/yard-management/container/positioningModalRegisterValidations';
  import ModalMixin from '@/_mixins/modal';
  import UpperCase from '@/_validations/uppercase-directive';
  import moment from 'moment';
  
  //Data
  function data() {
    return {
      loaded: false,
      modalActive: this.modal,
      items: [],
      loading: false,
      containerSelected: '',
      dataContainer: {},
      loadDefault: [
        {
          Name: 'OCUPADO',
          Color: '#ec631d',
          FgColor: true,
        },
        {
          Name: 'OCUPADO POR OTROS PUERTOS',
          Color: '#aaaaaa',
          FgColor: true,
        },
        {
          Name: 'PROHIBIDO ASIGNAR',
          Class: 'invalid-position',
          FgColor: false,
        }
      ],

      //POSITIONS
      isSubmit: false,
      SealJson: {},
      EventReasonId: '',
      EventList: [],
      EventServiceId: '',
      EventServiceList: [],
      MachineId: '',
      MachineList: [],
      TransactionDate: '',
      ValidPreviousDate: '',
      ValidLaterDate: '',
      previousDate: '',
      laterDate: '',
      Observation: '',
      YardName: '',
      Block: '',
      Position: '',
      PositionList: [],
      Auxiliary: '',
      AuxiliaryList: [],
      Seal: {
        Seal1: {
          index: 1,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: '',
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal2: {
          index: 2,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: '',
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal3: {
          index: 3,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: '',
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
        Seal4: {
          index: 4,
          CurrentSeal: '',
          Seal: '',
          NewSeal: '',
          ConditionSeal: '',
          SealAssignmentId: '',
          ObservationSeal: '',
          SealDocumentJson: [],
        },
      },
      CargoJson: {},
    }
  }
  
  //Methods
  function getDateRange() {
    this.previousDate = DateFormater.formatDateRange(this.dataContainer.ReceptionDate) 
    this.laterDate = new Date();
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ReceptionDate));
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
  }

  async function getYardMachine() {
    let TpCargoDetailId =  this.dataContainer.TpCargoDetailId;
    await this.$http.get('YardMachine-by-YardId-TpCargoDetailId', { YardId: this.dataContainer.YardId, CompanyBranchId: this.CompanyBranchId, TpCargoDetailId: TpCargoDetailId })
      .then(response => {
        let List = response.data.data;
        this.MachineList = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  async function getYardEventReasonList() {
    let Parameter = {
      EventId: process.env.VUE_APP_EVENT_POSITION,
      CargoId: this.dataContainer.CargoId,
      YardId: this.dataContainer.YardId,
    };
    await this.$http.get('YardEventReason-list', Parameter)
      .then(response => {
        let List = response.data.data;
        this.EventList = List&&List.length!=0 ? List : [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function getNewData() {

    //PRECINTO 1
    this.Seal.Seal1.CurrentSeal = this.dataContainer?.Seal1 ?? '';
    this.Seal.Seal1.Seal = this.dataContainer?.Seal1 ?? '';
    this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal1.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 2
    this.Seal.Seal2.CurrentSeal = this.dataContainer?.Seal2 ?? '';
    this.Seal.Seal2.Seal = this.dataContainer?.Seal2 ?? '';
    this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal2.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 3
    this.Seal.Seal3.CurrentSeal = this.dataContainer?.Seal3 ?? '';
    this.Seal.Seal3.Seal = this.dataContainer?.Seal3 ?? '';
    this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal3.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
    //PRECINTO 4
    this.Seal.Seal4.CurrentSeal = this.dataContainer?.Seal4 ?? '';
    this.Seal.Seal4.Seal = this.dataContainer?.Seal4 ?? '';
    this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
      process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
    this.Seal.Seal4.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];
    
    this.$v.$reset();
  }


  async function toggle(newVal) {
    this.dataContainer = {};

    if(newVal)
      this.getYardCargoReception();
    else{
      this.items = [];
    }
     
    this.modalActive = newVal;
  }

  function ServiceOptions() {
    if(this.EventServiceList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      this.EventServiceList.map(function(e){
        chart.push({
          value: e.EventId,
          label: e[`EventName${_lang}`],
        })
      })
      return chart;
    }
  }

  function getEventService(event) {
    this.EventReasonId = event.target.value;
    this.EventServiceId = '';
    this.EventServiceList = [];
    if (this.EventReasonId) {
      let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
      this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData.EventServiceJson : [];
    }
  }

  function GetData(Data) {
    return Data ? Data : '';
  }

  function GetDescriptionOfGoods(value) {
    return trimRemoveSpecialCharacters(value);
  }

  async function getYardCargo(item) {
    this.loading = true;
    await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: item.YardId })
      .then(async (response) => {
        let res = response.data.data;
        if (res && res?.length>0) {
          this.dataContainer = res[0]; 

        this.YardName = this.dataContainer.YardName;
          await this.getDateRange();
          await this.getYardMachine();
          await this.getYardEventReasonList();
          this.Block = this.positionData?.YardBlockId;
          this.Position = this.positionData?.YardSlotId
          this.getNewData();
          this.AuxiliaryList = this.positionData.LeftRightJson ? this.positionData.LeftRightJson[0].LeftJson.concat(this.positionData.LeftRightJson[0].RightJson) : [];
        }
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
    this.loading = false;
  }

  async function getYardCargoReception() {
    this.loading = true;
    await this.$http.get('YardCargoReception-by-YardId', { YardId: this.yardData.YardId })
        .then(response => {
            let List = response.data.data;
            this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    this.loading = false;
  }

  function EventOptions() {
    if(this.EventList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      this.EventList.map(function(e){
        chart.push({
          value: e.EventReasonId,
          label: e[`EventReasonName${_lang}`],
        })
      })
      return chart;
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function ConditionWithoutSeal(ConditionSeal) {
    if (typeof ConditionSeal == 'string') {
      return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
    }else{
      return false;
    }
  }

  function Submit() {
    try {
      this.loading = true;
      this.isSubmit = true;
      this.$v.$touch();
      if (this.$v.$error){
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
          throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
          throw this.$t('label.ErrorDispatchDatePleaseCheck');
        }
        throw this.$t('label.errorsPleaseCheck');
      }

      let CargoJson = {
        YardAreaId: '',
        YardCargoId: this.dataContainer?.YardCargoId,
        YardBlockId: this.Block,
        YardSlotId: this.Position,
        YardSlotAuxId: this.Auxiliary,
        EventServiceId: this.EventServiceId,
        EventReasonId: this.EventReasonId,
        MachineId: this.MachineId,
        Seal1: this.Seal.Seal1.Seal,
        Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
        Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
        ObservationSeal1: this.Seal.Seal1.ObservationSeal,
        Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
          this.Seal.Seal1.SealDocumentJson.map(Document => {
            return{
              SealRoute: Document.SealRoute ?? '',
              FgActDocument: 1,
              Status: 1,
            } 
          }),
        Seal2: this.Seal.Seal2.Seal,
        Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
        Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
        ObservationSeal2: this.Seal.Seal2.ObservationSeal,
        Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
          this.Seal.Seal2.SealDocumentJson.map(Document => {
            return{
              SealRoute: Document.SealRoute ?? '',
              FgActDocument: 1,
              Status: 1,
            } 
          }),
        Seal3: this.Seal.Seal3.Seal,
        Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
        Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
        ObservationSeal3: this.Seal.Seal3.ObservationSeal,
        Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
          this.Seal.Seal3.SealDocumentJson.map(Document => {
            return{
              SealRoute: Document.SealRoute ?? '',
              FgActDocument: 1,
              Status: 1,
            } 
          }),
        Seal4: this.Seal.Seal4.Seal,
        Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
        Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
        ObservationSeal4: this.Seal.Seal4.ObservationSeal,
        Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
          this.Seal.Seal4.SealDocumentJson.map(Document => {
            return{
              SealRoute: Document.SealRoute ?? '',
              FgActDocument: 1,
              Status: 1,
            } 
          }),
        TransactionBegin:  undefined, 
        TransactionFinish: undefined,
        TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) ,
        Observation: this.Observation,
      }

      let Method =  'POST';
      let Route =  'YardCargoPositioning';

      this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
        .then((response) => {
          this.notifySuccess({text: response.data.data[0].Response});
          this.Reset();
          this.isSubmit = false;
          this.loading = false;
          this.$emit('submited');
        }).catch((err) => {
          this.isSubmit = false;
          this.loading = false;
          this.notifyError({text: err});
        })
    } catch (error) {
      this.isSubmit = false;
      this.loading = false;
      this.notifyError({text: error});
    }
  }

  function Reset() {
    this.containerSelected = '',
    this.EventReasonId = '',
    this.EventList = [],
    this.EventServiceId = '',
    this.EventServiceList = [],
    this.TransactionDate = '',
    this.ValidPreviousDate = '',
    this.ValidLaterDate = '',
    this.previousDate = '',
    this.laterDate = '',
    this.Observation = '',
    this.YardName = '';
    this.MachineId = '';
    this.MachineList = [];
    this.Block = '';
    this.Position = '';
    this.PositionList = [];
    this.Auxiliary = '';
    this.AuxiliaryList = [];
    this.Seal = {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    };
    this.CargoJson = {};
    this.$v.$reset();
    this.toggle(false);
  }
  
  //Computed

  function GetDate(date) {
    if (date) {
      return DateFormater.formatDateTimeWithSlash(date);
    } else {
      return 'N/A';
    }
  }

  function BlJson() {
    return this.dataContainer?.BlJson&&this.dataContainer?.BlJson.length!=0 ? this.dataContainer?.BlJson[0] : {};
  }

  function ConfirmationDate() {
    return (this.dataContainer && this.dataContainer?.ConfirmationDate) ?  DateFormater.formatDateTimeWithSlash(this.dataContainer?.ConfirmationDate) : '';
  }

  function ContainerOption(){
      if(this.globalSelectContainer?.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
              Json: '',
          }];
      }else{
          const chart = [{
              value: '', 
              label: this.$t('label.select'),
              Json: '',
          }];

          this.globalSelectContainer?.map(list => {
              chart.push({
                  value: list.ContainerId,
                  label: list.ContainerCode,
                  Json: {...list }
              });
          });
          return chart;
      }
  }

  function MachineOptions() {
    if(this.MachineList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.MachineList.map(function(e){
        chart.push({
          value: e.MachineId,
          label: e.MachineName,
        })
      })
      return chart;
    }
  }

  function PositionOptions() {
    if(this.PositionList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.PositionList.map(function(e){
        chart.push({
          value: e.YardSlotId,
          label: e.SlotCode,
        })
      })
      return chart;
    }
  }

  function AuxiliaryOptions() {
    if(this.AuxiliaryList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.AuxiliaryList.map(function(e){
        chart.push({
          value: e.YardSlotId,
          label: e.SlotCode,
        })
      })
      return chart;
    }
  }

  function ContainerSize() {
    return this.dataContainer?.Size != 20;
  }


  export default {
    name: 'position-modal',
    props: {
      modal: Boolean,
      positionData: {
        type: Object,
        default: {},
      },
    },
    validations(){
      return positioningRegisterValidations(false, this.ValidPreviousDate, this.ValidLaterDate,
        moment(this.data.DispatchDate).format("YYYY-MM-DD HH:mm") ,this.EventServiceList.length!=0, 
        this.ContainerSize);
    },
    data,
    mixins: [
      ModalMixin,
    ],
    directives: UpperCase,
    methods: {
      toggle,
      getYardCargo,
      getYardCargoReception,
      GetData,
      GetDescriptionOfGoods,
      GetDate,
      validateDateRange,
      getEventService,
      getDateRange,
      getYardMachine,
      getYardEventReasonList,
      getNewData,
      ConditionWithoutSeal,
      Submit,
      Reset
    },
    computed: {
      ContainerSize,
      ServiceOptions,
      EventOptions,
      PositionOptions,
      MachineOptions,
      AuxiliaryOptions,
      BlJson,
      ConfirmationDate,
      ContainerOption,
      ...mapState({
        yardData: state => state.yardManagement.yardData,
        globalSelectContainer: state => state.yardManagement.globalSelectContainer,
        data: state => state.yardManagement.dataContainer,
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      }),
    },
    watch: {
      modal: function(val) {
        this.Reset();
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      },
      containerSelected: async function (val) {
        if(val && val!=''){
          let  data = await this.globalSelectContainer.find(item => item.ContainerId == val );
          data && await this.getYardCargo(data);
        }
      },
      TransactionDate: async function(val){
        try{
          if(val){
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
              throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
              throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataContainer.DispatchDate).format("YYYY-MM-DD HH:mm")) {
              throw this.$t('label.ErrorDispatchDatePleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
              throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
          }
        }catch(e){
          this.notifyError({text: e});
        }
      },
    }
  }
  </script>