<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            class="modal-extended"
            :show.sync="$store.state.contenedores.modalSize"
            :title="tituloModal"
            :close-on-backdrop="false"
        >

       <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
            <CRow class="pr-2" >
                
                 <CCol sm="12" lg="12" class="pl-0" >
                    <CInput
                        :label="$t('label.size')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-6 pr-2', input:'col-sm-12 col-lg-6 pr-2'}"
                        type="number"
                        v-model="$v.formSize.TpCargoSizeName.$model"
                        @blur="$v.formSize.TpCargoSizeName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formSize.TpCargoSizeName.$error }"
                        required
                        :invalid-feedback="errorMessage($v.formSize.TpCargoSizeName)"
                        :is-valid="hasError($v.formSize.TpCargoSizeName)"
                        min="0"
                    >
                       
                        -->
                    </CInput>
                </CCol>
                <CCol sm="12" lg="12" class="pl-0" >
                    <CSelect
                        :label="$t('label.sizeUnit')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-6 pr-2', input:'col-sm-12 col-lg-6 pr-2'}"
                        :value="formSize.UnitMeasureIdSize"
                        :addInputClasses="{ 'is-invalid': $v.formSize.UnitMeasureIdSize.$error }"
                        @blur="$v.formSize.UnitMeasureIdSize.$touch()"
                        @input="formSize.UnitMeasureIdSize = $event.target.value"
                        :options="optionList"
                        :invalid-feedback="errorMessage($v.formSize.UnitMeasureIdSize)"
                        :is-valid="hasError($v.formSize.UnitMeasureIdSize)"
                    >
                    </CSelect>
                </CCol>
                 <CCol sm="12" lg="12" class="pl-0">
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-6 pr-2', input:'col-sm-12 col-lg-6 pr-2'}"
                        :is-valid="formSize.FgActCargoSize"
                        v-if="!idIndentification"
                        v-model="formSize.FgActCargoSize"
                        :value.sync="formSize.FgActCargoSize"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading">
                        <span  class="spinner-border spinner-border-sm" role="Size" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormSize } from '@/_validations/contenedores/ContenedoresValidation';
    import { alertPropertiesHelpers} from '@/_helpers/funciones';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';

    //data
    function data() {
        return {
            formSize:{
                UnitMeasureIdSize:'',
                TpCargoSizeId:0,
                TpCargoSizeName:'',
                FgActCargoSize:true,
            },
            originalAct: '',
            myDataCombo:[],
            tituloModal: this.$t('label.nuevo')+ ' '+ this.$t('label.size'),
            loadingOverlay: false,
            
        }
    }
    //methods
    function cerrarModal(){
        if(this.apiStateFormLoading)
            return
        this.$store.state.contenedores.modalSize = false;
    }
    function registerData(){
        
         try {
            this.$v.formSize.$touch();
            if (this.$v.formSize.$pending || this.$v.formSize.$error) {
                throw this.$t('label.errorsPleaseCheck');
             } 

                let { TpCargoSizeId,TpCargoSizeName,FgActCargoSize,UnitMeasureIdSize } = this.formSize;
                const { dispatch } = this.$store;
                    this.loadingOverlay = true;
                if(this.originalAct !== '' && TpCargoSizeId !== 0){
                    if(this.originalAct !== FgActCargoSize){
                        this.$swal.fire( 
                            alertPropertiesHelpers(this, {
                                text: `${this.$t('label.changeStatusQuestion')} ${TpCargoSizeName}?`
                            })
                        ).then((result) => {
                            if (result.isConfirmed) {
                                dispatch('contenedores/dataSize', { TpCargoSizeId,TpCargoSizeName,FgActCargoSize,UnitMeasureIdSize });
                            }else {
                                //this.$swal(this.$t('label.operationCancelled'));
                            }
                        })
                    }else{
                        dispatch('contenedores/dataSize', { TpCargoSizeId,TpCargoSizeName,FgActCargoSize,UnitMeasureIdSize });
                    }
                }else{
                    dispatch('contenedores/dataSize', { TpCargoSizeId,TpCargoSizeName,FgActCargoSize,UnitMeasureIdSize });
                } 
        } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
        
    }
    //computed
    function idIndentification(){
        return this.formSize.TpCargoSizeId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionList(){
        if(this.myDataCombo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataCombo.map(function(e){
                if(e.FgActUnitMeasure){
                    chart.push({
                        value: e.UnitMeasureId, 
                        label: e.UnitMeasureAbbrev,
                    })
                }
            })
            return chart;
        }
    }
    function selectOptions(){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalSize(newQuestion){
        if(newQuestion === false){
            this.formSize.TpCargoSizeId=0;
            this.formSize.TpCargoSizeName='';
            this.formSize.UnitMeasureIdSize='';
            this.originalAct = '';
            this.tituloModal = this.$t('label.nuevo')+ ' '+ this.$t('label.size');
            this.formSize.FgActCargoSize=true;
            this.$nextTick(() => { this.$v.$reset() })

            this.$store.commit('contenedores/asignarid', 0);

        }else{
            this.tituloModal = this.$t('label.nuevo')+ ' '+ this.$t('label.size');
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            try {
                this.$http.ejecutar('GET', 'UnitMeasureContainerSize-list', { Filter: 'ALL' }, '')
                .then(
                    combo => {
                        if(combo.status === 200){
                            if(this.idState === 0){
                                this.myDataCombo = combo.data.data;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }else{
                                const id = this.idState;
                                this.$http.ejecutar('GET', 'Size-by-id',{ SizeId: id }, '' ).then(response => {
                                    if(response.status === 200){
                                        const Information = response.data.data;
                                        if(Information.length !== 0){
                                            this.tituloModal = this.$t('label.edit')+' '+this.$t('label.size')+': '+Information[0].Size;
                                            this.formSize.TpCargoSizeId= Information[0].SizeId;
                                            this.formSize.TpCargoSizeName= Information[0].Size;
                                            this.formSize.UnitMeasureIdSize= Information[0].UnitMeasureIdSize;
                                            this.originalAct = Information[0].FgActSize;
                                            this.formSize.FgActCargoSize= Information[0].FgActSize;
                                            this.myDataCombo = combo.data.data;
                                            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                                        }
                                        this.$v.$touch();
                                    }else{
                                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                        this.$store.state.contenedores.modalSize = false;
                                    }
                                }).catch(err => {
                                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                    this.$store.state.contenedores.modalSize = false;
                                    this.$store.commit('contenedores/messageMutation', err);
                                });
                            }
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalSize = false;
                        }   
                    },
                    error => {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalSize = false;
                        this.$store.commit('contenedores/messageMutation', error);
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                this.$store.state.contenedores.modalSize = false;
                this.$store.commit('contenedores/messageMutation', err);
            }
        }
    }

    export default {
        name: 'modal-size',
        data,
        validations(){ return FormSize() },
        mixins:[GeneralMixin],
        directives: UpperCase,
        methods:{
            registerData,
            cerrarModal,
            //errorMessage,
        },
        computed:{
            idIndentification,
            optionList,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalSize: state=> state.contenedores.modalSize,
            })
        },
        watch:{
            modalSize
        }
    }
</script>
