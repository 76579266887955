<template>
    <div>
        <CCollapse :show="activeMovementItem(1)">
             <ReceptionEquipment/> 
        </CCollapse>
        <CCollapse :show="activeMovementItem(2)">
            <ReceptionMasive/>
        </CCollapse>
        <!--<CCollapse :show="activeMovementItem(4)">
            <DispatchMasive/>
        </CCollapse>-->
        <CCollapse :show="activeMovementItem(5)">
            <ContainersInYard/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(6)">
            <DispatchExecuted/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(7)">
            <Recognition/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(8)">
            <Pending/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(9)">
            <CanceledContainers/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(10)">
            <DispatchMassiveEmpty/>
        </CCollapse>
        <CCollapse :show="activeMovementItem(11)">
            <PositionamientoMassive/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReceptionEquipment from './reception/equipment';
    import ReceptionMasive from './reception/masive';
    //import DispatchMasive from './dispatch/masive';
    import ContainersInYard from './containers-in-yard/containers-in-yard-index'
    import DispatchExecuted from './dispatch/executed';
    import DispatchMassiveEmpty from './dispatch/massive-empty-containers';
    import PositionamientoMassive from './positionamiento/massive-positionaming-containers';
    import Recognition from './recognition/recognition-index';
    import Pending from './reception/pending';
    import CanceledContainers from './reception/canceled-containers-index';

    //methods
    function activeMovementItem(item) {
        return this.dropItemMovementList === item;
    }

    export default {
        name:"movements-list-index",
        methods:{
            activeMovementItem,
        },
        components:{
            ReceptionEquipment, 
            ReceptionMasive, 
            //DispatchMasive,
            ContainersInYard,
            DispatchExecuted,
            DispatchMassiveEmpty,
            PositionamientoMassive,
            Recognition,
            Pending,
            CanceledContainers,
        },
        computed:{
            ...mapState({
                dropItemMovementList: state => state.yardManagement.dropItemMovementList,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>