import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { alphaNumSpecials2 } from '../funciones';

export default () => {
  return {
    newStatus: {
      ConfigName: { alphaNumSpecials2, required },
      ObservationReverse: {}
    }
  }
}

export const NameNotRequiredValidations = () => {
  return {
    newStatus: {
      ConfigName: {},
      ObservationReverse: { required, }
    }
  }
}