<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.packagingList')}}</b>
    </CCardHeader>
    <CCardBody>
      <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
        <CustomTab :title="$t('label.packaging')">
          <indexEmbalaje :Tab="Tab"/>
        </CustomTab>
        <CustomTab :title="$t('label.accesories')">
          <indexAccesories :Tab="Tab"/>
        </CustomTab>
      </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import indexEmbalaje from './index-embalaje';
import indexAccesories from './index-accesories';

function handleTab(tab) {
  this.Tab = tab;
}

function data() {
  return {
    Tab: -1,
  };
}

export default {
  name: 'ListPackagings',
  data,
  components: {
    CustomTabs,
    CustomTab,
    indexEmbalaje,
    indexAccesories
  },

  methods:{
    handleTab,
  },
  mounted() {
    this.Tab = 0;
  }
};
</script>
<style lang=""></style>
