<template>
<form @submit.prevent="submitBody">
<CModalExtended
      :title="tituloModal"
      color="dark"
      size="xl"
      class="modal-extended"
      @update:show="CloseModal(false)"
      :show.sync="showModal"
      :closeOnBackdrop="false"
>   
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />

        <CCardBody>
            <CRow>
                <CCol sm="12">
                    <CRow>
                        <CCol sm="12">
                                <CRow sm="12" class="d-flex justify-content-center">
                                    <CCol sm="12" lg="4" xl="4" class="px-0 " v-if="!IsEdit">
                                        <div class="form-group form-row">
                                            <label class="col-form-label-sm col-sm-11 col-lg-3 col-xl-3 text-right required">{{$t('label.from')}}</label>
                                            <div class="input-group col-sm-12 col-lg-9">
                                                <vue-datepicker 
                                                    type="datetime"
                                                    header
                                                    :lang="this.$i18n.locale"
                                                    :editable="false"
                                                    :clearable="false"
                                                    format="DD/MM/YYYY HH:mm"
                                                    placeholder="DD/MM/YYYY HH:mm"
                                                    time-title-format="DD/MM/YYYY HH:mm"
                                                    v-model="fromDay"
                                                    :disabled-date="validateDateRange"
                                                    :append-to-body="false"
                                                    value-type="format"
                                                    :show-second="false"
                                                >
                                                    <template #input>
                                                        <CInput
                                                        v-uppercase
                                                        class="w-100 float-left"
                                                        :value="fromDay"
                                                        placeholder="DD/MM/YYYY HH:mm"
                                                        :is-valid="hasError($v.fromDay)"
                                                        @blur="$v.fromDay.$touch()"
                                                        size="sm"                      
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-calendar" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="5" xl="5" class="px-0 " v-else>
                                        <div class="form-group form-row">
                                            <label class="col-form-label-sm col-sm-11 col-lg-4 col-xl-4 text-right required">{{$t('label.from')}}</label>
                                            <div class="input-group col-sm-12 col-lg-7">
                                                <vue-datepicker 
                                                    type="datetime"
                                                    header
                                                    :lang="this.$i18n.locale"
                                                    :editable="false"
                                                    :clearable="false"
                                                    format="DD/MM/YYYY HH:mm"
                                                    placeholder="DD/MM/YYYY HH:mm"
                                                    time-title-format="DD/MM/YYYY HH:mm"
                                                    v-model="fromDay"
                                                    :disabled-date="validateDateRange"
                                                    :append-to-body="false"
                                                    value-type="format"
                                                    :show-second="false"
                                                >
                                                    <template #input>
                                                        <CInput
                                                        v-uppercase
                                                        class="w-100 float-left"
                                                        :value="fromDay"
                                                        placeholder="DD/MM/YYYY HH:mm"
                                                        :is-valid="hasError($v.fromDay)"
                                                        @blur="$v.fromDay.$touch()"
                                                        size="sm"                      
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-calendar" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </div>
                                        </div>
                                    </CCol>    
                                    <CCol sm="12" lg="4" xl="4" class="px-0" v-if="!IsEdit">
                                        <div class="form-group form-row">
                                            <label class="col-form-label-sm col-sm-11 col-lg-3 col-xl-3 text-right required">{{$t('label.to')}}</label>
                                            <div class="input-group col-sm-12 col-lg-9">
                                                <vue-datepicker 
                                                    type="datetime"
                                                    header
                                                    :lang="this.$i18n.locale"
                                                    :editable="false"
                                                    :clearable="false"
                                                    format="DD/MM/YYYY HH:mm"
                                                    placeholder="DD/MM/YYYY HH:mm"
                                                    time-title-format="DD/MM/YYYY HH:mm"
                                                    v-model="toDay"
                                                    :disabled-date="validateDateRange"
                                                    :append-to-body="false"
                                                    value-type="format"
                                                    :show-second="false"
                                                >
                                                    <template #input>
                                                        <CInput
                                                        addLabelClasses="required text-right"
                                                        v-uppercase
                                                        class="w-100 float-left"
                                                        :value="toDay"
                                                        placeholder="DD/MM/YYYY HH:mm"
                                                        :is-valid="hasError($v.toDay)"
                                                        @blur="$v.toDay.$touch()"
                                                        size="sm"                      
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-calendar" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="5" xl="5" class="px-0" v-else>
                                        <div class="form-group form-row">
                                            <label class="col-form-label-sm col-sm-11 col-lg-3 col-xl-3 text-right required">{{$t('label.to')}}</label>
                                            <div class="input-group col-sm-12 col-lg-7">
                                                <vue-datepicker 
                                                    type="datetime"
                                                    header
                                                    :lang="this.$i18n.locale"
                                                    :editable="false"
                                                    :clearable="false"
                                                    format="DD/MM/YYYY HH:mm"
                                                    placeholder="DD/MM/YYYY HH:mm"
                                                    time-title-format="DD/MM/YYYY HH:mm"
                                                    v-model="toDay"
                                                    :disabled-date="validateDateRange"
                                                    :append-to-body="false"
                                                    value-type="format"
                                                    :show-second="false"
                                                >
                                                    <template #input>
                                                        <CInput
                                                        addLabelClasses="required text-right"
                                                        v-uppercase
                                                        class="w-100 float-left"
                                                        :value="toDay"
                                                        placeholder="DD/MM/YYYY HH:mm"
                                                        :is-valid="hasError($v.toDay)"
                                                        @blur="$v.toDay.$touch()"
                                                        size="sm"                      
                                                        >
                                                            <template #append-content>
                                                                <CIcon name="cil-calendar" />
                                                            </template>
                                                        </CInput>
                                                    </template>
                                                    <template #icon-calendar>
                                                        <div style="display: none"></div>
                                                    </template>
                                                </vue-datepicker>
                                            </div>
                                        </div>
                                    </CCol>
                                
                                    <CCol sm="12" lg="4" xxl="4" class="px-0" v-if="!IsEdit">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm required">{{ $t('label.StowageFactor') }}</label>
                                            <div class="input-group col-sm-12 col-lg-5 input-group-sm">
                                                <money
                                                    size="sm"
                                                    v-uppercase
                                                    v-model.trim="$v.StowageFactor.$model"
                                                    v-bind="measure_2"
                                                    :class="ValidateFormControl(StowageFactor, true)"                                   
                                                >
                                                </money>
                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((StowageFactor == 0 || StowageFactor == '0,00') && valid==true)">
                                                    {{$t('label.required')}}
                                                </CCol>
                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(StowageFactor) < 0 || setNum(StowageFactor) > 1)">
                                                    {{$t('validation.minValueIs')+' 0,0001 '+$t('label.and')+' '+$t('validation.maxValueIs')+' 1'}}
                                                </CCol>
                                            </div>
                                        </div>
                                    </CCol>      
                                      
                                    <!--<CCol sm="12" lg="4" xl="4" class="px-0">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{ $t('label.density') }}</label>
                                            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                <money
                                                    v-uppercase
                                                    addLabelClasses="required"
                                                    :label="$t('label.density')"
                                                    v-model.trim="$v.Density.$model"
                                                    v-bind="measure"
                                                    :class="ValidateFormControl(Density)"
                                                >
                                                </money>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>-->
                                </CRow>
                                <CRow class="d-flex justify-content-center" v-if="IsEdit">
                                    <CCol sm="12" lg="5" xxl="5" class="px-0">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-4 col-form-label-sm required">{{ $t('label.StowageFactor') }}</label>
                                            <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                <money
                                                    size="sm"
                                                    v-uppercase
                                                    v-model.trim="$v.StowageFactor.$model"
                                                    v-bind="measure_2"
                                                    :class="ValidateFormControl(StowageFactor, true)"                                   
                                                >
                                                </money>
                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((StowageFactor == 0 || StowageFactor == '0,00') && valid==true)">
                                                    {{$t('label.required')}}
                                                </CCol>
                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(StowageFactor) < 0 || setNum(StowageFactor) > 1)">
                                                    {{$t('validation.minValueIs')+' 0,0001 '+$t('label.and')+' '+$t('validation.maxValueIs')+' 1'}}
                                                </CCol>
                                            </div>
                                        </div>
                                    </CCol>    
                                    <CCol v-if="IsEdit" sm="12" lg="5" xxl="5" class="px-0 ">
                                        <CSelect
                                            size="sm"
                                            :options="statusOptions"
                                            :value.sync="Status"
                                            v-model.trim="$v.Status.$model"
                                            :label="$t('label.status')"
                                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                                            addLabelClasses="required text-right"
                                            :is-valid="statusSelectColor"
                                        />
                                    </CCol>
                                </CRow>
                                          
                            <CustomTabs :active-tab="tap" @update:activeTab="handleTab">
                                <CustomTab :title="$t('label.generalData')" icon="general" active>   
                                   
                                        <CRow>
                                            <CCol sm="12" class="mb-2">
                                                <CRow>
                                                    <CCol sm="6">
                                                        <div class="text-center">
                                                            <div class="subtitle bg-summary-descarga mb-2">
                                                                
                                                                <div class="text-color ">{{$t('label.inicio')}}</div>
                                                            
                                                                    
                                                                
                                                            </div>
                                                        </div>
                                                    </CCol>
                                                    <CCol sm="6">
                                                        <div class="">
                                                            <div class="subtitle bg-summary-embarque">
                                                                <div class="text-center text-color ">{{$t('label.fin')}}</div>
                                                    
                                                                    
                                                            
                                                            </div>
                                                        </div>
                                                    </CCol>
                                                    <CCol sm="6">
                                                    <table class="col-sm-12 table-I-F">
                                                
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ $t('label.ballast')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                addLabelClasses="required"
                                                                                :label="$t('label.ballast')"
                                                                                v-model.trim="$v.StartBallast.$model"    
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartBallast)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ 'F.O '+$t('label.fuelOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.fuelOil')"
                                                                                v-model.trim="$v.StartFuelOil.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartFuelOil)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>                                                       
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.O '+$t('label.dieselOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.dieselOil')"
                                                                                v-model.trim="$v.StartDieselOil.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartDieselOil)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'F.W '+$t('label.freshWater')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.freshWater')"
                                                                                v-model.trim="$v.StartFreshWater.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartFreshWater)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ $t('label.constant')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.constant')"
                                                                                v-model.trim="$v.StartConstant.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartConstant)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </CCol>
                                                    
                                                    <CCol sm="6">
                                                    <table class="col-sm-12 table-I-F">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ $t('label.ballast')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                addLabelClasses="required"
                                                                                :label="$t('label.ballast')"
                                                                                v-model.trim="$v.EndBallast.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndBallast, false, '', true)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ 'F.O '+$t('label.fuelOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.fuelOil')"
                                                                                v-model.trim="$v.EndFuelOil.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndFuelOil, false, '', true)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div> 
                                                                </th>                               
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.O '+$t('label.dieselOil')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.dieselOil')"
                                                                                v-model.trim="$v.EndDieselOil.$model"  
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndDieselOil, false, '', true)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>     
                                                                </th> 
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'F.W '+$t('label.freshWater')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.freshWater')"
                                                                                v-model.trim="$v.EndFreshWater.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndFreshWater, false, '', true)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>    
                                                                </th> 
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ $t('label.constant')+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.constant')"
                                                                                v-model.trim="$v.EndConstant.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndConstant, false, '', true)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>    
                                                                </th> 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </CCol>                                 
                                                </CRow>     
                                            </CCol>
                                            <div v-if="totalgross>itinerarySelected.Gross" style="font-weight: normal;" class="text-invalid-feedback text-center mb-3">
                                                {{ $t('label.VesselsGrossIs')+': '+formatNumber(itinerarySelected.Gross)+' TON '+$t('label.and')+' '+$t('label.CurrentGrossIs')+': '+formatNumber(totalgross)+' TON' }}
                                            </div>                                    
                                        </CRow>
                                </CustomTab>
                            
                        
                        
                        
                                <CustomTab :title="$t('label.draft')" icon="draft">
                                        <CRow class="mb-3">

                                            <CCol sm="12" class="mb-3">
                                                <CRow>
                                                    <CCol sm="6">
                                                        <div class="text-center">
                                                            <div class="subtitle bg-summary-descarga mb-2">
                                                                <div class="text-color ">{{$t('label.inicio')}}</div>
                                                            </div>
                                                        </div>
                                                    </CCol>
                                                    <CCol sm="6">
                                                        <div class="">
                                                            <div class="subtitle bg-summary-embarque">
                                                                <div class="text-center text-color ">{{$t('label.fin')}}</div>
                                                            </div>
                                                        </div>
                                                    </CCol>
                                                    <CCol sm="6">
                                                    <table class="col-sm-12 table-I-F">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row mt-2">
                                                                        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ 'D.A - '+$t('label.draftAft')+' (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.draftAft')"
                                                                                v-model.trim="$v.StartDraftAft.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartDraftAft, true, 1)"
                                                                            >
                                                                            </money>
                                                                            <div v-if="setNum(StartDraftAft)>itinerarySelected.Popa" style="font-weight: normal;" class="text-invalid-feedback">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(itinerarySelected.Popa) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.M (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.constant')"
                                                                                v-model.trim="$v.StartDM.$model"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartDM)"
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div>

                                                                </th>                                                       
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.F - '+$t('label.draftForward')+' (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.draftForward')"
                                                                                v-model.trim="StartDraftForward"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(StartDraftForward, true, 3)"
                                                                            >
                                                                            </money>
                                                                            <div v-if="setNum(StartDraftForward)>itinerarySelected.Proa" style="font-weight: normal;" class="text-invalid-feedback">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(itinerarySelected.Proa) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </CCol>
                                                    
                                                    <CCol sm="6">
                                                    <table class="col-sm-12 table-I-F">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row mt-2">
                                                                        <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ 'D.A - '+$t('label.draftAft')+' (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.draftAft')"
                                                                                v-model.trim="EndDraftAft"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndDraftAft, true, 1)"
                                                                            >
                                                                            </money>
                                                                            <div v-if="setNum(EndDraftAft)>itinerarySelected.Popa" style="font-weight: normal;" class="text-invalid-feedback">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(itinerarySelected.Popa) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.M (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.constant')"
                                                                                v-model.trim="EndDM"
                                                                                v-bind="measure"
                                                                                :class="ValidateFormControl(EndDM)"      
                                                                            >
                                                                            </money>
                                                                        </div>
                                                                    </div> 
                                                                </th>                               
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div class="form-group form-row">
                                                                        <label class="text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{ 'D.F - '+$t('label.draftForward')+' (MTR)' }}</label>
                                                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm mt-2">
                                                                            <money
                                                                                v-uppercase
                                                                                :label="$t('label.draftForward')"
                                                                                v-model.trim="EndDraftForward"
                                                                                v-bind="measure" 
                                                                                :class="ValidateFormControl(EndDraftForward, true, 3)"
                                                                            >
                                                                            </money>
                                                                            <div v-if="setNum(EndDraftForward)>itinerarySelected.Proa" style="font-weight: normal;" class="text-invalid-feedback">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(itinerarySelected.Proa) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>     
                                                                </th> 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </CCol>             
                                                </CRow>     
                                            </CCol>   
                                        </CRow>
                                </CustomTab>    
                            
                                <CustomTab :title="$t('label.hold')" icon="hold">
                                        <CRow class="mb-3">
                                            <CCol sm="12">
                                                <CCard bodyWrapper class="mb-3">
                                                    <CRow v-if="!IsEdit">
                                                            <CCol v-for="(item) in Bodega" :key="item.VesselHoldId" sm="12" lg="6" xxl="4" class="px-0">
                                                                <div class="form-row">
                                                                    <label class="text-right col-sm-12 col-lg-4 mt-2 col-form-label-sm">{{ item.VesselHoldName+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm mt-2">
                                                                        <money
                                                                            :label="$t('label.hold')"
                                                                            v-model.trim="item.BaleTon"
                                                                            v-bind="measure"
                                                                            :class="ValidateHoldControl(item.BaleTon, item.MaxBale, setNum(StowageFactor)) ? 'form-control is-valid': (ValidateHoldControl2(item.BaleTon, item.MaxBale, setNum(StowageFactor)))"
                                                                        >
                                                                        </money>
                                                                        <CCol lg="12" v-if="(item.BaleTon > item.MaxBale*setNum(StowageFactor))">
                                                                            <CRow class="justify-content-end">
                                                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="item.BaleTon > item.MaxBale*setNum(StowageFactor)">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(item.MaxBale*setNum(StowageFactor)) }}
                                                                                </CCol>
                                                                            </CRow>
                                                                        </CCol>
                                                                    </div>
                                                                </div>                                  
                                                            </CCol>
                                                            <CCol lg="12" v-if="HoldValid && (totalgross<this.itinerarySelected.Gross)">
                                                                <CRow class="justify-content-end">
                                                                    <CCol class="col-sm-12 col-lg-12 text-invalid-feedback text-center">
                                                                        {{$t('label.errorHoldPleaseCheck')}}
                                                                    </CCol>
                                                                </CRow>
                                                            </CCol>
                                                    </CRow>
                                                    <CRow v-if="IsEdit">    
                                                            <CCol v-for="(item) in BodegaUpdate" :key="item.VesselHoldId" sm="12" lg="6" xxl="4" class="px-0">     
                                                                <div  class="form-row">
                                                                    <label class="text-right col-sm-12 col-lg-4 mt-2 col-form-label-sm">{{ item.VesselHoldName+' '+$t('label.metricTonnageAcronym') }}</label>
                                                                    <div class="input-group col-sm-12 col-lg-7 input-group-sm mt-2">
                                                                        <money
                                                                        :label="$t('label.hold')"
                                                                            v-model.trim="item.CertificateWeigth"
                                                                            v-bind="measure"
                                                                            :class="ValidateHoldControl(item.CertificateWeigth, item.MaxBale, setNum(StowageFactor)) ? 'form-control is-valid': (ValidateHoldControl2(item.CertificateWeigth, item.MaxBale, setNum(StowageFactor)))"
                                                                            :disabled="item.disabled"
                                                                        >
                                                                        </money>
                                                                        <CCol lg="12" v-if="(item.CertificateWeigth > item.MaxBale*setNum(StowageFactor))">
                                                                            <CRow class="justify-content-end">
                                                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="item.CertificateWeigth > item.MaxBale*setNum(StowageFactor)">
                                                                                {{ $t('label.maxValueIs')+': '+formatNumber(item.MaxBale*setNum(StowageFactor)) }}
                                                                                </CCol>
                                                                            </CRow>
                                                                        </CCol>
                                                                    </div>
                                                                </div>
                                                            </CCol>
                                                            <CCol lg="12" v-if="HoldValid && (totalgross<this.itinerarySelected.Gross)">
                                                                <CRow class="justify-content-end">
                                                                    <CCol class="col-sm-12 col-lg-12 text-invalid-feedback text-center">
                                                                        {{$t('label.errorHoldPleaseCheck')}}
                                                                    </CCol>
                                                                </CRow>
                                                            </CCol>
                                                    </CRow>
                                                </CCard>
                                                <div v-if="totalgross>itinerarySelected.Gross" style="font-weight: normal;" class="text-invalid-feedback text-center mb-3">
                                                    {{ $t('label.VesselsGrossIs')+': '+formatNumber(itinerarySelected.Gross)+' TON '+$t('label.and')+' '+$t('label.CurrentGrossIs')+': '+formatNumber(totalgross)+' TON'}}
                                                </div>
                                            </CCol>
                                        </CRow>
                                </CustomTab>
                            </CustomTabs>
                        </CCol>
                    </CRow>
                </CCol> 
            </CRow>
        </CCardBody>
    <template #footer>
            <CButton
                square
                color="add"
                :disabled="isSubmit"
                @click.stop="IsEdit ? statusConfirmation(EditDraftItems.FgActVistDraft , Status, submitBody) : submitBody()"
            >
                <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
            <CButton color="wipe" @click="CloseModal(false)">
                <CIcon name="x" />&nbsp; {{$t('button.exit')}}
            </CButton>
      </template>
</CModalExtended>   
</form>    
</template>
<script>
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { VMoney, Money } from "v-money";
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import validationDraft from '@/_validations/draft_inspection/validationDraft_inspection';
import { reset } from 'numeral';

    //data
    function data() {
        return {
            tap: 0,
            isSubmit: false,
            IsEdit: false,
            showModal: false,
            previousDate: new Date(),
            laterDate: new Date(),
            ValidPreviousDate: new Date(),
            ValidLaterDate: new Date(),
            fromDay:"",
            toDay:"",
            StartDraftAft: 0,
            StartDM: 0,
            StartDraftForward: 0,
            EndDraftAft: 0,
            EndDM: 0,
            EndDraftForward: 0,
            StartFreshWater: 0,
            StartConstant: 0,
            StartDieselOil: 0,
            StartFuelOil: 0,
            StartBallast: 0,
            EndFreshWater: 0,
            EndConstant: 0,
            EndDieselOil: 0,
            EndFuelOil: 0,
            EndBallast: 0,
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
                maxlength: 16,
            },
            measure_2: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 4,
                masked: false,
                maxlength: 16,
            },
            Status: 0,
            OriginStatus: 0,
            position: [],
            StowageFactor: 0,
            /*Density: 0,*/
            Bodega: [],
            BodegaUpdate: [],
            BodegaMT: [],
            apiStateLoading: false,
            VisitDraftId: "",
            valid: false,
            Max: false,
            Min: false,
            HoldValid: false,
            Maxvald: false,

            FlagDraftAft: false,
            FlagDM: false,
            FlagDraftForward: false,
            FlagFreshWater: false,
            FlagConstant: false,
            FlagDieselOil: false,
            FlagFuelOil: false,
            FlagBallast: false,
        }
    }
    //methods
    function statusSelectColor() {
        return this.Status === 1;
    }

    function handleTab(Tab) {
        this.tap = Tab;
    }

    function CloseModal(Reset) {
        this.HoldValid = false;
        this.isSubmit=false;
        this.BodegaUpdate = [];
        this.VisitDraftId = "";
        this.Status = 0;
        this.fromDay="";
        this.toDay="";
        this.StowageFactor = 0;
        /*this.Density = 0;*/

        this.BodegaMT = [];
        this.Bodega =[];

        this.StartDraftAft = 0;
        this.StartDM = 0;
        this.StartDraftForward = 0;

        this.StartFreshWater = 0;
        this.StartConstant = 0;
        this.StartDieselOil = 0;
        this.StartFuelOil = 0;
        this.StartBallast = 0;

        this.EndDraftAft = 0;
        this.EndDM = 0;
        this.EndDraftForward = 0;

        this.EndFreshWater = 0;
        this.EndConstant = 0;
        this.EndDieselOil = 0;
        this.EndFuelOil = 0;
        this.EndBallast = 0;

        this.IsEdit = false;
        this.Max = false;
        this.Min = false;
        this.valid = false;
        this.Maxvald = false;
        this.tap = 0;
        this.FlagDraftAft = false,
        this.FlagDM = false,
        this.FlagDraftForward = false,
        this.FlagFreshWater = false,
        this.FlagConstant = false,
        this.FlagDieselOil = false,
        this.FlagFuelOil = false,
        this.FlagBallast = false,
        this.$v.$reset();
        this.$emit('close-Modal', Reset);
     }

    function submitBody() {
        try {
            this.apiStateLoading = true;
            this.BodegaMT=[];
            this.isSubmit=true;
            this.valid = true;
            this.Max = false;
            this.Min = false;
            this.Maxvald = false;
            this.HoldValid = false;
            this.MaxHold();
            this.MinHold();
            let ValidateGross = totalgross>this.itinerarySelected.Gross ? true : false;
            this.$v.$touch();
            if (this.$v.$error||ValidateGross) {
                this.isSubmit=false;
                if (this.Max) {
                     this.Maxvald = true;
                }
                if (!this.Min) {
                    this.HoldValid = true;
                }
                if ((DateFormater.formatDateTimeWithoutSlash(this.fromDay)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined)
                    || DateFormater.formatDateTimeWithoutSlash(this.toDay)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
                    throw this.$t('label.ErrorAtaPleaseCheck');
                }
                if ((DateFormater.formatDateTimeWithoutSlash(this.fromDay)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined)
                    || DateFormater.formatDateTimeWithoutSlash(this.toDay)<this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
                    throw this.$t('label.ErrorEtaPleaseCheck');
                }
                if ((DateFormater.formatDateTimeWithoutSlash(this.fromDay)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined)
                    || DateFormater.formatDateTimeWithoutSlash(this.toDay)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
                    throw this.$t('label.ErrorAtdPleaseCheck');
                }
                if(this.fromDay != ''){
                    if (DateFormater.formatDateTimeWithoutSlash(this.fromDay)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
                        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                    }
                }
                if(this.toDay != ''){
                    if (DateFormater.formatDateTimeWithoutSlash(this.toDay)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
                        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                    }  
                }
                if(this.fromDay != '' && this.toDay != ''){
                    if(this.fromDay != '' && this.toDay != ''){
                        if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay)) {
                            throw this.$t('label.ErrorFromToPleaseCheck');
                        }
                    }
                }
                this.apiStateLoading = false;
                throw this.$t('label.errorsPleaseCheck');
            }

            this.HoldStatus();
            this.formNum();
            
            if (this.IsEdit) {
                this.BodegaUpdate.map((item) => {
                    this.BodegaMT.push({
                        CertificateWeigth: item.CertificateWeigth,
                        VesselHoldId: item.VesselHoldId,
                        VisitDraftHoldId: item.VisitDraftHoldId,
                        Status: item.Status === 'ACTIVO' ? 1 : 0,
                    });
                });
            }else{
               this.Bodega.map((item) => {
                    this.BodegaMT.push({
                        CertificateWeigth: item.BaleTon,
                        VesselHoldId: item.VesselHoldId,
                    });
                }); 
            }
            let dateStart= DateFormater.formatDateTimeWithoutSlash(this.fromDay);
            let dateEnd= DateFormater.formatDateTimeWithoutSlash(this.toDay);

                const VisitDraftJson = this.IsEdit?[{ 
                    VisitDraftGeneralJson: {
                        VisitId: this.VisitId,
                        VisitDraftId: this.VisitDraftId,
                        DateFrom: dateStart,
                        DateTo: dateEnd,
                        StowageFactor: this.StowageFactor,
                        /*Density: this.Density,*/
                        Ballast: [{I: this.StartBallast, F: this.EndBallast}],
                        FuelOil: [{I: this.StartFuelOil, F: this.EndFuelOil}],
                        DieselOil: [{I: this.StartDieselOil, F: this.EndDieselOil}],
                        FreshWater: [{I: this.StartFreshWater, F: this.EndFreshWater}],
                        Constant: [{I: this.StartConstant, F: this.EndConstant}],
                        DraftAft: [{I: this.StartDraftAft, F: this.EndDraftAft}],
                        DraftForward: [{I: this.StartDraftForward, F:this.EndDraftForward}],
                        DraftMidShip: [{I: this.StartDM, F: this.EndDM}],
                        Status: this.Status,

                        VisitDraftHoldJson: this.BodegaMT,
                    }    
                }]:[{ 
                    VisitDraftGeneralJson: {
                        VisitId: this.VisitId,
                        DateFrom: dateStart,
                        DateTo: dateEnd,
                        StowageFactor: this.StowageFactor,
                        /*Density: this.Density,*/
                        Ballast: [{I: this.StartBallast, F: this.EndBallast}],
                        FuelOil: [{I: this.StartFuelOil, F: this.EndFuelOil}],
                        DieselOil: [{I: this.StartDieselOil, F: this.EndDieselOil}],
                        FreshWater: [{I: this.StartFreshWater, F: this.EndFreshWater}],
                        Constant: [{I: this.StartConstant, F: this.EndConstant}],
                        DraftAft: [{I: this.StartDraftAft, F: this.EndDraftAft}],
                        DraftForward: [{I: this.StartDraftForward, F:this.EndDraftForward}],
                        DraftMidShip: [{I: this.StartDM, F: this.EndDM}],

                        VisitDraftHoldJson: this.BodegaMT,
                    }    
                }]
                let metodo = this.IsEdit ? 'PUT':'POST';
                let ruta = this.isEdit ? 'VisitDraft-update':'VisitDraft-insert';
                if (this.Bodega.length > 0 && this.Max === false && this.Min === true && dateStart < dateEnd) {
                    this.$http
                        .ejecutar( metodo, ruta, VisitDraftJson, { root: 'VisitDraftJson' })
                        .then((response) => {
                            if (response && response.status === (200 || 201)) {
                                this.apiStateLoading = false;
                                this.CloseModal(true);
                                this.notifySuccess({text: response.data.data[0].Response});
                            } 
                        }).catch((err) => {
                            this.isSubmit=false;
                            this.apiStateLoading = false;
                            this.notifyError({text: err});
                        })
                }else{
                    if (this.Max) {
                        this.Maxvald = true;
                    }
                    if (!this.Min) {
                        this.HoldValid = true;
                       this.notifyError({text: this.$t('label.errorHoldPleaseCheck')});
                    }else{
                        this.notifyError();
                    }
                    this.isSubmit=false;
                    this.apiStateLoading = false;
                }
        } catch (e) {
            this.isSubmit=false;
            this.apiStateLoading = false;
            this.notifyError({text: e});
        }
    }

    function formNum() {
        /*this.Density = setNum(this.Density);*/
        this.StowageFactor = setNum(this.StowageFactor);
        this.StartBallast = setNum(this.StartBallast);
        this.EndBallast = setNum(this.EndBallast);
        this.StartFuelOil = setNum(this.StartFuelOil);
        this.EndFuelOil = setNum(this.EndFuelOil);
        this.StartDieselOil = setNum(this.StartDieselOil);
        this.EndDieselOil = setNum(this.EndDieselOil);
        this.StartFreshWater = setNum(this.StartFreshWater);
        this.EndFreshWater = setNum(this.EndFreshWater);
        this.StartConstant = setNum(this.StartConstant);
        this.EndConstant = setNum(this.EndConstant);
        this.StartDraftAft = setNum(this.StartDraftAft);
        this.EndDraftAft = setNum(this.EndDraftAft);
        this.StartDraftForward = setNum(this.StartDraftForward);
        this.EndDraftForward = setNum(this.EndDraftForward);
        this.StartDM = setNum(this.StartDM);
        this.EndDM = setNum(this.EndDM);
    }
    
    function MaxHold() {
        let i = 0;
        if (this.IsEdit) {
            this.BodegaUpdate.map((item) => {
                if (item.CertificateWeigth > (this.Bodega[i].MaxBale*setNum(this.StowageFactor))) {
                    this.Max = true;
                }
                i++;
            });
        }else{
            this.Bodega.map((item) => {
                if (item.BaleTon > (item.MaxBale*setNum(this.StowageFactor))) {
                    this.Max = true;
                }
            });
        }
    }

    function MinHold() {
        if (this.IsEdit) {
            this.BodegaUpdate.map((item) => {
                if (item.CertificateWeigth > 0) {
                    this.Min = true;
                }
            });
        }else{
            this.Bodega.map((item) => {
                if (item.BaleTon > 0) {
                    this.Min = true;
                }
            });
        }
    }

    function HoldStatus() {
        
        if (this.Status != this.OriginStatus) {
            if (this.Bodega.length > this.BodegaUpdate.length || this.Bodega.length == this.BodegaUpdate.length) {
                for (let index = 0; index < this.BodegaUpdate.length; index++) {
                    this.BodegaUpdate[index].Status = 'ACTIVO';     
                }
            }else{
                for (let index = 0; index < this.BodegaUpdate.length; index++) {
                    if (this.position[index] == index) {
                        this.BodegaUpdate[index].Status = 'ACTIVO';
                    }else{
                        this.BodegaUpdate[index].Status = 0;
                    }
                }
            }
        }
    }

    function Prueba(Num) {
        let New = [];
        let val = Num;
        if ( !isNaN(Num) ) {
            return Num;
        }
        else{
            New = val.split(',');
            let Punto = New[0].split('.');
            for(let index = 1; index < Punto.length; index++){
                val = val.replace(".", "");
            }
            val = val.replace(",", ".");
        } 
    }
    
    function setNum(Num) {
        let New = [];
        let val = Num;
        if ( !isNaN(Num) ) {
            return Num;
        }
        else{
            New = val.split(',');
            let Punto = New[0].split('.');
            for(let index = 1; index < Punto.length; index++){
                val = val.replace(".", "");
            }
            val = val.replace(",", ".");
            return Number.parseFloat(val);
        }
    }

    function byIdHold(){
        this.apiStateLoading = true;
        this.Bodega =[];
        let id = this.VisitId;
        this.$http
            .get('VisitVesselHoldDensity-list', { VisitId: id } )
            .then(res => {
                const Information = res.data.data;
                if (Information[0].VesselHoldJson.length > 0) {
                    Information[0].VesselHoldJson.map((item) => {
                        this.Bodega.push({ 
                            BaleTon: 0,
                            MaxBale: item.Bale,
                            VesselHoldId: item.VesselHoldId,
                            VesselHoldName: item.VesselHoldName,
                        });
                    });
                }
                /*if (Information[0].DensityInfoJson.length > 0) {
                    this.Density = formatNumber(Information[0].DensityInfoJson[0].Density)
                }*/
            }).finally(() => {
                if (this.IsEdit) {
                    this.getBodegaUpdate();
                } else {
                    this.apiStateLoading = false;
                }
            });    
    }
    function getBodegaUpdate() {
        let p = 0;
        let edit = this.EditDraftItems.DraftHoldJson;
        for (let i = 0; i < this.Bodega.length; i++) {
            for (let b = 0; b < edit.length; b++) {
                if (edit[b].VesselHoldId == this.Bodega[i].VesselHoldId) {
                    this.BodegaUpdate.push({ 
                        CertificateWeigth: edit[b].CertificateWeigth,
                        disabled: false,
                        MaxBale: this.Bodega[i].MaxBale,
                        VesselHoldName: edit[b].VesselHoldName,
                        VesselHoldId: edit[b].VesselHoldId,
                        VisitDraftHoldId: edit[b].VisitDraftHoldId,
                        Status: edit[b].Status,
                    });
                }
            }     
        }
        this.position = [];
        if (this.Bodega.length > this.BodegaUpdate.length) {
            for (let i = 0; i < this.Bodega.length; i++) {
                for (let b = 0; b < this.BodegaUpdate.length; b++) {
                    if (this.BodegaUpdate[b].VesselHoldId == this.Bodega[i].VesselHoldId) {
                        this.position[i]=i;
                    }
                    if (this.position[i] != i) {
                        this.position[i]='N/A';
                    }
                }         
            }
            p = 0;
            this.Bodega.map((item) => {
                if (this.position[p] != p ) {
                    this.BodegaUpdate.push({ 
                        CertificateWeigth: item.BaleTon,
                        disabled: false,
                        MaxBale: item.MaxBale,
                        VesselHoldName: item.VesselHoldName,
                        VesselHoldId: item.VesselHoldId,
                        VisitDraftHoldId: "",
                        Status: 'ACTIVO',
                    });
                }
                p++;
            });
        }
        if (this.Bodega.length < this.BodegaUpdate.length) {
            for (let i = 0; i < this.BodegaUpdate.length; i++) {
                for (let b = 0; b < this.Bodega.length; b++) {
                    if (this.BodegaUpdate[i].VesselHoldId == this.Bodega[b].VesselHoldId) {
                        this.position[i]=i;
                    }
                    if (this.position[i] != i) {
                        this.position[i]='N/A';
                    }
                }     
            }
            p = 0;
            this.BodegaUpdate.map((item) => {
                if (this.position[p] != p ) {
                    this.BodegaUpdate[p].disabled = true;
                }
                p++;
            });
        }
        this.apiStateLoading = false; 
    } 

    function totalgross() {
        let GrossSuma = setNum(this.EndFreshWater)+setNum(this.EndConstant)+setNum(this.EndDieselOil)+setNum(this.EndFuelOil)+setNum(this.EndBallast);
        let Totalgross = this.TotalGross(GrossSuma, this.IsEdit, this.Bodega, this.BodegaUpdate);

        return Totalgross;
    }

    function ValidateFormControl(value, required, draft, End) {
        if((this.totalgross>this.itinerarySelected.Gross) && End){
            return 'form-control is-invalid';
        }else{
            value = setNum(value);
            let MaxDraft = true;
            if(!isNaN(draft)){
                MaxDraft = draft==1 ? this.itinerarySelected.Popa : draft==2 ? true : this.itinerarySelected.Proa;
            }
            if(((value == 0 && this.valid==true) || value < 0 || value > 1) && required && MaxDraft < value){
                return 'form-control is-invalid';
            }else{
                if(value == 0 && this.valid==false){
                    return 'form-control';
                }else{
                    return 'form-control is-valid';
                }
            }
        } 
    }

    function ValidateHoldControl(value, MaxBale, StowageFactor) {
        value = this.setNum(value);
        if (value > (MaxBale*StowageFactor)||this.totalgross>this.itinerarySelected.Gross) {
            return false;
        }else if((value == 0 || value == '0,00') && (!this.Maxvald)){
                return false;
            }else{
                return true;     
            }    
    }

    function ValidateHoldControl2(value, MaxBale, StowageFactor) {
        value = setNum(value);
        if (value > (MaxBale*StowageFactor)||this.totalgross>this.itinerarySelected.Gross) {
            return 'form-control is-invalid';
        }else{
            if (!this.HoldValid && (value == 0 || value == '0,00')) {
                if (this.valid==false) {
                    return 'form-control';
                }else{
                    return 'form-control is-valid';
                }
            }
            else{
                return 'form-control is-invalid';
            } 
        }    
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    function getDateRange(){
        let currentDate = new Date();
        if(this.itinerarySelected.Ata!=undefined){ 
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
            this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
        }

        if(this.itinerarySelected.Ata==undefined){
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
            this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
        }

        if(this.itinerarySelected.Atd!=undefined){  
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
            this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
        }

        if(this.itinerarySelected.Atd==undefined){
            this.laterDate = currentDate;
            this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash((currentDate));
        }
    }

    function formatNumber(number) {
        if (number == "") return "0,00";
        let format = Number.parseFloat(number).toFixed(2);
        format = format.replace(".", ",");

        return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
    }

    //computed
    function tituloModal() {
        if(!this.IsEdit){
            return this.$t('label.nueva')+' '+this.$t('label.DraftInspection');
        } else {
            return this.$t('label.edit')+' '+this.$t('label.DraftInspection');
        } 
    }
    //watch
    function gethold(val){
        this.VisitDraftId = val.VisitDraftId;
        this.Status = val.FgActVistDraft?1:0;
        this.OriginStatus = val.FgActVistDraft?1:0;
        
        this.fromDay=val.DateFrom;
        this.toDay=val.DateTo;
                    
        this.StowageFactor = val.StowageFactor;
        /*this.Density = val.Density;*/

        this.StartDraftAft = val.DraftAft[0].I;
        this.StartDM = val.DraftMidShip[0].I;
        this.StartDraftForward = val.DraftForward[0].I;

        this.StartFreshWater = val.FreshWater[0].I;
        this.StartConstant = val.Constant[0].I;
        this.StartDieselOil = val.DieselOil[0].I;
        this.StartFuelOil = val.FuelOil[0].I;
        this.StartBallast = val.Ballast[0].I;

        this.EndDraftAft = val.DraftAft[0].F;
        this.EndDM = val.DraftMidShip[0].F;
        this.EndDraftForward = val.DraftForward[0].F;

        this.EndFreshWater = val.FreshWater[0].F;
        this.EndConstant = val.Constant[0].F;
        this.EndDieselOil = val.DieselOil[0].F;
        this.EndFuelOil = val.FuelOil[0].F;
        this.EndBallast = val.Ballast[0].F;
        
        this.valid = true;
        this.Maxvald = true;
        this.BodegaUpdate=[];
        this.getdata();
        this.$v.$touch(); 
    }

    function getdata(){
        if(!this.isEdit){
            this.StowageFactor = this.stowagefactor;
        }
        this.getDateRange();
        this.byIdHold();
    }

    function TotalGross(val, isEdit, Bodega, BodegaUpdate){
        let totalGross = val;
        if (isEdit) {
            BodegaUpdate.map((item) => {
                totalGross = totalGross+this.setNum(item.CertificateWeigth);
            });
        }else{
            Bodega.map((item) => {
                totalGross = totalGross+this.setNum(item.BaleTon);
            }); 
        }
        return Number.parseFloat(totalGross).toFixed(2);
    }
    
    export default {
        name:'draft-inspection',
        data,
        props: {
            generar: Boolean, 
            EditDraftItems: Object,
            isEdit: Boolean,
        },
        mixins: [GeneralMixin, ModalMixin],
        validations(){
            return validationDraft(DateFormater.formatDateTimeWithoutSlash(this.fromDay), DateFormater.formatDateTimeWithoutSlash(this.toDay), this.ValidLaterDate, this.ValidPreviousDate, this.itinerarySelected);
        },
        directives: UpperCase,
        components: {
            CustomTabs,
            CustomTab,
            Money,
        },
        methods:{
            Prueba,
            TotalGross,
            handleTab,
            MinHold,
            MaxHold,
            HoldStatus,
            formatNumber,
            setNum,
            formNum,
            statusSelectColor,
            gethold,
            ValidateFormControl,
            ValidateHoldControl2,
            ValidateHoldControl,
            getdata,
            submitBody,
            CloseModal,
            validateDateRange,
            getDateRange,
            byIdHold,
            getBodegaUpdate,
        },
        computed: {
            totalgross,
            tituloModal,
            ...mapState({
                VisitId: state => state.visitas.VisitId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                stowagefactor: state => state.visitas.StowageFactor,
            })
        },
        watch: {
            fromDay:async function(val) {
                try{
                    if(val != ''){
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
                            throw this.$t('label.ErrorAtaPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
                            throw this.$t('label.ErrorEtaPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
                            throw this.$t('label.ErrorAtdPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
                            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) && this.toDay!='') {
                            throw this.$t('label.ErrorFromToPleaseCheck');
                        }
                    }
                }catch(e){
                    this.notifyError({text: e});
                }
            },
            toDay:async function(val) {
                try{
                    if(val != ''){
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
                            throw this.$t('label.ErrorAtaPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
                            throw this.$t('label.ErrorEtaPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
                            throw this.$t('label.ErrorAtdPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
                            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) && this.fromDay!='') {
                            throw this.$t('label.ErrorFromToPleaseCheck');
                        }
                    }
                }catch(e){
                    this.notifyError({text: e});
                }
            },
            StartFreshWater: function(val) {
                if(this.FlagFreshWater==false && !isNaN(val)){
                    this.EndFreshWater = val;
                }
            },
            EndFreshWater: function(val) {
                if(!isNaN(val)){
                    if(val != this.StartFreshWater){
                        this.FlagFreshWater = val;
                    }
                }
            },
            StartConstant: function(val) {
                if(this.FlagConstant==false && !isNaN(val)){
                    this.EndConstant = val;
                }
            },
            EndConstant: function(val) {
                if(!isNaN(val)){
                    if(val != this.StartConstant){
                        this.FlagConstant = val;
                    }
                }
            },
            StartDieselOil: function(val) {
                if(this.FlagDieselOil==false && !isNaN(val)){
                    this.EndDieselOil = val;
                }
            },
            EndDieselOil: function(val) {
                if(!isNaN(val)){
                    if(val != this.StartDieselOil){
                        this.FlagDieselOil = val;
                    }
                }
            },
            StartFuelOil: function(val) {
                if(this.FlagFuelOil==false && !isNaN(val)){
                    this.EndFuelOil = val;
                }
            },
            EndFuelOil: function(val) {
                if(!isNaN(val)){
                    if(val != this.StartFuelOil){
                        this.FlagFuelOil = val;
                    }
                }
            },
            StartBallast: function(val) {
                if(this.FlagBallast==false && !isNaN(val)){
                    this.EndBallast = val;
                }
            },
            EndBallast: function(val) {
                if(!isNaN(val)){
                    if(val != this.StartBallast){
                        this.FlagBallast = val;
                    }
                }
            },
            StartDraftAft: function(val) {
                if(this.FlagDraftAft==false && !isNaN(val)){
                    this.EndDraftAft = val;
                } 
            },
            EndDraftAft: function(val) {
                if(!isNaN(val) && val != this.StartDraftAft){
                    this.FlagDraftAft = true;
                }
            },
            StartDM: function(val) {
                if(this.FlagDM==false && !isNaN(val)){
                    this.EndDM = val;
                } 
            },
            EndDM: function(val) {
                if(!isNaN(val) && val != this.StartDM){
                    this.FlagDM = true;
                }
            },
            StartDraftForward: function(val) {
                if(this.FlagDraftForward==false && !isNaN(val)){
                    this.EndDraftForward = val;
                } 
            },
            EndDraftForward: function(val) {
                if(!isNaN(val) && val != this.StartDraftForward){
                    this.FlagDraftForward = true;
                }
            },
            generar: function(val) {
                this.tap = 0;
                this.showModal = val;
                this.IsEdit = this.isEdit;
                if (val) {
                    if (this.isEdit) {
                    this.gethold(this.EditDraftItems);
                    }else{
                        this.getdata();
                    }
                }
            },          
        },
    }
</script>
<style lang="scss">
.container-summary {
  width: 85%;
  margin-bottom: 5px;
}
.summary-total {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: #8f99a5;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
}
.float-right {
  margin-left: auto;
}

.summary-container-subtitle,
.summary-table-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.summary-container-subtitle {
  margin-bottom: 15px;
}
.subtitle {
  display: flex;
  padding: 10px;
  border-radius: 4px;
}
    .table-I-F{
        padding: 0.75rem;
        border: 1px solid;
        border-color: #d8dbe0;
    }


.bg-summary-descarga {
  background-color: #EC631B;
}
.bg-summary-embarque {
  background-color: #1A3760;

}
.text-color {
  color: #ffffff;
}

</style>