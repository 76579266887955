import ENUM from './enum';
import service from '@/_services/service';
import {DateFormater,validURL} from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

export const ubicacion = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
		id:0,
		tabIndex:'tab1',
		messageError:'',
		iconRoute:'',
        myDataPais:[],
        myDataEstado:[],
        myDataMunicipio:[],
		myDataParroquia:[],
		filtroPais:'',
        filtroEstado:'',
        filtroMunicipio:'',
        filtroParroquia:'',
        modalPais:false,
        modalEstado:false,
        modalMunicipio:false,
        modalParroquia:false,
    },
    getters: {
        myDataPaistable: state => {
            let chart = [];
            if(state.myDataPais.length !== 0){
                state.myDataPais.sort(function (a, b) {
                    if (a.FgActCountry < b.FgActCountry) return 1;
                    else if (a.FgActCountry > b.FgActCountry) return -1;
                    else {
                        if (a.CountryName > b.CountryName) {
                            return 1;
                        }
                        if (a.CountryName < b.CountryName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataPais.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
					Icon: (validURL(item.Icon) || item.Icon === null || item.Icon === '') ? item.Icon : `${DataStorage.getConnection()}${item.Icon.replace('public/','', null, 'i')}`,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActCountry,
                    _classes: (( item.FgActCountry === false ) ? 'table-danger' : ((state.filtroPais !== '' && state.filtroPais === item.CountryId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataEstadotable: state => {
            let chart = [];
            if(state.myDataEstado.length !== 0){
                state.myDataEstado.sort(function (a, b) {
                    if (a.FgActState < b.FgActState) return 1;
                    else if (a.FgActState > b.FgActState) return -1;
                    else {
                        if (a.CountryName > b.CountryName) {
                            return 1;
                        }
                        if (a.CountryName < b.CountryName) {
                            return -1;
                        }
                        return 0;
                    }
				})
				
                return state.myDataEstado.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActState,
                    _classes: (( item.FgActState === false ) ? 'table-danger' : ((state.filtroEstado !== '' && state.filtroEstado === item.CountryId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataMunicipiotable: state => {
            let chart = [];
            if(state.myDataMunicipio.length !== 0){
                state.myDataMunicipio.sort(function (a, b) {
                    if (a.FgActMunicipality < b.FgActMunicipality) return 1;
                    else if (a.FgActMunicipality > b.FgActMunicipality) return -1;
                    else {
                        if (a.MunicipalityName > b.MunicipalityName) {
                            return 1;
                        }
                        if (a.MunicipalityName < b.MunicipalityName) {
                            return -1;
                        }
                        return 0;
                    }
				})
				
                return state.myDataMunicipio.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActMunicipality,
                    _classes: (( item.FgActMunicipality === false ) ? 'table-danger' : ((state.filtroMunicipio !== '' && state.filtroMunicipio === item.MunicipalityId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataParroquiatable: state => {
            let chart = [];
            if(state.myDataParroquia.length !== 0){
                state.myDataParroquia.sort(function (a, b) {
                    if (a.FgActParish < b.FgActParish) return 1;
                    else if (a.FgActParish > b.FgActParish) return -1;
                    else {
                        if (a.ParishName > b.ParishName) {
                            return 1;
                        }
                        if (a.ParishName < b.ParishName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataParroquia.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActParish,
                    _classes: ( item.FgActParish ) ? '' : 'table-danger'
                }));
            }
            return chart;
        }
    },
    actions: {
        getPaislist({commit}){
			commit('getPaislisttRequest');
			service.ejecutar('GET', 'Country-list',  { Filter: 'ALL' } , '')
			.then(
				Paises => commit('getPaislistSuccess', { Paises }),
				error => {
					commit('messageMutation', error)
					commit('getPaislistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },
        postregisterPais ({ commit,dispatch }, { idPais,NbPais,CodIsoAlpha2,CodIsoAlpha3,Numeric,UtcZone,svgIcon,Latitude,Longitude,FgActPais,Image_original}) {
			commit('postregisterPaisRequest');
			try {
				let method = 'POST';
                let valores = {};
				let link = 'Country-insert';

				if(idPais === 0){
                    valores = {
                        CountryName:NbPais,
                        IsoCodeAlpha2:CodIsoAlpha2,
                        IsoCodeAlpha3:CodIsoAlpha3,
                        Numeric:Numeric,
                        UtcZone:UtcZone,
                        Icon:svgIcon,
                        Latitude:Latitude,
                        Longitude:Longitude
                    }
                }else{
                    method = 'PUT';
                    link = 'Country-update';
                    valores = {
						CountryId:idPais,
                        CountryName:NbPais,
                        IsoCodeAlpha2:CodIsoAlpha2,
                        IsoCodeAlpha3:CodIsoAlpha3,
                        Numeric:Numeric,
                        UtcZone:UtcZone,
                        Icon:svgIcon,
                        Latitude:Latitude,
						Longitude:Longitude,
						Status: FgActPais ? 1 : 0
                    }
                }
				(async () => {
					try{
						if(typeof svgIcon !== 'string'  && svgIcon !== null && svgIcon !== undefined && svgIcon !== ''){
							const response = await service.file(link,svgIcon,Image_original);
							const info = response.data.data;
							valores.Icon = info.files[0].path;
						}

						service.ejecutar(method, link, valores, { root: 'CountryJson' })
						.then(
							dataPaises => {
								if(dataPaises.data.status === 200){
									const information = dataPaises.data.data;
									if(information[0].id === ""){
										commit('messageMutation', information[0].Response)
										commit('postregisterPaisFailure', information[0].Response)
									}else{
										commit('postregisterPaisSuccess', { information })
										dispatch('ubicacion/getPaislist', '', { root: true });
									}
								}else{
									commit('postregisterPaisFailure', dataPaises.data);
								}
							},
							error => {
								commit('messageMutation', error)
								commit('postregisterPaisFailure', error)
								//dispatch('alert/error', error, { root: true });
							}
						);
					} catch (error) {
						commit('messageMutation', error)
						commit('postregisterPaisFailure', error)
					}
				})();
			} catch (error) {
				commit('messageMutation', error)
				commit('postregisterPaisFailure', error)
			}
        },
        getEstadolist({commit},{idPais}){
		
				commit('getEstadolistRequest');
				service.ejecutar('GET', 'State-list',  { Filter: 'ALL', CountryId:idPais } , '')
				.then(
					Estados => commit('getEstadolistSuccess', { Estados }),
					error => {
						commit('messageMutation', error)
						commit('getEstadolistFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			
			
        },
        postregisterEstado ({ commit }, { idEstado,NbEstado,IdPais,FgActEstado }) {
			commit('postregisterEstadoRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'State-insert';
                if(idEstado === 0){
                    valores = {
						CountryId:IdPais,
						StateName:NbEstado,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'State-update';
                    valores = {
						StateId:idEstado,
                        CountryId:IdPais,
						StateName:NbEstado,
                        Status: FgActEstado ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'StateJson' })
				.then(
					dataEstado => {
						if(dataEstado.data.status === 200){
							const information = dataEstado.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('postregisterEstadoFailure', information[0].Response)
							}else{
								commit('messageMutation', information[0].Response)
								commit('postregisterEstadoSuccess', { IdPais });
							}
						}else{
							commit('postregisterEstadoFailure', dataEstado.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('postregisterEstadoFailure', error)
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('postregisterEstadoFailure', error)
			}
				
		},
		getMunicipiolist({commit},{idEstado}){
			commit('getMunicipiolistRequest');
			service.ejecutar('GET', 'Municipality-list',  { Filter: 'ALL', StateId:idEstado } , '')
			.then(
				Municipios => commit('getMunicipiolistSuccess', { Municipios }),
				error => {
					commit('getMunicipiolistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },
        postregisterMunicipio ({ commit }, { IdPais,IdEstado,NbMunicipio,IdMunicipio,FgActMunicipio }) {
				commit('postregisterMunicipiosRequest');
				try {
					let method = 'POST';
					let valores = {};
					let link = 'Municipality-insert';
					if(IdMunicipio === 0){
						valores = {
							StateId:IdEstado,
							MunicipalityName:NbMunicipio,
							UserId:JSON.parse(localStorage.getItem('user')).UserId
						}
					}else{
						method = 'PUT';
						link = 'Municipality-update';
						valores = {
							MunicipalityId:IdMunicipio,
							StateId:IdEstado,
							MunicipalityName:NbMunicipio,
							Status: FgActMunicipio ? 1 : 0,
							UserId:JSON.parse(localStorage.getItem('user')).UserId
						}
					}
					
					service.ejecutar(method, link, valores, { root: 'MunicipalityJson' })
					.then(
						dataMunicipios => {
							if(dataMunicipios.data.status === 200){
								const information = dataMunicipios.data.data;
								if(information[0].id === ""){
									commit('messageMutation', information[0].Response)
									commit('postregisterMunicipioFailure', information[0].Response)
								}else{
									commit('messageMutation', information[0].Response)
									commit('postregisterMunicipiosSuccess', { IdPais,IdEstado });
									/*UbicacionDataService.getMunicipiolist(IdEstado)
									.then(
										Municipios => commit('postregisterMunicipiosSuccess', { Municipios }),
										error => {
											commit('postregisterMunicipioFailure', error)
											//dispatch('alert/error', error, { root: true });
										}
									);*/
								}
							}else{
								commit('postregisterMunicipioFailure', dataMunicipios.data);
							}
						},
						error => {
							commit('messageMutation', error)
							commit('postregisterMunicipioFailure', error)
							//dispatch('alert/error', error, { root: true });
						}
					);
				} catch (error) {
					commit('messageMutation', error)
					commit('postregisterMunicipioFailure', error)
				}
		},
		getParroquialist({commit},{idMunicipio}){
			commit('getParroquialistRequest');
			service.ejecutar('GET', 'Parish-list',  { Filter: 'ALL', MunicipalityId:idMunicipio } , '')
			.then(
				Parroquias => commit('getParroquialistSuccess', { Parroquias }),
				error => {
					commit('messageMutation', error)
					commit('getParroquialistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },
		postregisterParroquia ({ commit }, { IdPais,IdEstado,IdParroquia,IdMunicipio,NbParroquia,FgActParroquia }) {
				commit('postregisterParroquiaRequest');
				try{
					let method = 'POST';
					let valores = {};
					let link = 'Parish-insert';
					if(IdParroquia === 0){
						valores = {
							MunicipalityId:IdMunicipio,
							ParishName:NbParroquia,
							UserId:JSON.parse(localStorage.getItem('user')).UserId
						}
					}else{
						method = 'PUT';
						link = 'Parish-update';
						valores = {
							ParishId:IdParroquia,
							MunicipalityId:IdMunicipio,
							ParishName:NbParroquia,
							Status: FgActParroquia ? 1 : 0,
							UserId:JSON.parse(localStorage.getItem('user')).UserId
						}
					}
					
					service.ejecutar(method, link, valores, { root: 'ParishJson' })
					.then(
						dataParroquias => {
							if(dataParroquias.data.status === 200){
								const information = dataParroquias.data.data;
								if(information[0].id === ""){
									commit('messageMutation', information[0].Response)
									commit('postregisterParroquiaFailure', information[0].Response)
								}else{
									commit('messageMutation', information[0].Response)
									commit('postregisterParroquiaSuccess', { IdPais,IdEstado,IdMunicipio })
									/*UbicacionDataService.getParroquialist(IdMunicipio)
									.then(
										Parroquias => commit('postregisterParroquiaSuccess', { Parroquias }),
										error => {
											commit('postregisterParroquiaFailure', error)
											//dispatch('alert/error', error, { root: true });
										}
									);*/
								}
							}else{
								commit('postregisterParroquiaFailure', dataParroquias.data);
							}
						},
						error => {
							commit('messageMutation', error)
							commit('postregisterParroquiaFailure', error)
							//dispatch('alert/error', error, { root: true });
						}
					);
				} catch (error) {
					commit('messageMutation', error)
					commit('postregisterParroquiaFailure', error)
				} 
        },
    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
		},
		messageMutation (state, value ) {
            state.messageError = value;
		},
		mutationfiltroPais (state,  value ) {
			if (value) {
				state.filtroPais = value;
			} else {
				state.filtroPais = '';
				state.myDataEstado=[];
				state.filtroEstado='';
				state.myDataMunicipio=[];
			}
		},
		mutationfiltroEstado (state, value ) {
			state.filtroEstado = value;
		},
		mutationfiltroMunicipio (state,  value) {
			state.filtroMunicipio = value;
		},
		mutationfiltroParroquia (state,  value ) {
			state.filtroParroquia = value;
		},
        
        mutationModalPais (state,  action ) {
			state.modalPais = action;
        },
        mutationModalEstado (state, action ) {
			state.modalEstado = action;
        },
        mutationModalMunicipio (state,  action) {
			state.modalMunicipio = action;
        },
        mutationModalParroquia (state,  action ) {
			state.modalParroquia = action;
        },
		
		//Listar paises
        getPaislisttRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getPaislistSuccess(state, { Paises }) {
			try{
				if(Paises.status === 200){

					state.myDataPais = Paises.data.data;

					state.filtroPais = '';
					state.apiState = ENUM.LOADED;
				}else{
					console.log(Paises);
					state.apiState = ENUM.ERROR; 
				}
			}catch(error){
				console.log(error);
				state.apiState = ENUM.ERROR; 
			}
        },
        getPaislistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar un pais
		postregisterPaisRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		postregisterPaisSuccess (state, { information }) {
			try{
				state.messageError = information[0].Response;
				state.modalPais = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		postregisterPaisFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
        },
		
		//Listar Estados
        getEstadolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getEstadolistSuccess(state, { Estados }) {
			if((state.filtroPais!='') && (state.filtroPais!=null)){
				try{
					if(Estados.status === 200){
						state.myDataEstado = Estados.data.data;
						state.filtroEstado = state.myDataEstado.length > 0 ? state.myDataEstado[0].StateId : '';
						state.apiState = ENUM.LOADED;
					}else{
						console.log(Estados);
						state.apiState = ENUM.ERROR; 
						}		
					}catch(error){
						console.log(error);
						state.apiState = ENUM.ERROR; 
					}
			}else{
				state.myDataEstado=[];
				state.filtroEstado='';
				state.myDataMunicipio=[];
			}
        },
        getEstadolistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Registrar o actualizar un estado
		postregisterEstadoRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		postregisterEstadoSuccess (state, { IdPais }) {
			try{
				state.filtroPais = '';
				state.filtroPais = IdPais;
				state.modalEstado = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		postregisterEstadoFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
		
		//Listar Municipios
		getMunicipiolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getMunicipiolistSuccess(state, { Municipios }) {
            if((state.filtroPais!='') && (state.filtroEstado!='')){
				try{
					if(Municipios.status === 200){
						state.myDataMunicipio = Municipios.data.data;
						state.filtroMunicipio = state.myDataMunicipio.length > 0 ? state.myDataMunicipio[0].MunicipalityId : '';
						state.apiState = ENUM.LOADED;
					}else{
						console.log(Municipios);
						state.apiState = ENUM.ERROR; 
					}
				}catch(error){
					console.log(error);
					state.apiState = ENUM.ERROR; 
				}
            }else{
				state.myDataMunicipio=[];
				state.filtroPais='';
				state.filtroEstado='';
			}
        },
        getMunicipiolistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Registrar o actualizar un municipio
		postregisterMunicipiosRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		postregisterMunicipiosSuccess (state, { IdPais,IdEstado }) {
			try{
				state.filtroPais = IdPais;
				state.filtroEstado = '';
				state.filtroEstado = IdEstado;
				state.modalMunicipio = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
            
			
		},
		postregisterMunicipioFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
		
		//Listar Parroquias
		getParroquialistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getParroquialistSuccess(state, { Parroquias }) {
			if((state.filtroPais!='') && (state.filtroEstado!='') && (state.filtroMunicipio!='')){
				try{
					if(Parroquias.status === 200){
						state.myDataParroquia = Parroquias.data.data;
						state.apiState = ENUM.LOADED;
					}else{
						console.log(Parroquias);
						state.apiState = ENUM.ERROR; 
					}
				}catch(error){
					console.log(error);
					state.apiState = ENUM.ERROR; 
				}
			}else{
				state.myDataMunicipio=[];
				state.myDataParroquia=[];
				state.filtroPais='';
				state.filtroEstado='';
				state.filtroMunicipio='';
			}

        },
        getParroquialistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Registrar o actualizar un municipio
		postregisterParroquiaRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		postregisterParroquiaSuccess (state, { IdPais,IdEstado,IdMunicipio }) {
			try{
				state.filtroPais = IdPais;
				state.filtroEstado = IdEstado;
				state.filtroMunicipio = '';
				state.filtroMunicipio = IdMunicipio;

				state.modalParroquia = false;
				state.apiStateForm = ENUM.LOADED
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		postregisterParroquiaFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
        },
    }
}