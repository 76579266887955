import { required, maxLength, decimal, minValue, maxValue, } from "vuelidate/lib/validators";
import { onlyAlphanumericEspecial, onlyHsCode, especiales, nameLength ,onlyNumber } from '@/_validations/validacionEspeciales';

const isValidFile = (value) => {
    if(value === '' || value === null || value === undefined){
        return true;
    }
    if(!value){
        return false;
    }
    if(value.type)
      return value.type.startsWith('image');
    else
        return (value != '');
};

export default () => {
    return {
        HeadingId: { required },
        CommodityNameEs: { required, onlyAlphanumericEspecial, maxLength: maxLength(250), nameLength },
        CommodityNameEn: { required, onlyAlphanumericEspecial, maxLength: maxLength(250),  nameLength },
        HsCode: { onlyHsCode, maxLength:maxLength(10) },
        CommodityDs: { especiales, maxLength: maxLength(250) },
        Density: { decimal, minValue:minValue(0), maxValue:maxValue(99999999.99), maxLength: maxLength(11), onlyNumber },
        Status: { },
        CommodityRoute: { isValidFile },
    }
}

