import ENUM from './enum';
import service from '@/_services/service';
import {DateFormater,validURL} from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

export const contenedores = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
		id:0,
		tabIndex:'tab1',
        messageError:'',
        filtroCargo:'',
        filtroView:'',
        filtroSize:'',
        modalName: '',
        groupRoute: '',
        MetadataJson:[],
        modalCargos:false,
        modalVista:false,
        modalCode:false,
        modalSize:false,
        modalDetalle:false,
        modalComponent:false,
        modalAccesorios:false,
        modalComponents:false,
        modalElementos:false,
        modalStatus:false,
        modalCondicion:false,

        myDataGrupo:[],
        myDataSize:[],
        myDataDetail:[],
        myDataView:[],
        myDataElement:[],
        myDataAccesory:[],
        myDataComponent:[],
        myDataStatus:[],
        myDataCondition:[],
        myDataIsoCode:[]

       
    },
    getters: {
        myDataGrupotable: state => {
            let chart = [];
            if(state.myDataGrupo.length !== 0){
                state.myDataGrupo.sort(function (a, b) {
                    if (a.FgActTpCargo < b.FgActTpCargo) return 1;
                    else if (a.FgActTpCargo > b.FgActTpCargo) return -1;
                    else {
                        if (a.TpCargoName > b.TpCargoName) {
                            return 1;
                        }
                        if (a.TpCargoName < b.TpCargoName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataGrupo.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    Route: (validURL(item.Route) || item.Route === null || item.Route === '') ? item.Route : `${DataStorage.getConnection()}${item.Route.replace('public/','', null, 'i')}`,
                    status: item.FgActTpCargo,
                    _classes: (( item.FgActTpCargo === false ) ? 'table-danger' : ((state.filtroCargo !== '' && state.filtroCargo === item.TpCargoId) ? 'table-primary' : '' ))
                }));
            }
            return chart;
        },
        myDataSizetable: state => {
            let chart = [];
            if(state.myDataSize.length !== 0){
                state.myDataSize.sort(function (a, b) {
                    if (a.FgActSize < b.FgActSize) return 1;
                    else if (a.FgActSize > b.FgActSize) return -1;
                    else {
                        return a.Size - b.Size;
                    }
                })
                return state.myDataSize.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActSize,
                    _classes: ( item.FgActSize ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataDetailtable: state => {
            let chart = [];
            if(state.myDataDetail.length !== 0){
                state.myDataDetail.sort(function (a, b) {
                    if (a.FgActTpCargoDetail < b.FgActTpCargoDetail) return 1;
                    else if (a.FgActTpCargoDetail > b.FgActTpCargoDetail) return -1;
                    else {
                        if (a.TpCargoDetailName > b.TpCargoDetailName) {
                            return 1;
                        }
                        if (a.TpCargoDetailName < b.TpCargoDetailName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataDetail.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    TpCargoDetailCode: item.TpCargoDetailCode,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpCargoDetail,
                    _classes: ( item.FgActTpCargoDetail ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataViewtable: state => {
            let chart = [];
            if(state.myDataView.length !== 0){
                state.myDataView.sort(function (a, b) {
                    if (a.FgActTpCargoView < b.FgActTpCargoView) return 1;
                    else if (a.FgActTpCargoView > b.FgActTpCargoView) return -1;
                    else {
                        if (a.TpCargoViewName > b.TpCargoViewName) {
                            return 1;
                        }
                        if (a.TpCargoViewName < b.TpCargoViewName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataView.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpCargoView,
                    _classes: ( item.FgActTpCargoView ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataAccesorytable: state => {
            let chart = [];
            if(state.myDataAccesory.length !== 0){
                state.myDataAccesory.sort(function (a, b) {
                    if (a.FgActCargoAccesory < b.FgActCargoAccesory) return 1;
                    else if (a.FgActCargoAccesory > b.FgActCargoAccesory) return -1;
                    else {
                        if (a.TpCargoAccesoryNameEs > b.TpCargoAccesoryNameEs) {
                            return 1;
                        }
                        if (a.TpCargoAccesoryNameEn > b.TpCargoAccesoryNameEn) {
                            return 1;
                        }
                        if (a.TpCargoAccesoryNameEs < b.TpCargoAccesoryNameEs) {
                            return -1;
                        }
                        if (a.TpCargoAccesoryNameEn < b.TpCargoAccesoryNameEn) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataAccesory.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActCargoAccesory,
                    _classes: ( item.FgActCargoAccesory ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataElementtable: state => {
            let chart = [];
            if(state.myDataElement.length !== 0){
                state.myDataElement.sort(function (a, b) {
                    if (a.FgActTpCargoElement < b.FgActTpCargoElement) return 1;
                    else if (a.FgActTpCargoElement > b.FgActTpCargoElement) return -1;
                    else {
                        if (a.TpCargoElementName > b.TpCargoElementName) {
                            return 1;
                        }
                        if (a.TpCargoElementName < b.TpCargoElementName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataElement.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpCargoElement,
                    _classes: ( item.FgActTpCargoElement ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataComponenttable: state => {
            let chart = [];
            if(state.myDataComponent.length !== 0){
                state.myDataComponent.sort(function (a, b) {
                    if (a.FgActCargoAccesory < b.FgActCargoAccesory) return 1;
                    else if (a.FgActCargoAccesory > b.FgActCargoAccesory) return -1;
                    else {
                        if (a.TpCargoAccesoryNameEs > b.TpCargoAccesoryNameEs) {
                            return 1;
                        }
                        if (a.TpCargoAccesoryNameEn > b.TpCargoAccesoryNameEn) {
                            return 1;
                        }
                        if (a.TpCargoAccesoryNameEs < b.TpCargoAccesoryNameEs) {
                            return -1;
                        }
                        if (a.TpCargoAccesoryNameEn < b.TpCargoAccesoryNameEn) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataComponent.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActCargoAccesory,
                    _classes: ( item.FgActCargoAccesory ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataStatustable: state => {
            let chart = [];
            if(state.myDataStatus.length !== 0){
                state.myDataStatus.sort(function (a, b) {
                    if (a.FgActTpCargoStatus < b.FgActTpCargoStatus) return 1;
                    else if (a.FgActTpCargoStatus > b.FgActTpCargoStatus) return -1;
                    else {
                        if (a.TpCargoStatusName > b.TpCargoStatusName) {
                            return 1;
                        }
                        if (a.TpCargoStatusName < b.TpCargoStatusName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataStatus.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpCargoStatus,
                    _classes: ( item.FgActTpCargoStatus ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataConditiontable: state => {
            let chart = [];
            if(state.myDataCondition.length !== 0){
                state.myDataCondition.sort(function (a, b) {
                    if (a.FgActTpCargoCondition < b.FgActTpCargoCondition) return 1;
                    else if (a.FgActTpCargoCondition > b.FgActTpCargoCondition) return -1;
                    else {
                        if (a.TpCargoConditionName > b.TpCargoConditionName) {
                            return 1;
                        }
                        if (a.TpCargoConditionName < b.TpCargoConditionName) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataCondition.map((item) => Object.assign({}, item, {
					RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActTpCargoCondition,
                    _classes: ( item.FgActTpCargoCondition ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataIsoCodetable: state => {
            let chart = [];
            if(state.myDataIsoCode.length !== 0){
                state.myDataIsoCode.sort(function (a, b) {
                    if (a.FgActMetadata < b.FgActMetadata) return 1;
                    else if (a.FgActMetadata > b.FgActMetadata) return -1;
                    else {
                        if (a.TpCargoDetailCode > b.TpCargoDetailCode) {
                            return 1;
                        }
                        if (a.TpCargoDetailCode < b.TpCargoDetailCode) {
                            return -1;
                        }
                        return 0;
                    }
				})
                return state.myDataIsoCode.map((item) => Object.assign({}, item, {
					//RowNumber: item.Nro,
                    //UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    Status: item.Status,
                    _classes: ( item.FgActMetadata ) ? '' : 'table-danger'
                }));
            }
            return chart;
        }
    },
    actions: {
        getGrupolist({commit}){
            commit('getGrupolistRequest');
            service.ejecutar('GET', 'TpCargo-list', { Filter: 'ALL' }, '')
            .then(
                response => commit('getGrupolistSuccess', { response }),
                error => {
                    commit('messageMutation', error)
                    commit('getGrupolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataGrupo({ commit,dispatch }, { TpCargoId,TpCargoName,TpCargoDs,TpCargoCode,Route,DocumentJson,FgAccesory,FgActTpCargo,Image_original }){
            commit('dataGrupoRequest');
			try {
				let method = 'POST';
                let valores = {};
				let link = 'TpCargo-insert';

				if(TpCargoId === 0){
                    valores = {
                        TpCargoName:TpCargoName,
                        TpCargoDs:TpCargoDs,
                        TpCargoCode:TpCargoCode,
                        FgAccesory:FgAccesory,
                    }

                    if(DocumentJson.length !== 0){
                        let chart = [];
                        DocumentJson.map(function(e) {
                            if(e.FgActMetadata){
                                chart.push({
                                    StandardDocumentId:e.StandardDocumentId,
                                    MetadataDesc:e.MetadataDesc,
                                })
                            }
                        });
                        valores.DocumentJson = chart;
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargo-update';
                    valores = {
						TpCargoId:TpCargoId,
                        TpCargoName:TpCargoName,
                        TpCargoDs:TpCargoDs,
                        TpCargoCode:TpCargoCode,
                        FgAccesory:FgAccesory ? 1 : 0,
						Status: FgActTpCargo ? 1 : 0
                    }

                    if(DocumentJson.length !== 0){
                        let chart = [];
                        DocumentJson.map(function(e) {
                            if(e.MetadataId === ''){
                                if(e.FgActMetadata){
                                    chart.push({
                                        MetadataId:e.MetadataId,
                                        StandardDocumentId:e.StandardDocumentId,
                                        MetadataDesc:e.MetadataDesc,
                                        Status: e.FgActMetadata ? 1 : 0
                                    })
                                }
                            }else{
                                chart.push({
                                    MetadataId:e.MetadataId,
                                    StandardDocumentId:e.StandardDocumentId,
                                    MetadataDesc:e.MetadataDesc,
                                    Status: e.FgActMetadata ? 1 : 0
                                })
                            }
                        });
                        valores.DocumentJson = chart;
                    }
                }
				(async () => {
					try{
						if(typeof Route !== 'string'  && Route !== null && Route !== undefined && Route !== ''){
							const response = await service.file(link,Route,Image_original);
							const info = response.data.data;
							valores.Route = info.files[0].path;
						}else{
                            valores.Route = Route;
                        }

						service.ejecutar(method, link, valores, { root: 'TpCargoJson' })
						.then(
							dataPaises => {
								if(dataPaises.data.status === 200){
									const information = dataPaises.data.data;
									if(information[0].id === ""){
										commit('messageMutation', information[0].Response)
										commit('dataGrupoFailure', information[0].Response)
									}else{
										commit('dataGrupoSuccess', { information })
										dispatch('contenedores/getGrupolist', '', { root: true });
									}
								}else{
									commit('dataGrupoFailure', dataPaises.data);
								}
							},
							error => {
								commit('messageMutation', error)
								commit('dataGrupoFailure', error)
								//dispatch('alert/error', error, { root: true });
							}
						);
					} catch (error) {
						commit('messageMutation', error)
						commit('dataGrupoFailure', error)
					}
				})();
			} catch (error) {
				commit('messageMutation', error)
				commit('dataGrupoFailure', error)
			}
        },  
        getDetaillist({commit,dispatch},{ idCargo }){
            commit('getDetaillistRequest');

            service.ejecutar('GET', 'Size-list', { Filter: 'ALL' }, '')
            .then(
                sizes => {
                    service.ejecutar('GET', 'TpCargoDetail-list', { 
                        TpCargoId: (idCargo === undefined || idCargo === null) ? '': idCargo
                        ,Filter: 'ALL' }, ''
                    ).then(
                        response => {
                            commit('getDetaillistSuccess', { response,sizes });
                            dispatch('contenedores/getGrupolist', '', { root: true });
                        },
                        error => {
                            commit('messageMutation', error)
                            commit('getDetaillistFailure', error)
                            //dispatch('alert/error', error, { root: true });
                        }
                    );
                },
                error => {
                    commit('messageMutation', error)
                    commit('getDetaillistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataDetails ({ commit }, { TpCargoDetailId,TpCargoId,SizeId,TpCargoDetailName,TpCargoDetailCode,OutsideLength,OutsideWidth,
            OutsideHeigth,OutsideVolumen,InsideLength,InsideWidth,InsideHeigth,InsideVolumen,WeigthEmpty,MaxLoad,
            DocumentJson,MaxGrossWeigth, color,FgSpecial,FgActTpCargoDetail, FgApplyOversize, FgApplyRefrigeration, FgApplySeal }) {
			commit('dataDetailsRequest');
			try{
				let method = 'POST';
                let valores = {};
                let link = 'TpCargoDetail-insert';
                if(TpCargoDetailId === 0){
                    valores = {
                        TpCargoId:TpCargoId, 
                        SizeId:SizeId, 
                        TpCargoDetailName:TpCargoDetailName, 
                        TpCargoDetailCode:TpCargoDetailCode, 
                        OutsideLength:parseFloat(OutsideLength), 
                        OutsideWidth:parseFloat(OutsideWidth), 
                        OutsideHeigth:parseFloat(OutsideHeigth), 
                        OutsideVolumen:parseFloat(OutsideVolumen), 
                        InsideLength:parseFloat(InsideLength), 
                        InsideWidth:parseFloat(InsideWidth), 
                        InsideHeigth:parseFloat(InsideHeigth), 
                        InsideVolumen:parseFloat(InsideVolumen), 
                        WeigthEmpty:parseFloat(WeigthEmpty), 
                        MaxLoad:parseFloat(MaxLoad), 
                        MaxGrossWeigth:parseFloat(MaxGrossWeigth),
                        Color:color,
                        FgApplyOversize: FgApplyOversize ? 1 : 0,
                        FgApplyRefrigeration: FgApplyRefrigeration ? 1 : 0,
                        FgApplySeal: FgApplySeal ? 1 : 0,
                        FgSpecial:FgSpecial ? 1 : 0
                    }

                    if(DocumentJson.length !== 0){
                        let chart = [];
                        DocumentJson.map(function(e) {
                            if(e.FgActMetadata){
                                chart.push({
                                    StandardDocumentId:e.StandardDocumentId,
                                    MetadataDesc:e.MetadataDesc,
                                })
                            }
                        });
                        valores.DocumentJson = chart;
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoDetail-update';
                    valores = {
                        TpCargoDetailId:TpCargoDetailId,
                        TpCargoId:TpCargoId, 
                        SizeId:SizeId, 
                        TpCargoDetailName:TpCargoDetailName, 
                        TpCargoDetailCode:TpCargoDetailCode, 
                        OutsideLength:parseFloat(OutsideLength), 
                        OutsideWidth:parseFloat(OutsideWidth), 
                        OutsideHeigth:parseFloat(OutsideHeigth), 
                        OutsideVolumen:parseFloat(OutsideVolumen), 
                        InsideLength:parseFloat(InsideLength), 
                        InsideWidth:parseFloat(InsideWidth), 
                        InsideHeigth:parseFloat(InsideHeigth), 
                        InsideVolumen:parseFloat(InsideVolumen), 
                        WeigthEmpty:parseFloat(WeigthEmpty), 
                        MaxLoad:parseFloat(MaxLoad), 
                        MaxGrossWeigth:parseFloat(MaxGrossWeigth),
                        Color:color,
                        FgApplyOversize: FgApplyOversize ? 1 : 0,
                        FgApplyRefrigeration: FgApplyRefrigeration ? 1 : 0,
                        FgApplySeal: FgApplySeal ? 1 : 0,
                        FgSpecial: FgSpecial ? 1 : 0,
                        Status: FgActTpCargoDetail ? 1 : 0,
                    }
                    
                    if(DocumentJson.length !== 0){
                        let chart = [];
                        DocumentJson.map(function(e) {
                            if(e.MetadataId === ''){
                                if(e.FgActMetadata){
                                    chart.push({
                                        MetadataId:e.MetadataId,
                                        StandardDocumentId:e.StandardDocumentId,
                                        MetadataDesc:e.MetadataDesc,
                                        Status: e.FgActMetadata ? 1 : 0
                                    })
                                }
                            }else{
                                chart.push({
                                    MetadataId:e.MetadataId,
                                    StandardDocumentId:e.StandardDocumentId,
                                    MetadataDesc:e.MetadataDesc,
                                    Status: e.FgActMetadata ? 1 : 0
                                })
                            }
                        });
                        valores.DocumentJson = chart;
                    }
                }
                service.ejecutar(method, link, valores, { root: 'TpCargoDetailJson' })
				.then(
					datos => {
						if(datos.data.status === 200){
							const information = datos.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('dataDetailsFailure', information[0].Response)
							}else{
                                commit('dataDetailsSuccess', { information })
							}
						}else{
							commit('dataDetailsFailure', datos.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('dataDetailsFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('dataDetailsFailure', error)
			}
            
        },
        getSizelist({commit/*,dispatch*/}){
            commit('getSizelistRequest');
            service.ejecutar('GET', 'Size-list', { Filter: 'ALL' }, '')
            .then(
                response => {
                    commit('getSizelistSuccess', { response });
                    //dispatch('contenedores/getGrupolist', '', { root: true });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getSizelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataSize ({ commit, dispatch }, { TpCargoSizeId,TpCargoSizeName,FgActCargoSize,UnitMeasureIdSize }) {
			commit('dataSizeRequest');
			try{
				let method = 'POST';
                let valores = {};
                let link = 'Size-insert';
                if(TpCargoSizeId === 0){
                    valores = {
                        UnitMeasureIdSize:UnitMeasureIdSize,
						Size:TpCargoSizeName,
                    }
                }else{
                    method = 'PUT';
                    link = 'Size-update';
                    valores = {
                        SizeId:TpCargoSizeId,
						UnitMeasureIdSize:UnitMeasureIdSize,
                        Size:TpCargoSizeName,
                        Status: FgActCargoSize ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'SizeJson' })
				.then(
					datos => {
						if(datos.data.status === 200){
							const information = datos.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('dataSizeFailure', information[0].Response)
							}else{
                                commit('dataSizeSuccess', { information })
                                dispatch('contenedores/getSizelist', '', { root: true });
							}
						}else{
							commit('dataSizeFailure', datos.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('dataSizeFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('dataSizeFailure', error)
			}
            
        },
        getViewlist({commit,dispatch},{ idView }){
            commit('getViewlistRequest');
            service.ejecutar('GET', 'TpCargoView-list', { TpCargoId: (idView === undefined || idView === null) ? '': idView,Filter: 'ALL' }, '')
            .then(
                response => {
                    commit('getViewlistSuccess', { response });
                    dispatch('contenedores/getGrupolist', '', { root: true });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getViewlistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataView ({ commit }, { TpCargoViewId, TpCargoId, TpCargoViewName, FgActTpCargoView }) {
            commit('dataViewRequest');
            try{
                let method = 'POST';
                let valores = {};
                let link = 'TpCargoView-insert';
                if(TpCargoViewId === 0){
                    valores = {
                        TpCargoId:TpCargoId,
                        TpCargoViewName:TpCargoViewName,
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoView-update';
                    valores = {
                        TpCargoViewId:TpCargoViewId,
                        TpCargoId:TpCargoId,
                        TpCargoViewName:TpCargoViewName,
                        Status: FgActTpCargoView ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpCargoViewJson' })
                .then(
                    datos => {
                        if(datos.data.status === 200){
                            const information = datos.data.data;
                            if(information[0].id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('dataViewFailure', information[0].Response)
                            }else{
                                commit('dataViewSuccess', { information })
                            }
                        }else{
                            commit('dataViewFailure', datos.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('dataViewFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('dataViewFailure', error)
            }
            
        },
        getElementlist({commit,dispatch},{ idCargo,idView }){
            commit('getElementlistRequest');

            service.ejecutar('GET', 'TpCargoView-list', { TpCargoId: '',Filter: 'ALL' }, '')
            .then(
                views => {
                    service.ejecutar('GET', 'TpCargoViewElement-list', { 
                        TpCargoViewId: (idView === undefined || idView === null) ? '': idView,
                        TpCargoId: (idCargo === undefined || idCargo === null) ? '': idCargo
                        ,Filter: 'ALL' }, ''
                    ).then(
                        response => {
                            commit('getElementlistSuccess', { response,views });
                            dispatch('contenedores/getGrupolist', '', { root: true });
                        },
                        error => {
                            commit('messageMutation', error)
                            commit('getElementlistFailure', error)
                            //dispatch('alert/error', error, { root: true });
                        }
                    );
                },
                error => {
                    commit('messageMutation', error)
                    commit('getElementlistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataElement ({ commit }, { TpCargoElementId, TpCargoViewId, TpCargoId,TpCargoElementName,FgActCargoElement }) {
            commit('dataElementRequest');
            try{
                let method = 'POST';
                let valores = {};
                let link = 'TpCargoViewElement-insert';
                if(TpCargoElementId === 0){
                    valores = {
                        TpCargoViewId:TpCargoViewId,
                        TpCargoElementName:TpCargoElementName,
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoViewElement-update';
                    valores = {
                        TpCargoElementId:TpCargoElementId,
                        TpCargoElementName:TpCargoElementName,
                        TpCargoViewId:TpCargoViewId,
                        Status: FgActCargoElement ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpCargoViewElementJson' })
                .then(
                    datos => {
                        if(datos.data.status === 200){
                            const information = datos.data.data;
                            if(information[0].id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('dataElementFailure', information[0].Response)
                            }else{
                                commit('dataElementSuccess', { information,TpCargoId })
                            }
                        }else{
                            commit('dataElementFailure', datos.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('dataElementFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('dataElementFailure', error)
            }
        },
        getAccesorylist({commit,dispatch},{ idCargo }){
            commit('getAccesorylistRequest');
            service.ejecutar('GET', 'TpCargoAccesory-list', { TpCargoId: (idCargo === undefined || idCargo === null) ? '': idCargo,Filter: 'ALL' }, '')
            .then(
                response => {
                    commit('getAccesorylistSuccess', { response });
                    dispatch('contenedores/getGrupolist', '', { root: true });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getAccesorylistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        getComponentlist({commit,dispatch},{ idCargo }){
            commit('getComponentlistRequest');
            service.ejecutar('GET', 'TpCargoComponent-list', { TpCargoId: (idCargo === undefined || idCargo === null) ? '': idCargo,Filter: 'ALL' }, '')
            .then(
                response => {
                    commit('getComponentlistSuccess', { response });
                    dispatch('contenedores/getGrupolist', '', { root: true });
                },
                error => {
                    commit('messageMutation', error)
                    commit('getComponentlistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        dataAccesory ({ commit }, { TpCargoAccesoryId, TpCargoId,TpCargoAccesoryNameEs,TpCargoAccesoryNameEn,FgActCargoAccesory,FgComponent }) {
            commit('dataAccesoryRequest');
            try{
                let method = 'POST';
                let valores = {};
                let link = 'TpCargoAccesory-insert';
                if(TpCargoAccesoryId === 0){
                    valores = {
                        TpCargoId:TpCargoId,
                        TpCargoAccesoryNameEs:TpCargoAccesoryNameEs,
                        TpCargoAccesoryNameEn:TpCargoAccesoryNameEn,
                        FgComponent:FgComponent,
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoAccesory-update';
                    valores = {
                        TpCargoAccesoryId:TpCargoAccesoryId,
                        TpCargoId:TpCargoId,
                        TpCargoAccesoryNameEs:TpCargoAccesoryNameEs,
                        TpCargoAccesoryNameEn:TpCargoAccesoryNameEn,
                        FgComponent:FgComponent,
                        Status: FgActCargoAccesory ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpCargoAccesoryJson' })
                .then(
                    datos => {
                        if(datos.data.status === 200){
                            const information = datos.data.data;
                            if(information[0].id === ""){
                                commit('messageMutation', information[0].Response)
                                commit('dataAccesoryFailure', information[0].Response)
                            }else{
                                commit('dataAccesorySuccess', { information })
                            }
                        }else{
                            commit('dataAccesoryFailure', datos.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('dataAccesoryFailure', error)
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                commit('messageMutation', error)
                commit('dataAccesoryFailure', error)
            }
            
        },
        getStatuslist({commit}){
			commit('getStatuslistRequest');
			service.ejecutar('GET', 'TpCargoStatus-list', { Filter: 'ALL' }, '')
			.then(
				Response => commit('getStatuslistSuccess', { Response }),
				error => {
					commit('messageMutation', error)
					commit('getStatuslistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },
        dataStatus ({ commit, dispatch }, { TpCargoStatusId,TpCargoStatusName,FgActTpCargoStatus }) {
			commit('dataStatusRequest');
			try{
				let method = 'POST';
                let valores = {};
                let link = 'TpCargoStatus-insert';
                if(TpCargoStatusId === 0){
                    valores = {
						TpCargoStatusName:TpCargoStatusName,
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoStatus-update';
                    valores = {
						TpCargoStatusId:TpCargoStatusId,
                        TpCargoStatusName:TpCargoStatusName,
                        Status: FgActTpCargoStatus ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpCargoStatusJson' })
				.then(
					datos => {
						if(datos.data.status === 200){
							const information = datos.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('dataStatusFailure', information[0].Response)
							}else{
                                commit('dataStatusSuccess', { information })
                                dispatch('contenedores/getStatuslist', '', { root: true });
							}
						}else{
							commit('dataStatusFailure', datos.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('dataStatusFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('dataStatusFailure', error)
			}
            
        },
        getConditionlist({commit}){
			commit('getConditionlistRequest');
			service.ejecutar('GET', 'TpCargoCondition-list', { Filter: 'ALL' }, '')
			.then(
				Response => commit('getConditionlistSuccess', { Response }),
				error => {
					commit('messageMutation', error)
					commit('getConditionlistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },
        dataCondition ({ commit,dispatch }, { TpCargoConditionId,TpCargoStatusId,TpCargoConditionName,TpCargoConditionCode,FgActTpCargoCondition }) {
			commit('dataConditionRequest');
			try{
				let method = 'POST';
                let valores = {};
                let link = 'TpCargoCondition-insert';
                if(TpCargoConditionId === 0){
                    valores = {
                        TpCargoStatusId:TpCargoStatusId,
                        TpCargoConditionName:TpCargoConditionName,
                        TpCargoConditionCode:TpCargoConditionCode,
                    }
                }else{
                    method = 'PUT';
                    link = 'TpCargoCondition-update';
                    valores = {
                        TpCargoConditionId:TpCargoConditionId,
						TpCargoStatusId:TpCargoStatusId,
                        TpCargoConditionName:TpCargoConditionName,
                        TpCargoConditionCode:TpCargoConditionCode,
                        Status: FgActTpCargoCondition ? 1 : 0,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'TpCargoConditionJson' })
				.then(
					datos => {
						if(datos.data.status === 200){
							const information = datos.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('dataConditionFailure', information[0].Response)
							}else{
                                commit('dataConditionSuccess', { information })
                                dispatch('contenedores/getConditionlist', '', { root: true });
							}
						}else{
							commit('dataConditionFailure', datos.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('dataConditionFailure', error)
						//dispatch('alert/error', error, { root: true });
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('dataConditionFailure', error)
			}
        },

        getIsoCodelist({commit}){
			commit('getIsoCodelistRequest');
			service.ejecutar('GET', 'Metadata-list')
			.then(
				Response => commit('getIsoCodelistSuccess', { Response }),
                //console.log('el response ---', Response),
				error => {
					commit('messageMutation', error)
					commit('getIsoCodelistFailure', error)
					//dispatch('alert/error', error, { root: true });
				}
			);
        },

    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
		},
		messageMutation (state, value ) {
            state.messageError = value;
        },

        //Listar TODOS
        getGrupolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getGrupolistSuccess(state, { response }) {
            try{
				if(response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataGrupo = response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getGrupolistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        dataGrupoRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		dataGrupoSuccess(state, { information }) {
			try{
                state.messageError = information[0].Response;
                state.modalCargos = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.messageError = error;
				state.apiStateForm = ENUM.ERROR;
			}
		},
		dataGrupoFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
        },

        getSizelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getSizelistSuccess(state, { response }) {
            try{
				if(response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataSize = response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getSizelistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        dataSizeRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		dataSizeSuccess(state, { information }) {
			try{
                state.messageError = information[0].Response;
                state.modalSize = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.messageError = error;
				state.apiStateForm = ENUM.ERROR;
			}
		},
		dataSizeFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
        },
        
        getDetaillistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getDetaillistSuccess(state, { response,sizes }) {
            try{
                if(response.status === 200 && sizes.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataDetail = response.data.data;
                    state.myDataSize = sizes.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(response);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getDetaillistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        dataDetailsRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        dataDetailsSuccess(state, { information }) {
            try{
                state.messageError = information[0].Response;
                state.filtroCargo = information[0].TpCargoId;
                state.filtroSize = information[0].SizeId;
                state.modalDetalle = false;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.messageError = error;
                state.apiStateForm = ENUM.ERROR;
            }
        },
        dataDetailsFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },

        getViewlistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getViewlistSuccess(state, { response }) {
            try{
				if(response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataView = response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getViewlistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        dataViewRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        dataViewSuccess(state, { information }) {
            try{
                state.messageError = information[0].Response;
                state.filtroCargo = information[0].TpCargoId;
                state.modalVista = false;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.messageError = error;
                state.apiStateForm = ENUM.ERROR;
            }
        },
        dataViewFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
        getElementlistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getElementlistSuccess(state, { response,views }) {
            try{
                if(response.status === 200 && views.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataElement = response.data.data;
                    state.myDataView = views.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(response);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getElementlistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        dataElementRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        dataElementSuccess(state, { information }) {
            try{
                state.messageError = information[0].Response;
                state.filtroCargo = information[0].TpCargoId;
                state.filtroView = information[0].TpCargoViewId;
                state.modalElementos = false;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.messageError = error;
                state.apiStateForm = ENUM.ERROR;
            }
        },
        dataElementFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
        getAccesorylistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getAccesorylistSuccess(state, { response }) {
            try{
                if(response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataAccesory = response.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(response);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getAccesorylistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        getComponentlistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getComponentlistSuccess(state, { response }) {
            try{
                if(response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataComponent = response.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(response);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getComponentlistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        dataAccesoryRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        dataAccesorySuccess(state, { information }) {
            try{
                state.messageError = information[0].Response;
                state.filtroCargo = information[0].TpCargoId;
                if(information[0].FgComponent === false){
                    state.modalAccesorios = false;
                }else if(information[0].FgComponent === true){
                    state.modalComponent = false;
                }
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(error);
                state.messageError = error;
                state.apiStateForm = ENUM.ERROR;
            }
        },
        dataAccesoryFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },

        getStatuslistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getStatuslistSuccess(state, { Response }) {
            try{
				if(Response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataStatus = Response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(Response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getStatuslistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        dataStatusRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		dataStatusSuccess(state, { information }) {
			try{
                state.messageError = information[0].Response;
                state.modalStatus = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.messageError = error;
				state.apiStateForm = ENUM.ERROR;
			}
		},
		dataStatusFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},

        getConditionlistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getConditionlistSuccess(state, { Response }) {
            try{
				if(Response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataCondition = Response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(Response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getConditionlistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },


        getIsoCodelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getIsoCodelistSuccess(state, { Response }) {
            try{
				if(Response.status === 200){
                    //console.log(JSON.parse(Paises.data.data[0].Json));
                    state.myDataIsoCode = Response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					console.log(Response);
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
				console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getIsoCodelistFailure(state, error) {
			console.log(error);
            state.apiState = ENUM.ERROR; 
        },



        dataConditionRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		dataConditionSuccess(state, { information }) {
			try{
                state.messageError = information[0].Response;
                state.modalCondicion = false;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.messageError = error;
				state.apiStateForm = ENUM.ERROR;
			}
		},
		dataConditionFailure(state, error) {
			console.log(error);
			state.apiStateForm = ENUM.ERROR;
		},
    }
}