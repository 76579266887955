<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol sm="6" class="d-flex align-items-center">
          <small>BAY PLAN</small>
        </CCol>
        <CCol sm="6" class="d-flex align-items-center justify-content-end">
          <CButton
            shape="square"
            color="excel"
            size="sm"
            class="ml-2"
            @click.prevent="printPdf"
          >
            <div class="d-flex align-items-center">
              <CIcon name="cil-cloud-download"/>
              <span class="ml-1">{{$t('label.download')}}</span>
            </div>
          </CButton>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CCard ref="content">
            <CCardBody>
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="toDeckHasData">
                  <bay-grid
                    :col="colNum(false)"
                    :row="rowNum(false)"
                    :crossLine="isCrossLineTo"
                    :items="positionsToDeck"
                    showStatus
                    :showDashedNumbers="false"
                    :maxCol="responsiveCol(isCrossLineUnder)"
                  />
                </CCol>
              </CRow>
              <hr class="dash">
              <CRow align-horizontal="center">
                <CCol sm="8" v-if="underDeckHasData">
                  <bay-grid
                  :col="colNum(true)"
                  :row="rowNum(true)"
                  :crossLine="isCrossLineUnder"
                  :items="positionsUnderDeck"
                  showStatus
                  showNumbersBottom
                  :showDashedNumbers="false"
                  :maxCol="responsiveCol(isCrossLineTo)"
                />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex';
import BayGrid from "./bay-grid";
import { BayMixin } from '@/_mixins/buque';

//Data
function data() {
  return {
    isLoading: false,
    toDeckPos: [],
    underDeckPos: [],
  }
}

//Created:
function created() {
  this.toDeckPos = [...this.positionsToDeck];
  this.underDeckPos = [...this.positionsUnderDeck];
}

//Metodos: 
function printPdf() {
  if(!this.currentBay || (Object.keys(this.buque).length == 0 && !this.buque.VesselId))
    return;

  let pageInfo = {
    currentBay: this.currentBay,
    vesselId: this.buque.VesselId,
    vesselRoute: this.buque.VesselRoute,
    bayCode: this.currentBay.BayCode,
    positionsToDeck: this.positionsToDeck,
    positionsUnderDeck: this.positionsUnderDeck,
    type: 'bay-plan',
  };

  localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfBayPlan'});
  window.open(routeData.href, '_blank');
}

//Computeds: 
function docOptions() {
  return {
    name: 'BAYPLAN',
    title: `BAYPLAN ${this.currentBay.BayCode}`
  }
}

export default {
  name: 'bay-plan',
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  mixins: [BayMixin],
  components: {
    BayGrid
  },
  data,
  created,
  methods: {
    printPdf,
  },
  computed: {
    docOptions,
    ...mapState({
      currentBay: state => state.baygrid.bay,
      positionsToDeck: state => state.baygrid.positionsToDeck,
      positionsUnderDeck: state => state.baygrid.positionsUnderDeck
    })
  },
  watch: {
    positionsToDeck: function(newPos) {
      this.toDeckPos = [...newPos];
    },
    positionsUnderDeck: function(newPos) {
      this.underDeckPos = [...newPos];
    }
  }
}
</script>

<style scoped>
.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
</style>