<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="xl"
      :show.sync="modalActive"
    >
      <CTabs
        style="width: 100%"
        variant="tabs"
        :active-tab.sync="activeTab"
        :fade="false"
      >
        <CTab :title="$t('label.generalInfo')">
          <CRow class="mt-3">
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.activity')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="activityOptions"
                :disabled="isEmpty(activities)"
                :value.sync="$v.stowageBillOfLoading.PortActivityId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.PortActivityId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.PortActivityId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="getClientLabel"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="clientOptions"
                :disabled="isEmpty(clients)"
                :value.sync="$v.stowageBillOfLoading.ClientTpId.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.ClientTpId)"
                :is-valid="hasError($v.stowageBillOfLoading.ClientTpId)"
              >
                <template #append>
                  <CButton
                    shape="square"
                    color="add"
                    size="sm"
                    class="pt-0"
                    :disabled="stowageBillOfLoading.PortActivityId == ''"
                    v-c-tooltip="{
                      content: $t('label.nuevo') + getClientLabel,
                      placement: 'top-end',
                    }"
                    @click="getTpClientId"
                  >
                    <CIcon name="cil-playlist-add" />
                  </CButton>
                </template>
              </CSelect>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>
            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="getRifLabel"
                size="sm"
                v-model.trim="rifClient"
                disabled
              />
            </CCol>

            <CCol sm="12">
              <CCollapse :show="collapse">
                <CRow>
                  <CCol sm="12" v-if="collapse">
                    <AddCliente
                      metalScrap="ClientPortActivity-insert"
                      :tpClientId="currentTpClientId"
                      :title="getCollapseTitle"
                      @child-refresh="handleCollapse"
                      @update-list="handleRefresh"
                    />
                  </CCol>
                </CRow>
              </CCollapse>
            </CCol>

            <CCol sm="4">
              <CInput
                addLabelClasses="required text-right"
                :placeholder="'BL'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="'BL'"
                maxlength="20"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.NroBl.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.NroBl)"
                :is-valid="hasError($v.stowageBillOfLoading.NroBl)"
              />
            </CCol>
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="'POL'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="loadPortOptions"
                :disabled="isEmpty(loadPorts)"
                :value.sync="$v.stowageBillOfLoading.LoadPortId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.LoadPortId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.LoadPortId)"
              >
              </CSelect>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>
            <CCol sm="4">
              <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-4 mt-1">{{'POD'}}</label>
                <div class="col-sm-12 col-lg-8">
                  <v-select
                    id="v-select-small"
                    :disabled="isEmpty(dischargePorts)"
                    :placeholder="$t('label.select')"
                    :options="dischargePortOptions"
                    :reduce="option => option.value" 
                    v-model.trim="$v.stowageBillOfLoading.DischargePortId.$model"
                    :value.sync="$v.stowageBillOfLoading.DischargePortId.$model"
                    :class="computedSelectPOD 
                        ? 'select-client--correct' 
                        : (verifyClient ? 'select-client--error' : '') 
                      "
                    :invalid-feedback="
                      errorMessage($v.stowageBillOfLoading.DischargePortId)
                    "
                    :is-valid="hasError($v.stowageBillOfLoading.DischargePortId)"
                  />
                  <div v-if="verifyClient&&(this.stowageBillOfLoading.DischargePortId === '' || this.stowageBillOfLoading.DischargePortId === null)">
                    <label class="text-invalid-feedback">{{$t('validation.required')}}</label>
                  </div>
                </div>
              </div>
              <loading v-show="loadingClientAndPort" element="select" />
            </CCol>

            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="'Nº BOOKING'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="'Nº BOOKING'"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.BookingNumber.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.BookingNumber)"
                :is-valid="hasError($v.stowageBillOfLoading.BookingNumber)"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.seller')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.seller')"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.Shipper.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Shipper)"
                :is-valid="hasError($v.stowageBillOfLoading.Shipper)"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :placeholder="$t('label.notifyParty')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.notifyParty')"
                size="sm"
                v-model.trim="$v.stowageBillOfLoading.NotifyParty.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.NotifyParty)"
                :is-valid="hasError($v.stowageBillOfLoading.NotifyParty)"
              />
            </CCol>

            <CCol sm="8">
              <CTextarea
                size="sm"
                addWrapperClasses="input-textarea-manifiesto-general"
                addLabelClasses="text-right label-textarea-manifiesto-general"
                v-uppercase
                :label="$t('label.inventoryContains')"
                :placeholder="$t('label.inventoryContains')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                rows="2"
                maxlength="150"
                v-model.trim="$v.stowageBillOfLoading.GeneralInfo.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.GeneralInfo)"
                :is-valid="hasError($v.stowageBillOfLoading.GeneralInfo)"
              />
            </CCol>
            <CCol sm="4" v-if="edit">
              <div>
                <CSelect
                  :value.sync="stowageBillOfLoading.Status"
                  :is-valid="statusSelectColor()"
                  :horizontal="{
                    label: 'col-sm-12 col-lg-4',
                    input: 'col-sm-12 col-lg-8',
                  }"
                  size="sm"
                  :label="$t('label.status')"
                  :options="statusOptions"
                  addLabelClasses="text-right"
                />
              </div>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.loadCondition')">
          <CRow class="mt-3">
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.cargoType')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="cargoTypesOptions"
                :disabled="isEmpty(cargoTypes)"
                :value.sync="$v.stowageBillOfLoading.TpCargoId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.TpCargoId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.TpCargoId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.classification')"
                size="sm"
                v-model.trim="classification"
                disabled
              />
            </CCol>
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.commodity')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="commodityOptions"
                :disabled="isEmpty(commodities)"
                :value.sync="$v.stowageBillOfLoading.CommodityId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.CommodityId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.CommodityId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :label="'IMDG'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="imosOptions"
                :disabled="isEmpty(imos)"
                :value.sync="$v.stowageBillOfLoading.ImdgId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.ImdgId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.ImdgId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="4">
              <CInput
                size="sm"
                ref="weightInput"
                :label="$t('label.weight') + ' (TON) (EST)'"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                placeholder="0"
                addLabelClasses="required text-right"
                v-model.lazy="stowageBillOfLoading.Weight"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Weight.$touch()"
                maxlength="9"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Weight)"
                :is-valid="hasError($v.stowageBillOfLoading.Weight)"
                required
              />
            </CCol>
            <!-- <CCol sm="4">
              <CInput
                addLabelClasses="required text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.density')+'(KG/M³)'"
                size="sm"
                v-model.trim="density"
                disabled
              /> -->
              <!-- <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-5 col-form-label-sm">{{
                  $t('label.density')+'(KG/M³)'
                }}</label>
                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                  <money
                    v-model="density"
                    v-bind="measureDensity"
                    class="form-control"
                    value="0"
                    disabled
                  >
                  </money>
                </div>
              </div> -->
            <!--</CCol>-->
            <CCol sm="4">
              <CSelect
                size="sm"
                :placeholder="$t('label.select')"
                addLabelClasses="required text-right"
                :label="$t('label.heading')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :options="rubrosOptions"
                :disabled="isEmpty(rubros)"
                :value.sync="$v.stowageBillOfLoading.HeadingId.$model"
                :invalid-feedback="
                  errorMessage($v.stowageBillOfLoading.HeadingId)
                "
                :is-valid="hasError($v.stowageBillOfLoading.HeadingId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="4">
              <CInput
                ref="temperatureInput"
                addLabelClasses="text-right"
                :placeholder="$t('label.temperature')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                :label="$t('label.temperature')"
                size="sm"
                v-model.lazy="stowageBillOfLoading.Temperature"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Temperature)"
                :is-valid="hasError($v.stowageBillOfLoading.Temperature)"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Temperature.$touch()"
                maxlength="9"
              >
                <template #prepend>
                  <CSelect
                    :value.sync="$v.stowageBillOfLoading.UnitMeasureTemperatureId.$model"
                    :options="temperatureOptions"
                    :disabled="isEmpty(temperatures)"
                    class="mr-1 mb-0"
                    size="sm"
                  />
                </template>
              </CInput>
            </CCol>
            <CCol sm="4">
              <CInput
                ref="volumenInput"
                addLabelClasses="text-right"
                placeholder="0"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                :label="$t('label.volume')+'(M³/MOL)'"
                size="sm"
                v-model.lazy="stowageBillOfLoading.Volumen"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Volumen)"
                :is-valid="hasError($v.stowageBillOfLoading.Volumen)"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Volumen.$touch()"
                maxlength="9"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                ref="viscosityInput"
                addLabelClasses="text-right"
                placeholder="0"
                :horizontal="{
                  label: 'col-sm-12 col-lg-4',
                  input: 'col-sm-12 col-lg-8',
                }"
                v-uppercase
                :label="$t('label.viscosity')"
                size="sm"
                v-model.lazy="stowageBillOfLoading.Viscosity"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Viscosity)"
                :is-valid="hasError($v.stowageBillOfLoading.Viscosity)"
                v-money="measure"
                @focus="$v.stowageBillOfLoading.Viscosity.$touch()"
                maxlength="9"
              />
            </CCol>
            <CCol sm="8">
              <CTextarea
                size="sm"
                addWrapperClasses="input-textarea-manifiesto-condition"
                addLabelClasses="text-right label-textarea-manifiesto-condition"
                v-uppercase
                :label="$t('label.condition')"
                :placeholder="$t('label.condition')"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-9',
                }"
                rows="2"
                maxlength="150"
                v-model.trim="$v.stowageBillOfLoading.Condiction.$model"
                :invalid-feedback="errorMessage($v.stowageBillOfLoading.Condiction)"
                :is-valid="hasError($v.stowageBillOfLoading.Condiction)"
              />
            </CCol>
          </CRow>
        </CTab>
        <CTab 
          :title="$t('label.estimatedToShip')" 
          v-if="modalityId != 'AFF23EB5-E481-4784-AA9D-1C8A2C8A2BF7'"
          :disabled="!isExporter || this.stowageBillOfLoading.PortActivityId == ''"
        >
          <CRow class="mt-3" alignHorizontal="center">
            <CCol sm="4">
              <CInput
                addLabelClasses="text-right"
                :horizontal="{
                  label: 'col-sm-12 col-lg-3',
                  input: 'col-sm-12 col-lg-7',
                }"
                v-uppercase
                label="BL"
                size="sm"
                v-model.trim="stowageBillOfLoading.NroBl"
                disabled
              />
            </CCol>
            <CCol sm="4">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{
                  $t('label.declared') + " (TON)"
                }}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    v-model="currentDeclaredWeight"
                    v-bind="measure"
                    class="form-control"
                    value="0"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
            <CCol sm="4">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{
                  $t('label.availability') + " (TON)"
                }}</label>
                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                  <money
                    v-model="declaredMinusAssign"
                    v-bind="measure"
                    class="form-control"
                    value="0"
                    disabled
                  >
                  </money>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow class="mt-3" alignHorizontal="center">
            <CCol sm="10">
                <EstimatedInputs
                :yards="yardOptions"
                :declared="`${currentDeclared}`"
                :item="blToEdit"
                :edit="editEstimated"
                :current="formatedEstimateds"
                :modalOpen="modalActive"
                :modalEdit="edit"
                @clear="clearEstimated"
                @submit="submitEstimated"
                @update="updateEstimated"
                @value="getCurrentAssign"
              />
            </CCol>
            <CCol sm="10">
              <dataTableExtended
                class="align-center-row-datatable"
                :items="formatedEstimateds"
                :fields="fieldsEstimated"
                :loading="loading"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="tableText.itemsPerPage"
                hover
                small
                sorter
                pagination
              >
                <template #loading>
                  <loading />
                </template>
                <template #Detalle="{ item }">
                  <td class="text-center align-middle">
                    <CButtonGroup>
                      <CButton
                        shape="square"
                        color="edit"
                        size="sm"
                        v-c-tooltip="
                          $t('label.edit') + ' '
                        "
                        @click.prevent="editEstimatedInfo(item)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ManifiestoCargaValidation from "@/_validations/planificacion-estiba/ManifiestoDeCargaValidations";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from "@/_validations/validacionEspeciales";
import { VMoney, Money } from "v-money";
import UpperCase from '@/_validations/uppercase-directive';
import AddCliente from "@/pages/cliente/add-cliente";
import EstimatedInputs from "./estimated-to-ship-inputs";

//DATA
function data() {
  return {
    verifyClient: false,
    modalActive: false,
    stowageBillOfLoading: {
      StowagePlanningBillOfLadingId: "",
      StowagePlanningId: "",
      TpCargoId: "",
      HeadingId: "7A3154CF-06F9-4103-B272-E811C935ACC3",
      CommodityId: "",
      ClientTpId: "",
      NroBl: "",
      Shipper: "",
      NotifyParty: "",
      LoadPortId: "",
      DischargePortId: "",
      ImdgId: "",
      PortActivityId: "",
      BookingNumber: "",
      UnitMeasureWeightBlId: "52388680-623F-4E8A-8DB9-424605963856",
      Weight: 0,
      UnitMeasureVolumenBlId: "",
      Volumen: 0,
      UnitMeasureTemperatureId: "8AA41F4A-D0DC-46CB-9471-9934A8C28A8E",
      Temperature: "",
      Viscosity: "",
      GeneralInfo: "",
      Condiction: "",
      StowagePlanningBlDetJson: [],
      Status: 1,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    measureDensity: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 4,
      masked: false,
    },
    modalTitle: "",
    collapse: false,
    oldStatus: 1,
    loading: false,
    yards: [],
    imos: [],
    cargoTypes: [],
    activities: [],
    rubros: [],
    commodities: [],
    temperatures: [],
    //density: '',
    classification: '',
    rifClient: '',
    temperatureDefault: '',
    loadPorts: [],
    dischargePorts: [],
    clients: [],
    loadingClients: false,
    loadingPorts: false,
    blToEdit: null,
    editEstimated: false,
    currentTpClientId: '',
    loadingClientAndPort: false,
    byIdUser: '',
    byIdDate: '',
    activeTab: 0,
    currentDeclaredWeight: 0,
    currentEstimatedTotal: 0,
    declaredMinusAssign: 0,
    editDeclared: 0,
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else this.loadData();

  this.modalActive = newVal;
}
function resetForm() {
  let arrKeys = Object.keys(this.stowageBillOfLoading);
  this.verifyClient = false;
  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "HeadingId":
        this.stowageBillOfLoading[arrKeys[i]] = "7A3154CF-06F9-4103-B272-E811C935ACC3";
        break;
      case "Weight":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "UnitMeasureWeightBlId":
        this.stowageBillOfLoading[arrKeys[i]] = "52388680-623F-4E8A-8DB9-424605963856";
        break;
      case "Volumen":
        this.stowageBillOfLoading[arrKeys[i]] = '';
        break;
      case "Temperature":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "UnitMeasureTemperatureId":
        this.stowageBillOfLoading[arrKeys[i]] = "8AA41F4A-D0DC-46CB-9471-9934A8C28A8E";
        break;
      case "Viscosity":
        this.stowageBillOfLoading[arrKeys[i]] = "";
        break;
      case "Status":
        this.stowageBillOfLoading[arrKeys[i]] = 1;
        this.oldStatus = 1;
        break;
      case "StowagePlanningBlDetJson":
        this.stowageBillOfLoading[arrKeys[i]] = [];
        break;
      default:
        this.stowageBillOfLoading[arrKeys[i]] = "";
    }
  }
  this.oldStatus = 1;
  this.yards = [];
  this.clients = [];
  this.commodities = [];
  this.rubros = [];
  this.cargoTypes = [];
  this.loadPorts = [];
  this.dischargePorts = [];
  this.activities = [];
  this.temperatures = [];

  this.modalTitle = "";
  this.rifClient = "";
  this.classification = "";
  //this.density = "";

  this.collapse = false;
  this.blToEdit = null;
  this.editEstimated = false;
  this.loadingClients = false;
  this.loadingPorts = false;
  this.currentTpClientId = '';
  this.loadingClientAndPort = false;
  this.byIdUser = '';
  this.byIdDate = '';
  this.activeTab = 0;
  this.currentEstimatedTotal = 0;
  this.currentDeclaredWeight = 0;
  this.declaredMinusAssign = 0;
  this.editDeclared = 0;

  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = "";
  let inputVolumen = this.$refs.volumenInput.$el.querySelector("input");
  inputVolumen.value = "";
  let inputViscosity = this.$refs.viscosityInput.$el.querySelector("input");
  inputViscosity.value = "";
  let inputTemperature = this.$refs.temperatureInput.$el.querySelector("input");
  inputTemperature.value = "";
  this.$v.$reset();
}
function setForm(bill) {
  let arrKeys = Object.keys(this.stowageBillOfLoading);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Weight":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Volumen":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Viscosity":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]].toFixed(2) : '';
        break;
      case "Temperature":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? Number.parseFloat(bill[arrKeys[i]]).toFixed(2) : '';
        break;
      case "StowagePlanningBlDetJson":
        this.stowageBillOfLoading[arrKeys[i]] = Array.isArray(bill['StowagePlanningBlDetailJson']) ? bill['StowagePlanningBlDetailJson'] : [];
        break;
      case "Status":
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = bill[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.stowageBillOfLoading[arrKeys[i]] = bill[arrKeys[i]] ? bill[arrKeys[i]] : "";
    }
  }

  this.byIdUser = bill.TransaLogin ? bill.TransaLogin : 'N/A';
  this.byIdDate = bill.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(bill.TransaRegDate) : 'N/A';
  this.modalTitle = `${bill.NroBl}`;
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = this.stowageBillOfLoading.Weight ? this.stowageBillOfLoading.Weight : '';
  let inputVolumen = this.$refs.volumenInput.$el.querySelector("input");
  inputVolumen.value = this.stowageBillOfLoading.Volumen ? this.stowageBillOfLoading.Volumen : '';
  let inputViscosity = this.$refs.viscosityInput.$el.querySelector("input");
  inputViscosity.value = this.stowageBillOfLoading.Viscosity ? this.stowageBillOfLoading.Viscosity : '';
  let inputTemperature = this.$refs.temperatureInput.$el.querySelector("input");
  inputTemperature.value = this.stowageBillOfLoading.Temperature ? this.stowageBillOfLoading.Temperature : '';
  this.$v.$touch();
}
function loadData() {
  this.loading = true;
  let peticiones = !this.edit
    ? [
        this.$http.ejecutar("GET", "PortActivity-list-by-StowagePlanningId", {
          StowagePlanningId: this.planificacionId,
        }),
        this.$http.ejecutar("GET", "Imdg-list", {
          ImdgClassId: '',
          Filter: 'ACTIVO',
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
        this.$http.ejecutar("GET", "StowagePlanningMetalScrapYard-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "UnitMeasureByTpUnitMeasureId", {
          TpUnitMeasureId: 'b6f6dcfd-7246-410c-a576-36462ccf5819',
          Filter: 'ACTIVO',
        }),
      ]
    : [
        this.$http.ejecutar("GET", "PortActivity-list-by-StowagePlanningId", {
          StowagePlanningId: this.planificacionId,
        }),
        this.$http.ejecutar("GET", "Imdg-list", {
          ImdgClassId: '',
          Filter: 'ACTIVO',
        }),
        this.$http.ejecutar("GET", "BulkCargoConfig-list"),
        this.$http.ejecutar("GET", "StowagePlanningMetalScrapYard-list", {
          CompanyBranchId: this.getBranchId,
        }),
        this.$http.ejecutar("GET", "UnitMeasureByTpUnitMeasureId", {
          TpUnitMeasureId: 'b6f6dcfd-7246-410c-a576-36462ccf5819',
          Filter: 'ACTIVO',
        }),
        this.$http.ejecutar("GET", "StowagePlanningBillOfLading-by-id", {
          StowagePlanningBillOfLadingId: this.billOfLoadingId,
        }),
      ];

  Promise.all(peticiones)
    .then((responses) => {
      this.activities = responses[0].data.data;
      this.imos = responses[1].data.data;
      this.cargoTypes = responses[2].data.data[0].TpCargoJson;
      this.rubros = responses[2].data.data[0].CommodityJson;
      this.commodities = responses[2].data.data[0].CommodityJson;
      this.yards = responses[3].data.data;
      this.temperatures = responses[4].data.data;
      this.clients = [];
      this.loadPorts = [];
      this.dischargePorts = [];

      /*let found = this.rubros.find((item) => item.HeadingId == this.stowageBillOfLoading.HeadingId);
      if(found) {
        this.density = found.Density;
      }*/

      if (this.edit) {
        this.setForm(responses[5].data.data[0]);
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function formatNumericValue(val) {
  return Number.parseFloat(val.replace(".", "").replace(",", "."));
}
function formatedData() {
  if (!this.edit) {
    let newBill = {
      ...this.stowageBillOfLoading,
      StowagePlanningId: this.planificacionId,
      Weight: this.formatNumericValue(this.stowageBillOfLoading.Weight),
      Volumen: this.formatNumericValue(this.stowageBillOfLoading.Volumen),
      Viscosity: this.formatNumericValue(this.stowageBillOfLoading.Viscosity),
      Temperature: this.formatNumericValue(this.stowageBillOfLoading.Temperature),
      StowagePlanningBlDetJson: !this.isExporter ? [] : this.stowageBillOfLoading.StowagePlanningBlDetJson.map((item) =>
        Object.assign(
          {},
          {
            BillOfLadingYardId: item.BillOfLadingYardId,
            UnitMeasureWeightBlDetId: item.UnitMeasureWeightBlDetId,
            Weight: item.Weight,
          }
        )
      ),
    };

    delete newBill.StowagePlanningBillOfLadingId;
    delete newBill.Status;

    return newBill;
  } else {
    return {
      ...this.stowageBillOfLoading,
      StowagePlanningId: this.planificacionId,
      Weight: this.formatNumericValue(this.stowageBillOfLoading.Weight),
      Volumen: this.formatNumericValue(this.stowageBillOfLoading.Volumen),
      Viscosity: this.formatNumericValue(this.stowageBillOfLoading.Viscosity),
      Temperature: this.formatNumericValue(this.stowageBillOfLoading.Temperature),
      StowagePlanningBlDetJson: !this.isExporter ? this.stowageBillOfLoading.StowagePlanningBlDetJson.map((item) =>
        Object.assign(
          {},
          {
            BillOfLadingYardId: item.BillOfLadingYardId,
            UnitMeasureWeightBlDetId: item.UnitMeasureWeightBlDetId,
            Weight: item.Weight,
            Status: 0,
          }
        )
      ) : this.stowageBillOfLoading.StowagePlanningBlDetJson.map((item) =>
        Object.assign(
          {},
          {
            BillOfLadingYardId: item.BillOfLadingYardId,
            UnitMeasureWeightBlDetId: item.UnitMeasureWeightBlDetId,
            Weight: item.Weight,
            Status: item.Status,
          }
        )
      ),
    };
  }
}
function submit() {
  try {
    this.verifyClient = true;
    this.$v.stowageBillOfLoading.$touch();
    if (this.$v.stowageBillOfLoading.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit ? "StowagePlanningBillOfLading-update" : "StowagePlanningBillOfLading-insert";
    let StowagePlanningBillOfLadingJson = this.formatedData;
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, StowagePlanningBillOfLadingJson, {
        root: "StowagePlanningBillOfLadingJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function isEmpty(arr) {
  return arr.length == 0;
}

function confirmation() {
  try {
    this.$v.stowageBillOfLoading.$touch();
    if (this.$v.stowageBillOfLoading.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.stowageBillOfLoading.Status,
      this.submit,
      `${this.modalTitle}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getClients() {
  this.loadingClients = true;
  this.$http
    .get("Client-list-by-PortActivityId", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.clients = response.data.data;

      if(this.edit) {
        let foundClient = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
        if(foundClient)
          this.rifClient = foundClient.ClientRif;
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingClients = false;
    });
}
function getPorts() {
  this.loadingPorts = true;
  this.$http
    .get("StowagePlanningPort-by-CompanyBranchId", {
      CompanyBranchId: this.getBranchId,
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.loadPorts = response.data.data[0].VisitJson[0].LoadPortJson;
      this.dischargePorts = response.data.data[0].VisitJson[0].DischargePortJson;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingPorts = false;
    });
}
function handleCollapse() {
  if (this.collapse) {
    this.getClients();
    this.collapse = false;
    this.currentTpClientId = '';
  } else {
    this.collapse = true;
  }
}
function handleRefresh() {
  this.getClients();
  this.currentTpClientId = '';
}

function updateEstimated(response) {
  let info = { ...response };

  let index = this.stowageBillOfLoading.StowagePlanningBlDetJson.findIndex(
    (item) => item.StowagePlanningBlDetailId == info.StowagePlanningBlDetailId
  );

  if (index == -1) {
    let flag = this.stowageBillOfLoading.StowagePlanningBlDetJson.findIndex(
      (item) => item.BillOfLadingYardId == info.BillOfLadingYardId
    );

    if (flag == -1) return;

    let toReplace = { ...this.stowageBillOfLoading.StowagePlanningBlDetJson[flag], ...info };
    this.stowageBillOfLoading.StowagePlanningBlDetJson.splice(flag, 1, toReplace);
  } else {
    let toReplace = { ...this.stowageBillOfLoading.StowagePlanningBlDetJson[index], ...info };
    this.stowageBillOfLoading.StowagePlanningBlDetJson.splice(index, 1, toReplace);
  }

  this.editEstimated = false;
  this.blToEdit = null;
  this.editDeclared = 0;
}
function editEstimatedInfo(item) {
  this.blToEdit = { ...item };
  this.editEstimated = true;
  this.editDeclared = this.blToEdit.Weight;
}
function clearEstimated() {
  this.editEstimated = false;
  this.blToEdit = null;
  this.editDeclared = 0;
}
function submitEstimated(item) {
  this.stowageBillOfLoading.StowagePlanningBlDetJson.push(item);
}
function getTpClientId() {
  this.loading = true;
  this.$http
    .get("TpClientPortActivity-list", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    })
    .then((response) => {
      this.currentTpClientId = response.data.data[0].TpClientId;
      this.handleCollapse();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function loadClientsAndPorts() {
  this.loadingClientAndPort = true;
  let peticiones = [
    this.$http.ejecutar("GET", "Client-list-by-PortActivityId", {
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    }),
    this.$http.ejecutar("GET", "StowagePlanningPort-by-CompanyBranchId", {
      CompanyBranchId: this.getBranchId,
      PortActivityId: this.stowageBillOfLoading.PortActivityId,
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.clients = responses[0].data.data;

      this.loadPorts = responses[1].data.data[0].VisitJson[0].LoadPortJson;
      this.dischargePorts = responses[1].data.data[0].VisitJson[0].DischargePortJson;

      if(this.edit) {
        let foundClient = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
        if(foundClient)
          this.rifClient = foundClient.ClientRif;
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingClientAndPort = false;
    });
}
function getCurrentAssign(weight){
  if(!weight || weight == 0)
    this.declaredMinusAssign = Number.parseFloat(`${this.currentDeclaredWeight}`.replace(".", "").replace(",", "."));

  this.declaredMinusAssign = Number.parseFloat(`${this.currentDeclaredWeight}`.replace(".", "").replace(",", ".")) - weight;
}

//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return `${this.$t("label.nuevo")} BL`;
  } else {
    return `${this.$t("label.edit")} BL: ${this.modalTitle}`;
  }
}
function yardOptions() {
  return this.yards.map((yard) =>
    Object.assign({}, yard, {
      label: yard.YardName,
      value: yard.YardId,
    })
  );
}
function activityOptions() {
  return this.activities.map((item) =>
    Object.assign({}, item, {
      label: item['PortActivityName'+this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1)],
      value: item.PortActivityId,
    })
  );
}
function cargoTypesOptions() {
  return this.cargoTypes.map((item) =>
    Object.assign({}, item, {
      label: item.GpoCargoName ,
      value: item.TpCargoId,
    })
  );
}
function rubrosOptions() {
  return this.rubros.map((item) =>
    Object.assign({}, item, {
      label: item.HeadingName,
      value: item.HeadingId,
    })
  );
}
function clientOptions() {
  return this.clients.map((item) =>
    Object.assign({}, item, {
      label: item.ClientName,
      value: item.ClientTpId,
    })
  );
}
function commodityOptions() {
  return this.commodities.map((item) =>
    Object.assign({}, item, {
      label: item.CommodityName,
      value: item.CommodityId,
    })
  );
}
function temperatureOptions() {
  return this.temperatures.map((item) =>
    Object.assign({}, item, {
      label: item.UnitMeasureAbbrev,
      value: item.UnitMeasureId,
    })
  );
}
function loadPortOptions() {
  return this.loadPorts.map((item) =>
    Object.assign({}, item, {
      label: `${item.PortName}, ${item.IsoCodeAlpha2}`,
      value: item.PortId,
    })
  );
}
function dischargePortOptions() {
  return this.dischargePorts.map((item) =>
    Object.assign({}, item, {
      label: `${item.PortName}, ${item.IsoCodeAlpha2}`,
      value: item.PortId,
    })
  );
}
function imosOptions() {
  return this.imos.map((item) =>
    Object.assign({}, item, {
      label: item.ImdgCod,
      value: item.ImdgId,
    })
  );
}
function statusSelectColor() {
  return this.stowageBillOfLoading.Status === 1;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
function fieldsEstimated() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes: "text-center" },
    { key: "NroBl", label: 'BL' },
    { key: "Yard", label: this.$t("label.yard") },
    { key: "FormatWeight", label: this.$t("label.weight") + " (TON)" },
    { key: "User", label: this.$t("label.user") },
    { key: "Date", label: this.$t("label.date"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NroBl: "align-middle",
    Yard: "align-middle",
    FormatWeight: "align-middle",
    User: "align-middle",
    Date: "align-middle",
  };
}
function computedSelectPOD() {
  if (this.stowageBillOfLoading.DischargePortId === '' || this.stowageBillOfLoading.DischargePortId === null) {
    return false;
  } else {
    return true;
  }
}
function isExporter() {
  return (this.stowageBillOfLoading.PortActivityId == '' || this.stowageBillOfLoading.PortActivityId == 'A104FA2A-53A6-4238-8344-C2FD20F04EDE')
}
function getClientLabel() {
  if(this.isExporter)
    return this.$t('label.exporter');
  else
    return this.$t('label.importer');
}
function getRifLabel() {
  if(this.isExporter)
    return this.$t('label.rifExporter');
  else
    return this.$t('label.rifImporter');
}
function currentActivity() {
  return this.stowageBillOfLoading.PortActivityId;
}
function currentClient() {
  return this.stowageBillOfLoading.ClientTpId;
}
function currentCargoType() {
  return this.stowageBillOfLoading.TpCargoId;
}
/*function currentRubro() {
  return this.stowageBillOfLoading.HeadingId;
}*/

function formatedEstimateds() {
  let index = 1;
  return this.stowageBillOfLoading.StowagePlanningBlDetJson.map((item) => {
    let yardName = "";
    let foundYard = this.yards.find(
      (yard) => yard.YardId == item.YardId
    );
    if (foundYard)
      yardName = foundYard.YardName;

    return Object.assign({}, item, {
      Nro: index++,
      FormatWeight: formatMilDecimal(item.Weight.toFixed(2)),
      Yard: yardName,
      User: item.CurrentUser ? item.CurrentUser : this.byIdUser,
      Date: item.CurrentDate
        ? item.CurrentDate
        : this.byIdDate,
      _cellClasses: this.cellTableClasses,
    });
  });
}

function getCollapseTitle() {
  if(this.isExporter)
    return this.$t('label.clientExporter');
  else
    return this.$t('label.clientImporter');
}
function currentDeclared(){
  if(this.stowageBillOfLoading.StowagePlanningBlDetJson.length == 0) return this.stowageBillOfLoading.Weight;
  let current = Number.parseFloat(this.stowageBillOfLoading.Weight.replace(".", "").replace(",", "."));
  
  for (let index = 0; index < this.stowageBillOfLoading.StowagePlanningBlDetJson.length; index++) {
    current -= this.stowageBillOfLoading.StowagePlanningBlDetJson[index].Weight;
  }
  
  return current + this.editDeclared;
}
function currentEstimated() {
  if(this.stowageBillOfLoading.StowagePlanningBlDetJson.length == 0) return 0;

  let sum = 0;
  for (let index = 0; index < this.stowageBillOfLoading.StowagePlanningBlDetJson.length; index++) {
    sum += this.stowageBillOfLoading.StowagePlanningBlDetJson[index].Weight;
  }
  return sum;
}

export default {
  name: "manifiesto-carga-modal",
  mixins: [ModalMixin, General],
  data,
  components: {
    AddCliente,
    EstimatedInputs,
    Money,
  },
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    billOfLoadingId: {
      type: String,
      default: "",
    },
    cargoType: {
      type: String,
      default: "",
    },
  },
  validations() {
    return ManifiestoCargaValidation(this.stowageBillOfLoading.LoadPortId, this.stowageBillOfLoading.DischargePortId, this.currentEstimatedTotal);
  },
  directives: {
    money: VMoney,
  },
  directives: UpperCase,
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentActivity: function(newVal, oldVal) {
      if(newVal != oldVal && this.modalActive){
        this.loadClientsAndPorts();
        this.stowageBillOfLoading.ClientTpId = '';
        this.stowageBillOfLoading.LoadPortId = '';
        this.stowageBillOfLoading.DischargePortId = '';
        this.rifClient = '';
      }
    },
    currentClient: function(newVal, oldVal) {
      if(newVal != oldVal){
        let found = this.clients.find((item) => item.ClientTpId == this.stowageBillOfLoading.ClientTpId);
        if(found && this.modalActive)
          this.rifClient = found.ClientRif;
      }
    },
    currentCargoType: function(newVal, oldVal) {
      if(newVal != oldVal){
        let found = this.cargoTypes.find((item) => item.TpCargoId == this.stowageBillOfLoading.TpCargoId);
        if(found && this.modalActive)
          this.classification = found.TpCargoName;
      }
    },
    /*currentRubro: function(newVal, oldVal) {
      if(newVal != oldVal){
        let found = this.rubros.find((item) => item.HeadingId == this.stowageBillOfLoading.HeadingId);
        if(found && this.modalActive)
          this.density = found.Density;
      }
    },*/
    currentDeclared: function(newVal, oldVal) {
      if(newVal != oldVal){
        this.currentDeclaredWeight = newVal;
        this.declaredMinusAssign = newVal;
      }
    },
    currentEstimated: function(newVal, oldVal) {
      this.currentEstimatedTotal = newVal;
    },
  },
  methods: {
    toggle,
    loadData,
    isEmpty,
    getPorts,
    getClients,
    handleCollapse,
    handleRefresh,
    resetForm,
    submit,
    confirmation,
    formatNumericValue,
    setForm,
    statusSelectColor,
    updateEstimated,
    submitEstimated,
    clearEstimated,
    editEstimatedInfo,
    getTpClientId,
    loadClientsAndPorts,
    getCurrentAssign,
  },
  computed: {
    computedSelectPOD,
    tituloModal,
    getBranchId,
    fieldsEstimated,
    cellTableClasses,
    yardOptions,
    rubrosOptions,
    clientOptions,
    activityOptions,
    loadPortOptions,
    dischargePortOptions,
    cargoTypesOptions,
    temperatureOptions,
    commodityOptions,
    imosOptions,
    isExporter,
    getClientLabel,
    getRifLabel,
    currentActivity,
    currentClient,
    currentCargoType,
    //currentRubro,
    formatedData,
    formatedEstimateds,
    getCollapseTitle,
    currentDeclared,
    currentEstimated,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
      planificacionId: state => state.planificacionestiba.planificacionId,
      modalityId: state => state.planificacionestiba.ModalityId,
    }),
  },
};
</script>
<style lang="scss" scoped>
.ocultar {
  display: none;
}
.block {
  cursor: pointer;
}

.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}

.select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }
</style>