<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.cranesTypesGeneralList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.craneType'),
                  placement: 'top-end'
                }"
                @click="toggleAdd"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
     class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                  <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>

                <template #TpCraneName="{ item }">
                  <td class="center-cell text-justify">
                    {{ item.TpCraneName }}
                  </td>
                </template>
                <template #MaxHeigth="{ item }">
                  <td class="center-cell ">
                    {{ item.MaxHeigth }}
                  </td>
                </template>
                <template #MaxWeigth="{ item }">
                  <td class="center-cell ">
                    {{ item.MaxWeigth }}
                  </td>
                </template>
                <template #Foto="{ item }">
                  <td class="center-cell text-center">
                    <div class="d-flex justify-content-center">
                      <c-img-extended 
                        :src="item.Foto"
                        :key="item.RowNumber"
                        :error-options="{ width: 200, height: 200, fontSize: 40 }"
                        block
                        thumbnail
                        class="img-reg"
                      />
                    </div>
                  </td>
                </template>

                <template #Ultimo="{ item }">
                  <td class="center-cell text-justify">
                    {{ item.Ultimo }}
                  </td>
                </template>
                <template #FormatedDate="{ item }">
                  <td class="center-cell text-center">
                    {{ item.FormatedDate }}
                  </td>
                </template>

                <template #Status="{item}">
                  <td class="center-cell text-center">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{$t('label.'+item.Status)}}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="py-2 center-cell">
                      <CButton
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.craneType'),
                          placement: 'top-end'
                        }"
                        @click="toggleEdit(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
    <add-tp-grua :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-tp-grua :modal.sync="modalEdit" :tipo-grua-data="tipoGrua" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import CImgExtended from '@/components/CImgExtended';
import GeneralMixin from '@/_mixins/general';

import AddTpGrua from './add-tipo-grua-modal';
import EditTpGrua from './edit-tipo-grua-modal';

//Funcion data() del ciclo.


function data() {
  return {
    items: [],
    tipoGrua: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getTiposDeGruas();
}

//*****Funciones de methods:
function getTiposDeGruas() {
  this.isLoading = true;

  this.$http.get('TpCrane-list', { Filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item, index) {
  this.tipoGrua = Object.assign({}, item);

  this.modalEdit = true;
}
function handleSubmit(){
  this.getTiposDeGruas();
}

//*****Funcones de computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    Foto: `${this.$store.getters["connection/getBase"]}${item.TpCraneRoute?item.TpCraneRoute.replace('public/', '', null, 'i'):''}`,
    Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%;',
      filter: false
    },
    { 
      key: 'TpCraneName',
      label: this.$t('label.craneType'),
      _style: 'width:25%;',
    },
    { key: 'MaxHeigth', label: this.$t('label.maxHeigth')+' (MTR)', _style:'width:15%; ',_classes:"text-center"},
    { key: 'MaxWeigth', label: this.$t('label.maxWeight')+' (TON)', _style:'width:15%; ',_classes:"text-center"},
    { 
      key: 'Foto',
      label: this.$t('label.picture'),
      sorter: false,
      filter: false,
      _classes:"text-center",
      _style: 'width:100px', 
    },
    { key: 'Ultimo', label: this.$t('label.user'),_style: 'width:10%;',},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;', _classes:"center-cell text-center"},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%; text-align:center;'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
    }
  ];
}

export default {
  name: 'tipos-grua',
  mixins: [GeneralMixin],
  components: { AddTpGrua,EditTpGrua, CImgExtended },
  data,
  created,
  methods: {
    getTiposDeGruas,
    toggleAdd,
    toggleEdit,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields,
  }
}
</script>

<style scoped>
.img-reg{
  width: 3em;
  height: 3em;
}
.center-cell {
  vertical-align: middle;
}
</style>