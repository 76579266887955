import {
  required,
  helpers,
} from 'vuelidate/lib/validators';

import { textareaSpecials } from '../funciones';
    
const textareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);

export default () => {
  return {
    company: { required },
    // fileDescription: { textareaNotReq },
  }
}