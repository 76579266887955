import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater,validURL } from '@/_helpers/funciones';

export const planificacionbuque = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        messageError:'',
        tabIndex:0,
        modalGruas:false,

        myDataGruas:[],
    },
    getters: {
        myDataGruastable: state => {
            let chart = [];
            if(state.myDataGruas.length !== 0){
                state.myDataGruas.sort(function (a, b) {
                    if (a.FgActCranes < b.FgActCranes) return 1;
                    else if (a.FgActCranes > b.FgActCranes) return -1;
                    else {
                        if (a.CranesName > b.CranesName) {
                            return 1;
                        }
                        if (a.CranesName < b.CranesName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataGruas.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActCranes,
                    _classes: ( item.FgActCranes ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
		},
		messageMutation (state, value ) {
            state.messageError = value;
        },
    }
}