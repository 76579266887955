<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="getTitle"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaRol"
      @update:show="limpiarDatos()"
    >
      <CRow>
        <input id="RoleId" v-model="RoleId" type="hidden" />
        <CCol sm="12" v-if="asignar">
          <CInput
            :label="$t('label.name')+' (ES)'"
            :placeholder="$t('label.name')+' (ES)'"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model.trim="$v.RoleNameEs.$model"
            :invalid-feedback="errorMessage($v.RoleNameEs)"
            v-uppercase
            :is-valid="hasError($v.RoleNameEs)"
            maxlength="50"
          />  
        </CCol>
        <CCol sm="12" v-if="asignar">
          <CInput
            :label="$t('label.name')+' (EN)'"
            :placeholder="$t('label.name')+' (EN)'"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model.trim="$v.RoleNameEn.$model"
            :invalid-feedback="errorMessage($v.RoleNameEn)"
            v-uppercase
            :is-valid="hasError($v.RoleNameEn)"
            maxlength="50"
          />  
        </CCol>
        <CCol sm="12" v-if="asignar">
          <CInput
            :label="$t('label.routeDash')"
            :placeholder="$t('label.routeDash')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model.trim="$v.Dash.$model"
            :invalid-feedback="errorMessage($v.Dash)"
            maxlength="500"
            :is-valid="hasError($v.Dash)"
          />
        </CCol>
        <CCol sm="12">
          <div class="form-group form-row">
            <label class="required col-form-label col-sm-4 text-right pb-1"> {{$t('label.generalRole')}}</label><br />
            <div class="col-sm-8 pt-1">
              <label class="mr-5">
                <input type="radio" name="rol_general" value="1" checked v-model="FgGeneral" @click="vPatios=true, validSelect=false" />&nbsp;{{$t('label.yes')}}
              </label>
              <label class="mr-5">
                <input type="radio" name="rol_general" value="0" v-model="FgGeneral" @click="vPatios=false, validSelect=true" />&nbsp;{{$t('no')}}
              </label>
            </div>
          </div>
          
        </CCol>
        <CCol sm="12" v-if="vPatios">
          <div class="form-group form-row">
            <label class="col-form-label col-sm-4 text-right pb-1">{{$t('label.branch')}}</label>
            <div class="col-sm-7">
              <multiselect 
                :options="valoresSucursales" 
                :multiple="true" :close-on-select="false" 
                :clear-on-select="false" :placeholder="$t('label.select')" 
                label="name" 
                track-by="name" 
                v-model.trim="$v.sucursales.$model"              
                :class="isValidmultiselectBranch()"
                style="min-height: 33px;"
              >
                <template  slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" 
                    v-if="values.length &amp;&amp; !isOpen">{{ values.length +' '+ $t('label.selected')}}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </CCol>
        <CCol sm="12" v-if="!vPatios">
          <div class="form-group form-row">
            <label class="required col-form-label col-sm-12 col-lg-4 text-right pb-1">{{$t('label.yard')+'S'}}</label>
            <div class="col-sm-12 col-lg-7">
              <multiselect 
                  :options="valoresPatios" 
                  :multiple="true" 
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :placeholder="$t('label.select')" 
                  label="name" 
                  track-by="name" 
                  v-model.trim="$v.patios.$model"
                  :is-valid="hasError($v.patios)"               
                  :class="isValidmultiselectYard()"
                  :invalid-feedback="errorMessage($v.patios)" 
                  style="min-height: 33px;"
                >
                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length +' '+$t('label.selected')}}</span></template>
              </multiselect>
              <div class="col-sm-12 p-0">
                <div class='text-invalid-feedback'>{{ multiselectValidateYard(patios) }}</div>
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" v-if="actualizar" >
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            addLabelClasses="text-right required"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="add" 
                :disabled="isSudmit" 
                @click="evaluaStatus">
            <CIcon name="checkAlt"/>&nbsp;
            {{$t('button.accept')}}
          </CButton>
          <CButton outline color="wipe" @click="limpiarDatos()">
            <CIcon name="x"/>&nbsp;
            {{$t('button.cancel')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import RolValidations from '@/_validations/rol/rolValidations';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';

//DATA
function data () {
  return {
    //MODELO
    RoleId: '',
    RoleNameEs: '',
    RoleNameEn: '',
    Dash : '',
    FgGeneral: 1,
    vPatios: true,
    Status: 1,
    //VARIABLES
    RoleConfig: '',
    ActualizaRol: false,
    actualizar: false,
    activarSucursales: true,
    asignar: true,
    patios: [],
    valoresPatios: [],
    sucursales: [],
    valoresSucursales: [],
    Loading: false,
    esGeneral: false,
    tituloModal: '',
    isSudmit: false,
    YardVal:false,
    BranchVal:false,
    validSelect:false,
  }
}

function listarPatios () {
  this.patios.length = 0;
  this.valoresPatios.length = 0;
  this.Loading = true;
  this.$http.get("Yard-list", { CompanyBranchId: '', Filter: 'ACTIVO' })
  .then(response => {
    let otro = [...response.data.data];
    this.valoresPatios = otro.map(otro => Object.assign({}, this.valoresPatios, {
      value: otro.YardId,
      name: (otro.BranchName+" - "+otro.YardName)
    }));
    
    if ((this.RoleConfig!==null) && (this.FgGeneral==0)) {
      if (this.RoleConfig.length > 0)  {
        this.RoleConfig.forEach((ob)=> {
          if (ob.FgActRoleConfig) {
            this.patios.push({
              value: ob.YardId,
              name: (ob.BranchName + " - " +ob.YardName)
            })
          }          
        });
      }
    }
    this.validSelect = (this.patios?.length == 0 || this.patios == null || this.patios == "") ? false : true;
    this.Loading = false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  });
}

function listarSucursales () {
  this.valoresSucursales.length = 0;
  this.$http.get("Branch-list-by-company", { CompanyId: this.user.CompanyId })
  .then(response => {
    let otro = [...response.data.data];
    this.valoresSucursales = otro.map(otro => Object.assign({}, this.valoresSucursales, {
      value: otro.CompanyBranchId,
      name: otro.BranchName
    }));
    
    if ((this.RoleConfig!==null) && (this.FgGeneral==1)) {
      if (this.RoleConfig.length > 0)  {
        this.RoleConfig.forEach((ob)=> {
          if (ob.FgActRoleConfig) {
            this.sucursales.push({
              value: ob.CompanyBranchId,
              name: ob.BranchName
            })
          }          
        });
      }
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')}?`,
      })).then((result) => {
      if (result.isConfirmed) {
        this.patios=[];
        this.guardar();
      }
    });  
  } else this.guardar();
}
  
function guardar () {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.Loading    = true;
    let RoleJson    = [];
    let RoleYardJson= [];
    let RoleCompanyBranchJson = [];
    let metodo      = '';
    let ruta        = '';
    let res         = [];
    
    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      this.YardVal = true;
      this.BranchVal = this.vPatios ? false : true;
      throw this.$t('label.errorsPleaseCheck');
    }
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.FgGeneral==0) {
      this.patios.forEach((ob)=> {
        RoleYardJson.push({YardId: ob.value})
      });
    } else {
      this.sucursales.forEach((ob)=> {
        RoleCompanyBranchJson.push({CompanyBranchId: ob.value})
      });
    }

    if (this.actualizar) {
      RoleJson = {
        RoleId: this.RoleId,
        RoleNameEs: this.RoleNameEs,
        RoleNameEn: this.RoleNameEn,
        Dash: this.Dash,
        FgGeneral: this.FgGeneral,
        Status: this.Status,
        RoleCompanyBranchJson: RoleCompanyBranchJson,
        UserId: this.user.UserId,
        RoleYardJson: RoleYardJson,
      };
      metodo = 'PUT';
      ruta = "Role-update";
    } else {
      RoleJson = {
        RoleNameEs: this.RoleNameEs,
        RoleNameEn: this.RoleNameEn,
        Dash: this.Dash,
        FgGeneral: this.FgGeneral,
        UserId: this.user.UserId,
        RoleCompanyBranchJson: RoleCompanyBranchJson,
        RoleYardJson: RoleYardJson,
      };
      metodo = 'POST';
      ruta = "Role-insert";
    }

    this.$http.ejecutar(metodo, ruta, RoleJson, { root: 'RoleJson' })
    .then(response => {
      res = [...response.data.data];
      this.notifySuccess({
        text: res[0].Response,
      });
      this.Loading= false;
      this.$emit('child-refresh', true);
      this.ActualizaRol = false;
      this.limpiarDatos();
    }).catch((err) => {
      this.notifyError({text: err,});
      this.isSubmit = false;
      this.Loading= false;
    });
  } catch (err) {
    this.notifyError({text: err,});
    this.isSubmit = false;
    this.Loading= false;
  }
}
function isValidmultiselectYard() {
  if(this.YardVal === true){ 
    return (this.patios == null || this.patios == "") ? 'no-valido' : 'valido';
  }
}
function multiselectValidateYard() {
  if(this.YardVal === true){ 
    if(this.patios == null || this.patios == ""){ 
      return this.$t("label.required");
    }
  }
}
function isValidmultiselectBranch() {
  if(this.YardVal === true){ 
    return (this.sucursales == null || this.sucursales == "") ? 'valido' : 'valido';
  }
}
function limpiarDatos() {
  this.RoleNameEs     = '';
  this.RoleNameEn     = '';
  this.Dash         = '';
  this.RoleId       = '';
  this.FgGeneral    = 1;
  this.Status       = 1;
  this.patios       = [];
  this.sucursales   = [];
  this.valoresPatios= [];
  this.RoleConfig   = '';
  this.asignar      = true;
  this.ActualizaRol = false;
  this.YardVal      = false;
  this.BranchVal     = false;
  this.validSelect   = false;
  this.$nextTick(() => { this.$v.$reset() })
  this.$emit('cerrarModal');
}

//COMPUTED
function isDisabled() {
    if (this.FgGeneral==1) {
      return !this.RoleNameEs || !this.RoleNameEn || !this.Dash || this.sucursales.length < 1
    } else {
      return !this.RoleNameEs || !this.RoleNameEn || !this.Dash || this.patios.length < 1
    }      
}

function selectOptions(){
  return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ];
}
function getTitle() {
  return this.tituloModal;
}
export default {
  name: 'rol-update',
  mixins: [GeneralMixin, ModalMixin],
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations(){  return  RolValidations(this.validSelect)},
  watch: {
    modal: function (val) {
      if (val) {
        this.ActualizaRol=true;
        //NUEVO REGISTRO
        if (this.modal==true) {
          this.actualizar  = false;
          this.tituloModal = this.$t('label.nuevo')+this.$t('label.role');
          this.vPatios = true;
        } 
        //ACTUALIZAR REGISTRO
        else {
          let _lang = this.$i18n.locale;
          let name =_lang =='en' ? this.modal.RoleNameEn : this.modal.RoleNameEs;

          this.actualizar  = true;

          if (this.modal.esGeneral) {
            this.esGeneral=false;
            this.FgGeneral = 0;
            this.vPatios = false;
            this.validSelect=false;
            this.actualizar = false;
          } else {
            if (this.modal.aPatio==true) {
              this.asignar    = false;
              this.actualizar = false;
              this.tituloModal = this.$t('label.add')+this.$t('label.branch');
            }
            this.modal.aPatio = false;
            if (this.actualizar  == true) {
              this.tituloModal  = this.$t('label.edit')+this.$t('label.role')+": "+name;
            }else{
               this.tituloModal = this.$t('label.add')+this.$t('label.branch')+' - '+this.$t('label.yard');
            }
            this.RoleId       = this.modal.RoleId;
            this.RoleNameEs   = this.modal.RoleNameEs;
            this.RoleNameEn   = this.modal.RoleNameEn;
            this.Dash         = this.modal.Dash;
            this.FgGeneral    = this.modal.FgGeneral ? 1 : 0;
            this.RoleConfig   = this.modal.RoleConfigJson;
            this.YardVal      = true;
            this.Status       = this.modal.FgActRole ? 1 : 0;
            if (this.FgGeneral == 0) {
              this.vPatios = false;
            } else {
              this.vPatios = true;
            }
            this.$v.$touch();
          }
        }
        this.listarSucursales();
        this.listarPatios(); 
      }
    },
    esGeneral: function () {
      if (this.esGeneral==true) {
        this.FgGeneral = 0;
      } else {
        this.FgGeneral = 1;
      }
    }
  },
  methods: {
    guardar,
    isValidmultiselectYard,
    multiselectValidateYard,
    isValidmultiselectBranch,
    listarPatios,
    evaluaStatus,
    limpiarDatos,
    listarSucursales
  },
  computed: {
    isDisabled,
    selectOptions,
    getTitle,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style scoped>

</style>