<template>
    <div class="container-fluid mb-4" style="margin-top:20px;">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CModalExtended
            :title="$t('label.errors')"
            color="dark"
            :show.sync="showModal"
            :closeOnBackdrop="false"
            size="lg"
            class="modal-content-employee"
        >
            <CRow>
                <CCol sm="12">
                    <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fieldsModals"
                    :items="dateFilteredItems"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :loading="loadingTable"
                    hover
                    sorter
                    >

                    <template #Nro="{ index}">
                            <td>
                                {{index+1}}
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>

            <template #footer>
                <CButton color="wipe" @click="cerrarModalTable">
                    <CIcon name="x" />&nbsp; {{$t('button.exit')}}
                </CButton>
            </template>
        </CModalExtended>
        <CRow>
            <CCol sm="5">
                <CSelect
                    :label="$t('label.type')"
                    horizontal
                    addLabelClasses="required"
                    :options="selectedOptions"
                    :value.sync="TpStowageFileId"
                ></CSelect>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="items"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :loading="loadingTable"
                    hover
                    sorter
                    >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #Status="{ item }">
                        <td class="text-center align-middle">
                            <div>
                                <CBadge :color="getBadge(item.Status)">
                                {{ $t("label." + item.Status) }}
                                </CBadge>
                            </div>
                        </td>
                    </template>
                    <template #Detalle="{ item }">
                        <td class="text-center">
                            <CButton class="mr-1" color="watch" size="sm" v-c-tooltip="{content: $t('label.openErrors'),placement: 'top'}" @click="showModalError(item)">
                                <CIcon name="cil-exclamation-circle" />
                            </CButton>
                            <CButton class="mr-1" color="excel" size="sm" v-c-tooltip="{ content: $t('label.downloadExcel'),placement: 'top-end' }" target="_blank" :href="routeDoc(item)">
                                <CIcon name="cil-cloud-download"/>
                            </CButton>
                            <CButton color="wipe" size="sm" v-c-tooltip="{content: $t('label.deletedRecords'),placement: 'top-end'}" @click="ConfirmarEliminado(item)" >
                                <CIcon name="cil-trash" />
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';

    function data() {
        return {
            items: [],
            TpStowageFileId:'',
            TpStowageFileOptions:[],
            ItemsListModal:[],
            showModal:false,
            loadingOverlay:false,
            loadingTable:false,
            listOption: 1,
        }
    }
    //methods
    function getBadge(status) {
        switch (status) {
        case 'ACTIVO':
            return 'success'
        case 'INACTIVO':
            return 'danger'
        }
    }
    function routeDoc(item){
        return `${this.$store.getters["connection/getBase"]}${item.Route}`;
    }
    async function listarTpStowageFile () {
        this.loadingOverlay = true;
        this.TpStowageFileOptions = [];

        let listado = Array;
        await this.$http.get("TpStowageFile-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            this.TpStowageFileOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
                value: listado.TpStowageFileId,
                es: listado.TpStowageFileNameEs,
                en: listado.TpStowageFileNameEn
            }));
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
    function ConfirmarEliminado(item) {
        this.$swal.fire(
        alertPropertiesHelpers(this, {
            text: `${this.$t('label.deleteQuestion')}?`,
        })
        ).then((result) => {
            if (result.isConfirmed) {
                this.loadingOverlay = true;
                let res = [];
                let FileJson = {
                    StowagePlanningFileId: item.StowagePlanningFileId,
                };
                this.$http.put("StowagePlanningFile-deactivate", FileJson, { root: 'FileJson' })
                .then(response => {
                    res = [...response.data.data];
                    this.$notify({
                        group: 'container',
                        title: '¡'+this.$t('label.success')+'!',
                        text: res[0].Response,
                        type: "success"
                    });
                    this.loadingOverlay = false;
                    this.StowagePlanningFileList();
                }).catch((err) => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                    this.loadingOverlay = false;
                });
            }
        });
    }
    async function StowagePlanningFileList () {
        this.loadingTable = true;
        this.items = [];
        
        let listado = Array;
        await this.$http.get("StowagePlanningFile-list", { TpStowageFileId:this.TpStowageFileId,StowagePlanningId: this.StowagePlanningId })
        .then(response => {
            listado = [...response.data.data];
            this.items = listado.map(listado => Object.assign({}, this.items, {
                ...listado,
                Nro: Number(listado.Nro),
                UltimoUsuario: listado.TransaLogin,
                FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
                Status: listado.Status,
                FgActStPlanFile: listado.FgActStPlanFile,
                _classes: ( listado.FgActStPlanFile ) ? '' : 'table-danger'
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingTable = false;
        });
    }
    async function showModalError(item){
        let errorJson = [];
        if(item.TpStowageFileId == "FAAA44E9-8A3B-4A49-B45B-A7EEFF0C815F"){
            this.listOption = 1;
            await item.StowagePlanningVehicleJson.map(async (list) => {
                if(list.JsonError !== null){
                    if(Array.isArray(list.JsonError)){
                        if(list.JsonError.length > 0){
                            await list.JsonError.map(async(list2) => {
                                errorJson.push({
                                    ChoferName: list.LicensePlate ? list.LicensePlate : "N/A",
                                    ErrorMessageEn: list2.ErrorMessageEn,
                                    ErrorMessageEs: list2.ErrorMessageEs,
                                    ColumnName: list2.ColumnName,
                                    ColumnValue: list2.ColumnValue ? list2.ColumnValue : '',
                                })
                            })
                        }
                    }
                }
            });
        }
        else if(item.TpStowageFileId == "F1BCD883-4A98-4398-96A1-0DF12D65D0A1"){
            this.listOption = 2;
            await item.StowagePlanningDriverJson.map(async (list) => {
                if(list.JsonError !== null){
                    if(Array.isArray(list.JsonError)){
                        if(list.JsonError.length > 0){
                            await list.JsonError.map(async(list2) => {
                                errorJson.push({
                                    ChoferName: list.DriverName != "" ? (list.DriverName +' - '+  list.DriverCi) : list.DriverCi,
                                    ErrorMessageEn: list2.ErrorMessageEn,
                                    ErrorMessageEs: list2.ErrorMessageEs,
                                    ColumnName: list2.ColumnName,
                                    ColumnValue: list2.ColumnValue
                                })
                            })
                        }
                    }
                }
            });
        }
        this.ItemsListModal = errorJson;
        this.showModal = true;
    }
    function cerrarModalTable(){
        this.showModal = false;
    }
    //computed
    function selectedOptions(){
        let opciones = [{
            value : "",
            label : this.$t('label.all')
        }];
        let locale = this.$i18n.locale;
        this.TpStowageFileOptions.map(function(e) {
            opciones.push({
                value : e.value,
                label : locale == "es" ? e.es : e.en,
            });
        });
        return opciones;
    }
    function fields(){
        let locale = this.$i18n.locale;
        let ketStowageName = locale == "es" ? "TpStowageFileNameEs" : "TpStowageFileNameEn";
        return [
            { 
                key: 'Nro', 
                label: '#',
                _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { key: ketStowageName, label: this.$t('label.type'), _style: 'width:18%;',_classes: "text-center",sorter: true },
            { key: 'FileName', label: this.$t('label.filename'), _style: 'width:18%;',_classes: "text-center",sorter: true },
            { key: 'ClientName', label: this.$t('label.transport'),_style: 'width:15%;',_classes: "text-center",sorter: true },
            { key: 'Correct', label:this.$t('label.rightRows'), _style: 'width:12%;', _classes: "text-center",sorter: true },
            { key: 'Error', label:this.$t('label.wrongRows'), _style: 'width:12%;', _classes: "text-center",sorter: true },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes: "text-center",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes: "text-center",_style:'width:10%;',sorter: true},
            { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
            { 
                key: 'Detalle',
                label: '',
                sorter: false,
                filter: false,
                _style: 'min-width: 135px; width:1%; text-align:center;'
            }
        ];
    }
    function fieldsModals(){
        let locale = this.$i18n.locale;
        let ketName = locale == "es" ? "ErrorMessageEs" : "ErrorMessageEn";
        let nameColumn = this.listOption == 1 ? this.$t('label.vehicle') : this.$t('label.driver');
        return [
            { 
                key: 'Nro', 
                label: '#',
                _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { key: 'ChoferName', label: nameColumn,_classes: "text-center", _style: 'width:15%;',sorter: true },
            { key: 'ColumnName', label: this.$t('label.column'),_classes: "text-center", _style: 'width:20%;',sorter: true },
            { key: 'ColumnValue', label: this.$t('label.value'),_classes: "text-center", _style: 'width:20%;',sorter: true },
            { key: ketName, label: this.$t('label.error'),_classes: "text-center", _style: 'width:40%;',sorter: true },
        ]
    }
    function dateFilteredItems(){
        if(this.ItemsListModal.length !== 0){
            let items = this.ItemsListModal;
            return items.map((item,index) => Object.assign({}, item, {
                Nro: Number(items.Nro),
                UltimoUsuario: item.TransaLogin ? item.TransaLogin : 'N/A',
                FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                Status: item.Status ? item.Status : 'N/A',
            }));;
        }
        return [];
    }
    export default {
        name:'historic',
        data,
        props:['value'],
        mixins: [GeneralMixin],
        directives: UpperCase,
        methods:{
            routeDoc,
            listarTpStowageFile,
            ConfirmarEliminado,
            StowagePlanningFileList,
            showModalError,
            cerrarModalTable,
            getBadge
        },
        computed:{
            selectedOptions,
            fields,
            fieldsModals,
            dateFilteredItems,
            ...mapState({
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVessel: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        watch:{
            value: function (newValue,OldValue) {
                if ((newValue==5) && (this.StowagePlanning!="")) {
                    this.listarTpStowageFile();
                    this.StowagePlanningFileList();
                }else if(OldValue==3){
                    this.TpStowageFileId = "";
                }
            },
            TpStowageFileId: function(newValue){
                if(this.value == 5){
                    this.StowagePlanningFileList();
                }   
            }
        }
    }
</script>