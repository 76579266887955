<template>
    <div>
        <CModalExtended
            size="xl"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.selectFile')"
            :show.sync="$store.state.planificacionestiba.modalComparacionList"
        >
            <div class="m-2">
                <CRow>
                    <CCol sm="6" style="padding-right:0px;">
                        <div
                            class="card"
                            :style="'height:100%;border-radius: 0.25rem 0px 0px 0.25rem;'"
                        >
                            <div class="card-header"
                                :style="
                                    'background:' +
                                    ' #6c757d ' +
                                    '!important;width:100%;color:#00000;text-align:center;border-radius: 0.25rem 0px 0px 0px;'
                                "
                            >
                                BAPLIE
                            </div>
                            <div class="card-body">
                                <CRow>
                                    <CCol sm="12" class="d-flex align-items-center">
                                        <label style="margin-bottom:0px;" class="col-form-label">{{$t('label.discharges')}}</label>
                                        <CButton
                                            size="sm"
                                            color="light"
                                            class="ml-2 boton_informacion"
                                            @click="changeCollapse1()"
                                        >
                                            <CIcon :name="typecheck1"/>
                                        </CButton>
                                    </CCol>
                                    <CCol sm="12" v-if="checkListDownload">
                                        <CRow>
                                            <CCol sm="4" v-for="(item,index) in dischargeListItems" :key="index">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">{{item.EdiFileName}}</label>
                                                    <div class="col-sm-3 d-flex align-items-center justify-content-end">
                                                        <CSwitch
                                                            color="info"
                                                            variant="3d"
                                                            size="sm"
                                                            type="checkbox"
                                                            :id="'customManifestCheck'+index"
                                                            name="customManifestCheck"
                                                            :checked.sync="item.change"
                                                            v-on:update:checked="changeDischargeOptions(item)"
                                                        />
                                                    </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                <CRow >
                                    <CCol sm="12" class="d-flex align-items-center">
                                        <label style="margin-bottom:0px;" class="col-form-label">{{$t('label.shipments')}}</label>
                                        <CButton
                                            size="sm"
                                            color="light"
                                            class="ml-2 boton_informacion"
                                            @click="changeCollapse2()"
                                        >
                                            <CIcon :name="typecheck2"/>
                                        </CButton>
                                    </CCol>
                                    <CCol sm="12" v-if="checkListLoad">
                                        <CRow>
                                            <CCol sm="4" v-for="(item,index) in loadListItems" :key="index">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">{{item.EdiFileName}}</label>
                                                    <div class="col-sm-3 d-flex align-items-center justify-content-end">
                                                        <CSwitch
                                                            color="info"
                                                            variant="3d"
                                                            size="sm"
                                                            type="checkbox"
                                                            :id="'customManifestCheck'+index"
                                                            name="customManifestCheck"
                                                            :checked.sync="item.change"
                                                            v-on:update:checked="changeLoadOptions(item)"
                                                        />
                                                    </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm="6" style="padding-left:0px;">
                        <div
                            class="card"
                            :style="'height:100%;border-radius: 0px 0.25rem 0.25rem 0px;'"
                        >
                            <div class="card-header"
                                :style="
                                    'background:' +
                                    ' #6c757d ' +
                                    '!important;width:100%;color:#00000;text-align:center;border-radius: 0px 0.25rem 0px 0px;'
                                "
                            >
                                {{$t('label.preliminarList')}}
                            </div>
                            <div class="card-body">
                                <CRow>
                                    <CCol sm="4" v-for="(item,index) in preliminarListItems" :key="index">
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">{{item.EdiFileName}}</label>
                                            <div class="col-sm-3 d-flex align-items-center justify-content-end">
                                                <CSwitch
                                                    color="info"
                                                    variant="3d"
                                                    size="sm"
                                                    type="checkbox"
                                                    :id="'customManifestCheck'+index"
                                                    name="customManifestCheck"
                                                    :checked.sync="item.change"
                                                    v-on:update:checked="changePreliminarOptions(item)"
                                                />
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCol>
                    <CCol sm="12">
                        <div
                            class="card"
                            :style="'height:100%;'"
                        >
                            <div class="card-header"
                                :style="
                                    'background:' +
                                    ' #6c757d ' +
                                    '!important;width:100%;color:#00000;text-align:center;'
                                "
                            >
                                {{$t('label.selectedFiles')}}
                            </div>
                            <div class="card-body">
                                <dataTableExtended
                                    class="align-center-row-datatable"
                                    hover
                                    sorter
                                    small
                                    :loading="apiStateLoading"
                                    column-filter
                                    :table-filter="tableTextHelpers.tableFilterText"
                                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                    :items="dateFilteredItems"
                                    :fields="fields"
                                    :noItemsView="tableTextHelpers.noItemsViewText"
                                    :items-per-page="5"
                                    pagination
                                     
                                >
                                    <template #id="{ index}">
                                        <td class="center-cell text-center">
                                        {{index+1}}
                                        </td>
                                    </template>
                                </dataTableExtended>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </div>
            
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    :disabled="apiStateLoading"
                    @click="generarDocument"
                >   
                    
                    <CIcon name="checkAlt"/>&nbsp;
                        {{$t('label.generate')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import DataStorage from '@/_helpers/localStorage';
    //data
    function data() {
        return {
            usersOpened: null,
            checkListDownload:false,
            checkListLoad:false,
            padre: '',
            loadListItems: [],
            dischargeListItems: [],
            preliminarListItems: [],
            oldItemsList:{
                DischargeJson:[],
                LoadJson:[],
                PreliminarJson:[],
            }
        }
    }
    //methods
    function generarDocument(){
        let chart = [];
        this.loadListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })
        this.dischargeListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })
        this.preliminarListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })
        if(chart.length > 0){
            this.$store.dispatch('planificacionestiba/postFileCompartionList', { LoadPortId: this.loadPort,DischargePortId:this.dischargePort,FileJson:chart,resumidoOption:this.resumidoOption,filtrarOption:this.filtrarOption });
            this.$store.state.planificacionestiba.showComparationGroup = true;
            this.$store.state.planificacionestiba.modalComparacionList = false;
        }else{
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: "DEBE SELECCIONAR POR LO MENOS UN BAPLIE",
                type: "error"
            });
        }
    }
    function cerrarModal(){

        this.oldItemsList.LoadJson.map(async(item) => {
            this.loadListItems.map(async(item2) => {
                if(item.EdiFileId === item2.EdiFileId){
                    item2.change = item.change;
                }
            })
        });
        this.oldItemsList.DischargeJson.map(async(item) => {
            this.dischargeListItems.map(async(item2) => {
                if(item.EdiFileId === item2.EdiFileId){
                    item2.change = item.change;
                }
            })
        });
        this.oldItemsList.PreliminarJson.map(async(item) => {
            this.preliminarListItems.map(async(item2) => {
                if(item.EdiFileId === item2.EdiFileId){
                    item2.change = item.change;
                }
            })
        });

        this.$store.state.planificacionestiba.modalComparacionList = false;
    }
    function changeCollapse1(){
        this.checkListDownload = !this.checkListDownload;
    }
    function changeCollapse2(){
        this.checkListLoad = !this.checkListLoad;
    }
    function changeLoadOptions(item){
        if(this.loadListItems.length !== 0){
            this.loadListItems.map(async(value)  => {
                if(value.EdiFileId === item.EdiFileId){
                    value.change = item.change;
                }else{
                    if(item.change){
                        value.change = false;
                    }
                }
            })
        }
    }
    function changeDischargeOptions(item){
        if(this.dischargeListItems.length !== 0){
            this.dischargeListItems.map(async(value)  => {
                if(value.EdiFileId === item.EdiFileId){
                    value.change = item.change;
                }else{
                    if(item.change){
                        value.change = false;
                    }
                }
            })
        }
    }
    function changePreliminarOptions(item){
        if(this.preliminarListItems.length !== 0){
            this.preliminarListItems.map(async(value)  => {
                if(value.EdiFileId === item.EdiFileId){
                    value.change = item.change;
                }
            })
        }
    }
    //computed
    function fields(){
        return [
            { key: 'id', label: '#',filter :false, _style:'width:5%; text-align:center;' },
            { key: 'filename', label: this.$t('label.filename'),_classes:"text-left", _style:'width:50%;' },
            { key: 'type', label: this.$t('label.type'),_classes:"text-left", _style:'width:23%;'},
            { key: 'transaction', label: this.$t('label.transaction'),_classes:"text-left", _style:'width:22%;'},
        ]
    }
    function typecheck1(){
       // return this.checkListEdi ? 'cil-caret-bottom' : 'cil-caret-right';
        return this.checkListDownload ? 'cil-chevron-top' : 'cil-chevron-bottom';
    }
    function typecheck2(){
       // return this.checkListEdi ? 'cil-caret-bottom' : 'cil-caret-right';
        return this.checkListLoad ? 'cil-chevron-top' : 'cil-chevron-bottom';
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function dateFilteredItems(){
        if(this.modalComparacionList){
            let chart = [];
            this.loadListItems.map(async(item) => {
                if(item.change){
                    chart.push({
                        filename: item.EdiFileName,
                        type: item.TpEdiName,
                        transaction: this.$t('label.LOAD')
                    })
                }
            })
            this.dischargeListItems.map(async(item) => {
                if(item.change){
                    chart.push({
                        filename: item.EdiFileName,
                        type: item.TpEdiName,
                        transaction: this.$t('label.DISCHARGE')
                    })
                }
            })
            this.preliminarListItems.map(async(item) => {
                if(item.change){
                    chart.push({
                        filename: item.EdiFileName,
                        type: item.TpEdiName,
                        transaction: ""
                    })
                }
            })
            return chart;
        }else{
            return [];
        }
    }
    export default {
        name:'modal-comparacion-list',
        data,
        props: ['loadPort','dischargePort','resumidoOption','filtrarOption'],
        methods:{
            cerrarModal,
            changeLoadOptions,
            changeDischargeOptions,
            changePreliminarOptions,
            changeCollapse1,
            changeCollapse2,
            generarDocument
        },
        computed:{
            fields,
            typecheck1,
            typecheck2,
            tableTextHelpers,
            apiStateLoading,
            dateFilteredItems,
            ...mapState({
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                modalComparacionList: state => state.planificacionestiba.modalComparacionList,
            })
        },
        watch:{
            modalComparacionList(newItem){
                if(newItem){
                    this.loadListItems = this.$store.getters["planificacionestiba/getDataComparationLoad"];
                    this.dischargeListItems = this.$store.getters["planificacionestiba/getDataComparationDischarge"];
                    this.preliminarListItems = this.$store.getters["planificacionestiba/getDataComparationPreliminar"];

                    this.oldItemsList = {
                        DischargeJson:[],
                        LoadJson:[],
                        PreliminarJson:[],
                    }

                    this.loadListItems.map(async(item) => {
                        this.oldItemsList.LoadJson.push({
                            change: item.change,
                            EdiFileId: item.EdiFileId
                        }); 
                    })
                    this.dischargeListItems.map(async(item) => {
                        this.oldItemsList.DischargeJson.push({
                            change: item.change,
                            EdiFileId: item.EdiFileId
                        });
                    })
                    this.preliminarListItems.map(async(item) => {
                        this.oldItemsList.PreliminarJson.push({
                            change: item.change,
                            EdiFileId: item.EdiFileId
                        }); 
                    })
                }else{
                    this.loadListItems = [];
                    this.dischargeListItems = [];
                    this.preliminarListItems = [];
                }
            }
        }
    }
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
.boton_informacion{
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
</style>