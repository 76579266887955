<template>
  <div>
    <ActualizaRol :modal="vRol" @cerrarModal="vRol=false" @child-refresh="refrescarTabla=true" />
    <VerPatio :modal="vVerPatio" @cerrarModal="vVerPatio=false" />
    
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.roleList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="text-right">
            <CButton square color="add" @click="vRol=true"
              v-c-tooltip="{
                placement: 'top-end',
                content: $t('label.role'),
              }"
            >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nuevo')}}
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
     class="align-center-row-datatable"
              :items="items"
              :fields="fields"
              :loading="Loading"
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              column-filter
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            >
            <template #loading>
              <loading/>
            </template>
            <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}">
              <td class="py-2 text-center">
                <CButton color="edit" class="mr-1" square size="sm" v-c-tooltip="{content: $t('label.edit')+$t('label.role')}" @click="vRol=item" >
                  <CIcon name="pencil" />
                </CButton>
                <CButton color="watch" square size="sm" class="mr-1" v-c-tooltip="{content: $t('label.toView')+' '+$t('label.branch')+' - '+$t('label.yard')}" @click="vVerPatio=item" >
                  <CIcon name="eye" />
                </CButton>
                <CButton color="dark" square size="sm" class="mr-1" v-c-tooltip="{content: $t('label.add')+$t('label.branch')+' - '+$t('label.yard')}" @click="asignarPatio(item)" >
                  <CIcon name="cil-chevron-circle-up-alt" />
                </CButton>
              </td>
            </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import ActualizaRol from './rol-modal';
import VerPatio from './rol-ver-patio';
import General from '@/_mixins/general';


function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%;',
      filter: false
    },
    { key: 'RoleNameEs', label:this.$t('label.name')+' '+'(ES)', _style: "width: 20%;",_classes:"text-center" },
    { key: 'RoleNameEn', label:this.$t('label.name')+' '+'(EN)', _style: "width: 20%;",_classes:"text-center" },
    { key: 'Dash', label: this.$t('label.path'), _style: "width: 20%;",_classes:"text-center"},
    { key: 'General', label:this.$t('label.general'),_style: "width: 10%;",_classes:"text-center",},
    { key: 'Usuario', label:this.$t('label.user'), _style: "width: 15%;",_classes:"text-center" },
    { key: 'Fecha', label:this.$t('label.date'),  _style: "width: 15%;", _classes:"text-center" },
    { key: 'Status', label:this.$t('label.status'), _style: "width: 15%;", _classes:"text-center" },
    { 
      _style: 'min-width: 150px;',
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
    }
  ]; 
}

//FUNCIONES DE METHOD
function filtroItems(listado) {
  this.items = listado.map(listado => Object.assign({}, this.items, {
    Nro: listado.Nro,
    RoleId: listado.RoleId,
    RoleNameEs: listado.RoleNameEs, 
    RoleNameEn: listado.RoleNameEn, 
    Dash: listado.Dash, 
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Status: listado.Status,
    General: listado.FgGeneral ? this.$t('label.yes'): 'NO',
    FgGeneral: listado.FgGeneral,
    RoleConfigJson: listado.RoleConfigJson,
    FgActRole: listado.FgActRole,
    _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
  }));

  return this.items;
}
   
function listarDatos () {
  this.items = [];
  this.Loading = true;

  let listado = Array;
  this.$http.get("Role-list", { filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data];
    this.filtroItems(listado);
    this.Loading = false;
  }).catch( err => {
    this.Loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function asignarPatio(item) {
  let valor = Object.assign(item, { aPatio: true });
  this.vRol = valor;
  //delete valor['aPatio'];
}

//DATA
function data () {
  return {
    items: [],
    error: null,
    value: [],
    vRol: false,
    vVerPatio: false,
    Loading: false,
    refrescarTabla: false,
  }
}

export default {
  name: 'index',
  mixins: [General],
  data,
  components: {
    ActualizaRol,
    VerPatio
  },
  methods: {
    filtroItems,
    listarDatos,
    asignarPatio
  },
  computed:{
    fields,
  },
  watch: {
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.listarDatos();
        this.refrescarTabla = false;
      }
    }
  },
  mounted() {
    this.listarDatos();
  },
}
</script>
<style scoped>
  
</style>