<template>
  <CRow class="m-0 pt-3">
    <CCol sm="12" class="mb-3">
      <vue-editor
        size="sm"
        :horizontal="{label:'col-sm-12 col-lg-12', input:'col-sm-12 col-lg-12'}"
        v-model="vForm.DescriptionOfGoods.DescriptionOfGoods.$model"
        :is-valid="hasError(vForm.DescriptionOfGoods.DescriptionOfGoods)"
        :invalid-feedback="errorMessage(vForm.DescriptionOfGoods.DescriptionOfGoods)"
        :disabled="fgInfo"
        :class="!vForm.DescriptionOfGoods.DescriptionOfGoods.$dirty ? '' : (vForm.DescriptionOfGoods.DescriptionOfGoods.$error ? 'select-description--error' : 'select-description--correct')"
      >
      </vue-editor>
      <div class="invalid-feedback" v-if="vForm.DescriptionOfGoods.DescriptionOfGoods.$error">
        {{ errorMessage(vForm.DescriptionOfGoods.DescriptionOfGoods) }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { VueEditor } from "vue2-editor";

export default {
  name: 'descriptionOfGoods',

  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: {
      type: Object,
      required: true,
      default: () => {},
    },
    fgInfo: Boolean,
  },
  components: {
    VueEditor
  },
  mixins: [
    ModalMixin, 
    GeneralMixin,
  ],
  methods: {

  },
  computed: {

  },
  directives: UpperCase,
  watch: {

  }
};
</script>