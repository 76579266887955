<template>
  <CCollapse :show="showCollapase">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CCard>
      <CCardHeader color="dark text-white">
        <CRow>
          <CCol sm="11" class="d-flex ">{{TitleModal}}</CCol>
          <CCol sm="1" class="d-flex justify-content-end">
            <button type="button" aria-label="Close" class="close" @click="closeCollapse">×</button>
          </CCol>
        </CRow>
      </CCardHeader>
      <CRow class="mt-2">
        <CCol sm="12" lg="4">
          <CRow>
            <CCol sm="12" lg="12" class="text-center">
              <!-- <label>LOGO</label> -->
              <picture-input
                v-if="ActualizaMercancia"
                :imgSrc="image"
                ref="imageInput"
                width="200"
                height="200"
                accept="image/jpeg,image/png,image"
                size="10"
                :custom-strings="imgInputTexts"
                @change="handleFileUpload"
              > 
              </picture-input>
                                   
          <div class="text-invalid-feedback text-center" v-if="$v.CommodityRoute.$error">
            {{$t('label.badImage')}}
          </div>

            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="8">

            <CCol sm="12" lg="12">
              <CInput
                v-uppercase
                :label= "`${$t('label.commodity')} (ES)`"
                addLabelClasses="text-right required"
                :placeholder="`${$t('label.commodity')} (ES)`"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                maxlength="250"
                v-model.trim="$v.CommodityNameEs.$model"
                :is-valid="hasError($v.CommodityNameEs)"
                :invalid-feedback="errorMessage($v.CommodityNameEs)"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                v-uppercase
                :label= "`${$t('label.commodity')} (EN)`"
                addLabelClasses="text-right required"
                :placeholder="`${$t('label.commodity')} (EN)`"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                maxlength="250"
                v-model.trim="$v.CommodityNameEn.$model"
                :is-valid="hasError($v.CommodityNameEn)"
                :invalid-feedback="errorMessage($v.CommodityNameEn)"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                :label= "$t('label.hsCode')"
                addLabelClasses="text-right"
                v-uppercase
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                :placeholder="$t('label.hsCode')"
                :is-valid="hasError($v.HsCode)"
                :invalid-feedback="errorMessage($v.HsCode)"
                maxlength="250"
                v-model.trim="$v.HsCode.$model"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <div class="position-relative">
                <div role="group" class="form-group form-row">
                  <label class="col-sm-12 col-lg-4 text-right required col-form-label">{{$t('label.heading')}}</label>
                  <div class="col-sm-12 col-lg-7 input-group-sm">
                    <v-select
                      class="select-adjust"
                      :options="rubOptions"
                      v-model="HeadingId"
                      :getOptionLabel="option => option.label"
                      :placeholder="$t('label.select')"
                      :class="isRubroValid"
                    />
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                :label="`${$t('label.density')} (KG/M3)`"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                v-model.trim="$v.Density.$model"
                :is-valid="hasError($v.Density)"
                :invalid-feedback="errorMessage($v.Density)"
                maxlength="5"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CTextarea
                rows="5"
                maxlength="250"
                :label="$t('label.description')"
                :placeholder="$t('label.commodityDescription')"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                v-model.trim="$v.CommodityDs.$model"
                :is-valid="hasError($v.CommodityDs)"
                :invalid-feedback="errorMessage($v.CommodityDs)"
              />
            </CCol>
            <CCol sm="12" lg="12" v-if="isEdit">
              <CSelect
                :label="$t('label.status')"
                add-label-classes="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
                v-model="Status"
                :value.sync="Status"
                :is-valid="statusSelectColor"
                :options="statusOptions"
                required
              />
            </CCol>

        </CCol>
      </CRow>
      <CCardFooter>
        <div class="row justify-content-end">

            <CButton
              outline
              color="add"
              :disabled="isSubmit"
              @click.stop="isEdit?statusConfirmation(modal.Item.FgActCommodity, Status, submitFile):submitFile()"
              class="btn d-flex align-items-center m-2 btn-add"
            >
              <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
            </CButton>
  
            <CButton
              :disabled="isSubmit"
              class="btn d-flex align-items-center btn-wipe mb-2 mt-2"
              color="wipe"
              @click="closeCollapse"
            >
              <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
            </CButton>
    
        </div>
      </CCardFooter>
    </CCard>
  </CCollapse>
</template>

<script>
import MercanciaValidations from '@/_validations/rubro/mercanciaValidations';
import UpperCase from '@/_validations/uppercase-directive';
import Fuse from 'fuse.js';
import { imgInputMsgs, GenerateImgPlaceholder } from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';

//DATA
function data() {
  return {
    //MODELO
    CommodityId: '',
    HeadingId: '',
    CommodityNameEs: '',
    CommodityNameEn: '',
    HsCode: '',
    CommodityDs: '',
    Density: '',
    CommodityRoute: '',
    //VARIABLES
    ActualizaMercancia: false,
    image: '/img/uploadPhotoEs.svg',
    Loading: false,
    rubros: [],
    isEdit: false,
    showCollapase: false,
    srcError:'/img/errorimage.jpg',
    isSubmit: false,
  };
}
function closeCollapse() {
  this.ActualizaMercancia = false;
  this.$emit('close-collapse');
}

//METHOD
function resetForm() {
  this.CommodityId = '';
  this.HeadingId = null;
  this.CommodityNameEs = null;
  this.CommodityNameEn = null;
  this.HsCode = null;
  this.CommodityDs = null;
  this.Density = '';
  this.CommodityRoute = '';
  this.Status = '';
  this.image = '/img/uploadPhotoEs.svg';
  this.isSubmit = false;
  this.$nextTick(() => {
    this.$v.$reset();
  });
}

function submitFile() {
  if(!this.isEdit){
    if(typeof this.CommodityRoute != 'string'){
      this.Loading = true;
      this.$http
        .file('Commodity-insert', this.CommodityRoute)
        .then((response) => {
          this.CommodityRoute = response.data.data.files[0].path;
          this.submit();
        })
        .catch((err) => {
          this.Loading = false;
          this.$notify({ text: err });
        })
    }else{
      this.submit();
    }
  }else{
    if(this.CommodityRoute == this.OriginCommodityRoute || typeof this.CommodityRoute == 'string'){
      this.submit();
    }else{
      this.Loading = true;
      this.$http
        .file('Commodity-update', this.CommodityRoute, this.OriginCommodityRoute)
        .then((response) => {
          this.CommodityRoute = response.data.data.files[0].path;
          this.submit();
        })
        .catch((err) => {
          this.Loading = false;
          this.$notify({ text: err });
        })
      }
  }
}  

function submit() {
  try {
    this.$v.$touch();
    //if (this.$v.$invalid) return false;
    this.Loading = true;
    this.isSubmit = true;

    if (this.$v.$invalid) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let res = [];
    let metodo = '';
    let ruta = '';
    let CommodityJson = [];

    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.isEdit) {
      CommodityJson = {
        CommodityId: this.CommodityId,
        HeadingId: this.HeadingId.value,
        CommodityNameEs: this.CommodityNameEs,
        CommodityNameEn: this.CommodityNameEn,
        HsCode: this.HsCode,
        CommodityDs: this.CommodityDs,
        Density: this.Density,
        CommodityRoute: this.CommodityRoute,
        Status: this.Status,
      };
      metodo = 'PUT';
      ruta = 'Commodity-update';
    } else {
      CommodityJson = {
        HeadingId: this.HeadingId.value,
        CommodityNameEs: this.CommodityNameEs,
        CommodityNameEn: this.CommodityNameEn,
        HsCode: this.HsCode,
        CommodityDs: this.CommodityDs,
        Density: this.Density,
        CommodityRoute: this.CommodityRoute,
      };
      metodo = 'POST';
      ruta = 'Commodity-insert';
    }
   
    this.$http
      .ejecutar(metodo, ruta, CommodityJson, { root: 'CommodityJson' })
      .then((response) => {
        res = [...response.data.data];
        this.notifySuccess({ text: res[0].Response });
        this.Loading = false;
        this.isSubmit = false;
        this.resetForm();
        this.closeCollapse();
        this.ActualizaMercancia = false;
        this.$emit('refresh-Commodity');
      })
      .catch((err) => {
        this.notifyError({ text: err });
        this.Loading = false;
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }  
}

function handleFileUpload(image) {
  if (image) {
    this.CommodityRoute = this.$refs.imageInput.file;
    this.$v.CommodityRoute.$touch(); 
  }
}

//COMPUTED
function rubOptions(){
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.rubros.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.rubros.map((e)=>{
        if (e.FgActHeading) {
          chart.push({
            value: e.HeadingId,
            label: e['HeadingName'+_lang],
        })
      }    
    })
    return chart;
  }
}

function TitleModal() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let title = this['CommodityName'+_lang]
  return this.isEdit ? this.$t('label.edit')+' '+this.$t('label.commodity')+': '+title : this.$t('label.nueva')+' '+this.$t('label.commodity')
}

function isRubroValid() {
  if(this.$v.HeadingId.$dirty)
    return this.HeadingId == null || this.HeadingId == ''
      ? 'no-valido'
      : 'valido';
  else
    return this.HeadingId != null && this.HeadingId != ''? 'valido': '';
}

export default {
  name: 'mercanciaCollapse',
  mixins: [
    GeneralMixin,
    ModalMixin
  ],
  components: {
    PictureInput,
  },
  data,
  props: {
    modal: null,
    collapse: Boolean,
  },
  directives: UpperCase,
  validations: MercanciaValidations,  
  methods: {
    resetForm,
    submit,
    submitFile,
    handleFileUpload,
    closeCollapse,
  },
  computed: {
    TitleModal,
    rubOptions,
    isRubroValid,
  },
  watch: {
    modal: function() {
      if (this.modal) {
        this.ActualizaMercancia = true;
        let listado = [];
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  
        listado = this.modal.Rubros.map((item) => {
            return {
              ...item,
              HeadingId: item.HeadingId,
              HeadingName: item['HeadingName'+_lang],
            };
        });
      
        this.rubros = listado;
        if (this.modal.Item == true) {
          this.isEdit = false;
          let _lang = this.$i18n.locale;
          this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        } else {
          this.isEdit = true;
          this.CommodityId = this.modal.Item.CommodityId;
          this.HeadingId = this.modal.Item.HeadingId;
          this.CommodityNameEs = this.modal.Item.CommodityNameEs != 'N/A' ? this.modal.Item.CommodityNameEs : '' ;
          this.CommodityNameEn = this.modal.Item.CommodityNameEn != 'N/A' ? this.modal.Item.CommodityNameEn : '' ;
          this.HsCode = this.modal.Item.HsCode,
          this.CommodityDs = this.modal.Item.CommodityDs;
          this.Density = this.modal.Item.Density;
          this.CommodityRoute = this.modal.Item.CommodityRoute;
          this.Status = this.modal.Item.FgActCommodity?1:0;
          if(this.modal.Item.CommodityRoute != null && this.modal.Item.CommodityRoute != ''){
              
              this.image = `${this.$store.getters["connection/getBase"]}${this.modal.Item.CommodityRoute.replace('Public/', '', 'i')}`;
          }else{
            let _lang = this.$i18n.locale;
            this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
          }

          this.HeadingId = {
            label:  this.modal.Item['HeadingName'+_lang],
            value: this.modal.Item.HeadingId,
          };
          this.$v.$touch();
        }
      }else{
        this.ActualizaMercancia = false;
      }
    },
    collapse: function(val) {
      this.showCollapase = val;
    },
    showCollapase: function(val) {
      if(!val){
        this.resetForm();
      }
    }
  },
};
</script>
<style scoped>
.alto {
  height: 200px;
}
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
</style>
