<template>
  <CCard body-wrapper>
    <CRow>
      <CCol sm="10">
        <CRow>
          <CCol sm="12">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              addLabelClasses="required text-right"
              :label="$t('label.typeMovement')"
              :horizontal="{
                label: 'col-sm-12 col-lg-5',
                input: 'col-sm-12 col-lg-7',
              }"
              :options="movementsType"
              :disabled="isEmpty(movementsType)"
              :value.sync="$v.movementInfo.MovementOrderId.$model"
              :invalid-feedback="errorMessage($v.movementInfo.MovementOrderId)"
              :is-valid="hasError($v.movementInfo.MovementOrderId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="12">
            <div class="form-group form-row">
              <label class="required text-right col-sm-12 col-lg-5 mt-2 col-form-label-sm">{{
                $t("label.weight") + " (KG)"
              }}</label>
              <div class="input-group col-sm-12 col-lg-7 input-group-sm mt-2">
                <money
                  addLabelClasses="required"
                  :label="$t('label.weight')"
                  v-model.trim="$v.movementInfo.Weight.$model"
                  :invalid-feedback="errorMessage($v.movementInfo.Weight)"
                  :is-valid="hasError($v.movementInfo.Weight)"
                  v-bind="measure"
                  class="form-control"
                  value="0"
                >
                </money>
                <!-- <div class="invalid-feedback show-feedback" v-if="$v.movementInfo.Weight.$invalid">
                  {{$t('validation.verifyNumber')}}
                </div> -->
              </div>
            </div>
          </CCol>
          <CCol sm="12">
            <CSelect
              size="sm"
              :placeholder="$t('label.select')"
              addLabelClasses="required text-right"
              :label="$t('label.truckScale')"
              :horizontal="{
                label: 'col-sm-12 col-lg-5',
                input: 'col-sm-12 col-lg-7',
              }"
              :options="scales"
              :disabled="isEmpty(scales)"
              :value.sync="$v.movementInfo.TruckWeghingScaleId.$model"
              :invalid-feedback="errorMessage($v.movementInfo.TruckWeghingScaleId)"
              :is-valid="hasError($v.movementInfo.TruckWeghingScaleId)"
            >
            </CSelect>
          </CCol>
          <CCol sm="12">
            <div role="group" class="form-group form-row">
              <label
                class="
                  text-right
                  required
                  col-form-label col-sm-12 col-lg-5 col-form-label-sm
                "
              >
                {{ $t("label.receptionDate") }}
              </label>
              <div class="col-sm-12 col-lg-7 input-group-sm">
                <vue-datepicker
                  :lang="$i18n.locale"
                  v-model="$v.movementInfo.TransactionDate.$model"
                  type="datetime"
                  format="DD/MM/YYYY HH:mm"
                  value-type="format"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  :show-second="false"
                  :clearable="false"
                  popup-class="vuedatapickerespecial"
                  :editable="false"
                  :append-to-body="false"
                  :disabled-date="validationDate"
                  class="col-sm-12 col-lg-12 col-xl-12 px-0 vue-datepicker-drive:focus"
                >
                  <template #input>
                    <CInput
                      :is-valid="hasError($v.movementInfo.TransactionDate)"
                      :invalid-feedback="
                        errorMessage($v.movementInfo.TransactionDate)
                      "
                      v-model.trim="$v.movementInfo.TransactionDate.$model"
                      @keypress="rightDate($event)"
                      class="mb-0"
                      size="sm"
                      placeholder="DD/MM/YYYY HH:mm"
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CCol sm="12" v-if="edit">
            <div>
              <CSelect
                :value.sync="movementInfo.Status"
                :is-valid="statusSelectColor()"
                :horizontal="{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                }"
                size="sm"
                :label="$t('label.status')"
                :options="statusOptions"
                addLabelClasses="text-right"
              />
            </div>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="2" class="d-flex justify-content-end align-items-start">
        <CButtonGroup>
          <CButton color="add" size="sm" shape="square" :disabled="isInvalid" @click="confirmation">
            <CIcon name="checkAlt" />
          </CButton>
          <CButton
            class="ml-1"
            color="wipe"
            size="sm"
            shape="square"
            v-c-tooltip="{
              content: $t('label.clearFields'),
              placement: 'top-end',
            }"
            @click="clearInputs"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>
  </CCard>
</template>
<script>
import MovementFormValidations from "@/_validations/metalscrap/MovementFormValidations";
import { VMoney, Money } from "v-money";
import ModalMixin from "@/_mixins/modal";
import { dateValidationByYearQty } from "@/_validations/funciones";

//DATA
function data() {
  return {
    movementInfo: {
      OrderDetailId: "",
      MovementOrderId: "",
      TruckWeghingScaleId: "",
      TransactionDate: "",
      Weight: "",
      Status: 1,
    },
    oldStatus: "",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  };
}

// METHODS
function isEmpty(arr) {
  return arr.length == 0;
}
function clearInputs() {
  let arrKeys = Object.keys(this.movementInfo);
  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.movementInfo[arrKeys[i]] = 1;
        break;
      default:
        this.movementInfo[arrKeys[i]] = "";
    }
  }
  this.oldStatus = 1;
  this.$v.$reset();
  this.$emit("clear");
}
function submit() {
  try {
    this.$v.movementInfo.$touch();

    if (this.$v.movementInfo.$invalid || this.$v.movementInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }

    if (!this.edit) {
      let newMov = {
        ...this.movementInfo,
        Weight: this.movementInfo.Weight,
      };
      delete newMov.OrderDetailId;

      this.$emit("submit", newMov);
    } else {
      this.$emit("update", {
        ...this.movementInfo,
        Weight: this.movementInfo.Weight,
      });
    }
    this.clearInputs();
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function setForm(item) {
  if (!item) return;

  let arrKeys = Object.keys(this.movementInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.movementInfo.Status = item[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = item[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      case "Weight":
        this.movementInfo[arrKeys[i]] = this.formatNumericValue(item.Weight);
        break;
      default:
        this.movementInfo[arrKeys[i]] = item[arrKeys[i]] ? item[arrKeys[i]] : "";
    }
  }
  this.$v.$touch();
}
function confirmation() {
  if (!this.edit) {
    this.submit();
  } else {
    try {
      this.$v.movementInfo.$touch();
      if (this.$v.movementInfo.$error) {
        throw this.$t("label.errorsPleaseCheck");
      }
      this.statusConfirmation(
        this.oldStatus,
        this.movementInfo.Status,
        this.submit,
        ``
      );
    } catch (e) {
      this.notifyError({ text: e });
    }
  }
}
function statusSelectColor() {
  return this.movementInfo.Status === 1;
}
function formatNumericValue(val) {
  return parseFloat(val.replace(".", "").replace(",", ".")).toFixed(2);
}

function validationDate(date) {
  return dateValidationByYearQty(date);
}

//COMPUTED
function isInvalid() {
  if(this.edit && this.movementInfo.MovementOrderId == this.item.MovementOrderId) return false;

  let found = this.current.find((item) => item.MovementOrderId == this.movementInfo.MovementOrderId);

  if(found) return true;
  else return false;
}

export default {
  name: "mov-inputs",
  mixins: [ModalMixin],
  components: {
    Money,
  },
  data,
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    movementsType: {
      type: Array,
      default: () => [],
    },
    scales: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => null,
    },
    current: {
      type: Array,
      default: () => [],
    },
  },
  validations: MovementFormValidations,
  directives: {},
  watch: {
    edit: function (val) {
      if (val) this.setForm(this.item);
    },
    modalOpen: function (val) {
      if (!val) this.clearInputs();
    },
  },
  methods: {
    isEmpty,
    clearInputs,
    submit,
    setForm,
    confirmation,
    statusSelectColor,
    formatNumericValue,
    dateValidationByYearQty,
    validationDate,
  },
  computed: {
    isInvalid,
  },
};
</script>
<style lang="scss"  scoped>
.show-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}
</style>