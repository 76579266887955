import {
  required,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

import { DateFormater, NumberFormater } from "@/_helpers/funciones";

export default (form, dateRange) => {
  return {
    form: {
      GeneralData: {
        FgTotal: {},
        YardAreaId: {},
        TransactionBegin: { 
          required,
          RangeDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) >= dateRange.GeneralData.PreviousDate &&
              DateFormater.formatDateTimeWithoutSlash(value) <= dateRange.GeneralData.LaterDate
          },
          ValidateDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) < dateRange.GeneralData.TransactionFinish
          }
        },
        TransactionFinish: {
          required,
          RangeDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) >= dateRange.GeneralData.PreviousDate &&
              DateFormater.formatDateTimeWithoutSlash(value) <= dateRange.GeneralData.LaterDate
          },
          ValidateDate(value) {
            return DateFormater.formatDateTimeWithoutSlash(value) > dateRange.GeneralData.TransactionBegin
          }
        },
        EventReasonId: {
          required,
        },
        MachineId: {},
        Observation: {},
        Status: { required },
      },
      Packaging: {
        FgVolumen: {},
        FgSerial: {},
        BlCargoMasterDetailId: { required },
        BlCargoMasterDetailSerialId: form.Packaging.FgSerial ? { required } : {},
        //WeightOrigin: {},
        Quantity: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 ? true : false },
          maxNumberFormater: maxValue(form.Packaging.QuantityOrigin),
        },
        Length: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || !form.Packaging.FgVolumen ? true : false },
        },
        Width: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || !form.Packaging.FgVolumen ? true : false },
        },
        Height: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 || !form.Packaging.FgVolumen ? true : false },
        },
        Volumen: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 ? true : false },
          maxNumberFormater: maxValue(form.Packaging.VolumenOrigin),
        },
        ImplementJson: {},
      },
      DetailJson: form.GeneralData.FgTotal ? {} : { required },
      Implement: {
        GpoImplementId: { required },
        ImplementId: { required },
        Quantity: {
          NotNegative(Value) { return NumberFormater.setNum(Value) < 0 ? false : true },
          required(Value) { return NumberFormater.setNum(Value) > 0 ? true : false },
        },
        Observation: {},
      },
      Document: {
        DocumentDs: { required },
        DocumentRoute: { },
      },
      CustomInspectionDocumentJson: [],
    }
  }
}