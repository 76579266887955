<template>
  <div>
    <CModalExtended
      :title="tituloModal"
      size="lg"
      class="modal-extended"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="$store.state.marcamodelo.modalBrandUpdated"
    >
      
           <CRow>
            <CCol sm="12" lg="12">
              <CCard class="card-simple">
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="5">
                <CRow>
                  <CCol sm="12" lg="12" class="text-center">
                    <picture-input
                      ref="imageInputMarca"
                      :imgSrc="imagen"
                      width="200"
                      height="200"
                      accept="image/jpeg,image/png,image"
                      size="10"
                      :custom-strings="imgInputTexts"
                      @change="handleFileUploadMarca"
                      v-if="$store.state.marcamodelo.modalBrandUpdated"
                    >
                    </picture-input>
                    <div v-if="$v.formMarca.RutaMarca.$error">
                      <div
                        class="text-invalid-feedback text-center"
                        v-if="!$v.formMarca.RutaMarca.isValidFile"
                      >
                        {{$t('label.badImage')}}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" lg="7">
                <CRow>
                  <CCol sm="12" lg="12" class="mt-4">
                    <CInput
                      :label="$t('label.name')"
                      addLabelClasses="required text-right"
                      v-uppercase
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.brandName')"
                      v-model="$v.formMarca.NbMarca.$model"
                      :is-valid="hasError($v.formMarca.NbMarca)"
                      :invalid-feedback="errorMessage($v.formMarca.NbMarca)"
                      required
                      maxLength=20
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="12">
                    <CSelect
                      :label="$t('label.status')"
                      addLabelClasses="required text-right"
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :is-valid="formMarca.FgActMarca"
                      v-model="formMarca.FgActMarca"
                      :value.sync="formMarca.FgActMarca"
                      :options="selectOptions"
                      v-if="
                        !idIndentification($store.state.marcamodelo.idMarca)
                      "
                    />
                  </CCol>
                  <CCol sm="12" lg="12">  <!-- v-model="FgGeneral" -->
                    <div class="form-group form-row">
                      <label class="required col-form-label col-sm-12 col-lg-3 text-right pb-1"> {{$t('label.applyTo')}}</label><br />
                    <div class="col-sm-12 col-lg-9 pt-2">
                      <label class="col-sm-6 px-0">
                        <input type="radio" name="brand" value="1" v-model="formMarca.FgMachine" />&nbsp;{{$t('label.machinery')}}
                      </label>
                      <label class="col-sm-6 px-0 text-right">
                        <input type="radio" name="brand" value="0" v-model="formMarca.FgMachine" />&nbsp;{{$t('label.devices')}}
                      </label>
                      <div class="text-invalid-feedback" v-if="$v.formMarca.FgMachine.$error">
                        {{errorMessage($v.formMarca.FgMachine)}}
                      </div>
                    </div>
                  </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            </CCardBody>
        </CCard>
            </CCol>
           </CRow>
      <CElementCover :opacity="0.8" v-if="apiStateLoading">
        <label class="d-inline">{{$t('label.load')}}... </label>
        <CSpinner size="sm" />
      </CElementCover>
      <template #footer>
          <CButton
            color="add"
            class="d-flex align-items-center"
            @click="IsEdit ? statusConfirmation(originalActMarca, formMarca.FgActMarca?1:0, registerDataMarca) : registerDataMarca()"
            :disabled="apiStateLoading"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>

        <CButton
          color="wipe"
          @click="cerrarModal"
          class="d-flex align-items-center m-2"
          :disabled="apiStateLoading"
        >
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import ENUM from "@/_store/enum";
import { mapState } from "vuex";
import FORMARCAMODELO from "@/_validations/marcamodelo/MarcaModeloValidation";
import UpperCase from "@/_validations/uppercase-directive";
import GeneralMixin from "@/_mixins/general";
import ModalMixin from "@/_mixins/modal";
import { GenerateImgPlaceholder } from "@/_helpers/funciones";
import PictureInput from "@/components/picture-input";
import { maxLength } from 'vuelidate/lib/validators';

//data
function data() {
  return {
    formMarca: {
      NbMarca: "",
      RutaMarca: "",
      FgActMarca: true,
      Image_url: "",
      FgDevice: '',
      FgMachine: '',
    },
    imagen: "/img/uploadPhotoEs.svg",
    originalRutmarca: null,
    IsEdit: false,
    
    originalActMarca: "",

  };
}
//methods
function idIndentification(id) {
  return id === 0;
}
function registerDataMarca() {
  try { 
    this.$v.formMarca.$touch();
    if (this.$v.formMarca.$pending || this.$v.formMarca.$error)
      throw this.$t('label.errorsPleaseCheck');
    if (this.$v.formMarca.$pending || this.$v.formMarca.$error) return;

    if(this.formMarca.FgMachine === '1'){
      this.formMarca.FgMachine = 1;
      this.formMarca.FgDevice = 0;
      } 
    if(this.formMarca.FgMachine === '0'){
      this.formMarca.FgMachine = 0;
      this.formMarca.FgDevice = 1; 
      }

    let IdMarca = this.idMarca;
    let Image_original = this.originalRutmarca;

    const BrandJson = this.IsEdit?{ 
      BrandId:IdMarca,
      BrandRoute:this.formMarca.RutaMarca,
      BrandName:this.formMarca.NbMarca,
      Status: this.formMarca.FgActMarca ? 1 : 0,
      FgDevice:this.formMarca.FgDevice ? 1 : 0, 
      FgMachine:this.formMarca.FgMachine ? 1 : 0,
      serId:JSON.parse(localStorage.getItem('user')).UserId    
    }:{ 
      BrandRoute:this.formMarca.RutaMarca,
      BrandName:this.formMarca.NbMarca,
      FgDevice:this.formMarca.FgDevice ? 1 : 0, 
      FgMachine:this.formMarca.FgMachine ? 1 : 0,
      UserId:JSON.parse(localStorage.getItem('user')).UserId 
    }
    let ruta = this.IsEdit ? 'Brand-update':'Brand-insert';
    let metodo = this.IsEdit ? 'PUT':'POST';
    if(typeof this.formMarca.RutaMarca !== 'string' && this.formMarca.RutaMarca !== null && this.formMarca.RutaMarca !== undefined && this.formMarca.RutaMarca !== ''){
      this.$http.file(ruta, this.formMarca.RutaMarca, Image_original)
        .then((response) => {
          BrandJson.BrandRoute = response.data.data.files[0].path;
          this.submit(BrandJson);
        }).catch((err) => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
        });
    }else{
      this.$http
        .ejecutar( metodo, ruta, BrandJson, { root: 'BrandJson' })
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            this.notifySuccess({text: response.data.data[0].Response});
            this.cerrarModal();
          } 
        }).catch((err) => {
            this.notifyError({text: err});
          })
    }
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function submit(BrandJson) {
  try { 
    let metodo = this.IsEdit ? 'PUT':'POST';
    let ruta = this.IsEdit ? 'Brand-update':'Brand-insert';
    this.$http
      .ejecutar( metodo, ruta, BrandJson, { root: 'BrandJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          this.notifySuccess({text: response.data.data[0].Response});
          this.cerrarModal();
        } 
      }).catch((err) => {
          this.notifyError({text: err});
        })
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function setAltImg(event) {
  event.target.src = this.srcError;
}

async function getInformationMarca() {
  const id = this.idMarca;
  const icon = this.BrandRoute;
  if(icon != null){
    this.imagen = `${this.$store.getters["connection/getBase"]}${icon.replace('http://pruebassegop.softicompany.com/', '', null, 'i')}`;
    this.formMarca.Image_url = this.imagen;
  }else{
    this.imagen = "/img/uploadPhotoEs.svg";
    this.formMarca.Image_url = '';
  }

  this.$store.state.marcamodelo.apiStateForm = ENUM.LOADING;
  this.$http
    .ejecutar("GET", "Brand-by-id", { BrandId: id }, "")
    .then((response) => {
      try {
        if (response.status === 200) {
          const Information = response.data.data;
          if (Information.length !== 0) {

            this.$refs.imageInputMarca.file = null;

            this.formMarca.NbMarca = Information[0].BrandName;
            this.formMarca.RutaMarca = Information[0].BrandRoute;
            this.originalRutmarca = Information[0].BrandRoute;
            this.originalActMarca = Information[0].FgActBrand;
            this.formMarca.FgActMarca = Information[0].FgActBrand;
            this.formMarca.FgMachine = Information[0].FgMachine ? 1 : 0;
            this.$store.state.marcamodelo.apiStateForm = ENUM.INIT;
          }
        } else {
          this.$store.state.marcamodelo.apiStateForm = ENUM.ERROR;
          this.$store.commit("marcamodelo/mutationModalBrand", false);
        }
      } catch (error) {
        this.$store.state.marcamodelo.apiStateForm = ENUM.ERROR;
        this.$store.commit("marcamodelo/mutationModalBrand", false);
        this.$store.commit("marcamodelo/messageMutation", err);
      }
    })
    .catch((err) => {
      this.$store.state.marcamodelo.apiStateForm = ENUM.ERROR;
      this.$store.commit("marcamodelo/mutationModalBrand", false);
      this.$store.commit("marcamodelo/messageMutation", err);
    });
}

function cerrarModal() {
  /*if(this.apiStateLoading)
            return*/
  this.formMarca.NbMarca = "";
  this.formMarca.RutaMarca = "";
  this.formMarca.FgActMarca = true;
  this.imagen = "/img/uploadPhotoEs.svg";
  this.formMarca.FgMachine = '';
  this.formMarca.FgDevice = '';
  this.originalActMarca = "";
  this.originalRutmarca = null;
  this.IsEdit=false;
  this.$emit('set-modal-brand-list'); 
}
function handleFileUploadMarca() {
  this.formMarca.RutaMarca = this.$refs.imageInputMarca.file;
  this.$v.formMarca.RutaMarca.$touch();
}

//computed


function apiStateLoading() {
  return this.apiState === ENUM.LOADING;
}
function apiStateFormLoading() {
  let carga = false;
  if (this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT) {
    carga = !carga;
  }
  return carga;
}

function selectOptions(){
  return [
      {
        value: true,
        label: this.$t('label.ACTIVO'),
      },
      {
        value: false,
        label: this.$t('label.INACTIVO'),
      },
  ]
}

function tituloModal(){
  if(idIndentification(this.$store.state.marcamodelo.idMarca)){
    return /*this.$t('label.register')+' '+*/this.$t('label.nueva')+' '+this.$t('label.brand');
  } else {
    return this.$t('label.edit')+' '+this.$t('label.brand')+': '+this.formMarca.NbMarca;
  } 
}

//watch
function mutationModalBrand(newQuestion, Oldquestion) {
  if (newQuestion === false) {
    this.formMarca.NbMarca = "";
    this.formMarca.RutaMarca = "";
    this.formMarca.FgActMarca = true;
    this.imagen = "/img/uploadPhotoEs.svg";
    this.formMarca.FgMachine = '';
    this.formMarca.FgDevice = '';
    this.originalActMarca = "";
    this.originalRutmarca = null;
    this.IsEdit = false;

    this.$nextTick(() => {
      this.$v.$reset();
    });
    this.$store.commit("marcamodelo/asignarid", 0);
  } else {
    if (this.idMarca !== 0) {
      this.$v.formMarca.$touch();
      this.IsEdit = true;
      this.getInformationMarca();
    }
  }
}

export default {
  name: "modal-brand",
  data,
  mixins: [
    GeneralMixin,
    ModalMixin
  ],
  components: {
    PictureInput,
  },
  validations() {
    return FORMARCAMODELO();
  },
  computed: {
    tituloModal,
    apiStateLoading,
    apiStateFormLoading,
    selectOptions,
    ...mapState({
      idMarca: (state) => state.marcamodelo.idMarca,
      BrandRoute: (state) => state.marcamodelo.BrandRoute,
      mutationModalBrand: (state) => state.marcamodelo.modalBrandUpdated,
      apiState: (state) => state.marcamodelo.apiState,
      apiStateForm: (state) => state.marcamodelo.apiStateForm,
    }),
  },
  directives: UpperCase,
  methods: {
    registerDataMarca,
    submit,
    idIndentification,
    setAltImg,
    cerrarModal,
    getInformationMarca,
    handleFileUploadMarca,
  },
  watch: {
    mutationModalBrand,
    /*
            idState*/
  },
};
</script>

<style lang="scss">
.radioInvalidate{
  color: #e55353;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>
