import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { onlyText } from '../funciones';

export default () => {
  return {
    transaccion: {
      TransacStowageNameEs: { required, onlyText, maxLength: maxLength(100) },
      TransacStowageName: { required, onlyText, maxLength: maxLength(100) },
      MovStowageId: { required }
    },
    selectedMov: { required }
  }
}