<template>
  <div class="c-app">
    <notifications group="container" position="top right" :duration="3000" :max="5" :ignoreDuplicates="true" :closeOnClick="true" class="mt-3 mr-3" />
    <CWrapper>
      <CCollapse :show="true">
        <TheNavBar :navegation = "isYardManagement" />
      </CCollapse>
      <div class="c-body">
        <request-pending>
          <main class="c-main" style="height: 100%;" :class="{ 'pt-0': this.isPortSituation?true: null}">
            <CContainer fluid style="height: 100%;" :class="{ 'pr-0 pl-0': this.isPortSituation?true: null}">
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </CContainer>
          </main>
        </request-pending>
      </div>
      <CCollapse :show="showFooter" :class="this.Footer ? 'Footer-displey-none' : ''">
        <TheFooter/>
      </CCollapse>
    </CWrapper>
  </div>
</template>

<script>
import TheHeader from './TheHeader'
import TheNavBar from './TheNavBar'
import TheFooter from './TheFooter'
import { mapState } from 'vuex';

function data(){ 
  return {
    Footer: false,
  };
}

function showFooter(){ 
  if(this.$route.path != '/situacion-del-puerto'){
    this.Footer = false;
  }else{
    this.Footer = true;
  }
  return !this.isHidden;
}
function isPortSituation() {
  return this.$route.path == '/situacion-del-puerto';
}
function isYardManagement() {
  return this.$route.path.trim() == '/yard-management';
}

export default {
  name: 'TheContainer',
  data,
  components: {
    TheHeader,
    TheNavBar,
    TheFooter
  },
  computed: {
    showFooter,
    isPortSituation,
    isYardManagement,
    ...mapState({
      isHidden: state => state.planificacionestiba.isHidden
    })
  }
}
</script>
<style>
.Footer-displey-none{
  display: none;
}
</style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
