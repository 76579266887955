<template>
    <CRow style="margin-top: 20px">
        <CCol sm="12">
            <CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
                <CTab :title="$t('label.generalList')"  class="form-especial">
                    <asignacion-recurso :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.assignResource')" class="form-especial">
                    <index-recurso :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from "vuex";
    import IndexRecurso from './index-recurso';
    import AsignacionRecurso from './asignacion-recurso';
    //data
    function data() {
        return {
            tabChange: 0,
        }
    }
    //methods
    function handleTab(tab) {
        this.tabChange = tab;
    }
    //computed
    function transferir(){
        return this.tabChange === 0;
    }
    export default {
        name:'tabs-recurso',
        data,
        methods:{
            handleTab
        },
        computed:{
            transferir,
            ...mapState({
                dropItemReport: state => state.visitas.dropItemReport,
            })
        },
        components:{
            IndexRecurso,
            AsignacionRecurso
        },
        watch:{
            dropItemReport(newValue,OldValue){
                if(OldValue === 21){
                    this.tabChange = 0;
                }
            }
        }
    }
</script>