<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-content-class-vehiculo"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow  class="mt-2">
        <CCol sm="12">
          <CSelect
            :label="$t('label.vehicleType')"
            :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
            add-label-classes="required text-right"
            v-uppercase
            :options="computedTpVehicle"
            :value="TpVehicleId"
            @change="selectTpVehiculo($event)"
            :invalid-feedback="errorMessage($v.TpVehicleId)"
            :is-valid="hasError($v.TpVehicleId)"
            v-model.trim="$v.TpVehicleId.$model"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CInput
            :label="$t('label.clasificationName')"
            :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
            add-label-classes="required text-right"
            v-uppercase
            :invalid-feedback="errorMessage($v.VehicleClassifName)"
            :is-valid="hasError($v.VehicleClassifName)"
            v-model.trim="$v.VehicleClassifName.$model"
            :value.sync="newVehicleClasific"
            :placeholder="$t('label.vehicleClasificationName')"
            maxlength="250"
          />
        </CCol>
      </CRow>

      <CRow v-if="editModal">
        <CCol sm="12">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
            add-label-classes="required text-right"
            v-uppercase
            :options="optionsStatus"
            :invalid-feedback="errorMessage($v.Status)"
            :is-valid="statusSelectColor()"
            v-model.trim="$v.Status.$model" 
            :value.sync="Status"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit, vehicleClasific+' '+newVehicleClasific):submit()"

        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
        
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';
import clasifiaccionVehiculo from '@/_validations/chofer-vehiculo/clasifiaccionVehiculo';

function data() {
  return {
    modalActive: false,
    itemsTpVehicle: [],
    loadingOverlay: false,
    loadingTable: false,
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    Status: 1,
    originStatus: '',
    newVehicleClasific: '',
    TpVehicleId: '',
    VehicleClassifName: '',
    VehicleClassifId: '',
    isSubmit: false,  
  };
}
function buttonDisable() {
  if (this.VehicleClassifName === '' || this.VehicleClassifName === null) {
    return true;
  } else {
    return false;
  }
}

function vehicleClasific() {
  return this.$t('label.vehicleClasific');
}
//### Function para cerrar el modal ###
function selectedStatus(event) {
  this.Status = event.target.value;
}

async function mountedTpVehicle() {
  //this.loadingOverlay = true;
  this.loadingOverlay = true;

  await this.$http
    .get('TpVehicle-list?Filter=ACTIVO')
    .then((response) => {
      if (response.data.data.data !== null) {
        this.itemsTpVehicle = response.data.data;
        if (!this.editModal) {
          this.TpVehicleId = this.itemsTpVehicle[0].TpVehicleId;
        }
      }

      this.totalPages = response.data.data.TotalRecords;
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedTpVehicle() {
  if (this.itemsTpVehicle.length > 0) {
    return this.itemsTpVehicle.map((item) => {
      return {
        label: item.TpVehicleName,
        value: item.TpVehicleId,
      };
    });
  }
}

function selectTpVehiculo(event) {
  this.TpVehicleId = event.target.value;
}

//### Function para guardar nueva clase de vehiculo###
async function submit() {
  try {
    //this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      //this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
      
    const VehicleClassificationJson = {
      TpVehicleId: this.TpVehicleId,
      VehicleClassifName: this.VehicleClassifName,
    };

    if(this.editModal){
      VehicleClassificationJson.Status= this.Status;
      VehicleClassificationJson.VehicleClassifId= this.VehicleClassifId;
    };

    let method = this.editModal? 'PUT':'POST';
    let ruta = this.editModal? 'VehicleClassification-update':'VehicleClassification-insert';

    await this.$http
    .ejecutar (method, ruta, VehicleClassificationJson, { root: 'VehicleClassificationJson' })

      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
       //   this.loadingOverlay = false;
          this.toggle(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
      //  this.loadingOverlay = false;
        this.notifyError({ text: e });

      }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
   // this.loadingOverlay = false;
    this.notifyError({ text: e });
  }
}

function resetForm(event) {
  this.VehicleClassifName = '';
  this.TpVehicleId = '';
  this.Status = 1;
  this.openDate = false;
  this.$v.$reset();
  this.$emit('updated-modal', event);
}

export default {
  name: 'modalClassVehiculo',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    classVehicleSelected: Object,
  },
  mixins: [GeneralMixin, ModalMixin],
  directives: UpperCase,
  watch: {
    modal: function(val) {
      this.showModal = val;
      if (val === true) {
        this.mountedTpVehicle();
      }
      if(this.editModal)
        this.$v.$touch();
    },
    classVehicleSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.VehicleClassifId = val.VehicleClassifId;
        this.VehicleClassifName = val.VehicleClassifName;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.TpVehicleId = val.TpVehicleId;

        this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
        this.newVehicleClasific= val.VehicleClassifName;
      }
    },
  },
  methods: {
    mountedTpVehicle,
    selectTpVehiculo,
    selectedStatus,
    submit,
    resetForm,
  },
  validations: clasifiaccionVehiculo,
  computed: {
    computedTpVehicle,
    buttonDisable,
    vehicleClasific,
  },
};
</script>
<style lang="scss">
.modal-content-class-vehiculo {
  .modal-content {
    //width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>
