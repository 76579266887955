<template>
    <div class="container-fluid">
      <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
      <modalAssignment 
        :modal="showModalAssignment" 
        :assignmentItem="assignmentItem"
        :isEdit="isEdit"
        @closeModal="close" 
        @child-refresh="getBLList" 
      />
      
    
      <CTabs variant="tabs" :active-tab="0">
        <CTab :title="$t('label.consigneeAssignment')">
          <div> 
            <CCol sm="12" class="d-flex align-items-center justify-content-end mt-3 mb-2">
              <CButton
                color="excel"
                size="sm"
                @click="onBtnExport(true)"
              >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
              </CButton>
            </CCol>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-3">
              <CButton
                color="add"
                v-c-tooltip="{
                  content: $t('label.consigneeAssignment'),
                  placement: 'top',
                }"
                  @click="showModalAssignment = true"
                  >
                <CIcon name="cil-playlist-add"/>&nbsp; {{ $t('label.nueva') }}
              </CButton>
            </CCol>
            <CRow class="m-0">
              <CCol sm="12" lg="4" >
                <CSelect
                  :horizontal="{label:'col-sm-12 col-lg-5 text-right', input:'col-sm-12 col-lg-7'}"
                  :label="$t('label.consignee')"
                  :options="consigneeOptions"
                  v-model.trim="ClientTpId"
                  :value.sync="ClientTpId"
                  @change="filterConsignee"
                />   
              </CCol>
              <CCol sm="12" lg="3">
                <CSelect
                  :horizontal="{label:'col-sm-12 col-lg-auto text-right', input:'col-sm-12 col-lg-10'}"
                  :label="$t('label.bl')"
                  :options="blOptions"
                  v-model.trim="VisitBillOfLadingId"
                  :value.sync="VisitBillOfLadingId"
                  @change="filterBl"
                />   
              </CCol>
              <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field" style="text-align: right;">
                <CButton
                  color="watch"
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                  @click="getBLList(false)"
                >
                  <CIcon name="cil-search" />
                </CButton>
                <CButton
                  color="wipe"
                  class="justify-content-end"
                  size="sm"
                  v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                  @click="refreshComponent()"
                >
                  <CIcon name="cil-brush-alt" />
                </CButton>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol sm="12" lg="3" xl="3" class="center-field">
                <CInput
                  :label="$t('label.filter')"
                  :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                  :placeholder="$t('label.enterSearch')"
                  @keyup="onQuickFilterChanged($event.target.value)"
                  v-model="search"
                >
                </CInput>
              </CCol>
              <CCol sm="12" lg="5" xl="5">
              </CCol>
              <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                  :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
                  :label="$t('label.recordsPerPage')"
                  @change="onPageSizeChanged"
                  :options="[50,100,500,1000]"
                  required
                  v-model="pageSize"
                >
                </CSelect>
              </CCol>
            </CRow>
          <CCol sm="12" lg="12" xl="12">
            <div class="d-flex bd-highlight">
                <div class="bd-highlight" style="width:50px">
                    <CButton
                        block
                        style="height: 100vh;"
                        shape="square"
                        color="light"
                        @click="ModalColumn = true"
                    >
                        <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                            <CIcon name="cil-cog" class="correct-vertical"/>
                        </div>
                    </CButton>
                </div>
                <div class="flex-grow-1 bd-highlight">
                  <ag-grid-vue
                  v-if="showGrid"
                  style="width: 100%; height: 100vh;"
                  class="ag-theme-alpine"
                  :getRowStyle="getRowStyle"
                  :gridOptions="gridOptions"
                  :defaultColDef="defaultColDef"
                  :localeText="localeText"
                  :columnDefs="columnDefs"
                  :rowData="RowData"
                  :suppressRowClickSelection="true"
                  :groupSelectsChildren="true"
                  :enableRangeSelection="true"
                  :pagination="true"
                  :paginationPageSize="paginationPageSize"
                  :paginationNumberFormatter="paginationNumberFormatter"
                  rowSelection="single"
                  @grid-ready="onGridReady"
                  :tooltipShowDelay="tooltipShowDelay"
                  :tooltipHideDelay="tooltipHideDelay"
                  >
                  </ag-grid-vue>
                </div>
            </div>
          </CCol>
            <ModalRegister
              :modal.sync="ModalColumn"
              :ModalList="columnsOption"
            />
          </div>
        </CTab>   
      </CTabs>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import AgGrid from '@/_mixins/ag-grid';
  import { DateFormater } from '@/_helpers/funciones';
  import { formatMilDecimal } from '@/_validations/validacionEspeciales';
  import modalAssignment from './modal-assignment';
  import ModalRegister from '../stowage/modal-columns';
  import ReportesVisitas from '@/_mixins/reportesVisitas';
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  
  //Data
  function data() {
    return {
      tooltipShowDelay: 0,
      tooltipHideDelay: 2000,
      gridOptions: null,
      paginationPageSize: null,
      paginationNumberFormatter: null,
      defaultColDef: null,
      AllgridApi:null,
      AllgridColumnApi:null,
      gridApi: null,
      columnApi: null,
      loadingOverlay:false,
      rows: [],
      PackagingList: [],
      showCollapseIndex: 0,
      pageSize:'',
      search: '',
      showModalAssignment: false,
      assignmentItem: {},
      isEdit: false,
      BlList: [],
      billOfLandingList: [],
      consigneeList: [],
      ModalColumn:false,
      VisitBillOfLadingId: '',
      ClientTpId: '',
      columnsOption:{
        selectAll:true,
        packaging: true,
        commodity: true,
        cant: true,
        device: true,
        user: true,
        date: true,
        status: true
      }
    };
  }
  //beforeMount
  function beforeMount() {
    this.gridOptions = {
      onCellClicked: (event) => {
        if(event.colDef.colId == 'editAssignment'){
          this.showModalAssignment = true;
          this.assignmentItem = event.data;
          this.isEdit = true;
        }
      },
      onCellMouseOver: (event) => {
        if(event.colDef.colId == 'editAssignment'){
          tippy('#myEditOrderButton', {
            content: this.$t('label.edit')+' '+this.$t('label.consigneeAssignment'),
          })
        }
      },
    };
    this.defaultColDef = {
      editable: false,
      resizable: true,
      flex: 1,
    };
    this.paginationPageSize = 100;
    this.paginationNumberFormatter = (params) => {
      return params.value.toLocaleString();
    };
  }
  //methods 
  function getRowStyle(params) {
    if (params.node.data.Status!="ACTIVO") {
      return { background: '#e0e4e1' };
    }
  }
  
  function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.tooltipShowDelay = 0;
    this.tooltipHideDelay = 2000;
  }
  
  function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
  }
  
  function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
  }
  
  async function getConsigneeList() {
    this.loadingOverlay = true;
    await this.$http
        .get('VisitGeneralCargoConsigneImportdentification-by-VisitId?VisitId='+this.Visit)
            .then(async response => {
            let data =  response.data.data  && response.data.data[0] ? response.data.data[0] : {};
            this.consigneeList = data.ConsigneeJson ?? [];
            this.billOfLandingList = data.BillOfLandingJson ?? [];
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }

  async function filterConsignee(e) {
    this.ClientTpId=e.target.value;
    this.VisitBillOfLadingId = '';
  }

  async function getBillOfLandingList() {
    this.loadingOverlay = true;
    await this.$http
        .get(`VisitGeneralCargoConsigneImportdentification-by-VisitId?VisitId=${this.Visit}`)
            .then(async response => {
            let data =  response.data.data  && response.data.data[0] ? response.data.data[0] : {};
            this.billOfLandingList = data.BillOfLandingJson ?? [];
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }

  async function filterBl(e) {
    this.VisitBillOfLadingId=e.target.value;
  }

  
  async function getBLList() {
    this.loadingOverlay = true;
    await this.$http
        .get('VisitBlImportPackagingIdentification-by-VisitId',{ VisitId: this.Visit, TpClientId: this.ClientTpId, VisitBillOfLadingId: this.VisitBillOfLadingId })
            .then(async response => {
            this.BlList = response.data.data ?? [];
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }

  async function onBtnExport(valor) {
    this.LoadingOverlay = true;
    let rowData = [];
    this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
    if(rowData.length !== 0) {
      let valores = [];
      let currentDate = new Date();
      valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
      valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

      await this.getOrdersExcel(rowData,this.$t('label.consigneeAssignment'),valor, valores, 1);
    }else{
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error"
      });
    }
    this.LoadingOverlay = false;
  }

  function close(){
    this.showModalAssignment = false;
    this.isEdit = false; 
    this.assignmentItem = {};
  }

  async function refreshComponent() {
    this.loadingOverlay = true;
    this.showModalAssignment= false;
    this.search = "";
    this.tooltipShowDelay = 0,
    this.tooltipHideDelay = 2000,
    this.pageSize="";
    this.VisitBillOfLadingId='',
    this.ClientTpId= '',
    this.columnsOption = {
      selectAll:true,
      packaging: true,
      commodity: true,
      cant: true,
      device: true,
      user: true,
      date: true,
      status: true
    }
    this.onQuickFilterChanged(this.search);
    this.gridApi.paginationSetPageSize(parseInt(50));
    await this.getConsigneeList();
    await this.getBLList();
  }
  


  //computed
  function columnDefs(){
    let columnDefs = [
      {
        headerName: "",
        field: 'editAssignment',
        width: 45,
        minWidth: 45,
        maxWidth: 45,
        suppressMovable: true,
        lockPosition: true,
        colId: "editAssignment",
        cellRenderer: params => {
          if(params.value !== ""){
            return  `   
              <Button id="myEditOrderButton" class="btn mr-1 btn-edit btn-sm">                   
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.93 22.93" role="img" class="c-icon" data-v-3be54f06="">
                  <g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1">
                    <path id="_Color" data-name=" Color" d="M4.78,22.93H0V18.15L14.09,4.06l4.77,4.78ZM20.23,7.48h0L15.45,2.7,17.78.37a1.29,1.29,0,0,1,1.8,0l3,3a1.29,1.29,0,0,1,0,1.8L20.23,7.48Z">
                    </path>
                  </g></g>
                </svg>
              </Button>
            `
          }
        },
        cellClass: 'center-cell-especial px-1 gb-cell',
      },
      {
        field: "Nro",
        headerName: '#',
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 70,
        cellClass: 'center-cell-especial gb-cell',
      },
      {
        field: this.$i18n.locale=='en' ? "TpCargoNameEn" : "TpCargoNameEs",
        headerName: this.$t('label.cargoType'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 180,
        cellClass: 'center-cell-especial gb-cell',
      },
      {
        field: "NroBl",
        headerName: this.$t('label.bl'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 200,
        cellClass: 'center-cell-especial gb-cell',
      },
      {
        field: "ClientName",
        headerName: this.$t('label.consignee'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 180,
        cellClass: 'center-cell-especial gb-cell',
      },
    ]

    if(this.columnsOption.packaging){
      columnDefs.push({
        field: this.$i18n.locale=='en' ? "PackagingNameEn" : "PackagingNameEs",
        headerName: this.$t('label.packaging'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 150,
        cellClass: 'center-cell-especial',
      });
    }

    if(this.columnsOption.commodity){
      columnDefs.push({
        field: this.$i18n.locale=='en' ? "CommodityNameEn" : "CommodityNameEs",
        headerName: this.$t('label.commodity'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 150,
        cellClass: 'center-cell-especial',
      });
    }

    if(this.columnsOption.cant){
      columnDefs.push({
        field: "Quantity",
        headerName: this.$t('label.quantity'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 150,
        cellClass: 'center-cell-especial',
        cellRenderer: params => {
          let variable = 0.00;
          if(params.value !== null && params.value !== '' ){    
              let newValue =  Number.parseFloat(params.value).toFixed(2);
              variable = formatMilDecimal(newValue);
          }
          return variable;
        },
      });
    }

    if(this.columnsOption.device){
      columnDefs.push({
        field: "DeviceCode",
        headerName: this.$t('label.device'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 150,
        cellClass: 'center-cell-especial',
      });
    }

    if(this.columnsOption.user){
      columnDefs.push({
        field: "TransaLogin",
        headerName: this.$t('label.user'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell-especial',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 200,
        cellClass: 'center-cell-especial',
      });
    }

    if(this.columnsOption.date){
      columnDefs.push({
        field: "TransaRegDate",
        headerName: this.$t('label.date'), 
        filter: 'agNumberColumnFilter',
        cellClass: 'center-cell-especial',
        lockPosition: true,
        spanHeaderHeight: true,
        minWidth: 220,
      });
    }

    if(this.columnsOption.status){
      columnDefs.push({
        field: "Status",
        headerName: this.$t('label.status'),
        filter: 'agNumberColumnFilter',
        headerClass: 'center-cell',
        suppressMovable: true,
        lockPosition: true,
        minWidth: 150,
        cellStyle: {top: '28%'},
        cellClass: 'center-cell-especial text-color-negrita text-sm',
        cellRenderer: params => {
            let textStatus = '';
            if(params.value == 'ACTIVO' || params.value == 'ACTIVE')
                textStatus = `<h6 style="color:#3c4b64; font-size:75%"><b>&nbsp; ${params.value}</b></h6>`
            if(params.value == 'INACTIVO' || params.value == 'INACTIVE')
                textStatus = `<h6 style="color:#e55353; font-size:75%"><b>&nbsp; ${params.value}</b></h6>`

            return textStatus === '' ? '' : textStatus;
        }
      });
    }

    return columnDefs;
  }
  
  function RowData(){
    if(this.BlList.length != 0){
      return this.BlList.map((item) => {
        return {
          ...item,
          Nro: item.Nro ? item.Nro : '',
          Quantity: item.Quantity ?? 0,
          TransaRegDate : item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
          Status: item.Status == 'ACTIVO' ? this.$t('label.ACTIVO') : this.$t('label.INACTIVO')
        };
      });
    }else{
      return [];
    }
  }

  function consigneeOptions(){
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.consigneeList.map((e) => {
        chart.push({
          value: e.ClientTpId, 
          label: e.ClientName
        });
    });
    return chart;
  }

  function blOptions(){
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    if (this.ClientTpId) {
      this.billOfLandingList.filter(item => item.ClientTpId == this.ClientTpId ).forEach((e) => {
        chart.push({
          value: e.VisitBillOfLadingId, 
          label: e.NroBl
        });
      });
    }else{
      this.billOfLandingList.map((e) => {
        chart.push({
          value: e.VisitBillOfLadingId, 
          label: e.NroBl
        });
      });
    }
   
    return chart;
  }

  export default {
    name: "index-assignment",
    data,
    beforeMount,
    props: {
      currentModuleId: Boolean,
    },
    mixins: [ReportesVisitas, AgGrid],
    methods:{
      getRowStyle,
      onGridReady,
      close,
      onQuickFilterChanged,
      onPageSizeChanged,
      getConsigneeList, 
      filterConsignee,
      getBillOfLandingList,
      filterBl,
      getBLList,
      onBtnExport,
      refreshComponent
    },
    computed:{
      columnDefs,
      RowData,
      consigneeOptions,
      blOptions,
      ...mapState({
        Visit: state => state.visitas.VisitId,
      }),
    },
    components:{
      modalAssignment,
      ModalRegister,
      tippy,
    },
    watch:{
      currentModuleId: async function (newValue) {
        if (newValue) {
          await this.refreshComponent();
        }
      },
    showGrid: function (val) {
      if (val) {
        this.loadingOverlay = false;
      } else {
        this.loadingOverlay = true;
      }
    }
  }
  };
  </script>
  <style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
  <style lang="scss">
  .tippy-box[data-theme~='bluesimple'] {
    fill: #6ca3d7;
    color:#606a87;
  }
  .text-color-negrita {
    font-weight: bold;
  }
  
  /**
  ****************************
  * Tooltip
  ****************************
  */
  .ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom) {
    -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
                    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  }
  
  .ag-theme-alpine .ag-tooltip {
    background-color: #000015;
    background-color: var(--ag-header-background-color, #000015);
    color: #fff;
    color: var(--ag-foreground-color, #fff);
    padding: 6px;
    border: solid 1px;
    border-color: #000015;
    border-color: var(--ag-border-color, #000015);
    border-radius: 3px;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    white-space: normal;
    text-align: center;
    border-radius: 0.25rem;
  }
  
  .ag-theme-alpine .ag-tooltip.ag-tooltip-hiding {
    opacity: 0;
  }
  
  .ag-theme-alpine .ag-tooltip-custom {
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }
  
  .ag-theme-alpine .ag-tooltip-custom.ag-tooltip-hiding {
    opacity: 0;
  }
  
  #vertical-orientation {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
  .correct-vertical{
    transform: rotate(90deg);
  }
  
  .center-cell-especial{
    text-align: center;
    vertical-align: middle;
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
  }
  .gb-cell{
    background-color: rgba(0, 0, 0, 0.07);
    text-align: center;
    vertical-align: middle;
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
  }
  revo-grid {
    height: 100%;
  }
  .ag-font-style {
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
  }
  .titulo-icono{
    margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
  }
  
  </style>