<template>
  <div>   
    <CRow>
      <CCol sm="10" class="mt-3 d-flex justify-content-start px-0">       
        <CSelect
          :label="$t('label.vehicleClasification')+':'"
          :horizontal="{label: 'col-auto', input:'col-sm-6'}"
          add-label-classes="text-left"
          :options="computedClassVehicle"
          :value="computedClassVehicle"
          @change="filterSelectedClass($event)"
          v-model="VehicleClassifId"  
          class="col-sm-9"        
        />
      </CCol>
      <CCol sm="2" class="d-flex justify-content-end align-items-center">
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateChofer"
          v-c-tooltip="{
            content: $t('label.vehicle'),
            placement: 'top-end'
          }"
        >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>        
        </CButton>
      </CCol>
    </CRow> 
    
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedVehicleList"
      sorter    
      :items-per-page-select="tableText.itemsPerPageText"
      :items-per-page="tableText.itemsPerPage"
      :noItemsView="tableText.noItemsViewText"
      :loading="loadingTable"
      hover
      @pagination-change="changePagination($event)"
      :paginator="{ TotalPages: totalPages, TotalRecords: totalRecords, Page: page}"
    >
    
    <template #over-table>
        <div class="row justify-content-start" style="margin-top: -2.8rem">
          <div class="col-auto px-2">
            <label class="p-1 mt-1">{{  $t('label.filter') }}<b>: </b></label>
          </div>
          <div class="col-auto px-0">
            <CInput
              v-model="searchKey"
              :placeholder="$t('label.enterSearch')"             
              v-uppercase
              class="col-sm-12 px-0"
            />
          </div>
          <div class="col-auto px-2">
            <CButton
              color="watch"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.search')+' '+$t('label.vehicle'),
                placement: 'top',
              }" 
              @click="searchAdvanceFilter"
            >
              <CIcon name="cil-search" />
            </CButton>
          </div>
          <div class="col-auto px-0">
            <CButton
              color="edit"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.reset')+$t('label.table'),
                placement: 'top',
              }"       
                @click="resetDataOfTable"
            >
              <CIcon name="cil-loop-circular" />
            </CButton>
          </div>     
        </div>        
      </template>
      <template #loading>
        <loading/>
      </template>
      <template #under-table>
        <div v-if="totalPages > 0" class="ml-3 mb-3">
          <CPagination
            :activePage.sync="page"
            :pages="totalPages"
            size="sm"
            @update:activePage="updatePage($event)"
          />
        </div>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButtonGroup>
            <CButton
              color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.vehicle'),
                placement: 'top-end'
              }"
              @click="toggleShowModalEditVehicle(item)"
            >
              <CIcon name="pencil" />
            </CButton>
          </CButtonGroup>
        </td>
      </template>
    </dataTableExtended>
    
    <modalVehiculo
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="resetDataOfTable"
      :objectVehicle="objectVehicle"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import modalVehiculo from './modal-vehiculo';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    VehicleClassifId:'',
    tpVehicleId: '',
    searchKey: '',
    itemsClassVehicle: [],
    itemsVehiculo: [],
    itemsVehiculoTemporal: [],
    loadingTable: false,
    loadingOverlay: false,
    showModal: false,
    editModal: false,
    titleModal: '',
    page: 1,
    recordPages: 5,
    totalPages: 0,
    totalRecords: 0,
    objectVehicle: {},   
    resetData: false,
  };
}

function fields() {
  return [
    { label: '#', key: 'Nro', filter: false, _style: 'width:1%; text-align:center;',_classes:'text-center center-cell' },
    { label: this.$t('label.vehicleClasification'), key: 'VehicleClassifName', _classes:'text-uppercase text-center center-cell' },    
    { label: this.$t('label.vehiclePlate'), key: 'LicensePlate', _classes:'text-uppercase text-center center-cell'},
    { label: this.$t('label.brand'), key: 'BrandName', _classes:'text-uppercase text-center center-cell' },
    { label: this.$t('label.tpVehicle'), key: 'TpVehicleName', _classes:'text-uppercase text-center center-cell' },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes:'text-uppercase text-center center-cell' },
    { label: this.$t('label.date'), key: 'FormatedDate', _style: 'width:1%;', _classes:'text-center center-cell' },
    { label: this.$t('label.status'), key: 'Status', _style: 'width:1%;', _classes:'text-center center-cell'},
    { key: 'options', label: '', sorter: false, filter: false, _style: 'width:1%;', _classes:'text-center center-cell' },
  ]
}
//### ACtualiza el estado una vez cerrado el modal
function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.VehicleClassifId= '',
  this.editModal = false;
  this.objectVehicle = {};
}

//## Activa el modal para editar
function toggleShowModalEditVehicle(item) {
  this.showModal = true;
  this.objectVehicle = item;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.vehicle')+' '+this.$t('label.plate')+': '+this.objectVehicle.LicensePlate;
  this.editModal = true;
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateChofer() {
  this.showModal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.vehicle');
}

//reinicia el datatable
function resetDataOfTable() {
  this.resetData = true;
  this.page = 1;
  this.searchKey= '',
  this.VehicleClassifId= '',
  this.mountedVehiclesItems(true);
}


//cuando presiono el boton de busqueda
function searchAdvanceFilter() {
    if (this.searchKey.length > 0) {
    this.resetData = true;
    this.page = 1;
    this.mountedVehiclesItems(true);

  } 
}

// hace la consulta de acuerdo a la cantidad de registros por pagina.
async function changePagination(event) {
  this.page = 1;
  this.recordPages = event;
  await this.mountedVehiclesItems(true);
}

//actualiza los registros de la pagina seleccionada 
async function updatePage(event) {
  this.page = event; 
  await this.mountedVehiclesItems(true);
}

//consulta lista de vehiculos
async function mountedVehiclesItems(value = false) {
  this.loadingOverlay = true;
 
  await this.mountedClassVehicle();
  let json = '';
  if (!value) {
    this.resetData = false;
    json = JSON.stringify({ 
      VehicleJson: [{ TpVehicleId: '',  VehicleClassifId: '', SearchKey: '' }],
    });
  } else {

    json = JSON.stringify({
      VehicleJson: [
        { TpVehicleId: '',  VehicleClassifId: this.VehicleClassifId, SearchKey: this.searchKey },
      ],
    });

  }
  this.loadingTable = true;
  await this.$http
    .get(
      `Vehicle-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&VehicleJson=${json}`
    )
    .then((response) => {
      if (response.data.data !== null) {
        this.itemsVehiculo = response.data.data.data;
        this.itemsVehiculoTemporal = response.data.data.data;
        this.totalPages = response.data.data.TotalPages;
        this.totalRecords = response.data.data.TotalRecords;
      } else {
        this.itemsVehiculo=[];
        this.loadingTable = false;
        this.totalPages = 0;
        this.totalRecords = 0;       
      }
      this.loadingOverlay = false;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.notifyError({ text: e, });
    });
  

}

//lista de vehiculos
//### Funcion computed para formatear los datos de la tabla ###
function computedVehicleList() {
  if (this.itemsVehiculo.length > 0) {
    return this.itemsVehiculo.map((item) => {
      return {
        ...item,
        VehicleClassifName: item?.VehicleClassifName??'N/A',
        LicensePlate: item?.LicensePlate??'N/A',
        BrandName: item?.BrandName??'N/A',
        TpVehicleName: item?.TpVehicleName??'N/A',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

//clase de vehiculo 
function filterSelectedClass(event) {
 this.VehicleClassifId = event.target.value;

  if (this.VehicleClassifId === 'N/A') {
    this.VehicleClassifId = '';
  } else {
    this.VehicleClassifId = event.target.value;
  }
  if (this.searchKey.length > 0) {
    this.resetData = true;
    this.page = 1;
  }    
    this.mountedVehiclesItems(true);
}


//clase de vehiculos
async function mountedClassVehicle() {
  //this.loadingOverlay = true;

  await this.$http
    .get('VehicleClassification-list?TpVehicleId')

    .then((response) => {
      if (response.data.data.data !== null) {
        this.itemsClassVehicle = response.data.data;
        const temporalCLass = {
          VehicleClassifName: this.$t('label.select'),
          VehicleClassifId: 'N/A',
        };

        this.itemsClassVehicle = [temporalCLass, ...this.itemsClassVehicle];
      }
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedClassVehicle() {
  if (this.itemsClassVehicle.length > 0) {
    return this.itemsClassVehicle.map((item) => {
      return {
        label: item.VehicleClassifName,
        value: item.VehicleClassifId,
      };
    });
  }
}

export default {
  name: 'vehiculo',
  data,
  mixins: [GeneralMixin, ModalMixin],
  components: {
    modalVehiculo,
  },
  directives: UpperCase,
  methods: {
    toggleShowModalCreateChofer,
    updateCLoseModal,
    mountedClassVehicle,
    mountedVehiclesItems,
    filterSelectedClass,
    toggleShowModalEditVehicle,
    updatePage,
    changePagination,
    resetDataOfTable,
    searchAdvanceFilter,
  },
  mounted: mountedVehiclesItems,
  
  computed: {
    computedVehicleList,
    computedClassVehicle,
    fields,
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
