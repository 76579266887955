<template>
  <div>
    <CModalExtended
      :title="Title"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      @update:show="$emit('Close')"
    >
      <CRow>
        <CCol sm="12" lg="6">
          <CSelect
            v-if="!isEdit"
            v-uppercase
            size="sm"
            :addLabelClasses="`text-right required`"
            :label="$t('label.location')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-model="$v.VesselUbicationId.$model"
            :options="LocationOptions"
            :is-valid="hasError($v.VesselUbicationId)"
            :invalid-feedback="errorMessage($v.VesselUbicationId)"
            @change="(VesselHoldId='',VesselHoldHatchCId='', StowagePlanningBillOfLadingId='', getVesselUbicationDeck(false))"
          />
          <CInput
            v-else
            v-uppercase
            size="sm"
            :label="$t('label.location')"
            :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
            :placeholder="$t('label.location')"
            addLabelClasses="required text-right"
            v-model="VesselUbicationName"
            :is-valid="hasError($v.VesselUbicationId)"
            :invalid-feedback="errorMessage($v.VesselUbicationId)"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="6" v-if="ValidateHold">
          <CSelect
            v-if="!isEdit"
            v-uppercase
            size="sm"
            :addLabelClasses="`text-right required`"
            :label="$t('label.hold')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-model="$v.VesselHoldId.$model"
            :options="HoldOptions"
            :is-valid="hasError($v.VesselHoldId)"
            :invalid-feedback="errorMessage($v.VesselHoldId)"
            @change="getHatch($event.target.value)"
          />
          <CInput
            v-else
            v-uppercase
            size="sm"
            :label="$t('label.hold')"
            :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
            :placeholder="$t('label.hold')"
            addLabelClasses="required text-right"
            v-model="VesselHoldName"
            :is-valid="hasError($v.VesselHoldId)"
            :invalid-feedback="errorMessage($v.VesselHoldId)"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="6" v-if="ValidateHold">
          <CSelect
            v-if="!isEdit"
            v-uppercase
            size="sm"
            :addLabelClasses="`text-right required`"
            :label="$t('label.hatchCover')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-model="$v.VesselHoldHatchCId.$model"
            :options="HatchOptions"
            :is-valid="hasError($v.VesselHoldHatchCId)"
            :invalid-feedback="errorMessage($v.VesselHoldHatchCId)"
            :disabled="!VesselHoldId"
            @change="(StowagePlanningBillOfLadingId='', getVesselUbicationDeck(false))"
          />
          <CInput
            v-else
            v-uppercase
            size="sm"
            :label="$t('label.hatchCover')"
            :horizontal="{ label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6' }"
            :placeholder="$t('label.hatchCover')"
            addLabelClasses="required text-right"
            v-model="HatchCoverName"
            :is-valid="hasError($v.VesselHoldHatchCId)"
            :invalid-feedback="errorMessage($v.VesselHoldHatchCId)"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="6">
          <CSelect
            v-if="!isEdit"
            v-uppercase
            size="sm"
            :addLabelClasses="`text-right required`"
            label="BL"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            v-model="$v.StowagePlanningBillOfLadingId.$model"
            :options="BlOptions"
            :is-valid="hasError($v.StowagePlanningBillOfLadingId)"
            :invalid-feedback="errorMessage($v.StowagePlanningBillOfLadingId)"
            :disabled="(!VesselUbicationId&&!ValidateHold) || (ValidateHold&&!VesselHoldId&&!VesselHoldHatchCId)"
            @change="StowagePlanningBillOfLadingPackagingId = ''"
          />
          <CInput
            v-else
            v-uppercase
            size="sm"
            label="BL"
            :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
            placeholder="BL"
            addLabelClasses="required text-right"
            v-model="NroBl"
            :is-valid="hasError($v.StowagePlanningBillOfLadingId)"
            :invalid-feedback="errorMessage($v.StowagePlanningBillOfLadingId)"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="6" class="mb-3">
          <CSelect
            v-if="!isEdit"
            v-uppercase
            size="sm"
            :addLabelClasses="`required text-right`"
            class="mb-0"
            style="height: 100%;"
            :label="$t('label.detail')"
            :placeholder="$t('label.select')"
            :horizontal="{
              label: `${!$v.StowagePlanningBillOfLadingPackagingId.$error ? 'd-flex align-items-center' : ''} d-flex justify-content-end col-sm-12 col-lg-5`, 
              input: `${!$v.StowagePlanningBillOfLadingPackagingId.$error ? ' d-flex align-items-center' : ''} col-sm-12 col-lg-6`
            }"
            v-model="$v.StowagePlanningBillOfLadingPackagingId.$model"
            :options="PackagingOptions"
            :is-valid="hasError($v.StowagePlanningBillOfLadingPackagingId)"
            :invalid-feedback="errorMessage($v.StowagePlanningBillOfLadingPackagingId)"
            :disabled="!StowagePlanningBillOfLadingId"
          />
          <CInput
            v-else
            v-uppercase
            size="sm"
            :label="$t('label.detail')"
            :horizontal="{
              label: `${!$v.StowagePlanningBillOfLadingPackagingId.$error ? 'd-flex align-items-center' : ''} d-flex justify-content-end col-sm-12 col-lg-5`, 
              input: `${!$v.StowagePlanningBillOfLadingPackagingId.$error ? ' d-flex align-items-center' : ''} col-sm-12 col-lg-6`
            }"
            :placeholder="$t('label.detail')"
            addLabelClasses="required text-right"
            class="mb-0"
            style="height: 100%;"
            v-model="PackagingName"
            :is-valid="hasError($v.StowagePlanningBillOfLadingPackagingId)"
            :invalid-feedback="errorMessage($v.StowagePlanningBillOfLadingPackagingId)"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="6">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{`${$t('label.availabilityByBl')} (TON)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
              <Money
                v-bind="measure"
                class="form-control"
                :value.async="BlData?.AvailableWeight"
                disabled
              >
              </Money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{`${ValidatePackaging ? $t('label.AvailabilityByPackaging')  : $t('label.AvailabilityByDetail')} (TON)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
              <Money
                :value.async="AvailabilityWeight"
                v-bind="measure"
                class="form-control"
                disabled
              >
              </Money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{`${ValidatePackaging ? $t('label.QuantityAvailabilityPerPackaging') : $t('label.QuantityAvailabilityPerDetail')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
              <Money
                :value.async="AvailabilityQuantity"
                v-bind="measure"
                class="form-control"
                disabled
              >
              </Money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <CInput
            size="sm"
            ref="WeightInput"
            :label="$t('label.assignTon')"
            :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
            placeholder="0"
            addLabelClasses="required text-right"
            v-model="WeightAsigned"
            v-money="measure"
            @focus="$v.WeightAsigned.$touch()"
            maxlength="9"
            :is-valid="hasError($v.WeightAsigned)"
          >
            <template #append>
              <CButton
                color="watch"
                size="sm"
                :disabled="ValidateAssignmentButton"
                v-c-tooltip="{
                  content: $t('label.AssignAll'),
                  placement: 'top-end',
                }"
                style="padding: 0.15rem 0.4rem; border-radius: 0.2rem;"
                @click="AssignAll"
              >
                <CIcon name="SelectAll" />
              </CButton>
            </template>
            <template #invalid-feedback>
              <div v-if="$v.WeightAsigned.$error" class="invalid-feedback">
                <p v-if="!$v.WeightAsigned.required" class="mb-0">{{ errorMessage($v.WeightAsigned) }}</p>
                <p v-else-if="!$v.WeightAsigned.notAvailablePackaging" class="mb-0">
                  {{ `${ValidatePackaging ? $t('validation.notAvailablePackaging') : $t('validation.notAvailableDetail')}` }}
                </p>
                <p v-else-if="!$v.WeightAsigned.ValidateTotalPackaging" class="mb-0">
                  {{ `${ValidatePackaging ? $t('validation.WeightAvailabilityPerPackaging') : $t('validation.WeightAvailabilityPerDetail')}` }}
                </p>
              </div>
            </template>
          </CInput>
        </CCol>
        <CCol sm="12" lg="6">
          <CInput
            size="sm"
            ref="QuantityInput"
            :label="$t('label.quantity')"
            :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
            placeholder="0"
            addLabelClasses="required text-right"
            v-model="QuantityAsigned"
            v-money="measure"
            @focus="$v.QuantityAsigned.$touch()"
            maxlength="9"
            :is-valid="hasError($v.QuantityAsigned)"
          >
            <template #invalid-feedback>
              <div v-if="$v.QuantityAsigned.$error" class="invalid-feedback">
                <p v-if="!$v.QuantityAsigned.required" class="mb-0">
                  {{ `${errorMessage($v.QuantityAsigned)}` }}
                </p>
                <p v-else-if="!$v.QuantityAsigned.maxValue" class="mb-0">
                  {{ `${$t('validation.maxValueIs')}: ${PackagingData?.Quantity ?? 0}` }}
                </p>
                <p v-else-if="!$v.QuantityAsigned.ValidateTotalPackaging" class="mb-0">
                  {{ `${ValidatePackaging ? $t('validation.QuantityAvailabilityPerPackaging') : $t('validation.QuantityAvailabilityPerDetail')}` }}
                </p>
              </div>
            </template>
          </CInput>
        </CCol>
        <CCol sm="12" lg="6" v-if="isEdit">
          <div>
            <CSelect
              :value.sync="Status"
              :is-valid="statusSelectColor()"
              :horizontal="{ label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6' }"
              size="sm"
              :label="$t('label.status')"
              :options="statusOptions"
              addLabelClasses="text-right"
            />
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="isEdit ? statusConfirmation(DeckItem.FgActBlDeck, Status, Submit) : Submit()"
        >
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton color="wipe"  @click="$emit('Close')">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { NumberFormater } from "@/_helpers/funciones";
import ModalMixin from '@/_mixins/modal';
import DeckValidations  from "@/_validations/planificacion-estiba/deckValidations";
import { VMoney, Money } from "v-money";

function data() {
  return {
    showModal: false,
    isSubmit: false,
    VesselUbicationId: '',
    VesselUbicationName: '',
    LocationList: [],
    VesselHoldId: '',
    VesselHoldName: '',
    HoldList: [],
    VesselHoldHatchCId: '',
    HatchCoverName: '',
    HatchList: [],
    StowagePlanningBillOfLadingId: '',
    NroBl: '',
    BlList: [],
    StowagePlanningBillOfLadingPackagingId: '',
    PackagingName: '',
    WeightAsigned: 0,
    AdditionalWeight: 0,
    QuantityAsigned: 0,
    AdditionalQuantity: 0,
    Status: 1,
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }
}

//methods
async function getService() {
  this.$emit('Loading', true);
  let requests = [];
  requests = [
    this.$http.get("VesselUbicationDeck-list"),
    this.$http.get('VesselHoldHatch-by-VesselId', { VesselId: this.CurrentItinerary.VesselId}),
  ];
  await Promise.all(requests)
    .then(async(responses) => {
      this.LocationList = responses[0]?.data?.data ?? [];
      this.HoldList = responses[1]?.data?.data ?? [];
      if (this.isEdit) {
        await this.getData(this.DeckItem);
        await this.getVesselUbicationDeck(true);
      }
    })
    .catch((err) => {
      this.LocationList = [];
      this.this.HoldList = [];
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false);
    });
}

async function getVesselUbicationDeck(Loading = false) {
  if ((this.VesselUbicationId&&!this.ValidateHold) || (this.ValidateHold&&this.VesselHoldId&&this.VesselHoldHatchCId)) {
    this.$emit('Loading', true);
    let Parameter = {
      StowagePlanningId: this.planificacionId ?? '',
      VesselUbicationId: this.VesselUbicationId ?? '',
      VesselHoldHatchCId: this.VesselHoldHatchCId ?? '',
      StowagePlanningBlDeckId: this.DeckItem.StowagePlanningBlDeckId ?? '',
    }
    await this.$http.get("StowagePlanningBillOfLadingDeck-list", Parameter)
      .then((response) => {
        this.BlList = response?.data?.data ?? [];
      })
      .catch((err) => {
        this.BlList = [];
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .finally(() => {
        this.$emit('Loading', Loading);
      });
  }else{
    this.BlList = [];
  }
}

function Submit() {
  try {
    this.isSubmit = true;
    this.$emit('Loading', true);
    this.$v.$touch();
    if (this.$v.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let StowagePlanningBlDeckJson = {
      StowagePlanningBillOfLadingId: this.StowagePlanningBillOfLadingId,
      StowagePlanningBillOfLadingPackagingId: this.StowagePlanningBillOfLadingPackagingId,
      VesselUbicationId: this.VesselUbicationId,
      VesselHoldHatchCId: this.VesselHoldHatchCId,
      UnitMeasureWeightBlDeckId: process.env.VUE_APP_UNIT_MEASURE_WEIGHT_ID_TON,
      WeightAsigned: NumberFormater.setNum(this.WeightAsigned),
      QuantityAsigned: NumberFormater.setNum(this.QuantityAsigned),
    }

    if (this.isEdit) {
      StowagePlanningBlDeckJson.StowagePlanningBlDeckId = this.DeckItem.StowagePlanningBlDeckId ?? '';
      StowagePlanningBlDeckJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'StowagePlanningBlDeck-update' : 'StowagePlanningBlDeck-insert';

    this.$http.ejecutar(Method, Route, StowagePlanningBlDeckJson, { root: 'StowagePlanningBlDeckJson' })
      .then(async (response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        await this.$emit('Close');
        await this.Reset();
        this.$emit('Update');
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$emit('Loading', false);
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$emit('Loading', false);
    this.notifyError({text: error});
  }
}

async function getHatch(Id) {
  this.VesselHoldId = Id ?? '';
  this.VesselHoldHatchCId = '';
  if (this.VesselHoldId) {
    let HoldData = this.HoldList.find(item => item.VesselHoldId == this.VesselHoldId);
    this.HatchList = HoldData?.HatchCoverJson ?? [];
  }else{
    this.HatchList = [];
  }
}

function AssignAll() {
  if (!this.ValidateAssignmentButton) {
    this.WeightAsigned = this.PackagingData.Weight ? this.PackagingData.Weight.toFixed(2) : '';
    this.QuantityAsigned = this.PackagingData.Quantity ? this.PackagingData.Quantity.toFixed(2) : '';
    let inputWeight = this.$refs.WeightInput.$el.querySelector("input");
    inputWeight.value = this.WeightAsigned;
    let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
    QuantityInput.value = this.QuantityAsigned;
    this.$v.WeightAsigned.$touch();
    this.$v.QuantityAsigned.$touch();
  }
}

async function getData(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1);
  this.VesselUbicationId = item?.VesselUbicationId ?? '';
  this.VesselUbicationName = item['VesselUbicationName'+_lang] ?? '';
  this.VesselHoldId = item?.VesselHoldId ?? '';
  if (this.VesselHoldId) {
    this.VesselHoldName = item?.VesselHoldName ?? '';
    await this.getHatch(this.VesselHoldId);
  }
  this.VesselHoldHatchCId = item?.VesselHoldHatchCId ?? '';
  this.HatchCoverName = item?.HatchCoverName ?? '';
  this.StowagePlanningBillOfLadingId = item?.StowagePlanningBillOfLadingId ?? '';
  this.NroBl = item?.NroBl ?? '';
  this.StowagePlanningBillOfLadingPackagingId = item?.StowagePlanningBillOfLadingPackagingId ?? '';
  this.PackagingName = item['PackagingName'+_lang] ?? '';
  let WeightInput = this.$refs.WeightInput.$el.querySelector("input");
  WeightInput.value = item?.WeightAsigned ? item?.WeightAsigned.toFixed(2) : 0;
  let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
  QuantityInput.value = item?.QuantityAsigned ? item?.QuantityAsigned.toFixed(2) : 0;
  this.Status = item?.FgActBlDeck ? 1 : 0;
  if (this.Status == 1) {
    this.AdditionalWeight = item?.WeightAsigned ?? 0;
    this.AdditionalQuantity = item?.QuantityAsigned ?? 0;
  }
  this.$v.$touch();
}

function statusSelectColor() {
  return this.Status == 1;
}

async function Reset() {
  this.VesselUbicationId = '';
  this.VesselUbicationName = '';
  this.LocationList = [];
  this.VesselHoldId = '';
  this.VesselHoldName = '';
  this.HoldList = [];
  this.VesselHoldHatchCId = '';
  this.HatchCoverName = '';
  this.HatchList = [];
  this.StowagePlanningBillOfLadingId = '';
  this.NroBl = '';
  this.BlList = [];
  this.StowagePlanningBillOfLadingPackagingId = '';
  this.PackagingName = '';
  let WeightInput = this.$refs.WeightInput.$el.querySelector("input");
  WeightInput.value = 0;
  this.AdditionalWeight = 0;
  let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
  QuantityInput.value = 0;
  this.AdditionalQuantity = 0;
  this.Status = 1;
  this.$v.$reset();
}

//computed
function LocationOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1)
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.LocationList.map(item => {
    chart.push({
      value: item.VesselUbicationId, 
      label: item['VesselUbicationName'+_lang] ?? '',
    })    
  })
  return chart;
}

function HoldOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.HoldList.map(item => {
    chart.push({
      value: item.VesselHoldId, 
      label: item.VesselHoldName,
    })    
  })
  return chart;
}

function HatchOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.HatchList.map(item => {
    chart.push({
      value: item.VesselHoldHatchCId, 
      label: item.HatchCoverName,
    })    
  })
  return chart;
}

function BlOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.BlList.map(item => {
    chart.push({
      value: item.StowagePlanningBillOfLadingId, 
      label: item.NroBl,
    })    
  })
  return chart;
}

function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  let PackagingList = this.BlData?.PackagingJson ?? [];
  PackagingList.map(item => {
    chart.push({
      value: item.StowagePlanningBillOfLadingPackagingId, 
      label: item['PackagingName'+_lang] ?? '',
    })    
  })
  return chart;
}

function Title() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.AssignmentToDeck')}: `
  } else {
    return `${this.$t('label.nueva')} ${this.$t('label.AssignmentToDeck')}`
  }
}

function CurrentItinerary(){
  return this.myDataItinirary.find(item => item.ItineraryId == this.ItineraryId) ?? {};
}

function ValidateHold() {
  if (typeof this.VesselUbicationId == 'string') {
    return this.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VESSEL_UBICATION_ID_DECK_HOLD;
  }else{
    return false;
  }
}

function BlData() {
  return this.BlList.find(item => item.StowagePlanningBillOfLadingId == this.StowagePlanningBillOfLadingId) ?? {};
}

function PackagingData() {
  let Data = this.BlData?.PackagingJson ?? [];
  return Data.find(item => item.StowagePlanningBillOfLadingPackagingId == this.StowagePlanningBillOfLadingPackagingId) ?? {};
}

function ValidatePackaging() {
  return this.PackagingData?.PackagingId?.toUpperCase() != process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED;
}

function AvailabilityWeight() {
  return (this.PackagingData?.Weight ?? 0) + (this.AdditionalWeight ?? 0);
}

function AvailabilityQuantity() {
  return (this.PackagingData?.Quantity ?? 0) + (this.AdditionalQuantity ?? 0);
}

function ValidateAssignmentButton() {
  return this.StowagePlanningBillOfLadingPackagingId ? false : true;
}

function FgTotalWeight() {
  return NumberFormater.setNum(this.WeightAsigned) == this.PackagingData?.Weight;
}

function FgTotalQuantity() {
  return NumberFormater.setNum(this.QuantityAsigned) == this.PackagingData?.Quantity;
}

export default {
  name: 'deck-modal',
  components: {
    Money,
  },
  data,
  props: {
    modal: Boolean,
    isEdit: Boolean,
    DeckItem: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [ModalMixin],
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener('input', (e) => {
          e.target.value = e.target.value.toUpperCase()
          vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
        });
      }
    }, 
    money: VMoney
  },
  validations(){
    return DeckValidations(this.AvailabilityWeight, this.AvailabilityQuantity, this.ValidateHold, this.FgTotalWeight, this.FgTotalQuantity);
  },
  methods: {
    getService,
    getVesselUbicationDeck,
    Submit,
    getHatch,
    AssignAll,
    getData,
    statusSelectColor,
    Reset,
  },
	computed:{
    LocationOptions,
    HoldOptions,
    HatchOptions,
    BlOptions,
    PackagingOptions,
    Title,
    CurrentItinerary,
    ValidateHold,
    BlData,
    PackagingData,
    ValidatePackaging,
    AvailabilityWeight,
    AvailabilityQuantity,
    ValidateAssignmentButton,
    FgTotalWeight,
    FgTotalQuantity,
    ...mapState({
      branch: state => state.auth.branch,
      planificacionId: state => state.planificacionestiba.planificacionId,
      ItineraryId: state => state.planificacionestiba.ItineraryId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
    }),
	},
  watch: {
    modal: async function(Newval){
			this.showModal = Newval;
      if (Newval) {
        this.getService();
      }else{
        this.Reset();
      }
    },
  },
};
</script>