<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="cerrarModal()"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-extended"
    >     
      <CRow>
        <CCol sm="12" lg="12">
          <CRow>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-3 col-form-label-sm mb-0">{{$t('label.supplier')}}</label><br />
                <div class="input-group col-sm-12 col-lg-9">
                  <v-select 
                    id="v-select-small"
                    :filter="fuseSearchProveedor"
                    :options="proveedorOptions"
                    v-model="proveedorId"
                    :placeholder="$t('label.select')"
                    :class="computedSelect(proveedorId)"
                    :clearable="false"
                  />
                  <div class="input-group-append" id="button-addon4">
                    <div class="d-flex align-items-start">
                      <CButton
                        color="add"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.nuevo')+$t('label.supplier'),
                          placement: 'top-end'
                        }"
                        @click="habilitaCollapse(2)"
                      >
                        <CIcon name="cil-playlist-add" />
                      </CButton>  
                    </div>
                  </div>
                  <div class="col-sm-12" v-if="$v.proveedorId.$error || (proveedorId.value == '' && verifySelect == true)">
                    <div class="text-invalid-feedback">
                      {{$t('label.required')}}
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-4 col-form-label-sm mb-0">{{$t('label.supplie')}}</label>
                <div class="input-group col-sm-12 col-lg-8">
                  <v-select 
                    id="v-select-small"
                    :filter="fuseSearchSupplie"
                    :options="supplieOptions"
                    v-model="supplieId"
                    :placeholder="$t('label.select')"
                    :class="computedSelect(supplieId)"
                    :clearable="false"
                  />
                  <div class="input-group-append" id="button-addon1">
                    <div class="d-flex align-items-start">
                      <CButton
                        color="add"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.nuevo')+$t('label.supplie'),
                          placement: 'top-end'
                        }"
                        @click="habilitaCollapse(1)"
                      >
                        <CIcon name="cil-playlist-add" />
                      </CButton>  
                    </div>
                  </div>
                  <div class="col-sm-12" v-if="$v.supplieId.$error || (supplieId.value == '' && verifySelect == true)">
                    <div class="text-invalid-feedback">
                      {{$t('label.required')}}
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" class="d-flex justify-content-center" v-if="collapseInt==1">
              <CCol sm="12" lg="10">
                <CCollapse :show="collapse">
                  <AddInsumo :value="newUnit" @child-refresh="habilitaCollapse(1)" />
                </CCollapse>
              </CCol>
            </CCol>
            <CCol sm="12" class="d-flex justify-content-center" v-if="collapseInt==2">
              <CCol sm="12" lg="10">
                <CCollapse :show="collapse">
                  <AddProveedor :value="newUnit" @child-refresh="habilitaCollapse(2)" />
                </CCollapse>
              </CCol>
            </CCol>

            <CCol sm="12" lg="6" v-if="(collapseInt!=1 && collapseInt!=2)">
              <div class="form-group form-row ">
                <label class="required text-right col-sm-12 col-lg-3 col-form-label-sm">{{$t('label.date')}}</label><br />
                <div class="input-group-sm col-sm-12 col-lg-9">
                  <!--vue-datepicker 
                    valueType="format"
                    :lang="this.$i18n.locale"
                    :disabled-date="fechaNoValida"
                    :editable="false"
                    :clearable="false"
                    :is-valid="hasError($v.fecha)"
                    :invalid-feedback="$t('label.required')"
                    format="DD/MM/YYYY"
                    v-model.trim="$v.fecha.$model"
                    :append-to-body="false"
                    :class="isFechaValida(fechaVal)" 
                    @blur="fechaVal=true"
                  >
                  </vue-datepicker-->
                  <vue-datepicker
                        :lang="$i18n.locale"
                        v-model="fecha"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="format"
                        placeholder="DD/MM/YYYY"
                        time-title-format="DD/MM/YYYY"
                        :show-second="false"
                        :clearable="false"
                        :disabled-date="fechaNoValida"
                        :editable="false"
                        :append-to-body="false"
                    >
                      <template #input>
                          <CInput
                            class="mb-0"
                            v-uppercase
                            placeholder="dd/mm/aaaa"
                            :invalid-feedback="errorMessage($v.fecha)"
                            :is-valid="hasError($v.fecha)"
                            v-model.trim="$v.fecha.$model"
                            @keypress="rightDate($event)"
                            size="sm"
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                    </vue-datepicker>
                </div>
              </div>


              <!--:default-value="fecha"-->
            </CCol>
            <CCol sm="12" lg="6" v-if="(collapseInt!=1 && collapseInt!=2)">
              <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-4 col-form-label-sm mb-0">{{$t('label.measureUnit')}}</label>
                <div class="input-group col-sm-12 col-lg-8">
                  <v-select 
                    id="v-select-small"
                    :filter="fuseSearchMeasureUnit"
                    :options="measureUnitOptions"
                    v-model="measureUnitId"
                    :placeholder="$t('label.select')"
                    :class="computedSelect(measureUnitId)"
                    :clearable="false"
                    :disabled="supplieId==''||supplieId.value==''"
                  />
                  <div class="input-group-append" id="button-addon2">
                    <div class="d-flex align-items-start">
                      <CButton
                        shape="square"
                        color="add"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.nueva')+$t('label.measureUnit'),
                          placement: 'top-end'
                        }"
                        @click="habilitaCollapse(3)"
                      >
                        <CIcon name="cil-playlist-add" />
                      </CButton>  
                    </div>   
                  </div>
                  <div class="col-sm-12" v-if="$v.measureUnitId.$error || (measureUnitId.value == '' && verifySelect == true)">
                    <div class="text-invalid-feedback">
                      {{$t('label.required')}}
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" class="d-flex justify-content-center" v-if="collapseInt==3">
              <CCol sm="12" lg="10">
                <CCollapse :show="collapse">
                  <AddUnidadMedida :value="newUnit" @child-refresh="habilitaCollapse(3)" />
                </CCollapse>
              </CCol>
            </CCol>
            <CCol sm="12" lg="6" v-if="(collapseInt!=1 && collapseInt!=2 && collapseInt!=3)">
              <div class="form-group form-row">
                <label class="required text-right col-sm-12 col-lg-3 col-form-label-sm">{{$t('label.quantity')}}</label>
                <div class="input-group input-group-sm col-sm-12 col-lg-9">
                  <money 
                    :label="$t('label.quantity')"
                    v-uppercase
                    v-model.trim="$v.quantity.$model"
                    v-bind="money" 
                    :class="ValidateFormControl(quantity)" 
                    value="0"
                    
                    >
                  </money>
                  <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(ReverseFormat(quantity) < 0)">
                    {{ $t('label.ValueNotNegative') }}
                  </CCol>
                  <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((quantity == 0 || quantity == '0,00') && valid==true)">
                    {{$t('label.required')}}
                  </CCol>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6" v-if="(collapseInt!=1 && collapseInt!=2 && collapseInt!=3)">
              <CTextarea
                :label="$t('label.observation')"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                v-model.trim="$v.observation.$model"
                :is-valid="hasError($v.observation)"
                :invalid-feedback="errorMessage($v.observation)"
                v-uppercase
                size="sm"
                maxlength="250"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add" @click="guardar">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="cerrarModal">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import moment from 'moment';
import UpperCase  from '@/_validations/uppercase-directive';
import SupplyValidations from '@/_validations/planificacion-estiba/supplyValidations';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import mixinServicio from '@/_mixins/servicio';
import { DateFormater, alertPropertiesHelpers, NumberFormater } from '@/_helpers/funciones';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import { Money } from 'v-money';
import Fuse from "fuse.js";

import AddUnidadMedida from '@/pages/unidadmedida/unidadmedida';
import AddInsumo from './add-insumo';
import AddProveedor from '@/pages/cliente/add-cliente';


function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:3%; text-align:center;', filter: false
    },
    { key: 'ClientName', label:this.$t('label.supplier'), _style: 'width: 15%;' },
    { key: 'SupplyName', label:this.$t('label.Supplies'), _style: 'width: 15%;' },
    { key: 'UnitMeasureName', label:this.$t('label.MeasurementUnit'), _style: 'width: 15%;' },
    { key: 'TotalFormat', label:this.$t('label.quantity'), _style: 'width: 10%;' },
    { key: 'Fecha', label:this.$t('label.date'), _style: 'width: 10%;', _classes: 'text-center', },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
       _style: 'min-width:135px; width:3%; text-align:center;'
    }
  ];
}

function habilitaCollapse(valor) {
  if (this.collapse) {
    if(this.collapseInt == valor){
      switch(valor) {
        case 1:
          this.listarSupplies();
          break;
        case 2:
          this.listarProveedores();
          break;
        case 3:
          this.listarUM();
          break;
      }
      this.collapseInt = 0;
      this.collapse = false;
    }else{
      this.collapseInt = valor;
    }
  } else {
    this.collapseInt = valor;
    this.collapse = true;
  }  
}

async function listarProveedores () {
  this.loadingOverlay = true;
  
  await this.$http.get("Client-list-by-activity", { TpClientId: 'ED88125A-CD80-4C72-9A5F-90BAED4600C7', Filter: 'ACTIVO' })
  .then(response => {
    this.proveedores = [...response.data.data];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function listarUM () {
  this.loadingOverlay = true;
  
  await this.$http.get("UnitMeasurelist-by-SupplyId", { SupplyId: this.supplieId==''?this.supplieId:this.supplieId.value})
  .then(response => {
    this.measureUnits = [...response.data.data];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function listarSupplies () {
  this.loadingOverlay = true;
  
  let listado = [];
  await this.$http.get("Supply-list", { GpoSupplyId: '', Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data]
    //if (this.evaluador) listado = listado.filter(ob => ob.SupplyId == this.SupplyId);
    this.supplies = listado;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function StowagePlanningById () {
  this.loadingOverlay = true;
  
  let listado = Array;
  await this.$http.get("StowagePlanning-by-id", { StowagePlanningId: this.StowagePlanningId })
  .then(response => {
    listado = [...response.data.data];
    this.arrival = listado[0].Eta ? DateFormater.formatDateWithoutSlash(listado[0].Eta) : DateFormater.formatDateWithoutSlash(listado[0].Ata);
    this.departure = listado[0].Etd ? DateFormater.formatDateWithoutSlash(listado[0].Etd) : DateFormater.formatDateWithoutSlash(listado[0].Atd);
    this.fechaAnterior  = new Date(this.arrival);
    //this.fecha = listado[0].Eta ? DateFormater.formatOnlyDateWithSlash(listado[0].Eta) : DateFormater.formatOnlyDateWithSlash(listado[0].Ata);
    let timezone_offset_minutes = new Date(this.departure).getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
    let horas = timezone_offset_minutes/60;
    let nuevo = new Date(this.departure);

    if (horas < 0) nuevo.setHours(nuevo.getHours() + (horas*-1));
    this.fechaSiguiente = nuevo;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function fechaNoValida(date) {
    return date < this.fechaAnterior || date > this.fechaSiguiente;
}

async function StowagePlanningList () {
  this.loadingTable = true;
  this.items = [];
  let listado = Array;
  await this.$http.get("StowagePlanningSupplyDetail-list", { StowagePlanningId: this.StowagePlanningId })
  .then(response => {
    listado = [...response.data.data];
    if (this.evaluador) listado = listado.filter(ob => ob.SupplyId == this.SupplyId);
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: listado.Nro,
      SupplyName: listado.SupplyName,
      ClientName: listado.ClientName,
      //Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Fecha: listado.DatePlanning ? DateFormater.formatOnlyDateWithSlash(listado.DatePlanning) : 'N/A',
      UnitMeasureName: listado.UnitMeasureName, 
      Quantity: listado.Quantity,
      TotalFormat: NumberFormater.formatNumber(listado.Quantity, 2),
      GpoSupplyId: listado.GpoSupplyId,
      UnitMeasureId: listado.UnitMeasureId,
      UnitMeasureAbbrev: listado.UnitMeasureAbbrev,
      StowagePlanningResourceId: listado.StowagePlanningResourceId ? listado.StowagePlanningResourceId : '',
      SupplyId: listado.SupplyId,
      ClientId: listado.ClientId,
      ClientTpId: listado.ClientTpId,
      Observation: listado.Observation ? listado.Observation : '',
      _toggled: false,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingTable = false;
  });
}

function checkNumeros($event) {
    this.evaluador = true;
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 188) {
        $event.preventDefault();
    }
}

function numberFormat() {
  if (this.quantity.indexOf(",") != -1) this.quantity = this.quantity.replaceAll(".", "").replace(",", ".");
  
  this.quantity = parseFloat(this.quantity)
                        //.replace(/([0-9])([0-9]{2})$/, '$1.$2')
                        .toFixed(2)
                        .replace(".",",")
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  if ((this.quantity == "0.00") || (this.quantity == "NaN")) this.quantity = '';
}

function editDatos(item) {
    this.StowagePlanningResourceId = item.StowagePlanningResourceId;
    this.flag = true;
    this.valid = true;
    this.supplieId = {
      value: item.SupplyId,
      label: item.SupplyName
    }
    this.SupplyName = item.SupplyName;
    this.proveedorId = {
      value: item.ClientTpId,
      label: item.ClientName
    }
    this.measureUnitId = {
      value: item.UnitMeasureId,
      label: item.UnitMeasureAbbrev
    } 
    this.Status = 1;
    this.observation = item.Observation;
    /*let fecha1 = item.Fecha.split("/");
    fecha1 = fecha1[2]+"-"+fecha1[1]+"-"+fecha1[0];
    fecha1 = new Date(fecha1);
    if (this.fechaSiguiente < fecha1) {
      this.fecha = DateFormater.formatOnlyDateWithSlash(this.fechaSiguiente);
    } else {
      fecha1 = fecha1.setDate(fecha1.getDate() + 1);
      this.fecha = DateFormater.formatOnlyDateWithSlash(fecha1);
    }*/
    this.fecha = item.Fecha;  
    this.quantity = item.Quantity;//.replaceAll(".", "").replace(",", ".");
    //this.numberFormat();
    this.actualizar = true;
    //this.fechaVal = true;
    this.valid = true;
    this.$v.$touch();
    this.$nextTick(() => { this.$v.$touch() })
}

function limpiarDatos() {
    this.SupplyName = '';
    this.actualizar = false;
    this.verifySelect = false;
    this.valid = false;
    this.StowagePlanningResourceId = '';
    if (!this.evaluador) this.supplieId = '';
    this.evaluador = false;
    this.measureUnitId = '';
    this.proveedorId = '';
    this.observation = '';
    this.Status = 1;
    //this.fecha = new Date();
    this.fecha = '';
    this.quantity = 0;
    //this.fechaVal = false;
    this.supplieId = '';
    this.$v.$reset();
    this.$nextTick(() => { this.$v.$reset() })
}

function guardar () {
  try{
    this.isSubmit = true;
    this.loadingOverlay = true;
    this.verifySelect = true;
    this.valid = true;
    this.quantity = this.ReverseFormat(this.quantity);
    this.measureUnitId = this.measureUnitId.value=='' ? '' : this.measureUnitId;
    this.proveedorId = this.proveedorId.value=='' ? '' : this.proveedorId;
    this.supplieId = this.supplieId.value=='' ? '' : this.supplieId;
    let Min = (this.quantity <= 0); 
    this.$v.$touch();
    if (this.$v.$error || Min) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let StowagePlanningSupplyJson    = [];
    let metodo      = '';
    let ruta        = '';
    let res         = [];
    let fechaCorrecta = moment(this.fecha, "DD/MM/YYYY").format('YYYY-MM-DD');

    if (this.actualizar) {
      StowagePlanningSupplyJson = {
        StowagePlanningResourceId: this.StowagePlanningResourceId,
        StowagePlanningId: this.StowagePlanningId,
        ClientTpId: this.proveedorId.value,
        SupplyId: this.supplieId.value,
        DatePlanning: fechaCorrecta,
        UnitMeasureId: this.measureUnitId.value,
        Quantity: this.quantity,
        Observation: this.observation,
        Status: this.Status
      };
      metodo = 'PUT';
      ruta = "StowagePlanningSupply-update";
    } else {
      StowagePlanningSupplyJson = {
        StowagePlanningId: this.StowagePlanningId,
        ClientTpId: this.proveedorId.value,
        SupplyId: this.supplieId.value,
        DatePlanning: fechaCorrecta,
        UnitMeasureId: this.measureUnitId.value,
        Quantity: this.quantity,
        Observation: this.observation,
      };
      metodo = 'POST';
      ruta = "StowagePlanningSupply-insert";
    }
    
    this.$http.ejecutar(metodo, ruta, StowagePlanningSupplyJson, { root: 'StowagePlanningSupplyJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.isSubmit = false;
      this.loadingOverlay= false;
      this.cerrarModal();
      this.limpiarDatos();
      this.StowagePlanningList();
      this.$emit('child-refresh', true);

    }).catch((err) => {
      this.isSubmit = false;
      this.loadingOverlay= false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
  }catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
}

function ConfirmarEliminado(item) {
  this.$swal.fire(
    alertPropertiesHelpers(this, {
      text:  `${this.$t('label.changeStatusQuestion')} ${item.Nro}?`,
    })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let StowagePlanningSupplyJson = [];

      StowagePlanningSupplyJson = {
        StowagePlanningResourceId: item.StowagePlanningResourceId,
        StowagePlanningId: this.StowagePlanningId,
        ClientTpId: item.ClientTpId,
        SupplyId: item.SupplyId,
        DatePlanning: item.Fecha,
        UnitMeasureId: item.UnitMeasureId,
        Quantity: item.Quantity,
        Observation: item.observation,
        Status: 0
      };

      this.$http.put("StowagePlanningSupply-update", StowagePlanningSupplyJson, { root: 'StowagePlanningSupplyJson' })
      .then(response => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.limpiarDatos();
        this.StowagePlanningList();
        this.$emit('child-refresh', true);
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      });
    }
  });  
}

function cerrarModal() {
  //this.fechaVal = false;
  this.collapse = false;
  this.collapseInt = 0;
  this.actualizar = false;
  this.limpiarDatos();
  this.$emit('child-refresh', true);
  this.showModal=false;
}
//BUSQUEDA
function fuseSearchSupplie(options, search) {
  const fuse = new Fuse(options, {
    keys: ["label"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchMeasureUnit(options, search) {
  const fuse = new Fuse(options, {
    keys: ["label"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchProveedor(options, search) {
  const fuse = new Fuse(options, {
    keys: ["label"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function computedSelect(value) {
  if (value.value === '' || value.value === null || value === '' || value === null) {
    if(this.verifySelect==false){
      return 'mx-0 px-0 select-adjust';
    }else{
      return 'select-client--error mx-0 px-0 select-adjust';
    }
  } else {
    return 'select-client--correct mx-0 px-0 select-adjust';
  }
}

/*function isFechaValida(fechaVal) {
  if(this.fechaVal){ 
    return (this.fecha == null || this.fecha == "") ? 'no-valido' : 'valido';
  }
}*/

function data() {
  return {
    flag: false,
    SupplyName: false,
    verifySelect: false,
    valid: false,
    isSubmit: false,
    showModal: false,
    actualizar: false,
    evaluador: false,
    loadingOverlay: false,
    loadingTable: false,
    items: [],
    supplies: [],
    proveedores: [],
    measureUnits: [],
    tituloCollapse: '',
    collapse: false,
    collapseInt: 0,
    newUnit: false,
    obCollapse: false,
    fechaAnterior: new Date(),
    fechaSiguiente: new Date(),
    //MODELO
    StowagePlanningId: '',
    StowagePlanningResourceId: '',
    supplieId: '',
    measureUnitId: '',
    proveedorId: '',
    Status: 1,
   // fecha: new Date(),
    fecha: '',
    quantity: '',
    observation: '',
    ClientTpId: '',
    SupplyId: '',
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false
    },
    //fechaVal: false, 
    arrival: '',
    departure: '',
  };
}
function ReverseFormat(number) {
  return NumberFormater.setNum(number);
}
function ValidateFormControl(value) { 
  value = this.ReverseFormat(value);
  if ((((value == 0 || value == '0,0000') && this.valid==true) || value < 0 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}
//COMPUTED
function titleModal() {
  return !this.actualizar ? this.$t('label.assign')+' '+this.$t('label.supplie') : this.$t('label.edit')+' '+this.$t('label.supplie')+': '+this.SupplyName;
}
function optionsStatus() {
  return [
    { label: this.$t('label.ACTIVO'), value: 1 },
    { label: this.$t('label.INACTIVO'), value: 0 },
  ];
}
function supplieOptions() {
  if(this.supplies.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.supplies.map(function(e){
      chart.push({
        value: e.SupplyId, 
        label: e.SupplyName,
      })    
    })
    return chart;
  }
}

function measureUnitOptions() {
  if(this.measureUnits.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.measureUnits.map(function(e){
      chart.push({
        value: e.UnitMeasureId, 
        label: e.UnitMeasureAbbrev,
      })    
    })
    return chart;
  }
}

function proveedorOptions() {
  if(this.proveedores.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.proveedores.map(function(e){
      chart.push({
        value: e.ClientTpId, 
        label: e.ClientName,
      })    
    })
    return chart;
  }
}

export default {
  name: 'modal-insumo',
  data,
  props: {
    modal: null,
  },
  mixins: [General, mixinServicio, ModalMixin],
  directives: UpperCase,
  validations: SupplyValidations,
  components: {
    AddUnidadMedida,
    AddInsumo,
    AddProveedor,
    Money
  },
  watch: {
    modal: async function () {
      if (this.modal.item!=undefined) {
        this.limpiarDatos();
        this.StowagePlanningId = this.modal.StowagePlanningId;
        this.listarProveedores();
        this.StowagePlanningById();
        this.listarSupplies();
        await this.StowagePlanningList();

        if (this.modal.item==true) {          
          this.evaluador = false;
        } else {
          //this.evaluador = true;
          //this.fechaVal = true;
          let listado = this.items.filter(ob => (ob.StowagePlanningResourceId == this.modal.item.StowagePlanningResourceId))
          if (listado.length > 0) this.editDatos(listado[0])
        }
        
        this.showModal = true;        
        this.$emit('cerrarModal');
      }
    },
    supplieId: async function(val) {
      if (val.value!='' && val!='') {
        if (this.flag) {
          this.flag = false;  
        }else{
          this.measureUnitId = '';
        }
        this.listarUM();
      } else {
        this.measureUnitId = '';
      }
    }
  },
  methods: {
    computedSelect,
    ValidateFormControl,
    ReverseFormat,
    habilitaCollapse,
    listarProveedores,
    listarUM,
    listarSupplies,
    fechaNoValida,
    StowagePlanningById,
    StowagePlanningList,
    numberFormat,
    checkNumeros,
    limpiarDatos,
    guardar,
    ConfirmarEliminado,
    editDatos,
    cerrarModal,
    fuseSearchSupplie,
    fuseSearchMeasureUnit,
    fuseSearchProveedor,
    //isFechaValida,
  },
  computed: {
    titleModal,
    fields,
    optionsStatus,
    supplieOptions,
    measureUnitOptions,
    proveedorOptions,
  },
};
</script>
<style lang="scss" scope>
  .w-80 {
    width: 80% !important;
  }
  .center-cell {
  text-align: center;
  }
 
</style>
<style lang="scss">
  .select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }
</style>
