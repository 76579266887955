<template>
  <div>
    <CRow class="justify-content-center mt-3">
      <CCol sm="12" lg="5">
        <CSelect
          size="sm"
          :label="$t('label.consignee')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          addLabelClasses="required text-right"
          v-model.trim="vForm.consignee.ConsigneeId.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeId)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeId)"
          class="remove-space"
          :options="consigneeOptions"
          :value.sync="form.consignee.ConsigneeId"
          :disabled="fgInfo"
          @change="selectConsignee($event)"
        >
          <template #append>
            <div v-if="!fgInfo" class="d-flex align-items-start">
              <CButton
                color="add"
                size="sm"
                class="buttonClassConsignee"
                v-c-tooltip="{
                  content: $t('label.nuevo')+$t('label.consignee'),
                  placement: 'top-end'
                }"
                @click="collapse=!collapse"
              >
                <CIcon name="cil-playlist-add" />
              </CButton>  
            </div>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.rifNumber')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneeRif.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeRif)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeRif)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" class="d-flex justify-content-center px-0" v-if="collapse">
        <CCol sm="12" lg="10">
          <CCollapse :show="collapse">
            <AddConsignee :modal="collapse" :showModal="showModal" @submited="handleSubmitConsignee" @cerrarModal="this.collapse = false" @child-refresh="this.refreshConsignee=true" />
          </CCollapse>
        </CCol>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" v-if="(!collapse)">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.name')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneeName.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeName)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeName)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5" >
        <CInput
          size="sm" 
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneeAddress.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeAddress)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeAddress)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" v-if="(!collapse)">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneePrimaryPhone.$model"
          :is-valid="hasError(vForm.consignee.ConsigneePrimaryPhone)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneePrimaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneeSecondaryPhone.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeSecondaryPhone)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeSecondaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" v-if="(!collapse)">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneePrimaryEmail.$model"
          :is-valid="hasError(vForm.consignee.ConsigneePrimaryEmail)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneePrimaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consignee.ConsigneeSecondaryEmail.$model"
          :is-valid="hasError(vForm.consignee.ConsigneeSecondaryEmail)"
          :invalid-feedback="errorMessage(vForm.consignee.ConsigneeSecondaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import AddConsignee from '../add-consignee';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';

function data() {
  return {
    collapse: false,
    refreshConsignee: false,
    consigneeItems: []
  };
}


//-----------------------   Method    ------------------------

function getConsigneeList() {
  this.$store.state.yardManagement.loading = true;
  this.$http
  .get('ClientConsignee-list',{ filter: 'ACTIVO', CompanyBranchId: this.getBranchId})
  .then(response => {
    let listado = response.data.data;
    if (listado && listado.length != 0){
      this.consigneeItems = listado;
    }
  })        
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function resetForm() {

  //consolidator
  this.form.consolidator.ConsolidatorId = '';
  this.form.consolidator.ConsolidatorRif ='';
  this.form.consolidator.ConsolidatorName = '';
  this.form.consolidator.ConsolidatorAddress = '';
  this.form.consolidator.ConsolidatorPrimaryPhone = '';
  this.form.consolidator.ConsolidatorSecondaryPhone = '';
  this.form.consolidator.ConsolidatorPrimaryEmail = '';
  this.form.consolidator.ConsolidatorSecondaryEmail = '';

  //consignee
  this.form.consignee.ConsigneeId = '';
  this.form.consignee.ConsigneeRif ='';
  this.form.consignee.ConsigneeAddress = '';
  this.form.consignee.ConsigneeName = '';
  this.form.consignee.ConsigneePrimaryPhone = '';
  this.form.consignee.ConsigneeSecondaryPhone = '';
  this.form.consignee.ConsigneePrimaryEmail = '';
  this.form.consignee.ConsigneeSecondaryEmail = '';

  this.collapse= false
  this.refreshConsignee=true

  //data array
  this.consigneeItems = [];
}

function selectConsignee(event) {
  this.form.consignee.ConsigneeId = event.target.value;
  if (this.form.consignee.ConsigneeId) {
    let data = this.consigneeItems.find((item) => item.ClientTpId == this.form.consignee.ConsigneeId);
   
    //llenado de campos según el agente aduanal
    this.form.consignee.ConsigneeRif = data?.ClientRif ?? '';
    this.form.consignee.ConsigneeAddress = data?.ClientAddress ?? '';
    this.form.consignee.ConsigneeName = data?.ClientName ?? '';
    this.form.consignee.ConsigneePrimaryPhone = data?.PrimaryPhone ?? '';
    this.form.consignee.ConsigneeSecondaryPhone = data?.SecondaryPhone ?? '';
    this.form.consignee.ConsigneePrimaryEmail = data?.PrimaryEmail ?? '';
    this.form.consignee.ConsigneeSecondaryEmail = data?.SecondaryEmail ?? '';

    this.vForm.consignee.$touch();
  }else{
    
    //reseteo de los campos
    this.form.consignee.ConsigneeRif = '';
    this.form.consignee.ConsigneeAddress =  '';
    this.form.consignee.ConsigneeName =  '';
    this.form.consignee.ConsigneePrimaryPhone = '';
    this.form.consignee.ConsigneeSecondaryPhone =  '';
    this.form.consignee.ConsigneePrimaryEmail =  '';
    this.form.consignee.ConsigneeSecondaryEmail =  '';
  }
}

function handleSubmitConsignee() {
  this.getConsigneeList()
}



//-----------------------   Computed   ------------------------

function consigneeOptions () {
  if(this.consigneeItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.consigneeItems.map(function(e){
      if (e.FgActClientTp) {
        chart.push({
          value: e.ClientTpId, 
          label: e.ClientName,
        }) 
      }  
    })
    return chart;
  }
}


export default {
  name: 'consignee-tab',
  components: {
      AddConsignee
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Tab: Boolean,
    isEdit: Boolean,
    fgInfo: Boolean,
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    selectConsignee,
    handleSubmitConsignee,
    resetForm,
    getConsigneeList
  },
  computed:{
    consigneeOptions,
    ...mapState({
      branch: (state) => state.auth.branch,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
    Tab: async function (Newval) {
      if (Newval) 
        await this.getConsigneeList();
    },
    refreshConsignee(val){
      if(val){
        this.collapse = false;
        this.refreshConsignee = false;
      }
    },
  }
};
</script>
<style scoped>
.buttonClassConsignee{
  height: 90% !important;
  position: relative;
}
</style>