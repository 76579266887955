<template>
  <div class="mb-4 mt-4">
    <!--CRow>
      <CCol sm="12"> 
        <CInput
          v-model.trim="buqueIMO"
          v-uppercase
          :horizontal="{ label: 'col-sm-1', input: 'col-sm-11'}"
          disabled
          size="sm"
          :label="$t('label.vessel')"
          addLabelClasses="text-right"
        >
        </CInput>
      </CCol>
    </CRow-->
    <CRow>
      <CCol sm="3" lg="3">
        <div class="position-relative">
          <div class="form-group form-row" rol="group">
            <label class="required text-right col-form-label col-sm-3" for="tpGrua">{{$t('label.crane')}}</label>
            <div class="col-sm-9 input-group">
              <v-select 
                :options="gruasOptions"
                label="CraneName"
                :placeholder="$t('label.select')"
                class="select-adjust"
                v-model="grua"
                :disabled="isGruasEmpty"
              />
              <div :class="isGruaValid">
                {{$t('label.required')}}
              </div>
            </div>
          </div>
          <CElementCover
            :opacity="1"
            v-show="isLoadingGruas"
          >
            <label class="d-inline">{{$t('label.load')}}... </label>
            <CSpinner size="sm" />
          </CElementCover>
        </div>
      </CCol>
      <CCol sm="4" lg="3">
        <div class="position-relative">
          <div class="form-group form-row" rol="group">
            <label class="required col-form-label col-sm-3 text-right">
              {{$t('label.bay')}}S
            </label>
            <div class="col-sm-9 input-group">
              <multiselect 
                v-model="$v.bays.$model"
                :options="baysOptions"
                :multiple="!edit"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :disabled="isBaysEmpty"
                :placeholder="$t('label.select')"
                label="BayCode"
                track-by="BayCode"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span v-if="values.length &amp;&amp; !isOpen">
                    {{ values.length }} Bay(s) {{$t('label.select')}}(s)
                  </span>
                </template>
              </multiselect>
            </div>
          </div>
          <CElementCover
            :opacity="1"
            v-show="isLoadingBays"
          >
            <label class="d-inline">{{$t('label.load')}}... </label>
            <CSpinner size="sm" />
          </CElementCover>
        </div>
      </CCol>
      <CCollapse sm="3" lg="3" :show="edit">
          <CCol sm="12" lg="12">
            <CSelect
              :value.sync="gruaBay.Status"
              :is-valid="statusSelectColor"
              :label="$t('label.status')"
              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
              :options="statusOptions"
              addLabelClasses="text-right"
            />
          </CCol>
      </CCollapse>
      <CCol sm="2" lg="3">
        <CButton
          size="sm"
          color="add"
          :disabled="isSubmitValid"
          class=" mr-2"
          @click.stop="statusConfirmation"
          v-c-tooltip="{
            content:$t('label.aggregate'),
            placement: 'top-end'
          }"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><!--span class="ml-1">{{ submitText }}</span-->
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <!--span class="ml-1">{{ submitText }}</span-->
          </div>
        </CButton>
        <CButton
          size="sm"
          color="wipe"
          :disabled="isSubmit"
          @click.stop="resetInputs"
          v-c-tooltip="{
            content:$t('label.clearFields'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-brush-alt"/>
        </CButton>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          hover
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedGruaBays"
          :fields="fields"
          :loading="isLoading"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="activePage"
          pagination
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #RowNumber="{ item }">
            <td class="center-cell text-center">
              {{ item.RowNumber }}
            </td>
          </template>

          <template #Status="{item}">
            <td class="center-cell text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>

          <template #Details="{item}">
            <td class="py-2 center-cell text-center">
                <CButton
                  color="edit"
                  size="sm"
                  :key="item.Nro"
                  :disabled="edit"
                  v-c-tooltip="{
                    content: $t('label.edit')+$t('label.crane')+'-'+$t('label.bay'),
                    placement: 'top-end'
                  }"
                  @click.stop="handleEdit(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GruaBayValidations from '@/_validations/buque/GruaBayValidations';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:5%; text-align:center',
      filter: false,
    },
    { key: 'CraneName', label: this.$t('label.crane'), _style: 'width:30%' },
    { key: 'VesselHoldName', label: this.$t('label.hold'), },
    { key: 'BayCode', label: this.$t('label.bay'), _style: 'width:10%;  text-align:center', _classes: 'text-center' },
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:15%; text-align:center', _classes: 'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%; text-align:center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
    }
  ];
}

//Created:
function created() { }

//Data:
function data() {
  return {
    isSubmit: false,
    isLoading: false,
    isLoadingGruas: false,
    isLoadingBays: false,
    items: [],
    gruaBay: {
      BayCraneId: '',
      VesselBayId: '',
      VesselCraneId: '',
      Status: 1
    },
    gruas: [],
    grua: null,
    bays: null,
    baysList: [],
    oldFgAct: false,
    edit: false,
    activePage: 1
  }
}

//Methods:
function getGruas(IdBuque) {
  this.isLoadingGruas = true;
  this.$http.ejecutar('GET', 'VesselCraneList-by-vessel', { VesselId: IdBuque, Filter: 'ACTIVO' })
  .then(response => {
    this.gruas = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGruas = false;
  });
}
function getBays(IdBuque, craneId) {
  this.isLoadingBays = true;
  this.$http.ejecutar('GET', 'VesselBayToDeck-list', { VesselCraneId: craneId, Filter: 'ACTIVO' })
  .then(response => {
    this.baysList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingBays = false;
  });
}
function getGruasBays(IdBuque) {
  this.isLoading = true;
  this.$http.ejecutar('GET', 'VesselBayCrane-by-vessel', { VesselId: IdBuque, Filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function submit() {
  if(!this.edit)
    this.addGruaBay();
  else
    this.editGruaBay();
}
function addGruaBay() {
  this.isSubmit = true;
  let BayCraneJson = this.formatedData();

  this.$http.ejecutar('POST', 'VesselBayCrane-Insert', BayCraneJson, { root: 'BayCraneJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getGruasBays(this.buque.VesselId);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function editGruaBay() {
  this.isSubmit = true;
  let BayCraneJson = {...this.formatedData()};
  
  this.$http.ejecutar('PUT', 'VesselBayCrane-update', BayCraneJson, { root: 'BayCraneJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getGruasBays(this.buque.VesselId);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function resetInputs() {
  if(!this.edit){
    this.gruaBay.VesselBayId = '';
    this.gruaBay.VesselCraneId = ''
    this.grua = null;
    this.bays = null;
    this.$v.$reset();
  } else{
    this.gruaBay.BayCraneId = '';
    this.gruaBay.VesselBayId = '';
    this.gruaBay.VesselCraneId = '';
    this.gruaBay.Status = 1;
    this.grua = null;
    this.bays = null
    this.edit = false;
    this.$v.$reset();
  }
}
function handleEdit(item) {
  this.gruaBay.BayCraneId = item.BayCraneId;
  this.gruaBay.VesselCraneId = item.VesselCraneId;
  this.gruaBay.VesselBayId = item.VesselBayId;
  this.gruaBay.Status = item.FgActBayCrane?1:0;
  this.oldFgAct = item.FgActBayCrane;
  this.edit = true;
  this.grua = {
    VesselCraneId: item.VesselCraneId,
    CraneName: item.CraneName
  };
  this.bays = {
    VesselBayId: item.VesselBayId,
    BayCode: item.BayCode,
  }
  this.$v.$touch();
}
function formatedData() {
if(!this.edit){
    return this.bays.map((item) => Object.assign({}, {
      VesselBayId: item.VesselBayId,
      VesselCraneId: this.grua.VesselCraneId
    }));
  } else {
    return {
        BayCraneId: this.gruaBay.BayCraneId,
        VesselCraneId: this.grua.value,
        Status: this.gruaBay.Status,
        VesselBayId: this.bays.VesselBayId,
    };
  }
}
function statusConfirmation(){
  if(!this.edit)
    this.submit();
  else{
    let status = this.oldFgAct?1:0;
    if(this.gruaBay.Status === status)
      this.submit();
    else{
      this.$swal.fire({
        text: `${this.$t('label.changeStatusQuestion')}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }
}

//Computerd: 
function buqueIMO() {
  return this.buque && this.buque.VesselName && this.buque.Imo
    ?`${this.buque.VesselName} - ${this.buque.Imo}`
    :'';
}
function formatedGruaBays() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.gruaBay.Status === 1;
}
function submitText() {
  return this.edit?this.$t('label.edit'):this.$t('label.add');
}
function isGruasEmpty(){
  return this.gruas.length == 0;
}
function gruasOptions() {
  return this.gruas.map((grua) => Object.assign({}, grua, {
    label: grua.CraneName,
    value: grua.VesselCraneId
  }));
}
function isGruaValid() {
  return {
    'invalid-feedback': true,
    invalid: this.grua? null : this.$v.$anyDirty? this.$v.grua.$invalid : null
  };
}
function isBaysEmpty(){
  return this.baysList.length == 0;
}
function baysOptions() {
  return this.baysList.map((item) => Object.assign({}, item, {
    label: item.BayCode,
    value: item.VesselBayId
  }));
}
function isBodegaValid() {
  return {
    'invalid-feedback': true,
    invalid: this.bays ? null : this.$v.$anyDirty? this.$v.bays.$invalid : null
  };
}

export default {
  name: 'grua-bay',
  mixins: [GeneralMixin],
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data,
  created,
  validations: GruaBayValidations,
  directives: UpperCase,
  computed: {
    buqueIMO,
    formatedGruaBays,
    isSubmitValid,
    statusOptions,
    submitText,
    isGruasEmpty,
    isGruaValid,
    gruasOptions,
    isBaysEmpty,
    baysOptions,
    isBodegaValid, 
    fields
  },
  methods: {
    addGruaBay,
    editGruaBay,
    resetInputs,
    submit,
    handleEdit,
    getGruas,
    getBays,
    getGruasBays,
    formatedData,
    statusSelectColor,
    statusConfirmation,
  },
  watch: {
    buque: function(newBuque) {
      if( Object.keys(newBuque).length != 0 && newBuque.VesselId) {
        this.getGruasBays(newBuque.VesselId);
        this.getGruas(newBuque.VesselId);
      }
    },
    active: function(newVal) {
      if(newVal) {
        if( Object.keys(this.buque).length != 0 && this.buque.VesselId) {
          this.getGruasBays(this.buque.VesselId);
          this.getGruas(this.buque.VesselId);
        }
      }
    },
    grua: function(newGrua) {
      if( newGrua && Object.keys(newGrua).length != 0 && newGrua.VesselCraneId
        && Object.keys(this.buque).length != 0 && this.buque.VesselId) {
        this.getBays(this.buque.VesselId, newGrua.VesselCraneId);
      } else {
        this.baysList = [];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}
.center-cell {
  text-align: center;
}
 
</style>