<template>
  <CModalExtended
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <template slot="header" >
      <div class="d-flex align-content-center">
        <div class="" style="margin-right:10px;">
          <img 
            v-if="delay"
            class="img-fluid rounded filter-white "
            height="20px" width="20px" 
            :src="delay.Icon" 
            alt=""
          >
        </div>
        <div class="flex-grow-1" style="color:#fff">
          {{ modalTitle }}
        </div>
      </div>
  </template>
    <CRow class="options">
      <CCol sm="12" class="position-relative">
        <dataTableExtended
     class="align-center-row-datatable"
          hover
          sorter
          size="sm"
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedTimes"
          :fields="fields"
          :loading="isLoading"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="activePage"
          pagination
        >
          <template #loading>
            <loading/>
          </template>
          <template #Nro="{item}">
            <td
              class="center-item"
              :style="'background-color:' + hexToRGBA(item.Color) + '!important;'"
            >
              {{ item.Nro }}
            </td>
          </template>
          <template #Code="{item}">
            <td 
              class="center-item"
              :style="'background-color:' + hexToRGBA(item.Color) + '!important;'"
            >
              {{ item.Code }}
            </td>
          </template>
          <template #Lost="{item}">
            <td
              class="center-item"
              :style="'background-color:' + hexToRGBA(item.Color) + '!important;'"
            >
              {{ item.Lost }}
            </td>
          </template>
          <template #Time="{item}">
            <td
              class="center-item"
              :style="'background-color:' + hexToRGBA(item.Color) + '!important;'"
            >
              {{ item.Time }}
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x" />
        <span class="ml-1">{{ $t("button.exit") }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { tableTextTranslatedHelpers, hexToRgb } from '@/_helpers/funciones';

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    activePage: 1,
  };
}

//Methods
function toggle(newVal) {

  this.modalActive = newVal;
}
function hexToRGBA(color) {
  let rgba = hexToRgb(color, 0.2);

  return `rgb(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}

//Computed
function modalTitle() {
  if(!this.delay) return this.$t('label.delays');

  return this.$i18n.locale == "es"
    ? `${this.delay.TpDelayNameEs}: TOTAL ${this.delay.TpDelayHours} HRS`
    : `${this.delay.TpDelayNameEn}: TOTAL ${this.delay.TpDelayHours} HRS`;
}
function tableText() {
  return tableTextTranslatedHelpers(this);
}
function fields() {
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%; text-align:center;'
    },
    { key: 'Code', label:this.$t('label.code'), _style:'width: 5%; text-align:center;'},
    {
      key: 'Lost',
      label: this.$t('label.TimeLost'),
      _style:'width: 50%; text-align:center;'
    },
    {
      key: 'Time',
      label: 'TOTAL HRS',
      _style:'text-align:center;'
    },
  ];
}
function formatedTimes() {
  if(!this.delay) return [];

  let locale = this.$i18n.locale;
  return this.delay.TpDelayDetailsJson.map((item, index) => Object.assign({}, item, {
    Nro: index+1,
    Code: item.CustomCode,
    Lost: locale == "es" ? item.DelayCustomNameEs : item.DelayCustomNameEn,
    Time: item.TotalHours,
  }));
}

export default {
  name: "delay-list-modal",
  props: {
    modal: Boolean,
    delay: {
      type: Object,
      default: null,
    },
  },
  data,
  methods: {
    toggle,
    hexToRGBA,
  },
  computed: {
    fields,
    modalTitle,
    tableText,
    formatedTimes,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
.center-item {
  text-align: center;
  vertical-align: middle !important;
}
.filter-white {
  filter: invert(100%) sepia(0%) saturate(1076%) hue-rotate(213deg) brightness(99%) contrast(98%);
}
</style>