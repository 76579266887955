import { required, minValue} from 'vuelidate/lib/validators';
import { NumberFormater} from '@/_helpers/funciones';

  export default (val, RemainData) => {

    let valid = !val ? { required } : {};

    return {
        PackagingId:  valid,
        VisitBillOfLadingPackagingId: valid,
        VisitBillOfLadingId: {},
        QuantityPlanning: {},
        QuantityIdentify: {},
        Quantity: { 
          required(value) {
            return NumberFormater.setNum(value) > 0;
          }, 
          QuantityData (value){
            if (NumberFormater.setNum(value) > 0) {
              return  NumberFormater.setNum(value) <=  NumberFormater.setNum(RemainData);
            }else{
              return true;
            }
          } 
        },
        QuantityPlanningPackaging: {},
        QuantityStowage: {},
        RemainPackaging: {},
        CommodityName: {},
        Remain: {},
        Consignee: {},
        PackagingName: {},
        ClientName: {},
        NroBl: {},
        SmallerRemain: {},
        Status: {}

    }
  }