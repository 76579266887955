<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-for="i in totalPages" :key="i">
      <!-- Paginas normales -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong>
              {{ titleFromCompany }}
            </strong>
            <strong>
              {{ companyLocation }}
            </strong>
            <strong class="mb-2">
              {{ titleDataSheet }} ,
              {{ $t('label.teusCapacity') }}: {{ getDato(details?.TeusCapacity != 0 ? details?.TeusCapacity : '0') }},
              {{ $t('label.availableTeus') }}: {{ getDato(details?.TeusAvailable != 0 ? details?.TeusAvailable : '0') }},
              {{ $t('label.occupiedTeus') }}: {{ getDato(details?.TeusOccupied != 0 ? details?.TeusOccupied : '0') }}

            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="bay-plan">
                  <div class="bay-plan-card">
                    <CRow class="max-height">
                      <CCol sm="12">
                        <div class="zoom-container zoom-center">
                          <div class="bay-view">
                            <div v-for="(positions, index) in bayJson" v-if="validateRange(i, index)" class="d-flex align-items-center justify-content-center">
                              <div class="bay-view-container" :style="{ width: zoom  }" >
                                <div v-for="(position) in positions" style="width: 100%;">
                                  <div v-if="position.BayCode">
                                    <bay-grid
                                      :col="colNum(position.HeightJson)"
                                      :row="rowNum(position.HeightJson)"
                                      :items="position.HeightJson ?? []"
                                      :maxCol="responsiveCol(position.HeightJson)"
                                      showStatus
                                      :statusCondition = true
                                      :bayCode="position.BayCode"
                                      :cargoView = "true"
                                      :bayFilter = " columns === 1 && rows === 1"
                                      :bayView = " columns === 1 && rows === 1"
                                      :byBay ="true"
                                    />
                                  </div>
                                  <div  v-if="position.BayCode === undefined"></div>
                              </div>
                            </div>
                          </div> 
                        </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  
                  <span class="total-block">{{ `${$t('label.block')} : ${getDato(totalBlock?.blockName)}` }}</span>
                  <span class="total-teus">{{ `TOTAL TEUS: ${getDato(totalBlock?.TotalTeus)}` }}</span>
                  <span class="total-20">{{ `TOTAL CONT 20 FT: ${getDato(totalBlock?.TotalTeus)}` }}</span>
                  <span class="total-40">{{ `TOTAL CONT 40 FT: ${getDato(totalBlock?.TotalCont20)}` }}</span>
                  <span class="total-45">{{ `TOTAL CONT 45 FT: ${getDato(totalBlock?.TotalCont40)}` }}</span>
                </div>
                
                
                <div class="legend-case-1" v-if="formatedLegend.length < 7">
                  <CCol  sm="2"  v-for="leg in formatedLegend" :key="leg.Id">
                    <CRow>
                      <CCol sm="2" class="item legend-icon-case-1">
                        <div :class="leg.Class  || null"></div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="11" class="item-label ml-2 d-flex justify-content-center">
                        {{ getDato( $i18n.locale=='en' ? leg.Name : leg.Name ) }}
                      </CCol>
                    </CRow>
                  </CCol>
                </div>

                 <div class="legend-case-2" v-if="formatedLegend.length >= 7">
                  <CCol  sm="1"  v-for="leg in formatedLegend" :key="leg.Id">
                    <CRow  class="d-flex justify-content-center">
                      <CCol sm="2" class="item legend-icon-case-2">
                        <div :class="leg.Class  || null"></div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="12" class="item-label ml-2 d-flex justify-content-center">
                        {{ getDato( $i18n.locale=='en' ? leg.Name : leg.Name ) }}
                      </CCol>
                    </CRow>
                  </CCol>
                </div>
                
              </div>
            </div>
          </div>
        </section>


        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}:
              {{ i  }}
              {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayMixin from "@/_mixins/bays";
import BayGrid from "@/pages/yard-management/planimetry/bay-view/bay-grid";
import { NumberFormater} from '@/_helpers/funciones';

//Data
function data() {
  return {
    isLoading: false,
    pageCount: 0,
    numberOfPages:1,
    srcError: 'img/errorimage.jpg',
    zoom: '100%'
  };
}

//Created:
function created() {

 if(this.rows === 1){
  if(this.columns === 1) this.zoom = "45%";

  if(this.columns === 2) this.zoom = "75%";

  if(this.columns === 3) this.zoom = "90%";

  if(this.columns === 4) this.zoom = "100%";

  if(this.columns>=5) this.zoom = "100%";
 }
 else{
  if(this.columns === 1) this.zoom = "30%";

  if(this.columns === 2) this.zoom = "50%";

  if(this.columns === 3) this.zoom = "80%";

  if(this.columns === 4) this.zoom = "90%";

  if(this.columns>=5) this.zoom = "100%";
 }

  let toBlock = 0;

  for(let i=1; i<=this.blockItems; i++){
    toBlock = ((this.columns * ((this.rows>1)? 2 : 1))  + toBlock)

    if(toBlock>=this.blockItems){
      this.numberOfPages = i;
      break;
    }
  }
}

//Metodos:
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function getBayCountsBySize(bay, size) {
  let contTwenty = 0, contForty = 0, contFortyFive = 0;
  return '';

  bay.PositionJson.forEach((pos) => {
    if(pos.CargoJson != null && pos.CargoJson.length != 0){
      switch (pos.CargoJson[0].Size) {
        case "20":
          contTwenty++;
          break;
        case "40":
          contForty++;
          break;
        case "45":
          contFortyFive++;
          break;
        default:
          break;
      }
    }
  });

  if(size == 20)
    return contTwenty;
  if(size == 40)
    return contForty;
  if(size == 45)
    return contFortyFive;

  return 0
}

//methods
function getDato(value) {
  if (!this.details) return "";

  return value ? value : "";
}

function titleDataSheet() {
  if (!this.details) return ` `;

  let name = this.details.YardName;

  return `${name}`;
}

function colNum(positions){
  let count = 0;

  positions?.map(item =>{
    if(count < item?.PositionJson?.length)
      count = item?.PositionJson?.length;
  });
  return count;
}

function rowNum(positions){
  return positions?.length > 0 ? positions?.length :  0;
}

function responsiveCol(positions) {
  let colNum = this.colNum(positions);
  return colNum;
}

function NumberFormated(value){
  return value ? NumberFormater.formatNumber(value, 2) : '0,00';
}

//Computeds:

function imageSrc(Src) {
  if (Src === '') {
    return this.srcError;
  }
  else {
    return `${this.$store.getters["connection/getBase"]}${Src}`;
  }
}

function footerText() {

  let filtro = this.filter.PortCode ? ` - ${this.$t("label.filteredBy")} ${this.filter.PortCode}` : '';

  return `${this.$t("label.planimetry")} ${filtro}`;
}

function totalPages() {
  return this.numberOfPages;
}

function validateRange(i, index) {
  let cont = i-1;
  let fromBlock = (cont * ((this.rows>1)? 2 : 1))
  let toBlock = ((cont * ((this.rows>1)? 2 : 1))  + ((this.rows>1)? 2 : 1))
  return index>=fromBlock && index<toBlock;
}

function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}

function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}

function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

function formatedLegend(){
  return this.legend.filter((item) => item.Class != null);
}


export default {
  name: "bay-view-pdf",
  props: {
    bayJson: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Number,
      default: 60,
    },
    rows: {
      type: Number,
      default: 60,
    },
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 1,
    },
    blockItems: {
      type: Number,
      default: 1,
    },
    totalBlock: {
      type: Object,
      default: null,
    },
    itineraryInfo: {
      type: Object,
      default: null,
    },
    filter: {
      type: Object,
      default: () => {
        return {
          FilterType: "",
          PortId: "",
          PortCode: "",
        };
      }
    },
    modulo: {
      type: String,
      default: 'stowage'
    },
    details: {
      type: Object,
      default: null,
    },
    legend: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [BayMixin],
  components: {
    BayGrid,
  },
  data,
  created,
  methods: {
    getBayCountsBySize,
    colNum,
    rowNum,
    responsiveCol,
    validateRange,
    getDato,
    imageSrc,
    NumberFormated
  },
  computed: {
    footerText,
    totalPages,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    titleDataSheet,
    hasLogo,
    loginName,
    formatedLegend,
    ...mapState({
      user: (state) => state.auth.user,
      yardData: state => state.yardManagement.yardData,
    }),
  },
  watch: {
  },
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.body .general-info .info-content .img-vessel,
.body .general-info .info-content .title-vessel {
  height: 20%;
  color: #000000d7;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff50;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 0.8rem;
  padding: 0%;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.5rem;
}
.vessel{
  width: 100%;
  height: 100%;
}
.vessel > table {
  height: 80%;
}
.vessel > table td {
  border: 0.5px solid #0000008e;
}
.vessel > table td {
  padding: 0px 5px;
  width: 20%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-30 {
  width: 30% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 80%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .info-vessel .bay-plan {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  overflow: hidden;
  position: relative;
}

.total-block {
  position: absolute;
  left: 82%;
  bottom: 17%;
}

.total-teus {
  position: absolute;
  left: 82%;
  bottom: 13%;
}
.total-20 {
  position: absolute;
  left: 82%;
  bottom: 9%;
}

.total-40 {
  position: absolute;
  left: 82%;
  bottom: 5%;
}

.total-45 {
  position: absolute;
  left: 82%;
  bottom: 1%;
}

.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}

.info-content  .legend-case-1 {
  width: 100%;
  padding: 4px 9px 7px 9px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 1%;
  gap: 1px;
}

.info-content  .legend {
  width: 100%;
  padding: 4px 9px 4px 9px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 1%;
  gap: 1px;
}

.info-content  .legend-case-2 {
  width: 100%;
  padding: 4px 9px 4px 9px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 1%;
  padding-top: 2%;
  gap: 1px;
}

.info-content  .total-Block {
  width: 100%;
  padding: 4px 4px 4px 4px;
  display: flex;
  justify-content: center;
  margin-top: 6%;
  gap: 5px;
}
.legend .area {				
  width:	100%;
}
 .legend .area {
  min-height: 10px;						
  width:	calc((100% / 12) - 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0%;
}
 .item {
  min-height: 60%;
  height: 25px;
  padding-top: 10%;
  /* filter: brightness(0%); */
  /* border-radius: 5px; */
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

 .item-label {
  font-size: 7px;
  height: 25px;
  padding-top: 5%;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding-left: 0px;

}
 .item svg{
  width: 50%;
}
 .item .icon {
  max-width: 30px;
  position: absolute;
  width: 28%;
  top: 14%;
}

.area-border {
  border: 1px solid #000;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */

.body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}

.body .general-info .info-content .img-vessel,

.body .general-info .info-content .title-vessel {
  height: 25%;
  color: #000000d7;
}

.zoom-container {
  width: 100%;
  height: 100%;
  max-height: 670px;
  overflow: hidden;
}
.zoom-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.max-height {
  height: 100%;
}

/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.bay-view {
  margin: 10px 5px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.bay-info {
  position: absolute;
  right: 1.5%;
  bottom: 10%;
  width: 220px;
  height: 85px;
  margin-left: auto;
  font-size: 1.05rem;
  font-weight: bold;
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
<style scoped>
  /* Contenedor y cuadros */
  .max-container {
    height: 390px;
    max-height: 390px;
    overflow: auto;
    scrollbar-width: thin;
  }
  
  .legend-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .legend-wrapper {
    margin: 5px;
    width: calc(calc(100% / 3) - 10px);
    display: flex;
    align-items: center;
  
    padding: 10px;
    /* border: 1px solid #000; */
  }
  .legend-icon-case-1  {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid #000;
    position: absolute;

  }

  .legend-icon-case-2 {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid #000;
    position: relative;
  }
  .legend-text {
    margin-left: 10px;
    margin-top: 10px;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }
  .bg-position {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .5s ease-in-out;
  }
  
  /* Iconos */
  .flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar,
  .estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45,
  .footer-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube,
  .refrigerado-high-cube,
  .rt-refrigerado-high-cube,
  .rh-reefer  {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
    top: 0;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .oversize-top {
    width: 100%;
    height: 50%;
    border: 1px solid #555;
    background: #555;
    -moz-border-radius: 100px 100px 0 0;
    -webkit-border-radius: 100px 100px 0 0;
    border-radius: 100px 100px 0 0;
    position: absolute;
    top: -50%;
    z-index: 100;
  }
  .oversize-right {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 0 100px 100px 0;
    position: absolute;
    right: -50%;
    z-index: 1;
    top:0;
  }
  .oversize-left {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 100px 0 0 100px;
    position: absolute;
    left: -50%;
    z-index: 1;
    top:0;
  }
  .oversize-back {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
  }
  .oversize-front {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    top: 0;
  }
  
  .empty {
    background-image: url('/img/iconos/Empty.svg');
    position: absolute;
    width: 25%;
    height: 25%;
    top: 4%;
    left: 75%;
  }
  .hazard-available {
    background-image: url('/img/iconos/AvailableHazard.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .hazard-restriction {
    background-image: url('/img/iconos/HazardRestriction.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .reefer-plug {
    background-image: url('/img/iconos/ReeferPlug.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-40 {
    background-image: url('/img/iconos/OccupiedBy40.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-45 {
    background-image: url('/img/iconos/Plus45_1.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-oversize,
  .reserved {
    background-image: url('/img/iconos/OccupiedOversize.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .restow {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    top: 0;
  }
  
  .center-icon { /* Center a .svg */
    position: absolute;
    width: 45%;
    height: 45%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .invalid-position {
    background-image: url('/img/iconos/ban-solid.svg');
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    -moz-transform: translate(-50%, -50%) rotate(90deg);
    -ms-transform: translate(-50%, -50%) rotate(90deg);
    -o-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .item-disabled {
    background: #9e9e9e;
    border-color: #9e9e9e;
    color: #fff;
  }

  .disabled, .disabled-position {
    background-image: url('/img/yards/blocks/DISABLE-NEGRO.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .legend .area .item {
  width: 100%;
  /* min-height: 44px; */
  min-height: 25px;
  /* filter: brightness(0%); */
  /* border-radius: 5px; */
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


  </style>