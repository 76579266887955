<template>
  <main>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.SealAssignmentList')}} </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end py-2">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="toggleCreate"
              v-c-tooltip="{
                content: $t('label.SealAssignment'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nueva')}}
            </CButton>
          </CCol>
        </CRow>
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedList"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        > 
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item, index }">
            <td>
                <CButton
                  size="sm"
                  square
                  color="edit"
                  class="d-flex align-items-center"
                  @click="toggleEdit(item)"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.UnNumber'),
                    placement: 'top-end'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
            
            </td>
          </template>
        </dataTableExtended>
      </CCardBody>
    </CCard>
    <modalSealAssignment
      :modal.sync="modalUnNumberCreate"
      :isEdit="isEdit"
      :sealAssignment="sealAssignment"
      @Update="Update"
      @close="closeModal"
    />
  </main>
</template>
<script>
import modalSealAssignment from './modal-seal-assignment';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function data() {
  return {
    items: [],
    modalUnNumberCreate: false,
    sealAssignment: {},
    loading: false,
    isEdit: false,
  };
}

//methods
function mountedUnNumberList() {
  this.loading = true;
  this.$http
    .get('SealAssignment-list')
    .then((response) => (this.items = response.data.data))
    .finally(() => {
      this.loading = false;
    });
}

function toggleCreate() {
  this.modalUnNumberCreate = true;
}

function toggleEdit(item) {
  this.sealAssignment = item;
  this.isEdit = true;
  this.modalUnNumberCreate = true;
}

function Update() {
  this.modalUnNumberCreate = false;
  this.isEdit = false;
  this.mountedUnNumberList();
}

function closeModal(){
  this.sealAssignment = {};
  this.modalUnNumberCreate= false;
  this.isEdit= false;
}

//computed
function fields(){
  return [
    { key: 'Nro', label: '#', _classes: 'text-center',  _style: 'width: 5%;', filter: false },
    { key: 'SealAssignmentNameEs', label: `${this.$t('label.name')} (ES)`, _classes: 'text-center', _style: 'width: 30%'},
    { key: 'SealAssignmentNameEn', label: `${this.$t('label.name')} (EN)`, _classes: 'text-center', _style: 'width: 30%'},
    { key: 'TransaLogin', label: `${this.$t('label.user')}`, _classes: 'text-center', _style: 'width: 10%'},
    { key: 'FormatedDate', label: `${this.$t('label.date')}`, _classes: 'text-center', _style: 'width: 15%'},
    { key: 'Status', label: `${this.$t('label.status')}`, _classes: 'text-center', _style: 'width: 10%'},
    { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
  ];
}

function formatedList() {
  return this.items.map((item) => {
    return {
      ...item,
      UnNumber: item.UnNumber ?? 'N/A',
      SealAssignmentNameEs: item.SealAssignmentNameEs ?? 'N/A',
      SealAssignmentNameEn: item.SealAssignmentNameEn ?? 'N/A',
      ImdgClassAbbrev: item.ImdgClassAbbrev ?? 'N/A',
      ImdgCod: item.ImdgCod ?? 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      TransaLogin: item.TransaLogin ?? 'N/A',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

export default {
  name: 'index',
  components: { modalSealAssignment },
  data,
  mixins: [GeneralMixin],
  methods: {
    mountedUnNumberList,
    toggleCreate,
    toggleEdit,
    Update,
    closeModal
  },
  mounted: mountedUnNumberList,
  computed: {
    formatedList,
    fields,
  },
};
</script>