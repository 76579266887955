<template>
  <CRow class="justify-content-center m-0 pt-3">
    <CCol v-if="!fgInfo" sm="12" lg="10" class="px-0">
      <CRow class="m-0">
        <CCol sm="12" lg="6">
          <CSelect
            horizontal
            size="sm"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :options="documentYardStatusOption"
            v-model.trim="VForm.Status.DocumentYardStatusId.$model"
            :is-valid="hasError(VForm.Status.DocumentYardStatusId)"
            :invalid-feedback="errorMessage(VForm.Status.DocumentYardStatusId)"
          />
        </CCol>
        <CCol sm="12" lg="6">
          <div class="form-group form-row d-flex aling-items-center mb-3">
            <label class="col-sm-12 col-lg-4 col-form-label-sm text-right mb-0 required">{{`${$t('label.statusDate')}`}}</label>
            <div class="input-group col-sm-12 col-lg-8">
              <vue-datepicker 
                type="datetime"
                header
                :lang="this.$i18n.locale"
                :editable="false"
                :clearable="false"
                class="w-100"
                format="DD/MM/YYYY HH:mm"
                placeholder="DD/MM/YYYY HH:mm"
                time-title-format="DD/MM/YYYY HH:mm"
                v-model.trim="VForm.Status.TransactionDate.$model"
                :disabled-date="validateDateRange"
                :append-to-body="false"
                value-type="format"
                :show-second="false"
              >
                <template #input>
                    <CInput
                      v-uppercase
                      class="w-100 float-left"
                      v-model="VForm.Status.TransactionDate.$model"
                      placeholder="DD/MM/YYYY HH:mm"
                      :is-valid="hasError(VForm.Status.TransactionDate)"
                      @blur="VForm.Status.TransactionDate.$touch()"
                      size="sm"                      
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                </template>
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <CTextarea
            :label="$t('label.observation')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            maxlength="250"
            size="sm"
            v-uppercase
            v-model.trim="VForm.Status.Observation.$model"
            addLabelClasses="text-right"
            rows="2"
            :is-valid="hasError(VForm.Status.Observation)"
            :invalid-feedback="errorMessage(VForm.Status.Observation)"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol  v-if="!fgInfo" sm="12" lg="auto">
      <CCol class="col-sm-12 col-lg-auto text-right" style="text-align: left;">
        <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmitStatus"
            @click="submit()"
            >
            <CIcon name="checkAlt"/>
        </CButton>
        <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" 
            @click="resetForm()"
            >
            <CIcon name="cil-brush-alt" />
        </CButton>
      </CCol>
    </CCol>

    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedLocationList"
        :fields="fields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              v-if="!ValidatePendingCertification(item.DocumentYardStatusId) && !fgInfo"
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.status'),
                placement: 'top-end'
              }"
              @click="editStatus(item)"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import CertificateOfEntryMixin from '@/_mixins/certificate-of-entry';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    isSubmitStatus: false,
    isEdit: false,
    fgPdf: false,
    StatusLis: [],
    DocumentYardStatusList: [],
  };
}

//methods
async function getYardDocumentBlCargoStatusHistList() {
  await this.$http.get("YardDocumentBlCargoStatusHist-list", { YardDocumentId: this.form.Bl.YardDocumentId })
  .then(response => {
    this.StatusLis = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
}

async function getYardDocumentBlCargoStatusList() {
  await this.$http.get("YardDocumentBlCargoStatus-list")
  .then(response => {
    this.DocumentYardStatusList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
}

async function YardDocumentBlCargoUpdate() {
  try {
    this.VForm.Bl.$touch();
    this.VForm.GeneralData.$touch();
    this.VForm.DescriptionOfGoods.$touch();
    
    if (this.VForm.Bl.$error || this.VForm.GeneralData.$error || this.VForm.DescriptionOfGoods.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let YardDocumentJson = [{
      YardDocumentId: this.form.Bl.YardDocumentId ?? '',
      YardOriginId: this.form.Bl.YardOriginId,
      BlcargoId: this.form.Bl.BlCargoId,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.form.Bl.TransactionDate),
      WeigthReception: this.form.GeneralData.Weight,
      VolumenReception: this.form.GeneralData.Volumen,
      ReceptionBpAct: this.form.Bl.ReceptionBpAct,
      Dua: this.form.Bl.Dua,
      Asycuda: this.form.Bl.Asycuda,
      ReceiverName: this.form.Bl.ReceiverName,
      ReceiverCardId: this.form.Bl.ReceiverCardId,
      DescriptionOfGoods: this.form.DescriptionOfGoods.DescriptionOfGoods ?? '',
    }]

    let metodo = 'PUT';
    let ruta = 'YardDocumentBlCargo-update';

    return await this.$http.ejecutar(metodo, ruta, YardDocumentJson, { root: 'YardDocumentJson' })
      .then(async(response) => {
        await this.$emit('UpdateCertificateOfEntryItem', response.data.data[0]);
        return response.data.data[0];
      }).catch((e) => {
        this.notifyError({text: e});
        return false;
      });
  } catch (e) {
    this.notifyError({text: e});
    return false;
  }
}

async function getYardDocumentBlCargoReport(item) {
  await this.$http.get("YardDocumentBlCargoReport", { YardDocumentId: item.YardDocumentId })
    .then(async(response) => {
      let pdf = response.data.data?.[0] ?? undefined;
      if (pdf) {
        await this.getPdf(pdf, this.$t('label.CertificateEntry'), 1);
      }
    }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
    })
}

async function submitStatus(){
  try {
    this.$emit('Loading', true);
    this.isSubmitStatus = true;
    this.VForm.Status.$touch();

    if (this.VForm.Status.$error) {
      let Error = this.TransactionDateError(this.form.Status.TransactionDate)
      if (Error) {
        throw Error;
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let response = this.FgUpdate ? await this.YardDocumentBlCargoUpdate() : true;

    if (response) {
      let YardDocumentStatusHistJson = {
        YardDocumentId: this.form.Bl.YardDocumentId ?? '',
        YardDocumentStatusHistId: this.form.Status.YardDocumentStatusHistId ?? '',
        DocumentYardStatusId: this.form.Status.DocumentYardStatusId ?? '',
        TransactionDate: this.form.Status.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(this.form.Status.TransactionDate) : '',
        Observation: this.form.Status.Observation ?? '',
      }

      let metodo = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'YardDocumentBlCargoStatusHist-update': 'YardDocumentBlCargoStatusHist-insert';

      this.$http.ejecutar(metodo, ruta, YardDocumentStatusHistJson, { root: 'YardDocumentStatusHistJson' })
        .then(async (responseStatus) => {
          if (!this.isEdit) {
            await this.$emit('UpdateCertificateOfEntryItem', responseStatus.data.data[0]);
          }
          await this.getYardDocumentBlCargoStatusHistList();
          await this.$emit('Update');
          if (this.fgPdf) {
            await this.getYardDocumentBlCargoReport(response);
          }
          this.resetForm();
          this.$emit('Loading', false);
        }).catch((err) => {
          this.isSubmitStatus = false;
          this.$emit('Loading', false);
          this.notifyError({text: err});
        });
    }else{
      this.isSubmitStatus = false;
      this.$emit('Loading', false);
    }
  } catch (e) {
    this.isSubmitStatus = false;
    this.$emit('Loading', false);
    this.notifyError({text: e});
  }
}

function submit() {
  this.$swal.fire(this.alertProperties({
    text: `${this.$t('label.changeStatusQuestion')}?`,
  })).then(async (result) => {
    if (result.isConfirmed) {
      if (this.FgUpdate && this.form.Status.DocumentYardStatusId == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_CERTIFICATE) {
        await this.$swal.fire(this.alertProperties({
          text: `${this.$t('label.ConfirmPdf')}?`,
          confirmButtonText: this.$t('label.yes'),
          cancelButtonText: this.$t('label.no')
        })).then(result => {
          if (result.isConfirmed) {
            this.fgPdf = true;
          }else{
            this.fgPdf = false;
          }
        })
      }
      this.submitStatus();
    }
  })
}

function validateDateRange(date) {
  return date < DateFormater.formatDateRange(this.dateRange.Status.previousDate) || date > DateFormater.formatDateRange(this.dateRange.Status.laterDate);
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function ValidatePendingCertification(DocumentYardStatusId) {
  return DocumentYardStatusId == process.env.VUE_APP_DOCUMENT_YARD_STATUS_ID_PENDING_CERTIFICATION
}

function editStatus(item) {
  this.isEdit = true;
  this.form.Status.YardDocumentStatusHistId = item.YardDocumentStatusHistId ?? '';
  this.form.Status.DocumentYardStatusId = item.DocumentYardStatusId ?? '';
  this.form.Status.TransactionDate = item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '';
  this.form.Status.Observation = item.Observation ?? '';
}

function TransactionDateError(date) {
  if (date) {
    if (this.dateRange.Status.fgStatusDate && DateFormater.formatDateTimeWithoutSlash(date) <= this.dateRange.Status.previousDate) {
      return this.$t('validation.minDateStatusCertificate_LastReception');
    }else if(!this.dateRange.Status.fgStatusDate && DateFormater.formatDateTimeWithoutSlash(date) <= this.dateRange.Status.previousDate) {
      return this.$t('validation.minDateStatusCertificate');
    }else if (DateFormater.formatDateTimeWithoutSlash(date) > this.dateRange.Status.laterDate) {
      return this.$t('label.ErrorCurrentDatedPleaseCheck');
    }
  }else {
    return false
  }
}

function resetForm() {
  this.isEdit = false;
  this.isSubmitStatus = false;
  this.fgPdf = false,
  this.form.Status.YardDocumentStatusHistId = '';
  this.form.Status.DocumentYardStatusId = '';
  this.form.Status.TransactionDate = '';
  this.form.Status.Observation = '';
  this.VForm.Status.$reset();
}

//computed
function documentYardStatusOption() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.DocumentYardStatusList.map(function(e){
    chart.push({
      value: e.DocumentYardStatusId, 
      label: e[`ValueDs${_lang}`] ?? ''
    })    
  })
  return chart;
}

function computedLocationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.StatusLis?.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      DocumentYardStatusPreviousDs: item?.[`DocumentYardStatusPreviousDs${_lang}`] ?? '',
      DocumentYardStatusDs: item?.[`DocumentYardStatusDs${_lang}`] ?? '',
      FormatedTransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
    };
  });
}

function fields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: `DocumentYardStatusPreviousDs`, label: this.$t('label.previousStatus'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: `DocumentYardStatusDs`, label: this.$t('label.newStatus'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'FormatedTransactionDate', label: this.$t('label.statusDate'), _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'Observation', label: `${this.$t('label.observation')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'TransaLogin', label: `${this.$t('label.user')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'FormatedDate', label: `${this.$t('label.date')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px; text-align:center;', sorter: false, filter: false },
  ];
}

function TransactionDate() {
  return this.form.Status.TransactionDate
}

export default {
  name: 'Status',
  components: {
  
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: undefined,
    },
    VForm: {
      type: Object,
      required: true,
      default: undefined,
    },
    dateRange: {
      type: Object,
      required: true,
      default: undefined,
    },
    tab: Boolean,
    showModal: Boolean,
    FgUpdate: Boolean,
    fgInfo: Boolean,
  },
  mixins: [
    GeneralMixin,
    ModalMixin,
    CertificateOfEntryMixin,
  ],
  directives: UpperCase,
  methods: {
    getYardDocumentBlCargoStatusHistList,
    getYardDocumentBlCargoStatusList,
    YardDocumentBlCargoUpdate,
    getYardDocumentBlCargoReport,
    submitStatus,
    submit,
    validateDateRange,
    formatNumber,
    ValidatePendingCertification,
    editStatus,
    TransactionDateError,
    resetForm,
  },
  computed: {
    documentYardStatusOption,
    computedLocationList,
    fields,
    TransactionDate,
  },
  watch: {
    tab: async function (NewVal) {
      if (NewVal) {
        this.$emit('Loading', true);
        await this.getYardDocumentBlCargoStatusList();
        await this.getYardDocumentBlCargoStatusHistList();
        this.$emit('Loading', false);
      }
    },
    showModal: function (NewVal) {
      if (!NewVal) {
        this.resetForm();
      }else {
        this.VForm.Status.$reset();
      }
    },
    TransactionDate: function (NewVal) {
      try {
        let Error = this.TransactionDateError(NewVal)
        if (Error) {
          throw Error;
        }
      } catch (error) {
        this.notifyError({text: error});
      }
    }
  }
};
</script>