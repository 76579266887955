<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <transition name="fade">
      <div class="c-app pdf-page">
        <yard-pdf
          :layoutList="pageInfo.layoutList"
          :yardInfo="pageInfo.yardInfo"
          :legend="legend"
          :areas="pageInfo.areas"
          :teus="pageInfo.teus"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import YardPdf from "@/pages/yard-setting/pdf/yard-pdf";
import ldb from 'localdata';
import debounce from 'lodash/debounce';

//Data
function data() {
  return {
    pageInfo: {
      yardList: [],
      yardInfo: null,
      areas: [],
      teus:0
    },
    docTitle: '',
    legend: [],
    loading: true,
  };
}

//Created
function created() {
  this.loading = true;
  let info = "",
      vm = this;
  ldb.get('pageInfoYard', async function (value) {
    info = JSON.parse(value);
    ldb.delete('pageInfoYard');

    if(!info) {
      window.close();
      return;
    }

    vm.pageInfo = {...vm.pageInfo, ...info};

    await vm.getDataFromService();
    
    window.addEventListener("beforeprint", function(event) {
      // document.title = `FULL CARGO PLAN`;
    });
    window.addEventListener("afterprint", function(event) {
      close();
    });
  });
}

//Methods:
async function getDataFromService() {

  await this.$http.ejecutar('GET', 'YardSlotConfigStatus-list')
  .then(response => {
    this.legend = response.data.data || [];
    this.loading = false;
  }).catch( err => {
    this.loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
//Computed:


export default {
  name: "Reporte-PDF",
  components: {
    YardPdf,
  },
  data,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    })
  }, 500),
  created,
  methods: {
    getDataFromService,
  },
  computed: {

  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>