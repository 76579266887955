import axios from 'axios';
import babelPolyfill from 'babel-polyfill';
import initIncterceptor from '../_helpers/interceptor';
import DataStorage from '@/_helpers/localStorage';
import { store } from '@/_store';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}`;
//axios.defaults.timeout = 3000;
axios.defaults.timeout = 320000;

initIncterceptor();

const TimeOut = (seconds) => {
  let max = 320000,
    min = 0,
    timeOut = seconds? (seconds*3000):max;

  if(timeOut > 320000)
    timeOut = max;

  if(timeOut < 0)
    timeOut = min;

  return {
    timeout: timeOut
  }
}

let dataUserLoguedId = '';

const Errores = (err) => {
  let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
  let connectionErrorMsg = lang == 'ES' ? "ERROR DE CONEXION" : "CONNECTION ERROR";
  if (err.response) {
    if (err.response.data.data!==null) { 
      if (err?.response?.data?.data[0]?.Json) {
        throw new Error(err.response.data.data[0]?.Json.Response);
      }else{
        throw new Error(err.response.data.data.data);
      }
    } else {
      throw new Error(`${connectionErrorMsg}`);
    }    
  } else if(err.code){
    throw new Error(`${connectionErrorMsg}`);
  } else if (err.request) {
    throw new Error(`${connectionErrorMsg}`);
  } else {
    throw new Error(err);
  }
};

function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { 
      'Authorization': `Bearer ${user.token}` ,
      "Access-Control-Allow-Origin": "*"
    };
  } else {
    return { };
  }
}
function parseConfig(cfg) {
  let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
  let defaultConfig = {
    timeout: 320000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Language": `${lang}`
    }
  }

  let root = cfg.root?cfg.root:null;
  let config = {
    timeout: cfg.timeout ? TimeOut(cfg.timeout) : defaultConfig.timeout,
    headers: cfg.headers
      ?{...defaultConfig.headers, ...cfg.headers, ...authHeader()}
      : {...defaultConfig.headers, ...authHeader()},
    baseURL: `${DataStorage.getConnection()}apptest/services/`
  };


  return {'root': root, 'requestConfig': config};
}
function getCurrentBaseURL(type = 1) {
  return {
    baseURL: type == 1 ? `${DataStorage.getConnection()}apptest/services/` : `${DataStorage.getConnection()}apptest/`
  };
}

//Si hay un error esto notificara
function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
  });
}

class Service{
  async get(ruta, parametro) {
    try {
      return await axios.get(ruta, {
        params: parametro,
        headers: authHeader(),
        ...getCurrentBaseURL(),
      });
    } catch(err) {
      //aqui vamos darle con lo del boton;
      if(err.message.search("timeout") < 0){
        Errores(err);
      } else {
        //if (!store.state.request.pendings.find({ruta: ruta, parametro: parametro}))
          await store.state.request.pendings.push({ruta, parametro});
        throw (err);
      }
    }
  }
  async getFile(ruta, parametro, config = {}) {
    try {
      let {root, requestConfig} = parseConfig(config);
      requestConfig.baseURL = `${DataStorage.getConnection()}apptest/files/`;
      return await axios({
        method: 'get',
        url: ruta,
        params: parametro,
        headers: authHeader(),
        baseURL: requestConfig.baseURL
      });
    } catch(err) {
      Errores(err);
    }
  }
  async getInformationPaislist() {
    try {
      //return await fetch('https://restcountries.eu/rest/v2/all').then(handleResponse).then(response => {
        return await fetch('https://restcountries.com/v3.1/all').then(handleResponse).then(response => {
        return response;
      });
    } catch(err) {
      Errores(err);
    }
  }
  async post(ruta, parametro, config = {}) {
    try {
      let {root, requestConfig} = parseConfig(config);
      let params = {};

      if(parametro instanceof FormData) {
        params = parametro;
        requestConfig.baseURL = config.baseURL
          ? config.baseURL
          : `${DataStorage.getConnection()}apptest/files/`;
        requestConfig.headers = {
            ...requestConfig.headers,
            ...{ 'Content-Type': 'multipart/form-data'}
        };
      } else {
        params = new URLSearchParams();
        params.append(root, JSON.stringify({ [root]: Array.isArray(parametro)?[...parametro]:[parametro]}));
      }

      if(config.onUploadProgress){
        requestConfig.onUploadProgress = config.onUploadProgress;
      }
      if(config.onDownloadProgress){
        requestConfig.onDownloadProgress = config.onDownloadProgress;
      }

      return await axios.post(ruta, params, requestConfig);
    } catch (err) {
      Errores(err);
    }
  }
  
  async delete(ruta, params) {
    try {
      if (params) {
        params = {files: Array.isArray(params) ? 
          params.map(item =>{ 
            if (item) {
              return `Public/${item}`
            }
          }) : [`Public/${params}`]}
        return await axios.delete(ruta, { 
          headers: authHeader(),
          ...getCurrentBaseURL(2),
          data: params,
        });
      }else{
        let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
        let connectionErrorMsg = lang == 'ES' ? "FORMATO REQUERIDO URL" : "REQUIRED FORMAT URL";
        throw connectionErrorMsg;
      }
    } catch(err) {
      Errores(err);
    }
  }

  async postJSON(ruta, parametro) {
    try {
      let lang = DataStorage.getLocale()? (DataStorage.getLocale()).toUpperCase():'ES';
      let defaultConfig = {
        timeout: 320000,
        headers: {
          // 'Content-Type': 'application/json',
          "Language": `${lang}`
        }
      }
      let config = {
        baseURL: `${DataStorage.getConnection()}apptest/`,
        timeout: defaultConfig.timeout,
        headers: {...defaultConfig.headers, ...authHeader()}
      };

      let params = {};
      params = parametro;

      return await axios.post(ruta, params, config);
    } catch (err) {
      Errores(err);
    }
  }
  async put(ruta, parametro, config = {}) {
    try {
      let {root, requestConfig} = parseConfig(config);
      let params = {};

      if(parametro instanceof FormData) {
        params = parametro;
        requestConfig.baseURL = `${DataStorage.getConnection()}apptest/files/`;
        requestConfig.headers = {
            ...requestConfig.headers,
            ...{ 'Content-Type': 'multipart/form-data'}
        };
      } else {
        params = new URLSearchParams();
        params.append(root, JSON.stringify({ [root]: Array.isArray(parametro)?[...parametro]:[parametro]}));
      }

      return await axios.put(ruta, params, requestConfig);
    } catch (err) {
      Errores(err);
    }
  }
  async file(ruta, nuevo = null, guardado = null) {
    try {
      let metodo = guardado?'PUT':'POST';
      let params = new FormData();
      
      if(guardado){
        let filesToDelete = Array.isArray(guardado) ? guardado : new Array(guardado);
        params.append('filesToDelete', JSON.stringify(filesToDelete));
      }

      if(Array.isArray(nuevo) && nuevo.length > 0){
        nuevo.forEach((item, index) => {
          params.append(`file${index+1}`, item);
        });
      }else
        params.append('file1', nuevo);

      return await this.ejecutar(metodo, ruta, params);
    } catch (err) {
      Errores(err);
    }
  }

  getDataUserLogedId() {
    return dataUserLoguedId;
  }

  ejecutar(metodo, ruta, parametro, config = {}) {
    let method = metodo.toUpperCase();
    
    switch (method) {
      case "GET":
        return this.get(ruta,parametro);
      case "POST":
        return this.post(ruta,parametro, config);
      case "PUT":
        return this.put(ruta,parametro, config);
      case "DELETE":
        return this.delete(ruta,parametro, config);
      default:
        return Errores("Error en el método");
    }
  }
}

export default new Service();

export const AuthHeader = () => authHeader();
