<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <loading-overlay
        :active="loadingPdf"
        :is-full-page="true"
        loader="bars"
      />
      <stowage-plan-pdf
        :Condition="Condition"
        :vessel="vessel"
        :TpVesselId="pageInfo.TpVesselId"
        :FgGeneralCargo="pageInfo.FgGeneralCargo"
        :commodity="commodity"
        :BlJson="BlJson"
        :positions="positions"
        :holds="holds"
        :departure="departure"
        :DeckJson="DeckJson"
        :BlDeckJson="BlDeckJson"
        :HatchCoverJson="HatchCoverJson"
      />
    </div>
  </transition>
</template>

<script>
import StowagePlanPdf from "./stowage-plan-pdf";
import debounce from "lodash/debounce";

//Data
function data() {
  return {
    Condition: 1,
    pageInfo: {
      StowagePlanningId: '',
      TpVesselId: '',
      FgGeneralCargo: false,
    },
    vessel: [],
    commodity: [],
    positions: [],
    holds: [],
    departure: [],
    BlJson: [],
    DeckJson: {},
    BlDeckJson: [],
    HatchCoverJson: [],
    loadingPdf: false,
  };
}

//Created
function created() {
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = { ...this.pageInfo, ...info };

  this.getDataFromService(this.pageInfo.StowagePlanningId, this.pageInfo.FgGeneralCargo);

  localStorage.removeItem("pageInfo");

  window.addEventListener("beforeprint", function (event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function (event) {
    close();
  });
}

//Methods
async function getDataFromService(id, FgGeneralCargo) {
  await this.$http.ejecutar("GET", "StowagePlanReport", { StowagePlanningId: id })
    .then(async(response) => {
      let StowagePlanReport = response.data.data&&response.data.data[0] ? response.data.data[0] : {};
      this.vessel = StowagePlanReport?.VesselDataJson ?? [];
      let Commodity = StowagePlanReport?.CommodityDataJson ?? [];
      if (!FgGeneralCargo) {
        await this.CommodityLength(Commodity, 9);
        this.Condition = this.commodity.length;
      }else{
        let DeckJson = StowagePlanReport?.DeckJson && StowagePlanReport?.DeckJson[0] ? StowagePlanReport?.DeckJson[0] : {};
        let HatchCoverJson = DeckJson?.HatchCoverJson ?? [];
        let BlDeckJson = DeckJson?.BlDeckJson ?? [];
        await this.CommodityLength(Commodity, 32, FgGeneralCargo);
        this.BlJson = await this.BlLength(Commodity, 32);
        DeckJson.HatchCoverJson = DeckJson?.HatchCoverJson?.map(HatchCover => {
          let Total = HatchCover?.BlDeckHatchCoverJson.reduce((accumulator, currentValue) => accumulator + (currentValue?.WeightAsigned ?? 0), 0)
          return {
            ...HatchCover,
            Total: Total,
          }
        });
        this.DeckJson = DeckJson;
        this.HatchCoverJson = await this.HatchCoverPage(HatchCoverJson, 21);
        this.BlDeckJson = await this.BlDeckLength(BlDeckJson, 21);
        this.Condition = 1;
      }
      this.positions = StowagePlanReport?.PositionsJson ?? [];
      this.positions.map((item) => {
        if (item.FgHold) {
          this.holds.push(item.PositionJson[0]);
        }
      });
      this.departure = StowagePlanReport?.DepartureJson ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function CommodityLength(CommodityIndex, Max, FgGeneralCargo) {
  let Count = 0;
  let n = 0;
  let i = 0;
  for (n = 0; n < CommodityIndex.length; n++) {
    Count++;
    for (i = 0; i < (CommodityIndex[n].CommodityHold.length); i++) {
      Count++;
    }  
  }
  if (Count > Max) {
    let position = 0;
    let size = 0;
    let ObjectCommodity = [];
    let less = 0;
    for (n = 0; position < CommodityIndex.length; n++) {
      size = Max;
      ObjectCommodity = [];
      for (i = 0; position < CommodityIndex.length  && ((CommodityIndex[position].CommodityHold.length+1)<=size) ; i++) {
        if (!FgGeneralCargo || CommodityIndex[position].CommodityHold.length!=0) {
          less = CommodityIndex[position].CommodityHold.length+1
          ObjectCommodity.push(CommodityIndex[position]);
          size = (size - less); 
        }
        position = position + 1;
      }
      this.commodity.push({
        index: ObjectCommodity,
      });
    }
  } else {
    this.commodity.push({
      index: !FgGeneralCargo ? CommodityIndex : CommodityIndex.filter(item => item.CommodityHold.length != 0),
    });
  }
}

function BlLength(CommodityJson, Max) {
  let BlList = [];
  let Count = 0;
  CommodityJson.forEach(item => {
    Count++;
    item.ExternalPackagingJson.forEach(Packaging => {
      Count++; 
    });
  });
  if (Count > Max) {
    let position = 0;
    let size = 0;
    let ObjectCommodity = [];
    let less = 0;
    for (let n = 0; position < CommodityJson.length; n++) {
      size = Max;
      ObjectCommodity = [];
      for (let i = 0; position < CommodityJson.length  && ((CommodityJson[position].ExternalPackagingJson.length+1)<=size) ; i++) {
        less = CommodityJson[position].ExternalPackagingJson.length+1
        ObjectCommodity.push(CommodityJson[position]);
        position = position + 1;
        size = (size - less);
      }
      BlList.push(ObjectCommodity);
    }
    return BlList;
  } else {
    BlList.push(CommodityJson)
    return BlList;
  }
}

async function HatchCoverPage(HatchCoverJson, Max) {
  if (HatchCoverJson.length != 0) {
    let ListAux = [];
    let HatchCoverList = [];
    let HatchCoverAux = [];
    for (let indexMaster = 0; indexMaster < HatchCoverJson.length; indexMaster++) {
      ListAux.push(HatchCoverJson[indexMaster]);
      if (Number.isInteger((indexMaster+1)/2)) {
        HatchCoverAux = [];

        ListAux[0].BlDeckHatchCoverJson = await this.BlDeckLength(ListAux[0].BlDeckHatchCoverJson, Max);
        ListAux[1].BlDeckHatchCoverJson = await this.BlDeckLength(ListAux[1].BlDeckHatchCoverJson, Max);
        
        let length = ListAux[0].BlDeckHatchCoverJson.length > ListAux[1].BlDeckHatchCoverJson.length ? ListAux[0].BlDeckHatchCoverJson.length : ListAux[1].BlDeckHatchCoverJson.length;
        for (let index = 0; index < length; index++) {
          HatchCoverAux.push(ListAux);
          HatchCoverAux[index] = HatchCoverAux[index].map((HatchCover, indexHatchCover) => {
            return {
              ...HatchCover,
              BlDeckHatchCoverJson: ListAux[indexHatchCover]?.BlDeckHatchCoverJson[index] ?? [],
            }
          })
        }
        HatchCoverList.push(...HatchCoverAux);
        ListAux = [];
      }
      
    }
    if (ListAux.length == 1) {
      HatchCoverAux = [];
      ListAux[0].BlDeckHatchCoverJson = await this.BlDeckLength(ListAux[0].BlDeckHatchCoverJson, Max);
      ListAux[0].BlDeckHatchCoverJson.forEach((item, index) => {
        HatchCoverAux.push(ListAux);
        HatchCoverAux[index] = HatchCoverAux[index].map((HatchCover, indexHatchCover) => {
          return {
            ...HatchCover,
            BlDeckHatchCoverJson: ListAux[indexHatchCover]?.BlDeckHatchCoverJson[index] ?? [],
          }
        })
      })
      HatchCoverList.push(...HatchCoverAux);
    }
    return HatchCoverList;
  } else {
    return [];
  }
}

async function BlDeckLength(BlDeckJson, Max) {
  let List = [];
  let SubList = [];
  let Total = {
    QuantityAsigned: 0,
    WeightAsigned: 0,
  }
  if (BlDeckJson.length!=0) {
    BlDeckJson.forEach((item, index) => {
      if (!Number.isInteger((index+1)/Max)) {
        SubList.push(item);
        Total.QuantityAsigned += item.QuantityAsigned;
        Total.WeightAsigned += item.WeightAsigned;
      }else{
        List.push(SubList);
        SubList = [];
        SubList.push(item);
        Total.QuantityAsigned += item.QuantityAsigned;
        Total.WeightAsigned += item.WeightAsigned;
      }
    })
    SubList.push({FgTotal: true, ...Total})
    List.push(SubList);
  }
  return List;
}

//Computed:

export default {
  name: "Stowage-Plan-PDF-Report",
  components: {
    StowagePlanPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
  methods: {
    CommodityLength,
    getDataFromService,
    BlLength,
    HatchCoverPage,
    BlDeckLength,
  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
