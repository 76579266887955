
<template>
    <CRow class="mt-3">
        <CCol col="12" xl="12" >
            <CCol sm="12">
                <CRow>
                    <CCol sm="12" class="d-flex align-items-center justify-content-end">
                        <CButton
                            color="add"
                            @click="activarModal()"
                            v-c-tooltip="{
                                content: $t('label.name')+' '+$t('label.status'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                        </CButton>
                        <CButton
                            color="excel"
                            style="margin-left:5px;"
                            @click="generarXLS(true)"
                            v-c-tooltip="{
                                content: $t('label.exportToExcel'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XLSX
                        </CButton>
                    </CCol>
                </CRow>
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #TpCargoStatusId="{ index }">
                        <td class="center-cell">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="center-cell">
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="center-cell">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.edit')+' '+ $t('label.name')+' '+$t('label.status'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';
    function fields(){
        return [
            { key: 'TpCargoStatusId',label: '#', filter:false, _style: 'width:4%; text-align:center', },
            { key: 'TpCargoStatusName',label: this.$t('label.name')+' '+this.$t('label.status'), _style:'width:28%;' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:24%;',},
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:24%;', _classes: 'text-center'},
            { key: 'Status', label: this.$t('label.status'),  _style:'width:15%; text-align:center;',},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 50px; width:5%', 
                sorter: false, 
                filter: false
            },
        ];
    }

    //data
    function data() {
        return {
            activePage: 1,
        }
    }

    //methods
    function activarModal(){
        this.$store.state.contenedores.modalStatus = true;
    }
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.statusName')] = item.TpCargoStatusName;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'StatusDeContenedores' : 'ContainerStatus';

            await this.getExcelArray(itemsArray, this.$t('label.containerLists.statusLists'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }
    function updateModal(item,index){
        this.$store.state.contenedores.modalStatus = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoStatusId);
    }
    

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataStatustable"];
    }

    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }

    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 7){
            this.$store.dispatch('contenedores/getStatuslist');
        }
    }
    
    export default {
        name: 'index-status',
        data,
        props: ['loading'],    
        mixins:[
            GeneralMixin,
            Reportes
        ],
        methods: {
            activarModal,
            updateModal,
            generarXLS
        },
        computed: {
            computedItems,
            formatedItems,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
            })
        },
        watch:{
            tabIndex
        }
    }   
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>