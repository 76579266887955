
class DataStorage {
  setUser(rawData) {
    localStorage.setItem('user', JSON.stringify({
      ...rawData.Json.UserJson[0],
      FgFirstTime: rawData.FgFirstTime,
      token: rawData.token,
      refreshToken: rawData.refreshToken
    }));
  }
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  deleteUser() {
    if(localStorage.getItem('user')){
      localStorage.removeItem('user');
      localStorage.removeItem('repository');
      this.deleteConfig();
    }
  }

  setInitConfiguration(rawData) {
    localStorage.setItem('configuration', JSON.stringify({
      role: rawData.role,
      branch: rawData.branch,
      routesByRole: rawData.routesByRole
    }));
  }
  deleteConfig() {
    if(localStorage.getItem('configuration'))
      localStorage.removeItem('configuration');
  }
  getConfiguration() {
    return JSON.parse(localStorage.getItem('configuration'));
  }
  getRole() {
    let config = JSON.parse(localStorage.getItem('configuration'));

    return config ? config.role : null;
  }
  changeRole(role) {
    let config = JSON.parse(localStorage.getItem('configuration'));
    if(config) {
      config.role = role;
      localStorage.setItem('configuration', JSON.stringify(config));
    }
  }
  getBranch() {
    let config = JSON.parse(localStorage.getItem('configuration'));

    return config ? config.branch : null;
  }
  changeBranch(branch) {
    let config = JSON.parse(localStorage.getItem('configuration'));
    if(config) {
      config.branch = branch;
      localStorage.setItem('configuration', JSON.stringify(config));
    }
  }
  getYard() {
    let config = JSON.parse(localStorage.getItem('configuration'));

    return config ? config.yard: null;
  }
  changeYard(yard) {
    let config = JSON.parse(localStorage.getItem('configuration'));
    if(config) {
      config.yard = yard;
      localStorage.setItem('configuration', JSON.stringify(config));
    }
  }
  getRoutesByRole() {
    let config = JSON.parse(localStorage.getItem('configuration'));

    return config ? config.rutesByRole : [];
  }
  changeRoutesByRole(routes) {
    let config = JSON.parse(localStorage.getItem('configuration'));
    if(config){
      config.routesByRole = routes;
      localStorage.setItem('configuration', JSON.stringify(config));
    }
  }

  setLocale(lang) {
    localStorage.setItem('locale', lang);
  }
  getLocale() {
    let locale = localStorage.getItem('locale');

    return locale ? locale : null;
  }
  setConnection(url) {
    localStorage.setItem('repository', url);
  }
  getConnection() {
    let repository = localStorage.getItem('repository');

    return repository ? repository : '';
  }
}

export default new DataStorage();