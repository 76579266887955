<template>
    <div>
        <apexchart type="bar" :height="height" width="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    function series(){
        return [{
            data: this.seriesGrafica
        }];
    }
    function chartOptions(){
        return {
            chart: {
                height: this.height,
                type: 'bar',
                events: {
                    click: function(chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            colors: this.colors,
            plotOptions: {
                bar: {
                    columnWidth: '35%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: this.categories,
                labels: {
                    style: {
                        colors: this.colors,
                        fontSize: this.textSize
                    }
                }
            },
            yaxis: {
                show: false,
                showAlways: false,
                showForNullSeries: false,
            },
            tooltip: {
                x: {
                    formatter: function(val) {
                        return val
                    }  
                },
                y: {
                    formatter: function(val) {
                        return val;
                    },
                    title: {
                        formatter: (seriesName, { series, seriesIndex, dataPointIndex, w }) => {
                            if(this.legendsTooltip.length > 0){
                                return this.legendsTooltip[dataPointIndex];
                            }   
                            return "";
                        },
                    },
                },
            },
        }
    }
    export default {
        name:'ColumnsDistruibuid',
        props:{
            height: {
                type: String,
                required: true,
                default: '380px',
            },
            textSize: {
                type: String,
                required: false,
                default: '13px',
            },
            seriesGrafica: {
                type: Array,
                required: true,
                default: () => [],
            },
            colors: {
                type: Array,
                required: true,
                default: () => [],
            },
            categories: {
                type: Array,
                required: true,
                default: () => [],
            },
            legendsTooltip:{
                type: Array,
                required: false,
                default: () => [],
            }
        },
        computed:{
            series,
            chartOptions
        }
    }
</script>