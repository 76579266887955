import {
  required,
} from "vuelidate/lib/validators";

export default () => {
  return {
    formArchivos: {
      TpEdiId: { required },
      TpEdiTransacId: { },
    },
    docFile: { required }
  }
}

export const ArchivosAllRequiredValidations = () => {
  return {
    formArchivos: {
      TpEdiId: { required },
      TpEdiTransacId: { required },
    },
    docFile: { required }
  }
}