import Service from '@/_services/service';
import { router } from '@/_helpers/router';
import { slugify } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

const config = DataStorage.getConfiguration();
const initialConfig = config
  ? { routesByRole: config.routesByRole }
  : { routesByRole: [] };

export const menu = {
  namespaced: true,
  state: {
    routesInfo: null,
    ...initialConfig
  },
  actions: {
    getMenuInfo({ commit, dispatch }, RoleId) {
      return Service.ejecutar('GET', 'Menu-by-role', { RoleId: RoleId })
      .then(
        routes => {
          if (routes.data.data[0]) {
            commit('menuSuccess', routes.data.data[0]);
            dispatch('auth/setRole', RoleId, { root: true });
            return Promise.resolve(routes.data.data[0]);
          }else{
            commit('menuFailure');
            return Promise.reject('menu');
          }
        },
        error => {
          commit('menuFailure');
          return Promise.reject(error);
        }
      );
    },
    remakeMenu({ commit }, lang) {
      commit('remake', lang);
    },
  },
  mutations: {
    menuSuccess(state, routes) {
      state.routesInfo = routes;
      state.routesByRole = generateMenu(state.routesInfo.LevelOneJson);
      DataStorage.changeRoutesByRole(state.routesByRole);
    },
    menuFailure(state) {
      state.routesInfo = null;
      state.routesByRole = [];
    },
    remake(state, lang) {
      state.routesByRole = generateMenu(state.routesInfo.LevelOneJson, lang);
      DataStorage.changeRoutesByRole(state.routesByRole);
    },
  }
};

function generateMenu(LevelOneJson, locale = '') {
  let onlyRoutes = [];
  let routesByRole = [];

  let lang = locale
    ? currentLang(locale)
    : DataStorage.getLocale() ? currentLang(DataStorage.getLocale()) : 'ES';

  if(Array.isArray(LevelOneJson) && LevelOneJson.length != 0){
    for (let i = 0; i < LevelOneJson.length; i++) {
      for (let j = 0; j < LevelOneJson[i].LevelTwoJson.length; j++) {
        onlyRoutes = [...onlyRoutes, ...LevelOneJson[i].LevelTwoJson[j].LevelTreeJson];
      }    
    }
  }

  onlyRoutes.forEach((ruta) => {
    let address = () => import(`@/pages/${ruta.ModuleRoute.replace('@/pages/', '')}`);

    let route = {
      path: ruta.Path ? ruta.Path : slugify(ruta['ModuleName'+lang]),
      name: ruta['ModuleName'+lang],
      meta: {
        label: `${ruta['ModuleName'+lang]}`,
        process: ruta.ProcessRoute?`${DataStorage.getConnection()}${ruta.ProcessRoute.replace('Public/', '', null, 'i')}`:'',
        manual: ruta.ManualRoute?`${DataStorage.getConnection()}${ruta.ManualRoute.replace('Public/', '', null, 'i')}`:''
      },
      component: address
    };

    router.addRoute('Home', route);
    routesByRole.push(route);
    extraRoutes(ruta.ModuleRoute.replace('@/pages/', ''), lang);
  });

  return routesByRole;
}

function extraRoutes(name, lang) {
  switch (name) {
    case 'buque':
      if(lang == 'ES') {
        let routeConfig = [
          {
            path: 'buque/add',
            name:  'AddBuque',
            component:  () => import('@/pages/buque/add-buque'),
            meta: { label:'Registrar Buque' }
          },
          {
            path: 'buque/edit',
            name:  'EditBuque',
            props: true,
            component: () => import('@/pages/buque/edit-buque'),
            meta: { label:'Modificar Buque' }
          }
        ];
        routeConfig.forEach(route => {
          router.addRoute('Home', route);
        });
      }
      if(lang == 'EN') {
        let routeConfig = [
          {
            path: 'vessel/add',
            name:  'AddBuque',
            component:  () => import('@/pages/buque/add-buque'),
            meta: { label:'Add Vessel' }
          },
          {
            path: 'vessel/edit',
            name:  'EditBuque',
            props: true,
            component: () => import('@/pages/buque/edit-buque'),
            meta: { label:'Edit Vessel' }
          }
        ];
        routeConfig.forEach(route => {
          router.addRoute('Home', route);
        });
      }
      break;
    default:
      break;
  }
}

function currentLang(lang) {
  return lang.toUpperCase();
}