<template>
    <div sm="12">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow>
            <CCol sm="12" class="text-right mb-2 mt-2">
                <CButton
                    square
                    color="add"
                    v-c-tooltip="{
                    content: $t('label.UnitMeasurementAssociation'),
                    placement: 'top-end'
                    }"
                    @click="ModalmeasurementUnits=true"
                >
                    <CIcon name="cil-playlist-add" /><span class="ml-1"
                    >{{$t('label.nueva')}}</span
                    >
                </CButton>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                class="align-center-row-datatable"
                    size="lg"
                    :fields="MeasurementUnitsfields"
                    :items="computedMeasurementUnits"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #Status="{item}">
                    <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                    </template>
                    <template #Detalle="{ item }">
                    <td class="text-center">
                        <CButton
                        size="sm"
                        color="edit"
                        class="mr-1"
                        v-c-tooltip="{
                            content: $t('label.edit')+$t('label.UnitMeasurementAssociation'),
                            placement: 'top-end',
                        }"
                        @click="EditMeasurementUnits(item)"
                        >
                        <CIcon name="pencil" />
                        </CButton>
                    </td>
                    </template>
                </dataTableExtended>
            </CCol>
            <ModalMeasurementUnits
            :modal.sync="ModalmeasurementUnits"
            :Edit="isEdit"
            :MeasurementUnits="MeasurementUnits"
            @set-Measurement-Units-list="closeModal"
            />
        </CRow>
    </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalMeasurementUnits from './modal-measurement-units';

function data() {
  return {
    MeasurementUnitsItem: [],
    MeasurementUnits: {},
    ModalmeasurementUnits: false,
    isEdit: false,
    loadingOverlay: false,
  };
}

function MeasurementUnitsfields(){
  return [
    { 
      key: 'Nro', 
      label: '#', filter: false,
      _style: 'width:3%',
      _classes: 'text-center',
    },          
    { key: 'SupplyName', label:this.$t('label.Supplies'), _style: 'width:25%;', _classes: 'text-center', },
    { key: 'UnitMeasureName', label:this.$t('label.MeasurementUnit'), _style: 'width:25%;', _classes: 'text-center', },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:15%' },
    { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:15%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center', _style:'width:15%' },
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'min-width:45px; width:1%; text-align:center;'
    }
  ];
}

function EditMeasurementUnits(item) {
  this.isEdit = true;
  this.MeasurementUnits = item;
  this.ModalmeasurementUnits = true;
}

function closeModal() {
  this.ModalmeasurementUnits = false;
  this.isEdit = false;
  this.getSupplyUnitMeasureList();
}

async function getSupplyUnitMeasureList () {
  this.loadingOverlay = true;
  this.MeasurementUnitsItem = [];
  await this.$http.get("SupplyUnitMeasure-list")
  .then(response => {
    let listado = response.data.data;
    if (listado.length > 0) {
      this.MeasurementUnitsItem = listado;
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function computedMeasurementUnits() {
  if (this.MeasurementUnitsItem ==[]) return [];
  if (this.MeasurementUnitsItem.length > 0) {
    return this.MeasurementUnitsItem.map((item) => {
      return {
        ...item,
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
      };
    });
  }
}

export default {
  name: 'index-insumo',
  data,
  mixins: [GeneralMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalMeasurementUnits,
  },
  methods: {
    closeModal,
    EditMeasurementUnits,
    getSupplyUnitMeasureList,
  },  
  watch: {
    active: function(newVal){
        if (newVal) {
          this.getSupplyUnitMeasureList();
        }
    }
  },
  computed: {
    computedMeasurementUnits,
    MeasurementUnitsfields,
  },
};
</script>