<template>
    <div>
        <CRow class="my-2">
            <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                <CButton
                    color="edit"
                    class="mr-2"
                    v-c-tooltip="{
                        content: $t('label.return'),
                        placement: 'top',
                    }"
                    size="sm"
                    @click="Reset"
                >
                    <CIcon name="cil-chevron-left-alt" />
                    
                </CButton>
                <CRow class="align-items-center justify-content-start w-100 mt-2 m-0">
                    <h6>
                        {{`${ this.isEdit ? $t('label.editBlReception') : $t('label.newBlReception')}: ${this.ContainerItem.BlNro}`}} 
                    </h6>
                    <!--
                        <CRow class="flex-grow-1 align-items-center justify-content-end">
                            <CCol sm="12" lg="auto">
                                <h6>{{`${$t('label.discharged')}: ${FormatNumber(this.ContainerItem.QuantityStowage, 2)}`}} </h6>
                            </CCol>
                            <CCol sm="12" lg="auto">
                                <h6>{{`${$t('label.received')}: ${FormatNumber(this.ContainerItem.QuantityReceived, 2)} `}} </h6>
                            </CCol>
                            <CCol sm="12" lg="auto">
                                <h6>{{`${$t('label.pendingReceive')}: ${FormatNumber(this.ContainerItem.QuantityRemainReceived, 2)}`}} </h6>
                            </CCol>
                        </CRow>
                    -->
                </CRow>
            </CCol>
        </CRow>

        <CRow class="mb-3">
            <CCol sm="12">
                <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
                    <CTab :title="$t('label.basicData')" :class="(this.$v.Form.BasicData.$anyError)? 'tab-error' : ''">
                        <BasicData
                            :Form="Form"
                            :ModuleForm="Form.BasicData"
                            :isEdit="isEdit"
                            :Tab="tabIndex==0"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            :VForm="$v.Form" 
                            :isWasRequested="isWasRequested"
                            :isSumited = "Form.Packaging.isSumited"
                            @UpdateSubmit = "(event)=>{Submit(event)}"
                        />
                    </CTab>

                    <CTab :title="$t('label.packaging')" :class="((this.$v.Form.DetailJson.$anyError) || this.Form.isErrorSerial)? 'tab-error' : ''">
                        <Packaging
                            :Form="Form"
                            :ModuleForm="Form.Packaging"
                            :Quantity="Form.Packaging.Quantity"
                            :SealForm="Form.Packaging.Seal"
                            :isEdit="isEdit"
                            :Tab="tabIndex==1"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            :isWasRequested="isWasRequested"
                            @UpdatePackagingList="(event)=>UpdatePackagingList(event)"
                            @PackagingListOnList="(event)=> Form.Packaging.PackagingList = event"
                        />
                    </CTab>

                    <CTab :title="$t('label.logistics')">
                        <Logistic
                            :Form="Form"
                            :ModuleForm="Form.Logistic"
                            :isEdit="isEdit"
                            :Tab="tabIndex==2"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            :isWasRequested="isWasRequested"
                            :VForm="$v.Form" 
                        />
                    </CTab>
                </CTabs>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!isWasRequested">
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="statusConfirmation((ContainerItem.Status == 'INACTIVO' || ContainerItem.Status == 'INACTIVE') ? 0 : 1, Form.BasicData.Status, Submit)"
                    :disabled="isSubmit"
                 >
                    <CIcon name="checkAlt"/>{{$t('button.accept')}}
                </CButton>
            </CCol>
        </CRow>
    </div>
    
</template>
<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import ModalMixin from '@/_mixins/modal';
    import General from '@/_mixins/general';
    import BlReceptionFormValidations from '@/_validations/yard-management/warehouse/blReceptionValidations';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import Logistic from './tabs/logistic';
    import Packaging from './tabs/packaging';
    import BasicData from './tabs/basicData';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    //data
    function data() {
        return {
            //General
            tabIndex: -1,
            isSubmit: false,
            maquinaryIsRequired: false,
            totalQuantity: 0,
            isWasRequested: true,
            blSelected: '',

            //Form
            Form:{
                isErrorSerial: false,
                //Form Basic
                BasicData: {
                    MachineId: '',
                    TransactionDate: '',
                    TpEirId: process.env.VUE_APP_RECEPTION_BL_ID_TP_EIR,
                    TpEirTransacId: '',
                    YardName: '',
                    DocumentComment: '',
                    DocumentServiceComment: '',
                    DocumentJson:'',
                    Observation: '',
                    Status: 1,
                    laterDate: new Date,
                    ValidPreviousDate: new Date,
                    previousDate:  DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO),
                },

                //Form Documents
                Documents: {
                    DocumentDs: '',
                },

                //Form Packaging
                Packaging: {
                    YardAreaId: '',
                    VisitBillOfLadingId: '',
                    maxPackagingQuantity: 0,
                    maxPackagingWeight: 0,
                    maxPackagingVolumen: 0,
                    BlName: '',
                    Imo: '',
                    ImdgId:'',
                    ImdgClass: '',
                    ImdgClassId: '',
                    UnNumber: '',
                    UnNumberId: '',
                    PackagingGroup: '',
                    PackagingGroupId: '',
                    PackagingId: '',
                    BlCargoMasterDetailId: '',
                    VisitBillOfLadingPackagingId: '',
                    Quantity: 0,
                    Volumen: 0,
                    Length: 0,
                    Width: 0,
                    Height: 0,
                    VolumeCheck: false,
                    Weigth: 0,
                    PackagingItem: {},
                    SerialList: [],
                    AccesoryList: [],
                    PackagingList: [],

                    //Seals 
                    Seal: {
                        Seal1: {
                            Seal: '',
                        },
                        Seal2: {
                            Seal: '',
                        },
                        Seal3: {
                            Seal: '',
                        },
                        Seal4: {
                            Seal: '',
                        }
                    },

                    //Serialitation
                    Serialization: {
                        SerialItem: {},
                        VolumeCheck: false,
                        IsNewSerial: false,
                        Id: '',
                        Serial: '',
                        Serialized: '',
                        PackagingId: '',
                        Weight: 0, 
                        Volumen: 0,
                        Length: 0,
                        Width: 0,
                        Height: 0,
                        DescriptionOfGoods: 0
                    },
                    //SERIAL IMDG
                    SerialImdg: {
                        id: 0,
                        ImdgList: [],
                        ImdgClass: '',
                        ImdgId: '',
                        PackagingGroup: '',
                        UnNumber: '',
                        DescriptionGoods: ''
                    }
                },

                //Form Logistic
                Logistic: {
                    CarrierId: '',
                    numberIdLetter: '',
                    SearchDriver: '',
                    DriverId: '',
                    SearchPlate: '',
                    VehicleId: '',
                },

                DetailJson: [],
            },
        };
    }

    //-----------------------   Method    ------------------------

    function FormatNumber(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    function UpdatePackagingList(event) {
        this.Form.DetailJson = event;
    }

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    function Reset(isclose = true) {
         //General
        this.tabIndex = -1;

        //Form
        this.Form.isErrorSerial = false;
        this.Form.BasicData.MachineId = '';
        this.Form.BasicData.TransactionDate = '';
        this.Form.BasicData.DocumentComment = '';
        this.Form.Documents.DocumentDs = '';
        this.Form.BasicData.DocumentServiceComment = '';
        this.Form.BasicData.DocumentJson = [];
        this.Form.BasicData.Observation = '';
        this.Form.BasicData.Status = 1;

        //Packaging Form
        this.Form.Packaging.YardAreaId = '';
        this.Form.Packaging.VisitBillOfLadingId = '';
        this.Form.Packaging.PackagingId = '';
        this.Form.Packaging.BlCargoMasterDetailId = '';
        this.Form.Packaging.VisitBillOfLadingPackagingId = '';
        this.Form.Packaging.Quantity = 0;
        this.Form.Packaging.SerialList = [];
        this.Form.DetailJson = [];
        this.Form.Packaging.Length = 0;
        this.Form.Packaging.Width = 0;
        this.Form.Packaging.Height = 0;
        this.Form.Packaging.Volumen = 0;
        this.Form.Packaging.Weigth = 0;
        this.Form.Packaging.VolumeCheck = false;
        this.Form.Packaging.PackagingItem = {};
        this.Form.Packaging.SerialList = [];
        this.Form.Packaging.AccesoryList = [];

        this.Form.Packaging.Seal.Seal1.Seal = '';
        this.Form.Packaging.Seal.Seal2.Seal = '';
        this.Form.Packaging.Seal.Seal3.Seal = '';
        this.Form.Packaging.Seal.Seal4.Seal = '';

        this.Form.Packaging.Serialization.VolumeCheck = true,
        this.Form.Packaging.Serialization.IsNewSerial = false,
        this.Form.Packaging.Serialization.Id = '';
        this.Form.Packaging.Serialization.Serial = '';
        this.Form.Packaging.Serialization.PackagingId = '';
        this.Form.Packaging.Serialization.Weight = 0; 
        this.Form.Packaging.Serialization.Volumen = 0;
        this.Form.Packaging.Serialization.Length = 0;
        this.Form.Packaging.Serialization.Width = 0;
        this.Form.Packaging.Serialization.Height = 0;
        this.Form.Packaging.Serialization.DescriptionOfGoods = '';
        this.Form.Packaging.Serialization.Serialized = '';

        //IMDG
        this.Form.Packaging.SerialImdg.id = 0;
        this.Form.Packaging.SerialImdg.ImdgList = [];
        this.Form.Packaging.SerialImdg.ImdgClass = '';
        this.Form.Packaging.SerialImdg.ImdgId = '';
        this.Form.Packaging.SerialImdg.PackagingGroup = '';
        this.Form.Packaging.SerialImdg.UnNumber = '';
        this.Form.Packaging.SerialImdg.DescriptionGoods = '';
        this.Form.Packaging.Imo = '';
        this.Form.Packaging.ImdgClass = '';
        this.Form.Packaging.UnNumber = '';
        this.Form.Packaging.PackagingGroup = '';

        this.Form.Packaging.ImdgClassId = '';
        this.Form.Packaging.UnNumberId = '';
        this.Form.Packaging.PackagingGroupId = '';

        //Logistic Form
        this.Form.Logistic.CarrierId = '';
        this.Form.Logistic.numberIdLetter = '';
        this.Form.Logistic.SearchDriver = '';
        this.Form.Logistic.DriverId = '';
        this.Form.Logistic.SearchPlate = '';
        this.Form.Logistic.VehicleId = '';

        //General Form
        this.isSubmit = false;
        this.isWasRequested = false;
        this.$v.$reset();
        isclose && this.$emit("Close");
    }

    async function backToList() {
        this.$store.state.yardManagement.loading = true;
        this.$emit("Update-list");
        this.Reset();
    }

    async function position() {
        this.$store.state.yardManagement.loading = true;
        this.$store.state.yardManagement.FgPositioning = true;
        await this.$emit("Update-list", true);
        this.Reset();
    }

    async function getBlCargoListYardId() {
        await this.$http.get('BlCargoResum-list-by-YardId', { BlCargoJson: {YardId: this.yardData.YardId} })
        .then(response => {
            let List = response.data.data;
            this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
    }

    function Submit(UpdateComponent = false) {
        
        try {
            this.$store.state.yardManagement.loading = true;
            this.isSubmit = true;
            this.Validate = true;

            let totalQuantity = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0;
            let totalWeigth = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Weigth, 0)  ?? 0;
            let totalLength = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Length, 0)  ?? 0;
            let totalWidth = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Width, 0)  ?? 0;
            let totalHeight = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Height, 0)  ?? 0;
            let totalVolumen = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Volumen, 0)  ?? 0;
            let date = this.Form.BasicData.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(this.Form.BasicData.TransactionDate) : '';

            this.$v.Form.BasicData.$touch();
            this.$v.Form.Logistic.$touch();
            this.$v.Form.DetailJson.$touch();

            let dateValidation = ((!this.isMaster && (this.dataBl.VisitBillOfLadingId == undefined || this.dataBl.VisitBillOfLadingId == null)) && this.dataBl.DeconsolidationDate) ? this.dataBl.DeconsolidationDate : this.ContainerItem?.MinDischargeDate ?? process.env.VUE_APP_START_DATE_IN_PATIO
            let ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(dateValidation));

            if (this.Form.BasicData.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.Form.BasicData.TransactionDate) < ValidPreviousDate ) 
                if((!this.isMaster && (this.dataBl.VisitBillOfLadingId == undefined || this.dataBl.VisitBillOfLadingId == null)) && this.dataBl.DeconsolidationDate)
                    throw this.$t('label.ErrorNotLowerDesconsolidationPleaseCheck');
                else
                    throw this.$t('label.TheDateCannotBeLessThanTheDateOfConfirmation');
            
            if (this.Form.BasicData.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.Form.BasicData.TransactionDate) > DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(new Date))) 
                throw this.$t('label.ErrorCurrentDatedPleaseCheck');

            if (this.$v.Form.BasicData.$error || this.$v.Form.Logistic.$error || this.$v.Form.DetailJson.$error) 
                throw this.$t('label.errorsPleaseCheck');

            if(this.Form.DetailJson.length === 0)
                throw `${this.$t('label.must')} ${this.$t('label.aggregate')} ${this.$t('label.atLeast')} ${this.$t('label.detail')}`;

            if(this.Form.DetailJson.some((e)=> e.FgRequiredSerialization && e.SerialJson.length == 0)){
                this.Form.isErrorSerial = true;
                throw `${this.$t('validation.PackagingRequiresSerial')}`;
            }

            let BlCargoJson = {
                YardId: this.$store.state.yardManagement.yardData.YardId,
                MachineId: !UpdateComponent ? this.Form.BasicData.MachineId : this.ContainerItem.MachineId,
                YardCargoId: this.ContainerItem.YardCargoId,
                BlCargoId: this.ContainerItem.BlCargoId ?? '',
                VisitBillOfLadingId: this.ContainerItem.VisitBillOfLadingId,
                TransactionDate: !UpdateComponent ? date : DateFormater.formatDateTimeWithoutSlash(this.ContainerItem.TransactionDate),
                TpEirTransacId: !UpdateComponent ? this.Form.BasicData.TpEirTransacId : this.ContainerItem.TpEirTransacId,
                CarrierId: !UpdateComponent ? this.Form.Logistic.CarrierId  : this.ContainerItem.CarrierId,
                DriverId: !UpdateComponent ? this.Form.Logistic.DriverId  : this.ContainerItem.DriverId,
                VehicleId: !UpdateComponent ? this.Form.Logistic.VehicleId  : this.ContainerItem.VehicleId,
                ReceptionDocumentJson: this.Form.BasicData.DocumentJson,
                DocumentDs: !UpdateComponent ? this.Form.BasicData.DocumentComment : this.Form.BasicData.DocumentServiceComment,
                DetailJson: !UpdateComponent ? this.Form.DetailJson : this.Form.Packaging.PackagingList,
                Observation: !UpdateComponent ? this.Form.BasicData.Observation : this.ContainerItem.Observation,
                Status: !UpdateComponent ? this.Form.BasicData.Status  : (this.ContainerItem.Status == 'INACTIVO' || this.ContainerItem.Status == 'INACTIVE') ? 0 : 1,
            }

            this.$http.put('YardWCargoReception-update', BlCargoJson, { root: 'BlCargoJson' })
            .then(async(response) => {
                if(!UpdateComponent){
                    await this.getBlCargoListYardId();
                    this.notifySuccess({text: response.data.data[0].Response});
                    this.Reset();
                    this.$emit("Update-list", true);
                }
                else
                    this.$store.state.yardManagement.loading = false;
                
                this.isSubmit = false;
            }).catch((err) => {
                this.isSubmit = false;
                this.$store.state.yardManagement.loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isSubmit = false;
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }

    //-----------------------   Computed   ------------------------

    function validPackaginJson(){
        return this.Form?.DetailJson?.some((e)=> ((e.YardAreaId && e.YardAreaId != '') && (e.Status != 0))) ?? false;
    }

    function isMaster() {
        return this.dataBl.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER
    }

    export default{
        name: 'bl-reception',
        data,
        mixins: [General, ModalMixin],
        directives: UpperCase,
        props: {
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            fgCertificate: Boolean,
            Active: Boolean,
            isEdit: {
                type: Boolean,
                required: false,
                default: () => false,
            },
        },
        components: {
            CustomTabs,
            CustomTab,
            Logistic,
            Packaging,
            BasicData
        },
        validations() {
            return BlReceptionFormValidations(this.validPackaginJson, this.Form, this.Form.Packaging.Serialization, 0, 0,  this.Form.Packaging.PackagingItem, this.ContainerItem, []);
        },
        methods: {
            handleTab,
            UpdatePackagingList,
            Reset,
            Submit,
            backToList,
            position,
            getBlCargoListYardId,
            FormatNumber
        },
        computed: {
            validPackaginJson,
            isMaster,
            ...mapState({
                yardData: state => state.yardManagement.yardData,
                dataBl: state => state.yardManagement.dataBl,
            })
        },
        watch:{
            Active: function (val) {
                if (val) {
                    this.Form.Packaging.maxPackagingWeight = this.ContainerItem.Weight ?? 999999999;
                    this.Reset(false);
                    this.tabIndex = 0;

                    if(this.dataBl.VisitBillOfLadingId){
                        this.Form.BasicData.TpEirTransacId = process.env.VUE_APP_RECEPTION_TRANSACT_ENTRY_VISIT_WAREHOUSE;
                    }
                    else{
                        this.Form.BasicData.TpEirTransacId = process.env.VUE_APP_RECEPTION_TRANSACT_DESCONSOLIDATION_WAREHOUSE;
                    }

                    if(this.ContainerItem.Status == 'INACTIVO' || this.ContainerItem.Status == 'INACTIVE') 
                        this.isWasRequested = true; 

                    if(!(this.yardData?.YardId) || this.fgCertificate)
                        this.isWasRequested = true; 
                }
                else
                    this.tabIndex = -1;
            }
        }
    }
</script>