<template>
    <div>
        <CModalExtended
            :title="titleModalGroup"
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="$store.state.implemento.modalGroup"
            class="modal-extended"
        >
                           
            <CRow>
                <CCol sm="12">
                    <CRow>
                        <CCol sm="12" lg="11">                                         
                            <CInput
                            :label="$t('label.name')+' (ES)'"
                            addLabelClasses="text-right required"
                            v-uppercase
                            :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5 required', input:'col-sm-12 col-lg-7 col-xl-7'}"
                            @blur="$v.formGpoImplemento.NbGpoImplementoEs.$touch()"
                            :placeholder="$t('label.name')+' '+$t('label.implementsGroup')+' (ES)'"
                            v-model="$v.formGpoImplemento.NbGpoImplementoEs.$model"
                            :is-valid="hasError($v.formGpoImplemento.NbGpoImplementoEs)"
                            :invalid-feedback="errorMessage($v.formGpoImplemento.NbGpoImplementoEs)"
                            >                                                                                                                                                                                                                      
                            </CInput>                                                                                                                                                                              
                        </CCol>
                        <CCol sm="12" lg="11">                                         
                            <CInput
                            :label="$t('label.name')+' (EN)'"
                            addLabelClasses="text-right required"
                            v-uppercase
                            :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5 required', input:'col-sm-12 col-lg-7 col-xl-7'}"
                            @blur="$v.formGpoImplemento.NbGpoImplementoEn.$touch()"
                            :placeholder="$t('label.name')+' '+$t('label.implementsGroup')+' (EN)'"
                            v-model="$v.formGpoImplemento.NbGpoImplementoEn.$model"
                            :is-valid="hasError($v.formGpoImplemento.NbGpoImplementoEn)"
                            :invalid-feedback="errorMessage($v.formGpoImplemento.NbGpoImplementoEn)"
                            >                                                                                                                                                                                                                      
                            </CInput>                                                                                                                                                                              
                        </CCol>
                        <CCol sm="12" lg="11">                                         
                            <CSelect
                                :label="$t('label.cargoGroup')"
                                :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5 required', input:'col-sm-12 col-lg-7 col-xl-7'}"
                                :placeholder="$t('label.select')"
                                addLabelClasses="required text-right"
                                :value="formGpoImplemento.GpoCargoId"
                                :options="computedGpoCargoList"
                                v-model.trim="$v.formGpoImplemento.GpoCargoId.$model"
                                :is-valid="hasError($v.formGpoImplemento.GpoCargoId)"
                                :invalid-feedback="errorMessage($v.formGpoImplemento.GpoCargoId)"
                            />                                                                                                                                                                            
                        </CCol>
                        <CCol sm="12" lg="11" v-if="this.idGpoImplemento !== 0">
                                <CSelect
                                :label="$t('label.status')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5', input:'col-sm-12 col-lg-7 col-xl-7'}"
                                :is-valid="formGpoImplemento.FgActGpoImplemento"
                                v-model="formGpoImplemento.FgActGpoImplemento"
                                :value.sync="formGpoImplemento.FgActGpoImplemento"
                                :options="selectOptions"
                            />
                        </CCol>                                   
                    </CRow>                                
                </CCol>
            </CRow>
                
            <template #footer >
                <CButton
                    color="add"
                    class="d-flex align-items-center"
                    @click="registerDataGpoImplemento()"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="checkAlt" />{{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import  FORMIMPLEMENTO from '@/_validations/implemento/ImplementoValidation';
    import UpperCase  from '@/_validations/uppercase-directive';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import PictureInput from '@/components/picture-input';
    import general from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';

    //data
    function data() {
        return {
            showPicture: 1,
            activeTab: 0,
            isEdit:0,
            formGpoImplemento:{
                NbGpoImplementoEn:'',
                NbGpoImplementoEs:'',
                GpoCargoId: '',
                FgActGpoImplemento:true,
            },
            GpoCargoId: '',
            gpoCargoSelect: [],
            originalActGpoImplemento:'',
            originalActImplemento:'',
            editModal: false,
            loading:false,
        }
    }
    //methods
    function getGpoCargoList() {
        this.$store.state.implemento.apiStateForm = ENUM.LOADING;
        this.$http
            .get('GpoCargo-list?Filter=ACTIVO')
            .then((res) => (this.gpoCargoSelect = res.data.data));
        this.$store.state.implemento.apiStateForm = ENUM.INIT;
    }

    function registerDataGpoImplemento(){
        try { 
            this.$v.formGpoImplemento.$touch();
            if (this.$v.formGpoImplemento.$pending || this.$v.formGpoImplemento.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formGpoImplemento.$pending || this.$v.formGpoImplemento.$error) return;
            
            if(this.originalActGpoImplemento !== '' && this.idGpoImplemento !== 0){
                if(this.originalActGpoImplemento !== this.formGpoImplemento.FgActGpoImplemento){
                    this.$swal
                    .fire(this.alertProperties({
                        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.formGpoImplemento.NbGpoImplementoEn}?`,
                    }))
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.submit();
                        }
                    })
                }else{
                    this.submit();
                }
            }else{
                this.submit();
            }
        } catch (e) {
            this.notifyError({ text: e });
        }
    }

    async function submit() {
        try{
            const GpoImplementJson = {
                GpoImplementNameEn:this.formGpoImplemento.NbGpoImplementoEn,
                GpoImplementNameEs:this.formGpoImplemento.NbGpoImplementoEs,
                GpoCargoId: this.formGpoImplemento.GpoCargoId,
                UserId:JSON.parse(localStorage.getItem('user')).UserId
            }
            if(this.editModal){
                GpoImplementJson.Status= this.formGpoImplemento.FgActGpoImplemento ? 1 : 0;
                GpoImplementJson.GpoImplementId= this.idGpoImplemento;
            }

            let method = this.editModal ? 'PUT':'POST';
            let link = this.editModal ? 'GpoImplement-update':'GpoImplement-insert';

            await this.$http.ejecutar(method, link, GpoImplementJson, { root: 'GpoImplementJson' })
            .then((response) => {
                if ((response.status = 200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.notifySuccess({text: messageSuccess})
                    this.closeModal();
                }
            }).catch((e) => {
                this.notifyError({text: e});
            });
        } catch (error) {
            this.notifyError({text: error});
        }
    }

    function closeModal(){
        if(this.apiStateLoading)
            return
        this.$store.dispatch('implemento/getGpoImplementolist', { filtro : 'ALL', type : 1});
        this.$store.commit('implemento/mutationModalGroup', false);
    }

    //computed
    function  tituloModal(){
        return this.$t('label.Implement')
    }
    function optionsListGpoImplemento(){
        if(this.myDataGpoImplemento.length === 0){
            return [{
                value: '', 
                label: this.$t('label.toSelect'),
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.toSelect'),
            }];
            this.myDataGpoImplemento.map(function(e){
                if(e.FgActGpoImplement === true){
                    chart.push({
                        value: e.GpoImplementId, 
                        label: e.GpoImplementName,
                    })
                }
            })
            return chart;
        }
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }

     function selectOptions() {
        return [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ];
    }

//watch
    async function modalGroup(newQuestion,Oldquestion){
        if( newQuestion && this.idGpoImplemento !== 0 ){
            this.formGpoImplemento.NbGpoImplementoEn = this.groupData.GpoImplementNameEn;
            this.formGpoImplemento.NbGpoImplementoEs = this.groupData.GpoImplementNameEs;
            this.formGpoImplemento.GpoCargoId = this.groupData.GpoCargoId;
            this.formGpoImplemento.FgActGpoImplemento = this.groupData.FgActGpoImplement;
            this.originalActGpoImplemento = this.groupData.FgActGpoImplement;
            this.editModal = true;
            this.$v.formGpoImplemento.$touch();  
        }

        if( newQuestion && this.idGpoImplemento === 0 ){
            this.formGpoImplemento.NbGpoImplementoEn='';
            this.formGpoImplemento.GpoCargoId='';
            this.formGpoImplemento.NbGpoImplementoEs='';
            this.formGpoImplemento.FgActGpoImplemento=true;
            this.editModal = false;
            this.originalActGpoImplemento = ''; 
            this.$nextTick(() => { this.$v.$reset() })
        }

        if(newQuestion === false){
            this.formGpoImplemento.NbGpoImplementoEn='';
            this.formGpoImplemento.GpoCargoId='';
            this.formGpoImplemento.NbGpoImplementoEs='';
            this.formGpoImplemento.FgActGpoImplemento=true;
            this.editModal = false;
            this.originalActGpoImplemento = '';
            this.$nextTick(() => { this.$v.$reset() })
        }

        await this.getGpoCargoList();
    }
    function formReset(newQuestion,Oldquestion){
        this.$store.state.implemento.formReset = 0;
    }

    function computedGpoCargoList(){
        let _lang = this.$i18n.locale;
        if(this.gpoCargoSelect.length === 0){
            return [{
            value: '', 
            label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
            value: '', 
            label: this.$t('label.select'),
            }]
            this.gpoCargoSelect.map(function(e){
            chart.push({
                value: e.GpoCargoId, 
                label: _lang=='en' ? e.GpoCargoNameEn : e.GpoCargoNameEs,
            })    
            })
            return chart;
        }
    }

    export default {
        name: 'modal-group',
        data,
        mixins: [TipoGruaMixin,general, ModalMixin],
        props: {
            groupData: {
                type: Object,
                default: () => { }
            },
            titleModalGroup:{ 
                type: String,
                default: false,
            }
        },
        components: {
        },
        validations(){ return FORMIMPLEMENTO() },
        computed: {
            apiStateLoading,
            apiStateFormLoading,
            optionsListGpoImplemento,
            computedGpoCargoList,
            selectOptions,
            tituloModal,
            ...mapState({
                idGpoImplemento: state=> state.implemento.idGpoImplemento,
                idImplemento: state=> state.implemento.idImplemento,
                modalGroup: state=> state.implemento.modalGroup,
                myDataGpoImplemento: state => state.implemento.myDataGpoImplemento,
                formReset: state=> state.implemento.formReset,
                apiState: state => state.implemento.apiState,
                apiStateForm: state => state.implemento.apiStateForm
            })
        },
        directives: UpperCase,
        methods:{
            registerDataGpoImplemento,
            getGpoCargoList,
            closeModal,
            submit
        },
        watch:{
            modalGroup,
            formReset,
            /*
            idState*/
        }
    }
</script>

<style lang="scss">
   
</style>
