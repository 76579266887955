<template>
  <CModalExtended
    :title="title"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    @update:show="Reset"
  >
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <CCol sm="12" lg="12" xl="12">
          <CCard class="card-simple">
            <CCardBody >
              <CRow  class="mb-4">
                <CCol sm="12" lg="4" xl="4" class="text-center">
                    <div>    
                      <picture-input
                        v-if="imageRoute"
                        ref="imageInput"
                        width="180"
                        height="180"
                        accept="image/jpeg,image/png"
                        size="10"
                        :custom-strings="imgInputTexts"
                        @change="handleFileUpload"
                        :imgSrc="imageRoute"
                      >
                      </picture-input>
                    </div>
                      <div v-if="$v.image.$error">
                        <div
                          class="text-invalid-feedback text-center"
                          v-if="!$v.image.isValidFile"
                        >
                          {{$t('label.badImage')}}
                        </div>
                      </div>
                </CCol>
                <CCol sm="12" lg="8" xl="8">
                  <CRow>
                    <!-- Inputs -->
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CInput
                        v-model="$v.yard.YardName.$model"
                        :placeholder="$t('label.name')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        v-uppercase
                        size="sm"
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :is-valid="hasError($v.yard.YardName)"
                        :invalid-feedback="errorMessage($v.yard.YardName)"
                      />
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="text-right"
                        :label="$t('label.plan')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="configOptions"
                        v-model="yard.Plan"
                        :value.sync="yard.Plan"
                        :is-valid="hasError($v.yard.Plan)"
                        :invalid-feedback="errorMessage($v.yard.Plan)"
                        @change="listAcronym($event)"
                      >
                      </CSelect>
                    </CCol>
                     <!-- moneyInputs -->
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CRow class="row justify-content-center px-6 m-0 mb-2"> 
                        <CCol sm="12" lg="5" class="px-2">
                          <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('label.width')} (M)`}}</label>
                        </CCol>
                        <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-7 col-xl-7">
                          <money
                            size="sm"
                            v-bind="measure" 
                            class="form-control col-sm-12"
                            style="max-height: 80%;"
                            v-model.trim="$v.yard.Width.$model"
                            @blur="$v.yard.Width.$touch()"
                            addLabelClasses="required text-right"
                            :class="ValidateFormControl(yard.Width)"
                            value="0"
                            maxlength="9"
                          ></money>
                          <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Width) < 0)">
                            {{ $t('label.ValueNotNegative') }}
                          </CCol>
                          <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Width == 0 || yard.Width == '0,00') && valid==true)">
                            {{$t('label.required')}}
                          </CCol>
                        </CCol>
                      </CRow> 
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0" v-if="yard.Plan">
                      <CInput
                        v-model="$v.yard.YardAlias.$model"
                        :placeholder="$t('label.acronym')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        v-uppercase
                        size="sm"
                        :label="$t('label.acronym')"
                        addLabelClasses="required text-right"
                        :is-valid="hasError($v.yard.YardAlias)"
                        :invalid-feedback="errorMessage($v.yard.YardAlias)"
                        disabled
                      />
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0" v-if="!yard.Plan">
                      <CInput
                        v-model="$v.yard.YardAlias.$model"
                        :placeholder="$t('label.acronym')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        v-uppercase
                        size="sm"
                        :label="$t('label.acronym')"
                        addLabelClasses="required text-right"
                        :is-valid="hasError($v.yard.YardAlias)"
                        :invalid-feedback="errorMessage($v.yard.YardAlias)"
                      />
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CRow class="row justify-content-center px-6 m-0 mb-2"> 
                          <CCol sm="12" lg="5" class="px-2">
                            <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('label.largo')} (M)`}}</label>
                          </CCol>
                          <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-7 col-xl-7">
                            <money
                              size="sm"
                              v-bind="measure" 
                              class="form-control col-sm-12"
                              style="max-height: 80%;"
                              v-model.trim="$v.yard.Length.$model"
                              @blur="$v.yard.Length.$touch()"
                              addLabelClasses="required text-right"
                              :class="ValidateFormControl(yard.Length)" 
                              value="0"
                              maxlength="9"
                            ></money>
                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Length) < 0)">
                              {{ $t('label.ValueNotNegative') }}
                            </CCol>
                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Length == 0 || yard.Length == '0,00') && valid==true)">
                              {{$t('label.required')}}
                            </CCol>
                          </CCol>
                        </CRow> 
                    </CCol>
                    <!-- Selects -->
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.nroAccess')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="accessOptions"
                        v-model="$v.yard.AccesNumber.$model"
                        :is-valid="hasError($v.yard.AccesNumber)"
                        :invalid-feedback="errorMessage($v.yard.AccesNumber)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CRow class="row justify-content-center px-6 m-0 mb-2"> 
                          <CCol sm="12" lg="5" class="px-2">
                            <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('AREA')} (M²)`}}</label>
                          </CCol>
                          <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-7 col-xl-7">
                            <money
                              size="sm"
                              disabled
                              v-bind="measure" 
                              class="form-control col-sm-12"
                              style="max-height: 80%;"
                              v-model.trim="$v.yard.Area.$model"
                              @blur="$v.yard.Area.$touch()"
                              addLabelClasses="required text-right"
                              :class="ValidateFormControl(yard.Area)" 
                              :value.sync="calculateArea"
                              value="0"
                              maxlength="9"
                            ></money>
                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Area) < 0)">
                              {{ $t('label.ValueNotNegative') }}
                            </CCol>
                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Area) > 50000)">
                              {{ $t('label.exceededArea') }}
                            </CCol>
                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((yard.Area == 0 || yard.Area == '0,00') && valid==true)">
                              {{$t('label.required')}}
                            </CCol>
                          </CCol>
                        </CRow> 
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="text-right"
                        :label="$t('label.capStatic')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="staticOptions"
                        v-model="$v.yard.StaticCapacity.$model"
                        :is-valid="hasError($v.yard.StaticCapacity)"
                        :invalid-feedback="errorMessage($v.yard.StaticCapacity)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.height_')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="OperationalOptions"
                        v-model="$v.yard.OperationalCapacity.$model"
                        :is-valid="hasError($v.yard.OperationalCapacity)"
                        :invalid-feedback="errorMessage($v.yard.OperationalCapacity)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.yardType')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="yardTypeOptions"
                        v-model="$v.yard.YardTpId.$model"
                        :is-valid="hasError($v.yard.YardTpId)"
                        :invalid-feedback="errorMessage($v.yard.YardTpId)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="text-right"
                        :label="$t('label.groupingOfYards')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="yardsGroupingOptions"
                        v-model="$v.yard.YardGpoId.$model"
                        :is-valid="hasError($v.yard.YardGpoId)"
                        :invalid-feedback="errorMessage($v.yard.YardGpoId)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.condition')"
                        :horizontal="{
                          label:'col-sm-12 col-lg-5 col-xl-5',
                          input:'col-sm-12 col-lg-7 col-xl-7'
                        }"
                        :options="conditionOptions"
                        v-model="$v.yard.YardStatus.$model"
                        :is-valid="hasError($v.yard.YardStatus)"
                        :invalid-feedback="errorMessage($v.yard.YardStatus)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <div class="form-group form-row">
                        <label class="col-sm-12 col-lg-5 col-xl-5 d-flex justify-content-end text-right col-form-label-sm">{{$t('label.management')}}</label>
                        <div class="input-group col-sm-12 col-lg-7 col-xl-7 input-group-sm">
                          <CSwitch
                            size="sm"
                            color="watch"
                            variant="3d"
                            type="checkbox"
                            :checked.sync="yard.FgManagement"
                          />
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <div class="form-group form-row">
                        <label class="col-sm-12 col-lg-5 col-xl-5 d-flex justify-content-end text-right col-form-label-sm">{{$t('label.Certificate')}}</label>
                        <div class="input-group col-sm-12 col-lg-7 col-xl-7 input-group-sm">
                          <CSwitch
                            size="sm"
                            color="watch"
                            variant="3d"
                            type="checkbox"
                            :checked.sync="yard.FgCertificateDocument"
                          />
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" lg="6" xl="6" class="pl-0">
                      <div class="form-group form-row">
                        <label class="col-sm-12 col-lg-5 col-xl-5 d-flex justify-content-end text-right col-form-label-sm">{{$t('label.generateCertificate')}}</label>
                        <div class="input-group col-sm-12 col-lg-7 col-xl-7 input-group-sm">
                          <CSwitch
                            size="sm"
                            color="watch"
                            variant="3d"
                            type="checkbox"
                            :checked.sync="yard.FgActReceptionGenerate"
                          />
                        </div>
                      </div>
                    </CCol>
                     <!-- Status of edit -->
                    <CCol sm="12" lg="6" xl="6" class="pl-0" v-if="edit">
                      <div>
                        <CSelect
                          :value.sync="yard.Status"
                          :is-valid="statusSelectColor"
                          :horizontal="{
                            label:'col-sm-12 col-lg-5 col-xl-5',
                            input:'col-sm-12 col-lg-7 col-xl-7'
                          }"
                          size="sm"
                          :label="$t('label.status')"
                          :options="statusOptions"
                          addLabelClasses="required text-right"
                        />
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>         
    </form>
    <template #footer>
      <CButton
        square
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="edit ? statusConfirmation(yardItem.FgActYard, yard.Status, submitFile) : submitFile()"
      >
      <CIcon name="checkAlt"/>{{$t('button.accept')}}
      </CButton>
      <CButton
        square
        color="wipe"
        class="d-flex align-items-center"
        @click="Reset"
      >
        <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
<script>
import UpperCase from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';
import { FormYards } from '@/_validations/master-yards/MasterOfYards';
import PictureInput from '@/components/picture-input';
import { imgInputTextsHelpers } from '@/_helpers/funciones';
import NumberInput from '@/components/numberinput';

//Data
function data() {
  return {
    modalActive: false,
    isSubmit: false,
    yard: {
      YardTpId: '',
      YardGpoId: '',
      YardId: '',
      YardName: '',
      YardAlias: '',
      Plan: '',
      Area: '',
      Width: '',
      Length: '',
      StaticCapacity: '',
      OperationalCapacity: '',
      AccesNumber: '',
      YardRoute: '',
      YardStatus:'',
      FgManagement: false,
      FgCertificateDocument: false,
      FgActReceptionGenerate: false,
      Status: 0,
    },
    image: '',
    loadingOverlay: false,
    imageRoute: '',
    TypeYards: [],
    conditionItems: [],
    groupYardsItems: [],
    confingItems: [],
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 10,
    },
    valid: false,
  }
}

function submitFile() {
  try {
    this.isSubmit = true;
    this.valid = true;
    this.loadingOverlay = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      this.valid = true;
      this.loadingOverlay = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    if(this.yard.YardRoute == this.image)
      this.submit();
    else{
      this.$http.file('Yard-file', this.image,this.yard.YardRoute)
      .then((response) => {
        this.yard.YardRoute = response.data.data.files[0].path;
        this.submit();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
        this.loadingOverlay = false;
      });
    }
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}
function submit() {
  this.isSubmit = true;
  this.valid = true;
  this.loadingOverlay = true;
  if (this.$v.$error || this.yard.Width == '' || this.yard.Width == '0,00' || this.yard.Length == '' || this.yard.Length == '0,00' || this.yard.Width == '0,00' || this.yard.Area == '' || this.yard.Area == '0,00' || this.yard.Area > 50000) {
    this.isSubmit = false;
    this.valid = true;
    this.loadingOverlay = false; 
    throw this.$t('label.errorsPleaseCheck');
  }
  let YardJson = this.edit ? {
      CompanyBranchId: this.CompanyBranchId,
      YardTpId: this.yard.YardTpId,
      YardGpoId: this.yard.YardGpoId,
      YardId: this.yardId,
      YardName: this.yard.YardName,
      YardAlias: this.yard.YardAlias,
      StaticCapacity: this.yard.StaticCapacity,
      OperationalCapacity: this.yard.OperationalCapacity,
      AccesNumber: this.yard.AccesNumber,
      Length: this.yard.Length,
      Width: this.yard.Width,
      YardStatus: this.yard.YardStatus, 
      FgManagement: this.yard.FgManagement, 
      FgCertificateDocument: this.yard.FgCertificateDocument, 
      FgActReceptionGenerate: this.yard.FgActReceptionGenerate, 
      SquareMeters: this.yard.Area,
      Status: this.yard.Status,
      YardProfileRoute: this.yard.YardRoute,
    }:{
      CompanyBranchId: this.CompanyBranchId,
      YardTpId: this.yard.YardTpId,
      YardGpoId: this.yard.YardGpoId,
      YardName: this.yard.YardName,
      YardAlias: this.yard.YardAlias,
      StaticCapacity: this.yard.StaticCapacity,
      OperationalCapacity: this.yard.OperationalCapacity,
      AccesNumber: this.yard.AccesNumber,
      Length: this.yard.Length,
      Width: this.yard.Width,
      SquareMeters: this.yard.Area,
      YardStatus: this.yard.YardStatus,
      FgManagement: this.yard.FgManagement, 
      FgCertificateDocument: this.yard.FgCertificateDocument, 
      FgActReceptionGenerate: this.yard.FgActReceptionGenerate, 
      YardProfileRoute: this.yard.YardRoute,
    };

  let metodo = this.edit ? 'PUT':'POST';
  let ruta = this.edit ? 'Yard-update': 'Yard-insert';

  this.$http.ejecutar(metodo, ruta, YardJson, { root: 'YardJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.Reset();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.loadingOverlay = false;
    });
}
async function getData(val) {
  this.yard.YardTpId = val.YardTpId;
  this.yard.YardGpoId = val.YardGpoId;
  this.yard.YardId = val.YardId;
  this.yard.YardName = val.YardName != 'N/A' ? val.YardName : '';
  this.yard.YardAlias = val.YardAlias != 'N/A' ? val.YardAlias : '';
  let dataFilter = this.confingItems.filter((e) => {return e.SvgKey == val.YardAlias});
  this.yard.Plan =  dataFilter.length > 0  ?  dataFilter[0].YardSvgConfigId : '';
  this.yard.StaticCapacity = val.StaticCapacity;
  this.yard.OperationalCapacity = val.OperationalCapacity;
  this.yard.AccesNumber = val.AccesNumber;
  this.yard.Length = val?.Length ?? '';
  this.yard.Width = val?.Width ?? '';
  this.yard.Area = val?.SquareMeters != 'N/A' ? val?.SquareMeters : '';
  this.yard.YardStatus  = val.YardStatus;
  this.yard.FgManagement  = val.FgManagement;
  this.yard.FgCertificateDocument  = val.FgCertificateDocument;
  this.yard.FgActReceptionGenerate  = val.FgActReceptionGenerate;
  this.yard.Status = val.FgActYard?1:0;
  let _lang = this.$i18n.locale;
  let imgplaceholder = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
  this.imageRoute = val.YardProfileRoute?`${this.$store.getters["connection/getBase"]}${val.YardProfileRoute.replace('public/', '', null, 'i')}`:imgplaceholder;
  this.image = val.YardProfileRoute ? val.YardProfileRoute : '' ;
  this.yard.YardRoute = val.YardProfileRoute ? val.YardProfileRoute : '';
  this.valid = true;
  this.$v.$touch();
  this.loadingOverlay = false;
}
function Reset() {
  this.yard.YardTpId = '';
  this.yard.YardGpoId = '';
  this.yard.YardId = '';
  this.yard.YardName = '';
  this.yard.Plan = '';
  this.yard.YardAlias = '';
  this.yard.StaticCapacity = '';
  this.yard.OperationalCapacity = '';
  this.yard.AccesNumber = '';
  this.yard.Length = '';
  this.yard.Width = '';
  this.yard.Area = '';
  this.yard.YardStatus = '';
  this.yard.FgManagement = false;
  this.yard.FgCertificateDocument = false;
  this.yard.FgActReceptionGenerate = false;
  this.yard.Status = 0;
  this.yard.YardRoute = '';
  this.TypeYards = [];
  this.conditionItems= [];
  this.groupYardsItems= [];
  this.confingItems = [];
  this.image = '';
  this.imageRoute = '';
  this.valid = false;

  this.$emit('close');
  this.$v.$reset();
}
function handleFileUpload() {
  this.image = this.$refs.imageInput.file;
  this.$v.image.$touch();
}
async function getListApis() {
  this.loadingOverlay = true;
  let peticiones =  
    [
      this.$http.ejecutar("GET", "TpYard-list", {Filter: 'ACTIVO'}),
      this.$http.ejecutar("GET", "YardGpo-list", { CompanyBranchId: this.CompanyBranchId}),
      this.$http.ejecutar("GET", "YardStatus-list"),
      this.$http.ejecutar("GET", "YardSvgConfig-list"),
    ];
  await Promise.all(peticiones)
    .then((responses) => {
      this.TypeYards = responses[0].data.data;
      this.groupYardsItems = responses[1].data.data;
      this.conditionItems = responses[2].data.data;
      this.confingItems = responses[3].data.data;
      this.loadingOverlay = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function listAcronym(event){
  this.yard.Plan = event.target.value;
  if (this.yard.Plan) {
    let YardData = this.confingItems.find((item) => item.YardSvgConfigId == this.yard.Plan);
    this.yard.YardAlias = YardData.SvgKey;
  } else {
    this.yard.YardAlias = '';
  }
}

function ValidateFormControl(value) { 
  // value = this.ReverseFormat(value);
  if ((((value == 0 || value == '0,0000') && this.valid==true) || value < 0 || value > 50000 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}
//Computeds
function imgInputTexts() {
  return imgInputTextsHelpers(this);
}
function calculateArea(){
  const {Length,Width} = this.yard
  let total = 0;
  if(parseFloat(Length) > 0){
    if(total === 0)
      total = 0;
      total = parseFloat(Width) * parseFloat(Length);
    }
    if(parseFloat(Width) > 0){
      if(total === 0)
        total = 0;
        total = parseFloat(Length) * parseFloat(Width);
      }
      this.yard.Area = total;
      return total;
}
function staticOptions() {
  return[
    { value:'',label: this.$t('label.select')},
    { value:1,label: ' 1'},
    { value:2,label: ' 2'},
    { value:3,label: ' 3'},
    { value:4,label: ' 4'},
    { value:5,label: ' 5'},
  ]                
}
function OperationalOptions() {
  return[
    { value:'',label: this.$t('label.select')},
    { value:1,label: ' 1'},
    { value:2,label: ' 2'},
    { value:3,label: ' 3'},
    { value:4,label: ' 4'},
    { value:5,label: ' 5'},
  ]       
}
function accessOptions() {
  return[
    { value:'',label: this.$t('label.select')},
    { value:1,label: ' 1'},
    { value:2,label: ' 2'},
    { value:3,label: ' 3'},
    { value:4,label: ' 4'},
    { value:5,label: ' 5'},
  ]   
}
function yardTypeOptions(){
  let _lang = this.$i18n.locale;
  if(this.TypeYards.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.TypeYards.map((e)=>{
      chart.push({
        value: e.TpYardId, 
        label: _lang=='en' ? e.TpYardNameEn : e.TpYardNameEs,
      })    
    })
    return chart;
  }
}
function conditionOptions(){
  let _lang = this.$i18n.locale;
  if(this.conditionItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.conditionItems.map((e)=>{
      chart.push({
        value: e.ProcessStatusId, 
        label: _lang=='en' ? e.ValueDsEn : e.ValueDsEs,
      })    
    })
    return chart;
  }
}
function yardsGroupingOptions(){
  if(this.groupYardsItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.groupYardsItems.map(function(e){
      chart.push({
        value: e.YardGpoId, 
        label: e.YardGpoName,
      })    
    })
    return chart;
  }
}

function configOptions(){
  if(this.confingItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.confingItems.map(function(e){
      chart.push({
        value: e.YardSvgConfigId, 
        label: e.SvgKey,
      })    
    })
    return chart;
  }
}
function statusSelectColor(){
  return this.yard.Status == 1;
}


export default {
  name: 'modal-yard',
  mixins: [ModalMixin],
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    yardId: {
      type: String,
      default: "",
    },
    yardItem: {
      type: Object,
      default: "",
    },
    title:{
      type: String,
      default: "",
    },
  },
  components: {
    PictureInput,
    NumberInput
  },
  data,
  validations(){ return FormYards() },
  directives: UpperCase,
  methods: {
    submit,
    Reset,
    handleFileUpload,
    submitFile,
    getData,
    getListApis,
    ValidateFormControl, 
    listAcronym
  },
  computed: {
    imgInputTexts,
    staticOptions,
    OperationalOptions,
    yardTypeOptions,
    conditionOptions, 
    yardsGroupingOptions,
    configOptions,
    accessOptions,
    statusSelectColor,
    calculateArea,
    ...mapState({
      CompanyBranchId: (state) => state.auth.branch.CompanyBranchId,
    })
  },
  watch: {
    modal: async function (val) {
      this.modalActive = val;
      if(val){
        await this.getListApis();
        if (this.edit) {
          this.getData(this.yardItem);
        }else{
          let _lang = this.$i18n.locale;
          this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        }
      }
    },
  }

}
</script>
  
<style scoped>
.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 0;
}
</style>
<style lang="scss">
.radioInvalidate{
  color: #e55353;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>