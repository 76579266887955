<template>
  <CModalExtended :title="modalTitle" color="dark" size="xl" :close-on-backdrop="false" :show.sync="modalActive">
    <CRow>
      <CCol sm="6">
        <CInput
          v-if="visit != 'restow'"
          :value="visit == 'discharge' ? bayPosition.CodPosition:codPosition"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="visit == 'discharge'?'ARRIVAL POSITION':'DEPARTURE POSITION'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CSelect
          v-if="visit == 'discharge'"
          class="mt-2"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.crane')"
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :options="cranesOptions"
          :disabled="isEmpty(cranes)"
          :value.sync="$v.bayPosition.VisitCraneId.$model"
          :invalid-feedback="
            errorMessage($v.bayPosition.VisitCraneId)
          "
          :is-valid="hasError($v.bayPosition.VisitCraneId)"
        >
        </CSelect>
        <CInput
          v-if="visit == 'load' || visit == 'restow'"
          class="mt-2"
          :value="craneSelected?craneSelected.CraneAlias:''"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="$t('label.crane')"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          v-if="visit == 'discharge' || visit == 'load'"
          class="mt-2"
          :value="visit == 'discharge'?bayPosition.LoadPort:bayPosition.DischargePort"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="visit == 'discharge'?'LOAD PORT':'DISCHARGE PORT'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          v-if="visit == 'restow'"
          class="mt-2"
          :value="bayPosition.LoadPort"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'LOAD PORT'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          v-if="visit == 'restow'"
          class="mt-2"
          :value="bayPosition.DischargePort"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'DISCHARGE PORT'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          class="mt-2"
          v-model.trim="bayPosition.TpCargoStatusName"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'STATUS'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          class="mt-2"
          v-model.trim="bayPosition.IsoCode"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'ISOCODE'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          class="mt-2"
          v-model.trim="bayPosition.TpCargoDetailCode"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'SIZE (FT)'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          class="mt-2"
          v-model.trim="bayPosition.ShippingLineCode"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="'CARRIER CODE'"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          v-if="visit == 'restow'"
          class="mt-2"
          :value="bayPosition.PreviousPosition"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="$t('label.previousPosition')"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          v-if="visit == 'restow'"
          class="mt-2"
          :value="codPosition"
          
          :horizontal="{
            label: 'col-sm-12 col-lg-5',
            input: 'col-sm-12 col-lg-7',
          }"
          :label="$t('label.newPosition')"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
      </CCol>
      <CCol sm="6">
        <CSelect
          v-if="visit == 'restow'"
          class="mt-2"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.type')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          :options="movTypeOptions"
          @change="movIdInAutorized"
          :disabled="isEmpty(movTypes)"
          :value.sync="bayPosition.MovementId"
        >
        </CSelect>
        <CSelect
          v-if="visit == 'restow'"
          class="mb-2 mt-2"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.AUTHORIZED_BY')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          :options="AutorizedOptions"
          :disabled="isEmpty(autorizedTypes)"
          :value.sync="$v.bayPosition.MovStowageReasonId.$model"
          :invalid-feedback="
            errorMessage($v.bayPosition.MovStowageReasonId)
          "
          :is-valid="hasError($v.bayPosition.MovStowageReasonId)"
        >
        </CSelect>
        <CInput
          v-model.trim="$v.bayPosition.Seal1.$model"
          
          :invalid-feedback="errorMessage($v.bayPosition.Seal1)"
          :is-valid="hasError($v.bayPosition.Seal1)"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          label="SEAL 1"
          addLabelClasses="text-right"
          maxlength="100"
          :disabled="visit=='restow'"
        />
        <CInput
          class="mt-2"
          v-model.trim="$v.bayPosition.Seal2.$model"
          
          :invalid-feedback="errorMessage($v.bayPosition.Seal2)"
          :is-valid="hasError($v.bayPosition.Seal2)"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          label="SEAL 2"
          addLabelClasses="text-right"
          maxlength="100"
          :disabled="visit=='restow'"
        />
        <CInput
          class="mt-2"
          v-model.trim="$v.bayPosition.Seal3.$model"
          
          :invalid-feedback="errorMessage($v.bayPosition.Seal3)"
          :is-valid="hasError($v.bayPosition.Seal3)"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          label="SEAL 3"
          addLabelClasses="text-right"
          maxlength="100"
          :disabled="visit=='restow'"
        />
        <CInput
          class="mt-2"
          v-model.trim="$v.bayPosition.Seal4.$model"
          
          :invalid-feedback="errorMessage($v.bayPosition.Seal4)"
          :is-valid="hasError($v.bayPosition.Seal4)"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          label="SEAL 4"
          addLabelClasses="text-right"
          maxlength="100"
          :disabled="visit=='restow'"
        />
        <div class="d-flex mt-2">
          <label class="col-sm-12 col-lg-4 col-xl-4 required text-right mt-2 pr-1">{{$t('label.date')}}</label>
          <CCol sm="12" lg="8" xl="8" class="p-0">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="$v.bayPosition.TransactionDate.$model"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              :disabled = "false"
              value-type="format"
              :show-second="false"
            >

              <template #input>
                <CInput
                  class="w-100 float-left mb-0"
                  :value="bayPosition.TransactionDate"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="hasError($v.bayPosition.TransactionDate)"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </CCol>
        </div>
        <CTextarea
          v-if="visit != 'restow'"
          class="mt-2"
          :addLabelClasses="'text-right'"
          
          :label="$t('label.observation')"
          :placeholder="$t('label.observation')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          rows="3"
          maxlength="150"
          v-model.trim="$v.bayPosition.Observation.$model"
          :invalid-feedback="errorMessage($v.bayPosition.Observation)"
          :is-valid="hasError($v.bayPosition.Observation)"
        />
        <CTextarea
          v-if="visit == 'restow'"
          class="mt-2"
          addLabelClasses="required text-right"
          
          :label="$t('label.observation')"
          :placeholder="$t('label.observation')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          rows="3"
          maxlength="150"
          v-model.trim="$v.bayPosition.MovStowageReasonDs.$model"
          :invalid-feedback="errorMessage($v.bayPosition.MovStowageReasonDs)"
          :is-valid="hasError($v.bayPosition.MovStowageReasonDs)"
        />
      </CCol>
    </CRow>

    <template #footer>
      <CButton
        color="add"
        shape="square"
        :disabled="isSubmit"
        @click="submit"
      >
        <div v-if="!isSubmit">
          <CIcon name="checkAlt" /><span class="ml-1">{{
            $t("button.accept")
          }}</span>
        </div>
        <div v-if="isSubmit">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="ml-1">{{ $t("button.accept") }}</span>
        </div>
      </CButton>
      <CButton
        color="wipe"
        shape="square"
        :disabled="isSubmit"
        @click="toggle(false)"
      >
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import ENUM from '@/_store/enum';
import {
  DateFormater
} from "@/_helpers/funciones";
import ConfirmValidation, { ConfirmLoad, ConfirmRestow } from '@/_validations/ajustes-plano/ConfirmValidations';
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    bayPosition: {
      VisitCargoId: "",
      VisitId: "",
      VisitCraneId: "",
      Seal1: "",
      Seal2: "",
      Seal3: "",
      Seal4: "",
      Observation: "",
      TransactionDate: "",
      //Restow
      MovStowageReasonId: "",
      MovStowageReasonDs: "",
      MovementId: "",
      //...
      PreviousPosition: "",
      CodPosition: "",
      LoadPort: "",
      DischargePort: "",
      TpCargoStatusName: "",
      IsoCode: "",
      TpCargoDetailCode: "",
      ShippingLineCode: "",
    },
    cranes: [],
    movTypes: [],
    autorizedTypes: [],
    // Fechas
    searchFilterDate:{
      fromDay:"",
      toDay:"",
    },
    previousDate: new Date(),
    laterDate: new Date(),
    disabledFilterByPreference: false,
  };
}

//Methods
function toggle(newVal) {
  if(newVal){
    this.setData(this.visitPosition);
    this.getDateRange();
    this.autorizedTypes = [];
    if(this.visit == 'discharge' && this.visitPosition && this.visitPosition.VesselBayId)
      this.getCranes();
    if(this.visit == 'restow')
      this.getMovTypes();
  }

  this.modalActive = newVal;
}
function setData(bayPosition) {
  if(!this.visitPosition) return;

  let arrKeys = Object.keys(this.bayPosition);

  this.$v.$reset();

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "TransactionDate":
      this.bayPosition[arrKeys[i]] = this.itinerarySelected && this.itinerarySelected.Atd
        ? DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd)
        : DateFormater.formatDateTimeWithSlash(new Date());
        break;
      case "Observation":
        this.bayPosition[arrKeys[i]] = '';
        break;
      case "VisitId":
        this.bayPosition[arrKeys[i]] = this.VisitId;
        break;
      default:
        this.bayPosition[arrKeys[i]] = bayPosition[arrKeys[i]] ? bayPosition[arrKeys[i]] : "";
    }
  }

  // this.$v.$touch();
}
function resetForm() {
  let arrKeys = Object.keys(this.bayPosition);

  for (let i = 0; i < arrKeys.length; i++) {
    this.bayPosition[arrKeys[i]] = "";
  }
  this.cranes = [];
  this.$v.$reset();
}
function formatDate(date, server = false) {
  if (!date) return '';
  let arrDate = date.split(/[/ :-]/);

  if (!server) return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;
  else return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
}
function formatDateTime(date, server = false) {
  if (!date) return "";

  if (!server) {
    return DateFormater.formatDateTimeWithSlash(date);
  } else {
    let onlyDate = DateFormater.formatDateTimeWithSlashToOnlyDate(date);

    return `${this.formatDate(onlyDate, true)} ${DateFormater.formatDateTimeWithSlashToOnlyTime(date)}`;
  }
}

function getCranes() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http
    .get("VisitCrane-by-VesselBayId", {
      VesselBayId: this.visitPosition ? this.visitPosition.VesselBayId : '',
      VisitId: this.VisitId,
    })
    .then((response) => {
      this.cranes = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function submit() {
  try {
    
    this.$v.bayPosition.$touch();
    if (DateFormater.formatDateTimeWithoutSlash(this.bayPosition.TransactionDate) <= DateFormater.formatDateTimeWithoutSlash(this.previousDate))
        throw this.$t('label.ErrorAtaPleaseCheck');
    
    if (DateFormater.formatDateTimeWithoutSlash(this.bayPosition.TransactionDate) > DateFormater.formatDateTimeWithoutSlash(this.laterDate)) 
      throw this.$t('label.ErrorCurrentDatedPleaseCheck');
    
    if (this.$v.bayPosition.$error) 
      throw this.$t("label.errorsPleaseCheck");
    

    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    this.isSubmit = true;
    let route = '';
    switch (this.visit) {
      case 'discharge':
        route = 'VisitCargoDischargeConfirmation';
        break;
      case 'load':
        route = 'VisitCargoLoadConfirmation';
        break;
      case 'restow':
        route = 'VisitCargoRestowConfirmation';
        break;
      default:
        break;
    }
    let CargoJson = {
      VisitCargoId: this.bayPosition.VisitCargoId,
      VisitId: this.bayPosition.VisitId,
      VisitCraneId: this.bayPosition.VisitCraneId,
      Seal1: this.bayPosition.Seal1,
      Seal2: this.bayPosition.Seal2,
      Seal3: this.bayPosition.Seal3,
      Seal4: this.bayPosition.Seal4,
      Observation: this.bayPosition.Observation,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.bayPosition.TransactionDate),
    };
    if(this.visit == 'load'){
      CargoJson.VesselBayPosId = this.vesselBayPosId;
      CargoJson.VisitCraneId = this.craneSelected?.VisitCraneId;
    }
    if(this.visit == 'restow'){
      delete CargoJson.Seal1;
      delete CargoJson.Seal2;
      delete CargoJson.Seal3;
      delete CargoJson.Seal4;
      delete CargoJson.Observation;
      delete CargoJson.VisitId;
      CargoJson.MovStowageReasonId = this.bayPosition.MovStowageReasonId;
      CargoJson.MovStowageReasonDs = this.bayPosition.MovStowageReasonDs;
      CargoJson.VesselBayPosId = this.vesselBayPosId;
      CargoJson.VisitCraneId = this.craneSelected?.VisitCraneId;
    }

    let metodo = "POST";

    this.$http
      .ejecutar(metodo, route, CargoJson, {
        root: "CargoJson",
      })
      .then((response) => {
        this.$emit("submited", {
          type: this.visit,
          data: response.data.data[0],
          action: 'confirm',
        });
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      })
      .then(() => {
        this.isSubmit = false;
        //this.$store.state.visitas.apiStateForm = ENUM.INIT;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}


function validateDateRange(date) {
  return date < this.previousDate.setHours(0, 0, 0, 0) || date > this.laterDate;
}
function getDateRange(){
    let currentDate = new Date();

    if(this.itinerarySelected.Ata!=undefined){ 
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined){
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined){  
        this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined){
        this.laterDate = currentDate;
    }
}

function getMovTypes() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http
    .get("VisitRestowTransacStowage-list")
    .then((response) => {
      this.movTypes = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    }).then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}

function movIdInAutorized(event) {
  try {
    
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;


    let CargoJson = {
      TransacStowageId: event.target.value,
    };
    

    let metodo = "GET";

    this.$http
      .ejecutar(metodo, 'MovStowageReason-by-transac-list', CargoJson, {  root: "CargoJson" })
      .then((response) => {
        this.autorizedTypes = response.data.data ?? [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      })
      .then(() => {
        this.isSubmit = false;
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

//Computed
function modalTitle() {
  let title = '';
  switch (this.visit) {
    case 'discharge':
      title = this.$t("label.discharge");
      break;
    case 'load':
      title = this.$t("label.loadPortSituation");
      break;
    case 'restow':
      title = this.$t("label.restowPortSituation");
      break;
    default:
      break;
  }

  return `${title}: ${this.visitPosition ? this.visitPosition.ContainerCode:""}`;
}
function cranesOptions() {
  return this.cranes.map((crane) =>
    Object.assign({}, crane, {
      label: crane.CraneAlias,
      value: crane.VisitCraneId,
    })
  );
}
function isEmpty(arr) {
  return arr.length == 0;
}
function movTypeOptions() {
  return this.movTypes.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.TransacStowageNameEn : item.TransacStowageNameEs,
      value: item.TransacStowageId,
    })
  );
}

function AutorizedOptions() {
  return this.autorizedTypes.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.MovStowageReasonNameEn : item.MovStowageReasonNameEs,
      value: item.MovStowageReasonId,
    })
  );
}

export default {
  name: "confirm-modal",
  mixins: [ModalMixin, General],
  props: {
    modal: Boolean,
    visit: {
      type: String,
      default: "",
    },
    visitPosition: {
      type: Object,
      default: null
    },
    craneSelected: {
      type: Object,
      default: null
    },
    vesselBayPosId: {
      type: String,
      default: "",
    },
    codPosition: {
      type: String,
      default: "",
    },
  },
  validations() {
    if(this.visit == 'discharge')
      return ConfirmValidation(this.previousDate, this.laterDate);
    else if(this.visit == 'restow')
      return ConfirmRestow(this.previousDate, this.laterDate);
    else
      return ConfirmLoad(this.previousDate, this.laterDate);
  },
  data,
  methods: {
    toggle,
    setData,
    formatDate,
    formatDateTime,
    resetForm,
    isEmpty,
    getCranes,
    submit,
    validateDateRange,
    getDateRange,
    getMovTypes,
    movIdInAutorized, 
  },
  computed: {
    AutorizedOptions,
    modalTitle,
    cranesOptions,
    movTypeOptions,
    ...mapState({
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>

</style>