<template>
    <div id="chart">
        <apexchart type="donut" :height="height" :width="width" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    //computed
    function chartOptions(){
        let options = {
            chart: {
                type: 'donut',
                width: this.width,
                height: this.height,
            },
            dataLabels: {
              enabled: false
            },
            labels: this.labels,
            legend: {
                show: this.displayLegend,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: this.positionLegend,
                horizontalAlign: "center",
                floating: false,
                fontSize: '11px',
            },
            noData: {  
                text: "Loading...",  
                align: 'center',  
                verticalAlign: 'middle',  
                offsetX: 0,  
                offsetY: 0,  
                style: {  
                    color: "#000000",  
                    fontSize: '14px',  
                    fontFamily: "Helvetica"  
                }  
            },
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: 200
                },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
        if(this.colors.length !== 0){
            options.colors = this.colors;
        }
        return options;
    }
    export default {
        name:"simplePie",
        props:{
            width:{
                type: String,
                required: true,
                default: '430',
            },
            height:{
                type: String,
                required: false,
                default: '100%',
            },
            colors: {
                type: Array,
                required: false,
                default: () => [],
            },
            labels: {
                type: Array,
                required: true,
                default: () => [],
            },
            series: {
                type: Array,
                required: true,
                default: () => [],
            },
            displayLegend:{
                required: false,
                default: false
            },
            positionLegend:{
                type: String,
                required : false,
                default: () => "right"
            },
            tooltip:{
                type: Boolean,
                default: false,
            },
        },
        computed:{
            chartOptions
        }
    }
</script>