<template>
    <div class="mb-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ReceptionsVisit')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                            square
                            size="sm"
                            color="watch"
                            class="d-flex align-items-center"
                            @click="toggleAdd(item)"
                            v-c-tooltip="{
                                content: $t('label.reception'),
                                placement: 'top-start'
                            }"
                            >
                            <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <EquipmentRegister
                :Active="showIndex==1"
                :ContainerItem="ContainerItem"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import EquipmentRegister from './equipment-register';
import { DateFormater } from '@/_helpers/funciones';
  
//data
function data() {
    return {
        Items: [],
        ContainerItem:{},
        dataContainer: [],
        showIndex: 0,
    };
}

//methods
async function getYardVisitReception(loading) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('YardVisitContainerPendingReception-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            let List = response.data.data;
            this.dataContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = loading;
            if (this.FgPositioning) {
                this.$store.state.yardManagement.ContainerTabIndex = 2;
                this.$store.state.yardManagement.dropContainerMovement = 3;
                this.$store.state.yardManagement.yardCollapse = 2;
            }
        });
}

async function Update(loading) {
    await this.getYardVisitReception(loading);
}

function toggleAdd(item) {
    this.ContainerItem = item;
    this.showIndex = 1;
}
  
//computed
function computedListContainer() {
    return this.dataContainer.map((item, index) => {
        return {
            ...item,
            Nro: index+1,
            VesselVoyage: `${item.VesselName} - ${item.VoyageArrival}`,
            Ata: DateFormater.formatDateTimeWithSlash(item.Ata ) ?? 'N/A',
            ConfirmationDate: DateFormater.formatDateTimeWithSlash(item.ConfirmationDate ) ?? 'N/A',
        };
    })
}

function fields(){ 
    return [
        { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
        { key: 'YardNamePlanning', label: this.$t('label.planned'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
        { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px; text-align:center;', sorter: true, filter: true},
        { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;  text-align:center;', sorter: true, filter: true},
        { key: 'ConfirmationDate', label: this.$t('label.confirmationDate'),  _classes: 'text-uppercase text-center', _style: 'min-width:150px;  text-align:center;', sorter: true, filter: true},
        { key: 'TpCargoDetailCode', label: `${this.$t('label.type')}`, _style:'min-width:120px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
        { key: 'ShippingLineCode', label: `${this.$t('label.newShippingLine')}`, _style:'min-width:150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
        { key: 'ImdgCode', label: this.$t('label.imdg'), _style:'min-width:100px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'Seals', label: this.$t('label.Seals'),  _style:'min-width:250px; text-align:center;', _classes:"text-center" , sorter: true, filter: true},
        { key: 'BlNo', label: this.$t('label.bl'),  _style:'min-width:120px; text-align:center;', _classes:" text-center" , sorter: true, filter: true},
        { key: 'Consignee', label: `${this.$t('label.consignee')}`,  _style:'min-width:150px; text-align:center;', _classes:"text-center", sorter: true, filter: true },
    ];
}

export default{
    name: 'equipment',
    data,
    mixins: [General],
    components: {
        EquipmentRegister,
    },
    methods: {
        toggleAdd,
        getYardVisitReception,
        Update,
    },
    computed: {
        fields,
        computedListContainer,
        ...mapState({
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            YardId: state => state.yardManagement.yardData.YardId,
            FgPositioning: state => state.yardManagement.FgPositioning,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
        })
    },
    watch:{
        dropItemMovementList: function (val) {
            if (val==1) {
                this.showIndex = 0;
                this.dataContainer = [];
                this.getYardVisitReception(false);
            }
        }
    }
}
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>