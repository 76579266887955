<template>
  <div>
    <CCol v-if="!fgDisabled" sm="12" class="my-2">
      <vue-dropzone 
        ref="myVueDropzone" 
        id="dropzone" 
        :options="dropzoneOptions" 
        :useCustomSlot="true"
        v-on:vdropzone-error="DropzoneError"
      > 
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
          <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
        </div>
      </vue-dropzone>
    </CCol>

    <CRow v-if="!fgDisabled" class="m-0 mt-4">
      <CCol sm="12" lg="10">
        <CInput
          :label="$t('label.description')"
          horizontal
          addLabelClasses="required text-right"
          :placeholder="$t('label.documentDescription')"
          v-uppercase
          :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}" 
          v-model="vForm.Document.DocumentDs.$model"
          :is-valid="hasError(vForm.Document.DocumentDs)"
          :invalid-feedback="errorMessage(vForm.Document.DocumentDs)"
          class="remove-space"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="auto"  class="d-flex align-items-start justify-content-end">
        <CButton 
          color="add"
          size="sm"
          v-c-tooltip="{
              content: $t('label.add'),
              placement: 'top-end'
          }"
          :disabled="isSubmitDocument"
          @click="submitDocument()" 
        >
          <CIcon name="checkAlt"/>
        </CButton>
      </CCol>
    </CRow>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListDocument"
        :fields="documentfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >

        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #DocsRoute="{item}">
          <td class="py-2 text-center">
            <CButton
              class="btn btn-sm btn-watch mr-1"
              color="excel"
              v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
              target="_blank"
              :href="item.DocsRoute"
            >
              <CIcon name="cil-cloud-download" />
            </CButton>
            <CButton
              v-if="!fgDisabled"
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.document'),
                placement: 'top-end',
              }"
              @click="deleteDocument(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapState } from 'vuex';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    isSubmitDocument: false,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      //acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    },
  };
}

//-----------------------   Method    ------------------------
async function resetForm(all = false) {
  let files = this.$refs.myVueDropzone.getQueuedFiles();

  if(files.length != 0){
    this.$refs.myVueDropzone.removeFile(files[0]);
  }
  
  this.form.Document = {
    DocumentDs: '',
    DocumentRoute: '',
  }

  if (all) {
    this.form.CustomInspectionDocumentJson = []
  }

  this.vForm.Document.$reset();
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function deleteDocument(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
  .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      this.$http.delete('files', item.DocumentRoute)
        .then(async(response) => {
          if (item?.YardCargoEventDetailDocumentId) {
            this.form.CustomInspectionDocumentJson = this.form.CustomInspectionDocumentJson.map(e => {
              if (e.IdX == item.IdX) {
                return {
                  ...e,
                  Status: 0,
                }
              }else{
                return e
              }
            })
          }else{
            this.form.CustomInspectionDocumentJson = this.form.CustomInspectionDocumentJson.filter(e => e.IdX != item.IdX);
          }
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

function submitDocument() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmitDocument = true;
    this.vForm.Document.$touch();
    let files = this.$refs.myVueDropzone.getQueuedFiles();
    if (files.length === 0) {
      this.$store.state.yardManagement.loading = false;
      this.isSubmitDocument = false;
      throw this.$t('label.documentQuestion');
    }
    if (this.vForm.Document.$error) {
      this.$store.state.yardManagement.loading = false;
      this.isSubmitDocument = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.$http.file('YardManagement-file', files[0])
      .then(response => {
        this.form.Document.DocumentRoute = response.data.data.files[0].path;
        this.form.CustomInspectionDocumentJson.push({
          IdX: this.form.CustomInspectionDocumentJson.length > 0 ? this.form.CustomInspectionDocumentJson[this.form.CustomInspectionDocumentJson.length - 1].IdX + 1 : 1,
          DocumentDs: this.form.Document.DocumentDs,
          DocumentRoute: this.form.Document.DocumentRoute,
          Status: 1,
        });
        this.isSubmitDocument = false;
        this.$store.state.yardManagement.loading = false;
        this.resetForm()
      }).catch((err) => {
        this.isSubmitDocument = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmitDocument = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: e});
  }
}

//-----------------------   Computed   ------------------------
function computedListDocument() {
  return this.form.CustomInspectionDocumentJson.filter(e => e.Status == 1).map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      DocsRoute: item.DocumentRoute ? 
        `${this.$store.getters["connection/getBase"]}${item.DocumentRoute.replace("Public/", "", "i")}`
      : "N/A",
    };
  });
}

function documentfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width: 1px; min-width: 45px', _classes: 'text-center', filter: false },
    { key: 'DocumentDs', label: this.$t('label.documents'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;' },
    { key: 'DocsRoute', label: '', _style: `width: 1px; min-width: 90px`, _classes: 'text-center', sorter: false, filter: false },
  ];
}

export default {
  name: 'documentation-tab',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Tab: Boolean,
    show: Boolean,
    fgDisabled: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    DropzoneError,
    deleteDocument,
    submitDocument,
    resetForm,
  },
  computed:{
    documentfields,
    computedListDocument,
    ...mapState({
     
    })
  },
  watch: {
    show: async function(Newval) {
      if (!Newval) {
        this.resetForm(true);
      }
    },
  }
};
</script>