<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="titleModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="$store.state.grupomodulo.updatedModal"
    > 
    <CustomTabs :active-tab="tabCus" class="text-table">
        <CustomTab :title="$t('label.information')" icon="cil-columns">  
          <CRow class="mr-5">
            <CCol sm="12">
              <CInput
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right"
                :label="$t('label.subsystemType')"
                v-model="TpModuleName"  
                size="sm"     
                disabled          
              />
            </CCol>
            <CCol sm="12" v-if="(TpModuleFilterSelect.toUpperCase() === this.sigetWebId) || (TypeSubSystemSelect.toUpperCase() === this.sigetWebId)">
              <CInput
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="text-right"
                :label="$t('label.navigation')"
                v-model="TpNavigationName" 
                size="sm"     
                disabled          
              />
            </CCol>
              <CCol sm="12"  v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
                <CInput
                  :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                  addLabelClasses="text-right"
                  :label="$t('label.Ship')"
                  v-model="VesselName" 
                  size="sm"     
                  disabled        
                />
             </CCol>
          </CRow>
          <CRow class="mr-5">
            <CCol sm="6" v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
              <CInput             
                :label="$t('label.icon')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"  
                :placeholder="$t('label.icon')" 
                v-model="$v.dataModule.Icon.$model"           
                :is-valid="hasError($v.dataModule.Icon)"
                :invalid-feedback="errorMessage($v.dataModule.Icon)"
                size="sm"
                class="ml-1"
              >
              </CInput>
            </CCol>
            <CCol sm="6" v-if="(NavigationFilterSelect.toUpperCase() === this.visitaId) || (NavigationSelect.toUpperCase() === this.visitaId)">
              <CInput              
                :label="$t('label.srcOption')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                :placeholder="$t('label.srcOption')"
                v-model="$v.dataModule.OptionSrc.$model"
                :is-valid="hasError($v.dataModule.OptionSrc)"
                :invalid-feedback="errorMessage($v.dataModule.OptionSrc)"
                size="sm"
              >
              </CInput>
            </CCol>
          </CRow>
            <CRow class="mr-5">
              <CCol sm="12">
                <CInput 
                  :label="$t('label.name')+' '+'(ES)'"
                  addLabelClasses="required text-right"
                  :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"              
                  v-uppercase
                  :placeholder="$t('label.registerName')+' '+'(ES)'"
                  v-model="$v.dataModule.GpoModuleNameEs.$model"
                  :is-valid="hasError($v.dataModule.GpoModuleNameEs)"
                  :invalid-feedback="errorMessage($v.dataModule.GpoModuleNameEs)"
                  size="sm"
                >
              </CInput>
              </CCol>
            </CRow>
            <CRow class="mr-5">
            <CCol sm="12">
              <CInput 
                :label="$t('label.name')+' '+'(EN)'"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"              
                v-uppercase
                :placeholder="$t('label.registerName')+' '+'(EN)'"
                v-model="$v.dataModule.GpoModuleNameEn.$model"
                :is-valid="hasError($v.dataModule.GpoModuleNameEn)"
                :invalid-feedback="errorMessage($v.dataModule.GpoModuleNameEn)"
                size="sm"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="mr-5">
            <CCol sm="12">
              <CTextarea                    
                :label="$t('label.decription')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                type="textarea"
                v-uppercase
                v-model="$v.dataModule.description.$model"
                :is-valid="hasError($v.dataModule.description)"
                :invalid-feedback="errorMessage($v.dataModule.description)"
                size="sm"
                :plaintext="false"
                :readonly="false"
                :lazy="(true,400)"
              >
              </CTextarea>
            </CCol>
          </CRow>
          <CRow class="mr-5">
            <CCol sm="6">
              <CSelect                    
                :label="$t('label.level')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-6',input:'col-sm-12 col-lg-6'}"
                v-model="$v.dataModule.nivel.$model"              
                :value="LevelSelect"
                @change="LevelList($event)"
                :options="opcionesNivel"
                :is-valid="hasError($v.dataModule.nivel)"
                :invalid-feedback="errorMessage($v.dataModule.nivel)"
                size="sm"
                class="ml-1"
              >
             </CSelect>
            </CCol>
            <CCol sm="6">
              <CSelect                    
                :label="$t('label.father')"
                :class="dataModule.nivel==2  || dataModule.nivel==3 ? 'condition-label text-right' : 'text-right' "
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                v-model="$v.dataModule.padre.$model"
                :disabled="verificarPadre"
                :options="opcionesPadre"
                :is-valid="hasError($v.dataModule.padre)"
                :invalid-feedback="errorMessage($v.dataModule.padre)"
                size="sm"
              >
              </CSelect>
            </CCol>
          </CRow>
          <CRow class="mr-5">
            <CCol sm="12">
              <CInput                    
                :label="$t('label.order')"
                :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-8'}"
                addLabelClasses="required text-right"                                        
                v-model="$v.dataModule.orden.$model"
                :disabled="!dataModule.status"
                min="1"
                type="number"
                :is-valid="hasError($v.dataModule.orden)"
                :invalid-feedback="errorMessage($v.dataModule.orden)"
                size="sm"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="mr-5">
            <CCol sm="12">
              <CSelect        
                :options="statusOptions"
                size="sm"
                :value.sync="dataModule.status"
                :label="$t('label.status')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                addLabelClasses="required text-right"
                :is-valid="statusSelectColor"  
              />
            </CCol>
          </CRow>
        </CustomTab> 
        <CustomTab :title="$t('label.dependencies')" icon="cil-columns" v-if="this.dataModule.nivel==1" > 
          <dataTableExtended
            class="align-center-row-datatable"
            :items="ItemsHijos"
            :fields="fieldsUpdated"
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="5"
            pagination
            v-if="dataModule.nivel < 2"
            ref="table-grupo-update-modulo"
            sorter
            column-filter
            hover
          >
          <template #loading>
            <loading/>
          </template>
          <template #status="{item}">
            <td class="text-center center-cell">
              <div v-if="typeItem(item)">
                <CBadge  color="success">
                  {{$t('label.ACTIVO')}}
                </CBadge>
              </div>
              <div v-if="!typeItem(item)">
                <CBadge  color="danger">
                  {{$t('label.INACTIVO')}}
                </CBadge>
              </div>
            </td>
          </template>
          <template #GpoModuleId="{item,index}">
            <td>
              <CButtonGroup>
                <CButton
                  class="btn btn-light"
                  square
                  size="sm"
                  v-c-tooltip="{
                    content: $t('label.Down'),
                    placement: 'top-end',
                  }"
                  @click="updateItem(item, index, 0)"
                >
                <CIcon name="cil-arrow-circle-bottom"/>
                </CButton>
                <CButton
                  color="dark"
                  square
                  size="sm"
                  @click="updateItem(item, index, 1)"
                  v-c-tooltip="{
                    content: $t('label.Up'),
                    placement: 'top-end',
                  }"
                >
                <CIcon name="cil-arrow-circle-top"/>
                </CButton>
              </CButtonGroup>
            </td>
            </template>
            <template #Order="{item}">
              <td style="text-align:center;">
                {{item.Order}}
              </td>
            </template>
            <template #acciones="{item, index}">
              <td>
                <CButton
                  color="dark"
                  square
                  size="sm"
                  @click="onChange(item,index,0)"
                  v-c-tooltip="'Cambiar status'"
                >
                <CIcon name="cil-chevron-circle-left-alt"/>
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CustomTab>       
    </CustomTabs>   
      <div slot="footer">
        <CButton
          color="add"
          @click="registeredDateModal"
          :disabled="apiStateLoading"
          class="m-2"
        >
          <div v-if="apiStateLoading">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            {{$t('button.accept')}}
          </div>
          
          <div v-if="!apiStateLoading">
            <CIcon name="checkAlt"/>&nbsp;
            {{$t('button.accept')}}
          </div>
        </CButton>
        <CButton
          color="wipe"
          @click="setDateModal"
          :disabled="apiStateLoading"
          class="m-2"
        >
        <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>          
      </div>
    </CModalExtended>
  </div>
  
</template>

<script>
import ENUM from '@/_store/enum';
import { mapState,mapMutations } from 'vuex';
import GrupoValidations from '@/_validations/grupoModulo/GrupoModuloValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { tableTextHelpers } from '@/_helpers/funciones';
import general from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

//data
function data(){
  return {             
    originalAct:'',
    titleModal: this.$t('label.edit') + this.$t('label.moduleGroup')+' ',
    sudSystemItems:[],
    navigationItems:[],
    vesselItems:[],
    GpoModuleL1Item:[],
    WeighingItems:[],
    TypeSubSystemSelect:'',
    NavigationSelect:'',
    VesselSelect:'',
    LevelSelect:'',
    sigetWebId:'886B95BE-452A-45E0-8C11-2C162BC31AFE',  
    visitaId:'5FFE2FBD-CD0C-4B78-96B7-0C1F0F8CB97C',
    tabCus:0,
    GpoModuleL1Filter:'',
    Loading:false,
    TpModuleName:'',
    TpNavigationName:'',
    VesselName:'',
    dataModule:{
      id:0,
      TpModuleId:'',
      TpModuleNavegationId:'',
      TpVesselId:'',
      Icon:'',
      OptionSrc:'',
      GpoModuleNameEs:'',
      GpoModuleNameEn:'',
      description:'',
      nivel:0,
      orden:1,
      hijo:[],
      padre:0,
      status:0,
    },
  }
}

//methods
function setDateModal () {
  this.$store.state.grupomodulo.updatedModal = !this.$store.state.grupomodulo.updatedModal;
}
function typeItem(item){
  return (item.Status === 1) ? true : false;
}
function registeredDateModal(){
  this.$v.dataModule.$touch();
  try {
    //this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let { id,TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden,hijo,status } = this.dataModule;
    const { dispatch } = this.$store;
    if(id === 0){
      return
    }else{
      let x = this.originalData.map(function(e) {
        return e.id; 
      }).indexOf(id);
      
      if(parseInt(nivel) === 2){
        let res = this.originalData.find((items)=>{
          return items.id === padre;
        });
        if(res?.nivel && parseInt(res.nivel) !== 1){
          this.$notify({
            group: 'container',
            type: 'error',
            title: 'Error',
            text:  `${this.$t('label.fatherError')}`
          });
          return
        }else{
          if(res?.status && res.status === false){
            this.$notify({
              group: 'container',
              type: 'error',
              title: 'Error',
              text:  `${this.$t('label.fatherError')}`
            });
            return
          }
        }
      }

      if(x !== -1){
        if(parseInt(this.originalData[x].nivel) === parseInt(nivel)){
          if(parseInt(this.originalData[x].orden) === parseInt(orden)){
            if(padre !== this.originalData[x].padre && parseInt(nivel) === 2){
              let res = this.originalData.find((items)=>{
                return items.id === padre;
              })
              if(res !== undefined && res !== null){
                const itemOrdenado = res.hijos.sort(function(a,b){
                  return parseInt(a.Order) - parseInt(b.Order);
                });
                let ordenPadre = 0;
                itemOrdenado.map(function(e) {
                  if(e.FgActGpoModule && e.Order !== 0 && parseInt(e.Order) > ordenPadre && parseInt(e.Level) === 2){
                    ordenPadre = parseInt(e.Order);
                  }
                });
                if(parseInt(orden) > (ordenPadre+1)){
                  this.$notify({
                    group: 'container',
                    type: 'error',
                    title: 'Error',
                    text: `${this.$t('label.exidedOrder')}`
                  });
                  return
                }else{
                  let x = itemOrdenado.map(function(e) {
                    return parseInt(e.Order); 
                  }).indexOf(parseInt(orden));
                  if(x === -1){
                    orden = orden;
                  }else{
                    this.$notify({
                      group: 'container',
                      type: 'error',
                      title: 'Error',
                      text: `${this.$t('label.ordenExists')}`
                    });
                    return
                  }
                }
              }else{
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.ordenReselectOrder')}`
                })
                return 
              }
            }else{
              orden = orden;
            }
          }else{
            if(parseInt(nivel) === 1){
              const filtradonivel = this.originalData.filter(item => item.nivel === parseInt(nivel));
              const itemOrdenado = filtradonivel.sort(function(a,b){
                return parseInt(a.orden) - parseInt(b.orden);
              });
              if(parseInt(orden) === 1){
                let x = itemOrdenado.map(function(e) {
                  return parseInt(e.orden); 
                }).indexOf(1);
                if(x === -1){
                  orden = 1;
                }else{
                  this.$notify({
                    group: 'container',
                    type: 'error',
                    title: 'Error',
                    text: `${this.$t('label.ordenExists')}`
                  });
                  return
                }
              }
              let ordenPadre = 0;
              itemOrdenado.map(function(e) {
                if(e.status && e.orden !== 0 && parseInt(e.orden) > ordenPadre && parseInt(e.nivel) === 1){
                  ordenPadre = parseInt(e.orden);
                }
              })
              if(parseInt(orden) > (ordenPadre+1)){
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.exidedOrder')}`
                });
                return 
              }else{
                let x = itemOrdenado.map(function(e) {
                  return parseInt(e.orden); 
                }).indexOf(parseInt(orden));
                if(x === -1){
                  orden = orden;
                }else{
                  this.$notify({
                    group: 'container',
                    type: 'error',
                    title: 'Error',
                    text: `${this.$t('label.ordenExists')}`
                  });
                  return 
                }
              }
            }else{
              let res = this.originalData.find((items)=>{
                return items.id === padre;
              });
              if(res !== undefined && res !== null){
                const itemOrdenado = res.hijos.sort(function(a,b){
                  return parseInt(a.Order) - parseInt(b.Order);
                });
                let ordenPadre = 0;
                itemOrdenado.map(function(e) {
                  if(e.FgActGpoModule && e.Orden !== 0 && parseInt(e.Order) > ordenPadre && parseInt(e.Level) === 2){
                    ordenPadre = parseInt(e.Order);
                  }
                });
                if(parseInt(orden) > (ordenPadre+1)){
                  this.$notify({
                    group: 'container',
                    type: 'error',
                    title: 'Error',
                    text: `${this.$t('label.exidedOrder')}`
                  });
                  return 
                }else{
                  let x = itemOrdenado.map(function(e) {
                    return parseInt(e.Order); 
                  }).indexOf(parseInt(orden));
                  if(x === -1){
                    orden = orden;
                  }else{
                    this.$notify({
                      group: 'container',
                      type: 'error',
                      title: 'Error',
                      text: `${this.$t('label.ordenExists')}`
                    });
                    return 
                  }
                }
              }else{
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.ordenReselectOrder')}`
                })
                return 
              }
            }
          }
        }else{
          if(parseInt(nivel) === 1){
            const filtradonivel = this.originalData.filter(item => item.nivel === parseInt(nivel));
            const itemOrdenado = filtradonivel.sort(function(a,b){
              return parseInt(a.orden) - parseInt(b.orden);
            });
            if(parseInt(orden) === 1){
              let x = itemOrdenado.map(function(e) {
                return parseInt(e.orden); 
              }).indexOf(1);
              if(x === -1){
                orden = 1;
              }else{
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.ordenExists')}`
                });
                return
              }
            }
            let ordenPadre = 0;
            itemOrdenado.map(function(e) {
              if(e.status && e.orden !== 0 && parseInt(e.orden) > ordenPadre && parseInt(e.nivel) === 1){
                ordenPadre = parseInt(e.orden);
              }
            })
            if(parseInt(orden) > (ordenPadre+1)){
              this.$notify({
                group: 'container',
                type: 'error',
                title: 'Error',
                text: `${this.$t('label.exidedOrder')}`
              });
              return 
            }else{
              let x = itemOrdenado.map(function(e) {
                return parseInt(e.orden); 
              }).indexOf(parseInt(orden));
              if(x === -1){
                orden = orden;
              }else{
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.ordenExists')}`
                });
                return
              }
            }
          }else{
            let res = this.originalData.find((items)=>{
              return items.id == padre;
            });
            if(res !== undefined && res !== null){
              const itemOrdenado = res.hijos.sort(function(a,b){
                return parseInt(a.Order) - parseInt(b.Order);
              });
              let ordenPadre = 0;
              itemOrdenado.map(function(e) {
                if(e.FgActGpoModule && e.Order !== 0 && parseInt(e.Order) > ordenPadre && parseInt(e.Level) === 2){
                  ordenPadre = parseInt(e.Order);
                }
              });
              if(parseInt(orden) > (ordenPadre+1)){
                this.$notify({
                  group: 'container',
                  type: 'error',
                  title: 'Error',
                  text: `${this.$t('label.exidedOrder')}`
                });
                return 
              }else{
                let x = itemOrdenado.map(function(e) {
                  return parseInt(e.Order); 
                }).indexOf(parseInt(orden));
                if(x === -1){
                  orden = orden;
                }else{
                  this.$notify({
                    group: 'container',
                    type: 'error',
                    title: 'Error',
                    text: `${this.$t('label.ordenExists')}`
                  });
                  return 
                }
              }
            }else{
              this.$notify({
                group: 'container',
                type: 'error',
                title: 'Error',
                text: `${this.$t('label.ordenReselectOrder')}`
              })
              return 
            }
          }
        }
      }

      if(this.originalAct !== '' && id !== 0){
        if(this.originalAct !== status){            
            this.$swal
              .fire(this.alertProperties({
                  text: `${this.$t('label.changeStatusQuestion')+'?'}`,
              }))
              .then((result) => {
              if (result.isConfirmed) {
                dispatch('grupomodulo/putupdatedGrupoModulo', { id,TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden,status,hijo });
                this.$emit('Update-list');
              }else {
                //this.$swal('Operación cancelada');
              }
            })
        }else{
          dispatch('grupomodulo/putupdatedGrupoModulo', { id,TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden,status,hijo });
          this.$emit('Update-list');
        }
      }else{
        dispatch('grupomodulo/putupdatedGrupoModulo', { id,TpModuleId,TpModuleNavegationId,TpVesselId,Icon,OptionSrc,GpoModuleNameEs,GpoModuleNameEn,description,nivel,padre,orden,status,hijo });
        this.$emit('Update-list');
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function onChange(item,index,value){
  let status = (item.Status === 1) ? false : true;
  const x = this.dataModule.hijo.map(function(e) {
    return e.GpoModuleId; 
  }).indexOf(item.GpoModuleId);
  if(status){
    let index = 0;
    this.dataModule.hijo.map(function(e) {
      if(e.Status === 1){
        index = index + 1;
      }
    })
    this.dataModule.hijo[x].Status = 1;
    if(parseInt(this.dataModule.hijo[x].Order) === 0){
      this.dataModule.hijo[x].Order = index+1;
    }
    item._classes='';
  }else{
    let index = 0;
    this.dataModule.hijo.map(function(e) {
      if(parseInt(e.Order) > item.Order && e.GpoModuleId !== item.GpoModuleId){
        e.Order = parseInt(e.Order) - 1;
      }
    })
    this.dataModule.hijo[x].Status = 0;
    this.dataModule.hijo[x].Order = 0;
    item._classes='table-danger';
  }
}
function updateItem(item,index,tipo){
  if(parseInt(item.Status) !== 1 ) return
  const x = this.dataModule.hijo.map(function(e) {
    return e.GpoModuleId; 
  }).indexOf(item.GpoModuleId);

  if(tipo === 0){
    if(this.dataModule.hijo.length === 1){
      item.Order = 1;
    }else{
      const OrdenCambiada = parseInt(item.Order) + 1;
      let bandera = false;
      this.dataModule.hijo.map(function(e) {
        if(OrdenCambiada === parseInt(e.Order) && item.id !== e.GpoModuleId){
          bandera = true;
          e.Order = parseInt(e.Order) - 1;
        }
      });
      if(bandera){
        item.Order = OrdenCambiada;
        this.dataModule.hijo[x].Order = parseInt(item.Order);
      }
    }
  }else if(tipo === 1){
    if(parseInt(item.Order) === 1){
      item.Order = 1;
    }else{
      const OrdenCambiada = parseInt(item.Order) - 1;
      let bandera = false;
      this.dataModule.hijo.map(function(e) {
        if(OrdenCambiada === parseInt(e.Order) && item.id !== e.GpoModuleId){
          bandera = true;
          e.Order = parseInt(e.Order) + 1;
        }
      });
      if(bandera){
        item.Order = OrdenCambiada;
        this.dataModule.hijo[x].Order = parseInt(item.Order);
      }
    }
  }else{
    //item.Orden = 0;
    //this.dataModule.hijo[x].Orden = item.Orden;
  }
}
function getInformationGroup(id) {
  this.$store.state.grupomodulo.apiStateForm = ENUM.LOADING;
  let _lang = this.$i18n.locale;
  this.$http.ejecutar('GET', 'GpoModule-by-id',{ GpoModuleId: id }, '' ).then(
  response => {
    if(response.status === 200){
      const grupoInformation = response.data.data;
      try {
        if(grupoInformation.length !== 0){
          this.dataModule.id = grupoInformation[0].GpoModuleId;
          this.titleModal =  _lang=='en' ? this.$t('label.edit') + this.$t('label.moduleGroup')+": "+grupoInformation[0].GpoModuleNameEn: 
                                           this.$t('label.edit') + this.$t('label.moduleGroup')+": "+grupoInformation[0].GpoModuleNameEs;
          // this.dataModule.TpModuleId = grupoInformation[0].TpModuleFilterSelect;
          // this.dataModule.TpModuleNavegationId = grupoInformation[0].NavigationFilterSelect;
          // this.dataModule.TpVesselId = grupoInformation[0].VesselSelectData;
          this.dataModule.Icon = grupoInformation[0].Icon;
          this.dataModule.OptionSrc = grupoInformation[0].OptionSrc;
          this.dataModule.GpoModuleNameEs = grupoInformation[0].GpoModuleNameEs;
          this.dataModule.GpoModuleNameEn = grupoInformation[0].GpoModuleNameEn;
          this.dataModule.description = grupoInformation[0].GpoModuleDs;
          this.dataModule.nivel = parseInt(grupoInformation[0].Level);
          this.dataModule.orden = parseInt(grupoInformation[0].Order);
          this.originalAct = grupoInformation[0].FgActGpoModule;
          this.dataModule.status = grupoInformation[0].FgActGpoModule ? 1:0;
          if(parseInt(grupoInformation[0].Level) === 3){
            this.dataModule.padre = grupoInformation[0].GpoModuleParentId;
          }else if(parseInt(grupoInformation[0].Level) === 2){
            this.dataModule.padre = grupoInformation[0].GpoModuleParentId;
          }else if(parseInt(grupoInformation[0].Level) === 1){
            const grupohijosInformation = grupoInformation[0].GpoModuleChildJson;
            if(Array.isArray(grupohijosInformation) && grupohijosInformation.length !== 0){
              const chart = [];
              grupohijosInformation.map(function(e){
                chart.push({
                  GpoModuleId: e.GpoModuleId,
                  GpoModuleParentId: e.GpoModuleParentId,
                  GpoModuleNameEs: e.GpoModuleNameEs,
                  GpoModuleNameEn: e.GpoModuleNameEn,
                  GpoModuleDs: e.GpoModuleDs,
                  Order: e.Order,
                  Level: e.Level,
                  Status: (e.FgActGpoModule) ? 1 : 0,
                  UserId: e.TransaUserId
                })
              })
              this.dataModule.hijo = chart;
            }else if(Array.isArray(grupohijosInformation)){
              this.dataModule.hijo = [];
            }else if(typeof(grupohijosInformation) === "object"){
              const chart = [];
              chart.push({
                GpoModuleId: grupohijosInformation.GpoModuleId,
                GpoModuleParentId: grupohijosInformation.GpoModuleParentId,
                GpoModuleNameEs: grupohijosInformation.GpoModuleNameEs,
                GpoModuleNameEn: grupohijosInformation.GpoModuleNameEn,
                GpoModuleDs: grupohijosInformation.GpoModuleDs,
                Order: parseInt(grupohijosInformation.Order),
                Level: parseInt(grupohijosInformation.Level),
                Status: (e.FgActGpoModule) ? 1 : 0,
                UserId: grupohijosInformation.TransaUserId
              })
              this.dataModule.hijo = chart;
            }else{
              this.dataModule.hijo = [];
            }
          }
          this.$v.dataModule.$touch();
          this.$store.state.grupomodulo.apiStateForm = ENUM.INIT;
        }else{
          this.$store.state.grupomodulo.apiStateForm = ENUM.ERROR;
          this.setDateModal();
        }
      } catch (error) {
        this.$store.state.grupomodulo.apiStateForm = ENUM.ERROR;
        this.$store.state.grupomodulo.messageError = error;
        console.log(error);
        this.setDateModal();
      }
    }else{
      this.$store.state.grupomodulo.apiStateForm = ENUM.ERROR;
      this.setDateModal();
    }
  })
  .catch(err => {
    this.$store.state.grupomodulo.apiStateForm = ENUM.ERROR;
    this.setDateModal();
    this.grupoInformation = [];
  });
}


function getSudSystemList() {
  this.$http.get("TpModuleConfigWeb-list")
    .then(response => {       
      this.sudSystemItems = response.data.data;
      //this.getNavigationList();
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
} 
function getVesselList() {
  this.Loading = true;
  this.$http.get("TpVessel-list")
    .then(response => {       
      this.vesselItems = response.data.data;
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false
    });
} 
function GpoModuleL1List() {
  if (this.TpModuleNavegationId!='') { 
    this.Loading = true;
    this.$http.get("GpoModuleL1-list",{TpModuleId: this.TpModuleFilterSelect,TpModuleNavegationId:this.NavigationFilterSelect})
    .then(response => {       
      this.GpoModuleL1Item = response.data.data;
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false;
    });
  }else{
    this.dataModule.padre='';
    this.GpoModuleL1Item=[];
  }
}
function WeighingL1List() {
    this.Loading = true;
    this.$http.get("GpoModule-list",{TpModuleId: this.TpModuleFilterSelect,TpModuleNavegationId:this.NavigationFilterSelect,GpoModuleOrigId:''})
    .then(response => {       
      this.WeighingItems = response.data.data;
      this.Loading = false
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false;
    });
}
function LevelList(event) {
  this.dataModule.padre = '';
  this.LevelSelect = event.target.value;
  if ((this.LevelSelect==2) || (this.LevelSelect==3)) {
     if (this.NavigationFilterSelect) {
       this.GpoModuleL1List();
     }else{
       this.WeighingL1List();
     }
  }else{
    this.dataModule.padre = '';
    this.WeighingItems = [];
    this.GpoModuleL1Item=[];
  }
}
//computed
function opcionesPadre(){
  let _lang = this.$i18n.locale;
  let opciones = [{
    value : 0,
    label : this.$t('label.select')
  }];
  if (this.NavigationFilterSelect!=''){
    if (this.NavigationFilterSelect==this.visitaId) {
      if(this.filterVessel.length === 0){
        return [{
          value: '', 
          label: this.$t('label.select'),
        }];
      }else{
        var chart = [{
          value: '', 
          label: this.$t('label.select'),
        }]
        this.filterVessel.map(function(e){
          chart.push({
            value: e.GpoModuleId, 
            label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
          })    
        })
        return chart;
      }  
    }else{
      if(this.GpoModuleL1Item.length === 0){
        return [{
          value: '', 
          label: this.$t('label.select'),
        }];
      }else{
        var chart = [{
          value: '', 
          label: this.$t('label.select'),
        }]
        this.GpoModuleL1Item.map(function(e){
          chart.push({
            value: e.GpoModuleId, 
            label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
          })    
        })
        return chart;
      }
    }  
  }else{
    this.WeighingItems.map(function(e) {
      if(parseInt(e.Level) === 1 && e.FgActGpoModule){
        opciones.push({
          value : e.GpoModuleId,
          label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
        }) 
      }
    })
    return opciones;
  } 
}
function verificarPadre(){
  return ((parseInt(this.dataModule.nivel) === 2) || (parseInt(this.dataModule.nivel) === 3)) ? false : true;
}
function apiStateLoading() {
  return this.apiState === ENUM.LOADING;
}
function apiStateFormLoading() {
  let carga = false;
  if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
    carga = !carga;
  }
  return carga;
}
function originalData(){
  return this.$store.getters["grupomodulo/myDatatable"];
}
function computedItems () {
  return this.dataModule.hijo;
}
function ItemsHijos(){
  if(this.idValor !== 0){
    const chart = [];
    this.computedItems.sort(function(a,b){
      if (a.Status < b.Status) return 1;
      else if (a.Status > b.Status) return -1;
      else {
        return parseInt(a.Order) - parseInt(b.Order);
      }
    })
    this.computedItems.map(function(e){
      chart.push({
        GpoModuleId:e.GpoModuleId,
        GpoModuleParentId:e.GpoModuleParentId,
        GpoModuleNameEs: e.GpoModuleNameEs,
        GpoModuleNameEn: e.GpoModuleNameEn,
        GpoModuleDs: e.GpoModuleDs,
        Order: parseInt(e.Order),
        Level:parseInt(e.Level),
        UserId: e.TransaUserId,
        Status: e.Status ,
        _classes: ( parseInt(e.Status) === 1 ) ? '' : 'table-danger'
      });
    })  
    
    return chart.filter(item => {
      return item
    })
  }
  return [];
}
function opcionesNivel(){
  if (this.NavigationFilterSelect!='') {
    return[
            { value:0,label: this.$t('label.select')},
            { value:1,label: this.$t('label.level') + ' 1'},
            { value:2,label:this.$t('label.level') + ' 2'},
            { value:3,label:this.$t('label.level') + ' 3'}
        ]
  }else{
    return[
            { value:0,label: this.$t('label.select')},
            { value:1,label: this.$t('label.level') + ' 1'},
            { value:2,label:this.$t('label.level') + ' 2'},
          ]
  }
}
function statusSelectColor() {
  return this.dataModule.status === 1;
}
function fieldsUpdated(){
  return[
          { 
            key: 'GpoModuleId', 
            label: '#',
            _style: 'width:3%', 
            sorter: false, 
            filter: false 
          },
          { key: 'GpoModuleNameEs', label: this.$t('label.gpmoduloName')+' '+('(ES)'),_style: 'width:35%',_classes:"center-cell" },
          { key: 'GpoModuleNameEn', label: this.$t('label.gpmoduloName')+' '+('(En)'),_style: 'width:35%',_classes:"center-cell"},
          { key: 'Order', label: this.$t('label.order'),_style: 'text-align:center;',_style: 'width:15%',_classes:"center-cell" },
          { key: 'status', label: this.$t('label.status'),filter :false,_classes:"center-cell",_style: 'width:15%',  },
          { 
            key: 'acciones', 
            label: '', 
            _style: 'width:45px', 
            sorter: false, 
            filter: false
          }, 
        ]
}  
function vesselListFormatted(){
  if(this.vesselItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vesselItems.map(function(e){
      chart.push({
        value: e.TpVesselId, 
        label: e.TpVesselName,
      })    
    })
    return chart;
  }
}  


//watch
function idValor(newValue,OldValue){
  if(newValue !== 0){
    if(this.updatedModal){
      let idValor = newValue;
      this.dataModule.id = newValue;
      this.getInformationGroup(idValor);
    }
  }
}
async function updatedModal (newQuestion, oldQuestion) {
    if(newQuestion === false) {
      this.$store.state.grupomodulo.id = 0;
      this.dataModule.id = 0;
      this.dataModule.TpModuleId='';
      this.dataModule.TpModuleNavegationId='';
      this.dataModule.TpVesselId='';
      this.dataModule.Icon='';
      this.dataModule.OptionSrc='';
      this.dataModule.GpoModuleNameEs = '';
      this.dataModule.GpoModuleNameEs = '';
      this.dataModule.description = '';
      this.dataModule.orden = 0;
      this.dataModule.nivel = 0;
      this.dataModule.padre = 0;
      this.dataModule.hijo = [];
      this.titleModal = this.$t('label.edit') + this.$t('label.moduleGroup') +' ';
      this.originalAct = '';
      this.TypeSubSystemSelect='',
      this.NavigationSelect='',
      this.VesselSelect='',
      this.tabCus = 0,
      this.sudSystemItems = [],
      this.navigationItems= [],
      this.vesselItems = [],
      this.GpoModuleL1Item = [],
      this.WeighingItems = [],
      this.NavigationSelec = '',
      this.TpModuleName = '',
      this.TpNavigationName = '',
      this.VesselName = '',
      this.LevelSelect='',
      this.GpoModuleL1Filter ='',
      this.idSelect = false;
      this.dataModule.status = true;
      this.$nextTick(() => { this.$v.$reset() })
    }else{
      let _lang=this.$i18n.locale;

      if (!this.NavigationFilterSelect) {
        this.WeighingL1List();
      }else if(this.visitaId==this.NavigationFilterSelect){
        this.VesselName = this.VesselObj.TpVesselName;
        this.TpVesselId = this.VesselObj.TpVesselId;
        this.GpoModuleL1List();
      }else{
        this.GpoModuleL1List();
      }
      //asigna nombre al input de tipo de subsistema
      this.TpModuleName =  _lang=='en' ? this.TpModuleObj.TpModuleNameEn : this.TpModuleObj.TpModuleNameEs;
      //asigna nombre al input de tipo de navegacion
      if(this.NavigationFilterSelect==this.visitaId) {
        this.idSelect=true;
        this.TpNavigationName = _lang=='en' ? this.navigationObj[0].TpModuleNavegationEn : this.navigationObj[0].TpModuleNavegationEs;
      }else{
        this.idSelect=false;
        this.TpNavigationName = _lang=='en' ? this.navigationObj[1].TpModuleNavegationEn : this.navigationObj[1].TpModuleNavegationEs;
      }
      //igualando los selects con los input del modal
      this.dataModule.TpModuleId = this.TpModuleFilterSelect;
      this.dataModule.TpModuleNavegationId = this.NavigationFilterSelect;
      this.dataModule.TpVesselId = this.VesselSelectData;
    }
}

function apiStateForm(newQuestion,OldQuestion){
  if(newQuestion === ENUM.LOADED){
    this.$store.state.grupomodulo.updatedModal = false;
  }
}

export default {
    name: 'app-grupo-updated-modulo',
    data,
    props: ['TpModuleFilterSelect', 'NavigationFilterSelect','TpModuleObj','navigationObj','filterVessel','VesselSelectData','VesselObj'],
    mixins:[general,ModalMixin],
    components:{CustomTabs,CustomTab},
    validations(){ return GrupoValidations(parseInt(this.dataModule.nivel),this.dataModule.status,this.idSelect) },
    computed: {
      opcionesPadre,
      verificarPadre,
      originalData,
      computedItems,
      fieldsUpdated,
      opcionesNivel,
      apiStateLoading,
      apiStateFormLoading,
      ItemsHijos,
      vesselListFormatted,
      ...mapState({
        idValor: state => state.grupomodulo.id,
        apiState: state => state.grupomodulo.apiState,
        apiStateForm: state => state.grupomodulo.apiStateForm,
        updatedModal: state => state.grupomodulo.updatedModal,
      })
    },
    watch:{
        idValor,
        updatedModal,
        apiStateForm
    },
    directives: UpperCase,
    methods: {
      getInformationGroup,
      setDateModal,
      updateItem,
      typeItem,
      onChange,
      registeredDateModal,
      statusSelectColor,
      getSudSystemList,
      getVesselList,
      GpoModuleL1List,
      WeighingL1List,
      LevelList,
    }
}
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}

.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}

</style>