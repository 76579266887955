import {
    onlyMayusTextEspecial,
    SoloContenedores,
    onlyMayusText,
    onlyAlphanumeric,
    SoloDecimales,
    requiredSelect,
    UtcZone
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    sameAs,
    not,
    helpers,
    decimal,
    minValue,
    maxValue,
    numeric,
    integer,
} from "vuelidate/lib/validators";

const metricsWithSymbol = (value) => /^([-+]{1})?(?:[1-9][0-9]{0,15}|0)(\.[1-9])?(\.\d{1,7})?$/.test(value);
const metricsWSNotReq = (value) => !helpers.req(value) || metricsWithSymbol(value);

// para validacion del check-digit

const charValue = (char, special = false) =>{
    let values = {
        A:10,
        B:12,
        C:special?2:13,
        D:14,
        E:15,
        F:16,
        G:17,
        H:special?4:18,
        I:19,
        J:20,
        K:21,
        L:special?0:23,
        M:24,
        N:25,
        O:26,
        P:27,
        Q:28,
        R:29,
        S:30,
        T:31,
        U:special?9:32,
        V:34,
        W:35,
        X:36,
        Y:37,
        Z:38,
    }

    return values[char];
}

function onlyNumbers(char) {
	var letters = /^[0-9]/g; //g means global
	return letters.test(char);
}

function onlyAlpha(char) {
	var letters = /^[A-Z]/g; //i means ignorecase
    return letters.test(char);
}

function onlyUniqueAlpha(char) {
	var letters = /[UJZ]/g; //i means ignorecase
    return letters.test(char);
}


async function containerCodeValidation(text) {

    try {
        let chars = text.split('');
        /*
        if( chars.length != (11)){
            throw ('lengthCodeInvalid')
        }
        */

        const special = text.substr(0,3) == "HLCU";

        let charValues = await chars.map((element, index)=>{
            //let result = false; 
            let value;
            /*
            
            if(index < 3){
                result = onlyAlpha(element);    
            } else if(index == 3){
                result = onlyUniqueAlpha(element);    
            } else {
                result = onlyNumbers(element);
            } 

            if(!result && (index < 3)){
                throw (`OnlyUpperCaseLetterInPosition#${index+1}`)
            } else if (!result && (index == 3)){
                throw (`OnlySpecificUpperCaseLetterInPosition#${index+1}#(U, J, Z)`)
            } else if (!result){
                throw (`OnlyNumberInPosition#${index+1}`)
            }
            */
            
            if(index< 4){
                value = charValue(element, special);
            } else {
                value = parseInt(element);
            } 

            if( index == (chars.length-1))
                return value;
            
            value = parseInt(value * Math.pow(2, index));

            return value;
        });

        let plusValues = 0;

        await charValues.forEach((element, index) => {
            if (index<(charValues.length-1))
                plusValues = parseInt(plusValues+element);
        });
        
        let resultDevided = Math.floor(plusValues/11) ;
        let resultTimes = parseInt(resultDevided*11)
        let lastChar = parseInt(plusValues - resultTimes);

        

        if(lastChar == 10){
            lastChar = 0;
        }

        if (charValues[charValues.length-1] != lastChar){
            throw (`checkDigitInvalid`);
        } 
            
        return true;
    } catch (e){
        return false;
    }
}

//fin de las validacion del check digit

export const FormContainerData = (limitWEIGHT,limitVGM,obligationVGM,obligationResinto,DryReeferStatus,obligationUnidadFlashPoint,obligationImdgCode) => {
    let vgmData = {};
    if(obligationVGM){
        vgmData = {
            required,
            maxLength: maxLength(9),
            limit(value) {
                if (value === '' || value === null || limitVGM === null) return true
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let valueReal = parseFloat(value.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
                        if(parseFloat(limitVGM) < parseFloat(valueReal)){
                            resolve(false)
                        }else{
                            resolve(true);
                        }
                    })
                }, 350 + Math.random() * 300)
            } 
        }
    }else{
        vgmData = {
            maxLength: maxLength(9),
            limit(value) {
                if (value === '' || value === null || limitVGM === null) return true
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let valueReal = parseFloat(value.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
                        if(parseFloat(limitVGM) < parseFloat(valueReal)){
                            resolve(false)
                        }else{
                            resolve(true);
                        }
                    })
                }, 350 + Math.random() * 300)
            } 
        }
    }
    let Sealdata = {}
    if(obligationResinto){
        Sealdata = {
            required,
            minLength: minLength(1),
            maxLength: maxLength(50)
        }
    }else{
        Sealdata = {
            minLength: minLength(1),
            maxLength: maxLength(50)
        }
    }
    let rangeRf = {}
    let SelectMeasureRf = {}
    let rangeRfUnity = {}
    if(DryReeferStatus){
        SelectMeasureRf = {
            required,
        }
        rangeRf = {
            required,
            minLength: minLength(4),
            maxLength: maxLength(50)
        }
        rangeRfUnity = {
            required,
            minLength: minLength(2),
            maxLength: maxLength(4),
            metricsWSNotReq
        }
    }else{
        SelectMeasureRf = {
        }
        rangeRf = {
            minLength: minLength(4),
            maxLength: maxLength(50)
        }
        rangeRfUnity = {
            minLength: minLength(2),
            maxLength: maxLength(4),
            metricsWSNotReq
        }
    }
    let unidadFlashPoint = {}
    if(obligationUnidadFlashPoint){
        unidadFlashPoint = {
            required
        }
    }
    let IdImdgCode = {
        minLength: minLength(1),
        maxLength: maxLength(5)
    }
    if(obligationImdgCode){
        IdImdgCode = {
            required,
            minLength: minLength(1),
            maxLength: maxLength(5)
        }
    }
    return {
        formContainerData: {
            //columna 1
            ContainerCode:{
                required,
                onlyAlphanumeric,
                SoloContenedores,
                checkDigit: containerCodeValidation,
                minLength: minLength(11),
                maxLength: maxLength(11)
            },
            portActivity:{
                
            },
            stowageMovement:{
                
            },
            PreviusPosition:{

            },
            NewPosition:{
                
            },
            VesselBayId:{
                required
            },
            BayCode: {
                required,
                numeric,
                minLength: minLength(3),
                maxLength: maxLength(3)
            },
            VesselBayPosId:{
                required
            },
            BaySlot:{
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(10)
            },
            SizeId:{
                required
            },
            Size:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(2)
            },
            TpCargoClassId:{},
            Class:{
                minLength: minLength(1),
                maxLength: maxLength(1)
            },
            TpCargoStatusId:{
                required
            },
            Status:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            LoadPortId:{
                required,
                notsameAsport: not(sameAs('DischargePortId'))
            },
            LoadPort:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(5),
                notsameAsport: not(sameAs('DischargePort'))
            },
            DischargePortId:{
                required,
                notsameAsport: not(sameAs('LoadPortId'))
            },
            DischargePort:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(5),
                notsameAsport: not(sameAs('LoadPort'))
            },
            DeliveryPortId:{},
            DeliveryPort:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            OptionalPortId:{},
            OptionalPort:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            TranshipmentPortId:{},
            TranshipmentPort:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            ShippingLineId:{
                required
            },
            ShippingLineCode:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            ShippingLineName:{
                minLength: minLength(1),
                maxLength: maxLength(20)
            },
            HeadingId:{
                
            },
            CommodityId:{
                
            },
            CommodityCode:{
                maxLength: maxLength(50)
            },
            CommodityIdNumber:{
                maxLength: maxLength(50)
            },
            HandlingRemark:{
                maxLength: maxLength(50)
            },
            ContainerRemark:{
                maxLength: maxLength(50)
            },
            SpecialInstruction:{
                maxLength: maxLength(250)
            },
            GeneralInfo:{
                onlyAlphanumeric,
                maxLength: maxLength(250)
            },
            DescriptionOfGoods:{
                maxLength: maxLength(250)
            },
            BookingNumber:{
                onlyAlphanumeric,
                maxLength: maxLength(50)
            },
            Consignee:{
                minLength: minLength(4),
                maxLength: maxLength(500)
            },
            ShipperOwn:{
                minLength: minLength(4),
                maxLength: maxLength(500)
            },
            Shipper:{
                minLength: minLength(4),
                maxLength: maxLength(500)
            },
            BlNo:{
                maxLength: maxLength(250)
            },
            Dua:{
                onlyAlphanumeric,
                maxLength: maxLength(250)
            },
            OriginalPortLoading:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            OriginalPortLoadingId:{},
            //columna 2
            TpCargoId:{
                
            },
            MetadataId:{
                required
            },
            IsoCode:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(50)
            },
            TpCargoName:{

            },
            TpCargoCode:{

            },
            //columna 2
            Lenght:{
                required,   
                maxLength: maxLength(9)
            },
            UnitMeasureLenght:{
                required
            },
            Height:{
                required,
                maxLength: maxLength(9)
            },
            UnitMeasureHeigth:{
                required
            },
            Weigth:{
                required,
                maxLength: maxLength(9),
                limit(value) {
                    if (value === '' || value === null || limitWEIGHT === 0.00) return true;
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            let valueReal = parseFloat(value.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
                            if(parseFloat(limitWEIGHT) < parseFloat(valueReal)){
                                resolve(false)
                            }else{
                                resolve(true);
                            }
                        })
                    }, 350 + Math.random() * 300)
                }   
            },
            UnitMeasureWeigth:{
                required
            },
            Vgm: vgmData,
            UnitMeasureVgm:{
                required
            },
            DescriptionType:{
                minLength: minLength(4),
                maxLength: maxLength(50)
            },
            //columna 3
            UnitMeasureRf:SelectMeasureRf,
            MinimumRangeRf:rangeRfUnity,
            MaximumRangeRf:rangeRfUnity,
            ReeferSetting:rangeRf,
            PluggedIn:{
                required
            },
            //columna 4
            UnitMeasureDim:{},
            DimFront:{
                SoloDecimales,
                maxLength: maxLength(9),
            },
            DimBack:{
                SoloDecimales,
                maxLength: maxLength(9),
            },
            DimLeft:{
                SoloDecimales,
                maxLength: maxLength(9),
            },
            DimRight:{
                SoloDecimales,
                maxLength: maxLength(9),
            },
            DimTop:{
                SoloDecimales,
                maxLength: maxLength(9),
            },
            //columna 5
            ImdgClassId:{},
            ImdgId:{},
            ImdgCode:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            ImdgClassCode:IdImdgCode,
            ImgdPageNo:{
                minLength: minLength(1),
                maxLength: maxLength(5)
            },
            UnNumber:{
                minLength: minLength(4),
                maxLength: maxLength(5)
            },
            FlashPoint:{
                minLength: minLength(2),
                maxLength: maxLength(4),
                metricsWSNotReq
            },
            UnitMeasureFlashPoint:unidadFlashPoint,
            PackagingGroupId:{},
            PackagingGroupCode:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            EmergencySheduleNo:{
                onlyAlphanumeric,
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
            MedicalGuide:{
                onlyAlphanumeric,
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
            ImdgCodePlaceCardUpper:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            ImdgCodePlaceCardLower:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            DangerousLabel1:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            DangerousLabel2:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            DangerousLabel3:{
                minLength: minLength(1),
                maxLength: maxLength(10)
            },
            DangerousLabelOptional:{
                minLength: minLength(1),
                maxLength: maxLength(250)
            },
            DescriptionOfGoodsDGS:{
                minLength: minLength(1),
                maxLength: maxLength(250)
            },
            Seal1:Sealdata,
            Seal2:{
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
            Seal3:{
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
            Seal4:{
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
        }
    }
}

export const FormMovimiento = (optionalPosition) => {
    let position = {};
    let baycode = {};
    if(optionalPosition){
        position = {
            required,
            numeric
        }
        baycode = {
            required
        }
    }
    return {
        formMovimiento: {
            MovStowageReasonId:{
                required,
            },
            MovStowageReasonDs:{
            },
            NewPosition:position,
            BayCode:baycode
        }
    }
}
export const FormMovimientoDirect = () => {
    return {
        formMovimientoDirect: {
            TransacStowageId:{},
            MovStowageReasonDs:{},
            SiglaContenedor:{
                required
            },
            PuertoProcedencia:{
                required
            },
            Bl:{
            },
            Consignario:{
                required
            }
        }
    }
}
export const FormPreliminar = () => {
    return {
        formPreliminar: {
            Name:{
                required,
                minLength: minLength(4),
            }
        }
    }
}