<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf" v-for="(page, index) in Condition" :key="page">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{ titleCompany }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleVessel }}
              </strong>
            </div>
            <div>
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="info-vessel">
                <div class="title-report">
                 <table class="title-table">
                    <tbody>
                      <tr>
                        <td class="limit-field-16 text-size-10">
                          <strong>{{ $t("label.date") }}: </strong>
                          {{Day}}
                        </td>
                        <td class="limit-field-16 text-size-10">
                          <strong>{{ $t("label.crane")}}: </strong>
                          {{CraneAlias}}
                        </td>
                        <td class="text-center">
                          <strong>{{ $t("label.dailyReport") }}</strong>
                        </td>
                        <td class="limit-field-16">
                        </td>
                        <td class="limit-field-16">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div class="vessel">
                  <table>
                    <tbody>
                      <tr>
                        <td class="limit-field-25 header-bg text-center">
                          <strong>{{ $t("label.benningOperation") }}</strong>
                        </td>
                        <td class="limit-field-25">
                          {{ DateFormat(Header.BeginningOfOperations) }}
                        </td>
                        <td class="limit-field-25 header-bg text-center">
                          <strong>{{ $t("label.Agency") }}</strong>
                        </td>
                        <td class="limit-field-25">
                          {{ Header.AgencyName }}
                        </td>
                      </tr>
                      <tr>
                        <td class="limit-field-25 header-bg text-center">
                          <strong>{{ $t("label.client") }}</strong>
                        </td>
                        <td class="limit-field-25">
                          {{ Header.ClientName }}
                        </td>
                        <td class="limit-field-25 header-bg text-center">
                          <strong>{{ $t("label.operator") }}</strong>
                        </td>
                        <td class="limit-field-25">
                          {{ Header.OperatorName }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div class="holds-info mt-2">
                <div class="Load-info">
                  <div class="Load-div">  
                    <table class="load-table">
                      <tbody>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>
                              {{'TON'+' '+$t('label.PerHours')}}
                            </strong>
                          </td>
                          <td class="text-table-left">
                            <strong>{{ formatNumber(Embarked.EmbarkedCargoXHour)  }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>
                              {{$t('label.ToLoad')}}
                            </strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.Remaining) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>
                              {{$t('label.AveragePerBin')+$t('label.metricTonnageAcronym')}}
                            </strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.AverageTonPerImplement) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>{{$t('label.Nocturnal')}}</strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.NightTon) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>{{$t('label.Diurnal')}}</strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.DayTon) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>SUBTOTAL</strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.SubTotalTon) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>{{`${$t('label.Accumulated')} (${StartsDate} - ${Day})`}}</strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.AccumulatedTon) }}</td>
                        </tr>
                        <tr>
                          <td class="header-bg text-table-left">
                            <strong>TOTAL</strong>
                          </td>
                          <td class="text-table-left">{{ formatNumber(Embarked.TotalTon) }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="tables-info">
                      <table class="movement-table">
                        <tbody>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>MPH</strong>
                            </td>
                            <td class="text-table-left">
                              <strong>{{ (Movement.MovXHour) }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>
                                {{$t('label.movement')}}
                              </strong>
                            </td>
                            <td class="text-table-left">{{ (Movement.Movement) }}</td>
                          </tr>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>
                                {{$t('label.Nocturnal')}}
                              </strong>
                            </td>
                            <td class="text-table-left">{{ (Movement.NightMovs) }}</td>
                          </tr>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>
                                {{$t('label.Diurnal')}}
                              </strong>
                            </td>
                            <td class="text-table-left">{{ (Movement.DayMovs) }}</td>
                          </tr>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>
                                {{`${$t('label.Accumulated')} (${StartsDate} - ${Day})`}}
                              </strong>
                            </td>
                            <td class="text-table-left">{{ (Movement.AccumulatedMovs) }}</td>
                          </tr>
                          <tr>
                            <td class="header-bg text-table-left">
                              <strong>TOTAL 24 HRS</strong>
                            </td>
                            <td class="text-table-left">{{ (Movement.Total24Hrs) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="turn-div mt-2" v-if="Turn.length >= page">
                    <table class="turn-table">
                      <thead>
                        <tr>
                          <th colspan="4" class="text-center header-bg">
                            {{ $t('label.turn') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-for="(Turns) in Turn[index].index" :key="Turns.TurnId">
                            <table class="turn-info">
                              <thead>
                                <tr>
                                  <th colspan="4" class="text-center">{{ TurnName(Turns) }}</th>
                                </tr>
                                <tr>
                                  <th style='width:25%' class="text-center">VC</th>
                                  <th style='width:25%' class="text-center">HD</th>
                                  <th style='width:20%' class="text-center">CANT</th>
                                  <th style='width:30%' class="text-center">TON</th>
                                </tr>
                              </thead>   
                              <tbody v-if="Turns.CraneJson.length > 0">
                                <tr v-for="TurnCranes in Turns.CraneJson" :key="TurnCranes.IdX">
                                  <td class="text-center">{{TurnCranes.CraneAlias}}</td>
                                  <td class="text-center">{{TurnCranes.VesselHoldName}}</td>
                                  <td class="text-center">{{ TurnCranes.CantImplement }}</td>
                                  <td class="text-center">{{ formatNumber(TurnCranes.Ton) }}</td>                                                                                                            
                                </tr>
                                <tr v-if="Turn.length == page">
                                  <td></td>
                                  <td class="text-center"><strong>TOT</strong></td>
                                  <td class="text-center"><strong>{{ Turns.TotalMov }}</strong></td>
                                  <td class="text-center"><strong>{{ formatNumber(Turns.TotalTon) }}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>                                                                                                           
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <table class="departure-table text-center" v-if="Lapse.length >= page">
                  <thead>
                    <tr>
                      <th colspan="2" class="header-bg text-center">
                        {{$t('label.LoadedPerTurns')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center"><strong>{{$t('label.lapse')+' Nº'}}</strong></td>
                      <td class="text-center"><strong>TON</strong></td>
                    </tr>
                    <tr>
                      <td colspan="2" class="px-0 pt-0 pb-0">
                        <table class="text-size-11 text-center lapse-table table-color">  
                          <tbody>
                            <tr v-for="Lapses in Lapse[index].index" :key="Lapses.IdX">
                              <td style='width:50%' class="">{{ Lapses.LapseCode }}</td>
                              <td style='width:50%' class="text-table-center">{{ formatNumber(Lapses.Ton) }}</td>                                                                                                            
                            </tr>
                            <tr v-if="Lapse.length == page">
                              <td style='width:50%' class="text-center"><strong>TOTAL</strong></td>
                              <td class="text-center"><strong>{{ formatNumber(Total.LapseTon) }}</strong></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="transversal-view">
                <TransversalView 
                    :vesselType="TpVessel"
                    :TpVesselId="itinerarySelected.TpVesselId"
                    :positionJson="Position"
                    :fullscreen="true"
                    legend
                />
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(page) }} {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransversalView from '@/pages/situacion-puerto/transversal-view-small';
import { DateFormater } from "@/_helpers/funciones";
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function DateFormat(Date) {
  if (Date != null && Date != '') {
    Date = DateFormater.formatDateTimeWithSlash(Date);
    return Date;
  } else {
    return Date;
  }
}
function formatNumber(number) {
  if (number == "") return "0,00";
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}
function TurnName(Turns) {
  let _lang = this.$i18n.locale;
  let Name = _lang=='en' ? Turns.TurnNameEn : Turns.TurnNameEs;
  return Name;
}

//Computeds:
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleCompany() {
 let CompanyName = this.user.CompanyName;
 let CompanyRif = this.user.CompanyRif;
 let BranchName = this.user.BranchJson[0].BranchName;

  return `${CompanyName} ${CompanyRif} - ${BranchName}`;
}
function titleVessel() {
 let VesselName = this.itinerarySelected.VesselName;
 let Imo = this.itinerarySelected.Imo;
 let CountryName = this.itinerarySelected.CountryName;
 let berth = this.$t('label.berth');
 let BerthName = this.itinerarySelected.BerthName;

  return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
}
function titleFromItinerary() {
  let previousDate;
  let laterDate;
  let fecha = "";
  let voyage = this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

  if(this.itinerarySelected.Ata!=undefined){ 
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = "ATA:"+fecha;
  }

  if(this.itinerarySelected.Ata==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = "ETA:"+fecha;
  }

  if(this.itinerarySelected.Atd!=undefined){  
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = "ATD:"+fecha;
  }

  if(this.itinerarySelected.Atd==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
    laterDate = "ETD:"+fecha;
  }

  return `${previousDate}, ${laterDate} - ${voyage}`;
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function totalPages() {
  return this.Condition;
}

export default {
  name: "sumary-final-report-view-pdf",
  props: {
    Condition: Number,
    Total: Object,
    TpVessel: String,
    Day: String,
    StartsDate: String,
    CraneAlias: String,
    Header: Object,
    Embarked: Object,
    Movement: Object,
    Turn: Array,
    Lapse: Array,
    Position: Array,
    itinerarySelected: Object,
  },
  mixins: [],
  components: {
    TransversalView,
  },
  data,
  created,
  methods: {
    DateFormat,
    TurnName,
    formatNumber,
  },
  computed: {
    currentDateTime,
    titleCompany,
    titleVessel,
    titleFromItinerary,
    hasLogo,
    hasVesselImg,
    loginName,
    totalPages,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}
.title-report{
    width: 100%;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 640px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  /* height: 75%; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  /* margin-top: 10px; */
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  /* font-size: 12px !important; */
  font-size: 10px !important;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-25 {
  width: 25% !important;
}
.limit-field-16{
  width: 16.6% !important;
}
.limit-field-30 {
  width: 32% !important;
}
.limit-field-40 {
  width: 40% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .transversal-view {
  /* border: 1px solid #000; */
  width: 100%;
  /* height: 100%; */
  /* margin-top: 10px; */
}

/* *************************************************** */
/* Tablas de Info */
/* *************************************************** */
.lapse-table{
  width: 100%;
  border-collapse: collapse;
}
table.lapse-table tr:first-child td {
  border-top: none;
}
table.lapse-table tr:last-child td {
  border-bottom: none;
}
table.lapse-table td:first-child {
  border-left: none !important;
}
table.lapse-table td:last-child {
  border-right: none !important;
}
.holds-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.title-table {
  width: 100%;
}
.text-size-10{
    font-size: 10px !important;
}
.Load-info {
  width: 78%;
}
.Load-div{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.load-table {
  width: 50%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.load-table,
table.load-table td,
table.load-table th {
  border: 1px solid #000;
}
table.load-table td,
table.load-table th {
  padding: 0 5px;
}
table.load-table td {
  text-align: right;
}
table.load-table tfoot {
  font-weight: bold;
}
table.load-table td:nth-child(1) {
  width: 55%;
}
.tables-info{
  width: 48%;
}

.movement-table{
  width: 100%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.movement-table,
table.movement-table td,
table.movement-table th {
  border: 1px solid #000;
}
table.movement-table td,
table.movement-table th {
  padding: 0 5px;
}
table.movement-table td {
  text-align: right;
}
table.movement-table tfoot {
  font-weight: bold;
}
table.movement-table td:nth-child(1) {
  width: 60%;
}
.turn-div{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.turn-table{
  margin-bottom: 0rem !important;
  color: #000 !important;
  width: 100%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.turn-table,
table.turn-table td,
table.turn-table th {
  border: 1px solid #000;
}
table.turn-table td,
table.turn-table th {
  padding: 0 5px;
}
table.turn-table td {
  vertical-align: top !important;
  text-align: right;
}
table.turn-table tfoot {
  font-weight: bold;
}
table.turn-table td:nth-of-type(odd) {
  width: 25%;
}
.turn-info{
  width: 100%;
  border-collapse: collapse;
}
table.turn-info,
table.turn-info td,
table.turn-info th {
  border: 0px solid #000;
}
.text-table-left {
  text-align: left !important;
}
.text-table-center {
  text-align: center !important;
}
.text-table-right {
  text-align: right !important;
}
.header-bg {
  background-color: #bfbfbf;
}
.table-color tbody tr:nth-of-type(odd) {
    background-color: #bfbfbf;
}

.departure-table {
  width: 20%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.departure-table,
table.departure-table td,
table.departure-table th {
  border: 1px solid #000;
}
table.departure-table td,
table.departure-table th {
  padding: 0 5px;
}
table.departure-table td:nth-child(2) {
  text-align: right;
}
table.departure-table td:nth-child(1),
table.departure-table td:nth-child(2) {
  width: 50%;
}

.DraftDepth-table {
  width: 20%;
  height: 1px;
  /* font-size: 12px; */
  font-size: 10px !important;
  border-collapse: collapse;
}
table.DraftDepth-table,
table.DraftDepth-table td,
table.DraftDepth-table th {
  border: 1px solid #000;
}
table.DraftDepth-table td,
table.DraftDepth-table th {
  padding: 0 5px;
}
table.DraftDepth-table td {
  text-align: right;
}
table.DraftDepth-table tfoot {
  font-weight: bold;
}

 .bl-table {
    width: 100% !important;
    font-size: 10px !important;
  }
  .hold-commodity td:nth-child(-n+4) {
    vertical-align: top;
    text-align: left;
  }
  .bl-info {
    min-height: 1px;
    height: 100%;
    font-size: 10px !important;
    border-collapse: collapse;
  }
  table.bl-info td {
    border: 1px solid #000;
    text-align: center !important;
    vertical-align: middle !important;
  }
  table.bl-info td:first-child {
    border-left: none !important;
  }
  table.bl-info td:last-child {
    border-right: none !important;
  }
  table.bl-info tr:first-child td {
    border-top: none;
  }
  table.bl-info tr:last-child td {
    border-bottom: none;
  }
  .bl-info .bl-color {
    width: 100%;
    min-height: 12px;
  }
  .bl-same-width {
    width: 12%;
  }
</style>