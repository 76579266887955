<template>
  <div class="col-sm-12 px-2" :style="`${(scrollX) ? 'overflow-x: auto;' : 'overflow-x: hidden;'} ${(scrolly) ? `overflow-y: auto;` : 'overflow-y: hidden;'} ${maxHeight ? `height: ${maxHeight};` : ''}`">
    <div class="col-sm-12 px-1" :style="`${maxHeight ? `min-height: ${maxHeight};` : ''}`">
      <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
    </div>
  </div>
</template>
<script>
function chartOptions(){
  return {
    chart: {
      type: 'bar',
      animations: {
        enabled: this.animations,
      }
    },
    colors: this.Colors.length!=0?this.Colors:undefined,
    xaxis: {
      categories: this.Categories,
      labels: {
        show: this.xaxis,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: this.distributed,
        horizontal: this.horizontal,
      }
    },
    dataLabels: this.dataLabels ?? {},
		legend: {
			show: false
		},
		tooltip: {
			enabled: false,
		},
    yaxis: {
      labels: {
        show: this.yaxis,
      },
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false,
			},
		},
    grid: {
      show: this.grid,
    }
  }    
}

export default {
  name:'container-type-bar',
  props: {
    Series: {
      type: Array,
      require: true,
      default: () => [],
    },
    Categories:{
      type: Array,
      require: true,
      default: () => [],
    },
    Colors:{
      type: Array,
      require: false,
      default: () => [],
    },
    Width:{
      type: String,
      default: 'auto',
    },
    Height:{
      type: String,
      default: 'auto',
    },
    maxHeight: {
      type: String,
    },
    animations: {
      type: Boolean,
      default: true,
    },
    plotOptionsDataLabels: {
      type: Object,
      default: () => {}  
    },
    distributed: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    xaxis: {
      type: Boolean,
      default: false,
    },
    yaxis: {
      type: Boolean,
      default: true,
    },
    grid: {
      type: Boolean,
      default: true,
    },
    dataLabels: Object,
    scrollX: Boolean,
    scrolly: Boolean,
  },
  methods:{
  },
  computed:{
    chartOptions
  },
}
</script>