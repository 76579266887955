<template> 
    <div class="mb-3">
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
        <CRow class="mt-2">                    
            <CCol sm="12" lg="5">
                <CSelect
                 :label="$t('label.classificationMachine')"
                 :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-6'}"
                 addLabelClasses="text-right"  
                 :value="ClassificationTypeSelect"
                 :options="ClassificationListFormatted"
                 @change="ClassificationList($event)"  
                />
            </CCol>
            <CCol sm="12" lg="7" class="d-flex justify-content-end">
                <div>
                    <CButton
                     color="add"
                     class="d-flex align-items-center" 
                     v-c-tooltip="{
                        content: $t('label.typeMachine'),
                        placement: 'top-end'
                     }"
                     @click="toggleAdd"
                     >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                    </CButton>
                </div>
            </CCol>                    
        </CRow>
        <CRow class="mb-3">
            <CCol sm="12" >
                <dataTableExtended
     class="align-center-row-datatable"
                    :items="formatedItems"
                    :fields="fields"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    sorter
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    column-filter
                    hover  
                >
                    <template #Nro="{ item }">
                        <td class="center-cell">
                            {{ item.Nro }}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="center-cell">
                            <CBadge :color="getBadge(item.Status)">
                            {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #actions="{item}">
                        <td class="center-cell">
                                <CButton
                                    color="edit"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.typeMachine'),
                                        placement: 'top-end'
                                    }"
                                    @click="toggleEdit(item)"    
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
        <ModalTpMachine  
            :modal.sync="modalTpMachine" 
            :title="titleModal"
            :TpMachineItems="TpMachineItems"
            :isEdit="isEdit"
            @Update-list="Update"
            @close="closeModal"
        />
    </div>  
</template>

<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import ModalTpMachine from './modal-tipo-maquina';
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';

 //data
function data() {
    return {
        items: [],
        TpMachineItems: {},
        activePage: 1,
        isLoading: false,
        modalTpMachine: false,
        titleModal:'',
        isEdit:false,
        ClassificationItems:[],
        ClassificationTypeSelect:''
    };
}

function getMachineType() {
    this.isLoading = true;
    this.$http.get('TpMachineList-by-ClassifMachineId', {TpClassificationMachineId: this.ClassificationTypeSelect})
        .then((response) => {
            this.items = response.data.data;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.error')+'!',
            text: err,
            type: "error"
            });
        }).then(() => {
             this.isLoading = false;
        });
}


function toggleAdd() {
    this.modalTpMachine = true;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.machineType');
}
function toggleEdit(item) {
    this.TpMachineItems = (item); 
    this.modalTpMachine = true;
    this.isEdit = true;
    
    let _lang = this.$i18n.locale;
    let title = _lang=='en' ? this.TpMachineItems.TpMachineNameEn : this.TpMachineItems.TpMachineNameEs;
    this.titleModal =  this.$t('label.edit')+' '+this.$t('label.machineType')+': '+title;
}
function Update() {
    this.modalTpMachine = false;
    this.isEdit = false;
    this.getMachineType();
}
function closeModal(){
  this.modalTpMachine= false;
  this.TpMachineItems = {};
  this.titleModal = '';
  this.isEdit= false;
}
function getClassificationlist() {
    this.Loading = true;
    this.$http.get('TpClassificationMachine-list')
        .then((response) => {
            let list = response.data.data;
            if (list.length != 0) {
                this.ClassificationItems = list;
                this.Loading = false;
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
            });
        }).then(() => {
            this.Loading = false;
        });
}
function ClassificationList(event) {
  this.ClassificationTypeSelect = event.target.value;
  if ( this.ClassificationTypeSelect!='') {
    this.getMachineType();
  }else{
    this.ClassificationTypeSelect = '';
    this.getMachineType();
  }
}
//Computed:
function formatedItems() {
    return this.items.map((item) => Object.assign({}, item, {
        Nro: item.Nro,
        FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
        _classes: item.Status != 'ACTIVO'? 'table-danger': ''
    }));
}

function fields() {
    return[
            { key: 'Nro',label: '#',filter: false,_style: 'width:1%; text-align:center;',},
            { key: 'TpMachineNameEs',label: this.$t('label.machineType')+' '+('(ES)'),_style:'width:25%', _classes:'text-uppercase text-center center-cell' },
            { key: 'TpMachineNameEn',label: this.$t('label.machineType')+' '+('(EN)'),_style:'width:25%', _classes:'text-uppercase text-center center-cell' },
            { key: 'TransaLogin', label: this.$t('label.user'),_style:'width:15%', _classes:'text-uppercase text-center center-cell' },
            { key: 'FormatedDate', label: this.$t('label.date'),_style:'width:15%', _classes:'text-uppercase text-center center-cell' },
            { key: 'Status', label: this.$t('label.status'), _style:'width:15%', _classes:'text-uppercase text-center center-cell' },
            { key: 'actions', label: '', sorter: false, filter: false, _style: 'min-width:45px;', _classes:'text-center center-cell'}
        ]
    }

function tableText() {
    return tableTextTranslatedHelpers(this);
}

function ClassificationListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.ClassificationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ClassificationItems.map(function(e){
      if (e.FgActClassificationMachine) {
        chart.push({
          value: e.TpClassificationMachineId, 
          label: _lang=='en' ? e.TpClassificationMachineNameEn : e.TpClassificationMachineNameEs,
        }) 
      }    
    })
    return chart;
  }
}
export default {
    name:'app-tipo-maquina',
    mixins: [GeneralMixin, ModalMixin], 
    components: {ModalTpMachine},
    props:{Tab: Number},
    data,   
    methods:{
        getMachineType,
        toggleAdd,
        toggleEdit,
        Update,
        closeModal,
        getClassificationlist,
        ClassificationList
    },
    computed: {
        fields,
        formatedItems,
        tableText,
        ClassificationListFormatted
    },
    watch: {
        Tab:function (val){
            if(val === 2){
                this.getMachineType();
                this.getClassificationlist();
            }
        }
    }
}
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>