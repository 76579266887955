<template>
  <div>
    <CRow class="justify-content-center m-0 mt-3">
      <CCol sm="12" class="my-2">
        <vue-dropzone 
          ref="myVueDropzone" 
          id="dropzone" 
          size="4"
          :options="dropzoneOptions" 
          :useCustomSlot="true"
          v-on:vdropzone-error="DropzoneError"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
            <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
          </div>
        </vue-dropzone>
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.documentType')"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :options="DocumentTypeOptions"
          v-model.trim="$v.Documentation.PortActivictyDocumentCargoId.$model"
          :is-valid="hasError($v.Documentation.PortActivictyDocumentCargoId)"
          :invalid-feedback="errorMessage($v.Documentation.PortActivictyDocumentCargoId)"
        >
        </CSelect>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          v-uppercase
          size="sm"
          :placeholder="$t('label.documentDescription')"
          addLabelClasses="required text-right"
          :label="$t('label.description')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}" 
          v-model.trim="$v.Documentation.StowagePlanningBlDocumentDs.$model"
          :is-valid="hasError($v.Documentation.StowagePlanningBlDocumentDs)"
          :invalid-feedback="errorMessage($v.Documentation.StowagePlanningBlDocumentDs)"
        />
      </CCol>
      <CCol sm="12" lg="3"  class="d-flex align-items-start justify-content-end">
        <CButton
          color="add"
          size="sm"
          v-c-tooltip="{
            content: $t('label.add'),
            placement: 'top-end'
          }"
          @click="SubmitDocument()"
        >
          <CIcon name="checkAlt"/>
        </CButton>
      </CCol>
    </CRow>

    <dataTableExtended
      class="align-center-row-datatable"
      :fields="fields"
      :items="formatedItems"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Status="{ item }">
        <td class="text-center">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
      <template #DocsRoute="{item}">
        <td class="py-2 text-center">
          <CButton
            class="btn btn-sm btn-watch mr-1"
            color="excel"
            v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
            target="_blank"
            @click="downloadArchive(item.DocsRoute)"
          >
            <CIcon name="cil-cloud-download" />
          </CButton>
          <CButton
            class="btn btn-sm btn-wipe"
            v-c-tooltip="{
            content: $t('label.delete')+$t('label.document'),
            placement: 'top-end',
            }"
            @click="DeleteDocument(item)"
          >
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>
    <div class="text-invalid-feedback text-center" v-if="$v.DocumentList.$error">
      {{ $t('validation.NotDocumentype') }}
    </div>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

function data() {
  return {
    Documentation: {
      PortActivictyDocumentCargoId:'',
      StowagePlanningBlDocumentDs:''
    },
    DocumentTypeList: [],
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    }
  };
}

  let user = JSON.parse(localStorage.getItem('user'));

  //methods
function getService() {
  this.$emit('Loading', true);
  let requests = [
    this.$http.ejecutar("GET", "PortActivityGpoCargoDocument-list", { PortActivictyId: this.BasicData.Activity }),
  ];
  Promise.all(requests)
    .then((responses) => {
      this.DocumentTypeList = responses[0]?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false);
    });
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function DeleteDocument(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        this.$emit('Loading', true);
        this.$http.delete('files', item.RouteStowagePlanningBlDocument.replace(/public+[/]/i, ''))
          .then(async(response) => {
            if (item.StowagePlanningBillOfLadingDocId) {
              await this.UpdateBl(item.StowagePlanningBillOfLadingDocId);
            }else{
              this.$emit('UpdateList', this.DocumentList.filter(e => e.IdX != item.IdX));
            }
            this.notifySuccess({text: response.data.data.info});
          })
          .catch((err) => {
            this.notifyError({text: err});
          })
          .finally(() => {
            this.$emit('Loading', false);
          });
      }
    });
}

function SubmitDocument(){
  try {
    this.$emit('Loading', true);
    this.isSubmit = true;
    this.$v.Documentation.$touch();
    let files = this.$refs.myVueDropzone.getQueuedFiles();

    if (files.length === 0) {
      throw this.$t('label.documentQuestion');
    }
    if (this.$v.Documentation.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    this.$http.file('GeneralCargo-file', files[0])
      .then(response => {
        this.$refs.myVueDropzone.removeAllFiles();
        this.DocumentList.push({
          IdX: this.DocumentList.length > 0 ? this.DocumentList[this.DocumentList.length - 1].IdX + 1 : 1,
          PortActivictyDocumentCargoId: this.Documentation.PortActivictyDocumentCargoId,
          StowagePlanningBlDocumentDs: this.Documentation.StowagePlanningBlDocumentDs,
          RouteStowagePlanningBlDocument: response.data.data.files[0].path,
          Status: 1,
        });
        this.Reset(false);
        this.$emit('Loading', false);
      }).catch((err) => {
        this.isSubmit = false;
        this.$emit('Loading', false);
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmit = false;
    this.$emit('Loading', false);
    this.notifyError({text: e});
  }
}

async function UpdateBl(StowagePlanningBillOfLadingDocId) {
  this.StowagePlanningBillOfLadingJson.DocumentJson = this.StowagePlanningBillOfLadingJson.DocumentJson.map(item => {
    return {
      ...item,
      Status: item.StowagePlanningBillOfLadingDocId != StowagePlanningBillOfLadingDocId ? 1 : 0,
    }
  })
  await this.$http.put('StowagePlanningBillOfLadingGC-update', this.StowagePlanningBillOfLadingJson, { root: 'StowagePlanningBillOfLadingJson' })
    .then(async response => {
      this.$emit('UpdateList', this.DocumentList.filter(e => e.IdX != item.IdX));
      await this.$emit('UpdateBlList');
    })
    .catch((err) => {
      this.notifyError({text: err});
    })
}

function downloadArchive(Route) {
  window.open(Route, '_blank');
}

function Reset(All) {
  this.Documentation = {
    PortActivictyDocumentCargoId:'',
    StowagePlanningBlDocumentDs:''
  };
  if (All) {
    this.DocumentTypeList = [];
  }
  this.$v.Documentation.$reset();
}

//computed
function DocumentTypeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.DocumentTypeList.map(function(e){
    chart.push({
      value: e.PortAtivictyDocumentCargoId, 
      label: e.StandardDocumentName,
    })    
  })
  return chart;
}

function fields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'StowagePlanningBlDocumentDs', label: this.$t('label.documents'),  _classes: 'text-uppercase text-center', _style: 'width:90%;', sorter: true, filter: true},
    { key: 'DocsRoute', label: '', _style: 'width:5%; min-width:90px; text-align:center;', sorter: false, filter: false },
  ];
}

function formatedItems() {
  let List = this.DocumentList.filter(item => item.Status != 0);
  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      DocsRoute: item.RouteStowagePlanningBlDocument ? `${this.$store.getters['connection/getBase']}${item.RouteStowagePlanningBlDocument.replace(/public+[/]/i, '')}`: "",
    };
  });
}

export default {
  name: 'documentation-tab',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data,
  props: {
    DocumentList: {
      type: Array,
      required: true,
      default: () => [],
    },
    BasicData: {
      type: Object,
      required: true,
      default: () => {},
    },
    StowagePlanningBillOfLadingJson: {
      type: Object,
      required: true,
      default: () => {},
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations(null, null, this.BasicData.Activity);
  },
  methods: {
    getService,
    DropzoneError,
    DeleteDocument,
    SubmitDocument,
    UpdateBl,
    downloadArchive,
    Reset,
  },
  computed:{
    DocumentTypeOptions,
    fields,
    formatedItems
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.Reset(true);
        this.$v.$reset();
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
    Validate: function (Newval){
      if (Newval) {
        this.$v.DocumentList.$touch();
      }
    }
  }
};
</script>