<template>
  <div class="mb-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateState"
          v-c-tooltip="{
            content: $t('label.cargoState'),
            placement: 'top-end',
          }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.nuevo')}}
          </span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedBulkCargo"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+' '+$t('label.cargoState'),
              placement: 'top-end',
            }"
            @click="toggleShowModalEditEstado(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>

    <modalEstado
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="mountedBulkCargo"
      :estadoCgaGranelSelected="estadoCgaGranelSelected"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import modalEstado from './modal-estado';
import GeneralMixin from '@/_mixins/general';

function data() {
  return {
    itemsEstados: [],
    Loading: false,
    showModal: false,
    titleModal: '',
    editModal: false,
    estadoCgaGranelSelected: {},
  };
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter:false,
      _style: 'width:3%; text-align:center',
    },
    {  key: 'TpCargoName',label: this.$t('label.cargoState'), _style:'min-width:30%', _classes:"text-center" },
    {  key: 'TpUnitMeasureName', label: this.$t('label.MeasurementUnit'), _style:'min-width:30%', _classes:"text-center"},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes:"text-center" },
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"text-center" },
    {  key: 'options',
      label: '', 
      _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false,
    },
  ];
}

function toggleShowModalEditEstado(item) {
  this.estadoCgaGranelSelected = item;
  this.showModal = true;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.bulkCargoState')+': '+this.estadoCgaGranelSelected.TpCargoName;
  this.editModal = true;
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedBulkCargo() {
  //this.loadingOverlay = true;
  this.Loading = true;

  this.$http
    .get('BulkCargo-list')
    .then((response) => (this.itemsEstados = response.data.data))
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.notifyError({text: e});
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedBulkCargo() {
  if (this.itemsEstados.length > 0) {
    return this.itemsEstados.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        TpUnitMeasureName: this.getUnitsMeasuresName(
          item.TpCargoUnitMeasureJson
        ),
      };
    });
  }
}

function getUnitsMeasuresName(TpCargoUnitMeasureJson) {
  let measures = '';
  if (TpCargoUnitMeasureJson !== null && TpCargoUnitMeasureJson.length > 0) {
    TpCargoUnitMeasureJson.map((item, index) => {
      if (item.Status === 'ACTIVO') {
        measures = item.TpUnitMeasureName;
      }
    });
  }

  return measures;
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateState() {
  this.showModal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.bulkCargoState');
}

//### ACtualiza el estado una vez cerrado el modal
function updateShowModalCreate(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.estadoCgaGranelSelected = {};
}

function handleTab(tab) {
    this.Tab = tab;
}

export default {
  name: 'estadoCgaGranel',
  data,
  props:{Tab: Number},
  components: {
    modalEstado,
  },
  mixins: [GeneralMixin],
  methods: {
    toggleShowModalCreateState,
    updateShowModalCreate,
    mountedBulkCargo,
    getUnitsMeasuresName,
    toggleShowModalEditEstado,
    handleTab
  },
  mounted: mountedBulkCargo,
  computed: {
    computedBulkCargo,
    fields,
  },
  watch:{
    Tab:function (val){
        if(val == 0){
          this.mountedBulkCargo();
        }
    }
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
