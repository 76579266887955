import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode'
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment'

function data() {
    return {
      dataBuque:{
        CountryName:'',
        DatePlanning:'',
        VesselFlagRoute:'',
      },
    }
  }

async function getPdf(rowData, titulo, colDelete, tableHeader) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';
    if (tableHeader) {
        for (let index = 0; index < tableHeader.length; index++) {
            columns.push({
                title: tableHeader[index].title,
                dataKey: tableHeader[index].dataKey
            });
        }
    }else{
        let columnsDefs = [];
        if (this.gridApi!==undefined) {
            columnsDefs = this.gridApi.getColumnDefs();
        }

        if(columnsDefs.length > 0){
            if(colDelete)
            columnsDefs.splice(0, colDelete);
        }

        for (let index = 0; index < columnsDefs.length; index++) {
            //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
            columns.push({
                title: columnsDefs[index].headerName,
                dataKey: columnsDefs[index].field
            });
        }
    }

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let largo = columns.length*70;
    let resta = 0;
    let tcentro = 550;
    let font = 8;
    let widthImage = 59;
    let positionX = 115;
    let sizeText = 9;
    let tableWidthBody = 'auto';

    if (columns.length > 9){
        widthImage = 120;
        positionX = 165;
        sizeText = 10;
        tableWidthBody = 970;
    }

    if (columns.length > 14) font=7;

    if (largo < 700) {
        largo = 700;
        resta = 350;
        tcentro = 350;
    //} else if ((largo > 600) && (largo < 850)) {
    //    largo = 850;
    } else {
        largo = 1050;
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [600, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(8);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }

    doc.setDrawColor("#fff");
    //doc.rect(40, 40, 65, 70);
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    doc.setDrawColor("#fff");
    doc.rect(positionX, 40, 905 - resta, 40);
    doc.setFontSize(sizeText);
    doc.text(this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName, tcentro, 65, 'center');

    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 970-resta, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 970-resta, 25, "FD");
    doc.setFontSize(sizeText);
    doc.text(titulo, tcentro, 125, 'center');

    /*if (data.Cont20FtDeck == "DECK") {

    }*/
    //let arreglo = rowData.shift();
    let inicio = 145;
    if (rowData[0].Cont20FtDeck == "DECK") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(150, 145, 320, 20, "FD");
        doc.text(rowData[0].Cont20FtDeck, 280, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(437, 145, 285, 20, "FD");
        doc.text(rowData[0].Cont20FtHold, 580, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(722, 145, 288, 20, "FD");
        doc.text(rowData[0].Cont20FtTotal, 880, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].Cont20Full == "20F") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(232, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Full, 295, 160, 'center');
        doc.setFillColor("#1a3760");
        doc.rect(363, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Empty, 425, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(495, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont40Full, 550, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(620, 145, 142, 20, "FD");
        doc.text(rowData[0].Cont40Empty, 680, 160, 'center');

        doc.setFillColor("#5d6164");
        doc.rect(750, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont45Full, 810, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(880, 145, 130, 20, "FD");
        doc.text(rowData[0].Cont45Empty, 938, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].CtaFunctionCode == "CONTACT INFORMATION") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(190, 145, 137, 20, "FD");
        doc.text(rowData[0].CtaFunctionCode, 260, 160, 'center');

        doc.setFillColor("#1a3760");
        doc.rect(327, 145, 90, 20, "FD");
        doc.text("CONTACT", 370, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(417, 145, 275, 20, "FD");
        doc.text(rowData[0].HanDescriptionCode, 530, 160, 'center');

        doc.setFillColor("#5d6164");
        doc.rect(692, 145, 190, 20, "FD");
        doc.text(rowData[0].TsrServicePriorityCode, 780, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].section == "totalLoad") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(151, 145, 255, 20, "FD");
        doc.text("DECK", 280, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(403, 145, 257, 20, "FD");
        doc.text("HOLD", 520, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].total == "DELAY_LIST") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(860, 145, 38, 20, "FD");
        doc.text("FULL", 875, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(897, 145, 38, 20, "FD");
        doc.text("EMPTY", 915, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].section == "transport_summary") {
        let _lang = this.$i18n.locale;
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        let posX =_lang=='en' ? 305 : 274;
        let ancho =_lang=='en' ? 325 : 344;

        doc.setFillColor("#1a3760");
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.orders'), posX+173, 160, 'center');

        doc.setFillColor("#ec631b");
        posX =_lang=='en' ? 620 : 618;
        ancho =_lang=='en' ? 210 : 228;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.times'), posX+105, 160, 'center');

        doc.setFillColor("#5d6164");
        posX =_lang=='en' ? 827 : 845;
        ancho =_lang=='en' ? 183 : 165;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.ton')+'S', posX+75, 160, 'center');

        rowData.shift();
        inicio = 165;
    }

    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        columnWidth: 70,
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: {
            cellPadding: 1,
            fontSize: font,
            halign: "center",
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        /*styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap',
           // font: 'arial',
            fontSize: font,
            cellPadding: 2,
            halign: "center",
            overflowColumns: 'linebreak'
        },*/
    });

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getPassPdf(Data, titulo, pdfType) {
	// pdfType == 1 es despacho y pdfType == 2 es recepcion
	let typeOrder = pdfType;
	
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

  const response1 = await this.$http.getFile('base64', {
    path:this.user.LogoRoute.replace('Public/','', null, 'i')
  });
  const divisiones1 = this.user.LogoRoute.split(".");
  const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let sizeText = 8;

  const doc = new jsPDF({
    orientation: 'p',
    unit: 'pt',
    format: [600, 842],
    putOnlyUsedFonts:true,
    floatPrecision: 16 // or "smart", default is 16
  });

  //***************** Footer Report *******************//

  const addFooters = (doc, HeaderJson, user) => {
    const pageCount = doc.internal.getNumberOfPages();
    let fecha  = DateFormater.formatTimeZoneToDateTime(new Date());
    let footerY = 827;
    
    doc.setFontSize(6);
    doc.setTextColor("#000");
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)

      doc.autoTable({
        head: [{ TransaUserName: this.$t('label.PreparedBy'), ReceiverName: this.$t('label.ReceivedBy') }],
        body: [{
          TransaUserName: `${HeaderJson?.TransaUserName ?? ''} CI: ${HeaderJson?.TransaUserCi ?? ''}`.trim(),
          ReceiverName: `${HeaderJson?.ReceiverName ?? ''} CI: ${HeaderJson?.ReceiverCardId ?? ''}`.trim(),
        }],
        startY: footerY-80,
        theme: 'grid',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: 15,
        tableWidth: 570,
        styles: {
          cellPadding: 5, 
          fontSize: 6,
        },
        columnStyles: {
          overflow: 'linebreak',
        },
        headStyles: {
          lineWidth: 0.5,
          fillColor: "#fff",
          textColor: "#000",
        },
      });

      doc.text(`${this.$t('label.pageReport')} ${i} ${this.$t('label.ofReport')} ${pageCount}`, doc.internal.pageSize.width - 15, footerY-15, {           
        align: 'right'
      })
      doc.text(`${this.$t('label.PrintedBy')}: ${user.UserName} ${user.UserLastName} ${user.UserCi}`, 15, footerY, {
        align: 'left'
      });
      doc.text(`${this.$t('label.printed')}: ${fecha}`, doc.internal.pageSize.width - 15, footerY, {
        align: 'right'
      });


    }
  }

  //***************** Header Report *******************//

  const addHeader = (doc, HeaderJson, user, myBase64Image, QR, titulo, sizeText) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(sizeText);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)

      //***************** Image *******************//
      doc.setDrawColor("#fff");
      doc.addImage(myBase64Image, "JPEG", 15, 12.5, 87, 60);

      //***************** Center *******************//
      doc.setDrawColor("#fff");
      doc.text(`${user?.CompanyName ?? ''} - ${user?.CompanyRif ?? ''}`, 300,  25, 'center');

      //***************** QR *******************//
      doc.addImage(QR, "JPEG", 280, 30, 40, 40);

      //***************** Right Table *******************//
      let head = [
        [
          {
            content: '',
            colSpan: 2,
          }
        ]
      ];
      let body = [
        [
          { content: this.$t('label.branch'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: user?.BranchJson?.[0]?.BranchName ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.code'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.DocumentCode ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.yard'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.YardAlias ?? '', colSpan: 1 },
        ],
        [
          { content: this.$t('label.date'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.TransactionDate ? DateFormater.formatDateTimeWithSlash(HeaderJson.TransactionDate) : '', colSpan: 1 },
        ],
				[
          { content: this.$t('label.Acta'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
          { content: HeaderJson?.DocumentCertificateBl ?? 'N/A', colSpan: 1 },
        ],
      ]
      doc.autoTable({
        head: head,
        body: body,
        showHead: false,
        startY: 15,
        theme: 'plain',
        columnWidth: 70,
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: {top: 15, right: 15, bottom: 100, left: 425},
        tableWidth: 180,
        styles: {
          cellPadding: 2,
          fontSize: 6,
        },
        columnStyles: {
          overflow: 'linebreak',
        },
      });

      //***************** title *******************//
      doc.setFillColor("#cccccc");
      doc.rect(15, 85, 570, 3, 'F');

      doc.setFillColor("#cccccc");
      doc.rect(15, 90, 570, 25, "FD");
      doc.text(titulo, 300, 105, 'center');
    }
  }

	//***************** addWatermark *******************//

	const addWatermark = (doc) => {
		const pageCount = doc.internal.getNumberOfPages();

		for (let i = 1; i <= pageCount; i++){
			doc.setPage(i)
			doc.saveGraphicsState();
			doc.setGState(new doc.GState({opacity: 0.2}));
			doc.setFontSize(80);
			doc.text(this.$t('label.canceledDocument'), 300, 421, {align: 'center', baseline: 'middle', angle: 25});
			doc.restoreGraphicsState();

		}
	}

  //***************** Header Body *******************//

  let HeaderJson = Data?.HeaderJson?.[0] ?? {};
	let GeneralLogistic = HeaderJson?.GeneralLogisticJson?.[0] ?? {};

  let HeaderTableReport = [
    [
      {
        content: '',
        colSpan: 6,
      }
    ]
  ]

  let BodyTableReport = [
    [
      { content: this.$t('label.client'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.ClientName ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.type'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.[`CustomOperationDs${_lang}`] ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.transaction'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: `${GeneralLogistic?.[`TpTransacEirName${_lang}`] ?? ''} ${GeneralLogistic?.TpTransacEirAcronym ? `(${GeneralLogistic?.TpTransacEirAcronym ?? ''})` : ''}`, colSpan: 1, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.vessel'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.VesselName ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.voyage'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Voyage ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: 'ATA / ETA ', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Arrival ? DateFormater.formatDateTimeWithSlash(HeaderJson.Arrival) : '', colSpan: 1, styles: { valign: 'middle', halign: 'left' } },
    ],
    [
      { content: this.$t('label.blCargoMaster'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER ? (HeaderJson?.BlNro ?? '') : (HeaderJson?.MasterBlNro ?? ''), colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.BlHouse'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE ? (HeaderJson?.BlNro ?? '') : '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.classification'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.[`TpCargoName${_lang}`] ?? '', colSpan: 1, styles: { halign: 'left' } },
    ],
    [
      { content: this.$t('label.customBroker'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.CustomBrokerName ?? '', colSpan: 2, styles: { halign: 'left' } },
      { content: this.$t('label.transport'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: GeneralLogistic?.CarrierName ?? '', colSpan: 2, styles: { halign: 'left' } },
    ],
  ]

  if (typeOrder == 1) {
    BodyTableReport.push([
      { content: this.$t('label.driver'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: `${GeneralLogistic?.DriverName ?? ''} ${GeneralLogistic?.DriverCi ? `CI: ${GeneralLogistic?.DriverCi}` : ''}`, colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.vehiclePlate'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: GeneralLogistic?.LicensePlate ?? '', colSpan: 1, styles: { halign: 'left' } },
      { content: this.$t('label.destination'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: HeaderJson?.Destiny ?? '', colSpan: 1, styles: { halign: 'left' } },
    ])
  }else{
    BodyTableReport.push([
      { content: this.$t('label.driver'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: `${GeneralLogistic?.DriverName ?? ''} ${GeneralLogistic?.DriverCi ? `CI: ${GeneralLogistic?.DriverCi}` : ''}`, colSpan: 2, styles: { halign: 'left' } },
      { content: this.$t('label.vehiclePlate'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
      { content: GeneralLogistic?.LicensePlate ?? '', colSpan: 2, styles: { halign: 'left' } },
    ])
  }

  BodyTableReport.push([
    { content: this.$t('label.quantity'), colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
    { content: NumberFormater.formatNumber(typeOrder == 1 ? HeaderJson?.QuantityDispatch : HeaderJson?.QuantityReceived, 2), colSpan: 1, styles: { halign: 'left' } },
    { content: `${this.$t('label.weight')} (KGM)`, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
    { content: NumberFormater.formatNumber(typeOrder == 1 ? HeaderJson?.WeigthDispatch : HeaderJson?.WeigthReception, 2), colSpan: 1, styles: { halign: 'left' } },
    { content: `${this.$t('label.volume')} (M³)`, colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', textColor: "#000" } },
    { content: NumberFormater.formatNumber(typeOrder == 1 ? HeaderJson?.VolumenDispatch : HeaderJson?.VolumenReception, 2), colSpan: 1, styles: { halign: 'left' } },
  ])

  doc.autoTable({
    head: HeaderTableReport,
    body: BodyTableReport,
    showHead: false,
    startY: 125,
    theme: 'grid',
    columnWidth: 70,
    tableLineColor: 200,
    tableLineWidth: 0,
    margin: 15,
    tableWidth: 570,
    styles: {
      cellPadding: 5,
      fontSize: 8,
    },
    columnStyles: {
      overflow: 'linebreak',
    },
  });

  //***************** Body *******************//

  const HeaderTablePackaging = (type, item) => {
    let HeaderTablePackaging = [
      [
        { content: this.$t('label.packaging'), colSpan: 5, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE" } },
        { content: this.$t('label.quantity'), colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
        { content: `${this.$t('label.volume')} (M³)`, colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
        { content: `${this.$t('label.weight')} (KGM)`, colSpan: 5, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE" } },
      ],
      [
        { content: item?.[`PackagingName${_lang}`] ?? '', colSpan: 5, styles: { valign: 'middle', halign: 'center', lineWidth: 0.5 } },
        { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
        { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
        { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 5, styles: { valign: 'middle', halign: 'right', lineWidth: 0.5 } },
      ],
    ]

    if (type == 1) {
      HeaderTablePackaging.push([
        { content: '#', colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
        { content: typeOrder == 1 ? this.$t('label.dispatchDate') : this.$t('label.receptionDate'), colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 50, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.serial'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.type'), colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.Seals'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.newShippingLine'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 50, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.imdg'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
      ])
    }else if (type == 2) {
      HeaderTablePackaging.push([
        { content: '#', colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
        { content: typeOrder == 1 ? this.$t('label.dispatchDate') : this.$t('label.receptionDate'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.serial'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 60, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.Battery'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.Extinguisher'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.KeySets'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.imdg'), colSpan: 1, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 10, lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", minCellWidth: 20, lineWidth: { top: 0.5 } } }, 
      ])
    }else {
      HeaderTablePackaging.push([
        { content: '#', colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 30, lineWidth: { top: 0.5 } } },
        { content: typeOrder == 1 ? this.$t('label.dispatchDate') : this.$t('label.receptionDate'), colSpan: 3, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 70, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.serial'), colSpan: 3, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.commodity'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.Seals'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: this.$t('label.imdg'), colSpan: 2, styles: { valign: 'middle', halign: 'center', fillColor: "#EEE", cellWidth: 40, lineWidth: { top: 0.5 } } },
        { content: this.$t('label.quantity'), colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", cellWidth: 5, lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.volume')} (M³)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
        { content: `${this.$t('label.weight')} (KGM)`, colSpan: 2, styles: { valign: 'middle', halign: 'right', fillColor: "#EEE", lineWidth: { top: 0.5 } } },
      ])
    }

    return HeaderTablePackaging;
  };

  const BodyTablePackaging = (type, item, packaging) => {
    if (type === 1) {
      return [
        { content: item?.Nro ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: typeOrder == 1 ? (item?.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : '') : (item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : ''), colSpan: 1, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.TpCargoDetailCode ?? '', colSpan: 1, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.Seals ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.ShippingLineCode ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.DescriptionOfGoods ? removeTags(item?.DescriptionOfGoods) : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.ImdgCod ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
      ]
    }else if (type === 2) {
      return [
        { content: item?.Nro ?? '', colSpan: 1, styles: { valign: 'middle', halign: 'center' } },
        { content: typeOrder == 1 ? (item?.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : '') : (item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : ''), colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: packaging?.[`CommodityName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center'} },
        { content: item?.FgBattery ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.FgExtinguisher ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.FgKey ? 'x' : '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.ImdgCod ?? '', colSpan: 1, styles: { valign: 'middle', halign: 'center'} },
        { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
      ]
    }else {
      return [
        { content: item?.Nro ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center'  } },
        { content: typeOrder == 1 ? (item?.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : '') : (item?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : ''), colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.Serial ? item?.Serial : 'N/A', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
        { content: packaging?.[`CommodityName${_lang}`] ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.Seals ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: item?.ImdgCod ?? '', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: NumberFormater.formatNumber(item?.Quantity, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Volumen, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
        { content: NumberFormater.formatNumber(item?.Weigth, 2), colSpan: 2, styles: { valign: 'middle', halign: 'right' } },
      ]
    }
  }

  let PackagingJson = Data?.PackagingJson ?? [];

  PackagingJson.forEach((e) => {
    let fgLimit = false;
    let type = e.PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER ? 1 : 
      e.PackagingId == process.env.VUE_APP_PACKAGING_ID_VEHICLE ? 2 : 3
    let SerialJson = e?.SerialJson ?? [];
    let PackagingDetailJson = e?.PackagingDetailJson ?? [];
    let Detail = [...PackagingDetailJson, ...SerialJson];
    let finalY = doc.lastAutoTable.finalY;
    let header = HeaderTablePackaging(type, e);
    let body = [];

    Detail.forEach((el, index) => {
      el.Nro = index+1;
      body.push(BodyTablePackaging(type, el, e));
    })

    if ((finalY+15) >= 700) {
      fgLimit = true;
      doc.addPage([600, 842], 'p')
    }

    doc.autoTable({
      head: header,
      body: body,
      startY: !fgLimit ? finalY+15 : 125,
      theme: 'grid',
      tableLineColor: 200,
      tableLineWidth: 0,
      margin: {top: 135, right: 15, bottom: 100, left: 15},
      tableWidth: 570,
      styles: {
        cellPadding: 1, 
        fontSize: 7,
      },
      columnStyles: {
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: "#fff",
        textColor: "#000",
      },
    });
  })

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/","");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

  let QR = '';
  QRCode.toDataURL(HeaderJson?.DocumentQrCode ?? '', { type: 'image/jpeg' }, function (err, url) {
    if (err) throw false

    QR = url
  });

  addHeader(doc, HeaderJson, this.user, myBase64Image, QR, titulo, sizeText);
  addFooters(doc, HeaderJson, this.user);
	if (!HeaderJson?.FgActDocumentYard) {
		addWatermark(doc);
	}
	
  doc.save(`${fileName}${today}${time}.pdf`);//
  this.$notify({
    group: 'container',
    text: this.$t('label.reportSuccessfully'),
    type: "success"
  });
}

async function getReporExcel(rowData, titulo, excel, val, YardName, colDelete, tableHeader, header = '') {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;


                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = header!=''? header : YardName!='' ? YardName : this.yardData?.YardName;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }

        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=2) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            //if (cell.value==)
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
        })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getReporExcelCanceled(rowData, titulo, excel, val, YardName, colDelete, tableHeader, header = '') {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        let titles = this.gridApi.getColumnDefs();
        
        titles.map((item)=>{
            if(item.children){
                let  children =  item.children
                children.map((j)=>{
                    j.children.map((k)=>{
                        columnsDefs.push(k)
                    })
                })
            }else
                columnsDefs.push(item)
        })

        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;


                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = header!=''? header : YardName!='' ? YardName : this.yardData?.YardName;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }

        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell8.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //recepción a buque
        worksheet.getRow(6).height = 30
        worksheet.mergeCells("J6:" + "S6");
        const customCell9 = worksheet.getCell("J6");
        customCell9.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell9.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell9.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell9.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '42aa90'
            }
        }
        customCell9.value = `${this.$t('label.receptionToShip')}`

        //recepción en buque
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("J7:" + "O7");
        const customCell10 = worksheet.getCell("J7");
        customCell10.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell10.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell10.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell10.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '1f3864'
            }
        }
        customCell10.value = `${this.$t('label.reception')}`

        //despacho en buque
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("P7:" + "S7");
        const customCell11 = worksheet.getCell("P7");
        customCell11.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell11.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell11.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell11.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ed7d31'
            }
        }
        customCell11.value = `${this.$t('label.dispatch')}`

        //retorno
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("T7:" + "W7");
        const customCell12 = worksheet.getCell("T7");
        customCell12.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell12.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell12.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell12.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '1f3864'
            }
        }
        customCell12.value =`${this.$t('label.returned')}`
        
        //despacho
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("X7:" + "AF7");
        const customCell13 = worksheet.getCell("X7");
        customCell13.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell13.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell13.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell13.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ed7d31'
            }
        }
        customCell13.value = `${this.$t('label.dispatch')}`
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=2) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            //if (cell.value==)
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
        })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getExcel(rowData, titulo, excel, title, companyName, membrete=true, nombreLibro ) {
    const workbook = new Excel.Workbook();
    let nombreOpcional = nombreLibro ? nombreLibro : titulo;
    const worksheet = workbook.addWorksheet(nombreOpcional);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let columnsDefs2 = [];
    let keyColumns = [];
    let bandera = true;

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });

        if (columnsDefs[0].field =='messagesContent') {
            for (let i = 0; i < columnsDefs.length; i++) {
                if ((columnsDefs[i].field != 'messagesContent') && (columnsDefs[i].field != 'especial')) {
                    columnsDefs2.push(columnsDefs[i]);
                }
            }
            columnsDefs = columnsDefs2;
            keyColumns.splice(0, 2);
            y=y-3;
        }
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    //if (isUndefined(un)) un = 'TON';
    if(membrete){
        if (excel) {

            if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(companyName){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "2")
            }else if(companyName === undefined){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
            }

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = companyName ? 60 : 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            if(companyName){
            }else if(companyName === undefined){
                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                let titleVessel = `${this.dataContainer?.VesselName}, ${this.$t('label.voyage')}: ${this.dataContainer?.VoyageArrival} ${(this.dataContainer?.Ata && this.dataContainer?.Ata != null) ? ', ATA :' : ''} ${(this.dataContainer?.Ata && this.dataContainer?.Ata != null) ? DateFormater.formatDateTimeWithSlash(this.dataContainer?.Ata) : ''} ${(this.dataContainer?.Atd && this.dataContainer?.Atd != null) ? '- ATD:' : ''} ${(this.dataContainer?.Atd && this.dataContainer?.Atd != null) ? DateFormater.formatDateTimeWithSlash(this.dataContainer?.Atd) : ''}`

                customCell.value = titleVessel;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = title;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //COLUMNA 2
            let division = Math.floor(y / 3);
            let rango1 = numeroLetra(division);
            let rango11 = numeroLetra(division+1);
            let rango2 = numeroLetra(division*2);
            let rango21 = numeroLetra((division * 2) +1);
            let rango3 = numeroLetra(y);

            const hoy = new Date();

            if(companyName){
                worksheet.mergeCells("A4:" + rango1 +"4");
                const customCellCompany = worksheet.getCell("A4");
                worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                customCellCompany.height = 50;
                customCellCompany.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };

                customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCellCompany.value = this.$t('label.company')+": "+companyName;
                customCellCompany.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                    left: { style: 'thin' },
                }
            }else if(companyName === undefined){
                worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }

            const customCell6 = companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }

            //linea
            worksheet.mergeCells("A5:" + rango3 +"5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }
    }


    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    headerRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
   // headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;

            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function excel( excelHeader = [],  dat = [], principalTitle = '', branchName = '', fileTitle = '', filters = [], booksNames = [], isDistribuyed = true,  PersonalfilterCell = false,  GroupTitle = false,MoreOpcions = {}) {

  let Opcions = {
    backgroundColorInTitle: '',
    colorLetterInTitle: '000000',
    ...MoreOpcions
  } 

  //Excel creation
  const workbook = new Excel.Workbook();
  const auth = this.user;

  //Validation of data 
  if(!dat?.[0] || !dat?.[0] == undefined || dat?.[0]?.length == 0){
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
    return false
  }

  //Cell number
  const CellNumber  = (column, field) => {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];
    return `${abc[column - 1]}${field}`;
  }

  //Identification of columns by numbers
  const cellMerge = (column, field, column2, field2) => {
    return `${CellNumber(column, field)}:${CellNumber(column2, field2)}`;
  }

  //Add image at excel
  const image = async(worksheet, workbook, url, col = 0, row = 0, width = 210,  height = 110)  => {
    const response = await this.$http.getFile('base64', {
      path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    if(response){
      const divisiones1 = this.user.LogoRoute.split(".");
      const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response.data.data.base64;
      const tl = { col: col, row: row };
      const ext = { width: width, height: height };

      const workbookImgId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
      });

      worksheet.addImage(workbookImgId, { tl, ext });
    }
  }

  //Row styles
  const rowStyles = (worksheet, rowIndex, autoFit = false, minWidth = 10, maxWidth = 26, color = '', textColor = '', size= 12, border = false) => {
    const rowCells = worksheet.getRow(rowIndex);
    
    if(rowCells){
      rowCells.eachCell((cell, cellNum) => {

        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
          wrapText: true
        };
    
        if(color != ''){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: color
              }
          }
        }
    
        if(textColor != ''){
          cell.font = { 
            name: "Calibri",
            family: 4,
            size: size,
            underline: false,
            bold: true,
            color: { argb: textColor },
        };
        }

        if(border){
          cell.border = {
            top: { style: 'thin', color: '000000' },
            left: { style: 'thin', color: '000000' },
            bottom: { style: 'thin', color: '000000' },
            right: { style: 'thin', color: '000000' },
          };
        }

        cell.wrapText = true;
      });
    }
  }

  //Cell styles
  const cellStyles = (worksheet, columnIndex, rowIndex, autoFit = false, minWidth = 10, maxWidth = 26, color = '', textColor = '', size= 12, border = false, horizontalAling = false, isBold = true) => {
    const cell = worksheet.getCell(CellNumber(columnIndex, rowIndex));
    const column = worksheet.getColumn(columnIndex);

    // Obtiene el valor de la celda y su longitud
    const cellValue = cell.value;
    const maxLength = cellValue ? cellValue.length : 0;

    if(cell.value){

      cell.alignment = {
        horizontal: horizontalAling ? horizontalAling : 'center',
        vertical: 'middle',
        wrapText: true
      };

      if(color != ''){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: color
            }
        }
      }

      if(textColor != ''){
        cell.font = { 
          name: "Calibri",
          family: 4,
          size: size,
          underline: false,
          bold: isBold,
          color: { argb: textColor },
        };
      }

      if(border){
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
      }
      }

      cell.wrapText = true;

      if(autoFit){

        // Ajusta el ancho de la columna al tamaño del texto, con el ancho mínimo especificado
        const newWidth = Math.max(maxLength, minWidth);

        // Set the new column width
        column.width = newWidth < maxWidth? maxWidth : newWidth;  

      }

    }

  }

  //Dynamic filters
  const filterCell = (worksheet, fieldLast, numberColumns, filters) => {

    let data = []
    let merges = []

    let divition = Math.round(numberColumns / filters.length) 
    let afterDivition = 1
    let numberFilter = 0

    for(let i=1; i<=numberColumns; i++){

      if((i == afterDivition) && (numberFilter < filters.length)){
          data.push(filters[numberFilter])
          numberFilter++
      }
      else
        data.push("")

      if(merges.length < filters.length){
        if(i == divition ){
          let finalColumn = Math.floor(numberColumns / filters.length)  + divition
          merges.push(cellMerge( afterDivition, fieldLast, finalColumn > numberColumns? numberColumns : i, fieldLast+1))
          afterDivition = divition  + 1 
          divition += Math.floor(numberColumns / filters.length) 
        }
        else{
          if(i == numberColumns){
            merges.push(cellMerge( afterDivition, fieldLast,  numberColumns, fieldLast+1))
            afterDivition = divition  + 1 
            divition += Math.floor(numberColumns / filters.length)
          }
        }
      }
    }

    worksheet.addRow(data);
    worksheet.addRow([]);

    data.map((e, index)=>{
      cellStyles(worksheet, index + 1, fieldLast, true, 10, 26, '', '000000', 12, true);
    })

    merges.map((e)=>{
      worksheet.mergeCells(e);
    })

    worksheet.mergeCells(cellMerge(1, fieldLast+2, (numberColumns <= 4 ? 4 : numberColumns), fieldLast+2));
  }

  const dataFilter = (header, values) => {
    let title = []
    let data = []

    header.map((element)=>{
      title.push(element.text)
    })

    values?.map((e)=>{
      //general data
      let aux = []

      //data for jsons
      let rowMerge = []
      let indexMerge = []
      let json2 = header?.find(el => el.json && el.json2)?.json2;

      header.map((element, index)=>{
        let columnMerge = []

        //push elements aux and value 
        if(element.aux && element.json && element.aux != '' && element.json == ''){
          aux.push(`${e[element.value]??''} ${e[element.aux]??''}`)
        }
        else
          //push elements for jsons
          if(element?.json != ""){ 
            e[element?.json]?.map((el)=>{
              let date = ''
              let dateHour = ''
              if (!element?.json2) {
                if(element.isDate){
                  date = el[element.value] ? moment(el[element.value], 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/A'
                  dateHour = el[element.value] ? moment(el[element.value], 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : 'N/A'
                }
                //the merge depends on the 3rd level json
                if (el?.[json2]?.length == 0) {
                  columnMerge.push({index: index, merge: undefined, value:  element.isDate ? (element.isDate == 1 ? date : dateHour ) : el[element.value] ??'N/A' , aux: element.aux != ''? el[element.aux]??'' : ''})
                } else {
                  el?.[json2].map((ele, index2) => {
                    columnMerge.push({index: index, merge: index2 == 0 ? el?.[json2]?.length : undefined, value:  element.isDate ? (element.isDate == 1 ? date : dateHour ) : el[element.value] ??'N/A' , aux: element.aux != ''? el[element.aux]??'' : ''})
                  })
                }
              
              }else {
                //push elements for json2
                el?.[element?.json2]?.map(ele => {
                  if(element.isDate){
                    date = ele[element.value] ? moment(ele[element.value], 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/A'
                    dateHour = ele[element.value] ? moment(ele[element.value], 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : 'N/A'
                  }
                  columnMerge.push({index: index, value: element.isDate ? (element.isDate == 1 ? date : dateHour ) : ele[element.value] ??'N/A' , aux: element.aux != ''? ele[element.aux]??'' : ''})
                })
              }
            })
            
            aux.push('')
            rowMerge.push(columnMerge)
            indexMerge.push(index)
            columnMerge = []
          }
          //push elements value
          else if(element.isDate){
            let date = ''
            let dateHour = ''
            date = e[element.value] ? moment(e[element.value], 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/A'
            dateHour = e[element.value] ? moment(e[element.value], 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : 'N/A'
            aux.push(element.isDate ? (element.isDate == 1 ? date : dateHour ) : e[element.value] ??'N/A')
          }else{
            aux.push(isNaN(e[element.value]) ? removeTags(e[element.value]??'') : e[element.value] ?? '')
          } 
      })
      //structure developed for jsons
      if(rowMerge.length>0 && rowMerge[0].length > 0){
        let result = []
        let auxMerge = []

        //cant columns
        let constColumns = 0;
        rowMerge.map((rows)=>{
          if (rows.length > constColumns) {
            constColumns = rows.length;
          }
        })

        for (let indexColumns = 0; indexColumns < constColumns; indexColumns++) {
          auxMerge.push(aux)
        }
        
        //insert data at rows for merge
        auxMerge.map((col, ind)=>{
          let dat = []

          col.map((el, index)=>{
            let merge = false
            rowMerge.map((e, inde)=>{
              if(rowMerge?.[inde]?.[ind]?.index == index){
                merge = true

                if(rowMerge[inde][ind].aux != '' && rowMerge[inde][ind].aux != 'N/A')
                  dat.push({
                    data: `${rowMerge[inde][ind].value} ${(rowMerge[inde][ind].aux != '' && rowMerge[inde][ind].aux != 'N/A') ? '-' : ''} ${rowMerge[inde][ind].aux}`,
                    merge: rowMerge[inde][ind]?.merge,
                  })
                else{
                  dat.push({
                    data: rowMerge[inde][ind].value,
                    merge: rowMerge[inde][ind]?.merge,
                  })
                }
              }
            })

            !merge && dat.push({data: el})
          })

          result.push(dat)
        })
        //Add result rows
        result.map((e)=>{
          data.push({
            data: e.map(el => el.data), 
            rowMerge: true, 
            totalRows: constColumns, 
            indexMerge: indexMerge.map(el => {
              return {
                index: el,
                mergeLv2: e?.[el]?.merge,
              }
            })
          })
        })
        rowMerge = []
        indexMerge= []
      }

      //structure developed for normal data
      else{
        data.push({data: aux, rowMerge: false})
        rowMerge = []
      }

    })

    return {title: title, data: data}
  }

  //Main header
  const header = (worksheet, workbook, amount, principalTitle, branch, titleReport) => {
    //Configuration header
    let principalData =  ['',`${principalTitle}`]
    //let branchData =  ['',`${'Sucursal'}: ${branch}`]
    let titleData =  [`${titleReport}`]
    let calMax = ((amount <= 4) ?  4 : amount)
    let fieldLast = 1

    //Merge for header
    let merges = [
      cellMerge(1, 1, 1, 4),
      cellMerge(2, 1, calMax, 4), 
      //cellMerge(2, 3, calMax,4), 
      cellMerge(1, 5, calMax, 6) 
    ];

    worksheet.addRow(principalData).height = 27;
    cellStyles(worksheet, 2, fieldLast, true, 10, 26, 'f2f2f2', '000000', 12, true);
    worksheet.addRow([]);
    fieldLast +=2;

    worksheet.addRow([]);
    //cellStyles(worksheet, 2, fieldLast, true, 10, 26, 'f2f2f2', '000000', 12, true);
    worksheet.addRow([]);
    fieldLast +=2;

    worksheet.addRow(titleData);
    cellStyles(worksheet, 1, fieldLast, true, 10, 26, Opcions.backgroundColorInTitle ? Opcions.backgroundColorInTitle.replace('#', '') : '', Opcions.colorLetterInTitle ? Opcions.colorLetterInTitle.replace('#', '') : '000000', 12, true);
    worksheet.addRow([]);
    fieldLast +=2;

    //Merges in excel
    merges.map((e)=>{
      worksheet.mergeCells(e);
    })

    return { data: principalData, last: fieldLast}
  }

  //Add configuration
  workbook.creator = 'Softico';
  workbook.lastModifiedBy = 'Softico';
  workbook.created = new Date(2024, 3, 6);
  workbook.modified = new Date();
  workbook.lastPrinted = new Date(2024, 3, 6);

  for(let i=0; i<excelHeader.length; i++){
    //Add values at excel

    let values = await dataFilter(excelHeader[i], dat[i])
    let data = values.data
    let title = values.title


    //Configuration page 1
    const worksheet = workbook.addWorksheet(booksNames.length>0 ? booksNames[i] : `${this.$t('list')} ${i+1}`);

    //Header of excel
    let headerData = await header(worksheet, workbook, title.length, `${this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName}`, '', Array.isArray(fileTitle) ? fileTitle?.[i] : fileTitle) 

    //header configuration
    let fieldLast = headerData.last
    
    //Add filter at excel
    if(PersonalfilterCell){
        let cantField = PersonalfilterCell(worksheet, cellMerge, cellStyles, CellNumber)
        fieldLast+=cantField;
    }
    else{
        filterCell(worksheet, fieldLast, title.length,  filters?.[i]?.length>0? filters[i] : []);
        fieldLast+=3;
    }

    //Add title in table 
    if(GroupTitle){
      let field = GroupTitle(worksheet, fieldLast, cellMerge, cellStyles)

      fieldLast+=field;

        worksheet.addRow(title).height = 30;
    }
    else{
      worksheet.addRow(title).height = 30;
    }

    title.map((e, index)=>{
      cellStyles(worksheet, index + 1, fieldLast, true, 10, 26, excelHeader[i]?.[index]?.backgroundColor ?? "cccccc", excelHeader[i]?.[index]?.color ?? "000000", 11, true);
    })
    fieldLast++;
    

    //Add data table 
    let previous = ''
    let merges = []
    let rowGray = false
    let totalDataIndex = 0
    data.map((e, dataIndex)=>{
      let statusMerge = 0
      worksheet.addRow(e.data)

      title.map((el, i)=>{
        cellStyles(worksheet, i + 1, fieldLast);
      })
      
      if(previous != e.data[0] && e.rowMerge){
          e?.data?.map((el, i)=>{
            if(e?.indexMerge?.[statusMerge]?.index == i) {
              if (e.indexMerge?.[statusMerge]?.mergeLv2) {
                merges.push(cellMerge( i + 1, fieldLast, i + 1, fieldLast +  (e.indexMerge[statusMerge].mergeLv2 - 1)))
              }
              e.indexMerge.length > statusMerge && statusMerge++
            } 
            else
              merges.push(cellMerge( i + 1, fieldLast, i + 1, fieldLast +  (e.totalRows - 1)))
            
          })
          previous = e.data[0]
      }else{
        e?.data?.map((el, i)=>{
          if(e?.indexMerge?.[statusMerge]?.index == i) {
            if (e.indexMerge?.[statusMerge]?.mergeLv2) {
              merges.push(cellMerge( i + 1, fieldLast, i + 1, fieldLast +  (e.indexMerge[statusMerge].mergeLv2 - 1)))
            }
            e.indexMerge.length > statusMerge && statusMerge++
          }
        })
      }

      if(dataIndex > 0 && data?.[dataIndex-1].data[0] != e.data[0]){
        rowGray = !rowGray
      }

      //validation for conditions and row marking
      excelHeader[i].map(async(c, itemsIndex)=>{
        // "c" is the helper, "e.data" is the values, "i" is the number iteration
        if(!isNaN(e?.data[itemsIndex]) && (typeof e?.data[itemsIndex] == 'number')){
          let cell = worksheet.getCell(CellNumber(itemsIndex + 1, fieldLast));

          if(!c.isNotFormater && c.isNotFormater == undefined){
            cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
          }
        }
          
        if(c.textFieldColor){
          let color = dat[i]?.[totalDataIndex]?.[c.textFieldColor]
          cellStyles(worksheet, itemsIndex + 1, fieldLast, true, 10, 26, '', color ? color.replace("#", '') : '', 12, true);
        }
      
        if(c.condition && c.condition(c,  e.data, itemsIndex)?.value)
          rowStyles(worksheet, fieldLast, false, 10, 26,  c.condition(c,  e.data, itemsIndex)?.backgroundColor ?? "",  c.condition(c,  e.data, itemsIndex)?.color ?? "", 11, true)   
        else
          if(rowGray && isDistribuyed)
            rowStyles(worksheet, fieldLast, false, 10, 26, 'dbdbdb', "", 12, true)
          else
            rowStyles(worksheet, fieldLast, false, 10, 26, "", "", 12, true)
      })

      if(dataIndex == 0 || (dataIndex > 0 && data?.[dataIndex-1].data[0] != e.data[0]))
        totalDataIndex ++
      
      
      fieldLast++
    })

    merges.map((e)=>{
      worksheet.mergeCells(e);
    })

    //Add image in header
    auth?.LogoRoute?.replace("Public/", '') && await image(worksheet, workbook, auth?.LogoRoute?.replace("Public/", '')??'')
  }
  
  //Print document
  let fileName = Array.isArray(fileTitle) ? fileTitle?.[0] : fileTitle;
  fileName = fileName.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/","");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

  workbook.xlsx.writeBuffer().then(buffer => { saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`) }).catch((err) => { console.log(err) })

}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

export default {
  data,
  methods: {
    getPdf,
    getPassPdf,
    getReporExcel,
    getReporExcelCanceled,
    getExcel,
    excel,
    numeroLetra,
  },
  computed:{
    ...mapState({
      dataContainer: state => state.yardManagement.dataContainer,
      user: state => state.auth.user,
      branch: state => state.auth.branch,
      yardData: state => state.yardManagement.yardData,
    }),
  },
  }