<template>
    <div>
      <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
      <CModalExtended
        :title="ModalTitle"
        color="dark"
        size="xl"
        @update:show="closeModal()"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
        <CRow>
          <CCol sm="12" class="d-flex align-items-center justify-content-end ">
            <CButton 
              color="danger"
              class="mr-1"
              size="sm" 
              @click="onBtnPdf()"
            >
              <CIcon name="cil-file-pdf"/>&nbsp; PDF 
            </CButton>
            <CButton
              color="excel"
              class="mr-1"
              size="sm" 
              @click="onBtnExport(true)"
            >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
            <CButton
              color="watch"
              size="sm" 
              @click="onBtnExport(false)"
            >
              <CIcon name="cil-file-excel"/>&nbsp; CSV
            </CButton>
          </CCol>
          <CCol sm="12" lg="4">
            <CSelect
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-7'}"
              :placeholder="$t('label.select')"
              addLabelClasses="text-right"
              @change="Filtre($event)"
              :value="OrderStatus"
              :options="computedOrderStatus"
            />
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              size="lg"
              :fields="fields"
              :items="computedListHold"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              @filtered-items-change="getFilteredList"
              sorter
            >
              <template #OrderStatusDs="{item}">
                  <td class="text-center">
                    <CBadge :style="'color:'+item.ColorStatus">
                      {{ item.OrderStatusDs }}
                    </CBadge>
                  </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
        <template #footer>
          <CButton color="wipe" @click="closeModal()">
            <CIcon name="x" />&nbsp; {{$t('button.exit')}}
          </CButton>
        </template>
      </CModalExtended>
    </div>
  </template>
  <script>
  import GeneralMixin from '@/_mixins/general';
  import GeneralReport from '@/_mixins/generalReport';
  import UpperCase from '@/_validations/uppercase-directive';
  import { mapState } from 'vuex';
  import { DateFormater, NumberFormater } from '@/_helpers/funciones';
  
  function data() {
    return {
      OrderStatus: '',
      OrderStatusName: '',
      OrderStatusOption: [],
      Orderitem: [],
      FilterList: [],
      modalActive: false,
      loadingOverlay: false,
    };
  }

  function closeModal() {
    this.OrderStatusName = '';
    this.OrderStatus = '';
    this.OrderStatusOption = [];
    this.OrderStatus = [];
    this.Orderitem  = [];
    this.$emit('set-list');
  }
  
  function Filtre(event) {
    this.OrderStatus = event.target.value;
    this.mountedData(this.Order);
  }

  function getFilteredList(List) {
    this.FilterList = List;
  }

  async function onBtnPdf(){
    this.loadingOverlay = true;
    let _lang = this.$i18n.locale;
    let ReporPdf = [];
    let tableHeader = [];
    let title = '';
    if(this.nulo!="null" && this.FilterList.length !== 0) {
      if (this.isEntry) {
        title = this.$t('label.entryOrders');

        tableHeader.push({ title: '#', dataKey: 'Nro'});
        tableHeader.push({ title: this.$t('label.noOrder'), dataKey: 'ControlNumber'});
        tableHeader.push({ title: this.$t('label.orderDate'), dataKey: 'FormatedDate'});
        tableHeader.push({ title: this.$t('label.RelocationGuide'), dataKey: 'GuideNumber'});
        tableHeader.push({ title: this.$t('label.vehicle'), dataKey: 'LicensePlate'});
        tableHeader.push({ title: this.$t('label.netWeight')+' (TON)', dataKey: 'FormatedNetWeightTon'});
        tableHeader.push({ title: this.$t('label.entryDate'), dataKey: 'FormatedEntryDate'});
        tableHeader.push({ title: this.$t('label.outDate'), dataKey: 'FormatedOutDate'});
        tableHeader.push({ title: this.$t('label.status'), dataKey: 'OrderStatusDs'});

        for (let i = 0; i < this.FilterList.length; i++) {
          ReporPdf.push({
            Nro: this.FilterList[i].Nro,
            ControlNumber: this.FilterList[i].ControlNumber,
            FormatedDate: this.FilterList[i].TransactionDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].TransactionDate):'N/A',
            GuideNumber : this.FilterList[i].GuideNumber,
            LicensePlate: this.FilterList[i].LicensePlate,
            FormatedNetWeightTon: this.FilterList[i].NetWeightTon,
            FormatedEntryDate: this.FilterList[i].EntryDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].EntryDate):'N/A',
            FormatedOutDate: this.FilterList[i].OutDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].OutDate):'N/A',
            OrderStatusDs: _lang=='en'? this.FilterList[i].OrderStatusDsEn : this.FilterList[i].OrderStatusDsEs, 
          });   
        }
      } else {
        title = this.$t('label.shipmentOrders');

        tableHeader.push({ title: '#', dataKey: 'Nro'});
        tableHeader.push({ title: this.$t('label.noOrder'), dataKey: 'OrderCode'});
        tableHeader.push({ title: this.$t('label.transport'), dataKey: 'CarrierAlias'});
        tableHeader.push({ title: this.$t('label.vehicle'), dataKey: 'LicensePlate'});
        tableHeader.push({ title: this.$t('label.Gamela'), dataKey: 'ImplementAlias'});
        tableHeader.push({ title: this.$t('label.yard'), dataKey: 'YardName'});
        tableHeader.push({ title: this.$t('label.netWeight')+' (TON)', dataKey: 'NetWeightTon'});
        tableHeader.push({ title: this.$t('label.crane'), dataKey: 'CraneName'});
        tableHeader.push({ title: this.$t('label.hold'), dataKey: 'VesselHoldName'});
        tableHeader.push({ title: this.$t('label.inicio'), dataKey: 'FormatedDate'});
        tableHeader.push({ title: this.$t('label.fin'), dataKey: 'FinishDate'});
        tableHeader.push({ title: this.$t('label.time'), dataKey: 'TotalTime'});
        tableHeader.push({ title: this.$t('label.status'), dataKey: 'OrderStatusDs'});

        for (let i = 0; i < this.FilterList.length; i++) {
          ReporPdf.push({
            Nro: this.FilterList[i].Nro,
            OrderCode: this.FilterList[i].OrderCode ? this.FilterList[i].OrderCode : '',
            CarrierAlias: this.FilterList[i].CarrierAlias ? this.FilterList[i].CarrierAlias : '',
            LicensePlate: this.FilterList[i].LicensePlate ? this.FilterList[i].LicensePlate : '',
            ImplementAlias: this.FilterList[i].ImplementAlias ? this.FilterList[i].ImplementAlias : '',
            YardName: this.FilterList[i].YardName ? this.FilterList[i].YardName : 'N/A',
            NetWeightTon: this.FilterList[i].NetWeightTon ? this.FilterList[i].NetWeightTon : 0,
            CraneName: this.FilterList[i].CraneName ? this.FilterList[i].CraneName : 'N/A',
            VesselHoldName: this.FilterList[i].VesselHoldName ? this.FilterList[i].VesselHoldName : 'N/A',
            FormatedDate: this.FilterList[i].TransactionDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].TransactionDate):'N/A',
            FinishDate: this.FilterList[i].FinishDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].FinishDate):'N/A',
            TotalTime: this.FilterList[i].TotalTime? this.FilterList[i].TotalTime:'N/A',
            OrderStatusDs: _lang=='en'? this.FilterList[i].OrderStatusDsEn : this.FilterList[i].OrderStatusDsEs, 
          });   
        }
      }
      await this.getPdf(ReporPdf, title, false, tableHeader);
    }else{
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error" 
      });
    }
    this.loadingOverlay = false;   
  }

  async function onBtnExport(valor) {
    this.loadingOverlay = true;
    let _lang = this.$i18n.locale;
    let currentDate = new Date();
    let title = '';
    let Filter = [];
    let ReporExcel = [];
    let tableHeader = {label:[],key:[]};
    if(this.nulo!="null" && this.FilterList.length !== 0) {
      Filter[0] = this.$t('label.status')+": "+this.OrderStatusName;
      Filter[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
      Filter[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

      if (this.isEntry) {
        title = this.$t('label.entryOrders');
        for (let i = 0; i < this.FilterList.length; i++) {
          ReporExcel.push({
            Nro: this.FilterList[i].Nro,
            ControlNumber: this.FilterList[i].ControlNumber,
            FormatedDate: this.FilterList[i].TransactionDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].TransactionDate):'N/A',
            GuideNumber : this.FilterList[i].GuideNumber,
            LicensePlate: this.FilterList[i].LicensePlate,
            FormatedNetWeightTon: this.FilterList[i].NetWeightTon,
            FormatedEntryDate: this.FilterList[i].EntryDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].EntryDate):'N/A',
            FormatedOutDate: this.FilterList[i].OutDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].OutDate):'N/A',
            OrderStatusDs: _lang=='en'? this.FilterList[i].OrderStatusDsEn : this.FilterList[i].OrderStatusDsEs, 
          });   
        }
      } else {
        title = this.$t('label.shipmentOrders');
        for (let i = 0; i < this.FilterList.length; i++) {
          ReporExcel.push({
            Nro: this.FilterList[i].Nro,
            OrderCode: this.FilterList[i].OrderCode ? this.FilterList[i].OrderCode : '',
            CarrierAlias: this.FilterList[i].CarrierAlias ? this.FilterList[i].CarrierAlias : '',
            LicensePlate: this.FilterList[i].LicensePlate ? this.FilterList[i].LicensePlate : '',
            ImplementAlias: this.FilterList[i].ImplementAlias ? this.FilterList[i].ImplementAlias : '',
            FormatedYardName: this.FilterList[i].YardName ? this.FilterList[i].YardName : 'N/A',
            FormatedNetWeightTon: this.FilterList[i].NetWeightTon ? this.FilterList[i].NetWeightTon : 0,
            FormatedCraneName: this.FilterList[i].CraneName ? this.FilterList[i].CraneName : 'N/A',
            HoldName: this.FilterList[i].VesselHoldName ? this.FilterList[i].VesselHoldName : 'N/A',
            FormatedDate: this.FilterList[i].TransactionDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].TransactionDate):'N/A',
            FormatedFinishDate: this.FilterList[i].FinishDate? DateFormater.formatDateTimeWithSlash(this.FilterList[i].FinishDate):'N/A',
            TotalTime: this.FilterList[i].TotalTime? this.FilterList[i].TotalTime:'N/A',
            OrderStatusDs: _lang=='en'? this.FilterList[i].OrderStatusDsEn : this.FilterList[i].OrderStatusDsEs, 
          });
        }  
      }
      this.fields.map(item=>{
        tableHeader.label.push(item.label);
        tableHeader.key.push(item.key);
      });
      await this.getReporExcel(ReporExcel, title, valor, Filter, false, tableHeader);
    }else{
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error" 
      });
    }
    this.loadingOverlay = false;   
  }

  function mountedData(item) {
    this.loadingOverlay = true;
    let DriverOrderJson = this.isEntry ? [{
      CompanyBranchId: this.branch.CompanyBranchId,
      DateFrom: '',
      SearchKey: '',
      DriverId: item.DriverId,
      BillOfLadingYardId: item.BillOfLadingYardId,
      YardClientTpId: item.YardClientTpId,
      OrderStatus: this.OrderStatus,
    }]:[{
      CompanyBranchId: this.branch.CompanyBranchId,
      DateFrom: '',
      SearchKey: '',
      DriverId: item.DriverId,
      VisitId: item.VisitId,
      OrderStatus: this.OrderStatus,
    }]

    let route = this.isEntry ? 'DriverEntryOrderDetail-list' : 'DriverShipmentOrderDetail-list';
    this.$http.post(route, DriverOrderJson, { root: 'DriverOrderJson' })
      .then(response => {
        let nulo = String(response.data.data[0].Json);
        if(nulo != "null"){
          this.Orderitem = response.data.data;
          if (this.OrderStatus != '') {
            let _lang = this.$i18n.locale;
            let StatusOption = this.OrderStatusOption.find( item => item.ProcessStatusId === this.OrderStatus );
            this.OrderStatusName = _lang=='en' ? StatusOption.ValueDsEn : StatusOption.ValueDsEs;
          }else{
            this.OrderStatusName = 'N/A'
          }
        }else{
          this.Orderitem = [];
        }
        this.loadingOverlay = false;
        }).catch( err => {
          this.loadingOverlay = false;
          this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }
  
  function getOrderStatusList() {
    this.loadingOverlay = true;
    let router = this.isEntry ? 'OrderEntryStatus-list' : 'OrderShipmentStatus-list';
    this.$http.get(router)
      .then(response => {
        this.OrderStatusOption = response.data.data;
        this.mountedData(this.Order);
        }).catch( err => {
          this.loadingOverlay = false;
          this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }
  
  function computedListHold() {
    let _lang = this.$i18n.locale;
    return this.Orderitem.map((item) => {
      if (this.isEntry) {
        return {
          ...item,
          FormatedNetWeightTon: item.NetWeightTon ? NumberFormater.formatNumber(item.NetWeightTon, 2) : '0,00',
          FormatedEntryDate: item.EntryDate ? DateFormater.formatDateTimeWithSlash(item.EntryDate) : 'N/A',
          FormatedOutDate: item.OutDate ? DateFormater.formatDateTimeWithSlash(item.OutDate) : 'N/A',
          OrderStatusDs: _lang=='en'? item.OrderStatusDsEn : item.OrderStatusDsEs,
          FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate):'N/A',
        };
      } else {
        return {
          ...item,
          FormatedYardName: item.YardName ? item.YardName : 'N/A',
          FormatedNetWeightTon: item.NetWeightTon ? NumberFormater.formatNumber(item.NetWeightTon, 2) : '0,00',
          FormatedCraneName: item.CraneName ? item.CraneName : 'N/A',
          HoldName: item.VesselHoldName ? item.VesselHoldName : 'N/A',
          FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate):'N/A',
          FormatedFinishDate: item.FinishDate ? DateFormater.formatDateTimeWithSlash(item.FinishDate) : 'N/A',
          OrderStatusDs: _lang=='en'? item.OrderStatusDsEn : item.OrderStatusDsEs,
        };
      }
    });
  }

  function computedOrderStatus(){
    if(this.OrderStatusOption.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      let _lang = this.$i18n.locale;
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.OrderStatusOption.map(function(e){
        chart.push({
          value: e.ProcessStatusId, 
          label: _lang=='en' ? e.ValueDsEn : e.ValueDsEs,
        })    
      })
      return chart;
    }
  }
  
  function fields(){
    if (this.isEntry) {
      return [
        { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%;', filter: false},
        { label: this.$t('label.noOrder'), key: 'ControlNumber', _classes: 'text-center', _style: 'width:12.6%;',},
        { label: this.$t('label.orderDate'), key: 'FormatedDate', _classes: 'text-center', _style: 'width:12%;' },
        { label: this.$t('label.RelocationGuide'), key: 'GuideNumber', _classes: 'text-center', _style: 'width:15%;' },
        { label: this.$t('label.vehicle'), key: 'LicensePlate', _classes: 'text-center', _style: 'width:9%;' },
        { label: this.$t('label.netWeight')+' (TON)', key: 'FormatedNetWeightTon', _classes: 'text-center',_style: 'width:14.4%;' },
        { label: this.$t('label.entryDate'), key: 'FormatedEntryDate', _classes: 'text-center', _style: 'width:13%;' },
        { label: this.$t('label.outDate'), key: 'FormatedOutDate', _classes: 'text-center',_style: 'width:12%;' },
        { label: this.$t('label.status'), key: 'OrderStatusDs', _classes: 'text-center', _style: 'width:9%;' },
      ];
    } else {
      return [
        { label: '#', key: 'Nro', _classes: 'text-center', filter: false},
        { label: this.$t('label.noOrder'), key: 'OrderCode', _classes: 'text-center', _style: 'min-width:135px',},
        { label: this.$t('label.transport'), key: 'CarrierAlias', _classes: 'text-center', _style: 'width:12%;' },
        { label: this.$t('label.vehicle'), key: 'LicensePlate', _classes: 'text-center', _style: 'min-width:90px' },
        { label: this.$t('label.Gamela'), key: 'ImplementAlias', _classes: 'text-center', _style: 'min-width:90px' },
        { label: this.$t('label.yard'), key: 'FormatedYardName', _classes: 'text-center', _style: 'min-width:100px' },
        { label: this.$t('label.netWeight')+' (TON)', key: 'FormatedNetWeightTon', _classes: 'text-center',_style: 'min-width:170px', },
        { label: this.$t('label.crane'), key: 'FormatedCraneName', _classes: 'text-center', _style: 'min-width:90px' },
        { label: this.$t('label.hold'), key: 'HoldName', _classes: 'text-center', _style: 'min-width:90px' },
        { label: this.$t('label.inicio'), key: 'FormatedDate', _classes: 'text-center', _style: 'min-width:120px' },
        { label: this.$t('label.fin'), key: 'FormatedFinishDate', _classes: 'text-center',_style: 'min-width:120px' },
        { label: this.$t('label.time'), key: 'TotalTime', _classes: 'text-center',_style: 'width:12%;' },
        { label: this.$t('label.status'), key: 'OrderStatusDs', _classes: 'text-center', _style: 'width:11%;' },
      ];
    }
  }

  function ModalTitle() {
    let EmptyObject = Object.entries(this.Order).length!=0;
    if (this.isEntry) {
      return this.$t('label.SeeEntryOrdersByDriver')+': '+(EmptyObject?this.Order.DriverName+'-'+this.Order.DriverCi:'');
    } else {
      return this.$t('label.SeeShipmentOrdersByDriver')+': '+(EmptyObject?this.Order.DriverName+'-'+this.Order.DriverCi:'');
    }
  }
  
  export default {
    name: 'modal-hold-information',
    props: { modal: Boolean, Order: Object, isEntry: Boolean },
    data,
    mixins: [
      GeneralMixin,
      GeneralReport,
    ],
    directives: UpperCase,
    methods: {
      onBtnPdf,
      onBtnExport,
      Filtre,
      getOrderStatusList,
      mountedData,
      closeModal,
      getFilteredList,
    },
    computed: {
      ModalTitle,
      computedOrderStatus,
      computedListHold,
      fields,
      ...mapState({
        branch: state => state.auth.branch,
      }),
    },
    watch: {
        modal: function(val) {
          this.modalActive = val;
          if (val) {
            this.getOrderStatusList();
          }
        },
    },
    
  };
  </script>