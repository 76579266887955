<template>
  <div>
    <CrearRuta :modal.sync="CreModal"
      @cerrarModal="CreModal = false"  />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b> {{$t('label.apiRoutes')}} </b>
      </CCardHeader>
      <CCardBody>
        <CRow
          ><CCol sm="12" class="d-flex align-items-center justify-content-end">
            <CButton square color="add" @click="CreModal = true" v-c-tooltip="{placement:'top-end',content:$t('label.structure')}">
              <CIcon name="cil-playlist-add" />&nbsp; {{$t('label.nueva')}}
            </CButton>

            <CButton
              square
              color="add"
              @click="CreModal = true"
              style="margin-left:5px;"
              v-c-tooltip="{placement:'top-end',content:$t('label.route')}"
            >
              <CIcon name="cil-playlist-add" />&nbsp; {{$t('label.nueva')}}
            </CButton>
          </CCol>
          <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="itemsApi"
            :fields="fieldApi"
            column-filter
            :items-per-page="5"
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            hover
            small
            sorter
            pagination
          >
             <template #loading>
                <loading/>
             </template>
             <template #Detalle="{item}">
              <td class="py-2">
                <CButton
                  color="primary"
                  class="mr-1"
                  square
                  size="sm"
                  v-c-tooltip="$t('label.edit') + ' ' + $t('label.routeApi')"
                  @click="CreModal = item"
                >
                  <CIcon name="pencil" />
                </CButton>
                
              </td>
            </template>
          </dataTableExtended>
        </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { DateFormater, tableTextHelpers } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import CrearRuta from "./crear-ruta";

function fieldApi(){
   return [
            {
              key: "Nro",
              label: "#",
              _style: "width:1%;",
              filter:false
            },
            { key: "Module", label: "API", _style:'min-width:50%;' },
            { key: "FileDirectory", label: this.$t('label.fileDirectory') },
          ]
}          
function listApi() {
  this.itemsApi = [];

  let listado = Array;
  this.$http
    .get("Route-list", {})
    .then((response) => {
      listado = [...response.data.data];
      this.itemsApi = listado.map((listado) =>
        Object.assign({}, this.itemsApi, {
          Nro: listado.id,
          Module: listado.Module,
          Rutas: listado.routes,
          FileDirectory: listado.FileDirectory || 'N/A',
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.LoadingDocs = false;
    });
}

//DATA
function data() {
  return {
    itemsApi: [],
    CreModal: false,
    ModModal: false,
    Loading: false,    
  };
}

export default {
  name: "ruta-api",
  mixins: [General],
  data,
  components: {
    CrearRuta,
  },
  methods: {
    listApi,
  },
  computed:{
    fieldApi
  },
  // props: {
  //   fieldApi: {
  //     type: Array,
  //     default() {
  //       return fieldApi;
  //     },
  //   },
  // },
  watch: {
    refresh: function() {
      this.listApi();
    },
  },
  mounted() {
    this.listApi();
  },
};
</script>
<style scoped></style>
