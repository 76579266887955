import {
    required,
    maxLength,
    alpha,
  } from 'vuelidate/lib/validators';

import { onlyAlphaguion,nameLength } from '@/_validations/validacionEspeciales';
  
  const isValidFile = (value) => {
    if(!value)
      return false;
    
    if(value.type)
      return value.type.startsWith('image');
    else
        return (value != '');
  };

  export default () => {
    return {
      actividadPortuaria: {
        PortActivityNameEs: { required, onlyAlphaguion, maxLength: maxLength(50), nameLength },
        PortActivityNameEn: { required, onlyAlphaguion, maxLength: maxLength(50), nameLength },
        ClientNameEs: { required, onlyAlphaguion, maxLength: maxLength(50), nameLength },
        ClientNameEn: { required, onlyAlphaguion, maxLength: maxLength(50), nameLength },
        PortActivityAbbrev: { required, alpha, maxLength: maxLength(6) },
        Color: { },
      }
    }
  }