<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CrearModal 
      :modal="CreModal" 
      @child-refresh="refrescarDocs=true" 
      @cerrarModal="CreModal=false" 
    />
    <DocumentosModal 
      :modal.sync="ShowDocModal"
      :documentItem="documentItem"
      :title="titleModal"
      @close="closeModal"
    />  
    <ListarModal 
      :modal.sync="ShowListModal" 
      :listItem="listItem"
      :title="titleModal2"
      @close="closeModal"
    />  
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b> {{$t('label.standardDocumentation')}} </b>
      </CCardHeader>
      <CCardBody>
        <CCol sm="12" class="text-right">
          <CButton square color="add" 
          @click="CreModal = true" 
          v-c-tooltip="{
            placement: 'top-end',
            content: $t('label.standardDocumentation')
          }">

            <CIcon name="cil-playlist-add" />{{$t('label.nueva')}}
          </CButton>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="itemsDocs"
            :fields="fieldsDoc"
            :loading="LoadingDocs"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
            <template #loading>
              <loading/>
            </template>
            <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}">
              <td class="py-2 text-center">
                <CButton
                  color="edit"
                  class="mr-1"
                  square
                  size="sm"
                  v-c-tooltip="$t('label.edit')+' '+$t('label.standardDocumentation')"
                  @click="CreModal = item"
                >
                  <CIcon name="pencil" />
                </CButton>
                <CButton
                  class="mr-1"
                  color="add"
                  size="sm"
                  square
                  v-c-tooltip="$t('label.uploadCommodityDocuments')"
                  @click="docModal(item)"
                  v-if="item.FgActStandardDocument"
                >
                  <CIcon name="cil-cloud-upload"/>
                </CButton>
                <CButton
                  color="watch"
                  square
                  size="sm"
                  v-c-tooltip="{
                    placement:'top-end',
                    content:$t('label.toView')+' '+$t('label.documents')
                  }"
                  @click="ListModal(item)"
                >
                  <CIcon name="eye" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import ListarModal from "./list-modal";
import CrearModal from "./crear-modal";
import DocumentosModal from "./list-documents";

//DATA
function data() {
  return {
    itemsDocs: [],
    CreModal: false,
    ShowDocModal: false,
    ShowListModal: false,
    documentItem : {},
    listItem : {},
    titleModal:'',
    titleModal2:'',
    ModModal: false,
    Loading: false,
    LoadingDocs: false,
    refrescarDocs: false,
    StandardDocumentDocRoute: "",
  };
}



//METHOD
function listarDocs() {
  this.itemsDocs = [];
  this.docs = [];
  this.LoadingDocs = true;

  let listado = Array;
  this.$http
    .get("StandardDocument-list", { filter: "ALL" })
    .then((response) => {
      listado = [...response.data.data];
      this.docs = listado.filter((ob) => ob.Status === "ACTIVO");
      this.itemsDocs = listado.map((listado) =>
        Object.assign({}, this.itemsDocs, {
          Nro: listado.Nro,
          FgActStandardDocument: listado.FgActStandardDocument,
          Name: listado.StandardDocumentName,
          Usuario: listado.TransaLogin ? listado.TransaLogin : "N/A",
          ShippingLineId: listado.ShippingLineId ? listado.ShippingLineId : "",
          ShippingLineName: listado.ShippingLineName ? listado.ShippingLineName : "",
          Fecha: listado.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate)
            : "N/A",
          StandardDocumentId: listado.StandardDocumentId,
          StandardDocumentDescription: listado.StandardDocumentDescription,
          StandardOrganization: listado.StandardOrganization,
          StandardDocumentDocRoute: listado.StandardDocumentDocRoute
            ? listado.StandardDocumentDocRoute.replace(
                "Public/StandarDocument",
                "",
                "i"
              )
            : "N/A",
          DocsRoute: listado.StandardDocumentDocRoute
            ? `${
                this.$store.getters["connection/getBase"]
              }${listado.StandardDocumentDocRoute.replace("Public/", "", "i")}`
            : "N/A",
          Status: listado.Status,
          _classes: listado.Status === "INACTIVO" ? "table-danger" : "",
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.LoadingDocs = false;
    });
}

function docModal(item) {
  this.ShowDocModal = true;
  this.documentItem = item;
  this.titleModal =  this.$t('label.uploadCommodityDocuments')+': '+this.documentItem.Name;
}

function ListModal(item) {
  this.ShowListModal = true;
  this.listItem = item;
  this.titleModal2 =  this.$t('label.viewDocuments')+': '+this.listItem.Name;
}

function closeModal(){
  this.documentItem = {};
  this.listItem = {};
  this.ShowListModal = false;
  this.ShowDocModal= false;
  this.titleModal = '';
  this.titleModal2 = '';
}


function fieldsDoc(){
  return [
     { key: 'Nro', label: '#', _classes: 'text-center center-cell', _style: 'width:3%; text-align:center', filter: false},
     { key: 'Name',label: this.$t('label.standardDocumentation'), _classes: 'text-center center-cell',_style: 'width:30%;',},
     { key: 'Usuario', label: this.$t('label.user'), _classes: 'text-center center-cell', _style:'width:15%' },
     { key: 'Fecha', label: this.$t('label.date'), _classes:'text-center center-cell', _style:'width:15%' },
     { key: 'Status', label: this.$t('label.status'),  _classes: 'text-center ', _style:'width:15%' },
     { key: 'Detalle', label: '',_style: 'width:5%; min-width:130px;text-align:center', sorter: false, filter: false },
  ];
}



export default {
  name: "list",
  mixins: [General],
  data,
  components: {
    ListarModal,
    CrearModal,
    DocumentosModal
  },
  methods: {
    listarDocs,
    docModal,
    ListModal,
    closeModal
  },
  watch: {
    refrescarDocs: function() {
      if (this.refrescarDocs) {
        this.listarDocs();
        this.refrescarDocs = false;
      }
    },
  },
  computed:{
    fieldsDoc,
  },
  mounted() {
    this.listarDocs();
  },
};
</script>
<style scoped></style>
