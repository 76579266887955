<template>
  <form-buque :vessel-id="VesselId" :vessel-image="VesselImage"/>
</template>

<script>
import FormBuque from "@/pages/buque/tabs/form";

//Datos:
function data() {
  return {};
}

//Created
function created() {
}

export default {
  name: "edit-buque",
  props: {
    VesselId: {
      type: String,
      default: "",
    },
    VesselImage: {
      type: String,
      default: "",
    },
  },
  components: { FormBuque },
  data,
  created,
};
</script>

<style scoped>
</style>