<template>
  <div class="container-fluid">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CTabs variant="tabs" :active-tab="0">
      <CTab :title="$t('label.TransportCertification')">
        <CCard bodyWrapper >
          <CRow v-if="!Collapse">
            <CCol sm="12" class="text-right">
              <CButton
                square
                color="add"
                @click="Collapse=true"
                v-c-tooltip="{
                  content: $t('label.TransportCertification'),
                  placement: 'top-end'
                }"
              >
                <CIcon name="cil-playlist-add" /><span class="ml-1"
                  >{{$t('label.nueva')}}</span
                >
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                size="lg"
                :fields="fields"
                :items="computedListDraft"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                sorter
              >
                <template #Status="{item}">
                    <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                </template>
                <template #options="{ item }">
                  <td class="center-cell">       
                      <CButton
                          square
                          size="sm"
                          color="edit"
                          class="mr-1"
                          @click="toggleEdit(item)"
                          v-c-tooltip="{
                              content: $t('label.edit')+' '+$t('label.TransportCertification'),
                              placement: 'top-end'
                          }"
                      >
                          <CIcon name="pencil" />
                      </CButton>      
                      <CButton
                        square
                        color="revert"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.reverse'),
                          placement: 'top-end'
                        }"
                        @click="Reverse(item)"
                      >
                        <CIcon name="cil-reload"/>
                      </CButton>  
                      <CButton
                          square
                          color="danger"
                          size="sm"
                          class="mr-1"
                          @click.prevent="printPdf(item)"
                          v-c-tooltip="{
                            content: $t('label.download')+' PDF',
                            placement: 'top-end'
                          }"
                      >
                          <CIcon name="cil-file-pdf"/>
                      </CButton>
                            
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
          <CCol sm="12" lg="12">
            <CCollapse :show="Collapse">
              <CollapseTransportCertification
                :Collapse="Collapse"
                :isEdit="isEdit"
                :item="item"
                @close-Collapse="Close"
              />
            </CCollapse>
          </CCol>
        </CCard>
      </CTab>
    </CTabs>
    <ModalTransportCertificationReverse
      :modal.sync="ModalReverse"
      @close-Modal="Close"
    />
    <ModalTransportCertificationPdf
      :modal.sync="ModalPdf"
      @close-Modal="Close"
    />
  </div>
</template>
<script>
import { DateFormater, NumberFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import ModalTransportCertificationReverse from './modal-transport-certification-reverse';
import ModalTransportCertificationPdf from './modal-transport-certification-pdf';
import CollapseTransportCertification from './collapse-transport-certification';
import General from '@/_mixins/general';

import { mapState } from 'vuex';

function data() {
  return {
    Collapse: false,
    DraftItems: [],
    item: {},
    ModalPdf: false,
    isEdit: false,
    nulo: '',
    loading: false,
    ModalReverse: false,
  };
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.DFrom'), key: ' ', _classes: 'text-center', _style: 'width:13%;',},
    { label: this.$t('label.DTo'), key: ' ', _classes: 'text-center', _style:'width:13%' },
    { label: this.$t('label.TotalCertification')+this.$t('label.metricTonnageAcronym'), key: ' ', _classes: 'text-uppercase', _style:'width:15%' },
    { label: this.$t('label.gamelasQuantity'), key: ' ', _classes: 'text-uppercase', _style:'width:13%' },
    { label: this.$t('label.user'), key: ' ', _classes: 'text-uppercase', _style:'width:10%' },
    { label: this.$t('label.date'), key: ' ',_classes:'text-center', _style:'width:11%' },
    { label: this.$t('label.status'), key: ' ', _classes: 'text-center', _style:'width:10%' },
    { label: '', key: 'options', _style: 'min-width:135px;', _classes:'text-center', sorter: false, filter: false },
  ];
}

function tableText(){
    return tableTextTranslatedHelpers(this);
}

function toggleEdit(item) {
  this.item = item;
  this.isEdit = true;
  this.Collapse = true;
}

function Close() {
  this.item = {};
  this.isEdit = false;
  this.Collapse = false;
  this.ModalPdf = false;
  this.ModalReverse = false;
}

function computedListDraft() {
  
  if (this.nulo=="null" || this.DraftItems ==[]) return this.DraftItems = [];
  if (this.DraftItems.length > 0) {
    return this.DraftItems.map((item) => {
      return {
        ...item,
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.toNormalDate(item.TransaRegDate)
          : 'N/A',
      };
    });
  }
}

function getDraftList() {
  /*this.loading = true;
  this.DraftItems =[];
  let i = 0;
  let id = this.Visit;
    const VisitDraftJson = [{
      VisitId: id
    }]
      this.$http
        .post('VisitDraft-list', VisitDraftJson, { root: 'VisitDraftJson' })
        .then(res => {
          this.DraftItems = res.data.data;
          this.nulo = String(res.data.data[0].Json);
          if (this.DraftItems.length > 0 && this.nulo != "null") {
            this.DraftItems.map((item) => {
              this.DraftItems[i].DateFrom = DateFormater.formatToNormalDateTime(item.DateFrom);
              this.DraftItems[i].DateTo = DateFormater.formatToNormalDateTime(item.DateTo);
              this.DraftItems[i].TransaRegDate = DateFormater.formatToNormalDateTime(item.TransaRegDate);
              i++;
            });
          }
        }).finally(() => {
          this.loading = false;
        });*/
}

function Reverse(item) {
  this.ModalReverse = true;
}

function printPdf(item) {
  this.ModalPdf = true;
    /*let pageInfo = {
      VisitDraftId: item.VisitDraftId,
      item: item,
      itinerarySelected: this.itinerarySelected,
    };

    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

    let routeData = this.$router.resolve({name: 'PdfDraft'});
    window.open(routeData.href, '_blank');*/
}


export default {
  name: 'index',
  data,
  mixins: [General],
  components: {
    ModalTransportCertificationReverse,
    ModalTransportCertificationPdf,
    CollapseTransportCertification,
  },
  methods: {
    Close,
    printPdf,
    Reverse,
    toggleEdit,
    getDraftList,
  },  
  computed: {
    computedListDraft,
    fields,
    tableText,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        dropItemDratf: state => state.visitas.dropItemDratf,
    })
  },
  watch: {
    dropItemDratf: function (newValue) {
      if ((newValue==2) && (this.Visit!='')) {
        this.Collapse = false;
        this.getDraftList();
      }
    },
  },
};
</script>
<style scope>

</style>