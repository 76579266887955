<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="xl"
      class="modal-extended"
      :show.sync="modalActive"
      @update:show="closeModal(false, false)"
      :closeOnBackdrop="false"
    >
      <CRow class="d-flex justify-content-center">
        <CCol v-if="NoOrder != ''" sm="12" lg="11" class="d-flex justify-content-end mb-2">
           <CCol sm="auto" class="text-OrderStatusDs text-color-negrita text-white rounded" :style="StatusColor">
            {{OrderCode+': '+OrderStatusDs}}
          </CCol>
        </CCol>
       
        <CCol sm="12" lg="6" class="mr-0">
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-5 col-xl-5 text-right', input: 'col-sm-12 col-lg-7 col-xl-7'}"
            addLabelClasses="text-right required"
            :label="$t('label.Gamela')"
            size="sm"
            :options="scrapContainerFormatted"
            v-model.trim="$v.scrapContainer.$model"
            :value.sync="scrapContainer"
            :is-valid="hasError($v.scrapContainer)"
            :invalid-feedback="errorMessage($v.scrapContainer)"
            @change="filterscrapContainer"
            :disabled="isEdit || Collapse"
          />
          <CCol sm="12" class="px-0 "  v-if="!Collapse">
            <div class="form-group form-row mb-0">
              <label class="col-form-label-sm col-sm-12 col-lg-5 text-right required">{{$t('label.movementType')}}</label>
              <CCol sm="12" lg="7">
                <CCol sm="12" class="rounded" style="background: #1A3760; height: 26px;">
                  <label class="text-color-negrita text-white mb-0" style="font-size: 1.1rem;">{{MovementType}}</label>
                </CCol>
              </CCol>
            </div>
          </CCol>
          <CInput
            v-if="!Collapse"
            addLabelClasses="text-right"
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-5 col-xl-5 text-right', input: 'col-sm-12 col-lg-7 col-xl-7'}"
            :label="$t('label.transport')"
            v-model.trim="$v.Transportation.$model"
            :value.sync="Transportation"
            :is-valid="hasError($v.Transportation)"
            :disabled="true"
          />
          <CInput
            v-if="!Collapse"
            :label="$t('label.vehicle')"
            size="sm"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            v-uppercase
            v-model.trim="$v.vehicle.$model"
            :value.sync="vehicle"
            :is-valid="hasError($v.vehicle)"
            :disabled="true"
          />
          <CInput
            v-if="!Collapse"
            :label="$t('label.yard')"
            size="sm"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            v-uppercase
            v-model.trim="$v.Yard.$model"
            :value.sync="Yard"
            :is-valid="hasError($v.Yard)"
            :disabled="true"
          />
          <CInput
            v-if="!Collapse"
            :label="$t('label.clientYard')"
            size="sm"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
            v-uppercase
            v-model.trim="$v.client.$model"
            :value.sync="client"
            :is-valid="hasError($v.client)"
            :disabled="true"
          />
        </CCol>
        
        <CCol sm="12" lg="6">
          <CCol sm="12" class="px-0 ">
            <div class="form-group form-row mb-2">
              <label class="col-form-label-sm col-sm-12 col-lg-5 col-xl-5 text-right required">{{$t('label.noOrder')}}</label>
              <div class="input-group col-sm-12 col-lg-6">
                <CInput
                  size="sm"
                  class="mb-0"
                  v-uppercase
                  :disabled="true"
                  v-model.trim="$v.OrderCode.$model"
                  :is-valid="hasError($v.NoOrder)"
                  style="width: 100% !important"
                >
                  <template #append>
                    <div class="d-flex align-items-start">
                      <CButton
                        v-if="!Collapse" 
                        :disabled="NoOrderOptions.length <= 1 || isEdit"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="NoOrderOptions.length > 1 && !hasError($v.NoOrder) && valid? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.OrderNolist'),
                          placement: 'top-end'
                        }"
                        @click="ShowOrdenCollapse(true)"
                      >
                        <CIcon :class="(NoOrderOptions.length > 1 && valid && OrderCode == '')?'icon-toast':''" name="cil-playlist-add"/>
                      </CButton>
                      <CButton
                        v-else
                        :disabled="NoOrderOptions.length <= 1 || isEdit"
                        shape="square"
                        color="add"
                        size="sm"
                        class="pt-0 d-flex align-items-center rounded"
                        :style="NoOrderOptions.length > 1 && (!hasError($v.NoOrder) && valid)? 'border-color: #e55353' : ''"
                        v-c-tooltip="{
                          content: $t('label.OrderNolist'),
                          placement: 'top-end'
                        }"
                        @click="ShowOrdenCollapse(false)"
                      >
                        <CIcon :class="(NoOrderOptions.length > 1 && valid && OrderCode == '')?'icon-toast':''"  name="cil-playlist-add"/>
                      </CButton>
                    </div>
                  </template>
                </CInput>
                <div v-if="(NoOrderOptions.length > 1 && valid && OrderCode == '')">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.OrderNoAssociateBinMustSelected')}}
                  </div>
                </div>
                <div v-if="(valid && NoOrderOptions.length == 0)">
                  <div class="text-invalid-feedback mb-2">
                    {{$t('label.required')}}
                  </div>
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" class="px-0 " v-if="!Collapse">
            <div class="form-group form-row mb-0">
              <label class="col-form-label-sm col-sm-11 col-lg-5 col-xl-5 text-right required">{{$t('label.FECHA')}}</label>
              <div class="input-group col-sm-12 col-lg-6">
                <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model="TransactionDate"
                  :disabled-date="validateDateRange"
                  :append-to-body="false"
                  value-type="format"
                  :show-second="false"
                >
                  <template #input>
                    <CInput
                      v-uppercase
                      class="w-100 float-left"
                      :value="TransactionDate"
                      placeholder="DD/MM/YYYY HH:mm"
                      :is-valid="hasError($v.TransactionDate)"
                      @blur="$v.TransactionDate.$touch()"
                      size="sm"                      
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CSelect
            v-if="!Collapse"
            :horizontal="{ label: 'col-sm-12 col-lg-5 col-xl-5 text-right', input: 'col-sm-12 col-lg-6 col-xl-6'}"
            addLabelClasses="text-right required"
            size="sm"
            :label="$t('label.truckScale')"
            :options="truckScaleListFormatted"
            v-model.trim="$v.truckScale.$model"
            :value.sync="truckScale"
            :is-valid="hasError($v.truckScale)"
            :invalid-feedback="errorMessage($v.truckScale)"
            @change="filtertruckScale"
          />
          <div class="form-group form-row" v-if="!Collapse">
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.weight')+'(KGM)' }}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                v-uppercase
                v-model.trim="weightKGM"
                v-bind="measure" 
                :class="ValidateFormControl(weightKGM, 0)"
                :disabled="truckScale == '' ? true : false"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(weightKGM) < 2000) && (setNum(weightKGM) > 0) && scrapContainer != ''">
                {{ $t('label.minValueIs')+': '+formatNumber(2000) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(weightKGM) >= MaxWeight) && (truckScale != '')">
                {{ $t('label.maxValueIs')+': '+formatNumber(MaxWeight) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((weightKGM == 0 || weightKGM == '0,0000') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </div>
            
          </div>
          <div class="form-group form-row" v-if="!Collapse">
            <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.weight')+$t('label.metricTonnageAcronym') }}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                v-uppercase
                :disabled="true"
                v-model.trim="weightTON"
                v-bind="measure" 
                :class="ValidateFormControl(weightTON, 1)"
              >
              </money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(weightTON) < 2000/1000) && (setNum(weightTON) > 0) && scrapContainer != ''">
                {{ $t('label.minValueIs')+': '+formatNumber(2000/1000) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="(setNum(weightTON) >= (MaxWeight/1000)) && (truckScale != '')">
                {{ $t('label.maxValueIs')+': '+formatNumber(MaxWeight/1000) }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((weightTON == 0 || weightTON == '0,0000') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </div>
          </div>    
          <CTextarea
            v-if="!Collapse"
            size="sm"
            :label="$t('label.observation')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
            :placeholder="$t('label.Description')"
            v-uppercase
            :is-valid="hasError($v.Observation)"
            :invalid-feedback="errorMessage($v.Observation)"
            v-model.trim="$v.Observation.$model"
          />
        </CCol>
      </CRow>
      <CCollapse :show="Collapse">
        <CollapseTareOrOrder
          :showCollapse="Collapse"
          :Option="NoOrderOptions"
          :isTare="isTare"
          :BinAlias="BinAlias"
          :NoOrder="NoOrder"
          @set-NoOrder="ShowOrdenCollapse"
        />
      </CCollapse>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          type="submit"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal(false, false)"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import CollapseTareOrOrder from './full_weight/collapse-tare-or-orden';
import { VMoney, Money } from "v-money";
import { mapState } from 'vuex';
import tareWeighingValidation from '@/_validations/visitas/shipment_orders/Weighing/tareWeighingValidation';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    ValidPreviousDate: new Date(),
    ValidLaterDate: new Date(),
    previousDate: new Date(),
    laterDate: new Date(),
    IssueDate: '', 
    FinishDate: '',
    isTare: false,
    Collapse: false,
    NoOrder: '',
    NoOrderOptions: [],
    Transportation: '',
    vehicle: '',
    client: '',
    Observation: '',
    BinAlias: '',
    scrapContainer: '',
    scrapContainerOptions: [],
    truckScale: '',
    truckScaleOptions: [],
    MovementType: '',
    MovementOrderId: '',
    //MovementTypeOptions: [],
    weightKGM: 0,
    weightTON: 0,
    MaxWeight: 0,
    Yard: '',
    OrderCode: '',
    OrderStatusDs: '',
    ColorStatus: '',
    TransactionDate: '',
    StowagePlanningLogWeigthId: '',

    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 4,
      masked: false,
      maxlength: 16,
    },
    isSubmit: false,
    modalActive: false,
    valid: false,
    apiStateLoading: false,
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.apiStateLoading = true;
    this.$v.$touch();
    this.valid = true;
    let Max = setNum(this.weightKGM) > this.MaxWeight ? true : false;
    let Min = setNum(this.weightKGM) < 2000 ? true : false;
    if (this.$v.$error || Max || Min) {
      this.apiStateLoading = false;
      this.isSubmit = false;
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
        throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
        throw this.$t('label.ErrorEtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
        throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.IssueDate) {
          throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.FinishDate) {
          throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }
    let date = DateFormater.formatDateTimeWithoutSlash(this.TransactionDate);
    this.weightKGM = setNum(this.weightKGM);
    let LogisticJson = this.isEdit ? {
      StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
      OrderId: this.NoOrder,
      StowagePlanningImplementVehicleId: this.scrapContainer,
      MovementOrderId: this.MovementOrderId,
      TruckWeghingScaleId: this.truckScale,
      Weight: this.weightKGM,
      Observation: this.Observation,
      TransactionDate: date,
      Status: 1,
    }:{
      OrderId: this.NoOrder,
      StowagePlanningImplementVehicleId: this.scrapContainer,
      MovementOrderId: this.MovementOrderId,
      TruckWeghingScaleId: this.truckScale,
      Weight: this.weightKGM,
      Observation: this.Observation,
      TransactionDate: date,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'StowagePlanningLogisticWeigthOrder-update':'StowagePlanningLogisticWeigthOrder-insert';
    this.$http
      .ejecutar( metodo, ruta, LogisticJson, { root: 'LogisticJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.apiStateLoading = false;
          this.closeModal(false, true);
          this.notifySuccess({text: messageSuccess});
          this.isSubmit = false;
        } 
      }).catch((e) => {
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function ShowOrdenCollapse(collapse, event, Orden,  Code, Status, Color, IssueDate, FinishDate,) {
  if (!this.isEdit) {
    if(collapse){
      this.isTare=false;
      this.Collapse=true;
    }else{
      this.Collapse=false;
      if(Orden != '' && Orden != null && Code != '' && Code != null && Status != '' && Status != null && Color != '' && Color != null && event){
        this.NoOrder=Orden;
        this.OrderCode=Code;
        this.OrderStatusDs=Status;
        this.ColorStatus=Color;
        this.IssueDate = DateFormater.formatDateTimeWithoutSlash(IssueDate);
        this.FinishDate = FinishDate!='N/A'? DateFormater.formatDateTimeWithoutSlash(FinishDate) : this.ValidLaterDate;
      }
      if(event == false){
        this.NoOrder='';
        this.OrderCode='';
        this.OrderStatusDs='';
        this.ColorStatus='';
        this.IssueDate = this.ValidPreviousDate;
        this.FinishDate = this.ValidLaterDate;
      }
    }
  }
}

function closeModal(filtre, refresh) {
  this.$v.$reset();
  this.Collapse = false;
  this.valid = false;
  this.weightKGM = 0;
  this.weightTON = 0;
  this.MaxWeight = 0;
  this.TransactionDate = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.StowagePlanningLogWeigthId = '';
  this.NoOrder = '';
  this.NoOrderOptions = [];
  this.Transportation = '';
  this.vehicle = '';
  this.client = '';
  this.Observation = '';
  this.BinAlias= '';
  this.scrapContainer = '';
  this.scrapContainerOptions = [];
  this.truckScale = '';
  this.truckScaleOptions = [];
  this.MovementType = '';
  this.MovementOrderId = '';
  this.Yard = '';
  this.OrderCode = '';
  this.OrderStatusDs = '';
  this.ColorStatus = '';
  this.IssueDate = '';
  this.FinishDate = '';
  this.$emit('set-Weighing-list', filtre, refresh);
}

async function getList() {
  this.apiStateLoading = true;
  this.getDateRange();
  let _lang = this.$i18n.locale;
  let OrderName = '';
  let peticiones =  
    [
      //this.$http.ejecutar("GET", "ShipmentOrderActive-list", {VisitId: this.Visit}),
      this.$http.ejecutar("GET", "StowagePlanningImplementVehicleActive-list", {StowagePlanningId: this.StowagePlanningId,
        OrderId: this.isEdit ? this.TareItem.OrderId : ''}),
      this.$http.ejecutar("GET", "TruckWeighingScale-list", { CompanyBranchId: this.branch.CompanyBranchId }),
      this.$http.ejecutar("GET", "ShipmentMovementOrder-list", {VisitId: this.Visit}),
    ];

  Promise.all(peticiones)
    .then((responses) => {
      //this.NoOrderOptions = responses[0].data.data;
      this.scrapContainerOptions = responses[0].data.data;
      this.truckScaleOptions = responses[1].data.data;
      let List = responses[2].data.data;
      List.map((item) => {
        OrderName = _lang == 'en' ? item.MovementOrderNameEn : item.MovementOrderNameEs;
        if (OrderName == this.$t('label.Taraweight')) {
          this.MovementType = OrderName;
          this.MovementOrderId = item.MovementOrderId;
        }
      }); 
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if (this.isEdit) {
        this.getdata(this.TareItem);
      }else{
        this.$v.$reset();
        this.apiStateLoading = false;
      }
    });
}

function setNum(Num) {
  let New = [];
  let val = Num;
  if ( !isNaN(Num) ) {
    return Num;
  }
  else{
    New = val.split(',');
    let Punto = New[0].split('.');
    for(let index = 1; index < Punto.length; index++){
      val = val.replace(".", "");
    }
    val = val.replace(",", ".");
    return Number.parseFloat(val);
  } 
}

function StatusColor() {
  return { background: this.ColorStatus };
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function ValidateFormControl(value, WeightType) {
  value = setNum(value);
  let Max = WeightType == 0 ? this.MaxWeight : (this.MaxWeight/1000);
  let Min = WeightType == 0 ? 2000 : 2 ;
  if ((value == 0 && this.valid==true) || (value > Max && (this.truckScale != '')) || ((value < Min) && this.scrapContainer != '' && value > 0 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function filterscrapContainer(e) {
  this.scrapContainer=e.target.value;
  this.getScrapContainer();
}

function formatNumber(number) {
  if (number == "") return "0,0000";
  let format = Number.parseFloat(number).toFixed(4);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}

function filtertruckScale(e) {
  this.truckScale=e.target.value;
  this.getTruckScale();
}

function conversionKgTon(val) {
  let Ton = (val/1000);
  if(!Number.isInteger(Ton) && Ton >= 0.0001){
    let New = `${Ton}`;
    New = New.split('.');
    Ton = New[0]+'.'+New[1].substring(4, -1);
    Ton = Number.parseFloat(Ton);
  }
  if(Ton >= 0.0001){
    this.weightTON = Ton;
  }else{
    this.weightTON = 0;
  }
}

function getdata(val) {
  this.$v.$touch();
  this.valid = true;
  this.StowagePlanningLogWeigthId = val.StowagePlanningLogWeigthId;
  this.weightKGM = val.TareKgs;
  this.Observation = val.Observation;
  this.scrapContainer = val.StowagePlanningImplementVehicleId;
  this.truckScale = val.TruckWeghingScaleId;
  this.MovementType = val.MovementOrderName;
  this.MovementOrderId = val.MovementOrderId;
  this.NoOrder = val.OrderId ? val.OrderId : '';
  this.TransactionDate = val.FormatedDate;
  this.getTruckScale();
  this.getScrapContainer();

}

function getNoOrder() {
  let _lang = this.$i18n.locale;
  this.apiStateLoading = true;
  this.$http
    .get('ShipmentOrderActive-list', { VisitId: this.Visit, StowagePlanningImplementVehicleId: this.scrapContainer })
    .then((response) => {
      this.NoOrderOptions= response.data.data;
      if(this.isEdit){
        if(this.NoOrder != '' && this.NoOrder != null){ 
          this.NoOrderOptions.map((item) => {
            if (item.OrderId == this.NoOrder) {
              this.OrderCode = item.OrderCode;
              this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
              this.ColorStatus = item.ColorStatus;
              this.IssueDate = item.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.TransactionDate)) : '';
              this.FinishDate = item.FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.FinishDate)) : this.ValidLaterDate;
            }
          });
        }
      }else{
        this.NoOrder = '';
        this.OrderCode = '';
        this.OrderStatusDs = '';
        this.ColorStatus = '';
        this.IssueDate = this.ValidPreviousDate;
        this.FinishDate = this.ValidLaterDate;
        if (this.NoOrderOptions.length == 1) {
          this.NoOrder = this.NoOrderOptions[0].OrderId;
          this.OrderCode = this.NoOrderOptions[0].OrderCode;
          this.OrderStatusDs = _lang=='en' ? this.NoOrderOptions[0].OrderStatusDsEn : this.NoOrderOptions[0].OrderStatusDsEs;
          this.ColorStatus = this.NoOrderOptions[0].ColorStatus;
          this.IssueDate = this.NoOrderOptions[0].TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.NoOrderOptions[0].TransactionDate)) : '';
          this.FinishDate = this.NoOrderOptions[0].FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.NoOrderOptions[0].FinishDate)) : this.ValidLaterDate;
        } else {
          this.NoOrderOptions.map((item) => {
            if (item.OrderStatusDsEn == 'OPEN') {
              this.NoOrder = item.OrderId;
              this.OrderCode = item.OrderCode;
              this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
              this.ColorStatus = item.ColorStatus;
              this.IssueDate = item.TransactionDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.TransactionDate)) : '';
              this.FinishDate = item.FinishDate ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(item.FinishDate)) : this.ValidLaterDate;
            }
          });
        }
      }
      this.apiStateLoading = false;  
    }).catch((err) => {
      this.apiStateLoading = false;
      this.notifyError({text: err});
    })

  /*if(this.NoOrder != '' ){
    let _lang = this.$i18n.locale;
    this.NoOrderOptions.map((item) => {
      if (item.OrderId == this.NoOrder) {
        this.OrderCode = item.OrderCode;
        this.OrderStatusDs = _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs;
        this.ColorStatus = item.ColorStatus;
        this.scrapContainer = item.StowagePlanningImplementVehicleId;
        this.getScrapContainer();
      }
    });
  }else{
    this.scrapContainer = '';
    this.Transportation = '';
    this.vehicle = '';
    this.Yard = '';
    this.client = '';
  }*/
  
}

function getScrapContainer() {
  if (this.scrapContainer != '' && this.scrapContainer != null) {
    let Gamela = this.scrapContainerOptions.find(item => item.StowagePlanningImplementVehicleId==this.scrapContainer);
    if (Gamela) {
        this.BinAlias = Gamela.ImplementAlias;
        this.Transportation = Gamela.CarrierAlias ? Gamela.CarrierAlias : '';
        this.vehicle = Gamela.LicensePlate ? Gamela.LicensePlate : '';
        this.Yard = Gamela.YardName ? Gamela.YardName : '';
        this.client = Gamela.YardClientName ? Gamela.YardClientName : '';
        this.getNoOrder();
    } else {
      this.apiStateLoading = false;
    }
  }else{
    this.BinAlias = '';
    this.NoOrderOptions = [];
    this.NoOrder = '';
    this.OrderCode = '';
    this.OrderStatusDs = '';
    this.ColorStatus = '';
    this.Transportation = '';
    this.vehicle = '';
    this.Yard = '';
    this.client = '';
    this.IssueDate = this.ValidPreviousDate;
    this.FinishDate = this.ValidLaterDate;
  }
}

function getTruckScale() {
  if(this.truckScale != ''){
    this.truckScaleOptions.map((item) => {
      if (item.TruckWeghingScaleId == this.truckScale) {
        this.MaxWeight = item.MaxWeightKgm;
      }
    });
  }else{
    this.MaxWeight = 0;
  }
}

function getDateRange(){
  let currentDate = new Date();
  if(this.itinerarySelected.Eta){
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
  }

  if (this.itinerarySelected.Eta>this.itinerarySelected.Ata) {
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
  }

  if(this.itinerarySelected.Atd!=undefined){  
    this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
  }

  if(this.itinerarySelected.Atd==undefined){
    this.laterDate = currentDate;
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(currentDate);
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash((currentDate));
  }
  this.IssueDate = this.ValidPreviousDate;
  this.FinishDate = this.ValidLaterDate;
}

function truckScaleListFormatted(){
  if(this.truckScaleOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.truckScaleOptions.map(function(e){
      chart.push({
        value: e.TruckWeghingScaleId, 
        label: e.TruckWeghingScaleName,
      })    
    })
    return chart;
  }
}

/*function MovementTypeListFormatted(){
  if(this.MovementTypeOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MovementTypeOptions.map(function(e){
      chart.push({
        value: '', 
        label: '',
      })    
    })
    return chart;
  }
}*/

/*function NoOrderListFormatted(){
    if(this.NoOrderOptions.length === 0){
        return [{
          value: '', 
          label: this.$t('label.select'),
        }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.NoOrderOptions.map(function(e){
        chart.push({
          value: e.OrderId, 
          label: e.OrderCode,
        })
      })
      return chart;
    }
}*/

function scrapContainerFormatted(){
  if(this.scrapContainerOptions.length === 0){
    return [{
      alue: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.scrapContainerOptions.map(function(e){
      chart.push({
        value: e.StowagePlanningImplementVehicleId, 
        label: e.ImplementAlias,
      })     
    })
    return chart;
  }
}

function titleModal(){
  if(this.isEdit){
    let title = this.TareItem.OrderCode!='N/A' ? this.TareItem.OrderCode : this.TareItem.ImplementAlias;
    return this.$t('label.edit')+' '+this.$t('label.Taraweight')+': '+title;
  }else{
    return this.$t('label.nuevo')+' '+this.$t('label.Taraweight');
  }
}

export default {
  name: 'modal-tare-weight',
  props: { modal: Boolean, isEdit: Boolean, TareItem: Object, },
  data,
  mixins: [
    ModalMixin,
  ],
  validations(){
    return tareWeighingValidation(this.ValidPreviousDate, this.ValidLaterDate, this.IssueDate, this.FinishDate);
  },  
  directives: UpperCase,
  components: {
    Money,
    CollapseTareOrOrder,
  },
  computed: {
    StatusColor,
    titleModal,
    //MovementTypeListFormatted,
    truckScaleListFormatted,
    //NoOrderListFormatted,
    scrapContainerFormatted,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      branch: state => state.auth.branch,
      itinerarySelected: state => state.visitas.itinerarySelected,
      StowagePlanningId: state => state.visitas.StowagePlanningId,
    }),
  },
  methods: {
    getDateRange,
    validateDateRange,
    ShowOrdenCollapse,
    setNum,
    conversionKgTon,
    getTruckScale,
    getScrapContainer,
    getNoOrder,
    ValidateFormControl,
    formatNumber,
    filtertruckScale,
    filterscrapContainer,
    getList,
    getdata,
    closeModal,
    submitBody,
  },
  watch: {
    modal: async function(val){
      this.modalActive = val;
      if (val) {
        this.getList();
      }
    },
    weightKGM: async function(val){
      if (!isNaN(val)) {
        this.conversionKgTon(val);
      }
    },
    TransactionDate: async function(val){
      try{
        if(val != ''){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
            throw this.$t('label.ErrorAtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Ata==undefined) {
            throw this.$t('label.ErrorEtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
            throw this.$t('label.ErrorAtdPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
              throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
          }
          if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
              throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
 
};
</script>