<template>
  <CRow>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.role'),
          placement: 'top-end'
        }"
        @click="roleModal=true"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #Row="{ item }">
          <td class="center-cell">
            {{ item.Row }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="py-2 center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.role'),
                  placement: 'top-end'
                }"
                @click="roleModal=item"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
    <ModalRoles :modal="roleModal" @closeModal="roleModal=false" @child-refresh="getRolesList" />
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalRoles from './modal-role';

//Funcion data() del ciclo.
function fields() { 
  return [
    { 
      key: 'Row', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center'
    },
    { key: 'StowageRoleName', label:this.$t('label.role'), _style:'width:30%'},
    { key: 'ApplyTablet', label:this.$t('label.applySigetMobile'), _style:'width:15%'},
    { key: 'Supervisor', label:this.$t('label.supervisor'), _style:'width:10%'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 15%;'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 15%;',_classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      sorter: false, 
      filter: false,
      _style: 'width:5%; min-width:45px;',
      _classes: 'text-center'
    }
  ];
}


function data() {
  return {
    items: [],
    turno: {},
    activePage: 1,
    isLoading: false,
    roleModal: false,
  };
}

//Created
function created() {
  this.getRolesList();
}

//Methods:
function getRolesList() {
  this.isLoading = true;

  this.$http.get("StowageRole-list", { filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function handleSubmit(){
  this.getRolesList();
}

//Computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    Row: item.Nro,
    ApplyTablet: item.FgApplyTablet ? this.$t('label.yes') : 'NO',
    FgApplyTablet: item.FgApplyTablet ? item.FgApplyTablet : '',
    Supervisor: item.FgSupervisor ? this.$t('label.yes') : 'NO',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'roles',
  mixins: [GeneralMixin],
  components: {
    ModalRoles,
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data,
  created,
  methods: {
    getRolesList,
    handleSubmit
  },
  computed: {
    fields,
    formatedItems
  },
  watch: {
    active: function(newVal) {
      if(newVal==1)
        this.getRolesList(); }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>