var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.dispatchList')}`)+" ")],1)]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1)],1),_c('CRow',{staticClass:"mb-3"},[_c('CCol',{attrs:{"sm":"12"}},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":_vm.tabIndex},on:{"update:activeTab":_vm.handleTab}},[_c('CTab',{attrs:{"title":_vm.$t('label.packaging')}},[_c('CRow',{staticClass:"my-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"12","xl":"12"}},[(_vm.fgCertificate && _vm.YardId && _vm.validateTypeStatus && this.dataContainers?.length == 0 && _vm.verifyContainers)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content: _vm.$t('label.dispatch'),
                                        placement: 'top-end'
                                    }),expression:"{\n                                        content: $t('label.dispatch'),\n                                        placement: 'top-end'\n                                    }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){_vm.showIndex=1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1):_vm._e()],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListReceptionBl,"fields":_vm.blFields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},on:{"filtered-items-change":(List)=> this.FilterList = [...List]},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.Status != 'INACTIVO' && item.Status != 'INACTIVE' )?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                    content: _vm.$t('label.dispatch'),
                                                    placement: 'top-start'
                                                }),expression:"{\n                                                    content: $t('label.dispatch'),\n                                                    placement: 'top-start'\n                                                }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.togglePackagingAdd(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                    content: _vm.$t('label.DispatchOrder'),
                                                    placement: 'top-start'
                                                }),expression:"{\n                                                    content: $t('label.DispatchOrder'),\n                                                    placement: 'top-start'\n                                                }"}],attrs:{"size":"sm","square":"","color":"danger"},on:{"click":function($event){return _vm.togglePdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}},{key:"Weight",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.NumberFormat(item.Weight, 2))+" ")])]}}])})],1)],1)],1),_c('CTab',{attrs:{"title":_vm.$t('label.Containers')}},[_c('div',{staticClass:"my-3"},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"6"}}),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6","xl":"6"}},[(this.dataContainers?.length>0 && _vm.verifyContainers)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                            content: _vm.$t('label.dispatch'),
                                            placement: 'top-end'
                                        }),expression:"{\n                                            content: $t('label.dispatch'),\n                                            placement: 'top-end'\n                                        }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":()=>{this.isDispatchContainer = true, _vm.showIndex = 1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1):_vm._e()],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedContainerListContainer,"fields":_vm.Containerfields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},on:{"filtered-items-change":(List)=> this.FilterList = [...List]},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.Status != 'INACTIVO' && item.Status != 'INACTIVE' )?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.edit'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.edit'),\n                                                        placement: 'top-start'\n                                                    }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleContainerEdit(item, true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.DispatchOrder'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.DispatchOrder'),\n                                                        placement: 'top-start'\n                                                    }"}],attrs:{"size":"sm","square":"","color":"danger"},on:{"click":function($event){return _vm.togglePdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}},{key:"Weight",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.NumberFormat(item.Weight, 2))+" ")])]}}])})],1)],1)],1)])],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('BlDispatch',{directives:[{name:"show",rawName:"v-show",value:(!this.isDispatchContainer),expression:"!this.isDispatchContainer"}],attrs:{"Active":_vm.showIndex==1 && !this.isDispatchContainer,"ContainerItem":_vm.ContainerItem,"isEdit":_vm.isEdit},on:{"Close":function($event){_vm.showIndex=0, _vm.ContainerItem={}, _vm.isEdit=false},"Update-list":_vm.Update}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContainerIndex==0 && this.isDispatchContainer),expression:"showContainerIndex==0 && this.isDispatchContainer"}],staticClass:"my-3"},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-start justify-content-start",attrs:{"sm":"12","lg":"6"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.return'),
                            placement: 'top',
                        }),expression:"{\n                            content: $t('label.return'),\n                            placement: 'top',\n                        }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":()=> {_vm.showIndex=0}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('CRow',{staticClass:"align-items-center justify-content-start w-100 m-0"},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.ContainersInInventory')}`)+" ")],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedContainerInventoryItems,"fields":_vm.fieldsContainerInventory,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"number",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content: _vm.$t('label.dispatchContainer'),
                                        placement: 'top-start'
                                    }),expression:"{\n                                        content: $t('label.dispatchContainer'),\n                                        placement: 'top-start'\n                                    }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleContainer(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1)],1)]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContainerIndex==1 && this.isDispatchContainer),expression:"showContainerIndex==1 && this.isDispatchContainer"}]},[_c('DispatchRegister',{directives:[{name:"show",rawName:"v-show",value:(this.isDispatchContainer && _vm.showContainerIndex==1),expression:"this.isDispatchContainer && showContainerIndex==1"}],attrs:{"Active":_vm.showIndex==1 && this.isDispatchContainer && _vm.showContainerIndex == 1,"ContainerItem":_vm.ContainerItem,"isEdit":_vm.isEdit},on:{"Close":function($event){_vm.isDispatchContainer = false, _vm.showIndex=0, _vm.ContainerItem={}, _vm.showContainerIndex=0},"Update-list":_vm.Update}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }