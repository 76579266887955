<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.scrapReception')}}</b>
    </CCardHeader>
    <CCardBody>
      <CustomTabs :active-tab="activeTab" @update:activeTab="handleTab">
        <CustomTab icon="general" :title="$t('label.entryOrders')" class="pb-3">
          <manual :activeTab="activeTab"/>
        </CustomTab>
        <CustomTab icon="general" :title="$t('label.massiveLoading')" class="pb-3">
          <massive :activeTab="activeTab"/>
        </CustomTab>
        <CustomTab icon="general" :title="$t('label.historic')" class="pb-3">
          <historical :activeTab="activeTab"/>
        </CustomTab>
      </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import manual from './manual-load-tab';
import massive from './massive-load-tab';
import historical from './historic-tab';

function data() {
  return {
    activeTab: 0,
  };
}

function handleTab(tab) {
  this.activeTab = tab;
}

export default {
  name: 'entry-orders',
  data,
  components: {
    CustomTabs,
    CustomTab,
    manual,
    massive,
    historical,
  },
  methods: {
    handleTab,
  }
};
</script>
<style lang=""></style>