<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.notifyParty')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToName.$model"
        :is-valid="hasError($v.Notify.NotifyToName)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToName)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.address')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToAddress.$model"
        :is-valid="hasError($v.Notify.NotifyToAddress)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToAddress)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.mainPhone')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToPrimaryPhone.$model"
        :is-valid="hasError($v.Notify.NotifyToPrimaryPhone)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToPrimaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5" >
      <CInput
        size="sm"
        :label="$t('label.secondaryPhone')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToSecondaryPhone.$model"
        :is-valid="hasError($v.Notify.NotifyToSecondaryPhone)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToSecondaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.mainEmail')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToPrimaryEmail.$model"
        :is-valid="hasError($v.Notify.NotifyToPrimaryEmail)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToPrimaryEmail)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        size="sm"
        :label="$t('label.secondaryEmail')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        v-uppercase
        addLabelClasses="text-right"
        v-model="$v.Notify.NotifyToSecondaryEmail.$model"
        :is-valid="hasError($v.Notify.NotifyToSecondaryEmail)"
        :invalid-feedback="errorMessage($v.Notify.NotifyToSecondaryEmail)"
      />
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
  
function data() {
  return {

  };
}
  
export default {
  name: 'notify-tab',
  components: {
  },
  data,
  props: {
  Notify: {
      type: Object,
      required: true,
      default: () => {},
  },
    isEdit: Boolean,
    showModal: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations();
  },
  methods: {

  },
  computed:{

  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.Notify.$touch();
      }else{
        this.$v.$reset();
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.Notify.$touch();
      }
    }
  }
};
</script>