<template>
  <CRow class="mt-3">
    <loading-overlay
      :active="isLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CCol sm="5" class="form-inline">
      <label class="mr-2">
        {{$t('label.movement')}}:
      </label>
      <v-select 
        :options="movimientosOptions"
        label="label"
        v-model="movimiento"
        :reduce="option => option.value"
        :placeholder="$t('label.select')"
        class="w-75"
      />
    </CCol>
     <CCol sm="5" class="form-inline">
      <label class="mr-2">
        {{$t('label.transaction')}}:
      </label>
      <v-select 
        :options="transaccionesOptions"
        label="label"
        v-model="transaccion"
        :reduce="option => option.value"
        :placeholder="$t('label.select')"
        class="w-75"
      />
    </CCol>
    <CCol sm="2" class="d-flex justify-content-end align-items-center">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.CancellationReason'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
     class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination    
      >
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="py-2 center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.CancellationReason'),
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>

    <add-cancelacion-modal :modal.sync="modalAdd" @submited="handleSubmit"/>
    <edit-cancelacion-modal :modal.sync="modalEdit" :CancelacionData="cancelacion" @submited="handleSubmit"/>
  </CRow>
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import AddCancelacionModal from './add-cancelacion-modal';
import EditCancelacionModal from './edit-cancelacion-modal';

function data() {
  return {
    items: [],
    cancelacion: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    movimientos: [],
    movimiento: '',
    transacciones: [],
    transaccion: ''
  };
}

//Methods:
function getCancelaciones(MovStowageId, TransacStowageId) {

  this.isLoading      = true;

  MovStowageId        = MovStowageId ? MovStowageId : '';
  TransacStowageId    = TransacStowageId ? TransacStowageId : '';

 this.$http.get("MovStowageReason-list", { TransacStowageId: TransacStowageId, 
 MovStowageReasonId: MovStowageId, filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function getList(MovStowageId, TransacStowageId) {
  this.isLoading = true;
  MovStowageId        = MovStowageId ? MovStowageId : '';
  TransacStowageId    = TransacStowageId ? TransacStowageId : '';
  let requests =  
    [
      this.$http.ejecutar("GET", "MovStowage-list", { Filter: 'ACTIVO' }),
      this.$http.ejecutar("GET", "MovStowageReason-list", { TransacStowageId: TransacStowageId, 
        MovStowageReasonId: MovStowageId, filter: 'ALL' }),
    ];
  Promise.all(requests)
    .then((responses) => {
      this.movimientos = responses[0].data.data;
      this.items = responses[1].data.data;
      this.isLoading = false;
    })
    .catch((err) => {
      this.isLoading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function getListCancellationsAndTransactions(MovStowageId, TransacStowageId) {
  this.isLoading = true;
  MovStowageId        = MovStowageId ? MovStowageId : '';
  TransacStowageId    = TransacStowageId ? TransacStowageId : '';
  let requests =  
    [
      this.$http.ejecutar("GET", "TransacStowage-list", { MovStowageId: this.movimiento, Filter: 'ACTIVO' }),
      this.$http.ejecutar("GET", "MovStowageReason-list", { TransacStowageId: TransacStowageId, 
        MovStowageReasonId: MovStowageId, filter: 'ALL' }),
    ];
  Promise.all(requests)
    .then((responses) => {
      this.transacciones = responses[0].data.data;
      this.items = responses[1].data.data;
      this.isLoading = false;
    })
    .catch((err) => {
      this.isLoading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item) {
  this.cancelacion = Object.assign({}, item);
  this.modalEdit = true;
}

function handleSubmit(){
  this.getList();
}

//Computed:
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,  _style: 'width:1%; text-align:center;',
    },
    { key: 'MovStowageReasonNameEs',label:this.$t('label.CancellationReason')+' (ES)', _style:'width:30%;', _classes:"text-uppercase text-center"},
    { key: 'MovStowageReasonName', label:this.$t('label.CancellationReason')+' (EN)', _style:'width:30%;',_classes:"text-uppercase text-center"},
    { key: 'MovStowageName', label:this.$t('label.movement'), _style:'width:20%;',_classes:"text-uppercase text-center"},
    { key: 'TransacStowageName',label:this.$t('label.transaction'), _style:'width:20%;', _classes:"text-uppercase text-center"},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:17%;', _classes:"text-uppercase text-center"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:17%;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style:'width:17%;', _classes:"text-center"},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}
function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    MovName: _lang=='en'? item.MovStowageName : item.MovStowageNameEs,
    TransacName: _lang=='en'? item.TransacStowageName : item.TransacStowageNameEs,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function movimientosOptions() {
  let _this = this.$i18n.locale;
  return this.movimientos.map((item) => Object.assign({}, item, {
    value: item.MovStowageId,
    label: _this == 'en' ? item.MovStowageName : item.MovStowageNameEs,
  }));
}
function transaccionesOptions() {
  let _this = this.$i18n.locale;
  return this.transacciones.map((item) => Object.assign({}, item, {
    value: item.TransacStowageId,
    label: _this == 'en' ? item.TransacStowageName : item.TransacStowageNameEs,
  }));
}
export default {
  name: 'motivo-cancelacion-index',
  mixins: [GeneralMixin],
  components: { AddCancelacionModal, EditCancelacionModal },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  methods: {
    getList,
    getCancelaciones,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    getListCancellationsAndTransactions,
  },
  computed: {
    fields,
    formatedItems,
    movimientosOptions,
    transaccionesOptions,
  },
  watch: {
    movimiento: function(newMov,OldMov) {
      if(newMov){
        this.transaccion = '';
        this.getListCancellationsAndTransactions(newMov);
      }else{
        if (OldMov) {
          this.transaccion = '';
          this.transacciones = [];
          this.getCancelaciones();
        }
      }
    },
    transaccion: function(newMov) {
      if(newMov){
        this.getCancelaciones(this.movimiento, newMov);
      }else{
        if (this.movimiento&&newMov) {
          this.getCancelaciones(this.movimiento?this.movimiento:null);
        }
      }
    },
    active: function(newVal) {
      if(newVal){
        this.getList();
      }
    }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>