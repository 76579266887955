<template>
  <div>
    <CCol v-if="!fgInfo" sm="12" class="my-2">
      <vue-dropzone 
        ref="myVueDropzone" 
        id="dropzone" 
        size="4"
        :options="dropzoneOptions" 
        :useCustomSlot="true"
        v-on:vdropzone-files-added="sendingEvent"
        v-on:vdropzone-removed-file="deleteFile"
        v-on:vdropzone-error="DropzoneError"
      > 
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
          <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
        </div>
      </vue-dropzone>
    </CCol>

    <CRow v-if="!fgInfo" class="m-0 mt-4">
      <CCol sm="11" lg="4">
        <CSelect
          :label="$t('label.documentType')"
          horizontal
          addLabelClasses="required text-right" 
          v-model.trim="$v.document.PortActivictyDocumentCargoId.$model"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :is-valid="hasError($v.document.PortActivictyDocumentCargoId)"
          :invalid-feedback="errorMessage($v.document.PortActivictyDocumentCargoId)"
          class="remove-space"
          :options="documentTypeOptions"
          :value.sync="document.PortActivictyDocumentCargoId"
        >
        </CSelect>
      </CCol>
      <CCol sm="11" lg="5">
        <CInput
          :label="$t('label.description')"
          horizontal
          addLabelClasses="required text-right"
          :placeholder="$t('label.documentDescription')"
          v-uppercase
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}" 
          v-model="$v.document.BlCargoDocumentDs.$model"
          :is-valid="hasError($v.document.BlCargoDocumentDs)"
          :invalid-feedback="errorMessage($v.document.BlCargoDocumentDs)"
          class="remove-space"
        >
        </CInput>
      </CCol>
      <CCol sm="1" lg="3"  class="d-flex align-items-start justify-content-end">
        <CButton 
          color="add"
          size="sm"
          v-c-tooltip="{
              content: $t('label.add'),
              placement: 'top-end'
          }"
          :disabled="isSubmitDocument"
          @click="submitDocument()" 
        >
          <CIcon name="checkAlt"/>
        </CButton>
      </CCol>
    </CRow>

    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedListDocument"
      :fields="documentfields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >

      <template #Status="{ item }">
        <td class="text-center">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #DocsRoute="{item}">
        <td class="py-2 text-center">
          <CButton
            class="btn btn-sm btn-watch mr-1"
            color="excel"
            v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
            target="_blank"
            :href="item.DocsRoute"
          >
            <CIcon name="cil-cloud-download" />
          </CButton>
          <CButton
            v-if="!fgInfo"
            class="btn btn-sm btn-wipe"
            v-c-tooltip="{
              content: $t('label.delete')+$t('label.document'),
              placement: 'top-end',
            }"
            @click="deleteDocument(item)"
          >
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>

    </dataTableExtended>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import ModalBlFormValidations from '@/_validations/yard-management/movements/modalBlFormValidations';
import UpperCase from '@/_validations/uppercase-directive';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapState } from 'vuex';
import { Money } from "v-money";

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    isSubmitDocument: false,
    documentTypeItems: [],
    document:{
      BlCargoDocumentId: '',
      PortActivictyDocumentCargoId: '',
      BlCargoDocumentDs: '',
      Status: 1,
      RouteBlCargoDocument: ''
    },
    documentItems: [],
    docFile: '',
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    },
  };
}

//-----------------------   Method    ------------------------

async function resetForm() {
  //document
  this.document.PortActivictyDocumentCargoId = '';
  this.document.BlCargoDocumentDs = '';
  this.document.RouteBlCargoDocument = '';
  this.deleteFile()
  this.Refresh()

  //data array
  this.documentItems = []
  this.documentTypeItems = [];
  this.collapse = false;
}

function sendingEvent(files){
  this.$refs.myVueDropzone.removeFile(files);
  if(files[0].name) this.docFile = files[0];
}

function deleteFile(file) {
  this.docFile = null;
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function Refresh() {
  if (!this.fgInfo) {
    let files = this.$refs.myVueDropzone.getQueuedFiles();

    if(files.length != 0){
      this.$refs.myVueDropzone.removeFile(files[0]);
    }
  }
  
  this.document.PortActivictyDocumentCargoId = '';
  this.document.BlCargoDocumentDs = '';
  this.document.RouteBlCargoDocument = '',
  this.document.FgActCargoDocument = 1;
  this.$v.$reset();
}

function deleteDocument(item) {
  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteQuestion')}?`,
      }))
    .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      this.$http.delete('files', item.RouteBlCargoDocument)
        .then(async(response) => {
          this.documentItems = this.documentItems.filter(e => e.IdX != item.IdX);
          this.$emit('documentItems', this.documentItems)
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

function getBadge(status) {
  return status === 'Active' ? 'success' :'danger'
}

function submitDocument() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmitDocument = true;
    this.$v.document.$touch();
    let files = this.$refs.myVueDropzone.getQueuedFiles();
    if (files.length === 0) {
      this.$store.state.yardManagement.loading = false;
      this.isSubmitDocument = false;
      throw this.$t('label.documentQuestion');
    }
    if (this.$v.document.$error) {
      this.$store.state.yardManagement.loading = false;
      this.isSubmitDocument = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.$http.file('BlCargo-file', files[0])
      .then(response => {
        this.document.RouteBlCargoDocument = response.data.data.files[0].path;
        this.$store.state.yardManagement.loading = false;
        this.documentItems.push({
          IdX: this.documentItems.length > 0 ? this.documentItems[this.documentItems.length - 1].IdX + 1 : 1,
          BlCargoDocumentId: this.document.BlCargoDocumentId,
          PortActivictyDocumentCargoId: this.document.PortActivictyDocumentCargoId,
          BlCargoDocumentDs: this.document.BlCargoDocumentDs,
          RouteBlCargoDocument: this.document.RouteBlCargoDocument,
          Status: this.document.Status
        });
        this.isSubmitDocument = false;
        this.$store.state.yardManagement.loading = false;
        this.Refresh()
        this.$emit('documentItems', this.documentItems)
        
      }).catch((err) => {
        this.isSubmitDocument = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmitDocument = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: e});
  }
}

function getDocumentTypeList() {
  this.$store.state.yardManagement.loading = true;
  this.$http
  .get('TpDocumentYardCargo-list',{ TpCargoId: this.form.dataBasic.TpCargoId, PortActivityId: this.form.dataBasic.PortActivityId})
  .then(response => {
    let list = response.data.data;
    if (list && list.length != 0){
      this.documentTypeItems = list;
    }
  })        
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

//-----------------------   Computed   ------------------------

function documentTypeOptions () {
  if(this.documentTypeItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.documentTypeItems.map(function(e){
      chart.push({
        value: e.PortAtivictyDocumentCargoId, 
        label: e.StandardDocumentName,
      })    
    })
    return chart;
  }
}

function computedListDocument() {
  if(this.documentItems == []){
    return this.documentItems;
  }
  return this.documentItems.map((item) => {
    return {
      ...item,
      DocsRoute: item.RouteBlCargoDocument
    ? `${
        this.$store.getters["connection/getBase"]
      }${item.RouteBlCargoDocument.replace("Public/", "", "i")}`
    : "N/A",
    };
  });
}

function documentfields(){
  return [
    { key: 'IdX', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'BlCargoDocumentDs', label: this.$t('label.documents'),  _classes: 'text-uppercase text-center', _style: 'width:90%;', sorter: true, filter: true},
    { key: 'DocsRoute', label: '', _style: `width:5%; min-width: ${this.fgInfo ? '45px' : '90px'}`, _classes: 'text-center', sorter: false, filter: false },
  ];
}

export default {
  name: 'documentation-tab',
  components: {
      Money,
      vueDropzone: vue2Dropzone,
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
    fgInfo: Boolean,
    Items: Array
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return ModalBlFormValidations(this.form.customBroke.consolidatorCheck,this.validCargoMaster);
  },
  methods: {
    sendingEvent,
    deleteFile,
    DropzoneError,
    deleteDocument,
    getBadge,
    submitDocument,
    Refresh,
    resetForm,
    getDocumentTypeList
  },
  computed:{
    documentfields,
    documentTypeOptions,
    computedListDocument,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      loading: state => state.yardManagement.loading,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
        this.$v.$reset();
      }
    },
    Tab: async function (Newval) {
      if (Newval){
        this.documentItems = this.Items;
        this.documentTypeItems = [];
        if (!this.fgInfo && this.form.dataBasic.TpCargoId && this.form.dataBasic.PortActivityId) {
          await this.getDocumentTypeList();
        }
      }  
    },
  }
};
</script>