<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            class="modal-extended"
            :show.sync="$store.state.contenedores.modalVista"
            :title="tituloModal"
            :close-on-backdrop="false"
        >
            <CRow>
                  <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.group')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :value="formVista.TpCargoId"
                        :addInputClasses="{ 'is-invalid': $v.formVista.TpCargoId.$error }"
                        @blur="$v.formVista.TpCargoId.$touch()"
                        @input="formVista.TpCargoId = $event.target.value"
                        :options="optionList"
                        :invalid-feedback="errorMessage($v.formVista.TpCargoId)"
                        :is-valid="hasError($v.formVista.TpCargoId)"
                    >
                    </CSelect>
                </CCol>
                <CCol sm="12" lg="12" >
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="$t('label.viewName')"
                        v-model="$v.formVista.TpCargoViewName.$model"
                        @blur="$v.formVista.TpCargoViewName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formVista.TpCargoViewName.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formVista.TpCargoViewName)"
                        :is-valid="hasError($v.formVista.TpCargoViewName)"
                    >
                    </CInput>
                </CCol>
                  <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :is-valid="formVista.FgActTpCargoView"
                        v-if="!idIndentification"
                        v-model="formVista.FgActTpCargoView"
                        :value.sync="formVista.FgActTpCargoView"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="Vista" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormVista } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    //data
    function data() {
        return {
            formVista:{
                TpCargoId:'',
                TpCargoViewId:0,
                TpCargoViewName:'',
                FgActTpCargoView:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nueva')+' '+this.$t('label.view'),
            selectOptions: [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ],
            loading:false,
        }
    }
    //methods
    function cambio(e){
        this.formVista.FgActTpCargoView = !this.formVista.FgActTpCargoView;
    }
    function cerrarModal(){
        if(this.apiStateFormLoading || this.loading)
            return
        this.$store.state.contenedores.modalVista = false;
    }
    function registerData(){
        try { 
                 this.$v.formVista.$touch();
                 if (this.$v.formVista.$pending || this.$v.formVista.$error) {
                        throw this.$t('label.errorsPleaseCheck');
             } 

                let { TpCargoViewId, TpCargoId, TpCargoViewName, FgActTpCargoView } = this.formVista;
                const { dispatch } = this.$store;
                if(this.originalAct !== '' && TpCargoViewId !== 0){
                    if(this.originalAct !== FgActTpCargoView){
                        this.$swal.fire(
                                alertPropertiesHelpers(this, {
                                    text: `${this.$t('label.changeStatusQuestion')+' '} ${TpCargoViewName}?`,
                                })
                            ).then((result) => {
                            if (result.isConfirmed) {
                                dispatch('contenedores/dataView', { TpCargoViewId, TpCargoId, TpCargoViewName, FgActTpCargoView });
                            }else {
                                //this.$swal(this.$t('label.operationCancelled'));
                            }
                        })
                    }else{
                        dispatch('contenedores/dataView', { TpCargoViewId, TpCargoId, TpCargoViewName, FgActTpCargoView });
                    }
                }else{
                    dispatch('contenedores/dataView', { TpCargoViewId, TpCargoId, TpCargoViewName, FgActTpCargoView });
                }
                
        } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    //computed
    function idIndentification(){
        return this.formVista.TpCargoViewId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionList(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }

    //watch
    function modalVista(newQuestion){
        if(newQuestion === false){
            this.formVista.TpCargoViewId=0;
            this.formVista.TpCargoViewName='';
            this.formVista.TpCargoId = '';
            this.originalAct = '';
            this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.view');
            this.formVista.FgActTpCargoView=true;
            this.$store.state.contenedores.filtroCargo = '';

            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            this.tituloModal =this.$t('label.nueva')+' '+this.$t('label.view');
            if(this.idState !== 0){
                const id = this.idState;
                this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'TpCargoView-by-id',{ TpCargoViewId: id }, '' ).then(response => {
                    try {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                        
                                this.tituloModal = this.$t('label.edit')+' '+this.$t('label.view')+': '+Information[0].TpCargoViewName;
                                this.formVista.TpCargoViewId= Information[0].TpCargoViewId;
                                this.formVista.TpCargoId= Information[0].TpCargoId;
                                this.formVista.TpCargoViewName= Information[0].TpCargoViewName;
                                this.originalAct = Information[0].FgActTpCargoView;
                                this.formVista.FgActTpCargoView= Information[0].FgActTpCargoView;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }
                            this.$v.$touch();
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalVista = false;
                        }
                    } catch (error) {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalVista = false;
                        this.$store.commit('contenedores/messageMutation', err);
                    }
                }).catch(err => {
                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                    this.$store.state.contenedores.modalVista = false;
                    this.$store.commit('contenedores/messageMutation', err);
                });
            }else{
                if(this.filtroCargo !== ''){
                    this.formVista.TpCargoId= this.filtroCargo;
                }
            }
        }
    }

    export default {
        name: 'modal-view',
        data,
        validations(){ return FormVista() },
        methods:{
            registerData,
            cambio,
            cerrarModal,
            //errorMessage,
        },
        mixins:[GeneralMixin],
        directives: UpperCase,
        computed:{
            idIndentification,
            optionList,
            apiStateFormLoading,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalVista: state=> state.contenedores.modalVista,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                filtroCargo: state => state.contenedores.filtroCargo,
            })
        },
        watch:{
            modalVista
        }
    }
</script>
