<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      class="modal-content-tp-vehiculo"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow  class="mt-2">
        <CCol sm="12">
          <CInput
            :label="$t('label.tpVehicleName')"
            :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
            add-label-classes="required text-right"
            v-uppercase
            :invalid-feedback="errorMessage($v.TpVehicleName)"
            :is-valid="hasError($v.TpVehicleName)"
            v-model.trim="$v.TpVehicleName.$model"
            :value.sync="newVehicleType"
            :placeholder="$t('label.tpVehicleName')"
          />
        </CCol>
      </CRow>

      <CRow v-if="editModal">
        <CCol sm="12">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
            add-label-classes="required text-right"
            v-uppercase
            :options="optionsStatus"
            :invalid-feedback="errorMessage($v.Status)"
            :is-valid="statusSelectColor()"            
            v-model.trim="$v.Status.$model"
            :value.sync="Status"     
          />
        </CCol>
      </CRow>

      <template #footer>

        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit, vehicleType+' '+newVehicleType):submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>

      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';
import tpVehiculo from '@/_validations/chofer-vehiculo/tpVehiculo';

function data() {
  return {
    modalActive: false,
    loadingOverlay: false,
    loadingTable: false,
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    Status: 1,
    originStatus: '',
    newVehicleType: '',
    TpVehicleName: '',
    TpVehicleId: '',
    isSubmit: false,  
  };
}

//### Function para cerrar el modal ###

function selectedStatus(event) {
  this.Status = event.target.value;
  //console.log(this.Status);
}

function buttonEnable() {
  if (this.TpVehicleName === '' || this.TpVehicleName === null) {
    return true;
  } else {
    return false;
  }
}

function vehicleType() {
  return this.$t('label.vehicleType');
}
//### Function para guardar informacion del tipo de vehiculo###

async function submit(){
  try {
   // this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
     // this.loadingOverlay = false; 
     this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    
    const TpVehicleJson = {
      TpVehicleName: this.TpVehicleName,
    };
    
    if(this.editModal){
      TpVehicleJson.Status= this.Status;
      TpVehicleJson.TpVehicleId= this.TpVehicleId;
    };

    let method = this.editModal? 'PUT':'POST';
    let ruta = this.editModal? 'TpVehicle-update':'TpVehicle-insert';

    await this.$http
      .ejecutar (method, ruta, TpVehicleJson, { root: 'TpVehicleJson' })

      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
         // this.loadingOverlay = false;
          this.toggle(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
       // this.loadingOverlay = false;
        this.notifyError({ text: e });
      }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    //this.loadingOverlay = false;
    this.notifyError({ text: e });

  }
}

function resetForm(event) {
  this.TpVehicleId = '';
  this.TpVehicleName = ''; 
  this.Status = 1;
  this.openDate = false;
  this.$v.$reset();
  this.$emit('updated-modal', event);
}

export default {
  name: 'modalTpVehiculo',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    tpVehicleSelected: Object,
  },
  mixins: [GeneralMixin, ModalMixin],
  directives: UpperCase,
  watch: {
    modal: function(val) {
      this.modalActive = val;
      if(this.editModal)
        this.$v.$touch();
    },
    tpVehicleSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.TpVehicleName = val.TpVehicleName;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.TpVehicleId = val.TpVehicleId;

        this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
        this.newVehicleType= val.TpVehicleName;
      }
    },
  },
  methods: {
    selectedStatus,
    resetForm,
    submit,

  },
  validations: tpVehiculo,
  computed: {
    buttonEnable,
    vehicleType,
  },
};
</script>
<style lang="scss">
.modal-content-tp-vehiculo {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>
