<template>
  <div>
  <!--<CCard>
    <CCardHeader class="text-center bg-dark text-white">
        <b>{{ titulo }}</b>
    </CCardHeader>
    <CCardBody>-->
     <!-- <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />-->
    <CCard>
      <CCardHeader color="dark text-white">
        <CRow>
          <CCol sm="8" class="d-flex ">{{titulo}}</CCol>
          <CCol sm="4" class="d-flex justify-content-end">
            <CButton size="sm" 
              @click="tablaCollapse = !tablaCollapse" 
                v-c-tooltip="{
                  content: $t('label.dataTable'),
                  placement: 'top-end'
              }"
              >
              {{ cambiaTexto }}
              <CIcon :name="cambiaIcono"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
      <CRow>
          <CCol sm="9">
            <CInput
              v-model.trim="$v.rol.StowageRoleName.$model"
              v-uppercase
              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
              :invalid-feedback="errorMessage($v.rol.StowageRoleName)"
              :is-valid="hasError($v.rol.StowageRoleName)"
              size="sm"
              :label="$t('label.name')"
              :placeholder="$t('label.role')+' '+$t('label.name')"
              addLabelClasses="required text-right"
            />
          </CCol>
          <CCol sm="3" >                
            <CButton color="add" :disabled="isSubmit" size="sm" class="mr-1"  
              @click="submit()"  
              v-c-tooltip="{
                  content: $t('label.grabar'),
                  placement: 'top-end'
              }"
              >
              <CIcon name="checkAlt"/>
            </CButton>
            <CButton color="wipe" :disabled="isSubmit" size="sm" class="mr-1"  
              @click="limpiarDatos()"  
              v-c-tooltip="{
                  content: $t('label.clearFields'),
                  placement: 'top-end'
              }"
              >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
          <CCol sm="9"> 
            <CSelect
              :label="$t('label.applySigetMobile')"
              :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
              add-label-classes="text-right"
              v-uppercase
              :options="selectOptionsFgApplyTablet"
              :value.sync="rol.FgApplyTablet"
              v-model.trim="rol.FgApplyTablet" 
              :is-valid="hasError($v.rol.FgApplyTablet)"
              :invalid-feedback="errorMessage($v.rol.FgApplyTablet)"             
            >
            </CSelect>
          </CCol>
          <CCol sm="12">
            <CCollapse :show="tablaCollapse" class="mt-2">
              <dataTableExtended
                class="align-center-row-datatable"
                :items="formatedItems"
                :fields="fields"
                :loading="Loading"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="5"
                hover
                small
                sorter
                pagination  
              >
                <template #loading>
                  <loading/>
                </template>
                <template #FgApplyTablet="{item}">
                  <td class="center-cell">
                    <div v-if="item.FgApplyTablet">
                      {{$t('label.yes')}} 
                    </div>
                    <div v-if="!item.FgApplyTablet">
                      {{ 'NO' }} 
                    </div>
                  </td>
                </template> 
                <template #Status="{item}">
                  <td class="center-cell">
                  <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #Detalle="{item}">
                <td class="center-cell  py-2">
                  <CButton color="edit" class="mr-1" size="sm" 
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.role'),
                      placement: 'top-end'
                    }"
                  @click="Editar(item)"   
                  >
                    <CIcon name="pencil" />
                  </CButton>
                  <CButton color="primary-yellow" size="sm" 
                    v-c-tooltip="getTooltip(item.Status)"
                    @click="ConfirmarEliminado(item)" 
                    :disabled="isSubmit"
                  >
                    <CIcon name="cil-new-releases" />
                  </CButton>
                </td>
              </template>
              </dataTableExtended>
            </CCollapse>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!--</CCardBody>
    <CCardFooter class="d-flex justify-content-end">-->
    <!--<CRow>
      <CCol sm="12" class="text-right">
        <CButtonGroup>
          <CButton
            v-if="!Edit"
            size="sm"
            shape="square"
            color="add"
            class="d-flex align-items-center mr-2"
            :disabled="isSubmitValid"
            @click="submit"
          >
            <div v-if="!isSubmit">
              <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
            </div>
            <div v-if="isSubmit">
              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ml-1">{{$t('button.accept')}}</span>
            </div>
          </CButton>
          <CButton
            v-else
            size="sm"
            shape="square"
            color="add"
            class="d-flex align-items-center mr-2"
            :disabled="isSubmitValid"
            @click="statusConfirmation(StowageRole.FgActStowageRole,rol.Status, submit, StowageRole.StowageRoleName)"
          >
            <div v-if="!isSubmit">
              <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
            </div>
            <div v-if="isSubmit">
              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ml-1">{{$t('button.accept')}}</span>
            </div>
          </CButton>
          <CButton
            size="sm"
            shape="square"
            color="wipe"
            :disabled="isSubmit"
            class="d-flex align-items-center"
            @click.stop="closeCollapse"
          >
            <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
        </CButtonGroup>-->
    <!--</CCardFooter>
  </CCard>-->
    <!--  </CCol>
    </CRow>-->
  </div>
</template>

<script>
import SingleRoleFormValidation from '@/_validations/estiba/SingleRoleFormValidation';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import General from '@/_mixins/general';
import { hasError } from '@/_helpers/funciones';
import { DateFormater } from '@/_helpers/funciones';

//Data
function data() {
  return {
    isSubmit: false,
    Loading: false,
    rol: {
      StowageRoleId: '',
      StowageRoleName: '',
      FgApplyTablet: 1,
      Status: 1
    },
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: this.$t('label.name'),
    tablaCollapse: false,
    items: [],
    edita: false,
    stowageRoleName: '',
    selectOptionsFgApplyTablet: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],
  }
}

async function StowageRolJoin(idRol) {
  let IdTipoBuque= '';

  if(this.$store.state.planificacionestiba.TpVesselId)
    IdTipoBuque = this.$store.state.planificacionestiba.TpVesselId;

  if(this.$store.state.visitas.itinerarySelected.TpVesselId)
    IdTipoBuque = this.$store.state.visitas.itinerarySelected.TpVesselId;

  //let IdTipoBuque = this.$store.state.planificacionestiba.TpVesselId;
  let StowageRoleVesselJson = {
    StowageRoleId: idRol,
    TpVesselId: IdTipoBuque
  };

  if (IdTipoBuque!=null || IdTipoBuque!='') {
    await this.$http.post("StowageRoleVessel-insert", StowageRoleVesselJson, { root: 'StowageRoleVesselJson' })
    .then(response => {
      return true;
    }).catch(err => {
      return false;
    })
  } else {
    return false;
  } 
}

//Method
function submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
     
    let metodo = this.edita ? 'PUT':'POST';
    let ruta = this.edita ? 'StowageRole-update' : 'StowageRole-insert'; 
    let StowageRoleJson = this.edita
      ? {
          StowageRoleId: this.rol.StowageRoleId,
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
          Status: this.rol.Status
        }
      : { 
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
        };

    this.$http.ejecutar(metodo, ruta, StowageRoleJson, { root: 'StowageRoleJson' })
    .then(response => {
      if (metodo=='POST') {
        this.StowageRolJoin(response.data.data[0].StowageRoleId);
      }
      this.getRolesList();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
     //this.resetForm();
     this.limpiarDatos();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function closeCollapse() {
  this.resetForm();
}

function resetForm() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.edita= false;
  this.rol.FgApplyTablet = 1;
  //this.$v.$reset();
  this.$nextTick(() => { this.$v.$reset() })
  this.$emit('close');
}
function setForm(newRole) {
  this.rol.StowageRoleId = newRole.StowageRoleId;
  this.rol.StowageRoleName = newRole.StowageRoleName;
  this.rol.FgApplyTablet = newRole.FgApplyTablet ? 1 : 0;
  this.rol.Status = newRole.FgActStowageRole ? 1 : 0;
}

function getRolesList() {
 //this.Loading = true;
  this.$http.get("StowageRole-list", {
      filter: 'ALL'
  }).then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    //this.Loading = false;
  });
    
}

function getTooltip(status) {
  switch (status) {
    case 'ACTIVO': 
      return {
        content: this.$t('label.inactivateRole'), //'Inactivar role',
        placement: 'top-end'
      };
    case 'INACTIVO':
      return {
        content:  this.$t('label.activateRole'), //'Activar role',
        placement: 'top-end'
      };
  }
}
function ConfirmarEliminado(item) {
  this.$swal.fire({
    text: `${this.$t('label.changeStatusQuestion')+' '} ${item.StowageRoleName}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: this.$t('button.confirm'),
    cancelButtonText: this.$t('button.cancel')
  }).then((result) => {
    if (result.isConfirmed) {
      //this.Loading = true;
      this.isSubmit = true;
      let res = [];
      let StowageRoleJson = [];

      StowageRoleJson = {
        StowageRoleId: item.StowageRoleId,
        StowageRoleName: item.StowageRoleName,
        Status: item.Status === 'ACTIVO'? 0:1,
      };
      this.$http.put("StowageRole-update", StowageRoleJson, { root: 'StowageRoleJson' })
      .then(response => {
        res = [...response.data.data];
        this.getRolesList();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
          this.limpiarDatos()
       // this.Loading= false;    
        this.isSubmit = false;
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        //this.Loading= false;
        this.isSubmit = false;
      });
    }
  });  
}

function Editar(item) {
  this.rol.StowageRoleId   = item.StowageRoleId;
  this.rol.StowageRoleName = item.StowageRoleName;
  this.rol.FgApplyTablet = item.FgApplyTablet ? 1:0;
  //this.titulo = this.$t('label.edit')+' '+this.$t('label.role')+': '+item.StowageRoleName;
  this.stowageRoleName = item.StowageRoleName;
  this.edita = true;
  this.$v.$touch();
}

function limpiarDatos() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.rol.FgApplyTablet = 0;
  this.stowageRoleName= '',
  this.edita= false,
 // this.$v.$reset();
  this.$nextTick(() => { this.$v.$reset() })
}

//Computed
function titulo() {
  return this.edita ? this.$t('label.edit')+' '+ this.$t('label.role')+': '+this.stowageRoleName : this.$t('label.nuevo')+' '+ this.$t('label.role');
}

function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function cambiaIcono() {
  return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}

/*
function statusSelectColor(){
  return this.rol.Status === 1;
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}*/

function fields(){ 
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;' },
    { key: 'StowageRoleName', label: this.$t('label.stowageRole'), _style:'width:25%', _classes: 'text-uppercase' },
    { key: 'FgApplyTablet', filter: false, label: 'SIGET MOBILE', _style: 'width: 15%;', _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-uppercase' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 10%;', _classes:'text-center'},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style:'min-width:100px; width:10%;',
      _classes: 'text-center'
    }
  ]
}

function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    Nro: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
   // FgApplyTablet: item.FgApplyTablet ? 1 : 0,
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

function created() {
  this.getRolesList();
}

export default {
  name:  'role-collapse',
  props: {
    StowageRole: {
      type: Object,
      default: () => {}
    },
    Edit: {
      type: Boolean,
      default: false
    },
    LimpiarCollapse: {
      type: Boolean,
      default: false
    },
  },
  data,
  created,
  mixins: [General, ModalMixin],
  validations: SingleRoleFormValidation,
  directives: UpperCase,
  methods: {
    submit,
    closeCollapse,
    hasError,
    resetForm,
    setForm,
    getRolesList,
    getTooltip,
    ConfirmarEliminado,
    Editar,
    limpiarDatos,
    StowageRolJoin
  },
  computed: {
    titulo,
    //statusSelectColor,
    isSubmitValid,
    cambiaIcono,
    cambiaTexto,
    fields, 
    formatedItems,
  },
  watch: {
    Edit: function(newEdit){
      if(newEdit && Object.keys(this.StowageRole).length !== 0)
        this.setForm(this.StowageRole);  
        //this.resetForm(); 
    },
    StowageRole: function(newRole) {
      if(this.Edit && Object.keys(newRole).length !== 0)
        this.setForm(newRole);
       // this.resetForm(); 
    },
    LimpiarCollapse: function(val){
      if(val){
        this.limpiarDatos();
      }
    }
  }
}
</script>

<style lang="scss">
  .card-body .card-header {
    background-color: #5D6164 !important;
    color: #fff;
  }
  .center-cell {
  text-align: center;
  }
  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>